import { Routes } from '@angular/router';

import { AllFirePermitsComponent } from './all-fire-permits/all-fire-permits.component';
import { UpdateFirePermitComponent } from './update-fire-permit/update-fire-permit.component';
import { UploadFirePermitFileComponent } from './upload-fire-permit-file/upload-fire-permit-file.component';
import { ModalLogsComponent } from '../logs/modal-logs/modal-logs.component';
import { AllFirePermitActionsComponent } from './all-fire-permit-actions/all-fire-permit-actions.component';
import { UpdateFirePermitActionComponent } from './update-fire-permit-action/update-fire-permit-action.component';

export const firePermitsRoutes: Routes = [
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/fire-permits',
    component: AllFirePermitsComponent,
    children: [
      {
        path: 'create',
        component: UpdateFirePermitComponent
      },
      {
        path: ':fire_permit_id',
        component: UpdateFirePermitComponent
      },
      {
        path: ':fire_permit_id/logs',
        component: ModalLogsComponent
      }
    ]
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/fire-permits/:fire_permit_id/files',
    component: AllFirePermitsComponent,
    children: [
      {
        path: 'create',
        component: UploadFirePermitFileComponent
      },
      {
        path: ':file_id',
        component: UploadFirePermitFileComponent
      }
    ]
  }, {
    path: 'fire-permit-actions',
    component: AllFirePermitActionsComponent,
    children: [
      {
        path: 'create',
        component: UpdateFirePermitActionComponent
      },
      {
        path: ':action_id',
        component: UpdateFirePermitActionComponent
      }
    ]
  }
];
