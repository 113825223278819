import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs';

import { AuthService } from '../../../core/auth/auth.service';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { RegistriesRequestService } from '../../../services/registries.request.service';
import { SanitaryWorksheetsRequestService } from '../../../services/sanitary-worksheets.request.service';

import { EstablishmentsRequestService } from '../../../services/establishments.request.service';
import Registry from '../../../../shared/interfaces/registry';
import Establishment from '../../../../shared/interfaces/establishment';
import SanitaryWorksheet from '../../../../shared/interfaces/sanitary_worksheet';
import BatiFile from '../../../../shared/interfaces/bati_file';


declare function Flatpickr(): any;

@Component({
  selector: 'app-all-sanitary-worksheets',
  templateUrl: './all-sanitary-worksheets.component.html',
  styleUrls: ['./all-sanitary-worksheets.component.scss']
})
export class AllSanitaryWorksheetsComponent implements OnInit, AfterViewInit, OnDestroy {
  changeEnterprise: Subscription;

  canCreate = false;
  canUpdate = false;
  canDelete = false;
  canLog = false;

  requestInProgress = new Subject();
  requestCount = 0;

  pageName: string;
  previousPageName: string[] = [];
  previousPageRoute: string[] = [];

  currentRoute = '';

  registry: Registry;
  establishment: Establishment;
  sanitaryWorksheets: SanitaryWorksheet[] = [];
  sanitaryWorksheetFiles: SanitaryWorksheet [] = [];
  files: BatiFile[] = [];

  count = 0;
  pages = 1;

  search = '';
  sort = '';
  order = 'desc';
  limit = 12;
  page = 1;

  countFile = 0;
  pagesFile = 1;

  searchFile = '';
  sortFile = '';
  orderFile = 'desc';
  limitFile = 12;
  pageFile = 1;

  errors = {};

  timeout: any;
  loaderContent = false;
  loaderWorksheets = false;
  loaderFiles = false;

  modalConfirmData = null;
  modalConfirmFile = null;

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private registriesService: RegistriesRequestService,
    private sanitaryWorksheetsService: SanitaryWorksheetsRequestService,
    private establishmentsService: EstablishmentsRequestService,
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loaderContent = this.requestCount > 0;
    });
    this.changeEnterprise = this.authService.getEnterprise().subscribe(() => {
      this.getRegistry();
      this.getEstablishment();
      this.getSanitaryWorksheets();
      this.getSanitaryWorksheetsFiles();
    });

    this.currentRoute = '/' + this.activatedRoute.snapshot.url.join('/') + '/';

    this.sort = 'SanitaryWorksheets.description';
    this.sortFile = 'SanitaryWorksheets.name';
    this.pageName = 'Liste des fiches travaux';

    if (this.authService.loaded) {
      this.getRegistry();
      this.getEstablishment();
      this.getSanitaryWorksheets();
      this.getSanitaryWorksheetsFiles();
    }
    this.createBreadcrumbs();
  }

  ngAfterViewInit() {
    this.modalConfirmData.eventOnClose.subscribe(event => {
      if (event) {
        this.requestInProgress.next(true);
        let request;

        request = this.sanitaryWorksheetsService.deleteSanitaryWorksheet(
          this.activatedRoute.snapshot.params.registry_id,
          this.activatedRoute.snapshot.params.establishment_id,
          event
        );

        request.subscribe(response => {
          if (response['success']) {
            this.refreshData();
            this.refreshDataFiles();
            CustomNotifierService.getSuccessDelete(this.notifier);
          } else if (response['errors']) {
            CustomNotifierService.getErrors(response['errors'], this.notifier);
          }
          this.requestInProgress.next(false);
        });
      }
    });
  }

  ngOnDestroy() {
    const flatPicker = document.getElementsByClassName('flatpickr-calendar') as HTMLCollection;
    for (let i = flatPicker.length; i >= 0; i--) {
      if (flatPicker[i]) {
        flatPicker[i].remove();
      }
    }

    this.changeEnterprise.unsubscribe();
  }

  searchData() {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.page = 1;
      this.refreshData();
    }, 500);
  }

  refreshData(sort = this.sort, order = this.order, page = this.page) {
    this.sanitaryWorksheets = [];
    this.sort = sort;
    this.order = order;
    this.page = page;
    this.getSanitaryWorksheets();
  }

  searchDataFiles() {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.pageFile = 1;
      this.refreshDataFiles();
    }, 500);
  }

  refreshDataFiles(sort = this.sortFile, order = this.orderFile, page = this.pageFile) {
    this.sanitaryWorksheetFiles = [];
    this.sortFile = sort;
    this.orderFile = order;
    this.pageFile = page;
    this.getSanitaryWorksheetsFiles();
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  getRegistry() {
    this.requestInProgress.next(true);
    this.registriesService.getRegistry(this.activatedRoute.snapshot.params.registry_id).subscribe(response => {
      if (response['success']) {
        this.registry = response['registry'];
        this.createBreadcrumbs();
      } else if (response['errors']) {
        this.errors = response['errors'];
      }
      this.requestInProgress.next(false);
    });
  }

  getEstablishment() {
    this.requestInProgress.next(true);
    this.establishmentsService.getMyEstablishment(this.activatedRoute.snapshot.params.establishment_id).subscribe(response => {
      if (response['success']) {
        this.establishment = response['establishment'];
        this.createBreadcrumbs();
      } else if (response['errors']) {
        this.errors = response['errors'];
      }
      this.requestInProgress.next(false);
    });
  }

  getSanitaryWorksheets() {
    let request;
    this.requestInProgress.next(true);
    this.loaderWorksheets = true;

    request = this.sanitaryWorksheetsService.getAllSanitaryWorksheet(
        this.search, this.sort, this.order, this.limit, this.page,
        this.activatedRoute.snapshot.params.registry_id,
        this.activatedRoute.snapshot.params.establishment_id
    );

    request.subscribe(response => {
      if (response['success']) {
        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];
        this.canDelete = response['canDelete'];
        this.canLog = response['canLog'];

        if (response['sanitaryWorksheets']) {
          this.sanitaryWorksheets = response['sanitaryWorksheets'];
        } else {
          this.files = response['files'];
        }

        this.count = response['count'];
        this.pages = response['pages'];
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loaderWorksheets = false;
      this.requestInProgress.next(false);

      if (!this.activatedRoute.children.length) {
        setTimeout(() => {
          Flatpickr();
        }, 250);
      }
    });
  }

  getSanitaryWorksheetsFiles() {
    let request;
    this.requestInProgress.next(true);
    this.loaderFiles = true;

    request = this.sanitaryWorksheetsService.getAllSanitaryWorksheetFiles(
        this.searchFile, this.sortFile, this.orderFile, this.limitFile, this.pageFile,
        this.activatedRoute.snapshot.params.registry_id,
        this.activatedRoute.snapshot.params.establishment_id
    );

    request.subscribe(response => {
      if (response['success']) {
        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];
        this.canDelete = response['canDelete'];
        this.canLog = response['canLog'];

        if (response['sanitaryWorksheets']) {
          this.sanitaryWorksheetFiles = response['sanitaryWorksheets'];
        }

        this.countFile = response['count'];
        this.pagesFile = response['pages'];
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loaderFiles = false;
      this.requestInProgress.next(false);

      if (!this.activatedRoute.children.length) {
        setTimeout(() => {
          Flatpickr();
        }, 250);
      }
    });
  }

  downloadFile(file_id, file_name) {
    let request;

    request = this.sanitaryWorksheetsService.downloadFile(
        this.activatedRoute.snapshot.params.registry_id,
        this.activatedRoute.snapshot.params.establishment_id,
        file_id,
    );

    request.subscribe((response: BufferSource) => {
      try {
        const decoder = new TextDecoder('utf-8');
        const domString = decoder.decode(response);
        const json = JSON.parse(domString);

        CustomNotifierService.getErrors(json['errors'], this.notifier);
      } catch (e) {
        const blob = new Blob([response]);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = file_name;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      }
    });
  }

  filter(property) {
    this.sanitaryWorksheets = [];
    if (this.sort === property) {
      if (this.order === 'asc') {
        this.order = 'desc';
      } else {
        this.order = 'asc';
      }
    } else {
      this.sort = property;
      this.order = 'asc';
    }
    this.getSanitaryWorksheets();
  }

  filterFiles(property) {
    this.sanitaryWorksheetFiles = [];
    if (this.sortFile === property) {
      if (this.orderFile === 'asc') {
        this.orderFile = 'desc';
      } else {
        this.orderFile = 'asc';
      }
    } else {
      this.sortFile = property;
      this.orderFile = 'asc';
    }
    this.getSanitaryWorksheetsFiles();
  }

  createBreadcrumbs() {
    this.previousPageName = [];
    this.previousPageRoute = [];

    if (this.activatedRoute.snapshot.params.registry_id && this.activatedRoute.snapshot.params.establishment_id) {
      this.previousPageName.push('Tableau de bord');
      this.previousPageRoute.push('/');

      this.previousPageName.push(this.registry ? this.registry.name : 'Tableau de bord registre');
      this.previousPageRoute.push('/dashboard-registry/' + this.activatedRoute.snapshot.params.registry_id);

      this.previousPageName.push(this.establishment ? this.establishment.name : 'Tableau de bord établissement');
      this.previousPageRoute.push('/dashboard-registry/' + this.activatedRoute.snapshot.params.registry_id +
          '/establishment/' + this.activatedRoute.snapshot.params.establishment_id);
    } else {
      this.previousPageName.push('Tableau de bord');
      this.previousPageRoute.push('/administration');
    }
  }

  dispatchPagination(data) {
    if (data.action === 'total_elements') {
      this.refreshPage(data.value);
    } else if (data.action === 'change_page') {
      this.changePage(data.value);
    }
  }

  refreshPage(limit) {
    this.limit = limit;
    this.page = 1;
    this.refreshData();
  }

  changePage(page) {
    if (page >= 1 && page <= this.pages) {
      this.page = page;
      this.getSanitaryWorksheets();
    }
  }

  dispatchPaginationFile(data) {
    if (data.action === 'total_elements') {
      this.refreshPageFile(data.value);
    } else if (data.action === 'change_page') {
      this.changePageFile(data.value);
    }
  }

  refreshPageFile(limit) {
    this.limitFile = limit;
    this.pageFile = 1;
    this.refreshDataFiles();
  }

  changePageFile(page) {
    if (page >= 1 && page <= this.pagesFile) {
      this.pageFile = page;
      this.getSanitaryWorksheetsFiles();
    }
  }

  bindModalConfirmData(modal) {
    this.modalConfirmData = modal;
  }
  bindModalConfirmFile(modal) {
    this.modalConfirmFile = modal;
  }
}
