import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { HttpClient } from '@angular/common/http';
import { RequestService } from './request.service';

@Injectable()
export class SanitaryLegionellaFramesRequestService extends RequestService {
  constructor(
    protected http: HttpClient,
    protected notifier: NotifierService
  ) {
    super(http, notifier);
  }

  /// GET ALL
  public getAllSanitaryLegionellaFrames(search, sort, order, limit, page, registry_id, establishment_id, sanitaryLegionellaId) {
    return this.get( 'registry/' + registry_id + '/establishment/' + establishment_id + '/sanitary-surveillance/legionellas/' +
      sanitaryLegionellaId + '/frames?' + 'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit +
      '&page=' + page);
  }
  /// GET ONE
  public getSanitaryLegionellaFrame(registry_id, establishment_id, sanitaryLegionellaId, sanitaryLegionellaFrameId) {
    return this.get('registry/' + registry_id + '/establishment/' + establishment_id + '/sanitary-surveillance/legionellas/'
      + sanitaryLegionellaId + '/frame/' + sanitaryLegionellaFrameId);
  }
  /// CREATE
  public createSanitaryLegionellaFrame(registry_id, establishment_id, sanitaryLegionellaId, sanitaryLegionellaFrame) {
    return this.post('registry/' + registry_id + '/establishment/' + establishment_id + '/sanitary-surveillance/legionellas/'
      + sanitaryLegionellaId + '/frame/', { sanitaryLegionellaFrame });
  }
  /// UPDATE
  public updateSanitaryLegionellaFrame(registry_id, establishment_id, sanitaryLegionellaId, sanitaryLegionellaFrameId,
                                       sanitaryLegionellaFrame) {
    return this.put('registry/' + registry_id + '/establishment/' + establishment_id + '/sanitary-surveillance/legionellas/'
      + sanitaryLegionellaId + '/frame/' + sanitaryLegionellaFrameId, { sanitaryLegionellaFrame });
  }
  /// DELETE
  public deleteSanitaryLegionellaFrame(registry_id, establishment_id, sanitaryLegionellaId, sanitaryLegionellaFrameId) {
    return this.delete('registry/' + registry_id + '/establishment/' + establishment_id + '/sanitary-surveillance/legionellas/'
      + sanitaryLegionellaId + '/frame/' + sanitaryLegionellaFrameId);
  }
}
