<div class="d-flex align-items-center bg-auth border-top border-top-2 border-primary">
  <div class="container">

    <div class="is-loading loader-modal-content" *ngIf="loader"></div>

    <div class="row justify-content-center" *ngIf="!loader && ssoConfig">
      <div class="col-12 col-md-5 col-xl-4 my-5">
        <div class="row d-flex align-items-center text-center mb-5">
          <img class="col-6 logo" src="{{ environment.SERVER_HOST + 'img/sso_configs/' + ssoConfig['picture'] }}" alt="Logo {{ ssoConfig.name }}" *ngIf="ssoConfig && ssoConfig['picture']"/>
          <img class="col-6" src="../../../../assets/img/logo_batiregistre.png" alt="Logo Batiregistre" />
        </div>

        <h1 class="display-4 text-center mb-3">
          Me connecter
        </h1>

        <p class="text-muted text-center mb-5">
          Accédez à Batiregistre
        </p>

        <button class="btn btn-lg btn-block btn-primary mb-3" (click)="signIn()"
                [ngClass]="{ 'is-loading is-loading-sm': isSigningIn }"
                [disabled]="isSigningIn">
          Se connecter via Authentification unique
        </button>
      </div>
    </div>
  </div>
</div>
