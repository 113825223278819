import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AllObservationsModule } from './all-observations/all-observations.module';
import { UpdateObservationModule } from './update-observation/update-observation.module';
import { UpdateObservationStateModule } from './update-observation-state/update-observation-state.module';
import { UploadObservationFileModule } from './upload-observation-file/upload-observation-file.module';

import { ObservationsRequestService } from '../../services/observations.request.service';

@NgModule({
  imports: [
    CommonModule,
    AllObservationsModule,
    UpdateObservationModule,
    UploadObservationFileModule,
    UpdateObservationStateModule
  ],
  providers: [ObservationsRequestService]
})
export class ObservationsModule {}
