import { Routes } from '@angular/router';
import { AllIssuesComponent } from './all-issues/all-issues.component';
import { UpdateIssueComponent } from './update-issue/update-issue.component';
import { CreateIssueComponent } from './create-issue/create-issue.component';
import { CreateCommentComponent } from './create-comment/create-comment.component';


export const issuesRoutes: Routes = [
  {
    path: 'issues',
    component: AllIssuesComponent,
  },
  {
    path: 'issues/mines',
    component: AllIssuesComponent,
    children: [
      {
        path: 'create',
        component: CreateIssueComponent
      }
    ]
  },
  {
    path: 'issues/:issueId',
    component: UpdateIssueComponent,
    children: [
      {
        path: 'comment',
        component: CreateCommentComponent
      }
    ]
  },
  {
    path: 'issues/mines/:issueId',
    component: UpdateIssueComponent,
    children: [
      {
        path: 'comment',
        component: CreateCommentComponent
      }
    ]
  },
];
