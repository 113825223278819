<app-modal-right (eventOnClose)="closeModal()" #reportModal>{{ bindModal(reportModal) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">{{ isNew ? 'Création' : canUpdate ? 'Mise à jour' : 'Consultation' }}</h6>

        <h1 class="header-title">
          Rapport {{ checkpoint ? ' - ' +
          (checkpoint.checkpoint ? checkpoint.checkpoint.name : '') +
          (checkpoint.checkpoint && checkpoint.name ? ' - ' : '') +
          (checkpoint.name ? checkpoint.name : '') : '' }}
        </h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <form [formGroup]="reportForm" [hidden]="loaderContent">
      <div class="row">
        <div class="col-12">
          <!-- INTERVENANT -->
          <div class="form-group" *ngIf="!loaderContent">
            <label>Intervenant</label>
            <ng-select [items]="enterprises"
                       [multiple]="false"
                       bindLabel="name"
                       notFoundText="Aucun résultat"
                       [selectableGroup]="true"
                       [closeOnSelect]="true"
                       bindValue="id"
                       placeholder="Sélectionnez un intervenant"
                       [(ngModel)]="user" (ngModelChange)="changeUser()" [ngModelOptions]="{ standalone: true }" [disabled]="!isNew && !canUpdate">
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                {{ item.name }}
              </ng-template>
            </ng-select>
          </div>

          <!-- VISIT DATE -->
          <div class="form-group">
            <label for="validationStartDate" required>Date de visite</label>
            <input type="date" formControlName="visit_date" class="form-control" id="validationStartDate" placeholder="Date de visite" data-toggle="flatpickr"
                   [ngClass]="{ 'is-invalid': visit_date.invalid && (visit_date.dirty || visit_date.touched) }">
            <div *ngIf="visit_date.invalid && (visit_date.dirty || visit_date.touched)" class="invalid-feedback">
              {{ getErrorMessage("visit_date") }}
            </div>
          </div>

          <!-- RECEPTION DATE -->
          <div class="form-group">
            <label for="validationAcceptedDate">Date de réception du rapport</label>
            <input type="date" formControlName="report_date" class="form-control" id="validationAcceptedDate" placeholder="Date de réception du rapport" data-toggle="flatpickr"
                   [ngClass]="{ 'is-invalid': report_date.invalid && (report_date.dirty || report_date.touched) }">
            <div *ngIf="report_date.invalid && (report_date.dirty || report_date.touched)" class="invalid-feedback">
              {{ getErrorMessage("report_date") }}
            </div>
          </div>

          <!-- OBSERVATIONS -->
          <div class="form-group" *ngIf="oldObservations">
            <label for="ObservationsOld">Observations existantes</label>
            <textarea class="form-control" id="ObservationsOld" placeholder="Observations" [rows]="countOldObservations" disabled>{{ oldObservations }}</textarea>
          </div>
          <div class="form-group">
            <label for="validationObservations">Observations</label>
            <textarea formControlName="observations" class="form-control" id="validationObservations" placeholder="Observations" rows="5" (keydown.enter)="addTextareaBullet($event)" (paste)="pasteTextareaBullet($event)" (change)="addTextareaBullet($event)"
                      [ngClass]="{ 'is-invalid': observations.invalid && (observations.dirty || observations.touched) }"></textarea>
            <div *ngIf="observations.invalid && (observations.dirty || observations.touched)" class="invalid-feedback">
              {{ getErrorMessage("observations") }}
            </div>
            <span class="badge badge-soft-info d-inline-flex"><span class="fe fe-info mr-1"></span>Une observation par ligne</span>
          </div>

          <!-- CORRECTOR -->
          <div class="form-group" *ngIf="!loaderContent">
            <label>Affecter la levée des observations à</label>
            <ng-select [items]="correctors"
                       [multiple]="false"
                       bindLabel="name"
                       notFoundText="Aucun résultat"
                       [selectableGroup]="true"
                       [closeOnSelect]="true"
                       bindValue="id"
                       placeholder="Sélectionnez un intervenant"
                       [(ngModel)]="corrector" (ngModelChange)="changeCorrector()" [ngModelOptions]="{ standalone: true }" [disabled]="!isNew && !canUpdate">
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                {{ item.name }}
              </ng-template>
            </ng-select>
          </div>

          <!-- DEADLINE -->
          <div class="form-group">
            <label for="validationDeadline">Échéance</label>
            <input type="date" formControlName="deadline" class="form-control" id="validationDeadline" placeholder="Échéance" data-toggle="flatpickr" [ngClass]="{ 'is-invalid': deadline.invalid && (deadline.dirty || deadline.touched) }">
            <div *ngIf="deadline.invalid && (deadline.dirty || deadline.touched)" class="invalid-feedback">
              {{ getErrorMessage("deadline") }}
            </div>
          </div>

          <!-- FINANCIAL ESTIMATE -->
          <div class="form-group">
            <label for="validationFinancialEstimation">Estimation financière</label>
            <input type="number" formControlName="financial_estimation" class="form-control" id="validationFinancialEstimation" placeholder="0" [ngClass]="{ 'is-invalid': financial_estimation.invalid && (financial_estimation.dirty || financial_estimation.touched) }" min="0">
            <div *ngIf="financial_estimation.invalid && (financial_estimation.dirty || financial_estimation.touched)" class="invalid-feedback">
              {{ getErrorMessage("financial_estimation") }}
            </div>
          </div>

          <!-- EMERGENCIES -->
          <div class="form-group">
            <label for="validationEmergencies">Degré d'urgence</label>
            <select class="form-control" data-toggle="select" formControlName="emergencies" id="validationEmergencies" [ngClass]="{ 'is-invalid': emergencies.invalid && (emergencies.dirty || emergencies.touched) }">
              <option [value]="null">Sélectionnez un degré d'urgence</option>
              <option value="0">Bas</option>
              <option value="1">Normal</option>
              <option value="2">Haut</option>
              <option value="3">Prioritaire</option>
            </select>
            <div *ngIf="emergencies.invalid && (emergencies.dirty || emergencies.touched)" class="invalid-feedback">
              {{ getErrorMessage("emergencies") }}
            </div>
          </div>

          <!-- COMMENTS -->
          <div class="form-group">
            <label for="validationCommentReport">Commentaires éventuels</label>
            <textarea formControlName="comment_report" class="form-control" id="validationCommentReport" placeholder="Commentaires éventuels" rows="5"></textarea>
            <div *ngIf="comment_report.invalid && (comment_report.dirty || comment_report.touched)" class="invalid-feedback">
              {{ getErrorMessage("comment_report") }}
            </div>
          </div>

          <div class="form-group" *ngIf="isNew || canUpdate">
            <label for="inputFile">Documents</label>

            <div class="dropzone dropzone-multiple dz-clickable" appDragDrop (dropHandler)="onFileChange($event)">
              <div class="dz-default dz-message" (click)="chooseFile()">
                <span>Déposer vos fichiers ici pour télécharger</span>
              </div>
              <input id="inputFile" (change)="onFileChange($event)" type="file" [hidden]="true" multiple>
            </div>
          </div>

          <div class="card" *ngFor="let file of files; let index = index">
            <div class="card-body">
              <div class="d-flex align-content-center justify-content-between">
                <h4 class="card-title mb-0 name align-self-center">
                  {{ file.name + '.' + file.extension }}
                </h4>

                <a class="href tooltipHtml" data-toggle="dropdown" data-placement="bottom" title="Supprimer" (click)="removeFile(index)">
                  <i class="fe fe-trash"></i>
                </a>
              </div>
            </div>
          </div>

          <!-- ESTABLISHMENTS -->
          <div class="form-group" *ngIf="isNew && !loaderContent && establishments">
            <label for="establismentSelected">Autre(s) établissement(s) cible(s)</label>
            <ng-select [items]="establishments"
                       [multiple]="true"
                       bindLabel="name"
                       bindValue="id"
                       groupBy="group"
                       notFoundText="Aucun résultat"
                       [selectableGroup]="true"
                       [selectableGroupAsModel]="false"
                       [closeOnSelect]="false"
                       placeholder="Sélectionnez un établissement"
                       [(ngModel)]="establishmentsSelected" [ngModelOptions]="{ standalone: true }" [disabled]="!isNew && !canUpdate">
              <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                <input type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }"/> Sélectionnez tout
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="establismentSelected" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }" /> {{ item.name }}
              </ng-template>
            </ng-select>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div modal-footer class="w-100" *ngIf="isNew || canUpdate">
    <button class="btn btn-primary w-100" (click)="isNew ? createReport() : updateReport()" [ngClass]="{ 'is-loading is-loading-sm': loader }" [disabled]="loader || reportForm.invalid">
      {{ isNew ? 'Créer' : 'Mettre à jour' }}
    </button>
  </div>
</app-modal-right>
