import { Injectable, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { Location } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NotifierModule } from 'angular-notifier';
import { AgmCoreModule } from '@agm/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { CoreModule } from './core/core.module';
import { PagesModule } from './pages/pages.module';

import { AuthGuard } from './core/auth/auth.guard';
import { AuthService } from './core/auth/auth.service';
import { TokenService } from './services/token.service';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { maps, environment } from '../environments/environment';

import { NotificationsRequestService } from './services/notifications.request.service';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { MessagingService } from './services/messaging.service';

@Injectable()
export class UnstripTrailingSlashLocation extends Location {
  public static stripTrailingSlash(url: string): string {
    return url;
  }
}

Location.stripTrailingSlash = UnstripTrailingSlashLocation.stripTrailingSlash;

@NgModule({
  declarations: [AppComponent],
  imports: [
    CoreModule,
    PagesModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NotifierModule.withConfig({
      position: {
        horizontal: {
          position: 'middle',
          distance: 12
        },
        vertical: {
          position: 'bottom',
          distance: 0,
          gap: 0
        }
      },
      behaviour: {
        stacking: 1
      }
    }),
    AgmCoreModule.forRoot({
      apiKey: maps.API_KEY,
      libraries: ['places'],
    }),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    NgbModule
  ],
  providers: [
    AuthGuard,
    AuthService,
    TokenService,
    NotificationsRequestService,
    MessagingService,
    { provide: HTTP_INTERCEPTORS, useClass: TokenService, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
