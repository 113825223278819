import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';

import { AllDocumentsComponent } from '../all-documents/all-documents.component';
import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { DocumentsRequestService } from '../../../services/documents.request.service';
import Folder from '../../../../shared/interfaces/folder';
import Registry from '../../../../shared/interfaces/registry';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-update-document',
  templateUrl: './update-document.component.html',
  styleUrls: ['./update-document.component.scss']
})
export class UpdateDocumentComponent implements OnInit, AfterViewInit, OnDestroy {
  canCreate = false;
  canUpdate = false;

  requestInProgress = new Subject();
  requestCount = 0;

  registries: Registry[] = [];
  folderTypes: Folder[] = [];

  folderForm: FormGroup;
  id = new FormControl('');
  registry_id = new FormControl('');
  parent_id = new FormControl('');
  generic_folders_type_id = new FormControl('', Validators.required);
  name = new FormControl('', Validators.required);
  is_important = new FormControl(false);
  is_concatenable = new FormControl(false);
  can_contain_file = new FormControl(false);
  order_number = new FormControl('', Validators.required);
  background_color = new FormControl('');

  errors = {};

  isNew = true;
  loader = false;
  loaderContent = false;

  modalFolder = null;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private allDocuments: AllDocumentsComponent,
    private documentsService: DocumentsRequestService
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loaderContent = this.requestCount > 0;
    });

    this.folderForm = this.formBuilder.group({
      id: this.id,
      registry_id: this.registry_id,
      parent_id: this.parent_id,
      generic_folders_type_id: this.generic_folders_type_id,
      name: this.name,
      is_important: this.is_important,
      is_concatenable: this.is_concatenable,
      can_contain_file: this.can_contain_file,
      order_number: this.order_number,
      background_color: this.background_color
    });

    if (this.activatedRoute.snapshot.params.folder_id) {
      this.isNew = false;
      this.registry_id.disable();
      this.generic_folders_type_id.disable();
      this.getFolder(this.activatedRoute.snapshot.params.folder_id, false);
    } else if (this.activatedRoute.snapshot.parent.params.parent_id) {
      this.getFolder(this.activatedRoute.snapshot.parent.params.parent_id, true);
    } else if (this.activatedRoute.snapshot.parent.params.registry_id) {
      this.generic_folders_type_id.setValue(1);
      this.generic_folders_type_id.disable();
      this.registry_id.setValue(parseInt(this.activatedRoute.snapshot.parent.params.registry_id, 10));
      this.registry_id.disable();
    }

    this.getRegistries();
    this.getFolderType();
  }

  ngAfterViewInit() {
    this.modalFolder.open();
  }

  ngOnDestroy() {
    this.modalFolder.close();
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  getFolder(id: number, children: boolean) {
    this.requestInProgress.next(true);

    this.documentsService.getFolder(id).subscribe(response => {
      if (response['success']) {
        if (children) {
          this.registry_id.setValue(response['folder']['registry_id']);
          this.registry_id.disable();

          this.generic_folders_type_id.setValue(response['folder']['generic_folders_type_id']);
          this.generic_folders_type_id.disable();
        } else {
          this.canCreate = response['canCreate'];
          this.canUpdate = response['canUpdate'];

          this.folderForm.patchValue(response['folder']);

          if (!this.canUpdate) {
            this.folderForm.disable();
          }

          this.is_concatenable.disable();

          if (this.activatedRoute.snapshot.parent.params.establishment_id) {
            this.folderForm.disable();
            this.is_important.disable();
            this.canUpdate = false;
          }
        }
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  getRegistries() {
    this.requestInProgress.next(true);

    this.documentsService.getDocumentsRegistries().subscribe(response => {
      if (response['success']) {
        this.registries = response['registries'];
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  getFolderType() {
    this.requestInProgress.next(true);

    this.documentsService.getFolderType().subscribe(response => {
      if (response['success']) {
        this.folderTypes = response['folderTypes'];
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  get selectedFolderType() {
    if (this.generic_folders_type_id.value) {
      const found = this.folderTypes.find(element => element.id === parseInt(this.generic_folders_type_id.value, 10));
      if (found && (found.registry_is_required || found.worksheet_is_required)) {
        this.registry_id.setValidators([Validators.required]);
        this.registry_id.updateValueAndValidity();
      } else {
        this.registry_id.clearValidators();
        this.registry_id.updateValueAndValidity();
      }
      return found;
    }
  }

  createFolder() {
    let folder;
    this.errors = {};
    this.loader = true;

    if (this.activatedRoute.snapshot.parent.params.parent_id) {
      folder = {
        ...this.folderForm.value,
        parent_id: this.activatedRoute.snapshot.parent.params.parent_id,
        registry_id: this.registry_id.value,
        generic_folders_type_id: this.generic_folders_type_id.value
      };
    } else {
      folder = {
        ...this.folderForm.value,
        registry_id: this.registry_id.value,
        generic_folders_type_id: this.generic_folders_type_id.value
      };
    }

    this.documentsService.createFolder(folder).subscribe(response => {
      if (response['success']) {
        this.modalFolder.close();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(response['errors'], this.folderForm, this.notifier);
      }
      this.loader = false;
    });
  }

  updateFolder() {
    let folder;
    this.errors = {};
    this.loader = true;

    folder = {
      ...this.folderForm.value,
      registry_id: this.registry_id.value,
      generic_folders_type_id: this.generic_folders_type_id.value,
      is_concatenable: this.is_concatenable.value
    };

    this.documentsService.updateFolder(this.id.value, folder).subscribe(response => {
      if (response['success']) {
        this.modalFolder.close();
        CustomNotifierService.getSuccessUpdate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(response['errors'], this.folderForm, this.notifier);
      }
      this.loader = false;
    });
  }

  bindModal(modal) {
    this.modalFolder = modal;
  }

  closeModal() {
    this.allDocuments.refreshData();
    this.router.navigate([this.activatedRoute.snapshot.parent.url.join('/')]).then(() => {});
  }
}
