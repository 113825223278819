<div class="main-content">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col">
        <div class="header mb-0">
          <div class="header-body no-border">
            <div class="row align-items-center">
              <div class="col">
                <h1 class="header-title" *ngIf="view === 'establishment'">
                  Points de contrôle {{ installation ? ' - ' + (installation.installation ? installation.installation.name : '') + ' ' + installation.description : '' }}
                </h1>
                <h1 class="header-title" *ngIf="view === 'installation'">
                  Points de contrôle {{ installation ? ' - ' + installation.name : '' }}
                </h1>
                <h1 class="header-title" *ngIf="view === 'registry'">
                  Points de contrôle {{ registry ? ' - ' + registry.name : '' }}
                </h1>

                <small class="form-text text-muted">
                  <span *ngIf="view === 'establishment'">
                    Liste des points de contrôle  de l'installation {{ installation && installation.name }}
                    du registre {{ registry ? registry.name : ' ' }} de mon établissement {{ establishment ? establishment.name : '' }}
                  </span>
                  <span *ngIf="view === 'installation'">Liste des points de contrôle de l'installation {{ installation && installation.name }}</span>
                  <span *ngIf="view === 'registry'">Liste des points de contrôle du registre {{ registry && registry.name }}</span>
                </small>

                <app-breadcrumbs [currentPage]="pageName" [names]="previousPageName" [routes]="previousPageRoute"></app-breadcrumbs>
              </div>

              <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0" *ngIf="view == 'establishment'">
                <a class="btn btn-primary d-block d-md-inline-block text-white" (click)="showHiddenCheckpoints()">
                  {{ show_inactive ? 'Masquer les installations masqués' : 'Afficher les installations masqués' }}
                </a>
              </div>

              <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0" *ngIf="(view !== 'establishment' && canCreate) || (view === 'establishment' && canCreate && registry && registry.checkpoints_custom_module)">
                <a class="btn btn-primary d-block d-md-inline-block" [routerLink]="[currentRoute + '/create']">
                  Ajouter un point de contrôle
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col">
            <app-searchbar [(ngModel)]="search" (keyup)="searchData()" ngDefaultControl></app-searchbar>
          </div>
          <div class="col-auto" *ngIf="view == 'establishment'">
            <div class="nav btn-group" role="tablist" id="filters-and-display-buttons">
              <button class="btn btn-lg btn-white tooltipHtml border-right-radius" role="tab" aria-label="Filtres" aria-labelledby="filters-and-display-buttons" data-toggle="dropdown" data-placement="bottom" title="Filtre">
                <span class="fe fe-filter"></span>
              </button>
              <span class="dropdown-menu dropdown-menu-right">
                <a class="dropdown-item href" (click)="filterData('with_report')" aria-label="Tri des points de contrôle : Avec rapports">
                  Avec Rapport
                </a>
                <a class="dropdown-item href" (click)="filterData('without_report')" aria-label="Tri des points de contrôle : Sans rapports">
                  Sans Rapport
                </a>
                <a class="dropdown-item href" (click)="filterData('')" aria-label="Tri des points de contrôle : Sans rapports">
                  Réinitialiser
                </a>
              </span>
            </div>
          </div>
        </div>

        <div class="is-loading loader-content" *ngIf="loader"></div>

        <div class="tab-content" *ngIf="!loader && checkpoints.length">

          <div class="tab-pane fade active show" id="tabPaneOne" role="tabpanel">
            <div class="row listAlias">
              <div class="col-12" *ngIf="show_inactive">
                <div class="card bg-light border">
                  <div class="card-body">
                    <span>Attention, à votre demande, les installations masquées sont temporairement visibles en gris.</span>
                  </div>
                </div>
              </div>

              <div class="col-12 d-flex flex-column">
                <div class="card flex-fill">
                  <div class="card-body">
                    <div class="table-responsive">
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th class="href" (click)="filter('Checkpoints.name')">
                              <a class="text-muted">{{ registry && registry.checkpoints_module && !registry.installation_custom_module ? 'Nom' : 'Point de contrôle' }}</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'Checkpoints.name' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'Checkpoints.name' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'Checkpoints.name'"></span>
                            </th>
                            <th>Fréquence</th>
                            <th *ngIf="view === 'establishment' && registry.checkpoints_module && !registry.installation_custom_module">Identifiant unique</th>
                            <th *ngIf="view === 'establishment'">Description</th>
                            <th *ngIf="view === 'establishment'">Rapports</th>
                            <th *ngIf="view === 'establishment'" class="href" (click)="filter('frequency_type')">
                              <a class="text-muted">Réglementation</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'frequency_type' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'frequency_type' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'frequency_type'"></span>
                            </th>
                            <th *ngIf="view !== 'establishment'">Installation</th>
                            <th *ngIf="view !== 'establishment'">Registre</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="dropdown" *ngFor="let checkpoint of checkpoints">
                            <td>
                              <a class="href" *ngIf="view !== 'establishment'" [routerLink]="[currentRoute + '/' + checkpoint.id]" [attr.aria-label]="'Point de contrôle : ' + checkpoint.name">
                                {{ checkpoint.name }}
                              </a>
                              <a class="href" *ngIf="view === 'establishment'" [routerLink]="[currentRoute + '/' + checkpoint.id + '/reports']" [ngClass]="{ 'is-inactive': checkpoint?.show_checkpoint === false }">
                                {{ registry.checkpoints_module && !registry.installation_custom_module ? (checkpoint.initial_name ? checkpoint.initial_name : '') : checkpoint.name }}
                              </a>
                              <i *ngIf="view === 'establishment' && checkpoint.reference !== null" class="fe fe-info ml-1 tooltipHtml cursor" data-html="true" title="Cliquer pour plus d'information sur le point de contrôle" [routerLink]="[currentRoute + '/' + checkpoint.id + '/tooltip']"></i>
                            </td>
                            <td>
                              {{ view === 'establishment' && checkpoint.frequency_duration ? (getFrequencyText(checkpoint.frequency, checkpoint.frequency_duration)) : 'Pas de fréquence' }}
                              {{ view !== 'establishment' ? getFrequenciesText(checkpoint.frequencies) : '' }}
                            </td>
                            <td *ngIf="view === 'establishment' && registry.checkpoints_module && !registry.installation_custom_module">{{ checkpoint.name && checkpoint.name }}</td>
                            <td *ngIf="view === 'establishment'">{{ checkpoint.description }}</td>
                            <td *ngIf="view === 'establishment'">
                              <div class="btn-group">
                                <a class="btn btn-sm btn-white" [routerLink]="[currentRoute + '/' + checkpoint.id + '/reports']">
                                  {{ checkpoint.count_reports + ' rapport(s)'}}
                                </a>
                              </div>
                            </td>
                            <td *ngIf="view === 'establishment'"><span class="label" [ngClass]="{'label-danger': getFrequencyRequired(checkpoint.frequencies) === 'Imposé', 'label-success': getFrequencyRequired(checkpoint.frequencies) === 'Recommandé' }">{{ getFrequencyRequired(checkpoint.frequencies) }}</span></td>
                            <td *ngIf="view !== 'establishment'" [attr.aria-label]="'Installation : ' + checkpoint.installation.name">{{ checkpoint.installation.name }}</td>
                            <td *ngIf="view !== 'establishment'" [attr.aria-label]="'Registre : ' + checkpoint.registry.name">{{ checkpoint.registry.name }}</td>
                            <td class="d-flex flex-row-reverse">
                              <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fe fe-more-vertical"></i>
                              </a>

                              <div class="dropdown-menu dropdown-menu-right">
                                <a *ngIf="view === 'establishment'" class="dropdown-item href" [routerLink]="[currentRoute + '/' + checkpoint.id + '/reports/create']">
                                  Ajouter un rapport
                                </a>

                                <a class="dropdown-item href" [routerLink]="[currentRoute + '/' + checkpoint.id]">
                                  {{ canUpdate ? 'Éditer le point de contrôle' : 'Consulter le point de contrôle' }}
                                </a>

                                <a *ngIf="view === 'establishment' && checkpoint.initial_name && canCreate" class="dropdown-item href" (click)="duplicateCheckpoint(checkpoint.id)">
                                  Dupliquer le point de contrôle
                                </a>

                                <a class="dropdown-item href" (click)="checkpoint.count_reports ? modalConfirmReport.open(checkpoint.id) : modalConfirmData.open(checkpoint.id)" *ngIf="canDelete">
                                  Supprimer le point de contrôle
                                </a>

                                <a *ngIf="view === 'establishment' && canLog" class="dropdown-item href" [routerLink]="[currentRoute + '/' + checkpoint.id + '/logs']">
                                  Historique des actions
                                </a>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <app-pagination (returnEvent)="dispatchPagination($event)"
                                    [count_elements]="count"
                                    [current_page]="page"
                                    [elements_length]="checkpoints.length"
                                    [limit]="limit"
                                    [total_pages]="pages"
                                    ngDefaultControl>
                    </app-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="loader-content" *ngIf="!loader && !checkpoints.length">
          <p>Aucun résultat</p>
        </div>
      </div>
    </div>
  </div>
</div>

<app-confirm-modal [type]="'delete_data'" #confirmData>{{ bindModalConfirmData(confirmData) }}</app-confirm-modal>
<app-confirm-modal [type]="'delete_with_report'" #confirmReport>{{ bindModalConfirmReport(confirmReport) }}</app-confirm-modal>

<router-outlet></router-outlet>
