import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})

export class PaginationComponent implements OnInit {
  @Input() count_elements: any;
  @Input() limit: any;
  @Input() current_page: any;
  @Input() total_pages: any;
  @Input() elements_length: any;
  @Input() classes: any;
  @Input() dropdown_items_by_page: any[] = [];
  @Output() returnEvent = new EventEmitter<any>();

  // Risks specific cases
  @Input() allRisks: boolean;
  @Input() allRisksCurrentItem: any;
  @Output() returnEventAllRisks = new EventEmitter<any>();

  constructor() {
    this.allRisks = false;
  }

  ngOnInit() {
    if (!this.dropdown_items_by_page.length) {
      let i = 12;
      while (i < 97) { // Fill the array with the default values [12, 24, 48, 96]
        this.dropdown_items_by_page.push(i);
        i *= 2;
      }
    }
  }

  returnAction(action: string, value: number) {
    this.returnEvent.emit({ action: action, value: value });
  }

  returnActionAllRisks(action: string, value: number, item: any) {
    this.returnEventAllRisks.emit({ action: action, value: value, item: item });
  }
}
