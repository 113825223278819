<div class="main-content">
  <div class="header bg-dark pb-5">
    <div class="container-fluid">
      <div class="header-body">
        <div class="row align-items-end">
          <div class="col">
            <h6 class="header-pretitle text-secondary">TABLEAU DE BORD</h6>

            <h1 class="header-title text-white">Statistiques Formations</h1>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid mt-n6">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- INPUTS -->
          <div class="card-header">
            <div class="row align-items-center">
              <!-- ESTABLISHMENTS -->
              <div class="col-6" *ngIf="establishments">
                <div class="form-group">
                  <label>Etablissements</label>
                  <ng-select
                    [items]="establishments"
                    [multiple]="true"
                    bindLabel="name"
                    notFoundText="Aucun résultat"
                    [selectableGroup]="true"
                    [closeOnSelect]="false"
                    bindValue="id"
                    placeholder="Sélectionnez un/des établissement(s)"
                    [(ngModel)]="selectedEstablishments"
                    (ngModelChange)="updateSelectedEstablishments()"
                    [ngModelOptions]="{ standalone: true }"
                  >
                    <ng-template
                      ng-option-tmp
                      let-item="item"
                      let-item$="item$"
                      let-index="index"
                    >
                      <input
                        type="checkbox"
                        [ngModel]="item$.selected"
                        [ngModelOptions]="{ standalone: true }"
                      />
                      {{ item.name }}
                    </ng-template>
                  </ng-select>
                </div>
              </div>

              <!-- REGISTRIES -->
              <div class="col-6" *ngIf="registries">
                <div class="form-group">
                  <label>Registres</label>
                  <ng-select
                    [items]="registries"
                    [multiple]="false"
                    bindLabel="name"
                    notFoundText="Aucun résultat"
                    [selectableGroup]="true"
                    [closeOnSelect]="true"
                    bindValue="id"
                    placeholder="Sélectionnez un registre"
                    [(ngModel)]="selectedRegistry"
                    (ngModelChange)="updateSelectedRegistry()"
                    [ngModelOptions]="{ standalone: true }"
                  >
                    <ng-template
                      ng-option-tmp
                      let-item="item"
                      let-item$="item$"
                      let-index="index"
                      >{{ item.name }}</ng-template
                    >
                  </ng-select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="row">
            <div class="col-6">
              <div class="card cursor" (click)="open('list_trainings')">
                <div class="card-body">
                  <div class="row align-items-center">
                    <div class="col">
                      <h6 class="card-title text-uppercase text-muted mb-2">
                        Nombre total de formations
                      </h6>
                      <span class="h2 mb-0">{{ nbTrainings }}</span>
                    </div>
                    <div class="col-auto">
                      <span class="h2 fe fe-bar-chart text-muted mb-0"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-6">
              <div class="card cursor" (click)="open('list_expired_trainings')">
                <div class="card-body">
                  <div class="row align-items-center">
                    <div class="col">
                      <h6 class="card-title text-uppercase text-muted mb-2">
                        Nombre total de formations en retard
                      </h6>
                      <span class="h2 mb-0">{{ nbTrainingsLate }}</span>
                    </div>
                    <div class="col-auto">
                      <span class="h2 fe fe-bar-chart text-muted mb-0"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div class="card cursor" (click)="open('list_employees_train')">
                <div class="card-body">
                  <div class="row align-items-center">
                    <div class="col">
                      <h6 class="card-title text-uppercase text-muted mb-2">
                        Nombre total de personnels formés
                      </h6>
                      <span class="h2 mb-0">{{ nbEmployeesTrain }}</span>
                    </div>
                    <div class="col-auto">
                      <span class="h2 fe fe-bar-chart text-muted mb-0"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-6">
              <div
                class="card cursor"
                (click)="open('list_employees_must_train')"
              >
                <div class="card-body">
                  <div class="row align-items-center">
                    <div class="col">
                      <h6 class="card-title text-uppercase text-muted mb-2">
                        Nombre total de personnels non formés et devant l'être
                      </h6>
                      <span class="h2 mb-0">{{ nbEmployeesMustTrain }}</span>
                    </div>
                    <div class="col-auto">
                      <span class="h2 fe fe-bar-chart text-muted mb-0"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">
                <h4 class="card-header-title">
                  Nombre de personnes par formations
                </h4>
              </div>
            </div>
          </div>
          <!-- EMPLOYEES BY TRAINNINGS -->
          <div class="card-body">
            <div class="chart">
              <div class="is-loading" *ngIf="loaderEmployeesByTrainings"></div>
              <canvas
                [hidden]="loaderEmployeesByTrainings"
                id="NbEmployeesByTrainings"
                class="chart-canvas"
              ></canvas>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">
                <h4 class="card-header-title">
                  Nombre de personnes formées par établissement
                </h4>
              </div>
            </div>
          </div>
          <!-- EMPLOYEES TRAIN BY ESTABLISHMENT -->
          <div class="card-body">
            <div class="chart">
              <div
                class="is-loading"
                *ngIf="loaderEmployeesByEstablishments"
              ></div>
              <canvas
                [hidden]="loaderEmployeesByEstablishments"
                id="NbEmployeesByEstablishmentChart"
                class="chart-canvas"
              ></canvas>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="list_trainings"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
>
  <div class="modal-dialog training" role="document">
    <div class="modal-content modal-training">
      <div class="modal-training-header">
        <h4 class="modal-title">Liste des formations</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="is-loading loader-modal-content p-5" *ngIf="loader"></div>
        <div
          class="table-responsive table-trainings"
          *ngIf="trainings.length && !loader"
        >
          <table class="table table-striped">
            <thead>
              <tr>
                <th class="href" (click)="filterTrainings('Trainings.name')">
                  <a class="text-muted">Nom</a>
                  <span
                    class="fe fe-arrow-down"
                    *ngIf="
                      sortTraininigs === 'Trainings.name' &&
                      orderTraininigs === 'asc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-up"
                    *ngIf="
                      sortTraininigs === 'Trainings.name' &&
                      orderTraininigs === 'desc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-right"
                    *ngIf="sortTraininigs !== 'Trainings.name'"
                  ></span>
                </th>

                <th
                  class="href"
                  (click)="filterTrainings('Trainings.frequency')"
                >
                  <a class="text-muted">Périodicité</a>
                  <span
                    class="fe fe-arrow-down"
                    *ngIf="
                      sortTraininigs === 'Trainings.frequency' &&
                      orderTraininigs === 'asc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-up"
                    *ngIf="
                      sortTraininigs === 'Trainings.frequency' &&
                      orderTraininigs === 'desc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-right"
                    *ngIf="sortTraininigs !== 'Trainings.frequency'"
                  ></span>
                </th>

                <th
                  class="href"
                  (click)="filterTrainings('Trainings.establishment_id')"
                >
                  <a class="text-muted">Établissement</a>
                  <span
                    class="fe fe-arrow-down"
                    *ngIf="
                      sortTraininigs === 'Trainings.establishment_id' &&
                      orderTraininigs === 'asc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-up"
                    *ngIf="
                      sortTraininigs === 'Trainings.establishment_id' &&
                      orderTraininigs === 'desc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-right"
                    *ngIf="sortTraininigs !== 'Trainings.establishment_id'"
                  ></span>
                </th>

                <th
                  class="href"
                  (click)="filterTrainings('Trainings.registry_id')"
                >
                  <a class="text-muted">Registre</a>
                  <span
                    class="fe fe-arrow-down"
                    *ngIf="
                      sortTraininigs === 'Trainings.registry_id' &&
                      orderTraininigs === 'asc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-up"
                    *ngIf="
                      sortTraininigs === 'Trainings.registry_id' &&
                      orderTraininigs === 'desc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-right"
                    *ngIf="sortTraininigs !== 'Trainings.registry_id'"
                  ></span>
                </th>

                <th
                  class="href"
                  (click)="filterTrainings('Trainings.priority')"
                >
                  <a class="text-muted">Status</a>
                  <span
                    class="fe fe-arrow-down"
                    *ngIf="
                      sortTraininigs === 'Trainings.priority' &&
                      orderTraininigs === 'asc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-up"
                    *ngIf="
                      sortTraininigs === 'Trainings.priority' &&
                      orderTraininigs === 'desc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-right"
                    *ngIf="sortTraininigs !== 'Trainings.priority'"
                  ></span>
                </th>

                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr class="dropdown" *ngFor="let training of trainings">
                <td>
                  {{ training.name }}
                </td>
                <td>
                  {{
                    training.frequency && training.frequency_duration
                      ? training.frequency_duration + " " + training.frequency
                      : "Aucune"
                  }}
                </td>
                <td>
                  {{
                    training.establishment ? training.establishment.name : ""
                  }}
                </td>
                <td>{{ training.registry.name }}</td>
                <td>
                  <span
                    class="label label-rounded"
                    [ngClass]="
                      training.priority
                        ? 'label-important'
                        : 'label-not-important'
                    "
                    [attr.aria-label]="
                      training.priority
                        ? 'La formation est marquée comme prioritaire'
                        : 'La formation est marquée comme non prioritaire'
                    "
                  >
                    {{ training.priority ? "Obligatoire" : "Recommandée" }}
                  </span>
                </td>
                <td class="d-flex flex-row-reverse">
                  <a
                    class="dropdown-ellipses dropdown-toggle href"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fe fe-more-vertical"></i>
                  </a>

                  <div class="dropdown-menu dropdown-menu-right">
                    <a
                      class="dropdown-item href"
                      (click)="getUrl('list_trainings')"
                    >
                      lien vers les formations
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <app-pagination
            (returnEvent)="dispatchPaginationTrainings($event)"
            [count_elements]="countTraininigs"
            [current_page]="pageTraininigs"
            [elements_length]="trainings.length"
            [limit]="limitTraininigs"
            [total_pages]="pagesTraininigs"
            ngDefaultControl
          >
          </app-pagination>
        </div>
        <div
          class="table-responsive table-trainings"
          *ngIf="!trainings.length && !loader"
        >
          Aucun résultat
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="list_expired_trainings"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
>
  <div class="modal-dialog training" role="document">
    <div class="modal-content modal-training">
      <div class="modal-training-header">
        <h4 class="modal-title">Liste des formations en retard</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="is-loading loader-modal-content p-5" *ngIf="loader"></div>
        <div
          class="table-responsive table-trainings"
          *ngIf="expiredTrainings.length && !loader"
        >
          <table class="table table-striped">
            <thead>
              <tr>
                <th
                  class="href"
                  (click)="filterExpiredTrainings('Trainings.name')"
                >
                  <a class="text-muted">Nom</a>
                  <span
                    class="fe fe-arrow-down"
                    *ngIf="
                      sortExpiredTraininigs === 'Trainings.name' &&
                      orderExpiredTraininigs === 'asc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-up"
                    *ngIf="
                      sortExpiredTraininigs === 'Trainings.name' &&
                      orderExpiredTraininigs === 'desc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-right"
                    *ngIf="sortExpiredTraininigs !== 'Trainings.name'"
                  ></span>
                </th>

                <th
                  class="href"
                  (click)="filterExpiredTrainings('Trainings.frequency')"
                >
                  <a class="text-muted">Périodicité</a>
                  <span
                    class="fe fe-arrow-down"
                    *ngIf="
                      sortExpiredTraininigs === 'Trainings.frequency' &&
                      orderExpiredTraininigs === 'asc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-up"
                    *ngIf="
                      sortExpiredTraininigs === 'Trainings.frequency' &&
                      orderExpiredTraininigs === 'desc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-right"
                    *ngIf="sortExpiredTraininigs !== 'Trainings.frequency'"
                  ></span>
                </th>

                <th
                  class="href"
                  (click)="filterExpiredTrainings('Trainings.establishment_id')"
                >
                  <a class="text-muted">Établissement</a>
                  <span
                    class="fe fe-arrow-down"
                    *ngIf="
                      sortExpiredTraininigs === 'Trainings.establishment_id' &&
                      orderExpiredTraininigs === 'asc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-up"
                    *ngIf="
                      sortExpiredTraininigs === 'Trainings.establishment_id' &&
                      orderExpiredTraininigs === 'desc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-right"
                    *ngIf="
                      sortExpiredTraininigs !== 'Trainings.establishment_id'
                    "
                  ></span>
                </th>

                <th
                  class="href"
                  (click)="filterExpiredTrainings('Trainings.registry_id')"
                >
                  <a class="text-muted">Registre</a>
                  <span
                    class="fe fe-arrow-down"
                    *ngIf="
                      sortExpiredTraininigs === 'Trainings.registry_id' &&
                      orderExpiredTraininigs === 'asc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-up"
                    *ngIf="
                      sortExpiredTraininigs === 'Trainings.registry_id' &&
                      orderExpiredTraininigs === 'desc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-right"
                    *ngIf="sortExpiredTraininigs !== 'Trainings.registry_id'"
                  ></span>
                </th>

                <th
                  class="href"
                  (click)="filterExpiredTrainings('Trainings.priority')"
                >
                  <a class="text-muted">Status</a>
                  <span
                    class="fe fe-arrow-down"
                    *ngIf="
                      sortExpiredTraininigs === 'Trainings.priority' &&
                      orderExpiredTraininigs === 'asc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-up"
                    *ngIf="
                      sortExpiredTraininigs === 'Trainings.priority' &&
                      orderExpiredTraininigs === 'desc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-right"
                    *ngIf="sortExpiredTraininigs !== 'Trainings.priority'"
                  ></span>
                </th>

                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr class="dropdown" *ngFor="let train of expiredTrainings">
                <td>
                  {{ train.training.name }}
                </td>
                <td>
                  {{ train.date | date: "dd/MM/yyyy" }}
                </td>
                <td>{{ train.training.establishment.name }}</td>
                <td>{{ train.training.registry.name }}</td>
                <td>
                  <span
                    class="label label-rounded"
                    [ngClass]="
                      train.training.priority
                        ? 'label-important'
                        : 'label-not-important'
                    "
                    [attr.aria-label]="
                      train.training.priority
                        ? 'La formation est marquée comme prioritaire'
                        : 'La formation est marquée comme non prioritaire'
                    "
                  >
                    {{
                      train.training.priority ? "Obligatoire" : "Recommandée"
                    }}
                  </span>
                </td>
                <td class="d-flex flex-row-reverse">
                  <a
                    class="dropdown-ellipses dropdown-toggle href"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fe fe-more-vertical"></i>
                  </a>

                  <div class="dropdown-menu dropdown-menu-right">
                    <a
                      class="dropdown-item href"
                      (click)="getUrl('list_expired_trainings')"
                    >
                      lien vers les formations
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <app-pagination
            (returnEvent)="dispatchPaginationExpiredTrainings($event)"
            [count_elements]="countExpiredTraininigs"
            [current_page]="pageExpiredTraininigs"
            [elements_length]="expiredTrainings.length"
            [limit]="limitExpiredTraininigs"
            [total_pages]="pagesExpiredTraininigs"
            ngDefaultControl
          >
          </app-pagination>
        </div>
        <div
          class="table-responsive table-trainings"
          *ngIf="!expiredTrainings.length && !loader"
        >
          Aucun résultat
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="list_employees_train"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
>
  <div class="modal-dialog training" role="document">
    <div class="modal-content modal-training">
      <div class="modal-training-header">
        <h4 class="modal-title">Liste des employees formées</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="is-loading loader-modal-content p-5" *ngIf="loader"></div>
        <div
          class="table-responsive table-trainings"
          *ngIf="employeesTrain.length && !loader"
        >
          <table class="table table-striped">
            <thead>
              <tr>
                <th
                  class="href"
                  (click)="filterEmployeesTrain('EmployeeTrainings.firstname')"
                >
                  <a class="text-muted">Prénom</a>
                  <span
                    class="fe fe-arrow-down"
                    *ngIf="
                      sortEmployeesTrain === 'EmployeeTrainings.firstname' &&
                      orderEmployeesTrain === 'asc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-up"
                    *ngIf="
                      sortEmployeesTrain === 'EmployeeTrainings.firstname' &&
                      orderEmployeesTrain === 'desc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-right"
                    *ngIf="sortEmployeesTrain !== 'EmployeeTrainings.firstname'"
                  ></span>
                </th>
                <th
                  class="href"
                  (click)="filterEmployeesTrain('EmployeeTrainings.lastname')"
                >
                  <a class="text-muted">Nom</a>
                  <span
                    class="fe fe-arrow-down"
                    *ngIf="
                      sortEmployeesTrain === 'EmployeeTrainings.lastname' &&
                      orderEmployeesTrain === 'asc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-up"
                    *ngIf="
                      sortEmployeesTrain === 'EmployeeTrainings.lastname' &&
                      orderEmployeesTrain === 'desc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-right"
                    *ngIf="sortEmployeesTrain !== 'EmployeeTrainings.lastname'"
                  ></span>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr class="dropdown" *ngFor="let employee of employeesTrain">
                <td>
                  {{ employee.employee_training.firstname }}
                </td>
                <td>
                  {{ employee.employee_training.lastname }}
                </td>
                <td class="d-flex flex-row-reverse">
                  <a
                    class="dropdown-ellipses dropdown-toggle href"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fe fe-more-vertical"></i>
                  </a>

                  <div class="dropdown-menu dropdown-menu-right">
                    <a
                      class="dropdown-item href"
                      (click)="getUrl('list_employees_train')"
                    >
                      lien vers la liste personnel
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <app-pagination
            (returnEvent)="dispatchPaginationEmployeesTrain($event)"
            [count_elements]="countEmployeesTrain"
            [current_page]="pageEmployeesTrain"
            [elements_length]="employeesTrain.length"
            [limit]="limitEmployeesTrain"
            [total_pages]="pagesEmployeesTrain"
            ngDefaultControl
          >
          </app-pagination>
        </div>
        <div
          class="table-responsive table-trainings"
          *ngIf="!employeesTrain.length && !loader"
        >
          Aucun résultat
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="list_employees_must_train"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
>
  <div class="modal-dialog training" role="document">
    <div class="modal-content modal-training">
      <div class="modal-training-header">
        <h4 class="modal-title">
          Liste des employees non formées et devant l'être
        </h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="is-loading loader-modal-content p-5" *ngIf="loader"></div>
        <div
          class="table-responsive table-trainings"
          *ngIf="employeesMustTrain.length && !loader"
        >
          <table class="table table-striped">
            <thead>
              <tr>
                <th
                  class="href"
                  (click)="
                    filterEmployeesMustTrain('EmployeeTrainings.firstname')
                  "
                >
                  <a class="text-muted">Prénom</a>
                  <span
                    class="fe fe-arrow-down"
                    *ngIf="
                      sortEmployeesMustTrain ===
                        'EmployeeTrainings.firstname' &&
                      orderEmployeesMustTrain === 'asc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-up"
                    *ngIf="
                      sortEmployeesMustTrain ===
                        'EmployeeTrainings.firstname' &&
                      orderEmployeesMustTrain === 'desc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-right"
                    *ngIf="
                      sortEmployeesMustTrain !== 'EmployeeTrainings.firstname'
                    "
                  ></span>
                </th>
                <th
                  class="href"
                  (click)="
                    filterEmployeesMustTrain('EmployeeTrainings.lastname')
                  "
                >
                  <a class="text-muted">Nom</a>
                  <span
                    class="fe fe-arrow-down"
                    *ngIf="
                      sortEmployeesMustTrain === 'EmployeeTrainings.lastname' &&
                      orderEmployeesMustTrain === 'asc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-up"
                    *ngIf="
                      sortEmployeesMustTrain === 'EmployeeTrainings.lastname' &&
                      orderEmployeesMustTrain === 'desc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-right"
                    *ngIf="
                      sortEmployeesMustTrain !== 'EmployeeTrainings.lastname'
                    "
                  ></span>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr class="dropdown" *ngFor="let employee of employeesMustTrain">
                <td>
                  {{ employee.employee_training.firstname }}
                </td>
                <td>
                  {{ employee.employee_training.lastname }}
                </td>
                <td class="d-flex flex-row-reverse">
                  <a
                    class="dropdown-ellipses dropdown-toggle href"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fe fe-more-vertical"></i>
                  </a>

                  <div class="dropdown-menu dropdown-menu-right">
                    <a
                      class="dropdown-item href"
                      (click)="getUrl('list_employees_must_train')"
                    >
                      lien vers la liste personnel
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <app-pagination
            (returnEvent)="dispatchPaginationEmployeesMustTrain($event)"
            [count_elements]="countEmployeesMustTrain"
            [current_page]="pageEmployeesMustTrain"
            [elements_length]="employeesMustTrain.length"
            [limit]="limitEmployeesMustTrain"
            [total_pages]="pagesEmployeesMustTrain"
            ngDefaultControl
          >
          </app-pagination>
        </div>
        <div
          class="table-responsive table-trainings"
          *ngIf="!employeesMustTrain.length && !loader"
        >
          Aucun résultat
        </div>
      </div>
    </div>
  </div>
</div>
