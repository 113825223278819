<app-modal-right (eventOnClose)="closeModal()" #ssoConfig>{{ binModal(ssoConfig) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6
          class="header-pretitle">{{ isNew ? 'Créer une authentification unique' : canUpdate ? 'Mettre à jour une authentification unique' : 'Consulter une authentification unique' }}</h6>

        <h1 class="header-title">Authentification unique</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <form [formGroup]="ssoForm" [hidden]="loaderContent">
      <div class="row">
        <div class="col-12">

          <div class="form-group">
            <label for="validationName" required>Nom</label>
            <input type="text" formControlName="name" id="validationName" class="form-control" placeholder="Nom"
                   [ngClass]="{ 'is-invalid': name.invalid && (name.dirty || name.touched) }">
            <div *ngIf="name.invalid && (name.dirty || name.touched)" class="invalid-feedback">
              {{ getErrorMessage("name") }}
            </div>
          </div>

          <div class="form-group">
            <label required>Entreprise</label>
            <ng-select [items]="enterprises"
                       [multiple]="false"
                       bindLabel="name"
                       notFoundText="Aucun résultat"
                       [selectableGroup]="true"
                       [closeOnSelect]="false"
                       bindValue="id"
                       placeholder="Sélectionnez une entreprise"
                       [(ngModel)]="selectedEnterprise" (ngModelChange)="getUsers(selectedEnterprise)" [ngModelOptions]="{ standalone: true }">
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <input type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }" /> {{ item.name }}
              </ng-template>
            </ng-select>
          </div>

          <div class="form-group" *ngIf="!loader && selectedEnterprise">
            <label for="listUsers">Connection via Authentification unique</label>
            <ng-select [items]="users"
                       [multiple]="true"
                       bindLabel="full_name"
                       bindValue="id"
                       notFoundText="Aucun résultat"
                       [selectableGroup]="true"
                       [selectableGroupAsModel]="false"
                       [closeOnSelect]="false"
                       placeholder="Sélectionnez un/ou plusieurs utilisateur(s)"
                       [(ngModel)]="selectedUsers" [ngModelOptions]="{ standalone: true }">
              <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                <input type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }"/> Sélectionnez tout
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index" class="overflow-hidden">
                <div class="text-truncate">
                  <input id="listUsers" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }" /> {{ item.full_name }}
                </div>
              </ng-template>
            </ng-select>
          </div>

          <div class="form-group">
            <label for="validationIdpName" required>Identifiant de l’IdP</label>
            <input type="text" formControlName="idp_name" id="validationIdpName" class="form-control" placeholder="Identifiant de l’IdP "
                   [ngClass]="{ 'is-invalid': idp_name.invalid && (idp_name.dirty || idp_name.touched) }">
            <div *ngIf="idp_name.invalid && (idp_name.dirty || idp_name.touched)" class="invalid-feedback">
              {{ getErrorMessage("idp_name") }}
            </div>
          </div>

          <div class="form-group">
            <label for="validationClientId" required>Client ID</label>
            <input type="text" formControlName="client_id" id="validationClientId" class="form-control" placeholder="Client ID"
                   [ngClass]="{ 'is-invalid': client_id.invalid && (client_id.dirty || client_id.touched) }">
            <div *ngIf="client_id.invalid && (client_id.dirty || client_id.touched)" class="invalid-feedback">
              {{ getErrorMessage("client_id") }}
            </div>
          </div>

          <div class="form-group">
            <label for="validationClientSecret" required>Client Secret</label>
            <input type="text" formControlName="client_secret" id="validationClientSecret" class="form-control" placeholder="Client secret"
                   [ngClass]="{ 'is-invalid': client_secret.invalid && (client_secret.dirty || client_secret.touched) }">
            <div *ngIf="client_secret.invalid && (client_secret.dirty || client_secret.touched)" class="invalid-feedback">
              {{ getErrorMessage("client_secret") }}
            </div>
          </div>

          <div class="form-group row align-items-center">
            <label for="validationRedirectUri" class="col-12" required>URL de redirection</label>
            <p class="col-6 mb-0">{{ environment.ERP_HOST + '/auth/login/'}}</p>
            <input type="text" formControlName="redirect_uri" id="validationRedirectUri" class="form-control col-6" placeholder="URI de redirection"
                   [ngClass]="{ 'is-invalid': redirect_uri.invalid && (redirect_uri.dirty || redirect_uri.touched) }">
            <div *ngIf="redirect_uri.invalid && (redirect_uri.dirty || redirect_uri.touched)" class="invalid-feedback">
              {{ getErrorMessage("redirect_uri") }}
            </div>
          </div>


          <div class="form-group">
            <label for="validationAuthorizeUri" required>Point de terminaison d'autorisation OAuth 2.0</label>
            <input type="text" formControlName="authorize_uri" id="validationAuthorizeUri" class="form-control" placeholder="Point de terminaison d'autorisation OAuth 2.0"
                   [ngClass]="{ 'is-invalid': authorize_uri.invalid && (authorize_uri.dirty || authorize_uri.touched) }">
            <div *ngIf="authorize_uri.invalid && (authorize_uri.dirty || authorize_uri.touched)" class="invalid-feedback">
              {{ getErrorMessage("authorize_uri") }}
            </div>
          </div>

          <div class="form-group">
            <label for="validationTokenUri" required>Point de terminaison de jeton OAuth 2.0</label>
            <input type="text" formControlName="token_uri" id="validationTokenUri" class="form-control" placeholder="Point de terminaison de jeton OAuth 2.0"
                   [ngClass]="{ 'is-invalid': token_uri.invalid && (token_uri.dirty || token_uri.touched) }">
            <div *ngIf="token_uri.invalid && (token_uri.dirty || token_uri.touched)" class="invalid-feedback">
              {{ getErrorMessage("token_uri") }}
            </div>
          </div>

          <div class="form-group">
            <label for="validationUserUri" required>Point de terminaison de l'utilisateur OAuth 2.0</label>
            <input type="text" formControlName="user_uri" id="validationUserUri" class="form-control" placeholder="Point de terminaison de l'utilisateur OAuth 2.0"
                   [ngClass]="{ 'is-invalid': user_uri.invalid && (user_uri.dirty || user_uri.touched) }">
            <div *ngIf="user_uri.invalid && (user_uri.dirty || user_uri.touched)" class="invalid-feedback">
              {{ getErrorMessage("user_uri") }}
            </div>
          </div>

          <div class="form-group">
            <label for="validationLogout">URL de déconnexion</label>
            <textarea type="text" formControlName="logout_uri" id="validationLogout" class="form-control" placeholder="Url de déconnexion"
                   [ngClass]="{ 'is-invalid': logout_uri.invalid && (logout_uri.dirty || logout_uri.touched) }">
            </textarea>
            <div *ngIf="logout_uri.invalid && (logout_uri.dirty || logout_uri.touched)" class="invalid-feedback">
              {{ getErrorMessage("logout_uri") }}
            </div>
          </div>

          <div class="form-group">
            <label required>Récupération de l'utilisateur via :</label>
            <ng-select [items]="tokenLists"
                       [multiple]="false"
                       bindLabel="name"
                       bindValue="name"
                       notFoundText="Aucun résultat"
                       [selectableGroup]="true"
                       [selectableGroupAsModel]="false"
                       [closeOnSelect]="false"
                       placeholder="Sélectionnez une valeur"
                       formControlName="token_user">
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <input type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }" /> {{ item.name }}
              </ng-template>
            </ng-select>
          </div>

          <div class="form-group">
            <label for="validationKey" required>Clef primaire du token (clef de récupération de l'email de l'utilisateur du token)</label>
            <input type="text" formControlName="token_key" id="validationKey" class="form-control" placeholder="Clef primaire du token"
                   [ngClass]="{ 'is-invalid': token_key.invalid && (token_key.dirty || token_key.touched) }">
            <div *ngIf="token_key.invalid && (token_key.dirty || token_key.touched)" class="invalid-feedback">
              {{ getErrorMessage("token_key") }}
            </div>
          </div>

          <div class="form-group" [hidden]="!isNew && !canUpdate || picture.value">
            <label for="inputFile" class="mb-1">Photo Authentification unique</label>

            <small class="form-text text-muted">
              Veuillez utiliser une image ne dépassant pas 200px * 200px.
            </small>

            <div class="dropzone dropzone-multiple dz-clickable" appDragDrop (dropHandler)="onFileChange($event)">
              <div class="dz-default dz-message" (click)="chooseFile()">
                <span>Déposer votre fichier ici pour télécharger</span>
              </div>
              <input id="inputFile" (change)="onFileChange($event)" type="file" accept="image/*" [hidden]="true">
            </div>
          </div>

          <div class="form-group" *ngIf="picture.value">
            <label class="mb-1">Photo Authentification unique</label>

            <small class="form-text text-muted">
              Veuillez utiliser une image ne dépassant pas 200px * 200px.
            </small>

            <div class="card">
              <div class="card-body">
                <div class="d-flex align-items-center justify-content-between">
                  <div class="avatar avatar-lg avatar-4by3">
                    <img src="{{ checkPicture() }}" alt="Photo Authentification unique" class="avatar-img rounded">
                  </div>

                  <a class="href tooltipHtml" data-placement="bottom" title="Supprimer" (click)="removeFile()" *ngIf="isNew || canUpdate">
                    <i class="fe fe-trash"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </form>
  </div>

  <div modal-footer class="w-100" *ngIf="isNew || canUpdate">
    <button class="btn btn-primary w-100" (click)="isNew ? createSsoConfig() : updateSsoConfig()" [ngClass]="{ 'is-loading is-loading-sm': loader }" [disabled]="loader || ssoForm.invalid">
      {{ isNew ? 'Créer' : 'Mettre à jour'}}
    </button>
  </div>
</app-modal-right>
