<app-modal-right (eventOnClose)="closeModal()" #employee>{{ bindModal(employee) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">{{ isNew ? 'Création' : canUpdate ? 'Mise à jour' : 'Consultation' }}</h6>

        <h1 class="header-title">Membre du personnel</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <form [formGroup]="employeeForm" [hidden]="loaderContent">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="validationFirstname" required>Prénom</label>
            <input type="text" formControlName="firstname" class="form-control" id="validationFirstname" placeholder="Prénom" [ngClass]="{ 'is-invalid': firstname.invalid && (firstname.dirty || firstname.touched) }">
            <div *ngIf="firstname.invalid && (firstname.dirty || firstname.touched)" class="invalid-feedback">
              {{ getErrorMessage("firstname") }}
            </div>
          </div>

          <div class="form-group">
            <label for="validationLastname" required>Nom</label>
            <input type="text" formControlName="lastname" class="form-control" id="validationLastname" placeholder="Nom" [ngClass]="{ 'is-invalid': lastname.invalid && (lastname.dirty || lastname.touched) }">
            <div *ngIf="lastname.invalid && (lastname.dirty || lastname.touched)" class="invalid-feedback">
              {{ getErrorMessage("lastname") }}
            </div>
          </div>

          <div class="form-group">
            <label for="validationYearEntryService">Année d'entrée dans le service</label>
            <input type="number" formControlName="year_entry_service" minlength="4" maxlength="4" min="1900" max="2100" class="form-control" id="validationYearEntryService" placeholder="Année d'entrée dans le service" [ngClass]="{ 'is-invalid': year_entry_service.invalid && (year_entry_service.dirty || year_entry_service.touched) }">
            <div *ngIf="year_entry_service.invalid && (year_entry_service.dirty || year_entry_service.touched)" class="invalid-feedback">
              {{ getErrorMessage("year_entry_service") }}
            </div>
          </div>

          <div class="form-group">
            <label for="validationRole">Fonction</label>
            <input type="text" formControlName="role" class="form-control" id="validationRole" placeholder="Fonction" [ngClass]="{ 'is-invalid': role.invalid && (role.dirty || role.touched) }">
            <div *ngIf="role.invalid && (role.dirty || role.touched)" class="invalid-feedback">
              {{ getErrorMessage("function") }}
            </div>
          </div>

          <div class="form-group">
            <label for="validationQualification" required>Qualification / Formation</label>
            <input type="text" formControlName="qualification" class="form-control" id="validationQualification" placeholder="Qualification / Formation" [ngClass]="{ 'is-invalid': qualification.invalid && (qualification.dirty || qualification.touched) }">
            <div *ngIf="qualification.invalid && (qualification.dirty || qualification.touched)" class="invalid-feedback">
              {{ getErrorMessage("qualification") }}
            </div>
          </div>

          <div class="form-group">
            <label for="validationObservation">Observation(s)</label>
            <textarea formControlName="observation" class="form-control" id="validationObservation" placeholder="Observation(s)" rows="5"></textarea>
            <div *ngIf="observation.invalid && (observation.dirty || observation.touched)" class="invalid-feedback">
              {{ getErrorMessage("observation") }}
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div modal-footer class="w-100" *ngIf="isNew || canUpdate">
    <button class="btn btn-primary w-100" (click)="isNew ? createEmployee() : updateEmployee()" [ngClass]="{ 'is-loading is-loading-sm': loader }" [disabled]="loader || employeeForm.invalid">
      {{ isNew ? 'Créer' : 'Mettre à jour' }}
    </button>
  </div>
</app-modal-right>
