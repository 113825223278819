import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

import { UpdateOfficialPrescriptionStateComponent } from './update-official-prescription-state.component';
import { ModalRightModule } from '../../../core/modal-right/modal-right.module';
import { UtilsModule } from '../../../core/utils/utils.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    ModalRightModule,
    NgSelectModule,
    UtilsModule
  ],
  declarations: [UpdateOfficialPrescriptionStateComponent]
})

export class UpdateOfficialPrescriptionStateModule {}
