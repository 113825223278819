<div class="main-content">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col">
        <div class="header mb-0">
          <div class="header-body no-border">
            <div class="row align-items-center">
              <div class="col">
                <h1 class="header-title">
                  Tickets
                </h1>

                <small class="form-text text-muted">
                  gestion des tickets
                </small>

                <app-breadcrumbs [currentPage]="pageName" [names]="previousPageName" [routes]="previousPageRoute"></app-breadcrumbs>
              </div>

              <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0" *ngIf="view === 'client'">
                <a class="btn btn-primary d-block d-md-inline-block" [routerLink]="[currentRoute + 'create']">
                  Créer un ticket
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col">
            <app-searchbar [(ngModel)]="search" (keyup)="searchData()" ngDefaultControl></app-searchbar>
          </div>

          <div class="col-auto" *ngIf="view === 'intervention_files'">
            <button class="btn btn-lg btn-white tooltipHtml" aria-label="Afficher les filtres disponibles"  data-toggle="dropdown" data-placement="bottom" title="Filtre">
              <span class="fe fe-filter"></span>
            </button>

            <span class="dropdown-menu dropdown-menu-right">
              <a class="dropdown-item href" (click)="refreshData('InterventionVoucherFiles.name', 'asc', 1)" aria-label="Tri des interventions : Nom - Ordre croissant">
                Nom A-Z
              </a>

              <a class="dropdown-item href" (click)="refreshData('InterventionVoucherFiles.name', 'desc', 1)" aria-label="Tri des interventions : Nom - Ordre décroissant">
                Nom Z-A
              </a>
            </span>
          </div>
        </div>

        <div class="is-loading loader-content" *ngIf="loader"></div>

        <div class="tab-content" *ngIf="!loader && issues.length">
          <div class="tab-pane fade active show" id="tabPaneOne" role="tabpanel">
            <div class="row listAlias">
              <div class="col-12 d-flex flex-column">
                <div class="card flex-fill">
                  <div class="card-body">
                    <div class="table-responsive">
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th>Référence</th>
                            <th class="href" (click)="filter('IssueCategories.name')">
                              <a class="text-muted">Catégorie</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'IssueCategories.name' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'IssueCategories.name' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'IssueCategories.name'"></span>
                            </th>
                            <th class="href" (click)="filter('Issues.emergency')">
                              <a class="text-muted">Priorité</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'Issues.emergency' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'Issues.emergency' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'Issues.emergency'"></span>
                            </th>
                            <th class="href" (click)="filter('IssueStates.order_number')">
                              <a class="text-muted">État</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'IssueStates.order_number' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'IssueStates.order_number' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'IssueStates.order_number'"></span>
                            </th>
                            <th *ngIf="view === 'admin'">Entreprise</th>
                            <th class="href" (click)="filter('Issues.content')">
                              <a class="text-muted">Objet</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'Issues.content' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'Issues.content' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'Issues.content'"></span>
                            </th>
                            <th class="href" (click)="filter('Issues.created')">
                              <a class="text-muted">Créé le</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'Issues.created' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'Issues.created' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'Issues.created'"></span>
                            </th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let issue of issues">
                            <td>{{ getRef(issue.id) }}</td>
                            <td><span [className]="'label-rounded ' + issue.issue_category.label">{{ issue.issue_category.name }}</span></td>
                            <td>{{ issue.emergency }}</td>
                            <td><span [className]="'label-rounded ' + issue.issue_state.label">{{ issue.issue_state.name }}</span></td>
                            <td *ngIf="view === 'admin'">{{ issue.enterprise.name }}</td>
                            <td><a [routerLink]="[issue.id]">{{ issue.content }}</a></td>
                            <td>{{ issue.created | date : 'dd/MM/yyyy' }}</td>
                            <td>
                              <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fe fe-more-vertical"></i>
                              </a>

                              <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item href" [routerLink]="[issue.id]">
                                 Consulter le ticket
                                </a>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <app-pagination (returnEvent)="dispatchPagination($event)"
                                    [count_elements]="count"
                                    [current_page]="page"
                                    [elements_length]="issues.length"
                                    [limit]="limit"
                                    [total_pages]="pages"
                                    ngDefaultControl>
                    </app-pagination>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="loader-content" *ngIf="!loader && !issues.length">
          <p>Aucun résultat</p>
        </div>
      </div>
    </div>
  </div>
</div>

<router-outlet></router-outlet>
