<app-modal-right (eventOnClose)="closeModal()" #firePermit>{{ bindModal(firePermit) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">{{ isNew ? 'Création' : canUpdate ? 'Mise à jour' : 'Consultation' }}</h6>

        <h1 class="header-title">Permis feu</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <form [formGroup]="firePermitForm" [hidden]="loaderContent">
      <div class="row">
        <div class="col-12">

          <!-- REFERENCY -->
          <div class="form-group">
            <label for="validationReferency">Référence</label>
            <input type="text" formControlName="referency" class="form-control" id="validationReferency" placeholder="Référence" [ngClass]="{ 'is-invalid': referency.invalid && (referency.dirty || referency.touched) }">
            <div *ngIf="referency.invalid && (referency.dirty || referency.touched)" class="invalid-feedback">
              {{ getErrorMessage("referency") }}
            </div>
          </div>

          <!-- BEGIN DATE -->
          <div class="form-group">
            <label for="validationStartDate" required>Date de début</label>
            <input type="text" formControlName="begin_date" class="form-control" id="validationStartDate" placeholder="Date de début" data-toggle="flatpickr_datetime" [ngClass]="{ 'is-invalid': begin_date.invalid && (begin_date.dirty || begin_date.touched) }">
            <div *ngIf="begin_date.invalid && (begin_date.dirty || begin_date.touched)" class="invalid-feedback d-block">
              {{ getErrorMessage("begin_date") }}
            </div>
          </div>

          <!-- END DATE -->
          <div class="form-group">
            <label for="validationEndDate" required>Date de fin</label>
            <input type="text" formControlName="end_date" class="form-control" id="validationEndDate" placeholder="Date de fin" data-toggle="flatpickr_datetime" [ngClass]="{ 'is-invalid': end_date.invalid && (end_date.dirty || end_date.touched) }">
            <div *ngIf="end_date.invalid && (end_date.dirty || end_date.touched)" class="invalid-feedback d-block">
              {{ getErrorMessage("end_date") }}
            </div>
          </div>

          <!-- PLACE -->
          <div class="form-group">
            <label for="validationPlace" required>Lieu de l'opération</label>
            <input type="text" formControlName="place" class="form-control" id="validationPlace" placeholder="Lieu de l'opération" [ngClass]="{ 'is-invalid': place.invalid && (place.dirty || place.touched) }">
            <div *ngIf="place.invalid && (place.dirty || place.touched)" class="invalid-feedback">
              {{ getErrorMessage("place") }}
            </div>
          </div>

          <!-- DETAILS -->
          <div class="form-group">
            <label for="validationDetails" required>Précisions</label>
            <input type="text" formControlName="details" class="form-control" id="validationDetails" placeholder="Précisions" [ngClass]="{ 'is-invalid': details.invalid && (details.dirty || details.touched) }">
            <div *ngIf="details.invalid && (details.dirty || details.touched)" class="invalid-feedback">
              {{ getErrorMessage("details") }}
            </div>
          </div>

          <!-- NATURE -->
          <div class="form-group">
            <label for="validationNature" required>Nature du travail (soudage, tronçonnage, découpage, etc)</label>
            <textarea type="text" formControlName="nature" class="form-control" id="validationNature" placeholder="Nature du travail (soudage, tronçonnage, découpage, etc)" [ngClass]="{ 'is-invalid': nature.invalid && (nature.dirty || nature.touched) }"></textarea>
            <div *ngIf="nature.invalid && (nature.dirty || nature.touched)" class="invalid-feedback">
              {{ getErrorMessage("nature") }}
            </div>
          </div>

          <!-- TOOLS -->
          <div class="form-group">
            <label for="validationTools" required>Outil, matériel utilisé</label>
            <textarea type="text" formControlName="tools" class="form-control" id="validationTools" placeholder="Outil, matériel utilisé" [ngClass]="{ 'is-invalid': tools.invalid && (tools.dirty || tools.touched) }"></textarea>
            <div *ngIf="tools.invalid && (tools.dirty || tools.touched)" class="invalid-feedback">
              {{ getErrorMessage("tools") }}
            </div>
          </div>

          <!-- HAS PREVENTION -->
          <div class="form-group d-flex justify-content-start align-items-center">
            <div class="col pl-0">
              Plan de prévention existant
            </div>
            <div class="custom-checkbox-toggle">
              <input type="checkbox" class="custom-control-input" id="validationHasPrevention" formControlName="has_prevention" [ngClass]="{ 'backgroundColor': has_prevention.value }" (change)="changeHasPrevention()">
              <label class="custom-control-label" [ngClass]="{ 'backgroundColor': has_prevention.value }" for="validationHasPrevention"></label>
            </div>
          </div>
          <!-- REF PREVENTION -->
          <div *ngIf="has_prevention.value" class="form-group">
            <label for="validationRefPrevention" required>Référence du plan de prévention</label>
            <input type="text" formControlName="ref_prevention" id="validationRefPrevention" class="form-control" placeholder="Référence du plan de prévention" [ngClass]="{ 'is-invalid': ref_prevention.invalid && (ref_prevention.dirty || ref_prevention.touched) }" maxlength="255">
            <div *ngIf="ref_prevention.invalid && (ref_prevention.dirty || ref_prevention.touched)" class="invalid-feedback">
              {{ getErrorMessage("ref_prevention") }}
            </div>
          </div>

          <!-- RISKS -->
          <div class="form-group">
            <label for="validationRisks" required>Risques identifiés</label>
            <textarea formControlName="risks" class="form-control" id="validationRisks" placeholder="Préciser ici les risques identifiés (poussières, gaz, liquides inflammables, risque d’explosion, de propagation par proximité...)" rows="5"
                      [ngClass]="{ 'is-invalid': risks.invalid && (risks.dirty || risks.touched) }"></textarea>
            <div *ngIf="risks.invalid && (risks.dirty || risks.touched)" class="invalid-feedback">
              {{ getErrorMessage("risks") }}
            </div>
          </div>

          <!-- ACTION PREVENTION -->
          <div class="form-group">
            <label for="validationActionPrevention" required>Moyens complémentaires de lutte contre l’incendie (extincteurs, RIA, etc…)</label>
            <textarea formControlName="action_prevention" class="form-control" id="validationActionPrevention" placeholder="Moyens complémentaires de lutte contre l’incendie (extincteurs, RIA, etc…)" rows="5"
                      [ngClass]="{ 'is-invalid': action_prevention.invalid && (action_prevention.dirty || action_prevention.touched) }"></textarea>
            <div *ngIf="action_prevention.invalid && (action_prevention.dirty || action_prevention.touched)" class="invalid-feedback">
              {{ getErrorMessage("action_prevention") }}
            </div>
          </div>

          <!-- ACTION ALERT -->
          <div class="form-group">
            <label for="validationActionAlert" required>Moyens d’alerte et emplacement</label>
            <textarea formControlName="action_alert" class="form-control" id="validationActionAlert" placeholder="Moyens d’alerte et emplacement" rows="5"
                      [ngClass]="{ 'is-invalid': action_alert.invalid && (action_alert.dirty || action_alert.touched) }"></textarea>
            <div *ngIf="action_alert.invalid && (action_alert.dirty || action_alert.touched)" class="invalid-feedback">
              {{ getErrorMessage("action_alert") }}
            </div>
          </div>

          <!-- EMERGENCIES -->
          <div class="form-group">
            <label for="validationEmergencies" required>Numéro d'urgence</label>
            <input type="text" formControlName="emergencies" class="form-control" id="validationEmergencies" placeholder="Numéro d'urgence" [ngClass]="{ 'is-invalid': emergencies.invalid && (emergencies.dirty || emergencies.touched) }">
            <div *ngIf="emergencies.invalid && (emergencies.dirty || emergencies.touched)" class="invalid-feedback">
              {{ getErrorMessage("emergencies") }}
            </div>
          </div>

          <!-- ACTIONS -->
          <div class="form-group">
            <label for="listRegistry" required>Actions</label>
            <ng-select [items]="actions"
                       [multiple]="true"
                       bindLabel="name"
                       bindValue="name"
                       groupBy="group"
                       notFoundText="Aucun résultat"
                       [selectableGroup]="true"
                       [selectableGroupAsModel]="false"
                       [closeOnSelect]="false"
                       [addTag]="true"
                       [clearSearchOnAdd]="true"
                       addTagText="Ajouter"
                       placeholder="Actions"
                       [(ngModel)]="actions" [ngModelOptions]="{ standalone: true }" [disabled]="!isNew && !canUpdate">
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="listRegistry" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }" /> {{ item }}
              </ng-template>
            </ng-select>
          </div>

          <!-- PATROL REQUIRED -->
          <div class="form-group d-flex justify-content-start align-items-center">
            <div class="col pl-0">
              Ronde de sécurité nécessaire
            </div>
            <div class="custom-checkbox-toggle">
              <input type="checkbox" class="custom-control-input" id="validationPatrolRequired" formControlName="patrol_required" [ngClass]="{ 'backgroundColor': patrol_required.value }" (change)="changePatrolRequired()">
              <label class="custom-control-label" [ngClass]="{ 'backgroundColor': patrol_required.value }" for="validationPatrolRequired"></label>
            </div>
          </div>
          <!-- PATROL DESCRIPTION -->
          <div *ngIf="patrol_required.value" class="form-group">
            <label for="validationPatrolDescription" required>Précisions sur la ronde de sécurité</label>
            <textarea type="text" formControlName="patrol_description" id="validationPatrolDescription" class="form-control" placeholder="Précisions sur la ronde de sécurité" [ngClass]="{ 'is-invalid': patrol_description.invalid && (patrol_description.dirty || patrol_description.touched) }" maxlength="255"></textarea>
            <div *ngIf="patrol_description.invalid && (patrol_description.dirty || patrol_description.touched)" class="invalid-feedback">
              {{ getErrorMessage("patrol_description") }}
            </div>
          </div>

          <!-- ORIGINATOR NAME -->
          <div class="form-group">
            <label for="validationOriginatorName" required>Donneur d'ordre - Nom</label>
            <input type="text" formControlName="originator_name" class="form-control" id="validationOriginatorName" placeholder="Donneur d'ordre - Nom" [ngClass]="{ 'is-invalid': originator_name.invalid && (originator_name.dirty || originator_name.touched) }">
            <div *ngIf="originator_name.invalid && (originator_name.dirty || originator_name.touched)" class="invalid-feedback">
              {{ getErrorMessage("originator_name") }}
            </div>
          </div>

          <!-- ORIGINATOR FUNCTION -->
          <div class="form-group">
            <label for="validationOriginatorFunction" required>Donneur d'ordre - Fonction</label>
            <input type="text" formControlName="originator_function" class="form-control" id="validationOriginatorFunction" placeholder="Donneur d'ordre - Fonction" [ngClass]="{ 'is-invalid': originator_function.invalid && (originator_function.dirty || originator_function.touched) }">
            <div *ngIf="originator_function.invalid && (originator_function.dirty || originator_function.touched)" class="invalid-feedback">
              {{ getErrorMessage("originator_function") }}
            </div>
          </div>

          <!-- ORIGINATOR PHONE -->
          <div class="form-group">
            <label for="validationOriginatorPhone" required>Donneur d'ordre - Téléphone</label>
            <input type="text" formControlName="originator_phone" class="form-control" id="validationOriginatorPhone" placeholder="Donneur d'ordre - Téléphone" [ngClass]="{ 'is-invalid': originator_phone.invalid && (originator_phone.dirty || originator_phone.touched) }">
            <div *ngIf="originator_phone.invalid && (originator_phone.dirty || originator_phone.touched)" class="invalid-feedback">
              {{ getErrorMessage("originator_phone") }}
            </div>
          </div>

          <!-- SECURITY PROVIDER NAME -->
          <div class="form-group">
            <label for="validationSecurityProviderName" required>Personne désignée pour assurer la sécurité - Nom</label>
            <input type="text" formControlName="security_provider_name" class="form-control" id="validationSecurityProviderName" placeholder="Personne désignée pour assurer la sécurité - Nom" [ngClass]="{ 'is-invalid': security_provider_name.invalid && (security_provider_name.dirty || security_provider_name.touched) }">
            <div *ngIf="security_provider_name.invalid && (security_provider_name.dirty || security_provider_name.touched)" class="invalid-feedback">
              {{ getErrorMessage("security_provider_name") }}
            </div>
          </div>

          <!-- SECURITY PROVIDER FUNCTION -->
          <div class="form-group">
            <label for="validationSecurityProviderFunction" required>Personne désignée pour assurer la sécurité - Fonction</label>
            <input type="text" formControlName="security_provider_function" class="form-control" id="validationSecurityProviderFunction" placeholder="Personne désignée pour assurer la sécurité - Fonction" [ngClass]="{ 'is-invalid': security_provider_function.invalid && (security_provider_function.dirty || security_provider_function.touched) }">
            <div *ngIf="security_provider_function.invalid && (security_provider_function.dirty || security_provider_function.touched)" class="invalid-feedback">
              {{ getErrorMessage("security_provider_function") }}
            </div>
          </div>

          <!-- SECURITY PROVIDER PHONE -->
          <div class="form-group">
            <label for="validationSecurityProviderPhone" required>Personne désignée pour assurer la sécurité - Téléphone</label>
            <input type="text" formControlName="security_provider_phone" class="form-control" id="validationSecurityProviderPhone" placeholder="Personne désignée pour assurer la sécurité - Téléphone" [ngClass]="{ 'is-invalid': security_provider_phone.invalid && (security_provider_phone.dirty || security_provider_phone.touched) }">
            <div *ngIf="security_provider_phone.invalid && (security_provider_phone.dirty || security_provider_phone.touched)" class="invalid-feedback">
              {{ getErrorMessage("security_provider_phone") }}
            </div>
          </div>

          <!-- CONTRIBUTOR NAME -->
          <div class="form-group">
            <label for="validationContributorName" required>Intervenant - Nom</label>
            <input type="text" formControlName="contributor_name" class="form-control" id="validationContributorName" placeholder="Intervenant - Nom" [ngClass]="{ 'is-invalid': contributor_name.invalid && (contributor_name.dirty || contributor_name.touched) }">
            <div *ngIf="contributor_name.invalid && (contributor_name.dirty || contributor_name.touched)" class="invalid-feedback">
              {{ getErrorMessage("contributor_name") }}
            </div>
          </div>

          <!-- CONTRIBUTOR FUNCTION -->
          <div class="form-group">
            <label for="validationContributorFunction" required>Intervenant - Fonction</label>
            <input type="text" formControlName="contributor_function" class="form-control" id="validationContributorFunction" placeholder="Intervenant - Fonction" [ngClass]="{ 'is-invalid': contributor_function.invalid && (contributor_function.dirty || contributor_function.touched) }">
            <div *ngIf="contributor_function.invalid && (contributor_function.dirty || contributor_function.touched)" class="invalid-feedback">
              {{ getErrorMessage("contributor_function") }}
            </div>
          </div>

          <!-- CONTRIBUTOR PHONE -->
          <div class="form-group">
            <label for="validationContributorPhone" required>Intervenant - Téléphone</label>
            <input type="text" formControlName="contributor_phone" class="form-control" id="validationContributorPhone" placeholder="Intervenant - Téléphone" [ngClass]="{ 'is-invalid': contributor_phone.invalid && (contributor_phone.dirty || contributor_phone.touched) }">
            <div *ngIf="contributor_phone.invalid && (contributor_phone.dirty || contributor_phone.touched)" class="invalid-feedback">
              {{ getErrorMessage("contributor_phone") }}
            </div>
          </div>

          <!-- DOCUMENTS -->
          <div class="form-group" *ngIf="isNew || canUpdate">
            <label for="inputFile">Documents</label>

            <div class="dropzone dropzone-multiple dz-clickable" appDragDrop (dropHandler)="onFileChange($event)">
              <div class="dz-default dz-message" (click)="chooseFile()">
                <span>Déposer vos fichiers ici pour télécharger</span>
              </div>
              <input id="inputFile" (change)="onFileChange($event)" type="file" [hidden]="true" multiple>
            </div>
          </div>
          <div class="card" *ngFor="let file of files; let index = index">
            <div class="card-body">
              <div class="d-flex align-content-center justify-content-between">
                <h4 class="card-title mb-0 name align-self-center">
                  {{ file.name + '.' + file.extension }}
                </h4>

                <a class="href tooltipHtml" data-placement="bottom" title="Supprimer" (click)="removeFile(index)" *ngIf="isNew || canUpdate">
                  <i class="fe fe-trash"></i>
                </a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </form>
  </div>

  <div modal-footer class="w-100" *ngIf="isNew || canUpdate">
    <button class="btn btn-primary w-100" (click)="isNew ? createFirePermit() : updateFirePermit()" [ngClass]="{ 'is-loading is-loading-sm': loader }" [disabled]="loader || firePermitForm.invalid">
      {{ isNew ? 'Créer' : 'Mettre à jour' }}
    </button>
  </div>
</app-modal-right>
