import { Routes } from '@angular/router';

import { AllSingleSignOnComponent } from './all-single-sign-on/all-single-sign-on.component';
import { UpdateSingleSignOnComponent } from './update-single-sign-on/update-single-sign-on.component';

export const singleSignOnRoutes: Routes = [
  {
    path: 'single-sign-on',
    component: AllSingleSignOnComponent,
    children: [
      {
        path: 'create',
        component: UpdateSingleSignOnComponent
      },
      {
        path: ':sso_id',
        component: UpdateSingleSignOnComponent
      }
    ]
  }
];
