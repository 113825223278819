import { Routes } from '@angular/router';

import { UpdateSanitaryLegionellaComponent } from './update-sanitary-legionella/update-sanitary-legionella.component';

export const sanitaryLegionellaRoutes: Routes = [
  {
    path: 'create',
    component: UpdateSanitaryLegionellaComponent
  },
  {
    path: ':sanitary_legionella_id',
    component: UpdateSanitaryLegionellaComponent
  },
];
