import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AllRvratModule } from './all-rvrat/all-rvrat.module';
import { UpdateRvratModule } from './update-rvrat/update-rvrat.module';
import { UploadRvratFileModule } from './upload-rvrat-file/upload-rvrat-file.module';

import { RvratsRequestService } from '../../services/rvrats.request.service';

@NgModule({
  imports: [
    CommonModule,
    AllRvratModule,
    UpdateRvratModule,
    UploadRvratFileModule
  ],
  providers: [
    RvratsRequestService
  ]
})
export class RvratModule {}
