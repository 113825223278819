import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { HttpClient } from '@angular/common/http';
import { NotifierService } from 'angular-notifier';

@Injectable()
export class ExportDuplicateRequestService extends RequestService {

  constructor(
    protected http: HttpClient,
    protected notifier: NotifierService
  ) {
    super(http, notifier);
  }

  // EXPORT UNIQUE DOC
  public exportUniqueDocument(establishment_id, ids) {
    return this.put('export-unique-document/' + establishment_id, {ids});
  }

}
