import {Compiler, Component, OnInit} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { NotifierService } from 'angular-notifier';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../core/auth/auth.service';

import { environment } from '../../../../environments/environment';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { SsoConfigsRequestService } from '../../../services/sso-configs.request.service';

import sso_config from '../../../../shared/interfaces/sso_config';

@Component({
  selector: 'app-sso-login',
  templateUrl: './sso-login.component.html',
  styleUrls: ['./sso-login.component.scss'],
  animations: [
    trigger('routeTransition', [
      state('void', style({ width: '100%', height: '100%', display: 'block', position: 'relative' }) ),
      state('*', style({ width: '100%', height: '100%', display: 'block', position: 'relative' }) ),
      transition(':enter', [
        style({
          opacity: '0',
          overflow: 'hidden'
        }),
        animate('.5s linear', style({
          opacity: '1',
          overflow: 'hidden'
        }))
      ]),
      transition(':leave', [
        style({
        })
      ])
    ])
  ]
})
export class SsoLoginComponent implements OnInit {

  environment = environment;

  ssoConfig: sso_config;
  code: string;
  state: string;
  isSigningIn = false;

  loader = false;

  constructor(
    private router: Router,
    private http: HttpClient,
    private compiler: Compiler,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private ssoConfigService: SsoConfigsRequestService
  ) {}

  ngOnInit() {

    this.getSsoConfig();

    this.activatedRoute.queryParams.subscribe(params => {
      if (params['redirect']) {
        CustomNotifierService.getSsoLogin(this.notifier);
      }
    });
    this.code = this.activatedRoute.snapshot.queryParams.code;
    if (this.code) {
      this.isSigningIn = true;
      this.http.post(environment.API_PATH + 'auth/login/' + this.activatedRoute.snapshot.params.redirect_uri + '/token',
        { code: this.code }).subscribe(response => {
          if (response['success']) {
            this.compiler.clearCache();
            localStorage.setItem('accessToken', response['accessToken']);
            localStorage.setItem('refreshToken', response['refreshToken']);
            localStorage.setItem('sso_logout', response['sso_logout']);
            localStorage.setItem('enterprises', JSON.stringify(response['enterprises']));
            localStorage.setItem('isProvider', JSON.stringify(response['is_provider']));
            localStorage.setItem('isOnlyProvider', JSON.stringify(response['is_only_provider']));
            localStorage.setItem('enterprise', JSON.stringify(response['enterprise']));
            localStorage.setItem('isAdmin', JSON.stringify(response['is_admin']));
            localStorage.setItem('user', JSON.stringify(response['user']));
            localStorage.setItem('registries', JSON.stringify(response['registries']));

            this.authService.changeProvider(response['is_provider']);
            this.authService.changeOnlyProvider(response['is_only_provider']);
            this.authService.changeAdmin(response['is_admin']);

            this.authService.login();
          } else if (response['errors']) {
            CustomNotifierService.getErrors(response['errors'], this.notifier);
          }
          this.isSigningIn = false;
        });
    }
  }

  getSsoConfig() {
    this.loader = true;

    this.ssoConfigService.getSsoConfigByRedirectUri(this.activatedRoute.snapshot.params.redirect_uri).subscribe(response => {
      if (response['success']) {
        this.ssoConfig = response['sso_config'];
      } else {
        this.router.navigate(['/auth/login']).then(() => {});
      }
      this.loader = false;
    });
  }

  signIn() {
    this.isSigningIn = true;
    this.http.get(environment.API_PATH + 'auth/login/' + this.activatedRoute.snapshot.params.redirect_uri)
      .subscribe(response => {
        if (response['success']) {
          window.location.href = response['url'];
        }
      });
  }
}
