<app-modal-right (eventOnClose)="closeModal()" #unity>{{ bindModal(unity) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">{{ isNew ? 'Création' : canUpdate ? 'Mise à jour' : 'Consultation' }}</h6>

        <h1 class="header-title">Risque</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <form [formGroup]="riskForm" *ngIf="!loaderContent">
      <div class="row">
        <div class="col-12">

          <!-- UNITIES -->
          <div class="form-group" *ngIf="isNew && !loaderContent && risk_unity_works">
            <label for="unitiesSelected" required>Liste des unitées de travail</label>
            <ng-select [items]="risk_unity_works" [multiple]="true"
                       bindLabel="name"
                       bindValue="id"
                       groupBy="group"
                       notFoundText="Aucun résultat"
                       [selectableGroup]="true"
                       [selectableGroupAsModel]="false"
                       [closeOnSelect]="false"
                       placeholder="Sélectionnez une/des unitée(s) de travail"
                       formControlName="risk_unity_work_id" [disabled]="!isNew && !canUpdate">
              <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                <input type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }"/> Sélectionnez tout
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="unitiesSelected" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }" /> {{ item.name }}
              </ng-template>
            </ng-select>
          </div>

          <!-- RISK FAMILY -->
          <div class="form-group">
            <label for="validationFamily" required>Famille de risque</label>
            <select class="form-control" data-toggle="select" formControlName="family" id="validationFamily" [ngClass]="{ 'is-invalid': family.invalid && (family.dirty || family.touched) }">
              <option value="" [selected]="!family">Sélectionnez une famille de risque</option>
              <ng-container *ngFor="let risk_family of risk_families">
                <option [value]="risk_family.name">{{ risk_family.name }}</option>
              </ng-container>
            </select>
            <div *ngIf="family.invalid && (family.dirty || family.touched)" class="invalid-feedback">
              {{ getErrorMessage("family") }}
            </div>
          </div>

          <!-- RISK DESCRIPTION -->
          <div class="form-group">
            <label for="validationRiskDescription" required>Précision sur l’intitulé du risque / les causes</label>
            <input type="text" id="validationRiskDescription" formControlName="description" class="form-control" placeholder="Précision sur l’intitulé du risque / les causes" maxlength="255"
                   [ngClass]="{ 'is-invalid': description.invalid && (description.dirty || description.touched) }">
            <div *ngIf="description.invalid && (description.dirty || description.touched)" class="invalid-feedback">
              {{ getErrorMessage("description") }}
            </div>
          </div>

          <!-- RISK DAMAGES -->
          <div class="form-group" *ngIf="risk_damages">
            <label for="ListDamages">Dommages associés</label>
            <ng-select [items]="risk_damages"
                       [multiple]="true"
                       addTag="true"
                       addTagText="Ajouter dommage"
                       bindLabel="name"
                       bindValue="name"
                       notFoundText="Aucun résultat"
                       [selectableGroup]="true"
                       [selectableGroupAsModel]="false"
                       [closeOnSelect]="false"
                       placeholder="Sélectionnez un/des dommage(s)"
                       formControlName="damages" [disabled]="!isNew && !canUpdate">
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <div>
                  <input id="ListDamages" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }"/> {{ item.name }}
                </div>
              </ng-template>
            </ng-select>
          </div>

          <!-- RISK USERS -->
          <div class="form-group" *ngIf="!loaderContent && users">
            <label for="userSelected">Qui est concerné ?</label>
            <ng-select [items]="users"
                       [multiple]="true"
                       bindLabel="name"
                       bindValue="id"
                       groupBy="enterprise_id"
                       [selectableGroup]="true"
                       [selectableGroupAsModel]="false"
                       [closeOnSelect]="false"
                       placeholder="Qui est concerné ?"
                       notFoundText="Aucun résultat"
                       [(ngModel)]="usersSelected" [ngModelOptions]="{ standalone: true }" [disabled]="!isNew && !canUpdate">
              <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                <input type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }"/> Sélectionnez tout
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="userSelected" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }"/> {{ item.name }}
              </ng-template>
            </ng-select>
          </div>

          <!-- RISK FREQUENCY -->
          <div class="form-group">
            <label for="validationFrequency" required>Fréquence</label>
            <select class="form-control" data-toggle="select" formControlName="frequency_id" id="validationFrequency" [ngClass]="{ 'is-invalid': frequency_id.invalid && (frequency_id.dirty || frequency_id.touched) }">
              <option value="" [selected]="!frequency_id">Sélectionnez une fréquence</option>
              <ng-container *ngFor="let frequency of frequencies">
                <option [value]="frequency.id">{{ frequency.title }}</option>
              </ng-container>
            </select>
            <div *ngIf="frequency_id.invalid && (frequency_id.dirty || frequency_id.touched)" class="invalid-feedback">
              {{ getErrorMessage("frequency_id") }}
            </div>
          </div>

          <!-- RISK GRAVITY -->
          <div class="form-group">
            <label for="validationGravity" required>Gravité</label>
            <select class="form-control" data-toggle="select" formControlName="gravity_id" id="validationGravity" [ngClass]="{ 'is-invalid': gravity_id.invalid && (gravity_id.dirty || gravity_id.touched) }">
              <option value="" [selected]="!gravity_id">Sélectionnez une gravité</option>
              <ng-container *ngFor="let gravity of gravities">
                <option [value]="gravity.id">{{ gravity.title }}</option>
              </ng-container>
            </select>
            <div *ngIf="gravity_id.invalid && (gravity_id.dirty || gravity_id.touched)" class="invalid-feedback">
              {{ getErrorMessage("gravity_id") }}
            </div>
          </div>

          <!-- RISK MASTER -->
          <div class="form-group">
            <label for="validationMaster" required>Maitrise</label>
            <select class="form-control" data-toggle="select" formControlName="master_id" id="validationMaster" [ngClass]="{ 'is-invalid': master_id.invalid && (master_id.dirty || master_id.touched) }">
              <option value="" [selected]="!master_id">Sélectionnez une maitrise</option>
              <ng-container *ngFor="let master of masters">
                <option [value]="master.id">{{ master.title }}</option>
              </ng-container>
            </select>
            <div *ngIf="master_id.invalid && (master_id.dirty || master_id.touched)" class="invalid-feedback">
              {{ getErrorMessage("master_id") }}
            </div>
          </div>

          <!-- RISK HAS PROTECTION -->
          <div class="form-group d-flex justify-content-start align-items-center">
            <div class="col pl-0">
              Moyens de protection et de prévention mis en place
            </div>
            <div class="custom-checkbox-toggle">
              <input type="checkbox" class="custom-control-input" id="validationHasProtection" formControlName="has_protection"
                     [ngClass]="{ 'backgroundColor': has_protection.value }">
              <label class="custom-control-label" [ngClass]="{ 'backgroundColor': has_protection.value }" for="validationHasProtection"></label>
            </div>
          </div>

          <!-- CURRENT PROTECTION -->
          <div class="form-group" *ngIf="has_protection.value">
            <label for="validationCurrentProtection">Moyens de protection et de prévention existants</label>
            <textarea formControlName="current_protection" class="form-control" id="validationCurrentProtection" placeholder="Moyens de protection et de prévention existants" rows="5"></textarea>
            <div *ngIf="current_protection.invalid && (current_protection.dirty || current_protection.touched)" class="invalid-feedback">
              {{ getErrorMessage("current_protection") }}
            </div>
          </div>

          <!-- TO DO PROTECTION -->
          <div class="form-group">
            <label for="validationTodoProtection">Actions correctives à réaliser</label>
            <textarea formControlName="todo_protection" class="form-control" id="validationTodoProtection" placeholder="Actions correctives à réaliser" rows="5"></textarea>
            <div *ngIf="todo_protection.invalid && (todo_protection.dirty || todo_protection.touched)" class="invalid-feedback">
              {{ getErrorMessage("todo_protection") }}
            </div>
          </div>

          <!-- RISk DEADLINE -->
          <div class="form-group">
            <label for="validationDeadline">Échéance</label>
            <input type="date" formControlName="deadline" class="form-control" id="validationDeadline" placeholder="Échéance" data-toggle="flatpickr" [ngClass]="{ 'is-invalid': deadline.invalid && (deadline.dirty || deadline.touched) }">
            <div *ngIf="deadline.invalid && (deadline.dirty || deadline.touched)" class="invalid-feedback">
              {{ getErrorMessage("deadline") }}
            </div>
          </div>

          <!-- RISK COST -->
          <div class="form-group">
            <label for="validationCost">Budget</label>
            <input type="number" formControlName="cost" class="form-control" id="validationCost" placeholder="0" [ngClass]="{ 'is-invalid': cost.invalid && (cost.dirty || cost.touched) }" min="0">
            <div *ngIf="cost.invalid && (cost.dirty || cost.touched)" class="invalid-feedback">
              {{ getErrorMessage("cost") }}
            </div>
          </div>

          <!-- RISK COST DETAIL -->
          <div class="form-group" *ngIf="costs">
            <label>Précisions sur le budget</label>
            <ng-select [items]="costs"
                       [multiple]="false"
                       bindLabel="name"
                       bindValue="name"
                       notFoundText="Aucun résultat"
                       [selectableGroup]="true"
                       [selectableGroupAsModel]="false"
                       [closeOnSelect]="false"
                       placeholder="Sélectionnez une précision"
                       formControlName="cost_detail" [disabled]="!isNew && !canUpdate">
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <div class="tooltipHtml" data-placement="bottom" [title]="item.description" *ngIf="item.description">{{ item.name }}</div>
                <div *ngIf="!item.description">{{ item.name }}</div>
              </ng-template>
            </ng-select>
          </div>

          <!-- RISK USER AFFECTED -->
          <div class="form-group">
            <label for="validationUserAffected">Service / personne en charge du dossier</label>
            <input type="text" formControlName="user_affected" class="form-control" id="validationUserAffected" placeholder="Service / personne en charge du dossier" [ngClass]="{ 'is-invalid': user_affected.invalid && (user_affected.dirty || user_affected.touched) }">
            <div *ngIf="user_affected.invalid && (user_affected.dirty || user_affected.touched)" class="invalid-feedback">
              {{ getErrorMessage("user_affected") }}
            </div>
          </div>

          <!-- RISK USER RECEIVE EMAIL -->
          <div class="form-group d-flex justify-content-start align-items-center">
            <div class="col pl-0">
              Peut recevoir un email
            </div>
            <div class="custom-checkbox-toggle">
              <input type="checkbox" class="custom-control-input" id="validationReceiveEmail" formControlName="user_receive_email"
                     [ngClass]="{ 'backgroundColor': user_receive_email.value }">
              <label class="custom-control-label" [ngClass]="{ 'backgroundColor': user_receive_email.value }" for="validationReceiveEmail"></label>
            </div>
          </div>

          <!-- RISK USER EMAIL -->
          <div class="form-group" *ngIf="user_receive_email.value">
            <label for="validationUserEmail">Email</label>
            <input type="text" formControlName="user_email" class="form-control" id="validationUserEmail" placeholder="Email" [ngClass]="{ 'is-invalid': user_email.invalid && (user_email.dirty || user_email.touched) }">
            <div *ngIf="user_email.invalid && (user_email.dirty || user_email.touched)" class="invalid-feedback">
              {{ getErrorMessage("user_email") }}
            </div>
          </div>

          <!-- RISK STATE -->
          <div class="form-group">
            <label for="validationState" required>Réalisé</label>
            <select class="form-control" data-toggle="select" formControlName="state" id="validationState" [ngClass]="{ 'is-invalid': state.invalid && (state.dirty || state.touched) }">
              <option value="" [selected]="!state">Sélectionnez un état</option>
              <option value="yes">Oui</option>
              <option value="no">Non</option>
              <option value="in_progress">En cours</option>
              <option value="permanent">Permanent</option>
            </select>
            <div *ngIf="state.invalid && (state.dirty || state.touched)" class="invalid-feedback">
              {{ getErrorMessage("state") }}
            </div>
          </div>

          <!-- RISK COMMENTS -->
          <div class="form-group">
            <label for="validationComments">Commentaires éventuels</label>
            <textarea formControlName="comments" class="form-control" id="validationComments" placeholder="Commentaires éventuels" rows="3"></textarea>
            <div *ngIf="comments.invalid && (comments.dirty || comments.touched)" class="invalid-feedback">
              {{ getErrorMessage("comments") }}
            </div>
          </div>

          <!-- RISk DATE UPDATE -->
          <div class="form-group">
            <label for="validationDateUpdate">Date de mises à jour</label>
            <input type="date" formControlName="date_update" class="form-control" id="validationDateUpdate" placeholder="Date de mises à jour" data-toggle="flatpickr" [ngClass]="{ 'is-invalid': date_update.invalid && (date_update.dirty || date_update.touched) }">
            <div *ngIf="date_update.invalid && (date_update.dirty || date_update.touched)" class="invalid-feedback">
              {{ getErrorMessage("date_update") }}
            </div>
          </div>

          <!-- ESTABLISHMENTS -->
          <div class="form-group" *ngIf="isNew && !loaderContent && establishments">
            <label for="establishmentSelected">Autre(s) établissement(s) cible(s)</label>
            <ng-select [items]="establishments"
                       [multiple]="true"
                       bindLabel="name"
                       bindValue="id"
                       groupBy="group"
                       notFoundText="Aucun résultat"
                       [selectableGroup]="true"
                       [selectableGroupAsModel]="false"
                       [closeOnSelect]="false"
                       placeholder="Sélectionnez un/des établissement(s)"
                       [(ngModel)]="establishmentsSelected" [ngModelOptions]="{ standalone: true }" [disabled]="!isNew && !canUpdate">
              <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                <input type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }"/> Sélectionnez tout
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="establishmentSelected" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }" /> {{ item.name }}
              </ng-template>
            </ng-select>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div modal-footer class="w-100" *ngIf="isNew || canUpdate">
    <button class="btn btn-primary w-100" (click)="isNew ? createRisk() : updateRisk()"
            [ngClass]="{ 'is-loading is-loading-sm': loader }" [disabled]="loader || riskForm.invalid">
      {{ isNew ? 'Créer' : 'Mettre à jour' }}
    </button>
  </div>
</app-modal-right>
