<app-modal-right (eventOnClose)="closeModal()" #risk>{{ bindModal(risk) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">{{ isNew ? 'Création' : canUpdate ? 'Mise à jour' : 'Consultation' }}</h6>

        <h1 class="header-title">Risque</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <form [formGroup]="riskForm" [hidden]="loaderContent">
      <div class="form-group">
        <label for="name" required>Risque</label>
        <input type="text" formControlName="name" class="form-control" id="name" placeholder="Risque" [ngClass]="{ 'is-invalid': name.invalid && (name.dirty || name.touched) }">
        <div *ngIf="name.invalid && (name.dirty || name.touched)" class="invalid-feedback">
          {{ getErrorMessage("name") }}
        </div>
      </div>
    </form>
  </div>

  <div modal-footer class="w-100" *ngIf="isNew || canUpdate">
    <button class="btn btn-primary w-100" (click)="isNew ? createRisk() : updateRisk()" [ngClass]="{ 'is-loading is-loading-sm': loader }" [disabled]="loader || riskForm.invalid">
      {{ isNew ? 'Créer' : 'Mettre à jour' }}
    </button>
  </div>
</app-modal-right>
