import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NotifierService } from "angular-notifier";

import { CustomNotifierService } from "../../../services/custom.notifier.service";

import { AllTrainingsComponent } from "../all-trainings/all-trainings.component";

import { TrainingsRequestService } from "../../../services/trainings.request.service";
import { RegistriesRequestService } from "../../../services/registries.request.service";

import {
  establishment_types,
  ERP_subtypes,
  IGH_subtypes,
  BHC_categories,
  ERP_categories,
  ERP_first_subcategories,
  ERP_second_subcategories,
} from "../../../../shared/establishments";

import Training from "../../../../shared/interfaces/training";
import Registry from "../../../../shared/interfaces/registry";
import EstablishmentSubSubtype from "../../../../shared/interfaces/establishment_sub_subtype";
import EstablishmentCategory from "../../../../shared/interfaces/establishment_category";
import EstablishmentSubCategory from "../../../../shared/interfaces/establishment_sub_category";

@Component({
  selector: "app-update-training",
  templateUrl: "./update-training.component.html",
  styleUrls: ["./update-training.component.scss"],
})
export class UpdateTrainingComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  trainings: Training[];
  registries: Registry[];
  sub_subtypes: EstablishmentSubSubtype[];
  categories: EstablishmentCategory[];
  subcategories: EstablishmentSubCategory[];

  establishment_types = establishment_types;
  ERP_subtypes = ERP_subtypes;
  BHC_categories = BHC_categories;
  IGH_subtypes = IGH_subtypes;
  ERP_categories = ERP_categories;
  ERP_first_subcategories = ERP_first_subcategories;
  ERP_second_subcategories = ERP_second_subcategories;

  isNewFrequency = true;
  id_frequency: number;

  show_subtypes = false;
  show_sub_subtypes = false;
  show_categories = false;
  show_subcategories = false;

  isNew = true;
  canCreate = false;
  canUpdate = false;
  loader = false;
  loaderContent = false;

  errors = {};
  subtypes: any;
  training: any;

  modal_for = "";
  view = "";
  previousRoute = "";
  activeTab = "training";

  modalTraining = null;

  trainingForm: FormGroup;
  id = new FormControl("");
  name = new FormControl("", Validators.required);
  registry_id = new FormControl(null);
  enterprise_id = new FormControl(null);
  establishment_id = new FormControl(null);
  priority = new FormControl(false);
  generic = new FormControl(true);
  custom = new FormControl(true);
  description = new FormControl(null);
  origin = new FormControl(null);
  frequency_alert = new FormControl(false);
  frequency_notification = new FormControl(false);

  trainingFrequencyForm: FormGroup;
  frequency_duration = new FormControl("", [
    Validators.required,
    Validators.min(1),
  ]);
  frequency = new FormControl("", Validators.required);
  frequency_type = new FormControl("", Validators.required);
  establishment_type_id = new FormControl(null);
  establishment_subtypes = new FormControl([]);
  establishment_sub_subtype_id = new FormControl(null);
  establishment_category_id = new FormControl(null);
  establishment_subcategory_id = new FormControl(null);

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private allTrainings: AllTrainingsComponent,
    private trainingsService: TrainingsRequestService,
    private registriesService: RegistriesRequestService
  ) {}

  ngOnInit() {
    this.previousRoute = this.activatedRoute.snapshot.parent.url.join("/");
    if (
      this.activatedRoute.snapshot.parent.url[0] &&
      this.activatedRoute.snapshot.parent.url[0].path === "dashboard-registry"
    ) {
      this.modal_for = "enterprise";
    } else {
      this.modal_for = "admin";
    }

    if (this.activatedRoute.snapshot.params.training_id) {
      this.isNew = false;
      this.getTraining(this.activatedRoute.snapshot.params.training_id);
    }
    this.buildForms();
    this.getRegistries();
  }

  ngAfterViewInit() {
    this.modalTraining.open();
  }

  ngOnDestroy() {
    this.modalTraining.close();
  }

  getTraining(training_id: any) {
    this.errors = {};
    this.loader = true;
    this.loaderContent = true;

    this.trainingsService.getTraining(training_id).subscribe((response) => {
      if (response["success"]) {
        this.canUpdate = response["canUpdate"];
        if (!this.canUpdate) {
          this.trainingForm.disable();
        }
        this.training = response["training"];
        this.trainingForm.patchValue(response["training"]);
        this.changeTypeEstablishment(false);
      } else if (response["errors"]) {
        this.errors = response["errors"];
        CustomNotifierService.getErrorsForm(
          this.errors,
          this.trainingForm,
          this.notifier
        );
      }
      this.loader = false;
      this.loaderContent = false;
    });
  }

  getRegistries() {
    this.loader = true;
    this.registriesService.getCheckpointRegistries().subscribe((response) => {
      if (response["success"]) {
        this.registries = response["registries"];
        if (this.isNew && this.view === "registry") {
          this.registry_id.patchValue(
            parseInt(this.activatedRoute.snapshot.parent.params.registry_id, 10)
          );
          this.registry_id.disable();
        }
      } else if (response["errors"]) {
        CustomNotifierService.getErrors(response["errors"], this.notifier);
      }
      this.loader = false;
    });
  }

  changeTypeEstablishment(clear = true) {
    this.show_subtypes = false;
    this.show_sub_subtypes = false;
    this.show_categories = false;
    this.show_subcategories = false;

    const type_id = parseInt(this.establishment_type_id.value, 10);
    if (type_id === 1) {
      this.show_subtypes = true;
      this.subtypes = this.ERP_subtypes;

      this.show_sub_subtypes = false;
      this.sub_subtypes = [];

      this.show_categories = true;
      this.categories = this.ERP_categories;

      this.show_subcategories = false;
      this.subcategories = [];
    } else if (type_id === 2) {
      this.show_subtypes = false;
      this.subtypes = [];

      this.show_sub_subtypes = false;
      this.sub_subtypes = [];

      this.show_categories = false;
      this.categories = [];

      this.show_subcategories = false;
      this.subcategories = [];
    } else if (type_id === 3) {
      this.show_subtypes = false;
      this.subtypes = [];

      this.show_sub_subtypes = false;
      this.sub_subtypes = [];

      this.show_categories = true;
      this.categories = this.BHC_categories;

      this.show_subcategories = false;
      this.subcategories = [];
    } else if (type_id === 4) {
      this.show_subtypes = true;
      this.subtypes = this.IGH_subtypes;

      this.show_sub_subtypes = false;
      this.sub_subtypes = [];

      this.show_categories = false;
      this.categories = [];

      this.show_subcategories = false;
      this.subcategories = [];
    }

    if (clear) {
      this.establishment_subtypes.setValue(null);
      this.establishment_sub_subtype_id.setValue(null);
      this.establishment_category_id.setValue(null);
      this.establishment_subcategory_id.setValue(null);
    }
  }

  changeCategoryEstablishment() {
    const category_id = parseInt(this.establishment_category_id.value, 10);
    if (category_id === 1) {
      this.show_subcategories = true;
      this.subcategories = this.ERP_first_subcategories;
    } else if (category_id === 2) {
      this.show_subcategories = true;
      this.subcategories = this.ERP_second_subcategories;
    } else {
      this.show_subcategories = false;
      this.subcategories = [];
    }
  }

  changeFrequency() {
    this.frequency.updateValueAndValidity();
    this.frequency_duration.updateValueAndValidity();
  }

  getTypes(frequency) {
    let str = "";
    if (+frequency.establishment_type_id === 1) {
      str += "ERP ";
      if (frequency.establishment_subtypes) {
        str += "- ";
        frequency.establishment_subtypes.forEach((subtype) => {
          const found = ERP_subtypes.find((element) => {
            return element.id === subtype;
          });
          str += found.name + " ";
        });
      }
      if (frequency.establishment_category_id) {
        str += "- ";
        const category = this.ERP_categories.find((element) => {
          return element.id === +frequency.establishment_category_id;
        });
        str += category.name + " ";

        if (frequency.establishment_subcategory_id) {
          str += "- ";
          let subcategory;
          if (+frequency.establishment_category_id === 1) {
            subcategory = this.ERP_first_subcategories.find((element) => {
              return element.id === +frequency.establishment_subcategory_id;
            });
          } else if (+frequency.establishment_category_id === 2) {
            subcategory = this.ERP_second_subcategories.find((element) => {
              return element.id === +frequency.establishment_subcategory_id;
            });
          }
          str += subcategory.name + " ";
        }
      }
    } else if (+frequency.establishment_type_id === 2) {
      str += "ERT";
    } else if (+frequency.establishment_type_id === 3) {
      str += "BHC";
      if (frequency.establishment_category_id) {
        str += "- ";
        const category = this.BHC_categories.find((element) => {
          return element.id === +frequency.establishment_category_id;
        });
        str += category.name + " ";
      }
    } else if (+frequency.establishment_type_id === 4) {
      str += "IGH";
      if (frequency.establishment_subtypes) {
        str += "- ";
        frequency.establishment_subtypes.forEach((subtype) => {
          const found = IGH_subtypes.find((element) => {
            return element.id === subtype;
          });
          str += found.name + " ";
        });
      }
    } else {
      str += "Général, aucun type";
    }
    return str;
  }

  createFrequency() {
    if (!this.training) {
      this.training = { frequencies: [] };
    } else if (this.training.frequencies === null) {
      this.training.frequencies = [];
    }

    if (this.establishment_subtypes.value) {
      this.trainingFrequencyForm.value.establishment_subtypes =
        this.establishment_subtypes.value;
    }
    this.training.frequencies.push(this.trainingFrequencyForm.value);

    this.establishment_type_id.setValue(null);
    this.establishment_subtypes.setValue([]);
    this.frequency_duration.setValue("");
    this.frequency_type.setValue("");
    this.frequency.setValue("");
    this.changeTypeEstablishment();
  }

  updateFrequency() {
    if (this.establishment_subtypes.value) {
      this.trainingFrequencyForm.value.establishment_subtypes =
        this.establishment_subtypes.value;
    }
    this.training.frequencies[this.id_frequency] =
      this.trainingFrequencyForm.value;

    this.isNewFrequency = true;
    this.id_frequency = null;

    this.establishment_type_id.setValue(null);
    this.establishment_subtypes.setValue([]);
    this.frequency_duration.setValue("");
    this.frequency_type.setValue("");
    this.frequency.setValue("");
    this.changeTypeEstablishment();
  }

  editFrequency(frequency, key) {
    this.isNewFrequency = false;
    this.id_frequency = key;
    this.trainingFrequencyForm.patchValue(frequency);
    this.changeTypeEstablishment(false);
  }

  cancelEditFrequency() {
    this.isNewFrequency = true;
    this.id_frequency = null;

    this.establishment_type_id.setValue(null);
    this.establishment_subtypes.setValue([]);
    this.frequency_duration.setValue(null);
    this.frequency_type.setValue(null);
    this.frequency.setValue(null);
    this.changeTypeEstablishment();
  }

  deleteFrequency(key) {
    this.training.frequencies.splice(key, 1);

    const tooltips = document.getElementsByClassName(
      "tooltip"
    ) as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  createTraining() {
    this.errors = {};
    this.loader = true;

    const trainingData = {
      ...this.trainingForm.value,
      frequency: this.frequency.value,
      frequency_duration: this.frequency_duration.value,
      registry_id: this.registry_id.value,
      frequencies: this.training.frequencies,
    };

    this.trainingsService.createTraining(trainingData).subscribe((response) => {
      if (response["success"]) {
        this.modalTraining.close();
        this.allTrainings.refreshData();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response["errors"]) {
        this.errors = response["errors"];
        CustomNotifierService.getErrorsForm(
          this.errors,
          this.trainingForm,
          this.notifier
        );
      }
      this.loader = false;
    });
  }

  updateTraining() {
    this.errors = {};
    this.loader = true;

    const trainingData = {
      ...this.trainingForm.value,
      frequency: this.training.frequency,
      frequency_duration: this.training.frequency_duration,
      registry_id: this.registry_id.value,
      frequencies: this.training.frequencies,
    };

    const request = this.trainingsService.updateTraining(
      this.id.value,
      trainingData
    );
    request.subscribe((response) => {
      if (response["success"]) {
        this.modalTraining.close();
        this.allTrainings.refreshData();
        CustomNotifierService.getSuccessUpdate(this.notifier);
      } else if (response["errors"]) {
        this.errors = response["errors"];
        CustomNotifierService.getErrorsForm(
          this.errors,
          this.trainingForm,
          this.notifier
        );
      }
      this.loader = false;
    });
  }

  buildForms() {
    this.trainingForm = this.formBuilder.group({
      id: this.id,
      name: this.name,
      registry_id: this.registry_id,
      enterprise_id: this.enterprise_id,
      establishment_id: this.establishment_id,
      priority: this.priority,
      generic: this.generic,
      custom: this.custom,
      description: this.description,
      origin: this.origin,
      frequency_alert: this.frequency_alert,
      frequency_notification: this.frequency_notification,
    });

    this.trainingFrequencyForm = this.formBuilder.group({
      frequency: this.frequency,
      frequency_type: this.frequency_type,
      frequency_duration: this.frequency_duration,
      establishment_type_id: this.establishment_type_id,
      establishment_subtypes: this.establishment_subtypes,
      establishment_sub_subtype_id: this.establishment_sub_subtype_id,
      establishment_category_id: this.establishment_category_id,
      establishment_subcategory_id: this.establishment_subcategory_id,
    });
  }

  binModal(modal) {
    this.modalTraining = modal;
  }

  changeTabs(activeTab) {
    this.activeTab = activeTab;
  }
  prevTabs() {
    this.activeTab = "training";
  }
  nextTabs() {
    this.activeTab = "frequencies";
  }

  getErrorMessage(param) {
    return this.errors[param] && this.errors[param]["message"];
  }

  closeModal() {
    this.allTrainings.refreshData();
    this.router.navigate([this.previousRoute]).then(() => {});
  }
}
