import { Routes } from '@angular/router';

import { AllContentsComponent } from './all-contents/all-contents.component';
import { UpdateContentComponent } from './udpate-content/update-content.component';

export const contentsRoutes: Routes = [
  {
    path: 'contents',
    component: AllContentsComponent,
    children: [
      {
        path: ':content_id',
        component: UpdateContentComponent
      }
    ]
  },
];
