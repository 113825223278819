import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { HttpClient } from '@angular/common/http';
import { NotifierService } from 'angular-notifier';

@Injectable()
export class CheckpointsRequestService extends RequestService {
  constructor(
    protected http: HttpClient,
    protected notifier: NotifierService
  ) {
    super(http, notifier);
  }

  /// GET
  public getCheckpointsByInstallation(search, sort, order, limit, page, installation_id) {
    return this.get('checkpoints/installation/' + installation_id + '?' +
      'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getCheckpointsByRegistry(search, sort, order, limit, page, registry_id) {
    return this.get('checkpoints/registry/' + registry_id + '?' +
      'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getCheckpoint(checkpoint_id) {
    return this.get('checkpoint/' + checkpoint_id);
  }

  public getAllCheckpointsByMyInstallationEstablishmentAndRegistry(
    search, sort, order, limit, page, establishment_id, registry_id, installation_id, withReport) {
    return this.get('establishment/mine/' + establishment_id +
      '/installation/' + installation_id +
      '/registry/' + registry_id +
      '/checkpoints?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page + '&all=1' +
      '&withReport=' + withReport);
  }
  public getCheckpointsByMyInstallationEstablishmentAndRegistry(
    search, sort, order, limit, page, establishment_id, registry_id, installation_id, withReport) {
    return this.get('establishment/mine/' + establishment_id +
      '/installation/' + installation_id +
      '/registry/' + registry_id +
      '/checkpoints?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page +
      '&withReport=' + withReport);
  }
  public getMyEstablishmentCheckpoint(checkpoint_id) {
    return this.get('establishment/mine/checkpoint/' + checkpoint_id);
  }
  public getCheckpointsForAccess() {
    return this.get('checkpoints/access');
  }
  public getCheckpointsForAccessByEstablishment(establishment_id, registry_id) {
    return this.get('checkpoints/access/' + establishment_id + '/registry/' + registry_id);
  }
  public getCheckpointsForInternal(user_id) {
    return this.get('checkpoints/user/' + user_id);
  }
  public getRegistriesForInternal(user_id) {
    return this.get('checkpoints/user/' + user_id + '/registries');
  }
  public getCustomerCheckpoints(
    search, sort, order, limit, page, establishment_id, registry_id, installation_id) {
    return this.get('customers/establishments/' + establishment_id +
      '/registries/' + registry_id + '/installations/' + installation_id + '/checkpoints' +
      '?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }

  /* CHECKPOINTS DASHBOARD */
  public getNextCheckpointDashboard(search, sort, order, limit, page, registry_id, establishment_id, checkpoint_id, year, month) {
    if (establishment_id) {
      return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/next-checkpoints?' +
        'checkpoint_id=' + checkpoint_id + '&year=' + year + '&month=' + month +
        '&search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
    } else {
      return this.get('dashboard-registry/' + registry_id + '/next-checkpoints?' +
        'checkpoint_id=' + checkpoint_id + '&year=' + year + '&month=' + month +
        '&search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
    }
  }
  public getDelayCheckpointDashboard(search, sort, order, limit, page, registry_id, establishment_id, checkpoint_id, year, month) {
    if (establishment_id) {
      return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/delay-checkpoints?' +
        'checkpoint_id=' + checkpoint_id + '&year=' + year + '&month=' + month +
        '&search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
    } else {
      return this.get('dashboard-registry/' + registry_id + '/delay-checkpoints?' +
        'checkpoint_id=' + checkpoint_id + '&year=' + year + '&month=' + month +
        '&search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
    }
  }
  public getCustomerNextCheckpointDashboard(search, sort, order, limit, page, registry_id, establishment_id, checkpoint_id) {
    return this.get('customers/dashboard/' + establishment_id + '/' + registry_id + '/next-checkpoints?' +
      'checkpoint_id=' + checkpoint_id + '&search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getCustomerDelayCheckpointDashboard(search, sort, order, limit, page, registry_id, establishment_id, checkpoint_id) {
    return this.get('customers/dashboard/' + establishment_id + '/' + registry_id + '/delay-checkpoints?' +
      'checkpoint_id=' + checkpoint_id + '&search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }

  /* FILTER DASHBOARD */
  public getAllCheckpointsRegistry(registry_id, establishment_id) {
    return this.get('dashboard-registry/' + registry_id + '/checkpoints?establishment_id=' + establishment_id);
  }
  public getCustomerAllCheckpointsRegistry(registry_id, establishment_id) {
    return this.get('customers/dashboard/' + establishment_id + '/' + registry_id + '/checkpoints?establishment_id=' + establishment_id);
  }

  /// CREATE
  public createCheckpoint(checkpoint) {
    return this.post('checkpoint', { checkpoint });
  }
  public createCustomCheckpoint(registry_id, establishment_id, checkpoint) {
    return this.post('dashboard-registry/' + registry_id + '/establishment/' + establishment_id +
      '/installations/checkpoints/create', { checkpoint });
  }

  /// UPDATE
  public updateCheckpoint(checkpoint_id, checkpoint) {
    return this.put('checkpoint/' + checkpoint_id, { checkpoint });
  }
  public updateMyEstablishmentCheckpoint(checkpoint_id, checkpoint) {
    return this.put('establishment/mine/checkpoint/' + checkpoint_id, { checkpoint });
  }
  public duplicateCheckpoint(checkpoint_id) {
    return this.put('establishment/mine/checkpoint/' + checkpoint_id + '/duplicate', {});
  }
  public updateCheckpointsForAccess(enterprise_id, user_id, establishments) {
    return this.put('checkpoints/access', { enterprise_id, user_id, establishments });
  }
  public updateCheckpointsForInternal(user_id, establishments) {
    return this.put('checkpoints/user/' + user_id, { establishments });
  }
  public updateRegistriesForInternal(user_id, establishments) {
    return this.put('checkpoints/user/' + user_id + '/registries', { establishments });
  }
  public updatePatternForAccess(establishment_id, enterprise_id, user_id, establishments) {
    return this.put('checkpoints/access/' + establishment_id, { enterprise_id, user_id, establishments });
  }
  public updateProviderAccess(providers) {
    return this.put('checkpoints/access-transfer', {providers: providers});
  }

  /// DELETE
  public deleteCheckpoint(registry_id, establishment_id, checkpoint_id) {
    return this.delete('checkpoint/' + checkpoint_id);
  }
  public deleteCustomCheckpoint(registry_id, establishment_id, checkpoint_id) {
    return this.delete('dashboard-registry/' + registry_id + '/establishment/' + establishment_id +
      '/installations/checkpoints/' + checkpoint_id);
  }
}
