import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AllCotationsModule } from './all-cotations/all-cotations.module';

import { CotationsRequestService } from '../../services/cotations.request.service';
import { UpdateCotationModule } from './update-cotation/update-cotation.module';
import { UpdateCotationLimitModule } from './update-cotation-limit/update-cotation-limit.module';

@NgModule({
  imports: [
    CommonModule,
    AllCotationsModule,
    UpdateCotationModule,
    UpdateCotationLimitModule,
  ],
  providers: [
    CotationsRequestService
  ]
})
export class CotationsModule {}
