import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Subject } from 'rxjs';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { UsersRequestService } from '../../../services/users.request.service';

import { AllCotationsComponent } from '../all-cotations/all-cotations.component';
import { CotationsRequestService } from '../../../services/cotations.request.service';

@Component({
  selector: 'app-update-cotation-limit',
  templateUrl: './update-cotation-limit.component.html',
  styleUrls: ['./update-cotation-limit.component.scss']
})
export class UpdateCotationLimitComponent implements OnInit, AfterViewInit, OnDestroy {
  canCreate = false;
  canUpdate = false;

  requestInProgress = new Subject();
  requestCount = 0;

  itemForm: FormGroup;
  id = new FormControl('');
  enterprise_id = new FormControl('');
  limit_brut_low = new FormControl('', [Validators.required]);
  limit_brut_high = new FormControl('', [Validators.required]);
  limit_matrice_low = new FormControl('', [Validators.required]);
  limit_matrice_high = new FormControl('', [Validators.required]);

  cotation: any;
  index = -1;

  errors = {};

  view = '';
  loader = false;
  loaderContent = false;

  modalCotation = null;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private allCotations: AllCotationsComponent,
    private usersService: UsersRequestService,
    private cotationsService: CotationsRequestService,
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loaderContent = this.requestCount > 0;
    });

    if (this.activatedRoute.snapshot.parent.url.length === 1) {
      this.view = 'admin';
    } else {
      this.view = 'user';
    }

    this.itemForm = this.formBuilder.group({
      id: this.id,
      enterprise_id: this.enterprise_id,
      limit_brut_low: this.limit_brut_low,
      limit_brut_high: this.limit_brut_high,
      limit_matrice_low: this.limit_matrice_low,
      limit_matrice_high: this.limit_matrice_high
    });

    this.getCotation();
  }

  ngAfterViewInit() {
    this.modalCotation.open();
  }

  ngOnDestroy() {
    this.modalCotation.close();
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  getCotation() {
    this.requestInProgress.next(true);
    let request;
    if (this.view === 'admin') {
      request = this.cotationsService.getAdminCotationLimit();
    } else {
      request = this.cotationsService.getCotationLimit();
    }

    request.subscribe(response => {
      if (response['success']) {
        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];

        this.itemForm.patchValue(response['cotation_limit']);
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  updateCotation() {
    let request;

    this.errors = {};
    this.loader = true;

    if (this.view === 'admin') {
      if (this.id.value) {
        request = this.cotationsService.updateAdminCotationLimit(this.id.value, this.itemForm.value);
      } else {
        request = this.cotationsService.createAdminCotationLimit(this.itemForm.value);
      }
    } else {
      if (this.id.value && this.enterprise_id.value) {
        request = this.cotationsService.updateCotationLimit(this.id.value, this.itemForm.value);
      } else {
        request = this.cotationsService.createCotationLimit(this.itemForm.value);
      }
    }

    request.subscribe(response => {
      if (response['success']) {
        this.loader = false;
        this.modalCotation.close();
        CustomNotifierService.getSuccessUpdate(this.notifier);
      } else if (response['errors']) {
        this.loader = false;
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
    });
  }

  bindModal(modal) {
    this.modalCotation = modal;
  }

  closeModal() {
    this.router.navigate([this.activatedRoute.snapshot.parent.url.join('/')]).then(() => {
      this.allCotations.getCotations();
    });
  }
}
