import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Subject } from 'rxjs';

import { CustomNotifierService } from '../../../services/custom.notifier.service';

import { AllSanitaryCheckpointsComponent } from '../all-sanitary-checkpoints/all-sanitary-checkpoints.component';

import {
  establishment_types,
  ERP_subtypes,
  IGH_subtypes,
  BHC_categories,
  ERP_categories,
  ERP_first_subcategories,
  ERP_second_subcategories
} from '../../../../shared/establishments';
import EstablishmentSubSubtype from '../../../../shared/interfaces/establishment_sub_subtype';
import EstablishmentCategory from '../../../../shared/interfaces/establishment_category';
import EstablishmentSubCategory from '../../../../shared/interfaces/establishment_sub_category';
import { SanitaryCheckpointsRequestService } from '../../../services/sanitary-checkpoints.request.service';

@Component({
  selector: 'app-update-sanitary-checkpoint',
  templateUrl: './update-sanitary-checkpoint.component.html',
  styleUrls: ['./update-sanitary-checkpoint.component.scss']
})
export class UpdateSanitaryCheckpointComponent implements OnInit, AfterViewInit, OnDestroy {
  canUpdate = false;

  requestInProgress = new Subject();
  requestCount = 0;

  modalCheckpoint = null;
  previousRoute = '';

  errors = {};
  isNew = true;
  view = '';
  activeTab = 'sanitaryCheckpoint';

  types = [
    { name: 'Point de surveillance' },
    { name: 'Point de prélèvement' },
  ];

  show_subtypes = false;
  subtypes: any;
  show_sub_subtypes = false;
  sub_subtypes: EstablishmentSubSubtype[];
  show_categories = false;
  categories: EstablishmentCategory[];
  show_subcategories = false;
  subcategories: EstablishmentSubCategory[];

  establishment_types = establishment_types;
  ERP_subtypes = ERP_subtypes;
  BHC_categories = BHC_categories;
  IGH_subtypes = IGH_subtypes;
  ERP_categories = ERP_categories;
  ERP_first_subcategories = ERP_first_subcategories;
  ERP_second_subcategories = ERP_second_subcategories;

  sanitaryCheckpoint: any;

  sanitaryCheckpointForm: FormGroup;
  id = new FormControl('');
  name = new FormControl('', Validators.required);
  more_information = new FormControl('');
  recommendation = new FormControl(null, Validators.required);
  type = new FormControl(null, Validators.required);

  isNewFrequency = true;
  id_frequency: number;
  frequenciesForm: FormGroup;
  frequency_duration = new FormControl('', [Validators.required, Validators.min(0)]);
  frequency = new FormControl('', Validators.required);
  frequency_type = new FormControl('', Validators.required);
  establishment_type_id = new FormControl(null);
  establishment_subtypes = new FormControl([]);
  establishment_sub_subtype_id = new FormControl(null);
  establishment_category_id = new FormControl(null);
  establishment_subcategory_id = new FormControl(null);

  loader = false;
  loaderContent = false;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private allSanitaryCheckpoints: AllSanitaryCheckpointsComponent,
    private sanitaryCheckpointsService: SanitaryCheckpointsRequestService,
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loaderContent = this.requestCount > 0;
    });

    this.previousRoute = this.activatedRoute.snapshot.parent.url.join('/');

    this.sanitaryCheckpointForm = this.formBuilder.group({
      id: this.id,
      name: this.name,
      recommendation: this.recommendation,
      type: this.type,
      more_information: this.more_information,
    });

    this.frequenciesForm = this.formBuilder.group({
      frequency: this.frequency,
      frequency_type: this.frequency_type,
      frequency_duration: this.frequency_duration,
      establishment_type_id: this.establishment_type_id,
      establishment_subtypes: this.establishment_subtypes,
      establishment_sub_subtype_id: this.establishment_sub_subtype_id,
      establishment_category_id: this.establishment_category_id,
      establishment_subcategory_id: this.establishment_subcategory_id
    });

    if (this.activatedRoute.snapshot.params.sanitaryCheckpointId) {
      this.isNew = false;
      this.getSanitaryCheckpoint();
    }

    ERP_subtypes.forEach(value => {
      value['group'] = true;
    });

    IGH_subtypes.forEach(value => {
      value['group'] = true;
    });
  }

  ngAfterViewInit() {
    this.modalCheckpoint.open();
  }

  ngOnDestroy() {
    this.modalCheckpoint.close();
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  getSanitaryCheckpoint() {
    this.errors = {};
    this.requestInProgress.next(true);
    let request;

    request = this.sanitaryCheckpointsService.getSanitaryCheckpoint(this.activatedRoute.snapshot.params.sanitaryCheckpointId);

    request.subscribe(response => {
      if (response['success']) {
        this.canUpdate = response['canUpdate'];
        if (!this.canUpdate) {
          this.sanitaryCheckpointForm.disable();
        }
        this.sanitaryCheckpoint = response['sanitaryCheckpoint'];
        this.sanitaryCheckpointForm.patchValue(response['sanitaryCheckpoint']);
        this.changeTypeEstablishment(false);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.sanitaryCheckpointForm, this.notifier);
      }
      this.requestInProgress.next(false);
    });

  }

  changeTypeEstablishment(clear = true) {
    this.show_subtypes = false;
    this.show_sub_subtypes = false;
    this.show_categories = false;
    this.show_subcategories = false;

    const type_id = parseInt(this.establishment_type_id.value, 10);
    if (type_id === 1) {
      this.show_subtypes = true;
      this.subtypes = this.ERP_subtypes;

      this.show_sub_subtypes = false;
      this.sub_subtypes = [];

      this.show_categories = true;
      this.categories = this.ERP_categories;

      this.show_subcategories = false;
      this.subcategories = [];
    } else if (type_id === 2) {
      this.show_subtypes = false;
      this.subtypes = [];

      this.show_sub_subtypes = false;
      this.sub_subtypes = [];

      this.show_categories = false;
      this.categories = [];

      this.show_subcategories = false;
      this.subcategories = [];
    } else if (type_id === 3) {
      this.show_subtypes = false;
      this.subtypes = [];

      this.show_sub_subtypes = false;
      this.sub_subtypes = [];

      this.show_categories = true;
      this.categories = this.BHC_categories;

      this.show_subcategories = false;
      this.subcategories = [];
    } else if (type_id === 4) {
      this.show_subtypes = true;
      this.subtypes = this.IGH_subtypes;

      this.show_sub_subtypes = false;
      this.sub_subtypes = [];

      this.show_categories = false;
      this.categories = [];

      this.show_subcategories = false;
      this.subcategories = [];
    }

    if (clear) {
      this.establishment_subtypes.setValue(null);
      this.establishment_sub_subtype_id.setValue(null);
      this.establishment_category_id.setValue(null);
      this.establishment_subcategory_id.setValue(null);
    }
  }

  changeCategoryEstablishment() {
    const category_id = parseInt(this.establishment_category_id.value, 10);
    if (category_id === 1) {
      this.show_subcategories = true;
      this.subcategories = this.ERP_first_subcategories;
    } else if (category_id === 2) {
      this.show_subcategories = true;
      this.subcategories = this.ERP_second_subcategories;
    } else {
      this.show_subcategories = false;
      this.subcategories = [];
    }
  }

  createSanitaryCheckpoint() {
    this.errors = {};
    this.loader = true;

    if (!this.sanitaryCheckpoint || !this.sanitaryCheckpoint.frequencies) {
      this.sanitaryCheckpoint = { frequencies: [] };
    }
    const checkpoint = { ...this.sanitaryCheckpointForm.value,
      frequencies: this.sanitaryCheckpoint.frequencies
    };

    this.sanitaryCheckpointsService.createSanitaryCheckpoint(checkpoint).subscribe(response => {
      if (response['success']) {
        this.modalCheckpoint.close();
        this.allSanitaryCheckpoints.refreshData();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.sanitaryCheckpointForm, this.notifier);
      }
      this.loader = false;
    });
  }

  updateSanitaryCheckpoint() {
    this.errors = {};
    this.loader = true;

    if (!this.sanitaryCheckpoint || !this.sanitaryCheckpoint.frequencies) {
      this.sanitaryCheckpoint = { frequencies: [] };
    }
    const checkpoint = {
      ...this.sanitaryCheckpointForm.value,
      frequencies: this.sanitaryCheckpoint.frequencies
    };

    this.sanitaryCheckpointsService.updateSanitaryCheckpoint(this.id.value, checkpoint).subscribe(response => {
      if (response['success']) {
        this.modalCheckpoint.close();
        this.allSanitaryCheckpoints.refreshData();
        CustomNotifierService.getSuccessUpdate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.sanitaryCheckpointForm, this.notifier);
      }
      this.loader = false;
    });
  }

  changeFrequency() {
    this.frequency.updateValueAndValidity();
    this.frequency_duration.updateValueAndValidity();
  }

  getTypes(frequency) {
    let str = '';
    frequency.establishment_type_id = parseInt(frequency.establishment_type_id, 10);
    const type = this.establishment_types.find(elem => {
      return elem.id === frequency.establishment_type_id;
    });
    if (type) {
      str += type.name + ' ';
      if (frequency.establishment_subtypes && type.hasSubtypes) {
        str += '- ';
        frequency.establishment_subtypes.forEach(establishment_subtype => {
          establishment_subtype = parseInt(establishment_subtype, 10);
          const subtype = type.subtypes.find(element => {
            return element.id === establishment_subtype;
          });
          str += subtype.name + ' ';
        });
      }
      if (frequency.establishment_category_id && type.hasCategories) {
        str += '- ';
        frequency.establishment_category_id = parseInt(frequency.establishment_category_id, 10);
        const category = type.categories.find(element => {
          return element.id === frequency.establishment_category_id;
        });
        str += category.name + ' ';

        if (frequency.establishment_subcategory_id && category.hasSubCategories) {
          str += '- ';
          frequency.establishment_subcategory_id = parseInt(frequency.establishment_subcategory_id, 10);
          const subcategory = category.subCategories.find(element => {
            return element.id === frequency.establishment_subcategory_id;
          });
          str += subcategory.name + ' ';
        }
      }
    }
    return str;
  }

  createFrequency() {
    if (!this.sanitaryCheckpoint) {
      this.sanitaryCheckpoint = { frequencies: [] };
    } else if (this.sanitaryCheckpoint.frequencies === null) {
      this.sanitaryCheckpoint.frequencies = [];
    }

    if (this.establishment_subtypes.value) {
      this.frequenciesForm.value.establishment_subtypes = this.establishment_subtypes.value;
    }
    this.sanitaryCheckpoint.frequencies.push(this.frequenciesForm.value);

    this.establishment_type_id.setValue(null);
    this.establishment_subtypes.setValue([]);
    this.frequency_duration.setValue('');
    this.frequency_type.setValue('');
    this.frequency.setValue('');
    this.changeTypeEstablishment();
  }

  updateFrequency() {
    if (this.establishment_subtypes.value) {
      this.frequenciesForm.value.establishment_subtypes = this.establishment_subtypes.value;
    }
    this.sanitaryCheckpoint.frequencies[this.id_frequency] = (this.frequenciesForm.value);

    this.isNewFrequency = true;
    this.id_frequency = null;

    this.establishment_type_id.setValue(null);
    this.establishment_subtypes.setValue([]);
    this.frequency_duration.setValue('');
    this.frequency_type.setValue('');
    this.frequency.setValue('');
    this.changeTypeEstablishment();
  }

  editFrequency(frequency, key) {
    this.isNewFrequency = false;
    this.id_frequency = key;
    this.frequenciesForm.patchValue(frequency);
    this.changeTypeEstablishment(false);
  }

  cancelEditFrequency() {
    this.isNewFrequency = true;
    this.id_frequency = null;

    this.establishment_type_id.setValue(null);
    this.establishment_subtypes.setValue([]);
    this.frequency_duration.setValue(null);
    this.frequency_type.setValue(null);
    this.frequency.setValue(null);
    this.changeTypeEstablishment();
  }

  deleteFrequency(key) {
    this.sanitaryCheckpoint.frequencies.splice(key, 1);

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  binModal(modal) {
    this.modalCheckpoint = modal;
  }

  closeModal() {
    this.allSanitaryCheckpoints.refreshData();
    this.router.navigate([this.previousRoute]).then(() => {});
  }

  changeTabs(activeTab) {
    this.activeTab = activeTab;
  }
  prevTabs() {
    this.activeTab = 'sanitaryCheckpoint';
  }
  nextTabs() {
    this.activeTab = 'frequencies';
  }
}
