import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-pagination-modal',
  templateUrl: './pagination-modal.component.html',
  styleUrls: ['./pagination-modal.component.scss']
})
export class PaginationModalComponent implements OnInit {
  @Input() count_elements: any;
  @Input() limit: any;
  @Input() current_page: any;
  @Input() total_pages: any;
  @Input() elements_length: any;
  @Input() classes: any;
  @Input() dropdown_items_by_page: any[] = [];
  @Output() returnEvent = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
    if (!this.dropdown_items_by_page.length) {
      let i = 12;
      while (i < 97) { // Fill the array with the default values [12, 24, 48, 96]
        this.dropdown_items_by_page.push(i);
        i *= 2;
      }
    }
  }

  returnAction(action: string, value: number) {
    this.returnEvent.emit({ action: action, value: value });
  }
}
