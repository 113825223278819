import { Routes } from "@angular/router";
import { AjoutSessionFormationComponent } from "./ajout-session-formation/ajout-session-formation.component";
import { FormationsEmployeeComponent } from "./formations-employee/formations-employee.component";
import { EmployeesTrainingComponent } from "./get-employees-training/get-employees-training.component";
import { UpdateEmployeeComponent } from "./update-employee/update-employee.component";

export const employeesTrainingRoutes: Routes = [
  {
    path: "employees",
    component: EmployeesTrainingComponent,
    children: [
      {
        path: "create",
        component: UpdateEmployeeComponent,
      },
      {
        path: ":employee_id",
        component: UpdateEmployeeComponent,
      },
    ],
  },
  {
    path: "employees/:employee_id/formations_list",
    component: FormationsEmployeeComponent,
    children: [
      {
        path: "create",
        component: AjoutSessionFormationComponent,
      },
    ],
  },
];
