<div class="main-content">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col">
        <div class="header mb-0">
          <div class="header-body no-border">
            <div class="row align-items-center">
              <div class="col">
                <h1 class="header-title">Formations</h1>

                <small class="form-text text-muted"> Liste des sessions </small>

                <app-breadcrumbs
                  [currentPage]="pageName"
                  [names]="previousPageName"
                  [routes]="previousPageRoute"
                >
                </app-breadcrumbs>
              </div>

              <div
                class="col-12 col-md-auto mt-2 mt-md-0 mb-0"
                *ngIf="canCreate"
              >
                <a
                  class="btn btn-primary d-block d-md-inline-block"
                  [routerLink]="[currentRoute + 'create']"
                >
                  Ajouter une session formation
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col">
            <app-searchbar
              [(ngModel)]="search"
              (keyup)="searchData()"
              ngDefaultControl
            ></app-searchbar>
          </div>
        </div>

        <div class="is-loading loader-content" *ngIf="loader"></div>

        <div class="tab-content" *ngIf="!loader && sessionsTrainings.length">
          <div
            class="tab-pane fade active show"
            id="tabPaneOne"
            role="tabpanel"
          >
            <div class="row listAlias">
              <div class="col-12 d-flex flex-column">
                <div class="card flex-fill">
                  <div class="card-body">
                    <div class="table-responsive">
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th
                              class="href"
                              (click)="filter('Establishments.name')"
                            >
                              <a class="text-muted">Établissement</a>
                              <span
                                class="fe fe-arrow-down"
                                *ngIf="
                                  sort === 'Establishments.name' &&
                                  order === 'asc'
                                "
                              ></span>
                              <span
                                class="fe fe-arrow-up"
                                *ngIf="
                                  sort === 'Establishments.name' &&
                                  order === 'desc'
                                "
                              ></span>
                              <span
                                class="fe fe-arrow-right"
                                *ngIf="sort !== 'Establishments.name'"
                              ></span>
                            </th>

                            <th
                              class="href"
                              (click)="filter('DateTrainings.date')"
                            >
                              <a class="text-muted">Date</a>
                              <span
                                class="fe fe-arrow-down"
                                *ngIf="
                                  sort === 'DateTrainings.date' &&
                                  order === 'asc'
                                "
                              ></span>
                              <span
                                class="fe fe-arrow-up"
                                *ngIf="
                                  sort === 'DateTrainings.date' &&
                                  order === 'desc'
                                "
                              ></span>
                              <span
                                class="fe fe-arrow-right"
                                *ngIf="sort !== 'DateTrainings.date'"
                              ></span>
                            </th>
                            <th
                              class="href"
                              (click)="filter('DateTrainings.duration')"
                            >
                              <a class="text-muted">Durée</a>
                              <span
                                class="fe fe-arrow-down"
                                *ngIf="
                                  sort === 'DateTrainings.duration' &&
                                  order === 'asc'
                                "
                              ></span>
                              <span
                                class="fe fe-arrow-up"
                                *ngIf="
                                  sort === 'DateTrainings.duration' &&
                                  order === 'desc'
                                "
                              ></span>
                              <span
                                class="fe fe-arrow-right"
                                *ngIf="sort !== 'DateTrainings.duration'"
                              ></span>
                            </th>
                            <th
                              class="href"
                              (click)="filter('DateTrainings.instructor')"
                            >
                              <a class="text-muted">Formation dispensée</a>
                              <span
                                class="fe fe-arrow-down"
                                *ngIf="
                                  sort === 'DateTrainings.instructor' &&
                                  order === 'asc'
                                "
                              ></span>
                              <span
                                class="fe fe-arrow-up"
                                *ngIf="
                                  sort === 'DateTrainings.instructor' &&
                                  order === 'desc'
                                "
                              ></span>
                              <span
                                class="fe fe-arrow-right"
                                *ngIf="sort !== 'DateTrainings.instructor'"
                              ></span>
                            </th>
                            <th class="href">
                              <a class="text-muted"
                                >Nombre de personnes formées</a
                              >
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            class="dropdown"
                            *ngFor="let sessionsTraining of sessionsTrainings"
                          >
                            <td>
                              <a
                                class="href"
                                [routerLink]="[
                                  currentRoute + sessionsTraining.id
                                ]"
                              >
                                {{
                                  sessionsTraining.training.establishment.name
                                }}
                              </a>
                            </td>
                            <td>
                              <a
                                class="href"
                                [routerLink]="[
                                  currentRoute + sessionsTraining.id
                                ]"
                                >{{
                                  sessionsTraining.date | date: "dd/MM/yyyy"
                                }}
                              </a>
                            </td>
                            <td>
                              <a
                                class="href"
                                [routerLink]="[
                                  currentRoute + sessionsTraining.id
                                ]"
                                >{{
                                  sessionsTraining.duration
                                    | date: "h:mm":"+0000"
                                }}
                              </a>
                            </td>
                            <td>
                              <a
                                class="href"
                                [routerLink]="[
                                  currentRoute + sessionsTraining.id
                                ]"
                                >{{ sessionsTraining.instructor }}
                              </a>
                            </td>
                            <td>
                              <a
                                class="href"
                                [routerLink]="[
                                  currentRoute + sessionsTraining.id
                                ]"
                              >
                                {{ sessionsTraining.date_training_employees }}
                              </a>
                            </td>
                            <td class="d-flex flex-row-reverse">
                              <a
                                class="dropdown-ellipses dropdown-toggle href"
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <i class="fe fe-more-vertical"></i>
                              </a>

                              <div class="dropdown-menu dropdown-menu-right">
                                <a
                                  class="dropdown-item href"
                                  [routerLink]="[
                                    currentRoute + sessionsTraining.id
                                  ]"
                                >
                                  {{
                                    canUpdate
                                      ? "Éditer la session"
                                      : "Consulter la session"
                                  }}
                                </a>

                                <a
                                  class="dropdown-item href"
                                  (click)="
                                    modalConfirmData.open(sessionsTraining.id)
                                  "
                                  *ngIf="canDelete"
                                >
                                  Supprimer la session
                                </a>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <app-pagination
                      (returnEvent)="dispatchPagination($event)"
                      [count_elements]="count"
                      [current_page]="page"
                      [elements_length]="sessionsTrainings.length"
                      [limit]="limit"
                      [total_pages]="pages"
                      ngDefaultControl
                    >
                    </app-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="loader-content"
          *ngIf="!loader && !sessionsTrainings.length"
        >
          <p>Aucun résultat</p>
        </div>
      </div>
    </div>
  </div>
</div>

<app-confirm-modal [type]="'delete_data'" #confirmData>{{
  bindModalConfirmData(confirmData)
}}</app-confirm-modal>

<router-outlet></router-outlet>
