import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AllPrescriptionsComponent } from './all-prescriptions.component';
import { BreadcrumbsModule } from '../../../core/breadcrumbs/breadcrumbs.module';
import { ConfirmModalModule } from '../../../core/confirm-modal/confirm-modal.module';
import { SearchbarModule } from '../../../core/searchbar/searchbar.module';
import { PaginationModule } from '../../../core/pagination/pagination.module';
import { VideoModalModule } from '../../../core/video-modal/video-modal.module';
import { PdfViewerModule } from '../../../core/pdf-viewer/pdf-viewer.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    ConfirmModalModule,
    BreadcrumbsModule,
    SearchbarModule,
    PaginationModule,
    VideoModalModule,
    PdfViewerModule
  ],
  declarations: [AllPrescriptionsComponent]
})

export class AllPrescriptionsModule {}
