import { Component, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  connectedAs = false;
  registries = [];

  constructor(
    private notifier: NotifierService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    if (this.activatedRoute.snapshot.children.length && !this.activatedRoute.snapshot.children[0].url.length) {
      const url = this.authService.checkUrlStats(JSON.parse(localStorage.getItem('registries')));
      this.router.navigate([url]).then(() => {});
    }
  }
}
