<div class="d-flex align-items-center bg-auth border-top border-top-2 border-primary">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-md-5 col-xl-4 my-5">
        <div class="text-center mb-5">
          <img src="../../../../assets/img/logo_batiregistre.png" alt="Logo Batiregistre" />
        </div>

        <h1 class="text-center mb-3">
          Créer mon mot de passe
        </h1>

        <form [formGroup]="loginForm">
          <div class="card bg-light border" *ngIf="!expired && !invalid">
            <div class="card-body">
              <p class="mb-2">Exigences relatives au mot de passe</p>

              <p class="small text-muted mb-2">
                Pour créer un nouveau mot de passe, il doit contenir au minimum 8 caractères et vous devez satisfaire à au moins 3 des 4 des exigences suivantes :
              </p>

              <ul class="small text-muted pl-4 mb-0">
                <li>
                  Au moins une majuscule
                </li>

                <li>
                  Au moins une minuscule
                </li>

                <li>
                  Au moins un caractère spécial
                </li>

                <li>
                  Au moins un chiffre
                </li>
              </ul>
            </div>
          </div>
          <div class="card bg-light border" *ngIf="expired || invalid">
            <div class="card-body">
              <p class="small text-muted mb-2">
                Le token n'est pas ou plus valide. Veuillez relancer un email pour recevoir un nouveau lien.
              </p>
            </div>
          </div>

          <div class="form-group" *ngIf="expired || invalid">
            <label for="validationEmail" required>Email</label>
            <input type="email" formControlName="email" class="form-control" id="validationEmail" placeholder="Email" [ngClass]="{ 'is-invalid': email.invalid && (email.dirty || email.touched) }" autocomplete>
            <div *ngIf="email.invalid && (email.dirty || email.touched)" class="invalid-feedback">
              {{ getErrorMessage("email") }}
            </div>
          </div>

          <div class="form-group" *ngIf="!expired && !invalid">
            <label for="validationPassword" required>Mot de passe</label>
            <div class="input-group input-group-merge">
              <input type="{{ showPassword ? 'text' : 'password' }}" formControlName="password" class="form-control form-control-appended"
                     id="validationPassword" placeholder="Mot de passe"
                     [ngClass]="{ 'is-invalid': password.invalid && (password.dirty || password.touched) }">
              <div class="input-group-append" (click)="toggle()">
                <span class="input-group-text"><i class="fe {{ showPassword ? 'fe-eye' : 'fe-eye-off' }}"></i></span>
              </div>
              <div *ngIf="password.invalid && (password.dirty || password.touched)" class="invalid-feedback">
                {{ getErrorMessage("password") }}
              </div>
            </div>
          </div>

          <div class="form-group" *ngIf="!expired && !invalid">
            <label for="validationPasswordConfirm" required>Confirmez mot de passe</label>
            <div class="input-group input-group-merge">
              <input type="{{ showPassword ? 'text' : 'password' }}" formControlName="confirm_password" class="form-control form-control-appended"
                     id="validationPasswordConfirm" placeholder="Mot de passe"
                     [ngClass]="{ 'is-invalid': confirm_password.invalid && (confirm_password.dirty || confirm_password.touched) }">
              <div class="input-group-append" (click)="toggle()">
                <span class="input-group-text"><i class="fe {{ showPassword ? 'fe-eye' : 'fe-eye-off' }}"></i></span>
              </div>
              <div *ngIf="confirm_password.invalid && (confirm_password.dirty || confirm_password.touched)" class="invalid-feedback">
                {{ getErrorMessage("confirm_password") }}
              </div>
            </div>
          </div>

          <button class="btn btn-lg btn-block btn-primary mb-3" (click)="changePassword()" [ngClass]="{ 'is-loading is-loading-sm': isSigningIn }" [disabled]="isSigningIn || loginForm.invalid || password.value !== confirm_password.value" *ngIf="!expired && !invalid">
            Créer
          </button>
          <button class="btn btn-lg btn-block btn-primary mb-3" (click)="resendEmail()" [ngClass]="{ 'is-loading is-loading-sm': isSigningIn }" [disabled]="isSigningIn || loginForm.invalid" *ngIf="expired || invalid">
            Envoyer
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
