<div class="main-content">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col">
        <div class="header mb-0">
          <div class="header-body no-border">
            <div class="row align-items-center">
              <div class="col">
                <h1 class="header-title">{{ view === 'providers' ? 'Prestataires' : 'Entreprises' }}</h1>

                <small class="form-text text-muted">Liste de mes clients</small>

                <app-breadcrumbs [currentPage]="pageName" [names]="previousPageName" [routes]="previousPageRoute"></app-breadcrumbs>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col">
            <app-searchbar [(ngModel)]="search" (keyup)="searchData()" ngDefaultControl></app-searchbar>
          </div>

          <div class="col-auto">
            <div class="nav btn-group" role="tablist">
              <button class="btn btn-lg btn-white tooltipHtml" data-placement="bottom" title="Mosaïque" [ngClass]="{ 'active': activeTabsList === 'tabPaneOne'}" (click)="changeActiveTabsList('tabPaneOne')">
                <span class="fe fe-grid"></span>
              </button>

              <button class="btn btn-lg btn-white tooltipHtml" data-placement="bottom" title="Liste" [ngClass]="{ 'active': activeTabsList === 'tabPaneTwo'}" (click)="changeActiveTabsList('tabPaneTwo')">
                <span class="fe fe-list"></span>
              </button>

              <button class="btn btn-lg btn-white tooltipHtml border-right-radius" data-toggle="dropdown" data-placement="bottom" title="Filtre">
                <span class="fe fe-filter"></span>
              </button>
              <span class="dropdown-menu dropdown-menu-right">
                <a class="dropdown-item href" (click)="refreshData('Enterprises.name', 'asc', 1)" aria-label="Tri des entreprises : Nom - Ordre croissant">
                  Nom A-Z
                </a>

                <a class="dropdown-item href" (click)="refreshData('Enterprises.name', 'desc', 1)" aria-label="Tri des entreprises : Nom - Ordre décroissant">
                  Nom Z-A
                </a>

                <a class="dropdown-item href" (click)="refreshData('Enterprises.zipcode', 'asc', 1)" aria-label="Tri des entreprises : Code postal - Ordre croissant">
                  Code postal A-Z
                </a>

                <a class="dropdown-item href" (click)="refreshData('Enterprises.zipcode', 'desc', 1)" aria-label="Tri des entreprises : Code postal - Ordre décroissant">
                  Code postal Z-A
                </a>

                <a class="dropdown-item href" (click)="refreshData('Enterprises.city', 'asc', 1)" aria-label="Tri des entreprises : Ville - Ordre croissant">
                  Ville A-Z
                </a>

                <a class="dropdown-item href" (click)="refreshData('Enterprises.city', 'desc', 1)" aria-label="Tri des entreprises : Ville - Ordre décroissant">
                  Ville Z-A
                </a>
              </span>

            </div>
          </div>
        </div>

        <div class="is-loading loader-content" *ngIf="loader"></div>

        <div class="tab-content" [hidden]="loader || !enterprises.length">
          <div class="tab-pane fade" id="tabPaneOne" role="tabpanel" [ngClass]="{ 'show active': activeTabsList === 'tabPaneOne'}">
            <div class="row listAlias">
              <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 d-flex flex-column" *ngFor="let enterprise of enterprises">
                <div class="card flex-fill">
                  <span class="card-img-top card-img-center" [style.backgroundImage]="'url(' + environment.SERVER_HOST + 'img/enterprises/' + enterprise.picture + ')'" *ngIf="enterprise.picture != '' && enterprise.picture != null"></span>

                  <span class="card-img-top card-img-center" [style.background-image]="'url(../assets/img/establishments/building.png)'" *ngIf="enterprise.picture == '' || enterprise.picture == null"></span>

                  <div class="card-body">
                    <div class="row align-items-center">
                      <div class="col overflow-hidden">
                        <h4 class="card-title name text-truncate mb-2">
                          <a [routerLink]="[updateRoute + enterprise.id + '/establishments']">
                            {{ enterprise.name }}
                          </a>
                        </h4>

                        <p class="card-text small text-muted text-truncate mb-0">
                          <span *ngIf="enterprise.address != null && enterprise.zipcode != null && enterprise.city != null">
                            {{ enterprise.address }} <br>
                            {{ enterprise.zipcode + ' ' + enterprise.city }}
                          </span>

                          <span *ngIf="enterprise.address == null || enterprise.zipcode == null || enterprise.city == null">
                            Aucune adresse renseignée par l'entreprise
                          </span>
                        </p>
                      </div>

                      <div class="col-auto">
                        <div class="dropdown">
                          <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fe fe-more-vertical"></i>
                          </a>

                          <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item href" [routerLink]="[updateRoute + enterprise.id + '/establishments']">
                              Liste des établissements
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="tab-pane fade" id="tabPaneTwo" role="tabpanel" [ngClass]="{ 'show active': activeTabsList === 'tabPaneTwo'}">
            <div class="row list">
              <div class="col-12">
                <div class="card">
                  <div class="card-body">
                    <div class="table-responsive">
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th class="href" (click)="filter('Enterprises.name')">
                              <a class="text-muted">Société</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'Enterprises.name' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'Enterprises.name' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'Enterprises.name'"></span>
                            </th>
                            <th *ngIf="view !== 'providers'">Adresse</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="dropdown" *ngFor="let enterprise of enterprises">
                            <td>
                              <a class="href" [routerLink]="[updateRoute + enterprise.id + '/establishments']">
                                {{ enterprise.name }}
                              </a>
                            </td>
                            <td>
                              <span *ngIf="enterprise.address != null && enterprise.zipcode != null && enterprise.city != null">
                                {{ enterprise.address }} <br>
                                {{ enterprise.zipcode + ' ' + enterprise.city }}
                              </span>

                              <span *ngIf="enterprise.address == null || enterprise.zipcode == null || enterprise.city == null">
                                  Aucune adresse renseignée par l'entreprise
                                </span>
                            </td>
                            <td class="d-flex flex-row-reverse">
                              <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fe fe-more-vertical"></i>
                              </a>

                              <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item href" [routerLink]="[updateRoute + enterprise.id + '/establishments']">
                                  Liste des établissements
                                </a>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row pagination-content" aria-label="Pagination">
            <div class="col-12 d-flex flex-column">
              <div class="card">
                <app-pagination (returnEvent)="dispatchPagination($event)"
                                [classes]="'card-body'"
                                [count_elements]="count"
                                [current_page]="page"
                                [elements_length]="enterprises.length"
                                [limit]="limit"
                                [total_pages]="pages"
                                ngDefaultControl>
                </app-pagination>
              </div>
            </div>
          </div>
        </div>

        <div class="loader-content" *ngIf="!loader && !enterprises.length">
          <p>Aucun résultat</p>
        </div>
      </div>
    </div>
  </div>
</div>

<router-outlet></router-outlet>
