import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';

import { CustomNotifierService } from '../../../services/custom.notifier.service';

import { AllInstallationsComponent } from '../all-installations/all-installations.component';
import { InstallationsRequestService } from '../../../services/installations.request.service';
import Installation from '../../../../shared/interfaces/installation';

@Component({
  selector: 'app-update-installation-custom',
  templateUrl: './update-installation-custom.component.html',
  styleUrls: ['./update-installation-custom.component.scss']
})
export class UpdateInstallationCustomComponent implements OnInit, AfterViewInit, OnDestroy {
  canUpdate = false;
  errors = {};

  loader = false;
  loaderContent = false;

  isNew = true;
  archive = false;

  previousRoute = '';

  modalInstallation = null;

  installations: Installation[];

  installationForm: FormGroup;
  id = new FormControl('');
  description = new FormControl('');

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private allInstallations: AllInstallationsComponent,
    private installationsService: InstallationsRequestService
  ) {}

  ngOnInit() {
    this.previousRoute = this.activatedRoute.snapshot.parent.url.join('/');

    if (this.activatedRoute.snapshot.params.installation_id) {
      this.getInstallation();
      this.isNew = false;
    }

    this.installationForm = this.formBuilder.group({
      id: this.id,
      description: this.description
    });
  }

  ngAfterViewInit() {
    this.modalInstallation.open();
  }

  ngOnDestroy() {
    this.modalInstallation.close();
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  getInstallation() {
    this.errors = {};
    this.loaderContent = true;

    this.installationsService.getInstallationCustom(
      this.activatedRoute.snapshot.params.installation_id
    ).subscribe(response => {
      if (response['success']) {
        this.canUpdate = response['canUpdate'];
        if (response['installation']) {
          this.installationForm.patchValue( response['installation']);
        }
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.installationForm, this.notifier);
      }
      this.loaderContent = false;
    });

  }

  createInstallation() {
    this.errors = {};
    this.loader = true;

    this.installationsService.createInstallationCustom(
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.installationForm.value
    ).subscribe(response => {
      if (response['success']) {
        this.modalInstallation.close();
        this.allInstallations.refreshData();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.installationForm, this.notifier);
      }
      this.loader = false;
    });
  }

  updateInstallation() {
    this.errors = {};
    this.loader = true;

    this.installationsService.updateInstallationCustom(
      this.activatedRoute.snapshot.params.installation_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.installationForm.value
    ).subscribe(response => {
      if (response['success']) {
        this.modalInstallation.close();
        this.allInstallations.refreshData();
        CustomNotifierService.getSuccessUpdate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.installationForm, this.notifier);
      }
      this.loader = false;
    });
  }

  binModal(modal) {
    this.modalInstallation = modal;
  }

  closeModal() {
    this.allInstallations.refreshData();
    this.router.navigate([this.previousRoute]).then(() => {
    });
  }
}
