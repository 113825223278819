import { Routes } from '@angular/router';

import { ModalLogsComponent } from '../logs/modal-logs/modal-logs.component';
import { AllSanitaryOperationsComponent } from './all-sanitary-operations/all-sanitary-operations.component';
import { UpdateSanitaryOperationComponent } from './update-sanitary-operation/update-sanitary-operation.component';
import { AllSanitaryOperationFramesComponent } from './all-sanitary-operation-frames/all-sanitary-operation-frames.component';
import { UpdateSanitaryOperationFrameComponent } from './update-sanitary-operation-frame/update-sanitary-operation-frame.component';

export const sanitaryOperationsRoutes: Routes = [
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/sanitary-operations',
    component: AllSanitaryOperationsComponent,
    children: [
      {
        path: 'create',
        component: UpdateSanitaryOperationComponent
      },
      {
        path: ':sanitary_operation_id',
        component: UpdateSanitaryOperationComponent
      },
      {
        path: ':sanitary_operation_id/logs',
        component: ModalLogsComponent
      }
    ]
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/sanitary-operations/:sanitary_operation_id/operation-frames',
    component: AllSanitaryOperationFramesComponent,
    children: [
      {
        path: 'create',
        component: UpdateSanitaryOperationFrameComponent
      },
      {
        path: ':sanitary_operation_frame_id',
        component: UpdateSanitaryOperationFrameComponent
      },
      {
        path: ':sanitary_operation_frame_id/logs',
        component: ModalLogsComponent
      }
    ]
  },
];
