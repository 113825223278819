import { Routes } from '@angular/router';

import { AllReportWorksheetsComponent } from './all-report-worksheets/all-report-worksheets.component';
import { UpdateReportWorksheetComponent } from './update-report-worksheet/update-report-worksheet.component';

import { AllWorksComponent } from './all-works/all-works.component';
import { UpdateWorkComponent } from './update-work/update-work.component';
import { ModalLogsComponent } from '../logs/modal-logs/modal-logs.component';

export const reportWorksheetsRoutes: Routes = [
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/report-worksheets',
    component: AllReportWorksheetsComponent,
    children: [
      {
        path: 'create',
        component: UpdateReportWorksheetComponent
      },
      {
        path: ':report_id',
        component: UpdateReportWorksheetComponent
      },
      {
        path: ':report_id/logs',
        component: ModalLogsComponent,
      }
    ]
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/report-worksheets/:report_id/works',
    component: AllWorksComponent,
    children: [
      {
        path: 'create',
        component: UpdateWorkComponent
      },
      {
        path: ':work_id',
        component: UpdateWorkComponent
      }
    ]
  }
];
