import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { frequencies } from '../../../../shared/frequencies';

import { AuthService } from '../../../core/auth/auth.service';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { CheckpointsRequestService } from '../../../services/checkpoints.request.service';
import { RegistriesRequestService } from '../../../services/registries.request.service';
import { EstablishmentsRequestService } from '../../../services/establishments.request.service';
import { InstallationsRequestService } from '../../../services/installations.request.service';
import { ConfirmModalComponent } from '../../../core/confirm-modal/confirm-modal.component';

import Checkpoint from '../../../../shared/interfaces/checkpoint';
import Registry from '../../../../shared/interfaces/registry';
import Establishment from '../../../../shared/interfaces/establishment';
import Installation from '../../../../shared/interfaces/installation';
import Frequency from '../../../../shared/interfaces/frequency';
import { establishment_types } from '../../../../shared/establishments';

@Component({
  selector: 'app-all-checkpoints',
  templateUrl: './all-checkpoints.component.html',
  styleUrls: ['./all-checkpoints.component.scss']
})
export class AllCheckpointsComponent implements OnInit, AfterViewInit, OnDestroy {
  changeEnterprise: Subscription;
  environment = environment;
  establishment_types = establishment_types;

  canUpdate = false;
  canCreate = false;
  canDelete = false;
  canLog = false;
  withReport = '';

  requestInProgress = new Subject();
  requestCount = 0;

  pageName: string;
  previousPageName: string[] = [];
  previousPageRoute: string[] = [];
  currentRoute = '';

  registry: Registry;
  establishment: Establishment;
  installation: Installation;
  checkpoints: Checkpoint[] = [];
  count = 0;
  pages = 1;

  search = '';
  sort = 'Checkpoints.name';
  order = 'asc';
  limit = 12;
  page = 1;

  show_inactive = false;

  view = '';
  errors = {};
  timeout: any;
  loader = false;

  modalConfirmData: ConfirmModalComponent = null;
  modalConfirmReport: ConfirmModalComponent = null;

  constructor(
    private router: Router,
    private authService: AuthService,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private registriesService: RegistriesRequestService,
    private checkpointsService: CheckpointsRequestService,
    private installationsService: InstallationsRequestService,
    private establishmentsService: EstablishmentsRequestService,
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loader = this.requestCount > 0;
    });

    const is_inactive = JSON.parse(localStorage.getItem('show_inactive_installations'));
    if (is_inactive !== null) {
      this.show_inactive = is_inactive;
    }

    this.changeEnterprise = this.authService.getEnterprise().subscribe(() => {
      this.getCheckpoints();
    });

    if (this.activatedRoute.snapshot.params.establishment_id) {
      this.view = 'establishment';
      this.getRegistry();
      this.getEstablishment();
      this.getInstallation();
    } else if (this.activatedRoute.snapshot.params.installation_id) {
      this.view = 'installation';
      this.getInstallation();
    } else if (this.activatedRoute.snapshot.params.registry_id) {
      this.view = 'registry';
      this.getRegistry();
    }

    this.createBreadcrumbs();
    if (this.authService.loaded) {
      this.getCheckpoints();
    }
  }

  ngAfterViewInit() {
    this.modalConfirmData.eventOnClose.subscribe(event => {
      if (event) {
        let request;
        this.requestInProgress.next(true);

        if (this.view === 'establishment') {
          request = this.checkpointsService.deleteCustomCheckpoint(
            this.activatedRoute.snapshot.params.registry_id,
            this.activatedRoute.snapshot.params.establishment_id,
            event
          );
        } else {
          request = this.checkpointsService.deleteCheckpoint(
            this.activatedRoute.snapshot.params.registry_id,
            this.activatedRoute.snapshot.params.establishment_id,
            event
          );
        }

        request.subscribe(response => {
          if (response['success']) {
            this.getCheckpoints();
            CustomNotifierService.getSuccessDelete(this.notifier);
          } else if (response['errors']) {
            CustomNotifierService.getErrors(response['errors'], this.notifier);
          }
          this.requestInProgress.next(false);
        });
      }
    });
    this.modalConfirmReport.eventOnClose.subscribe(event => {
      if (event) {
        let request;
        this.requestInProgress.next(true);

        if (this.view === 'establishment') {
          request = this.checkpointsService.deleteCustomCheckpoint(
            this.activatedRoute.snapshot.params.registry_id,
            this.activatedRoute.snapshot.params.establishment_id,
            event
          );
        } else {
          request = this.checkpointsService.deleteCheckpoint(
            this.activatedRoute.snapshot.params.registry_id,
            this.activatedRoute.snapshot.params.establishment_id,
            event
          );
        }

        request.subscribe(response => {
          if (response['success']) {
            this.getCheckpoints();
            CustomNotifierService.getSuccessDelete(this.notifier);
          } else if (response['errors']) {
            CustomNotifierService.getErrors(response['errors'], this.notifier);
          }
          this.requestInProgress.next(false);
        });
      }
    });
  }

  ngOnDestroy() {
    this.changeEnterprise.unsubscribe();

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  searchData() {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.page = 1;
      this.refreshData();
    }, 500);
  }

  filterData(withReport: string) {
    this.refreshData(this.sort, this.order, this.page, withReport);
  }

  refreshData(sort = this.sort, order = this.order, page = this.page, withReport = '') {
    this.sort = sort;
    this.order = order;
    this.page = page;
    this.withReport = withReport;
    this.getCheckpoints();
  }

  getRegistry() {
    this.requestInProgress.next(true);
    this.registriesService.getRegistry(this.activatedRoute.snapshot.params.registry_id).subscribe(response => {
      if (response['success']) {
        this.registry = response['registry'];
        this.createBreadcrumbs();
      } else if (response['errors']) {
        this.errors = response['errors'];
      }
      this.requestInProgress.next(false);
    });
  }

  getEstablishment() {
    this.requestInProgress.next(true);
    this.establishmentsService.getMyEstablishment(this.activatedRoute.snapshot.params.establishment_id).subscribe(response => {
      if (response['success']) {
        this.establishment = response['establishment'];
        this.createBreadcrumbs();
      } else if (response['errors']) {
        this.errors = response['errors'];
      }
      this.requestInProgress.next(false);
    });
  }

  getInstallation() {
    this.requestInProgress.next(true);
    let request;
    if (this.activatedRoute.snapshot.params.establishment_id) {
      request = this.installationsService.getInstallationCustom(this.activatedRoute.snapshot.params.installation_id);
    } else {
      request = this.installationsService.getInstallation(this.activatedRoute.snapshot.params.installation_id);
    }
    request.subscribe(response => {
      if (response['success']) {
        this.installation = response['installation'];
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrors(this.errors, this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  getCheckpoints() {
    this.requestInProgress.next(true);
    let request;

    if (this.activatedRoute.snapshot.params.establishment_id) {
      const establishment_id = this.activatedRoute.snapshot.params.establishment_id;
      const installation_id = this.activatedRoute.snapshot.params.installation_id;
      const registry_id = this.activatedRoute.snapshot.params.registry_id;
      if (this.show_inactive) {
        request = this.checkpointsService.getAllCheckpointsByMyInstallationEstablishmentAndRegistry(
          this.search, this.sort, this.order, this.limit, this.page, establishment_id, registry_id, installation_id, this.withReport);
      } else {
        request = this.checkpointsService.getCheckpointsByMyInstallationEstablishmentAndRegistry(
          this.search, this.sort, this.order, this.limit, this.page, establishment_id, registry_id, installation_id, this.withReport);
      }
    } else if (this.activatedRoute.snapshot.params.installation_id) {
      const installation_id = this.activatedRoute.snapshot.params.installation_id;
      request = this.checkpointsService.getCheckpointsByInstallation(
        this.search, this.sort, this.order, this.limit, this.page, installation_id);
    } else if (this.activatedRoute.snapshot.params.registry_id) {
      const registry_id = this.activatedRoute.snapshot.params.registry_id;
      request = this.checkpointsService.getCheckpointsByRegistry(this.search, this.sort, this.order, this.limit, this.page, registry_id);
    }
    request.subscribe(response => {
      if (response['success']) {
        this.canUpdate = response['canUpdate'];
        this.canCreate = response['canCreate'];
        this.canDelete = response['canDelete'];
        this.canLog = response['canLog'];

        this.checkpoints = response['checkpoints'];

        this.count = response['count'];
        this.pages = response['pages'];
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  getFrequenciesText(param: Frequency[]) {
    let result = '';
    if (param.length) {
      param.forEach(frequency => {
        if (frequency.establishment_type_id) {
          result += this.getTypeText(frequency.establishment_type_id) + ' - ';
        }
        result += this.getFrequencyText(frequency.frequency, frequency.frequency_duration) + '\n';
      });
      return result;
    }
    return 'Pas de fréquence';
  }

  showHiddenCheckpoints() {
    this.show_inactive = !this.show_inactive;
    localStorage.setItem('show_inactive_installations', JSON.stringify(this.show_inactive));
    this.getCheckpoints();
  }

  duplicateCheckpoint(checkpoint_id) {
    this.requestInProgress.next(true);
    this.checkpointsService.duplicateCheckpoint(checkpoint_id).subscribe(response => {
      if (response['success']) {
        this.router.navigate([this.currentRoute + '/' + response['checkpoint']['id']]).then(() => {});
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  getTypeText(type_id) {
    type_id = parseInt(type_id, 10);
    const found = this.establishment_types.find(elem => {
      return elem.id === type_id;
    });
    if (found) {
      return found.name;
    }
    return '';
  }
  getFrequencyText(frequency, number) {
    if (frequencies[frequency] && frequencies[frequency][number]) {
      return frequencies[frequency][number];
    } else {
      if (frequency === 'year') {
        if (number > 1) {
          return number + ' ans';
        } else {
          return number + ' an';
        }
      } else if (frequency === 'month') {
        return number + ' mois';
      } else if (frequency === 'week') {
        if (number > 1) {
          return number + ' semaines';
        } else {
          return number + ' semaine';
        }
      } else if (frequency === 'day') {
        if (number > 1) {
          return number + ' jours';
        } else {
          return number + ' jour';
        }
      }
    }
  }
  getFrequencyRequired(required_frequencies) {
    let type = 'Recommandé';
    required_frequencies.forEach(frequency => {
      if (frequency.frequency_type === 'required_by_law') {
        type = 'Imposé';
      }
    });

    if (required_frequencies.length) {
      return type;
    } else {
      return '';
    }
  }

  filter(property) {
    if (this.sort === property) {
      if (this.order === 'asc') {
        this.order = 'desc';
      } else {
        this.order = 'asc';
      }
    } else {
      this.sort = property;
      this.order = 'asc';
    }

    this.refreshData();
  }

  dispatchPagination(data) {
    if (data.action === 'total_elements') {
      this.refreshPage(data.value);
    } else if (data.action === 'change_page') {
      this.changePage(data.value);
    }
  }

  refreshPage(limit) {
    this.limit = limit;
    this.page = 1;
    this.refreshData();
  }

  changePage(page) {
    if (page >= 1 && page <= this.pages) {
      this.page = page;
      this.refreshData();
    }
  }

  bindModalConfirmData(modal) {
    this.modalConfirmData = modal;
  }
  bindModalConfirmReport(modal) {
    this.modalConfirmReport = modal;
  }

  createBreadcrumbs() {
    this.previousPageName = [];
    this.previousPageRoute = [];
    this.pageName = 'Points de contrôle';

    this.currentRoute = '/' + this.activatedRoute.snapshot.url.join('/');

    if (this.activatedRoute.snapshot.params.establishment_id) {
      this.previousPageName.push('Tableau de bord');
      this.previousPageRoute.push('/');

      this.previousPageName.push(this.registry ? this.registry.name : 'Tableau de bord registre');
      this.previousPageRoute.push('/dashboard-registry/' + this.activatedRoute.snapshot.params.registry_id);

      this.previousPageName.push(this.establishment ? this.establishment.name : 'Tableau de bord établissement');
      this.previousPageRoute.push('/dashboard-registry/' + this.activatedRoute.snapshot.params.registry_id +
        '/establishment/' + this.activatedRoute.snapshot.params.establishment_id);

      this.previousPageName.push('Installations');
      this.previousPageRoute.push('/dashboard-registry/' + this.activatedRoute.snapshot.params.registry_id +
        '/establishment/' + this.activatedRoute.snapshot.params.establishment_id + '/installations/');
    } else if (this.activatedRoute.snapshot.params.installation_id) {
      this.view = 'installation';

      this.previousPageName.push('Tableau de bord');
      this.previousPageRoute.push('/administration');

      this.previousPageName.push('Installations');
      this.previousPageRoute.push('/installations');
    } else if (this.activatedRoute.snapshot.params.registry_id) {
      this.view = 'registry';

      this.previousPageName.push('Tableau de bord');
      this.previousPageRoute.push('/administration');

      this.previousPageName.push('Registres');
      this.previousPageRoute.push('/registries');
    }
  }
}
