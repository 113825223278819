import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Subject } from 'rxjs';
import { NgbTimeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { NgbTimeStringAdapter } from '../../../core/utils/ngb-time-string-adapter';

import { CustomNotifierService } from '../../../services/custom.notifier.service';

import { AllFirePermitActionsComponent } from '../all-fire-permit-actions/all-fire-permit-actions.component';
import { FirePermitsRequestService } from '../../../services/fire.permits.request.service';

@Component({
  selector: 'app-update-fire-permit-action',
  templateUrl: './update-fire-permit-action.component.html',
  styleUrls: ['./update-fire-permit-action.component.scss'],
  providers: [{ provide: NgbTimeAdapter, useClass: NgbTimeStringAdapter }]
})
export class UpdateFirePermitActionComponent implements OnInit, AfterViewInit, OnDestroy {
  canCreate = false;
  canUpdate = false;

  requestInProgress = new Subject();
  requestCount = 0;

  actionForm: FormGroup;
  name = new FormControl('');

  errors = {};

  isNew = true;
  loader = false;
  loaderContent = false;

  modalFirePermit = null;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private allFirePermits: AllFirePermitActionsComponent,
    private firePermitsService: FirePermitsRequestService,
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loaderContent = this.requestCount > 0;
    });

    this.actionForm = this.formBuilder.group({
      name: this.name,
    });

    if (this.activatedRoute.snapshot.params.action_id) {
      this.isNew = false;
      this.getAction();
    }
  }

  ngAfterViewInit() {
    this.modalFirePermit.open();
  }

  ngOnDestroy() {
    this.modalFirePermit.close();
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  getAction() {
    this.requestInProgress.next(true);
    this.firePermitsService.getAction(this.activatedRoute.snapshot.params.action_id).subscribe(response => {
      if (response['success']) {
        this.canUpdate = response['canUpdate'];
        this.actionForm.patchValue(response['action']);
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  createAction() {
    let request;
    this.errors = {};
    this.loader = true;

    request = this.firePermitsService.createAction(
      this.actionForm.value
    );

    request.subscribe(response => {
      if (response['success']) {
        this.modalFirePermit.close();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(response['errors'], this.actionForm, this.notifier);
      }
      this.loader = false;
    });
  }

  updateAction() {
    let request;

    this.errors = {};
    this.loader = true;

    request = this.firePermitsService.updateAction(
      this.activatedRoute.snapshot.params.action_id,
      this.actionForm.value
    );

    request.subscribe(response => {
      if (response['success']) {
        this.modalFirePermit.close();
        CustomNotifierService.getSuccessUpdate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loader = false;
    });
  }

  bindModal(modal) {
    this.modalFirePermit = modal;
  }

  closeModal() {
    this.router.navigate([this.activatedRoute.snapshot.parent.url.join('/')]).then(() => {
      this.allFirePermits.refreshData();
    });
  }

}
