import { Routes } from '@angular/router';

import { AllAsbestosDetectionComponent } from './all-asbestos-detection/all-asbestos-detection.component';
import { UpdateAsbestosDetectionComponent } from './update-asbestos-detection/update-asbestos-detection.component';

import { AllAsbestosWorkComponent } from './all-asbestos-work/all-asbestos-work.component';
import { UpdateAsbestosWorkComponent } from './update-asbestos-work/update-asbestos-work.component';

import { UploadAsbestosFileComponent } from './upload-asbestos-file/upload-asbestos-file.component';

import { AsbestosTechnicalFileComponent } from './asbestos-technical-file/asbestos-technical-file.component';

import { ModalLogsComponent } from '../logs/modal-logs/modal-logs.component';

export const asbestosRoutes: Routes = [
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/asbestos-detection',
    component: AllAsbestosDetectionComponent,
    children: [
      {
        path: 'create',
        component: UpdateAsbestosDetectionComponent
      },
      {
        path: ':asbestos_detection_id',
        component: UpdateAsbestosDetectionComponent
      },
      {
        path: ':asbestos_detection_id/logs',
        component: ModalLogsComponent
      }
    ]
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/asbestos-detection/:asbestos_detection_id/files',
    component: AllAsbestosDetectionComponent,
    children: [
      {
        path: 'create',
        component: UploadAsbestosFileComponent
      },
      {
        path: ':file_id',
        component: UploadAsbestosFileComponent
      }
    ]
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/asbestos-detection/:asbestos_detection_id/localizations',
    component: AllAsbestosDetectionComponent
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/asbestos-detection/:asbestos_detection_id/localizations/' +
      ':localization_id/files',
    component: AllAsbestosDetectionComponent,
    children: [
      {
        path: 'create',
        component: UploadAsbestosFileComponent
      },
      {
        path: ':file_id',
        component: UploadAsbestosFileComponent
      }
    ]
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/asbestos-works',
    component: AllAsbestosWorkComponent,
    children: [
      {
        path: 'create',
        component: UpdateAsbestosWorkComponent
      },
      {
        path: ':asbestos_work_id',
        component: UpdateAsbestosWorkComponent
      },
      {
        path: ':asbestos_work_id/logs',
        component: ModalLogsComponent
      }
    ]
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/asbestos-work/:asbestos_work_id',
    component: AllAsbestosWorkComponent,
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/asbestos-work/:asbestos_work_id/file-type/:file_type_id/files',
    component: AllAsbestosWorkComponent,
    children: [
      {
        path: 'create',
        component: UploadAsbestosFileComponent
      },
      {
        path: ':file_id',
        component: UploadAsbestosFileComponent
      }
    ]
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/asbestos-works/:asbestos_work_id/localizations',
    component: AllAsbestosWorkComponent
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/asbestos-works/:asbestos_work_id/localizations/' +
      ':localization_id/files',
    component: AllAsbestosWorkComponent,
    children: [
      {
        path: 'create',
        component: UploadAsbestosFileComponent
      },
      {
        path: ':file_id',
        component: UploadAsbestosFileComponent
      }
    ]
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/asbestos-technical-file',
    component: AsbestosTechnicalFileComponent
  },
];
