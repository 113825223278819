import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs';

import { AuthService } from '../../../core/auth/auth.service';
import { CustomNotifierService } from '../../../services/custom.notifier.service';

import { CotationsRequestService } from '../../../services/cotations.request.service';
import Cotation from '../../../../shared/interfaces/cotation';
import { ConfirmModalComponent } from '../../../core/confirm-modal/confirm-modal.component';
import Registry from '../../../../shared/interfaces/registry';
import Establishment from '../../../../shared/interfaces/establishment';
import { RegistriesRequestService } from '../../../services/registries.request.service';
import { EstablishmentsRequestService } from '../../../services/establishments.request.service';

declare function Flatpickr(): any;

@Component({
  selector: 'app-all-cotations',
  templateUrl: './all-cotations.component.html',
  styleUrls: ['./all-cotations.component.scss']
})
export class AllCotationsComponent implements OnInit, AfterViewInit, OnDestroy {
  changeEnterprise: Subscription;

  canCreate = false;
  canUpdate = false;

  requestInProgress = new Subject();
  requestCount = 0;

  pageName: string;
  previousPageName: string[] = [];
  previousPageRoute: string[] = [];

  currentRoute = '';
  cotationRoute = '';
  view = '';

  frequencies: Cotation[];
  gravities: Cotation[];
  masters: Cotation[];
  limits: {
    limit_brut_low: number;
    limit_brut_high: number;
    limit_matrice_low: number;
    limit_matrice_high: number;
  };
  registry: Registry;
  establishment: Establishment;

  errors = {};
  timeout: any;
  loader = false;

  matrice: any[] = [];

  modalConfirmData: ConfirmModalComponent;

  constructor(
    private authService: AuthService,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private cotationsService: CotationsRequestService,
    private registriesService: RegistriesRequestService,
    private establishmentsService: EstablishmentsRequestService,
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loader = this.requestCount > 0;
    });

    this.changeEnterprise = this.authService.getEnterprise().subscribe(() => {
      this.getCotations();
    });

    this.currentRoute = '/' + this.activatedRoute.snapshot.url.join('/') + '/';
    this.cotationRoute = '/dashboard-registry/' + this.activatedRoute.snapshot.params.registry_id +
      '/establishment/' + this.activatedRoute.snapshot.params.establishment_id + '/cotation/';

    if (this.activatedRoute.snapshot.url.length === 1) {
      this.view = 'admin';
    } else {
      this.view = 'user';
    }
    this.pageName = 'Echelles de cotation';

    if (this.activatedRoute.snapshot.params.registry_id) {
      this.getRegistry();
    }
    if (this.activatedRoute.snapshot.params.establishment_id) {
      this.getEstablishment();
    }
    if (this.authService.loaded) {
      this.getCotations();
    }
    this.createBreadcrumbs();
  }

  ngAfterViewInit() {
    this.modalConfirmData.eventOnClose.subscribe(event => {
      if (event) {
        this.requestInProgress.next(true);
        let request;

        if ((this.view === 'admin')) {
          request = this.cotationsService.deleteAdminCotation(
            event
          );
        } else {
          request = this.cotationsService.deleteEnterpriseCotation(
            event
          );
        }

        request.subscribe(response => {
          if (response['success']) {
            this.getCotations();
            CustomNotifierService.getSuccessDelete(this.notifier);
          } else if (response['errors']) {
            CustomNotifierService.getErrors(response['errors'], this.notifier);
          }
          this.requestInProgress.next(false);
        });
      }
    });
  }

  ngOnDestroy() {
    const flatPicker = document.getElementsByClassName('flatpickr-calendar') as HTMLCollection;
    for (let i = flatPicker.length; i >= 0; i--) {
      if (flatPicker[i]) {
        flatPicker[i].remove();
      }
    }

    this.changeEnterprise.unsubscribe();
  }

  getRegistry() {
    this.requestInProgress.next(true);
    this.registriesService.getRegistry(this.activatedRoute.snapshot.params.registry_id).subscribe(response => {
      if (response['success']) {
        this.registry = response['registry'];
        this.createBreadcrumbs();
      } else if (response['errors']) {
        this.errors = response['errors'];
      }
      this.requestInProgress.next(false);
    });
  }

  getEstablishment() {
    this.requestInProgress.next(true);
    this.establishmentsService.getMyEstablishment(this.activatedRoute.snapshot.params.establishment_id).subscribe(response => {
      if (response['success']) {
        this.establishment = response['establishment'];
        this.createBreadcrumbs();
      } else if (response['errors']) {
        this.errors = response['errors'];
      }
      this.requestInProgress.next(false);
    });
  }

  getCotations() {
    this.requestInProgress.next(true);
    let request;
    if (this.view === 'admin') {
      request = this.cotationsService.getAdminCotations();
    } else {
      request = this.cotationsService.getCotations();
    }

    request.subscribe(response => {
      if (response['success']) {
        this.canUpdate = response['canUpdate'];
        this.frequencies = response['frequencies'];
        this.gravities = response['gravities'];
        this.gravities.sort(this.asort);
        this.masters = response['masters'];
        this.masters.sort(this.asort);
        this.limits = response['limits'];

        this.gravities.forEach(gravity => {
          this.frequencies.forEach(frequency => {
            const multiplication = gravity.coefficient * frequency.coefficient;
            if (!this.matrice.includes(multiplication)) {
              this.matrice.push(multiplication);
            }
          });
        });
        this.matrice.sort(this.sort);
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);

      if (!this.activatedRoute.children.length) {
        setTimeout(() => {
          Flatpickr();
        }, 1);
      }
    });
  }

  getBrutColor(multiplication) {
    if (multiplication > this.limits.limit_brut_high) {
      return '#FF0000';
    } else if (multiplication <= this.limits.limit_brut_low) {
      return '#63A538';
    } else {
      return '#FFFF00';
    }
  }

  getNetColor(multiplication) {
    if (multiplication > this.limits.limit_matrice_high) {
      return '#FF0000';
    } else if (multiplication <= this.limits.limit_matrice_low) {
      return '#63A538';
    } else {
      return '#FFFF00';
    }
  }

  createBreadcrumbs() {
    this.previousPageName = [];
    this.previousPageRoute = [];

    if (this.activatedRoute.snapshot.url.length === 1) {
      this.previousPageName.push('Tableau de bord');
      this.previousPageRoute.push('/administration');
    } else {
      this.previousPageName.push('Tableau de bord');
      this.previousPageRoute.push('/');

      this.previousPageName.push(this.registry ? this.registry.name : 'Tableau de bord registre');
      this.previousPageRoute.push('/dashboard-registry/' + this.activatedRoute.snapshot.params.registry_id);

      if (this.activatedRoute.snapshot.url.length === 5) {
        this.previousPageName.push(this.establishment ? this.establishment.name : 'Tableau de bord établissement');
        this.previousPageRoute.push('/dashboard-registry/' + this.activatedRoute.snapshot.params.registry_id +
          '/establishment/' + this.activatedRoute.snapshot.params.establishment_id);
      }
    }
  }

  bindModalConfirmData(modal: ConfirmModalComponent) {
    this.modalConfirmData = modal;
  }

  sort = (a, b) => {
    if (a > b) {
      return -1;
    } else if (a < b) {
      return 1;
    }
    return 0;
  }

  asort = (a, b) => {
    if (a.coefficient > b.coefficient) {
      return 1;
    } else if (a.coefficient < b.coefficient) {
      return -1;
    }
    return 0;
  }
}
