import { Component, OnDestroy, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs';

import { AuthService } from '../../../core/auth/auth.service';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { IssuesRequestService } from '../../../services/issues.request.service';

import BatiFile from '../../../../shared/interfaces/bati_file';
import Issue from '../../../../shared/interfaces/issue';

@Component({
  selector: 'app-all-issues',
  templateUrl: './all-issues.component.html',
  styleUrls: ['./all-issues.component.scss']
})
export class AllIssuesComponent implements OnInit, OnDestroy {
  changeEnterprise: Subscription;

  canCreate = false;
  canUpdate = false;
  canDelete = false;
  canLog = false;

  requestInProgress = new Subject();
  requestCount = 0;

  currentRoute = '';

  issues: Issue[] = [];
  files: BatiFile[] = [];

  pageName: string;
  previousPageName: string[] = [];
  previousPageRoute: string[] = [];

  count = 0;
  pages = 1;

  view = '';

  search = '';
  sort = 'Issues.created';
  order = 'desc';
  limit = 12;
  page = 1;

  timeout: any;
  loader = false;

  modalConfirmData = null;
  modalConfirmFile = null;

  constructor(
    private authService: AuthService,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private issuesService: IssuesRequestService
  ) {}

  ngOnInit() {
    this.currentRoute = '/' + this.activatedRoute.snapshot.url.join('/') + '/';

    if (this.activatedRoute.snapshot.url.length > 1) {
      this.view = 'client';
    } else {
      this.view = 'admin';
    }

    this.createBreadcrumbs();

    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loader = this.requestCount > 0;
    });

    this.changeEnterprise = this.authService.getEnterprise().subscribe(() => {
      this.getIssues();
    });

    if (this.authService.loaded) {
      this.getIssues();
    }
  }

  ngOnDestroy() {
    this.changeEnterprise.unsubscribe();
  }

  getRef = (param: number) => {
    const size = 10 - param.toString(10).length;
    let result = '';
    for (let i = 0; i < size; i++) {
      result = result + '0';
    }
    result += param.toString(10);
    return result;
  }

  searchData() {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.page = 1;
      this.refreshData();
    }, 500);
  }

  refreshData(sort = this.sort, order = this.order, page = this.page) {
    this.sort = sort;
    this.order = order;
    this.page = page;
    this.getIssues();
  }

  getIssues() {
    let request;
    this.issues = [];
    this.requestInProgress.next(true);

    if (this.view === 'admin') {
      request = this.issuesService.getIssues(this.search, this.sort, this.order, this.limit, this.page);
    } else {
      request = this.issuesService.getMyIssues(this.search, this.sort, this.order, this.limit, this.page);
    }

    request.subscribe(response => {
      if (response['success']) {
        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];

        this.issues = response['issues'];
        this.count = response['count'];
        this.pages = response['pages'];
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  filter(property) {
    if (this.sort === property) {
      if (this.order === 'asc') {
        this.order = 'desc';
      } else {
        this.order = 'asc';
      }
    } else {
      this.sort = property;
      this.order = 'asc';
    }

    this.getIssues();
  }

  dispatchPagination(data) {
    if (data.action === 'total_elements') {
      this.refreshPage(data.value);
    } else if (data.action === 'change_page') {
      this.changePage(data.value);
    }
  }

  refreshPage(limit) {
    this.limit = limit;
    this.page = 1;
    this.refreshData();
  }

  createBreadcrumbs() {
    this.previousPageName = [];
    this.previousPageRoute = [];

    this.previousPageName.push('Tableau de bord');
    this.previousPageRoute.push('/');

    if (this.view === 'admin') {
      this.pageName = 'gestion des tickets';
    }
  }

  changePage(page) {
    if (page >= 1 && page <= this.pages) {
      this.page = page;
      this.getIssues();
    }
  }
}
