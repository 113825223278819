<app-modal-right (eventOnClose)="closeModal()" #commission>{{ bindModal(commission) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">{{ canUpdate ? 'Mise à jour' : 'Consultation' }}</h6>

        <h1 class="header-title">Limites des matrices de cotation</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <form [formGroup]="itemForm" [hidden]="loaderContent">

      <div class="row">
        <div class="col-12">

          <div class="form-group">
            <label for="validationlimit_brut_low">Limite brut basse</label>
            <input type="number" formControlName="limit_brut_low" class="form-control" id="validationlimit_brut_low" placeholder="Limite brut basse" [ngClass]="{ 'is-invalid': limit_brut_low.invalid && (limit_brut_low.dirty || limit_brut_low.touched) }">
            <div *ngIf="limit_brut_low.invalid && (limit_brut_low.dirty || limit_brut_low.touched)" class="invalid-feedback">
              {{ getErrorMessage("limit_brut_low") }}
            </div>
          </div>

          <div class="form-group">
            <label for="validationlimit_brut_high">Limite brut haute</label>
            <input type="number" formControlName="limit_brut_high" class="form-control" id="validationlimit_brut_high" placeholder="Limite brut haute" [ngClass]="{ 'is-invalid': limit_brut_high.invalid && (limit_brut_high.dirty || limit_brut_high.touched) }">
            <div *ngIf="limit_brut_high.invalid && (limit_brut_high.dirty || limit_brut_high.touched)" class="invalid-feedback">
              {{ getErrorMessage("limit_brut_high") }}
            </div>
          </div>

          <div class="form-group">
            <label for="validationlimit_matrice_low">Limite matrice basse</label>
            <input type="number" formControlName="limit_matrice_low" class="form-control" id="validationlimit_matrice_low" placeholder="Limite matrice basse" [ngClass]="{ 'is-invalid': limit_matrice_low.invalid && (limit_matrice_low.dirty || limit_matrice_low.touched) }">
            <div *ngIf="limit_matrice_low.invalid && (limit_matrice_low.dirty || limit_matrice_low.touched)" class="invalid-feedback">
              {{ getErrorMessage("limit_matrice_low") }}
            </div>
          </div>

          <div class="form-group">
            <label for="validationlimit_matrice_high">Limite matrice haute</label>
            <input type="number" formControlName="limit_matrice_high" class="form-control" id="validationlimit_matrice_high" placeholder="Limite matrice haute" [ngClass]="{ 'is-invalid': limit_matrice_high.invalid && (limit_matrice_high.dirty || limit_matrice_high.touched) }">
            <div *ngIf="limit_matrice_high.invalid && (limit_matrice_high.dirty || limit_matrice_high.touched)" class="invalid-feedback">
              {{ getErrorMessage("limit_matrice_high") }}
            </div>
          </div>

        </div>
      </div>

    </form>
  </div>

  <div modal-footer class="w-100" *ngIf="canUpdate">
    <button class="btn btn-primary w-100" (click)="updateCotation()" [ngClass]="{ 'is-loading is-loading-sm': loader }" [disabled]="loader || itemForm.invalid">
      Mettre à jour
    </button>
  </div>
</app-modal-right>
