import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IssuesRequestService } from '../../services/issues.request.service';
import { AllIssuesModule } from './all-issues/all-issues.module';
import { UpdateIssueModule } from './update-issue/update-issue.module';
import { CreateIssueModule } from './create-issue/create-issue.module';
import { CreateCommentModule } from './create-comment/create-comment.module';
// import { SocketService } from './socket.service';

@NgModule({
  imports: [
    CommonModule,
    AllIssuesModule,
    UpdateIssueModule,
    CreateIssueModule,
    CreateCommentModule
  ],
  providers: [IssuesRequestService]
})
export class IssuesModule {}
