import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { ConfirmModalModule } from "../../../core/confirm-modal/confirm-modal.module";
import { AffectationEmployeesTrainingComponent } from "./affectation-employees-training.component";
import { BreadcrumbsModule } from "src/app/core/breadcrumbs/breadcrumbs.module";
import { CommissionsModule } from "../../commissions/commissions.module";
import { PaginationModule } from "src/app/core/pagination/pagination.module";
import { ModalRightModule } from "src/app/core/modal-right/modal-right.module";
import { NgSelectModule } from "@ng-select/ng-select";
import { SearchbarModule } from "src/app/core/searchbar/searchbar.module";

@NgModule({
  imports: [
    CommonModule,
    CommissionsModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    ConfirmModalModule,
    BreadcrumbsModule,
    SearchbarModule,
    PaginationModule,
    ModalRightModule,
    NgSelectModule,
  ],
  declarations: [AffectationEmployeesTrainingComponent],
})
export class AffectationEmployeesTrainingModule {}
