import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Subject } from 'rxjs';
import { NgbTimeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { NgbTimeStringAdapter } from '../../../core/utils/ngb-time-string-adapter';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { UsersRequestService } from '../../../services/users.request.service';
import { AllSanitaryOperationsComponent } from '../all-sanitary-operations/all-sanitary-operations.component';
import { SanitaryOperationsRequestService } from '../../../services/sanitary-operations.request.service';
import { SanitaryInstallationsRequestService } from '../../../services/sanitary-installations.request.service';
import { DocumentsRequestService } from '../../../services/documents.request.service';
import { SanitaryFramesRequestService } from '../../../services/sanitary-frames.request.service';

import SanitaryFrames from '../../../../shared/interfaces/sanitary_frames';


declare function Dropzones(): any;

@Component({
  selector: 'app-update-sanitary-operation',
  templateUrl: './update-sanitary-operation.component.html',
  styleUrls: ['./update-sanitary-operation.component.scss'],
  providers: [{ provide: NgbTimeAdapter, useClass: NgbTimeStringAdapter }]
})
export class UpdateSanitaryOperationComponent implements OnInit, AfterViewInit, OnDestroy {
  counter = Array;
  canCreate = false;
  canUpdate = false;

  activeTab = 1;

  requestInProgress = new Subject();
  requestCount = 0;

  periods = [
    { value: 'weekly', name: 'Hebdomadaire' },
    { value: 'monthly', name: 'Mensuelle' },
    { value: 'quarterly', name: 'Trimestrielle' },
    { value: 'biannual', name: 'Semestrielle' },
    { value: 'annual', name: 'Annuelle' },
  ];

  weekly: SanitaryFrames[];
  monthly: SanitaryFrames[];
  quarterly: SanitaryFrames[];
  biannual: SanitaryFrames[];
  annual: SanitaryFrames[];

  sanitaryOperationForm: FormGroup;
  operation = new FormControl('', Validators.required);
  actions = new FormControl('', Validators.required);
  periodicity = new FormControl(null, Validators.required);
  sanitary_installation_id = new FormControl(null, Validators.required);
  generic_folder_id = new FormControl(null, Validators.required);
  sanitary_frame_id = new FormControl(null, Validators.required);

  sanitaryInstallations = [];
  sanitaryProtocols = [];
  enterprises: any = [];
  user: string;

  errors = {};

  isNew = true;
  loader = false;
  loaderContent = false;

  modalSanitaryOperation = null;

  constructor(
      private router: Router,
      private formBuilder: FormBuilder,
      private notifier: NotifierService,
      private activatedRoute: ActivatedRoute,
      private usersService: UsersRequestService,
      private documentsService: DocumentsRequestService,
      private sanitaryFramesService: SanitaryFramesRequestService,
      private allSanitaryOperations: AllSanitaryOperationsComponent,
      private sanitaryOperationsServices: SanitaryOperationsRequestService,
      private sanitaryInstallationsService: SanitaryInstallationsRequestService,
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loaderContent = this.requestCount > 0;
    });

    this.sanitaryOperationForm = this.formBuilder.group({
      sanitary_installation_id: this.sanitary_installation_id,
      operation: this.operation,
      actions: this.actions,
      periodicity: this.periodicity,
      generic_folder_id: this.generic_folder_id,
      sanitary_frame_id: this.sanitary_frame_id,
    });

    if (this.activatedRoute.snapshot.params.sanitary_operation_id) {
      this.isNew = false;
      this.getSanitaryOperation();
    }

    this.getSanitaryInstallations();
    this.getSanitaryProtocols();
    this.getSanitaryFrames();
  }

  ngAfterViewInit() {
    Dropzones();
    this.modalSanitaryOperation.open();
  }

  ngOnDestroy() {
    const flatPicker = document.getElementsByClassName('flatpickr-calendar') as HTMLCollection;
    for (let i = flatPicker.length; i >= 0; i--) {
      if (flatPicker[i]) {
        flatPicker[i].remove();
      }
    }

    const dropZones = document.getElementsByClassName('dz-hidden-input') as HTMLCollection;
    for (let i = 0; i < dropZones.length; i++) {
      dropZones[i].remove();
    }

    this.clearTooltip();

    this.modalSanitaryOperation.close();
  }
  clearTooltip() {
    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }
  changeTabs(activeTab) {
    this.activeTab = activeTab;
  }

  getSanitaryFrames() {
    this.requestInProgress.next(true);
    this.sanitaryFramesService.getAllSanitaryFrames('', '', '', '', '',
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id
    ).subscribe(response => {
      if (response['success']) {
        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];

        this.weekly = response['weekly'];
        this.monthly = response['monthly'];
        this.quarterly = response['quarterly'];
        this.biannual = response['biannual'];
        this.annual = response['annual'];

      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  getSanitaryInstallations() {
    let request;
    this.requestInProgress.next(true);
    this.loader = true;

    request = this.sanitaryInstallationsService.getAllSanitaryInstallations(
        '', '', '', 0, 1,
        this.activatedRoute.snapshot.parent.params.registry_id,
        this.activatedRoute.snapshot.parent.params.establishment_id,
    );

    request.subscribe(response => {
      if (response['success']) {
        this.sanitaryInstallations = response['sanitaryInstallations'];
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loader = false;
      this.requestInProgress.next(false);
    });
  }

  getSanitaryProtocols() {
    let request;
    this.requestInProgress.next(true);
    this.loader = true;

    request = this.documentsService.getProtocolFolders(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id
    );

    request.subscribe(response => {
      if (response['success']) {
        this.sanitaryProtocols = response['children'];
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loader = false;
      this.requestInProgress.next(false);
    });
  }

  getSanitaryOperation() {
    this.requestInProgress.next(true);
    this.sanitaryOperationsServices.getSanitaryOperation(
        this.activatedRoute.snapshot.parent.params.registry_id,
        this.activatedRoute.snapshot.parent.params.establishment_id,
        this.activatedRoute.snapshot.params.sanitary_operation_id
    ).subscribe(response => {
      if (response['success']) {
        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];

        this.sanitaryOperationForm.patchValue(response['sanitaryOperation']);
        this.sanitaryOperationForm[`controls`].periodicity.disable();
        this.sanitaryOperationForm[`controls`].sanitary_frame_id.disable();

        if (!this.canUpdate) {
          this.sanitaryOperationForm.disable();
        }
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  createSanitaryOperation() {
    let request;
    this.errors = {};
    this.loader = true;

    const data = {
      ...this.sanitaryOperationForm.value,
    };

    request = this.sanitaryOperationsServices.createSanitaryOperation(
        this.activatedRoute.snapshot.parent.params.registry_id,
        this.activatedRoute.snapshot.parent.params.establishment_id,
        data
    );

    request.subscribe(response => {
      if (response['success']) {
        this.loader = false;
        this.modalSanitaryOperation.close();
        CustomNotifierService.getSuccessCreate(this.notifier);
        this.router.navigate([
          'dashboard-registry/' + this.activatedRoute.snapshot.parent.params.registry_id +
          '/establishment/' + this.activatedRoute.snapshot.parent.params.establishment_id +
          '/sanitary-operations/' + response['sanitaryOperation']['id'] + '/operation-frames/create']).then();
      } else if (response['errors']) {
        this.loader = false;
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(response['errors'], this.sanitaryOperationForm, this.notifier);
      } else {
        this.loader = false;
      }
    });
  }

  updateSanitaryOperation() {
    let request;

    this.errors = {};
    this.loader = true;

    const data = {
      ...this.sanitaryOperationForm.value,
    };

    request = this.sanitaryOperationsServices.updateSanitaryOperation(
        this.activatedRoute.snapshot.parent.params.registry_id,
        this.activatedRoute.snapshot.parent.params.establishment_id,
        this.activatedRoute.snapshot.params.sanitary_operation_id,
        data
    );

    request.subscribe(response => {
      if (response['success']) {
        this.loader = false;
        this.modalSanitaryOperation.close();
        CustomNotifierService.getSuccessUpdate(this.notifier);
      } else if (response['errors']) {
        this.loader = false;
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      } else {
        this.loader = false;
      }
    });
  }

  bindModal(modal) {
    this.modalSanitaryOperation = modal;
  }
  closeModal() {
    this.router.navigate([this.activatedRoute.snapshot.parent.url.join('/')]).then(() => {
      this.allSanitaryOperations.refreshData();
    });
  }

  changeFrequency() {
    this.sanitary_frame_id.setValue(null);
  }
}
