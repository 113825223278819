import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';

import { AllOffersComponent } from '../all-offers/all-offers.component';
import { CustomNotifierService } from '../../../services/custom.notifier.service';

import { SubscriptionsRequestService } from '../../../services/subscriptions.request.service';
import { RegistriesRequestService } from '../../../services/registries.request.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-update-offer',
  templateUrl: './update-offer.component.html',
  styleUrls: ['./update-offer.component.scss']
})
export class UpdateOfferComponent implements OnInit, AfterViewInit, OnDestroy {
  canUpdate = false;

  requestInProgress = new Subject();
  requestCount = 0;

  offerForm: FormGroup;
  id = new FormControl('');
  name = new FormControl('', Validators.required);
  registry_limit = new FormControl('', Validators.required);
  registry_offers = new FormControl([]);
  type = new FormControl('', Validators.required);

  registries = [];
  selectedRegistry = [];

  errors = {};

  isNew = true;
  loader = false;
  loaderContent = false;

  modalOffer = null;

  activeTab = 'firstPart';

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private allOffers: AllOffersComponent,
    private subscriptionsService: SubscriptionsRequestService,
    private registriesService: RegistriesRequestService
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loaderContent = this.requestCount > 0;
    });

    this.offerForm = this.formBuilder.group({
      id: this.id,
      name: this.name,
      registry_limit: this.registry_limit,
      registry_offers: this.registry_offers,
      prices: this.formBuilder.array([]) as FormArray,
      type: this.type
    });

    if (this.activatedRoute.snapshot.params.offer_id) {
      this.isNew = false;
      this.getOffer();
    }

    this.getRegistries();
  }

  ngAfterViewInit() {
    this.modalOffer.open();
  }

  ngOnDestroy() {
    this.modalOffer.close();
  }

  initPrices() {
    return this.formBuilder.group({
      id: [''],
      nb_establishment: ['', Validators.required],
      price: ['', Validators.required]
    });
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  getOffer() {
    this.errors = {};
    this.requestInProgress.next(true);

    this.subscriptionsService.getOffer(this.activatedRoute.snapshot.params.offer_id).subscribe(response => {
      if (response['success']) {
        this.canUpdate = response['canUpdate'];

        for (const key in response['offer']['registry_offers']) {
          if (response['offer']['registry_offers'].hasOwnProperty(key)) {
            this.selectedRegistry.push(
              response['offer'].registry_offers[key].registry.id
            );
          }
        }

        for (const key in response['offer']['prices']) {
          if (response['offer']['prices'].hasOwnProperty(key)) {
            this.addOfferPrice();
          }
        }

        this.offerForm.patchValue(response['offer']);

        if (!this.canUpdate) {
          this.offerForm.disable();
        }
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.offerForm, this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  getRegistries() {
    this.requestInProgress.next(true);

    this.registriesService.getRegistries('', '', '', '', '').subscribe(response => {
      if (response['success']) {
        response['registries'].forEach(registries => {
          registries.group = true;
          this.registries.push(registries);
        });
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  createOffer() {
    this.errors = {};
    this.loader = true;

    for (const key in this.selectedRegistry) {
      if (this.selectedRegistry.hasOwnProperty(key)) {
        this.registry_offers.value.push({ registry_id: this.selectedRegistry[key] });
      }
    }

    this.subscriptionsService.createOffer(this.offerForm.value).subscribe(response => {
      if (response['success']) {
        this.modalOffer.close();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.offerForm, this.notifier);
      }
      this.loader = false;
    });
  }

  updateOffer() {
    this.errors = {};
    this.loader = true;

    const subtypes = [];

    for (const key in this.selectedRegistry) {
      if (this.selectedRegistry.hasOwnProperty(key)) {
        const find = this.registry_offers.value.find(element => {
          return element.registry_id === this.selectedRegistry[key];
        });
        if (find) {
          subtypes.push(find);
        } else {
          subtypes.push({ registry_id: this.selectedRegistry[key]});
        }
      }
    }

    this.registry_offers.setValue(subtypes);

    this.subscriptionsService.updateOffer(this.id.value, this.offerForm.value).subscribe(response => {
      if (response['success']) {
        this.modalOffer.close();
        CustomNotifierService.getSuccessUpdate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.offerForm, this.notifier);
      }
      this.loader = false;
    });
  }

  addOfferPrice() {
    const control = <FormArray>this.offerForm.controls['prices'];
    control.push(this.initPrices());
  }

  deleteOfferPrice(index) {
    const control = <FormArray>this.offerForm.controls['prices'];
    control.removeAt(index);
  }

  changeTabs(activeTab) {
    this.activeTab = activeTab;
  }

  prevTabs() {
    this.activeTab = 'firstPart';
  }

  nextTabs() {
    this.activeTab = 'secondPart';
  }

  bindModal(modal) {
    this.modalOffer = modal;
  }

  closeModal() {
    this.allOffers.getOffers();
    this.router.navigate(['offers']).then(() => {});
  }
}
