import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CustomNotifierService } from '../../services/custom.notifier.service';
import { HttpClient } from '@angular/common/http';
import { NotifierService } from 'angular-notifier';
import { UsersRequestService } from '../../services/users.request.service';
import { mergeMap } from 'rxjs/operators';
import { AngularFireMessaging } from '@angular/fire/messaging';

@Injectable()
export class AuthService {
  enterprises: Subject<any> = new Subject();
  enterprise: Subject<any> = new Subject();
  provider: Subject<any> = new Subject();
  onlyProvider: Subject<any> = new Subject();
  admin: Subject<any> = new Subject();
  user: Subject<any> = new Subject();
  registries: Subject<any> = new Subject();
  monoOffer: Subject<any> = new Subject();
  discoverOffer: Subject<any> = new Subject();
  discoverDate: Subject<any> = new Subject();

  isToolBarNotification = false;
  isSideNavNotification = false;

  loaded = false;

  loader = false;
  loaderChange = new Subject<boolean>();
  isDashboardRedirect = false;

  statsMenu = [{
    url: '/dashboard-observations',
    registry_module: 'checkpoints_module',
  }, {
    url: '/dashboard-commissions',
    registry_module: 'commissions_module',
  }, {
    url: '/dashboard-providers',
  }, {
    url: '/dashboard-asbestos',
    registry_module: 'asbestos_module',
  }, {
    url: '/dashboard-unique-document',
    registry_module: 'cotations_module',
  }];

  statsRegistries = [];

  constructor(
    private router: Router,
    private http: HttpClient,
    private notifier: NotifierService,
    private usersService: UsersRequestService,
    private angularFireMessaging: AngularFireMessaging,
  ) {
    this.loaderChange.subscribe((value) => {
      this.loader = value;
    });
    this.enterprise.subscribe(() => {
      this.loaded = true;
    });
  }

  login() {
    this.statsRegistries = JSON.parse(localStorage.getItem('registries'));
    this.router.navigate([this.checkUrlStats()]).then();
  }

  logout() {
    const notificationToken = localStorage.getItem('notificationToken');
    const sso_logout = localStorage.getItem('sso_logout');
    if (notificationToken) {
      this.http.delete(environment.API_PATH + 'notification/delete-token/' + notificationToken)
        .subscribe(response => {
          if (response['success']) {
            this.angularFireMessaging.getToken.pipe(mergeMap(token => this.angularFireMessaging.deleteToken(token)))
              .subscribe((token) => {});
            if (sso_logout) {
              localStorage.clear();
              window.location.href = sso_logout;
            } else {
              localStorage.clear();
              this.router.navigate(['/auth/login']).then(() => {
              });
            }
          }
        });
    } else if (sso_logout) {
      localStorage.clear();
      window.location.href = sso_logout;
    } else {
      localStorage.clear();
      this.router.navigate(['/auth/login']).then(() => {
      });
    }
  }

  disconnectAs() {
    const user_id = JSON.parse(localStorage.getItem('user')).id;
    const enterprise_id = JSON.parse(localStorage.getItem('enterprise')).id;
    this.usersService.disconnectedAs(user_id, enterprise_id).subscribe(response => {
      if (response['success']) {
        localStorage.removeItem('connectedAs');
        localStorage.removeItem('connectedAsRefreshToken');
        localStorage.removeItem('connectedAsAccessToken');

        localStorage.removeItem('connectedAsEnterprises');
        localStorage.removeItem('connectedAsEnterprise');
        localStorage.removeItem('connectedAsIsProvider');
        localStorage.removeItem('connectedAsIsOnlyProvider');
        localStorage.removeItem('connectedAsIsAdmin');
        localStorage.removeItem('connectedAsUser');
        localStorage.removeItem('connectedAsRegistries');
        localStorage.removeItem('connectedAsIsMonoOffer');
        localStorage.removeItem('connectedAsIsDiscoverOffer');
        localStorage.removeItem('connectedAsDiscoverDate');

        const enterprises = JSON.parse(localStorage.getItem('enterprises'));
        const enterprise = JSON.parse(localStorage.getItem('enterprise'));
        const isProvider = JSON.parse(localStorage.getItem('isProvider'));
        const isOnlyProvider = JSON.parse(localStorage.getItem('isOnlyProvider'));
        const isAdmin = JSON.parse(localStorage.getItem('isAdmin'));
        const user = JSON.parse(localStorage.getItem('user'));
        const registries = JSON.parse(localStorage.getItem('registries'));
        const monoOffer = JSON.parse(localStorage.getItem('isMonoOffer'));
        const discoverOffer = JSON.parse(localStorage.getItem('isDiscoverOffer'));
        if (discoverOffer) {
          const discoverDate = JSON.parse(localStorage.getItem('discoverDate'));
          this.discoverDate.next(discoverDate);
        }

        this.enterprises.next(enterprises);
        this.enterprise.next(enterprise);
        this.provider.next(isProvider);
        this.onlyProvider.next(isOnlyProvider);
        this.admin.next(isAdmin);
        this.user.next(user);
        this.registries.next(registries);
        this.monoOffer.next(monoOffer);
        this.discoverOffer.next(discoverOffer);

        this.statsRegistries = registries;
        if (this.checkUrlStats() !== null) {
          this.router.navigate([this.checkUrlStats()]).then();
        }
        this.me();
      }
    });
  }

  getEnterprises() {
    return this.enterprises;
  }

  getEnterprise() {
    return this.enterprise;
  }

  getAdmin() {
    return this.admin;
  }

  getProvider() {
    return this.provider;
  }

  getOnlyProvider() {
    return this.onlyProvider;
  }

  getUser() {
    return this.user;
  }

  getRegistries() {
    return this.registries;
  }

  getMonoOffer() {
    return this.monoOffer;
  }

  getDiscoverOffer() {
    return this.discoverOffer;
  }

  getDiscoverDate() {
    return this.discoverDate;
  }

  changeEnterprises(enterprises) {
    this.enterprises.next(enterprises);
  }

  changeEnterprise(enterprise) {
    this.loaderChange.next(true);

    this.http.post(environment.API_PATH + 'auth/switch', {enterprise_id: enterprise.id} )
      .subscribe(response => {
        if (response['success']) {
          const connectedAs = JSON.parse(localStorage.getItem('connectedAs'));
          if (connectedAs) {
            localStorage.setItem('connectedAsIsProvider', JSON.stringify(response['is_provider']));
            localStorage.setItem('connectedAsIsOnlyProvider', JSON.stringify(response['is_only_provider']));
            localStorage.setItem('connectedAsIsAdmin', JSON.stringify(response['is_admin']));
            localStorage.setItem('connectedAsEnterprise', JSON.stringify(enterprise));
            localStorage.setItem('connectedAsRegistries', JSON.stringify(response['registries']));
            localStorage.setItem('connectedAsIsMonoOffer', JSON.stringify(response['is_mono_offer']));
            localStorage.setItem('connectedAsIsDiscoverOffer', JSON.stringify(response['is_discover_offer']));
            if (response['is_discover_offer']) {
              localStorage.setItem('connectedAsDiscoverDate', JSON.stringify(response['discover_date']));
            }
          } else {
            localStorage.setItem('isProvider', JSON.stringify(response['is_provider']));
            localStorage.setItem('isOnlyProvider', JSON.stringify(response['is_only_provider']));
            localStorage.setItem('isAdmin', JSON.stringify(response['is_admin']));
            localStorage.setItem('enterprise', JSON.stringify(enterprise));
            localStorage.setItem('registries', JSON.stringify(response['registries']));
            localStorage.setItem('isMonoOffer', JSON.stringify(response['is_mono_offer']));
            localStorage.setItem('isDiscoverOffer', JSON.stringify(response['is_discover_offer']));
            if (response['is_discover_offer']) {
              localStorage.setItem('discoverDate', JSON.stringify(response['discover_date']));
            }
          }
          this.admin.next(response['is_admin']);
          this.provider.next(response['is_provider']);
          this.onlyProvider.next(response['is_only_provider']);
          this.enterprise.next(enterprise);
          this.registries.next(response['registries']);
          this.monoOffer.next(response['is_mono_offer']);
          this.discoverOffer.next(response['is_discover_offer']);
          if (response['is_discover_offer']) {
            this.discoverDate.next(response['discover_date']);
          } else {
            this.discoverDate.next(null);
          }

          this.statsRegistries = response['registries'];
          if (this.checkUrlStats() !== null) {
            this.router.navigate([this.checkUrlStats()]).then();
          }
        } else {
          CustomNotifierService.getErrorRequest(this.notifier);
        }
        this.loaderChange.next(false);
      });
  }

  changeAdmin(bool) {
    this.admin.next(bool);
  }

  changeProvider(bool) {
    this.provider.next(bool);
  }

  changeOnlyProvider(bool) {
    this.onlyProvider.next(bool);
  }

  changeUser(user) {
    this.user.next(user);
  }

  changeRegistries(registries) {
    this.registries.next(registries);
    this.checkUrlStats(registries);
  }

  changeMonoOffer(monoOffer) {
    this.monoOffer.next(monoOffer);
  }

  changeDiscoverOffer(discoverOffer) {
    this.discoverOffer.next(discoverOffer);
  }

  changeDiscoverDate(discoverDate) {
    this.discoverDate.next(discoverDate);
  }

  me() {
    this.http.get(environment.API_PATH + 'auth/me').subscribe(response => {
      if (response['success']) {
        const connectedAs = JSON.parse(localStorage.getItem('connectedAs'));
        if (connectedAs) {
          localStorage.setItem('connectedAsEnterprises', JSON.stringify(response['enterprises']));
          localStorage.setItem('connectedAsIsProvider', JSON.stringify(response['is_provider']));
          localStorage.setItem('connectedAsIsOnlyProvider', JSON.stringify(response['is_only_provider']));
          localStorage.setItem('connectedAsEnterprise', JSON.stringify(response['enterprise']));
          localStorage.setItem('connectedAsIsAdmin', JSON.stringify(response['is_admin']));
          localStorage.setItem('connectedAsRegistries', JSON.stringify(response['registries']));
          localStorage.setItem('connectedAsIsMonoOffer', JSON.stringify(response['is_mono_offer']));
          localStorage.setItem('connectedAsIsDiscoverOffer', JSON.stringify(response['is_discover_offer']));
          if (response['is_discover_offer']) {
            localStorage.setItem('connectedAsDiscoverDate', JSON.stringify(response['discover_date']));
          } else {
            localStorage.removeItem('connectedAsDiscoverDate');
          }
        } else {
          localStorage.setItem('enterprises', JSON.stringify(response['enterprises']));
          localStorage.setItem('isProvider', JSON.stringify(response['is_provider']));
          localStorage.setItem('isOnlyProvider', JSON.stringify(response['is_only_provider']));
          localStorage.setItem('enterprise', JSON.stringify(response['enterprise']));
          localStorage.setItem('isAdmin', JSON.stringify(response['is_admin']));
          localStorage.setItem('registries', JSON.stringify(response['registries']));
          localStorage.setItem('isMonoOffer', JSON.stringify(response['is_mono_offer']));
          localStorage.setItem('isDiscoverOffer', JSON.stringify(response['is_discover_offer']));
          if (response['is_discover_offer']) {
            localStorage.setItem('discoverDate', JSON.stringify(response['discover_date']));
          } else {
            localStorage.removeItem('discoverDate');
          }
        }
        this.enterprises.next(response['enterprises']);
        this.enterprise.next(response['enterprise']);
        this.provider.next(response['is_provider']);
        this.onlyProvider.next(response['is_only_provider']);
        this.admin.next(response['is_admin']);
        this.registries.next(response['registries']);
        this.monoOffer.next(response['is_mono_offer']);
        this.discoverOffer.next(response['is_discover_offer']);
        if (response['is_discover_offer']) {
          this.discoverDate.next(response['discover_date']);
        } else {
          this.discoverDate.next(null);
        }
        this.statsRegistries = response['registries'];

        if (this.isDashboardRedirect) {
          this.router.navigate([this.checkUrlStats()]).then();
        }
      } else {
        CustomNotifierService.getErrorRequest(this.notifier);
      }
    });
  }

  checkUrlStats(value = null) {
    let url = null;
    if (value) {
      this.statsRegistries = value;
    }
    for (let i = 0; i < this.statsMenu.length; i++) {
      if (url === null) {
        if (this.statsMenu[i].url === '/dashboard-providers') {
          url = this.statsMenu[i].url;
          break;
        } else if (this.statsMenu[i].registry_module) {
          for (let y = 0; y < this.statsRegistries.length; y++) {
            if (this.statsRegistries[y][this.statsMenu[i].registry_module]) {
              url = this.statsMenu[i].url;
              break;
            }
          }
        }
      }
    }
    return url;
  }
}
