import { Routes } from '@angular/router';

import { AllWorksheetsComponent } from './all-worksheets/all-worksheets.component';
import { UpdateWorksheetComponent } from './update-worksheet/update-worksheet.component';
import { UploadWorksheetFileComponent } from './upload-worksheet-file/upload-worksheet-file.component';
import { UpdateRequiredWorksheetComponent } from './update-required-worksheet/update-required-worksheet.component';

import { ModalLogsComponent } from '../logs/modal-logs/modal-logs.component';

export const worksheetsRoutes: Routes = [
  {
    path: 'registries/:registry_id/worksheets/file-types',
    component: AllWorksheetsComponent,
    children: [
      {
        path: 'create',
        component: UpdateRequiredWorksheetComponent
      },
      {
        path: ':file_type_id',
        component: UpdateRequiredWorksheetComponent
      }
    ]
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/worksheets',
    component: AllWorksheetsComponent,
    children: [
      {
        path: 'create',
        component: UpdateWorksheetComponent
      },
      {
        path: ':worksheet_id',
        component: UpdateWorksheetComponent
      },
      {
        path: ':worksheet_id/logs',
        component: ModalLogsComponent
      }
    ]
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/worksheet/:worksheet_id',
    component: AllWorksheetsComponent
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/worksheet/:worksheet_id/file-type/:file_type_id/files',
    component: AllWorksheetsComponent,
    children: [
      {
        path: 'create',
        component: UploadWorksheetFileComponent
      },
      {
        path: ':file_id',
        component: UploadWorksheetFileComponent
      }
    ]
  }
];
