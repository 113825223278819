<app-modal-right (eventOnClose)="closeModal()" #offer>{{ bindModal(offer) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">{{ isNew ? 'Création' : canUpdate ? 'Mise à jour' : 'Consultation' }}</h6>

        <h1 class="header-title">Notification</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <div *ngIf="!loaderContent">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label>Tuiles</label>
            <ng-select [items]="tiles"
                       [multiple]="false"
                       bindLabel="name"
                       notFoundText="Aucun résultat"
                       [selectableGroup]="true"
                       [closeOnSelect]="true"
                       bindValue="name"
                       placeholder="Sélectionnez une tuile"
                       [(ngModel)]="selectedTiles" (ngModelChange)="changeTiles()" [ngModelOptions]="{ standalone: true }">
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">{{ item.name }}</ng-template>
            </ng-select>
          </div>

          <div *ngIf="selectedTiles === 'Message personnalisé'">
            <!----------------------------- NOTIFICATION ----------------------------->
            <div class="form-group">
              <label for="notification" required>liste des utilisateurs
                <span *ngIf="errors && errors['users']" class="errors">
                  {{ getErrorMessage("users") }}
                </span>
              </label>
              <ng-select [items]="usersList"
                         [multiple]="true"
                         bindLabel="name"
                         [compareWith]="compareUsers"
                         groupBy="category"
                         notFoundText="Aucun résultat"
                         [selectableGroup]="true"
                         [selectableGroupAsModel]="false"
                         [closeOnSelect]="false"
                         placeholder="Sélectionnez un/ou plusieurs utilisateurs(s)"
                         [(ngModel)]="selectedUsers" [ngModelOptions]="{ standalone: true }">
                <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                  <input type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }"/> {{ item.category }}
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                  <div class="tooltipHtml" data-placement="bottom">
                    <input id="notification" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }"/>
                    {{ item.name }}
                  </div>
                </ng-template>
              </ng-select>
            </div>
            <div class="form-group">
              <label for="personal_url">Lien URL</label>
              <input class="form-control" id="personal_url" placeholder="Exemple : https://www.google.fr" [(ngModel)]="url">
            </div>
            <div class="form-group">
              <label for="personal_message" required>Message personnalisé
                <span *ngIf="errors && errors['personal_message']" class="errors">
                  {{ getErrorMessage("personal_message") }}
                </span>
              </label>
              <editor initialValue=""
                      apiKey="test"
                      [(ngModel)]="personal_message"
                      id="personal_message"
                      [init]="{ height: 250, menubar: false, plugins: ['link preview table code lists'],
                                toolbar:'undo redo | formatselect fontselect fontsizeselect | ' +
                                'bold italic underline strikethrough backcolor forecolor | preview | ' +
                                'alignleft aligncenter alignright alignjustify | ' +
                                'bullist numlist outdent indent link table'
                              }">
              </editor>
            </div>
            <div class="form-group mb-1 d-flex justify-content-between">
              <div>
                Envoyer une notification
              </div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="sendNotification" [(ngModel)]="send_notification" [ngClass]="{ 'backgroundColor': send_notification }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': send_notification }" for="sendNotification"></label>
              </div>
            </div>
          </div>

          <div *ngIf="selectedTiles === 'Message général'">
            <!----------------------------- NOTIFICATION ----------------------------->
            <div class="form-group">
              <label for="general_url">URL</label>
              <input class="form-control" id="general_url" placeholder="Exemple : https://www.google.fr" [(ngModel)]="url">
            </div>
            <div class="form-group">
              <label for="general_message" required>Message général
                <span *ngIf="errors && errors['personal_message']" class="errors">
                  {{ getErrorMessage("personal_message") }}
                </span>
              </label>
              <editor initialValue=""
                      apiKey="test"
                      [(ngModel)]="personal_message"
                      id="general_message"
                      [init]="{ height: 250, menubar: false, plugins: ['link preview table code lists'],
                                toolbar:'undo redo | formatselect fontselect fontsizeselect | ' +
                                'bold italic underline strikethrough backcolor forecolor | preview | ' +
                                'alignleft aligncenter alignright alignjustify | ' +
                                'bullist numlist outdent indent link table'
                              }">
              </editor>
            </div>
            <div class="form-group mb-1 d-flex justify-content-between">
              <div>
                Envoyer une notification
              </div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="sendAdminNotification" [(ngModel)]="send_notification" [ngClass]="{ 'backgroundColor': send_notification }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': send_notification }" for="sendAdminNotification"></label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div modal-footer class="w-100 d-flex justify-content-between">
    <button class="btn btn-primary w-100" (click)="isNew ? create() : update()" [ngClass]="{ 'is-loading is-loading-sm': loader }" [disabled]="loader || (!isNew && !canUpdate) || !selectedTiles">
      {{ isNew ? 'Créer' : 'Mettre à jour' }}
    </button>
  </div>
</app-modal-right>
