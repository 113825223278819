import { Routes } from '@angular/router';

import { AllExportsComponent } from './all-exports/all-exports.component';

export const adminExportsRoutes: Routes = [
  {
    path: 'exports',
    component: AllExportsComponent,
  }
];
