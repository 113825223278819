<div class="main-content">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col">
        <div class="header mb-0">
          <div class="header-body no-border">
            <div class="row align-items-center">
              <div class="col">
                <h1 class="header-title">
                  Tickets
                </h1>

                <small class="form-text text-muted">
                  <span>Liste des tickets</span>
                </small>

                <app-breadcrumbs [currentPage]="pageName" [names]="previousPageName" [routes]="previousPageRoute"></app-breadcrumbs>
              </div>

              <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0" *ngIf="view !== 'client' || (issue && issue.issue_state_id !== 3)">
                <a class="btn btn-primary d-block d-md-inline-block" [routerLink]="[currentRoute + 'comment']">
                  Ajouter un message
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="is-loading loader-content" *ngIf="loader"></div>



        <div class="row" *ngIf="issue && !loader">
          <div class="col-12 d-flex flex-column">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <h3 class="header-title">Ticket n°{{ getRef(issue.id) }} - {{ issue.content }}</h3>
                    <p *ngIf="view === 'admin'">Entreprise : {{ issue.enterprise.name }}</p>
                    <p>Catégorie : <span [className]="'label-rounded ' + issue.issue_category.label">{{ issue.issue_category.name }}</span></p>
                    <p>Priorité : {{ issue.emergency }}</p>
                    <p>État : <span [className]="'label-rounded ' + issue.issue_state.label">{{ issue.issue_state.name }}</span></p>

                    <div class="form-group" *ngIf="states && states.length">
                      <label>Changer l'état</label>
                      <ng-select [items]="states"
                                 [multiple]="false"
                                 bindLabel="name"
                                 bindValue="id"
                                 groupBy="group"
                                 notFoundText="Aucun résultat"
                                 [selectableGroupAsModel]="false"
                                 [closeOnSelect]="true"
                                 placeholder="Sélectionnez un état"
                                 [(ngModel)]="state" (ngModelChange)="setState()" [ngModelOptions]="{ standalone: true }">
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                          {{ item.name }}
                        </ng-template>
                      </ng-select>
                    </div>
                    <div class="form-group" *ngIf="categories && categories.length && issue.issue_state_id !== 3">
                      <label>Changer la catégorie</label>
                      <ng-select [items]="categories"
                                 [multiple]="false"
                                 bindLabel="name"
                                 bindValue="id"
                                 groupBy="group"
                                 notFoundText="Aucun résultat"
                                 [selectableGroupAsModel]="false"
                                 [closeOnSelect]="true"
                                 placeholder="Sélectionnez une catégorie"
                                 [(ngModel)]="category" (ngModelChange)="setCategory()" [ngModelOptions]="{ standalone: true }">
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                          {{ item.name }}
                        </ng-template>
                      </ng-select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-body msg_card_body">
                <div class="row d-flex justify-content-start mb-4 col-10" *ngFor="let comment of issue.issue_comments"
                     [ngClass]="{
                     'ml-auto from_support': (comment.from_support && view === 'admin') || (!comment.from_support && view === 'client')
                     }">
                  <p class="col-12 pre-line">{{ comment.comment }}</p>
                  <p class="col-12" *ngIf="comment.issue_comment_files && comment.issue_comment_files.length">
                    <img src="../assets/img/files/file_icon.png" [title]="file.name" *ngFor="let file of comment.issue_comment_files" (click)="downloadFile(file)"/>
                  </p>
                  <small>{{ comment.created | date : 'dd/MM/yyyy HH:mm' }} - {{ comment.user.firstname + ' ' + comment.user.lastname }}</small>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<router-outlet></router-outlet>
