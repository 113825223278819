import { AfterViewInit, Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { MapsAPILoader } from '@agm/core';

import { AllEstablishmentsComponent } from '../all-establishments/all-establishments.component';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { RegistriesRequestService } from '../../../services/registries.request.service';
import { EstablishmentsRequestService } from '../../../services/establishments.request.service';

declare var $: any;

@Component({
  selector: 'app-update-establishment-description',
  templateUrl: './update-establishment-description.component.html',
  styleUrls: ['./update-establishment-description.component.scss']
})
export class UpdateEstablishmentDescriptionComponent implements OnInit, AfterViewInit, OnDestroy {
  establishmentForm: FormGroup;
  description = new FormControl('');

  errors = {};

  loader = false;
  loaderContent = false;
  canUpdate = false;

  modalEstablishmentDescriptive = null;
  establishmentId;

  previousRoute = '';

  constructor(
    private ngZone: NgZone,
    private router: Router,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private mapsAPILoader: MapsAPILoader,
    private activatedRoute: ActivatedRoute,
    private registriesService: RegistriesRequestService,
    private allEstablishments: AllEstablishmentsComponent,
    private establishmentsService: EstablishmentsRequestService
  ) {}

  ngOnInit() {
    this.establishmentForm = this.formBuilder.group({
      description: this.description,
    });
    this.establishmentId = this.activatedRoute.snapshot.params.establishment_id;
    if (this.establishmentId) {
      this.getEstablishment();
    }

    this.previousRoute = this.activatedRoute.snapshot.parent.url.join('/');
  }

  ngAfterViewInit() {
    this.modalEstablishmentDescriptive.open();
  }

  ngOnDestroy() {
    this.modalEstablishmentDescriptive.close();
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  getEstablishment() {
    this.errors = {};
    this.loaderContent = true;

    this.establishmentsService.getMyEstablishment(this.establishmentId).subscribe(response => {
      if (response['success']) {
        this.establishmentForm.patchValue(response['establishment']);
        this.canUpdate = response['canUpdate'];
        if (!this.canUpdate) {
          this.establishmentForm.disable();
        }
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.establishmentForm, this.notifier);
      }
      this.loaderContent = false;
    });
  }

  updateEstablishment() {
    this.errors = {};
    this.loader = true;

    const establishment = {
      ...this.establishmentForm.value,
      modifyDescription: true,
    };

    this.establishmentsService.updateMyEstablishment(this.establishmentId, establishment).subscribe(response => {
      if (response['success']) {
        this.modalEstablishmentDescriptive.close();
        CustomNotifierService.getSuccessUpdate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.establishmentForm, this.notifier);
      }
      this.loader = false;
    });
  }

  bindModal(modal) {
    this.modalEstablishmentDescriptive = modal;
  }
  closeModal() {
    if (this.activatedRoute.parent.snapshot.params.group_id) {
      this.router.navigate([this.previousRoute]).then(() => {});
    } else {
      this.router.navigate(['/establishments/mine/active']).then(() => {});
    }
  }
}
