import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AllReportsModule } from './all-reports/all-reports.module';
import { UpdateReportModule } from './update-report/update-report.module';
import { CheckpointsRequestService } from '../../services/checkpoints.request.service';
import { InstallationsRequestService } from '../../services/installations.request.service';
import { ReportsRequestService } from '../../services/reports.request.service';
import { UploadReportFileModule } from './upload-report-file/upload-report-file.module';

@NgModule({
  imports: [
    CommonModule,
    AllReportsModule,
    UpdateReportModule,
    UploadReportFileModule
  ],
  providers: [
    CheckpointsRequestService,
    InstallationsRequestService,
    ReportsRequestService
  ]
})
export class ReportsModule {}
