<div id="video-modal" class="modal modal-video fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header align-items-center">

        <button type="button" class="close" (click)="close()">
          <span aria-hidden="true">&times;</span>
          <span class="sr-only">Close</span>
        </button>
      </div>
      <div class="modal-body">
        <video *ngIf="url" controls>
          <source [src]="url" type="video/mp4">
        </video>
      </div>
    </div>
  </div>
</div>
