import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';

import { CustomNotifierService } from '../../../services/custom.notifier.service';

import { AllCheckpointsComponent } from '../all-checkpoints/all-checkpoints.component';
import { CheckpointsRequestService } from '../../../services/checkpoints.request.service';
import { EnterprisesRequestService } from '../../../services/enterprises.request.service';
import { UsersRequestService } from '../../../services/users.request.service';
import { Subject } from 'rxjs';
import Checkpoint from '../../../../shared/interfaces/checkpoint';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

declare function Flatpickr(): any;

@Component({
  selector: 'app-update-my-checkpoint',
  templateUrl: './update-my-checkpoint.component.html',
  styleUrls: ['./update-my-checkpoint.component.scss']
})
export class UpdateMyCheckpointComponent implements OnInit, AfterViewInit, OnDestroy {
  canUpdate = false;

  modalCheckpoint = null;
  previousRoute = '';

  errors = {};
  view = '';

  enterprises: any = [];
  userReport: string;
  userObservation: string;

  checkpoint: Checkpoint;
  archive = false;
  showReference: SafeHtml = '';

  checkpointForm: FormGroup;
  id = new FormControl('');
  name = new FormControl('');
  description = new FormControl('');
  frequencies = new FormControl('');
  frequency_duration = new FormControl('', [Validators.required, Validators.min(0)]);
  frequency = new FormControl('', Validators.required);
  next_report_date = new FormControl('');
  report_enterprise_id = new FormControl('');
  report_user_id = new FormControl('');
  observation_enterprise_id = new FormControl('');
  observation_user_id = new FormControl('');
  installation_id = new FormControl('');
  checkpoint_id = new FormControl('');
  establishment_registry_id = new FormControl('', Validators.required);
  frequency_notification = new FormControl(true);
  frequency_alert = new FormControl(true);

  initialNextReportDate = '';

  loader = false;
  loaderContent = false;

  requestInProgress = new Subject();
  requestCount = 0;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private sanitizer: DomSanitizer,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private allCheckpoints: AllCheckpointsComponent,
    private checkpointsService: CheckpointsRequestService,
    private enterprisesService: EnterprisesRequestService,
    private usersService: UsersRequestService
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loaderContent = this.requestCount > 0;
      if (!this.loaderContent) {
        Flatpickr();
      }
    });

    this.previousRoute = this.activatedRoute.snapshot.parent.url.join('/');

    this.checkpointForm = this.formBuilder.group({
      id: this.id,
      name: this.name,
      description: this.description,
      frequencies: this.frequencies,
      frequency: this.frequency,
      frequency_duration: this.frequency_duration,
      next_report_date: this.next_report_date,
      report_enterprise_id: this.report_enterprise_id,
      report_user_id: this.report_user_id,
      observation_enterprise_id: this.observation_enterprise_id,
      observation_user_id: this.observation_user_id,
      checkpoint_id: this.checkpoint_id,
      installation_id: this.installation_id,
      establishment_registry_id: this.establishment_registry_id,
      frequency_notification: this.frequency_notification,
      frequency_alert: this.frequency_alert,
    });

    if (this.activatedRoute.snapshot.params.checkpoint_id) {
      this.getCheckpoint();
    }

    this.getEnterprises();
  }

  ngAfterViewInit() {
    this.modalCheckpoint.open();
  }

  ngOnDestroy() {
    const flatPicker = document.getElementsByClassName('flatpickr-calendar') as HTMLCollection;
    for (let i = flatPicker.length; i >= 0; i--) {
      if (flatPicker[i]) {
        flatPicker[i].remove();
      }
    }

    this.modalCheckpoint.close();
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  getCheckpoint() {
    this.errors = {};
    this.requestInProgress.next(true);

    this.checkpointsService.getMyEstablishmentCheckpoint(this.activatedRoute.snapshot.params.checkpoint_id).subscribe(response => {
      if (response['success']) {
        this.canUpdate = response['canUpdate'];
        this.checkpoint = response['checkpoint'];
        this.initialNextReportDate = response['next_report_date'];
        this.checkpointForm.patchValue(response['checkpoint']);
        if (this.checkpoint.report_enterprise_id && this.checkpoint.report_user_id) {
          this.userReport = this.checkpoint.report_enterprise_id + '-' + this.checkpoint.report_user_id;
        }
        if (this.checkpoint.observation_enterprise_id && this.checkpoint.observation_user_id) {
          this.userObservation = this.checkpoint.observation_enterprise_id + '-' + this.checkpoint.observation_user_id;
        }
        if (!this.canUpdate) {
          this.checkpointForm.disable();
        }
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.checkpointForm, this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  getEnterprises() {
    this.requestInProgress.next(true);

    this.enterprisesService.getProviders('', '', '', '', '').subscribe(response => {
      if (response['success']) {
        response['enterprises'].forEach(enterprise => {
          this.getUsers(enterprise);
        });
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  getUsers(enterprise) {
    this.requestInProgress.next(true);

    this.usersService.getProviderUsers('', '', '', '', '', enterprise.id).subscribe(response => {
      if (response['success']) {
        response['users'].forEach(user => {
          this.enterprises.push({
            id: enterprise.id + '-' + user.id,
            name: enterprise.name + ' - ' + user.firstname + ' ' + user.lastname
          });
        });
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  changeUserReport() {
    if (this.userReport) {
      const split = this.userReport.split('-');
      this.report_enterprise_id.setValue(split[0]);
      this.report_user_id.setValue(split[1]);
    } else {
      this.report_enterprise_id.setValue(null);
      this.report_user_id.setValue(null);
    }
  }

  changeUserObservation() {
    if (this.userObservation) {
      const split = this.userObservation.split('-');
      this.observation_enterprise_id.setValue(split[0]);
      this.observation_user_id.setValue(split[1]);
    } else {
      this.observation_enterprise_id.setValue(null);
      this.observation_user_id.setValue(null);
    }
  }

  updateCheckpoint() {
    this.errors = {};
    this.loader = true;

    const customNextReportDate = this.next_report_date.value !== this.initialNextReportDate;
    const checkpoint = { ...this.checkpointForm.value, custom_next_report_date: customNextReportDate };
    this.checkpointsService.updateMyEstablishmentCheckpoint(this.id.value, checkpoint).subscribe(response => {
      if (response['success']) {
        this.modalCheckpoint.close();
        this.allCheckpoints.refreshData();
        CustomNotifierService.getSuccessUpdate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.checkpointForm, this.notifier);
      }
      this.loader = false;
    });
  }

  changeFrequency() {
    this.frequency.updateValueAndValidity();
    this.frequency_duration.updateValueAndValidity();
  }

  getReference(reference) {
    this.showReference = this.sanitizer.bypassSecurityTrustHtml(reference);
    return this.showReference;
  }

  binModal(modal) {
    this.modalCheckpoint = modal;
  }

  closeModal() {
    this.allCheckpoints.refreshData();
    this.router.navigate([this.previousRoute]).then(() => {
    });
  }

}
