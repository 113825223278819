import { Routes } from '@angular/router';

import { AllPreventionRisksComponent } from './all-prevention-risks/all-prevention-risks.component';
import { UpdatePreventionRiskComponent } from './update-prevention-risk/update-prevention-risk.component';
import { ModalLogsComponent } from '../logs/modal-logs/modal-logs.component';

export const preventionRisksRoutes: Routes = [
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/prevention-risks',
    component: AllPreventionRisksComponent,
    children: [
      {
        path: 'create',
        component: UpdatePreventionRiskComponent
      },
      {
        path: ':risk_id',
        component: UpdatePreventionRiskComponent
      },
      {
        path: ':risk_id/logs',
        component: ModalLogsComponent
      },
    ]
  },
  {
    path: 'prevention-risks',
    component: AllPreventionRisksComponent,
    children: [
      {
        path: 'create',
        component: UpdatePreventionRiskComponent
      },
      {
        path: ':risk_id',
        component: UpdatePreventionRiskComponent
      },
    ]
  },
];
