import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NotifierService } from 'angular-notifier';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()

/**
 * Groups all generic functions for requests to the API.
 */
export class RequestService {

  constructor(
    protected http: HttpClient,
    protected notifier: NotifierService
  ) {}

  /**
   * Generic GET method request.
   *
   * @param path The route needed for the request.
   * @param options The options to send.
   */
  protected get(path: string, options: any = {}) {
    return new Observable((observer) => {
      this.http.get(environment.API_PATH + path, options).subscribe(response => {
        observer.next(response);
      });
    });
  }

  /**
   * Generic POST method request.
   *
   * @param path The route needed for the request.
   * @param data The data to send.
   */
  protected post(path: string, data: any) {
    return new Observable((observer) => {
      this.http.post(environment.API_PATH + path, data).subscribe(response => {
        observer.next(response);
      });
    });
  }

  /**
   * Generic PUT method request.
   *
   * @param path The route needed for the request.
   * @param data The data to send.
   */
  protected put(path: string, data: any) {
    return new Observable((observer) => {
      this.http.put(environment.API_PATH + path, data).subscribe(response => {
        observer.next(response);
      });
    });
  }

  /**
   * Generic DELETE method request.
   *
   * @param path The route needed for the request.
   */
  protected delete(path: string) {
    return new Observable((observer) => {
      this.http.delete(environment.API_PATH + path).subscribe(response => {
        observer.next(response);
      });
    });
  }
}
