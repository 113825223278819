<app-modal-right (eventOnClose)="closeModal()" #modalLogs>{{ bindModal(modalLogs) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">Consultation</h6>

        <h1 class="header-title">Historique des actions</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="row mb-4">
      <div class="col">
        <app-searchbar [(ngModel)]="search" (keyup)="searchData()" ngDefaultControl></app-searchbar>
      </div>
    </div>

    <div class="tab-content">
      <div class="tab-pane fade active show" id="tabPaneOne" role="tabpanel">
        <div class="row listAlias">
          <div class="col-12 d-flex flex-column">
            <div class="card flex-fill">
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table table-striped mb-0">
                    <thead>
                      <tr>
                        <th class="href" (click)="filter(sortBy + 'Logs.created')">
                          <a class="text-muted">Créé le</a>
                          <span class="fe fe-arrow-down" *ngIf="sort === sortBy + 'Logs.created' && order === 'asc'"></span>
                          <span class="fe fe-arrow-up" *ngIf="sort === sortBy + 'Logs.created' && order === 'desc'"></span>
                          <span class="fe fe-arrow-right" *ngIf="sort !== sortBy + 'Logs.created'"></span>
                        </th>
                        <th class="href" (click)="filter(sortBy + 'Logs.author_user')">
                          <a class="text-muted">Utilisateur</a>
                          <span class="fe fe-arrow-down" *ngIf="sort === sortBy + 'Logs.author_user' && order === 'asc'"></span>
                          <span class="fe fe-arrow-up" *ngIf="sort === sortBy + 'Logs.author_user' && order === 'desc'"></span>
                          <span class="fe fe-arrow-right" *ngIf="sort !== sortBy + 'Logs.author_user'"></span>
                        </th>
                        <th class="href" (click)="filter(sortBy + 'Logs.author_enterprise')">
                          <a class="text-muted">Entreprise</a>
                          <span class="fe fe-arrow-down" *ngIf="sort === sortBy + 'Logs.author_enterprise' && order === 'asc'"></span>
                          <span class="fe fe-arrow-up" *ngIf="sort === sortBy + 'Logs.author_enterprise' && order === 'desc'"></span>
                          <span class="fe fe-arrow-right" *ngIf="sort !== sortBy + 'Logs.author_enterprise'"></span>
                        </th>
                        <th>Message</th>
                      </tr>
                    </thead>
                    <tbody>
                      <!-- loader -->
                      <tr *ngIf="loader">
                        <td colspan="9">
                          <div class="is-loading loader-content"></div>
                        </td>
                      </tr>
                      <tr *ngFor="let log of logs">
                        <td>{{ log.created | date : 'dd/MM/yyyy HH:mm' }}</td>
                        <td>{{ log.author_user }}</td>
                        <td>{{ log.author_enterprise }}</td>
                        <td><span [innerHTML]="log.message"></span></td>
                      </tr>
                      <tr *ngIf="!loader && !logs.length">
                        <td colspan="9">
                          <div class="loader-content">
                            Aucun résultat
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div modal-footer class="w-100">
    <app-pagination-modal (returnEvent)="dispatchPagination($event)"
                          [count_elements]="count"
                          [current_page]="page"
                          [elements_length]="logs.length"
                          [limit]="limit"
                          [total_pages]="pages"
                          ngDefaultControl>
    </app-pagination-modal>
  </div>
</app-modal-right>
