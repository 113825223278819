import { Routes } from '@angular/router';

import { AllSecurityProtocolsComponent } from './all-security-protocols/all-security-protocols.component';
import { UpdateSecurityProtocolComponent } from './update-security-protocol/update-security-protocol.component';
import { ModalLogsComponent } from '../logs/modal-logs/modal-logs.component';
import { UploadSecurityProtocolFileComponent } from './upload-security-protocol-file/upload-security-protocol-file.component';

export const securityProtocolsRoutes: Routes = [
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/security-protocols',
    component: AllSecurityProtocolsComponent,
    children: [
      {
        path: 'create',
        component: UpdateSecurityProtocolComponent
      },
      {
        path: ':security_protocol_id',
        component: UpdateSecurityProtocolComponent
      },
      {
        path: ':security_protocol_id/logs',
        component: ModalLogsComponent
      }
    ]
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/security-protocols/:security_protocol_id/files',
    component: AllSecurityProtocolsComponent,
    children: [
      {
        path: 'create',
        component: UploadSecurityProtocolFileComponent
      },
      {
        path: ':file_id',
        component: UploadSecurityProtocolFileComponent
      }
    ]
  },
];
