import { Component, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { Subject, Subscription } from 'rxjs';

import { environment } from '../../../../environments/environment';

import { AuthService } from '../../../core/auth/auth.service';
import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { ExportDownloadRequestService } from '../../../services/export-download.request.service';

@Component({
  selector: 'app-all-exports',
  templateUrl: './all-exports.component.html',
  styleUrls: ['./all-exports.component.scss']
})
export class AllExportsComponent implements OnInit {
  changeEnterprise: Subscription;
  environment = environment;

  requestInProgress = new Subject();
  requestCount = 0;

  loader = false;

  canCreate = false;
  canUpdate = false;
  canDelete = false;

  pageName: string;
  previousPageName: string[] = [];
  previousPageRoute: string[] = [];

  downloadExports = [
    {'url': 'users-export', 'title': 'Utilisateurs'},
  ];

  modalConfirmData = null;

  constructor(
    private notifier: NotifierService,
    private authService: AuthService,
    private exportDownloadService: ExportDownloadRequestService
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loader = this.requestCount > 0;
    });

    this.pageName = 'Liste des exports';
    this.previousPageName.push('Tableau de bord');
    this.previousPageRoute.push('/administration');
  }

  download(link, title) {
    this.loader = true;
    this.exportDownloadService.adminExport(link)
      .subscribe((response: BufferSource) => {
        try {
          const decoder = new TextDecoder('utf-8');
          const domString = decoder.decode(response);
          const json = JSON.parse(domString);
          CustomNotifierService.getErrors(json['errors'], this.notifier);
        } catch (e) {
          const blob = new Blob([response]);
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          a.download = 'Export ' + title + '.' + 'xlsx';
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
        }
        this.loader = false;
      });
  }
}
