import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { HttpClient } from '@angular/common/http';
import { RequestService } from './request.service';

@Injectable()
export class RisksRequestService extends RequestService {
  constructor(
    protected http: HttpClient,
    protected notifier: NotifierService
  ) {
    super(http, notifier);
  }

  /// GET
  public getUnities(search, sort, order, limit, page) {
    return this.get('risks/unities?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getForGlobalUnitiesShow() {
    return this.get('risks/unities/global-show');
  }
  public getAllMyRegistryEstablishmentUnities(search, sort, order, limit, page, registry_id, establishment_id) {
    return this.get('establishment/mine/' + establishment_id + '/registry/' + registry_id + '/risk-unities?' +
        'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page + '&all=1');
  }
  public getMyRegistryEstablishmentUnities(search, sort, order, limit, page, registry_id, establishment_id) {
    return this.get('establishment/mine/' + establishment_id + '/registry/' + registry_id + '/risk-unities?' +
        'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getMyRegistryEstablishmentUnitiesShow(registry_id, establishment_id) {
    return this.get('establishment/mine/' + establishment_id + '/registry/' + registry_id + '/risk-unities/show');
  }
  public getUnity(unity_id) {
    return this.get('risks/unity/' + unity_id);
  }
  public getUsers(search, sort, order, limit, page) {
    return this.get('risks/users?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getForGlobalUsersShow() {
    return this.get('risks/users/global-show');
  }
  public getUser(user_id) {
    return this.get('risks/user/' + user_id);
  }
  public getAllMyRegistryEstablishmentUsers(search, sort, order, limit, page, registry_id, establishment_id) {
    return this.get('establishment/mine/' + establishment_id + '/registry/' + registry_id + '/risk-users?' +
        'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page + '&all=1');
  }
  public getMyRegistryEstablishmentUsers(search, sort, order, limit, page, registry_id, establishment_id) {
    return this.get('establishment/mine/' + establishment_id + '/registry/' + registry_id + '/risk-users?' +
        'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getMyRegistryEstablishmentUsersShow(registry_id, establishment_id) {
    return this.get('establishment/mine/' + establishment_id + '/registry/' + registry_id + '/risk-users/show');
  }
  public getRisksWork(search, sort, order, limit, page, establishment_id, unity_id) {
    return this.get('risk/' + establishment_id + '/unity/' + unity_id +
      '?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getRisk(establishment_id, risk_id) {
    return this.get('risk/' + establishment_id + '/' + risk_id);
  }
  public getCosts(search, sort, order, limit, page) {
    return this.get('risks/costs?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getCost(cost_id) {
    return this.get('risks/cost/' + cost_id);
  }
  public getDamages(search, sort, order, limit, page) {
    return this.get('risks/damages?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getDamagesLabel() {
    return this.get('risks/damages/labels');
  }
  public getDamage(damage_id) {
    return this.get('risks/damage/' + damage_id);
  }
  public getFiles(search, sort, order, limit, page, risk_id) {
    return this.get('risks/' + risk_id +
      '/files?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getFile(risk_id, file_id) {
    return this.get('risks/' + risk_id + '/files/' + file_id);
  }
  public getActions(search, sort, order, limit, page, establishment_id) {
    return this.get('risks/actions/' + establishment_id +
        '/?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public downloadActions(establishment_id) {
    return this.get('risks/download-actions/' + establishment_id, { responseType: 'arraybuffer' });
  }
  public getFamilies(search, sort, order, limit, page) {
    return this.get('risks/families?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getFamiliesLabel() {
    return this.get('risks/families/labels');
  }
  public getFamily(family_id) {
    return this.get('risks/family/' + family_id);
  }

  /// CREATE
  public createUnity(unity) {
    return this.post('risks/unity', { unity });
  }
  public createUser(user) {
    return this.post('risks/user', { user });
  }
  public createRisk(establishment_id, risk) {
    return this.post('risk/' + establishment_id, { risk });
  }
  public createCost(cost) {
    return this.post('risks/cost', { cost });
  }
  public createDamage(damage) {
    return this.post('risks/damage', { damage });
  }
  public createFile(risk_id, file) {
    return this.post('risks/' + risk_id + '/files', { file: file });
  }
  public createFamily(family) {
    return this.post('risks/family', { family });
  }

  /// UPDATE
  public updateUnity(unity_id, unity) {
    return this.put('risks/unity/' + unity_id, { unity });
  }
  public updateForGlobalUnitiesShow(establishment) {
    return this.put('risks/unities/global-show', { establishment });
  }
  public updateMyRegistryEstablishmentUnitiesShow(establishment_id, registry_id, unities) {
    return this.put('establishment/mine/' + establishment_id + '/registry/' + registry_id +
        '/risk-unities/show', { unities });
  }
  public updateUser(user_id, user) {
    return this.put('risks/user/' + user_id, { user });
  }
  public updateForGlobalUsersShow(establishment) {
    return this.put('risks/users/global-show', { establishment });
  }
  public updateMyRegistryEstablishmentUsersShow(establishment_id, registry_id, users) {
    return this.put('establishment/mine/' + establishment_id + '/registry/' + registry_id +
        '/risk-users/show', { users });
  }
  public updateRisk(establishment_id, risk_id, risk) {
    return this.put('risk/' + establishment_id + '/' + risk_id, { risk });
  }
  public updateCost(cost_id, cost) {
    return this.put('risks/cost/' + cost_id, { cost });
  }
  public updateDamage(damage_id, damage) {
    return this.put('risks/damage/' + damage_id, { damage });
  }
  public updateFile(risk_id, file_id, file) {
    return this.put('risks/' + risk_id + '/files/' + file_id, { file: file });
  }
  public updateFamily(family_id, family) {
    return this.put('risks/family/' + family_id, { family });
  }

  /// DELETE
  public deleteUnity(unity_id) {
    return this.delete('risks/unity/' + unity_id);
  }
  public deleteUser(user_id) {
    return this.delete('risks/user/' + user_id);

  }
  public deleteRisk(establishment_id, risk_id) {
    return this.delete('risk/' + establishment_id + '/' + risk_id);

  }
  public deleteDamage(damage_id) {
    return this.delete('risks/damage/' + damage_id);
  }
  public deleteFile(risk_id, file_id) {
    return this.delete('risks/' + risk_id + '/files/' + file_id);
  }
  public deleteFamily(family_id) {
    return this.delete('risks/family/' + family_id);
  }

  /// DOWNLOAD
  public downloadFile(risk_id, file_id) {
    return this.get('risks/' + risk_id + '/files/' + file_id + '/download', { responseType: 'arraybuffer' });
  }

}
