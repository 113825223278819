import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { HttpClient } from '@angular/common/http';
import { NotifierService } from 'angular-notifier';

@Injectable()
export class ReportWorksheetsRequestService extends RequestService {

  constructor(
    protected http: HttpClient,
    protected notifier: NotifierService
  ) {
    super(http, notifier);
  }

  /// GET
  public getAllReports(search, sort, order, limit, page, registry_id, establishment_id) {
    return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/report_worksheets?' +
      'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page
    );
  }
  public getReport(registry_id, establishment_id, report_id) {
    return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/report_worksheets/' + report_id
    );
  }
  public getAllWorks(search, sort, order, limit, page, registry_id, establishment_id, report_id) {
    return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/report_worksheets/' + report_id +
      '/works?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page
    );
  }
  public getWork(registry_id, establishment_id, report_id, work_id) {
    return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/report_worksheets/' + report_id +
      '/works/' + work_id
    );
  }
  public getDatesWorksDashboard(registry_id) {
    return this.get('dashboard-registry/' + registry_id + '/dates-works');
  }
  public getWorksByDateDashboard(search, sort, order, limit, page, registry_id, year) {
    return this.get('dashboard-registry/' + registry_id + '/year/' + year +  '/works?' +
      'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page
    );
  }
  public getWorkDashboard(registry_id, report_id, work_id) {
    return this.get('dashboard-registry/' + registry_id + '/report_worksheets/' + report_id + '/works/' + work_id
    );
  }
  public getAllReportsDashboard(registry_id) {
    return this.get('dashboard-registry/' + registry_id + '/report_worksheets');
  }

  /// CREATE
  public createReport(registry_id, establishment_id, report) {
    return this.post('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/report_worksheets', { report });
  }
  public createWork(registry_id, establishment_id, report_id, work) {
    return this.post('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/report_worksheets/' + report_id +
      '/works', { work });
  }

  /// UPDATE
  public updateReport(registry_id, establishment_id, report_id, report) {
    return this.put('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/report_worksheets/' + report_id,
      { report });
  }
  public updateWork(registry_id, establishment_id, report_id, work_id, work) {
    return this.put('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/report_worksheets/' + report_id +
      '/works/' + work_id, { work });
  }
  public updateWorkDashboard(registry_id, report_id, work_id, work) {
    return this.put('dashboard-registry/' + registry_id + '/report_worksheets/' + report_id + '/works/' + work_id, { work });
  }

  /// DELETE
  public deleteReport(registry_id, establishment_id, report_id) {
    return this.delete('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/report_worksheets/' + report_id);
  }
  public deleteWork(registry_id, establishment_id, report_id, work_id) {
    return this.delete('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/report_worksheets/' + report_id +
      '/works/' + work_id);
  }
}
