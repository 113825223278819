import {Component, OnDestroy, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { NotifierService } from 'angular-notifier';
import { StatisticsRequestService } from '../../../services/statistics.request.service';
import { Subject, Subscription } from 'rxjs';
import { AuthService } from '../../../core/auth/auth.service';

import * as moment from 'moment';

declare function UserCharts(): any;
declare function Flatpickr(): any;

@Component({
  selector: 'app-dashboard-asbestos',
  templateUrl: './dashboard-asbestos.component.html',
  styleUrls: ['./dashboard-asbestos.component.scss']
})
export class DashboardAsbestosComponent implements OnInit, OnDestroy {
  changeEnterprise: Subscription;
  establishments = [];
  selectedEstablishments = null;
  userChart = UserCharts();
  installations;
  checkpoints;
  periods = [
    { value: 'custom', name: 'Période personnalisée' },
    { value: 'days', name: '7 derniers jours' },
    { value: 'month', name: '30 derniers jours' },
    { value: 'months', name: '3 derniers mois' },
    { value: 'year', name: 'Année en cours' },
    { value: 'lastyear', name: 'Année dernière' },
    { value: 'lastyears', name: 'Il y a deux ans' }
  ];
  period = 'custom';
  dateFrom;
  dateTo;
  groupBy = 'month';

  // ASBESTOS
  hasAsbestos = 0;
  nbLocalizations = 0;
  spottedParts = 0;

  loaderAsbestosDetection = false;
  loaderAsbestosMeasure = false;
  loaderAsbestosWorksType = false;

  requestInProgress = new Subject();
  requestCount = 0;
  loader = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private notifier: NotifierService,
    private statisticsService: StatisticsRequestService
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loader = this.requestCount > 0;
    });

    this.changeEnterprise = this.authService.getEnterprise().subscribe(() => {
      this.initUserCharts();
      Flatpickr();
    });

    if (this.authService.loaded) {
      this.initUserCharts();
      setTimeout(function () {
        Flatpickr();
      }, 100);
    }
  }

  ngOnDestroy() {
    this.changeEnterprise.unsubscribe();

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  initUserCharts() {
    this.statisticsService.getEstablishmentsNumber('true', '').subscribe(response => {
      if (response['success']) {
        this.establishments = response['establishments'];
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
    });
    this.statisticsService.getAsbestosStats('month', '', '').subscribe(response => {
      if (response['success']) {
        this.userChart.createAsbestosChart('#headerChart', response, 'month');
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
    });
    this.getAsbestosBlock();
    this.getAsbestosMeasureBlock();
    this.getAsbestosWorksTypeBlock();
    this.getAsbestosWorksEstablishmentsBlock();
  }

  updateAsbestosChart(groupBy = this.groupBy) {
    this.groupBy = groupBy;
    this.statisticsService.getAsbestosStats(groupBy, this.dateFrom, this.dateTo)
      .subscribe(response => {
        if (response['success']) {
          this.userChart.createAsbestosChart('#headerChart', response, groupBy);
        } else if (response['errors']) {
          CustomNotifierService.getErrors(response['errors'], this.notifier);
        }
      });
  }

  updateSelectedEstablishments() {
    this.getAsbestosBlock();
    this.getAsbestosMeasureBlock();
    this.getAsbestosWorksTypeBlock();
    this.getAsbestosWorksEstablishmentsBlock();
  }

  updatePeriod(event = null) {
    let update = false;
    if (this.period === 'days') {
      this.dateFrom = moment().subtract(7, 'day').format('YYYY-MM-DD');
      this.dateTo = moment().format('YYYY-MM-DD');
      update = true;
    } else if (this.period === 'month') {
      this.dateFrom = moment().subtract(1, 'month').format('YYYY-MM-DD');
      this.dateTo = moment().format('YYYY-MM-DD');
      update = true;
    } else if (this.period === 'months') {
      this.dateFrom = moment().subtract(3, 'month').format('YYYY-MM-DD');
      this.dateTo = moment().format('YYYY-MM-DD');
      update = true;
    } else if (this.period === 'year') {
      this.dateFrom = moment().startOf('year').format('YYYY-MM-DD');
      this.dateTo = moment().endOf('year').format('YYYY-MM-DD');
      update = true;
    } else if (this.period === 'lastyear') {
      this.dateFrom = moment().startOf('year').subtract(1, 'year').format('YYYY-MM-DD');
      this.dateTo = moment().endOf('year').subtract(1, 'year').format('YYYY-MM-DD');
      update = true;
    } else if (this.period === 'lastyears') {
      this.dateFrom = moment().startOf('year').subtract(2, 'year').format('YYYY-MM-DD');
      this.dateTo = moment().endOf('year').subtract(2, 'year').format('YYYY-MM-DD');
      update = true;
    }
    if (event === null) {
      this.dateFrom = null;
      this.dateTo = null;
      update = true;
    }
    if (update) {
      this.updateAsbestosChart();
      this.getAsbestosBlock();
      this.getAsbestosMeasureBlock();
      this.getAsbestosWorksTypeBlock();
      this.getAsbestosWorksEstablishmentsBlock();
    }
  }

  getAsbestosBlock() {
    this.loaderAsbestosDetection = true;
    this.statisticsService.asbestosStat(this.dateFrom, this.dateTo, this.selectedEstablishments).subscribe(response => {
      this.hasAsbestos = 0;
      this.nbLocalizations = 0;
      this.spottedParts = 0;
      if (response['success']) {
        this.hasAsbestos = response['asbestos'][0]['nbHasAsbestos'];
        if (response['asbestos'][0]['nbLocalizations']) {
          this.nbLocalizations = response['asbestos'][0]['nbLocalizations'];
        }
        if (response['asbestos'][0]['nbLocalizations']) {
          this.spottedParts = response['asbestos'][0]['spottedParts'];
        }
        this.userChart.createAsbestosDetectionChart('#asbestosDetectionChart',
          this.hasAsbestos, this.nbLocalizations, this.spottedParts, '');
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loaderAsbestosDetection = false;
    });
  }
  getAsbestosMeasureBlock() {
    this.loaderAsbestosMeasure = true;
    this.statisticsService.asbestosMeasureStat(this.dateFrom, this.dateTo, this.selectedEstablishments).subscribe(response => {
      if (response['success']) {
        this.userChart.createAsbestosMeasureChart('#asbestosMeasureChart', response);
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loaderAsbestosMeasure = false;
    });
  }
  getAsbestosWorksTypeBlock() {
    this.loaderAsbestosWorksType = true;
    this.statisticsService.asbestosWorksTypeStat(this.dateFrom, this.dateTo, this.selectedEstablishments).subscribe(response => {
      if (response['success']) {
        this.userChart.createAsbestosWorksTypeChart('#asbestosWorksTypeChart', response);
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loaderAsbestosWorksType = false;
    });
  }
  getAsbestosWorksEstablishmentsBlock() {
    this.loaderAsbestosWorksType = true;
    this.statisticsService.asbestosEstablishmentsStat(this.dateFrom, this.dateTo, this.selectedEstablishments).subscribe(response => {
      if (response['success']) {
        this.userChart.createAsbestosEstablishmentsChart('#asbestosEstablishmentsChart', response);
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loaderAsbestosWorksType = false;
    });
  }

  resetFilters() {
    this.selectedEstablishments = [];
    this.period = 'custom';
    this.getAsbestosBlock();
    this.getAsbestosMeasureBlock();
    this.getAsbestosWorksTypeBlock();
  }
}
