import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';

import { CustomNotifierService } from '../../../../services/custom.notifier.service';

import { AllFamiliesComponent } from '../all-families/all-families.component';
import { RisksRequestService } from '../../../../services/risks.request.service';

@Component({
  selector: 'app-update-family',
  templateUrl: './update-family.component.html',
  styleUrls: ['./update-family.component.scss']
})
export class UpdateFamilyComponent implements OnInit, AfterViewInit, OnDestroy {
  canUpdate = false;
  errors = {};

  loader = false;
  loaderContent = false;

  isNew = true;
  archive = false;

  previousRoute = '';

  modal = null;

  familyForm: FormGroup;
  id = new FormControl('');
  name = new FormControl('', Validators.required);

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private allUnities: AllFamiliesComponent,
    private risksService: RisksRequestService
  ) {}

  ngOnInit() {
    this.previousRoute = this.activatedRoute.snapshot.parent.url.join('/');

    if (this.activatedRoute.snapshot.params.family_id) {
      this.isNew = false;
      this.getFamily();
    }

    this.familyForm = this.formBuilder.group({
      id: this.id,
      name: this.name,
    });
  }

  ngAfterViewInit() {
    this.modal.open();
  }

  ngOnDestroy() {
    this.modal.close();
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  getFamily() {
    this.errors = {};
    this.loaderContent = true;

    this.risksService.getFamily(this.activatedRoute.snapshot.params.family_id).subscribe(response => {
      if (response['success']) {
        if (!this.archive) {
          this.canUpdate = response['canUpdate'];
        }
        if (!this.canUpdate) {
          this.familyForm.disable();
        }
        this.familyForm.patchValue(response['risk_family']);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.familyForm, this.notifier);
      }
      this.loaderContent = false;
    });

  }

  createFamily() {
    this.errors = {};
    this.loader = true;

    const family = { ...this.familyForm.value };
    this.risksService.createFamily(family).subscribe(response => {
      if (response['success']) {
        this.modal.close();
        this.allUnities.refreshData();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.familyForm, this.notifier);
      }
      this.loader = false;
    });
  }

  updateFamily() {
    this.errors = {};
    this.loader = true;

    this.risksService.updateFamily(this.id.value, this.familyForm.value).subscribe(response => {
      if (response['success']) {
        this.modal.close();
        this.allUnities.refreshData();
        CustomNotifierService.getSuccessUpdate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.familyForm, this.notifier);
      }
      this.loader = false;
    });
  }

  binModal(modal) {
    this.modal = modal;
  }

  closeModal() {
    this.allUnities.refreshData();
    this.router.navigate([this.previousRoute]).then(() => {
    });
  }
}
