import { Component, OnInit, AfterViewInit, OnDestroy, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';

import { AllCheckpointsComponent } from '../all-checkpoints/all-checkpoints.component';

import { Subject } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CustomNotifierService } from '../../../services/custom.notifier.service';
import checkpoint from '../../../../shared/interfaces/checkpoint';
import { CheckpointsRequestService } from '../../../services/checkpoints.request.service';

@Component({
  selector: 'app-tooltip-checkpoint',
  templateUrl: './tooltip-checkpoint.component.html',
  styleUrls: ['./tooltip-checkpoint.component.scss']
})
export class TooltipCheckpointComponent implements OnInit, AfterViewInit, OnDestroy {
  canCreate = false;
  canUpdate = false;

  requestInProgress = new Subject();
  requestCount = 0;
  errors = {};

  isNew = true;
  loader = false;
  loaderContent = false;

  view = '';
  showDescription: SafeHtml = [];
  checkpoint: checkpoint;
  references: any[] = [];

  modalContent = null;

  contents: any = [];

  constructor(
    private router: Router,
    private injector: Injector,
    public sanitizer: DomSanitizer,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private checkpointsService: CheckpointsRequestService,
    private allCheckpointsComponent: AllCheckpointsComponent
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loaderContent = this.requestCount > 0;
    });

    if (this.activatedRoute.snapshot.params.checkpoint_id) {
      this.getCheckpoint();
    }
  }

  ngAfterViewInit() {
    this.modalContent.open();
  }

  ngOnDestroy() {
    this.modalContent.close();
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  getCheckpoint() {
    this.errors = {};
    this.requestInProgress.next(true);

    this.checkpointsService.getMyEstablishmentCheckpoint(this.activatedRoute.snapshot.params.checkpoint_id).subscribe(response => {
      if (response['success']) {
        this.canUpdate = response['canUpdate'];
        this.checkpoint = response['checkpoint'];
        this.checkpoint['reference'].forEach(reference => {
          this.showDescription = this.sanitizer.bypassSecurityTrustHtml(reference);
          this.references.push(this.showDescription);
        });
        console.log(this.references);
        // this.showDescription = this.sanitizer.bypassSecurityTrustHtml(response['checkpoint']['reference']);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrors(this.errors, this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }


  binModal(modal) {
    this.modalContent = modal;
  }

  closeModal() {
    this.allCheckpointsComponent.getCheckpoints();
    this.router.navigate([this.activatedRoute.snapshot.parent.url.join('/')]).then(() => {});
  }
}
