import { Component, Injector, OnInit } from '@angular/core';

import { AuthService } from '../auth/auth.service';

import { environment } from '../../../environments/environment';
import { NotificationsRequestService } from '../../services/notifications.request.service';

import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { CustomNotifierService } from '../../services/custom.notifier.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MessagingService } from '../../services/messaging.service';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { NotificationService } from '../../services/notification.service';
import { InterventionsRequestService } from '../../services/interventions.request.service';
import * as firebase from 'firebase';

@Component({
  selector: 'app-toolbar-notification',
  templateUrl: './toolbar-notification.component.html',
  styleUrls: ['./toolbar-notification.component.scss']
})
export class ToolbarNotificationComponent implements OnInit {

  user: any;
  enterprise: any;
  hideSidebar: boolean;
  environment = environment;

  requestInProgress = new Subject();
  requestCount = 0;

  notificationToken: any;
  notificationUser: any;
  notificationEnterprise: any;

  search = '';
  sort = 'Notifications.created';
  order = 'desc';
  limit = 10;
  page = 1;

  connectedAs = false;
  loader = false;

  constructor(
    public router: Router,
    private injector: Injector,
    public sanitizer: DomSanitizer,
    public authService: AuthService,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private messagingService: MessagingService,
    public notificationService: NotificationService,
    private angularFireMessaging: AngularFireMessaging,
    private interventionsService: InterventionsRequestService,
    private notificationRequestService: NotificationsRequestService,
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loader = this.requestCount > 0;
    });

    this.hideSidebar = false;

    this.connectedAs = JSON.parse(localStorage.getItem('connectedAs'));
    if (this.connectedAs) {
      this.enterprise = JSON.parse(localStorage.getItem('connectedAsEnterprise'));
      this.user = JSON.parse(localStorage.getItem('connectedAsUser'));
    } else {
      this.enterprise = JSON.parse(localStorage.getItem('enterprise'));
      this.user = JSON.parse(localStorage.getItem('user'));
    }

    const observableEnterprise = this.authService.getEnterprise();
    observableEnterprise.subscribe(value => {
      this.enterprise = value;
      this.getNotifications();
    });

    const observableUser = this.authService.getUser();
    observableUser.subscribe(value => {
      this.user = value;
      this.connectedAs = JSON.parse(localStorage.getItem('connectedAs'));
      this.getNotifications();
    });

    this.requestPermission();
    this.receiveMessage();
  }
  receiveMessage() {
    const message = this.messagingService.currentMessage;
    message.subscribe(notification => {
      this.getNotifications();
    });
  }

  requestPermission() {
    if (firebase.messaging.isSupported()) {
      this.angularFireMessaging.requestToken.subscribe(
        (token) => {
          if (token) {
            this.sendTokenToServer(token);
          } else {
            localStorage.removeItem('notificationToken');
            localStorage.removeItem('notificationUserId');
            localStorage.removeItem('notificationEnterpriseId');
          }
        },
        (err) => {
          CustomNotifierService.getErrorNotification(this.notifier);
        }
      );
    } else {
      CustomNotifierService.notSupportNotification(this.notifier);
    }
  }

  sendTokenToServer(currentToken) {
    this.notificationToken = localStorage.getItem('notificationToken');
    this.notificationUser = parseInt(localStorage.getItem('notificationUserId'), 10);
    this.notificationEnterprise = parseInt(localStorage.getItem('notificationEnterpriseId'), 10);
    let token;
    if (
      !this.notificationToken ||
      (this.notificationToken !== currentToken &&
        this.notificationUser === this.user.id &&
        this.notificationEnterprise === this.enterprise.id)
    ) {
      this.requestInProgress.next(true);
      token = {
        token: currentToken,
        user: this.user.id,
        enterprise: this.enterprise.id
      };
      this.notificationRequestService.sendNotificationToken(token).subscribe( response => {
        if (response['success']) {
          localStorage.setItem('notificationToken', currentToken);
          localStorage.setItem('notificationUserId', this.user.id);
          localStorage.setItem('notificationEnterpriseId', this.enterprise.id);
          CustomNotifierService.getSuccessNotification(this.notifier);
        } else {
          CustomNotifierService.getErrorNotification(this.notifier);
        }
        this.requestInProgress.next(false);
      });
    }
  }

  getNotifications() {
    this.requestInProgress.next(true);
    this.notificationRequestService.getMyNotReadNotifications(this.search, this.sort, this.order, this.limit, this.page)
      .subscribe(response => {
        if (response['success']) {
          this.notificationService.notReadNotification = response['notifications'];
        } else if (response['errors']) {
          CustomNotifierService.getErrors(response['errors'], this.notifier);
        }
        this.requestInProgress.next(false);
      });
  }

  sanitizerHtml(htmlString) {
    return this.sanitizer.bypassSecurityTrustHtml(htmlString);
  }

  markAsRead(notification_id) {
    this.requestInProgress.next(true);
    this.notificationRequestService.checkAsRead(notification_id).subscribe( response => {
      if (response['success']) {
        this.notificationService.notReadNotification = response['notifications'];
        const find = this.notificationService.allNotification.find(notification => {
          return notification.id === notification_id;
        });
        if (find !== undefined) {
          find.if_read = true;
        }
      }
      this.requestInProgress.next(false);
    });
  }

  downloadInterventionPdf(id) {
    const fileName = 'bon_intervention.pdf';
    let request;
    request = this.interventionsService.downloadPdf(id);
    CustomNotifierService.downloadInProgress(this.notifier);
    request.subscribe((response: BufferSource) => {
      try {
        const decoder = new TextDecoder('utf-8');
        const domString = decoder.decode(response);
        const json = JSON.parse(domString);
        CustomNotifierService.getErrors(json['errors'], this.notifier);
      } catch (e) {
        const blob = new Blob([response]);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();

        this.loader = false;
      }
    });
  }
}
