import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UpdateSanitaryAnalyzeFrameModule } from './update-sanitary-analyze-frame/update-sanitary-analyze-frame.module';
import { AllSanitaryAnalyzeFramesModule } from './all-sanitary-analyze-frames/all-sanitary-analyze-frames.module';

import { SanitaryAnalyzeFramesRequestService } from '../../../services/sanitary-analyze-frames.request.service';

@NgModule({
  imports: [
    CommonModule,
    AllSanitaryAnalyzeFramesModule,
    UpdateSanitaryAnalyzeFrameModule,
  ],
  providers: [
      SanitaryAnalyzeFramesRequestService
  ]
})
export class SanitaryAnalyzeFramesModule {}
