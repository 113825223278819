import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Subject } from 'rxjs';
import { NgbTimeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { NgbTimeStringAdapter } from '../../../core/utils/ngb-time-string-adapter';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { UsersRequestService } from '../../../services/users.request.service';

import { AllSanitaryInstallationsComponent } from '../all-sanitary-installations/all-sanitary-installations.component';

import BatiFile from '../../../../shared/interfaces/bati_file';
import { SanitaryInstallationsRequestService } from '../../../services/sanitary-installations.request.service';

declare function Flatpickr(): any;
declare function Dropzones(): any;
declare const $: any;

@Component({
  selector: 'app-update-sanitary-installation',
  templateUrl: './update-sanitary-installation.component.html',
  styleUrls: ['./update-sanitary-installation.component.scss'],
  providers: [{ provide: NgbTimeAdapter, useClass: NgbTimeStringAdapter }]
})
export class UpdateSanitaryInstallationComponent implements OnInit, AfterViewInit, OnDestroy {
  counter = Array;
  canCreate = false;
  canUpdate = false;

  activeTab = 1;

  requestInProgress = new Subject();
  requestCount = 0;

  sanitaryInstallationForm: FormGroup;
  // PART 1
  description = new FormControl('', Validators.required);
  public_distribution = new FormControl(false);
  // PART 3
  witness_headlines = new FormControl(false);
  heating_cord = new FormControl(false);
  // PART 4
  ecs_temperature = new FormControl('');
  usage_point_mixer = new FormControl(false);

  files: BatiFile[] = [];
  filesRequired: BatiFile[] = [];
  enterprises: any = [];
  user: string;

  errors = {};

  isNew = true;
  loader = false;
  loaderContent = false;

  modalSanitaryInstallation = null;

  constructor(
      private router: Router,
      private formBuilder: FormBuilder,
      private notifier: NotifierService,
      private activatedRoute: ActivatedRoute,
      private allSanitaryInstallations: AllSanitaryInstallationsComponent,
      private usersService: UsersRequestService,
      private sanitaryInstallationServices: SanitaryInstallationsRequestService,
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loaderContent = this.requestCount > 0;
    });

    this.sanitaryInstallationForm = this.formBuilder.group({
      // PART 1
      description: this.description,
      public_distribution: this.public_distribution,
      private_resources: this.formBuilder.group({
        well: new FormControl(false),
        drilling: new FormControl(false),
      }),
      diagnostic: this.formBuilder.group({
        network_diagnostic: new FormControl(false),
        realisation_date: new FormControl(''),
        actualisation_date: new FormControl(''),
        providers: new FormControl(''),
        annexed_plan: new FormControl(false),
        no_archiving_plan: new FormControl(''),
      }),
      // PART 2
      constituent_materials_cold_water: this.formBuilder.group({
        copper: new FormControl(false),
        galvanized_steel: new FormControl(false),
        pvc: new FormControl(false),
        other: new FormControl(false),
        other_detail: new FormControl(''),
      }),
      interconnection_cold_water: this.formBuilder.group({
        network: new FormControl(false),
        localization: new FormControl(''),
      }),
      water_devices_cold_water: this.formBuilder.group({
        steam_cleaners: new FormControl(false),
        refrigerated_fountains: new FormControl(false),
        decorative_fountains: new FormControl(false),
        beverage_dispenser: new FormControl(false),
        mist: new FormControl(false),
        air_humidifier: new FormControl(false),
        oxygen_therapy_devices: new FormControl(false),
        other: new FormControl(false),
        other_detail: new FormControl(''),
      }),
      pipe_insulation_cold_water: this.formBuilder.group({
        integral: new FormControl(false),
        partial: new FormControl(false),
        nonexistent: new FormControl(false),
      }),
      protection_devices: this.formBuilder.group({
        general_cold_water_supply: new FormControl(false),
        network_interconnection: new FormControl(false),
        building: new FormControl(false),
        device_supply_cold_water: new FormControl(false),
        processing_device: new FormControl(false),
      }),
      level_measurements: this.formBuilder.group({
        temperature_measure: new FormControl(false),
        network: new FormControl(false),
        network_frequency: new FormControl(''),
        usage_points: new FormControl(false),
        usage_points_frequency: new FormControl(''),
      }),
      analyze_cold_water: this.formBuilder.group({
        analyze: new FormControl(false),
        frequency: new FormControl(''),
        nature: new FormControl(''),
      }),
      // PART 3
      constituent_materials_hot_water: this.formBuilder.group({
        copper: new FormControl(false),
        galvanized_steel: new FormControl(false),
        pvc: new FormControl(false),
        other: new FormControl(false),
        other_detail: new FormControl(''),
      }),
      interconnection_hot_water: this.formBuilder.group({
        network: new FormControl(false),
        localization: new FormControl(''),
      }),
      water_devices_hot_water: this.formBuilder.group({
        dishwasher: new FormControl(),
        washing_line: new FormControl(),
        spa_bath: new FormControl(),
        spa: new FormControl(),
        other: new FormControl(false),
        other_detail: new FormControl(''),
      }),
      pipe_insulation_hot_water: this.formBuilder.group({
        integral: new FormControl(false),
        partial: new FormControl(false),
        nonexistent: new FormControl(false),
      }),
      witness_headlines: this.witness_headlines,
      heating_cord: this.heating_cord,
      network_type: this.formBuilder.group({
        linear: new FormControl(false),
        partially_closed: new FormControl(false),
        loop_number: new FormControl(''),
        fully_closed: new FormControl(false),
      }),
      // PART 4
      production_system: this.formBuilder.group({
        by_accumulation: new FormControl(false),
        by_accumulation_number: new FormControl(''),
        semi_instant_less: new FormControl(false),
        semi_instant_less_number: new FormControl(''),
        semi_instant_more: new FormControl(false),
        semi_instant_more_number: new FormControl(''),
        instant: new FormControl(false),
        instant_number: new FormControl(''),
      }),
      ecs_temperature: this.ecs_temperature,
      balloon: this.formBuilder.group({
        serial: new FormControl(false),
        in_parallel: new FormControl(false),
        drain_valve: new FormControl(false),
        access: new FormControl(false),
        trap_door: new FormControl(false),
        air_purge: new FormControl(false),
      }),
      temperature_measurement: this.formBuilder.group({
        production_outlet: new FormControl(false),
        production_outlet_frequency: new FormControl(''),
        loop_return: new FormControl(false),
        loop_return_frequency: new FormControl(''),
        usage_point: new FormControl(false),
        usage_point_frequency: new FormControl(''),
      }),
      analyze_hot_water: this.formBuilder.group({
        legionella_research: new FormControl(false),
        legionella_research_place: new FormControl(''),
        legionella_research_frequency: new FormControl(''),
        other_analyze: new FormControl(false),
        other_analyze_detail: new FormControl(''),
        other_analyze_place: new FormControl(''),
        other_analyze_frequency: new FormControl(''),
      }),
      installed_treatment_type: this.formBuilder.group({
        sweetly: new FormControl(false),
        sweetly_name: new FormControl(''),
        anti_corrosion: new FormControl(false),
        anti_corrosion_name: new FormControl(''),
        disinfection: new FormControl(false),
        continue: new FormControl(false),
        discontinue: new FormControl(false),
      }),
      mixer: this.formBuilder.group({
        downstream_mixer: new FormControl(false),
        temperature: new FormControl(''),
      }),
      usage_point_mixer: this.usage_point_mixer,
      device: this.formBuilder.group({
        regular_device: new FormControl(false),
        frequency: new FormControl(''),
      }),
      shower_number: this.formBuilder.group({
        individual_shower: new FormControl(false),
        individual_shower_number: new FormControl(''),
        collective_shower: new FormControl(false),
        collective_shower_number: new FormControl(''),
      }),
    });

    if (this.activatedRoute.snapshot.params.sanitary_installation_id) {
      this.isNew = false;
      this.getSanitaryInstallation();
    } else {
      setTimeout(() => {
        Flatpickr();
      }, 1);
    }

    $('i.tooltipHtml').hover(function () {
      const tooltip = $(document).find('div.tooltip.show');
      tooltip.css('top: 5px');
    });
  }

  ngAfterViewInit() {
    Dropzones();
    this.modalSanitaryInstallation.open();
  }

  ngOnDestroy() {
    const flatPicker = document.getElementsByClassName('flatpickr-calendar') as HTMLCollection;
    for (let i = flatPicker.length; i >= 0; i--) {
      if (flatPicker[i]) {
        flatPicker[i].remove();
      }
    }

    const dropZones = document.getElementsByClassName('dz-hidden-input') as HTMLCollection;
    for (let i = 0; i < dropZones.length; i++) {
      dropZones[i].remove();
    }

    this.clearTooltip();

    this.modalSanitaryInstallation.close();
  }
  clearTooltip() {
    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }
  changeTabs(activeTab) {
    this.activeTab = activeTab;
  }

  getSanitaryInstallation() {
    this.requestInProgress.next(true);
    this.sanitaryInstallationServices.getSanitaryInstallation(
        this.activatedRoute.snapshot.parent.params.registry_id,
        this.activatedRoute.snapshot.parent.params.establishment_id,
        this.activatedRoute.snapshot.params.sanitary_installation_id
    ).subscribe(response => {
      if (response['success']) {
        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];

        this.sanitaryInstallationForm.patchValue(response['sanitaryInstallation']);

        if (!this.canUpdate) {
          this.sanitaryInstallationForm.disable();
        }
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      Flatpickr();
      this.requestInProgress.next(false);
    });
  }
  createSanitaryInstallation() {
    let request;
    this.errors = {};
    this.loader = true;

    const data = {
      ...this.sanitaryInstallationForm.value,
    };

    request = this.sanitaryInstallationServices.createSanitaryInstallation(
        this.activatedRoute.snapshot.parent.params.registry_id,
        this.activatedRoute.snapshot.parent.params.establishment_id,
        data
    );

    request.subscribe(response => {
      if (response['success']) {
        this.isNew = false;
        if (this.files.length || this.filesRequired.length) {
          this.createDocuments(response['sanitaryInstallation']['id']);
        } else {
          this.loader = false;
          this.modalSanitaryInstallation.close();
          CustomNotifierService.getSuccessCreate(this.notifier);
        }
      } else if (response['errors']) {
        this.loader = false;
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(response['errors'], this.sanitaryInstallationForm, this.notifier);
      } else {
        this.loader = false;
      }
    });
  }
  updateSanitaryInstallation() {
    let request;

    this.errors = {};
    this.loader = true;

    const data = {
      ...this.sanitaryInstallationForm.value,
    };

    request = this.sanitaryInstallationServices.updateSanitaryInstallation(
        this.activatedRoute.snapshot.parent.params.registry_id,
        this.activatedRoute.snapshot.parent.params.establishment_id,
        this.activatedRoute.snapshot.params.sanitary_installation_id,
        data
    );

    request.subscribe(response => {
      if (response['success']) {
        if (this.files.length || this.filesRequired.length) {
          this.createDocuments(this.activatedRoute.snapshot.params.sanitary_installation_id);
        } else {
          this.loader = false;
          this.modalSanitaryInstallation.close();
          CustomNotifierService.getSuccessUpdate(this.notifier);
        }
      } else if (response['errors']) {
        this.loader = false;
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      } else {
        this.loader = false;
      }
    });
  }
  createDocuments(sanitary_installation_id) {
    let request;
    this.errors = {};

    request = this.sanitaryInstallationServices.createFile(
        this.activatedRoute.snapshot.parent.params.registry_id,
        this.activatedRoute.snapshot.parent.params.establishment_id,
        sanitary_installation_id,
        [...this.files, ...this.filesRequired]
    );

    request.subscribe(response => {
      if (response['success']) {
        this.modalSanitaryInstallation.close();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loader = false;
    });
  }

  chooseFile() {
    const element: HTMLElement = document.getElementById('inputFile') as HTMLElement;
    element.click();
  }
  onFileChange(event) {
    if (event.target && event.target.files && event.target.files.length) {
      for (let i = 0; i < event.target.files.length; i++) {
        const reader = new FileReader();
        const file = event.target.files[i];
        reader.readAsDataURL(file);

        reader.onload = () => {
          let extension = '';
          let name = '';

          const split = file.name.split('.');
          if (split.length) {
            name = split[0];
            extension = split[split.length - 1];
          }

          this.files.push({ file_base64: reader.result, name: name, extension: extension });
        };
      }
    } else {
      let extension = '';
      let name = '';

      const split = event.name.split('.');
      if (split.length) {
        name = split[0];
        extension = split[split.length - 1];
      }

      this.files.push({ file_base64: event.file, name: name, extension: extension });
    }
    const element: HTMLInputElement = document.getElementById('inputFile') as HTMLInputElement;
    element.value = '';
  }
  removeFile(index) {
    this.files.splice(index, 1);

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  bindModal(modal) {
    this.modalSanitaryInstallation = modal;
  }
  closeModal() {
    this.router.navigate([this.activatedRoute.snapshot.parent.url.join('/')]).then(() => {
      this.allSanitaryInstallations.refreshData();
    });
  }
}
