<app-modal-right (eventOnClose)="closeModal()" #training
  >{{ bindModal(training) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">
          {{ isNew ? "Création" : canUpdate ? "Mise à jour" : "Consultation" }}
        </h6>

        <h1 class="header-title">Formation</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <form [formGroup]="trainingForm" [hidden]="loaderContent">
      <div class="row">
        <div class="col-12">
          <div class="custom-control custom-switch" style="margin-bottom: 20px">
            <input
              type="checkbox"
              class="custom-control-input"
              formControlName="priority"
              id="customSwitches"
            />
            <label class="custom-control-label" for="customSwitches"
              >Obligatoire</label
            >
          </div>
          <div class="form-group">
            <label for="validationNom" required>Nom</label>
            <input
              type="text"
              formControlName="name"
              class="form-control"
              id="validationNom"
              placeholder="Nom"
              [ngClass]="{
                'is-invalid': name.invalid && (name.dirty || name.touched)
              }"
            />
            <div
              *ngIf="name.invalid && (name.dirty || name.touched)"
              class="invalid-feedback"
            >
              {{ getErrorMessage("name") }}
            </div>
          </div>

          <div class="form-group">
            <label for="validationFrequencyDuration" required
              >Périodicité</label
            >
            <div class="row">
              <input
                type="number"
                formControlName="frequency_duration"
                class="form-control col"
                id="validationFrequencyDuration"
                [ngClass]="{
                  'is-invalid':
                    frequency_duration.invalid &&
                    (frequency_duration.dirty || frequency_duration.touched)
                }"
              />
              <select
                class="form-control col"
                data-toggle="select"
                formControlName="frequency"
                id="validationFrequency"
              >
                <option value="null" [disabled]="true" class="text-muted">
                  Sélectionner une durée
                </option>
                <option value="month">Mois</option>
                <option value="year">Année(s)</option>
              </select>
              <div
                *ngIf="
                  frequency_duration.invalid &&
                  (frequency_duration.dirty || frequency_duration.touched)
                "
                class="invalid-feedback"
              >
                {{ getErrorMessage("frequency_duration") }}
              </div>
              <div
                *ngIf="
                  frequency.invalid && (frequency.dirty || frequency.touched)
                "
                class="invalid-feedback"
              >
                {{ getErrorMessage("frequency") }}
              </div>
            </div>
          </div>

          <div class="form-group">
            <label for="validationRegistrie" required>Régistre</label>
            <select
              class="form-control"
              data-toggle="select"
              formControlName="registry_id"
              id="validationRegistrie"
              (change)="getEstablishment()"
            >
              <option [ngValue]="null" [disabled]="true" class="text-muted">
                Sélectionner un registre
              </option>
              <ng-container *ngFor="let registrie of registries">
                <option [value]="registrie.id">{{ registrie.name }}</option>
              </ng-container>
            </select>
          </div>

          <div class="custom-control custom-switch" style="margin-bottom: 20px">
            <input
              type="checkbox"
              class="custom-control-input"
              formControlName="hasEstablishment"
              id="customHasEstablishment"
            />
            <label class="custom-control-label" for="customHasEstablishment"
              >Particulière à un établissement</label
            >
          </div>

          <div class="form-group" *ngIf="hasEstablishment.value">
            <label for="validationEstablishment">Établissement</label>
            <select
              class="form-control"
              data-toggle="select"
              formControlName="establishment_id"
              id="validationEstablishment"
            >
              <option [ngValue]="null" [disabled]="true" class="text-muted">
                {{
                  disableEstab
                    ? "Veuillez d'abord selectionner un régistre"
                    : "Sélectionner un établissement"
                }}
              </option>
              <ng-container *ngFor="let establishment of establishments">
                <option [value]="establishment.id">
                  {{ establishment.name }}
                </option>
              </ng-container>
            </select>
          </div>
          <div class="form-group">
            <label for="validationFormationDispensee"
              >Formation dispensée</label
            >
            <select
              class="form-control"
              data-toggle="select"
              formControlName="formation_dispensee"
              id="validationFormationDispensee"
            >
              <option value="none" active>Non précisé</option>
              <option value="in">Interne</option>
              <option value="out">Externe</option>
            </select>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div modal-footer class="w-100" *ngIf="isNew || canUpdate">
    <button
      class="btn btn-primary w-100"
      (click)="isNew ? createTraining() : updateTraining()"
      [ngClass]="{ 'is-loading is-loading-sm': loader }"
      [disabled]="loader || trainingForm.invalid"
    >
      {{ isNew ? "Créer" : "Mettre à jour" }}
    </button>
  </div>
</app-modal-right>
