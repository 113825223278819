<div class="main-content">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col">
        <div class="header mb-0">
          <div class="header-body no-border">
            <div class="row align-items-center">
              <div class="col">
                <h1 class="header-title">Dossier technique amiante</h1>

                <small class="form-text text-muted">
                  <span>Tableau de bord du dossier technique amiante</span>
                </small>

                <app-breadcrumbs [currentPage]="pageName" [names]="previousPageName" [routes]="previousPageRoute"></app-breadcrumbs>
              </div>

            </div>
          </div>
        </div>

        <div class="is-loading loader-content" *ngIf="loaderContent"></div>

        <div class="tab-content" *ngIf="!loaderContent">
          <div class="tab-pane fade active show" id="tabPaneOne" role="tabpanel">
            <div class="row listAlias">
              <!-- DETECTION -->
              <div class="col-12 d-flex flex-column">
                <div class="card flex-fill">
                  <div class="card-body">
                    <div class="row mb-4">
                      <div class="col-md-6">
                        <h2>Repérages</h2>
                      </div>
                      <div class="col-md-6">
                        <app-searchbar [(ngModel)]="searchAsbestosDetections" (keyup)="searchAsbestosDetectionsData()" [searchbar_id]="'detection'" [searchbar_name]="'detection'" ngDefaultControl></app-searchbar>
                      </div>
                    </div>

                    <div class="table-responsive">
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th class="href" (click)="filterAsbestosDetections('AsbestosDetections.referency')">
                              <a class="text-muted">Référence rapport de repérage</a>
                              <span class="fe fe-arrow-down" *ngIf="sortByAsbestosDetections === 'AsbestosDetections.referency' && sortDirectionAsbestosDetections === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sortByAsbestosDetections === 'AsbestosDetections.referency' && sortDirectionAsbestosDetections === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sortByAsbestosDetections !== 'AsbestosDetections.referency'"></span>
                            </th>
                            <th class="href" (click)="filterAsbestosDetections('AsbestosDetections.date')">
                              <a class="text-muted">Date</a>
                              <span class="fe fe-arrow-down" *ngIf="sortByAsbestosDetections === 'AsbestosDetections.date' && sortDirectionAsbestosDetections === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sortByAsbestosDetections === 'AsbestosDetections.date' && sortDirectionAsbestosDetections === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sortByAsbestosDetections !== 'AsbestosDetections.date'"></span>
                            </th>
                            <th class="href" (click)="filterAsbestosDetections('AsbestosDetections.organization')">
                              <a class="text-muted">Organisme</a>
                              <span class="fe fe-arrow-down" *ngIf="sortByAsbestosDetections === 'AsbestosDetections.organization' && sortDirectionAsbestosDetections === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sortByAsbestosDetections === 'AsbestosDetections.organization' && sortDirectionAsbestosDetections === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sortByAsbestosDetections !== 'AsbestosDetections.organization'"></span>
                            </th>
                            <th class="href" (click)="filterAsbestosDetections('AsbestosDetections.object')">
                              <a class="text-muted">Objet</a>
                              <span class="fe fe-arrow-down" *ngIf="sortByAsbestosDetections === 'AsbestosDetections.object' && sortDirectionAsbestosDetections === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sortByAsbestosDetections === 'AsbestosDetections.object' && sortDirectionAsbestosDetections === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sortByAsbestosDetections !== 'AsbestosDetections.object'"></span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngIf="loaderAsbestosDetections">
                            <td colspan="6">
                              <div class="is-loading loader-content"></div>
                            </td>
                          </tr>
                          <tr *ngIf="!loaderAsbestosDetections && !asbestosDetections.length">
                            <td colspan="6">
                              <div class="loader-content">
                                Aucun résultat
                              </div>
                            </td>
                          </tr>
                          <tr *ngFor="let asbestos of asbestosDetections">
                            <td>{{ asbestos.referency }}</td>
                            <td>{{ asbestos.date | date : 'dd/MM/yyyy' }}</td>
                            <td>{{ asbestos.organization }}</td>
                            <td>{{ asbestos.object }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <app-pagination (returnEvent)="dispatchPaginationAsbestosDetections($event)"
                                    [count_elements]="countAsbestosDetections"
                                    [current_page]="pageAsbestosDetections"
                                    [elements_length]="asbestosDetections.length"
                                    [limit]="limitAsbestosDetections"
                                    [total_pages]="pagesAsbestosDetections"
                                    ngDefaultControl>
                    </app-pagination>
                  </div>
                </div>
              </div>

              <!-- DETECTION BUILDINGS IDENTIFIED -->
              <div class="col-12 d-flex flex-column">
                <div class="card flex-fill">
                  <div class="card-body">
                    <div class="row mb-4">
                      <div class="col-md-6">
                        <h2>Liste des parties de l'immeuble repérées</h2>
                      </div>
                      <div class="col-md-6">
                        <app-searchbar [(ngModel)]="searchAsbestosDetections" (keyup)="searchAsbestosDetectionsData()" [searchbar_id]="'detection_building_identified'" [searchbar_name]="'detection_building_identified'" ngDefaultControl></app-searchbar>
                      </div>
                    </div>

                    <div class="table-responsive">
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th>Type</th>
                            <th class="href" (click)="filterAsbestosDetections('AsbestosDetections.referency')">
                              <a class="text-muted">Référence rapport de repérage</a>
                              <span class="fe fe-arrow-down" *ngIf="sortByAsbestosDetections === 'AsbestosDetections.referency' && sortDirectionAsbestosDetections === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sortByAsbestosDetections === 'AsbestosDetections.referency' && sortDirectionAsbestosDetections === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sortByAsbestosDetections !== 'AsbestosDetections.referency'"></span>
                            </th>
                            <th>Liste des parties des immeubles repérées</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngIf="loaderAsbestosDetections">
                            <td colspan="3">
                              <div class="is-loading loader-content"></div>
                            </td>
                          </tr>
                          <tr *ngIf="!loaderAsbestosDetections && !asbestosDetections.length">
                            <td colspan="3">
                              <div class="loader-content">
                                Aucun résultat
                              </div>
                            </td>
                          </tr>
                          <tr *ngFor="let asbestos of asbestosDetections">
                            <td>{{ asbestos.type }}</td>
                            <td>{{ asbestos.referency }}</td>
                            <td style="white-space: pre;">{{ asbestosBuildingsIdentified(asbestos.buildings_identified) }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <app-pagination (returnEvent)="dispatchPaginationAsbestosDetections($event)"
                                    [count_elements]="countAsbestosDetections"
                                    [current_page]="pageAsbestosDetections"
                                    [elements_length]="asbestosDetections.length"
                                    [limit]="limitAsbestosDetections"
                                    [total_pages]="pagesAsbestosDetections"
                                    ngDefaultControl>
                    </app-pagination>
                  </div>
                </div>
              </div>

              <!-- DETECTION LOCALIZATIONS -->
              <div class="col-12 d-flex flex-column">
                <div class="card flex-fill">
                  <div class="card-body">
                    <div class="row mb-4">
                      <div class="col-md-6">
                        <h2>Matériaux et produits contenant de l’amiante</h2>
                      </div>
                      <div class="col-md-6">
                        <app-searchbar [(ngModel)]="searchAsbestosDetectionLocalizations" (keyup)="searchAsbestosDetectionLocalizationsData()" [searchbar_id]="'detection_localizations'" [searchbar_name]="'detection_localizations'" ngDefaultControl></app-searchbar>
                      </div>
                    </div>

                    <div class="table-responsive">
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th>Type de repérage</th>
                            <th>Date</th>
                            <th class="href" (click)="filterAsbestosDetectionLocalizations('AsbestosDetectionLocalizations.localization')">
                              <a class="text-muted">Localisation</a>
                              <span class="fe fe-arrow-down" *ngIf="sortByAsbestosDetectionLocalizations === 'AsbestosDetectionLocalizations.localization' && sortDirectionAsbestosDetectionLocalizations === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sortByAsbestosDetectionLocalizations === 'AsbestosDetectionLocalizations.localization' && sortDirectionAsbestosDetectionLocalizations === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sortByAsbestosDetectionLocalizations !== 'AsbestosDetectionLocalizations.localization'"></span>
                            </th>
                            <th class="href" (click)="filterAsbestosDetectionLocalizations('AsbestosDetectionLocalizations.accuracy_localization')">
                              <a class="text-muted">Détail</a>
                              <span class="fe fe-arrow-down" *ngIf="sortByAsbestosDetectionLocalizations === 'AsbestosDetectionLocalizations.accuracy_localization' && sortDirectionAsbestosDetectionLocalizations === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sortByAsbestosDetectionLocalizations === 'AsbestosDetectionLocalizations.accuracy_localization' && sortDirectionAsbestosDetectionLocalizations === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sortByAsbestosDetectionLocalizations !== 'AsbestosDetectionLocalizations.accuracy_localization'"></span>
                            </th>
                            <th class="href" (click)="filterAsbestosDetectionLocalizations('AsbestosDetectionLocalizations.identifier')">
                              <a class="text-muted">Identifiant</a>
                              <span class="fe fe-arrow-down" *ngIf="sortByAsbestosDetectionLocalizations === 'AsbestosDetectionLocalizations.identifier' && sortDirectionAsbestosDetectionLocalizations === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sortByAsbestosDetectionLocalizations === 'AsbestosDetectionLocalizations.identifier' && sortDirectionAsbestosDetectionLocalizations === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sortByAsbestosDetectionLocalizations !== 'AsbestosDetectionLocalizations.identifier'"></span>
                            </th>
                            <th class="href" (click)="filterAsbestosDetectionLocalizations('AsbestosDetectionLocalizations.description')">
                              <a class="text-muted">Description</a>
                              <span class="fe fe-arrow-down" *ngIf="sortByAsbestosDetectionLocalizations === 'AsbestosDetectionLocalizations.description' && sortDirectionAsbestosDetectionLocalizations === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sortByAsbestosDetectionLocalizations === 'AsbestosDetectionLocalizations.description' && sortDirectionAsbestosDetectionLocalizations === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sortByAsbestosDetectionLocalizations !== 'AsbestosDetectionLocalizations.description'"></span>
                            </th>
                            <th class="href" (click)="filterAsbestosDetectionLocalizations('AsbestosDetectionLocalizations.conservation_state')">
                              <a class="text-muted">État de conservation</a>
                              <span class="fe fe-arrow-down" *ngIf="sortByAsbestosDetectionLocalizations === 'AsbestosDetectionLocalizations.conservation_state' && sortDirectionAsbestosDetectionLocalizations === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sortByAsbestosDetectionLocalizations === 'AsbestosDetectionLocalizations.conservation_state' && sortDirectionAsbestosDetectionLocalizations === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sortByAsbestosDetectionLocalizations !== 'AsbestosDetectionLocalizations.conservation_state'"></span>
                            </th>
                            <th class="href" (click)="filterAsbestosDetectionLocalizations('AsbestosDetectionLocalizations.recommended_measures')">
                              <a class="text-muted">Mesures préconisées</a>
                              <span class="fe fe-arrow-down" *ngIf="sortByAsbestosDetectionLocalizations === 'AsbestosDetectionLocalizations.recommended_measures' && sortDirectionAsbestosDetectionLocalizations === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sortByAsbestosDetectionLocalizations === 'AsbestosDetectionLocalizations.recommended_measures' && sortDirectionAsbestosDetectionLocalizations === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sortByAsbestosDetectionLocalizations !== 'AsbestosDetectionLocalizations.recommended_measures'"></span>
                            </th>
                            <th class="href" (click)="filterAsbestosDetectionLocalizations('AsbestosDetectionLocalizations.result')">
                              <a class="text-muted">Résultat</a>
                              <span class="fe fe-arrow-down" *ngIf="sortByAsbestosDetectionLocalizations === 'AsbestosDetectionLocalizations.result' && sortDirectionAsbestosDetectionLocalizations === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sortByAsbestosDetectionLocalizations === 'AsbestosDetectionLocalizations.result' && sortDirectionAsbestosDetectionLocalizations === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sortByAsbestosDetectionLocalizations !== 'AsbestosDetectionLocalizations.result'"></span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngIf="loaderAsbestosDetectionLocalizations">
                            <td colspan="9">
                              <div class="is-loading loader-content"></div>
                            </td>
                          </tr>
                          <tr *ngIf="!loaderAsbestosDetectionLocalizations && !asbestosDetectionLocalizations.length">
                            <td colspan="9">
                              <div class="loader-content">
                                Aucun résultat
                              </div>
                            </td>
                          </tr>
                          <tr *ngFor="let asbestos of asbestosDetectionLocalizations">
                            <td>{{ asbestos.asbestos_detection.type }}</td>
                            <td>{{ asbestos.asbestos_detection.date | date : 'dd/MM/yyyy' }}
                            <td>{{ asbestos.localization }}</td>
                            <td>{{ asbestos.accuracy_localization }}</td>
                            <td>{{ asbestos.identifier }}</td>
                            <td>{{ asbestos.description }}</td>
                            <td>{{ asbestos.conservation_state }}</td>
                            <td>{{ asbestos.recommended_measures }}</td>
                            <td>{{ asbestos.result }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <app-pagination (returnEvent)="dispatchPaginationAsbestosDetectionLocalizations($event)"
                                    [count_elements]="countAsbestosDetectionlocalizations"
                                    [current_page]="pageAsbestosDetectionLocalizations"
                                    [elements_length]="asbestosDetectionLocalizations.length"
                                    [limit]="limitAsbestosDetectionlocalizations"
                                    [total_pages]="pagesAsbestosDetectionLocalizations"
                                    ngDefaultControl>
                    </app-pagination>
                  </div>
                </div>
              </div>

              <!-- WORKS -->
              <div class="col-12 d-flex flex-column">
                <div class="card flex-fill">
                  <div class="card-body">
                    <div class="row mb-4">
                      <div class="col-md-6">
                        <h2>Travaux</h2>
                      </div>
                      <div class="col-md-6">
                        <app-searchbar [(ngModel)]="searchAsbestosWorks" (keyup)="searchAsbestosWorksData()" [searchbar_id]="'works'" [searchbar_name]="'works'" ngDefaultControl></app-searchbar>
                      </div>
                    </div>

                    <div class="table-responsive">
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th class="href" (click)="filterAsbestosWorks('AsbestosWorks.type')">
                              <a class="text-muted">Type</a>
                              <span class="fe fe-arrow-down" *ngIf="sortByAsbestosWorks === 'AsbestosWorks.type' && sortDirectionAsbestosWorks === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sortByAsbestosWorks === 'AsbestosWorks.type' && sortDirectionAsbestosWorks === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sortByAsbestosWorks !== 'AsbestosWorks.type'"></span>
                            </th>
                            <th>Matériaux et produits contenant de l’amiante</th>
                            <th class="href" (click)="filterAsbestosWorks('AsbestosWorks.date')">
                              <a class="text-muted">Date de réception / réalisation</a>
                              <span class="fe fe-arrow-down" *ngIf="sortByAsbestosWorks === 'AsbestosWorks.date' && sortDirectionAsbestosWorks === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sortByAsbestosWorks === 'AsbestosWorks.date' && sortDirectionAsbestosWorks === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sortByAsbestosWorks !== 'AsbestosWorks.date'"></span>
                            </th>
                            <th>Maître d'oeuvre</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngIf="loaderAsbestosWorks">
                            <td colspan="4">
                              <div class="is-loading loader-content"></div>
                            </td>
                          </tr>
                          <tr *ngIf="!loaderAsbestosWorks && !asbestosWorks.length">
                            <td colspan="4">
                              <div class="loader-content">
                                Aucun résultat
                              </div>
                            </td>
                          </tr>
                          <tr *ngFor="let asbestos of asbestosWorks">
                            <td>{{ asbestos.type }}</td>
                            <td>
                              <div class="btn-group">
                                <a class="btn btn-sm btn-white" [routerLink]="['/dashboard-registry/' + this.registry.id + '/establishment/' + this.establishment.id + '/asbestos-works/' + asbestos.id  + '/localizations']">
                                  {{ asbestos.count_localizations + ' localisation(s)' }}
                                </a>
                              </div>
                            </td>
                            <td>{{ asbestos.date | date : 'dd/MM/yyyy' }}</td>
                            <td>{{ asbestos.project_manager }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <app-pagination (returnEvent)="dispatchPaginationAsbestosWorks($event)"
                                    [count_elements]="countAsbestosWorks"
                                    [current_page]="pageAsbestosWorks"
                                    [elements_length]="asbestosWorks.length"
                                    [limit]="limitAsbestosWorks"
                                    [total_pages]="pagesAsbestosWorks"
                                    ngDefaultControl>
                    </app-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<router-outlet></router-outlet>
