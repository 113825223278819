<div class="main-content">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col">
        <div class="header">
          <div class="header-body">
            <div class="row align-items-center">
              <div class="col">
                <h1 class="header-title">Réglages</h1>
              </div>
            </div>

            <div class="row align-items-center">
              <div class="col">
                <ul class="nav nav-tabs nav-overflow header-tabs">
                  <li class="nav-item">
                    <a class="nav-link href" [ngClass]="{ 'active': activeTab === 'account'}" (click)="changeTabs('account')">Mon profil</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link href" [ngClass]="{ 'active': activeTab === 'enterprise'}" (click)="changeTabs('enterprise')">Mon entreprise</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="is-loading loader-content" *ngIf="loader"></div>

        <!-- ACCOUNT -->
        <div class="tab-content" *ngIf="!loader" [hidden]="activeTab === 'enterprise'">
          <form class="mb-4" [formGroup]="userForm">
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="validationLastname">Nom de famille</label>
                  <input type="text" formControlName="lastname" class="form-control" id="validationLastname" placeholder="Nom de famille" [ngClass]="{ 'is-invalid': lastname.invalid && (lastname.dirty || lastname.touched) }" maxlength="255">
                  <div *ngIf="lastname.invalid && (lastname.dirty || lastname.touched)" class="invalid-feedback">
                    {{ getErrorMessage("lastname") }}
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="validationFirstname">Prénom</label>
                  <input type="text" formControlName="firstname" class="form-control" id="validationFirstname" placeholder="Prénom" [ngClass]="{ 'is-invalid': firstname.invalid && (firstname.dirty || firstname.touched) }" maxlength="255">
                  <div *ngIf="firstname.invalid && (firstname.dirty || firstname.touched)" class="invalid-feedback">
                    {{ getErrorMessage("firstname") }}
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="validationPhone">Téléphone</label>
                  <input type="tel" formControlName="phone" class="form-control" id="validationPhone" placeholder="Téléphone" [ngClass]="{ 'is-invalid': phone.invalid && (phone.dirty || phone.touched) }" maxlength="255">
                  <div *ngIf="phone.invalid && (phone.dirty || phone.touched)" class="invalid-feedback">
                    {{ getErrorMessage("phone") }}
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="validationAdresseEmail">Email</label>
                  <input type="email" formControlName="email" class="form-control" id="validationAdresseEmail" placeholder="nom@monentreprise.com" [ngClass]="{ 'is-invalid': email.invalid && (email.dirty || email.touched) }" maxlength="255">
                  <div *ngIf="email.invalid && (email.dirty || email.touched)" class="invalid-feedback">
                    {{ getErrorMessage("email") }}
                  </div>
                </div>
              </div>

              <div class="col-12 d-flex justify-content-end">
                <button class="btn btn-primary mb-3" (click)="updateUser()" [ngClass]="{ 'is-loading is-loading-sm': isUpdatingUser }" [disabled]="isUpdatingUser || userForm.invalid">
                  Mettre à Jour
                </button>
              </div>
            </div>
          </form>

          <hr class="mt-4 mb-5">

          <div class="mb-4">
            <div class="form-group" [hidden]="picture.value">
              <label for="inputFile" class="mb-1">Photo utilisateur</label>

              <small class="form-text text-muted">
                Veuillez utiliser une image ne dépassant pas 200px * 200px.
              </small>

              <div class="dropzone dropzone-multiple dz-clickable" appDragDrop (dropHandler)="onFileChange($event)">
                <div class="dz-default dz-message" (click)="chooseFile()">
                  <span>Déposer votre fichier ici pour télécharger</span>
                </div>
                <input id="inputFile" (change)="onFileChange($event)" type="file" accept="image/*" [hidden]="true">
              </div>
            </div>

            <div class="form-group" *ngIf="picture.value">
              <label class="mb-1">Photo utilisateur</label>

              <small class="form-text text-muted">
                Veuillez utiliser une image ne dépassant pas 200px * 200px.
              </small>

              <div class="card">
                <div class="card-body">
                  <div class="d-flex align-items-center justify-content-between">
                    <div class="avatar avatar-lg avatar-4by3">
                      <img src="{{ checkPicture() }}" alt="Photo utilisateur" class="avatar-img rounded">
                    </div>

                    <a class="href tooltipHtml" data-placement="bottom" title="Supprimer" (click)="removeFile()">
                      <i class="fe fe-trash"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-end">
              <button class="btn btn-primary mb-3" (click)="updatePicture()" [ngClass]="{ 'is-loading is-loading-sm': isUpdatingPicture }" [disabled]="isUpdatingPicture">
                Mettre à Jour
              </button>
            </div>
          </div>

          <hr class="mt-4 mb-5">

          <form class="mb-4" [formGroup]="passwordForm">
            <div class="row password-content">
              <div class="col-12 col-md-6 order-md-2">
                <div class="card bg-light border ml-md-4">
                  <div class="card-body">
                    <p class="mb-2">Exigences relatives au mot de passe</p>

                    <p class="small text-muted mb-2">
                      Pour créer un nouveau mot de passe, il doit contenir au minimum 8 caractères et vous devez satisfaire à au moins 3 des 4 des exigences suivantes :
                    </p>

                    <ul class="small text-muted pl-4 mb-0">
                      <li>Au moins une majuscule</li>
                      <li>Au moins une minuscule</li>
                      <li>Au moins un caractère spécial</li>
                      <li>Au moins un chiffre</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="validationPassword" required>Mot de Passe actuel</label>
                  <div class="input-group input-group-merge">
                    <input type="{{ showOldPassword ? 'text' : 'password' }}" formControlName="old_password" class="form-control form-control-appended" id="validationPassword" placeholder="Entrez votre mot de passe actuel"
                           [ngClass]="{ 'is-invalid': old_password.invalid && (old_password.dirty || old_password.touched) }" maxlength="255">
                    <div class="input-group-append" (click)="toggle('old_password')">
                      <span class="input-group-text"><i class="fe {{ showOldPassword ? 'fe-eye' : 'fe-eye-off' }}"></i></span>
                    </div>
                    <div *ngIf="old_password.invalid && (old_password.dirty || old_password.touched)" class="invalid-feedback">
                      {{ getErrorMessage("old_password") }}
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label for="validationOldPassword" required>Nouveau Mot de Passe</label>
                  <div class="input-group input-group-merge">
                    <input type="{{ showPassword ? 'text' : 'password' }}" formControlName="password" class="form-control form-control-appended" id="validationOldPassword" placeholder="Entrez votre nouveau mot de passe"
                           [ngClass]="{ 'is-invalid': password.invalid && (password.dirty || password.touched) }" maxlength="255">
                    <div class="input-group-append" (click)="toggle('password')">
                      <span class="input-group-text"><i class="fe {{ showPassword ? 'fe-eye' : 'fe-eye-off' }}"></i></span>
                    </div>
                    <div *ngIf="password.invalid && (password.dirty || password.touched)" class="invalid-feedback">
                      {{ getErrorMessage("password") }}
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label for="validationNewPassword" required>Confirmation Nouveau Mot de Passe</label>
                  <div class="input-group input-group-merge">
                    <input type="{{ showNewPassword ? 'text' : 'password' }}" formControlName="confirm_password" class="form-control form-control-appended" id="validationNewPassword" placeholder="Confirmez votre nouveau mot de passe"
                           [ngClass]="{ 'is-invalid': confirm_password.invalid && (confirm_password.dirty || confirm_password.touched) }" maxlength="255">
                    <div class="input-group-append" (click)="toggle('new_password')">
                      <span class="input-group-text"><i class="fe {{ showNewPassword ? 'fe-eye' : 'fe-eye-off' }}"></i></span>
                    </div>
                    <div *ngIf="confirm_password.invalid && (confirm_password.dirty || confirm_password.touched)" class="invalid-feedback">
                      {{ getErrorMessage("confirm_password") }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-end">
              <button class="btn btn-primary mb-3" (click)="updatePassword()" [ngClass]="{ 'is-loading is-loading-sm': isUpdatingPassword }" [disabled]="isUpdatingPassword || passwordForm.invalid">
                Mettre à Jour
              </button>
            </div>
          </form>
        </div>

        <!-- ENTERPRISE -->
        <div class="tab-content" *ngIf="!loader" [hidden]="activeTab === 'account'">

          <form class="mb-4" [formGroup]="enterpriseForm">
            <div class="row">
              <!-- NAME -->
              <div class="col-12">
                <div class="form-group">
                  <label for="validationName" required>Nom de l'entreprise</label>
                  <input type="text" formControlName="name" class="form-control" id="validationName" placeholder="Nom de l'entreprise" [ngClass]="{ 'is-invalid': name.invalid && (name.dirty || name.touched) }" maxlength="255">
                  <div *ngIf="name.invalid && (name.dirty || name.touched)" class="invalid-feedback">
                    {{ getErrorMessage("name") }}
                  </div>
                </div>
              </div>

              <!-- ADDRESS -->
              <div class="col-12">
                <div class="form-group">
                  <label for="validationAddress">Adresse</label>
                  <input type="text" formControlName="address" class="form-control" id="validationAddress" placeholder="Adresse" [ngClass]="{ 'is-invalid': address.invalid && (address.dirty || address.touched) }" maxlength="255" #search (keyup)="onSearch(search.value)">
                  <ul class="google-places-container" *ngIf="results.length">
                    <li *ngFor="let result of results" class="item" (click)="selectPlace(result)">
                      <a class="href" role="button">
                        <i class="fe fe-map-pin"></i> {{ result.description }}
                      </a>
                    </li>
                  </ul>
                  <div id="service-helper"></div>
                  <div *ngIf="address.invalid && (address.dirty || address.touched)" class="invalid-feedback">
                    {{ getErrorMessage("address") }}
                  </div>
                </div>
              </div>

              <!-- ZIPCODE -->
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="validationZipcode">Code postal</label>
                  <input type="text" formControlName="zipcode" class="form-control" id="validationZipcode" placeholder="Code postal" [ngClass]="{ 'is-invalid': zipcode.invalid && (zipcode.dirty || zipcode.touched) }" maxlength="5">
                  <div *ngIf="zipcode.invalid && (zipcode.dirty || zipcode.touched)" class="invalid-feedback">
                    {{ getErrorMessage("zipcode") }}
                  </div>
                </div>
              </div>

              <!-- CITY -->
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="validationCity">Ville</label>
                  <input type="text" formControlName="city" class="form-control" id="validationCity" placeholder="Ville" [ngClass]="{ 'is-invalid': city.invalid && (city.dirty || city.touched) }" maxlength="255">
                  <div *ngIf="city.invalid && (city.dirty || city.touched)" class="invalid-feedback">
                    {{ getErrorMessage("city") }}
                  </div>
                </div>
              </div>

              <!-- SIRET -->
              <div class="col-12">
                <div class="form-group">
                  <label for="validationSiret" required>Siret</label>
                  <input type="text" formControlName="siret" class="form-control" id="validationSiret" placeholder="Siret" [ngClass]="{ 'is-invalid': siret.invalid && (siret.dirty || siret.touched) }" maxlength="14">
                  <div *ngIf="siret.invalid && (siret.dirty || siret.touched)" class="invalid-feedback">
                    {{ getErrorMessage("siret") }}
                  </div>
                </div>
              </div>

            </div>
          </form>

          <div class="mb-4">
            <div class="form-group" [hidden]="enterprise_picture.value">
              <label for="inputFile" class="mb-1">Logo entreprise</label>

              <small class="form-text text-muted">
                Veuillez utiliser une image ne dépassant pas 200px * 200px.
              </small>

              <div class="dropzone dropzone-multiple dz-clickable" appDragDrop (dropHandler)="canUpdate ? onFileChangeEnterprise($event) : null">
                <div class="dz-default dz-message" (click)="chooseFileEnterprise()" [ngClass]="{ 'disabled': !canUpdate }">
                  <span>Déposer votre fichier ici pour télécharger</span>
                </div>
                <input id="inputFileEnterprise" (change)="onFileChangeEnterprise($event)" type="file" accept="image/*" [hidden]="true" [disabled]="!canUpdate">
              </div>
            </div>

            <div class="form-group" *ngIf="enterprise_picture.value">
              <label class="mb-1">Logo entreprise</label>

              <small class="form-text text-muted">
                Veuillez utiliser une image ne dépassant pas 200px * 200px.
              </small>

              <div class="card">
                <div class="card-body">
                  <div class="d-flex align-items-center justify-content-between">
                    <div class="avatar avatar-lg avatar-4by3">
                      <img src="{{ checkPictureEnterprise() }}" alt="Logo entreprise" class="avatar-img rounded">
                    </div>

                    <a class="href tooltipHtml" data-placement="bottom" title="Supprimer" (click)="removeFileEnterprise()">
                      <i class="fe fe-trash"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-end">
              <button class="btn btn-primary mb-3" (click)="updateEnterprise()" [ngClass]="{ 'is-loading is-loading-sm': isUpdatingEnterprise }" [disabled]="isUpdatingEnterprise || !canUpdate">
                Mettre à Jour
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
