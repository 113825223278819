import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { HttpClient } from '@angular/common/http';

import { AllEnterprisesComponent } from '../all-enterprises/all-enterprises.component';
import { CustomNotifierService } from '../../../services/custom.notifier.service';

import { EnterprisesRequestService } from '../../../services/enterprises.request.service';

declare var $: any;

@Component({
  selector: 'app-add-provider-sirene',
  templateUrl: './add-provider-sirene.component.html',
  styleUrls: ['./add-provider-sirene.component.scss']
})
export class AddProviderSireneComponent implements OnInit, AfterViewInit, OnDestroy {
  canCreate = false;
  canUpdate = false;

  searchForm: FormGroup;
  search = new FormControl('', [Validators.required]);

  enterpriseForm: FormGroup;
  qualification = new FormControl('TECHNICIEN', [Validators.required]);
  access_to_enterprise = new FormControl(false, [Validators.required]);

  enterprise_id: number;

  errors = {};

  count = 0;
  pages = 1;

  limit = 100;
  page = 1;

  suggestions: any[];
  suggestionSelected: string;
  enterprises: any[];
  enterpriseSelected: any;

  timeout: any;

  loader = false;
  loaderSearch = false;
  loaderContent = false;

  modalEnterprise = null;

  monoOffer = false;
  discoverOffer = false;
  connectedAs = false;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private allEnterprises: AllEnterprisesComponent,
    private enterprisesService: EnterprisesRequestService,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.searchForm = this.formBuilder.group({
      search: this.search,
    });
    this.enterpriseForm = this.formBuilder.group({
      qualification: this.qualification,
      access_to_enterprise: this.access_to_enterprise,
    });

    this.connectedAs = JSON.parse(localStorage.getItem('connectedAs'));
    if (this.connectedAs) {
      this.monoOffer = JSON.parse(localStorage.getItem('connectedAsIsMonoOffer'));
      this.discoverOffer = JSON.parse(localStorage.getItem('connectedAsIsDiscoverOffer'));
    } else {
      this.monoOffer = JSON.parse(localStorage.getItem('isMonoOffer'));
      this.discoverOffer = JSON.parse(localStorage.getItem('isDiscoverOffer'));
    }
  }

  ngAfterViewInit() {
    this.modalEnterprise.open();
  }

  ngOnDestroy() {
    this.modalEnterprise.close();
  }

  searchData() {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.page = 1;
      this.refreshData();
    }, 500);
  }

  refreshData(page = this.page) {
    this.page = page;
    if (this.search.value && this.search.value.length > 2) {
      this.http.get('https://entreprise.data.gouv.fr/api/sirene/v1/suggest/' + this.search.value).subscribe(response => {
        if (response && response.hasOwnProperty('suggestions')) {
          if (response['suggestions'].length > 0) {
            this.suggestions = response['suggestions'];
          } else if (this.search.value.length === 9 || this.search.value.length === 14) {
            this.suggestions = [this.search.value];
          }
        }
      });
    } else if (!this.search.value) {
      this.suggestions = [];
    }
  }

  getSuggestion(suggestion, page) {
    this.suggestionSelected = suggestion;
    this.suggestions = [];
    const url = 'https://entreprise.data.gouv.fr/api/sirene/v1/full_text/' + suggestion + '?page=' + page + '&per_page=100';
    this.http.get(url).subscribe(response => {
      this.count = 0;
      this.pages = 1;
      this.page = 1;
      if (response && response.hasOwnProperty('etablissement') && response['etablissement'].length > 0) {
        this.count = parseInt(response['total_results'], 10);
        this.pages = parseInt(response['total_pages'], 10);
        this.page = parseInt(response['page'], 10);
        this.enterprises = response['etablissement'];
      } else if (response && 'success' in response && response['success'] === false) {
        if (this.search.value.length === 9) {
          this.searchSiren(suggestion);
        } else if (this.search.value.length === 14) {
          this.searchSiret(suggestion);
        }
      }
    }, () => {
      if (this.search.value.length === 9) {
        this.searchSiren(suggestion);
      } else if (this.search.value.length === 14) {
        this.searchSiret(suggestion);
      }
    });
  }
  searchSiren(suggestion) {
    this.http.get('https://entreprise.data.gouv.fr/api/sirene/v1/siren/' + suggestion).subscribe(responseSiren => {
      this.count = 0;
      this.pages = 1;
      this.page = 1;
      if (responseSiren && responseSiren.hasOwnProperty('siege_social') && responseSiren['siege_social']) {
        this.count = 1;
        this.enterprises = [responseSiren['siege_social']];
        responseSiren['other_etablissements_sirets'].forEach(siret => {
          this.http.get('https://entreprise.data.gouv.fr/api/sirene/v1/siret/' + siret).subscribe(responseSiret => {
            this.count++;
            if (responseSiret && responseSiret.hasOwnProperty('etablissement') && responseSiret['etablissement']) {
              this.count = 1;
              this.enterprises.push(responseSiret['etablissement']);
            }
          });
        });
      } else {
        this.enterprises = [];
      }
    }, () => {
      this.enterprises = [];
    });
  }
  searchSiret(suggestion) {
    this.http.get('https://entreprise.data.gouv.fr/api/sirene/v1/siret/' + suggestion).subscribe(response => {
      this.count = 0;
      this.pages = 1;
      this.page = 1;
      if (response && response.hasOwnProperty('etablissement') && response['etablissement']) {
        this.count = 1;
        this.enterprises = [response['etablissement']];
      } else {
        this.enterprises = [];
      }
    }, () => {
      this.enterprises = [];
    });
  }

  changePage(page) {
    if (page >= 1 && page <= this.pages) {
      this.page = page;
      this.getSuggestion(this.suggestionSelected, page);
    }
  }

  selectEnterprise(enterprise) {
    this.enterpriseSelected = enterprise;
  }
  unselectEnterprise() {
    this.enterpriseSelected = null;

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  addEnterprise() {
    this.loaderSearch = true;

    this.enterprisesService.checkSiret(this.enterpriseSelected.siret).subscribe(responseCheck => {
      if (responseCheck['success']) {
        if (responseCheck['exist']) {
          this.enterprise_id = responseCheck['enterprise']['id'];
          this.enterprisesService.addProvider(responseCheck['enterprise']['id'], this.enterpriseForm.value).subscribe(response => {
            if (response['success']) {
              this.modalEnterprise.close();
              CustomNotifierService.getSuccessCreate(this.notifier);
            } else if (response['errors']) {
              this.errors = response['errors'];
              CustomNotifierService.getErrors(this.errors, this.notifier);
            }
            this.loaderSearch = false;
          });
        } else {
          const enterprise = {
            name: this.enterpriseSelected.nom_raison_sociale,
            address: this.enterpriseSelected.l4_normalisee,
            zipcode: this.enterpriseSelected.code_postal,
            city: this.enterpriseSelected.libelle_commune,
            longitude: this.enterpriseSelected.longitude,
            latitude: this.enterpriseSelected.latitude,
            siret: this.enterpriseSelected.siret,
            qualification: this.qualification.value,
            access_to_enterprise: this.access_to_enterprise.value
          };

          this.enterprisesService.createProvider(enterprise).subscribe(response => {
            if (response['success']) {
              this.enterprise_id = response['enterprise']['id'];
              this.modalEnterprise.close();
              CustomNotifierService.getSuccessCreate(this.notifier);
            } else if (response['errors']) {
              this.errors = response['errors'];
              CustomNotifierService.getErrors(this.errors, this.notifier);
              this.loaderSearch = false;
            }
            this.loaderSearch = false;
          });
        }
      } else if (responseCheck['errors']) {
        this.errors = responseCheck['errors'];
        CustomNotifierService.getErrors(this.errors, this.notifier);
      }
      this.loaderSearch = false;
    });
  }

  bindModal(modal) {
    this.modalEnterprise = modal;
  }

  closeModal() {
    this.allEnterprises.getEnterprises();
    if (this.enterprise_id) {
      this.router.navigate([this.activatedRoute.snapshot.parent.url[0].path + '/' + this.enterprise_id + '/users/create'])
        .then(() => {});
    } else {
      this.router.navigate([this.activatedRoute.snapshot.parent.url[0].path ])
        .then(() => {});
    }
  }
}
