import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Subject } from 'rxjs';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { UsersRequestService } from '../../../services/users.request.service';
import { EnterprisesRequestService } from '../../../services/enterprises.request.service';

import { AllAgenciesComponent } from '../all-agencies/all-agencies.component';
import { CommissionsRequestService } from '../../../services/commissions.request.service';

import BatiFile from '../../../../shared/interfaces/bati_file';

declare function Flatpickr(): any;
declare function Dropzones(): any;

@Component({
  selector: 'app-update-agency',
  templateUrl: './update-agency.component.html',
  styleUrls: ['./update-agency.component.scss']
})
export class UpdateAgencyComponent implements OnInit, AfterViewInit, OnDestroy {
  canCreate = false;
  canUpdate = false;

  requestInProgress = new Subject();
  requestCount = 0;

  commissionForm: FormGroup;
  id = new FormControl('');
  establishment_registry_id = new FormControl('');
  visit_date = new FormControl('', Validators.required);
  report_date = new FormControl(null);
  agency = new FormControl(null);
  prescriptions_name = new FormControl(null);
  enterprise_id = new FormControl(null);
  user_id = new FormControl(null);
  deadline = new FormControl(null);
  emergencies = new FormControl(null);
  financial_estimation = new FormControl(0, Validators.min(0));
  comment_commission = new FormControl(null);
  type_visit = new FormControl([], Validators.required);
  updated = new FormControl('');
  type = new FormControl('agencies');

  files: BatiFile[] = [];
  visit: any = [
    { name: 'Visite périodique', group: true },
    { name: 'Visite inopinée', group: true },
  ];
  selectedTypeVisit: any = [];
  enterprises: any = [];
  user: string;

  errors = {};

  isNew = true;
  loader = false;
  loaderContent = false;

  modalCommission = null;

  oldPrescriptions: string = null;
  countOldPrescriptions = 0;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private allCommissions: AllAgenciesComponent,
    private usersService: UsersRequestService,
    private commissionsService: CommissionsRequestService,
    private enterprisesService: EnterprisesRequestService
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loaderContent = this.requestCount > 0;
    });

    this.commissionForm = this.formBuilder.group({
      id: this.id,
      establishment_registry_id: this.establishment_registry_id,
      visit_date: this.visit_date,
      report_date: this.report_date,
      agency: this.agency,
      prescriptions_name: this.prescriptions_name,
      enterprise_id: this.enterprise_id,
      user_id: this.user_id,
      deadline: this.deadline,
      emergencies: this.emergencies,
      financial_estimation: this.financial_estimation,
      comment_commission: this.comment_commission,
      type_visit: this.type_visit,
      updated: this.updated,
      type: this.type,
    });

    if (this.activatedRoute.snapshot.params.commission_id) {
      this.isNew = false;
      this.getCommission();
    } else {
      Flatpickr();
    }

    this.getMyUsers();
    this.getEnterprises();
  }

  ngAfterViewInit() {
    Dropzones();
    this.modalCommission.open();
  }

  ngOnDestroy() {
    const flatPicker = document.getElementsByClassName('flatpickr-calendar') as HTMLCollection;
    for (let i = flatPicker.length; i >= 0; i--) {
      if (flatPicker[i]) {
        flatPicker[i].remove();
      }
    }

    const dropZones = document.getElementsByClassName('dz-hidden-input') as HTMLCollection;
    for (let i = 0; i < dropZones.length; i++) {
      dropZones[i].remove();
    }

    this.modalCommission.close();
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  getCommission() {
    let request;
    this.requestInProgress.next(true);

    request = this.commissionsService.getCommission(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.activatedRoute.snapshot.params.commission_id
    );

    request.subscribe(response => {
      if (response['success']) {
        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];

        this.commissionForm.patchValue(response['commission']);

        this.prescriptions_name.setValue('');
        const temp = [];
        this.countOldPrescriptions = response['commission']['prescriptions'].length;
        response['commission']['prescriptions'].forEach(element => {
          temp.push(element.name);
        });
        this.oldPrescriptions = temp.join('\n');

        response['commission']['type_visit'].forEach(type_visit => {
          this.selectedTypeVisit.push(type_visit);
        });

        if (!this.canUpdate) {
          this.commissionForm.disable();
        }

        if (this.commissionForm.value.enterprise_id && this.commissionForm.value.user_id) {
          this.user = this.commissionForm.value.enterprise_id + '-' + this.commissionForm.value.user_id;
        }
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      Flatpickr();
      this.requestInProgress.next(false);
    });
  }

  getEnterprises() {
    this.requestInProgress.next(true);

    this.enterprisesService.getProviders('', '', '', '', '').subscribe(response => {
      if (response['success']) {
        response['enterprises'].forEach(enterprise => {
          this.getUsers(enterprise);
        });
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  getUsers(enterprise) {
    this.requestInProgress.next(true);

    this.usersService.getProviderUsers('', '', '', '', '', enterprise.id).subscribe(response => {
      if (response['success']) {
        response['users'].forEach(user => {
          this.enterprises.push({
            id: enterprise.id + '-' + user.id,
            name: enterprise.name + ' - ' + user.firstname + ' ' + user.lastname
          });
        });
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  getMyUsers() {
    this.requestInProgress.next(true);

    this.usersService.getMyUsers('', '', '', '', '').subscribe(response => {
      if (response['success']) {
        response['users'].forEach(user => {
          this.enterprises.push({
            id: response['enterprise'].id + '-' + user.id,
            name: response['enterprise'].name + ' - ' + user.firstname + ' ' + user.lastname
          });
        });
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  changeUser() {
    if (this.user) {
      const split = this.user.split('-');
      this.enterprise_id.setValue(split[0]);
      this.user_id.setValue(split[1]);
    } else {
      this.enterprise_id.setValue(null);
      this.user_id.setValue(null);
    }
  }

  createCommission() {
    let request;
    this.errors = {};
    this.loader = true;

    if (this.prescriptions_name.value) {
      const temp = [];
      const prescriptions_name: string = this.prescriptions_name.value;
      const splitValue = prescriptions_name.split('\n');

      splitValue.forEach(value => {
        const reg = new RegExp(/^\s*•\s+/gm);
        value = value.replace(reg, '');
        if (value) {
          temp.push(value);
        }
      });
      this.prescriptions_name.setValue(temp);
    }

    request = this.commissionsService.createCommission(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.commissionForm.value
    );

    request.subscribe(response => {
      if (response['success']) {
        this.isNew = false;
        if (this.files.length) {
          this.createDocuments(response['commission']['id']);
        } else {
          this.loader = false;
          this.modalCommission.close();
          CustomNotifierService.getSuccessCreate(this.notifier);
        }
      } else if (response['errors']) {
        this.loader = false;
        this.errors = response['errors'];
        this.prescriptions_name.setValue(null);
        CustomNotifierService.getErrorsForm(response['errors'], this.commissionForm, this.notifier);
      }

      if (this.prescriptions_name.value) {
        this.prescriptions_name.setValue(this.prescriptions_name.value.join('\n'));
        this.prescriptions_name.setValue(this.prescriptions_name.value.replace(/(^|\r\n|\n)([^• ]|$)/g, '$1• $2'));
      }
    });
  }

  updateCommission() {
    let request;
    let commission;

    this.errors = {};
    this.loader = true;

    if (this.prescriptions_name.value) {
      const temp = [];
      const prescriptions_name: string = this.prescriptions_name.value;
      const splitValue = prescriptions_name.split('\n');

      splitValue.forEach(value => {
        if (value) {
          const reg = new RegExp(/^\s*•\s+/gm);
          temp.push(value.replace(reg, ''));
        }
      });

      this.prescriptions_name.setValue(temp);
    }

    commission = {
      ...this.commissionForm.value,
      id: this.id.value,
      establishment_registry_id: this.establishment_registry_id.value
    };

    request = this.commissionsService.updateCommission(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.activatedRoute.snapshot.params.commission_id,
      commission
    );

    request.subscribe(response => {
      if (response['success']) {
        if (this.files.length) {
          this.createDocuments(this.activatedRoute.snapshot.params.commission_id);
        } else {
          this.loader = false;
          this.modalCommission.close();
          CustomNotifierService.getSuccessUpdate(this.notifier);
        }
      } else if (response['errors']) {
        this.loader = false;
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }

      if (this.prescriptions_name.value) {
        this.prescriptions_name.setValue(this.prescriptions_name.value.join('\n'));
        this.prescriptions_name.setValue(this.prescriptions_name.value.replace(/(^|\r\n|\n)([^• ]|$)/g, '$1• $2'));
      }
    });
  }

  addTextareaBullet(event) {
    if (event.srcElement.value) {
      event.srcElement.value = event.srcElement.value.replace(/(^|\r\n|\n)([^• ]|$)/g, '$1• $2');
    }
  }

  pasteTextareaBullet(event) {
    setTimeout(() => {
      event.srcElement.value = event.srcElement.value.replace(/^\s+|\s+$/g, '');
      event.srcElement.value = event.srcElement.value.replace(/[\r\n]+/g, '\n');
      event.srcElement.value = event.srcElement.value.replace(/(^|\r\n|\n)([^• ]|$)/g, '$1• $2');
    }, 150);
  }

  changeVisitType() {
    this.type_visit.setValue(this.selectedTypeVisit);

    let count = 0;
    this.selectedTypeVisit.forEach(visit => {
      if (visit.name === 'Visite de réception' ) {
        count ++;
      }
    });
    return count;
  }

  createDocuments(commission_id) {
    let request;
    this.errors = {};

    request = this.commissionsService.createFile(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      commission_id,
      this.files
    );

    request.subscribe(response => {
      if (response['success']) {
        this.modalCommission.close();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loader = false;
    });
  }

  chooseFile() {
    const element: HTMLElement = document.getElementById('inputFile') as HTMLElement;
    element.click();
  }

  onFileChange(event) {
    if (event.target && event.target.files && event.target.files.length) {
      for (let i = 0; i < event.target.files.length; i++) {
        const reader = new FileReader();
        const file = event.target.files[i];
        reader.readAsDataURL(file);

        reader.onload = () => {
          let extension = '';
          let name = '';

          const split = file.name.split('.');
          if (split.length) {
            name = split[0];
            extension = split[split.length - 1];
          }

          this.files.push({ file_base64: reader.result, name: name, extension: extension });
        };
      }
    } else {
      let extension = '';
      let name = '';

      const split = event.name.split('.');
      if (split.length) {
        name = split[0];
        extension = split[split.length - 1];
      }

      this.files.push({ file_base64: event.file, name: name, extension: extension });
    }
    const element: HTMLInputElement = document.getElementById('inputFile') as HTMLInputElement;
    element.value = '';
  }

  removeFile(index) {
    this.files.splice(index, 1);

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  bindModal(modal) {
    this.modalCommission = modal;
  }

  closeModal() {
    this.router.navigate([this.activatedRoute.snapshot.parent.url.join('/')]).then(() => {
      this.allCommissions.refreshData();
    });
  }
}
