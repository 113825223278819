import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AllEnterprisesModule } from './all-enterprises/all-enterprises.module';
import { UpdateEnterpriseModule } from './update-enterprise/update-enterprise.module';

import { AddProviderModule } from './add-provider/add-provider.module';
import { AddProviderSireneModule } from './add-provider-sirene/add-provider-sirene.module';

import { UpdateSubscriptionModule } from './update-subscription/update-subscription.module';

import { AllProviderContractsModule } from './all-provider-contracts/all-provider-contracts.module';
import { UpdateProviderContractModule } from './update-provider-contract/update-provider-contract.module';

import { RightsTransferModule } from './rights-transfer/rights-transfer.module';

import { EnterprisesRequestService } from '../../services/enterprises.request.service';
import { UsersRequestService } from '../../services/users.request.service';
import { ArchivesRequestService } from '../../services/archives.request.service';
import { AllProviderRegistryModule } from './all-provider-registry/all-provider-registry.module';

@NgModule({
  imports: [
    CommonModule,
    AllEnterprisesModule,
    UpdateEnterpriseModule,
    AddProviderModule,
    AddProviderSireneModule,
    UpdateSubscriptionModule,
    AllProviderContractsModule,
    UpdateProviderContractModule,
    RightsTransferModule,
    AllProviderRegistryModule
  ],
  providers: [
    EnterprisesRequestService,
    UsersRequestService,
    ArchivesRequestService
  ],
})
export class EnterprisesModule {}
