import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AllDocumentsComponent } from './all-documents.component';
import { ModalRightModule } from '../../../core/modal-right/modal-right.module';
import { ConfirmModalModule } from '../../../core/confirm-modal/confirm-modal.module';
import { BreadcrumbsModule } from '../../../core/breadcrumbs/breadcrumbs.module';
import { SearchbarModule } from '../../../core/searchbar/searchbar.module';
import { VideoModalModule } from '../../../core/video-modal/video-modal.module';
import { PdfViewerModule } from '../../../core/pdf-viewer/pdf-viewer.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    ModalRightModule,
    ConfirmModalModule,
    BreadcrumbsModule,
    SearchbarModule,
    VideoModalModule,
    PdfViewerModule
  ],
  declarations: [AllDocumentsComponent]
})

export class AllDocumentsModule {}
