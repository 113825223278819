<app-modal-right (eventOnClose)="closeModal()" #faq>{{ bindModal(faq) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">{{ isNew ? 'Création' : canUpdate ? 'Mise à jour' : 'Consultation' }}</h6>

        <h1 class="header-title">Faq</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <form class="mb-4" [formGroup]="faqForm" [hidden]="loaderContent">
      <div class="form-group">
        <label for="validationQuestion" required>Question</label>
        <input type="text" formControlName="question" class="form-control" id="validationQuestion" placeholder="Question" [ngClass]="{ 'is-invalid': question.invalid && (question.dirty || question.touched) }" maxlength="255">
        <div *ngIf="question.invalid && (question.dirty || question.touched)" class="invalid-feedback">
          {{ getErrorMessage("question") }}
        </div>
      </div>

      <div class="form-group">
        <label required>Réponse</label>
        <editor initialValue=""
                formControlName="answer"
                [init]="{ height: 500, menubar: false, plugins: ['link preview table code lists'],
                                toolbar:'undo redo | formatselect fontselect fontsizeselect | ' +
                                'bold italic underline strikethrough backcolor forecolor | preview | ' +
                                'alignleft aligncenter alignright alignjustify | ' +
                                'bullist numlist outdent indent link table'
                              }">
        </editor>
        <div *ngIf="answer.invalid && (answer.dirty || answer.touched)" class="invalid-feedback">
          {{ getErrorMessage("answer") }}
        </div>
      </div>
    </form>
  </div>

  <div modal-footer class="w-100 d-flex justify-content-between">

    <button class="btn btn-primary w-30"(click)="isNew ? createFaq() : updateFaq()"
            [ngClass]="{ 'is-loading is-loading-sm': loader }" [disabled]="loader || faqForm.invalid || (!isNew && !canUpdate)">
      {{ isNew ? 'Créer' : 'Mettre à jour' }}
    </button>
  </div>
</app-modal-right>
