<app-modal-right (eventOnClose)="closeModal()" #user>{{ binModal(user) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">{{ isNew ? 'Création' : canUpdate ? 'Mise à jour' : 'Consultation' }}</h6>

        <h1 class="header-title">Précision sur le budget - Risque</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <form [formGroup]="costForm" *ngIf="!loaderContent">
      <div class="row">
        <div class="col-12">
          <!-- NAME -->
          <div class="form-group">
            <label for="validationUserName" required>Nom</label>
            <input type="text" id="validationUserName" formControlName="name" class="form-control" placeholder="Nom" maxlength="255"
                   [ngClass]="{ 'is-invalid': name.invalid && (name.dirty || name.touched) }">
            <div *ngIf="name.invalid && (name.dirty || name.touched)" class="invalid-feedback">
              {{ getErrorMessage("name") }}
            </div>
          </div>

          <!-- DESCRIPTION -->
          <div class="form-group">
            <label for="validationCurrentProtection">Description</label>
            <textarea formControlName="description" class="form-control" id="validationCurrentProtection" placeholder="Description" rows="5"></textarea>
            <div *ngIf="description.invalid && (description.dirty || description.touched)" class="invalid-feedback">
              {{ getErrorMessage("description") }}
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div modal-footer class="w-100" *ngIf="isNew || canUpdate">
    <button class="btn btn-primary w-100" (click)="isNew ? createCost() : updateCost()"
            [ngClass]="{ 'is-loading is-loading-sm': loader }" [disabled]="loader || costForm.invalid">
      {{ isNew ? 'Créer' : 'Mettre à jour' }}
    </button>
  </div>
</app-modal-right>
