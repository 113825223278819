import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';

import { AuthService } from '../../../core/auth/auth.service';
import { ConfirmModalComponent } from '../../../core/confirm-modal/confirm-modal.component';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { DocumentsRequestService } from '../../../services/documents.request.service';
import { RegistriesRequestService } from '../../../services/registries.request.service';
import { EstablishmentsRequestService } from '../../../services/establishments.request.service';
import { VideosRequestService } from '../../../services/videos.request.service';
import { PdfRequestService } from '../../../services/pdf.request.service';

import Folder from '../../../../shared/interfaces/folder';
import Registry from '../../../../shared/interfaces/registry';
import Establishment from '../../../../shared/interfaces/establishment';
import File from '../../../../shared/interfaces/file';

import { VideoModalComponent } from '../../../core/video-modal/video-modal.component';
import { PdfViewerComponent } from '../../../core/pdf-viewer/pdf-viewer.component';

import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-all-documents',
  templateUrl: './all-documents.component.html',
  styleUrls: ['./all-documents.component.scss']
})
export class AllDocumentsComponent implements OnInit, AfterViewInit, OnDestroy {
  changeEnterprise: Subscription;

  canCreate = false;
  canUpdate = false;

  requestInProgress = new Subject();
  requestCount = 0;

  folders: Folder[] = [];
  folderParent: Folder;
  fileTypeParent: Folder;
  files: File[] = [];
  registry: Registry;

  establishment: Establishment;
  establishment_id: number;

  updateRoute = '';
  customerRoute = '';

  view = 'parent';
  viewAdmin = false;
  viewRegistry = false;
  viewPublic = false;
  viewCustomer = false;

  pageName: string;
  pageTitle: string;
  previousPageName: string[] = [];
  previousPageRoute: string[] = [];

  count = 0;
  pages = 1;

  search = '';
  sort = '';
  order = 'asc';
  limit = 0;
  page = 1;

  timeout: any;

  loader = false;
  modalVideo: VideoModalComponent = null;
  modalPdf: PdfViewerComponent = null;
  modalConfirmFile: ConfirmModalComponent = null;

  constructor(
    private router: Router,
    private http: HttpClient,
    private authService: AuthService,
    private notifier: NotifierService,
    public activatedRoute: ActivatedRoute,
    private PdfService: PdfRequestService,
    private videosService: VideosRequestService,
    private documentsService: DocumentsRequestService,
    private registriesService: RegistriesRequestService,
    private establishmentsService: EstablishmentsRequestService,
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loader = this.requestCount > 0;
    });

    this.activatedRoute.params.subscribe(() => {
      this.updateRoute = '/' + this.activatedRoute.snapshot.url.join('/') + '/';

      this.defineView();
      this.getFolders();
    });

    this.changeEnterprise = this.authService.getEnterprise().subscribe(() => {
      this.getFolders();
    });

    this.pageName = 'Documents';
    this.pageTitle = 'Documents';

    this.createBreadcrumbs();
  }

  ngAfterViewInit() {
    this.modalConfirmFile.eventOnClose.subscribe(event => {
      if (event) {
        this.requestInProgress.next(true);
        let request;
        if (this.viewCustomer) {
          request = this.documentsService.deleteDashboardFile(
            this.activatedRoute.snapshot.params.registry_id, this.establishment_id, event);
        } else {
          request = this.documentsService.deleteFile(event);
        }
        request.subscribe(response => {
          if (response['success']) {
            this.refreshData();
            CustomNotifierService.getSuccessDelete(this.notifier);
          } else if (response['errors']) {
            CustomNotifierService.getErrors(response['errors'], this.notifier);
          }
          this.requestInProgress.next(false);
        });
      }
    });
  }

  ngOnDestroy() {
    this.changeEnterprise.unsubscribe();

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  /**
   * Define the view to display correct elements and data.
   */
  defineView() {
    const snapshot = this.activatedRoute.snapshot;

    if (snapshot.url[1] && snapshot.url[1].path === 'public') {
      this.viewPublic = true;
    } else if (snapshot.params.establishment_id) {
      this.viewCustomer = true;
      this.establishment_id = parseInt(snapshot.params.establishment_id, 10);

      this.customerRoute = '/dashboard-registry/' + snapshot.params.registry_id + '/establishment/' + this.establishment_id;

      this.getRegistry();
      this.getEstablishment();
    } else if (snapshot.params.registry_id) {
      this.viewRegistry = true;
      this.getRegistry();
    } else {
      this.viewAdmin = true;
    }

    if (snapshot.params.file_type_id) {
      this.view = 'file';
      // this.limit = 12;
    } else if (this.activatedRoute.snapshot.params.parent_id) {
      this.view = 'children';
    } else {
      this.view = 'parent';
    }
  }

  searchData() {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.page = 1;
      this.getFolders();
    }, 500);
  }

  refreshData(sort = this.sort, order = this.order, page = this.page) {
    this.sort = sort;
    this.order = order;
    this.page = page;
    this.getFolders();
  }

  getRegistry() {
    this.requestInProgress.next(true);
    this.registriesService.getRegistry(this.activatedRoute.snapshot.params.registry_id).subscribe(response => {
      if (response['success']) {
        this.registry = response['registry'];
        this.createBreadcrumbs();
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  getEstablishment() {
    this.requestInProgress.next(true);
    this.establishmentsService.getMyEstablishment(this.establishment_id).subscribe(response => {
      if (response['success']) {
        this.establishment = response['establishment'];
        this.createBreadcrumbs();
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  /**
   * Retrieves files and / or folders.
   */
  getFolders() {
    this.requestInProgress.next(true);
    const snapshot = this.activatedRoute.snapshot;
    let request;

    if (this.view === 'file') {
      if (this.viewCustomer) {
        request = this.documentsService.getDashboardFiles(
          this.search, this.sort, this.order, this.limit, this.page,
          this.activatedRoute.snapshot.params.registry_id, this.establishment_id, this.activatedRoute.snapshot.params.file_type_id
        );
      } else {
        request = this.documentsService.getFiles(this.search, this.sort, this.order, this.limit, this.page, snapshot.params.file_type_id);
      }
    } else if (this.view === 'children') {
      if (this.viewCustomer) {
        request = this.documentsService.getDashboardChildrenFolders(
          this.search, this.sort, this.order, this.limit, this.page,
          this.activatedRoute.snapshot.params.registry_id, this.establishment_id, this.activatedRoute.snapshot.params.parent_id
        );
      } else {
        request = this.documentsService.getChildrenFolders(
          this.search, this.sort, this.order, this.limit, this.page, snapshot.params.parent_id
        );
      }
    } else if (this.view === 'parent') {
      if (this.viewPublic) {
        request = this.documentsService.getPublicFolders(this.search, this.sort, this.order, this.limit, this.page);
      } else if (this.viewRegistry) {
        request = this.documentsService.getRegistryFolders(
          this.search, this.sort, this.order, this.limit, this.page, snapshot.params.registry_id
        );
      } else if (this.viewCustomer) {
        request = this.documentsService.getDashboardFolders(
          this.search, this.sort, this.order, this.limit, this.page, this.activatedRoute.snapshot.params.registry_id, this.establishment_id
        );
      } else {
        request = this.documentsService.getFolders(this.search, this.sort, this.order, this.limit, this.page);
      }
    }

    request.subscribe(response => {
      if (response['success']) {
        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];

        if (response['folders']) {
          this.folders = response['folders'];
        }

        if (response['files']) {
          this.files = response['files'];
        }

        if (response['fileTypeParent']) {
          this.fileTypeParent = response['fileTypeParent'];
          this.folderParent = response['fileTypeParent']['generic_folder'];
        } else {
          this.folderParent = response['folderParent'];
        }

        this.count = response['count'];
        this.pages = response['pages'];
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
      this.createBreadcrumbs();
    });
  }

  checkUserUpload() {
    let bool = false;
    for (let i = 0; i < this.folders.length; i++) {
      bool = this.folders[i].establishment_id !== this.establishment_id;
    }
    return bool;
  }

  get selectedRequired() {
    const array: Folder[] = [];
    for (const key in this.folders) {
      if (this.folders.hasOwnProperty(key)) {
        if (this.folders[key].file_is_required && !this.folders[key].generic_files.length) {
          array.push(this.folders[key]);
        }
      }
    }
    return array;
  }

  /**
   * Download one file
   *
   * @param file_id The file
   * @param file_name And his name
   */
  downloadFile(file_id: any, file_name: any) {
    let request;
    if (this.viewCustomer) {
      request = this.documentsService.downloadDashboardFile(
        this.activatedRoute.snapshot.params.registry_id, this.establishment_id, file_id);
    } else {
      request = this.documentsService.downloadFile(file_id);
    }
    request.subscribe((response: BufferSource) => {
      try {
        const decoder = new TextDecoder('utf-8');
        const domString = decoder.decode(response);
        const json = JSON.parse(domString);

        CustomNotifierService.getErrors(json['errors'], this.notifier);
      } catch (e) {
        const blob = new Blob([response]);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = file_name;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      }
    });
  }

  createBreadcrumbs() {
    const snapshot = this.activatedRoute.snapshot;
    this.previousPageName = [];
    this.previousPageRoute = [];

    this.previousPageName.push('Tableau de bord');

    // Set the first part of breadcrumbs for each case
    if (this.viewPublic || this.viewCustomer) {
      this.previousPageRoute.push('/');
    } else {
      // If is admin
      this.previousPageRoute.push('/administration');

      if (this.viewRegistry) {
        this.previousPageName.push('Registres');
        this.previousPageRoute.push('/registries');
      }
    }

    if (snapshot.params.parent_id) {
      if (this.viewCustomer) {
        this.previousPageName.push(this.registry ? this.registry.name : 'Tableau de bord registre');
        this.previousPageRoute.push('/dashboard-registry/' + snapshot.params.registry_id);

        this.previousPageName.push(this.establishment ? this.establishment.name : 'Tableau de bord établissement');
        this.previousPageRoute.push(this.customerRoute);
      } else {
        this.previousPageName.push('Dossiers Racine');
        if (this.viewPublic) {
          this.previousPageRoute.push('/documents/public/');
        } else if (this.viewRegistry) {
          this.previousPageRoute.push('/documents/registry/' + snapshot.params.registry_id);
        } else {
          this.previousPageRoute.push('/documents/');
        }
      }
    }

    if (this.fileTypeParent && this.folderParent) {
      this.pageName = this.fileTypeParent.name;
      this.previousPageName.push(this.folderParent.name);

      if (this.viewPublic) {
        this.previousPageRoute.push('/document/public/' + this.folderParent.id);
      } else if (this.viewCustomer) {
        this.previousPageRoute.push(this.customerRoute + '/document/' + this.folderParent.id);
      } else if (this.viewRegistry) {
        this.previousPageRoute.push('/document/registry/' + snapshot.params.registry_id + '/' + this.folderParent.id);
      } else {
        this.previousPageRoute.push('/document/' + this.folderParent.id);
      }
    } else if (!this.fileTypeParent && this.folderParent && this.folderParent.parent_folder) {
      this.pageName = this.folderParent.name;
      this.previousPageName.push(this.folderParent.parent_folder.name);

      if (this.viewPublic) {
        this.previousPageRoute.push('/document/public/' + this.folderParent.parent_folder.id);
      } else if (this.viewCustomer) {
        this.previousPageRoute.push(this.customerRoute + '/document/' + this.folderParent.parent_folder.id);
      } else if (this.viewRegistry) {
        this.previousPageRoute.push('/document/registry/' + snapshot.params.registry_id + '/' + this.folderParent.parent_folder.id);
      } else {
        this.previousPageRoute.push('/document/' + this.folderParent.parent_folder.id);
      }
    } else if (!this.fileTypeParent && this.folderParent) {
      this.pageName = this.folderParent.name;
    }
  }

  dispatchPagination(data) {
    if (data.action === 'total_elements') {
      this.refreshPage(data.value);
    } else if (data.action === 'change_page') {
      this.changePage(data.value);
    }
  }

  refreshPage(limit) {
    this.limit = limit;
    this.page = 1;
    this.refreshData();
  }

  changePage(page) {
    if (page >= 1 && page <= this.pages) {
      this.page = page;
      this.refreshData();
    }
  }

  filter(property) {
    if (this.sort === property) {
      if (this.order === 'asc') {
        this.order = 'desc';
      } else {
        this.order = 'asc';
      }
    } else {
      this.sort = property;
      this.order = 'asc';
    }
  }

  bindModalConfirmFile(modal) {
    this.modalConfirmFile = modal;
  }

  bindModalVideo(modal) {
    this.modalVideo = modal;
  }

  bindModalPdf(modal) {
    this.modalPdf = modal;
  }

  /**
   * Download one file
   *
   * @param file_id The file
   */
  showVideo(file_id) {
    this.videosService.getFile(file_id).subscribe(response => {
      if (response['success']) {
        const url = environment.API_PATH + 'download-video/' + response['file']['token'];
        this.modalVideo.open(url);
      }
    });
  }
  /**
   * Show Pdf
   *
   * @param file_url The file
   */
  showPdf(file_url) {
    const url = environment.API_PATH + 'file/GenericFiles/' + file_url;
    this.http.get(url + '/isExist').subscribe(response => {
      if (response['success']) {
        this.modalPdf.open(url);
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
    });
  }
  getImage(extension) {
    if (extension === 'mp4') {
      return '../assets/img/files/mp4.png';
    } else if (extension === 'pdf') {
      return '../assets/img/files/pdf.png';
    } else if (extension === 'xlsx' || extension === 'csv') {
      return '../assets/img/files/xls.png';
    } else if (extension === 'doc' || extension === 'docx') {
      return '../assets/img/files/doc.png';
    } else if (extension === 'jpeg' || extension === 'jpg') {
      return '../assets/img/files/jpg.png';
    } else if (extension === 'png') {
      return '../assets/img/files/png.png';
    } else {
      return '../assets/img/files/file_icon.png';
    }
  }
}
