<app-modal-right (eventOnClose)="closeModal()" #temperature>{{ bindModal(temperature) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">{{ isNew ? 'Création' : canUpdate ? 'Mise à jour' : 'Consultation' }}</h6>

        <h1 class="header-title">Surveillance des températures</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <form [formGroup]="sanitaryTemperatureForm" [hidden]="loaderContent">
      <!-- ID / NAME -->
      <div class="form-group">
        <label for="validationTitle" required>Identification</label>
        <input type="text" formControlName="name" class="form-control" id="validationTitle" placeholder="Identification" [ngClass]="{ 'is-invalid': name.invalid && (name.dirty || name.touched) }">
      </div>
      <!-- INSTALLATION -->
      <div class="form-group">
        <label required>Installation</label>
        <ng-select [items]="sanitaryInstallations"
                   [multiple]="false"
                   bindLabel="description"
                   notFoundText="Aucun résultat"
                   [selectableGroup]="true"
                   [closeOnSelect]="true"
                   bindValue="id"
                   placeholder="Sélectionnez une installation"
                   formControlName="sanitary_installation_id">
          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">{{ item.description }}</ng-template>
        </ng-select>
      </div>

      <div class="form-group" *ngIf="isSanitary">
        <label required>Sélectionner une fiche de suivi</label>
        <ng-select [items]="frameLists"
                   [multiple]="false"
                   bindLabel="title"
                   bindValue="title"
                   [selectableGroup]="false"
                   [closeOnSelect]="true"
                   placeholder="Sélectionner une fiche de suivi"
                   formControlName="frame">
          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
            {{ item.title }}
          </ng-template>
        </ng-select>
      </div>

      <div class="form-group" formArrayName="descriptions">
        <div [formGroupName]="indexDescription" [attr.id]="'description' + indexDescription" *ngFor="let description of sanitaryTemperatureForm['controls'].descriptions['controls'] as FormGroup; let indexDescription = index;">
          <div class="card">

            <div class="card-header">
              <div class="d-flex align-items-center" *ngIf="!description['controls'].isNew.value">
                <i data-toggle="collapse" [attr.href]="'#indexDescription' + indexDescription" aria-expanded="true" class="fe chevron mr-2 href collapsed"></i>
                <p class="mb-0 my-sm-3 mx-sm-auto mx-lg-0">{{ description['controls'].title.value }}<span *ngIf="description['controls'].more_information.value">{{ ' (' + description['controls'].more_information.value + ')'}}</span></p>
              </div>
              <div class="d-flex align-items-center" *ngIf="description['controls'].isNew.value">
                <i data-toggle="collapse" [attr.href]="'#indexDescription' + indexDescription" aria-expanded="true" class="fe chevron mr-2 href collapsed"></i>
                <input type="text" id="validationTitleDescription" formControlName="title"  class="form-control" placeholder="Titre"
                       [ngClass]="{ 'is-invalid': description['controls'].title.invalid && (description['controls'].title.dirty || description['controls'].title.touched) }">
                <div *ngIf="description['controls'].title.invalid && (description['controls'].title.dirty || description['controls'].title.touched)" class="invalid-feedback">
                  {{ getErrorMessage("title") }}
                </div>
                <a class="href tooltipHtml col-2 d-flex align-items-center justify-content-center" data-placement="bottom" title="Supprimer" (click)="deleteDescription(indexDescription)">
                  <i class="fe fe-trash"></i>
                </a>
              </div>
            </div>

            <div class="card-body collapse" [id]="'indexDescription' + indexDescription" [attr.id]="'indexDescription' + indexDescription" role="tabpanel" [attr.data-parent]="'#indexDescription' + indexDescription">

              <div class="form-group">
                <label for="validationPointList">Liste des points et leur localisation (étage, n°de chambre, lavabo, douche….)</label>
                <div class="form-group" formArrayName="pointLists">
                  <div [formGroupName]="indexPointList" *ngFor="let pointList of description['controls'].pointLists['controls'] as FormGroup; let indexPointList = index;">
                    <div class="d-flex align-items-center">
                      <input type="text" id="validationPointList" formControlName="value"  class="form-control mb-2" placeholder="Point de contrôle"
                             [ngClass]="{ 'is-invalid': pointList['controls'].value.invalid && (pointList['controls'].value.dirty || pointList['controls'].value.touched) }">
                      <div *ngIf="pointList['controls'].value.invalid && (pointList['controls'].value.dirty || pointList['controls'].value.touched)" class="invalid-feedback">
                        {{ getErrorMessage("title") }}
                      </div>
                      <a class="href tooltipHtml col-2 d-flex align-items-center justify-content-center" data-placement="bottom" title="Supprimer" (click)="deletePointList(indexDescription, indexPointList)">
                        <i class="fe fe-trash"></i>
                      </a>
                    </div>
                  </div>
                  <button class="btn btn-primary col-6 offset-3 mt-2" (click)="addNewPointList(indexDescription)">
                    Ajouter un point et sa localisation
                  </button>
                </div>
              </div>

              <!-- RECOMMENDATION -->
              <div class="form-group">
                <label for="validationRecommendation" *ngIf="description['controls'].isNew.value">Préconisation</label>
                <textarea formControlName="recommendation" class="form-control" id="validationRecommendation" placeholder="Préconisation " rows="5"
                          [ngClass]="{ 'is-invalid': description['controls'].recommendation.invalid && (description['controls'].recommendation.dirty || description['controls'].recommendation.touched) }"></textarea>
              </div>

              <div formArrayName="frequencies">
                <div [formGroupName]="indexFrequency" class="card bg-light border" *ngFor="let frequency of description['controls'].frequencies['controls']; let indexFrequency = index">
                  <div class="card-body">
                    <div class="d-flex align-items-center">
                      <div class="col" *ngIf="!description['controls'].isNew.value || frequency.value.frequency">
                        <p class="small text-muted mb-2">
                          Fréquence : {{ getFrequencyText(frequency.value.frequency, frequency.value.frequency_duration) }}
                        </p>
                      </div>
                    </div>
                    <ng-container *ngIf="description['controls'].isNew.value">
                      <div class="row">
                        <!-- FREQUENCY DURATION -->
                        <div class="form-group col-5">
                          <label for="validationDuration">Durée de fréquence</label>
                          <input type="number" id="validationDuration" formControlName="frequency_duration" class="form-control" placeholder="Durée de fréquence" min="1" step="1"
                                 [ngClass]="{ 'is-invalid': frequency['controls'].frequency_duration.invalid && (frequency['controls'].frequency_duration.dirty || frequency['controls'].frequency_duration.touched) }">
                          <div *ngIf="frequency['controls'].frequency_duration.invalid && (frequency['controls'].frequency_duration.dirty || frequency['controls'].frequency_duration.touched)" class="invalid-feedback">
                            {{ getErrorMessage("frequency_duration") }}
                          </div>
                        </div>

                        <!-- FREQUENCY -->
                        <div class="form-group col-6">
                          <label for="validationFrequency">Fréquence</label>
                          <select class="form-control" data-toggle="select" formControlName="frequency" id="validationFrequency"
                                  [ngClass]="{ 'is-invalid': frequency['controls'].frequency.invalid && (frequency['controls'].frequency.dirty || frequency['controls'].frequency.touched) }">
                            <option value="" [selected]="!frequency['controls'].frequency.value">Sélectionnez une fréquence</option>
                            <option value="day">Jour(s)</option>
                            <option value="week">Semaine(s)</option>
                            <option value="month">Mois</option>
                            <option value="year">Année(s)</option>
                          </select>
                          <div *ngIf="frequency['controls'].frequency.invalid && (frequency['controls'].frequency.dirty || frequency['controls'].frequency.touched)" class="invalid-feedback">
                            {{ getErrorMessage("frequency") }}
                          </div>
                        </div>

                        <a class="href tooltipHtml col-1 d-flex align-items-center justify-content-center" data-placement="bottom" title="Supprimer" (click)="deleteFrequency(indexDescription, indexFrequency)" *ngIf="description['controls'].isNew.value">
                          <i class="fe fe-trash"></i>
                        </a>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
              <button class="btn btn-primary col-6 offset-3 mt-2" (click)="addFrequency(indexDescription)" *ngIf="description['controls'].isNew.value">
                Ajouter une fréquence
              </button>
            </div>

          </div>
        </div>
        <button class="btn btn-primary col-6 offset-3 mt-2" (click)="addNewDescription()">
          Ajouter une identification
        </button>
      </div>
      <!-- DOCUMENT -->
      <h2 class="header-title mb-3">Documents</h2>
      <div class="form-group" *ngIf="isNew || canUpdate">
        <div class="dropzone dropzone-multiple dz-clickable" appDragDrop (dropHandler)="onFileChange($event)">
          <div class="dz-default dz-message" (click)="chooseFile()">
            <span>Déposer vos fichiers ici pour télécharger</span>
          </div>
          <input id="inputFile" (change)="onFileChange($event)" type="file" [hidden]="true" multiple>
        </div>
      </div>
      <div class="card" *ngFor="let file of files; let index = index">
        <div class="card-body">
          <div class="d-flex align-content-center justify-content-between">
            <h4 class="card-title mb-0 name align-self-center">
              {{ file.name + '.' + file.extension }}
            </h4>

            <a class="href tooltipHtml" data-placement="bottom" title="Supprimer" (click)="removeFile(index)" *ngIf="isNew || canUpdate">
              <i class="fe fe-trash"></i>
            </a>
          </div>
        </div>
      </div>

    </form>
  </div>

  <div modal-footer class="w-100" *ngIf="isNew || canUpdate">
    <button class="btn btn-primary w-100" (click)="isNew ? createSanitaryTemperature() : modalUpdateFrame.open(sanitaryTemperatureForm)" [ngClass]="{ 'is-loading is-loading-sm': loader }" [disabled]="loader || sanitaryTemperatureForm.invalid">
      {{ isNew ? 'Créer' : 'Mettre à jour' }}
    </button>
  </div>
</app-modal-right>

<app-confirm-modal [type]="'update_frame'" #updateFrame>{{ bindModalUpdateFrame(updateFrame) }}</app-confirm-modal>
