import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AllEmployeesModule } from './all-employees/all-employees.module';
import { UpdateEmployeeModule } from './update-employee/update-employee.module';
import { UploadFileModule } from './upload-file/upload-file.module';

import { EmployeesRequestService } from '../../services/employees.request.service';

@NgModule({
  imports: [
    CommonModule,
    AllEmployeesModule,
    UpdateEmployeeModule,
    UploadFileModule
  ],
  providers: [EmployeesRequestService],
})
export class EmployeesModule {}
