import { Routes } from '@angular/router';

import { AllSanitaryCheckpointsComponent } from './all-sanitary-checkpoints/all-sanitary-checkpoints.component';
import { UpdateSanitaryCheckpointComponent } from './update-sanitary-checkpoint/update-sanitary-checkpoint.component';

export const sanitaryCheckpointsRoutes: Routes = [
  {
    path: 'sanitary-checkpoints',
    component: AllSanitaryCheckpointsComponent,
    children: [
      {
        path: 'create',
        component: UpdateSanitaryCheckpointComponent
      },
      {
        path: ':sanitaryCheckpointId',
        component: UpdateSanitaryCheckpointComponent
      }
    ]
  },
];
