<div class="d-flex align-items-center bg-auth border-top border-top-2 border-primary">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-md-5 col-xl-4 my-5">
        <div class="text-center mb-5">
          <img src="../../../assets/img/logo_batiregistre.png" alt="Logo Batiregistre" />
        </div>

        <p class="text-muted text-center">
          404 page non trouvé
        </p>

        <h1 class="display-4 text-center">
          404
        </h1>

        <p class="text-muted text-center">
          La page n'existe pas
        </p>
      </div>
    </div>
  </div>
</div>
