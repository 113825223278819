import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';

import { AllEmployeesComponent } from '../all-employees/all-employees.component';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { EmployeesRequestService } from '../../../services/employees.request.service';

@Component({
  selector: 'app-update-employee',
  templateUrl: './update-employee.component.html',
  styleUrls: ['./update-employee.component.scss']
})
export class UpdateEmployeeComponent implements OnInit, AfterViewInit, OnDestroy {
  canCreate = false;
  canUpdate = false;

  employeeForm: FormGroup;
  id = new FormControl('');
  establishment_registry_id = new FormControl('');
  firstname = new FormControl('', Validators.required);
  lastname = new FormControl('', Validators.required);
  year_entry_service = new FormControl(null);
  role = new FormControl(null);
  qualification = new FormControl(null, Validators.required);
  observation = new FormControl(null);

  errors = {};

  isNew = true;
  loader = false;
  loaderContent = false;

  modalEmployee = null;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private allEmployees: AllEmployeesComponent,
    private employeesService: EmployeesRequestService,
  ) {}

  ngOnInit() {
    this.employeeForm = this.formBuilder.group({
      id: this.id,
      establishment_registry_id: this.establishment_registry_id,
      firstname: this.firstname,
      lastname: this.lastname,
      year_entry_service: this.year_entry_service,
      role: this.role,
      qualification: this.qualification,
      observation: this.observation
    });

    if (this.activatedRoute.snapshot.params.employee_id) {
      this.isNew = false;
      this.getEmployee();
    }
  }

  ngAfterViewInit() {
    this.modalEmployee.open();
  }

  ngOnDestroy() {
    this.modalEmployee.close();
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  getEmployee() {
    let request;
    this.loaderContent = true;

    request = this.employeesService.getEmployee(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.activatedRoute.snapshot.params.employee_id
    );

    request.subscribe(response => {
      if (response['success']) {
        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];

        this.employeeForm.patchValue(response['employee']);

        if (!this.canUpdate) {
          this.employeeForm.disable();
        }
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loaderContent = false;
    });
  }

  createEmployee() {
    let request;
    this.errors = {};
    this.loader = true;

    request = this.employeesService.createEmployee(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.employeeForm.value
    );

    request.subscribe(response => {
      if (response['success']) {
        this.modalEmployee.close();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loader = false;
    });
  }

  updateEmployee() {
    let request;
    let employee;

    this.errors = {};
    this.loader = true;

    employee = {
      ...this.employeeForm.value,
      id: this.id.value,
      establishment_registry_id: this.establishment_registry_id.value
    };

    request = this.employeesService.updateEmployee(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.activatedRoute.snapshot.params.employee_id,
      employee
    );

    request.subscribe(response => {
      if (response['success']) {
        this.modalEmployee.close();
        CustomNotifierService.getSuccessUpdate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loader = false;
    });
  }

  bindModal(modal) {
    this.modalEmployee = modal;
  }

  closeModal() {
    this.router.navigate([this.activatedRoute.snapshot.parent.url.join('/')]).then(() => {
      this.allEmployees.refreshData();
    });
  }
}
