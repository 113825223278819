import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Subject } from 'rxjs';
import { NgbTimeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { NgbTimeStringAdapter } from '../../../core/utils/ngb-time-string-adapter';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { UsersRequestService } from '../../../services/users.request.service';

import { AllFirePermitsComponent } from '../all-fire-permits/all-fire-permits.component';
import { FirePermitsRequestService } from '../../../services/fire.permits.request.service';

import BatiFile from '../../../../shared/interfaces/bati_file';

declare function FlatpickrDateTime(): any;
declare function Dropzones(): any;

@Component({
  selector: 'app-update-fire-permit',
  templateUrl: './update-fire-permit.component.html',
  styleUrls: ['./update-fire-permit.component.scss'],
  providers: [{ provide: NgbTimeAdapter, useClass: NgbTimeStringAdapter }]
})
export class UpdateFirePermitComponent implements OnInit, AfterViewInit, OnDestroy {
  canCreate = false;
  canUpdate = false;

  requestInProgress = new Subject();
  requestCount = 0;

  firePermitForm: FormGroup;
  referency = new FormControl('');
  begin_date = new FormControl('');
  end_date = new FormControl('');
  place = new FormControl('');
  details = new FormControl('');
  nature = new FormControl('');
  tools = new FormControl('');
  has_prevention = new FormControl(false);
  ref_prevention = new FormControl('');
  risks = new FormControl('');
  action_prevention = new FormControl('');
  action_alert = new FormControl('');
  emergencies = new FormControl('');
  patrol_required = new FormControl(false);
  patrol_description = new FormControl('');
  originator_name = new FormControl('');
  originator_function = new FormControl('');
  originator_phone = new FormControl('');
  security_provider_name = new FormControl('');
  security_provider_function = new FormControl('');
  security_provider_phone = new FormControl('');
  contributor_name = new FormControl('');
  contributor_function = new FormControl('');
  contributor_phone = new FormControl('');
  actions = [];

  files: BatiFile[] = [];
  enterprises: any = [];
  user: string;

  errors = {};

  isNew = true;
  loader = false;
  loaderContent = false;

  modalFirePermit = null;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private allFirePermits: AllFirePermitsComponent,
    private usersService: UsersRequestService,
    private firePermitsService: FirePermitsRequestService,
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loaderContent = this.requestCount > 0;
    });

    this.firePermitForm = this.formBuilder.group({
      referency: this.referency,
      begin_date: this.begin_date,
      end_date: this.end_date,
      place: this.place,
      details: this.details,
      nature: this.nature,
      tools: this.tools,
      has_prevention: this.has_prevention,
      ref_prevention: this.ref_prevention,
      risks: this.risks,
      action_prevention: this.action_prevention,
      action_alert: this.action_alert,
      emergencies: this.emergencies,
      patrol_required: this.patrol_required,
      patrol_description: this.patrol_description,
      originator_name: this.originator_name,
      originator_function: this.originator_function,
      originator_phone: this.originator_phone,
      security_provider_name: this.security_provider_name,
      security_provider_function: this.security_provider_function,
      security_provider_phone: this.security_provider_phone,
      contributor_name: this.contributor_name,
      contributor_function: this.contributor_function,
      contributor_phone: this.contributor_phone,
    });

    if (this.activatedRoute.snapshot.params.fire_permit_id) {
      this.isNew = false;
      this.getFirePermit();
    } else {
      this.getActions();
    }
  }

  ngAfterViewInit() {
    Dropzones();
    this.modalFirePermit.open();
  }

  ngOnDestroy() {
    const flatPicker = document.getElementsByClassName('flatpickr-calendar') as HTMLCollection;
    for (let i = flatPicker.length; i >= 0; i--) {
      if (flatPicker[i]) {
        flatPicker[i].remove();
      }
    }

    const dropZones = document.getElementsByClassName('dz-hidden-input') as HTMLCollection;
    for (let i = 0; i < dropZones.length; i++) {
      dropZones[i].remove();
    }

    this.modalFirePermit.close();
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  getActions() {
    this.requestInProgress.next(true);
    this.firePermitsService.getActions('', '', '', '', '').subscribe(response => {
      if (response['success']) {
        const actions = [];
        response['actions'].forEach(action => {
          actions.push(action.name);
        });
        this.actions = actions;
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      FlatpickrDateTime();
      this.requestInProgress.next(false);
    });
  }

  getFirePermit() {
    this.requestInProgress.next(true);
    this.firePermitsService.getFirePermit(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.activatedRoute.snapshot.params.fire_permit_id
    ).subscribe(response => {
      if (response['success']) {
        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];

        this.firePermitForm.patchValue(response['permit']);
        this.firePermitForm.patchValue({
          originator_name: response['permit']['originator']['name'],
          originator_function: response['permit']['originator']['function'],
          originator_phone: response['permit']['originator']['phone'],
          security_provider_name: response['permit']['security_provider']['name'],
          security_provider_function: response['permit']['security_provider']['function'],
          security_provider_phone: response['permit']['security_provider']['phone'],
          contributor_name: response['permit']['contributor']['name'],
          contributor_function: response['permit']['contributor']['function'],
          contributor_phone: response['permit']['contributor']['phone'],
        });
        this.actions = response['permit']['actions'];

        if (!this.canUpdate) {
          this.firePermitForm.disable();
        }
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      FlatpickrDateTime();
      this.requestInProgress.next(false);
    });
  }

  createFirePermit() {
    let request;
    this.errors = {};
    this.loader = true;

    const data = {
      ...this.firePermitForm.value,
      actions: this.actions
    };

    request = this.firePermitsService.createFirePermit(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      data
    );

    request.subscribe(response => {
      if (response['success']) {
        this.isNew = false;
        if (this.files.length) {
          this.createDocuments(response['permit']['id']);
        } else {
          this.loader = false;
          this.modalFirePermit.close();
          CustomNotifierService.getSuccessCreate(this.notifier);
        }
      } else if (response['errors']) {
        this.loader = false;
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(response['errors'], this.firePermitForm, this.notifier);
      } else {
        this.loader = false;
      }
    });
  }

  updateFirePermit() {
    let request;

    this.errors = {};
    this.loader = true;

    const data = {
      ...this.firePermitForm.value,
      actions: this.actions
    };

    request = this.firePermitsService.updateFirePermit(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.activatedRoute.snapshot.params.fire_permit_id,
      data
    );

    request.subscribe(response => {
      if (response['success']) {
        if (this.files.length) {
          this.createDocuments(this.activatedRoute.snapshot.params.fire_permit_id);
        } else {
          this.loader = false;
          this.modalFirePermit.close();
          CustomNotifierService.getSuccessUpdate(this.notifier);
        }
      } else if (response['errors']) {
        this.loader = false;
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(response['errors'], this.firePermitForm, this.notifier);
      } else {
        this.loader = false;
      }
    });
  }

  createDocuments(fire_permit_id) {
    let request;
    this.errors = {};

    request = this.firePermitsService.createFile(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      fire_permit_id,
      this.files
    );

    request.subscribe(response => {
      if (response['success']) {
        this.modalFirePermit.close();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loader = false;
    });
  }

  chooseFile() {
    const element: HTMLElement = document.getElementById('inputFile') as HTMLElement;
    element.click();
  }

  onFileChange(event) {
    if (event.target && event.target.files && event.target.files.length) {
      for (let i = 0; i < event.target.files.length; i++) {
        const reader = new FileReader();
        const file = event.target.files[i];
        reader.readAsDataURL(file);

        reader.onload = () => {
          let extension = '';
          let name = '';

          const split = file.name.split('.');
          if (split.length) {
            name = split[0];
            extension = split[split.length - 1];
          }

          this.files.push({ file_base64: reader.result, name: name, extension: extension });
        };
      }
    } else {
      let extension = '';
      let name = '';

      const split = event.name.split('.');
      if (split.length) {
        name = split[0];
        extension = split[split.length - 1];
      }

      this.files.push({ file_base64: event.file, name: name, extension: extension });
    }
    const element: HTMLInputElement = document.getElementById('inputFile') as HTMLInputElement;
    element.value = '';
  }

  removeFile(index) {
    this.files.splice(index, 1);

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  bindModal(modal) {
    this.modalFirePermit = modal;
  }

  closeModal() {
    this.router.navigate([this.activatedRoute.snapshot.parent.url.join('/')]).then(() => {
      this.allFirePermits.refreshData();
    });
  }

  changeHasPrevention() {
    if (this.has_prevention.value) {
      this.ref_prevention.setValidators(Validators.required);
      this.ref_prevention.updateValueAndValidity();
    } else {
      this.ref_prevention.setValue(null);
      this.ref_prevention.clearValidators();
      this.ref_prevention.updateValueAndValidity();
    }
  }

  changePatrolRequired() {
    if (this.patrol_required.value) {
      this.patrol_description.setValidators(Validators.required);
      this.patrol_description.updateValueAndValidity();
    } else {
      this.patrol_description.setValue(null);
      this.patrol_description.clearValidators();
      this.patrol_description.updateValueAndValidity();
    }
  }
}
