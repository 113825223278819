import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Subject } from 'rxjs';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { AsbestosRequestService } from '../../../services/asbestos.request.service';

import { AllAsbestosWorkComponent } from '../all-asbestos-work/all-asbestos-work.component';

import BatiFile from '../../../../shared/interfaces/bati_file';

declare function Flatpickr(): any;

@Component({
  selector: 'app-update-asbestos-work',
  templateUrl: './update-asbestos-work.component.html',
  styleUrls: ['./update-asbestos-work.component.scss']
})
export class UpdateAsbestosWorkComponent implements OnInit, AfterViewInit, OnDestroy {
  canCreate = false;
  canUpdate = false;

  requestInProgress = new Subject();
  requestCount = 0;

  asbestosWorkForm: FormGroup;
  id = new FormControl('');
  establishment_registry_id = new FormControl('');
  type = new FormControl(null, Validators.required);
  precision_type = new FormControl(null);
  date = new FormControl(null, Validators.required);
  project_manager = new FormControl(null);
  csps = new FormControl(null);
  visual_inspector = new FormControl(null);
  control_office = new FormControl(null);
  amount = new FormControl(null, Validators.min(0));
  localization = new FormControl([]);

  files: BatiFile[] = [];
  localizations: any = [];
  selectedLocalizations: any = [];

  errors = {};

  isNew = true;
  loader = false;
  loaderContent = false;

  modalAsbestosWork = null;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private allAsbestos: AllAsbestosWorkComponent,
    private asbestosService: AsbestosRequestService,
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loaderContent = this.requestCount > 0;
    });

    this.asbestosWorkForm = this.formBuilder.group({
      id: this.id,
      establishment_registry_id: this.establishment_registry_id,
      type: this.type,
      precision_type: this.precision_type,
      date: this.date,
      project_manager: this.project_manager,
      csps: this.csps,
      visual_inspector: this.visual_inspector,
      control_office: this.control_office,
      amount: this.amount,
      localization: this.localization
    });

    if (this.activatedRoute.snapshot.params.asbestos_work_id) {
      this.isNew = false;
      this.getAsbestosWork();
    } else {
      Flatpickr();
    }

    this.getLocalizations();
  }

  ngAfterViewInit() {
    this.modalAsbestosWork.open();
  }

  ngOnDestroy() {
    const flatPicker = document.getElementsByClassName('flatpickr-calendar') as HTMLCollection;
    for (let i = flatPicker.length; i >= 0; i--) {
      if (flatPicker[i]) {
        flatPicker[i].remove();
      }
    }

    this.modalAsbestosWork.close();
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  getLocalizations() {
    let request;
    this.requestInProgress.next(true);

    request = this.asbestosService.getAsbestosLocalizations(
      '', '', '', '', '',
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id
    );

    request.subscribe(response => {
      if (response['success']) {
        response['asbestosLocalizations'].forEach(asbestosLocalizations => {
          asbestosLocalizations.group = true;
          this.localizations.push(asbestosLocalizations);
        });
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  getAsbestosWork() {
    let request;
    this.requestInProgress.next(true);

    request = this.asbestosService.getAsbestosWork(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.activatedRoute.snapshot.params.asbestos_work_id
    );

    request.subscribe(response => {
      if (response['success']) {
        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];

        this.asbestosWorkForm.patchValue(response['asbestosWork']);

        const arrayLocalizations = [];
        response['asbestosWork']['asbestos_detection_localizations'].forEach(localization => {
          arrayLocalizations.push(localization);
          localization.group = true;
          this.localizations.unshift(localization);
          this.selectedLocalizations.push(localization.id);
        });
        this.localization.setValue(arrayLocalizations);

        if (!this.canUpdate) {
          this.asbestosWorkForm.disable();
        }
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      Flatpickr();
      this.requestInProgress.next(false);
    });
  }

  createAsbestosWork() {
    let request;
    this.errors = {};
    this.loader = true;

    request = this.asbestosService.createAsbestosWork(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.asbestosWorkForm.value
    );

    request.subscribe(response => {
      if (response['success']) {
        this.modalAsbestosWork.close();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(response['errors'], this.asbestosWorkForm, this.notifier);
      }
      this.loader = false;
    });
  }

  updateAsbestosWork() {
    let request;
    let asbestosWork;

    this.errors = {};
    this.loader = true;

    asbestosWork = {
      ...this.asbestosWorkForm.value,
      id: this.id.value,
      establishment_registry_id: this.establishment_registry_id.value
    };

    request = this.asbestosService.updateAsbestosWork(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.activatedRoute.snapshot.params.asbestos_work_id,
      asbestosWork
    );

    request.subscribe(response => {
      if (response['success']) {
        this.modalAsbestosWork.close();
        CustomNotifierService.getSuccessUpdate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(response['errors'], this.asbestosWorkForm, this.notifier);
      }
      this.loader = false;
    });
  }

  setLocalizations() {
    this.localization.setValue(this.selectedLocalizations);
  }

  bindModal(modal) {
    this.modalAsbestosWork = modal;
  }

  closeModal() {
    this.router.navigate([this.activatedRoute.snapshot.parent.url.join('/')]).then(() => {
      this.allAsbestos.refreshData();
    });
  }
}
