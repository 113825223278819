import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

import { ModalRightModule } from '../../../core/modal-right/modal-right.module';
import { EditorModule } from '@tinymce/tinymce-angular';
import { AllCheckpointsComponent } from '../all-checkpoints/all-checkpoints.component';
import { TooltipCheckpointComponent } from './tooltip-checkpoint.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    ModalRightModule,
    EditorModule
  ],
  declarations: [TooltipCheckpointComponent],
  providers: [AllCheckpointsComponent]
})

export class TooltipCheckpointModule {}
