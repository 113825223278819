import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { HttpClient } from '@angular/common/http';
import { RequestService } from './request.service';

@Injectable()
export class WorksheetsRequestService extends RequestService{
  constructor(
    protected http: HttpClient,
    protected notifier: NotifierService
  ) {
    super(http, notifier);
  }

  /// GET
  public getAllWorksheets(search, sort, order, limit, page, registry_id, establishment_id) {
    return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/worksheets?' +
      'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getAllRequiredWorksheets(search, sort, order, limit, page, registry_id, establishment_id, worksheet_id) {
    if (establishment_id) {
      return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/worksheet/' + worksheet_id + '?' +
        'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
    } else {
      return this.get('registries/' + registry_id + '/file-types?' +
        'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
    }
  }
  public getAllFiles(search, sort, order, limit, page, registry_id, establishment_id, worksheet_id, file_type_id) {
    return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/worksheet/' + worksheet_id +
      '/file-type/' + file_type_id + '/files?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getWorksheet(registry_id, establishment_id, worksheet_id) {
    return this.get(
      'dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/worksheets/' + worksheet_id);
  }
  public getRequiredWorksheet(registry_id, file_type_id) {
    return this.get('registries/' + registry_id + '/file-types/' + file_type_id);
  }
  public getFile(registry_id, establishment_id, worksheet_id, file_type_id, file_id) {
    return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id +
    '/worksheet/' + worksheet_id + '/file-type/' + file_type_id + '/file/' + file_id);
  }

  /// CREATE
  public createWorksheet(registry_id, establishment_id, worksheet) {
    return this.post('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/worksheets', { worksheet });
  }
  public createRequiredWorksheet(registry_id, requiredWorksheet) {
    return this.post('registries/' + registry_id + '/file-types', { requiredWorksheet });
  }
  public createFile(registry_id, establishment_id, worksheet_id, file_type_id, file) {
    return this.post('dashboard-registry/' + registry_id + '/establishment/' + establishment_id +
      '/worksheet/' + worksheet_id + '/file-type/' + file_type_id + '/file', { file: file });
  }

  /// UPDATE
  public updateWorksheet(registry_id, establishment_id, worksheet_id, worksheet) {
    return this.put('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/worksheets/' + worksheet_id,
      { worksheet });
  }
  public updateRequiredWorksheet(registry_id, file_type_id, requiredWorksheet) {
    return this.put('registries/' + registry_id + '/file-types/' + file_type_id, { requiredWorksheet });
  }
  public updateFile(registry_id, establishment_id, worksheet_id, file_type_id, file_id, file) {
    return this.put('dashboard-registry/' + registry_id + '/establishment/' + establishment_id +
      '/worksheet/' + worksheet_id + '/file-type/' + file_type_id + '/file/' + file_id, { file: file });
  }

  /// DELETE
  public deleteWorksheet(registry_id, establishment_id, worksheet_id) {
    return this.delete('dashboard-registry/' + registry_id + '/establishment/' + establishment_id +
      '/worksheets/' + worksheet_id);
  }
  public deleteFile(registry_id, establishment_id, worksheet_id, file_type_id, file_id) {
    return this.delete('dashboard-registry/' + registry_id + '/establishment/' + establishment_id +
      '/worksheet/' + worksheet_id + '/file-type/' + file_type_id + '/file/' + file_id);
  }

  /// DOWNLOAD
  public downloadFile(registry_id, establishment_id, worksheet_id, file_type_id, file_id) {
    return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id +
      '/worksheet/' + worksheet_id + '/file-type/' + file_type_id + '/file/' + file_id + '/download', { responseType: 'arraybuffer' });
  }
}
