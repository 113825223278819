import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Subject } from 'rxjs';

import { CustomNotifierService } from '../../../services/custom.notifier.service';

import { CheckpointsRequestService } from '../../../services/checkpoints.request.service';
import { InstallationsRequestService } from '../../../services/installations.request.service';
import { RegistriesRequestService } from '../../../services/registries.request.service';

import { AllCheckpointsComponent } from '../all-checkpoints/all-checkpoints.component';

import {
  establishment_types,
  ERP_subtypes,
  IGH_subtypes,
  BHC_categories,
  ERP_categories,
  ERP_first_subcategories,
  ERP_second_subcategories
} from '../../../../shared/establishments';
import Installation from '../../../../shared/interfaces/installation';
import Registry from '../../../../shared/interfaces/registry';
import EstablishmentSubSubtype from '../../../../shared/interfaces/establishment_sub_subtype';
import EstablishmentCategory from '../../../../shared/interfaces/establishment_category';
import EstablishmentSubCategory from '../../../../shared/interfaces/establishment_sub_category';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-update-checkpoint',
  templateUrl: './update-checkpoint.component.html',
  styleUrls: ['./update-checkpoint.component.scss']
})
export class UpdateCheckpointComponent implements OnInit, AfterViewInit, OnDestroy {
  canUpdate = false;

  requestInProgress = new Subject();
  requestCount = 0;

  modalCheckpoint = null;
  previousRoute = '';

  errors = {};
  isNew = true;
  view = '';
  activeTab = 'checkpoint';

  show_subtypes = false;
  subtypes: any;
  show_sub_subtypes = false;
  sub_subtypes: EstablishmentSubSubtype[];
  show_categories = false;
  categories: EstablishmentCategory[];
  show_subcategories = false;
  subcategories: EstablishmentSubCategory[];

  installations: Installation[];
  registries: Registry[];
  establishment_types = establishment_types;
  ERP_subtypes = ERP_subtypes;
  BHC_categories = BHC_categories;
  IGH_subtypes = IGH_subtypes;
  ERP_categories = ERP_categories;
  ERP_first_subcategories = ERP_first_subcategories;
  ERP_second_subcategories = ERP_second_subcategories;

  showReference: SafeHtml = '';

  checkpoint: any;

  checkpointForm: FormGroup;
  id = new FormControl('');
  name = new FormControl('', Validators.required);
  qualification = new FormControl(null, Validators.required);
  registry_id = new FormControl('', Validators.required);
  installation_id = new FormControl('', Validators.required);

  isNewFrequency = true;
  id_frequency: number;
  frequenciesForm: FormGroup;
  frequency_duration = new FormControl('', [Validators.required, Validators.min(0)]);
  frequency = new FormControl('', Validators.required);
  frequency_type = new FormControl('', Validators.required);
  establishment_type_id = new FormControl(null);
  establishment_subtypes = new FormControl([]);
  establishment_sub_subtype_id = new FormControl(null);
  establishment_category_id = new FormControl(null);
  establishment_subcategory_id = new FormControl(null);
  reference = new FormControl(null);

  loader = false;
  loaderContent = false;

  constructor(
    private router: Router,
    public sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private allCheckpoints: AllCheckpointsComponent,
    private checkpointsService: CheckpointsRequestService,
    private installationsService: InstallationsRequestService,
    private registriesService: RegistriesRequestService
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loaderContent = this.requestCount > 0;
    });

    this.previousRoute = this.activatedRoute.snapshot.parent.url.join('/');

    if (this.activatedRoute.snapshot.parent.params.establishment_id) {
      this.view = 'establishment';
    } else if (this.activatedRoute.snapshot.parent.params.installation_id) {
      this.view = 'installation';
    } else if (this.activatedRoute.snapshot.parent.params.registry_id) {
      this.view = 'registry';
    }

    this.checkpointForm = this.formBuilder.group({
      id: this.id,
      name: this.name,
      qualification: this.qualification,
      installation_id: this.installation_id,
      registry_id: this.registry_id,
    });

    this.frequenciesForm = this.formBuilder.group({
      frequency: this.frequency,
      frequency_type: this.frequency_type,
      frequency_duration: this.frequency_duration,
      establishment_type_id: this.establishment_type_id,
      establishment_subtypes: this.establishment_subtypes,
      establishment_sub_subtype_id: this.establishment_sub_subtype_id,
      establishment_category_id: this.establishment_category_id,
      establishment_subcategory_id: this.establishment_subcategory_id,
      reference: this.reference
    });

    if (this.activatedRoute.snapshot.params.checkpoint_id) {
      this.isNew = false;
      this.getCheckpoint();

      this.installation_id.disable();
      this.registry_id.disable();
    }

    this.getInstallations();
    this.getRegistries();


    ERP_subtypes.forEach(value => {
      value['group'] = true;
    });

    IGH_subtypes.forEach(value => {
      value['group'] = true;
    });
  }

  ngAfterViewInit() {
    this.modalCheckpoint.open();
  }

  ngOnDestroy() {
    this.modalCheckpoint.close();
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  getCheckpoint() {
    this.errors = {};
    this.requestInProgress.next(true);

    let request;
    if (this.activatedRoute.snapshot.parent.params.installation_id) {
      request = this.checkpointsService.getCheckpoint(this.activatedRoute.snapshot.params.checkpoint_id);
    } else if (this.activatedRoute.snapshot.parent.params.registry_id) {
      request = this.checkpointsService.getCheckpoint(this.activatedRoute.snapshot.params.checkpoint_id);
    }

    request.subscribe(response => {
      if (response['success']) {
        this.canUpdate = response['canUpdate'];
        if (!this.canUpdate) {
          this.checkpointForm.disable();
        }
        this.checkpoint = response['checkpoint'];
        this.checkpointForm.patchValue(response['checkpoint']);
        this.changeTypeEstablishment(false);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.checkpointForm, this.notifier);
      }
      this.requestInProgress.next(false);
    });

  }

  getInstallations() {
    this.requestInProgress.next(true);
    this.installationsService.getInstallations('', '', '', '', '').subscribe(response => {
      if (response['success']) {
        this.installations = response['installations'];
        if (this.isNew && this.view === 'installation') {
          this.installation_id.patchValue(parseInt(this.activatedRoute.snapshot.parent.params.installation_id, 10));
          this.installation_id.disable();
        }
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  getRegistries() {
    this.requestInProgress.next(true);
    this.registriesService.getCheckpointRegistries().subscribe(response => {
      if (response['success']) {
        this.registries = response['registries'];
        if (this.isNew && this.view === 'registry') {
          this.registry_id.patchValue(parseInt(this.activatedRoute.snapshot.parent.params.registry_id, 10));
          this.registry_id.disable();
        }
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  changeTypeEstablishment(clear = true) {
    this.show_subtypes = false;
    this.show_sub_subtypes = false;
    this.show_categories = false;
    this.show_subcategories = false;

    const type_id = parseInt(this.establishment_type_id.value, 10);
    if (type_id === 1) {
      this.show_subtypes = true;
      this.subtypes = this.ERP_subtypes;

      this.show_sub_subtypes = false;
      this.sub_subtypes = [];

      this.show_categories = true;
      this.categories = this.ERP_categories;

      this.show_subcategories = false;
      this.subcategories = [];
    } else if (type_id === 2) {
      this.show_subtypes = false;
      this.subtypes = [];

      this.show_sub_subtypes = false;
      this.sub_subtypes = [];

      this.show_categories = false;
      this.categories = [];

      this.show_subcategories = false;
      this.subcategories = [];
    } else if (type_id === 3) {
      this.show_subtypes = false;
      this.subtypes = [];

      this.show_sub_subtypes = false;
      this.sub_subtypes = [];

      this.show_categories = true;
      this.categories = this.BHC_categories;

      this.show_subcategories = false;
      this.subcategories = [];
    } else if (type_id === 4) {
      this.show_subtypes = true;
      this.subtypes = this.IGH_subtypes;

      this.show_sub_subtypes = false;
      this.sub_subtypes = [];

      this.show_categories = false;
      this.categories = [];

      this.show_subcategories = false;
      this.subcategories = [];
    }

    if (clear) {
      this.establishment_subtypes.setValue(null);
      this.establishment_sub_subtype_id.setValue(null);
      this.establishment_category_id.setValue(null);
      this.establishment_subcategory_id.setValue(null);
    }
  }

  changeCategoryEstablishment() {
    const category_id = parseInt(this.establishment_category_id.value, 10);
    if (category_id === 1) {
      this.show_subcategories = true;
      this.subcategories = this.ERP_first_subcategories;
    } else if (category_id === 2) {
      this.show_subcategories = true;
      this.subcategories = this.ERP_second_subcategories;
    } else {
      this.show_subcategories = false;
      this.subcategories = [];
    }
  }

  createCheckpoint() {
    this.errors = {};
    this.loader = true;

    if (!this.checkpoint || !this.checkpoint.frequencies) {
      this.checkpoint = { frequencies: [] };
    }
    const checkpoint = { ...this.checkpointForm.value,
      installation_id: this.installation_id.value,
      registry_id: this.registry_id.value,
      frequencies: this.checkpoint.frequencies
    };

    this.checkpointsService.createCheckpoint(checkpoint).subscribe(response => {
      if (response['success']) {
        this.modalCheckpoint.close();
        this.allCheckpoints.refreshData();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.checkpointForm, this.notifier);
      }
      this.loader = false;
    });
  }

  updateCheckpoint() {
    this.errors = {};
    this.loader = true;

    if (!this.checkpoint || !this.checkpoint.frequencies) {
      this.checkpoint = { frequencies: [] };
    }
    const checkpoint = {
      ...this.checkpointForm.value,
      registry_id: this.registry_id.value,
      installation_id: this.installation_id.value,
      frequencies: this.checkpoint.frequencies
    };

    this.checkpointsService.updateCheckpoint(this.id.value, checkpoint).subscribe(response => {
      if (response['success']) {
        this.modalCheckpoint.close();
        this.allCheckpoints.refreshData();
        CustomNotifierService.getSuccessUpdate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.checkpointForm, this.notifier);
      }
      this.loader = false;
    });
  }

  changeFrequency() {
    this.frequency.updateValueAndValidity();
    this.frequency_duration.updateValueAndValidity();
  }

  getTypes(frequency) {
    let str = '';
    frequency.establishment_type_id = parseInt(frequency.establishment_type_id, 10);
    const type = this.establishment_types.find(elem => {
      return elem.id === frequency.establishment_type_id;
    });
    if (type) {
      str += type.name + ' ';
      if (frequency.establishment_subtypes && type.hasSubtypes) {
        str += '- ';
        frequency.establishment_subtypes.forEach(establishment_subtype => {
          establishment_subtype = parseInt(establishment_subtype, 10);
          const subtype = type.subtypes.find(element => {
            return element.id === establishment_subtype;
          });
          str += subtype.name + ' ';
        });
      }
      if (frequency.establishment_category_id && type.hasCategories) {
        str += '- ';
        frequency.establishment_category_id = parseInt(frequency.establishment_category_id, 10);
        const category = type.categories.find(element => {
          return element.id === frequency.establishment_category_id;
        });
        str += category.name + ' ';

        if (frequency.establishment_subcategory_id && category.hasSubCategories) {
          str += '- ';
          frequency.establishment_subcategory_id = parseInt(frequency.establishment_subcategory_id, 10);
          const subcategory = category.subCategories.find(element => {
            return element.id === frequency.establishment_subcategory_id;
          });
          str += subcategory.name + ' ';
        }
      }
    }
    return str;
  }

  getReference(reference) {
    this.showReference = this.sanitizer.bypassSecurityTrustHtml(reference);
    return this.showReference;
  }

  createFrequency() {
    if (!this.checkpoint) {
      this.checkpoint = { frequencies: [] };
    } else if (this.checkpoint.frequencies === null) {
      this.checkpoint.frequencies = [];
    }

    if (this.establishment_subtypes.value) {
      this.frequenciesForm.value.establishment_subtypes = this.establishment_subtypes.value;
    }
    this.checkpoint.frequencies.push(this.frequenciesForm.value);

    this.establishment_type_id.setValue(null);
    this.establishment_subtypes.setValue([]);
    this.frequency_duration.setValue('');
    this.frequency_type.setValue('');
    this.frequency.setValue('');
    this.reference.setValue('');
    this.changeTypeEstablishment();
  }

  updateFrequency() {
    if (this.establishment_subtypes.value) {
      this.frequenciesForm.value.establishment_subtypes = this.establishment_subtypes.value;
    }
    this.checkpoint.frequencies[this.id_frequency] = (this.frequenciesForm.value);

    this.isNewFrequency = true;
    this.id_frequency = null;

    this.establishment_type_id.setValue(null);
    this.establishment_subtypes.setValue([]);
    this.frequency_duration.setValue('');
    this.frequency_type.setValue('');
    this.frequency.setValue('');
    this.reference.setValue('');
    this.changeTypeEstablishment();
  }

  editFrequency(frequency, key) {
    this.isNewFrequency = false;
    this.id_frequency = key;
    this.frequenciesForm.patchValue(frequency);
    this.changeTypeEstablishment(false);
  }

  cancelEditFrequency() {
    this.isNewFrequency = true;
    this.id_frequency = null;

    this.establishment_type_id.setValue(null);
    this.establishment_subtypes.setValue([]);
    this.frequency_duration.setValue(null);
    this.frequency_type.setValue(null);
    this.frequency.setValue(null);
    this.reference.setValue(null);
    this.changeTypeEstablishment();
  }

  deleteFrequency(key) {
    this.checkpoint.frequencies.splice(key, 1);

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  binModal(modal) {
    this.modalCheckpoint = modal;
  }

  closeModal() {
    this.allCheckpoints.refreshData();
    this.router.navigate([this.previousRoute]).then(() => {});
  }

  changeTabs(activeTab) {
    this.activeTab = activeTab;
  }
  prevTabs() {
    this.activeTab = 'checkpoint';
  }
  nextTabs() {
    this.activeTab = 'frequencies';
  }
}
