import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';

import { AllRolesComponent } from '../all-roles/all-roles.component';
import { CustomNotifierService } from '../../../services/custom.notifier.service';

import { RolesRequestService } from '../../../services/roles.request.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-update-role',
  templateUrl: './update-role.component.html',
  styleUrls: ['./update-role.component.scss']
})
export class UpdateRoleComponent implements OnInit, AfterViewInit, OnDestroy {
  canUpdate = false;

  requestInProgress = new Subject();
  requestCount = 0;

  roleForm: FormGroup;
  id = new FormControl('');
  name = new FormControl('', Validators.required);
  description = new FormControl('');
  order_number = new FormControl('', Validators.required);
  can_receive_alert = new FormControl(false);
  is_public_visible = new FormControl(true);
  permissions = new FormControl([]);
  created = new FormControl('');
  updated = new FormControl('');

  errors = {};

  isNew = true;
  loader = false;
  loaderContent = false;

  modalRole = null;

  rights: any = [];
  roles: any = [];
  selectedRights: any = [];

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private allRoles: AllRolesComponent,
    private rolesService: RolesRequestService
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loaderContent = this.requestCount > 0;
    });

    this.roleForm = this.formBuilder.group({
      id: this.id,
      name: this.name,
      description: this.description,
      order_number: this.order_number,
      can_receive_alert: this.can_receive_alert,
      is_public_visible: this.is_public_visible,
      permissions: this.permissions,
      created: this.created,
      updated: this.updated
    });

    if (this.activatedRoute.snapshot.params.role_id) {
      this.isNew = false;
      this.getRole(this.activatedRoute.snapshot.params.role_id);
    }

    this.getRights();
  }

  ngAfterViewInit() {
    this.modalRole.open();
  }

  ngOnDestroy() {
    this.modalRole.close();
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  getRights() {
    this.requestInProgress.next(true);

    this.rolesService.getRights().subscribe(response => {
      if (response['success']) {
        this.rights = response['rights'];
      }
      this.requestInProgress.next(false);
    });
  }

  getRole(role_id) {
    this.errors = {};
    this.requestInProgress.next(true);

    this.rolesService.getRole(role_id).subscribe(response => {
      if (response['success']) {
        if (role_id !== '1') {
          this.canUpdate = response['canUpdate'];
        }

        response['role']['permissions'].forEach(permission => {
          this.selectedRights.push(permission.action_group_id);
        }, this);

        this.roleForm.patchValue(response['role']);

        if (!this.canUpdate) {
          this.roleForm.disable();
        }
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.roleForm, this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  createRole() {
    this.errors = {};
    this.loader = true;

    for (const key in this.selectedRights) {
      if (this.selectedRights.hasOwnProperty(key)) {
        this.permissions.value.push({action_group_id: this.selectedRights[key]});
      }
    }

    this.rolesService.createRole(this.roleForm.value).subscribe(response => {
      if (response['success']) {
        this.modalRole.close();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.roleForm, this.notifier);
      }
      this.loader = false;
    });
  }

  updateRole() {
    this.errors = {};
    this.loader = true;
    const subtypes = [];

    for (const key in this.selectedRights) {
      if (this.selectedRights.hasOwnProperty(key)) {
        const find = this.permissions.value.find(element => {
          return element.action_group_id === this.selectedRights[key];
        });
        if (find) {
          subtypes.push(find);
        } else {
          subtypes.push({action_group_id: this.selectedRights[key]});
        }
      }
    }

    this.permissions.setValue(subtypes);

    this.rolesService.updateRole(this.id.value, this.roleForm.value).subscribe(response => {
      if (response['success']) {
        this.modalRole.close();
        CustomNotifierService.getSuccessUpdate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.roleForm, this.notifier);
      }
      this.loader = false;
    });
  }

  binModal(modal) {
    this.modalRole = modal;
  }

  closeModal() {
    this.allRoles.getRoles();
    this.router.navigate(['roles']).then(() => {});
  }
}
