<app-modal-right (eventOnClose)="closeModal()" checkEditClose="false" #downloadModal>{{ bindModal(downloadModal) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">Export</h6>

        <h1 class="header-title">Export du document unique</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <div *ngIf="!loaderContent">
      <div class="row">
        <div class="col-12">
          <div class="form-group" *ngIf="isNew && !loaderContent">
            <label>Établissement a choisir</label>
            <ng-select [items]="establishments"
                       [multiple]="true"
                       bindLabel="name"
                       bindValue="id"
                       groupBy="group"
                       notFoundText="Aucun résultat"
                       [selectableGroup]="true"
                       [selectableGroupAsModel]="false"
                       [closeOnSelect]="false"
                       placeholder="Sélectionnez un/des établissement(s)"
                       [(ngModel)]="selectedEstablishments" [ngModelOptions]="{ standalone: true }" [disabled]="!isNew && !canUpdate">
              <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                <input type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }"/> Sélectionnez tout
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="type" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }" /> {{ item.name }}
              </ng-template>
            </ng-select>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div modal-footer class="w-100">
    <button class="btn btn-primary w-100" (click)="export()" [ngClass]="{ 'is-loading is-loading-sm': loader }" [disabled]="loader || !selectedEstablishments">
      Exporter
    </button>
  </div>
</app-modal-right>
