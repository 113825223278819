import { Component, OnDestroy, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { Subject, Subscription } from 'rxjs';

import { AuthService } from '../../../core/auth/auth.service';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { NotificationsRequestService } from '../../../services/notifications.request.service';
import { UsersRequestService } from '../../../services/users.request.service';
import { EnterprisesRequestService } from '../../../services/enterprises.request.service';
import { ActivatedRoute } from '@angular/router';

import { DomSanitizer } from '@angular/platform-browser';
import { RolesRequestService } from '../../../services/roles.request.service';

@Component({
  selector: 'app-observations',
  templateUrl: './observations.component.html',
  styleUrls: ['./observations.component.scss']
})
export class ObservationsComponent implements OnInit, OnDestroy {
  changeEnterprise: Subscription;

  requestInProgress = new Subject();
  requestCount = 0;

  canCreate = false;
  canUpdate = false;
  canDelete = false;

  notifications = [];
  updateNotification = [];
  selectedAllRegistriesRole: any = [];
  selectedRegistriesRole: any = [];
  selectedAllRegistriesUser: any = [];
  selectedRegistriesUser: any = [];

  pageName: string;
  previousPageName: string[] = [];
  previousPageRoute: string[] = [];

  roles = [];
  users = [];
  enterprise: any;

  view = '';
  type = '';
  currentRoute = '';
  timeout: any;

  loader = false;

  errors;

  registriesRoles = [
    { number: 1, registry_id: 1, title: 'Registre de Sécurité', name: 'Les rapports de contrôle', type: 'checkpoints'},
    { number: 2, registry_id: 1, title: 'Registre de Sécurité', name: 'Les rapports de la commission de sécurité', type: 'commissions'},
    { number: 3, registry_id: 1, title: 'Registre de Sécurité', name: 'Les rapports du RUS', type: 'officials'},
    { number: 4, registry_id: 1, title: 'Registre de Sécurité', name: 'Les RVRATs', type: 'rvrats'},
    { number: 5, registry_id: 6, title: 'Registre Unique de Sécurité', name: 'Les rapports de contrôle', type: 'checkpoints' },
    { number: 6, registry_id: 6, title: 'Registre Unique de Sécurité', name: 'Les rapports d\'inspection', type: 'commissions' },
    { number: 7, registry_id: 4, title: 'Registre APSAD', name: 'Les rapports de contrôle', type: 'checkpoints' },
    { number: 8, registry_id: 4, title: 'Registre APSAD', name: 'Les rapports d\'expertise', type: 'commissions' },
    { number: 9, registry_id: 3, title: 'Registre ICPE', name: 'Les rapports de contrôle', type: 'checkpoints'},
    { number: 10, registry_id: 3, title: 'Registre ICPE', name: 'Les rapports d\'inspection', type: 'commissions' },
    { number: 11, registry_id: 5, title: 'Mon Registre', name: 'Les rapports de contrôle', type: 'checkpoints' },
    { number: 12, registry_id: 8, title: 'Document Unique', name: 'Les rapports d\'inspection', type: 'commissions' },
  ];
  registriesUsers = [
    { number: 1, registry_id: 1, title: 'Registre de Sécurité', name: 'Les rapports de contrôle', type: 'checkpoints'},
    { number: 2, registry_id: 1, title: 'Registre de Sécurité', name: 'Les rapports de la commission de sécurité', type: 'commissions'},
    { number: 3, registry_id: 1, title: 'Registre de Sécurité', name: 'Les rapports du RUS', type: 'officials'},
    { number: 4, registry_id: 1, title: 'Registre de Sécurité', name: 'Les RVRATs', type: 'rvrats'},
    { number: 5, registry_id: 6, title: 'Registre Unique de Sécurité', name: 'Les rapports de contrôle', type: 'checkpoints' },
    { number: 6, registry_id: 6, title: 'Registre Unique de Sécurité', name: 'Les rapports d\'inspection', type: 'commissions' },
    { number: 7, registry_id: 4, title: 'Registre APSAD', name: 'Les rapports de contrôle', type: 'checkpoints' },
    { number: 8, registry_id: 4, title: 'Registre APSAD', name: 'Les rapports d\'expertise', type: 'commissions' },
    { number: 9, registry_id: 3, title: 'Registre ICPE', name: 'Les rapports de contrôle', type: 'checkpoints'},
    { number: 10, registry_id: 3, title: 'Registre ICPE', name: 'Les rapports d\'inspection', type: 'commissions' },
    { number: 11, registry_id: 5, title: 'Mon Registre', name: 'Les rapports de contrôle', type: 'checkpoints' },
    { number: 12, registry_id: 8, title: 'Document Unique', name: 'Les rapports d\'inspection', type: 'commissions' },
  ];

  compareRegistries = ObservationsComponent.compareRegistries;
  static compareRegistries(c1: any, c2: any) {
    if (!c1 || !c2) {
      return false;
    }
    return c1.number === c2.number;
  }

  constructor(
    private sanitizer: DomSanitizer,
    private authService: AuthService,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private rolesServices: RolesRequestService,
    private usersServices: UsersRequestService,
    private enterpriseService: EnterprisesRequestService,
    private notificationService: NotificationsRequestService,
  ) {
  }

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loader = this.requestCount > 0;
    });

    this.pageName = 'Notification';
    this.view = 'mine';
    this.type = this.activatedRoute.snapshot.url[2].path;
    const connectedAs = localStorage.getItem('connectedAs');
    if (connectedAs) {
      this.enterprise = JSON.parse(localStorage.getItem('connectedAsEnterprise'));
    } else {
      this.enterprise = JSON.parse(localStorage.getItem('enterprise'));
    }

    this.previousPageName.push('Tableau de bord');
    this.previousPageRoute.push('/');
    this.getUsers();
    this.getRoles();

    this.changeEnterprise = this.authService.getEnterprise().subscribe(() => {
      this.getNotifications();
    });

    if (this.authService.loaded) {
      this.getNotifications();
    }
  }

  ngOnDestroy() {
    this.changeEnterprise.unsubscribe();

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  getRoles() {
    let request;
    this.requestInProgress.next(true);
    request = this.notificationService.getRoles(this.enterprise.id);
    request.subscribe(response => {
      if (response['success']) {
        response['roles'].forEach( element => {
          const value = {
            id: element.id,
            name: element.name,
            is_super_user: element.is_super_user,
            registries: [],
            send_email: false,
            send_notification: false,
          };
          this.roles.push(value);
        });
      }
      this.requestInProgress.next(false);
      this.updateRoleLists();
    });
  }
  getUsers() {
    let request;
    this.requestInProgress.next(true);
    request = this.notificationService.getEnterpriseUsers('', '', '', '', '', this.enterprise.id);
    request.subscribe(response => {
      if (response['success']) {
        response['users'].forEach( element => {
          const value = {
            id: element.id,
            name: element.full_name,
            is_super_user: element.last_user_enterprise.role.is_super_user,
            category: element.last_user_enterprise.role.name,
            registries: [],
            send_email: false,
            send_notification: false,
          };
          this.users.push(value);
        });
      }
      this.requestInProgress.next(false);
      this.updateUserLists();
    });
  }
  getNotifications() {
    this.requestInProgress.next(true);
    let request;
    request = this.notificationService.getNotifications('', '', '', '', '', this.type);
    request.subscribe(response => {
      if (response['success']) {
        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];
        this.canDelete = response['canDelete'];
        this.notifications = response['notifications'];
        this.updateUserLists();
        this.updateRoleLists();
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  update() {
    this.updateNotification = [];
    this.users.forEach( user => {
      this.updateNotification.push({
        enterprise_id: this.enterprise.id,
        tiles: 'Observations',
        users: [{
          id: user.id,
          name: user.name,
          category: user.category
        }],
        roles: [],
        registries: user.registries,
        send_email: user.send_email,
        send_notification: user.send_notification,
      });
    });
    this.roles.forEach( role => {
      this.updateNotification.push({
        enterprise_id: this.enterprise.id,
        tiles: 'Observations',
        users: [],
        roles: [{
          id: role.id,
          name: role.name,
        }],
        registries: role.registries,
        send_email: role.send_email,
        send_notification: role.send_notification,
      });
    });
    let request;
    this.loader = true;
    request = this.notificationService.createAlerts(this.updateNotification);

    request.subscribe(response => {
      if (response['success']) {
        this.getNotifications();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrors(this.errors, this.notifier);
      }
      this.loader = false;
    });
  }

  updateAllRoles() {
    this.selectedRegistriesRole = [];
    this.roles.forEach(item => {
      item.registries = this.selectedAllRegistriesRole;
      this.selectedRegistriesRole.push(this.selectedAllRegistriesRole);
    });
  }
  updateRole(index) {
    this.roles[index]['registries'] = [];
    this.roles[index]['registries'] = this.selectedRegistriesRole[index];
  }
  updateAllUser() {
    this.selectedRegistriesUser = [];
    this.users.forEach(item => {
      item.registries = this.selectedAllRegistriesUser;
      this.selectedRegistriesUser.push(this.selectedAllRegistriesUser);
    });
  }
  updateUser(index) {
    this.users[index]['registries'] = [];
    this.users[index]['registries'] = this.selectedRegistriesUser[index];
  }

  getAllCheckedEmail(item) {
    let count = 0;
    item.forEach( value => {
      if (value.send_email) {
        count += 1;
      }
    });
    return count === item.length;
  }
  checkAllEmail(item, checked) {
    item.forEach(value => {
      value.send_email = checked;
    });
  }
  checkEmail(item) {
    item.send_email = !item.send_email;
  }
  getAllCheckedNotification(item) {
    let count = 0;
    item.forEach( value => {
      if (value.send_notification) {
        count += 1;
      }
    });
    return count === item.length;
  }
  checkAllNotification(item, checked) {
    item.forEach(value => {
      value.send_notification = checked;
    });
  }
  checkNotification(item) {
    item.send_notification = !item.send_notification;
  }

  updateUserLists() {
    if (this.notifications.length && this.users.length) {
      this.notifications.forEach( (notification) => {
        if (notification.users.length) {
          notification.users.forEach(user => {
            const foundIndex = this.users.findIndex(element => {
              return element.id === user.id;
            });
            const found = this.users.find(element => {
              return element.id === user.id;
            });
            if (found !== undefined) {
              this.selectedRegistriesUser[foundIndex] = notification.registries;
              found.registries = notification.registries;
              found.send_email = notification.send_email;
              found.send_notification = notification.send_notification;
            }
          });
        }
      });
      const isValid = this.selectedRegistriesUser.every(item => JSON.stringify(item) === JSON.stringify((this.selectedRegistriesUser[0])));
      if (isValid) {
        this.selectedAllRegistriesUser = this.selectedRegistriesUser[0];
      } else {
        this.selectedAllRegistriesUser = [];
      }
    }
  }
  updateRoleLists() {
    if (this.notifications.length && this.roles.length) {
      this.notifications.forEach( (notification) => {
        if (notification.roles.length) {
          notification.roles.forEach(role => {
            const foundIndex = this.roles.findIndex(element => {
              return element.id === role.id;
            });
            const found = this.roles.find(element => {
              return element.id === role.id;
            });
            if (found !== undefined) {
              this.selectedRegistriesRole[foundIndex] = notification.registries;
              found.registries = notification.registries;
              found.send_email = notification.send_email;
              found.send_notification = notification.send_notification;
            }
          });
        }
      });
      const isValid = this.selectedRegistriesRole.every(item => JSON.stringify(item) === JSON.stringify((this.selectedRegistriesRole[0])));
      if (isValid) {
        this.selectedAllRegistriesRole = this.selectedRegistriesRole[0];
      } else {
        this.selectedAllRegistriesRole = [];
      }
    }
  }

}
