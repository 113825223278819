import { Injectable } from "@angular/core";
import { RequestService } from "./request.service";
import { HttpClient } from "@angular/common/http";
import { NotifierService } from "angular-notifier";

@Injectable()
export class InterventionsRequestService extends RequestService {
  constructor(protected http: HttpClient, protected notifier: NotifierService) {
    super(http, notifier);
  }

  /// GET
  public getAllInterventions(
    search,
    sort,
    order,
    limit,
    page,
    selectedEstablishments,
    selectedCheckpoints
  ) {
    return this.get(
      "interventions?" +
        "search=" +
        search +
        "&sort=" +
        sort +
        "&order=" +
        order +
        "&limit=" +
        limit +
        "&page=" +
        page +
        "&selectedEstablishments=" +
        selectedEstablishments +
        "&selectedCheckpoints=" +
        selectedCheckpoints
    );
  }
  public getIntervention(intervention_id) {
    return this.get("interventions/" + intervention_id);
  }
  public getInterventionDetail(intervention_id) {
    return this.get("interventions/" + intervention_id + "/details");
  }
  public getFiles(search, sort, order, limit, page, intervention_id) {
    return this.get(
      "interventions/" +
        intervention_id +
        "/files?" +
        "search=" +
        search +
        "&sort=" +
        sort +
        "&order=" +
        order +
        "&limit=" +
        limit +
        "&page=" +
        page
    );
  }
  public getFile(intervention_id, file_id) {
    return this.get("interventions/" + intervention_id + "/files/" + file_id);
  }

  /// CREATE
  public createPrescriptionIntervention(
    registry_id,
    commission_id,
    prescription_id,
    intervention
  ) {
    return this.post(
      "dashboard-registry/" +
        registry_id +
        "/commissions/" +
        commission_id +
        "/prescriptions/" +
        prescription_id +
        "/interventions",
      { intervention }
    );
  }
  public createPrescriptionFile(
    registry_id,
    commission_id,
    prescription_id,
    intervention_id,
    file
  ) {
    return this.post(
      "dashboard-registry/" +
        registry_id +
        "/commissions/" +
        commission_id +
        "/prescriptions/" +
        prescription_id +
        "/interventions/" +
        intervention_id +
        "/files",
      { file: file }
    );
  }
  public createObservationIntervention(
    registry_id,
    report_id,
    observation_id,
    intervention
  ) {
    return this.post(
      "dashboard-registry/" +
        registry_id +
        "/reports/" +
        report_id +
        "/observations/" +
        observation_id +
        "/interventions",
      { intervention }
    );
  }
  public createObservationFile(
    registry_id,
    report_id,
    observation_id,
    intervention_id,
    file
  ) {
    return this.post(
      "dashboard-registry/" +
        registry_id +
        "/reports/" +
        report_id +
        "/observations/" +
        observation_id +
        "/interventions/" +
        intervention_id +
        "/files",
      { file: file }
    );
  }
  public createOfficialPrescriptionIntervention(
    registry_id,
    official_id,
    prescription_id,
    intervention
  ) {
    return this.post(
      "dashboard-registry/" +
        registry_id +
        "/officials/" +
        official_id +
        "/prescriptions/" +
        prescription_id +
        "/interventions",
      { intervention }
    );
  }
  // todo
  public createOfficialPrescriptionFile(
    registry_id,
    official_id,
    prescription_id,
    intervention_id,
    file
  ) {
    return this.post(
      "dashboard-registry/" +
        registry_id +
        "/officials/" +
        official_id +
        "/prescriptions/" +
        prescription_id +
        "/interventions/" +
        intervention_id +
        "/files",
      { file: file }
    );
  }
  public createRvratPrescriptionIntervention(registry_id, rvrat_id, rvrat_prescription_id, intervention) {
    return this.post('dashboard-registry/' + registry_id + '/rvrat/' + rvrat_id +
      '/rvrat-prescriptions/' + rvrat_prescription_id + '/interventions', { intervention });
  }
  public createRvratPrescriptionFile(registry_id, rvrat_id, rvrat_prescription_id, intervention_id, file) {
    return this.post('dashboard-registry/' + registry_id + '/rvrat/' + rvrat_id +
      '/rvrat-prescriptions/' + rvrat_prescription_id + '/interventions/' + intervention_id + '/files', { file: file });
  }
  public createFile(intervention_id, file) {
    return this.post("interventions/" + intervention_id + "/files", {
      file: file,
    });
  }

  /// UPDATE
  public updateIntervention(intervention_id, intervention) {
    return this.put("interventions/" + intervention_id, { intervention });
  }
  public updateFile(intervention_id, file_id, file) {
    return this.put("interventions/" + intervention_id + "/files/" + file_id, {
      file: file,
    });
  }

  /// DELETE
  public deleteIntervention(intervention_id) {
    return this.delete("interventions/" + intervention_id);
  }
  public deleteFile(intervention_id, file_id) {
    return this.delete(
      "interventions/" + intervention_id + "/files/" + file_id
    );
  }

  /// DOWNLOAD
  public downloadPdf(intervention_id) {
    return this.get("interventions/" + intervention_id + "/download", {
      responseType: "arraybuffer",
    });
  }
  public downloadFile(intervention_id, file_id) {
    return this.get(
      "interventions/" + intervention_id + "/files/" + file_id + "/download",
      { responseType: "arraybuffer" }
    );
  }
}
