import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AllRvratPrescriptionsModule } from './all-rvrat-prescriptions/all-rvrat-prescriptions.module';
import { UpdateRvratPrescriptionModule } from './update-rvrat-prescription/update-rvrat-prescription.module';
import { UpdateRvratPrescriptionStateModule } from './update-rvrat-prescription-state/update-rvrat-prescription-state.module';
import { UploadRvratPrescriptionFileModule } from './upload-rvrat-prescription-file/upload-rvrat-prescription-file.module';

import { RvratPrescriptionsRequestService } from '../../services/rvrat-prescriptions.request.service';

@NgModule({
  imports: [
    CommonModule,
    AllRvratPrescriptionsModule,
    UpdateRvratPrescriptionModule,
    UploadRvratPrescriptionFileModule,
    UpdateRvratPrescriptionStateModule
  ],
  providers: [RvratPrescriptionsRequestService]
})
export class RvratPrescriptionsModule {}
