import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { NotifierService } from 'angular-notifier';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from '../../../core/auth/auth.service';
import { CustomNotifierService } from '../../../services/custom.notifier.service';

import { environment } from '../../../../environments/environment';
import * as moment from 'moment';

@Component({
  selector: 'app-lost-password',
  templateUrl: './lost-password.component.html',
  styleUrls: ['./lost-password.component.scss'],
  animations: []
})
export class LostPasswordComponent implements OnInit {
  showPassword = false;
  isSigningIn = false;
  errors = {};
  expired = false;
  invalid = false;

  token: string = null;

  loginForm: FormGroup;
  email = new FormControl('', [Validators.required, Validators.email]);
  password = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(?:(?=.*[a-z])(?:(?=.*[A-Z])(?=.*[\d\W])|(?=.*\W)(?=.*\d))|(?=.*\W)(?=.*[A-Z])(?=.*\d)).{8,}$/)
  ]);
  confirm_password = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(?:(?=.*[a-z])(?:(?=.*[A-Z])(?=.*[\d\W])|(?=.*\W)(?=.*\d))|(?=.*\W)(?=.*[A-Z])(?=.*\d)).{8,}$/)
  ]);

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.token = this.activatedRoute.snapshot.params.token;

    this.loginForm = this.formBuilder.group({
      email: this.email
    });

    if (this.token) {
      const split = this.token.split(':');
      if (split.length === 2) {
        const now = moment.utc();
        const time = moment.utc(split[1], 'YYMMDDHHmmss');
        time.add(1, 'week');

        if (now.isBefore(time, 's')) {
          this.loginForm = this.formBuilder.group({
            password: this.password,
            confirm_password: this.confirm_password
          });
        } else {
          this.expired = true;
        }
      } else {
        this.invalid = true;
      }
    }
  }

  toggle() {
    this.showPassword = !this.showPassword;
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  changePassword() {
    this.errors = {};
    this.isSigningIn = true;

    this.http.post(environment.API_PATH + 'auth/change-password', { ...this.loginForm.value, token: this.token })
      .subscribe(response => {
        if (response['success']) {
          CustomNotifierService.getSuccessUpdate(this.notifier);
          this.router.navigate(['/auth/login']).then(() => {});
        } else if (response['errors']['general']['code'] === 'INVALID_PASSWORD_TOKEN') {
          this.invalid = true;
          this.errors = response['errors'];
          setTimeout(() => {
            CustomNotifierService.getErrors(this.errors, this.notifier);
          }, 100);
        } else {
          this.errors = response['errors'];
          CustomNotifierService.getErrorsForm(this.errors, this.loginForm, this.notifier);
        }
        this.isSigningIn = false;
      });
  }

  resendEmail() {
    this.isSigningIn = true;

    this.http.post(environment.API_PATH + 'auth/send-lost', { email: this.email.value})
      .subscribe(response => {
        if (response['success']) {
          CustomNotifierService.getSuccessEmail(this.notifier);
        } else {
          CustomNotifierService.getErrors(response['errors'], this.notifier);
        }
        this.isSigningIn = false;
      });
  }
}
