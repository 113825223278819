import { Routes } from '@angular/router';

import { AllRegistriesComponent } from './all-registries/all-registries.component';
import { DashboardRegistryComponent } from './dashboard-registry/dashboard-registry.component';
import { UpdateRegistryComponent } from './update-registry/update-registry.component';

import { UpdatePrescriptionComponent } from '../prescriptions/update-prescription/update-prescription.component';
import { UpdatePrescriptionStateComponent } from '../prescriptions/update-prescription-state/update-prescription-state.component';

import { UpdateObservationComponent } from '../observations/update-observation/update-observation.component';
import { UpdateObservationStateComponent } from '../observations/update-observation-state/update-observation-state.component';

import { UpdateInterventionComponent } from '../interventions/update-intervention/update-intervention.component';
import { UpdateRegistryManagerComponent } from './update-registry-manager/update-registry-manager.component';

import { UpdateAsbestosDetectionComponent } from '../asbestos/update-asbestos-detection/update-asbestos-detection.component';

import { UpdateReportComponent } from '../reports/update-report/update-report.component';

import { ExportDownloadComponent } from '../export-download/export-download.component';
import { UpdateWorkComponent } from '../report-worksheets/update-work/update-work.component';
import { ModalLogsComponent } from '../logs/modal-logs/modal-logs.component';
import { UpdateOfficialPrescriptionStateComponent } from '../official-prescriptions/update-official-prescription-state/update-official-prescription-state.component';
import { UpdateOfficialPrescriptionComponent } from '../official-prescriptions/update-official-prescription/update-official-prescription.component';
import { ExportUniqueDocComponent } from '../export-unique-doc/export-unique-doc.component';
import { UpdateRvratPrescriptionStateComponent } from '../rvrat-prescriptions/update-rvrat-prescription-state/update-rvrat-prescription-state.component';
import { UpdateRvratPrescriptionComponent } from '../rvrat-prescriptions/update-rvrat-prescription/update-rvrat-prescription.component';

export const registriesRoutes: Routes = [
  {
    path: 'dashboard-registry/:registry_id',
    component: DashboardRegistryComponent,
    children: [
      // PRESCRIPTIONS
      {
        path: 'prescriptions/:prescription_id/state',
        component: UpdatePrescriptionStateComponent
      },
      {
        path: 'commissions/:commission_id/prescriptions/:prescription_id/intervention/create',
        component: UpdateInterventionComponent
      },
      {
        path: 'prescriptions/:prescription_id',
        component: UpdatePrescriptionComponent
      },
      // OFFICIAL PRESCRIPTIONS
      {
        path: 'official-prescriptions/:prescription_id/state',
        component: UpdateOfficialPrescriptionStateComponent
      },
      {
        path: 'official/:official_id/prescriptions/:prescription_id/intervention/create',
        component: UpdateInterventionComponent
      },
      {
        path: 'official-prescriptions/:prescription_id',
        component: UpdateOfficialPrescriptionComponent
      },
      // RVRAT PRESCRIPTIONS
      {
        path: 'rvrat-prescriptions/:rvrat_prescription_id/state',
        component: UpdateRvratPrescriptionStateComponent
      },
      {
        path: 'rvrat/:rvrat_id/rvrat-prescriptions/:rvrat_prescription_id/intervention/create',
        component: UpdateInterventionComponent
      },
      {
        path: 'rvrat-prescriptions/:rvrat_prescription_id',
        component: UpdateRvratPrescriptionComponent
      },
      // OBSERVATIONS
      {
        path: 'observations/:observation_id/state',
        component: UpdateObservationStateComponent
      },
      {
        path: 'reports/:report_id/observations/:observation_id/intervention/create',
        component: UpdateInterventionComponent
      },
      {
        path: 'observations/:observation_id',
        component: UpdateObservationComponent
      },
      // ASBESTOS
      {
        path: 'asbestos-detection-dashboard/:asbestos_detection_id',
        component: UpdateAsbestosDetectionComponent
      },
      // REPORTS
      {
        path: 'checkpoints/:checkpoint_id/reports/create',
        component: UpdateReportComponent
      },
      {
        path: 'installation/:installation_id/checkpoints/:checkpoint_id/reports/:report_id',
        component: UpdateReportComponent
      },
      // REPORT WORKSHEETS
      {
        path: 'report-worksheets/:report_id/works/:work_id',
        component: UpdateWorkComponent
      },
      // DOWNLOAD
      {
        path: 'download',
        component: ExportDownloadComponent
      }
    ]
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id',
    component: DashboardRegistryComponent,
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    children: [
      {
        path: 'prescriptions/:prescription_id/state',
        component: UpdatePrescriptionStateComponent
      },
      {
        path: 'prescriptions/:prescription_id',
        component: UpdatePrescriptionComponent
      },
      {
        path: 'rvrat-prescriptions/:rvrat_prescription_id/state',
        component: UpdateRvratPrescriptionStateComponent
      },
      {
        path: 'rvrat-prescriptions/:rvrat_prescription_id',
        component: UpdateRvratPrescriptionComponent
      },
      {
        path: 'official-prescriptions/:prescription_id/state',
        component: UpdateOfficialPrescriptionStateComponent
      },
      {
        path: 'official-prescriptions/:prescription_id',
        component: UpdateOfficialPrescriptionComponent
      },
      {
        path: 'commissions/:commission_id/prescriptions/:prescription_id/intervention/create',
        component: UpdateInterventionComponent
      },
      {
        path: 'observations/:observation_id/state',
        component: UpdateObservationStateComponent
      },
      {
        path: 'observations/:observation_id',
        component: UpdateObservationComponent
      },
      {
        path: 'reports/:report_id/observations/:observation_id/intervention/create',
        component: UpdateInterventionComponent
      },
      {
        path: 'registry-manager',
        component: UpdateRegistryManagerComponent
      },
      {
        path: 'asbestos-detection-dashboard/:asbestos_detection_id',
        component: UpdateAsbestosDetectionComponent
      },
      {
        path: 'checkpoints/:checkpoint_id/reports/create',
        component: UpdateReportComponent
      },
      {
        path: 'installation/:installation_id/checkpoints/:checkpoint_id/reports/:report_id',
        component: UpdateReportComponent
      },
      {
        path: 'download',
        component: ExportDownloadComponent
      },
      {
        path: 'document/:parent_id/logs',
        component: ModalLogsComponent
      },
      {
        path: 'registry-manager/logs',
        component: ModalLogsComponent
      },
      {
        path: 'employee/logs',
        component: ModalLogsComponent
      },
      {
        path: 'employee-formation/logs',
        component: ModalLogsComponent
      },
      {
        path: 'export_to',
        component: ExportUniqueDocComponent
      },
    ]
  },
  {
    path: 'registries/mine',
    component: AllRegistriesComponent,
  },
  {
    path: 'establishments/mine/:active/:establishment_id/registries',
    component: AllRegistriesComponent,
  },
  {
    path: 'registries',
    component: AllRegistriesComponent,
    children: [
      {
        path: 'create',
        component: UpdateRegistryComponent
      },
      {
        path: ':registry_id',
        component: UpdateRegistryComponent
      }
    ]
  }
];
