import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AllOfficialsModule } from './all-officials/all-officials.module';
import { UpdateOfficialModule } from './update-official/update-official.module';
import { UploadOfficialFileModule } from './upload-official-file/upload-official-file.module';

import { OfficialsRequestService } from '../../services/officials.request.service';

@NgModule({
  imports: [
    CommonModule,
    AllOfficialsModule,
    UpdateOfficialModule,
    UploadOfficialFileModule
  ],
  providers: [
    OfficialsRequestService
  ]
})
export class OfficialsModule {}
