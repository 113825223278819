<app-modal-right (eventOnClose)="closeModal()" #enterprise>{{ bindModal(enterprise) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">Création</h6>

        <h1 class="header-title">Prestataire</h1>
      </div>
    </div>

    <form [formGroup]="searchForm" class="mt-5">
      <div class="form-group">
        <label for="checkSiret" required>Rechercher dans la base par : Nom, SIRET, adresse, ...</label>
        <input type="text" class="form-control" formControlName="search" id="checkSiret" placeholder="Nom, SIRET, adresse, ..." [ngClass]="{ 'is-invalid': search.invalid && (search.dirty || search.touched) }" (keyup)="searchData()">
        <ul class="suggestions">
          <li class="sugg" *ngFor="let suggestion of suggestions" (click)="getSuggestion(suggestion, 1)">{{ suggestion }}</li>
        </ul>
        <div *ngIf="search.invalid && (search.dirty || search.touched)" class="invalid-feedback">
          {{ getErrorMessage("search") }}
        </div>
      </div>
    </form>

    <div class="row card-company-selected" *ngIf="enterpriseSelected">
      <div class="col">
        <div id="{{ enterpriseSelected.id }}">
          <div class="company--name">Selection : {{ enterpriseSelected.nom_raison_sociale }}</div>
          <div class="company--domain">{{ enterpriseSelected.libelle_activite_principale }}</div>
          <div class="company--address">{{ enterpriseSelected.geo_adresse }}</div>
          <div class="company--siret">SIRET : {{ enterpriseSelected.siret }}</div>
          <div class="company--siren">SIREN : {{ enterpriseSelected.siren }}</div>

          <form [formGroup]="enterpriseForm">
            <!-- QUALIFICATION -->
            <div class="form-group mt-3">
              <label for="qualification" required>Qualification</label>
              <select class="form-control" data-toggle="select" formControlName="qualification" id="qualification" [ngClass]="{ 'is-invalid': qualification.invalid && (qualification.dirty || qualification.touched) }">
                <option value="TECHNICIEN">Technicien compétent</option>
                <option value="ORGANISME">Organisme agréé</option>
              </select>
              <div *ngIf="qualification.invalid && (qualification.dirty || qualification.touched)" class="invalid-feedback">
                {{ getErrorMessage("qualification") }}
              </div>
            </div>

            <!-- ACCESS TO ENTERPRISE -->
            <div class="form-group d-flex justify-content-start align-items-center mb-0" *ngIf="!monoOffer && !discoverOffer">
              <div class="col pl-0">
                Donner accès à BatiRegistre
              </div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="validationAccess" formControlName="access_to_enterprise" [ngClass]="{ 'backgroundColor': access_to_enterprise.value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': access_to_enterprise.value }" for="validationAccess"></label>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="col-auto">
        <a class="href tooltipHtml" data-placement="bottom" title="Désélectionner" (click)="unselectEnterprise()">
          <i class="fe fe-trash"></i>
        </a>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>
    <div [hidden]="loaderContent">
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="siret" role="tabpanel" aria-labelledby="tab-siret">
          <div class="row">
            <div class="col-12 col-lg-6 mb-4" *ngFor="let enterprise of enterprises">
              <div class="card-company" id="{{ enterprise.id }}" (click)="selectEnterprise(enterprise)">
                <div class="company--name">{{ enterprise.nom_raison_sociale }}</div>
                <div class="company--domain">{{ enterprise.libelle_activite_principale }}</div>
                <div class="company--address">{{ enterprise.geo_adresse }}</div>
                <div class="company--siret">SIRET : {{ enterprise.siret }}</div>
                <div class="company--siren">SIREN : {{ enterprise.siren }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div modal-footer class="w-100">
    <div class="row">
      <div class="col-12 text-center">
        <nav aria-label="Pagination" class="justify-content-end col-auto m-3">
          <ul class="pagination justify-content-end">
            <li class="page-item" [ngClass]="{ 'disabled': page <= 1 }" (click)="changePage(page - 1)"><a class="page-link">Précédent</a></li>
            <li class="page-item" *ngIf="page - 2 > 1" (click)="changePage(1)"><a class="page-link">1</a></li>

            <li class="page-item" *ngIf="page - 3 > 1"><a class="page-link page-dotted">...</a></li>

            <li class="page-item" *ngIf="page - 2 >= 1" (click)="changePage(page - 2)"><a class="page-link">{{ page - 2 }}</a></li>
            <li class="page-item" *ngIf="page - 1 >= 1" (click)="changePage(page - 1)"><a class="page-link">{{ page - 1 }}</a></li>

            <li class="page-item active"><a class="page-link disabled">{{ page }}</a></li>

            <li class="page-item" *ngIf="page + 1 <= pages" (click)="changePage(page + 1)"><a class="page-link">{{ page + 1 }}</a></li>
            <li class="page-item" *ngIf="page + 2 <= pages" (click)="changePage(page + 2)"><a class="page-link">{{ page + 2 }}</a></li>

            <li class="page-item" *ngIf="page + 3 < pages"><a class="page-link page-dotted">...</a></li>

            <li class="page-item" *ngIf="page + 2 < pages" (click)="changePage(pages)"><a class="page-link">{{ pages }}</a></li>
            <li class="page-item" [ngClass]="{ 'disabled': page >= pages }" (click)="changePage(page + 1)"><a class="page-link" [attr.aria-label]="page >= pages ? 'Bouton suivant : Désactivé, vous êtes déja sur la dernière page' : 'Aller à la page suivante'">Suivant</a></li>
          </ul>
        </nav>
      </div>
    </div>
    <button class="btn btn-primary w-100"
      (click)="addEnterprise()"
      [ngClass]="{ 'is-loading is-loading-sm': loaderSearch }"
      [disabled]="loaderSearch || !enterpriseSelected || enterpriseForm.invalid">
      Ajouter ce prestataire
    </button>
  </div>
</app-modal-right>
