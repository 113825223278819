import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { AllCostsComponent } from './all-costs.component';
import { BreadcrumbsModule } from '../../../../core/breadcrumbs/breadcrumbs.module';
import { FormsModule } from '@angular/forms';
import { SearchbarModule } from '../../../../core/searchbar/searchbar.module';
import { PaginationModule } from '../../../../core/pagination/pagination.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    BreadcrumbsModule,
    FormsModule,
    SearchbarModule,
    PaginationModule
  ],
  declarations: [AllCostsComponent]
})

export class AllCostsModule {}
