import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs';

import { AuthService } from '../../../core/auth/auth.service';
import { environment } from '../../../../environments/environment';
import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { TrainingsRequestService } from '../../../services/trainings.request.service';
import { EstablishmentsRequestService } from '../../../services/establishments.request.service';
import { RegistriesRequestService } from '../../../services/registries.request.service';

import Training from '../../../../shared/interfaces/training';
import DateTraining from '../../../../shared/interfaces/date_training';
import Registry from '../../../../shared/interfaces/registry';
import Establishment from '../../../../shared/interfaces/establishment';

@Component({
  selector: 'app-all-trainings',
  templateUrl: './all-trainings.component.html',
  styleUrls: ['./all-trainings.component.scss']
})
export class AllTrainingsComponent implements OnInit, AfterViewInit {
  changeEnterprise: Subscription;

  environment = environment;
  trainings: Training[] = [];
  date_trainings: DateTraining[] = [];
  training: Training;
  registry: Registry;
  establishment: Establishment;

  requestInProgress = new Subject();
  requestCount = 0;

  canCreate = false;
  canUpdate = false;
  canDelete = false;
  canLog = false;

  previousPageName: string[] = [];
  previousPageRoute: string[] = [];
  updateRoute = '';
  createRoute = '';
  employeesRoute = '';

  count = 0;
  pages = 1;

  search = '';
  sort = '';
  order = 'asc';
  limit = 12;
  page = 1;
  training_name = '';

  timeout: any;
  view = '';
  loader = true;
  errors = {};

  modalConfirm = null;
  modalConfirmData = null;

  show_inactive = false;

  constructor(
    private authService: AuthService,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private trainingsService: TrainingsRequestService,
    private establishmentsService: EstablishmentsRequestService,
    private registriesService: RegistriesRequestService
  ) {
  }

  ngOnInit() {
    const snapshot = this.activatedRoute.snapshot;
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loader = this.requestCount > 0;
    });
    this.defineView();

    this.updateRoute = '/' + snapshot.url.join('/') + '/';
    this.createRoute = '/' + snapshot.url.join('/') + '/create';
    this.sort = this.view === 'dates_training' ? 'date' : 'Trainings.name';
    if (this.view === 'user') {
      this.employeesRoute = '/dashboard-registry/' + snapshot.params.registry_id + '/establishment/' + snapshot.params.establishment_id +
        '/employees-trainings';
      const is_inactive = JSON.parse(localStorage.getItem('show_inactive_trainings'));
      if (is_inactive !== null) {
        this.show_inactive = is_inactive;
      } else {
        localStorage.setItem('show_inactive_trainings', JSON.stringify(this.show_inactive));
      }
    }

    this.changeEnterprise = this.authService.getEnterprise().subscribe(() => {
      if (this.view !== 'admin') {
        this.getRegistry(+snapshot.params.registry_id);
        this.getEstablishment(+snapshot.params.establishment_id);
      }
      if (this.view === 'dates_training') {
        this.getTraining(+snapshot.params.training_id);
      }
      this.getTrainings();
    });

    if (this.authService.loaded) {
      if (this.view !== 'admin') {
        this.getRegistry(+snapshot.params.registry_id);
        this.getEstablishment(+snapshot.params.establishment_id);
      }
      if (this.view === 'dates_training') {
        this.getTraining(+snapshot.params.training_id);
      }
      this.getTrainings();
    }
    this.createBreadcrumbs();
  }

  ngAfterViewInit() {
    this.modalConfirmData.eventOnClose.subscribe(event => {
      if (event) {
        let request;
        this.requestInProgress.next(true);

        if (this.view === 'dates_training') {
          request = this.trainingsService.deleteDateTraining(
            this.activatedRoute.snapshot.params.registry_id,
            this.activatedRoute.snapshot.params.establishment_id,
            event
          );
        } else {
          request = this.trainingsService.deleteTraining(
            this.activatedRoute.snapshot.params.registry_id,
            this.activatedRoute.snapshot.params.establishment_id,
            event
          );
        }

        request.subscribe(response => {
          if (response['success']) {
            this.refreshData();
            CustomNotifierService.getSuccessDelete(this.notifier);
          } else if (response['errors']) {
            CustomNotifierService.getErrors(response['errors'], this.notifier);
          }
          this.requestInProgress.next(false);
        });
      }
    });
  }

  defineView() {
    this.requestInProgress.next(true);
    if (this.activatedRoute.snapshot.params.training_id) {
      this.view = 'dates_training';
    } else if (this.activatedRoute.snapshot.url[0].path === 'dashboard-registry') {
      this.view = 'user';
    } else {
      this.view = 'admin';
    }
    this.requestInProgress.next(false);
  }

  /**
   * This function retrieves trainings (both admin side and user side) as well as dates trainings (user side only).
   */
  getTrainings() {
    this.requestInProgress.next(true);
    const snapshot = this.activatedRoute.snapshot;
    const admin = this.view === 'admin';
    let request;
    let establishment_id;
    let registry_id;

    if (snapshot.params.establishment_id) { // Only trainings for enterprise view
      establishment_id = +snapshot.params.establishment_id;
      registry_id = +snapshot.params.registry_id;
    } else { // Generic trainings for admin
      establishment_id = null;
      registry_id = null;
    }

    if (snapshot.params.training_id) { // Test for date trainings view
      request = this.trainingsService.getDateTrainings(
        this.search, this.sort, this.order, this.limit, this.page, snapshot.params.training_id);
    } else { // Test if is only trainings (user and admin)
      request = this.trainingsService.getTrainings(this.search, this.sort, this.order, this.limit, this.page, establishment_id, registry_id,
        this.show_inactive, admin);
    }

    request.subscribe(response => {
      if (response['success']) {
        this.canUpdate = response['canUpdate'];
        this.canCreate = response['canCreate'];
        this.canDelete = response['canDelete'];
        this.canLog = response['canLog'];

        this.trainings = response['trainings'];

        this.pages = response['pages'];
        this.count = response['count'];
        if (snapshot.params.training_id) { // If it's a date_training
          this.date_trainings = response['date_trainings'];
          this.training_name = response['trainings']['name'];
        }
        this.createBreadcrumbs();
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  /**
   * @param id The establishment id
   */
  getEstablishment(id: number) {
    this.requestInProgress.next(true);
    this.establishmentsService.getMyEstablishment(id).subscribe(response => {
      if (response['success']) {
        this.establishment = response['establishment'];
        this.createBreadcrumbs();
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  /**
   * @param id The registry id
   */
  getRegistry(id: number) {
    this.requestInProgress.next(true);
    this.registriesService.getRegistry(id).subscribe(response => {
      if (response['success']) {
        this.registry = response['registry'];
        this.createBreadcrumbs();
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  /**
   * @param id The training id
   */
  getTraining(id: number) {
    this.requestInProgress.next(true);
    this.trainingsService.getTraining(id).subscribe(response => {
      if (response['success']) {
        this.training = response['training'];
        this.createBreadcrumbs();
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  showHideTrainingsHidden() {
    this.show_inactive = !this.show_inactive;
    localStorage.setItem('show_inactive_trainings', JSON.stringify(this.show_inactive));
    this.getTrainings();
  }

  searchData() {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.getTrainings();
    }, 500);
  }

  refreshData(sort = this.sort, order = this.order, page = this.page) {
    this.sort = sort;
    this.order = order;
    this.page = page;
    this.getTrainings();
  }

  filter(property) {
    if (this.sort === property) {
      if (this.order === 'asc') {
        this.order = 'desc';
      } else {
        this.order = 'asc';
      }
    } else {
      this.sort = property;
      this.order = 'asc';
    }
    this.getTrainings();
  }

  dispatchPagination(data) {
    if (data.action === 'total_elements') {
      this.refreshPage(data.value);
    } else if (data.action === 'change_page') {
      this.changePage(data.value);
    }
  }

  changePage(page) {
    if (page >= 1 && page <= this.pages) {
      this.page = page;
      this.getTrainings();
    }
  }

  refreshPage(limit) {
    this.limit = limit;
    this.page = 1;
    this.refreshData();
  }

  bindModal(modal) {
    this.modalConfirm = modal;
  }

  bindModalConfirmData(modal) {
    this.modalConfirmData = modal;
  }

  createBreadcrumbs() {
    this.previousPageName = [];
    this.previousPageRoute = [];
    const params = this.activatedRoute.snapshot.params;

    this.previousPageName.push('Tableau de bord');
    this.previousPageRoute.push('/');

    this.previousPageName.push(this.registry ? this.registry.name : 'Tableau de bord registre');
    this.previousPageRoute.push('/dashboard-registry/' + params.registry_id);

    this.previousPageName.push(this.establishment ? this.establishment.name : 'Tableau de bord établissement');
    this.previousPageRoute.push('/dashboard-registry/' + params.registry_id + '/establishment/' + params.establishment_id);

    if (this.view === 'dates_training') {
      this.previousPageName.push(this.training ? 'Formation : ' + this.training.name : 'Formations');
      this.previousPageRoute.push('/dashboard-registry/' + params.registry_id + '/establishment/' + params.establishment_id + '/trainings');
    } else if (this.view === 'admin') {
      this.previousPageName = [];
      this.previousPageRoute = [];

      this.previousPageName.push('Tableau de bord');
      this.previousPageRoute.push('/administration');
    }
  }
}
