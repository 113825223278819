<div class="main-content">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col">
        <div class="header mb-0">
          <div class="header-body no-border">
            <div class="row align-items-center">
              <div class="col">
                <h1 class="header-title">Actions correctives</h1>

                <small class="form-text text-muted">Liste des actions correctives</small>

                <app-breadcrumbs [currentPage]="pageName" [names]="previousPageName" [routes]="previousPageRoute"></app-breadcrumbs>
              </div>

            </div>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col">
            <app-searchbar [(ngModel)]="search" (keyup)="searchData()" ngDefaultControl></app-searchbar>
          </div>
        </div>

        <div class="is-loading loader-content" *ngIf="loader"></div>

        <div class="tab-content" *ngIf="!loader && actions.length">
          <div class="tab-pane fade active show" id="tabPaneOne" role="tabpanel">
            <div class="row listAlias">
              <div class="col-12 d-flex flex-column">
                <div class="card flex-fill">
                  <div class="card-body">
                    <div class="table-responsive">
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th class="href" (click)="filter('Risks.family')">
                              <a class="text-muted">Unité de travail</a>
                            </th>
                            <th class="href" (click)="filter('Risks.description')">
                              <a class="text-muted">Description du risque</a>
                            </th>
                            <th class="href" (click)="filter('Risks.todo_protection')">
                              <a class="text-muted">Action corrective</a>
                            </th>
                            <th class="href" (click)="filter('Risks.deadline')">
                              <a class="text-muted">Échéance</a>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="dropdown" *ngFor="let action of actions">
                            <td>
                              <a>
                                {{ action['family'] }}
                              </a>
                            </td>
                            <td>
                              <a>
                                {{ action['description'] }}
                              </a>
                            </td>
                            <td>
                              <a>
                                {{ action['todo_protection'] }}
                              </a>
                            </td>
                            <td>
                              <a>
                                {{ action['deadline'] | date : 'dd/MM/yyyy' }}
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <app-pagination (returnEvent)="dispatchPagination($event)"
                                    [count_elements]="count"
                                    [current_page]="page"
                                    [elements_length]="actions.length"
                                    [limit]="limit"
                                    [total_pages]="pages"
                                    ngDefaultControl>
                    </app-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="loader-content" *ngIf="!loader && !actions.length">
          <p>Aucun résultat</p>
        </div>
      </div>
    </div>
  </div>
</div>

<app-confirm-modal [type]="'delete_data'" #confirmData>{{ bindModalConfirmData(confirmData) }}</app-confirm-modal>

<router-outlet></router-outlet>
