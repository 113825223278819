import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NotifierService } from "angular-notifier";
import { Subject } from "rxjs";

import { AllTrainingsComponent } from "../all-trainings/all-trainings.component";

import { CustomNotifierService } from "../../../services/custom.notifier.service";
import { TrainingsRequestService } from "../../../services/trainings.request.service";

import Training from "../../../../shared/interfaces/training";

@Component({
  selector: "app-update-training-custom",
  templateUrl: "./update-training-custom.component.html",
  styleUrls: ["./update-training-custom.component.scss"],
})
export class UpdateTrainingCustomComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  modalTraining = null;
  previousRoute = "";

  isNew = true;
  canUpdate = false;
  canCreate = false;

  errors = {};

  view = "";

  trainingForm: FormGroup;
  id = new FormControl("");
  name = new FormControl("", Validators.required);
  description = new FormControl("");
  frequency_duration = new FormControl("", [
    Validators.required,
    Validators.min(1),
  ]);
  frequency = new FormControl("", Validators.required);
  frequencies = new FormControl([]);
  establishment_id = new FormControl("");
  registry_id = new FormControl("");
  enterprise_id = new FormControl("");
  custom = new FormControl(true);
  generic = new FormControl(false);
  priority = new FormControl(false);
  origin = new FormControl(null);
  frequency_notification = new FormControl(false);
  frequency_alert = new FormControl(false);

  trainings: Training[];
  originTraining: Training[];

  loader = false;
  loaderContent = false;

  requestInProgress = new Subject();
  requestCount = 0;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private allTrainings: AllTrainingsComponent,
    private trainingsService: TrainingsRequestService
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loaderContent = this.requestCount > 0;
    });

    this.previousRoute = this.activatedRoute.snapshot.parent.url.join("/");
    this.trainingForm = this.formBuilder.group({
      id: this.id,
      name: this.name,
      description: this.description,
      frequencies: this.frequencies,
      frequency: this.frequency,
      frequency_duration: this.frequency_duration,
      establishment_id: this.establishment_id,
      registry_id: this.registry_id,
      enterprise_id: this.enterprise_id,
      generic: this.generic,
      priority: this.priority,
      custom: this.custom,
      origin: this.origin,
      frequency_notification: this.frequency_notification,
      frequency_alert: this.frequency_alert,
    });

    if (this.activatedRoute.snapshot.params.training_id) {
      this.isNew = false;
      this.getCustomTraining();
    }
  }

  ngAfterViewInit(): void {
    this.modalTraining.open();
  }

  ngOnDestroy(): void {
    this.modalTraining.close();
  }

  getCustomTraining() {
    this.errors = {};
    this.loader = true;
    this.loaderContent = true;

    this.trainingsService
      .getTraining(this.activatedRoute.snapshot.params.training_id)
      .subscribe((response) => {
        if (response["success"]) {
          this.canUpdate = response["canUpdate"];
          if (!this.canUpdate) {
            this.trainingForm.disable();
          }
          this.trainings = response["training"];
          this.originTraining = response["origin_training"];
          this.trainingForm.patchValue(response["training"]);
        } else if (response["errors"]) {
          this.errors = response["errors"];
          CustomNotifierService.getErrorsForm(
            this.errors,
            this.trainingForm,
            this.notifier
          );
        }
        this.loader = false;
        this.loaderContent = false;
      });
  }

  createCustomTraining() {
    this.errors = {};
    this.loader = true;
    const connectedAs = JSON.parse(localStorage.getItem("connectedAs"));
    const enterprise_id = connectedAs
      ? JSON.parse(localStorage.getItem("connectedAsEnterprise")).i
      : JSON.parse(localStorage.getItem("enterprise")).id;

    this.trainingForm.patchValue({ enterprise_id: enterprise_id });
    this.trainingForm.patchValue({
      establishment_id:
        this.activatedRoute.snapshot.parent.params.establishment_id,
    });
    this.trainingForm.patchValue({
      registry_id: this.activatedRoute.snapshot.parent.params.registry_id,
    });
    this.trainingForm.patchValue({
      frequencies: [
        {
          frequency_type: null,
          establishment_type_id: null,
          establishment_subtypes: null,
          establishment_category_id: null,
          establishment_sub_subtype_id: null,
          establishment_subcategory_id: null,
          frequency: this.trainingForm.value.frequency,
          frequency_duration: this.trainingForm.value.frequency_duration,
        },
      ],
    });

    const trainingData = { ...this.trainingForm.value };
    this.trainingsService.createTraining(trainingData).subscribe((response) => {
      if (response["success"]) {
        this.modalTraining.close();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response["errors"]) {
        this.errors = response["errors"];
        CustomNotifierService.getErrorsForm(
          this.errors,
          this.trainingForm,
          this.notifier
        );
      }
      this.loader = false;
    });
  }

  updateCustomTraining() {
    this.errors = {};
    this.loader = true;
    const connectedAs = JSON.parse(localStorage.getItem("connectedAs"));
    const enterprise_id = connectedAs
      ? JSON.parse(localStorage.getItem("connectedAsEnterprise")).id
      : JSON.parse(localStorage.getItem("enterprise")).id;

    this.trainingForm.patchValue({ enterprise_id: enterprise_id });
    this.trainingForm.patchValue({
      establishment_id:
        this.activatedRoute.snapshot.parent.params.establishment_id,
    });
    this.trainingForm.patchValue({
      registry_id: this.activatedRoute.snapshot.parent.params.registry_id,
    });
    this.trainingForm.patchValue({
      frequencies: this.originTraining["frequencies"],
    });

    const trainingData = { ...this.trainingForm.value };
    this.trainingsService
      .updateTraining(this.id.value, trainingData)
      .subscribe((response) => {
        if (response["success"]) {
          this.modalTraining.close();
          CustomNotifierService.getSuccessCreate(this.notifier);
        } else if (response["errors"]) {
          this.errors = response["errors"];
          CustomNotifierService.getErrorsForm(
            this.errors,
            this.trainingForm,
            this.notifier
          );
        }
        this.loader = false;
      });
  }

  getErrorMessage(param) {
    return this.errors[param] && this.errors[param]["message"];
  }

  changeFrequency() {
    this.frequency.updateValueAndValidity();
    this.frequency_duration.updateValueAndValidity();
  }

  binModal(modal) {
    this.modalTraining = modal;
  }

  closeModal() {
    this.allTrainings.refreshData();
    this.router.navigate([this.previousRoute]).then(() => {});
  }
}
