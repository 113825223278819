import { Component, OnDestroy, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { EnterprisesRequestService } from '../../../services/enterprises.request.service';

import { environment } from '../../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../core/auth/auth.service';
import { Subscription } from 'rxjs';
import Enterprise from '../../../../shared/interfaces/enterprise';

@Component({
  selector: 'app-all-customers',
  templateUrl: './all-customers.component.html',
  styleUrls: ['./all-customers.component.scss']
})
export class AllCustomersComponent implements OnInit, OnDestroy {
  changeEnterprise: Subscription;
  environment = environment;

  activeTabsList = '';

  pageName: string;
  previousPageName: string[] = [];
  previousPageRoute: string[] = [];
  updateRoute = '';
  view = '';

  enterprises: Enterprise[] = [];

  count = 0;
  pages = 1;

  search = '';
  sort = 'Enterprises.name';
  order = 'asc';
  limit = 12;
  page = 1;

  timeout: any;
  loader = false;

  constructor(
    private router: Router,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private enterprisesService: EnterprisesRequestService
  ) {}

  ngOnInit() {
    this.changeEnterprise = this.authService.getEnterprise().subscribe(() => {
      this.getEnterprises();
    });

    this.activeTabsList = localStorage.getItem('enterpriseTabsList');
    if (!this.activeTabsList) {
      localStorage.setItem('enterpriseTabsList', JSON.stringify('tabPaneOne'));
      this.activeTabsList = 'tabPaneOne';
    } else {
      this.activeTabsList = JSON.parse(this.activeTabsList);
    }

    this.pageName = 'Mes clients';
    this.previousPageName.push('Tableau de bord');
    this.previousPageRoute.push('/');

    if (this.authService.loaded) {
      this.getEnterprises();
    }
  }

  ngOnDestroy() {
    this.changeEnterprise.unsubscribe();

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  searchData() {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.page = 1;
      this.refreshData();
    }, 500);
  }

  refreshData(sort = this.sort, order = this.order, page = this.page) {
    this.sort = sort;
    this.order = order;
    this.page = page;
    this.getEnterprises();
  }

  getEnterprises() {
    this.loader = true;
    this.enterprisesService.getCustomers(this.search, this.sort, this.order, this.limit, this.page).subscribe(response => {
      if (response['success']) {
        this.enterprises = response['enterprises'];

        this.count = response['count'];
        this.pages = response['pages'];
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loader = false;
    });
  }

  dispatchPagination(data) {
    if (data.action === 'total_elements') {
      this.refreshPage(data.value);
    } else if (data.action === 'change_page') {
      this.changePage(data.value);
    }
  }

  refreshPage(limit) {
    this.limit = limit;
    this.page = 1;
    this.refreshData();
  }

  filter(property) {
    if (this.sort === property) {
      if (this.order === 'asc') {
        this.order = 'desc';
      } else {
        this.order = 'asc';
      }
    } else {
      this.sort = property;
      this.order = 'asc';
    }

    this.refreshData();
  }

  changePage(page) {
    if (page >= 1 && page <= this.pages) {
      this.page = page;
      this.getEnterprises();
    }
  }

  changeActiveTabsList(tabValue: string) {
    localStorage.setItem('enterpriseTabsList', JSON.stringify(tabValue));
    this.activeTabsList = tabValue;
  }
}
