<div class="main-content">

  <div class="header bg-dark pb-5">
    <div class="container-fluid">

      <div class="header-body">
        <div class="row align-items-end">
          <div class="col">

            <h6 class="header-pretitle text-secondary">
              TABLEAU DE BORD
            </h6>

            <h1 class="header-title text-white">
              Statistiques Prestataires / Intervenants
            </h1>

          </div>
        </div>
      </div>

    </div>
  </div>

  <div class="container-fluid mt-n6">
    <div class="row">
      <div class="col-12">

        <div class="card">
          <!-- INPUTS -->
          <div class="card-header">
            <div class="row align-items-center">
              <!-- ESTABLISHMENTS -->
              <div class="col-6" *ngIf="establishments">
                <div class="form-group">
                  <label>Etablissements</label>
                  <ng-select [items]="establishments"
                             [multiple]="true"
                             bindLabel="name"
                             notFoundText="Aucun résultat"
                             [selectableGroup]="true"
                             [closeOnSelect]="false"
                             bindValue="id"
                             placeholder="Sélectionnez un/des établissement(s)"
                             [(ngModel)]="selectedEstablishments" (ngModelChange)="updateSelectedEstablishments()" [ngModelOptions]="{ standalone: true }">
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                      <input type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }" /> {{ item.name }}
                    </ng-template>
                  </ng-select>

                </div>
              </div>
              <!-- PRESET TIME -->
              <div class="col-6" *ngIf="establishments">
                <div class="form-group">
                  <label>Période</label>
                  <ng-select [items]="periods"
                             [multiple]="false"
                             bindLabel="name"
                             notFoundText="Aucun résultat"
                             [selectableGroup]="true"
                             [closeOnSelect]="true"
                             bindValue="value"
                             placeholder="Sélectionnez une période"
                             [(ngModel)]="period" (ngModelChange)="updatePeriod($event)" [ngModelOptions]="{ standalone: true }">
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">{{ item.name }}</ng-template>
                  </ng-select>

                </div>
              </div>

              <!-- REGISTRIES -->
              <div class="col-6" *ngIf="registries">
                <div class="form-group">
                  <label>Registres</label>
                  <ng-select [items]="registries"
                             [multiple]="false"
                             bindLabel="name"
                             notFoundText="Aucun résultat"
                             [selectableGroup]="true"
                             [closeOnSelect]="true"
                             bindValue="id"
                             placeholder="Sélectionnez un registre"
                             [(ngModel)]="selectedRegistry" (ngModelChange)="updateSelectedRegistry()" [ngModelOptions]="{ standalone: true }">
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">{{ item.name }}</ng-template>
                  </ng-select>

                </div>
              </div>

              <!-- CLEAR FILTERS -->
              <div class="col-12 d-flex align-items-center justify-content-center">
                <div class="btn-group">
                  <a class="btn btn-block btn-primary mb-4 text-white text-truncate" (click)="resetFilters()">
                    Réinitialiser les filtres
                  </a>
                </div>
              </div>

            </div>
          </div>

        </div>

      </div>

      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">
                <h4 class="card-header-title">
                  Nombre de points de contrôle attribués par enterprise prestataire
                </h4>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="chart">
              <div class="is-loading" *ngIf="loaderCheckpointsProviders"></div>
              <canvas [hidden]="loaderCheckpointsProviders" id="checkpointsProviders" class="chart-canvas"></canvas>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-xl-6">
        <div class="card">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">
                <h4 class="card-header-title">
                  Nombre de visites effectuées par entreprise prestataire
                </h4>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="chart">
              <div class="is-loading" *ngIf="loaderReportsProviders"></div>
              <canvas [hidden]="loaderReportsProviders" id="reportsProviders" class="chart-canvas"></canvas>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-xl-6">
        <div class="card">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">
                <h4 class="card-header-title">
                  Nombre d'observations émises par entreprise prestataire
                </h4>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="chart">
              <div class="is-loading" *ngIf="loaderObservationsProviders"></div>
              <canvas [hidden]="loaderObservationsProviders" id="observationsProviders" class="chart-canvas"></canvas>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-xl-6">
        <div class="card">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">
                <h4 class="card-header-title">
                  Nombre de bons d’intervention attribués aux différents intervenants par prestataire
                </h4>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="chart">
              <div class="is-loading" *ngIf="loaderInterventionsProviders"></div>
              <canvas [hidden]="loaderInterventionsProviders" id="interventionsProviders" class="chart-canvas"></canvas>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-xl-6">
        <div class="card">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">
                <h4 class="card-header-title">
                  Pourcentage d’observations levées selon les bons d’interventions par prestataire
                </h4>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="chart">
              <div class="is-loading" *ngIf="loaderInterventionsProviders"></div>
              <canvas [hidden]="loaderInterventionsProviders" id="observationsStateByIntervention" class="chart-canvas"></canvas>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-xl-6">
        <div class="card">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">
                <h4 class="card-header-title">
                  Retard moyen d’intervention par prestataire
                </h4>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="chart">
              <div class="is-loading" *ngIf="loaderDelayInterventionsProviders"></div>
              <canvas [hidden]="loaderDelayInterventionsProviders" id="delayInterventionProviders" class="chart-canvas"></canvas>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-xl-6">
        <div class="card">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">
                <h4 class="card-header-title">
                  Coût moyen d’intervention par prestataire
                </h4>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="chart">
              <div class="is-loading" *ngIf="loaderObservationsProviders"></div>
              <canvas [hidden]="loaderObservationsProviders" id="costsInterventionsProviders" class="chart-canvas"></canvas>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>

<div class="modal fade" id="checkpointsByProvider" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
  <div class="modal-dialog provider" role="document">
    <div class="modal-content modal-provider">
      <div class="modal-provider-header">
        <h4 class="modal-title" *ngIf="checkpoints.length">Entreprise prestataire : {{ providerName }}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
        <div class="is-loading loader-modal-content p-5" *ngIf="loader"></div>
        <div class="table-responsive table-providers" *ngIf="checkpoints.length && !loader">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Registre</th>
                <th>Établissement</th>
                <th>Installation</th>
                <th>Point de contrôle</th>
                <th>Périodicité</th>
                <th>Utilisateur</th>
                <th>Type d'attribution</th>
              </tr>
            </thead>
            <tbody>
              <tr class="dropdown" *ngFor="let checkpoint of checkpoints">
                <td>{{ checkpoint['establishment_registry']['registry']['name'] }}</td>
                <td>{{ checkpoint['establishment_registry']['establishment']['name'] }}</td>
                <td *ngIf="checkpoint['establishment_installation']['installation'] && checkpoint['establishment_installation']['installation']['name']">
                  {{ checkpoint['establishment_installation']['installation']['name'] }}
                </td>
                <!--<td *ngIf="checkpoint['establishment_installation']['description']">{{ checkpoint['establishment_installation']['description'] }}</td>-->
                <td>{{ checkpoint['full_name'] }}</td>
                <td>{{ getFrequencyText(checkpoint.frequency, checkpoint.frequency_duration) }}</td>
                <td>{{ getUser(checkpoint) }}</td>
                <td>{{ getAttribution(checkpoint) }}</td>
              </tr>
            </tbody>
          </table>
          <app-pagination (returnEvent)="dispatchPaginationAllCheckpointsByProvider($event)"
                          [count_elements]="checkpointCount"
                          [current_page]="checkpointPage"
                          [elements_length]="checkpoints.length"
                          [limit]="checkpointLimit"
                          [total_pages]="checkpointPages"
                          ngDefaultControl>
          </app-pagination>
        </div>
        <div class="table-responsive table-providers" *ngIf="!checkpoints.length && !loader">
          Aucun résultat
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="reportsByProvider" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
  <div class="modal-dialog provider" role="document">
    <div class="modal-content modal-provider">
      <div class="modal-provider-header">
        <h4 class="modal-title" *ngIf="reports.length">Entreprise prestataire : {{ providerName }}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
        <div class="is-loading loader-modal-content p-5" *ngIf="loader"></div>
        <div class="table-responsive table-providers" *ngIf="reports.length && !loader">
          <table class="table table-striped">
            <thead>
            <tr>
              <th class="href" (click)="filterReport('Establishments.name')">
                <a class="text-muted">Établissement</a>
                <span class="fe fe-arrow-down" *ngIf="reportSort === 'Establishments.name' && reportOrder === 'asc'"></span>
                <span class="fe fe-arrow-up" *ngIf="reportSort === 'Establishments.name' && reportOrder === 'desc'"></span>
                <span class="fe fe-arrow-right" *ngIf="reportSort !== 'Establishments.name'"></span>
              </th>
              <th class="href" (click)="filterReport('Reports.visit_date')">
                <a class="text-muted">Date du rapport</a>
                <span class="fe fe-arrow-down" *ngIf="reportSort === 'Reports.visit_date' && reportOrder === 'asc'"></span>
                <span class="fe fe-arrow-up" *ngIf="reportSort === 'Reports.visit_date' && reportOrder === 'desc'"></span>
                <span class="fe fe-arrow-right" *ngIf="reportSort !== 'Reports.visit_date'"></span>
              </th>
              <th>Nom du rapport</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr class="dropdown" *ngFor="let report of reports">
              <td>{{ getEstablishmentNameReport(report) }}</td>
              <td>{{ report['visit_date'] ? (report['visit_date'] | date : 'dd/MM/yyyy') : '' }}</td>
              <td>{{ report['establishment_checkpoint']['full_name'] }}</td>
              <td class="d-flex flex-row-reverse">
                <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i class="fe fe-more-vertical"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-right">
                  <a class="dropdown-item href" [routerLink]=[getUrlReport(report)]>
                    lien vers le rapport
                  </a>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
          <app-pagination (returnEvent)="dispatchPaginationAllReportsByProvider($event)"
                          [count_elements]="reportCount"
                          [current_page]="reportPage"
                          [elements_length]="reports.length"
                          [limit]="reportLimit"
                          [total_pages]="reportPages"
                          ngDefaultControl>
          </app-pagination>
        </div>
        <div class="table-responsive table-providers" *ngIf="!reports.length && !loader">
          Aucun résultat
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="observationsByProvider" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
  <div class="modal-dialog provider" role="document">
    <div class="modal-content modal-provider">
      <div class="modal-provider-header">
        <h4 class="modal-title" *ngIf="observations.length">Entreprise prestataire : {{ providerName }}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
        <div class="is-loading loader-modal-content p-5" *ngIf="loader"></div>
        <div class="table-responsive table-providers" *ngIf="observations.length && !loader">
          <table class="table table-striped">
            <thead>
            <tr>
              <th class="href" (click)="filterObservation('Establishments.name')">
                <a class="text-muted">Établissement</a>
                <span class="fe fe-arrow-down" *ngIf="observationSort === 'Establishments.name' && observationOrder === 'asc'"></span>
                <span class="fe fe-arrow-up" *ngIf="observationSort === 'Establishments.name' && observationOrder === 'desc'"></span>
                <span class="fe fe-arrow-right" *ngIf="observationSort !== 'Establishments.name'"></span>
              </th>
              <th class="href" (click)="filterObservation('Reports.visit_date')">
                <a class="text-muted">Date du rapport</a>
                <span class="fe fe-arrow-down" *ngIf="observationSort === 'Reports.visit_date' && observationOrder === 'asc'"></span>
                <span class="fe fe-arrow-up" *ngIf="observationSort === 'Reports.visit_date' && observationOrder === 'desc'"></span>
                <span class="fe fe-arrow-right" *ngIf="observationSort !== 'Reports.visit_date'"></span>
              </th>
              <th>Nom de l'observation</th>
              <th>Observation levée ?</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr class="dropdown" *ngFor="let observation of observations">
              <td>{{ getEstablishmentNameObservation(observation) }}</td>
              <td>{{ observation['report'] && observation['report']['visit_date'] ? (observation['report']['visit_date'] | date : 'dd/MM/yyyy') : '' }}</td>
              <td>{{ observation['name'] }}</td>
              <td>
                <span class="label" [ngClass]="{'label-unactive': observation['state'] === false, 'label-active': observation['state'] === true }">
                  {{ observation['state'] ? 'Oui' : 'Non' }}
                </span>
              </td>
              <td class="d-flex flex-row-reverse">
                <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i class="fe fe-more-vertical"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-right">
                  <a class="dropdown-item href" [routerLink]="[getUrlObservation(observation)]">
                    lien vers l'observation
                  </a>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
          <app-pagination (returnEvent)="dispatchPaginationAllObservationsByProvider($event)"
                          [count_elements]="observationCount"
                          [current_page]="observationPage"
                          [elements_length]="observations.length"
                          [limit]="observationLimit"
                          [total_pages]="observationPages"
                          ngDefaultControl>
          </app-pagination>
        </div>
        <div class="table-responsive table-providers" *ngIf="!observations.length && !loader">
          Aucun résultat
        </div>
      </div>
    </div>
  </div>
</div>
