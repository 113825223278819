import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { HttpClient } from '@angular/common/http';
import { NotifierService } from 'angular-notifier';

@Injectable()
export class SubscriptionsRequestService extends RequestService {
  constructor(
    protected http: HttpClient,
    protected notifier: NotifierService
  ) {
    super(http, notifier);
  }

  /// GET
  public getOffers(search, sort, order, limit, page) {
    return this.get('offers?' +
      'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getOffer(offer_id) {
    return this.get('offer/' + offer_id);
  }
  public getFrequencies() {
    return this.get('frequencies');
  }
  public getSubscription(subscription_id) {
    return this.get('subscription/' + subscription_id);
  }

  /// CREATE
  public createSubscription(subscription) {
    return this.post('subscription', { subscription });
  }
  public createOffer(offer) {
    return this.post('offer', { offer });
  }

  /// UPDATE
  public updateSubscription(subscription_id, subscription) {
    return this.put('subscription/' + subscription_id, { subscription });
  }
  public updateOffer(offer_id, offer) {
    return this.put('offer/' + offer_id, { offer });
  }

  /// DELETE
  public deleteOffer(offer_id) {
    return this.delete('offer/' + offer_id);
  }
}
