<div class="main-content">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col">
        <div class="header mb-0">
          <div class="header-body no-border">
            <div class="row align-items-center">
              <div class="col">
                <h1 class="header-title">
                  Installations
                </h1>

                <small class="form-text text-muted">
                  <span *ngIf="view === 'admin'">Liste des installations de la plateforme</span>
                  <span *ngIf="view !== 'admin'">Liste des installations du registre {{ registry ? registry.name : ' ' }} de mon établissement {{ establishment ? establishment.name : '' }}</span>
                </small>

                <app-breadcrumbs [currentPage]="pageName" [names]="previousPageName" [routes]="previousPageRoute"></app-breadcrumbs>
              </div>

              <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0" *ngIf="canCreate && (view === 'admin' || registry && registry.installation_custom_module)">
                <a class="btn btn-primary d-block d-md-inline-block" [routerLink]="[currentRoute + 'create']">
                  Ajouter une installation
                </a>
              </div>

              <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0" *ngIf="view == 'dashboard'">
                <a class="btn btn-primary d-block d-md-inline-block text-white" (click)="showHiddenInstallations()">
                  {{ show_inactive ? 'Masquer les installations masqués' : 'Afficher les installations masqués' }}
                </a>
              </div>

              <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0" *ngIf="canCreate && view === 'dashboard'">
                <a class="btn btn-primary d-block d-md-inline-block" [routerLink]="[currentRoute + 'show']">
                  Edition de masse
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col">
            <app-searchbar [(ngModel)]="search" (keyup)="searchData()" ngDefaultControl></app-searchbar>
          </div>
        </div>

        <div class="is-loading loader-content" *ngIf="loader"></div>

        <div class="tab-content" *ngIf="!loader && installations.length">
          <div class="tab-pane fade active show" id="tabPaneOne" role="tabpanel">
            <div class="row listAlias">
              <div class="col-12" *ngIf="show_inactive">
                <div class="card bg-light border">
                  <div class="card-body">
                    <span>Attention, à votre demande, les installations masquées sont temporairement visibles en gris.</span>
                  </div>
                </div>
              </div>

              <div class="col-12 d-flex flex-column">
                <div class="card flex-fill">
                  <div class="card-body">
                    <div class="table-responsive">
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th class="href" (click)="filter('Installations.name')">
                              <a class="text-muted">Nom</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'Installations.name' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'Installations.name' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'Installations.name'"></span>
                            </th>
                            <th *ngIf="view !== 'admin'">Description</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="dropdown" *ngFor="let installation of installations">
                            <td>
                              <a class="href" [routerLink]="[currentRoute + installation.id + '/checkpoints']" [ngClass]="{ 'is-inactive': fullInactive(installation) }">
                                {{ view !== 'admin' ? installation.installation ? installation.installation.name : installation.description : installation.name }}
                              </a>
                            </td>
                            <td *ngIf="view !== 'admin'">
                              <p class="description">
                                {{ installation.installation ? installation.description : '' }}
                              </p>
                            </td>
                            <td class="d-flex flex-row-reverse">
                              <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fe fe-more-vertical"></i>
                              </a>

                              <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item href" [routerLink]="[currentRoute + installation.id + '/checkpoints']">
                                  Consulter les points de contrôle
                                </a>

                                <a *ngIf="view === 'dashboard' && canUpdate" class="dropdown-item href" [routerLink]="[currentRoute + installation.id + '/custom']">
                                  Éditer l'installation
                                </a>

                                <a *ngIf="view === 'dashboard' && canDelete && registry.id === 5" class="dropdown-item href" (click)="modalConfirmData.open(installation.id)">
                                  Supprimer l'installation
                                </a>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <app-pagination (returnEvent)="dispatchPagination($event)"
                                    [count_elements]="count"
                                    [current_page]="page"
                                    [elements_length]="installations.length"
                                    [limit]="limit"
                                    [total_pages]="pages"
                                    ngDefaultControl>
                    </app-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="loader-content" *ngIf="!loader && !installations.length">
          <p>Aucun résultat</p>
        </div>
      </div>
    </div>
  </div>
</div>

<app-confirm-modal [type]="'delete_data'" #confirmData>{{ bindModalConfirmData(confirmData) }}</app-confirm-modal>

<router-outlet></router-outlet>
