import { Routes } from '@angular/router';

import { UpdateSanitaryTemperatureComponent } from './update-sanitary-temperature/update-sanitary-temperature.component';

export const sanitaryTemperatureRoutes: Routes = [
  {
    path: 'create',
    component: UpdateSanitaryTemperatureComponent
  },
  {
    path: ':sanitary_temperature_id',
    component: UpdateSanitaryTemperatureComponent
  },
];
