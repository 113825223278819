import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Subject } from 'rxjs';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { ReportsRequestService } from '../../../services/reports.request.service';

import { AllCustomerReportsComponent } from '../all-customer-reports/all-customer-reports.component';
import BatiFile from '../../../../shared/interfaces/bati_file';

declare function Flatpickr(): any;

@Component({
  selector: 'app-update-customer-report',
  templateUrl: './update-customer-report.component.html',
  styleUrls: ['./update-customer-report.component.scss']
})
export class UpdateCustomerReportComponent implements OnInit, AfterViewInit, OnDestroy {
  canCreate = false;
  canUpdate = false;

  reportForm: FormGroup;
  id = new FormControl('');
  user_id = new FormControl('');
  enterprise_id = new FormControl('');
  establishment_checkpoint_id = new FormControl('');
  visit_date = new FormControl('', Validators.required);
  report_date = new FormControl(null);
  observations = new FormControl(null);
  deadline = new FormControl(null);
  emergencies = new FormControl(null);
  financial_estimation = new FormControl(0, Validators.min(0));
  comment_report = new FormControl(null);
  type_visit = new FormControl(null);
  scoring = new FormControl(null);
  worksheet = new FormControl([]);

  errors = {};

  isNew = true;
  loader = false;
  loaderContent = false;

  requestInProgress = new Subject();
  requestCount = 0;

  modalReport = null;
  enterprises: any = [];
  users: any = [];
  files: BatiFile[] = [];

  activeTab = 'firstPart';

  oldObservations: string = null;
  countOldObservations = 0;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private allReports: AllCustomerReportsComponent,
    private reportsService: ReportsRequestService
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loaderContent = this.requestCount > 0;
    });

    this.reportForm = this.formBuilder.group({
      id: this.id,
      establishment_checkpoint_id: this.establishment_checkpoint_id,
      visit_date: this.visit_date,
      report_date: this.report_date,
      observations: this.observations,
      deadline: this.deadline,
      emergencies: this.emergencies,
      financial_estimation: this.financial_estimation,
      comment_report: this.comment_report,
      type_visit: this.type_visit,
    });

    if (this.activatedRoute.snapshot.params.report_id) {
      this.isNew = false;
      this.getReport();
    } else {
      Flatpickr();
    }
  }

  ngAfterViewInit() {
    this.modalReport.open();
  }

  ngOnDestroy() {
    const flatPicker = document.getElementsByClassName('flatpickr-calendar') as HTMLCollection;
    for (let i = flatPicker.length; i >= 0; i--) {
      if (flatPicker[i]) {
        flatPicker[i].remove();
      }
    }

    this.modalReport.close();
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  getReport() {
    this.requestInProgress.next(true);

    this.reportsService.getCustomerReport(
      this.activatedRoute.snapshot.parent.params.checkpoint_id,
      this.activatedRoute.snapshot.params.report_id
    ).subscribe(response => {
      if (response['success']) {
        this.canUpdate = response['canCreateReport'];

        this.reportForm.patchValue(response['report']);
        this.observations.setValue('');
        const temp = [];
        this.countOldObservations = response['report']['observations'].length;
        response['report']['observations'].forEach(element => {
          temp.push(element.name);
        });
        this.oldObservations = temp.join('\n');

        if (!this.canUpdate) {
          this.reportForm.disable();
        }
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      Flatpickr();
      this.requestInProgress.next(false);
    });
  }

  createReport() {
    let request;
    this.errors = {};
    this.loader = true;

    if (this.observations.value) {
      const temp = [];
      const observations: string = this.observations.value;
      const splitValue = observations.split('\n');

      splitValue.forEach(value => {
        const reg = new RegExp(/^\s*•\s+/gm);
        value = value.replace(reg, '');
        if (value) {
          temp.push(value);
        }
      });
      this.observations.setValue(temp);
    }

    request = this.reportsService.createCustomerReport(
      this.activatedRoute.snapshot.parent.params.checkpoint_id,
      this.reportForm.value
    );

    request.subscribe(response => {
      if (response['success']) {
        this.isNew = false;
        if (this.files.length) {
          this.createDocuments(response['report']['id']);
        } else {
          this.loader = false;
          this.modalReport.close();
          CustomNotifierService.getSuccessCreate(this.notifier);
        }
      } else if (response['errors']) {
        this.loader = false;
        this.errors = response['errors'];
        this.observations.setValue(null);
        CustomNotifierService.getErrorsForm(response['errors'], this.reportForm, this.notifier);
      }

      if (this.observations.value) {
        this.observations.setValue(this.observations.value.join('\n'));
        this.observations.setValue(this.observations.value.replace(/(^|\r\n|\n)([^• ]|$)/g, '$1• $2'));
      }
    });
  }

  updateReport() {
    let request;

    this.errors = {};
    this.loader = true;

    if (this.observations.value) {
      const temp = [];
      const observations: string = this.observations.value;
      const splitValue = observations.split('\n');

      splitValue.forEach(value => {
        if (value) {
          const reg = new RegExp(/^\s*•\s+/gm);
          temp.push(value.replace(reg, ''));
        }
      });

      this.observations.setValue(temp);
    }

    request = this.reportsService.updateCustomerReport(
      this.activatedRoute.snapshot.parent.params.checkpoint_id,
      this.activatedRoute.snapshot.params.report_id,
      this.reportForm.value
    );

    request.subscribe(response => {
      if (response['success']) {
        if (this.files.length) {
          this.createDocuments(this.activatedRoute.snapshot.params.report_id);
        } else {
          this.loader = false;
          this.modalReport.close();
          CustomNotifierService.getSuccessUpdate(this.notifier);
        }
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }

      if (this.observations.value) {
        this.observations.setValue(this.observations.value.join('\n'));
        this.observations.setValue(this.observations.value.replace(/(^|\r\n|\n)([^• ]|$)/g, '$1• $2'));
      }

      this.loader = false;
    });
  }

  addTextareaBullet(event) {
    if (event.srcElement.value) {
      event.srcElement.value = event.srcElement.value.replace(/(^|\r\n|\n)([^• ]|$)/g, '$1• $2');
    }
  }

  pasteTextareaBullet(event) {
    setTimeout(() => {
      event.srcElement.value = event.srcElement.value.replace(/^\s+|\s+$/g, '');
      event.srcElement.value = event.srcElement.value.replace(/[\r\n]+/g, '\n');
      event.srcElement.value = event.srcElement.value.replace(/(^|\r\n|\n)([^• ]|$)/g, '$1• $2');
    }, 150);
  }

  createDocuments(report_id) {
    let request;
    this.errors = {};
    this.loader = true;

    request = this.reportsService.createCustomerFile(
      report_id,
      this.files
    );

    request.subscribe(response => {
      if (response['success']) {
        this.modalReport.close();
        CustomNotifierService.getSuccessUpdate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loader = false;
    });
  }

  chooseFile() {
    const element: HTMLElement = document.getElementById('inputFile') as HTMLElement;
    element.click();
  }

  onFileChange(event) {
    if (event.target && event.target.files && event.target.files.length) {
      for (let i = 0; i < event.target.files.length; i++) {
        const reader = new FileReader();
        const file = event.target.files[i];
        reader.readAsDataURL(file);

        reader.onload = () => {
          let extension = '';
          let name = '';

          const names: string = file.name;
          const split = names.split('.');
          if (split.length) {
            name = split[0];
            extension = split[split.length - 1];
          }

          this.files.push({ file_base64: reader.result, name: name, extension: extension});
        };
      }
    } else {
      let extension = '';
      let name = '';

      const split = event.name.split('.');
      if (split.length) {
        name = split[0];
        extension = split[split.length - 1];
      }

      this.files.push({ file_base64: event.file, name: name, extension: extension });
    }
    const element: HTMLInputElement = document.getElementById('inputFile') as HTMLInputElement;
    element.value = '';
  }

  removeFile(index) {
    this.files.splice(index, 1);

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  changeTabs(activeTab) {
    this.activeTab = activeTab;
  }

  bindModal(modal) {
    this.modalReport = modal;
  }

  closeModal() {
    this.allReports.refreshData();
    this.router.navigate([this.activatedRoute.snapshot.parent.url.join('/')]).then(() => {});
  }
}
