<app-modal-right (eventOnClose)="closeModal()" #asbestosWork>{{ bindModal(asbestosWork) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">{{ isNew ? 'Création' : canUpdate ? 'Mise à jour' : 'Consultation' }}</h6>

        <h1 class="header-title">Fiche travaux amiante</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <form [formGroup]="asbestosWorkForm" [hidden]="loaderContent">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="validationType" required>Type</label>
            <select class="form-control" data-toggle="select" formControlName="type" id="validationType" [ngClass]="{ 'is-invalid': type.invalid && (type.dirty || type.touched) }">
              <option [value]="null">Sélectionnez un type</option>
              <option value="Retrait">Retrait</option>
              <option value="Encapsulage">Encapsulage</option>
              <option value="Autre">Autre</option>
            </select>
            <div *ngIf="type.invalid && (type.dirty || type.touched)" class="invalid-feedback">
              {{ getErrorMessage("type") }}
            </div>
          </div>

          <div class="form-group">
            <label for="validationPrecisionType">Précision du type de travaux</label>
            <input type="text" formControlName="precision_type" class="form-control" id="validationPrecisionType" placeholder="Précision du type de travaux" [ngClass]="{ 'is-invalid': precision_type.invalid && (precision_type.dirty || precision_type.touched) }">
            <div *ngIf="precision_type.invalid && (precision_type.dirty || precision_type.touched)" class="invalid-feedback">
              {{ getErrorMessage("precision_type") }}
            </div>
          </div>

          <div class="form-group" *ngIf="!loaderContent">
            <label for="worksheet">Matériaux et produits contenant de l'amiante concernés</label>
            <ng-select [items]="localizations"
                       [multiple]="true"
                       bindLabel="identifier"
                       bindValue="id"
                       groupBy="group"
                       notFoundText="Aucun résultat"
                       [selectableGroup]="true"
                       [selectableGroupAsModel]="false"
                       [closeOnSelect]="false"
                       placeholder="Sélectionnez une localisation"
                       [(ngModel)]="selectedLocalizations" (ngModelChange)="setLocalizations()" [ngModelOptions]="{ standalone: true }" [disabled]="!isNew && !canUpdate">
              <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                <input type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }"/> Sélectionnez tout
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="worksheet" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }" /> {{ item.localization + ' #' + item.identifier + ' - ' + item.description }}
              </ng-template>
            </ng-select>
          </div>

          <div class="form-group">
            <label for="validationDate" required>Date de réception / réalisation</label>
            <input type="date" formControlName="date" class="form-control" id="validationDate" placeholder="Date de réception / réalisation" data-toggle="flatpickr" [ngClass]="{ 'is-invalid': date.invalid && (date.dirty || date.touched) }">
            <div *ngIf="date.invalid && (date.dirty || date.touched)" class="invalid-feedback">
              {{ getErrorMessage("date") }}
            </div>
          </div>

          <div class="form-group">
            <label for="validationProjectManager">Maître d'oeuvre</label>
            <input type="text" formControlName="project_manager" class="form-control" id="validationProjectManager" placeholder="Maître d'oeuvre" [ngClass]="{ 'is-invalid': project_manager.invalid && (project_manager.dirty || project_manager.touched) }">
            <div *ngIf="project_manager.invalid && (project_manager.dirty || project_manager.touched)" class="invalid-feedback">
              {{ getErrorMessage("project_manager") }}
            </div>
          </div>

          <div class="form-group">
            <label for="validationCsps">CSPS</label>
            <input type="text" formControlName="csps" class="form-control" id="validationCsps" placeholder="Objet" [ngClass]="{ 'is-invalid': csps.invalid && (csps.dirty || csps.touched) }">
            <div *ngIf="csps.invalid && (csps.dirty || csps.touched)" class="invalid-feedback">
              {{ getErrorMessage("csps") }}
            </div>
          </div>

          <div class="form-group">
            <label for="validationVisuaInspector">Contrôleur visuel</label>
            <input type="text" formControlName="visual_inspector" class="form-control" id="validationVisuaInspector" placeholder="Contrôleur visuel" [ngClass]="{ 'is-invalid': visual_inspector.invalid && (visual_inspector.dirty || visual_inspector.touched) }">
            <div *ngIf="visual_inspector.invalid && (visual_inspector.dirty || visual_inspector.touched)" class="invalid-feedback">
              {{ getErrorMessage("visual_inspector") }}
            </div>
          </div>

          <div class="form-group">
            <label for="validationControlOffice">Bureau de contrôle</label>
            <input type="text" formControlName="control_office" class="form-control" id="validationControlOffice" placeholder="Bureau de contrôle" [ngClass]="{ 'is-invalid': control_office.invalid && (control_office.dirty || control_office.touched) }">
            <div *ngIf="control_office.invalid && (control_office.dirty || control_office.touched)" class="invalid-feedback">
              {{ getErrorMessage("control_office") }}
            </div>
          </div>

          <div class="form-group">
            <label for="validationAmount">Montant</label>
            <input type="number" formControlName="amount" class="form-control" id="validationAmount" placeholder="0" [ngClass]="{ 'is-invalid': amount.invalid && (amount.dirty || amount.touched) }" min="0">
            <div *ngIf="amount.invalid && (amount.dirty || amount.touched)" class="invalid-feedback">
              {{ getErrorMessage("amount") }}
            </div>
          </div>

        </div>
      </div>
    </form>
  </div>

  <div modal-footer class="w-100" *ngIf="isNew || canUpdate">
    <button class="btn btn-primary w-100" (click)="isNew ? createAsbestosWork() : updateAsbestosWork()" [ngClass]="{ 'is-loading is-loading-sm': loader }" [disabled]="loader || asbestosWorkForm.invalid">
      {{ isNew ? 'Créer' : 'Mettre à jour' }}
    </button>
  </div>
</app-modal-right>
