<app-modal-right (eventOnClose)="closeModal()" #intervention>{{ bindModal(intervention) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">Détails</h6>

        <h1 class="header-title">Bon d'intervention</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>
      <div class="table-responsive" *ngIf="!loaderContent">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Observation(s) / Prescription(s) / Mise(s) en demeure</th>
              <th>Levée(s) ?</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="!details.length">
              <td colspan="2">
                <div class="loader-content">
                  Aucun résultat
                </div>
              </td>
            </tr>
            <tr *ngFor="let detail of details">
              <td>{{ detail.name }}</td>
              <td><span class="label" [ngClass]="{'label-danger': !detail.state, 'label-success': detail.state }">{{ detail.state ? 'Oui' : 'Non' }}</span></td>
            </tr>
          </tbody>
        </table>
      </div>
  </div>
</app-modal-right>
