import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { HttpClient } from '@angular/common/http';
import { RequestService } from './request.service';

@Injectable()
export class PreventionsRequestService extends RequestService {
  constructor(
    protected http: HttpClient,
    protected notifier: NotifierService
  ) {
    super(http, notifier);
  }

  public getAllPreventions(search, sort, order, limit, page, registry_id, establishment_id, type) {
    return this.get( 'registry/' + registry_id + '/establishment/' + establishment_id + '/preventions?' +
      'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page + '&type=' + type);
  }
  public getPreventionsDashboard(search, sort, order, limit, page, registry_id, establishment_id, type, next) {
    return this.get( 'registry/' + registry_id + '/preventions?' +
      'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page +
      '&type=' + type + '&next=' + (next ? 'y' : '') + '&establishmentId=' + (establishment_id ? establishment_id : ''));
  }
  public getPrevention(registry_id, establishment_id, preventionId) {
    return this.get('registry/' + registry_id + '/establishment/' + establishment_id + '/preventions/' + preventionId);
  }
  public duplicatePrevention(registry_id, establishment_id, preventionId) {
    return this.get('registry/' + registry_id + '/establishment/' + establishment_id + '/preventions/' + preventionId + '/duplicate');
  }
  public createPrevention(registry_id, establishment_id, prevention, type) {
    return this.post('registry/' + registry_id + '/establishment/' + establishment_id + '/preventions?type=' + type, { prevention });
  }
  public updatePrevention(registry_id, establishment_id, preventionId, prevention) {
    return this.put('registry/' + registry_id + '/establishment/' + establishment_id + '/preventions/' + preventionId,
      { prevention });
  }
  public deletePrevention(registry_id, establishment_id, preventionId) {
    return this.delete('registry/' + registry_id + '/establishment/' + establishment_id + '/preventions/' + preventionId);
  }

  public getFiles(search, sort, order, limit, page, registry_id, establishment_id, preventionId) {
    return this.get('registry/' + registry_id + '/establishment/' + establishment_id + '/prevention/' + preventionId +
      '/files?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getFile(registry_id, establishment_id, preventionId, file_id) {
    return this.get('registry/' + registry_id + '/establishment/' + establishment_id + '/prevention/' + preventionId +
      '/files/' + file_id);
  }
  public createFile(registry_id, establishment_id, preventionId, files) {
    return this.post('registry/' + registry_id + '/establishment/' + establishment_id + '/prevention/' + preventionId + '/files',
      { files: files });
  }
  public updateFile(registry_id, establishment_id, preventionId, file_id, file) {
    return this.put('registry/' + registry_id + '/establishment/' + establishment_id + '/prevention/' + preventionId +
      '/files/' + file_id, { file: file });
  }
  public deleteFile(registry_id, establishment_id, preventionId, file_id) {
    return this.delete('registry/' + registry_id + '/establishment/' + establishment_id + '/prevention/' + preventionId +
      '/files/' + file_id);
  }

  // Download
  public downloadFile(registry_id, establishment_id, preventionId, file_id) {
    return this.get('registry/' + registry_id + '/establishment/' + establishment_id + '/prevention/' + preventionId +
      '/files/' + file_id + '/download', { responseType: 'arraybuffer' });
  }
  public downloadPdf(registry_id, establishment_id, preventionId) {
    return this.get('registry/' + registry_id + '/establishment/' + establishment_id + '/prevention/' + preventionId + '/download',
        { responseType: 'arraybuffer' });
  }

  public getAllMyPreventionRisks(search, sort, order, limit, page, registry_id, establishment_id, all = '') {
    return this.get( 'registry/' + registry_id + '/establishment/' + establishment_id + '/preventions/risks?' +
      'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page + '&a=' + all);
  }
  public getMyPreventionRisk(registry_id, establishment_id, riskId) {
    return this.get('registry/' + registry_id + '/establishment/' + establishment_id + '/preventions/risks/' + riskId);
  }
  public createMyPreventionRisk(registry_id, establishment_id, risk) {
    return this.post('registry/' + registry_id + '/establishment/' + establishment_id + '/preventions/risks', { risk });
  }
  public updateMyPreventionRisk(registry_id, establishment_id, riskId, risk) {
    return this.put('registry/' + registry_id + '/establishment/' + establishment_id + '/preventions/risks/' + riskId,
      { risk });
  }
  public deleteMyPreventionRisk(registry_id, establishment_id, riskId) {
    return this.delete('registry/' + registry_id + '/establishment/' + establishment_id + '/preventions/risks/' + riskId);
  }

  public getAllPreventionRisks(search, sort, order, limit, page) {
    return this.get( 'preventions/risks?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getPreventionRisk(riskId) {
    return this.get('preventions/risks/' + riskId);
  }
  public createPreventionRisk(risk) {
    return this.post('preventions/risks', { risk });
  }
  public updatePreventionRisk(riskId, risk) {
    return this.put('preventions/risks/' + riskId, { risk });
  }
  public deletePreventionRisk(riskId) {
    return this.delete('preventions/risks/' + riskId);
  }
}
