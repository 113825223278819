<div class="d-flex align-items-center bg-auth border-top border-top-2 border-primary">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-md-5 col-xl-4 my-5">
        <div class="text-center mb-5">
          <img src="../../../../assets/img/logo_batiregistre.png" alt="Logo Batiregistre" />
        </div>

        <h1 class="display-4 text-center mb-3">
          Me connecter
        </h1>

        <p class="text-muted text-center mb-5">
          Accédez à Batiregistre
        </p>

        <form [formGroup]="loginForm">
          <div class="form-group">
            <label for="validationEmail" required>Email</label>
            <input type="email" formControlName="email" class="form-control" id="validationEmail" placeholder="Email"
                   [ngClass]="{ 'is-invalid': email.invalid && (email.dirty || email.touched) }" (keyup)="clearError()">
            <div *ngIf="email.invalid && (email.dirty || email.touched)" class="invalid-feedback">
              {{ getErrorMessage("email") }}
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col">
                <label for="validationPassword" required>Mot de Passe</label>
              </div>

              <div class="col-auto">
                <a class="form-text small text-muted href" [routerLink]="['/auth/lost-password']" tabindex="-1">
                  Mot de passe oublié ?
                </a>
              </div>
            </div>

            <div class="input-group input-group-merge">
              <input type="{{ showPassword ? 'text' : 'password' }}" formControlName="password" class="form-control form-control-appended"
                     id="validationPassword" placeholder="Mot de passe"
                     [ngClass]="{ 'is-invalid': password.invalid && (password.dirty || password.touched) }"
                     (keyup)="clearError()">
              <div class="input-group-append" (click)="toggle()">
                <span class="input-group-text"><i class="fe {{ showPassword ? 'fe-eye' : 'fe-eye-off' }}"></i></span>
              </div>
              <div *ngIf="password.invalid && (password.dirty || password.touched)" class="invalid-feedback">
                {{ getErrorMessage("password") }}
              </div>
            </div>
          </div>

          <button class="btn btn-lg btn-block btn-primary mb-3" (click)="signIn()"
                  [ngClass]="{ 'is-loading is-loading-sm': isSigningIn }"
                  [disabled]="isSigningIn || loginForm.invalid">
            Se connecter
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
