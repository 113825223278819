import { AfterViewInit, Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { MapsAPILoader } from '@agm/core';

import { environment } from '../../../../environments/environment';

import { AllEstablishmentsComponent } from '../all-establishments/all-establishments.component';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { RegistriesRequestService } from '../../../services/registries.request.service';
import { EstablishmentsRequestService } from '../../../services/establishments.request.service';

import Registry from '../../../../shared/interfaces/registry';
import Establishment from '../../../../shared/interfaces/establishment';

@Component({
  selector: 'app-mass-edit-registries',
  templateUrl: './mass-edit-registries.component.html',
  styleUrls: ['./mass-edit-registries.component.scss']
})
export class MassEditRegistriesComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('search') public searchElementRef: ElementRef;

  environment = environment;

  establishmentForm: FormGroup;
  is_rus = new FormControl('');

  establishmentsSelected = [];
  registriesSelected = [];
  operationSelected: any;

  errors = {};

  view = '';
  previousRoute = '';
  children = false;

  registries: Registry[];
  establishments: Establishment[];
  operation = [{
    id: 'add',
    name: 'Ajouter ces registres'
  }, {
    id: 'subtract',
    name: 'Retirer ces registres'
  }];

  loader = false;
  loaderContent = false;

  modalEstablishment = null;

  constructor(
    private ngZone: NgZone,
    private router: Router,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private mapsAPILoader: MapsAPILoader,
    private activatedRoute: ActivatedRoute,
    private registriesService: RegistriesRequestService,
    private allEstablishments: AllEstablishmentsComponent,
    private establishmentsService: EstablishmentsRequestService
  ) {}

  ngOnInit() {
    this.establishmentForm = this.formBuilder.group({
      is_rus: this.is_rus
    });

    this.getEstablishments();
    this.getAvailableRegistries();
  }

  ngAfterViewInit() {
    this.modalEstablishment.open();
  }

  ngOnDestroy() {
    this.modalEstablishment.close();
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  getEstablishments() {
    this.errors = {};
    this.loaderContent = true;

    this.establishmentsService.getMyEstablishments('', '', '', '', '').subscribe(response => {
      if (response['success']) {
        this.establishments = response['establishments'];
        this.establishments.forEach(establishment => {
          establishment['group'] = true;
        });
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.establishmentForm, this.notifier);
      }
      this.loaderContent = false;
    });
  }
  getAvailableRegistries() {
    this.loaderContent = true;
    this.registriesService.getQuotaEstablishment(0, '').subscribe(response => {
      if (response['success']) {
        this.registries = response['registry_offers'].concat(response['registry_no_offers']);
        this.registries.forEach(registry => {
          registry['group'] = true;
        });
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loaderContent = false;
    });
  }

  updateEstablishment() {
    this.errors = {};
    this.loader = true;

    this.establishmentsService.updateMyRegistries(
      this.registriesSelected, this.establishmentsSelected, this.operationSelected
    ).subscribe(response => {
      if (response['success']) {
        this.modalEstablishment.close();
        CustomNotifierService.getSuccessUpdate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.establishmentForm, this.notifier);
      }
      this.loader = false;
    });
  }

  bindModal(modal) {
    this.modalEstablishment = modal;
  }
  closeModal() {
    this.allEstablishments.refreshData();
    this.router.navigate(['/establishments/mine/active']).then(() => {});
  }
}
