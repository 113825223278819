import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ModalLogsComponent } from './modal-logs.component';
import { ModalRightModule } from '../../../core/modal-right/modal-right.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { UtilsModule } from '../../../core/utils/utils.module';
import { SearchbarModule } from '../../../core/searchbar/searchbar.module';
import { PaginationModalModule } from '../../../core/pagination-modal/pagination-modal.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    ModalRightModule,
    UtilsModule,
    SearchbarModule,
    PaginationModalModule
  ],
  declarations: [ModalLogsComponent]
})

export class ModalLogsModule {}
