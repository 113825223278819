import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { LatLngBounds, MapsAPILoader } from '@agm/core';

import { environment } from '../../../../environments/environment';

import { AuthService } from '../../../core/auth/auth.service';
import { ConfirmModalComponent } from '../../../core/confirm-modal/confirm-modal.component';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { EstablishmentsRequestService } from '../../../services/establishments.request.service';

import Establishment from '../../../../shared/interfaces/establishment';
import Enterprise from '../../../../shared/interfaces/enterprise';
import Registry from '../../../../shared/interfaces/registry';

@Component({
  selector: 'app-all-establishments',
  templateUrl: './all-establishments.component.html',
  styleUrls: ['./all-establishments.component.scss']
})
export class AllEstablishmentsComponent implements OnInit, AfterViewInit, OnDestroy {
  changeEnterprise: Subscription;
  environment = environment;

  requestInProgress = new Subject();
  requestCount = 0;

  canUpdate = false;
  canCreate = false;
  canDelete = false;
  canLog = false;
  limited = false;
  group = false;
  addNewGroup = true;
  hasGroup = false;

  group_id = null;
  groupParent = null;
  activeTabsList = '';

  establishments: Establishment[] = [];
  groupName: Establishment;
  enterprise: Enterprise;
  registry: Registry;

  pageName: string;
  previousGroup: string;
  previousPageName: string[] = [];
  previousPageRoute: string[] = [];
  currentRoute = '';
  view = 'enterprise';
  archive = false;
  children = false;
  hasArchive = false;

  count = 0;
  total = 0;
  pages = 1;

  subscription = 0;

  search = '';
  sort = 'Establishments.is_group';
  order = 'desc';
  limit = 12;
  page = 1;

  errors = {};

  timeout: any;

  loader = false;

  modalConfirm: ConfirmModalComponent = null;
  modalDelete: ConfirmModalComponent = null;
  content = '';

  markers = [];
  bounds: any;
  openedWindow = 0;

  constructor(
    private router: Router,
    private authService: AuthService,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private mapsAPILoader: MapsAPILoader,
    private establishmentsService: EstablishmentsRequestService
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loader = this.requestCount > 0;
    });

    this.changeEnterprise = this.authService.getEnterprise().subscribe(() => {
      this.getEstablishments();
    });

    this.activeTabsList = localStorage.getItem('establishmentTabsList');
    if (!this.activeTabsList) {
      localStorage.setItem('establishmentTabsList', JSON.stringify('tabPaneOne'));
      this.activeTabsList = 'tabPaneOne';
    } else {
      this.activeTabsList = JSON.parse(this.activeTabsList);
    }
    if (this.activatedRoute.snapshot.params.group_id) {
      this.group_id = this.activatedRoute.snapshot.params.group_id;
      this.group = true;
    }
    if (this.activatedRoute.snapshot.params.parent_id) {
      this.children = true;
    }

    this.activatedRoute.params.subscribe(() => {
      this.archive = false;
      if (this.activatedRoute.snapshot.params.active && this.activatedRoute.snapshot.params.active === 'archive') {
        this.archive = true;
        this.pageName = 'Établissements archivés';
      }
      this.getEstablishments();
    });
  }

  ngAfterViewInit() {
    this.modalDelete.eventOnClose.subscribe(event => {
      if (event && this.archive) {
        this.loader = true;
        this.establishmentsService.deleteMine(event).subscribe(response => {
          if (response['success']) {
            CustomNotifierService.getSuccessDelete(this.notifier);
            this.refreshData();
          } else if (response['errors']) {
            CustomNotifierService.getErrors(response['errors'], this.notifier);
          }
        });
      }
    });

    this.modalConfirm.eventOnClose.subscribe(event => {
      if (event) {
        this.loader = true;
        let request;
        if (this.view === 'mine') {
          request = this.establishmentsService.changeMyActive(event);
        } else {
          request = this.establishmentsService.changeActive(event);
        }
        request.subscribe(response => {
          if (response['success']) {
            if (this.archive) {
              CustomNotifierService.getSuccessActivation(this.notifier);
            } else {
              CustomNotifierService.getSuccessDeactivation(this.notifier);
            }
            this.refreshData();
          } else if (response['errors']) {
            CustomNotifierService.getErrors(response['errors'], this.notifier);
          }
        });
      }
    });
  }

  ngOnDestroy() {
    this.changeEnterprise.unsubscribe();

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  searchData() {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.page = 1;
      this.refreshData();
    }, 500);
  }

  refreshData(sort = this.sort, order = this.order, page = this.page) {
    this.sort = sort;
    this.order = order;
    this.page = page;
    this.getEstablishments();
  }

  getEstablishments() {
    this.requestInProgress.next(true);
    let request;
    if (this.activatedRoute.snapshot.params.parent_id) {
      const parent_id = this.activatedRoute.snapshot.params.parent_id;
      request = this.establishmentsService.geMyEstablishmentsChildren(this.search, this.sort, this.order, this.limit, this.page, parent_id);
    } else if (this.activatedRoute.snapshot.params.enterprise_id) {
      if (this.archive) {
        request = this.establishmentsService.getEnterpriseEstablishmentsArchive(
          this.search, this.sort, this.order, this.limit, this.page, this.activatedRoute.snapshot.params.enterprise_id);
      } else {
        request = this.establishmentsService.getEnterpriseEstablishments(
          this.search, this.sort, this.order, this.limit, this.page, this.activatedRoute.snapshot.params.enterprise_id);
      }
    } else if (this.activatedRoute.snapshot.params.registry_id) {
      if (this.activatedRoute.snapshot.params.group_id) {
        request = this.establishmentsService.getMyRegistryByGroupId(
          this.search, this.sort, this.order, this.limit, this.page, this.activatedRoute.snapshot.params.registry_id,
          this.activatedRoute.snapshot.params.group_id);
      } else {
        request = this.establishmentsService.getMyRegistry(
          this.search, this.sort, this.order, this.limit, this.page, this.activatedRoute.snapshot.params.registry_id, true);
      }
    } else if (this.activatedRoute.snapshot.params.group_id) {
      request = this.establishmentsService.getMyEstablishmentsByGroupId(
        this.search, this.sort, this.order, this.limit, this.page, this.activatedRoute.snapshot.params.group_id);
    } else {
      if (this.archive) {
        request = this.establishmentsService.getMyEstablishmentsArchive(this.search, this.sort, this.order, this.limit, this.page);
      } else {
        request = this.establishmentsService.getMyEstablishments(this.search, this.sort, this.order, this.limit, this.page, true);
      }
    }

    request.subscribe(response => {
      if (response['success']) {
        this.canUpdate = response['canUpdate'];
        this.canCreate = response['canCreate'];
        this.canDelete = response['canDelete'];
        this.canLog = response['canLog'];

        this.hasGroup = false;

        this.establishments = response['establishments'];
        this.subscription = response['subscription'];
        this.enterprise = response['enterprise'];
        this.registry = response['registry'];
        this.groupName = response['group'];
        this.addNewGroup = response['addNewGroup'];
        if (response['groupParent']) {
          this.groupParent = response['groupParent'];
        } else {
          this.groupParent = null;
        }

        this.hasArchive = response['hasArchive'];
        if (response['limited']) {
          this.limited = true;
        }

        this.count = response['count'];
        this.total = response['total'];
        this.pages = response['pages'];

        if (this.view === 'mine') {
          this.markers = response['markers'];
          this.setBounds();
        }

        this.createBreadcrumbs();

      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  openWindow(id) {
    this.openedWindow = id;
  }

  isInfoWindowOpen(id) {
    return this.openedWindow === id;
  }

  setBounds() {
    this.mapsAPILoader.load().then(() => {
      this.bounds = new window['google'].maps.LatLngBounds();
      if (this.markers) {
        this.markers.forEach((marker) => {
          if (marker.latitude !== null && marker.longitude !== null) {
            this.bounds.extend(new window['google'].maps.LatLng(marker.latitude, marker.longitude));
          }
        });
      }
    });
  }

  getScoring(establishment) {
    if (establishment.scoring === 'Bâtiment sous avis favorable' ||
      establishment.scoring === 'Établissement sous avis favorable') {
      return 'scoring-green';
    } else if (establishment.scoring === 'Bâtiment sous avis défavorable' ||
      establishment.scoring === 'Établissement sous avis défavorable') {
      return 'scoring-red';
    } else {
      return 'scoring-grey';
    }
  }

  dispatchPagination(data) {
    if (data.action === 'total_elements') {
      this.refreshPage(data.value);
    } else if (data.action === 'change_page') {
      this.changePage(data.value);
    }
  }

  refreshPage(limit) {
    this.limit = limit;
    this.page = 1;
    this.refreshData();
  }

  changePage(page) {
    if (page >= 1 && page <= this.pages) {
      this.page = page;
      this.refreshData();
    }
  }

  changeActiveTabsList(tabValue: string) {
    localStorage.setItem('establishmentTabsList', JSON.stringify(tabValue));
    this.activeTabsList = tabValue;
  }

  filter(property) {
    if (this.sort === property) {
      if (this.order === 'asc') {
        this.order = 'desc';
      } else {
        this.order = 'asc';
      }
    } else {
      this.sort = property;
      this.order = 'asc';
    }

    this.refreshData();
  }

  bindModal(modal) {
    this.modalConfirm = modal;
  }
  bindDeleteModal(modal) {
    this.modalDelete = modal;
  }

  createBreadcrumbs() {
    this.previousPageName = [];
    this.previousPageRoute = [];
    this.archive = false;

    this.currentRoute = '/' + this.activatedRoute.snapshot.url.join('/') + '/';

    this.pageName = 'Établissements actifs';

    if (this.activatedRoute.snapshot.params.parent_id) {
      this.pageName = 'Exploitants';

      this.previousPageName.push('Tableau de bord');
      this.previousPageRoute.push('/');

      this.previousPageName.push('Tableau de bord registre');
      this.previousPageRoute.push('/dashboard-registry/' + this.activatedRoute.snapshot.params.registry_id);

      this.previousPageName.push('Tableau de bord établissement');
      this.previousPageRoute.push(
        '/dashboard-registry/' + this.activatedRoute.snapshot.params.registry_id +
        '/establishment/' + this.activatedRoute.snapshot.params.parent_id
      );
      this.view = 'children';
    } else if (this.activatedRoute.snapshot.params.enterprise_id) {
      this.previousPageName.push('Tableau de bord');
      this.previousPageRoute.push('/administration');

      this.previousPageName.push('Entreprises');
      this.previousPageRoute.push('/enterprises');
    } else if (this.activatedRoute.snapshot.params.registry_id) {
      this.view = 'registry';
      if (this.activatedRoute.snapshot.params.group_id) {
        this.pageName = this.groupName.name;

        this.previousPageName.push('Tableau de bord');
        this.previousPageRoute.push('/');

        this.previousPageName.push('Registres');
        this.previousPageRoute.push('/registries/mine');

        this.previousPageName.push('Établissement actifs');
        this.previousPageRoute.push('/registries/mine/' + this.activatedRoute.snapshot.params.registry_id + '/establishments');

        if (this.groupParent) {
          this.previousPageName.push(this.groupParent.name);
          this.previousPageRoute.push('/registries/mine/' +
            this.activatedRoute.snapshot.params.registry_id + '/group/' + this.groupParent.id);
        }
      } else {
        this.previousPageName.push('Tableau de bord');
        this.previousPageRoute.push('/');

        this.previousPageName.push('Registres');
        this.previousPageRoute.push('/registries/mine');
      }
    } else if (this.activatedRoute.snapshot.url[1] && this.activatedRoute.snapshot.url[1].path === 'mine') {
      if (this.activatedRoute.snapshot.params.group_id) {
        this.view = 'mine';
        this.pageName = this.groupName.name;

        this.previousPageName.push('Tableau de bord');
        this.previousPageRoute.push('/');

        this.previousPageName.push('Établissement actifs');
        this.previousPageRoute.push('/establishments/mine/active');

        if (this.groupParent) {
          this.previousPageName.push(this.groupParent.name);
          this.previousPageRoute.push('/establishments/mine/group/' + this.groupParent.id);
        }
      } else {
        this.view = 'mine';
        this.previousPageName.push('Tableau de bord');
        this.previousPageRoute.push('/');
      }
    }
  }

  removeFromGroup(establishment_id) {
    this.requestInProgress.next(true);
    let request;
    request = this.establishmentsService.removeFromGroup(this.activatedRoute.snapshot.params.group_id, establishment_id);

    request.subscribe(response => {
      if (response['success']) {
        CustomNotifierService.getSuccessDelete(this.notifier);
        this.getEstablishments();
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrors(this.errors, this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  deleteGroup(establishment_id) {
    this.requestInProgress.next(true);
    let request;

    if (this.activatedRoute.snapshot.params.group_id) {
      request = this.establishmentsService.deleteGroupByParentId(establishment_id, this.activatedRoute.snapshot.params.group_id);
    } else {
      request = this.establishmentsService.deleteGroup(establishment_id);
    }

    request.subscribe(response => {
      if (response['success']) {
        CustomNotifierService.getSuccessDelete(this.notifier);
        this.getEstablishments();
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrors(this.errors, this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }
}
