<app-modal-right (eventOnClose)="closeModal()" #firePermit>{{ bindModal(firePermit) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">{{ isNew ? 'Création' : canUpdate ? 'Mise à jour' : 'Consultation' }}</h6>

        <h1 class="header-title">Permis feu - Action</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <form [formGroup]="actionForm" [hidden]="loaderContent">
      <div class="row">
        <div class="col-12">

          <!-- PLACE -->
          <div class="form-group">
            <label for="validationPlace">Nom de l'action</label>
            <input type="text" formControlName="name" class="form-control" id="validationPlace" placeholder="Nom de l'action" [ngClass]="{ 'is-invalid': name.invalid && (name.dirty || name.touched) }">
            <div *ngIf="name.invalid && (name.dirty || name.touched)" class="invalid-feedback">
              {{ getErrorMessage("name") }}
            </div>
          </div>

        </div>
      </div>
    </form>
  </div>

  <div modal-footer class="w-100" *ngIf="isNew || canUpdate">
    <button class="btn btn-primary w-100" (click)="isNew ? createAction() : updateAction()" [ngClass]="{ 'is-loading is-loading-sm': loader }" [disabled]="loader || actionForm.invalid">
      {{ isNew ? 'Créer' : 'Mettre à jour' }}
    </button>
  </div>
</app-modal-right>
