import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { HttpClient } from '@angular/common/http';
import { RequestService } from './request.service';

@Injectable()
export class SanitaryLegionellasRequestService extends RequestService {
  constructor(
    protected http: HttpClient,
    protected notifier: NotifierService
  ) {
    super(http, notifier);
  }

  /// GET ALL
  public getAllSanitaryLegionella(search, sort, order, limit, page, registry_id, establishment_id) {
    return this.get( 'registry/' + registry_id + '/establishment/' + establishment_id + '/sanitary-legionellas?' +
      'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  /// GET ONE
  public getSanitaryLegionella(registry_id, establishment_id, sanitaryLegionellaId) {
    return this.get('registry/' + registry_id + '/establishment/' + establishment_id +
        '/sanitary-legionella/' + sanitaryLegionellaId);
  }
  /// CREATE
  public createSanitaryLegionella(registry_id, establishment_id, sanitaryLegionella) {
    return this.post('registry/' + registry_id + '/establishment/' + establishment_id +
        '/sanitary-legionella', { sanitaryLegionella });
  }
  /// UPDATE
  public updateSanitaryLegionella(registry_id, establishment_id, sanitaryLegionellaId, sanitaryLegionella) {
    return this.put('registry/' + registry_id + '/establishment/' + establishment_id +
        '/sanitary-legionella/' + sanitaryLegionellaId,
      { sanitaryLegionella });
  }
  /// DELETE
  public deleteSanitaryLegionella(registry_id, establishment_id, sanitaryLegionellaId) {
    return this.delete('registry/' + registry_id + '/establishment/' + establishment_id +
        '/sanitary-legionella/' + sanitaryLegionellaId);
  }

  /// FILE
  public getFiles(search, sort, order, limit, page, registry_id, establishment_id, sanitaryLegionellaId) {
    return this.get('registry/' + registry_id + '/establishment/' + establishment_id + '/sanitary-legionella/' + sanitaryLegionellaId +
      '/files?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getFile(registry_id, establishment_id, sanitaryLegionellaId, file_id) {
    return this.get('registry/' + registry_id + '/establishment/' + establishment_id + '/sanitary-legionella/' + sanitaryLegionellaId +
      '/files/' + file_id);
  }
  public createFile(registry_id, establishment_id, sanitaryLegionellaId, files) {
    return this.post('registry/' + registry_id + '/establishment/' + establishment_id +
      '/sanitary-legionella/' + sanitaryLegionellaId + '/files',
      { files: files });
  }
  public updateFile(registry_id, establishment_id, sanitaryLegionellaId, file_id, file) {
    return this.put('registry/' + registry_id + '/establishment/' + establishment_id + '/sanitary-legionella/' + sanitaryLegionellaId +
      '/files/' + file_id, { file: file });
  }
  public deleteFile(registry_id, establishment_id, sanitaryLegionellaId, file_id) {
    return this.delete('registry/' + registry_id + '/establishment/' + establishment_id +
      '/sanitary-legionella/' + sanitaryLegionellaId + '/files/' + file_id);
  }
  public downloadFile(registry_id, establishment_id, sanitaryLegionellaId, file_id) {
    return this.get('registry/' + registry_id + '/establishment/' + establishment_id + '/sanitary-legionella/' + sanitaryLegionellaId +
      '/files/' + file_id + '/download', { responseType: 'arraybuffer' });
  }
}
