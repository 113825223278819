<app-modal-right (eventOnClose)="closeModal()" #enterprise>{{ bindModal(enterprise) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">{{ isNew ? 'Création' : canUpdate ? 'Mise à jour' : 'Consultation' }}</h6>

        <h1 class="header-title">Entreprise</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <form [formGroup]="enterpriseForm" [hidden]="loaderContent">
      <ul class="nav nav-tabs" role="tablist" *ngIf="view === 'enterprises' && !isNew">
        <li class="nav-item col-6">
          <a class="nav-link href" [ngClass]="{ 'active': activeTab === 'firstPart'}" (click)="changeTabs('firstPart')">Entreprise</a>
        </li>
        <li class="nav-item col-6">
          <a class="nav-link href" [ngClass]="{ 'active': activeTab === 'secondPart'}" (click)="changeTabs('secondPart')">Archive</a>
        </li>
      </ul>

      <div class="tab-content">
        <!-- ENTERPRISE -->
        <div class="tab-pane fade" id="firstPart" role="tabpanel" aria-labelledby="firstPart" [ngClass]="{ 'show active': activeTab === 'firstPart'}">
          <div class="row">
            <div class="col-12">
              <!-- NAME -->
              <div class="form-group">
                <label for="validationName" required>Nom de l'entreprise</label>
                <input type="text" formControlName="name" class="form-control" id="validationName" placeholder="Nom de l'entreprise" [ngClass]="{ 'is-invalid': name.invalid && (name.dirty || name.touched) }" maxlength="255">
                <div *ngIf="name.invalid && (name.dirty || name.touched)" class="invalid-feedback">
                  {{ getErrorMessage("name") }}
                </div>
              </div>

              <!-- QUALIFICATION -->
              <div class="form-group" *ngIf="view === 'providers'">
                <label for="validationProviderType" required>Qualification</label>
                <select class="form-control" data-toggle="select" formControlName="qualification" id="validationProviderType" [ngClass]="{ 'is-invalid': qualification.invalid && (qualification.dirty || qualification.touched) }">
                  <option [value]="null">Sélectionnez une qualification</option>
                  <option value="TECHNICIEN">Technicien compétent</option>
                  <option value="ORGANISME">Organisme agréé</option>
                </select>
                <div *ngIf="qualification.invalid && (qualification.dirty || qualification.touched)" class="invalid-feedback">
                  {{ getErrorMessage("qualification") }}
                </div>
              </div>

              <!-- ACCESS TO ENTERPRISE -->
              <div class="form-group d-flex justify-content-start align-items-center" *ngIf="view === 'providers' && (!monoOffer && !discoverOffer)">
                <div class="col pl-0">
                  Donner accès à BatiRegistre
                </div>
                <div class="custom-checkbox-toggle">
                  <input type="checkbox" class="custom-control-input" id="validationAccess" formControlName="access_to_enterprise" [ngClass]="{ 'backgroundColor': access_to_enterprise.value }">
                  <label class="custom-control-label" [ngClass]="{ 'backgroundColor': access_to_enterprise.value }" for="validationAccess"></label>
                </div>
              </div>

              <!-- ADDRESS -->
              <div class="form-group">
                <label for="validationAddress">Adresse</label>
                <input type="text" formControlName="address" class="form-control" id="validationAddress" placeholder="Adresse" [ngClass]="{ 'is-invalid': address.invalid && (address.dirty || address.touched) }" maxlength="255" #search (keyup)="onSearch(search.value)">
                <ul class="google-places-container" *ngIf="results.length">
                  <li *ngFor="let result of results" class="item" (click)="selectPlace(result)">
                    <a class="href" role="button">
                      <i class="fe fe-map-pin"></i> {{ result.description }}
                    </a>
                  </li>
                </ul>
                <div id="service-helper"></div>
                <div *ngIf="address.invalid && (address.dirty || address.touched)" class="invalid-feedback">
                  {{ getErrorMessage("address") }}
                </div>
              </div>

              <!-- ZIPCODE -->
              <div class="form-group">
                <label for="validationZipcode">Code postal</label>
                <input type="text" formControlName="zipcode" class="form-control" id="validationZipcode" placeholder="Code postal" [ngClass]="{ 'is-invalid': zipcode.invalid && (zipcode.dirty || zipcode.touched) }" maxlength="5">
                <div *ngIf="zipcode.invalid && (zipcode.dirty || zipcode.touched)" class="invalid-feedback">
                  {{ getErrorMessage("zipcode") }}
                </div>
              </div>

              <!-- CITY -->
              <div class="form-group">
                <label for="validationCity">Ville</label>
                <input type="text" formControlName="city" class="form-control" id="validationCity" placeholder="Ville" [ngClass]="{ 'is-invalid': city.invalid && (city.dirty || city.touched) }" maxlength="255">
                <div *ngIf="city.invalid && (city.dirty || city.touched)" class="invalid-feedback">
                  {{ getErrorMessage("city") }}
                </div>
              </div>

              <!-- SIRET -->
              <div class="form-group">
                <label for="validationSiret" required>Siret</label>
                <input type="text" formControlName="siret" class="form-control" id="validationSiret" placeholder="Siret" [ngClass]="{ 'is-invalid': siret.invalid && (siret.dirty || siret.touched) }" maxlength="14">
                <div *ngIf="siret.invalid && (siret.dirty || siret.touched)" class="invalid-feedback">
                  {{ getErrorMessage("siret") }}
                </div>
              </div>

              <!-- PICTURE -->
              <div class="form-group" [hidden]="!isNew && !canUpdate || picture.value">
                <label class="mb-1">Logo entreprise</label>

                <small class="form-text text-muted">
                  Veuillez utiliser une image ne dépassant pas 200px * 200px.
                </small>

                <div class="dropzone dropzone-multiple dz-clickable" appDragDrop (dropHandler)="onFileChange($event)">
                  <div class="dz-default dz-message" (click)="chooseFile()">
                    <span>Déposer votre fichier ici pour télécharger</span>
                  </div>
                  <input id="inputFile" (change)="onFileChange($event)" type="file" accept="image/*" [hidden]="true">
                </div>
              </div>

              <div class="form-group" *ngIf="picture.value">
                <label class="mb-1">Logo entreprise</label>

                <small class="form-text text-muted">
                  Veuillez utiliser une image ne dépassant pas 200px * 200px.
                </small>

                <div class="card">
                  <div class="card-body">
                    <div class="d-flex align-items-center justify-content-between">
                      <div class="avatar avatar-lg avatar-4by3">
                        <img src="{{ checkPicture() }}" alt="Logo entreprise" class="avatar-img rounded">
                      </div>

                      <a class="href tooltipHtml" data-placement="bottom" title="Supprimer" (click)="removeFile()" *ngIf="isNew || canUpdate">
                        <i class="fe fe-trash"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- ARCHIVE -->
        <div class="tab-pane fade" id="secondPart" role="tabpanel" aria-labelledby="secondPart" [ngClass]="{ 'show active': activeTab === 'secondPart'}">
          <div class="row">
            <div class="col-12">

              <div class="card bg-light border" *ngIf="!archive">
                <div class="card-body">
                  <p class="small text-muted mb-2">
                    Aucune archive.
                  </p>
                </div>
              </div>

              <div class="card bg-light border" *ngIf="archive && !archive.realized_date">
                <div class="card-body">
                  <p class="small text-muted mb-2">
                    Archive en cours de création. <br>
                    Archive demandée le {{ archive.request_date | date : 'dd/MM/yyyy à HH:mm' }}
                  </p>
                </div>
              </div>

              <div class="card bg-light border" *ngIf="archive && archive.realized_date">
                <div class="card-body">
                  <p class="small text-muted mb-2">
                    Archive crée le {{ archive.realized_date | date : 'dd/MM/yyyy à HH:mm' }}
                  </p>
                </div>
              </div>



            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div modal-footer class="w-100" *ngIf="isNew || canUpdate">
    <button class="btn btn-primary w-100" (click)="isNew ? createEnterprise() : updateEnterprise()"
            *ngIf="activeTab === 'firstPart'"
            [ngClass]="{ 'is-loading is-loading-sm': loader }"
            [disabled]="loader || enterpriseForm.invalid">
      {{ isNew ? 'Créer' : 'Mettre à jour' }}
    </button>

    <button class="btn btn-primary w-100" (click)="addArchive()"
            *ngIf="activeTab === 'secondPart' && (!archive || (archive && archive.realized_date)) && !loaderContent"
            [ngClass]="{ 'is-loading is-loading-sm': loader, 'mb-3': archive && archive.realized_date }">
      Demander une archive
    </button>

    <button class="btn btn-primary w-100" (click)="downloadArchive()"
            *ngIf="activeTab === 'secondPart' && archive && archive.realized_date && !loaderContent"
            [ngClass]="{ 'is-loading is-loading-sm': loader }">
      Télécharger l'archive
    </button>
  </div>
</app-modal-right>
