import { Routes } from '@angular/router';

import { AllLogsComponent } from './all-logs/all-logs.component';

export const logsRoutes: Routes = [
  {
    path: 'logs',
    component: AllLogsComponent,
  }
];
