import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AfterViewInit, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { MapsAPILoader } from '@agm/core';

import { environment } from '../../../../environments/environment';

import { AllEnterprisesComponent } from '../all-enterprises/all-enterprises.component';
import { AllProviderRegistryComponent } from '../all-provider-registry/all-provider-registry.component';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { EnterprisesRequestService } from '../../../services/enterprises.request.service';
import { ArchivesRequestService } from '../../../services/archives.request.service';
import { AuthService } from '../../../core/auth/auth.service';

declare function Dropzones(): any;
declare var $: any;

@Component({
  selector: 'app-update-enterprise',
  templateUrl: './update-enterprise.component.html',
  styleUrls: ['./update-enterprise.component.scss']
})
export class UpdateEnterpriseComponent implements OnInit, AfterViewInit, OnDestroy {
  environment = environment;

  canCreate = false;
  canUpdate = false;

  view = '';
  pathRequest = '';
  activeTab = 'firstPart';

  enterpriseForm: FormGroup;
  id = new FormControl('');
  name = new FormControl('', [Validators.required]);
  qualification = new FormControl(null, [Validators.required]);
  access_to_enterprise = new FormControl(true);
  address = new FormControl('', []);
  zipcode = new FormControl('', []);
  city = new FormControl('', []);
  siret = new FormControl('', [Validators.required]);
  picture = new FormControl('');

  offers: any = [];

  errors = {};

  isNew = true;
  loader = false;
  loaderContent = false;

  modalEnterprise = null;

  results = [];
  searchTerm: string;
  autocompleteService;
  sessionToken: google.maps.places.AutocompleteSessionToken = null;

  archive;

  allRegistryProviders: any;
  allEnterprises: any;

  monoOffer = false;
  discoverOffer = false;
  connectedAs = false;

  constructor(
    private router: Router,
    private injector: Injector,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private notifier: NotifierService,
    private mapsAPILoader: MapsAPILoader,
    private activatedRoute: ActivatedRoute,
    private enterprisesService: EnterprisesRequestService,
    private archivesService: ArchivesRequestService
  ) {
    if (this.activatedRoute.snapshot.parent.params.establishment_id) {
      this.allRegistryProviders = <AllProviderRegistryComponent>this.injector.get(AllProviderRegistryComponent);
    } else {
      this.allEnterprises = <AllEnterprisesComponent>this.injector.get(AllEnterprisesComponent);
    }
  }

  ngOnInit() {
    if ((this.activatedRoute.snapshot.parent.url[0] && this.activatedRoute.snapshot.parent.url[0].path === 'providers') ||
      (this.activatedRoute.snapshot.parent.params.establishment_id &&
        this.activatedRoute.snapshot.parent.url[4].path && this.activatedRoute.snapshot.parent.url[4].path === 'providers')) {
      this.view = 'providers';
      this.pathRequest = 'provider';

      this.enterpriseForm = this.formBuilder.group({
        id: this.id,
        name: this.name,
        qualification: this.qualification,
        access_to_enterprise: this.access_to_enterprise,
        address: this.address,
        zipcode: this.zipcode,
        city: this.city,
        siret: this.siret,
        picture: this.picture
      });
    } else if (this.activatedRoute.snapshot.parent.url[0] && this.activatedRoute.snapshot.parent.url[0].path === 'enterprises') {
      this.view = 'enterprises';
      this.pathRequest = 'enterprise';

      this.enterpriseForm = this.formBuilder.group({
        id: this.id,
        name: this.name,
        address: this.address,
        zipcode: this.zipcode,
        city: this.city,
        siret: this.siret,
        picture: this.picture
      });
    }

    if (this.activatedRoute.snapshot.params.enterprise_id) {
      this.isNew = false;
      this.getEnterprise(this.activatedRoute.snapshot.params.enterprise_id);
    }

    this.connectedAs = JSON.parse(localStorage.getItem('connectedAs'));
    if (this.connectedAs) {
      this.monoOffer = JSON.parse(localStorage.getItem('connectedAsIsMonoOffer'));
      this.discoverOffer = JSON.parse(localStorage.getItem('connectedAsIsDiscoverOffer'));
    } else {
      this.monoOffer = JSON.parse(localStorage.getItem('isMonoOffer'));
      this.discoverOffer = JSON.parse(localStorage.getItem('isDiscoverOffer'));
    }
  }

  ngAfterViewInit() {
    Dropzones();
    this.modalEnterprise.open();

    this.mapsAPILoader.load().then(() => {
      this.sessionToken = new google.maps.places.AutocompleteSessionToken();
      this.autocompleteService = new google.maps.places.AutocompleteService();
    });
  }

  ngOnDestroy() {
    const dropZones = document.getElementsByClassName('dz-hidden-input') as HTMLCollection;
    for (let i = 0; i < dropZones.length; i++) {
      dropZones[i].remove();
    }
    this.modalEnterprise.close();
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  getEnterprise(id) {
    this.loaderContent = true;

    let request;
    if (this.activatedRoute.snapshot.parent.params.establishment_id &&
      this.activatedRoute.snapshot.parent.url[4].path && this.activatedRoute.snapshot.parent.url[4].path === 'providers') {
      request = this.enterprisesService.getProvider(id);
    }
    if (this.activatedRoute.snapshot.parent.url[0] && this.activatedRoute.snapshot.parent.url[0].path === 'providers') {
      request = this.enterprisesService.getProvider(id);
    } else if (this.activatedRoute.snapshot.parent.url[0] && this.activatedRoute.snapshot.parent.url[0].path === 'enterprises') {
      request = this.enterprisesService.getEnterprise(id);
    }

    request.subscribe(response => {
      if (response['success']) {
        this.canUpdate = response['canUpdate'];
        this.archive = response['archive'];
        this.enterpriseForm.patchValue(response['enterprise']);
        if (!this.canUpdate) {
          this.enterpriseForm.disable();
        }
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.enterpriseForm, this.notifier);
      }
      this.loaderContent = false;
    });
  }

  onSearch(term: string) {
    this.searchTerm = term;
    if (this.searchTerm === '') { return; }
    this.autocompleteService.getPlacePredictions({
      input: term,
      sessionToken: this.sessionToken,
      componentRestrictions: { country: 'fr' }
    }, data => {
      if (data) {
        this.results = data;
      } else {
        this.results = [];
      }
    });
  }
  selectPlace(data) {
    const request = {
      placeId: data.place_id,
      sessionToken: this.sessionToken
    };
    const service = new google.maps.places.PlacesService($('#service-helper').get(0));
    service.getDetails(request, (place, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        let route = '';
        let streetNumber = '';

        for (let i = 0; i < place.address_components.length; i++) {
          const component = place.address_components[i];
          const addressType = component.types[0];

          switch (addressType) {
            case 'street_number':
              streetNumber = component.long_name;
              break;
            case 'route':
              route = component.short_name;
              break;
            case 'locality':
              this.city.setValue(component.long_name);
              break;
            case 'postal_code':
              this.zipcode.setValue(component.long_name);
              break;
          }
        }

        if (route) {
          this.address.setValue(streetNumber && route ? streetNumber + ' ' + route : route);
        }
      }
    });
    this.results = [];
  }

  createEnterprise() {
    this.loader = true;

    this.enterprisesService.createEnterprise(this.enterpriseForm.value).subscribe(response => {
      if (response['success']) {
        this.modalEnterprise.close();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.enterpriseForm, this.notifier);
      }
      this.loader = false;
    });
  }

  updateEnterprise() {
    let request;
    this.loader = true;

    if (this.view === 'providers') {
      request = this.enterprisesService.updateProvider(this.id.value, this.enterpriseForm.value);
    } else {
      request = this.enterprisesService.updateEnterprise(this.id.value, this.enterpriseForm.value);
    }

    request.subscribe(response => {
      if (response['success']) {
        this.modalEnterprise.close();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.enterpriseForm, this.notifier);
      }
      this.loader = false;
    });
  }

  chooseFile() {
    const element: HTMLElement = document.getElementById('inputFile') as HTMLElement;
    element.click();
  }

  onFileChange(event) {
    this.picture.setValue('');

    const reader = new FileReader();

    if (event.target && event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.picture.setValue(reader.result);
      };
    } else {
      this.picture.setValue(event.file);
    }
    const element: HTMLInputElement = document.getElementById('inputFile') as HTMLInputElement;
    element.value = '';
  }

  checkPicture() {
    if (this.picture.value.indexOf(';base64,') !== -1) {
      return this.picture.value;
    } else {
      return environment.SERVER_HOST + 'img/enterprises/' + this.picture.value;
    }
  }

  removeFile() {
    this.picture.setValue(null);

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  addArchive() {
    this.loaderContent = true;

    this.archivesService.addArchive(this.activatedRoute.snapshot.params.enterprise_id).subscribe(response => {
      if (response['success']) {
        this.archive = response['archive'];
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.enterpriseForm, this.notifier);
      }
      this.loaderContent = false;
    });
  }
  downloadArchive() {
    this.loaderContent = true;
    this.archivesService.downloadArchive(this.activatedRoute.snapshot.params.enterprise_id).subscribe(response => {
        const url = environment.API_PATH + 'download/' + response['archive']['token'];
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.target = '_blank';
        a.click();
        a.remove();
      this.loaderContent = false;
    });
  }

  changeTabs(activeTab) {
    this.activeTab = activeTab;
  }
  prevTabs() {
    if (this.activeTab === 'secondPart') {
      this.activeTab = 'firstPart';
    }
  }
  nextTabs() {
    if (this.activeTab === 'firstPart') {
      this.activeTab = 'secondPart';
    }
  }

  bindModal(modal) {
    this.modalEnterprise = modal;
  }

  closeModal() {
    if (this.activatedRoute.snapshot.parent.params.establishment_id) {
      this.allRegistryProviders.getEnterprises();
      if (this.activatedRoute.snapshot.parent.url[4]) {
        this.router.navigate(['dashboard-registry/' +
        this.activatedRoute.snapshot.parent.params.registry_id + '/establishment/' +
        this.activatedRoute.snapshot.parent.params.establishment_id + '/providers']).then(() => {});
      } else {
        const url = this.authService.checkUrlStats(JSON.parse(localStorage.getItem('registries')));
        this.router.navigate([url]).then(() => {});
      }
    } else {
      this.allEnterprises.getEnterprises();
      if (this.activatedRoute.snapshot.parent.url[0]) {
        this.router.navigate([this.activatedRoute.snapshot.parent.url[0].path]).then(() => {});
      } else {
        const url = this.authService.checkUrlStats(JSON.parse(localStorage.getItem('registries')));
        this.router.navigate([url]).then(() => {});
      }
    }
  }
}
