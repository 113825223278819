import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AllEstablishmentsModule } from './all-establishments/all-establishments.module';
import { AllEstablishmentFilesModule } from './all-establishment-files/all-establishment-files.module';
import { UpdateEstablishmentModule } from './update-establishment/update-establishment.module';
import { UploadEstablishmentFileModule } from './upload-establishment-file/upload-establishment-file.module';
import { AddRegistriesModule } from './add-registries/add-registries.module';
import { EstablishmentsRequestService } from '../../services/establishments.request.service';
import {MassEditRegistriesModule} from './mass-edit-registries/mass-edit-registries.module';
import { MassImportModule } from './mass-import/mass-import.module';
import { UpdateEstablishmentDescriptionModule } from './update-establishment-description/update-establishment-description.module';
import { UpdateGroupModule } from './update-group/update-group.module';

@NgModule({
  imports: [
    CommonModule,
    AllEstablishmentsModule,
    AllEstablishmentFilesModule,
    UpdateEstablishmentModule,
    AddRegistriesModule,
    MassEditRegistriesModule,
    UploadEstablishmentFileModule,
    AddRegistriesModule,
    MassImportModule,
    UpdateEstablishmentDescriptionModule,
    UpdateGroupModule
  ],
  providers: [
    EstablishmentsRequestService
  ]
})
export class EstablishmentsModule {}
