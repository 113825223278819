import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgSelectModule } from "@ng-select/ng-select";
import { ModalRightModule } from "src/app/core/modal-right/modal-right.module";
import { UtilsModule } from "src/app/core/utils/utils.module";
import { ExportDownloadRequestService } from "src/app/services/export-download.request.service";
import { ExportDownloadComponent } from "./export-download.component";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    ModalRightModule,
    UtilsModule,
  ],
  providers: [ExportDownloadRequestService],
  declarations: [ExportDownloadComponent],
})
export class ExportDownloadModule {}
