import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { StatisticsRequestService } from "../../services/statistics.request.service";
import { NgSelectModule } from "@ng-select/ng-select";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
// import { DashboardCommissionsModule } from './dashboard-commissions/dashboard-commissions.module';
// import { DashboardObservationsModule } from './dashboard-observations/dashboard-observations.module';
import { DashboardProvidersModule } from "./dashboard-providers/dashboard-providers.module";
import { DashboardTrainingsModule } from "./dashboard-trainings/dashboard-trainings.module";
import { DashboardAsbestosModule } from "./dashboard-asbestos/dashboard-asbestos.module";
import { DashboardUniqueDocumentModule } from "./dashboard-unique-document/dashboard-unique-document.module";
import { DashboardAdminModule } from "./dashboard-admin/dashboard-admin.module";
import { DashboardHomeModule } from "./dashboard-home/dashboard-home.module";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    DashboardHomeModule,
    // DashboardObservationsModule,
    // DashboardCommissionsModule,
    DashboardProvidersModule,
    DashboardTrainingsModule,
    DashboardAsbestosModule,
    DashboardUniqueDocumentModule,
    DashboardAdminModule,
  ],
  providers: [StatisticsRequestService],
  declarations: [],
})
export class DashboardModule {}
