<app-modal-right (eventOnClose)="closeModal()" #prevention>{{ bindModal(prevention) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">{{ isNew ? 'Création' : canUpdate ? 'Mise à jour' : 'Consultation' }}</h6>

        <h1 class="header-title">Plan de prévention</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <form [formGroup]="preventionForm" [hidden]="loaderContent">

      <ul class="nav nav-tabs" role="tablist">
        <li class="nav-item col-2">
          <a class="nav-link href" [ngClass]="{ 'active': activeTab === 1}" (click)="changeTabs(1)">
            1 <i class="fe fe-info ml-2 tooltipHtml" title="Entreprise utilisatrice et Description des opérations"></i>
          </a>
        </li>
        <li class="nav-item col-2">
          <a class="nav-link href" [ngClass]="{ 'active': activeTab === 2}" (click)="changeTabs(2)">
            2 <i class="fe fe-info ml-2 tooltipHtml" title="Entreprises extérieures"></i>
          </a>
        </li>
        <li class="nav-item col-2">
          <a class="nav-link href" [ngClass]="{ 'active': activeTab === 3}" (click)="changeTabs(3)">
            3 <i class="fe fe-info ml-2 tooltipHtml" title="Visite préalable"></i>
          </a>
        </li>
        <li class="nav-item col-2">
          <a class="nav-link href" [ngClass]="{ 'active': activeTab === 4}" (click)="changeTabs(4)">
            4 <i class="fe fe-info ml-2 tooltipHtml" title="Matériels et installations mis à disposition"></i>
          </a>
        </li>
        <li class="nav-item col-2">
          <a class="nav-link href" [ngClass]="{ 'active': activeTab === 5}" (click)="changeTabs(5)">
            5 <i class="fe fe-info ml-2 tooltipHtml" title="Organisation des 1ers secours et instructions de sécurité"></i>
          </a>
        </li>
        <li class="nav-item col-2">
          <a class="nav-link href" [ngClass]="{ 'active': activeTab === 6}" (click)="changeTabs(6)">
            6 <i class="fe fe-info ml-2 tooltipHtml" title="Analyse des risques interférents"></i>
          </a>
        </li>
      </ul>

      <div class="tab-content">
        <!-- STEP ONE -->
        <div class="tab-pane fade" [ngClass]="{ 'show active': activeTab === 1}">
          <div class="card bg-light border">
            <div class="card-body">
              <p class="small text-muted mb-2">
                Le plan de prévention doit être établi avant toute intervention dans une entreprise utilisatrice par une
                entreprise extérieure, en application des articles R .4511-1 à R. 4511-10 du Code du travail.
                Avant le début des travaux et à l'initiative de l’employeur de l'entreprise utilisatrice, les employeurs
                définissent à l’issue d’une inspection commune les mesures à prendre par chacun d'eux en vue
                d'éviter les risques professionnels, qui peuvent résulter de l'exercice simultané, en un même lieu,
                des installations et activités des deux entreprises.
              </p>
            </div>
          </div>

          <!-- REFERENCY -->
          <div class="form-group">
            <label for="validationReferency">Référence</label>
            <input type="text" formControlName="referency" class="form-control" id="validationReferency" placeholder="Référence" [ngClass]="{ 'is-invalid': referency.invalid && (referency.dirty || referency.touched) }">
            <div *ngIf="referency.invalid && (referency.dirty || referency.touched)" class="invalid-feedback">
              {{ getErrorMessage("referency") }}
            </div>
          </div>

          <h2 class="header-title mb-3">Entreprise utilisatrice</h2>

          <!-- EU SOCIAL REASON -->
          <div class="form-group">
            <label for="euSocialReason" required><strong>Raison sociale</strong></label>
            <input type="text" formControlName="eu_social_reason" class="form-control font_strong" id="euSocialReason" placeholder="Raison sociale" [ngClass]="{ 'is-invalid': eu_social_reason.invalid && (eu_social_reason.dirty || eu_social_reason.touched) }">
            <div *ngIf="eu_social_reason.invalid && (eu_social_reason.dirty || eu_social_reason.touched)" class="invalid-feedback">
              {{ getErrorMessage("eu_social_reason") }}
            </div>
          </div>

          <!-- EU ADDRESS -->
          <div class="form-group">
            <label for="euAddress" required>Adresse</label>
            <input type="text" formControlName="eu_address" class="form-control" id="euAddress" placeholder="Adresse" [ngClass]="{ 'is-invalid': eu_address.invalid && (eu_address.dirty || eu_address.touched) }">
            <div *ngIf="eu_address.invalid && (eu_address.dirty || eu_address.touched)" class="invalid-feedback">
              {{ getErrorMessage("eu_address") }}
            </div>
          </div>

          <!-- EU PHONE -->
          <div class="form-group">
            <label for="euPhone" required>Téléphone</label>
            <input type="text" formControlName="eu_phone" class="form-control" id="euPhone" placeholder="Téléphone" [ngClass]="{ 'is-invalid': eu_phone.invalid && (eu_phone.dirty || eu_phone.touched) }">
            <div *ngIf="eu_phone.invalid && (eu_phone.dirty || eu_phone.touched)" class="invalid-feedback">
              {{ getErrorMessage("eu_phone") }}
            </div>
          </div>

          <!-- EU LEGAL MANAGER NAME -->
          <div class="form-group">
            <label for="euLegalManagerName" required>Représentant légal</label>
            <input type="text" formControlName="eu_legal_manager_name" class="form-control" id="euLegalManagerName" placeholder="Représentant légal" [ngClass]="{ 'is-invalid': eu_legal_manager_name.invalid && (eu_legal_manager_name.dirty || eu_legal_manager_name.touched) }">
            <div *ngIf="eu_legal_manager_name.invalid && (eu_legal_manager_name.dirty || eu_legal_manager_name.touched)" class="invalid-feedback">
              {{ getErrorMessage("eu_legal_manager_name") }}
            </div>
          </div>

          <!-- EU LEGAL MANAGER FUNCTION -->
          <div class="form-group">
            <label for="euLegalManagerFunction" required>Fonction</label>
            <input type="text" formControlName="eu_legal_manager_function" class="form-control" id="euLegalManagerFunction" placeholder="Fonction" [ngClass]="{ 'is-invalid': eu_legal_manager_function.invalid && (eu_legal_manager_function.dirty || eu_legal_manager_function.touched) }">
            <div *ngIf="eu_legal_manager_function.invalid && (eu_legal_manager_function.dirty || eu_legal_manager_function.touched)" class="invalid-feedback">
              {{ getErrorMessage("eu_legal_manager_function") }}
            </div>
          </div>

          <!-- EU LEGAL MANAGER PHONE -->
          <div class="form-group">
            <label for="euLegalManagerPhone" required>Coordonnées</label>
            <input type="text" formControlName="eu_legal_manager_phone" class="form-control" id="euLegalManagerPhone" placeholder="Coordonnées" [ngClass]="{ 'is-invalid': eu_legal_manager_phone.invalid && (eu_legal_manager_phone.dirty || eu_legal_manager_phone.touched) }">
            <div *ngIf="eu_legal_manager_phone.invalid && (eu_legal_manager_phone.dirty || eu_legal_manager_phone.touched)" class="invalid-feedback">
              {{ getErrorMessage("eu_legal_manager_phone") }}
            </div>
          </div>

          <!-- EU SECURITY MANAGER NAME -->
          <div class="form-group">
            <label for="euSecurityManagerName" required>Responsable sécurité / travaux</label>
            <input type="text" formControlName="eu_security_manager_name" class="form-control" id="euSecurityManagerName" placeholder="Responsable sécurité / travaux" [ngClass]="{ 'is-invalid': eu_security_manager_name.invalid && (eu_security_manager_name.dirty || eu_security_manager_name.touched) }">
            <div *ngIf="eu_security_manager_name.invalid && (eu_security_manager_name.dirty || eu_security_manager_name.touched)" class="invalid-feedback">
              {{ getErrorMessage("eu_security_manager_name") }}
            </div>
          </div>

          <!-- EU SECURITY MANAGER FUNCTION -->
          <div class="form-group">
            <label for="euSecurityManagerFunction" required>Fonction</label>
            <input type="text" formControlName="eu_security_manager_function" class="form-control" id="euSecurityManagerFunction" placeholder="Fonction" [ngClass]="{ 'is-invalid': eu_security_manager_function.invalid && (eu_security_manager_function.dirty || eu_security_manager_function.touched) }">
            <div *ngIf="eu_security_manager_function.invalid && (eu_security_manager_function.dirty || eu_security_manager_function.touched)" class="invalid-feedback">
              {{ getErrorMessage("eu_security_manager_function") }}
            </div>
          </div>

          <!-- EU SECURITY MANAGER PHONE -->
          <div class="form-group">
            <label for="euSecurityManagerPhone" required>Coordonnées</label>
            <input type="text" formControlName="eu_security_manager_phone" class="form-control" id="euSecurityManagerPhone" placeholder="Coordonnées" [ngClass]="{ 'is-invalid': eu_security_manager_phone.invalid && (eu_security_manager_phone.dirty || eu_security_manager_phone.touched) }">
            <div *ngIf="eu_security_manager_phone.invalid && (eu_security_manager_phone.dirty || eu_security_manager_phone.touched)" class="invalid-feedback">
              {{ getErrorMessage("eu_security_manager_phone") }}
            </div>
          </div>

          <!-- EU INFORMATIONS -->
          <div class="form-group">
            <label for="euInformations" required>Autres informations utiles</label>
            <input type="text" formControlName="eu_informations" class="form-control" id="euInformations" placeholder="Autres informations utiles" [ngClass]="{ 'is-invalid': eu_informations.invalid && (eu_informations.dirty || eu_informations.touched) }">
            <div *ngIf="eu_informations.invalid && (eu_informations.dirty || eu_informations.touched)" class="invalid-feedback">
              {{ getErrorMessage("eu_informations") }}
            </div>
          </div>

          <h2 class="header-title mb-3">Opération nécessitant un plan de prévention</h2>

          <!-- OPERATION -->
          <div class="form-group">
            <label for="operation" required>Nature de l'opération</label>
            <input type="text" formControlName="operation" class="form-control" id="operation" placeholder="Nature de l'opération" [ngClass]="{ 'is-invalid': operation.invalid && (operation.dirty || operation.touched) }">
            <div *ngIf="operation.invalid && (operation.dirty || operation.touched)" class="invalid-feedback">
              {{ getErrorMessage("operation") }}
            </div>
          </div>

          <!-- PLACE -->
          <div class="form-group">
            <label for="place" required>Localisation</label>
            <input type="text" formControlName="place" class="form-control" id="place" placeholder="Localisation" [ngClass]="{ 'is-invalid': place.invalid && (place.dirty || place.touched) }">
            <div *ngIf="place.invalid && (place.dirty || place.touched)" class="invalid-feedback">
              {{ getErrorMessage("place") }}
            </div>
          </div>

          <!-- BEGIN DATE -->
          <div class="form-group">
            <label for="beginDate" required>Date de début</label>
            <input type="text" formControlName="begin_date" class="form-control" id="beginDate" placeholder="Date de début" data-toggle="flatpickr" [ngClass]="{ 'is-invalid': begin_date.invalid && (begin_date.dirty || begin_date.touched) }">
            <div *ngIf="begin_date.invalid && (begin_date.dirty || begin_date.touched)" class="invalid-feedback">
              {{ getErrorMessage("begin_date") }}
            </div>
          </div>

          <!-- END DATE -->
          <div class="form-group">
            <label for="endDate" required>Date de fin</label>
            <input type="text" formControlName="end_date" class="form-control" id="endDate" placeholder="Date de fin" data-toggle="flatpickr" [ngClass]="{ 'is-invalid': end_date.invalid && (end_date.dirty || end_date.touched) }">
            <div *ngIf="end_date.invalid && (end_date.dirty || end_date.touched)" class="invalid-feedback">
              {{ getErrorMessage("end_date") }}
            </div>
          </div>

          <!-- DURATION ESTIMATION -->
          <div class="form-group">
            <label for="durationEstimation" required>Durée prévisible (h)</label>
            <input type="text" formControlName="duration_estimation" class="form-control" id="durationEstimation" placeholder="Durée prévisible (h)" [ngClass]="{ 'is-invalid': duration_estimation.invalid && (duration_estimation.dirty || duration_estimation.touched) }">
            <div *ngIf="duration_estimation.invalid && (duration_estimation.dirty || duration_estimation.touched)" class="invalid-feedback">
              {{ getErrorMessage("duration_estimation") }}
            </div>
          </div>

          <!-- EFFECTIF GLOBAL -->
          <div class="form-group">
            <label for="effectifGlobal" required>Effectif global prévisible</label>
            <input type="number" formControlName="effectif_global" class="form-control" id="effectifGlobal" placeholder="Effectif global prévisible" [ngClass]="{ 'is-invalid': effectif_global.invalid && (effectif_global.dirty || effectif_global.touched) }">
            <div *ngIf="effectif_global.invalid && (effectif_global.dirty || effectif_global.touched)" class="invalid-feedback">
              {{ getErrorMessage("effectif_global") }}
            </div>
          </div>

          <!-- TIME SLOT -->
          <div class="form-group">
            <label for="timeSlot" required>Plage horaire d'intervention</label>
            <input type="text" formControlName="time_slot" class="form-control" id="timeSlot" placeholder="Plage horaire d'intervention" [ngClass]="{ 'is-invalid': time_slot.invalid && (time_slot.dirty || time_slot.touched) }">
            <div *ngIf="time_slot.invalid && (time_slot.dirty || time_slot.touched)" class="invalid-feedback">
              {{ getErrorMessage("time_slot") }}
            </div>
          </div>

          <!-- WORKS -->
          <div class="form-group">
            <label for="works" required>Travaux sous-traités</label>
            <textarea type="text" formControlName="works" class="form-control" id="works" placeholder="Travaux sous-traités" [ngClass]="{ 'is-invalid': works.invalid && (works.dirty || works.touched) }"></textarea>
            <div *ngIf="works.invalid && (works.dirty || works.touched)" class="invalid-feedback">
              {{ getErrorMessage("works") }}
            </div>
          </div>

          <!-- DESCRIPTIONS -->
          <div class="form-group">
            <label for="descriptions" required>Descriptions succinctes</label>
            <textarea type="text" formControlName="descriptions" class="form-control" id="descriptions" placeholder="Descriptions succintes" [ngClass]="{ 'is-invalid': descriptions.invalid && (descriptions.dirty || descriptions.touched) }"></textarea>
            <div *ngIf="descriptions.invalid && (descriptions.dirty || descriptions.touched)" class="invalid-feedback">
              {{ getErrorMessage("descriptions") }}
            </div>
          </div>

          <!-- DOCUMENTS -->
          <h2 class="header-title mb-3">Documents</h2>
          <div class="form-group" *ngIf="isNew || canUpdate">
            <div class="dropzone dropzone-multiple dz-clickable" appDragDrop (dropHandler)="onFileChange($event)">
              <div class="dz-default dz-message" (click)="chooseFile()">
                <span>Déposer vos fichiers ici pour télécharger</span>
              </div>
              <input id="inputFile" (change)="onFileChange($event)" type="file" [hidden]="true" multiple>
            </div>
          </div>
          <div class="card" *ngFor="let file of files; let index = index">
            <div class="card-body">
              <div class="d-flex align-content-center justify-content-between">
                <h4 class="card-title mb-0 name align-self-center">
                  {{ file.name + '.' + file.extension }}
                </h4>

                <a class="href tooltipHtml" data-placement="bottom" title="Supprimer" (click)="removeFile(index)" *ngIf="isNew || canUpdate">
                  <i class="fe fe-trash"></i>
                </a>
              </div>
            </div>
          </div>
        </div>

        <!-- STEP TWO - ENTERPRISES -->
        <div class="tab-pane fade" [ngClass]="{ 'show active': activeTab === 2}">
          <h2 class="header-title mb-3">Entreprises extérieures</h2>
          <div class="d-flex justify-content-center align-items-center mt-4" *ngIf="!preventionForm.controls.prevention_providers.value.length">
            Aucune entreprise extérieure
          </div>

          <div formArrayName="prevention_providers" class="mb-4">
            <div [formGroupName]="i" *ngFor="let provider of preventionForm['controls'].prevention_providers['controls'] as FormGroup; let i = index; let last = last" class="card">
              <div class="card-header">
                <div class="d-flex justify-content-between">
                  <a data-toggle="collapse" [href]="'#provider' + i" [attr.aria-controls]="'provider' + i" role="button" aria-expanded="true">
                    Entreprise extérieure {{ i + 1 }}
                  </a>

                  <a class="href tooltipHtml" data-placement="bottom" title="Supprimer" (click)="deletePreventionProvider(i)">
                    <i class="fe fe-trash"></i>
                  </a>
                </div>
              </div>

              <div class="card-body collapse show" [id]="'provider' + i">

                <div class="form-group">
                  <label for="euSocialReason" required><strong>Raison sociale</strong></label>
                  <input type="text" placeholder="Raison sociale" formControlName="social_reason" class="form-control d-inline-block col-12 font_strong" [ngClass]="{ 'is-invalid': provider['controls'].social_reason.invalid && (provider['controls'].social_reason.dirty || provider['controls'].social_reason.touched) }">
                  <div *ngIf="provider['controls'].social_reason.invalid && (provider['controls'].social_reason.dirty || provider['controls'].social_reason.touched)" class="invalid-feedback">
                    {{ getErrorMessage("social_reason") }}
                  </div>
                </div>

                <div class="form-group">
                  <label for="euSocialReason">Adresse</label>
                  <input type="text" placeholder="Adresse" formControlName="address" class="form-control d-inline-block col-12" [ngClass]="{ 'is-invalid': provider['controls'].address.invalid && (provider['controls'].address.dirty || provider['controls'].address.touched) }">
                  <div *ngIf="provider['controls'].address.invalid && (provider['controls'].address.dirty || provider['controls'].address.touched)" class="invalid-feedback">
                    {{ getErrorMessage("address") }}
                  </div>
                </div>

                <div class="form-group">
                  <label for="euSocialReason">Tél</label>
                  <input type="text" placeholder="Tél" formControlName="phone" class="form-control d-inline-block col-12" [ngClass]="{ 'is-invalid': provider['controls'].phone.invalid && (provider['controls'].phone.dirty || provider['controls'].phone.touched) }">
                  <div *ngIf="provider['controls'].phone.invalid && (provider['controls'].phone.dirty || provider['controls'].phone.touched)" class="invalid-feedback">
                    {{ getErrorMessage("phone") }}
                  </div>
                </div>

                <div class="form-group">
                  <label for="euSocialReason">Représentant légal</label>
                  <input type="text" placeholder="Représentant légal" formControlName="legal_manager_name" class="form-control d-inline-block col-12" [ngClass]="{ 'is-invalid': provider['controls'].legal_manager_name.invalid && (provider['controls'].legal_manager_name.dirty || provider['controls'].legal_manager_name.touched) }">
                  <div *ngIf="provider['controls'].legal_manager_name.invalid && (provider['controls'].legal_manager_name.dirty || provider['controls'].legal_manager_name.touched)" class="invalid-feedback">
                    {{ getErrorMessage("legal_manager_name") }}
                  </div>
                </div>

                <div class="form-group">
                  <label for="euSocialReason">Fonction</label>
                  <input type="text" placeholder="Fonction" formControlName="legal_manager_function" class="form-control d-inline-block col-12" [ngClass]="{ 'is-invalid': provider['controls'].legal_manager_function.invalid && (provider['controls'].legal_manager_function.dirty || provider['controls'].legal_manager_function.touched) }">
                  <div *ngIf="provider['controls'].legal_manager_function.invalid && (provider['controls'].legal_manager_function.dirty || provider['controls'].legal_manager_function.touched)" class="invalid-feedback">
                    {{ getErrorMessage("legal_manager_function") }}
                  </div>
                </div>

                <div class="form-group">
                  <label for="euSocialReason">Coordonnées</label>
                  <input type="text" placeholder="Coordonnées" formControlName="legal_manager_phone" class="form-control d-inline-block col-12" [ngClass]="{ 'is-invalid': provider['controls'].legal_manager_phone.invalid && (provider['controls'].legal_manager_phone.dirty || provider['controls'].legal_manager_phone.touched) }">
                  <div *ngIf="provider['controls'].legal_manager_phone.invalid && (provider['controls'].legal_manager_phone.dirty || provider['controls'].legal_manager_phone.touched)" class="invalid-feedback">
                    {{ getErrorMessage("legal_manager_phone") }}
                  </div>
                </div>

                <div class="form-group">
                  <label for="euSocialReason">Autres informations utiles</label>
                  <input type="text" placeholder="Autres informations utiles" formControlName="informations" class="form-control d-inline-block col-12" [ngClass]="{ 'is-invalid': provider['controls'].informations.invalid && (provider['controls'].informations.dirty || provider['controls'].informations.touched) }">
                  <div *ngIf="provider['controls'].informations.invalid && (provider['controls'].informations.dirty || provider['controls'].informations.touched)" class="invalid-feedback">
                    {{ getErrorMessage("informations") }}
                  </div>
                </div>

                <div formArrayName="prevention_providers" class="col-10 offset-1">
                  <div [formGroupName]="j" *ngFor="let sub_provider of provider['controls']['prevention_providers']['controls']; let j = index; let sub_last = last" class="card">
                    <div class="card-header">
                      <div class="d-flex justify-content-between">
                        <a data-toggle="collapse" [href]="'#sub_provider' + i + '-' + j" [attr.aria-controls]="'sub_provider' + i + '-' + j" role="button" aria-expanded="true">
                          Sous-traitant {{ i + 1 }}.{{ j + 1 }}
                        </a>

                        <a class="href tooltipHtml" data-placement="bottom" title="Supprimer" (click)="deletePreventionSubProvider(i, j)">
                          <i class="fe fe-trash"></i>
                        </a>
                      </div>
                    </div>

                    <div class="card-body collapse show" [id]="'sub_provider' + i + '-' + j">

                      <div class="form-group">
                        <label for="euSocialReason" required><strong>Raison sociale</strong></label>
                        <input type="text" placeholder="Raison sociale" formControlName="social_reason" class="form-control d-inline-block col-12 font_strong" [ngClass]="{ 'is-invalid': sub_provider['controls'].social_reason.invalid && (sub_provider['controls'].social_reason.dirty || sub_provider['controls'].social_reason.touched) }">
                        <div *ngIf="sub_provider['controls'].social_reason.invalid && (sub_provider['controls'].social_reason.dirty || sub_provider['controls'].social_reason.touched)" class="invalid-feedback">
                          {{ getErrorMessage("social_reason") }}
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="euSocialReason">Adresse</label>
                        <input type="text" placeholder="Adresse" formControlName="address" class="form-control d-inline-block col-12" [ngClass]="{ 'is-invalid': sub_provider['controls'].address.invalid && (sub_provider['controls'].address.dirty || sub_provider['controls'].address.touched) }">
                        <div *ngIf="sub_provider['controls'].address.invalid && (sub_provider['controls'].address.dirty || sub_provider['controls'].address.touched)" class="invalid-feedback">
                          {{ getErrorMessage("address") }}
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="euSocialReason">Tél</label>
                        <input type="text" placeholder="Tél" formControlName="phone" class="form-control d-inline-block col-12" [ngClass]="{ 'is-invalid': sub_provider['controls'].phone.invalid && (sub_provider['controls'].phone.dirty || sub_provider['controls'].phone.touched) }">
                        <div *ngIf="sub_provider['controls'].phone.invalid && (sub_provider['controls'].phone.dirty || sub_provider['controls'].phone.touched)" class="invalid-feedback">
                          {{ getErrorMessage("phone") }}
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="euSocialReason">Représentant légal</label>
                        <input type="text" placeholder="Représentant légal" formControlName="legal_manager_name" class="form-control d-inline-block col-12" [ngClass]="{ 'is-invalid': sub_provider['controls'].legal_manager_name.invalid && (sub_provider['controls'].legal_manager_name.dirty || sub_provider['controls'].legal_manager_name.touched) }">
                        <div *ngIf="sub_provider['controls'].legal_manager_name.invalid && (sub_provider['controls'].legal_manager_name.dirty || sub_provider['controls'].legal_manager_name.touched)" class="invalid-feedback">
                          {{ getErrorMessage("legal_manager_name") }}
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="euSocialReason">Fonction</label>
                        <input type="text" placeholder="Fonction" formControlName="legal_manager_function" class="form-control d-inline-block col-12" [ngClass]="{ 'is-invalid': sub_provider['controls'].legal_manager_function.invalid && (sub_provider['controls'].legal_manager_function.dirty || sub_provider['controls'].legal_manager_function.touched) }">
                        <div *ngIf="sub_provider['controls'].legal_manager_function.invalid && (sub_provider['controls'].legal_manager_function.dirty || sub_provider['controls'].legal_manager_function.touched)" class="invalid-feedback">
                          {{ getErrorMessage("legal_manager_function") }}
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="euSocialReason">Coordonnées</label>
                        <input type="text" placeholder="Coordonnées" formControlName="legal_manager_phone" class="form-control d-inline-block col-12" [ngClass]="{ 'is-invalid': sub_provider['controls'].legal_manager_phone.invalid && (sub_provider['controls'].legal_manager_phone.dirty || sub_provider['controls'].legal_manager_phone.touched) }">
                        <div *ngIf="sub_provider['controls'].legal_manager_phone.invalid && (sub_provider['controls'].legal_manager_phone.dirty || sub_provider['controls'].legal_manager_phone.touched)" class="invalid-feedback">
                          {{ getErrorMessage("legal_manager_phone") }}
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="euSocialReason">Autres informations utiles</label>
                        <input type="text" placeholder="Autres informations utiles" formControlName="informations" class="form-control d-inline-block col-12" [ngClass]="{ 'is-invalid': sub_provider['controls'].informations.invalid && (sub_provider['controls'].informations.dirty || sub_provider['controls'].informations.touched) }">
                        <div *ngIf="sub_provider['controls'].informations.invalid && (sub_provider['controls'].informations.dirty || sub_provider['controls'].informations.touched)" class="invalid-feedback">
                          {{ getErrorMessage("informations") }}
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <button class="btn btn-primary col-6 offset-3 my-1" (click)="addPreventionSubProvider(i)">
                  <i class="fe fe-plus"></i> Ajouter un sous-traitant
                </button>

              </div>

            </div>
          </div>

          <button class="btn btn-primary col-6 offset-3 mb-3" (click)="addPreventionProvider()">
            <i class="fe fe-plus"></i> Ajouter une entreprise
          </button>

        </div>

        <!-- STEP THREE - VISIT -->
        <div class="tab-pane fade" [ngClass]="{ 'show active': activeTab === 3}">
          <h2 class="header-title mb-3">Visite préalable commune</h2>
          <div class="form-group">
            <label required>Date de la visite préalable</label>
            <input type="text" placeholder="Date de la visite préalable" formControlName="visit_date" data-toggle="flatpickr"  class="form-control d-inline-block col-12" [ngClass]="{ 'is-invalid': preventionForm['controls'].visit_date.invalid && (preventionForm['controls'].visit_date.dirty || preventionForm['controls'].visit_date.touched) }">
            <div *ngIf="preventionForm['controls'].visit_date.invalid && (preventionForm['controls'].visit_date.dirty || preventionForm['controls'].visit_date.touched)" class="invalid-feedback">
              {{ getErrorMessage("visit_date") }}
            </div>
          </div>
          <div class="form-group">
            <button class="btn btn-primary col-6 offset-3 mb-4" (click)="autofillParticipants()">
              Préremplir la liste des entreprises
            </button>
            <div formArrayName="participants">
              <div [formGroupName]="i" *ngFor="let participant of preventionForm['controls'].participants['controls'] as FormGroup; let i = index; let last = last" class="card">
                <div class="card-header">
                  <div class="d-flex justify-content-between">
                    <a data-toggle="collapse" [href]="'#participant' + i" [attr.aria-controls]="'participant' + i" role="button" aria-expanded="true">
                      Entreprise {{ i + 1 }}
                    </a>

                    <a class="href tooltipHtml" data-placement="bottom" title="Supprimer" (click)="deleteParticipant(i)">
                      <i class="fe fe-trash"></i>
                    </a>
                  </div>
                </div>

                <div class="card-body collapse show" [id]="'participant' + i">
                  <div class="form-group">
                    <label for="euSocialReason" required>Raison sociale</label>
                    <input type="text" placeholder="Raison sociale" formControlName="name" class="form-control d-inline-block col-12" [ngClass]="{ 'is-invalid': participant['controls'].name.invalid && (participant['controls'].name.dirty || participant['controls'].name.touched) }">
                  </div>

                  <div formArrayName="users"  class="col-10 offset-1">
                    <div [formGroupName]="j" *ngFor="let users of participant['controls']['users']['controls']; let j = index; let sub_last = last" class="card">
                      <div class="card-header">
                        <div class="d-flex justify-content-between">
                          <a data-toggle="collapse" [href]="'#user_participant' + i + '-' + j" [attr.aria-controls]="'user_participant' + i + '-' + j" role="button" aria-expanded="true">
                            Participant {{ i + 1 }}.{{ j + 1 }}
                          </a>

                          <a class="href tooltipHtml" data-placement="bottom" title="Supprimer" (click)="deleteUser(i, j)">
                            <i class="fe fe-trash"></i>
                          </a>
                        </div>
                      </div>

                      <div class="card-body collapse show" [id]="'user_participant' + i + '-' + j">
                        <div class="form-group">
                          <label for="euSocialReason" required>Nom du participant</label>
                          <input type="text" placeholder="Raison sociale" formControlName="name" class="form-control d-inline-block col-12" [ngClass]="{ 'is-invalid': users['controls'].name.invalid && (users['controls'].name.dirty || users['controls'].name.touched) }">
                        </div>

                        <div class="form-group">
                          <label for="euSocialReason" required>Fonction</label>
                          <input type="text" placeholder="Raison sociale" formControlName="role" class="form-control d-inline-block col-12" [ngClass]="{ 'is-invalid': users['controls'].role.invalid && (users['controls'].role.dirty || users['controls'].role.touched) }">
                        </div>
                      </div>
                    </div>
                  </div>

                  <button class="btn btn-primary col-6 offset-3 my-1" (click)="addUser(i)">
                    <i class="fe fe-plus"></i> Ajouter un participant
                  </button>
                </div>

              </div>

            </div>

            <div class="d-flex justify-content-center align-items-center mt-4" *ngIf="!preventionForm.controls.participants.value.length">
              Aucune entreprise
            </div>
          </div>
          <div class="form-group">
            <button class="btn btn-primary col-6 offset-3 mb-3" (click)="addParticipant()">
              <i class="fe fe-plus"></i> Ajouter une entreprise
            </button>
          </div>

          <div formGroupName="actions" *ngIf="preventionForm['controls'].actions['controls'] as action">
            <div *ngFor='let count of counter(8) let i = index;'>
              <div *ngIf="'bool' + (i + 1) as bool">
                <div *ngIf="'item' + (i + 1) as item">

                  <div class="form-group d-flex justify-content-start align-items-center">
                    <div class="col pl-0">{{ getLabel(bool) }}</div>
                    <div class="custom-checkbox-toggle">
                      <input type="checkbox" class="custom-control-input" id="{{ bool }}" [formControlName]="bool" [ngClass]="{ 'backgroundColor': action[bool].value }">
                      <label class="custom-control-label" [ngClass]="{ 'backgroundColor': action[bool].value }" for="{{ bool }}"></label>
                    </div>
                  </div>

                  <div *ngIf="action[bool].value" class="form-group">
                    <label for="{{ item }}">Observations</label>
                    <textarea type="text" [formControlName]="item" id="{{ item }}" class="form-control" [placeholder]="getLabel(bool)"></textarea>
                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>

        <!-- STEP FOUR - MATERIALS -->
        <div class="tab-pane fade" [ngClass]="{ 'show active': activeTab === 4}">
          <h2 class="header-title mb-3">Matériels et installations mis à disposition</h2>
          <div class="d-flex justify-content-center align-items-center mt-4" *ngIf="!preventionForm.controls.prevention_materials.value.length">
            Aucun materiel - aucune installation
          </div>

          <div formArrayName="prevention_materials" class="mb-4">
            <div [formGroupName]="i" *ngFor="let material of preventionForm['controls'].prevention_materials['controls'] as FormGroup; let i = index; let last = last" class="card">
              <div class="card-header">
                <div class="d-flex justify-content-between">
                  <a data-toggle="collapse" [href]="'#prevention_materials' + i" [attr.aria-controls]="'prevention_materials' + i" role="button" aria-expanded="true">
                    Matériel {{ i + 1 }}
                  </a>

                  <a class="href tooltipHtml" data-placement="bottom" title="Supprimer" (click)="deletePreventionMaterial(i)">
                    <i class="fe fe-trash"></i>
                  </a>
                </div>
              </div>

              <div class="card-body collapse show" [id]="'prevention_materials' + i">
                <div class="form-group">
                  <label for="euSocialReason" required>Nom du matériel / de l'installation</label>
                  <input type="text" placeholder="Nom du matériel / de l'installation" formControlName="name" class="form-control d-inline-block col-12" [ngClass]="{ 'is-invalid': material['controls'].name.invalid && (material['controls'].name.dirty || material['controls'].name.touched) }">
                  <div *ngIf="material['controls'].name.invalid && (material['controls'].name.dirty || material['controls'].name.touched)" class="invalid-feedback">
                    {{ getErrorMessage("name") }}
                  </div>
                </div>

                <div class="form-group">
                  <label for="euSocialReason">Référence éventuelle</label>
                  <input type="text" placeholder="Référence éventuelle" formControlName="referency" class="form-control d-inline-block col-12" [ngClass]="{ 'is-invalid': material['controls'].referency.invalid && (material['controls'].referency.dirty || material['controls'].referency.touched) }">
                  <div *ngIf="material['controls'].referency.invalid && (material['controls'].referency.dirty || material['controls'].referency.touched)" class="invalid-feedback">
                    {{ getErrorMessage("referency") }}
                  </div>
                </div>

                <div class="form-group">
                  <label for="euSocialReason">Conditions d’utilisation et de prêt (formations et habilitations requises, règles d’utilisation, etc…)</label>
                  <textarea type="text" placeholder="Conditions d’utilisation et de prêt (formations et habilitations requises, règles d’utilisation, etc…)" formControlName="utility" class="form-control d-inline-block col-12" [ngClass]="{ 'is-invalid': material['controls'].utility.invalid && (material['controls'].utility.dirty || material['controls'].utility.touched) }"></textarea>
                  <div *ngIf="material['controls'].utility.invalid && (material['controls'].utility.dirty || material['controls'].utility.touched)" class="invalid-feedback">
                    {{ getErrorMessage("utility") }}
                  </div>
                </div>

                <div class="form-group">
                  <label for="euSocialReason">Conditions d'entretien</label>
                  <textarea type="text" placeholder="Conditions d'entretien" formControlName="maintenance" class="form-control d-inline-block col-12" [ngClass]="{ 'is-invalid': material['controls'].maintenance.invalid && (material['controls'].maintenance.dirty || material['controls'].maintenance.touched) }"></textarea>
                  <div *ngIf="material['controls'].maintenance.invalid && (material['controls'].maintenance.dirty || material['controls'].maintenance.touched)" class="invalid-feedback">
                    {{ getErrorMessage("maintenance") }}
                  </div>
                </div>

                <div class="form-group">
                  <label for="euSocialReason">Autre</label>
                  <input type="text" placeholder="Autre" formControlName="other_informations" class="form-control d-inline-block col-12" [ngClass]="{ 'is-invalid': material['controls'].other_informations.invalid && (material['controls'].other_informations.dirty || material['controls'].other_informations.touched) }">
                  <div *ngIf="material['controls'].other_informations.invalid && (material['controls'].other_informations.dirty || material['controls'].other_informations.touched)" class="invalid-feedback">
                    {{ getErrorMessage("other_informations") }}
                  </div>
                </div>
              </div>

            </div>
          </div>

          <button class="btn btn-primary col-6 offset-3 mb-3" (click)="addPreventionMaterial()">
            Ajouter un matériel / une installation
          </button>
        </div>

        <!-- STEP FIVE - SECURITY -->
        <div class="tab-pane fade" [ngClass]="{ 'show active': activeTab === 5}">
          <h2 class="header-title mb-3">Organisation des premiers secours</h2>

          <div formArrayName="emergency_numbers" class="mb-4">
            <div [formGroupName]="i" *ngFor="let emergency_number of preventionForm['controls'].emergency_numbers['controls'] as FormGroup; let i = index; let last = last" class="card">
              <div class="card-header">
                <div class="d-flex justify-content-between">
                  <a data-toggle="collapse" [href]="'#emergency_numbers' + i" [attr.aria-controls]="'emergency_numbers' + i" role="button" aria-expanded="true">
                    Numéro {{ i + 1 }}
                  </a>

                  <a class="href tooltipHtml" data-placement="bottom" title="Supprimer" (click)="deleteEmergencyNumber(i)">
                    <i class="fe fe-trash"></i>
                  </a>
                </div>
              </div>

              <div class="card-body collapse show" [id]="'emergency_numbers' + i">
                <div class="form-group">
                  <label for="euSocialReason" required>Organisation</label>
                  <input type="text" placeholder="Organisation" formControlName="organisation" class="form-control d-inline-block col-12" [ngClass]="{ 'is-invalid': emergency_number['controls'].organisation.invalid && (emergency_number['controls'].organisation.dirty || emergency_number['controls'].organisation.touched) }">
                  <div *ngIf="emergency_number['controls'].organisation.invalid && (emergency_number['controls'].organisation.dirty || emergency_number['controls'].organisation.touched)" class="invalid-feedback">
                    {{ getErrorMessage("organisation") }}
                  </div>
                </div>

                <div class="form-group">
                  <label for="euSocialReason" required>Numéro d'urgence</label>
                  <input type="text" placeholder="Numéro d'urgence" formControlName="number" class="form-control d-inline-block col-12" [ngClass]="{ 'is-invalid': emergency_number['controls'].number.invalid && (emergency_number['controls'].number.dirty || emergency_number['controls'].number.touched) }">
                  <div *ngIf="emergency_number['controls'].number.invalid && (emergency_number['controls'].number.dirty || emergency_number['controls'].number.touched)" class="invalid-feedback">
                    {{ getErrorMessage("number") }}
                  </div>
                </div>

                <div class="form-group">
                  <label for="euSocialReason" required>Commentaires</label>
                  <input type="text" placeholder="Commentaires" formControlName="comment" class="form-control d-inline-block col-12" [ngClass]="{ 'is-invalid': emergency_number['controls'].comment.invalid && (emergency_number['controls'].comment.dirty || emergency_number['controls'].comment.touched) }">
                  <div *ngIf="emergency_number['controls'].comment.invalid && (emergency_number['controls'].comment.dirty || emergency_number['controls'].comment.touched)" class="invalid-feedback">
                    {{ getErrorMessage("comment") }}
                  </div>
                </div>
              </div>

            </div>
          </div>

          <button class="btn btn-primary col-6 offset-3 mb-3" (click)="addEmergencyNumber()">
            Ajouter un numéro
          </button>

          <div formArrayName="emergency_materials" class="my-4">
            <div [formGroupName]="i" *ngFor="let emergency_material of preventionForm['controls'].emergency_materials['controls'] as FormGroup; let i = index; let last = last" class="card">
              <div class="card-header">
                <div class="d-flex justify-content-between">
                  <a data-toggle="collapse" [href]="'#emergency_materials' + i" [attr.aria-controls]="'emergency_materials' + i" role="button" aria-expanded="true">
                    Matériel {{ i + 1 }}
                  </a>

                  <a class="href tooltipHtml" data-placement="bottom" title="Supprimer" (click)="deleteEmergencyMaterial(i)">
                    <i class="fe fe-trash"></i>
                  </a>
                </div>
              </div>

              <div class="card-body collapse show" [id]="'emergency_materials' + i">
                <div class="form-group">
                  <label for="euSocialReason" required>Matériel</label>
                  <input type="text" placeholder="Matériel" formControlName="material" class="form-control d-inline-block col-12" [ngClass]="{ 'is-invalid': emergency_material['controls'].material.invalid && (emergency_material['controls'].material.dirty || emergency_material['controls'].material.touched) }">
                  <div *ngIf="emergency_material['controls'].material.invalid && (emergency_material['controls'].material.dirty || emergency_material['controls'].material.touched)" class="invalid-feedback">
                    {{ getErrorMessage("material") }}
                  </div>
                </div>

                <div class="form-group">
                  <label for="euSocialReason" required>Consignes d'utilisation</label>
                  <input type="text" placeholder="Consignes d'utilisation" formControlName="use" class="form-control d-inline-block col-12" [ngClass]="{ 'is-invalid': emergency_material['controls'].use.invalid && (emergency_material['controls'].use.dirty || emergency_material['controls'].use.touched) }">
                  <div *ngIf="emergency_material['controls'].use.invalid && (emergency_material['controls'].use.dirty || emergency_material['controls'].use.touched)" class="invalid-feedback">
                    {{ getErrorMessage("use") }}
                  </div>
                </div>

                <div class="form-group">
                  <label for="euSocialReason" required>Commentaires</label>
                  <input type="text" placeholder="Commentaires" formControlName="comment" class="form-control d-inline-block col-12" [ngClass]="{ 'is-invalid': emergency_material['controls'].comment.invalid && (emergency_material['controls'].comment.dirty || emergency_material['controls'].comment.touched) }">
                  <div *ngIf="emergency_material['controls'].comment.invalid && (emergency_material['controls'].comment.dirty || emergency_material['controls'].comment.touched)" class="invalid-feedback">
                    {{ getErrorMessage("comment") }}
                  </div>
                </div>
              </div>

            </div>
          </div>

          <button class="btn btn-primary col-6 offset-3 mb-3" (click)="addEmergencyMaterial()">
            Ajouter un matériel
          </button>

          <h2 class="header-title my-4">Instructions générales de sécurité</h2>

          <div formGroupName="documents" *ngIf="preventionForm['controls'].documents['controls'] as document">

            <h3 class="header-title my-4">Documents remis aux EE</h3>
            <!-- DOC 1 -->
            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Consignes générales à respecter sur le site de l’opération</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="doc1" formControlName="doc1" [ngClass]="{ 'backgroundColor': document['doc1'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': document['doc1'].value }" for="doc1"></label>
              </div>
            </div>
            <!-- DOC 2 -->
            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Consignes générales en cas d’incendie</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="doc2" formControlName="doc2" [ngClass]="{ 'backgroundColor': document['doc2'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': document['doc2'].value }" for="doc2"></label>
              </div>
            </div>
            <!-- DOC 3 -->
            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Dossier technique amiante</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="doc3" formControlName="doc3" [ngClass]="{ 'backgroundColor': document['doc3'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': document['doc3'].value }" for="doc3"></label>
              </div>
            </div>
            <!-- DOC 4 -->
            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Permis feux éventuels</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="doc4" formControlName="doc4" [ngClass]="{ 'backgroundColor': document['doc4'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': document['doc4'].value }" for="doc4"></label>
              </div>
            </div>
            <!-- DOC 5 -->
            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Etude de sécurité pyrotechnique (si EE réalise une activité pyrotechnique dans une EU)</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="doc5" formControlName="doc5" [ngClass]="{ 'backgroundColor': document['doc5'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': document['doc5'].value }" for="doc5"></label>
              </div>
            </div>
            <!-- DOC 6 -->
            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Conclusion de l’étude de sécurité de l’activité pyrotechniques (si EU avec activité pyrotechnique)</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="doc6" formControlName="doc6" [ngClass]="{ 'backgroundColor': document['doc6'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': document['doc6'].value }" for="doc6"></label>
              </div>
            </div>
            <!-- DOC 7 -->
            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Autres</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="doc7" formControlName="doc7" [ngClass]="{ 'backgroundColor': document['doc7'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': document['doc7'].value }" for="doc7"></label>
              </div>
            </div>
            <div *ngIf="checkDocumentEE(document)" class="form-group">
              <label for="obs7">Précisions</label>
              <textarea type="text" formControlName="obs7" id="obs7" class="form-control" placeholder="Précisions"></textarea>
            </div>

            <h3 class="header-title my-4">Plan du site</h3>
            <!-- DOC 8 -->
            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Voies et plan de circulation</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="doc8" formControlName="doc8" [ngClass]="{ 'backgroundColor': document['doc8'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': document['doc8'].value }" for="doc8"></label>
              </div>
            </div>
            <!-- DOC 9 -->
            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Lieu de stationnement et de stockage du matériel</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="doc9" formControlName="doc9" [ngClass]="{ 'backgroundColor': document['doc9'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': document['doc9'].value }" for="doc9"></label>
              </div>
            </div>
            <!-- DOC 10 -->
            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Lieu et secteur de l’opération</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="doc10" formControlName="doc10" [ngClass]="{ 'backgroundColor': document['doc10'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': document['doc10'].value }" for="doc10"></label>
              </div>
            </div>
            <!-- DOC 11 -->
            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Locaux mis à disposition des salariés (vestiaire, sanitaire, restauration, etc.)</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="doc11" formControlName="doc11" [ngClass]="{ 'backgroundColor': document['doc11'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': document['doc11'].value }" for="doc11"></label>
              </div>
            </div>
            <!-- DOC 12 -->
            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Autres</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="doc12" formControlName="doc12" [ngClass]="{ 'backgroundColor': document['doc12'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': document['doc12'].value }" for="doc12"></label>
              </div>
            </div>
            <div *ngIf="checkSitePlan(document)" class="form-group">
              <label for="obs12">Précisions</label>
              <textarea type="text" formControlName="obs12" id="obs12" class="form-control" placeholder="Précisions"></textarea>
            </div>
          </div>

          <h2 class="header-title my-4">Service de santé</h2>
          <div formGroupName="health_service" *ngIf="preventionForm['controls'].health_service['controls'] as health">
            <div class="form-group">
              <label for="medic_name">Nom du médecin du travail de l'EU</label>
              <input type="text" formControlName="medic_name" id="medic_name" class="form-control" placeholder="Nom du médecin du travail de l'EU">
            </div>
            <div class="form-group">
              <label for="medic_phone">Coordonnées du médecin</label>
              <input type="text" formControlName="medic_phone" id="medic_phone" class="form-control" placeholder="Coordonnées du médecin">
            </div>
            <div class="form-group">
              <label for="infirmary">Localisation de l'infirmerie de l'EU</label>
              <input type="text" formControlName="infirmary" id="infirmary" class="form-control" placeholder="Localisation de l'infirmerie de l'EU">
            </div>

            <hr class="col-11 my-5">

            <h3 class="header-title my-4">Noms et lieux de travail des membres du CSE</h3>
            <div class="form-group">
              <button class="btn btn-primary col-6 offset-3 mb-3" (click)="autofillCSE()">
                Préremplir la liste des entreprises
              </button>
              <div formArrayName="cse_members">
                <div [formGroupName]="i" *ngFor="let member of health.cse_members['controls'] as FormGroup; let i = index; let last = last" class="card">

                  <div class="card-header">
                    <div class="d-flex justify-content-between">
                      <a data-toggle="collapse" [href]="'#cse_members' + i" [attr.aria-controls]="'cse_members' + i" role="button" aria-expanded="true">
                        Entreprise {{ i + 1 }}
                      </a>

                      <a class="href tooltipHtml" data-placement="bottom" title="Supprimer" (click)="deleteCSE(i)">
                        <i class="fe fe-trash"></i>
                      </a>
                    </div>
                  </div>

                  <div class="card-body collapse show" [id]="'cse_members' + i">
                    <div class="form-group">
                      <label for="euSocialReason">Raison sociale</label>
                      <input type="text" placeholder="Raison sociale" formControlName="name" class="form-control d-inline-block col-12" [ngClass]="{ 'is-invalid': member['controls'].name.invalid && (member['controls'].name.dirty || member['controls'].name.touched) }">
                    </div>

                    <div formArrayName="users" class="col-10 offset-1">
                      <div [formGroupName]="j" *ngFor="let users of member['controls']['users']['controls']; let j = index" class="card">
                        <div class="card-header">
                          <div class="d-flex justify-content-between">
                            <a data-toggle="collapse" [href]="'#cse_member' + i + '-' + j" [attr.aria-controls]="'cse_member' + i + '-' + j" role="button" aria-expanded="true">
                              Membre {{ i + 1 }}.{{ j + 1 }}
                            </a>

                            <a class="href tooltipHtml" data-placement="bottom" title="Supprimer" (click)="deleteMember(i, j)">
                              <i class="fe fe-trash"></i>
                            </a>
                          </div>
                        </div>

                        <div class="card-body collapse show" [id]="'cse_member' + i + '-' + j">
                          <div class="form-group">
                            <label for="euSocialReason">Nom du membre</label>
                            <input type="text" placeholder="Nom du membre" formControlName="name" class="form-control d-inline-block col-12" [ngClass]="{ 'is-invalid': users['controls'].name.invalid && (users['controls'].name.dirty || users['controls'].name.touched) }">
                          </div>

                          <div class="form-group">
                            <label for="euSocialReason">Lieu de travail</label>
                            <input type="text" placeholder="Lieu de travail" formControlName="place" class="form-control d-inline-block col-12" [ngClass]="{ 'is-invalid': users['controls'].place.invalid && (users['controls'].place.dirty || users['controls'].place.touched) }">
                          </div>

                        </div>
                      </div>
                    </div>

                    <button class="btn btn-primary col-6 offset-3 mt-3" (click)="addMember(i)">
                      Ajouter un membre
                    </button>
                  </div>

                </div>

              </div>

              <div class="d-flex justify-content-center align-items-center mt-4" *ngIf="!preventionForm.controls.health_service['controls']['cse_members']['controls'].length">
                Aucune entreprise
              </div>
            </div>
            <div class="form-group">
              <button class="btn btn-primary col-6 offset-3" (click)="addCSE()">
                Ajouter une entreprise
              </button>
            </div>

            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Postes nécessitant un suivi individuel renforcé</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="required_follow" formControlName="required_follow" [ngClass]="{ 'backgroundColor': health['required_follow'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': health['required_follow'].value }" for="required_follow"></label>
              </div>
            </div>

            <div formArrayName="jobs" *ngIf="health['required_follow'].value" class="mb-4">
              <div [formGroupName]="i" *ngFor="let job of health.jobs['controls'] as FormGroup; let i = index" class="card">
                <div class="card-header">
                  <div class="d-flex justify-content-between">
                    <a data-toggle="collapse" [href]="'#jobs' + i" [attr.aria-controls]="'jobs' + i" role="button" aria-expanded="true">
                      Poste {{ i + 1 }}
                    </a>

                    <a class="href tooltipHtml" data-placement="bottom" title="Supprimer" (click)="deleteJob(i)">
                      <i class="fe fe-trash"></i>
                    </a>
                  </div>
                </div>

                <div class="card-body collapse show" [id]="'jobs' + i">
                  <div class="form-group">
                    <label for="euSocialReason" required>Poste</label>
                    <input type="text" placeholder="Poste" formControlName="name" class="form-control d-inline-block col-12" [ngClass]="{ 'is-invalid': job['controls'].name.invalid && (job['controls'].name.dirty || job['controls'].name.touched) }">
                    <div *ngIf="job['controls'].name.invalid && (job['controls'].name.dirty || job['controls'].name.touched)" class="invalid-feedback">
                      {{ getErrorMessage("name") }}
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <button class="btn btn-primary col-6 offset-3" *ngIf="health['required_follow'].value" (click)="addJob()">
              Ajouter un poste
            </button>

            <div formArrayName="attached" *ngIf="health['required_follow'].value" class="my-4">
              <div [formGroupName]="i" *ngFor="let attached of health.attached['controls'] as FormGroup; let i = index; let last = last" class="card">
                <div class="card-header">
                  <div class="d-flex justify-content-between">
                    <a data-toggle="collapse" [href]="'#attached' + i" [attr.aria-controls]="'attached' + i" role="button" aria-expanded="true">
                      Annexe {{ i + 1 }}
                    </a>

                    <a class="href tooltipHtml" data-placement="bottom" title="Supprimer" (click)="deleteAttached(i)">
                      <i class="fe fe-trash"></i>
                    </a>
                  </div>
                </div>

                <div class="card-body collapse show" [id]="'attached' + i">

                  <div class="form-group">
                    <label for="euSocialReason" required>Annexe</label>
                    <input type="text" placeholder="Annexe" formControlName="name" class="form-control d-inline-block col-12" [ngClass]="{ 'is-invalid': attached['controls'].name.invalid && (attached['controls'].name.dirty || attached['controls'].name.touched) }">
                    <div *ngIf="attached['controls'].name.invalid && (attached['controls'].name.dirty || attached['controls'].name.touched)" class="invalid-feedback">
                      {{ getErrorMessage("name") }}
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <button class="btn btn-primary col-6 offset-3 mb-3" *ngIf="health['required_follow'].value" (click)="addAttached()">
              Ajouter une annexe
            </button>

            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Pré-requis pour les salariés intervenants (Conditions de participation des salariés d'une entreprise aux travaux réalisés par une autre (formation, habilitation, autorisation, aptitudes médicales requises...)</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="requirements_required" formControlName="requirements_required" [ngClass]="{ 'backgroundColor': health['requirements_required'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': health['requirements_required'].value }" for="requirements_required"></label>
              </div>
            </div>
            <div formArrayName="requirements" *ngIf="health['requirements_required'].value" class="my-4">
              <div [formGroupName]="i" *ngFor="let requirement of health.requirements['controls'] as FormGroup; let i = index; let last = last" class="card">
                <div class="card-header">
                  <div class="d-flex justify-content-between">
                    <a data-toggle="collapse" [href]="'#requirements' + i" [attr.aria-controls]="'requirements' + i" role="button" aria-expanded="true">
                      Pré-requis {{ i + 1 }}
                    </a>

                    <a class="href tooltipHtml" data-placement="bottom" title="Supprimer" (click)="deleteRequirement(i)">
                      <i class="fe fe-trash"></i>
                    </a>
                  </div>
                </div>

                <div class="card-body collapse show" [id]="'requirements' + i">
                  <div class="form-group">
                    <label for="euSocialReason" required>Pré-requis</label>
                    <input type="text" placeholder="Pré-requis" formControlName="name" class="form-control d-inline-block col-12" [ngClass]="{ 'is-invalid': requirement['controls'].name.invalid && (requirement['controls'].name.dirty || requirement['controls'].name.touched) }">
                    <div *ngIf="requirement['controls'].name.invalid && (requirement['controls'].name.dirty || requirement['controls'].name.touched)" class="invalid-feedback">
                      {{ getErrorMessage("name") }}
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <button class="btn btn-primary col-6 offset-3 mb-3" *ngIf="health['requirements_required'].value" (click)="addRequirement()">
              Ajouter un pré-requis
            </button>

          </div>
        </div>

        <!-- STEP SIX - RISKS -->
        <div class="tab-pane fade" [ngClass]="{ 'show active': activeTab === 6}">
          <h2 class="header-title mb-3">Analyse des risques interférents</h2>
          <div class="d-flex justify-content-center align-items-center mt-4" *ngIf="!preventionForm.controls.risks.value.length">
            Aucun risque
          </div>

          <div formArrayName="risks" class="mb-4">
            <div [formGroupName]="i" *ngFor="let risk of preventionForm['controls'].risks['controls'] as FormGroup; let i = index; let last = last" class="card">
              <div class="card-header">
                <div class="d-flex justify-content-between">
                  <a data-toggle="collapse" [href]="'#risk' + i" [attr.aria-controls]="'risk' + i" role="button" aria-expanded="true">
                    Risque {{ i + 1 }}
                  </a>

                  <a class="href tooltipHtml" data-placement="bottom" title="Supprimer" (click)="deleteRisk(i)">
                    <i class="fe fe-trash"></i>
                  </a>
                </div>
              </div>

              <div class="card-body collapse show" [id]="'risk' + i">

                <div class="form-group">
                  <label for="euSocialReason" required>Phase de l'opération</label>
                  <input type="text" placeholder="Phase de l'opération" formControlName="operation" class="form-control d-inline-block col-12" [ngClass]="{ 'is-invalid': risk['controls'].operation.invalid && (risk['controls'].operation.dirty || risk['controls'].operation.touched) }">
                  <div *ngIf="risk['controls'].operation.invalid && (risk['controls'].operation.dirty || risk['controls'].operation.touched)" class="invalid-feedback">
                    {{ getErrorMessage("operation") }}
                  </div>
                </div>

                <div class="form-group">
                  <label for="euSocialReason" required>Nature de l'intervention</label>
                  <input type="text" placeholder="Nature de l'intervention" formControlName="nature" class="form-control d-inline-block col-12" [ngClass]="{ 'is-invalid': risk['controls'].nature.invalid && (risk['controls'].nature.dirty || risk['controls'].nature.touched) }">
                  <div *ngIf="risk['controls'].nature.invalid && (risk['controls'].nature.dirty || risk['controls'].nature.touched)" class="invalid-feedback">
                    {{ getErrorMessage("nature") }}
                  </div>
                </div>

                <div class="form-group">
                  <label for="listRegistry" required>Risques identifiés</label>
                  <ng-select [items]="risks"
                             [multiple]="true"
                             bindLabel="name"
                             bindValue="name"
                             groupBy="group"
                             notFoundText="Aucun résultat"
                             [selectableGroup]="true"
                             [selectableGroupAsModel]="false"
                             [closeOnSelect]="false"
                             [addTag]="true"
                             [clearSearchOnAdd]="true"
                             addTagText="Ajouter"
                             placeholder="Risques identifiés"
                             formControlName="identified_risks"
                             [disabled]="!isNew && !canUpdate">
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                      <input id="listRegistry" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }" /> {{ item.name }}
                    </ng-template>
                  </ng-select>
                </div>

                <div class="form-group">
                  <label for="euSocialReason" required>Date de début</label>
                  <input type="text" placeholder="Date de début" formControlName="begin_date" data-toggle="flatpickr" class="form-control d-inline-block col-12" [ngClass]="{ 'is-invalid': risk['controls'].begin_date.invalid && (risk['controls'].begin_date.dirty || risk['controls'].begin_date.touched) }">
                  <div *ngIf="risk['controls'].begin_date.invalid && (risk['controls'].begin_date.dirty || risk['controls'].begin_date.touched)" class="invalid-feedback">
                    {{ getErrorMessage("begin_date") }}
                  </div>
                </div>

                <div class="form-group">
                  <label for="euSocialReason" required>Date de fin</label>
                  <input type="text" placeholder="Date de fin" formControlName="end_date" data-toggle="flatpickr" class="form-control d-inline-block col-12" [ngClass]="{ 'is-invalid': risk['controls'].end_date.invalid && (risk['controls'].end_date.dirty || risk['controls'].end_date.touched) }">
                  <div *ngIf="risk['controls'].end_date.invalid && (risk['controls'].end_date.dirty || risk['controls'].end_date.touched)" class="invalid-feedback">
                    {{ getErrorMessage("end_date") }}
                  </div>
                </div>

                <div class="form-group">
                  <label for="euSocialReason" required>Entreprises concernés</label>
                  <input type="text" placeholder="Entreprises concernés" formControlName="enterprises" class="form-control d-inline-block col-12" [ngClass]="{ 'is-invalid': risk['controls'].enterprises.invalid && (risk['controls'].enterprises.dirty || risk['controls'].enterprises.touched) }">
                  <div *ngIf="risk['controls'].enterprises.invalid && (risk['controls'].enterprises.dirty || risk['controls'].enterprises.touched)" class="invalid-feedback">
                    {{ getErrorMessage("enterprises") }}
                  </div>
                </div>

                <div class="form-group">
                  <label for="euSocialReason" required>Mesures de prévention</label>
                  <input type="text" placeholder="Mesures de prévention" formControlName="measures" class="form-control d-inline-block col-12" [ngClass]="{ 'is-invalid': risk['controls'].measures.invalid && (risk['controls'].measures.dirty || risk['controls'].measures.touched) }">
                  <div *ngIf="risk['controls'].measures.invalid && (risk['controls'].measures.dirty || risk['controls'].measures.touched)" class="invalid-feedback">
                    {{ getErrorMessage("measures") }}
                  </div>
                </div>

              </div>
            </div>
          </div>

          <button class="btn btn-primary col-6 offset-3 mb-3" (click)="addRisk()">
            Ajouter un risque
          </button>
        </div>

      </div>

    </form>
  </div>

  <div modal-footer class="w-100" *ngIf="isNew || canUpdate">
    <button class="btn btn-primary w-100" (click)="isNew ? createPrevention() : updatePrevention()" [ngClass]="{ 'is-loading is-loading-sm': loader }" [disabled]="loader || preventionForm.invalid">
      {{ isNew ? 'Créer' : 'Mettre à jour' }}
    </button>
  </div>
</app-modal-right>
