<app-modal-right (eventOnClose)="closeModal()" #operation>{{ bindModal(operation) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">{{ isNew ? 'Création' : canUpdate ? 'Mise à jour' : 'Consultation' }}</h6>

        <h1 class="header-title">Opérations de maintenance, entretien</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <form [formGroup]="sanitaryOperationForm" [hidden]="loaderContent">
      <!-- INSTALLATION -->
      <div class="form-group">
        <label required>Installation</label>
        <ng-select [items]="sanitaryInstallations"
                   [multiple]="false"
                   bindLabel="description"
                   notFoundText="Aucun résultat"
                   [selectableGroup]="true"
                   [closeOnSelect]="true"
                   bindValue="id"
                   placeholder="Sélectionnez une installation"
                   formControlName="sanitary_installation_id">
          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">{{ item.description }}</ng-template>
        </ng-select>
      </div>
      <!-- OPERATION -->
      <div class="form-group">
        <label for="validationOperation" required>Opération</label>
        <input type="text" formControlName="operation" class="form-control" id="validationOperation" placeholder="Opération" [ngClass]="{ 'is-invalid': operation.invalid && (operation.dirty || operation.touched) }">
      </div>
      <!-- ACTIONS -->
      <div class="form-group">
        <label for="validationActions" required>Actions à réaliser</label>
        <textarea type="text" formControlName="actions" class="form-control" id="validationActions" placeholder="Actions à réaliser"></textarea>
        <div *ngIf="actions.invalid && (actions.dirty || actions.touched)" class="invalid-feedback">
          {{ getErrorMessage("actions") }}
        </div>
      </div>
      <!-- PERIODICITY -->
      <div class="form-group">
        <label required>Période</label>
        <ng-select [items]="periods"
                   [multiple]="false"
                   bindLabel="name"
                   notFoundText="Aucun résultat"
                   [selectableGroup]="true"
                   [closeOnSelect]="true"
                   bindValue="name"
                   placeholder="Sélectionnez une période"
                   formControlName="periodicity"
                   (change)="changeFrequency()">
          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">{{ item.name }}</ng-template>
        </ng-select>
      </div>

      <!-- WEEKLY FRAMES -->
      <div class="form-group" *ngIf="periodicity.value && periodicity.value === 'Hebdomadaire'">
        <label required>Fiche de suivi</label>
        <ng-select [items]="weekly"
                   [multiple]="false"
                   bindLabel="label"
                   notFoundText="Aucun résultat"
                   [selectableGroup]="true"
                   [closeOnSelect]="true"
                   bindValue="id"
                   placeholder="Sélectionnez une fiche de suivi"
                   formControlName="sanitary_frame_id">
          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">{{ item.label }}</ng-template>
        </ng-select>
      </div>
      <!-- MONTHLY FRAMES -->
      <div class="form-group" *ngIf="periodicity.value && periodicity.value === 'Mensuelle'">
        <label required>Fiche de suivi</label>
        <ng-select [items]="monthly"
                   [multiple]="false"
                   bindLabel="label"
                   notFoundText="Aucun résultat"
                   [selectableGroup]="true"
                   [closeOnSelect]="true"
                   bindValue="id"
                   placeholder="Sélectionnez une fiche de suivi"
                   formControlName="sanitary_frame_id">
          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">{{ item.label }}</ng-template>
        </ng-select>
      </div>
      <!-- QUARTERLY FRAMES -->
      <div class="form-group" *ngIf="periodicity.value && periodicity.value === 'Trimestrielle'">
        <label required>Fiche de suivi</label>
        <ng-select [items]="quarterly"
                   [multiple]="false"
                   bindLabel="label"
                   notFoundText="Aucun résultat"
                   [selectableGroup]="true"
                   [closeOnSelect]="true"
                   bindValue="id"
                   placeholder="Sélectionnez une fiche de suivi"
                   formControlName="sanitary_frame_id">
          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">{{ item.label }}</ng-template>
        </ng-select>
      </div>
      <!-- BIANNUAL FRAMES -->
      <div class="form-group" *ngIf="periodicity.value && periodicity.value === 'Semestrielle'">
        <label required>Fiche de suivi</label>
        <ng-select [items]="biannual"
                   [multiple]="false"
                   bindLabel="label"
                   notFoundText="Aucun résultat"
                   [selectableGroup]="true"
                   [closeOnSelect]="true"
                   bindValue="id"
                   placeholder="Sélectionnez une fiche de suivi"
                   formControlName="sanitary_frame_id">
          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">{{ item.label }}</ng-template>
        </ng-select>
      </div>
      <!-- ANNUAL FRAMES -->
      <div class="form-group" *ngIf="periodicity.value && periodicity.value === 'Annuelle'">
        <label required>Fiche de suivi</label>
        <ng-select [items]="annual"
                   [multiple]="false"
                   bindLabel="label"
                   notFoundText="Aucun résultat"
                   [selectableGroup]="true"
                   [closeOnSelect]="true"
                   bindValue="id"
                   placeholder="Sélectionnez une fiche de suivi"
                   formControlName="sanitary_frame_id">
          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">{{ item.label }}</ng-template>
        </ng-select>
      </div>
      <!-- PROTOCOLES -->
      <div class="form-group">
        <label required>Protocoles</label>
        <ng-select [items]="sanitaryProtocols"
                   [multiple]="false"
                   bindLabel="name"
                   notFoundText="Aucun résultat"
                   [selectableGroup]="true"
                   [closeOnSelect]="true"
                   bindValue="id"
                   placeholder="Sélectionnez un protocole"
                   formControlName="generic_folder_id">
          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">{{ item.name }}</ng-template>
        </ng-select>
      </div>
    </form>
  </div>

  <div modal-footer class="w-100" *ngIf="isNew || canUpdate">
    <button class="btn btn-primary w-100" (click)="isNew ? createSanitaryOperation() : updateSanitaryOperation()" [ngClass]="{ 'is-loading is-loading-sm': loader }" [disabled]="loader || sanitaryOperationForm.invalid">
      {{ isNew ? 'Créer' : 'Mettre à jour' }}
    </button>
  </div>
</app-modal-right>
