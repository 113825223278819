<app-modal-right (eventOnClose)="closeModal()" #checkpointModal>{{ binModal(checkpointModal) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">{{ isNew ? 'Création' : canUpdate ? 'Mise à jour' : 'Consultation' }}</h6>

        <h1 class="header-title">Points de contrôle</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <div [hidden]="loaderContent">
      <ul class="nav nav-tabs mb-3 pt-0" id="myTab" role="tablist">
        <li class="nav-item col-6">
          <a class="nav-link pt-0 href" [ngClass]="{ 'active': activeTab === 'sanitaryCheckpoint'}" (click)="changeTabs('sanitaryCheckpoint')">Point de contrôle</a>
        </li>
        <li class="nav-item col-6">
          <a class="nav-link pt-0 href" [ngClass]="{ 'active': activeTab === 'frequencies'}" (click)="changeTabs('frequencies')">Fréquences</a>
        </li>
      </ul>

      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade" id="checkpoint" role="tabpanel" aria-labelledby="tab-checkpoint" [ngClass]="{ 'show active': activeTab === 'sanitaryCheckpoint'}">
          <form class="mb-4" [formGroup]="sanitaryCheckpointForm" *ngIf="!loaderContent">
            <!-- NAME -->
            <div class="form-group">
              <label for="validationCheckpointName" required>Nom</label>
              <input type="text" id="validationCheckpointName" formControlName="name" class="form-control" placeholder="Nom"
                     [ngClass]="{ 'is-invalid': name.invalid && (name.dirty || name.touched) }">
              <div *ngIf="name.invalid && (name.dirty || name.touched)" class="invalid-feedback">
                {{ getErrorMessage("name") }}
              </div>
            </div>

            <!-- MORE INFORMATION -->
            <div class="form-group">
              <label for="validationMoreInformation">Plus d'information</label>
              <input type="text" id="validationMoreInformation" formControlName="more_information" class="form-control" placeholder="Plus d'information"
                     [ngClass]="{ 'is-invalid': more_information.invalid && (more_information.dirty || more_information.touched) }">
              <div *ngIf="more_information.invalid && (more_information.dirty || more_information.touched)" class="invalid-feedback">
                {{ getErrorMessage("more_information") }}
              </div>
            </div>

            <!-- RECOMMENDATION -->
            <div class="form-group">
              <label for="validationRecommendation" required>Préconisation</label>
              <input type="text" id="validationRecommendation" formControlName="recommendation" class="form-control" placeholder="Préconisation"
                     [ngClass]="{ 'is-invalid': recommendation.invalid && (recommendation.dirty || recommendation.touched) }">
              <div *ngIf="recommendation.invalid && (recommendation.dirty || recommendation.touched)" class="invalid-feedback">
                {{ getErrorMessage("recommendation") }}
              </div>
            </div>

            <!-- TYPE -->
            <div class="form-group">
              <label required>Type de points de contrôle</label>
              <ng-select [items]="types"
                         [multiple]="false"
                         bindLabel="name"
                         notFoundText="Aucun résultat"
                         [selectableGroup]="true"
                         [closeOnSelect]="true"
                         bindValue="name"
                         placeholder="Sélectionnez un point de contrôle"
                         formControlName="type">
                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">{{ item.name }}</ng-template>
              </ng-select>
            </div>

          </form>
        </div>

        <div class="tab-pane fade" id="frequencies" role="tabpanel" aria-labelledby="tab-frequencies" [ngClass]="{ 'show active': activeTab === 'frequencies'}">
          <form class="mb-4" [formGroup]="frequenciesForm" *ngIf="!loaderContent">
            <!-- FREQUENCY DURATION -->
            <div class="form-group">
              <label for="validationDuration" required>Durée de fréquence</label>
              <input type="number" id="validationDuration" formControlName="frequency_duration" class="form-control" placeholder="Durée de fréquence" min="1" step="1"
                     (change)="changeFrequency()"
                     [ngClass]="{ 'is-invalid': frequency_duration.invalid && (frequency_duration.dirty || frequency_duration.touched) }">
              <div *ngIf="frequency_duration.invalid && (frequency_duration.dirty || frequency_duration.touched)" class="invalid-feedback">
                {{ getErrorMessage("frequency_duration") }}
              </div>
            </div>

            <!-- FREQUENCY -->
            <div class="form-group">
              <label for="validationFrequency" required>Fréquence</label>
              <select class="form-control" data-toggle="select" formControlName="frequency" id="validationFrequency"
                      (change)="changeFrequency()"
                      [ngClass]="{ 'is-invalid': frequency.invalid && (frequency.dirty || frequency.touched) }">
                <option value="" [selected]="!frequency.value">Sélectionnez une fréquence</option>
                <option value="day">Jour(s)</option>
                <option value="week">Semaine(s)</option>
                <option value="month">Mois</option>
                <option value="year">Année(s)</option>
              </select>
              <div *ngIf="frequency.invalid && (frequency.dirty || frequency.touched)" class="invalid-feedback">
                {{ getErrorMessage("frequency") }}
              </div>
            </div>

            <!-- FREQUENCY TYPE -->
            <div class="form-group">
              <label for="validationFrequencyType" required>Recommandation</label>
              <select class="form-control" data-toggle="select" formControlName="frequency_type" id="validationFrequencyType"
                      [ngClass]="{ 'is-invalid': frequency_type.invalid && (frequency_type.dirty || frequency_type.touched) }">
                <option value="" [selected]="!frequency_type.value">Sélectionnez une recommandation</option>
                <option value="recommended_by_bati">Recommandé par BatiSafe</option>
                <option value="recommended_by_law">Recommandé par la réglementation</option>
                <option value="required_by_law">Imposé par la réglementation</option>
              </select>
              <div *ngIf="frequency_type.invalid && (frequency_type.dirty || frequency_type.touched)" class="invalid-feedback">
                {{ getErrorMessage("frequency_type") }}
              </div>
            </div>

            <!-- ESTABLISHMENT TYPE -->
            <div class="form-group">
              <label for="validationestablishment_type_id">Type d'établissement</label>
              <select class="form-control" data-toggle="select" formControlName="establishment_type_id" id="validationestablishment_type_id" (change)="changeTypeEstablishment()"
                      [ngClass]="{ 'is-invalid': establishment_type_id.invalid && (establishment_type_id.dirty || establishment_type_id.touched) }">
                <option value="" [selected]="!establishment_type_id.value">Sélectionnez un type d'établissement</option>
                <ng-container *ngFor="let establishment_type of establishment_types">
                  <option [value]="establishment_type.id">{{ establishment_type.name }}</option>
                </ng-container>
              </select>
              <div *ngIf="establishment_type_id.invalid && (establishment_type_id.dirty || establishment_type_id.touched)" class="invalid-feedback">
                {{ getErrorMessage("establishment_type_id") }}
              </div>
            </div>

            <!-- ESTABLISHMENT SUBTYPE -->
            <div class="form-group" *ngIf="show_subtypes">
              <label>Sous-type d'établissement</label>
              <ng-select [items]="subtypes"
                         [multiple]="true"
                         bindLabel="name"
                         bindValue="id"
                         groupBy="group"
                         notFoundText="Aucun résultat"
                         [selectableGroup]="true"
                         [selectableGroupAsModel]="false"
                         [closeOnSelect]="false"
                         placeholder="Sélectionnez un sous-type"
                         [(ngModel)]="establishment_subtypes.value" [ngModelOptions]="{ standalone: true }" [disabled]="!isNew && !canUpdate">
                <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                  <input type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }"/> Sélectionnez tout
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                  <input id="type" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }" /> {{ item.name }}
                </ng-template>
              </ng-select>

              <div *ngIf="establishment_subtypes.invalid && (establishment_subtypes.dirty || establishment_subtypes.touched)" class="invalid-feedback">
                {{ getErrorMessage("establishment_subtypes") }}
              </div>
            </div>

            <!-- ESTABLISHMENT SUB SUBTYPE -->
            <div class="form-group" *ngIf="show_sub_subtypes">
              <label for="validationestablishment_sub_subtype_id">Sous sous-type d'établissement</label>
              <select class="form-control" data-toggle="select" formControlName="establishment_sub_subtype_id" id="validationestablishment_sub_subtype_id" [ngClass]="{ 'is-invalid': establishment_sub_subtype_id.invalid && (establishment_sub_subtype_id.dirty || establishment_sub_subtype_id.touched) }">
                <option value="">Sélectionnez un sous sous-type d'établissement</option>
                <ng-container *ngFor="let sub_subtype of sub_subtypes">
                  <option [value]="sub_subtype.id">{{ sub_subtype.name }}</option>
                </ng-container>
              </select>
              <div *ngIf="establishment_sub_subtype_id.invalid && (establishment_sub_subtype_id.dirty || establishment_sub_subtype_id.touched)" class="invalid-feedback">
                {{ getErrorMessage("establishment_sub_subtype_id") }}
              </div>
            </div>

            <!-- ESTABLISHMENT CATEGORY -->
            <div class="form-group" *ngIf="show_categories">
              <label for="validationestablishment_category_id">Catégorie d'établissement</label>
              <select class="form-control" data-toggle="select" formControlName="establishment_category_id" id="validationestablishment_category_id" (change)="changeCategoryEstablishment()"
                      [ngClass]="{ 'is-invalid': establishment_category_id.invalid && (establishment_category_id.dirty || establishment_category_id.touched) }">
                <option value="" [selected]="!establishment_category_id.value">Sélectionnez une catégorie d'établissement</option>
                <ng-container *ngFor="let category of categories">
                  <option [value]="category.id">{{ category.name }}</option>
                </ng-container>
              </select>
              <div *ngIf="establishment_category_id.invalid && (establishment_category_id.dirty || establishment_category_id.touched)" class="invalid-feedback">
                {{ getErrorMessage("establishment_category_id") }}
              </div>
            </div>

            <!-- ESTABLISHMENT SUBCATEGORY -->
            <div class="form-group" *ngIf="show_subcategories">
              <label for="validationestablishment_subcategory_id">Sous catégorie d'établissement</label>
              <select class="form-control" data-toggle="select" formControlName="establishment_subcategory_id" id="validationestablishment_subcategory_id" [ngClass]="{ 'is-invalid': establishment_subcategory_id.invalid && (establishment_subcategory_id.dirty || establishment_subcategory_id.touched) }">
                <option value="" [selected]="!establishment_subcategory_id.value">Sélectionnez une sous catégorie d'établissement</option>
                <ng-container *ngFor="let subcategory of subcategories">
                  <option [value]="subcategory.id">{{ subcategory.name }}</option>
                </ng-container>
              </select>
              <div *ngIf="establishment_subcategory_id.invalid && (establishment_subcategory_id.dirty || establishment_subcategory_id.touched)" class="invalid-feedback">
                {{ getErrorMessage("establishment_subcategory_id") }}
              </div>
            </div>

            <div class="form-group" *ngIf="!isNewFrequency">
              <button class="btn btn-primary w-100" (click)="cancelEditFrequency()"
                      [ngClass]="{ 'is-loading is-loading-sm': loader }" [disabled]="loader">
                Annuler l'édition
              </button>
            </div>
            <div class="form-group">
              <button class="btn btn-primary w-100" (click)="isNewFrequency ? createFrequency() : updateFrequency()"
                      [ngClass]="{ 'is-loading is-loading-sm': loader }" [disabled]="loader || frequenciesForm.invalid">
                {{ isNewFrequency ? 'Ajouter' : 'Mettre à jour' }} la fréquence
              </button>
            </div>

            <!-- CARD RECAP CHECKPOINT REFERENCE -->
            <div class="card bg-light border" *ngFor="let frequency of sanitaryCheckpoint?.frequencies; let i = index">
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <div class="col">
                    <p class="small text-muted mb-2">
                      Fréquence : {{ frequency.frequency_duration}}
                      {{ frequency.frequency === 'day' ? 'Jour(s)' : frequency.frequency === 'week' ? 'Semaine(s)' : frequency.frequency === 'month' ? 'Mois' : 'Année(s)' }}
                    </p>
                    <p class="small text-muted mb-2">
                      Fréquence type : {{ frequency.frequency_type === 'recommended_by_bati' ? 'Recommandé par BatiSafe' :
                      frequency.frequency_type === 'recommended_by_law' ? 'Recommandé par la réglementation' : 'Imposé par la réglementation' }}
                    </p>
                    <p class="small text-muted mb-2">
                      {{ getTypes(frequency) }}
                    </p>
                  </div>

                  <a class="href tooltipHtml col-auto" data-placement="bottom" title="Editer" (click)="editFrequency(frequency, i)">
                    <i class="fe fe-edit-2"></i>
                  </a>

                  <a class="href tooltipHtml col-auto" data-placement="bottom" title="Supprimer" (click)="deleteFrequency(i)">
                    <i class="fe fe-trash"></i>
                  </a>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div modal-footer class="w-100 d-flex justify-content-between">
    <button class="btn btn-primary w-30" [disabled]="activeTab === 'sanitaryCheckpoint'" (click)="prevTabs()">
      Précédent
    </button>

    <button class="btn btn-primary w-30" *ngIf="activeTab === 'sanitaryCheckpoint'" (click)="nextTabs()">
      Suivant
    </button>

    <button class="btn btn-primary w-30" *ngIf="activeTab === 'frequencies'" (click)="isNew ? createSanitaryCheckpoint() : updateSanitaryCheckpoint()"
            [ngClass]="{ 'is-loading is-loading-sm': loader }" [disabled]="loader || sanitaryCheckpointForm.invalid || (!isNew && !canUpdate)">
      {{ isNew ? 'Créer' : 'Mettre à jour' }}
    </button>
  </div>
</app-modal-right>
