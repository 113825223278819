<div class="main-content">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col">
        <div class="header mb-0">
          <div class="header-body no-border">
            <div class="row align-items-center">
              <div class="col">
                <h1 class="header-title">
                  {{ view === 'rvrat_prescriptions' ? 'Prescriptions des RVRAT' : 'Documents' }}
                </h1>

                <small class="form-text text-muted">
                  {{ view === 'rvrat_prescriptions' ? 'Liste des prescriptions des RVRAT' : 'Liste des documents' }}
                </small>

                <app-breadcrumbs [currentPage]="pageName" [names]="previousPageName" [routes]="previousPageRoute"></app-breadcrumbs>
              </div>

              <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0" *ngIf="canCreate">
                <a class="btn btn-primary d-block d-md-inline-block" [routerLink]="[currentRoute + 'create']" *ngIf="view === 'rvrat_prescriptions'">
                  Ajouter des prescriptions
                </a>

                <a class="btn btn-primary d-block d-md-inline-block" [routerLink]="[currentRoute + 'create']" *ngIf="view === 'rvrat_prescription_files'">
                  Ajouter un document
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col">
            <app-searchbar [(ngModel)]="search" (keyup)="searchData()" ngDefaultControl></app-searchbar>
          </div>

          <div class="col-auto" *ngIf="view === 'rvrat_prescription_files'">
            <button class="btn btn-lg btn-white tooltipHtml" aria-label="Afficher les filtres disponibles"  data-toggle="dropdown" data-placement="bottom" title="Filtre">
              <span class="fe fe-filter"></span>
            </button>

            <span class="dropdown-menu dropdown-menu-right">
              <a class="dropdown-item href" (click)="refreshData('RvratPrescriptions.name', 'asc', 1)" aria-label="Tri des prescriptions : Nom - Ordre croissant">
                Nom A-Z
              </a>

              <a class="dropdown-item href" (click)="refreshData('RvratPrescriptions.name', 'desc', 1)" aria-label="Tri des prescriptions : Nom - Ordre décroissant">
                Nom Z-A
              </a>
            </span>
          </div>
        </div>

        <div class="is-loading loader-content" *ngIf="loader"></div>

        <div class="tab-content" *ngIf="!loader && (rvrat_prescriptions.length || files.length)">
          <div class="tab-pane fade active show" id="tabPaneOne" role="tabpanel">
            <div class="row listAlias">
              <div class="col-12 d-flex flex-column" *ngIf="view === 'rvrat_prescriptions'">
                <div class="card flex-fill">
                  <div class="card-body">
                    <div class="table-responsive">
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th class="href" (click)="filter('RvratPrescriptions.emergencies')">
                              <a class="text-muted">Degré d'urgence</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'RvratPrescriptions.emergencies' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'RvratPrescriptions.emergencies' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'RvratPrescriptions.emergencies'"></span>
                            </th>
                            <th>Date de visite</th>
                            <th *ngIf="!is_child">Exploitant</th>
                            <th class="href" (click)="filter('RvratPrescriptions.state')">
                              <a class="text-muted">Levée(s)</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'RvratPrescriptions.state' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'RvratPrescriptions.state' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'RvratPrescriptions.state'"></span>
                            </th>
                            <th class="href" (click)="filter('RvratPrescriptions.date_changing_state')">
                              <a class="text-muted">Date de levée(s)</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'RvratPrescriptions.date_changing_state' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'RvratPrescriptions.date_changing_state' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'RvratPrescriptions.date_changing_state'"></span>
                            </th>
                            <th class="href" (click)="filter('RvratPrescriptions.name')">
                              <a class="text-muted">Prescription(s)</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'RvratPrescriptions.name' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'RvratPrescriptions.name' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'RvratPrescriptions.name'"></span>
                            </th>
                            <th>Commentaire(s)</th>
                            <th>Document(s)</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="dropdown" *ngFor="let rvrat_prescription of rvrat_prescriptions">
                            <td>
                              <span class="label label-rounded" [ngClass]="getEmergencies(rvrat_prescription) && getEmergencies(rvrat_prescription).color">{{ getEmergencies(rvrat_prescription) && getEmergencies(rvrat_prescription).value }}</span>
                            </td>
                            <td>{{ rvrat_prescription.rvrat.date | date : 'dd/MM/yyyy' }}</td>
                            <td *ngIf="!is_child">{{ rvrat_prescription.establishment && rvrat_prescription.establishment.name }}</td>
                            <td>
                              <span *ngIf="!rvrat_prescription.state && rvrat_prescription.has_intervention"
                                    class="fe fe-clock tooltipHtml"
                                    [ngClass]="{ 'fe-red': rvrat_prescription.deadline_danger }"
                                    [title]="'Intervention demandée pour le ' + (rvrat_prescription.deadline_intervention | date : 'dd/MM/yyyy')"></span>&nbsp;
                              <span class="label" [ngClass]="{'label-danger': !rvrat_prescription.state, 'label-success': rvrat_prescription.state }">{{ rvrat_prescription.state ? 'Oui' : 'Non' }}</span>
                            </td>
                            <td>{{ rvrat_prescription.date_changing_state | date : 'dd/MM/yyyy' }}</td>
                            <td>
                              <a class="href" [routerLink]="[currentRoute + rvrat_prescription.id]">
                              {{ rvrat_prescription.name }}
                              </a>
                            </td>
                            <td>{{ rvrat_prescription.comment_changing_state }}</td>
                            <td>
                              <div class="btn-group">
                                <a class="btn btn-sm btn-white" [routerLink]="[currentRoute + rvrat_prescription.id + '/files']">
                                  {{ rvrat_prescription.count_documents + ' document(s)'}}
                                </a>
                              </div>
                            </td>
                            <td class="d-flex flex-row-reverse">
                              <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fe fe-more-vertical"></i>
                              </a>

                              <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item href" [routerLink]="[currentRoute + rvrat_prescription.id + '/state']" *ngIf="canUpdate">
                                  Lever la prescription
                                </a>

                                <a class="dropdown-item href" [routerLink]="[currentRoute + rvrat_prescription.id + '/intervention/create']">
                                  Créer un bon d'intervention
                                </a>

                                <a class="dropdown-item href" [routerLink]="[currentRoute + rvrat_prescription.id]">
                                  {{ canUpdate ? 'Éditer la prescription' : 'Consulter la prescription' }}
                                </a>

                                <a class="dropdown-item href" (click)="modalConfirmData.open(rvrat_prescription.id)" *ngIf="canDelete">
                                  Supprimer la prescription
                                </a>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <app-pagination (returnEvent)="dispatchPagination($event)"
                                    [count_elements]="count"
                                    [current_page]="page"
                                    [elements_length]="rvrat_prescriptions.length"
                                    [limit]="limit"
                                    [total_pages]="pages"
                                    ngDefaultControl>
                    </app-pagination>
                  </div>
                </div>
              </div>

              <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 d-flex flex-column" *ngFor="let file of files">
                <div class="card flex-fill">
                  <div class="href image-file-download" (click)="downloadFile(file.id, file.name + '.' + file.extension)">
                    <img src="../assets/img/files/file_icon.png" alt="Télécharger le fichier" />
                  </div>

                  <div class="card-body">
                    <div class="row align-items-center">
                      <div class="col overflow-hidden">
                        <h4 class="card-title name text-wrap mb-0">
                          <a [routerLink]="" (click)="downloadFile(file.id, file.name + '.' + file.extension)">
                            {{ file.name + '.' + file.extension }}
                          </a>
                        </h4>
                      </div>

                      <div class="col-auto align-self-start">
                        <div class="dropdown">
                          <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fe fe-more-vertical"></i>
                          </a>

                          <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item href" [routerLink]="[currentRoute + file.id]">
                              {{ canUpdate ? 'Éditer le fichier' : 'Consulter le fichier' }}
                            </a>

                            <a class="dropdown-item href" (click)="downloadFile(file.id, file.name + '.' + file.extension)">
                              Télécharger le fichier
                            </a>

                            <a class="dropdown-item href" (click)="modalConfirmFile.open(file.id)" *ngIf="canDelete">
                              Supprimer le fichier
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row pagination-content" *ngIf="view === 'rvrat_prescription_files'">
            <div class="col-12 d-flex flex-column">
              <div class="card">
                <app-pagination (returnEvent)="dispatchPagination($event)"
                                [classes]="'card-body'"
                                [count_elements]="count"
                                [current_page]="page"
                                [elements_length]="files.length"
                                [limit]="limit"
                                [total_pages]="pages"
                                ngDefaultControl>
                </app-pagination>
              </div>
            </div>
          </div>
        </div>

        <div class="loader-content" *ngIf="!loader && (!rvrat_prescriptions.length && !files.length)">
          <p>Aucun résultat</p>
        </div>
      </div>
    </div>
  </div>
</div>

<app-confirm-modal [type]="'delete_data'" #confirmData>{{ bindModalConfirmData(confirmData) }}</app-confirm-modal>
<app-confirm-modal [type]="'delete_file'" #confirmFile>{{ bindModalConfirmFile(confirmFile) }}</app-confirm-modal>

<router-outlet></router-outlet>
