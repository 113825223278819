<div class="main-content">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col">
        <div class="header mb-0">
          <div class="header-body no-border">
            <div class="row align-items-center">
              <div class="col">
                <h1 class="header-title">
                  Opération de surveillance des températures - Fiches de suivi
                </h1>

                <small class="form-text text-muted">
                  Liste des fiches de suivi pour la surveillance des températures
                </small>

                <app-breadcrumbs [currentPage]="pageName" [names]="previousPageName" [routes]="previousPageRoute"></app-breadcrumbs>
              </div>

              <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0" *ngIf="canCreate">
                <a class="btn btn-primary d-block d-md-inline-block" [routerLink]="[currentRoute + 'create']">
                  Ajouter une fiche de suivi
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col">
            <app-searchbar [(ngModel)]="search" (keyup)="searchData()" ngDefaultControl></app-searchbar>
          </div>
        </div>

        <div class="is-loading loader-content" *ngIf="loader"></div>

        <!-- OPERATIONS -->
        <div class="tab-content" *ngIf="!loader && sanitaryTemperatureFrames.length">
          <div class="tab-pane fade active show" id="tabPaneOne" role="tabpanel">
            <div class="row listAlias">
              <div class="col-12 d-flex flex-column">
                <div class="card flex-fill">
                  <div class="card-body">
                    <div class="table-responsive">
                      <table class="table table-striped">
                        <thead>
                        <tr>
                          <th class="href" (click)="filter('SanitaryTemperatureFrames.title')">
                            <a class="text-muted">Nom</a>
                            <span class="fe fe-arrow-down" *ngIf="sort === 'SanitaryTemperatureFrames.title' && order === 'asc'"></span>
                            <span class="fe fe-arrow-up" *ngIf="sort === 'SanitaryTemperatureFrames.title' && order === 'desc'"></span>
                            <span class="fe fe-arrow-right" *ngIf="sort !== 'SanitaryTemperatureFrames.title'"></span>
                          </th>
                          <th>Date</th>
                          <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr class="dropdown" *ngFor="let temperature of sanitaryTemperatureFrames">
                          <td>
                            <a class="href" [routerLink]="[currentRoute + temperature.id]">
                              {{ temperature.title }}
                            </a>
                          </td>
                          <td>
                            <a class="href" [routerLink]="[currentRoute + temperature.id]">
                              {{ temperature.date | date : 'yyyy' }}
                            </a>
                          </td>
                          <td class="d-flex flex-row-reverse">
                            <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <i class="fe fe-more-vertical"></i>
                            </a>

                            <div class="dropdown-menu dropdown-menu-right">
                              <a class="dropdown-item href" [routerLink]="[currentRoute + temperature.id]">
                                {{ canUpdate ? 'Éditer la fiche de suivi' : 'Consulter la fiche de suivi' }}
                              </a>

                              <a class="dropdown-item href" (click)="modalConfirmData.open(temperature.id)" *ngIf="canDelete">
                                Supprimer la fiche de suivi
                              </a>

                              <a class="dropdown-item href" [routerLink]="[currentRoute + temperature.id + '/logs']" *ngIf="canLog">
                                Historique des actions
                              </a>

                              <a class="dropdown-item href" (click)="download(temperature)">
                                Télécharger la fiche de suivi
                              </a>
                            </div>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                    <app-pagination (returnEvent)="dispatchPagination($event)"
                                    [count_elements]="count"
                                    [current_page]="page"
                                    [elements_length]="sanitaryTemperatureFrames.length"
                                    [limit]="limit"
                                    [total_pages]="pages"
                                    ngDefaultControl>
                    </app-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="loader-content" *ngIf="!loader && !sanitaryTemperatureFrames.length">
          <p>Aucun résultat</p>
        </div>
      </div>
    </div>
  </div>
</div>

<app-confirm-modal [type]="'delete_data'" #confirmData>{{ bindModalConfirmData(confirmData) }}</app-confirm-modal>

<router-outlet></router-outlet>
