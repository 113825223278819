import { Routes } from '@angular/router';

import { AccessInternalCheckpointsComponent } from './access-internal-checkpoints/access-internal-checkpoints.component';
import { AccessInternalRegistriesComponent } from './access-internal-registries/access-internal-registries.component';

export const accessInternalRoutes: Routes = [
  {
    path: 'users/mine/:user_id/access',
    component: AccessInternalCheckpointsComponent,
  },
  {
    path: 'users/mine/:user_id/access/registries',
    component: AccessInternalRegistriesComponent,
  }
];
