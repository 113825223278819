<div class="searchbar" id="searchbar">
  <div class="input-group input-group-lg input-group-merge">
    <label [htmlFor]="searchbar_id ? searchbar_id : 'search_bar'"></label>
    <input type="text" class="form-control form-control-prepended search"
           [attr.aria-label]="!aria_label ? 'Barre de recherche' : aria_label"
           [placeholder]="!placeholder_text ? 'Rechercher' : placeholder_text"
           [id]="searchbar_id ? searchbar_id : 'search_bar'" [name]="searchbar_name ? searchbar_name : 'search_bar'">
    <div class="input-group-prepend">
      <div class="input-group-text">
        <span class="fe fe-search"></span>
      </div>
    </div>
  </div>
</div>
