import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Subject } from 'rxjs';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { ReportWorksheetsRequestService } from '../../../services/report.worksheets.request.service';

import { AllReportWorksheetsComponent } from '../all-report-worksheets/all-report-worksheets.component';

@Component({
  selector: 'app-update-report-worksheet',
  templateUrl: './update-report-worksheet.component.html',
  styleUrls: ['./update-report-worksheet.component.scss']
})
export class UpdateReportWorksheetComponent implements OnInit, AfterViewInit, OnDestroy {
  canCreate = false;
  canUpdate = false;

  reportForm: FormGroup;
  id = new FormControl('');
  establishment_registry_id = new FormControl('');
  year = new FormControl('', [ Validators.required, Validators.min(1900), Validators.max(2100) ]);
  works = new FormControl(null);
  financial_estimation = new FormControl(0, Validators.min(0));
  financial_estimation_intellectual = new FormControl(0, Validators.min(0));
  financial_real = new FormControl(0, Validators.min(0));
  comment = new FormControl(null);

  errors = {};

  isNew = true;
  loader = false;
  loaderContent = false;

  modalReport = null;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private allReports: AllReportWorksheetsComponent,
    private reportsService: ReportWorksheetsRequestService
  ) {}

  ngOnInit() {
    this.reportForm = this.formBuilder.group({
      id: this.id,
      establishment_registry_id: this.establishment_registry_id,
      year: this.year,
      works: this.works,
      financial_estimation: this.financial_estimation,
      financial_estimation_intellectual: this.financial_estimation_intellectual,
      financial_real: this.financial_real,
      comment: this.comment,
    });

    if (this.activatedRoute.snapshot.params.report_id) {
      this.isNew = false;
      this.year.disable();
      this.getReport();
    }
  }

  ngAfterViewInit() {
    this.modalReport.open();
  }

  ngOnDestroy() {
    this.modalReport.close();
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  getReport() {
    this.loaderContent = true;

    this.reportsService.getReport(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.activatedRoute.snapshot.params.report_id
    ).subscribe(response => {
      if (response['success']) {
        this.canUpdate = response['canUpdate'];

        this.reportForm.patchValue(response['report']);

        if (!this.canUpdate) {
          this.reportForm.disable();
        }
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loaderContent = false;
    });
  }

  createReport() {
    let request;
    this.errors = {};
    this.loader = true;

    if (this.works.value) {
      const temp = [];
      const works: string = this.works.value;
      const splitValue = works.split('\n');

      splitValue.forEach(value => {
        const reg = new RegExp(/^\s*•\s+/gm);
        value = value.replace(reg, '');
        if (value) {
          temp.push(value);
        }
      });
      this.works.setValue(temp);
    }

    request = this.reportsService.createReport(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.reportForm.value
    );

    request.subscribe(response => {
      if (response['success']) {
        this.isNew = false;
        this.loader = false;
        this.modalReport.close();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response['errors']) {
        this.loader = false;
        this.errors = response['errors'];
        this.works.setValue(null);
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }

      if (this.works.value) {
        this.works.setValue(this.works.value.join('\n'));
        this.works.setValue(this.works.value.replace(/(^|\r\n|\n)([^• ]|$)/g, '$1• $2'));
      }
    });
  }

  updateReport() {
    let request;

    this.errors = {};
    this.loader = true;

    if (this.works.value) {
      const temp = [];
      const works: string = this.works.value;
      const splitValue = works.split('\n');

      splitValue.forEach(value => {
        if (value) {
          const reg = new RegExp(/^\s*•\s+/gm);
          temp.push(value.replace(reg, ''));
        }
      });

      this.works.setValue(temp);
    }

    const report = {
      ...this.reportForm.value,
      year: this.year.value
    };
    request = this.reportsService.updateReport(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.activatedRoute.snapshot.params.report_id,
      report
    );

    request.subscribe(response => {
      if (response['success']) {
        this.loader = false;
        this.modalReport.close();
        CustomNotifierService.getSuccessUpdate(this.notifier);
      } else if (response['errors']) {
        this.loader = false;
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }

      if (this.works.value) {
        this.works.setValue(this.works.value.join('\n'));
        this.works.setValue(this.works.value.replace(/(^|\r\n|\n)([^• ]|$)/g, '$1• $2'));
      }

    });
  }

  addTextareaBullet(event) {
    if (event.srcElement.value) {
      event.srcElement.value = event.srcElement.value.replace(/(^|\r\n|\n)([^• ]|$)/g, '$1• $2');
    }
  }

  pasteTextareaBullet(event) {
    setTimeout(() => {
      event.srcElement.value = event.srcElement.value.replace(/^\s+|\s+$/g, '');
      event.srcElement.value = event.srcElement.value.replace(/[\r\n]+/g, '\n');
      event.srcElement.value = event.srcElement.value.replace(/(^|\r\n|\n)([^• ]|$)/g, '$1• $2');
    }, 150);
  }

  bindModal(modal) {
    this.modalReport = modal;
  }

  closeModal() {
    this.allReports.refreshData();
    this.router.navigate([this.activatedRoute.snapshot.parent.url.join('/')]).then(() => {});
  }
}
