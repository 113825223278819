import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { HttpClient } from '@angular/common/http';
import { NotifierService } from 'angular-notifier';

@Injectable()
export class UsersRequestService extends RequestService {
  constructor(
    protected http: HttpClient,
    protected notifier: NotifierService
  ) {
    super(http, notifier);
  }

  public connectedAs(user_id, enterprise_id) {
    return this.get('users/connected-as/' + user_id + '?enterprise_id=' + enterprise_id);
  }

  public disconnectedAs(user_id, enterprise_id) {
    return this.get('users/disconnected-as/' + user_id + '?enterprise_id=' + enterprise_id);
  }

  /// GET
  public getAllUsers(search, sort, order, limit, page) {
    return this.get('users?' + 'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getEnterpriseUsers(search, sort, order, limit, page, enterprise_id) {
    return this.get('users/' + enterprise_id +
      '?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getMyUsers(search, sort, order, limit, page, selectedEnterprises = []) {
    return this.get('users/mine?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page +
        '&selectedEnterprises=' + selectedEnterprises);
  }
  public getProviderUsers(search, sort, order, limit, page, enterprise_id) {
    return this.get('provider/' + enterprise_id +
      '/users?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getRegistryProviderUsers(search, sort, order, limit, page, enterprise_id, registry_id, establishment_id) {
    return this.get('provider/' + enterprise_id + '/registry/' + registry_id + '/establishment/' + establishment_id +
      '/users?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getUser(user_id) {
    return this.get('user/' + user_id);
  }
  public getUserInEnterprise(user_id, enterprise_id) {
    return this.get('user/' + user_id + '/enterprise/' + enterprise_id);
  }
  public getMyUser(user_id) {
    return this.get('user/mine/' + user_id);
  }
  public getProviderUser(enterprise_id, user_id) {
    return this.get('provider/' + enterprise_id + '/user/' + user_id);
  }

  /// CHECK
  public checkEmail(email) {
    return this.post('users/check-email', { email });
  }

  /// CREATE
  public createUser(user) {
    return this.post('user', { user });
  }
  public createEnterpriseUser(enterprise_id, user) {
    return this.post('user/enterprise/' + enterprise_id, { user });
  }
  public createMyUser(user) {
    return this.post('user/mine', { user });
  }
  public createProvider(enterprise_id, user) {
    return this.post('provider/' + enterprise_id + '/user', { user });
  }

  /// UPDATE
  public updateUser(user_id, user) {
    return this.put('user/' + user_id, { user });
  }
  public updateEnterpriseUser(enterprise_id, user_id, user) {
    return this.put('user/' + user_id + '/enterprise/' + enterprise_id, { user });
  }
  public updateMyUser(user_id, user) {
    return this.put('user/mine/' + user_id, { user });
  }
  public updateProvider(enterprise_id, user_id, user) {
    return this.put('provider/' + enterprise_id + '/user/' + user_id, { user });
  }

  /// ADD
  public addUserEnterprise(enterprise_id, user_id, user) {
    return this.post('user/' + user_id + '/enterprise/' + enterprise_id, { user });
  }
  public addMyUser(user) {
    return this.post('user/mine/' + user.id, { user });
  }
  public addProvider(enterprise_id, user_id) {
    return this.post('provider/' + enterprise_id + '/user/' + user_id, {});
  }

  /// DELETE
  public deleteUserEnterprise(enterprise_id, user_id) {
    return this.delete('user/' + user_id + '/enterprise/' + enterprise_id);
  }
  public removeUserProvider(enterprise_id, user_id) {
    return this.delete('user/' + user_id + '/provider/' + enterprise_id);
  }
}
