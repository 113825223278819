<app-modal-right (eventOnClose)="closeModal()" #registry>{{ bindModal(registry) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">{{ canUpdate ? 'Mise à jour' : 'Consultation' }}</h6>

        <h1 class="header-title">Coordonnées du responsable</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <form [formGroup]="managerForm" [hidden]="loaderContent">
      <div class="row">
        <div class="col-12">
          <!-- NAME -->
          <div class="form-group">
            <label for="validationName" required>Nom du responsable</label>
            <input type="text" formControlName="name" id="validationName" class="form-control" placeholder="Nom du responsable" [ngClass]="{ 'is-invalid': name.invalid && (name.dirty || name.touched) }">
            <div *ngIf="name.invalid && (name.dirty || name.touched)" class="invalid-feedback">
              {{ getErrorMessage("name") }}
            </div>
          </div>

          <!-- ROLE -->
          <div class="form-group">
            <label for="validationRole" required>Fonction</label>
            <input type="text" formControlName="role" id="validationRole" class="form-control" placeholder="Fonction" [ngClass]="{ 'is-invalid': role.invalid && (role.dirty || role.touched) }">
            <div *ngIf="role.invalid && (role.dirty || role.touched)" class="invalid-feedback">
              {{ getErrorMessage("role") }}
            </div>
          </div>

          <!-- SERVICES -->
          <div class="form-group">
            <label for="validationService">Service</label>
            <input type="text" formControlName="services" id="validationService" class="form-control" placeholder="Service" [ngClass]="{ 'is-invalid': services.invalid && (services.dirty || services.touched) }">
            <div *ngIf="services.invalid && (services.dirty || services.touched)" class="invalid-feedback">
              {{ getErrorMessage("services") }}
            </div>
          </div>

          <!-- ADDRESS -->
          <div class="form-group">
            <label for="validationAddress" required>Adresse</label>
            <input type="text" formControlName="address" id="validationAddress" class="form-control" placeholder="Adresse" [ngClass]="{ 'is-invalid': address.invalid && (address.dirty || address.touched) }">
            <div *ngIf="address.invalid && (address.dirty || address.touched)" class="invalid-feedback">
              {{ getErrorMessage("address") }}
            </div>
          </div>

          <!-- PHONE -->
          <div class="form-group">
            <label for="validationPhone">Téléphone</label>
            <input type="text" formControlName="phone" id="validationPhone" class="form-control" placeholder="Téléphone" [ngClass]="{ 'is-invalid': phone.invalid && (phone.dirty || phone.touched) }">
            <div *ngIf="phone.invalid && (phone.dirty || phone.touched)" class="invalid-feedback">
              {{ getErrorMessage("phone") }}
            </div>
          </div>

          <!-- EMAIL -->
          <div class="form-group">
            <label for="validationEmail" required>Email</label>
            <input type="text" formControlName="email" id="validationEmail" class="form-control" placeholder="Email" [ngClass]="{ 'is-invalid': email.invalid && (email.dirty || email.touched) }">
            <div *ngIf="email.invalid && (email.dirty || email.touched)" class="invalid-feedback">
              {{ getErrorMessage("email") }}
            </div>
          </div>

          <!-- ACCESS -->
          <div class="form-group">
            <label for="validationAccess" required>Modalités de consultation de ce dossier</label>
            <textarea formControlName="access" id="validationAccess" class="form-control" placeholder="Modalités de consultation de ce dossier" [ngClass]="{ 'is-invalid': access.invalid && (access.dirty || access.touched) }" rows="5"></textarea>
            <div *ngIf="access.invalid && (access.dirty || access.touched)" class="invalid-feedback">
              {{ getErrorMessage("access") }}
            </div>
          </div>

          <!-- PLACE -->
          <div class="form-group">
            <label for="validationPlace" required>Lieu de consultation</label>
            <input type="text" formControlName="place" id="validationPlace" class="form-control" placeholder="Lieu de consultation" [ngClass]="{ 'is-invalid': place.invalid && (place.dirty || place.touched) }">
            <div *ngIf="place.invalid && (place.dirty || place.touched)" class="invalid-feedback">
              {{ getErrorMessage("place") }}
            </div>
          </div>

          <!-- HOUR -->
          <div class="form-group">
            <label for="validationHour">Horaires</label>
            <input type="text" formControlName="hour" id="validationHour" class="form-control" placeholder="Horaires" [ngClass]="{ 'is-invalid': hour.invalid && (hour.dirty || hour.touched) }">
            <div *ngIf="hour.invalid && (hour.dirty || hour.touched)" class="invalid-feedback">
              {{ getErrorMessage("hour") }}
            </div>
          </div>

          <!-- OTHERS -->
          <div class="form-group">
            <label for="validationOthers">Autres contacts</label>
            <textarea formControlName="others" id="validationOthers" class="form-control" placeholder="Autres contacts" [ngClass]="{ 'is-invalid': others.invalid && (others.dirty || others.touched) }"></textarea>
            <div *ngIf="others.invalid && (others.dirty || others.touched)" class="invalid-feedback">
              {{ getErrorMessage("others") }}
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div modal-footer class="w-100" *ngIf="isNew || canUpdate">
    <button class="btn btn-primary w-100" (click)="isNew ? createManager() : updateManager()" [ngClass]="{ 'is-loading is-loading-sm': loader }" [disabled]="loader || managerForm.invalid">
      Mettre à jour
    </button>
  </div>
</app-modal-right>
