import { Component, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { Subject, Subscription } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../core/auth/auth.service';
import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { ContentsRequestService } from '../../../services/contents.request.service';

@Component({
  selector: 'app-all-contents',
  templateUrl: './all-contents.component.html',
  styleUrls: ['./all-contents.component.scss']
})
export class AllContentsComponent implements OnInit {
  changeEnterprise: Subscription;
  environment = environment;

  requestInProgress = new Subject();
  requestCount = 0;

  loader = false;

  contents: any;

  canCreate = false;
  canUpdate = false;
  canDelete = false;

  pageName: string;
  previousPageName: string[] = [];
  previousPageRoute: string[] = [];

  modalConfirmData = null;

  constructor(
    private notifier: NotifierService,
    private authService: AuthService,
    private contentsRequestService: ContentsRequestService,
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loader = this.requestCount > 0;
    });

    this.pageName = 'Liste des Infobulles';
    this.previousPageName.push('Tableau de bord');
    this.previousPageRoute.push('/administration');

    this.getAllContents();
  }

  getAllContents() {
    this.loader = true;

    this.contentsRequestService.getAllContents().subscribe(response => {
      if (response['success']) {
        this.contents = response['contents'];
        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loader = false;
    });
  }

}


