<app-modal-right (eventOnClose)="closeModal()" #file>{{ bindModal(file) }}>
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">Membres du personnel</h6>
        <h1 class="header-title">Import massif</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <div [hidden]="loaderContent">
      <form [formGroup]="fileForm">
        <div class="card bg-light border">
          <div class="card-body">
            <span>Informations : <br>
              <ul>
                <li>Les types de fichiers acceptés pour l'importation sont les suivants :
                  <ul>
                    <li>XLS (Microsoft Excel)</li>
                    <li>XLSX (Microsoft Excel)</li>
                    <li>CSV</li>
                    <li>ODS (OpenOffice / OpenDocument)</li>
                  </ul>
                </li>
                <li>Vous pouvez télécharger un exemple de fichier (type CSV) en <a class="href" (click)="downloadTemplate()">cliquant ici</a>.</li>
              </ul>
            </span>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <!-- SHEET DROP ZONE -->
            <div class="form-group">
              <label for="inputSheet" required>Liste à importer</label>
              <div class="dropzone dropzone-multiple dz-clickable" appDragDrop (dropHandler)="onFileChange($event)">
                <div class="dz-default dz-message" (click)="chooseFile()">
                  <span>Déposer votre fichier ici pour télécharger</span>
                </div>
                <!-- The input accepts .csv, .ods, .xls, .xlsx -->
                <input id="inputSheet" type="file"
                       accept="
                             text/csv,
                             application/vnd.oasis.opendocument.spreadsheet,
                             application/vnd.ms-excel,
                             application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                       (change)="onFileChange($event)" [hidden]="true">
              </div>
            </div>

            <!-- FILE RECAP -->
            <div class="form-group card" *ngIf="file_base64.value && isNew">
              <div class="card-body">
                <div class="d-flex align-content-center justify-content-between">
                  <h4 class="card-title mb-0 name align-self-center">
                    {{ fileName }}
                  </h4>

                  <a class="href tooltipHtml" data-placement="bottom" title="Supprimer" (click)="removeFile()">
                    <i class="fe fe-trash"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div modal-footer class="w-100">
    <button class="btn btn-primary w-100" (click)="createFile()" [ngClass]="{ 'is-loading is-loading-sm': loader }" [disabled]="loader || fileForm.invalid">
      Créer
    </button>
  </div>
</app-modal-right>
