import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AllWorksheetsModule } from './all-worksheets/all-worksheets.module';
import { UpdateWorksheetModule } from './update-worksheet/update-worksheet.module';
import { UploadWorksheetFileModule } from './upload-worksheet-file/upload-worksheet-file.module';
import { UpdateRequiredWorksheetModule } from './update-required-worksheet/update-required-worksheet.module';

import { WorksheetsRequestService } from '../../services/worksheets.request.service';

@NgModule({
  imports: [
    CommonModule,
    AllWorksheetsModule,
    UpdateWorksheetModule,
    UploadWorksheetFileModule,
    UpdateRequiredWorksheetModule
  ],
  providers: [
    WorksheetsRequestService
  ]
})
export class WorksheetsModule {}
