import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Subject } from 'rxjs';

import { AllEmployeesComponent } from '../all-employees/all-employees.component';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { EmployeesRequestService } from '../../../services/employees.request.service';
import { DocumentsRequestService } from '../../../services/documents.request.service';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit, AfterViewInit, OnDestroy {
  canCreate = false;

  requestInProgress = new Subject();
  requestCount = 0;

  fileForm: FormGroup;
  id = new FormControl('');
  extension = new FormControl('');
  file_base64 = new FormControl('', Validators.required);
  establishment_id = new FormControl('');
  registry_id = new FormControl('');

  fileName: string;

  errors = {};

  isNew = true;
  loader = false;
  loaderContent = false;

  modalFile = null;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private allEmployees: AllEmployeesComponent,
    private employeesService: EmployeesRequestService,
    private documentsService: DocumentsRequestService
  ) {
  }

  ngOnInit() {
    this.fileForm = this.formBuilder.group({
      id: this.id,
      extension: this.extension,
      file_base64: this.file_base64,
      registry_id: this.registry_id,
      establishment_id: this.establishment_id
    });

    this.fileForm.patchValue({ registry_id: +this.activatedRoute.snapshot.parent.params.registry_id });
    this.fileForm.patchValue({ establishment_id: +this.activatedRoute.snapshot.parent.params.establishment_id });
  }

  ngAfterViewInit(): void {
    this.modalFile.open();
  }

  ngOnDestroy(): void {
    this.modalFile.close();
  }

  /**
   * Download a spreadsheet template (csv type) to indicate the fields needed for creation.
   *
   * @param file_name File name to download
   */
  downloadTemplate(file_name: string = 'template.csv') {
    this.requestInProgress.next(false);
    this.documentsService.downloadTemplate(true).subscribe((response: BufferSource) => {
      try {
        const decoder = new TextDecoder('utf-8');
        const domString = decoder.decode(response);
        const json = JSON.parse(domString);
        CustomNotifierService.getErrors(json['errors'], this.notifier);
      } catch (e) {
        const blob = new Blob([response]);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = file_name;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      }
      this.requestInProgress.next(false);
    });
  }

  createFile() {
    const parent = this.activatedRoute.snapshot.parent.params;
    const request = this.employeesService.createSheetEmployees(+parent.registry_id, +parent.establishment_id, this.fileForm.value);
    request.subscribe(response => {
      if (response['success']) {
        this.modalFile.close();
        this.closeModal();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loader = false;
    });
  }

  removeFile() {
    this.file_base64.setValue(null);
    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  chooseFile() {
    const element: HTMLElement = document.getElementById('inputSheet') as HTMLElement;
    element.click();
  }

  onFileChange(event) {
    this.file_base64.setValue('');
    const reader = new FileReader();

    if (event.target && event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.file_base64.setValue(reader.result);
        this.fileName = file.name;

        const split = this.fileName.split('.');
        if (split.length) {
          this.extension.setValue(split[split.length - 1]);
        }
      };
    } else { // Drag and drop
      this.file_base64.setValue(event.file);
      this.fileName = event.name;

      const split = this.fileName.split('.');
      if (split.length) {
        this.extension.setValue(split[split.length - 1]);
      }
    }
    const element: HTMLInputElement = document.getElementById('inputSheet') as HTMLInputElement;
    element.value = '';
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  bindModal(modal) {
    this.modalFile = modal;
  }

  closeModal() {
    this.allEmployees.refreshData();
    this.router.navigate([this.activatedRoute.snapshot.parent.url.join('/')]).then(() => {
    });
  }
}
