import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { AllTrainingsModule } from "./all-trainings/all-trainings.module";
import { UpdateTrainingModule } from "./update-training/update-training.module";
import { UpdateTrainingCustomModule } from "./update-training-custom/update-training-custom.module";
import { UpdateDateTrainingModule } from "./update-date-training/update-date-training.module";
import { AllTrainingsFilesModule } from "./all-trainings-files/all-trainings-files.module";
import { UploadFileModule } from "./upload-file/upload-file.module";
import { ShowTrainingModule } from "./show-training/show-training.module";
import { AllEmployeesModule } from "./all-employees/all-employees.module";
import { UpdateEmployeeModule } from "./update-employee/update-employee.module";

import { TrainingsRequestService } from "../../services/trainings.request.service";
import { RegistriesRequestService } from "../../services/registries.request.service";

@NgModule({
  imports: [
    CommonModule,
    AllTrainingsModule,
    UpdateTrainingModule,
    UpdateTrainingCustomModule,
    UpdateDateTrainingModule,
    AllTrainingsFilesModule,
    UploadFileModule,
    ShowTrainingModule,
    AllEmployeesModule,
    UpdateEmployeeModule,
    UpdateEmployeeModule,
  ],
  providers: [TrainingsRequestService, RegistriesRequestService],
  declarations: [],
})
export class TrainingsModule {}
