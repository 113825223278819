import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Subject } from 'rxjs';

import { AllDocumentsComponent } from '../all-documents/all-documents.component';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { DocumentsRequestService } from '../../../services/documents.request.service';
import { EstablishmentsRequestService } from '../../../services/establishments.request.service';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit, AfterViewInit, OnDestroy {
  canCreate = false;
  canUpdate = false;

  requestInProgress = new Subject();
  requestCount = 0;

  fileForm: FormGroup;
  id = new FormControl('');
  name = new FormControl('', Validators.required);
  extension = new FormControl('');
  establishment_id = new FormControl(null);
  is_model = new FormControl(false);
  order_number = new FormControl(1, Validators.required);
  file_base64 = new FormControl('');
  generic_files_type_id = new FormControl(null);
  generic_folder_id = new FormControl(null);

  fileName: string;

  errors = {};

  isNew = true;
  loader = false;
  loaderContent = false;

  viewCustomer = false;

  modalFile = null;
  modalConfirmFile = null;

  establishments: any = [];
  establishmentsSelected: any = [];

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private allDocuments: AllDocumentsComponent,
    private establishmentsService: EstablishmentsRequestService,
    private documentsService: DocumentsRequestService,
  ) {
  }

  ngOnInit() {
    const snapshot = this.activatedRoute.snapshot;
    const snapPP = snapshot.parent.params;

    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loaderContent = this.requestCount > 0;
    });

    this.fileForm = this.formBuilder.group({
      id: this.id,
      name: this.name,
      extension: this.extension,
      establishment_id: this.establishment_id,
      is_model: this.is_model,
      order_number: this.order_number,
      file_base64: this.file_base64,
      generic_files_type_id: this.generic_files_type_id,
      generic_folder_id: this.generic_folder_id
    });

    if (snapshot.params.file_id) {
      this.fileForm.get('order_number').clearValidators();
      this.fileForm.get('order_number').updateValueAndValidity();
    }


    if (snapPP.establishment_id) {
      this.viewCustomer = true;
      this.fileForm.patchValue({ establishment_id: snapPP.establishment_id });
      this.getEstablishments();
    }

    // If isn't new
    if (snapshot.params.file_id) {
      this.isNew = false;

      this.getFile();
    } else {
      // If is new
      this.file_base64.setValidators([Validators.required]);
      this.file_base64.updateValueAndValidity();

      if (snapPP.file_type_id) {
        this.generic_files_type_id.setValue(snapPP.file_type_id);
      } else {
        this.generic_folder_id.setValue(snapPP.parent_id);
      }
    }
  }

  ngAfterViewInit() {
    this.modalFile.open();

    this.modalConfirmFile.eventOnClose.subscribe(event => {
      if (event) {
        this.loaderContent = true;
        let request;
        if (this.viewCustomer) {
          request = this.documentsService.deleteDashboardFile(
            this.activatedRoute.snapshot.parent.params.registry_id, this.activatedRoute.snapshot.parent.params.establishment_id, event);
        } else {
          request = this.documentsService.deleteFile(event);
        }
        request.subscribe(response => {
          if (response['success']) {
            this.modalFile.close();
            CustomNotifierService.getSuccessDelete(this.notifier);
          } else if (response['errors']) {
            CustomNotifierService.getErrors(response['errors'], this.notifier);
          }
          this.loaderContent = false;
        });
      }
    });
  }

  ngOnDestroy() {
    this.modalFile.close();
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  getEstablishments() {
    this.requestInProgress.next(true);

    this.establishmentsService.getMyEstablishments('', '', '', '', '').subscribe(response => {
      if (response['success']) {
        response['establishments'].forEach(establishment => {
          if (establishment.id !== parseInt(this.activatedRoute.snapshot.parent.params.establishment_id, 10)) {
            establishment.group = true;
            this.establishments.push(establishment);
          }
        });
      }

      this.requestInProgress.next(false);
    });
  }

  getFile() {
    this.requestInProgress.next(true);
    let request;
    if (this.viewCustomer) {
      request = this.documentsService.getDashboardFile(
        this.activatedRoute.snapshot.parent.params.registry_id,
        this.activatedRoute.snapshot.parent.params.establishment_id,
        this.activatedRoute.snapshot.params.file_id);
    } else {
      request = this.documentsService.getFile(this.activatedRoute.snapshot.params.file_id);
    }

    request.subscribe(response => {
      if (response['success']) {
        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];

        this.fileForm.patchValue(response['file']);

        if (!this.canUpdate) {
          this.fileForm.disable();
        }
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  chooseFile() {
    const element: HTMLElement = document.getElementById('inputFile') as HTMLElement;
    element.click();
  }

  onFileChange(event) {
    this.file_base64.setValue('');

    const reader = new FileReader();

    if (event.target && event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.file_base64.setValue(reader.result);
        this.fileName = file.name;

        const split = this.fileName.split('.');
        if (split.length) {
          this.extension.setValue(split[split.length - 1]);
        }
      };
    } else {
      this.file_base64.setValue(event.file);
      this.fileName = event.name;

      const split = this.fileName.split('.');
      if (split.length) {
        this.extension.setValue(split[split.length - 1]);
      }
    }
    const element: HTMLInputElement = document.getElementById('inputFile') as HTMLInputElement;
    element.value = '';
  }

  createFile() {
    this.errors = {};
    this.loader = true;
    let request;
    if (this.viewCustomer) {
      request = this.documentsService.createDashboardFile(
        this.activatedRoute.snapshot.parent.params.registry_id, this.activatedRoute.snapshot.parent.params.establishment_id,
        this.fileForm.value, this.establishmentsSelected
      );
    } else {
      request = this.documentsService.createFile(this.fileForm.value, this.establishmentsSelected);
    }

    request.subscribe(response => {
      if (response['success']) {
        this.modalFile.close();
        this.closeModal();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loader = false;
    });
  }

  updateFile() {
    this.errors = {};
    this.loader = true;
    let request;
    if (this.viewCustomer) {
      request = this.documentsService.updateDashboardFile(
        this.activatedRoute.snapshot.parent.params.registry_id, this.activatedRoute.snapshot.parent.params.establishment_id,
        this.id.value, this.fileForm.value, this.establishmentsSelected);
    } else {
      request = this.documentsService.updateFile(this.id.value, this.fileForm.value, this.establishmentsSelected);
    }

    request.subscribe(response => {
      if (response['success']) {
        this.modalFile.close();
        CustomNotifierService.getSuccessUpdate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loader = false;
    });
  }

  /**
   * Download the file from his id and his name.
   * @param file_id The file id
   * @param file_name The file name
   */
  downloadFile(file_id: any, file_name: any) {
    let request;
    if (this.viewCustomer) {
      request = this.documentsService.downloadDashboardFile(
        this.activatedRoute.snapshot.parent.params.registry_id, this.activatedRoute.snapshot.parent.params.establishment_id, file_id);
    } else {
      request = this.documentsService.downloadFile(file_id);
    }

    request.subscribe((response: BufferSource) => {
      try {
        const decoder = new TextDecoder('utf-8');
        const domString = decoder.decode(response);
        const json = JSON.parse(domString);

        CustomNotifierService.getErrors(json['errors'], this.notifier);
      } catch (e) {
        const blob = new Blob([response]);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = file_name;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      }
    });
  }

  removeFile() {
    this.file_base64.setValue(null);

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  bindModal(modal) {
    this.modalFile = modal;
  }

  bindModalConfirmFile(modal) {
    this.modalConfirmFile = modal;
  }

  closeModal() {
    this.allDocuments.refreshData();
    this.router.navigate([this.activatedRoute.snapshot.parent.url.join('/')]).then(() => {
    });
  }
}
