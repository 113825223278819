import {
  AfterViewInit,
  Component,
  Injector,
  OnDestroy,
  OnInit,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NotifierService } from "angular-notifier";
import * as moment from "moment";
import { Subject } from "rxjs";
import { CustomNotifierService } from "src/app/services/custom.notifier.service";
import { TrainingsRequestService } from "src/app/services/trainings.request.service";

@Component({
  selector: "app-export-download",
  templateUrl: "./export-download.component.html",
  styleUrls: ["./export-download.component.scss"],
})
export class ExportDownloadComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  loaderContent = false;
  loader = false;

  errors = {};

  requestInProgress = new Subject();
  requestCount = 0;
  modalDownload = null;
  previousRoute = "";

  selectedInstallations = [];
  filteredExports = [
    {
      id: "_listes",
      name: "Liste des formations",
    },
    {
      id: "_sessions",
      name: "Liste des sessions des formations",
    },
    {
      id: "_echues",
      name: "Liste des formations échues",
    },
    {
      id: "_employees_train",
      name: "Liste des personnels formés",
    },
    {
      id: "_employees_must_train",
      name: "Liste des personnels non formés et devant l'être",
    },
  ];

  isNew = true;

  downloadForm: FormGroup;
  name = new FormControl(null, Validators.required);
  exportType = new FormControl("XLSX", Validators.required);

  constructor(
    private router: Router,
    private injector: Injector,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private trainingService: TrainingsRequestService
  ) {}

  ngOnInit() {
    this.previousRoute = this.activatedRoute.snapshot.parent.url.join("/");

    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loaderContent = this.requestCount > 0;
    });

    this.downloadForm = this.formBuilder.group({
      name: this.name,
      exportType: this.exportType,
    });
  }

  ngAfterViewInit() {
    this.modalDownload.open();
  }

  ngOnDestroy() {
    this.modalDownload.close();
  }

  bindModal(modal) {
    this.modalDownload = modal;
  }

  downloadReport() {
    this.loader = true;
    let request;
    this.errors = {};

    request = this.trainingService.getPDF(
      this.name.value,
      this.exportType.value
    );

    let ExportTraining = this.filteredExports.filter((item) => {
      return this.name.value === item.id;
    });

    const fileName = ExportTraining[0].name;

    request.subscribe((response: BufferSource) => {
      try {
        const decoder = new TextDecoder("utf-8");
        const domString = decoder.decode(response);
        const json = JSON.parse(domString);
        CustomNotifierService.getErrors(json["errors"], this.notifier);
      } catch (e) {
        const blob = new Blob([response]);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute("style", "display: none");
        a.href = url;
        const extension =
          this.exportType.value === "PDF"
            ? "pdf"
            : this.exportType.value.toLowerCase();
        a.download =
          fileName + " " + moment().format("DD-MM-YYYY") + "." + extension;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      }
      this.loader = false;
      this.modalDownload.close();
    });
  }

  closeModal() {
    this.router
      .navigate([this.activatedRoute.snapshot.parent.url.join("/")])
      .then(() => {});
  }
}
