<app-modal-right (eventOnClose)="closeModal()" #establishment>{{ bindModal(establishment) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">{{ isNew ? 'Création' : canUpdate ? 'Mise à jour' : 'Consultation' }}</h6>

        <h1 class="header-title">{{ children ? 'Exploitant' : 'Établissement' }}</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <form class="mb-4" [formGroup]="establishmentForm" [hidden]="loaderContent">
      <ul class="nav nav-tabs" role="tablist">
        <li class="nav-item" [ngClass]="(canUpdate || isNew) ? 'col-3' : 'col-4'">
          <a class="nav-link href" [ngClass]="{ 'active': activeTab === 'firstPart'}" (click)="changeTabs('firstPart')">Informations</a>
        </li>
        <li class="nav-item" [ngClass]="(canUpdate || isNew) ? 'col-3' : 'col-4'">
          <a class="nav-link href" [ngClass]="{ 'active': activeTab === 'secondPart'}" (click)="changeTabs('secondPart')">Compléments</a>
        </li>
        <li class="nav-item" [ngClass]="(canUpdate || isNew) ? 'col-3' : 'col-4'">
          <a class="nav-link href" [ngClass]="{ 'active': activeTab === 'thirdPart'}" (click)="changeTabs('thirdPart')">Numéros utiles</a>
        </li>
        <li class="nav-item" [ngClass]="(canUpdate || isNew) ? 'col-3' : 'col-4'" *ngIf="canUpdate || isNew">
          <a class="nav-link href" [ngClass]="{ 'active': activeTab === 'fourthPart'}" (click)="changeTabs('fourthPart')">Registres</a>
        </li>
      </ul>

      <div class="tab-content">
        <div class="tab-pane fade" id="firstPart" role="tabpanel" aria-labelledby="firstPart" [ngClass]="{ 'show active': activeTab === 'firstPart'}">
          <div class="form-group">
            <label for="validationName" required>Nom de l'{{ children ? 'exploitant' : 'établissement' }}</label>
            <input type="text" formControlName="name" class="form-control" id="validationName" placeholder="Nom" [ngClass]="{ 'is-invalid': name.invalid && (name.dirty || name.touched) }" maxlength="255">
            <div *ngIf="name.invalid && (name.dirty || name.touched)" class="invalid-feedback">
              {{ getErrorMessage("name") }}
            </div>
          </div>

          <div class="form-group">
            <label>Description de l'{{  children ? 'exploitant' : 'établissement'  }}</label>
            <editor initialValue=""
                    formControlName="description"
                    [init]="{ height: 500, menubar: false, plugins: ['link preview table code lists'],
                                toolbar:'undo redo | formatselect fontselect fontsizeselect | ' +
                                'bold italic underline strikethrough backcolor forecolor | preview | ' +
                                'alignleft aligncenter alignright alignjustify | ' +
                                'bullist numlist outdent indent link table'
                              }">
            </editor>
            <div *ngIf="description.invalid && (description.dirty || description.touched)" class="invalid-feedback">
              {{ getErrorMessage("description") }}
            </div>
          </div>

          <div class="form-group">
            <label for="validationIssuancePersonInCharge" required>Responsable de l'{{  children ? 'exploitant' : 'établissement'  }}</label>
            <input type="text" formControlName="person_in_charge" class="form-control" id="validationIssuancePersonInCharge" placeholder="Responsable" maxlength="255"
                   [ngClass]="{ 'is-invalid': person_in_charge.invalid && (person_in_charge.dirty || person_in_charge.touched) }">
            <div *ngIf="person_in_charge.invalid && (person_in_charge.dirty || person_in_charge.touched)" class="invalid-feedback">
              {{ getErrorMessage("person_in_charge") }}
            </div>
          </div>

          <div class="form-group">
            <label for="validationIssuancePersonInChargePhone" required>Coordonnées du responsable</label>
            <input type="text" formControlName="person_in_charge_phone" class="form-control" id="validationIssuancePersonInChargePhone" placeholder="Coordonnées du responsable" maxlength="255"
                   [ngClass]="{ 'is-invalid': person_in_charge_phone.invalid && (person_in_charge_phone.dirty || person_in_charge_phone.touched) }">
            <div *ngIf="person_in_charge_phone.invalid && (person_in_charge_phone.dirty || person_in_charge_phone.touched)" class="invalid-feedback">
              {{ getErrorMessage("person_in_charge_phone") }}
            </div>
          </div>

          <div class="form-group">
            <label for="validationAddress">Adresse</label>
            <div class="input-group input-group-merge">
              <input type="text" formControlName="address" class="form-control form-control-appended"
                     id="validationAddress" placeholder="Adresse" [ngClass]="{ 'is-invalid': address.invalid && (address.dirty || address.touched) }" maxlength="255" #search (keyup)="onSearch(search.value)">
              <div class="input-group-append" (click)="clearSearch()">
                <span class="input-group-text"><i class="fe fe-x"></i></span>
              </div>
            </div>
            <ul class="google-places-container" *ngIf="results.length">
              <li *ngFor="let result of results" class="item" (click)="selectPlace(result)">
                <a class="href" role="button">
                  <i class="fe fe-map-pin"></i> {{ result.description }}
                </a>
              </li>
            </ul>
            <div id="service-helper"></div>
            <div *ngIf="address.invalid && (address.dirty || address.touched)" class="invalid-feedback">
              {{ getErrorMessage("address") }}
            </div>
          </div>

          <div class="form-group">
            <label for="validationZipcode">Code postal</label>
            <input type="text" formControlName="zipcode" class="form-control" id="validationZipcode" placeholder="Code postal" [ngClass]="{ 'is-invalid': zipcode.invalid && (zipcode.dirty || zipcode.touched) }" maxlength="5">
            <div *ngIf="zipcode.invalid && (zipcode.dirty || zipcode.touched)" class="invalid-feedback">
              {{ getErrorMessage("zipcode") }}
            </div>
          </div>

          <div class="form-group">
            <label for="validationCity">Ville</label>
            <input type="text" formControlName="city" class="form-control" id="validationCity" placeholder="Ville" [ngClass]="{ 'is-invalid': city.invalid && (city.dirty || city.touched) }" maxlength="255">
            <div *ngIf="city.invalid && (city.dirty || city.touched)" class="invalid-feedback">
              {{ getErrorMessage("city") }}
            </div>
          </div>

          <div class="form-group">
            <label for="validationPhone">Téléphone</label>
            <input type="tel" formControlName="phone" class="form-control" id="validationPhone" placeholder="Téléphone" [ngClass]="{ 'is-invalid': phone.invalid && (phone.dirty || phone.touched) }" maxlength="255">
            <div *ngIf="phone.invalid && (phone.dirty || phone.touched)" class="invalid-feedback">
              {{ getErrorMessage("phone") }}
            </div>
          </div>

          <div class="form-group">
            <label for="validationReferency">Référence éventuelle</label>
            <input type="text" formControlName="referency" class="form-control" id="validationReferency" placeholder="Référence éventuelle" [ngClass]="{ 'is-invalid': referency.invalid && (referency.dirty || referency.touched) }" maxlength="255">
            <div *ngIf="referency.invalid && (referency.dirty || referency.touched)" class="invalid-feedback">
              {{ getErrorMessage("referency") }}
            </div>
          </div>

          <div class="form-group d-flex justify-content-start align-items-center" *ngIf="!children && canRus">
            <div class="col pl-0">
              L'établissement est-il sous direction unique ?
            </div>
            <div class="custom-checkbox-toggle">
              <input type="checkbox" class="custom-control-input" id="isRus" formControlName="is_rus" [ngClass]="{ 'backgroundColor': is_rus.value }">
              <label class="custom-control-label" [ngClass]="{ 'backgroundColor': is_work_code.value }" for="isRus"></label>
            </div>
          </div>

          <div class="form-group">
            <label class="w-100" required>L'{{  children ? 'exploitant' : 'établissement'  }} est du type</label>
            <div class="form-check form-check-inline" *ngFor="let typeEstablishment of typesEstablishments">
              <input *ngIf="typeEstablishment.id !== 5" class="form-check-input" type="radio" name="inlineRadioOptions" id="{{ typeEstablishment.name }}" value="{{ typeEstablishment.id }}" (change)="changeTypeEstablishment(typeEstablishment)" [checked]="establishment_type_id.value === typeEstablishment.id" [disabled]="(!isNew && !canUpdate) || children">
              <label *ngIf="typeEstablishment.id !== 5" class="form-check-label" for="{{ typeEstablishment.name }}">{{ typeEstablishment.name }}</label>
            </div>
          </div>

          <div class="form-group" *ngIf="establishment_type_id.value === 1">
            <label for="ERPCategories" required>Catégorie ERP</label>
            <select class="form-control" id="ERPCategories" data-toggle="select" formControlName="establishment_subcategory_id" [ngClass]="{ 'is-invalid': establishment_subcategory_id.invalid && (establishment_subcategory_id.dirty || establishment_subcategory_id.touched) }" [disabled]="children">
              <option [value]="null">Sélectionnez une catégorie</option>
              <optgroup label="1er groupe">
                <option [value]="1">1ère catégorie</option>
                <option [value]="2">2ème catégorie</option>
                <option [value]="3">3ème catégorie</option>
                <option [value]="4">4ème catégorie</option>
              </optgroup>
              <optgroup label="2ème groupe">
                <option [value]="5">5ème catégorie</option>
              </optgroup>
            </select>
          </div>

          <div class="form-group" *ngIf="establishment_type_id.value === 3">
            <label for="BHCCategories" required>Catégorie BHC</label>
            <select class="form-control" id="BHCCategories" data-toggle="select" formControlName="establishment_category_id" [ngClass]="{ 'is-invalid': establishment_category_id.invalid && (establishment_category_id.dirty || establishment_category_id.touched) }">
              <option [value]="null">Sélectionnez une catégorie</option>
              <ng-container *ngFor="let categoriesBHC of categoriesBHC">
                <option [value]="categoriesBHC.id">{{ categoriesBHC.name }}</option>
              </ng-container>
            </select>
          </div>

          <div class="form-group" *ngIf="establishment_type_id.value === 1">
            <label for="typeERP" required>Type d'ERP</label>
            <ng-select [items]="subTypesERP"
                       [multiple]="true"
                       bindLabel="name"
                       bindValue="id"
                       groupBy="group"
                       notFoundText="Aucun résultat"
                       [selectableGroup]="true"
                       [selectableGroupAsModel]="false"
                       [closeOnSelect]="false"
                       placeholder="Sélectionnez un type d'ERP"
                       [(ngModel)]="selectedERPSubTypes" (ngModelChange)="setSubType()" [ngModelOptions]="{ standalone: true }" [disabled]="(!isNew && !canUpdate) || children">
              <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                <input type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }"/> Sélectionnez tout
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="typeERP" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }" /> {{ item.name }}
              </ng-template>
            </ng-select>
          </div>

          <div class="form-group" *ngIf="establishment_type_id.value === 4">
            <label for="typeIGH" required>Type d'IGH</label>
            <ng-select [items]="subTypesIGH"
                       [multiple]="true"
                       bindLabel="name"
                       bindValue="id"
                       groupBy="group"
                       notFoundText="Aucun résultat"
                       [selectableGroup]="true"
                       [selectableGroupAsModel]="false"
                       [closeOnSelect]="false"
                       placeholder="Sélectionnez un type d'IGH"
                       [(ngModel)]="selectedIGHSubTypes" (ngModelChange)="setSubType()" [ngModelOptions]="{ standalone: true }" [disabled]="(!isNew && !canUpdate) || children">
              <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                <input type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }"/> Sélectionnez tout
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="typeIGH" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }" /> {{ item.name }}
              </ng-template>
            </ng-select>
          </div>

          <div class="form-group d-flex justify-content-start align-items-center">
            <div class="col pl-0">
              L’{{  children ? 'exploitant' : 'établissement'  }} est-il soumis au code du travail ?
            </div>
            <div class="custom-checkbox-toggle">
              <input type="checkbox" class="custom-control-input" id="isWorkCode" formControlName="is_work_code" [ngClass]="{ 'backgroundColor': is_work_code.value }">
              <label class="custom-control-label" [ngClass]="{ 'backgroundColor': is_work_code.value }" for="isWorkCode"></label>
            </div>
          </div>
        </div>

        <div class="tab-pane fade" id="secondPart" role="tabpanel" aria-labelledby="secondPart" [ngClass]="{ 'show active': activeTab === 'secondPart'}">
          <div class="form-group">
            <label for="validationOpenDate">Date d'ouverture</label>
            <input type="date" formControlName="open_date" class="form-control" id="validationOpenDate" placeholder="Date d'ouverture" data-toggle="flatpickr"
                   [ngClass]="{ 'is-invalid': open_date.invalid && (open_date.dirty || open_date.touched) }">
            <div *ngIf="open_date.invalid && (open_date.dirty || open_date.touched)" class="invalid-feedback">
              {{ getErrorMessage("open_date") }}
            </div>
          </div>

          <div class="form-group">
            <label for="validationIssuanceBuildingDate">Date de délivrance du permis de construire</label>
            <input type="date" formControlName="issuance_building_date" class="form-control" id="validationIssuanceBuildingDate" placeholder="Date de délivrance du permis de construire" data-toggle="flatpickr"
                   [ngClass]="{ 'is-invalid': issuance_building_date.invalid && (issuance_building_date.dirty || issuance_building_date.touched) }">
            <div *ngIf="issuance_building_date.invalid && (issuance_building_date.dirty || issuance_building_date.touched)" class="invalid-feedback">
              {{ getErrorMessage("issuance_building_date") }}
            </div>
          </div>

          <div class="form-group">
            <label for="validationScoring">Notation</label>
            <select class="form-control" data-toggle="select" formControlName="scoring" id="validationScoring" placeholder="Notation" [ngClass]="{ 'is-invalid': scoring.invalid && (scoring.dirty || scoring.touched) }">
              <option [value]="null">Sélectionnez une notation</option>
              <option value="Établissement sous avis favorable">Établissement sous avis favorable</option>
              <option value="Établissement sous avis défavorable">Établissement sous avis défavorable</option>
            </select>
            <div *ngIf="scoring.invalid && (scoring.dirty || scoring.touched)" class="invalid-feedback">
              {{ getErrorMessage("scoring") }}
            </div>
          </div>

          <div class="form-group">
            <label for="validationEffectiveStaff">Effectif personnel</label>
            <input type="text" formControlName="effective_staff" class="form-control" id="validationEffectiveStaff" placeholder="Effectif personnel" maxlength="255"
                   [ngClass]="{ 'is-invalid': effective_staff.invalid && (effective_staff.dirty || effective_staff.touched) }">
            <div *ngIf="effective_staff.invalid && (effective_staff.dirty || effective_staff.touched)" class="invalid-feedback">
              {{ getErrorMessage("effective_staff") }}
            </div>
          </div>

          <div class="form-group">
            <label for="validationEffectivePublic">Effectif public</label>
            <input type="text" formControlName="effective_public" class="form-control" id="validationEffectivePublic" placeholder="Effectif public" maxlength="255"
                   [ngClass]="{ 'is-invalid': effective_public.invalid && (effective_public.dirty || effective_public.touched) }">
            <div *ngIf="effective_public.invalid && (effective_public.dirty || effective_public.touched)" class="invalid-feedback">
              {{ getErrorMessage("effective_public") }}
            </div>
          </div>

          <div class="form-group">
            <label for="validationArea">Surface</label>
            <input type="text" formControlName="area" class="form-control" id="validationArea" placeholder="Surface" [ngClass]="{ 'is-invalid': area.invalid && (area.dirty || area.touched) }" maxlength="255">
            <div *ngIf="area.invalid && (area.dirty || area.touched)" class="invalid-feedback">
              {{ getErrorMessage("area") }}
            </div>
          </div>

          <div class="form-group">
            <label for="validationArea">Syndicat de copropriété</label>
            <input type="text" formControlName="syndicate" class="form-control" id="validationSyndicate" placeholder="Syndicat de copropriété" [ngClass]="{ 'is-invalid': syndicate.invalid && (syndicate.dirty || syndicate.touched) }" maxlength="255">
            <div *ngIf="syndicate.invalid && (syndicate.dirty || syndicate.touched)" class="invalid-feedback">
              {{ getErrorMessage("syndicate") }}
            </div>
          </div>

          <div class="form-group" [hidden]="!isNew && !canUpdate || picture.value">
            <label for="inputFile" class="mb-1">Photo Établissement</label>

            <small class="form-text text-muted">
              Veuillez utiliser une image ne dépassant pas 200px * 200px.
            </small>

            <div class="dropzone dropzone-multiple dz-clickable" appDragDrop (dropHandler)="onFileChange($event)">
              <div class="dz-default dz-message" (click)="chooseFile()">
                <span>Déposer votre fichier ici pour télécharger</span>
              </div>
              <input id="inputFile" (change)="onFileChange($event)" type="file" accept="image/*" [hidden]="true">
            </div>
          </div>

          <div class="form-group" *ngIf="picture.value">
            <label class="mb-1">Photo établissement</label>

            <small class="form-text text-muted">
              Veuillez utiliser une image ne dépassant pas 200px * 200px.
            </small>

            <div class="card">
              <div class="card-body">
                <div class="d-flex align-items-center justify-content-between">
                  <div class="avatar avatar-lg avatar-4by3">
                    <img src="{{ checkPicture() }}" alt="Photo établissement" class="avatar-img rounded">
                  </div>

                  <a class="href tooltipHtml" data-placement="bottom" title="Supprimer" (click)="removeFile()" *ngIf="isNew || canUpdate">
                    <i class="fe fe-trash"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="tab-pane fade" id="thirdPart" role="tabpanel" aria-labelledby="thirdPart" [ngClass]="{ 'show active': activeTab === 'thirdPart'}">

          <div class="form-group">
            <div class="d-flex justify-content-between align-items-center mb-05 list-head" *ngIf="establishmentForm.controls['use_full_numbers'].value.length">
              <span class="d-inline-block col-5 p-0">Nom</span>

              <span class="d-inline-block col-5 p-0">Numéro</span>

              <span style="width: 15px;"></span>
            </div>

            <div formArrayName="use_full_numbers">
              <div *ngFor="let useFullNumber of establishmentForm['controls'].use_full_numbers['controls']; let i = index;">
                <div [formGroupName]="i" class="d-flex justify-content-between align-items-center mb-05">
                  <input type="text" placeholder="Nom" formControlName="name" class="form-control d-inline-block col-5" [ngClass]="{ 'is-invalid': useFullNumber['controls'].name.invalid && (useFullNumber['controls'].name.dirty || useFullNumber['controls'].name.touched) }">

                  <input type="text" placeholder="Numéro" formControlName="number" class="form-control d-inline-block col-5" [ngClass]="{ 'is-invalid': useFullNumber['controls'].number.invalid && (useFullNumber['controls'].number.dirty || useFullNumber['controls'].number.touched) }">

                  <a class="href" (click)="deleteNumber(i)">
                    <i class="fe fe-x-circle"></i>
                  </a>
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-center align-items-center mt-4" *ngIf="!establishmentForm.controls.use_full_numbers.value.length">
              Aucun numéro enregistré
            </div>
          </div>

          <div class="form-group">
            <button class="btn btn-primary w-100" (click)="addNumber()">
              Ajouter un numéro
            </button>
          </div>

        </div>

        <div class="tab-pane fade" id="fourthPart" role="tabpanel" aria-labelledby="fourthPart" [ngClass]="{ 'show active': activeTab === 'fourthPart'}">

          <div class="card bg-light border" *ngIf="alertNeeded">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div class="col">
                  <p class="small text-muted mb-2">
                    Vous êtes sur le point de désactiver un registre. Cette action est irréversible
                  </p>
                </div>
              </div>
            </div>
          </div>

          <h3 *ngIf="registry_offers">Registres inclus dans l'offre</h3>
          <!-- QUOTAS -->
          <div class="card" *ngFor="let registry of registry_offers">
            <div class="card-body">
              <div class="d-flex align-content-center justify-content-between">
                <h4 class="card-title mb-0 name align-self-center" [ngClass]="{ 'blue-text': registry.checked, 'grey-text': !registry.can_activate }">
                  <i class="fe fe-alert-triangle fe-white tooltipHtml" title="Ce registre a été désactivé. Contactez un administrateur pour le réactiver." *ngIf="!registry.can_activate"></i>
                  {{ registry.name }}
                </h4>

                <a class="href" (click)="checkRegistry(registry)">
                  <i class="fe fe-check-square fe-white" *ngIf="registry.checked"></i>
                  <i class="fe fe-square fe-white" *ngIf="!registry.checked"></i>
                </a>
              </div>
              <div class="form-group d-flex justify-content-start align-items-center mb-0 mt-2" *ngIf="registry.name === 'Carnet sanitaire' && registry.checked">
                <div class="col pl-0">
                  Piscine ?
                </div>
                <a class="href" (click)="checkPool(registry)">
                  <i class="fe fe-check-square fe-white" *ngIf="registry.is_pool"></i>
                  <i class="fe fe-square fe-white" *ngIf="!registry.is_pool"></i>
                </a>
              </div>
            </div>
          </div>

          <hr *ngIf="registry_offers && registry_no_offers && registry_offers.length && registry_no_offers.length">

          <h3 *ngIf="registry_no_offers && registry_no_offers.length">Registres en supplément de l'offre <span [ngClass]="{ 'red-text': count_no_offers > offer.registry_limit }">({{ count_no_offers }}/{{ offer.registry_limit }} registre(s) autorisé(s))</span></h3>
          <!-- REGISTRIES -->
          <div class="card" *ngFor="let registry of registry_no_offers">
            <div class="card-body">
              <div class="d-flex align-content-center justify-content-between">
                <h4 class="card-title mb-0 name align-self-center" [ngClass]="{ 'blue-text': registry.checked, 'grey-text': !registry.can_activate }">
                  <i class="fe fe-alert-triangle fe-white tooltipHtml" *ngIf="!registry.can_activate" title="Ce registre a été désactivé. Contactez un administrateur pour le réactiver."></i>
                  {{ registry.name }}
                </h4>

                <a class="href" *ngIf="!registry['disabled'] && registry.can_activate" (click)="checkRegistry(registry)">
                  <i class="fe fe-check-square fe-white" *ngIf="registry.checked"></i>
                  <i class="fe fe-square fe-white" *ngIf="!registry.checked"></i>
                </a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </form>
  </div>

  <div modal-footer class="w-100 d-flex justify-content-between">
    <button class="btn btn-primary w-30" [disabled]="activeTab === 'firstPart'" (click)="prevTabs()">
      Précédent
    </button>

    <button class="btn btn-primary w-30" *ngIf="activeTab === 'firstPart' || activeTab === 'secondPart' || activeTab === 'thirdPart'" (click)="nextTabs()">
      Suivant
    </button>

    <button class="btn btn-primary w-30" *ngIf="activeTab === 'fourthPart'"
            (click)="isNew ? createEstablishment() : updateEstablishment()" [ngClass]="{ 'is-loading is-loading-sm': loader }"
            [disabled]="loader || establishmentForm.invalid || (!isNew && !canUpdate) || (offer && count_no_offers > offer.registry_limit) || ((canUpdate || isNew) && count_registries === 0)">
      {{ isNew ? 'Créer' : 'Mettre à jour' }}
    </button>
  </div>
</app-modal-right>
