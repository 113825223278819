import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';

import { AllEnterprisesComponent } from '../all-enterprises/all-enterprises.component';
import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { SubscriptionsRequestService } from '../../../services/subscriptions.request.service';
import Frequency from '../../../../shared/interfaces/frequency';
import Offer from '../../../../shared/interfaces/offer';
import { Subject } from 'rxjs';

declare function Flatpickr(): any;

@Component({
  selector: 'app-update-subscription',
  templateUrl: './update-subscription.component.html',
  styleUrls: ['./update-subscription.component.scss']
})
export class UpdateSubscriptionComponent implements OnInit, AfterViewInit, OnDestroy {
  canCreate = false;
  canUpdate = false;

  requestInProgress = new Subject();
  requestCount = 0;

  subscriptionForm: FormGroup;
  id = new FormControl('');
  offer_id = new FormControl('', [Validators.required]);
  billing_frequency_id = new FormControl('', [Validators.required]);
  start_date = new FormControl('', [Validators.required]);
  expiration_date = new FormControl('', [Validators.required]);
  renewal = new FormControl(true);
  nb_establishment = new FormControl('', [Validators.min(0)]);
  nb_establishment_rus = new FormControl(0, [Validators.min(0)]);
  nb_user = new FormControl(null);
  is_mono = new FormControl(false);
  is_discover = new FormControl(false);
  discover_date = new FormControl(null);
  price = new FormControl({ value: '', disabled: true });

  offers: Offer[] = [];
  frequencies: Frequency[] = [];

  errors = {};

  isNew = false;
  loader = false;
  loaderContent = false;

  modalSubscription = null;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private allEnterprises: AllEnterprisesComponent,
    private subscriptionsService: SubscriptionsRequestService
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loaderContent = this.requestCount > 0;
    });

    this.subscriptionForm = this.formBuilder.group({
      id: this.id,
      offer_id: this.offer_id,
      billing_frequency_id: this.billing_frequency_id,
      start_date: this.start_date,
      expiration_date: this.expiration_date,
      renewal: this.renewal,
      nb_establishment: this.nb_establishment,
      nb_establishment_rus: this.nb_establishment_rus,
      nb_user: this.nb_user,
      is_mono: this.is_mono,
      is_discover: this.is_discover,
      discover_date: this.discover_date,
      price: this.price
    });

    this.getOffers();
    this.getFrequencies();
    this.getSubscription(this.activatedRoute.snapshot.params.enterprise_id);
  }

  ngAfterViewInit() {
    this.modalSubscription.open();
  }

  ngOnDestroy() {
    const flatPicker = document.getElementsByClassName('flatpickr-calendar') as HTMLCollection;
    for (let i = flatPicker.length; i >= 0; i--) {
      if (flatPicker[i]) {
        flatPicker[i].remove();
      }
    }
    this.modalSubscription.close();
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  getOffers() {
    this.requestInProgress.next(true);

    this.subscriptionsService.getOffers('', '', '', '', '').subscribe(response => {
      if (response['success']) {
        this.offers = response['offers'];
      }
      this.requestInProgress.next(false);
    });
  }

  getFrequencies() {
    this.requestInProgress.next(true);

    this.subscriptionsService.getFrequencies().subscribe(response => {
      if (response['success']) {
        this.frequencies = response['frequencies'];
      }
      this.requestInProgress.next(false);
    });
  }

  getSubscription(id) {
    this.requestInProgress.next(true);

    this.subscriptionsService.getSubscription(id).subscribe(response => {
      if (response['success']) {
        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];

        this.subscriptionForm.patchValue(response['subscription']);

        if (this.is_mono.value) {
          this.nb_user.disable();
        }
        if (!this.canUpdate) {
          this.subscriptionForm.disable();
        }
      } else {
        this.isNew = true;
      }
      setTimeout(function () {
        Flatpickr();
      }, 100);
      this.requestInProgress.next(false);
    });
  }

  get selectedOffer() {
    if (this.offer_id.value) {
      return this.offers.find(element => element.id === parseInt(this.offer_id.value, 10));
    }
  }

  resetForm() {
    this.price.setValue(null);
  }

  selectedPrice(e) {
    if (e.target.value) {
      this.nb_establishment.setValue(
        this.selectedOffer.prices.find(element => element.id === parseInt(e.target.value, 10))['nb_establishment']
      );

      this.price.setValue(
        this.selectedOffer.prices.find(element => element.id === parseInt(e.target.value, 10))['price']
      );
    }
  }

  createSubscription() {
    this.loader = true;

    this.nb_user.enable();

    const subscription = {
      ...this.subscriptionForm.value,
      enterprise_id: this.activatedRoute.snapshot.params.enterprise_id,
      billing_frequency_id: this.billing_frequency_id.value,
      nb_establishment: this.nb_establishment.value,
      price: this.price.value
    };

    this.subscriptionsService.createSubscription(subscription).subscribe(response => {
      if (response['success']) {
        this.modalSubscription.close();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.subscriptionForm, this.notifier);
      }
      this.loader = false;
    });
  }

  updateSubscription() {
    this.loader = true;

    this.nb_user.enable();

    const subscription = {
      ...this.subscriptionForm.value,
      billing_frequency_id: this.billing_frequency_id.value,
      nb_establishment: this.nb_establishment.value,
      price: this.price.value
    };

    this.subscriptionsService.updateSubscription(subscription.id, subscription).subscribe(response => {
        if (response['success']) {
          this.modalSubscription.close();
          CustomNotifierService.getSuccessUpdate(this.notifier);
        } else {
          this.errors = response['errors'];
          CustomNotifierService.getErrorsForm(this.errors, this.subscriptionForm, this.notifier);
        }
      });
  }

  bindModal(modal) {
    this.modalSubscription = modal;
  }

  closeModal() {
    this.allEnterprises.getEnterprises();
    this.router.navigate([this.activatedRoute.snapshot.parent.url[0].path]).then(() => {});
  }

  validationMono(is_mono: boolean) {
    if (is_mono) {
      this.nb_user.setValue(1);
      this.nb_user.disable();
    } else {
      this.nb_user.setValue(null);
      this.nb_user.enable();
    }
  }

  validationDiscover(is_discover: boolean) {
    setTimeout(function () {
      Flatpickr();
    }, 100);
    if (is_discover) {
      this.discover_date.setValidators([Validators.required]);
      this.discover_date.updateValueAndValidity();
    } else {
      this.discover_date.setValue(null);
      this.discover_date.clearValidators();
      this.discover_date.updateValueAndValidity();
    }
  }
}
