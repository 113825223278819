<div class="main-content">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col">
        <div class="header mb-0">
          <div class="header-body no-border">
            <div class="row align-items-center">
              <div class="col">
                <h1 class="header-title">Unités de travail</h1>

                <small class="form-text text-muted">Edition de masse des unités de travail</small>

                <app-breadcrumbs [currentPage]="pageName" [names]="previousPageName" [routes]="previousPageRoute"></app-breadcrumbs>
              </div>

              <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0">
                <a class="btn btn-primary d-block d-md-inline-block" [routerLink]="" (click)="updateShow()">
                  Valider
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="is-loading loader-content" *ngIf="loader"></div>

        <div class="tab-content" [hidden]="loader || !establishments.length">
          <div class="tab-pane fade show active" id="tabPaneOne" role="tabpanel">
            <div class="row listAlias">

              <!-- CARD INSTALLATION -->
              <div class="col-12 d-flex flex-column" *ngFor="let establishment of establishments; let lastEstablishment = last; let keyEstablishment = index">
                <div class="card flex-fill card-installation" [ngClass]="{ 'fix-last': lastEstablishment }">
                  <div class="card-body">
                    <div class="row align-items-center">
                      <div class="col overflow-hidden align-self-start d-flex">
                        <h4 class="card-title name text-truncate mt-3" [ngClass]="{ 'mb-4': !establishment.collapsed }">
                          <a class="href" (click)="toggleEstablishment(keyEstablishment)">
                            <i class="fe fe-chevron-right" *ngIf="establishment.collapsed"></i>
                            <i class="fe fe-chevron-down" *ngIf="!establishment.collapsed"></i>
                            {{ (establishment ? establishment.name : '') }}
                            - {{ calcCheckedEstablishment(keyEstablishment) + ' / ' + calcAllEstablishment(keyEstablishment) }}
                          </a>
                        </h4>
                      </div>

                      <div class="col-auto align-self-start d-flex" [ngClass]="{ 'mb-4': !establishment.collapsed }">

                        <a class="href fix-checkbox" (click)="checkEstablishment(keyEstablishment)">
                          <i class="fe fe-check-square fe-black" *ngIf="calcCheckedEstablishment(keyEstablishment) === calcAllEstablishment(keyEstablishment)"></i>
                          <i class="fe fe-square fe-black" *ngIf="calcCheckedEstablishment(keyEstablishment)  !== calcAllEstablishment(keyEstablishment)"></i>
                        </a>
                      </div>

                      <!-- CARD CHECKPOINT -->
                      <div class="col-12 d-flex flex-column" *ngIf="!establishment.collapsed">
                        <div *ngFor="let user of establishment['establishment_risk_users']; let lastUser = last; let keyUser = index">
                          <div class="card flex-fill" [ngClass]="{ 'fix-last': lastUser, 'card-checkpoint': user.show_user }">
                            <div class="card-body">
                              <div class="row align-items-center">
                                <div class="col overflow-hidden">
                                  <h4 class="card-title mb-2 name text-truncate">
                                    <a class="fix-font">
                                      {{ user.risk_user.name }}
                                    </a>
                                  </h4>
                                </div>

                                <div class="col-auto align-self-start d-flex">

                                  <a class="href fix-checkbox" (click)="checkUnity(keyEstablishment, keyUser)">
                                    <i class="fe fe-check-square fe-black" *ngIf="user.show_user"></i>
                                    <i class="fe fe-square fe-black" *ngIf="!user.show_user"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="loader-content" *ngIf="!loader && !establishments.length">
          <p>Aucun résultat</p>
        </div>
      </div>
    </div>
  </div>
</div>

<router-outlet></router-outlet>
