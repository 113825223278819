<div class="main-content">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col">
        <div class="header mb-0">
          <div class="header-body no-border">
            <div class="row align-items-center">
              <div class="col">
                <h1 class="header-title">
                  Fiche travaux
                </h1>

                <small class="form-text text-muted">
                  Liste des fiches travaux
                </small>

                <app-breadcrumbs [currentPage]="pageName" [names]="previousPageName" [routes]="previousPageRoute"></app-breadcrumbs>
              </div>

              <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0" *ngIf="canCreate">
                <a class="btn btn-primary d-block d-md-inline-block" [routerLink]="[currentRoute + 'create']">
                  Ajouter une fiche travaux
                </a>
              </div>
              <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0" *ngIf="canCreate">
                <a class="btn btn-primary d-block d-md-inline-block" [routerLink]="[currentRoute + 'file/create']">
                  Joindre le relevé d’intervention du prestataire
                </a>
              </div>
            </div>
          </div>
        </div>

        <!-- WORKSHEETS -->
        <div class="tab-content">
          <div class="tab-pane fade active show" id="tabPaneOne" role="tabpanel">
            <div class="row listAlias">
              <div class="col-12">
                <div class="card bg-light border">
                  <div class="card-body">
                    <span>Compléter une fiche travaux pour chaque rénovation ou modification effectuée sur l’installation, ou joindre le relevé d’intervention du prestataire.</span>
                  </div>
                </div>
              </div>
              <div class="col-12 d-flex flex-column">
                <div class="card flex-fill">
                  <div class="card-body">
                    <div class="row mb-4">
                      <div class="col-md-6">
                        <h2>Fiches travaux</h2>
                      </div>
                      <div class="col-md-6">
                        <app-searchbar [(ngModel)]="search" (keyup)="searchData()" [searchbar_id]="'search_worksheet'" [searchbar_name]="'search_worksheet'" ngDefaultControl></app-searchbar>
                      </div>
                    </div>
                    <div class="table-responsive">
                      <table class="table table-striped">
                        <thead>
                        <tr>
                          <th class="href" (click)="filter('SanitaryWorksheets.date')">
                            <a class="text-muted">Date</a>
                            <span class="fe fe-arrow-down" *ngIf="sort === 'SanitaryWorksheets.date' && order === 'asc'"></span>
                            <span class="fe fe-arrow-up" *ngIf="sort === 'SanitaryWorksheets.date' && order === 'desc'"></span>
                            <span class="fe fe-arrow-right" *ngIf="sort !== 'SanitaryWorksheets.date'"></span>
                          </th>
                          <th class="href" (click)="filter('SanitaryWorksheets.provider')">
                            <a class="text-muted">Prestataire</a>
                            <span class="fe fe-arrow-down" *ngIf="sort === 'SanitaryWorksheets.provider' && order === 'asc'"></span>
                            <span class="fe fe-arrow-up" *ngIf="sort === 'SanitaryWorksheets.provider' && order === 'desc'"></span>
                            <span class="fe fe-arrow-right" *ngIf="sort !== 'SanitaryWorksheets.provider'"></span>
                          </th>
                          <th class="href" (click)="filter('SanitaryWorksheets.description')">
                            <a class="text-muted">description</a>
                            <span class="fe fe-arrow-down" *ngIf="sort === 'SanitaryWorksheets.description' && order === 'asc'"></span>
                            <span class="fe fe-arrow-up" *ngIf="sort === 'SanitaryWorksheets.description' && order === 'desc'"></span>
                            <span class="fe fe-arrow-right" *ngIf="sort !== 'SanitaryWorksheets.description'"></span>
                          </th>
                          <th></th>
                        </tr>
                        </thead>
                        <tbody>
                          <tr *ngIf="loaderWorksheets">
                            <td colspan="4">
                              <div class="is-loading loader-content"></div>
                            </td>
                          </tr>
                          <tr *ngIf="!loaderWorksheets && !sanitaryWorksheets.length">
                            <td colspan="4">
                              <div class="loader-content">
                                Aucun résultat
                              </div>
                            </td>
                          </tr>
                          <tr class="dropdown" *ngFor="let worksheet of sanitaryWorksheets">
                            <td>
                              <a class="href" [routerLink]="[currentRoute + worksheet.id]">
                                {{ worksheet.date | date : 'dd/MM/yyyy' }}
                              </a>
                            </td>
                            <td>
                              <a class="href" [routerLink]="[currentRoute + worksheet.id]">
                                {{ worksheet.provider }}
                              </a>
                            </td>
                            <td>
                              <a class="href" [routerLink]="[currentRoute + worksheet.id]">
                                {{ worksheet.description }}
                              </a>
                            </td>
                            <td class="d-flex flex-row-reverse">
                              <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fe fe-more-vertical"></i>
                              </a>

                              <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item href" [routerLink]="[currentRoute + worksheet.id]">
                                  {{ canUpdate ? 'Éditer la fiche travaux' : 'Consulter la fiche travaux' }}
                                </a>

                                <a class="dropdown-item href" (click)="modalConfirmData.open(worksheet.id)" *ngIf="canDelete">
                                  Supprimer la fiche travaux
                                </a>

                                <a class="dropdown-item href" [routerLink]="[currentRoute + worksheet.id + '/logs']" *ngIf="canLog">
                                  Historique des actions
                                </a>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <app-pagination (returnEvent)="dispatchPagination($event)"
                                    [count_elements]="count"
                                    [current_page]="page"
                                    [elements_length]="sanitaryWorksheets.length"
                                    [limit]="limit"
                                    [total_pages]="pages"
                                    ngDefaultControl>
                    </app-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- PROVIDERS -->
        <div class="tab-content">
          <div class="tab-pane fade active show" id="tabPaneTwo" role="tabpanel">
            <div class="row listAlias">
              <div class="col-12 d-flex flex-column">
                <div class="card flex-fill">
                  <div class="card-body">
                    <div class="row mb-4">
                      <div class="col-md-6">
                        <h2>Relevés d'interventions des prestataires</h2>
                      </div>
                      <div class="col-md-6">
                        <app-searchbar [(ngModel)]="searchFile" (keyup)="searchDataFiles()" [searchbar_id]="'search_file'" [searchbar_name]="'search_file'" ngDefaultControl></app-searchbar>
                      </div>
                    </div>
                    <div class="table-responsive">
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th class="href" (click)="filterFiles('SanitaryWorksheets.name')">
                              <a class="text-muted">Nom</a>
                              <span class="fe fe-arrow-down" *ngIf="sortFile === 'SanitaryWorksheets.name' && orderFile === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sortFile === 'SanitaryWorksheets.name' && orderFile === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sortFile !== 'SanitaryWorksheets.name'"></span>
                            </th>
                            <th>Document(s)</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngIf="loaderFiles">
                            <td colspan="3">
                              <div class="is-loading loader-content"></div>
                            </td>
                          </tr>
                          <tr *ngIf="!loaderFiles && !sanitaryWorksheetFiles.length">
                            <td colspan="3">
                              <div class="loader-content">
                                Aucun résultat
                              </div>
                            </td>
                          </tr>
                          <tr class="dropdown" *ngFor="let worksheet of sanitaryWorksheetFiles">
                            <td>
                              <a class="href" [routerLink]="[currentRoute + 'file/' + worksheet.id]">
                                {{ worksheet.name }}
                              </a>
                            </td>
                            <td>
                              <div class="btn-group">
                                <a class="btn btn-sm btn-white" (click)="downloadFile(worksheet.id, worksheet.name + '.' + worksheet.extension)">
                                  1 document(s)
                                </a>
                              </div>
                            </td>
                            <td class="d-flex flex-row-reverse">
                              <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fe fe-more-vertical"></i>
                              </a>

                              <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item href" [routerLink]="[currentRoute + 'file/' + worksheet.id]">
                                  {{ canUpdate ? 'Éditer le relevé d\'intervention' : 'Consulter le relevé d\'intervention' }}
                                </a>

                                <a class="dropdown-item href" (click)="modalConfirmData.open(worksheet.id)" *ngIf="canDelete">
                                  Supprimer le relevé d'intervention
                                </a>

                                <a class="dropdown-item href" [routerLink]="[currentRoute + worksheet.id + '/logs']" *ngIf="canLog">
                                  Historique des actions
                                </a>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <app-pagination (returnEvent)="dispatchPaginationFile($event)"
                                    [count_elements]="countFile"
                                    [current_page]="pageFile"
                                    [elements_length]="sanitaryWorksheetFiles.length"
                                    [limit]="limitFile"
                                    [total_pages]="pagesFile"
                                    ngDefaultControl>
                    </app-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<app-confirm-modal [type]="'delete_data'" #confirmData>{{ bindModalConfirmData(confirmData) }}</app-confirm-modal>
<app-confirm-modal [type]="'delete_file'" #confirmFile>{{ bindModalConfirmFile(confirmFile) }}</app-confirm-modal>

<router-outlet></router-outlet>
