import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { HttpClient } from '@angular/common/http';
import { NotifierService } from 'angular-notifier';

@Injectable()
export class SanitaryCheckpointsRequestService extends RequestService {
  constructor(
    protected http: HttpClient,
    protected notifier: NotifierService
  ) {
    super(http, notifier);
  }

  /// GET
  public getAllSanitaryCheckpoints(search, sort, order, limit, page) {
    return this.get('sanitary-checkpoints/?' +
      'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getAllSanitaryCheckpointsByType(type) {
    return this.get('sanitary-checkpoints-type/?type=' + type);
  }
  public getSanitaryCheckpoint(sanitaryCheckpointId) {
    return this.get('sanitary-checkpoint/' + sanitaryCheckpointId);
  }
  /// CREATE
  public createSanitaryCheckpoint(sanitaryCheckpoint) {
    return this.post('sanitary-checkpoint', { sanitaryCheckpoint });
  }
  /// UPDATE
  public updateSanitaryCheckpoint(sanitaryCheckpointId, sanitaryCheckpoint) {
    return this.put('sanitary-checkpoint/' + sanitaryCheckpointId, { sanitaryCheckpoint });
  }
  /// DELETE
  public deleteSanitaryCheckpoint(sanitaryCheckpointId) {
    return this.delete('sanitary-checkpoint/' + sanitaryCheckpointId);
  }
}
