import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { HttpClient } from '@angular/common/http';
import { NotifierService } from 'angular-notifier';

@Injectable()
export class LogsRequestService extends RequestService {
  constructor(
    protected http: HttpClient,
    protected notifier: NotifierService
  ) {
    super(http, notifier);
  }

  public getAllLog(search, sort, order, limit, page, selectedRegistries, selectedEstablishments, selectedEnterprises, selectedUsers,
                   dateFrom, dateTo) {
    return this.get( 'logs' +
      '?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page +
      '&selectedRegistries=' + selectedRegistries + '&selectedEstablishments=' + selectedEstablishments + '&selectedEnterprises=' +
      selectedEnterprises + '&selectedUsers=' + selectedUsers + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo);
  }
  public getLogUser(search, sort, order, limit, page, user_id) {
    return this.get( 'logs/user/' + user_id +
      '?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getLogEstablishment(search, sort, order, limit, page, establishment_id, enterprise_id) {
    return this.get( 'logs/establishment/' + establishment_id + '?enterprise_id=' + enterprise_id +
      '&search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getLogEnterprise(search, sort, order, limit, page, enterprise_id, provider) {
    return this.get( 'logs/enterprise/' + enterprise_id +
      '?view=' + provider + '&search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getLogReportWorksheet(search, sort, order, limit, page, report_id) {
    return this.get( 'logs/report-worksheet/' + report_id +
      '?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getLogAsbestosDetection(search, sort, order, limit, page, asbestos_detection_id) {
    return this.get( 'logs/asbestos-detection/' + asbestos_detection_id +
      '?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getLogAsbestosWork(search, sort, order, limit, page, asbestos_work_id) {
    return this.get( 'logs/asbestos-work/' + asbestos_work_id +
      '?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getLogCommission(search, sort, order, limit, page, commission_id) {
    return this.get( 'logs/commission/' + commission_id +
      '?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getLogDateTraining(search, sort, order, limit, page, date_training_id) {
    return this.get( 'logs/date-training/' + date_training_id +
      '?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getLogTraining(search, sort, order, limit, page, training_id) {
    return this.get( 'logs/training/' + training_id +
      '?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getLogReport(search, sort, order, limit, page, report_id) {
    return this.get( 'logs/report/' + report_id +
      '?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getLogWorksheet(search, sort, order, limit, page, worksheet_id) {
    return this.get( 'logs/worksheet/' + worksheet_id +
      '?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getLogRisk(search, sort, order, limit, page, risk_id) {
    return this.get( 'logs/risk/' + risk_id +
      '?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getLogRiskUnity(search, sort, order, limit, page, unity_id) {
    return this.get( 'logs/risk-unity/' + unity_id +
      '?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getLogRiskUser(search, sort, order, limit, page, user_id) {
    return this.get( 'logs/risk-user/' + user_id +
      '?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getLogDocument(search, sort, order, limit, page, folder_id) {
    return this.get( 'logs/document/' + folder_id +
      '?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getLogRegistryManager(search, sort, order, limit, page, registry_id, establishment_id) {
    return this.get( 'logs/registry-manager/' + registry_id + '/' + establishment_id +
      '?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getLogEmployee(search, sort, order, limit, page, registry_id, establishment_id) {
    return this.get( 'logs/employee/' + registry_id + '/' + establishment_id +
      '?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getLogEmployeeFormation(search, sort, order, limit, page, registry_id, establishment_id) {
    return this.get( 'logs/employee-training/' + registry_id + '/' + establishment_id +
      '?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getLogInterventionVoucher(search, sort, order, limit, page, intervention_id) {
    return this.get( 'logs/intervention-voucher/' + intervention_id +
      '?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getLogFirePermit(search, sort, order, limit, page, fire_permit_id) {
    return this.get( 'logs/fire-permit/' + fire_permit_id +
      '?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getLogPrevention(search, sort, order, limit, page, prevention_id) {
    return this.get( 'logs/prevention-plan/' + prevention_id +
      '?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getLogSecurityProtocol(search, sort, order, limit, page, security_protocol_id) {
    return this.get( 'logs/security-protocol/' + security_protocol_id +
      '?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getLogEstablishmentCheckpoint(search, sort, order, limit, page, establishment_checkpoint_id) {
    return this.get( 'logs/establishment-checkpoint/' + establishment_checkpoint_id +
      '?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getLogSanitaryContributor(search, sort, order, limit, page, contributorId) {
    return this.get( 'logs/sanitary-contributor/' + contributorId +
        '?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getLogSanitaryInstallation(search, sort, order, limit, page, installationId) {
    return this.get( 'logs/sanitary-installation/' + installationId +
        '?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getLogSanitaryWorksheet(search, sort, order, limit, page, worksheetId) {
    return this.get( 'logs/sanitary-worksheet/' + worksheetId +
        '?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getLogSanitaryPool(search, sort, order, limit, page, poolId) {
    return this.get( 'logs/sanitary-pool/' + poolId +
        '?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
}
