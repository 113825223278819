import { Routes } from '@angular/router';

import { PlanningComponent } from './planning.component';
import { UpdateReportComponent } from '../reports/update-report/update-report.component';

export const planningRoutes: Routes = [
  {
    path: 'planning',
    component: PlanningComponent,
    children: [
      {
        path: 'checkpoints/:checkpoint_id/reports/create',
        component: UpdateReportComponent
      },
      {
        path: 'installation/:installation_id/checkpoints/:checkpoint_id/reports/:report_id',
        component: UpdateReportComponent
      }
    ]
  },
];
