import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AccessProviderCheckpointsModule } from './access-provider-checkpoints/access-provider-checkpoints.module';
import { AccessProviderPatternModule } from './access-provider-pattern/access-provider-pattern.module';

@NgModule({
  imports: [
    CommonModule,
    AccessProviderCheckpointsModule,
    AccessProviderPatternModule
  ]
})

export class AccessProviderModule {}
