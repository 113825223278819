import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Subject } from 'rxjs';
import { NgbTimeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { NgbTimeStringAdapter } from '../../../core/utils/ngb-time-string-adapter';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { UsersRequestService } from '../../../services/users.request.service';
import { SecurityProtocolsRequestService } from '../../../services/security.protocols.request.service';

import { AllSecurityProtocolsComponent } from '../all-security-protocols/all-security-protocols.component';

import BatiFile from '../../../../shared/interfaces/bati_file';

declare function Flatpickr(): any;
declare function Dropzones(): any;

@Component({
  selector: 'app-update-security-protocol',
  templateUrl: './update-security-protocol.component.html',
  styleUrls: ['./update-security-protocol.component.scss'],
  providers: [{ provide: NgbTimeAdapter, useClass: NgbTimeStringAdapter }]
})
export class UpdateSecurityProtocolComponent implements OnInit, AfterViewInit, OnDestroy {
  counter = Array;
  canCreate = false;
  canUpdate = false;

  activeTab = 1;

  requestInProgress = new Subject();
  requestCount = 0;

  securityProtocolForm: FormGroup;
  referency = new FormControl('');
  nature = new FormControl('');
  loading = new FormControl(false);
  unloading = new FormControl(false);
  begin_date = new FormControl('');
  schedules = new FormControl('');
  repetitive_operations = new FormControl(false);
  punctual_operations = new FormControl(false);
  precisions = new FormControl('');
  home_manager = new FormControl(false);
  home_manager_first_name = new FormControl('');
  home_manager_last_name = new FormControl('');
  transport_manager = new FormControl(false);
  transport_manager_first_name = new FormControl('');
  transport_manager_last_name = new FormControl('');
  explosive_merchandise = new FormControl(false);

  field = new FormControl('', Validators.required);

  files: BatiFile[] = [];
  filesRequired: BatiFile[] = [];
  enterprises: any = [];
  user: string;

  errors = {};

  isNew = true;
  loader = false;
  loaderContent = false;

  modalSecurityProtocol = null;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private allSecurityProtocols: AllSecurityProtocolsComponent,
    private usersService: UsersRequestService,
    private securityProtocolsService: SecurityProtocolsRequestService,
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loaderContent = this.requestCount > 0;
    });

    this.securityProtocolForm = this.formBuilder.group({
      // PART 1
      referency: this.referency,
      operation: this.formBuilder.group({
        nature: this.nature,
        loading: this.loading,
        unloading: this.unloading,
        begin_date: this.begin_date,
        schedules: this.schedules,
        repetitive_operations: this.repetitive_operations,
        punctual_operations: this.punctual_operations,
        precisions: this.precisions,
        home_manager: this.home_manager,
        home_manager_first_name: this.home_manager_first_name,
        home_manager_last_name: this.home_manager_last_name,
        transport_manager: this.transport_manager,
        transport_manager_first_name: this.transport_manager_first_name,
        transport_manager_last_name: this.transport_manager_last_name,
      }),
      // PART 2
      home_business: this.formBuilder.group({
        social_reason: new FormControl(),
        address: new FormControl(),
        phone: new FormControl(),
        email: new FormControl(),
        represented_by: new FormControl(),
        follower: new FormControl(),
        absence: new FormControl(),
        occupational_doctor: new FormControl()
      }),
      loading_materials: this.formBuilder.group({
        forklift: new FormControl(false),
        hand_pallet_truck: new FormControl(false),
        electric_pallet_truck: new FormControl(false),
        liftgate: new FormControl(false),
        hand_truck: new FormControl(false),
        manual_trolley: new FormControl(false),
        overhead_crane: new FormControl(false),
        other: new FormControl(false),
        other_detail: new FormControl(''),
      }),
      other_materials: this.formBuilder.array([]) as FormArray,
      rescue_materials: this.formBuilder.array([]) as FormArray,
      rooms_materials: this.formBuilder.array([]) as FormArray,
      // PART 3
      transport_business: this.formBuilder.group({
        social_reason: new FormControl(),
        address: new FormControl(),
        phone: new FormControl(),
        email: new FormControl(),
        represented_by: new FormControl(),
        follower: new FormControl(),
        absence: new FormControl(),
        occupational_doctor: new FormControl()
      }),
      vehicle: this.formBuilder.group({
        two_wheels: new FormControl(false),
        light_vehicle: new FormControl(false),
        transporter: new FormControl(false),
        heavy_goods_vehicle: new FormControl(false),
        trucks: new FormControl(false),
        heavy_goods_vehicle_with_trailer: new FormControl(false),
        other: new FormControl(false),
        other_detail: new FormControl(''),
      }),
      vehicle_facilities: this.formBuilder.group({
        tray: new FormControl(false),
        tipper: new FormControl(false),
        tarpaulin: new FormControl(false),
        van: new FormControl(false),
        bodied_vehicle: new FormControl(false),
        tank: new FormControl(false),
        other: new FormControl(false),
        other_detail: new FormControl(''),
      }),
      vehicle_equipment: this.formBuilder.group({
        forklift: new FormControl(false),
        hand_pallet_truck: new FormControl(false),
        electric_pallet_truck: new FormControl(false),
        hand_truck: new FormControl(false),
        manual_trolley: new FormControl(false),
        other: new FormControl(false),
        other_detail: new FormControl(''),
      }),
      merchandise: this.formBuilder.group({
        description: new FormControl(''),
        conditioning: this.formBuilder.group({
          bulk: new FormControl(false),
          big_bag: new FormControl(false),
          tank: new FormControl(false),
          pallets: new FormControl(false),
          crates: new FormControl(false),
          barrels: new FormControl(false),
          cartons: new FormControl(false),
          other: new FormControl(false),
          other_detail: new FormControl(''),
        }),
        precautions: new FormControl(''),
        is_dangerous_material: new FormControl(false),
        dangerous_materials: this.formBuilder.group({
          explosive: new FormControl(false),
          explosive_detail: new FormControl(''),
          flammable: new FormControl(false),
          flammable_detail: new FormControl(''),
          oxidizer: new FormControl(false),
          oxidizer_detail: new FormControl(''),
          toxic: new FormControl(false),
          toxic_detail: new FormControl(''),
          corrosive: new FormControl(false),
          corrosive_detail: new FormControl(''),
          environment: new FormControl(false),
          environment_detail: new FormControl(''),
          cmr: new FormControl(false),
          cmr_detail: new FormControl(''),
          health: new FormControl(false),
          health_detail: new FormControl(''),
          special_precautions: new FormControl(false),
          special_precautions_detail: new FormControl(''),
        })
      }),
      explosive_merchandise: this.explosive_merchandise,
      // PART 4
      emergency_numbers: this.formBuilder.array([]) as FormArray,
      instructions: this.formBuilder.array([]) as FormArray,
      prohibitions: this.formBuilder.group({
        smoke: new FormControl(false),
        drugs: new FormControl(false),
        other: new FormControl(false),
        other_detail: new FormControl(''),
      }),
      individual_protections: this.formBuilder.group({
        shoes: new FormControl(false),
        helmet: new FormControl(false),
        gloves: new FormControl(false),
        jacket: new FormControl(false),
        glasses: new FormControl(false),
        other: new FormControl(false),
        other_detail: new FormControl(''),
      }),
    });

    if (this.activatedRoute.snapshot.params.security_protocol_id) {
      this.isNew = false;
      this.getSecurityProtocol();
    } else {
      setTimeout(() => {
        Flatpickr();
      }, 1);
    }
  }

  ngAfterViewInit() {
    Dropzones();
    this.modalSecurityProtocol.open();
  }

  ngOnDestroy() {
    const flatPicker = document.getElementsByClassName('flatpickr-calendar') as HTMLCollection;
    for (let i = flatPicker.length; i >= 0; i--) {
      if (flatPicker[i]) {
        flatPicker[i].remove();
      }
    }

    const dropZones = document.getElementsByClassName('dz-hidden-input') as HTMLCollection;
    for (let i = 0; i < dropZones.length; i++) {
      dropZones[i].remove();
    }

    this.clearTooltip();

    this.modalSecurityProtocol.close();
  }
  clearTooltip() {
    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }
  changeTabs(activeTab) {
    this.activeTab = activeTab;
  }

  getSecurityProtocol() {
    this.requestInProgress.next(true);
    this.securityProtocolsService.getSecurityProtocol(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.activatedRoute.snapshot.params.security_protocol_id
    ).subscribe(response => {
      if (response['success']) {
        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];

        response['security_protocol']['other_materials'].forEach(() => {
          this.addOtherMaterial();
        });

        response['security_protocol']['rescue_materials'].forEach(() => {
          this.addRescueMaterial();
        });

        response['security_protocol']['rooms_materials'].forEach(() => {
          this.addRoomMaterial();
        });

        response['security_protocol']['emergency_numbers'].forEach(() => {
          this.addEmergencyNumber();
        });

        response['security_protocol']['instructions'].forEach(() => {
          this.addInstruction();
        });

        this.securityProtocolForm.patchValue(response['security_protocol']);

        if (!this.canUpdate) {
          this.securityProtocolForm.disable();
        }
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      Flatpickr();
      this.requestInProgress.next(false);
    });
  }
  createSecurityProtocol() {
    let request;
    this.errors = {};
    this.loader = true;

    const data = {
      ...this.securityProtocolForm.value,
    };

    request = this.securityProtocolsService.createSecurityProtocol(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      data
    );

    request.subscribe(response => {
      if (response['success']) {
        this.isNew = false;
        if (this.files.length || this.filesRequired.length) {
          this.createDocuments(response['securityProtocol']['id']);
        } else {
          this.loader = false;
          this.modalSecurityProtocol.close();
          CustomNotifierService.getSuccessCreate(this.notifier);
        }
      } else if (response['errors']) {
        this.loader = false;
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(response['errors'], this.securityProtocolForm, this.notifier);
      } else {
        this.loader = false;
      }
    });
  }
  updateSecurityProtocol() {
    let request;

    this.errors = {};
    this.loader = true;

    const data = {
      ...this.securityProtocolForm.value,
    };

    request = this.securityProtocolsService.updateSecurityProtocol(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.activatedRoute.snapshot.params.security_protocol_id,
      data
    );

    request.subscribe(response => {
      if (response['success']) {
        if (this.files.length || this.filesRequired.length) {
          this.createDocuments(this.activatedRoute.snapshot.params.security_protocol_id);
        } else {
          this.loader = false;
          this.modalSecurityProtocol.close();
          CustomNotifierService.getSuccessUpdate(this.notifier);
        }
      } else if (response['errors']) {
        this.loader = false;
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      } else {
        this.loader = false;
      }
    });
  }
  createDocuments(security_protocol_id) {
    let request;
    this.errors = {};

    request = this.securityProtocolsService.createFile(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      security_protocol_id,
      [...this.files, ...this.filesRequired]
    );

    request.subscribe(response => {
      if (response['success']) {
        this.modalSecurityProtocol.close();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loader = false;
    });
  }

  chooseFile() {
    const element: HTMLElement = document.getElementById('inputFile') as HTMLElement;
    element.click();
  }
  onFileChange(event) {
    if (event.target && event.target.files && event.target.files.length) {
      for (let i = 0; i < event.target.files.length; i++) {
        const reader = new FileReader();
        const file = event.target.files[i];
        reader.readAsDataURL(file);

        reader.onload = () => {
          let extension = '';
          let name = '';

          const split = file.name.split('.');
          if (split.length) {
            name = split[0];
            extension = split[split.length - 1];
          }

          this.files.push({ file_base64: reader.result, name: name, extension: extension });
        };
      }
    } else {
      let extension = '';
      let name = '';

      const split = event.name.split('.');
      if (split.length) {
        name = split[0];
        extension = split[split.length - 1];
      }

      this.files.push({ file_base64: event.file, name: name, extension: extension });
    }
    const element: HTMLInputElement = document.getElementById('inputFile') as HTMLInputElement;
    element.value = '';
  }
  removeFile(index) {
    this.files.splice(index, 1);

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  chooseFileRequired() {
    const element: HTMLElement = document.getElementById('inputFileRequired') as HTMLElement;
    element.click();
  }
  onFileChangeRequired(event) {
    if (event.target && event.target.files && event.target.files.length) {
      for (let i = 0; i < event.target.files.length; i++) {
        const reader = new FileReader();
        const file = event.target.files[i];
        reader.readAsDataURL(file);

        reader.onload = () => {
          let extension = '';
          let name = '';

          const split = file.name.split('.');
          if (split.length) {
            name = split[0];
            extension = split[split.length - 1];
          }

          this.filesRequired.push({ file_base64: reader.result, name: name, extension: extension });
        };
      }
    } else {
      let extension = '';
      let name = '';

      const split = event.name.split('.');
      if (split.length) {
        name = split[0];
        extension = split[split.length - 1];
      }

      this.filesRequired.push({ file_base64: event.file, name: name, extension: extension });
    }
    const element: HTMLInputElement = document.getElementById('inputFileRequired') as HTMLInputElement;
    element.value = '';
  }
  removeFileRequired(index) {
    this.filesRequired.splice(index, 1);

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  addOtherMaterial() {
    const control = <FormArray>this.securityProtocolForm.controls['other_materials'];
    control.push(this.formBuilder.group({
      name: [''],
    }));
  }
  deleteOtherMaterial(index) {
    const control = <FormArray>this.securityProtocolForm.controls['other_materials'];
    control.removeAt(index);
    this.clearTooltip();
  }

  addRescueMaterial() {
    const control = <FormArray>this.securityProtocolForm.controls['rescue_materials'];
    control.push(this.formBuilder.group({
      name: [''],
    }));
  }
  deleteRescueMaterial(index) {
    const control = <FormArray>this.securityProtocolForm.controls['rescue_materials'];
    control.removeAt(index);
    this.clearTooltip();
  }

  addRoomMaterial() {
    const control = <FormArray>this.securityProtocolForm.controls['rooms_materials'];
    control.push(this.formBuilder.group({
      name: [''],
    }));
  }
  deleteRoomMaterial(index) {
    const control = <FormArray>this.securityProtocolForm.controls['rooms_materials'];
    control.removeAt(index);
    this.clearTooltip();
  }

  addEmergencyNumber() {
    const control = <FormArray>this.securityProtocolForm.controls['emergency_numbers'];
    control.push(this.formBuilder.group({
      organisation: ['', Validators.required],
      number: ['', Validators.required],
      comment: ['', Validators.required],
    }));
  }
  deleteEmergencyNumber(index) {
    const control = <FormArray>this.securityProtocolForm.controls['emergency_numbers'];
    control.removeAt(index);
    this.clearTooltip();
  }

  addInstruction() {
    const control = <FormArray>this.securityProtocolForm.controls['instructions'];
    control.push(this.formBuilder.group({
      name: ['', Validators.required],
    }));
  }
  deleteInstruction(index) {
    const control = <FormArray>this.securityProtocolForm.controls['instructions'];
    control.removeAt(index);
    this.clearTooltip();
  }

  bindModal(modal) {
    this.modalSecurityProtocol = modal;
  }
  closeModal() {
    this.router.navigate([this.activatedRoute.snapshot.parent.url.join('/')]).then(() => {
      this.allSecurityProtocols.refreshData();
    });
  }

  checkProtection(protection) {
    return !!(protection['shoes'].value ||
      protection['helmet'].value ||
      protection['gloves'].value ||
      protection['jacket'].value ||
      protection['glasses'].value ||
      protection['other'].value);
  }
}
