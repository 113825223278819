<div class="main-content">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col">
        <div class="header mb-0">
          <div class="header-body no-border">
            <div class="row align-items-center">
              <div class="col">
                <h1 class="header-title">Formations</h1>

                <small class="form-text text-muted">
                  Liste des employées devant être formées
                </small>

                <app-breadcrumbs
                  [currentPage]="pageName"
                  [names]="previousPageName"
                  [routes]="previousPageRoute"
                >
                </app-breadcrumbs>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col">
            <app-searchbar
              [(ngModel)]="search"
              (keyup)="searchData()"
              ngDefaultControl
            ></app-searchbar>
          </div>
        </div>

        <div class="is-loading loader-content" *ngIf="loader"></div>

        <div class="tab-content" *ngIf="!loader && employeesTraining.length">
          <div
            class="tab-pane fade active show"
            id="tabPaneOne"
            role="tabpanel"
          >
            <div class="row listAlias">
              <div class="col-12 d-flex flex-column">
                <div class="card flex-fill">
                  <div class="card-body">
                    <div class="table-responsive">
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th
                              class="href"
                              (click)="filter('Establishments.name')"
                            >
                              <a class="text-muted">Établissement</a>
                              <span
                                class="fe fe-arrow-down"
                                *ngIf="
                                  sort === 'Establishments.name' &&
                                  order === 'asc'
                                "
                              ></span>
                              <span
                                class="fe fe-arrow-up"
                                *ngIf="
                                  sort === 'Establishments.name' &&
                                  order === 'desc'
                                "
                              ></span>
                              <span
                                class="fe fe-arrow-right"
                                *ngIf="sort !== 'Establishments.name'"
                              ></span>
                            </th>
                            <th
                              class="href"
                              (click)="filter('EmployeeTrainings.firstname')"
                            >
                              <a class="text-muted">Nom</a>
                              <span
                                class="fe fe-arrow-down"
                                *ngIf="
                                  sort === 'EmployeeTrainings.firstname' &&
                                  order === 'asc'
                                "
                              ></span>
                              <span
                                class="fe fe-arrow-up"
                                *ngIf="
                                  sort === 'EmployeeTrainings.firstname' &&
                                  order === 'desc'
                                "
                              ></span>
                              <span
                                class="fe fe-arrow-right"
                                *ngIf="sort !== 'EmployeeTrainings.firstname'"
                              ></span>
                            </th>
                            <th
                              class="href"
                              (click)="filter('EmployeeTrainings.lastname')"
                            >
                              <a class="text-muted">Prénom</a>
                              <span
                                class="fe fe-arrow-down"
                                *ngIf="
                                  sort === 'EmployeeTrainings.lastname' &&
                                  order === 'asc'
                                "
                              ></span>
                              <span
                                class="fe fe-arrow-up"
                                *ngIf="
                                  sort === 'EmployeeTrainings.lastname' &&
                                  order === 'desc'
                                "
                              ></span>
                              <span
                                class="fe fe-arrow-right"
                                *ngIf="sort !== 'EmployeeTrainings.lastname'"
                              ></span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            class="dropdown"
                            *ngFor="let employeesTrain of employeesTraining"
                          >
                            <td>
                              <a
                                class="href"
                                [routerLink]="[
                                  currentRoute + employeesTrain.id
                                ]"
                              >
                                {{
                                  employeesTrain.date_training.training
                                    .establishment.name
                                }}
                              </a>
                            </td>
                            <td>
                              <a
                                class="href"
                                [routerLink]="[
                                  currentRoute + employeesTrain.id
                                ]"
                              >
                                {{ employeesTrain.employee_training.firstname }}
                              </a>
                            </td>
                            <td>
                              <a
                                class="href"
                                [routerLink]="[
                                  currentRoute + employeesTrain.id
                                ]"
                              >
                                {{ employeesTrain.employee_training.lastname }}
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <app-pagination
                      (returnEvent)="dispatchPagination($event)"
                      [count_elements]="count"
                      [current_page]="page"
                      [elements_length]="employeesTraining.length"
                      [limit]="limit"
                      [total_pages]="pages"
                      ngDefaultControl
                    >
                    </app-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="loader-content"
          *ngIf="!loader && !employeesTraining.length"
        >
          <p>Aucun résultat</p>
        </div>
      </div>
    </div>
  </div>
</div>

<app-confirm-modal [type]="'delete_data'" #confirmData>{{
  bindModalConfirmData(confirmData)
}}</app-confirm-modal>

<router-outlet></router-outlet>
