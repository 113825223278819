import { Component, OnInit, AfterViewInit, OnDestroy, Injector } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';

import { AllContentsComponent } from '../all-contents/all-contents.component';
import { AllCommissionsComponent } from '../../commissions/all-commissions/all-commissions.component';
import { CustomNotifierService } from '../../../services/custom.notifier.service';

import { ContentsRequestService } from '../../../services/contents.request.service';
import { Subject } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-update-content',
  templateUrl: './update-content.component.html',
  styleUrls: ['./update-content.component.scss']
})
export class UpdateContentComponent implements OnInit, AfterViewInit, OnDestroy {
  canCreate = false;
  canUpdate = false;

  requestInProgress = new Subject();
  requestCount = 0;

  contentForm: FormGroup;
  id = new FormControl('');
  title = new FormControl('', Validators.required);
  short_description = new FormControl('');
  description = new FormControl('');

  errors = {};

  isNew = true;
  loader = false;
  loaderContent = false;

  allContents: any;
  allCommissions: any;

  view = '';
  showDescription: SafeHtml = '';

  modalContent = null;

  contents: any = [];

  constructor(
    private router: Router,
    private injector: Injector,
    public sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private contentRequestService: ContentsRequestService
  ) {
    if (this.activatedRoute.snapshot.parent.url[4] && this.activatedRoute.snapshot.parent.url[4].path === 'commissions') {
      this.allCommissions = <AllCommissionsComponent>this.injector.get(AllCommissionsComponent);
    } else {
      this.allContents = <AllContentsComponent>this.injector.get(AllContentsComponent);
    }
  }

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loaderContent = this.requestCount > 0;
    });

    this.contentForm = this.formBuilder.group({
      id: this.id,
      title: this.title,
      short_description: this.short_description,
      description: this.description,
    });

    if (this.activatedRoute.snapshot.params.content_id) {
      this.isNew = false;
      this.getContent(this.activatedRoute.snapshot.params.content_id);
    }

    if (this.activatedRoute.snapshot.parent.url[4] && this.activatedRoute.snapshot.parent.url[4].path === 'commissions') {
      this.view = 'commissions';
    } else {
      this.view = 'admin';
    }
  }

  ngAfterViewInit() {
    this.modalContent.open();
  }

  ngOnDestroy() {
    this.modalContent.close();
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  getContent(content_id) {
    this.errors = {};
    this.requestInProgress.next(true);

    this.contentRequestService.getContent(content_id).subscribe(response => {
      if (response['success']) {
        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];

        if (this.view === 'admin') {
          this.contentForm.patchValue(response['content']);
          if (!this.canUpdate) {
            this.contentForm.disable();
          }
        } else {
          this.showDescription = this.sanitizer.bypassSecurityTrustHtml(response['content']['description']);
        }
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.contentForm, this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  createContent() {
    this.errors = {};
    this.loader = true;

    this.contentRequestService.createContent(this.contentForm.value).subscribe(response => {
      if (response['success']) {
        this.modalContent.close();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.contentForm, this.notifier);
      }
      this.loader = false;
    });
  }

  updateContent() {
    this.errors = {};
    this.loader = true;

    this.contentRequestService.updateContent(this.id.value, this.contentForm.value).subscribe(response => {
      if (response['success']) {
        this.modalContent.close();
        CustomNotifierService.getSuccessUpdate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.contentForm, this.notifier);
      }
      this.loader = false;
    });
  }

  binModal(modal) {
    this.modalContent = modal;
  }

  closeModal() {
    if (this.view === 'commissions') {
      this.allCommissions.getCommissions();
      this.allCommissions.getContent();
    } else {
      this.allContents.getAllContents();
    }
    this.router.navigate([this.activatedRoute.snapshot.parent.url.join('/')]).then(() => {});
  }
}
