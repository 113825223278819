import { Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import * as moment from 'moment';
import * as _ from 'lodash';

import { AuthService } from '../auth/auth.service';

import { CalendarRequestService } from '../../services/calendar.request.service';
import { CustomNotifierService } from '../../services/custom.notifier.service';

import { DashboardRegistryComponent } from '../../pages/registries/dashboard-registry/dashboard-registry.component';
import Establishment from '../../../shared/interfaces/establishment';
import Registry from '../../../shared/interfaces/registry';
import { EstablishmentsRequestService } from '../../services/establishments.request.service';
import { RegistriesRequestService } from '../../services/registries.request.service';

export interface CalendarDate {
  mDate: moment.Moment;
  selected?: boolean;
  today?: boolean;
  weekend?: boolean;
  event?: any;
}

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit, OnDestroy {
  @Input() registry;
  @Input() establishment;

  canCreate = false;

  changeEnterprise: Subscription;
  changeEstablishment: Subscription;

  requestInProgress = new Subject();
  requestCount = 0;

  pageName: string;
  previousPageName: string[] = [];
  previousPageRoute: string[] = [];

  months: any;
  years: any = [];
  currentDate: moment.Moment;
  dayNames: {
    day: number;
    name: string;
  };
  weeks: CalendarDate[][] = [];
  selectedDates: CalendarDate;

  dateOne;
  dateTwo;

  errors = {};

  viewPlanning = false;
  loaderPlanning = false;
  loaderGetReport = false;
  loaderScroll = false;

  limit = 10;
  page = 1;
  pages = 1;

  dayEvents = [];
  weekEvents = [];
  monthEvents = [];

  currentRoute = '';
  view = 'all';

  establishments: Establishment[] = [];
  establishmentsSelected: number[] = [];
  registries: Registry[] = [];
  registriesSelected: number[] = [];

  static isToday(date: moment.Moment): boolean {
    return moment().isSame(moment(date), 'day');
  }

  static isWeekend(date: moment.Moment): boolean {
    return moment(date).day() === 0 || moment(date).day() === 6;
  }

  constructor(
    private injector: Injector,
    private authService: AuthService,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private establishmentsService: EstablishmentsRequestService,
    private registriesService: RegistriesRequestService,
    private calendarService: CalendarRequestService
  ) {
    moment.locale('fr');
    this.currentDate = moment();
    this.months = moment.months();

    this.dayNames = Array.apply(null, Array(7)).map(function (e, i) {
      return {
        day: moment(i, 'e').day(),
        name: _.upperFirst(moment(i, 'e').startOf('week').isoWeekday(i + 1).format('ddd'))
      };
    });
  }

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loaderPlanning = this.requestCount > 0;

      if (!this.loaderPlanning) {
        this.generateCalendar();

        this.weeks.forEach(week => {
          week.forEach(day => {
            if (day.today) {
              this.selectedDates = day;
              day.selected = true;
            }
          }, this);
        }, this);

        this.getEventsDay();
      }
    });

    this.pageName = 'Calendrier';
    this.currentRoute = '/' + this.activatedRoute.snapshot.url.join('/') + '/';

    if (this.activatedRoute.snapshot.url[0].path === 'planning') {
      this.viewPlanning = true;
      this.getMyEstablishments();
      this.getMyRegistries();
    }

    this.changeEnterprise = this.authService.getEnterprise().subscribe(() => {
      this.generateCalendar();

      this.weeks.forEach(week => {
        week.forEach(day => {
          if (day.today) {
            this.selectedDates = day;
            setTimeout(() => {
              this.selectedDates.selected = true;
              this.setDayDate();
            }, 150);
          }
        }, this);
      }, this);

      this.getEvents();

      this.createBreadcrumbs();
    });

    if (this.registry && this.registry.id) {
      const dashboardRegistry = <DashboardRegistryComponent>this.injector.get(DashboardRegistryComponent);
      this.changeEstablishment = dashboardRegistry.getEstablishment().subscribe(response => {
        if (response) {
          this.establishment = {id: response};
        } else {
          this.establishment = null;
        }

        this.generateCalendar();

        this.weeks.forEach(week => {
          week.forEach(day => {
            if (day.today) {
              this.selectedDates = day;
              setTimeout(() => {
                this.selectedDates.selected = true;
                this.setDayDate();
              }, 150);
            }
          }, this);
        }, this);

        this.getEvents();
      });
    }

    if (this.authService.loaded) {
      this.generateCalendar();

      this.weeks.forEach(week => {
        week.forEach(day => {
          if (day.today) {
            this.selectedDates = day;
            setTimeout(() => {
              this.selectedDates.selected = true;
              this.setDayDate();
            }, 150);
          }
        }, this);
      }, this);

      this.getEvents();

      this.createBreadcrumbs();
    }

    let year = this.currentDate.year();
    year -= 3;
    for (let i = 0; i < 7; i++) {
      this.years.push(year + i);
    }
  }

  ngOnDestroy() {
    if (this.changeEnterprise instanceof Subscription) {
      this.changeEnterprise.unsubscribe();
    }

    if (this.registry && this.registry.id) {
      this.changeEstablishment.unsubscribe();
    }

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  // GET DAY EVENT
  getEventsDay() {
    this.dayEvents = [];
    this.setDayDate();

    this.monthEvents.forEach(event => {
      if (!event.is_report && moment(event.date).isSame(this.selectedDates.mDate.format('YYYY-MM-DD'))) {
        this.dayEvents.push(event);
      }
    }, this);

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }
  // REQUEST API GET EVENTS
  getEvents() {
    this.dayEvents = [];
    this.monthEvents = [];
    this.weekEvents = [];

    let requestCheckpoints;
    let requestObservations;
    let requestCommissions;
    let requestAsbestos;
    let requestTrainings;
    let requestRisks;

    if (this.registry && this.establishment) {
      if (this.registry.checkpoints_module || this.registry.checkpoints_custom_module || this.registry.checkpoints_direct_module) {
        requestCheckpoints = this.calendarService.getAllCheckpoint(
          'month', this.selectedDates.mDate.format('YYYY-MM-DD'), this.registry.id, this.establishment.id
        );

        requestObservations = this.calendarService.getAllObservations(
          'month', this.selectedDates.mDate.format('YYYY-MM-DD'), this.registry.id, this.establishment.id
        );
      }

      if (this.registry.commissions_module || this.registry.expertises_module) {
        requestCommissions = this.calendarService.getAllCommissions(
          'month', this.selectedDates.mDate.format('YYYY-MM-DD'), this.registry.id, this.establishment.id
        );
      }

      if (this.registry.asbestos_module) {
        requestAsbestos = this.calendarService.getAllAsbestos(
          'month', this.selectedDates.mDate.format('YYYY-MM-DD'), this.registry.id, this.establishment.id
        );
      }

      if (this.registry.formations_module) {
        requestTrainings = this.calendarService.getAllTrainings(
          'month', this.selectedDates.mDate.format('YYYY-MM-DD'), this.registry.id, this.establishment.id
        );
      }

      if (this.registry.id === 8 || this.registry.id === '8') {
        requestRisks = this.calendarService.getAllRisks(
          'month', this.selectedDates.mDate.format('YYYY-MM-DD'), this.registry.id, this.establishment.id
        );
      }
    } else if (this.registry && !this.establishment) {
      if (this.registry.checkpoints_module || this.registry.checkpoints_custom_module || this.registry.checkpoints_direct_module) {
        requestCheckpoints = this.calendarService.getAllCheckpoint(
          'month', this.selectedDates.mDate.format('YYYY-MM-DD'), this.registry.id
        );

        requestObservations = this.calendarService.getAllObservations(
          'month', this.selectedDates.mDate.format('YYYY-MM-DD'), this.registry.id
        );
      }

      if (this.registry.commissions_module || this.registry.expertises_module) {
        requestCommissions = this.calendarService.getAllCommissions(
          'month', this.selectedDates.mDate.format('YYYY-MM-DD'), this.registry.id
        );
      }

      if (this.registry.asbestos_module) {
        requestAsbestos = this.calendarService.getAllAsbestos(
          'month', this.selectedDates.mDate.format('YYYY-MM-DD'), this.registry.id
        );
      }

      if (this.registry.formations_module) {
        requestTrainings = this.calendarService.getAllTrainings(
          'month', this.selectedDates.mDate.format('YYYY-MM-DD'), this.registry.id
        );
      }

      if (this.registry.id === 8 || this.registry.id === '8') {
        requestRisks = this.calendarService.getAllRisks(
          'month', this.selectedDates.mDate.format('YYYY-MM-DD'), this.registry.id
        );
      }
    } else {
      requestCheckpoints = this.calendarService.getAllCheckpoint(
        'month', this.selectedDates.mDate.format('YYYY-MM-DD'), '', '', this.establishmentsSelected, this.registriesSelected
      );
      requestObservations = this.calendarService.getAllObservations(
        'month', this.selectedDates.mDate.format('YYYY-MM-DD'), '', '', this.establishmentsSelected, this.registriesSelected
      );
      requestCommissions = this.calendarService.getAllCommissions(
        'month', this.selectedDates.mDate.format('YYYY-MM-DD'), '', '', this.establishmentsSelected, this.registriesSelected
      );
      requestAsbestos = this.calendarService.getAllAsbestos(
        'month', this.selectedDates.mDate.format('YYYY-MM-DD'), '', '', this.establishmentsSelected, this.registriesSelected
      );
      requestTrainings = this.calendarService.getAllTrainings(
        'month', this.selectedDates.mDate.format('YYYY-MM-DD'), '', '', this.establishmentsSelected, this.registriesSelected
      );
      requestRisks = this.calendarService.getAllRisks(
        'month', this.selectedDates.mDate.format('YYYY-MM-DD'), '', '', this.establishmentsSelected, this.registriesSelected
      );
    }

    if (requestCommissions) {
      this.requestInProgress.next(true);
      requestCommissions.subscribe(response => {
        if (response['success']) {
          response['month'].forEach(event => {
            event.next_commission = true;
            event.startDate = moment(event.commission_date).subtract('2', 'weeks');
            event.endDate = moment(event.commission_date).add('2', 'weeks');
            this.setEventBetweenDates(event.startDate, event.endDate, event);
          }, this);
        } else {
          CustomNotifierService.getErrors(response['errors'], this.notifier);
        }
        this.requestInProgress.next(false);
      });
    }
    if (requestCheckpoints) {
      this.requestInProgress.next(true);
      requestCheckpoints.subscribe(response => {
        if (response['success']) {
          this.canCreate = response['canCreate'];

          response['month'].forEach(event => {
            if (event.custom_next_report_date) {
              if (event.reports && event.reports.length) {
                const date = moment(event.reports[0].visit_date).add(event.frequency_duration, event.frequency);
                if (moment(event.next_report_date).isSameOrBefore(date, 'd')) {
                  event.to_do_before = true;
                } else {
                  event.to_do_after = true;
                }
              } else {
                if (moment(event.next_report_date).isBefore(moment(), 'd')) {
                  event.to_do_late = true;
                } else {
                  event.to_do_not_late = true;
                }
              }
            } else {
              if (moment(event.next_report_date).isBefore(moment(), 'd')) {
                event.to_do_late = true;
              } else {
                event.to_do_not_late = true;
              }
            }
            event.date = moment(event.next_report_date, 'YYYY-MM-DD|THH:mm:ssZZ').format('YYYY-MM-DD');
            this.monthEvents.push(event);
          }, this);
        } else {
          CustomNotifierService.getErrors(response['errors'], this.notifier);
        }
        this.requestInProgress.next(false);
      });
    }
    if (requestObservations) {
      this.requestInProgress.next(true);
      requestObservations.subscribe(response => {
        if (response['success']) {
          response['month'].forEach(event => {
            if (event.nbReports && event.nbReports !== 0) {
              event.done = true;
              event.is_report = true;
              for (let i = 0; i < event.nbReports; i++) {
                this.monthEvents.push(event);
              }
            } else {
              event.done = true;
              event.date = event.visit_date;
              this.monthEvents.push(event);
            }
          }, this);
        } else {
          CustomNotifierService.getErrors(response['errors'], this.notifier);
        }
        this.requestInProgress.next(false);
      });
    }
    if (requestAsbestos) {
      this.requestInProgress.next(true);
      requestAsbestos.subscribe(response => {
        if (response['success']) {
          response['month'].forEach(event => {
            if (moment(event.asbestos_detection.date).isBefore(moment())) {
              event.to_do_late = true;
            } else {
              event.to_do_not_late = true;
            }
            event.date = event.asbestos_detection.date;
            this.monthEvents.push(event);
          }, this);
        } else {
          CustomNotifierService.getErrors(response['errors'], this.notifier);
        }
        this.requestInProgress.next(false);
      });
    }
    if (requestTrainings) {
      this.requestInProgress.next(true);
      requestTrainings.subscribe(response => {
        if (response['success']) {
          response['month'].forEach(event => {
            if (moment(event.next_date_training).isBefore(moment())) {
              event.to_do_late = true;
            } else {
              event.to_do_not_late = true;
            }
            event.training = true;
            event.date = event.next_date_training;
            this.monthEvents.push(event);
          }, this);
        } else {
          CustomNotifierService.getErrors(response['errors'], this.notifier);
        }
        this.requestInProgress.next(false);
      });
    }
    if (requestRisks) {
      this.requestInProgress.next(true);
      requestRisks.subscribe(response => {
        if (response['success']) {
          response['month'].forEach(event => {
            if (moment(event.deadline).isBefore(moment())) {
              event.to_do_late = true;
            } else {
              event.to_do_not_late = true;
            }
            event.risk = true;
            event.date = event.deadline;
            this.monthEvents.push(event);
          }, this);
        } else {
          CustomNotifierService.getErrors(response['errors'], this.notifier);
        }
        this.requestInProgress.next(false);
      });
    }
  }
  // REFRESH EVENTS
  refreshEvents() {
    this.monthEvents = [];
    this.getEvents();
    this.getEventsDay();
  }

  // LOOP FORM COMMISSION NEXT DATE 2 WEEKS BEFORE TWO WEEKS AFTER
  setEventBetweenDates(startDate, endDate, event) {
    const now = startDate.clone();
    while (now.isSameOrBefore(endDate)) {
      if (now.isSame(startDate)) {
        this.monthEvents.push({
          date: now.format('YYYY-MM-DD'),
          start_date: true,
          commission_date: event.commission_date,
          establishment: event.establishment,
          next_commission: true
        });
      } else if (now.isSame(endDate)) {
        this.monthEvents.push({
          date: now.format('YYYY-MM-DD'),
          end_date: true,
          commission_date: event.commission_date,
          establishment: event.establishment,
          next_commission: true
        });
      } else {
        this.monthEvents.push({
          date: now.format('YYYY-MM-DD'),
          commission_date: event.commission_date,
          establishment: event.establishment,
          next_commission: true
        });
      }
      now.add('1', 'days');
    }
  }

  // CALENDAR SETTINGS
  isSelected(date: moment.Moment): boolean {
    if (this.selectedDates) {
      return moment(date).isSame(this.selectedDates.mDate, 'day');
    }
  }
  isSelectedMonth(date: moment.Moment): boolean {
    return moment(date).isSame(this.currentDate, 'month');
  }
  setEvent(date: moment.Moment): any {
    const event = [];

    this.monthEvents.forEach(function (monthEvent) {
      if (monthEvent.date === date.format('YYYY-MM-DD')) {
        if (monthEvent.to_do_late) {
          const find = event.find(element => {
            return element.to_do_late === monthEvent.to_do_late;
          });
          if (find) {
            find.count++;
          } else {
            event.push({...monthEvent, count: 1});
          }
        }

        if (monthEvent.to_do_not_late) {
          const find = event.find(element => {
            return element.to_do_not_late === monthEvent.to_do_not_late;
          });
          if (find) {
            find.count++;
          } else {
            event.push({...monthEvent, count: 1});
          }
        }

        if (monthEvent.to_do_before) {
          const find = event.find(element => {
            return element.to_do_before === monthEvent.to_do_before;
          });
          if (find) {
            find.count++;
          } else {
            event.push({...monthEvent, count: 1});
          }
        }

        if (monthEvent.to_do_after) {
          const find = event.find(element => {
            return element.to_do_after === monthEvent.to_do_after;
          });
          if (find) {
            find.count++;
          } else {
            event.push({...monthEvent, count: 1});
          }
        }

        if (monthEvent.done) {
          const find = event.find(element => {
            return element.done === monthEvent.done;
          });
          if (find) {
            find.count++;
          } else {
            event.push({...monthEvent, count: 1});
          }
        }

        if (monthEvent.next_commission) {
          const find = event.find(element => {
            return element.next_commission === monthEvent.next_commission;
          });
          if (find) {
            find.count++;
          } else {
            event.unshift({...monthEvent, count: 1});
          }
        }

        // if (monthEvent.risk) {
        //   const find = event.find(element => {
        //     return element.risk === monthEvent.risk;
        //   });
        //   if (find) {
        //     find.count++;
        //   } else {
        //     event.push({...monthEvent, count: 1});
        //   }
        // }
      }
    });
    return event;
  }
  selectDate(date: CalendarDate, event): void {
    this.selectedDates.selected = false;
    this.selectedDates = date;
    date.selected = true;
    if (event.is_report) {
      this.dayEvents = [];
      this.getReports();
    } else {
      this.getEventsDay();
    }
  }

  // GO BACK TODAY
  goBackToday() {
    this.selectedDates.selected = false;
    if (
      !moment(this.selectedDates.mDate).isSame(moment(), 'month') ||
      !moment(this.selectedDates.mDate).isSame(this.currentDate, 'month')) {
      this.currentDate = moment();
      this.generateCalendar();
    }

    this.weeks.forEach(week => {
      week.forEach(day => {
        if (day.today) {
          this.selectedDates = day;
          day.selected = true;
        }
      }, this);
    }, this);

    this.getEvents();
    this.getEventsDay();
  }

  // MONTH VIEW
  setMonth(month) {
    this.monthEvents = [];
    this.currentDate = moment(this.currentDate).month(month);

    this.generateCalendar();

    this.weeks.forEach(week => {
      week.forEach(day => {
        if ((day.mDate.month() === this.currentDate.month()) && day.mDate.date() === 1) {
          this.selectedDates = day;
          day.selected = true;
          this.selectedDates.today = CalendarComponent.isToday(this.selectedDates.mDate);
          this.selectedDates.weekend = CalendarComponent.isWeekend(this.selectedDates.mDate);
        }
      }, this);
    }, this);

    this.getEvents();
  }
  changeMonth(date: CalendarDate) {
    this.selectedDates.selected = false;
    this.selectedDates = date;

    if (date.mDate.isBefore(this.currentDate)) {
      this.prevMonth(false);
    } else {
      this.nextMonth(false);
    }
  }
  prevMonth(isButton = true) {
    this.monthEvents = [];
    this.currentDate = moment(this.currentDate).subtract(1, 'months');
    this.generateCalendar();

    if (isButton) {
      this.weeks.forEach(week => {
        week.forEach(day => {
          if ((day.mDate.month() === this.currentDate.month()) && day.mDate.date() === 1) {
            this.selectedDates = day;
            day.selected = true;
            this.selectedDates.today = CalendarComponent.isToday(this.selectedDates.mDate);
            this.selectedDates.weekend = CalendarComponent.isWeekend(this.selectedDates.mDate);
          }
        }, this);
      }, this);
    } else {
      this.weeks.forEach(week => {
        week.forEach(day => {
          if (day.mDate.isSame(this.selectedDates.mDate, 'day')) {
            this.selectedDates = day;
            day.selected = true;
            this.selectedDates.today = CalendarComponent.isToday(this.selectedDates.mDate);
            this.selectedDates.weekend = CalendarComponent.isWeekend(this.selectedDates.mDate);
          }
        }, this);
      }, this);
    }

    this.getEvents();
    this.getEventsDay();
  }
  nextMonth(isButton = true) {
    this.monthEvents = [];
    this.currentDate = moment(this.currentDate).add(1, 'months');
    this.generateCalendar();

    if (isButton) {
      this.weeks.forEach(week => {
        week.forEach(day => {
          if ((day.mDate.month() === this.currentDate.month()) && day.mDate.date() === 1) {
            this.selectedDates = day;
            day.selected = true;
            this.selectedDates.today = CalendarComponent.isToday(this.selectedDates.mDate);
            this.selectedDates.weekend = CalendarComponent.isWeekend(this.selectedDates.mDate);
          }
        }, this);
      }, this);
    } else {
      this.weeks.forEach(week => {
        week.forEach(day => {
          if (day.mDate.isSame(this.selectedDates.mDate, 'day')) {
            this.selectedDates = day;
            day.selected = true;
            this.selectedDates.today = CalendarComponent.isToday(this.selectedDates.mDate);
            this.selectedDates.weekend = CalendarComponent.isWeekend(this.selectedDates.mDate);
          }
        }, this);
      }, this);
    }

    this.getEvents();
    this.getEventsDay();
  }

  setYear(year) {
    this.monthEvents = [];
    this.currentDate = moment(this.currentDate).year(year);

    this.generateCalendar();

    this.weeks.forEach(week => {
      week.forEach(day => {
        if ((day.mDate.month() === this.currentDate.month()) && day.mDate.date() === 1) {
          this.selectedDates = day;
          day.selected = true;
          this.selectedDates.today = CalendarComponent.isToday(this.selectedDates.mDate);
          this.selectedDates.weekend = CalendarComponent.isWeekend(this.selectedDates.mDate);
        }
      }, this);
    }, this);

    this.getEvents();
  }

  // GENERATE ALL TYPE OF CALENDAR
  generateCalendar() {
    const dates = this.fillDates(this.currentDate);
    const weeks: CalendarDate[][] = [];
    while (dates.length > 0) {
      weeks.push(dates.splice(0, 7));
    }
    this.weeks = weeks;
  }
  fillDates(currentMoment: moment.Moment): CalendarDate[] {
    const startOfGrid = currentMoment.clone().startOf('month').isoWeekday(1).startOf('isoWeek');
    const endOfGrid = currentMoment.clone().endOf('month').isoWeekday(1).endOf('isoWeek');

    const start = startOfGrid.date();
    const end = startOfGrid.date() + Math.ceil(endOfGrid.diff(startOfGrid, 'days', true));

    return _.range(start, end).map((date: number): CalendarDate => {
      const d = moment(startOfGrid).date(date);
      return {
        today: CalendarComponent.isToday(d),
        selected: this.isSelected(d),
        mDate: d,
        weekend: CalendarComponent.isWeekend(d),
        event: this.setEvent(d)
      };
    });
  }

  // EVENTS
  setDayDate() {
    this.dateOne = this.selectedDates.today ? 'Aujourd\'hui' : this.selectedDates.mDate.format('dddd D MMMM');
    this.dateTwo = this.selectedDates.mDate.format('DD/MM/YYYY');
  }

  getRouteEvent(event) {
    if (event.to_do_late || event.to_do_not_late || event.to_do_before || event.to_do_after) {
      if (event.establishment_checkpoint) {
        return this.currentRoute + 'installation/' + event.establishment_checkpoint.establishment_installation.id +
          '/checkpoints/' + event.establishment_checkpoint.id + '/reports/' + event.id;
      } else if (event.establishment_registry) {
        return this.canCreate ? this.currentRoute + 'checkpoints/' + event.id + '/reports/create' : null;
      }
    } else if (event.done && event.establishment_checkpoint) {
      return this.currentRoute + 'installation/' + event.establishment_checkpoint.establishment_installation.id +
        '/checkpoints/' + event.establishment_checkpoint.id + '/reports/' + event.id;
    }
  }

  // BREADCRUMBS
  createBreadcrumbs() {
    this.previousPageName = [];
    this.previousPageRoute = [];

    this.previousPageName.push('Tableau de bord');
    this.previousPageRoute.push('/');
  }

  getMyEstablishments() {
    this.requestInProgress.next(true);
    this.establishmentsService.getMyEstablishments('', '', '', '', '').subscribe(response => {
      if (response['success']) {
        this.establishments = response['establishments'];
        this.establishments.forEach(establishment => {
          establishment['group'] = true;
        });
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
    });
    this.requestInProgress.next(false);
  }
  getMyRegistries() {
    this.requestInProgress.next(true);
    this.registriesService.getMyRegistries('', '', '', '', '').subscribe(response => {
      if (response['success']) {
        this.registries = response['registries'];
        this.registries.forEach(registry => {
          registry['group'] = true;
        });
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
    });
    this.requestInProgress.next(false);
  }

  getReports() {
    this.page = 1;
    this.loaderGetReport = true;
    let requestObservations;

    requestObservations = this.calendarService.getAllObservations(
      'month', this.selectedDates.mDate.format('YYYY-MM-DD'), '', '', this.establishmentsSelected,
      this.registriesSelected, this.selectedDates.mDate.format('YYYY-MM-DD'), this.limit, this.page);

    requestObservations.subscribe(response => {
      if (response['success']) {
        response['month']['checkpoints'].forEach(event => {
          event.done = true;
          this.dayEvents.push(event);
          this.pages = response['month']['pages'];
        }, this);
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loaderGetReport = false;
    });
  }

  onScroll(page) {
    if (page >= 1 && page < this.pages && !this.loaderScroll) {
      page ++;
      this.page = page;
      this.loaderScroll = true;
      let requestObservations;

      requestObservations = this.calendarService.getAllObservations(
        'month', this.selectedDates.mDate.format('YYYY-MM-DD'), '', '', this.establishmentsSelected,
        this.registriesSelected, this.selectedDates.mDate.format('YYYY-MM-DD'), this.limit, this.page);

      requestObservations.subscribe(response => {
        if (response['success']) {
          response['month']['checkpoints'].forEach(event => {
            event.done = true;
            this.dayEvents.push(event);
          }, this);
        } else {
          CustomNotifierService.getErrors(response['errors'], this.notifier);
        }
        this.loaderScroll = false;
      });
    }
  }
}
