<div class="main-content">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col">
        <div class="header mb-0">
          <div class="header-body no-border">
            <div class="row align-items-center">
              <div class="col">
                <h1 class="header-title">Notification des formations</h1>

                <small class="form-text text-muted">
                  Liste des alertes pour recyclage des formations
                </small>

                <app-breadcrumbs
                  [currentPage]="pageName"
                  [names]="previousPageName"
                  [routes]="previousPageRoute"
                ></app-breadcrumbs>
              </div>

              <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0">
                <a
                  class="btn btn-primary d-block d-md-inline-block text-white"
                  (click)="update()"
                >
                  Mettre à jour
                </a>
              </div>

              <div
                class="col-12 col-md-auto mt-2 mt-md-0 mb-0"
                *ngIf="
                  environment.SERVER_HOST !== 'https://app.api.batiregistre.fr/'
                "
              >
                <a
                  class="btn btn-primary d-block d-md-inline-block text-white"
                  (click)="sendEmailAlerts()"
                >
                  Envoyer un Email
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="is-loading loader-content" *ngIf="loader"></div>

        <div class="tab-content" *ngIf="!loader && users.length">
          <div
            class="tab-pane fade active show"
            id="tabPaneOne"
            role="tabpanel"
          >
            <div class="row listAlias">
              <div class="col-12 d-flex flex-column">
                <div class="card flex-fill">
                  <div class="card-body">
                    <div class="table-responsive">
                      <table class="table table-striped mb-0">
                        <thead>
                          <tr>
                            <th></th>
                            <th
                              *ngFor="let period of periodUsers"
                              class="text-center"
                            >
                              {{ period.name }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>Tous les utilisateurs</th>
                            <td *ngFor="let period of periodUsers">
                              <div class="d-flex justify-content-center">
                                <i
                                  class="fe fe-check-square fe-white"
                                  *ngIf="
                                    getAllChecked(users, period);
                                    else notCheckedUsers
                                  "
                                  (click)="checkPeriod(users, period, false)"
                                ></i>
                                <ng-template #notCheckedUsers>
                                  <i
                                    class="fe fe-square fe-white"
                                    (click)="checkPeriod(users, period, true)"
                                  ></i>
                                </ng-template>
                              </div>
                            </td>
                          </tr>
                          <tr *ngFor="let user of users">
                            <th>
                              {{ user.name + " - " + user.category }}
                              <span
                                *ngIf="!user.can_receive_alert"
                                class="fe fe-info tooltipHtml ml-1"
                                [title]="
                                  'Attention cet utilisateur n\'est pas configuré pour recevoir des alertes périodiques'
                                "
                              ></span
                              >&nbsp;
                            </th>
                            <td>
                              <div class="d-flex justify-content-center">
                                <i
                                  class="fe fe-white"
                                  (click)="check(user, 'monthly')"
                                  [ngClass]="{
                                    'fe-check-square': user.monthly,
                                    'fe-square': !user.monthly
                                  }"
                                >
                                </i>
                              </div>
                            </td>
                            <td>
                              <div class="d-flex justify-content-center">
                                <i
                                  class="fe fe-white"
                                  (click)="check(user, 'quarterly')"
                                  [ngClass]="{
                                    'fe-check-square': user.quarterly,
                                    'fe-square': !user.quarterly
                                  }"
                                >
                                </i>
                              </div>
                            </td>
                            <td>
                              <div class="d-flex justify-content-center">
                                <i
                                  class="fe fe-white"
                                  (click)="check(user, 'semester')"
                                  [ngClass]="{
                                    'fe-check-square': user.semester,
                                    'fe-square': !user.semester
                                  }"
                                >
                                </i>
                              </div>
                            </td>
                            <td>
                              <div class="d-flex justify-content-center">
                                <i
                                  class="fe fe-white"
                                  (click)="check(user, 'annual')"
                                  [ngClass]="{
                                    'fe-check-square': user.annual,
                                    'fe-square': !user.annual
                                  }"
                                >
                                </i>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<router-outlet></router-outlet>
