import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { AllMessagesModule } from "./all-messages/all-messages.module";
import { UpdateMessageModule } from "./update-message/update-message.module";
import { UserNotificationsModule } from "./user-notifications/user-notifications.module";
import { PeriodicAlertsModule } from "./periodic-alerts/periodic-alerts.module";
import { ReportsModule } from "./reports/reports.module";
import { ObservationsModule } from "./observations/observations.module";
import { ReportInterventionsModule } from "./report-interventions/report-interventions.module";
import { InterventionsModule } from "./interventions/interventions.module";
import { FormationsModule } from "./formations/formations.module";

@NgModule({
  imports: [
    CommonModule,
    UserNotificationsModule,
    AllMessagesModule,
    UpdateMessageModule,
    PeriodicAlertsModule,
    ReportsModule,
    ObservationsModule,
    ReportInterventionsModule,
    InterventionsModule,
    FormationsModule,
  ],
  providers: [],
})
export class NotificationsModule {}
