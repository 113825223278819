import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { BreadcrumbsModule } from "src/app/core/breadcrumbs/breadcrumbs.module";
import { ConfirmModalModule } from "src/app/core/confirm-modal/confirm-modal.module";
import { PaginationModule } from "src/app/core/pagination/pagination.module";
import { SearchbarModule } from "src/app/core/searchbar/searchbar.module";
import { CommissionsModule } from "../../commissions/commissions.module";
import { FormationsEmployeeComponent } from "./formations-employee.component";

@NgModule({
  imports: [
    CommonModule,
    CommissionsModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    ConfirmModalModule,
    BreadcrumbsModule,
    SearchbarModule,
    PaginationModule,
  ],
  declarations: [FormationsEmployeeComponent],
})
export class FormationsEmployeeModule {}
