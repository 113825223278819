import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Subject, Subscription } from 'rxjs';
import * as moment from 'moment';

import { environment } from '../../../../environments/environment';

import { AuthService } from '../../../core/auth/auth.service';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { EnterprisesRequestService } from '../../../services/enterprises.request.service';

import Enterprise from '../../../../shared/interfaces/enterprise';
import Registry from '../../../../shared/interfaces/registry';
import { EstablishmentsRequestService } from '../../../services/establishments.request.service';
import Establishment from '../../../../shared/interfaces/establishment';

@Component({
  selector: 'app-all-provider-registry',
  templateUrl: './all-provider-registry.component.html',
  styleUrls: ['./all-provider-registry.component.scss']
})
export class AllProviderRegistryComponent implements OnInit, AfterViewInit, OnDestroy {
  changeEnterprise: Subscription;
  environment = environment;

  requestInProgress = new Subject();
  requestCount = 0;

  currentDate: moment.Moment;

  canCreate = false;
  canUpdate = false;
  canLog = false;

  activeTabsList = '';

  pageName: string;
  previousPageName: string[] = [];
  previousPageRoute: string[] = [];
  createRoute = '';
  updateRoute = '';
  view = '';

  errors = {};

  enterprises: Enterprise[] = [];
  establishment: Establishment;
  registry: Registry;

  count = 0;
  pages = 1;

  search = '';
  sort = 'Enterprises.name';
  order = 'asc';
  limit = 12;
  page = 1;

  registry_id: number;
  establishment_id: number;

  timeout: any;
  loader = false;

  modalConfirmProvider = null;

  constructor(
    private router: Router,
    private authService: AuthService,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private enterprisesService: EnterprisesRequestService,
    private establishmentsService: EstablishmentsRequestService,
  ) {
    this.currentDate = moment();
  }

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loader = this.requestCount > 0;
    });

    this.changeEnterprise = this.authService.getEnterprise().subscribe(() => {
      this.getEnterprises();
    });

    this.activeTabsList = localStorage.getItem('enterpriseTabsList');
    if (!this.activeTabsList) {
      localStorage.setItem('enterpriseTabsList', JSON.stringify('tabPaneOne'));
      this.activeTabsList = 'tabPaneOne';
    } else {
      this.activeTabsList = JSON.parse(this.activeTabsList);
    }

    this.createRoute = '/' + this.activatedRoute.snapshot.url.join('/') + '/create';
    this.updateRoute = '/' + this.activatedRoute.snapshot.url.join('/') + '/';
    this.pageName = 'Prestataires';

    this.registry_id = this.activatedRoute.snapshot.params.registry_id;
    this.establishment_id = this.activatedRoute.snapshot.params.establishment_id;

    this.getEstablishment();

    if (this.authService.loaded) {
      this.getEnterprises();
    }
  }

  ngAfterViewInit() {
    this.modalConfirmProvider.eventOnClose.subscribe(event => {
      if (event) {
        this.enterprisesService.removeProvider(event).subscribe(response => {
          if (response['success']) {
            CustomNotifierService.getSuccessDelete(this.notifier);
            this.getEnterprises();
          } else if (response['errors']) {
            CustomNotifierService.getErrors(response['errors'], this.notifier);
          }
        });
      }
    });
  }

  ngOnDestroy() {
    this.changeEnterprise.unsubscribe();

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  searchData() {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.page = 1;
      this.refreshData();
    }, 500);
  }

  refreshData(sort = this.sort, order = this.order, page = this.page) {
    this.sort = sort;
    this.order = order;
    this.page = page;
    this.getEnterprises();
  }

  getEstablishment() {
    this.requestInProgress.next(true);
    this.establishmentsService.getMyEstablishment(this.activatedRoute.snapshot.params.establishment_id).subscribe(response => {
      if (response['success']) {
        this.establishment = response['establishment'];
        this.createBreadcrumbs();
      } else if (response['errors']) {
        this.errors = response['errors'];
      }
      this.requestInProgress.next(false);
    });
  }

  getEnterprises() {
    this.requestInProgress.next(true);
    let request;

    request = this.enterprisesService.getProvidersRegistryEstablishment(this.search, this.sort, this.order, this.limit, this.page,
      this.activatedRoute.snapshot.params.registry_id, this.activatedRoute.snapshot.params.establishment_id);

    request.subscribe(response => {
      if (response['success']) {
        this.enterprises = response['enterprises'];
        this.registry = response['registry'];

        this.count = response['count'];
        this.pages = response['pages'];

        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];
        this.canLog = response['canLog'];
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  dispatchPagination(data) {
    if (data.action === 'total_elements') {
      this.refreshPage(data.value);
    } else if (data.action === 'change_page') {
      this.changePage(data.value);
    }
  }

  refreshPage(limit) {
    this.limit = limit;
    this.page = 1;
    this.refreshData();
  }

  filter(property) {
    if (this.sort === property) {
      if (this.order === 'asc') {
        this.order = 'desc';
      } else {
        this.order = 'asc';
      }
    } else {
      this.sort = property;
      this.order = 'asc';
    }

    this.refreshData();
  }

  changePage(page) {
    if (page >= 1 && page <= this.pages) {
      this.page = page;
      this.getEnterprises();
    }
  }

  getAccess(enterprise) {
    if (enterprise.my_enterprises[0].access_to_enterprise) {
      return 'scoring-green';
    } else {
      return 'scoring-red';
    }
  }

  changeActiveTabsList(tabValue: string) {
    localStorage.setItem('enterpriseTabsList', JSON.stringify(tabValue));
    this.activeTabsList = tabValue;
  }

  bindModalConfirmProvider(modal) {
    this.modalConfirmProvider = modal;
  }

  createBreadcrumbs() {
    this.previousPageName = [];
    this.previousPageRoute = [];

    this.previousPageName.push('Tableau de bord');
    this.previousPageRoute.push('/');

    this.previousPageName.push(this.registry ? this.registry.name : 'Tableau de bord registre');
    this.previousPageRoute.push('/dashboard-registry/' + this.activatedRoute.snapshot.params.registry_id);

    this.previousPageName.push(this.establishment ? this.establishment.name : 'Tableau de bord établissement');
    this.previousPageRoute.push('/dashboard-registry/' + this.activatedRoute.snapshot.params.registry_id +
      '/establishment/' + this.activatedRoute.snapshot.params.establishment_id);
  }
}
