<div class="main-content">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col">
        <div class="header mb-0">
          <div class="header-body no-border">
            <div class="row align-items-center">
              <div class="col">
                <h1 class="header-title">Registres</h1>

                <small class="form-text text-muted">
                  <span *ngIf="view === 'admin'">Liste des registres de la plateforme</span>
                  <span *ngIf="view === 'enterprise'">Liste des registres de l'établisement {{ establishment && establishment.name }}</span>
                  <span *ngIf="view === 'establishment'">Liste des registres de mon établisement {{ establishment && establishment.name }}</span>
                  <span *ngIf="view === 'mine'">Liste des registres de mes établissements</span>
                </small>

                <app-breadcrumbs [currentPage]="pageName" [names]="previousPageName" [routes]="previousPageRoute"></app-breadcrumbs>
              </div>

              <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0" *ngIf="canCreate && view === 'admin'">
                <a class="btn btn-primary d-block d-md-inline-block" [routerLink]="['/registries/create']">
                  Ajouter un registre
                </a>
              </div>

              <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0" *ngIf="canReceiveAlert">
                <a class="btn btn-primary d-block d-md-inline-block text-white" (click)="sendPeriodicAlertEmail()">
                  Recevoir les alertes périodiques
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col">
            <app-searchbar [(ngModel)]="search" (keyup)="searchData()" ngDefaultControl></app-searchbar>
          </div>

          <div class="col-auto">
            <button class="btn btn-lg btn-white tooltipHtml" aria-label="Afficher les filtres disponibles"  data-toggle="dropdown" data-placement="bottom" title="Filtre">
              <span class="fe fe-filter"></span>
            </button>

            <span class="dropdown-menu dropdown-menu-right">
              <a class="dropdown-item href" (click)="refreshData('Registries.name', 'asc', 1)" aria-label="Tri des registres : Nom - Ordre croissant">
                Nom A-Z
              </a>

              <a class="dropdown-item href" (click)="refreshData('Registries.name', 'desc', 1)" aria-label="Tri des registres : Nom - Ordre décroissant">
                Nom Z-A
              </a>
            </span>
          </div>
        </div>

        <div class="is-loading loader-content" *ngIf="loader"></div>

        <div class="tab-content" *ngIf="!loader && registries.length">
          <div class="tab-pane fade active show" id="tabPaneOne" role="tabpanel">
            <div class="row listAlias">
              <div class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4 d-flex flex-column" *ngFor="let registry of registries">
                <div class="card flex-fill" [ngStyle]="{ 'background-color' : registry.background_color }">
                  <div class="card-body">
                    <div class="row align-items-center">
                      <div class="col-auto align-self-start">
                        <i class="fe fe-book"></i>
                      </div>

                      <div class="col overflow-hidden" *ngIf="!discoverOffer">
                        <h4 class="card-title name text-truncate mb-0">
                          <a [routerLink]="['/registries/' + registry.id]" *ngIf="view === 'admin'" [attr.aria-label]="'Accès au registre ' + registry.name">
                            {{ registry.name }}
                          </a>

                          <a [routerLink]="['/dashboard-registry/' + registry.id + '/establishment/' + establishment.id]" *ngIf="view === 'establishment'" [attr.aria-label]="'Accès au registre ' + registry.name">
                            {{ registry.name }}
                          </a>

                          <a *ngIf="view === 'enterprise'" [attr.aria-label]="'Accès au registre ' + registry.name">
                            {{ registry.name }}
                          </a>

                          <a [routerLink]="['/dashboard-registry/' + registry.id]" *ngIf="view === 'mine'" [attr.aria-label]="'Accès au registre ' + registry.name">
                            {{ registry.name }}
                          </a>
                        </h4>
                      </div>

                      <div class="col overflow-hidden" *ngIf="discoverOffer && registry.is_discover_offer">
                        <h4 class="card-title name text-truncate mb-0">
                          <a [routerLink]="['/registries/' + registry.id]" *ngIf="view === 'admin'" [attr.aria-label]="'Accès au registre ' + registry.name">
                            {{ registry.name }}
                          </a>

                          <a [routerLink]="['/dashboard-registry/' + registry.id + '/establishment/' + establishment.id]" *ngIf="view === 'establishment'" [attr.aria-label]="'Accès au registre ' + registry.name">
                            {{ registry.name }}
                          </a>

                          <a *ngIf="view === 'enterprise'" [attr.aria-label]="'Accès au registre ' + registry.name">
                            {{ registry.name }}
                          </a>

                          <a [routerLink]="['/dashboard-registry/' + registry.id]" *ngIf="view === 'mine'" [attr.aria-label]="'Accès au registre ' + registry.name">
                            {{ registry.name }}
                          </a>
                        </h4>
                      </div>

                      <div class="col overflow-hidden" *ngIf="discoverOffer && !registry.is_discover_offer">
                        <h4 class="card-title name text-truncate mb-0 href">
                          <a class="tooltipHtml" title="Veuillez souscrire à une offre pour débloquer le contenu" [attr.aria-label]="'Accès au registre ' + registry.name">
                            {{ registry.name }}
                          </a>
                        </h4>
                      </div>

                      <div class="col-auto">
                        <div class="dropdown">
                          <a class="dropdown-ellipses dropdown-toggle href" role="button" [attr.aria-label]="'Sous-menu du registre ' + registry.name" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fe fe-more-vertical"></i>
                          </a>

                          <!-- DROPDOWN ADMIN -->
                          <div class="dropdown-menu dropdown-menu-right" *ngIf="view === 'admin'">
                            <a class="dropdown-item href" [routerLink]="['/registries/' + registry.id]">
                              {{ canUpdate ? 'Éditer le registre' : 'Consulter le registre' }}
                            </a>

                            <a class="dropdown-item href" [routerLink]="[ registry.id + '/checkpoints' ]" *ngIf="registry.checkpoints_module">
                              Consulter les points de contrôle
                            </a>

                            <a class="dropdown-item href" [routerLink]="['/documents/registry/' + registry.id]" *ngIf="registry.documents_module">
                              Consulter les documents
                            </a>

                            <a class="dropdown-item href" [routerLink]="[ registry.id + '/worksheets/file-types' ]" *ngIf="registry.worksheets_module || registry.asbestos_module">
                              Gestion des types de documents pour travaux
                            </a>
                          </div>

                          <!-- DROPDOWN ESTABLISHMENT REGISTRIES -->
                          <div class="dropdown-menu dropdown-menu-right" *ngIf="view === 'establishment' && !discoverOffer">
                            <a class="dropdown-item href" [routerLink]="['/dashboard-registry/' + registry.id + '/establishment/' + establishment.id]">
                              Tableau de bord {{ registry.name }}
                            </a>
                          </div>
                          <div class="dropdown-menu dropdown-menu-right" *ngIf="view === 'establishment' && discoverOffer && registry.is_discover_offer">
                            <a class="dropdown-item href" [routerLink]="['/dashboard-registry/' + registry.id + '/establishment/' + establishment.id]">
                              Tableau de bord {{ registry.name }}
                            </a>
                          </div>
                          <div class="dropdown-menu dropdown-menu-right" *ngIf="view === 'establishment' && discoverOffer && !registry.is_discover_offer">
                            <a class="dropdown-item href">
                              Tableau de bord {{ registry.name }}
                            </a>
                          </div>

                          <!-- DROPDOWN ENTERPRISE REGISTRIES -->
                          <div class="dropdown-menu dropdown-menu-right" *ngIf="view === 'enterprise'">
                          </div>

                          <!-- DROPDOWN MY REGISTRIES -->
                          <div class="dropdown-menu dropdown-menu-right" *ngIf="view === 'mine' && !discoverOffer">
                            <a class="dropdown-item href" [routerLink]="['/dashboard-registry/' + registry.id]">
                              Tableau de bord {{ registry.name }}
                            </a>

                            <a class="dropdown-item href" [routerLink]="['/registries/mine/' + registry.id] + '/establishments'">
                              Liste des établissements
                            </a>
                          </div>
                          <div class="dropdown-menu dropdown-menu-right" *ngIf="view === 'mine' && discoverOffer && registry.is_discover_offer">
                            <a class="dropdown-item href" [routerLink]="['/dashboard-registry/' + registry.id]">
                              Tableau de bord {{ registry.name }}
                            </a>

                            <a class="dropdown-item href" [routerLink]="['/registries/mine/' + registry.id] + '/establishments'">
                              Liste des établissements
                            </a>
                          </div>
                          <div class="dropdown-menu dropdown-menu-right" *ngIf="view === 'mine' && discoverOffer && !registry.is_discover_offer">
                            <a class="dropdown-item href">
                              Tableau de bord {{ registry.name }}
                            </a>

                            <a class="dropdown-item href">
                              Liste des établissements
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row pagination-content" aria-label="Pagination">
            <div class="col-12 d-flex flex-column">
              <div class="card">
                <app-pagination (returnEvent)="dispatchPagination($event)"
                                [classes]="'card-body'"
                                [count_elements]="count"
                                [current_page]="page"
                                [elements_length]="registries.length"
                                [limit]="limit"
                                [total_pages]="pages"
                                ngDefaultControl>
                </app-pagination>
              </div>
            </div>
          </div>
        </div>

        <div class="loader-content" *ngIf="!loader && !registries.length">
          <p>Aucun résultat</p>
        </div>
      </div>
    </div>
  </div>
</div>

<router-outlet></router-outlet>
