import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { HttpClient } from '@angular/common/http';
import { NotifierService } from 'angular-notifier';

@Injectable()
export class AsbestosRequestService extends RequestService {
  constructor(
    protected http: HttpClient,
    protected notifier: NotifierService
  ) {
    super(http, notifier);
  }

  /// GET
  /**
   * ====== ASBESTOS DETECTION ======
   * **/
  public getAllAsbestosDetection(search, sort, order, limit, page, registry_id, establishment_id) {
    return this.get( 'dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/asbestos-detection?' +
      'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getAsbestosDetection(registry_id, establishment_id, asbestos_detection_id) {
    return this.get( 'dashboard-registry/' + registry_id + '/establishment/' + establishment_id +
      '/asbestos-detection/' + asbestos_detection_id);
  }
  public getRegistryAsbestosDetection(registry_id, asbestos_detection_id) {
    return this.get( 'dashboard-registry/' + registry_id + '/asbestos-detection/' + asbestos_detection_id);
  }
  /**
   * ====== ASBESTOS DETECTION FILES ======
   * **/
  public getAsbestosDetectionFiles(search, sort, order, limit, page, registry_id, establishment_id, asbestos_detection_id) {
    return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/asbestos-detection/' +
      asbestos_detection_id + '/files?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getAsbestosDetectionFile(registry_id, establishment_id, asbestos_detection_id, file_id) {
    return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/asbestos-detection/' +
      asbestos_detection_id + '/files/' + file_id);
  }
  /**
   * ====== ASBESTOS DETECTION LOCALIZATIONS ======
   * **/
  public getAllAsbestosDetectionLocalizations(search, sort, order, limit, page, registry_id, establishment_id, asbestos_detection_id) {
    return this.get( 'dashboard/' + registry_id + '/establishment/' + establishment_id + '/asbestos-detection/' +
      asbestos_detection_id + '/localizations' +
      '?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  /**
   * ====== ASBESTOS WORK ======
   * **/
  public getAllAsbestosWork(search, sort, order, limit, page, registry_id, establishment_id) {
    return this.get( 'dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/asbestos-works?' +
      'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getAsbestosWork(registry_id, establishment_id, asbestos_work_id) {
    return this.get( 'dashboard-registry/' + registry_id + '/establishment/' + establishment_id +
      '/asbestos-works/' + asbestos_work_id);
  }
  /**
   * ====== ASBESTOS WORK FILES ======
   * **/
  public getAllAsbestosWorkFilesRequired(search, sort, order, limit, page, asbestos_work_id) {
    return this.get('asbestos-work/' + asbestos_work_id +
      '?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getAsbestosWorkFiles(search, sort, order, limit, page, asbestos_work_id, file_type_id) {
    return this.get('asbestos-work/' + asbestos_work_id + '/file-type/' + file_type_id +  '/files' +
      '?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getAsbestosWorkFile(asbestos_work_id, file_type_id, file_id) {
    return this.get('asbestos-work/' + asbestos_work_id + '/file-type/' + file_type_id + '/file/' + file_id);
  }
  /**
   * ====== ASBESTOS WORK LOCALIZATIONS ======
   * **/
  public getAllAsbestosWorkLocalizations(search, sort, order, limit, page, registry_id, establishment_id, asbestos_work_id) {
    return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/asbestos-work/' +
      asbestos_work_id + '/localizations' +
      '?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  /**
   * ====== ASBESTOS LOCALIZATIONS ======
   * **/
  public getAsbestosLocalizations(search, sort, order, limit, page, registry_id, establishment_id) {
    return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/localizations' +
    '?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  /**
   * ====== ASBESTOS LOCALIZATIONS FILES ======
   * **/
  public getAsbestosLocalizationsFiles(
    search, sort, order, limit, page, registry_id, establishment_id, localization_id) {
    return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/localizations/' +
      localization_id + '/files?' + 'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getAsbestosLocalizationFile(registry_id, establishment_id, localization_id, file_id) {
    return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/localizations/' +
      localization_id + '/files/' + file_id);
  }
  /**
   * ====== ASBESTOS DASHBOARD ======
   * **/
  public getEvaluationAsbestosDashboard(search, sort, order, limit, page, registry_id, establishment_id) {
    if (establishment_id) {
      return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/asbestos-evaluation?' +
        'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
    } else {
      return this.get('dashboard-registry/' + registry_id + '/asbestos-evaluation?' +
        'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
    }
  }
  public getMeasureAsbestosDashboard(search, sort, order, limit, page, registry_id, establishment_id) {
    if (establishment_id) {
      return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/asbestos-measure?' +
        'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
    } else {
      return this.get('dashboard-registry/' + registry_id + '/asbestos-measure?' +
        'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
    }
  }

  /// CREATE
  /**
   * ====== ASBESTOS DETECTION ======
   * **/
  public createAsbestosDetection(registry_id, establishment_id, asbestosDetection) {
    return this.post('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/asbestos-detection',
      { asbestosDetection });
  }
  /**
   * ====== ASBESTOS DETECTION FILE ======
   * **/
  public createAsbestosDetectionFile(registry_id, asbestos_detection_id, file) {
    return this.post('dashboard-registry/' + registry_id + '/asbestos-detection/' + asbestos_detection_id +
      '/files', {file: file});
  }
  public createAsbestosDetectionLocalizationFile(registry_id, asbestos_detection_id, asbestos_localization_id, file) {
    return this.post('dashboard-registry/' + registry_id + '/asbestos-detection/' + asbestos_detection_id +
      '/localization/' + asbestos_localization_id + '/files', {file: file});
  }
  /**
   * ====== ASBESTOS WORK ======
   * **/
  public createAsbestosWork(registry_id, establishment_id, asbestosWork) {
    return this.post('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/asbestos-works',
      { asbestosWork });
  }
  /**
   * ====== ASBESTOS WORK FILE ======
   * **/
  public createAsbestosWorkFile(asbestos_work_id, file_type_id, file) {
    return this.post('asbestos-work/' + asbestos_work_id + '/file-type/' + file_type_id + '/file', { file: file });
  }
  /**
   * ====== ASBESTOS LOCALIZATION FILE ======
   * **/
  public createAsbestosLocalizationFile(registry_id, establishment_id, localization_id, file) {
    return this.post('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/localizations/' +
      localization_id + '/files', { file: file });
  }

  /// UPDATE
  /**
   * ====== ASBESTOS DETECTION ======
   * **/
  public updateAsbestosDetection(registry_id, establishment_id, asbestos_detection_id, asbestosDetection) {
    return this.put('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/asbestos-detection/' +
      asbestos_detection_id, { asbestosDetection });
  }
  public updateRegistryAsbestosDetection(registry_id, asbestos_detection_id, asbestosDetection) {
    return this.put('dashboard-registry/' + registry_id + '/asbestos-detection/' + asbestos_detection_id, { asbestosDetection });
  }
  /**
   * ====== ASBESTOS DETECTION FILE ======
   * **/
  public updateAsbestosDetectionFile(registry_id, establishment_id, asbestos_detection_id, file_id, file) {
    return this.put('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/asbestos-detection/' +
      asbestos_detection_id + '/files/' + file_id, { file: file });
  }
  /**
   * ====== ASBESTOS WORK ======
   * **/
  public updateAsbestosWork(registry_id, establishment_id, asbestos_work_id, asbestosWork) {
    return this.put('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/asbestos-works/' +
      asbestos_work_id, { asbestosWork });
  }
  /**
   * ====== ASBESTOS WORK FILE ======
   * **/
  public updateAsbestosWorkFile(asbestos_work_id, file_type_id, file_id, file) {
    return this.put('asbestos-work/' + asbestos_work_id + '/file-type/' + file_type_id + '/file/' + file_id, { file: file });
  }
  /**
   * ====== ASBESTOS LOCALIZATION FILE ======
   * **/
  public updateAsbestosLocalizationFile(registry_id, establishment_id, localization_id, file_id, file) {
    return this.put('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/localizations/' +
      localization_id + '/files/' + file_id, { file: file });
  }

  /// DELETE
  public deleteAsbestosDetection(registry_id, establishment_id, asbestos_detection_id) {
    return this.delete('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/asbestos-detection/' +
      asbestos_detection_id);
  }
  public deleteAsbestosWork(registry_id, establishment_id, asbestos_work_id) {
    return this.delete('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/asbestos-works/' +
      asbestos_work_id);
  }
  /**
   * ====== ASBESTOS DETECTION FILE ======
   * **/
  public deleteAsbestosDetectionFile(registry_id, establishment_id, asbestos_detection_id, file_id) {
    return this.delete('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/asbestos-detection/' +
      asbestos_detection_id + '/files/' + file_id);
  }
  /**
   * ====== ASBESTOS WORK FILE ======
   * **/
  public deleteAsbestosWorkFile(asbestos_work_id, file_type_id, file_id) {
    return this.delete('asbestos-work/' + asbestos_work_id + '/file-type/' + file_type_id + '/file/' + file_id);
  }
  /**
   * ====== ASBESTOS LOCALIZATION FILE ======
   * **/
  public deleteAsbestosLocalizationFile(registry_id, establishment_id, localization_id, file_id) {
    return this.delete('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/localizations/' +
      localization_id + '/files/' + file_id);
  }

  /// DOWNLOAD
  /**
   * ====== ASBESTOS DETECTION FILE ======
   * **/
  public downloadAsbestosDetectionFile(registry_id, establishment_id, asbestos_detection_id, file_id) {
    return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/asbestos-detection/' +
      asbestos_detection_id + '/files/' + file_id + '/download', { responseType: 'arraybuffer' });
  }
  /**
   * ====== ASBESTOS WORK FILE ======
   * **/
  public downloadAsbestosWorkFile(asbestos_work_id, file_type_id, file_id) {
    return this.get('asbestos-work/' + asbestos_work_id + '/file-type/' + file_type_id + '/file/' + file_id + '/download',
      { responseType: 'arraybuffer' });
  }
  /**
   * ====== ASBESTOS LOCALIZATION FILE ======
   * **/
  public downloadAsbestosLocalizationFile(registry_id, establishment_id, localization_id, file_id) {
    return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/localizations/' +
      localization_id + '/files/' + file_id + '/download', { responseType: 'arraybuffer' });
  }
}
