import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Subject } from 'rxjs';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { UsersRequestService } from '../../../services/users.request.service';

import { AllCotationsComponent } from '../all-cotations/all-cotations.component';
import { CotationsRequestService } from '../../../services/cotations.request.service';

@Component({
  selector: 'app-update-cotation',
  templateUrl: './update-cotation.component.html',
  styleUrls: ['./update-cotation.component.scss']
})
export class UpdateCotationComponent implements OnInit, AfterViewInit, OnDestroy {
  canCreate = false;
  canUpdate = false;

  requestInProgress = new Subject();
  requestCount = 0;

  itemForm: FormGroup;
  id = new FormControl('');
  type = new FormControl('');
  title = new FormControl('', [Validators.required]);
  description = new FormControl('');
  coefficient = new FormControl(0, [Validators.required]);

  isNew = true;
  errors = {};

  view = '';
  viewName = '';
  loader = false;
  loaderContent = false;

  modalCotation = null;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private allCotations: AllCotationsComponent,
    private usersService: UsersRequestService,
    private cotationsService: CotationsRequestService,
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loaderContent = this.requestCount > 0;
    });

    this.itemForm = this.formBuilder.group({
      id: this.id,
      type: this.type,
      title: this.title,
      description: this.description,
      coefficient: this.coefficient
    });

    if (this.activatedRoute.snapshot.parent.url.length === 1) {
      this.view = 'admin';
    } else {
      this.view = 'user';
    }

    if (this.activatedRoute.snapshot.url[0].path === 'frequencies') {
      this.viewName = 'Fréquence';
    } else if (this.activatedRoute.snapshot.url[0].path === 'gravities') {
      this.viewName = 'Gravité';
    } else if (this.activatedRoute.snapshot.url[0].path === 'masters') {
      this.viewName = 'Maitrise';
    }

    if (this.activatedRoute.snapshot.params.cotation_id) {
      this.isNew = false;
      this.getCotation();
    } else {
      if (this.activatedRoute.snapshot.url[0].path === 'frequencies') {
        this.itemForm.patchValue({type: 'frequency'});
      } else if (this.activatedRoute.snapshot.url[0].path === 'gravities') {
        this.itemForm.patchValue({type: 'gravity'});
      } else if (this.activatedRoute.snapshot.url[0].path === 'masters') {
        this.itemForm.patchValue({type: 'master'});
      }
    }
  }

  ngAfterViewInit() {
    this.modalCotation.open();
  }

  ngOnDestroy() {
    this.modalCotation.close();
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  getCotation() {
    this.requestInProgress.next(true);
    let request;
    if (this.view === 'admin') {
      request = this.cotationsService.getAdminCotation(this.activatedRoute.snapshot.params.cotation_id);
    } else {
      request = this.cotationsService.getCotation(this.activatedRoute.snapshot.params.cotation_id);
    }

    request.subscribe(response => {
      if (response['success']) {
        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];

        this.itemForm.patchValue(response['cotation']);
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  createCotation() {
    let request;

    this.errors = {};
    this.loader = true;

    if (this.view === 'admin') {
      request = this.cotationsService.createAdminCotation(this.itemForm.value);
    } else {
      request = this.cotationsService.createCotation(this.itemForm.value);
    }

    request.subscribe(response => {
      if (response['success']) {
        this.loader = false;
        this.modalCotation.close();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response['errors']) {
        this.loader = false;
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
    });
  }

  updateCotation() {
    let request;

    this.errors = {};
    this.loader = true;

    if (this.view === 'admin') {
      request = this.cotationsService.updateAdminCotation(this.id.value, this.itemForm.value);
    } else {
      request = this.cotationsService.updateCotation(this.id.value, this.itemForm.value);
    }

    request.subscribe(response => {
      if (response['success']) {
        this.loader = false;
        this.modalCotation.close();
        CustomNotifierService.getSuccessUpdate(this.notifier);
      } else if (response['errors']) {
        this.loader = false;
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
    });
  }

  bindModal(modal) {
    this.modalCotation = modal;
  }

  closeModal() {
    this.router.navigate([this.activatedRoute.snapshot.parent.url.join('/')]).then(() => {
      this.allCotations.getCotations();
    });
  }
}
