import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { HttpClient } from '@angular/common/http';
import { RequestService } from './request.service';

@Injectable()
export class SanitaryAnalyzeFramesRequestService extends RequestService {
  constructor(
    protected http: HttpClient,
    protected notifier: NotifierService
  ) {
    super(http, notifier);
  }

  /// GET ALL
  public getAllSanitaryAnalyzeFrames(search, sort, order, limit, page, registry_id, establishment_id, sanitaryAnalyzeId) {
    return this.get( 'registry/' + registry_id + '/establishment/' + establishment_id + '/sanitary-surveillance/analyzes/' +
      sanitaryAnalyzeId + '/frames?' + 'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit +
      '&page=' + page);
  }
  /// GET ONE
  public getSanitaryAnalyzeFrame(registry_id, establishment_id, sanitaryAnalyzeId, sanitaryAnalyzeFrameId) {
    return this.get('registry/' + registry_id + '/establishment/' + establishment_id + '/sanitary-surveillance/analyzes/'
      + sanitaryAnalyzeId + '/frame/' + sanitaryAnalyzeFrameId);
  }
  /// CREATE
  public createSanitaryAnalyzeFrame(registry_id, establishment_id, sanitaryAnalyzeId, sanitaryAnalyzeFrame) {
    return this.post('registry/' + registry_id + '/establishment/' + establishment_id + '/sanitary-surveillance/analyzes/'
      + sanitaryAnalyzeId + '/frame/', { sanitaryAnalyzeFrame });
  }
  /// UPDATE
  public updateSanitaryAnalyzeFrame(registry_id, establishment_id, sanitaryAnalyzeId, sanitaryAnalyzeFrameId,
                                       sanitaryAnalyzeFrame) {
    return this.put('registry/' + registry_id + '/establishment/' + establishment_id + '/sanitary-surveillance/analyzes/'
      + sanitaryAnalyzeId + '/frame/' + sanitaryAnalyzeFrameId, { sanitaryAnalyzeFrame });
  }
  /// DELETE
  public deleteSanitaryAnalyzeFrame(registry_id, establishment_id, sanitaryAnalyzeId, sanitaryAnalyzeFrameId) {
    return this.delete('registry/' + registry_id + '/establishment/' + establishment_id + '/sanitary-surveillance/analyzes/'
      + sanitaryAnalyzeId + '/frame/' + sanitaryAnalyzeFrameId);
  }
}
