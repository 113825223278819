<div class="main-content">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col">
        <div class="header mb-0">
          <div class="header-body no-border">
            <div class="row align-items-center">
              <div class="col">
                <h1 class="header-title" *ngIf="!group">
                  {{ archive ? 'Établissements archivés' : 'Établissements actifs' }}
                </h1>
                <h1 class="header-title" *ngIf="group && groupName">
                  {{ groupName.name }}
                </h1>

                <small class="form-text text-muted">
                  <span *ngIf="view === 'enterprise'">
                    {{ archive ? 'Liste des   archivés de l\'entreprise ' + (enterprise ? enterprise.name : '' ) :
                    'Liste des établissements actifs de l\'entreprise ' + (enterprise ? enterprise.name : '' ) }}
                  </span>
                  <span *ngIf="view === 'mine' && !group">{{ archive ? 'Liste des établissements archivés' : 'Liste des établissements actifs' }}</span>
                  <span *ngIf="view === 'registry' && group">Liste des établissements possédant le registre {{ registry ? registry.name : '' }}</span>
                  <span *ngIf="group"> dans le groupe : {{ groupName && groupName.name }}</span>
                </small>

                <app-breadcrumbs [currentPage]="pageName" [names]="previousPageName" [routes]="previousPageRoute"></app-breadcrumbs>
              </div>

              <div class="col-12 col-md-auto mt-md-0 mb-0 mt-2" *ngIf="hasArchive && !archive && !children && !group">
                <a class="btn btn-primary d-block d-md-inline-block" [routerLink]="['/establishments/mine/archive']">
                  Archives
                </a>
              </div>

              <div class="col-12 col-md-auto mt-md-0 mb-0 mt-2" *ngIf="canCreate && !archive && !children && !group">
                <a class="btn btn-primary d-block d-md-inline-block" [routerLink]="['/establishments/mine/active/mass-import']">
                  Import de masse
                </a>
              </div>

              <div class="col-12 col-md-auto mt-md-0 mb-0 mt-2" *ngIf="canCreate && !archive && !children && !group">
                <a class="btn btn-primary d-block d-md-inline-block" [routerLink]="['/establishments/mine/active/mass-edit-registries']">
                  Attribution de masse
                </a>
              </div>

              <div class="col-12 col-md-auto mt-md-0 mb-0 mt-2" *ngIf="canCreate && !archive && !group">
                <a class="btn btn-primary d-block d-md-inline-block" [routerLink]="[currentRoute + 'create']">
                  {{ children ? 'Ajouter un exploitant' : 'Ajouter un établissement' }}
                </a>
              </div>
              <div class="col-12 col-md-auto mt-md-0 mb-0 mt-2" *ngIf="canCreate && !archive && group">
                <a class="btn btn-primary d-block d-md-inline-block" [routerLink]="[currentRoute + 'add-establishment']">
                  Ajouter un établissement
                </a>
              </div>

              <div class="col-12 col-md-auto mt-md-0 mb-0 mt-2" *ngIf="canCreate && !archive && addNewGroup">
                <a class="btn btn-primary d-block d-md-inline-block" [routerLink]="[currentRoute + 'create-group']">
                  Ajouter un groupe
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col">
            <app-searchbar [(ngModel)]="search" (keyup)="searchData()" ngDefaultControl></app-searchbar>
          </div>

          <div class="col-auto">
            <div class="nav btn-group" role="tablist" id="filters-and-display-buttons">
              <button class="btn btn-lg btn-white tooltipHtml" role="tab" aria-label="Affichage mosaïque" aria-labelledby="filters-and-display-buttons" data-placement="bottom" title="Mosaïque" [ngClass]="{ 'active': activeTabsList === 'tabPaneOne'}" (click)="changeActiveTabsList('tabPaneOne')">
                <span class="fe fe-grid"></span>
              </button>

              <button class="btn btn-lg btn-white tooltipHtml" role="tab" aria-label="Affichage liste" aria-labelledby="filters-and-display-buttons" data-placement="bottom" title="Liste" [ngClass]="{ 'active': activeTabsList === 'tabPaneTwo'}" (click)="changeActiveTabsList('tabPaneTwo')">
                <span class="fe fe-list"></span>
              </button>

              <button class="btn btn-lg btn-white tooltipHtml border-right-radius" role="tab" aria-label="Filtres" aria-labelledby="filters-and-display-buttons" data-toggle="dropdown" data-placement="bottom" title="Filtre" [disabled]="activeTabsList === 'tabPaneTwo'">
                <span class="fe fe-filter"></span>
              </button>
              <span class="dropdown-menu dropdown-menu-right">
                <a class="dropdown-item href" (click)="refreshData('Establishments.name', 'asc', 1)" aria-label="Tri des établissements : Nom - Ordre croissant">
                  Nom A-Z
                </a>

                <a class="dropdown-item href" (click)="refreshData('Establishments.name', 'desc', 1)" aria-label="Tri des établissements : Nom - Ordre décroissant">
                  Nom Z-A
                </a>

                <a class="dropdown-item href" (click)="refreshData('Establishments.zipcode', 'asc', 1)" aria-label="Tri des établissements : code postal - Ordre croissant">
                  Code postal A-Z
                </a>

                <a class="dropdown-item href" (click)="refreshData('Establishments.zipcode', 'desc', 1)" aria-label="Tri des établissements : code postal - Ordre décroissant">
                  Code postal Z-A
                </a>

                <a class="dropdown-item href" (click)="refreshData('Establishments.city', 'asc', 1)" aria-label="Tri des établissements : Ville - Ordre croissant">
                  Ville A-Z
                </a>

                <a class="dropdown-item href" (click)="refreshData('Establishments.city', 'desc', 1)" aria-label="Tri des établissements : Ville - Ordre décroissant">
                  Ville Z-A
                </a>
              </span>
            </div>
          </div>
        </div>

        <div class="is-loading loader-content" *ngIf="loader"></div>

        <div *ngIf="!loader && !archive && view !== 'registry' && !children && !group && establishments.length" class="alert" [ngClass]="{ 'alert-primary': total > subscription, 'alert-success': total < subscription, 'alert-warning': total === subscription }" role="alert">
          Nombre d'établissement utilisé par rapport à votre abonnement : {{ total + '/' + subscription }}
        </div>

        <div *ngIf="!loader && archive && view === 'mine' && !children && !group && establishments.length" class="alert alert-primary" role="alert">
          Veuillez contacter le support pour réactiver un établissement
        </div>

        <div class="tab-content" [hidden]="loader || !establishments.length">
          <!-- CARD -->
          <div class="tab-pane fade" id="tabPaneOne" role="tabpanel" [ngClass]="{ 'show active': activeTabsList === 'tabPaneOne'}">
            <div class="row listAlias">

              <!-- GROUP -->
              <ng-container *ngFor="let establishment of establishments">
                <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 d-flex flex-column" *ngIf="establishment.is_group">
                  <div class="card">
                    <div class="card-body">
                      <div class="row align-items-center">
                        <div class="col-auto align-self-start align-icon">
                          <i class="fe fe-folder"></i>
                        </div>

                        <div class="col overflow-hidden">
                          <h4 class="card-title name text-wrap mb-0">
                            <a [routerLink]="['/establishments/mine/group/' + establishment.id]" *ngIf="view === 'mine'">
                              {{ establishment.name }}
                            </a>
                            <a [routerLink]="['/registries/mine/' + registry.id + '/group/' + establishment.id]" *ngIf="view === 'registry'">
                              {{ establishment.name }}
                            </a>
                          </h4>
                        </div>

                        <div class="col-auto align-self-start">
                          <div class="dropdown">
                            <a class="dropdown-ellipses dropdown-toggle href" role="button" [attr.aria-label]="'Sous menu pour l\'établissement ' + establishment.name" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <i class="fe fe-more-vertical"></i>
                            </a>

                            <div class="dropdown-menu dropdown-menu-right">
                              <a class="dropdown-item href" [routerLink]="['/establishments/mine/group/' + establishment.id]">
                                Liste des établissements
                              </a>
                              <a class="dropdown-item href" [routerLink]="[currentRoute + 'update-group/' + establishment.id]">
                                {{ canUpdate ? 'Éditer le groupe' : 'Consulter le groupe' }}
                              </a>
                              <a class="dropdown-item href" (click)="removeFromGroup(establishment.id)" *ngIf="group && canUpdate">
                                Retirer du groupe
                              </a>
                              <a class="dropdown-item href" (click)="deleteGroup(establishment.id)" *ngIf="canDelete">
                                Supprimer le groupe
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
              <!-- ESTABLISHMENTS -->
            <div class="row listAlias">
              <ng-container *ngFor="let establishment of establishments">
                <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 d-flex flex-column" *ngIf="!establishment.is_group">
                  <div class="card flex-fill">
                    <span class="card-img-top card-img-center" [title]="'Photo de l\'établissement : ' + establishment.name" [style.backgroundImage]="'url(' + environment.SERVER_HOST + 'img/establishments/' + establishment.picture + ')'" *ngIf="establishment.picture != '' && establishment.picture != null"></span>

                    <span class="card-img-top card-img-center" [title]="'Photo de l\'établissement : ' + establishment.name" [style.background-image]="'url(../assets/img/establishments/building.png)'" *ngIf="establishment.picture == '' || establishment.picture == null"></span>

                    <div class="card-body">
                      <div class="row align-items-center">
                        <div class="circle tooltipHtml" data-placement="bottom"
                             [title]="establishment.establishment_registries[0]?.last_commission?.visit_date ?
                                     'Date dernière commission : ' + establishment.establishment_registries[0]?.last_commission?.visit_date : ''"
                             [ngClass]="[getScoring(establishment), establishment.establishment_registries[0]?.last_commission?.visit_date ? 'cursor' : '']" *ngIf="!archive">
                        </div>

                        <div class="col overflow-hidden">
                          <h4 class="card-title mb-2 name text-truncate">
                            <a [routerLink]="['/dashboard-registry/' + registry.id + '/establishment/' + establishment.id]" [attr.aria-label]="'Nom de l\'établissement : ' + establishment.name" *ngIf="!archive && view === 'registry'">
                              {{ establishment.name }}
                            </a>

                            <a [routerLink]="['/establishments/mine/active/' + establishment.id + '/registries']" *ngIf="view === 'children'" [attr.aria-label]="'Nom de l\'établissement : ' + establishment.name">
                              {{ establishment.name }}
                            </a>

                            <a [routerLink]="['/establishments/mine/active/' + establishment.id + '/registries']" *ngIf="!archive && view === 'mine'" [attr.aria-label]="'Nom de l\'établissement : ' + establishment.name">
                              {{ establishment.name }}
                            </a>

                            <a [routerLink]="[currentRoute + 'update/' + establishment.id]" *ngIf="archive || view === 'enterprise'" [attr.aria-label]="'Nom de l\'établissement : ' + establishment.name">
                              {{ establishment.name }}
                            </a>
                          </h4>

                          <p class="card-text small text-muted text-truncate mb-0">
                            <span>{{ establishment?.establishment_type.name }}</span>
                            <span *ngIf="establishment?.establishment_type?.name === 'ERP' && establishment?.is_work_code">/ERT</span>
                            <span *ngIf="establishment?.establishment_subcategory?.name">{{ ' - ' + establishment?.establishment_subcategory?.name }}</span>
                          </p>

                          <p class="card-text small text-muted text-truncate mb-0" *ngIf="establishment?.establishment_subtype_establishments.length">
                            <span>Type : </span>
                            <span *ngFor="let subtype of establishment?.establishment_subtype_establishments; let last = last;">
                              {{ subtype.establishment_subtype.name }}{{ last ? '' : ' / ' }}
                            </span>
                          </p>
                        </div>

                        <div class="col-auto">
                          <div class="dropdown">
                            <a class="dropdown-ellipses dropdown-toggle href" role="button" [attr.aria-label]="'Sous menu pour l\'établissement ' + establishment.name" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <i class="fe fe-more-vertical"></i>
                            </a>

                            <div class="dropdown-menu dropdown-menu-right">
                              <a *ngIf="(!archive && view === 'mine') || children" class="dropdown-item href" [routerLink]="['/establishments/mine/active/' + establishment.id + '/registries']">
                                Consulter les registres
                              </a>

                              <a *ngIf="!archive && view === 'mine'" class="dropdown-item href" [routerLink]="['/establishments/mine/active/' + establishment.id + '/files']">
                                Consulter les documents
                              </a>

                              <a *ngIf="!archive && view === 'registry'" class="dropdown-item href" [routerLink]="['/dashboard-registry/' + registry.id + '/establishment/' + establishment.id]">
                                Tableau de bord {{ registry.name }}
                              </a>

                              <a *ngIf="view !== 'registry'" class="dropdown-item href" [routerLink]="[currentRoute + 'update-description/' + establishment.id]">
                                Description de l'établissement
                              </a>

                              <a *ngIf="view !== 'registry'" class="dropdown-item href" [routerLink]="[currentRoute + 'update/' + establishment.id]">
                                {{ canUpdate ? 'Éditer l\'établissement' : 'Consulter l\'établissement' }}
                              </a>

                              <a *ngIf="(view === 'enterprise') || (view === 'mine' && !children && canUpdate && !archive)" class="dropdown-item href" (click)="modalConfirm.open(establishment.id)">
                                {{ archive ? 'Activer l\'établissement' : 'Désactiver l\'établissement' }}
                              </a>

                              <a *ngIf="view === 'mine' && !children && archive && canDelete" class="dropdown-item href" (click)="modalDelete.open(establishment.id)">
                                Supprimer l'établissement
                              </a>

                              <a class="dropdown-item href" [routerLink]="[currentRoute + establishment.id + '/logs']" *ngIf="canLog">
                                Historique des actions
                              </a>

                              <a class="dropdown-item href" (click)="removeFromGroup(establishment.id)" *ngIf="group">
                                Retirer du groupe
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>

            </div>
          </div>

          <!-- TABLE -->
          <div class="tab-pane fade" id="tabPaneTwo" role="tabpanel" [ngClass]="{ 'show active': activeTabsList === 'tabPaneTwo'}">
            <div class="row list">
              <div class="col-12">
                <div class="card">
                  <div class="card-body">
                    <div class="table-responsive">
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th class="href" (click)="filter('Establishments.name')">
                              <a class="text-muted">Désignation de l'établissement</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'Establishments.name' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'Establishments.name' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'Establishments.name'"></span>
                            </th>
                            <th class="href" (click)="filter('Establishments.person_in_charge')">
                              <a class="text-muted">Responsable de l'établissement</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'Establishments.person_in_charge' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'Establishments.person_in_charge' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'Establishments.person_in_charge'"></span>
                            </th>
                            <th class="href" (click)="filter('Establishments.zipcode')">
                              <a class="text-muted">Code postal</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'Establishments.zipcode' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'Establishments.zipcode' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'Establishments.zipcode'"></span>
                            </th>
                            <th class="href" (click)="filter('Establishments.city')">
                              <a class="text-muted">Ville</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'Establishments.city' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'Establishments.city' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'Establishments.city'"></span>
                            </th>
                            <th class="href" (click)="filter('Establishments.effective_public')">
                              <a class="text-muted">Effectif public</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'Establishments.effective_public' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'Establishments.effective_public' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'Establishments.effective_public'"></span>
                            </th>
                            <th>Notation</th>
                            <th class="href" (click)="filter('EstablishmentTypes.name')">
                              <a class="text-muted">Classement</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'EstablishmentTypes.name' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'EstablishmentTypes.name' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'EstablishmentTypes.name'"></span>
                            </th>
                            <th class="href" (click)="filter('EstablishmentSubcategories.name')">
                              <a class="text-muted">Catégorie</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'EstablishmentSubcategories.name' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'EstablishmentSubcategories.name' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'EstablishmentSubcategories.name'"></span>
                            </th>
                            <th class="href" (click)="filter('EstablishmentCategories.name')">
                              <a class="text-muted">Groupe</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'EstablishmentCategories.name' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'EstablishmentCategories.name' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'EstablishmentCategories.name'"></span>
                            </th>
                            <th>Types</th>
                            <th *ngIf="view === 'mine' && !archive">Documents</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="dropdown" *ngFor="let establishment of establishments">
                            <td>
                              <a class="href" [routerLink]="['/establishments/mine/group/' + establishment.id]" *ngIf="establishment.is_group">
                                <i class="fe fe-folder mr-2"></i>
                                {{ establishment.name }}
                              </a>
                              <a class="href" [routerLink]="['/dashboard-registry/' + registry.id + '/establishment/' + establishment.id]" *ngIf="!archive && view === 'registry' && !establishment.is_group">
                                {{ establishment.name }}
                              </a>

                              <a class="href" [routerLink]="['/establishments/mine/active/' + establishment.id + '/registries']" *ngIf="view === 'children' && !establishment.is_group">
                                {{ establishment.name }}
                              </a>

                              <a class="href" [routerLink]="['/establishments/mine/active/' + establishment.id + '/registries']" *ngIf="!archive && view === 'mine' && !establishment.is_group">
                                {{ establishment.name }}
                              </a>

                              <a class="href" [routerLink]="['/establishments/mine/active/' + 'update/' + establishment.id]" *ngIf="archive || view === 'enterprise' && !establishment.is_group">
                                {{ establishment.name }}
                              </a>
                            </td>
                            <td>{{ establishment.person_in_charge }}</td>
                            <td>{{ establishment.zipcode }}</td>
                            <td>{{ establishment.city }}</td>
                            <td>{{ establishment.effective_public }}</td>
                            <td>
                              <div class="circleList tooltipHtml" data-placement="bottom"
                                   [title]="establishment.establishment_registries[0]?.last_commission?.visit_date ?
                                     'Date dernière commission : ' + establishment.establishment_registries[0]?.last_commission?.visit_date : ''"
                                   [ngClass]="[getScoring(establishment), establishment.establishment_registries[0]?.last_commission?.visit_date ? 'cursor' : '']" *ngIf="!archive">
                              </div>
                            </td>
                            <td>{{ establishment?.establishment_type?.name === 'ERP' && establishment?.is_work_code ? establishment?.establishment_type?.name + '/ERT' : establishment?.establishment_type?.name }}</td>
                            <td>{{ establishment?.establishment_subcategory?.name }}</td>
                            <td>{{ establishment?.establishment_category?.name }}</td>
                            <td>
                              <span *ngFor="let subtype of establishment?.establishment_subtype_establishments; let last = last;">
                                {{ subtype.establishment_subtype.name }}{{ last ? '' : ' / ' }}
                              </span>
                            </td>
                            <td *ngIf="view === 'mine' && !archive">
                              <div class="btn-group">
                                <a class="btn btn-sm btn-white" [routerLink]="[currentRoute + establishment.id + '/files']">
                                  {{ establishment.count_documents + ' document(s)'}}
                                </a>
                              </div>
                            </td>
                            <td class="d-flex flex-row-reverse" *ngIf="!establishment.is_group">
                              <a class="dropdown-ellipses dropdown-toggle href" role="button" [attr.aria-label]="'Actions pour l\'établissement ' + establishment.name" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fe fe-more-vertical"></i>
                              </a>

                              <div class="dropdown-menu dropdown-menu-right">
                                <a *ngIf="(!archive && view === 'mine') || children" class="dropdown-item href" [routerLink]="['/establishments/mine/active/' + establishment.id + '/registries']">
                                  Consulter les registres
                                </a>

                                <a *ngIf="!archive && view === 'mine'" class="dropdown-item href" [routerLink]="[currentRoute + establishment.id + '/files']">
                                  Consulter les documents
                                </a>

                                <a *ngIf="!archive && view === 'registry'" class="dropdown-item href" [routerLink]="['/dashboard-registry/' + registry.id + '/establishment/' + establishment.id]">
                                  Tableau de bord {{ registry.name }}
                                </a>

                                <a *ngIf="view !== 'registry'" class="dropdown-item href" [routerLink]="[currentRoute + 'update/' + establishment.id]">
                                  {{ canUpdate ? 'Éditer l\'établissement' : 'Consulter l\'établissement' }}
                                </a>

                                <a *ngIf="view !== 'registry' && !children && canUpdate" class="dropdown-item href" (click)="modalConfirm.open(establishment.id)">
                                  {{ archive ? 'Activer l\'établissement' : 'Désactiver l\'établissement' }}
                                </a>

                                <a *ngIf="view === 'mine' && !children && archive && canDelete" class="dropdown-item href" (click)="modalDelete.open(establishment.id)">
                                  Supprimer l'établissement
                                </a>

                                <a class="dropdown-item href" [routerLink]="[currentRoute + establishment.id + '/logs']" *ngIf="canLog">
                                  Historique des actions
                                </a>

                                <a class="dropdown-item href" (click)="removeFromGroup(establishment.id)" *ngIf="group">
                                  Retirer du groupe
                                </a>
                              </div>
                            </td>
                            <td class="d-flex flex-row-reverse" *ngIf="establishment.is_group">
                              <a class="dropdown-ellipses dropdown-toggle href" role="button" [attr.aria-label]="'Actions pour l\'établissement ' + establishment.name" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fe fe-more-vertical"></i>
                              </a>

                              <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item href" [routerLink]="['/establishments/mine/group/' + establishment.id]">
                                  Liste des établissements
                                </a>
                                <a class="dropdown-item href" [routerLink]="[currentRoute + 'update-group/' + establishment.id]">
                                  {{ canUpdate ? 'Éditer le groupe' : 'Consulter le groupe' }}
                                </a>
                                <a class="dropdown-item href" (click)="removeFromGroup(establishment.id)" *ngIf="group">
                                  Retirer du groupe
                                </a>
                                <a class="dropdown-item href" (click)="deleteGroup(establishment.id)">
                                  Supprimer le groupe
                                </a>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row pagination-content" aria-label="Pagination">
            <div class="col-12 d-flex flex-column">
              <div class="card">
                <app-pagination (returnEvent)="dispatchPagination($event)"
                                [classes]="'card-body'"
                                [count_elements]="count"
                                [current_page]="page"
                                [elements_length]="establishments.length"
                                [limit]="limit"
                                [total_pages]="pages"
                                ngDefaultControl>
                </app-pagination>
              </div>
            </div>
          </div>

          <!-- GEOLOCATION -->
          <div class="tab-pane show active" id="tabPaneThree" role="tabpanel" *ngIf="view === 'mine' && markers && markers.length && bounds">
            <div class="row listAlias">
              <div class="col-12 d-flex flex-column">
                <div class="card flex-fill">
                  <div class="card-body">
                    <div class="row mb-4">
                      <div class="col-md-6">
                        <h3 class="header-title"> Localisation de mes établissements</h3>
                      </div>
                    </div>

                    <agm-map [zoom]="3"
                             [minZoom]="2"
                             [maxZoom]="18"
                             [fitBounds]="bounds"
                             [mapTypeControl]="false"
                             [fullscreenControl]="false"
                             [streetViewControl]="false"
                             [scrollwheel]="null"
                             [gestureHandling]="'cooperative'"
                             *ngIf="!loader">
                      <agm-marker *ngFor="let marker of markers" [latitude]="marker.latitude" [longitude]="marker.longitude" (markerClick)="openWindow(marker.id)">
                        <agm-info-window [isOpen]="isInfoWindowOpen(marker.id)">
                          {{ marker.name }} <br>
                          <span class="tooltip-address">
                            {{ marker.address }} <br>
                            {{ marker.zipcode + ' ' + marker.city }}
                          </span>
                        </agm-info-window>
                      </agm-marker>
                    </agm-map>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="loader-content" *ngIf="!loader && !establishments.length">
          <p>Aucun résultat</p>
        </div>
      </div>
    </div>
  </div>
</div>

<app-confirm-modal [type]="archive ? 'activation' : 'deactivation'" #confirm>{{ bindModal(confirm) }}</app-confirm-modal>
<app-confirm-modal [type]="'delete_establishment'" #deleteModal>{{ bindDeleteModal(deleteModal) }}</app-confirm-modal>

<router-outlet></router-outlet>
