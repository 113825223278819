import { Component, Output, ElementRef, EventEmitter, AfterViewInit, Input, ViewChild } from '@angular/core';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';

declare var $: any;

@Component({
  selector: 'app-modal-right',
  templateUrl: './modal-right.component.html',
  styleUrls: ['./modal-right.component.scss']
})
export class ModalRightComponent implements AfterViewInit {
  @ViewChild('confirm') public modalConfirm: ConfirmModalComponent;
  @Output() eventOnClose: EventEmitter<any> = new EventEmitter();
  @Input() checkEditClose: boolean;

  @Input() width = '50%';

  modalEl = null;

  form = null;
  inputChange = null;
  formChange = false;

  constructor(
    private _rootNode: ElementRef
  ) {}

  open() {
    this.modalEl.modal('show');
  }

  close() {
    this.modalEl.modal('hide');
    this.eventOnClose.next(null);
  }

  ngAfterViewInit() {

    const self = this;
    const modal = document.getElementById('modal-right');

    this.modalEl = $(this._rootNode.nativeElement).find('div.modal-right');

    // DETECT IF FORM CHANGED
    const interval = setInterval(() => {
      this.form = document.getElementsByTagName('form');

      if (this.form.length) {
        clearInterval(interval);
        this.form[0].addEventListener('input', function (e) {
          self.inputChange = e;
          self.formChange = true;
        });
      }
    }, 250);

    // ESCAPE TOUCH
    $('#modal-right').keyup(function(event) {
      if (event.key === 'Escape') {
        self.checkEdit();
      }
    });

    // CLICK
    document.onclick = function (event) {
      if (event.target === modal) {
        self.checkEdit();
      }
    };

    this.modalConfirm.eventOnClose.subscribe(event => {
      if (event) {
        this.modalEl.modal('hide');
        this.eventOnClose.next(null);
      } else {
        if (this.inputChange && this.inputChange.srcElement) {
          setTimeout(() => {
            this.inputChange.srcElement.focus();
          }, 150);
        }
      }
    });
  }

  checkEdit() {
    if (this.formChange && this.checkEditClose !== false) {
      this.modalConfirm.open('0');
    } else {
      this.modalEl.modal('hide');
      this.eventOnClose.next(null);
    }
  }
}
