import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { NotifierService } from 'angular-notifier';
import { StatisticsRequestService } from '../../../services/statistics.request.service';
import { Subject, Subscription } from 'rxjs';
import { AuthService } from '../../../core/auth/auth.service';

import * as moment from 'moment';

declare var $: any;

declare function UserCharts(): any;
declare function Flatpickr(): any;

@Component({
  selector: 'app-dashboard-commissions',
  templateUrl: './dashboard-commissions.component.html',
  styleUrls: ['./dashboard-commissions.component.scss']
})
export class DashboardCommissionsComponent implements OnInit, OnDestroy {
  changeEnterprise: Subscription;
  establishments = [];
  selectedEstablishments = null;
  registries = [];
  selectedRegistry = null;
  userChart = UserCharts();
  installations;
  installationCustoms = [];
  checkpoints;
  checkpointCustoms = [];
  periods = [
    { value: 'custom', name: 'Période personnalisée' },
    { value: 'days', name: '7 derniers jours' },
    { value: 'month', name: '30 derniers jours' },
    { value: 'months', name: '3 derniers mois' },
    { value: 'year', name: 'Année en cours' },
    { value: 'lastyear', name: 'Année dernière' },
    { value: 'lastyears', name: 'Il y a deux ans' }
  ];
  period = 'custom';
  dateFrom;
  dateTo;
  groupBy = 'month';
  // PRESCRIPTIONS
  totalPrescriptions = 0;
  totalPrescriptionsL = 0;
  totalPrescriptionsNL = 0;
  // EMERGENCIES
  totalPrescriptionsEmergencies = 0;
  totalPrescriptionsEmergencyN = 0;
  totalPrescriptionsEmergency0 = 0;
  totalPrescriptionsEmergency1 = 0;
  totalPrescriptionsEmergency2 = 0;
  totalPrescriptionsEmergency3 = 0;
  // DELAY
  totalPrescriptionsDelay = 0;
  totalPrescriptionsDelayN = 0;
  totalPrescriptionsAverageDelay = 0;

  // ALL PRESCRIPTIONS
  allPrescriptions = [];
  allPrescriptionsLimit = 12;
  allPrescriptionsCount = 0;
  allPrescriptionsSort = '';
  allPrescriptionsOrder = '';
  allPrescriptionsPage = 1;
  allPrescriptionsPages = 1;

  // ALL PRESCRIPTIONS STATE
  prescriptionsState = [];
  prescriptionsStateLimit = 12;
  prescriptionsStateCount = 0;
  prescriptionsStateSort = '';
  prescriptionsStateOrder = '';
  prescriptionsStatePage = 1;
  prescriptionsStatePages = 1;

  // ALL PRESCRIPTIONS NOT STATE
  prescriptionsNotState = [];
  prescriptionsNotStateLimit = 12;
  prescriptionsNotStateCount = 0;
  prescriptionsNotStateSort = '';
  prescriptionsNotStateOrder = '';
  prescriptionsNotStatePage = 1;
  prescriptionsNotStatePages = 1;

  // EMERGENCIES BLOC
  prescriptionsNC = [];
  prescriptionsNCLimit = 12;
  prescriptionsNCCount = 0;
  prescriptionsNCSort = '';
  prescriptionsNCOrder = '';
  prescriptionsNCPage = 1;
  prescriptionsNCPages = 1;

  prescriptionsLow = [];
  prescriptionsLowLimit = 12;
  prescriptionsLowCount = 0;
  prescriptionsLowSort = '';
  prescriptionsLowOrder = '';
  prescriptionsLowPage = 1;
  prescriptionsLowPages = 1;

  prescriptionsNormal = [];
  prescriptionsNormalLimit = 12;
  prescriptionsNormalCount = 0;
  prescriptionsNormalSort = '';
  prescriptionsNormalOrder = '';
  prescriptionsNormalPage = 1;
  prescriptionsNormalPages = 1;

  prescriptionsHigher = [];
  prescriptionsHigherLimit = 12;
  prescriptionsHigherCount = 0;
  prescriptionsHigherSort = '';
  prescriptionsHigherOrder = '';
  prescriptionsHigherPage = 1;
  prescriptionsHigherPages = 1;

  prescriptionsPriority = [];
  prescriptionsPriorityLimit = 12;
  prescriptionsPriorityCount = 0;
  prescriptionsPrioritySort = '';
  prescriptionsPriorityOrder = '';
  prescriptionsPriorityPage = 1;
  prescriptionsPriorityPages = 1;

  // DELAY PRESCRIPTIONS
  prescriptionsLate = [];
  prescriptionsLateLimit = 12;
  prescriptionsLateCount = 0;
  prescriptionsLateSort = '';
  prescriptionsLateOrder = '';
  prescriptionsLatePage = 1;
  prescriptionsLatePages = 1;

  prescriptionsInTime = [];
  prescriptionsInTimeLimit = 12;
  prescriptionsInTimeCount = 0;
  prescriptionsInTimeSort = '';
  prescriptionsInTimeOrder = '';
  prescriptionsInTimePage = 1;
  prescriptionsInTimePages = 1;

  // ------------------- COMPARATIVE ESTABLISHMENTS -------------------
  selectedEstablishmentA = null;
  selectedEstablishmentB = null;
  totalPrescriptionsA = 0;
  totalPrescriptionsB = 0;
  prescriptionsALate = 0;
  prescriptionsBLate = 0;

  // ------------------ AVERAGE DAY + AVERAGE COSTS -------------------
  // DAY
  dayLimit = 6;
  dayPage = 1;
  dayFilter1 = [];
  dayFilter1Count = 0;
  dayFilter1Limit = 12;
  dayFilter1Sort = '';
  dayFilter1Order = '';
  dayFilter1Page = 1;
  dayFilter1Pages = 1;
  dayFilter2 = [];
  dayFilter2Count = 0;
  dayFilter2Sort = '';
  dayFilter2Order = '';
  dayFilter2Limit = 12;
  dayFilter2Page = 1;
  dayFilter2Pages = 1;
  dayFilter3 = [];
  dayFilter3Count = 0;
  dayFilter3Limit = 12;
  dayFilter3Sort = '';
  dayFilter3Order = '';
  dayFilter3Page = 1;
  dayFilter3Pages = 1;
  dayFilter4 = [];
  dayFilter4Count = 0;
  dayFilter4Limit = 12;
  dayFilter4Sort = '';
  dayFilter4Order = '';
  dayFilter4Page = 1;
  dayFilter4Pages = 1;

  // COSTS
  costsLimit = 12;
  costsPage = 1;
  costFilter1 = [];
  costFilter1Count = 0;
  costFilter1Limit = 12;
  costFilter1Sort = '';
  costFilter1Order = '';
  costFilter1Page = 1;
  costFilter1Pages = 1;
  costFilter2 = [];
  costFilter2Count = 0;
  costFilter2Limit = 12;
  costFilter2Sort = '';
  costFilter2Order = '';
  costFilter2Page = 1;
  costFilter2Pages = 1;
  costFilter3 = [];
  costFilter3Count = 0;
  costFilter3Limit = 12;
  costFilter3Sort = '';
  costFilter3Order = '';
  costFilter3Page = 1;
  costFilter3Pages = 1;

  requestInProgress = new Subject();
  requestCount = 0;
  loader = false;

  modal;

  loaderPrescChart = false;
  loaderEmergenciesPrescriptionsChart = false;
  loaderDelayPrescriptionsChart = false;
  loaderPrescriptionsComparative = false;
  loaderPrescriptionsAverageDays = false;
  loaderPrescriptionsAverageCosts = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private notifier: NotifierService,
    private statisticsService: StatisticsRequestService,
    private _rootNode: ElementRef
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loader = this.requestCount > 0;
    });

    this.changeEnterprise = this.authService.getEnterprise().subscribe(() => {
      this.initUserCharts();
      Flatpickr();
    });

    if (this.authService.loaded) {
      this.initUserCharts();
      setTimeout(function () {
        Flatpickr();
      }, 100);
    }

    // Remove background opacity bug if modal closed too fast
    const modalBackdrop = document.getElementsByClassName('modal-backdrop fade show') as HTMLCollection;
    for (let i = 0; i < modalBackdrop.length; i++) {
      modalBackdrop[i].remove();
    }
    const body = document.getElementsByTagName('body');
    for (let i = 0; i < body.length; i++) {
      body[i].className = body[i].className.replace('modal-open', '');
    }
  }

  ngOnDestroy() {
    this.changeEnterprise.unsubscribe();

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }

    if (this.modal) {
      this.modal.modal('hide');
      this.selectedEstablishments = [];
    }
  }

  initUserCharts() {
    this.statisticsService.getEstablishmentsNumber('true', '').subscribe(response => {
      if (response['success']) {
        this.establishments = response['establishments'];
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
    });
    this.statisticsService.getRegistries([], '', 'all_commission_registries').subscribe(response => {
      if (response['success']) {
        this.registries = response['registries'];
        this.installationCustoms = response['installation_customs'];
        this.checkpointCustoms = response['checkpoint_customs'];
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
    });
    this.statisticsService.getPrescriptionsStats('month', '', '').subscribe(response => {
      if (response['success']) {
        this.userChart.createPrescriptionsChart('#headerChart', response, 'month');
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
    });

    this.getPrescriptionsStatsCosts();
    this.getPrescriptionsStatsDay();
    this.getPrescriptionBlock();
    this.getEmergenciesPrescriptions();
    this.getDelayPrescriptions();
  }

  updatePrescriptionsChart(groupBy = this.groupBy) {
    this.groupBy = groupBy;
    this.statisticsService.getPrescriptionsStats(groupBy, this.dateFrom, this.dateTo)
      .subscribe(response => {
        if (response['success']) {
          this.userChart.createPrescriptionsChart('#headerChart', response, groupBy);
        } else if (response['errors']) {
          CustomNotifierService.getErrors(response['errors'], this.notifier);
        }
      });
  }

  updateSelectedEstablishments() {
    this.getPrescriptionBlock();
    this.getEmergenciesPrescriptions();
    this.getDelayPrescriptions();
    this.getPrescriptionsStatsCosts();
    this.getPrescriptionsStatsDay();
  }

  updatePeriod(event = null) {
    let update = false;
    if (this.period === 'days') {
      this.dateFrom = moment().subtract(7, 'day').format('YYYY-MM-DD');
      this.dateTo = moment().format('YYYY-MM-DD');
      update = true;
    } else if (this.period === 'month') {
      this.dateFrom = moment().subtract(1, 'month').format('YYYY-MM-DD');
      this.dateTo = moment().format('YYYY-MM-DD');
      update = true;
    } else if (this.period === 'months') {
      this.dateFrom = moment().subtract(3, 'month').format('YYYY-MM-DD');
      this.dateTo = moment().format('YYYY-MM-DD');
      update = true;
    } else if (this.period === 'year') {
      this.dateFrom = moment().startOf('year').format('YYYY-MM-DD');
      this.dateTo = moment().endOf('year').format('YYYY-MM-DD');
      update = true;
    } else if (this.period === 'lastyear') {
      this.dateFrom = moment().startOf('year').subtract(1, 'year').format('YYYY-MM-DD');
      this.dateTo = moment().endOf('year').subtract(1, 'year').format('YYYY-MM-DD');
      update = true;
    } else if (this.period === 'lastyears') {
      this.dateFrom = moment().startOf('year').subtract(2, 'year').format('YYYY-MM-DD');
      this.dateTo = moment().endOf('year').subtract(2, 'year').format('YYYY-MM-DD');
      update = true;
    }
    if (event === null) {
      this.dateFrom = null;
      this.dateTo = null;
      update = true;
    }
    if (update) {
      this.getPrescriptionBlock();
      this.updatePrescriptionsChart();
      this.getEmergenciesPrescriptions();
      this.getDelayPrescriptions();
      this.getPrescriptionsStatsCosts();
      this.getPrescriptionsStatsDay();
    }
  }

  updateSelectedRegistry() {
    this.getPrescriptionsStatsCosts();
    this.getPrescriptionsStatsDay();
    this.getPrescriptionBlock();
    this.getEmergenciesPrescriptions();
    this.getDelayPrescriptions();
  }

  updateDate() {
    this.period = 'custom';
    this.getPrescriptionBlock();
    this.updatePrescriptionsChart();
    this.getEmergenciesPrescriptions();
    this.getDelayPrescriptions();
    this.getPrescriptionsStatsCosts();
    this.getPrescriptionsStatsDay();
  }

  getPrescriptionBlock() {
    this.loaderPrescChart = true;
    this.statisticsService.prescriptionsStat(this.dateFrom, this.dateTo, this.selectedEstablishments,
      this.selectedRegistry, '', '', '', '').subscribe(response => {
      this.totalPrescriptions = 0;
      this.totalPrescriptionsL = 0;
      this.totalPrescriptionsNL = 0;
      if (response['success']) {
        this.totalPrescriptions = response['allPrescriptionsCount'];
        this.totalPrescriptionsL = response['prescriptionsStateCount'];
        this.totalPrescriptionsNL = response['prescriptionsNotStateCount'];

        this.userChart.createPrescChart(
          '#prescChart', response, this.totalPrescriptions, this.totalPrescriptionsL, this.totalPrescriptionsNL, this);
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loaderPrescChart = false;
    });
  }

  getEmergenciesPrescriptions() {
    this.loaderEmergenciesPrescriptionsChart = true;
    this.statisticsService.getEmergenciesPrescriptions(this.dateFrom, this.dateTo, this.selectedEstablishments,
      this.selectedRegistry, '', '', '', '')
      .subscribe(response => {
      if (response['success']) {
        this.totalPrescriptionsEmergencies = 0;
        this.totalPrescriptionsEmergencyN = 0;
        this.totalPrescriptionsEmergency0 = 0;
        this.totalPrescriptionsEmergency1 = 0;
        this.totalPrescriptionsEmergency2 = 0;
        this.totalPrescriptionsEmergency3 = 0;
        this.totalPrescriptionsEmergencyN = response['prescriptionsNCCount'];
        this.totalPrescriptionsEmergency0 = response['prescriptionsLowCount'];
        this.totalPrescriptionsEmergency1 = response['prescriptionsNormalCount'];
        this.totalPrescriptionsEmergency2 = response['prescriptionsHigherCount'];
        this.totalPrescriptionsEmergency3 = response['prescriptionsPriorityCount'];
        this.totalPrescriptionsEmergencies = this.totalPrescriptionsEmergencyN + this.totalPrescriptionsEmergency0 +
          this.totalPrescriptionsEmergency1 + this.totalPrescriptionsEmergency2 + this.totalPrescriptionsEmergency3;
        this.userChart.createPrescriptionsEmergenciesChart(
          '#emergenciesPrescriptionsChart', response, this.totalPrescriptionsEmergencies, this);
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
        this.loaderEmergenciesPrescriptionsChart = false;
    });
  }

  getDelayPrescriptions() {
    this.loaderDelayPrescriptionsChart = true;
    this.statisticsService.getDelayPrescriptions(this.dateFrom, this.dateTo, this.selectedEstablishments,
      this.selectedRegistry, '', '', '', ''
    ).subscribe(response => {
      if (!response['success']) {
        if (response['errors']) {
          CustomNotifierService.getErrors(response['errors'], this.notifier);
        }
      } else {
        let total = 0;
        let days = 0;
        if (response['prescriptionsLate']) {
          response['prescriptionsLate'].forEach(prescription => {
            days += parseInt(prescription['day'], 10);
            total++;
          });
        }
        if (response['prescriptionsInTime']) {
          response['prescriptionsInTime'].forEach(prescription => {
            days += parseInt(prescription['day'], 10);
            total++;
          });
        }
        if (total > 0) {
          if (days < 0) {
            days = 0;
          }
          this.totalPrescriptionsAverageDelay = Math.round(days / total);
        } else {
          this.totalPrescriptionsAverageDelay = 0;
        }


        this.totalPrescriptionsDelay = 0;
        this.totalPrescriptionsDelayN = 0;
        this.totalPrescriptionsDelay = parseInt(response['prescriptionsLateCount'], 10);
        this.totalPrescriptionsDelayN = parseInt(response['prescriptionsInTimeCount'], 10);
        this.userChart.createPrescriptionsDelayChart(
          '#delayPrescriptionsChart', this.totalPrescriptionsDelay, this.totalPrescriptionsDelayN, this);
      }
      this.loaderDelayPrescriptionsChart = false;
    });
  }

  // -------------------- OPEN MODAL FOR CLICK ON STATS --------------------
  open(content) {
    if (content === 'allPrescriptions') {
      this.allPrescriptionsPage = 1;
      this.getAllPrescriptions(content);
    } else if (content === 'prescriptionsState') {
      this.prescriptionsStatePage = 1;
      this.getPrescriptionsState(content);
    } else if (content === 'prescriptionsNotState') {
      this.prescriptionsNotStatePage = 1;
      this.getPrescriptionsNotState(content);
    } else if (content === 'prescriptionsLate') {
      this.prescriptionsLatePage = 1;
      this.getPrescriptionsLate(content);
    } else if (content === 'prescriptionsInTime') {
      this.prescriptionsInTimePage = 1;
      this.getPrescriptionsInTime(content);
    }
  }
  // -------------------- GET ALL PRESCRIPTION + PAGINATION --------------------
  filterAllPrescriptions(property) {
    if (this.allPrescriptionsSort === property) {
      if (this.allPrescriptionsOrder === 'asc') {
        this.allPrescriptionsOrder = 'desc';
      } else {
        this.allPrescriptionsOrder = 'asc';
      }
    } else {
      this.allPrescriptionsSort = property;
      this.allPrescriptionsOrder = 'asc';
    }

    this.getAllPrescriptions('allPrescriptions');
  }
  getAllPrescriptions(content) {
    this.requestInProgress.next(true);
    this.statisticsService.prescriptionsStat(this.dateFrom, this.dateTo, this.selectedEstablishments, this.selectedRegistry,
      this.allPrescriptionsSort, this.allPrescriptionsOrder, this.allPrescriptionsLimit, this.allPrescriptionsPage, 'all')
      .subscribe(response => {
      if (response['success']) {
        this.allPrescriptions = response['allPrescriptions'];
        this.allPrescriptionsCount = response['allPrescriptionsCount'];
        this.allPrescriptionsPages = response['allPrescriptionsPages'];
        this.modal = $(this._rootNode.nativeElement).find('div#' + content);
        this.modal.modal('show');
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }
  dispatchPaginationAll(data) {
    if (data.action === 'total_elements') {
      this.refreshPageAll(data.value);
    } else if (data.action === 'change_page') {
      this.changePageAll(data.value);
    }
  }
  refreshPageAll(limit) {
    this.allPrescriptionsLimit = limit;
    this.allPrescriptionsPage = 1;
    this.getAllPrescriptions('allPrescriptions');
  }
  changePageAll(page) {
    if (page >= 1 && page <= this.allPrescriptionsPages) {
      this.allPrescriptionsPage = page;
      this.getAllPrescriptions('allPrescriptions');
    }
  }

  // -------------------- GET ALL PRESCRIPTION STATE + PAGINATION --------------------
  filterPrescriptionsState(property) {
    if (this.prescriptionsStateSort === property) {
      if (this.prescriptionsStateOrder === 'asc') {
        this.prescriptionsStateOrder = 'desc';
      } else {
        this.prescriptionsStateOrder = 'asc';
      }
    } else {
      this.prescriptionsStateSort = property;
      this.prescriptionsStateOrder = 'asc';
    }

    this.getPrescriptionsState('prescriptionState');
  }
  getPrescriptionsState(content) {
    this.requestInProgress.next(true);
    this.statisticsService.prescriptionsStat(this.dateFrom, this.dateTo, this.selectedEstablishments, this.selectedRegistry,
      this.prescriptionsStateSort, this.prescriptionsStateOrder, this.prescriptionsStateLimit, this.prescriptionsStatePage, 'state')
      .subscribe(response => {
      if (response['success']) {
        this.prescriptionsState = response['prescriptionsState'];
        this.prescriptionsStateCount = response['prescriptionsStateCount'];
        this.prescriptionsStatePages = response['prescriptionsStatePages'];
        this.modal = $(this._rootNode.nativeElement).find('div#' + content);
        this.modal.modal('show');
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }
  dispatchPaginationState(data) {
    if (data.action === 'total_elements') {
      this.refreshPageState(data.value);
    } else if (data.action === 'change_page') {
      this.changePageState(data.value);
    }
  }
  refreshPageState(limit) {
    this.prescriptionsStateLimit = limit;
    this.prescriptionsStatePage = 1;
    this.getPrescriptionsState('prescriptionState');
  }
  changePageState(page) {
    if (page >= 1 && page <= this.prescriptionsStatePages) {
      this.prescriptionsStatePage = page;
      this.getPrescriptionsState('prescriptionsState');
    }
  }

  // -------------------- GET ALL PRESCRIPTION NOT STATE + PAGINATION --------------------
  filterPrescriptionsNotState(property) {
    if (this.prescriptionsNotStateSort === property) {
      if (this.prescriptionsNotStateOrder === 'asc') {
        this.prescriptionsNotStateOrder = 'desc';
      } else {
        this.prescriptionsNotStateOrder = 'asc';
      }
    } else {
      this.prescriptionsNotStateSort = property;
      this.prescriptionsNotStateOrder = 'asc';
    }

    this.getPrescriptionsNotState('prescriptionsNotState');
  }
  getPrescriptionsNotState(content) {
    this.requestInProgress.next(true);
    this.statisticsService.prescriptionsStat(this.dateFrom, this.dateTo, this.selectedEstablishments, this.selectedRegistry,
      this.prescriptionsNotStateSort, this.prescriptionsNotStateOrder, this.prescriptionsNotStateLimit, this.prescriptionsNotStatePage,
      'notState').subscribe(response => {
      if (response['success']) {
        this.prescriptionsNotState = response['prescriptionsNotState'];
        this.prescriptionsNotStateCount = response['prescriptionsNotStateCount'];
        this.prescriptionsNotStatePages = response['prescriptionsNotStatePages'];
        this.modal = $(this._rootNode.nativeElement).find('div#' + content);
        this.modal.modal('show');
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }
  dispatchPaginationNotState(data) {
    if (data.action === 'total_elements') {
      this.refreshPageNotState(data.value);
    } else if (data.action === 'change_page') {
      this.changePageNotState(data.value);
    }
  }
  refreshPageNotState(limit) {
    this.prescriptionsNotStateLimit = limit;
    this.prescriptionsNotStatePage = 1;
    this.getPrescriptionsNotState('prescriptionsNotState');
  }
  changePageNotState(page) {
    if (page >= 1 && page <= this.prescriptionsNotStatePages) {
      this.prescriptionsNotStatePage = page;
      this.getPrescriptionsNotState('prescriptionsNotState');
    }
  }

  // -------------------- GET ALL EMERGENCY PRESCRIPTION NC NOT STATE + PAGINATION --------------------
  filterPrescriptionsNC(property) {
    if (this.prescriptionsNCSort === property) {
      if (this.prescriptionsNCOrder === 'asc') {
        this.prescriptionsNCOrder = 'desc';
      } else {
        this.prescriptionsNCOrder = 'asc';
      }
    } else {
      this.prescriptionsNCSort = property;
      this.prescriptionsNCOrder = 'asc';
    }

    this.getPrescriptionsNC('prescriptionsNC');
  }
  getPrescriptionsNC(content) {
    this.requestInProgress.next(true);
    this.statisticsService.getEmergenciesPrescriptions(this.dateFrom, this.dateTo, this.selectedEstablishments, this.selectedRegistry,
      this.prescriptionsNCSort, this.prescriptionsNCOrder, this.prescriptionsNCLimit, this.prescriptionsNCPage, 'NC')
      .subscribe(response => {
        if (response['success']) {
          this.prescriptionsNC = response['prescriptionsNC'];
          this.prescriptionsNCCount = response['prescriptionsNCCount'];
          this.prescriptionsNCPages = response['prescriptionsNCPages'];
          this.modal = $(this._rootNode.nativeElement).find('div#' + content);
          this.modal.modal('show');
        } else if (response['errors']) {
          CustomNotifierService.getErrors(response['errors'], this.notifier);
        }
        this.requestInProgress.next(false);
      });
  }
  dispatchPaginationNC(data) {
    if (data.action === 'total_elements') {
      this.refreshPageNC(data.value);
    } else if (data.action === 'change_page') {
      this.changePageNC(data.value);
    }
  }
  refreshPageNC(limit) {
    this.prescriptionsNCLimit = limit;
    this.prescriptionsNCPage = 1;
    this.getPrescriptionsNC('prescriptionsNC');
  }
  changePageNC(page) {
    if (page >= 1 && page <= this.prescriptionsNCPages) {
      this.prescriptionsNCPage = page;
      this.getPrescriptionsNC('prescriptionsNC');
    }
  }

  // -------------------- GET ALL EMERGENCY PRESCRIPTION LOW NOT STATE + PAGINATION --------------------
  filterPrescriptionsLow(property) {
    if (this.prescriptionsLowSort === property) {
      if (this.prescriptionsLowOrder === 'asc') {
        this.prescriptionsLowOrder = 'desc';
      } else {
        this.prescriptionsLowOrder = 'asc';
      }
    } else {
      this.prescriptionsLowSort = property;
      this.prescriptionsLowOrder = 'asc';
    }

    this.getPrescriptionsLow('prescriptionsLow');
  }
  getPrescriptionsLow(content) {
    this.requestInProgress.next(true);
    this.statisticsService.getEmergenciesPrescriptions(this.dateFrom, this.dateTo, this.selectedEstablishments, this.selectedRegistry,
      this.prescriptionsLowSort, this.prescriptionsLowOrder, this.prescriptionsLowLimit, this.prescriptionsLowPage, 'low')
      .subscribe(response => {
        if (response['success']) {
          this.prescriptionsLow = response['prescriptionsLow'];
          this.prescriptionsLowCount = response['prescriptionsLowCount'];
          this.prescriptionsLowPages = response['prescriptionsLowPages'];
          this.modal = $(this._rootNode.nativeElement).find('div#' + content);
          this.modal.modal('show');
        } else if (response['errors']) {
          CustomNotifierService.getErrors(response['errors'], this.notifier);
        }
        this.requestInProgress.next(false);
      });
  }
  dispatchPaginationLow(data) {
    if (data.action === 'total_elements') {
      this.refreshPageLow(data.value);
    } else if (data.action === 'change_page') {
      this.changePageLow(data.value);
    }
  }
  refreshPageLow(limit) {
    this.prescriptionsLowLimit = limit;
    this.prescriptionsLowPage = 1;
    this.getPrescriptionsLow('prescriptionsLow');
  }
  changePageLow(page) {
    if (page >= 1 && page <= this.prescriptionsLowPages) {
      this.prescriptionsLowPage = page;
      this.getPrescriptionsLow('prescriptionsLow');
    }
  }

  // -------------------- GET ALL EMERGENCY PRESCRIPTION NORMAL NOT STATE + PAGINATION --------------------
  filterPrescriptionsNormal(property) {
    if (this.prescriptionsNormalSort === property) {
      if (this.prescriptionsNormalOrder === 'asc') {
        this.prescriptionsNormalOrder = 'desc';
      } else {
        this.prescriptionsNormalOrder = 'asc';
      }
    } else {
      this.prescriptionsNormalSort = property;
      this.prescriptionsNormalOrder = 'asc';
    }

    this.getPrescriptionsNormal('prescriptionsNormal');
  }
  getPrescriptionsNormal(content) {
    this.requestInProgress.next(true);
    this.statisticsService.getEmergenciesPrescriptions(this.dateFrom, this.dateTo, this.selectedEstablishments, this.selectedRegistry,
      this.prescriptionsNormalSort, this.prescriptionsNormalOrder, this.prescriptionsNormalLimit, this.prescriptionsNormalPage, 'normal')
      .subscribe(response => {
        if (response['success']) {
          this.prescriptionsNormal = response['prescriptionsNormal'];
          this.prescriptionsNormalCount = response['prescriptionsNormalCount'];
          this.prescriptionsNormalPages = response['prescriptionsNormalPages'];
          this.modal = $(this._rootNode.nativeElement).find('div#' + content);
          this.modal.modal('show');
        } else if (response['errors']) {
          CustomNotifierService.getErrors(response['errors'], this.notifier);
        }
        this.requestInProgress.next(false);
      });
  }
  dispatchPaginationNormal(data) {
    if (data.action === 'total_elements') {
      this.refreshPageNormal(data.value);
    } else if (data.action === 'change_page') {
      this.changePageNormal(data.value);
    }
  }
  refreshPageNormal(limit) {
    this.prescriptionsNormalLimit = limit;
    this.prescriptionsNormalPage = 1;
    this.getPrescriptionsNormal('prescriptionsNormal');
  }
  changePageNormal(page) {
    if (page >= 1 && page <= this.prescriptionsNormalPages) {
      this.prescriptionsNormalPage = page;
      this.getPrescriptionsNormal('prescriptionsNormal');
    }
  }

  // -------------------- GET ALL EMERGENCY PRESCRIPTION HIGHER NOT STATE + PAGINATION --------------------
  filterPrescriptionsHigher(property) {
    if (this.prescriptionsHigherSort === property) {
      if (this.prescriptionsHigherOrder === 'asc') {
        this.prescriptionsHigherOrder = 'desc';
      } else {
        this.prescriptionsHigherOrder = 'asc';
      }
    } else {
      this.prescriptionsHigherSort = property;
      this.prescriptionsHigherOrder = 'asc';
    }

    this.getPrescriptionsHigher('prescriptionsHigher');
  }
  getPrescriptionsHigher(content) {
    this.requestInProgress.next(true);
    this.statisticsService.getEmergenciesPrescriptions(this.dateFrom, this.dateTo, this.selectedEstablishments, this.selectedRegistry,
      this.prescriptionsHigherSort, this.prescriptionsHigherOrder, this.prescriptionsHigherLimit, this.prescriptionsHigherPage, 'higher')
      .subscribe(response => {
        if (response['success']) {
          this.prescriptionsHigher = response['prescriptionsHigher'];
          this.prescriptionsHigherCount = response['prescriptionsHigherCount'];
          this.prescriptionsHigherPages = response['prescriptionsHigherPages'];
          this.modal = $(this._rootNode.nativeElement).find('div#' + content);
          this.modal.modal('show');
        } else if (response['errors']) {
          CustomNotifierService.getErrors(response['errors'], this.notifier);
        }
        this.requestInProgress.next(false);
      });
  }
  dispatchPaginationHigher(data) {
    if (data.action === 'total_elements') {
      this.refreshPageHigher(data.value);
    } else if (data.action === 'change_page') {
      this.changePageHigher(data.value);
    }
  }
  refreshPageHigher(limit) {
    this.prescriptionsHigherLimit = limit;
    this.prescriptionsHigherPage = 1;
    this.getPrescriptionsHigher('prescriptionsHigher');
  }
  changePageHigher(page) {
    if (page >= 1 && page <= this.prescriptionsHigherPages) {
      this.prescriptionsHigherPage = page;
      this.getPrescriptionsHigher('prescriptionsHigher');
    }
  }

  // -------------------- GET ALL EMERGENCY PRESCRIPTION PRIORITY NOT STATE + PAGINATION --------------------
  filterPrescriptionsPriority(property) {
    if (this.prescriptionsPrioritySort === property) {
      if (this.prescriptionsPriorityOrder === 'asc') {
        this.prescriptionsPriorityOrder = 'desc';
      } else {
        this.prescriptionsPriorityOrder = 'asc';
      }
    } else {
      this.prescriptionsPrioritySort = property;
      this.prescriptionsPriorityOrder = 'asc';
    }

    this.getPrescriptionsPriority('prescriptionsPriority');
  }
  getPrescriptionsPriority(content) {
    this.requestInProgress.next(true);
    this.statisticsService.getEmergenciesPrescriptions(this.dateFrom, this.dateTo, this.selectedEstablishments, this.selectedRegistry,
      this.prescriptionsPrioritySort, this.prescriptionsPriorityOrder, this.prescriptionsPriorityLimit, this.prescriptionsPriorityPage,
      'priority').subscribe(response => {
        if (response['success']) {
          this.prescriptionsPriority = response['prescriptionsPriority'];
          this.prescriptionsPriorityCount = response['prescriptionsPriorityCount'];
          this.prescriptionsPriorityPages = response['prescriptionsPriorityPages'];
          this.modal = $(this._rootNode.nativeElement).find('div#' + content);
          this.modal.modal('show');
        } else if (response['errors']) {
          CustomNotifierService.getErrors(response['errors'], this.notifier);
        }
        this.requestInProgress.next(false);
      });
  }
  dispatchPaginationPriority(data) {
    if (data.action === 'total_elements') {
      this.refreshPagePriority(data.value);
    } else if (data.action === 'change_page') {
      this.changePagePriority(data.value);
    }
  }
  refreshPagePriority(limit) {
    this.prescriptionsPriorityLimit = limit;
    this.prescriptionsPriorityPage = 1;
    this.getPrescriptionsPriority('prescriptionsPriority');
  }
  changePagePriority(page) {
    if (page >= 1 && page <= this.prescriptionsPriorityPages) {
      this.prescriptionsPriorityPage = page;
      this.getPrescriptionsPriority('prescriptionsPriority');
    }
  }

  // -------------------- GET ALL PRESCRIPTION LATE + PAGINATION --------------------
  filterPrescriptionsLate(property) {
    if (this.prescriptionsLateSort === property) {
      if (this.prescriptionsLateOrder === 'asc') {
        this.prescriptionsLateOrder = 'desc';
      } else {
        this.prescriptionsLateOrder = 'asc';
      }
    } else {
      this.prescriptionsLateSort = property;
      this.prescriptionsLateOrder = 'asc';
    }

    this.getPrescriptionsLate('prescriptionsLate');
  }
  getPrescriptionsLate(content) {
    this.requestInProgress.next(true);
    this.statisticsService.getDelayPrescriptions(this.dateFrom, this.dateTo, this.selectedEstablishments, this.selectedRegistry,
      this.prescriptionsLateSort, this.prescriptionsLateOrder, this.prescriptionsLateLimit, this.prescriptionsLatePage,
      'late').subscribe(response => {
        if (response['success']) {
          this.prescriptionsLate = response['prescriptionsLate'];
          this.prescriptionsLateCount = response['prescriptionsLateCount'];
          this.prescriptionsLatePages = response['prescriptionsLatePages'];
          this.modal = $(this._rootNode.nativeElement).find('div#' + content);
          this.modal.modal('show');
        } else if (response['errors']) {
          CustomNotifierService.getErrors(response['errors'], this.notifier);
        }
        this.requestInProgress.next(false);
      });
  }
  dispatchPaginationLate(data) {
    if (data.action === 'total_elements') {
      this.refreshPageLate(data.value);
    } else if (data.action === 'change_page') {
      this.changePageLate(data.value);
    }
  }
  refreshPageLate(limit) {
    this.prescriptionsLateLimit = limit;
    this.prescriptionsLatePage = 1;
    this.getPrescriptionsLate('prescriptionsLate');
  }
  changePageLate(page) {
    if (page >= 1 && page <= this.prescriptionsLatePages) {
      this.prescriptionsLatePage = page;
      this.getPrescriptionsLate('prescriptionsLate');
    }
  }

  // -------------------- GET ALL PRESCRIPTION IN TIME + PAGINATION --------------------
  filterPrescriptionsInTime(property) {
    if (this.prescriptionsInTimeSort === property) {
      if (this.prescriptionsInTimeOrder === 'asc') {
        this.prescriptionsInTimeOrder = 'desc';
      } else {
        this.prescriptionsInTimeOrder = 'asc';
      }
    } else {
      this.prescriptionsInTimeSort = property;
      this.prescriptionsInTimeOrder = 'asc';
    }

    this.getPrescriptionsInTime('prescriptionsInTime');
  }
  getPrescriptionsInTime(content) {
    this.requestInProgress.next(true);
    this.statisticsService.getDelayPrescriptions(this.dateFrom, this.dateTo, this.selectedEstablishments, this.selectedRegistry,
      this.prescriptionsInTimeSort, this.prescriptionsInTimeOrder, this.prescriptionsInTimeLimit, this.prescriptionsInTimePage,
      'inTime').subscribe(response => {
        if (response['success']) {
          this.prescriptionsInTime = response['prescriptionsInTime'];
          this.prescriptionsInTimeCount = response['prescriptionsInTimeCount'];
          this.prescriptionsInTimePages = response['prescriptionsInTimePages'];
          this.modal = $(this._rootNode.nativeElement).find('div#' + content);
          this.modal.modal('show');
        } else if (response['errors']) {
          CustomNotifierService.getErrors(response['errors'], this.notifier);
        }
        this.requestInProgress.next(false);
      });
  }
  dispatchPaginationInTime(data) {
    if (data.action === 'total_elements') {
      this.refreshPageInTime(data.value);
    } else if (data.action === 'change_page') {
      this.changePageInTime(data.value);
    }
  }
  refreshPageInTime(limit) {
    this.prescriptionsInTimeLimit = limit;
    this.prescriptionsInTimePage = 1;
    this.getPrescriptionsInTime('prescriptionsInTime');
  }
  changePageInTime(page) {
    if (page >= 1 && page <= this.prescriptionsInTimePages) {
      this.prescriptionsInTimePage = page;
      this.getPrescriptionsInTime('prescriptionsInTime');
    }
  }

  // -------------------- COMPARATIVE ESTABLISHMENTS --------------------
  updateSelectedEstablishmentA() {
    if (this.selectedEstablishmentA && this.selectedEstablishmentB) {
      this.selectedEstablishments = [];
      this.selectedEstablishments.push(this.selectedEstablishmentA);
      this.selectedEstablishments.push(this.selectedEstablishmentB);
      this.getComparativePrescriptions();
      this.getPrescriptionsStatsCosts();
      this.getPrescriptionsStatsDay();
    }
  }
  updateSelectedEstablishmentB() {
    if (this.selectedEstablishmentA && this.selectedEstablishmentB) {
      this.selectedEstablishments = [];
      this.selectedEstablishments.push(this.selectedEstablishmentA);
      this.selectedEstablishments.push(this.selectedEstablishmentB);
      this.getComparativePrescriptions();
      this.getPrescriptionsStatsCosts();
      this.getPrescriptionsStatsDay();
    }
  }
  updateRegistryComparative() {
    this.getComparativePrescriptions();
    this.getPrescriptionsStatsCosts();
    this.getPrescriptionsStatsDay();
  }
  updatePeriodComparative(event = null) {
    let update = false;
    if (this.period === 'days') {
      this.dateFrom = moment().subtract(7, 'day').format('YYYY-MM-DD');
      this.dateTo = moment().format('YYYY-MM-DD');
      update = true;
    } else if (this.period === 'month') {
      this.dateFrom = moment().subtract(1, 'month').format('YYYY-MM-DD');
      this.dateTo = moment().format('YYYY-MM-DD');
      update = true;
    } else if (this.period === 'months') {
      this.dateFrom = moment().subtract(3, 'month').format('YYYY-MM-DD');
      this.dateTo = moment().format('YYYY-MM-DD');
      update = true;
    } else if (this.period === 'year') {
      this.dateFrom = moment().startOf('year').format('YYYY-MM-DD');
      this.dateTo = moment().endOf('year').format('YYYY-MM-DD');
      update = true;
    } else if (this.period === 'lastyear') {
      this.dateFrom = moment().startOf('year').subtract(1, 'year').format('YYYY-MM-DD');
      this.dateTo = moment().endOf('year').subtract(1, 'year').format('YYYY-MM-DD');
      update = true;
    } else if (this.period === 'lastyears') {
      this.dateFrom = moment().startOf('year').subtract(2, 'year').format('YYYY-MM-DD');
      this.dateTo = moment().endOf('year').subtract(2, 'year').format('YYYY-MM-DD');
      update = true;
    }
    if (event === null) {
      this.dateFrom = null;
      this.dateTo = null;
      update = true;
    }
    if (update) {
      this.getComparativePrescriptions();
    }
  }
  updateDateComparative() {
    this.period = 'custom';
    this.getComparativePrescriptions();
  }
  getComparativePrescriptions() {
    if (this.selectedEstablishmentA && this.selectedEstablishmentB) {
      this.loaderPrescriptionsComparative = true;
      this.statisticsService.prescriptionsStat(
        this.dateFrom, this.dateTo, this.selectedEstablishmentA, this.selectedRegistry, '', '', '', ''
      ).subscribe(response => {
        if (response['success']) {
          this.totalPrescriptionsA = response['allPrescriptionsCount'];
          this.prescriptionsALate = response['prescriptionsNotStateCount'];
          this.statisticsService.prescriptionsStat(
            this.dateFrom, this.dateTo, this.selectedEstablishmentB, this.selectedRegistry, '', '', '', ''
          ).subscribe(result => {
            if (result['success']) {
              this.totalPrescriptionsB = result['allPrescriptionsCount'];
              this.prescriptionsBLate = result['prescriptionsNotStateCount'];
              this.userChart.createPrescriptionsComparativeChart('#createPrescriptionsComparative',
                this.totalPrescriptionsA, this.prescriptionsALate, this.totalPrescriptionsB, this.prescriptionsBLate,
                this.selectedEstablishmentA, this.selectedEstablishmentB, this);
            } else if (result['errors']) {
              CustomNotifierService.getErrors(result['errors'], this.notifier);
            }
          });
        } else if (response['errors']) {
          CustomNotifierService.getErrors(response['errors'], this.notifier);
        }
        this.loaderPrescriptionsComparative = false;
      });
    }
  }

  // -------------------- URL + ESTABLISHMENT NAME --------------------
  getEstablishmentNamePrescription(prescription) {
    if (prescription['commission']) {
      if (prescription['commission']['establishment_registry']) {
        if (prescription['commission']['establishment_registry']['establishment']) {
          return prescription['commission']['establishment_registry']['establishment']['name'];
        } else {
          return '';
        }
      } else {
        return '';
      }
    } else {
      return '';
    }
  }
  getUrlPrescription(prescription) {
    return '/dashboard-registry/' + prescription['commission']['establishment_registry']['registry_id']
      + '/establishment/' + prescription['commission']['establishment_registry']['establishment_id']
      + '/agencies/' + prescription['commission']['id'] + '/prescriptions/' + prescription['id'];
  }

  // ------------ STATS AVERAGE DAY / STATS AVERAGE COSTS --------------
  // DAY + MODAL AND PAGINATION FOR ALL FILTERS
  getPrescriptionsStatsDay() {
    this.loaderPrescriptionsAverageDays = true;
    this.statisticsService.prescStatDay(this.dateFrom, this.dateTo, this.selectedEstablishments, this.selectedRegistry,
      '', '', this.dayLimit, this.dayPage)
      .subscribe(response => {
        if (response['success']) {
          this.dayFilter1Count = response['prescriptionsFilter1Count'];
          this.dayFilter2Count = response['prescriptionsFilter2Count'];
          this.dayFilter3Count = response['prescriptionsFilter3Count'];
          this.dayFilter4Count = response['prescriptionsFilter4Count'];
          this.userChart.createPrescriptionsAverageDays('#createPrescriptionsAverageDays',
            this.dayFilter1Count, this.dayFilter2Count, this.dayFilter3Count, this.dayFilter4Count, this);
        } else if (response['errors']) {
          CustomNotifierService.getErrors(response['errors'], this.notifier);
        }
        this.loaderPrescriptionsAverageDays = false;
      });
  }
  filtersDayFilter1(property) {
    if (this.dayFilter1Sort === property) {
      if (this.dayFilter1Order === 'asc') {
        this.dayFilter1Order = 'desc';
      } else {
        this.dayFilter1Order = 'asc';
      }
    } else {
      this.dayFilter1Sort = property;
      this.dayFilter1Order = 'asc';
    }

    this.getPrescriptionsStatsDayFilter1('prescriptionsAverageCostsFilter1');
  }
  getPrescriptionsStatsDayFilter1(content) {
    this.requestInProgress.next(true);
    this.statisticsService.prescStatDay(this.dateFrom, this.dateTo, this.selectedEstablishments, this.selectedRegistry,
      this.dayFilter1Sort, this.dayFilter1Order, this.dayFilter1Limit, this.dayFilter1Page, 'filter1')
      .subscribe(response => {
        if (response['success']) {
          this.dayFilter1 = response['prescriptionsFilter1'];
          this.dayFilter1Count = response['prescriptionsFilter1Count'];
          this.dayFilter1Pages = response['prescriptionsFilter1Pages'];
          this.modal = $(this._rootNode.nativeElement).find('div#' + content);
          this.modal.modal('show');
        } else if (response['errors']) {
          CustomNotifierService.getErrors(response['errors'], this.notifier);
        }
        this.requestInProgress.next(false);
      });
  }
  dispatchPaginationDayFilter1(data) {
    if (data.action === 'total_elements') {
      this.refreshPageDayFilter1(data.value);
    } else if (data.action === 'change_page') {
      this.changePageDayFilter1(data.value);
    }
  }
  refreshPageDayFilter1(limit) {
    this.dayFilter1Limit = limit;
    this.dayFilter1Page = 1;
    this.getPrescriptionsStatsDayFilter1('prescriptionsAverageCostsFilter1');
  }
  changePageDayFilter1(page) {
    if (page >= 1 && page <= this.dayFilter1Pages) {
      this.dayFilter1Page = page;
      this.getPrescriptionsStatsDayFilter1('prescriptionsAverageCostsFilter1');
    }
  }

  filtersDayFilter2(property) {
    if (this.dayFilter2Sort === property) {
      if (this.dayFilter2Order === 'asc') {
        this.dayFilter2Order = 'desc';
      } else {
        this.dayFilter2Order = 'asc';
      }
    } else {
      this.dayFilter2Sort = property;
      this.dayFilter2Order = 'asc';
    }

    this.getPrescriptionsStatsDayFilter2('prescriptionsAverageCostsFilter2');
  }
  getPrescriptionsStatsDayFilter2(content) {
    this.requestInProgress.next(true);
    this.statisticsService.prescStatDay(this.dateFrom, this.dateTo, this.selectedEstablishments, this.selectedRegistry,
      this.dayFilter2Sort, this.dayFilter2Order, this.dayFilter1Limit, this.dayFilter1Page, 'filter2')
      .subscribe(response => {
        if (response['success']) {
          this.dayFilter2 = response['prescriptionsFilter2'];
          this.dayFilter2Count = response['prescriptionsFilter2Count'];
          this.dayFilter2Pages = response['prescriptionsFilter2Pages'];
          this.modal = $(this._rootNode.nativeElement).find('div#' + content);
          this.modal.modal('show');
        } else if (response['errors']) {
          CustomNotifierService.getErrors(response['errors'], this.notifier);
        }
        this.requestInProgress.next(false);
      });
  }
  dispatchPaginationDayFilter2(data) {
    if (data.action === 'total_elements') {
      this.refreshPageDayFilter2(data.value);
    } else if (data.action === 'change_page') {
      this.changePageDayFilter2(data.value);
    }
  }
  refreshPageDayFilter2(limit) {
    this.dayFilter2Limit = limit;
    this.dayFilter2Page = 1;
    this.getPrescriptionsStatsDayFilter2('prescriptionsAverageCostsFilter2');
  }
  changePageDayFilter2(page) {
    if (page >= 1 && page <= this.dayFilter2Pages) {
      this.dayFilter2Page = page;
      this.getPrescriptionsStatsDayFilter2('prescriptionsAverageCostsFilter2');
    }
  }

  filtersDayFilter3(property) {
    if (this.dayFilter3Sort === property) {
      if (this.dayFilter3Order === 'asc') {
        this.dayFilter3Order = 'desc';
      } else {
        this.dayFilter3Order = 'asc';
      }
    } else {
      this.dayFilter3Sort = property;
      this.dayFilter3Order = 'asc';
    }

    this.getPrescriptionsStatsDayFilter3('prescriptionsAverageCostsFilter3');
  }
  getPrescriptionsStatsDayFilter3(content) {
    this.requestInProgress.next(true);
    this.statisticsService.prescStatDay(this.dateFrom, this.dateTo, this.selectedEstablishments, this.selectedRegistry,
      this.dayFilter3Sort, this.dayFilter3Order, this.dayFilter1Limit, this.dayFilter1Page, 'filter3')
      .subscribe(response => {
        if (response['success']) {
          this.dayFilter3 = response['prescriptionsFilter3'];
          this.dayFilter3Count = response['prescriptionsFilter3Count'];
          this.dayFilter3Pages = response['prescriptionsFilter3Pages'];
          this.modal = $(this._rootNode.nativeElement).find('div#' + content);
          this.modal.modal('show');
        } else if (response['errors']) {
          CustomNotifierService.getErrors(response['errors'], this.notifier);
        }
        this.requestInProgress.next(false);
      });
  }
  dispatchPaginationDayFilter3(data) {
    if (data.action === 'total_elements') {
      this.refreshPageDayFilter3(data.value);
    } else if (data.action === 'change_page') {
      this.changePageDayFilter3(data.value);
    }
  }
  refreshPageDayFilter3(limit) {
    this.dayFilter3Limit = limit;
    this.dayFilter3Page = 1;
    this.getPrescriptionsStatsDayFilter3('prescriptionsAverageCostsFilter3');
  }
  changePageDayFilter3(page) {
    if (page >= 1 && page <= this.dayFilter3Pages) {
      this.dayFilter3Page = page;
      this.getPrescriptionsStatsDayFilter3('prescriptionsAverageCostsFilter3');
    }
  }

  filtersDayFilter4(property) {
    if (this.dayFilter4Sort === property) {
      if (this.dayFilter4Order === 'asc') {
        this.dayFilter4Order = 'desc';
      } else {
        this.dayFilter4Order = 'asc';
      }
    } else {
      this.dayFilter4Sort = property;
      this.dayFilter4Order = 'asc';
    }

    this.getPrescriptionsStatsDayFilter4('prescriptionsAverageCostsFilter4');
  }
  getPrescriptionsStatsDayFilter4(content) {
    this.requestInProgress.next(true);
    this.statisticsService.prescStatDay(this.dateFrom, this.dateTo, this.selectedEstablishments, this.selectedRegistry,
      this.dayFilter4Sort, this.dayFilter4Order, this.dayFilter4Limit, this.dayFilter4Page, 'filter4')
      .subscribe(response => {
        if (response['success']) {
          this.dayFilter4 = response['prescriptionsFilter4'];
          this.dayFilter4Count = response['prescriptionsFilter4Count'];
          this.dayFilter4Pages = response['prescriptionsFilter4Pages'];
          this.modal = $(this._rootNode.nativeElement).find('div#' + content);
          this.modal.modal('show');
        } else if (response['errors']) {
          CustomNotifierService.getErrors(response['errors'], this.notifier);
        }
        this.requestInProgress.next(false);
      });
  }
  dispatchPaginationDayFilter4(data) {
    if (data.action === 'total_elements') {
      this.refreshPageDayFilter4(data.value);
    } else if (data.action === 'change_page') {
      this.changePageDayFilter4(data.value);
    }
  }
  refreshPageDayFilter4(limit) {
    this.dayFilter4Limit = limit;
    this.dayFilter4Page = 1;
    this.getPrescriptionsStatsDayFilter4('prescriptionsAverageCostsFilter4');
  }
  changePageDayFilter4(page) {
    if (page >= 1 && page <= this.dayFilter4Pages) {
      this.dayFilter4Page = page;
      this.getPrescriptionsStatsDayFilter4('prescriptionsAverageCostsFilter4');
    }
  }
  // COSTS + MODAL AND PAGINATION FOR ALL FILTERS
  getPrescriptionsStatsCosts() {
    this.loaderPrescriptionsAverageCosts = true;
    this.statisticsService.prescStatCosts(this.dateFrom, this.dateTo, this.selectedEstablishments, this.selectedRegistry,
      '', '', this.costsLimit, this.costsPage)
      .subscribe(response => {
        if (response['success']) {
          this.costFilter1Count = response['prescriptionsFilter1Count'];
          this.costFilter2Count = response['prescriptionsFilter2Count'];
          this.costFilter3Count = response['prescriptionsFilter3Count'];
          this.userChart.createPrescriptionsAverageCosts('#createPrescriptionsAverageCosts',
            this.costFilter1Count, this.costFilter2Count, this.costFilter3Count, this);
        } else if (response['errors']) {
          CustomNotifierService.getErrors(response['errors'], this.notifier);
        }
        this.loaderPrescriptionsAverageCosts = false;
      });
  }

  filtersCostFilter1(property) {
    if (this.costFilter1Sort === property) {
      if (this.costFilter1Order === 'asc') {
        this.costFilter1Order = 'desc';
      } else {
        this.costFilter1Order = 'asc';
      }
    } else {
      this.costFilter1Sort = property;
      this.costFilter1Order = 'asc';
    }

    this.getPrescriptionsStatsCostsFilter1('prescriptionsAverageCostsFilter1');
  }
  getPrescriptionsStatsCostsFilter1(content) {
    this.requestInProgress.next(true);
    this.statisticsService.prescStatCosts(this.dateFrom, this.dateTo, this.selectedEstablishments, this.selectedRegistry,
      this.costFilter1Sort, this.costFilter1Order, this.costFilter1Limit, this.costFilter1Page, 'filter1')
      .subscribe(response => {
        if (response['success']) {
          this.costFilter1 = response['prescriptionsFilter1'];
          this.costFilter1Count = response['prescriptionsFilter1Count'];
          this.costFilter1Pages = response['prescriptionsFilter1Pages'];
          this.modal = $(this._rootNode.nativeElement).find('div#' + content);
          this.modal.modal('show');
        } else if (response['errors']) {
          CustomNotifierService.getErrors(response['errors'], this.notifier);
        }
        this.requestInProgress.next(false);
      });
  }
  dispatchPaginationCostsFilter1(data) {
    if (data.action === 'total_elements') {
      this.refreshPageCostsFilter1(data.value);
    } else if (data.action === 'change_page') {
      this.changePageCostsFilter1(data.value);
    }
  }
  refreshPageCostsFilter1(limit) {
    this.costFilter1Limit = limit;
    this.costFilter1Page = 1;
    this.getPrescriptionsStatsCostsFilter1('prescriptionsAverageCostsFilter1');
  }
  changePageCostsFilter1(page) {
    if (page >= 1 && page <= this.costFilter1Pages) {
      this.costFilter1Page = page;
      this.getPrescriptionsStatsCostsFilter1('prescriptionsAverageCostsFilter1');
    }
  }

  filtersCostFilter2(property) {
    if (this.costFilter2Sort === property) {
      if (this.costFilter2Order === 'asc') {
        this.costFilter2Order = 'desc';
      } else {
        this.costFilter2Order = 'asc';
      }
    } else {
      this.costFilter2Sort = property;
      this.costFilter2Order = 'asc';
    }

    this.getPrescriptionsStatsCostsFilter2('prescriptionsAverageCostsFilter2');
  }
  getPrescriptionsStatsCostsFilter2(content) {
    this.requestInProgress.next(true);
    this.statisticsService.prescStatCosts(this.dateFrom, this.dateTo, this.selectedEstablishments, this.selectedRegistry,
      this.costFilter2Sort, this.costFilter2Order, this.costFilter2Limit, this.costFilter2Page, 'filter2')
      .subscribe(response => {
        if (response['success']) {
          this.costFilter2 = response['prescriptionsFilter2'];
          this.costFilter2Count = response['prescriptionsFilter2Count'];
          this.costFilter2Pages = response['prescriptionsFilter2Pages'];
          this.modal = $(this._rootNode.nativeElement).find('div#' + content);
          this.modal.modal('show');
        } else if (response['errors']) {
          CustomNotifierService.getErrors(response['errors'], this.notifier);
        }
        this.requestInProgress.next(false);
      });
  }
  dispatchPaginationCostsFilter2(data) {
    if (data.action === 'total_elements') {
      this.refreshPageCostsFilter2(data.value);
    } else if (data.action === 'change_page') {
      this.changePageCostsFilter2(data.value);
    }
  }
  refreshPageCostsFilter2(limit) {
    this.costFilter2Limit = limit;
    this.costFilter2Page = 1;
    this.getPrescriptionsStatsCostsFilter2('prescriptionsAverageCostsFilter2');
  }
  changePageCostsFilter2(page) {
    if (page >= 1 && page <= this.costFilter2Pages) {
      this.costFilter2Page = page;
      this.getPrescriptionsStatsCostsFilter2('prescriptionsAverageCostsFilter2');
    }
  }

  filtersCostFilter3(property) {
    if (this.costFilter3Sort === property) {
      if (this.costFilter3Order === 'asc') {
        this.costFilter3Order = 'desc';
      } else {
        this.costFilter3Order = 'asc';
      }
    } else {
      this.costFilter3Sort = property;
      this.costFilter3Order = 'asc';
    }

    this.getPrescriptionsStatsCostsFilter3('prescriptionsAverageCostsFilter3');
  }
  getPrescriptionsStatsCostsFilter3(content) {
    this.requestInProgress.next(true);
    this.statisticsService.prescStatCosts(this.dateFrom, this.dateTo, this.selectedEstablishments, this.selectedRegistry,
      this.costFilter3Sort, this.costFilter3Order, this.costFilter3Limit, this.costFilter3Page, 'filter3')
      .subscribe(response => {
        if (response['success']) {
          this.costFilter3 = response['prescriptionsFilter3'];
          this.costFilter3Count = response['prescriptionsFilter3Count'];
          this.costFilter3Pages = response['prescriptionsFilter3Pages'];
          this.modal = $(this._rootNode.nativeElement).find('div#' + content);
          this.modal.modal('show');
        } else if (response['errors']) {
          CustomNotifierService.getErrors(response['errors'], this.notifier);
        }
        this.requestInProgress.next(false);
      });
  }
  dispatchPaginationCostsFilter3(data) {
    if (data.action === 'total_elements') {
      this.refreshPageCostsFilter3(data.value);
    } else if (data.action === 'change_page') {
      this.changePageCostsFilter3(data.value);
    }
  }
  refreshPageCostsFilter3(limit) {
    this.costFilter3Limit = limit;
    this.costFilter3Page = 1;
    this.getPrescriptionsStatsCostsFilter3('prescriptionsAverageCostsFilter3');
  }
  changePageCostsFilter3(page) {
    if (page >= 1 && page <= this.costFilter3Pages) {
      this.costFilter3Page = page;
      this.getPrescriptionsStatsCostsFilter3('prescriptionsAverageCostsFilter3');
    }
  }

  resetFilters() {
    this.selectedEstablishments = [];
    this.selectedEstablishmentA = null;
    this.selectedEstablishmentB = null;
    this.period = 'custom';
    $('#dateFrom').flatpickr().clear();
    $('#dateTo').flatpickr().clear();
    this.selectedRegistry = null;
    this.getPrescriptionsStatsCosts();
    this.getPrescriptionsStatsDay();
    this.getPrescriptionBlock();
    this.getEmergenciesPrescriptions();
    this.getDelayPrescriptions();
  }
  resetComparativeFilters() {
    this.selectedEstablishments = [];
    this.selectedEstablishmentA = null;
    this.selectedEstablishmentB = null;
    this.period = 'custom';
    $('#dateFromComp').flatpickr().clear();
    $('#dateToComp').flatpickr().clear();
    this.selectedRegistry = null;
    this.getPrescriptionsStatsCosts();
    this.getPrescriptionsStatsDay();
  }
}
