import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NotifierService } from "angular-notifier";
import { EstablishmentsRequestService } from "src/app/services/establishments.request.service";
import { RegistriesRequestService } from "src/app/services/registries.request.service";
import Establishment from "src/shared/interfaces/establishment";
import Registry from "src/shared/interfaces/registry";

import { CustomNotifierService } from "../../../services/custom.notifier.service";
import { TrainingsRequestService } from "../../../services/trainings.request.service";
import { EmployeesTrainingComponent } from "../get-employees-training/get-employees-training.component";

@Component({
  selector: "app-update-employee",
  templateUrl: "./update-employee.component.html",
  styleUrls: ["./update-employee.component.scss"],
})
export class UpdateEmployeeComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  canCreate = false;
  canUpdate = false;

  search = "";
  sort = "Establishments.id";
  order = "asc";
  limit = 12;
  page = 1;

  establishment: Establishment;
  registries: Registry;

  employeeForm: FormGroup;
  id = new FormControl("");
  establishment_id = new FormControl(null, Validators.required);
  registry_id = new FormControl(null, Validators.required);
  firstname = new FormControl("", Validators.required);
  lastname = new FormControl("", Validators.required);
  email = new FormControl("", [Validators.required, Validators.email]);

  errors = {};
  disableSelect = true;

  isNew = true;
  loader = false;
  loaderContent = false;

  modalEmployee = null;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private allEmployees: EmployeesTrainingComponent,
    private trainingsService: TrainingsRequestService,
    private establishmentsService: EstablishmentsRequestService,
    private registriesService: RegistriesRequestService
  ) {}

  ngOnInit() {
    this.employeeForm = this.formBuilder.group({
      id: this.id,
      establishment_id: this.establishment_id,
      registry_id: this.registry_id,
      firstname: this.firstname,
      lastname: this.lastname,
      email: this.email,
    });
    this.getRegistry();

    if (this.activatedRoute.snapshot.params.employee_id) {
      this.isNew = false;
      this.disableSelect = false;
      this.getEmployee();
    }
  }

  ngAfterViewInit(): void {
    this.modalEmployee.open();
  }

  ngOnDestroy(): void {
    this.modalEmployee.close();
  }

  getErrorMessage(param) {
    return this.errors[param] && this.errors[param]["message"];
  }

  getEmployee() {
    this.loaderContent = true;
    this.trainingsService
      .getEmployee(this.activatedRoute.snapshot.params.employee_id)
      .subscribe((response) => {
        if (response["success"]) {
          this.canCreate = response["canCreate"];
          this.canUpdate = response["canUpdate"];
          this.employeeForm.patchValue({
            id: response["employee"].id,
            establishment_id: response["employee"].establishment_id,
            registry_id: response["employee"].registry_id,
            firstname: response["employee"].firstname,
            lastname: response["employee"].lastname,
            email: response["employee"].email,
          });
          this.getEstablishment();
          this.establishment_id.patchValue(
            response["employee"].establishment_id
          );
          if (!this.canUpdate) {
            this.employeeForm.disable();
          }
        } else if (response["errors"]) {
          CustomNotifierService.getErrors(response["errors"], this.notifier);
        }
        this.loaderContent = false;
      });
  }

  getEstablishment() {
    if (this.registry_id.value) {
      this.establishment_id.patchValue(null);
      this.establishmentsService
        .getAllEstablishmentsRegistry(this.registry_id.value)
        .subscribe((response) => {
          if (response["success"]) {
            this.establishment = response["establishments"];
            this.disableSelect = false;
          } else if (response["errors"]) {
            this.errors = response["errors"];
          }
        });
    }
  }

  getRegistry() {
    this.registriesService
      .getMyRegistries(
        this.search,
        this.sort,
        this.order,
        this.limit,
        this.page
      )
      .subscribe((response) => {
        if (response["success"]) {
          this.registries = response["registries"];
        } else if (response["errors"]) {
          this.errors = response["errors"];
        }
      });
  }

  createEmployee() {
    this.errors = {};
    this.loader = true;

    this.trainingsService
      .createEmployee(this.employeeForm.value)
      .subscribe((response) => {
        if (response["success"]) {
          this.modalEmployee.close();
          CustomNotifierService.getSuccessCreate(this.notifier);
        } else if (response["errors"]) {
          this.errors = response["errors"];
          CustomNotifierService.getErrors(response["errors"], this.notifier);
        }
        this.loader = false;
      });
  }

  updateEmployee() {
    let employee;
    this.errors = {};
    this.loader = true;
    employee = this.employeeForm.value;

    this.trainingsService.updateEmployee(employee).subscribe((response) => {
      if (response["success"]) {
        this.modalEmployee.close();
        CustomNotifierService.getSuccessUpdate(this.notifier);
      } else if (response["errors"]) {
        this.errors = response["errors"];
        CustomNotifierService.getErrors(response["errors"], this.notifier);
      }
      this.loader = false;
    });
  }

  bindModal(modal) {
    this.modalEmployee = modal;
  }

  closeModal() {
    this.router
      .navigate([this.activatedRoute.snapshot.parent.url.join("/")])
      .then(() => {
        this.allEmployees.refreshData();
      });
  }
}
