<div class="main-content">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col">

        <!-- HEADER -->
        <div class="header mb-0">
          <div class="header-body no-border">
            <div class="row align-items-center">
              <div class="col">
                <h1 class="header-title">Tableau de bord des opérations de surveillance</h1>

                <small class="form-text text-muted">
                  <span>Tableau de bord des opérations de surveillance</span>
                </small>

                <app-breadcrumbs [currentPage]="pageName" [names]="previousPageName"
                                 [routes]="previousPageRoute"></app-breadcrumbs>
              </div>

            </div>
          </div>
        </div>

        <div class="is-loading loader-content" *ngIf="loaderContent"></div>

        <div *ngIf="!loaderContent" class="row">
          <!-- TEMPERATURE -->
          <div class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4 d-flex flex-column">
            <div class="card flex-fill">
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col-auto align-self-start">
                    <i class="fe fe-thermometer"></i>
                  </div>

                  <div class="col overflow-hidden">
                    <h4 class="card-title name text-wrap mb-0">
                      <a [routerLink]="['temperatures']">
                        Surveillance des températures
                      </a>
                    </h4>
                  </div>

                  <div class="col-auto align-self-start">
                    <div class="dropdown">
                      <a class="dropdown-ellipses dropdown-toggle href" role="button"
                         [attr.aria-label]="'Actions rapide des surveillances des températures'" data-toggle="dropdown"
                         aria-haspopup="true" aria-expanded="false">
                        <i class="fe fe-more-vertical"></i>
                      </a>

                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item href" [routerLink]="['temperatures']">
                          Consulter les surveillances tes températures
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- LEGIONELLA -->
          <div class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4 d-flex flex-column">
            <div class="card flex-fill">
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col-auto align-self-start">
                    <i class="fe fe-droplet"></i>
                  </div>

                  <div class="col overflow-hidden">
                    <h4 class="card-title name text-wrap mb-0">
                      <a [routerLink]="['legionellas']">
                        Surveillance des légionnelles
                      </a>
                    </h4>
                  </div>

                  <div class="col-auto align-self-start">
                    <div class="dropdown">
                      <a class="dropdown-ellipses dropdown-toggle href" role="button"
                         [attr.aria-label]="'Actions rapide des surveillances des légionnelles'" data-toggle="dropdown"
                         aria-haspopup="true" aria-expanded="false">
                        <i class="fe fe-more-vertical"></i>
                      </a>

                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item href" [routerLink]="['legionellas']">
                          Consulter les surveillances des légionnelles
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- OTHER ANALYZES -->
          <div class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4 d-flex flex-column">
            <div class="card flex-fill">
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col-auto align-self-start">
                    <i class="fe fe-archive"></i>
                  </div>

                  <div class="col overflow-hidden">
                    <h4 class="card-title name text-wrap mb-0">
                      <a [routerLink]="['analyzes']">
                        Autres analyses
                      </a>
                    </h4>
                  </div>

                  <div class="col-auto align-self-start">
                    <div class="dropdown">
                      <a class="dropdown-ellipses dropdown-toggle href" role="button"
                         [attr.aria-label]="'Actions rapide des autres analyses'" data-toggle="dropdown"
                         aria-haspopup="true" aria-expanded="false">
                        <i class="fe fe-more-vertical"></i>
                      </a>

                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item href" [routerLink]="['analyzes']">
                          Consulter Des autres analyses
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<router-outlet></router-outlet>
