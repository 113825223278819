import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { HttpClient } from '@angular/common/http';
import { NotifierService } from 'angular-notifier';

@Injectable()
export class PdfRequestService extends RequestService {
  constructor(
    protected http: HttpClient,
    protected notifier: NotifierService
  ) {
    super(http, notifier);
  }

  /// SHOW PDF
  public getFile(fileName, fileUrl) {
    return this.get('file/' + fileName + '/' + fileUrl, { responseType: 'arraybuffer' });
  }
}
