import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { LayoutComponent } from './layout.component';
import { SidenavModule } from '../sidenav/sidenav.module';
import { ToolbarModule } from '../toolbar/toolbar.module';
import { ToolbarNotificationModule } from '../toolbar-notification/toolbar-notification.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SidenavModule,
    ToolbarModule,
    ToolbarNotificationModule
  ],
  declarations: [LayoutComponent],
  exports: [LayoutComponent]
})
export class LayoutModule {}
