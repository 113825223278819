import { Routes } from '@angular/router';

import { AllDocumentsComponent } from './all-documents/all-documents.component';
import { UpdateDocumentComponent } from './update-document/update-document.component';
import { UpdateFileTypeComponent } from './update-file-type/update-file-type.component';
import { UploadFileComponent } from './upload-file/upload-file.component';

export const documentsRoutes: Routes = [
  // PUBLIC DOCUMENTS
  {
    path: 'documents/public',
    component: AllDocumentsComponent
  },
  {
    path: 'document/public/:parent_id',
    component: AllDocumentsComponent
  },
  {
    path: 'document/public/:parent_id/file-type/:file_type_id/files',
    component: AllDocumentsComponent
  },
  // ADMIN DOCUMENTS
  {
    path: 'documents',
    component: AllDocumentsComponent,
    children: [
      {
        path: 'create',
        component: UpdateDocumentComponent
      },
      {
        path: ':folder_id',
        component: UpdateDocumentComponent
      }
    ]
  },
  {
    path: 'document/:parent_id',
    component: AllDocumentsComponent,
    children: [
      {
        path: 'file-type/create',
        component: UpdateFileTypeComponent
      },
      {
        path: 'file-type/:file_type_id',
        component: UpdateFileTypeComponent
      },
      {
        path: 'create',
        component: UpdateDocumentComponent
      },
      {
        path: ':folder_id',
        component: UpdateDocumentComponent
      },
      {
        path: 'files/create',
        component: UploadFileComponent
      },
      {
        path: 'files/:file_id',
        component: UploadFileComponent
      }
    ]
  },
  {
    path: 'document/:parent_id/file-type/:file_type_id/files',
    component: AllDocumentsComponent,
    children: [
      {
        path: 'create',
        component: UploadFileComponent
      },
      {
        path: ':file_id',
        component: UploadFileComponent
      }
    ]
  },
  // REGISTRY DOCUMENTS
  {
    path: 'documents/registry/:registry_id',
    component: AllDocumentsComponent,
    children: [
      {
        path: 'create',
        component: UpdateDocumentComponent
      },
      {
        path: ':folder_id',
        component: UpdateDocumentComponent
      }
    ]
  },
  {
    path: 'document/registry/:registry_id/:parent_id',
    component: AllDocumentsComponent,
    children: [
      {
        path: 'file-type/create',
        component: UpdateFileTypeComponent
      },
      {
        path: 'file-type/:file_type_id',
        component: UpdateFileTypeComponent
      },
      {
        path: 'create',
        component: UpdateDocumentComponent
      },
      {
        path: ':folder_id',
        component: UpdateDocumentComponent
      },
      {
        path: 'files/create',
        component: UploadFileComponent
      },
      {
        path: 'files/:file_id',
        component: UploadFileComponent
      }
    ]
  },
  {
    path: 'document/registry/:registry_id/:parent_id/file-type/:file_type_id/files',
    component: AllDocumentsComponent,
    children: [
      {
        path: 'create',
        component: UploadFileComponent
      },
      {
        path: ':file_id',
        component: UploadFileComponent
      }
    ]
  },
  // DASHBOARD REGISTRY DOCUMENTS CUSTOMER
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/document/:parent_id',
    component: AllDocumentsComponent,
    children: [
      {
        path: 'files/create',
        component: UploadFileComponent
      },
      {
        path: 'files/:file_id',
        component: UploadFileComponent
      }
    ]
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/document/:parent_id/file-type/:file_type_id/files',
    component: AllDocumentsComponent,
    children: [
      {
        path: 'create',
        component: UploadFileComponent
      },
      {
        path: ':file_id',
        component: UploadFileComponent
      }
    ]
  }
];
