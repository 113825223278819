import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { HttpClient } from '@angular/common/http';
import { RequestService } from './request.service';

@Injectable()
export class OfficialPrescriptionsRequestService extends RequestService {
  constructor(
    protected http: HttpClient,
    protected notifier: NotifierService
  ) {
    super(http, notifier);
  }

  /// GET
  /* PRESCRIPTIONS */
  public getAllPrescriptions(search, sort, order, limit, page, official_id, child_id) {
    return this.get('official/' + official_id + '/prescriptions' +
      '?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page + '&child_id=' + child_id);
  }
  public getAllUncorrectedPrescriptions(official_id) {
    return this.get('official/' + official_id + '/prescriptions/uncorrected');
  }
  public getPrescription(official_id, prescription_id) {
    return this.get('official/' + official_id + '/prescriptions/' + prescription_id);
  }
  public getPrescriptionState(official_id, prescription_id) {
    return this.get('official/' + official_id + '/prescriptions/' + prescription_id + '/state');
  }

  public getFiles(search, sort, order, limit, page, official_id, prescription_id, child_id) {
    return this.get('official/' + official_id + '/prescription/' + prescription_id + '/files?' +
      'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page + '&child_id=' + child_id);
  }
  public getFile(official_id, prescription_id, file_id) {
    return this.get('official/' + official_id + '/prescription/' + prescription_id + '/files/' + file_id);
  }

  /* PRESCRIPTIONS DASHBOARD */
  public getPrescriptionDashboard(search, sort, order, limit, page, registry_id, establishment_id, emergency, year, month) {
    if (establishment_id) {
      return this.get('dashboard/' + registry_id + '/establishment/' + establishment_id + '/official_prescriptions?' +
        'emergency=' + emergency + '&year=' + year + '&month=' + month +
        '&search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
    } else {
      return this.get('dashboard/' + registry_id + '/official_prescriptions?' +
        'emergency=' + emergency + '&year=' + year + '&month=' + month +
        '&search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
    }
  }
  public getMyPrescriptionDashboard(search, sort, order, limit, page, registry_id, establishment_id, emergency, year, month) {
    if (establishment_id) {
      return this.get('dashboard/' + registry_id + '/establishment/' + establishment_id + '/official_prescriptions/mine?' +
        'emergency=' + emergency + '&year=' + year + '&month=' + month +
        '&search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
    } else {
      return this.get('dashboard/' + registry_id + '/official_prescriptions/mine?' +
        'emergency=' + emergency + '&year=' + year + '&month=' + month +
      '&search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
    }
  }

  /* ESTABLISHMENT PRESCRIPTIONS DASHBOARD */
  // todo modal edit
  public getEstablishmentPrescription(registry_id, establishment_id, prescription_id) {
    return this.get('dashboard/' + registry_id + '/establishment/' + establishment_id + '/official_prescriptions/' +
      prescription_id);
  }
  public getEstablishmentPrescriptionState(registry_id, establishment_id, prescription_id) {
    return this.get('dashboard/' + registry_id + '/establishment/' + establishment_id + '/official_prescriptions/' +
      prescription_id + '/state');
  }

  /* REGISTRY PRESCRIPTIONS DASHBOARD */
  public getRegistryPrescription(registry_id, prescription_id) {
    return this.get('dashboard/' + registry_id + '/official_prescriptions/' + prescription_id);
  }
  public getRegistryPrescriptionState(registry_id, prescription_id) {
    return this.get('dashboard/' + registry_id + '/official_prescriptions/' + prescription_id + '/state');
  }

  /// CREATE
  /* PRESCRIPTIONS */
  public createPrescription(official_id, prescription) {
    return this.post('official/' + official_id + '/prescriptions', { prescription });
  }
  public createFile(official_id, prescription_id, file, prescriptions = []) {
    return this.post('official/' + official_id + '/prescription/' + prescription_id + '/files', { file, prescriptions });
  }

  /* ESTABLISHMENT PRESCRIPTIONS DASHBOARD */
  createEstablishmentPrescriptionFile(registry_id, establishment_id, prescription_id, file, prescriptions) {
    return this.post('dashboard-registry/' + registry_id + '/establishment/' + establishment_id +
      '/prescriptions/' + prescription_id + '/files', { file, prescriptions });
  }
  /* REGISTRY PRESCRIPTIONS DASHBOARD */
  createRegistryPrescriptionFile(registry_id, prescription_id, file, prescriptions) {
    return this.post('dashboard/' + registry_id + '/official_prescriptions/' + prescription_id + '/files',
      { file, prescriptions });
  }

  /// UPDATE
  /* PRESCRIPTIONS */
  public updatePrescription(official_id, prescription_id, prescription) {
    return this.put('official/' + official_id + '/prescriptions/' + prescription_id, { prescription });
  }
  public updatePrescriptionState(official_id, prescription_id, prescription) {
    return this.put('official/' + official_id + '/prescriptions/' + prescription_id + '/state', { prescription });
  }
  public updateFile(official_id, prescription_id, file_id, file) {
    return this.put('official/' + official_id + '/prescription/' + prescription_id + '/files/' + file_id, { file: file });
  }

  /* ESTABLISHMENT PRESCRIPTIONS DASHBOARD */
  public updateEstablishmentPrescription(registry_id, establishment_id, prescription_id, prescription) {
    return this.put( 'dashboard/' + registry_id + '/establishment/' + establishment_id +
      '/official_prescriptions/' + prescription_id, { prescription });
  }
  public updateEstablishmentPrescriptionState(registry_id, establishment_id, prescription_id, prescription) {
    return this.put('dashboard/' + registry_id + '/establishment/' + establishment_id +
      '/official_prescriptions/' + prescription_id + '/state', { prescription });
  }

  /* REGISTRY PRESCRIPTIONS DASHBOARD */
  public updateRegistryPrescription(registry_id, prescription_id, prescription) {
    return this.put('dashboard/' + registry_id + '/official_prescriptions/' + prescription_id, { prescription });
  }
  public updateRegistryPrescriptionState(registry_id, prescription_id, prescription) {
    return this.put('dashboard/' + registry_id + '/official_prescriptions/' + prescription_id + '/state', { prescription });
  }

  /// DELETE
  public deletePrescription(official_id, prescription_id) {
    return this.delete('official/' + official_id + '/prescriptions/' + prescription_id);
  }
  public deleteFile(official_id, prescription_id, file_id) {
    return this.delete('official/' + official_id + '/prescription/' + prescription_id + '/files/' + file_id);
  }

  /// DOWNLOAD
  public downloadFile(official_id, prescription_id, file_id) {
    return this.get('official/' + official_id + '/prescription/' + prescription_id + '/files/' + file_id + '/download',
      { responseType: 'arraybuffer' });
  }
}
