import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AllInstallationsModule } from './all-installations/all-installations.module';
import { UpdateInstallationModule } from './update-installation/update-installation.module';
import { InstallationsRequestService } from '../../services/installations.request.service';
import { ShowInstallationModule } from './show-installation/show-installation.module';
import {UpdateInstallationCustomModule} from './update-installation-custom/update-installation-custom.module';

@NgModule({
  imports: [
    CommonModule,
    AllInstallationsModule,
    UpdateInstallationModule,
    UpdateInstallationCustomModule,
    ShowInstallationModule
  ],
  providers: [
    InstallationsRequestService
  ]
})
export class InstallationsModule {}
