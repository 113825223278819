import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { environment } from '../../../../environments/environment';

import { AuthService } from '../../../core/auth/auth.service';
import { UsersRequestService } from '../../../services/users.request.service';

import User from '../../../../shared/interfaces/user';
import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { NotifierService } from 'angular-notifier';

declare const Select2: any;

@Component({
  selector: 'app-all-users',
  templateUrl: './all-users.component.html',
  styleUrls: ['./all-users.component.scss']
})
export class AllUsersComponent implements OnInit, OnDestroy {
  changeEnterprise: Subscription;
  environment = environment;

  isAdmin = false;

  canUpdate = false;
  canCreate = false;
  canDelete = false;
  canAffect = false;

  activeTabsList = '';

  loader = false;
  errors = {};

  pageName: string;
  previousPageName: string[] = [];
  previousPageRoute: string[] = [];
  updateRoute = '';
  createRoute = '';

  enterprise: any;
  users: User[] = [];
  count = 0;
  pages = 1;

  enterprise_id: number = null;
  search = '';
  sort = 'Users.firstname';
  order = 'asc';
  limit = 12;
  page = 1;

  subscription = 0;
  total = 0;

  timeout: any;
  view = '';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private usersService: UsersRequestService,
    private notifier: NotifierService
  ) {}

  ngOnInit() {
    let connectedAs;
    this.changeEnterprise = this.authService.getEnterprise().subscribe(() => {
      this.getUsers();
      connectedAs = JSON.parse(localStorage.getItem('connectedAs'));
      if (connectedAs) {
        this.isAdmin = JSON.parse(localStorage.getItem('connectedAsIsAdmin'));
      } else {
        this.isAdmin = JSON.parse(localStorage.getItem('isAdmin'));
      }
    });

    connectedAs = JSON.parse(localStorage.getItem('connectedAs'));
    if (connectedAs) {
      this.isAdmin = JSON.parse(localStorage.getItem('connectedAsIsAdmin'));
    } else {
      this.isAdmin = JSON.parse(localStorage.getItem('isAdmin'));
    }

    this.activeTabsList = localStorage.getItem('userTabsList');
    if (!this.activeTabsList) {
      localStorage.setItem('userTabsList', JSON.stringify('tabPaneOne'));
      this.activeTabsList = 'tabPaneOne';
    } else {
      this.activeTabsList = JSON.parse(this.activeTabsList);
    }

    this.updateRoute = '/' + this.activatedRoute.snapshot.url.join('/') + '/';
    this.createRoute = '/' + this.activatedRoute.snapshot.url.join('/') + '/create';

    this.pageName = 'Utilisateurs';

    if (this.activatedRoute.snapshot.params.enterprise_id) {
      this.enterprise_id = this.activatedRoute.snapshot.params.enterprise_id;
      if (this.activatedRoute.snapshot.url[0] && this.activatedRoute.snapshot.url[0].path === 'providers') {
        this.view = 'provider';

        this.previousPageName.push('Tableau de bord');
        this.previousPageRoute.push('/');

        this.previousPageName.push('Prestataires');
        this.previousPageRoute.push('/providers');
      } else if (this.activatedRoute.snapshot.url[0] && this.activatedRoute.snapshot.url[0].path === 'enterprises') {
        this.view = 'enterprise';

        this.previousPageName.push('Tableau de bord');
        this.previousPageRoute.push('/administration');

        this.previousPageName.push('Entreprises');
        this.previousPageRoute.push('/enterprises');
      }
    } else if (this.activatedRoute.snapshot.url[1] && this.activatedRoute.snapshot.url[1].path === 'mine') {
      this.view = 'mine';

      this.previousPageName.push('Tableau de bord');
      this.previousPageRoute.push('/');
    } else {
      this.view = 'all';

      this.previousPageName.push('Tableau de bord');
      this.previousPageRoute.push('/administration');
    }

    if (this.authService.loaded) {
      this.getUsers();
    }
  }

  ngOnDestroy() {
    this.changeEnterprise.unsubscribe();

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  searchData() {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.page = 1;
      this.refreshData();
    }, 500);
  }

  refreshData(sort = this.sort, order = this.order, page = this.page) {
    this.sort = sort;
    this.order = order;
    this.page = page;
    this.getUsers();
  }

  getUsers() {
    this.loader = true;

    let request;
    if (this.activatedRoute.snapshot.params.enterprise_id) {
      const enterprise_id = this.activatedRoute.snapshot.params.enterprise_id;
      if (this.activatedRoute.snapshot.url[0] && this.activatedRoute.snapshot.url[0].path === 'providers') {
        request = this.usersService.getProviderUsers(this.search, this.sort, this.order, this.limit, this.page, enterprise_id);
      } else if (this.activatedRoute.snapshot.url[0] && this.activatedRoute.snapshot.url[0].path === 'enterprises') {
        request = this.usersService.getEnterpriseUsers(this.search, this.sort, this.order, this.limit, this.page, enterprise_id);
      }
    } else if (this.activatedRoute.snapshot.url[1] && this.activatedRoute.snapshot.url[1].path === 'mine') {
      request = this.usersService.getMyUsers(this.search, this.sort, this.order, this.limit, this.page);
    } else {
      request = this.usersService.getAllUsers(this.search, this.sort, this.order, this.limit, this.page);
    }

    request.subscribe(response => {
      if (response['success']) {
        setTimeout(() => {
          Select2();
        }, 1);

        this.canUpdate = response['canUpdate'];
        this.canCreate = response['canCreate'];
        this.canDelete = response['canDelete'];
        this.canAffect = response['canAffect'];

        this.subscription = response['subscription'];
        this.total = response['total'];
        this.users = response['users'];
        if (response['enterprise']) {
          this.enterprise = response['enterprise'];
        }

        this.count = response['count'];
        this.pages = response['pages'];
      }
      this.loader = false;
    });
  }

  dispatchPagination(data) {
    if (data.action === 'total_elements') {
      this.refreshPage(data.value);
    } else if (data.action === 'change_page') {
      this.changePage(data.value);
    }
  }

  refreshPage(limit) {
    this.limit = limit;
    this.page = 1;
    this.refreshData();
  }

  filter(property) {
    if (this.sort === property) {
      if (this.order === 'asc') {
        this.order = 'desc';
      } else {
        this.order = 'asc';
      }
    } else {
      this.sort = property;
      this.order = 'asc';
    }

    this.refreshData();
  }

  changePage(page) {
    if (page >= 1 && page <= this.pages) {
      this.page = page;
      this.refreshData();
    }
  }

  changeActiveTabsList(tabValue: string) {
    localStorage.setItem('userTabsList', JSON.stringify(tabValue));
    this.activeTabsList = tabValue;
  }

  connectedAs(user_id) {
    let enterprise_id = '';
    if (this.activatedRoute.snapshot.params.enterprise_id) {
      enterprise_id = this.activatedRoute.snapshot.params.enterprise_id;
    } else if (this.view === 'mine') {
      const connectedAs = JSON.parse(localStorage.getItem('connectedAs'));
      if (connectedAs) {
        const enterprise = JSON.parse(localStorage.getItem('connectedAsEnterprise'));
        enterprise_id = enterprise.id;
      } else {
        const enterprise = JSON.parse(localStorage.getItem('enterprise'));
        enterprise_id = enterprise.id;
      }
    }
    this.usersService.connectedAs(user_id, enterprise_id).subscribe(response => {
      if (response['success']) {
        localStorage.setItem('connectedAs', 'true');
        localStorage.setItem('connectedAsRefreshToken', response['refreshToken']);
        localStorage.setItem('connectedAsAccessToken', response['accessToken']);

        localStorage.setItem('connectedAsEnterprises', JSON.stringify(response['enterprises']));
        localStorage.setItem('connectedAsIsOnlyProvider', JSON.stringify(response['is_only_provider']));
        localStorage.setItem('connectedAsEnterprise', JSON.stringify(response['enterprise']));
        localStorage.setItem('connectedAsIsAdmin', JSON.stringify(response['is_admin']));
        localStorage.setItem('connectedAsUser', JSON.stringify(response['user']));
        localStorage.setItem('connectedAsRegistries', JSON.stringify(response['registries']));
        localStorage.setItem('connectedAsIsMonoOffer', JSON.stringify(response['is_mono_offer']));
        localStorage.setItem('connectedAsIsDiscoverOffer', JSON.stringify(response['is_discover_offer']));
        if (response['is_discover_offer']) {
          localStorage.setItem('connectedAsDiscoverDate', JSON.stringify(response['discover_date']));
        } else {
          localStorage.removeItem('connectedAsDiscoverDate');
        }
        this.authService.changeEnterprises(response['enterprises']);
        this.authService.changeEnterprise(response['enterprise']);
        this.authService.changeProvider(response['is_provider']);
        this.authService.changeOnlyProvider(response['is_only_provider']);
        this.authService.changeAdmin(response['is_admin']);
        this.authService.changeUser(response['user']);
        this.authService.changeRegistries(response['registries']);
        this.authService.changeMonoOffer(response['is_mono_offer']);
        this.authService.changeDiscoverOffer(response['is_discover_offer']);
        if (response['is_discover_offer']) {
          this.authService.changeDiscoverDate(response['discover_date']);
        } else {
          this.authService.changeDiscoverDate(null);
        }
        if (!response['is_admin']) {
          if (this.authService.checkUrlStats() !== null) {
            this.router.navigate([this.authService.checkUrlStats()]).then();
          }
          this.authService.checkUrlStats(response['registries']);
        }
      }
    });
  }

  deleteEnterprise(user_id) {
    this.usersService.deleteUserEnterprise(this.activatedRoute.snapshot.params.enterprise_id, user_id).subscribe(response => {
      if (response['success']) {
        CustomNotifierService.getSuccessDelete(this.notifier);
        this.getUsers();
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrors(this.errors, this.notifier);
      }
    });
  }

  deleteProvider(user_id) {
    this.usersService.removeUserProvider(this.activatedRoute.snapshot.params.enterprise_id, user_id).subscribe(response => {
      if (response['success']) {
        CustomNotifierService.getSuccessDelete(this.notifier);
        this.getUsers();
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrors(this.errors, this.notifier);
      }
    });
  }

  getAccess(user) {
    if (this.enterprise.provider && !this.enterprise.provider.access_to_enterprise) {
      return 'scoring-red';
    } else if (user.last_user_enterprise && !user.last_user_enterprise.access_to_enterprise) {
      return 'scoring-red';
    } else if (!user.hasCheckpoints && this.view === 'provider') {
      return 'scoring-red';
    }
    return 'scoring-green';
  }
}
