import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AllPrescriptionsModule } from './all-prescriptions/all-prescriptions.module';
import { UpdatePrescriptionModule } from './update-prescription/update-prescription.module';
import { UpdatePrescriptionStateModule } from './update-prescription-state/update-prescription-state.module';
import { UploadPrescriptionFileModule } from './upload-prescription-file/upload-prescription-file.module';

import { PrescriptionsRequestService } from '../../services/prescriptions.request.service';

@NgModule({
  imports: [
    CommonModule,
    AllPrescriptionsModule,
    UpdatePrescriptionModule,
    UploadPrescriptionFileModule,
    UpdatePrescriptionStateModule
  ],
  providers: [PrescriptionsRequestService]
})
export class PrescriptionsModule {}
