<app-modal-right (eventOnClose)="closeModal()" #addRegistries>{{ bindModal(addRegistries) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">Définir vos registres</h6>

        <h1 class="header-title">Registre</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <form [formGroup]="establishmentRegistriesForm" [hidden]="loaderContent">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="validationRegistries">Liste des registres à votre disposition</label>
            <small class="form-text text-muted">Registres utilisés : <span style="color: #e7332A" *ngIf="quotas && offer">{{ quotas.length + '/' + (offer.registry_offers.length + offer.registry_limit) }}</span></small>

            <select class="form-control" id="validationRegistries" formControlName="id" *ngIf="quotas && (quotas.length < (offer.registry_offers.length + offer.registry_limit))">
              <option value="" [selected]="!id.value">Sélectionnez un registre</option>
              <ng-container *ngFor="let registry of registries">
                <option [value]="registry.id">{{ registry.name }}</option>
              </ng-container>
            </select>
          </div>
        </div>
      </div>
    </form>

    <div class="card" *ngFor="let quota of quotas">
      <div class="card-body">
        <div class="d-flex align-content-center justify-content-between">
          <h4 class="card-title mb-0 name align-self-center">
            {{ quota['registry']['name'] }}
          </h4>

          <a class="href" (click)="deleteRegistry(quota['registry']['id'])">
            <i class="fe fe-trash"></i>
          </a>
        </div>
      </div>
    </div>
  </div>

  <div modal-footer class="w-100">
    <button class="btn btn-primary w-100" (click)="addEstablishmentRegistries()" [ngClass]="{ 'is-loading is-loading-sm': loader }" [disabled]="loader || establishmentRegistriesForm.invalid">
      Valider
    </button>
  </div>
</app-modal-right>
