<app-modal-right [width]="'85%'" (eventOnClose)="closeModal()" #userModal>{{ bindModal(userModal) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">{{ isNew ? 'Création' : canUpdate ? 'Mise à jour' : 'Consultation' }}</h6>

        <h1 class="header-title">Utilisateur</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <div [hidden]="loaderContent">
      <ul class="nav nav-tabs mb-3 pt-0" id="myTab" role="tablist" *ngIf="showAccessForm">
        <li class="nav-item col-6">
          <a class="nav-link pt-0 href" [ngClass]="{ 'active': activeTab === 'user'}" (click)="changeTabs('user')">Utilisateur</a>
        </li>
        <li class="nav-item col-6">
          <a class="nav-link pt-0 href" [ngClass]="{ 'active': activeTab === 'access'}" (click)="changeTabs('access')">Accès</a>
        </li>
      </ul>

      <div *ngIf="!loader && isProvider && isNew" class="alert alert-primary" role="alert">
        Attention, si vous n'ajoutez pas d'utilisateur à ce prestataire, vous ne pourrez pas assigner de rapport/installation/prescription à ce prestataire
      </div>

      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade" id="user" role="tabpanel" [ngClass]="{ 'show active': activeTab === 'user'}">
          <form class="mb-4" [formGroup]="emailForm" *ngIf="!loaderContent && showEmailForm">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label for="validationSearch" required>Email</label>
                  <input type="text" formControlName="email" class="form-control" id="validationSearch"
                         placeholder="Email" maxlength="255"
                         [ngClass]="{ 'is-invalid': emailSearch.invalid && (emailSearch.dirty || emailSearch.touched) }" required>
                  <div *ngIf="emailSearch.invalid && (emailSearch.dirty || emailSearch.touched)" class="invalid-feedback">
                    {{ getErrorMessage("email") }}
                  </div>
                </div>

                <button class="btn btn-primary w-100" (click)="checkEmail()"
                        [ngClass]="{ 'is-loading is-loading-sm': loader }" [disabled]="loader || emailForm.invalid">
                  Rechercher
                </button>
              </div>
            </div>
          </form>

          <form [formGroup]="userForm" *ngIf="!loaderContent && showUserForm">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label for="validationFirstname" required>Prénom</label>
                  <input type="text" formControlName="firstname" class="form-control" id="validationFirstname" placeholder="Prénom" [ngClass]="{ 'is-invalid': firstname.invalid && (firstname.dirty || firstname.touched) }" maxlength="255">
                  <div *ngIf="firstname.invalid && (firstname.dirty || firstname.touched)" class="invalid-feedback">
                    {{ getErrorMessage("firstname") }}
                  </div>
                </div>

                <div class="form-group">
                  <label for="validationLastname" required>Nom de famille</label>
                  <input type="text" formControlName="lastname" class="form-control" id="validationLastname" placeholder="Nom de famille" [ngClass]="{ 'is-invalid': lastname.invalid && (lastname.dirty || lastname.touched) }" maxlength="255">
                  <div *ngIf="lastname.invalid && (lastname.dirty || lastname.touched)" class="invalid-feedback">
                    {{ getErrorMessage("lastname") }}
                  </div>
                </div>

                <div class="form-group">
                  <label for="validationPhone">Téléphone</label>
                  <input type="text" formControlName="phone" class="form-control" id="validationPhone" placeholder="Téléphone" [ngClass]="{ 'is-invalid': phone.invalid && (phone.dirty || phone.touched) }" maxlength="255">
                  <div *ngIf="phone.invalid && (phone.dirty || phone.touched)" class="invalid-feedback">
                    {{ getErrorMessage("phone") }}
                  </div>
                </div>

                <div class="form-group">
                  <label for="validationEmail" required>Email</label>
                  <input type="text" formControlName="email" class="form-control" id="validationEmail" placeholder="Email" [ngClass]="{ 'is-invalid': email.invalid && (email.dirty || email.touched) }" maxlength="255">
                  <div *ngIf="email.invalid && (email.dirty || email.touched)" class="invalid-feedback">
                    {{ getErrorMessage("email") }}
                  </div>
                </div>

                <div class="form-group" [hidden]="!isNew && !canUpdate || picture.value">
                  <label for="inputFile" class="mb-1">Photo Utilisateur</label>

                  <small class="form-text text-muted">
                    Veuillez utiliser une image ne dépassant pas 200px * 200px.
                  </small>

                  <div class="dropzone dropzone-multiple dz-clickable" appDragDrop (dropHandler)="onFileChange($event)">
                    <div class="dz-default dz-message" (click)="chooseFile()">
                      <span>Déposer votre fichier ici pour télécharger</span>
                    </div>
                    <input id="inputFile" (change)="onFileChange($event)" type="file" accept="image/*" [hidden]="true">
                  </div>
                </div>

                <div class="form-group" *ngIf="picture.value">
                  <label class="mb-1">Photo Utilisateur</label>

                  <small class="form-text text-muted">
                    Veuillez utiliser une image ne dépassant pas 200px * 200px.
                  </small>

                  <div class="card">
                    <div class="card-body">
                      <div class="d-flex align-items-center justify-content-between">
                        <div class="avatar avatar-lg avatar-4by3">
                          <img src="{{ checkPicture() }}" alt="Photo utilisateur" class="avatar-img rounded">
                        </div>

                        <a class="href tooltipHtml" data-placement="bottom" title="Supprimer" (click)="removeFile()" *ngIf="isNew || canUpdate">
                          <i class="fe fe-trash"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group" *ngIf="showRoleSelect">
                  <label required>Rôle</label>
                  <ng-select [items]="roles"
                             bindLabel="name"
                             bindValue="id"
                             notFoundText="Aucun résultat"
                             typeToSearchText="Taper pour rechercher"
                             placeholder="Sélectionner un rôle"
                             formControlName="role_id"
                             [disabled]="!isNew && !canUpdate">
                  </ng-select>
                </div>

                <!-- ACCESS TO ENTERPRISE -->
                <div class="form-group d-flex justify-content-start align-items-center" *ngIf="showRoleSelect">
                  <div class="col pl-0">
                    Donner accès à BatiRegistre
                  </div>
                  <div class="custom-checkbox-toggle">
                    <input type="checkbox" class="custom-control-input" id="validationAccess" formControlName="access_to_enterprise" [ngClass]="{ 'backgroundColor': access_to_enterprise.value }">
                    <label class="custom-control-label" [ngClass]="{ 'backgroundColor': access_to_enterprise.value }" for="validationAccess"></label>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div class="tab-pane fade" id="access" role="tabpanel" [ngClass]="{ 'show active': activeTab === 'access'}">
          <div class="row listAlias" *ngIf="registries.length && establishments.length">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h2 class="col">Établissements / Registres</h2>
                  </div>
                </div>
                <div class="table-responsive">
                  <table class="table table-nonfluid table-striped">
                    <thead>
                    <tr>
                      <th></th>
                      <th *ngFor="let registry of registries" [ngStyle]="{ 'background-color': registry.background_color, 'color': 'white'}">{{ registry.name }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <th>Tous les établissements</th>
                      <td *ngFor="let registry of registries" [ngStyle]="{ 'background-color': registry.background_color }">
                        <div class="d-flex justify-content-center">
                          <i class="fe fe-check-square fe-white" *ngIf="registry.checked; else notChecked" (click)="checkRegistry(registry, false)"></i>
                          <ng-template #notChecked>
                            <i class="fe fe-square fe-white" (click)="checkRegistry(registry, true)"></i>
                          </ng-template>
                        </div>
                      </td>
                    </tr>

                    <ng-container *ngFor="let establishment of establishments; let firstLevelIndex = index; let first = first; let last = last">
                      <!-- GROUP LEVEL 1 -->
                      <ng-container *ngIf="establishment.is_group">
                        <tr class="divider" *ngIf="first"></tr>
                        <tr>
                          <th class="d-flex align-items-center">
                            <a class="href" (click)="toggleLevel1(firstLevelIndex)">
                              <i class="fe fe-chevron-right" *ngIf="establishment.collapsed"></i>
                              <i class="fe fe-chevron-down" *ngIf="!establishment.collapsed"></i>
                              {{ establishment.name }}
                            </a>
                          </th>
                          <td *ngFor="let registry of registries" [ngStyle]="{ 'background-color': registry.background_color }">
                            <div class="d-flex justify-content-center" *ngIf="checkIfGroupRegistry(establishment, registry)">
                              <i class="fe fe-check-square fe-white" *ngIf="checkGroup(establishment, registry); else notChecked" (click)="checkGroupRegistry(registry, establishment, false)"></i>
                              <ng-template #notChecked>
                                <i class="fe fe-square fe-white" (click)="checkGroupRegistry(registry, establishment, true)"></i>
                              </ng-template>
                            </div>
                          </td>
                        </tr>

                        <!-- ESTABLISHMENT LEVEL 1 -->
                        <ng-container *ngFor="let level1 of establishment.establishment_groups; let secondLevelIndex = index">
                          <!-- GROUP LEVEL 2 -->
                          <ng-container *ngIf="level1.is_group && !establishment.collapsed">
                            <tr>
                              <th class="d-flex align-items-center">
                                <a class="href" (click)="toggleLevel2(firstLevelIndex, secondLevelIndex)">
                                  <i class="fe fe-minus mr-2"></i>
                                  <i class="fe fe-chevron-right" *ngIf="level1.collapsed"></i>
                                  <i class="fe fe-chevron-down" *ngIf="!level1.collapsed"></i>
                                  {{ level1.name }}
                                </a>
                              </th>
                              <td *ngFor="let registry of registries" [ngStyle]="{ 'background-color': registry.background_color }">
                                <div class="d-flex justify-content-center" *ngIf="checkIfGroupRegistry(level1, registry)">
                                  <i class="fe fe-check-square fe-white" *ngIf="checkGroup(level1, registry); else notChecked" (click)="checkGroupRegistry(registry, level1, false)"></i>
                                  <ng-template #notChecked>
                                    <i class="fe fe-square fe-white" (click)="checkGroupRegistry(registry, level1, true)"></i>
                                  </ng-template>
                                </div>
                              </td>
                            </tr>
                            <!-- ESTABLISHMENT LEVEL 2-->
                            <ng-container *ngIf="!level1.establishment_groups.length">
                              <tr *ngIf="!level1.collapsed"></tr>
                            </ng-container>
                            <ng-container *ngFor="let level2 of level1.establishment_groups">
                              <tr *ngIf="!level1.collapsed">
                                <th class="d-flex align-items-center">
                                  <i class="fe fe-minus mr-2"></i>
                                  <i class="fe fe-minus mr-2"></i>
                                  <div>
                                    <small *ngIf="level2.parent_id" style="font-size: 0.8em">{{ level2.parent.name }} <br></small>
                                    {{ level2.name }}
                                  </div>
                                </th>
                                <td *ngFor="let registry of registries" [ngStyle]="{ 'background-color': registry.background_color }">
                                  <div *ngIf="hasRegistry(level2.establishment_registries, registry)" class="d-flex justify-content-center">
                                    <i class="fe fe-check-square fe-white" *ngIf="getChecked(level2.establishment_registries, registry); else notChecked" (click)="groupCheck(level2.establishment_registries, registry)"></i>
                                    <ng-template #notChecked>
                                      <i class="fe fe-square fe-white" (click)="groupCheck(level2.establishment_registries, registry)"></i>
                                    </ng-template>
                                  </div>
                                </td>
                              </tr>
                            </ng-container>
                          </ng-container>

                          <!-- ESTABLISHMENT LEVEL 1 WITH NO GROUP -->
                          <ng-container *ngIf="!level1.is_group && !establishment.collapsed">
                            <tr>
                              <th class="d-flex align-items-center">
                                <i class="fe fe-minus mr-2"></i>
                                <div>
                                  <small *ngIf="level1.parent_id" style="font-size: 0.8em">{{ level1.parent.name }} <br></small>
                                  {{ level1.name }}
                                </div>
                              </th>
                              <td *ngFor="let registry of registries" [ngStyle]="{ 'background-color': registry.background_color }">
                                <div *ngIf="hasRegistry(level1.establishment_registries, registry)" class="d-flex justify-content-center">
                                  <i class="fe fe-check-square fe-white" *ngIf="getChecked(level1.establishment_registries, registry); else notChecked" (click)="groupCheck(level1.establishment_registries, registry)"></i>
                                  <ng-template #notChecked>
                                    <i class="fe fe-square fe-white" (click)="groupCheck(level1.establishment_registries, registry)"></i>
                                  </ng-template>
                                </div>
                              </td>
                            </tr>
                          </ng-container>
                        </ng-container>

                        <tr class="divider"></tr>
                      </ng-container>

                      <!-- ESTABLISHMENT WITH NO GROUP -->
                      <tr *ngIf="!establishment.is_group">
                        <th>
                          <small *ngIf="establishment.parent_id" style="font-size: 0.8em">{{ establishment.parent.name }} <br></small>
                          {{ establishment.name }}
                        </th>
                        <td *ngFor="let registry of registries" [ngStyle]="{ 'background-color': registry.background_color }">
                          <div *ngIf="hasRegistry(establishment.establishment_registries, registry)" class="d-flex justify-content-center">
                            <i class="fe fe-check-square fe-white" *ngIf="getChecked(establishment.establishment_registries, registry); else notChecked" (click)="check(establishment.establishment_registries, registry)"></i>
                            <ng-template #notChecked>
                              <i class="fe fe-square fe-white" (click)="check(establishment.establishment_registries, registry)"></i>
                            </ng-template>
                          </div>
                        </td>
                      </tr>

                    </ng-container>

                    </tbody>
                  </table>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div modal-footer class="w-100 d-flex justify-content-between" *ngIf="showAccessForm">
    <button class="btn btn-primary w-30" [disabled]="activeTab === 'user'" (click)="prevTabs()">
      Précédent
    </button>

    <button class="btn btn-primary w-30" *ngIf="activeTab === 'user'" (click)="nextTabs()">
      Suivant
    </button>

    <button class="btn btn-primary w-30" *ngIf="activeTab === 'access'" (click)="isNew ? (exist ? addUser() : createUser()) : updateUser()"
            [ngClass]="{ 'is-loading is-loading-sm': loader }" [disabled]="loader || userForm.invalid || (!isNew && !canUpdate)">
      {{ isNew ? 'Créer' : 'Mettre à jour' }}
    </button>
  </div>
)
  <div modal-footer class="w-100" *ngIf="(isNew || canUpdate) && !showAccessForm">
    <button class="btn btn-primary w-100" (click)="isNew ? (exist ? addUser() : createUser()) : updateUser()"
            [ngClass]="{ 'is-loading is-loading-sm': loader }" [disabled]="loader || userForm.invalid">
      {{ isNew ? 'Créer' : 'Mettre à jour' }}
    </button>
  </div>
</app-modal-right>
