import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { HttpClient } from '@angular/common/http';
import { NotifierService } from 'angular-notifier';

@Injectable()
export class EnterprisesRequestService extends RequestService {
  constructor(
    protected http: HttpClient,
    protected notifier: NotifierService
  ) {
    super(http, notifier);
  }

  /// GET
  public getEnterprises(search, sort, order, limit, page) {
    return this.get('enterprises?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getProviders(search, sort, order, limit, page) {
    return this.get('providers?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getProvidersRegistryEstablishment(search, sort, order, limit, page, registry_id, establishment_id) {
    return this.get('providers/registry/' + registry_id + '/establishment/' + establishment_id +
      '?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getProvider(enterprise_id) {
    return this.get('provider/' + enterprise_id);
  }
  public getEnterprise(enterprise_id) {
    return this.get('enterprise/' + enterprise_id);
  }
  public getMine() {
    return this.get('enterprise/mine');
  }
  public getCustomers(search, sort, order, limit, page) {
    return this.get('customers?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getProviderContracts(search, sort, order, limit, page, enterprise_id) {
    return this.get('provider/' + enterprise_id + '/contracts?' +
      'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getProviderContract(enterprise_id, contract_id) {
    return this.get('provider/' + enterprise_id + '/contract/' + contract_id);
  }

  /// CHECK
  public checkSiret(siret) {
    return this.post('enterprise/check-siret', { siret });
  }
  public checkEmail(email) {
    return this.post('enterprise/check-email', { email });
  }

  /// CREATE
  public createEnterprise(enterprise) {
    return this.post('enterprise', { enterprise });
  }
  public createProvider(enterprise) {
    return this.post('provider', { enterprise });
  }
  public createProviderContract(enterprise_id, contract) {
    return this.post('provider/' + enterprise_id + '/contract', { contract });
  }

  /// UPDATE
  public updateEnterprise(enterprise_id, enterprise) {
    return this.put('enterprise/' + enterprise_id, { enterprise });
  }
  public updateMine(enterprise) {
    return this.put('enterprise/mine', { enterprise });
  }
  public updateProvider(enterprise_id, enterprise) {
    return this.put('provider/' + enterprise_id, { enterprise });
  }
  public updateProviderContract(enterprise_id, contract_id, contract) {
    return this.put('provider/' + enterprise_id + '/contract/' + contract_id, { contract });
  }

  /// ADD
  public addProvider(enterprise_id, enterprise) {
    return this.put('provider/add/' + enterprise_id, { enterprise });
  }

  /// DOWNLOAD PROVIDER CONTRACT
  public downloadProviderContractFile(enterprise_id, contract_id) {
    return this.get('provider/' + enterprise_id + '/contract/' + contract_id + '/download',
      { responseType: 'arraybuffer' });
  }

  /// REMOVE
  public removeProvider(enterprise_id) {
    return this.delete('provider/' + enterprise_id);
  }
  public deleteProviderContract(enterprise_id, contract_id) {
    return this.delete('provider/' + enterprise_id + '/contract/' + contract_id);
  }
}
