import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-planning',
  templateUrl: './planning.component.html',
  styleUrls: ['./planning.component.scss']
})
export class PlanningComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor() {}

  ngOnInit() {}

  ngAfterViewInit() {}

  ngOnDestroy() {}
}
