import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs';

import { AuthService } from '../../../core/auth/auth.service';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { RegistriesRequestService } from '../../../services/registries.request.service';
import { EstablishmentsRequestService } from '../../../services/establishments.request.service';
import { RvratsRequestService } from '../../../services/rvrats.request.service';
import { ContentsRequestService } from '../../../services/contents.request.service';

import Rvrat from '../../../../shared/interfaces/rvrat';
import BatiFile from '../../../../shared/interfaces/bati_file';
import Registry from '../../../../shared/interfaces/registry';
import Establishment from '../../../../shared/interfaces/establishment';

declare function Flatpickr(): any;

@Component({
  selector: 'app-all-rvrat',
  templateUrl: './all-rvrat.component.html',
  styleUrls: ['./all-rvrat.component.scss']
})
export class AllRvratComponent implements OnInit, AfterViewInit, OnDestroy {
  changeEnterprise: Subscription;

  canCreate = false;
  canUpdate = false;
  canDelete = false;
  canLog = false;

  requestInProgress = new Subject();
  requestCount = 0;

  pageName: string;
  previousPageName: string[] = [];
  previousPageRoute: string[] = [];
  worksheetId: any;
  currentRoute = '';
  rvratRoute = '';
  view = '';

  registry: Registry;
  establishment: Establishment;
  rvrats: Rvrat[] = [];
  files: BatiFile[] = [];
  content: any;

  periodicities = [
    {'id': 1, 'value' : '1 année(s)'},
    {'id': 2, 'value' : '2 année(s)'},
    {'id': 3, 'value' : '3 année(s)'},
    {'id': 4, 'value' : '4 année(s)'},
    {'id': 5, 'value' : '5 année(s)'},
  ];

  count = 0;
  pages = 1;

  search = '';
  sort = '';
  order = 'desc';
  limit = 12;
  page = 1;

  errors = {};

  timeout: any;
  loader = false;
  loaderDate = false;

  modalConfirmData = null;
  modalConfirmFile = null;

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private registriesService: RegistriesRequestService,
    private rvratsService: RvratsRequestService,
    private contentRequestService: ContentsRequestService,
    private establishmentsService: EstablishmentsRequestService,
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loader = this.requestCount > 0;
    });

    this.changeEnterprise = this.authService.getEnterprise().subscribe(() => {
      this.getRegistry();
      this.getEstablishment();
      this.getRvrats();
    });

    this.currentRoute = '/' + this.activatedRoute.snapshot.url.join('/') + '/';
    this.rvratRoute = '/dashboard-registry/' + this.activatedRoute.snapshot.params.registry_id +
      '/establishment/' + this.activatedRoute.snapshot.params.establishment_id;

    if (this.activatedRoute.snapshot.url[8] && this.activatedRoute.snapshot.url[8].path === 'files') {
      this.view = 'rvrat_files';
      this.sort = 'RvratFiles.name';
      this.pageName = 'Liste des documents';
    } else {
      this.view = 'rvrats';
      this.sort = 'Rvrats.date';
      this.pageName = 'Liste des rapports de vérification réglementaire après travaux';
    }

    this.worksheetId = this.activatedRoute.snapshot.params.worksheet_id;

    if (this.authService.loaded) {
      this.getRegistry();
      this.getEstablishment();
      this.getRvrats();
    }
    this.createBreadcrumbs();
  }

  ngAfterViewInit() {
    this.modalConfirmData.eventOnClose.subscribe(event => {
      if (event) {
        this.requestInProgress.next(true);
        let request;

        request = this.rvratsService.deleteRvrat(
          this.activatedRoute.snapshot.params.registry_id,
          this.activatedRoute.snapshot.params.establishment_id,
          event
        );

        request.subscribe(response => {
          if (response['success']) {
            this.refreshData();
            CustomNotifierService.getSuccessDelete(this.notifier);
          } else if (response['errors']) {
            CustomNotifierService.getErrors(response['errors'], this.notifier);
          }
          this.requestInProgress.next(false);
        });
      }
    });
    this.modalConfirmFile.eventOnClose.subscribe(event => {
      if (event) {
        this.requestInProgress.next(true);
        let request;

        request = this.rvratsService.deleteFile(
          this.activatedRoute.snapshot.params.registry_id,
          this.activatedRoute.snapshot.params.establishment_id,
          this.activatedRoute.snapshot.params.rvrat_id,
          event
        );

        request.subscribe(response => {
          if (response['success']) {
            this.refreshData();
            CustomNotifierService.getSuccessDelete(this.notifier);
          } else if (response['errors']) {
            CustomNotifierService.getErrors(response['errors'], this.notifier);
          }
          this.requestInProgress.next(false);
        });
      }
    });
  }

  ngOnDestroy() {
    const flatPicker = document.getElementsByClassName('flatpickr-calendar') as HTMLCollection;
    for (let i = flatPicker.length; i >= 0; i--) {
      if (flatPicker[i]) {
        flatPicker[i].remove();
      }
    }

    this.changeEnterprise.unsubscribe();
  }

  searchData() {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.page = 1;
      this.refreshData();
    }, 500);
  }

  refreshData(sort = this.sort, order = this.order, page = this.page) {
    this.sort = sort;
    this.order = order;
    this.page = page;
    this.getRvrats();
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  getRegistry() {
    this.requestInProgress.next(true);
    this.registriesService.getRegistry(this.activatedRoute.snapshot.params.registry_id).subscribe(response => {
      if (response['success']) {
        this.registry = response['registry'];
        this.createBreadcrumbs();
      } else if (response['errors']) {
        this.errors = response['errors'];
      }
      this.requestInProgress.next(false);
    });
  }

  getEstablishment() {
    this.requestInProgress.next(true);
    this.establishmentsService.getMyEstablishment(this.activatedRoute.snapshot.params.establishment_id).subscribe(response => {
      if (response['success']) {
        this.establishment = response['establishment'];
        this.createBreadcrumbs();
      } else if (response['errors']) {
        this.errors = response['errors'];
      }
      this.requestInProgress.next(false);
    });
  }

  getRvrats() {
    let request;
    this.requestInProgress.next(true);

    if (this.view === 'rvrat_files') {
      request = this.rvratsService.getFiles(
        this.search, this.sort, this.order, this.limit, this.page,
        this.activatedRoute.snapshot.params.registry_id,
        this.activatedRoute.snapshot.params.establishment_id,
        this.activatedRoute.snapshot.params.rvrat_id
      );
    } else {
      request = this.rvratsService.getAllRvrats(
        this.search, this.sort, this.order, this.limit, this.page,
        this.activatedRoute.snapshot.params.registry_id,
        this.activatedRoute.snapshot.params.establishment_id,
        this.activatedRoute.snapshot.params.worksheet_id
      );
    }

    request.subscribe(response => {
      if (response['success']) {
        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];
        this.canDelete = response['canDelete'];
        this.canLog = response['canLog'];

        if (response['rvrats']) {
          this.rvrats = response['rvrats'];
        } else {
          this.files = response['files'];
        }

        this.count = response['count'];
        this.pages = response['pages'];
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);

      if (!this.activatedRoute.children.length) {
        setTimeout(() => {
          Flatpickr();
        }, 250);
      }
    });
  }

  downloadFile(file_id, file_name) {
    let request;

    request = this.rvratsService.downloadFile(
      this.activatedRoute.snapshot.params.registry_id,
      this.activatedRoute.snapshot.params.establishment_id,
      this.activatedRoute.snapshot.params.rvrat_id,
      file_id
    );

    request.subscribe((response: BufferSource) => {
      try {
        const decoder = new TextDecoder('utf-8');
        const domString = decoder.decode(response);
        const json = JSON.parse(domString);

        CustomNotifierService.getErrors(json['errors'], this.notifier);
      } catch (e) {
        const blob = new Blob([response]);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = file_name;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      }
    });
  }

  filter(property) {
    if (this.sort === property) {
      if (this.order === 'asc') {
        this.order = 'desc';
      } else {
        this.order = 'asc';
      }
    } else {
      this.sort = property;
      this.order = 'asc';
    }

    this.getRvrats();
  }

  createBreadcrumbs() {
    this.previousPageName = [];
    this.previousPageRoute = [];

    if (this.activatedRoute.snapshot.params.registry_id && !this.activatedRoute.snapshot.params.establishment_id) {
      this.previousPageName.push('Tableau de bord');
      this.previousPageRoute.push('/administration');

      this.previousPageName.push('Registres');
      this.previousPageRoute.push('/registries');
    } else {
      this.previousPageName.push('Tableau de bord');
      this.previousPageRoute.push('/');

      this.previousPageName.push(this.registry ? this.registry.name : 'Tableau de bord registre');
      this.previousPageRoute.push('/dashboard-registry/' + this.activatedRoute.snapshot.params.registry_id);

      this.previousPageName.push(this.establishment ? this.establishment.name : 'Tableau de bord établissement');
      this.previousPageRoute.push(this.rvratRoute);

      this.previousPageName.push('Fiche travaux');
      this.previousPageRoute.push(this.rvratRoute + '/worksheets');

      this.previousPageName.push('Type de fichier');
      this.previousPageRoute.push(this.rvratRoute + '/worksheet/' + this.activatedRoute.snapshot.params.worksheet_id);

      if (this.view === 'rvrat_files') {
        this.previousPageName.push('Rapport de vérification réglementaire après travaux');
        this.previousPageRoute.push('/dashboard-registry/' + this.activatedRoute.snapshot.params.registry_id +
          '/establishment/' + this.activatedRoute.snapshot.params.establishment_id + '/worksheet/' +
          this.activatedRoute.snapshot.params.worksheet_id + '/rvrat');
      }
    }
  }

  dispatchPagination(data) {
    if (data.action === 'total_elements') {
      this.refreshPage(data.value);
    } else if (data.action === 'change_page') {
      this.changePage(data.value);
    }
  }

  refreshPage(limit) {
    this.limit = limit;
    this.page = 1;
    this.refreshData();
  }

  changePage(page) {
    if (page >= 1 && page <= this.pages) {
      this.page = page;
      this.getRvrats();
    }
  }

  bindModalConfirmData(modal) {
    this.modalConfirmData = modal;
  }
  bindModalConfirmFile(modal) {
    this.modalConfirmFile = modal;
  }
}
