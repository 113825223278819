<app-modal-right (eventOnClose)="closeModal()" #prevention>{{ bindModal(prevention) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">{{ isNew ? 'Création' : canUpdate ? 'Mise à jour' : 'Consultation' }}</h6>

        <h1 class="header-title">Protocole de sécurité</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <form [formGroup]="securityProtocolForm" [hidden]="loaderContent">

      <ul class="nav nav-tabs" role="tablist">
        <li class="nav-item col-3">
          <a class="nav-link href" [ngClass]="{ 'active': activeTab === 1}" (click)="changeTabs(1)">
            1 <i class="fe fe-info ml-2 tooltipHtml" title="Déroulement de l’opération"></i>
          </a>
        </li>
        <li class="nav-item col-3">
          <a class="nav-link href" [ngClass]="{ 'active': activeTab === 2}" (click)="changeTabs(2)">
            2 <i class="fe fe-info ml-2 tooltipHtml" title="Informations concernant l’entreprise d’accueil et les moyens mis à disposition"></i>
          </a>
        </li>
        <li class="nav-item col-3">
          <a class="nav-link href" [ngClass]="{ 'active': activeTab === 3}" (click)="changeTabs(3)">
            3 <i class="fe fe-info ml-2 tooltipHtml" title="Informations concernant l’entreprise de transport et la marchandise"></i>
          </a>
        </li>
        <li class="nav-item col-3">
          <a class="nav-link href" [ngClass]="{ 'active': activeTab === 4}" (click)="changeTabs(4)">
            4 <i class="fe fe-info ml-2 tooltipHtml" title="Consignes générales de sécurité"></i>
          </a>
        </li>
      </ul>

      <div class="tab-content">

        <!-- 1 - OPERATION -->
        <div class="tab-pane fade" [ngClass]="{ 'show active': activeTab === 1}">

          <!-- REFERENCY -->
          <div class="form-group">
            <label for="validationReferency">Référence</label>
            <input type="text" formControlName="referency" class="form-control" id="validationReferency" placeholder="Référence" [ngClass]="{ 'is-invalid': referency.invalid && (referency.dirty || referency.touched) }">
            <div *ngIf="referency.invalid && (referency.dirty || referency.touched)" class="invalid-feedback">
              {{ getErrorMessage("referency") }}
            </div>
          </div>

          <!-- OPERATION -->
          <div formGroupName="operation" *ngIf="securityProtocolForm['controls'].operation['controls'] as operation">
            <!-- NATURE -->
            <div class="form-group">
              <label for="nature">Nature de l'opération</label>
              <input type="text" formControlName="nature" id="nature" class="form-control" placeholder="Nature de l'opération">
            </div>

            <!-- LOADING -->
            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Chargement</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="loading" formControlName="loading" [ngClass]="{ 'backgroundColor': operation['loading'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': operation['loading'].value }" for="loading"></label>
              </div>
            </div>

            <!-- UNLOADING -->
            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Déchargement</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="unloading" formControlName="unloading" [ngClass]="{ 'backgroundColor': operation['unloading'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': operation['unloading'].value }" for="unloading"></label>
              </div>
            </div>

            <!-- DATE -->
            <div class="form-group">
              <label for="begin_date">Date</label>
              <input type="text" formControlName="begin_date" id="begin_date" data-toggle="flatpickr" class="form-control" placeholder="Date">
            </div>

            <!-- SCHEDULES -->
            <div class="form-group">
              <label for="schedules">Horaires</label>
              <input type="text" formControlName="schedules" id="schedules" class="form-control" placeholder="Horaires">
            </div>

            <!--REPETITIVE OPERATION -->
            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Opérations répétitives</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="repetitive_operations" formControlName="repetitive_operations" [ngClass]="{ 'backgroundColor': operation['repetitive_operations'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': operation['repetitive_operations'].value }" for="repetitive_operations"></label>
              </div>
            </div>

            <!-- PUNCTUAL OPERATION -->
            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Opérations ponctuelles</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="punctual_operations" formControlName="punctual_operations" [ngClass]="{ 'backgroundColor': operation['punctual_operations'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': operation['punctual_operations'].value }" for="punctual_operations"></label>
              </div>
            </div>

            <!-- PRECISIONS -->
            <div class="form-group">
              <label for="precisions">Précisions/remarques diverses</label>
              <input type="text" formControlName="precisions" id="precisions" class="form-control" placeholder="Précisions/remarques diverses">
            </div>

            <p class="header-title my-4">Responsable de l’opération de chargement/déchargement</p>

            <!-- HOME MANAGER -->
            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Opération réalisée par l’entreprise d’accueil</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="home_manager" formControlName="home_manager" [ngClass]="{ 'backgroundColor': operation['home_manager'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': operation['home_manager'].value }" for="home_manager"></label>
              </div>
            </div>
            <p *ngIf="operation['home_manager'].value" class="header-title my-4">Responsable</p>
            <!-- HOME MANAGER FIRST NAME -->
            <div class="form-group" *ngIf="operation['home_manager'].value">
              <label for="home_manager_first_name">Prénom</label>
              <input type="text" formControlName="home_manager_first_name" id="home_manager_first_name" class="form-control" placeholder="Prénom">
            </div>
            <!-- HOME MANAGER LAST NAME -->
            <div class="form-group" *ngIf="operation['home_manager'].value">
              <label for="home_manager_last_name">Nom</label>
              <input type="text" formControlName="home_manager_last_name" id="home_manager_last_name" class="form-control" placeholder="Nom">
            </div>

            <!-- TRANSPORT MANAGER -->
            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Opération réalisée par l’entreprise de transport</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="transport_manager" formControlName="transport_manager" [ngClass]="{ 'backgroundColor': operation['transport_manager'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': operation['transport_manager'].value }" for="transport_manager"></label>
              </div>
            </div>
            <p *ngIf="operation['transport_manager'].value" class="header-title my-4">Responsable</p>
            <!-- TRANSPORT MANAGER FIRST NAME -->
            <div class="form-group" *ngIf="operation['transport_manager'].value">
              <label for="transport_manager_first_name">Prénom</label>
              <input type="text" formControlName="transport_manager_first_name" id="transport_manager_first_name" class="form-control" placeholder="Prénom">
            </div>
            <!-- TRANSPORT MANAGER LAST NAME -->
            <div class="form-group" *ngIf="operation['transport_manager'].value">
              <label for="transport_manager_last_name">Nom</label>
              <input type="text" formControlName="transport_manager_last_name" id="transport_manager_last_name" class="form-control" placeholder="Nom">
            </div>

          </div>

          <!-- DOCUMENTS -->
          <h2 class="header-title mb-3">Documents</h2>
          <div class="form-group" *ngIf="isNew || canUpdate">
            <div class="dropzone dropzone-multiple dz-clickable" appDragDrop (dropHandler)="onFileChange($event)">
              <div class="dz-default dz-message" (click)="chooseFile()">
                <span>Déposer vos fichiers ici pour télécharger</span>
              </div>
              <input id="inputFile" (change)="onFileChange($event)" type="file" [hidden]="true" multiple>
            </div>
          </div>
          <div class="card" *ngFor="let file of files; let index = index">
            <div class="card-body">
              <div class="d-flex align-content-center justify-content-between">
                <h4 class="card-title mb-0 name align-self-center">
                  {{ file.name + '.' + file.extension }}
                </h4>

                <a class="href tooltipHtml" data-placement="bottom" title="Supprimer" (click)="removeFile(index)" *ngIf="isNew || canUpdate">
                  <i class="fe fe-trash"></i>
                </a>
              </div>
            </div>
          </div>

        </div>

        <!-- 2 - HOME BUSINESS -->
        <div class="tab-pane fade" [ngClass]="{ 'show active': activeTab === 2}">

          <h2 class="header-title my-4">Entreprise d'accueil</h2>
          <div formGroupName="home_business" *ngIf="securityProtocolForm['controls'].home_business['controls'] as home_business">

            <!-- SOCIAL REASON -->
            <div class="form-group">
              <label for="social_reason"><strong>Raison sociale</strong></label>
              <input type="text" formControlName="social_reason" id="social_reason" class="form-control font_strong" placeholder="Raison sociale">
            </div>

            <!-- ADDRESS -->
            <div class="form-group">
              <label for="address">Adresse</label>
              <input type="text" formControlName="address" id="address" class="form-control" placeholder="Adresse">
            </div>

            <!-- PHONE -->
            <div class="form-group">
              <label for="phone">Téléphone</label>
              <input type="text" formControlName="phone" id="phone" class="form-control" placeholder="Téléphone">
            </div>

            <!-- EMAIL -->
            <div class="form-group">
              <label for="email">Email</label>
              <input type="text" formControlName="email" id="email" class="form-control" placeholder="Email">
            </div>

            <!-- REPRESENTED BY -->
            <div class="form-group">
              <label for="represented_by">Représentée par</label>
              <input type="text" formControlName="represented_by" id="represented_by" class="form-control" placeholder="Représentée par">
            </div>

            <!-- FOLLOWER -->
            <div class="form-group">
              <label for="follower">Personnes en charge de suivre l'opération</label>
              <input type="text" formControlName="follower" id="follower" class="form-control" placeholder="Personnes en charge de suivre l'opération">
            </div>

            <!-- ABSENCE -->
            <div class="form-group">
              <label for="absence">En cas d'absence</label>
              <input type="text" formControlName="absence" id="absence" class="form-control" placeholder="En cas d'absence">
            </div>

            <!-- OCCUPATIONAL DOCTOR -->
            <div class="form-group">
              <label for="occupational_doctor">Médecin du travail</label>
              <input type="text" formControlName="occupational_doctor" id="occupational_doctor" class="form-control" placeholder="Médecin du travail">
            </div>

          </div>

          <h2 class="header-title my-4">Moyens mis à disposition pour l'opération de chargement / déchargement</h2>
          <div formGroupName="loading_materials" *ngIf="securityProtocolForm['controls'].loading_materials['controls'] as loading_materials">

            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Chariot élévateur</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="forklift" formControlName="forklift" [ngClass]="{ 'backgroundColor': loading_materials['forklift'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': loading_materials['forklift'].value }" for="forklift"></label>
              </div>
            </div>

            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Transpalette manuel</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="hand_pallet_truck" formControlName="hand_pallet_truck" [ngClass]="{ 'backgroundColor': loading_materials['hand_pallet_truck'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': loading_materials['hand_pallet_truck'].value }" for="hand_pallet_truck"></label>
              </div>
            </div>

            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Transpalette électrique</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="electric_pallet_truck" formControlName="electric_pallet_truck" [ngClass]="{ 'backgroundColor': loading_materials['electric_pallet_truck'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': loading_materials['electric_pallet_truck'].value }" for="electric_pallet_truck"></label>
              </div>
            </div>

            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Hayon élévateur</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="liftgate" formControlName="liftgate" [ngClass]="{ 'backgroundColor': loading_materials['liftgate'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': loading_materials['liftgate'].value }" for="liftgate"></label>
              </div>
            </div>

            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Diable</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="hand_truck" formControlName="hand_truck" [ngClass]="{ 'backgroundColor': loading_materials['hand_truck'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': loading_materials['hand_truck'].value }" for="hand_truck"></label>
              </div>
            </div>

            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Chariot manuel</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="manual_trolley" formControlName="manual_trolley" [ngClass]="{ 'backgroundColor': loading_materials['manual_trolley'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': loading_materials['manual_trolley'].value }" for="manual_trolley"></label>
              </div>
            </div>

            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Pont roulant</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="overhead_crane" formControlName="overhead_crane" [ngClass]="{ 'backgroundColor': loading_materials['overhead_crane'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': loading_materials['overhead_crane'].value }" for="overhead_crane"></label>
              </div>
            </div>

            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Autre</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="other" formControlName="other" [ngClass]="{ 'backgroundColor': loading_materials['other'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': loading_materials['other'].value }" for="other"></label>
              </div>
            </div>
            <div class="form-group" *ngIf="loading_materials['other'].value">
              <label>Préciser</label>
              <textarea type="text" formControlName="other_detail" class="form-control" placeholder="Préciser"></textarea>
            </div>

          </div>

          <h2 class="header-title my-4">Moyens mis à disposition pour d'autres opérations annexes</h2>
          <div class="d-flex justify-content-center align-items-center mt-4" *ngIf="!securityProtocolForm.controls.other_materials.value.length">
            Aucun moyen
          </div>
          <div formArrayName="other_materials" class="mb-4">
            <div [formGroupName]="i" *ngFor="let material of securityProtocolForm['controls'].other_materials['controls'] as FormGroup; let i = index; let last = last" class="card">
              <div class="card-header">
                <div class="d-flex justify-content-between">
                  <a data-toggle="collapse" [href]="'#other_materials' + i" [attr.aria-controls]="'other_materials' + i" role="button" aria-expanded="true">
                    Moyen {{ i + 1 }}
                  </a>

                  <a class="href tooltipHtml" data-placement="bottom" title="Supprimer" (click)="deleteOtherMaterial(i)">
                    <i class="fe fe-trash"></i>
                  </a>
                </div>
              </div>

              <div class="card-body collapse show" [id]="'other_materials' + i">
                <div class="form-group">
                  <label>Moyen</label>
                  <input type="text" placeholder="Moyen" formControlName="name" class="form-control d-inline-block col-12" [ngClass]="{ 'is-invalid': material['controls'].name.invalid && (material['controls'].name.dirty || material['controls'].name.touched) }">
                  <div *ngIf="material['controls'].name.invalid && (material['controls'].name.dirty || material['controls'].name.touched)" class="invalid-feedback">
                    {{ getErrorMessage("name") }}
                  </div>
                </div>
              </div>

            </div>
          </div>
          <button class="btn btn-primary col-6 offset-3" (click)="addOtherMaterial()">
            Ajouter un moyen
          </button>

          <h2 class="header-title my-4">Moyens / équipements de secours mis à disposition</h2>
          <div class="d-flex justify-content-center align-items-center mt-4" *ngIf="!securityProtocolForm.controls.rescue_materials.value.length">
            Aucun moyen
          </div>
          <div formArrayName="rescue_materials" class="mb-4">
            <div [formGroupName]="i" *ngFor="let material of securityProtocolForm['controls'].rescue_materials['controls'] as FormGroup; let i = index; let last = last" class="card">
              <div class="card-header">
                <div class="d-flex justify-content-between">
                  <a data-toggle="collapse" [href]="'#rescue_materials' + i" [attr.aria-controls]="'rescue_materials' + i" role="button" aria-expanded="true">
                    Moyen {{ i + 1 }}
                  </a>

                  <a class="href tooltipHtml" data-placement="bottom" title="Supprimer" (click)="deleteRescueMaterial(i)">
                    <i class="fe fe-trash"></i>
                  </a>
                </div>
              </div>

              <div class="card-body collapse show" [id]="'rescue_materials' + i">
                <div class="form-group">
                  <label>Moyen</label>
                  <input type="text" placeholder="Moyen" formControlName="name" class="form-control d-inline-block col-12" [ngClass]="{ 'is-invalid': material['controls'].name.invalid && (material['controls'].name.dirty || material['controls'].name.touched) }">
                  <div *ngIf="material['controls'].name.invalid && (material['controls'].name.dirty || material['controls'].name.touched)" class="invalid-feedback">
                    {{ getErrorMessage("name") }}
                  </div>
                </div>
              </div>

            </div>
          </div>
          <button class="btn btn-primary col-6 offset-3" (click)="addRescueMaterial()">
            Ajouter un moyen
          </button>

          <h2 class="header-title my-4">Locaux mis à disposition</h2>
          <div class="d-flex justify-content-center align-items-center mt-4" *ngIf="!securityProtocolForm.controls.rooms_materials.value.length">
            Aucun local
          </div>
          <div formArrayName="rooms_materials" class="mb-4">
            <div [formGroupName]="i" *ngFor="let material of securityProtocolForm['controls'].rooms_materials['controls'] as FormGroup; let i = index; let last = last" class="card">
              <div class="card-header">
                <div class="d-flex justify-content-between">
                  <a data-toggle="collapse" [href]="'#rooms_materials' + i" [attr.aria-controls]="'rooms_materials' + i" role="button" aria-expanded="true">
                    Local {{ i + 1 }}
                  </a>

                  <a class="href tooltipHtml" data-placement="bottom" title="Supprimer" (click)="deleteRoomMaterial(i)">
                    <i class="fe fe-trash"></i>
                  </a>
                </div>
              </div>
              <div class="card-body collapse show" [id]="'rooms_materials' + i">
                <div class="form-group">
                  <!--<label>Local</label>-->
                  <input type="text" placeholder="Local" formControlName="name" class="form-control d-inline-block col-12" [ngClass]="{ 'is-invalid': material['controls'].name.invalid && (material['controls'].name.dirty || material['controls'].name.touched) }">
                  <div *ngIf="material['controls'].name.invalid && (material['controls'].name.dirty || material['controls'].name.touched)" class="invalid-feedback">
                    {{ getErrorMessage("name") }}
                  </div>
                </div>

              </div>
            </div>
          </div>
          <button class="btn btn-primary col-6 offset-3" (click)="addRoomMaterial()">
            Ajouter un Local
          </button>
        </div>

        <!-- 3 - TRANSPORT BUSINESS -->
        <div class="tab-pane fade" [ngClass]="{ 'show active': activeTab === 3}">
          <h2 class="header-title my-4">Entreprise de transport</h2>
          <div formGroupName="transport_business" *ngIf="securityProtocolForm['controls'].transport_business['controls'] as transport_business">

            <!-- SOCIAL REASON -->
            <div class="form-group">
              <label for="transport_social_reason"><strong>Raison sociale</strong></label>
              <input type="text" formControlName="social_reason" id="transport_social_reason" class="form-control font_strong" placeholder="Raison sociale">
            </div>

            <!-- ADDRESS -->
            <div class="form-group">
              <label for="transport_address">Adresse</label>
              <input type="text" formControlName="address" id="transport_address" class="form-control" placeholder="Adresse">
            </div>

            <!-- PHONE -->
            <div class="form-group">
              <label for="transport_phone">Téléphone</label>
              <input type="text" formControlName="phone" id="transport_phone" class="form-control" placeholder="Téléphone">
            </div>

            <!-- EMAIL -->
            <div class="form-group">
              <label for="transport_email">Email</label>
              <input type="text" formControlName="email" id="transport_email" class="form-control" placeholder="Email">
            </div>

            <!-- REPRESENTED BY -->
            <div class="form-group">
              <label for="transport_represented_by">Représentée par</label>
              <input type="text" formControlName="represented_by" id="transport_represented_by" class="form-control" placeholder="Représentée par">
            </div>

            <!-- FOLLOWER -->
            <div class="form-group">
              <label for="transport_follower">Personnes en charge de suivre l'opération</label>
              <input type="text" formControlName="follower" id="transport_follower" class="form-control" placeholder="Personnes en charge de suivre l'opération">
            </div>

            <!-- ABSENCE -->
            <div class="form-group">
              <label for="transport_absence">En cas d'absence</label>
              <input type="text" formControlName="absence" id="transport_absence" class="form-control" placeholder="En cas d'absence">
            </div>

            <!-- OCCUPATIONAL DOCTOR -->
            <div class="form-group">
              <label for="transport_occupational_doctor">Médecin du travail</label>
              <input type="text" formControlName="occupational_doctor" id="transport_occupational_doctor" class="form-control" placeholder="Médecin du travail">
            </div>

          </div>

          <h2 class="header-title my-4">Caractéristiques du véhicule</h2>
          <div formGroupName="vehicle" *ngIf="securityProtocolForm['controls'].vehicle['controls'] as vehicle">

            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Deux roues</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" formControlName="two_wheels" id="two_wheels" [ngClass]="{ 'backgroundColor': vehicle['two_wheels'].value }">
                <label for="two_wheels" class="custom-control-label" [ngClass]="{ 'backgroundColor': vehicle['two_wheels'].value }"></label>
              </div>
            </div>

            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Véhicule léger</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" formControlName="light_vehicle" id="light_vehicle" [ngClass]="{ 'backgroundColor': vehicle['light_vehicle'].value }">
                <label for="light_vehicle" class="custom-control-label" [ngClass]="{ 'backgroundColor': vehicle['light_vehicle'].value }"></label>
              </div>
            </div>

            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Camionette</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" formControlName="transporter" id="transporter" [ngClass]="{ 'backgroundColor': vehicle['transporter'].value }">
                <label for="transporter" class="custom-control-label" [ngClass]="{ 'backgroundColor': vehicle['transporter'].value }"></label>
              </div>
            </div>

            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Poids lourds < 3.5T</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" formControlName="heavy_goods_vehicle" id="heavy_goods_vehicle" [ngClass]="{ 'backgroundColor': vehicle['heavy_goods_vehicle'].value }">
                <label for="heavy_goods_vehicle" class="custom-control-label" [ngClass]="{ 'backgroundColor': vehicle['heavy_goods_vehicle'].value }"></label>
              </div>
            </div>

            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Poids lourds > 3.5T</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" formControlName="trucks" id="trucks" [ngClass]="{ 'backgroundColor': vehicle['trucks'].value }">
                <label for="trucks" class="custom-control-label" [ngClass]="{ 'backgroundColor': vehicle['trucks'].value }"></label>
              </div>
            </div>

            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Poids lourds avec remorque</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" formControlName="heavy_goods_vehicle_with_trailer" id="heavy_goods_vehicle_with_trailer" [ngClass]="{ 'backgroundColor': vehicle['heavy_goods_vehicle_with_trailer'].value }">
                <label for="heavy_goods_vehicle_with_trailer" class="custom-control-label" [ngClass]="{ 'backgroundColor': vehicle['heavy_goods_vehicle_with_trailer'].value }"></label>
              </div>
            </div>

            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Autre</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" formControlName="other" id="vehicle_other" [ngClass]="{ 'backgroundColor': vehicle['other'].value }">
                <label for="vehicle_other" class="custom-control-label" [ngClass]="{ 'backgroundColor': vehicle['other'].value }"></label>
              </div>
            </div>
            <div class="form-group" *ngIf="vehicle['other'].value">
              <label>Préciser</label>
              <textarea type="text" formControlName="other_detail" class="form-control" placeholder="Préciser"></textarea>
            </div>

          </div>

          <h2 class="header-title my-4">Aménagement du véhicule</h2>
          <div formGroupName="vehicle_facilities" *ngIf="securityProtocolForm['controls'].vehicle_facilities['controls'] as vehicle">

            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Plateau</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" formControlName="tray" id="tray" [ngClass]="{ 'backgroundColor': vehicle['tray'].value }">
                <label for="tray" class="custom-control-label" [ngClass]="{ 'backgroundColor': vehicle['tray'].value }"></label>
              </div>
            </div>

            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Benne</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" formControlName="tipper" id="tipper" [ngClass]="{ 'backgroundColor': vehicle['tipper'].value }">
                <label for="tipper" class="custom-control-label" [ngClass]="{ 'backgroundColor': vehicle['tipper'].value }"></label>
              </div>
            </div>

            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Bâche</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" formControlName="tarpaulin" id="tarpaulin" [ngClass]="{ 'backgroundColor': vehicle['tarpaulin'].value }">
                <label for="tarpaulin" class="custom-control-label" [ngClass]="{ 'backgroundColor': vehicle['tarpaulin'].value }"></label>
              </div>
            </div>

            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Fourgon</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" formControlName="van" id="van" [ngClass]="{ 'backgroundColor': vehicle['van'].value }">
                <label for="van" class="custom-control-label" [ngClass]="{ 'backgroundColor': vehicle['van'].value }"></label>
              </div>
            </div>

            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Carrossé</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" formControlName="bodied_vehicle" id="bodied_vehicle" [ngClass]="{ 'backgroundColor': vehicle['bodied_vehicle'].value }">
                <label for="bodied_vehicle" class="custom-control-label" [ngClass]="{ 'backgroundColor': vehicle['bodied_vehicle'].value }"></label>
              </div>
            </div>

            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Citerne</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" formControlName="tank" id="tank" [ngClass]="{ 'backgroundColor': vehicle['tank'].value }">
                <label for="tank" class="custom-control-label" [ngClass]="{ 'backgroundColor': vehicle['tank'].value }"></label>
              </div>
            </div>

            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Autre</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" formControlName="other" id="vehicle_facilities_other" [ngClass]="{ 'backgroundColor': vehicle['other'].value }">
                <label for="vehicle_facilities_other" class="custom-control-label" [ngClass]="{ 'backgroundColor': vehicle['other'].value }"></label>
              </div>
            </div>
            <div class="form-group" *ngIf="vehicle['other'].value">
              <label>Préciser</label>
              <textarea type="text" formControlName="other_detail" class="form-control" placeholder="Préciser"></textarea>
            </div>

          </div>

          <h2 class="header-title my-4">Equipement du véhicule</h2>
          <div formGroupName="vehicle_equipment" *ngIf="securityProtocolForm['controls'].vehicle_equipment['controls'] as vehicle">

            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Chariot élévateur</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" formControlName="forklift" id="vehicle_equipment_forklift" [ngClass]="{ 'backgroundColor': vehicle['forklift'].value }">
                <label for="vehicle_equipment_forklift" class="custom-control-label" [ngClass]="{ 'backgroundColor': vehicle['forklift'].value }"></label>
              </div>
            </div>

            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Transpalette manuel</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" formControlName="hand_pallet_truck" id="vehicle_equipment_hand_pallet_truck" [ngClass]="{ 'backgroundColor': vehicle['hand_pallet_truck'].value }">
                <label for="vehicle_equipment_hand_pallet_truck" class="custom-control-label" [ngClass]="{ 'backgroundColor': vehicle['hand_pallet_truck'].value }"></label>
              </div>
            </div>

            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Transpalette électrique</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" formControlName="electric_pallet_truck" id="vehicle_equipment_electric_pallet_truck" [ngClass]="{ 'backgroundColor': vehicle['electric_pallet_truck'].value }">
                <label for="vehicle_equipment_electric_pallet_truck" class="custom-control-label" [ngClass]="{ 'backgroundColor': vehicle['electric_pallet_truck'].value }"></label>
              </div>
            </div>

            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Diable</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" formControlName="hand_truck" id="vehicle_equipment_hand_truck" [ngClass]="{ 'backgroundColor': vehicle['hand_truck'].value }">
                <label for="vehicle_equipment_hand_truck" class="custom-control-label" [ngClass]="{ 'backgroundColor': vehicle['hand_truck'].value }"></label>
              </div>
            </div>

            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Chariot manuel</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" formControlName="manual_trolley" id="vehicle_equipment_manual_trolley" [ngClass]="{ 'backgroundColor': vehicle['manual_trolley'].value }">
                <label for="vehicle_equipment_manual_trolley" class="custom-control-label" [ngClass]="{ 'backgroundColor': vehicle['manual_trolley'].value }"></label>
              </div>
            </div>

            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Autre</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" formControlName="other" id="vehicle_equipment_other" [ngClass]="{ 'backgroundColor': vehicle['other'].value }">
                <label for="vehicle_equipment_other" class="custom-control-label" [ngClass]="{ 'backgroundColor': vehicle['other'].value }"></label>
              </div>
            </div>
            <div class="form-group" *ngIf="vehicle['other'].value">
              <label>Préciser</label>
              <textarea type="text" formControlName="other_detail" class="form-control" placeholder="Préciser"></textarea>
            </div>

          </div>

          <h2 class="header-title my-4">Informations concernant la marchandise transportée</h2>
          <div formGroupName="merchandise" *ngIf="securityProtocolForm['controls'].merchandise['controls'] as merchandise">

            <div class="form-group">
              <label for="description">Descriptif (nature, quantité, etc.)</label>
              <textarea type="text" formControlName="description" id="description" class="form-control" placeholder="Descriptif (nature, quantité, etc.)"></textarea>
            </div>

            <div formGroupName="conditioning" *ngIf="merchandise.conditioning['controls'] as conditioning">

              <div class="form-group d-flex justify-content-start align-items-center">
                <div class="col pl-0">Vrac</div>
                <div class="custom-checkbox-toggle">
                  <input type="checkbox" class="custom-control-input" id="vrac" formControlName="bulk" [ngClass]="{ 'backgroundColor': conditioning['bulk'].value }">
                  <label class="custom-control-label" [ngClass]="{ 'backgroundColor': conditioning['bulk'].value }" for="vrac"></label>
                </div>
              </div>

              <div class="form-group d-flex justify-content-start align-items-center">
                <div class="col pl-0">Big Bag</div>
                <div class="custom-checkbox-toggle">
                  <input type="checkbox" class="custom-control-input" id="bigBag" formControlName="big_bag" [ngClass]="{ 'backgroundColor': conditioning['big_bag'].value }">
                  <label class="custom-control-label" [ngClass]="{ 'backgroundColor': conditioning['big_bag'].value }" for="bigBag"></label>
                </div>
              </div>

              <div class="form-group d-flex justify-content-start align-items-center">
                <div class="col pl-0">Citerne</div>
                <div class="custom-checkbox-toggle">
                  <input type="checkbox" class="custom-control-input" id="citerne" formControlName="tank" [ngClass]="{ 'backgroundColor': conditioning['tank'].value }">
                  <label class="custom-control-label" [ngClass]="{ 'backgroundColor': conditioning['tank'].value }" for="citerne"></label>
                </div>
              </div>

              <div class="form-group d-flex justify-content-start align-items-center">
                <div class="col pl-0">Palettes</div>
                <div class="custom-checkbox-toggle">
                  <input type="checkbox" class="custom-control-input" id="palettes" formControlName="pallets" [ngClass]="{ 'backgroundColor': conditioning['pallets'].value }">
                  <label class="custom-control-label" [ngClass]="{ 'backgroundColor': conditioning['pallets'].value }" for="palettes"></label>
                </div>
              </div>

              <div class="form-group d-flex justify-content-start align-items-center">
                <div class="col pl-0">Caisses</div>
                <div class="custom-checkbox-toggle">
                  <input type="checkbox" class="custom-control-input" id="caisses" formControlName="crates" [ngClass]="{ 'backgroundColor': conditioning['crates'].value }">
                  <label class="custom-control-label" [ngClass]="{ 'backgroundColor': conditioning['crates'].value }" for="caisses"></label>
                </div>
              </div>

              <div class="form-group d-flex justify-content-start align-items-center">
                <div class="col pl-0">Fûts</div>
                <div class="custom-checkbox-toggle">
                  <input type="checkbox" class="custom-control-input" id="futs" formControlName="barrels" [ngClass]="{ 'backgroundColor': conditioning['barrels'].value }">
                  <label class="custom-control-label" [ngClass]="{ 'backgroundColor': conditioning['barrels'].value }" for="futs"></label>
                </div>
              </div>

              <div class="form-group d-flex justify-content-start align-items-center">
                <div class="col pl-0">Cartons</div>
                <div class="custom-checkbox-toggle">
                  <input type="checkbox" class="custom-control-input" id="cartons" formControlName="cartons" [ngClass]="{ 'backgroundColor': conditioning['cartons'].value }">
                  <label class="custom-control-label" [ngClass]="{ 'backgroundColor': conditioning['cartons'].value }" for="cartons"></label>
                </div>
              </div>

              <div class="form-group d-flex justify-content-start align-items-center">
                <div class="col pl-0">Autre</div>
                <div class="custom-checkbox-toggle">
                  <input type="checkbox" class="custom-control-input" id="otherConditioning" formControlName="other" [ngClass]="{ 'backgroundColor': conditioning['other'].value }">
                  <label class="custom-control-label" [ngClass]="{ 'backgroundColor': conditioning['other'].value }" for="otherConditioning"></label>
                </div>
              </div>
              <div class="form-group" *ngIf="conditioning['other'].value">
                <label for="conditioningItem8detail">Préciser</label>
                <textarea type="text" formControlName="other_detail" id="conditioningItem8detail" class="form-control" placeholder="Préciser"></textarea>
              </div>

            </div>

            <div class="form-group">
              <label for="precautions">Précautions particulières</label>
              <textarea type="text" formControlName="precautions" id="precautions" class="form-control" placeholder="Précautions particulières"></textarea>
            </div>

            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Présence de matière dangereuse</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="dangerousMaterial" formControlName="is_dangerous_material" [ngClass]="{ 'backgroundColor': merchandise['is_dangerous_material'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': merchandise['is_dangerous_material'].value }" for="dangerousMaterial"></label>
              </div>
            </div>
            <div formGroupName="dangerous_materials" *ngIf="merchandise['is_dangerous_material'].value && merchandise.dangerous_materials['controls'] as dangerous_material">

              <div class="form-group d-flex justify-content-start align-items-center">
                <div class="col pl-0"><img src="../assets/img/picto-explosif.jpg" height="80" alt="Picto Explosif">Explosif</div>
                <div class="custom-checkbox-toggle">
                  <input type="checkbox" class="custom-control-input" id="explosif" formControlName="explosive" [ngClass]="{ 'backgroundColor': dangerous_material['explosive'].value }">
                  <label class="custom-control-label" [ngClass]="{ 'backgroundColor': dangerous_material['explosive'].value }" for="explosif"></label>
                </div>
              </div>
              <div class="form-group" *ngIf="dangerous_material['explosive'].value">
                <label for="dangerousItem1detail">Préciser</label>
                <textarea type="text" formControlName="explosive_detail" id="dangerousItem1detail" class="form-control" placeholder="Préciser"></textarea>
              </div>

              <div class="form-group d-flex justify-content-start align-items-center">
                <div class="col pl-0"><img src="../assets/img/picto-inflammable.jpg" height="80" alt="Picto Inflammable">Inflammable</div>
                <div class="custom-checkbox-toggle">
                  <input type="checkbox" class="custom-control-input" id="inflammable" formControlName="flammable" [ngClass]="{ 'backgroundColor': dangerous_material['flammable'].value }">
                  <label class="custom-control-label" [ngClass]="{ 'backgroundColor': dangerous_material['flammable'].value }" for="inflammable"></label>
                </div>
              </div>
              <div class="form-group" *ngIf="dangerous_material['flammable'].value">
                <label for="dangerousItem2detail">Préciser</label>
                <textarea type="text" formControlName="flammable_detail" id="dangerousItem2detail" class="form-control" placeholder="Préciser"></textarea>
              </div>

              <div class="form-group d-flex justify-content-start align-items-center">
                <div class="col pl-0"><img src="../assets/img/picto-comburant.jpg" height="80" alt="Picto Comburant">Comburant</div>
                <div class="custom-checkbox-toggle">
                  <input type="checkbox" class="custom-control-input" id="comburant" formControlName="oxidizer" [ngClass]="{ 'backgroundColor': dangerous_material['oxidizer'].value }">
                  <label class="custom-control-label" [ngClass]="{ 'backgroundColor': dangerous_material['oxidizer'].value }" for="comburant"></label>
                </div>
              </div>
              <div class="form-group" *ngIf="dangerous_material['oxidizer'].value">
                <label for="dangerousItem3detail">Préciser</label>
                <textarea type="text" formControlName="oxidizer_detail" id="dangerousItem3detail" class="form-control" placeholder="Préciser"></textarea>
              </div>

              <div class="form-group d-flex justify-content-start align-items-center">
                <div class="col pl-0"><img src="../assets/img/picto-toxique.jpg" height="80" alt="Picto Toxique">Toxique</div>
                <div class="custom-checkbox-toggle">
                  <input type="checkbox" class="custom-control-input" id="toxique" formControlName="toxic" [ngClass]="{ 'backgroundColor': dangerous_material['toxic'].value }">
                  <label class="custom-control-label" [ngClass]="{ 'backgroundColor': dangerous_material['toxic'].value }" for="toxique"></label>
                </div>
              </div>
              <div class="form-group" *ngIf="dangerous_material['toxic'].value">
                <label for="dangerousItem4detail">Préciser</label>
                <textarea type="text" formControlName="toxic_detail" id="dangerousItem4detail" class="form-control" placeholder="Préciser"></textarea>
              </div>

              <div class="form-group d-flex justify-content-start align-items-center">
                <div class="col pl-0"><img src="../assets/img/picto-corrosif.jpg" height="80" alt="Picto Corrosif">Corrosif</div>
                <div class="custom-checkbox-toggle">
                  <input type="checkbox" class="custom-control-input" id="corrosif" formControlName="corrosive" [ngClass]="{ 'backgroundColor': dangerous_material['corrosive'].value }">
                  <label class="custom-control-label" [ngClass]="{ 'backgroundColor': dangerous_material['corrosive'].value }" for="corrosif"></label>
                </div>
              </div>
              <div class="form-group" *ngIf="dangerous_material['corrosive'].value">
                <label for="dangerousItem5detail">Préciser</label>
                <textarea type="text" formControlName="corrosive_detail" id="dangerousItem5detail" class="form-control" placeholder="Préciser"></textarea>
              </div>

              <div class="form-group d-flex justify-content-start align-items-center">
                <div class="col pl-0"><img src="../assets/img/picto-environnement.jpg" height="80" alt="Picto Environnement">Dangereux pour l'environnement</div>
                <div class="custom-checkbox-toggle">
                  <input type="checkbox" class="custom-control-input" id="environnement" formControlName="environment" [ngClass]="{ 'backgroundColor': dangerous_material['environment'].value }">
                  <label class="custom-control-label" [ngClass]="{ 'backgroundColor': dangerous_material['environment'].value }" for="environnement"></label>
                </div>
              </div>
              <div class="form-group" *ngIf="dangerous_material['environment'].value">
                <label for="dangerousItem6detail">Préciser</label>
                <textarea type="text" formControlName="environment_detail" id="dangerousItem6detail" class="form-control" placeholder="Préciser"></textarea>
              </div>

              <div class="form-group d-flex justify-content-start align-items-center">
                <div class="col pl-0"><img src="../assets/img/picto-cmr.jpg" height="80" alt="Picto CMR">CMR</div>
                <div class="custom-checkbox-toggle">
                  <input type="checkbox" class="custom-control-input" id="cmr" formControlName="cmr" [ngClass]="{ 'backgroundColor': dangerous_material['cmr'].value }">
                  <label class="custom-control-label" [ngClass]="{ 'backgroundColor': dangerous_material['cmr'].value }" for="cmr"></label>
                </div>
              </div>
              <div class="form-group" *ngIf="dangerous_material['cmr'].value">
                <label for="dangerousItem7detail">Préciser</label>
                <textarea type="text" formControlName="cmr_detail" id="dangerousItem7detail" class="form-control" placeholder="Préciser"></textarea>
              </div>

              <div class="form-group d-flex justify-content-start align-items-center">
                <div class="col pl-0"><img src="../assets/img/picto-sante.jpg" height="80" alt="Picto Sante">Dangereux pour la santé</div>
                <div class="custom-checkbox-toggle">
                  <input type="checkbox" class="custom-control-input" id="sante" formControlName="health" [ngClass]="{ 'backgroundColor': dangerous_material['health'].value }">
                  <label class="custom-control-label" [ngClass]="{ 'backgroundColor': dangerous_material['health'].value }" for="sante"></label>
                </div>
              </div>
              <div class="form-group" *ngIf="dangerous_material['health'].value">
                <label for="dangerousItem8detail">Préciser</label>
                <textarea type="text" formControlName="health_detail" id="dangerousItem8detail" class="form-control" placeholder="Préciser"></textarea>
              </div>

              <div class="form-group d-flex justify-content-start align-items-center">
                <div class="col pl-0">Soumis à la réglementation relative au transport de matières dangereuses</div>
                <div class="custom-checkbox-toggle">
                  <input type="checkbox" class="custom-control-input" id="transport-dangerous" formControlName="special_precautions" [ngClass]="{ 'backgroundColor': dangerous_material['special_precautions'].value }">
                  <label class="custom-control-label" [ngClass]="{ 'backgroundColor': dangerous_material['special_precautions'].value }" for="transport-dangerous"></label>
                </div>
              </div>
              <div class="form-group" *ngIf="dangerous_material['special_precautions'].value">
                <label for="dangerousItem9detail">Précautions particulières</label>
                <textarea type="text" formControlName="special_precautions_detail" id="dangerousItem9detail" class="form-control" placeholder="Précautions particulières"></textarea>
              </div>

            </div>

          </div>

          <!-- EXPLOSIVE -->
          <div class="form-group d-flex justify-content-start align-items-center">
            <div class="col pl-0">Chargements/déchargement de substances ou objets explosifs</div>
            <div class="custom-checkbox-toggle">
              <input type="checkbox" class="custom-control-input" id="explosive_merchandise" formControlName="explosive_merchandise" [ngClass]="{ 'backgroundColor': securityProtocolForm['controls']['explosive_merchandise'].value }">
              <label class="custom-control-label" [ngClass]="{ 'backgroundColor': securityProtocolForm['controls']['explosive_merchandise'].value }" for="explosive_merchandise"></label>
            </div>
          </div>

          <div class="card bg-light border" *ngIf="securityProtocolForm['controls']['explosive_merchandise'].value">
            <div class="card-body">
              <p class="small text-muted mb-2">
                Joindre les conclusions de l’étude de sécurité pyrotechnique
                relatives à cette activité ainsi que les conclusions du document relatif aux itinéraires
                autorisés dans les sites et aux effets dominos possibles entre le transport et
                l’installation.
              </p>
            </div>
          </div>

          <!-- DOCUMENTS -->
          <h2 class="header-title mb-3" *ngIf="securityProtocolForm['controls']['explosive_merchandise'].value && (isNew || canUpdate)">Documents</h2>
          <div class="form-group" *ngIf="securityProtocolForm['controls']['explosive_merchandise'].value && (isNew || canUpdate)">
            <div class="dropzone dropzone-multiple dz-clickable" appDragDrop (dropHandler)="onFileChangeRequired($event)">
              <div class="dz-default dz-message" (click)="chooseFileRequired()">
                <span>Déposer vos fichiers ici pour télécharger</span>
              </div>
              <input id="inputFileRequired" (change)="onFileChangeRequired($event)" type="file" [hidden]="true" multiple>
            </div>
          </div>
          <div class="card" *ngFor="let file of filesRequired; let index = index">
            <div class="card-body">
              <div class="d-flex align-content-center justify-content-between">
                <h4 class="card-title mb-0 name align-self-center">
                  {{ file.name + '.' + file.extension }}
                </h4>

                <a class="href tooltipHtml" data-placement="bottom" title="Supprimer" (click)="removeFileRequired(index)" *ngIf="isNew || canUpdate">
                  <i class="fe fe-trash"></i>
                </a>
              </div>
            </div>
          </div>

        </div>

        <!-- 4 - SECURITY -->
        <div class="tab-pane fade" [ngClass]="{ 'show active': activeTab === 4}">
          <h2 class="header-title my-4">Organisation des secours</h2>
          <div class="d-flex justify-content-center align-items-center mt-4" *ngIf="!securityProtocolForm.controls.emergency_numbers.value.length">
            Aucun numero
          </div>
          <div formArrayName="emergency_numbers" class="mb-4">
            <div [formGroupName]="i" *ngFor="let emergency_number of securityProtocolForm['controls'].emergency_numbers['controls'] as FormGroup; let i = index; let last = last" class="card">
              <div class="card-header">
                <div class="d-flex justify-content-between">
                  <a data-toggle="collapse" [href]="'#emergency_numbers' + i" [attr.aria-controls]="'emergency_numbers' + i" role="button" aria-expanded="true">
                    Numéro {{ i + 1 }}
                  </a>

                  <a class="href tooltipHtml" data-placement="bottom" title="Supprimer" (click)="deleteEmergencyNumber(i)">
                    <i class="fe fe-trash"></i>
                  </a>
                </div>
              </div>

              <div class="card-body collapse show" [id]="'emergency_numbers' + i">
                <div class="form-group">
                  <label required>Organisation</label>
                  <input type="text" placeholder="Organisation" formControlName="organisation" class="form-control d-inline-block col-12" [ngClass]="{ 'is-invalid': emergency_number['controls'].organisation.invalid && (emergency_number['controls'].organisation.dirty || emergency_number['controls'].organisation.touched) }">
                  <div *ngIf="emergency_number['controls'].organisation.invalid && (emergency_number['controls'].organisation.dirty || emergency_number['controls'].organisation.touched)" class="invalid-feedback">
                    {{ getErrorMessage("organisation") }}
                  </div>
                </div>

                <div class="form-group">
                  <label required>Numéro d'urgence</label>
                  <input type="text" placeholder="Numéro d'urgence" formControlName="number" class="form-control d-inline-block col-12" [ngClass]="{ 'is-invalid': emergency_number['controls'].number.invalid && (emergency_number['controls'].number.dirty || emergency_number['controls'].number.touched) }">
                  <div *ngIf="emergency_number['controls'].number.invalid && (emergency_number['controls'].number.dirty || emergency_number['controls'].number.touched)" class="invalid-feedback">
                    {{ getErrorMessage("number") }}
                  </div>
                </div>

                <div class="form-group">
                  <label required>Commentaires</label>
                  <input type="text" placeholder="Commentaires" formControlName="comment" class="form-control d-inline-block col-12" [ngClass]="{ 'is-invalid': emergency_number['controls'].comment.invalid && (emergency_number['controls'].comment.dirty || emergency_number['controls'].comment.touched) }">
                  <div *ngIf="emergency_number['controls'].comment.invalid && (emergency_number['controls'].comment.dirty || emergency_number['controls'].comment.touched)" class="invalid-feedback">
                    {{ getErrorMessage("comment") }}
                  </div>
                </div>
              </div>

            </div>
          </div>
          <button class="btn btn-primary col-6 offset-3" (click)="addEmergencyNumber()">
            Ajouter un numéro
          </button>

          <h2 class="header-title my-4">Les consignes générales et particulières de sécurité</h2>
          <div class="d-flex justify-content-center align-items-center mt-4" *ngIf="!securityProtocolForm.controls.instructions.value.length">
            Aucune consigne
          </div>
          <div formArrayName="instructions" class="mb-4">
            <div [formGroupName]="i" *ngFor="let instruction of securityProtocolForm['controls'].instructions['controls'] as FormGroup; let i = index" class="card">
              <div class="card-header">
                <div class="d-flex justify-content-between">
                  <a data-toggle="collapse" [href]="'#instruction' + i" [attr.aria-controls]="'instruction' + i" role="button" aria-expanded="true">
                    Consigne {{ i + 1 }}
                  </a>

                  <a class="href tooltipHtml" data-placement="bottom" title="Supprimer" (click)="deleteInstruction(i)">
                    <i class="fe fe-trash"></i>
                  </a>
                </div>
              </div>

              <div class="card-body collapse show" [id]="'instruction' + i">
                <div class="form-group">
                  <label for="instruction">Consigne</label>
                  <textarea type="text" id="instruction" placeholder="Consigne" formControlName="name" class="form-control d-inline-block col-12"
                            [ngClass]="{ 'is-invalid': instruction['controls'].name.invalid && (instruction['controls'].name.dirty || instruction['controls'].name.touched) }"></textarea>
                  <div *ngIf="instruction['controls'].name.invalid && (instruction['controls'].name.dirty || instruction['controls'].name.touched)" class="invalid-feedback">
                    {{ getErrorMessage("name") }}
                  </div>
                </div>
              </div>

            </div>
          </div>
          <button class="btn btn-primary col-6 offset-3" (click)="addInstruction()">
            Ajouter une consigne
          </button>

          <h2 class="header-title my-4">Les interdictions</h2>
          <div formGroupName="prohibitions" *ngIf="securityProtocolForm['controls'].prohibitions['controls'] as prohibitions">

            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Interdiction de fumer</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="prohibitionItem1" formControlName="smoke" [ngClass]="{ 'backgroundColor': prohibitions['smoke'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': prohibitions['smoke'].value }" for="prohibitionItem1"></label>
              </div>
            </div>

            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Interdiction de consommer des produits illicites</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="prohibitionItem2" formControlName="drugs" [ngClass]="{ 'backgroundColor': prohibitions['drugs'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': prohibitions['drugs'].value }" for="prohibitionItem2"></label>
              </div>
            </div>

            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Autre</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="prohibitionItem3" formControlName="other" [ngClass]="{ 'backgroundColor': prohibitions['other'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': prohibitions['other'].value }" for="prohibitionItem3"></label>
              </div>
            </div>
            <div class="form-group" *ngIf="prohibitions['other'].value">
              <label for="prohibitionItem3detail">Préciser</label>
              <textarea type="text" formControlName="other_detail" id="prohibitionItem3detail" class="form-control" placeholder="Préciser"></textarea>
            </div>

          </div>

          <h2 class="header-title my-4">Les équipements de protection individuelle</h2>
          <div formGroupName="individual_protections" *ngIf="securityProtocolForm['controls'].individual_protections['controls'] as protection">

            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Chaussures de sécurité</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="personalEquipmentItem1" formControlName="shoes" [ngClass]="{ 'backgroundColor': protection['shoes'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': protection['shoes'].value }" for="personalEquipmentItem1"></label>
              </div>
            </div>

            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Casque</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="personalEquipmentItem2" formControlName="helmet" [ngClass]="{ 'backgroundColor': protection['helmet'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': protection['helmet'].value }" for="personalEquipmentItem2"></label>
              </div>
            </div>

            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Gants</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="personalEquipmentItem3" formControlName="gloves" [ngClass]="{ 'backgroundColor': protection['gloves'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': protection['gloves'].value }" for="personalEquipmentItem3"></label>
              </div>
            </div>

            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Gilet fluorescent</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="personalEquipmentItem4" formControlName="jacket" [ngClass]="{ 'backgroundColor': protection['jacket'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': protection['jacket'].value }" for="personalEquipmentItem4"></label>
              </div>
            </div>

            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Lunettes de sécurité</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="personalEquipmentItem5" formControlName="glasses" [ngClass]="{ 'backgroundColor': protection['glasses'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': protection['glasses'].value }" for="personalEquipmentItem5"></label>
              </div>
            </div>

            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Autre</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="personalEquipmentItem6" formControlName="other" [ngClass]="{ 'backgroundColor': protection['other'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': protection['other'].value }" for="personalEquipmentItem6"></label>
              </div>
            </div>
            <div class="form-group" *ngIf="checkProtection(protection)">
              <label for="personalEquipmentItem6detail">Préciser</label>
              <textarea type="text" formControlName="other_detail" id="personalEquipmentItem6detail" class="form-control" placeholder="Préciser"></textarea>
            </div>

          </div>

        </div>

      </div>

    </form>
  </div>

  <div modal-footer class="w-100" *ngIf="isNew || canUpdate">
    <button class="btn btn-primary w-100" (click)="isNew ? createSecurityProtocol() : updateSecurityProtocol()" [ngClass]="{ 'is-loading is-loading-sm': loader }" [disabled]="loader || securityProtocolForm.invalid">
      {{ isNew ? 'Créer' : 'Mettre à jour' }}
    </button>
  </div>
</app-modal-right>
