<div class="main-content">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col">
        <div class="header mb-0">
          <div class="header-body no-border">
            <div class="row align-items-center">
              <div class="col">
                <h1 class="header-title">{{ pageTitle }}</h1>
                <!-- FOLDER -->
                <small class="form-text text-muted" *ngIf="view === 'parent'">
                  {{ (viewRegistry && (registry && registry.name)) ? 'Liste des documents du registre ' + registry.name : viewPublic ? 'Liste des documents partagé par Batiregistre' : 'Liste des documents' }}
                </small>

                <!-- FILE TYPE -->
                <small class="form-text text-muted" *ngIf="view === 'children'">
                  {{ folderParent && folderParent.name ? 'Liste des documents du dossier ' + folderParent.name : 'Liste des documents' }}
                </small>

                <!-- FILE -->
                <small class="form-text text-muted" *ngIf="view === 'file'">
                  {{ fileTypeParent && fileTypeParent.name ? 'Liste des documents du type de fichier ' + fileTypeParent.name : 'Liste des documents' }}
                </small>

                <app-breadcrumbs [currentPage]="pageName" [names]="previousPageName" [routes]="previousPageRoute"></app-breadcrumbs>
              </div>

              <!-- ADD FOLDER -->
              <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0" *ngIf="canCreate && (viewRegistry || viewAdmin) && (view === 'parent' || view === 'children')">
                <a class="btn btn-primary d-block d-md-inline-block" [routerLink]="[updateRoute + 'create']">
                  Ajouter un dossier
                </a>
              </div>

              <!-- ADD FILE TYPE -->
              <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0" *ngIf="canCreate && (viewRegistry || viewAdmin) && view ==='children'">
                <a class="btn btn-primary d-block d-md-inline-block" [routerLink]="[updateRoute + 'file-type/create']">
                  Ajouter un type de fichier
                </a>
              </div>

              <!-- ADD FILE ADMIN AND REGISTRY -->
              <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0" *ngIf="canCreate && (viewRegistry || viewAdmin) && view === 'file'">
                <a class="btn btn-primary d-block d-md-inline-block" [routerLink]="[updateRoute + 'create']" *ngIf="fileTypeParent.file_is_unique && fileTypeParent.file_count < 1">
                  Ajouter un fichier
                </a>

                <a class="btn btn-primary d-block d-md-inline-block" [routerLink]="[updateRoute + 'create']" *ngIf="!fileTypeParent.file_is_unique">
                  Ajouter un fichier
                </a>
              </div>

              <!-- ADD FILE CUSTOMERS -->
              <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0" *ngIf="canCreate && viewCustomer && view === 'file'">
                <a class="btn btn-primary d-block d-md-inline-block" [routerLink]="[updateRoute + 'create']" *ngIf="fileTypeParent.file_is_unique && checkUserUpload()">
                  Ajouter un fichier
                </a>

                <a class="btn btn-primary d-block d-md-inline-block" [routerLink]="[updateRoute + 'create']" *ngIf="!fileTypeParent.file_is_unique">
                  Ajouter un fichier
                </a>
              </div>

              <!-- ADD FILES WITHOUT TYPES -->
              <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0" *ngIf="canCreate && !viewPublic && view === 'children' && folderParent.can_contain_file">
                <a class="btn btn-primary d-block d-md-inline-block" [routerLink]="[updateRoute + 'files/create']">
                  Ajouter un fichier
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col">
            <app-searchbar [(ngModel)]="search" (keyup)="searchData()" ngDefaultControl></app-searchbar>
          </div>

          <div class="col-auto">
            <button class="btn btn-lg btn-white filter tooltipHtml" data-toggle="dropdown" data-placement="bottom" title="Filtre">
              <span class="fe fe-filter"></span>
            </button>
            <span class="dropdown-menu dropdown-menu-right">
              <a class="dropdown-item href" (click)="refreshData('order_number', 'asc', 1)" aria-label="Tri des documents : Numéro - Ordre croissant">
                Numéro A-Z
              </a>

              <a class="dropdown-item href" (click)="refreshData('order_number', 'desc', 1)" aria-label="Tri des documents : Numéro - Ordre décroissant">
                Numéro Z-A
              </a>

              <a class="dropdown-item href" (click)="refreshData('name', 'asc', 1)" aria-label="Tri des documents : Nom - Ordre croissant">
                Nom A-Z
              </a>

              <a class="dropdown-item href" (click)="refreshData('name', 'desc', 1)" aria-label="Tri des documents : Nom - Ordre décroissant">
                Nom Z-A
              </a>

              <a class="dropdown-item href" (click)="refreshData('Registries.name', 'asc', 1)" aria-label="Tri des documents : Registre - Ordre croissant">
                Registre A-Z
              </a>

              <a class="dropdown-item href" (click)="refreshData('Registries.name', 'desc', 1)" aria-label="Tri des documents : Registre - Ordre décroissant">
                Registre Z-A
              </a>
            </span>
          </div>
        </div>

        <div class="is-loading loader-content" *ngIf="loader"></div>

        <div class="tab-content" *ngIf="!loader && (folders.length || files.length)">
          <!-- VIEW ADMIN -->
          <div class="tab-pane fade active show" role="tabpanel" *ngIf="viewAdmin">
            <div class="row listAlias">
              <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 d-flex flex-column" *ngFor="let file of files">
                <div class="card flex-fill">
                  <div class="href image-file-download" *ngIf="!['mp4', 'pdf', 'png', 'jpeg', 'jpg'].includes(file.extension)" (click)="downloadFile(file.id, file.name + '.' + file.extension)">
                    <span class="ribbon" *ngIf="file.establishment_id === 0 && file.is_model"><span>MODELE BATIREGISTRE</span></span>
                    <span class="ribbon" *ngIf="file.establishment_id === 0 && !file.is_model"><span>FICHIER BATIREGISTRE</span></span>
                    <img [src]="getImage(file.extension)" alt="Télécharger le fichier"/>
                  </div>
                  <div class="href image-file-download" *ngIf="file.extension === 'mp4'" (click)="showVideo(file.id)">
                    <span class="ribbon" *ngIf="file.establishment_id === 0 && file.is_model"><span>MODELE BATIREGISTRE</span></span>
                    <span class="ribbon" *ngIf="file.establishment_id === 0 && !file.is_model"><span>FICHIER BATIREGISTRE</span></span>
                    <img [src]="getImage(file.extension)" alt="Télécharger le fichier"/>
                  </div>
                  <div class="href image-file-download" *ngIf="['pdf', 'png', 'jpeg', 'jpg'].includes(file.extension)" (click)="showPdf(file.file_url)">
                    <span class="ribbon" *ngIf="file.establishment_id === 0 && file.is_model"><span>MODELE BATIREGISTRE</span></span>
                    <span class="ribbon" *ngIf="file.establishment_id === 0 && !file.is_model"><span>FICHIER BATIREGISTRE</span></span>
                    <img [src]="getImage(file.extension)" alt="Visionner le PDF"/>
                  </div>

                  <div class="card-body">
                    <div class="row align-items-center">
                      <div class="col overflow-hidden">
                        <h4 class="card-title name text-wrap mb-0">
                          <a [routerLink]="" *ngIf="!['mp4', 'pdf', 'png', 'jpeg', 'jpg'].includes(file.extension)" (click)="downloadFile(file.id, file.name + '.' + file.extension)">
                            {{ file.order_number + ' - ' + file.name + '.' + file.extension }}
                          </a>
                          <a [routerLink]="" *ngIf="file.extension === 'mp4'" (click)="showVideo(file.id)">
                            {{ file.order_number + ' - ' + file.name + '.' + file.extension }}
                          </a>
                          <a [routerLink]="" *ngIf="['pdf', 'png', 'jpeg', 'jpg'].includes(file.extension)" (click)="showPdf(file.file_url)">
                            {{ file.order_number + ' - ' + file.name + '.' + file.extension }}
                          </a>
                        </h4>
                      </div>

                      <div class="col-auto align-self-start">
                        <div class="dropdown">
                          <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown"
                             aria-haspopup="true" aria-expanded="false">
                            <i class="fe fe-more-vertical"></i>
                          </a>

                          <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item href" [routerLink]="[updateRoute + 'files/' + file.id]">
                              {{ canUpdate ? 'Éditer le fichier' : 'Consulter le fichier' }}
                            </a>

                            <a class="dropdown-item href" (click)="downloadFile(file.id, file.name + '.' + file.extension)">
                              Télécharger le fichier
                            </a>

                            <a class="dropdown-item href" *ngIf="file.extension === 'mp4'" (click)="showVideo(file.id)">
                              Regarder la vidéo
                            </a>

                            <a class="dropdown-item href" *ngIf="file.extension === 'pdf'" (click)="showPdf(file.file_url)">
                              Visionner le PDF
                            </a>

                            <a class="dropdown-item href" *ngIf="['png', 'jpeg', 'jpg'].includes(file.extension)" (click)="showPdf(file.file_url)">
                              Visionner l'image
                            </a>

                            <a class="dropdown-item href" (click)="modalConfirmFile.open(file.id)" *ngIf="canUpdate">
                              Supprimer le fichier
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="w-100"></div>

              <div [ngClass]="view === 'file' ? 'col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 d-flex flex-column' : 'col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4 d-flex flex-column'" *ngFor="let folder of folders">
                <div class="card flex-fill" [ngClass]="{ 'is-important': folder.is_important }">
                  <!-- FOLDER + FILE TYPE + FILE -->
                  <div class="href image-file-download" *ngIf="view === 'file' && !['mp4', 'pdf', 'png', 'jpeg', 'jpg'].includes(folder.extension)" (click)="downloadFile(folder.id, folder.name + '.' + folder.extension)">
                    <span class="ribbon" *ngIf="folder.establishment_id === 0 && folder.is_model"><span>MODELE BATIREGISTRE</span></span>
                    <span class="ribbon" *ngIf="folder.establishment_id === 0 && !folder.is_model"><span>FICHIER BATIREGISTRE</span></span>
                    <img [src]="getImage(folder.extension)" alt="Télécharger le fichier"/>
                  </div>
                  <div class="href image-file-download" *ngIf="view === 'file' && folder.extension === 'mp4'" (click)="showVideo(folder.id)">
                    <span class="ribbon" *ngIf="folder.establishment_id === 0 && folder.is_model"><span>MODELE BATIREGISTRE</span></span>
                    <span class="ribbon" *ngIf="folder.establishment_id === 0 && !folder.is_model"><span>FICHIER BATIREGISTRE</span></span>
                    <img [src]="getImage(folder.extension)" alt="Télécharger le fichier"/>
                  </div>
                  <div class="href image-file-download" *ngIf="view === 'file' && ['pdf', 'png', 'jpeg', 'jpg'].includes(folder.extension)" (click)="showPdf(folder['file_url'])">
                    <span class="ribbon" *ngIf="folder.establishment_id === 0 && folder.is_model"><span>MODELE BATIREGISTRE</span></span>
                    <span class="ribbon" *ngIf="folder.establishment_id === 0 && !folder.is_model"><span>FICHIER BATIREGISTRE</span></span>
                    <img [src]="getImage(folder.extension)" alt="Visionner le PDF"/>
                  </div>

                  <!-- FOLDER + FILE TYPE + FILE -->
                  <div class="card-body">
                    <div class="row align-items-center">
                      <div class="col-auto align-self-start align-icon" *ngIf="view !== 'file'">
                        <i class="fe fe-folder" *ngIf="folder.generic_folders_type_id"></i>
                        <i class="fe fe-list" *ngIf="folder.generic_folder_id"></i>
                      </div>

                      <div class="col overflow-hidden">
                        <h4 class="card-title name text-wrap mb-0" *ngIf="!folder.extension">
                          <!-- FOLDER -->
                          <a [routerLink]="['/document/' + folder.id]" *ngIf="folder.generic_folders_type_id">
                            {{ folder.order_number + ' - ' + folder.name }}
                          </a>

                          <!-- FILE TYPE -->
                          <a [routerLink]="[updateRoute + '/file-type/' + folder.id + '/files']" *ngIf="folder.generic_folder_id">
                            {{ folder.order_number + ' - ' + folder.name }}
                          </a>

                          <!-- FILE -->
                          <a [routerLink]="" (click)="downloadFile(folder.id, folder.name + '.' + folder.extension)" *ngIf="folder.generic_files_type_id && !['mp4', 'pdf', 'png', 'jpeg', 'jpg'].includes(folder.extension)">
                            {{ folder.order_number + ' - ' + folder.name + '.' + folder.extension }}
                          </a>

                          <!-- VIDEO -->
                          <a [routerLink]="" (click)="showVideo(folder.id)" *ngIf="folder.generic_files_type_id && folder.extension === 'mp4'">
                            {{ folder.order_number + ' - ' + folder.name + '.' + folder.extension }}
                          </a>

                          <!-- PDF -->
                          <a [routerLink]="" *ngIf="['pdf', 'png', 'jpeg', 'jpg'].includes(folder.extension)" (click)="showPdf(folder['file_url'])">
                            {{ folder.order_number + ' - ' + folder.name + '.' + folder.extension }}
                          </a>
                        </h4>

                        <!-- FOLDER -->
                        <p class="card-text text-truncate mb-0" *ngIf="folder.generic_folders_type_id">
                          {{ folder.generic_folders_type.name }}
                        </p>
                        <p class="card-text small text-truncate mb-0" *ngIf="folder.generic_folders_type_id && folder.registry" [attr.aria-label]="'Registre : ' + folder.registry.name">
                          {{ folder.registry.name }}
                        </p>
                      </div>

                      <div class="col-auto align-self-start">
                        <div class="dropdown">
                          <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown"
                             aria-haspopup="true" aria-expanded="false">
                            <i class="fe fe-more-vertical"></i>
                          </a>

                          <!-- FOLDER -->
                          <div class="dropdown-menu dropdown-menu-right" *ngIf="folder.generic_folders_type_id">
                            <a class="dropdown-item href" [routerLink]="['/document/' + folder.id + '/files/create']" *ngIf="folder.can_contain_file && canUpdate">
                              Ajouter un fichier
                            </a>

                            <a class="dropdown-item href" [routerLink]="['/document/' + folder.id]">
                              Consulter le contenu
                            </a>

                            <a class="dropdown-item href" [routerLink]="[updateRoute + folder.id]">
                              {{ canUpdate ? 'Éditer le dossier' : 'Consulter le dossier' }}
                            </a>
                          </div>

                          <!-- FILE TYPE -->
                          <div class="dropdown-menu dropdown-menu-right" *ngIf="folder.generic_folder_id">
                            <a class="dropdown-item href" [routerLink]="[updateRoute + '/file-type/' + folder.id + '/files/create']" *ngIf="canUpdate">
                              {{ folder.file_is_unique ? 'Ajouter un fichier' : 'Ajouter des fichiers' }}
                            </a>

                            <a class="dropdown-item href" [routerLink]="[updateRoute + '/file-type/' + folder.id + '/files']">
                              {{ folder.file_is_unique ? 'Consulter le fichier' : 'Consulter les fichiers' }}
                            </a>

                            <a class="dropdown-item href" [routerLink]="[updateRoute + '/file-type/' + folder.id]">
                              {{ canUpdate ? 'Éditer le type de fichier' : 'Consulter le type de fichier' }}
                            </a>
                          </div>

                          <!-- FILE -->
                          <div class="dropdown-menu dropdown-menu-right" *ngIf="folder.generic_files_type_id">
                            <a class="dropdown-item href" [routerLink]="[updateRoute + folder.id]">
                              {{ canUpdate ? 'Éditer le fichier' : 'Consulter le fichier' }}
                            </a>

                            <a class="dropdown-item href" (click)="downloadFile(folder.id, folder.name + '.' + folder.extension)">
                              Télécharger le fichier
                            </a>

                            <a class="dropdown-item href" *ngIf="folder.extension === 'mp4'" (click)="showVideo(folder.id)">
                              Regarder la vidéo
                            </a>

                            <a class="dropdown-item href" *ngIf="folder.extension === 'pdf'" (click)="showPdf(folder['file_url'])">
                              Visionner le PDF
                            </a>

                            <a class="dropdown-item href" *ngIf="['png', 'jpeg', 'jpg'].includes(folder.extension)" (click)="showPdf(folder['file_url'])">
                              Visionner l'image
                            </a>

                            <a class="dropdown-item href" (click)="modalConfirmFile.open(folder.id)" *ngIf="canUpdate">
                              Supprimer le fichier
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- VIEW REGISTRY -->
          <div class="tab-pane fade active show" role="tabpanel" *ngIf="viewRegistry">
            <div class="row listAlias">
              <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 d-flex flex-column" *ngFor="let file of files">
                <div class="card flex-fill">
                  <div class="href image-file-download"  *ngIf="!['mp4', 'pdf', 'png', 'jpeg', 'jpg'].includes(file.extension)" (click)="downloadFile(file.id, file.name + '.' + file.extension)">
                    <span class="ribbon" *ngIf="file.establishment_id === 0 && file.is_model"><span>MODELE BATIREGISTRE</span></span>
                    <span class="ribbon" *ngIf="file.establishment_id === 0 && !file.is_model"><span>FICHIER BATIREGISTRE</span></span>
                    <img [src]="getImage(file.extension)" alt="Télécharger le fichier"/>
                  </div>
                  <div class="href image-file-download" *ngIf="file.extension === 'mp4'" (click)="showVideo(file.id)">
                    <span class="ribbon" *ngIf="file.establishment_id === 0 && file.is_model"><span>MODELE BATIREGISTRE</span></span>
                    <span class="ribbon" *ngIf="file.establishment_id === 0 && !file.is_model"><span>FICHIER BATIREGISTRE</span></span>
                    <img [src]="getImage(file.extension)" alt="Télécharger le fichier"/>
                  </div>
                  <div class="href image-file-download" *ngIf="['pdf', 'png', 'jpeg', 'jpg'].includes(file.extension)" (click)="showPdf(file.file_url)">
                    <span class="ribbon" *ngIf="file.establishment_id === 0 && file.is_model"><span>MODELE BATIREGISTRE</span></span>
                    <span class="ribbon" *ngIf="file.establishment_id === 0 && !file.is_model"><span>FICHIER BATIREGISTRE</span></span>
                    <img [src]="getImage(file.extension)" alt="Visionner le PDF"/>
                  </div>

                  <div class="card-body">
                    <div class="row align-items-center">
                      <div class="col overflow-hidden">
                        <h4 class="card-title name text-wrap mb-0">
                          <a [routerLink]="" *ngIf="!['mp4', 'pdf', 'png', 'jpeg', 'jpg'].includes(file.extension)" (click)="downloadFile(file.id, file.name + '.' + file.extension)">
                            {{ file.name + '.' + file.extension }}
                          </a>
                          <a [routerLink]="" *ngIf="file.extension === 'mp4'" (click)="showVideo(file.id)">
                            {{ file.name + '.' + file.extension }}
                          </a>
                          <a [routerLink]="" *ngIf="['pdf', 'png', 'jpeg', 'jpg'].includes(file.extension)" (click)="showPdf(file.file_url)">
                            {{ file.name + '.' + file.extension }}
                          </a>
                        </h4>
                      </div>

                      <div class="col-auto align-self-start">
                        <div class="dropdown">
                          <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fe fe-more-vertical"></i>
                          </a>

                          <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item href" [routerLink]="[updateRoute + 'files/' + file.id]">
                              {{ canUpdate ? 'Éditer le fichier' : 'Consulter le fichier' }}
                            </a>

                            <a class="dropdown-item href" (click)="downloadFile(file.id, file.name + '.' + file.extension)">
                              Télécharger le fichier
                            </a>

                            <a class="dropdown-item href" *ngIf="file.extension === 'mp4'" (click)="showVideo(file.id)">
                              Regarder la vidéo
                            </a>

                            <a class="dropdown-item href" *ngIf="file.extension === 'pdf'" (click)="showPdf(file.file_url)">
                              Visionner le PDF
                            </a>

                            <a class="dropdown-item href" *ngIf="['png', 'jpeg', 'jpg'].includes(file.extension)" (click)="showPdf(file.file_url)">
                              Visionner l'image
                            </a>

                            <a class="dropdown-item href" (click)="modalConfirmFile.open(file.id)" *ngIf="canUpdate">
                              Supprimer le fichier
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="w-100"></div>

              <div [ngClass]="view === 'file' ? 'col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 d-flex flex-column' : 'col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4 d-flex flex-column'" *ngFor="let folder of folders">
                <div class="card flex-fill" [ngClass]="{ 'is-important': folder.is_important }">
                  <!-- FOLDER + FILE TYPE + FILE -->
                  <div class="href image-file-download" *ngIf="view === 'file' && !['mp4', 'pdf', 'png', 'jpeg', 'jpg'].includes(folder.extension)" (click)="downloadFile(folder.id, folder.name + '.' + folder.extension)">
                    <span class="ribbon" *ngIf="folder.establishment_id === 0 && folder.is_model"><span>MODELE BATIREGISTRE</span></span>
                    <span class="ribbon" *ngIf="folder.establishment_id === 0 && !folder.is_model"><span>FICHIER BATIREGISTRE</span></span>
                    <img [src]="getImage(folder.extension)" alt="Télécharger le fichier"/>
                  </div>
                  <div class="href image-file-download" *ngIf="view === 'file' && folder.extension === 'mp4'" (click)="showVideo(folder.id)">
                    <span class="ribbon" *ngIf="folder.establishment_id === 0 && folder.is_model"><span>MODELE BATIREGISTRE</span></span>
                    <span class="ribbon" *ngIf="folder.establishment_id === 0 && !folder.is_model"><span>FICHIER BATIREGISTRE</span></span>
                    <img [src]="getImage(folder.extension)" alt="Télécharger le fichier"/>
                  </div>
                  <div class="href image-file-download" *ngIf="view === 'file' && ['pdf', 'png', 'jpeg', 'jpg'].includes(folder.extension)" (click)="showPdf(folder['file_url'])">
                    <span class="ribbon" *ngIf="folder.establishment_id === 0 && folder.is_model"><span>MODELE BATIREGISTRE</span></span>
                    <span class="ribbon" *ngIf="folder.establishment_id === 0 && !folder.is_model"><span>FICHIER BATIREGISTRE</span></span>
                    <img [src]="getImage(folder.extension)" alt="Visionner le PDF"/>
                  </div>

                  <!-- FOLDER + FILE TYPE + FILE -->
                  <div class="card-body">
                    <div class="row align-items-center">
                      <div class="col-auto align-self-start align-icon" *ngIf="view !== 'file'">
                        <i class="fe fe-folder" *ngIf="folder.generic_folders_type_id"></i>
                        <i class="fe fe-list" *ngIf="folder.generic_folder_id"></i>
                      </div>

                      <div class="col overflow-hidden">
                        <h4 class="card-title name text-wrap mb-0">
                          <!-- FOLDER -->
                          <a [routerLink]="['/document/registry/' + activatedRoute.snapshot.params['registry_id'] + '/' + folder.id]" *ngIf="folder.generic_folders_type_id">
                            {{ folder.name }}
                          </a>

                          <!-- FILE TYPE -->
                          <a [routerLink]="[updateRoute + '/file-type/' + folder.id + '/files']" *ngIf="folder.generic_folder_id">
                            {{ folder.name }}
                          </a>

                          <!-- FILE -->
                          <a [routerLink]="" (click)="downloadFile(folder.id, folder.name + '.' + folder.extension)" *ngIf="folder.generic_files_type_id && !['mp4', 'pdf', 'png', 'jpeg', 'jpg'].includes(folder.extension)">
                            {{ folder.order_number + ' - ' + folder.name + '.' + folder.extension }}
                          </a>

                          <!-- VIDEO -->
                          <a [routerLink]="" (click)="showVideo(folder.id)" *ngIf="folder.generic_files_type_id && folder.extension === 'mp4'">
                            {{ folder.order_number + ' - ' + folder.name + '.' + folder.extension }}
                          </a>

                          <!-- PDF -->
                          <a [routerLink]="" *ngIf="['pdf', 'png', 'jpeg', 'jpg'].includes(folder.extension)" (click)="showPdf(folder['file_url'])">
                            {{ folder.order_number + ' - ' + folder.name + '.' + folder.extension }}
                          </a>
                        </h4>

                        <!-- FOLDER -->
                        <p class="card-text text-truncate mb-0" *ngIf="folder.generic_folders_type_id">
                          {{ folder.generic_folders_type.name }}
                        </p>
                        <p class="card-text small text-truncate mb-0" *ngIf="folder.generic_folders_type_id && folder.registry">
                          {{ folder.registry.name }}
                        </p>
                      </div>

                      <div class="col-auto align-self-start">
                        <div class="dropdown">
                          <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fe fe-more-vertical"></i>
                          </a>

                          <!-- FOLDER -->
                          <div class="dropdown-menu dropdown-menu-right" *ngIf="folder.generic_folders_type_id">
                            <a class="dropdown-item href" [routerLink]="['/document/registry/' + activatedRoute.snapshot.params['registry_id'] + '/' + folder.id + '/files/create']" *ngIf="folder.can_contain_file && canUpdate">
                              Ajouter un fichier
                            </a>

                            <a class="dropdown-item href" [routerLink]="['/document/registry/' + activatedRoute.snapshot.params['registry_id'] + '/' + folder.id]">
                              Consulter le contenu
                            </a>

                            <a class="dropdown-item href" [routerLink]="[updateRoute + folder.id]">
                              {{ canUpdate ? 'Éditer le dossier' : 'Consulter le dossier' }}
                            </a>
                          </div>

                          <!-- FILE TYPE -->
                          <div class="dropdown-menu dropdown-menu-right" *ngIf="folder.generic_folder_id">
                            <a class="dropdown-item href" [routerLink]="[updateRoute + '/file-type/' + folder.id + '/files/create']" *ngIf="canUpdate">
                              {{ folder.file_is_unique ? 'Ajouter un fichier' : 'Ajouter des fichiers' }}
                            </a>

                            <a class="dropdown-item href" [routerLink]="[updateRoute + '/file-type/' + folder.id + '/files']">
                              {{ folder.file_is_unique ? 'Consulter le fichier' : 'Consulter les fichiers' }}
                            </a>

                            <a class="dropdown-item href" [routerLink]="[updateRoute + '/file-type/' + folder.id]">
                              {{ canUpdate ? 'Éditer le type de fichier' : 'Consulter le type de fichier' }}
                            </a>
                          </div>

                          <!-- FILE -->
                          <div class="dropdown-menu dropdown-menu-right" *ngIf="folder.generic_files_type_id">
                            <a class="dropdown-item href" [routerLink]="[updateRoute + folder.id]">
                              {{ canUpdate ? 'Éditer le fichier' : 'Consulter le fichier' }}
                            </a>

                            <a class="dropdown-item href" (click)="downloadFile(folder.id, folder.name + '.' + folder.extension)">
                              Télécharger le fichier
                            </a>

                            <a class="dropdown-item href" *ngIf="folder.extension === 'pdf'" (click)="showPdf(folder['file_url'])">
                              Visionner le PDF
                            </a>

                            <a class="dropdown-item href" *ngIf="['png', 'jpeg', 'jpg'].includes(folder.extension)" (click)="showPdf(folder['file_url'])">
                              Visionner l'image
                            </a>

                            <a class="dropdown-item href" *ngIf="folder.extension === 'mp4'" (click)="showVideo(folder.id)">
                              Regarder la vidéo
                            </a>

                            <a class="dropdown-item href" (click)="modalConfirmFile.open(folder.id)" *ngIf="canUpdate">
                              Supprimer le fichier
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- VIEW PUBLIC -->
          <div class="tab-pane fade active show" role="tabpanel" *ngIf="viewPublic">
            <div class="row listAlias">
              <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 d-flex flex-column" *ngFor="let file of files">
                <div class="card flex-fill">
                  <div class="href image-file-download" *ngIf="!['mp4', 'pdf', 'png', 'jpeg', 'jpg'].includes(file.extension)" (click)="downloadFile(file.id, file.name + '.' + file.extension)">
                    <span class="ribbon" *ngIf="file.establishment_id === 0 && file.is_model"><span>MODELE BATIREGISTRE</span></span>
                    <span class="ribbon" *ngIf="file.establishment_id === 0 && !file.is_model"><span>FICHIER BATIREGISTRE</span></span>
                    <img [src]="getImage(file.extension)" alt="Télécharger le fichier"/>
                  </div>

                  <div class="href image-file-download" *ngIf="file.extension === 'mp4'" (click)="showVideo(file.id)">
                    <span class="ribbon" *ngIf="file.establishment_id === 0 && file.is_model"><span>MODELE BATIREGISTRE</span></span>
                    <span class="ribbon" *ngIf="file.establishment_id === 0 && !file.is_model"><span>FICHIER BATIREGISTRE</span></span>
                    <img [src]="getImage(file.extension)" alt="Télécharger le fichier"/>
                  </div>

                  <div class="href image-file-download" *ngIf="['pdf', 'png', 'jpeg', 'jpg'].includes(file.extension)" (click)="showPdf(file.file_url)">
                    <span class="ribbon" *ngIf="file.establishment_id === 0 && file.is_model"><span>MODELE BATIREGISTRE</span></span>
                    <span class="ribbon" *ngIf="file.establishment_id === 0 && !file.is_model"><span>FICHIER BATIREGISTRE</span></span>
                    <img [src]="getImage(file.extension)" alt="Visionner le PDF"/>
                  </div>

                  <div class="card-body">
                    <div class="row align-items-center">
                      <div class="col overflow-hidden">
                        <h4 class="card-title name text-wrap mb-0">
                          <a [routerLink]="" *ngIf="!['mp4', 'pdf', 'png', 'jpeg', 'jpg'].includes(file.extension)" (click)="downloadFile(file.id, file.name + '.' + file.extension)">
                            {{ file.name + '.' + file.extension }}
                          </a>
                          <a [routerLink]="" *ngIf="file.extension === 'mp4'" (click)="showVideo(file.id)">
                            {{ file.name + '.' + file.extension }}
                          </a>
                          <a [routerLink]="" *ngIf="['pdf', 'png', 'jpeg', 'jpg'].includes(file.extension)" (click)="showPdf(file.file_url)">
                            {{ file.name + '.' + file.extension }}
                          </a>
                        </h4>
                      </div>

                      <div class="col-auto align-self-start">
                        <div class="dropdown">
                          <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fe fe-more-vertical"></i>
                          </a>

                          <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item href" (click)="downloadFile(file.id, file.name + '.' + file.extension)">
                              Télécharger le fichier
                            </a>
                            <a class="dropdown-item href" *ngIf="file.extension === 'mp4'" (click)="showVideo(file.id)">
                              Regarder la vidéo
                            </a>
                            <a class="dropdown-item href" *ngIf="file.extension === 'pdf'" (click)="showPdf(file.file_url)">
                              Visionner le PDF
                            </a>
                            <a class="dropdown-item href" *ngIf="['png', 'jpeg', 'jpg'].includes(file.extension)" (click)="showPdf(file.file_url)">
                              Visionner l'image
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="w-100"></div>

              <div [ngClass]="view === 'file' ? 'col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 d-flex flex-column' : 'col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4 d-flex flex-column'" *ngFor="let folder of folders">
                <div class="card flex-fill" [ngClass]="{ 'is-important': folder.is_important }">
                  <div class="href image-file-download" *ngIf="view === 'file' && folder.extension !== 'mp4'" (click)="downloadFile(folder.id, folder.name + '.' + folder.extension)">
                    <span class="ribbon" *ngIf="folder.establishment_id === 0 && folder.is_model"><span>MODELE BATIREGISTRE</span></span>
                    <span class="ribbon" *ngIf="folder.establishment_id === 0 && !folder.is_model"><span>FICHIER BATIREGISTRE</span></span>
                    <img [src]="getImage(folder.extension)" alt="Télécharger le fichier"/>
                  </div>
                  <div class="href image-file-download" *ngIf="view === 'file' && folder.extension === 'mp4'" (click)="showVideo(folder.id)">
                    <span class="ribbon" *ngIf="folder.establishment_id === 0 && folder.is_model"><span>MODELE BATIREGISTRE</span></span>
                    <span class="ribbon" *ngIf="folder.establishment_id === 0 && !folder.is_model"><span>FICHIER BATIREGISTRE</span></span>
                    <img [src]="getImage(folder.extension)" alt="Télécharger le fichier"/>
                  </div>

                  <div class="card-body">
                    <div class="row align-items-center">
                      <div class="col-auto align-self-start align-icon" *ngIf="view !== 'file'">
                        <i class="fe fe-folder" *ngIf="folder.generic_folders_type_id"></i>
                        <i class="fe fe-list" *ngIf="folder.generic_folder_id"></i>
                      </div>

                      <div class="col overflow-hidden">
                        <h4 class="card-title name text-wrap mb-0">
                          <!-- FOLDER -->
                          <a [routerLink]="['/document/public/' + folder.id]" *ngIf="folder.generic_folders_type_id">
                            {{ folder.name }}
                          </a>

                          <!-- FILE TYPE -->
                          <a [routerLink]="[updateRoute + '/file-type/' + folder.id + '/files']" *ngIf="folder.generic_folder_id">
                            {{ folder.name }}
                          </a>

                          <!-- FILE -->
                          <a [routerLink]="" (click)="downloadFile(folder.id, folder.name + '.' + folder.extension)" *ngIf="folder.generic_files_type_id && folder.extension !== 'mp4'">
                            {{ folder.name + '.' + folder.extension }}
                          </a>

                          <!-- VIDEO -->
                          <a [routerLink]="" (click)="showVideo(folder.id)" *ngIf="folder.generic_files_type_id && folder.extension === 'mp4'">
                            {{ folder.name + '.' + folder.extension }}
                          </a>
                        </h4>
                      </div>

                      <div class="col-auto align-self-start">
                        <div class="dropdown">
                          <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fe fe-more-vertical"></i>
                          </a>

                          <!-- FOLDER -->
                          <div class="dropdown-menu dropdown-menu-right" *ngIf="folder.generic_folders_type_id">
                            <a class="dropdown-item href" [routerLink]="['/document/public/' + folder.id]">
                              Consulter le contenu
                            </a>
                          </div>

                          <!-- FILE TYPE -->
                          <div class="dropdown-menu dropdown-menu-right" *ngIf="folder.generic_folder_id">
                            <a class="dropdown-item href" [routerLink]="[updateRoute + '/file-type/' + folder.id + '/files']">
                              {{ folder.file_is_unique ? 'Consulter le fichier' : 'Consulter les fichiers' }}
                            </a>
                          </div>

                          <!-- FILE -->
                          <div class="dropdown-menu dropdown-menu-right" *ngIf="folder.generic_files_type_id">
                            <a class="dropdown-item href" (click)="downloadFile(folder.id, folder.name + '.' + folder.extension)">
                              Télécharger le fichier
                            </a>
                          </div>

                          <!-- VIDEO -->
                          <div class="dropdown-menu dropdown-menu-right" *ngIf="folder.generic_files_type_id && folder.extension === 'mp4'">
                            <a class="dropdown-item href" (click)="showVideo(folder.id)">
                              Regarder la vidéo
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- VIEW CUSTOMER -->
          <div class="tab-pane fade active show" role="tabpanel" *ngIf="viewCustomer">
            <div class="row listAlias">
              <div class="col-12" *ngIf="selectedRequired.length && view !== 'file'">
                <div class="card bg-light border">
                  <div class="card-body">
                    <p class="mb-2">Exigences relatives aux types de fichiers</p>

                    <p class="small text-muted mb-2">
                      Des documents obligatoires n'ont pas été envoyés sur la plateforme :
                    </p>

                    <ul class="small text-muted pl-4 mb-0">
                      <li *ngFor="let fileType of selectedRequired">
                        {{ fileType.name }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 d-flex flex-column" *ngFor="let file of files">
                <div class="card flex-fill">
                  <div class="href image-file-download" *ngIf="!['mp4', 'pdf', 'png', 'jpeg', 'jpg'].includes(file.extension)" (click)="downloadFile(file.id, file.name + '.' + file.extension)">
                    <span class="ribbon" *ngIf="file.establishment_id === 0 && file.is_model"><span>MODELE BATIREGISTRE</span></span>
                    <span class="ribbon" *ngIf="file.establishment_id === 0 && !file.is_model"><span>FICHIER BATIREGISTRE</span></span>
                    <img [src]="getImage(file.extension)" alt="Télécharger le fichier"/>
                  </div>
                  <div class="href image-file-download" *ngIf="file.extension === 'mp4'" (click)="showVideo(file.id)">
                    <span class="ribbon" *ngIf="file.establishment_id === 0 && file.is_model"><span>MODELE BATIREGISTRE</span></span>
                    <span class="ribbon" *ngIf="file.establishment_id === 0 && !file.is_model"><span>FICHIER BATIREGISTRE</span></span>
                    <img [src]="getImage(file.extension)" alt="Télécharger le fichier"/>
                  </div>

                  <div class="href image-file-download" *ngIf="['pdf', 'png', 'jpeg', 'jpg'].includes(file.extension)" (click)="showPdf(file.file_url)">
                    <span class="ribbon" *ngIf="file.establishment_id === 0 && file.is_model"><span>MODELE BATIREGISTRE</span></span>
                    <span class="ribbon" *ngIf="file.establishment_id === 0 && !file.is_model"><span>FICHIER BATIREGISTRE</span></span>
                    <img [src]="getImage(file.extension)" alt="Visionner le PDF"/>
                  </div>

                  <div class="card-body">
                    <div class="row align-items-center">
                      <div class="col overflow-hidden">
                        <h4 class="card-title name text-wrap mb-0">
                          <a [routerLink]="" *ngIf="file.extension !== 'mp4' && file.extension !== 'pdf'" (click)="downloadFile(file.id, file.name + '.' + file.extension)">
                            {{ file.name + '.' + file.extension }}
                          </a>
                          <a [routerLink]="" *ngIf="file.extension === 'mp4'" (click)="showVideo(file.id)">
                            {{ file.name + '.' + file.extension }}
                          </a>
                          <a [routerLink]="" *ngIf="['pdf', 'png', 'jpeg', 'jpg'].includes(file.extension)" (click)="showPdf(file.file_url)">
                            {{ file.name + '.' + file.extension }}
                          </a>
                        </h4>
                      </div>

                      <div class="col-auto align-self-start">
                        <div class="dropdown">
                          <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fe fe-more-vertical"></i>
                          </a>

                          <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item href" [routerLink]="[updateRoute + 'files/' + file.id]">
                              {{ canUpdate ? 'Éditer le fichier' : 'Consulter le fichier' }}
                            </a>

                            <a class="dropdown-item href" (click)="downloadFile(file.id, file.name + '.' + file.extension)">
                              Télécharger le fichier
                            </a>

                            <a class="dropdown-item href" *ngIf="file.extension === 'mp4'" (click)="showVideo(file.id)">
                              Regarder la vidéo
                            </a>

                            <a class="dropdown-item href" *ngIf="file.extension === 'pdf'" (click)="showPdf(file.file_url)">
                              Visionner le PDF
                            </a>

                            <a class="dropdown-item href" *ngIf="['png', 'jpeg', 'jpg'].includes(file.extension)" (click)="showPdf(file.file_url)">
                              Visionner l'image
                            </a>

                            <a class="dropdown-item href" (click)="modalConfirmFile.open(file.id)" *ngIf="canUpdate">
                              Supprimer le fichier
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="w-100"></div>

              <div [ngClass]="view === 'file' ? 'col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 d-flex flex-column' : 'col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4 d-flex flex-column'" *ngFor="let folder of folders">
                <div class="card flex-fill" [ngClass]="{ 'is-important': folder.is_important }">
                  <!-- FOLDER + FILE TYPE + FILE -->
                  <div class="href image-file-download" *ngIf="view === 'file' && !['mp4', 'pdf', 'png', 'jpeg', 'jpg'].includes(folder.extension)" (click)="downloadFile(folder.id, folder.name + '.' + folder.extension)">
                    <span class="ribbon" *ngIf="folder.establishment_id === 0 && folder.is_model"><span>MODÈLE BATIREGISTRE</span></span>
                    <span class="ribbon" *ngIf="folder.establishment_id === 0 && !folder.is_model"><span>FICHIER BATIREGISTRE</span></span>
                    <img [src]="getImage(folder.extension)" alt="Télécharger le fichier"/>
                  </div>
                  <div class="href image-file-download" *ngIf="view === 'file' && folder.extension === 'mp4'" (click)="showVideo(folder.id)">
                    <span class="ribbon" *ngIf="folder.establishment_id === 0 && folder.is_model"><span>MODÈLE BATIREGISTRE</span></span>
                    <span class="ribbon" *ngIf="folder.establishment_id === 0 && !folder.is_model"><span>FICHIER BATIREGISTRE</span></span>
                    <img [src]="getImage(folder.extension)" alt="Télécharger le fichier"/>
                  </div>
                  <div class="href image-file-download" *ngIf="view === 'file' && ['pdf', 'png', 'jpeg', 'jpg'].includes(folder.extension)" (click)="showPdf(folder['file_url'])">
                    <span class="ribbon" *ngIf="folder.establishment_id === 0 && folder.is_model"><span>MODELE BATIREGISTRE</span></span>
                    <span class="ribbon" *ngIf="folder.establishment_id === 0 && !folder.is_model"><span>FICHIER BATIREGISTRE</span></span>
                    <img [src]="getImage(folder.extension)" alt="Visionner le PDF"/>
                  </div>

                  <!-- FOLDER + FILE TYPE + FILE -->
                  <div class="card-body">
                    <!--FOLDER BADGE-->
                    <span class="button__badge" *ngIf="folder.can_contain_file" [ngClass]="{ 'color-badge' : folder.generic_files.length }">
                      {{ !folder.generic_files.length ? '0' : folder.generic_files.length > 99 ? '99+' : folder.generic_files.length }}
                    </span>

                    <!--FILE TYPE BADGE-->
                    <span class="button__badge" *ngIf="folder.generic_folder_id && folder.file_is_public" [ngClass]="{ 'color-badge' : folder.generic_files.length }">
                      {{ !folder.generic_files.length ? '0' : folder.generic_files.length > 99 ? '99+' : folder.generic_files.length }}
                    </span>

                    <div class="row align-items-center">
                      <div class="col-auto align-self-start align-icon" *ngIf="view !== 'file'">
                        <i class="fe fe-folder" *ngIf="folder.generic_folders_type_id"></i>
                        <i class="fe fe-list" *ngIf="folder.generic_folder_id"></i>
                      </div>

                      <div class="col overflow-hidden">
                        <h4 class="card-title name text-wrap mb-0">
                          <span class="tooltipHtml" *ngIf="folder.file_is_required" title="Ce document est obligatoire">
                            <i class="fe fe-info"></i>
                          </span>

                          <!-- FOLDER -->
                          <a [routerLink]="[folder.can_contain_file && folder.generic_files.length === 0 ? customerRoute + '/document/' + folder.id + '/files/create' : customerRoute + '/document/' + folder.id]" *ngIf="folder.generic_folders_type_id && canUpdate">
                            {{ folder.name }}
                          </a>
                          <a [routerLink]="[folder.can_contain_file && folder.generic_files.length === 0 ? customerRoute + '/document/' + folder.id + '/files' : customerRoute + '/document/' + folder.id]" *ngIf="folder.generic_folders_type_id && !canUpdate">
                            {{ folder.name }}
                          </a>

                          <!-- FILE TYPE -->
                          <a [routerLink]="[folder.generic_files.length === 0 ? updateRoute + '/file-type/' + folder.id + '/files/create' : updateRoute + '/file-type/' + folder.id + '/files']" *ngIf="folder.generic_folder_id && canUpdate">
                            {{ folder.name }}
                          </a>
                          <a [routerLink]="[folder.generic_files.length === 0 ? updateRoute + '/file-type/' + folder.id + '/files' : updateRoute + '/file-type/' + folder.id + '/files']" *ngIf="folder.generic_folder_id && !canUpdate">
                            {{ folder.name }}
                          </a>


                          <!-- FILE -->
                          <a [routerLink]="" (click)="downloadFile(folder.id, folder.name + '.' + folder.extension)" *ngIf="folder.generic_files_type_id && !['mp4', 'pdf', 'png', 'jpeg', 'jpg'].includes(folder.extension)">
                            {{ folder.order_number + ' - ' + folder.name + '.' + folder.extension }}
                          </a>

                          <!-- VIDEO -->
                          <a [routerLink]="" (click)="showVideo(folder.id)" *ngIf="folder.generic_files_type_id && folder.extension === 'mp4'">
                            {{ folder.order_number + ' - ' + folder.name + '.' + folder.extension }}
                          </a>

                          <!-- PDF -->
                          <a [routerLink]="" *ngIf="['pdf', 'png', 'jpeg', 'jpg'].includes(folder.extension)" (click)="showPdf(folder['file_url'])">
                            {{ folder.order_number + ' - ' + folder.name + '.' + folder.extension }}
                          </a>

                        </h4>

                        <!-- FILE TYPE -->
                        <p class="card-text small text-truncate mb-0" *ngIf="folder.generic_folder_id && folder.generic_files && folder.generic_files.length && folder.count_files">
                          Document(s) envoyé(s) avec succès
                        </p>
                      </div>

                      <div class="col-auto align-self-start">
                        <div class="dropdown">
                          <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fe fe-more-vertical"></i>
                          </a>

                          <!-- FOLDER -->
                          <div class="dropdown-menu dropdown-menu-right" *ngIf="folder.generic_folders_type_id">
                            <a class="dropdown-item href" [routerLink]="[customerRoute + '/document/' + folder.id + '/files/create']" *ngIf="folder.can_contain_file">
                              Ajouter un fichier
                            </a>

                            <a class="dropdown-item href" [routerLink]="[customerRoute + '/document/' + folder.id]">
                              Consulter le contenu
                            </a>
                          </div>

                          <!-- FILE TYPE -->
                          <div class="dropdown-menu dropdown-menu-right" *ngIf="folder.generic_folder_id">
                            <a class="dropdown-item href" [routerLink]="[updateRoute + '/file-type/' + folder.id + '/files/create']" *ngIf="canUpdate">
                              {{ folder.file_is_unique ? 'Ajouter un fichier' : 'Ajouter des fichiers' }}
                            </a>

                            <a class="dropdown-item href" [routerLink]="[updateRoute + '/file-type/' + folder.id + '/files']">
                              {{ folder.file_is_unique ? 'Consulter le fichier' : 'Consulter les fichiers' }}
                            </a>
                          </div>

                          <!-- FILE -->
                          <div class="dropdown-menu dropdown-menu-right" *ngIf="folder.generic_files_type_id">
                            <a class="dropdown-item href" [routerLink]="[updateRoute + folder.id]" *ngIf="folder.establishment_id === establishment_id">
                              {{ canUpdate ? 'Éditer le fichier' : 'Consulter le fichier' }}
                            </a>

                            <a class="dropdown-item href" (click)="downloadFile(folder.id, folder.name + '.' + folder.extension)">
                              Télécharger le fichier
                            </a>

                            <a class="dropdown-item href" *ngIf="folder.extension === 'mp4'" (click)="showVideo(folder.id)">
                              Regarder la vidéo
                            </a>

                            <a class="dropdown-item href" *ngIf="folder.extension === 'pdf'" (click)="showPdf(folder['file_url'])">
                              Visionner le PDF
                            </a>

                            <a class="dropdown-item href" *ngIf="['png', 'jpeg', 'jpg'].includes(folder.extension)" (click)="showPdf(folder['file_url'])">
                              Visionner l'image
                            </a>

                            <a class="dropdown-item href" (click)="modalConfirmFile.open(folder.id)" *ngIf="folder.establishment_id === establishment_id && canUpdate">
                              Supprimer le fichier
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="loader-content" *ngIf="!loader && (!folders.length && !files.length)">
          <p>Aucun résultat</p>
        </div>
      </div>
    </div>
  </div>
</div>

<app-confirm-modal [type]="'delete_file'" #confirmFile>{{ bindModalConfirmFile(confirmFile) }}</app-confirm-modal>
<app-video-modal #video>{{ bindModalVideo(video) }}</app-video-modal>
<app-pdf-viewer #pdf>{{ bindModalPdf(pdf) }}</app-pdf-viewer>

<router-outlet></router-outlet>
