<div id="pagination" class="w-100 d-flex justify-content-between align-items-center">
  <div class="d-flex align-items-center">
    <div class="col-auto">
      <button class="btn btn-lg btn-white filter tooltipHtml" aria-label="Nombre d'éléments à afficher"
              data-toggle="dropdown" data-placement="bottom" title="Affichage par page">
        <span class="fe fe-sliders"></span>
      </button>

      <span class="dropdown-menu dropdown-menu-right">
          <a *ngFor="let dropdown_item of dropdown_items_by_page" class="dropdown-item href"
             [attr.aria-label]="'Afficher ' + dropdown_item + ' éléments'"
             (click)="returnAction('total_elements', dropdown_item)">
            {{ dropdown_item }}
          </a>
        </span>
    </div>

    <small class="col text-muted">
      Éléments {{ limit * (current_page - 1) + 1 }}-{{ limit * (current_page - 1) + elements_length}}
      sur {{ count_elements }}
    </small>
  </div>

  <nav>
    <ul class="pagination mb-0">
      <li class="page-item" [ngClass]="{ 'disabled': current_page <= 1 }"
          (click)="returnAction('change_page', (current_page - 1))">
        <a class="page-link"
           [attr.aria-label]="current_page <= 1  ? 'Bouton précédent : Désactivé, vous êtes déja sur la première page' : 'Aller à la page précédente'">
          Précédent
        </a>
      </li>
      <li class="page-item" *ngIf="current_page - 2 > 1" (click)="returnAction('change_page', (1))">
        <a class="page-link">1</a>
      </li>

      <li class="page-item" *ngIf="current_page - 3 > 1">
        <a class="page-link page-dotted">...</a>
      </li>

      <li class="page-item" *ngIf="current_page - 2 >= 1" (click)="returnAction('change_page', (current_page - 2))">
        <a class="page-link">{{ current_page - 2 }}</a>
      </li>
      <li class="page-item" *ngIf="current_page - 1 >= 1" (click)="returnAction('change_page', (current_page - 1))">
        <a class="page-link">{{ current_page - 1 }}</a>
      </li>

      <li class="page-item active">
        <a class="page-link disabled">{{ current_page }}</a>
      </li>

      <li class="page-item" *ngIf="current_page + 1 <= total_pages"
          (click)="returnAction('change_page', (current_page + 1))">
        <a class="page-link">{{ current_page + 1 }}</a>
      </li>
      <li class="page-item" *ngIf="current_page + 2 <= total_pages"
          (click)="returnAction('change_page', (current_page + 2))">
        <a class="page-link">{{ current_page + 2 }}</a>
      </li>

      <li class="page-item" *ngIf="current_page + 3 < total_pages"><a class="page-link page-dotted">...</a></li>

      <li class="page-item" *ngIf="current_page + 2 < total_pages" (click)="returnAction('change_page', total_pages)">
        <a class="page-link">{{ total_pages }}</a>
      </li>
      <li class="page-item" [ngClass]="{ 'disabled': current_page >= total_pages }"
          (click)="returnAction('change_page', (current_page + 1))">
        <a class="page-link"
           [attr.aria-label]="current_page >= total_pages ? 'Bouton suivant : Désactivé, vous êtes déja sur la dernière page' : 'Aller à la page suivante'">
          Suivant
        </a>
      </li>
    </ul>
  </nav>
</div>
