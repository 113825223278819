import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

import { UpdateSanitaryFrameComponent } from './update-sanitary-frame.component';
import { ModalRightModule } from '../../../core/modal-right/modal-right.module';
import { UtilsModule } from '../../../core/utils/utils.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    ModalRightModule,
    UtilsModule,
    NgbModule
  ],
  declarations: [UpdateSanitaryFrameComponent]
})

export class UpdateSanitaryFrameModule {}
