import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AllSanitaryFramesModule } from './all-sanitary-frames/all-sanitary-frames.module';
import { UpdateSanitaryFrameModule } from './update-sanitary-frame/update-sanitary-frame.module';

import { SanitaryFramesRequestService } from '../../services/sanitary-frames.request.service';

@NgModule({
  imports: [
    CommonModule,
    AllSanitaryFramesModule,
    UpdateSanitaryFrameModule,
  ],
  providers: [
    SanitaryFramesRequestService
  ]
})
export class SanitaryFramesModule {}
