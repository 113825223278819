import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Subject } from 'rxjs';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { CheckpointsRequestService } from '../../../services/checkpoints.request.service';
import { EnterprisesRequestService } from '../../../services/enterprises.request.service';
import { UsersRequestService } from '../../../services/users.request.service';
import Installation from '../../../../shared/interfaces/installation';
import {AllCheckpointsComponent} from '../all-checkpoints/all-checkpoints.component';

@Component({
  selector: 'app-update-checkpoint-custom',
  templateUrl: './update-checkpoint-custom.component.html',
  styleUrls: ['./update-checkpoint-custom.component.scss']
})
export class UpdateCheckpointCustomComponent implements OnInit, AfterViewInit, OnDestroy {
  modalCheckpoint = null;
  previousRoute = '';

  errors = {};
  view = '';

  enterprises: any = [];
  userReport: string;
  userObservation: string;

  checkpointForm: FormGroup;
  id = new FormControl('');
  name = new FormControl('', Validators.required);
  description = new FormControl('');
  frequencies = new FormControl([]);
  frequency_duration = new FormControl('', [Validators.required, Validators.min(0)]);
  frequency = new FormControl('', Validators.required);
  report_enterprise_id = new FormControl('');
  report_user_id = new FormControl('');
  observation_enterprise_id = new FormControl('');
  observation_user_id = new FormControl('');
  establishment_installation_id = new FormControl('', Validators.required);
  establishment_registry_id = new FormControl('');
  frequency_notification = new FormControl(true);
  frequency_alert = new FormControl(true);

  installations: Installation[];

  loader = false;
  loaderContent = false;

  requestInProgress = new Subject();
  requestCount = 0;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private allCheckpoints: AllCheckpointsComponent,
    private checkpointsService: CheckpointsRequestService,
    private enterprisesService: EnterprisesRequestService,
    private usersService: UsersRequestService
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loaderContent = this.requestCount > 0;
    });

    this.previousRoute = this.activatedRoute.snapshot.parent.url.join('/');

    this.checkpointForm = this.formBuilder.group({
      id: this.id,
      name: this.name,
      description: this.description,
      frequencies: this.frequencies,
      frequency: this.frequency,
      frequency_duration: this.frequency_duration,
      report_enterprise_id: this.report_enterprise_id,
      report_user_id: this.report_user_id,
      observation_enterprise_id: this.observation_enterprise_id,
      observation_user_id: this.observation_user_id,
      establishment_installation_id: this.establishment_installation_id,
      establishment_registry_id: this.establishment_registry_id,
      frequency_notification: this.frequency_notification,
      frequency_alert: this.frequency_alert,
    });

    this.establishment_installation_id.setValue(this.activatedRoute.snapshot.parent.params.installation_id);

    this.getEnterprises();
  }

  ngAfterViewInit() {
    this.modalCheckpoint.open();
  }

  ngOnDestroy() {
    this.modalCheckpoint.close();
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  getEnterprises() {
    this.requestInProgress.next(true);

    this.enterprisesService.getProviders('', '', '', '', '').subscribe(response => {
      if (response['success']) {
        response['enterprises'].forEach(enterprise => {
          this.getUsers(enterprise);
        });
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  getUsers(enterprise) {
    this.requestInProgress.next(true);

    this.usersService.getProviderUsers('', '', '', '', '', enterprise.id).subscribe(response => {
      if (response['success']) {
        response['users'].forEach(user => {
          this.enterprises.push({
            id: enterprise.id + '-' + user.id,
            name: enterprise.name + ' - ' + user.firstname + ' ' + user.lastname
          });
        });
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  changeUserReport() {
    if (this.userReport) {
      const split = this.userReport.split('-');
      this.report_enterprise_id.setValue(split[0]);
      this.report_user_id.setValue(split[1]);
    } else {
      this.report_enterprise_id.setValue(null);
      this.report_user_id.setValue(null);
    }
  }

  changeUserObservation() {
    if (this.userObservation) {
      const split = this.userObservation.split('-');
      this.observation_enterprise_id.setValue(split[0]);
      this.observation_user_id.setValue(split[1]);
    } else {
      this.observation_enterprise_id.setValue(null);
      this.observation_user_id.setValue(null);
    }
  }

  createCustomCheckpoint() {
    let request;

    this.errors = {};
    this.loader = true;

    request = this.checkpointsService.createCustomCheckpoint(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.checkpointForm.value
    );

    request.subscribe(response => {
      if (response['success']) {
        this.modalCheckpoint.close();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.checkpointForm, this.notifier);
      }
      this.loader = false;
    });
  }

  changeFrequency() {
    this.frequency.updateValueAndValidity();
    this.frequency_duration.updateValueAndValidity();
  }

  binModal(modal) {
    this.modalCheckpoint = modal;
  }

  closeModal() {
    this.allCheckpoints.refreshData();
    this.router.navigate([this.previousRoute]).then(() => {});
  }
}
