import { Routes } from "@angular/router";

import { AllMessagesComponent } from "./all-messages/all-messages.component";
import { UpdateMessageComponent } from "./update-message/update-message.component";
import { UserNotificationsComponent } from "./user-notifications/user-notifications.component";
import { PeriodicAlertsComponent } from "./periodic-alerts/periodic-alerts.component";
import { ReportsComponent } from "./reports/reports.component";
import { ObservationsComponent } from "./observations/observations.component";
import { ReportInterventionsComponent } from "./report-interventions/report-interventions.component";
import { InterventionsComponent } from "./interventions/interventions.component";
import { FormationsComponent } from "./formations/formations.component";

export const notificationsRoutes: Routes = [
  {
    path: "my-notifications",
    component: UserNotificationsComponent,
  },
  {
    path: "notification-center/mine/periodic-alerts",
    component: PeriodicAlertsComponent,
  },
  {
    path: "notification-center/mine/reports",
    component: ReportsComponent,
  },
  {
    path: "notification-center/mine/observations",
    component: ObservationsComponent,
  },
  {
    path: "notification-center/mine/report-interventions",
    component: ReportInterventionsComponent,
  },
  {
    path: "notification-center/mine/interventions",
    component: InterventionsComponent,
  },
  {
    path: "notification-center/mine/notification_formations",
    component: FormationsComponent,
  },
  {
    path: "notification-center/mine/messages",
    component: AllMessagesComponent,
    children: [
      {
        path: "create",
        component: UpdateMessageComponent,
      },
      {
        path: ":notification_id",
        component: UpdateMessageComponent,
      },
    ],
  },
  {
    path: "notification-center",
    component: AllMessagesComponent,
    children: [
      {
        path: "create",
        component: UpdateMessageComponent,
      },
      {
        path: ":notification_id",
        component: UpdateMessageComponent,
      },
    ],
  },
];
