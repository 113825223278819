import { Routes } from '@angular/router';

import { AllAgenciesComponent } from './all-agencies/all-agencies.component';
import { UpdateAgencyComponent } from './update-agency/update-agency.component';
import { UploadAgencyFileComponent } from './upload-agency-file/upload-agency-file.component';
import { ModalLogsComponent } from '../logs/modal-logs/modal-logs.component';

export const agenciesRoutes: Routes = [
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/agencies',
    component: AllAgenciesComponent,
    children: [
      {
        path: 'create',
        component: UpdateAgencyComponent
      },
      {
        path: ':commission_id',
        component: UpdateAgencyComponent
      },
      {
        path: ':commission_id/logs',
        component: ModalLogsComponent
      }
    ]
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/agencies/:commission_id/files',
    component: AllAgenciesComponent,
    children: [
      {
        path: 'create',
        component: UploadAgencyFileComponent
      },
      {
        path: ':file_id',
        component: UploadAgencyFileComponent
      }
    ]
  }
];
