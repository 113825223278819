import { AfterViewInit, Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { MapsAPILoader } from '@agm/core';

import { environment } from '../../../../environments/environment';
import { BHC_categories, ERP_subtypes, establishment_types, IGH_subtypes } from '../../../../shared/establishments';

import { AllEstablishmentsComponent } from '../all-establishments/all-establishments.component';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { RegistriesRequestService } from '../../../services/registries.request.service';
import { EstablishmentsRequestService } from '../../../services/establishments.request.service';

import Registry from '../../../../shared/interfaces/registry';
import EstablishmentType from '../../../../shared/interfaces/establishment_type';

@Component({
  selector: 'app-mass-import',
  templateUrl: './mass-import.component.html',
  styleUrls: ['./mass-import.component.scss']
})
export class MassImportComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('search') public searchElementRef: ElementRef;

  environment = environment;
  typesEstablishments = establishment_types;
  subTypesERP = ERP_subtypes;
  subTypesIGH = IGH_subtypes;
  categoriesBHC = BHC_categories;

  selectedERPSubTypes = [];
  selectedERPSubTypesFull = [];
  selectedIGHSubTypes = [];
  selectedIGHSubTypesFull = [];

  errors = {};

  view = '';
  previousRoute = '';
  children = false;

  registries: Registry[];
  selectedRegistries: number[];

  loader = false;
  loaderContent = false;

  modalEstablishment = null;

  establishmentForm: FormGroup;
  establishment_type_id = new FormControl('', Validators.required);
  establishment_subtype_establishments = new FormControl([]);
  establishment_category_id = new FormControl(null);
  establishment_subcategory_id = new FormControl(null);
  is_work_code = new FormControl('');

  file_base64 = new FormControl('');
  fileName = '';

  is_invalid = true;

  constructor(
    private ngZone: NgZone,
    private router: Router,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private mapsAPILoader: MapsAPILoader,
    private activatedRoute: ActivatedRoute,
    private registriesService: RegistriesRequestService,
    private allEstablishments: AllEstablishmentsComponent,
    private establishmentsService: EstablishmentsRequestService
  ) {}

  ngOnInit() {
    this.establishmentForm = this.formBuilder.group({
      establishment_category_id: this.establishment_category_id,
      establishment_subcategory_id: this.establishment_subcategory_id,
      establishment_subtype_establishments: this.establishment_subtype_establishments,
      establishment_type_id: this.establishment_type_id,
      is_work_code: this.is_work_code
    });

    this.getAvailableRegistries();
  }

  ngAfterViewInit() {
    this.modalEstablishment.open();
  }

  ngOnDestroy() {
    this.modalEstablishment.close();
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  getAvailableRegistries() {
    this.loaderContent = true;
    this.registriesService.getQuotaEstablishment(0, '').subscribe(response => {
      if (response['success']) {
        this.registries = response['registry_offers'].concat(response['registry_no_offers']);
        this.registries.forEach(registry => {
          registry['group'] = true;
        });
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loaderContent = false;
    });
  }
  getTemplate() {
    const subtypes = [];
    this.establishment_subtype_establishments.value.forEach(subtype => {
      subtypes.push(subtype.establishment_subtype_id);
    });
    this.loader = true;
    this.establishmentsService.getMassImportTemplate(
      this.establishment_type_id.value,
      subtypes.join(','),
      this.establishment_category_id.value,
      this.establishment_subcategory_id.value,
      this.selectedRegistries.join(','),
      this.is_work_code.value
    ).subscribe((response: BufferSource) => {
      this.loader = false;
      try {
        const decoder = new TextDecoder('utf-8');
        const domString = decoder.decode(response);
        const json = JSON.parse(domString);

        CustomNotifierService.getErrors(json['errors'], this.notifier);
      } catch (e) {
        const blob = new Blob([response]);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = 'Modèle import.xlsx';
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      }
    });
  }

  setTemplate() {
    let request;
    this.errors = {};
    this.loader = true;

    request = this.establishmentsService.setMassImportTemplate(
      this.file_base64.value
    );

    request.subscribe(response => {
      if (response['success']) {
        // this.modalEstablishment.close();
        CustomNotifierService.getSuccessImportWaitingEmail(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loader = false;
    });
  }

  chooseFile() {
    const element: HTMLElement = document.getElementById('inputFile') as HTMLElement;
    element.click();
  }
  onFileChange(event) {
    this.file_base64.setValue('');

    const reader = new FileReader();

    if (event.target && event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        const split = file.name.split('.');
        if (split.length && split[split.length - 1] === 'xlsx') {
          this.fileName = file.name;
          this.file_base64.setValue(reader.result);
        }
      };
    }
    const element: HTMLInputElement = document.getElementById('inputFile') as HTMLInputElement;
    element.value = '';
  }

  setSubType() {
    const subtypes = [];
    let selected = null;
    let selectedFull = null;

    if (this.establishment_type_id.value === 1) {
      selected = this.selectedERPSubTypes;
      selectedFull = this.selectedERPSubTypesFull;
    } else if (this.establishment_type_id.value === 4) {
      selected = this.selectedIGHSubTypes;
      selectedFull = this.selectedIGHSubTypesFull;
    }
    if (selected && selectedFull) {
      selected.forEach(selectedSubtype => {
        const find = selectedFull.find(element => {
          return element.establishment_subtype_id === selectedSubtype;
        });
        if (find) {
          subtypes.push(find);
        } else {
          subtypes.push({ establishment_subtype_id: selectedSubtype });
        }
      });
      this.establishment_subtype_establishments.setValue(subtypes);
    }
    this.validate();
  }
  changeTypeEstablishment(type: EstablishmentType) {
    if (type.requiredCategories) {
      this.establishment_category_id.setValidators([Validators.required]);
    } else {
      this.establishment_category_id.clearValidators();
    }
    if (type.requiredSubCategories) {
      this.establishment_subcategory_id.setValidators([Validators.required]);
    } else {
      this.establishment_subcategory_id.clearValidators();
    }
    if (type.requiredSubtypes) {
      this.establishment_subtype_establishments.setValidators([Validators.required]);
    } else {
      this.establishment_subtype_establishments.clearValidators();
    }

    if (type.id === 3) {
      this.is_work_code.setValue(false);
      this.is_work_code.disable();
    } else {
      if (type.id === 1 || type.id === 2) {
        this.is_work_code.setValue(true);
      } else if (type.id === 4) {
        this.is_work_code.setValue(false);
      }
      this.is_work_code.enable();
    }

    this.establishment_category_id.updateValueAndValidity();
    this.establishment_subcategory_id.updateValueAndValidity();
    this.establishment_subtype_establishments.updateValueAndValidity();
    this.establishment_type_id.setValue(type.id);
    this.setSubType();
  }
  validate($event = null) {
    this.is_invalid = true;
    if ($event && typeof $event === 'object' && !$event.length) {
      return;
    } else if ((!$event || typeof $event !== 'object') && (!this.selectedRegistries || !this.selectedRegistries.length)) {
      return;
    }
    if (!this.establishment_type_id.value) {
      return;
    }
    if (this.establishment_type_id.value === 1 &&
      (this.establishment_subcategory_id.value === null || this.establishment_subcategory_id.value === 'null')) {
      return;
    }
    if (this.establishment_type_id.value === 1 && !this.establishment_subtype_establishments.value.length) {
      return;
    }
    if (this.establishment_type_id.value === 3 &&
      (this.establishment_category_id.value === null || this.establishment_category_id.value === 'null')) {
      return;
    }
    if (this.establishment_type_id.value === 4 && !this.establishment_subtype_establishments.value.length) {
      return;
    }
    if (parseInt(this.establishment_subcategory_id.value, 10) === 5) {
      this.establishment_category_id.setValue(2);
    } else if ([1, 2, 3, 4].includes(parseInt(this.establishment_subcategory_id.value, 10))) {
      this.establishment_category_id.setValue(1);
    }
    this.is_invalid = false;
  }

  bindModal(modal) {
    this.modalEstablishment = modal;
  }
  closeModal() {
    this.allEstablishments.refreshData();
    this.router.navigate(['/establishments/mine/active']).then(() => {});
  }
}
