<app-modal-right (eventOnClose)="closeModal()" #installation>{{ bindModal(installation) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">{{ isNew ? 'Création' : canUpdate ? 'Mise à jour' : 'Consultation' }}</h6>

        <h1 class="header-title">Installation</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <form [formGroup]="sanitaryInstallationForm" [hidden]="loaderContent">

      <ul class="nav nav-tabs" role="tablist">
        <li class="nav-item col-3">
          <a class="nav-link href" [ngClass]="{ 'active': activeTab === 1}" (click)="changeTabs(1)">
            1 <i class="fe fe-info ml-2 tooltipHtml" title="Description de l'installation"></i>
          </a>
        </li>
        <li class="nav-item col-3">
          <a class="nav-link href" [ngClass]="{ 'active': activeTab === 2}" (click)="changeTabs(2)">
            2 <i class="fe fe-info ml-2 tooltipHtml" title="Eau froide"></i>
          </a>
        </li>
        <li class="nav-item col-3">
          <a class="nav-link href" [ngClass]="{ 'active': activeTab === 3}" (click)="changeTabs(3)">
            3 <i class="fe fe-info ml-2 tooltipHtml" title="Eau chaude - Partie 1"></i>
          </a>
        </li>
        <li class="nav-item col-3">
          <a class="nav-link href" [ngClass]="{ 'active': activeTab === 4}" (click)="changeTabs(4)">
            4 <i class="fe fe-info ml-2 tooltipHtml" title="Eau chaude - Partie 2"></i>
          </a>
        </li>
      </ul>

      <div class="tab-content">
        <!-- STEP 1 -->
        <div class="tab-pane fade" [ngClass]="{ 'show active': activeTab === 1}">
          <h2 class="header-title my-4">Description de l'installation</h2>
          <div class="form-group">
            <label for="description" required>Description</label>
            <input type="text" formControlName="description" class="form-control" id="description" placeholder="Description" [ngClass]="{ 'is-invalid': description.invalid && (description.dirty || description.touched) }">
          </div>
          <h2 class="header-title my-4">Alimentation</h2>
          <h3 class="header-title mb-4">Origine de l'alimentation en eau</h3>
          <!-- PUBLIC DISTRIBUTION -->
          <div class="form-group d-flex justify-content-start align-items-center mb-1">
            <div class="col pl-0">Distribution publique</div>
            <div class="custom-checkbox-toggle">
              <input type="checkbox" class="custom-control-input" id="public_distribution" formControlName="public_distribution" [ngClass]="{ 'backgroundColor': public_distribution.value }">
              <label class="custom-control-label" [ngClass]="{ 'backgroundColor': public_distribution.value }" for="public_distribution"></label>
            </div>
          </div>
          <p class="small text-muted mb-4">
            (Annexer la fiche d’information annuelle de qualité de l’eau distribuée avec la facture par le gestionnaire du réseau au présent document)
          </p>

          <!-- PRIVATE RESOURCE -->
          <h4 class="header-title mb-1">Ressource privée</h4>
          <div formGroupName="private_resources" *ngIf="sanitaryInstallationForm['controls'].private_resources['controls'] as private_resources">
            <div class="form-group d-flex justify-content-start align-items-center mb-1">
              <div class="col pl-0">Puits</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="well" formControlName="well" [ngClass]="{ 'backgroundColor': private_resources['well'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': private_resources['well'].value }" for="well"></label>
              </div>
            </div>
            <div class="form-group d-flex justify-content-start align-items-center mb-1">
              <div class="col pl-0">Forage</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="drilling" formControlName="drilling" [ngClass]="{ 'backgroundColor': private_resources['drilling'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': private_resources['drilling'].value }" for="drilling"></label>
              </div>
            </div>
            <p class="small text-muted mb-4">
              (Annexer l’autorisation préfectorale au présent document)
            </p>
          </div>
          <h2 class="header-title my-4">Diagnostic</h2>
          <!-- NETWORK DIAGNOSTIC -->
          <div formGroupName="diagnostic" *ngIf="sanitaryInstallationForm['controls'].diagnostic['controls'] as diagnostic">
            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Diagnostic des réseaux d’eau réalisés</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="network_diagnostic" formControlName="network_diagnostic" [ngClass]="{ 'backgroundColor': diagnostic['network_diagnostic'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': diagnostic['network_diagnostic'].value }" for="network_diagnostic"></label>
              </div>
            </div>
            <!-- REALISATION DATE -->
            <div class="form-group">
              <label for="realisationDate">Date de réalisation</label>
              <input type="text" formControlName="realisation_date" class="form-control" id="realisationDate" placeholder="Date de réalisation" data-toggle="flatpickr" [ngClass]="{ 'is-invalid': diagnostic['realisation_date'].invalid && (diagnostic['realisation_date'].dirty || diagnostic['realisation_date'].touched) }">
            </div>
            <!-- ACTUALISATION DATE -->
            <div class="form-group">
              <label for="actualisationDate">Date d'actualisation</label>
              <input type="text" formControlName="actualisation_date" class="form-control" id="actualisationDate" placeholder="Date d'actualisation" data-toggle="flatpickr" [ngClass]="{ 'is-invalid': diagnostic['actualisation_date'].invalid && (diagnostic['actualisation_date'].dirty || diagnostic['actualisation_date'].touched) }">
            </div>
            <!-- PROVIDERS -->
            <div class="form-group">
              <label for="validationProviders">Prestataire</label>
              <input type="text" formControlName="providers" class="form-control" id="validationProviders" placeholder="Prestataire" [ngClass]="{ 'is-invalid': diagnostic['providers'].invalid && (diagnostic['providers'].dirty || diagnostic['providers'].touched) }">
            </div>
            <!-- ANNEXED PLAN -->
            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Plan annexé au carnet sanitaire</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="annexedPlan" formControlName="annexed_plan" [ngClass]="{ 'backgroundColor': diagnostic['annexed_plan'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': diagnostic['annexed_plan'].value }" for="annexedPlan"></label>
              </div>
            </div>
            <div class="form-group" *ngIf="diagnostic['annexed_plan'].value">
              <label for="validationNoArchivingPlan">Lieu de l'archivage</label>
              <input type="text" formControlName="no_archiving_plan" class="form-control" id="validationNoArchivingPlan" placeholder="Lieu de l'archivage" [ngClass]="{ 'is-invalid': diagnostic['no_archiving_plan'].invalid && (diagnostic['no_archiving_plan'].dirty || diagnostic['no_archiving_plan'].touched) }">
            </div>
          </div>
          <!-- DOCUMENTS -->
          <h2 class="header-title mb-3">Documents</h2>
          <div class="form-group" *ngIf="isNew || canUpdate">
            <div class="dropzone dropzone-multiple dz-clickable" appDragDrop (dropHandler)="onFileChange($event)">
              <div class="dz-default dz-message" (click)="chooseFile()">
                <span>Déposer vos fichiers ici pour télécharger</span>
              </div>
              <input id="inputFile" (change)="onFileChange($event)" type="file" [hidden]="true" multiple>
            </div>
          </div>
          <div class="card" *ngFor="let file of files; let index = index">
            <div class="card-body">
              <div class="d-flex align-content-center justify-content-between">
                <h4 class="card-title mb-0 name align-self-center">
                  {{ file.name + '.' + file.extension }}
                </h4>

                <a class="href tooltipHtml" data-placement="bottom" title="Supprimer" (click)="removeFile(index)" *ngIf="isNew || canUpdate">
                  <i class="fe fe-trash"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <!-- STEP 2 -->
        <div class="tab-pane fade" [ngClass]="{ 'show active': activeTab === 2}">
          <h2 class="header-title my-4">Réseau eau froide (EF)</h2>
          <!-- CONSTITUENT MATERIALS -->
          <h4 class="header-title mb-1">Matériaux constitutifs</h4>
          <div formGroupName="constituent_materials_cold_water" *ngIf="sanitaryInstallationForm['controls'].constituent_materials_cold_water['controls'] as constituent_materials_cold_water">
            <div class="form-group d-flex justify-content-start align-items-center mb-1">
              <div class="col pl-0">Cuivre</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="copper_cold_water" formControlName="copper" [ngClass]="{ 'backgroundColor': constituent_materials_cold_water['copper'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': constituent_materials_cold_water['copper'].value }" for="copper_cold_water"></label>
              </div>
            </div>
            <div class="form-group d-flex justify-content-start align-items-center mb-1">
              <div class="col pl-0">Acier galvanisé</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="galvanized_steel_cold_water" formControlName="galvanized_steel" [ngClass]="{ 'backgroundColor': constituent_materials_cold_water['galvanized_steel'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': constituent_materials_cold_water['galvanized_steel'].value }" for="galvanized_steel_cold_water"></label>
              </div>
            </div>
            <div class="form-group d-flex justify-content-start align-items-center mb-1">
              <div class="col pl-0">PVC</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="pvc_cold_water" formControlName="pvc" [ngClass]="{ 'backgroundColor': constituent_materials_cold_water['pvc'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': constituent_materials_cold_water['pvc'].value }" for="pvc_cold_water"></label>
              </div>
            </div>
            <div class="form-group d-flex justify-content-start align-items-center" [ngClass]="{ 'mb-1' : constituent_materials_cold_water['other'].value }">
              <div class="col pl-0">Autre</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="other_materials_cold_water" formControlName="other" [ngClass]="{ 'backgroundColor': constituent_materials_cold_water['other'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': constituent_materials_cold_water['other'].value }" for="other_materials_cold_water"></label>
              </div>
            </div>
            <div class="form-group" *ngIf="constituent_materials_cold_water['other'].value">
              <label>Préciser</label>
              <textarea type="text" formControlName="other_detail" class="form-control" placeholder="Préciser"></textarea>
            </div>
          </div>
          <!-- NETWORK INTERCONNECTION -->
          <div formGroupName="interconnection_cold_water" *ngIf="sanitaryInstallationForm['controls'].interconnection_cold_water['controls'] as interconnection_cold_water">
            <div class="form-group d-flex justify-content-start align-items-center" [ngClass]="{ 'mb-1' : interconnection_cold_water['network'].value }">
              <h4 class="col pl-0">Existence d’interconnexions avec d’autres réseaux</h4>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="network_ef" formControlName="network" [ngClass]="{ 'backgroundColor': interconnection_cold_water['network'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': interconnection_cold_water['network'].value }" for="network_ef"></label>
              </div>
            </div>
            <!-- LOCALIZATION -->
            <div class="form-group" *ngIf="interconnection_cold_water['network'].value">
              <label for="validationNoArchivingPlan">Localisation</label>
              <textarea type="text" formControlName="localization" class="form-control" id="localization_cold_water" placeholder="Localisation" [ngClass]="{ 'is-invalid': interconnection_cold_water['localization'].invalid && (interconnection_cold_water['localization'].dirty || interconnection_cold_water['localization'].touched) }"></textarea>
            </div>
          </div>
          <!-- WATER DEVICES -->
          <h4 class="header-title mb-1">Appareils alimentés en eau</h4>
          <div formGroupName="water_devices_cold_water" *ngIf="sanitaryInstallationForm['controls'].water_devices_cold_water['controls'] as water_devices_cold_water">
            <div class="form-group d-flex justify-content-start align-items-center mb-1">
              <div class="col pl-0">Nettoyeurs vapeurs</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="steam_cleaners" formControlName="steam_cleaners" [ngClass]="{ 'backgroundColor': water_devices_cold_water['steam_cleaners'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': water_devices_cold_water['steam_cleaners'].value }" for="steam_cleaners"></label>
              </div>
            </div>
            <div class="form-group d-flex justify-content-start align-items-center mb-1">
              <div class="col pl-0">Fontaines réfrigérées</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="refrigerated_fountains" formControlName="refrigerated_fountains" [ngClass]="{ 'backgroundColor': water_devices_cold_water['refrigerated_fountains'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': water_devices_cold_water['refrigerated_fountains'].value }" for="refrigerated_fountains"></label>
              </div>
            </div>
            <div class="form-group d-flex justify-content-start align-items-center mb-1">
              <div class="col pl-0">Fontaines décoratives</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="decorative_fountains" formControlName="decorative_fountains" [ngClass]="{ 'backgroundColor': water_devices_cold_water['decorative_fountains'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': water_devices_cold_water['decorative_fountains'].value }" for="decorative_fountains"></label>
              </div>
            </div>
            <div class="form-group d-flex justify-content-start align-items-center mb-1">
              <div class="col pl-0">Distributeur de boissons</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="beverage_dispenser" formControlName="beverage_dispenser" [ngClass]="{ 'backgroundColor': water_devices_cold_water['beverage_dispenser'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': water_devices_cold_water['beverage_dispenser'].value }" for="beverage_dispenser"></label>
              </div>
            </div>
            <div class="form-group d-flex justify-content-start align-items-center mb-1">
              <div class="col pl-0">Brumisateur</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="mist" formControlName="mist" [ngClass]="{ 'backgroundColor': water_devices_cold_water['mist'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': water_devices_cold_water['mist'].value }" for="mist"></label>
              </div>
            </div>
            <div class="form-group d-flex justify-content-start align-items-center mb-1">
              <div class="col pl-0">Humidificateur d’air</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="air_humidifier" formControlName="air_humidifier" [ngClass]="{ 'backgroundColor': water_devices_cold_water['air_humidifier'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': water_devices_cold_water['air_humidifier'].value }" for="air_humidifier"></label>
              </div>
            </div>
            <div class="form-group d-flex justify-content-start align-items-center mb-1">
              <div class="col pl-0">Appareils d’oxygénothérapie</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="oxygen_therapy_devices" formControlName="oxygen_therapy_devices" [ngClass]="{ 'backgroundColor': water_devices_cold_water['oxygen_therapy_devices'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': water_devices_cold_water['oxygen_therapy_devices'].value }" for="oxygen_therapy_devices"></label>
              </div>
            </div>
            <div class="form-group d-flex justify-content-start align-items-center" [ngClass]="{ 'mb-1' : water_devices_cold_water['other'].value }">
              <div class="col pl-0">Autre</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="other_water_devices" formControlName="other" [ngClass]="{ 'backgroundColor': water_devices_cold_water['other'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': water_devices_cold_water['other'].value }" for="other_water_devices"></label>
              </div>
            </div>
            <div class="form-group" *ngIf="water_devices_cold_water['other'].value">
              <label>Préciser</label>
              <textarea type="text" formControlName="other_detail" class="form-control" placeholder="Préciser"></textarea>
            </div>

          </div>
          <!-- PIPE INSULATION -->
          <h4 class="header-title mb-1">Calorifugeages des canalisations</h4>
          <div formGroupName="pipe_insulation_cold_water" *ngIf="sanitaryInstallationForm['controls'].pipe_insulation_cold_water['controls'] as pipe_insulation_cold_water">
            <div class="form-group d-flex justify-content-start align-items-center mb-1">
              <div class="col pl-0">Intégral</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="integral" formControlName="integral" [ngClass]="{ 'backgroundColor': pipe_insulation_cold_water['integral'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': pipe_insulation_cold_water['integral'].value }" for="integral"></label>
              </div>
            </div>
            <div class="form-group d-flex justify-content-start align-items-center mb-1">
              <div class="col pl-0">Partiel</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="partial" formControlName="partial" [ngClass]="{ 'backgroundColor': pipe_insulation_cold_water['partial'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': pipe_insulation_cold_water['partial'].value }" for="partial"></label>
              </div>
            </div>
            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Inexistant</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="nonexistent" formControlName="nonexistent" [ngClass]="{ 'backgroundColor': pipe_insulation_cold_water['nonexistent'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': pipe_insulation_cold_water['nonexistent'].value }" for="nonexistent"></label>
              </div>
            </div>
          </div>
          <!-- PROTECTION DEVICES -->
          <h4 class="header-title mb-1">Existence de dispositifs de protection contre les retours d’eau (clapet, disconnecteur) à l’amont de chaque</h4>
          <div formGroupName="protection_devices" *ngIf="sanitaryInstallationForm['controls'].protection_devices['controls'] as protection_devices">
            <div class="form-group d-flex justify-content-start align-items-center mb-1">
              <div class="col pl-0">Arrivée générale d'eau froide</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="general_cold_water_supply" formControlName="general_cold_water_supply" [ngClass]="{ 'backgroundColor': protection_devices['general_cold_water_supply'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': protection_devices['general_cold_water_supply'].value }" for="general_cold_water_supply"></label>
              </div>
            </div>
            <div class="form-group d-flex justify-content-start align-items-center mb-1">
              <div class="col pl-0">Interconnexion entre les réseaux</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="network_interconnection" formControlName="network_interconnection" [ngClass]="{ 'backgroundColor': protection_devices['network_interconnection'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': protection_devices['network_interconnection'].value }" for="network_interconnection"></label>
              </div>
            </div>
            <div class="form-group d-flex justify-content-start align-items-center mb-1">
              <div class="col pl-0">Bâtiment</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="building" formControlName="building" [ngClass]="{ 'backgroundColor': protection_devices['building'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': protection_devices['building'].value }" for="building"></label>
              </div>
            </div>
            <div class="form-group d-flex justify-content-start align-items-center mb-1">
              <div class="col pl-0">Appareil alimenté par le réseau d'eau froid</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="device_supply_cold_water" formControlName="device_supply_cold_water" [ngClass]="{ 'backgroundColor': protection_devices['device_supply_cold_water'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': protection_devices['device_supply_cold_water'].value }" for="device_supply_cold_water"></label>
              </div>
            </div>
            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Dispositif de traitement</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="processing_device" formControlName="processing_device" [ngClass]="{ 'backgroundColor': protection_devices['processing_device'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': protection_devices['processing_device'].value }" for="processing_device"></label>
              </div>
            </div>
          </div>
          <h2 class="header-title my-3">Surveillance (EF)</h2>
          <!-- TEMPERATURE MEASURE EF -->
          <div formGroupName="level_measurements" *ngIf="sanitaryInstallationForm['controls'].level_measurements['controls'] as level_measurements">
            <div class="form-group d-flex justify-content-start align-items-center mb-1">
              <div class="col pl-0">Mesure de le température</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="temperature_measure" formControlName="temperature_measure" [ngClass]="{ 'backgroundColor': level_measurements['temperature_measure'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': level_measurements['temperature_measure'].value }" for="temperature_measure"></label>
              </div>
            </div>
            <ng-container *ngIf="level_measurements['temperature_measure'].value">
              <div class="form-group d-flex justify-content-start align-items-center mb-1">
                <div class="col pl-0">Au niveau du réseaux</div>
                <div class="custom-checkbox-toggle">
                  <input type="checkbox" class="custom-control-input" id="network" formControlName="network" [ngClass]="{ 'backgroundColor': level_measurements['network'].value }">
                  <label class="custom-control-label" [ngClass]="{ 'backgroundColor': level_measurements['network'].value }" for="network"></label>
                </div>
              </div>
              <div class="form-group" *ngIf="level_measurements['network'].value">
                <label>Fréquence</label>
                <input type="text" formControlName="network_frequency" class="form-control" placeholder="Fréquence">
              </div>
              <div class="form-group d-flex justify-content-start align-items-center mb-1">
                <div class="col pl-0">Au niveau des points d'usage</div>
                <div class="custom-checkbox-toggle">
                  <input type="checkbox" class="custom-control-input" id="usage_points" formControlName="usage_points" [ngClass]="{ 'backgroundColor': level_measurements['usage_points'].value }">
                  <label class="custom-control-label" [ngClass]="{ 'backgroundColor': level_measurements['usage_points'].value }" for="usage_points"></label>
                </div>
              </div>
              <div class="form-group" *ngIf="level_measurements['usage_points'].value">
                <label>Fréquence</label>
                <input type="text" formControlName="usage_points_frequency" class="form-control" placeholder="Fréquence">
              </div>
            </ng-container>
          </div>
          <!-- MICROBIOLOGICAL AND CHEMICAL ANALYZE COLD WATER -->
          <div formGroupName="analyze_cold_water" *ngIf="sanitaryInstallationForm['controls'].analyze_cold_water['controls'] as analyze_cold_water">
            <div class="form-group d-flex justify-content-start align-items-center mb-1">
              <div class="col pl-0">Analyses microbiologiques et chimiques effectuées sur le réseau</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="analyze" formControlName="analyze" [ngClass]="{ 'backgroundColor': analyze_cold_water['analyze'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': analyze_cold_water['analyze'].value }" for="analyze"></label>
              </div>
            </div>
            <div class="form-group mb-1" *ngIf="analyze_cold_water['analyze'].value">
              <label for="validationProviders">Fréquence</label>
              <input type="text" formControlName="frequency" class="form-control" id="frequency" placeholder="Fréquence" [ngClass]="{ 'is-invalid': analyze_cold_water['frequency'].invalid && (analyze_cold_water['frequency'].dirty || analyze_cold_water['frequency'].touched) }">
              <label for="validationProviders">Nature</label>
              <input type="text" formControlName="nature" class="form-control" id="nature" placeholder="Nature" [ngClass]="{ 'is-invalid': analyze_cold_water['nature'].invalid && (analyze_cold_water['nature'].dirty || analyze_cold_water['nature'].touched) }">
            </div>
            <p class="small text-muted mb-4">
              (Annexer les bulletins d'analyse au présent carnet)
            </p>
          </div>
          <!-- DOCUMENTS -->
          <h2 class="header-title mb-3">Documents</h2>
          <div class="form-group" *ngIf="isNew || canUpdate">
            <div class="dropzone dropzone-multiple dz-clickable" appDragDrop (dropHandler)="onFileChange($event)">
              <div class="dz-default dz-message" (click)="chooseFile()">
                <span>Déposer vos fichiers ici pour télécharger</span>
              </div>
              <input id="inputFileColdWater" (change)="onFileChange($event)" type="file" [hidden]="true" multiple>
            </div>
          </div>
          <div class="card" *ngFor="let file of files; let index = index">
            <div class="card-body">
              <div class="d-flex align-content-center justify-content-between">
                <h4 class="card-title mb-0 name align-self-center">
                  {{ file.name + '.' + file.extension }}
                </h4>

                <a class="href tooltipHtml" data-placement="bottom" title="Supprimer" (click)="removeFile(index)" *ngIf="isNew || canUpdate">
                  <i class="fe fe-trash"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <!-- STEP 3 -->
        <div class="tab-pane fade" [ngClass]="{ 'show active': activeTab === 3}">
          <h2 class="header-title my-4">Réseau eau chaude sanitaire (ECS) - Partie 1</h2>
          <!-- CONSTITUENT MATERIALS HOT WATER -->
          <h4 class="header-title mb-1">Matériaux constitutifs</h4>
          <div formGroupName="constituent_materials_hot_water" *ngIf="sanitaryInstallationForm['controls'].constituent_materials_hot_water['controls'] as constituent_materials_hot_water">
            <div class="form-group d-flex justify-content-start align-items-center mb-1">
              <div class="col pl-0">Cuivre</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="copper_hot_water" formControlName="copper" [ngClass]="{ 'backgroundColor': constituent_materials_hot_water['copper'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': constituent_materials_hot_water['copper'].value }" for="copper_hot_water"></label>
              </div>
            </div>
            <div class="form-group d-flex justify-content-start align-items-center mb-1">
              <div class="col pl-0">Acier galvanisé</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="galvanized_steel_hot_water" formControlName="galvanized_steel" [ngClass]="{ 'backgroundColor': constituent_materials_hot_water['galvanized_steel'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': constituent_materials_hot_water['galvanized_steel'].value }" for="galvanized_steel_hot_water"></label>
              </div>
            </div>
            <div class="form-group d-flex justify-content-start align-items-center mb-1">
              <div class="col pl-0">PVC</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="pvc_hot_water" formControlName="pvc" [ngClass]="{ 'backgroundColor': constituent_materials_hot_water['pvc'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': constituent_materials_hot_water['pvc'].value }" for="pvc_hot_water"></label>
              </div>
            </div>
            <div class="form-group d-flex justify-content-start align-items-center" [ngClass]="{ 'mb-1' : constituent_materials_hot_water['other'].value }">
              <div class="col pl-0">Autre</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="other_materials_hot_water" formControlName="other" [ngClass]="{ 'backgroundColor': constituent_materials_hot_water['other'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': constituent_materials_hot_water['other'].value }" for="other_materials_hot_water"></label>
              </div>
            </div>
            <div class="form-group" *ngIf="constituent_materials_hot_water['other'].value">
              <label>Préciser</label>
              <textarea type="text" formControlName="other_detail" class="form-control" placeholder="Préciser"></textarea>
            </div>
          </div>
          <!-- NETWORK INTERCONNECTION -->
          <div formGroupName="interconnection_hot_water" *ngIf="sanitaryInstallationForm['controls'].interconnection_hot_water['controls'] as interconnection_hot_water">
            <div class="form-group d-flex justify-content-start align-items-center" [ngClass]="{ 'mb-1' : interconnection_hot_water['network'].value }">
              <h4 class="col pl-0">Existence d’interconnexions avec d’autres réseaux</h4>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="network_ec" formControlName="network" [ngClass]="{ 'backgroundColor': interconnection_hot_water['network'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': interconnection_hot_water['network'].value }" for="network_ec"></label>
              </div>
            </div>
            <!-- LOCALIZATION -->
            <div class="form-group" *ngIf="interconnection_hot_water['network'].value">
              <label for="validationNoArchivingPlan">Localisation</label>
              <textarea type="text" formControlName="localization" class="form-control" id="localization_hot_water" placeholder="Localisation" [ngClass]="{ 'is-invalid': interconnection_hot_water['localization'].invalid && (interconnection_hot_water['localization'].dirty || interconnection_hot_water['localization'].touched) }"></textarea>
            </div>
          </div>
          <!-- WATER DEVICES HOT WATER -->
          <h4 class="header-title mb-1">Appareils alimentés en eau</h4>
          <div formGroupName="water_devices_hot_water" *ngIf="sanitaryInstallationForm['controls'].water_devices_hot_water['controls'] as water_devices_hot_water">
            <div class="form-group d-flex justify-content-start align-items-center mb-1">
              <div class="col pl-0">Lave-vaisselle</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="dishwasher" formControlName="dishwasher" [ngClass]="{ 'backgroundColor': water_devices_hot_water['dishwasher'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': water_devices_hot_water['dishwasher'].value }" for="dishwasher"></label>
              </div>
            </div>
            <div class="form-group d-flex justify-content-start align-items-center mb-1">
              <div class="col pl-0">Lave-linge</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="washing_line" formControlName="washing_line" [ngClass]="{ 'backgroundColor': water_devices_hot_water['washing_line'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': water_devices_hot_water['washing_line'].value }" for="washing_line"></label>
              </div>
            </div>
            <div class="form-group d-flex justify-content-start align-items-center mb-1">
              <div class="col pl-0">Baignoire balnéothérapie</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="spa_bath" formControlName="spa_bath" [ngClass]="{ 'backgroundColor': water_devices_hot_water['spa_bath'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': water_devices_hot_water['spa_bath'].value }" for="spa_bath"></label>
              </div>
            </div>
            <div class="form-group d-flex justify-content-start align-items-center mb-1">
              <div class="col pl-0">Spa</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="spa" formControlName="spa" [ngClass]="{ 'backgroundColor': water_devices_hot_water['spa'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': water_devices_hot_water['spa'].value }" for="spa"></label>
              </div>
            </div>
            <div class="form-group d-flex justify-content-start align-items-center" [ngClass]="{ 'mb-1' : water_devices_hot_water['other'].value }">
              <div class="col pl-0">Autre</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="other_water_devices_hot_water" formControlName="other" [ngClass]="{ 'backgroundColor': water_devices_hot_water['other'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': water_devices_hot_water['other'].value }" for="other_water_devices_hot_water"></label>
              </div>
            </div>
            <div class="form-group" *ngIf="water_devices_hot_water['other'].value">
              <label>Préciser</label>
              <textarea type="text" formControlName="other_detail" class="form-control" placeholder="Préciser"></textarea>
            </div>

          </div>
          <!-- PIPE INSULATION -->
          <h4 class="header-title mb-1">Calorifugeages des canalisations</h4>
          <div formGroupName="pipe_insulation_hot_water" *ngIf="sanitaryInstallationForm['controls'].pipe_insulation_hot_water['controls'] as pipe_insulation_hot_water">
            <div class="form-group d-flex justify-content-start align-items-center mb-1">
              <div class="col pl-0">Intégral</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="integral_hot_water" formControlName="integral" [ngClass]="{ 'backgroundColor': pipe_insulation_hot_water['integral'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': pipe_insulation_hot_water['integral'].value }" for="integral_hot_water"></label>
              </div>
            </div>
            <div class="form-group d-flex justify-content-start align-items-center mb-1">
              <div class="col pl-0">Partiel</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="partial_hot_water" formControlName="partial" [ngClass]="{ 'backgroundColor': pipe_insulation_hot_water['partial'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': pipe_insulation_hot_water['partial'].value }" for="partial_hot_water"></label>
              </div>
            </div>
            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Inexistant</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="nonexistent_hot_water" formControlName="nonexistent" [ngClass]="{ 'backgroundColor': pipe_insulation_hot_water['nonexistent'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': pipe_insulation_hot_water['nonexistent'].value }" for="nonexistent_hot_water"></label>
              </div>
            </div>
          </div>
          <!-- WITNESS HEADLINES -->
          <div class="form-group d-flex justify-content-start align-items-center mb-1">
            <div class="col pl-0">Existence de manchettes témoins</div>
            <div class="custom-checkbox-toggle">
              <input type="checkbox" class="custom-control-input" id="witness_headlines" formControlName="witness_headlines" [ngClass]="{ 'backgroundColor': witness_headlines.value }">
              <label class="custom-control-label" [ngClass]="{ 'backgroundColor': witness_headlines.value }" for="witness_headlines"></label>
            </div>
          </div>
          <!-- HEATING CORD -->
          <div class="form-group d-flex justify-content-start align-items-center">
            <div class="col pl-0">Cordon chauffant</div>
            <div class="custom-checkbox-toggle">
              <input type="checkbox" class="custom-control-input" id="heating_cord" formControlName="heating_cord" [ngClass]="{ 'backgroundColor': heating_cord.value }">
              <label class="custom-control-label" [ngClass]="{ 'backgroundColor': heating_cord.value }" for="heating_cord"></label>
            </div>
          </div>
          <!-- NETWORK TYPE -->
          <h4 class="header-title mb-1">Type de réseau d'ECS</h4>
          <div formGroupName="network_type" *ngIf="sanitaryInstallationForm['controls'].network_type['controls'] as network_type">
            <div class="form-group d-flex justify-content-start align-items-center mb-1">
              <div class="col pl-0">Linéaire</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="linear" formControlName="linear" [ngClass]="{ 'backgroundColor': network_type['linear'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': network_type['linear'].value }" for="linear"></label>
              </div>
            </div>
            <div class="form-group d-flex justify-content-start align-items-center mb-1">
              <div class="col pl-0">Bouclé partiellement</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="partially_closed" formControlName="partially_closed" [ngClass]="{ 'backgroundColor': network_type['partially_closed'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': network_type['partially_closed'].value }" for="partially_closed"></label>
              </div>
            </div>
            <div class="form-group" *ngIf="network_type['partially_closed'].value">
              <label>Nombre de boucles</label>
              <input type="number" formControlName="loop_number" class="form-control" placeholder="Nombre de boucles">
            </div>
            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Bouclé intégralement</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="fully_closed" formControlName="fully_closed" [ngClass]="{ 'backgroundColor': network_type['fully_closed'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': network_type['fully_closed'].value }" for="fully_closed"></label>
              </div>
            </div>
          </div>
        </div>
        <!-- STEP 4 -->
        <div class="tab-pane fade" [ngClass]="{ 'show active': activeTab === 4}">
          <h2 class="header-title my-4">Réseau eau chaude sanitaire (ECS) - Partie 2</h2>
          <h2 class="header-title mb-3">Production</h2>
          <!-- PRODUCTION SYSTEM -->
          <h4 class="header-title mb-1">Système de production d'ECS</h4>
          <div formGroupName="production_system" *ngIf="sanitaryInstallationForm['controls'].production_system['controls'] as production_system">
            <div class="form-group d-flex justify-content-start align-items-center mb-1">
              <div class="col pl-0">Par accumulation</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="by_accumulation" formControlName="by_accumulation" [ngClass]="{ 'backgroundColor': production_system['by_accumulation'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': production_system['by_accumulation'].value }" for="by_accumulation"></label>
              </div>
            </div>
            <div class="form-group" *ngIf="production_system['by_accumulation'].value">
              <label>Ballon</label>
              <input type="number" formControlName="by_accumulation_number" class="form-control" placeholder="Ballon - nombre">
            </div>
            <div class="form-group d-flex justify-content-start align-items-center mb-1">
              <div class="col pl-0">Semi instantanée (Échangeur + ballon < 400 L)</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="semi_instant_less" formControlName="semi_instant_less" [ngClass]="{ 'backgroundColor': production_system['semi_instant_less'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': production_system['semi_instant_less'].value }" for="semi_instant_less"></label>
              </div>
            </div>
            <div class="form-group" *ngIf="production_system['semi_instant_less'].value">
              <input type="number" formControlName="semi_instant_less_number" class="form-control" placeholder="Nombre d'échangeur">
            </div>
            <div class="form-group d-flex justify-content-start align-items-center mb-1">
              <div class="col pl-0">Semi instantanée (Échangeur + ballon > 400 L)</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="semi_instant_more" formControlName="semi_instant_more" [ngClass]="{ 'backgroundColor': production_system['semi_instant_more'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': production_system['semi_instant_more'].value }" for="semi_instant_more"></label>
              </div>
            </div>
            <div class="form-group" *ngIf="production_system['semi_instant_more'].value">
              <input type="number" formControlName="semi_instant_more_number" class="form-control" placeholder="Nombre d'échangeur">
            </div>
            <div class="form-group d-flex justify-content-start align-items-center" [ngClass]="{ 'mb-1' : production_system['instant'].value }">
              <div class="col pl-0">Instantanée</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="instant" formControlName="instant" [ngClass]="{ 'backgroundColor': production_system['instant'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': production_system['instant'].value }" for="instant"></label>
              </div>
            </div>
            <div class="form-group" *ngIf="production_system['instant'].value">
              <input type="number" formControlName="instant_number" class="form-control" placeholder="Nombre d'échangeur">
            </div>
          </div>
          <!-- ECS TEMPERATURE -->
          <div class="form-group">
            <label for="validationNoArchivingPlan">Température de l'ECS en sortie de production</label>
            <input type="number" formControlName="ecs_temperature" class="form-control" id="ecs_temperature" placeholder="°C" [ngClass]="{ 'is-invalid': ecs_temperature.invalid && (ecs_temperature.dirty || ecs_temperature.touched) }">
            <div *ngIf="ecs_temperature.invalid && (ecs_temperature.dirty || ecs_temperature.touched)" class="invalid-feedback">
              {{ getErrorMessage("ecs_temperature") }}
            </div>
          </div>
          <!-- BALLOON -->
          <h4 class="header-title mb-1">Ballon</h4>
          <div formGroupName="balloon" *ngIf="sanitaryInstallationForm['controls'].balloon['controls'] as balloon">
            <div class="form-group d-flex justify-content-start align-items-center mb-1">
              <div class="col pl-0">En série</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="serial" formControlName="serial" [ngClass]="{ 'backgroundColor': balloon['serial'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': balloon['serial'].value }" for="serial"></label>
              </div>
            </div>
            <div class="form-group d-flex justify-content-start align-items-center mb-1">
              <div class="col pl-0">En parallèle</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="in_parallel" formControlName="in_parallel" [ngClass]="{ 'backgroundColor': balloon['in_parallel'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': balloon['in_parallel'].value }" for="in_parallel"></label>
              </div>
            </div>
            <div class="form-group d-flex justify-content-start align-items-center mb-1">
              <div class="col pl-0">Avec vanne de vidange point bas</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="drain_valve" formControlName="drain_valve" [ngClass]="{ 'backgroundColor': balloon['drain_valve'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': balloon['drain_valve'].value }" for="drain_valve"></label>
              </div>
            </div>
            <div class="form-group d-flex justify-content-start align-items-center mb-1">
              <div class="col pl-0">Avec accès</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="access" formControlName="access" [ngClass]="{ 'backgroundColor': balloon['access'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': balloon['access'].value }" for="access"></label>
              </div>
            </div>
            <div class="form-group d-flex justify-content-start align-items-center mb-1" *ngIf="balloon['access'].value">
              <div class="col pl-0">Trappe de visite</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="trap_door" formControlName="trap_door" [ngClass]="{ 'backgroundColor': balloon['trap_door'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': balloon['trap_door'].value }" for="trap_door"></label>
              </div>
            </div>
            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Avec purge d'air</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="air_purge" formControlName="air_purge" [ngClass]="{ 'backgroundColor': balloon['air_purge'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': balloon['air_purge'].value }" for="air_purge"></label>
              </div>
            </div>
          </div>
          <!-- SURVEILLANCE -->
          <h2 class="header-title mb-3">Surveillance</h2>
          <!-- TEMPERATURE MEASURE ECS -->
          <h4 class="header-title mb-1">Mesure de la température</h4>
          <div formGroupName="temperature_measurement" *ngIf="sanitaryInstallationForm['controls'].temperature_measurement['controls'] as temperature_measurement">
            <div class="form-group d-flex justify-content-start align-items-center mb-1">
              <div class="col pl-0">Par accumulation</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="production_outlet" formControlName="production_outlet" [ngClass]="{ 'backgroundColor': temperature_measurement['production_outlet'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': temperature_measurement['production_outlet'].value }" for="production_outlet"></label>
              </div>
            </div>
            <div class="form-group" *ngIf="temperature_measurement['production_outlet'].value">
              <label>Fréquence</label>
              <input type="text" formControlName="production_outlet_frequency" class="form-control" placeholder="Fréquence">
            </div>
            <div class="form-group d-flex justify-content-start align-items-center mb-1">
              <div class="col pl-0">Retour de boucle</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="loop_return" formControlName="loop_return" [ngClass]="{ 'backgroundColor': temperature_measurement['loop_return'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': temperature_measurement['loop_return'].value }" for="loop_return"></label>
              </div>
            </div>
            <div class="form-group" *ngIf="temperature_measurement['loop_return'].value">
              <label>Fréquence</label>
              <input type="text" formControlName="loop_return_frequency" class="form-control" placeholder="Fréquence">
            </div>
            <div class="form-group d-flex justify-content-start align-items-center" [ngClass]="{ 'mb-1' : temperature_measurement['usage_point'].value }">
              <div class="col pl-0">Point d'usage</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="usage_point" formControlName="usage_point" [ngClass]="{ 'backgroundColor': temperature_measurement['usage_point'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': temperature_measurement['usage_point'].value }" for="usage_point"></label>
              </div>
            </div>
            <div class="form-group" *ngIf="temperature_measurement['usage_point'].value">
              <label>Fréquence</label>
              <input type="text" formControlName="usage_point_frequency" class="form-control" placeholder="Fréquence">
            </div>
          </div>
          <!-- MICROBIOLOGICAL AND CHEMICAL ANALYZE HOT WATER -->
          <h4 class="header-title mb-1">Analyses microbiologiques et chimiques effectuées sur le réseau</h4>
          <div formGroupName="analyze_hot_water" *ngIf="sanitaryInstallationForm['controls'].analyze_hot_water['controls'] as analyze_hot_water">
            <div class="form-group d-flex justify-content-start align-items-center mb-1">
              <div class="col pl-0">Recherche de légionelles</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="legionella_research" formControlName="legionella_research" [ngClass]="{ 'backgroundColor': analyze_hot_water['legionella_research'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': analyze_hot_water['legionella_research'].value }" for="legionella_research"></label>
              </div>
            </div>
            <div class="form-group" *ngIf="analyze_hot_water['legionella_research'].value">
              <label>Lieu</label>
              <input type="text" formControlName="legionella_research_place" class="form-control" placeholder="Lieu">
              <label>Fréquence</label>
              <input type="text" formControlName="legionella_research_frequency" class="form-control" placeholder="Fréquence">
            </div>
            <div class="form-group d-flex justify-content-start align-items-center mb-1">
              <div class="col pl-0">Autres analyses</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="other_analyze" formControlName="other_analyze" [ngClass]="{ 'backgroundColor': analyze_hot_water['other_analyze'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': analyze_hot_water['other_analyze'].value }" for="other_analyze"></label>
              </div>
            </div>
            <div class="form-group mb-1" *ngIf="analyze_hot_water['other_analyze'].value">
              <label>Préciser</label>
              <textarea type="text" formControlName="other_analyze_detail" class="form-control" placeholder="Préciser"></textarea>
              <label>Lieu</label>
              <input type="text" formControlName="other_analyze_place" class="form-control" placeholder="Lieu">
              <label>Fréquence</label>
              <input type="text" formControlName="other_analyze_frequency" class="form-control" placeholder="Fréquence">
            </div>
            <p class="small text-muted mb-4">
              (Annexer les bulletins d'analyses au présent carnet)
            </p>
          </div>
          <!-- TREATMENTS -->
          <h2 class="header-title mb-3">Traitements</h2>
          <!-- TREATMENT TYPE -->
          <h4 class="header-title mb-1">Type de traitement installé (s'il y a lieu)</h4>
          <div formGroupName="installed_treatment_type" *ngIf="sanitaryInstallationForm['controls'].installed_treatment_type['controls'] as installed_treatment_type">
            <div class="form-group d-flex justify-content-start align-items-center mb-1">
              <div class="col pl-0">Adoussicement</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="sweetly" formControlName="sweetly" [ngClass]="{ 'backgroundColor': installed_treatment_type['sweetly'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': installed_treatment_type['sweetly'].value }" for="sweetly"></label>
              </div>
            </div>
            <div class="form-group" *ngIf="installed_treatment_type['sweetly'].value">
              <label>Nom du produit</label>
              <input type="text" formControlName="sweetly_name" class="form-control" placeholder="Nom du produit">
            </div>
            <div class="form-group d-flex justify-content-start align-items-center mb-1">
              <div class="col pl-0">Anti-corrosion</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="anti_corrosion" formControlName="anti_corrosion" [ngClass]="{ 'backgroundColor': installed_treatment_type['anti_corrosion'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': installed_treatment_type['anti_corrosion'].value }" for="anti_corrosion"></label>
              </div>
            </div>
            <div class="form-group" *ngIf="installed_treatment_type['anti_corrosion'].value">
              <label>Nom du produit</label>
              <input type="text" formControlName="anti_corrosion_name" class="form-control" placeholder="Nom du produit">
            </div>
            <div class="form-group d-flex justify-content-start align-items-center mb-1">
              <div class="col pl-0">Désinfection</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="disinfection" formControlName="disinfection" [ngClass]="{ 'backgroundColor': installed_treatment_type['disinfection'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': installed_treatment_type['disinfection'].value }" for="disinfection"></label>
              </div>
            </div>
            <div class="form-group" *ngIf="installed_treatment_type['disinfection'].value">
              <label>Nom du produit</label>
              <input type="text" formControlName="disinfection_name" class="form-control" placeholder="Nom du produit">
            </div>
            <div class="form-group d-flex justify-content-start align-items-center mb-1">
              <div class="col pl-0">Continue</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="continue" formControlName="continue" [ngClass]="{ 'backgroundColor': installed_treatment_type['continue'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': installed_treatment_type['continue'].value }" for="continue"></label>
              </div>
            </div>
            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Discontinue</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="discontinue" formControlName="discontinue" [ngClass]="{ 'backgroundColor': installed_treatment_type['discontinue'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': installed_treatment_type['discontinue'].value }" for="discontinue"></label>
              </div>
            </div>
          </div>
          <!-- DISTRIBUTION -->
          <h2 class="header-title mb-3">Distribution</h2>
          <div formGroupName="mixer" *ngIf="sanitaryInstallationForm['controls'].mixer['controls'] as mixer">
            <div class="form-group d-flex justify-content-start align-items-center mb-1">
              <div class="col pl-0">Mitigeur en aval de la production</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="downstream_mixer" formControlName="downstream_mixer" [ngClass]="{ 'backgroundColor': mixer['downstream_mixer'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': mixer['downstream_mixer'].value }" for="downstream_mixer"></label>
              </div>
            </div>
            <div class="form-group" *ngIf="mixer['downstream_mixer'].value">
              <label>Préciser la température</label>
              <input type="number" formControlName="temperature" class="form-control" placeholder="°C">
            </div>
          </div>
          <div class="form-group d-flex justify-content-start align-items-center mb-1">
            <div class="col pl-0">Mitigeurs au niveau de chaque points d'usage</div>
            <div class="custom-checkbox-toggle">
              <input type="checkbox" class="custom-control-input" id="usage_point_mixer" formControlName="usage_point_mixer" [ngClass]="{ 'backgroundColor': usage_point_mixer.value }">
              <label class="custom-control-label" [ngClass]="{ 'backgroundColor': usage_point_mixer.value }" for="usage_point_mixer"></label>
            </div>
          </div>
          <div formGroupName="device" *ngIf="sanitaryInstallationForm['controls'].device['controls'] as device">
            <div class="form-group d-flex justify-content-start align-items-center" [ngClass]="{ 'mb-1' : device['regular_device'].value }">
              <div class="col pl-0">Changement régulier des périphériques (Pommeaux de douce, flexible, mousseurs...)</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="regular_device" formControlName="regular_device" [ngClass]="{ 'backgroundColor': device['regular_device'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': device['regular_device'].value }" for="regular_device"></label>
              </div>
            </div>
            <div class="form-group" *ngIf="device['regular_device'].value">
              <label>Fréquence</label>
              <input type="text" formControlName="frequency" class="form-control" placeholder="Fréquence">
            </div>
          </div>
          <!-- SHOWER NUMBER -->
          <h4 class="header-title mb-1">Nombre de douche</h4>
          <div formGroupName="shower_number" *ngIf="sanitaryInstallationForm['controls'].shower_number['controls'] as shower_number">
            <div class="form-group d-flex justify-content-start align-items-center mb-1">
              <div class="col pl-0">Individuelle</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="individual_shower" formControlName="individual_shower" [ngClass]="{ 'backgroundColor': shower_number['individual_shower'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': shower_number['individual_shower'].value }" for="individual_shower"></label>
              </div>
            </div>
            <div class="form-group" *ngIf="shower_number['individual_shower'].value">
              <label>Nombre</label>
              <input type="number" formControlName="individual_shower_number" class="form-control" placeholder="Nombre">
            </div>
            <div class="form-group d-flex justify-content-start align-items-center" [ngClass]="{ 'mb-1' : shower_number['collective_shower'].value }">
              <div class="col pl-0">Collectives</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="collective_shower" formControlName="collective_shower" [ngClass]="{ 'backgroundColor': shower_number['collective_shower'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': shower_number['collective_shower'].value }" for="collective_shower"></label>
              </div>
            </div>
            <div class="form-group" *ngIf="shower_number['collective_shower'].value">
              <label>Nombre</label>
              <input type="number" formControlName="collective_shower_number" class="form-control" placeholder="Nombre">
            </div>
          </div>
          <!-- DOCUMENT -->
          <h2 class="header-title mb-3">Documents</h2>
          <div class="form-group" *ngIf="isNew || canUpdate">
            <div class="dropzone dropzone-multiple dz-clickable" appDragDrop (dropHandler)="onFileChange($event)">
              <div class="dz-default dz-message" (click)="chooseFile()">
                <span>Déposer vos fichiers ici pour télécharger</span>
              </div>
              <input id="inputFileHotWaterPart2" (change)="onFileChange($event)" type="file" [hidden]="true" multiple>
            </div>
          </div>
          <div class="card" *ngFor="let file of files; let index = index">
            <div class="card-body">
              <div class="d-flex align-content-center justify-content-between">
                <h4 class="card-title mb-0 name align-self-center">
                  {{ file.name + '.' + file.extension }}
                </h4>

                <a class="href tooltipHtml" data-placement="bottom" title="Supprimer" (click)="removeFile(index)" *ngIf="isNew || canUpdate">
                  <i class="fe fe-trash"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div modal-footer class="w-100" *ngIf="isNew || canUpdate">
    <button class="btn btn-primary w-100" (click)="isNew ? createSanitaryInstallation() : updateSanitaryInstallation()" [ngClass]="{ 'is-loading is-loading-sm': loader }" [disabled]="loader || sanitaryInstallationForm.invalid">
      {{ isNew ? 'Créer' : 'Mettre à jour' }}
    </button>
  </div>
</app-modal-right>
