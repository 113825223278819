import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-searchbar',
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.scss']
})
export class SearchbarComponent implements OnInit {
  @Input() search: any;
  @Input() aria_label: any;
  @Input() placeholder_text: string;
  @Input() searchbar_id: string;
  @Input() searchbar_name: string;

  constructor() { }

  ngOnInit() {
  }
}
