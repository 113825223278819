import { Routes } from '@angular/router';

import { AllEmployeesComponent } from './all-employees/all-employees.component';
import { UpdateEmployeeComponent } from './update-employee/update-employee.component';
import { UploadFileComponent } from './upload-file/upload-file.component';

export const employeesRoutes: Routes = [
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/employees',
    component: AllEmployeesComponent,
    children: [
      {
        path: 'create',
        component: UpdateEmployeeComponent
      },
      {
        path: 'sheet',
        component: UploadFileComponent
      },
      {
        path: ':employee_id',
        component: UpdateEmployeeComponent
      }
    ]
  }
];
