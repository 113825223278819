import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { AccountComponent } from './account.component';
import { UtilsModule } from '../../core/utils/utils.module';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    UtilsModule,
    NgSelectModule
  ],
  declarations: [AccountComponent]
})

export class AccountModule {}
