import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { CheckpointsRequestService } from '../../../services/checkpoints.request.service';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../core/auth/auth.service';
import { Subscription } from 'rxjs';
import { NotifierService } from 'angular-notifier';
import { AccessProviderCheckpointsComponent } from '../access-provider-checkpoints/access-provider-checkpoints.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EstablishmentsRequestService } from '../../../services/establishments.request.service';
import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { ModalRightComponent } from '../../../core/modal-right/modal-right.component';

@Component({
  selector: 'app-access-provider-pattern',
  templateUrl: './access-provider-pattern.component.html',
  styleUrls: ['./access-provider-pattern.component.scss']
})
export class AccessProviderPatternComponent implements OnInit, AfterViewInit, OnDestroy {
  changeEnterprise: Subscription;
  environment = environment;

  canUpdate = false;
  canCreate = false;

  loader = false;
  loaderContent = false;
  errors = {};

  pageName: string;
  previousPageName: string[] = [];
  previousPageRoute: string[] = [];

  user_id: number;
  enterprise_id: number;
  establishments: any = [];
  establishmentsSelected: any = [];
  establishment: any;

  modal: ModalRightComponent = null;

  patternForm: FormGroup;
  establishment_id = new FormControl(null, Validators.required);

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private notifier: NotifierService,
    private accessProviderCheckpoints: AccessProviderCheckpointsComponent,
    private establishmentsService: EstablishmentsRequestService,
    private checkpointsService: CheckpointsRequestService
  ) {}

  ngOnInit() {
    this.changeEnterprise = this.authService.getEnterprise().subscribe(() => {
      this.user_id = parseInt(this.activatedRoute.parent.snapshot.params.user_id, 10);
      this.enterprise_id = parseInt(this.activatedRoute.parent.snapshot.params.enterprise_id, 10);
      this.getEstablishments();
    });

    if (this.authService.loaded) {
      this.user_id = parseInt(this.activatedRoute.parent.snapshot.params.user_id, 10);
      this.enterprise_id = parseInt(this.activatedRoute.parent.snapshot.params.enterprise_id, 10);
      this.getEstablishments();
    }

    this.patternForm = this.formBuilder.group({
      establishment_id: this.establishment_id,
    });
  }

  ngAfterViewInit() {
    this.modal.open();
  }

  ngOnDestroy() {
    this.changeEnterprise.unsubscribe();

    this.modal.close();
  }

  getEstablishments() {
    this.loaderContent = true;

    this.establishmentsService.getMyEstablishments('', '', '', '', '').subscribe(response => {
      if (response['success']) {
        response['establishments'].forEach(establishment => {
          establishment.group = true;
          this.establishments.push(establishment);
        });
      }
      this.loaderContent = false;
    });
  }

  sendPattern() {
    if (this.establishment_id.value && this.establishmentsSelected.length) {
      this.loader = true;

      this.checkpointsService.updatePatternForAccess(
        this.establishment_id.value,
        this.enterprise_id,
        this.user_id,
        this.establishmentsSelected
      ).subscribe(response => {
        if (response['success']) {
          CustomNotifierService.getSuccessUpdate(this.notifier);
          this.modal.close();
        }
        this.loader = false;
      });
    }
  }

  bindModal(modal) {
    this.modal = modal;
  }

  closeModal() {
    this.router.navigate([this.activatedRoute.snapshot.parent.url.join('/')]).then(() => {
      this.accessProviderCheckpoints.getCheckpoints();
    });
  }
}
