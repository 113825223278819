import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { frequencies } from '../../../../shared/frequencies';

import { AuthService } from '../../../core/auth/auth.service';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { ConfirmModalComponent } from '../../../core/confirm-modal/confirm-modal.component';

import sanitaryCheckpoint from '../../../../shared/interfaces/sanitary-checkpoint';
import Frequency from '../../../../shared/interfaces/frequency';
import { establishment_types } from '../../../../shared/establishments';
import { SanitaryCheckpointsRequestService } from '../../../services/sanitary-checkpoints.request.service';

@Component({
  selector: 'app-all-sanitary-checkpoints',
  templateUrl: './all-sanitary-checkpoints.component.html',
  styleUrls: ['./all-sanitary-checkpoints.component.scss']
})
export class AllSanitaryCheckpointsComponent implements OnInit, AfterViewInit, OnDestroy {
  changeEnterprise: Subscription;
  environment = environment;
  establishment_types = establishment_types;

  canUpdate = false;
  canCreate = false;
  canDelete = false;
  canLog = false;

  requestInProgress = new Subject();
  requestCount = 0;

  pageName: string;
  previousPageName: string[] = [];
  previousPageRoute: string[] = [];
  currentRoute = '';

  sanitaryCheckpoints: sanitaryCheckpoint[] = [];
  count = 0;
  pages = 1;

  search = '';
  sort = 'SanitaryCheckpoints.type';
  order = 'asc';
  limit = 12;
  page = 1;

  show_inactive = false;

  view = '';
  errors = {};
  timeout: any;
  loader = false;

  modalConfirmData: ConfirmModalComponent = null;

  constructor(
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private sanitaryCheckpointsService: SanitaryCheckpointsRequestService
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loader = this.requestCount > 0;
    });

    this.changeEnterprise = this.authService.getEnterprise().subscribe(() => {
      this.getSanitaryCheckpoints();
    });

    this.createBreadcrumbs();
    if (this.authService.loaded) {
      this.getSanitaryCheckpoints();
    }
  }

  ngAfterViewInit() {
    this.modalConfirmData.eventOnClose.subscribe(event => {
      if (event) {
        let request;
        this.requestInProgress.next(true);

        request = this.sanitaryCheckpointsService.deleteSanitaryCheckpoint(
          event
        );

        request.subscribe(response => {
          if (response['success']) {
            this.getSanitaryCheckpoints();
            CustomNotifierService.getSuccessDelete(this.notifier);
          } else if (response['errors']) {
            CustomNotifierService.getErrors(response['errors'], this.notifier);
          }
          this.requestInProgress.next(false);
        });
      }
    });
  }

  ngOnDestroy() {
    this.changeEnterprise.unsubscribe();

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  searchData() {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.page = 1;
      this.refreshData();
    }, 500);
  }

  refreshData(sort = this.sort, order = this.order, page = this.page) {
    this.sort = sort;
    this.order = order;
    this.page = page;
    this.getSanitaryCheckpoints();
  }

  getSanitaryCheckpoints() {
    this.requestInProgress.next(true);
    let request;

    request = this.sanitaryCheckpointsService.getAllSanitaryCheckpoints(this.search, this.sort, this.order, this.limit, this.page);

    request.subscribe(response => {
      if (response['success']) {
        this.canUpdate = response['canUpdate'];
        this.canCreate = response['canCreate'];
        this.canDelete = response['canDelete'];
        this.canLog = response['canLog'];

        this.sanitaryCheckpoints = response['sanitaryCheckpoints'];

        this.count = response['count'];
        this.pages = response['pages'];
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  getFrequenciesText(param: Frequency[]) {
    let result = '';
    if (param.length) {
      param.forEach(frequency => {
        if (frequency.establishment_type_id) {
          result += this.getTypeText(frequency.establishment_type_id) + ' - ';
        }
        result += this.getFrequencyText(frequency.frequency, frequency.frequency_duration) + '\n';
      });
      return result;
    }
    return 'Pas de fréquence';
  }

  getTypeText(type_id) {
    type_id = parseInt(type_id, 10);
    const found = this.establishment_types.find(elem => {
      return elem.id === type_id;
    });
    if (found) {
      return found.name;
    }
    return '';
  }
  getFrequencyText(frequency, number) {
    if (frequencies[frequency] && frequencies[frequency][number]) {
      return frequencies[frequency][number];
    } else {
      if (frequency === 'year') {
        if (number > 1) {
          return number + ' ans';
        } else {
          return number + ' an';
        }
      } else if (frequency === 'month') {
        return number + ' mois';
      } else if (frequency === 'week') {
        if (number > 1) {
          return number + ' semaines';
        } else {
          return number + ' semaine';
        }
      } else if (frequency === 'day') {
        if (number > 1) {
          return number + ' jours';
        } else {
          return number + ' jour';
        }
      }
    }
  }
  getFrequencyRequired(required_frequencies) {
    let type = 'Recommandé';
    required_frequencies.forEach(frequency => {
      if (frequency.frequency_type === 'required_by_law') {
        type = 'Imposé';
      }
    });

    if (required_frequencies.length) {
      return type;
    } else {
      return '';
    }
  }

  filter(property) {
    if (this.sort === property) {
      if (this.order === 'asc') {
        this.order = 'desc';
      } else {
        this.order = 'asc';
      }
    } else {
      this.sort = property;
      this.order = 'asc';
    }

    this.refreshData();
  }

  dispatchPagination(data) {
    if (data.action === 'total_elements') {
      this.refreshPage(data.value);
    } else if (data.action === 'change_page') {
      this.changePage(data.value);
    }
  }

  refreshPage(limit) {
    this.limit = limit;
    this.page = 1;
    this.refreshData();
  }

  changePage(page) {
    if (page >= 1 && page <= this.pages) {
      this.page = page;
      this.refreshData();
    }
  }

  bindModalConfirmData(modal) {
    this.modalConfirmData = modal;
  }

  createBreadcrumbs() {
    this.previousPageName = [];
    this.previousPageRoute = [];
    this.pageName = 'Points de contrôle du carnet Sanitaire';
    this.currentRoute = '/' + this.activatedRoute.snapshot.url.join('/');
    this.previousPageName.push('Tableau de bord');
    this.previousPageRoute.push('/administration');
  }
}
