import { Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { AuthService } from '../../../core/auth/auth.service';

import Registry from '../../../../shared/interfaces/registry';
// import { DashboardObservationsComponent } from '../dashboard-observations/dashboard-observations.component';
// import { DashboardCommissionsComponent } from '../dashboard-commissions/dashboard-commissions.component';

interface Menu {
  name: string;
  icon: string;
  url?: string;
  submenu_ref?: string;
  submenu?: Submenu[];
}

interface Submenu {
  name: string;
  icon: string;
  url: string;
  ids?: any;
  picture: string;
}

@Component({
  selector: 'app-dashboard-home',
  templateUrl: './dashboard-home.component.html',
  styleUrls: ['./dashboard-home.component.scss']
})
export class DashboardHomeComponent implements OnInit, OnDestroy {

  // @ViewChild('parent', {read: ViewContainerRef})
  // parent: ViewContainerRef;

  changeEnterprise: Subscription;
  canCreate = false;
  canUpdate = false;

  establishment: any;
  registries: Registry[] = [];

  pageName: string;
  previousPageName: string[] = [];
  previousPageRoute: string[] = [];

  menus: Menu[] = [
    {
      name: 'Tableau de bord',
      icon: 'fe-bar-chart-2',
      url: '/',
      submenu_ref: 'dashboards',
      submenu: [{
        name: 'Observations',
        icon: 'fe-bar-chart-2',
        url: '/dashboard-observations',
        ids: [1, 3, 4, 5, 6],
        picture: 'observations.png',
      }, {
        name: 'Commissions / Organismes',
        icon: 'fe-bar-chart-2',
        url: '/dashboard-commissions',
        ids: [1, 6],
        picture: 'commissions.png',
      }, {
        name: 'Prestataires / Intervenants',
        icon: 'fe-bar-chart-2',
        url: '/dashboard-providers',
        picture: 'providers.png',
      }, {
        name: 'Dossier technique Amiante',
        icon: 'fe-bar-chart-2',
        url: '/dashboard-asbestos',
        ids: [7],
        picture: 'asbestos.png',
      }, {
        name: 'Document Unique',
        icon: 'fe-bar-chart-2',
        url: '/dashboard-unique-document',
        ids: [8],
        picture: 'unique-doc.png',
      }]
    },
  ];

  connectedAs = false;

  constructor(
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    // private componentFactoryResolver: ComponentFactoryResolver
  ) {
    this.changeEnterprise = this.authService.getEnterprise().subscribe(() => {});
    // const childComponent = this.componentFactoryResolver.resolveComponentFactory(DashboardObservationsComponent);
    // setTimeout(() => {
    //   this.parent.createComponent(childComponent);
    // }, 1000);
  }

  ngOnInit() {
    this.connectedAs = JSON.parse(localStorage.getItem('connectedAs'));
    if (this.connectedAs) {
      this.registries = JSON.parse(localStorage.getItem('connectedAsRegistries'));
    } else {
      this.registries = JSON.parse(localStorage.getItem('registries'));
    }

    const observableRegistries = this.authService.getRegistries();
    observableRegistries.subscribe(value => {
      this.registries = value;
    });

    this.authService.me();

  }

  ngOnDestroy() {
    this.changeEnterprise.unsubscribe();

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  setActiveMenu(menu) {
    let bool = false;
    if (menu.ids) {
      this.registries.forEach(element => {
        if (menu.ids.indexOf(element.id) !== -1) {
          bool = true;
        }
      });
      return bool;
    } else {
      return true;
    }
  }
}
