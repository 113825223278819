import { Routes } from '@angular/router';

import { AllCotationsComponent } from './all-cotations/all-cotations.component';
import { UpdateCotationComponent } from './update-cotation/update-cotation.component';
import { UpdateCotationLimitComponent } from './update-cotation-limit/update-cotation-limit.component';

export const cotationsRoutes: Routes = [
  {
    path: 'cotations',
    component: AllCotationsComponent,
    children: [
      {
        path: 'frequencies',
        component: UpdateCotationComponent
      },
      {
        path: 'gravities',
        component: UpdateCotationComponent
      },
      {
        path: 'masters',
        component: UpdateCotationComponent
      },
      {
        path: 'limits',
        component: UpdateCotationLimitComponent
      },
      {
        path: 'frequencies/:cotation_id',
        component: UpdateCotationComponent
      },
      {
        path: 'gravities/:cotation_id',
        component: UpdateCotationComponent
      },
      {
        path: 'masters/:cotation_id',
        component: UpdateCotationComponent
      },
      {
        path: 'limits/:cotation_id',
        component: UpdateCotationLimitComponent
      }
    ]
  },
  {
    path: 'dashboard-registry/:registry_id/cotations',
    component: AllCotationsComponent,
    children: [
      {
        path: 'frequencies',
        component: UpdateCotationComponent
      },
      {
        path: 'gravities',
        component: UpdateCotationComponent
      },
      {
        path: 'masters',
        component: UpdateCotationComponent
      },
      {
        path: 'limits',
        component: UpdateCotationLimitComponent
      },
      {
        path: 'frequencies/:cotation_id',
        component: UpdateCotationComponent
      },
      {
        path: 'gravities/:cotation_id',
        component: UpdateCotationComponent
      },
      {
        path: 'masters/:cotation_id',
        component: UpdateCotationComponent
      },
    ]
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/cotations',
    component: AllCotationsComponent,
    children: [
      {
        path: 'frequencies',
        component: UpdateCotationComponent
      },
      {
        path: 'gravities',
        component: UpdateCotationComponent
      },
      {
        path: 'masters',
        component: UpdateCotationComponent
      },
      {
        path: 'limits',
        component: UpdateCotationLimitComponent
      },
      {
        path: 'frequencies/:cotation_id',
        component: UpdateCotationComponent
      },
      {
        path: 'gravities/:cotation_id',
        component: UpdateCotationComponent
      },
      {
        path: 'masters/:cotation_id',
        component: UpdateCotationComponent
      },
    ]
  }
];
