import { FormBuilder } from '@angular/forms';
import { AfterViewInit, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';

import { Subject } from 'rxjs';
import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { NotificationsRequestService } from '../../../services/notifications.request.service';

import { UsersRequestService } from '../../../services/users.request.service';
import { EnterprisesRequestService } from '../../../services/enterprises.request.service';
import { AllMessagesComponent } from '../all-messages/all-messages.component';

@Component({
  selector: 'app-update-message',
  templateUrl: './update-message.component.html',
  styleUrls: ['./update-message.component.scss']
})
export class UpdateMessageComponent implements OnInit, AfterViewInit, OnDestroy {
  canCreate = true;
  canUpdate = true;

  requestInProgress = new Subject();
  requestCount = 0;

  errors = {};

  view = '';

  allNotifications: any;

  isNew = true;
  loader = false;
  loaderContent = false;

  selectedUsers: any = [];
  selectedTiles: any;
  personal_message: any;
  nb_days: any;
  url = '';
  usersList = [];

  notify_report = false;
  send_email = false;
  send_notification  = false;

  enterprise: any;

  notification: any;

  tiles = [];

  modalNotification = null;

  compareUsers = UpdateMessageComponent.compareUsers;
  static compareUsers(c1: any, c2: any) {
    if (!c1 || !c2) {
      return false;
    }
    return c1.id === c2.id;
  }

  constructor(
    private router: Router,
    private injector: Injector,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private usersServices: UsersRequestService,
    private enterpriseService: EnterprisesRequestService,
    private notificationService: NotificationsRequestService,
  ) {
    this.allNotifications = <AllMessagesComponent>this.injector.get(AllMessagesComponent);
  }

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loaderContent = this.requestCount > 0;
    });

    this.enterprise = JSON.parse(localStorage.getItem('enterprise'));

    if (this.activatedRoute.snapshot.parent.url[1] && this.activatedRoute.snapshot.parent.url[1].path === 'mine') {
      this.view = 'mine';
      this.tiles = [
        { name: 'Message personnalisé'},
      ];
      this.selectedTiles = 'Message personnalisé';
    } else {
      this.view = 'admin';
      this.tiles = [
        { name: 'Message général'},
      ];
      this.selectedTiles = 'Message général';
    }

    if (this.activatedRoute.snapshot.params.notification_id) {
      this.isNew = false;
      if (this.view === 'mine') {
        this.getUsers();
      }
      this.getNotification();
    } else {
      if (this.view === 'mine') {
        this.getUsers();
      }
    }
  }

  ngAfterViewInit() {
    this.modalNotification.open();
  }

  ngOnDestroy() {
    this.modalNotification.close();
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  changeTiles() {
    this.errors = {};
    this.selectedUsers = [];
  }

  getUsers() {
    let request;
    this.requestInProgress.next(true);
    request = this.notificationService.getEnterpriseUsers('', '', '', '', '', this.enterprise.id);
    request.subscribe(response => {
      if (response['success']) {
        response['users'].forEach( element => {
          const value = {
            id: element.id,
            name: element.full_name,
            category: element.last_user_enterprise.role.name,
          };
          this.usersList.push(value);
        });
      }
      this.requestInProgress.next(false);
    });
  }

  getNotification() {
    let request;
    this.requestInProgress.next(true);
    if (this.view === 'mine') {
      request = this.notificationService.getNotification(this.activatedRoute.snapshot.params.notification_id);
    }
    if (this.view === 'admin') {
      request = this.notificationService.getAdminNotification(this.activatedRoute.snapshot.params.notification_id);
    }
    request.subscribe(response => {
      if (response['success']) {
        if (response['notification'] && response['notification']['tiles'] === 'Message personnalisé') {
          this.selectedTiles = response['notification']['tiles'];
          this.personal_message = response['notification']['personal_message'];
          this.send_notification = response['notification']['send_notification'];
          this.url = response['notification']['url'] ? response['notification']['url'] : '';
          response['notification']['users'].forEach( user => {
            this.selectedUsers.push(user);
          });
        }
        if (response['notification'] && response['notification']['tiles'] === 'Message général') {
          this.selectedTiles = response['notification']['tiles'];
          this.personal_message = response['notification']['personal_message'];
          this.send_notification = response['notification']['send_notification'];
          this.url = response['notification']['url'] ? response['notification']['url'] : '';
        }
      }
      this.requestInProgress.next(false);
    });
  }

  create() {
    this.errors = {};
    this.loader = true;

    let notification;
    let request;
    if (this.selectedTiles === 'Message personnalisé') {
      const url_header = 'https://';
      if (this.url !== '' && !this.url.includes(url_header)) {
        this.url = url_header + this.url;
      }
      notification = {
        tiles: this.selectedTiles,
        users: this.selectedUsers,
        personal_message: this.personal_message,
        send_notification: this.send_notification,
        enterprise_id: this.enterprise.id,
        url: this.url ? this.url : null,
      };
      request = this.notificationService.createNotification(notification);
    }
    if (this.selectedTiles === 'Message général') {
      const url_header = 'https://';
      if (this.url !== '' && !this.url.includes(url_header)) {
        this.url = url_header + this.url;
      }
      notification = {
        tiles: this.selectedTiles,
        personal_message: this.personal_message,
        send_notification: this.send_notification,
        url: this.url ? this.url : null,
      };
      request = this.notificationService.createAdminNotification(notification);
    }
    request.subscribe(response => {
      if (response['success']) {
        this.modalNotification.close();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrors(this.errors, this.notifier);
      }
      this.loader = false;
    });
  }

  update() {
    this.errors = {};
    this.loader = true;
    let request;
    let notification;

    if (this.selectedTiles === 'Message personnalisé') {
      const url_header = 'https://';
      if (this.url !== '' && !this.url.includes(url_header)) {
        this.url = url_header + this.url;
      }
      notification = {
        tiles: this.selectedTiles,
        users: this.selectedUsers,
        personal_message: this.personal_message,
        send_notification: this.send_notification,
        enterprise_id: this.enterprise.id,
        url: this.url ? this.url : null,
      };
      request = this.notificationService.updateNotification(this.activatedRoute.snapshot.params.notification_id, notification);
    }
    if (this.selectedTiles === 'Message général') {
      const url_header = 'https://';
      if (this.url !== '' && !this.url.includes(url_header)) {
        this.url = url_header + this.url;
      }
      notification = {
        tiles: this.selectedTiles,
        personal_message: this.personal_message,
        send_notification: this.send_notification,
        url: this.url ? this.url : null,
      };
      request = this.notificationService.updateAdminNotification(this.activatedRoute.snapshot.params.notification_id, notification);
    }
    request.subscribe(response => {
      if (response['success']) {
        this.modalNotification.close();
        CustomNotifierService.getSuccessUpdate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrors(this.errors, this.notifier);
      }
      this.loader = false;
    });
  }

  bindModal(modal) {
    this.modalNotification = modal;
  }
  closeModal() {
    if (this.view === 'mine') {
      this.allNotifications.refreshData();
      this.router.navigate(['notification-center/mine/messages']).then(() => {});
    } else {
      this.allNotifications.refreshAdminData();
      this.router.navigate(['notification-center']).then(() => {});
    }
  }
}
