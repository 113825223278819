<app-modal-right (eventOnClose)="closeModal()" checkEditClose="false" #downloadModal>{{ bindModal(downloadModal) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">Téléchargement</h6>

        <h1 class="header-title">Documents à télécharger</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <form [formGroup]="downloadForm" *ngIf="!loaderContent">
      <div class="row" *ngIf="isNew && !loaderContent">
        <div class="col-12">
          <div class="form-group">
            <label>Document à télécharger</label>
            <ng-select [items]="filteredExports"
                       [multiple]="false"
                       bindLabel="name"
                       groupBy="type"
                       notFoundText="Aucun résultat"
                       [selectableGroup]="false"
                       [selectableGroupAsModel]="false"
                       [closeOnSelect]="true"
                       [loading]="firstLoad"
                       placeholder="Sélectionnez un document à télécharger"
                       [(ngModel)]="selectedExport"
                       [ngModelOptions]="{ standalone: true }"
                       (change)="selectExport(selectedExport)"
                       [disabled]="loader">
              <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                {{item.type}}
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                {{ item.name }}
              </ng-template>
            </ng-select>
          </div>
        </div>

        <!----------------------------- EXPORT BEGIN END DATE ---------------------->
        <div class="col-12">
          <div class="form-group" *ngIf="selectedExport && (selectedExport.type === 'CSV' || selectedExport.type === 'TABLEAUX DE SYNTHÈSE')">
            <label class="w-100" required>Format de l'export</label>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" id="csv" value="CSV" formControlName="exportType">
              <label class="form-check-label" for="csv">CSV</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" id="xlsx" value="XLSX" formControlName="exportType">
              <label class="form-check-label" for="xlsx">XLSX</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" id="xls" value="XLS" formControlName="exportType">
              <label class="form-check-label" for="xls">XLS</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" id="ods" value="ODS" formControlName="exportType">
              <label class="form-check-label" for="ods">ODS</label>
            </div>
          </div>
          <!-- INPUTS -->
          <div class="card" *ngIf="selectedExport && selectedExport.options.length">
            <div class="card-body href" data-toggle="collapse" data-target="#collapseFilters" aria-expanded="false" aria-controls="collapseFilters" (click)="generateFlatpickr()">
              <div class="row align-items-center">
                <div class="col overflow-hidden">
                  <p class="name text-wrap mb-0">
                    <a>Liste des filtres</a>
                  </p>
                </div>
                <div class="col-auto align-self-start">
                  <span class="fe fe-filter text-right tooltipHtml" data-placement="bottom" title="Afficher la liste des filtres"></span>
                </div>
              </div>
            </div>
            <div class="collapse col-12" id="collapseFilters">
              <hr class="dropdown-divider mt-0" *ngIf="selectedExport && selectedExport.options.indexOf('with_dates') !== -1">
              <div class="row align-items-center">
                <!-- DATE FROM -->
                <div class="col-6" *ngIf="selectedExport && selectedExport.options.indexOf('with_dates') !== -1">
                  <div class="form-group">
                    <label for="exportBeginDate">Export du</label>
                    <input type="date" [(ngModel)]="exportBeginDate" [ngModelOptions]="{standalone: true}" class="form-control" id="exportBeginDate" placeholder="Date du" data-toggle="flatpickr">
                  </div>
                </div>
                <!-- DATE TO -->
                <div class="col-6" *ngIf="selectedExport && selectedExport.options.indexOf('with_dates') !== -1">
                  <div class="form-group">
                    <label for="exportEndDate">au</label>
                    <input type="date" [(ngModel)]="exportEndDate" [ngModelOptions]="{standalone: true}" class="form-control" id="exportEndDate" placeholder="Date au" data-toggle="flatpickr">
                  </div>
                </div>
                <div class="col-12">
                  <!----------------------------- WITH FORMATIONS -------------------------->
                  <hr class="dropdown-divider" *ngIf="selectedExport && selectedExport.options.indexOf('with_formations') !== -1">
                  <div class="form-group d-flex justify-content-start align-items-center" *ngIf="selectedExport && selectedExport.options.indexOf('with_formations') !== -1">
                    <div class="col pl-0">
                      Télécharger les formations ?
                    </div>
                    <div class="custom-checkbox-toggle">
                      <input type="checkbox" class="custom-control-input" id="formations" formControlName="withFormations" [ngClass]="{ 'backgroundColor': withFormations.value }">
                      <label class="custom-control-label" [ngClass]="{ 'backgroundColor': withFormations.value }" for="formations"></label>
                    </div>
                  </div>
                  <div class="form-group d-flex justify-content-start align-items-center" *ngIf="selectedExport && selectedExport.options.indexOf('with_formations') !== -1 && (withFormations.value === true && withAnnexes.value === true)">
                    <div class="col pl-0">
                      Télécharger les annexes des formations ?
                    </div>
                    <div class="custom-checkbox-toggle">
                      <input type="checkbox" class="custom-control-input" id="annexesFormations" formControlName="withAnnexesFormations" [ngClass]="{ 'backgroundColor': withAnnexesFormations.value }">
                      <label class="custom-control-label" [ngClass]="{ 'backgroundColor': withAnnexesFormations.value }" for="annexesFormations"></label>
                    </div>
                  </div>
                  <!----------------------------- WITH COMMISSIONS ------------------------->
                  <hr class="dropdown-divider" *ngIf="selectedExport && selectedExport.options.indexOf('with_commissions') !== -1">
                  <div class="form-group d-flex justify-content-start align-items-center" *ngIf="selectedExport && selectedExport.options.indexOf('with_commissions') !== -1">
                    <div class="col pl-0">
                      Télécharger les commissions ?
                    </div>
                    <div class="custom-checkbox-toggle">
                      <input type="checkbox" class="custom-control-input" id="commissions" formControlName="withCommissions" [ngClass]="{ 'backgroundColor': withCommissions.value }">
                      <label class="custom-control-label" [ngClass]="{ 'backgroundColor': withCommissions.value }" for="commissions"></label>
                    </div>
                  </div>
                  <div class="form-group d-flex justify-content-start align-items-center" *ngIf="selectedExport && selectedExport.options.indexOf('with_commissions') !== -1 && (withCommissions.value === true && withAnnexes.value === true)">
                    <div class="col pl-0">
                      Télécharger les annexes des commissions ?
                    </div>
                    <div class="custom-checkbox-toggle">
                      <input type="checkbox" class="custom-control-input" id="annexesCommissions" formControlName="withAnnexesCommissions" [ngClass]="{ 'backgroundColor': withAnnexesCommissions.value }">
                      <label class="custom-control-label" [ngClass]="{ 'backgroundColor': withAnnexesCommissions.value }" for="annexesCommissions"></label>
                    </div>
                  </div>
                  <!----------------------------- WITH WORKSHEETS -------------------------->
                  <hr class="dropdown-divider" *ngIf="selectedExport && selectedExport.options.indexOf('with_worksheets') !== -1">
                  <div class="form-group d-flex justify-content-start align-items-center" *ngIf="selectedExport && selectedExport.options.indexOf('with_worksheets') !== -1">
                    <div class="col pl-0">
                      Télécharger les fiches travaux ?
                    </div>
                    <div class="custom-checkbox-toggle">
                      <input type="checkbox" class="custom-control-input" id="worksheets" formControlName="withWorksheets" [ngClass]="{ 'backgroundColor': withWorksheets.value }">
                      <label class="custom-control-label" [ngClass]="{ 'backgroundColor': withWorksheets.value }" for="worksheets"></label>
                    </div>
                  </div>
                  <div class="form-group d-flex justify-content-start align-items-center" *ngIf="selectedExport && selectedExport.options.indexOf('with_worksheets') !== -1 && (withWorksheets.value === true && withAnnexes.value === true)">
                    <div class="col pl-0">
                      Télécharger les annexes des fiches travaux ?
                    </div>
                    <div class="custom-checkbox-toggle">
                      <input type="checkbox" class="custom-control-input" id="annexesWorksheets" formControlName="withAnnexesWorksheets" [ngClass]="{ 'backgroundColor': withAnnexesWorksheets.value }">
                      <label class="custom-control-label" [ngClass]="{ 'backgroundColor': withAnnexesWorksheets.value }" for="annexesWorksheets"></label>
                    </div>
                  </div>
                  <!----------------------------- WITH EMPLOYEES --------------------------->
                  <hr class="dropdown-divider" *ngIf="selectedExport && selectedExport.options.indexOf('with_employees') !== -1">
                  <div class="form-group d-flex justify-content-start align-items-center" *ngIf="selectedExport && selectedExport.options.indexOf('with_employees') !== -1">
                    <div class="col pl-0">
                      Télécharger les employées ?
                    </div>
                    <div class="custom-checkbox-toggle">
                      <input type="checkbox" class="custom-control-input" id="employees" formControlName="withEmployees" [ngClass]="{ 'backgroundColor': withEmployees.value }">
                      <label class="custom-control-label" [ngClass]="{ 'backgroundColor': withEmployees.value }" for="employees"></label>
                    </div>
                  </div>
                  <!----------------------------- WITH INSTALLATIONS ----------------------->
                  <hr class="dropdown-divider" *ngIf="selectedExport && selectedExport.options.indexOf('with_installations') !== -1">
                  <div class="form-group d-flex justify-content-start align-items-center" *ngIf="selectedExport && selectedExport.options.indexOf('with_installations') !== -1">
                    <div class="col pl-0">
                      Télécharger les installations ?
                    </div>
                    <div class="custom-checkbox-toggle">
                      <input type="checkbox" class="custom-control-input" id="installations" formControlName="withInstallations" [ngClass]="{ 'backgroundColor': withInstallations.value }">
                      <label class="custom-control-label" [ngClass]="{ 'backgroundColor': withInstallations.value }" for="installations"></label>
                    </div>
                  </div>
                  <div class="form-group d-flex justify-content-start align-items-center" *ngIf="selectedExport && selectedExport.options.indexOf('with_installations') !== -1 && (withInstallations.value === true && withAnnexes.value === true)">
                    <div class="col pl-0">
                      Télécharger les annexes des installations ?
                    </div>
                    <div class="custom-checkbox-toggle">
                      <input type="checkbox" class="custom-control-input" id="annexesInstallations" formControlName="withAnnexesInstallations" [ngClass]="{ 'backgroundColor': withAnnexesInstallations.value }">
                      <label class="custom-control-label" [ngClass]="{ 'backgroundColor': withAnnexesInstallations.value }" for="annexesInstallations"></label>
                    </div>
                  </div>
                  <div class="col pl-0" *ngIf="selectedExport && ((selectedExport.options.indexOf('with_installations') !== -1 && withInstallations.value === true) || selectedExport.options.indexOf('installations_list') !== -1)">
                    <div class="form-group" *ngIf="installations.length">
                      <label>Installations</label>
                      <ng-select [items]="installations"
                                 [multiple]="true"
                                 bindLabel="installation.name"
                                 notFoundText="Aucun résultat"
                                 [selectableGroup]="true"
                                 [closeOnSelect]="false"
                                 bindValue="installation.id"
                                 placeholder="Sélectionnez une/des installation(s)"
                                 [(ngModel)]="selectedInstallations" [ngModelOptions]="{ standalone: true }">
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                          <input type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }" /> {{ item.installation.name }}
                        </ng-template>
                      </ng-select>
                    </div>
                    <div class="form-group" *ngIf="registryInstallations.length">
                      <label>Installations</label>
                      <ng-select [items]="registryInstallations"
                                 [multiple]="true"
                                 bindLabel="name"
                                 notFoundText="Aucun résultat"
                                 [selectableGroup]="true"
                                 [closeOnSelect]="false"
                                 bindValue="id"
                                 placeholder="Sélectionnez une/des installation(s)"
                                 [(ngModel)]="selectedInstallations" [ngModelOptions]="{ standalone: true }">
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                          <input type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }" /> {{ item.name }}
                        </ng-template>
                      </ng-select>
                    </div>
                    <div class="form-group" *ngIf="establishmentInstallations.length">
                      <label>Installations</label>
                      <ng-select [items]="establishmentInstallations"
                                 [multiple]="true"
                                 bindLabel="description"
                                 notFoundText="Aucun résultat"
                                 [selectableGroup]="true"
                                 [closeOnSelect]="false"
                                 bindValue="id"
                                 placeholder="Sélectionnez une/des installation(s)"
                                 [(ngModel)]="selectedInstallations" [ngModelOptions]="{ standalone: true }">
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                          <input type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }" /> {{ item.description }}
                        </ng-template>
                      </ng-select>
                    </div>
                  </div>
                  <!----------------------------- WITH ANNEXES ----------------------------->
                  <hr class="dropdown-divider" *ngIf="selectedExport && selectedExport.options.indexOf('with_annexes') !== -1">
                  <!----------------------------- ANNEXE BEGIN END DATE -------------------->
                  <div class="form-group d-flex justify-content-start align-items-center" *ngIf="selectedExport && selectedExport.options.indexOf('with_annexes') !== -1 && withAnnexes.value === true">
                    <div class="col pl-0">
                      Inclure uniquement les documents obligatoires
                    </div>
                    <div class="custom-checkbox-toggle">
                      <input type="checkbox" class="custom-control-input" id="isDocument" formControlName="withDocuments" [ngClass]="{ 'backgroundColor': withDocuments.value }">
                      <label class="custom-control-label" [ngClass]="{ 'backgroundColor': withDocuments.value }" for="isDocument"></label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div modal-footer class="w-100">
    <button class="btn btn-primary w-100" (click)="downloadReport()" [ngClass]="{ 'is-loading is-loading-sm': loader }" [disabled]="loader || !selectedExport">
      Télécharger
    </button>
  </div>
</app-modal-right>
