<div class="main-content">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col">
        <div class="header mb-0">
          <div class="header-body no-border">
            <div class="row align-items-center">
              <div class="col">
                <h1 class="header-title">
                  Membres du personnel - Formations
                </h1>

                <small class="form-text text-muted">
                  Liste des membres du personnel relatifs aux formations
                </small>

                <app-breadcrumbs [currentPage]="pageName" [names]="previousPageName" [routes]="previousPageRoute"></app-breadcrumbs>
              </div>

              <ng-container *ngIf="canCreate && !loader">
                <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0 dropdown">
                  <a class="btn btn-primary d-block d-md-inline-block dropdown-toggle" [routerLink]="" role="button" [attr.aria-label]="'Sous menu des actions relatives aux employés de formations'"
                     id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Actions
                  </a>
                  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
                    <a class="dropdown-item" [routerLink]="[currentRoute + 'sheet']">Import de masse du personnel</a>
                    <a class="dropdown-item" [routerLink]="[currentRoute + 'create']">Ajouter un membre du personel</a>
                    <a class="dropdown-item" [routerLink]="[trainingsRoute]">Retour aux formations</a>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="!canCreate && !loader">
                <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0">
                  <a class="btn btn-primary d-block d-md-inline-block" [routerLink]="[trainingsRoute]">
                    Retour aux formations
                  </a>
                </div>
              </ng-container>
            </div>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col">
            <app-searchbar [(ngModel)]="search" (keyup)="searchData()" [placeholder_text]="'Rechercher par nom, prénom ou email'" ngDefaultControl></app-searchbar>
          </div>
        </div>

        <div class="is-loading loader-content" *ngIf="loader"></div>

        <div class="tab-content" *ngIf="!loader && employees.length">
          <div class="tab-pane fade active show" id="tabPaneOne" role="tabpanel">
            <div class="row listAlias">
              <div class="col-12 d-flex flex-column">
                <div class="card flex-fill">
                  <div class="card-body">
                    <div class="table-responsive">
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th class="href" (click)="filter('EmployeeTrainings.firstname')">
                              <a class="text-muted">Prénom</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'EmployeeTrainings.firstname' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'EmployeeTrainings.firstname' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'EmployeeTrainings.firstname'"></span>
                            </th>
                            <th class="href" (click)="filter('EmployeeTrainings.lastname')">
                              <a class="text-muted">Nom</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'EmployeeTrainings.lastname' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'EmployeeTrainings.lastname' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'EmployeeTrainings.lastname'"></span>
                            </th>
                            <th class="href" (click)="filter('EmployeeTrainings.email')">
                              <a class="text-muted">Email</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'EmployeeTrainings.email' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'EmployeeTrainings.email' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'EmployeeTrainings.email'"></span>
                            </th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="dropdown" *ngFor="let employee of employees">
                            <td>
                              <a class="href" [routerLink]="[currentRoute + employee.id]" [attr.aria-label]="'Prénom de l\'employé : ' + employee.firstname">
                                {{ employee.firstname }}
                              </a>
                            </td>
                            <td>
                              <a class="href" [routerLink]="[currentRoute + employee.id]" [attr.aria-label]="employee.lastname === '' ? 'Aucun nom attribué pour l\'employé' : 'Nom de l\'employé : ' + employee.lastname">
                                {{ employee.lastname === '' ? 'Aucun nom attribué' : employee.lastname }}
                              </a>
                            </td>
                            <td [attr.aria-label]="'Adresse email de l\'employé : ' + employee.email">{{ employee.email }}</td>
                            <td class="d-flex flex-row-reverse">
                              <a class="dropdown-ellipses dropdown-toggle href" role="button" [attr.aria-label]="'Sous menu pour le membre ' + employee.firstname + ' ' + employee.lastname " data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fe fe-more-vertical"></i>
                              </a>

                              <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item href" [routerLink]="[currentRoute + employee.id]">
                                  {{ canUpdate ? 'Éditer le membre' : 'Consulter le membre' }}
                                </a>

                                <a class="dropdown-item href" (click)="modalConfirmEmployee.open(employee.id)" *ngIf="canDelete">
                                  Supprimer le membre
                                </a>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <app-pagination (returnEvent)="dispatchPagination($event)"
                                    [count_elements]="count"
                                    [current_page]="page"
                                    [elements_length]="employees.length"
                                    [limit]="limit"
                                    [total_pages]="pages"
                                    ngDefaultControl>
                    </app-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="loader-content" *ngIf="!loader && !employees.length">
          <p>Aucun résultat</p>
        </div>
      </div>
    </div>
  </div>
</div>

<app-confirm-modal [type]="'delete_employee'" #confirmEmployee>{{ bindModalConfirmEmployee(confirmEmployee) }}</app-confirm-modal>

<router-outlet></router-outlet>
