import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AllFaqModule } from './all-faq/all-faq.module';
import { UpdateFaqModule } from './update-faq/update-faq.module';
import { FaqRequestService } from '../../services/faq.request.service';

@NgModule({
  imports: [
    CommonModule,
    AllFaqModule,
    UpdateFaqModule
  ],
  providers: [
    FaqRequestService
  ]
})
export class FaqModule {}
