<div class="main-content">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col">
        <div class="header mb-0">
          <div class="header-body no-border">
            <div class="row align-items-center">
              <div class="col">
                <h1 class="header-title">
                  {{ view === 'sanitary_contributors' ? 'Intervenants' : 'Documents' }}
                </h1>

                <small class="form-text text-muted">
                  {{ view === 'sanitary_contributors' ? 'Liste des intervenants' : 'Liste des documents' }}
                </small>

                <app-breadcrumbs [currentPage]="pageName" [names]="previousPageName" [routes]="previousPageRoute"></app-breadcrumbs>
              </div>

              <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0" *ngIf="canCreate">
                <a class="btn btn-primary d-block d-md-inline-block" [routerLink]="[currentRoute + 'create']" *ngIf="view === 'sanitary_contributors'">
                  Ajouter un intervenant interne
                </a>
              </div>
              <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0" *ngIf="canCreate">
                <a class="btn btn-primary d-block d-md-inline-block" [routerLink]="[currentRoute + 'create_external']" *ngIf="view === 'sanitary_contributors'">
                  Ajouter un intervenant externe
                </a>
              </div>
              <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0" *ngIf="canCreate">
                <a class="btn btn-primary d-block d-md-inline-block" [routerLink]="[currentRoute + 'create']" *ngIf="view === 'sanitary_contributor_files'">
                  Ajouter un document
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="is-loading loader-content" *ngIf="loaderContent && view === 'sanitary_contributor_files'"></div>

        <!-- INTERNAL CONTRIBUTORS -->
        <div class="tab-content">
          <div class="tab-pane fade active show" id="tabPaneOne" role="tabpanel">
            <div class="row listAlias">
              <div class="col-12 d-flex flex-column" *ngIf="view === 'sanitary_contributors'">
                <div class="card flex-fill">
                  <div class="card-body">
                    <div class="row mb-4">
                      <div class="col-md-6">
                        <h2>Intervenants Internes</h2>
                      </div>
                      <div class="col-md-6">
                        <app-searchbar [(ngModel)]="searchInternal" (keyup)="searchDataInternal()" [searchbar_id]="'internal'" [searchbar_name]="'internal'" ngDefaultControl></app-searchbar>
                      </div>
                    </div>
                    <div class="table-responsive">
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th class="href" (click)="filterInternal('SanitaryContributors.lastname')">
                              <a class="text-muted">Nom</a>
                              <span class="fe fe-arrow-down" *ngIf="sortInternal === 'SanitaryContributors.lastname' && orderInternal === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sortInternal === 'SanitaryContributors.lastname' && orderInternal === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sortInternal !== 'SanitaryContributors.lastname'"></span>
                            </th>
                            <th class="href" (click)="filterInternal('SanitaryContributors.firstname')">
                              <a class="text-muted">Prénom</a>
                              <span class="fe fe-arrow-down" *ngIf="sortInternal === 'SanitaryContributors.firstname' && orderInternal === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sortInternal === 'SanitaryContributors.firstname' && orderInternal === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sortInternal !== 'SanitaryContributors.firstname'"></span>
                            </th>
                            <th class="href" (click)="filterInternal('SanitaryContributors.qualification')">
                              <a class="text-muted">Qualification</a>
                              <span class="fe fe-arrow-down" *ngIf="sortInternal === 'SanitaryContributors.qualification' && orderInternal === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sortInternal === 'SanitaryContributors.qualification' && orderInternal === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sortInternal !== 'SanitaryContributors.qualification'"></span>
                            </th>
                            <th class="href" (click)="filterInternal('SanitaryContributors.phone')">
                              <a class="text-muted">Téléphone</a>
                              <span class="fe fe-arrow-down" *ngIf="sortInternal === 'SanitaryContributors.phone' && orderInternal === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sortInternal === 'SanitaryContributors.phone' && orderInternal === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sortInternal !== 'SanitaryContributors.phone'"></span>
                            </th>
                            <th class="href" (click)="filterInternal('SanitaryContributors.email')">
                              <a class="text-muted">Email</a>
                              <span class="fe fe-arrow-down" *ngIf="sortInternal === 'SanitaryContributors.email' && orderInternal === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sortInternal === 'SanitaryContributors.email' && orderInternal === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sortInternal !== 'SanitaryContributors.email'"></span>
                            </th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngIf="loaderInternal">
                            <td colspan="9">
                              <div class="is-loading loader-content"></div>
                            </td>
                          </tr>
                          <tr *ngIf="!loaderInternal && !sanitaryInternalContributors.length">
                            <td colspan="9">
                              <div class="loader-content">
                                Aucun résultat
                              </div>
                            </td>
                          </tr>
                          <tr class="dropdown" *ngFor="let internal_contributor of sanitaryInternalContributors">
                            <td>
                              <a class="href" [routerLink]="[currentRoute + internal_contributor.id]">
                                {{ internal_contributor.lastname }}
                              </a>
                            </td>
                            <td>
                              <a class="href" [routerLink]="[currentRoute + internal_contributor.id]">
                                {{ internal_contributor.firstname }}
                              </a>
                            </td>
                            <td>
                              <a class="href" [routerLink]="[currentRoute + internal_contributor.id]">
                                {{ internal_contributor.qualification }}
                              </a>
                            </td>
                            <td>
                              <a class="href" [routerLink]="[currentRoute + internal_contributor.id]">
                                {{ internal_contributor.phone }}
                              </a>
                            </td>
                            <td>
                              <a class="href" [routerLink]="[currentRoute + internal_contributor.id]">
                                {{ internal_contributor.email }}
                              </a>
                            </td>
                            <td class="d-flex flex-row-reverse">
                              <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fe fe-more-vertical"></i>
                              </a>

                              <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item href" [routerLink]="[currentRoute + internal_contributor.id]">
                                  {{ canUpdate ? 'Éditer l\'intervenant' : 'Consulter l\'intervenant' }}
                                </a>

                                <a class="dropdown-item href" (click)="modalConfirmData.open(internal_contributor.id)" *ngIf="canDelete">
                                  Supprimer l'intervenant
                                </a>

                                <a class="dropdown-item href" [routerLink]="[currentRoute + internal_contributor.id + '/logs']" *ngIf="canLog">
                                  Historique des actions
                                </a>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <app-pagination (returnEvent)="dispatchPaginationInternal($event)"
                                    [count_elements]="countInternal"
                                    [current_page]="pageInternal"
                                    [elements_length]="sanitaryInternalContributors.length"
                                    [limit]="limitInternal"
                                    [total_pages]="pagesInternal"
                                    ngDefaultControl>
                    </app-pagination>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <!-- EXTERNAL CONTRIBUTORS -->
        <div class="tab-content">
          <div class="tab-pane fade active show" id="tabPaneTwo" role="tabpanel">
            <div class="row listAlias">
              <div class="col-12 d-flex flex-column" *ngIf="view === 'sanitary_contributors'">
                <div class="card flex-fill">
                  <div class="card-body">
                    <div class="row mb-4">
                      <div class="col-md-6">
                        <h2>Intervenants Externes</h2>
                      </div>
                      <div class="col-md-6">
                        <app-searchbar [(ngModel)]="searchExternal" (keyup)="searchDataExternal()" [searchbar_id]="'external'" [searchbar_name]="'external'" ngDefaultControl></app-searchbar>
                      </div>
                    </div>
                    <div class="table-responsive">
                      <table class="table table-striped">
                        <thead>
                        <tr>
                          <th class="href" (click)="filterExternal('SanitaryContributors.enterprise')">
                            <a class="text-muted">Entreprise</a>
                            <span class="fe fe-arrow-down" *ngIf="sortExternal === 'SanitaryContributors.enterprise' && orderExternal === 'asc'"></span>
                            <span class="fe fe-arrow-up" *ngIf="sortExternal === 'SanitaryContributors.enterprise' && orderExternal === 'desc'"></span>
                            <span class="fe fe-arrow-right" *ngIf="sortExternal !== 'SanitaryContributors.enterprise'"></span>
                          </th>
                          <th class="href" (click)="filterExternal('SanitaryContributors.email')">
                            <a class="text-muted">Email</a>
                            <span class="fe fe-arrow-down" *ngIf="sortExternal === 'SanitaryContributors.email' && orderExternal === 'asc'"></span>
                            <span class="fe fe-arrow-up" *ngIf="sortExternal === 'SanitaryContributors.email' && orderExternal === 'desc'"></span>
                            <span class="fe fe-arrow-right" *ngIf="sortExternal !== 'SanitaryContributors.email'"></span>
                          </th>
                          <th class="href" (click)="filterExternal('SanitaryContributors.phone')">
                            <a class="text-muted">Téléphone</a>
                            <span class="fe fe-arrow-down" *ngIf="sortExternal === 'SanitaryContributors.phone' && orderExternal === 'asc'"></span>
                            <span class="fe fe-arrow-up" *ngIf="sortExternal === 'SanitaryContributors.phone' && orderExternal === 'desc'"></span>
                            <span class="fe fe-arrow-right" *ngIf="sortExternal !== 'SanitaryContributors.phone'"></span>
                          </th>
                          <th class="href" (click)="filterExternal('SanitaryContributors.due_date')">
                            <a class="text-muted">Echéance du contrat</a>
                            <span class="fe fe-arrow-down" *ngIf="sortExternal === 'SanitaryContributors.due_date' && orderExternal === 'asc'"></span>
                            <span class="fe fe-arrow-up" *ngIf="sortExternal === 'SanitaryContributors.due_date' && orderExternal === 'desc'"></span>
                            <span class="fe fe-arrow-right" *ngIf="sortExternal !== 'SanitaryContributors.due_date'"></span>
                          </th>
                          <th>Contrat(s)</th>
                          <th></th>
                        </tr>
                        </thead>
                        <tbody>
                          <tr *ngIf="loaderExternal">
                            <td colspan="9">
                              <div class="is-loading loader-content"></div>
                            </td>
                          </tr>
                          <tr *ngIf="!loaderExternal && !sanitaryExternalContributors.length">
                            <td colspan="9">
                              <div class="loader-content">
                                Aucun résultat
                              </div>
                            </td>
                          </tr>
                          <tr class="dropdown" *ngFor="let external_contributor of sanitaryExternalContributors">
                            <td>
                              <a class="href" [routerLink]="[currentRoute + '/external/' +external_contributor.id]">
                                {{ external_contributor.enterprise }}
                              </a>
                            </td>
                            <td>
                              <a class="href" [routerLink]="[currentRoute + '/external/' + external_contributor.id]">
                                {{ external_contributor.email }}
                              </a>
                            </td>
                            <td>
                              <a class="href" [routerLink]="[currentRoute + '/external/' + external_contributor.id]">
                                {{ external_contributor.phone }}
                              </a>
                            </td>
                            <td>
                              <a class="href" [routerLink]="[currentRoute + '/external/' + external_contributor.id]" *ngIf="external_contributor.external">
                                {{ external_contributor.due_date | date : 'dd/MM/yyyy' }}
                              </a>
                            </td>
                            <td>
                              <div class="btn-group" *ngIf="external_contributor.external">
                                <a class="btn btn-sm btn-white" [routerLink]="[currentRoute + external_contributor.id + '/files']">
                                  {{ external_contributor.count_documents + ' document(s)'}}
                                </a>
                              </div>
                            </td>
                            <td class="d-flex flex-row-reverse">
                              <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fe fe-more-vertical"></i>
                              </a>

                              <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item href" [routerLink]="[currentRoute + '/external/' + external_contributor.id]">
                                  {{ canUpdate ? 'Éditer l\'intervenant' : 'Consulter l\'intervenant' }}
                                </a>

                                <a class="dropdown-item href" (click)="modalConfirmData.open(external_contributor.id)" *ngIf="canDelete">
                                  Supprimer l'intervenant
                                </a>

                                <a class="dropdown-item href" [routerLink]="[currentRoute + external_contributor.id + '/logs']" *ngIf="canLog">
                                  Historique des actions
                                </a>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <app-pagination (returnEvent)="dispatchPaginationExternal($event)"
                                    [count_elements]="countExternal"
                                    [current_page]="pageExternal"
                                    [elements_length]="sanitaryExternalContributors.length"
                                    [limit]="limitExternal"
                                    [total_pages]="pagesExternal"
                                    ngDefaultControl>
                    </app-pagination>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 d-flex flex-column" *ngFor="let file of files">
                <div class="card flex-fill">

                  <div class="href image-file-download" *ngIf="!['mp4', 'pdf', 'png', 'jpeg', 'jpg'].includes(file.extension)" (click)="downloadFile(file.id, file.name + '.' + file.extension)">
                    <img [src]="getImage(file.extension)" alt="Télécharger le fichier"/>
                  </div>
                  <div class="href image-file-download" *ngIf="file.extension === 'mp4'" (click)="showVideo(file.id)">
                    <img [src]="getImage(file.extension)" alt="Télécharger le fichier"/>
                  </div>
                  <div class="href image-file-download" *ngIf="['pdf', 'png', 'jpeg', 'jpg'].includes(file.extension)" (click)="showPdf(file.file_url)">
                    <img [src]="getImage(file.extension)" alt="Visionner le PDF"/>
                  </div>

                  <div class="card-body">
                    <div class="row align-items-center">
                      <div class="col overflow-hidden">
                        <h4 class="card-title name text-wrap mb-0">
                          <a [routerLink]="" *ngIf="!['mp4', 'pdf', 'png', 'jpeg', 'jpg'].includes(file.extension)" (click)="downloadFile(file.id, file.name + '.' + file.extension)">
                            {{ file.name + '.' + file.extension }}
                          </a>
                          <a [routerLink]="" *ngIf="file.extension === 'mp4'" (click)="showVideo(file.id)">
                            {{ file.name + '.' + file.extension }}
                          </a>
                          <a [routerLink]="" *ngIf="['pdf', 'png', 'jpeg', 'jpg'].includes(file.extension)" (click)="showPdf(file.file_url)">
                            {{ file.name + '.' + file.extension }}
                          </a>
                        </h4>
                      </div>

                      <div class="col-auto align-self-start">
                        <div class="dropdown">
                          <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fe fe-more-vertical"></i>
                          </a>

                          <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item href" [routerLink]="[currentRoute + file.id]">
                              {{ canUpdate ? 'Éditer le fichier' : 'Consulter le fichier' }}
                            </a>

                            <a class="dropdown-item href" *ngIf="file.extension === 'mp4'" (click)="showVideo(file.id)">
                              Regarder la vidéo
                            </a>

                            <a class="dropdown-item href" *ngIf="file.extension === 'pdf'" (click)="showPdf(file.file_url)">
                              Visionner le PDF
                            </a>

                            <a class="dropdown-item href" *ngIf="['png', 'jpeg', 'jpg'].includes(file.extension)" (click)="showPdf(file.file_url)">
                              Visionner l'image
                            </a>

                            <a class="dropdown-item href" (click)="downloadFile(file.id, file.name + '.' + file.extension)">
                              Télécharger le fichier
                            </a>

                            <a class="dropdown-item href" (click)="modalConfirmFile.open(file.id)" *ngIf="canUpdate">
                              Supprimer le fichier
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div class="row pagination-content" *ngIf="view === 'sanitary_contributor_files' && files.length">
            <div class="col-12 d-flex flex-column">
              <div class="card">
                <app-pagination (returnEvent)="dispatchPaginationExternal($event)"
                                [classes]="'card-body'"
                                [count_elements]="countExternal"
                                [current_page]="pageExternal"
                                [elements_length]="files.length"
                                [limit]="limitExternal"
                                [total_pages]="pagesExternal"
                                ngDefaultControl>
                </app-pagination>
              </div>
            </div>
          </div>
        </div>

        <div class="loader-content" *ngIf="!loaderContent && !files.length && view === 'sanitary_contributor_files'">
          <p>Aucun résultat</p>
        </div>
      </div>
    </div>
  </div>
</div>

<app-confirm-modal [type]="'delete_data'" #confirmData>{{ bindModalConfirmData(confirmData) }}</app-confirm-modal>
<app-confirm-modal [type]="'delete_file'" #confirmFile>{{ bindModalConfirmFile(confirmFile) }}</app-confirm-modal>
<app-video-modal #video>{{ bindModalVideo(video) }}</app-video-modal>
<app-pdf-viewer #pdf>{{ bindModalPdf(pdf) }}</app-pdf-viewer>

<router-outlet></router-outlet>
