import { Routes } from '@angular/router';

import { ModalLogsComponent } from '../logs/modal-logs/modal-logs.component';
import { AllSanitaryInstallationsComponent } from './all-sanitary-installations/all-sanitary-installations.component';
import { UpdateSanitaryInstallationComponent } from './update-sanitary-installation/update-sanitary-installation.component';
import { UploadSanitaryInstallationFileComponent } from './upload-sanitary-installation-file/upload-sanitary-installation-file.component';

export const sanitaryInstallationsRoutes: Routes = [
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/sanitary-installations',
    component: AllSanitaryInstallationsComponent,
    children: [
      {
        path: 'create',
        component: UpdateSanitaryInstallationComponent
      },
      {
        path: ':sanitary_installation_id',
        component: UpdateSanitaryInstallationComponent
      },
      {
        path: ':sanitary_installation_id/logs',
        component: ModalLogsComponent
      }
    ]
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/sanitary-installations/:sanitary_installation_id/files',
    component: AllSanitaryInstallationsComponent,
    children: [
      {
        path: 'create',
        component: UploadSanitaryInstallationFileComponent
      },
      {
        path: ':file_id',
        component: UploadSanitaryInstallationFileComponent
      }
    ]
  },
];
