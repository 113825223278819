import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AllAgenciesModule } from './all-agencies/all-agencies.module';
import { UpdateAgencyModule } from './update-agency/update-agency.module';
import { UploadAgencyFileModule } from './upload-agency-file/upload-agency-file.module';

import { CommissionsRequestService } from '../../services/commissions.request.service';

@NgModule({
  imports: [
    CommonModule,
    AllAgenciesModule,
    UpdateAgencyModule,
    UploadAgencyFileModule
  ],
  providers: [
    CommissionsRequestService
  ]
})
export class AgenciesModule {}
