<app-modal-right (eventOnClose)="closeModal()" #prevention>{{ bindModal(prevention) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">{{ isNew ? 'Création' : canUpdate ? 'Mise à jour' : 'Consultation' }}</h6>

        <h1 class="header-title">Plan de prévention</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <form [formGroup]="preventionForm" [hidden]="loaderContent">

      <ul class="nav nav-tabs" role="tablist">
        <li class="nav-item col-4">
          <a class="nav-link href" [ngClass]="{ 'active': activeTab === 1}" (click)="changeTabs(1)">
            Entreprises concernées et Opération
          </a>
        </li>
        <li class="nav-item col-4">
          <a class="nav-link href" [ngClass]="{ 'active': activeTab === 2}" (click)="changeTabs(2)">
            Visite préalable
          </a>
        </li>
        <li class="nav-item col-4">
          <a class="nav-link href" [ngClass]="{ 'active': activeTab === 3}" (click)="changeTabs(3)">
            Risques interférents
          </a>
        </li>
      </ul>

      <div class="tab-content">
        <!-- STEP ONE -->
        <div class="tab-pane fade" [ngClass]="{ 'show active': activeTab === 1}">
          <div class="card bg-light border">
            <div class="card-body">
              <p class="small text-muted mb-2">
                Le plan de prévention doit être établi avant toute intervention dans une entreprise utilisatrice par une
                entreprise extérieure, en application des articles R .4511-1 à R. 4511-10 du Code du travail.
                Avant le début des travaux et à l'initiative de l’employeur de l'entreprise utilisatrice, les employeurs
                définissent à l’issue d’une inspection commune les mesures à prendre par chacun d'eux en vue
                d'éviter les risques professionnels, qui peuvent résulter de l'exercice simultané, en un même lieu,
                des installations et activités des deux entreprises.
              </p>
            </div>
          </div>

          <!-- REFERENCY -->
          <div class="form-group">
            <label for="validationReferency">Référence</label>
            <input type="text" formControlName="referency" class="form-control" id="validationReferency" placeholder="Référence" [ngClass]="{ 'is-invalid': referency.invalid && (referency.dirty || referency.touched) }">
            <div *ngIf="referency.invalid && (referency.dirty || referency.touched)" class="invalid-feedback">
              {{ getErrorMessage("referency") }}
            </div>
          </div>

          <h2 class="header-title mb-3">Entreprises concernées</h2>

          <!-- EU SOCIAL REASON -->
          <div class="form-group">
            <label for="euSocialReason" required><strong>Entreprise utilisatrice</strong></label>
            <input type="text" formControlName="eu_social_reason" class="form-control font_strong" id="euSocialReason" placeholder="Entreprise utilisatrice" [ngClass]="{ 'is-invalid': eu_social_reason.invalid && (eu_social_reason.dirty || eu_social_reason.touched) }">
            <div *ngIf="eu_social_reason.invalid && (eu_social_reason.dirty || eu_social_reason.touched)" class="invalid-feedback">
              {{ getErrorMessage("eu_social_reason") }}
            </div>
          </div>

          <div formArrayName="prevention_providers" class="mb-4">
            <div [formGroupName]="i" *ngFor="let provider of preventionForm['controls'].prevention_providers['controls'] as FormGroup; let i = index; let last = last">

              <div class="form-group">
                <label for="euSocialReason" required><strong>Entreprises extérieures</strong></label>
                <textarea type="text" placeholder="Entreprises extérieures" formControlName="social_reason" class="form-control d-inline-block col-12 font_strong" [ngClass]="{ 'is-invalid': provider['controls'].social_reason.invalid && (provider['controls'].social_reason.dirty || provider['controls'].social_reason.touched) }"></textarea>
                <div *ngIf="provider['controls'].social_reason.invalid && (provider['controls'].social_reason.dirty || provider['controls'].social_reason.touched)" class="invalid-feedback">
                  {{ getErrorMessage("social_reason") }}
                </div>
              </div>

            </div>
          </div>


          <h2 class="header-title mb-3">Opération nécessitant un plan de prévention</h2>

          <!-- OPERATION -->
          <div class="form-group">
            <label for="operation" required>Nature de l'opération</label>
            <input type="text" formControlName="operation" class="form-control" id="operation" placeholder="Nature de l'opération" [ngClass]="{ 'is-invalid': operation.invalid && (operation.dirty || operation.touched) }">
            <div *ngIf="operation.invalid && (operation.dirty || operation.touched)" class="invalid-feedback">
              {{ getErrorMessage("operation") }}
            </div>
          </div>

          <!-- BEGIN DATE -->
          <div class="form-group">
            <label for="beginDate" required>Date de début</label>
            <input type="text" formControlName="begin_date" class="form-control" id="beginDate" placeholder="Date de début" data-toggle="flatpickr" [ngClass]="{ 'is-invalid': begin_date.invalid && (begin_date.dirty || begin_date.touched) }">
            <div *ngIf="begin_date.invalid && (begin_date.dirty || begin_date.touched)" class="invalid-feedback">
              {{ getErrorMessage("begin_date") }}
            </div>
          </div>

          <!-- END DATE -->
          <div class="form-group">
            <label for="endDate" required>Date de fin</label>
            <input type="text" formControlName="end_date" class="form-control" id="endDate" placeholder="Date de fin" data-toggle="flatpickr" [ngClass]="{ 'is-invalid': end_date.invalid && (end_date.dirty || end_date.touched) }">
            <div *ngIf="end_date.invalid && (end_date.dirty || end_date.touched)" class="invalid-feedback">
              {{ getErrorMessage("end_date") }}
            </div>
          </div>

          <!-- DURATION ESTIMATION -->
          <div class="form-group">
            <label for="durationEstimation" required>Durée prévisible (h)</label>
            <input type="text" formControlName="duration_estimation" class="form-control" id="durationEstimation" placeholder="Durée prévisible (h)" [ngClass]="{ 'is-invalid': duration_estimation.invalid && (duration_estimation.dirty || duration_estimation.touched) }">
            <div *ngIf="duration_estimation.invalid && (duration_estimation.dirty || duration_estimation.touched)" class="invalid-feedback">
              {{ getErrorMessage("duration_estimation") }}
            </div>
          </div>

          <!-- DOCUMENTS -->
          <h2 class="header-title mb-3">Documents</h2>
          <div class="form-group" *ngIf="isNew || canUpdate">
            <div class="dropzone dropzone-multiple dz-clickable" appDragDrop (dropHandler)="onFileChange($event)">
              <div class="dz-default dz-message" (click)="chooseFile()">
                <span>Déposer vos fichiers ici pour télécharger</span>
              </div>
              <input id="inputFile" (change)="onFileChange($event)" type="file" [hidden]="true" multiple>
            </div>
          </div>
          <div class="card" *ngFor="let file of files; let index = index">
            <div class="card-body">
              <div class="d-flex align-content-center justify-content-between">
                <h4 class="card-title mb-0 name align-self-center">
                  {{ file.name + '.' + file.extension }}
                </h4>

                <a class="href tooltipHtml" data-placement="bottom" title="Supprimer" (click)="removeFile(index)" *ngIf="isNew || canUpdate">
                  <i class="fe fe-trash"></i>
                </a>
              </div>
            </div>
          </div>
        </div>

        <!-- STEP TWO - VISIT -->
        <div class="tab-pane fade" [ngClass]="{ 'show active': activeTab === 2}">
          <h2 class="header-title mb-3">Visite préalable commune</h2>
          <div class="form-group">
            <label required>Date de la visite préalable</label>
            <input type="text" placeholder="Date de la visite préalable" formControlName="visit_date" data-toggle="flatpickr"  class="form-control d-inline-block col-12" [ngClass]="{ 'is-invalid': preventionForm['controls'].visit_date.invalid && (preventionForm['controls'].visit_date.dirty || preventionForm['controls'].visit_date.touched) }">
            <div *ngIf="preventionForm['controls'].visit_date.invalid && (preventionForm['controls'].visit_date.dirty || preventionForm['controls'].visit_date.touched)" class="invalid-feedback">
              {{ getErrorMessage("visit_date") }}
            </div>
          </div>
          <div formGroupName="actions" *ngIf="preventionForm['controls'].actions['controls'] as action">
            <div *ngFor='let count of counter(8) let i = index;'>
              <div *ngIf="'bool' + (i + 1) as bool">
                <div *ngIf="'item' + (i + 1) as item">

                  <div class="form-group d-flex justify-content-start align-items-center">
                    <div class="col pl-0">{{ getLabel(bool) }}</div>
                    <div class="custom-checkbox-toggle">
                      <input type="checkbox" class="custom-control-input" id="{{ bool }}" [formControlName]="bool" [ngClass]="{ 'backgroundColor': action[bool].value }">
                      <label class="custom-control-label" [ngClass]="{ 'backgroundColor': action[bool].value }" for="{{ bool }}"></label>
                    </div>
                  </div>

                  <div *ngIf="action[bool].value" class="form-group">
                    <label for="{{ item }}">Observations</label>
                    <textarea type="text" [formControlName]="item" id="{{ item }}" class="form-control" [placeholder]="getLabel(bool)"></textarea>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <h2 class="header-title my-4">Service de santé</h2>
          <div formGroupName="health_service" *ngIf="preventionForm['controls'].health_service['controls'] as health">
            <div class="form-group d-flex justify-content-start align-items-center">
              <div class="col pl-0">Postes nécessitant un suivi individuel renforcé</div>
              <div class="custom-checkbox-toggle">
                <input type="checkbox" class="custom-control-input" id="required_follow" formControlName="required_follow" [ngClass]="{ 'backgroundColor': health['required_follow'].value }">
                <label class="custom-control-label" [ngClass]="{ 'backgroundColor': health['required_follow'].value }" for="required_follow"></label>
              </div>
            </div>
            <div formArrayName="jobs" *ngIf="health['required_follow'].value" class="mb-4">
              <div [formGroupName]="i" *ngFor="let job of health.jobs['controls'] as FormGroup; let i = index; let last = last">

                <div class="form-group">
                  <label for="euSocialReason" required>Poste</label>
                  <input type="text" placeholder="Poste" formControlName="name" class="form-control d-inline-block col-12" [ngClass]="{ 'is-invalid': job['controls'].name.invalid && (job['controls'].name.dirty || job['controls'].name.touched) }">
                  <div *ngIf="job['controls'].name.invalid && (job['controls'].name.dirty || job['controls'].name.touched)" class="invalid-feedback">
                    {{ getErrorMessage("name") }}
                  </div>
                </div>

                <button class="btn btn-primary w-50 d-flex m-auto justify-content-center" (click)="deleteJob(i)">
                  Supprimer le poste
                </button>
                <hr class="col-12" *ngIf="!last">
              </div>
            </div>
            <button class="btn btn-primary w-100" *ngIf="health['required_follow'].value" (click)="addJob()">
              Ajouter un poste
            </button>

          </div>
        </div>

        <!-- STEP SIX - RISKS -->
        <div class="tab-pane fade" [ngClass]="{ 'show active': activeTab === 3}">
          <h2 class="header-title mb-3">Analyse des risques interférents</h2>
          <div class="d-flex justify-content-center align-items-center mt-4" *ngIf="!preventionForm.controls.risks.value.length">
            Aucun risque
          </div>

          <div formArrayName="risks" class="mb-4">
            <div [formGroupName]="i" *ngFor="let risk of preventionForm['controls'].risks['controls'] as FormGroup; let i = index; let last = last" class="card">
              <div class="card-header">
                <div class="d-flex justify-content-between">
                  <a data-toggle="collapse" [href]="'#risk' + i" [attr.aria-controls]="'risk' + i" role="button" aria-expanded="true">
                    Risque {{ i + 1 }}
                  </a>

                  <a class="href tooltipHtml" data-placement="bottom" title="Supprimer" (click)="deleteRisk(i)">
                    <i class="fe fe-trash"></i>
                  </a>
                </div>
              </div>

              <div class="card-body collapse show" [id]="'risk' + i">
                <div class="form-group">
                  <label for="euSocialReason" required>Phase de l'opération</label>
                  <input type="text" placeholder="Phase de l'opération" formControlName="operation" class="form-control d-inline-block col-12" [ngClass]="{ 'is-invalid': risk['controls'].operation.invalid && (risk['controls'].operation.dirty || risk['controls'].operation.touched) }">
                  <div *ngIf="risk['controls'].operation.invalid && (risk['controls'].operation.dirty || risk['controls'].operation.touched)" class="invalid-feedback">
                    {{ getErrorMessage("operation") }}
                  </div>
                </div>

                <div class="form-group">
                  <label for="euSocialReason" required>Nature de l'intervention</label>
                  <input type="text" placeholder="Nature de l'intervention" formControlName="nature" class="form-control d-inline-block col-12" [ngClass]="{ 'is-invalid': risk['controls'].nature.invalid && (risk['controls'].nature.dirty || risk['controls'].nature.touched) }">
                  <div *ngIf="risk['controls'].nature.invalid && (risk['controls'].nature.dirty || risk['controls'].nature.touched)" class="invalid-feedback">
                    {{ getErrorMessage("nature") }}
                  </div>
                </div>

                <div class="form-group">
                  <label for="listRegistry" required>Risques identifiés</label>
                  <ng-select [items]="risks"
                             [multiple]="true"
                             bindLabel="name"
                             bindValue="name"
                             groupBy="group"
                             notFoundText="Aucun résultat"
                             [selectableGroup]="true"
                             [selectableGroupAsModel]="false"
                             [closeOnSelect]="false"
                             [addTag]="true"
                             [clearSearchOnAdd]="true"
                             addTagText="Ajouter"
                             placeholder="Risques identifiés"
                             formControlName="identified_risks"
                             [disabled]="!isNew && !canUpdate">
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                      <input id="listRegistry" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }" /> {{ item.name }}
                    </ng-template>
                  </ng-select>
                </div>

                <div class="form-group">
                  <label for="euSocialReason" required>Date de début</label>
                  <input type="text" placeholder="Date de début" formControlName="begin_date" data-toggle="flatpickr"  class="form-control d-inline-block col-12" [ngClass]="{ 'is-invalid': risk['controls'].begin_date.invalid && (risk['controls'].begin_date.dirty || risk['controls'].begin_date.touched) }">
                  <div *ngIf="risk['controls'].begin_date.invalid && (risk['controls'].begin_date.dirty || risk['controls'].begin_date.touched)" class="invalid-feedback">
                    {{ getErrorMessage("begin_date") }}
                  </div>
                </div>

                <div class="form-group">
                  <label for="euSocialReason" required>Date de fin</label>
                  <input type="text" placeholder="Date de fin" formControlName="end_date" data-toggle="flatpickr"  class="form-control d-inline-block col-12" [ngClass]="{ 'is-invalid': risk['controls'].end_date.invalid && (risk['controls'].end_date.dirty || risk['controls'].end_date.touched) }">
                  <div *ngIf="risk['controls'].end_date.invalid && (risk['controls'].end_date.dirty || risk['controls'].end_date.touched)" class="invalid-feedback">
                    {{ getErrorMessage("end_date") }}
                  </div>
                </div>

                <div class="form-group">
                  <label for="euSocialReason" required>Entreprises concernés</label>
                  <input type="text" placeholder="Entreprises concernés" formControlName="enterprises" class="form-control d-inline-block col-12" [ngClass]="{ 'is-invalid': risk['controls'].enterprises.invalid && (risk['controls'].enterprises.dirty || risk['controls'].enterprises.touched) }">
                  <div *ngIf="risk['controls'].enterprises.invalid && (risk['controls'].enterprises.dirty || risk['controls'].enterprises.touched)" class="invalid-feedback">
                    {{ getErrorMessage("enterprises") }}
                  </div>
                </div>

                <div class="form-group">
                  <label for="euSocialReason" required>Mesures de prévention</label>
                  <input type="text" placeholder="Mesures de prévention" formControlName="measures" class="form-control d-inline-block col-12" [ngClass]="{ 'is-invalid': risk['controls'].measures.invalid && (risk['controls'].measures.dirty || risk['controls'].measures.touched) }">
                  <div *ngIf="risk['controls'].measures.invalid && (risk['controls'].measures.dirty || risk['controls'].measures.touched)" class="invalid-feedback">
                    {{ getErrorMessage("measures") }}
                  </div>
                </div>

              </div>
            </div>
          </div>

          <button class="btn btn-primary col-6 offset-3 mb-3" (click)="addRisk()">
            Ajouter un risque
          </button>
        </div>

      </div>

    </form>
  </div>

  <div modal-footer class="w-100" *ngIf="isNew || canUpdate">
    <button class="btn btn-primary w-100" (click)="isNew ? createPrevention() : updatePrevention()" [ngClass]="{ 'is-loading is-loading-sm': loader }" [disabled]="loader || preventionForm.invalid">
      {{ isNew ? 'Créer' : 'Mettre à jour' }}
    </button>
  </div>
</app-modal-right>
