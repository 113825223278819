<app-modal-right (eventOnClose)="closeModal()" #registry>{{ bindModal(registry) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">{{ isNew ? 'Création' : canUpdate ? 'Mise à jour' : 'Consultation' }}</h6>

        <h1 class="header-title">Registre</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <form [formGroup]="registryForm" [hidden]="loaderContent">
      <div class="row">
        <div class="col-12">

          <!-- REGISTRY NAME -->
          <div class="form-group">
            <label for="validationName" required>Nom</label>
            <input type="text" formControlName="name" id="validationName" class="form-control" placeholder="Nom du registre" [ngClass]="{ 'is-invalid': name.invalid && (name.dirty || name.touched) }" maxlength="255">
            <div *ngIf="name.invalid && (name.dirty || name.touched)" class="invalid-feedback">
              {{ getErrorMessage("name") }}
            </div>
          </div>

          <!-- REGISTRY COLOR -->
          <div class="form-group">
            <label for="validationBackgroundColor">Couleur</label>
            <input type="color" formControlName="background_color" id="validationBackgroundColor" class="form-control" placeholder="Couleur du registre" [ngClass]="{ 'is-invalid': background_color.invalid && (background_color.dirty || background_color.touched) }" maxlength="255">
            <div *ngIf="background_color.invalid && (background_color.dirty || background_color.touched)" class="invalid-feedback">
              {{ getErrorMessage("background_color") }}
            </div>
          </div>

          <!-- MODULE DOCUMENTS -->
          <div class="form-group d-flex justify-content-start align-items-center">
            <div class="col pl-0">
              Module Documents
            </div>
            <div class="custom-checkbox-toggle">
              <input type="checkbox" class="custom-control-input" id="validationDocumentsModule" formControlName="documents_module" [ngClass]="{ 'backgroundColor': documents_module.value }">
              <label class="custom-control-label" [ngClass]="{ 'backgroundColor': documents_module.value }" for="validationDocumentsModule"></label>
            </div>
          </div>

          <!-- MODULE CHECKPOINTS -->
          <div class="form-group d-flex justify-content-start align-items-center">
            <div class="col pl-0">
              Module Points de contrôle
            </div>
            <div class="custom-checkbox-toggle">
              <input type="checkbox" class="custom-control-input" id="validationCheckpointsModule" formControlName="checkpoints_module" [ngClass]="{ 'backgroundColor': checkpoints_module.value }" (change)="changeCheckpointRequired()">
              <label class="custom-control-label" [ngClass]="{ 'backgroundColor': checkpoints_module.value }" for="validationCheckpointsModule"></label>
            </div>
          </div>
          <!-- MODULE CHECKPOINTS NAME -->
          <div *ngIf="checkpoints_module.value" class="form-group">
            <label for="validationCheckpointName" required>Titre du module Points de contrôle</label>
            <input type="text" formControlName="checkpoints_name" id="validationCheckpointName" class="form-control" placeholder="Titre du module Points de contrôle" [ngClass]="{ 'is-invalid': checkpoints_name.invalid && (checkpoints_name.dirty || checkpoints_name.touched) }" maxlength="255">
            <div *ngIf="checkpoints_name.invalid && (checkpoints_name.dirty || checkpoints_name.touched)" class="invalid-feedback">
              {{ getErrorMessage("checkpoints_name") }}
            </div>
          </div>

          <!-- MODULE CUSTOM CHECKPOINTS -->
          <div class="form-group d-flex justify-content-start align-items-center">
            <div class="col pl-0">
              Module Points de contrôle personnalisés
            </div>
            <div class="custom-checkbox-toggle">
              <input type="checkbox" class="custom-control-input" id="validationCustomCheckpointsModule" formControlName="checkpoints_custom_module" [ngClass]="{ 'backgroundColor': checkpoints_custom_module.value }">
              <label class="custom-control-label" [ngClass]="{ 'backgroundColor': checkpoints_custom_module.value }" for="validationCustomCheckpointsModule"></label>
            </div>
          </div>

          <!-- MODULE WORKSHEETS -->
          <div class="form-group d-flex justify-content-start align-items-center">
            <div class="col pl-0">
              Module Fiche travaux
            </div>
            <div class="custom-checkbox-toggle">
              <input type="checkbox" class="custom-control-input" id="validationWorksheetsModule" formControlName="worksheets_module" [ngClass]="{ 'backgroundColor': worksheets_module.value }">
              <label class="custom-control-label" [ngClass]="{ 'backgroundColor': worksheets_module.value }" for="validationWorksheetsModule"></label>
            </div>
          </div>

          <!-- MODULE COMMISSIONS -->
          <div class="form-group d-flex justify-content-start align-items-center">
            <div class="col pl-0">
              Module Commissions de sécurité
            </div>
            <div class="custom-checkbox-toggle">
              <input type="checkbox" class="custom-control-input" id="validationCommissionsModule" formControlName="commissions_module" [ngClass]="{ 'backgroundColor': commissions_module.value }" (change)="changeCommissionRequired()">
              <label class="custom-control-label" [ngClass]="{ 'backgroundColor': commissions_module.value }" for="validationCommissionsModule"></label>
            </div>
          </div>
          <!-- MODULE COMMISSIONS NAME -->
          <div *ngIf="commissions_module.value" class="form-group">
            <label for="validationCommissionsName" required>Titre du module Commission de sécurité</label>
            <input type="text" formControlName="commissions_name" id="validationCommissionsName" class="form-control" placeholder="Titre du module Commission de sécurité" [ngClass]="{ 'is-invalid': commissions_name.invalid && (commissions_name.dirty || commissions_name.touched) }" maxlength="255">
            <div *ngIf="commissions_name.invalid && (commissions_name.dirty || commissions_name.touched)" class="invalid-feedback">
              {{ getErrorMessage("commissions_name") }}
            </div>
          </div>

          <!-- MODULE EXPERTISES -->
          <div class="form-group d-flex justify-content-start align-items-center">
            <div class="col pl-0">
              Module Expertise
            </div>
            <div class="custom-checkbox-toggle">
              <input type="checkbox" class="custom-control-input" id="validationExpertiseModule" formControlName="expertises_module" [ngClass]="{ 'backgroundColor': expertises_module.value }" (change)="changeExpertiseRequired()">
              <label class="custom-control-label" [ngClass]="{ 'backgroundColor': expertises_module.value }" for="validationExpertiseModule"></label>
            </div>
          </div>
          <!-- MODULE EXPERTISES NAME -->
          <div *ngIf="expertises_module.value" class="form-group">
            <label for="validationExpertiseName" required>Titre du module Expertise</label>
            <input type="text" formControlName="expertises_name" id="validationExpertiseName" class="form-control" placeholder="Titre du module Expertise" [ngClass]="{ 'is-invalid': expertises_name.invalid && (expertises_name.dirty || expertises_name.touched) }" maxlength="255">
            <div *ngIf="expertises_name.invalid && (expertises_name.dirty || expertises_name.touched)" class="invalid-feedback">
              {{ getErrorMessage("expertises_name") }}
            </div>
          </div>

          <!-- MODULE AGENCIES -->
          <div class="form-group d-flex justify-content-start align-items-center">
            <div class="col pl-0">
              Module Organismes
            </div>
            <div class="custom-checkbox-toggle">
              <input type="checkbox" class="custom-control-input" id="validationAgenciesModule" formControlName="agencies_module" [ngClass]="{ 'backgroundColor': agencies_module.value }" (change)="changeAgenciesRequired()">
              <label class="custom-control-label" [ngClass]="{ 'backgroundColor': agencies_module.value }" for="validationAgenciesModule"></label>
            </div>
          </div>
          <!-- MODULE AGENCIES NAME -->
          <div *ngIf="agencies_module.value" class="form-group">
            <label for="validationAgenciesName" required>Titre du module Organismes</label>
            <input type="text" formControlName="agencies_name" id="validationAgenciesName" class="form-control" placeholder="Titre du module Organismes" [ngClass]="{ 'is-invalid': agencies_name.invalid && (agencies_name.dirty || agencies_name.touched) }" maxlength="255">
            <div *ngIf="agencies_name.invalid && (agencies_name.dirty || agencies_name.touched)" class="invalid-feedback">
              {{ getErrorMessage("agencies_name") }}
            </div>
          </div>

          <!-- MODULE CHILDREN ESTABLISHMENT -->
          <div class="form-group d-flex justify-content-start align-items-center">
            <div class="col pl-0">
              Module Exploitant
            </div>
            <div class="custom-checkbox-toggle">
              <input type="checkbox" class="custom-control-input" id="validationChildrenEstablishmentModule" formControlName="rus_module" [ngClass]="{ 'backgroundColor': rus_module.value }">
              <label class="custom-control-label" [ngClass]="{ 'backgroundColor': rus_module.value }" for="validationChildrenEstablishmentModule"></label>
            </div>
          </div>

          <!-- MODULE TRAININGS -->
          <div class="form-group d-flex justify-content-start align-items-center">
            <div class="col pl-0">
              Module Formations
            </div>
            <div class="custom-checkbox-toggle">
              <input type="checkbox" class="custom-control-input" id="validationFormationsModule" formControlName="formations_module" [ngClass]="{ 'backgroundColor': formations_module.value }">
              <label class="custom-control-label" [ngClass]="{ 'backgroundColor': formations_module.value }" for="validationFormationsModule"></label>
            </div>
          </div>

          <!-- MODULE REPORT WORKSHEETS -->
          <div class="form-group d-flex justify-content-start align-items-center">
            <div class="col pl-0">
              Module Suivi des travaux
            </div>
            <div class="custom-checkbox-toggle">
              <input type="checkbox" class="custom-control-input" id="validationReportWorksheetsModule" formControlName="report_worksheets_module" [ngClass]="{ 'backgroundColor': report_worksheets_module.value }">
              <label class="custom-control-label" [ngClass]="{ 'backgroundColor': report_worksheets_module.value }" for="validationReportWorksheetsModule"></label>
            </div>
          </div>

          <!-- MODULE HAS MANAGER -->
          <div class="form-group d-flex justify-content-start align-items-center">
            <div class="col pl-0">
              Module Coordonnées du responsable
            </div>
            <div class="custom-checkbox-toggle">
              <input type="checkbox" class="custom-control-input" id="validationHasManagerModule" formControlName="has_manager_module" [ngClass]="{ 'backgroundColor': has_manager_module.value }">
              <label class="custom-control-label" [ngClass]="{ 'backgroundColor': has_manager_module.value }" for="validationHasManagerModule"></label>
            </div>
          </div>

          <!-- MODULE ASBESTOS -->
          <div class="form-group d-flex justify-content-start align-items-center">
            <div class="col pl-0">
              Module Amiante
            </div>
            <div class="custom-checkbox-toggle">
              <input type="checkbox" class="custom-control-input" id="validationAsbestosModule" formControlName="asbestos_module" [ngClass]="{ 'backgroundColor': asbestos_module.value }">
              <label class="custom-control-label" [ngClass]="{ 'backgroundColor': asbestos_module.value }" for="validationAsbestosModule"></label>
            </div>
          </div>

          <!-- MODULE COTATIONS -->
          <div class="form-group d-flex justify-content-start align-items-center">
            <div class="col pl-0">
              Module Cotations
            </div>
            <div class="custom-checkbox-toggle">
              <input type="checkbox" class="custom-control-input" id="validationCotationsModule" formControlName="cotations_module" [ngClass]="{ 'backgroundColor': cotations_module.value }">
              <label class="custom-control-label" [ngClass]="{ 'backgroundColor': cotations_module.value }" for="validationCotationsModule"></label>
            </div>
          </div>

          <!-- MODULE EMPLOYEES -->
          <div class="form-group d-flex justify-content-start align-items-center">
            <div class="col pl-0">
              Module Membres du personnel
            </div>
            <div class="custom-checkbox-toggle">
              <input type="checkbox" class="custom-control-input" id="validationEmployeesModule" formControlName="employees_module" [ngClass]="{ 'backgroundColor': employees_module.value }">
              <label class="custom-control-label" [ngClass]="{ 'backgroundColor': employees_module.value }" for="validationEmployeesModule"></label>
            </div>
          </div>

          <!-- MODULE FIRE PERMITS -->
          <div class="form-group d-flex justify-content-start align-items-center">
            <div class="col pl-0">
              Module Permis feu
            </div>
            <div class="custom-checkbox-toggle">
              <input type="checkbox" class="custom-control-input" id="validationFirePermitModule" formControlName="fire_permits_module" [ngClass]="{ 'backgroundColor': fire_permits_module.value }">
              <label class="custom-control-label" [ngClass]="{ 'backgroundColor': fire_permits_module.value }" for="validationFirePermitModule"></label>
            </div>
          </div>

          <!-- MODULE SANITARY RECORD -->
          <div class="form-group d-flex justify-content-start align-items-center">
            <div class="col pl-0">
              Module Carnet sanitaire
            </div>
            <div class="custom-checkbox-toggle">
              <input type="checkbox" class="custom-control-input" id="validationSanitaryRecord" formControlName="sanitary_record_module" [ngClass]="{ 'backgroundColor': sanitary_record_module.value }">
              <label class="custom-control-label" [ngClass]="{ 'backgroundColor': sanitary_record_module.value }" for="validationSanitaryRecord"></label>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div modal-footer class="w-100" *ngIf="isNew || canUpdate">
    <button class="btn btn-primary w-100" (click)="isNew ? createRegistry() : updateRegistry()" [ngClass]="{ 'is-loading is-loading-sm': loader }" [disabled]="loader || registryForm.invalid">
      {{ isNew ? 'Créer' : 'Mettre à jour'}}
    </button>
  </div>
</app-modal-right>
