<app-modal-right (eventOnClose)="closeModal()" #analyzeFrame>
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">{{ isNew ? 'Création' : canUpdate ? 'Mise à jour' : 'Consultation' }}</h6>

        <h1 class="header-title">Fiche de suivi</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>
    <form [formGroup]="sanitaryAnalyzeFrameForm" [hidden]="loaderContent">

      <!-- TITLE -->
      <div class="form-group">
        <label for="validationTitle" required>Titre</label>
        <input type="text" formControlName="title" class="form-control" id="validationTitle" placeholder="Titre" [ngClass]="{ 'is-invalid': title.invalid && (title.dirty || title.touched) }">
      </div>

      <!-- DATE -->
      <div class="form-group">
        <label for="validationDate" required>Année (préciser la date de réalisation)</label>
        <input type="text" formControlName="date" class="form-control" id="validationDate" placeholder="Date de réalisation" data-toggle="flatpickr" [ngClass]="{ 'is-invalid': date.invalid && (date.dirty || date.touched) }">
      </div>

      <!-- SELECT FOR ADD VALUE IN FRAME -->
      <div class="form-group" *ngIf="listItems.length">
        <label required>Selectionner une valeur afin de remplir la fiche de suivi</label>
        <ng-select [items]="listItems"
                   [multiple]="false"
                   bindLabel="title"
                   bindValue="title"
                   [selectableGroup]="false"
                   [selectableGroupAsModel]="false"
                   [closeOnSelect]="true"
                   placeholder="Selectionner une valeur afin de remplir la fiche de suivi"
                   [(ngModel)]="selectedDescription" [ngModelOptions]="{ standalone: true }">
          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
            {{ item.title }}
          </ng-template>
        </ng-select>
      </div>

      <!-- FORM ARRAY -->
      <div formArrayName="descriptions" class="mb-4" *ngIf="selectedDescription">
        <!-- FOR EACH IN DESCRIPTION -->
        <div [formGroupName]="indexDescription" *ngFor="let description of sanitaryAnalyzeFrameForm['controls'].descriptions['controls'] as FormGroup; let indexDescription = index;">

          <!-- MONTHLY / WEEKLY / QUARTERLY -->
          <ng-container *ngIf="periodicity === 'Mensuelle' || periodicity === 'Hebdomadaire' || periodicity === 'Trimestrielle'">

            <div formArrayName="values">
              <div [formGroupName]="indexValue" class="form-group" *ngFor="let value of description['controls'].values['controls'] as FormGroup; let indexValue = index;">
                <div class="card" *ngIf="description['controls'].title.value === selectedDescription">
                  <div class="card-header">
                    <div class="d-flex align-items-center">
                      <i data-toggle="collapse" [attr.href]="'#values' + indexValue" aria-expanded="true" class="fe chevron mr-2 href"></i>
                      <p class="mb-0 my-sm-3 mx-sm-auto mx-lg-0">{{ value['controls'].title.value }}</p>
                    </div>
                  </div>

                  <div formArrayName="pointLists" class="card-body collapse show" [id]="'values' + indexValue" [attr.id]="'values' + indexValue" role="tabpanel" [attr.data-parent]="'#values' + indexValue" *ngIf="description['controls'].title.value === selectedDescription">
                    <div [formGroupName]="indexPointList" class="form-group" *ngFor="let pointList of value['controls'].pointLists['controls'] as FormGroup; let indexPointList = index;">

                      <div class="card" *ngIf="pointList['controls'].title.value">
                        <div class="card-header">
                          <div class="d-flex align-items-center">
                            <i data-toggle="collapse" [attr.href]="'#pointLists' + indexValue + '-' + indexPointList" aria-expanded="true" class="fe chevron mr-2 href collapsed" (click)="addFlatpickr()"></i>
                            <p class="mb-0 my-sm-3 mx-sm-auto mx-lg-0">{{ pointList['controls'].title.value }}</p>
                          </div>
                        </div>

                        <div formArrayName="comments" class="card-body collapse" [id]="'pointLists' + indexValue + '-' + indexPointList" [attr.id]="'pointLists' + indexValue + '-' + indexPointList" role="tabpanel" [attr.data-parent]="'#pointLists' + indexValue + '-' + indexPointList">
                          <div [formGroupName]="indexComment" class="form-group" *ngFor="let comment of pointList['controls'].comments['controls'] as FormGroup; let indexComment = index; let firstComment = first">

                            <ng-container *ngIf="firstComment">
                              <label>{{ comment['controls'].title.value }}</label>
                              <input type="text" formControlName="comment" class="form-control" placeholder="Date" data-toggle="flatpickr" [ngClass]="{ 'is-invalid': comment['controls'].comment.invalid && (comment['controls'].comment.dirty || comment['controls'].comment.touched) }">
                              <div *ngIf="comment['controls'].comment.invalid && (comment['controls'].comment.dirty || comment['controls'].comment.touched)" class="invalid-feedback">
                                {{ getErrorMessage("comment") }}
                              </div>
                            </ng-container>
                            <ng-container *ngIf="!firstComment">
                              <label>{{ comment['controls'].title.value }}</label>
                              <input type="text" placeholder="{{ comment['controls'].title.value }}" formControlName="comment" class="form-control d-inline-block col-12" [ngClass]="{'is-invalid': comment['controls'].comment.invalid && (comment['controls'].comment.dirty || comment['controls'].comment.touched) }">
                              <div *ngIf="comment['controls'].comment.invalid && (comment['controls'].comment.dirty || comment['controls'].comment.touched)" class="invalid-feedback">
                                {{ getErrorMessage("comment") }}
                              </div>
                            </ng-container>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>

                </div>
              </div>
            </div>

          </ng-container>

          <!-- SEMESTER -->
          <ng-container *ngIf="periodicity === 'Semestrielle'">

            <div formArrayName="pointLists">
              <div [formGroupName]="indexPointList" class="form-group" *ngFor="let pointList of description['controls'].pointLists['controls'] as FormGroup; let indexPointList = index;">
                <div class="card" *ngIf="description['controls'].title.value === selectedDescription">
                  <div class="card-header">
                    <div class="d-flex align-items-center">
                      <i data-toggle="collapse" [attr.href]="'#values' + indexPointList" aria-expanded="true" class="fe chevron mr-2 href"></i>
                      <p class="mb-0 my-sm-3 mx-sm-auto mx-lg-0">{{ pointList['controls'].title.value }}</p>
                    </div>
                  </div>

                  <div formArrayName="semesters" class="card-body collapse show" [id]="'pointLists' + indexPointList" [attr.id]="'pointLists' + indexPointList" role="tabpanel" [attr.data-parent]="'#pointLists' + indexPointList" *ngIf="description['controls'].title.value === selectedDescription">
                    <div [formGroupName]="indexSemester" class="form-group" *ngFor="let semester of pointList['controls'].semesters['controls'] as FormGroup; let indexSemester = index;">

                      <div class="card">
                        <div class="card-header">
                          <div class="d-flex align-items-center">
                            <i data-toggle="collapse" [attr.href]="'#pointLists' + indexPointList + '-' + indexSemester" aria-expanded="true" class="fe chevron mr-2 href collapsed" (click)="addFlatpickr()"></i>
                            <p class="mb-0 my-sm-3 mx-sm-auto mx-lg-0">{{ semester['controls'].title.value }}</p>
                          </div>
                        </div>

                        <div formArrayName="comments" class="card-body collapse row" [id]="'pointLists' + indexPointList + '-' + indexSemester" [attr.id]="'pointLists' + indexPointList + '-' + indexSemester" role="tabpanel" [attr.data-parent]="'#pointLists' + indexPointList + '-' + indexSemester">
                          <div [formGroupName]="indexComment" class="form-group col-6" *ngFor="let comment of semester['controls'].comments['controls'] as FormGroup; let indexComment = index; let firstComment = first">
                            <ng-container *ngIf="indexSemester === 0">
                              <div class="form-group">
                                <label>{{ comment['controls'].title.value }}</label>
                                <input type="text" formControlName="comment" class="form-control" placeholder="Date de réalisation" data-toggle="flatpickr" [ngClass]="{ 'is-invalid': comment['controls'].comment.invalid && (comment['controls'].comment.dirty || comment['controls'].comment.touched) }">
                                <div *ngIf="comment['controls'].comment.invalid && (comment['controls'].comment.dirty || comment['controls'].comment.touched)" class="invalid-feedback">
                                  {{ getErrorMessage("comment") }}
                                </div>
                              </div>
                            </ng-container>

                            <ng-container *ngIf="indexSemester > 0">
                              <label>{{ comment['controls'].title.value }}</label>
                              <input type="text" placeholder="{{ comment['controls'].title.value }}" formControlName="comment" class="form-control d-inline-block col-12" [ngClass]="{'is-invalid': comment['controls'].comment.invalid && (comment['controls'].comment.dirty || comment['controls'].comment.touched) }">
                              <div *ngIf="comment['controls'].comment.invalid && (comment['controls'].comment.dirty || comment['controls'].comment.touched)" class="invalid-feedback">
                                {{ getErrorMessage("comment") }}
                              </div>
                            </ng-container>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>

                </div>
              </div>
            </div>

          </ng-container>

          <!-- ANNUAL -->
          <ng-container *ngIf="periodicity === 'Annuelle'">
            <div formArrayName="pointLists">
              <div [formGroupName]="indexPointList" class="form-group" *ngFor="let pointList of description['controls'].pointLists['controls'] as FormGroup; let indexPointList = index;">
                <div class="card" *ngIf="description['controls'].title.value === selectedDescription && pointList['controls'].title.value">
                  <div class="card-header">
                    <div class="d-flex align-items-center">
                      <i data-toggle="collapse" [attr.href]="'#pointLists' + indexPointList" aria-expanded="true" class="fe chevron mr-2 href collapsed" (click)="addFlatpickr()"></i>
                      <p class="mb-0 my-sm-3 mx-sm-auto mx-lg-0">{{ pointList['controls'].title.value }}</p>
                    </div>
                  </div>

                  <div formArrayName="comments" class="card-body collapse" [id]="'pointLists' + indexPointList" [attr.id]="'pointLists' + indexPointList" role="tabpanel" [attr.data-parent]="'#pointLists' + indexPointList">
                    <div [formGroupName]="indexComment" class="form-group" *ngFor="let comment of pointList['controls'].comments['controls'] as FormGroup; let indexComment = index; let firstComment = first">
                      <ng-container *ngIf="firstComment">
                        <label>{{ comment['controls'].title.value }}</label>
                        <input type="text" formControlName="comment" class="form-control" placeholder="Date" data-toggle="flatpickr" [ngClass]="{ 'is-invalid': comment['controls'].comment.invalid && (comment['controls'].comment.dirty || comment['controls'].comment.touched) }">
                        <div *ngIf="comment['controls'].comment.invalid && (comment['controls'].comment.dirty || comment['controls'].comment.touched)" class="invalid-feedback">
                          {{ getErrorMessage("comment") }}
                        </div>
                      </ng-container>
                      <ng-container *ngIf="!firstComment">
                        <label>{{ comment['controls'].title.value }}</label>
                        <input type="text" placeholder="{{ comment['controls'].title.value }}" formControlName="comment" class="form-control d-inline-block col-12" [ngClass]="{'is-invalid': comment['controls'].comment.invalid && (comment['controls'].comment.dirty || comment['controls'].comment.touched) }">
                        <div *ngIf="comment['controls'].comment.invalid && (comment['controls'].comment.dirty || comment['controls'].comment.touched)" class="invalid-feedback">
                          {{ getErrorMessage("comment") }}
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

        </div>
      </div>

      <!-- ADD DETECTED ANOMALIES AND INTERVENTIONS IF WEEKLY/MONTHLY/QUARTERLY FRAME -->
      <div class="form-group">
        <label for="validationDetectionAnomaly">Anomalie(s) détectée(s)</label>
        <textarea formControlName="detected_anomalies" class="form-control" id="validationDetectionAnomaly" placeholder="Anomalie(s) detectée(s)" rows="5"
                  [ngClass]="{ 'is-invalid': detected_anomalies.invalid && (detected_anomalies.dirty || detected_anomalies.touched) }"></textarea>
        <div *ngIf="detected_anomalies.invalid && (detected_anomalies.dirty || detected_anomalies.touched)" class="invalid-feedback">
          {{ getErrorMessage("detected_anomalies") }}
        </div>
      </div>

      <div class="form-group">
        <label for="validationIntervention">Intervention(s)</label>
        <textarea formControlName="interventions" class="form-control" id="validationIntervention" placeholder="Intervention(s)" rows="5"
                  [ngClass]="{ 'is-invalid': interventions.invalid && (interventions.dirty || interventions.touched) }"></textarea>
        <div *ngIf="interventions.invalid && (interventions.dirty || interventions.touched)" class="invalid-feedback">
          {{ getErrorMessage("interventions") }}
        </div>
      </div>

    </form>
  </div>

  <div modal-footer class="w-100" *ngIf="isNew || canUpdate">
    <button class="btn btn-primary w-100" (click)="isNew ? createSanitaryAnalyzeFrame() : updateSanitaryAnalyzeFrame()" [ngClass]="{ 'is-loading is-loading-sm': loader }" [disabled]="loader || sanitaryAnalyzeFrameForm.invalid">
      {{ isNew ? 'Créer' : 'Mettre à jour' }}
    </button>
  </div>
</app-modal-right>
