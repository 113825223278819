import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { HttpClient } from '@angular/common/http';
import { RequestService } from './request.service';

@Injectable()
export class FirePermitsRequestService extends RequestService {
  constructor(
    protected http: HttpClient,
    protected notifier: NotifierService
  ) {
    super(http, notifier);
  }

  /// GET
  public getAllFirePermits(search, sort, order, limit, page, registry_id, establishment_id) {
    return this.get( 'registry/' + registry_id + '/establishment/' + establishment_id + '/fire-permits?' +
      'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getFirePermitsDashboard(search, sort, order, limit, page, registry_id, establishment_id, next) {
    return this.get( 'registry/' + registry_id + '/fire-permits?' +
      'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page +
      '&next=' + (next ? 'y' : '') + '&establishmentId=' + (establishment_id ? establishment_id : ''));
  }
  public getFirePermit(registry_id, establishment_id, firePermitId) {
    return this.get('registry/' + registry_id + '/establishment/' + establishment_id + '/fire-permits/' + firePermitId);
  }
  public getFiles(search, sort, order, limit, page, registry_id, establishment_id, firePermitId) {
    return this.get('registry/' + registry_id + '/establishment/' + establishment_id + '/fire-permit/' + firePermitId +
      '/files?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getFile(registry_id, establishment_id, firePermitId, file_id) {
    return this.get('registry/' + registry_id + '/establishment/' + establishment_id + '/fire-permit/' + firePermitId +
      '/files/' + file_id);
  }

  public getActions(search, sort, order, limit, page) {
    return this.get( 'fire-permits/actions?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getAction(actionId) {
    return this.get( 'fire-permits/actions/' + actionId);
  }

  /// CREATE
  public createFirePermit(registry_id, establishment_id, permit) {
    return this.post('registry/' + registry_id + '/establishment/' + establishment_id + '/fire-permits', { permit });
  }
  public createFile(registry_id, establishment_id, permitId, files) {
    return this.post('registry/' + registry_id + '/establishment/' + establishment_id + '/fire-permit/' + permitId + '/files',
      { files: files });
  }
  public createAction(action) {
    return this.post('fire-permits/actions', { action });
  }

  /// UPDATE
  public updateFirePermit(registry_id, establishment_id, permitId, permit) {
    return this.put('registry/' + registry_id + '/establishment/' + establishment_id + '/fire-permits/' + permitId,
      { permit });
  }
  public updateFile(registry_id, establishment_id, permitId, file_id, file) {
    return this.put('registry/' + registry_id + '/establishment/' + establishment_id + '/fire-permit/' + permitId +
      '/files/' + file_id, { file: file });
  }
  public updateAction(actionId, action) {
    return this.put('fire-permits/actions/' + actionId,
      { action });
  }

  /// DELETE
  public deleteFirePermit(registry_id, establishment_id, permitId) {
    return this.delete('registry/' + registry_id + '/establishment/' + establishment_id + '/fire-permits/' + permitId);
  }
  public deleteFile(registry_id, establishment_id, permitId, file_id) {
    return this.delete('registry/' + registry_id + '/establishment/' + establishment_id + '/fire-permit/' + permitId +
      '/files/' + file_id);
  }

  public deleteAction(actionId) {
    return this.delete('fire-permits/actions/' + actionId);
  }

  /// DOWNLOAD
  public downloadPdf(registry_id, establishment_id, permitId) {
    return this.get('registry/' + registry_id + '/establishment/' + establishment_id + '/fire-permits/' + permitId + '/download',
        { responseType: 'arraybuffer' });
  }
  public downloadFile(registry_id, establishment_id, permitId, file_id) {
    return this.get('registry/' + registry_id + '/establishment/' + establishment_id + '/fire-permit/' + permitId +
      '/files/' + file_id + '/download', { responseType: 'arraybuffer' });
  }
}
