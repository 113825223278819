import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Subject } from 'rxjs';
import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { IssuesRequestService } from '../../../services/issues.request.service';
import { UpdateIssueComponent } from '../update-issue/update-issue.component';
import IssueCommentFile from '../../../../shared/interfaces/issue_comment_file';
// import { SocketService } from '../socket.service';

@Component({
  selector: 'app-create-comment',
  templateUrl: './create-comment.component.html',
  styleUrls: ['./create-comment.component.scss']
})
export class CreateCommentComponent implements OnInit, AfterViewInit, OnDestroy {
  requestInProgress = new Subject();
  requestCount = 0;
  view = '';
  loader = false;
  modal = null;

  currentRoute = '';

  errors = {};
  files: IssueCommentFile[] = [];

  issueForm: FormGroup;
  id = new FormControl('');
  issue_id = new FormControl('');
  comment = new FormControl('', Validators.required);

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private issuesService: IssuesRequestService,
    private updateIssue: UpdateIssueComponent,
    // private socketService: SocketService
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loader = this.requestCount > 0;
    });

    this.currentRoute = '/' + this.activatedRoute.snapshot.url.join('/') + '/';

    this.issueForm = this.formBuilder.group({
      id: this.id,
      issue_id: this.issue_id,
      comment: this.comment
    });

    if (this.activatedRoute.parent.snapshot.url.length > 2) {
      this.view = 'client';
    } else {
      this.view = 'admin';
    }

    this.issueForm.patchValue({ issue_id: this.activatedRoute.parent.snapshot.params.issueId });
  }

  ngAfterViewInit() {
    this.modal.open();
  }

  ngOnDestroy() {
    this.modal.close();
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  createComment() {
    this.errors = {};
    this.loader = true;

    let request;
    if (this.view === 'client') {
      request = this.issuesService.createMyIssueComment(this.activatedRoute.parent.snapshot.params.issueId, this.issueForm.value);
    } else {
      request = this.issuesService.createIssueComment(this.activatedRoute.parent.snapshot.params.issueId, this.issueForm.value);
    }
    request.subscribe(response => {
      if (response['success']) {
        if (this.files.length) {
          this.createDocuments(response['comment']['id']);
        } else {
          // this.socketService.sendMessage('issue' + this.activatedRoute.parent.snapshot.params.issueId);
          this.modal.close();
          CustomNotifierService.getSuccessCreate(this.notifier);
        }
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.issueForm, this.notifier);
      }
      this.loader = false;
    });
  }

  createDocuments(commentId) {
    let request;
    this.errors = {};

    if (this.view === 'client') {
      request = this.issuesService.createMyIssueCommentFile(
        this.activatedRoute.parent.snapshot.params.issueId,
        commentId,
        this.files
      );
    } else {
      request = this.issuesService.createIssueCommentFile(
        this.activatedRoute.parent.snapshot.params.issueId,
        commentId,
        this.files
      );
    }

    request.subscribe(response => {
      if (response['success']) {
        // this.socketService.sendMessage('issue' + this.activatedRoute.parent.snapshot.params.issueId);
        this.modal.close();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loader = false;
    });
  }

  chooseFile() {
    const element: HTMLElement = document.getElementById('inputFile') as HTMLElement;
    element.click();
  }

  onFileChange(event) {
    if (event.target && event.target.files && event.target.files.length) {
      for (let i = 0; i < event.target.files.length; i++) {
        const reader = new FileReader();
        const file = event.target.files[i];
        reader.readAsDataURL(file);

        reader.onload = () => {
          let extension = '';
          let name = '';

          const split = file.name.split('.');
          if (split.length) {
            name = split[0];
            extension = split[split.length - 1];
          }

          this.files.push({ file_base64: reader.result, name: name, extension: extension });
        };
      }
    } else {
      let extension = '';
      let name = '';

      const split = event.name.split('.');
      if (split.length) {
        name = split[0];
        extension = split[split.length - 1];
      }

      this.files.push({ file_base64: event.file, name: name, extension: extension });
    }
    const element: HTMLInputElement = document.getElementById('inputFile') as HTMLInputElement;
    element.value = '';
  }

  removeFile(index) {
    this.files.splice(index, 1);

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  bindModal(modal) {
    this.modal = modal;
  }

  closeModal() {
    this.updateIssue.getIssue();
    this.router.navigate([this.activatedRoute.snapshot.parent.url.join('/')]).then(() => {});
  }

}
