import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Subject, Subscription } from 'rxjs';

import { environment } from '../../../../environments/environment';

import { AuthService } from '../../../core/auth/auth.service';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { TrainingsRequestService } from '../../../services/trainings.request.service';
import { RegistriesRequestService } from '../../../services/registries.request.service';
import { EstablishmentsRequestService } from '../../../services/establishments.request.service';

import Training from '../../../../shared/interfaces/training';
import Registry from '../../../../shared/interfaces/registry';
import Establishment from '../../../../shared/interfaces/establishment';

@Component({
  selector: 'app-show-training',
  templateUrl: './show-training.component.html',
  styleUrls: ['./show-training.component.scss']
})
export class ShowTrainingComponent implements OnInit, OnDestroy {
  changeEnterprise: Subscription;
  environment = environment;

  canUpdate = false;
  canCreate = false;
  loader = true;

  requestInProgress = new Subject();
  requestCount = 0;

  errors = {};

  pageName: string;
  previousPageName: string[] = [];
  previousPageRoute: string[] = [];
  returnRoute = '';

  registry: Registry;
  establishment: Establishment;
  trainings: Training[] = [];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private notifier: NotifierService,
    private registriesService: RegistriesRequestService,
    private establishmentsService: EstablishmentsRequestService,
    private trainingsService: TrainingsRequestService
  ) {
  }

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loader = this.requestCount > 0;
    });

    this.changeEnterprise = this.authService.getEnterprise().subscribe(() => {
      this.getRegistry();
      this.getEstablishment();
      this.getTrainings();
    });

    if (this.authService.loaded) {
      this.getRegistry();
      this.getEstablishment();
      this.getTrainings();
    }
  }

  ngOnDestroy() {
    this.changeEnterprise.unsubscribe();

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  getRegistry() {
    const snapshot = this.activatedRoute.snapshot.params;
    this.requestInProgress.next(true);
    this.registriesService.getRegistry(snapshot.registry_id).subscribe(response => {
      if (response['success']) {
        this.registry = response['registry'];
        this.createBreadcrumb();
      } else if (response['errors']) {
        this.errors = response['errors'];
      }
      this.requestInProgress.next(false);
    });
  }

  getEstablishment() {
    const snapshot = this.activatedRoute.snapshot.params;
    this.requestInProgress.next(true);
    this.establishmentsService.getMyEstablishment(snapshot.establishment_id).subscribe(response => {
      if (response['success']) {
        this.establishment = response['establishment'];
        this.createBreadcrumb();
      } else if (response['errors']) {
        this.errors = response['errors'];
      }
      this.requestInProgress.next(false);
    });
  }

  getTrainings() {
    this.requestInProgress.next(true);
    const registry_id = this.activatedRoute.snapshot.params.registry_id;
    const establishment_id = this.activatedRoute.snapshot.params.establishment_id;
    this.trainingsService.getTrainings('', 'Trainings.name', 'ASC', '', '', establishment_id, registry_id, true, false)
      .subscribe(response => {
        if (response['success']) {
          this.trainings = response['trainings'];
          this.createBreadcrumb();
        }
        this.requestInProgress.next(false);
      });
  }

  updateShow() {
    this.requestInProgress.next(true);

    const registry_id = this.activatedRoute.snapshot.params.registry_id;
    const establishment_id = this.activatedRoute.snapshot.params.establishment_id;

    this.trainingsService.updateTrainingsShow(registry_id, establishment_id, this.trainings)
      .subscribe(response => {
      if (response['success']) {
        CustomNotifierService.getSuccessUpdate(this.notifier);
        this.router.navigate([this.returnRoute]).then(() => {});
      } else if (response['errors']) {
        CustomNotifierService.getErrors(this.errors, this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  checkTraining(keyTraining) {
    this.trainings[keyTraining].display = !this.trainings[keyTraining].display;
  }

  createBreadcrumb() {
    const params = this.activatedRoute.snapshot.params;
    this.previousPageName = [];
    this.previousPageRoute = [];

    this.pageName = 'Edition de masse';

    this.previousPageName.push('Tableau de bord');
    this.previousPageRoute.push('/');

    this.previousPageName.push(this.registry ? this.registry.name : 'Tableau de bord registre');
    this.previousPageRoute.push('/dashboard-registry/' + params.registry_id);

    this.previousPageName.push(this.establishment ? this.establishment.name : 'Tableau de bord établissement');
    this.previousPageRoute.push('/dashboard-registry/' + params.registry_id + '/establishment/' + params.establishment_id);

    this.previousPageName.push('Formations');
    this.previousPageRoute.push('/dashboard-registry/' + params.registry_id + '/establishment/' + params.establishment_id + '/trainings');
    this.returnRoute = this.previousPageRoute.slice(-1).pop();
  }
}
