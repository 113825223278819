<div class="main-content">

  <div class="header bg-dark pb-5">
    <div class="container-fluid">

      <div class="header-body">
        <div class="row align-items-end">
          <div class="col">

            <h6 class="header-pretitle text-secondary">
              TABLEAU DE BORD
            </h6>

            <h1 class="header-title text-white">
              Statistiques Utilisateurs
            </h1>

          </div>
          <div class="col-auto">

            <ul class="nav nav-tabs header-tabs">
              <li class="nav-item" data-toggle="chart" data-target="#headerChart">
                <a href="#" class="nav-link text-center active" data-toggle="tab" (click)="updateUserChart('month')">
                  <h6 class="header-pretitle text-secondary">
                    GROUPER PAR
                  </h6>
                  <h3 class="text-white mb-0">
                    MOIS
                  </h3>
                </a>
              </li>
              <li class="nav-item" data-toggle="chart" data-target="#headerChart">
                <a href="#" class="nav-link text-center" data-toggle="tab" (click)="updateUserChart('day')">
                  <h6 class="header-pretitle text-secondary">
                    GROUPER PAR
                  </h6>
                  <h3 class="text-white mb-0">
                    JOUR
                  </h3>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link text-center">
                  <h6 class="header-pretitle text-secondary">
                    TOTAL
                  </h6>
                  <h3 class="text-white mb-0">
                    {{ totalUsers }}
                  </h3>
                </a>
              </li>
            </ul>

          </div>
        </div>
      </div>

      <div class="header-footer">

        <div class="chart">
          <canvas id="headerChart" class="chart-canvas"></canvas>
        </div>

      </div>

    </div>
  </div>

  <div class="container-fluid mt-n6">
    <div class="row">
      <div class="col-12 col-xl-5">

        <div class="card">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">

                <h4 class="card-header-title">
                  Bâtiments par type
                </h4>

              </div>
              <div class="col-auto mr-n3">

                <span class="text-muted">
                  Bâtiments total :
                </span>

              </div>
              <div class="col-auto">
                {{ totalEstablishments }}
              </div>
            </div>

          </div>
          <div class="card-body">

            <div class="chart">
              <canvas id="establishmentsTypeChart" class="chart-canvas"></canvas>
            </div>

          </div>
        </div>

      </div>
      <div class="col-12 col-xl-7">

        <div class="card">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">

                <h4 class="card-header-title">
                  Pourcentage d'entreprises ayant souscrit aux registres
                </h4>

              </div>
              <div class="col-auto mr-n3">

                <span class="text-muted">
                  Entreprises ayant des registres :
                </span>

              </div>
              <div class="col-auto">
                {{ totalEnterprises }}
              </div>
            </div>

          </div>
          <div class="card-body">

            <div class="chart">
              <canvas id="registryByEnterpriseChart" class="chart-canvas"></canvas>
            </div>

          </div>
        </div>

      </div>

      <div class="col-12">

        <div class="card">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">

                <h4 class="card-header-title">
                  Registres par domaine
                </h4>

              </div>
            </div>

          </div>
          <div class="card-body">

            <div class="chart">
              <canvas id="registryChart" class="chart-canvas"></canvas>
            </div>

          </div>
        </div>

      </div>
    </div>

  </div>

</div>
