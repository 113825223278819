import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AllPreventionsModule } from './all-preventions/all-preventions.module';
import { UpdatePreventionModule } from './update-prevention/update-prevention.module';

import { PreventionsRequestService } from '../../services/preventions.request.service';
import { UploadPreventionFileModule } from './upload-preventiion-file/upload-prevention-file.module';
import { UpdatePreventionSimplifiedModule } from './update-prevention-simplified/update-prevention-simplified.module';

@NgModule({
  imports: [
    CommonModule,
    AllPreventionsModule,
    UpdatePreventionModule,
    UpdatePreventionSimplifiedModule,
    UploadPreventionFileModule,
  ],
  providers: [
    PreventionsRequestService
  ]
})
export class PreventionsModule {}
