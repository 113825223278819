import { Routes } from '@angular/router';
import { UpdateSanitaryTemperatureFrameComponent } from './update-sanitary-temperature-frame/update-sanitary-temperature-frame.component';

export const sanitaryTemperatureFramesRoutes: Routes = [
  {
    path: 'create',
    component: UpdateSanitaryTemperatureFrameComponent
  },
  {
    path: ':sanitary_temperature_frame_id',
    component: UpdateSanitaryTemperatureFrameComponent
  },
];
