<app-modal-right (eventOnClose)="closeModal()" #record>{{ bindModal(record) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">{{  isNew ? 'Création du relevé de :' : canUpdate ? 'Mise à jour du relevé de :' : 'Consultation du relevé de :' }}</h6>

        <h1 class="header-title" *ngIf="sanitaryPool">{{ sanitaryPool.description }}</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <form [formGroup]="sanitaryPoolRecordForm" [hidden]="loaderContent">
      <!-- DATE -->
      <div class="form-group">
        <label for="validationDate" required>Date</label>
        <input type="text" [(ngModel)]="selected_date" [ngModelOptions]="{ standalone: true }" (ngModelChange)="updateSanitaryPoolRecord(selected_date)" class="form-control" id="validationDate" placeholder="Date de réalisation" data-toggle="flatpickr" [ngClass]="{ 'is-invalid': record_date.invalid && (record_date.dirty || record_date.touched) }">
      </div>
      <!-- ATTENDANCE -->
      <div class="form-group">
        <label for="validationAttendance" required>Fréquentation</label>
        <input type="number" formControlName="attendance" class="form-control" id="validationAttendance" placeholder="Fréquentation" [ngClass]="{ 'is-invalid': attendance.invalid && (attendance.dirty || attendance.touched) }">
      </div>
      <!-- TRANSPARENCY -->
      <h2 class="header-title mb-2">Transparence de l'eau</h2>
      <div formGroupName="transparency" class="row" *ngIf="sanitaryPoolRecordForm['controls'].transparency['controls'] as transparency">
        <div class="form-group col-lg-6">
          <label for="validationTransparency1" required>Relevé 1</label>
          <input type="number" formControlName="statement_1" class="form-control" id="validationTransparency1" placeholder="Relevé 1" [ngClass]="{ 'is-invalid': transparency['statement_1'].invalid && (transparency['statement_1'].dirty || transparency['statement_1'].touched) }">
        </div>
        <div class="form-group col-lg-6">
          <label for="validationTransparency2">Relevé 2</label>
          <input type="number" formControlName="statement_2" class="form-control" id="validationTransparency2" placeholder="Relevé 2" [ngClass]="{ 'is-invalid': transparency['statement_2'].invalid && (transparency['statement_2'].dirty || transparency['statement_2'].touched) }">
        </div>
      </div>
      <!-- WATER PH -->
      <h2 class="header-title mb-2">Ph de l'eau</h2>
      <div formGroupName="water_ph" class="row" *ngIf="sanitaryPoolRecordForm['controls'].water_ph['controls'] as water_ph">
        <div class="form-group col-lg-6">
          <label for="validationWaterPH1" required>Relevé 1</label>
          <input type="number" formControlName="statement_1" class="form-control" id="validationWaterPH1" placeholder="Relevé 1" [ngClass]="{ 'is-invalid': water_ph['statement_1'].invalid && (water_ph['statement_1'].dirty || water_ph['statement_1'].touched) }">
        </div>
        <div class="form-group col-lg-6">
          <label for="validationWaterPH2">Relevé 2</label>
          <input type="number" formControlName="statement_2" class="form-control" id="validationWaterPH2" placeholder="Relevé 2" [ngClass]="{ 'is-invalid': water_ph['statement_2'].invalid && (water_ph['statement_2'].dirty || water_ph['statement_2'].touched) }">
        </div>
      </div>
      <!-- DISINFECTANT -->
      <h2 class="header-title mb-2">Teneur en désinfectant de l'eau</h2>
      <div formGroupName="water_disinfectant" class="row" *ngIf="sanitaryPoolRecordForm['controls'].water_disinfectant['controls'] as water_disinfectant">
        <div class="form-group col-lg-6">
          <label for="validationDisinfectant1" required>Relevé 1</label>
          <input type="number" formControlName="statement_1" class="form-control" id="validationDisinfectant1" placeholder="Relevé 1" [ngClass]="{ 'is-invalid': water_disinfectant['statement_1'].invalid && (water_disinfectant['statement_1'].dirty || water_disinfectant['statement_1'].touched) }">
        </div>
        <div class="form-group col-lg-6">
          <label for="validationDisinfectant2">Relevé 2</label>
          <input type="number" formControlName="statement_2" class="form-control" id="validationDisinfectant2" placeholder="Relevé 2" [ngClass]="{ 'is-invalid': water_disinfectant['statement_2'].invalid && (water_disinfectant['statement_2'].dirty || water_disinfectant['statement_2'].touched) }">
        </div>
      </div>
      <!-- TEMPERATURE -->
      <h2 class="header-title mb-2">Température de l'eau</h2>
      <div formGroupName="temperature" class="row" *ngIf="sanitaryPoolRecordForm['controls'].temperature['controls'] as temperature">
        <div class="form-group col-lg-6">
          <label for="validationTemperature1" required>Relevé 1</label>
          <input type="number" formControlName="statement_1" class="form-control" id="validationTemperature1" placeholder="Relevé 1" [ngClass]="{ 'is-invalid': temperature['statement_1'].invalid && (temperature['statement_1'].dirty || temperature['statement_1'].touched) }">
        </div>
        <div class="form-group col-lg-6">
          <label for="validationTemperature2">Relevé 2</label>
          <input type="number" formControlName="statement_2" class="form-control" id="validationTemperature2" placeholder="Relevé 2" [ngClass]="{ 'is-invalid': temperature['statement_2'].invalid && (temperature['statement_2'].dirty || temperature['statement_2'].touched) }">
        </div>
      </div>
      <!-- WATER METER -->
      <div class="form-group">
        <label for="validationWaterMeter" required>Relevé des compteurs d'eau</label>
        <input type="number" formControlName="water_meter" class="form-control" id="validationWaterMeter" placeholder="Relevé des compteurs d'eau" [ngClass]="{ 'is-invalid': water_meter.invalid && (water_meter.dirty || water_meter.touched) }">
      </div>
      <!-- OBSERVATIONS -->
      <div class="form-group">
        <label for="validationObservation" required>
          Observations
          <span class="italic">
              relatives aux vérifications techniques, au lavage des filtres, à la vidange des bassins, à la vidange
              ou visite des filtres, au renouvellement des stocks de désinfectants, au remplissage des cuves de réactifs, aux incidents survenus.
            </span>
        </label>
        <textarea type="text" formControlName="observations" class="form-control" id="validationObservation" placeholder="Observations"></textarea>
        <div *ngIf="observations.invalid && (observations.dirty || observations.touched)" class="invalid-feedback">
          {{ getErrorMessage("observations") }}
        </div>
      </div>
      <!-- STABILIZER -->
      <h2 class="header-title mb-2">Nom du stabilisant utilisé et concentration (relevé hebdomadaire)</h2>
      <div formGroupName="stabilizer" class="row" *ngIf="sanitaryPoolRecordForm['controls'].stabilizer['controls'] as stabilizer">
        <div class="form-group col-lg-6">
          <label for="validationStabilizer1" required>Nom du stabilisant</label>
          <input type="text" formControlName="name" class="form-control" id="validationStabilizer1" placeholder="Nom du stabilisant" [ngClass]="{ 'is-invalid': stabilizer['name'].invalid && (stabilizer['name'].dirty || stabilizer['name'].touched) }">
        </div>
        <div class="form-group col-lg-6">
          <label for="validationStabilizer2" required>Concentration</label>
          <input type="text" formControlName="concentration" class="form-control" id="validationStabilizer2" placeholder="Concentration" [ngClass]="{ 'is-invalid': stabilizer['concentration'].invalid && (stabilizer['concentration'].dirty || stabilizer['concentration'].touched) }">
        </div>
      </div>
    </form>
  </div>

  <div modal-footer class="w-100" *ngIf="isNew || canUpdate">
    <button class="btn btn-primary w-100" (click)="updateSanitaryPoolRecord()" [ngClass]="{ 'is-loading is-loading-sm': loader }" [disabled]="loader || sanitaryPoolRecordForm.invalid">
      {{ isNew ? 'Créer' : 'Mettre à jour' }}
    </button>
  </div>
</app-modal-right>
