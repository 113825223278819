import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { HttpClient } from '@angular/common/http';
import { NotifierService } from 'angular-notifier';

@Injectable()
export class InstallationsRequestService extends RequestService {

  constructor(
    protected http: HttpClient,
    protected notifier: NotifierService
  ) {
    super(http, notifier);
  }

  /// GET
  public getInstallations(search, sort, order, limit, page) {
    return this.get('installations?' +
      'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getRegistryInstallations(search, sort, order, limit, page, registry_id) {
    return this.get('installations/registry/' + registry_id + '?' +
      'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getInstallation(installation_id) {
    return this.get('installation/' + installation_id);
  }
  public getAllMyRegistryEstablishmentInstallations(search, sort, order, limit, page, registry_id, establishment_id) {
    return this.get('establishment/mine/' + establishment_id + '/registry/' + registry_id + '/installations?' +
      'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page + '&all=1');
  }
  public getMyRegistryEstablishmentInstallations(search, sort, order, limit, page, registry_id, establishment_id) {
    return this.get('establishment/mine/' + establishment_id + '/registry/' + registry_id + '/installations?' +
      'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getMyRegistryEnterprise(search, sort, order, limit, page, registry_id) {
    return this.get('establishment/mine/registry/' + registry_id + '/installations?' +
        'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getMyRegistryEstablishmentInstallationsShow(registry_id, establishment_id) {
    return this.get('establishment/mine/' + establishment_id + '/registry/' + registry_id + '/installations/show');
  }
  public getCustomerInstallations(search, sort, order, limit, page, enterprise_id, establishment_id, registry_id) {
    return this.get('customers/' + enterprise_id + '/establishments/' + establishment_id + '/registries/' + registry_id +
      '/installations?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getInstallationCustom(installation_id) {
    return this.get('installations/' + installation_id + '/custom');
  }

  /// CREATE
  public createInstallation(installation) {
    return this.post('installation', { installation });
  }
  public createInstallationCustom(establishment_id, installation) {
    return this.post('establishment/mine/' + establishment_id + '/installations/custom', { installation });
  }

  /// UPDATE
  public updateInstallation(installation_id, installation) {
    return this.put('installation/' + installation_id, { installation });
  }
  public updateMyRegistryEstablishmentInstallationsShow(establishment_id, registry_id, installations) {
    return this.put('establishment/mine/' + establishment_id + '/registry/' + registry_id +
      '/installations/show', { installations });
  }
  public updateInstallationCustom(installation_id, establishment_id, installation) {
    return this.put('establishment/mine/' + establishment_id + '/installations/' + installation_id + '/custom', { installation });
  }

  /// DELETE
  public deleteInstallationCustom(establishment_id, installation_id) {
    return this.delete('establishment/mine/' + establishment_id + '/installations/' + installation_id + '/custom');
  }
}
