import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';

import { AllWorksheetsComponent } from '../all-worksheets/all-worksheets.component';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { WorksheetsRequestService } from '../../../services/worksheets.request.service';

@Component({
  selector: 'app-update-required-worksheet',
  templateUrl: './update-required-worksheet.component.html',
  styleUrls: ['./update-required-worksheet.component.scss']
})
export class UpdateRequiredWorksheetComponent implements OnInit, AfterViewInit, OnDestroy {
  canCreate = false;
  canUpdate = false;

  requiredWorksheetForm: FormGroup;
  id = new FormControl('');
  registry_id = new FormControl('');
  name = new FormControl('', Validators.required);
  is_required =  new FormControl(false);

  errors = {};

  isNew = true;
  loader = false;
  loaderContent = false;

  modalRequiredWorksheet = null;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private allWorksheets: AllWorksheetsComponent,
    private worksheetsService: WorksheetsRequestService
  ) {}

  ngOnInit() {
    this.requiredWorksheetForm = this.formBuilder.group({
      id: this.id,
      registry_id: this.registry_id,
      name: this.name,
      is_required: this.is_required
    });

    if (this.activatedRoute.snapshot.params.file_type_id) {
      this.isNew = false;
      this.getRequiredWorksheet();
    }

    this.registry_id.setValue(this.activatedRoute.snapshot.parent.params.registry_id);
  }

  ngAfterViewInit() {
    this.modalRequiredWorksheet.open();
  }

  ngOnDestroy() {
    this.modalRequiredWorksheet.close();
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  getRequiredWorksheet() {
    let request;
    this.loaderContent = true;

    request = this.worksheetsService.getRequiredWorksheet(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.params.file_type_id
    );

    request.subscribe(response => {
      if (response['success']) {
        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];

        this.requiredWorksheetForm.patchValue(response['requiredWorksheet']);

        if (!this.canUpdate) {
          this.requiredWorksheetForm.disable();
        }
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loaderContent = false;
    });
  }

  createRequiredWorksheet() {
    let request;
    this.errors = {};
    this.loader = true;

    request = this.worksheetsService.createRequiredWorksheet(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.requiredWorksheetForm.value
    );

    request.subscribe(response => {
      if (response['success']) {
        this.modalRequiredWorksheet.close();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loader = false;
    });
  }

  updateRequiredWorksheet() {
    let request;
    this.errors = {};
    this.loader = true;

    request = this.worksheetsService.updateRequiredWorksheet(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.params.file_type_id,
      this.requiredWorksheetForm.value
    );

    request.subscribe(response => {
      if (response['success']) {
        this.modalRequiredWorksheet.close();
        CustomNotifierService.getSuccessUpdate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loader = false;
    });
  }

  bindModal(modal) {
    this.modalRequiredWorksheet = modal;
  }

  closeModal() {
    this.allWorksheets.refreshData();
    this.router.navigate([this.activatedRoute.snapshot.parent.url.join('/')]).then(() => {});
  }
}
