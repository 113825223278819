<div class="main-content">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col">
        <div class="header mb-0">
          <div class="header-body no-border">
            <div class="row align-items-center">
              <div class="col">
                <h1 class="header-title">Établissements / Registres</h1>

                <small class="form-text text-muted"
                  >Attribution des établissements / registres à l'utilisateur
                  {{ user && user.firstname + " " + user.lastname }} de mon
                  entreprise</small
                >

                <app-breadcrumbs
                  [currentPage]="pageName"
                  [names]="previousPageName"
                  [routes]="previousPageRoute"
                ></app-breadcrumbs>
              </div>

              <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0">
                <a
                  class="btn btn-primary d-block d-md-inline-block"
                  [routerLink]=""
                  (click)="updateAccess()"
                >
                  Valider
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="is-loading loader-content" *ngIf="loader"></div>

        <div class="tab-content" *ngIf="!loader && establishments.length">
          <div
            class="tab-pane fade show active"
            id="tabPaneOne"
            role="tabpanel"
          >
            <div class="row listAlias">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <h2 class="col">Établissements / Registres</h2>
                    </div>
                  </div>
                  <div class="table-responsive">
                    <table class="table table-nonfluid table-striped">
                      <thead>
                        <tr>
                          <th></th>
                          <th
                            *ngFor="let registry of registries"
                            [ngStyle]="{
                              'background-color': registry.background_color,
                              color: 'white'
                            }"
                          >
                            {{ registry.name }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <th>Tous les établissements</th>
                        <td *ngFor="let registry of registries" [ngStyle]="{ 'background-color': registry.background_color }">
                          <div class="d-flex justify-content-center">
                            <i class="fe fe-check-square fe-white" *ngIf="registry.checked; else notChecked" (click)="checkRegistry(registry, false)"></i>
                            <ng-template #notChecked>
                              <i class="fe fe-square fe-white" (click)="checkRegistry(registry, true)"></i>
                            </ng-template>
                          </div>
                        </td>
                      </tr>

                      <ng-container *ngFor="let establishment of establishments; let firstLevelIndex = index; let first = first; let last = last">
                        <!-- GROUP LEVEL 1 -->
                        <ng-container *ngIf="establishment.is_group">
                          <tr class="divider" *ngIf="first"></tr>
                          <tr>
                            <th class="d-flex align-items-center">
                              <a class="href" (click)="toggleLevel1(firstLevelIndex)">
                                <i class="fe fe-chevron-right" *ngIf="establishment.collapsed"></i>
                                <i class="fe fe-chevron-down" *ngIf="!establishment.collapsed"></i>
                                {{ establishment.name }}
                              </a>
                            </th>
                            <td *ngFor="let registry of registries" [ngStyle]="{ 'background-color': registry.background_color }">
                              <div class="d-flex justify-content-center" *ngIf="checkIfGroupRegistry(establishment, registry)">
                                <i class="fe fe-check-square fe-white" *ngIf="checkGroup(establishment, registry); else notChecked" (click)="checkGroupRegistry(registry, establishment, false)"></i>
                                <ng-template #notChecked>
                                  <i class="fe fe-square fe-white" (click)="checkGroupRegistry(registry, establishment, true)"></i>
                                </ng-template>
                              </div>
                            </td>
                          </tr>

                          <!-- ESTABLISHMENT LEVEL 1 -->
                          <ng-container *ngFor="let level1 of establishment.establishment_groups; let secondLevelIndex = index">
                            <!-- GROUP LEVEL 2 -->
                            <ng-container *ngIf="level1.is_group && !establishment.collapsed">
                              <tr>
                                <th class="d-flex align-items-center">
                                  <a class="href" (click)="toggleLevel2(firstLevelIndex, secondLevelIndex)">
                                    <i class="fe fe-minus mr-2"></i>
                                    <i class="fe fe-chevron-right" *ngIf="level1.collapsed"></i>
                                    <i class="fe fe-chevron-down" *ngIf="!level1.collapsed"></i>
                                    {{ level1.name }}
                                  </a>
                                </th>
                                <td *ngFor="let registry of registries" [ngStyle]="{ 'background-color': registry.background_color }">
                                  <div class="d-flex justify-content-center" *ngIf="checkIfGroupRegistry(level1, registry)">
                                    <i class="fe fe-check-square fe-white" *ngIf="checkGroup(level1, registry); else notChecked" (click)="checkGroupRegistry(registry, level1, false)"></i>
                                    <ng-template #notChecked>
                                      <i class="fe fe-square fe-white" (click)="checkGroupRegistry(registry, level1, true)"></i>
                                    </ng-template>
                                  </div>
                                </td>
                              </tr>
                              <!-- ESTABLISHMENT LEVEL 2-->
                              <ng-container *ngIf="!level1.establishment_groups.length">
                                <tr *ngIf="!level1.collapsed"></tr>
                              </ng-container>
                              <ng-container *ngFor="let level2 of level1.establishment_groups">
                                <tr *ngIf="!level1.collapsed">
                                  <th class="d-flex align-items-center">
                                    <i class="fe fe-minus mr-2"></i>
                                    <i class="fe fe-minus mr-2"></i>
                                    <div>
                                      <small *ngIf="level2.parent_id" style="font-size: 0.8em">{{ level2.parent.name }} <br></small>
                                      {{ level2.name }}
                                    </div>
                                  </th>
                                  <td *ngFor="let registry of registries" [ngStyle]="{ 'background-color': registry.background_color }">
                                    <div *ngIf="hasRegistry(level2.establishment_registries, registry)" class="d-flex justify-content-center">
                                      <i class="fe fe-check-square fe-white" *ngIf="getChecked(level2.establishment_registries, registry); else notChecked" (click)="groupCheck(level2.establishment_registries, registry)"></i>
                                      <ng-template #notChecked>
                                        <i class="fe fe-square fe-white" (click)="groupCheck(level2.establishment_registries, registry)"></i>
                                      </ng-template>
                                    </div>
                                  </td>
                                </tr>
                              </ng-container>
                            </ng-container>

                            <!-- ESTABLISHMENT LEVEL 1 WITH NO GROUP -->
                            <ng-container *ngIf="!level1.is_group && !establishment.collapsed">
                              <tr>
                                <th class="d-flex align-items-center">
                                  <i class="fe fe-minus mr-2"></i>
                                  <div>
                                    <small *ngIf="level1.parent_id" style="font-size: 0.8em">{{ level1.parent.name }} <br></small>
                                    {{ level1.name }}
                                  </div>
                                </th>
                                <td *ngFor="let registry of registries" [ngStyle]="{ 'background-color': registry.background_color }">
                                  <div *ngIf="hasRegistry(level1.establishment_registries, registry)" class="d-flex justify-content-center">
                                    <i class="fe fe-check-square fe-white" *ngIf="getChecked(level1.establishment_registries, registry); else notChecked" (click)="groupCheck(level1.establishment_registries, registry)"></i>
                                    <ng-template #notChecked>
                                      <i class="fe fe-square fe-white" (click)="groupCheck(level1.establishment_registries, registry)"></i>
                                    </ng-template>
                                  </div>
                                </td>
                              </tr>
                            </ng-container>
                          </ng-container>

                          <tr class="divider"></tr>
                        </ng-container>

                        <!-- ESTABLISHMENT WITH NO GROUP -->
                        <tr *ngIf="!establishment.is_group">
                          <th>
                            <small *ngIf="establishment.parent_id" style="font-size: 0.8em">{{ establishment.parent.name }} <br></small>
                            {{ establishment.name }}
                          </th>
                          <td *ngFor="let registry of registries" [ngStyle]="{ 'background-color': registry.background_color }">
                            <div *ngIf="hasRegistry(establishment.establishment_registries, registry)" class="d-flex justify-content-center">
                              <i class="fe fe-check-square fe-white" *ngIf="getChecked(establishment.establishment_registries, registry); else notChecked" (click)="check(establishment.establishment_registries, registry)"></i>
                              <ng-template #notChecked>
                                <i class="fe fe-square fe-white" (click)="check(establishment.establishment_registries, registry)"></i>
                              </ng-template>
                            </div>
                          </td>
                        </tr>

                      </ng-container>

                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="loader-content" *ngIf="!loader && !establishments.length">
          <p>Aucun résultat</p>
        </div>
      </div>
    </div>
  </div>
</div>

<router-outlet></router-outlet>
