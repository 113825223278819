import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { ToolbarComponent } from './toolbar.component';
import { ToolbarNotificationModule } from '../toolbar-notification/toolbar-notification.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ToolbarNotificationModule
  ],
  declarations: [ToolbarComponent],
  exports: [ToolbarComponent]
})

export class ToolbarModule {}
