<app-modal-right (eventOnClose)="closeModal()" #group>{{ bindModal(group) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">{{ isNew ? 'Création' : canUpdate ? 'Mise à jour' : 'Consultation' }}</h6>

        <h1 class="header-title">Groupe</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <form class="mb-4" [formGroup]="groupForm" [hidden]="loaderContent">

      <div class="tab-content">

        <div class="form-group">
          <label for="validationName" required>Nom</label>
          <input type="text" formControlName="name" class="form-control" id="validationName" placeholder="Nom" [ngClass]="{ 'is-invalid': name.invalid && (name.dirty || name.touched) }" maxlength="255">
          <div *ngIf="name.invalid && (name.dirty || name.touched)" class="invalid-feedback">
            {{ getErrorMessage("name") }}
          </div>
        </div>

        <div class="form-group">
          <label for="validationIssuancePersonInCharge" required>Responsable</label>
          <input type="text" formControlName="person_in_charge" class="form-control" id="validationIssuancePersonInCharge" placeholder="Responsable" maxlength="255"
                 [ngClass]="{ 'is-invalid': person_in_charge.invalid && (person_in_charge.dirty || person_in_charge.touched) }">
          <div *ngIf="person_in_charge.invalid && (person_in_charge.dirty || person_in_charge.touched)" class="invalid-feedback">
            {{ getErrorMessage("person_in_charge") }}
          </div>
        </div>

        <label for="listGroup">Etablissements</label>
        <ng-select [items]="establishments"
                   [multiple]="true"
                   bindLabel="name"
                   bindValue="id"
                   notFoundText="Aucun résultat"
                   [selectableGroup]="true"
                   [selectableGroupAsModel]="false"
                   [closeOnSelect]="false"
                   placeholder="Sélectionnez un/des établissement(s)"
                   [(ngModel)]="selectedEstablishments" [ngModelOptions]="{ standalone: true }">
          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
            <input id="listGroup" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }" /> {{ item.name }}
          </ng-template>
        </ng-select>

        <div class="form-group mt-4">
          <label>Description du groupe</label>
          <editor initialValue=""
                  formControlName="description"
                  [init]="{ height: 500, menubar: false, plugins: ['link preview table code lists'],
                              toolbar:'undo redo | formatselect fontselect fontsizeselect | ' +
                              'bold italic underline strikethrough backcolor forecolor | preview | ' +
                              'alignleft aligncenter alignright alignjustify | ' +
                              'bullist numlist outdent indent link table'
                            }">
          </editor>
          <div *ngIf="description.invalid && (description.dirty || description.touched)" class="invalid-feedback">
            {{ getErrorMessage("description") }}
          </div>
        </div>

      </div>

    </form>
  </div>

  <div modal-footer class="w-100 d-flex justify-content-between">
    <button class="btn btn-primary w-30"
            (click)="isNew ? createGroup() : updateGroup()" [ngClass]="{ 'is-loading is-loading-sm': loader }"
            [disabled]="loader || groupForm.invalid || (!isNew && !canUpdate)">
      {{ isNew ? 'Créer' : 'Mettre à jour' }}
    </button>
  </div>
</app-modal-right>
