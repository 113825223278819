import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AfterViewInit, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Subject } from 'rxjs';
import { AuthService } from '../../../core/auth/auth.service';

import { environment } from '../../../../environments/environment';

import { AllUsersComponent } from '../all-users/all-users.component';
import { AllProviderRegistryUsersComponent } from '../all-provider-registry-users/all-provider-registry-users.component';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { UsersRequestService } from '../../../services/users.request.service';
import { CheckpointsRequestService } from '../../../services/checkpoints.request.service';
import { RolesRequestService } from '../../../services/roles.request.service';

import { ModalRightComponent } from '../../../core/modal-right/modal-right.component';

import Establishment from '../../../../shared/interfaces/establishment';
import Registry from '../../../../shared/interfaces/registry';


declare var $: any;

@Component({
  selector: 'app-update-user',
  templateUrl: './update-user.component.html',
  styleUrls: ['./update-user.component.scss']
})
export class UpdateUserComponent implements OnInit, AfterViewInit, OnDestroy {
  canUpdate = false;
  canAffect = false;
  errors = {};
  exist = false;

  requestInProgress = new Subject();
  requestCount = 0;

  showEmailForm = true;
  emailForm: FormGroup;
  emailSearch = new FormControl('', [Validators.required, Validators.email]);

  showUserForm = false;
  userForm: FormGroup;
  id = new FormControl('');
  firstname = new FormControl('', [Validators.required]);
  lastname = new FormControl('', [Validators.required]);
  phone = new FormControl('');
  email = new FormControl('', [Validators.required, Validators.email]);
  role_id = new FormControl(null, [Validators.required]);
  access_to_enterprise = new FormControl(false);
  picture = new FormControl(null);

  previousRoute = '';

  isNew = true;
  loader = false;
  loaderContent = false;
  showRoleSelect = true;

  activeTab = 'user';

  isProvider = false;
  showAccessForm = true;
  establishments: Establishment[] = [];
  registries: Registry[] = [];
  access: any[] = [];

  modalUser: ModalRightComponent = null;

  roles: any = [];
  allRegistryProviderUsers: any;
  allUsers: any;

  openedLevel1 = -1;
  openedLevel2 = -1;

  constructor(
    private router: Router,
    private injector: Injector,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private checkpointsService: CheckpointsRequestService,
    private usersService: UsersRequestService,
    private rolesService: RolesRequestService,
    private authService: AuthService
  ) {
    if (this.activatedRoute.snapshot.parent.params.registry_id && this.activatedRoute.snapshot.parent.params.establishment_id &&
      this.activatedRoute.snapshot.parent.params.enterprise_id) {
      this.allRegistryProviderUsers = <AllProviderRegistryUsersComponent>this.injector.get(AllProviderRegistryUsersComponent);
    } else {
      this.allUsers = <AllUsersComponent>this.injector.get(AllUsersComponent);
    }
  }

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loaderContent = this.requestCount > 0;
    });

    this.previousRoute = this.activatedRoute.snapshot.parent.url.join('/');

    this.emailForm = this.formBuilder.group({
      email: this.emailSearch
    });

    if (this.activatedRoute.snapshot.parent.url.length === 1) {
      this.showEmailForm = false;
      this.showUserForm = true;
      this.showRoleSelect = false;
      this.showAccessForm = false;
    } else if (this.activatedRoute.snapshot.parent.url.length === 3) {
      if (this.activatedRoute.snapshot.parent.url[0].path === 'providers') {
        this.showRoleSelect = false;
        this.isProvider = true;
      }
      this.showAccessForm = false;
    } else if (this.activatedRoute.snapshot.parent.params.registry_id && this.activatedRoute.snapshot.parent.params.establishment_id &&
      this.activatedRoute.snapshot.parent.params.enterprise_id) {
        this.showRoleSelect = false;
        this.isProvider = true;
        this.showAccessForm = false;
    }

    if (this.showRoleSelect) {
      this.userForm = this.formBuilder.group({
        id: this.id,
        firstname: this.firstname,
        lastname: this.lastname,
        phone: this.phone,
        email: this.email,
        role_id: this.role_id,
        access_to_enterprise: this.access_to_enterprise,
        picture: this.picture
      });
      this.getRoles();
    } else {
      this.userForm = this.formBuilder.group({
        id: this.id,
        firstname: this.firstname,
        lastname: this.lastname,
        phone: this.phone,
        email: this.email,
        picture: this.picture
      });
    }

    if (this.activatedRoute.snapshot.params.user_id) {
      this.isNew = false;
      this.showEmailForm = false;
      this.showUserForm = true;
      this.userForm.controls['email'].disable();
      this.getUser();
    } else if (this.activatedRoute.snapshot.parent.url.length === 2) {
      this.getCheckpoints();
    }

  }

  ngAfterViewInit() {
    this.modalUser.open();
  }

  ngOnDestroy() {
    this.modalUser.close();
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  getUser() {
    this.errors = {};
    this.requestInProgress.next(true);

    let request;

    if (this.activatedRoute.snapshot.parent.url[1] && this.activatedRoute.snapshot.parent.url[1].path === 'mine') {
      request = this.usersService.getMyUser(this.activatedRoute.snapshot.params.user_id);
    } else if (this.activatedRoute.snapshot.parent.url[0] && this.activatedRoute.snapshot.parent.url[0].path === 'users') {
      request = this.usersService.getUser(this.activatedRoute.snapshot.params.user_id);
    } else if (this.activatedRoute.snapshot.parent.url[0] && this.activatedRoute.snapshot.parent.url[0].path === 'enterprises') {
      request = this.usersService.getUserInEnterprise(
        this.activatedRoute.snapshot.params.user_id,
        this.activatedRoute.snapshot.parent.params.enterprise_id
      );
    } else if (this.activatedRoute.snapshot.parent.url[0] && this.activatedRoute.snapshot.parent.url[0].path === 'providers') {
      request = this.usersService.getProviderUser(
        this.activatedRoute.snapshot.parent.params.enterprise_id,
        this.activatedRoute.snapshot.params.user_id
      );
    } else if (this.activatedRoute.snapshot.parent.url[4] || this.activatedRoute.snapshot.parent.url[4].path === 'providers') {
      request = this.usersService.getProviderUser(
        this.activatedRoute.snapshot.parent.params.enterprise_id,
        this.activatedRoute.snapshot.params.user_id
      );
    }

    request.subscribe(response => {
      if (response['success']) {
        this.canUpdate = response['canUpdate'];
        this.canAffect = response['canAffect'];
        this.userForm.patchValue(response['user']);
        if (response['user']['last_user_enterprise']) {
          this.userForm.patchValue({ access_to_enterprise: response['user']['last_user_enterprise']['access_to_enterprise'] });
          this.role_id.patchValue(response['user']['last_user_enterprise']['role_id']);
        }

        if (this.userForm.value.role_id === 1) {
          this.userForm.controls['role_id'].disable();
          this.showRoleSelect = false;
        }

        if (!this.canUpdate) {
          this.userForm.disable();
        } else if (this.activatedRoute.snapshot.parent.url.length === 2 && this.canAffect) {
          this.showAccessForm = true;
          this.getCheckpoints();
        }
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.userForm, this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }
  getRoles() {
    this.requestInProgress.next(true);
    this.rolesService.getRoles('', 'Roles.order_number', 'asc', '', '').subscribe(response => {
      if (response['success']) {
        this.roles = response['roles'];
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.userForm, this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  checkEmail() {
    this.loader = true;
    this.showUserForm = false;
    this.userForm.reset();
    this.userForm.patchValue({access_to_enterprise: false});

    this.usersService.checkEmail(this.emailForm.value.email).subscribe(response => {
      if (response['success']) {
        this.exist = response['exist'];
        this.showUserForm = true;
        if (!this.exist) {
          this.userForm.patchValue({email: this.emailForm.value.email});
          this.userForm.controls['firstname'].enable();
          this.userForm.controls['lastname'].enable();
          this.userForm.controls['email'].disable();
        } else {
          this.userForm.patchValue(response['user']);
          this.userForm.controls['firstname'].disable();
          this.userForm.controls['lastname'].disable();
          this.userForm.controls['email'].disable();
        }
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.emailForm, this.notifier);
      }
      this.loader = false;
    });
  }
  createUser() {
    this.errors = {};
    this.loader = true;

    const user = { ...this.userForm.value, email: this.email.value };
    let request;

    if (this.activatedRoute.snapshot.parent.url[1] && this.activatedRoute.snapshot.parent.url[1].path === 'mine') {
      request = this.usersService.createMyUser(user);
    } else if (this.activatedRoute.snapshot.parent.url[0] && this.activatedRoute.snapshot.parent.url[0].path === 'users') {
      request = this.usersService.createUser(user);
    } else if (this.activatedRoute.snapshot.parent.url[0] && this.activatedRoute.snapshot.parent.url[0].path === 'enterprises') {
      request = this.usersService.createEnterpriseUser(this.activatedRoute.snapshot.parent.params.enterprise_id, user);
    } else if (this.activatedRoute.snapshot.parent.url[0] && this.activatedRoute.snapshot.parent.url[0].path === 'providers') {
      request = this.usersService.createProvider(this.activatedRoute.snapshot.parent.params.enterprise_id, user);
    }

    request.subscribe(response => {
      if (response['success']) {
        if (this.showAccessForm && this.canAffect) {
          this.updateAccess(response['user']['id']);
        } else {
          this.modalUser.close();
        }
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.userForm, this.notifier);
      }
      this.loader = false;
    });
  }
  updateUser() {
    this.errors = {};
    this.loader = true;

    let request;
    const user = { ...this.userForm.value, email: this.email.value };

    if (this.activatedRoute.snapshot.parent.url[1] && this.activatedRoute.snapshot.parent.url[1].path === 'mine') {
      request = this.usersService.updateMyUser(this.userForm.value.id, user);
    } else if (this.activatedRoute.snapshot.parent.url[0] && this.activatedRoute.snapshot.parent.url[0].path === 'users') {
      request = this.usersService.updateUser(this.userForm.value.id, user);
    } else if (this.activatedRoute.snapshot.parent.url[0] && this.activatedRoute.snapshot.parent.url[0].path === 'enterprises') {
      const enterprise_id = this.activatedRoute.snapshot.parent.params.enterprise_id;
      request = this.usersService.updateEnterpriseUser(enterprise_id, this.userForm.value.id, user);
    } else if (this.activatedRoute.snapshot.parent.url[0] && this.activatedRoute.snapshot.parent.url[0].path === 'providers') {
      request = this.usersService.updateProvider(this.activatedRoute.snapshot.parent.params.enterprise_id, this.userForm.value.id, user);
    } else if (this.activatedRoute.snapshot.parent.url[4] || this.activatedRoute.snapshot.parent.url[4].path === 'providers') {
      request = this.usersService.updateProvider(this.activatedRoute.snapshot.parent.params.enterprise_id, this.userForm.value.id, user);
    }

    request.subscribe(response => {
      if (response['success']) {
        const getUser = JSON.parse(localStorage.getItem('user'));
        if (getUser.id === response['user']['id']) {
         getUser.picture = response['picture']['picture'];
          this.saveUserToLocalstorage(getUser);
          this.authService.changeUser(getUser);
        }

        if (this.showAccessForm && this.canAffect) {
          this.updateAccess(this.id.value);
        } else {
          this.loader = false;
          this.modalUser.close();
        }
        CustomNotifierService.getSuccessUpdate(this.notifier);
      } else if (response['errors']) {
        this.loader = false;
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.userForm, this.notifier);
      }
    });
  }
  addUser() {
    this.errors = {};
    this.loader = true;

    const user = { ...this.userForm.value };
    let request;

    if (this.activatedRoute.snapshot.parent.url[1] && this.activatedRoute.snapshot.parent.url[1].path === 'mine') {
      request = this.usersService.addMyUser(user);
    } else if (this.activatedRoute.snapshot.parent.url[0] && this.activatedRoute.snapshot.parent.url[0].path === 'enterprises') {
      request = this.usersService.addUserEnterprise(this.activatedRoute.snapshot.parent.params.enterprise_id, user.id, user);
    } else if (this.activatedRoute.snapshot.parent.url[0] && this.activatedRoute.snapshot.parent.url[0].path === 'providers') {
      request = this.usersService.addProvider(this.activatedRoute.snapshot.parent.params.enterprise_id, user.id);
    } else if (this.activatedRoute.snapshot.parent.url[4] || this.activatedRoute.snapshot.parent.url[4].path === 'providers') {
      request = this.usersService.addProvider(this.activatedRoute.snapshot.parent.params.enterprise_id, user.id);
    }

    request.subscribe(response => {
      if (response['success']) {
        CustomNotifierService.getSuccessCreate(this.notifier);
        if (this.showAccessForm) {
          this.updateAccess(this.id.value);
        } else {
          this.modalUser.close();
        }
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.userForm, this.notifier);
      }
      this.loader = false;
    });
  }

  getCheckpoints() {
    this.requestInProgress.next(true);
    let request;
    if (this.isNew) {
      request = this.checkpointsService.getRegistriesForInternal(0);
    } else {
      request = this.checkpointsService.getRegistriesForInternal(this.activatedRoute.snapshot.params.user_id);
    }

    request.subscribe(response => {
      if (response['success']) {
        this.registries = response['registries'];
        this.registries.forEach(registry => {
          registry['count'] = 0;
        });
        this.establishments = [];
        response['establishments'].forEach(establishment => {
          if (!establishment.parent_id) {
            this.establishments.push(establishment);
          }
        });
        response['establishments'].forEach(establishment => {
          if (establishment.parent_id) {
            const found = this.establishments.findIndex(element => {
              return element.id === establishment.parent_id;
            });
            if (found !== -1) {
              this.establishments.splice(found + 1, 0, establishment);
            } else {
              this.establishments.forEach(level1 => {
                const foundGroupLevel1 = level1.establishment_groups.findIndex(element => {
                  return element.id === establishment.parent_id;
                });
                if (foundGroupLevel1 !== -1) {
                  level1.establishment_groups.splice(foundGroupLevel1 + 1, 0, establishment);
                } else {
                  if (level1.establishment_groups && level1.establishment_groups.length) {
                    level1.establishment_groups.forEach(level2 => {
                      if (level2.establishment_groups.length) {
                        const foundGroupLevel2 = level2.establishment_groups.findIndex(element => {
                          return element.id === establishment.parent_id;
                        });
                        if (foundGroupLevel2 !== -1) {
                          level2.establishment_groups.splice(foundGroupLevel2 + 1, 0, establishment);
                        } else {
                          this.establishments.push(establishment);
                        }
                      }
                    });
                  }
                }
              });
            }
          }
        });
        this.establishments.forEach(establishment => {
          establishment.collapsed = true;
          if (establishment.establishment_groups.length) {
            establishment.establishment_groups.forEach(level1 => {
              level1.collapsed = true;
              if (level1.establishment_groups && level1.establishment_groups.length) {
                level1.establishment_groups.forEach(level2 => {
                  level2.collapsed = true;
                  level2.establishment_registries.forEach(establishment_registry => {
                    const found = this.registries.find(element => {
                      return element.id === establishment_registry.registry_id;
                    });
                    found['count'] += 1;
                  });
                });
              } else {
                level1.establishment_registries.forEach(establishment_registry => {
                  const found = this.registries.find(element => {
                    return element.id === establishment_registry.registry_id;
                  });
                  found['count'] += 1;
                });
              }
            });
          } else {
            establishment.establishment_registries.forEach(establishment_registry => {
              const found = this.registries.find(element => {
                return element.id === establishment_registry.registry_id;
              });
              found['count'] += 1;
            });
          }
        });
        const arrayToSplice = [];
        this.registries.forEach((registry, index) => {
          if (registry['count'] === 0) {
            arrayToSplice.unshift(index);
          }
        });
        arrayToSplice.forEach(index => {
          this.registries.splice(index, 1);
        });
        this.access = response['access'];
        this.access.forEach(access => {
          this.establishments.forEach(establishment => {
            if (establishment.establishment_groups && establishment.establishment_groups.length) {
              const foundGroupLevel1 = establishment.establishment_groups.find(element => {
                return element.id === access.establishment_registry.establishment_id;
              });
              if (foundGroupLevel1) {
                const foundLevel1 = foundGroupLevel1.establishment_registries.find(element => {
                  return element.id === access.establishment_registry_id;
                });
                if (foundLevel1) {
                  foundLevel1['checked'] = true;
                }
              }
              establishment.establishment_groups.forEach(group => {
                if (group.establishment_groups && group.establishment_groups.length) {
                  const foundGroupLevel2 = group.establishment_groups.find(element => {
                    return element.id === access.establishment_registry.establishment_id;
                  });
                  if (foundGroupLevel2) {
                    const foundLevel2 = foundGroupLevel2.establishment_registries.find(element => {
                      return element.id === access.establishment_registry_id;
                    });
                    if (foundLevel2) {
                      foundLevel2['checked'] = true;
                    }
                  }
                }
              });
            }
          });

          const found = this.establishments.find(element => {
            return element.id === access.establishment_registry.establishment_id;
          });
          if (found) {
            const found2 = found.establishment_registries.find(element => {
              return element.id === access.establishment_registry_id;
            });
            if (found2) {
              found2['checked'] = true;
            }
          }
        });
      }

      this.establishments.sort(function(a, b) {
        const typeA = a.is_group;
        const typeB = b.is_group;
        if (typeA > typeB) {
          return -1;
        }
        if (typeA < typeB) {
          return 1;
        }
        return 0;
      });

      this.requestInProgress.next(false);
    });
  }
  hasRegistry(registries, registry) {
    const found = registries.find(element => {
      return element.registry_id === registry.id;
    });
    return !!found;
  }
  getChecked(registries, registry) {
    const found = registries.find(element => {
      return element.registry_id === registry.id;
    });
    if (found) {
      return found.checked;
    }
    return false;
  }
  check(registries, registry) {
    const found = registries.find(element => {
      return element.registry_id === registry.id;
    });
    found.checked = !found.checked;
  }
  groupCheck(registries, registry) {
    const found = registries.find(element => {
      return element.registry_id === registry.id;
    });
    found.checked = !found.checked;
  }
  checkRegistry(registry, checked) {
    this.establishments.forEach(establishment => {
      if (establishment.establishment_groups && establishment.establishment_groups.length) {
        establishment.establishment_groups.forEach(level1 => {
          if (level1.establishment_groups && level1.establishment_groups.length) {
            level1.establishment_groups.forEach(level2 => {
              level2.establishment_registries.forEach(establishment_registry => {
                if (establishment_registry['registry_id'] === registry.id) {
                  establishment_registry['checked'] = checked;
                }
              });
            });
          } else {
            level1.establishment_registries.forEach(establishment_registry => {
              if (establishment_registry['registry_id'] === registry.id) {
                establishment_registry['checked'] = checked;
              }
            });
          }
        });
      } else {
        establishment.establishment_registries.forEach(establishment_registry => {
          if (establishment_registry['registry_id'] === registry.id) {
            establishment_registry['checked'] = checked;
          }
        });
      }
    });
    registry.checked = checked;
  }
  checkGroupRegistry(registry, establishment, checked) {
    if (establishment.establishment_groups && establishment.establishment_groups.length) {
      establishment.establishment_groups.forEach(level1 => {
        if (level1.establishment_groups && level1.establishment_groups.length) {
          level1.establishment_groups.forEach(level2 => {
            level2.establishment_registries.forEach(establishment_registry => {
              if (establishment_registry['registry_id'] === registry.id) {
                establishment_registry['checked'] = checked;
              }
            });
          });
        } else {
          level1.establishment_registries.forEach(establishment_registry => {
            if (establishment_registry['registry_id'] === registry.id) {
              establishment_registry['checked'] = checked;
            }
          });
        }
      });
    }
  }

  checkGroup(establishment: Establishment, registry) {
    let count = 0;
    let length = 0;
    establishment.establishment_groups.forEach(level1 => {
      if (level1.establishment_groups && level1.establishment_groups.length) {
        level1.establishment_groups.forEach(level2 => {
          let found2 = level2.establishment_registries.find(establishment_registry => {
            return establishment_registry.registry_id === registry.id && establishment_registry['checked'];
          });
          if (found2) {
            count++;
          }
          found2 = level2.establishment_registries.find(establishment_registry => {
            return establishment_registry.registry_id === registry.id;
          });
          if (found2) {
            length++;
          }
        });
      } else {
        let found1 = level1.establishment_registries.find(establishment_registry => {
          return establishment_registry.registry_id === registry.id && establishment_registry['checked'];
        });
        if (found1) {
          count++;
        }
        found1 = level1.establishment_registries.find(establishment_registry => {
          return establishment_registry.registry_id === registry.id;
        });
        if (found1) {
          length++;
        }
      }
    });
    return length ? count === length : false;
  }

  checkIfGroupRegistry(establishment, registry) {
    let shouldSkip = false;
    if (establishment.establishment_groups && establishment.establishment_groups.length) {
      establishment.establishment_groups.forEach(establishment_group => {
        if (shouldSkip) {
          return;
        }
        establishment_group.establishment_registries.forEach(establishment_registry => {
          if (shouldSkip) {
            return;
          }
          if (establishment_registry.registry_id === registry.id) {
            shouldSkip = true;
            return;
          }
        });
      });
    }
    return shouldSkip;
  }

  toggleLevel1(key) {
    this.establishments[key].collapsed = !this.establishments[key].collapsed;
    if (this.establishments[key].establishment_groups.length) {
      this.establishments[key].establishment_groups.forEach(establishment => {
        establishment.collapsed = true;
      });
    }
  }
  toggleLevel2(level1, level2) {
    if (this.establishments[level1].establishment_groups.length) {
      this.establishments[level1].establishment_groups[level2].collapsed =
        !this.establishments[level1].establishment_groups[level2].collapsed;
    }
  }

  updateAccess(user_id) {
    this.checkpointsService.updateRegistriesForInternal(user_id, this.establishments).subscribe(response => {
      if (response['success']) {
        this.modalUser.close();
        CustomNotifierService.getSuccessUpdate(this.notifier);
      } else if (response['errors']) {
        CustomNotifierService.getErrors(this.errors, this.notifier);
      }
      this.loader = false;
    });
  }

  chooseFile() {
    const element: HTMLElement = document.getElementById('inputFile') as HTMLElement;
    element.click();
  }
  onFileChange(event) {
    this.picture.setValue('');

    const reader = new FileReader();

    if (event.target && event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.picture.setValue(reader.result);
      };
    } else {
      this.picture.setValue(event.file);
    }
    const element: HTMLInputElement = document.getElementById('inputFile') as HTMLInputElement;
    element.value = '';
  }
  checkPicture() {
    if (this.picture.value.indexOf(';base64,') !== -1) {
      return this.picture.value;
    } else {
      return environment.SERVER_HOST + 'img/users/' + this.picture.value;
    }
  }
  removeFile() {
    this.picture.setValue(null);

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  saveUserToLocalstorage(user) {
    const connectedAs = JSON.parse(localStorage.getItem('connectedAs'));
    if (connectedAs) {
      localStorage.setItem('connectedAsUser', JSON.stringify(user));
    } else {
      localStorage.setItem('user', JSON.stringify(user));
    }
  }

  bindModal(modal) {
    this.modalUser = modal;
  }

  changeTabs(tabValue: string) {
    this.activeTab = tabValue;
  }
  prevTabs() {
    this.activeTab = 'user';
  }
  nextTabs() {
    this.activeTab = 'access';
  }

  closeModal() {
   if (this.activatedRoute.snapshot.parent.params.registry_id && this.activatedRoute.snapshot.parent.params.establishment_id &&
     this.activatedRoute.snapshot.parent.params.enterprise_id) {
      this.allRegistryProviderUsers.refreshData();
      this.router.navigate(['dashboard-registry/' +
      this.activatedRoute.snapshot.parent.params.registry_id + '/establishment/' +
      this.activatedRoute.snapshot.parent.params.establishment_id + '/providers/' +
      this.activatedRoute.snapshot.parent.params.enterprise_id + '/users']).then(() => {});
    } else {
      this.allUsers.refreshData();
      this.router.navigate([this.previousRoute]).then(() => {});
    }
  }
}
