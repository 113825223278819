<app-modal-right (eventOnClose)="closeModal()" #installation>{{ binModal(installation) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">{{ isNew ? 'Création' : canUpdate ? 'Mise à jour' : 'Consultation' }}</h6>

        <h1 class="header-title">Installation</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <form [formGroup]="installationForm" *ngIf="!loaderContent">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="validationInstallationDescription">Description</label>
            <textarea type="text" id="validationInstallationDescription" formControlName="description" class="form-control" placeholder="Description"></textarea>
            <div *ngIf="description.invalid && (description.dirty || description.touched)" class="invalid-feedback">
              {{ getErrorMessage("description") }}
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div modal-footer class="w-100" *ngIf="isNew || canUpdate">
    <button class="btn btn-primary w-100" (click)="isNew ? createInstallation() : updateInstallation()"
            [ngClass]="{ 'is-loading is-loading-sm': loader }" [disabled]="loader || installationForm.invalid">
      {{ isNew ? 'Créer' : 'Mettre à jour' }}
    </button>
  </div>
</app-modal-right>
