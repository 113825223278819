<div class="d-flex align-items-center bg-auth border-top border-top-2 border-primary">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-md-5 col-xl-4 my-5">
        <div class="text-center mb-5">
          <img src="../../../../assets/img/logo_batiregistre.png" alt="Logo Batiregistre" />
        </div>

        <h1 class="display-4 text-center mb-3">
          Fin de votre offre découverte
        </h1>

        <p class="text-muted text-center mb-5">
          Merci de contacter un administrateur afin de pouvoir accéder à de nouvelles offres
        </p>

        <button class="btn btn-lg btn-block btn-primary mb-3" [routerLink]="['/auth/login']">
          Se connecter
        </button>

      </div>
    </div>
  </div>
</div>
