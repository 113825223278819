import { Component, Output, ElementRef, EventEmitter, AfterViewInit, Input } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-video-modal',
  templateUrl: './video-modal.component.html',
  styleUrls: ['./video-modal.component.scss']
})
export class VideoModalComponent implements AfterViewInit {
  @Input() type;
  @Output() eventOnClose: EventEmitter<any> = new EventEmitter();

  modalElVideo = null;
  url: any;

  constructor(
    private _rootNode: ElementRef
  ) {}

  open(url) {
    this.url = url;
    this.modalElVideo.modal('show');
  }

  close() {
    this.url = '';
    this.eventOnClose.next(null);
    this.modalElVideo.modal('hide');
  }

  closeInternal() {
    this.url = '';
    this.eventOnClose.next(null);
    this.modalElVideo.modal('hide');
  }

  ngAfterViewInit() {
    this.modalElVideo = $(this._rootNode.nativeElement).find('div.modal-video');

    const self = this;
    $('#video-modal').on('hidden.bs.modal', function () {
      self.url = '';
      self.eventOnClose.next(null);
      self.modalElVideo.modal('hide');
    });
  }
}
