import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { AuthService } from '../../../core/auth/auth.service';
import { Subject, Subscription } from 'rxjs';

import { UsersRequestService } from '../../../services/users.request.service';
import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { CheckpointsRequestService } from '../../../services/checkpoints.request.service';

import { environment } from '../../../../environments/environment';

import Establishment from '../../../../shared/interfaces/establishment';
import User from '../../../../shared/interfaces/user';
import Registry from '../../../../shared/interfaces/registry';

declare var $: any;

@Component({
  selector: 'app-access-internal-registries',
  templateUrl: './access-internal-registries.component.html',
  styleUrls: ['./access-internal-registries.component.scss'],
})
export class AccessInternalRegistriesComponent implements OnInit, OnDestroy {
  changeEnterprise: Subscription;
  environment = environment;

  canUpdate = false;
  canCreate = false;

  requestInProgress = new Subject();
  requestCount = 0;

  loader = false;
  errors = {};

  pageName: string;
  previousPageName: string[] = [];
  previousPageRoute: string[] = [];

  user: User;
  user_id: number;
  establishments: Establishment[] = [];
  registries: Registry[] = [];
  access: any[] = [];

  timeout: any;
  view = "";

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private notifier: NotifierService,
    private checkpointsService: CheckpointsRequestService,
    private usersService: UsersRequestService
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loader = this.requestCount > 0;
    });

    this.changeEnterprise = this.authService.getEnterprise().subscribe(() => {
      this.user_id = parseInt(this.activatedRoute.snapshot.params.user_id, 10);
      this.getRegistries();
      this.getUser();
      this.createBreadcrumbs();
    });

    if (this.authService.loaded) {
      this.user_id = parseInt(this.activatedRoute.snapshot.params.user_id, 10);
      this.getRegistries();
      this.getUser();
      this.createBreadcrumbs();
    }
  }

  ngOnDestroy() {
    this.changeEnterprise.unsubscribe();

    const tooltips = document.getElementsByClassName(
      "tooltip"
    ) as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  getRegistries() {
    this.requestInProgress.next(true);

    this.checkpointsService.getRegistriesForInternal(this.user_id).subscribe(response => {
      if (response['success']) {
        this.registries = response['registries'];
        this.registries.forEach(registry => {
          registry['count'] = 0;
        });
        this.establishments = [];
        response['establishments'].forEach(establishment => {
          if (!establishment.parent_id) {
            this.establishments.push(establishment);
          }
        });
        response['establishments'].forEach(establishment => {
          if (establishment.parent_id) {
            const found = this.establishments.findIndex(element => {
              return element.id === establishment.parent_id;
            });
            if (found !== -1) {
              this.establishments.splice(found + 1, 0, establishment);
            } else {
              this.establishments.forEach(level1 => {
                const foundGroupLevel1 = level1.establishment_groups.findIndex(element => {
                  return element.id === establishment.parent_id;
                });
                if (foundGroupLevel1 !== -1) {
                  level1.establishment_groups.splice(foundGroupLevel1 + 1, 0, establishment);
                } else {
                  if (level1.establishment_groups && level1.establishment_groups.length) {
                    level1.establishment_groups.forEach(level2 => {
                      if (level2.establishment_groups.length) {
                        const foundGroupLevel2 = level2.establishment_groups.findIndex(element => {
                          return element.id === establishment.parent_id;
                        });
                        if (foundGroupLevel2 !== -1) {
                          level2.establishment_groups.splice(foundGroupLevel2 + 1, 0, establishment);
                        } else {
                          this.establishments.push(establishment);
                        }
                      }
                    });
                  }
                }
              });
            }
          }
        });
        this.establishments.forEach(establishment => {
          establishment.collapsed = true;
          if (establishment.establishment_groups.length) {
            establishment.establishment_groups.forEach(level1 => {
              level1.collapsed = true;
              if (level1.establishment_groups && level1.establishment_groups.length) {
                level1.establishment_groups.forEach(level2 => {
                  level2.collapsed = true;
                  level2.establishment_registries.forEach(establishment_registry => {
                    const found = this.registries.find(element => {
                      return element.id === establishment_registry.registry_id;
                    });
                    found['count'] += 1;
                  });
                });
              } else {
                level1.establishment_registries.forEach(establishment_registry => {
                  const found = this.registries.find(element => {
                    return element.id === establishment_registry.registry_id;
                  });
                  found['count'] += 1;
                });
              }
            });
          } else {
            establishment.establishment_registries.forEach(establishment_registry => {
              const found = this.registries.find(element => {
                return element.id === establishment_registry.registry_id;
              });
              found['count'] += 1;
            });
          }
        });
        const arrayToSplice = [];
        this.registries.forEach((registry, index) => {
          if (registry['count'] === 0) {
            arrayToSplice.unshift(index);
          }
        });
        arrayToSplice.forEach(index => {
          this.registries.splice(index, 1);
        });
        this.access = response['access'];
        this.access.forEach(access => {
          this.establishments.forEach(establishment => {
            if (establishment.establishment_groups && establishment.establishment_groups.length) {
              const foundGroupLevel1 = establishment.establishment_groups.find(element => {
                  return element.id === access.establishment_registry.establishment_id;
              });
              if (foundGroupLevel1) {
                const foundLevel1 = foundGroupLevel1.establishment_registries.find(element => {
                  return element.id === access.establishment_registry_id;
                });
                if (foundLevel1) {
                  foundLevel1['checked'] = true;
                }
              }
              establishment.establishment_groups.forEach(group => {
                if (group.establishment_groups && group.establishment_groups.length) {
                  const foundGroupLevel2 = group.establishment_groups.find(element => {
                    return element.id === access.establishment_registry.establishment_id;
                  });
                  if (foundGroupLevel2) {
                    const foundLevel2 = foundGroupLevel2.establishment_registries.find(element => {
                      return element.id === access.establishment_registry_id;
                    });
                    if (foundLevel2) {
                      foundLevel2['checked'] = true;
                    }
                  }
                }
              });
            }
          });

          const found = this.establishments.find(element => {
            return element.id === access.establishment_registry.establishment_id;
          });
          if (found) {
            const found2 = found.establishment_registries.find(element => {
              return element.id === access.establishment_registry_id;
            });
            if (found2) {
              found2['checked'] = true;
            }
          }
        });
      }

      this.establishments.sort(function(a, b) {
        const typeA = a.is_group;
        const typeB = b.is_group;
        if (typeA > typeB) {
          return -1;
        }
        if (typeA < typeB) {
          return 1;
        }
        return 0;
      });

      this.requestInProgress.next(false);
    });
  }
  getUser() {
    this.requestInProgress.next(true);

    this.usersService.getMyUser(this.user_id).subscribe((response) => {
      if (response["success"]) {
        this.user = response["user"];
      }
      this.requestInProgress.next(false);
    });
  }
  hasRegistry(registries, registry) {
    const found = registries.find((element) => {
      return element.registry_id === registry.id;
    });
    return !!found;
  }
  getChecked(registries, registry) {
    const found = registries.find((element) => {
      return element.registry_id === registry.id;
    });
    if (found) {
      return found.checked;
    }
    return false;
  }
  check(registries, registry) {
    const found = registries.find((element) => {
      return element.registry_id === registry.id;
    });
    found.checked = !found.checked;
  }
  groupCheck(registries, registry) {
    const found = registries.find(element => {
      return element.registry_id === registry.id;
    });
    found.checked = !found.checked;
  }
  checkRegistry(registry, checked) {
    this.establishments.forEach(establishment => {
      if (establishment.establishment_groups && establishment.establishment_groups.length) {
        establishment.establishment_groups.forEach(level1 => {
          if (level1.establishment_groups && level1.establishment_groups.length) {
            level1.establishment_groups.forEach(level2 => {
              level2.establishment_registries.forEach(establishment_registry => {
                if (establishment_registry['registry_id'] === registry.id) {
                  establishment_registry['checked'] = checked;
                }
              });
            });
          } else {
            level1.establishment_registries.forEach(establishment_registry => {
              if (establishment_registry['registry_id'] === registry.id) {
                establishment_registry['checked'] = checked;
              }
            });
          }
        });
      } else {
        establishment.establishment_registries.forEach(establishment_registry => {
          if (establishment_registry['registry_id'] === registry.id) {
            establishment_registry['checked'] = checked;
          }
        });
      }
    });
    registry.checked = checked;
  }
  checkGroupRegistry(registry, establishment, checked) {
    if (establishment.establishment_groups && establishment.establishment_groups.length) {
      establishment.establishment_groups.forEach(level1 => {
        if (level1.establishment_groups && level1.establishment_groups.length) {
          level1.establishment_groups.forEach(level2 => {
            level2.establishment_registries.forEach(establishment_registry => {
              if (establishment_registry['registry_id'] === registry.id) {
                establishment_registry['checked'] = checked;
              }
            });
          });
        } else {
          level1.establishment_registries.forEach(establishment_registry => {
            if (establishment_registry['registry_id'] === registry.id) {
              establishment_registry['checked'] = checked;
            }
          });
        }
      });
    }
  }

  checkIfGroupRegistry(establishment, registry) {
    let shouldSkip = false;
    if (establishment.establishment_groups && establishment.establishment_groups.length) {
      establishment.establishment_groups.forEach(establishment_group => {
        if (shouldSkip) {
          return;
        }
        establishment_group.establishment_registries.forEach(establishment_registry => {
          if (shouldSkip) {
            return;
          }
          if (establishment_registry.registry_id === registry.id) {
            shouldSkip = true;
            return;
          }
        });
      });
    }
    return shouldSkip;
  }

  toggleLevel1(key) {
    this.establishments[key].collapsed = !this.establishments[key].collapsed;
    if (this.establishments[key].establishment_groups.length) {
      this.establishments[key].establishment_groups.forEach(establishment => {
        establishment.collapsed = true;
      });
    }
  }
  toggleLevel2(level1, level2) {
    if (this.establishments[level1].establishment_groups.length) {
      this.establishments[level1].establishment_groups[level2].collapsed =
        !this.establishments[level1].establishment_groups[level2].collapsed;
    }
  }

  updateAccess() {
    this.requestInProgress.next(true);
    this.checkpointsService
      .updateRegistriesForInternal(this.user_id, this.establishments)
      .subscribe((response) => {
        if (response["success"]) {
          CustomNotifierService.getSuccessUpdate(this.notifier);
          this.router.navigate(["users/mine"]).then(() => {});
        } else if (response["errors"]) {
          CustomNotifierService.getErrors(this.errors, this.notifier);
        }
        this.requestInProgress.next(false);
      });
  }

  createBreadcrumbs() {
    this.pageName = "Établissements / Registres";

    this.previousPageName = [];
    this.previousPageRoute = [];

    this.previousPageName.push("Tableau de bord");
    this.previousPageRoute.push("/");

    this.previousPageName.push("Utilisateurs");
    this.previousPageRoute.push("/users/mine");
  }

  collapseChildren(establishment_groups: Establishment[]) {
    establishment_groups.forEach( (group, index) => {
      $('#secondLevel' + index).collapse('hide');
      const id = $('#collapsed' + index);
      id.attr('aria-expended', 'false');
      id.addClass('collapsed');
    });
  }

  checkGroup(establishment: Establishment, registry) {
    let count = 0;
    let length = 0;
    establishment.establishment_groups.forEach(level1 => {
      if (level1.establishment_groups && level1.establishment_groups.length) {
        level1.establishment_groups.forEach(level2 => {
          let found2 = level2.establishment_registries.find(establishment_registry => {
            return establishment_registry.registry_id === registry.id && establishment_registry['checked'];
          });
          if (found2) {
            count++;
          }
          found2 = level2.establishment_registries.find(establishment_registry => {
            return establishment_registry.registry_id === registry.id;
          });
          if (found2) {
            length++;
          }
        });
      } else {
        let found1 = level1.establishment_registries.find(establishment_registry => {
          return establishment_registry.registry_id === registry.id && establishment_registry['checked'];
        });
        if (found1) {
          count++;
        }
        found1 = level1.establishment_registries.find(establishment_registry => {
          return establishment_registry.registry_id === registry.id;
        });
        if (found1) {
          length++;
        }
      }
    });
    return length ? count === length : false;
  }
}
