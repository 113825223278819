import { Component, OnDestroy, OnInit} from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { AuthService } from '../../../core/auth/auth.service';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { RegistriesRequestService } from '../../../services/registries.request.service';

import Registry from '../../../../shared/interfaces/registry';

import { NotificationsRequestService } from '../../../services/notifications.request.service';

@Component({
  selector: 'app-all-registries',
  templateUrl: './all-registries.component.html',
  styleUrls: ['./all-registries.component.scss']
})
export class AllRegistriesComponent implements OnInit, OnDestroy {
  changeEnterprise: Subscription;
  canCreate = false;
  canUpdate = false;
  canReceiveAlert = false;
  canReceiveAlertUser: any;
  canReceiveAlertEnterprise: any;

  establishment: any;
  registries: Registry[] = [];

  pageName: string;
  previousPageName: string[] = [];
  previousPageRoute: string[] = [];

  count = 0;
  pages = 1;

  search = '';
  sort = 'Registries.name';
  order = 'asc';
  limit = 12;
  page = 1;

  timeout: any;
  view = '';
  loader = false;

  connectedAs = false;
  discoverOffer = false;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private registriesService: RegistriesRequestService,
    private notificationService: NotificationsRequestService,
  ) {
    this.changeEnterprise = this.authService.getEnterprise().subscribe(() => {
      this.getRegistries();
    });
  }

  ngOnInit() {
    this.pageName = 'Registres';

    if (this.activatedRoute.snapshot.params.enterprise_id) {
      this.view = 'enterprise';
      this.previousPageName.push('Tableau de bord');
      this.previousPageRoute.push('/administration');

      this.previousPageName.push('Entreprises');
      this.previousPageRoute.push('/enterprises');

      this.previousPageName.push('Établissements');
      this.previousPageRoute.push('/enterprises/' + this.activatedRoute.snapshot.params.enterprise_id + '/establishments');
    } else if (this.activatedRoute.snapshot.params.establishment_id) {
      this.view = 'establishment';
      this.previousPageName.push('Tableau de bord');
      this.previousPageRoute.push('/');

      this.previousPageName.push('Mes établissements');
      this.previousPageRoute.push('/establishments/mine/active');
    } else if (this.activatedRoute.snapshot.url[1]) {
      this.view = 'mine';
      this.previousPageName.push('Tableau de bord');
      this.previousPageRoute.push('/');
    } else {
      this.view = 'admin';
      this.previousPageName.push('Tableau de bord');
      this.previousPageRoute.push('/administration');
    }

    if (this.authService.loaded) {
      this.getRegistries();
    }

    this.connectedAs = JSON.parse(localStorage.getItem('connectedAs'));
    if (this.connectedAs) {
      this.discoverOffer = JSON.parse(localStorage.getItem('connectedAsIsDiscoverOffer'));
    } else {
      this.discoverOffer = JSON.parse(localStorage.getItem('isDiscoverOffer'));
    }
  }

  ngOnDestroy() {
    this.changeEnterprise.unsubscribe();

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  searchData() {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.page = 1;
      this.refreshData();
    }, 500);
  }

  refreshData(sort = this.sort, order = this.order, page = this.page) {
    this.sort = sort;
    this.order = order;
    this.page = page;
    this.getRegistries();
  }

  getRegistries() {
    this.loader = true;
    let request;

    if (this.view === 'establishment') {
      request = this.registriesService.getRegistriesEstablishment(
        this.search, this.sort, this.order, this.limit, this.page, this.activatedRoute.snapshot.params.establishment_id
      );
    } else if (this.view === 'mine') {
      request = this.registriesService.getMyRegistries(this.search, this.sort, this.order, this.limit, this.page);
    } else {
      request = this.registriesService.getRegistries(this.search, this.sort, this.order, this.limit, this.page);
    }

    request.subscribe(response => {
      if (response['success']) {
        this.registries = response['registries'];

        if (response['periodicAlert']) {
          this.canReceiveAlert = response['periodicAlert']['canReceiveAlert'];
          this.canReceiveAlertUser = response['periodicAlert']['userId'];
          this.canReceiveAlertEnterprise = response['periodicAlert']['enterpriseId'];
        }

        this.count = response['count'];
        this.pages = response['pages'];

        if (this.activatedRoute.snapshot.params.establishment_id) {
          this.establishment = response['establishment'];
        }
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loader = false;
    });
  }

  dispatchPagination(data) {
    if (data.action === 'total_elements') {
      this.refreshPage(data.value);
    } else if (data.action === 'change_page') {
      this.changePage(data.value);
    }
  }

  refreshPage(limit) {
    this.limit = limit;
    this.page = 1;
    this.refreshData();
  }

  changePage(page) {
    if (page >= 1 && page <= this.pages) {
      this.page = page;
      this.getRegistries();
    }
  }

  sendPeriodicAlertEmail() {
    this.loader = true;
    this.notificationService.sendPeriodicAlerts(this.canReceiveAlertUser, this.canReceiveAlertEnterprise).subscribe(response => {
      if (response['success']) {
        CustomNotifierService.getSuccessEmail(this.notifier);
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loader = false;
    });
  }
}
