<div class="main-content">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col">
        <div class="header mb-0">
          <div class="header-body no-border">
            <div class="row align-items-center">
              <div class="col">
                <h1 class="header-title">Unités de travail</h1>

                <small class="form-text text-muted">Liste des unités de travail</small>

                <app-breadcrumbs [currentPage]="pageName" [names]="previousPageName" [routes]="previousPageRoute"></app-breadcrumbs>
              </div>

              <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0" *ngIf="canCreate">
                <a class="btn btn-primary d-block d-md-inline-block" [routerLink]="[currentRoute + 'create']">
                  Ajouter une unité de travail
                </a>
              </div>

              <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0" *ngIf="view == 'establishment'">
                <a class="btn btn-primary d-block d-md-inline-block text-white" (click)="showHiddenUnities()">
                  {{ show_inactive ? 'Masquer les unités de travail masqués' : 'Afficher les unités de travail masqués' }}
                </a>
              </div>

              <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0" *ngIf="canCreate && view === 'establishment'">
                <a class="btn btn-primary d-block d-md-inline-block" [routerLink]="[currentRoute + 'show']">
                  Edition de masse
                </a>
              </div>
              <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0" *ngIf="canCreate && view !== 'establishment'">
                <a class="btn btn-primary d-block d-md-inline-block" [routerLink]="[currentRoute + 'global-show']">
                  Edition de masse
                </a>
              </div>

            </div>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col">
            <app-searchbar [(ngModel)]="search" (keyup)="searchData()" ngDefaultControl></app-searchbar>
          </div>
        </div>

        <div class="is-loading loader-content" *ngIf="loader"></div>

        <div class="tab-content" *ngIf="!loader && unities.length">
          <div class="tab-pane fade active show" id="tabPaneOne" role="tabpanel">
            <div class="row listAlias">
              <div class="col-12" *ngIf="show_inactive && view === 'establishment'">
                <div class="card bg-light border">
                  <div class="card-body">
                    <span>Attention, à votre demande, les unités de travail masquées sont temporairement visibles en gris.</span>
                  </div>
                </div>
              </div>

              <div class="col-12 d-flex flex-column">
                <div class="card flex-fill">
                  <div class="card-body">
                    <div class="table-responsive">
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th class="href" (click)="filter('RiskUnityWorks.name')">
                              <a class="text-muted">Nom</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'RiskUnityWorks.name' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'RiskUnityWorks.name' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'RiskUnityWorks.name'"></span>
                            </th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="dropdown" *ngFor="let unity of unities">
                            <td>
                              <a class="href" *ngIf="view === 'establishment'" [routerLink]="[currentRoute + unity.id]" [ngClass]="{ 'is-inactive': inactive(unity) }">
                                {{ unity['name'] }}
                              </a>
                              <a class="href" *ngIf="view !== 'establishment'" [routerLink]="[currentRoute + unity.id]">
                                {{ unity['name'] }}
                              </a>
                            </td>
                            <td class="d-flex flex-row-reverse">
                              <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fe fe-more-vertical"></i>
                              </a>

                              <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item href" [routerLink]="[currentRoute + unity.id]">
                                  {{ canUpdate ? 'Éditer l\'unité de travail' : 'Consulter l\'unité de travail' }}
                                </a>

                                <a class="dropdown-item href" (click)="modalConfirmData.open(unity.id)" *ngIf="canDelete">
                                  Supprimer l'unité de travail
                                </a>

                                <a class="dropdown-item href" [routerLink]="[currentRoute + unity.id + '/logs']" *ngIf="canLog">
                                  Historique des actions
                                </a>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <app-pagination (returnEvent)="dispatchPagination($event)"
                                    [count_elements]="count"
                                    [current_page]="page"
                                    [elements_length]="unities.length"
                                    [limit]="limit"
                                    [total_pages]="pages"
                                    ngDefaultControl>
                    </app-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="loader-content" *ngIf="!loader && !unities.length">
          <p>Aucun résultat</p>
        </div>
      </div>
    </div>
  </div>
</div>

<app-confirm-modal [type]="'delete_data'" #confirmData>{{ bindModalConfirmData(confirmData) }}</app-confirm-modal>

<router-outlet></router-outlet>
