import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { AllActionsComponent } from './all-actions.component';

import { BreadcrumbsModule } from '../../../../core/breadcrumbs/breadcrumbs.module';
import { ConfirmModalModule } from '../../../../core/confirm-modal/confirm-modal.module';
import { SearchbarModule } from '../../../../core/searchbar/searchbar.module';
import { PaginationModule } from '../../../../core/pagination/pagination.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    BreadcrumbsModule,
    FormsModule,
    ConfirmModalModule,
    FormsModule,
    SearchbarModule,
    PaginationModule
  ],
  declarations: [AllActionsComponent]
})

export class AllActionsModule {}
