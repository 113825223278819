import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardSurveillanceModule } from './dashboard-surveillance/dashboard-surveillance.module';

import { SanitaryLegionellasModule } from './sanitary-legionellas/sanitary-legionellas.module';
import { SanitaryTemperaturesModule } from './sanitary-temperatures/sanitary-temperatures.module';
import { SanitaryAnalyzesModule } from './sanitary-analyzes/sanitary-analyzes.module';

import { SanitaryLegionellaFramesModule } from './sanitary-legionella-frames/sanitary-legionella-frames.module';
import { SanitaryTemperatureFramesModule } from './sanitary-temperature-frames/sanitary-temperature-frames.module';
import { SanitaryAnalyzeFramesModule } from './sanitary-analyze-frames/sanitary-analyze-frames.module';

import { SanitaryLegionellasRequestService } from '../../services/sanitary-legionellas.request.service';
import { SanitaryTemperaturesRequestService } from '../../services/sanitary-temperatures.request.service';
import { SanitaryAnalyzesRequestService } from '../../services/sanitary-analyzes.request.service';

import { SanitaryLegionellaFramesRequestService } from '../../services/sanitary-legionella-frames.request.service';
import { SanitaryTemperatureFramesRequestService } from '../../services/sanitary-temperature-frames.request.service';
import { SanitaryAnalyzeFramesRequestService } from '../../services/sanitary-analyze-frames.request.service';

@NgModule({
  imports: [
    CommonModule,
    DashboardSurveillanceModule,
    SanitaryLegionellasModule,
    SanitaryTemperaturesModule,
    SanitaryAnalyzesModule,
    SanitaryLegionellaFramesModule,
    SanitaryTemperatureFramesModule,
    SanitaryAnalyzeFramesModule,
  ],
  providers: [
      SanitaryLegionellasRequestService,
      SanitaryTemperaturesRequestService,
      SanitaryAnalyzesRequestService,
      SanitaryLegionellaFramesRequestService,
      SanitaryTemperatureFramesRequestService,
      SanitaryAnalyzeFramesRequestService,
  ]
})
export class SanitarySurveillancesModule {}
