import { Routes } from "@angular/router";
import { AffectationEmployeesTrainingComponent } from "./affectation-employees-training/affectation-employees-training.component";
import { CreateUpdateTrainingComponent } from "./create-update-training/create-update-training.component";
import { EmployeesMustTrainComponent } from "./employees-must-train/employees-must-train.component";
import { EmployeesTrainingComponent } from "./employees-training/employees-training.component";
import { ExportDownloadComponent } from "./export-download/export-download.component";
import { GetAllTrainingsComponent } from "./get-all-training/get-all-training.component";
import { TrainingSessionsComponent } from "./training-sessions/training-sessions.component";
import { UpdateTrainingSessionComponent } from "./update-training-session/update-training-session.component";

export const allTrainingRoutes: Routes = [
  {
    path: "formations",
    component: GetAllTrainingsComponent,
    children: [
      {
        path: "create",
        component: CreateUpdateTrainingComponent,
      },
      {
        path: "download",
        component: ExportDownloadComponent,
      },
      {
        path: "import_massif",
        component: AffectationEmployeesTrainingComponent,
      },
      {
        path: ":training_id",
        component: CreateUpdateTrainingComponent,
      },
    ],
  },

  {
    path: "formations/:training_id/sessions",
    component: TrainingSessionsComponent,
    children: [
      {
        path: "create",
        component: UpdateTrainingSessionComponent,
      },
      {
        path: ":date_training_id",
        component: UpdateTrainingSessionComponent,
      },
    ],
  },
  {
    path: "formations/:training_id/employees_train",
    component: EmployeesTrainingComponent,
  },
  {
    path: "formations/:training_id/employees_must_train",
    component: EmployeesMustTrainComponent,
  },
];
