import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Subject } from 'rxjs';
import Issue from '../../../../shared/interfaces/issue';
import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { IssuesRequestService } from '../../../services/issues.request.service';
import IssueCommentFile from '../../../../shared/interfaces/issue_comment_file';
import IssueState from '../../../../shared/interfaces/issue_state';
import IssueCategory from '../../../../shared/interfaces/issue_category';
// import { SocketService } from '../socket.service';

@Component({
  selector: 'app-update-issue',
  templateUrl: './update-issue.component.html',
  styleUrls: ['./update-issue.component.scss']
})
export class UpdateIssueComponent implements OnInit {
  requestInProgress = new Subject();
  requestCount = 0;
  view = '';
  loader = false;

  pageName = 'Ticket';
  previousPageName: string[] = [];
  previousPageRoute: string[] = [];

  currentRoute = '';

  issue: Issue;
  states: IssueState[];
  state: number;
  categories: IssueCategory[];
  category: number;

  constructor(
    private router: Router,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private issuesService: IssuesRequestService,
    // private socketService: SocketService
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loader = this.requestCount > 0;
    });

    // this.socketService.getMessages('issue' + this.activatedRoute.snapshot.params.issueId).subscribe(() => {
    //   this.getIssue();
    // });

    this.currentRoute = '/' + this.activatedRoute.snapshot.url.join('/') + '/';

    if (this.activatedRoute.snapshot.url.length > 2) {
      this.view = 'client';
      this.getCategories();
    } else {
      this.view = 'admin';
      this.getStates();
    }

    this.createBreadcrumbs();
    this.getIssue();
  }

  getIssue() {
    let request;
    this.requestInProgress.next(true);

    if (this.view === 'admin') {
      request = this.issuesService.getIssue(this.activatedRoute.snapshot.params.issueId);
    } else {
      request = this.issuesService.getMyIssue(this.activatedRoute.snapshot.params.issueId);
    }

    request.subscribe(response => {
      if (response['success']) {
        this.issue = response['issue'];
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  getRef = (param: number) => {
    const size = 10 - param.toString(10).length;
    let result = '';
    for (let i = 0; i < size; i++) {
      result = result + '0';
    }
    result += param.toString(10);
    return result;
  }

  getCategories() {
    let request;
    this.requestInProgress.next(true);

    request = this.issuesService.getCategories();

    request.subscribe(response => {
      if (response['success']) {
        this.categories = response['categories'];
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  setCategory() {
    this.loader = true;

    const issue = {
      ...this.issue,
      issue_category_id: this.category
    };
    let request;
    request = this.issuesService.updateMyIssue(this.activatedRoute.snapshot.params.issueId, issue);
    request.subscribe(response => {
      if (response['success']) {
        this.issue = response['issue'];
        CustomNotifierService.getSuccessUpdate(this.notifier);
      } else if (response['errors']) {
        CustomNotifierService.getErrorRequest(this.notifier);
      }
      this.loader = false;
    });
  }

  getStates() {
    let request;
    this.requestInProgress.next(true);

    request = this.issuesService.getStates();

    request.subscribe(response => {
      if (response['success']) {
        this.states = response['states'];
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  setState() {
    this.loader = true;

    const issue = {
      ...this.issue,
      issue_state_id: this.state
    };
    let request;
    request = this.issuesService.updateIssue(this.activatedRoute.snapshot.params.issueId, issue);
    request.subscribe(response => {
      if (response['success']) {
        this.issue = response['issue'];
        CustomNotifierService.getSuccessUpdate(this.notifier);
      } else if (response['errors']) {
        CustomNotifierService.getErrorRequest(this.notifier);
      }
      this.loader = false;
    });
  }

  reopen() {
    this.loader = true;

    const issue = {
      ...this.issue,
      issue_state_id: 4
    };
    let request;
    request = this.issuesService.updateMyIssue(this.activatedRoute.snapshot.params.issueId, issue);
    request.subscribe(response => {
      if (response['success']) {
        this.issue = response['issue'];
        CustomNotifierService.getSuccessUpdate(this.notifier);
      } else if (response['errors']) {
        CustomNotifierService.getErrorRequest(this.notifier);
      }
      this.loader = false;
    });
  }

  downloadFile(file: IssueCommentFile) {
    let request;

    if (this.view === 'admin') {
      request = this.issuesService.downloadIssueCommentFile(
        this.activatedRoute.snapshot.params.issueId,
        file.issue_comment_id,
        file.id
      );
    } else {
      request = this.issuesService.downloadMyIssueCommentFile(
        this.activatedRoute.snapshot.params.issueId,
        file.issue_comment_id,
        file.id
      );
    }

    request.subscribe((response: BufferSource) => {
      try {
        const decoder = new TextDecoder('utf-8');
        const domString = decoder.decode(response);
        const json = JSON.parse(domString);

        CustomNotifierService.getErrors(json['errors'], this.notifier);
      } catch (e) {
        const blob = new Blob([response]);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = file.name + '.' + file.extension;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      }
    });
  }

  createBreadcrumbs() {
    this.previousPageName = [];
    this.previousPageRoute = [];

    this.previousPageName.push('Tableau de bord');
    this.previousPageRoute.push('/');

    if (this.view === 'admin') {
      this.previousPageName.push('Gestion des tickets');
      this.previousPageRoute.push('/issues');
    } else {
      this.previousPageName.push('Liste des tickets');
      this.previousPageRoute.push('/issues/mines');
    }
    if (this.issue) {
      this.pageName = 'Ticket n°' + this.issue.id;
    }
  }

}
