import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler, HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import {Compiler, Injectable} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable, Subject, onErrorResumeNext, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { NotifierService } from 'angular-notifier';
import { CustomNotifierService } from './custom.notifier.service';
import { AuthService } from '../core/auth/auth.service';
import { LoginComponent } from '../pages/auth/login/login.component';

@Injectable()
export class TokenService implements HttpInterceptor {
  refreshInProgress: Subject<any>;
  requestInProgressSubject = new Subject;

  requestInProgress = 0;

  needToGoBack = false;
  disconnected = false;
  notConnected = false;
  noEnterprises = false;
  noAttribution = false;
  serverError = false;
  endDiscover = false;

  constructor(
    private router: Router,
    private notifier: NotifierService,
    private authService: AuthService,
    private compiler: Compiler
  ) {
    this.requestInProgressSubject.subscribe((value: number) => {
      this.requestInProgress = value;
      if (value === 0) {
        if (this.needToGoBack) {
          this.needToGoBack = false;
          // history.back();
          if (document.location.hostname !== 'localhost') {
            const url = this.authService.checkUrlStats(JSON.parse(localStorage.getItem('registries')));
            this.router.navigate([url]).then(() => {});
          }
          CustomNotifierService.getErrorNotAuthorized(this.notifier);
        } else if (this.disconnected) {
          this.disconnected = false;
          const connectedAs = JSON.parse(localStorage.getItem('connectedAs'));
          if (connectedAs) {
            authService.disconnectAs();
          } else {
            localStorage.clear();
            this.router.navigate(['/auth/login']).then(() => {});
          }
          CustomNotifierService.getErrorDisconnected(this.notifier);
        } else if (this.notConnected) {
          this.notConnected = false;
          const connectedAs = JSON.parse(localStorage.getItem('connectedAs'));
          if (connectedAs) {
            authService.disconnectAs();
            CustomNotifierService.getErrorDisconnected(this.notifier);
          } else {
            localStorage.clear();
            this.router.navigate(['/auth/login']).then(() => {});
            CustomNotifierService.getErrorNotConnected(this.notifier);
          }
        } else if (this.noEnterprises) {
          this.noEnterprises = false;
          CustomNotifierService.getErrorNoEnterprises(this.notifier);
        } else if (this.noAttribution) {
          this.noAttribution = false;
          // history.back();
          if (document.location.hostname !== 'localhost') {
            const connectedAs = JSON.parse(localStorage.getItem('connectedAs'));
            let url = '';
            if (connectedAs) {
              url = this.authService.checkUrlStats(JSON.parse(localStorage.getItem('connectedAsRegistries')));
            } else {
              url = this.authService.checkUrlStats(JSON.parse(localStorage.getItem('registries')));
            }
            this.router.navigate([url]).then(() => {});
          }
          CustomNotifierService.getErrorNoAttribution(this.notifier);
        } else if (this.serverError) {
          this.serverError = false;
          CustomNotifierService.getErrorRequest(this.notifier);
        } else if (this.endDiscover) {
          this.endDiscover = false;
          localStorage.clear();
          this.router.navigate(['/auth/discover']).then(() => {});
          CustomNotifierService.getErrorEndDiscover(this.notifier);
        }
      }
    });
  }

  static addBearer(request: HttpRequest<any>, token: string = null) {
    let enterprise = JSON.parse(localStorage.getItem('enterprise'));
    const notificationToken = localStorage.getItem('notificationToken');
    if (!token) {
      const connectedAs = JSON.parse(localStorage.getItem('connectedAs'));
      if (connectedAs) {
        enterprise = JSON.parse(localStorage.getItem('connectedAsEnterprise'));
        token = localStorage.getItem('connectedAsAccessToken');
      } else {
        token = localStorage.getItem('accessToken');
      }
    }
    if (enterprise) {
      let headers = new HttpHeaders();
      headers = headers.append('Authorization', `Bearer ${token}`);
      headers = headers.append('Enterprise', `${enterprise.id}`);
      headers = headers.append('SetCookie', 'HttpOnly;Secure;SameSite=Strict');
      headers = headers.append('notificationToken', notificationToken ? notificationToken : '');
      return request.clone({
       headers
      });
    } else {
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
          SetCookie: 'HttpOnly;Secure;SameSite=Strict'
        }
      });
    }
  }

  static bearerExpired(next: HttpHandler): Observable<HttpEvent<any>> {
    const connectedAs = JSON.parse(localStorage.getItem('connectedAs'));
    let refresh;
    if (connectedAs) {
      refresh = localStorage.getItem('connectedAsRefreshToken');
    } else {
      refresh = localStorage.getItem('refreshToken');
    }
    const request = new HttpRequest('POST', environment.API_PATH + 'auth/refresh', { refresh });
    return next.handle(request);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.requestInProgressSubject.next(this.requestInProgress + 1);
    request = TokenService.addBearer(request);


    return onErrorResumeNext(next.handle(request).pipe(
      catchError(error => {
        this.serverError = !(request && request.url && request.url.startsWith('https://entreprise.data.gouv.fr'));
        this.requestInProgressSubject.next(this.requestInProgress - 1);
        return of(error);
      }),
      mergeMap((event: HttpEvent<any>) => {
        return new Observable<any>((observer) => {
          if (event instanceof HttpResponse) {
            if (!event.body['success']) {
              if (event.body['errors'] &&
                event.body['errors']['general'] &&
                event.body['errors']['general']['code'] === 'EXPIRED_BEARER') {
                if (this.refreshInProgress) {
                  this.refreshInProgress.subscribe(() => {
                    this.refreshInProgress.unsubscribe();
                    request = TokenService.addBearer(request);
                    next.handle(request).subscribe(resendResponse => {
                      if (resendResponse instanceof HttpResponse) {
                        observer.next(resendResponse);
                      }
                    });
                  });
                } else {
                  this.refreshInProgress = new Subject();
                  TokenService.bearerExpired(next).subscribe(refreshResponse => {
                    if (refreshResponse instanceof HttpResponse) {
                      this.compiler.clearCache();
                      const connectedAs = JSON.parse(localStorage.getItem('connectedAs'));
                      if (connectedAs) {
                        localStorage.setItem('connectedAsAccessToken', refreshResponse.body['accessToken']);
                        localStorage.setItem('connectedAsRefreshToken', refreshResponse.body['refreshToken']);
                      } else {
                        localStorage.setItem('accessToken', refreshResponse.body['accessToken']);
                        localStorage.setItem('refreshToken', refreshResponse.body['refreshToken']);
                      }

                      request = TokenService.addBearer(request);
                      next.handle(request).subscribe(resendResponse => {
                        if (resendResponse instanceof HttpResponse) {
                          observer.next(resendResponse);
                        }
                      });

                      this.refreshInProgress.next(true);
                      this.refreshInProgress = null;
                    }
                  });
                }
              } else if (event.body['errors'] &&
                event.body['errors']['general'] &&
                event.body['errors']['general']['code'] === 'INVALID_BEARER') {
                this.disconnected = true;
              } else if (event.body['errors'] &&
                event.body['errors']['general'] &&
                event.body['errors']['general']['code'] === 'NO_BEARER') {
                this.notConnected = true;
              } else if (event.body['errors'] &&
                event.body['errors']['general'] &&
                event.body['errors']['general']['code'] === 'NOT_AUTHORIZED') {
                this.needToGoBack = true;
              } else if (event.body['errors'] &&
                event.body['errors']['general'] &&
                event.body['errors']['general']['code'] === 'NO_ATTRIBUTION') {
                this.noAttribution = true;
              } else if (event.body['errors'] &&
                event.body['errors']['general'] &&
                event.body['errors']['general']['code'] === 'NO_ENTERPRISES') {
                this.noEnterprises = true;
                observer.next(event);
              } else if (event.body['errors'] &&
                event.body['errors']['general'] &&
                event.body['errors']['general']['code'] === 'END_DISCOVER') {
                this.endDiscover = true;
              } else {
                observer.next(event);
              }
            } else {
              observer.next(event);
            }
            this.requestInProgressSubject.next(this.requestInProgress - 1);
          } else if (event instanceof HttpErrorResponse) {
            const response = new HttpResponse({body: {success: false}});
            observer.next(response);
          }
        });
      })
    ));
  }
}
