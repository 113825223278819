import { Routes } from '@angular/router';

import { AllInstallationsComponent } from './all-installations/all-installations.component';
import { UpdateInstallationComponent } from './update-installation/update-installation.component';
import { ShowInstallationComponent } from './show-installation/show-installation.component';
import { UpdateInstallationCustomComponent } from './update-installation-custom/update-installation-custom.component';

export const installationsRoutes: Routes = [
  {
    path: 'installations',
    component: AllInstallationsComponent,
    children: [
      {
        path: 'create',
        component: UpdateInstallationComponent
      },
      {
        path: 'update/:installation_id',
        component: UpdateInstallationComponent
      }
    ]
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/installations',
    component: AllInstallationsComponent,
    children: [
      {
        path: 'create',
        component: UpdateInstallationCustomComponent
      },
      {
        path: ':installation_id/custom',
        component: UpdateInstallationCustomComponent
      }
    ]
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/installations/show',
    component: ShowInstallationComponent,
  }
];
