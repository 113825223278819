<div class="main-content">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col">
        <div class="header mb-0">
          <div class="header-body no-border">
            <div class="row align-items-center">
              <div class="col">
                <h1 class="header-title">Points de contrôle</h1>

                <small class="form-text text-muted">Attribution des points de contrôle à l'utilisateur prestataire {{ user && user.firstname + ' ' + user.lastname }} de l'entreprise {{ enterprise && enterprise.name }}</small>

                <app-breadcrumbs [currentPage]="pageName" [names]="previousPageName" [routes]="previousPageRoute"></app-breadcrumbs>
              </div>

              <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0" *ngIf="view !== 'providers'">
                <a class="btn btn-primary d-block d-md-inline-block" [routerLink]="'pattern'">
                  Modèle
                </a>
              </div>

              <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0">
                <a class="btn btn-primary d-block d-md-inline-block" [routerLink]="" (click)="updateAccess()">
                  Valider
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="is-loading loader-content" *ngIf="loader"></div>

        <div class="tab-content" *ngIf="!loader && establishments.length">
          <div class="tab-pane fade show active" id="tabPaneOne" role="tabpanel">
            <div class="row listAlias">

              <!-- CARD ESTABLISHMENT -->
              <div class="col-12 d-flex flex-column" *ngFor="let establishment of establishments; let keyEstablishment = index;">
                <div class="card flex-fill card-small">
                  <div class="card-body">
                    <div class="row align-items-center">
                      <div class="col overflow-hidden align-self-start d-flex">
                        <h4 class="card-title name text-truncate mt-3" [ngClass]="{ 'mb-4': !establishment.collapsed }">
                          <a class="href" (click)="toggleEstablishment(keyEstablishment)" [ngClass]="{ 'card-blue': calcCheckedEstablishment(keyEstablishment) }">
                            <i class="fe fe-chevron-right" *ngIf="establishment.collapsed"></i>
                            <i class="fe fe-chevron-down" *ngIf="!establishment.collapsed"></i>
                            {{ establishment.name }} - <span class="tooltipHtml" title="nombre attribué(s) / nombre total">{{ calcCheckedEstablishment(keyEstablishment) + ' / ' + calcAllEstablishment(keyEstablishment) }}</span>
                          </a>
                        </h4>
                      </div>

                      <div class="col-auto align-self-start d-flex" [ngClass]="{ 'mb-4': !establishment.collapsed }">
                        <label for="authorizationEstablishment"></label>
                        <select id="authorizationEstablishment" class="form-control" name="establishmentAccess" (change)="changeEstablishmentAuthorisations($event, keyEstablishment)">
                          <!--<option value="">Séléctionnez des droits</option>-->
                          <option value="observation">Lever des observations</option>
                          <option value="report">Créer des rapports</option>
                          <option value="report-observation" selected>Créer des rapports et lever des observations</option>
                        </select>

                        <a class="href fix-checkbox" (click)="checkEstablishment(keyEstablishment)" >
                          <i class="fe fe-check-square fe-black" *ngIf="calcCheckedEstablishment(keyEstablishment) === calcAllEstablishment(keyEstablishment)"></i>
                          <i class="fe fe-square fe-black" *ngIf="calcCheckedEstablishment(keyEstablishment)  !== calcAllEstablishment(keyEstablishment)"></i>
                        </a>
                      </div>

                      <!-- CARD REGISTRY -->
                      <div class="col-12 d-flex flex-column" *ngFor="let registry of establishment.registries; let lastRegistry = last; let keyRegistry = index">
                        <div class="card flex-fill card-small" [ngClass]="{ 'fix-last': lastRegistry}" *ngIf="!establishment.collapsed">
                          <div class="card-body">
                            <div class="row align-items-center">
                              <div class="col overflow-hidden align-self-start d-flex">
                                <h4 class="card-title name text-truncate mt-3" [ngClass]="{ 'mb-4': !registry.collapsed }">
                                  <a class="href" (click)="toggleRegistry(keyRegistry)" [ngClass]="{ 'card-blue': calcCheckedRegistry(keyEstablishment, keyRegistry) }">
                                    <i class="fe fe-chevron-right" *ngIf="registry.collapsed"></i>
                                    <i class="fe fe-chevron-down" *ngIf="!registry.collapsed"></i>
                                    {{ registry.name }} - <span class="tooltipHtml" title="nombre attribué(s) / nombre total">{{ calcCheckedRegistry(keyEstablishment, keyRegistry) + ' / ' + calcAllRegistry(keyEstablishment, keyRegistry) }}</span>
                                  </a>
                                </h4>
                              </div>

                              <div class="col-auto align-self-start d-flex" [ngClass]="{ 'mb-4': !registry.collapsed }">
                                <label for="authorizationRegistry"></label>
                                <select id="authorizationRegistry" class="form-control" name="establishmentAccess" (change)="changeRegistryAuthorisations($event, keyEstablishment, keyRegistry)">
                                  <!--<option value="">Séléctionnez des droits</option>-->
                                  <option value="observation">Lever des observations</option>
                                  <option value="report">Créer des rapports</option>
                                  <option value="report-observation" selected>Créer des rapports et lever des observations</option>
                                </select>

                                <a class="href fix-checkbox" (click)="checkRegistry(keyEstablishment, keyRegistry)">
                                  <i class="fe fe-check-square fe-black" *ngIf="calcCheckedRegistry(keyEstablishment, keyRegistry) === calcAllRegistry(keyEstablishment, keyRegistry)"></i>
                                  <i class="fe fe-square fe-black" *ngIf="calcCheckedRegistry(keyEstablishment, keyRegistry)  !== calcAllRegistry(keyEstablishment, keyRegistry)"></i>
                                </a>
                              </div>

                              <!-- CARD INSTALLATION -->
                              <div class="col-12 d-flex flex-column" *ngFor="let installation of registry.installations; let lastInstallation = last; let keyInstallation = index">
                                <div class="card flex-fill card-small" [ngClass]="{ 'fix-last': lastInstallation}" *ngIf="!registry.collapsed">
                                  <div class="card-body">
                                    <div class="row align-items-center">
                                      <div class="col overflow-hidden align-self-start d-flex">
                                        <h4 class="card-title name text-truncate mt-3" [ngClass]="{ 'mb-4': !installation.collapsed }">
                                          <a class="href" (click)="toggleInstallation(keyInstallation)" [ngClass]="{ 'card-blue': calcCheckedInstallation(keyEstablishment, keyRegistry, keyInstallation) }">
                                            <i class="fe fe-chevron-right" *ngIf="installation.collapsed"></i>
                                            <i class="fe fe-chevron-down" *ngIf="!installation.collapsed"></i>
                                            {{ (installation.installation ? installation.installation.name : '') }}
                                            {{ installation.installation && installation.description ? ' - ' : '' }}
                                            {{ installation.description }}
                                            - <span class="tooltipHtml" title="nombre attribué(s) / nombre total">{{ calcCheckedInstallation(keyEstablishment, keyRegistry, keyInstallation) + ' / ' + calcAllInstallation(keyEstablishment, keyRegistry, keyInstallation) }}</span>
                                          </a>
                                        </h4>
                                      </div>

                                      <div class="col-auto align-self-start d-flex" [ngClass]="{ 'mb-4': !installation.collapsed }">
                                        <label for="authorizationInstallation"></label>
                                        <select id="authorizationInstallation" class="form-control" name="establishmentAccess" (change)="changeInstallationAuthorisations($event, keyEstablishment, keyRegistry, keyInstallation)">
                                          <!--<option value="">Séléctionnez des droits</option>-->
                                          <option value="observation">Lever des observations</option>
                                          <option value="report">Créer des rapports</option>
                                          <option value="report-observation" selected>Créer des rapports et lever des observations</option>
                                        </select>

                                        <a class="href fix-checkbox" (click)="checkInstallation(keyEstablishment, keyRegistry, keyInstallation)">
                                          <i class="fe fe-check-square fe-black" *ngIf="calcCheckedInstallation(keyEstablishment, keyRegistry, keyInstallation) === calcAllInstallation(keyEstablishment, keyRegistry, keyInstallation)"></i>
                                          <i class="fe fe-square fe-black" *ngIf="calcCheckedInstallation(keyEstablishment, keyRegistry, keyInstallation)  !== calcAllInstallation(keyEstablishment, keyRegistry, keyInstallation)"></i>
                                        </a>
                                      </div>

                                      <!-- CARD CHECKPOINT -->
                                      <div class="col-12 d-flex flex-column" *ngIf="!installation.collapsed">
                                        <div *ngFor="let checkpoint of installation.checkpoints; let lastCheckpoint = last; let keyCheckpoint = index">
                                          <div class="card flex-fill card-small" [ngClass]="{ 'fix-last': lastCheckpoint, 'card-blue': checkpoint.checked }">
                                            <div class="card-body">
                                              <div class="row align-items-center">
                                                <div class="col overflow-hidden">
                                                  <h4 class="card-title mb-2 name text-truncate">
                                                    <i class="fe fe-alert-triangle fe-black tooltipHtml" data-placement="bottom" title="Le point de contrôle va être réattribuer"
                                                       *ngIf="checkpoint.checked &&
                                                       ((checkpoint['authorization'] === 'report' && checkpoint['not_authorization'] === 'report') ||
                                                       (checkpoint['authorization'] === 'observation' && checkpoint['not_authorization'] === 'observation') ||
                                                       (checkpoint['authorization'] === 'report-observation' && checkpoint['not_authorization'] === 'report-observation') ||
                                                       (checkpoint['authorization'] === 'report-observation' && checkpoint['not_authorization'] === 'report') ||
                                                       (checkpoint['authorization'] === 'report-observation' && checkpoint['not_authorization'] === 'observation') ||
                                                       (checkpoint['authorization'] === 'report' && checkpoint['not_authorization'] === 'report-observation') ||
                                                       (checkpoint['authorization'] === 'observation' && checkpoint['not_authorization'] === 'report-observation'))">
                                                    </i>
                                                    <a class="fix-font">
                                                      {{ checkpoint.full_name }}
                                                    </a>
                                                  </h4>

                                                  <!-- IF REPORT -->
                                                  <small *ngIf="checkpoint && checkpoint['report_user'] && checkpoint['not_authorization'] === 'report'" class="form-text text-muted text-truncate" [ngClass]="{ 'text-white': checkpoint.checked }">
                                                    {{ checkpoint['report_enterprise']['name'] + ' - ' + checkpoint['report_user']['firstname'] + ' ' + checkpoint['report_user']['lastname'] + ' - ' + 'Créer des rapports' }}
                                                  </small>
                                                  <!-- IF OBSERVATION -->
                                                  <small *ngIf="checkpoint && checkpoint['observation_user'] && checkpoint['not_authorization'] === 'observation'" class="form-text text-muted text-truncate" [ngClass]="{ 'text-white': checkpoint.checked }">
                                                    {{ checkpoint['observation_enterprise']['name'] + ' - ' + checkpoint['observation_user']['firstname'] + ' ' + checkpoint['observation_user']['lastname'] + ' - ' + 'Lever des observations' }}
                                                  </small>
                                                  <!-- IF REPORT AND OBSERVATION -->
                                                  <small *ngIf="checkpoint && checkpoint['report_user'] && checkpoint['observation_user'] && (checkpoint['report_user']['id'] === checkpoint['observation_user']['id'] && checkpoint['report_enterprise']['id'] === checkpoint['observation_enterprise']['id']) && checkpoint['not_authorization'] === 'report-observation'" class="form-text text-muted text-truncate" [ngClass]="{ 'text-white': checkpoint.checked }">
                                                    {{ checkpoint['observation_enterprise']['name'] + ' - ' + checkpoint['observation_user']['firstname'] + ' ' + checkpoint['observation_user']['lastname'] + ' - ' + 'Créer des rapports et lever des observations' }}
                                                  </small>
                                                  <!-- IF REPORT OR OBSERVATION -->
                                                  <small *ngIf="checkpoint && checkpoint['report_user'] && checkpoint['observation_user'] && (checkpoint['report_user']['id'] !== checkpoint['observation_user']['id'] || checkpoint['report_enterprise']['id'] !== checkpoint['observation_enterprise']['id']) && checkpoint['not_authorization'] === 'report-observation'" class="form-text text-muted text-truncate" [ngClass]="{ 'text-white': checkpoint.checked }">
                                                    {{ checkpoint['report_enterprise']['name'] + ' - ' + checkpoint['report_user']['firstname'] + ' ' + checkpoint['report_user']['lastname'] + ' - ' + 'Créer des rapports' }}
                                                  </small>
                                                  <small *ngIf="checkpoint && checkpoint['report_user'] && checkpoint['observation_user'] && (checkpoint['report_user']['id'] !== checkpoint['observation_user']['id'] || checkpoint['report_enterprise']['id'] !== checkpoint['observation_enterprise']['id']) && checkpoint['not_authorization'] === 'report-observation'" class="form-text text-muted text-truncate" [ngClass]="{ 'text-white': checkpoint.checked }">
                                                    {{ checkpoint['observation_enterprise']['name'] + ' - ' + checkpoint['observation_user']['firstname'] + ' ' + checkpoint['observation_user']['lastname'] + ' - ' + 'Lever des observations' }}
                                                  </small>
                                                </div>

                                                <div class="col-auto align-self-start d-flex">

                                                  <label for="authorizationCheckpoint"></label>
                                                  <select id="authorizationCheckpoint" class="form-control" name="establishmentAccess" [value]="checkpoint.authorization" (change)="changeCheckpointAuthorization($event, checkpoint)">
                                                    <option value="observation">Lever des observations</option>
                                                    <option value="report">Créer des rapports</option>
                                                    <option value="report-observation" selected>Créer des rapports et lever des observations</option>
                                                  </select>

                                                  <a class="href fix-checkbox" (click)="checkCheckpoint(keyEstablishment, keyRegistry, keyInstallation, keyCheckpoint)">
                                                    <i class="fe fe-check-square fe-black" *ngIf="checkpoint.checked"></i>
                                                    <i class="fe fe-square fe-black" *ngIf="!checkpoint.checked"></i>
                                                  </a>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="loader-content" *ngIf="!loader && !establishments.length">
          <p>Aucun résultat</p>
        </div>
      </div>
    </div>
  </div>
</div>

<router-outlet></router-outlet>
