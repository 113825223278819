<app-modal-right (eventOnClose)="closeModal()" #intervention>{{ bindModal(intervention) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">Création</h6>

        <h1 class="header-title">Ticket</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loader"></div>

    <form class="mb-4" [formGroup]="issueForm" [hidden]="loader">
      <div class="tab-content">
        <div class="tab-pane fade show active" id="firstPart" role="tabpanel" aria-labelledby="firstPart">

          <div class="form-group" *ngIf="categories && categories.length">
            <label required>Catégories</label>
            <ng-select [items]="categories"
                       [multiple]="false"
                       bindLabel="name"
                       bindValue="id"
                       groupBy="group"
                       notFoundText="Aucun résultat"
                       [selectableGroupAsModel]="false"
                       [closeOnSelect]="true"
                       placeholder="Sélectionnez une catégorie"
                       [(ngModel)]="selectedCategory" (ngModelChange)="setCategory()" [ngModelOptions]="{ standalone: true }">
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                {{ item.name }}
              </ng-template>
            </ng-select>
          </div>

          <!-- EMERGENCIES -->
          <div class="form-group">
            <label for="validationEmergencies" required>Priorité</label>
            <select class="form-control" data-toggle="select" formControlName="emergency" id="validationEmergencies" [ngClass]="{ 'is-invalid': emergency.invalid && (emergency.dirty || emergency.touched) }">
              <option value="">Sélectionnez un degré d'urgence</option>
              <option value="Bas">Bas</option>
              <option value="Normal">Normal</option>
              <option value="Haut">Haut</option>
              <option value="Prioritaire">Prioritaire</option>
            </select>
            <div *ngIf="emergency.invalid && (emergency.dirty || emergency.touched)" class="invalid-feedback">
              {{ getErrorMessage("emergency") }}
            </div>
          </div>

          <div class="form-group">
            <label for="validationContent" required>Objet du ticket</label>
            <textarea formControlName="content" class="form-control" id="validationContent" placeholder="Objet du ticket" rows="5"></textarea>
            <div *ngIf="content.invalid && (content.dirty || content.touched)" class="invalid-feedback">
              {{ getErrorMessage("content") }}
            </div>
          </div>

          <div class="form-group">
            <label for="validationComments" required>Message</label>
            <textarea formControlName="comment" class="form-control" id="validationComments" placeholder="Objet du ticket" rows="5"></textarea>
            <div *ngIf="comment.invalid && (comment.dirty || comment.touched)" class="invalid-feedback">
              {{ getErrorMessage("comment") }}
            </div>
          </div>

          <div class="card bg-light border">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div class="col">
                  <p class="small text-muted mb-2">
                    Aider à comprendre et identifier votre demande en mettant un maximum de détail.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <label for="inputFile">Documents</label>
            <div class="dropzone dropzone-multiple dz-clickable" appDragDrop (dropHandler)="onFileChange($event)">
              <div class="dz-default dz-message" (click)="chooseFile()">
                <span>Déposer vos fichiers ici pour télécharger</span>
              </div>
              <input id="inputFile" (change)="onFileChange($event)" type="file" [hidden]="true" multiple>
            </div>
          </div>

          <div class="card bg-light border">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div class="col">
                  <p class="small text-muted mb-2">
                    Vous pouvez ajouter des captures d'écran, documents, etc. pour nous aider à comprendre votre besoin.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="card" *ngFor="let file of files; let index = index">
            <div class="card-body">
              <div class="d-flex align-content-center justify-content-between">
                <h4 class="card-title mb-0 name align-self-center">
                  {{ file.name + '.' + file.extension }}
                </h4>

                <a class="href tooltipHtml" data-placement="bottom" title="Supprimer" (click)="removeFile(index)">
                  <i class="fe fe-trash"></i>
                </a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </form>
  </div>

  <div modal-footer class="w-100 d-flex justify-content-between">
    <button class="btn btn-primary w-100" (click)="createIssue()"
            [ngClass]="{ 'is-loading is-loading-sm': loader }" [disabled]="loader || issueForm.invalid">
      Créer
    </button>
  </div>
</app-modal-right>
