import { Routes } from '@angular/router';
import { AllCustomersComponent } from './all-customers/all-customers.component';
import { AllCustomerEstablishmentsComponent } from './all-customer-establishments/all-customer-establishments.component';
import { AllCustomerRegistriesComponent } from './all-customer-registries/all-customer-registries.component';
import { AllCustomerInstallationsComponent } from './all-customer-installations/all-customer-installations.component';
import { AllCustomerCheckpointsComponent } from './all-customer-checkpoints/all-customer-checkpoints.component';

import { AllCustomerReportsComponent } from './all-customer-reports/all-customer-reports.component';
import { UpdateCustomerReportComponent } from './update-customer-report/update-customer-report.component';
import { UploadCustomerReportFileComponent } from './upload-customer-report-file/upload-customer-report-file.component';

import { AllCustomerObservationsComponent } from './all-customer-observations/all-customer-observations.component';
import { UpdateCustomerObservationComponent } from './update-customer-observation/update-customer-observation.component';
import { UploadCustomerObservationFileComponent } from './upload-customer-observation-file/upload-customer-observation-file.component';
import { UpdateCustomerObservationStateComponent } from './update-customer-observation-state/update-customer-observation-state.component';

export const customersRoutes: Routes = [
  {
    path: 'customers',
    component: AllCustomersComponent
  },
  {
    path: 'customers/:enterprise_id/establishments',
    component: AllCustomerEstablishmentsComponent
  },
  {
    path: 'customers/:enterprise_id/establishments/:establishment_id/registries',
    component: AllCustomerRegistriesComponent
  },
  {
    path: 'customers/:enterprise_id/establishments/:establishment_id/registries/:registry_id/installations',
    component: AllCustomerInstallationsComponent,
    children: [
      {
        path: 'observations/:observation_id',
        component: UpdateCustomerObservationComponent
      },
      {
        path: 'observations/:observation_id/state',
        component: UpdateCustomerObservationStateComponent
      }
    ]
  },
  {
    path: 'customers/:enterprise_id/establishments/:establishment_id/registries/:registry_id/installations/:installation_id/checkpoints',
    component: AllCustomerCheckpointsComponent
  },
  {
    path: 'customers/:enterprise_id/establishments/:establishment_id/registries/:registry_id/installations/:installation_id/checkpoints/' +
      ':checkpoint_id/reports',
    component: AllCustomerReportsComponent,
    children: [
      {
        path: 'create',
        component: UpdateCustomerReportComponent
      },
      {
        path: ':report_id',
        component: UpdateCustomerReportComponent
      }
    ]
  },
  {
    path: 'customers/:enterprise_id/establishments/:establishment_id/registries/:registry_id/installations/:installation_id/checkpoints/' +
      ':checkpoint_id/reports/:report_id/files',
    component: AllCustomerReportsComponent,
    children: [
      {
        path: 'create',
        component: UploadCustomerReportFileComponent
      },
      {
        path: ':file_id',
        component: UploadCustomerReportFileComponent
      }
    ]
  },
  {
    path: 'customers/:enterprise_id/establishments/:establishment_id/registries/:registry_id/installations/:installation_id/' +
      'checkpoints/:checkpoint_id/reports/:report_id/observations',
    component: AllCustomerObservationsComponent,
    children: [
      {
        path: 'create',
        component: UpdateCustomerObservationComponent
      },
      {
        path: ':observation_id',
        component: UpdateCustomerObservationComponent
      },
      {
        path: ':observation_id/state',
        component: UpdateCustomerObservationStateComponent
      }
    ]
  },
  {
    path: 'customers/:enterprise_id/establishments/:establishment_id/registries/:registry_id/installations/:installation_id/' +
      'checkpoints/:checkpoint_id/reports/:report_id/observations/:observation_id/files',
    component: AllCustomerObservationsComponent,
    children: [
      {
        path: 'create',
        component: UploadCustomerObservationFileComponent
      },
      {
        path: ':file_id',
        component: UploadCustomerObservationFileComponent
      }
    ]
  }
];
