<app-modal-right (eventOnClose)="closeModal()" #legionella>{{ bindModal(legionella) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">{{ isNew ? 'Création' : canUpdate ? 'Mise à jour' : 'Consultation' }}</h6>

        <h1 class="header-title">Surveillance des températures</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <form [formGroup]="sanitaryTemperatureFrameForm" [hidden]="loaderContent">
      <!-- ID / NAME -->
      <div class="form-group">
        <label for="validationTitle" required>Titre</label>
        <input type="text" formControlName="title" class="form-control" id="validationTitle" placeholder="Titre" [ngClass]="{ 'is-invalid': title.invalid && (title.dirty || title.touched) }">
      </div>
      <!-- CREATED DATE -->
      <div class="form-group">
        <label required="">Date</label>
        <input type="text" formControlName="date" class="form-control" placeholder="Date" data-toggle="flatpickr" [ngClass]="{ 'is-invalid': date.invalid && (date.dirty || date.touched) }">
        <div *ngIf="date.invalid && (date.dirty || date.touched)" class="invalid-feedback">
          {{ getErrorMessage("date") }}
        </div>
      </div>

      <div class="form-group" *ngIf="listItems.length">
        <label required>Sélectionner un point de surveillance</label>
        <ng-select [items]="listItems"
                   [multiple]="false"
                   bindLabel="title"
                   bindValue="title"
                   [selectableGroup]="false"
                   [selectableGroupAsModel]="false"
                   [closeOnSelect]="true"
                   placeholder="Sélectionner un point de surveillance"
                   [(ngModel)]="selectedDescription" (ngModelChange)="changeDescription()" [ngModelOptions]="{ standalone: true }">
          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
            {{ item.title }}
          </ng-template>
        </ng-select>
      </div>

      <div class="form-group" formArrayName="descriptions">
        <div [formGroupName]="indexDescription" *ngFor="let description of sanitaryTemperatureFrameForm['controls'].descriptions['controls'] as FormGroup; let indexDescription = index;">

          <div *ngIf="description['controls'].title.value === selectedDescription">

            <div formArrayName="values">
              <div [formGroupName]="indexValue" class="form-group" [attr.id]="'value' + indexValue" *ngFor="let value of description['controls'].values['controls'] as FormGroup; let indexValue = index;">

                <div class="card" *ngIf="value['controls'].title.value">

                  <div class="card-header">
                    <div class="d-flex align-items-center">
                      <i data-toggle="collapse" [attr.href]="'#indexValue' + indexValue" aria-expanded="true" class="fe chevron mr-2 href collapsed"></i>
                      <p class="mb-0 my-sm-3 mx-sm-auto mx-lg-0">{{ value['controls'].title.value }}</p>
                    </div>
                  </div>

                  <ng-container *ngIf="sanitaryFrame === 'Quotidienne'">
                    <div formArrayName="comments" class="card-body collapse row" [id]="'indexValue' + indexValue" [attr.id]="'indexValue' + indexValue" role="tabpanel" [attr.data-parent]="'#indexValue' + indexValue">

                      <div class="form-group w-100" *ngIf="monthItems.length">
                        <label required>Sélectionner un mois</label>
                        <ng-select [items]="monthItems"
                                   [multiple]="false"
                                   bindLabel="title"
                                   bindValue="title"
                                   [selectableGroup]="false"
                                   [selectableGroupAsModel]="false"
                                   [closeOnSelect]="true"
                                   placeholder="Sélectionner un mois"
                                   [(ngModel)]="selectedMonth" [ngModelOptions]="{ standalone: true }">
                          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                            {{ item.title }}
                          </ng-template>
                        </ng-select>
                      </div>

                      <div [formGroupName]="indexValueComment" *ngFor="let item of value['controls'].comments['controls'] as FormGroup; let indexValueComment = index;">
                        <div *ngIf="item['controls'].title.value === selectedMonth">

                          <div formArrayName="comments" class="row">
                            <div [formGroupName]="indexComment" class="col-4" *ngFor="let comment of item['controls'].comments['controls'] as FormGroup; let indexComment = index;">

                              <div class="form-group">
                                <label>{{ comment['controls'].title.value }}</label>
                                <input type="number" formControlName="comment" class="form-control" placeholder="°C" [ngClass]="{ 'is-invalid': comment['controls'].comment.invalid && (comment['controls'].comment.dirty || comment['controls'].comment.touched) }">
                                <div *ngIf="comment['controls'].comment.invalid && (comment['controls'].comment.dirty || comment['controls'].comment.touched)" class="invalid-feedback">
                                  {{ getErrorMessage("comment") }}
                                </div>
                              </div>

                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="sanitaryFrame !== 'Quotidienne'">
                    <div formArrayName="comments" class="card-body collapse row"  [id]="'indexValue' + indexValue" [attr.id]="'indexValue' + indexValue" role="tabpanel" [attr.data-parent]="'#indexValue' + indexValue">
                      <div [formGroupName]="indexItem" class="col-4" *ngFor="let item of value['controls'].comments['controls'] as FormGroup; let indexItem = index;">

                          <div class="form-group">
                            <label>{{ item['controls'].title.value }}</label>
                            <input type="number" formControlName="comment" class="form-control" placeholder="°C" [ngClass]="{ 'is-invalid': item['controls'].comment.invalid && (item['controls'].comment.dirty || item['controls'].comment.touched) }">
                            <div *ngIf="item['controls'].comment.invalid && (item['controls'].comment.dirty || item['controls'].comment.touched)" class="invalid-feedback">
                              {{ getErrorMessage("comment") }}
                            </div>
                          </div>

                      </div>
                    </div>
                  </ng-container>

                </div>

              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="form-group">
        <label for="validationDetectionAnomaly">Anomalie(s) détectée(s)</label>
        <textarea formControlName="detected_anomalies" class="form-control" id="validationDetectionAnomaly" placeholder="Anomalie(s) detectée(s)" rows="5"
                  [ngClass]="{ 'is-invalid': detected_anomalies.invalid && (detected_anomalies.dirty || detected_anomalies.touched) }"></textarea>
        <div *ngIf="detected_anomalies.invalid && (detected_anomalies.dirty || detected_anomalies.touched)" class="invalid-feedback">
          {{ getErrorMessage("detected_anomalies") }}
        </div>
      </div>

      <div class="form-group">
        <label for="validationIntervention">Intervention(s)</label>
        <textarea formControlName="interventions" class="form-control" id="validationIntervention" placeholder="Intervention(s)" rows="5"
                  [ngClass]="{ 'is-invalid': interventions.invalid && (interventions.dirty || interventions.touched) }"></textarea>
        <div *ngIf="interventions.invalid && (interventions.dirty || interventions.touched)" class="invalid-feedback">
          {{ getErrorMessage("interventions") }}
        </div>
      </div>

    </form>
  </div>

  <div modal-footer class="w-100" *ngIf="isNew || canUpdate">
    <button class="btn btn-primary w-100" (click)="isNew ? createSanitaryTemperatureFrame() : updateSanitaryTemperatureFrame()" [ngClass]="{ 'is-loading is-loading-sm': loader }" [disabled]="loader || sanitaryTemperatureFrameForm.invalid">
      {{ isNew ? 'Créer' : 'Mettre à jour' }}
    </button>
  </div>
</app-modal-right>
