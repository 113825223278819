import {
  Component,
  ContentChildren,
  ElementRef,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NotifierService } from "angular-notifier";
import { Subject, Subscription } from "rxjs";

import * as moment from "moment";
import { AuthService } from "../../../core/auth/auth.service";
import { StatisticsRequestService } from "../../../services/statistics.request.service";
import { CustomNotifierService } from "../../../services/custom.notifier.service";

declare var $: any;

declare function UserCharts(): any;
declare function Flatpickr(): any;

@ContentChildren(DashboardObservationsComponent)
@Component({
  selector: "app-dashboard-observations",
  templateUrl: "./dashboard-observations.component.html",
  styleUrls: ["./dashboard-observations.component.scss"],
})
export class DashboardObservationsComponent implements OnInit, OnDestroy {
  // ---------------------- STATISTICS ----------------------
  changeEnterprise: Subscription;
  establishments = [];
  selectedEstablishments = null;
  registries = [];
  selectedRegistry = null;
  totalEstablishments = 0;
  userChart = UserCharts();
  installations;
  installationCustoms = [];
  registryInstallations = [];
  selectedInstallation = [];
  selectedInstallationCustom = [];
  checkpoints;
  selectedCheckpoints: any[] = [];
  selectedCheckpointsCustom: any[] = [];
  selectedCheckpoint = null;
  periods = [
    { value: "custom", name: "Période personnalisée" },
    { value: "days", name: "7 derniers jours" },
    { value: "month", name: "30 derniers jours" },
    { value: "months", name: "3 derniers mois" },
    { value: "year", name: "Année en cours" },
    { value: "lastyear", name: "Année dernière" },
    { value: "lastyears", name: "Il y a deux ans" },
  ];
  period = "custom";
  dateFrom;
  dateTo;
  groupBy = "month";

  // ALL OBSERVATIONS
  allObservations = [];
  allObservationsLimit = 12;
  allObservationsSort = "";
  allObservationsOrder = "";
  allObservationsCount = 0;
  allObservationsPage = 1;
  allObservationsPages = 1;

  // ALL OBSERVATIONS STATE
  observationsState = [];
  observationsStateLimit = 12;
  observationsStateSort = "";
  observationsStateOrder = "";
  observationsStateCount = 0;
  observationsStatePage = 1;
  observationsStatePages = 1;

  // ALL OBSERVATIONS NOT STATE
  observationsNotState = [];
  observationsNotStateLimit = 12;
  observationsNotStateSort = "";
  observationsNotStateOrder = "";
  observationsNotStateCount = 0;
  observationsNotStatePage = 1;
  observationsNotStatePages = 1;

  // ALL REPORTS
  reports = [];
  reportsLimit = 12;
  reportsSort = "";
  reportsOrder = "";
  reportsCount = 0;
  reportsPage = 1;
  reportsPages = 1;

  // EMERGENCIES BLOC
  observationsNC = [];
  observationsNCLimit = 12;
  observationsNCSort = "";
  observationsNCOrder = "";
  observationsNCCount = 0;
  observationsNCPage = 1;
  observationsNCPages = 1;

  observationsLow = [];
  observationsLowLimit = 12;
  observationsLowSort = "";
  observationsLowOrder = "";
  observationsLowCount = 0;
  observationsLowPage = 1;
  observationsLowPages = 1;

  observationsNormal = [];
  observationsNormalLimit = 12;
  observationsNormalSort = "";
  observationsNormalOrder = "";
  observationsNormalCount = 0;
  observationsNormalPage = 1;
  observationsNormalPages = 1;

  observationsHigher = [];
  observationsHigherLimit = 12;
  observationsHigherSort = "";
  observationsHigherOrder = "";
  observationsHigherCount = 0;
  observationsHigherPage = 1;
  observationsHigherPages = 1;

  observationsPriority = [];
  observationsPriorityLimit = 12;
  observationsPrioritySort = "";
  observationsPriorityOrder = "";
  observationsPriorityCount = 0;
  observationsPriorityPage = 1;
  observationsPriorityPages = 1;

  // DELAY OBSERVATIONS
  observationsLate = [];
  observationsLateLimit = 12;
  observationsLateSort = "";
  observationsLateOrder = "";
  observationsLateCount = 0;
  observationsLatePage = 1;
  observationsLatePages = 1;

  observationsInTime = [];
  observationsInTimeLimit = 12;
  observationsInTimeSort = "";
  observationsInTimeOrder = "";
  observationsInTimeCount = 0;
  observationsInTimePage = 1;
  observationsInTimePages = 1;

  observationsAllCheckpoints = [];
  observationsAllCheckpointsLimit = 12;
  observationsAllCheckpointsSort = "";
  observationsAllCheckpointsOrder = "";
  observationsAllCheckpointsCount = 0;
  observationsAllCheckpointsPage = 1;
  observationsAllCheckpointsPages = 1;

  observationsCheckpointsLate = [];
  observationsCheckpointsLateLimit = 12;
  observationsCheckpointsLateSort = "";
  observationsCheckpointsLateOrder = "";
  observationsCheckpointsLateCount = 0;
  observationsCheckpointsLatePage = 1;
  observationsCheckpointsLatePages = 1;

  // OBSERVATIONS
  totalObs = 0;
  totalObsL = 0;
  totalObsNL = 0;
  totalObsCheckpoints = 0;
  // EMERGENCIES
  totalObsEmergencies = 0;
  totalObsEmergencyN = 0;
  totalObsEmergency0 = 0;
  totalObsEmergency1 = 0;
  totalObsEmergency2 = 0;
  totalObsEmergency3 = 0;
  // DELAY
  totalObsDelay = 0;
  totalObsDelayN = 0;
  totalObsAverage = 0;
  totalObsCheckpointLate = 0;

  // ------------------- COMPARATIVE ESTABLISHMENTS -------------------
  selectedEstablishmentA = null;
  selectedEstablishmentB = null;
  totalCheckpointsA = 0;
  totalCheckpointsB = 0;
  checkpointsALate = 0;
  checkpointsBLate = 0;
  totalObservationsA = 0;
  totalObservationsB = 0;
  observationsALate = 0;
  observationsBLate = 0;

  // ------------------- OBSERVATIONS BY INSTALLATION -------------------

  obsInstallation = [];
  obsStateInstallation = [];
  obsNotStateInstallation = [];

  installationName = "";
  installationIds = [];

  allObsInstallation = [];
  allObsInstallationCount = 0;
  allObsInstallationLimit = 12;
  allObsInstallationSort = "";
  allObsInstallationOrder = "";
  allObsInstallationPage = 1;
  allObsInstallationPages = 1;

  allObsStateInstallation = [];
  allObsStateInstallationCount = 0;
  allObsStateInstallationLimit = 12;
  allObsStateInstallationSort = "";
  allObsStateInstallationOrder = "";
  allObsStateInstallationPage = 1;
  allObsStateInstallationPages = 1;

  allObsNotStateInstallation = [];
  allObsNotStateInstallationCount = 0;
  allObsNotStateInstallationLimit = 12;
  allObsNotStateInstallationSort = "";
  allObsNotStateInstallationOrder = "";
  allObsNotStateInstallationPage = 1;
  allObsNotStateInstallationPages = 1;

  obsInstallationLimit = 12;
  obsInstallationPage = 1;

  // ------------------ AVERAGE DAY + AVERAGE COSTS -------------------
  // DAY
  dayLimit = 12;
  dayPage = 1;
  dayFilter1 = [];
  dayFilter1Count = 0;
  dayFilter1Limit = 12;
  dayFilter1Sort = "";
  dayFilter1Order = "";
  dayFilter1Page = 1;
  dayFilter1Pages = 1;
  dayFilter2 = [];
  dayFilter2Count = 0;
  dayFilter2Limit = 12;
  dayFilter2Sort = "";
  dayFilter2Order = "";
  dayFilter2Page = 1;
  dayFilter2Pages = 1;
  dayFilter3 = [];
  dayFilter3Count = 0;
  dayFilter3Limit = 12;
  dayFilter3Sort = "";
  dayFilter3Order = "";
  dayFilter3Page = 1;
  dayFilter3Pages = 1;
  dayFilter4 = [];
  dayFilter4Count = 0;
  dayFilter4Limit = 12;
  dayFilter4Sort = "";
  dayFilter4Order = "";
  dayFilter4Page = 1;
  dayFilter4Pages = 1;

  // COSTS
  costsLimit = 12;
  costsPage = 1;
  costFilter1 = [];
  costFilter1Count = 0;
  costFilter1Limit = 12;
  costFilter1Sort = "";
  costFilter1Order = "";
  costFilter1Page = 1;
  costFilter1Pages = 1;
  costFilter2 = [];
  costFilter2Count = 0;
  costFilter2Limit = 12;
  costFilter2Sort = "";
  costFilter2Order = "";
  costFilter2Page = 1;
  costFilter2Pages = 1;
  costFilter3 = [];
  costFilter3Count = 0;
  costFilter3Limit = 12;
  costFilter3Sort = "";
  costFilter3Order = "";
  costFilter3Page = 1;
  costFilter3Pages = 1;

  requestInProgress = new Subject();
  requestCount = 0;
  loader = false;

  modal;

  loaderObsChart = false;
  loaderEmergenciesChart = false;
  loaderDelayObservationsChart = false;
  loaderObservationsComparativeChart = false;
  loaderObsInstallation = false;
  loaderObservationsAverageDays = false;
  loaderObservationsAverageCosts = false;
  loaderEstablishmentsType = false;
  loaderRegistryByEnterprise = false;
  loaderCheckpoint = false;

  constructor(
    private authService: AuthService,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private statisticsService: StatisticsRequestService,
    private _rootNode: ElementRef
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loader = this.requestCount > 0;
    });

    this.changeEnterprise = this.authService.getEnterprise().subscribe(() => {
      this.initUserCharts();
      Flatpickr();
    });

    if (this.authService.loaded) {
      this.initUserCharts();
      setTimeout(function () {
        Flatpickr();
      }, 100);
    }

    // Remove background opacity bug if modal closed too fast
    const modalBackdrop = document.getElementsByClassName(
      "modal-backdrop fade show"
    ) as HTMLCollection;
    for (let i = 0; i < modalBackdrop.length; i++) {
      modalBackdrop[i].remove();
    }
    const body = document.getElementsByTagName("body");
    for (let i = 0; i < body.length; i++) {
      body[i].className = body[i].className.replace("modal-open", "");
    }
  }

  ngOnDestroy() {
    this.changeEnterprise.unsubscribe();

    const tooltips = document.getElementsByClassName(
      "tooltip"
    ) as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
    if (this.modal) {
      this.modal.modal("hide");
      this.selectedEstablishments = [];
    }
  }

  initUserCharts() {
    this.statisticsService
      .getRegistries([], "all_checkpoint_registries", "")
      .subscribe((response) => {
        if (response["success"]) {
          this.registries = response["registries"];
        } else if (response["errors"]) {
          CustomNotifierService.getErrors(response["errors"], this.notifier);
        }
      });

    this.statisticsService.getInstallations().subscribe((response) => {
      if (response["success"]) {
        this.installations = response["installations"];
        this.checkpoints = response["checkpoints"];
      } else if (response["errors"]) {
        CustomNotifierService.getErrors(response["errors"], this.notifier);
      }
    });

    this.statisticsService.getInstallationsCustom().subscribe((response) => {
      if (response["success"]) {
        this.installationCustoms = response["installationsCustom"];
        this.installationCustoms.forEach((custom) => {
          if (custom.installation_id === 27) {
            custom.description =
              custom.installation.name +
              " " +
              custom.description +
              " (" +
              custom.establishment.name +
              ")";
          }
        });
      } else if (response["errors"]) {
        CustomNotifierService.getErrors(response["errors"], this.notifier);
      }
    });

    this.statisticsService
      .getObservationsStats("month", "", "")
      .subscribe((response) => {
        if (response["success"]) {
          this.userChart.createObservationsChart(
            "#headerChart",
            response,
            "month"
          );
        } else if (response["errors"]) {
          CustomNotifierService.getErrors(response["errors"], this.notifier);
        }
      });
    this.statisticsService
      .getEstablishmentsNumber("true", "")
      .subscribe((response) => {
        if (response["success"]) {
          this.totalEstablishments = response["nbEstablishments"];
          this.establishments = response["establishments"];
        } else if (response["errors"]) {
          CustomNotifierService.getErrors(response["errors"], this.notifier);
        }
      });
    this.loaderEstablishmentsType = true;
    this.statisticsService
      .getEstablishmentsNumber("true", "type")
      .subscribe((response) => {
        if (response["success"]) {
          this.userChart.createEstablishmentChart(
            "#establishmentsTypeChart",
            response
          );
        } else if (response["errors"]) {
          CustomNotifierService.getErrors(response["errors"], this.notifier);
        }
        this.loaderEstablishmentsType = false;
      });
    this.loaderRegistryByEnterprise = true;
    this.statisticsService
      .getEnterpriseRegistriesByDomainAndEstablishment()
      .subscribe((response) => {
        if (response["success"]) {
          this.userChart.createRegistryByDomainAndEnterpriseChart(
            "#registryByEnterpriseChart",
            response
          );
        } else if (response["errors"]) {
          CustomNotifierService.getErrors(response["errors"], this.notifier);
        }
        this.loaderRegistryByEnterprise = false;
      });
    this.getObservationsByInstallations();
    this.getObservationsStatsCosts();
    this.getObservationsStatsDay();
    this.getObsBlock();
    this.getEmergenciesObservations();
    this.getDelayObservations();
    this.getDelayObservationsCheckpoints();
  }

  updateObservationsChart(groupBy = this.groupBy) {
    this.groupBy = groupBy;
    this.statisticsService
      .getObservationsStats(groupBy, this.dateFrom, this.dateTo)
      .subscribe((response) => {
        if (response["success"]) {
          this.userChart.createObservationsChart(
            "#headerChart",
            response,
            groupBy
          );
        } else if (response["errors"]) {
          CustomNotifierService.getErrors(response["errors"], this.notifier);
        }
      });
  }

  updateSelectedEstablishments() {
    this.registryInstallations = [];
    this.selectedInstallationCustom = [];
    this.selectedCheckpoints = [];
    this.selectedCheckpointsCustom = [];
    this.selectedInstallation = [];
    this.selectedCheckpoint = null;
    this.selectedRegistry = null;

    this.getInstallations();
    this.getInstallationsCustom();
    this.getObsBlock();
    this.getEmergenciesObservations();
    this.getDelayObservations();
    this.getDelayObservationsCheckpoints();
    this.getObservationsStatsCosts();
    this.getObservationsStatsDay();
    this.getObservationsByInstallations();
  }

  updatePeriod(event = null) {
    let update = false;
    if (this.period === "days") {
      this.dateFrom = moment().subtract(7, "day").format("YYYY-MM-DD");
      this.dateTo = moment().format("YYYY-MM-DD");
      update = true;
    } else if (this.period === "month") {
      this.dateFrom = moment().subtract(1, "month").format("YYYY-MM-DD");
      this.dateTo = moment().format("YYYY-MM-DD");
      update = true;
    } else if (this.period === "months") {
      this.dateFrom = moment().subtract(3, "month").format("YYYY-MM-DD");
      this.dateTo = moment().format("YYYY-MM-DD");
      update = true;
    } else if (this.period === "year") {
      this.dateFrom = moment().startOf("year").format("YYYY-MM-DD");
      this.dateTo = moment().endOf("year").format("YYYY-MM-DD");
      update = true;
    } else if (this.period === "lastyear") {
      this.dateFrom = moment()
        .startOf("year")
        .subtract(1, "year")
        .format("YYYY-MM-DD");
      this.dateTo = moment()
        .endOf("year")
        .subtract(1, "year")
        .format("YYYY-MM-DD");
      update = true;
    } else if (this.period === "lastyears") {
      this.dateFrom = moment()
        .startOf("year")
        .subtract(2, "year")
        .format("YYYY-MM-DD");
      this.dateTo = moment()
        .endOf("year")
        .subtract(2, "year")
        .format("YYYY-MM-DD");
      update = true;
    }
    if (event === null) {
      this.dateFrom = null;
      this.dateTo = null;
      update = true;
    }
    if (update) {
      this.getObsBlock();
      this.updateObservationsChart();
      this.getEmergenciesObservations();
      this.getDelayObservations();
      this.getDelayObservationsCheckpoints();
      this.getObservationsStatsCosts();
      this.getObservationsStatsDay();
      this.getObservationsByInstallations();
    }
  }

  updateSelectedRegistry() {
    this.registryInstallations = [];
    this.selectedInstallationCustom = [];
    this.selectedCheckpoints = [];
    this.selectedCheckpointsCustom = [];
    this.selectedCheckpoint = null;
    this.selectedInstallation = [];

    if (this.selectedRegistry !== 5) {
      this.getInstallations();
      const tempCheckpoints = [];

      if (!this.selectedRegistry && !this.checkpoints) {
        return [];
      }
      for (let i = 0; i < this.checkpoints.length; i++) {
        if (this.checkpoints[i]["registry_id"] === this.selectedRegistry) {
          tempCheckpoints.push(this.checkpoints[i]);
        }
      }
      this.installations.forEach((installation) => {
        const found = tempCheckpoints.findIndex((checkpoint) => {
          return checkpoint.installation_id === installation.id;
        });
        if (found !== -1) {
          this.registryInstallations.push(installation);
        }
      });
    } else {
      this.getInstallationsCustom();
    }
    this.getObsBlock();
    this.getEmergenciesObservations();
    this.getDelayObservations();
    this.getDelayObservationsCheckpoints();
    this.getObservationsStatsCosts();
    this.getObservationsStatsDay();
    this.getObservationsByInstallations();
  }

  getInstallations() {
    let request;

    request = this.statisticsService.getInstallations(
      this.selectedEstablishments,
      this.selectedRegistry
    );

    request.subscribe((response) => {
      if (response["success"]) {
        this.installations = response["installations"];
        this.checkpoints = response["checkpoints"];
      } else if (response["errors"]) {
        CustomNotifierService.getErrors(response["errors"], this.notifier);
      }
    });
  }
  updateSelectedInstallation() {
    this.selectedCheckpoint = null;
    this.getCheckpoints();
    this.getObsBlock();
    this.updateObservationsChart();
    this.getEmergenciesObservations();
    this.getDelayObservations();
    this.getDelayObservationsCheckpoints();
    this.getObservationsStatsCosts();
    this.getObservationsStatsDay();
    this.getObservationsByInstallations();
  }
  updateSelectedCheckpoint() {
    this.getObsBlock();
    this.getEmergenciesObservations();
    this.getDelayObservations();
    this.getDelayObservationsCheckpoints();
    this.getObservationsStatsCosts();
    this.getObservationsStatsDay();
  }
  getCheckpoints() {
    if (this.selectedRegistry && this.selectedRegistry !== 5) {
      if (!this.selectedInstallation || !this.checkpoints) {
        return [];
      }
      this.selectedCheckpoints = [];
      for (let i = 0; i < this.checkpoints.length; i++) {
        for (let y = 0; y < this.selectedInstallation.length; y++) {
          if (
            this.checkpoints[i]["installation_id"] ===
            this.selectedInstallation[y]
          ) {
            this.selectedCheckpoints.push(this.checkpoints[i]);
          }
        }
      }
    }
  }

  getInstallationsCustom() {
    let request;

    request = this.statisticsService.getInstallationsCustom(
      this.selectedEstablishments
    );

    request.subscribe((response) => {
      if (response["success"]) {
        this.installationCustoms = response["installationsCustom"];
        this.installationCustoms.forEach((custom) => {
          if (custom.installation_id === 27) {
            custom.description =
              custom.installation.name +
              " " +
              custom.description +
              " (" +
              custom.establishment.name +
              ")";
          }
        });
      } else if (response["errors"]) {
        CustomNotifierService.getErrors(response["errors"], this.notifier);
      }
    });
  }
  updateSelectedInstallationCustom() {
    this.selectedCheckpointsCustom = null;
    this.getCheckpointsCustom();
    this.getObsBlock();
    this.updateObservationsChart();
    this.getEmergenciesObservations();
    this.getDelayObservations();
    this.getDelayObservationsCheckpoints();
    this.getObservationsStatsCosts();
    this.getObservationsStatsDay();
    this.getObservationsByInstallations();
  }
  updateSelectedCheckpointCustom() {
    this.getObsBlock();
    this.getEmergenciesObservations();
    this.getDelayObservations();
    this.getDelayObservationsCheckpoints();
    this.getObservationsStatsCosts();
    this.getObservationsStatsDay();
  }
  getCheckpointsCustom() {
    if (this.selectedRegistry && this.selectedRegistry === 5) {
      if (!this.selectedInstallationCustom) {
        return [];
      }
      this.selectedCheckpointsCustom = [];
      for (let i = 0; i < this.installationCustoms.length; i++) {
        for (let y = 0; y < this.selectedInstallationCustom.length; y++) {
          if (
            this.installationCustoms[i]["id"] ===
            this.selectedInstallationCustom[y]
          ) {
            this.installationCustoms[i].establishment_checkpoints.forEach(
              (checkpoint) => {
                this.selectedCheckpointsCustom.push(checkpoint);
              }
            );
          }
        }
      }
    }
  }

  updateDate() {
    this.period = "custom";
    this.getObsBlock();
    this.updateObservationsChart();
    this.getEmergenciesObservations();
    this.getDelayObservations();
    this.getDelayObservationsCheckpoints();
  }

  getObsBlock() {
    this.loaderObsChart = true;
    let installations = [];
    if (this.selectedRegistry !== 5) {
      installations = this.selectedInstallation;
    } else {
      installations = this.selectedInstallationCustom;
    }
    this.statisticsService
      .obsStat(
        installations,
        this.dateFrom,
        this.dateTo,
        this.selectedCheckpoint,
        this.selectedEstablishments,
        "",
        "",
        "",
        "",
        this.selectedRegistry
      )
      .subscribe((response) => {
        this.totalObs = 0;
        this.totalObsL = 0;
        this.totalObsNL = 0;
        if (response["success"]) {
          this.totalObsCheckpoints = response["checkpointsCount"];
          this.totalObs = response["allObservationsCount"];
          this.totalObsL = response["observationsStateCount"];
          this.totalObsNL = response["observationsNotStateCount"];
          this.userChart.createObsChart(
            "#obsChart",
            response,
            this.totalObs,
            this.totalObsL,
            this.totalObsNL,
            this
          );
        } else if (response["errors"]) {
          CustomNotifierService.getErrors(response["errors"], this.notifier);
        }
        this.loaderObsChart = false;
      });
  }

  getEmergenciesObservations() {
    this.loaderEmergenciesChart = true;
    let installations = [];
    if (this.selectedRegistry !== 5) {
      installations = this.selectedInstallation;
    } else {
      installations = this.selectedInstallationCustom;
    }
    this.statisticsService
      .getEmergenciesObservations(
        installations,
        this.dateFrom,
        this.dateTo,
        this.selectedCheckpoint,
        this.selectedEstablishments,
        "",
        "",
        "",
        "",
        this.selectedRegistry
      )
      .subscribe((response) => {
        if (response["success"]) {
          this.totalObsEmergencies = 0;
          this.totalObsEmergencyN = 0;
          this.totalObsEmergency0 = 0;
          this.totalObsEmergency1 = 0;
          this.totalObsEmergency2 = 0;
          this.totalObsEmergency3 = 0;
          this.totalObsEmergencyN = response["observationsNCCount"];
          this.totalObsEmergency0 = response["observationsLowCount"];
          this.totalObsEmergency1 = response["observationsNormalCount"];
          this.totalObsEmergency2 = response["observationsHigherCount"];
          this.totalObsEmergency3 = response["observationsPriorityCount"];
          this.totalObsEmergencies =
            this.totalObsEmergencyN +
            this.totalObsEmergency0 +
            this.totalObsEmergency1 +
            this.totalObsEmergency2 +
            this.totalObsEmergency3;
          this.userChart.createObsEmergenciesChart(
            "#emergenciesObservationsChart",
            response,
            this.totalObsEmergencies,
            this
          );
        } else if (response["errors"]) {
          CustomNotifierService.getErrors(response["errors"], this.notifier);
        }
        this.loaderEmergenciesChart = false;
      });
  }

  getDelayObservations() {
    this.loaderDelayObservationsChart = true;
    let installations = [];
    if (this.selectedRegistry !== 5) {
      installations = this.selectedInstallation;
    } else {
      installations = this.selectedInstallationCustom;
    }
    this.statisticsService
      .getDelayObservations(
        installations,
        this.dateFrom,
        this.dateTo,
        this.selectedCheckpoint,
        this.selectedEstablishments,
        "",
        "",
        "",
        "",
        this.selectedRegistry
      )
      .subscribe((response) => {
        if (response["success"]) {
          let total = 0;
          let days = 0;
          if (response["observationsLate"]) {
            response["observationsLate"].forEach((observation) => {
              days += parseInt(observation["day"], 10);
              total++;
            });
          }
          if (response["observationsInTime"]) {
            response["observationsInTime"].forEach((observation) => {
              days += parseInt(observation["day"], 10);
              total++;
            });
          }
          if (total > 0) {
            if (days < 0) {
              days = 0;
            }
            this.totalObsAverage = Math.round(days / total);
          } else {
            this.totalObsAverage = 0;
          }

          this.totalObsDelay = 0;
          this.totalObsDelayN = 0;
          this.totalObsDelay = parseInt(response["observationsLateCount"], 10);
          this.totalObsDelayN = parseInt(
            response["observationsInTimeCount"],
            10
          );
          this.userChart.createObsDelayChart(
            "#delayObservationsChart",
            this.totalObsDelay,
            this.totalObsDelayN,
            this
          );
        } else if (response["errors"]) {
          CustomNotifierService.getErrors(response["errors"], this.notifier);
        }
        this.loaderDelayObservationsChart = false;
      });
  }
  getDelayObservationsCheckpoints() {
    this.loaderCheckpoint = true;
    let installations = [];
    if (this.selectedRegistry !== 5) {
      installations = this.selectedInstallation;
    } else {
      installations = this.selectedInstallationCustom;
    }
    this.statisticsService
      .getDelayObservationsCheckpoints(
        installations,
        this.dateFrom,
        this.dateTo,
        this.selectedCheckpoint,
        this.selectedEstablishments,
        "",
        "",
        "",
        "",
        this.selectedRegistry
      )
      .subscribe((response) => {
        if (response["success"]) {
          this.totalObsCheckpointLate = response["checkpointsLateCount"];
        } else if (response["errors"]) {
          CustomNotifierService.getErrors(response["errors"], this.notifier);
        }
        this.loaderCheckpoint = false;
      });
  }

  // -------------------- URL + ESTABLISHMENT NAME --------------------
  getEstablishmentNameReport(report) {
    if (report["establishment_checkpoint"]) {
      if (report["establishment_checkpoint"]["establishment_registry"]) {
        if (
          report["establishment_checkpoint"]["establishment_registry"][
            "establishment"
          ]
        ) {
          return report["establishment_checkpoint"]["establishment_registry"][
            "establishment"
          ]["name"];
        } else {
          return "";
        }
      } else {
        return "";
      }
    } else {
      return "";
    }
  }
  getEstablishmentNameObservation(observation) {
    if (observation["report"]) {
      if (observation["report"]["establishment_checkpoint"]) {
        if (
          observation["report"]["establishment_checkpoint"][
            "establishment_registry"
          ]
        ) {
          if (
            observation["report"]["establishment_checkpoint"][
              "establishment_registry"
            ]["establishment"]
          ) {
            return observation["report"]["establishment_checkpoint"][
              "establishment_registry"
            ]["establishment"]["name"];
          } else {
            return "";
          }
        } else {
          return "";
        }
      } else {
        return "";
      }
    } else {
      return "";
    }
  }
  getEstablishmentNameCheckpoints(checkpoint) {
    if (checkpoint["establishment_registry"]) {
      if (checkpoint["establishment_registry"]["establishment"]) {
        return checkpoint["establishment_registry"]["establishment"]["name"];
      } else {
        return "";
      }
    } else {
      return "";
    }
  }
  getUrlReport(report) {
    return (
      "/dashboard-registry/" +
      report["establishment_checkpoint"]["establishment_registry"][
        "registry_id"
      ] +
      "/establishment/" +
      report["establishment_checkpoint"]["establishment_registry"][
        "establishment_id"
      ] +
      "/installations/" +
      report["establishment_checkpoint"]["establishment_installation_id"] +
      "/checkpoints/" +
      report["establishment_checkpoint"]["id"] +
      "/reports/" +
      report["id"]
    );
  }
  getUrlObservation(observation) {
    return (
      "/dashboard-registry/" +
      observation["report"]["establishment_checkpoint"][
        "establishment_registry"
      ]["registry_id"] +
      "/establishment/" +
      observation["report"]["establishment_checkpoint"][
        "establishment_registry"
      ]["establishment_id"] +
      "/installations/" +
      observation["report"]["establishment_checkpoint"][
        "establishment_installation_id"
      ] +
      "/checkpoints/" +
      observation["report"]["establishment_checkpoint"]["id"] +
      "/reports/" +
      observation["report_id"] +
      "/observations/" +
      observation["id"]
    );
  }
  getUrlCheckpoint(checkpoint) {
    return (
      "/dashboard-registry/" +
      checkpoint["establishment_registry"]["registry_id"] +
      "/establishment/" +
      checkpoint["establishment_registry"]["establishment_id"] +
      "/installations/" +
      checkpoint["establishment_installation_id"] +
      "/checkpoints/" +
      checkpoint["id"]
    );
  }

  // -------------------- OPEN MODAL FOR CLICK ON STATS --------------------
  open(content) {
    if (content === "allObservations") {
      this.allObservationsPage = 1;
      this.getAllObservations(content);
    } else if (content === "observationsState") {
      this.observationsStatePage = 1;
      this.getObservationsState(content);
    } else if (content === "observationsNotState") {
      this.observationsNotStatePage = 1;
      this.getObservationsNotState(content);
    } else if (content === "allReports") {
      this.reportsPage = 1;
      this.getReports(content);
    } else if (content === "observationsLate") {
      this.observationsLatePage = 1;
      this.getObservationsLate(content);
    } else if (content === "observationsInTime") {
      this.observationsInTimePage = 1;
      this.getObservationsInTime(content);
    } else if (content === "observationsCheckpointsLate") {
      this.observationsCheckpointsLatePages = 1;
      this.getObservationsCheckpointsLate(content);
    }
  }
  // -------------------- GET ALL OBSERVATION + PAGINATION --------------------
  filterAllObservations(property) {
    if (this.allObservationsSort === property) {
      if (this.allObservationsOrder === "asc") {
        this.allObservationsOrder = "desc";
      } else {
        this.allObservationsOrder = "asc";
      }
    } else {
      this.allObservationsSort = property;
      this.allObservationsOrder = "asc";
    }

    this.getAllObservations("allObservations");
  }
  getAllObservations(content) {
    this.requestInProgress.next(true);
    let installations = [];
    if (this.selectedRegistry !== 5) {
      installations = this.selectedInstallation;
    } else {
      installations = this.selectedInstallationCustom;
    }
    this.statisticsService
      .obsStat(
        installations,
        this.dateFrom,
        this.dateTo,
        this.selectedCheckpoint,
        this.selectedEstablishments,
        this.allObservationsSort,
        this.allObservationsOrder,
        this.allObservationsLimit,
        this.allObservationsPage,
        this.selectedRegistry,
        "allObservations"
      )
      .subscribe((response) => {
        if (response["success"]) {
          this.allObservations = response["allObservations"];
          this.allObservationsCount = response["allObservationsCount"];
          this.allObservationsPages = response["allObservationsPages"];
          this.modal = $(this._rootNode.nativeElement).find("div#" + content);
          this.modal.modal("show");
        } else if (response["errors"]) {
          CustomNotifierService.getErrors(response["errors"], this.notifier);
        }
        this.requestInProgress.next(false);
      });
  }
  dispatchPaginationAll(data) {
    if (data.action === "total_elements") {
      this.refreshPageAll(data.value);
    } else if (data.action === "change_page") {
      this.changePageAll(data.value);
    }
  }
  refreshPageAll(limit) {
    this.allObservationsLimit = limit;
    this.allObservationsPage = 1;
    this.getAllObservations("allObservations");
  }
  changePageAll(page) {
    if (page >= 1 && page <= this.allObservationsPages) {
      this.allObservationsPage = page;
      this.getAllObservations("allObservations");
    }
  }

  // -------------------- GET ALL OBSERVATION STATE + PAGINATION --------------------
  filterObservationsState(property) {
    if (this.observationsStateSort === property) {
      if (this.observationsStateOrder === "asc") {
        this.observationsStateOrder = "desc";
      } else {
        this.observationsStateOrder = "asc";
      }
    } else {
      this.observationsStateSort = property;
      this.observationsStateOrder = "asc";
    }

    this.getObservationsState("observationsState");
  }
  getObservationsState(content) {
    this.requestInProgress.next(true);
    let installations = [];
    if (this.selectedRegistry !== 5) {
      installations = this.selectedInstallation;
    } else {
      installations = this.selectedInstallationCustom;
    }
    this.statisticsService
      .obsStat(
        installations,
        this.dateFrom,
        this.dateTo,
        this.selectedCheckpoint,
        this.selectedEstablishments,
        this.observationsStateSort,
        this.observationsStateOrder,
        this.observationsStateLimit,
        this.observationsStatePage,
        this.selectedRegistry,
        "observationsState"
      )
      .subscribe((response) => {
        if (response["success"]) {
          this.observationsState = response["observationsState"];
          this.observationsStateCount = response["observationsStateCount"];
          this.observationsStatePages = response["observationsStatePages"];
          this.modal = $(this._rootNode.nativeElement).find("div#" + content);
          this.modal.modal("show");
        } else if (response["errors"]) {
          CustomNotifierService.getErrors(response["errors"], this.notifier);
        }
        this.requestInProgress.next(false);
      });
  }
  dispatchPaginationState(data) {
    if (data.action === "total_elements") {
      this.refreshPageState(data.value);
    } else if (data.action === "change_page") {
      this.changePageState(data.value);
    }
  }
  refreshPageState(limit) {
    this.observationsStateLimit = limit;
    this.observationsStatePage = 1;
    this.getObservationsState("observationsState");
  }
  changePageState(page) {
    if (page >= 1 && page <= this.observationsStatePages) {
      this.observationsStatePage = page;
      this.getObservationsState("observationsState");
    }
  }

  // -------------------- GET ALL OBSERVATION NOT STATE + PAGINATION --------------------
  filterObservationsNotState(property) {
    if (this.observationsNotStateSort === property) {
      if (this.observationsNotStateOrder === "asc") {
        this.observationsNotStateOrder = "desc";
      } else {
        this.observationsNotStateOrder = "asc";
      }
    } else {
      this.observationsNotStateSort = property;
      this.observationsNotStateOrder = "asc";
    }

    this.getObservationsNotState("observationsNotState");
  }
  getObservationsNotState(content) {
    this.requestInProgress.next(true);
    let installations = [];
    if (this.selectedRegistry !== 5) {
      installations = this.selectedInstallation;
    } else {
      installations = this.selectedInstallationCustom;
    }
    this.statisticsService
      .obsStat(
        installations,
        this.dateFrom,
        this.dateTo,
        this.selectedCheckpoint,
        this.selectedEstablishments,
        this.observationsNotStateSort,
        this.observationsNotStateOrder,
        this.observationsNotStateLimit,
        this.observationsNotStatePage,
        this.selectedRegistry,
        "observationsNotState"
      )
      .subscribe((response) => {
        if (response["success"]) {
          this.observationsNotState = response["observationsNotState"];
          this.observationsNotStateCount =
            response["observationsNotStateCount"];
          this.observationsNotStatePages =
            response["observationsNotStatePages"];
          this.modal = $(this._rootNode.nativeElement).find("div#" + content);
          this.modal.modal("show");
        } else if (response["errors"]) {
          CustomNotifierService.getErrors(response["errors"], this.notifier);
        }
        this.requestInProgress.next(false);
      });
  }
  dispatchPaginationNotState(data) {
    if (data.action === "total_elements") {
      this.refreshPageNotState(data.value);
    } else if (data.action === "change_page") {
      this.changePageNotState(data.value);
    }
  }
  refreshPageNotState(limit) {
    this.observationsNotStateLimit = limit;
    this.observationsNotStatePage = 1;
    this.getObservationsNotState("observationsNotState");
  }
  changePageNotState(page) {
    if (page >= 1 && page <= this.observationsNotStatePages) {
      this.observationsNotStatePage = page;
      this.getObservationsNotState("observationsNotState");
    }
  }

  // -------------------- GET ALL REPORT NOT STATE + PAGINATION --------------------
  filterReports(property) {
    if (this.reportsSort === property) {
      if (this.reportsOrder === "asc") {
        this.reportsOrder = "desc";
      } else {
        this.reportsOrder = "asc";
      }
    } else {
      this.reportsSort = property;
      this.reportsOrder = "asc";
    }

    this.getReports("allReports");
  }
  getReports(content) {
    this.requestInProgress.next(true);
    let installations = [];
    if (this.selectedRegistry !== 5) {
      installations = this.selectedInstallation;
    } else {
      installations = this.selectedInstallationCustom;
    }
    this.statisticsService
      .obsStat(
        installations,
        this.dateFrom,
        this.dateTo,
        this.selectedCheckpoint,
        this.selectedEstablishments,
        this.reportsSort,
        this.reportsOrder,
        this.reportsLimit,
        this.reportsPage,
        this.selectedRegistry,
        "checkpoints"
      )
      .subscribe((response) => {
        if (response["success"]) {
          this.reports = response["checkpoints"];
          this.reportsCount = response["checkpointsCount"];
          this.reportsPages = response["checkpointsPages"];
          this.modal = $(this._rootNode.nativeElement).find("div#" + content);
          this.modal.modal("show");
        } else if (response["errors"]) {
          CustomNotifierService.getErrors(response["errors"], this.notifier);
        }
        this.requestInProgress.next(false);
      });
  }
  dispatchPaginationReport(data) {
    if (data.action === "total_elements") {
      this.refreshPageReport(data.value);
    } else if (data.action === "change_page") {
      this.changePageReport(data.value);
    }
  }
  refreshPageReport(limit) {
    this.reportsLimit = limit;
    this.reportsPage = 1;
    this.getReports("allReports");
  }
  changePageReport(page) {
    if (page >= 1 && page <= this.reportsPages) {
      this.reportsPage = page;
      this.getReports("allReports");
    }
  }

  // -------------------- GET ALL EMERGENCY OBSERVATION NC NOT STATE + PAGINATION --------------------
  filterObservationsNC(property) {
    if (this.observationsNCSort === property) {
      if (this.observationsNCOrder === "asc") {
        this.observationsNCOrder = "desc";
      } else {
        this.observationsNCOrder = "asc";
      }
    } else {
      this.observationsNCSort = property;
      this.observationsNCOrder = "asc";
    }

    this.getObservationsNC("observationsNC");
  }
  getObservationsNC(content) {
    this.requestInProgress.next(true);
    let installations = [];
    if (this.selectedRegistry !== 5) {
      installations = this.selectedInstallation;
    } else {
      installations = this.selectedInstallationCustom;
    }
    this.statisticsService
      .getEmergenciesObservations(
        installations,
        this.dateFrom,
        this.dateTo,
        this.selectedCheckpoint,
        this.selectedEstablishments,
        this.observationsNCSort,
        this.observationsNCOrder,
        this.observationsNCLimit,
        this.observationsNCPage,
        this.selectedRegistry,
        "NC"
      )
      .subscribe((response) => {
        if (response["success"]) {
          this.observationsNC = response["observationsNC"];
          this.observationsNCCount = response["observationsNCCount"];
          this.observationsNCPages = response["observationsNCPages"];
          this.modal = $(this._rootNode.nativeElement).find("div#" + content);
          this.modal.modal("show");
        } else if (response["errors"]) {
          CustomNotifierService.getErrors(response["errors"], this.notifier);
        }
        this.requestInProgress.next(false);
      });
  }
  dispatchPaginationNC(data) {
    if (data.action === "total_elements") {
      this.refreshPageNC(data.value);
    } else if (data.action === "change_page") {
      this.changePageNC(data.value);
    }
  }
  refreshPageNC(limit) {
    this.observationsNCLimit = limit;
    this.observationsNCPage = 1;
    this.getObservationsNC("observationsNC");
  }
  changePageNC(page) {
    if (page >= 1 && page <= this.observationsNCPages) {
      this.observationsNCPage = page;
      this.getObservationsNC("observationsNC");
    }
  }

  // -------------------- GET ALL EMERGENCY OBSERVATION LOW NOT STATE + PAGINATION --------------------
  filterObservationsLow(property) {
    if (this.observationsLowSort === property) {
      if (this.observationsLowOrder === "asc") {
        this.observationsLowOrder = "desc";
      } else {
        this.observationsLowOrder = "asc";
      }
    } else {
      this.observationsLowSort = property;
      this.observationsLowOrder = "asc";
    }

    this.getObservationsLow("observationsLow");
  }
  getObservationsLow(content) {
    this.requestInProgress.next(true);
    let installations = [];
    if (this.selectedRegistry !== 5) {
      installations = this.selectedInstallation;
    } else {
      installations = this.selectedInstallationCustom;
    }
    this.statisticsService
      .getEmergenciesObservations(
        installations,
        this.dateFrom,
        this.dateTo,
        this.selectedCheckpoint,
        this.selectedEstablishments,
        this.observationsLowSort,
        this.observationsLowOrder,
        this.observationsLowLimit,
        this.observationsLowPage,
        this.selectedRegistry,
        "low"
      )
      .subscribe((response) => {
        if (response["success"]) {
          this.observationsLow = response["observationsLow"];
          this.observationsLowCount = response["observationsLowCount"];
          this.observationsLowPages = response["observationsLowPages"];
          this.modal = $(this._rootNode.nativeElement).find("div#" + content);
          this.modal.modal("show");
        } else if (response["errors"]) {
          CustomNotifierService.getErrors(response["errors"], this.notifier);
        }
        this.requestInProgress.next(false);
      });
  }
  dispatchPaginationLow(data) {
    if (data.action === "total_elements") {
      this.refreshPageLow(data.value);
    } else if (data.action === "change_page") {
      this.changePageLow(data.value);
    }
  }
  refreshPageLow(limit) {
    this.observationsLowLimit = limit;
    this.observationsLowPage = 1;
    this.getObservationsLow("observationsLow");
  }
  changePageLow(page) {
    if (page >= 1 && page <= this.observationsLowPages) {
      this.observationsLowPage = page;
      this.getObservationsLow("observationsLow");
    }
  }

  // -------------------- GET ALL EMERGENCY OBSERVATION NORMAL NOT STATE + PAGINATION --------------------
  filterObservationsNormal(property) {
    if (this.observationsNormalSort === property) {
      if (this.observationsNormalOrder === "asc") {
        this.observationsNormalOrder = "desc";
      } else {
        this.observationsNormalOrder = "asc";
      }
    } else {
      this.observationsNormalSort = property;
      this.observationsNormalOrder = "asc";
    }

    this.getObservationsNormal("observationsNormal");
  }
  getObservationsNormal(content) {
    this.requestInProgress.next(true);
    let installations = [];
    if (this.selectedRegistry !== 5) {
      installations = this.selectedInstallation;
    } else {
      installations = this.selectedInstallationCustom;
    }
    this.statisticsService
      .getEmergenciesObservations(
        installations,
        this.dateFrom,
        this.dateTo,
        this.selectedCheckpoint,
        this.selectedEstablishments,
        this.observationsNormalSort,
        this.observationsNormalOrder,
        this.observationsNormalLimit,
        this.observationsNormalPage,
        this.selectedRegistry,
        "normal"
      )
      .subscribe((response) => {
        if (response["success"]) {
          this.observationsNormal = response["observationsNormal"];
          this.observationsNormalCount = response["observationsNormalCount"];
          this.observationsNormalPages = response["observationsNormalPages"];
          this.modal = $(this._rootNode.nativeElement).find("div#" + content);
          this.modal.modal("show");
        } else if (response["errors"]) {
          CustomNotifierService.getErrors(response["errors"], this.notifier);
        }
        this.requestInProgress.next(false);
      });
  }
  dispatchPaginationNormal(data) {
    if (data.action === "total_elements") {
      this.refreshPageNormal(data.value);
    } else if (data.action === "change_page") {
      this.changePageNormal(data.value);
    }
  }
  refreshPageNormal(limit) {
    this.observationsNormalLimit = limit;
    this.observationsNormalPage = 1;
    this.getObservationsNormal("observationsNormal");
  }
  changePageNormal(page) {
    if (page >= 1 && page <= this.observationsNormalPages) {
      this.observationsNormalPage = page;
      this.getObservationsNormal("observationsNormal");
    }
  }

  // -------------------- GET ALL EMERGENCY OBSERVATION HIGHER NOT STATE + PAGINATION --------------------
  filterObservationsHigher(property) {
    if (this.observationsHigherSort === property) {
      if (this.observationsHigherOrder === "asc") {
        this.observationsHigherOrder = "desc";
      } else {
        this.observationsHigherOrder = "asc";
      }
    } else {
      this.observationsHigherSort = property;
      this.observationsHigherOrder = "asc";
    }

    this.getObservationsHigher("observationsHigher");
  }
  getObservationsHigher(content) {
    this.requestInProgress.next(true);
    let installations = [];
    if (this.selectedRegistry !== 5) {
      installations = this.selectedInstallation;
    } else {
      installations = this.selectedInstallationCustom;
    }
    this.statisticsService
      .getEmergenciesObservations(
        installations,
        this.dateFrom,
        this.dateTo,
        this.selectedCheckpoint,
        this.selectedEstablishments,
        this.observationsHigherSort,
        this.observationsHigherOrder,
        this.observationsHigherLimit,
        this.observationsHigherPage,
        this.selectedRegistry,
        "higher"
      )
      .subscribe((response) => {
        if (response["success"]) {
          this.observationsHigher = response["observationsHigher"];
          this.observationsHigherCount = response["observationsHigherCount"];
          this.observationsHigherPages = response["observationsHigherPages"];
          this.modal = $(this._rootNode.nativeElement).find("div#" + content);
          this.modal.modal("show");
        } else if (response["errors"]) {
          CustomNotifierService.getErrors(response["errors"], this.notifier);
        }
        this.requestInProgress.next(false);
      });
  }
  dispatchPaginationHigher(data) {
    if (data.action === "total_elements") {
      this.refreshPageHigher(data.value);
    } else if (data.action === "change_page") {
      this.changePageHigher(data.value);
    }
  }
  refreshPageHigher(limit) {
    this.observationsHigherLimit = limit;
    this.observationsHigherPage = 1;
    this.getObservationsHigher("observationsHigher");
  }
  changePageHigher(page) {
    if (page >= 1 && page <= this.observationsHigherPages) {
      this.observationsHigherPage = page;
      this.getObservationsHigher("observationsHigher");
    }
  }

  // -------------------- GET ALL EMERGENCY OBSERVATION PRIORITY NOT STATE + PAGINATION --------------------
  filterObservationsPriority(property) {
    if (this.observationsPrioritySort === property) {
      if (this.observationsPriorityOrder === "asc") {
        this.observationsPriorityOrder = "desc";
      } else {
        this.observationsPriorityOrder = "asc";
      }
    } else {
      this.observationsPrioritySort = property;
      this.observationsPriorityOrder = "asc";
    }

    this.getObservationsPriority("observationsPriority");
  }
  getObservationsPriority(content) {
    this.requestInProgress.next(true);
    let installations = [];
    if (this.selectedRegistry !== 5) {
      installations = this.selectedInstallation;
    } else {
      installations = this.selectedInstallationCustom;
    }
    this.statisticsService
      .getEmergenciesObservations(
        installations,
        this.dateFrom,
        this.dateTo,
        this.selectedCheckpoint,
        this.selectedEstablishments,
        this.observationsPrioritySort,
        this.observationsPriorityOrder,
        this.observationsPriorityLimit,
        this.observationsPriorityPage,
        this.selectedRegistry,
        "priority"
      )
      .subscribe((response) => {
        if (response["success"]) {
          this.observationsPriority = response["observationsPriority"];
          this.observationsPriorityCount =
            response["observationsPriorityCount"];
          this.observationsPriorityPages =
            response["observationsPriorityPages"];
          this.modal = $(this._rootNode.nativeElement).find("div#" + content);
          this.modal.modal("show");
        } else if (response["errors"]) {
          CustomNotifierService.getErrors(response["errors"], this.notifier);
        }
        this.requestInProgress.next(false);
      });
  }
  dispatchPaginationPriority(data) {
    if (data.action === "total_elements") {
      this.refreshPagePriority(data.value);
    } else if (data.action === "change_page") {
      this.changePagePriority(data.value);
    }
  }
  refreshPagePriority(limit) {
    this.observationsPriorityLimit = limit;
    this.observationsPriorityPage = 1;
    this.getObservationsPriority("observationsPriority");
  }
  changePagePriority(page) {
    if (page >= 1 && page <= this.observationsPriorityPages) {
      this.observationsPriorityPage = page;
      this.getObservationsPriority("observationsPriority");
    }
  }

  // -------------------- GET ALL OBSERVATION LATE + PAGINATION --------------------
  filterObservationsLate(property) {
    if (this.observationsLateSort === property) {
      if (this.observationsLateOrder === "asc") {
        this.observationsLateOrder = "desc";
      } else {
        this.observationsLateOrder = "asc";
      }
    } else {
      this.observationsLateSort = property;
      this.observationsLateOrder = "asc";
    }

    this.getObservationsLate("observationsLate");
  }
  getObservationsLate(content) {
    this.requestInProgress.next(true);
    let installations = [];
    if (this.selectedRegistry !== 5) {
      installations = this.selectedInstallation;
    } else {
      installations = this.selectedInstallationCustom;
    }
    this.statisticsService
      .getDelayObservations(
        installations,
        this.dateFrom,
        this.dateTo,
        this.selectedCheckpoint,
        this.selectedEstablishments,
        this.observationsLateSort,
        this.observationsLateOrder,
        this.observationsLateLimit,
        this.observationsLatePage,
        this.selectedRegistry,
        "late"
      )
      .subscribe((response) => {
        if (response["success"]) {
          this.observationsLate = response["observationsLate"];
          this.observationsLateCount = response["observationsLateCount"];
          this.observationsLatePages = response["observationsLatePages"];
          this.modal = $(this._rootNode.nativeElement).find("div#" + content);
          this.modal.modal("show");
        } else if (response["errors"]) {
          CustomNotifierService.getErrors(response["errors"], this.notifier);
        }
        this.requestInProgress.next(false);
      });
  }
  dispatchPaginationLate(data) {
    if (data.action === "total_elements") {
      this.refreshPageLate(data.value);
    } else if (data.action === "change_page") {
      this.changePageLate(data.value);
    }
  }
  refreshPageLate(limit) {
    this.observationsLateLimit = limit;
    this.observationsLatePage = 1;
    this.getObservationsLate("observationsLate");
  }
  changePageLate(page) {
    if (page >= 1 && page <= this.observationsLatePages) {
      this.observationsLatePage = page;
      this.getObservationsLate("observationsLate");
    }
  }

  // -------------------- GET ALL OBSERVATION IN TIME + PAGINATION --------------------
  filterObservationsInTime(property) {
    if (this.observationsInTimeSort === property) {
      if (this.observationsInTimeOrder === "asc") {
        this.observationsInTimeOrder = "desc";
      } else {
        this.observationsInTimeOrder = "asc";
      }
    } else {
      this.observationsInTimeSort = property;
      this.observationsInTimeOrder = "asc";
    }

    this.getObservationsInTime("observationsInTime");
  }
  getObservationsInTime(content) {
    this.requestInProgress.next(true);
    let installations = [];
    if (this.selectedRegistry !== 5) {
      installations = this.selectedInstallation;
    } else {
      installations = this.selectedInstallationCustom;
    }
    this.statisticsService
      .getDelayObservations(
        installations,
        this.dateFrom,
        this.dateTo,
        this.selectedCheckpoint,
        this.selectedEstablishments,
        this.observationsInTimeSort,
        this.observationsInTimeOrder,
        this.observationsInTimeLimit,
        this.observationsInTimePage,
        this.selectedRegistry,
        "inTime"
      )
      .subscribe((response) => {
        if (response["success"]) {
          this.observationsInTime = response["observationsInTime"];
          this.observationsInTimeCount = response["observationsInTimeCount"];
          this.observationsInTimePages = response["observationsInTimePages"];
          this.modal = $(this._rootNode.nativeElement).find("div#" + content);
          this.modal.modal("show");
        } else if (response["errors"]) {
          CustomNotifierService.getErrors(response["errors"], this.notifier);
        }
        this.requestInProgress.next(false);
      });
  }
  dispatchPaginationInTime(data) {
    if (data.action === "total_elements") {
      this.refreshPageInTime(data.value);
    } else if (data.action === "change_page") {
      this.changePageInTime(data.value);
    }
  }
  refreshPageInTime(limit) {
    this.observationsInTimeLimit = limit;
    this.observationsInTimePage = 1;
    this.getObservationsInTime("observationsInTime");
  }
  changePageInTime(page) {
    if (page >= 1 && page <= this.observationsInTimePages) {
      this.observationsInTimePage = page;
      this.getObservationsInTime("observationsInTime");
    }
  }

  // -------------------- GET ALL OBSERVATION CHECKPOINTS LATE + PAGINATION --------------------
  filterObservationsCheckpointsLate(property) {
    if (this.observationsCheckpointsLateSort === property) {
      if (this.observationsCheckpointsLateOrder === "asc") {
        this.observationsCheckpointsLateOrder = "desc";
      } else {
        this.observationsCheckpointsLateOrder = "asc";
      }
    } else {
      this.observationsCheckpointsLateSort = property;
      this.observationsCheckpointsLateOrder = "asc";
    }

    this.getObservationsCheckpointsLate("observationsCheckpointsLate");
  }
  getObservationsCheckpointsLate(content) {
    this.requestInProgress.next(true);
    let installations = [];
    if (this.selectedRegistry !== 5) {
      installations = this.selectedInstallation;
    } else {
      installations = this.selectedInstallationCustom;
    }
    this.statisticsService
      .getDelayObservationsCheckpoints(
        installations,
        this.dateFrom,
        this.dateTo,
        this.selectedCheckpoint,
        this.selectedEstablishments,
        this.observationsCheckpointsLateSort,
        this.observationsCheckpointsLateOrder,
        this.observationsCheckpointsLateLimit,
        this.observationsCheckpointsLatePage,
        this.selectedRegistry,
        "checkpointsLate"
      )
      .subscribe((response) => {
        if (response["success"]) {
          this.observationsCheckpointsLate = response["checkpointsLate"];
          this.observationsCheckpointsLateCount =
            response["checkpointsLateCount"];
          this.observationsCheckpointsLatePages =
            response["checkpointsLatePages"];
          this.modal = $(this._rootNode.nativeElement).find("div#" + content);
          this.modal.modal("show");
        } else if (response["errors"]) {
          CustomNotifierService.getErrors(response["errors"], this.notifier);
        }
        this.requestInProgress.next(false);
      });
  }
  dispatchPaginationCheckpointsLate(data) {
    if (data.action === "total_elements") {
      this.refreshPageCheckpointsLate(data.value);
    } else if (data.action === "change_page") {
      this.changePageCheckpointsLate(data.value);
    }
  }
  refreshPageCheckpointsLate(limit) {
    this.observationsCheckpointsLateLimit = limit;
    this.observationsCheckpointsLatePage = 1;
    this.getObservationsCheckpointsLate("observationsCheckpointsLate");
  }
  changePageCheckpointsLate(page) {
    if (page >= 1 && page <= this.observationsCheckpointsLatePages) {
      this.observationsCheckpointsLatePage = page;
      this.getObservationsCheckpointsLate("observationsCheckpointsLate");
    }
  }

  // -------------------- GET ALL OBSERVATION CHECKPOINTS + PAGINATION --------------------
  filterObservationsAllCheckpoints(property) {
    if (this.observationsAllCheckpointsSort === property) {
      if (this.observationsAllCheckpointsOrder === "asc") {
        this.observationsAllCheckpointsOrder = "desc";
      } else {
        this.observationsAllCheckpointsOrder = "asc";
      }
    } else {
      this.observationsAllCheckpointsSort = property;
      this.observationsAllCheckpointsOrder = "asc";
    }

    this.getObservationsCheckpoints("observationsAllCheckpoints");
  }
  getObservationsCheckpoints(content) {
    this.requestInProgress.next(true);
    let installations = [];
    if (this.selectedRegistry !== 5) {
      installations = this.selectedInstallation;
    } else {
      installations = this.selectedInstallationCustom;
    }
    this.statisticsService
      .getDelayObservationsCheckpoints(
        installations,
        this.dateFrom,
        this.dateTo,
        this.selectedCheckpoint,
        this.selectedEstablishments,
        this.observationsAllCheckpointsSort,
        this.observationsAllCheckpointsOrder,
        this.observationsAllCheckpointsLimit,
        this.observationsAllCheckpointsPage,
        this.selectedRegistry,
        "allCheckpoints"
      )
      .subscribe((response) => {
        if (response["success"]) {
          this.observationsAllCheckpoints = response["allCheckpoints"];
          this.observationsAllCheckpointsCount =
            response["allCheckpointsCount"];
          this.observationsAllCheckpointsPages =
            response["allCheckpointsPages"];
          this.modal = $(this._rootNode.nativeElement).find("div#" + content);
          this.modal.modal("show");
        } else if (response["errors"]) {
          CustomNotifierService.getErrors(response["errors"], this.notifier);
        }
        this.requestInProgress.next(false);
      });
  }
  dispatchPaginationCheckpoints(data) {
    if (data.action === "total_elements") {
      this.refreshPageCheckpoints(data.value);
    } else if (data.action === "change_page") {
      this.changePageCheckpoints(data.value);
    }
  }
  refreshPageCheckpoints(limit) {
    this.observationsAllCheckpointsLimit = limit;
    this.observationsAllCheckpointsPage = 1;
    this.getObservationsCheckpoints("observationsAllCheckpoints");
  }
  changePageCheckpoints(page) {
    if (page >= 1 && page <= this.observationsAllCheckpointsPages) {
      this.observationsAllCheckpointsPage = page;
      this.getObservationsCheckpoints("observationsAllCheckpoints");
    }
  }

  // -------------------- COMPARATIVE ESTABLISHMENTS --------------------
  updateSelectedEstablishmentA() {
    if (this.selectedEstablishmentA && this.selectedEstablishmentB) {
      this.selectedEstablishments = [];
      this.selectedEstablishments.push(this.selectedEstablishmentA);
      this.selectedEstablishments.push(this.selectedEstablishmentB);
      this.getComparativeCheckpoints();
      this.getObservationsStatsDay();
      this.getObservationsStatsCosts();
    }
    this.registryInstallations = [];
    this.selectedInstallationCustom = [];
    this.selectedCheckpoints = [];
    this.selectedCheckpoint = null;
    this.selectedInstallation = [];
    this.selectedEstablishments = [];
  }
  updateSelectedEstablishmentB() {
    if (this.selectedEstablishmentA && this.selectedEstablishmentB) {
      this.selectedEstablishments = [];
      this.selectedEstablishments.push(this.selectedEstablishmentA);
      this.selectedEstablishments.push(this.selectedEstablishmentB);
      this.getComparativeCheckpoints();
      this.getObservationsStatsDay();
      this.getObservationsStatsCosts();
    }
    this.registryInstallations = [];
    this.selectedInstallationCustom = [];
    this.selectedCheckpoints = [];
    this.selectedCheckpoint = null;
    this.selectedInstallation = [];
    this.selectedEstablishments = [];
  }
  updateRegistryComparative() {
    this.registryInstallations = [];
    this.selectedInstallationCustom = [];
    this.selectedCheckpoints = [];
    this.selectedCheckpoint = null;
    this.selectedInstallation = [];
    this.selectedEstablishments = [];

    this.selectedEstablishments.push(this.selectedEstablishmentA);
    this.selectedEstablishments.push(this.selectedEstablishmentB);

    if (this.selectedRegistry !== 5) {
      this.getInstallations();
      const tempCheckpoints = [];

      if (!this.selectedRegistry && !this.checkpoints) {
        return [];
      }
      for (let i = 0; i < this.checkpoints.length; i++) {
        if (this.checkpoints[i]["registry_id"] === this.selectedRegistry) {
          tempCheckpoints.push(this.checkpoints[i]);
        }
      }
      this.installations.forEach((installation) => {
        const found = tempCheckpoints.findIndex((checkpoint) => {
          return checkpoint.installation_id === installation.id;
        });
        if (found !== -1) {
          this.registryInstallations.push(installation);
        }
      });
    } else {
      this.getInstallationsCustom();
    }
    this.getComparativeCheckpoints();
    this.getObservationsStatsDay();
    this.getObservationsStatsCosts();
  }
  updateInstallationComparative() {
    this.getComparativeCheckpoints();
    this.getObservationsStatsDay();
    this.getObservationsStatsCosts();
  }
  updateInstallationCustomComparative() {
    this.getComparativeCheckpoints();
    this.getObservationsStatsDay();
    this.getObservationsStatsCosts();
  }
  updatePeriodComparative(event = null) {
    let update = false;
    if (this.period === "days") {
      this.dateFrom = moment().subtract(7, "day").format("YYYY-MM-DD");
      this.dateTo = moment().format("YYYY-MM-DD");
      update = true;
    } else if (this.period === "month") {
      this.dateFrom = moment().subtract(1, "month").format("YYYY-MM-DD");
      this.dateTo = moment().format("YYYY-MM-DD");
      update = true;
    } else if (this.period === "months") {
      this.dateFrom = moment().subtract(3, "month").format("YYYY-MM-DD");
      this.dateTo = moment().format("YYYY-MM-DD");
      update = true;
    } else if (this.period === "year") {
      this.dateFrom = moment().startOf("year").format("YYYY-MM-DD");
      this.dateTo = moment().endOf("year").format("YYYY-MM-DD");
      update = true;
    } else if (this.period === "lastyear") {
      this.dateFrom = moment()
        .startOf("year")
        .subtract(1, "year")
        .format("YYYY-MM-DD");
      this.dateTo = moment()
        .endOf("year")
        .subtract(1, "year")
        .format("YYYY-MM-DD");
      update = true;
    } else if (this.period === "lastyears") {
      this.dateFrom = moment()
        .startOf("year")
        .subtract(2, "year")
        .format("YYYY-MM-DD");
      this.dateTo = moment()
        .endOf("year")
        .subtract(2, "year")
        .format("YYYY-MM-DD");
      update = true;
    }
    if (event === null) {
      this.dateFrom = null;
      this.dateTo = null;
      update = true;
    }
    if (update) {
      this.getComparativeCheckpoints();
      this.getObservationsStatsDay();
      this.getObservationsStatsCosts();
    }
  }
  updateDateComparative() {
    this.period = "custom";
    this.getComparativeCheckpoints();
    this.getObservationsStatsDay();
    this.getObservationsStatsCosts();
  }
  getComparativeCheckpoints() {
    if (this.selectedEstablishmentA && this.selectedEstablishmentB) {
      this.loaderObservationsComparativeChart = true;
      let installations = [];
      if (this.selectedRegistry !== 5) {
        installations = this.selectedInstallation;
      } else {
        installations = this.selectedInstallationCustom;
      }
      this.statisticsService
        .getDelayObservationsCheckpoints(
          installations,
          this.dateFrom,
          this.dateTo,
          this.selectedCheckpoint,
          this.selectedEstablishmentA,
          "",
          "",
          "",
          "",
          this.selectedRegistry
        )
        .subscribe((response) => {
          if (response["success"]) {
            this.totalCheckpointsA = response["allCheckpointsCount"];
            this.checkpointsALate = response["checkpointsLateCount"];
            this.statisticsService
              .getDelayObservationsCheckpoints(
                installations,
                this.dateFrom,
                this.dateTo,
                this.selectedCheckpoint,
                this.selectedEstablishmentB,
                "",
                "",
                "",
                "",
                this.selectedRegistry
              )
              .subscribe((result) => {
                if (result["success"]) {
                  this.totalCheckpointsB = result["allCheckpointsCount"];
                  this.checkpointsBLate = result["checkpointsLateCount"];
                  this.getComparativeObservations();
                } else if (result["errors"]) {
                  CustomNotifierService.getErrors(
                    result["errors"],
                    this.notifier
                  );
                }
              });
          } else if (response["errors"]) {
            CustomNotifierService.getErrors(response["errors"], this.notifier);
          }
          this.loaderObservationsComparativeChart = false;
        });
    }
  }
  getComparativeObservations() {
    if (this.selectedEstablishmentA && this.selectedEstablishmentB) {
      this.loaderObservationsComparativeChart = true;
      let installations = [];
      if (this.selectedRegistry !== 5) {
        installations = this.selectedInstallation;
      } else {
        installations = this.selectedInstallationCustom;
      }
      this.statisticsService
        .obsStat(
          installations,
          this.dateFrom,
          this.dateTo,
          this.selectedCheckpoint,
          this.selectedEstablishmentA,
          "",
          "",
          "",
          "",
          this.selectedRegistry
        )
        .subscribe((response) => {
          if (response["success"]) {
            this.totalObservationsA = response["allObservationsCount"];
            this.observationsALate = response["observationsNotStateCount"];
            this.statisticsService
              .obsStat(
                installations,
                this.dateFrom,
                this.dateTo,
                this.selectedCheckpoint,
                this.selectedEstablishmentB,
                "",
                "",
                "",
                "",
                this.selectedRegistry
              )
              .subscribe((result) => {
                if (result["success"]) {
                  this.totalObservationsB = result["allObservationsCount"];
                  this.observationsBLate = result["observationsNotStateCount"];
                  this.userChart.createObservationsComparativeChart(
                    "#createObservationsComparative",
                    this.totalCheckpointsA,
                    this.checkpointsALate,
                    this.totalCheckpointsB,
                    this.checkpointsBLate,
                    this.totalObservationsA,
                    this.observationsALate,
                    this.totalObservationsB,
                    this.observationsBLate,
                    this.selectedEstablishmentA,
                    this.selectedEstablishmentB,
                    this
                  );
                } else if (result["errors"]) {
                  CustomNotifierService.getErrors(
                    result["errors"],
                    this.notifier
                  );
                }
              });
          } else if (response["errors"]) {
            CustomNotifierService.getErrors(response["errors"], this.notifier);
          }
          this.loaderObservationsComparativeChart = false;
        });
    }
  }

  // ------------------ OBSERVATIONS / INSTALLATIONS ------------------
  getObservationsByInstallations() {
    this.loaderObsInstallation = true;
    let installations = [];
    if (this.selectedRegistry !== 5) {
      installations = this.selectedInstallation;
    } else {
      installations = this.selectedInstallationCustom;
    }
    this.statisticsService
      .obsStatByInstallation(
        installations,
        this.dateFrom,
        this.dateTo,
        this.selectedEstablishments,
        this.selectedRegistry,
        "",
        "",
        this.obsInstallationLimit,
        this.obsInstallationPage
      )
      .subscribe((response) => {
        if (response["success"]) {
          this.obsInstallation = response["allObservations"];
          this.obsStateInstallation = response["observationsState"];
          this.obsNotStateInstallation = response["observationsNotState"];

          this.allObsInstallation.forEach((element) => {
            if (element.installation && element.installation.length > 50) {
              element.installation = element.installation.substr(0, 50) + "...";
            }
          });
          this.allObsStateInstallation.forEach((element) => {
            if (element.installation && element.installation.length > 50) {
              element.installation = element.installation.substr(0, 50) + "...";
            }
          });
          this.allObsNotStateInstallation.forEach((element) => {
            if (element.installation && element.installation.length > 50) {
              element.installation = element.installation.substr(0, 50) + "...";
            }
          });
          this.userChart.createObsInstallationChart(
            "#createObsInstallation",
            this.obsInstallation,
            this
          );
          setTimeout(() => {
            this.userChart.createObsStateInstallationChart(
              "#createObsStateInstallation",
              this.obsStateInstallation,
              this
            );
          }, 15);
          setTimeout(() => {
            this.userChart.createObsNotStateInstallationChart(
              "#createObsNotStateInstallation",
              this.obsNotStateInstallation,
              this
            );
          }, 15);
        } else if (response["errors"]) {
          CustomNotifierService.getErrors(response["errors"], this.notifier);
        }
        this.loaderObsInstallation = false;
      });
  }

  filterAllObsInstallation(property) {
    if (this.allObsInstallationSort === property) {
      if (this.allObsInstallationOrder === "asc") {
        this.allObsInstallationOrder = "desc";
      } else {
        this.allObsInstallationOrder = "asc";
      }
    } else {
      this.allObsInstallationSort = property;
      this.allObsInstallationOrder = "asc";
    }

    this.getAllObservationsWithInstallation(
      "observationsInstallation",
      this.installationIds
    );
  }
  getAllObservationsWithInstallation(content, installationIds = null) {
    this.requestInProgress.next(true);
    let installations = [];
    if (this.selectedRegistry !== 5) {
      installations = this.selectedInstallation;
    } else {
      installations = this.selectedInstallationCustom;
    }
    this.statisticsService
      .obsStatByInstallation(
        installations,
        this.dateFrom,
        this.dateTo,
        this.selectedEstablishments,
        this.selectedRegistry,
        this.allObsInstallationSort,
        this.allObsInstallationOrder,
        this.allObsInstallationLimit,
        this.allObsInstallationPage,
        installationIds,
        "all"
      )
      .subscribe((response) => {
        if (response["success"]) {
          this.installationName =
            response["allObservations"]["installation"]["name"];
          this.installationIds =
            response["allObservations"]["installation"]["installation_ids"];
          this.allObsInstallation = response["allObservations"]["observations"];
          this.allObsInstallationCount = response["allObservations"]["count"];
          this.allObsInstallationPages = response["allObservations"]["pages"];
          this.modal = $(this._rootNode.nativeElement).find("div#" + content);
          this.modal.modal("show");
        } else if (response["errors"]) {
          CustomNotifierService.getErrors(response["errors"], this.notifier);
        }
        this.requestInProgress.next(false);
      });
  }
  dispatchPaginationAllObsInstallation(data) {
    if (data.action === "total_elements") {
      this.refreshPageAllObsInstallation(data.value);
    } else if (data.action === "change_page") {
      this.changePageAllObsInstallation(data.value);
    }
  }
  refreshPageAllObsInstallation(limit) {
    this.allObsInstallationLimit = limit;
    this.allObsInstallationPage = 1;
    this.getAllObservationsWithInstallation(
      "observationsInstallation",
      this.installationIds
    );
  }
  changePageAllObsInstallation(page) {
    if (page >= 1 && page <= this.allObsInstallationPages) {
      this.allObsInstallationPage = page;
      this.getAllObservationsWithInstallation(
        "observationsInstallation",
        this.installationIds
      );
    }
  }

  filterObsStateInstallation(property) {
    if (this.allObsStateInstallationSort === property) {
      if (this.allObsStateInstallationOrder === "asc") {
        this.allObsStateInstallationOrder = "desc";
      } else {
        this.allObsStateInstallationOrder = "asc";
      }
    } else {
      this.allObsStateInstallationSort = property;
      this.allObsStateInstallationOrder = "asc";
    }

    this.getObservationsStateByInstallation(
      "observationsInstallationState",
      this.installationIds
    );
  }
  getObservationsStateByInstallation(content, installationIds = null) {
    this.requestInProgress.next(true);
    let installations = [];
    if (this.selectedRegistry !== 5) {
      installations = this.selectedInstallation;
    } else {
      installations = this.selectedInstallationCustom;
    }
    this.statisticsService
      .obsStatByInstallation(
        installations,
        this.dateFrom,
        this.dateTo,
        this.selectedEstablishments,
        this.selectedRegistry,
        this.allObsStateInstallationSort,
        this.allObsStateInstallationOrder,
        this.allObsStateInstallationLimit,
        this.allObsStateInstallationPage,
        installationIds,
        "state"
      )
      .subscribe((response) => {
        if (response["success"]) {
          this.installationName =
            response["observationsState"]["installation"]["name"];
          this.installationIds =
            response["observationsState"]["installation"]["installation_ids"];
          this.allObsStateInstallation =
            response["observationsState"]["observations"];
          this.allObsStateInstallationCount =
            response["observationsState"]["count"];
          this.allObsStateInstallationPages =
            response["observationsState"]["pages"];
          this.modal = $(this._rootNode.nativeElement).find("div#" + content);
          this.modal.modal("show");
        } else if (response["errors"]) {
          CustomNotifierService.getErrors(response["errors"], this.notifier);
        }
        this.requestInProgress.next(false);
      });
  }
  dispatchPaginationObsStateInstallation(data) {
    if (data.action === "total_elements") {
      this.refreshPageObsStateInstallation(data.value);
    } else if (data.action === "change_page") {
      this.changePageObsStateInstallation(data.value);
    }
  }
  refreshPageObsStateInstallation(limit) {
    this.allObsStateInstallationLimit = limit;
    this.allObsStateInstallationPage = 1;
    this.getObservationsStateByInstallation(
      "observationsInstallationState",
      this.installationIds
    );
  }
  changePageObsStateInstallation(page) {
    if (page >= 1 && page <= this.allObsStateInstallationPages) {
      this.allObsStateInstallationPage = page;
      this.getObservationsStateByInstallation(
        "observationsInstallationState",
        this.installationIds
      );
    }
  }

  filterAllObsNotStateInstallation(property) {
    if (this.allObsNotStateInstallationSort === property) {
      if (this.allObsNotStateInstallationOrder === "asc") {
        this.allObsNotStateInstallationOrder = "desc";
      } else {
        this.allObsNotStateInstallationOrder = "asc";
      }
    } else {
      this.allObsNotStateInstallationSort = property;
      this.allObsNotStateInstallationOrder = "asc";
    }

    this.getObservationsNotStateByInstallation(
      "observationsInstallationNotState",
      this.installationIds
    );
  }
  getObservationsNotStateByInstallation(content, installationIds = null) {
    this.requestInProgress.next(true);
    let installations = [];
    if (this.selectedRegistry !== 5) {
      installations = this.selectedInstallation;
    } else {
      installations = this.selectedInstallationCustom;
    }
    this.statisticsService
      .obsStatByInstallation(
        installations,
        this.dateFrom,
        this.dateTo,
        this.selectedEstablishments,
        this.selectedRegistry,
        this.allObsNotStateInstallationSort,
        this.allObsNotStateInstallationOrder,
        this.allObsNotStateInstallationLimit,
        this.allObsNotStateInstallationPage,
        installationIds,
        "notState"
      )
      .subscribe((response) => {
        if (response["success"]) {
          this.installationName =
            response["observationsNotState"]["installation"]["name"];
          this.installationIds =
            response["observationsNotState"]["installation"][
              "installation_ids"
            ];
          this.allObsNotStateInstallation =
            response["observationsNotState"]["observations"];
          this.allObsNotStateInstallationCount =
            response["observationsNotState"]["count"];
          this.allObsNotStateInstallationPages =
            response["observationsNotState"]["pages"];
          this.modal = $(this._rootNode.nativeElement).find("div#" + content);
          this.modal.modal("show");
        } else if (response["errors"]) {
          CustomNotifierService.getErrors(response["errors"], this.notifier);
        }
        this.requestInProgress.next(false);
      });
  }
  dispatchPaginationObsNoStateInstallation(data) {
    if (data.action === "total_elements") {
      this.refreshPageObsNoStateInstallation(data.value);
    } else if (data.action === "change_page") {
      this.changePageObsNoStateInstallation(data.value);
    }
  }
  refreshPageObsNoStateInstallation(limit) {
    this.allObsNotStateInstallationLimit = limit;
    this.allObsNotStateInstallationPage = 1;
    this.getObservationsNotStateByInstallation(
      "observationsInstallationNotState",
      this.installationIds
    );
  }
  changePageObsNoStateInstallation(page) {
    if (page >= 1 && page <= this.allObsNotStateInstallationPages) {
      this.allObsNotStateInstallationPage = page;
      this.getObservationsNotStateByInstallation(
        "observationsInstallationNotState",
        this.installationIds
      );
    }
  }

  // ------------ STATS AVERAGE DAY / STATS AVERAGE COSTS --------------
  // DAY + MODAL AND PAGINATION FOR ALL FILTERS
  getObservationsStatsDay() {
    this.loaderObservationsAverageDays = true;
    let installations = [];
    if (this.selectedRegistry !== 5) {
      installations = this.selectedInstallation;
    } else {
      installations = this.selectedInstallationCustom;
    }
    this.statisticsService
      .obsStatDay(
        installations,
        this.dateFrom,
        this.dateTo,
        this.selectedCheckpoint,
        this.selectedEstablishments,
        "",
        "",
        this.dayLimit,
        this.dayPage,
        this.selectedRegistry
      )
      .subscribe((response) => {
        if (response["success"]) {
          this.dayFilter1Count = response["observationsFilter1Count"];
          this.dayFilter2Count = response["observationsFilter2Count"];
          this.dayFilter3Count = response["observationsFilter3Count"];
          this.dayFilter4Count = response["observationsFilter4Count"];
          this.userChart.createObservationsAverageDays(
            "#createObservationsAverageDays",
            this.dayFilter1Count,
            this.dayFilter2Count,
            this.dayFilter3Count,
            this.dayFilter4Count,
            this
          );
        } else if (response["errors"]) {
          CustomNotifierService.getErrors(response["errors"], this.notifier);
        }
        this.loaderObservationsAverageDays = false;
      });
  }

  filterDayFilter1(property) {
    if (this.dayFilter1Sort === property) {
      if (this.dayFilter1Order === "asc") {
        this.dayFilter1Order = "desc";
      } else {
        this.dayFilter1Order = "asc";
      }
    } else {
      this.dayFilter1Sort = property;
      this.dayFilter1Order = "asc";
    }

    this.getObservationsStatsDayFilter1("observationsAverageCostsFilter1");
  }
  getObservationsStatsDayFilter1(content) {
    this.requestInProgress.next(true);
    let installations = [];
    if (this.selectedRegistry !== 5) {
      installations = this.selectedInstallation;
    } else {
      installations = this.selectedInstallationCustom;
    }
    this.statisticsService
      .obsStatDay(
        installations,
        this.dateFrom,
        this.dateTo,
        this.selectedCheckpoint,
        this.selectedEstablishments,
        this.dayFilter1Sort,
        this.dayFilter1Order,
        this.dayFilter1Limit,
        this.dayFilter1Page,
        this.selectedRegistry,
        "filter1"
      )
      .subscribe((response) => {
        if (response["success"]) {
          this.dayFilter1 = response["observationsFilter1"];
          this.dayFilter1Count = response["observationsFilter1Count"];
          this.dayFilter1Pages = response["observationsFilter1Pages"];
          this.modal = $(this._rootNode.nativeElement).find("div#" + content);
          this.modal.modal("show");
        } else if (response["errors"]) {
          CustomNotifierService.getErrors(response["errors"], this.notifier);
        }
        this.requestInProgress.next(false);
      });
  }
  dispatchPaginationDayFilter1(data) {
    if (data.action === "total_elements") {
      this.refreshPageDayFilter1(data.value);
    } else if (data.action === "change_page") {
      this.changePageDayFilter1(data.value);
    }
  }
  refreshPageDayFilter1(limit) {
    this.dayFilter1Limit = limit;
    this.dayFilter1Page = 1;
    this.getObservationsStatsDayFilter1("observationsAverageCostsFilter1");
  }
  changePageDayFilter1(page) {
    if (page >= 1 && page <= this.dayFilter1Pages) {
      this.dayFilter1Page = page;
      this.getObservationsStatsDayFilter1("observationsAverageCostsFilter1");
    }
  }

  filterDayFilter2(property) {
    if (this.dayFilter2Sort === property) {
      if (this.dayFilter2Order === "asc") {
        this.dayFilter2Order = "desc";
      } else {
        this.dayFilter2Order = "asc";
      }
    } else {
      this.dayFilter2Sort = property;
      this.dayFilter2Order = "asc";
    }

    this.getObservationsStatsDayFilter2("observationsAverageCostsFilter2");
  }
  getObservationsStatsDayFilter2(content) {
    this.requestInProgress.next(true);
    let installations = [];
    if (this.selectedRegistry !== 5) {
      installations = this.selectedInstallation;
    } else {
      installations = this.selectedInstallationCustom;
    }
    this.statisticsService
      .obsStatDay(
        installations,
        this.dateFrom,
        this.dateTo,
        this.selectedCheckpoint,
        this.selectedEstablishments,
        this.dayFilter2Sort,
        this.dayFilter2Order,
        this.dayFilter2Limit,
        this.dayFilter2Page,
        this.selectedRegistry,
        "filter2"
      )
      .subscribe((response) => {
        if (response["success"]) {
          this.dayFilter2 = response["observationsFilter2"];
          this.dayFilter2Count = response["observationsFilter2Count"];
          this.dayFilter2Pages = response["observationsFilter2Pages"];
          this.modal = $(this._rootNode.nativeElement).find("div#" + content);
          this.modal.modal("show");
        } else if (response["errors"]) {
          CustomNotifierService.getErrors(response["errors"], this.notifier);
        }
        this.requestInProgress.next(false);
      });
  }
  dispatchPaginationDayFilter2(data) {
    if (data.action === "total_elements") {
      this.refreshPageDayFilter2(data.value);
    } else if (data.action === "change_page") {
      this.changePageDayFilter2(data.value);
    }
  }
  refreshPageDayFilter2(limit) {
    this.dayFilter2Limit = limit;
    this.dayFilter2Page = 1;
    this.getObservationsStatsDayFilter2("observationsAverageCostsFilter2");
  }
  changePageDayFilter2(page) {
    if (page >= 1 && page <= this.dayFilter2Pages) {
      this.dayFilter2Page = page;
      this.getObservationsStatsDayFilter2("observationsAverageCostsFilter2");
    }
  }

  filterDayFilter3(property) {
    if (this.dayFilter3Sort === property) {
      if (this.dayFilter3Order === "asc") {
        this.dayFilter3Order = "desc";
      } else {
        this.dayFilter3Order = "asc";
      }
    } else {
      this.dayFilter3Sort = property;
      this.dayFilter3Order = "asc";
    }

    this.getObservationsStatsDayFilter3("observationsAverageCostsFilter3");
  }
  getObservationsStatsDayFilter3(content) {
    this.requestInProgress.next(true);
    let installations = [];
    if (this.selectedRegistry !== 5) {
      installations = this.selectedInstallation;
    } else {
      installations = this.selectedInstallationCustom;
    }
    this.statisticsService
      .obsStatDay(
        installations,
        this.dateFrom,
        this.dateTo,
        this.selectedCheckpoint,
        this.selectedEstablishments,
        this.dayFilter3Sort,
        this.dayFilter3Order,
        this.dayFilter3Limit,
        this.dayFilter3Page,
        this.selectedRegistry,
        "filter3"
      )
      .subscribe((response) => {
        if (response["success"]) {
          this.dayFilter3 = response["observationsFilter3"];
          this.dayFilter3Count = response["observationsFilter3Count"];
          this.dayFilter3Pages = response["observationsFilter3Pages"];
          this.modal = $(this._rootNode.nativeElement).find("div#" + content);
          this.modal.modal("show");
        } else if (response["errors"]) {
          CustomNotifierService.getErrors(response["errors"], this.notifier);
        }
        this.requestInProgress.next(false);
      });
  }
  dispatchPaginationDayFilter3(data) {
    if (data.action === "total_elements") {
      this.refreshPageDayFilter3(data.value);
    } else if (data.action === "change_page") {
      this.changePageDayFilter3(data.value);
    }
  }
  refreshPageDayFilter3(limit) {
    this.dayFilter3Limit = limit;
    this.dayFilter3Page = 1;
    this.getObservationsStatsDayFilter3("observationsAverageCostsFilter3");
  }
  changePageDayFilter3(page) {
    if (page >= 1 && page <= this.dayFilter3Pages) {
      this.dayFilter3Page = page;
      this.getObservationsStatsDayFilter3("observationsAverageCostsFilter3");
    }
  }

  filterDayFilter4(property) {
    if (this.dayFilter4Sort === property) {
      if (this.dayFilter4Order === "asc") {
        this.dayFilter4Order = "desc";
      } else {
        this.dayFilter4Order = "asc";
      }
    } else {
      this.dayFilter4Sort = property;
      this.dayFilter4Order = "asc";
    }

    this.getObservationsStatsDayFilter4("observationsAverageCostsFilter4");
  }
  getObservationsStatsDayFilter4(content) {
    this.requestInProgress.next(true);
    let installations = [];
    if (this.selectedRegistry !== 5) {
      installations = this.selectedInstallation;
    } else {
      installations = this.selectedInstallationCustom;
    }
    this.statisticsService
      .obsStatDay(
        installations,
        this.dateFrom,
        this.dateTo,
        this.selectedCheckpoint,
        this.selectedEstablishments,
        this.dayFilter4Sort,
        this.dayFilter4Order,
        this.dayFilter4Limit,
        this.dayFilter4Page,
        this.selectedRegistry,
        "filter4"
      )
      .subscribe((response) => {
        if (response["success"]) {
          this.dayFilter4 = response["observationsFilter4"];
          this.dayFilter4Count = response["observationsFilter4Count"];
          this.dayFilter4Pages = response["observationsFilter4Pages"];
          this.modal = $(this._rootNode.nativeElement).find("div#" + content);
          this.modal.modal("show");
        } else if (response["errors"]) {
          CustomNotifierService.getErrors(response["errors"], this.notifier);
        }
        this.requestInProgress.next(false);
      });
  }
  dispatchPaginationDayFilter4(data) {
    if (data.action === "total_elements") {
      this.refreshPageDayFilter4(data.value);
    } else if (data.action === "change_page") {
      this.changePageDayFilter4(data.value);
    }
  }
  refreshPageDayFilter4(limit) {
    this.dayFilter4Limit = limit;
    this.dayFilter4Page = 1;
    this.getObservationsStatsDayFilter4("observationsAverageCostsFilter4");
  }
  changePageDayFilter4(page) {
    if (page >= 1 && page <= this.dayFilter4Pages) {
      this.dayFilter4Page = page;
      this.getObservationsStatsDayFilter4("observationsAverageCostsFilter4");
    }
  }
  // COSTS + MODAL AND PAGINATION FOR ALL FILTERS
  getObservationsStatsCosts() {
    this.loaderObservationsAverageCosts = true;
    let installations = [];
    if (this.selectedRegistry !== 5) {
      installations = this.selectedInstallation;
    } else {
      installations = this.selectedInstallationCustom;
    }
    this.statisticsService
      .obsStatCosts(
        installations,
        this.dateFrom,
        this.dateTo,
        this.selectedCheckpoint,
        this.selectedEstablishments,
        "",
        "",
        this.costsLimit,
        this.costsPage,
        this.selectedRegistry
      )
      .subscribe((response) => {
        if (response["success"]) {
          this.costFilter1Count = response["observationsFilter1Count"];
          this.costFilter2Count = response["observationsFilter2Count"];
          this.costFilter3Count = response["observationsFilter3Count"];
          this.userChart.createObservationsAverageCosts(
            "#createObservationsAverageCosts",
            this.costFilter1Count,
            this.costFilter2Count,
            this.costFilter3Count,
            this
          );
        } else if (response["errors"]) {
          CustomNotifierService.getErrors(response["errors"], this.notifier);
        }
        this.loaderObservationsAverageCosts = false;
      });
  }

  filterCostFilter1(property) {
    if (this.costFilter1Sort === property) {
      if (this.costFilter1Order === "asc") {
        this.costFilter1Order = "desc";
      } else {
        this.costFilter1Order = "asc";
      }
    } else {
      this.costFilter1Sort = property;
      this.costFilter1Order = "asc";
    }

    this.getObservationsStatsCostsFilter1("observationsAverageCostsFilter1");
  }
  getObservationsStatsCostsFilter1(content) {
    this.requestInProgress.next(true);
    let installations = [];
    if (this.selectedRegistry !== 5) {
      installations = this.selectedInstallation;
    } else {
      installations = this.selectedInstallationCustom;
    }
    this.statisticsService
      .obsStatCosts(
        installations,
        this.dateFrom,
        this.dateTo,
        this.selectedCheckpoint,
        this.selectedEstablishments,
        this.costFilter1Sort,
        this.costFilter1Order,
        this.costFilter1Limit,
        this.costFilter1Page,
        this.selectedRegistry,
        "filter1"
      )
      .subscribe((response) => {
        if (response["success"]) {
          this.costFilter1 = response["observationsFilter1"];
          this.costFilter1Count = response["observationsFilter1Count"];
          this.costFilter1Pages = response["observationsFilter1Pages"];
          this.modal = $(this._rootNode.nativeElement).find("div#" + content);
          this.modal.modal("show");
        } else if (response["errors"]) {
          CustomNotifierService.getErrors(response["errors"], this.notifier);
        }
        this.requestInProgress.next(false);
      });
  }
  dispatchPaginationCostsFilter1(data) {
    if (data.action === "total_elements") {
      this.refreshPageCostsFilter1(data.value);
    } else if (data.action === "change_page") {
      this.changePageCostsFilter1(data.value);
    }
  }
  refreshPageCostsFilter1(limit) {
    this.costFilter1Limit = limit;
    this.costFilter1Page = 1;
    this.getObservationsStatsCostsFilter1("observationsAverageCostsFilter1");
  }
  changePageCostsFilter1(page) {
    if (page >= 1 && page <= this.costFilter1Pages) {
      this.costFilter1Page = page;
      this.getObservationsStatsCostsFilter1("observationsAverageCostsFilter1");
    }
  }

  filterCostFilter2(property) {
    if (this.costFilter2Sort === property) {
      if (this.costFilter2Order === "asc") {
        this.costFilter2Order = "desc";
      } else {
        this.costFilter2Order = "asc";
      }
    } else {
      this.costFilter2Sort = property;
      this.costFilter2Order = "asc";
    }

    this.getObservationsStatsCostsFilter2("observationsAverageCostsFilter2");
  }
  getObservationsStatsCostsFilter2(content) {
    this.requestInProgress.next(true);
    let installations = [];
    if (this.selectedRegistry !== 5) {
      installations = this.selectedInstallation;
    } else {
      installations = this.selectedInstallationCustom;
    }
    this.statisticsService
      .obsStatCosts(
        installations,
        this.dateFrom,
        this.dateTo,
        this.selectedCheckpoint,
        this.selectedEstablishments,
        this.costFilter2Sort,
        this.costFilter2Order,
        this.costFilter2Limit,
        this.costFilter2Page,
        this.selectedRegistry,
        "filter2"
      )
      .subscribe((response) => {
        if (response["success"]) {
          this.costFilter2 = response["observationsFilter2"];
          this.costFilter2Count = response["observationsFilter2Count"];
          this.costFilter2Pages = response["observationsFilter2Pages"];
          this.modal = $(this._rootNode.nativeElement).find("div#" + content);
          this.modal.modal("show");
        } else if (response["errors"]) {
          CustomNotifierService.getErrors(response["errors"], this.notifier);
        }
        this.requestInProgress.next(false);
      });
  }
  dispatchPaginationCostsFilter2(data) {
    if (data.action === "total_elements") {
      this.refreshPageCostsFilter2(data.value);
    } else if (data.action === "change_page") {
      this.changePageCostsFilter2(data.value);
    }
  }
  refreshPageCostsFilter2(limit) {
    this.costFilter2Limit = limit;
    this.costFilter2Page = 1;
    this.getObservationsStatsCostsFilter2("observationsAverageCostsFilter2");
  }
  changePageCostsFilter2(page) {
    if (page >= 1 && page <= this.costFilter2Pages) {
      this.costFilter2Page = page;
      this.getObservationsStatsCostsFilter2("observationsAverageCostsFilter2");
    }
  }

  filterCostFilter3(property) {
    if (this.costFilter3Sort === property) {
      if (this.costFilter3Order === "asc") {
        this.costFilter3Order = "desc";
      } else {
        this.costFilter3Order = "asc";
      }
    } else {
      this.costFilter3Sort = property;
      this.costFilter3Order = "asc";
    }

    this.getObservationsStatsCostsFilter3("observationsAverageCostsFilter3");
  }
  getObservationsStatsCostsFilter3(content) {
    this.requestInProgress.next(true);
    let installations = [];
    if (this.selectedRegistry !== 5) {
      installations = this.selectedInstallation;
    } else {
      installations = this.selectedInstallationCustom;
    }
    this.statisticsService
      .obsStatCosts(
        installations,
        this.dateFrom,
        this.dateTo,
        this.selectedCheckpoint,
        this.selectedEstablishments,
        this.costFilter3Sort,
        this.costFilter3Order,
        this.costFilter3Limit,
        this.costFilter3Page,
        this.selectedRegistry,
        "filter3"
      )
      .subscribe((response) => {
        if (response["success"]) {
          this.costFilter3 = response["observationsFilter3"];
          this.costFilter3Count = response["observationsFilter3Count"];
          this.costFilter3Pages = response["observationsFilter3Pages"];
          this.modal = $(this._rootNode.nativeElement).find("div#" + content);
          this.modal.modal("show");
        } else if (response["errors"]) {
          CustomNotifierService.getErrors(response["errors"], this.notifier);
        }
        this.requestInProgress.next(false);
      });
  }
  dispatchPaginationCostsFilter3(data) {
    if (data.action === "total_elements") {
      this.refreshPageCostsFilter3(data.value);
    } else if (data.action === "change_page") {
      this.changePageCostsFilter3(data.value);
    }
  }
  refreshPageCostsFilter3(limit) {
    this.costFilter3Limit = limit;
    this.costFilter3Page = 1;
    this.getObservationsStatsCostsFilter3("observationsAverageCostsFilter3");
  }
  changePageCostsFilter3(page) {
    if (page >= 1 && page <= this.costFilter3Pages) {
      this.costFilter3Page = page;
      this.getObservationsStatsCostsFilter3("observationsAverageCostsFilter3");
    }
  }

  resetFilters() {
    this.selectedEstablishments = [];
    this.period = "custom";
    $("#dateFrom").flatpickr().clear();
    $("#dateTo").flatpickr().clear();
    this.selectedRegistry = null;
    this.selectedInstallation = [];
    this.selectedCheckpoint = null;
    this.selectedInstallationCustom = [];
    this.selectedCheckpointsCustom = null;
    this.selectedEstablishmentA = null;
    this.selectedEstablishmentB = null;
    this.getObsBlock();
    this.getEmergenciesObservations();
    this.getDelayObservations();
    this.getDelayObservationsCheckpoints();
    this.getObservationsStatsCosts();
    this.getObservationsStatsDay();
    this.getObservationsByInstallations();
  }
  resetComparativeFilters() {
    this.selectedEstablishments = [];
    this.selectedEstablishmentA = null;
    this.selectedEstablishmentB = null;
    this.period = "custom";
    $("#dateFromComp").flatpickr().clear();
    $("#dateToComp").flatpickr().clear();
    this.selectedRegistry = null;
    this.selectedInstallation = [];
    this.selectedCheckpoint = null;
    this.selectedInstallationCustom = [];
    this.selectedCheckpointsCustom = null;
    this.getObservationsStatsCosts();
    this.getObservationsStatsDay();
  }
}
