import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Subject } from 'rxjs';
import { NgbTimeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { NgbTimeStringAdapter } from '../../../core/utils/ngb-time-string-adapter';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { AllSanitaryOperationFramesComponent } from '../all-sanitary-operation-frames/all-sanitary-operation-frames.component';

import { UsersRequestService } from '../../../services/users.request.service';
import { SanitaryOperationsRequestService } from '../../../services/sanitary-operations.request.service';
import { DocumentsRequestService } from '../../../services/documents.request.service';
import { SanitaryFramesRequestService } from '../../../services/sanitary-frames.request.service';
import { SanitaryOperationFramesRequestService } from '../../../services/sanitary-operation-frames.request.service';
import { SanitaryContributorsRequestService } from '../../../services/sanitary-contributors.request.service';

import SanitaryOperation from '../../../../shared/interfaces/sanitary_operation';
import SanitaryContributors from '../../../../shared/interfaces/sanitary_contributors';
import { ModalRightComponent } from '../../../core/modal-right/modal-right.component';

declare function Flatpickr(): any;
declare function Dropzones(): any;


@Component({
  selector: 'app-update-sanitary-operation-frame',
  templateUrl: './update-sanitary-operation-frame.component.html',
  styleUrls: ['./update-sanitary-operation-frame.component.scss'],
  providers: [{ provide: NgbTimeAdapter, useClass: NgbTimeStringAdapter }]
})
export class UpdateSanitaryOperationFrameComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('operationFrame') public modalSanitaryOperationFrame: ModalRightComponent;

  counter = Array;
  canCreate = false;
  canUpdate = false;

  timeout: any;

  requestInProgress = new Subject();
  requestCount = 0;

  sanitaryOperation: SanitaryOperation;
  contributors: SanitaryContributors[] = [];

  sanitaryOperationFrameForm: FormGroup;
  title = new FormControl('', Validators.required);
  date = new FormControl('', Validators.required);
  descriptions = new FormControl('');
  type = new FormControl('');
  detected_anomalies = new FormControl('');
  interventions = new FormControl('');
  contributor_id = new FormControl(null);


  selectedDescription = null;
  errors = {};

  weeks = [
    'Configurer mes points d\'usage', 'Semaine 1', 'Semaine 2', 'Semaine 3', 'Semaine 4', 'Semaine 5', 'Semaine 6', 'Semaine 7',
    'Semaine 8', 'Semaine 9', 'Semaine 10', 'Semaine 11', 'Semaine 12', 'Semaine 13', 'Semaine 14', 'Semaine 15', 'Semaine 16',
    'Semaine 17', 'Semaine 18', 'Semaine 19', 'Semaine 20', 'Semaine 21', 'Semaine 22', 'Semaine 23', 'Semaine 24', 'Semaine 25',
    'Semaine 26', 'Semaine 27', 'Semaine 28', 'Semaine 29', 'Semaine 30', 'Semaine 31', 'Semaine 32', 'Semaine 33', 'Semaine 34',
    'Semaine 35', 'Semaine 36', 'Semaine 37', 'Semaine 38', 'Semaine 39', 'Semaine 40', 'Semaine 41', 'Semaine 42', 'Semaine 43',
    'Semaine 44', 'Semaine 45', 'Semaine 46', 'Semaine 47', 'Semaine 48', 'Semaine 49', 'Semaine 50', 'Semaine 51', 'Semaine 52'
  ];
  months = [
    'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
  ];
  quarters = [
    '1er trimestre', '2ème trimestre', '3ème trimestre', '4ème trimestre'
  ];
  annuals = [
    'Date de réalisation', 'Nom de l\'intervenant', 'Commentaires'
  ];
  biannuals = [
    '1er semestre', '2ème semestre'
  ];

  listItems = [];

  periodicity = '';

  my_frame = false;

  isNew = true;
  loader = false;
  loaderContent = false;

  constructor(
      private router: Router,
      private formBuilder: FormBuilder,
      private notifier: NotifierService,
      private activatedRoute: ActivatedRoute,
      private usersService: UsersRequestService,
      private documentsService: DocumentsRequestService,
      private sanitaryFramesService: SanitaryFramesRequestService,
      private sanitaryOperationsServices: SanitaryOperationsRequestService,
      private allSanitaryOperationFrames: AllSanitaryOperationFramesComponent,
      private sanitaryContributorsServices: SanitaryContributorsRequestService,
      private sanitaryOperationFramesServices: SanitaryOperationFramesRequestService,
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loaderContent = this.requestCount > 0;
    });

    this.sanitaryOperationFrameForm = this.formBuilder.group({
      date: this.date,
      title: this.title,
      descriptions: this.formBuilder.array([]),
      detected_anomalies: this.detected_anomalies,
      interventions: this.interventions,
      contributor_id: this.contributor_id,
    });

    this.getSanitaryContributors();

    if (this.activatedRoute.snapshot.params.sanitary_operation_frame_id) {
      this.isNew = false;
      this.getSanitaryOperationFrame();
    } else {
      this.getSanitaryOperation();
      setTimeout(() => {
        Flatpickr();
      }, 1);
    }
  }
  ngAfterViewInit() {
    Dropzones();
    this.modalSanitaryOperationFrame.open();
  }

  ngOnDestroy() {
    const flatPicker = document.getElementsByClassName('flatpickr-calendar') as HTMLCollection;
    for (let i = flatPicker.length; i >= 0; i--) {
      if (flatPicker[i]) {
        flatPicker[i].remove();
      }
    }

    const dropZones = document.getElementsByClassName('dz-hidden-input') as HTMLCollection;
    for (let i = 0; i < dropZones.length; i++) {
      dropZones[i].remove();
    }

    this.clearTooltip();

    this.modalSanitaryOperationFrame.close();
  }
  clearTooltip() {
    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }
  getSanitaryContributors() {
    this.requestInProgress.next(true);
    this.sanitaryContributorsServices.getAllSanitaryContributors(
      '', '', '', '', '',
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
    ).subscribe(response => {
      if (response['success']) {
        this.contributors = response['sanitaryContributors'];
        this.contributors.forEach(contributor => {
          if (contributor.enterprise) {
            contributor['full_name'] = contributor.lastname + ' ' + contributor.firstname + ' - ' + contributor.enterprise;
          } else {
            contributor['full_name'] = contributor.lastname + ' ' + contributor.firstname;
          }
        });
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }
  getSanitaryOperation() {
    this.requestInProgress.next(true);
    this.sanitaryOperationsServices.getSanitaryOperation(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.activatedRoute.snapshot.parent.params.sanitary_operation_id,
    ).subscribe(response => {
      if (response['success']) {
        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];

        this.sanitaryOperation = response['sanitaryOperation'];
        this.periodicity = this.sanitaryOperation['sanitary_frame']['type'];
        if (this.sanitaryOperation['sanitary_frame']['enterprise_id']) {
          this.my_frame = true;
        }
        if (this.periodicity === 'weekly') {
          this.sanitaryOperationFrameForm.controls['contributor_id'].setValidators(Validators.required);
          this.sanitaryOperationFrameForm.controls['contributor_id'].updateValueAndValidity();
          const control = <FormArray>this.sanitaryOperationFrameForm.controls['descriptions'];
          this.weeks.forEach((week, weekIndex) => {
            if (weekIndex > 0) {
              this.listItems.push({title: week});
            }
            const array = this.formBuilder.array([]);
            this.sanitaryOperation['sanitary_frame']['descriptions'].forEach((description, indexDescription) => {
              const secondArray = this.formBuilder.array([]);
              if (this.my_frame) {
                if (weekIndex === 0) {
                  secondArray.push(this.formBuilder.group({
                    title: this.formBuilder.control(null, Validators.required),
                  }));
                } else {
                  secondArray.push(this.formBuilder.group({
                    title: this.formBuilder.control({value: null, disabled: true}),
                    comment: this.formBuilder.control(null),
                  }));
                }
                array.push(this.formBuilder.group({
                  title: description.title,
                  comments: secondArray,
                }));
              } else {
                if (indexDescription < 2) {
                  if (weekIndex === 0) {
                    secondArray.push(this.formBuilder.group({
                      title: this.formBuilder.control(null, Validators.required),
                    }));
                  } else {
                    secondArray.push(this.formBuilder.group({
                      title: this.formBuilder.control({value: null, disabled: true}),
                      comment: this.formBuilder.control(null),
                    }));
                  }
                  array.push(this.formBuilder.group({
                    title: description.title,
                    comments: secondArray,
                  }));
                } else {
                  if (weekIndex !== 0) {
                    array.push(this.formBuilder.group({
                      title: description.title,
                      comment: this.formBuilder.control(null),
                    }));
                  }
                }
              }
            });
            control.push(this.formBuilder.group({
              title: week,
              values: array,
            }));
          });
        } else if (this.periodicity === 'monthly') {
          const control = <FormArray>this.sanitaryOperationFrameForm.controls['descriptions'];
          this.months.forEach(month => {
            this.listItems.push({title: month});
            const array = this.formBuilder.array([]);
            this.sanitaryOperation['sanitary_frame']['descriptions'].forEach(description => {
              array.push(this.formBuilder.group({
                title: description.title,
                comment: this.formBuilder.control(null),
              }));
            });
            control.push(this.formBuilder.group({
              title: month,
              values: array,
            }));
          });
        } else if (this.periodicity === 'quarterly') {
          const control = <FormArray>this.sanitaryOperationFrameForm.controls['descriptions'];
          this.quarters.forEach(quarter => {
            this.listItems.push({title: quarter});
            const array = this.formBuilder.array([]);
            this.sanitaryOperation['sanitary_frame']['descriptions'].forEach(description => {
              array.push(this.formBuilder.group({
                title: description.title,
                comment: this.formBuilder.control(null),
              }));
            });
            control.push(this.formBuilder.group({
              title: quarter,
              values: array,
            }));
          });
        } else if (this.periodicity === 'biannual') {
          const control = <FormArray>this.sanitaryOperationFrameForm.controls['descriptions'];
          this.sanitaryOperation['sanitary_frame']['descriptions'].forEach(description => {
            this.listItems.push({title: description.title});
            const array = this.formBuilder.array([]);
            this.annuals.forEach(annual => {
              const secondArray = this.formBuilder.array([]);
              this.biannuals.forEach(biannual => {
                secondArray.push(this.formBuilder.group({
                  title: biannual,
                  comment: this.formBuilder.control(null),
                }));
              });
              array.push(this.formBuilder.group({
                title: annual,
                comments: secondArray,
              }));
            });
            control.push(this.formBuilder.group({
              title: description.title,
              values: array,
            }));
          });
        } else if (this.periodicity === 'annual') {
          const control = <FormArray>this.sanitaryOperationFrameForm.controls['descriptions'];
          this.sanitaryOperation['sanitary_frame']['descriptions'].forEach(description => {
            this.listItems.push({title: description.title});
            const array = this.formBuilder.array([]);
            this.annuals.forEach(annual => {
              array.push(this.formBuilder.group({
                title: annual,
                comment: this.formBuilder.control(null),
              }));
            });
            control.push(this.formBuilder.group({
              title: description.title,
              values: array,
            }));
          });
        }
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      setTimeout(() => {
        Flatpickr();
      }, 1);
      this.requestInProgress.next(false);
    });
  }

  addDescriptions(description, indexDescription) {
    if (this.periodicity === 'weekly') {
      if (indexDescription > 0) {
        this.listItems.push({title: description.title});
      }
    } else {
      this.listItems.push({title: description.title});
    }
    const control = <FormArray>this.sanitaryOperationFrameForm.controls['descriptions'];
    control.push(this.formBuilder.group({
      title: this.formBuilder.control(''),
      values: this.formBuilder.array([]),
    }));
    description.values.forEach((value, indexValue) => {
      this.addValue(indexDescription, value, indexValue);
    });
  }
  addValue(indexDescription, value, indexValue) {
    if (this.periodicity === 'weekly') {
      const control = <FormArray>this.sanitaryOperationFrameForm.controls['descriptions']['controls'][indexDescription].controls['values'];
      if (indexValue < 2 || this.my_frame) {
        control.push(this.formBuilder.group({
          title: this.formBuilder.control(''),
          comments: this.formBuilder.array([]),
        }));
        value.comments.forEach(() => {
          this.addComment(indexDescription, indexValue);
        });
      } else {
        control.push(this.formBuilder.group({
          title: this.formBuilder.control(''),
          comment: this.formBuilder.control(null)
        }));
      }
    } else if (this.periodicity === 'biannual') {
      const control = <FormArray>this.sanitaryOperationFrameForm.controls['descriptions']['controls'][indexDescription].controls['values'];
      control.push(this.formBuilder.group({
        title: this.formBuilder.control(''),
        comments: this.formBuilder.array([]),
      }));
      value.comments.forEach(() => {
        this.addComment(indexDescription, indexValue);
      });
    } else {
      const control = <FormArray>this.sanitaryOperationFrameForm.controls['descriptions']['controls'][indexDescription].controls['values'];
      control.push(this.formBuilder.group({
        title: this.formBuilder.control(''),
        comment: this.formBuilder.control(null)
      }));
    }
  }
  addComment(indexDescription, indexValue) {
    const control = <FormArray>this.sanitaryOperationFrameForm.controls['descriptions']['controls'][indexDescription]
      .controls['values']['controls'][indexValue].controls['comments'];
    if (this.periodicity === 'weekly') {
      if (indexDescription === 0) {
        control.push(this.formBuilder.group({
          title: this.formBuilder.control(null),
        }));
      } else {
        control.push(this.formBuilder.group({
          title: this.formBuilder.control({value: null, disabled: true}),
          comment: this.formBuilder.control(null)
        }));
      }
    } else {
      control.push(this.formBuilder.group({
        title: this.formBuilder.control(null),
        comment: this.formBuilder.control(null)
      }));
    }
  }
  addNewComment(indexDescription, indexValue) {
    const control = <FormArray>this.sanitaryOperationFrameForm.controls['descriptions']['controls'][indexDescription]
      .controls['values']['controls'][indexValue].controls['comments'];
    if (indexDescription === 0) {
      control.push(this.formBuilder.group({
        title: this.formBuilder.control(null),
      }));
      this.sanitaryOperationFrameForm.controls['descriptions']['controls'].forEach((description, newIndexDescription) => {
        if (newIndexDescription > 0) {
          const controlComments = <FormArray>description.controls['values']['controls'][indexValue].controls['comments'];
          controlComments.push(this.formBuilder.group({
            title: this.formBuilder.control({value: null, disabled: true}),
            comment: this.formBuilder.control(null)
          }));
        }
      });
    }
  }
  deleteComment(indexDescription, indexValue, indexComment) {
    const control = <FormArray>this.sanitaryOperationFrameForm.controls['descriptions']['controls'][indexDescription]
      .controls['values']['controls'][indexValue].controls['comments'];
    control.removeAt(indexComment);
    this.sanitaryOperationFrameForm.controls['descriptions']['controls'].forEach((description, newIndexDescription) => {
      if (newIndexDescription > 0) {
        const controlComments = <FormArray>description.controls['values']['controls'][indexValue].controls['comments'];
        controlComments.removeAt(indexComment);
      }
    });
  }

  getSanitaryOperationFrame() {
    this.requestInProgress.next(true);
    this.sanitaryOperationFramesServices.getSanitaryOperationFrame(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.activatedRoute.snapshot.parent.params.sanitary_operation_id,
      this.activatedRoute.snapshot.params.sanitary_operation_frame_id,
    ).subscribe(response => {
      if (response['success']) {
        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];
        this.periodicity = response['sanitaryOperationFrame']['type'];
        this.my_frame = response['sanitaryOperationFrame']['my_frame'];
        if (this.periodicity === 'weekly' || this.periodicity === 'monthly' || this.periodicity === 'quarterly') {
          if (this.periodicity === 'weekly') {
            this.sanitaryOperationFrameForm.controls['contributor_id'].setValidators(Validators.required);
            this.sanitaryOperationFrameForm.controls['contributor_id'].updateValueAndValidity();
          }
        }
        response['sanitaryOperationFrame']['descriptions'].forEach((description, indexDescription) => {
          this.addDescriptions(description, indexDescription);
        });
        this.sanitaryOperationFrameForm.patchValue(response['sanitaryOperationFrame']);
        if (!this.canUpdate) {
          this.sanitaryOperationFrameForm.disable();
        }
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      setTimeout(() => {
        Flatpickr();
      }, 1);
      this.requestInProgress.next(false);
    });
  }
  createSanitaryOperationFrame() {
    let request;
    this.errors = {};
    this.loader = true;

    if (this.periodicity === 'weekly') {
      this.sanitaryOperationFrameForm['controls'].descriptions['controls'].forEach((description, newIndexDescription) => {
        if (newIndexDescription > 0) {
          description.controls.values.controls.forEach((value, indexValue) => {
            if (indexValue < 2 || this.my_frame) {
              value.controls.comments.controls.forEach(comment => {
                if (comment.controls.title.disabled) {
                  comment.controls.title.enable();
                }
              });
            }
          });
        }
      });
    }

    const data = {
      ...this.sanitaryOperationFrameForm.value,
    };

    if (this.my_frame) {
      data['my_frame'] = true;
    }

    request = this.sanitaryOperationFramesServices.createSanitaryOperationFrame(
        this.activatedRoute.snapshot.parent.params.registry_id,
        this.activatedRoute.snapshot.parent.params.establishment_id,
        this.activatedRoute.snapshot.parent.params.sanitary_operation_id,
        data
    );

    request.subscribe(response => {
      if (response['success']) {
          this.isNew = false;
          this.loader = false;
          this.modalSanitaryOperationFrame.close();
          CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response['errors']) {
        this.loader = false;
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(response['errors'], this.sanitaryOperationFrameForm, this.notifier);
      } else {
        this.loader = false;
      }
    });
  }
  updateSanitaryOperationFrame() {
    let request;

    this.errors = {};
    this.loader = true;

    if (this.periodicity === 'weekly') {
      this.sanitaryOperationFrameForm['controls'].descriptions['controls'].forEach((description, newIndexDescription) => {
        if (newIndexDescription > 0) {
          description.controls.values.controls.forEach((value, indexValue) => {
            if (indexValue < 2 || this.my_frame) {
              value.controls.comments.controls.forEach(comment => {
                if (comment.controls.title.disabled) {
                  comment.controls.title.enable();
                }
              });
            }
          });
        }
      });
    }

    const data = {
      ...this.sanitaryOperationFrameForm.value,
    };

    request = this.sanitaryOperationFramesServices.updateSanitaryOperationFrame(
        this.activatedRoute.snapshot.parent.params.registry_id,
        this.activatedRoute.snapshot.parent.params.establishment_id,
        this.activatedRoute.snapshot.parent.params.sanitary_operation_id,
        this.activatedRoute.snapshot.params.sanitary_operation_frame_id,
        data
    );

    request.subscribe(response => {
      if (response['success']) {
        this.loader = false;
        this.modalSanitaryOperationFrame.close();
        CustomNotifierService.getSuccessUpdate(this.notifier);
      } else if (response['errors']) {
        this.loader = false;
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      } else {
        this.loader = false;
      }
    });
  }

  closeModal() {
    this.router.navigate([this.activatedRoute.snapshot.parent.url.join('/')]).then(() => {
      this.allSanitaryOperationFrames.refreshData();
    });
  }
  configureUsagePoint(indexDescription, indexValue, indexComment) {
    if (indexDescription === 0) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        const title = this.sanitaryOperationFrameForm['controls'].
          descriptions['controls'][indexDescription].value.values[indexValue].comments[indexComment].title;
        this.sanitaryOperationFrameForm['controls'].descriptions['controls'].forEach((description, newIndexDescription) => {
          if (newIndexDescription > 0) {
            description.controls['values']['controls'][indexValue].controls['comments']['controls'][indexComment].controls['title'].
            setValue(title);
          }
        });
      }, 500);
    }
  }
  changeDescription() {
    setTimeout(() => {
      Flatpickr();
    }, 1);
  }
}
