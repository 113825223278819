import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs';

import { environment } from '../../../../environments/environment';

import { AuthService } from '../../../core/auth/auth.service';
import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { InstallationsRequestService } from '../../../services/installations.request.service';
import { RegistriesRequestService } from '../../../services/registries.request.service';
import { CheckpointsRequestService } from '../../../services/checkpoints.request.service';
import { ObservationsRequestService } from '../../../services/observations.request.service';

import Installation from '../../../../shared/interfaces/installation';
import Establishment from '../../../../shared/interfaces/establishment';
import Registry from '../../../../shared/interfaces/registry';
import Checkpoint from '../../../../shared/interfaces/checkpoint';
import Observation from '../../../../shared/interfaces/observation';

@Component({
  selector: 'app-all-customer-installations',
  templateUrl: './all-customer-installations.component.html',
  styleUrls: ['./all-customer-installations.component.scss']
})
export class AllCustomerInstallationsComponent implements OnInit, OnDestroy {
  changeEnterprise: Subscription;
  environment = environment;
  view = '';

  canUpdate = false;
  canCreate = false;

  requestInProgress = new Subject();
  requestCount = 0;

  pageName: string;
  previousPageName: string[] = [];
  previousPageRoute: string[] = [];
  currentRoute = '';

  registry: Registry;
  establishment: Establishment;
  installations: Installation[] = [];
  count = 0;
  pages = 1;

  search = '';
  sort = 'Installations.name';
  order = 'asc';
  limit = 12;
  page = 1;

  errors = {};
  timeout: any;
  loader = false;

  activeTab = '';

  filterByCheckpoints: Checkpoint[];
  filterByCheckpointId = '';
  filterByEmergencies: any = [
    { id: '0', name: 'Bas' },
    { id: '1', name: 'Normal' },
    { id: '2', name: 'Haut' },
    { id: '3', name: 'Prioritaire' }
  ];
  filterByEmergency = '';

  /**
   * Observations
   */
  observations: Observation[] = [];
  loaderObservations = true;
  pageObservations = 1;
  pagesObservations = 1;
  countObservations = 0;
  limitObservations = 12;
  timeoutObservations: any;
  searchObservation = '';
  sortByObservations = 'Observations.emergencies';
  sortDirectionObservations = 'desc';

  /**
   * Checkpoints
   */
  checkpoints: Checkpoint[] = [];
  loaderCheckpoints = true;
  canCreateCheckpoints = false;
  canUpdateCheckpoints = false;
  pageCheckpoints = 1;
  pagesCheckpoints = 1;
  countCheckpoints = 0;
  limitCheckpoints = 12;
  nextCheckpoint = true;
  timeoutCheckpoints: any;
  searchCheckpoint = '';
  sortByCheckpoints = 'EstablishmentCheckpoints.next_report_date';
  sortDirectionCheckpoints = 'asc';

  constructor(
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private injector: Injector,
    private installationsService: InstallationsRequestService,
    private registriesService: RegistriesRequestService,
    private checkpointsService: CheckpointsRequestService,
    private observationsService: ObservationsRequestService,
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loader = this.requestCount > 0;
    });

    this.changeEnterprise = this.authService.getEnterprise().subscribe(() => {
      this.getInstallations();
      this.getRegistry();
      this.getRegistryCheckpoints();
    });

    this.currentRoute = '/' + this.activatedRoute.snapshot.url.join('/') + '/';
    this.pageName = 'Installations';

    if (this.authService.loaded) {
      this.getInstallations();
      this.getRegistry();
      this.getRegistryCheckpoints();
    }
    this.createBreadcrumbs();
  }

  ngOnDestroy() {
    this.changeEnterprise.unsubscribe();

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  searchData() {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.page = 1;
      this.refreshData();
    }, 500);
  }

  refreshData(sort = this.sort, order = this.order, page = this.page) {
    this.sort = sort;
    this.order = order;
    this.page = page;
    this.getInstallations();
  }

  getInstallations() {
    this.requestInProgress.next(true);

    const enterprise_id = this.activatedRoute.snapshot.params.enterprise_id;
    const establishment_id = this.activatedRoute.snapshot.params.establishment_id;
    const registry_id = this.activatedRoute.snapshot.params.registry_id;
    this.installationsService.getCustomerInstallations(
      this.search, this.sort, this.order, this.limit, this.page, enterprise_id, establishment_id, registry_id
    ).subscribe(response => {
      if (response['success']) {
        this.installations = response['installations'];

        this.count = response['count'];
        this.pages = response['pages'];
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  filter(property) {
    if (this.sort === property) {
      if (this.order === 'asc') {
        this.order = 'desc';
      } else {
        this.order = 'asc';
      }
    } else {
      this.sort = property;
      this.order = 'asc';
    }

    this.refreshData();
  }

  dispatchPagination(data) {
    if (data.action === 'total_elements') {
      this.refreshPage(data.value);
    } else if (data.action === 'change_page') {
      this.changePage(data.value);
    }
  }

  refreshPage(limit) {
    this.limit = limit;
    this.page = 1;
    this.refreshData();
  }

  changePage(page) {
    if (page >= 1 && page <= this.pages) {
      this.page = page;
      this.refreshData();
    }
  }

  createBreadcrumbs() {
    this.previousPageName = [];
    this.previousPageRoute = [];

    this.previousPageName.push('Tableau de bord');
    this.previousPageRoute.push('/');

    this.previousPageName.push('Mes clients');
    this.previousPageRoute.push('/customers');

    this.previousPageName.push('Etablissements');
    this.previousPageRoute.push(
      '/customers/' + this.activatedRoute.snapshot.params.enterprise_id + '/establishments'
    );

    this.previousPageName.push('Registres');
    this.previousPageRoute.push(
      '/customers/' + this.activatedRoute.snapshot.params.enterprise_id +
      '/establishments/' + this.activatedRoute.snapshot.params.establishment_id +
      '/registries'
    );
  }

  getRegistry() {
    this.requestInProgress.next(true);
    this.registriesService.getRegistry(this.activatedRoute.snapshot.params.registry_id).subscribe(response => {
      if (response['success']) {
        this.registry = response['registry'];
        if (this.registry.checkpoints_module || this.registry.checkpoints_custom_module || this.registry.checkpoints_direct_module) {
          this.activeTab = 'checkpoints';
          this.getObservations();
          this.getCheckpoints();
        }
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  checkIfFilter() {
    return (this.registry.checkpoints_module || this.registry.checkpoints_custom_module || this.registry.checkpoints_direct_module ||
      this.registry.commissions_module || this.registry.expertises_module || this.registry.agencies_module);
  }
  setFilterWidth() {
    if ((this.registry.checkpoints_module || this.registry.checkpoints_custom_module || this.registry.checkpoints_direct_module) &&
      (this.registry.commissions_module || this.registry.expertises_module || this.registry.agencies_module)) {
      return 'col-12 col-sm-6 col-md-12 col-lg-6 col-xl-6';
    } else {
      return 'col-12';
    }
  }
  filterBy(type: string, event: any) {
    if (type === 'filter_checkpoints') {
      if (event && event.id) {
        this.filterByCheckpointId = event.id;
      } else {
        this.filterByCheckpointId = '';
      }
    }
    if (type === 'filter_emergencies') {
      if (event && event.id) {
        this.filterByEmergency = event.id;
      } else {
        this.filterByEmergency = '';
      }
    }

    this.getObservations();
    this.getCheckpoints();
  }
  changeTabs(tab) {
    this.activeTab = tab;
  }
  getRegistryCheckpoints() {
    let request;
    this.filterByCheckpoints = [];
    this.requestInProgress.next(true);
    request = this.checkpointsService.getCustomerAllCheckpointsRegistry(
      this.activatedRoute.snapshot.params.registry_id,
      this.activatedRoute.snapshot.params.establishment_id
    );

    request.subscribe(response => {
      if (response['success']) {
        this.filterByCheckpoints = response['checkpoints'];
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
    });
    this.requestInProgress.next(false);
  }

  /* OBSERVATIONS */
  getObservations() {
    let request;
    this.loaderObservations = true;
    this.observations = [];

    request = this.observationsService.getCustomerObservationDashboard(
      this.searchObservation, this.sortByObservations, this.sortDirectionObservations, this.limitObservations, this.pageObservations,
      this.activatedRoute.snapshot.params.registry_id,
      this.activatedRoute.snapshot.params.establishment_id,
      this.filterByCheckpointId,
      this.filterByEmergency
    );

    request.subscribe(response => {
      if (response['success']) {
        this.observations = response['observations'];
        this.pagesObservations = response['pages'];
        this.countObservations = response['count'];
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loaderObservations = false;
    });
  }
  filterObservations(property) {
    if (this.sortByObservations === property) {
      if (this.sortDirectionObservations === 'asc') {
        this.sortDirectionObservations = 'desc';
      } else {
        this.sortDirectionObservations = 'asc';
      }
    } else {
      this.sortByObservations = property;
      this.sortDirectionObservations = 'asc';
    }

    this.getObservations();
  }
  dispatchPaginationObservations(data) {
    if (data.action === 'total_elements') {
      this.refreshObservationsPage(data.value);
    } else if (data.action === 'change_page') {
      this.changePageObservations(data.value);
    }
  }
  changePageObservations(page) {
    if (page >= 1 && page <= this.pagesObservations) {
      this.pageObservations = page;
      this.getObservations();
    }
  }
  refreshObservationsPage(limit) {
    this.limitObservations = limit;
    this.pageObservations = 1;
    this.getObservations();
  }
  searchObservations() {
    clearTimeout(this.timeoutObservations);

    this.timeoutObservations = setTimeout(() => {
      this.pageObservations = 1;
      this.getObservations();
    }, 500);
  }

  /* CHECKPOINTS */
  getCheckpoints() {
    let request;
    this.loaderCheckpoints = true;
    this.checkpoints = [];

    if (this.nextCheckpoint) {
      request = this.checkpointsService.getCustomerNextCheckpointDashboard(
        this.searchCheckpoint, this.sortByCheckpoints, this.sortDirectionCheckpoints, this.limitCheckpoints, this.pageCheckpoints,
        this.activatedRoute.snapshot.params.registry_id,
        this.activatedRoute.snapshot.params.establishment_id,
        this.filterByCheckpointId
      );
    } else {
      request = this.checkpointsService.getCustomerDelayCheckpointDashboard(
        this.searchCheckpoint, this.sortByCheckpoints, this.sortDirectionCheckpoints, this.limitCheckpoints, this.pageCheckpoints,
        this.activatedRoute.snapshot.params.registry_id,
        this.activatedRoute.snapshot.params.establishment_id,
        this.filterByCheckpointId
      );
    }

    request.subscribe(response => {
      if (response['success']) {
        this.canCreateCheckpoints = response['canCreate'];
        this.canUpdateCheckpoints = response['canUpdate'];

        this.checkpoints = response['checkpoints'];

        this.pagesCheckpoints = response['pages'];
        this.countCheckpoints = response['count'];
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loaderCheckpoints = false;
    });
  }
  switchTabCheckpoints(bool) {
    if (this.nextCheckpoint !== bool) {
      this.nextCheckpoint = bool;
      this.pageCheckpoints = 1;
      this.pagesCheckpoints = 1;
      this.getCheckpoints();
    }
  }
  filterCheckpoints(property) {
    if (this.sortByCheckpoints === property) {
      if (this.sortDirectionCheckpoints === 'asc') {
        this.sortDirectionCheckpoints = 'desc';
      } else {
        this.sortDirectionCheckpoints = 'asc';
      }
    } else {
      this.sortByCheckpoints = property;
      this.sortDirectionCheckpoints = 'asc';
    }

    this.getCheckpoints();
  }
  dispatchPaginationCheckpoints(data) {
    if (data.action === 'total_elements') {
      this.refreshCheckpointsPage(data.value);
    } else if (data.action === 'change_page') {
      this.changePageCheckpoints(data.value);
    }
  }
  changePageCheckpoints(page) {
    if (page >= 1 && page <= this.pagesCheckpoints) {
      this.pageCheckpoints = page;
      this.getCheckpoints();
    }
  }
  refreshCheckpointsPage(limit) {
    this.limitCheckpoints = limit;
    this.pageCheckpoints = 1;
    this.getCheckpoints();
  }
  searchCheckpoints() {
    clearTimeout(this.timeoutCheckpoints);

    this.timeoutCheckpoints = setTimeout(() => {
      this.pageCheckpoints = 1;
      this.getCheckpoints();
    }, 500);
  }
  getEmergencies(item) {
    if (item.emergencies === 0) {
      return { color: 'visit-low', value: 'Bas' };
    } else if (item.emergencies === 1) {
      return { color: 'visit-normal', value: 'Normal' };
    } else if (item.emergencies === 2) {
      return { color: 'visit-high', value: 'Haut' };
    } else if (item.emergencies === 3) {
      return { color: 'visit-priority', value: 'Prioritaire' };
    }
  }
}
