import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs';

import { AuthService } from '../../../core/auth/auth.service';
import { HttpClient } from '@angular/common/http';

import { VideosRequestService } from '../../../services/videos.request.service';
import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { RegistriesRequestService } from '../../../services/registries.request.service';
import { EstablishmentsRequestService } from '../../../services/establishments.request.service';
import { OfficialsRequestService } from '../../../services/officials.request.service';

import Official from '../../../../shared/interfaces/official';
import BatiFile from '../../../../shared/interfaces/bati_file';
import Registry from '../../../../shared/interfaces/registry';
import Establishment from '../../../../shared/interfaces/establishment';

import { environment } from '../../../../environments/environment';

import { VideoModalComponent } from '../../../core/video-modal/video-modal.component';
import { PdfViewerComponent } from '../../../core/pdf-viewer/pdf-viewer.component';


declare function Flatpickr(): any;

@Component({
  selector: 'app-all-officials',
  templateUrl: './all-officials.component.html',
  styleUrls: ['./all-officials.component.scss']
})
export class AllOfficialsComponent implements OnInit, AfterViewInit, OnDestroy {
  changeEnterprise: Subscription;

  canCreate = false;
  canUpdate = false;
  canDelete = false;
  canLog = false;

  requestInProgress = new Subject();
  requestCount = 0;

  pageName: string;
  previousPageName: string[] = [];
  previousPageRoute: string[] = [];

  officialDateForm: FormGroup;
  official_date = new FormControl('', Validators.required);

  currentRoute = '';
  officialRoute = '';
  view = '';
  is_child = false;

  registry: Registry;
  establishment: Establishment;
  officials: Official[] = [];
  files: BatiFile[] = [];

  count = 0;
  pages = 1;

  search = '';
  sort = '';
  order = 'desc';
  limit = 12;
  page = 1;

  errors = {};

  timeout: any;
  loader = false;
  loaderDate = false;

  modalConfirmData = null;
  modalConfirmFile = null;
  modalVideo: VideoModalComponent = null;
  modalPdf: PdfViewerComponent = null;

  constructor(
    private http: HttpClient,
    private notifier: NotifierService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private videosService: VideosRequestService,
    private officialsService: OfficialsRequestService,
    private registriesService: RegistriesRequestService,
    private establishmentsService: EstablishmentsRequestService,
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loader = this.requestCount > 0;
    });

    this.changeEnterprise = this.authService.getEnterprise().subscribe(() => {
      this.getRegistry();
      this.getEstablishment();
      this.getOfficials();
    });

    this.currentRoute = '/' + this.activatedRoute.snapshot.url.join('/') + '/';
    this.officialRoute = '/dashboard-registry/' + this.activatedRoute.snapshot.params.registry_id +
      '/establishment/' + this.activatedRoute.snapshot.params.establishment_id + '/official/';

    if (this.activatedRoute.snapshot.url[6] && this.activatedRoute.snapshot.url[6].path === 'files') {
      this.view = 'official_files';
      this.sort = 'OfficialFiles.name';
      this.pageName = 'Liste des documents';
    } else {
      this.view = 'officials';
      this.sort = 'Officials.visit_date';
      this.pageName = 'Liste des visites';

      this.officialDateForm = this.formBuilder.group({
        official_date: this.official_date
      });
    }

    if (this.authService.loaded) {
      this.getRegistry();
      this.getEstablishment();
      this.getOfficials();
    }
    this.createBreadcrumbs();
  }

  ngAfterViewInit() {
    this.modalConfirmData.eventOnClose.subscribe(event => {
      if (event) {
        this.requestInProgress.next(true);
        let request;

        request = this.officialsService.deleteOfficial(
          this.activatedRoute.snapshot.params.registry_id,
          this.activatedRoute.snapshot.params.establishment_id,
          event
        );

        request.subscribe(response => {
          if (response['success']) {
            this.refreshData();
            CustomNotifierService.getSuccessDelete(this.notifier);
          } else if (response['errors']) {
            CustomNotifierService.getErrors(response['errors'], this.notifier);
          }
          this.requestInProgress.next(false);
        });
      }
    });
    this.modalConfirmFile.eventOnClose.subscribe(event => {
      if (event) {
        this.requestInProgress.next(true);
        let request;

        request = this.officialsService.deleteFile(
          this.activatedRoute.snapshot.params.registry_id,
          this.activatedRoute.snapshot.params.establishment_id,
          this.activatedRoute.snapshot.params.official_id,
          event
        );

        request.subscribe(response => {
          if (response['success']) {
            this.refreshData();
            CustomNotifierService.getSuccessDelete(this.notifier);
          } else if (response['errors']) {
            CustomNotifierService.getErrors(response['errors'], this.notifier);
          }
          this.requestInProgress.next(false);
        });
      }
    });
  }

  ngOnDestroy() {
    const flatPicker = document.getElementsByClassName('flatpickr-calendar') as HTMLCollection;
    for (let i = flatPicker.length; i >= 0; i--) {
      if (flatPicker[i]) {
        flatPicker[i].remove();
      }
    }

    this.changeEnterprise.unsubscribe();
  }

  searchData() {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.page = 1;
      this.refreshData();
    }, 500);
  }

  refreshData(sort = this.sort, order = this.order, page = this.page) {
    this.sort = sort;
    this.order = order;
    this.page = page;
    this.getOfficials();
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  getRegistry() {
    this.requestInProgress.next(true);
    this.registriesService.getRegistry(this.activatedRoute.snapshot.params.registry_id).subscribe(response => {
      if (response['success']) {
        this.registry = response['registry'];
        this.createBreadcrumbs();
      } else if (response['errors']) {
        this.errors = response['errors'];
      }
      this.requestInProgress.next(false);
    });
  }

  getEstablishment() {
    this.requestInProgress.next(true);
    this.establishmentsService.getMyEstablishment(this.activatedRoute.snapshot.params.establishment_id).subscribe(response => {
      if (response['success']) {
        this.establishment = response['establishment'];
        this.createBreadcrumbs();
      } else if (response['errors']) {
        this.errors = response['errors'];
      }
      this.requestInProgress.next(false);
    });
  }

  getOfficials() {
    let request;
    this.requestInProgress.next(true);

    if (this.view === 'official_files') {
      request = this.officialsService.getFiles(
        this.search, this.sort, this.order, this.limit, this.page,
        this.activatedRoute.snapshot.params.registry_id,
        this.activatedRoute.snapshot.params.establishment_id,
        this.activatedRoute.snapshot.params.official_id
      );
    } else {
      request = this.officialsService.getAllOfficials(
        this.search, this.sort, this.order, this.limit, this.page,
        this.activatedRoute.snapshot.params.registry_id,
        this.activatedRoute.snapshot.params.establishment_id
      );
    }

    request.subscribe(response => {
      if (response['success']) {
        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];
        this.canDelete = response['canDelete'];
        this.canLog = response['canLog'];

        if (response['officials']) {
          this.officials = response['officials'];
          if (!this.canUpdate) {
            this.official_date.disable();
          }
          this.official_date.setValue(response['official_date']);
        } else {
          this.files = response['files'];
        }

        this.count = response['count'];
        this.pages = response['pages'];
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);

      if (!this.activatedRoute.children.length) {
        setTimeout(() => {
          Flatpickr();
        }, 250);
      }
    });
  }

  updateOfficialDate() {
    let request;
    this.loaderDate = true;

    request = this.officialsService.updateOfficialDate(
      this.activatedRoute.snapshot.params.registry_id,
      this.activatedRoute.snapshot.params.establishment_id,
      this.officialDateForm.value
    );

    request.subscribe(response => {
      if (response['success']) {
        CustomNotifierService.getSuccessUpdate(this.notifier);
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loaderDate = false;
    });
  }

  downloadFile(file_id, file_name) {
    let request;

    request = this.officialsService.downloadFile(
      this.activatedRoute.snapshot.params.registry_id,
      this.activatedRoute.snapshot.params.establishment_id,
      this.activatedRoute.snapshot.params.official_id,
      file_id
    );

    request.subscribe((response: BufferSource) => {
      try {
        const decoder = new TextDecoder('utf-8');
        const domString = decoder.decode(response);
        const json = JSON.parse(domString);

        CustomNotifierService.getErrors(json['errors'], this.notifier);
      } catch (e) {
        const blob = new Blob([response]);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = file_name;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      }
    });
  }

  filter(property) {
    if (this.sort === property) {
      if (this.order === 'asc') {
        this.order = 'desc';
      } else {
        this.order = 'asc';
      }
    } else {
      this.sort = property;
      this.order = 'asc';
    }

    this.getOfficials();
  }

  createBreadcrumbs() {
    this.previousPageName = [];
    this.previousPageRoute = [];

    if (this.activatedRoute.snapshot.params.registry_id && this.activatedRoute.snapshot.params.establishment_id) {
      this.previousPageName.push('Tableau de bord');
      this.previousPageRoute.push('/');

      this.previousPageName.push(this.registry ? this.registry.name : 'Tableau de bord registre');
      this.previousPageRoute.push('/dashboard-registry/' + this.activatedRoute.snapshot.params.registry_id);

      this.previousPageName.push(this.establishment ? this.establishment.name : 'Tableau de bord établissement');
      this.previousPageRoute.push('/dashboard-registry/' + this.activatedRoute.snapshot.params.registry_id +
          '/establishment/' + this.activatedRoute.snapshot.params.establishment_id);

      if (this.view === 'official_files') {
        this.previousPageName.push('Responsable unique de sécurité');
        this.previousPageRoute.push('/dashboard-registry/' + this.activatedRoute.snapshot.params.registry_id +
          '/establishment/' + this.activatedRoute.snapshot.params.establishment_id + '/officials');
      }
    } else {
      this.previousPageName.push('Tableau de bord');
      this.previousPageRoute.push('/administration');
    }
  }

  dispatchPagination(data) {
    if (data.action === 'total_elements') {
      this.refreshPage(data.value);
    } else if (data.action === 'change_page') {
      this.changePage(data.value);
    }
  }

  refreshPage(limit) {
    this.limit = limit;
    this.page = 1;
    this.refreshData();
  }

  changePage(page) {
    if (page >= 1 && page <= this.pages) {
      this.page = page;
      this.getOfficials();
    }
  }

  bindModalConfirmData(modal) {
    this.modalConfirmData = modal;
  }
  bindModalConfirmFile(modal) {
    this.modalConfirmFile = modal;
  }

  /**
   * Download one file
   *
   * @param file_id The file
   */
  showVideo(file_id) {
    this.videosService.getFile(file_id).subscribe(response => {
      if (response['success']) {
        const url = environment.API_PATH + 'download-video/' + response['file']['token'];
        this.modalVideo.open(url);
      }
    });
  }
  /**
   * Show Pdf
   *
   * @param file_url The file
   */
  showPdf(file_url) {
    const url = environment.API_PATH + 'file/OfficialFiles/' + file_url;
    this.http.get(url + '/isExist').subscribe(response => {
      if (response['success']) {
        this.modalPdf.open(url);
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
    });
  }
  getImage(extension) {
    if (extension === 'mp4') {
      return '../assets/img/files/mp4.png';
    } else if (extension === 'pdf') {
      return '../assets/img/files/pdf.png';
    } else if (extension === 'xlsx' || extension === 'csv') {
      return '../assets/img/files/xls.png';
    } else if (extension === 'doc' || extension === 'docx') {
      return '../assets/img/files/doc.png';
    } else if (extension === 'jpeg' || extension === 'jpg') {
      return '../assets/img/files/jpg.png';
    } else if (extension === 'png') {
      return '../assets/img/files/png.png';
    } else {
      return '../assets/img/files/file_icon.png';
    }
  }
  bindModalVideo(modal) {
    this.modalVideo = modal;
  }
  bindModalPdf(modal) {
    this.modalPdf = modal;
  }
}
