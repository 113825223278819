<nav class="navbar navbar-expand-md navbar-light d-none d-md-flex" id="toolbar">
  <div class="container-fluid">
    <button (click)="collapseSidebar()" aria-label="Ouvrir ou fermer la barre de navigation latérale" class="navbar-toggler d-block" type="button">
      <span class="navbar-toggler-icon"></span>
    </button>

    <img class="navbar-brand-img mx-auto custom-rounded-circle" src="{{ environment.SERVER_HOST + 'img/enterprises/' + enterprise.picture }}" alt="Logo {{ enterprise.name }}" *ngIf="enterprise && enterprise.picture"/>
    <img class="navbar-brand-img mx-auto rounded-circle" src="../assets/img/establishments/building.png" alt="Logo {{ enterprise.name }}" *ngIf="enterprise && !enterprise.picture"/>

    <div class="navbar-user">

      <ng-container *ngIf="authService.isToolBarNotification && !authService.isSideNavNotification">
        <app-toolbar-notification></app-toolbar-notification>
      </ng-container>

      <div class="dropdown">
        <a href="#" class="avatar avatar-sm dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <img class="avatar-img rounded-circle" alt="Photo utilisateur" [src]="environment.SERVER_HOST + 'img/users/' + user.picture" *ngIf="user.picture != '' && user.picture != null" />

          <img class="avatar-img rounded-circle" alt="Photo utilisateur" src="../assets/img/users/user.png" *ngIf="user.picture == '' || user.picture == null" />
        </a>

        <div class="dropdown-menu dropdown-menu-right">
          <a [routerLink]="['/account']" class="href dropdown-item">Mon compte</a>
          <hr class="dropdown-divider">
          <a (click)="authService.logout()" *ngIf="!connectedAs" class="href dropdown-item">Déconnexion</a>
          <a (click)="authService.disconnectAs()" *ngIf="connectedAs" class="href dropdown-item">Revenir à mon compte</a>
        </div>
      </div>
    </div>
  </div>
</nav>
