import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Subject } from 'rxjs';
import { NgbTimeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { NgbTimeStringAdapter } from '../../../core/utils/ngb-time-string-adapter';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { UsersRequestService } from '../../../services/users.request.service';

import { AllSanitaryFramesComponent } from '../all-sanitary-frames/all-sanitary-frames.component';
import { SanitaryFramesRequestService } from '../../../services/sanitary-frames.request.service';

@Component({
  selector: 'app-update-sanitary-frame',
  templateUrl: './update-sanitary-frame.component.html',
  styleUrls: ['./update-sanitary-frame.component.scss'],
  providers: [{ provide: NgbTimeAdapter, useClass: NgbTimeStringAdapter }]
})
export class UpdateSanitaryFrameComponent implements OnInit, AfterViewInit, OnDestroy {
  canCreate = false;
  canUpdate = false;
  adminFrame = false;

  requestInProgress = new Subject();
  requestCount = 0;

  sanitaryFrameForm: FormGroup;
  enterprise_id = new FormControl(null);
  type = new FormControl('');
  label = new FormControl(null, [Validators.required]);

  enterprises: any = [];
  user: string;

  errors = {};

  typeUrl = '';

  isNew = true;
  loader = false;
  loaderContent = false;

  modalSanitaryFrame = null;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private allSanitaryFrames: AllSanitaryFramesComponent,
    private usersService: UsersRequestService,
    private sanitaryFramesService: SanitaryFramesRequestService,
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loaderContent = this.requestCount > 0;
    });

    this.sanitaryFrameForm = this.formBuilder.group({
      isNew: this.isNew,
      type: this.type,
      label: this.label,
      descriptions: this.formBuilder.array([]),
    });

    this.typeUrl = this.activatedRoute.snapshot.url[0].path;

    if (this.activatedRoute.snapshot.params.sanitary_frame_id) {
      this.isNew = false;
      this.getSanitaryFrame();
    } else {
      if (this.typeUrl === 'monthly' || this.typeUrl === 'quarterly') {
        for (let i = 0; i < 2; i++) {
          this.addColumn(i);
        }
      } else {
        this.addColumn();
      }
    }
  }

  addColumn(i = null) {
    const control = <FormArray>this.sanitaryFrameForm.controls['descriptions'];
    if (i !== null) {
      if (this.typeUrl === 'monthly' || this.typeUrl === 'quarterly') {
        if (i === 0) {
          if (this.typeUrl === 'monthly') {
            control.push(this.formBuilder.group({
              title: new FormControl({value: 'Mois (préciser la date de réalisation)', disabled: true}, [Validators.required]),
            }));
          } else {
            control.push(this.formBuilder.group({
              title: new FormControl({value: 'Trimestre (préciser la date de réalisation)', disabled: true}, [Validators.required]),
            }));
          }
        } else if (i === 1) {
          control.push(this.formBuilder.group({
            title: new FormControl({value: 'Nom de l\'intervenant', disabled: true}, [Validators.required])
          }));
        } else {
          control.push(this.formBuilder.group({
            title: new FormControl(null, [Validators.required])
          }));
        }
      } else {
        control.push(this.formBuilder.group({
          title: new FormControl(null, [Validators.required])
        }));
      }
    } else {
      control.push(this.formBuilder.group({
        title: new FormControl(null, [Validators.required])
      }));
    }
  }
  deleteColumn(index) {
    const control = <FormArray>this.sanitaryFrameForm.controls['descriptions'];
    control.removeAt(index);
  }

  ngAfterViewInit() {
    this.modalSanitaryFrame.open();
  }

  ngOnDestroy() {
    const dropZones = document.getElementsByClassName('dz-hidden-input') as HTMLCollection;
    for (let i = 0; i < dropZones.length; i++) {
      dropZones[i].remove();
    }

    this.modalSanitaryFrame.close();
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  getSanitaryFrame() {
    this.requestInProgress.next(true);
    this.sanitaryFramesService.getSanitaryFrame(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.activatedRoute.snapshot.params.sanitary_frame_id
    ).subscribe(response => {
      if (response['success']) {
        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];
        this.adminFrame = !response['sanitaryFrame']['enterprise_id'];
        response['sanitaryFrame']['descriptions'].forEach(() => {
          this.addColumn();
        });
        this.sanitaryFrameForm.patchValue(response['sanitaryFrame']);
        if (!this.canUpdate || this.adminFrame) {
          this.sanitaryFrameForm.disable();
        }
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  createSanitaryFrame() {
    let request;
    this.errors = {};
    this.loader = true;

    this.sanitaryFrameForm.controls['descriptions']['controls'].forEach(control => {
      if (control.disabled) {
        control.controls.title.enable();
      }
    });
    if (this.isNew) {
      this.sanitaryFrameForm.patchValue({ isNew: this.isNew });
      this.sanitaryFrameForm.patchValue({ type: this.activatedRoute.snapshot.url[0].path });
    }

    request = this.sanitaryFramesService.createSanitaryFrame(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.sanitaryFrameForm.value
    );

    request.subscribe(response => {
      if (response['success']) {
        this.isNew = false;
        this.loader = false;
        this.modalSanitaryFrame.close();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response['errors']) {
        this.loader = false;
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(response['errors'], this.sanitaryFrameForm, this.notifier);
      } else {
        this.loader = false;
      }
    });
  }

  updateSanitaryFrame() {
    let request;

    this.errors = {};
    this.loader = true;

    this.sanitaryFrameForm.controls['descriptions']['controls'].forEach(control => {
      if (control.disabled) {
        control.controls.title.enable();
      }
    });

    request = this.sanitaryFramesService.updateSanitaryFrame(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.activatedRoute.snapshot.params.sanitary_frame_id,
      this.sanitaryFrameForm.value
    );

    request.subscribe(response => {
      if (response['success']) {
        this.loader = false;
        this.modalSanitaryFrame.close();
        CustomNotifierService.getSuccessUpdate(this.notifier);
      } else if (response['errors']) {
        this.loader = false;
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(response['errors'], this.sanitaryFrameForm, this.notifier);
      } else {
        this.loader = false;
      }
    });
  }

  bindModal(modal) {
    this.modalSanitaryFrame = modal;
  }

  closeModal() {
    this.router.navigate([this.activatedRoute.snapshot.parent.url.join('/')]).then(() => {
      this.allSanitaryFrames.refreshData();
    });
  }

  canDelete(i) {
    if (this.typeUrl === 'monthly' || this.typeUrl === 'quarterly') {
      return i > 1;
    }
    return i > 0;
  }
}
