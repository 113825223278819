import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AllSanitaryWorksheetsModule } from './all-sanitary-worksheets/all-sanitary-worksheets.module';
import { UpdateSanitaryWorksheetModule } from './update-sanitary-worksheet/update-sanitary-worksheet.module';
import { UploadSanitaryWorksheetFileModule } from './upload-sanitary-worksheet-file/upload-sanitary-worksheet-file.module';

import { SanitaryWorksheetsRequestService } from '../../services/sanitary-worksheets.request.service';


@NgModule({
  imports: [
    CommonModule,
    AllSanitaryWorksheetsModule,
    UpdateSanitaryWorksheetModule,
    UploadSanitaryWorksheetFileModule,
  ],
  providers: [
    SanitaryWorksheetsRequestService
  ]
})
export class SanitaryWorksheetsModule {}
