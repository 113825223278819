import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AllFirePermitsModule } from './all-fire-permits/all-fire-permits.module';
import { UpdateFirePermitModule } from './update-fire-permit/update-fire-permit.module';
import { UploadFirePermitFileModule } from './upload-fire-permit-file/upload-fire-permit-file.module';

import { FirePermitsRequestService } from '../../services/fire.permits.request.service';
import { AllFirePermitActionsModule } from './all-fire-permit-actions/all-fire-permit-actions.module';
import { UpdateFirePermitActionModule } from './update-fire-permit-action/update-fire-permit-action.module';

@NgModule({
  imports: [
    CommonModule,
    AllFirePermitsModule,
    UpdateFirePermitModule,
    UploadFirePermitFileModule,
    AllFirePermitActionsModule,
    UpdateFirePermitActionModule
  ],
  providers: [
    FirePermitsRequestService
  ]
})
export class FirePermitsModule {}
