import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PaginationModalComponent } from './pagination-modal.component';

@NgModule({
  declarations: [PaginationModalComponent],
  exports: [PaginationModalComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class PaginationModalModule { }
