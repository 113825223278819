<app-modal-right (eventOnClose)="closeModal()" #prescription>{{ bindModal(prescription) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">{{ canUpdate ? 'Mise à jour' : 'Consultation' }}</h6>

        <h1 class="header-title">Prescription des RVRAT</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <form [formGroup]="rvratPrescriptionStateForm" [hidden]="loaderContent">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="validationName">Prescription</label>
            <input type="text" formControlName="name" class="form-control" id="validationName" placeholder="Nom de la prescription" [ngClass]="{ 'is-invalid': name.invalid && (name.dirty || name.touched) }">
            <div *ngIf="name.invalid && (name.dirty || name.touched)" class="invalid-feedback">
              {{ getErrorMessage("name") }}
            </div>
          </div>

          <div class="form-group">
            <label for="validationPersonChangingState">Personne levant l'observation</label>
            <input type="text" formControlName="person_changing_state" class="form-control" id="validationPersonChangingState" placeholder="Personne levant l'observation" [ngClass]="{ 'is-invalid': person_changing_state.invalid && (person_changing_state.dirty || person_changing_state.touched) }" maxlength="255">
            <div *ngIf="person_changing_state.invalid && (person_changing_state.dirty || person_changing_state.touched)" class="invalid-feedback">
              {{ getErrorMessage("person_changing_state") }}
            </div>
          </div>

          <div class="form-group d-flex justify-content-start align-items-center">
            <div class="col pl-0">
              Levée la prescription
            </div>
            <div class="custom-checkbox-toggle">
              <input type="checkbox" class="custom-control-input" id="state" formControlName="state" [ngClass]="{ 'backgroundColor': state.value }" (change)="changeState()">
              <label class="custom-control-label" [ngClass]="{ 'backgroundColor': state.value }" for="state"></label>
            </div>
          </div>

          <div class="form-group" *ngIf="state.value">
            <label for="validationFinancialReal">Coût réel</label>
            <input type="number" formControlName="financial_real" class="form-control" id="validationFinancialReal" placeholder="0" [ngClass]="{ 'is-invalid': financial_real.invalid && (financial_real.dirty || financial_real.touched) }" min="1" step="1">
            <div *ngIf="financial_real.invalid && (financial_real.dirty || financial_real.touched)" class="invalid-feedback">
              {{ getErrorMessage("financial_real") }}
            </div>
          </div>

          <div class="form-group" *ngIf="state.value">
            <label for="validationIssuanceBuildingDate">Date de levée de l'observation</label>
            <input type="date" formControlName="date_changing_state" class="form-control" id="validationIssuanceBuildingDate" placeholder="Date de levée de l'observation" data-toggle="flatpickr"
                   [ngClass]="{ 'is-invalid': date_changing_state.invalid && (date_changing_state.dirty || date_changing_state.touched) }">
            <div *ngIf="date_changing_state.invalid && (date_changing_state.dirty || date_changing_state.touched)" class="invalid-feedback">
              {{ getErrorMessage("date_changing_state") }}
            </div>
          </div>

          <div class="form-group">
            <label for="validationCommentChangingState" required>Commentaires</label>
            <textarea formControlName="comment_changing_state" class="form-control" id="validationCommentChangingState" placeholder="Commentaires" rows="5"
                      [ngClass]="{ 'is-invalid': comment_changing_state.invalid && (comment_changing_state.dirty || comment_changing_state.touched) }"></textarea>
            <div *ngIf="comment_changing_state.invalid && (comment_changing_state.dirty || comment_changing_state.touched)" class="invalid-feedback">
              {{ getErrorMessage("comment_changing_state") }}
            </div>
          </div>

          <div class="form-group" *ngIf="isNew || canUpdate">
            <label for="inputFile">Documents</label>

            <div class="dropzone dropzone-multiple dz-clickable" appDragDrop (dropHandler)="onFileChange($event)">
              <div class="dz-default dz-message" (click)="chooseFile()">
                <span required>Déposer vos fichiers ici pour télécharger</span>
              </div>
              <input id="inputFile" (change)="onFileChange($event)" type="file" [hidden]="true" multiple>
            </div>
          </div>

          <div class="card" *ngFor="let file of files; let index = index">
            <div class="card-body">
              <div class="d-flex align-content-center justify-content-between">
                <h4 class="card-title mb-0 name align-self-center">
                  {{ file.name + '.' + file.extension }}
                </h4>

                <a class="href tooltipHtml" data-placement="bottom" title="Supprimer" (click)="removeFile(index)" *ngIf="isNew || canUpdate">
                  <i class="fe fe-trash"></i>
                </a>
              </div>
            </div>
          </div>

          <div class="form-group" *ngIf="!loaderContent && prescriptions.length">
            <label for="listPrescriptions">Autres prescriptions</label>
            <ng-select [items]="prescriptions"
                       [multiple]="true"
                       bindLabel="name"
                       bindValue="id"
                       groupBy="group"
                       notFoundText="Aucun résultat"
                       [selectableGroup]="true"
                       [selectableGroupAsModel]="false"
                       [closeOnSelect]="false"
                       placeholder="Sélectionnez une/ou plusieurs prescription(s)"
                       [(ngModel)]="selectedPrescriptions" [ngModelOptions]="{ standalone: true }" [disabled]="!isNew && !canUpdate">
              <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                <input type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }"/> Sélectionnez tout
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="listPrescriptions" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }"/> {{ item.name }}
              </ng-template>
            </ng-select>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div modal-footer class="w-100" *ngIf="canUpdate">
    <button class="btn btn-primary w-100" (click)="updatePrescriptionState()" [ngClass]="{ 'is-loading is-loading-sm': loader }" [disabled]="loader || rvratPrescriptionStateForm.invalid || (state.value && (!files.length && !comment_changing_state.value))">
      Mettre à jour
    </button>
  </div>
</app-modal-right>
