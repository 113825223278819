import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AllPreventionRisksModule } from './all-prevention-risks/all-prevention-risks.module';
import { UpdatePreventionRiskModule } from './update-prevention-risk/update-prevention-risk.module';

import { PreventionsRequestService } from '../../services/preventions.request.service';

@NgModule({
  imports: [
    CommonModule,
    AllPreventionRisksModule,
    UpdatePreventionRiskModule,
  ],
  providers: [
    PreventionsRequestService
  ]
})
export class PreventionRisksModule {}
