import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DashboardSurveillanceComponent } from './dashboard-surveillance.component';
import { BreadcrumbsModule } from '../../../core/breadcrumbs/breadcrumbs.module';
import { CalendarModule } from '../../../core/calendar/calendar.module';
import { ExportDownloadModule } from '../../export-download/export-download.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { SearchbarModule } from '../../../core/searchbar/searchbar.module';
import { PaginationModule } from '../../../core/pagination/pagination.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    BreadcrumbsModule,
    ExportDownloadModule,
    CalendarModule,
    NgSelectModule,
    SearchbarModule,
    PaginationModule
  ],
  declarations: [DashboardSurveillanceComponent]
})

export class DashboardSurveillanceModule {}
