import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Subject } from 'rxjs';
import { NgbTimeAdapter } from '@ng-bootstrap/ng-bootstrap';

import { AllSanitaryTemperaturesComponent } from '../all-sanitary-temperatures/all-sanitary-temperatures.component';
import { SanitaryCheckpointsRequestService } from '../../../../services/sanitary-checkpoints.request.service';
import { SanitaryTemperaturesRequestService } from '../../../../services/sanitary-temperatures.request.service';
import { SanitaryInstallationsRequestService } from '../../../../services/sanitary-installations.request.service';
import { EstablishmentsRequestService } from '../../../../services/establishments.request.service';

import { NgbTimeStringAdapter } from '../../../../core/utils/ngb-time-string-adapter';
import { CustomNotifierService } from '../../../../services/custom.notifier.service';

import SanitaryCheckpoint from '../../../../../shared/interfaces/sanitary-checkpoint';
import Establishment from '../../../../../shared/interfaces/establishment';
import BatiFile from '../../../../../shared/interfaces/bati_file';
import { ConfirmModalComponent } from '../../../../core/confirm-modal/confirm-modal.component';

declare function Dropzones(): any;

@Component({
  selector: 'app-update-sanitary-temperature',
  templateUrl: './update-sanitary-temperature.component.html',
  styleUrls: ['./update-sanitary-temperature.component.scss'],
  providers: [{ provide: NgbTimeAdapter, useClass: NgbTimeStringAdapter }]
})
export class UpdateSanitaryTemperatureComponent implements OnInit, AfterViewInit, OnDestroy {
  counter = Array;
  canCreate = false;
  canUpdate = false;

  activeTab = 1;

  requestInProgress = new Subject();
  requestCount = 0;

  sanitaryTemperatureForm: FormGroup;
  name = new FormControl(null, Validators.required);
  sanitary_installation_id = new FormControl(null, Validators.required);
  descriptions = new FormControl('');
  frame = new FormControl(null, Validators.required);

  files: BatiFile[] = [];
  filesRequired: BatiFile[] = [];
  sanitaryCheckpoints: SanitaryCheckpoint[] = [];
  establishment: Establishment;
  sanitaryInstallations = [];
  enterprises: any = [];
  user: string;

  errors = {};

  frameLists = [
    {title: 'Quotidienne'},
    {title: 'Hebdomadaire'}
  ];

  isSanitary = false;
  isNew = true;
  loader = false;
  loaderContent = false;

  modalSanitaryTemperature = null;
  modalUpdateFrame: ConfirmModalComponent = null;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private establishmentsService: EstablishmentsRequestService,
    private allSanitaryTemperature: AllSanitaryTemperaturesComponent,
    private sanitaryTemperatureServices: SanitaryTemperaturesRequestService,
    private sanitaryCheckpointsServices: SanitaryCheckpointsRequestService,
    private sanitaryInstallationsServices: SanitaryInstallationsRequestService,
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loaderContent = this.requestCount > 0;
    });

    this.sanitaryTemperatureForm = this.formBuilder.group({
      name: this.name,
      sanitary_installation_id: this.sanitary_installation_id,
      frame: this.frame,
      descriptions: this.formBuilder.array([]),
    });

    if (this.activatedRoute.snapshot.params.sanitary_temperature_id) {
      this.isNew = false;
      this.getSanitaryTemperature();
    } else {
      this.getSanitaryCheckpoints();
    }

    this.getEstablishment();
    this.getSanitaryInstallations();
  }

  ngAfterViewInit() {
    Dropzones();
    this.modalSanitaryTemperature.open();

    this.modalUpdateFrame.eventOnClose.subscribe(event => {
      if (event) {
        this.updateSanitaryTemperature();
      }
    });
  }

  ngOnDestroy() {
    const flatPicker = document.getElementsByClassName('flatpickr-calendar') as HTMLCollection;
    for (let i = flatPicker.length; i >= 0; i--) {
      if (flatPicker[i]) {
        flatPicker[i].remove();
      }
    }

    const dropZones = document.getElementsByClassName('dz-hidden-input') as HTMLCollection;
    for (let i = 0; i < dropZones.length; i++) {
      dropZones[i].remove();
    }

    this.clearTooltip();

    this.modalSanitaryTemperature.close();
  }
  clearTooltip() {
    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }
  changeTabs(activeTab) {
    this.activeTab = activeTab;
  }

  getEstablishment() {
    this.errors = {};
    this.requestInProgress.next(true);

    const establishment_id = this.activatedRoute.snapshot.parent.params.establishment_id;
    let request;
    request = this.establishmentsService.getMyEstablishment(establishment_id);

    request.subscribe(response => {
      if (response['success']) {
        this.establishment = response['establishment'];
        if (this.establishment.establishment_type.name === 'ERP' &&
            this.establishment.establishment_subtype_establishments[0]['establishment_subtype']['name'] === 'U') {
            this.isSanitary = true;
        } else if (this.establishment.establishment_type.name === 'IGH' &&
                   this.establishment.establishment_subtype_establishments[0]['establishment_subtype']['name'] === 'GHU') {
            this.isSanitary = true;
        } else if (this.establishment.establishment_type.name === 'BHC' &&
                   this.establishment.establishment_category.name === 'Quatrième famille') {
            this.isSanitary = true;
        } else {
          this.frame.setValue('Mensuelle');
        }
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  getSanitaryInstallations() {
    let request;
    this.requestInProgress.next(true);
    this.loader = true;

    request = this.sanitaryInstallationsServices.getAllSanitaryInstallations(
      '', '', '', 0, 1,
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
    );

    request.subscribe(response => {
      if (response['success']) {
        this.sanitaryInstallations = response['sanitaryInstallations'];
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loader = false;
      this.requestInProgress.next(false);
    });
  }
  getSanitaryCheckpoints() {
    let request;
    this.requestInProgress.next(true);
    this.loader = true;

    request = this.sanitaryCheckpointsServices.getAllSanitaryCheckpointsByType('surveillance');

    request.subscribe(response => {
      if (response['success']) {
        this.sanitaryCheckpoints = response['sanitaryCheckpoints'];
        const control = <FormArray>this.sanitaryTemperatureForm.controls['descriptions'];
        this.sanitaryCheckpoints.forEach(checkpoint => {
          const tempFrequency = this.formBuilder.array([]);
          const tempPointList = this.formBuilder.array([]);
          checkpoint.frequencies.forEach(frequency => {
            tempFrequency.push(this.formBuilder.group({
              lineId: this.formBuilder.control(this.getRandomIdentifier()),
              frequency_duration: this.formBuilder.control(frequency.frequency_duration),
              frequency: this.formBuilder.control(frequency.frequency),
            }));
          });
          tempPointList.push(this.formBuilder.group({
            lineId: this.formBuilder.control(this.getRandomIdentifier()),
            value: this.formBuilder.control(''),
          }));
          control.push(this.formBuilder.group({
            lineId: this.formBuilder.control(this.getRandomIdentifier()),
            title: checkpoint.name,
            more_information: checkpoint.more_information,
            pointLists: tempPointList,
            recommendation: this.formBuilder.control(
              {value: checkpoint.recommendation, disabled: true}
            ),
            frequencies: tempFrequency,
            isNew: this.formBuilder.control(false),
          }));
        });
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loader = false;
      this.requestInProgress.next(false);
    });
  }

  getSanitaryTemperature() {
    this.requestInProgress.next(true);
    this.sanitaryTemperatureServices.getSanitaryTemperature(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.activatedRoute.snapshot.params.sanitary_temperature_id
    ).subscribe(response => {
      if (response['success']) {
        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];

        response['sanitaryTemperature']['descriptions'].forEach((description, indexDescription) => {
          this.addDescriptions(description, indexDescription);
        });
        this.sanitaryTemperatureForm.patchValue(response['sanitaryTemperature']);
        this.sanitaryTemperatureForm[`controls`].frame.disable();

        if (!this.canUpdate) {
          this.sanitaryTemperatureForm.disable();
        }
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  addDescriptions(description, indexDescription) {
    const control = <FormArray>this.sanitaryTemperatureForm.controls['descriptions'];
    control.push(this.formBuilder.group({
      isNew: this.formBuilder.control(''),
      lineId: this.formBuilder.control(this.isNew ? this.getRandomIdentifier() : ''),
      title: this.formBuilder.control(''),
      more_information: this.formBuilder.control(''),
      pointLists: this.formBuilder.array([]),
      recommendation: this.formBuilder.control(
        {value: null, disabled: false}
      ),
      frequencies: this.formBuilder.array([]),
    }));
    description.pointLists.forEach(() => {
      this.addPointList(indexDescription);
    });
    description.frequencies.forEach(() => {
      this.addFrequency(indexDescription);
    });
  }
  deleteDescription(indexDescription) {
    const control = <FormArray>this.sanitaryTemperatureForm.controls['descriptions'];
    control.removeAt(indexDescription);
    this.clearTooltip();
  }
  addPointList(indexDescription) {
    this.clearTooltip();
    const control = <FormArray>this.sanitaryTemperatureForm.controls['descriptions']['controls'][indexDescription].controls['pointLists'];
    control.push(this.formBuilder.group({
      lineId: this.formBuilder.control(this.isNew ? this.getRandomIdentifier() : ''),
      value: this.formBuilder.control(''),
    }));
  }
  addNewPointList(indexDescription) {
    this.clearTooltip();
    const control = <FormArray>this.sanitaryTemperatureForm.controls['descriptions']['controls'][indexDescription].controls['pointLists'];
    control.push(this.formBuilder.group({
      lineId: this.formBuilder.control(this.getRandomIdentifier()),
      value: this.formBuilder.control(''),
    }));
  }
  deletePointList(indexDescription, indexPointList) {
    const control = <FormArray>this.sanitaryTemperatureForm.controls['descriptions']['controls'][indexDescription].controls['pointLists'];
    control.removeAt(indexPointList);
    this.clearTooltip();
  }
  addFrequency(indexDescription) {
    const control = <FormArray>this.sanitaryTemperatureForm.controls['descriptions']['controls'][indexDescription].controls['frequencies'];
    control.push(this.formBuilder.group({
      lineId: this.formBuilder.control(this.isNew ? this.getRandomIdentifier() : ''),
      frequency_duration: this.formBuilder.control(null, Validators.required),
      frequency: this.formBuilder.control(null, Validators.required),
    }));
  }
  deleteFrequency(indexDescription, indexFrequency) {
    const control = <FormArray>this.sanitaryTemperatureForm.controls['descriptions']['controls'][indexDescription].controls['frequencies'];
    control.removeAt(indexFrequency);
    this.clearTooltip();
  }
  addNewDescription() {
    const control = <FormArray>this.sanitaryTemperatureForm.controls['descriptions'];
    control.push(this.formBuilder.group({
      isNew: this.formBuilder.control(true),
      lineId: this.formBuilder.control(this.getRandomIdentifier()),
      title: this.formBuilder.control('', Validators.required),
      more_information: this.formBuilder.control(''),
      pointLists: this.formBuilder.array([]),
      recommendation: this.formBuilder.control(''),
      frequencies: this.formBuilder.array([]),
    }));
    this.addNewPointList(this.sanitaryTemperatureForm.controls['descriptions'].value.length - 1);
    this.addFrequency(this.sanitaryTemperatureForm.controls['descriptions'].value.length - 1);
  }

  private getRandomIdentifier() {
    const date = new Date();
    const number = date.getTime();
    return parseInt(Math.floor((Math.random() * 500) + 1).toString(10) + number.toString(10), 10);
  }

  getFrequencyText(frequency, number) {
    if (frequency === 'year') {
      if (number > 1) {
        return number + ' ans';
      } else {
        return number + ' an';
      }
    } else if (frequency === 'month') {
      return number + ' mois';
    } else if (frequency === 'week') {
      if (number > 1) {
        return number + ' semaines';
      } else {
        return number + ' semaine';
      }
    } else if (frequency === 'day') {
      if (number > 1) {
        return number + ' jours';
      } else {
        return number + ' jour';
      }
    }
  }

  createSanitaryTemperature() {
    let request;
    this.errors = {};
    this.loader = true;

    const control = <FormArray>this.sanitaryTemperatureForm.controls['descriptions'];
    control.controls.forEach(element => {
      element['controls'].recommendation.enable();
      element['controls'].recommendation.updateValueAndValidity();
    });

    const data = {
      ...this.sanitaryTemperatureForm.value,
    };

    request = this.sanitaryTemperatureServices.createSanitaryTemperature(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      data
    );

    request.subscribe(response => {
      if (response['success']) {
        if (this.files.length || this.filesRequired.length) {
          this.createDocuments(response['sanitaryTemperature']['id']);
        } else {
          this.loader = false;
          this.modalSanitaryTemperature.close();
          CustomNotifierService.getSuccessCreate(this.notifier);
          this.router.navigate([
            'dashboard-registry/' + this.activatedRoute.snapshot.parent.params.registry_id +
            '/establishment/' + this.activatedRoute.snapshot.parent.params.establishment_id +
            '/sanitary-surveillances/temperatures/' + response['sanitaryTemperature']['id'] + '/frames/create']).then();
        }
      } else if (response['errors']) {
        this.loader = false;
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(response['errors'], this.sanitaryTemperatureForm, this.notifier);
      } else {
        this.loader = false;
      }
    });
  }
  updateSanitaryTemperature() {
    let request;

    this.errors = {};
    this.loader = true;

    const control = <FormArray>this.sanitaryTemperatureForm.controls['descriptions'];
    control.controls.forEach(element => {
      element['controls'].recommendation.enable();
      element['controls'].recommendation.updateValueAndValidity();
    });

    const data = {
      ...this.sanitaryTemperatureForm.value,
    };

    request = this.sanitaryTemperatureServices.updateSanitaryTemperature(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.activatedRoute.snapshot.params.sanitary_temperature_id,
      data
    );

    request.subscribe(response => {
      if (response['success']) {
        if (this.files.length || this.filesRequired.length) {
          this.createDocuments(response['sanitaryTemperature']['id']);
        } else {
          this.loader = false;
          this.modalSanitaryTemperature.close();
        }
        CustomNotifierService.getSuccessUpdate(this.notifier);
      } else if (response['errors']) {
        this.loader = false;
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      } else {
        this.loader = false;
      }
    });
  }
  createDocuments(sanitary_temperature_id) {
    let request;
    this.errors = {};

    request = this.sanitaryTemperatureServices.createFile(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      sanitary_temperature_id,
      [...this.files, ...this.filesRequired]
    );

    request.subscribe(response => {
      if (response['success']) {
        this.modalSanitaryTemperature.close();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loader = false;
    });
  }

  chooseFile() {
    const element: HTMLElement = document.getElementById('inputFile') as HTMLElement;
    element.click();
  }
  onFileChange(event) {
    if (event.target && event.target.files && event.target.files.length) {
      for (let i = 0; i < event.target.files.length; i++) {
        const reader = new FileReader();
        const file = event.target.files[i];
        reader.readAsDataURL(file);

        reader.onload = () => {
          let extension = '';
          let name = '';

          const split = file.name.split('.');
          if (split.length) {
            name = split[0];
            extension = split[split.length - 1];
          }

          this.files.push({ file_base64: reader.result, name: name, extension: extension });
        };
      }
    } else {
      let extension = '';
      let name = '';

      const split = event.name.split('.');
      if (split.length) {
        name = split[0];
        extension = split[split.length - 1];
      }

      this.files.push({ file_base64: event.file, name: name, extension: extension });
    }
    const element: HTMLInputElement = document.getElementById('inputFile') as HTMLInputElement;
    element.value = '';
  }
  removeFile(index) {
    this.files.splice(index, 1);

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  bindModal(modal) {
    this.modalSanitaryTemperature = modal;
  }
  closeModal() {
    this.router.navigate([this.activatedRoute.snapshot.parent.url.join('/')]).then(() => {
      this.allSanitaryTemperature.refreshData();
    });
  }
  bindModalUpdateFrame(modal) {
    this.modalUpdateFrame = modal;
  }
}
