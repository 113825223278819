<div class="main-content">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col">
        <div class="header mb-0">
          <div class="header-body no-border">
            <div class="row align-items-center">
              <div class="col">
                <h1 class="header-title">Historique des actions</h1>

                <small class="form-text text-muted">
                  <span>Listes des actions réalisées sur votre entreprise</span>
                </small>

                <app-breadcrumbs [currentPage]="pageName" [names]="previousPageName" [routes]="previousPageRoute"></app-breadcrumbs>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-12 mb-4">
            <app-searchbar [(ngModel)]="search" (keyup)="searchData()" ngDefaultControl></app-searchbar>
          </div>
          <div class="col-lg-6" *ngIf="registries">
            <div class="form-group">
              <label></label>
              <ng-select [items]="registries"
                         [multiple]="true"
                         bindLabel="name"
                         notFoundText="Aucun résultat"
                         [selectableGroup]="true"
                         [closeOnSelect]="false"
                         bindValue="id"
                         placeholder="Sélectionnez un/des registre(s)"
                         [(ngModel)]="selectedRegistries" (ngModelChange)="getLogs()" [ngModelOptions]="{ standalone: true }">
                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                  <input type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }" /> {{ item.name }}
                </ng-template>
              </ng-select>
            </div>
          </div>
          <div class="col-lg-6" *ngIf="establishments">
            <div class="form-group">
              <label></label>
              <ng-select [items]="establishments"
                         [multiple]="true"
                         bindLabel="name"
                         notFoundText="Aucun résultat"
                         [selectableGroup]="true"
                         [closeOnSelect]="false"
                         bindValue="id"
                         placeholder="Sélectionnez un/des établissement(s)"
                         [(ngModel)]="selectedEstablishments" (ngModelChange)="getLogs()" [ngModelOptions]="{ standalone: true }">
                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                  <input type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }" /> {{ item.name }}
                </ng-template>
              </ng-select>
            </div>
          </div>
          <div [ngClass]="{ 'col-12': !users.length, 'col-lg-6': users.length }" *ngIf="enterprises">
            <div class="form-group">
              <label></label>
              <ng-select [items]="enterprises"
                         [multiple]="true"
                         bindLabel="name"
                         notFoundText="Aucun résultat"
                         [selectableGroup]="true"
                         [closeOnSelect]="false"
                         bindValue="id"
                         placeholder="Sélectionnez une/des entreprise(s)"
                         [(ngModel)]="selectedEnterprises" (ngModelChange)="getLogs(); getUsers()" [ngModelOptions]="{ standalone: true }">
                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                  <input type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }" /> {{ item.name }}
                </ng-template>
              </ng-select>
            </div>
          </div>
          <div class="col-lg-6" *ngIf="users.length">
            <div class="form-group">
              <label></label>
              <ng-select [items]="users"
                         [multiple]="true"
                         bindLabel="full_name"
                         notFoundText="Aucun résultat"
                         [selectableGroup]="true"
                         [closeOnSelect]="false"
                         bindValue="id"
                         placeholder="Sélectionnez un/des utilisateur(s)"
                         [(ngModel)]="selectedUsers" (ngModelChange)="getLogs()" [ngModelOptions]="{ standalone: true }">
                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                  <input type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }" /> {{ item.full_name }}
                </ng-template>
              </ng-select>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="dateFrom">Du</label>
              <input type="date" [(ngModel)]="dateFrom" (ngModelChange)="getLogs()" class="form-control" id="dateFrom" placeholder="Date du" data-toggle="flatpickr">
            </div>
          </div>
          <!-- DATE TO -->
          <div class="col-lg-6">
            <div class="form-group">
              <label for="dateTo">Au</label>
              <input type="date" [(ngModel)]="dateTo" (ngModelChange)="getLogs()" class="form-control" id="dateTo" placeholder="Date au" data-toggle="flatpickr">
            </div>
          </div>
        </div>

        <div class="tab-content">
          <div class="tab-pane fade active show" id="tabPaneOne" role="tabpanel">
            <div class="row listAlias">
              <div class="col-12 d-flex flex-column">
                <div class="card flex-fill">
                  <div class="card-body">
                    <div class="table-responsive">
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th class="href" (click)="filter('AllLogs.created')">
                              <a class="text-muted">Créé le</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'AllLogs.created' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'AllLogs.created' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'AllLogs.created'"></span>
                            </th>
                            <th class="href" (click)="filter('AllLogs.author_user')">
                              <a class="text-muted">Utilisateur</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'AllLogs.author_user' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'AllLogs.author_user' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'AllLogs.author_user'"></span>
                            </th>
                            <th class="href" (click)="filter('AllLogs.author_enterprise')">
                              <a class="text-muted">Entreprise</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'AllLogs.author_enterprise' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'AllLogs.author_enterprise' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'AllLogs.author_enterprise'"></span>
                            </th>
                            <th>Registre</th>
                            <th>Établissement</th>
                            <th>Message</th>
                          </tr>
                        </thead>
                        <tbody>
                          <!-- loader -->
                          <tr *ngIf="loader">
                            <td colspan="9">
                              <div class="is-loading loader-content"></div>
                            </td>
                          </tr>
                          <!-- no results -->
                          <tr *ngIf="!loader && !logs.length">
                            <td colspan="9">
                              <div class="loader-content">
                                Aucun résultat
                              </div>
                            </td>
                          </tr>
                          <tr *ngFor="let log of logs">
                            <td>{{ log.created | date : 'dd/MM/yyyy HH:mm' }}</td>
                            <td>{{ log.author_user }}</td>
                            <td>{{ log.author_enterprise }}</td>
                            <td>{{ log.registry_name ? log.registry_name.name : '' }}</td>
                            <td>{{ log.establishment_name ? log.establishment_name.name : '' }}</td>
                            <td><div class="message-html" [innerHTML]="log.message"></div></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <app-pagination (returnEvent)="dispatchPagination($event)"
                                    [count_elements]="count"
                                    [current_page]="page"
                                    [elements_length]="logs.length"
                                    [limit]="limit"
                                    [total_pages]="pages"
                                    ngDefaultControl>
                    </app-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<router-outlet></router-outlet>
