import { Routes } from '@angular/router';

import { AllInterventionsComponent } from './all-interventions/all-interventions.component';
import { UpdateInterventionComponent } from './update-intervention/update-intervention.component';
import { DetailsInterventionComponent } from './details-intervention/details-intervention.component';
import { UploadInterventionFileComponent } from './upload-intervention-file/upload-intervention-file.component';

import { ModalLogsComponent } from '../logs/modal-logs/modal-logs.component';
import { DashboardRegistryComponent } from '../registries/dashboard-registry/dashboard-registry.component';

export const interventionsRoutes: Routes = [
  {
    path: 'interventions',
    component: AllInterventionsComponent,
    children: [
      {
        path: ':intervention_id',
        component: UpdateInterventionComponent
      },
      {
        path: ':intervention_id/details',
        component: DetailsInterventionComponent
      },
      {
        path: ':intervention_id/logs',
        component: ModalLogsComponent
      }
    ]
  },
  {
    path: 'interventions/:intervention_id/files',
    component: AllInterventionsComponent,
    children: [
      {
        path: 'create',
        component: UploadInterventionFileComponent
      },
      {
        path: ':file_id',
        component: UploadInterventionFileComponent
      }
    ]
  },
  {
    path: 'dashboard-registry/:registry_id/interventions',
    component: DashboardRegistryComponent,
    children: [
      {
        path: ':intervention_id',
        component: UpdateInterventionComponent
      },
    ]
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/interventions',
    component: DashboardRegistryComponent,
    children: [
      {
        path: ':intervention_id',
        component: UpdateInterventionComponent
      },
    ]
  },
];
