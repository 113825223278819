import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { LogsRequestService } from '../../../services/logs.request.service';

@Component({
  selector: 'app-modal-logs',
  templateUrl: './modal-logs.component.html',
  styleUrls: ['./modal-logs.component.scss']
})
export class ModalLogsComponent implements OnInit, AfterViewInit, OnDestroy {
  logs: any[] = [];

  count = 0;
  pages = 1;

  search = '';
  sort = '';
  sortBy = '';
  order = 'desc';
  limit = 12;
  page = 1;

  timeout: any;
  loader = false;

  modalLogs = null;

  constructor(
    private router: Router,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private logsRequestService: LogsRequestService
  ) {}

  ngOnInit() {
    this.getSpecificLogs();
  }

  ngAfterViewInit() {
    this.modalLogs.open();
  }

  ngOnDestroy() {
    this.modalLogs.close();
  }

  searchData() {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.page = 1;
      this.getSpecificLogs();
    }, 500);
  }

  getSpecificLogs() {
    let request;
    const routeSnapshot = this.activatedRoute.snapshot;
    this.logs = [];
    this.loader = true;

    if (routeSnapshot.params.user_id) {
      if (routeSnapshot.parent.url[0].path === 'users' || routeSnapshot.parent.url[2].path === 'users'
        || routeSnapshot.parent.url[4].path === 'providers') {
        /* LOGS USER */
        this.sortBy = 'User';
        this.sort = 'UserLogs.created';

        request = this.logsRequestService.getLogUser(
          this.search, this.sort, this.order, this.limit, this.page,
          routeSnapshot.params.user_id);
      } else if (routeSnapshot.parent.url[4].path === 'risks-users') {
        /* LOGS RISK USER */
        this.sortBy = 'RiskUser';
        this.sort = 'RiskUserLogs.created';

        request = this.logsRequestService.getLogRiskUser(
          this.search, this.sort, this.order, this.limit, this.page,
          routeSnapshot.params.user_id);
      }
    } else if (routeSnapshot.params.establishment_id) {
      /* LOGS ESTABLISHMENT */
      let enterprise_id = '';
      this.sortBy = 'Establishment';
      this.sort = 'EstablishmentLogs.created';

      if (routeSnapshot.parent.params.enterprise_id) {
        enterprise_id = routeSnapshot.parent.params.enterprise_id;
      }

      request = this.logsRequestService.getLogEstablishment(
        this.search, this.sort, this.order, this.limit, this.page,
        routeSnapshot.params.establishment_id, enterprise_id);
    } else if (routeSnapshot.params.enterprise_id) {
      /* LOGS ENTERPRISE */
      this.sortBy = 'Enterprise';
      this.sort = 'EnterpriseLogs.created';

      request = this.logsRequestService.getLogEnterprise(
        this.search, this.sort, this.order, this.limit, this.page,
        routeSnapshot.params.enterprise_id,
        routeSnapshot.parent.url[0].path);
    } else if (routeSnapshot.params.report_id) {
      if (routeSnapshot.parent.url[4].path === 'report-worksheets') {
        /* LOGS REPORT WORKSHEET */
        this.sortBy = 'ReportWorksheet';
        this.sort = 'ReportWorksheetLogs.created';

        request = this.logsRequestService.getLogReportWorksheet(
          this.search, this.sort, this.order, this.limit, this.page,
          routeSnapshot.params.report_id);
      } else if (routeSnapshot.parent.url[8].path === 'reports') {
        /* LOGS REPORT */
        this.sortBy = 'Report';
        this.sort = 'ReportLogs.created';

        request = this.logsRequestService.getLogReport(
          this.search, this.sort, this.order, this.limit, this.page,
          routeSnapshot.params.report_id);
      }
    } else if (routeSnapshot.params.asbestos_detection_id) {
      /* LOGS ASBESTOS DETECTION */
      this.sortBy = 'AsbestosDetection';
      this.sort = 'AsbestosDetectionLogs.created';

      request = this.logsRequestService.getLogAsbestosDetection(
        this.search, this.sort, this.order, this.limit, this.page,
        routeSnapshot.params.asbestos_detection_id);
    } else if (routeSnapshot.params.asbestos_work_id) {
      /* LOGS ASBESTOS WORK */
      this.sortBy = 'AsbestosWork';
      this.sort = 'AsbestosWorkLogs.created';

      request = this.logsRequestService.getLogAsbestosWork(
        this.search, this.sort, this.order, this.limit, this.page,
        routeSnapshot.params.asbestos_work_id);
    } else if (routeSnapshot.params.commission_id) {
      /* LOGS COMMISSION */
      this.sortBy = 'Commission';
      this.sort = 'CommissionLogs.created';

      request = this.logsRequestService.getLogCommission(
        this.search, this.sort, this.order, this.limit, this.page,
        routeSnapshot.params.commission_id);
    } else if (routeSnapshot.params.date_training_id) {
      /* LOGS DATE TRAINING */
      this.sortBy = 'DateTraining';
      this.sort = 'DateTrainingLogs.created';

      request = this.logsRequestService.getLogDateTraining(
        this.search, this.sort, this.order, this.limit, this.page,
        routeSnapshot.params.date_training_id);
    } else if (routeSnapshot.params.training_id) {
      /* LOGS TRAINING */
      this.sortBy = 'Training';
      this.sort = 'TrainingLogs.created';

      request = this.logsRequestService.getLogTraining(
        this.search, this.sort, this.order, this.limit, this.page,
        routeSnapshot.params.training_id);
    } else if (routeSnapshot.params.worksheet_id) {
      /* LOGS WORKSHEET */
      this.sortBy = 'Worksheet';
      this.sort = 'WorksheetLogs.created';

      request = this.logsRequestService.getLogWorksheet(
        this.search, this.sort, this.order, this.limit, this.page,
        routeSnapshot.params.worksheet_id);
    } else if (routeSnapshot.params.risk_id) {
      /* LOGS RISK */
      this.sortBy = 'Risk';
      this.sort = 'RiskLogs.created';

      request = this.logsRequestService.getLogRisk(
        this.search, this.sort, this.order, this.limit, this.page,
        routeSnapshot.params.risk_id);
    } else if (routeSnapshot.params.unity_id) {
      /* LOGS RISK UNITY */
      this.sortBy = 'RiskUnity';
      this.sort = 'RiskUnityLogs.created';

      request = this.logsRequestService.getLogRiskUnity(
        this.search, this.sort, this.order, this.limit, this.page,
        routeSnapshot.params.unity_id);
    } else if (routeSnapshot.params.parent_id) {
      /* LOGS DOCUMENT */
      this.sortBy = 'Document';
      this.sort = 'DocumentLogs.created';

      request = this.logsRequestService.getLogDocument(
        this.search, this.sort, this.order, this.limit, this.page,
        routeSnapshot.params.parent_id);
    } else if (routeSnapshot.url[0].path === 'registry-manager') {
      /* LOGS REGISTRY MANAGER */
      this.sortBy = 'RegistryManager';
      this.sort = 'RegistryManagerLogs.created';

      request = this.logsRequestService.getLogRegistryManager(
        this.search, this.sort, this.order, this.limit, this.page,
        routeSnapshot.parent.params.registry_id,
        routeSnapshot.parent.params.establishment_id);
    } else if (routeSnapshot.url[0].path === 'employee') {
      /* LOGS EMPLOYEE */
      this.sortBy = 'Employee';
      this.sort = 'EmployeeLogs.created';

      request = this.logsRequestService.getLogEmployee(
        this.search, this.sort, this.order, this.limit, this.page,
        routeSnapshot.parent.params.registry_id,
        routeSnapshot.parent.params.establishment_id);
    } else if (routeSnapshot.url[0].path === 'employee-formation') {
      /* LOGS EMPLOYEE TRAINING */
      this.sortBy = 'EmployeeTraining';
      this.sort = 'EmployeeTrainingLogs.created';

      request = this.logsRequestService.getLogEmployeeFormation(
        this.search, this.sort, this.order, this.limit, this.page,
        routeSnapshot.parent.params.registry_id,
        routeSnapshot.parent.params.establishment_id);
    } else if (routeSnapshot.params.intervention_id) {
      /* LOGS INTERVENTION VOUCHER */
      this.sortBy = 'InterventionVoucher';
      this.sort = 'InterventionVoucherLogs.created';

      request = this.logsRequestService.getLogInterventionVoucher(
        this.search, this.sort, this.order, this.limit, this.page,
        routeSnapshot.params.intervention_id);
    } else if (routeSnapshot.params.fire_permit_id) {
      /* LOGS INTERVENTION VOUCHER */
      this.sortBy = 'FirePermit';
      this.sort = 'FirePermitLogs.created';

      request = this.logsRequestService.getLogFirePermit(
        this.search, this.sort, this.order, this.limit, this.page,
        routeSnapshot.params.fire_permit_id);
    } else if (routeSnapshot.params.prevention_id) {
      /* LOGS PREVENTION PLAN */
      this.sortBy = 'Prevention';
      this.sort = 'PreventionLogs.created';

      request = this.logsRequestService.getLogPrevention(
        this.search, this.sort, this.order, this.limit, this.page,
        routeSnapshot.params.prevention_id);
    } else if (routeSnapshot.params.security_protocol_id) {
      /* LOGS SECURITY PROTOCOL */
      this.sortBy = 'SecurityProtocol';
      this.sort = 'SecurityProtocolLogs.created';

      request = this.logsRequestService.getLogSecurityProtocol(
        this.search, this.sort, this.order, this.limit, this.page,
        routeSnapshot.params.security_protocol_id);
    } else if (routeSnapshot.params.checkpoint_id) {
      /* LOGS ESTABLISHMENT CHECKPOINT */
      this.sortBy = 'EstablishmentCheckpoint';
      this.sort = 'EstablishmentCheckpointLogs.created';

      request = this.logsRequestService.getLogEstablishmentCheckpoint(
        this.search, this.sort, this.order, this.limit, this.page,
        routeSnapshot.params.checkpoint_id);
    } else if (routeSnapshot.params.fire_permit_id) {
      /* LOGS FIRE PERMIT */
      this.sortBy = 'FirePermit';
      this.sort = 'FirePermitLogs.created';

      request = this.logsRequestService.getLogFirePermit(
          this.search, this.sort, this.order, this.limit, this.page,
          routeSnapshot.params.fire_permit_id);
    } else if (routeSnapshot.params.sanitary_contributor_id) {
      /* LOGS SANITARY CONTRIBUTOR */
      this.sortBy = 'SanitaryContributor';
      this.sort = 'SanitaryContributorLogs.created';

      request = this.logsRequestService.getLogSanitaryContributor(
          this.search, this.sort, this.order, this.limit, this.page,
          routeSnapshot.params.sanitary_contributor_id);
    } else if (routeSnapshot.params.sanitary_installaton_id) {
      /* LOGS SANITARY INSTALLATION */
      this.sortBy = 'SanitaryInstallation';
      this.sort = 'SanitaryInstallationLogs.created';

      request = this.logsRequestService.getLogSanitaryInstallation(
          this.search, this.sort, this.order, this.limit, this.page,
          routeSnapshot.params.sanitary_installation_id);
    } else if (routeSnapshot.params.sanitary_worksheet_id) {
      /* LOGS SANITARY WORKSHEET */
      this.sortBy = 'SanitaryWorksheet';
      this.sort = 'SanitaryWorksheetLogs.created';

      request = this.logsRequestService.getLogSanitaryWorksheet(
          this.search, this.sort, this.order, this.limit, this.page,
          routeSnapshot.params.sanitary_worksheet_id);
    }  else if (routeSnapshot.params.sanitary_pool_id) {
      /* LOGS SANITARY POOL */
      this.sortBy = 'SanitaryPool';
      this.sort = 'SanitaryPoolLogs.created';

      request = this.logsRequestService.getLogSanitaryPool(
          this.search, this.sort, this.order, this.limit, this.page,
          routeSnapshot.params.sanitary_pool_id);
    }

    request.subscribe(response => {
      if (response['success']) {
        this.count = response['count'];
        this.pages = response['pages'];

        this.logs = response['logs'];
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loader = false;
    });
  }

  filter(property) {
    if (this.sort === property) {
      if (this.order === 'asc') {
        this.order = 'desc';
      } else {
        this.order = 'asc';
      }
    } else {
      this.sort = property;
      this.order = 'asc';
    }

    this.getSpecificLogs();
  }

  dispatchPagination(data) {
    if (data.action === 'total_elements') {
      this.refreshPage(data.value);
    } else if (data.action === 'change_page') {
      this.changePage(data.value);
    }
  }

  refreshPage(limit) {
    this.limit = limit;
    this.page = 1;
    this.getSpecificLogs();
  }

  changePage(page) {
    if (page >= 1 && page <= this.pages) {
      this.page = page;
      this.getSpecificLogs();
    }
  }

  bindModal(modal) {
    this.modalLogs = modal;
  }

  closeModal() {
    this.router.navigate([this.activatedRoute.snapshot.parent.url.join('/')]).then(() => {});
  }
}
