<app-modal-right (eventOnClose)="closeModal()" #legionella>{{ bindModal(legionella) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">{{ isNew ? 'Création' : canUpdate ? 'Mise à jour' : 'Consultation' }}</h6>

        <h1 class="header-title">Surveillance des légionnelles</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <form [formGroup]="sanitaryLegionellaFrameForm" [hidden]="loaderContent">
      <!-- ID / NAME -->
      <div class="form-group">
        <label for="validationTitle" required>Titre</label>
        <input type="text" formControlName="title" class="form-control" id="validationTitle" placeholder="Titre" [ngClass]="{ 'is-invalid': title.invalid && (title.dirty || title.touched) }">
      </div>
      <!-- CREATED DATE -->
      <div class="form-group">
        <label required="">Date de création</label>
        <input type="text" formControlName="date" class="form-control" placeholder="Date d'échéance" data-toggle="flatpickr" [ngClass]="{ 'is-invalid': date.invalid && (date.dirty || date.touched) }">
        <div *ngIf="date.invalid && (date.dirty || date.touched)" class="invalid-feedback">
          {{ getErrorMessage("date") }}
        </div>
      </div>

      <div class="form-group" *ngIf="listItems.length">
        <label required>Sélectionner un point de prélèvement</label>
        <ng-select [items]="listItems"
                   [multiple]="false"
                   bindLabel="title"
                   bindValue="title"
                   [selectableGroup]="false"
                   [selectableGroupAsModel]="false"
                   [closeOnSelect]="true"
                   placeholder="Sélectionner un point de prélèvement"
                   [(ngModel)]="selectedDescription" (ngModelChange)="changeDescription()" [ngModelOptions]="{ standalone: true }">
          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
            {{ item.title }}
          </ng-template>
        </ng-select>
      </div>

      <div class="form-group" formArrayName="descriptions">
        <div [formGroupName]="indexDescription" *ngFor="let description of sanitaryLegionellaFrameForm['controls'].descriptions['controls'] as FormGroup; let indexDescription = index;">

          <div *ngIf="description['controls'].title.value === selectedDescription">

            <div formArrayName="values">
              <div [formGroupName]="indexValue" class="form-group" [attr.id]="'value' + indexValue" *ngFor="let value of description['controls'].values['controls'] as FormGroup; let indexValue = index;">

                <div class="card" *ngIf="value['controls'].title.value">

                  <div class="card-header">
                    <div class="d-flex align-items-center">
                      <i data-toggle="collapse" [attr.href]="'#indexValue' + indexValue" aria-expanded="true" class="fe chevron mr-2 href collapsed"></i>
                      <p class="mb-0 my-sm-3 mx-sm-auto mx-lg-0">{{ value['controls'].title.value }}</p>
                    </div>
                  </div>

                  <div formArrayName="comments" class="row" class="card-body collapse"  [id]="'indexValue' + indexValue" [attr.id]="'indexValue' + indexValue" role="tabpanel" [attr.data-parent]="'#indexValue' + indexValue">
                    <div [formGroupName]="indexItem" class="col-12" *ngFor="let item of value['controls'].comments['controls'] as FormGroup; let indexItem = index; let firstComment = first, let lastComment = last">
                      <ng-container *ngIf="firstComment">
                        <div class="form-group">
                          <label>{{ item['controls'].title.value }}</label>
                          <input type="text" formControlName="comment" class="form-control" placeholder="Date" data-toggle="flatpickr" [ngClass]="{ 'is-invalid': item['controls'].comment.invalid && (item['controls'].comment.dirty || item['controls'].comment.touched) }">
                          <div *ngIf="item['controls'].comment.invalid && (item['controls'].comment.dirty || item['controls'].comment.touched)" class="invalid-feedback">
                            {{ getErrorMessage("comment") }}
                          </div>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="!firstComment && !lastComment">
                        <div class="form-group">
                          <label>{{ item['controls'].title.value }}</label>
                          <input type="text" formControlName="comment" class="form-control" placeholder="{{ item['controls'].title.value }}" [ngClass]="{ 'is-invalid': item['controls'].comment.invalid && (item['controls'].comment.dirty || item['controls'].comment.touched) }">
                          <div *ngIf="item['controls'].comment.invalid && (item['controls'].comment.dirty || item['controls'].comment.touched)" class="invalid-feedback">
                            {{ getErrorMessage("comment") }}
                          </div>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="lastComment">
                        <div class="form-group">
                          <label>{{ item['controls'].title.value }}</label>
                          <input type="text" formControlName="comment" class="form-control" placeholder="Date d'échéance" data-toggle="flatpickr" [ngClass]="{ 'is-invalid': item['controls'].comment.invalid && (item['controls'].comment.dirty || item['controls'].comment.touched) }">
                          <div *ngIf="item['controls'].comment.invalid && (item['controls'].comment.dirty || item['controls'].comment.touched)" class="invalid-feedback">
                            {{ getErrorMessage("comment") }}
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>

                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

    </form>
  </div>

  <div modal-footer class="w-100" *ngIf="isNew || canUpdate">
    <button class="btn btn-primary w-100" (click)="isNew ? createSanitaryLegionellaFrame() : updateSanitaryLegionellaFrame()" [ngClass]="{ 'is-loading is-loading-sm': loader }" [disabled]="loader || sanitaryLegionellaFrameForm.invalid">
      {{ isNew ? 'Créer' : 'Mettre à jour' }}
    </button>
  </div>
</app-modal-right>
