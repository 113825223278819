import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Subject } from 'rxjs';
import { NgbTimeAdapter } from '@ng-bootstrap/ng-bootstrap';

import { AllSanitaryLegionellasComponent } from '../all-sanitary-legionellas/all-sanitary-legionellas.component';
import { SanitaryLegionellasRequestService } from '../../../../services/sanitary-legionellas.request.service';
import { SanitaryCheckpointsRequestService } from '../../../../services/sanitary-checkpoints.request.service';

import { NgbTimeStringAdapter } from '../../../../core/utils/ngb-time-string-adapter';
import { CustomNotifierService } from '../../../../services/custom.notifier.service';

import SanitaryCheckpoint from '../../../../../shared/interfaces/sanitary-checkpoint';
import { SanitaryInstallationsRequestService } from '../../../../services/sanitary-installations.request.service';
import BatiFile from '../../../../../shared/interfaces/bati_file';
import { ConfirmModalComponent } from '../../../../core/confirm-modal/confirm-modal.component';

declare function Flatpickr(): any;
declare function Dropzones(): any;

@Component({
  selector: 'app-update-sanitary-legionella',
  templateUrl: './update-sanitary-legionella.component.html',
  styleUrls: ['./update-sanitary-legionella.component.scss'],
  providers: [{ provide: NgbTimeAdapter, useClass: NgbTimeStringAdapter }]
})
export class UpdateSanitaryLegionellaComponent implements OnInit, AfterViewInit, OnDestroy {
  counter = Array;
  canCreate = false;
  canUpdate = false;

  activeTab = 1;

  requestInProgress = new Subject();
  requestCount = 0;

  sanitaryLegionellaForm: FormGroup;
  name = new FormControl(null, Validators.required);
  sanitary_installation_id = new FormControl(null, Validators.required);
  descriptions = new FormControl('');

  files: BatiFile[] = [];
  filesRequired: BatiFile[] = [];
  sanitaryCheckpoints: SanitaryCheckpoint[] = [];
  sanitaryInstallations = [];
  enterprises: any = [];
  user: string;

  errors = {};

  isNew = true;
  loader = false;
  loaderContent = false;

  modalSanitaryLegionella = null;
  modalUpdateFrame: ConfirmModalComponent = null;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private allSanitaryLegionellas: AllSanitaryLegionellasComponent,
    private sanitaryLegionellaServices: SanitaryLegionellasRequestService,
    private sanitaryCheckpointsServices: SanitaryCheckpointsRequestService,
    private sanitaryInstallationsServices: SanitaryInstallationsRequestService,
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loaderContent = this.requestCount > 0;
    });

    this.sanitaryLegionellaForm = this.formBuilder.group({
      name: this.name,
      sanitary_installation_id: this.sanitary_installation_id,
      descriptions: this.formBuilder.array([]),
    });

    if (this.activatedRoute.snapshot.params.sanitary_legionella_id) {
      this.isNew = false;
      this.getSanitaryLegionella();
    } else {
      this.getSanitaryCheckpoints();
      setTimeout(() => {
        Flatpickr();
      }, 1);
    }
    this.getSanitaryInstallations();
  }

  ngAfterViewInit() {
    Dropzones();
    this.modalSanitaryLegionella.open();

    this.modalUpdateFrame.eventOnClose.subscribe(event => {
      if (event) {
        this.updateSanitaryLegionella();
      }
    });
  }

  ngOnDestroy() {
    const flatPicker = document.getElementsByClassName('flatpickr-calendar') as HTMLCollection;
    for (let i = flatPicker.length; i >= 0; i--) {
      if (flatPicker[i]) {
        flatPicker[i].remove();
      }
    }

    const dropZones = document.getElementsByClassName('dz-hidden-input') as HTMLCollection;
    for (let i = 0; i < dropZones.length; i++) {
      dropZones[i].remove();
    }

    this.clearTooltip();

    this.modalSanitaryLegionella.close();
  }
  clearTooltip() {
    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }
  changeTabs(activeTab) {
    this.activeTab = activeTab;
  }

  getSanitaryInstallations() {
    let request;
    this.requestInProgress.next(true);
    this.loader = true;

    request = this.sanitaryInstallationsServices.getAllSanitaryInstallations(
      '', '', '', 0, 1,
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
    );

    request.subscribe(response => {
      if (response['success']) {
        this.sanitaryInstallations = response['sanitaryInstallations'];
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loader = false;
      this.requestInProgress.next(false);
    });
  }
  getSanitaryCheckpoints() {
    let request;
    this.requestInProgress.next(true);
    this.loader = true;

    request = this.sanitaryCheckpointsServices.getAllSanitaryCheckpointsByType('prelevement');

    request.subscribe(response => {
      if (response['success']) {
        this.sanitaryCheckpoints = response['sanitaryCheckpoints'];
        const control = <FormArray>this.sanitaryLegionellaForm.controls['descriptions'];
        this.sanitaryCheckpoints.forEach(checkpoint => {
          const tempFrequency = [];
          const tempPointList = this.formBuilder.array([]);
          checkpoint.frequencies.forEach(frequency => {
            tempFrequency.push({
              lineId: this.formBuilder.control(this.getRandomIdentifier()),
              frequency: frequency.frequency_duration.toString() + ' ' + (frequency.frequency === 'day' ? 'Jour(s)' :
                         frequency.frequency === 'week' ? 'Semaine(s)' :
                         frequency.frequency === 'month' ? 'Mois' : 'Année(s)'),
            });
          });
          tempPointList.push(this.formBuilder.group({
            lineId: this.formBuilder.control(this.getRandomIdentifier()),
            value: this.formBuilder.control(''),
          }));
          control.push(this.formBuilder.group({
            lineId: this.formBuilder.control(this.getRandomIdentifier()),
            title: checkpoint.name,
            more_information: checkpoint.more_information,
            pointLists: tempPointList,
            recommendation: this.formBuilder.control(
              {value: checkpoint.recommendation, disabled: true}
            ),
            frequencies: this.formBuilder.array(tempFrequency),
          }));
        });
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loader = false;
      this.requestInProgress.next(false);
    });
  }

  getSanitaryLegionella() {
    this.requestInProgress.next(true);
    this.sanitaryLegionellaServices.getSanitaryLegionella(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.activatedRoute.snapshot.params.sanitary_legionella_id
    ).subscribe(response => {
      if (response['success']) {
        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];

        response['sanitaryLegionella']['descriptions'].forEach((description, indexDescription) => {
          this.addDescriptions(description, indexDescription);
        });
        this.sanitaryLegionellaForm.patchValue(response['sanitaryLegionella']);

        if (!this.canUpdate) {
          this.sanitaryLegionellaForm.disable();
        }
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      setTimeout(() => {
        Flatpickr();
      }, 1);
      this.requestInProgress.next(false);
    });
  }

  addDescriptions(description, indexDescription) {
    const control = <FormArray>this.sanitaryLegionellaForm.controls['descriptions'];

    control.push(this.formBuilder.group({
      lineId: this.formBuilder.control(this.getRandomIdentifier()),
      title: this.formBuilder.control('', Validators.required),
      more_information: this.formBuilder.control(''),
      pointLists: this.formBuilder.array([]),
      recommendation: this.formBuilder.control(
        {value: '', disabled: true}
      ),
      frequencies: this.formBuilder.array([]),
    }));
    description.pointLists.forEach(() => {
      this.addPointList(indexDescription);
    });
    description.frequencies.forEach(() => {
      this.addFrequency(indexDescription);
    });
  }
  addPointList(indexDescription) {
    this.clearTooltip();
    const control = <FormArray>this.sanitaryLegionellaForm.controls['descriptions']['controls'][indexDescription].controls['pointLists'];
    control.push(this.formBuilder.group({
      lineId: this.formBuilder.control(this.isNew ? this.getRandomIdentifier() : ''),
      value: this.formBuilder.control(''),
    }));
  }
  addNewPointList(indexDescription) {
    this.clearTooltip();
    const control = <FormArray>this.sanitaryLegionellaForm.controls['descriptions']['controls'][indexDescription].controls['pointLists'];
    control.push(this.formBuilder.group({
      lineId: this.formBuilder.control(this.getRandomIdentifier()),
      value: this.formBuilder.control(''),
    }));
  }
  deletePointList(indexDescription, indexPointList) {
    const control = <FormArray>this.sanitaryLegionellaForm.controls['descriptions']['controls'][indexDescription].controls['pointLists'];
    control.removeAt(indexPointList);
    this.clearTooltip();
  }
  addFrequency(indexDescription) {
    const control = <FormArray>this.sanitaryLegionellaForm.controls['descriptions']['controls'][indexDescription].controls['frequencies'];
    control.push(this.formBuilder.group({
      lineId: this.formBuilder.control(this.getRandomIdentifier()),
      frequency: this.formBuilder.control(''),
    }));
  }

  private getRandomIdentifier() {
    const date = new Date();
    const number = date.getTime();
    return parseInt(Math.floor((Math.random() * 500) + 1).toString(10) + number.toString(10), 10);
  }

  createSanitaryLegionella() {
    let request;
    this.errors = {};
    this.loader = true;

    const control = <FormArray>this.sanitaryLegionellaForm.controls['descriptions'];
    control.controls.forEach(element => {
      element['controls'].recommendation.enable();
      element['controls'].recommendation.updateValueAndValidity();
    });

    const data = {
      ...this.sanitaryLegionellaForm.value,
    };

    request = this.sanitaryLegionellaServices.createSanitaryLegionella(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      data
    );

    request.subscribe(response => {
      if (response['success']) {
        if (this.files.length || this.filesRequired.length) {
          this.createDocuments(response['sanitaryLegionella']['id']);
        } else {
          this.loader = false;
          this.modalSanitaryLegionella.close();
          CustomNotifierService.getSuccessCreate(this.notifier);
          this.router.navigate([
            'dashboard-registry/' + this.activatedRoute.snapshot.parent.params.registry_id +
            '/establishment/' + this.activatedRoute.snapshot.parent.params.establishment_id +
            '/sanitary-surveillances/legionellas/' + response['sanitaryLegionella']['id'] + '/frames/create']).then();
        }
      } else if (response['errors']) {
        this.loader = false;
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(response['errors'], this.sanitaryLegionellaForm, this.notifier);
      } else {
        this.loader = false;
      }
    });
  }
  updateSanitaryLegionella() {
    let request;

    this.errors = {};
    this.loader = true;

    const control = <FormArray>this.sanitaryLegionellaForm.controls['descriptions'];
    control.controls.forEach(element => {
      element['controls'].recommendation.enable();
      element['controls'].recommendation.updateValueAndValidity();
    });

    const data = {
      ...this.sanitaryLegionellaForm.value,
    };

    request = this.sanitaryLegionellaServices.updateSanitaryLegionella(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.activatedRoute.snapshot.params.sanitary_legionella_id,
      data
    );

    request.subscribe(response => {
      if (response['success']) {
        if (this.files.length || this.filesRequired.length) {
          this.createDocuments(response['sanitaryLegionella']['id']);
        } else {
          this.loader = false;
          this.modalSanitaryLegionella.close();
          CustomNotifierService.getSuccessUpdate(this.notifier);
        }
      } else if (response['errors']) {
        this.loader = false;
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      } else {
        this.loader = false;
      }
    });
  }

  createDocuments(sanitary_legionella_id) {
    let request;
    this.errors = {};

    request = this.sanitaryLegionellaServices.createFile(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      sanitary_legionella_id,
      [...this.files, ...this.filesRequired]
    );

    request.subscribe(response => {
      if (response['success']) {
        this.modalSanitaryLegionella.close();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loader = false;
    });
  }

  chooseFile() {
    const element: HTMLElement = document.getElementById('inputFile') as HTMLElement;
    element.click();
  }
  onFileChange(event) {
    if (event.target && event.target.files && event.target.files.length) {
      for (let i = 0; i < event.target.files.length; i++) {
        const reader = new FileReader();
        const file = event.target.files[i];
        reader.readAsDataURL(file);

        reader.onload = () => {
          let extension = '';
          let name = '';

          const split = file.name.split('.');
          if (split.length) {
            name = split[0];
            extension = split[split.length - 1];
          }

          this.files.push({ file_base64: reader.result, name: name, extension: extension });
        };
      }
    } else {
      let extension = '';
      let name = '';

      const split = event.name.split('.');
      if (split.length) {
        name = split[0];
        extension = split[split.length - 1];
      }

      this.files.push({ file_base64: event.file, name: name, extension: extension });
    }
    const element: HTMLInputElement = document.getElementById('inputFile') as HTMLInputElement;
    element.value = '';
  }
  removeFile(index) {
    this.files.splice(index, 1);

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  bindModal(modal) {
    this.modalSanitaryLegionella = modal;
  }
  closeModal() {
    this.router.navigate([this.activatedRoute.snapshot.parent.url.join('/')]).then(() => {
      this.allSanitaryLegionellas.refreshData();
    });
  }
  bindModalUpdateFrame(modal) {
    this.modalUpdateFrame = modal;
  }
}
