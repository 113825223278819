import { Routes } from "@angular/router";

import { DashboardCommissionsComponent } from "./dashboard-commissions/dashboard-commissions.component";
import { DashboardObservationsComponent } from "./dashboard-observations/dashboard-observations.component";
import { DashboardProvidersComponent } from "./dashboard-providers/dashboard-providers.component";
// import { DashboardTrainingsComponent } from './dashboard-trainings/dashboard-trainings.component';
import { DashboardAsbestosComponent } from "./dashboard-asbestos/dashboard-asbestos.component";
import { DashboardUniqueDocumentComponent } from "./dashboard-unique-document/dashboard-unique-document.component";
import { DashboardAdminComponent } from "./dashboard-admin/dashboard-admin.component";
import { DashboardTrainingsComponent } from "./dashboard-trainings/dashboard-trainings.component";
// import { DashboardHomeComponent } from './dashboard-home/dashboard-home.component';

export const dashboardRoutes: Routes = [
  // {
  //   path: '',
  //   component: DashboardHomeComponent
  // },
  {
    path: "dashboard-observations",
    component: DashboardObservationsComponent,
  },
  {
    path: "dashboard-commissions",
    component: DashboardCommissionsComponent,
  },
  {
    path: "dashboard-providers",
    component: DashboardProvidersComponent,
  },
  // {
  //   path: 'dashboard-trainings',
  //   component: DashboardTrainingsComponent
  // },
  {
    path: "dashboard-asbestos",
    component: DashboardAsbestosComponent,
  },
  {
    path: "dashboard-unique-document",
    component: DashboardUniqueDocumentComponent,
  },
  {
    path: "administration",
    component: DashboardAdminComponent,
  },
  {
    path: "dashboard-formations",
    component: DashboardTrainingsComponent,
  },
];
