import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';

import { AllDocumentsComponent } from '../all-documents/all-documents.component';
import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { DocumentsRequestService } from '../../../services/documents.request.service';

@Component({
  selector: 'app-update-file-type',
  templateUrl: './update-file-type.component.html',
  styleUrls: ['./update-file-type.component.scss']
})
export class UpdateFileTypeComponent implements OnInit, AfterViewInit, OnDestroy {
  canCreate = false;
  canUpdate = false;

  fileTypeForm: FormGroup;
  id = new FormControl('');
  enterprise_id = new FormControl('');
  generic_folder_id = new FormControl('', Validators.required);
  name = new FormControl('', Validators.required);
  order_number = new FormControl(0, Validators.required);
  file_is_concatenable = new FormControl(false);
  file_is_public = new FormControl(true);
  file_is_required = new FormControl(false);
  file_is_unique = new FormControl(false);

  errors = {};

  isNew = true;
  loader = false;
  loaderContent = false;

  modalFileType = null;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private allDocuments: AllDocumentsComponent,
    private documentsService: DocumentsRequestService
  ) {}

  ngOnInit() {
    this.fileTypeForm = this.formBuilder.group({
      id: this.id,
      generic_folder_id: this.generic_folder_id,
      name: this.name,
      order_number: this.order_number,
      file_is_concatenable: this.file_is_concatenable,
      file_is_public: this.file_is_public,
      enterprise_id: this.enterprise_id,
      file_is_required: this.file_is_required,
      file_is_unique: this.file_is_unique
    });

    this.generic_folder_id.setValue(this.activatedRoute.snapshot.parent.params.parent_id);

    if (this.activatedRoute.snapshot.params.file_type_id) {
      this.isNew = false;
      this.getFileType(this.activatedRoute.snapshot.params.file_type_id);
    }
  }

  ngAfterViewInit() {
    this.modalFileType.open();
  }

  ngOnDestroy() {
    this.modalFileType.close();
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  getFileType(file_type_id) {
    this.loaderContent = true;

    this.documentsService.getFileType(file_type_id).subscribe(response => {
      if (response['success']) {
        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];

        this.fileTypeForm.patchValue(response['fileType']);

        if (!this.canUpdate) {
          this.fileTypeForm.disable();
        }

        if (this.activatedRoute.snapshot.parent.params.establishment_id) {
          this.fileTypeForm.disable();
          this.canUpdate = false;
        }
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loaderContent = false;
    });
  }

  changeFileTypes() {
    if (!this.file_is_public.value) {
      this.file_is_concatenable.setValue(false);
      this.file_is_required.setValue(false);
    }
  }

  createFileType() {
    this.errors = {};
    this.loader = true;

    this.documentsService.createFileType(this.fileTypeForm.value).subscribe(response => {
      if (response['success']) {
        this.modalFileType.close();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loader = false;
    });
  }

  updateFileType() {
    this.errors = {};
    this.loader = true;

    this.documentsService.updateFileType(this.activatedRoute.snapshot.params.file_type_id, this.fileTypeForm.value).subscribe(response => {
      if (response['success']) {
        this.modalFileType.close();
        CustomNotifierService.getSuccessUpdate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loader = false;
    });
  }

  bindModal(modal) {
    this.modalFileType = modal;
  }

  closeModal() {
    this.allDocuments.refreshData();
    this.router.navigate([this.activatedRoute.snapshot.parent.url.join('/')]);
  }
}
