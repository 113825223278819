import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

import { ShowUnityComponent } from './show-unity.component';
import { BreadcrumbsModule } from '../../../../core/breadcrumbs/breadcrumbs.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    BreadcrumbsModule
  ],
  providers: [],
  declarations: [ShowUnityComponent]
})

export class ShowUnityModule {}
