import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NotifierService } from "angular-notifier";
import * as moment from "moment";
import { Subject } from "rxjs";
import { CustomNotifierService } from "src/app/services/custom.notifier.service";
import { TrainingsRequestService } from "src/app/services/trainings.request.service";
import BatiFile from "src/shared/interfaces/bati_file";
import { TrainingSessionsComponent } from "../training-sessions/training-sessions.component";

declare function Flatpickr(): any;
declare function FlatpickrTime(): any;

@Component({
  selector: "app-update-training-session",
  templateUrl: "./update-training-session.component.html",
  styleUrls: ["./update-training-session.component.scss"],
})
export class UpdateTrainingSessionComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  requestInProgress = new Subject();
  requestCount = 0;
  today: number = Date.now();

  canUpdate = false;
  isNew = true;
  loader = false;
  loaderContent = false;

  files: BatiFile[] = [];

  modalDateTraining = null;

  errors = {};
  employees: any = [];
  selectedEmployees: any = [];

  dateTrainingForm: FormGroup;
  id = new FormControl("");
  date = new FormControl("", Validators.required);
  duration = new FormControl("");
  instructor = new FormControl("", Validators.required);
  place = new FormControl("");
  specific_reminder = new FormControl(false);
  estimated_cost = new FormControl(0, Validators.min(0));
  real_cost = new FormControl(0, Validators.min(0));
  comments = new FormControl("");
  date_training_employees = new FormControl([]);
  training_id = new FormControl("");
  training_files = new FormControl([]);

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private allTrainings: TrainingSessionsComponent,
    private trainingsService: TrainingsRequestService
  ) {}

  ngOnInit() {
    const date_training_id = this.activatedRoute.snapshot.params
      .date_training_id
      ? this.activatedRoute.snapshot.params.date_training_id
      : null;

    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      if (this.requestCount === 0 && !value) {
        setTimeout(() => {
          Flatpickr();
          FlatpickrTime();
        }, 0);
      }
      this.loaderContent = this.requestCount > 0;
    });

    this.dateTrainingForm = this.formBuilder.group({
      id: this.id,
      date: this.date,
      duration: this.duration,
      instructor: this.instructor,
      place: this.place,
      specific_reminder: this.specific_reminder,
      estimated_cost: this.estimated_cost,
      real_cost: this.real_cost,
      comments: this.comments,
      training_id: this.training_id,
      date_training_employees: this.date_training_employees,
      training_files: this.training_files,
    });

    if (date_training_id) {
      // If is an update of the date training
      this.isNew = false;
      this.getDateTraining(date_training_id);
      this.getSelectedEmployees();
    }
    this.getEstablishmentRegistry();
  }

  ngAfterViewInit() {
    this.modalDateTraining.open();
  }

  ngOnDestroy() {
    const flatPicker = document.getElementsByClassName(
      "flatpickr-calendar"
    ) as HTMLCollection;
    for (let i = flatPicker.length; i >= 0; i--) {
      if (flatPicker[i]) {
        flatPicker[i].remove();
      }
    }
    this.modalDateTraining.close();
  }

  getErrorMessage(param) {
    return this.errors[param] && this.errors[param]["message"];
  }

  /**
   * Retrieves the event date.
   * Formats the start and end dates of the event date.
   * Replaces the instructor's id with his email in the form.
   *
   * @param date_training_id The date training id
   */
  getDateTraining(date_training_id) {
    this.requestInProgress.next(true);
    this.trainingsService
      .getDateTraining(date_training_id)
      .subscribe((response) => {
        if (response["success"]) {
          this.canUpdate = response["canUpdate"];
          this.dateTrainingForm.patchValue({ id: date_training_id });
          response["date_training"]["date"] = moment(
            response["date_training"]["date"]
          ).format("YYYY-MM-DD");
          response["date_training"]["duration"] = response["date_training"][
            "duration"
          ]
            ? moment.utc(response["date_training"]["duration"]).format("HH:mm")
            : "";
          response["date_training"]["date_training_employees"].forEach(
            (user) => {
              this.selectedEmployees.push(user.employee_id);
            },
            this
          );
          this.dateTrainingForm.patchValue(response["date_training"]);

          if (!this.canUpdate) {
            this.dateTrainingForm.disable();
          }
        } else if (response["errors"]) {
          this.errors = response["errors"];
          CustomNotifierService.getErrorsForm(
            this.errors,
            this.dateTrainingForm,
            this.notifier
          );
        }
        this.requestInProgress.next(false);
      });
  }

  createDateTraining() {
    this.errors = {};
    this.loader = true;
    const connectedAs = JSON.parse(localStorage.getItem("connectedAs"));
    const enterpriseId = connectedAs
      ? JSON.parse(localStorage.getItem("connectedAsEnterprise")).id
      : JSON.parse(localStorage.getItem("enterprise")).id;

    for (const key in this.selectedEmployees) {
      if (this.selectedEmployees.hasOwnProperty(key)) {
        this.date_training_employees.value.push({
          employee_id: this.selectedEmployees[key],
        });
      }
    }
    this.dateTrainingForm.patchValue({
      enterprise_date_formations: [{ enterprise_id: enterpriseId }],
    });
    this.dateTrainingForm.patchValue({
      training_id: this.activatedRoute.snapshot.parent.params.training_id,
    });
    this.dateTrainingForm.patchValue({ training_files: this.files });
    const dateTrainingData = { ...this.dateTrainingForm.value };

    this.trainingsService
      .createDateTraining(dateTrainingData)
      .subscribe((response) => {
        if (response["success"]) {
          this.modalDateTraining.close();
          this.allTrainings.refreshData();
          CustomNotifierService.getSuccessCreate(this.notifier);
        } else if (response["errors"]) {
          this.errors = response["errors"];
          CustomNotifierService.getErrorsForm(
            this.errors,
            this.dateTrainingForm,
            this.notifier
          );
        }
        this.loader = false;
      });
  }

  updateDateTraining() {
    this.errors = {};
    this.loader = true;
    const subtypes = [];
    const connectedAs = JSON.parse(localStorage.getItem("connectedAs"));
    const enterpriseId = connectedAs
      ? JSON.parse(localStorage.getItem("connectedAsEnterprise")).id
      : JSON.parse(localStorage.getItem("enterprise")).id;

    this.dateTrainingForm.patchValue({ training_files: this.files });
    this.dateTrainingForm.patchValue({
      enterprise_date_formations: [{ enterprise_id: enterpriseId }],
    });

    for (const key in this.selectedEmployees) {
      if (this.selectedEmployees.hasOwnProperty(key)) {
        const find = this.date_training_employees.value.find((element) => {
          return element.employee_id === this.selectedEmployees[key];
        });
        if (find) {
          subtypes.push(find);
        } else {
          subtypes.push({ employee_id: this.selectedEmployees[key] });
        }
      }
    }

    this.date_training_employees.setValue(subtypes);
    const request = this.trainingsService.updateDateTraining(
      this.activatedRoute.snapshot.params.date_training_id,
      this.dateTrainingForm.value
    );
    request.subscribe((response) => {
      if (response["success"]) {
        this.modalDateTraining.close();
        this.allTrainings.refreshData();
        CustomNotifierService.getSuccessUpdate(this.notifier);
      } else if (response["errors"]) {
        this.errors = response["errors"];
        CustomNotifierService.getErrorsForm(
          this.errors,
          this.dateTrainingForm,
          this.notifier
        );
      }
      this.loader = false;
    });
  }

  getEstablishmentRegistry() {
    let request;
    this.errors = {};

    request = this.trainingsService.getTraining(
      this.activatedRoute.snapshot.parent.params.training_id
    );

    request.subscribe((response) => {
      if (response["success"]) {
        this.getEmployees(
          response["training"].establishment_id,
          response["training"].registry_id
        );
      }
    });
  }

  getEmployees(establishment_id, registry_id) {
    this.requestInProgress.next(true);
    this.trainingsService
      .getEmployees("", "", "", "", "", establishment_id, registry_id)
      .subscribe((response) => {
        if (response["success"]) {
          response["results"]["employees"].forEach((employee) => {
            employee.group = true;
            this.employees.push(employee);
          });
        }
        this.requestInProgress.next(false);
      });
  }

  getSelectedEmployees() {
    this.requestInProgress.next(true);
    this.trainingsService
      .getSelectedEmployees(
        +this.activatedRoute.snapshot.params.date_training_id
      )
      .subscribe((response) => {
        if (response["success"]) {
          response["dateTrainingEmployees"].forEach((employee) => {
            employee.group = true;
            this.selectedEmployees.push(employee["employee_id"]);
          });
        }
        this.requestInProgress.next(false);
      });
  }

  /**
   * @param index The element to delete
   */
  removeFile(index) {
    this.files.splice(index, 1);

    const tooltips = document.getElementsByClassName(
      "tooltip"
    ) as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  chooseFile() {
    const element: HTMLElement = document.getElementById(
      "inputFile"
    ) as HTMLElement;
    element.click();
  }

  onFileChange(event) {
    if (event.target && event.target.files && event.target.files.length) {
      for (let i = 0; i < event.target.files.length; i++) {
        const reader = new FileReader();
        const file = event.target.files[i];
        reader.readAsDataURL(file);

        reader.onload = () => {
          let extension = "";
          let name = "";
          const names: string = file.name;
          const split = names.split(".");
          if (split.length) {
            name = split[0];
            extension = split[split.length - 1];
          }

          this.files.push({
            file_base64: reader.result,
            name: name,
            extension: extension,
          });
        };
      }
    } else {
      let extension = "";
      let name = "";
      const split = event.name.split(".");
      if (split.length) {
        name = split[0];
        extension = split[split.length - 1];
      }
      this.files.push({
        file_base64: event.file,
        name: name,
        extension: extension,
      });
    }
    const element: HTMLInputElement = document.getElementById(
      "inputFile"
    ) as HTMLInputElement;
    element.value = "";
  }

  binModal(modal) {
    this.modalDateTraining = modal;
  }

  closeModal() {
    this.router
      .navigate([this.activatedRoute.snapshot.parent.url.join("/")])
      .then(() => {});
  }
}
