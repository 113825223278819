import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Subject, Subscription } from 'rxjs';

import { AuthService } from '../../../core/auth/auth.service';
import { HttpClient } from '@angular/common/http';

import { VideosRequestService } from '../../../services/videos.request.service';
import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { WorksheetsRequestService } from '../../../services/worksheets.request.service';
import { RegistriesRequestService } from '../../../services/registries.request.service';
import { EstablishmentsRequestService } from '../../../services/establishments.request.service';

import RequiredWorksheet from '../../../../shared/interfaces/required_worksheet';
import BatiFile from '../../../../shared/interfaces/bati_file';
import Registry from '../../../../shared/interfaces/registry';
import Establishment from '../../../../shared/interfaces/establishment';

import { VideoModalComponent } from '../../../core/video-modal/video-modal.component';
import { PdfViewerComponent } from '../../../core/pdf-viewer/pdf-viewer.component';
import { environment } from '../../../../environments/environment';


@Component({
  selector: 'app-all-worksheets',
  templateUrl: './all-worksheets.component.html',
  styleUrls: ['./all-worksheets.component.scss']
})
export class AllWorksheetsComponent implements OnInit, AfterViewInit, OnDestroy {
  changeEnterprise: Subscription;

  canCreate = false;
  canUpdate = false;
  canDelete = false;

  requestInProgress = new Subject();
  requestCount = 0;

  pageName: string;
  previousPageName: string[] = [];
  previousPageRoute: string[] = [];

  currentRoute = '';
  createRoute = '';
  viewRoute = '';
  view = '';

  worksheet: any = [];
  worksheets: any = [];
  rvrats: any = [];
  registry: Registry;
  establishment: Establishment;
  files: BatiFile[] = [];
  requiredWorksheets: RequiredWorksheet[] = [];

  count = 0;
  pages = 1;

  search = '';
  sort = '';
  order = 'asc';
  limit = 12;
  page = 1;

  errors = {};

  timeout: any;
  loader = false;

  modalConfirmData = null;
  modalConfirmCommission = null;
  modalConfirmFile = null;
  modalVideo: VideoModalComponent = null;
  modalPdf: PdfViewerComponent = null;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private videosService: VideosRequestService,
    private worksheetsService: WorksheetsRequestService,
    private registriesService: RegistriesRequestService,
    private establishmentsService: EstablishmentsRequestService,
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loader = this.requestCount > 0;
    });

    this.changeEnterprise = this.authService.getEnterprise().subscribe(() => {
      if (this.activatedRoute.snapshot.params.registry_id && !this.activatedRoute.snapshot.params.establishment_id) {
        this.pageName = 'Types de fichiers';
        this.view = 'adminFileTypes';
        this.getAllRequiredWorksheets();
      } else if (this.activatedRoute.snapshot.params.worksheet_id && !this.activatedRoute.snapshot.params.file_type_id) {
        this.pageName = 'Types de fichiers';
        this.view = 'customerFileTypes';
        this.getAllRequiredWorksheets();
      } else if (this.activatedRoute.snapshot.params.file_type_id) {
        this.pageName = 'Fichiers';
        this.view = 'customerFiles';
        this.getAllFiles();
      } else {
        this.pageName = 'Fiche travaux';
        this.view = 'customerListWorksheets';
        this.sort = 'Worksheets.operation_name';
        this.getWorksheets();
      }

      if (this.activatedRoute.snapshot.params.registry_id && this.activatedRoute.snapshot.params.establishment_id) {
        this.getRegistry();
        this.getEstablishment();
      }
    });

    this.currentRoute = '/' + this.activatedRoute.snapshot.url.join('/') + '/';
    this.createRoute = '/' + this.activatedRoute.snapshot.url.join('/') + '/create';

    this.viewRoute = '/dashboard-registry/' + this.activatedRoute.snapshot.params.registry_id +
      '/establishment/' + this.activatedRoute.snapshot.params.establishment_id;

    if (this.authService.loaded) {
      if (this.activatedRoute.snapshot.params.registry_id && !this.activatedRoute.snapshot.params.establishment_id) {
        this.pageName = 'Types de fichiers';
        this.view = 'adminFileTypes';
        this.getAllRequiredWorksheets();
      } else if (this.activatedRoute.snapshot.params.worksheet_id && !this.activatedRoute.snapshot.params.file_type_id) {
        this.pageName = 'Types de fichiers';
        this.view = 'customerFileTypes';
        this.getAllRequiredWorksheets();
      } else if (this.activatedRoute.snapshot.params.file_type_id) {
        this.pageName = 'Fichiers';
        this.view = 'customerFiles';
        this.getAllFiles();
      } else {
        this.pageName = 'Fiche travaux';
        this.view = 'customerListWorksheets';
        this.sort = 'Worksheets.operation_name';
        this.getWorksheets();
      }

      if (this.activatedRoute.snapshot.params.registry_id && this.activatedRoute.snapshot.params.establishment_id) {
        this.getRegistry();
        this.getEstablishment();
      }
    }

    this.createBreadcrumbs();
  }

  ngAfterViewInit() {
    this.modalConfirmData.eventOnClose.subscribe(event => {
      if (event) {
        this.requestInProgress.next(true);
        let request;

        request = this.worksheetsService.deleteWorksheet(
          this.activatedRoute.snapshot.params.registry_id,
          this.activatedRoute.snapshot.params.establishment_id,
          event
        );

        request.subscribe(response => {
          if (response['success']) {
            this.refreshData();
            CustomNotifierService.getSuccessDelete(this.notifier);
          } else if (response['errors']) {
            CustomNotifierService.getErrors(response['errors'], this.notifier);
          }
          this.requestInProgress.next(false);
        });
      }
    });
    this.modalConfirmCommission.eventOnClose.subscribe(event => {
      if (event) {
        let request;

        request = this.worksheetsService.deleteWorksheet(
          this.activatedRoute.snapshot.params.registry_id,
          this.activatedRoute.snapshot.params.establishment_id,
          event
        );

        request.subscribe(response => {
          if (response['success']) {
            this.refreshData();
            CustomNotifierService.getSuccessDelete(this.notifier);
          } else if (response['errors']) {
            CustomNotifierService.getErrors(response['errors'], this.notifier);
          }
        });
      }
    });
    this.modalConfirmFile.eventOnClose.subscribe(event => {
      if (event) {
        this.requestInProgress.next(true);
        let request;

        request = this.worksheetsService.deleteFile(
          this.activatedRoute.snapshot.params.registry_id,
          this.activatedRoute.snapshot.params.establishment_id,
          this.activatedRoute.snapshot.params.worksheet_id,
          this.activatedRoute.snapshot.params.file_type_id,
          event
        );

        request.subscribe(response => {
          if (response['success']) {
            this.refreshData();
            CustomNotifierService.getSuccessDelete(this.notifier);
          } else if (response['errors']) {
            CustomNotifierService.getErrors(response['errors'], this.notifier);
          }
          this.requestInProgress.next(false);
        });
      }
    });
  }

  ngOnDestroy() {
    this.changeEnterprise.unsubscribe();

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  searchData() {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.page = 1;
      this.refreshData();
    }, 500);
  }

  refreshData(sort = this.sort, order = this.order, page = this.page) {
    this.sort = sort;
    this.order = order;
    this.page = page;

    if (this.activatedRoute.snapshot.params.registry_id && !this.activatedRoute.snapshot.params.establishment_id) {
      this.getAllRequiredWorksheets();
    } else if (this.activatedRoute.snapshot.params.worksheet_id && !this.activatedRoute.snapshot.params.file_type_id) {
      this.getAllRequiredWorksheets();
    } else if (this.activatedRoute.snapshot.params.file_type_id) {
      this.getAllFiles();
    } else {
      this.getWorksheets();
    }
  }

  getRegistry() {
    this.requestInProgress.next(true);
    this.registriesService.getRegistry(this.activatedRoute.snapshot.params.registry_id).subscribe(response => {
      if (response['success']) {
        this.registry = response['registry'];
        this.createBreadcrumbs();
      } else if (response['errors']) {
        this.errors = response['errors'];
      }
      this.requestInProgress.next(false);
    });
  }

  getEstablishment() {
    this.requestInProgress.next(true);
    this.establishmentsService.getMyEstablishment(this.activatedRoute.snapshot.params.establishment_id).subscribe(response => {
      if (response['success']) {
        this.establishment = response['establishment'];
        this.createBreadcrumbs();
      } else if (response['errors']) {
        this.errors = response['errors'];
      }
      this.requestInProgress.next(false);
    });
  }

  getWorksheets() {
    let request;
    this.requestInProgress.next(true);

    request = this.worksheetsService.getAllWorksheets(
      this.search, this.sort, this.order, this.limit, this.page,
      this.activatedRoute.snapshot.params.registry_id,
      this.activatedRoute.snapshot.params.establishment_id
    );

    request.subscribe(response => {
      if (response['success']) {
        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];
        this.canDelete = response['canDelete'];

        this.worksheets = response['worksheets'];

        this.count = response['count'];
        this.pages = response['pages'];
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  getAllRequiredWorksheets() {
    let request;
    this.requestInProgress.next(true);

    request = this.worksheetsService.getAllRequiredWorksheets(
      this.search, this.sort, this.order, this.limit, this.page,
      this.activatedRoute.snapshot.params.registry_id,
      this.activatedRoute.snapshot.params.establishment_id,
      this.activatedRoute.snapshot.params.worksheet_id
    );

    request.subscribe(response => {
      if (response['success']) {
        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];

        this.requiredWorksheets = response['requiredWorksheets'];
        this.rvrats = response['rvrats'];

        this.count = response['count'];
        this.pages = response['pages'];
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  getAllFiles() {
    let request;
    this.requestInProgress.next(true);

    request = this.worksheetsService.getAllFiles(
      this.search, this.sort, this.order, this.limit, this.page,
      this.activatedRoute.snapshot.params.registry_id,
      this.activatedRoute.snapshot.params.establishment_id,
      this.activatedRoute.snapshot.params.worksheet_id,
      this.activatedRoute.snapshot.params.file_type_id
    );

    request.subscribe(response => {
      if (response['success']) {
        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];

        this.files = response['files'];

        this.count = response['count'];
        this.pages = response['pages'];
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  get selectedRequired() {
    const array = [];
    for (const key in this.requiredWorksheets) {
      if (this.requiredWorksheets.hasOwnProperty(key)) {
        if (this.requiredWorksheets[key].is_required) {
          array.push(this.requiredWorksheets[key]);
        }
      }
    }
    return array;
  }

  filter(property) {
    if (this.sort === property) {
      if (this.order === 'asc') {
        this.order = 'desc';
      } else {
        this.order = 'asc';
      }
    } else {
      this.sort = property;
      this.order = 'asc';
    }

    this.getWorksheets();
  }

  downloadFile(file_id, file_name) {
    let request;

    request = this.worksheetsService.downloadFile(
      this.activatedRoute.snapshot.params.registry_id,
      this.activatedRoute.snapshot.params.establishment_id,
      this.activatedRoute.snapshot.params.worksheet_id,
      this.activatedRoute.snapshot.params.file_type_id,
      file_id
    );

    request.subscribe((response: BufferSource) => {
      try {
        const decoder = new TextDecoder('utf-8');
        const domString = decoder.decode(response);
        const json = JSON.parse(domString);

        CustomNotifierService.getErrors(json['errors'], this.notifier);
      } catch (e) {
        const blob = new Blob([response]);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = file_name;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      }
    });
  }

  dispatchPagination(data) {
    if (data.action === 'total_elements') {
      this.refreshPage(data.value);
    } else if (data.action === 'change_page') {
      this.changePage(data.value);
    }
  }

  refreshPage(limit) {
    this.limit = limit;
    this.page = 1;
    this.refreshData();
  }

  changePage(page) {
    if (page >= 1 && page <= this.pages) {
      this.page = page;
      this.getWorksheets();
    }
  }

  createBreadcrumbs() {
    this.previousPageName = [];
    this.previousPageRoute = [];

    if (this.activatedRoute.snapshot.params.registry_id && !this.activatedRoute.snapshot.params.establishment_id) {
      this.previousPageName.push('Tableau de bord');
      this.previousPageRoute.push('/administration');

      this.previousPageName.push('Registres');
      this.previousPageRoute.push('/registries');
    } else {
      this.previousPageName.push('Tableau de bord');
      this.previousPageRoute.push('/');

      this.previousPageName.push(this.registry ? this.registry.name : 'Tableau de bord registre');
      this.previousPageRoute.push('/dashboard-registry/' + this.activatedRoute.snapshot.params.registry_id);

      this.previousPageName.push(this.establishment ? this.establishment.name : 'Tableau de bord établissement');
      this.previousPageRoute.push(this.viewRoute);

      if (this.activatedRoute.snapshot.params.worksheet_id) {
        this.previousPageName.push('Fiche travaux');
        this.previousPageRoute.push(this.viewRoute + '/worksheets');
      }
      if (this.activatedRoute.snapshot.params.file_type_id) {
        this.previousPageName.push('Types de fichiers');
        this.previousPageRoute.push(this.viewRoute + '/worksheet/' + this.activatedRoute.snapshot.params.worksheet_id);
      }
    }
  }

  bindModalConfirmData(modal) {
    this.modalConfirmData = modal;
  }
  bindModalConfirmCommission(modal) {
    this.modalConfirmCommission = modal;
  }
  bindModalConfirmFile(modal) {
    this.modalConfirmFile = modal;
  }

  /**
   * Download one file
   *
   * @param file_id The file
   */
  showVideo(file_id) {
    this.videosService.getFile(file_id).subscribe(response => {
      if (response['success']) {
        const url = environment.API_PATH + 'download-video/' + response['file']['token'];
        this.modalVideo.open(url);
      }
    });
  }
  /**
   * Show Pdf
   *
   * @param file_url The file
   */
  showPdf(file_url) {
    const url = environment.API_PATH + 'file/WorksheetFiles/' + file_url;
    this.http.get(url + '/isExist').subscribe(response => {
      if (response['success']) {
        this.modalPdf.open(url);
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
    });
  }
  getImage(extension) {
    if (extension === 'mp4') {
      return '../assets/img/files/mp4.png';
    } else if (extension === 'pdf') {
      return '../assets/img/files/pdf.png';
    } else if (extension === 'xlsx' || extension === 'csv') {
      return '../assets/img/files/xls.png';
    } else if (extension === 'doc' || extension === 'docx') {
      return '../assets/img/files/doc.png';
    } else if (extension === 'jpeg' || extension === 'jpg') {
      return '../assets/img/files/jpg.png';
    } else if (extension === 'png') {
      return '../assets/img/files/png.png';
    } else {
      return '../assets/img/files/file_icon.png';
    }
  }
  bindModalVideo(modal) {
    this.modalVideo = modal;
  }
  bindModalPdf(modal) {
    this.modalPdf = modal;
  }
}
