import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { HttpClient } from '@angular/common/http';
import { RequestService } from './request.service';

@Injectable()
export class CommissionsRequestService extends RequestService {
  constructor(
    protected http: HttpClient,
    protected notifier: NotifierService
  ) {
    super(http, notifier);
  }

  /// GET
  public getAllCommissions(search, sort, order, limit, page, registry_id, establishment_id, type) {
    return this.get( 'dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/commissions?' +
      'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page + '&type=' + type);
  }
  public getCommission(registry_id, establishment_id, commission_id) {
    return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/commissions/' + commission_id);
  }
  public getFiles(search, sort, order, limit, page, registry_id, establishment_id, commission_id) {
    return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/commission/' + commission_id +
      '/files?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getFile(registry_id, establishment_id, commission_id, file_id) {
    return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/commission/' + commission_id +
      '/files/' + file_id);
  }
  public getWorksheet(registry_id, establishment_id, commission_id) {
    if (commission_id) {
      return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/commissions/' + commission_id +
        '/worksheets');
    } else {
      return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/commissions/worksheets');
    }
  }

  /// CREATE
  public createCommission(registry_id, establishment_id, commission) {
    return this.post('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/commissions', { commission });
  }
  public createFile(registry_id, establishment_id, commission_id, file) {
    return this.post('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/commission/' + commission_id + '/files',
      { file: file });
  }

  /// UPDATE
  public updateCommission(registry_id, establishment_id, commission_id, commission) {
    return this.put('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/commissions/' + commission_id,
      { commission });
  }
  public updateCommissionDate(registry_id, establishment_id, commission) {
    return this.put('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/commissions', { commission });
  }
  public updateFile(registry_id, establishment_id, commission_id, file_id, file) {
    return this.put('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/commission/' + commission_id +
      '/files/' + file_id, { file: file });
  }

  /// DELETE
  public deleteCommission(registry_id, establishment_id, commission_id) {
    return this.delete('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/commissions/' + commission_id);
  }
  public deleteFile(registry_id, establishment_id, commission_id, file_id) {
    return this.delete('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/commission/' + commission_id +
      '/files/' + file_id);
  }

  /// DOWNLOAD
  public downloadFile(registry_id, establishment_id, commission_id, file_id) {
    return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/commission/' + commission_id +
      '/files/' + file_id + '/download', { responseType: 'arraybuffer' });
  }
}
