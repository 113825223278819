import { Routes } from '@angular/router';

import { AllPreventionsComponent } from './all-preventions/all-preventions.component';
import { UpdatePreventionComponent } from './update-prevention/update-prevention.component';
import { ModalLogsComponent } from '../logs/modal-logs/modal-logs.component';
import { UploadPreventionFileComponent } from './upload-preventiion-file/upload-prevention-file.component';
import { UpdatePreventionSimplifiedComponent } from './update-prevention-simplified/update-prevention-simplified.component';

export const preventionsRoutes: Routes = [
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/preventions',
    component: AllPreventionsComponent,
    children: [
      {
        path: 'create',
        component: UpdatePreventionComponent
      },
      {
        path: ':prevention_id',
        component: UpdatePreventionComponent
      },
      {
        path: ':prevention_id/logs',
        component: ModalLogsComponent
      }
    ]
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/preventions-simplified',
    component: AllPreventionsComponent,
    children: [
      {
        path: 'create',
        component: UpdatePreventionSimplifiedComponent
      },
      {
        path: ':prevention_id',
        component: UpdatePreventionSimplifiedComponent
      },
      {
        path: ':prevention_id/logs',
        component: ModalLogsComponent
      }
    ]
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/preventions/:prevention_id/files',
    component: AllPreventionsComponent,
    children: [
      {
        path: 'create',
        component: UploadPreventionFileComponent
      },
      {
        path: ':file_id',
        component: UploadPreventionFileComponent
      }
    ]
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/preventions-simplified/:prevention_id/files',
    component: AllPreventionsComponent,
    children: [
      {
        path: 'create',
        component: UploadPreventionFileComponent
      },
      {
        path: ':file_id',
        component: UploadPreventionFileComponent
      }
    ]
  },
];
