<div class="main-content">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col">
        <div class="header mb-0">
          <div class="header-body no-border">
            <div class="row align-items-center">
              <div class="col">
                <h1 class="header-title">Formations</h1>
                <small class="form-text text-muted">Edition de masse des affichages des formations</small>
                <app-breadcrumbs [currentPage]="pageName" [names]="previousPageName" [routes]="previousPageRoute">
                </app-breadcrumbs>
              </div>

              <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0">
                <a class="btn btn-primary d-block d-md-inline-block" [routerLink]="[returnRoute]" [ngClass]="{ 'disabled': loader }">
                  Retour
                </a>
              </div>

              <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0">
                <a class="btn btn-primary d-block d-md-inline-block" [routerLink]="" (click)="updateShow()" [ngClass]="{ 'disabled': loader }">
                  Valider
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="is-loading loader-content" *ngIf="loader"></div>

        <div class="tab-content" [hidden]="loader || !trainings.length">
          <div class="tab-pane fade show active" id="tabPaneOne" role="tabpanel">
            <div class="row listAlias">

              <!-- NEWS -->
              <div class="col-12">
                <div class="card bg-light border">
                  <div class="card-body">
                    Cette fonctionnalité vous permet de cacher et/ou afficher les formations que vous souhaitez retirer
                    du tableau de bord car non nécessaires à votre établissement
                  </div>
                </div>
              </div>

              <!-- TRAININGS CARDS -->
              <div class="col-12 d-flex flex-column"
                   *ngFor="let training of trainings; let lastTraining = last; let trainingKey = index">
                <div class="card flex-fill card-training" [ngClass]="{ 'fix-last': lastTraining }">
                  <div class="card-body">
                    <div class="row align-items-center">
                      <div class="col overflow-hidden align-self-start d-flex">
                        <h4 class="card-title name text-truncate mt-3 href" (click)="checkTraining(trainingKey)">
                          <i class="fe fe-chevron-right"></i> {{ training.name }}
                        </h4>
                      </div>
                      <div class="col-auto align-self-start d-flex">
                        <a class="href fix-checkbox" (click)="checkTraining(trainingKey)">
                          <i class="fe fe-check-square fe-black" *ngIf="training.display"></i>
                          <i class="fe fe-square fe-black" *ngIf="!training.display"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="loader-content" *ngIf="!loader && !trainings.length">
          <p>Aucun résultat</p>
        </div>
      </div>
    </div>
  </div>
</div>

<router-outlet></router-outlet>
