/**
 * This file allows to interact with files linked to a training date such as files linked to one or more employees.
 */
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';

import { AuthService } from '../../../core/auth/auth.service';
import { ConfirmModalComponent } from '../../../core/confirm-modal/confirm-modal.component';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { RegistriesRequestService } from '../../../services/registries.request.service';
import { EstablishmentsRequestService } from '../../../services/establishments.request.service';
import { TrainingsRequestService } from '../../../services/trainings.request.service';

import Registry from '../../../../shared/interfaces/registry';
import Establishment from '../../../../shared/interfaces/establishment';
import Training from '../../../../shared/interfaces/training';
import DateTraining from '../../../../shared/interfaces/date_training';

import * as moment from 'moment';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { PdfViewerComponent } from '../../../core/pdf-viewer/pdf-viewer.component';

@Component({
  selector: 'app-all-trainings-files',
  templateUrl: './all-trainings-files.component.html',
  styleUrls: ['./all-trainings-files.component.scss']
})
export class AllTrainingsFilesComponent implements OnInit, AfterViewInit, OnDestroy {
  changeEnterprise: Subscription;

  canCreate = false;
  canUpdate = false;
  canDelete = false;

  requestInProgress = new Subject();
  requestCount = 0;

  files_date_training: any = [];
  employees_files_date_training: any = []; // It includes the list of employees and their files (everything is recovered together).

  registry: Registry;
  establishment: Establishment;
  training: Training;
  date_training: DateTraining;

  updateRoute = '';

  viewTrainings = false;
  viewDateTrainingsEmployees = false;

  pageName: string;
  previousPageName: string[] = [];
  previousPageRoute: string[] = [];

  count = 0;
  pages = 1;

  search = '';
  sort = 'id';
  order = 'asc';
  limit = 12;
  page = 1;

  timeout: any;
  loader = false;

  modalPdf: PdfViewerComponent = null;
  modalConfirmFile: ConfirmModalComponent = null;

  constructor(
    private router: Router,
    private http: HttpClient,
    private notifier: NotifierService,
    private authService: AuthService,
    public activatedRoute: ActivatedRoute,
    private trainingsService: TrainingsRequestService,
    private registriesService: RegistriesRequestService,
    private establishmentsService: EstablishmentsRequestService,
  ) {
    activatedRoute.params.subscribe(() => {
      this.requestInProgress.subscribe((value: boolean) => {
        if (value) {
          this.requestCount++;
        } else if (this.requestCount > 0) {
          this.requestCount--;
        }
        this.loader = this.requestCount > 0;
      });

      this.updateRoute = '/' + this.activatedRoute.snapshot.url.join('/') + '/';
      this.defineView();
      this.viewTrainings ? this.getFiles() : this.getEmployees(this.activatedRoute.snapshot.params.date_training_id);
    });
  }

  ngOnInit() {
    this.changeEnterprise = this.authService.getEnterprise().subscribe(() => {
      this.viewTrainings ? this.getFiles() : this.getEmployees(this.activatedRoute.snapshot.params.date_training_id);
    });

    this.getRegistry(+this.activatedRoute.snapshot.params.registry_id);
    this.getEstablishment(+this.activatedRoute.snapshot.params.establishment_id);
    this.getTraining(+this.activatedRoute.snapshot.params.training_id);
    this.getDateTraining(+this.activatedRoute.snapshot.params.date_training_id);
    this.createBreadcrumbs();
  }

  ngAfterViewInit() {
    this.modalConfirmFile.eventOnClose.subscribe(file_id => { // This, is the modal to perform a file deletion
      if (file_id) {
        this.trainingsService.deleteFile(file_id, +this.activatedRoute.snapshot.params.date_training_id, '').subscribe(response => {
          if (response['success']) {
            this.refreshData();
            CustomNotifierService.getSuccessDelete(this.notifier);
          } else if (response['errors']) {
            CustomNotifierService.getErrors(response['errors'], this.notifier);
          }
        });
      }
    });
  }

  ngOnDestroy() {
    this.changeEnterprise.unsubscribe();
    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  defineView() {
    this.requestInProgress.next(true);
    if (this.activatedRoute.snapshot.url[(this.activatedRoute.snapshot.url.length - 1)].path === 'employees') {
      this.sort = 'EmployeeTrainings.firstname';
      this.viewDateTrainingsEmployees = true;
      this.requestInProgress.next(false);
    } else if (this.activatedRoute.snapshot.url[(this.activatedRoute.snapshot.url.length - 1)].path === 'files') {
      this.sort = 'name';
      this.viewTrainings = true;
      this.requestInProgress.next(false);
    }
  }

  getFiles() {
    this.requestInProgress.next(true);
    const params = this.activatedRoute.snapshot.params;

    this.trainingsService.getFiles(this.search, this.sort, this.order, this.limit, this.page, params.date_training_id)
      .subscribe(response => {
        if (response['success']) {
          this.files_date_training = response['files'];
          this.canCreate = response['canCreate'];
          this.canUpdate = response['canUpdate'];
          this.canDelete = response['canDelete'];
          this.count = response['count'];
          this.pages = response['pages'];
        } else if (response['errors']) {
          CustomNotifierService.getErrors(response['errors'], this.notifier);
        }
        this.requestInProgress.next(false);
      });
  }

  /**
   * Retrieves all employees with their files.
   *
   * @param date_training_id The date training id to retrieve employees
   */
  getEmployees(date_training_id: number) {
    this.requestInProgress.next(true);
    this.trainingsService.getDateTrainingsEmployees(this.search, this.sort, this.order, this.limit, this.page, date_training_id)
      .subscribe(response => {
        if (response['success']) {
          this.employees_files_date_training = response['date_training_employees']['files'];
          this.count = response['date_training_employees']['count'];
          this.pages = response['pages'];
        } else if (response['errors']) {
          CustomNotifierService.getErrors(response['errors'], this.notifier);
        }
        this.requestInProgress.next(false);
      });
  }

  /**
   * @param index The index for the employees list to update.
   * @param dte_id Date training employee id.
   * @param is_present Boolean to check if the employee is present or not.
   * @param element_id The element to update after modifications.
   */
  updateEmployeePresent(index, dte_id, is_present, element_id) {
    this.trainingsService.updateEmployeePresent(+dte_id, is_present).subscribe(response => {
      if (response['success']) {
        this.employees_files_date_training[index]['is_present'] = !is_present;
        CustomNotifierService.getSuccessUpdate(this.notifier);
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
    });
  }

  /**
   * @param file_id The file
   * @param file_name And his name
   */
  downloadFile(file_id: any, file_name: any) {
    const params = this.activatedRoute.snapshot.params;
    this.trainingsService.downloadFile(file_id, +params.date_training_id, '').subscribe((response: BufferSource) => {
      try {
        const decoder = new TextDecoder('utf-8');
        const domString = decoder.decode(response);
        const json = JSON.parse(domString);

        CustomNotifierService.getErrors(json['errors'], this.notifier);
      } catch (e) {
        const blob = new Blob([response]);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = file_name;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      }
    });
  }

  /**
   * @param id The registry to retrieve
   */
  getRegistry(id: number) {
    this.requestInProgress.next(true);
    this.registriesService.getRegistry(id).subscribe(response => {
      if (response['success']) {
        this.registry = response['registry'];
        this.createBreadcrumbs();
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  /**
   * @param id The establishment to retrieve
   */
  getEstablishment(id: number) {
    this.requestInProgress.next(true);
    this.establishmentsService.getMyEstablishment(id).subscribe(response => {
      if (response['success']) {
        this.establishment = response['establishment'];
        this.createBreadcrumbs();
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  /**
   * @param id The training to retrieves
   */
  getTraining(id: number) {
    this.requestInProgress.next(true);
    this.trainingsService.getTraining(id).subscribe(response => {
      if (response['success']) {
        this.training = response['training'];
        this.createBreadcrumbs();
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  /**
   * @param id The date training to retrieves
   */
  getDateTraining(id: number) {
    this.requestInProgress.next(true);
    this.trainingsService.getDateTraining(id).subscribe(response => {
      if (response['success']) {
        this.date_training = response['date_training'];
        this.createBreadcrumbs();
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  searchData() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.page = 1;
      if (this.viewDateTrainingsEmployees) {
        this.getEmployees(+this.activatedRoute.snapshot.params.date_training_id);
      } else {
        this.getFiles();
      }
    }, 500);
  }

  refreshData(sort = this.sort, order = this.order, page = this.page) {
    this.sort = sort;
    this.order = order;
    this.page = page;
    this.viewDateTrainingsEmployees ? this.getEmployees(+this.activatedRoute.snapshot.params.date_training_id) : this.getFiles();
  }

  dispatchPagination(data) {
    if (data.action === 'total_elements') {
      this.refreshPage(data.value);
    } else if (data.action === 'change_page') {
      this.changePage(data.value);
    }
  }

  refreshPage(limit) {
    this.limit = limit;
    this.page = 1;
    this.refreshData();
  }

  changePage(page) {
    if (page >= 1 && page <= this.pages) {
      this.page = page;
      this.refreshData();
    }
  }

  filter(property) {
    if (this.sort === property) {
      if (this.order === 'asc') {
        this.order = 'desc';
      } else {
        this.order = 'asc';
      }
    } else {
      this.sort = property;
      this.order = 'asc';
    }
    this.viewDateTrainingsEmployees ? this.getEmployees(+this.activatedRoute.snapshot.params.date_training_id) : this.getFiles();
    this.refreshData();
  }

  bindModalConfirmFile(modal) {
    this.modalConfirmFile = modal;
  }

  createBreadcrumbs() {
    const params = this.activatedRoute.snapshot.params;
    this.pageName = this.viewDateTrainingsEmployees ? 'Personnel et leurs documents' : 'Documents de la date de formation';
    this.previousPageName = [];
    this.previousPageRoute = [];

    this.previousPageName.push('Tableau de bord');
    this.previousPageRoute.push('/');

    this.previousPageName.push(this.registry ? this.registry.name : 'Tableau de bord registre');
    this.previousPageRoute.push('/dashboard-registry/' + params.registry_id);

    this.previousPageName.push(this.establishment ? this.establishment.name : 'Tableau de bord établissement');
    this.previousPageRoute.push('/dashboard-registry/' + params.registry_id + '/establishment/' + params.establishment_id);

    this.previousPageName.push(this.training ? 'Formation : ' + this.training.name : 'Formations');
    this.previousPageRoute.push('/dashboard-registry/' + params.registry_id + '/establishment/' + params.establishment_id + '/trainings');

    this.previousPageName.push(
      this.date_training ? 'Date : ' + moment(this.date_training.date).format('DD/MM/YYYY') : 'Dates de formation'
    );
    this.previousPageRoute.push('/dashboard-registry/' + params.registry_id + '/establishment/' + params.establishment_id + '/trainings/'
      + params.training_id + '/dates-training');
  }

  /**
   * Show Pdf
   *
   * @param file_url The file
   */
  showPdf(file_url) {
    const url = environment.API_PATH + 'file/TrainingFiles/' + file_url;
    this.http.get(url + '/isExist').subscribe(response => {
      if (response['success']) {
        this.modalPdf.open(url);
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
    });
  }
  bindModalPdf(modal) {
    this.modalPdf = modal;
  }
}
