import { Routes } from '@angular/router';

import { AllOfficialPrescriptionsComponent } from './all-official-prescriptions/all-official-prescriptions.component';
import { UpdateOfficialPrescriptionComponent } from './update-official-prescription/update-official-prescription.component';
import { UpdateOfficialPrescriptionStateComponent} from './update-official-prescription-state/update-official-prescription-state.component';
import { UploadOfficialPrescriptionFileComponent } from './upload-official-prescription-file/upload-official-prescription-file.component';

import { UpdateInterventionComponent } from '../interventions/update-intervention/update-intervention.component';

export const officialPrescriptionsRoutes: Routes = [
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/official/:official_id/prescriptions',
    component: AllOfficialPrescriptionsComponent,
    children: [
      {
        path: 'create',
        component: UpdateOfficialPrescriptionComponent
      },
      {
        path: ':prescription_id',
        component: UpdateOfficialPrescriptionComponent
      },
      {
        path: ':prescription_id/state',
        component: UpdateOfficialPrescriptionStateComponent
      },
      {
        path: ':prescription_id/intervention/create',
        component: UpdateInterventionComponent
      },
    ]
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/official/:official_id/prescriptions/:prescription_id/files',
    component: AllOfficialPrescriptionsComponent,
    children: [
      {
        path: 'create',
        component: UploadOfficialPrescriptionFileComponent
      },
      {
        path: ':file_id',
        component: UploadOfficialPrescriptionFileComponent
      }
    ]
  },
];
