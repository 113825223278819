import { AfterViewInit, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Subject } from 'rxjs';

import { AllRvratPrescriptionsComponent } from '../all-rvrat-prescriptions/all-rvrat-prescriptions.component';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { UsersRequestService } from '../../../services/users.request.service';
import { EnterprisesRequestService } from '../../../services/enterprises.request.service';
import { DashboardRegistryComponent } from '../../registries/dashboard-registry/dashboard-registry.component';
import { EstablishmentsRequestService } from '../../../services/establishments.request.service';
import { RvratPrescriptionsRequestService } from '../../../services/rvrat-prescriptions.request.service';

import BatiFile from '../../../../shared/interfaces/bati_file';
import Establishment from '../../../../shared/interfaces/establishment';


declare function Flatpickr(): any;

@Component({
  selector: 'app-update-rvrat-prescription',
  templateUrl: './update-rvrat-prescription.component.html',
  styleUrls: ['./update-rvrat-prescription.component.scss']
})
export class UpdateRvratPrescriptionComponent implements OnInit, AfterViewInit, OnDestroy {
  canCreate = false;
  canUpdate = false;

  requestInProgress = new Subject();
  requestCount = 0;

  allRvratPrescriptions: any;
  dashboardRegistry: any;
  establishment: Establishment;
  establishments: Establishment[];
  establishment_selected: number;

  rvratPrescriptionForm: FormGroup;
  id = new FormControl('');
  rvrat_id = new FormControl('');
  name = new FormControl('', Validators.required);
  enterprise_id = new FormControl(null);
  user_id = new FormControl(null);
  establishment_id = new FormControl(null);
  deadline = new FormControl(null);
  emergencies = new FormControl(null);
  financial_estimation = new FormControl(0, Validators.min(0));
  comment_prescription = new FormControl(null);

  files: BatiFile[] = [];
  enterprises: any = [];
  user: string;

  errors = {};

  isNew = true;
  loader = false;
  loaderContent = false;

  modalPrescription = null;

  constructor(
    private router: Router,
    private injector: Injector,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private rvratPrescriptionsService: RvratPrescriptionsRequestService,
    private usersService: UsersRequestService,
    private establishmentsService: EstablishmentsRequestService,
    private enterprisesService: EnterprisesRequestService
  ) {
    if (this.activatedRoute.snapshot.parent.params.rvrat_id) {
      this.allRvratPrescriptions = <AllRvratPrescriptionsComponent>this.injector.get(AllRvratPrescriptionsComponent);
    } else {
      this.dashboardRegistry = <DashboardRegistryComponent>this.injector.get(DashboardRegistryComponent);
    }
  }

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loaderContent = this.requestCount > 0;
    });

    this.rvratPrescriptionForm = this.formBuilder.group({
      id: this.id,
      rvrat_id: this.rvrat_id,
      name: this.name,
      enterprise_id: this.enterprise_id,
      user_id: this.user_id,
      establishment_id: this.establishment_id,
      deadline: this.deadline,
      emergencies: this.emergencies,
      financial_estimation: this.financial_estimation,
      comment_prescription: this.comment_prescription
    });

    if (this.activatedRoute.snapshot.params.rvrat_prescription_id) {
      this.isNew = false;
      this.getPrescription();
    } else {
      Flatpickr();
    }

    if (this.activatedRoute.snapshot.parent.params.establishment_id) {
      this.getEstablishment();
    }
    this.getMyUsers();
    this.getEnterprises();
  }

  ngAfterViewInit() {
    this.modalPrescription.open();
  }

  ngOnDestroy() {
    const flatPicker = document.getElementsByClassName('flatpickr-calendar') as HTMLCollection;
    for (let i = flatPicker.length; i >= 0; i--) {
      if (flatPicker[i]) {
        flatPicker[i].remove();
      }
    }

    this.modalPrescription.close();
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  getEstablishment() {
    this.requestInProgress.next(true);
    this.establishmentsService.getMyEstablishment(this.activatedRoute.snapshot.parent.params.establishment_id).subscribe(response => {
      if (response['success']) {
        this.establishment = response['establishment'];
        if (this.establishment.parent_id) {
          this.rvratPrescriptionForm.patchValue({establishment_id: this.activatedRoute.snapshot.parent.params.establishment_id});
        } else {
          this.getEstablishments();
        }
      } else if (response['errors']) {
        this.errors = response['errors'];
      }
      this.requestInProgress.next(false);
    });
  }

  getEstablishments() {
    this.requestInProgress.next(true);
    this.establishmentsService.geMyEstablishmentsChildren(
      '', '', '', '', '',
      this.activatedRoute.snapshot.parent.params.establishment_id
    ).subscribe(response => {
      if (response['success']) {
        this.establishments = response['establishments'];
      } else if (response['errors']) {
        this.errors = response['errors'];
      }
      this.requestInProgress.next(false);
    });
  }

  changeEstablishment() {
    this.establishment_id.setValue(this.establishment_selected);
  }

  getEnterprises() {
    this.requestInProgress.next(true);

    this.enterprisesService.getProviders('', '', '', '', '').subscribe(response => {
      if (response['success']) {
        response['enterprises'].forEach(enterprise => {
          this.getUsers(enterprise);
        });
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  getUsers(enterprise) {
    this.requestInProgress.next(true);

    this.usersService.getProviderUsers('', '', '', '', '', enterprise.id).subscribe(response => {
      if (response['success']) {
        response['users'].forEach(user => {
          this.enterprises.push({
            id: enterprise.id + '-' + user.id,
            name: enterprise.name + ' - ' + user.firstname + ' ' + user.lastname
          });
        });
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  getMyUsers() {
    this.requestInProgress.next(true);

    this.usersService.getMyUsers('', '', '', '', '').subscribe(response => {
      if (response['success']) {
        response['users'].forEach(user => {
          this.enterprises.push({
            id: response['enterprise'].id + '-' + user.id,
            name: response['enterprise'].name + ' - ' + user.firstname + ' ' + user.lastname
          });
        });
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  changeUser() {
    if (this.user) {
      const split = this.user.split('-');
      this.enterprise_id.setValue(split[0]);
      this.user_id.setValue(split[1]);
    } else {
      this.enterprise_id.setValue(null);
      this.user_id.setValue(null);
    }
  }

  getPrescription() {
    let request;
    this.requestInProgress.next(true);

    if (this.activatedRoute.snapshot.parent.params.establishment_id && this.activatedRoute.snapshot.parent.params.rvrat_id) {
      request = this.rvratPrescriptionsService.getRvratPrescription(
        this.activatedRoute.snapshot.parent.params.registry_id,
        this.activatedRoute.snapshot.parent.params.establishment_id,
        this.activatedRoute.snapshot.parent.params.rvrat_id,
        this.activatedRoute.snapshot.params.rvrat_prescription_id
      );
    } else if (this.activatedRoute.snapshot.parent.params.establishment_id && !this.activatedRoute.snapshot.parent.params.rvrat_id) {
      request = this.rvratPrescriptionsService.getEstablishmentRvratPrescription(
        this.activatedRoute.snapshot.parent.params.registry_id,
        this.activatedRoute.snapshot.parent.params.establishment_id,
        this.activatedRoute.snapshot.params.rvrat_prescription_id
      );
    } else {
      request = this.rvratPrescriptionsService.getRegistryRvratPrescription(
        this.activatedRoute.snapshot.parent.params.registry_id,
        this.activatedRoute.snapshot.params.rvrat_prescription_id
      );
    }

    request.subscribe(response => {
      if (response['success']) {
        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];

        this.rvratPrescriptionForm.patchValue(response['rvrat_prescription']);

        if (!this.canUpdate) {
          this.rvratPrescriptionForm.disable();
        }

        if (this.rvratPrescriptionForm.value.enterprise_id && this.rvratPrescriptionForm.value.user_id) {
          this.user = this.rvratPrescriptionForm.value.enterprise_id + '-' + this.rvratPrescriptionForm.value.user_id;
        }
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      Flatpickr();
      this.requestInProgress.next(false);
    });
  }

  createPrescription() {
    let request;
    this.errors = {};
    this.loader = true;

    if (this.name.value) {
      const temp = [];
      const name: string = this.name.value;
      const splitValue = name.split('\n');

      splitValue.forEach(value => {
        const reg = new RegExp(/^\s*•\s+/gm);
        value = value.replace(reg, '');
        if (value) {
          temp.push(value);
        }
      });
      this.name.setValue(temp);
    }

    request = this.rvratPrescriptionsService.createRvratPrescription(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.activatedRoute.snapshot.parent.params.rvrat_id,
      this.rvratPrescriptionForm.value
    );

    request.subscribe(response => {
      if (response['success']) {
        this.isNew = false;
        if (this.files.length) {
          this.createDocuments(response['rvrat_prescription']['id']);
        } else {
          this.loader = false;
          this.modalPrescription.close();
          CustomNotifierService.getSuccessCreate(this.notifier);
        }
      } else if (response['errors']) {
        this.loader = false;
        this.errors = response['errors'];
        this.name.setValue(null);
        CustomNotifierService.getErrorsForm(response['errors'], this.rvratPrescriptionForm, this.notifier);
      }

      if (this.name.value) {
        this.name.setValue(this.name.value.join('\n'));
        this.name.setValue(this.name.value.replace(/(^|\r\n|\n)([^• ]|$)/g, '$1• $2'));
      }
    });
  }

  updatePrescription() {
    let request;
    let prescription;

    this.errors = {};
    this.loader = true;

    prescription = {
      ...this.rvratPrescriptionForm.value,
      id: this.id.value,
      rvrat_id: this.rvrat_id.value
    };

    if (this.activatedRoute.snapshot.parent.params.establishment_id && this.activatedRoute.snapshot.parent.params.rvrat_id) {
      request = this.rvratPrescriptionsService.updateRvratPrescription(
        this.activatedRoute.snapshot.parent.params.registry_id,
        this.activatedRoute.snapshot.parent.params.establishment_id,
        this.activatedRoute.snapshot.parent.params.rvrat_id,
        this.activatedRoute.snapshot.params.rvrat_prescription_id,
        prescription
      );
    } else if (this.activatedRoute.snapshot.parent.params.establishment_id && !this.activatedRoute.snapshot.parent.params.rvrat_id) {
      request = this.rvratPrescriptionsService.updateEstablishmentRvratPrescription(
        this.activatedRoute.snapshot.parent.params.registry_id,
        this.activatedRoute.snapshot.parent.params.establishment_id,
        this.activatedRoute.snapshot.params.rvrat_prescription_id,
        prescription
      );
    } else {
      request = this.rvratPrescriptionsService.updateRegistryRvratPrescription(
        this.activatedRoute.snapshot.parent.params.registry_id,
        this.activatedRoute.snapshot.params.rvrat_prescription_id,
        prescription
      );
    }

    request.subscribe(response => {
      if (response['success']) {
        if (this.files.length) {
          this.createDocuments(this.activatedRoute.snapshot.params.rvrat_prescription_id);
        } else {
          this.loader = false;
          this.modalPrescription.close();
          CustomNotifierService.getSuccessUpdate(this.notifier);
        }
      } else if (response['errors']) {
        this.loader = false;
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(response['errors'], this.rvratPrescriptionForm, this.notifier);
      }
    });
  }

  addTextareaBullet(event) {
    if (event.srcElement.value) {
      event.srcElement.value = event.srcElement.value.replace(/(^|\r\n|\n)([^• ]|$)/g, '$1• $2');
    }
  }

  pasteTextareaBullet(event) {
    setTimeout(() => {
      event.srcElement.value = event.srcElement.value.replace(/^\s+|\s+$/g, '');
      event.srcElement.value = event.srcElement.value.replace(/[\r\n]+/g, '\n');
      event.srcElement.value = event.srcElement.value.replace(/(^|\r\n|\n)([^• ]|$)/g, '$1• $2');
    }, 150);
  }

  createDocuments(prescription_id) {
    let request;
    this.errors = {};

    if (this.activatedRoute.snapshot.parent.params.establishment_id && this.activatedRoute.snapshot.parent.params.rvrat_id) {
      request = this.rvratPrescriptionsService.createFile(
        this.activatedRoute.snapshot.parent.params.registry_id,
        this.activatedRoute.snapshot.parent.params.establishment_id,
        this.activatedRoute.snapshot.parent.params.rvrat_id,
        prescription_id,
        this.files
      );
    } else if (this.activatedRoute.snapshot.parent.params.establishment_id && !this.activatedRoute.snapshot.parent.params.rvrat_id) {
      request = this.rvratPrescriptionsService.createEstablishmentRvratPrescriptionFile(
        this.activatedRoute.snapshot.parent.params.registry_id,
        this.activatedRoute.snapshot.parent.params.establishment_id,
        prescription_id,
        this.files
      );
    } else {
      request = this.rvratPrescriptionsService.createRegistryRvratPrescriptionFile(
        this.activatedRoute.snapshot.parent.params.registry_id,
        prescription_id,
        this.files
      );
    }

    request.subscribe(response => {
      if (response['success']) {
        this.modalPrescription.close();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loader = false;
    });
  }

  chooseFile() {
    const element: HTMLElement = document.getElementById('inputFile') as HTMLElement;
    element.click();
  }

  onFileChange(event) {
    if (event.target && event.target.files && event.target.files.length) {
      for (let i = 0; i < event.target.files.length; i++) {
        const reader = new FileReader();
        const file = event.target.files[i];
        reader.readAsDataURL(file);

        reader.onload = () => {
          let extension = '';
          let name = '';

          const split = file.name.split('.');
          if (split.length) {
            name = split[0];
            extension = split[split.length - 1];
          }

          this.files.push({ file_base64: reader.result, name: name, extension: extension });
        };
      }
    } else {
      let extension = '';
      let name = '';

      const split = event.name.split('.');
      if (split.length) {
        name = split[0];
        extension = split[split.length - 1];
      }

      this.files.push({ file_base64: event.file, name: name, extension: extension });
    }
    const element: HTMLInputElement = document.getElementById('inputFile') as HTMLInputElement;
    element.value = '';
  }

  removeFile(index) {
    this.files.splice(index, 1);

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  bindModal(modal) {
    this.modalPrescription = modal;
  }

  closeModal() {
    if (this.activatedRoute.snapshot.parent.params.rvrat_id) {
      this.allRvratPrescriptions.refreshData();
    } else {
      this.dashboardRegistry.getRvratPrescriptions();
    }
    this.router.navigate([this.activatedRoute.snapshot.parent.url.join('/')]).then(() => {});
  }
}
