import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NotifierService } from "angular-notifier";
import { CustomNotifierService } from "src/app/services/custom.notifier.service";
import { EmployeesRequestService } from "src/app/services/employees.request.service";
import { EstablishmentsRequestService } from "src/app/services/establishments.request.service";
import { TrainingsRequestService } from "src/app/services/trainings.request.service";
import DateTraining from "src/shared/interfaces/date_training";
import Employees from "src/shared/interfaces/employees";
import Establishment from "src/shared/interfaces/establishment";
import Training from "src/shared/interfaces/training";
import { FormationsEmployeeComponent } from "../formations-employee/formations-employee.component";

@Component({
  selector: "app-ajout-session-formation",
  templateUrl: "./ajout-session-formation.component.html",
  styleUrls: ["./ajout-session-formation.component.scss"],
})
export class AjoutSessionFormationComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  modalTraining = null;

  canCreate = false;
  canUpdate = false;

  search = "";
  sort = "date";
  order = "desc";
  limit = 12;
  page = 1;

  errors = {};

  isNew = true;

  loaderContent = false;
  loader = false;
  disableSelect = true;
  disableSelectDate = true;

  establishments: Establishment[] = [];
  trainings: Training[] = [];
  dateTrainings: DateTraining[] = [];

  sessionForm: FormGroup;
  id = new FormControl(null);
  establishment_id = new FormControl(null, Validators.required);
  training_id = new FormControl(null, Validators.required);
  date_training_id = new FormControl(null, Validators.required);

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private formationsEmployee: FormationsEmployeeComponent,
    private establishmentsService: EstablishmentsRequestService,
    private trainingsService: TrainingsRequestService
  ) {}

  ngOnInit() {
    this.sessionForm = this.formBuilder.group({
      id: this.id,
      establishment_id: this.establishment_id,
      training_id: this.training_id,
      date_training_id: this.date_training_id,
    });

    this.getEstablishments();
  }

  ngAfterViewInit() {
    this.modalTraining.open();
  }

  ngOnDestroy() {
    this.modalTraining.close();
  }

  getEstablishments() {
    let request;
    this.errors = {};
    this.loader = true;

    request = this.establishmentsService.getEstablishmentsByEmployee(
      this.activatedRoute.snapshot.parent.params.employee_id
    );

    request.subscribe((response) => {
      if (response["success"]) {
        this.establishments = response["establishments"];
      } else if (response["errors"]) {
        CustomNotifierService.getErrors(response["errors"], this.notifier);
      }
      this.loader = false;
    });
  }

  getTrainings() {
    let request;
    this.errors = {};
    this.loader = true;
    if (this.establishment_id.value) {
      this.training_id.patchValue(null);
      this.trainings = [];
      request = this.trainingsService.getAllTrainingsWithDateTrainings(
        this.establishment_id.value
      );

      request.subscribe((response) => {
        if (response["success"]) {
          this.trainings = response["trainings"];
          this.disableSelect = false;
        } else if (response["errors"]) {
          CustomNotifierService.getErrors(response["errors"], this.notifier);
        }
        this.loader = false;
      });
    }
  }

  getDateTrainings() {
    let request;
    this.errors = {};
    this.loader = true;
    if (this.training_id.value) {
      this.date_training_id.patchValue(null);
      this.dateTrainings = [];
      request = this.trainingsService.getDateTrainings(
        this.search,
        this.sort,
        this.order,
        this.limit,
        this.page,
        this.training_id.value
      );

      request.subscribe((response) => {
        if (response["success"]) {
          this.dateTrainings = response["date_trainings"].filter((item) => {
            var varDate = new Date(item.date);
            var today = new Date();
            return varDate >= today;
          });
          this.disableSelectDate = false;
        } else if (response["errors"]) {
          CustomNotifierService.getErrors(response["errors"], this.notifier);
        }
        this.loader = false;
      });
    }
  }

  createSession() {
    let request;
    this.errors = {};
    this.loader = true;

    request = this.trainingsService.createDateTrainingEmployee(
      this.activatedRoute.snapshot.parent.params.employee_id,
      this.sessionForm.value
    );

    request.subscribe((response) => {
      if (response["success"]) {
        this.modalTraining.close();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response["errors"]) {
        this.errors = response["errors"];
        CustomNotifierService.getErrors(response["errors"], this.notifier);
      }
      this.loader = false;
    });
  }

  updateSession() {}

  bindModal(modal) {
    this.modalTraining = modal;
  }

  closeModal() {
    this.router
      .navigate([this.activatedRoute.snapshot.parent.url.join("/")])
      .then(() => {
        this.formationsEmployee.refreshData();
      });
  }
}
