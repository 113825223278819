import { AfterViewInit, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';

import { AllCustomerInstallationsComponent } from '../all-customer-installations/all-customer-installations.component';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { ObservationsRequestService } from '../../../services/observations.request.service';

import BatiFile from '../../../../shared/interfaces/bati_file';
import { AllCustomerObservationsComponent } from '../all-customer-observations/all-customer-observations.component';

declare function Flatpickr(): any;

@Component({
  selector: 'app-update-customer-observation',
  templateUrl: './update-customer-observation.component.html',
  styleUrls: ['./update-customer-observation.component.scss']
})
export class UpdateCustomerObservationComponent implements OnInit, AfterViewInit, OnDestroy {
  canCreate = false;
  canUpdate = false;
  canObservation = false;

  allObservations: any;

  observationForm: FormGroup;
  id = new FormControl('');
  report_id = new FormControl('');
  name = new FormControl('', Validators.required);
  deadline = new FormControl(null);
  emergencies = new FormControl(null);
  financial_estimation = new FormControl(0, Validators.min(0));
  comment_observation = new FormControl(null);

  files: BatiFile[] = [];

  errors = {};

  isNew = true;
  loader = false;
  loaderContent = false;

  modalObservation = null;

  constructor(
    private router: Router,
    private injector: Injector,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private observationsService: ObservationsRequestService
  ) {
    if (!this.activatedRoute.snapshot.parent.params.installation_id) {
      this.allObservations = <AllCustomerInstallationsComponent>this.injector.get(AllCustomerInstallationsComponent);
    } else {
      this.allObservations = <AllCustomerObservationsComponent>this.injector.get(AllCustomerObservationsComponent);
    }
  }

  ngOnInit() {
    this.observationForm = this.formBuilder.group({
      id: this.id,
      report_id: this.report_id,
      name: this.name,
      deadline: this.deadline,
      emergencies: this.emergencies,
      financial_estimation: this.financial_estimation,
      comment_observation: this.comment_observation
    });

    if (this.activatedRoute.snapshot.params.observation_id) {
      this.isNew = false;
      this.getObservation();
    } else {
      Flatpickr();
    }
  }

  ngAfterViewInit() {
    this.modalObservation.open();
  }

  ngOnDestroy() {
    const flatPicker = document.getElementsByClassName('flatpickr-calendar') as HTMLCollection;
    for (let i = flatPicker.length; i >= 0; i--) {
      if (flatPicker[i]) {
        flatPicker[i].remove();
      }
    }

    this.modalObservation.close();
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  getObservation() {
    this.loaderContent = true;

    this.observationsService.getCustomerObservation(
      this.activatedRoute.snapshot.parent.params.enterprise_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.params.observation_id
    ).subscribe(response => {
      if (response['success']) {
        this.canUpdate = response['canCreateObservation'];
        this.canCreate = response['canCreateObservation'];
        this.canObservation = response['canStateObservation'];

        this.observationForm.patchValue(response['observation']);

        if (!this.canUpdate) {
          this.observationForm.disable();
        }
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      Flatpickr();
      this.loaderContent = false;
    });
  }

  createObservation() {
    let request;
    this.errors = {};
    this.loader = true;

    if (this.name.value) {
      const temp = [];
      const observations: string = this.name.value;
      const splitValue = observations.split('\n');

      splitValue.forEach(value => {
        const reg = new RegExp(/^\s*•\s+/gm);
        value = value.replace(reg, '');
        if (value) {
          temp.push(value);
        }
      });
      this.name.setValue(temp);
    }

    request = this.observationsService.createCustomerObservation(
      this.activatedRoute.snapshot.parent.params.enterprise_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.installation_id,
      this.activatedRoute.snapshot.parent.params.checkpoint_id,
      this.activatedRoute.snapshot.parent.params.report_id,
      this.observationForm.value
    );

    request.subscribe(response => {
      if (response['success']) {
        this.isNew = false;
        if (this.files.length) {
          this.createDocuments(response['observation']['id']);
        } else {
          this.loader = false;
          this.modalObservation.close();
          CustomNotifierService.getSuccessCreate(this.notifier);
        }
      } else if (response['errors']) {
        this.loader = false;
        this.errors = response['errors'];
        this.name.setValue(null);
        CustomNotifierService.getErrorsForm(response['errors'], this.observationForm, this.notifier);
      }

      if (this.name.value) {
        this.name.setValue(this.name.value.join('\n'));
        this.name.setValue(this.name.value.replace(/(^|\r\n|\n)([^• ]|$)/g, '$1• $2'));
      }
    });
  }

  updateObservation() {
    let request;
    let observation;

    this.errors = {};
    this.loader = true;

    observation = {
      ...this.observationForm.value,
      id: this.id.value,
      report_id: this.report_id.value
    };

    request = this.observationsService.updateCustomerObservation(
      this.activatedRoute.snapshot.parent.params.enterprise_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.params.observation_id,
      observation
    );

    request.subscribe(response => {
      if (response['success']) {
        if (this.files.length) {
          this.createDocuments(this.activatedRoute.snapshot.params.observation_id);
        } else {
          this.loader = false;
          this.modalObservation.close();
          CustomNotifierService.getSuccessUpdate(this.notifier);
        }
      } else if (response['errors']) {
        this.loader = false;
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
    });
  }

  addTextareaBullet(event) {
    if (event.srcElement.value) {
      event.srcElement.value = event.srcElement.value.replace(/(^|\r\n|\n)([^• ]|$)/g, '$1• $2');
    }
  }

  pasteTextareaBullet(event) {
    setTimeout(() => {
      event.srcElement.value = event.srcElement.value.replace(/^\s+|\s+$/g, '');
      event.srcElement.value = event.srcElement.value.replace(/[\r\n]+/g, '\n');
      event.srcElement.value = event.srcElement.value.replace(/(^|\r\n|\n)([^• ]|$)/g, '$1• $2');
    }, 150);
  }

  createDocuments(observation_id) {
    let request;
    this.errors = {};

    request = this.observationsService.createCustomerFile(
      this.activatedRoute.snapshot.parent.params.enterprise_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.installation_id,
      this.activatedRoute.snapshot.parent.params.checkpoint_id,
      this.activatedRoute.snapshot.parent.params.report_id,
      observation_id,
      this.files
    );

    request.subscribe(response => {
      if (response['success']) {
        this.modalObservation.close();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loader = false;
    });
  }

  chooseFile() {
    const element: HTMLElement = document.getElementById('inputFile') as HTMLElement;
    element.click();
  }

  onFileChange(event) {
    if (event.target && event.target.files && event.target.files.length) {
      for (let i = 0; i < event.target.files.length; i++) {
        const reader = new FileReader();
        const file = event.target.files[i];
        reader.readAsDataURL(file);

        reader.onload = () => {
          let extension = '';
          let name = '';

          const names = file.name as string;
          const split = names.split('.');
          if (split.length) {
            name = split[0];
            extension = split[split.length - 1];
          }

          this.files.push({ file_base64: reader.result, name: name, extension: extension });
        };
      }
    } else {
      let extension = '';
      let name = '';

      const split = event.name.split('.');
      if (split.length) {
        name = split[0];
        extension = split[split.length - 1];
      }

      this.files.push({ file_base64: event.file, name: name, extension: extension });
    }
    const element: HTMLInputElement = document.getElementById('inputFile') as HTMLInputElement;
    element.value = '';
  }

  removeFile(index) {
    this.files.splice(index, 1);

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  bindModal(modal) {
    this.modalObservation = modal;
  }

  closeModal() {
    if (!this.activatedRoute.snapshot.parent.params.installation_id) {
      this.allObservations.searchObservations();
    } else {
      this.allObservations.refreshData();
    }
    this.router.navigate([this.activatedRoute.snapshot.parent.url.join('/')]).then(() => {});
  }
}
