import { AfterViewInit, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Subject } from 'rxjs';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { ReportWorksheetsRequestService } from '../../../services/report.worksheets.request.service';

import { AllWorksComponent } from '../all-works/all-works.component';
import { DashboardRegistryComponent } from '../../registries/dashboard-registry/dashboard-registry.component';

@Component({
  selector: 'app-update-work',
  templateUrl: './update-work.component.html',
  styleUrls: ['./update-work.component.scss']
})
export class UpdateWorkComponent implements OnInit, AfterViewInit, OnDestroy {
  canCreate = false;
  canUpdate = false;

  allWorks: any;
  dashboardRegistry: any;

  workForm: FormGroup;
  id = new FormControl('');
  report_worksheet_id = new FormControl('');
  year = new FormControl('', [ Validators.required, Validators.min(1900), Validators.max(2100) ]);
  work = new FormControl('', [ Validators.required ]);
  state = new FormControl('0');
  comment = new FormControl(null);

  errors = {};

  isNew = true;
  loader = false;
  loaderContent = false;

  requestInProgress = new Subject();
  requestCount = 0;

  modalWork = null;

  constructor(
    private router: Router,
    private injector: Injector,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private reportsService: ReportWorksheetsRequestService
  ) {
    if (this.activatedRoute.snapshot.parent.params.report_id) {
      this.allWorks = <AllWorksComponent>this.injector.get(AllWorksComponent);
    } else {
      this.dashboardRegistry = <DashboardRegistryComponent>this.injector.get(DashboardRegistryComponent);
    }
  }

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loaderContent = this.requestCount > 0;
    });

    this.workForm = this.formBuilder.group({
      id: this.id,
      report_worksheet_id: this.report_worksheet_id,
      year: this.year,
      work: this.work,
      state: this.state,
      comment: this.comment,
    });

    if (this.activatedRoute.snapshot.params.work_id) {
      this.isNew = false;
      this.year.disable();
      this.getWork();
    } else {
      this.getReport();
      this.year.disable();
    }
  }

  ngAfterViewInit() {
    this.modalWork.open();
  }

  ngOnDestroy() {
    this.modalWork.close();
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  getWork() {
    let request;
    this.requestInProgress.next(true);

    if (this.activatedRoute.snapshot.parent.params.report_id) {
      request = this.reportsService.getWork(
        this.activatedRoute.snapshot.parent.params.registry_id,
        this.activatedRoute.snapshot.parent.params.establishment_id,
        this.activatedRoute.snapshot.parent.params.report_id,
        this.activatedRoute.snapshot.params.work_id
      );
    } else {
      request = this.reportsService.getWorkDashboard(
        this.activatedRoute.snapshot.parent.params.registry_id,
        this.activatedRoute.snapshot.params.report_id,
        this.activatedRoute.snapshot.params.work_id
      );
    }

    request.subscribe(response => {
      if (response['success']) {
        this.canUpdate = response['canUpdate'];

        this.workForm.patchValue(response['report']);

        if (!this.canUpdate) {
          this.workForm.disable();
        }
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  getReport() {
    this.requestInProgress.next(true);

    this.reportsService.getReport(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.activatedRoute.snapshot.parent.params.report_id
    ).subscribe(response => {
      if (response['success']) {
        this.workForm.patchValue({ year: response['report']['year'] });
      }
      this.requestInProgress.next(false);
    });
  }

  createWork() {
    let request;
    this.errors = {};
    this.loader = true;

    const work = {
      ...this.workForm.value,
      year: this.year.value
    };
    request = this.reportsService.createWork(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.activatedRoute.snapshot.parent.params.report_id,
      work
    );

    request.subscribe(response => {
      if (response['success']) {
        this.isNew = false;
        this.modalWork.close();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(response['errors'], this.workForm, this.notifier);
      }
      this.loader = false;
    });
  }

  updateWork() {
    let request;

    this.errors = {};
    this.loader = true;

    const work = {
      ...this.workForm.value,
      year: this.year.value
    };

    if (this.activatedRoute.snapshot.parent.params.report_id) {
      request = this.reportsService.updateWork(
        this.activatedRoute.snapshot.parent.params.registry_id,
        this.activatedRoute.snapshot.parent.params.establishment_id,
        this.activatedRoute.snapshot.parent.params.report_id,
        this.activatedRoute.snapshot.params.work_id,
        work
      );
    } else {
      request = this.reportsService.updateWorkDashboard(
        this.activatedRoute.snapshot.parent.params.registry_id,
        this.activatedRoute.snapshot.params.report_id,
        this.activatedRoute.snapshot.params.work_id,
        work
      );
    }

    request.subscribe(response => {
      if (response['success']) {
        this.modalWork.close();
        CustomNotifierService.getSuccessUpdate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(response['errors'], this.workForm, this.notifier);
      }
      this.loader = false;
    });
  }

  bindModal(modal) {
    this.modalWork = modal;
  }

  closeModal() {
    if (this.activatedRoute.snapshot.parent.params.report_id) {
      this.allWorks.refreshData();
    } else {
      this.dashboardRegistry.getWorks();
    }
    this.router.navigate([this.activatedRoute.snapshot.parent.url.join('/')]).then(() => {});
  }
}
