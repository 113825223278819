import { Component, OnDestroy, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs';

import { AuthService } from '../../../core/auth/auth.service';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { LogsRequestService } from '../../../services/logs.request.service';
import { RegistriesRequestService } from '../../../services/registries.request.service';
import { EstablishmentsRequestService } from '../../../services/establishments.request.service';
import { EnterprisesRequestService } from '../../../services/enterprises.request.service';
import { UsersRequestService } from '../../../services/users.request.service';

declare function Flatpickr(): any;

@Component({
  selector: 'app-all-logs',
  templateUrl: './all-logs.component.html',
  styleUrls: ['./all-logs.component.scss']
})
export class AllLogsComponent implements OnInit, OnDestroy {
  changeEnterprise: Subscription;

  requestInProgress = new Subject();
  requestCount = 0;

  logs: any[] = [];

  pageName: string;
  previousPageName: string[] = [];
  previousPageRoute: string[] = [];

  registries = [];
  selectedRegistries = [];
  establishments = [];
  selectedEstablishments = [];
  enterprises = [];
  selectedEnterprises = [];
  users = [];
  selectedUsers = [];

  dateFrom: any;
  dateTo: any;

  count = 0;
  pages = 1;

  search = '';
  sort = 'AllLogs.created';
  order = 'desc';
  limit = 12;
  page = 1;
  my_enterprise = [];

  timeout: any;
  loader = false;
  connectedAs = false;

  constructor(
    private authService: AuthService,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private logsService: LogsRequestService,
    private registriesServices: RegistriesRequestService,
    private establishmentServices: EstablishmentsRequestService,
    private enterpriseService: EnterprisesRequestService,
    private userService: UsersRequestService,
  ) {}

  ngOnInit() {

    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loader = this.requestCount > 0;
    });

    this.changeEnterprise = this.authService.getEnterprise().subscribe(() => {
      this.getLogs();
      this.getRegistries();
      this.getEstablishments();
      this.getEnterprises();
      Flatpickr();
    });

    this.pageName = 'Historique des actions';

    this.previousPageName.push('Tableau de bord');
    this.previousPageRoute.push('/');

    if (this.authService.loaded) {
      this.getLogs();
      this.getRegistries();
      this.getEstablishments();
      this.getEnterprises();
      setTimeout(function () {
        Flatpickr();
      }, 100);
    }

    this.connectedAs = JSON.parse(localStorage.getItem('connectedAs'));
    if (this.connectedAs) {
      this.my_enterprise = JSON.parse(localStorage.getItem('connectedAsEnterprise'));
    } else {
      this.my_enterprise = JSON.parse(localStorage.getItem('enterprise'));
    }
    const observableEnterprise = this.authService.getEnterprise();
    observableEnterprise.subscribe(value => {
      this.my_enterprise = value;
    });
  }

  ngOnDestroy() {
    this.changeEnterprise.unsubscribe();
  }

  searchData() {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.page = 1;
      this.refreshData();
    }, 500);
  }

  refreshData(sort = this.sort, order = this.order, page = this.page) {
    this.sort = sort;
    this.order = order;
    this.page = page;
    this.getLogs();
  }

  getRegistries() {
    let request;
    this.registries = [];
    this.requestInProgress.next(true);

    request = this.registriesServices.getMyRegistries('', 'Registries.name', 'ASC', '', '');

    request.subscribe(response => {
      if (response['success']) {
       this.registries = response['registries'];
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }
  getEstablishments() {
    let request;
    this.establishments = [];
    this.requestInProgress.next(true);

    request = this.establishmentServices.getMyEstablishments('', 'Establishments.name', 'ASC', '', '');

    request.subscribe(response => {
      if (response['success']) {
        this.establishments = response['establishments'];
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }
  getEnterprises() {
    let request;
    this.enterprises = [];
    this.requestInProgress.next(true);

    request = this.enterpriseService.getProviders('', 'Enterprises.name', 'ASC', '', '');

    request.subscribe(response => {
      if (response['success']) {
        this.enterprises = [...this.enterprises, {
          id: this.my_enterprise['id'],
          name: this.my_enterprise['name'],
        }];
        response['enterprises'].forEach( enterprise => {
          if (enterprise.id !== this.my_enterprise['id']) {
            this.enterprises = [...this.enterprises, {
              id: enterprise.id,
              name: enterprise.name,
            }];
          }
        });
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }
  getUsers() {
    let request;
    this.users = [];
    if (this.selectedEnterprises.length) {
      this.requestInProgress.next(true);
      request = this.userService.getMyUsers('', 'Users.firstname', 'ASC', '', '', this.selectedEnterprises);
      request.subscribe(response => {
        if (response['success']) {
          this.users = response['users'];
        } else {
          CustomNotifierService.getErrors(response['errors'], this.notifier);
        }
        this.requestInProgress.next(false);
      });
    } else {
      this.selectedUsers = [];
      this.selectedEnterprises = [];
      this.users = [];
    }
  }

  getLogs() {
    let request;
    this.logs = [];
    this.requestInProgress.next(true);

    request = this.logsService.getAllLog(
        this.search, this.sort, this.order, this.limit, this.page,
        this.selectedRegistries, this.selectedEstablishments, this.selectedEnterprises, this.selectedUsers,
        this.dateFrom, this.dateTo
    );

    request.subscribe(response => {
      if (response['success']) {
        this.count = response['count'];
        this.pages = response['pages'];

        this.logs = response['logs'];
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      Flatpickr();
      this.requestInProgress.next(false);
    });
  }

  filter(property) {
    if (this.sort === property) {
      if (this.order === 'asc') {
        this.order = 'desc';
      } else {
        this.order = 'asc';
      }
    } else {
      this.sort = property;
      this.order = 'asc';
    }

    this.getLogs();
  }

  dispatchPagination(data) {
    if (data.action === 'total_elements') {
      this.refreshPage(data.value);
    } else if (data.action === 'change_page') {
      this.changePage(data.value);
    }
  }

  refreshPage(limit) {
    this.limit = limit;
    this.page = 1;
    this.refreshData();
  }

  changePage(page) {
    if (page >= 1 && page <= this.pages) {
      this.page = page;
      this.getLogs();
    }
  }
}
