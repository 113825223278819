<app-modal-right (eventOnClose)="closeModal()" #sanitaryFrames>{{ bindModal(sanitaryFrames) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle" *ngIf="!adminFrame">{{ isNew ? 'Création' : canUpdate ? 'Mise à jour' : 'Consultation' }}</h6>
        <h6 class="header-pretitle" *ngIf="adminFrame">Consultation</h6>

        <h1 class="header-title">Fiche de suivi</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <form [formGroup]="sanitaryFrameForm" [hidden]="loaderContent">
      <div class="row">
        <div class="col-12">

          <!-- LABEL -->
          <div class="form-group" *ngIf="isNew || label !== null">
            <label for="validationTitle" required>Label</label>
            <input type="text" formControlName="label" class="form-control" id="validationTitle" placeholder="Label" [ngClass]="{ 'is-invalid': label.invalid && (label.dirty || label.touched) }">
            <div *ngIf="label.invalid && (label.dirty || label.touched)" class="invalid-feedback">
              {{ getErrorMessage(label) }}
            </div>
          </div>

          <div formArrayName="descriptions" class="mb-4">
            <div [formGroupName]="i" *ngFor="let column of sanitaryFrameForm['controls'].descriptions['controls'] as FormGroup; let i = index; let last = last" class="card">
              <div class="card-header">
                <div class="d-flex justify-content-between">
                  <a data-toggle="collapse" [href]="'#descriptions' + i" [attr.aria-controls]="'descriptions' + i" role="button" aria-expanded="true" required>
                    Colonne {{ i + 1 }}
                  </a>

                  <a class="href tooltipHtml" data-placement="bottom" title="Supprimer" (click)="deleteColumn(i)" *ngIf="!adminFrame && canDelete(i)">
                    <i class="fe fe-trash"></i>
                  </a>
                </div>
              </div>

              <div class="card-body collapse show" [id]="'descriptions' + i">
                <div class="form-group">
                  <input type="text" placeholder="Titre" formControlName="title" class="form-control d-inline-block col-12" [ngClass]="{'is-invalid': column['controls'].title.invalid && (column['controls'].title.dirty || column['controls'].title.touched) }">
                  <div *ngIf="column['controls'].title.invalid && (column['controls'].title.dirty || column['controls'].title.touched)" class="invalid-feedback">
                    {{ getErrorMessage("title") }}
                  </div>
                </div>
              </div>

            </div>
          </div>
          <button class="btn btn-primary col-6 offset-3" (click)="addColumn()" *ngIf="!adminFrame">
            Ajouter une colonne
          </button>

        </div>
      </div>
    </form>
  </div>

  <div modal-footer class="w-100" *ngIf="isNew || canUpdate">
    <button class="btn btn-primary w-100" (click)="isNew ? createSanitaryFrame() : updateSanitaryFrame()" [ngClass]="{ 'is-loading is-loading-sm': loader }" [disabled]="loader || sanitaryFrameForm.invalid || adminFrame">
      {{ isNew ? 'Créer' : 'Mettre à jour' }}
    </button>
  </div>
</app-modal-right>
