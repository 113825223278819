import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BreadcrumbsModule } from '../../../core/breadcrumbs/breadcrumbs.module';
import { ConfirmModalModule } from '../../../core/confirm-modal/confirm-modal.module';
import { SearchbarModule } from '../../../core/searchbar/searchbar.module';
import { PaginationModule } from '../../../core/pagination/pagination.module';
import { PeriodicAlertsComponent } from './periodic-alerts.component';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        ConfirmModalModule,
        BreadcrumbsModule,
        SearchbarModule,
        PaginationModule,
        NgSelectModule
    ],
    declarations: [PeriodicAlertsComponent]
})

export class PeriodicAlertsModule {}
