import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { HttpClient } from '@angular/common/http';
import { RequestService } from './request.service';

@Injectable()
export class CotationsRequestService extends RequestService {
  constructor(
    protected http: HttpClient,
    protected notifier: NotifierService
  ) {
    super(http, notifier);
  }

  /// GET
  public getAdminCotations() {
    return this.get('cotations');
  }
  public getCotations() {
    return this.get('cotations/mine');
  }
  public getAdminCotation(cotation_id) {
    return this.get('cotation/' + cotation_id);
  }
  public getCotation(cotation_id) {
    return this.get('cotation/mine/' + cotation_id);
  }
  public getAdminCotationLimit() {
    return this.get('cotation/limit');
  }
  public getCotationLimit() {
    return this.get('cotation/mine/limit');
  }

  /// CREATE
  public createAdminCotation(cotation) {
    return this.post('cotation',
      { cotation });
  }
  public createCotation(cotation) {
    return this.post('cotation/mine',
      { cotation });
  }
  public createAdminCotationLimit(limit) {
    return this.post('cotation/limit',
      { limit });
  }
  public createCotationLimit(limit) {
    return this.post('cotation/mine/limit',
      { limit });
  }

  /// UPDATE
  public updateAdminCotation(cotation_id, cotation) {
    return this.put('cotation/' + cotation_id,
      { cotation });
  }
  public updateCotation(cotation_id, cotation) {
    return this.put('cotation/mine/' + cotation_id,
      { cotation });
  }
  public updateAdminCotationLimit(limit_id, limit) {
    return this.put('cotation/limit/' + limit_id,
      { limit });
  }
  public updateCotationLimit(cotation_id, cotation) {
    return this.put('cotation/mine/limit/' + cotation_id,
      { cotation });
  }

  /// DELETE
  public deleteAdminCotation(cotation_id) {
    return this.delete('cotation/' + cotation_id);
  }
  public deleteEnterpriseCotation(cotation_id) {
    return this.delete('cotation/mine/' + cotation_id);
  }
}
