import { AfterViewInit, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Subject } from 'rxjs';

import { AllInterventionsComponent } from '../all-interventions/all-interventions.component';
import { AllPrescriptionsComponent } from '../../prescriptions/all-prescriptions/all-prescriptions.component';
import { AllObservationsComponent } from '../../observations/all-observations/all-observations.component';
import { DashboardRegistryComponent } from '../../registries/dashboard-registry/dashboard-registry.component';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { UsersRequestService } from '../../../services/users.request.service';

import { EnterprisesRequestService } from '../../../services/enterprises.request.service';
import { InterventionsRequestService } from '../../../services/interventions.request.service';
import { PrescriptionsRequestService } from '../../../services/prescriptions.request.service';
import { ObservationsRequestService } from '../../../services/observations.request.service';
import { OfficialPrescriptionsRequestService } from '../../../services/official.prescriptions.request.service';
import { AllOfficialPrescriptionsComponent } from '../../official-prescriptions/all-official-prescriptions/all-official-prescriptions.component';
import { AllRvratPrescriptionsComponent } from '../../rvrat-prescriptions/all-rvrat-prescriptions/all-rvrat-prescriptions.component';

import bati_file from '../../../../shared/interfaces/bati_file';
import { RvratPrescriptionsRequestService } from '../../../services/rvrat-prescriptions.request.service';

declare function Flatpickr(): any;

@Component({
  selector: 'app-update-intervention',
  templateUrl: './update-intervention.component.html',
  styleUrls: ['./update-intervention.component.scss']
})
export class UpdateInterventionComponent implements OnInit, AfterViewInit, OnDestroy {
  canCreate = false;
  canUpdate = false;

  requestInProgress = new Subject();
  requestCount = 0;

  dashboardRegistry: any;
  parentComponent: any;

  interventionForm: FormGroup;
  enterprise_id = new FormControl(null, Validators.required);
  user_id = new FormControl(null, Validators.required);
  deadline = new FormControl(null, Validators.required);
  actions = new FormControl(null, Validators.required);
  comments = new FormControl(null);
  merge_report = new FormControl(false);
  send_email = new FormControl(false);
  email = new FormControl(null);
  files_length = new FormControl(null);

  files: bati_file[] = [];
  enterprises: any = [];
  items: any = [];
  selectedItems: any = [];
  user: string;

  parent_id;
  errors = {};

  isNew = true;
  loader = false;
  loaderContent = false;

  activeTab = 'firstPart';

  monoOffer = false;
  discoverOffer = false;
  connectedAs = false;

  modalIntervention = null;

  constructor(
    private router: Router,
    private injector: Injector,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private usersService: UsersRequestService,
    private enterprisesService: EnterprisesRequestService,
    private prescriptionsService: PrescriptionsRequestService,
    private interventionsService: InterventionsRequestService,
    private observationsService: ObservationsRequestService,
    private officialPrescriptionsService: OfficialPrescriptionsRequestService,
    private rvratPrescriptionsService: RvratPrescriptionsRequestService,
  ) {
    if (this.activatedRoute.snapshot.parent.params.commission_id) {
      this.parentComponent = <AllPrescriptionsComponent>this.injector.get(AllPrescriptionsComponent);
    } else if (this.activatedRoute.snapshot.parent.params.report_id) {
      this.parentComponent = <AllObservationsComponent>this.injector.get(AllObservationsComponent);
    } else if (this.activatedRoute.snapshot.parent.params.official_id) {
      this.parentComponent = <AllOfficialPrescriptionsComponent>this.injector.get(AllOfficialPrescriptionsComponent);
    } else if (this.activatedRoute.snapshot.parent.params.rvrat_id) {
      this.parentComponent = <AllRvratPrescriptionsComponent>this.injector.get(AllRvratPrescriptionsComponent);
    } else if (this.activatedRoute.snapshot.params.intervention_id) {
      if (this.activatedRoute.parent.snapshot.params.registry_id) {
        this.parentComponent = <DashboardRegistryComponent>this.injector.get(DashboardRegistryComponent);
      } else {
        this.parentComponent = <AllInterventionsComponent>this.injector.get(AllInterventionsComponent);
      }
    } else {
      this.parentComponent = <DashboardRegistryComponent>this.injector.get(DashboardRegistryComponent);
    }
  }

  ngOnInit() {
    if (this.activatedRoute.snapshot.parent.params.commission_id) {
      this.parent_id = this.activatedRoute.snapshot.parent.params.commission_id;
    } else if (this.activatedRoute.snapshot.params.commission_id) {
      this.parent_id = this.activatedRoute.snapshot.params.commission_id;
    } else if (this.activatedRoute.snapshot.parent.params.report_id) {
      this.parent_id = this.activatedRoute.snapshot.parent.params.report_id;
    } else if (this.activatedRoute.snapshot.params.report_id) {
      this.parent_id = this.activatedRoute.snapshot.params.report_id;
    } else if (this.activatedRoute.snapshot.parent.params.official_id) {
      this.parent_id = this.activatedRoute.snapshot.parent.params.official_id;
    } else if (this.activatedRoute.snapshot.params.official_id) {
      this.parent_id = this.activatedRoute.snapshot.params.official_id;
    } else if (this.activatedRoute.snapshot.parent.params.rvrat_id) {
      this.parent_id = this.activatedRoute.snapshot.parent.params.rvrat_id;
    } else if (this.activatedRoute.snapshot.params.rvrat_id) {
      this.parent_id = this.activatedRoute.snapshot.params.rvrat_id;
    }

    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loaderContent = this.requestCount > 0;
    });

    this.interventionForm = this.formBuilder.group({
      enterprise_id: this.enterprise_id,
      user_id: this.user_id,
      deadline: this.deadline,
      actions: this.actions,
      comments: this.comments,
      merge_report: this.merge_report,
      send_email: this.send_email,
      email: this.email,
      files_length: this.files_length
    });

    this.connectedAs = JSON.parse(localStorage.getItem('connectedAs'));
    if (this.connectedAs) {
      this.monoOffer = JSON.parse(localStorage.getItem('connectedAsIsMonoOffer'));
      this.discoverOffer = JSON.parse(localStorage.getItem('connectedAsIsDiscoverOffer'));
    } else {
      this.monoOffer = JSON.parse(localStorage.getItem('isMonoOffer'));
      this.discoverOffer = JSON.parse(localStorage.getItem('isDiscoverOffer'));
    }

    if (!this.monoOffer && !this.discoverOffer) {
      this.getMyUsers();
    }

    this.getEnterprises();

    if (this.activatedRoute.snapshot.params.intervention_id) {
      this.isNew = false;
      this.getIntervention();
    } else {
      this.getAllItems();
    }
  }

  ngAfterViewInit() {
    this.modalIntervention.open();
  }

  ngOnDestroy() {
    const flatPicker = document.getElementsByClassName('flatpickr-calendar') as HTMLCollection;
    for (let i = flatPicker.length; i >= 0; i--) {
      if (flatPicker[i]) {
        flatPicker[i].remove();
      }
    }

    this.modalIntervention.close();
  }

  getEnterprises() {
    this.requestInProgress.next(true);

    this.enterprisesService.getProviders('', '', '', '', '').subscribe(response => {
      if (response['success']) {
        response['enterprises'].forEach(enterprise => {
          this.getUsers(enterprise);
        });
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  getUsers(enterprise) {
    this.requestInProgress.next(true);

    this.usersService.getProviderUsers('', '', '', '', '', enterprise.id).subscribe(response => {
      if (response['success']) {
        response['users'].forEach(user => {
          this.enterprises.push({
            id: enterprise.id + '-' + user.id,
            name: enterprise.name + ' - ' + user.firstname + ' ' + user.lastname,
            email: user.email
          });
        });
        this.changeUser();
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  getMyUsers() {
    this.requestInProgress.next(true);

    this.usersService.getMyUsers('', '', '', '', '').subscribe(response => {
      if (response['success']) {
        response['users'].forEach(user => {
          this.enterprises.push({
            id: response['enterprise'].id + '-' + user.id,
            name: response['enterprise'].name + ' - ' + user.firstname + ' ' + user.lastname,
            email: user.email
          });
        });
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  changeUser() {
    if (this.user) {
      const split = this.user.split('-');
      this.enterprise_id.setValue(split[0]);
      this.user_id.setValue(split[1]);

      const find = this.enterprises.find(element => {
        return element.id === this.user;
      });
      if (find) {
        this.email.setValue(find.email);
      }
    } else {
      this.enterprise_id.setValue(null);
      this.user_id.setValue(null);
      this.email.setValue(null);
    }
  }

  getIntervention() {
    let request;
    this.requestInProgress.next(true);

    request = this.interventionsService.getIntervention(
      this.activatedRoute.snapshot.params.intervention_id
    );

    request.subscribe(response => {
      if (response['success']) {
        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];

        this.interventionForm.patchValue(response['intervention']);

        response['intervention']['reference_action'].forEach(reference_action => {
          reference_action.group = true;
          this.items.push(reference_action);
        });

        response['intervention']['actions'].forEach(action => {
          action.group = true;
          this.selectedItems.push(action);
        });

        if (this.interventionForm.value.enterprise_id && this.interventionForm.value.user_id) {
          this.user = this.interventionForm.value.enterprise_id + '-' + this.interventionForm.value.user_id;
          this.changeUser();
        }

        if (!this.canUpdate) {
          this.interventionForm.disable();
        }
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      Flatpickr();
      this.requestInProgress.next(false);
    });
  }

  getAllItems() {
    let request;
    let id;
    this.requestInProgress.next(true);

    if (this.activatedRoute.snapshot.parent.params.commission_id || this.activatedRoute.snapshot.params.commission_id) {
      id = this.activatedRoute.snapshot.params.prescription_id;
      request = this.prescriptionsService.getAllUncorrectedPrescriptions(
        this.parent_id
      );
    } else if (this.activatedRoute.snapshot.parent.params.report_id || this.activatedRoute.snapshot.params.report_id) {
      id = this.activatedRoute.snapshot.params.observation_id;
      request = this.observationsService.getAllUncorrectedObservations(
        this.parent_id
      );
    } else if (this.activatedRoute.snapshot.parent.params.official_id || this.activatedRoute.snapshot.params.official_id) {
      id = this.activatedRoute.snapshot.params.prescription_id;
      request = this.officialPrescriptionsService.getAllUncorrectedPrescriptions(
        this.parent_id
      );
    } else if (this.activatedRoute.snapshot.parent.params.rvrat_id || this.activatedRoute.snapshot.params.rvrat_id) {
      id = this.activatedRoute.snapshot.params.rvrat_prescription_id;
      request = this.rvratPrescriptionsService.getAllUncorrectedRvratPrescriptions(
        this.parent_id
      );
    }

    request.subscribe(response => {
      if (response['success']) {
        if (this.activatedRoute.snapshot.params.prescription_id) {
          response['prescriptions'].forEach(prescriptions => {
            prescriptions.group = true;
            this.items.push(prescriptions);
          });
        } else if (this.activatedRoute.snapshot.params.observation_id) {
          response['observations'].forEach(observations => {
            observations.group = true;
            this.items.push(observations);
          });
        } else if (this.activatedRoute.snapshot.params.rvrat_prescription_id) {
          response['rvrat_prescriptions'].forEach(rvrat_prescription => {
            rvrat_prescription.group = true;
            this.items.push(rvrat_prescription);
          });
        }

        this.items.forEach(item => {
          if (item.id === parseInt(id, 10)) {
            this.selectedItems.push({ id: item.id, name: item.name });
            this.changeItems();

            if (item.enterprise_id && item.user_id) {
              this.user = item.enterprise_id + '-' + item.user_id;

              setTimeout(() => {
                this.changeUser();
              }, 150);
            }
          }
          this.deadline.setValue(item.deadline);

          if (this.activatedRoute.snapshot.params.commission_id || this.activatedRoute.snapshot.parent.params.commission_id) {
            this.comments.setValue(item.comment_prescription);
          } else if (this.activatedRoute.snapshot.params.report_id || this.activatedRoute.snapshot.parent.params.report_id) {
            this.comments.setValue(item.comment_observation);
          } else if (this.activatedRoute.snapshot.params.official_id || this.activatedRoute.snapshot.parent.params.official_id) {
            this.comments.setValue(item.comment);
          } else if (this.activatedRoute.snapshot.params.rvrat_id || this.activatedRoute.snapshot.parent.params.rvrat_id) {
            this.comments.setValue(item.comment);
          }
        });
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      Flatpickr();
      this.requestInProgress.next(false);
    });
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  changeItems() {
    const temp = [];
    this.selectedItems.forEach(item => {
      temp.push({ id : item.id, name : item.name });
    });
    this.actions.setValue(temp);
  }

  changeEmailValidator() {
    if (this.send_email.value) {
      this.email.setValidators([Validators.required, Validators.email]);
      this.email.updateValueAndValidity();
    } else {
      this.email.clearValidators();
      this.email.updateValueAndValidity();
    }
  }

  createIntervention() {
    let request;
    this.errors = {};
    this.loader = true;

    this.files_length.setValue(this.files.length);

    if (this.activatedRoute.snapshot.parent.params.commission_id || this.activatedRoute.snapshot.params.commission_id) {
      request = this.interventionsService.createPrescriptionIntervention(
        this.activatedRoute.snapshot.parent.params.registry_id,
        this.parent_id,
        this.activatedRoute.snapshot.params.prescription_id,
        this.interventionForm.value
      );
    } else if (this.activatedRoute.snapshot.parent.params.report_id || this.activatedRoute.snapshot.params.report_id) {
      request = this.interventionsService.createObservationIntervention(
        this.activatedRoute.snapshot.parent.params.registry_id,
        this.parent_id,
        this.activatedRoute.snapshot.params.observation_id,
        this.interventionForm.value
      );
    } else if (this.activatedRoute.snapshot.parent.params.official_id || this.activatedRoute.snapshot.params.official_id) {
      request = this.interventionsService.createOfficialPrescriptionIntervention(
        this.activatedRoute.snapshot.parent.params.registry_id,
        this.parent_id,
        this.activatedRoute.snapshot.params.prescription_id,
        this.interventionForm.value
      );
    } else if (this.activatedRoute.snapshot.parent.params.rvrat_id || this.activatedRoute.snapshot.params.rvrat_id) {
      request = this.interventionsService.createRvratPrescriptionIntervention(
        this.activatedRoute.snapshot.parent.params.registry_id,
        this.parent_id,
        this.activatedRoute.snapshot.params.rvrat_prescription_id,
        this.interventionForm.value
      );
    }

    request.subscribe(response => {
      if (response['success']) {
        if (this.files.length) {
          this.createDocuments(response['intervention']['id'], response['intervention']['intervention_number']);
        } else {
          CustomNotifierService.getSuccessCreate(this.notifier);
          this.downloadPdf(response['intervention']['id'], response['intervention']['intervention_number']);
        }
      } else if (response['errors']) {
        this.loader = false;
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
    });
  }

  updateIntervention() {
    let request;
    this.errors = {};
    this.loader = true;

    this.files_length.setValue(this.files.length);

    request = this.interventionsService.updateIntervention(
      this.activatedRoute.snapshot.params.intervention_id,
      this.interventionForm.value
    );

    request.subscribe(response => {
      if (response['success']) {
        this.isNew = false;
        if (this.files.length) {
          this.createDocuments(response['intervention']['id'], response['intervention']['intervention_number']);
        } else {
          CustomNotifierService.getSuccessUpdate(this.notifier);
          this.downloadPdf(response['intervention']['id'], response['intervention']['intervention_number']);
        }
      } else if (response['errors']) {
        this.loader = false;
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
    });
  }

  downloadPdf(id, intervention_number) {
    const fileName = 'bon_intervention_' + intervention_number + '.pdf';

    let request;
    request = this.interventionsService.downloadPdf(id);
    request.subscribe((response: BufferSource) => {
      try {
        const decoder = new TextDecoder('utf-8');
        const domString = decoder.decode(response);
        const json = JSON.parse(domString);
        CustomNotifierService.getErrors(json['errors'], this.notifier);
      } catch (e) {
        const blob = new Blob([response]);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();

        this.loader = false;
        this.modalIntervention.close();
      }
    });
  }

  createDocuments(intervention_id, intervention_number) {
    let request;
    this.errors = {};

    if (this.activatedRoute.snapshot.parent.params.commission_id || this.activatedRoute.snapshot.params.commission_id) {
      request = this.interventionsService.createPrescriptionFile(
        this.activatedRoute.snapshot.parent.params.registry_id,
        this.parent_id,
        this.activatedRoute.snapshot.params.prescription_id,
        intervention_id,
        this.files
      );
    } else if (this.activatedRoute.snapshot.parent.params.report_id || this.activatedRoute.snapshot.params.report_id) {
      request = this.interventionsService.createObservationFile(
        this.activatedRoute.snapshot.parent.params.registry_id,
        this.parent_id,
        this.activatedRoute.snapshot.params.observation_id,
        intervention_id,
        this.files
      );
    } else if (this.activatedRoute.snapshot.parent.params.official_id || this.activatedRoute.snapshot.params.official_id) {
      request = this.interventionsService.createOfficialPrescriptionFile(
        this.activatedRoute.snapshot.parent.params.registry_id,
        this.parent_id,
        this.activatedRoute.snapshot.params.prescription_id,
        intervention_id,
        this.files
      );
    } else if (this.activatedRoute.snapshot.parent.params.rvrat_id || this.activatedRoute.snapshot.params.rvrat_id) {
      request = this.interventionsService.createRvratPrescriptionFile(
        this.activatedRoute.snapshot.parent.params.registry_id,
        this.parent_id,
        this.activatedRoute.snapshot.params.rvrat_prescription_id,
        intervention_id,
        this.files
      );
    } else if (this.activatedRoute.snapshot.params.intervention_id) {
      request = this.interventionsService.createFile(
        intervention_id,
        this.files
      );
    }

    request.subscribe(response => {
      if (response['success']) {
        this.downloadPdf(intervention_id, intervention_number);
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loader = false;
    });
  }

  chooseFile() {
    const element: HTMLElement = document.getElementById('inputFile') as HTMLElement;
    element.click();
  }

  onFileChange(event) {
    if (event.target && event.target.files && event.target.files.length) {
      for (let i = 0; i < event.target.files.length; i++) {
        const reader = new FileReader();
        const file = event.target.files[i];
        reader.readAsDataURL(file);

        reader.onload = () => {
          let extension = '';
          let name = '';

          const split = file.name.split('.');
          if (split.length) {
            name = split[0];
            extension = split[split.length - 1];
          }

          this.files.push({ file_base64: reader.result, name: name, extension: extension });
        };
      }
    } else {
      let extension = '';
      let name = '';

      const split = event.name.split('.');
      if (split.length) {
        name = split[0];
        extension = split[split.length - 1];
      }

      this.files.push({ file_base64: event.file, name: name, extension: extension });
    }
    const element: HTMLInputElement = document.getElementById('inputFile') as HTMLInputElement;
    element.value = '';
  }

  removeFile(index) {
    this.files.splice(index, 1);

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  prevTabs() {
    this.activeTab = 'firstPart';
  }

  nextTabs() {
    this.activeTab = 'secondPart';
  }

  changeTabs(activeTab) {
    this.activeTab = activeTab;
  }

  bindModal(modal) {
    this.modalIntervention = modal;
  }

  closeModal() {
    if (this.activatedRoute.snapshot.parent.params.commission_id) {
      this.parentComponent.refreshData();
    } else if (this.activatedRoute.snapshot.parent.params.report_id) {
      this.parentComponent.refreshData();
    } else if (this.activatedRoute.snapshot.parent.params.rvrat_id) {
      this.parentComponent.refreshData();
    } else if (this.activatedRoute.snapshot.params.intervention_id) {
      if (this.activatedRoute.parent.snapshot.params.registry_id) {
        this.parentComponent.getObservations();
      } else {
        this.parentComponent.refreshData();
      }
    } else {
      this.parentComponent.getPrescriptions();
    }
    if (this.activatedRoute.parent.snapshot.params.registry_id) {
      if (this.activatedRoute.parent.snapshot.params.establishment_id) {
        this.router.navigate([
          'dashboard-registry/' + this.activatedRoute.parent.snapshot.params.registry_id +
          '/establishment/' + this.activatedRoute.parent.snapshot.params.establishment_id]).then(() => {
        });
      } else {
        this.router.navigate(['dashboard-registry/' + this.activatedRoute.parent.snapshot.params.registry_id]).then(() => {
        });
      }
    } else {
      this.router.navigate([this.activatedRoute.snapshot.parent.url.join('/')]).then(() => {});
    }
  }
}
