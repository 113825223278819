<app-modal-right (eventOnClose)="closeModal()" #worksheet>{{ bindModal(worksheet) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">{{ isNew ? 'Création' : canUpdate ? 'Mise à jour' : 'Consultation' }}</h6>

        <h1 class="header-title">Fiche travaux</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <form [formGroup]="sanitaryWorksheetForm" [hidden]="loaderContent">
      <!-- DATE -->
      <div class="form-group">
        <label for="validationDate" required>Date</label>
        <input type="text" formControlName="date" class="form-control" id="validationDate" placeholder="Date" data-toggle="flatpickr" [ngClass]="{ 'is-invalid': date.invalid && (date.dirty || date.touched) }">
      </div>
      <!-- PROVIDERS -->
      <div class="form-group">
        <label for="validationProvider" required>Prestataire</label>
        <input type="text" formControlName="provider" class="form-control" id="validationProvider" placeholder="Prestataire" [ngClass]="{ 'is-invalid': provider.invalid && (provider.dirty || provider.touched) }">
      </div>
      <!-- DESCRIPTION -->
      <div class="form-group">
        <label for="validationDescription" required>Description</label>
        <textarea type="text" formControlName="description" class="form-control" id="validationDescription" placeholder="Description" [ngClass]="{ 'is-invalid': description.invalid && (description.dirty || description.touched) }"></textarea>
      </div>
    </form>
  </div>
  <div modal-footer class="w-100" *ngIf="isNew || canUpdate">
    <button class="btn btn-primary w-100" (click)="isNew ? createSanitaryWorksheet() : updateSanitaryWorksheet()" [ngClass]="{ 'is-loading is-loading-sm': loader }" [disabled]="loader || sanitaryWorksheetForm.invalid">
      {{ isNew ? 'Créer' : 'Mettre à jour' }}
    </button>
  </div>
</app-modal-right>
