import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AccessInternalCheckpointsModule } from './access-internal-checkpoints/access-internal-checkpoints.module';
import {AccessInternalRegistriesModule} from './access-internal-registries/access-internal-registries.module';

@NgModule({
  imports: [
    CommonModule,
    AccessInternalCheckpointsModule,
    AccessInternalRegistriesModule
  ]
})

export class AccessInternalModule {}
