import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AllSingleSignOnModule } from './all-single-sign-on/all-single-sign-on.module';
import { UpdateSingleSignOnModule } from './update-single-sign-on/update-single-sign-on.module';
import { SsoConfigsRequestService } from '../../services/sso-configs.request.service';

@NgModule({
  imports: [
    CommonModule,
    AllSingleSignOnModule,
    UpdateSingleSignOnModule
  ],
  providers: [
    SsoConfigsRequestService
  ]
})

export class SingleSignOnModule {}
