import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Subject, Subscription } from 'rxjs';
import { NotifierService } from 'angular-notifier';
import { CheckpointsRequestService } from '../../../../services/checkpoints.request.service';
import { AuthService } from '../../../../core/auth/auth.service';
import { CustomNotifierService } from '../../../../services/custom.notifier.service';

import { environment } from '../../../../../environments/environment';

import Establishment from '../../../../../shared/interfaces/establishment';
import Enterprise from '../../../../../shared/interfaces/enterprise';
import { RisksRequestService } from '../../../../services/risks.request.service';


@Component({
  selector: 'app-global-unities',
  templateUrl: './global-unities.component.html',
  styleUrls: ['./global-unities.component.scss']
})
export class GlobalUnitiesComponent implements OnInit, OnDestroy {
  changeEnterprise: Subscription;
  environment = environment;

  canUpdate = false;
  canCreate = false;

  requestInProgress = new Subject();
  requestCount = 0;

  loader = false;
  errors = {};

  pageName: string;
  previousPageName: string[] = [];
  previousPageRoute: string[] = [];

  unities: any;
  user_id: number;
  enterprise: Enterprise;
  enterprise_id: number;
  establishments: Establishment[] = [];
  openedEstablishment = -1;

  timeout: any;
  view = '';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private notifier: NotifierService,
    private checkpointsService: CheckpointsRequestService,
    private risksService: RisksRequestService,
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loader = this.requestCount > 0;
    });

    this.changeEnterprise = this.authService.getEnterprise().subscribe(() => {
      this.user_id = parseInt(this.activatedRoute.snapshot.params.user_id, 10);
      this.enterprise_id = parseInt(this.activatedRoute.snapshot.params.enterprise_id, 10);
      this.getEstablishmentUnities();
      this.createBreadcrumbs();
    });

    if (this.authService.loaded) {
      this.user_id = parseInt(this.activatedRoute.snapshot.params.user_id, 10);
      this.enterprise_id = parseInt(this.activatedRoute.snapshot.params.enterprise_id, 10);
      this.getEstablishmentUnities();
      this.createBreadcrumbs();
    }
  }

  ngOnDestroy() {
    this.changeEnterprise.unsubscribe();

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  getEstablishmentUnities() {
    let request;
    this.requestInProgress.next(true);

    request = this.risksService.getForGlobalUnitiesShow();

    request.subscribe(response => {
      if (response['success']) {
        response['establishments'].forEach(establishment => {
          establishment.collapsed = true;
        });
        this.establishments = response['establishments'];
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  updateShow() {
    this.requestInProgress.next(true);
    this.risksService.updateForGlobalUnitiesShow(this.establishments).subscribe(response => {
      if (response['success']) {
        CustomNotifierService.getSuccessUpdate(this.notifier);
        this.getEstablishmentUnities();
      } else if (response['errors']) {
        CustomNotifierService.getErrors(this.errors, this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  calcCheckedEstablishment(keyEstablishment) {
    let total = 0;
    if (this.establishments) {
      this.establishments[keyEstablishment]['establishment_risk_unity_works'].forEach(unity => {
        if (unity.show_unity_work) {
          total++;
        }
      });
    }
    return total;
  }

  calcAllEstablishment(keyEstablishment) {
    let total = 0;
    if (this.establishments) {
      this.establishments[keyEstablishment]['establishment_risk_unity_works'].forEach(() => {
        total++;
      });
    }
    return total;
  }

  toggleEstablishment(key) {
    if (this.openedEstablishment >= 0) {
      if (this.openedEstablishment === key) {
        this.establishments[key].collapsed = true;
        this.openedEstablishment = -1;
      } else {
        if (this.openedEstablishment >= 0) {
          this.establishments[this.openedEstablishment].collapsed = true;
        }
        this.establishments[key].collapsed = false;
        this.openedEstablishment = key;
      }
    } else {
      this.establishments[key].collapsed = false;
      this.openedEstablishment = key;
    }
  }

  checkEstablishment(keyEstablishment) {
    if (this.calcCheckedEstablishment(keyEstablishment) === this.calcAllEstablishment(keyEstablishment)) {
      this.establishments[keyEstablishment]['establishment_risk_unity_works'].forEach(unity => {
        unity.show_unity_work = false;
      });
    } else {
      this.establishments[keyEstablishment]['establishment_risk_unity_works'].forEach(unity => {
        unity.show_unity_work = true;
      });
    }
  }

  checkUnity(keyEstablishment, keyUnity) {
    const show_unity_work = this.establishments[keyEstablishment]['establishment_risk_unity_works'][keyUnity].show_unity_work;
    this.establishments[keyEstablishment]['establishment_risk_unity_works'][keyUnity].show_unity_work = !show_unity_work;
  }

  createBreadcrumbs() {
    this.pageName = 'Edition de masse';

    this.previousPageName = [];
    this.previousPageRoute = [];

    this.previousPageName.push('Tableau de bord');
    this.previousPageRoute.push('/');

    this.previousPageName.push('Tableau de bord registre');
    this.previousPageRoute.push('/dashboard-registry/' + this.activatedRoute.snapshot.params.registry_id);

    this.previousPageName.push('Unités de travail');
    this.previousPageRoute.push('/dashboard-registry/' + this.activatedRoute.snapshot.params.registry_id + '/risks-unities');
  }
}
