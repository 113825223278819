import { Routes } from '@angular/router';

import { AccountComponent } from './account.component';

export const accountRoutes: Routes = [
  {
    path: 'account',
    component: AccountComponent
  },
];
