<div class="main-content">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col">
        <div class="header mb-0">
          <div class="header-body no-border">
            <div class="row align-items-center">
              <div class="col">
                <h1 class="header-title">
                  Centre de notification</h1>

                <small class="form-text text-muted">
                  Liste du centre de notification
                </small>

                <app-breadcrumbs [currentPage]="pageName" [names]="previousPageName" [routes]="previousPageRoute"></app-breadcrumbs>
              </div>

              <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0" *ngIf="canCreate">
                <a *ngIf="view === 'mine'" class="btn btn-primary d-block d-md-inline-block" [routerLink]="['/notification-center/mine/messages/create']">
                  Ajouter une notification
                </a>
                <a *ngIf="view === 'admin'" class="btn btn-primary d-block d-md-inline-block" [routerLink]="['/notification-center/create']">
                  Ajouter une notification
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="is-loading loader-content" *ngIf="loader"></div>

        <div class="tab-content" *ngIf="!loader && notifications.length">
          <div class="tab-pane fade active show" id="tabPaneOne" role="tabpanel">
            <div class="row listAlias">
              <div class="col-12 d-flex flex-column">
                <div class="card flex-fill">
                  <div class="card-body">
                    <div class="table-responsive">

                      <table class="table table-striped" *ngIf="!loader && personal_message.length && view === 'mine'">
                        <thead>
                          <tr>
                            <th class="href">
                              <a class="text-muted">Message personnalisé</a>
                            </th>
                            <th></th>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>
                        <thead>
                          <tr>
                            <th class="href">
                              <a class="text-muted">Utilisateurs notifiés</a>
                            </th>
                            <th class="href">
                              <a class="text-muted">URL</a>
                            </th>
                            <th class="href">
                              <a class="text-muted">Message</a>
                            </th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let item of personal_message">
                            <td width="300">
                              <div *ngFor="let user of item['users']">
                                {{ user.name }}
                              </div>
                            </td>
                            <td width="300">
                              <a class="href" target="_blank" *ngIf="item.url" [href]="item.url">
                                {{ item.url }}
                              </a>
                            </td>
                            <td><div [innerHTML]="sanitizerHtml(item.personal_message)"></div></td>
                            <td width="20">
                              <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fe fe-more-vertical"></i>
                              </a>
                              <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item href" [routerLink]="[item.id]">
                                  {{ canUpdate ? 'Éditer la notification' : 'Consulter la notification' }}
                                </a>
                                <a class="dropdown-item href" (click)="modalConfirmData.open(item.id)" *ngIf="canDelete">
                                  Supprimer la notification
                                </a>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <table class="table table-striped" *ngIf="!loader && personal_message.length && view === 'admin'">
                        <thead>
                        <tr>
                          <th class="href">
                            <a class="text-muted">Message général</a>
                          </th>
                          <th></th>
                          <th></th>
                        </tr>
                        </thead>
                        <thead>
                          <tr>
                            <th class="href">
                              <a class="text-muted">Message</a>
                            </th>
                            <th class="href">
                              <a class="text-muted">URL</a>
                            </th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let item of personal_message">
                          <td><div [innerHTML]="sanitizerHtml(item.personal_message)"></div></td>
                          <td width="20">
                            <a class="href" target="_blank" *ngIf="item.url" [href]="item.url">
                              {{ item.url }}
                            </a>
                          </td>
                          <td width="20">
                            <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <i class="fe fe-more-vertical"></i>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right">
                              <a class="dropdown-item href" [routerLink]="[item.id]">
                                {{ canUpdate ? 'Éditer la notification' : 'Consulter la notification' }}
                              </a>
                              <a class="dropdown-item href" (click)="modalConfirmData.open(item.id)" *ngIf="canDelete">
                                Supprimer la notification
                              </a>
                            </div>
                          </td>
                        </tr>
                        </tbody>
                      </table>

                    </div>
                    <app-pagination (returnEvent)="dispatchPagination($event)"
                                    [count_elements]="count"
                                    [current_page]="page"
                                    [elements_length]="notifications.length"
                                    [limit]="limit"
                                    [total_pages]="pages"
                                    ngDefaultControl>
                    </app-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="loader-content" *ngIf="!loader && !notifications.length">
          <p>Aucun résultat</p>
        </div>
      </div>
    </div>
  </div>
</div>

<app-confirm-modal [type]="'delete_data'" #confirmData>{{ bindModalConfirmData(confirmData) }}</app-confirm-modal>

<router-outlet></router-outlet>
