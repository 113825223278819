import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { HttpClient } from '@angular/common/http';
import { RequestService } from './request.service';

@Injectable()
export class ObservationsRequestService extends RequestService {
  constructor(
    protected http: HttpClient,
    protected notifier: NotifierService
  ) {
    super(http, notifier);
  }

  /**
   * GET
    */
  /* OBSERVATIONS */
  public getAllObservations(search, sort, order, limit, page, report_id) {
    return this.get('reports/' + report_id +
      '/observations?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getAllUncorrectedObservations(report_id) {
    return this.get('reports/' + report_id + '/observations/uncorrected');
  }
  public getObservation(report_id, observation_id) {
    return this.get('reports/' + report_id + '/observations/' + observation_id);
  }
  public getObservationState(report_id, observation_id) {
    return this.get('reports/' + report_id + '/observations/' + observation_id + '/state');
  }

  public getCustomerAllObservations(
    search, sort, order, limit, page, enterprise_id, establishment_id, registry_id, installation_id, checkpoint_id, report_id) {
    return this.get('customers/' + enterprise_id + '/establishments/' + establishment_id + '/registries/' + registry_id +
      '/installations/' + installation_id + '/checkpoints/' + checkpoint_id + '/reports/' + report_id +
      '/observations?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getCustomerObservation(enterprise_id, establishment_id, registry_id, observation_id) {
    return this.get('customers/' + enterprise_id + '/establishments/' + establishment_id + '/registries/' + registry_id +
      '/installations/observations/' + observation_id);
  }
  public getCustomerObservationState(
    enterprise_id, establishment_id, registry_id, observation_id) {
    return this.get('customers/' + enterprise_id + '/establishments/' + establishment_id + '/registries/' + registry_id +
      '/installations/observations/' + observation_id +
      '/state');
  }

  /* OBSERVATIONS FILES */
  public getFiles(search, sort, order, limit, page, observation_id) {
    return this.get('observations/' + observation_id + '/files?' +
      'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getFile(observation_id, file_id) {
    return this.get('observations/' + observation_id + '/files/' + file_id);
  }

  public getCustomerFiles(search, sort, order, limit, page,
    enterprise_id, establishment_id, registry_id, installation_id, checkpoint_id, report_id, observation_id) {
    return this.get('customers/' + enterprise_id + '/establishments/' + establishment_id + '/registries/' + registry_id +
      '/installations/' + installation_id + '/checkpoints/' + checkpoint_id + '/reports/' + report_id + '/observations/' + observation_id +
      '/files?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getCustomerFile(enterprise_id, establishment_id, registry_id, installation_id, checkpoint_id, report_id, observation_id, file_id) {
    return this.get('customers/' + enterprise_id + '/establishments/' + establishment_id + '/registries/' + registry_id +
      '/installations/' + installation_id + '/checkpoints/' + checkpoint_id + '/reports/' + report_id + '/observations/' + observation_id +
      '/files/' + file_id);
  }

  /* OBSERVATIONS DASHBOARD */
  public getAllUncorrectedObservationsRegistry(registry_id, report_id) {
    return this.get('dashboard-registry/' + registry_id + '/reports/' + report_id + '/observations/uncorrected');
  }
  public getObservationDashboard(search, sort, order, limit, page, registry_id, establishment_id, checkpoint_id, emergency, year, month) {
    if (establishment_id) {
      return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/observations?' +
        'checkpoint_id=' + checkpoint_id + '&emergency=' + emergency + '&year=' + year + '&month=' + month +
        '&search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
    } else {
      return this.get('dashboard-registry/' + registry_id + '/observations?' +
        'checkpoint_id=' + checkpoint_id + '&emergency=' + emergency + '&year=' + year + '&month=' + month +
        '&search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
    }
  }
  public getMyObservationDashboard(search, sort, order, limit, page, registry_id, establishment_id, checkpoint_id, emergency, year, month) {
    if (establishment_id) {
      return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/observations/mine?' +
        'checkpoint_id=' + checkpoint_id + '&emergency=' + emergency + '&year=' + year + '&month=' + month +
        '&search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
    } else {
      return this.get('dashboard-registry/' + registry_id + '/observations/mine?' +
        'checkpoint_id=' + checkpoint_id + '&emergency=' + emergency + '&year=' + year + '&month=' + month +
        '&search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
    }
  }
  /* ESTABLISHMENT OBSERVATIONS DASHBOARD */
  public getEstablishmentObservation(registry_id, establishment_id, observation_id) {
    return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id +
      '/observations/' + observation_id);
  }
  public getEstablishmentObservationState(registry_id, establishment_id, observation_id) {
    return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id +
      '/observations/' + observation_id + '/state');
  }
  /* REGISTRY OBSERVATIONS DASHBOARD */
  public getRegistryObservation(registry_id, observation_id) {
    return this.get('dashboard-registry/' + registry_id + '/observations/' + observation_id);
  }
  public getRegistryObservationState(registry_id, observation_id) {
    return this.get( 'dashboard-registry/' + registry_id + '/observations/' + observation_id + '/state');
  }
  /* CUSTOMERS REGISTRY OBSERVATIONS DASHBOARD */
  public getCustomerObservationDashboard(search, sort, order, limit, page, registry_id, establishment_id, checkpoint_id, emergency) {
    return this.get('customers/dashboard/' + establishment_id + '/' + registry_id + '/observations?' +
      'checkpoint_id=' + checkpoint_id + '&emergency=' + emergency + '&search=' + search + '&sort=' + sort + '&order=' + order +
      '&limit=' + limit + '&page=' + page);
  }


  /// CREATE
  public createObservation(report_id, observation) {
    return this.post('reports/' + report_id + '/observations',  { observation });
  }
  public createFile(observation_id, file) {
    return this.post('observations/' + observation_id + '/files', { file: file });
  }

  public createCustomerObservation(enterprise_id, establishment_id, registry_id, installation_id, checkpoint_id, report_id, observation) {
    return this.post('customers/' + enterprise_id + '/establishments/' + establishment_id + '/registries/' + registry_id +
      '/installations/' + installation_id + '/checkpoints/' + checkpoint_id + '/reports/' + report_id + '/observations',  { observation });
  }
  public createCustomerFile(enterprise_id, establishment_id, registry_id, installation_id, checkpoint_id, report_id, observation_id, file) {
    return this.post('customers/' + enterprise_id + '/establishments/' + establishment_id + '/registries/' + registry_id +
      '/installations/' + installation_id + '/checkpoints/' + checkpoint_id + '/reports/' + report_id + '/observations/' + observation_id +
      '/files', { file: file });
  }
  /// UPDATE
  public updateObservation(report_id, observation_id, observation) {
    return this.put('reports/' + report_id + '/observations/' + observation_id, { observation });
  }
  public updateObservationState(report_id, observation_id, observation) {
    return this.put('reports/' + report_id + '/observations/' + observation_id + '/state', { observation });
  }
  public updateEstablishmentObservation(registry_id, establishment_id, observation_id, observation) {
    return this.put('dashboard-registry/' + registry_id + '/establishment/' + establishment_id +
      '/observations/' + observation_id, { observation });
  }
  public updateEstablishmentObservationState(registry_id, establishment_id, observation_id, observation) {
    return this.put('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/observations/' + observation_id +
      '/state', { observation });
  }
  public updateRegistryObservation(registry_id, observation_id, observation) {
    return this.put('dashboard-registry/' + registry_id + '/observations/' + observation_id, { observation });
  }
  public updateRegistryObservationState(registry_id, observation_id, observation) {
    return this.put('dashboard-registry/' + registry_id + '/observations/' + observation_id + '/state', { observation });
  }
  public updateFile(observation_id, file_id, file) {
    return this.put('observations/' + observation_id + '/files/' + file_id, { file: file });
  }

  public updateCustomerFile(
    enterprise_id, establishment_id, registry_id, installation_id, checkpoint_id, report_id, observation_id, file_id, file) {
    return this.put('customers/' + enterprise_id + '/establishments/' + establishment_id + '/registries/' + registry_id +
      '/installations/' + installation_id + '/checkpoints/' + checkpoint_id + '/reports/' + report_id + '/observations/' + observation_id +
      '/files/' + file_id, { file: file });
  }
  public updateCustomerObservationState(
    enterprise_id, establishment_id, registry_id, observation_id, observation) {
    return this.put('customers/' + enterprise_id + '/establishments/' + establishment_id + '/registries/' + registry_id +
      '/installations/observations/' + observation_id + '/state', { observation });
  }
  public updateCustomerObservation(
    enterprise_id, establishment_id, registry_id, observation_id, observation) {
    return this.put('customers/' + enterprise_id + '/establishments/' + establishment_id + '/registries/' + registry_id +
      '/installations/observations/' + observation_id, { observation });
  }

  /// DELETE
  public deleteObservation(report_id, observation_id) {
    return this.delete('reports/' + report_id + '/observations/' + observation_id);
  }
  public deleteFile(observation_id, file_id) {
    return this.delete('observations/' + observation_id + '/files/' + file_id);
  }
  public deleteCustomerFile(
    enterprise_id, establishment_id, registry_id, installation_id, checkpoint_id, report_id, observation_id, file_id) {
    return this.delete('customers/' + enterprise_id + '/establishments/' + establishment_id + '/registries/' + registry_id +
      '/installations/' + installation_id + '/checkpoints/' + checkpoint_id + '/reports/' + report_id + '/observations/' + observation_id +
      '/files/' + file_id);
  }

  /// DOWNLOAD
  public downloadFile(observation_id, file_id) {
    return this.get('observations/' + observation_id + '/files/' + file_id + '/download', { responseType: 'arraybuffer' });
  }
  public downloadCustomerFile(
    enterprise_id, establishment_id, registry_id, installation_id, checkpoint_id, report_id, observation_id, file_id) {
    return this.get('customers/' + enterprise_id + '/establishments/' + establishment_id + '/registries/' + registry_id +
      '/installations/' + installation_id + '/checkpoints/' + checkpoint_id + '/reports/' + report_id + '/observations/' + observation_id +
      '/files/' + file_id + '/download', { responseType: 'arraybuffer' });
  }
}
