import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoginModule } from './login/login.module';
import { NewPasswordModule } from './new-password/new-password.module';
import { LostPasswordModule } from './lost-password/lost-password.module';
import { SsoLoginModule } from './sso-login/sso-login.module';
import { DiscoverModule } from './discover/discover.module';

@NgModule({
  imports: [
    CommonModule,
    LoginModule,
    SsoLoginModule,
    NewPasswordModule,
    LostPasswordModule,
    DiscoverModule
  ],
  declarations: []
})
export class AuthModule {}
