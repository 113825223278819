<app-modal-right
  (eventOnClose)="closeModal()"
  checkEditClose="false"
  #downloadModal
  >{{ bindModal(downloadModal) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">Téléchargement</h6>

        <h1 class="header-title">Documents à télécharger</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <form [formGroup]="downloadForm" *ngIf="!loaderContent">
      <div class="row" *ngIf="isNew && !loaderContent">
        <div class="col-12">
          <div class="form-group">
            <label>Document à télécharger</label>
            <select
              class="form-control"
              data-toggle="select"
              formControlName="name"
              id="validationName"
            >
              <option [ngValue]="null" [disabled]="true" class="text-muted">
                Document à télécharger
              </option>
              <ng-container *ngFor="let filteredExport of filteredExports">
                <option [value]="filteredExport.id">
                  {{ filteredExport.name }}
                </option>
              </ng-container>
            </select>
          </div>
        </div>

        <!----------------------------- EXPORT BEGIN END DATE ---------------------->
        <div class="col-12">
          <div class="form-group" *ngIf="name.value">
            <label class="w-100" required>Format de l'export</label>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                id="csv"
                value="CSV"
                formControlName="exportType"
              />
              <label class="form-check-label" for="csv">CSV</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                id="xlsx"
                value="XLSX"
                formControlName="exportType"
              />
              <label class="form-check-label" for="xlsx">XLSX</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                id="xls"
                value="XLS"
                formControlName="exportType"
              />
              <label class="form-check-label" for="xls">XLS</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                id="ods"
                value="ODS"
                formControlName="exportType"
              />
              <label class="form-check-label" for="ods">ODS</label>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div modal-footer class="w-100">
    <button
      class="btn btn-primary w-100"
      (click)="downloadReport()"
      [ngClass]="{ 'is-loading is-loading-sm': loader }"
      [disabled]="loader || !name.value"
    >
      Télécharger
    </button>
  </div>
</app-modal-right>
