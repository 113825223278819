import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Subject } from 'rxjs';
import { NgbTimeAdapter } from '@ng-bootstrap/ng-bootstrap';

import { AllSanitaryTemperatureFramesComponent } from '../all-sanitary-temperature-frames/all-sanitary-temperature-frames.component';
import { SanitaryTemperatureFramesRequestService } from '../../../../services/sanitary-temperature-frames.request.service';
import { SanitaryTemperaturesRequestService } from '../../../../services/sanitary-temperatures.request.service';
import { EstablishmentsRequestService } from '../../../../services/establishments.request.service';

import { NgbTimeStringAdapter } from '../../../../core/utils/ngb-time-string-adapter';
import { CustomNotifierService } from '../../../../services/custom.notifier.service';

import SanitaryTemperature from '../../../../../shared/interfaces/sanitary_temperature';

import * as _ from 'lodash';

declare function Flatpickr(): any;
declare function Dropzones(): any;

@Component({
  selector: 'app-update-sanitary-temperature-frame',
  templateUrl: './update-sanitary-temperature-frame.component.html',
  styleUrls: ['./update-sanitary-temperature-frame.component.scss'],
  providers: [{ provide: NgbTimeAdapter, useClass: NgbTimeStringAdapter }]
})
export class UpdateSanitaryTemperatureFrameComponent implements OnInit, AfterViewInit, OnDestroy {
  counter = Array;
  canCreate = false;
  canUpdate = false;

  activeTab = 1;

  requestInProgress = new Subject();
  requestCount = 0;

  sanitaryTemperature: SanitaryTemperature;

  sanitaryTemperatureFrameForm: FormGroup;
  date = new FormControl('', Validators.required);
  title = new FormControl('', Validators.required);
  frame = new FormControl(null, Validators.required);
  descriptions = new FormControl('');
  detected_anomalies = new FormControl('');
  interventions = new FormControl('');

  months = [
    'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
  ];
  days = [
    'Jour 1', 'Jour 2', 'Jour 3', 'Jour 4', 'Jour 5', 'Jour 6', 'Jour 7', 'Jour 8', 'Jour 9', 'Jour 10', 'Jour 11', 'Jour 12',
    'Jour 13', 'Jour 14', 'Jour 15', 'Jour 16', 'Jour 17', 'Jour 18', 'Jour 19', 'Jour 20', 'Jour 21', 'Jour 22', 'Jour 23',
    'Jour 24', 'Jour 25', 'Jour 26', 'Jour 27', 'Jour 28', 'Jour 29', 'Jour 30', 'Jour 31'
  ];
  weeks = [
    'Semaine 1', 'Semaine 2', 'Semaine 3', 'Semaine 4', 'Semaine 5', 'Semaine 6', 'Semaine 7', 'Semaine 8', 'Semaine 9',
    'Semaine 10', 'Semaine 11', 'Semaine 12', 'Semaine 13', 'Semaine 14', 'Semaine 15', 'Semaine 16', 'Semaine 17',
    'Semaine 18', 'Semaine 19', 'Semaine 20', 'Semaine 21', 'Semaine 22', 'Semaine 23', 'Semaine 24', 'Semaine 25',
    'Semaine 26', 'Semaine 27', 'Semaine 28', 'Semaine 29', 'Semaine 30', 'Semaine 31', 'Semaine 32', 'Semaine 33',
    'Semaine 34', 'Semaine 35', 'Semaine 36', 'Semaine 37', 'Semaine 38', 'Semaine 39', 'Semaine 40', 'Semaine 41',
    'Semaine 42', 'Semaine 43', 'Semaine 44', 'Semaine 45', 'Semaine 46', 'Semaine 47', 'Semaine 48', 'Semaine 49',
    'Semaine 50', 'Semaine 51', 'Semaine 52'
  ];
  listItems = [];
  selectedDescription = null;
  monthItems = [];
  selectedMonth = null;
  sanitaryFrame = null;

  enterprises: any = [];
  user: string;

  errors = {};

  isNew = true;
  loader = false;
  loaderContent = false;

  modalSanitaryTemperatureFrame = null;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private establishmentsService: EstablishmentsRequestService,
    private allSanitaryTemperatures: AllSanitaryTemperatureFramesComponent,
    private sanitaryTemperaturesServices: SanitaryTemperaturesRequestService,
    private sanitaryTemperatureFramesServices: SanitaryTemperatureFramesRequestService,
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loaderContent = this.requestCount > 0;
    });

    this.sanitaryTemperatureFrameForm = this.formBuilder.group({
      date: this.date,
      title: this.title,
      frame: this.frame,
      descriptions: this.formBuilder.array([]),
      detected_anomalies: this.detected_anomalies,
      interventions: this.interventions,
    });

    if (this.activatedRoute.snapshot.params.sanitary_temperature_frame_id) {
      this.isNew = false;
      this.getSanitaryTemperatureFrame();
    } else {
      this.getSanitaryTemperature();
      setTimeout(() => {
        Flatpickr();
      }, 1);
    }
  }

  ngAfterViewInit() {
    Dropzones();
    this.modalSanitaryTemperatureFrame.open();
  }

  ngOnDestroy() {
    const flatPicker = document.getElementsByClassName('flatpickr-calendar') as HTMLCollection;
    for (let i = flatPicker.length; i >= 0; i--) {
      if (flatPicker[i]) {
        flatPicker[i].remove();
      }
    }

    const dropZones = document.getElementsByClassName('dz-hidden-input') as HTMLCollection;
    for (let i = 0; i < dropZones.length; i++) {
      dropZones[i].remove();
    }

    this.clearTooltip();

    this.modalSanitaryTemperatureFrame.close();
  }
  clearTooltip() {
    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }
  changeTabs(activeTab) {
    this.activeTab = activeTab;
  }

  getSanitaryTemperature() {
    this.requestInProgress.next(true);
    this.sanitaryTemperaturesServices.getSanitaryTemperature(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.activatedRoute.snapshot.parent.params.sanitary_temperature_id
    ).subscribe(response => {
      if (response['success']) {
        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];
        this.sanitaryTemperature = response['sanitaryTemperature'];
        this.sanitaryFrame = this.sanitaryTemperature.frame;
        const control = <FormArray>this.sanitaryTemperatureFrameForm.controls['descriptions'];
        this.sanitaryTemperature['descriptions'].forEach((description, indexDescription) => {
          this.listItems.push({title: description.title});
          const arrayHeader = this.formBuilder.array([]);
          const arrayBody = this.formBuilder.array([]);
          const arrayDay = this.formBuilder.array([]);
          this.frame.setValue(this.sanitaryTemperature.frame);
          if (this.sanitaryFrame === 'Quotidienne') {
            this.days.forEach(day => {
              arrayDay.push(this.formBuilder.group({
                title: day,
                comment: this.formBuilder.control(null),
              }));
            });
            this.months.forEach(month => {
              if (indexDescription === 0) {
                this.monthItems.push({title: month});
              }
              arrayBody.push(this.formBuilder.group({
                title: month,
                comments: _.cloneDeep(arrayDay),
              }));
            });
          } else if (this.sanitaryFrame === 'Hebdomadaire') {
            this.weeks.forEach(week => {
              arrayBody.push(this.formBuilder.group({
                title: week,
                comment: this.formBuilder.control(null),
              }));
            });
          } else if (this.sanitaryFrame === 'Mensuelle') {
            this.months.forEach(month => {
              arrayBody.push(this.formBuilder.group({
                title: month,
                comment: this.formBuilder.control(null),
              }));
            });
          }
          description.pointLists.forEach(pointList => {
            arrayHeader.push(this.formBuilder.group({
              lineId: pointList.lineId,
              title: pointList.value,
              comments: _.cloneDeep(arrayBody),
            }));
          });
          control.push(this.formBuilder.group({
            lineId: description.lineId,
            title: description.title,
            values: _.cloneDeep(arrayHeader),
          }));
        });
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      setTimeout(() => {
        Flatpickr();
      }, 1);
      this.requestInProgress.next(false);
    });
  }

  getSanitaryTemperatureFrame() {
    this.requestInProgress.next(true);
    this.sanitaryTemperatureFramesServices.getSanitaryTemperatureFrame(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.activatedRoute.snapshot.parent.params.sanitary_temperature_id,
      this.activatedRoute.snapshot.params.sanitary_temperature_frame_id
    ).subscribe(response => {
      if (response['success']) {
        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];

        this.sanitaryFrame = response['sanitaryTemperatureFrame']['frame'];
        this.months.forEach(month => {
          this.monthItems.push({title: month});
        });
        response['sanitaryTemperatureFrame']['descriptions'].forEach((description, indexDescription) => {
          this.listItems.push({title: description.title});
          this.addDescriptions(description, indexDescription);
        });
        this.sanitaryTemperatureFrameForm.patchValue(response['sanitaryTemperatureFrame']);

        if (!this.canUpdate) {
          this.sanitaryTemperatureFrameForm.disable();
        }
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      setTimeout(() => {
        Flatpickr();
      }, 1);
      this.requestInProgress.next(false);
    });
  }

  addDescriptions(description, indexDescription) {
    const control = <FormArray>this.sanitaryTemperatureFrameForm.controls['descriptions'];
    control.push(this.formBuilder.group({
      lineId:  this.formBuilder.control(''),
      title: this.formBuilder.control('', Validators.required),
      values: this.formBuilder.array([]),
    }));
    description.values.forEach((value, indexValue) => {
      this.addValue(indexDescription, value, indexValue);
    });
  }
  addValue(indexDescription, value, indexValue) {
    const control = <FormArray>this.sanitaryTemperatureFrameForm.controls['descriptions']['controls'][indexDescription].controls['values'];
    control.push(this.formBuilder.group({
      lineId:  this.formBuilder.control(''),
      title: this.formBuilder.control(''),
      comments: this.formBuilder.array([]),
    }));
    value.comments.forEach((comment, indexComment) => {
      this.addComment(indexDescription, indexValue, comment, indexComment);
    });
    if (!value.comments.length) {
      const updateValue = <FormArray>this.sanitaryTemperatureFrameForm.controls['descriptions']['controls'][indexDescription]
        .controls['values']['controls'][indexValue].controls['comments'];
      if (this.sanitaryFrame === 'Quotidienne') {
        const arrayBody = this.formBuilder.array([]);
        this.days.forEach(day => {
          arrayBody.push(this.formBuilder.group({
            title: day,
            comment: this.formBuilder.control(null),
          }));
        });
        this.months.forEach(month => {
          if (indexDescription === 0) {
            this.monthItems.push({title: month});
          }
          updateValue.push(this.formBuilder.group({
            title: month,
            comments: _.cloneDeep(arrayBody),
          }));
        });
      } else if (this.sanitaryFrame === 'Hebdomadaire') {
        this.weeks.forEach(week => {
          updateValue.push(this.formBuilder.group({
            title: week,
            comment: this.formBuilder.control(null),
          }));
        });
      } else if (this.sanitaryFrame === 'Mensuelle') {
        this.months.forEach(month => {
          updateValue.push(this.formBuilder.group({
            title: month,
            comment: this.formBuilder.control(null),
          }));
        });
      }
    }
  }
  addComment(indexDescription, indexValue, comment, indexComment) {
    if (this.sanitaryFrame === 'Quotidienne') {
      const control = <FormArray>this.sanitaryTemperatureFrameForm.controls['descriptions']['controls'][indexDescription]
        .controls['values']['controls'][indexValue].controls['comments'];
      control.push(this.formBuilder.group({
        title: this.formBuilder.control(null),
        comments: this.formBuilder.array([]),
      }));
      comment.comments.forEach(() => {
        this.addMonthComment(indexDescription, indexValue, comment, indexComment);
      });
    } else {
      const control = <FormArray>this.sanitaryTemperatureFrameForm.controls['descriptions']['controls'][indexDescription]
        .controls['values']['controls'][indexValue].controls['comments'];
      control.push(this.formBuilder.group({
        title: this.formBuilder.control(null),
        comment: this.formBuilder.control(null)
      }));
    }
  }
  addMonthComment(indexDescription, indexValue, comment, indexComment) {
      const control = <FormArray>this.sanitaryTemperatureFrameForm.controls['descriptions']['controls'][indexDescription]
        .controls['values']['controls'][indexValue].controls['comments']['controls'][indexComment].controls['comments'];
      control.push(this.formBuilder.group({
        title: this.formBuilder.control(null),
        comment: this.formBuilder.control(null),
      }));
  }

  createSanitaryTemperatureFrame() {
    let request;
    this.errors = {};
    this.loader = true;

    const data = {
      ...this.sanitaryTemperatureFrameForm.value,
    };

    request = this.sanitaryTemperatureFramesServices.createSanitaryTemperatureFrame(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.activatedRoute.snapshot.parent.params.sanitary_temperature_id,
      data
    );

    request.subscribe(response => {
      if (response['success']) {
        this.loader = false;
        this.modalSanitaryTemperatureFrame.close();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response['errors']) {
        this.loader = false;
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(response['errors'], this.sanitaryTemperatureFrameForm, this.notifier);
      } else {
        this.loader = false;
      }
    });
  }

  updateSanitaryTemperatureFrame() {
    let request;

    this.errors = {};
    this.loader = true;

    const data = {
      ...this.sanitaryTemperatureFrameForm.value,
    };

    request = this.sanitaryTemperatureFramesServices.updateSanitaryTemperatureFrame(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.activatedRoute.snapshot.parent.params.sanitary_temperature_id,
      this.activatedRoute.snapshot.params.sanitary_temperature_frame_id,
      data
    );

    request.subscribe(response => {
      if (response['success']) {
        this.loader = false;
        this.modalSanitaryTemperatureFrame.close();
        CustomNotifierService.getSuccessUpdate(this.notifier);
      } else if (response['errors']) {
        this.loader = false;
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      } else {
        this.loader = false;
      }
    });
  }

  bindModal(modal) {
    this.modalSanitaryTemperatureFrame = modal;
  }
  closeModal() {
    this.router.navigate([this.activatedRoute.snapshot.parent.url.join('/')]).then(() => {
      this.allSanitaryTemperatures.refreshData();
    });
  }

  changeDescription() {
    this.selectedMonth = null;
  }
}
