import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReportWorksheetsRequestService } from '../../services/report.worksheets.request.service';

import { AllReportWorksheetsModule } from './all-report-worksheets/all-report-worksheets.module';
import { UpdateReportWorksheetModule } from './update-report-worksheet/update-report-worksheet.module';

import { AllWorksModule } from './all-works/all-works.module';
import { UpdateWorkModule } from './update-work/update-work.module';

@NgModule({
  imports: [
    CommonModule,
    AllReportWorksheetsModule,
    UpdateReportWorksheetModule,
    AllWorksModule,
    UpdateWorkModule
  ],
  providers: [
    ReportWorksheetsRequestService
  ]
})
export class ReportWorksheetsModule {}
