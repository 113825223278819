import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { AuthModule } from './auth/auth.module';
import { AccessInternalModule } from './access-internal/access-internal.module';
import { AccessProviderModule } from './access-provider/access-provider.module';
import { AccountModule } from './account/account.module';
import { AgenciesModule } from './agencies/agencies.module';
import { AsbestosModule } from './asbestos/asbestos.module';
import { CheckpointsModule } from './checkpoints/checkpoints.module';
import { CommissionsModule } from './commissions/commissions.module';
import { CotationsModule } from './cotations/cotations.module';
import { CustomersModule } from './customers/customers.module';
import { DocumentsModule } from './documents/documents.module';
import { EmployeesModule } from './employees/employees.module';
import { EnterprisesModule } from './enterprises/enterprises.module';
import { EstablishmentsModule } from './establishments/establishments.module';
import { ExpertisesModule } from './expertises/expertises.module';
import { ExportDownloadModule } from './export-download/export-download.module';
import { FirePermitsModule } from './fire-permits/fire-permits.module';
import { InstallationsModule } from './installations/installations.module';
import { InterventionsModule } from './interventions/interventions.module';
import { LogsModule } from './logs/logs.module';
import { NotFoundModule } from './not-found/not-found.module';
import { ObservationsModule } from './observations/observations.module';
import { OffersModule } from './offers/offers.module';
import { PrescriptionsModule } from './prescriptions/prescriptions.module';
import { PlanningModule } from './planning/planning.module';
import { RegistriesModule } from './registries/registries.module';
import { ReportsModule } from './reports/reports.module';
import { ReportWorksheetsModule } from './report-worksheets/report-worksheets.module';
import { RisksModule } from './risks/risks.module';
import { RolesModule } from './roles/roles.module';
import { TrainingsModule } from './trainings/trainings.module';
import { UsersModule } from './users/users.module';
import { WorksheetsModule } from './worksheets/worksheets.module';
import { OfficialsModule } from './officials/officials.module';
import { OfficialPrescriptionsModule } from './official-prescriptions/official-prescriptions.module';
import { IssuesModule } from './issues/issues.module';
import { PreventionsModule } from './preventions/preventions.module';
import { PreventionRisksModule } from './prevention-risks/prevention-risks.module';
import { SecurityProtocolsModule } from './security-protocols/security-protocols.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { ExportUniqueDocModule } from './export-unique-doc/export-unique-doc.module';
import { SanitaryContributorsModule } from './sanitary-contributors/sanitary-contributors.module';
import { SanitaryInstallationsModule } from './sanitary-installations/sanitary-installations.module';
import { SanitaryWorksheetsModule } from './sanitary-worksheets/sanitary-worksheets.module';
import { SanitaryOperationsModule } from './sanitary-operations/sanitary-operations.module';
import { SanitaryPoolsModule } from './sanitary-pools/sanitary-pools.module';
import { SanitarySurveillancesModule } from './sanitary-surveillances/sanitary-surveillances.module';
import { SanitaryFramesModule } from './sanitary-frames/sanitary-frames.module';
import { SanitaryCheckpointsModule } from './sanitary-checkpoints/sanitary-checkpoints.module';
import { NotificationsModule } from './notifications/notifications.module';
import { SingleSignOnModule } from './single-sign-on/single-sign-on.module';
import { AdminExportsModule } from './admin-exports/admin-exports.module';
import { ContentsModule } from './contents/contents.module';
import { FaqModule } from './faq/faq.module';
import { RvratModule } from './rvrat/rvrat.module';
import { RvratPrescriptionsModule } from './rvrat-prescriptions/rvrat-prescriptions.module';
import { AllTrainingsModule } from './all-training/all-training.module';
import { EmployeesTrainingModule } from './employees-training/employees-training.module';

@NgModule({
  imports: [
    CommonModule,
    AuthModule,
    AccessInternalModule,
    AccessProviderModule,
    AccountModule,
    AgenciesModule,
    AsbestosModule,
    AllTrainingsModule,
    EmployeesTrainingModule,
    CheckpointsModule,
    CommissionsModule,
    CotationsModule,
    CustomersModule,
    DocumentsModule,
    DocumentsModule,
    EmployeesModule,
    EnterprisesModule,
    EstablishmentsModule,
    ExportDownloadModule,
    ExpertisesModule,
    FirePermitsModule,
    InstallationsModule,
    InterventionsModule,
    IssuesModule,
    LogsModule,
    NotFoundModule,
    ObservationsModule,
    OfficialsModule,
    OfficialPrescriptionsModule,
    OffersModule,
    PlanningModule,
    PrescriptionsModule,
    RegistriesModule,
    ReportsModule,
    ReportWorksheetsModule,
    RisksModule,
    RolesModule,
    TrainingsModule,
    UsersModule,
    WorksheetsModule,
    PreventionsModule,
    PreventionRisksModule,
    SecurityProtocolsModule,
    ExportUniqueDocModule,
    DashboardModule,
    SanitaryContributorsModule,
    SanitaryInstallationsModule,
    SanitaryWorksheetsModule,
    SanitaryOperationsModule,
    SanitaryPoolsModule,
    SanitarySurveillancesModule,
    SanitaryFramesModule,
    SanitaryCheckpointsModule,
    NotificationsModule,
    SingleSignOnModule,
    AdminExportsModule,
    ContentsModule,
    FaqModule,
    RvratModule,
    RvratPrescriptionsModule,
  ]
})
export class PagesModule {}
