<div class="main-content">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col">
        <div class="header mb-0">
          <div class="header-body no-border">
            <div class="row align-items-center">
              <div class="col">
                <h1 class="header-title">Points de contrôle</h1>

                <small class="form-text text-muted">Edition de masse des affichages des points de contrôle</small>

                <app-breadcrumbs [currentPage]="pageName" [names]="previousPageName" [routes]="previousPageRoute"></app-breadcrumbs>
              </div>

              <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0">
                <a class="btn btn-primary d-block d-md-inline-block" [routerLink]="" (click)="updateShow()">
                  Valider
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="is-loading loader-content" *ngIf="loader"></div>

        <div class="tab-content" [hidden]="loader || !installations.length">
          <div class="tab-pane fade show active" id="tabPaneOne" role="tabpanel">
            <div class="row listAlias">

              <!-- NEWS -->
              <div class="col-12">
                <div class="card bg-light border">
                  <div class="card-body">
                    Cette fonctionnalité vous permet de décocher les installations que vous souhaitez retirer du tableau de bord car non présentes dans votre établissement
                  </div>
                </div>
              </div>

              <!-- CARD INSTALLATION -->
              <div class="col-12 d-flex flex-column" *ngFor="let installation of installations; let lastInstallation = last; let keyInstallation = index">
                <div class="card flex-fill card-installation" [ngClass]="{ 'fix-last': lastInstallation }">
                  <div class="card-body">
                    <div class="row align-items-center">
                      <div class="col overflow-hidden align-self-start d-flex">
                        <h4 class="card-title name text-truncate mt-3" [ngClass]="{ 'mb-4': !installation.collapsed }">
                          <a class="href" (click)="toggleInstallation(keyInstallation)">
                            <i class="fe fe-chevron-right" *ngIf="installation.collapsed"></i>
                            <i class="fe fe-chevron-down" *ngIf="!installation.collapsed"></i>
                            {{ (installation.installation ? installation.installation.name : '') }}
                            {{ installation.installation && installation.description ? ' - ' : '' }}
                            {{ installation.description }}
                            - {{ calcCheckedInstallation(keyInstallation) + ' / ' + calcAllInstallation(keyInstallation) }}
                          </a>
                        </h4>
                      </div>

                      <div class="col-auto align-self-start d-flex" [ngClass]="{ 'mb-4': !installation.collapsed }">

                        <a class="href fix-checkbox" (click)="checkInstallation(keyInstallation)">
                          <i class="fe fe-check-square fe-black" *ngIf="calcCheckedInstallation(keyInstallation) === calcAllInstallation(keyInstallation)"></i>
                          <i class="fe fe-square fe-black" *ngIf="calcCheckedInstallation(keyInstallation)  !== calcAllInstallation(keyInstallation)"></i>
                        </a>
                      </div>

                      <!-- CARD CHECKPOINT -->
                      <div class="col-12 d-flex flex-column" *ngIf="!installation.collapsed">
                        <div *ngFor="let checkpoint of installation.establishment_checkpoints; let lastCheckpoint = last; let keyCheckpoint = index">
                          <div class="card flex-fill" [ngClass]="{ 'fix-last': lastCheckpoint, 'card-checkpoint': checkpoint.show_checkpoint }">
                            <div class="card-body">
                              <div class="row align-items-center">
                                <div class="col overflow-hidden">
                                  <h4 class="card-title mb-2 name text-truncate">
                                    <a class="fix-font">
                                      {{ checkpoint.full_name }}
                                    </a>
                                  </h4>
                                </div>

                                <div class="col-auto align-self-start d-flex">

                                  <a class="href fix-checkbox" (click)="checkCheckpoint(keyInstallation, keyCheckpoint)">
                                    <i class="fe fe-check-square fe-black" *ngIf="checkpoint.show_checkpoint"></i>
                                    <i class="fe fe-square fe-black" *ngIf="!checkpoint.show_checkpoint"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="loader-content" *ngIf="!loader && !installations.length">
          <p>Aucun résultat</p>
        </div>
      </div>
    </div>
  </div>
</div>

<router-outlet></router-outlet>
