import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from "@angular/core";

import { AuthService } from "../auth/auth.service";

import { environment } from "../../../environments/environment";
import * as moment from "moment";
import { DomSanitizer } from "@angular/platform-browser";

declare var $: any;

declare function Navbar(): any;

interface Menu {
  name: string;
  icon: string;
  url?: string;
  submenu_ref?: string;
  submenu?: Submenu[];
  isMonoOffer?: boolean;
  isDiscoverOffer?: boolean;
}

interface Submenu {
  name: string;
  icon: string;
  url: string;
  ids?: any;
  isMonoOffer?: boolean;
  isDiscoverOffer?: boolean;
}

@Component({
  selector: "app-sidenav",
  templateUrl: "./sidenav.component.html",
  styleUrls: ["./sidenav.component.scss"],
})
export class SidenavComponent implements OnInit, AfterViewInit {
  environment = environment;

  menus: Menu[] = [
    {
      name: "Tableau de bord",
      icon: "fe-bar-chart-2",
      url: "/",
      submenu_ref: "dashboards",
      submenu: [
        {
          name: "Observations",
          icon: "fe-bar-chart-2",
          url: "/dashboard-observations",
          ids: [1, 3, 4, 5, 6],
        },
        {
          name: "Commissions / Organismes",
          icon: "fe-bar-chart-2",
          url: "/dashboard-commissions",
          ids: [1, 6],
          isMonoOffer: true,
          isDiscoverOffer: true,
        },
        {
          name: "Prestataires / Intervenants",
          icon: "fe-bar-chart-2",
          url: "/dashboard-providers",
        },
        {
          name: "Dossier technique Amiante",
          icon: "fe-bar-chart-2",
          url: "/dashboard-asbestos",
          ids: [7],
          isMonoOffer: true,
          isDiscoverOffer: true,
        },
        {
          name: "Document Unique",
          icon: "fe-bar-chart-2",
          url: "/dashboard-unique-document",
          ids: [8],
          isMonoOffer: true,
          isDiscoverOffer: true,
        },
        {
          name: "Formations",
          icon: "fe-bar-chart-2",
          url: "/dashboard-formations",
          ids: [9],
          isMonoOffer: true,
          isDiscoverOffer: true,
        },
      ],
    },
    {
      name: "Établissements",
      icon: "fe-briefcase",
      url: "/establishments/mine/active",
    },
    {
      name: "Registres",
      icon: "fe-book",
      url: "/registries/mine",
    },
    {
      name: "Employées",
      icon: "fe-user",
      url: "/employees",
    },
    {
      name: "Formations",
      icon: "fe-list",
      url: "/formations",
    },
    {
      name: "Planning",
      icon: "fe-calendar",
      url: "/planning",
    },
    {
      name: "Utilisateurs",
      icon: "fe-users",
      url: "/users/mine",
      isMonoOffer: true,
      isDiscoverOffer: true,
    },
    {
      name: "Prestataires",
      icon: "fe-cast",
      url: "/providers",
    },
    {
      name: "Bons d'intervention",
      icon: "fe-file",
      url: "/interventions",
    },
    {
      name: "Historique des actions",
      icon: "fe-archive",
      url: "/logs",
    },
    {
      name: "Centre de notification",
      icon: "fe-bell",
      url: "/",
      submenu_ref: "notifications",
      submenu: [
        {
          name: "Alertes périodiques",
          icon: "fe-bell",
          url: "/notification-center/mine/periodic-alerts",
        },
        {
          name: "Rapports",
          icon: "fe-bell",
          url: "/notification-center/mine/reports",
        },
        {
          name: "Non intervention sur les rapports",
          icon: "fe-bell",
          url: "/notification-center/mine/report-interventions",
        },
        {
          name: "Levées",
          icon: "fe-bell",
          url: "/notification-center/mine/observations",
        },
        {
          name: "Bon d'interventions",
          icon: "fe-bell",
          url: "/notification-center/mine/interventions",
        },
        {
          name: "Message personnalisé",
          icon: "fe-bell",
          url: "/notification-center/mine/messages",
        },
        {
          name: "Rappel pour le recyclage des formations",
          icon: "fe-bell",
          url: "/notification-center/mine/notification_formations",
        },
      ],
    },
    {
      name: "Base documentaire",
      icon: "fe-file-text",
      url: "/documents/public",
    },
    {
      name: "Mes tickets",
      icon: "fe-unlock",
      url: "/issues/mines",
    },
    {
      name: "FAQ",
      icon: "fe-book",
      url: "/faq/public",
    },
  ];

  menuAdmin: Menu[] = [
    {
      name: "Tableau de bord",
      icon: "fe-bar-chart-2",
      url: "/administration",
    },
    {
      name: "Registres",
      icon: "fe-book",
      url: "/registries",
    },
    {
      name: "Entreprises",
      icon: "fe-briefcase",
      url: "/enterprises",
    },
    {
      name: "Installations",
      icon: "fe-link-2",
      url: "/installations",
    },
    {
      name: "Échelles de cotation",
      icon: "fe-trending-up",
      url: "/cotations",
    },
    {
      name: "Risques - Budget",
      icon: "fe-pocket",
      url: "/risk-costs",
    },
    {
      name: "Risques - Dommages",
      icon: "fe-cloud-lightning",
      url: "/risk-damages",
    },
    {
      name: "Risques - Familles",
      icon: "fe-alert-triangle",
      url: "/risk-families",
    },
    {
      name: "Permis feu - Actions",
      icon: "fe-file",
      url: "/fire-permit-actions",
    },
    {
      name: "Plan de prévention - Risques",
      icon: "fe-file",
      url: "/prevention-risks",
    },
    {
      name: "Carnet sanitaire - Points de contrôle",
      icon: "fe-droplet",
      url: "/sanitary-checkpoints",
    },
    {
      name: "Formations",
      icon: "fe-calendar",
      url: "/trainings",
    },
    {
      name: "Offres",
      icon: "fe-credit-card",
      url: "/offers",
    },
    {
      name: "Utilisateurs",
      icon: "fe-users",
      url: "/users",
    },
    {
      name: "Exports",
      icon: "fe-download",
      url: "/exports",
    },
    {
      name: "Gestion des rôles",
      icon: "fe-unlock",
      url: "/roles",
    },
    {
      name: "Centre de notification",
      icon: "fe-bell",
      url: "/notification-center",
    },
    {
      name: "Infobulle",
      icon: "fe-info",
      url: "/contents",
    },
    {
      name: "Gestion Documentaire",
      icon: "fe-file-text",
      url: "/documents",
    },
    {
      name: "Gestion des tickets",
      icon: "fe-unlock",
      url: "/issues",
    },
    {
      name: "Authentifications Unique",
      icon: "fe-log-in",
      url: "/single-sign-on",
    },
    {
      name: "FAQ",
      icon: "fe-book",
      url: "/faq",
    },
  ];

  showMenu = true;
  isAdmin = false;
  isAdminChoosen = false;
  isProvider = false;
  isOnlyProvider = true;
  isLeviatanUser = false;
  monoOffer = false;
  discoverOffer = false;
  discoverDate = null;

  user: any;
  registries: any;
  enterprise: any;
  enterprises: any = [];

  connectedAs = false;

  @Output() isAdminSelected = new EventEmitter<boolean>();

  constructor(
    public sanitizer: DomSanitizer,
    public authService: AuthService
  ) {}

  ngOnInit() {
    this.connectedAs = JSON.parse(localStorage.getItem("connectedAs"));
    if (this.connectedAs) {
      this.enterprises = JSON.parse(
        localStorage.getItem("connectedAsEnterprises")
      );
      this.enterprise = JSON.parse(
        localStorage.getItem("connectedAsEnterprise")
      );
      this.isProvider = JSON.parse(
        localStorage.getItem("connectedAsIsProvider")
      );
      this.isOnlyProvider = JSON.parse(
        localStorage.getItem("connectedAsIsOnlyProvider")
      );
      this.isAdmin = JSON.parse(localStorage.getItem("connectedAsIsAdmin"));
      this.user = JSON.parse(localStorage.getItem("connectedAsUser"));
      this.registries = JSON.parse(
        localStorage.getItem("connectedAsRegistries")
      );
      this.monoOffer = JSON.parse(
        localStorage.getItem("connectedAsIsMonoOffer")
      );
      this.discoverOffer = JSON.parse(
        localStorage.getItem("connectedAsIsDiscoverOffer")
      );
      this.discoverDate = JSON.parse(
        localStorage.getItem("connectedAsDiscoverDate")
      );
    } else {
      this.enterprises = JSON.parse(localStorage.getItem("enterprises"));
      this.enterprise = JSON.parse(localStorage.getItem("enterprise"));
      this.isProvider = JSON.parse(localStorage.getItem("isProvider"));
      this.isOnlyProvider = JSON.parse(localStorage.getItem("isOnlyProvider"));
      this.isAdmin = JSON.parse(localStorage.getItem("isAdmin"));
      this.user = JSON.parse(localStorage.getItem("user"));
      this.registries = JSON.parse(localStorage.getItem("registries"));
      this.monoOffer = JSON.parse(localStorage.getItem("isMonoOffer"));
      this.discoverOffer = JSON.parse(localStorage.getItem("isDiscoverOffer"));
      this.discoverDate = JSON.parse(localStorage.getItem("discoverDate"));
    }

    if (this.user.email.includes("leviatan")) {
      this.isLeviatanUser = true;
    }

    this.isAdminChoosen = JSON.parse(localStorage.getItem("isAdminChoosen"));

    const observableEnterprises = this.authService.getEnterprises();
    observableEnterprises.subscribe((value) => {
      this.enterprises = value;
    });

    const observableEnterprise = this.authService.getEnterprise();
    observableEnterprise.subscribe((value) => {
      this.enterprise = value;
      this.showMenu = true;
    });

    const observableProvider = this.authService.getProvider();
    observableProvider.subscribe((value) => {
      this.isProvider = value;
    });

    const observableOnlyProvider = this.authService.getOnlyProvider();
    observableOnlyProvider.subscribe((value) => {
      this.isOnlyProvider = value;
    });

    const observableAdmin = this.authService.getAdmin();
    observableAdmin.subscribe((value) => {
      this.isAdmin = value;
      if (!this.isAdmin) {
        this.isAdminChoosen = false;
      }
    });

    const observableUser = this.authService.getUser();
    observableUser.subscribe((value) => {
      this.user = value;
      this.connectedAs = JSON.parse(localStorage.getItem("connectedAs"));
    });

    const observableRegistries = this.authService.getRegistries();
    observableRegistries.subscribe((value) => {
      this.registries = value;
    });

    const observableMonoOffer = this.authService.getMonoOffer();
    observableMonoOffer.subscribe((value) => {
      this.monoOffer = value;
    });

    const observableDiscoverOffer = this.authService.getDiscoverOffer();
    observableDiscoverOffer.subscribe((value) => {
      this.discoverOffer = value;
    });

    const observableDiscoverDate = this.authService.getDiscoverDate();
    observableDiscoverDate.subscribe((value) => {
      this.discoverDate = value;
    });

    this.authService.me();
  }

  ngAfterViewInit() {
    Navbar();
  }

  switchAdministration() {
    this.isAdminChoosen = !this.isAdminChoosen;
    localStorage.setItem("isAdminChoosen", JSON.stringify(this.isAdminChoosen));
  }

  chooseEnterprise() {
    this.showMenu = !this.showMenu;

    const tooltips = document.getElementsByClassName(
      "tooltip"
    ) as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  changeEnterprise(enterprise_id) {
    const found = this.enterprises.find((element) => {
      return element.id === enterprise_id;
    });
    this.authService.changeEnterprise(found);
  }

  hideCollapse() {
    $(".collapse").collapse("hide");
  }

  setActiveNav(e) {
    const navLink = document.getElementsByClassName("nav-link");
    for (let i = 0; i < navLink.length; i++) {
      navLink[i].className = navLink[i].className.replace(" active", "");
    }

    e.srcElement.classList.add("active");
  }

  setActiveMenu(submenu: Submenu) {
    let bool = false;
    if (submenu.ids) {
      this.registries.forEach((element) => {
        if (submenu.ids.indexOf(element.id) !== -1) {
          bool = true;
        }
      });
      return bool;
    } else {
      return true;
    }
  }

  getUrl() {
    let url = null;
    for (let i = 0; i < this.menus[0].submenu.length; i++) {
      if (url === null) {
        if (this.menus[0].submenu[i].url === "/dashboard-providers") {
          url = this.menus[0].submenu[i].url;
          break;
        } else if (this.menus[0].submenu[i].ids) {
          if (this.registries.length) {
            for (let y = 0; y < this.registries.length; y++) {
              if (
                this.menus[0].submenu[i].ids.indexOf(this.registries[y].id) !==
                -1
              ) {
                url = this.menus[0].submenu[i].url;
                break;
              }
            }
          }
        }
      }
    }
    return url;
  }

  getDelayOffer() {
    const today = moment().format("YYYY-MM-DD");
    const end = moment(this.discoverDate);
    const day = end.diff(today, "days");
    if (day > 0) {
      return this.sanitizer.bypassSecurityTrustHtml(
        "Fin de l'offre découverte :<br> " + (day + 1) + " jour(s) restant(s)"
      );
    } else {
      return this.sanitizer.bypassSecurityTrustHtml(
        "Fin de l'offre découverte :<br> Aujourd'hui"
      );
    }
  }
}
