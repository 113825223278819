import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { NotifierService } from 'angular-notifier';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { environment } from '../../../../environments/environment';

import { AuthService } from '../../../core/auth/auth.service';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { RegistriesRequestService } from '../../../services/registries.request.service';
import { EstablishmentsRequestService } from '../../../services/establishments.request.service';

import { DocumentsRequestService } from '../../../services/documents.request.service';
import { PrescriptionsRequestService } from '../../../services/prescriptions.request.service';
import { ObservationsRequestService } from '../../../services/observations.request.service';
import { CheckpointsRequestService } from '../../../services/checkpoints.request.service';
import { AsbestosRequestService } from '../../../services/asbestos.request.service';
import { TrainingsRequestService } from '../../../services/trainings.request.service';
import { ReportWorksheetsRequestService } from '../../../services/report.worksheets.request.service';
import { OfficialPrescriptionsRequestService } from '../../../services/official.prescriptions.request.service';
import { FirePermitsRequestService } from '../../../services/fire.permits.request.service';
import { PreventionsRequestService } from '../../../services/preventions.request.service';

import Establishment from '../../../../shared/interfaces/establishment';
import Folder from '../../../../shared/interfaces/folder';
import Observation from '../../../../shared/interfaces/observation';
import Prescription from '../../../../shared/interfaces/prescription';
import RvratPrescription from '../../../../shared/interfaces/rvrat-prescription';
import Registry from '../../../../shared/interfaces/registry';
import AsbestosDetectionLocalization from '../../../../shared/interfaces/asbestos_detection_localization';
import Training from '../../../../shared/interfaces/training';
import Checkpoint from '../../../../shared/interfaces/checkpoint';
import Intervention from '../../../../shared/interfaces/intervention';
import OfficialPrescription from '../../../../shared/interfaces/official_prescription';
import FirePermit from '../../../../shared/interfaces/fire_permit';
import Prevention from '../../../../shared/interfaces/prevention';

import * as _ from 'lodash';
import * as moment from 'moment';
import { RvratPrescriptionsRequestService } from '../../../services/rvrat-prescriptions.request.service';

declare function WorksheetCharts(): any;

@Component({
  selector: 'app-dashboard-registry',
  templateUrl: './dashboard-registry.component.html',
  styleUrls: ['./dashboard-registry.component.scss']
})
export class DashboardRegistryComponent implements OnInit, OnDestroy {
  changeEnterprise: Subscription;
  environment = environment;
  timeout: any;
  loaderContent = false;
  activeTab = '';

  requestInProgress = new Subject();
  requestCount = 0;
  requestObservationsInProgress = new Subject();
  requestObservationsCount = 0;
  requestPrescriptionsInProgress = new Subject();
  requestPrescriptionsCount = 0;
  requestRvratPrescriptionsInProgress = new Subject();
  requestRvratPrescriptionsCount = 0;
  requestCheckpointsInProgress = new Subject();
  requestCheckpointsCount = 0;
  requestTrainingsInProgress = new Subject();
  requestTrainingsCount = 0;
  requestAsbestosInProgress = new Subject();
  requestAsbestosCount = 0;
  requestWorksInProgress = new Subject();
  requestWorksCount = 0;
  requestReportsInProgress = new Subject();
  requestReportsCount = 0;
  requestOfficialPrescriptionsInProgress = new Subject();
  requestOfficialPrescriptionsCount = 0;
  requestFirePermitsInProgress = new Subject();
  requestFirePermitsCount = 0;
  requestPreventionsInProgress = new Subject();
  requestPreventionsCount = 0;
  requestPreventionsSimplifiedInProgress = new Subject();
  requestPreventionsSimplifiedCount = 0;

  registry: Registry;
  establishment: Establishment;

  registries: Registry[] = [];
  establishmentsList: Establishment[] = [];
  selectedEstablishment: any;
  selectedRegistry: any;

  filterByEstablishments: Establishment[];
  filterByCheckpoints: Checkpoint[];
  filterByEmergencies: any = [
    { id: '0', name: 'Bas' },
    { id: '1', name: 'Normal' },
    { id: '2', name: 'Haut' },
    { id: '3', name: 'Prioritaire' }
  ];

  filterByEstablishmentId = '';
  establishments: Subject<any> = new Subject();
  filterByCheckpointId = '';
  filterByEmergency = '';
  filterByYear = '';
  filterByMonth = '';

  folders: Folder[];

  pageName: string;
  previousPageName: string[] = [];
  previousPageRoute: string[] = [];
  currentRoute = '';

  limited = false;
  isRus = false;
  canLogEmployee = false;

  canUpdate = false;
  modifyDescription = false;
  edit = false;
  showDescription: SafeHtml = '';
  description = '';
  errors = {};
  loader = false;

  /**
   * Observations
   */
  observations: Observation[] = [];
  loaderObservations = true;
  canUpdateObservations = false;
  pageObservations = 1;
  pagesObservations = 1;
  countObservations = 0;
  limitObservations = 12;
  allObservations = true;
  timeoutObservations: any;
  searchObservation = '';
  sortByObservations = 'Observations.emergencies';
  sortDirectionObservations = 'desc';
  interventionsObservations = false;

  /**
   * Prescriptions
   */
  prescriptions: Prescription[] = [];
  loaderPrescriptions = true;
  canUpdatePrescriptions = false;
  pagePrescriptions = 1;
  pagesPrescriptions = 1;
  countPrescriptions = 0;
  limitPrescriptions = 12;
  allPrescriptions = true;
  timeoutPrescriptions: any;
  searchPrescription = '';
  sortByPrescriptions = 'Prescriptions.emergencies';
  sortDirectionPrescriptions = 'desc';
  interventionsPrescriptions = false;


  /**
   * Rvrat Prescriptions
   */
  rvratPrescriptions: RvratPrescription[] = [];
  loaderRvratPrescriptions = true;
  canUpdateRvratPrescriptions = false;
  pageRvratPrescriptions = 1;
  pagesRvratPrescriptions = 1;
  countRvratPrescriptions = 0;
  limitRvratPrescriptions = 12;
  allRvratPrescriptions = true;
  timeoutRvratPrescriptions: any;
  searchRvratPrescription = '';
  sortByRvratPrescriptions = 'RvratPrescriptions.emergencies';
  sortDirectionRvratPrescriptions = 'desc';
  interventionsRvratPrescriptions = false;

  /**
   * Checkpoints
   */
  checkpoints: Checkpoint[] = [];
  loaderCheckpoints = true;
  canCreateCheckpoints = false;
  canUpdateCheckpoints = false;
  pageCheckpoints = 1;
  pagesCheckpoints = 1;
  countCheckpoints = 0;
  limitCheckpoints = 12;
  nextCheckpoint = false;
  timeoutCheckpoints: any;
  searchCheckpoint = '';
  sortByCheckpoints = 'EstablishmentCheckpoints.next_report_date';
  sortDirectionCheckpoints = 'asc';

  /**
   * Trainings
   */
  trainings: Training[] = [];
  timeoutTrainings: any;
  loaderTrainings = true;
  allTrainings = false;
  canUpdateTrainings = false;
  pageTrainings = 1;
  pagesTrainings = 1;
  countTrainings = 0;
  limitTrainings = 12;
  searchTraining = '';
  sortByTrainings = 'Trainings.priority';
  sortDirectionTrainings = 'desc';

  /**
   * Asbestos
   */
  asbestosDetectionLocalizations: AsbestosDetectionLocalization[] = [];
  timeoutAsbestos: any;
  loaderAsbestos = true;
  allAsbestos = true;
  canUpdateAsbestos = false;
  pageAsbestos = 1;
  pagesAsbestos = 1;
  countAsbestos = 0;
  limitAsbestos = 12;
  searchAsbestosValue = '';
  sortByAsbestos = 'AsbestosDetectionLocalizations.localization';
  sortDirectionAsbestos = 'asc';

  /**
   * Works
   */
  dates: any[] = [];
  works: any[] = [];
  reports: any[] = [];
  timeoutWorks: any;
  loaderWorks = true;
  loaderReports = true;
  canUpdateWorks = false;
  pageWorks = 1;
  pagesWorks = 1;
  countWorks = 0;
  limitWorks = 12;
  yearWorks = '';
  searchWork = '';
  sortByWorks = 'Works.work';
  sortDirectionWorks = 'asc';

  /**
   * OfficialPrescriptions
   */
  officialPrescriptions: OfficialPrescription[] = [];
  officialLoaderPrescriptions = true;
  officialCanUpdatePrescriptions = false;
  officialPagePrescriptions = 1;
  officialPagesPrescriptions = 1;
  officialCountPrescriptions = 0;
  officialLimitPrescriptions = 12;
  officialAllPrescriptions = true;
  officialTimeoutPrescriptions: any;
  officialSearchPrescription = '';
  officialSortByPrescriptions = 'OfficialPrescriptions.emergencies';
  officialSortDirectionPrescriptions = 'desc';
  interventionsOfficialPrescriptions = false;

  /**
   * FirePermits
   */
  firePermits: FirePermit[] = [];
  loaderFirePermits = true;
  canUpdateFirePermits = false;
  pageFirePermits = 1;
  pagesFirePermits = 1;
  countFirePermits = 0;
  limitFirePermits = 12;
  nextFirePermits = false;
  timeoutFirePermits: any;
  searchFirePermit = '';
  sortByFirePermits = 'FirePermits.begin_date';
  sortDirectionFirePermits = 'desc';

  /**
   * Preventions
   */
  preventions: Prevention[] = [];
  loaderPreventions = true;
  canUpdatePreventions = false;
  pagePreventions = 1;
  pagesPreventions = 1;
  countPreventions = 0;
  limitPreventions = 12;
  nextPreventions = false;
  timeoutPreventions: any;
  searchPrevention = '';
  sortByPreventions = 'Preventions.operation';
  sortDirectionPreventions = 'desc';

  /**
   * Preventions simplified
   */
  preventionsSimplified: Prevention[] = [];
  loaderPreventionsSimplified = true;
  canUpdatePreventionsSimplified = false;
  pagePreventionsSimplified = 1;
  pagesPreventionsSimplified = 1;
  countPreventionsSimplified = 0;
  limitPreventionsSimplified = 12;
  nextPreventionsSimplified = false;
  timeoutPreventionsSimplified: any;
  searchPreventionSimplified = '';
  sortByPreventionsSimplified = 'Preventions.operation';
  sortDirectionPreventionsSimplified = 'desc';

  worksheetChart = WorksheetCharts();

  connectedAs = false;
  monoOffer = false;
  discoverOffer = false;

  constructor(
    private router: Router,
    public sanitizer: DomSanitizer,
    private authService: AuthService,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private changeDetector: ChangeDetectorRef,
    private asbestosService: AsbestosRequestService,
    private trainingsService: TrainingsRequestService,
    private documentsService: DocumentsRequestService,
    private registriesService: RegistriesRequestService,
    private checkpointsService: CheckpointsRequestService,
    private firePermitsService: FirePermitsRequestService,
    private preventionsService: PreventionsRequestService,
    private reportsService: ReportWorksheetsRequestService,
    private observationsService: ObservationsRequestService,
    private prescriptionsService: PrescriptionsRequestService,
    private establishmentsService: EstablishmentsRequestService,
    private rvratPrescriptionsService: RvratPrescriptionsRequestService,
    private officialPrescriptionsService: OfficialPrescriptionsRequestService,
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loaderContent = this.requestCount > 0;
    });
    this.requestObservationsInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestObservationsCount++;
      } else if (this.requestObservationsCount > 0) {
        this.requestObservationsCount--;
      }
      this.loaderObservations = this.requestObservationsCount > 0;
    });
    this.requestPrescriptionsInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestPrescriptionsCount++;
      } else if (this.requestPrescriptionsCount > 0) {
        this.requestPrescriptionsCount--;
      }
      this.loaderPrescriptions = this.requestPrescriptionsCount > 0;
    });
    this.requestRvratPrescriptionsInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestRvratPrescriptionsCount++;
      } else if (this.requestRvratPrescriptionsCount > 0) {
        this.requestRvratPrescriptionsCount--;
      }
      this.loaderRvratPrescriptions = this.requestRvratPrescriptionsCount > 0;
    });
    this.requestCheckpointsInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCheckpointsCount++;
      } else if (this.requestCheckpointsCount > 0) {
        this.requestCheckpointsCount--;
      }
      this.loaderCheckpoints = this.requestCheckpointsCount > 0;
    });
    this.requestTrainingsInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestTrainingsCount++;
      } else if (this.requestTrainingsCount > 0) {
        this.requestTrainingsCount--;
      }
      this.loaderTrainings = this.requestTrainingsCount > 0;
    });
    this.requestAsbestosInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestAsbestosCount++;
      } else if (this.requestAsbestosCount > 0) {
        this.requestAsbestosCount--;
      }
      this.loaderAsbestos = this.requestAsbestosCount > 0;
    });
    this.requestWorksInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestWorksCount++;
      } else if (this.requestWorksCount > 0) {
        this.requestWorksCount--;
      }
      this.loaderWorks = this.requestWorksCount > 0;
    });
    this.requestReportsInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestReportsCount++;
      } else if (this.requestReportsCount > 0) {
        this.requestReportsCount--;
      }
      this.loaderReports = this.requestReportsCount > 0;
    });
    this.requestOfficialPrescriptionsInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestOfficialPrescriptionsCount++;
      } else if (this.requestOfficialPrescriptionsCount > 0) {
        this.requestOfficialPrescriptionsCount--;
      }
      this.officialLoaderPrescriptions = this.requestOfficialPrescriptionsCount > 0;
    });
    this.requestFirePermitsInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestFirePermitsCount++;
      } else if (this.requestFirePermitsCount > 0) {
        this.requestFirePermitsCount--;
      }
      this.loaderFirePermits = this.requestFirePermitsCount > 0;
    });
    this.requestPreventionsInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestPreventionsCount++;
      } else if (this.requestPreventionsCount > 0) {
        this.requestPreventionsCount--;
      }
      this.loaderPreventions = this.requestPreventionsCount > 0;
    });
    this.requestPreventionsSimplifiedInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestPreventionsSimplifiedCount++;
      } else if (this.requestPreventionsSimplifiedCount > 0) {
        this.requestPreventionsSimplifiedCount--;
      }
      this.loaderPreventionsSimplified = this.requestPreventionsSimplifiedCount > 0;
    });

    this.currentRoute = '/' + this.activatedRoute.snapshot.url.join('/') + '/';

    this.changeEnterprise = this.authService.getEnterprise().subscribe(() => {
      if (this.activatedRoute.snapshot.params.establishment_id) {
        this.getMyEstablishment();
        this.getRegistryCheckpoints(this.activatedRoute.snapshot.params.establishment_id);
        this.getRegistries();
        this.getEstablishments();
      } else {
        this.getRegistryEstablishments();
        this.getRegistryCheckpoints();
      }
      this.getRegistry();
      this.createBreadcrumbs();
    });

    if (this.authService.loaded) {
      if (this.activatedRoute.snapshot.params.establishment_id) {
        this.getMyEstablishment();
        this.getRegistryCheckpoints(this.activatedRoute.snapshot.params.establishment_id);
        this.getRegistries();
        this.getEstablishments();
      } else {
        this.getRegistryEstablishments();
        this.getRegistryCheckpoints();
      }
      this.getRegistry();
      this.createBreadcrumbs();
    }

    this.connectedAs = JSON.parse(localStorage.getItem('connectedAs'));
    if (this.connectedAs) {
      this.monoOffer = JSON.parse(localStorage.getItem('connectedAsIsMonoOffer'));
      this.discoverOffer = JSON.parse(localStorage.getItem('connectedAsIsDiscoverOffer'));
    } else {
      this.monoOffer = JSON.parse(localStorage.getItem('isMonoOffer'));
      this.discoverOffer = JSON.parse(localStorage.getItem('isDiscoverOffer'));
    }
  }

  ngOnDestroy() {
    this.changeEnterprise.unsubscribe();
  }

  getRegistry() {
    this.requestInProgress.next(true);
    this.registriesService.getRegistry(
      this.activatedRoute.snapshot.params.registry_id,
      this.activatedRoute.snapshot.params.establishment_id
    ).subscribe(response => {
      if (response['success']) {
        this.registry = response['registry'];
        this.selectedRegistry = this.registry['id'];
        this.canLogEmployee = response['canLog'];
        this.updateIsRus();
        this.getAccess();

        this.createBreadcrumbs();
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  getAccess() {
    this.requestInProgress.next(true);
    this.registriesService.getAccess(
      this.activatedRoute.snapshot.params.registry_id, this.activatedRoute.snapshot.params.establishment_id
    ).subscribe(response => {
      if (response['success']) {
        this.limited = response['limited'];
        if (!this.limited && this.registry.asbestos_module && (!this.discoverOffer && !this.monoOffer)) {
          this.getAsbestos();
        }
        if (!this.limited && this.registry.formations_module && (!this.discoverOffer && !this.monoOffer)) {
          this.activeTab = 'formations';
          this.getTrainings();
        }
        if (this.registry.rus_module && (!this.discoverOffer && !this.monoOffer)) {
          if (!this.establishment || this.establishment && (this.establishment.is_rus || this.establishment.parent_id)) {
            this.activeTab = 'rus';
            this.getOfficialPrescriptions();
          }
        }
        if (!this.limited && this.registry.commissions_module || this.registry.expertises_module || this.registry.agencies_module
          && !this.discoverOffer) {
          this.activeTab = 'prescriptions';
          this.getPrescriptions();
        }
        if (!this.limited && this.registry.worksheets_module && !this.discoverOffer) {
          this.activeTab = 'rvrat';
          this.getRvratPrescriptions();
        }
        if (this.registry.checkpoints_module || this.registry.checkpoints_custom_module || this.registry.checkpoints_direct_module) {
          this.activeTab = 'observations';
          this.getObservations();
          this.getCheckpoints();
        }
        if (!this.limited && this.registry.report_worksheets_module && !this.discoverOffer) {
          this.getDatesWorks();
          this.getAllWorksheets();
        }
        if (!this.limited && this.activatedRoute.snapshot.params.establishment_id) {
          this.getFolders();
        }
        if (!this.limited && this.registry.fire_permits_module && (!this.discoverOffer && !this.monoOffer)) {
          this.getFirePermits();
          this.getPreventions();
          this.getPreventionsSimplified();
          this.activeTab = 'fire-permits';
        }
      }
      this.requestInProgress.next(false);
    });
  }

  updateIsRus() {
    if (this.activatedRoute.snapshot.params.establishment_id) {
      if (this.establishment && this.registry) {
        if (this.registry.rus_module && this.establishment.is_rus && this.establishment.parent_id === null) {
          this.isRus = true;
        } else if (this.registry.rus_module && this.establishment.parent_id !== null) {
          this.isRus = true;
        }
      }
    }
  }

  getMyEstablishment() {
    this.requestInProgress.next(true);
    this.establishmentsService.getMyEstablishment(this.activatedRoute.snapshot.params.establishment_id).subscribe(response => {
      if (response['success']) {
        this.establishment = response['establishment'];
        this.selectedEstablishment = this.establishment['id'];
        if (response['establishment']['description']) {
          this.description = response['establishment']['description'];
          this.showDescription = this.sanitizer.bypassSecurityTrustHtml(response['establishment']['description']);
        }
        this.canUpdate = response['canUpdate'];
        this.updateIsRus();
        this.createBreadcrumbs();
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
    });
    this.requestInProgress.next(false);
  }
  getRegistryEstablishments() {
    let request;
    this.filterByEstablishments = [];
    this.requestInProgress.next(true);

    request = this.establishmentsService.getAllEstablishmentsRegistry(this.activatedRoute.snapshot.params.registry_id);

    request.subscribe(response => {
      if (response['success']) {
        this.filterByEstablishments = response['establishments'];
        this.filterByEstablishments.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
    });
    this.requestInProgress.next(false);
  }
  getRegistries() {
    let request;
    this.registries = [];
    this.requestInProgress.next(true);

    request = this.registriesService.getRegistriesEstablishment('', '', '', '', '',
      this.activatedRoute.snapshot.params.establishment_id);

    request.subscribe(response => {
      if (response['success']) {
        this.registries = response['registries'];
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
    });
    this.requestInProgress.next(false);
  }
  getEstablishments() {
    let request;
    this.registries = [];
    this.requestInProgress.next(true);

    request = this.establishmentsService.getMyRegistry('', '', '', '', '',
      this.activatedRoute.snapshot.params.registry_id);

    request.subscribe(response => {
      if (response['success']) {
        this.establishmentsList = response['establishments'];
        this.establishmentsList.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
    });
    this.requestInProgress.next(false);
  }

  getRegistryCheckpoints(establishment = '') {
    this.filterByCheckpoints = [];
    this.checkpointsService.getAllCheckpointsRegistry(
      this.activatedRoute.snapshot.params.registry_id, establishment
    ).subscribe(response => {
      if (response['success']) {
        this.filterByCheckpoints = response['checkpoints'];

        this.filterByCheckpoints.sort((a, b) => {
          return a.full_name.localeCompare(b.full_name);
        });
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
    });
  }

  getFolders() {
    let request;
    this.requestInProgress.next(true);
    request = this.documentsService.getDashboardFolders(
      '', '', 'asc', '', '1',
      this.activatedRoute.snapshot.params.registry_id,
      this.activatedRoute.snapshot.params.establishment_id
    );

    request.subscribe(response => {
      if (response['success']) {
        this.folders = response['folders'];
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  getEmergencies(item) {
    if (item.emergencies === 0) {
      return { color: 'visit-low', value: 'Bas' };
    } else if (item.emergencies === 1) {
      return { color: 'visit-normal', value: 'Normal' };
    } else if (item.emergencies === 2) {
      return { color: 'visit-high', value: 'Haut' };
    } else if (item.emergencies === 3) {
      return { color: 'visit-priority', value: 'Prioritaire' };
    }
  }

  filterBy(type: string, event: any) {
    if (type === 'filter_establishments') {
      if (event && event.id) {
        this.establishments.next(event.id);
        this.filterByEstablishmentId = event.id;
        this.getRegistryCheckpoints(event.id);
      } else {
        this.establishments.next(null);
        this.filterByEstablishmentId = '';
        this.getRegistryCheckpoints();
      }
    }
    if (type === 'filter_checkpoints') {
      if (event && event.id) {
        this.filterByCheckpointId = event.id;
      } else {
        this.filterByCheckpointId = '';
      }
    }
    if (type === 'filter_emergencies') {
      if (event && event.id) {
        this.filterByEmergency = event.id;
      } else {
        this.filterByEmergency = '';
      }
    }
    if (type === 'filter_years') {
      if (event) {
        this.filterByYear = event;
      } else {
        this.filterByYear = '';
      }
    }
    if (type === 'filter_months') {
      if (event) {
        this.filterByMonth = event;
      } else {
        this.filterByMonth = '';
      }
    }

    if (!this.limited && this.registry.asbestos_module) {
      this.getAsbestos(this.filterByEstablishmentId);
    }
    if (!this.limited && this.registry.formations_module) {
      this.getTrainings(this.filterByEstablishmentId,  this.filterByYear, this.filterByMonth);
    }
    if (this.registry.rus_module) {
      if (!this.establishment || this.establishment && (this.establishment.is_rus || this.establishment.parent_id)) {
        this.getOfficialPrescriptions(this.filterByEstablishmentId, this.filterByEmergency, this.filterByYear, this.filterByMonth);
      }
    }
    if (!this.limited && this.registry.commissions_module || this.registry.expertises_module || this.registry.agencies_module) {
      this.getPrescriptions(this.filterByEstablishmentId, this.filterByEmergency, this.filterByYear, this.filterByMonth);
    }
    if (this.registry.checkpoints_module || this.registry.checkpoints_custom_module || this.registry.checkpoints_direct_module) {
      this.getObservations(this.filterByEstablishmentId, this.filterByCheckpointId,
          this.filterByEmergency, this.filterByYear, this.filterByMonth);
      this.getCheckpoints(this.filterByEstablishmentId, this.filterByCheckpointId, this.filterByYear, this.filterByMonth);
    }
    if (!this.limited && this.registry.fire_permits_module) {
      this.getFirePermits(this.filterByEstablishmentId);
    }
    if (!this.limited && this.registry.worksheets_module) {
      this.getRvratPrescriptions(this.filterByEstablishmentId, this.filterByEmergency, this.filterByYear, this.filterByMonth);
    }
  }
  getEstablishment() {
    return this.establishments;
  }

  checkIfFilter() {
    return (this.registry.checkpoints_module || this.registry.checkpoints_custom_module || this.registry.checkpoints_direct_module ||
      this.registry.commissions_module || this.registry.expertises_module || this.registry.agencies_module);
  }
  setFilterWidth() {
    if (this.establishment) {
      if ((this.registry.checkpoints_module || this.registry.checkpoints_custom_module || this.registry.checkpoints_direct_module) &&
        (this.registry.commissions_module || this.registry.expertises_module || this.registry.agencies_module)) {
        return 'col-12 col-sm-6 col-md-12 col-lg-6 col-xl-6';
      } else {
        return 'col-12';
      }
    } else {
      if ((this.registry.checkpoints_module || this.registry.checkpoints_custom_module || this.registry.checkpoints_direct_module) &&
        (this.registry.commissions_module || this.registry.expertises_module || this.registry.agencies_module)) {
        return 'col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4';
      } else {
        return 'col-12 col-sm-6 col-md-12 col-lg-6 col-xl-6';
      }
    }
  }

  /* OBSERVATIONS */
  getObservations(
    establishment_id = this.filterByEstablishmentId,
    checkpoint_id = this.filterByCheckpointId,
    emergency = this.filterByEmergency,
    year = this.filterByYear,
    month = this.filterByMonth
  ) {
    let request;
    this.requestObservationsInProgress.next(true);
    this.observations = [];

    if (this.allObservations) {
      request = this.observationsService.getObservationDashboard(
        this.searchObservation, this.sortByObservations, this.sortDirectionObservations, this.limitObservations, this.pageObservations,
        this.activatedRoute.snapshot.params.registry_id,
        establishment_id ? establishment_id : this.activatedRoute.snapshot.params.establishment_id,
        checkpoint_id,
        emergency,
        year,
        month,
      );
    } else {
      request = this.observationsService.getMyObservationDashboard(
        this.searchObservation, this.sortByObservations, this.sortDirectionObservations, this.limitObservations, this.pageObservations,
        this.activatedRoute.snapshot.params.registry_id,
        establishment_id ? establishment_id : this.activatedRoute.snapshot.params.establishment_id,
        checkpoint_id,
        emergency,
        year,
        month,
      );
    }

    request.subscribe(response => {
      if (response['success']) {
        this.canUpdateObservations = response['canUpdate'];
        this.observations = response['observations'];
        this.interventionsObservations = false;
        if (response['interventions']) {
          this.observations.forEach(observation => {
            response['interventions'].forEach((intervention: Intervention) => {
              intervention.actions.forEach(action => {
                if (observation.id === action.id) {
                  this.interventionsObservations = true;
                  observation.has_intervention = true;
                  if (observation.deadline_intervention && observation.deadline_intervention > intervention.deadline) {
                    observation['intervention_id'] = intervention.id;
                    observation.deadline_intervention = intervention.deadline;
                  } else if (!observation.deadline_intervention) {
                    observation['intervention_id'] = intervention.id;
                    observation.deadline_intervention = intervention.deadline;
                  }
                }
              });
            });
            if (observation.deadline_intervention) {
              observation.deadline_danger = moment(observation.deadline_intervention).isBefore(moment());
            }
          });
        }
        this.pagesObservations = response['pages'];
        this.countObservations = response['count'];
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestObservationsInProgress.next(false);
    });
  }
  switchTabObservations(bool) {
    if (this.allObservations !== bool) {
      this.allObservations = bool;
      this.pageObservations = 1;
      this.pagesObservations = 1;
      this.getObservations();
    }
  }
  filterObservations(property) {
    if (this.sortByObservations === property) {
      if (this.sortDirectionObservations === 'asc') {
        this.sortDirectionObservations = 'desc';
      } else {
        this.sortDirectionObservations = 'asc';
      }
    } else {
      this.sortByObservations = property;
      this.sortDirectionObservations = 'asc';
    }

    this.getObservations();
  }
  dispatchPaginationObservations(data) {
    if (data.action === 'total_elements') {
      this.refreshObservationsPage(data.value);
    } else if (data.action === 'change_page') {
      this.changePageObservations(data.value);
    }
  }
  changePageObservations(page) {
    if (page >= 1 && page <= this.pagesObservations) {
      this.pageObservations = page;
      this.getObservations();
    }
  }
  refreshObservationsPage(limit) {
    this.limitObservations = limit;
    this.pageObservations = 1;
    this.getObservations();
  }
  searchObservations() {
    clearTimeout(this.timeoutObservations);

    this.timeoutObservations = setTimeout(() => {
      this.pageObservations = 1;
      this.getObservations();
    }, 500);
  }

  /* PRESCRIPTIONS */
  getPrescriptions(
      establishment_id = this.filterByEstablishmentId,
      emergency = this.filterByEmergency,
      year = this.filterByYear,
      month = this.filterByMonth) {
    let request;
    this.prescriptions = [];
    this.requestPrescriptionsInProgress.next(true);

    if (this.allPrescriptions) {
      request = this.prescriptionsService.getPrescriptionDashboard(
        this.searchPrescription, this.sortByPrescriptions, this.sortDirectionPrescriptions, this.limitPrescriptions, this.pagePrescriptions,
        this.activatedRoute.snapshot.params.registry_id,
        establishment_id ? establishment_id : this.activatedRoute.snapshot.params.establishment_id,
        emergency,
        year,
        month
      );
    } else {
      request = this.prescriptionsService.getMyPrescriptionDashboard(
        this.searchPrescription, this.sortByPrescriptions, this.sortDirectionPrescriptions, this.limitPrescriptions, this.pagePrescriptions,
        this.activatedRoute.snapshot.params.registry_id,
        establishment_id ? establishment_id : this.activatedRoute.snapshot.params.establishment_id,
        emergency,
        year,
        month
      );
    }

    request.subscribe(response => {
      if (response['success']) {
        this.canUpdatePrescriptions = response['canUpdate'];
        this.prescriptions = response['prescriptions'];
        this.interventionsPrescriptions = false;
        if (response['interventions']) {
          this.prescriptions.forEach(prescription => {
            response['interventions'].forEach((intervention: Intervention) => {
              intervention.actions.forEach(action => {
                if (prescription.id === action.id) {
                  this.interventionsPrescriptions = true;
                  prescription.has_intervention = true;
                  if (prescription.deadline_intervention && prescription.deadline_intervention > intervention.deadline) {
                    prescription.deadline_intervention = intervention.deadline;
                  } else if (!prescription.deadline_intervention) {
                    prescription.deadline_intervention = intervention.deadline;
                  }
                }
              });
            });
            if (prescription.deadline_intervention) {
              prescription.deadline_danger = moment(prescription.deadline_intervention).isBefore(moment());
            }
          });
        }
        this.pagesPrescriptions = response['pages'];
        this.countPrescriptions = response['count'];
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestPrescriptionsInProgress.next(false);
    });
  }
  switchTabPrescriptions(bool) {
    if (this.allPrescriptions !== bool) {
      this.allPrescriptions = bool;
      this.pagePrescriptions = 1;
      this.pagesPrescriptions = 1;
      this.getPrescriptions();
    }
  }
  filterPrescriptions(property) {
    if (this.sortByPrescriptions === property) {
      if (this.sortDirectionPrescriptions === 'asc') {
        this.sortDirectionPrescriptions = 'desc';
      } else {
        this.sortDirectionPrescriptions = 'asc';
      }
    } else {
      this.sortByPrescriptions = property;
      this.sortDirectionPrescriptions = 'asc';
    }

    this.getPrescriptions();
  }
  dispatchPaginationPrescriptions(data) {
    if (data.action === 'total_elements') {
      this.refreshPrescriptionsPage(data.value);
    } else if (data.action === 'change_page') {
      this.changePagePrescriptions(data.value);
    }
  }
  changePagePrescriptions(page) {
    if (page >= 1 && page <= this.pagesPrescriptions) {
      this.pagePrescriptions = page;
      this.getPrescriptions();
    }
  }
  refreshPrescriptionsPage(limit) {
    this.limitPrescriptions = limit;
    this.pagePrescriptions = 1;
    this.getPrescriptions();
  }
  searchPrescriptions() {
    clearTimeout(this.timeoutPrescriptions);

    this.timeoutPrescriptions = setTimeout(() => {
      this.pagePrescriptions = 1;
      this.getPrescriptions();
    }, 500);
  }


  /* PRESCRIPTIONS */
  getRvratPrescriptions(
    establishment_id = this.filterByEstablishmentId,
    emergency = this.filterByEmergency,
    year = this.filterByYear,
    month = this.filterByMonth) {
    let request;
    this.rvratPrescriptions = [];
    this.requestRvratPrescriptionsInProgress.next(true);

    if (this.allPrescriptions) {
      request = this.rvratPrescriptionsService.getRvratPrescriptionDashboard(
        this.searchRvratPrescription, this.sortByRvratPrescriptions, this.sortDirectionRvratPrescriptions,
        this.limitRvratPrescriptions, this.pageRvratPrescriptions,
        this.activatedRoute.snapshot.params.registry_id,
        establishment_id ? establishment_id : this.activatedRoute.snapshot.params.establishment_id,
        emergency,
        year,
        month
      );
    } else {
      request = this.rvratPrescriptionsService.getMyRvratPrescriptionDashboard(
        this.searchRvratPrescription, this.sortByRvratPrescriptions, this.sortDirectionRvratPrescriptions,
        this.limitRvratPrescriptions, this.pageRvratPrescriptions,
        this.activatedRoute.snapshot.params.registry_id,
        establishment_id ? establishment_id : this.activatedRoute.snapshot.params.establishment_id,
        emergency,
        year,
        month
      );
    }

    request.subscribe(response => {
      if (response['success']) {
        this.canUpdateRvratPrescriptions = response['canUpdate'];
        this.rvratPrescriptions = response['rvrat_prescriptions'];
        this.interventionsRvratPrescriptions = false;
        if (response['interventions']) {
          this.rvratPrescriptions.forEach(prescription => {
            response['interventions'].forEach((intervention: Intervention) => {
              intervention.actions.forEach(action => {
                if (prescription.id === action.id) {
                  this.interventionsRvratPrescriptions = true;
                  prescription.has_intervention = true;
                  if (prescription.deadline_intervention && prescription.deadline_intervention > intervention.deadline) {
                    prescription.deadline_intervention = intervention.deadline;
                  } else if (!prescription.deadline_intervention) {
                    prescription.deadline_intervention = intervention.deadline;
                  }
                }
              });
            });
            if (prescription.deadline_intervention) {
              prescription.deadline_danger = moment(prescription.deadline_intervention).isBefore(moment());
            }
          });
        }
        this.pagesRvratPrescriptions = response['pages'];
        this.countRvratPrescriptions = response['count'];
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestRvratPrescriptionsInProgress.next(false);
    });
  }
  switchTabRvratPrescriptions(bool) {
    if (this.allRvratPrescriptions !== bool) {
      this.allRvratPrescriptions = bool;
      this.pageRvratPrescriptions = 1;
      this.pagesRvratPrescriptions = 1;
      this.getRvratPrescriptions();
    }
  }
  filterRvratPrescriptions(property) {
    if (this.sortByRvratPrescriptions === property) {
      if (this.sortDirectionRvratPrescriptions === 'asc') {
        this.sortDirectionRvratPrescriptions = 'desc';
      } else {
        this.sortDirectionRvratPrescriptions = 'asc';
      }
    } else {
      this.sortByRvratPrescriptions = property;
      this.sortDirectionRvratPrescriptions = 'asc';
    }

    this.getRvratPrescriptions();
  }
  dispatchPaginationRvratPrescriptions(data) {
    if (data.action === 'total_elements') {
      this.refreshRvratPrescriptionsPage(data.value);
    } else if (data.action === 'change_page') {
      this.changePageRvratPrescriptions(data.value);
    }
  }
  changePageRvratPrescriptions(page) {
    if (page >= 1 && page <= this.pagesRvratPrescriptions) {
      this.pageRvratPrescriptions = page;
      this.getRvratPrescriptions();
    }
  }
  refreshRvratPrescriptionsPage(limit) {
    this.limitRvratPrescriptions = limit;
    this.pageRvratPrescriptions = 1;
    this.getRvratPrescriptions();
  }
  searchRvratPrescriptions() {
    clearTimeout(this.timeoutRvratPrescriptions);

    this.timeoutRvratPrescriptions = setTimeout(() => {
      this.pageRvratPrescriptions = 1;
      this.getRvratPrescriptions();
    }, 500);
  }

  /* CHECKPOINTS */
  getCheckpoints(
      establishment_id = this.filterByEstablishmentId,
      checkpoint_id = this.filterByCheckpointId,
      year = this.filterByYear,
      month = this.filterByMonth) {
    let request;
    this.checkpoints = [];
    this.requestCheckpointsInProgress.next(true);

    if (this.nextCheckpoint) {
      request = this.checkpointsService.getNextCheckpointDashboard(
        this.searchCheckpoint, this.sortByCheckpoints, this.sortDirectionCheckpoints, this.limitCheckpoints, this.pageCheckpoints,
        this.activatedRoute.snapshot.params.registry_id,
        establishment_id ? establishment_id : this.activatedRoute.snapshot.params.establishment_id,
        checkpoint_id,
        year,
        month
      );
    } else {
      request = this.checkpointsService.getDelayCheckpointDashboard(
        this.searchCheckpoint, this.sortByCheckpoints, this.sortDirectionCheckpoints, this.limitCheckpoints, this.pageCheckpoints,
        this.activatedRoute.snapshot.params.registry_id,
        establishment_id ? establishment_id : this.activatedRoute.snapshot.params.establishment_id,
        checkpoint_id,
        year,
        month
      );
    }

    request.subscribe(response => {
      if (response['success']) {
        this.canCreateCheckpoints = response['canCreate'];
        this.canUpdateCheckpoints = response['canUpdate'];

        this.checkpoints = response['checkpoints'];

        this.pagesCheckpoints = response['pages'];
        this.countCheckpoints = response['count'];
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestCheckpointsInProgress.next(false);
    });
  }
  switchTabCheckpoints(bool) {
    if (this.nextCheckpoint !== bool) {
      this.nextCheckpoint = bool;
      this.pageCheckpoints = 1;
      this.pagesCheckpoints = 1;
      this.getCheckpoints();
    }
  }
  filterCheckpoints(property) {
    if (this.sortByCheckpoints === property) {
      if (this.sortDirectionCheckpoints === 'asc') {
        this.sortDirectionCheckpoints = 'desc';
      } else {
        this.sortDirectionCheckpoints = 'asc';
      }
    } else {
      this.sortByCheckpoints = property;
      this.sortDirectionCheckpoints = 'asc';
    }

    this.getCheckpoints();
  }
  dispatchPaginationCheckpoints(data) {
    if (data.action === 'total_elements') {
      this.refreshCheckpointsPage(data.value);
    } else if (data.action === 'change_page') {
      this.changePageCheckpoints(data.value);
    }
  }
  changePageCheckpoints(page) {
    if (page >= 1 && page <= this.pagesCheckpoints) {
      this.pageCheckpoints = page;
      this.getCheckpoints();
    }
  }
  refreshCheckpointsPage(limit) {
    this.limitCheckpoints = limit;
    this.pageCheckpoints = 1;
    this.getCheckpoints();
  }
  searchCheckpoints() {
    clearTimeout(this.timeoutCheckpoints);

    this.timeoutCheckpoints = setTimeout(() => {
      this.pageCheckpoints = 1;
      this.getCheckpoints();
    }, 500);
  }

  /* TRAININGS */
  getTrainings(establishment_id = this.filterByEstablishmentId, year = this.filterByYear, month = this.filterByMonth) {
    this.requestTrainingsInProgress.next(true);
    this.trainings = [];
    const params = this.activatedRoute.snapshot.params;
    const establishmentId = params.establishment_id ? params.establishment_id : '';
    const lateTraining = !this.allTrainings; // If is 12 next months

    const request = this.trainingsService.getTrainingsDashboard(this.searchTraining, this.sortByTrainings, this.sortDirectionTrainings,
      this.limitTrainings, this.pageTrainings, +params.registry_id, establishment_id ? establishment_id : establishmentId,
      lateTraining, year, month);

    request.subscribe(response => {
      if (response['success']) {
        this.canUpdateTrainings = response['canUpdate'];
        this.trainings = response['trainings'];
        this.pagesTrainings = response['pages'];
        this.countTrainings = response['count'];
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestTrainingsInProgress.next(false);
    });
  }
  switchTabTrainings(bool: boolean) {
    if (this.allTrainings !== bool) {
      this.allTrainings = bool;
      this.getTrainings();
    }
  }
  filterTrainings(property) {
    if (this.sortByTrainings === property) {
      this.sortDirectionTrainings = this.sortDirectionTrainings === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortByTrainings = property;
      this.sortDirectionTrainings = 'asc';
    }

    this.getTrainings();
  }
  dispatchPaginationTrainings(data) {
    if (data.action === 'total_elements') {
      this.refreshTrainingPage(data.value);
    } else if (data.action === 'change_page') {
      this.changePageTrainings(data.value);
    }
  }
  changePageTrainings(page) {
    if (page >= 1 && page <= this.pagesTrainings) {
      this.pageTrainings = page;
      this.getTrainings();
    }
  }
  refreshTrainingPage(limit) {
    this.limitTrainings = limit;
    this.pageTrainings = 1;
    this.getTrainings();
  }
  searchTrainings() {
    clearTimeout(this.timeoutTrainings);

    this.timeoutTrainings = setTimeout(() => {
      this.pageTrainings = 1;
      this.getTrainings();
    }, 500);
  }

  /* ASBESTOS */
  getAsbestos(establishment_id = this.filterByEstablishmentId) {
    let request;
    this.requestAsbestosInProgress.next(true);
    this.asbestosDetectionLocalizations = [];

    if (this.allAsbestos) {
      request = this.asbestosService.getEvaluationAsbestosDashboard(
        this.searchAsbestosValue, this.sortByAsbestos, this.sortDirectionAsbestos, this.limitAsbestos, this.pageAsbestos,
        this.activatedRoute.snapshot.params.registry_id,
        establishment_id ? establishment_id : this.activatedRoute.snapshot.params.establishment_id
      );
    } else {
      request = this.asbestosService.getMeasureAsbestosDashboard(
        this.searchAsbestosValue, this.sortByAsbestos, this.sortDirectionAsbestos, this.limitAsbestos, this.pageAsbestos,
        this.activatedRoute.snapshot.params.registry_id,
        establishment_id ? establishment_id : this.activatedRoute.snapshot.params.establishment_id
      );
    }

    request.subscribe(response => {
      if (response['success']) {
        this.canUpdateAsbestos = response['canUpdate'];
        this.asbestosDetectionLocalizations = response['asbestosDetectionLocalizations'];
        this.pagesAsbestos = response['pages'];
        this.countAsbestos = response['count'];
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestAsbestosInProgress.next(false);
    });
  }
  switchTabAsbestos(bool) {
    if (this.allAsbestos !== bool) {
      this.allAsbestos = bool;
      this.pageAsbestos = 1;
      this.pagesAsbestos = 1;
      this.getAsbestos();
    }
  }
  filterAsbestos(property) {
    if (this.sortByAsbestos === property) {
      if (this.sortDirectionAsbestos === 'asc') {
        this.sortDirectionAsbestos = 'desc';
      } else {
        this.sortDirectionAsbestos = 'asc';
      }
    } else {
      this.sortByAsbestos = property;
      this.sortDirectionAsbestos = 'asc';
    }

    this.getAsbestos();
  }
  dispatchPaginationAsbestos(data) {
    if (data.action === 'total_elements') {
      this.refreshAsbestosPage(data.value);
    } else if (data.action === 'change_page') {
      this.changePageAsbestos(data.value);
    }
  }
  changePageAsbestos(page) {
    if (page >= 1 && page <= this.pagesAsbestos) {
      this.pageAsbestos = page;
      this.getAsbestos();
    }
  }
  refreshAsbestosPage(limit) {
    this.limitAsbestos = limit;
    this.pageAsbestos = 1;
    this.getAsbestos();
  }
  searchAsbestos() {
    clearTimeout(this.timeoutAsbestos);

    this.timeoutAsbestos = setTimeout(() => {
      this.pageAsbestos = 1;
      this.getAsbestos();
    }, 500);
  }

  /* WORKS */
  getDatesWorks() {
    let request;
    this.requestWorksInProgress.next(true);
    this.dates = [];
    this.works = [];

    request = this.reportsService.getDatesWorksDashboard(
      this.activatedRoute.snapshot.params.registry_id
    );

    request.subscribe(response => {
      if (response['success']) {
        this.canUpdateWorks = response['canUpdate'];

        this.dates = response['dates'];
        this.works = response['works'];

        if (this.works.length) {
          this.yearWorks = this.works[0].year;
        }

        this.pagesWorks = response['pages'];
        this.countWorks = response['count'];
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestWorksInProgress.next(false);
    });
  }
  getWorks() {
    let request;
    this.requestWorksInProgress.next(true);
    this.works = [];

    request = this.reportsService.getWorksByDateDashboard(
      this.searchWork, this.sortByWorks, this.sortDirectionWorks, this.limitWorks, this.pageWorks,
      this.activatedRoute.snapshot.params.registry_id,
      this.yearWorks
    );

    request.subscribe(response => {
      if (response['success']) {
        this.canUpdateWorks = response['canUpdate'];
        this.works = response['works'];
        this.pagesWorks = response['pages'];
        this.countWorks = response['count'];
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestWorksInProgress.next(false);
    });
  }
  getAllWorksheets() {
    let request;
    this.requestReportsInProgress.next(true);

    request = this.reportsService.getAllReportsDashboard(
      this.activatedRoute.snapshot.params.registry_id
    );

    request.subscribe(response => {
      if (response['success']) {
        this.reports = response['reports'];
        const reverse = _.clone(this.reports);
        this.worksheetChart.createWorksheetsChart('#worksheetChart', reverse.reverse());
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestReportsInProgress.next(false);
    });
  }
  switchTabWorks(year) {
    this.yearWorks = year;
    this.searchWork = '';
    this.pageWorks = 1;
    this.pagesWorks = 1;
    this.getWorks();
  }
  filterWorks(property) {
    if (this.sortByWorks === property) {
      if (this.sortDirectionWorks === 'asc') {
        this.sortDirectionWorks = 'desc';
      } else {
        this.sortDirectionWorks = 'asc';
      }
    } else {
      this.sortByWorks = property;
      this.sortDirectionWorks = 'asc';
    }

    this.getWorks();
  }
  dispatchPaginationWorks(data) {
    if (data.action === 'total_elements') {
      this.refreshWorksPage(data.value);
    } else if (data.action === 'change_page') {
      this.changePageWorks(data.value);
    }
  }
  changePageWorks(page) {
    if (page >= 1 && page <= this.pagesWorks) {
      this.pageWorks = page;
      this.getWorks();
    }
  }
  refreshWorksPage(limit) {
    this.limitWorks = limit;
    this.pageWorks = 1;
    this.getWorks();
  }
  searchWorks() {
    clearTimeout(this.timeoutWorks);

    this.timeoutWorks = setTimeout(() => {
      this.pageWorks = 1;
      this.getWorks();
    }, 500);
  }

  /* OFFICIAL PRESCRIPTIONS */
  getOfficialPrescriptions(
      establishment_id = this.filterByEstablishmentId,
      emergency = this.filterByEmergency,
      year = this.filterByYear,
      month = this.filterByMonth) {
    let request;
    this.officialPrescriptions = [];
    this.requestOfficialPrescriptionsInProgress.next(true);

    if (this.officialAllPrescriptions) {
      request = this.officialPrescriptionsService.getPrescriptionDashboard(
        this.officialSearchPrescription, this.officialSortByPrescriptions, this.officialSortDirectionPrescriptions,
        this.officialLimitPrescriptions, this.officialPagePrescriptions, this.activatedRoute.snapshot.params.registry_id,
        establishment_id ? establishment_id : this.activatedRoute.snapshot.params.establishment_id,
        emergency,
        year,
        month
      );
    } else {
      request = this.officialPrescriptionsService.getMyPrescriptionDashboard(
        this.officialSearchPrescription, this.officialSortByPrescriptions, this.officialSortDirectionPrescriptions,
        this.officialLimitPrescriptions, this.officialPagePrescriptions, this.activatedRoute.snapshot.params.registry_id,
        establishment_id ? establishment_id : this.activatedRoute.snapshot.params.establishment_id,
        emergency,
        year,
        month
      );
    }

    request.subscribe(response => {
      if (response['success']) {
        this.officialCanUpdatePrescriptions = response['canUpdate'];
        this.officialPrescriptions = response['prescriptions'];
        this.interventionsOfficialPrescriptions = false;
        if (response['interventions']) {
          this.officialPrescriptions.forEach(prescription => {
            response['interventions'].forEach((intervention: Intervention) => {
              intervention.actions.forEach(action => {
                if (prescription.id === action.id) {
                  this.interventionsOfficialPrescriptions = true;
                  prescription.has_intervention = true;
                  if (prescription.deadline_intervention && prescription.deadline_intervention > intervention.deadline) {
                    prescription.deadline_intervention = intervention.deadline;
                  } else if (!prescription.deadline_intervention) {
                    prescription.deadline_intervention = intervention.deadline;
                  }
                }
              });
            });
            if (prescription.deadline_intervention) {
              prescription.deadline_danger = moment(prescription.deadline_intervention).isBefore(moment());
            }
          });
        }
        this.officialPagesPrescriptions = response['pages'];
        this.officialCountPrescriptions = response['count'];
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestOfficialPrescriptionsInProgress.next(false);
    });
  }
  switchTabOfficialPrescriptions(bool) {
    if (this.officialAllPrescriptions !== bool) {
      this.officialAllPrescriptions = bool;
      this.officialPagePrescriptions = 1;
      this.officialPagesPrescriptions = 1;
      this.getOfficialPrescriptions();
    }
  }
  filterOfficialPrescriptions(property) {
    if (this.officialSortByPrescriptions === property) {
      if (this.officialSortDirectionPrescriptions === 'asc') {
        this.officialSortDirectionPrescriptions = 'desc';
      } else {
        this.officialSortDirectionPrescriptions = 'asc';
      }
    } else {
      this.officialSortByPrescriptions = property;
      this.officialSortDirectionPrescriptions = 'asc';
    }

    this.getOfficialPrescriptions();
  }
  dispatchPaginationOfficialPrescriptions(data) {
    if (data.action === 'total_elements') {
      this.refreshOfficialPrescriptionsPage(data.value);
    } else if (data.action === 'change_page') {
      this.changePageOfficialPrescriptions(data.value);
    }
  }
  changePageOfficialPrescriptions(page) {
    if (page >= 1 && page <= this.officialPagesPrescriptions) {
      this.officialPagePrescriptions = page;
      this.getOfficialPrescriptions();
    }
  }
  refreshOfficialPrescriptionsPage(limit) {
    this.officialLimitPrescriptions = limit;
    this.officialPagePrescriptions = 1;
    this.getOfficialPrescriptions();
  }
  searchOfficialPrescriptions() {
    clearTimeout(this.officialTimeoutPrescriptions);

    this.officialTimeoutPrescriptions = setTimeout(() => {
      this.officialPagePrescriptions = 1;
      this.getOfficialPrescriptions();
    }, 500);
  }

  /* FIRE PERMITS */
  getFirePermits(establishment_id = this.filterByEstablishmentId) {
    let request;
    this.firePermits = [];
    this.requestFirePermitsInProgress.next(true);

    request = this.firePermitsService.getFirePermitsDashboard(
      this.searchFirePermit, this.sortByFirePermits, this.sortDirectionFirePermits,
      this.limitFirePermits, this.pageFirePermits, this.activatedRoute.snapshot.params.registry_id,
      establishment_id ? establishment_id : this.activatedRoute.snapshot.params.establishment_id,
      this.nextFirePermits
    );

    request.subscribe(response => {
      if (response['success']) {
        this.canUpdateFirePermits = response['canUpdate'];
        this.firePermits = response['permits'];
        this.pagesFirePermits = response['pages'];
        this.countFirePermits = response['count'];
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestFirePermitsInProgress.next(false);
    });
  }
  switchTabFirePermits(bool) {
    if (this.nextFirePermits !== bool) {
      this.nextFirePermits = bool;
      this.pageFirePermits = 1;
      this.pagesFirePermits = 1;
      this.getFirePermits();
    }
  }
  filterFirePermits(property) {
    if (this.sortByFirePermits === property) {
      if (this.sortDirectionFirePermits === 'asc') {
        this.sortDirectionFirePermits = 'desc';
      } else {
        this.sortDirectionFirePermits = 'asc';
      }
    } else {
      this.sortByFirePermits = property;
      this.sortDirectionFirePermits = 'asc';
    }

    this.getFirePermits();
  }
  dispatchPaginationFirePermits(data) {
    if (data.action === 'total_elements') {
      this.refreshFirePermitsPage(data.value);
    } else if (data.action === 'change_page') {
      this.changePageFirePermits(data.value);
    }
  }
  changePageFirePermits(page) {
    if (page >= 1 && page <= this.pagesFirePermits) {
      this.pageFirePermits = page;
      this.getFirePermits();
    }
  }
  refreshFirePermitsPage(limit) {
    this.limitFirePermits = limit;
    this.pageFirePermits = 1;
    this.getFirePermits();
  }
  searchFirePermits() {
    clearTimeout(this.timeoutFirePermits);

    this.timeoutFirePermits = setTimeout(() => {
      this.pageFirePermits = 1;
      this.getFirePermits();
    }, 500);
  }

  /* PREVENTIONS */
  getPreventions(establishment_id = this.filterByEstablishmentId) {
    let request;
    this.preventions = [];
    this.requestPreventionsInProgress.next(true);

    request = this.preventionsService.getPreventionsDashboard(
      this.searchPrevention, this.sortByPreventions, this.sortDirectionPreventions,
      this.limitPreventions, this.pagePreventions, this.activatedRoute.snapshot.params.registry_id,
      establishment_id ? establishment_id : this.activatedRoute.snapshot.params.establishment_id,
      'full', this.nextPreventions
    );

    request.subscribe(response => {
      if (response['success']) {
        this.canUpdatePreventions = response['canUpdate'];
        this.preventions = response['preventions'];
        this.pagesPreventions = response['pages'];
        this.countPreventions = response['count'];
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestPreventionsInProgress.next(false);
    });
  }
  switchTabPreventions(bool) {
    if (this.nextPreventions !== bool) {
      this.nextPreventions = bool;
      this.pagePreventions = 1;
      this.pagesPreventions = 1;
      this.getPreventions();
    }
  }
  filterPreventions(property) {
    if (this.sortByPreventions === property) {
      if (this.sortDirectionPreventions === 'asc') {
        this.sortDirectionPreventions = 'desc';
      } else {
        this.sortDirectionPreventions = 'asc';
      }
    } else {
      this.sortByPreventions = property;
      this.sortDirectionPreventions = 'asc';
    }

    this.getPreventions();
  }
  dispatchPaginationPreventions(data) {
    if (data.action === 'total_elements') {
      this.refreshPreventionsPage(data.value);
    } else if (data.action === 'change_page') {
      this.changePagePreventions(data.value);
    }
  }
  changePagePreventions(page) {
    if (page >= 1 && page <= this.pagesPreventions) {
      this.pagePreventions = page;
      this.getPreventions();
    }
  }
  refreshPreventionsPage(limit) {
    this.limitPreventions = limit;
    this.pagePreventions = 1;
    this.getPreventions();
  }
  searchPreventions() {
    clearTimeout(this.timeoutPreventions);

    this.timeoutPreventions = setTimeout(() => {
      this.pagePreventions = 1;
      this.getPreventions();
    }, 500);
  }

  /* PREVENTIONS SIMPLIFIED */
  getPreventionsSimplified(establishment_id = this.filterByEstablishmentId) {
    let request;
    this.preventionsSimplified = [];
    this.requestPreventionsSimplifiedInProgress.next(true);

    request = this.preventionsService.getPreventionsDashboard(
      this.searchPreventionSimplified, this.sortByPreventionsSimplified, this.sortDirectionPreventionsSimplified,
      this.limitPreventionsSimplified, this.pagePreventionsSimplified, this.activatedRoute.snapshot.params.registry_id,
      establishment_id ? establishment_id : this.activatedRoute.snapshot.params.establishment_id,
      'limited', this.nextPreventionsSimplified
    );

    request.subscribe(response => {
      if (response['success']) {
        this.canUpdatePreventionsSimplified = response['canUpdate'];
        this.preventionsSimplified = response['preventions'];
        this.pagesPreventionsSimplified = response['pages'];
        this.countPreventionsSimplified = response['count'];
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestPreventionsSimplifiedInProgress.next(false);
    });
  }
  switchTabPreventionsSimplified(bool) {
    if (this.nextPreventionsSimplified !== bool) {
      this.nextPreventionsSimplified = bool;
      this.pagePreventionsSimplified = 1;
      this.pagesPreventionsSimplified = 1;
      this.getPreventionsSimplified();
    }
  }
  filterPreventionsSimplified(property) {
    if (this.sortByPreventionsSimplified === property) {
      if (this.sortDirectionPreventionsSimplified === 'asc') {
        this.sortDirectionPreventionsSimplified = 'desc';
      } else {
        this.sortDirectionPreventionsSimplified = 'asc';
      }
    } else {
      this.sortByPreventionsSimplified = property;
      this.sortDirectionPreventionsSimplified = 'asc';
    }

    this.getPreventionsSimplified();
  }
  dispatchPaginationPreventionsSimplified(data) {
    if (data.action === 'total_elements') {
      this.refreshPreventionsSimplifiedPage(data.value);
    } else if (data.action === 'change_page') {
      this.changePagePreventionsSimplified(data.value);
    }
  }
  changePagePreventionsSimplified(page) {
    if (page >= 1 && page <= this.pagesPreventionsSimplified) {
      this.pagePreventionsSimplified = page;
      this.getPreventionsSimplified();
    }
  }
  refreshPreventionsSimplifiedPage(limit) {
    this.limitPreventionsSimplified = limit;
    this.pagePreventionsSimplified = 1;
    this.getPreventionsSimplified();
  }
  searchPreventionsSimplified() {
    clearTimeout(this.timeoutPreventionsSimplified);

    this.timeoutPreventionsSimplified = setTimeout(() => {
      this.pagePreventionsSimplified = 1;
      this.getPreventionsSimplified();
    }, 500);
  }

  changeTabs(tab) {
    this.activeTab = tab;
  }

  createBreadcrumbs() {
    this.previousPageName = [];
    this.previousPageRoute = [];
    this.pageName = this.registry ? this.registry.name : 'Tableau de bord registre';

    this.previousPageName.push('Tableau de bord');
    this.previousPageRoute.push('/');

    if (this.activatedRoute.snapshot.params.establishment_id) {
      this.pageName = this.establishment ? this.establishment.name : 'Tableau de bord établissement';
      this.previousPageName.push(this.registry ? this.registry.name : 'Tableau de bord registre');
      this.previousPageRoute.push('/dashboard-registry/' + this.activatedRoute.snapshot.params.registry_id);
    }
  }

  updateEstablishmentDescriptive() {
    this.errors = {};
    this.loader = true;
    this.edit = true;

    const establishmentDescriptive = {
      modifyDescription: true,
      description: this.description,
    };
    this.establishmentsService.updateMyEstablishment(this.activatedRoute.snapshot.params.establishment_id, establishmentDescriptive)
      .subscribe(response => {
      if (response['success']) {
        this.description = response['establishment']['description'];
        this.showDescription = this.sanitizer.bypassSecurityTrustHtml(response['establishment']['description']);
        this.edit = false;
        CustomNotifierService.getSuccessUpdate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
      }
      this.loader = false;
    });
  }

  checkIfClick(value) {
    this.modifyDescription = !value;
    this.edit = false;
  }
  checkIfEdit(value) {
    this.edit = !value;
  }

  changeEstablishment(establishment) {
    if (establishment !== null) {
      this.router.navigate(['/dashboard-registry/' + this.activatedRoute.snapshot.params.registry_id + '/establishment/' +
      establishment]).then(() => {
        window.location.reload();
      });
    }
  }
  changeRegistry(registry) {
    if (registry !== null) {
      this.router.navigate(['/dashboard-registry/' + registry + '/establishment/' +
      this.activatedRoute.snapshot.params.establishment_id]).then(() => {
        window.location.reload();
      });
    }
  }
}
