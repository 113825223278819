import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { HttpClient } from '@angular/common/http';
import { RequestService } from './request.service';

@Injectable()
export class SanitaryTemperatureFramesRequestService extends RequestService {
  constructor(
    protected http: HttpClient,
    protected notifier: NotifierService
  ) {
    super(http, notifier);
  }

  /// GET ALL
  public getAllSanitaryTemperatureFrames(search, sort, order, limit, page, registry_id, establishment_id, sanitaryTemperatureId) {
    return this.get( 'registry/' + registry_id + '/establishment/' + establishment_id + '/sanitary-surveillance/temperatures/' +
      sanitaryTemperatureId + '/frames?' + 'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit +
      '&page=' + page);
  }
  /// GET ONE
  public getSanitaryTemperatureFrame(registry_id, establishment_id, sanitaryTemperatureId, sanitaryTemperatureFrameId) {
    return this.get('registry/' + registry_id + '/establishment/' + establishment_id + '/sanitary-surveillance/temperatures/'
      + sanitaryTemperatureId + '/frame/' + sanitaryTemperatureFrameId);
  }
  /// CREATE
  public createSanitaryTemperatureFrame(registry_id, establishment_id, sanitaryTemperatureId, sanitaryTemperatureFrame) {
    return this.post('registry/' + registry_id + '/establishment/' + establishment_id + '/sanitary-surveillance/temperatures/'
      + sanitaryTemperatureId + '/frame/', { sanitaryTemperatureFrame });
  }
  /// UPDATE
  public updateSanitaryTemperatureFrame(registry_id, establishment_id, sanitaryTemperatureId, sanitaryTemperatureFrameId,
                                       sanitaryTemperatureFrame) {
    return this.put('registry/' + registry_id + '/establishment/' + establishment_id + '/sanitary-surveillance/temperatures/'
      + sanitaryTemperatureId + '/frame/' + sanitaryTemperatureFrameId, { sanitaryTemperatureFrame });
  }
  /// DELETE
  public deleteSanitaryTemperatureFrame(registry_id, establishment_id, sanitaryTemperatureId, sanitaryTemperatureFrameId) {
    return this.delete('registry/' + registry_id + '/establishment/' + establishment_id + '/sanitary-surveillance/temperatures/'
      + sanitaryTemperatureId + '/frame/' + sanitaryTemperatureFrameId);
  }
}
