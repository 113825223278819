<div class="main-content">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col">
        <div class="header mb-0">
          <div class="header-body no-border">
            <div class="row align-items-center">
              <div class="col">
                <h1 class="header-title" *ngIf="view === 'asbestos_detection'">
                  Repérages amiante
                </h1>
                <h1 class="header-title" *ngIf="view === 'asbestos_detection_localizations'">
                  Localisations amiantées
                </h1>
                <h1 class="header-title" *ngIf="view === 'asbestos_detection_files' || view === 'asbestos_detection_localization_files'">
                  Documents
                </h1>

                <small class="form-text text-muted" *ngIf="view === 'asbestos_detection'">
                  Liste des repérages amiante
                </small>
                <small class="form-text text-muted" *ngIf="view === 'asbestos_detection_localizations'">
                  Liste des localisations amiantées
                </small>
                <small class="form-text text-muted" *ngIf="view === 'asbestos_detection_files' || view === 'asbestos_detection_localization_files'">
                  Liste des documents
                </small>

                <app-breadcrumbs [currentPage]="pageName" [names]="previousPageName" [routes]="previousPageRoute"></app-breadcrumbs>
              </div>

              <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0" *ngIf="canCreate">
                <a class="btn btn-primary d-block d-md-inline-block" [routerLink]="[currentRoute + 'create']" *ngIf="view === 'asbestos_detection'">
                  Ajouter un repérage amiante
                </a>

                <a class="btn btn-primary d-block d-md-inline-block" [routerLink]="[currentRoute + 'create']" *ngIf="view === 'asbestos_detection_files' || view === 'asbestos_detection_localization_files'">
                  Ajouter un document
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col">
            <app-searchbar [(ngModel)]="search" (keyup)="searchData()" ngDefaultControl></app-searchbar>
          </div>

          <div class="col-auto" *ngIf="view === 'asbestos_detection_files' || view === 'asbestos_detection_localization_files'">
            <button class="btn btn-lg btn-white tooltipHtml" aria-label="Afficher les filtres disponibles"  data-toggle="dropdown" data-placement="bottom" title="Filtre">
              <span class="fe fe-filter"></span>
            </button>

            <span class="dropdown-menu dropdown-menu-right">
              <a class="dropdown-item href" (click)="refreshData('AsbestosDetections.name', 'asc', 1)" aria-label="Tri des repérages amiantes : Nom - Ordre croissant">
                Nom A-Z
              </a>

              <a class="dropdown-item href" (click)="refreshData('AsbestosDetections.name', 'desc', 1)" aria-label="Tri des repérages amiantes : Nom - Ordre décroissant">
                Nom Z-A
              </a>
            </span>
          </div>
        </div>

        <div class="is-loading loader-content" *ngIf="loader"></div>

        <div class="tab-content" *ngIf="!loader && (asbestosDetection.length || files.length)">
          <div class="tab-pane fade active show" id="tabPaneOne" role="tabpanel">
            <div class="row listAlias">
              <div class="col-12 d-flex flex-column" *ngIf="view === 'asbestos_detection'">
                <div class="card flex-fill">
                  <div class="card-body">
                    <div class="table-responsive">
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th class="href" (click)="filter('AsbestosDetections.referency')">
                              <a class="text-muted">Référence rapport de repérage</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'AsbestosDetections.referency' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'AsbestosDetections.referency' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'AsbestosDetections.referency'"></span>
                            </th>
                            <th>Type</th>
                            <th class="href" (click)="filter('AsbestosDetections.date')">
                              <a class="text-muted">Date</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'AsbestosDetections.date' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'AsbestosDetections.date' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'AsbestosDetections.date'"></span>
                            </th>
                            <th class="href" (click)="filter('AsbestosDetections.organization')">
                              <a class="text-muted">Organisme</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'AsbestosDetections.organization' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'AsbestosDetections.organization' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'AsbestosDetections.organization'"></span>
                            </th>
                            <th class="href" (click)="filter('AsbestosDetections.object')">
                              <a class="text-muted">Objet</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'AsbestosDetections.object' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'AsbestosDetections.object' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'AsbestosDetections.object'"></span>
                            </th>
                            <th>Matériaux et produits contenant de l'amiante</th>
                            <th class="href" (click)="filter('AsbestosDetections.has_asbestos')">
                              <a class="text-muted">Présence d'amiante ?</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'AsbestosDetections.has_asbestos' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'AsbestosDetections.has_asbestos' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'AsbestosDetections.has_asbestos'"></span>
                            </th>
                            <th>Rapport de repérage</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="dropdown" *ngFor="let asbestos of asbestosDetection">
                            <td>
                              <a class="href" [routerLink]="[currentRoute + asbestos.id]">
                                {{ asbestos.referency }}
                              </a>
                            </td>
                            <td>{{ asbestos.type }}</td>
                            <td>{{ asbestos.date | date : 'dd/MM/yyyy' }}</td>
                            <td>{{ asbestos.organization }}</td>
                            <td>{{ asbestos.object }}</td>
                            <td>
                              <div class="btn-group">
                                <a class="btn btn-sm btn-white" [routerLink]="[currentRoute + asbestos.id  + '/localizations']">
                                  {{ asbestos.count_localizations + ' localisation(s)' }}
                                </a>
                              </div>
                            </td>
                            <td>{{ asbestos.has_asbestos ? 'Oui' : 'Non' }}</td>
                            <td>
                              <div class="btn-group">
                                <a class="btn btn-sm btn-white" [routerLink]="[currentRoute + asbestos.id + '/files']">
                                  {{ asbestos.count_documents + ' rapport(s)'}}
                                </a>
                              </div>
                            </td>
                            <td class="d-flex flex-row-reverse">
                              <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fe fe-more-vertical"></i>
                              </a>

                              <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item href" [routerLink]="[currentRoute + asbestos.id]">
                                  {{ canUpdate ? 'Éditer la fiche repérage amiante' : 'Consulter la fiche repérage amiante' }}
                                </a>

                                <a class="dropdown-item href" (click)="modalConfirmData.open(asbestos.id)" *ngIf="canDelete">
                                  Supprimer la fiche repérage amiante
                                </a>

                                <a class="dropdown-item href" [routerLink]="[currentRoute + asbestos.id + '/logs']" *ngIf="canLog">
                                  Historique des actions
                                </a>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <app-pagination (returnEvent)="dispatchPagination($event)"
                                    [count_elements]="count"
                                    [current_page]="page"
                                    [elements_length]="asbestosDetection.length"
                                    [limit]="limit"
                                    [total_pages]="pages"
                                    ngDefaultControl>
                    </app-pagination>
                  </div>
                </div>
              </div>

              <div class="col-12 d-flex flex-column" *ngIf="view === 'asbestos_detection_localizations'">
                <div class="card flex-fill">
                  <div class="card-body">
                    <div class="table-responsive">
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th class="href" (click)="filter('localization')">
                              <a class="text-muted">Localisation</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'localization' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'localization' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'localization'"></span>
                            </th>
                            <th class="href" (click)="filter('accuracy_localization')">
                              <a class="text-muted">Détail</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'accuracy_localization' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'accuracy_localization' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'accuracy_localization'"></span>
                            </th>
                            <th class="href" (click)="filter('identifier')">
                              <a class="text-muted">Identifiant</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'identifier' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'identifier' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'identifier'"></span>
                            </th>
                            <th class="href" (click)="filter('description')">
                              <a class="text-muted">Description</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'description' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'description' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'description'"></span>
                            </th>
                            <th class="href" (click)="filter('conservation_state')">
                              <a class="text-muted">État de conservation</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'conservation_state' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'conservation_state' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'conservation_state'"></span>
                            </th>
                            <th class="href" (click)="filter('periodicity')">
                              <a class="text-muted">Périodicité</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'periodicity' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'periodicity' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'periodicity'"></span>
                            </th>
                            <th class="href" (click)="filter('result')">
                              <a class="text-muted">Résultat</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'result' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'result' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'result'"></span>
                            </th>
                            <th>Documents</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="dropdown" *ngFor="let asbestos of asbestosDetection">
                            <td>{{ asbestos.localization }}</td>
                            <td>{{ asbestos.accuracy_localization }}</td>
                            <td>{{ asbestos.identifier }}</td>
                            <td>{{ asbestos.description }}</td>
                            <td>{{ asbestos.conservation_state }}</td>
                            <td>{{ asbestos.periodicity }}</td>
                            <td>{{ asbestos.result }}</td>
                            <td>
                              <div class="btn-group">
                                <a class="btn btn-sm btn-white" [routerLink]="[currentRoute + asbestos.id + '/files']">
                                  {{ asbestos.count_documents + ' document(s)' }}
                                </a>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <app-pagination (returnEvent)="dispatchPagination($event)"
                                    [count_elements]="count"
                                    [current_page]="page"
                                    [elements_length]="asbestosDetection.length"
                                    [limit]="limit"
                                    [total_pages]="pages"
                                    ngDefaultControl>
                    </app-pagination>
                  </div>
                </div>
              </div>

              <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 d-flex flex-column" *ngFor="let file of files">
                <div class="card flex-fill">

                  <div class="href image-file-download" *ngIf="!['mp4', 'pdf', 'png', 'jpeg', 'jpg'].includes(file.extension)" (click)="downloadFile(file.id, file.name + '.' + file.extension)">
                    <img [src]="getImage(file.extension)" alt="Télécharger le fichier"/>
                  </div>
                  <div class="href image-file-download" *ngIf="file.extension === 'mp4'" (click)="showVideo(file.id)">
                    <img [src]="getImage(file.extension)" alt="Télécharger le fichier"/>
                  </div>
                  <div class="href image-file-download" *ngIf="['pdf', 'png', 'jpeg', 'jpg'].includes(file.extension)" (click)="showPdf(file.file_url)">
                    <img [src]="getImage(file.extension)" alt="Visionner le PDF"/>
                  </div>

                  <div class="card-body">
                    <div class="row align-items-center">
                      <div class="col overflow-hidden">
                        <h4 class="card-title name text-wrap mb-0">
                          <a [routerLink]="" *ngIf="!['mp4', 'pdf', 'png', 'jpeg', 'jpg'].includes(file.extension)" (click)="downloadFile(file.id, file.name + '.' + file.extension)">
                            {{ file.name + '.' + file.extension }}
                          </a>
                          <a [routerLink]="" *ngIf="file.extension === 'mp4'" (click)="showVideo(file.id)">
                            {{ file.name + '.' + file.extension }}
                          </a>
                          <a [routerLink]="" *ngIf="['pdf', 'png', 'jpeg', 'jpg'].includes(file.extension)" (click)="showPdf(file.file_url)">
                            {{ file.name + '.' + file.extension }}
                          </a>
                        </h4>

                        <p class="card-text text-truncate mb-0">
                          {{ file.document_category ? 'Catégorie du document : ' + file.document_category : '' }}
                        </p>
                      </div>

                      <div class="col-auto align-self-start">
                        <div class="dropdown">
                          <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fe fe-more-vertical"></i>
                          </a>

                          <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item href" [routerLink]="[currentRoute + file.id]">
                              {{ canUpdate ? 'Éditer le fichier' : 'Consulter le fichier' }}
                            </a>

                            <a class="dropdown-item href" *ngIf="file.extension === 'mp4'" (click)="showVideo(file.id)">
                              Regarder la vidéo
                            </a>

                            <a class="dropdown-item href" *ngIf="file.extension === 'pdf'" (click)="showPdf(file.file_url)">
                              Visionner le PDF
                            </a>

                            <a class="dropdown-item href" *ngIf="['png', 'jpeg', 'jpg'].includes(file.extension)" (click)="showPdf(file.file_url)">
                              Visionner l'image
                            </a>

                            <a class="dropdown-item href" (click)="downloadFile(file.id, file.name + '.' + file.extension)">
                              Télécharger le fichier
                            </a>

                            <a class="dropdown-item href" (click)="modalConfirmFile.open(file.id)" *ngIf="canDelete">
                              Supprimer le fichier
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row pagination-content" *ngIf="view === 'asbestos_detection_files' || view === 'asbestos_detection_localization_files'">
            <div class="col-12 d-flex flex-column">
              <div class="card">
                <app-pagination (returnEvent)="dispatchPagination($event)"
                                [classes]="'card-body'"
                                [count_elements]="count"
                                [current_page]="page"
                                [elements_length]="files.length"
                                [limit]="limit"
                                [total_pages]="pages"
                                ngDefaultControl>
                </app-pagination>
              </div>
            </div>
          </div>
        </div>

        <div class="loader-content" *ngIf="!loader && (!asbestosDetection.length && !files.length)">
          <p>Aucun résultat</p>
        </div>
      </div>
    </div>
  </div>
</div>

<app-confirm-modal [type]="'delete_data'" #confirmData>{{ bindModalConfirmData(confirmData) }}</app-confirm-modal>
<app-confirm-modal [type]="'delete_file'" #confirmFile>{{ bindModalConfirmFile(confirmFile) }}</app-confirm-modal>
<app-video-modal #video>{{ bindModalVideo(video) }}</app-video-modal>
<app-pdf-viewer #pdf>{{ bindModalPdf(pdf) }}</app-pdf-viewer>

<router-outlet></router-outlet>
