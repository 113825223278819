import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AllLogsModule } from './all-logs/all-logs.module';
import { ModalLogsModule } from './modal-logs/modal-logs.module';

import { LogsRequestService } from '../../services/logs.request.service';

@NgModule({
  imports: [
    CommonModule,
    AllLogsModule,
    ModalLogsModule
  ],
  exports: [ModalLogsModule],
  providers: [LogsRequestService]
})
export class LogsModule {}
