import { Routes } from '@angular/router';

import { ModalLogsComponent } from '../logs/modal-logs/modal-logs.component';
import { AllSanitaryContributorsComponent } from './all-sanitary-contributors/all-sanitary-contributors.component';
import { UpdateSanitaryContributorComponent } from './update-sanitary-contributor/update-sanitary-contributor.component';
import { UploadSanitaryContributorFileComponent } from './upload-sanitary-contributor-file/upload-sanitary-contributor-file.component';

export const sanitaryContributorsRoutes: Routes = [
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/sanitary-contributors',
    component: AllSanitaryContributorsComponent,
    children: [
      {
        path: 'create',
        component: UpdateSanitaryContributorComponent
      },
      {
        path: 'create_external',
        component: UpdateSanitaryContributorComponent
      },
      {
        path: ':sanitary_contributor_id',
        component: UpdateSanitaryContributorComponent
      },
      {
        path: 'external/:sanitary_contributor_id',
        component: UpdateSanitaryContributorComponent
      },
      {
        path: ':sanitary_contributor_id/logs',
        component: ModalLogsComponent
      }
    ]
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/sanitary-contributors/:sanitary_contributor_id/files',
    component: AllSanitaryContributorsComponent,
    children: [
      {
        path: 'create',
        component: UploadSanitaryContributorFileComponent
      },
      {
        path: ':file_id',
        component: UploadSanitaryContributorFileComponent
      }
    ]
  },
];
