import { Routes } from '@angular/router';

import { AllOfficialsComponent } from './all-officials/all-officials.component';
import { UpdateOfficialComponent } from './update-official/update-official.component';
import { UploadOfficialFileComponent } from './upload-official-file/upload-official-file.component';
import { ModalLogsComponent } from '../logs/modal-logs/modal-logs.component';

export const officialsRoutes: Routes = [
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/officials',
    component: AllOfficialsComponent,
    children: [
      {
        path: 'create',
        component: UpdateOfficialComponent
      },
      {
        path: ':official_id',
        component: UpdateOfficialComponent
      },
      {
        path: ':official_id/logs',
        component: ModalLogsComponent
      }
    ]
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/official/:official_id/files',
    component: AllOfficialsComponent,
    children: [
      {
        path: 'create',
        component: UploadOfficialFileComponent
      },
      {
        path: ':file_id',
        component: UploadOfficialFileComponent
      }
    ]
  }
];
