<div class="main-content">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col">
        <div class="header mb-0">
          <div class="header-body no-border">
            <div class="row align-items-center">
              <div class="col">
                <h1 class="header-title">FAQ</h1>

                <small class="form-text text-muted">Liste des FAQ de la plateforme</small>

                <app-breadcrumbs [currentPage]="pageName" [names]="previousPageName" [routes]="previousPageRoute"></app-breadcrumbs>
              </div>

              <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0" *ngIf="canCreate && viewAdmin">
                <a class="btn btn-primary d-block d-md-inline-block" [routerLink]="['/faq/create']">
                  Ajouter une faq
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col">
            <app-searchbar [(ngModel)]="search" (keyup)="searchData()" ngDefaultControl></app-searchbar>
          </div>

          <div class="col-auto">
            <button class="btn btn-lg btn-white tooltipHtml" aria-label="Afficher les filtres disponibles"  data-toggle="dropdown" data-placement="bottom" title="Filtre">
              <span class="fe fe-filter"></span>
            </button>

            <span class="dropdown-menu dropdown-menu-right">
              <a class="dropdown-item href" (click)="refreshData('Faqs.question', 'asc', 1)" aria-label="Tri des offres : Nom - Ordre croissant">
                Nom A-Z
              </a>

              <a class="dropdown-item href" (click)="refreshData('Faqs.question', 'desc', 1)" aria-label="Tri des offres : Nom - Ordre décroissant">
                Nom Z-A
              </a>

            </span>
          </div>
        </div>

        <div class="is-loading loader-content" *ngIf="loader"></div>

        <!-- ADMIN VIEW -->
        <div class="tab-content" *ngIf="!loader && faqs.length && viewAdmin">
          <div class="tab-pane fade active show" id="tabPaneOne" role="tabpanel">
            <div class="row listAlias">
              <div class="col-12 d-flex flex-column" *ngFor="let faq of faqs">
                <div class="card flex-fill">
                  <div class="card-body">
                    <div class="row align-items-center">
                      <div class="col overflow-hidden">
                        <h4 class="card-title name text-truncate mb-2">
                          <a [routerLink]="['/faq/' + faq.id]">
                            {{ faq.question }}
                          </a>
                        </h4>

                        <p class="card-text text-truncate mb-0" [innerHTML]="faq.answer"></p>

                      </div>

                      <div class="col-auto align-self-start">
                        <div class="dropdown">
                          <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fe fe-more-vertical"></i>
                          </a>

                          <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item href" [routerLink]="['/faq/' + faq.id]">
                              {{ canUpdate ? 'Éditer la faq' : 'Consulter la faq' }}
                            </a>

                            <a class="dropdown-item href" (click)="modalConfirmData.open(faq.id)" *ngIf="canDelete">
                              Supprimer la faq
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row pagination-content">
            <div class="col-12 d-flex flex-column">
              <div class="card">
                <app-pagination (returnEvent)="dispatchPagination($event)"
                                [classes]="'card-body'"
                                [count_elements]="count"
                                [current_page]="page"
                                [elements_length]="faqs.length"
                                [limit]="limit"
                                [total_pages]="pages"
                                ngDefaultControl>
                </app-pagination>
              </div>
            </div>
          </div>
        </div>

        <!-- PUBLIC VIEW -->
        <ng-container *ngIf="!loader && faqs.length && viewPublic">
          <div role="tablist" class="accordion_2 col-12" [attr.id]="'faq' + index" *ngFor="let faq of faqs; let index = index; let first = first">
            <div class="card">
              <div class="card-header" role="tab">
                <div class="row d-flex">
                  <div class="col-md-6 d-flex align-items-center">
                    <i data-toggle="collapse" [attr.href]="'#FaqList' + index" aria-expanded="true" class="fe chevron mr-2 href collapsed"></i>
                    <h2 class="mb-0 my-sm-3 mx-sm-auto mx-lg-0">{{ faq.question }}</h2>
                  </div>
                </div>
              </div>
              <div class="card-body collapse" [attr.id]="'FaqList' + index" role="tabpanel" [attr.data-parent]="'#faq' + index">
                <p class="card-text text-truncate mb-0" [innerHTML]="faq.answer"></p>
              </div>
            </div>
          </div>
        </ng-container>

        <div class="loader-content" *ngIf="!loader && !faqs.length">
          <p>Aucun résultat</p>
        </div>
      </div>
    </div>
  </div>
</div>

<app-confirm-modal [type]="'delete_data'" #confirmData>{{ bindModalConfirmData(confirmData) }}</app-confirm-modal>

<router-outlet></router-outlet>
