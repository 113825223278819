import { Routes } from '@angular/router';

import { AllEstablishmentsComponent } from './all-establishments/all-establishments.component';
import { UpdateEstablishmentComponent } from './update-establishment/update-establishment.component';
import { AddRegistriesComponent } from './add-registries/add-registries.component';
import { ModalLogsComponent } from '../logs/modal-logs/modal-logs.component';
import { MassEditRegistriesComponent } from './mass-edit-registries/mass-edit-registries.component';
import { AllEstablishmentFilesComponent } from './all-establishment-files/all-establishment-files.component';
import { UploadEstablishmentFileComponent } from './upload-establishment-file/upload-establishment-file.component';
import { MassImportComponent } from './mass-import/mass-import.component';
import { UpdateEstablishmentDescriptionComponent } from './update-establishment-description/update-establishment-description.component';
import { UpdateGroupComponent } from './update-group/update-group.component';

export const establishmentsRoutes: Routes = [
  {
    path: 'registries/mine/:registry_id/establishments',
    component: AllEstablishmentsComponent,
    children: [
      {
        path: 'create-group',
        component: UpdateGroupComponent
      },
      {
        path: 'update-group/:group_id',
        component: UpdateGroupComponent,
      },
      {
        path: ':establishment_id/logs',
        component: ModalLogsComponent,
      }
    ]
  },
  {
    path: 'registries/mine/:registry_id/group/:group_id',
    component: AllEstablishmentsComponent,
    children: [
      {
        path: 'create-group',
        component: UpdateGroupComponent
      },
      {
        path: 'add-establishment',
        component: UpdateEstablishmentComponent
      },
      {
        path: 'update/:establishment_id',
        component: UpdateEstablishmentComponent
      },
      {
        path: 'update-group/:group_id',
        component: UpdateGroupComponent,
      },
      {
        path: 'update-description/:establishment_id',
        component: UpdateEstablishmentDescriptionComponent
      },
      {
        path: ':establishment_id/logs',
        component: ModalLogsComponent,
      },
    ]
  },
  {
    path: 'establishments/mine/:active',
    component: AllEstablishmentsComponent,
    children: [
      {
        path: 'mass-edit-registries',
        component: MassEditRegistriesComponent
      },
      {
        path: 'mass-import',
        component: MassImportComponent
      },
      {
        path: 'create',
        component: UpdateEstablishmentComponent
      },
      {
        path: 'create-group',
        component: UpdateGroupComponent
      },
      {
        path: 'update/:establishment_id',
        component: UpdateEstablishmentComponent
      },
      {
        path: 'update-group/:group_id',
        component: UpdateGroupComponent,
      },
      {
        path: 'update-description/:establishment_id',
        component: UpdateEstablishmentDescriptionComponent
      },
      {
        path: ':establishment_id/logs',
        component: ModalLogsComponent,
      },
      {
        path: ':establishment_id/registries/update',
        component: AddRegistriesComponent
      }
    ]
  },
  {
    path: 'establishments/mine/group/:group_id',
    component: AllEstablishmentsComponent,
    children: [
      {
        path: 'create-group',
        component: UpdateGroupComponent
      },
      {
        path: 'add-establishment',
        component: UpdateEstablishmentComponent
      },
      {
        path: 'update/:establishment_id',
        component: UpdateEstablishmentComponent
      },
      {
        path: 'update-group/:group_id',
        component: UpdateGroupComponent,
      },
      {
        path: 'update-description/:establishment_id',
        component: UpdateEstablishmentDescriptionComponent
      },
      {
        path: ':establishment_id/logs',
        component: ModalLogsComponent,
      },
    ]
  },
  {
    path: 'establishments/mine/active/:establishment_id/files',
    component: AllEstablishmentFilesComponent,
    children: [
      {
        path: 'create',
        component: UploadEstablishmentFileComponent
      },
      {
        path: ':file_id',
        component: UploadEstablishmentFileComponent
      }
    ]
  },
  {
    path: 'enterprises/:enterprise_id/establishments/:active',
    component: AllEstablishmentsComponent,
    children: [
      {
        path: 'create',
        component: UpdateEstablishmentComponent
      },
      {
        path: 'update/:establishment_id',
        component: UpdateEstablishmentComponent
      },
      {
        path: 'update-description/:establishment_id',
        component: UpdateEstablishmentDescriptionComponent
      },
      {
        path: ':establishment_id/logs',
        component: ModalLogsComponent,
      },
      {
        path: ':establishment_id/registries/update',
        component: AddRegistriesComponent
      }
    ]
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:parent_id/children',
    component: AllEstablishmentsComponent,
    children: [
      {
        path: 'create',
        component: UpdateEstablishmentComponent
      },
      {
        path: 'update/:establishment_id',
        component: UpdateEstablishmentComponent
      },
      {
        path: ':establishment_id/logs',
        component: ModalLogsComponent,
      }
    ]
  }
];
