import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { NotifierService } from 'angular-notifier';
import { StatisticsRequestService } from '../../../services/statistics.request.service';
import { Subject, Subscription } from 'rxjs';
import { AuthService } from '../../../core/auth/auth.service';

import * as moment from 'moment';
import { frequencies } from '../../../../shared/frequencies';

declare var $: any;

declare function UserCharts(): any;
declare function Flatpickr(): any;

@Component({
  selector: 'app-dashboard-providers',
  templateUrl: './dashboard-providers.component.html',
  styleUrls: ['./dashboard-providers.component.scss']
})
export class DashboardProvidersComponent implements OnInit, OnDestroy {
  changeEnterprise: Subscription;
  establishments = [];
  selectedEstablishments = null;
  userChart = UserCharts();
  registries = [];
  selectedRegistry = null;
  installationCustoms = [];
  checkpointCustoms = [];

  modal;

  providerName = '';
  provider = [];

  checkpoints = [];
  checkpointLimit = 12;
  checkpointCount = 0;
  checkpointPage = 1;
  checkpointPages = 1;

  reports = [];
  reportLimit = 12;
  reportSort = '';
  reportOrder = '';
  reportCount = 0;
  reportPage = 1;
  reportPages = 1;

  observations = [];
  observationLimit = 12;
  observationSort = '';
  observationOrder = '';
  observationCount = 0;
  observationPage = 1;
  observationPages = 1;

  chart1 = null;
  chart2 = null;
  chart3 = null;
  chart4 = null;
  chart5 = null;
  chart6 = null;

  periods = [
    { value: 'custom', name: 'Période personnalisée' },
    { value: 'days', name: '7 derniers jours' },
    { value: 'month', name: '30 derniers jours' },
    { value: 'months', name: '3 derniers mois' },
    { value: 'year', name: 'Année en cours' },
    { value: 'lastyear', name: 'Année dernière' },
    { value: 'lastyears', name: 'Il y a deux ans' }
  ];

  period = 'custom';
  dateFrom;
  dateTo;
  groupBy = 'month';

  loaderCheckpointsProviders = false;
  loaderReportsProviders = false;
  loaderObservationsProviders = false;
  loaderInterventionsProviders = false;
  loaderDelayInterventionsProviders = false;

  requestInProgress = new Subject();
  requestCount = 0;
  loader = false;

  constructor(
    private _rootNode: ElementRef,
    private authService: AuthService,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private statisticsService: StatisticsRequestService
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loader = this.requestCount > 0;
    });

    this.changeEnterprise = this.authService.getEnterprise().subscribe(() => {
      this.initUserCharts();
      Flatpickr();
    });

    if (this.authService.loaded) {
      this.initUserCharts();
      setTimeout(function () {
        Flatpickr();
      }, 100);
    }

    // Remove background opacity bug if modal closed too fast
    const modalBackdrop = document.getElementsByClassName('modal-backdrop fade show') as HTMLCollection;
    for (let i = 0; i < modalBackdrop.length; i++) {
      modalBackdrop[i].remove();
    }
    const body = document.getElementsByTagName('body');
    for (let i = 0; i < body.length; i++) {
      body[i].className = body[i].className.replace('modal-open', '');
    }
  }

  ngOnDestroy() {
    this.changeEnterprise.unsubscribe();

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }

    if (this.modal) {
      this.modal.modal('hide');
      this.selectedEstablishments = [];
    }
  }

  initUserCharts() {
    this.statisticsService.getEstablishmentsNumber('true', '').subscribe(response => {
      if (response['success']) {
        this.establishments = response['establishments'];
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
    });
    this.statisticsService.getRegistries([], 'all_checkpoint_registries', '').subscribe(response => {
      if (response['success']) {
        this.registries = response['registries'];
        this.installationCustoms = response['installation_customs'];
        this.checkpointCustoms = response['checkpoint_customs'];
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
    });
    this.getCheckpointsByProviders();
    this.getReportsByProviders();
    this.getObservationsByProviders();
    this.getInterventionsByProviders();
    this.getDelayInterventionsByProviders();
    this.getCostsInterventionsByProviders();
  }

  updateSelectedEstablishments() {
    this.getCheckpointsByProviders();
    this.getReportsByProviders();
    this.getObservationsByProviders();
    this.getInterventionsByProviders();
    this.getDelayInterventionsByProviders();
    this.getCostsInterventionsByProviders();
  }

  updatePeriod(event = null) {
    let update = false;
    if (this.period === 'days') {
      this.dateFrom = moment().subtract(7, 'day').format('YYYY-MM-DD');
      this.dateTo = moment().format('YYYY-MM-DD');
      update = true;
    } else if (this.period === 'month') {
      this.dateFrom = moment().subtract(1, 'month').format('YYYY-MM-DD');
      this.dateTo = moment().format('YYYY-MM-DD');
      update = true;
    } else if (this.period === 'months') {
      this.dateFrom = moment().subtract(3, 'month').format('YYYY-MM-DD');
      this.dateTo = moment().format('YYYY-MM-DD');
      update = true;
    } else if (this.period === 'year') {
      this.dateFrom = moment().startOf('year').format('YYYY-MM-DD');
      this.dateTo = moment().endOf('year').format('YYYY-MM-DD');
      update = true;
    } else if (this.period === 'lastyear') {
      this.dateFrom = moment().startOf('year').subtract(1, 'year').format('YYYY-MM-DD');
      this.dateTo = moment().endOf('year').subtract(1, 'year').format('YYYY-MM-DD');
      update = true;
    } else if (this.period === 'lastyears') {
      this.dateFrom = moment().startOf('year').subtract(2, 'year').format('YYYY-MM-DD');
      this.dateTo = moment().endOf('year').subtract(2, 'year').format('YYYY-MM-DD');
      update = true;
    }
    if (event === null) {
      this.dateFrom = null;
      this.dateTo = null;
      update = true;
    }
    if (update) {
      this.getCheckpointsByProviders();
      this.getReportsByProviders();
      this.getObservationsByProviders();
      this.getInterventionsByProviders();
      this.getDelayInterventionsByProviders();
      this.getCostsInterventionsByProviders();
    }
  }

  updateSelectedRegistry() {
    this.getCheckpointsByProviders();
    this.getReportsByProviders();
    this.getObservationsByProviders();
    this.getInterventionsByProviders();
    this.getDelayInterventionsByProviders();
    this.getCostsInterventionsByProviders();
  }

  getEstablishmentNameReport(report) {
    if (report['establishment_checkpoint']) {
      if (report['establishment_checkpoint']['establishment_registry']) {
        if (report['establishment_checkpoint']['establishment_registry']['establishment']) {
          return report['establishment_checkpoint']['establishment_registry']['establishment']['name'];
        } else {
          return '';
        }
      } else {
        return '';
      }
    } else {
      return '';
    }
  }
  getEstablishmentNameObservation(observation) {
    if (observation['report']) {
      if (observation['report']['establishment_checkpoint']) {
        if (observation['report']['establishment_checkpoint']['establishment_registry']) {
          if (observation['report']['establishment_checkpoint']['establishment_registry']['establishment']) {
            return observation['report']['establishment_checkpoint']['establishment_registry']['establishment']['name'];
          } else {
            return '';
          }
        } else {
          return '';
        }
      } else {
        return '';
      }
    } else {
      return '';
    }
  }
  getUrlReport(report) {
    return '/dashboard-registry/' + report['establishment_checkpoint']['establishment_registry']['registry_id'] + '/establishment/' +
      report['establishment_checkpoint']['establishment_registry']['establishment_id'] + '/installations/' +
      report['establishment_checkpoint']['establishment_installation_id'] + '/checkpoints/' +
      report['establishment_checkpoint']['id'] + '/reports/' + report['id'];
  }
  getUrlObservation(observation) {
    return '/dashboard-registry/' + observation['report']['establishment_checkpoint']['establishment_registry']['registry_id']
      + '/establishment/' + observation['report']['establishment_checkpoint']['establishment_registry']['establishment_id']
      + '/installations/' + observation['report']['establishment_checkpoint']['establishment_installation_id'] + '/checkpoints/' +
      observation['report']['establishment_checkpoint']['id'] + '/reports/' +
      observation['report_id'] + '/observations/' + observation['id'];
  }

  getCheckpointsByProviders() {
    this.loaderCheckpointsProviders = true;
    this.statisticsService.checkpointsByProviders(this.dateFrom, this.dateTo,
      this.selectedEstablishments, this.selectedRegistry, '', ''
    ).subscribe(response => {
      if (response['success']) {
        this.chart1 = response['checkpoints'];
        this.userChart.createCheckpointsByProvidersChart('#checkpointsProviders', response['checkpoints'], this);
        if (this.chart2) {
          this.userChart.createReportsByProvidersChart('#reportsProviders', this.chart2, this);
          this.loaderReportsProviders = false;
        }
        if (this.chart3) {
          this.userChart.createObservationsByProvidersChart('#observationsProviders', this.chart3, this);
          this.loaderObservationsProviders = false;
        }
        if (this.chart4) {
          this.userChart.createInterventionsByProvidersChart('#interventionsProviders', this.chart4, this);
          this.userChart.createObservationsStateByInterventionsChart('#observationsStateByIntervention', this.chart4, this);
          this.loaderInterventionsProviders = false;
        }
        if (this.chart5) {
          this.userChart.createDelayInterventionByProvidersChart('#delayInterventionProviders', this.chart5, this);
          this.loaderDelayInterventionsProviders = false;
        }
        if (this.chart6) {
          this.userChart.createCostsInterventionByProvidersChart('#costsInterventionsProviders', this.chart6, this);
          this.loaderObservationsProviders = false;
        }
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loaderCheckpointsProviders = false;
    });
  }
  getAllCheckpointsByProvider(content, provider_id = null) {
    this.requestInProgress.next(true);
    this.statisticsService.checkpointsByProviders(this.dateFrom, this.dateTo,
      this.selectedEstablishments, this.selectedRegistry, this.checkpointLimit, this.checkpointPage, provider_id
    ).subscribe(response => {
      if (response['success']) {
        this.providerName = response['checkpoints']['provider']['name'];
        this.provider = response['checkpoints']['provider']['id'];
        this.checkpoints = response['checkpoints']['checkpoints'];
        this.checkpointCount = response['checkpoints']['count'];
        this.checkpointPages = response['checkpoints']['pages'];
        this.checkpoints.forEach( element => {
          if (element.full_name && element.full_name.length > 50) {
            element.full_name = element.full_name.substr(0, 50) + '...';
          }
        });
        this.modal = $(this._rootNode.nativeElement).find('div#' + content);
        this.modal.modal('show');
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }
  dispatchPaginationAllCheckpointsByProvider(data) {
    if (data.action === 'total_elements') {
      this.refreshPageAllCheckpointsByProvider(data.value);
    } else if (data.action === 'change_page') {
      this.changePageAllCheckpointsByProvider(data.value);
    }
  }
  refreshPageAllCheckpointsByProvider(limit) {
    this.checkpointLimit = limit;
    this.checkpointPage = 1;
    this.getAllCheckpointsByProvider('checkpointsByProvider', this.provider);
  }
  changePageAllCheckpointsByProvider(page) {
    if (page >= 1 && page <= this.checkpointPages) {
      this.checkpointPage = page;
      this.getAllCheckpointsByProvider('checkpointsByProvider', this.provider);
    }
  }

  getFrequencyText(frequency, number) {
    if (frequencies[frequency] && frequencies[frequency][number]) {
      return frequencies[frequency][number];
    } else {
      if (frequency === 'year') {
        if (number > 1) {
          return number + ' ans';
        } else {
          return number + ' an';
        }
      } else if (frequency === 'month') {
        return number + ' mois';
      } else if (frequency === 'week') {
        if (number > 1) {
          return number + ' semaines';
        } else {
          return number + ' semaine';
        }
      } else if (frequency === 'day') {
        if (number > 1) {
          return number + ' jours';
        } else {
          return number + ' jour';
        }
      }
    }
  }
  getUser(checkpoint) {
    if (checkpoint.report_user_id && checkpoint.report_enterprise_id) {
      if (checkpoint.report_enterprise_id === this.provider && checkpoint.observation_enterprise_id !== this.provider) {
        return checkpoint.report_user.lastname + ' ' + checkpoint.report_user.firstname;
      }
    }
    if (checkpoint.observation_user_id && checkpoint.observation_enterprise_id) {
      if (checkpoint.observation_enterprise_id === this.provider && checkpoint.report_enterprise_id !== this.provider) {
        return checkpoint.observation_user.lastname + ' ' + checkpoint.observation_user.firstname;
      }
    }
    if (checkpoint.report_user_id && checkpoint.report_enterprise_id &&
      checkpoint.observation_user_id && checkpoint.observation_enterprise_id
    ) {
      if (
        checkpoint.report_user_id === checkpoint.observation_user_id &&
        checkpoint.report_enterprise_id === this.provider && checkpoint.observation_enterprise_id === this.provider
      ) {
        return checkpoint.report_user.lastname + ' ' + checkpoint.report_user.firstname;
      }
    }
  }
  getAttribution(checkpoint) {
    if (checkpoint.report_user_id && checkpoint.report_enterprise_id) {
      if (checkpoint.report_enterprise_id === this.provider && checkpoint.observation_enterprise_id !== this.provider) {
        return 'Créer des rapports';
      }
    }
    if (checkpoint.observation_user_id && checkpoint.observation_enterprise_id) {
      if (checkpoint.observation_enterprise_id === this.provider && checkpoint.report_enterprise_id !== this.provider) {
        return 'Levées des observations';
      }
    }
    if (checkpoint.report_user_id && checkpoint.report_enterprise_id &&
      checkpoint.observation_user_id && checkpoint.observation_enterprise_id
    ) {
      if (
        checkpoint.report_user_id ===  checkpoint.observation_user_id &&
        checkpoint.report_enterprise_id === this.provider && checkpoint.observation_enterprise_id === this.provider
      ) {
        return 'Créer des rapports et levées des observations';
      }
    }
  }

  getReportsByProviders() {
    this.loaderReportsProviders = true;
    this.statisticsService.reportsByProviders(this.dateFrom, this.dateTo,
      this.selectedEstablishments, this.selectedRegistry, '', '', '', ''
    ).subscribe(response => {
      if (response['success']) {
        if (this.chart1) {
          this.userChart.createReportsByProvidersChart('#reportsProviders', response['reports'], this);
          this.loaderReportsProviders = false;
        } else {
          this.chart2 = response['reports'];
        }
      } else if (response['errors']) {
        this.loaderReportsProviders = false;
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
    });
  }

  filterReport(property) {
    if (this.reportSort === property) {
      if (this.reportOrder === 'asc') {
        this.reportOrder = 'desc';
      } else {
        this.reportOrder = 'asc';
      }
    } else {
      this.reportSort = property;
      this.reportOrder = 'asc';
    }

    this.getAllReportsByProvider('reportsByProvider', this.provider);
  }
  getAllReportsByProvider(content, provider_id = null) {
    this.requestInProgress.next(true);
    this.statisticsService.reportsByProviders(this.dateFrom, this.dateTo,
      this.selectedEstablishments, this.selectedRegistry, this.reportSort, this.reportOrder, this.reportLimit, this.reportPage, provider_id
    ).subscribe(response => {
      if (response['success']) {
        this.providerName = response['reports']['provider']['name'];
        this.provider = response['reports']['provider']['id'];
        this.reports = response['reports']['reports'];
        this.reportCount = response['reports']['count'];
        this.reportPages = response['reports']['pages'];
        this.modal = $(this._rootNode.nativeElement).find('div#' + content);
        this.modal.modal('show');
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }
  dispatchPaginationAllReportsByProvider(data) {
    if (data.action === 'total_elements') {
      this.refreshPageAllReportsByProvider(data.value);
    } else if (data.action === 'change_page') {
      this.changePageAllReportsByProvider(data.value);
    }
  }
  refreshPageAllReportsByProvider(limit) {
    this.reportLimit = limit;
    this.reportPage = 1;
    this.getAllReportsByProvider('reportsByProvider', this.provider);
  }
  changePageAllReportsByProvider(page) {
    if (page >= 1 && page <= this.reportPages) {
      this.reportPage = page;
      this.getAllReportsByProvider('reportsByProvider', this.provider);
    }
  }

  getObservationsByProviders() {
    this.loaderObservationsProviders = true;
    this.statisticsService.observationsByProviders(this.dateFrom, this.dateTo,
      this.selectedEstablishments, this.selectedRegistry, '', '', '', ''
    ).subscribe(response => {
      if (response['success']) {
        if (this.chart1) {
          this.userChart.createObservationsByProvidersChart('#observationsProviders', response['observations'], this);
          this.loaderObservationsProviders = false;
        } else {
          this.chart3 = response['observations'];
        }
      } else if (response['errors']) {
        this.loaderObservationsProviders = false;
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
    });
  }

  filterObservation(property) {
    if (this.observationSort === property) {
      if (this.observationOrder === 'asc') {
        this.observationOrder = 'desc';
      } else {
        this.observationOrder = 'asc';
      }
    } else {
      this.observationSort = property;
      this.observationOrder = 'asc';
    }

    this.getAllObservationsByProvider('observationsByProvider', this.provider);
  }
  getAllObservationsByProvider(content, provider_id = null) {
    this.requestInProgress.next(true);
    this.statisticsService.observationsByProviders(this.dateFrom, this.dateTo,
      this.selectedEstablishments, this.selectedRegistry, this.observationSort, this.observationOrder, this.observationLimit,
      this.observationPage, provider_id)
      .subscribe(response => {
      if (response['success']) {
        this.providerName = response['observations']['provider']['name'];
        this.provider = response['observations']['provider']['id'];
        this.observations = response['observations']['observations'];
        this.observationCount = response['observations']['count'];
        this.observationPages = response['observations']['pages'];
        this.modal = $(this._rootNode.nativeElement).find('div#' + content);
        this.modal.modal('show');
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }
  dispatchPaginationAllObservationsByProvider(data) {
    if (data.action === 'total_elements') {
      this.refreshPageAllObservationsByProvider(data.value);
    } else if (data.action === 'change_page') {
      this.changePageAllObservationsByProvider(data.value);
    }
  }
  refreshPageAllObservationsByProvider(limit) {
    this.observationLimit = limit;
    this.observationPage = 1;
    this.getAllObservationsByProvider('observationsByProvider', this.provider);
  }
  changePageAllObservationsByProvider(page) {
    if (page >= 1 && page <= this.observationPages) {
      this.observationPage = page;
      this.getAllObservationsByProvider('observationsByProvider', this.provider);
    }
  }

  getInterventionsByProviders() {
    this.loaderInterventionsProviders = true;
    this.statisticsService.interventionsByProviders(this.dateFrom, this.dateTo,
      this.selectedEstablishments, this.selectedRegistry, '', ''
    ).subscribe(response => {
      if (response['success']) {
        if (this.chart1) {
          this.userChart.createInterventionsByProvidersChart('#interventionsProviders', response['interventions'], this);
          this.userChart.createObservationsStateByInterventionsChart('#observationsStateByIntervention', response['interventions'], this);
          this.loaderInterventionsProviders = false;
        } else {
          this.chart4 = response['interventions'];
        }
      } else if (response['errors']) {
        this.loaderInterventionsProviders = false;
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
    });
  }

  getDelayInterventionsByProviders() {
    this.loaderDelayInterventionsProviders = true;
    this.statisticsService.delayInterventionsByProviders(this.dateFrom, this.dateTo,
      this.selectedEstablishments, this.selectedRegistry, '', ''
    ).subscribe(response => {
      if (response['success']) {
        if (this.chart1) {
          this.userChart.createDelayInterventionByProvidersChart('#delayInterventionProviders', response['interventions'], this);
          this.loaderDelayInterventionsProviders = false;
        } else {
          this.chart5 = response['interventions'];
        }
      } else if (response['errors']) {
        this.loaderDelayInterventionsProviders = false;
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
    });
  }
  getCostsInterventionsByProviders() {
    this.loaderObservationsProviders = true;
    this.statisticsService.costsInterventionsByProviders(this.dateFrom, this.dateTo,
      this.selectedEstablishments, this.selectedRegistry, '', ''
    ).subscribe(response => {
      if (response['success']) {
        if (this.chart1) {
          this.userChart.createCostsInterventionByProvidersChart('#costsInterventionsProviders', response['observations'], this);
          this.loaderObservationsProviders = false;
        } else {
          this.chart6 = response['observations'];
        }
      } else if (response['errors']) {
        this.loaderObservationsProviders = false;
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
    });
  }

  resetFilters() {
    this.selectedEstablishments = [];
    this.period = 'custom';
    this.getCheckpointsByProviders();
    this.getReportsByProviders();
    this.getObservationsByProviders();
    this.getInterventionsByProviders();
    this.getDelayInterventionsByProviders();
    this.getCostsInterventionsByProviders();
  }
}
