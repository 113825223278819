import { Routes } from '@angular/router';

import { ModalLogsComponent } from '../logs/modal-logs/modal-logs.component';
import { AllSanitaryWorksheetsComponent } from './all-sanitary-worksheets/all-sanitary-worksheets.component';
import { UpdateSanitaryWorksheetComponent } from './update-sanitary-worksheet/update-sanitary-worksheet.component';
import { UploadSanitaryWorksheetFileComponent } from './upload-sanitary-worksheet-file/upload-sanitary-worksheet-file.component';

export const sanitaryWorksheetsRoutes: Routes = [
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/sanitary-worksheets',
    component: AllSanitaryWorksheetsComponent,
    children: [
      {
        path: 'create',
        component: UpdateSanitaryWorksheetComponent
      },
      {
        path: ':sanitary_worksheet_id',
        component: UpdateSanitaryWorksheetComponent
      },
      {
        path: ':sanitary_worksheet_id/logs',
        component: ModalLogsComponent
      },
    ],
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/sanitary-worksheets/file',
    component: AllSanitaryWorksheetsComponent,
    children: [
      {
        path: 'create',
        component: UploadSanitaryWorksheetFileComponent
      },
      {
        path: ':sanitary_worksheet_id',
        component: UploadSanitaryWorksheetFileComponent
      },
    ],
  },
];
