<div class="main-content">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col">
        <div class="header mb-0">
          <div class="header-body no-border">
            <div class="row align-items-center">
              <div class="col">
                <h1 class="header-title">
                  Établissements
                </h1>

                <small class="form-text text-muted">
                  <span>Liste des établissements</span>
                </small>

                <app-breadcrumbs [currentPage]="pageName" [names]="previousPageName" [routes]="previousPageRoute"></app-breadcrumbs>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col">
            <app-searchbar [(ngModel)]="search" (keyup)="searchData()" ngDefaultControl></app-searchbar>
          </div>

          <div class="col-auto">
            <div class="nav btn-group" id="filters-and-display-buttons" aria-label="liste de boutons" role="tablist">
              <button class="btn btn-lg btn-white tooltipHtml" role="tab" aria-label="Affichage  mosaïque" aria-labelledby="filters-and-display-buttons" data-placement="bottom" title="Mosaïque" [ngClass]="{ 'active': activeTabsList === 'tabPaneOne'}" (click)="changeActiveTabsList('tabPaneOne')">
                <span class="fe fe-grid"></span>
              </button>

              <button class="btn btn-lg btn-white tooltipHtml" role="tab" aria-label="Affichage liste" aria-labelledby="filters-and-display-buttons" data-placement="bottom" title="Liste" [ngClass]="{ 'active': activeTabsList === 'tabPaneTwo'}" (click)="changeActiveTabsList('tabPaneTwo')">
                <span class="fe fe-list"></span>
              </button>

              <button class="btn btn-lg btn-white tooltipHtml border-right-radius" role="tab" aria-label="Filtres" aria-labelledby="filters-and-display-buttons" data-toggle="dropdown" data-placement="bottom" title="Filtres">
                <span class="fe fe-filter"></span>
              </button>
              <span class="dropdown-menu dropdown-menu-right">
                <a class="dropdown-item href" (click)="refreshData('Establishments.name', 'asc', 1)" aria-label="Tri des établissements : Nom - Ordre croissant">
                  Nom A-Z
                </a>

                <a class="dropdown-item href" (click)="refreshData('Establishments.name', 'desc', 1)" aria-label="Tri des établissements : Nom - Ordre décroissant">
                  Nom Z-A
                </a>

                <a class="dropdown-item href" (click)="refreshData('Establishments.zipcode', 'asc', 1)" aria-label="Tri des établissements : Code postal - Ordre croissant">
                  Code postal A-Z
                </a>

                <a class="dropdown-item href" (click)="refreshData('Establishments.zipcode', 'desc', 1)" aria-label="Tri des établissements : Code postal - Ordre décroissant">
                  Code postal Z-A
                </a>

                <a class="dropdown-item href" (click)="refreshData('Establishments.city', 'asc', 1)" aria-label="Tri des établissements : Ville - Ordre croissant">
                  Ville A-Z
                </a>

                <a class="dropdown-item href" (click)="refreshData('Establishments.city', 'desc', 1)" aria-label="Tri des établissements : Ville - Ordre décroissant">
                  Ville Z-A
                </a>
              </span>
            </div>
          </div>
        </div>

        <div class="is-loading loader-content" *ngIf="loader"></div>

        <div class="tab-content" [hidden]="loader || !establishments.length">
          <div class="tab-pane fade" id="tabPaneOne" role="tabpanel" [ngClass]="{ 'show active': activeTabsList === 'tabPaneOne'}">
            <div class="row listAlias">
              <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 d-flex flex-column" *ngFor="let establishment of establishments">
                <div class="card flex-fill">
                  <span class="card-img-top card-img-center" [title]="establishment.name" [style.backgroundImage]="'url(' + environment.SERVER_HOST + 'img/establishments/' + establishment.picture + ')'" *ngIf="establishment.picture != '' && establishment.picture != null"></span>

                  <span class="card-img-top card-img-center" [title]="establishment.name" [style.background-image]="'url(../assets/img/establishments/building.png)'" *ngIf="establishment.picture == '' || establishment.picture == null"></span>

                  <div class="card-body">
                    <div class="row align-items-center">
                      <div class="col overflow-hidden">
                        <h4 class="card-title mb-2 name text-truncate">
                          <a [routerLink]="[currentRoute + establishment.id + '/registries']">
                            {{ establishment.name }}
                          </a>
                        </h4>

                        <p class="card-text small text-muted text-truncate mb-0">
                          <span>{{ establishment?.establishment_type.name }}</span>
                          <span *ngIf="establishment?.establishment_category?.name">{{ ' - ' + establishment?.establishment_category?.name }}</span>
                        </p>

                        <p class="card-text small text-muted text-truncate mb-0" *ngIf="establishment?.establishment_subtype_establishments.length">
                          <span>Type : </span>
                          <span *ngFor="let subtype of establishment?.establishment_subtype_establishments; let last = last;">
                            {{ subtype.establishment_subtype.name }}{{ last ? '' : ' / ' }}
                          </span>
                        </p>
                      </div>

                      <div class="col-auto">
                        <div class="dropdown">
                          <a class="dropdown-ellipses dropdown-toggle href" role="button" [attr.aria-label]="'Sous menu de l\'établissement ' + establishment.name" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fe fe-more-vertical"></i>
                          </a>

                          <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item href" [routerLink]="[currentRoute + establishment.id + '/registries']">
                              Consulter les registres
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="tab-pane fade" id="tabPaneTwo" role="tabpanel" [ngClass]="{ 'show active': activeTabsList === 'tabPaneTwo'}">
            <div class="row list">
              <div class="col-12">
                <div class="card">
                  <div class="card-body">
                    <div class="table-responsive">
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th class="href" (click)="filter('Establishments.name')">
                              <a class="text-muted">Désignation de l'établissement</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'Establishments.name' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'Establishments.name' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'Establishments.name'"></span>
                            </th>
                            <th class="href" (click)="filter('Establishments.person_in_charge')">
                              <a class="text-muted">Responsable de l'établissement</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'Establishments.person_in_charge' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'Establishments.person_in_charge' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'Establishments.person_in_charge'"></span>
                            </th>
                            <th class="href" (click)="filter('Establishments.zipcode')">
                              <a class="text-muted">Code postal</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'Establishments.zipcode' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'Establishments.zipcode' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'Establishments.zipcode'"></span>
                            </th>
                            <th class="href" (click)="filter('Establishments.city')">
                              <a class="text-muted">Ville</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'Establishments.city' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'Establishments.city' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'Establishments.city'"></span>
                            </th>
                            <th class="href" (click)="filter('Establishments.effective_public')">
                              <a class="text-muted">Effectif public</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'Establishments.effective_public' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'Establishments.effective_public' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'Establishments.effective_public'"></span>
                            </th>
                            <th class="href" (click)="filter('EstablishmentTypes.name')">
                              <a class="text-muted">Classement</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'EstablishmentTypes.name' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'EstablishmentTypes.name' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'EstablishmentTypes.name'"></span>
                            </th>
                            <th class="href" (click)="filter('EstablishmentSubcategories.name')">
                              <a class="text-muted">Catégorie</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'EstablishmentSubcategories.name' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'EstablishmentSubcategories.name' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'EstablishmentSubcategories.name'"></span>
                            </th>
                            <th>Types</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="dropdown" *ngFor="let establishment of establishments">
                            <td>
                              <a class="href" [routerLink]="[currentRoute + establishment.id + '/registries']">
                                {{ establishment.name }}
                              </a>
                            </td>
                            <td>{{ establishment.person_in_charge }}</td>
                            <td>{{ establishment.zipcode }}</td>
                            <td>{{ establishment.city }}</td>
                            <td>{{ establishment.effective_public }}</td>
                            <td>{{ establishment?.establishment_type?.name }}</td>
                            <td>{{ establishment?.establishment_subcategory?.name }}</td>
                            <td>
                                <span *ngFor="let subtype of establishment?.establishment_subtype_establishments; let last = last;">
                                  {{ subtype.establishment_subtype.name }}{{ last ? '' : ' / ' }}
                                </span>
                            </td>
                            <td class="d-flex flex-row-reverse">
                              <a class="dropdown-ellipses dropdown-toggle href" role="button" [attr.aria-label]="'Sous menu de l\'établissement ' + establishment.name" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fe fe-more-vertical"></i>
                              </a>

                              <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item href" [routerLink]="[currentRoute + establishment.id + '/registries']">
                                  Consulter les registres
                                </a>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row pagination-content" aria-label="Pagination">
            <div class="col-12 d-flex flex-column">
              <div class="card">
                <app-pagination (returnEvent)="dispatchPagination($event)"
                                [classes]="'card-body'"
                                [count_elements]="count"
                                [current_page]="page"
                                [elements_length]="establishments.length"
                                [limit]="limit"
                                [total_pages]="pages"
                                ngDefaultControl>
                </app-pagination>
              </div>
            </div>
          </div>
        </div>

        <div class="loader-content" *ngIf="!loader && !establishments.length">
          <p>Aucun résultat</p>
        </div>
      </div>
    </div>
  </div>
</div>

<router-outlet></router-outlet>
