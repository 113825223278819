import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { HttpClient } from '@angular/common/http';
import { NotifierService } from 'angular-notifier';

@Injectable()
export class SsoConfigsRequestService extends RequestService {
  constructor(
    protected http: HttpClient,
    protected notifier: NotifierService
  ) {
    super(http, notifier);
  }

  /// GET
  public getSsoConfigs(search, sort, order, limit, page) {
    return this.get('sso_configs?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getSsoConfig(sso_id) {
    return this.get('sso_config/' + sso_id);
  }
  public getSsoConfigByRedirectUri(redirect_uri) {
    return this.get('sso_config/redirect/' + redirect_uri);
  }

  /// CREATE
  public createSsoConfig(sso_config) {
    return this.post('sso_config', { sso_config });
  }

  /// UPDATE
  public updateSsoConfig(sso_id, sso_config) {
    return this.put('sso_config/' + sso_id, { sso_config });
  }

  /// DELETE
  public deleteSsoConfig(sso_id) {
    return this.delete('sso_config/' + sso_id);
  }
}
