import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { HttpClient } from '@angular/common/http';
import { NotifierService } from 'angular-notifier';

@Injectable()
export class ContentsRequestService extends RequestService {

  constructor(
    protected http: HttpClient,
    protected notifier: NotifierService
  ) {
    super(http, notifier);
  }

  /// GET
  public getAllContents() {
    return this.get('contents');
  }
  public getContent(content_id) {
    return this.get('content/' + content_id);
  }
  getContentByCode(code) {
    return this.get('content-code/' + code);
  }

  /// CREATE
  public createContent(content) {
    return this.post('content', { content });
  }

  /// UPDATE
  public updateContent(content_id, content) {
    return this.put('content/' + content_id, { content });
  }

}
