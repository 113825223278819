import { AfterViewInit, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Subject } from 'rxjs';

import { CustomNotifierService } from '../../services/custom.notifier.service';

import { RegistriesRequestService } from '../../services/registries.request.service';
import { EstablishmentsRequestService } from '../../services/establishments.request.service';
import { ExportDuplicateRequestService } from '../../services/export-duplicate.request.service';

import Establishment from '../../../shared/interfaces/establishment';

@Component({
  selector: 'app-export-unique-doc',
  templateUrl: './export-unique-doc.component.html',
  styleUrls: ['./export-unique-doc.component.scss']
})
export class ExportUniqueDocComponent implements OnInit, AfterViewInit, OnDestroy {

  canCreate = false;
  canUpdate = false;

  errors = {};

  isNew = true;
  loader = false;
  loaderContent = true;

  requestInProgress = new Subject();
  requestCount = 0;
  modalDownload = null;
  previousRoute = '';

  establishment: Establishment;
  establishments = [];
  selectedEstablishments = [];
  establishmentId;
  registryId;
  registry;

  constructor(
    private router: Router,
    private injector: Injector,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private registriesService: RegistriesRequestService,
    private exportDuplicateService: ExportDuplicateRequestService,
    private establishmentRequestService: EstablishmentsRequestService,
  ) { }

  ngOnInit() {
    this.previousRoute = this.activatedRoute.snapshot.parent.url.join('/');

    this.establishmentId = parseInt(this.activatedRoute.snapshot.parent.params.establishment_id, 10);
    this.registryId = parseInt(this.activatedRoute.snapshot.parent.params.registry_id, 10);

    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loaderContent = this.requestCount > 0;
    });

    this.getRegistry();
    this.getEstablishments();

  }

  ngAfterViewInit() {
    this.modalDownload.open();
  }

  ngOnDestroy() {
    this.modalDownload.close();
  }

  getRegistry() {
    this.requestInProgress.next(true);
    this.registriesService.getRegistry(this.activatedRoute.snapshot.parent.params.registry_id).subscribe(response => {
      if (response['success']) {
        this.registry = response['registry'];
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  getEstablishments() {
    this.requestInProgress.next(true);
    this.establishmentRequestService.getAllEstablishmentsRegistry(this.activatedRoute.snapshot.parent.params.registry_id)
    .subscribe(response => {
      if (response['success']) {
        const establishments = response['establishments'];
        const found = establishments.findIndex(establishment => {
          return establishment.id === this.establishmentId;
        });
        if (found !== -1) {
          establishments.splice(found, 1);
        }
        this.establishments = establishments;
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  export() {
    this.requestInProgress.next(true);

    this.exportDuplicateService.exportUniqueDocument(this.establishmentId, this.selectedEstablishments).subscribe( response => {
      if (response['success'])  {
        CustomNotifierService.getSuccessUpdate(this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  bindModal(modal) {
    this.modalDownload = modal;
  }

  closeModal() {
    this.router.navigate([this.activatedRoute.snapshot.parent.url.join('/')]).then(() => {});
  }

}
