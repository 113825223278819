<app-modal-right (eventOnClose)="closeModal()" #file>{{ bindModal(file) }}>
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">{{ isNew ? 'Création' : canUpdate ? 'Mise à jour' : 'Consultation' }}</h6>
        <h1 class="header-title">{{ viewSheets ? 'Liste du personnel' : 'Fichier' }}</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <div [hidden]="loaderContent">
      <!-- VIEW FILES -->
      <ng-container *ngIf="viewFiles">
        <form [formGroup]="fileForm">
          <div class="row">
            <div class="col-12">
              <!-- NAME -->
              <div class="form-group">
                <label for="validationName" required>Nom</label>
                <input type="text" formControlName="name" id="validationName" class="form-control"
                       placeholder="Nom du fichier"
                       [ngClass]="{ 'is-invalid': name.invalid && (name.dirty || name.touched) }" maxlength="255">
                <div *ngIf="name.invalid && (name.dirty || name.touched)" class="invalid-feedback">
                  {{ getErrorMessage("name") }}
                </div>
              </div>

              <div class="card" *ngIf="!isNew">
                <div class="card-body">
                  <div class="d-flex align-content-center justify-content-between">
                    <h4 class="card-title mb-0 name align-self-center">
                      <a class="href tooltipHtml" data-placement="bottom" title="Téléchargement du fichier"
                         (click)="downloadFile(id.value, name.value + '.' + extension.value)">
                        {{ name.value + '.' + extension.value }}
                      </a>
                    </h4>

                    <a class="href tooltipHtml" data-placement="bottom" title="Supprimer" (click)="modalConfirm.open(id.value)" *ngIf="canUpdate">
                      <i class="fe fe-trash"></i>
                    </a>
                  </div>
                </div>
              </div>

              <!-- FILE DROP ZONE -->
              <div class="form-group" *ngIf="isNew || canUpdate">
                <label for="inputFile" *ngIf="!isNew">Documents</label>
                <label for="inputFile" *ngIf="isNew" required>Documents</label>

                <div class="dropzone dropzone-multiple dz-clickable" appDragDrop (dropHandler)="onFileChange($event)">
                  <div class="dz-default dz-message" (click)="chooseFile()">
                    <span>Déposer votre fichier ici pour télécharger</span>
                  </div>
                  <input id="inputFile" (change)="onFileChange($event)" type="file" [hidden]="true">
                </div>
              </div>

              <!-- FILE RECAP -->
              <div class="form-group card" *ngIf="file_base64.value && isNew">
                <div class="card-body">
                  <div class="d-flex align-content-center justify-content-between">
                    <h4 class="card-title mb-0 name align-self-center">
                      {{ fileName }}
                    </h4>

                    <a class="href tooltipHtml" data-placement="bottom" title="Supprimer" (click)="removeFile()" *ngIf="isNew || canUpdate">
                      <i class="fe fe-trash"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </ng-container>

      <!-- VIEW SHEETS -->
      <ng-container *ngIf="viewSheets">
        <div class="card bg-light border">
          <div class="card-body">
            <span>Informations : <br>
              <ul>
                <li>Les types de fichiers acceptés pour l'importation sont les suivants :
                  <ul>
                    <li>XLS (Microsoft Excel)</li>
                    <li>XLSX (Microsoft Excel)</li>
                    <li>CSV</li>
                    <li>ODS (OpenOffice / OpenDocument)</li>
                  </ul>
                </li>
                <li>Le tableau n'écrasera pas les informations précédentes.</li>
                <li>Si vous ne sélectionnez pas de registre ou d'établissement dans les listes déroulantes,
                  l'établissement et le registre actuel seront définis par défaut.</li>
                <li>Vous pouvez télécharger un exemple de fichier (type CSV) en <a class="href" (click)="downloadTemplate()">cliquant ici</a>.</li>
              </ul>
            </span>
          </div>
        </div>

        <form [formGroup]="fileForm">
          <div class="row">
            <div class="col-12">

              <!-- SHEET DROP ZONE -->
              <div class="form-group">
                <label for="inputFile" required>Liste à importer</label>
                <div class="dropzone dropzone-multiple dz-clickable" appDragDrop
                     (dropHandler)="onFileChange($event)">
                  <div class="dz-default dz-message" (click)="chooseFile()">
                    <span>Déposer votre fichier de personnel ici pour télécharger</span>
                  </div>
                  <!-- The input accepts .csv, .ods, .xls, .xlsx -->
                  <input id="inputSheet" type="file"
                         accept="
                   text/csv,
                   application/vnd.oasis.opendocument.spreadsheet,
                   application/vnd.ms-excel,
                   application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                         (change)="onFileChange($event)" [hidden]="true">
                </div>
              </div>

              <!-- FILE RECAP -->
              <div class="form-group card" *ngIf="file_base64.value && isNew">
                <div class="card-body">
                  <div class="d-flex align-content-center justify-content-between">
                    <h4 class="card-title mb-0 name align-self-center">
                      {{ fileName }}
                    </h4>

                    <a class="href tooltipHtml" data-placement="bottom" title="Supprimer" (click)="removeFile()">
                      <i class="fe fe-trash"></i>
                    </a>
                  </div>
                </div>
              </div>

              <!-- ESTABLISHMENTS SELECT -->
              <div class="form-group">
                <label for="validationEstablishment">Établissements</label>
                <ng-select [items]="establishments_dropdown"
                           [multiple]="true"
                           bindLabel="name"
                           bindValue="id"
                           groupBy="group"
                           notFoundText="Aucun résultat"
                           [selectableGroup]="true"
                           [selectableGroupAsModel]="false"
                           [closeOnSelect]="false"
                           placeholder="Sélectionnez un/ou plusieurs établissement(s)"
                           [(ngModel)]="establishmentsSelected" [ngModelOptions]="{ standalone: true }" [disabled]="!isNew && !canUpdate">
                  <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                    <input type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }"/> Sélectionnez tout
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                    <input id="validationEstablishment" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }" /> {{ item.name }}
                  </ng-template>
                </ng-select>
              </div>

              <!-- REGISTRIES SELECT -->
              <div class="form-group">
                <label for="validationRegistry">Registres</label>
                <ng-select [items]="registries_dropdown"
                           [multiple]="true"
                           bindLabel="name"
                           bindValue="id"
                           groupBy="group"
                           notFoundText="Aucun résultat"
                           [selectableGroup]="true"
                           [selectableGroupAsModel]="false"
                           [closeOnSelect]="false"
                           placeholder="Sélectionnez un/ou plusieurs registre(s)"
                           [(ngModel)]="registriesSelected" [ngModelOptions]="{ standalone: true }" [disabled]="!isNew && !canUpdate">
                  <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                    <input type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }"/> Sélectionnez tout
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                    <input id="validationRegistry" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }" /> {{ item.name }}
                  </ng-template>
                </ng-select>
              </div>

              <!-- TOGGLE TO DEFINE WHETHER THE SHEET WILL OVERWRITE OLD DATA OR ADD NEW DATA -->
              <!-- Don't touch -->
              <!--<div class="form-group d-flex justify-content-start align-items-center">
                <div class="col pl-0">Est-ce que les nouvelles données doivent écraser les anciennes ?</div>

                <div class="custom-checkbox-toggle">
                  <input type="checkbox" class="custom-control-input" id="sheetOverwriteDataToggle"
                         formControlName="sheetOverwriteData" [ngClass]="{ 'backgroundColor': sheetOverwriteData.value }">
                  <label class="custom-control-label" [ngClass]="{ 'backgroundColor': sheetOverwriteData.value }"
                         for="sheetOverwriteDataToggle"></label>
                </div>
              </div>-->
            </div>
          </div>
        </form>
      </ng-container>
    </div>
  </div>

  <div modal-footer class="w-100" *ngIf="isNew || canUpdate">
    <button class="btn btn-primary w-100" (click)="isNew ? createFile() : updateFile()"
            [ngClass]="{ 'is-loading is-loading-sm': loader }"
            [disabled]="loader || fileForm.invalid">
      {{ isNew ? 'Créer' : 'Mettre à jour'}}
    </button>
  </div>
</app-modal-right>

<app-confirm-modal [type]="viewSheets ? 'delete_employee' : 'delete_file'" #confirm>{{ bindModalConfirm(confirm) }}</app-confirm-modal>
