import { Injectable } from "@angular/core";
import { NotifierService } from "angular-notifier";
import { HttpClient } from "@angular/common/http";
import { RequestService } from "./request.service";

@Injectable()
export class EmployeesRequestService extends RequestService {
  constructor(protected http: HttpClient, protected notifier: NotifierService) {
    super(http, notifier);
  }

  /// GET
  public getEmployees(
    search,
    sort,
    order,
    limit,
    page,
    registry_id,
    establishment_id
  ) {
    return this.get(
      "dashboard-registry/" +
        registry_id +
        "/establishment/" +
        establishment_id +
        "/employees?" +
        "search=" +
        search +
        "&sort=" +
        sort +
        "&order=" +
        order +
        "&limit=" +
        limit +
        "&page=" +
        page
    );
  }
  public getEmployee(registry_id, establishment_id, employee_id) {
    return this.get(
      "dashboard-registry/" +
        registry_id +
        "/establishment/" +
        establishment_id +
        "/employees/" +
        employee_id
    );
  }

  public getAllEmployeesTrainOrNot(
    search,
    sort,
    order,
    limit,
    page,
    is_present,
    establishments,
    registry
  ) {
    return this.get(
      "date_trainings/employees_train_or_not/" +
        is_present +
        "?search=" +
        search +
        "&sort=" +
        sort +
        "&order=" +
        order +
        "&limit=" +
        limit +
        "&page=" +
        page +
        "&establishments=" +
        establishments +
        "&registry=" +
        registry
    );
  }

  /// CREATE
  public createEmployee(registry_id, establishment_id, employee) {
    return this.post(
      "dashboard-registry/" +
        registry_id +
        "/establishment/" +
        establishment_id +
        "/employees",
      { employee }
    );
  }
  public createSheetEmployees(
    registry_id: number,
    establishment_id: number,
    sheet: any
  ) {
    return this.post(
      "dashboard-registry/" +
        registry_id +
        "/establishment/" +
        establishment_id +
        "/employees/sheets",
      { employees: sheet }
    );
  }

  /// UPDATE
  public updateEmployee(registry_id, establishment_id, employee_id, employee) {
    return this.put(
      "dashboard-registry/" +
        registry_id +
        "/establishment/" +
        establishment_id +
        "/employees/" +
        employee_id,
      { employee }
    );
  }

  /// DELETE
  public deleteEmployee(registry_id, establishment_id, employee_id) {
    return this.delete(
      "dashboard-registry/" +
        registry_id +
        "/establishment/" +
        establishment_id +
        "/employees/" +
        employee_id
    );
  }

  /// GET EMPLOYEES TRAINING
  public getEmployeesTraining(search, sort, order, limit, page) {
    return this.get(
      "employees_trainings?" +
        "search=" +
        search +
        "&sort=" +
        sort +
        "&order=" +
        order +
        "&limit=" +
        limit +
        "&page=" +
        page
    );
  }

  /// GET EMPLOYEES TRAINING BY ESTABLISHMENTS
  public getEmployeesTrainingByEstablishments(
    search,
    sort,
    order,
    limit,
    page,
    establishment_id
  ) {
    return this.get(
      "employees_trainings_establishments/" +
        establishment_id +
        "?search=" +
        search +
        "&sort=" +
        sort +
        "&order=" +
        order +
        "&limit=" +
        limit +
        "&page=" +
        page
    );
  }

  /// GET EMPLOYEE TRAINING
  public getEmployeeTraining(employee_id) {
    return this.get("employees_trainings/" + employee_id);
  }

  ///DELETE FROM MAIN MENU
  public deleteEmployeeFromMainMenu(employee_id) {
    return this.delete("employees_trainings/" + employee_id);
  }
}
