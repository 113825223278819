import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NotifierService } from 'angular-notifier';

@Injectable()
export class CustomNotifierService {
  static getErrorsForm(errors: any, form: FormGroup, notifier: NotifierService) {
    if (errors && 'general' in errors) {
      notifier.notify('error', errors['general']['message']);
    } else if (errors) {
      for (const key in errors) {
        if (errors.hasOwnProperty(key)) {
          if (key in form.controls) {
            form.controls[key].setErrors(({ key: errors[key]['message'] }));
            form.controls[key].markAsTouched();
          }
        }
      }
    }
  }

  static getErrors(errors: any, notifier: NotifierService) {
    if (errors && 'general' in errors) {
      notifier.notify('error', errors['general']['message']);
    }
    if (errors && 'spreadsheet' in errors) {
      notifier.notify('error', errors['spreadsheet']['message']);
    }
  }

  static getErrorRequest(notifier: NotifierService) {
    notifier.notify(
      'error',
      'Une erreur est survenue avec le serveur, veuillez ressayer ultérieurement. Si le problème persiste, contactez un administrateur.'
    );
  }

  static getErrorNotAuthorized(notifier: NotifierService) {
    notifier.notify('error', 'Vous n\'avez pas les droits pour accéder à cette page.');
  }

  static getErrorNotConnected(notifier: NotifierService) {
    notifier.notify('error', 'Vous n\'êtes pas connecté, veuillez vous connecter.');
  }

  static getErrorDisconnected(notifier: NotifierService) {
    notifier.notify('error', 'Vous n\'êtes plus connecté, veuillez vous reconnecter.');
  }

  static getErrorNotification(notifier: NotifierService) {
    notifier.notify('error', 'Problème à l\'activation des notifications, veuillez autoriser la récéption de notification.');
  }

  static getSuccessCreate(notifier: NotifierService) {
    notifier.notify('success', 'Création effectuée avec succès.');
  }

  static getSuccessUpdate(notifier: NotifierService) {
    notifier.notify('success', 'Modification effectuée avec succès.');
  }

  static getSuccessActivation(notifier: NotifierService) {
    notifier.notify('success', 'Activation effectuée avec succès.');
  }

  static getSuccessNotification(notifier: NotifierService) {
    notifier.notify('success', 'Activation des notifications effectuée avec succès.');
  }

  static getSuccessDeactivation(notifier: NotifierService) {
    notifier.notify('success', 'Désactivation effectuée avec succès.');
  }

  static getSuccessDelete(notifier: NotifierService) {
    notifier.notify('success', 'Suppression effectuée avec succès.');
  }

  static getSuccessEmail(notifier: NotifierService) {
    notifier.notify('success', 'Un email vous a été envoyé.');
  }

  static getSuccessImportWaitingEmail(notifier: NotifierService) {
    notifier.notify('success', 'Fichier envoyé avec succès. Vous recevrez un e-mail lorsque le fichier aura été traité.');
  }

  static downloadInProgress(notifier: NotifierService) {
    notifier.notify('success', 'Téléchargement du bon d\'intervention en cours, veuillez patienter.');
  }

  static getErrorNoEnterprises(notifier: NotifierService) {
    notifier.notify('error', 'Le compte sélectionné n\'est rattaché à aucune entreprise. ' +
      'Contactez un administrateur pour plus d\'informations.');
  }

  static notSupportNotification(notifier: NotifierService) {
    notifier.notify(
      'error',
      'Attention cet environnement ne supporte pas la récéption de notification. Merci d\'utiliser un autre navigateur');
  }

  static getSsoLogin(notifier: NotifierService) {
    notifier.notify('error', 'L\'authentification unique est obligatoire pour cet utilisateur.');
  }

  static getErrorNoAttribution(notifier: NotifierService) {
    notifier.notify('error', 'Attention cet utilisateur n\'à pas l\'attribution établissement / registre requise.');
  }

  static getErrorEndDiscover(notifier: NotifierService) {
    notifier.notify('error', 'La fin de la période de découverte est terminée. Contactez un administrateur pour plus d\'informations.');
  }

  constructor() {}
}
