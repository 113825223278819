import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Subscription } from 'rxjs';
import * as moment from 'moment';

import { environment } from '../../../../environments/environment';

import { AuthService } from '../../../core/auth/auth.service';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { EnterprisesRequestService } from '../../../services/enterprises.request.service';

import Enterprise from '../../../../shared/interfaces/enterprise';

@Component({
  selector: 'app-all-enterprises',
  templateUrl: './all-enterprises.component.html',
  styleUrls: ['./all-enterprises.component.scss']
})
export class AllEnterprisesComponent implements OnInit, AfterViewInit, OnDestroy {
  changeEnterprise: Subscription;
  environment = environment;

  currentDate: moment.Moment;

  canCreate = false;
  canUpdate = false;
  canLog = false;

  activeTabsList = '';

  pageName: string;
  previousPageName: string[] = [];
  previousPageRoute: string[] = [];
  createRoute = '';
  updateRoute = '';
  view = '';

  enterprises: Enterprise[] = [];

  count = 0;
  pages = 1;

  search = '';
  sort = 'Enterprises.name';
  order = 'asc';
  limit = 12;
  page = 1;

  timeout: any;
  loader = false;

  modalConfirmProvider = null;

  constructor(
    private router: Router,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private enterprisesService: EnterprisesRequestService
  ) {
    this.currentDate = moment();
  }

  ngOnInit() {
    this.changeEnterprise = this.authService.getEnterprise().subscribe(() => {
      this.getEnterprises();
    });

    this.activeTabsList = localStorage.getItem('enterpriseTabsList');
    if (!this.activeTabsList) {
      localStorage.setItem('enterpriseTabsList', JSON.stringify('tabPaneOne'));
      this.activeTabsList = 'tabPaneOne';
    } else {
      this.activeTabsList = JSON.parse(this.activeTabsList);
    }

    this.createRoute = '/' + this.activatedRoute.snapshot.url.join('/') + '/create';
    this.updateRoute = '/' + this.activatedRoute.snapshot.url.join('/') + '/';

    if (this.activatedRoute.snapshot.url[0] && this.activatedRoute.snapshot.url[0].path === 'providers') {
      this.view = 'providers';
      this.pageName = 'Prestataires';
      this.previousPageName.push('Tableau de bord');
      this.previousPageRoute.push('/');
    } else if (this.activatedRoute.snapshot.url[0] && this.activatedRoute.snapshot.url[0].path === 'enterprises') {
      this.view = 'enterprises';
      this.pageName = 'Entreprises';
      this.previousPageName.push('Tableau de bord');
      this.previousPageRoute.push('/administration');
    }

    if (this.authService.loaded) {
      this.getEnterprises();
    }
  }

  ngAfterViewInit() {
    this.modalConfirmProvider.eventOnClose.subscribe(event => {
      if (event) {
        this.enterprisesService.removeProvider(event).subscribe(response => {
          if (response['success']) {
            CustomNotifierService.getSuccessDelete(this.notifier);
            this.getEnterprises();
          } else if (response['errors']) {
            CustomNotifierService.getErrors(response['errors'], this.notifier);
          }
        });
      }
    });
  }

  ngOnDestroy() {
    this.changeEnterprise.unsubscribe();

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  searchData() {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.page = 1;
      this.refreshData();
    }, 500);
  }

  refreshData(sort = this.sort, order = this.order, page = this.page) {
    this.sort = sort;
    this.order = order;
    this.page = page;
    this.getEnterprises();
  }

  getEnterprises() {
    this.loader = true;
    let request;

    if (this.activatedRoute.snapshot.url[0] && this.activatedRoute.snapshot.url[0].path === 'providers') {
      request = this.enterprisesService.getProviders(this.search, this.sort, this.order, this.limit, this.page);
    } else if (this.activatedRoute.snapshot.url[0] && this.activatedRoute.snapshot.url[0].path === 'enterprises') {
      request = this.enterprisesService.getEnterprises(this.search, this.sort, this.order, this.limit, this.page);
    }

    request.subscribe(response => {
      if (response['success']) {
        this.enterprises = response['enterprises'];

        this.count = response['count'];
        this.pages = response['pages'];

        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];
        this.canLog = response['canLog'];
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loader = false;
    });
  }

  dispatchPagination(data) {
    if (data.action === 'total_elements') {
      this.refreshPage(data.value);
    } else if (data.action === 'change_page') {
      this.changePage(data.value);
    }
  }

  refreshPage(limit) {
    this.limit = limit;
    this.page = 1;
    this.refreshData();
  }

  filter(property) {
    if (this.sort === property) {
      if (this.order === 'asc') {
        this.order = 'desc';
      } else {
        this.order = 'asc';
      }
    } else {
      this.sort = property;
      this.order = 'asc';
    }

    this.refreshData();
  }

  changePage(page) {
    if (page >= 1 && page <= this.pages) {
      this.page = page;
      this.getEnterprises();
    }
  }

  getAccess(enterprise) {
    if (enterprise.my_enterprises[0].access_to_enterprise) {
      return 'scoring-green';
    } else {
      return 'scoring-red';
    }
  }

  changeActiveTabsList(tabValue: string) {
    localStorage.setItem('enterpriseTabsList', JSON.stringify(tabValue));
    this.activeTabsList = tabValue;
  }

  bindModalConfirmProvider(modal) {
    this.modalConfirmProvider = modal;
  }
}
