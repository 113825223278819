<div class="main-content">
  <div class="header bg-dark pb-5">
    <div class="container-fluid">
      <div class="header-body">
        <div class="row align-items-end">
          <div class="col">
            <h6 class="header-pretitle text-secondary">TABLEAU DE BORD</h6>

            <h1 class="header-title text-white">Statistiques Observations</h1>
          </div>
          <div class="col-auto">
            <ul class="nav nav-tabs header-tabs">
              <li
                class="nav-item"
                data-toggle="chart"
                data-target="#headerChart"
              >
                <a
                  href="#"
                  class="nav-link text-center active"
                  data-toggle="tab"
                  (click)="updateObservationsChart('month')"
                >
                  <h6 class="header-pretitle text-secondary">GROUPER PAR</h6>
                  <h3 class="text-white mb-0">MOIS</h3>
                </a>
              </li>
              <li
                class="nav-item"
                data-toggle="chart"
                data-target="#headerChart"
              >
                <a
                  href="#"
                  class="nav-link text-center"
                  data-toggle="tab"
                  (click)="updateObservationsChart('day')"
                >
                  <h6 class="header-pretitle text-secondary">GROUPER PAR</h6>
                  <h3 class="text-white mb-0">JOUR</h3>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="header-footer">
        <div class="chart">
          <canvas id="headerChart" class="chart-canvas"></canvas>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid mt-n6">
    <div class="row">
      <div class="col-12">
        <ul class="nav nav-tabs observation-tabs" id="myTab" role="tablist">
          <li class="nav-item">
            <a
              class="
                nav-link
                d-flex
                align-items-center
                justify-content-center
                active
              "
              id="statistics-tab"
              data-toggle="tab"
              href="#statistics"
              role="tab"
              aria-controls="statistics"
              aria-selected="true"
              (click)="resetFilters()"
              >Statistiques</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link d-flex align-items-center justify-content-center"
              id="comparative-tab"
              data-toggle="tab"
              href="#comparative"
              role="tab"
              aria-controls="comparative"
              aria-selected="false"
              (click)="resetComparativeFilters()"
              >Comparatifs</a
            >
          </li>
        </ul>

        <div class="tab-content w-100" id="myTabContent">
          <!-- STATISTICS -->
          <div
            class="tab-pane fade show active"
            id="statistics"
            role="tabpanel"
            aria-labelledby="statistics-tab"
          >
            <div class="card unset-border-top">
              <!-- INPUTS -->
              <div class="card-header unset-border-top">
                <div class="row align-items-center">
                  <!-- ESTABLISHMENTS -->
                  <div class="col-6" *ngIf="establishments">
                    <div class="form-group">
                      <label>Etablissements</label>
                      <ng-select
                        [items]="establishments"
                        [multiple]="true"
                        bindLabel="name"
                        notFoundText="Aucun résultat"
                        [selectableGroup]="true"
                        [closeOnSelect]="false"
                        bindValue="id"
                        placeholder="Sélectionnez un/des établissement(s)"
                        [(ngModel)]="selectedEstablishments"
                        (ngModelChange)="updateSelectedEstablishments()"
                        [ngModelOptions]="{ standalone: true }"
                      >
                        <ng-template
                          ng-option-tmp
                          let-item="item"
                          let-item$="item$"
                          let-index="index"
                        >
                          <input
                            type="checkbox"
                            [ngModel]="item$.selected"
                            [ngModelOptions]="{ standalone: true }"
                          />
                          {{ item.name }}
                        </ng-template>
                      </ng-select>
                    </div>
                  </div>
                  <!-- PRESET TIME -->
                  <div class="col-6" *ngIf="establishments">
                    <div class="form-group">
                      <label>Période</label>
                      <ng-select
                        [items]="periods"
                        [multiple]="false"
                        bindLabel="name"
                        notFoundText="Aucun résultat"
                        [selectableGroup]="true"
                        [closeOnSelect]="true"
                        bindValue="value"
                        placeholder="Sélectionnez une période"
                        [(ngModel)]="period"
                        (ngModelChange)="updatePeriod($event)"
                        [ngModelOptions]="{ standalone: true }"
                      >
                        <ng-template
                          ng-option-tmp
                          let-item="item"
                          let-item$="item$"
                          let-index="index"
                          >{{ item.name }}</ng-template
                        >
                      </ng-select>
                    </div>
                  </div>

                  <!-- DATE FROM -->
                  <div class="col-6">
                    <div class="form-group">
                      <label for="dateFrom">Du</label>
                      <input
                        type="date"
                        [(ngModel)]="dateFrom"
                        (ngModelChange)="updateDate()"
                        class="form-control"
                        id="dateFrom"
                        placeholder="Date du"
                        data-toggle="flatpickr"
                      />
                    </div>
                  </div>
                  <!-- DATE TO -->
                  <div class="col-6">
                    <div class="form-group">
                      <label for="dateTo">Au</label>
                      <input
                        type="date"
                        [(ngModel)]="dateTo"
                        (ngModelChange)="updateDate()"
                        class="form-control"
                        id="dateTo"
                        placeholder="Date au"
                        data-toggle="flatpickr"
                      />
                    </div>
                  </div>
                  <!-- REGISTRIES -->
                  <div class="col-6" *ngIf="registries">
                    <div class="form-group">
                      <label>Registres</label>
                      <ng-select
                        [items]="registries"
                        [multiple]="false"
                        bindLabel="name"
                        notFoundText="Aucun résultat"
                        [selectableGroup]="true"
                        [closeOnSelect]="true"
                        bindValue="id"
                        placeholder="Sélectionnez un registre"
                        [(ngModel)]="selectedRegistry"
                        (ngModelChange)="updateSelectedRegistry()"
                        [ngModelOptions]="{ standalone: true }"
                      >
                        <ng-template
                          ng-option-tmp
                          let-item="item"
                          let-item$="item$"
                          let-index="index"
                          >{{ item.name }}</ng-template
                        >
                      </ng-select>
                    </div>
                  </div>
                  <ng-container
                    *ngIf="selectedRegistry && selectedRegistry !== 5"
                  >
                    <!-- INSTALLATIONS -->
                    <div
                      class="col-6"
                      *ngIf="selectedRegistry && registryInstallations.length"
                    >
                      <div class="form-group">
                        <label>Installation</label>
                        <ng-select
                          [items]="registryInstallations"
                          [multiple]="true"
                          bindLabel="name"
                          notFoundText="Aucun résultat"
                          [selectableGroup]="true"
                          [closeOnSelect]="false"
                          bindValue="id"
                          placeholder="Sélectionnez une installation"
                          [(ngModel)]="selectedInstallation"
                          (ngModelChange)="updateSelectedInstallation()"
                          [ngModelOptions]="{ standalone: true }"
                        >
                          <ng-template
                            ng-option-tmp
                            let-item="item"
                            let-item$="item$"
                            let-index="index"
                          >
                            <input
                              type="checkbox"
                              [ngModel]="item$.selected"
                              [ngModelOptions]="{ standalone: true }"
                            />
                            {{ item.name }}
                          </ng-template>
                        </ng-select>
                      </div>
                    </div>
                    <!-- CHECKPOINTS -->
                    <div
                      class="col-6"
                      *ngIf="
                        selectedInstallation.length &&
                        selectedCheckpoints.length
                      "
                    >
                      <div class="form-group">
                        <label>Points de contrôle</label>
                        <ng-select
                          [items]="selectedCheckpoints"
                          [multiple]="false"
                          bindLabel="name"
                          notFoundText="Aucun résultat"
                          [selectableGroup]="true"
                          [closeOnSelect]="true"
                          bindValue="id"
                          placeholder="Sélectionnez un point de contrôle"
                          [(ngModel)]="selectedCheckpoint"
                          (ngModelChange)="updateSelectedCheckpoint()"
                          [ngModelOptions]="{ standalone: true }"
                        >
                          <ng-template
                            ng-option-tmp
                            let-item="item"
                            let-item$="item$"
                            let-index="index"
                            >{{ item.name }}</ng-template
                          >
                        </ng-select>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container
                    *ngIf="selectedRegistry && selectedRegistry === 5"
                  >
                    <!-- INSTALLATIONS CUSTOMS -->
                    <div
                      class="col-6"
                      *ngIf="selectedRegistry && installationCustoms.length"
                    >
                      <div class="form-group">
                        <label>Installation</label>
                        <ng-select
                          [items]="installationCustoms"
                          [multiple]="true"
                          bindLabel="description"
                          notFoundText="Aucun résultat"
                          [selectableGroup]="true"
                          [closeOnSelect]="false"
                          bindValue="id"
                          placeholder="Sélectionnez une installation"
                          [(ngModel)]="selectedInstallationCustom"
                          (ngModelChange)="updateSelectedInstallationCustom()"
                          [ngModelOptions]="{ standalone: true }"
                        >
                          <ng-template
                            ng-option-tmp
                            let-item="item"
                            let-item$="item$"
                            let-index="index"
                          >
                            <input
                              type="checkbox"
                              [ngModel]="item$.selected"
                              [ngModelOptions]="{ standalone: true }"
                            />
                            {{ item.description }}
                          </ng-template>
                        </ng-select>
                      </div>
                    </div>
                    <!-- CHECKPOINTS CUSTOM -->
                    <div
                      class="col-6"
                      *ngIf="selectedInstallationCustom.length"
                    >
                      <div class="form-group">
                        <label>Points de contrôle</label>
                        <ng-select
                          [items]="selectedCheckpointsCustom"
                          [multiple]="false"
                          bindLabel="name"
                          notFoundText="Aucun résultat"
                          [selectableGroup]="true"
                          [closeOnSelect]="true"
                          bindValue="id"
                          placeholder="Sélectionnez un point de contrôle"
                          [(ngModel)]="selectedCheckpoint"
                          (ngModelChange)="updateSelectedCheckpointCustom()"
                          [ngModelOptions]="{ standalone: true }"
                        >
                          <ng-template
                            ng-option-tmp
                            let-item="item"
                            let-item$="item$"
                            let-index="index"
                            >{{ item.name }}</ng-template
                          >
                        </ng-select>
                      </div>
                    </div>
                  </ng-container>

                  <!-- CLEAR FILTERS -->
                  <div
                    class="
                      col-12
                      d-flex
                      align-items-center
                      justify-content-center
                    "
                  >
                    <div class="btn-group">
                      <a
                        class="
                          btn btn-block btn-primary
                          mb-4
                          text-white text-truncate
                        "
                        (click)="resetFilters()"
                      >
                        Réinitialiser les filtres
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <!-- OBSERVATIONS -->
              <div class="card-body">
                <div
                  class="is-loading loader-height"
                  *ngIf="loaderObsChart"
                ></div>
                <div class="row" [hidden]="loaderObsChart">
                  <!-- CARDS -->
                  <div class="col-6">
                    <!-- TOTAL -->
                    <div class="card cursor" (click)="open('allObservations')">
                      <div class="card-body">
                        <div class="row align-items-center">
                          <div class="col">
                            <h6
                              class="card-title text-uppercase text-muted mb-2"
                            >
                              Nombre total d'observations
                            </h6>
                            <span class="h2 mb-0">{{ totalObs }}</span>
                          </div>
                          <div class="col-auto">
                            <span
                              class="h2 fe fe-bar-chart text-muted mb-0"
                            ></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- TOTAL OK -->
                    <div
                      class="card cursor"
                      (click)="open('observationsState')"
                    >
                      <div class="card-body">
                        <div class="row align-items-center">
                          <div class="col">
                            <h6
                              class="card-title text-uppercase text-muted mb-2"
                            >
                              Nombre d'observations levées
                            </h6>
                            <span class="h2 mb-0">{{ totalObsL }}</span>
                          </div>
                          <div class="col-auto">
                            <span
                              class="h2 fe fe-bar-chart text-muted mb-0"
                            ></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- TOTAL KO -->
                    <div
                      class="card cursor"
                      (click)="open('observationsNotState')"
                    >
                      <div class="card-body">
                        <div class="row align-items-center">
                          <div class="col">
                            <h6
                              class="card-title text-uppercase text-muted mb-2"
                            >
                              Nombre d'observations non levées
                            </h6>
                            <span class="h2 mb-0">{{ totalObsNL }}</span>
                          </div>
                          <div class="col-auto">
                            <span
                              class="h2 fe fe-bar-chart text-muted mb-0"
                            ></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- TOTAL CHECKPOINTS -->
                    <div class="card cursor" (click)="open('allReports')">
                      <div class="card-body">
                        <div class="row align-items-center">
                          <div class="col">
                            <h6
                              class="card-title text-uppercase text-muted mb-2"
                            >
                              Nombre de rapports
                            </h6>
                            <span class="h2 mb-0">{{
                              totalObsCheckpoints
                            }}</span>
                          </div>
                          <div class="col-auto">
                            <span
                              class="h2 fe fe-bar-chart text-muted mb-0"
                            ></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- GRAPH CARDS -->
                  <div
                    class="
                      col-6
                      d-flex
                      align-items-center
                      justify-content-center
                    "
                  >
                    <div class="chart w-100">
                      <canvas id="obsChart" class="chart-canvas"></canvas>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="card">
                  <div class="card-header">
                    <div class="row align-items-center">
                      <div class="col">
                        <h4 class="card-header-title">
                          Observations par installation
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="chart">
                      <div
                        class="is-loading"
                        *ngIf="loaderObsInstallation"
                      ></div>
                      <canvas
                        [hidden]="loaderObsInstallation"
                        id="createObsInstallation"
                        class="chart-canvas"
                      ></canvas>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="card">
                  <div class="card-header">
                    <div class="row align-items-center">
                      <div class="col">
                        <h4 class="card-header-title">
                          Observations levées par installation
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="chart">
                      <div
                        class="is-loading"
                        *ngIf="loaderObsInstallation"
                      ></div>
                      <canvas
                        [hidden]="loaderObsInstallation"
                        id="createObsStateInstallation"
                        class="chart-canvas"
                      ></canvas>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="card">
                  <div class="card-header">
                    <div class="row align-items-center">
                      <div class="col">
                        <h4 class="card-header-title">
                          Observations non levées par installation
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="chart">
                      <div
                        class="is-loading"
                        *ngIf="loaderObsInstallation"
                      ></div>
                      <canvas
                        [hidden]="loaderObsInstallation"
                        id="createObsNotStateInstallation"
                        class="chart-canvas"
                      ></canvas>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- EMERGENCIES BLOCK -->
            <div class="card">
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col">
                    <h4 class="card-header-title">
                      Degrée d'urgence des observations
                    </h4>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <!-- CHART -->
                  <div class="col-12 d-flex align-items-center">
                    <div class="chart w-100">
                      <div
                        class="is-loading"
                        *ngIf="loaderEmergenciesChart"
                      ></div>
                      <canvas
                        [hidden]="loaderEmergenciesChart"
                        id="emergenciesObservationsChart"
                        class="chart-canvas"
                      ></canvas>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- DELAY BLOCK -->
            <div class="card">
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col">
                    <h4 class="card-header-title">Délais et retards</h4>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div
                  class="is-loading loader-height"
                  *ngIf="loaderCheckpoint || loaderDelayObservationsChart"
                ></div>
                <div
                  class="row"
                  [hidden]="loaderCheckpoint || loaderDelayObservationsChart"
                >
                  <div class="col-6">
                    <!-- TOTAL EN RETARD -->
                    <div class="card cursor" (click)="open('observationsLate')">
                      <div class="card-body">
                        <div class="row align-items-center">
                          <div class="col">
                            <h6
                              class="card-title text-uppercase text-muted mb-2"
                            >
                              Nombre d'observations levées en retard
                            </h6>
                            <span class="h2 mb-0">{{
                              totalObsDelay +
                                "/" +
                                (totalObsDelay + totalObsDelayN)
                            }}</span>
                          </div>
                          <div class="col-auto">
                            <span
                              class="h2 fe fe-bar-chart text-muted mb-0"
                            ></span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- TOTAL DANS LES TEMPS -->
                    <div
                      class="card cursor"
                      (click)="open('observationsInTime')"
                    >
                      <div class="card-body">
                        <div class="row align-items-center">
                          <div class="col">
                            <h6
                              class="card-title text-uppercase text-muted mb-2"
                            >
                              Nombre d'observations levées à temps
                            </h6>
                            <span class="h2 mb-0">{{
                              totalObsDelayN +
                                "/" +
                                (totalObsDelay + totalObsDelayN)
                            }}</span>
                          </div>
                          <div class="col-auto">
                            <span
                              class="h2 fe fe-bar-chart text-muted mb-0"
                            ></span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- TEMPS MOYEN DE LEVEE -->
                    <div class="card">
                      <div class="card-body">
                        <div class="row align-items-center">
                          <div class="col">
                            <h6
                              class="card-title text-uppercase text-muted mb-2"
                            >
                              Temps moyen de levée d'observations (jours)
                            </h6>
                            <span class="h2 mb-0">{{ totalObsAverage }}</span>
                          </div>
                          <div class="col-auto">
                            <span
                              class="h2 fe fe-bar-chart text-muted mb-0"
                            ></span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- NUMBER OF CHECKPOINTS -->
                    <div
                      class="card cursor"
                      (click)="open('observationsCheckpointsLate')"
                    >
                      <div class="card-body">
                        <div class="row align-items-center">
                          <div class="col">
                            <h6
                              class="card-title text-uppercase text-muted mb-2"
                            >
                              Nombre de points de contrôle en retard
                            </h6>
                            <span class="h2 mb-0">{{
                              totalObsCheckpointLate
                            }}</span>
                          </div>
                          <div class="col-auto">
                            <span
                              class="h2 fe fe-bar-chart text-muted mb-0"
                            ></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-6 d-flex align-items-center">
                    <div class="chart w-100">
                      <canvas
                        id="delayObservationsChart"
                        class="chart-canvas"
                      ></canvas>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- COMPARATIVE ESTABLISHMENTS -->
          <div
            class="tab-pane"
            id="comparative"
            role="tabpanel"
            aria-labelledby="comparative-tab"
          >
            <div class="card unset-border-top">
              <!-- INPUTS -->
              <div class="card-header unset-border-top">
                <div class="row align-items-center">
                  <!-- ESTABLISHMENTS -->
                  <div class="col-6" *ngIf="establishments">
                    <div class="form-group">
                      <label>Établissement A</label>
                      <ng-select
                        [items]="establishments"
                        [multiple]="false"
                        bindLabel="name"
                        notFoundText="Aucun résultat"
                        [selectableGroup]="true"
                        [closeOnSelect]="true"
                        bindValue="id"
                        placeholder="Sélectionnez un établissement"
                        [(ngModel)]="selectedEstablishmentA"
                        (ngModelChange)="updateSelectedEstablishmentA()"
                        [ngModelOptions]="{ standalone: true }"
                      >
                        <ng-template
                          ng-option-tmp
                          let-item="item"
                          let-item$="item$"
                          let-index="index"
                        >
                          <input
                            type="checkbox"
                            [ngModel]="item$.selected"
                            [ngModelOptions]="{ standalone: true }"
                          />
                          {{ item.name }}
                        </ng-template>
                      </ng-select>
                    </div>
                  </div>
                  <div class="col-6" *ngIf="establishments">
                    <div class="form-group">
                      <label>Établissement B</label>
                      <ng-select
                        [items]="establishments"
                        [multiple]="false"
                        bindLabel="name"
                        notFoundText="Aucun résultat"
                        [selectableGroup]="true"
                        [closeOnSelect]="true"
                        bindValue="id"
                        placeholder="Sélectionnez un établissement"
                        [(ngModel)]="selectedEstablishmentB"
                        (ngModelChange)="updateSelectedEstablishmentB()"
                        [ngModelOptions]="{ standalone: true }"
                      >
                        <ng-template
                          ng-option-tmp
                          let-item="item"
                          let-item$="item$"
                          let-index="index"
                        >
                          <input
                            type="checkbox"
                            [ngModel]="item$.selected"
                            [ngModelOptions]="{ standalone: true }"
                          />
                          {{ item.name }}
                        </ng-template>
                      </ng-select>
                    </div>
                  </div>
                  <!-- PRESET TIME -->
                  <div class="col-6" *ngIf="establishments">
                    <div class="form-group">
                      <label>Période</label>
                      <ng-select
                        [items]="periods"
                        [multiple]="false"
                        bindLabel="name"
                        notFoundText="Aucun résultat"
                        [selectableGroup]="true"
                        [closeOnSelect]="true"
                        bindValue="value"
                        placeholder="Sélectionnez une période"
                        [(ngModel)]="period"
                        (ngModelChange)="updatePeriodComparative($event)"
                        [ngModelOptions]="{ standalone: true }"
                      >
                        <ng-template
                          ng-option-tmp
                          let-item="item"
                          let-item$="item$"
                          let-index="index"
                          >{{ item.name }}</ng-template
                        >
                      </ng-select>
                    </div>
                  </div>
                  <!-- DATE FROM -->
                  <div class="col-6">
                    <div class="form-group">
                      <label for="dateFromComp">Du</label>
                      <input
                        type="date"
                        [(ngModel)]="dateFrom"
                        (ngModelChange)="updateDateComparative()"
                        class="form-control"
                        id="dateFromComp"
                        placeholder="Date du"
                        data-toggle="flatpickr"
                      />
                    </div>
                  </div>
                  <!-- DATE TO -->
                  <div class="col-6">
                    <div class="form-group">
                      <label for="dateToComp">Au</label>
                      <input
                        type="date"
                        [(ngModel)]="dateTo"
                        (ngModelChange)="updateDateComparative()"
                        class="form-control"
                        id="dateToComp"
                        placeholder="Date au"
                        data-toggle="flatpickr"
                      />
                    </div>
                  </div>
                  <!-- REGISTRIES -->
                  <div class="col-6" *ngIf="registries">
                    <div class="form-group">
                      <label>Registres</label>
                      <ng-select
                        [items]="registries"
                        [multiple]="false"
                        bindLabel="name"
                        notFoundText="Aucun résultat"
                        [selectableGroup]="true"
                        [closeOnSelect]="true"
                        bindValue="id"
                        placeholder="Sélectionnez un registre"
                        [(ngModel)]="selectedRegistry"
                        (ngModelChange)="updateRegistryComparative()"
                        [ngModelOptions]="{ standalone: true }"
                      >
                        <ng-template
                          ng-option-tmp
                          let-item="item"
                          let-item$="item$"
                          let-index="index"
                          >{{ item.name }}</ng-template
                        >
                      </ng-select>
                    </div>
                  </div>
                  <ng-container
                    *ngIf="selectedRegistry && selectedRegistry !== 5"
                  >
                    <!-- INSTALLATIONS -->
                    <div
                      class="col-6"
                      *ngIf="selectedRegistry && registryInstallations.length"
                    >
                      <div class="form-group">
                        <label>Installation</label>
                        <ng-select
                          [items]="registryInstallations"
                          [multiple]="true"
                          bindLabel="name"
                          notFoundText="Aucun résultat"
                          [selectableGroup]="true"
                          [closeOnSelect]="false"
                          bindValue="id"
                          placeholder="Sélectionnez une installation"
                          [(ngModel)]="selectedInstallation"
                          (ngModelChange)="updateInstallationComparative()"
                          [ngModelOptions]="{ standalone: true }"
                        >
                          <ng-template
                            ng-option-tmp
                            let-item="item"
                            let-item$="item$"
                            let-index="index"
                          >
                            <input
                              type="checkbox"
                              [ngModel]="item$.selected"
                              [ngModelOptions]="{ standalone: true }"
                            />
                            {{ item.name }}
                          </ng-template>
                        </ng-select>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container
                    *ngIf="selectedRegistry && selectedRegistry === 5"
                  >
                    <!-- INSTALLATIONS CUSTOMS -->
                    <div
                      class="col-6"
                      *ngIf="selectedRegistry && installationCustoms.length"
                    >
                      <div class="form-group">
                        <label>Installation</label>
                        <ng-select
                          [items]="installationCustoms"
                          [multiple]="true"
                          bindLabel="description"
                          notFoundText="Aucun résultat"
                          [selectableGroup]="true"
                          [closeOnSelect]="false"
                          bindValue="id"
                          placeholder="Sélectionnez une installation"
                          [(ngModel)]="selectedInstallationCustom"
                          (ngModelChange)="
                            updateInstallationCustomComparative()
                          "
                          [ngModelOptions]="{ standalone: true }"
                        >
                          <ng-template
                            ng-option-tmp
                            let-item="item"
                            let-item$="item$"
                            let-index="index"
                          >
                            <input
                              type="checkbox"
                              [ngModel]="item$.selected"
                              [ngModelOptions]="{ standalone: true }"
                            />
                            {{ item.description }}
                          </ng-template>
                        </ng-select>
                      </div>
                    </div>
                  </ng-container>
                  <!-- CLEAR FILTERS -->
                  <div
                    class="
                      col-12
                      d-flex
                      align-items-center
                      justify-content-center
                    "
                  >
                    <div class="btn-group">
                      <a
                        class="
                          btn btn-block btn-primary
                          mb-4
                          text-white text-truncate
                        "
                        (click)="resetComparativeFilters()"
                      >
                        Réinitialiser les filtres
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card-body">
                <div class="row">
                  <!-- GRAPH CARDS -->
                  <div class="col-12 d-flex align-items-center">
                    <div class="chart w-100">
                      <div
                        class="is-loading"
                        *ngIf="loaderObservationsComparativeChart"
                      ></div>
                      <canvas
                        [hidden]="loaderObservationsComparativeChart"
                        id="createObservationsComparative"
                        class="chart-canvas"
                      ></canvas>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-xl-6">
        <div class="card">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">
                <h4 class="card-header-title">Délais moyens de levées</h4>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="chart">
              <div
                class="is-loading"
                *ngIf="loaderObservationsAverageDays"
              ></div>
              <canvas
                [hidden]="loaderObservationsAverageDays"
                id="createObservationsAverageDays"
                class="chart-canvas"
              ></canvas>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-xl-6">
        <div class="card">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">
                <h4 class="card-header-title">Coûts moyens</h4>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="chart">
              <div
                class="is-loading"
                *ngIf="loaderObservationsAverageCosts"
              ></div>
              <canvas
                [hidden]="loaderObservationsAverageCosts"
                id="createObservationsAverageCosts"
                class="chart-canvas"
              ></canvas>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-xl-5">
        <div class="card">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">
                <h4 class="card-header-title">Vos bâtiments par type</h4>
              </div>
              <div class="col-auto mr-n3">
                <span class="text-muted"> Total : </span>
              </div>
              <div class="col-auto">
                {{ totalEstablishments }}
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="chart">
              <div class="is-loading" *ngIf="loaderEstablishmentsType"></div>
              <canvas
                [hidden]="loaderEstablishmentsType"
                id="establishmentsTypeChart"
                class="chart-canvas"
              ></canvas>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-xl-7">
        <div class="card">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">
                <h4 class="card-header-title">
                  Pourcentage de bâtiment par type de registre
                </h4>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="chart">
              <div class="is-loading" *ngIf="loaderRegistryByEnterprise"></div>
              <canvas
                [hidden]="loaderRegistryByEnterprise"
                id="registryByEnterpriseChart"
                class="chart-canvas"
              ></canvas>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12" *ngIf="false">
        <div class="card">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">
                <h4 class="card-header-title">Registres par domaine</h4>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="chart">
              <canvas id="registryChart" class="chart-canvas"></canvas>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-------------------------- MODAL FOR OPEN STATS -------------------------->
<!-------------------------- ALL OBSERVATIONS -------------------------->
<div
  class="modal fade"
  id="allObservations"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
>
  <div class="modal-dialog observation" role="document">
    <div class="modal-content modal-observation">
      <div class="modal-observation-header">
        <h4 class="modal-title">Nombre total d'observations</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="is-loading loader-modal-content p-5" *ngIf="loader"></div>
        <div
          class="table-responsive table-observations"
          *ngIf="allObservations.length && !loader"
        >
          <table class="table table-striped">
            <thead>
              <tr>
                <th
                  class="href"
                  (click)="filterAllObservations('Establishments.name')"
                >
                  <a class="text-muted">Établissement</a>
                  <span
                    class="fe fe-arrow-down"
                    *ngIf="
                      allObservationsSort === 'Establishments.name' &&
                      allObservationsOrder === 'asc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-up"
                    *ngIf="
                      allObservationsSort === 'Establishments.name' &&
                      allObservationsOrder === 'desc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-right"
                    *ngIf="allObservationsSort !== 'Establishments.name'"
                  ></span>
                </th>
                <th
                  class="href"
                  (click)="filterAllObservations('Reports.visit_date')"
                >
                  <a class="text-muted">Date du rapport</a>
                  <span
                    class="fe fe-arrow-down"
                    *ngIf="
                      allObservationsSort === 'Reports.visit_date' &&
                      allObservationsOrder === 'asc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-up"
                    *ngIf="
                      allObservationsSort === 'Reports.visit_date' &&
                      allObservationsOrder === 'desc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-right"
                    *ngIf="allObservationsSort !== 'Reports.visit_date'"
                  ></span>
                </th>
                <th>Nom de l'observation</th>
                <th>Observation levée ?</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr class="dropdown" *ngFor="let observation of allObservations">
                <td>{{ getEstablishmentNameObservation(observation) }}</td>
                <td>
                  {{
                    observation["report"] && observation["report"]["visit_date"]
                      ? (observation["report"]["visit_date"]
                        | date: "dd/MM/yyyy")
                      : ""
                  }}
                </td>
                <td>{{ observation["name"] }}</td>
                <td>
                  <span
                    class="label"
                    [ngClass]="{
                      'label-unactive': observation['state'] === false,
                      'label-active': observation['state'] === true
                    }"
                  >
                    {{ observation["state"] ? "Oui" : "Non" }}
                  </span>
                </td>
                <td class="d-flex flex-row-reverse">
                  <a
                    class="dropdown-ellipses dropdown-toggle href"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fe fe-more-vertical"></i>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a
                      class="dropdown-item href"
                      [routerLink]="[getUrlObservation(observation)]"
                    >
                      lien vers l'observation
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <app-pagination
            (returnEvent)="dispatchPaginationAll($event)"
            [count_elements]="allObservationsCount"
            [current_page]="allObservationsPage"
            [elements_length]="allObservations.length"
            [limit]="allObservationsLimit"
            [total_pages]="allObservationsPages"
            ngDefaultControl
          >
          </app-pagination>
        </div>
        <div
          class="table-responsive table-observations"
          *ngIf="!allObservations.length && !loader"
        >
          Aucun résultat
        </div>
      </div>
    </div>
  </div>
</div>
<!------------------------- OBSERVATIONS STATES ------------------------->
<div
  class="modal fade"
  id="observationsState"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
>
  <div class="modal-dialog observation" role="document">
    <div class="modal-content modal-observation">
      <div class="modal-observation-header">
        <h4 class="modal-title">Observations levées</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="is-loading loader-modal-content p-5" *ngIf="loader"></div>
        <div
          class="table-responsive table-observations"
          *ngIf="observationsState.length && !loader"
        >
          <table class="table table-striped">
            <thead>
              <tr>
                <th
                  class="href"
                  (click)="filterObservationsState('Establishments.name')"
                >
                  <a class="text-muted">Établissement</a>
                  <span
                    class="fe fe-arrow-down"
                    *ngIf="
                      observationsStateSort === 'Establishments.name' &&
                      observationsStateOrder === 'asc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-up"
                    *ngIf="
                      observationsStateSort === 'Establishments.name' &&
                      observationsStateOrder === 'desc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-right"
                    *ngIf="observationsStateSort !== 'Establishments.name'"
                  ></span>
                </th>
                <th
                  class="href"
                  (click)="filterObservationsState('Reports.visit_date')"
                >
                  <a class="text-muted">Date du rapport</a>
                  <span
                    class="fe fe-arrow-down"
                    *ngIf="
                      observationsStateSort === 'Reports.visit_date' &&
                      observationsStateOrder === 'asc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-up"
                    *ngIf="
                      observationsStateSort === 'Reports.visit_date' &&
                      observationsStateOrder === 'desc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-right"
                    *ngIf="observationsStateSort !== 'Reports.visit_date'"
                  ></span>
                </th>
                <th>Nom de l'observation</th>
                <th>Observation levée ?</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="dropdown"
                *ngFor="let observation of observationsState"
              >
                <td>{{ getEstablishmentNameObservation(observation) }}</td>
                <td>
                  {{
                    observation["report"] && observation["report"]["visit_date"]
                      ? (observation["report"]["visit_date"]
                        | date: "dd/MM/yyyy")
                      : ""
                  }}
                </td>
                <td>{{ observation["name"] }}</td>
                <td>
                  <span
                    class="label"
                    [ngClass]="{
                      'label-unactive': observation['state'] === false,
                      'label-active': observation['state'] === true
                    }"
                  >
                    {{ observation["state"] ? "Oui" : "Non" }}
                  </span>
                </td>
                <td class="d-flex flex-row-reverse">
                  <a
                    class="dropdown-ellipses dropdown-toggle href"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fe fe-more-vertical"></i>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a
                      class="dropdown-item href"
                      [routerLink]="[getUrlObservation(observation)]"
                    >
                      lien vers l'observation
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <app-pagination
            (returnEvent)="dispatchPaginationState($event)"
            [count_elements]="observationsStateCount"
            [current_page]="observationsStatePage"
            [elements_length]="observationsState.length"
            [limit]="observationsStateLimit"
            [total_pages]="observationsStatePages"
            ngDefaultControl
          >
          </app-pagination>
        </div>
        <div
          class="table-responsive table-observations"
          *ngIf="!observationsState.length && !loader"
        >
          Aucun résultat
        </div>
      </div>
    </div>
  </div>
</div>
<!------------------------- OBSERVATIONS NOT STATES ------------------------->
<div
  class="modal fade"
  id="observationsNotState"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
>
  <div class="modal-dialog observation" role="document">
    <div class="modal-content modal-observation">
      <div class="modal-observation-header">
        <h4 class="modal-title">Observations non levées</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="is-loading loader-modal-content p-5" *ngIf="loader"></div>
        <div
          class="table-responsive table-observations"
          *ngIf="observationsNotState.length && !loader"
        >
          <table class="table table-striped">
            <thead>
              <tr>
                <th
                  class="href"
                  (click)="filterObservationsNotState('Establishments.name')"
                >
                  <a class="text-muted">Établissement</a>
                  <span
                    class="fe fe-arrow-down"
                    *ngIf="
                      observationsNotStateSort === 'Establishments.name' &&
                      observationsNotStateOrder === 'asc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-up"
                    *ngIf="
                      observationsNotStateSort === 'Establishments.name' &&
                      observationsNotStateOrder === 'desc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-right"
                    *ngIf="observationsNotStateSort !== 'Establishments.name'"
                  ></span>
                </th>
                <th
                  class="href"
                  (click)="filterObservationsNotState('Reports.visit_date')"
                >
                  <a class="text-muted">Date du rapport</a>
                  <span
                    class="fe fe-arrow-down"
                    *ngIf="
                      observationsNotStateSort === 'Reports.visit_date' &&
                      observationsNotStateOrder === 'asc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-up"
                    *ngIf="
                      observationsNotStateSort === 'Reports.visit_date' &&
                      observationsNotStateOrder === 'desc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-right"
                    *ngIf="observationsNotStateSort !== 'Reports.visit_date'"
                  ></span>
                </th>
                <th>Nom de l'observation</th>
                <th>Observation levée ?</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="dropdown"
                *ngFor="let observation of observationsNotState"
              >
                <td>{{ getEstablishmentNameObservation(observation) }}</td>
                <td>
                  {{
                    observation["report"] && observation["report"]["visit_date"]
                      ? (observation["report"]["visit_date"]
                        | date: "dd/MM/yyyy")
                      : ""
                  }}
                </td>
                <td>{{ observation["name"] }}</td>
                <td>
                  <span
                    class="label"
                    [ngClass]="{
                      'label-unactive': observation['state'] === false,
                      'label-active': observation['state'] === true
                    }"
                  >
                    {{ observation["state"] ? "Oui" : "Non" }}
                  </span>
                </td>
                <td class="d-flex flex-row-reverse">
                  <a
                    class="dropdown-ellipses dropdown-toggle href"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fe fe-more-vertical"></i>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a
                      class="dropdown-item href"
                      [routerLink]="[getUrlObservation(observation)]"
                    >
                      lien vers l'observation
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <app-pagination
            (returnEvent)="dispatchPaginationNotState($event)"
            [count_elements]="observationsNotStateCount"
            [current_page]="observationsNotStatePage"
            [elements_length]="observationsNotState.length"
            [limit]="observationsNotStateLimit"
            [total_pages]="observationsNotStatePages"
            ngDefaultControl
          >
          </app-pagination>
        </div>
        <div
          class="table-responsive table-observations"
          *ngIf="!observationsNotState.length && !loader"
        >
          Aucun résultat
        </div>
      </div>
    </div>
  </div>
</div>
<!---------------------------------- REPORT ---------------------------------->
<div
  class="modal fade"
  id="allReports"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
>
  <div class="modal-dialog observation" role="document">
    <div class="modal-content modal-observation">
      <div class="modal-observation-header">
        <h4 class="modal-title">Rapports</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="is-loading loader-modal-content p-5" *ngIf="loader"></div>
        <div
          class="table-responsive table-observations"
          *ngIf="reports.length && !loader"
        >
          <table class="table table-striped">
            <thead>
              <tr>
                <th class="href" (click)="filterReports('Establishments.name')">
                  <a class="text-muted">Établissement</a>
                  <span
                    class="fe fe-arrow-down"
                    *ngIf="
                      reportsSort === 'Establishments.name' &&
                      reportsOrder === 'asc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-up"
                    *ngIf="
                      reportsSort === 'Establishments.name' &&
                      reportsOrder === 'desc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-right"
                    *ngIf="reportsSort !== 'Establishments.name'"
                  ></span>
                </th>
                <th class="href" (click)="filterReports('Reports.visit_date')">
                  <a class="text-muted">Date du rapport</a>
                  <span
                    class="fe fe-arrow-down"
                    *ngIf="
                      reportsSort === 'Reports.visit_date' &&
                      reportsOrder === 'asc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-up"
                    *ngIf="
                      reportsSort === 'Reports.visit_date' &&
                      reportsOrder === 'desc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-right"
                    *ngIf="reportsSort !== 'Reports.visit_date'"
                  ></span>
                </th>
                <th>Nom du rapport</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr class="dropdown" *ngFor="let report of reports">
                <td>{{ getEstablishmentNameReport(report) }}</td>
                <td>
                  {{
                    report["visit_date"]
                      ? (report["visit_date"] | date: "dd/MM/yyyy")
                      : ""
                  }}
                </td>
                <td>{{ report["establishment_checkpoint"]["full_name"] }}</td>
                <td class="d-flex flex-row-reverse">
                  <a
                    class="dropdown-ellipses dropdown-toggle href"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fe fe-more-vertical"></i>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a
                      class="dropdown-item href"
                      [routerLink]="[getUrlReport(report)]"
                    >
                      lien vers le rapport
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <app-pagination
            (returnEvent)="dispatchPaginationReport($event)"
            [count_elements]="reportsCount"
            [current_page]="reportsPage"
            [elements_length]="reports.length"
            [limit]="reportsLimit"
            [total_pages]="reportsPages"
            ngDefaultControl
          >
          </app-pagination>
        </div>
        <div
          class="table-responsive table-observations"
          *ngIf="!reports.length && !loader"
        >
          Aucun résultat
        </div>
      </div>
    </div>
  </div>
</div>
<!------------------------------ EMERGENCIES ------------------------------>
<!------------------------------ NC ------------------------------>
<div
  class="modal fade"
  id="observationsNC"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
>
  <div class="modal-dialog observation" role="document">
    <div class="modal-content modal-observation">
      <div class="modal-observation-header">
        <h4 class="modal-title">
          Observations degrée d'urgence non communiqué
        </h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="is-loading loader-modal-content p-5" *ngIf="loader"></div>
        <div
          class="table-responsive table-observations"
          *ngIf="observationsNC.length && !loader"
        >
          <table class="table table-striped">
            <thead>
              <tr>
                <th
                  class="href"
                  (click)="filterObservationsNC('Establishments.name')"
                >
                  <a class="text-muted">Établissement</a>
                  <span
                    class="fe fe-arrow-down"
                    *ngIf="
                      observationsNCSort === 'Establishments.name' &&
                      observationsNCOrder === 'asc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-up"
                    *ngIf="
                      observationsNCSort === 'Establishments.name' &&
                      observationsNCOrder === 'desc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-right"
                    *ngIf="observationsNCSort !== 'Establishments.name'"
                  ></span>
                </th>
                <th
                  class="href"
                  (click)="filterObservationsNC('Reports.visit_date')"
                >
                  <a class="text-muted">Date du rapport</a>
                  <span
                    class="fe fe-arrow-down"
                    *ngIf="
                      observationsNCSort === 'Reports.visit_date' &&
                      observationsNCOrder === 'asc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-up"
                    *ngIf="
                      observationsNCSort === 'Reports.visit_date' &&
                      observationsNCOrder === 'desc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-right"
                    *ngIf="observationsNCSort !== 'Reports.visit_date'"
                  ></span>
                </th>
                <th>Nom de l'observation</th>
                <th>Observation levée ?</th>
                <th>Degrée d'urgence</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr class="dropdown" *ngFor="let observation of observationsNC">
                <td>{{ getEstablishmentNameObservation(observation) }}</td>
                <td>
                  {{
                    observation["report"] && observation["report"]["visit_date"]
                      ? (observation["report"]["visit_date"]
                        | date: "dd/MM/yyyy")
                      : ""
                  }}
                </td>
                <td>{{ observation["name"] }}</td>
                <td>
                  <span
                    class="label"
                    [ngClass]="{
                      'label-unactive': observation['state'] === false,
                      'label-active': observation['state'] === true
                    }"
                  >
                    {{ observation["state"] ? "Oui" : "Non" }}
                  </span>
                </td>
                <td>
                  {{
                    observation["emergencies"] === null ? "Non communiqué" : ""
                  }}
                </td>
                <td class="d-flex flex-row-reverse">
                  <a
                    class="dropdown-ellipses dropdown-toggle href"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fe fe-more-vertical"></i>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a
                      class="dropdown-item href"
                      [routerLink]="[getUrlObservation(observation)]"
                    >
                      lien vers l'observation
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <app-pagination
            (returnEvent)="dispatchPaginationNC($event)"
            [count_elements]="observationsNCCount"
            [current_page]="observationsNCPage"
            [elements_length]="observationsNC.length"
            [limit]="observationsNCLimit"
            [total_pages]="observationsNCPages"
            ngDefaultControl
          >
          </app-pagination>
        </div>
        <div
          class="table-responsive table-observations"
          *ngIf="!observationsNC.length && !loader"
        >
          Aucun résultat
        </div>
      </div>
    </div>
  </div>
</div>
<!------------------------------ LOW ------------------------------>
<div
  class="modal fade"
  id="observationsLow"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
>
  <div class="modal-dialog observation" role="document">
    <div class="modal-content modal-observation">
      <div class="modal-observation-header">
        <h4 class="modal-title">Observations degrée d'urgence bas</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="is-loading loader-modal-content p-5" *ngIf="loader"></div>
        <div
          class="table-responsive table-observations"
          *ngIf="observationsLow.length && !loader"
        >
          <table class="table table-striped">
            <thead>
              <tr>
                <th
                  class="href"
                  (click)="filterObservationsLow('Establishments.name')"
                >
                  <a class="text-muted">Établissement</a>
                  <span
                    class="fe fe-arrow-down"
                    *ngIf="
                      observationsLowSort === 'Establishments.name' &&
                      observationsLowOrder === 'asc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-up"
                    *ngIf="
                      observationsLowSort === 'Establishments.name' &&
                      observationsLowOrder === 'desc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-right"
                    *ngIf="observationsLowSort !== 'Establishments.name'"
                  ></span>
                </th>
                <th
                  class="href"
                  (click)="filterObservationsLow('Reports.visit_date')"
                >
                  <a class="text-muted">Date du rapport</a>
                  <span
                    class="fe fe-arrow-down"
                    *ngIf="
                      observationsLowSort === 'Reports.visit_date' &&
                      observationsLowOrder === 'asc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-up"
                    *ngIf="
                      observationsLowSort === 'Reports.visit_date' &&
                      observationsLowOrder === 'desc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-right"
                    *ngIf="observationsLowSort !== 'Reports.visit_date'"
                  ></span>
                </th>
                <th>Nom de l'observation</th>
                <th>Observation levée ?</th>
                <th>Degrée d'urgence</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr class="dropdown" *ngFor="let observation of observationsLow">
                <td>{{ getEstablishmentNameObservation(observation) }}</td>
                <td>
                  {{
                    observation["report"] && observation["report"]["visit_date"]
                      ? (observation["report"]["visit_date"]
                        | date: "dd/MM/yyyy")
                      : ""
                  }}
                </td>
                <td>{{ observation["name"] }}</td>
                <td>
                  <span
                    class="label"
                    [ngClass]="{
                      'label-unactive': observation['state'] === false,
                      'label-active': observation['state'] === true
                    }"
                  >
                    {{ observation["state"] ? "Oui" : "Non" }}
                  </span>
                </td>
                <td>{{ observation["emergencies"] === 0 ? "Bas" : "" }}</td>
                <td class="d-flex flex-row-reverse">
                  <a
                    class="dropdown-ellipses dropdown-toggle href"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fe fe-more-vertical"></i>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a
                      class="dropdown-item href"
                      [routerLink]="[getUrlObservation(observation)]"
                    >
                      lien vers l'observation
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <app-pagination
            (returnEvent)="dispatchPaginationLow($event)"
            [count_elements]="observationsLowCount"
            [current_page]="observationsLowPage"
            [elements_length]="observationsLow.length"
            [limit]="observationsLowLimit"
            [total_pages]="observationsLowPages"
            ngDefaultControl
          >
          </app-pagination>
        </div>
        <div
          class="table-responsive table-observations"
          *ngIf="!observationsLow.length && !loader"
        >
          Aucun résultat
        </div>
      </div>
    </div>
  </div>
</div>
<!------------------------------ NORMAL ------------------------------>
<div
  class="modal fade"
  id="observationsNormal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
>
  <div class="modal-dialog observation" role="document">
    <div class="modal-content modal-observation">
      <div class="modal-observation-header">
        <h4 class="modal-title">Observations degrée d'urgence normal</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="is-loading loader-modal-content p-5" *ngIf="loader"></div>
        <div
          class="table-responsive table-observations"
          *ngIf="observationsNormal.length && !loader"
        >
          <table class="table table-striped">
            <thead>
              <tr>
                <th
                  class="href"
                  (click)="filterObservationsNormal('Establishments.name')"
                >
                  <a class="text-muted">Établissement</a>
                  <span
                    class="fe fe-arrow-down"
                    *ngIf="
                      observationsNormalSort === 'Establishments.name' &&
                      observationsNormalOrder === 'asc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-up"
                    *ngIf="
                      observationsNormalSort === 'Establishments.name' &&
                      observationsNormalOrder === 'desc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-right"
                    *ngIf="observationsNormalSort !== 'Establishments.name'"
                  ></span>
                </th>
                <th
                  class="href"
                  (click)="filterObservationsNormal('Reports.visit_date')"
                >
                  <a class="text-muted">Date du rapport</a>
                  <span
                    class="fe fe-arrow-down"
                    *ngIf="
                      observationsNormalSort === 'Reports.visit_date' &&
                      observationsNormalOrder === 'asc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-up"
                    *ngIf="
                      observationsNormalSort === 'Reports.visit_date' &&
                      observationsNormalOrder === 'desc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-right"
                    *ngIf="observationsNormalSort !== 'Reports.visit_date'"
                  ></span>
                </th>
                <th>Nom de l'observation</th>
                <th>Observation levée ?</th>
                <th>Degrée d'urgence</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="dropdown"
                *ngFor="let observation of observationsNormal"
              >
                <td>{{ getEstablishmentNameObservation(observation) }}</td>
                <td>
                  {{
                    observation["report"] && observation["report"]["visit_date"]
                      ? (observation["report"]["visit_date"]
                        | date: "dd/MM/yyyy")
                      : ""
                  }}
                </td>
                <td>{{ observation["name"] }}</td>
                <td>
                  <span
                    class="label"
                    [ngClass]="{
                      'label-unactive': observation['state'] === false,
                      'label-active': observation['state'] === true
                    }"
                  >
                    {{ observation["state"] ? "Oui" : "Non" }}
                  </span>
                </td>
                <td>{{ observation["emergencies"] === 1 ? "Normal" : "" }}</td>
                <td class="d-flex flex-row-reverse">
                  <a
                    class="dropdown-ellipses dropdown-toggle href"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fe fe-more-vertical"></i>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a
                      class="dropdown-item href"
                      [routerLink]="[getUrlObservation(observation)]"
                    >
                      lien vers l'observation
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <app-pagination
            (returnEvent)="dispatchPaginationNormal($event)"
            [count_elements]="observationsNormalCount"
            [current_page]="observationsNormalPage"
            [elements_length]="observationsNormal.length"
            [limit]="observationsNormalLimit"
            [total_pages]="observationsNormalPages"
            ngDefaultControl
          >
          </app-pagination>
        </div>
        <div
          class="table-responsive table-observations"
          *ngIf="!observationsNormal.length && !loader"
        >
          Aucun résultat
        </div>
      </div>
    </div>
  </div>
</div>
<!------------------------------ HIGHER ------------------------------>
<div
  class="modal fade"
  id="observationsHigher"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
>
  <div class="modal-dialog observation" role="document">
    <div class="modal-content modal-observation">
      <div class="modal-observation-header">
        <h4 class="modal-title">Observations degrée d'urgence haut</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="is-loading loader-modal-content p-5" *ngIf="loader"></div>
        <div
          class="table-responsive table-observations"
          *ngIf="observationsHigher.length && !loader"
        >
          <table class="table table-striped">
            <thead>
              <tr>
                <th
                  class="href"
                  (click)="filterObservationsHigher('Establishments.name')"
                >
                  <a class="text-muted">Établissement</a>
                  <span
                    class="fe fe-arrow-down"
                    *ngIf="
                      observationsHigherSort === 'Establishments.name' &&
                      observationsHigherOrder === 'asc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-up"
                    *ngIf="
                      observationsHigherSort === 'Establishments.name' &&
                      observationsHigherOrder === 'desc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-right"
                    *ngIf="observationsHigherSort !== 'Establishments.name'"
                  ></span>
                </th>
                <th
                  class="href"
                  (click)="filterObservationsHigher('Reports.visit_date')"
                >
                  <a class="text-muted">Date du rapport</a>
                  <span
                    class="fe fe-arrow-down"
                    *ngIf="
                      observationsHigherSort === 'Reports.visit_date' &&
                      observationsHigherOrder === 'asc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-up"
                    *ngIf="
                      observationsHigherSort === 'Reports.visit_date' &&
                      observationsHigherOrder === 'desc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-right"
                    *ngIf="observationsHigherSort !== 'Reports.visit_date'"
                  ></span>
                </th>
                <th>Nom de l'observation</th>
                <th>Observation levée ?</th>
                <th>Degrée d'urgence</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="dropdown"
                *ngFor="let observation of observationsHigher"
              >
                <td>{{ getEstablishmentNameObservation(observation) }}</td>
                <td>
                  {{
                    observation["report"] && observation["report"]["visit_date"]
                      ? (observation["report"]["visit_date"]
                        | date: "dd/MM/yyyy")
                      : ""
                  }}
                </td>
                <td>{{ observation["name"] }}</td>
                <td>
                  <span
                    class="label"
                    [ngClass]="{
                      'label-unactive': observation['state'] === false,
                      'label-active': observation['state'] === true
                    }"
                  >
                    {{ observation["state"] ? "Oui" : "Non" }}
                  </span>
                </td>
                <td>{{ observation["emergencies"] === 2 ? "Haut" : "" }}</td>
                <td class="d-flex flex-row-reverse">
                  <a
                    class="dropdown-ellipses dropdown-toggle href"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fe fe-more-vertical"></i>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a
                      class="dropdown-item href"
                      [routerLink]="[getUrlObservation(observation)]"
                    >
                      lien vers l'observation
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <app-pagination
            (returnEvent)="dispatchPaginationHigher($event)"
            [count_elements]="observationsHigherCount"
            [current_page]="observationsHigherPage"
            [elements_length]="observationsHigher.length"
            [limit]="observationsHigherLimit"
            [total_pages]="observationsHigherPages"
            ngDefaultControl
          >
          </app-pagination>
        </div>
        <div
          class="table-responsive table-observations"
          *ngIf="!observationsHigher.length && !loader"
        >
          Aucun résultat
        </div>
      </div>
    </div>
  </div>
</div>
<!------------------------------ PRIORITY ------------------------------>
<div
  class="modal fade"
  id="observationsPriority"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
>
  <div class="modal-dialog observation" role="document">
    <div class="modal-content modal-observation">
      <div class="modal-observation-header">
        <h4 class="modal-title">Observations degrée d'urgence prioritaire</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="is-loading loader-modal-content p-5" *ngIf="loader"></div>
        <div
          class="table-responsive table-observations"
          *ngIf="observationsPriority.length && !loader"
        >
          <table class="table table-striped">
            <thead>
              <tr>
                <th
                  class="href"
                  (click)="filterObservationsPriority('Establishments.name')"
                >
                  <a class="text-muted">Établissement</a>
                  <span
                    class="fe fe-arrow-down"
                    *ngIf="
                      observationsPrioritySort === 'Establishments.name' &&
                      observationsPriorityOrder === 'asc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-up"
                    *ngIf="
                      observationsPrioritySort === 'Establishments.name' &&
                      observationsPriorityOrder === 'desc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-right"
                    *ngIf="observationsPrioritySort !== 'Establishments.name'"
                  ></span>
                </th>
                <th
                  class="href"
                  (click)="filterObservationsPriority('Reports.visit_date')"
                >
                  <a class="text-muted">Date du rapport</a>
                  <span
                    class="fe fe-arrow-down"
                    *ngIf="
                      observationsPrioritySort === 'Reports.visit_date' &&
                      observationsPriorityOrder === 'asc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-up"
                    *ngIf="
                      observationsPrioritySort === 'Reports.visit_date' &&
                      observationsPriorityOrder === 'desc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-right"
                    *ngIf="observationsPrioritySort !== 'Reports.visit_date'"
                  ></span>
                </th>
                <th>Nom de l'observation</th>
                <th>Observation levée ?</th>
                <th>Degrée d'urgence</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="dropdown"
                *ngFor="let observation of observationsPriority"
              >
                <td>{{ getEstablishmentNameObservation(observation) }}</td>
                <td>
                  {{
                    observation["report"] && observation["report"]["visit_date"]
                      ? (observation["report"]["visit_date"]
                        | date: "dd/MM/yyyy")
                      : ""
                  }}
                </td>
                <td>{{ observation["name"] }}</td>
                <td>
                  <span
                    class="label"
                    [ngClass]="{
                      'label-unactive': observation['state'] === false,
                      'label-active': observation['state'] === true
                    }"
                  >
                    {{ observation["state"] ? "Oui" : "Non" }}
                  </span>
                </td>
                <td>
                  {{ observation["emergencies"] === 3 ? "Prioritaire" : "" }}
                </td>
                <td class="d-flex flex-row-reverse">
                  <a
                    class="dropdown-ellipses dropdown-toggle href"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fe fe-more-vertical"></i>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a
                      class="dropdown-item href"
                      [routerLink]="[getUrlObservation(observation)]"
                    >
                      lien vers l'observation
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <app-pagination
            (returnEvent)="dispatchPaginationPriority($event)"
            [count_elements]="observationsPriorityCount"
            [current_page]="observationsPriorityPage"
            [elements_length]="observationsPriority.length"
            [limit]="observationsPriorityLimit"
            [total_pages]="observationsPriorityPages"
            ngDefaultControl
          >
          </app-pagination>
        </div>
        <div
          class="table-responsive table-observations"
          *ngIf="!observationsPriority.length && !loader"
        >
          Aucun résultat
        </div>
      </div>
    </div>
  </div>
</div>
<!------------------------------ DELAY ------------------------------>
<!------------------------------ LATE ------------------------------>
<div
  class="modal fade"
  id="observationsLate"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
>
  <div class="modal-dialog observation" role="document">
    <div class="modal-content modal-observation">
      <div class="modal-observation-header">
        <h4 class="modal-title">Nombre d'observation levées en retard</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="is-loading loader-modal-content p-5" *ngIf="loader"></div>
        <div
          class="table-responsive table-observations"
          *ngIf="observationsLate.length && !loader"
        >
          <table class="table table-striped">
            <thead>
              <tr>
                <th
                  class="href"
                  (click)="filterObservationsLate('Establishments.name')"
                >
                  <a class="text-muted">Établissement</a>
                  <span
                    class="fe fe-arrow-down"
                    *ngIf="
                      observationsLateSort === 'Establishments.name' &&
                      observationsLateOrder === 'asc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-up"
                    *ngIf="
                      observationsLateSort === 'Establishments.name' &&
                      observationsLateOrder === 'desc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-right"
                    *ngIf="observationsLateSort !== 'Establishments.name'"
                  ></span>
                </th>
                <th
                  class="href"
                  (click)="filterObservationsLate('Reports.visit_date')"
                >
                  <a class="text-muted">Date du rapport</a>
                  <span
                    class="fe fe-arrow-down"
                    *ngIf="
                      observationsLateSort === 'Reports.visit_date' &&
                      observationsLateOrder === 'asc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-up"
                    *ngIf="
                      observationsLateSort === 'Reports.visit_date' &&
                      observationsLateOrder === 'desc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-right"
                    *ngIf="observationsLateSort !== 'Reports.visit_date'"
                  ></span>
                </th>
                <th>Date de levée</th>
                <th>Nom de l'observation</th>
                <th>Deadline</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr class="dropdown" *ngFor="let observation of observationsLate">
                <td>{{ getEstablishmentNameObservation(observation) }}</td>
                <td>
                  {{
                    observation["report"] && observation["report"]["visit_date"]
                      ? (observation["report"]["visit_date"]
                        | date: "dd/MM/yyyy")
                      : ""
                  }}
                </td>
                <td>
                  {{
                    observation["date_changing_state"]
                      ? (observation["date_changing_state"]
                        | date: "dd/MM/yyyy")
                      : ""
                  }}
                </td>
                <td>{{ observation["name"] }}</td>
                <td>
                  {{
                    observation["deadline"]
                      ? (observation["deadline"] | date: "dd/MM/yyyy")
                      : ""
                  }}
                </td>
                <td class="d-flex flex-row-reverse">
                  <a
                    class="dropdown-ellipses dropdown-toggle href"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fe fe-more-vertical"></i>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a
                      class="dropdown-item href"
                      [routerLink]="[getUrlObservation(observation)]"
                    >
                      lien vers l'observation
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <app-pagination
            (returnEvent)="dispatchPaginationLate($event)"
            [count_elements]="observationsLateCount"
            [current_page]="observationsLatePage"
            [elements_length]="observationsLate.length"
            [limit]="observationsLateLimit"
            [total_pages]="observationsLatePages"
            ngDefaultControl
          >
          </app-pagination>
        </div>
        <div
          class="table-responsive table-observations"
          *ngIf="!observationsLate.length && !loader"
        >
          Aucun résultat
        </div>
      </div>
    </div>
  </div>
</div>
<!------------------------------ IN TIME ------------------------------>
<div
  class="modal fade"
  id="observationsInTime"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
>
  <div class="modal-dialog observation" role="document">
    <div class="modal-content modal-observation">
      <div class="modal-observation-header">
        <h4 class="modal-title">Nombre d'observation levées à temps</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="is-loading loader-modal-content p-5" *ngIf="loader"></div>
        <div
          class="table-responsive table-observations"
          *ngIf="observationsInTime.length && !loader"
        >
          <table class="table table-striped">
            <thead>
              <tr>
                <th
                  class="href"
                  (click)="filterObservationsInTime('Establishments.name')"
                >
                  <a class="text-muted">Établissement</a>
                  <span
                    class="fe fe-arrow-down"
                    *ngIf="
                      observationsInTimeSort === 'Establishments.name' &&
                      observationsInTimeOrder === 'asc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-up"
                    *ngIf="
                      observationsInTimeSort === 'Establishments.name' &&
                      observationsInTimeOrder === 'desc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-right"
                    *ngIf="observationsInTimeSort !== 'Establishments.name'"
                  ></span>
                </th>
                <th
                  class="href"
                  (click)="filterObservationsInTime('Reports.visit_date')"
                >
                  <a class="text-muted">Date du rapport</a>
                  <span
                    class="fe fe-arrow-down"
                    *ngIf="
                      observationsInTimeSort === 'Reports.visit_date' &&
                      observationsInTimeOrder === 'asc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-up"
                    *ngIf="
                      observationsInTimeSort === 'Reports.visit_date' &&
                      observationsInTimeOrder === 'desc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-right"
                    *ngIf="observationsInTimeSort !== 'Reports.visit_date'"
                  ></span>
                </th>
                <th>Date de levée</th>
                <th>Nom de l'observation</th>
                <th>Deadline</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="dropdown"
                *ngFor="let observation of observationsInTime"
              >
                <td>{{ getEstablishmentNameObservation(observation) }}</td>
                <td>
                  {{
                    observation["report"] && observation["report"]["visit_date"]
                      ? (observation["report"]["visit_date"]
                        | date: "dd/MM/yyyy")
                      : ""
                  }}
                </td>
                <td>
                  {{
                    observation["date_changing_state"]
                      ? (observation["date_changing_state"]
                        | date: "dd/MM/yyyy")
                      : ""
                  }}
                </td>
                <td>{{ observation["name"] }}</td>
                <td>
                  {{
                    observation["deadline"]
                      ? (observation["deadline"] | date: "dd/MM/yyyy")
                      : ""
                  }}
                </td>
                <td class="d-flex flex-row-reverse">
                  <a
                    class="dropdown-ellipses dropdown-toggle href"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fe fe-more-vertical"></i>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a
                      class="dropdown-item href"
                      [routerLink]="[getUrlObservation(observation)]"
                    >
                      lien vers l'observation
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <app-pagination
            (returnEvent)="dispatchPaginationInTime($event)"
            [count_elements]="observationsInTimeCount"
            [current_page]="observationsInTimePage"
            [elements_length]="observationsInTime.length"
            [limit]="observationsInTimeLimit"
            [total_pages]="observationsInTimePages"
            ngDefaultControl
          >
          </app-pagination>
        </div>
        <div
          class="table-responsive table-observations"
          *ngIf="!observationsInTime.length && !loader"
        >
          Aucun résultat
        </div>
      </div>
    </div>
  </div>
</div>
<!------------------------------ CHECKPOINTS ------------------------------>
<div
  class="modal fade"
  id="observationsAllCheckpoints"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
>
  <div class="modal-dialog observation" role="document">
    <div class="modal-content modal-observation">
      <div class="modal-observation-header">
        <h4 class="modal-title">Points de contrôle</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="is-loading loader-modal-content p-5" *ngIf="loader"></div>
        <div
          class="table-responsive table-observations"
          *ngIf="observationsAllCheckpoints.length && !loader"
        >
          <table class="table table-striped">
            <thead>
              <tr>
                <th
                  class="href"
                  (click)="
                    filterObservationsAllCheckpoints('Establishments.name')
                  "
                >
                  <a class="text-muted">Établissement</a>
                  <span
                    class="fe fe-arrow-down"
                    *ngIf="
                      observationsAllCheckpointsSort ===
                        'Establishments.name' &&
                      observationsAllCheckpointsOrder === 'asc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-up"
                    *ngIf="
                      observationsAllCheckpointsSort ===
                        'Establishments.name' &&
                      observationsAllCheckpointsOrder === 'desc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-right"
                    *ngIf="
                      observationsAllCheckpointsSort !== 'Establishments.name'
                    "
                  ></span>
                </th>
                <th>Nom du point de contrôle</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="dropdown"
                *ngFor="let checkpoint of observationsAllCheckpoints"
              >
                <td>{{ getEstablishmentNameCheckpoints(checkpoint) }}</td>
                <td>{{ checkpoint["full_name"] }}</td>
                <td class="d-flex flex-row-reverse">
                  <a
                    class="dropdown-ellipses dropdown-toggle href"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fe fe-more-vertical"></i>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a
                      class="dropdown-item href"
                      [routerLink]="[getUrlCheckpoint(checkpoint)]"
                    >
                      lien vers le point de contrôle
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <app-pagination
            (returnEvent)="dispatchPaginationCheckpoints($event)"
            [count_elements]="observationsAllCheckpointsCount"
            [current_page]="observationsAllCheckpointsPage"
            [elements_length]="observationsAllCheckpoints.length"
            [limit]="observationsAllCheckpointsLimit"
            [total_pages]="observationsAllCheckpointsPages"
            ngDefaultControl
          >
          </app-pagination>
        </div>
        <div
          class="table-responsive table-observations"
          *ngIf="!observationsAllCheckpoints.length && !loader"
        >
          Aucun résultat
        </div>
      </div>
    </div>
  </div>
</div>
<!------------------------------ CHECKPOINTS LATE ------------------------------>
<div
  class="modal fade"
  id="observationsCheckpointsLate"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
>
  <div class="modal-dialog observation" role="document">
    <div class="modal-content modal-observation">
      <div class="modal-observation-header">
        <h4 class="modal-title">Nombre de points de contrôle en retard</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="is-loading loader-modal-content p-5" *ngIf="loader"></div>
        <div
          class="table-responsive table-observations"
          *ngIf="observationsCheckpointsLate.length && !loader"
        >
          <table class="table table-striped">
            <thead>
              <tr>
                <th
                  class="href"
                  (click)="
                    filterObservationsCheckpointsLate('Establishments.name')
                  "
                >
                  <a class="text-muted">Établissement</a>
                  <span
                    class="fe fe-arrow-down"
                    *ngIf="
                      observationsCheckpointsLateSort ===
                        'Establishments.name' &&
                      observationsCheckpointsLateOrder === 'asc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-up"
                    *ngIf="
                      observationsCheckpointsLateSort ===
                        'Establishments.name' &&
                      observationsCheckpointsLateOrder === 'desc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-right"
                    *ngIf="
                      observationsCheckpointsLateSort !== 'Establishments.name'
                    "
                  ></span>
                </th>
                <th>Nom du point de contrôle</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="dropdown"
                *ngFor="let checkpoint of observationsCheckpointsLate"
              >
                <td>{{ getEstablishmentNameCheckpoints(checkpoint) }}</td>
                <td>{{ checkpoint["full_name"] }}</td>
                <td class="d-flex flex-row-reverse">
                  <a
                    class="dropdown-ellipses dropdown-toggle href"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fe fe-more-vertical"></i>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a
                      class="dropdown-item href"
                      [routerLink]="[getUrlCheckpoint(checkpoint)]"
                    >
                      lien vers le point de contrôle
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <app-pagination
            (returnEvent)="dispatchPaginationCheckpointsLate($event)"
            [count_elements]="observationsCheckpointsLateCount"
            [current_page]="observationsCheckpointsLatePage"
            [elements_length]="observationsCheckpointsLate.length"
            [limit]="observationsCheckpointsLateLimit"
            [total_pages]="observationsCheckpointsLatePages"
            ngDefaultControl
          >
          </app-pagination>
        </div>
        <div
          class="table-responsive table-observations"
          *ngIf="!observationsCheckpointsLate.length && !loader"
        >
          Aucun résultat
        </div>
      </div>
    </div>
  </div>
</div>
<!------------------------------ BY INSTALLATION ------------------------------>
<div
  class="modal fade"
  id="observationsInstallation"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
>
  <div class="modal-dialog observation" role="document">
    <div class="modal-content modal-observation">
      <div class="modal-observation-header">
        <h4 class="modal-title" *ngIf="allObsInstallation.length">
          observations de l'installation : {{ installationName }}
        </h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="is-loading loader-modal-content p-5" *ngIf="loader"></div>
        <div
          class="table-responsive table-observations"
          *ngIf="allObsInstallation.length && !loader"
        >
          <table class="table table-striped">
            <thead>
              <tr>
                <th
                  class="href"
                  (click)="filterAllObsInstallation('Establishments.name')"
                >
                  <a class="text-muted">Établissement</a>
                  <span
                    class="fe fe-arrow-down"
                    *ngIf="
                      allObsInstallationSort === 'Establishments.name' &&
                      allObsInstallationOrder === 'asc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-up"
                    *ngIf="
                      allObsInstallationSort === 'Establishments.name' &&
                      allObsInstallationOrder === 'desc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-right"
                    *ngIf="allObsInstallationSort !== 'Establishments.name'"
                  ></span>
                </th>
                <th
                  class="href"
                  (click)="filterAllObsInstallation('Reports.visit_date')"
                >
                  <a class="text-muted">Date du rapport</a>
                  <span
                    class="fe fe-arrow-down"
                    *ngIf="
                      allObsInstallationSort === 'Reports.visit_date' &&
                      allObsInstallationOrder === 'asc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-up"
                    *ngIf="
                      allObsInstallationSort === 'Reports.visit_date' &&
                      allObsInstallationOrder === 'desc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-right"
                    *ngIf="allObsInstallationSort !== 'Reports.visit_date'"
                  ></span>
                </th>
                <th>Date de levée</th>
                <th>Nom de l'observation</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="dropdown"
                *ngFor="let observation of allObsInstallation"
              >
                <td>{{ getEstablishmentNameObservation(observation) }}</td>
                <td>
                  {{
                    observation["report"] && observation["report"]["visit_date"]
                      ? (observation["report"]["visit_date"]
                        | date: "dd/MM/yyyy")
                      : ""
                  }}
                </td>
                <td>
                  {{
                    observation["date_changing_state"]
                      ? (observation["date_changing_state"]
                        | date: "dd/MM/yyyy")
                      : ""
                  }}
                </td>
                <td>{{ observation["name"] }}</td>
                <td class="d-flex flex-row-reverse">
                  <a
                    class="dropdown-ellipses dropdown-toggle href"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fe fe-more-vertical"></i>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a
                      class="dropdown-item href"
                      [routerLink]="[getUrlObservation(observation)]"
                    >
                      lien vers l'observation
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <app-pagination
            (returnEvent)="dispatchPaginationAllObsInstallation($event)"
            [count_elements]="allObsInstallationCount"
            [current_page]="allObsInstallationPage"
            [elements_length]="allObsInstallation.length"
            [limit]="allObsInstallationLimit"
            [total_pages]="allObsInstallationPages"
            ngDefaultControl
          >
          </app-pagination>
        </div>
        <div
          class="table-responsive table-observations"
          *ngIf="!allObsInstallation.length && !loader"
        >
          Aucun résultat
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="modal fade"
  id="observationsInstallationState"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
>
  <div class="modal-dialog observation" role="document">
    <div class="modal-content modal-observation">
      <div class="modal-observation-header">
        <h4 class="modal-title" *ngIf="allObsStateInstallation.length">
          observations levées de l'installation : {{ installationName }}
        </h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="is-loading loader-modal-content p-5" *ngIf="loader"></div>
        <div
          class="table-responsive table-observations"
          *ngIf="allObsStateInstallation.length && !loader"
        >
          <table class="table table-striped">
            <thead>
              <tr>
                <th
                  class="href"
                  (click)="filterObsStateInstallation('Establishments.name')"
                >
                  <a class="text-muted">Établissement</a>
                  <span
                    class="fe fe-arrow-down"
                    *ngIf="
                      allObsStateInstallationSort === 'Establishments.name' &&
                      allObsStateInstallationOrder === 'asc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-up"
                    *ngIf="
                      allObsStateInstallationSort === 'Establishments.name' &&
                      allObsStateInstallationOrder === 'desc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-right"
                    *ngIf="
                      allObsStateInstallationSort !== 'Establishments.name'
                    "
                  ></span>
                </th>
                <th
                  class="href"
                  (click)="filterObsStateInstallation('Reports.visit_date')"
                >
                  <a class="text-muted">Date du rapport</a>
                  <span
                    class="fe fe-arrow-down"
                    *ngIf="
                      allObsStateInstallationSort === 'Reports.visit_date' &&
                      allObsStateInstallationOrder === 'asc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-up"
                    *ngIf="
                      allObsStateInstallationSort === 'Reports.visit_date' &&
                      allObsStateInstallationOrder === 'desc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-right"
                    *ngIf="allObsStateInstallationSort !== 'Reports.visit_date'"
                  ></span>
                </th>
                <th>Date de levée</th>
                <th>Nom de l'observation</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="dropdown"
                *ngFor="let observation of allObsStateInstallation"
              >
                <td>{{ getEstablishmentNameObservation(observation) }}</td>
                <td>
                  {{
                    observation["report"] && observation["report"]["visit_date"]
                      ? (observation["report"]["visit_date"]
                        | date: "dd/MM/yyyy")
                      : ""
                  }}
                </td>
                <td>
                  {{
                    observation["date_changing_state"]
                      ? (observation["date_changing_state"]
                        | date: "dd/MM/yyyy")
                      : ""
                  }}
                </td>
                <td>{{ observation["name"] }}</td>
                <td class="d-flex flex-row-reverse">
                  <a
                    class="dropdown-ellipses dropdown-toggle href"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fe fe-more-vertical"></i>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a
                      class="dropdown-item href"
                      [routerLink]="[getUrlObservation(observation)]"
                    >
                      lien vers l'observation
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <app-pagination
            (returnEvent)="dispatchPaginationObsStateInstallation($event)"
            [count_elements]="allObsStateInstallationCount"
            [current_page]="allObsStateInstallationPage"
            [elements_length]="allObsStateInstallation.length"
            [limit]="allObsStateInstallationLimit"
            [total_pages]="allObsStateInstallationPages"
            ngDefaultControl
          >
          </app-pagination>
        </div>
        <div
          class="table-responsive table-observations"
          *ngIf="!allObsStateInstallation.length && !loader"
        >
          Aucun résultat
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="modal fade"
  id="observationsInstallationNotState"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
>
  <div class="modal-dialog observation" role="document">
    <div class="modal-content modal-observation">
      <div class="modal-observation-header">
        <h4 class="modal-title" *ngIf="allObsNotStateInstallation.length">
          observation non levées de l'installation : {{ installationName }}
        </h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="is-loading loader-modal-content p-5" *ngIf="loader"></div>
        <div
          class="table-responsive table-observations"
          *ngIf="allObsNotStateInstallation.length && !loader"
        >
          <table class="table table-striped">
            <thead>
              <tr>
                <th
                  class="href"
                  (click)="
                    filterAllObsNotStateInstallation('Establishments.name')
                  "
                >
                  <a class="text-muted">Établissement</a>
                  <span
                    class="fe fe-arrow-down"
                    *ngIf="
                      allObsNotStateInstallationSort ===
                        'Establishments.name' &&
                      allObsNotStateInstallationOrder === 'asc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-up"
                    *ngIf="
                      allObsNotStateInstallationSort ===
                        'Establishments.name' &&
                      allObsNotStateInstallationOrder === 'desc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-right"
                    *ngIf="
                      allObsNotStateInstallationSort !== 'Establishments.name'
                    "
                  ></span>
                </th>
                <th
                  class="href"
                  (click)="
                    filterAllObsNotStateInstallation('Reports.visit_date')
                  "
                >
                  <a class="text-muted">Date du rapport</a>
                  <span
                    class="fe fe-arrow-down"
                    *ngIf="
                      allObsNotStateInstallationSort === 'Reports.visit_date' &&
                      allObsNotStateInstallationOrder === 'asc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-up"
                    *ngIf="
                      allObsNotStateInstallationSort === 'Reports.visit_date' &&
                      allObsNotStateInstallationOrder === 'desc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-right"
                    *ngIf="
                      allObsNotStateInstallationSort !== 'Reports.visit_date'
                    "
                  ></span>
                </th>
                <th>Date de levée</th>
                <th>Nom de l'observation</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="dropdown"
                *ngFor="let observation of allObsNotStateInstallation"
              >
                <td>{{ getEstablishmentNameObservation(observation) }}</td>
                <td>
                  {{
                    observation["report"] && observation["report"]["visit_date"]
                      ? (observation["report"]["visit_date"]
                        | date: "dd/MM/yyyy")
                      : ""
                  }}
                </td>
                <td>
                  {{
                    observation["date_changing_state"]
                      ? (observation["date_changing_state"]
                        | date: "dd/MM/yyyy")
                      : ""
                  }}
                </td>
                <td>{{ observation["name"] }}</td>
                <td class="d-flex flex-row-reverse">
                  <a
                    class="dropdown-ellipses dropdown-toggle href"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fe fe-more-vertical"></i>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a
                      class="dropdown-item href"
                      [routerLink]="[getUrlObservation(observation)]"
                    >
                      lien vers l'observation
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <app-pagination
            (returnEvent)="dispatchPaginationObsNoStateInstallation($event)"
            [count_elements]="allObsNotStateInstallationCount"
            [current_page]="allObsNotStateInstallationPage"
            [elements_length]="allObsNotStateInstallation.length"
            [limit]="allObsNotStateInstallationLimit"
            [total_pages]="allObsNotStateInstallationPages"
            ngDefaultControl
          >
          </app-pagination>
        </div>
        <div
          class="table-responsive table-observations"
          *ngIf="!allObsNotStateInstallation.length && !loader"
        >
          Aucun résultat
        </div>
      </div>
    </div>
  </div>
</div>
<!------------------------------ AVERAGE DAY OF STATE ------------------------------>
<div
  class="modal fade"
  id="AverageDayFilter1"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
>
  <div class="modal-dialog observation" role="document">
    <div class="modal-content modal-observation">
      <div class="modal-observation-header">
        <h4 class="modal-title">Observations levées en moins d'une semaine</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="is-loading loader-modal-content p-5" *ngIf="loader"></div>
        <div
          class="table-responsive table-observations"
          *ngIf="dayFilter1.length && !loader"
        >
          <table class="table table-striped">
            <thead>
              <tr>
                <th
                  class="href"
                  (click)="filterDayFilter1('Establishments.name')"
                >
                  <a class="text-muted">Établissement</a>
                  <span
                    class="fe fe-arrow-down"
                    *ngIf="
                      dayFilter1Sort === 'Establishments.name' &&
                      dayFilter1Order === 'asc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-up"
                    *ngIf="
                      dayFilter1Sort === 'Establishments.name' &&
                      dayFilter1Order === 'desc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-right"
                    *ngIf="dayFilter1Sort !== 'Establishments.name'"
                  ></span>
                </th>
                <th
                  class="href"
                  (click)="filterDayFilter1('Reports.visit_date')"
                >
                  <a class="text-muted">Date du rapport</a>
                  <span
                    class="fe fe-arrow-down"
                    *ngIf="
                      dayFilter1Sort === 'Reports.visit_date' &&
                      dayFilter1Order === 'asc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-up"
                    *ngIf="
                      dayFilter1Sort === 'Reports.visit_date' &&
                      dayFilter1Order === 'desc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-right"
                    *ngIf="dayFilter1Sort !== 'Reports.visit_date'"
                  ></span>
                </th>
                <th>Date de levée</th>
                <th>Nom de l'observation</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr class="dropdown" *ngFor="let observation of dayFilter1">
                <td>{{ getEstablishmentNameObservation(observation) }}</td>
                <td>
                  {{
                    observation["report"] && observation["report"]["visit_date"]
                      ? (observation["report"]["visit_date"]
                        | date: "dd/MM/yyyy")
                      : ""
                  }}
                </td>
                <td>
                  {{
                    observation["date_changing_state"]
                      ? (observation["date_changing_state"]
                        | date: "dd/MM/yyyy")
                      : ""
                  }}
                </td>
                <td>{{ observation["name"] }}</td>
                <td class="d-flex flex-row-reverse">
                  <a
                    class="dropdown-ellipses dropdown-toggle href"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fe fe-more-vertical"></i>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a
                      class="dropdown-item href"
                      [routerLink]="[getUrlObservation(observation)]"
                    >
                      lien vers l'observation
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <app-pagination
            (returnEvent)="dispatchPaginationDayFilter1($event)"
            [count_elements]="dayFilter1Count"
            [current_page]="dayFilter1Page"
            [elements_length]="dayFilter1.length"
            [limit]="dayFilter1Limit"
            [total_pages]="dayFilter1Pages"
            ngDefaultControl
          >
          </app-pagination>
        </div>
        <div
          class="table-responsive table-observations"
          *ngIf="!dayFilter1.length && !loader"
        >
          Aucun résultat
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="modal fade"
  id="AverageDayFilter2"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
>
  <div class="modal-dialog observation" role="document">
    <div class="modal-content modal-observation">
      <div class="modal-observation-header">
        <h4 class="modal-title">
          Observations levées entre une semaine et un mois
        </h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="is-loading loader-modal-content p-5" *ngIf="loader"></div>
        <div
          class="table-responsive table-observations"
          *ngIf="dayFilter2.length && !loader"
        >
          <table class="table table-striped">
            <thead>
              <tr>
                <th
                  class="href"
                  (click)="filterDayFilter2('Establishments.name')"
                >
                  <a class="text-muted">Établissement</a>
                  <span
                    class="fe fe-arrow-down"
                    *ngIf="
                      dayFilter2Sort === 'Establishments.name' &&
                      dayFilter2Order === 'asc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-up"
                    *ngIf="
                      dayFilter2Sort === 'Establishments.name' &&
                      dayFilter2Order === 'desc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-right"
                    *ngIf="dayFilter2Sort !== 'Establishments.name'"
                  ></span>
                </th>
                <th
                  class="href"
                  (click)="filterDayFilter2('Reports.visit_date')"
                >
                  <a class="text-muted">Date du rapport</a>
                  <span
                    class="fe fe-arrow-down"
                    *ngIf="
                      dayFilter2Sort === 'Reports.visit_date' &&
                      dayFilter2Order === 'asc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-up"
                    *ngIf="
                      dayFilter2Sort === 'Reports.visit_date' &&
                      dayFilter2Order === 'desc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-right"
                    *ngIf="dayFilter2Sort !== 'Reports.visit_date'"
                  ></span>
                </th>
                <th>Date de levée</th>
                <th>Nom de l'observation</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr class="dropdown" *ngFor="let observation of dayFilter2">
                <td>{{ getEstablishmentNameObservation(observation) }}</td>
                <td>
                  {{
                    observation["report"] && observation["report"]["visit_date"]
                      ? (observation["report"]["visit_date"]
                        | date: "dd/MM/yyyy")
                      : ""
                  }}
                </td>
                <td>
                  {{
                    observation["date_changing_state"]
                      ? (observation["date_changing_state"]
                        | date: "dd/MM/yyyy")
                      : ""
                  }}
                </td>
                <td>{{ observation["name"] }}</td>
                <td class="d-flex flex-row-reverse">
                  <a
                    class="dropdown-ellipses dropdown-toggle href"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fe fe-more-vertical"></i>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a
                      class="dropdown-item href"
                      [routerLink]="[getUrlObservation(observation)]"
                    >
                      lien vers l'observation
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <app-pagination
            (returnEvent)="dispatchPaginationDayFilter2($event)"
            [count_elements]="dayFilter2Count"
            [current_page]="dayFilter2Page"
            [elements_length]="dayFilter2.length"
            [limit]="dayFilter2Limit"
            [total_pages]="dayFilter2Pages"
            ngDefaultControl
          >
          </app-pagination>
        </div>
        <div
          class="table-responsive table-observations"
          *ngIf="!dayFilter2.length && !loader"
        >
          Aucun résultat
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="modal fade"
  id="AverageDayFilter3"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
>
  <div class="modal-dialog observation" role="document">
    <div class="modal-content modal-observation">
      <div class="modal-observation-header">
        <h4 class="modal-title">
          Observations levées entre un mois et trois mois
        </h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="is-loading loader-modal-content p-5" *ngIf="loader"></div>
        <div
          class="table-responsive table-observations"
          *ngIf="dayFilter3.length && !loader"
        >
          <table class="table table-striped">
            <thead>
              <tr>
                <th
                  class="href"
                  (click)="filterDayFilter3('Establishments.name')"
                >
                  <a class="text-muted">Établissement</a>
                  <span
                    class="fe fe-arrow-down"
                    *ngIf="
                      dayFilter3Sort === 'Establishments.name' &&
                      dayFilter3Order === 'asc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-up"
                    *ngIf="
                      dayFilter3Sort === 'Establishments.name' &&
                      dayFilter3Order === 'desc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-right"
                    *ngIf="dayFilter3Sort !== 'Establishments.name'"
                  ></span>
                </th>
                <th
                  class="href"
                  (click)="filterDayFilter3('Reports.visit_date')"
                >
                  <a class="text-muted">Date du rapport</a>
                  <span
                    class="fe fe-arrow-down"
                    *ngIf="
                      dayFilter3Sort === 'Reports.visit_date' &&
                      dayFilter3Order === 'asc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-up"
                    *ngIf="
                      dayFilter3Sort === 'Reports.visit_date' &&
                      dayFilter3Order === 'desc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-right"
                    *ngIf="dayFilter3Sort !== 'Reports.visit_date'"
                  ></span>
                </th>
                <th>Date de levée</th>
                <th>Nom de l'observation</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr class="dropdown" *ngFor="let observation of dayFilter3">
                <td>{{ getEstablishmentNameObservation(observation) }}</td>
                <td>
                  {{
                    observation["report"] && observation["report"]["visit_date"]
                      ? (observation["report"]["visit_date"]
                        | date: "dd/MM/yyyy")
                      : ""
                  }}
                </td>
                <td>
                  {{
                    observation["date_changing_state"]
                      ? (observation["date_changing_state"]
                        | date: "dd/MM/yyyy")
                      : ""
                  }}
                </td>
                <td>{{ observation["name"] }}</td>
                <td class="d-flex flex-row-reverse">
                  <a
                    class="dropdown-ellipses dropdown-toggle href"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fe fe-more-vertical"></i>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a
                      class="dropdown-item href"
                      [routerLink]="[getUrlObservation(observation)]"
                    >
                      lien vers l'observation
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <app-pagination
            (returnEvent)="dispatchPaginationDayFilter3($event)"
            [count_elements]="dayFilter3Count"
            [current_page]="dayFilter3Page"
            [elements_length]="dayFilter3.length"
            [limit]="dayFilter3Limit"
            [total_pages]="dayFilter3Pages"
            ngDefaultControl
          >
          </app-pagination>
        </div>
        <div
          class="table-responsive table-observations"
          *ngIf="!dayFilter3.length && !loader"
        >
          Aucun résultat
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="modal fade"
  id="AverageDayFilter4"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
>
  <div class="modal-dialog observation" role="document">
    <div class="modal-content modal-observation">
      <div class="modal-observation-header">
        <h4 class="modal-title">Observations levées au dessus de trois mois</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="is-loading loader-modal-content p-5" *ngIf="loader"></div>
        <div
          class="table-responsive table-observations"
          *ngIf="dayFilter4.length && !loader"
        >
          <table class="table table-striped">
            <thead>
              <tr>
                <th
                  class="href"
                  (click)="filterDayFilter4('Establishments.name')"
                >
                  <a class="text-muted">Établissement</a>
                  <span
                    class="fe fe-arrow-down"
                    *ngIf="
                      dayFilter4Sort === 'Establishments.name' &&
                      dayFilter4Order === 'asc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-up"
                    *ngIf="
                      dayFilter4Sort === 'Establishments.name' &&
                      dayFilter4Order === 'desc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-right"
                    *ngIf="dayFilter4Sort !== 'Establishments.name'"
                  ></span>
                </th>
                <th
                  class="href"
                  (click)="filterDayFilter4('Reports.visit_date')"
                >
                  <a class="text-muted">Date du rapport</a>
                  <span
                    class="fe fe-arrow-down"
                    *ngIf="
                      dayFilter4Sort === 'Reports.visit_date' &&
                      dayFilter4Order === 'asc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-up"
                    *ngIf="
                      dayFilter4Sort === 'Reports.visit_date' &&
                      dayFilter4Order === 'desc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-right"
                    *ngIf="dayFilter4Sort !== 'Reports.visit_date'"
                  ></span>
                </th>
                <th>Date de levée</th>
                <th>Nom de l'observation</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr class="dropdown" *ngFor="let observation of dayFilter4">
                <td>{{ getEstablishmentNameObservation(observation) }}</td>
                <td>
                  {{
                    observation["report"] && observation["report"]["visit_date"]
                      ? (observation["report"]["visit_date"]
                        | date: "dd/MM/yyyy")
                      : ""
                  }}
                </td>
                <td>
                  {{
                    observation["date_changing_state"]
                      ? (observation["date_changing_state"]
                        | date: "dd/MM/yyyy")
                      : ""
                  }}
                </td>
                <td>{{ observation["name"] }}</td>
                <td class="d-flex flex-row-reverse">
                  <a
                    class="dropdown-ellipses dropdown-toggle href"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fe fe-more-vertical"></i>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a
                      class="dropdown-item href"
                      [routerLink]="[getUrlObservation(observation)]"
                    >
                      lien vers l'observation
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <app-pagination
            (returnEvent)="dispatchPaginationDayFilter4($event)"
            [count_elements]="dayFilter4Count"
            [current_page]="dayFilter4Page"
            [elements_length]="dayFilter4.length"
            [limit]="dayFilter4Limit"
            [total_pages]="dayFilter4Pages"
            ngDefaultControl
          >
          </app-pagination>
        </div>
        <div
          class="table-responsive table-observations"
          *ngIf="!dayFilter4.length && !loader"
        >
          Aucun résultat
        </div>
      </div>
    </div>
  </div>
</div>
<!------------------------------ AVERAGE COSTS ------------------------------>
<div
  class="modal fade"
  id="AverageCostsFilter1"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
>
  <div class="modal-dialog observation" role="document">
    <div class="modal-content modal-observation">
      <div class="modal-observation-header">
        <h4 class="modal-title">Coûts moyens inférieurs à 100 euros</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="is-loading loader-modal-content p-5" *ngIf="loader"></div>
        <div
          class="table-responsive table-observations"
          *ngIf="costFilter1.length && !loader"
        >
          <table class="table table-striped">
            <thead>
              <tr>
                <th
                  class="href"
                  (click)="filterCostFilter1('Establishments.name')"
                >
                  <a class="text-muted">Établissement</a>
                  <span
                    class="fe fe-arrow-down"
                    *ngIf="
                      costFilter1Sort === 'Establishments.name' &&
                      costFilter1Order === 'asc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-up"
                    *ngIf="
                      costFilter1Sort === 'Establishments.name' &&
                      costFilter1Order === 'desc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-right"
                    *ngIf="costFilter1Sort !== 'Establishments.name'"
                  ></span>
                </th>
                <th
                  class="href"
                  (click)="filterCostFilter1('Reports.visit_date')"
                >
                  <a class="text-muted">Date du rapport</a>
                  <span
                    class="fe fe-arrow-down"
                    *ngIf="
                      costFilter1Sort === 'Reports.visit_date' &&
                      costFilter1Order === 'asc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-up"
                    *ngIf="
                      costFilter1Sort === 'Reports.visit_date' &&
                      costFilter1Order === 'desc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-right"
                    *ngIf="costFilter1Sort !== 'Reports.visit_date'"
                  ></span>
                </th>
                <th>Nom de l'observation</th>
                <th>Coût réel</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr class="dropdown" *ngFor="let observation of costFilter1">
                <td>{{ getEstablishmentNameObservation(observation) }}</td>
                <td>
                  {{
                    observation["report"] && observation["report"]["visit_date"]
                      ? (observation["report"]["visit_date"]
                        | date: "dd/MM/yyyy")
                      : ""
                  }}
                </td>
                <td>{{ observation["name"] }}</td>
                <td>{{ observation["financial_real"] + "€" }}</td>
                <td class="d-flex flex-row-reverse">
                  <a
                    class="dropdown-ellipses dropdown-toggle href"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fe fe-more-vertical"></i>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a
                      class="dropdown-item href"
                      [routerLink]="[getUrlObservation(observation)]"
                    >
                      lien vers l'observation
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <app-pagination
            (returnEvent)="dispatchPaginationCostsFilter1($event)"
            [count_elements]="costFilter1Count"
            [current_page]="costFilter1Page"
            [elements_length]="costFilter1.length"
            [limit]="costFilter1Limit"
            [total_pages]="costFilter1Pages"
            ngDefaultControl
          >
          </app-pagination>
        </div>
        <div
          class="table-responsive table-observations"
          *ngIf="!costFilter1.length && !loader"
        >
          Aucun résultat
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="modal fade"
  id="AverageCostsFilter2"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
>
  <div class="modal-dialog observation" role="document">
    <div class="modal-content modal-observation">
      <div class="modal-observation-header">
        <h4 class="modal-title">Coûts moyens entre 100 et 1000 euros</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="is-loading loader-modal-content p-5" *ngIf="loader"></div>
        <div
          class="table-responsive table-observations"
          *ngIf="costFilter2.length && !loader"
        >
          <table class="table table-striped">
            <thead>
              <tr>
                <th
                  class="href"
                  (click)="filterCostFilter2('Establishments.name')"
                >
                  <a class="text-muted">Établissement</a>
                  <span
                    class="fe fe-arrow-down"
                    *ngIf="
                      costFilter2Sort === 'Establishments.name' &&
                      costFilter2Order === 'asc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-up"
                    *ngIf="
                      costFilter2Sort === 'Establishments.name' &&
                      costFilter2Order === 'desc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-right"
                    *ngIf="costFilter2Sort !== 'Establishments.name'"
                  ></span>
                </th>
                <th
                  class="href"
                  (click)="filterCostFilter2('Reports.visit_date')"
                >
                  <a class="text-muted">Date du rapport</a>
                  <span
                    class="fe fe-arrow-down"
                    *ngIf="
                      costFilter2Sort === 'Reports.visit_date' &&
                      costFilter2Order === 'asc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-up"
                    *ngIf="
                      costFilter2Sort === 'Reports.visit_date' &&
                      costFilter2Order === 'desc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-right"
                    *ngIf="costFilter2Sort !== 'Reports.visit_date'"
                  ></span>
                </th>
                <th>Nom de l'observation</th>
                <th>Coût réel</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr class="dropdown" *ngFor="let observation of costFilter2">
                <td>{{ getEstablishmentNameObservation(observation) }}</td>
                <td>
                  {{
                    observation["report"] && observation["report"]["visit_date"]
                      ? (observation["report"]["visit_date"]
                        | date: "dd/MM/yyyy")
                      : ""
                  }}
                </td>
                <td>{{ observation["name"] }}</td>
                <td>{{ observation["financial_real"] + "€" }}</td>
                <td class="d-flex flex-row-reverse">
                  <a
                    class="dropdown-ellipses dropdown-toggle href"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fe fe-more-vertical"></i>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a
                      class="dropdown-item href"
                      [routerLink]="[getUrlObservation(observation)]"
                    >
                      lien vers l'observation
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <app-pagination
            (returnEvent)="dispatchPaginationCostsFilter2($event)"
            [count_elements]="costFilter2Count"
            [current_page]="costFilter2Page"
            [elements_length]="costFilter2.length"
            [limit]="costFilter2Limit"
            [total_pages]="costFilter2Pages"
            ngDefaultControl
          >
          </app-pagination>
        </div>
        <div
          class="table-responsive table-observations"
          *ngIf="!costFilter2.length && !loader"
        >
          Aucun résultat
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="modal fade"
  id="AverageCostsFilter3"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
>
  <div class="modal-dialog observation" role="document">
    <div class="modal-content modal-observation">
      <div class="modal-observation-header">
        <h4 class="modal-title">Coûts moyens au dessus de 1000 euros</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="is-loading loader-modal-content p-5" *ngIf="loader"></div>
        <div
          class="table-responsive table-observations"
          *ngIf="costFilter3.length && !loader"
        >
          <table class="table table-striped">
            <thead>
              <tr>
                <th
                  class="href"
                  (click)="filterCostFilter3('Establishments.name')"
                >
                  <a class="text-muted">Établissement</a>
                  <span
                    class="fe fe-arrow-down"
                    *ngIf="
                      costFilter3Sort === 'Establishments.name' &&
                      costFilter3Order === 'asc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-up"
                    *ngIf="
                      costFilter3Sort === 'Establishments.name' &&
                      costFilter3Order === 'desc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-right"
                    *ngIf="costFilter3Sort !== 'Establishments.name'"
                  ></span>
                </th>
                <th
                  class="href"
                  (click)="filterCostFilter3('Reports.visit_date')"
                >
                  <a class="text-muted">Date du rapport</a>
                  <span
                    class="fe fe-arrow-down"
                    *ngIf="
                      costFilter3Sort === 'Reports.visit_date' &&
                      costFilter3Order === 'asc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-up"
                    *ngIf="
                      costFilter3Sort === 'Reports.visit_date' &&
                      costFilter3Order === 'desc'
                    "
                  ></span>
                  <span
                    class="fe fe-arrow-right"
                    *ngIf="costFilter3Sort !== 'Reports.visit_date'"
                  ></span>
                </th>
                <th>Nom de l'observation</th>
                <th>Coût réel</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr class="dropdown" *ngFor="let observation of costFilter3">
                <td>{{ getEstablishmentNameObservation(observation) }}</td>
                <td>
                  {{
                    observation["report"] && observation["report"]["visit_date"]
                      ? (observation["report"]["visit_date"]
                        | date: "dd/MM/yyyy")
                      : ""
                  }}
                </td>
                <td>{{ observation["name"] }}</td>
                <td>{{ observation["financial_real"] + "€" }}</td>
                <td class="d-flex flex-row-reverse">
                  <a
                    class="dropdown-ellipses dropdown-toggle href"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fe fe-more-vertical"></i>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a
                      class="dropdown-item href"
                      [routerLink]="[getUrlObservation(observation)]"
                    >
                      lien vers l'observation
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <app-pagination
            (returnEvent)="dispatchPaginationCostsFilter3($event)"
            [count_elements]="costFilter3Count"
            [current_page]="costFilter3Page"
            [elements_length]="costFilter3.length"
            [limit]="costFilter3Limit"
            [total_pages]="costFilter3Pages"
            ngDefaultControl
          >
          </app-pagination>
        </div>
        <div
          class="table-responsive table-observations"
          *ngIf="!costFilter3.length && !loader"
        >
          Aucun résultat
        </div>
      </div>
    </div>
  </div>
</div>
