import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { HttpClient } from '@angular/common/http';
import { RequestService } from './request.service';

@Injectable()
export class SanitaryPoolsRequestService extends RequestService {
  constructor(
    protected http: HttpClient,
    protected notifier: NotifierService
  ) {
    super(http, notifier);
  }

  /// GET ALL
  public getAllSanitaryPool(search, sort, order, limit, page, registry_id, establishment_id) {
    return this.get( 'registry/' + registry_id + '/establishment/' + establishment_id + '/sanitary-pools?' +
      'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  /// GET ONE
  public getSanitaryPool(registry_id, establishment_id, sanitaryPoolId) {
    return this.get('registry/' + registry_id + '/establishment/' + establishment_id +
        '/sanitary-pool/' + sanitaryPoolId);
  }
  /// CREATE
  public createSanitaryPool(registry_id, establishment_id, sanitaryPool) {
    return this.post('registry/' + registry_id + '/establishment/' + establishment_id +
        '/sanitary-pool', { sanitaryPool });
  }
  /// UPDATE
  public updateSanitaryPool(registry_id, establishment_id, sanitaryPoolId, sanitaryPool) {
    return this.put('registry/' + registry_id + '/establishment/' + establishment_id +
        '/sanitary-pool/' + sanitaryPoolId,
      { sanitaryPool });
  }
  /// DELETE
  public deleteSanitaryPool(registry_id, establishment_id, sanitaryPoolId) {
    return this.delete('registry/' + registry_id + '/establishment/' + establishment_id +
        '/sanitary-pool/' + sanitaryPoolId);
  }
  /// FILE
  public getFiles(search, sort, order, limit, page, registry_id, establishment_id, sanitaryPoolId) {
    return this.get('registry/' + registry_id + '/establishment/' + establishment_id +
        '/sanitary-pool/' + sanitaryPoolId +
        '/files?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getFile(registry_id, establishment_id, sanitaryPoolId, file_id) {
    return this.get('registry/' + registry_id + '/establishment/' + establishment_id +
        '/sanitary-pool/' + sanitaryPoolId +
        '/files/' + file_id);
  }
  public createFile(registry_id, establishment_id, sanitaryPoolId, files) {
    return this.post('registry/' + registry_id + '/establishment/' + establishment_id +
        '/sanitary-pool/' + sanitaryPoolId + '/files',
        { files: files });
  }
  public updateFile(registry_id, establishment_id, sanitaryPoolId, file_id, file) {
    return this.put('registry/' + registry_id + '/establishment/' + establishment_id +
        '/sanitary-pool/' + sanitaryPoolId +
        '/files/' + file_id, { file: file });
  }
  public deleteFile(registry_id, establishment_id, sanitaryPoolId, file_id) {
    return this.delete('registry/' + registry_id + '/establishment/' + establishment_id +
        '/sanitary-pool/' + sanitaryPoolId + '/files/' + file_id);
  }
  public downloadFile(registry_id, establishment_id, sanitaryPoolId, file_id) {
    return this.get('registry/' + registry_id + '/establishment/' + establishment_id +
        '/sanitary-pool/' + sanitaryPoolId +
        '/files/' + file_id + '/download', { responseType: 'arraybuffer' });
  }

  // ------------------ ------------------  RECORD ------------------ ------------------
  /// GET ONE
  public getSanitaryPoolRecord(registry_id, establishment_id, sanitaryPoolId, record_date) {
    return this.get('registry/' + registry_id + '/establishment/' + establishment_id +
      '/sanitary-pool/' + sanitaryPoolId + '/records/' + record_date);
  }
  // /// CREATE
  public createSanitaryPoolRecord(registry_id, establishment_id, sanitaryPoolId, sanitaryPoolRecord) {
    return this.post('registry/' + registry_id + '/establishment/' + establishment_id +
      '/sanitary-pool/' + sanitaryPoolId + '/records', { sanitaryPoolRecord });
  }
  /// UPDATE
  public updateSanitaryPoolRecord(registry_id, establishment_id, sanitaryPoolId, record_date, sanitaryPoolRecord) {
    return this.put('registry/' + registry_id + '/establishment/' + establishment_id +
      '/sanitary-pool/' + sanitaryPoolId + '/records/' + record_date,
      { sanitaryPoolRecord });
  }
}
