import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { NotifierService } from "angular-notifier";
import { RequestService } from "./request.service";

@Injectable()
export class TrainingsRequestService extends RequestService {
  constructor(protected http: HttpClient, protected notifier: NotifierService) {
    super(http, notifier);
  }

  /**
   * Get all trainings
   *
   * @param search What to search
   * @param sort Sort by
   * @param order Order
   * @param limit Limit to display
   * @param page Page number
   * @param establishment_id The establishment to search for
   * @param registry_id The registry to search for
   * @param show Retrieves the show or not showed trainings
   * @param admin See if is an admin or not
   */
  public getTrainings(
    search: any,
    sort: any,
    order: any,
    limit: any,
    page: any,
    establishment_id: any,
    registry_id: any,
    show: boolean,
    admin: boolean
  ) {
    return this.get(
      "trainings?" +
        "search=" +
        search +
        "&sort=" +
        sort +
        "&order=" +
        order +
        "&limit=" +
        limit +
        "&page=" +
        page +
        "&admin=" +
        admin +
        "&show=" +
        show +
        "&establishment_id=" +
        establishment_id +
        "&registry_id=" +
        registry_id
    );
  }

  /**
   * Get all trainings from main menu
   *
   * @param search What to search
   * @param sort Sort by
   * @param order Order
   * @param limit Limit to display
   * @param page Page number
   */
  public getAllTrainings(
    search: any,
    sort: any,
    order: any,
    limit: any,
    page: any,
    establishments: any,
    registry: any
  ) {
    return this.get(
      "trainings/all?" +
        "search=" +
        search +
        "&sort=" +
        sort +
        "&order=" +
        order +
        "&limit=" +
        limit +
        "&page=" +
        page +
        "&establishments=" +
        establishments +
        "&registry=" +
        registry
    );
  }

  /**
   * Get one specific training with his id
   *
   * @param training_id The training id
   */
  public getTraining(training_id: any) {
    return this.get("training/" + training_id);
  }

  /**
   * Retrieves all trainings event dates from a training event
   *
   * @param search What to search
   * @param sort Sort by
   * @param order Order
   * @param limit Limit to display
   * @param page Page number
   * @param training_id Training id
   */
  public getDateTrainings(
    search: any,
    sort: any,
    order: any,
    limit: any,
    page: any,
    training_id: any
  ) {
    return this.get(
      "date-trainings/training/" +
        training_id +
        "?search=" +
        search +
        "&sort=" +
        sort +
        "&order=" +
        order +
        "&limit=" +
        limit +
        "&page=" +
        page
    );
  }

  /**
   * Retrieves one date training
   *
   * @param date_training_id The date training id to retrieve
   */
  public getDateTraining(date_training_id: any) {
    return this.get("date-training/" + date_training_id);
  }

  /**
   * Retrieves one session date training
   *
   * @param date_training_id The date training id to retrieve
   */
  public getDateTrainingSession(training_id: any, date_training_id: any) {
    return this.get(
      "training/" + training_id + "/sessions/" + date_training_id
    );
  }

  /**
   * Retrieves all employees trained
   *
   * @param date_training_id The date training id to retrieve
   */
  public getEmployeesTrained(
    search: any,
    sort: any,
    order: any,
    limit: any,
    page: any,
    date_training_id: any,
    is_present: any
  ) {
    return this.get(
      "training/" +
        date_training_id +
        "/employees_trained/" +
        is_present +
        "?search=" +
        search +
        "&sort=" +
        sort +
        "&order=" +
        order +
        "&limit=" +
        limit +
        "&page=" +
        page
    );
  }

  /**
   * Retrieves all employees for a date training
   *
   * @param search What to search
   * @param sort Sort by
   * @param order Order
   * @param limit Limit to display
   * @param page Page number
   * @param date_training_id The date training to search
   */
  public getDateTrainingsEmployees(
    search: any,
    sort: any,
    order: any,
    limit: any,
    page: any,
    date_training_id: any
  ) {
    return this.get(
      "date-trainings/" +
        date_training_id +
        "/files/employees" +
        "?search=" +
        search +
        "&sort=" +
        sort +
        "&order=" +
        order +
        "&limit=" +
        limit +
        "&page=" +
        page
    );
  }

  /**
   * Retrieves all Date Trainings by employee_id
   *
   * @param search What to search
   * @param sort Sort by
   * @param order Order
   * @param limit Limit to display
   * @param page Page number
   * @param employee_id The employee_id to search
   */
  public getDateTrainingsByEmployee(
    search: any,
    sort: any,
    order: any,
    limit: any,
    page: any,
    employee_id: any
  ) {
    return this.get(
      "date_trainings/" +
        employee_id +
        "?search=" +
        search +
        "&sort=" +
        sort +
        "&order=" +
        order +
        "&limit=" +
        limit +
        "&page=" +
        page
    );
  }

  public getAllTrainingsWithDateTrainings(establishment_id) {
    return this.get("trainings/with_sessions/" + establishment_id);
  }

  /**
   * Retrieves a specific file with his id for training or date training (files specifics for employees)
   *
   * @param file_id The file to get
   * @param establishment_id The establishment
   * @param date_training_id The date training
   * @param dte_id The date training
   */
  public getFile(
    file_id: any,
    establishment_id: any,
    date_training_id: any,
    dte_id: any
  ) {
    return this.get(
      "date-trainings/" +
        date_training_id +
        "/establishment/" +
        establishment_id +
        "/files/" +
        file_id +
        "?dte=" +
        dte_id
    );
  }

  public getAllExpiredTrainings(
    search: any,
    sort: any,
    order: any,
    limit: any,
    page: any,
    establishments: any,
    registry: any
  ) {
    return this.get(
      "training/expired?search=" +
        search +
        "&sort=" +
        sort +
        "&order=" +
        order +
        "&limit=" +
        limit +
        "&page=" +
        page +
        "&establishments=" +
        establishments +
        "&registry=" +
        registry
    );
  }

  /**
   * Retrieves all files for a date training id
   *
   * @param search What to search
   * @param sort Sort by
   * @param order Order
   * @param limit Limit to display
   * @param page Page number
   * @param date_training_id The date training to search files
   */
  public getFiles(
    search: any,
    sort: any,
    order: any,
    limit: any,
    page: any,
    date_training_id: any
  ) {
    return this.get(
      "date-trainings/" +
        date_training_id +
        "/files?search=" +
        search +
        "&sort=" +
        sort +
        "&order=" +
        order +
        "&limit=" +
        limit +
        "&page=" +
        page
    );
  }

  /**
   * Retrieves all employees related to a registry AND an establishment.
   *
   * @param search Filter
   * @param sort Filter
   * @param order Filter
   * @param limit Filter
   * @param page Filter
   * @param establishment_id The establishment
   * @param registry_id The registry
   */
  public getEmployees(
    search: any,
    sort: any,
    order: any,
    limit: any,
    page: any,
    establishment_id: number,
    registry_id: number
  ) {
    return this.get(
      "training/establishment/" +
        establishment_id +
        "/registry/" +
        registry_id +
        "/employees?search=" +
        search +
        "&sort=" +
        sort +
        "&order=" +
        order +
        "&limit=" +
        limit +
        "&page=" +
        page
    );
  }

  /**
   * Retrieves all sessions for a training
   *
   * @param search Filter
   * @param sort Filter
   * @param order Filter
   * @param limit Filter
   * @param page Filter
   * @param training_id The Training
   */
  public getSessionsTraining(
    search: any,
    sort: any,
    order: any,
    limit: any,
    page: any,
    training_id: number
  ) {
    return this.get(
      "training/sessions/" +
        training_id +
        "?search=" +
        search +
        "&sort=" +
        sort +
        "&order=" +
        order +
        "&limit=" +
        limit +
        "&page=" +
        page
    );
  }

  /**
   * Retrieves one employee
   *
   * @param employee_id The employee
   */
  public getEmployee(employee_id: number) {
    return this.get("training/employee/" + employee_id);
  }

  /**
   * Retrieves all employees selected for a date training
   *
   * @param date_training_id The date training linked
   */
  public getSelectedEmployees(date_training_id: number) {
    return this.get("date-training/" + date_training_id + "/employees");
  }

  /**
   * For dashboard.
   * Search trainings for the 12 next months
   *
   * @param search What to search
   * @param sort Sort by
   * @param order Order
   * @param limit Limit to display
   * @param page Page number
   * @param registry_id The registry id to search by
   * @param establishment_id The establishment to match
   * @param late If is late or not
   * @param year Year
   * @param month Month
   */
  public getTrainingsDashboard(
    search: any,
    sort: any,
    order: any,
    limit: any,
    page: any,
    registry_id: any,
    establishment_id: any,
    late: any,
    year,
    month
  ) {
    return this.get(
      "dashboard-registry/" +
        registry_id +
        "/report_trainings?establishment_id=" +
        establishment_id +
        "&year=" +
        year +
        "&month=" +
        month +
        "&late=" +
        late +
        "&search=" +
        search +
        "&sort=" +
        sort +
        "&order=" +
        order +
        "&limit=" +
        limit +
        "&page=" +
        page
    );
  }

  public getNbEmployeesByTrainings(establishments, registry) {
    return this.get(
      "date_training_employees/nb_employees/trainings?establishments=" +
        establishments +
        "&registry=" +
        registry
    );
  }

  public getNbEmployeesByEstablishments() {
    return this.get("date_training_employees/nb_employees/establishments");
  }

  // \/\/\/\/\/\
  // CREATE
  // \/\/\/\/\/\

  /**
   * Create a new training
   *
   * @param training The data to send
   */
  public createTraining(training: any) {
    return this.post("training", { training });
  }

  /**
   * Create a new training
   *
   * @param training The data to send
   */
  public createTrainingFromMainMenu(training: any) {
    return this.post("trainings/create", { training });
  }

  /**
   * Create a new date for a training
   *
   * @param dateTraining The date training data
   */
  public createDateTraining(dateTraining: any) {
    return this.post("date-training", { date_training: dateTraining });
  }

  public createDateTrainingEmployee(employee_id, sessionForm) {
    return this.post("date_training_employee/" + employee_id, { sessionForm });
  }

  public createDateTrainingEmployees(sessionForm) {
    return this.post("date_training_employees", { sessionForm });
  }

  public getPDF(name, exportType) {
    return this.get(
      "trainings/download_file/" + name + "?export_type=" + exportType,
      { responseType: "arraybuffer" }
    );
  }

  /**
   * Create a new file
   *
   * @param date_training_id The date training to link
   * @param dte_id The date training employee id
   * @param file The data
   */
  public createFile(date_training_id: any, dte_id: any, file: any) {
    return this.post(
      "date-trainings/" + date_training_id + "/files?dte=" + dte_id,
      { file: file }
    );
  }

  /**
   * Create employees from an employee list (sheets)
   *
   * @param registry_id The registry id
   * @param establishment_id The establishment id
   * @param overwrite If the new sheet needs to overwrite old data or only add the new data
   * @param file The file containing the employees list
   */
  public createSheetEmployees(
    registry_id: any,
    establishment_id: any,
    overwrite: boolean,
    file: any
  ) {
    return this.post(
      "training/registry/" +
        registry_id +
        "/establishment/" +
        establishment_id +
        "/sheets?o=" +
        overwrite,
      { employees: file }
    );
  }

  /**
   * Create one employee
   *
   * @param employee The employee data
   */
  public createEmployee(employee: any) {
    return this.post("training/employee", { employee: employee });
  }

  // \/\/\/\/\/\
  // END CREATE
  // \/\/\/\/\/\

  // \/\/\/\/\/\
  // UPDATE
  // \/\/\/\/\/\

  /**
   * Update one specific training
   *
   * @param training_id The training id
   * @param training The data to send
   */
  public updateTraining(training_id: any, training: any) {
    return this.put("training/" + training_id, { training: training });
  }

  /**
   * Update one specific training
   *
   * @param training_id The training id
   * @param training The data to send
   */
  public updateTrainingFromMainMenu(training_id: any, training: any) {
    return this.put("training/update/" + training_id, { training });
  }

  /**
   * Update one date training
   *
   * @param date_training_id The date training id
   * @param date_training_data The date training data
   */
  public updateDateTraining(date_training_id: any, date_training_data: any) {
    return this.put("date-training/" + date_training_id, {
      date_training: date_training_data,
    });
  }

  /**
   * Update one file
   *
   * @param file_id The file id
   * @param date_training_id The date training to match
   * @param dte_id The date training employee
   * @param file The file data
   */
  public updateFile(
    file_id: any,
    date_training_id: any,
    dte_id: any,
    file: any
  ) {
    return this.put(
      "date-trainings/" +
        date_training_id +
        "/files/" +
        file_id +
        "?dte=" +
        dte_id,
      { file: file }
    );
  }

  /**
   * Update one employee
   *
   * @param employee The employee data
   */
  public updateEmployee(employee: any) {
    return this.put("training/employee", { employee: employee });
  }

  /**
   * Update the employee if is present or not
   *
   * @param dte_id The date training employee id to find the employee
   * @param is_present Set the employee present or not
   */
  public updateEmployeePresent(dte_id: any, is_present: any) {
    return this.put("date-training/employee/" + dte_id + "/present", {
      is_present: is_present,
    });
  }

  /**
   * Update trainings for mass editing part.
   *
   * @param registry_id The registry id
   * @param establishment_id The establishment id
   * @param trainings The trainings
   */
  public updateTrainingsShow(
    registry_id: any,
    establishment_id: any,
    trainings: any
  ) {
    return this.put(
      "trainings/registry/" +
        registry_id +
        "/establishment/" +
        establishment_id +
        "/show",
      trainings
    );
  }

  // \/\/\/\/\/\
  // END UPDATE
  // \/\/\/\/\/\

  // \/\/\/\/\/\
  // DELETE
  // \/\/\/\/\/\

  /**
   * Delete a file with his ID.
   *
   * @param file_id The file to delete.
   * @param date_training_id The date training linked to
   * @param dte_id The date training employee
   */
  public deleteFile(file_id: any, date_training_id: any, dte_id: any) {
    return this.delete(
      "date-trainings/" +
        date_training_id +
        "/files/" +
        file_id +
        "?dte=" +
        dte_id
    );
  }

  /**
   * Delete an employee for a training
   *
   * @param employee_id The employee
   * @param establishment_id The establishment
   * @param registry_id The registry
   */
  public deleteEmployee(
    employee_id: any,
    establishment_id: any,
    registry_id: any
  ) {
    return this.delete(
      "training/establishment/" +
        establishment_id +
        "/registry/" +
        registry_id +
        "/employee/" +
        employee_id
    );
  }

  /**
   * Delete a training
   *
   * @param training_id The training
   * @param establishment_id The establishment
   * @param registry_id The registry
   */
  public deleteTraining(
    registry_id: any,
    establishment_id: any,
    training_id: any
  ) {
    return this.delete(
      "training/" +
        training_id +
        "/establishment/" +
        establishment_id +
        "/registry/" +
        registry_id
    );
  }

  /**
   * Delete a training
   *
   * @param training_id The training
   */
  public deleteTrainingFromMainMenu(training_id: any) {
    return this.delete("training/delete/" + training_id);
  }

  /**
   * Delete a training date
   *
   * @param date_training_id The training date
   * @param establishment_id The establishment
   * @param registry_id The registry
   */
  public deleteDateTraining(
    registry_id: any,
    establishment_id: any,
    date_training_id: any
  ) {
    return this.delete(
      "date-training/" +
        date_training_id +
        "/establishment/" +
        establishment_id +
        "/registry/" +
        registry_id
    );
  }

  // \/\/\/\/\/\
  // END DELETE
  // \/\/\/\/\/\

  // \/\/\/\/\/\
  // DOWNLOAD
  // \/\/\/\/\/\

  /**
   * Download a file from his ID.
   *
   * @param file_id The file to download
   * @param date_training_id The date training to match
   * @param dte_id The date training employee
   */
  public downloadFile(file_id: any, date_training_id: any, dte_id: any) {
    return this.get(
      "date-trainings/" +
        date_training_id +
        "/file/" +
        file_id +
        "/download?dte=" +
        dte_id,
      { responseType: "arraybuffer" }
    );
  }

  // \/\/\/\/\/\
  // END DOWNLOAD
  // \/\/\/\/\/\
}
