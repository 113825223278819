<app-modal-right (eventOnClose)="closeModal()" #rvrat>{{ bindModal(rvrat) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">{{ isNew ? 'Création' : canUpdate ? 'Mise à jour' : 'Consultation' }}</h6>

        <h1 class="header-title">Rapport</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <form [formGroup]="rvratForm" [hidden]="loaderContent">
      <div class="row">
        <div class="col-12">

          <!-- VISIT DATE -->
          <div class="form-group">
            <label for="validationStartDate" required>Date</label>
            <input type="date" formControlName="date" class="form-control" id="validationStartDate" placeholder="Date" data-toggle="flatpickr" [ngClass]="{ 'is-invalid': date.invalid && (date.dirty || date.touched) }">
            <div *ngIf="date.invalid && (date.dirty || date.touched)" class="invalid-feedback">
              {{ getErrorMessage("date") }}
            </div>
          </div>

          <!-- OLD PRESCRIPTIONS -->
          <div class="form-group" *ngIf="oldPrescriptions">
            <label for="PrescriptionsOld">Prescriptions existantes</label>
            <textarea class="form-control" id="PrescriptionsOld" placeholder="Prescriptions" [rows]="countOldPrescriptions" disabled>{{ oldPrescriptions }}</textarea>
          </div>
          <!-- PRESCRIPTIONS -->
          <div class="form-group">
            <label for="validationPrescriptions">Prescriptions</label>
            <textarea formControlName="prescriptions_name" class="form-control" id="validationPrescriptions" placeholder="Prescriptions" rows="5" (keydown.enter)="addTextareaBullet($event)" (paste)="pasteTextareaBullet($event)" (change)="addTextareaBullet($event)"
                      [ngClass]="{ 'is-invalid': prescriptions_name.invalid && (prescriptions_name.dirty || prescriptions_name.touched) }"></textarea>
            <div *ngIf="prescriptions_name.invalid && (prescriptions_name.dirty || prescriptions_name.touched)" class="invalid-feedback">
              {{ getErrorMessage("prescriptions_name") }}
            </div>
            <span class="badge badge-soft-info d-inline-flex"><span class="fe fe-info mr-1"></span>Une prescription par ligne</span>
          </div>

          <!-- COMMENTS -->
          <div class="form-group">
            <label for="validationCommentCommission">Commentaires éventuels</label>
            <textarea formControlName="comment_rvrat" class="form-control" id="validationCommentCommission" placeholder="Commentaires éventuels" rows="5"></textarea>
            <div *ngIf="comment_rvrat.invalid && (comment_rvrat.dirty || comment_rvrat.touched)" class="invalid-feedback">
              {{ getErrorMessage("comment_commission") }}
            </div>
          </div>

          <!-- DOCUMENTS -->
          <div class="form-group" *ngIf="isNew || canUpdate">
            <label for="inputFile">Documents</label>

            <div class="dropzone dropzone-multiple dz-clickable" appDragDrop (dropHandler)="onFileChange($event)">
              <div class="dz-default dz-message" (click)="chooseFile()">
                <span>Déposer vos fichiers ici pour télécharger</span>
              </div>
              <input id="inputFile" (change)="onFileChange($event)" type="file" [hidden]="true" multiple>
            </div>
          </div>
          <div class="card" *ngFor="let file of files; let index = index">
            <div class="card-body">
              <div class="d-flex align-content-center justify-content-between">
                <h4 class="card-title mb-0 name align-self-center">
                  {{ file.name + '.' + file.extension }}
                </h4>

                <a class="href tooltipHtml" data-placement="bottom" title="Supprimer" (click)="removeFile(index)" *ngIf="isNew || canUpdate">
                  <i class="fe fe-trash"></i>
                </a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </form>
  </div>

  <div modal-footer class="w-100" *ngIf="isNew || canUpdate">
    <button class="btn btn-primary w-100" (click)="isNew ? createRvrat() : updateRvrat()" [ngClass]="{ 'is-loading is-loading-sm': loader }" [disabled]="loader || rvratForm.invalid">
      {{ isNew ? 'Créer' : 'Mettre à jour' }}
    </button>
  </div>
</app-modal-right>
