<div class="main-content">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col">

        <!-- HEADER -->
        <div class="header mb-0">
          <div class="header-body no-border">
            <div class="row align-items-center">
              <div class="col">
                <h1 class="header-title">Tableau de bord {{ registry?.name }}</h1>

                <small class="form-text text-muted">
                  <span>Tableau de bord {{ registry?.name }} {{ establishment && ' de l\'établissement ' + establishment.name }}</span>
                </small>

                <app-breadcrumbs [currentPage]="pageName" [names]="previousPageName"
                                 [routes]="previousPageRoute"></app-breadcrumbs>
              </div>

              <div class="col">
                <div class="row align-items-center justify-content-end mb-3">
                  <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0" *ngIf="establishment && registry && registry.id === 1">
                    <a class="btn btn-primary d-block d-md-inline-block text-white" (click)="checkIfClick(modifyDescription)">
                      Description de l'établissement
                    </a>
                  </div>

                  <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0" *ngIf="establishment && registry && registry.cotations_module">
                    <a class="btn btn-primary d-block d-md-inline-block text-white"
                       [routerLink]="[currentRoute + 'export_to']">
                      Exporter vers
                    </a>
                  </div>

                  <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0"
                       *ngIf="!loaderContent && registry && registry.exports_module && registry.exports_module.length">
                    <a class="btn btn-primary d-block d-md-inline-block text-white"
                       [routerLink]="[currentRoute + 'download']">
                      Téléchargements
                    </a>
                  </div>
                </div>

                <div class="row align-items-center justify-content-end">
                  <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0 ng-select-establishment" *ngIf="establishment && establishmentsList.length && !loader">
                    <ng-select [items]="establishmentsList"
                               [multiple]="false"
                               bindLabel="name"
                               notFoundText="Aucun résultat"
                               [selectableGroup]="false"
                               [closeOnSelect]="true"
                               bindValue="id"
                               placeholder="Sélectionnez un etablissement"
                               [(ngModel)]="selectedEstablishment" (ngModelChange)="changeEstablishment(selectedEstablishment)" [ngModelOptions]="{ standalone: true }">
                      <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        {{ item.name }}
                      </ng-template>
                    </ng-select>
                  </div>

                  <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0 ng-select-registry" *ngIf="establishment && registries.length && !loader">
                    <ng-select [items]="registries"
                               [multiple]="false"
                               bindLabel="name"
                               notFoundText="Aucun résultat"
                               [selectableGroup]="false"
                               [closeOnSelect]="true"
                               bindValue="id"
                               placeholder="Sélectionnez un registre"
                               [(ngModel)]="selectedRegistry" (ngModelChange)="changeRegistry(selectedRegistry)" [ngModelOptions]="{ standalone: true }">
                      <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        {{ item.name }}
                      </ng-template>
                    </ng-select>
                  </div>

                </div>
              </div>

            </div>

            <!--<div class="row align-items-center justify-content-end">-->
            <!--  <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0 ng-select-establishment" *ngIf="establishment && establishmentsList.length && !loader">-->
            <!--    <ng-select [items]="establishmentsList"-->
            <!--               [multiple]="false"-->
            <!--               bindLabel="name"-->
            <!--               notFoundText="Aucun résultat"-->
            <!--               [selectableGroup]="false"-->
            <!--               [closeOnSelect]="true"-->
            <!--               bindValue="id"-->
            <!--               placeholder="Sélectionnez un etablissement"-->
            <!--               [(ngModel)]="selectedEstablishment" (ngModelChange)="changeEstablishment(selectedEstablishment)" [ngModelOptions]="{ standalone: true }">-->
            <!--      <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">-->
            <!--        {{ item.name }}-->
            <!--      </ng-template>-->
            <!--    </ng-select>-->
            <!--  </div>-->

            <!--  <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0 ng-select-registry" *ngIf="establishment && registries.length && !loader">-->
            <!--    <ng-select [items]="registries"-->
            <!--               [multiple]="false"-->
            <!--               bindLabel="name"-->
            <!--               notFoundText="Aucun résultat"-->
            <!--               [selectableGroup]="false"-->
            <!--               [closeOnSelect]="true"-->
            <!--               bindValue="id"-->
            <!--               placeholder="Sélectionnez un registre"-->
            <!--               [(ngModel)]="selectedRegistry" (ngModelChange)="changeRegistry(selectedRegistry)" [ngModelOptions]="{ standalone: true }">-->
            <!--      <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">-->
            <!--        {{ item.name }}-->
            <!--      </ng-template>-->
            <!--    </ng-select>-->
            <!--  </div>-->

            <!--</div>-->

          </div>
        </div>

        <div class="is-loading loader-content" *ngIf="loaderContent"></div>

        <div *ngIf="!loaderContent" class="row">
          <!-- ESTABLISHMENTS -->
          <div class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4 d-flex flex-column" *ngIf="!establishment">
            <div class="card flex-fill">
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col-auto align-self-start">
                    <i class="fe fe-briefcase"></i>
                  </div>

                  <div class="col overflow-hidden">
                    <h4 class="card-title name text-wrap mb-0">
                      <a
                        [routerLink]="['/registries/mine/' + activatedRoute.snapshot.params.registry_id + '/establishments']">
                        Établissements
                      </a>
                    </h4>
                  </div>

                  <div class="col-auto align-self-start">
                    <div class="dropdown">
                      <a class="dropdown-ellipses dropdown-toggle href" role="button"
                         [attr.aria-label]="'Raccourcis pour un établissement'" data-toggle="dropdown"
                         aria-haspopup="true" aria-expanded="false">
                        <i class="fe fe-more-vertical"></i>
                      </a>

                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item href"
                           [routerLink]="['/registries/mine/' + activatedRoute.snapshot.params.registry_id + '/establishments']">
                          Consulter les établissements
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- NEWS -->
          <div class="col-12" *ngIf="registry && registry.news && establishment">
            <div class="card bg-light border">
              <div class="card-body" [innerHTML]="registry.news">
              </div>
            </div>
          </div>

          <!-- TinyMCE -->
          <div class="col-12" *ngIf="registry && registry.id === 1 && establishment && modifyDescription">
            <div class="card bg-light border">
              <div class="card-body">
                <div class="is-loading loader-content" *ngIf="loader"></div>
                <div class="bandeau" *ngIf="!edit && !loader">
                  <div *ngIf="description">
                    <span [innerHTML]="showDescription"></span>
                  </div>
                  <div *ngIf="!description">
                    <span style="font-size: 12px; font-style: italic">Pas de description.</span>
                  </div>
                  <div class="d-flex justify-content-end" *ngIf="canUpdate">
                    <a class="btn btn-primary d-block d-md-inline-block text-white mt-2" (click)="checkIfEdit(edit)">
                      Modifier
                    </a>
                  </div>
                </div>
                <div class="tinyMce" [hidden]="!edit || loader || !canUpdate">
                  <div class="is-loading loader-content" *ngIf="loaderContent"></div>
                  <editor initialValue=""
                          [(ngModel)]="description"
                          [init]="{ height: 500, menubar: false, plugins: ['link preview table code lists'],
                                toolbar:'undo redo | formatselect fontselect fontsizeselect | ' +
                                'bold italic underline strikethrough backcolor forecolor | preview | ' +
                                'alignleft aligncenter alignright alignjustify | ' +
                                'bullist numlist outdent indent link table'
                              }">
                  </editor>
                  <div class="d-flex justify-content-between">
                    <a class="btn btn-primary d-block d-md-inline-block text-white mt-2 mr-2" (click)="checkIfEdit(edit)">
                      Annuler
                    </a>

                    <a class="btn btn-primary d-block d-md-inline-block text-white mt-2" (click)="updateEstablishmentDescriptive()">
                      Sauvegarder
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- DASHBOARD ACCESSIBILITY -->
          <div class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4 d-flex flex-column"
               *ngIf="establishment && (registry && registry.report_worksheets_module) && !limited">
            <div class="card flex-fill">
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col-auto align-self-start">
                    <i class="fe fe-clipboard"></i>
                  </div>

                  <div class="col overflow-hidden">
                    <h4 class="card-title name text-wrap mb-0">
                      <a [routerLink]="['report-worksheets']">
                        Suivi des travaux
                      </a>
                    </h4>
                  </div>

                  <div class="col-auto align-self-start">
                    <div class="dropdown">
                      <a class="dropdown-ellipses dropdown-toggle href" role="button"
                         [attr.aria-label]="'Raccourcis des suivis de travaux'" data-toggle="dropdown"
                         aria-haspopup="true" aria-expanded="false">
                        <i class="fe fe-more-vertical"></i>
                      </a>

                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item href" [routerLink]="['report-worksheets/create']">
                          Ajouter un suivi des travaux
                        </a>

                        <a class="dropdown-item href" [routerLink]="['report-worksheets']">
                          Listes des suivi des travaux
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- INSTALLATIONS -->
          <div class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4 d-flex flex-column"
               *ngIf="establishment && registry && registry.checkpoints_module">
            <div class="card flex-fill">
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col-auto align-self-start">
                    <i class="fe fe-link-2"></i>
                  </div>

                  <div class="col overflow-hidden">
                    <h4 class="card-title name text-wrap mb-0">
                      <a [routerLink]="['installations']">
                        {{ registry.checkpoints_name }}
                      </a>
                    </h4>
                  </div>

                  <div class="col-auto align-self-start">
                    <div class="dropdown">
                      <a class="dropdown-ellipses dropdown-toggle href" role="button"
                         [attr.aria-label]="'Actions rapide des installations'" data-toggle="dropdown"
                         aria-haspopup="true" aria-expanded="false">
                        <i class="fe fe-more-vertical"></i>
                      </a>

                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item href" [routerLink]="['installations']">
                          Consulter les installations
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- CHECKPOINTS -->
          <div class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4 d-flex flex-column"
               *ngIf="establishment && registry && registry.checkpoints_direct_module && !limited">
            <div class="card flex-fill">
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col-auto align-self-start">
                    <i class="fe fe-link-2"></i>
                  </div>

                  <div class="col overflow-hidden">
                    <h4 class="card-title name text-wrap mb-0">
                      <a [routerLink]="['installations/' + registry.checkpoints_direct_id + '/checkpoints']">
                        {{ registry.checkpoints_direct_name }}
                      </a>
                    </h4>
                  </div>

                  <div class="col-auto align-self-start">
                    <div class="dropdown">
                      <a class="dropdown-ellipses dropdown-toggle href" role="button"
                         [attr.aria-label]="'Raccourcis des installations'" data-toggle="dropdown"
                         aria-haspopup="true" aria-expanded="false">
                        <i class="fe fe-more-vertical"></i>
                      </a>

                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item href" [routerLink]="['installations']">
                          Consulter les installations
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- TRAININGS -->
          <div class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4 d-flex flex-column"
               *ngIf="establishment && (registry && registry.formations_module) && !limited">
            <div class="card flex-fill">
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col-auto align-self-start">
                    <i class="fe fe-calendar" [ngClass]="{ 'discover-color' : discoverOffer }"></i>
                  </div>

                  <div class="col overflow-hidden">
                    <h4 class="card-title name text-wrap mb-0">
                      <a [routerLink]="['trainings']" *ngIf="!discoverOffer">
                        Formations
                      </a>
                      <a class="discover-color href tooltipHtml" title="Veuillez souscrire à une offre pour débloquer le contenu" *ngIf="discoverOffer">
                        Formations
                      </a>
                    </h4>
                  </div>

                  <div class="col-auto align-self-start">
                    <div class="dropdown">
                      <a class="dropdown-ellipses dropdown-toggle href" role="button"
                         [attr.aria-label]="'Raccourcis des formations'" data-toggle="dropdown"
                         aria-haspopup="true" aria-expanded="false">
                        <i class="fe fe-more-vertical" [ngClass]="{ 'discover-color' : discoverOffer }"></i>
                      </a>

                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item href" [routerLink]="['trainings/create']" *ngIf="!discoverOffer">
                          Ajouter une formation
                        </a>
                        <a class="dropdown-item href discover-color tooltipHtml" title="Veuillez souscrire à une offre pour débloquer le contenu" *ngIf="discoverOffer">
                          Ajouter une formation
                        </a>

                        <a class="dropdown-item href" [routerLink]="['trainings']" *ngIf="!discoverOffer">
                          Consulter les formations
                        </a>
                        <a class="dropdown-item href discover-color tooltipHtml" title="Veuillez souscrire à une offre pour débloquer le contenu" *ngIf="discoverOffer">
                          Consulter les formations
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- COMMISSIONS -->
          <div class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4 d-flex flex-column"
               *ngIf="establishment && (registry && registry.commissions_module) && !limited">
            <div class="card flex-fill">
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col-auto align-self-start">
                    <i class="fe fe-clipboard" [ngClass]="{ 'discover-color' : discoverOffer }"></i>
                  </div>

                  <div class="col overflow-hidden">
                    <h4 class="card-title name text-wrap mb-0">
                      <a [routerLink]="['commissions']" *ngIf="!discoverOffer">
                        {{ registry.commissions_name }}
                      </a>
                      <a class="discover-color href tooltipHtml" title="Veuillez souscrire à une offre pour débloquer le contenu" *ngIf="discoverOffer">
                        {{ registry.commissions_name }}
                      </a>
                    </h4>
                  </div>

                  <div class="col-auto align-self-start">
                    <div class="dropdown">
                      <a class="dropdown-ellipses dropdown-toggle href" role="button"
                         [attr.aria-label]="'Raccourcis des commissions de sécurité'" data-toggle="dropdown"
                         aria-haspopup="true" aria-expanded="false">
                        <i class="fe fe-more-vertical" [ngClass]="{ 'discover-color' : discoverOffer }"></i>
                      </a>

                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item href" [routerLink]="['commissions/create']" *ngIf="!discoverOffer">
                          Ajouter une commission de sécurité
                        </a>
                        <a class="dropdown-item href discover-color tooltipHtml" title="Veuillez souscrire à une offre pour débloquer le contenu" *ngIf="discoverOffer">
                          Ajouter une commission de sécurité
                        </a>

                        <a class="dropdown-item href" [routerLink]="['commissions']" *ngIf="!discoverOffer">
                          Consulter les commissions de sécurité
                        </a>
                        <a class="dropdown-item href discover-color tooltipHtml" title="Veuillez souscrire à une offre pour débloquer le contenu" *ngIf="discoverOffer">
                          Consulter les commissions de sécurité
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- CORRECTIVE ACTIONS -->
          <div class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4 d-flex flex-column"
               *ngIf="registry && establishment && registry.cotations_module">
            <div class="card flex-fill">
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col-auto align-self-start">
                    <i class="fe fe-alert-triangle"></i>
                  </div>

                  <div class="col overflow-hidden">
                    <h4 class="card-title name text-wrap mb-0">
                      <a [routerLink]="['correctives-actions']">
                        Actions correctives
                      </a>
                    </h4>
                  </div>

                  <div class="col-auto align-self-start">
                    <div class="dropdown">
                      <a class="dropdown-ellipses dropdown-toggle href" role="button"
                         [attr.aria-label]="'Raccourcis des actions correctives'" data-toggle="dropdown"
                         aria-haspopup="true" aria-expanded="false">
                        <i class="fe fe-more-vertical"></i>
                      </a>

                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item href" [routerLink]="['correctives-actions']">
                          Consulter les actions correctives
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- SANITARY RECORD - CONTRIBUTORS-->
          <div class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4 d-flex flex-column"
               *ngIf="establishment && (registry && registry.sanitary_record_module)">
            <div class="card flex-fill">
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col-auto align-self-start">
                    <i class="fe fe-user"></i>
                  </div>

                  <div class="col overflow-hidden">
                    <h4 class="card-title name text-wrap mb-0">
                      <a [routerLink]="['sanitary-contributors']">
                        Intervenants
                      </a>
                    </h4>
                  </div>

                  <div class="col-auto align-self-start">
                    <div class="dropdown">
                      <a class="dropdown-ellipses dropdown-toggle href" role="button"
                         [attr.aria-label]="'Raccourcis des intervenants'" data-toggle="dropdown"
                         aria-haspopup="true" aria-expanded="false">
                        <i class="fe fe-more-vertical"></i>
                      </a>

                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item href" [routerLink]="['sanitary-contributors/create']">
                          Ajouter un intervenant
                        </a>

                        <a class="dropdown-item href" [routerLink]="['sanitary-contributors']">
                          Consulter les intervenants
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- SANITARY RECORD - INSTALLATION-->
          <div class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4 d-flex flex-column"
               *ngIf="establishment && (registry && registry.sanitary_record_module)">
            <div class="card flex-fill">
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col-auto align-self-start">
                    <i class="fe fe-link-2"></i>
                  </div>

                  <div class="col overflow-hidden">
                    <h4 class="card-title name text-wrap mb-0">
                      <a [routerLink]="['sanitary-installations']">
                        Description des installations
                      </a>
                    </h4>
                  </div>

                  <div class="col-auto align-self-start">
                    <div class="dropdown">
                      <a class="dropdown-ellipses dropdown-toggle href" role="button"
                         [attr.aria-label]="'Raccourcis des installations'" data-toggle="dropdown"
                         aria-haspopup="true" aria-expanded="false">
                        <i class="fe fe-more-vertical"></i>
                      </a>

                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item href" [routerLink]="['sanitary-installations/create']">
                          Ajouter une installation
                        </a>

                        <a class="dropdown-item href" [routerLink]="['sanitary-installations']">
                          Consulter les installations
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- SANITARY RECORD - WORKSHEET -->
          <div class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4 d-flex flex-column"
               *ngIf="establishment && (registry && registry.sanitary_record_module)">
            <div class="card flex-fill">
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col-auto align-self-start">
                    <i class="fe fe-edit"></i>
                  </div>

                  <div class="col overflow-hidden">
                    <h4 class="card-title name text-wrap mb-0">
                      <a [routerLink]="['sanitary-worksheets']">
                        Traçabilité des travaux
                      </a>
                    </h4>
                  </div>

                  <div class="col-auto align-self-start">
                    <div class="dropdown">
                      <a class="dropdown-ellipses dropdown-toggle href" role="button"
                         [attr.aria-label]="'Raccourcis des fiches travaux'" data-toggle="dropdown"
                         aria-haspopup="true" aria-expanded="false">
                        <i class="fe fe-more-vertical"></i>
                      </a>

                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item href" [routerLink]="['sanitary-worksheets/create']">
                          Créer une fiche travaux
                        </a>

                        <a class="dropdown-item href" [routerLink]="['sanitary-worksheets']">
                          Consulter les fiches travaux
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- SANITARY RECORD - OPERATIONS -->
          <div class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4 d-flex flex-column"
               *ngIf="establishment && (registry && registry.sanitary_record_module)">
            <div class="card flex-fill">
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col-auto align-self-start">
                    <i class="fe fe-edit"></i>
                  </div>

                  <div class="col overflow-hidden">
                    <h4 class="card-title name text-wrap mb-0">
                      <a [routerLink]="['sanitary-operations']">
                        Opérations de maintenance, entretien
                      </a>
                    </h4>
                  </div>

                  <div class="col-auto align-self-start">
                    <div class="dropdown">
                      <a class="dropdown-ellipses dropdown-toggle href" role="button"
                         [attr.aria-label]="'Raccourcis des opérations de maintenance, entretien'" data-toggle="dropdown"
                         aria-haspopup="true" aria-expanded="false">
                        <i class="fe fe-more-vertical"></i>
                      </a>

                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item href" [routerLink]="['sanitary-operations/create']">
                          Ajouter une opération de maintenance, entretien
                        </a>

                        <a class="dropdown-item href" [routerLink]="['sanitary-operations']">
                          Consulter les opérations de maintenance, entretien
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- SANITARY RECORD - SURVEILLANCE -->
          <div class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4 d-flex flex-column"
               *ngIf="establishment && (registry && registry.sanitary_record_module)">
            <div class="card flex-fill">
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col-auto align-self-start">
                    <i class="fe fe-eye"></i>
                  </div>

                  <div class="col overflow-hidden">
                    <h4 class="card-title name text-wrap mb-0">
                      <a [routerLink]="['sanitary-surveillances']">
                        Opérations de surveillance
                      </a>
                    </h4>
                  </div>

                  <div class="col-auto align-self-start">
                    <div class="dropdown">
                      <a class="dropdown-ellipses dropdown-toggle href" role="button"
                         [attr.aria-label]="'Raccourcis des opérations des surveillances'" data-toggle="dropdown"
                         aria-haspopup="true" aria-expanded="false">
                        <i class="fe fe-more-vertical"></i>
                      </a>

                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item href" [routerLink]="['sanitary-surveillances']">
                          Consulter les opérations de surveillance
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- EXPERTISES -->
          <div class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4 d-flex flex-column"
               *ngIf="establishment && (registry && registry.expertises_module) && !limited">
            <div class="card flex-fill">
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col-auto align-self-start">
                    <i class="fe fe-clipboard" [ngClass]="{ 'discover-color' : discoverOffer }"></i>
                  </div>

                  <div class="col overflow-hidden">
                    <h4 class="card-title name text-wrap mb-0">
                      <a [routerLink]="['expertises']" *ngIf="!discoverOffer">
                        {{ registry.expertises_name }}
                      </a>
                      <a class="discover-color href tooltipHtml" title="Veuillez souscrire à une offre pour débloquer le contenu" *ngIf="discoverOffer">
                        {{ registry.expertises_name }}
                      </a>
                    </h4>
                  </div>

                  <div class="col-auto align-self-start">
                    <div class="dropdown">
                      <a class="dropdown-ellipses dropdown-toggle href" role="button"
                         [attr.aria-label]="'Raccourcis des rapports'" data-toggle="dropdown"
                         aria-haspopup="true" aria-expanded="false">
                        <i class="fe fe-more-vertical" [ngClass]="{ 'discover-color' : discoverOffer }"></i>
                      </a>

                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item href" [routerLink]="['commissions/create']" *ngIf="!discoverOffer">
                          Ajouter un rapport
                        </a>
                        <a class="dropdown-item href discover-color tooltipHtml" title="Veuillez souscrire à une offre pour débloquer le contenu" *ngIf="discoverOffer">
                          Ajouter un rapport
                        </a>

                        <a class="dropdown-item href" [routerLink]="['commissions']" *ngIf="!discoverOffer">
                          Consulter les rapports
                        </a>
                        <a class="dropdown-item href discover-color tooltipHtml" title="Veuillez souscrire à une offre pour débloquer le contenu" *ngIf="discoverOffer">
                          Consulter les rapports
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- RUS -->
          <div class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4 d-flex flex-column" *ngIf="isRus || (isRus && discoverOffer)">
            <div class="card flex-fill">
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col-auto align-self-start">
                    <i class="fe fe-clipboard" [ngClass]="{ 'discover-color' : discoverOffer }"></i>
                  </div>

                  <div class="col overflow-hidden">
                    <h4 class="card-title name text-wrap mb-0">
                      <a [routerLink]="['officials']" *ngIf="!discoverOffer">
                        Responsable unique de sécurité
                      </a>
                      <a class="discover-color href tooltipHtml" title="Veuillez souscrire à une offre pour débloquer le contenu" *ngIf="discoverOffer">
                        Responsable unique de sécurité
                      </a>
                    </h4>
                  </div>

                  <div class="col-auto align-self-start">
                    <div class="dropdown">
                      <a class="dropdown-ellipses dropdown-toggle href" role="button"
                         [attr.aria-label]="'Raccourcis des responsables unique de sécurité'" data-toggle="dropdown"
                         aria-haspopup="true" aria-expanded="false">
                        <i class="fe fe-more-vertical" [ngClass]="{ 'discover-color' : discoverOffer }"></i>
                      </a>

                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item href" [routerLink]="['officials/create']" *ngIf="!discoverOffer">
                          Ajouter un responsable unique de sécurité
                        </a>
                        <a class="dropdown-item href discover-color tooltipHtml" title="Veuillez souscrire à une offre pour débloquer le contenu" *ngIf="discoverOffer">
                          Ajouter un responsable unique de sécurité
                        </a>

                        <a class="dropdown-item href" [routerLink]="['officials']" *ngIf="!discoverOffer">
                          Consulter les responsable unique de sécurité
                        </a>
                        <a class="dropdown-item href discover-color tooltipHtml" title="Veuillez souscrire à une offre pour débloquer le contenu" *ngIf="discoverOffer">
                          Consulter les responsable unique de sécurité
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- PREVENTION -->
          <div class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4 d-flex flex-column"
               *ngIf="establishment && (registry && registry.fire_permits_module) && !limited">
            <div class="card flex-fill">
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col-auto align-self-start">
                    <i class="fe fe-clipboard"></i>
                  </div>

                  <div class="col overflow-hidden">
                    <h4 class="card-title name text-wrap mb-0">
                      <a [routerLink]="['preventions']">
                        Plan de prévention
                      </a>
                    </h4>
                  </div>

                  <div class="col-auto align-self-start">
                    <div class="dropdown">
                      <a class="dropdown-ellipses dropdown-toggle href" role="button"
                         [attr.aria-label]="'Raccourcis des permis feu'" data-toggle="dropdown"
                         aria-haspopup="true" aria-expanded="false">
                        <i class="fe fe-more-vertical"></i>
                      </a>

                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item href" [routerLink]="['preventions/create']">
                          Ajouter un plan de prévention
                        </a>

                        <a class="dropdown-item href" [routerLink]="['preventions']">
                          Consulter les plans de prévention
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- PREVENTION SIMPLIFIED -->
          <div class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4 d-flex flex-column"
               *ngIf="establishment && (registry && registry.fire_permits_module) && !limited">
            <div class="card flex-fill">
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col-auto align-self-start">
                    <i class="fe fe-clipboard"></i>
                  </div>

                  <div class="col overflow-hidden">
                    <h4 class="card-title name text-wrap mb-0">
                      <a [routerLink]="['preventions-simplified']">
                        Plan de prévention simplifié <i class="fe fe-info ml-2 tooltipHtml" title="Pour les opérations de moins de 400 heures"></i>
                      </a>
                    </h4>
                  </div>

                  <div class="col-auto align-self-start">
                    <div class="dropdown">
                      <a class="dropdown-ellipses dropdown-toggle href" role="button"
                         [attr.aria-label]="'Raccourcis des permis feu'" data-toggle="dropdown"
                         aria-haspopup="true" aria-expanded="false">
                        <i class="fe fe-more-vertical"></i>
                      </a>

                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item href" [routerLink]="['preventions-simplified/create']">
                          Ajouter un plan de prévention
                        </a>

                        <a class="dropdown-item href" [routerLink]="['preventions-simplified']">
                          Consulter les plans de prévention
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- PREVENTION RISKS -->
          <div class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4 d-flex flex-column"
               *ngIf="establishment && (registry && registry.fire_permits_module) && !limited">
            <div class="card flex-fill">
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col-auto align-self-start">
                    <i class="fe fe-clipboard"></i>
                  </div>

                  <div class="col overflow-hidden">
                    <h4 class="card-title name text-wrap mb-0">
                      <a [routerLink]="['prevention-risks']">
                        Plan de prévention - Risques
                      </a>
                    </h4>
                  </div>

                  <div class="col-auto align-self-start">
                    <div class="dropdown">
                      <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown"
                         aria-haspopup="true" aria-expanded="false">
                        <i class="fe fe-more-vertical"></i>
                      </a>

                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item href" [routerLink]="['prevention-risks/create']">
                          Ajouter un risque
                        </a>

                        <a class="dropdown-item href" [routerLink]="['prevention-risks']">
                          Consulter les risques
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- FIRE PERMIT -->
          <div class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4 d-flex flex-column"
               *ngIf="establishment && (registry && registry.fire_permits_module) && !limited">
            <div class="card flex-fill">
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col-auto align-self-start">
                    <i class="fe fe-clipboard"></i>
                  </div>

                  <div class="col overflow-hidden">
                    <h4 class="card-title name text-wrap mb-0">
                      <a [routerLink]="['fire-permits']">
                        Permis feu
                      </a>
                    </h4>
                  </div>

                  <div class="col-auto align-self-start">
                    <div class="dropdown">
                      <a class="dropdown-ellipses dropdown-toggle href" role="button"
                         [attr.aria-label]="'Raccourcis des permis feu'" data-toggle="dropdown"
                         aria-haspopup="true" aria-expanded="false">
                        <i class="fe fe-more-vertical"></i>
                      </a>

                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item href" [routerLink]="['fire-permits/create']">
                          Ajouter un permis feu
                        </a>

                        <a class="dropdown-item href" [routerLink]="['fire-permits']">
                          Consulter les permis feu
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- SECURITY PROTOCOL -->
          <div class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4 d-flex flex-column"
               *ngIf="establishment && (registry && registry.fire_permits_module) && !limited">
            <div class="card flex-fill">
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col-auto align-self-start">
                    <i class="fe fe-clipboard"></i>
                  </div>

                  <div class="col overflow-hidden">
                    <h4 class="card-title name text-wrap mb-0">
                      <a [routerLink]="['security-protocols']">
                        Protocole de sécurité
                      </a>
                    </h4>
                  </div>

                  <div class="col-auto align-self-start">
                    <div class="dropdown">
                      <a class="dropdown-ellipses dropdown-toggle href" role="button"
                         [attr.aria-label]="'Raccourcis des protocoles de sécurité'" data-toggle="dropdown"
                         aria-haspopup="true" aria-expanded="false">
                        <i class="fe fe-more-vertical"></i>
                      </a>

                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item href" [routerLink]="['security-protocols/create']">
                          Ajouter un protocole de sécurité
                        </a>

                        <a class="dropdown-item href" [routerLink]="['security-protocols']">
                          Consulter les protocoles de sécurité
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- COTATION -->
          <div class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4 d-flex flex-column"
               *ngIf="registry && registry.cotations_module">
            <div class="card flex-fill">
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col-auto align-self-start">
                    <i class="fe fe-link-2"></i>
                  </div>

                  <div class="col overflow-hidden">
                    <h4 class="card-title name text-wrap mb-0">
                      <a [routerLink]="['cotations']">
                        Échelles de cotation
                      </a>
                    </h4>
                  </div>

                  <div class="col-auto align-self-start">
                    <div class="dropdown">
                      <a class="dropdown-ellipses dropdown-toggle href" role="button"
                         [attr.aria-label]="'Raccourcis des échelles de cotation'" data-toggle="dropdown"
                         aria-haspopup="true" aria-expanded="false">
                        <i class="fe fe-more-vertical"></i>
                      </a>

                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item href" [routerLink]="['cotations']">
                          Consulter les échelles de cotation
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- RISKS UNITIES -->
          <div class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4 d-flex flex-column"
               *ngIf="registry && registry.cotations_module">
            <div class="card flex-fill">
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col-auto align-self-start">
                    <i class="fe fe-alert-triangle"></i>
                  </div>

                  <div class="col overflow-hidden">
                    <h4 class="card-title name text-wrap mb-0">
                      <a [routerLink]="['risks-unities']">
                        Unités de travail
                      </a>
                    </h4>
                  </div>

                  <div class="col-auto align-self-start">
                    <div class="dropdown">
                      <a class="dropdown-ellipses dropdown-toggle href" role="button"
                         [attr.aria-label]="'Raccourcis des unités de travail'" data-toggle="dropdown"
                         aria-haspopup="true" aria-expanded="false">
                        <i class="fe fe-more-vertical"></i>
                      </a>

                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item href" [routerLink]="['risks-unities']">
                          Consulter les unités de travail
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- RISKS USERS -->
          <div class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4 d-flex flex-column"
               *ngIf="registry && registry.cotations_module">
            <div class="card flex-fill">
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col-auto align-self-start">
                    <i class="fe fe-alert-triangle"></i>
                  </div>

                  <div class="col overflow-hidden">
                    <h4 class="card-title name text-wrap mb-0">
                      <a [routerLink]="['risks-users']">
                        Personnes concernées par les risques
                      </a>
                    </h4>
                  </div>

                  <div class="col-auto align-self-start">
                    <div class="dropdown">
                      <a class="dropdown-ellipses dropdown-toggle href" role="button"
                         [attr.aria-label]="'Raccourcis des personnes concernées par les risques'"
                         data-toggle="dropdown"
                         aria-haspopup="true" aria-expanded="false">
                        <i class="fe fe-more-vertical"></i>
                      </a>

                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item href" [routerLink]="['risks-users']">
                          Consulter les personnes concernées par les risques
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- RISKS -->
          <div class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4 d-flex flex-column"
               *ngIf="registry && establishment && registry.cotations_module">
            <div class="card flex-fill">
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col-auto align-self-start">
                    <i class="fe fe-alert-triangle"></i>
                  </div>

                  <div class="col overflow-hidden">
                    <h4 class="card-title name text-wrap mb-0">
                      <a [routerLink]="['risks']">
                        Risques
                      </a>
                    </h4>
                  </div>

                  <div class="col-auto align-self-start">
                    <div class="dropdown">
                      <a class="dropdown-ellipses dropdown-toggle href" role="button"
                         [attr.aria-label]="'Raccourcis des risques'" data-toggle="dropdown"
                         aria-haspopup="true" aria-expanded="false">
                        <i class="fe fe-more-vertical"></i>
                      </a>

                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item href" [routerLink]="['risks']">
                          Consulter les risques
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- AGENCIES -->
          <div class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4 d-flex flex-column"
               *ngIf="establishment && (registry && registry.agencies_module) && !limited">
            <div class="card flex-fill">
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col-auto align-self-start">
                    <i class="fe fe-clipboard"></i>
                  </div>

                  <div class="col overflow-hidden">
                    <h4 class="card-title name text-wrap mb-0">
                      <a [routerLink]="['agencies']">
                        {{ registry.agencies_name }}
                      </a>
                    </h4>
                  </div>

                  <div class="col-auto align-self-start">
                    <div class="dropdown">
                      <a class="dropdown-ellipses dropdown-toggle href" role="button"
                         [attr.aria-label]="'Raccourcis des rapports'" data-toggle="dropdown"
                         aria-haspopup="true" aria-expanded="false">
                        <i class="fe fe-more-vertical"></i>
                      </a>

                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item href" [routerLink]="['commissions/create']">
                          Ajouter un rapport
                        </a>

                        <a class="dropdown-item href" [routerLink]="['commissions']">
                          Consulter les rapports
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- EMPLOYEES -->
          <div class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4 d-flex flex-column"
               *ngIf="establishment && (registry && registry.employees_module) && !limited">
            <div class="card flex-fill">
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col-auto align-self-start">
                    <i class="fe fe-user" [ngClass]="{ 'discover-color' : discoverOffer }"></i>
                  </div>

                  <div class="col overflow-hidden">
                    <h4 class="card-title name text-wrap mb-0">
                      <a [routerLink]="['employees']" *ngIf="!discoverOffer">
                        Membres du personnel
                      </a>
                      <a class="discover-color href tooltipHtml" title="Veuillez souscrire à une offre pour débloquer le contenu" *ngIf="discoverOffer">
                        Membres du personnel
                      </a>
                    </h4>
                  </div>

                  <div class="col-auto align-self-start">
                    <div class="dropdown">
                      <a class="dropdown-ellipses dropdown-toggle href" role="button"
                         [attr.aria-label]="'Raccourcis des membres du personnel'" data-toggle="dropdown"
                         aria-haspopup="true" aria-expanded="false">
                        <i class="fe fe-more-vertical" [ngClass]="{ 'discover-color' : discoverOffer }"></i>
                      </a>

                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item href" [routerLink]="['employees/create']" *ngIf="!discoverOffer">
                          Ajouter un membre du personnel
                        </a>
                        <a class="dropdown-item href discover-color tooltipHtml" title="Veuillez souscrire à une offre pour débloquer le contenu" *ngIf="discoverOffer">
                          Ajouter un membre du personnel
                        </a>

                        <a class="dropdown-item href" [routerLink]="['employees']" *ngIf="!discoverOffer">
                          Consulter les membres du personnel
                        </a>
                        <a class="dropdown-item href discover-color tooltipHtml" title="Veuillez souscrire à une offre pour débloquer le contenu" *ngIf="discoverOffer">
                          Consulter les membres du personnel
                        </a>

                        <a class="dropdown-item href" [routerLink]="['employee/logs']" *ngIf="canLogEmployee && !discoverOffer">
                          Historique des actions
                        </a>
                        <a class="dropdown-item href discover-color tooltipHtml" title="Veuillez souscrire à une offre pour débloquer le contenu" *ngIf="canLogEmployee && discoverOffer">
                          Historique des actions
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- EMPLOYEES TRAININGS -->
          <div class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4 d-flex flex-column"
               *ngIf="establishment && (registry && registry.formations_module) && !limited"
               style="display: none !important">
            <div class="card flex-fill">
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col-auto align-self-start">
                    <i class="fe fe-user"></i>
                  </div>

                  <div class="col overflow-hidden">
                    <h4 class="card-title name text-wrap mb-0">
                      <a [routerLink]="['employees-trainings']">
                        Membres du personnel - Formations
                      </a>
                    </h4>
                  </div>

                  <div class="col-auto align-self-start">
                    <div class="dropdown">
                      <a class="dropdown-ellipses dropdown-toggle href" role="button"
                         [attr.aria-label]="'Raccourcis des membres de personnel des formations'" data-toggle="dropdown"
                         aria-haspopup="true" aria-expanded="false">
                        <i class="fe fe-more-vertical"></i>
                      </a>

                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item href" [routerLink]="['employees-trainings']">
                          Consulter les membres du personnel
                        </a>

                        <a class="dropdown-item href" [routerLink]="['employee-training/logs']">
                          Historique des actions
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- WORKSHEETS -->
          <div class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4 d-flex flex-column"
               *ngIf="establishment && (registry && registry.worksheets_module) && !limited">
            <div class="card flex-fill">
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col-auto align-self-start">
                    <i class="fe fe-clipboard" [ngClass]="{ 'discover-color' : discoverOffer }"></i>
                  </div>

                  <div class="col overflow-hidden">
                    <h4 class="card-title name text-wrap mb-0">
                      <a [routerLink]="['worksheets']" *ngIf="!discoverOffer">
                        Fiche travaux
                      </a>
                      <a class="discover-color href tooltipHtml" title="Veuillez souscrire à une offre pour débloquer le contenu" *ngIf="discoverOffer">
                        Fiche travaux
                      </a>
                    </h4>
                  </div>

                  <div class="col-auto align-self-start">
                    <div class="dropdown">
                      <a class="dropdown-ellipses dropdown-toggle href" role="button"
                         [attr.aria-label]="'Raccourcis des fiches travaux'" data-toggle="dropdown"
                         aria-haspopup="true" aria-expanded="false">
                        <i class="fe fe-more-vertical" [ngClass]="{ 'discover-color' : discoverOffer }"></i>
                      </a>

                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item href" [routerLink]="['worksheets/create']" *ngIf="!discoverOffer">
                          Ajouter une fiche travaux
                        </a>
                        <a class="dropdown-item href discover-color tooltipHtml" title="Veuillez souscrire à une offre pour débloquer le contenu" *ngIf="discoverOffer">
                          Ajouter une fiche travaux
                        </a>

                        <a class="dropdown-item href" [routerLink]="['worksheets']" *ngIf="!discoverOffer">
                          Consulter les fiches travaux
                        </a>
                        <a class="dropdown-item href discover-color tooltipHtml" title="Veuillez souscrire à une offre pour débloquer le contenu" *ngIf="discoverOffer">
                          Consulter les fiches travaux
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- REGISTRY MANAGER -->
          <div class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4 d-flex flex-column"
               *ngIf="establishment && (registry && registry.has_manager_module) && !limited">
            <div class="card flex-fill">
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col-auto align-self-start">
                    <i class="fe fe-user"></i>
                  </div>

                  <div class="col overflow-hidden">
                    <h4 class="card-title name text-wrap mb-0">
                      <a [routerLink]="['registry-manager']">
                        Coordonnées du responsable
                      </a>
                    </h4>
                  </div>

                  <div class="col-auto align-self-start">
                    <div class="dropdown">
                      <a class="dropdown-ellipses dropdown-toggle href" role="button"
                         [attr.aria-label]="'Raccourcis du responsable'" data-toggle="dropdown"
                         aria-haspopup="true" aria-expanded="false">
                        <i class="fe fe-more-vertical"></i>
                      </a>

                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item href" [routerLink]="['registry-manager']">
                          Cordonnées du responsable
                        </a>

                        <a class="dropdown-item href" [routerLink]="['registry-manager/logs']">
                          Historique des actions
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ASBESTOS DETECTION -->
          <div class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4 d-flex flex-column"
               *ngIf="establishment && (registry && registry.asbestos_module) && !limited">
            <div class="card flex-fill">
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col-auto align-self-start">
                    <i class="fe fe-edit"></i>
                  </div>

                  <div class="col overflow-hidden">
                    <h4 class="card-title name text-wrap mb-0">
                      <a [routerLink]="['asbestos-detection']">
                        Repérage amiante
                      </a>
                    </h4>
                  </div>

                  <div class="col-auto align-self-start">
                    <div class="dropdown">
                      <a class="dropdown-ellipses dropdown-toggle href" role="button"
                         [attr.aria-label]="'Raccourcis des repérages amiantes'" data-toggle="dropdown"
                         aria-haspopup="true" aria-expanded="false">
                        <i class="fe fe-more-vertical"></i>
                      </a>

                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item href" [routerLink]="['asbestos-detection/create']">
                          Ajouter un repérage amiante
                        </a>

                        <a class="dropdown-item href" [routerLink]="['asbestos-detection']">
                          Consulter les repérages amiantes
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ASBESTOS WORK -->
          <div class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4 d-flex flex-column"
               *ngIf="establishment && (registry && registry.asbestos_module) && !limited">
            <div class="card flex-fill">
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col-auto align-self-start">
                    <i class="fe fe-edit"></i>
                  </div>

                  <div class="col overflow-hidden">
                    <h4 class="card-title name text-wrap mb-0">
                      <a [routerLink]="['asbestos-works']">
                        Travaux amiante
                      </a>
                    </h4>
                  </div>

                  <div class="col-auto align-self-start">
                    <div class="dropdown">
                      <a class="dropdown-ellipses dropdown-toggle href" role="button"
                         [attr.aria-label]="'Raccourcis des travaux amiantes'" data-toggle="dropdown"
                         aria-haspopup="true" aria-expanded="false">
                        <i class="fe fe-more-vertical"></i>
                      </a>

                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item href" [routerLink]="['asbestos-works/create']">
                          Ajouter des travaux amiantes
                        </a>

                        <a class="dropdown-item href" [routerLink]="['asbestos-works']">
                          Consulter les travaux amiantes
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ASBESTOS TECHNICAL FILE -->
          <div class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4 d-flex flex-column"
               *ngIf="establishment && (registry && registry.asbestos_module) && !limited">
            <div class="card flex-fill">
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col-auto align-self-start">
                    <i class="fe fe-edit"></i>
                  </div>

                  <div class="col overflow-hidden">
                    <h4 class="card-title name text-wrap mb-0">
                      <a [routerLink]="['asbestos-technical-file']">
                        Dossier technique amiante
                      </a>
                    </h4>
                  </div>

                  <div class="col-auto align-self-start">
                    <div class="dropdown">
                      <a class="dropdown-ellipses dropdown-toggle href" role="button"
                         [attr.aria-label]="'Raccourcis du dossier technique amiante'" data-toggle="dropdown"
                         aria-haspopup="true" aria-expanded="false">
                        <i class="fe fe-more-vertical"></i>
                      </a>

                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item href" [routerLink]="['asbestos-technical-file']">
                          Consulter le dossier technique amiante
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- CHILDREN -->
          <div class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4 d-flex flex-column"
               *ngIf="(establishment && registry && registry.rus_module && establishment && establishment.is_rus && !limited)
                || (establishment && establishment.is_rus && registry && registry.rus_module && !limited && discoverOffer)">
            <div class="card flex-fill">
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col-auto align-self-start">
                    <i class="fe fe-briefcase" [ngClass]="{ 'discover-color' : discoverOffer }"></i>
                  </div>

                  <div class="col overflow-hidden">
                    <h4 class="card-title name text-wrap mb-0">
                      <a [routerLink]="['children']" *ngIf="!discoverOffer">
                        Exploitants
                      </a>
                      <a class="discover-color href tooltipHtml" title="Veuillez souscrire à une offre pour débloquer le contenu" *ngIf="discoverOffer">
                        Exploitants
                      </a>
                    </h4>
                  </div>

                  <div class="col-auto align-self-start">
                    <div class="dropdown">
                      <a class="dropdown-ellipses dropdown-toggle href" role="button"
                         [attr.aria-label]="'Raccourcis des exploitants'" data-toggle="dropdown"
                         aria-haspopup="true" aria-expanded="false">
                        <i class="fe fe-more-vertical" [ngClass]="{ 'discover-color' : discoverOffer }"></i>
                      </a>

                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item href" [routerLink]="['children/create']" *ngIf="!discoverOffer">
                          Ajouter un exploitant
                        </a>
                        <a class="dropdown-item href discover-color tooltipHtml" title="Veuillez souscrire à une offre pour débloquer le contenu" *ngIf="discoverOffer">
                          Ajouter un exploitant
                        </a>

                        <a class="dropdown-item href" [routerLink]="['children']" *ngIf="!discoverOffer">
                          Consulter les exploitants
                        </a>
                        <a class="dropdown-item href discover-color tooltipHtml" title="Veuillez souscrire à une offre pour débloquer le contenu" *ngIf="discoverOffer">
                          Consulter les exploitants
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- FOLDERS -->
          <div class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4 d-flex flex-column" *ngFor="let folder of folders">
            <div class="card flex-fill" [ngStyle]="{ 'background-color' : folder.background_color }"
                 [ngClass]="{ 'is-important': folder.is_important, 'color-white': folder.background_color, 'is-discover': discoverOffer }">
              <div class="card-body">
                <span class="button__badge" *ngIf="folder.can_contain_file" [ngClass]="{ 'color-badge' : folder.generic_files.length }">
                  {{ !folder.generic_files.length ? '0' : folder.generic_files.length > 99 ? '99+' : folder.generic_files.length }}
                </span>

                <div class="row align-items-center">
                  <div class="col-auto align-self-start">
                    <ng-container *ngIf="!discoverOffer">
                      <i class="fe" [ngClass]="folder.is_important ? 'fe-alert-triangle' : 'fe-folder'"></i>
                    </ng-container>
                    <ng-container *ngIf="discoverOffer">
                      <i class="fe discover-color" [ngClass]="folder.is_important ? 'fe-alert-triangle' : 'fe-folder'"></i>
                    </ng-container>
                  </div>

                  <div class="col overflow-hidden">
                    <h4 class="card-title name text-wrap mb-0">
                      <a *ngIf="!discoverOffer"
                        [routerLink]="[(folder.can_contain_file && folder.generic_files.length === 0 && (establishment && establishment.parent_id === null)) ? 'document/' + folder.id + '/files/create' : 'document/' + folder.id]">
                        {{ folder.name }}
                      </a>
                      <a class="discover-color href tooltipHtml" title="Veuillez souscrire à une offre pour débloquer le contenu" *ngIf="discoverOffer">
                        {{ folder.name }}
                      </a>
                    </h4>
                  </div>

                  <div class="col-auto align-self-start">
                    <div class="dropdown">
                      <a class="dropdown-ellipses dropdown-toggle href" role="button"
                         [attr.aria-label]="'Raccourcis des documents'" data-toggle="dropdown"
                         aria-haspopup="true" aria-expanded="false">
                        <i class="fe fe-more-vertical" [ngClass]="{ 'discover-color' : discoverOffer }"></i>
                      </a>

                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item href" [routerLink]="['document/' + folder.id + '/files/create']"
                           *ngIf="folder.can_contain_file && !discoverOffer">
                          Ajouter un fichier
                        </a>
                        <a class="dropdown-item href discover-color tooltipHtml" title="Veuillez souscrire à une offre pour débloquer le contenu" *ngIf="folder.can_contain_file && discoverOffer">
                          Ajouter un fichier
                        </a>

                        <a class="dropdown-item href" [routerLink]="['document/' + folder.id]" *ngIf="!discoverOffer">
                          Consulter le contenu
                        </a>
                        <a class="dropdown-item href discover-color tooltipHtml" title="Veuillez souscrire à une offre pour débloquer le contenu" *ngIf="discoverOffer">
                          Consulter le contenu
                        </a>

                        <a class="dropdown-item href" [routerLink]="['document/' + folder.id + '/logs']" *ngIf="!discoverOffer">
                          Historique des actions
                        </a>
                        <a class="dropdown-item href discover-color tooltipHtml" title="Veuillez souscrire à une offre pour débloquer le contenu" *ngIf="discoverOffer">
                          Historique des actions
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- SANITARY RECORD - POOL -->
          <div class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4 d-flex flex-column"
               *ngIf="establishment && (registry.sanitary_record_module && registry.is_pool)">
            <div class="card flex-fill">
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col-auto align-self-start">
                    <i class="fe fe-droplet"></i>
                  </div>

                  <div class="col overflow-hidden">
                    <h4 class="card-title name text-wrap mb-0">
                      <a [routerLink]="['sanitary-pools']">
                        Piscine
                      </a>
                    </h4>
                  </div>

                  <div class="col-auto align-self-start">
                    <div class="dropdown">
                      <a class="dropdown-ellipses dropdown-toggle href" role="button"
                         [attr.aria-label]="'Raccourcis des piscines'" data-toggle="dropdown"
                         aria-haspopup="true" aria-expanded="false">
                        <i class="fe fe-more-vertical"></i>
                      </a>

                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item href" [routerLink]="['sanitary-pools/create']">
                          Ajouter une piscine
                        </a>

                        <a class="dropdown-item href" [routerLink]="['sanitary-pools']">
                          Consulter les piscines
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- SANITARY RECORD - MAINTENANCE FRAMES -->
          <div class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4 d-flex flex-column"
               *ngIf="establishment && registry.sanitary_record_module">
            <div class="card flex-fill">
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col-auto align-self-start">
                    <i class="fe fe-file-text"></i>
                  </div>

                  <div class="col overflow-hidden">
                    <h4 class="card-title name text-wrap mb-0">
                      <a [routerLink]="['sanitary-frames']">
                        Fiches de suivi
                      </a>
                    </h4>
                  </div>

                  <div class="col-auto align-self-start">
                    <div class="dropdown">
                      <a class="dropdown-ellipses dropdown-toggle href" role="button"
                         [attr.aria-label]="'Raccourcis des piscines'" data-toggle="dropdown"
                         aria-haspopup="true" aria-expanded="false">
                        <i class="fe fe-more-vertical"></i>
                      </a>

                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item href" [routerLink]="['sanitary-frames/create']">
                          Ajouter une fiche de suivi
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- PROVIDERS -->
          <div class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4 d-flex flex-column"
               *ngIf="registry && registry.checkpoints_module && !establishment">
            <div class="card flex-fill">
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col-auto align-self-start">
                    <i class="fe fe-cast"></i>
                  </div>

                  <div class="col overflow-hidden">
                    <h4 class="card-title name text-wrap mb-0">
                      <a [routerLink]="['/providers']">
                        Prestataires
                      </a>
                    </h4>
                  </div>

                  <div class="col-auto align-self-start">
                    <div class="dropdown">
                      <a class="dropdown-ellipses dropdown-toggle href" role="button"
                         [attr.aria-label]="'Raccourcis des prestataires'" data-toggle="dropdown"
                         aria-haspopup="true" aria-expanded="false">
                        <i class="fe fe-more-vertical"></i>
                      </a>

                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item href" [routerLink]="['/providers/create']">
                          Ajouter un prestataire
                        </a>

                        <a class="dropdown-item href" [routerLink]="['/providers']">
                          Consulter les prestataires
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- REGISTRY PROVIDERS -->
          <div class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4 d-flex flex-column"
               *ngIf="registry && registry.checkpoints_module && establishment">
            <div class="card flex-fill">
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col-auto align-self-start">
                    <i class="fe fe-cast"></i>
                  </div>

                  <div class="col overflow-hidden">
                    <h4 class="card-title name text-wrap mb-0">
                      <a [routerLink]="[currentRoute + '/providers']">
                        Prestataires
                      </a>
                    </h4>
                  </div>

                  <div class="col-auto align-self-start">
                    <div class="dropdown">
                      <a class="dropdown-ellipses dropdown-toggle href" role="button"
                         [attr.aria-label]="'Raccourcis des prestataires'" data-toggle="dropdown"
                         aria-haspopup="true" aria-expanded="false">
                        <i class="fe fe-more-vertical"></i>
                      </a>

                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item href" [routerLink]="['/providers/create']">
                          Ajouter un prestataire
                        </a>

                        <a class="dropdown-item href" [routerLink]="['/providers']">
                          Consulter les prestataires
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- USERS -->
          <div class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4 d-flex flex-column" *ngIf="!establishment &&
           (registry && (!registry.is_mono_offer || registry.is_discover_offer))">
            <div class="card flex-fill">
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col-auto align-self-start">
                    <i class="fe fe-user" [ngClass]="{ 'discover-color' : discoverOffer }"></i>
                  </div>

                  <div class="col overflow-hidden">
                    <h4 class="card-title name text-wrap mb-0">
                      <a [routerLink]="['/users/mine']" *ngIf="!discoverOffer">
                        Utilisateurs
                      </a>
                      <a class="discover-color href tooltipHtml" title="Veuillez souscrire à une offre pour débloquer le contenu" *ngIf="discoverOffer">
                        Utilisateurs
                      </a>
                    </h4>
                  </div>

                  <div class="col-auto align-self-start">
                    <div class="dropdown">
                      <a class="dropdown-ellipses dropdown-toggle href" role="button"
                         [attr.aria-label]="'Raccourcis des utilisateurs'" data-toggle="dropdown"
                         aria-haspopup="true" aria-expanded="false">
                        <i class="fe fe-more-vertical" [ngClass]="{ 'discover-color' : discoverOffer }"></i>
                      </a>

                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item href" [routerLink]="['/users/mine/create']" *ngIf="!discoverOffer">
                          Ajouter un utilisateur
                        </a>
                        <a class="dropdown-item href discover-color tooltipHtml" title="Veuillez souscrire à une offre pour débloquer le contenu" *ngIf="discoverOffer">
                          Ajouter un utilisateur
                        </a>

                        <a class="dropdown-item href" [routerLink]="['/users/mine']" *ngIf="!discoverOffer">
                          Consulter les utilisateurs
                        </a>
                        <a class="dropdown-item href discover-color tooltipHtml" title="Veuillez souscrire à une offre pour débloquer le contenu" *ngIf="discoverOffer">
                          Consulter les utilisateurs
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- DASHBOARD TABLE -->
          <div class="col-12 d-flex flex-column" *ngIf="registry">
            <!-- FILTER -->
            <div *ngIf="!loaderContent && checkIfFilter()">
              <div class="card flex-fill">
                <div class="card-body">
                  <div class="row mb-05">
                    <div class="col">
                      <h3 class="header-title">Filtres</h3>

                      <small class="form-text text-muted">
                        <span>Séléctionnez les différents filtres souhaités pour un résultat plus pertinent</span>
                      </small>
                    </div>
                  </div>

                  <div class="row">
                    <div class="form-group" [ngClass]="setFilterWidth()" *ngIf="!establishment">
                      <label>Établissement</label>
                      <ng-select [items]="filterByEstablishments"
                                 [multiple]="false"
                                 bindValue="id"
                                 bindLabel="name"
                                 notFoundText="Aucun résultat"
                                 [selectableGroup]="true"
                                 [closeOnSelect]="true"
                                 placeholder="Sélectionnez un établissement"
                                 (change)="filterBy('filter_establishments', $event)">
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index"
                                     class="overflow-hidden">
                          <div class="text-truncate">
                            {{ item.name }}
                          </div>
                        </ng-template>
                      </ng-select>
                    </div>

                    <div class="form-group" [ngClass]="setFilterWidth()" *ngIf="registry.checkpoints_module || registry.checkpoints_custom_module || registry.checkpoints_direct_module">
                      <label>Points de contrôle</label>
                      <ng-select [items]="filterByCheckpoints"
                                 [multiple]="false"
                                 bindValue="id"
                                 bindLabel="full_name"
                                 notFoundText="Aucun résultat"
                                 [selectableGroup]="true"
                                 [closeOnSelect]="true"
                                 placeholder="Sélectionnez un point de contrôle"
                                 (change)="filterBy('filter_checkpoints', $event)">
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index"
                                     class="overflow-hidden">
                          <div class="text-truncate">
                            {{ item.full_name }}
                          </div>
                        </ng-template>
                      </ng-select>
                    </div>

                    <div class="form-group" [ngClass]="setFilterWidth()" *ngIf="registry.commissions_module || registry.expertises_module || registry.agencies_module">
                      <label>Degré d'urgence</label>
                      <ng-select [items]="filterByEmergencies"
                                 [multiple]="false"
                                 bindValue="id"
                                 bindLabel="name"
                                 notFoundText="Aucun résultat"
                                 [selectableGroup]="true"
                                 [closeOnSelect]="true"
                                 placeholder="Sélectionnez un degré d'urgence"
                                 (change)="filterBy('filter_emergencies', $event)">
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                          {{ item.name }}
                        </ng-template>
                      </ng-select>
                    </div>

                    <div class="form-group" [ngClass]="setFilterWidth()">
                      <label for="year">Année</label>
                      <input type="number" id="year" class="form-control" [(ngModel)]="filterByYear" placeholder="Veuillez saisir une année (ex : 2020)" min="1900" max="2100" (ngModelChange)="filterBy('filter_years', filterByYear)">
                    </div>

                    <div class="form-group" [ngClass]="setFilterWidth()">
                      <label for="month">Mois</label>
                      <input type="number" id="month" class="form-control" [(ngModel)]="filterByMonth" placeholder="Veuillez saisir un mois (ex : 01)" min="01" max="12" (ngModelChange)="filterBy('filter_months', filterByMonth)">
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- ACCESSIBILTY -->
            <div *ngIf="!establishment && registry.report_worksheets_module">
              <div class="card flex-fill">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-6">
                      <h3 class="header-title">Suivi des travaux non réalisés</h3>

                      <small class="form-text text-muted">
                        <span>Liste du suivi des travaux non réalisés</span>
                      </small>
                    </div>

                    <div class="col-md-6">
                      <app-searchbar [(ngModel)]="searchWork" (keyup)="searchWorks()" [searchbar_id]="'accessibility_search_bar'" [searchbar_name]="'accessibility_search_bar'" ngDefaultControl></app-searchbar>
                    </div>
                  </div>

                  <div class="row" style="min-height: 57px;">
                    <div class="col-auto ml-auto">
                      <ul class="nav nav-tabs nav-tabs-sm">
                        <li class="nav-item href" *ngFor="let date of dates" (click)="switchTabWorks(date.year)">
                          <a class="nav-link" [ngClass]="{ 'active': date.year === yearWorks }">
                            {{ date.year }}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="table-responsive">
                    <table class="table table-striped">
                      <thead>
                      <tr>
                        <th>Établissement</th>
                        <th class="href" (click)="filterWorks('Works.work')">
                          <a class="text-muted">Travaux</a>
                          <span class="fe fe-arrow-down"
                                *ngIf="sortByWorks === 'Works.work' && sortDirectionWorks === 'asc'"></span>
                          <span class="fe fe-arrow-up"
                                *ngIf="sortByWorks === 'Works.work' && sortDirectionWorks === 'desc'"></span>
                          <span class="fe fe-arrow-right" *ngIf="sortByWorks !== 'Works.work'"></span>
                        </th>
                        <th></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngIf="loaderWorks">
                        <td colspan="3">
                          <div class="is-loading loader-content"></div>
                        </td>
                      </tr>
                      <tr *ngIf="!loaderWorks && !works.length">
                        <td colspan="3">
                          <div class="loader-content">
                            Aucun résultat
                          </div>
                        </td>
                      </tr>
                      <tr class="dropdown" *ngFor="let work of works">
                        <td>{{ work.report_worksheet.establishment_registry.establishment.name }}</td>
                        <td>
                          <a class="href"
                             [routerLink]="['report-worksheets/' + work.report_worksheet_id + '/works/' + work.id]">
                            {{ work.work }}
                          </a>
                        </td>
                        <td class="d-flex flex-row-reverse">
                          <a class="dropdown-ellipses dropdown-toggle href" role="button" [attr.aria-label]=""
                             data-toggle="dropdown"
                             aria-haspopup="true" aria-expanded="false">
                            <i class="fe fe-more-vertical"></i>
                          </a>

                          <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item href"
                               [routerLink]="['report-worksheets/' + work.report_worksheet_id + '/works/' + work.id]">
                              {{ canUpdateWorks ? 'Éditer la fiche travaux' : 'Consulter la fiche travaux' }}
                            </a>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <app-pagination (returnEvent)="dispatchPaginationWorks($event)"
                                  [count_elements]="countWorks"
                                  [current_page]="pageWorks"
                                  [elements_length]="works.length"
                                  [limit]="limitWorks"
                                  [total_pages]="pagesWorks"
                                  ngDefaultControl>
                  </app-pagination>
                </div>
              </div>

              <div class="card flex-fill">
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <h3 class="header-title">Tableau de suivi financier du patrimoine</h3>

                      <small class="form-text text-muted">
                        <span>Tableau de suivi financier du patrimoine</span>
                      </small>
                    </div>
                  </div>

                  <div class="table-responsive">
                    <table class="table table-striped" [ngClass]="{ 'margin-bottom-unset': !reports.length }">
                      <thead>
                      <tr>
                        <th>Année</th>
                        <th>Estimation travaux € HT</th>
                        <th>Estimation prestations intellectuelles éventuelles € HT</th>
                        <th>Coût TOTAL estimatif € HT</th>
                        <th>Coût TOTAL réel € HT</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngIf="loaderReports">
                        <td colspan="5">
                          <div class="is-loading loader-content"></div>
                        </td>
                      </tr>
                      <tr *ngIf="!loaderReports && !reports.length">
                        <td colspan="5">
                          <div class="loader-content">
                            Aucun résultat
                          </div>
                        </td>
                      </tr>
                      <tr class="dropdown" *ngFor="let report of reports">
                        <td>{{ report.year }}</td>
                        <td>{{ report.financial_estimation }}</td>
                        <td>{{ report.financial_estimation_intellectual }}</td>
                        <td>{{ report.financial_estimation + report.financial_estimation_intellectual }}</td>
                        <td>{{ report.financial_real }}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div class="chart align-self-center pb-4" [hidden]="!reports.length">
                  <canvas id="worksheetChart"></canvas>
                </div>
              </div>
            </div>

            <!-- OBSERVATIONS + PRESCRIPTIONS + CHECKPOINTS + TRAININGS + OFFICIAL PRESCRIPTIONS + FIRE PERMITS -->
            <div *ngIf="registry.checkpoints_module || registry.checkpoints_custom_module || registry.checkpoints_direct_module ||
            registry.commissions_module || registry.expertises_module || registry.agencies_module || registry.checkpoints_module ||
            registry.checkpoints_custom_module || registry.checkpoints_direct_module || registry.formations_module || registry.fire_permits_module || registry.worksheets_module">

              <ul class="nav nav-tabs nav-overflow" [hidden]="discoverOffer">
                <li class="nav-item href"
                    *ngIf="registry.checkpoints_module || registry.checkpoints_custom_module || registry.checkpoints_direct_module">
                  <a class="nav-link" [ngClass]="{ 'active': activeTab === 'observations'}"
                     (click)="changeTabs('observations')" aria-label="Récapitulatif des observations">Observations</a>
                </li>

                <li class="nav-item href"
                    *ngIf="registry.commissions_module || registry.expertises_module || registry.agencies_module">
                  <a class="nav-link" [ngClass]="{ 'active': activeTab === 'prescriptions'}"
                     (click)="changeTabs('prescriptions')"
                     aria-label="Récapitulatifs des prescriptions">Prescriptions</a>
                </li>

                <li class="nav-item href"
                    *ngIf="registry.checkpoints_module || registry.checkpoints_custom_module || registry.checkpoints_direct_module">
                  <a class="nav-link" [ngClass]="{ 'active': activeTab === 'checkpoints'}"
                     (click)="changeTabs('checkpoints')" aria-label="Récapitulatif des points de contrôle">Points de
                    contrôle</a>
                </li>

                <li class="nav-item href" *ngIf="registry.formations_module">
                  <a class="nav-link" [ngClass]="{ 'active': activeTab === 'formations'}"
                     (click)="changeTabs('formations')" aria-label="Récapitulatif des formations">Formations</a>
                </li>

                <li class="nav-item href" *ngIf="registry.rus_module && (!establishment || establishment && (establishment.is_rus || establishment.parent_id))">
                  <a class="nav-link" [ngClass]="{ 'active': activeTab === 'rus'}"
                     (click)="changeTabs('rus')" aria-label="Récapitulatif des rapports RUS">Prescriptions RUS</a>
                </li>

                <li class="nav-item href"
                    *ngIf="registry.worksheets_module">
                  <a class="nav-link" [ngClass]="{ 'active': activeTab === 'rvrat'}"
                     (click)="changeTabs('rvrat')"
                     aria-label="Récapitulatifs des prescriptions rvrat">Prescriptions des RVRAT</a>
                </li>

                <li class="nav-item href" *ngIf="registry.fire_permits_module">
                  <a class="nav-link" [ngClass]="{ 'active': activeTab === 'fire-permits'}"
                     (click)="changeTabs('fire-permits')" aria-label="Récapitulatif des permits feu">Permis feu</a>
                </li>

                <li class="nav-item href" *ngIf="registry.fire_permits_module">
                  <a class="nav-link" [ngClass]="{ 'active': activeTab === 'preventions'}"
                     (click)="changeTabs('preventions')" aria-label="Récapitulatif des plans de prévention">Plans de prévention</a>
                </li>

                <li class="nav-item href" *ngIf="registry.fire_permits_module">
                  <a class="nav-link" [ngClass]="{ 'active': activeTab === 'preventions-simplified'}"
                     (click)="changeTabs('preventions-simplified')" aria-label="Récapitulatif des plans de prévention">Plans de prévention simplifiés</a>
                </li>
              </ul>

              <ul class="nav nav-tabs" [hidden]="!discoverOffer">
                <li class="nav-item href"
                    *ngIf="registry.checkpoints_module || registry.checkpoints_custom_module || registry.checkpoints_direct_module">
                  <a class="nav-link" [ngClass]="{ 'active': activeTab === 'observations'}"
                     (click)="changeTabs('observations')" aria-label="Récapitulatif des observations">Observations</a>
                </li>

                <li class="nav-item href"
                    *ngIf="registry.commissions_module || registry.expertises_module || registry.agencies_module">
                  <a class="nav-link discover-color href tooltipHtml" title="Veuillez souscrire à une offre pour débloquer le contenu">Prescriptions</a>
                </li>

                <li class="nav-item href"
                    *ngIf="registry.checkpoints_module || registry.checkpoints_custom_module || registry.checkpoints_direct_module">
                  <a class="nav-link" [ngClass]="{ 'active': activeTab === 'checkpoints'}"
                     (click)="changeTabs('checkpoints')" aria-label="Récapitulatif des points de contrôle">Points de
                    contrôle</a>
                </li>

                <li class="nav-item href" *ngIf="registry.formations_module">
                  <a class="nav-link discover-color tooltipHtml" title="Veuillez souscrire à une offre pour débloquer le contenu">Formations</a>
                </li>

                <li class="nav-item href" *ngIf="registry.rus_module && (!establishment || establishment && (establishment.is_rus || establishment.parent_id))">
                  <a class="nav-link discover-color tooltipHtml" title="Veuillez souscrire à une offre pour débloquer le contenu">Prescriptions RUS</a>
                </li>
              </ul>

              <div class="tab-content pt-4" id="myTabContent">

                <!-- OBSERVATIONS -->
                <div class="tab-pane fade" role="tabpanel" aria-labelledby="observations myTabContent"
                     [ngClass]="{ 'show active': activeTab === 'observations'}">
                  <div class="card flex-fill">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-6">
                          <h3 class="header-title">Observations</h3>
                          <small class="form-text text-muted">
                            <span>Liste d'observations à lever</span>
                          </small>
                        </div>

                        <div class="col-md-6">
                          <app-searchbar [(ngModel)]="searchObservation" (keyup)="searchObservations()" [searchbar_id]="'observations_search_bar'" [searchbar_name]="'observations_search_bar'" ngDefaultControl></app-searchbar>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-auto ml-auto">
                          <ul class="nav nav-tabs nav-tabs-sm">
                            <li class="nav-item href" (click)="switchTabObservations(false)">
                              <a class="nav-link" [ngClass]="{ 'active': !allObservations }">
                                Mes observations
                              </a>
                            </li>

                            <li class="nav-item href" (click)="switchTabObservations(true)">
                              <a class="nav-link" [ngClass]="{ 'active': allObservations }">
                                Toutes les observations
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div class="table-responsive">
                        <table class="table table-striped">
                          <thead>
                          <tr>
                            <th *ngIf="!establishment" class="href" (click)="filterObservations('Establishments.name')">
                              <a class="text-muted">Établissement</a>
                              <span class="fe fe-arrow-down"
                                    *ngIf="sortByObservations === 'Establishments.name' && sortDirectionObservations === 'asc'"></span>
                              <span class="fe fe-arrow-up"
                                    *ngIf="sortByObservations === 'Establishments.name' && sortDirectionObservations === 'desc'"></span>
                              <span class="fe fe-arrow-right"
                                    *ngIf="sortByObservations !== 'Establishments.name'"></span>
                            </th>
                            <th class="href" (click)="filterObservations('Observations.emergencies')">
                              <a class="text-muted">Degré d'urgence</a>
                              <span class="fe fe-arrow-down"
                                    *ngIf="sortByObservations === 'Observations.emergencies' && sortDirectionObservations === 'asc'"></span>
                              <span class="fe fe-arrow-up"
                                    *ngIf="sortByObservations === 'Observations.emergencies' && sortDirectionObservations === 'desc'"></span>
                              <span class="fe fe-arrow-right"
                                    *ngIf="sortByObservations !== 'Observations.emergencies'"></span>
                            </th>
                            <th class="href" (click)="filterObservations('Reports.visit_date')">
                              <a class="text-muted">Date de visite</a>
                              <span class="fe fe-arrow-down"
                                    *ngIf="sortByObservations === 'Reports.visit_date' && sortDirectionObservations === 'asc'"></span>
                              <span class="fe fe-arrow-up"
                                    *ngIf="sortByObservations === 'Reports.visit_date' && sortDirectionObservations === 'desc'"></span>
                              <span class="fe fe-arrow-right"
                                    *ngIf="sortByObservations !== 'Reports.visit_date'"></span>
                            </th>
                            <th *ngIf="interventionsObservations">B.I.</th>
                            <th *ngIf="registry.id !== 5" class="href" (click)="filterObservations('Checkpoints.name')">
                              <a class="text-muted">Installation</a>
                              <span class="fe fe-arrow-down"
                                    *ngIf="sortByObservations === 'Checkpoints.name' && sortDirectionObservations === 'asc'"></span>
                              <span class="fe fe-arrow-up"
                                    *ngIf="sortByObservations === 'Checkpoints.name' && sortDirectionObservations === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sortByObservations !== 'Checkpoints.name'"></span>
                            </th>
                            <th *ngIf="registry.id === 5" class="href" (click)="filterObservations('EstablishmentCheckpoints.name')">
                              <a class="text-muted">Installation</a>
                              <span class="fe fe-arrow-down"
                                    *ngIf="sortByObservations === 'EstablishmentCheckpoints.name' && sortDirectionObservations === 'asc'"></span>
                              <span class="fe fe-arrow-up"
                                    *ngIf="sortByObservations === 'EstablishmentCheckpoints.name' && sortDirectionObservations === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sortByObservations !== 'EstablishmentCheckpoints.name'"></span>
                            </th>
                            <th class="href" (click)="filterObservations('Observations.name')">
                              <a class="text-muted">Observation(s)</a>
                              <span class="fe fe-arrow-down"
                                    *ngIf="sortByObservations === 'Observations.name' && sortDirectionObservations === 'asc'"></span>
                              <span class="fe fe-arrow-up"
                                    *ngIf="sortByObservations === 'Observations.name' && sortDirectionObservations === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sortByObservations !== 'Observations.name'"></span>
                            </th>
                            <th></th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr *ngIf="loaderObservations">
                            <td colspan="7">
                              <div class="is-loading loader-content"></div>
                            </td>
                          </tr>
                          <tr *ngIf="!loaderObservations && !observations.length">
                            <td colspan="7">
                              <div class="loader-content">
                                Aucun résultat
                              </div>
                            </td>
                          </tr>
                          <tr class="dropdown" *ngFor="let observation of observations">
                            <td
                              *ngIf="!establishment">{{ observation.report.establishment_checkpoint.establishment_registry.establishment.name }}</td>
                            <td>
                              <span class="label label-rounded" [ngClass]="getEmergencies(observation) && getEmergencies(observation).color">
                                {{ getEmergencies(observation) && getEmergencies(observation).value }}
                              </span>
                            </td>
                            <td>{{ observation.visit_date | date : 'dd/MM/yyyy' }}</td>
                            <td *ngIf="interventionsObservations">
                              <span *ngIf="!observation.state && observation.has_intervention"
                                    class="fe fe-clock tooltipHtml bi-click"
                                    [ngClass]="{ 'fe-red': observation.deadline_danger }"
                                    [title]="'Intervention demandée pour le ' + (observation.deadline_intervention | date : 'dd/MM/yyyy')"
                                    [routerLink]="'interventions/' + observation['intervention_id']"></span>&nbsp;
                            </td>
                            <td>
                              {{ observation.report.establishment_checkpoint.full_name }}
                            </td>
                            <td>{{ observation.name }}</td>
                            <td class="d-flex flex-row-reverse">
                              <a class="dropdown-ellipses dropdown-toggle href" role="button"
                                 [attr.aria-label]="'Sous menu du tableau de bord des observations'"
                                 data-toggle="dropdown"
                                 aria-haspopup="true" aria-expanded="false">
                                <i class="fe fe-more-vertical"></i>
                              </a>

                              <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item href"
                                   [routerLink]="[currentRoute + 'observations/' + observation.id + '/state']" *ngIf="canUpdateObservations">
                                  Lever l'observation
                                </a>

                                <a class="dropdown-item href"
                                   [routerLink]="[currentRoute + 'reports/' + observation.report_id +  '/observations/' + observation.id + '/intervention/create']">
                                  Créer un bon d'intervention
                                </a>

                                <a class="dropdown-item href"
                                   [routerLink]="[currentRoute + 'observations/' + observation.id]">
                                  {{ canUpdateObservations ? 'Éditer l\'observation' : 'Consulter l\'observation' }}
                                </a>
                              </div>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                      <app-pagination (returnEvent)="dispatchPaginationObservations($event)"
                                      [count_elements]="countObservations"
                                      [current_page]="pageObservations"
                                      [elements_length]="observations.length"
                                      [limit]="limitObservations"
                                      [total_pages]="pagesObservations"
                                      ngDefaultControl>
                      </app-pagination>
                    </div>
                  </div>
                </div>

                <!-- PRESCRIPTIONS -->
                <div class="tab-pane fade" role="tabpanel" aria-labelledby="prescriptions myTabContent"
                     [ngClass]="{ 'show active': activeTab === 'prescriptions'}">
                  <div class="card flex-fill">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-6">
                          <h3 class="header-title">Prescriptions</h3>
                          <small class="form-text text-muted">
                            <span>Liste des prescriptions à lever</span>
                          </small>
                        </div>

                        <div class="col-md-6">
                          <app-searchbar [(ngModel)]="searchPrescription" (keyup)="searchPrescriptions()" [searchbar_id]="'prescriptions_search_bar'" [searchbar_name]="'prescriptions_search_bar'" ngDefaultControl></app-searchbar>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-auto ml-auto">
                          <ul class="nav nav-tabs nav-tabs-sm">
                            <li class="nav-item href" (click)="switchTabPrescriptions(false)">
                              <a class="nav-link" [ngClass]="{ 'active': !allPrescriptions }">
                                Mes prescriptions
                              </a>
                            </li>
                            <li class="nav-item href" (click)="switchTabPrescriptions(true)">
                              <a class="nav-link" [ngClass]="{ 'active': allPrescriptions }">
                                Toutes les prescriptions
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div class="table-responsive">
                        <table class="table table-striped">
                          <thead>
                          <tr>
                            <th *ngIf="!establishment" class="href"
                                (click)="filterPrescriptions('Establishments.name')">
                              <a class="text-muted">Établissement</a>
                              <span class="fe fe-arrow-down"
                                    *ngIf="sortByPrescriptions === 'Establishments.name' && sortDirectionPrescriptions === 'asc'"></span>
                              <span class="fe fe-arrow-up"
                                    *ngIf="sortByPrescriptions === 'Establishments.name' && sortDirectionPrescriptions === 'desc'"></span>
                              <span class="fe fe-arrow-right"
                                    *ngIf="sortByPrescriptions !== 'Establishments.name'"></span>
                            </th>
                            <th class="href" (click)="filterPrescriptions('Prescriptions.emergencies')">
                              <a class="text-muted">Degré d'urgence</a>
                              <span class="fe fe-arrow-down"
                                    *ngIf="sortByPrescriptions === 'Prescriptions.emergencies' && sortDirectionPrescriptions === 'asc'"></span>
                              <span class="fe fe-arrow-up"
                                    *ngIf="sortByPrescriptions === 'Prescriptions.emergencies' && sortDirectionPrescriptions === 'desc'"></span>
                              <span class="fe fe-arrow-right"
                                    *ngIf="sortByPrescriptions !== 'Prescriptions.emergencies'"></span>
                            </th>
                            <th class="href" (click)="filterPrescriptions('Commissions.visit_date')">
                              <a class="text-muted">Date de visite</a>
                              <span class="fe fe-arrow-down"
                                    *ngIf="sortByPrescriptions === 'Commissions.visit_date' && sortDirectionPrescriptions === 'asc'"></span>
                              <span class="fe fe-arrow-up"
                                    *ngIf="sortByPrescriptions === 'Commissions.visit_date' && sortDirectionPrescriptions === 'desc'"></span>
                              <span class="fe fe-arrow-right"
                                    *ngIf="sortByPrescriptions !== 'Commissions.visit_date'"></span>
                            </th>
                            <th *ngIf="interventionsPrescriptions">B.I.</th>
                            <th class="href" (click)="filterPrescriptions('Prescriptions.name')">
                              <a class="text-muted">Prescription(s)</a>
                              <span class="fe fe-arrow-down"
                                    *ngIf="sortByPrescriptions === 'Prescriptions.name' && sortDirectionPrescriptions === 'asc'"></span>
                              <span class="fe fe-arrow-up"
                                    *ngIf="sortByPrescriptions === 'Prescriptions.name' && sortDirectionPrescriptions === 'desc'"></span>
                              <span class="fe fe-arrow-right"
                                    *ngIf="sortByPrescriptions !== 'Prescriptions.name'"></span>
                            </th>
                            <th></th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr *ngIf="loaderPrescriptions">
                            <td colspan="5">
                              <div class="is-loading loader-content"></div>
                            </td>
                          </tr>
                          <tr *ngIf="!loaderPrescriptions && !prescriptions.length">
                            <td colspan="5">
                              <div class="loader-content">
                                Aucun résultat
                              </div>
                            </td>
                          </tr>
                          <tr class="dropdown" *ngFor="let prescription of prescriptions">
                            <td
                              *ngIf="!establishment">{{ prescription.commission.establishment_registry.establishment.name }}</td>
                            <td>
                            <span class="label label-rounded"
                                  [ngClass]="getEmergencies(prescription) && getEmergencies(prescription).color">{{ getEmergencies(prescription) && getEmergencies(prescription).value }}</span>
                            </td>
                            <td>{{ prescription.visit_date | date : 'dd/MM/yyyy' }}</td>
                            <td *ngIf="interventionsPrescriptions">
                              <span *ngIf="!prescription.state && prescription.has_intervention"
                                    class="fe fe-clock tooltipHtml"
                                    [ngClass]="{ 'fe-red': prescription.deadline_danger }"
                                    [title]="'Intervention demandée pour le ' + (prescription.deadline_intervention | date : 'dd/MM/yyyy')"></span>&nbsp;
                            </td>
                            <td>{{ prescription.name }}</td>
                            <td class="d-flex flex-row-reverse">
                              <a class="dropdown-ellipses dropdown-toggle href" role="button"
                                 [attr.aria-label]="'Sous menu du tableau de bord des prescriptions'"
                                 data-toggle="dropdown"
                                 aria-haspopup="true" aria-expanded="false">
                                <i class="fe fe-more-vertical"></i>
                              </a>

                              <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item href"
                                   [routerLink]="[currentRoute + 'prescriptions/' + prescription.id + '/state']" *ngIf="canUpdatePrescriptions">
                                  Lever la prescription
                                </a>

                                <a class="dropdown-item href"
                                   [routerLink]="[currentRoute + 'commissions/' + prescription.commission_id + '/prescriptions/' + prescription.id + '/intervention/create']">
                                  Créer un bon d'intervention
                                </a>

                                <a class="dropdown-item href"
                                   [routerLink]="[currentRoute + 'prescriptions/' + prescription.id]">
                                  {{ canUpdatePrescriptions ? 'Éditer la prescription' : 'Consulter la prescription' }}
                                </a>
                              </div>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                      <app-pagination (returnEvent)="dispatchPaginationPrescriptions($event)"
                                      [count_elements]="countPrescriptions"
                                      [current_page]="pagePrescriptions"
                                      [elements_length]="prescriptions.length"
                                      [limit]="limitPrescriptions"
                                      [total_pages]="pagesPrescriptions"
                                      ngDefaultControl>
                      </app-pagination>
                    </div>
                  </div>
                </div>

                <!-- CHECKPOINTS -->
                <div class="tab-pane fade" role="tabpanel" aria-labelledby="checkpoints myTabContent"
                     [ngClass]="{ 'show active': activeTab === 'checkpoints'}">
                  <div class="card flex-fill">
                    <div class="card-body">
                      <div class="row">
                        <div class="col">
                          <h3 class="header-title">Points de contrôle</h3>

                          <small class="form-text text-muted">
                            <span>Liste des points de contrôle</span>
                          </small>
                        </div>

                        <div class="col-md-6">
                          <app-searchbar [(ngModel)]="searchCheckpoint" (keyup)="searchCheckpoints()" [searchbar_id]="'checkpoints_search_bar'" [searchbar_name]="'checkpoints_search_bar'" ngDefaultControl></app-searchbar>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-auto ml-auto">
                          <ul class="nav nav-tabs nav-tabs-sm">
                            <li class="nav-item href" (click)="switchTabCheckpoints(true)">
                              <a class="nav-link" [ngClass]="{ 'active': nextCheckpoint }">
                                Des 12 prochains mois
                              </a>
                            </li>

                            <li class="nav-item href" (click)="switchTabCheckpoints(false)">
                              <a class="nav-link" [ngClass]="{ 'active': !nextCheckpoint }">
                                En retard
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div class="table-responsive">
                        <table class="table table-striped">
                          <thead>
                          <tr>
                            <th *ngIf="!establishment" class="href" (click)="filterCheckpoints('Establishments.name')">
                              <a class="text-muted">Établissement</a>
                              <span class="fe fe-arrow-down"
                                    *ngIf="sortByCheckpoints === 'Establishments.name' && sortDirectionCheckpoints === 'asc'"></span>
                              <span class="fe fe-arrow-up"
                                    *ngIf="sortByCheckpoints === 'Establishments.name' && sortDirectionCheckpoints === 'desc'"></span>
                              <span class="fe fe-arrow-right"
                                    *ngIf="sortByCheckpoints !== 'Establishments.name'"></span>
                            </th>
                            <th class="href" (click)="filterCheckpoints('EstablishmentCheckpoints.next_report_date')">
                              <a class="text-muted">Date</a>
                              <span class="fe fe-arrow-down"
                                    *ngIf="sortByCheckpoints === 'EstablishmentCheckpoints.next_report_date' && sortDirectionCheckpoints === 'asc'"></span>
                              <span class="fe fe-arrow-up"
                                    *ngIf="sortByCheckpoints === 'EstablishmentCheckpoints.next_report_date' && sortDirectionCheckpoints === 'desc'"></span>
                              <span class="fe fe-arrow-right"
                                    *ngIf="sortByCheckpoints !== 'EstablishmentCheckpoints.next_report_date'"></span>
                            </th>
                            <th class="href" (click)="filterCheckpoints('EstablishmentCheckpoints.name')">
                              <a class="text-muted">Installation</a>
                              <span class="fe fe-arrow-down"
                                    *ngIf="sortByCheckpoints === 'EstablishmentCheckpoints.name' && sortDirectionCheckpoints === 'asc'"></span>
                              <span class="fe fe-arrow-up"
                                    *ngIf="sortByCheckpoints === 'EstablishmentCheckpoints.name' && sortDirectionCheckpoints === 'desc'"></span>
                              <span class="fe fe-arrow-right"
                                    *ngIf="sortByCheckpoints !== 'EstablishmentCheckpoints.name'"></span>
                            </th>
                            <th></th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr *ngIf="loaderCheckpoints">
                            <td colspan="4">
                              <div class="is-loading loader-content"></div>
                            </td>
                          </tr>
                          <tr *ngIf="!loaderCheckpoints && !checkpoints.length">
                            <td colspan="4">
                              <div class="loader-content">
                                Aucun résultat
                              </div>
                            </td>
                          </tr>
                          <tr class="dropdown" *ngFor="let checkpoint of checkpoints">
                            <td *ngIf="!establishment">{{ checkpoint.establishment_registry.establishment.name }}</td>
                            <td>{{ checkpoint.next_report_date ? (checkpoint.next_report_date | date : 'dd/MM/yyyy') : 'Jamais réalisé' }}</td>
                            <td>
                              {{ checkpoint.full_name }}
                            </td>
                            <td class="d-flex flex-row-reverse">
                              <div class="btn-group" *ngIf="canCreateCheckpoints">
                                <a class="btn btn-sm btn-white" [routerLink]="[currentRoute + 'checkpoints/' + checkpoint.id + '/reports/create']">
                                  Ajouter un rapport
                                </a>
                              </div>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                      <app-pagination (returnEvent)="dispatchPaginationCheckpoints($event)"
                                      [count_elements]="countCheckpoints"
                                      [current_page]="pageCheckpoints"
                                      [elements_length]="checkpoints.length"
                                      [limit]="limitCheckpoints"
                                      [total_pages]="pagesCheckpoints"
                                      ngDefaultControl>
                      </app-pagination>
                    </div>
                  </div>
                </div>

                <!-- TRAININGS -->
                <div class="tab-pane fade" role="tabpanel" aria-labelledby="myTabContent trainings"
                     [ngClass]="{ 'show active': activeTab === 'formations'}">
                  <div class="card flex-fill">
                    <div class="card-body">
                      <div class="row">
                        <div class="col">
                          <h3 class="header-title">Formations</h3>

                          <small class="form-text text-muted">
                          <span>
                            {{ allTrainings ? 'Liste des futures formations' : 'Liste des formations en retard' }}
                          </span>
                          </small>
                        </div>

                        <div class="col-md-6">
                          <app-searchbar [(ngModel)]="searchTraining" (keyup)="searchTrainings()" [searchbar_id]="'trainings_search_bar'" [searchbar_name]="'trainings_search_bar'" ngDefaultControl></app-searchbar>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-auto ml-auto">
                          <ul class="nav nav-tabs nav-tabs-sm">
                            <li class="nav-item href" (click)="switchTabTrainings(true)">
                              <a class="nav-link"
                                 [attr.aria-label]="allTrainings ? 'Onglet - Afficher les formations des 12 prochains mois' : ''"
                                 [ngClass]="{ 'active': allTrainings }">
                                Des 12 prochains mois
                              </a>
                            </li>

                            <li class="nav-item href" (click)="switchTabTrainings(false)">
                              <a class="nav-link"
                                 [attr.aria-label]="!allTrainings ? 'Onglet - Afficher les formations en retard' : ''"
                                 [ngClass]="{ 'active': !allTrainings }">
                                En retard
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div class="table-responsive">
                        <table class="table table-striped">
                          <thead>
                          <tr>
                            <th class="href" (click)="filterTrainings('Establishments.name')" *ngIf="!establishment">
                              <a class="text-muted sort">Établissement</a>
                              <span class="fe fe-arrow-down"
                                    *ngIf="sortByTrainings === 'Establishments.name' && sortDirectionTrainings === 'asc'"></span>
                              <span class="fe fe-arrow-up"
                                    *ngIf="sortByTrainings === 'Establishments.name' && sortDirectionTrainings === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sortByTrainings !== 'Establishments.name'"></span>
                            </th>
                            <th class="href" (click)="filterTrainings('Trainings.priority')">
                              <a class="text-muted sort" aria-label="Tri des formations par degré d'urgence">Degré d'urgence</a>
                              <span class="fe fe-arrow-down"
                                    *ngIf="sortByTrainings === 'Trainings.priority' && sortDirectionTrainings === 'asc'"
                                    [attr.aria-label]="sortByTrainings === 'Trainings.priority' && sortDirectionTrainings === 'asc' ? 'Ordre de tri des établissements : Du moins important au plus important' : 'Ordre de tri des établissements : Du plus important au moins important'"></span>
                              <span class="fe fe-arrow-up"
                                    *ngIf="sortByTrainings === 'Trainings.priority' && sortDirectionTrainings === 'desc'"
                                    [attr.aria-label]="sortByTrainings === 'Trainings.priority' && sortDirectionTrainings === 'desc' ? 'Ordre de tri des établissements : Du plus important au moins important' : 'Ordre de tri des établissements : Du moins important au plus important'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sortByTrainings !== 'Trainings.priority'"></span>
                            </th>
                            <th class="href" (click)="filterTrainings('Trainings.name')">
                              <a class="text-muted sort" aria-label="Tri des formations par leurs noms">Formation</a>
                              <span class="fe fe-arrow-down"
                                    *ngIf="sortByTrainings === 'Trainings.name' && sortDirectionTrainings === 'asc'"
                                    [attr.aria-label]="sortByTrainings === 'Trainings.name' && sortDirectionTrainings === 'asc' ? 'Ordre de tri des noms de formations : De A à Z' : 'Ordre de tri des noms de formations : De Z à A'"></span>
                              <span class="fe fe-arrow-up"
                                    *ngIf="sortByTrainings === 'Trainings.name' && sortDirectionTrainings === 'desc'"
                                    [attr.aria-label]="sortByTrainings === 'Trainings.name' && sortDirectionTrainings === 'desc' ? 'Ordre de tri des noms de formations : De Z à A' : 'Ordre de tri des noms de formations : De A à Z'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sortByTrainings !== 'Trainings.name'"></span>
                            </th>
                            <th class="href" (click)="filterTrainings('Trainings.next_date_training')">
                              <a class="text-muted sort" aria-label="Tri des formations par leurs dates">Date</a>
                              <span class="fe fe-arrow-down"
                                    *ngIf="sortByTrainings === 'Trainings.next_date_training' && sortDirectionTrainings === 'asc'"
                                    [attr.aria-label]="sortByTrainings === 'Trainings.next_date_training' && sortDirectionTrainings === 'asc' ? 'Ordre de tri des dates de formations : De la plus ancienne à la plus récente' : 'Ordre de tri des dates de formations : De la plus récente à la plus ancienne'"></span>
                              <span class="fe fe-arrow-up"
                                    *ngIf="sortByTrainings === 'Trainings.next_date_training' && sortDirectionTrainings === 'desc'"
                                    [attr.aria-label]="sortByTrainings === 'Trainings.next_date_training' && sortDirectionTrainings === 'desc' ? 'Ordre de tri des dates de formations : De la plus récente à la plus ancienne' : 'Ordre de tri des dates de formations : De la plus ancienne à la plus récente'"></span>
                              <span class="fe fe-arrow-right"
                                    *ngIf="sortByTrainings !== 'Trainings.next_date_training'"></span>
                            </th>
                            <th></th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr *ngIf="loaderTrainings">
                            <td colspan="7">
                              <div class="is-loading loader-content"></div>
                            </td>
                          </tr>
                          <tr *ngIf="!loaderTrainings && !trainings.length">
                            <td colspan="7">
                              <div class="loader-content">
                                Aucun résultat
                              </div>
                            </td>
                          </tr>
                          <tr *ngFor="let training of trainings">
                            <td *ngIf="!establishment">{{ training.establishment.name }}</td>
                            <td>
                                <span class="label label-rounded"
                                      [ngClass]="training.priority ? 'label-danger' : 'label-success'"
                                      [attr.aria-label]="training.priority ? 'La formation est marquée comme prioritaire' : 'La formation est marquée comme non prioritaire'">
                                  {{ training.priority ? 'Prioritaire' : 'Non prioritaire' }}
                                </span>
                            </td>
                            <td>
                              <a class="href"
                                 [routerLink]="(!establishment ? 'establishment/' + training.establishment.id + '/' : '') + 'trainings/' + training.id + '/dates-training'"
                                 [attr.aria-label]="'Le nom de la formation est : ' + training.name">
                                {{ training.name }}
                              </a>
                            </td>
                            <td
                              [attr.aria-label]="training.next_date_training ? 'La prochaine formation se fera le : ' + (training.next_date_training | date:'dd MM yyyy') : 'La formation n\'a jamais été réalisée' ">
                              {{ training.next_date_training ? (training.next_date_training | date:'dd/MM/yyyy') : 'Jamais réalisée' }}
                            </td>
                            <td class="d-flex flex-row-reverse">
                              <ng-container *ngIf="!establishment">
                                <a class="dropdown-ellipses dropdown-toggle href" role="button"
                                   [attr.aria-label]="'Sous menu du tableau de bord des formations, nom de la formation ' + training.name"
                                   data-toggle="dropdown"
                                   aria-haspopup="true" aria-expanded="false">
                                  <i class="fe fe-more-vertical"></i>
                                </a>
                                <div class="dropdown-menu dropdown-menu-right">
                                  <a class="dropdown-item href"
                                     [routerLink]="'establishment/' + training.establishment.id + '/trainings'">
                                    Consulter les formations de l'établissement
                                  </a>
                                  <a class="dropdown-item href"
                                     [routerLink]="'establishment/' + training.establishment.id + '/trainings/' + training.id + '/dates-training'">
                                    Consulter les dates de la formation de l'établissement
                                  </a>
                                </div>
                              </ng-container>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                      <app-pagination (returnEvent)="dispatchPaginationTrainings($event)"
                                      [count_elements]="countTrainings"
                                      [current_page]="pageTrainings"
                                      [elements_length]="trainings.length"
                                      [limit]="limitTrainings"
                                      [total_pages]="pagesTrainings"
                                      ngDefaultControl>
                      </app-pagination>
                    </div>
                  </div>
                </div>

                <!-- OFFICIAL PRESCRIPTIONS -->
                <div class="tab-pane fade" role="tabpanel" aria-labelledby="prescriptions myTabContent"
                     [ngClass]="{ 'show active': activeTab === 'rus'}">
                  <div class="card flex-fill">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-6">
                          <h3 class="header-title">Prescriptions RUS</h3>
                          <small class="form-text text-muted">
                            <span>Liste des prescriptions à lever</span>
                          </small>
                        </div>

                        <div class="col-md-6">
                          <app-searchbar [(ngModel)]="officialSearchPrescription" (keyup)="searchOfficialPrescriptions()" [searchbar_id]="'official_prescriptions_search_bar'" [searchbar_name]="'official_prescriptions_search_bar'" ngDefaultControl></app-searchbar>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-auto ml-auto">
                          <ul class="nav nav-tabs nav-tabs-sm">
                            <li class="nav-item href" (click)="switchTabOfficialPrescriptions(false)">
                              <a class="nav-link" [ngClass]="{ 'active': !officialAllPrescriptions }">
                                Mes prescriptions
                              </a>
                            </li>
                            <li class="nav-item href" (click)="switchTabOfficialPrescriptions(true)">
                              <a class="nav-link" [ngClass]="{ 'active': officialAllPrescriptions }">
                                Toutes les prescriptions
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div class="table-responsive">
                        <table class="table table-striped">
                          <thead>
                          <tr>
                            <th *ngIf="!establishment" class="href"
                                (click)="filterOfficialPrescriptions('Establishments.name')">
                              <a class="text-muted">Établissement</a>
                              <span class="fe fe-arrow-down"
                                    *ngIf="officialSortByPrescriptions === 'Establishments.name' && officialSortDirectionPrescriptions === 'asc'"></span>
                              <span class="fe fe-arrow-up"
                                    *ngIf="officialSortByPrescriptions === 'Establishments.name' && officialSortDirectionPrescriptions === 'desc'"></span>
                              <span class="fe fe-arrow-right"
                                    *ngIf="officialSortByPrescriptions !== 'Establishments.name'"></span>
                            </th>
                            <th class="href" (click)="filterOfficialPrescriptions('OfficialPrescriptions.emergencies')">
                              <a class="text-muted">Degré d'urgence</a>
                              <span class="fe fe-arrow-down"
                                    *ngIf="officialSortByPrescriptions === 'OfficialPrescriptions.emergencies' && officialSortDirectionPrescriptions === 'asc'"></span>
                              <span class="fe fe-arrow-up"
                                    *ngIf="officialSortByPrescriptions === 'OfficialPrescriptions.emergencies' && officialSortDirectionPrescriptions === 'desc'"></span>
                              <span class="fe fe-arrow-right"
                                    *ngIf="officialSortByPrescriptions !== 'OfficialPrescriptions.emergencies'"></span>
                            </th>
                            <th class="href" (click)="filterOfficialPrescriptions('Officials.visit_date')">
                              <a class="text-muted">Date de visite</a>
                              <span class="fe fe-arrow-down"
                                    *ngIf="officialSortByPrescriptions === 'Officials.visit_date' && officialSortDirectionPrescriptions === 'asc'"></span>
                              <span class="fe fe-arrow-up"
                                    *ngIf="officialSortByPrescriptions === 'Officials.visit_date' && officialSortDirectionPrescriptions === 'desc'"></span>
                              <span class="fe fe-arrow-right"
                                    *ngIf="officialSortByPrescriptions !== 'Officials.visit_date'"></span>
                            </th>
                            <th *ngIf="interventionsOfficialPrescriptions">B.I.</th>
                            <th class="href" (click)="filterOfficialPrescriptions('OfficialPrescriptions.name')">
                              <a class="text-muted">Prescription(s)</a>
                              <span class="fe fe-arrow-down"
                                    *ngIf="officialSortByPrescriptions === 'OfficialPrescriptions.name' && officialSortDirectionPrescriptions === 'asc'"></span>
                              <span class="fe fe-arrow-up"
                                    *ngIf="officialSortByPrescriptions === 'OfficialPrescriptions.name' && officialSortDirectionPrescriptions === 'desc'"></span>
                              <span class="fe fe-arrow-right"
                                    *ngIf="officialSortByPrescriptions !== 'OfficialPrescriptions.name'"></span>
                            </th>
                            <th></th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr *ngIf="officialLoaderPrescriptions">
                            <td colspan="6">
                              <div class="is-loading loader-content"></div>
                            </td>
                          </tr>
                          <tr *ngIf="!officialLoaderPrescriptions && !officialPrescriptions.length">
                            <td colspan="6">
                              <div class="loader-content">
                                Aucun résultat
                              </div>
                            </td>
                          </tr>
                          <tr class="dropdown" *ngFor="let prescription of officialPrescriptions">
                            <td
                              *ngIf="!establishment">{{ prescription.official.establishment_registry.establishment.name }}</td>
                            <td>
                            <span class="label label-rounded"
                                  [ngClass]="getEmergencies(prescription) && getEmergencies(prescription).color">{{ getEmergencies(prescription) && getEmergencies(prescription).value }}</span>
                            </td>
                            <td>{{ prescription.visit_date | date : 'dd/MM/yyyy' }}</td>
                            <td *ngIf="interventionsOfficialPrescriptions">
                              <span *ngIf="!prescription.state && prescription.has_intervention"
                                    class="fe fe-clock tooltipHtml"
                                    [ngClass]="{ 'fe-red': prescription.deadline_danger }"
                                    [title]="'Intervention demandée pour le ' + (prescription.deadline_intervention | date : 'dd/MM/yyyy')"></span>&nbsp;
                            </td>
                            <td>{{ prescription.name }}</td>
                            <td class="d-flex flex-row-reverse">
                              <a class="dropdown-ellipses dropdown-toggle href" role="button"
                                 [attr.aria-label]="'Sous menu du tableau de bord des prescriptions'"
                                 data-toggle="dropdown"
                                 aria-haspopup="true" aria-expanded="false">
                                <i class="fe fe-more-vertical"></i>
                              </a>

                              <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item href"
                                   [routerLink]="[currentRoute + 'official-prescriptions/' + prescription.id + '/state']" *ngIf="canUpdatePrescriptions">
                                  Lever la prescription
                                </a>

                                <a class="dropdown-item href"
                                   [routerLink]="[currentRoute + 'official/' + prescription.official_id + '/prescriptions/' + prescription.id + '/intervention/create']">
                                  Créer un bon d'intervention
                                </a>

                                <a class="dropdown-item href"
                                   [routerLink]="[currentRoute + 'official-prescriptions/' + prescription.id]">
                                  {{ canUpdatePrescriptions ? 'Éditer la prescription' : 'Consulter la prescription' }}
                                </a>
                              </div>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                      <app-pagination (returnEvent)="dispatchPaginationOfficialPrescriptions($event)"
                                      [count_elements]="officialCountPrescriptions"
                                      [current_page]="officialPagePrescriptions"
                                      [elements_length]="officialPrescriptions.length"
                                      [limit]="officialLimitPrescriptions"
                                      [total_pages]="officialPagesPrescriptions"
                                      ngDefaultControl>
                      </app-pagination>
                    </div>
                  </div>
                </div>

                <!-- RVRAT PRESCRIPTIONS -->
                <div class="tab-pane fade" role="tabpanel" aria-labelledby="rvrat myTabContent"
                     [ngClass]="{ 'show active': activeTab === 'rvrat'}">
                  <div class="card flex-fill">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-6">
                          <h3 class="header-title">Prescriptions des RVRAT</h3>
                          <small class="form-text text-muted">
                            <span>Liste des prescriptions à lever</span>
                          </small>
                        </div>

                        <div class="col-md-6">
                          <app-searchbar [(ngModel)]="searchPrescription" (keyup)="searchRvratPrescriptions()" [searchbar_id]="'prescriptions_search_bar'" [searchbar_name]="'prescriptions_search_bar'" ngDefaultControl></app-searchbar>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-auto ml-auto">
                          <ul class="nav nav-tabs nav-tabs-sm">
                            <li class="nav-item href" (click)="switchTabRvratPrescriptions(false)">
                              <a class="nav-link" [ngClass]="{ 'active': !allRvratPrescriptions }">
                                Mes prescriptions
                              </a>
                            </li>
                            <li class="nav-item href" (click)="switchTabRvratPrescriptions(true)">
                              <a class="nav-link" [ngClass]="{ 'active': allRvratPrescriptions }">
                                Toutes les prescriptions
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div class="table-responsive">
                        <table class="table table-striped">
                          <thead>
                          <tr>
                            <th *ngIf="!establishment" class="href"
                                (click)="filterRvratPrescriptions('Establishments.name')">
                              <a class="text-muted">Établissement</a>
                              <span class="fe fe-arrow-down"
                                    *ngIf="sortByPrescriptions === 'Establishments.name' && sortDirectionPrescriptions === 'asc'"></span>
                              <span class="fe fe-arrow-up"
                                    *ngIf="sortByPrescriptions === 'Establishments.name' && sortDirectionPrescriptions === 'desc'"></span>
                              <span class="fe fe-arrow-right"
                                    *ngIf="sortByPrescriptions !== 'Establishments.name'"></span>
                            </th>
                            <th class="href" (click)="filterRvratPrescriptions('RvratPrescriptions.emergencies')">
                              <a class="text-muted">Degré d'urgence</a>
                              <span class="fe fe-arrow-down"
                                    *ngIf="sortByPrescriptions === 'RvratPrescriptions.emergencies' && sortDirectionPrescriptions === 'asc'"></span>
                              <span class="fe fe-arrow-up"
                                    *ngIf="sortByPrescriptions === 'RvratPrescriptions.emergencies' && sortDirectionPrescriptions === 'desc'"></span>
                              <span class="fe fe-arrow-right"
                                    *ngIf="sortByPrescriptions !== 'RvratPrescriptions.emergencies'"></span>
                            </th>
                            <th class="href" (click)="filterRvratPrescriptions('Rvrats.date')">
                              <a class="text-muted">Date</a>
                              <span class="fe fe-arrow-down"
                                    *ngIf="sortByPrescriptions === 'Rvrats.date' && sortDirectionPrescriptions === 'asc'"></span>
                              <span class="fe fe-arrow-up"
                                    *ngIf="sortByPrescriptions === 'Rvrats.date' && sortDirectionPrescriptions === 'desc'"></span>
                              <span class="fe fe-arrow-right"
                                    *ngIf="sortByPrescriptions !== 'Rvrats.date'"></span>
                            </th>
                            <th *ngIf="interventionsRvratPrescriptions">B.I.</th>
                            <th class="href" (click)="filterRvratPrescriptions('RvratPrescriptions.name')">
                              <a class="text-muted">Prescription(s)</a>
                              <span class="fe fe-arrow-down"
                                    *ngIf="sortByPrescriptions === 'RvratPrescriptionsRvratPrescriptions.name' && sortDirectionPrescriptions === 'asc'"></span>
                              <span class="fe fe-arrow-up"
                                    *ngIf="sortByPrescriptions === 'RvratPrescriptions.name' && sortDirectionPrescriptions === 'desc'"></span>
                              <span class="fe fe-arrow-right"
                                    *ngIf="sortByPrescriptions !== 'RvratPrescriptions.name'"></span>
                            </th>
                            <th></th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr *ngIf="loaderRvratPrescriptions">
                            <td colspan="5">
                              <div class="is-loading loader-content"></div>
                            </td>
                          </tr>
                          <tr *ngIf="!loaderRvratPrescriptions && !rvratPrescriptions.length">
                            <td colspan="5">
                              <div class="loader-content">
                                Aucun résultat
                              </div>
                            </td>
                          </tr>
                          <tr class="dropdown" *ngFor="let rvratPrescription of rvratPrescriptions">
                            <td *ngIf="!establishment">
                              {{ rvratPrescription.rvrat.establishment_registry.establishment.name }}
                            </td>
                            <td>
                              <span class="label label-rounded"
                                    [ngClass]="getEmergencies(rvratPrescription) && getEmergencies(rvratPrescription).color">
                                {{ getEmergencies(rvratPrescription) && getEmergencies(rvratPrescription).value }}
                              </span>
                            </td>
                            <td>{{ rvratPrescription.date | date : 'dd/MM/yyyy' }}</td>
                            <td *ngIf="interventionsRvratPrescriptions">
                              <span *ngIf="!rvratPrescription.state && rvratPrescription.has_intervention"
                                    class="fe fe-clock tooltipHtml"
                                    [ngClass]="{ 'fe-red': rvratPrescription.deadline_danger }"
                                    [title]="'Intervention demandée pour le ' + (rvratPrescription.deadline_intervention | date : 'dd/MM/yyyy')"></span>&nbsp;
                            </td>
                            <td>{{ rvratPrescription.name }}</td>
                            <td class="d-flex flex-row-reverse">
                              <a class="dropdown-ellipses dropdown-toggle href" role="button"
                                 [attr.aria-label]="'Sous menu du tableau de bord des prescriptions'"
                                 data-toggle="dropdown"
                                 aria-haspopup="true" aria-expanded="false">
                                <i class="fe fe-more-vertical"></i>
                              </a>

                              <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item href"
                                   [routerLink]="[currentRoute + 'rvrat-prescriptions/' + rvratPrescription.id + '/state']" *ngIf="canUpdateRvratPrescriptions">
                                  Lever la prescription
                                </a>

                                <a class="dropdown-item href"
                                   [routerLink]="[currentRoute + 'rvrat/' + rvratPrescription.rvrat_id + '/rvrat-prescriptions/' + rvratPrescription.id + '/intervention/create']">
                                  Créer un bon d'intervention
                                </a>

                                <a class="dropdown-item href"
                                   [routerLink]="[currentRoute + 'rvrat-prescriptions/' + rvratPrescription.id]">
                                  {{ canUpdatePrescriptions ? 'Éditer la prescription' : 'Consulter la prescription' }}
                                </a>
                              </div>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                      <app-pagination (returnEvent)="dispatchPaginationRvratPrescriptions($event)"
                                      [count_elements]="countRvratPrescriptions"
                                      [current_page]="pageRvratPrescriptions"
                                      [elements_length]="rvratPrescriptions.length"
                                      [limit]="limitRvratPrescriptions"
                                      [total_pages]="pagesRvratPrescriptions"
                                      ngDefaultControl>
                      </app-pagination>
                    </div>
                  </div>
                </div>

                <!-- FIRE PERMITS -->
                <div class="tab-pane fade" role="tabpanel" aria-labelledby="fire-permits myTabContent"
                     [ngClass]="{ 'show active': activeTab === 'fire-permits'}">
                  <div class="card flex-fill">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-6">
                          <h3 class="header-title">Permis feu</h3>
                          <small class="form-text text-muted">
                            <span>Liste des permis feu</span>
                          </small>
                        </div>

                        <div class="col-md-6">
                          <app-searchbar [(ngModel)]="searchFirePermit" (keyup)="searchFirePermits()" [searchbar_id]="'fire_permits_search_bar'" [searchbar_name]="'fire_permits_search_bar'" ngDefaultControl></app-searchbar>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-auto ml-auto">
                          <ul class="nav nav-tabs nav-tabs-sm">
                            <li class="nav-item href" (click)="switchTabFirePermits(false)">
                              <a class="nav-link" [ngClass]="{ 'active': !nextFirePermits }">
                                En cours
                              </a>
                            </li>
                            <li class="nav-item href" (click)="switchTabFirePermits(true)">
                              <a class="nav-link" [ngClass]="{ 'active': nextFirePermits }">
                                À venir
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div class="table-responsive">
                        <table class="table table-striped">
                          <thead>
                          <tr>
                            <th class="href" (click)="filterFirePermits('FirePermits.referency')">
                              <a class="text-muted">Référence</a>
                              <span class="fe fe-arrow-down"
                                    *ngIf="sortByFirePermits === 'FirePermits.referency' && sortDirectionFirePermits === 'asc'"></span>
                              <span class="fe fe-arrow-up"
                                    *ngIf="sortByFirePermits === 'FirePermits.referency' && sortDirectionFirePermits === 'desc'"></span>
                              <span class="fe fe-arrow-right"
                                    *ngIf="sortByFirePermits !== 'FirePermits.referency'"></span>
                            </th>
                            <th *ngIf="!establishment" class="href"
                                (click)="filterFirePermits('Establishments.name')">
                              <a class="text-muted">Établissement</a>
                              <span class="fe fe-arrow-down"
                                    *ngIf="sortByFirePermits === 'Establishments.name' && sortDirectionFirePermits === 'asc'"></span>
                              <span class="fe fe-arrow-up"
                                    *ngIf="sortByFirePermits === 'Establishments.name' && sortDirectionFirePermits === 'desc'"></span>
                              <span class="fe fe-arrow-right"
                                    *ngIf="sortByFirePermits !== 'Establishments.name'"></span>
                            </th>
                            <th class="href" (click)="filterFirePermits('FirePermits.begin_date')">
                              <a class="text-muted">Date de début</a>
                              <span class="fe fe-arrow-down"
                                    *ngIf="sortByFirePermits === 'FirePermits.begin_date' && sortDirectionFirePermits === 'asc'"></span>
                              <span class="fe fe-arrow-up"
                                    *ngIf="sortByFirePermits === 'FirePermits.begin_date' && sortDirectionFirePermits === 'desc'"></span>
                              <span class="fe fe-arrow-right"
                                    *ngIf="sortByFirePermits !== 'FirePermits.begin_date'"></span>
                            </th>
                            <th class="href" (click)="filterFirePermits('FirePermits.end_date')">
                              <a class="text-muted">Date de fin</a>
                              <span class="fe fe-arrow-down"
                                    *ngIf="sortByFirePermits === 'FirePermits.end_date' && sortDirectionFirePermits === 'asc'"></span>
                              <span class="fe fe-arrow-up"
                                    *ngIf="sortByFirePermits === 'FirePermits.end_date' && sortDirectionFirePermits === 'desc'"></span>
                              <span class="fe fe-arrow-right"
                                    *ngIf="sortByFirePermits !== 'FirePermits.end_date'"></span>
                            </th>
                            <th class="href" (click)="filterFirePermits('FirePermits.place')">
                              <a class="text-muted">Lieu de l'opération</a>
                              <span class="fe fe-arrow-down"
                                    *ngIf="sortByFirePermits === 'FirePermits.place' && sortDirectionFirePermits === 'asc'"></span>
                              <span class="fe fe-arrow-up"
                                    *ngIf="sortByFirePermits === 'FirePermits.place' && sortDirectionFirePermits === 'desc'"></span>
                              <span class="fe fe-arrow-right"
                                    *ngIf="sortByFirePermits !== 'FirePermits.place'"></span>
                            </th>
                            <th class="href" (click)="filterFirePermits('FirePermits.details')">
                              <a class="text-muted">Précisions</a>
                              <span class="fe fe-arrow-down"
                                    *ngIf="sortByFirePermits === 'FirePermits.details' && sortDirectionFirePermits === 'asc'"></span>
                              <span class="fe fe-arrow-up"
                                    *ngIf="sortByFirePermits === 'FirePermits.details' && sortDirectionFirePermits === 'desc'"></span>
                              <span class="fe fe-arrow-right"
                                    *ngIf="sortByFirePermits !== 'FirePermits.details'"></span>
                            </th>
                            <th></th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr *ngIf="loaderFirePermits">
                            <td colspan="7">
                              <div class="is-loading loader-content"></div>
                            </td>
                          </tr>
                          <tr *ngIf="!loaderFirePermits && !firePermits.length">
                            <td colspan="7">
                              <div class="loader-content">
                                Aucun résultat
                              </div>
                            </td>
                          </tr>
                          <tr class="dropdown" *ngFor="let permit of firePermits">
                            <td>{{ permit.referency }}</td>
                            <td *ngIf="!establishment">{{ permit.establishment_registry.establishment.name }}</td>
                            <td>{{ permit.begin_date | date : 'dd/MM/yyyy HH:mm' }}</td>
                            <td>{{ permit.end_date | date : 'dd/MM/yyyy HH:mm' }}</td>
                            <td>{{ permit.place }}</td>
                            <td>{{ permit.details }}</td>
                            <td class="d-flex flex-row-reverse">
                              <a class="dropdown-ellipses dropdown-toggle href" role="button"
                                 [attr.aria-label]="'Sous menu du tableau de bord des permis feu'"
                                 data-toggle="dropdown"
                                 aria-haspopup="true" aria-expanded="false">
                                <i class="fe fe-more-vertical"></i>
                              </a>

                              <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item href" [routerLink]="[currentRoute + 'fire-permits/' + permit.id]" *ngIf="establishment">
                                  {{ canUpdateFirePermits ? 'Éditer le permis feu' : 'Consulter le permis feu' }}
                                </a>
                                <a class="dropdown-item href" [routerLink]="[currentRoute + 'establishment/' + permit.establishment_registry.establishment.id + '/fire-permits/' + permit.id]" *ngIf="!establishment">
                                  {{ canUpdateFirePermits ? 'Éditer le permis feu' : 'Consulter le permis feu' }}
                                </a>
                              </div>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                      <app-pagination (returnEvent)="dispatchPaginationFirePermits($event)"
                                      [count_elements]="countFirePermits"
                                      [current_page]="pageFirePermits"
                                      [elements_length]="firePermits.length"
                                      [limit]="limitFirePermits"
                                      [total_pages]="pagesFirePermits"
                                      ngDefaultControl>
                      </app-pagination>
                    </div>
                  </div>
                </div>

                <!-- PREVENTIONS -->
                <div class="tab-pane fade" role="tabpanel" aria-labelledby="preventions myTabContent"
                     [ngClass]="{ 'show active': activeTab === 'preventions'}">
                  <div class="card flex-fill">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-6">
                          <h3 class="header-title">Plans de prévention</h3>
                          <small class="form-text text-muted">
                            <span>Liste des plans de prévention</span>
                          </small>
                        </div>

                        <div class="col-md-6">
                          <app-searchbar [(ngModel)]="searchPrevention" (keyup)="searchPreventions()" [searchbar_id]="'preventions_search_bar'" [searchbar_name]="'preventions_search_bar'" ngDefaultControl></app-searchbar>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-auto ml-auto">
                          <ul class="nav nav-tabs nav-tabs-sm">
                            <li class="nav-item href" (click)="switchTabPreventions(false)">
                              <a class="nav-link" [ngClass]="{ 'active': !nextPreventions }">
                                En cours
                              </a>
                            </li>
                            <li class="nav-item href" (click)="switchTabPreventions(true)">
                              <a class="nav-link" [ngClass]="{ 'active': nextPreventions }">
                                À venir
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div class="table-responsive">
                        <table class="table table-striped">
                          <thead>
                          <tr>
                            <th class="href" (click)="filterPreventions('Preventions.referency')">
                              <a class="text-muted">Référence</a>
                              <span class="fe fe-arrow-down"
                                    *ngIf="sortByPreventions === 'Preventions.referency' && sortDirectionPreventions === 'asc'"></span>
                              <span class="fe fe-arrow-up"
                                    *ngIf="sortByPreventions === 'Preventions.referency' && sortDirectionPreventions === 'desc'"></span>
                              <span class="fe fe-arrow-right"
                                    *ngIf="sortByPreventions !== 'Preventions.referency'"></span>
                            </th>
                            <th *ngIf="!establishment" class="href"
                                (click)="filterPreventions('Establishments.name')">
                              <a class="text-muted">Établissement</a>
                              <span class="fe fe-arrow-down"
                                    *ngIf="sortByPreventions === 'Establishments.name' && sortDirectionPreventions === 'asc'"></span>
                              <span class="fe fe-arrow-up"
                                    *ngIf="sortByPreventions === 'Establishments.name' && sortDirectionPreventions === 'desc'"></span>
                              <span class="fe fe-arrow-right"
                                    *ngIf="sortByPreventions !== 'Establishments.name'"></span>
                            </th>
                            <th class="href" (click)="filterPreventions('Preventions.eu_social_reason')">
                              <a class="text-muted">Raison sociale</a>
                              <span class="fe fe-arrow-down"
                                    *ngIf="sortByPreventions === 'Preventions.eu_social_reason' && sortDirectionPreventions === 'asc'"></span>
                              <span class="fe fe-arrow-up"
                                    *ngIf="sortByPreventions === 'Preventions.eu_social_reason' && sortDirectionPreventions === 'desc'"></span>
                              <span class="fe fe-arrow-right"
                                    *ngIf="sortByPreventions !== 'Preventions.eu_social_reason'"></span>
                            </th>
                            <th class="href" (click)="filterPreventions('Preventions.operation')">
                              <a class="text-muted">Nature de l'opération</a>
                              <span class="fe fe-arrow-down"
                                    *ngIf="sortByPreventions === 'Preventions.operation' && sortDirectionPreventions === 'asc'"></span>
                              <span class="fe fe-arrow-up"
                                    *ngIf="sortByPreventions === 'Preventions.operation' && sortDirectionPreventions === 'desc'"></span>
                              <span class="fe fe-arrow-right"
                                    *ngIf="sortByPreventions !== 'Preventions.operation'"></span>
                            </th>
                            <th class="href" (click)="filterPreventions('Preventions.begin_date')">
                              <a class="text-muted">Date de début</a>
                              <span class="fe fe-arrow-down"
                                    *ngIf="sortByPreventions === 'Preventions.begin_date' && sortDirectionPreventions === 'asc'"></span>
                              <span class="fe fe-arrow-up"
                                    *ngIf="sortByPreventions === 'Preventions.begin_date' && sortDirectionPreventions === 'desc'"></span>
                              <span class="fe fe-arrow-right"
                                    *ngIf="sortByPreventions !== 'Preventions.begin_date'"></span>
                            </th>
                            <th class="href" (click)="filterPreventions('Preventions.end_date')">
                              <a class="text-muted">Date de fin</a>
                              <span class="fe fe-arrow-down"
                                    *ngIf="sortByPreventions === 'Preventions.end_date' && sortDirectionPreventions === 'asc'"></span>
                              <span class="fe fe-arrow-up"
                                    *ngIf="sortByPreventions === 'Preventions.end_date' && sortDirectionPreventions === 'desc'"></span>
                              <span class="fe fe-arrow-right"
                                    *ngIf="sortByPreventions !== 'Preventions.end_date'"></span>
                            </th>
                            <th></th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr *ngIf="loaderPreventions">
                            <td colspan="7">
                              <div class="is-loading loader-content"></div>
                            </td>
                          </tr>
                          <tr *ngIf="!loaderPreventions && !preventions.length">
                            <td colspan="7">
                              <div class="loader-content">
                                Aucun résultat
                              </div>
                            </td>
                          </tr>
                          <tr class="dropdown" *ngFor="let prevention of preventions">
                            <td>{{ prevention.referency }}</td>
                            <td *ngIf="!establishment">{{ prevention.establishment_registry.establishment.name }}</td>
                            <td>{{ prevention.eu_social_reason }}</td>
                            <td>{{ prevention.operation }}</td>
                            <td>{{ prevention.begin_date | date : 'dd/MM/yyyy' }}</td>
                            <td>{{ prevention.end_date | date : 'dd/MM/yyyy' }}</td>
                            <td class="d-flex flex-row-reverse">
                              <a class="dropdown-ellipses dropdown-toggle href" role="button"
                                 [attr.aria-label]="'Sous menu du tableau de bord des permis feu'"
                                 data-toggle="dropdown"
                                 aria-haspopup="true" aria-expanded="false">
                                <i class="fe fe-more-vertical"></i>
                              </a>

                              <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item href" [routerLink]="[currentRoute + 'preventions/' + prevention.id]" *ngIf="establishment">
                                  {{ canUpdatePreventions ? 'Éditer le permis feu' : 'Consulter le permis feu' }}
                                </a>
                                <a class="dropdown-item href" [routerLink]="[currentRoute + 'establishment/' + prevention.establishment_registry.establishment.id + '/preventions/' + prevention.id]" *ngIf="!establishment">
                                  {{ canUpdatePreventions ? 'Éditer le permis feu' : 'Consulter le permis feu' }}
                                </a>
                              </div>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                      <app-pagination (returnEvent)="dispatchPaginationPreventions($event)"
                                      [count_elements]="countPreventions"
                                      [current_page]="pagePreventions"
                                      [elements_length]="preventions.length"
                                      [limit]="limitPreventions"
                                      [total_pages]="pagesPreventions"
                                      ngDefaultControl>
                      </app-pagination>
                    </div>
                  </div>
                </div>

                <!-- PREVENTIONS SIMPLIFIED -->
                <div class="tab-pane fade" role="tabpanel" aria-labelledby="preventions myTabContent"
                     [ngClass]="{ 'show active': activeTab === 'preventions-simplified'}">
                  <div class="card flex-fill">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-6">
                          <h3 class="header-title">Plans de prévention simplifiés</h3>
                          <small class="form-text text-muted">
                            <span>Liste des plans de prévention simplifiés</span>
                          </small>
                        </div>

                        <div class="col-md-6">
                          <app-searchbar [(ngModel)]="searchPreventionSimplified" (keyup)="searchPreventionsSimplified()" [searchbar_id]="'preventions_simplified_search_bar'" [searchbar_name]="'preventions_simplified_search_bar'" ngDefaultControl></app-searchbar>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-auto ml-auto">
                          <ul class="nav nav-tabs nav-tabs-sm">
                            <li class="nav-item href" (click)="switchTabPreventionsSimplified(false)">
                              <a class="nav-link" [ngClass]="{ 'active': !nextPreventionsSimplified }">
                                En cours
                              </a>
                            </li>
                            <li class="nav-item href" (click)="switchTabPreventionsSimplified(true)">
                              <a class="nav-link" [ngClass]="{ 'active': nextPreventionsSimplified }">
                                À venir
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div class="table-responsive">
                        <table class="table table-striped">
                          <thead>
                          <tr>
                            <th class="href" (click)="filterPreventionsSimplified('Preventions.referency')">
                              <a class="text-muted">Référence</a>
                              <span class="fe fe-arrow-down"
                                    *ngIf="sortByPreventionsSimplified === 'Preventions.referency' && sortDirectionPreventionsSimplified === 'asc'"></span>
                              <span class="fe fe-arrow-up"
                                    *ngIf="sortByPreventionsSimplified === 'Preventions.referency' && sortDirectionPreventionsSimplified === 'desc'"></span>
                              <span class="fe fe-arrow-right"
                                    *ngIf="sortByPreventionsSimplified !== 'Preventions.referency'"></span>
                            </th>
                            <th *ngIf="!establishment" class="href"
                                (click)="filterPreventionsSimplified('Establishments.name')">
                              <a class="text-muted">Établissement</a>
                              <span class="fe fe-arrow-down"
                                    *ngIf="sortByPreventionsSimplified === 'Establishments.name' && sortDirectionPreventionsSimplified === 'asc'"></span>
                              <span class="fe fe-arrow-up"
                                    *ngIf="sortByPreventionsSimplified === 'Establishments.name' && sortDirectionPreventionsSimplified === 'desc'"></span>
                              <span class="fe fe-arrow-right"
                                    *ngIf="sortByPreventionsSimplified !== 'Establishments.name'"></span>
                            </th>
                            <th class="href" (click)="filterPreventionsSimplified('Preventions.eu_social_reason')">
                              <a class="text-muted">Raison sociale</a>
                              <span class="fe fe-arrow-down"
                                    *ngIf="sortByPreventionsSimplified === 'Preventions.eu_social_reason' && sortDirectionPreventionsSimplified === 'asc'"></span>
                              <span class="fe fe-arrow-up"
                                    *ngIf="sortByPreventionsSimplified === 'Preventions.eu_social_reason' && sortDirectionPreventionsSimplified === 'desc'"></span>
                              <span class="fe fe-arrow-right"
                                    *ngIf="sortByPreventionsSimplified !== 'Preventions.eu_social_reason'"></span>
                            </th>
                            <th class="href" (click)="filterPreventionsSimplified('Preventions.operation')">
                              <a class="text-muted">Nature de l'opération</a>
                              <span class="fe fe-arrow-down"
                                    *ngIf="sortByPreventionsSimplified === 'Preventions.operation' && sortDirectionPreventionsSimplified === 'asc'"></span>
                              <span class="fe fe-arrow-up"
                                    *ngIf="sortByPreventionsSimplified === 'Preventions.operation' && sortDirectionPreventionsSimplified === 'desc'"></span>
                              <span class="fe fe-arrow-right"
                                    *ngIf="sortByPreventionsSimplified !== 'Preventions.operation'"></span>
                            </th>
                            <th class="href" (click)="filterPreventionsSimplified('Preventions.begin_date')">
                              <a class="text-muted">Date de début</a>
                              <span class="fe fe-arrow-down"
                                    *ngIf="sortByPreventionsSimplified === 'Preventions.begin_date' && sortDirectionPreventionsSimplified === 'asc'"></span>
                              <span class="fe fe-arrow-up"
                                    *ngIf="sortByPreventionsSimplified === 'Preventions.begin_date' && sortDirectionPreventionsSimplified === 'desc'"></span>
                              <span class="fe fe-arrow-right"
                                    *ngIf="sortByPreventionsSimplified !== 'Preventions.begin_date'"></span>
                            </th>
                            <th class="href" (click)="filterPreventionsSimplified('Preventions.end_Date')">
                              <a class="text-muted">Date de fin</a>
                              <span class="fe fe-arrow-down"
                                    *ngIf="sortByPreventionsSimplified === 'Preventions.end_Date' && sortDirectionPreventionsSimplified === 'asc'"></span>
                              <span class="fe fe-arrow-up"
                                    *ngIf="sortByPreventionsSimplified === 'Preventions.end_Date' && sortDirectionPreventionsSimplified === 'desc'"></span>
                              <span class="fe fe-arrow-right"
                                    *ngIf="sortByPreventionsSimplified !== 'Preventions.end_Date'"></span>
                            </th>
                            <th></th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr *ngIf="loaderPreventionsSimplified">
                            <td colspan="7">
                              <div class="is-loading loader-content"></div>
                            </td>
                          </tr>
                          <tr *ngIf="!loaderPreventionsSimplified && !preventionsSimplified.length">
                            <td colspan="7">
                              <div class="loader-content">
                                Aucun résultat
                              </div>
                            </td>
                          </tr>
                          <tr class="dropdown" *ngFor="let prevention of preventionsSimplified">
                            <td>{{ prevention.referency }}</td>
                            <td *ngIf="!establishment">{{ prevention.establishment_registry.establishment.name }}</td>
                            <td>{{ prevention.eu_social_reason }}</td>
                            <td>{{ prevention.operation }}</td>
                            <td>{{ prevention.begin_date | date : 'dd/MM/yyyy' }}</td>
                            <td>{{ prevention.end_date | date : 'dd/MM/yyyy' }}</td>
                            <td class="d-flex flex-row-reverse">
                              <a class="dropdown-ellipses dropdown-toggle href" role="button"
                                 [attr.aria-label]="'Sous menu du tableau de bord des permis feu'"
                                 data-toggle="dropdown"
                                 aria-haspopup="true" aria-expanded="false">
                                <i class="fe fe-more-vertical"></i>
                              </a>

                              <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item href" [routerLink]="[currentRoute + 'preventions-simplified/' + prevention.id]" *ngIf="establishment">
                                  {{ canUpdatePreventionsSimplified ? 'Éditer le plan de prévention simplifié' : 'Consulter le plan de prévention simplifié' }}
                                </a>
                                <a class="dropdown-item href" [routerLink]="[currentRoute + 'establishment/' + prevention.establishment_registry.establishment.id + '/preventions-simplified/' + prevention.id]" *ngIf="!establishment">
                                  {{ canUpdatePreventionsSimplified ? 'Éditer le plan de prévention simplifié' : 'Consulter le plan de prévention simplifié' }}
                                </a>
                              </div>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                      <app-pagination (returnEvent)="dispatchPaginationPreventionsSimplified($event)"
                                      [count_elements]="countPreventionsSimplified"
                                      [current_page]="pagePreventionsSimplified"
                                      [elements_length]="preventionsSimplified.length"
                                      [limit]="limitPreventionsSimplified"
                                      [total_pages]="pagesPreventionsSimplified"
                                      ngDefaultControl>
                      </app-pagination>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <!-- ASBESTOS -->
            <div *ngIf="registry.asbestos_module">
              <div class="card flex-fill">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-6">
                      <h3 class="header-title">Matériaux et produits contenant de l’amiante</h3>

                      <small class="form-text text-muted">
                        <span>Liste des matériaux et produits contenant de l’amiante</span>
                      </small>
                    </div>

                    <div class="col-md-6">
                      <app-searchbar [(ngModel)]="searchAsbestosValue" (keyup)="searchAsbestos()" [searchbar_id]="'asbestos_search_bar'" [searchbar_name]="'asbestos_search_bar'" ngDefaultControl></app-searchbar>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-auto ml-auto">
                      <ul class="nav nav-tabs nav-tabs-sm">
                        <li class="nav-item href" (click)="switchTabAsbestos(false)">
                          <a class="nav-link" [ngClass]="{ 'active': !allAsbestos }">
                            Mesure d'empoussièrement
                          </a>
                        </li>

                        <li class="nav-item href" (click)="switchTabAsbestos(true)">
                          <a class="nav-link" [ngClass]="{ 'active': allAsbestos }">
                            Évaluations périodiques
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="table-responsive">
                    <table class="table table-striped">
                      <thead>
                      <tr>
                        <th *ngIf="!establishment">
                          <a class="text-muted">Établissement</a>
                        </th>
                        <th class="href" (click)="filterAsbestos('AsbestosDetectionLocalizations.localization')">
                          <a class="text-muted">Localisation</a>
                          <span class="fe fe-arrow-down"
                                *ngIf="sortByAsbestos === 'AsbestosDetectionLocalizations.localization' && sortDirectionAsbestos === 'asc'"></span>
                          <span class="fe fe-arrow-up"
                                *ngIf="sortByAsbestos === 'AsbestosDetectionLocalizations.localization' && sortDirectionAsbestos === 'desc'"></span>
                          <span class="fe fe-arrow-right"
                                *ngIf="sortByAsbestos !== 'AsbestosDetectionLocalizations.localization'"></span>
                        </th>
                        <th class="href"
                            (click)="filterAsbestos('AsbestosDetectionLocalizations.accuracy_localization')">
                          <a class="text-muted">Détail</a>
                          <span class="fe fe-arrow-down"
                                *ngIf="sortByAsbestos === 'AsbestosDetectionLocalizations.accuracy_localization' && sortDirectionAsbestos === 'asc'"></span>
                          <span class="fe fe-arrow-up"
                                *ngIf="sortByAsbestos === 'AsbestosDetectionLocalizations.accuracy_localization' && sortDirectionAsbestos === 'desc'"></span>
                          <span class="fe fe-arrow-right"
                                *ngIf="sortByAsbestos !== 'AsbestosDetectionLocalizations.accuracy_localization'"></span>
                        </th>
                        <th class="href" (click)="filterAsbestos('AsbestosDetectionLocalizations.identifier')">
                          <a class="text-muted">Identifiant</a>
                          <span class="fe fe-arrow-down"
                                *ngIf="sortByAsbestos === 'AsbestosDetectionLocalizations.identifier' && sortDirectionAsbestos === 'asc'"></span>
                          <span class="fe fe-arrow-up"
                                *ngIf="sortByAsbestos === 'AsbestosDetectionLocalizations.identifier' && sortDirectionAsbestos === 'desc'"></span>
                          <span class="fe fe-arrow-right"
                                *ngIf="sortByAsbestos !== 'AsbestosDetectionLocalizations.identifier'"></span>
                        </th>
                        <th class="href" (click)="filterAsbestos('AsbestosDetectionLocalizations.description')">
                          <a class="text-muted">Description</a>
                          <span class="fe fe-arrow-down"
                                *ngIf="sortByAsbestos === 'AsbestosDetectionLocalizations.description' && sortDirectionAsbestos === 'asc'"></span>
                          <span class="fe fe-arrow-up"
                                *ngIf="sortByAsbestos === 'AsbestosDetectionLocalizations.description' && sortDirectionAsbestos === 'desc'"></span>
                          <span class="fe fe-arrow-right"
                                *ngIf="sortByAsbestos !== 'AsbestosDetectionLocalizations.description'"></span>
                        </th>
                        <th class="href" (click)="filterAsbestos('AsbestosDetectionLocalizations.conservation_state')">
                          <a class="text-muted">État de conservation</a>
                          <span class="fe fe-arrow-down"
                                *ngIf="sortByAsbestos === 'AsbestosDetectionLocalizations.conservation_state' && sortDirectionAsbestos === 'asc'"></span>
                          <span class="fe fe-arrow-up"
                                *ngIf="sortByAsbestos === 'AsbestosDetectionLocalizations.conservation_state' && sortDirectionAsbestos === 'desc'"></span>
                          <span class="fe fe-arrow-right"
                                *ngIf="sortByAsbestos !== 'AsbestosDetectionLocalizations.conservation_state'"></span>
                        </th>
                        <th class="href" (click)="filterAsbestos('AsbestosDetectionLocalizations.periodicity')">
                          <a class="text-muted">Périodicité</a>
                          <span class="fe fe-arrow-down"
                                *ngIf="sortByAsbestos === 'AsbestosDetectionLocalizations.periodicity' && sortDirectionAsbestos === 'asc'"></span>
                          <span class="fe fe-arrow-up"
                                *ngIf="sortByAsbestos === 'AsbestosDetectionLocalizations.periodicity' && sortDirectionAsbestos === 'desc'"></span>
                          <span class="fe fe-arrow-right"
                                *ngIf="sortByAsbestos !== 'AsbestosDetectionLocalizations.periodicity'"></span>
                        </th>
                        <th class="href" (click)="filterAsbestos('AsbestosDetectionLocalizations.result')">
                          <a class="text-muted">Résultat</a>
                          <span class="fe fe-arrow-down"
                                *ngIf="sortByAsbestos === 'AsbestosDetectionLocalizations.result' && sortDirectionAsbestos === 'asc'"></span>
                          <span class="fe fe-arrow-up"
                                *ngIf="sortByAsbestos === 'AsbestosDetectionLocalizations.result' && sortDirectionAsbestos === 'desc'"></span>
                          <span class="fe fe-arrow-right"
                                *ngIf="sortByAsbestos !== 'AsbestosDetectionLocalizations.result'"></span>
                        </th>
                        <th></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngIf="loaderAsbestos">
                        <td colspan="10">
                          <div class="is-loading loader-content"></div>
                        </td>
                      </tr>
                      <tr *ngIf="!loaderAsbestos && !asbestosDetectionLocalizations.length">
                        <td colspan="10">
                          <div class="loader-content">
                            Aucun résultat
                          </div>
                        </td>
                      </tr>
                      <tr class="dropdown" *ngFor="let asbestos of asbestosDetectionLocalizations">
                        <td
                          *ngIf="!establishment">{{ asbestos.asbestos_detection.establishment_registry.establishment.name }}</td>
                        <td>{{ asbestos.localization }}</td>
                        <td>{{ asbestos.accuracy_localization }}</td>
                        <td>{{ asbestos.identifier }}</td>
                        <td>{{ asbestos.description }}</td>
                        <td>{{ asbestos.conservation_state }}</td>
                        <td>{{ asbestos.periodicity }}</td>
                        <td>{{ asbestos.result }}</td>
                        <td class="d-flex flex-row-reverse">
                          <a class="dropdown-ellipses dropdown-toggle href" role="button"
                             [attr.aria-label]="'Actions repérage amiante'" data-toggle="dropdown"
                             aria-haspopup="true" aria-expanded="false">
                            <i class="fe fe-more-vertical"></i>
                          </a>

                          <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item href"
                               [routerLink]="[currentRoute + 'asbestos-detection-dashboard/' + asbestos.asbestos_detection_id]">
                              {{ canUpdateAsbestos ? 'Éditer la fiche repérage amiante' : 'Consulter la fiche repérage amiante' }}
                            </a>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <app-pagination (returnEvent)="dispatchPaginationAsbestos($event)"
                                  [count_elements]="countAsbestos"
                                  [current_page]="pageAsbestos"
                                  [elements_length]="asbestosDetectionLocalizations.length"
                                  [limit]="limitAsbestos"
                                  [total_pages]="pagesAsbestos"
                                  ngDefaultControl>
                  </app-pagination>
                </div>
              </div>
            </div>

            <!-- CALENDAR -->
            <div *ngIf="!loaderContent && (
                      registry.checkpoints_module ||
                      registry.checkpoints_custom_module ||
                      registry.checkpoints_direct_module ||
                      registry.commissions_module ||
                      registry.expertises_module ||
                      registry.agencies_module ||
                      registry.formations_module ||
                      registry.asbestos_module)">
              <div class="card flex-fill">
                <div class="card-body">
                  <app-calendar [registry]="registry ? registry : null"
                                [establishment]="establishment ? establishment : null"
                                aria-label="Calendrier"></app-calendar>
                </div>
              </div>
            </div>

            <!-- MAPS -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<router-outlet></router-outlet>
