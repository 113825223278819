<div class="main-content">

  <div class="header bg-dark pb-5">
    <div class="container-fluid">

      <div class="header-body">
        <div class="row align-items-end">
          <div class="col">

            <h6 class="header-pretitle text-secondary">
              TABLEAU DE BORD
            </h6>

            <h1 class="header-title text-white">
              Statistiques Prescriptions
            </h1>

          </div>
          <div class="col-auto">

            <ul class="nav nav-tabs header-tabs">
              <li class="nav-item" data-toggle="chart" data-target="#headerChart">
                <a href="#" class="nav-link text-center active" data-toggle="tab" (click)="updatePrescriptionsChart('month')">
                  <h6 class="header-pretitle text-secondary">
                    GROUPER PAR
                  </h6>
                  <h3 class="text-white mb-0">
                    MOIS
                  </h3>
                </a>
              </li>
              <li class="nav-item" data-toggle="chart" data-target="#headerChart">
                <a href="#" class="nav-link text-center" data-toggle="tab" (click)="updatePrescriptionsChart('day')">
                  <h6 class="header-pretitle text-secondary">
                    GROUPER PAR
                  </h6>
                  <h3 class="text-white mb-0">
                    JOUR
                  </h3>
                </a>
              </li>
            </ul>

          </div>
        </div>
      </div>

      <div class="header-footer">

        <div class="chart">
          <canvas id="headerChart" class="chart-canvas"></canvas>
        </div>

      </div>

    </div>
  </div>

  <div class="container-fluid mt-n6">
    <div class="row">
      <div class="col-12">
        <ul class="nav nav-tabs prescription-tabs" id="myTab" role="tablist">
          <li class="nav-item">
            <a class="nav-link d-flex align-items-center justify-content-center active" id="statistics-tab" data-toggle="tab" href="#statistics" role="tab" aria-controls="statistics" aria-selected="true" (click)="resetFilters()">Statistiques</a>
          </li>
          <li class="nav-item">
            <a class="nav-link d-flex align-items-center justify-content-center" id="comparative-tab" data-toggle="tab" href="#comparative" role="tab" aria-controls="comparative" aria-selected="false" (click)="resetComparativeFilters()">Comparatifs</a>
          </li>
        </ul>

        <div class="tab-content w-100" id="myTabContent">
          <!-- STATISTICS -->
          <div class="tab-pane fade show active" id="statistics" role="tabpanel" aria-labelledby="statistics-tab">
            <div class="card unset-border-top">
              <!-- INPUTS -->
              <div class="card-header">
                <div class="row align-items-center">
                  <!-- ESTABLISHMENTS -->
                  <div class="col-6" *ngIf="establishments">
                    <div class="form-group">
                      <label>Etablissements</label>
                      <ng-select [items]="establishments"
                                 [multiple]="true"
                                 bindLabel="name"
                                 notFoundText="Aucun résultat"
                                 [selectableGroup]="true"
                                 [closeOnSelect]="false"
                                 bindValue="id"
                                 placeholder="Sélectionnez un/des établissement(s)"
                                 [(ngModel)]="selectedEstablishments" (ngModelChange)="updateSelectedEstablishments()" [ngModelOptions]="{ standalone: true }">
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                          <input type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }" /> {{ item.name }}
                        </ng-template>
                      </ng-select>

                    </div>
                  </div>
                  <!-- PRESET TIME -->
                  <div class="col-6" *ngIf="establishments">
                    <div class="form-group">
                      <label>Période</label>
                      <ng-select [items]="periods"
                                 [multiple]="false"
                                 bindLabel="name"
                                 notFoundText="Aucun résultat"
                                 [selectableGroup]="true"
                                 [closeOnSelect]="true"
                                 bindValue="value"
                                 placeholder="Sélectionnez une période"
                                 [(ngModel)]="period" (ngModelChange)="updatePeriod($event)" [ngModelOptions]="{ standalone: true }">
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">{{ item.name }}</ng-template>
                      </ng-select>

                    </div>
                  </div>

                  <!-- DATE FROM -->
                  <div class="col-6">
                    <div class="form-group">
                      <label for="dateFrom">Du</label>
                      <input type="date" [(ngModel)]="dateFrom" (ngModelChange)="updateDate()" class="form-control" id="dateFrom" placeholder="Date du" data-toggle="flatpickr">
                    </div>
                  </div>
                  <!-- DATE TO -->
                  <div class="col-6">
                    <div class="form-group">
                      <label for="dateTo">Au</label>
                      <input type="date" [(ngModel)]="dateTo" (ngModelChange)="updateDate()" class="form-control" id="dateTo" placeholder="Date au" data-toggle="flatpickr">
                    </div>
                  </div>
                  <!-- REGISTRIES -->
                  <div class="col-6" *ngIf="registries">
                    <div class="form-group">
                      <label>Registres</label>
                      <ng-select [items]="registries"
                                 [multiple]="false"
                                 bindLabel="name"
                                 notFoundText="Aucun résultat"
                                 [selectableGroup]="true"
                                 [closeOnSelect]="true"
                                 bindValue="id"
                                 placeholder="Sélectionnez un registre"
                                 [(ngModel)]="selectedRegistry" (ngModelChange)="updateSelectedRegistry()" [ngModelOptions]="{ standalone: true }">
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">{{ item.name }}</ng-template>
                      </ng-select>

                    </div>
                  </div>
                  <!-- CLEAR FILTERS -->
                  <div class="col-12 d-flex align-items-center justify-content-center">
                    <div class="btn-group">
                      <a class="btn btn-block btn-primary mb-4 text-white text-truncate" (click)="resetFilters()">
                        Réinitialiser les filtres
                      </a>
                    </div>
                  </div>

                </div>
              </div>

              <!-- PRESCRIPTIONS -->
              <div class="card-body">
                <div class="is-loading loader-height" *ngIf="loaderPrescChart"></div>
                <div [hidden]="loaderPrescChart" class="row">
                  <!-- CARDS -->
                  <div class="col-6">
                    <!-- TOTAL -->
                    <div class="card cursor" (click)="open('allPrescriptions')">
                      <div class="card-body">

                        <div class="row align-items-center">
                          <div class="col">
                            <h6 class="card-title text-uppercase text-muted mb-2">
                              Nombre total de prescriptions
                            </h6>
                            <span class="h2 mb-0">{{totalPrescriptions}}</span>
                          </div>
                          <div class="col-auto">
                            <span class="h2 fe fe-bar-chart text-muted mb-0"></span>
                          </div>
                        </div>

                      </div>
                    </div>
                    <!-- TOTAL OK -->
                    <div class="card cursor" (click)="open('prescriptionsState')">
                      <div class="card-body">

                        <div class="row align-items-center">
                          <div class="col">
                            <h6 class="card-title text-uppercase text-muted mb-2">
                              Nombre de prescriptions levées
                            </h6>
                            <span class="h2 mb-0">{{totalPrescriptionsL}}</span>
                          </div>
                          <div class="col-auto">
                            <span class="h2 fe fe-bar-chart text-muted mb-0"></span>
                          </div>
                        </div>

                      </div>
                    </div>
                    <!-- TOTAL KO -->
                    <div class="card cursor" (click)="open('prescriptionsNotState')">
                      <div class="card-body">

                        <div class="row align-items-center">
                          <div class="col">
                            <h6 class="card-title text-uppercase text-muted mb-2">
                              Nombre de prescriptions non levées
                            </h6>
                            <span class="h2 mb-0">{{totalPrescriptionsNL}}</span>
                          </div>
                          <div class="col-auto">
                            <span class="h2 fe fe-bar-chart text-muted mb-0"></span>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  <!-- GRAPH CARDS -->
                  <div class="col-6 d-flex align-items-center">
                    <div class="chart w-100">
                      <canvas id="prescChart" class="chart-canvas"></canvas>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col">

                    <h4 class="card-header-title">
                      Degrée d'urgence des prescriptions
                    </h4>

                  </div>
                </div>

              </div>
              <!-- EMERGENCIES BLOCK -->
              <div class="card-body">
                <div class="row">

                  <!-- CHART -->
                  <div class="col-12 d-flex align-items-center">
                    <div class="chart w-100">
                      <div class="is-loading" *ngIf="loaderEmergenciesPrescriptionsChart"></div>
                      <canvas [hidden]="loaderEmergenciesPrescriptionsChart" id="emergenciesPrescriptionsChart" class="chart-canvas"></canvas>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col">

                    <h4 class="card-header-title">
                      Délais de levées de prescriptions
                    </h4>

                  </div>
                </div>

              </div>
              <!-- DELAY BLOCK -->
              <div class="card-body">
                <div class="is-loading loader-height" *ngIf="loaderDelayPrescriptionsChart"></div>
                <div class="row" [hidden]="loaderDelayPrescriptionsChart">
                  <div class="col-6">

                    <!-- TOTAL EN RETARD -->
                    <div class="card cursor" (click)="open('prescriptionsLate')">
                      <div class="card-body">
                        <div class="row align-items-center">
                          <div class="col">
                            <h6 class="card-title text-uppercase text-muted mb-2">
                              Nombre de prescriptions levées en retard
                            </h6>
                            <span class="h2 mb-0">{{totalPrescriptionsDelay + '/' + (totalPrescriptionsDelay + totalPrescriptionsDelayN)}}</span>
                          </div>
                          <div class="col-auto">
                            <span class="h2 fe fe-bar-chart text-muted mb-0"></span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- TOTAL DANS LES TEMPS -->
                    <div class="card cursor" (click)="open('prescriptionsInTime')">
                      <div class="card-body">
                        <div class="row align-items-center">
                          <div class="col">
                            <h6 class="card-title text-uppercase text-muted mb-2">
                              Nombre de prescriptions levées à temps
                            </h6>
                            <span class="h2 mb-0">{{totalPrescriptionsDelayN + '/' + (totalPrescriptionsDelay + totalPrescriptionsDelayN)}}</span>
                          </div>
                          <div class="col-auto">
                            <span class="h2 fe fe-bar-chart text-muted mb-0"></span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- TEMPS MOYEN DE LEVEE -->
                    <div class="card">
                      <div class="card-body">
                        <div class="row align-items-center">
                          <div class="col">
                            <h6 class="card-title text-uppercase text-muted mb-2">
                              Temps moyen de levée de prescriptions (jours)
                            </h6>
                            <span class="h2 mb-0">{{totalPrescriptionsAverageDelay}}</span>
                          </div>
                          <div class="col-auto">
                            <span class="h2 fe fe-bar-chart text-muted mb-0"></span>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

                  <div class="col-6 d-flex align-items-center">
                    <div class="chart w-100">
                      <canvas id="delayPrescriptionsChart" class="chart-canvas"></canvas>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- COMPARATIVE ESTABLISHMENTS -->
          <div class="tab-pane" id="comparative" role="tabpanel" aria-labelledby="comparative-tab">
            <div class="card unset-border-top">
              <!-- INPUTS -->
              <div class="card-header unset-border-top">
                <div class="row align-items-center">
                  <!-- ESTABLISHMENTS -->
                  <div class="col-6" *ngIf="establishments">
                    <div class="form-group">
                      <label>Établissement A</label>
                      <ng-select [items]="establishments"
                                 [multiple]="false"
                                 bindLabel="name"
                                 notFoundText="Aucun résultat"
                                 [selectableGroup]="true"
                                 [closeOnSelect]="true"
                                 bindValue="id"
                                 placeholder="Sélectionnez un établissement"
                                 [(ngModel)]="selectedEstablishmentA" (ngModelChange)="updateSelectedEstablishmentA()" [ngModelOptions]="{ standalone: true }">
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                          <input type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }" /> {{ item.name }}
                        </ng-template>
                      </ng-select>

                    </div>
                  </div>
                  <div class="col-6" *ngIf="establishments">
                    <div class="form-group">
                      <label>Établissement B</label>
                      <ng-select [items]="establishments"
                                 [multiple]="false"
                                 bindLabel="name"
                                 notFoundText="Aucun résultat"
                                 [selectableGroup]="true"
                                 [closeOnSelect]="true"
                                 bindValue="id"
                                 placeholder="Sélectionnez un établissement"
                                 [(ngModel)]="selectedEstablishmentB" (ngModelChange)="updateSelectedEstablishmentB()" [ngModelOptions]="{ standalone: true }">
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                          <input type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }" /> {{ item.name }}
                        </ng-template>
                      </ng-select>

                    </div>
                  </div>
                  <!-- PRESET TIME -->
                  <div class="col-6" *ngIf="establishments">
                    <div class="form-group">
                      <label>Période</label>
                      <ng-select [items]="periods"
                                 [multiple]="false"
                                 bindLabel="name"
                                 notFoundText="Aucun résultat"
                                 [selectableGroup]="true"
                                 [closeOnSelect]="true"
                                 bindValue="value"
                                 placeholder="Sélectionnez une période"
                                 [(ngModel)]="period" (ngModelChange)="updatePeriodComparative($event)" [ngModelOptions]="{ standalone: true }">
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">{{ item.name }}</ng-template>
                      </ng-select>

                    </div>
                  </div>
                  <!-- DATE FROM -->
                  <div class="col-6">
                    <div class="form-group">
                      <label for="dateFromComp">Du</label>
                      <input type="date" [(ngModel)]="dateFrom" (ngModelChange)="updateDateComparative()" class="form-control" id="dateFromComp" placeholder="Date du" data-toggle="flatpickr">
                    </div>
                  </div>
                  <!-- DATE TO -->
                  <div class="col-6">
                    <div class="form-group">
                      <label for="dateToComp">Au</label>
                      <input type="date" [(ngModel)]="dateTo" (ngModelChange)="updateDateComparative()" class="form-control" id="dateToComp" placeholder="Date au" data-toggle="flatpickr">
                    </div>
                  </div>

                  <!-- REGISTRIES -->
                  <div class="col-6" *ngIf="registries">
                    <div class="form-group">
                      <label>Registres</label>
                      <ng-select [items]="registries"
                                 [multiple]="false"
                                 bindLabel="name"
                                 notFoundText="Aucun résultat"
                                 [selectableGroup]="true"
                                 [closeOnSelect]="true"
                                 bindValue="id"
                                 placeholder="Sélectionnez un registre"
                                 [(ngModel)]="selectedRegistry" (ngModelChange)="updateRegistryComparative()" [ngModelOptions]="{ standalone: true }">
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">{{ item.name }}</ng-template>
                      </ng-select>
                    </div>
                  </div>

                  <!-- CLEAR FILTERS -->
                  <div class="col-12 d-flex align-items-center justify-content-center">
                    <div class="btn-group">
                      <a class="btn btn-block btn-primary mb-4 text-white text-truncate" (click)="resetComparativeFilters()">
                        Réinitialiser les filtres
                      </a>
                    </div>
                  </div>


                </div>
              </div>

              <div class="card-body">
                <div class="row">
                  <!-- GRAPH CARDS -->
                  <div class="col-12 d-flex align-items-center">
                    <div class="chart w-100">
                      <div class="is-loading" *ngIf="loaderPrescriptionsComparative"></div>
                      <canvas [hidden]="loaderPrescriptionsComparative" id="createPrescriptionsComparative" class="chart-canvas"></canvas>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="col-12 col-xl-6">
        <div class="card">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">
                <h4 class="card-header-title">
                  Délais moyens de levées
                </h4>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="chart">
              <div class="is-loading" *ngIf="loaderPrescriptionsAverageDays"></div>
              <canvas [hidden]="loaderPrescriptionsAverageDays" id="createPrescriptionsAverageDays" class="chart-canvas"></canvas>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-xl-6">
        <div class="card">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">
                <h4 class="card-header-title">
                  Coûts moyens
                </h4>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="chart">
              <div class="is-loading" *ngIf="loaderPrescriptionsAverageCosts"></div>
              <canvas [hidden]="loaderPrescriptionsAverageCosts" id="createPrescriptionsAverageCosts" class="chart-canvas"></canvas>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>

</div>

<!-------------------------- MODAL FOR OPEN STATS -------------------------->
<!-------------------------- ALL PRESCRIPTIONS -------------------------->
<div class="modal fade" id="allPrescriptions" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
  <div class="modal-dialog prescription" role="document">
    <div class="modal-content modal-prescription">
      <div class="modal-prescription-header">
        <h4 class="modal-title">Nombre total de prescriptions</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
        <div class="is-loading loader-modal-content p-5" *ngIf="loader"></div>
        <div class="table-responsive table-prescriptions" *ngIf="allPrescriptions.length && !loader">
          <table class="table table-striped">
            <thead>
            <tr>
              <th class="href" (click)="filterAllPrescriptions('Establishments.name')">
                <a class="text-muted">Établissement</a>
                <span class="fe fe-arrow-down" *ngIf="allPrescriptionsSort === 'Establishments.name' && allPrescriptionsOrder === 'asc'"></span>
                <span class="fe fe-arrow-up" *ngIf="allPrescriptionsSort === 'Establishments.name' && allPrescriptionsOrder === 'desc'"></span>
                <span class="fe fe-arrow-right" *ngIf="allPrescriptionsSort !== 'Establishments.name'"></span>
              </th>
              <th class="href" (click)="filterAllPrescriptions('Commissions.visit_date')">
                <a class="text-muted">Date du rapport</a>
                <span class="fe fe-arrow-down" *ngIf="allPrescriptionsSort === 'Commissions.visit_date' && allPrescriptionsOrder === 'asc'"></span>
                <span class="fe fe-arrow-up" *ngIf="allPrescriptionsSort === 'Commissions.visit_date' && allPrescriptionsOrder === 'desc'"></span>
                <span class="fe fe-arrow-right" *ngIf="allPrescriptionsSort !== 'Commissions.visit_date'"></span>
              </th>
              <th>Nom de la prescription</th>
              <th>Prescription levée ?</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr class="dropdown" *ngFor="let prescription of allPrescriptions">
              <td>{{ getEstablishmentNamePrescription(prescription) }}</td>
              <td>{{ prescription['commission'] && prescription['commission']['visit_date'] ? (prescription['commission']['visit_date'] | date : 'dd/MM/yyyy') : '' }}</td>
              <td>{{ prescription['name'] }}</td>
              <td>
                <span class="label" [ngClass]="{'label-unactive': prescription['state'] === false, 'label-active': prescription['state'] === true }">
                  {{ prescription['state'] ? 'Oui' : 'Non' }}
                </span>
              </td>
              <td class="d-flex flex-row-reverse">
                <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i class="fe fe-more-vertical"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-right">
                  <a class="dropdown-item href" [routerLink]="[getUrlPrescription(prescription)]">
                    lien vers la prescription
                  </a>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
          <app-pagination (returnEvent)="dispatchPaginationAll($event)"
                          [count_elements]="allPrescriptionsCount"
                          [current_page]="allPrescriptionsPage"
                          [elements_length]="allPrescriptions.length"
                          [limit]="allPrescriptionsLimit"
                          [total_pages]="allPrescriptionsPages"
                          ngDefaultControl>
          </app-pagination>
        </div>
        <div class="table-responsive table-prescriptions" *ngIf="!allPrescriptions.length && !loader">
          Aucun résultat
        </div>
      </div>
    </div>
  </div>
</div>
<!------------------------- PRESCRIPTIONS STATES ------------------------->
<div class="modal fade" id="prescriptionsState" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
  <div class="modal-dialog prescription" role="document">
    <div class="modal-content modal-prescription">
      <div class="modal-prescription-header">
        <h4 class="modal-title">Prescriptions levées</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
        <div class="is-loading loader-modal-content p-5" *ngIf="loader"></div>
        <div class="table-responsive table-prescriptions" *ngIf="prescriptionsState.length && !loader">
          <table class="table table-striped">
            <thead>
            <tr>
              <th class="href" (click)="filterPrescriptionsState('Establishments.name')">
                <a class="text-muted">Établissement</a>
                <span class="fe fe-arrow-down" *ngIf="prescriptionsStateSort === 'Establishments.name' && prescriptionsStateOrder === 'asc'"></span>
                <span class="fe fe-arrow-up" *ngIf="prescriptionsStateSort === 'Establishments.name' && prescriptionsStateOrder === 'desc'"></span>
                <span class="fe fe-arrow-right" *ngIf="prescriptionsStateSort !== 'Establishments.name'"></span>
              </th>
              <th class="href" (click)="filterPrescriptionsState('Commissions.visit_date')">
                <a class="text-muted">Date du rapport</a>
                <span class="fe fe-arrow-down" *ngIf="prescriptionsStateSort === 'Commissions.visit_date' && prescriptionsStateOrder === 'asc'"></span>
                <span class="fe fe-arrow-up" *ngIf="prescriptionsStateSort === 'Commissions.visit_date' && prescriptionsStateOrder === 'desc'"></span>
                <span class="fe fe-arrow-right" *ngIf="prescriptionsStateSort !== 'Commissions.visit_date'"></span>
              </th>
              <th>Nom de la prescription</th>
              <th>Prescription levée ?</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr class="dropdown" *ngFor="let prescription of prescriptionsState">
              <td>{{ getEstablishmentNamePrescription(prescription) }}</td>
              <td>{{ prescription['commission'] && prescription['commission']['visit_date'] ? (prescription['commission']['visit_date'] | date : 'dd/MM/yyyy') : '' }}</td>
              <td>{{ prescription['name'] }}</td>
              <td>
                  <span class="label" [ngClass]="{'label-unactive': prescription['state'] === false, 'label-active': prescription['state'] === true }">
                    {{ prescription['state'] ? 'Oui' : 'Non' }}
                  </span>
              </td>
              <td class="d-flex flex-row-reverse">
                <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i class="fe fe-more-vertical"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-right">
                  <a class="dropdown-item href" [routerLink]="[getUrlPrescription(prescription)]">
                    lien vers la prescription
                  </a>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
          <app-pagination (returnEvent)="dispatchPaginationState($event)"
                          [count_elements]="prescriptionsStateCount"
                          [current_page]="prescriptionsStatePage"
                          [elements_length]="prescriptionsState.length"
                          [limit]="prescriptionsStateLimit"
                          [total_pages]="prescriptionsStatePages"
                          ngDefaultControl>
          </app-pagination>
        </div>
        <div class="table-responsive table-prescriptions" *ngIf="!prescriptionsState.length && !loader">
          Aucun résultat
        </div>
      </div>
    </div>
  </div>
</div>
<!------------------------- PRESCRIPTIONS NOT STATES ------------------------->
<div class="modal fade" id="prescriptionsNotState" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
  <div class="modal-dialog prescription" role="document">
    <div class="modal-content modal-prescription">
      <div class="modal-prescription-header">
        <h4 class="modal-title">Prescriptions non levées</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
        <div class="is-loading loader-modal-content p-5" *ngIf="loader"></div>
        <div class="table-responsive table-prescriptions" *ngIf="prescriptionsNotState.length && !loader">
          <table class="table table-striped">
            <thead>
            <tr>
              <th class="href" (click)="filterPrescriptionsNotState('Establishments.name')">
                <a class="text-muted">Établissement</a>
                <span class="fe fe-arrow-down" *ngIf="prescriptionsNotStateSort === 'Establishments.name' && prescriptionsNotStateOrder === 'asc'"></span>
                <span class="fe fe-arrow-up" *ngIf="prescriptionsNotStateSort === 'Establishments.name' && prescriptionsNotStateOrder === 'desc'"></span>
                <span class="fe fe-arrow-right" *ngIf="prescriptionsNotStateSort !== 'Establishments.name'"></span>
              </th>
              <th class="href" (click)="filterPrescriptionsNotState('Commissions.visit_date')">
                <a class="text-muted">Date du rapport</a>
                <span class="fe fe-arrow-down" *ngIf="prescriptionsNotStateSort === 'Commissions.visit_date' && prescriptionsNotStateOrder === 'asc'"></span>
                <span class="fe fe-arrow-up" *ngIf="prescriptionsNotStateSort === 'Commissions.visit_date' && prescriptionsNotStateOrder === 'desc'"></span>
                <span class="fe fe-arrow-right" *ngIf="prescriptionsNotStateSort !== 'Commissions.visit_date'"></span>
              </th>
              <th>Nom de la prescription</th>
              <th>Prescription levée ?</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr class="dropdown" *ngFor="let prescription of prescriptionsNotState">
              <td>{{ getEstablishmentNamePrescription(prescription) }}</td>
              <td>{{ prescription['commission'] && prescription['commission']['visit_date'] ? (prescription['commission']['visit_date'] | date : 'dd/MM/yyyy') : '' }}</td>
              <td>{{ prescription['name'] }}</td>
              <td>
                  <span class="label" [ngClass]="{'label-unactive': prescription['state'] === false, 'label-active': prescription['state'] === true }">
                    {{ prescription['state'] ? 'Oui' : 'Non' }}
                  </span>
              </td>
              <td class="d-flex flex-row-reverse">
                <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i class="fe fe-more-vertical"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-right">
                  <a class="dropdown-item href" [routerLink]="[getUrlPrescription(prescription)]">
                    lien vers la prescription
                  </a>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
          <app-pagination (returnEvent)="dispatchPaginationNotState($event)"
                          [count_elements]="prescriptionsNotStateCount"
                          [current_page]="prescriptionsNotStatePage"
                          [elements_length]="prescriptionsNotState.length"
                          [limit]="prescriptionsNotStateLimit"
                          [total_pages]="prescriptionsNotStatePages"
                          ngDefaultControl>
          </app-pagination>
        </div>
        <div class="table-responsive table-prescriptions" *ngIf="!prescriptionsNotState.length && !loader">
          Aucun résultat
        </div>
      </div>
    </div>
  </div>
</div>
<!------------------------------ EMERGENCIES ------------------------------>
<!------------------------------ NC ------------------------------>
<div class="modal fade" id="prescriptionsNC" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
  <div class="modal-dialog prescription" role="document">
    <div class="modal-content modal-prescription">
      <div class="modal-prescription-header">
        <h4 class="modal-title">Prescriptions degrée d'urgence non communiqué</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
        <div class="is-loading loader-modal-content p-5" *ngIf="loader"></div>
        <div class="table-responsive table-prescriptions" *ngIf="prescriptionsNC.length && !loader">
          <table class="table table-striped">
            <thead>
            <tr>
              <th class="href" (click)="filterPrescriptionsNC('Establishments.name')">
                <a class="text-muted">Établissement</a>
                <span class="fe fe-arrow-down" *ngIf="prescriptionsNCSort === 'Establishments.name' && prescriptionsNCOrder === 'asc'"></span>
                <span class="fe fe-arrow-up" *ngIf="prescriptionsNCSort === 'Establishments.name' && prescriptionsNCOrder === 'desc'"></span>
                <span class="fe fe-arrow-right" *ngIf="prescriptionsNCSort !== 'Establishments.name'"></span>
              </th>
              <th class="href" (click)="filterPrescriptionsNC('Commissions.visit_date')">
                <a class="text-muted">Date du rapport</a>
                <span class="fe fe-arrow-down" *ngIf="prescriptionsNCSort === 'Commissions.visit_date' && prescriptionsNCOrder === 'asc'"></span>
                <span class="fe fe-arrow-up" *ngIf="prescriptionsNCSort === 'Commissions.visit_date' && prescriptionsNCOrder === 'desc'"></span>
                <span class="fe fe-arrow-right" *ngIf="prescriptionsNCSort !== 'Commissions.visit_date'"></span>
              </th>
              <th>Nom de la prescription</th>
              <th>Prescription levée ?</th>
              <th>Degrée d'urgence</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr class="dropdown" *ngFor="let prescription of prescriptionsNC">
              <td>{{ getEstablishmentNamePrescription(prescription) }}</td>
              <td>{{ prescription['commission'] && prescription['commission']['visit_date'] ? (prescription['commission']['visit_date'] | date : 'dd/MM/yyyy') : '' }}</td>
              <td>{{ prescription['name'] }}</td>
              <td>
                  <span class="label" [ngClass]="{'label-unactive': prescription['state'] === false, 'label-active': prescription['state'] === true }">
                    {{ prescription['state'] ? 'Oui' : 'Non' }}
                  </span>
              </td>
              <td>{{ prescription['emergencies'] === null ? 'Non communiqué' : '' }}</td>
              <td class="d-flex flex-row-reverse">
                <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i class="fe fe-more-vertical"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-right">
                  <a class="dropdown-item href" [routerLink]="[getUrlPrescription(prescription)]">
                    lien vers la prescription
                  </a>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
          <app-pagination (returnEvent)="dispatchPaginationNC($event)"
                          [count_elements]="prescriptionsNCCount"
                          [current_page]="prescriptionsNCPage"
                          [elements_length]="prescriptionsNC.length"
                          [limit]="prescriptionsNCLimit"
                          [total_pages]="prescriptionsNCPages"
                          ngDefaultControl>
          </app-pagination>
        </div>
        <div class="table-responsive table-prescriptions" *ngIf="!prescriptionsNC.length && !loader">
          Aucun résultat
        </div>
      </div>
    </div>
  </div>
</div>
<!------------------------------ LOW ------------------------------>
<div class="modal fade" id="prescriptionsLow" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
  <div class="modal-dialog prescription" role="document">
    <div class="modal-content modal-prescription">
      <div class="modal-prescription-header">
        <h4 class="modal-title">Prescriptions degrée d'urgence bas</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
        <div class="is-loading loader-modal-content p-5" *ngIf="loader"></div>
        <div class="table-responsive table-prescriptions" *ngIf="prescriptionsLow.length && !loader">
          <table class="table table-striped">
            <thead>
            <tr>
              <th class="href" (click)="filterPrescriptionsLow('Establishments.name')">
                <a class="text-muted">Établissement</a>
                <span class="fe fe-arrow-down" *ngIf="prescriptionsLowSort === 'Establishments.name' && prescriptionsLowOrder === 'asc'"></span>
                <span class="fe fe-arrow-up" *ngIf="prescriptionsLowSort === 'Establishments.name' && prescriptionsLowOrder === 'desc'"></span>
                <span class="fe fe-arrow-right" *ngIf="prescriptionsLowSort !== 'Establishments.name'"></span>
              </th>
              <th class="href" (click)="filterPrescriptionsLow('Commissions.visit_date')">
                <a class="text-muted">Date du rapport</a>
                <span class="fe fe-arrow-down" *ngIf="prescriptionsLowSort === 'Commissions.visit_date' && prescriptionsLowOrder === 'asc'"></span>
                <span class="fe fe-arrow-up" *ngIf="prescriptionsLowSort === 'Commissions.visit_date' && prescriptionsLowOrder === 'desc'"></span>
                <span class="fe fe-arrow-right" *ngIf="prescriptionsLowSort !== 'Commissions.visit_date'"></span>
              </th>
              <th>Nom de la prescription</th>
              <th>Prescription levée ?</th>
              <th>Degrée d'urgence</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr class="dropdown" *ngFor="let prescription of prescriptionsLow">
              <td>{{ getEstablishmentNamePrescription(prescription) }}</td>
              <td>{{ prescription['commission'] && prescription['commission']['visit_date'] ? (prescription['commission']['visit_date'] | date : 'dd/MM/yyyy') : '' }}</td>
              <td>{{ prescription['name'] }}</td>
              <td>
                <span class="label" [ngClass]="{'label-unactive': prescription['state'] === false, 'label-active': prescription['state'] === true }">
                  {{ prescription['state'] ? 'Oui' : 'Non' }}
                </span>
              </td>
              <td>{{ prescription['emergencies'] === 0 ? 'Bas' : '' }}</td>
              <td class="d-flex flex-row-reverse">
                <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i class="fe fe-more-vertical"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-right">
                  <a class="dropdown-item href" [routerLink]="[getUrlPrescription(prescription)]">
                    lien vers la prescription
                  </a>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
          <app-pagination (returnEvent)="dispatchPaginationLow($event)"
                          [count_elements]="prescriptionsLowCount"
                          [current_page]="prescriptionsLowPage"
                          [elements_length]="prescriptionsLow.length"
                          [limit]="prescriptionsLowLimit"
                          [total_pages]="prescriptionsLowPages"
                          ngDefaultControl>
          </app-pagination>
        </div>
        <div class="table-responsive table-prescriptions" *ngIf="!prescriptionsLow.length && !loader">
          Aucun résultat
        </div>
      </div>
    </div>
  </div>
</div>
<!------------------------------ NORMAL ------------------------------>
<div class="modal fade" id="prescriptionsNormal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
  <div class="modal-dialog prescription" role="document">
    <div class="modal-content modal-prescription">
      <div class="modal-prescription-header">
        <h4 class="modal-title">Prescriptions degrée d'urgence normal</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
        <div class="is-loading loader-modal-content p-5" *ngIf="loader"></div>
        <div class="table-responsive table-prescriptions" *ngIf="prescriptionsNormal.length && !loader">
          <table class="table table-striped">
            <thead>
            <tr>
              <th class="href" (click)="filterPrescriptionsNormal('Establishments.name')">
                <a class="text-muted">Établissement</a>
                <span class="fe fe-arrow-down" *ngIf="prescriptionsNormalSort === 'Establishments.name' && prescriptionsNormalOrder === 'asc'"></span>
                <span class="fe fe-arrow-up" *ngIf="prescriptionsNormalSort === 'Establishments.name' && prescriptionsNormalOrder === 'desc'"></span>
                <span class="fe fe-arrow-right" *ngIf="prescriptionsNormalSort !== 'Establishments.name'"></span>
              </th>
              <th class="href" (click)="filterPrescriptionsNormal('Commissions.visit_date')">
                <a class="text-muted">Date du rapport</a>
                <span class="fe fe-arrow-down" *ngIf="prescriptionsNormalSort === 'Commissions.visit_date' && prescriptionsNormalOrder === 'asc'"></span>
                <span class="fe fe-arrow-up" *ngIf="prescriptionsNormalSort === 'Commissions.visit_date' && prescriptionsNormalOrder === 'desc'"></span>
                <span class="fe fe-arrow-right" *ngIf="prescriptionsNormalSort !== 'Commissions.visit_date'"></span>
              </th>
              <th>Nom de la prescription</th>
              <th>Prescription levée ?</th>
              <th>Degrée d'urgence</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr class="dropdown" *ngFor="let prescription of prescriptionsNormal">
              <td>{{ getEstablishmentNamePrescription(prescription) }}</td>
              <td>{{ prescription['commission'] && prescription['commission']['visit_date'] ? (prescription['commission']['visit_date'] | date : 'dd/MM/yyyy') : '' }}</td>
              <td>{{ prescription['name'] }}</td>
              <td>
                  <span class="label" [ngClass]="{'label-unactive': prescription['state'] === false, 'label-active': prescription['state'] === true }">
                    {{ prescription['state'] ? 'Oui' : 'Non' }}
                  </span>
              </td>
              <td>{{ prescription['emergencies'] === 1 ? 'Normal' : '' }}</td>
              <td class="d-flex flex-row-reverse">
                <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i class="fe fe-more-vertical"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-right">
                  <a class="dropdown-item href" [routerLink]="[getUrlPrescription(prescription)]">
                    lien vers la prescription
                  </a>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
          <app-pagination (returnEvent)="dispatchPaginationNormal($event)"
                          [count_elements]="prescriptionsNormalCount"
                          [current_page]="prescriptionsNormalPage"
                          [elements_length]="prescriptionsNormal.length"
                          [limit]="prescriptionsNormalLimit"
                          [total_pages]="prescriptionsNormalPages"
                          ngDefaultControl>
          </app-pagination>
        </div>
        <div class="table-responsive table-prescriptions" *ngIf="!prescriptionsNormal.length && !loader">
          Aucun résultat
        </div>
      </div>
    </div>
  </div>
</div>
<!------------------------------ HIGHER ------------------------------>
<div class="modal fade" id="prescriptionsHigher" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
  <div class="modal-dialog prescription" role="document">
    <div class="modal-content modal-prescription">
      <div class="modal-prescription-header">
        <h4 class="modal-title">Prescriptions degrée d'urgence haut</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
        <div class="is-loading loader-modal-content p-5" *ngIf="loader"></div>
        <div class="table-responsive table-prescriptions" *ngIf="prescriptionsHigher.length && !loader">
          <table class="table table-striped">
            <thead>
            <tr>
              <th class="href" (click)="filterPrescriptionsHigher('Establishments.name')">
                <a class="text-muted">Établissement</a>
                <span class="fe fe-arrow-down" *ngIf="prescriptionsHigherSort === 'Establishments.name' && prescriptionsHigherOrder === 'asc'"></span>
                <span class="fe fe-arrow-up" *ngIf="prescriptionsHigherSort === 'Establishments.name' && prescriptionsHigherOrder === 'desc'"></span>
                <span class="fe fe-arrow-right" *ngIf="prescriptionsHigherSort !== 'Establishments.name'"></span>
              </th>
              <th class="href" (click)="filterPrescriptionsHigher('Commissions.visit_date')">
                <a class="text-muted">Date du rapport</a>
                <span class="fe fe-arrow-down" *ngIf="prescriptionsHigherSort === 'Commissions.visit_date' && prescriptionsHigherOrder === 'asc'"></span>
                <span class="fe fe-arrow-up" *ngIf="prescriptionsHigherSort === 'Commissions.visit_date' && prescriptionsHigherOrder === 'desc'"></span>
                <span class="fe fe-arrow-right" *ngIf="prescriptionsHigherSort !== 'Commissions.visit_date'"></span>
              </th>
              <th>Nom de la prescription</th>
              <th>Prescription levée ?</th>
              <th>Degrée d'urgence</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr class="dropdown" *ngFor="let prescription of prescriptionsHigher">
              <td>{{ getEstablishmentNamePrescription(prescription) }}</td>
              <td>{{ prescription['commission'] && prescription['commission']['visit_date'] ? (prescription['commission']['visit_date'] | date : 'dd/MM/yyyy') : '' }}</td>
              <td>{{ prescription['name'] }}</td>
              <td>
                  <span class="label" [ngClass]="{'label-unactive': prescription['state'] === false, 'label-active': prescription['state'] === true }">
                    {{ prescription['state'] ? 'Oui' : 'Non' }}
                  </span>
              </td>
              <td>{{ prescription['emergencies'] === 2 ? 'Haut' : '' }}</td>
              <td class="d-flex flex-row-reverse">
                <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i class="fe fe-more-vertical"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-right">
                  <a class="dropdown-item href" [routerLink]="[getUrlPrescription(prescription)]">
                    lien vers la prescription
                  </a>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
          <app-pagination (returnEvent)="dispatchPaginationHigher($event)"
                          [count_elements]="prescriptionsHigherCount"
                          [current_page]="prescriptionsHigherPage"
                          [elements_length]="prescriptionsHigher.length"
                          [limit]="prescriptionsHigherLimit"
                          [total_pages]="prescriptionsHigherPages"
                          ngDefaultControl>
          </app-pagination>
        </div>
        <div class="table-responsive table-prescriptions" *ngIf="!prescriptionsHigher.length && !loader">
          Aucun résultat
        </div>
      </div>
    </div>
  </div>
</div>
<!------------------------------ PRIORITY ------------------------------>
<div class="modal fade" id="prescriptionsPriority" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
  <div class="modal-dialog prescription" role="document">
    <div class="modal-content modal-prescription">
      <div class="modal-prescription-header">
        <h4 class="modal-title">Prescriptions degrée d'urgence prioritaire</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
        <div class="is-loading loader-modal-content p-5" *ngIf="loader"></div>
        <div class="table-responsive table-prescriptions" *ngIf="prescriptionsPriority.length && !loader">
          <table class="table table-striped">
            <thead>
            <tr>
              <th class="href" (click)="filterPrescriptionsPriority('Establishments.name')">
                <a class="text-muted">Établissement</a>
                <span class="fe fe-arrow-down" *ngIf="prescriptionsPrioritySort === 'Establishments.name' && prescriptionsPriorityOrder === 'asc'"></span>
                <span class="fe fe-arrow-up" *ngIf="prescriptionsPrioritySort === 'Establishments.name' && prescriptionsPriorityOrder === 'desc'"></span>
                <span class="fe fe-arrow-right" *ngIf="prescriptionsPrioritySort !== 'Establishments.name'"></span>
              </th>
              <th class="href" (click)="filterPrescriptionsPriority('Commissions.visit_date')">
                <a class="text-muted">Date du rapport</a>
                <span class="fe fe-arrow-down" *ngIf="prescriptionsPrioritySort === 'Commissions.visit_date' && prescriptionsPriorityOrder === 'asc'"></span>
                <span class="fe fe-arrow-up" *ngIf="prescriptionsPrioritySort === 'Commissions.visit_date' && prescriptionsPriorityOrder === 'desc'"></span>
                <span class="fe fe-arrow-right" *ngIf="prescriptionsPrioritySort !== 'Commissions.visit_date'"></span>
              </th>
              <th>Nom de la prescription</th>
              <th>Prescription levée ?</th>
              <th>Degrée d'urgence</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr class="dropdown" *ngFor="let prescription of prescriptionsPriority">
              <td>{{ getEstablishmentNamePrescription(prescription) }}</td>
              <td>{{ prescription['commission'] && prescription['commission']['visit_date'] ? (prescription['commission']['visit_date'] | date : 'dd/MM/yyyy') : '' }}</td>
              <td>{{ prescription['name'] }}</td>
              <td>
                  <span class="label" [ngClass]="{'label-unactive': prescription['state'] === false, 'label-active': prescription['state'] === true }">
                    {{ prescription['state'] ? 'Oui' : 'Non' }}
                  </span>
              </td>
              <td>{{ prescription['emergencies'] === 3 ? 'Prioritaire' : '' }}</td>
              <td class="d-flex flex-row-reverse">
                <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i class="fe fe-more-vertical"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-right">
                  <a class="dropdown-item href" [routerLink]="[getUrlPrescription(prescription)]">
                    lien vers la prescription
                  </a>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
          <app-pagination (returnEvent)="dispatchPaginationPriority($event)"
                          [count_elements]="prescriptionsPriorityCount"
                          [current_page]="prescriptionsPriorityPage"
                          [elements_length]="prescriptionsPriority.length"
                          [limit]="prescriptionsPriorityLimit"
                          [total_pages]="prescriptionsPriorityPages"
                          ngDefaultControl>
          </app-pagination>
        </div>
        <div class="table-responsive table-prescriptions" *ngIf="!prescriptionsPriority.length && !loader">
          Aucun résultat
        </div>
      </div>
    </div>
  </div>
</div>
<!------------------------------ DELAY ------------------------------>
<!------------------------------ LATE ------------------------------>
<div class="modal fade" id="prescriptionsLate" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
  <div class="modal-dialog prescription" role="document">
    <div class="modal-content modal-prescription">
      <div class="modal-prescription-header">
        <h4 class="modal-title">Nombre de prescriptions levées en retard</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
        <div class="is-loading loader-modal-content p-5" *ngIf="loader"></div>
        <div class="table-responsive table-prescriptions" *ngIf="prescriptionsLate.length && !loader">
          <table class="table table-striped">
            <thead>
            <tr>
              <th class="href" (click)="filterPrescriptionsLate('Establishments.name')">
                <a class="text-muted">Établissement</a>
                <span class="fe fe-arrow-down" *ngIf="prescriptionsLateSort === 'Establishments.name' && prescriptionsLateOrder === 'asc'"></span>
                <span class="fe fe-arrow-up" *ngIf="prescriptionsLateSort === 'Establishments.name' && prescriptionsLateOrder === 'desc'"></span>
                <span class="fe fe-arrow-right" *ngIf="prescriptionsLateSort !== 'Establishments.name'"></span>
              </th>
              <th class="href" (click)="filterPrescriptionsLate('Commissions.visit_date')">
                <a class="text-muted">Date du rapport</a>
                <span class="fe fe-arrow-down" *ngIf="prescriptionsLateSort === 'Commissions.visit_date' && prescriptionsLateOrder === 'asc'"></span>
                <span class="fe fe-arrow-up" *ngIf="prescriptionsLateSort === 'Commissions.visit_date' && prescriptionsLateOrder === 'desc'"></span>
                <span class="fe fe-arrow-right" *ngIf="prescriptionsLateSort !== 'Commissions.visit_date'"></span>
              </th>
              <th>Date de levée</th>
              <th>Nom de la prescription</th>
              <th>Deadline</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr class="dropdown" *ngFor="let prescription of prescriptionsLate">
              <td>{{ getEstablishmentNamePrescription(prescription) }}</td>
              <td>{{ prescription['commission'] && prescription['commission']['visit_date'] ? (prescription['commission']['visit_date'] | date : 'dd/MM/yyyy') : '' }}</td>
              <td>{{ prescription['date_changing_state'] ? (prescription['date_changing_state'] | date : 'dd/MM/yyyy') : '' }}</td>
              <td>{{ prescription['name'] }}</td>
              <td>{{ prescription['deadline'] ? (prescription['deadline'] | date : 'dd/MM/yyyy') : '' }}</td>
              <td class="d-flex flex-row-reverse">
                <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i class="fe fe-more-vertical"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-right">
                  <a class="dropdown-item href" [routerLink]="[getUrlPrescription(prescription)]">
                    lien vers la prescription
                  </a>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
          <app-pagination (returnEvent)="dispatchPaginationLate($event)"
                          [count_elements]="prescriptionsLateCount"
                          [current_page]="prescriptionsLatePage"
                          [elements_length]="prescriptionsLate.length"
                          [limit]="prescriptionsLateLimit"
                          [total_pages]="prescriptionsLatePages"
                          ngDefaultControl>
          </app-pagination>
        </div>
        <div class="table-responsive table-prescriptions" *ngIf="!prescriptionsLate.length && !loader">
          Aucun résultat
        </div>
      </div>
    </div>
  </div>
</div>
<!------------------------------ IN TIME ------------------------------>
<div class="modal fade" id="prescriptionsInTime" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
  <div class="modal-dialog prescription" role="document">
    <div class="modal-content modal-prescription">
      <div class="modal-prescription-header">
        <h4 class="modal-title">Nombre de prescription levées à temps</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
        <div class="is-loading loader-modal-content p-5" *ngIf="loader"></div>
        <div class="table-responsive table-prescriptions" *ngIf="prescriptionsInTime.length && !loader">
          <table class="table table-striped">
            <thead>
            <tr>
              <th class="href" (click)="filterPrescriptionsInTime('Establishments.name')">
                <a class="text-muted">Établissement</a>
                <span class="fe fe-arrow-down" *ngIf="prescriptionsInTimeSort === 'Establishments.name' && prescriptionsInTimeOrder === 'asc'"></span>
                <span class="fe fe-arrow-up" *ngIf="prescriptionsInTimeSort === 'Establishments.name' && prescriptionsInTimeOrder === 'desc'"></span>
                <span class="fe fe-arrow-right" *ngIf="prescriptionsInTimeSort !== 'Establishments.name'"></span>
              </th>
              <th class="href" (click)="filterPrescriptionsInTime('Commissions.visit_date')">
                <a class="text-muted">Date du rapport</a>
                <span class="fe fe-arrow-down" *ngIf="prescriptionsInTimeSort === 'Commissions.visit_date' && prescriptionsInTimeOrder === 'asc'"></span>
                <span class="fe fe-arrow-up" *ngIf="prescriptionsInTimeSort === 'Commissions.visit_date' && prescriptionsInTimeOrder === 'desc'"></span>
                <span class="fe fe-arrow-right" *ngIf="prescriptionsInTimeSort !== 'Commissions.visit_date'"></span>
              </th>
              <th>Date de levée</th>
              <th>Nom de la prescription</th>
              <th>Deadline</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr class="dropdown" *ngFor="let prescription of prescriptionsInTime">
              <td>{{ getEstablishmentNamePrescription(prescription) }}</td>
              <td>{{ prescription['commission'] && prescription['commission']['visit_date'] ? (prescription['commission']['visit_date'] | date : 'dd/MM/yyyy') : '' }}</td>
              <td>{{ prescription['date_changing_state'] ? (prescription['date_changing_state'] | date : 'dd/MM/yyyy') : '' }}</td>
              <td>{{ prescription['name'] }}</td>
              <td>{{ prescription['deadline'] ? (prescription['deadline'] | date : 'dd/MM/yyyy') : '' }}</td>
              <td class="d-flex flex-row-reverse">
                <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i class="fe fe-more-vertical"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-right">
                  <a class="dropdown-item href" [routerLink]="[getUrlPrescription(prescription)]">
                    lien vers la prescription
                  </a>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
          <app-pagination (returnEvent)="dispatchPaginationInTime($event)"
                          [count_elements]="prescriptionsInTimeCount"
                          [current_page]="prescriptionsInTimePage"
                          [elements_length]="prescriptionsInTime.length"
                          [limit]="prescriptionsInTimeLimit"
                          [total_pages]="prescriptionsInTimePages"
                          ngDefaultControl>
          </app-pagination>
        </div>
        <div class="table-responsive table-prescriptions" *ngIf="!prescriptionsInTime.length && !loader">
          Aucun résultat
        </div>
      </div>
    </div>
  </div>
</div>
<!------------------------------ AVERAGE DAY OF STATE ------------------------------>
<div class="modal fade" id="AverageDayFilter1" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
  <div class="modal-dialog prescription" role="document">
    <div class="modal-content modal-prescription">
      <div class="modal-prescription-header">
        <h4 class="modal-title">Prescriptions levées en moins d'une semaine</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
        <div class="is-loading loader-modal-content p-5" *ngIf="loader"></div>
        <div class="table-responsive table-prescriptions" *ngIf="dayFilter1.length && !loader">
          <table class="table table-striped">
            <thead>
            <tr>
              <th class="href" (click)="filtersDayFilter1('Establishments.name')">
                <a class="text-muted">Établissement</a>
                <span class="fe fe-arrow-down" *ngIf="dayFilter1Sort === 'Establishments.name' && dayFilter1Order === 'asc'"></span>
                <span class="fe fe-arrow-up" *ngIf="dayFilter1Sort === 'Establishments.name' && dayFilter1Order === 'desc'"></span>
                <span class="fe fe-arrow-right" *ngIf="dayFilter1Sort !== 'Establishments.name'"></span>
              </th>
              <th class="href" (click)="filtersDayFilter1('Commissions.visit_date')">
                <a class="text-muted">Date du rapport</a>
                <span class="fe fe-arrow-down" *ngIf="dayFilter1Sort === 'Commissions.visit_date' && dayFilter1Order === 'asc'"></span>
                <span class="fe fe-arrow-up" *ngIf="dayFilter1Sort === 'Commissions.visit_date' && dayFilter1Order === 'desc'"></span>
                <span class="fe fe-arrow-right" *ngIf="dayFilter1Sort !== 'Commissions.visit_date'"></span>
              </th>
              <th>Date de levée</th>
              <th>Nom de la prescription</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr class="dropdown" *ngFor="let prescription of dayFilter1">
              <td>{{ getEstablishmentNamePrescription(prescription) }}</td>
              <td>{{ prescription['commission'] && prescription['commission']['visit_date'] ? (prescription['commission']['visit_date'] | date : 'dd/MM/yyyy') : '' }}</td>
              <td>{{ prescription['date_changing_state'] ? (prescription['date_changing_state'] | date : 'dd/MM/yyyy') : '' }}</td>
              <td>{{ prescription['name'] }}</td>
              <td class="d-flex flex-row-reverse">
                <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i class="fe fe-more-vertical"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-right">
                  <a class="dropdown-item href" [routerLink]="[getUrlPrescription(prescription)]">
                    lien vers la prescription
                  </a>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
          <app-pagination (returnEvent)="dispatchPaginationDayFilter1($event)"
                          [count_elements]="dayFilter1Count"
                          [current_page]="dayFilter1Page"
                          [elements_length]="dayFilter1.length"
                          [limit]="dayFilter1Limit"
                          [total_pages]="dayFilter1Pages"
                          ngDefaultControl>
          </app-pagination>
        </div>
        <div class="table-responsive table-prescriptions" *ngIf="!dayFilter1.length && !loader">
          Aucun résultat
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="AverageDayFilter2" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
  <div class="modal-dialog prescription" role="document">
    <div class="modal-content modal-prescription">
      <div class="modal-prescription-header">
        <h4 class="modal-title">Prescriptions levées entre une semaine et un mois</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
        <div class="is-loading loader-modal-content p-5" *ngIf="loader"></div>
        <div class="table-responsive table-prescriptions" *ngIf="dayFilter2.length && !loader">
          <table class="table table-striped">
            <thead>
            <tr>
              <th class="href" (click)="filtersDayFilter2('Establishments.name')">
                <a class="text-muted">Établissement</a>
                <span class="fe fe-arrow-down" *ngIf="dayFilter2Sort === 'Establishments.name' && dayFilter2Order === 'asc'"></span>
                <span class="fe fe-arrow-up" *ngIf="dayFilter2Sort === 'Establishments.name' && dayFilter2Order === 'desc'"></span>
                <span class="fe fe-arrow-right" *ngIf="dayFilter2Sort !== 'Establishments.name'"></span>
              </th>
              <th class="href" (click)="filtersDayFilter2('Commissions.visit_date')">
                <a class="text-muted">Date du rapport</a>
                <span class="fe fe-arrow-down" *ngIf="dayFilter2Sort === 'Commissions.visit_date' && dayFilter2Order === 'asc'"></span>
                <span class="fe fe-arrow-up" *ngIf="dayFilter2Sort === 'Commissions.visit_date' && dayFilter2Order === 'desc'"></span>
                <span class="fe fe-arrow-right" *ngIf="dayFilter2Sort !== 'Commissions.visit_date'"></span>
              </th>
              <th>Date de levée</th>
              <th>Nom de la prescription</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr class="dropdown" *ngFor="let prescription of dayFilter2">
              <td>{{ getEstablishmentNamePrescription(prescription) }}</td>
              <td>{{ prescription['commission'] && prescription['commission']['visit_date'] ? (prescription['commission']['visit_date'] | date : 'dd/MM/yyyy') : '' }}</td>
              <td>{{ prescription['date_changing_state'] ? (prescription['date_changing_state'] | date : 'dd/MM/yyyy') : '' }}</td>
              <td>{{ prescription['name'] }}</td>
              <td class="d-flex flex-row-reverse">
                <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i class="fe fe-more-vertical"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-right">
                  <a class="dropdown-item href" [routerLink]="[getUrlPrescription(prescription)]">
                    lien vers la prescription
                  </a>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
          <app-pagination (returnEvent)="dispatchPaginationDayFilter2($event)"
                          [count_elements]="dayFilter2Count"
                          [current_page]="dayFilter2Page"
                          [elements_length]="dayFilter2.length"
                          [limit]="dayFilter2Limit"
                          [total_pages]="dayFilter2Pages"
                          ngDefaultControl>
          </app-pagination>
        </div>
        <div class="table-responsive table-prescriptions" *ngIf="!dayFilter2.length && !loader">
          Aucun résultat
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="AverageDayFilter3" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
  <div class="modal-dialog prescription" role="document">
    <div class="modal-content modal-prescription">
      <div class="modal-prescription-header">
        <h4 class="modal-title">Prescriptions levées entre un mois et trois mois</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
        <div class="is-loading loader-modal-content p-5" *ngIf="loader"></div>
        <div class="table-responsive table-prescriptions" *ngIf="dayFilter3.length && !loader">
          <table class="table table-striped">
            <thead>
            <tr>
              <th class="href" (click)="filtersDayFilter3('Establishments.name')">
                <a class="text-muted">Établissement</a>
                <span class="fe fe-arrow-down" *ngIf="dayFilter3Sort === 'Establishments.name' && dayFilter3Order === 'asc'"></span>
                <span class="fe fe-arrow-up" *ngIf="dayFilter3Sort === 'Establishments.name' && dayFilter3Order === 'desc'"></span>
                <span class="fe fe-arrow-right" *ngIf="dayFilter3Sort !== 'Establishments.name'"></span>
              </th>
              <th class="href" (click)="filtersDayFilter3('Commissions.visit_date')">
                <a class="text-muted">Date du rapport</a>
                <span class="fe fe-arrow-down" *ngIf="dayFilter3Sort === 'Commissions.visit_date' && dayFilter3Order === 'asc'"></span>
                <span class="fe fe-arrow-up" *ngIf="dayFilter3Sort === 'Commissions.visit_date' && dayFilter3Order === 'desc'"></span>
                <span class="fe fe-arrow-right" *ngIf="dayFilter3Sort !== 'Commissions.visit_date'"></span>
              </th>
              <th>Date de levée</th>
              <th>Nom de la prescription</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr class="dropdown" *ngFor="let prescription of dayFilter3">
              <td>{{ getEstablishmentNamePrescription(prescription) }}</td>
              <td>{{ prescription['commission'] && prescription['commission']['visit_date'] ? (prescription['commission']['visit_date'] | date : 'dd/MM/yyyy') : '' }}</td>
              <td>{{ prescription['date_changing_state'] ? (prescription['date_changing_state'] | date : 'dd/MM/yyyy') : '' }}</td>
              <td>{{ prescription['name'] }}</td>
              <td class="d-flex flex-row-reverse">
                <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i class="fe fe-more-vertical"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-right">
                  <a class="dropdown-item href" [routerLink]="[getUrlPrescription(prescription)]">
                    lien vers la prescription
                  </a>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
          <app-pagination (returnEvent)="dispatchPaginationDayFilter3($event)"
                          [count_elements]="dayFilter3Count"
                          [current_page]="dayFilter3Page"
                          [elements_length]="dayFilter3.length"
                          [limit]="dayFilter3Limit"
                          [total_pages]="dayFilter3Pages"
                          ngDefaultControl>
          </app-pagination>
        </div>
        <div class="table-responsive table-prescriptions" *ngIf="!dayFilter3.length && !loader">
          Aucun résultat
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="AverageDayFilter4" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
  <div class="modal-dialog prescription" role="document">
    <div class="modal-content modal-prescription">
      <div class="modal-prescription-header">
        <h4 class="modal-title">Prescriptions levées au dessus de trois mois</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
        <div class="is-loading loader-modal-content p-5" *ngIf="loader"></div>
        <div class="table-responsive table-prescriptions" *ngIf="dayFilter4.length && !loader">
          <table class="table table-striped">
            <thead>
            <tr>
              <th class="href" (click)="filtersDayFilter4('Establishments.name')">
                <a class="text-muted">Établissement</a>
                <span class="fe fe-arrow-down" *ngIf="dayFilter4Sort === 'Establishments.name' && dayFilter4Order === 'asc'"></span>
                <span class="fe fe-arrow-up" *ngIf="dayFilter4Sort === 'Establishments.name' && dayFilter4Order === 'desc'"></span>
                <span class="fe fe-arrow-right" *ngIf="dayFilter4Sort !== 'Establishments.name'"></span>
              </th>
              <th class="href" (click)="filtersDayFilter4('Commissions.visit_date')">
                <a class="text-muted">Date du rapport</a>
                <span class="fe fe-arrow-down" *ngIf="dayFilter4Sort === 'Commissions.visit_date' && dayFilter4Order === 'asc'"></span>
                <span class="fe fe-arrow-up" *ngIf="dayFilter4Sort === 'Commissions.visit_date' && dayFilter4Order === 'desc'"></span>
                <span class="fe fe-arrow-right" *ngIf="dayFilter4Sort !== 'Commissions.visit_date'"></span>
              </th>
              <th>Date de levée</th>
              <th>Nom de la prescription</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr class="dropdown" *ngFor="let prescription of dayFilter4">
              <td>{{ getEstablishmentNamePrescription(prescription) }}</td>
              <td>{{ prescription['commission'] && prescription['commission']['visit_date'] ? (prescription['commission']['visit_date'] | date : 'dd/MM/yyyy') : '' }}</td>
              <td>{{ prescription['date_changing_state'] ? (prescription['date_changing_state'] | date : 'dd/MM/yyyy') : '' }}</td>
              <td>{{ prescription['name'] }}</td>
              <td class="d-flex flex-row-reverse">
                <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i class="fe fe-more-vertical"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-right">
                  <a class="dropdown-item href" [routerLink]="[getUrlPrescription(prescription)]">
                    lien vers la prescription
                  </a>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
          <app-pagination (returnEvent)="dispatchPaginationDayFilter4($event)"
                          [count_elements]="dayFilter4Count"
                          [current_page]="dayFilter4Page"
                          [elements_length]="dayFilter4.length"
                          [limit]="dayFilter4Limit"
                          [total_pages]="dayFilter4Pages"
                          ngDefaultControl>
          </app-pagination>
        </div>
        <div class="table-responsive table-prescriptions" *ngIf="!dayFilter4.length && !loader">
          Aucun résultat
        </div>
      </div>
    </div>
  </div>
</div>
<!------------------------------ AVERAGE COSTS ------------------------------>
<div class="modal fade" id="AverageCostsFilter1" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
  <div class="modal-dialog prescription" role="document">
    <div class="modal-content modal-prescription">
      <div class="modal-prescription-header">
        <h4 class="modal-title">Coûts moyens inférieurs à 100 euros</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
        <div class="is-loading loader-modal-content p-5" *ngIf="loader"></div>
        <div class="table-responsive table-prescriptions" *ngIf="costFilter1.length && !loader">
          <table class="table table-striped">
            <thead>
            <tr>
              <th class="href" (click)="filtersCostFilter1('Establishments.name')">
                <a class="text-muted">Établissement</a>
                <span class="fe fe-arrow-down" *ngIf="costFilter1Sort === 'Establishments.name' && costFilter1Order === 'asc'"></span>
                <span class="fe fe-arrow-up" *ngIf="costFilter1Sort === 'Establishments.name' && costFilter1Order === 'desc'"></span>
                <span class="fe fe-arrow-right" *ngIf="costFilter1Sort !== 'Establishments.name'"></span>
              </th>
              <th class="href" (click)="filtersCostFilter1('Commissions.visit_date')">
                <a class="text-muted">Date du rapport</a>
                <span class="fe fe-arrow-down" *ngIf="costFilter1Sort === 'Commissions.visit_date' && costFilter1Order === 'asc'"></span>
                <span class="fe fe-arrow-up" *ngIf="costFilter1Sort === 'Commissions.visit_date' && costFilter1Order === 'desc'"></span>
                <span class="fe fe-arrow-right" *ngIf="costFilter1Sort !== 'Commissions.visit_date'"></span>
              </th>
              <th>Nom de la prescription</th>
              <th>Coût réel</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr class="dropdown" *ngFor="let prescription of costFilter1">
              <td>{{ getEstablishmentNamePrescription(prescription) }}</td>
              <td>{{ prescription['commission'] && prescription['commission']['visit_date'] ? (prescription['commission']['visit_date'] | date : 'dd/MM/yyyy') : '' }}</td>
              <td>{{ prescription['name'] }}</td>
              <td>{{ prescription['financial_real'] + '€'}}</td>
              <td class="d-flex flex-row-reverse">
                <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i class="fe fe-more-vertical"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-right">
                  <a class="dropdown-item href" [routerLink]="[getUrlPrescription(prescription)]">
                    lien vers la prescription
                  </a>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
          <app-pagination (returnEvent)="dispatchPaginationCostsFilter1($event)"
                          [count_elements]="costFilter1Count"
                          [current_page]="costFilter1Page"
                          [elements_length]="costFilter1.length"
                          [limit]="costFilter1Limit"
                          [total_pages]="costFilter1Pages"
                          ngDefaultControl>
          </app-pagination>
        </div>
        <div class="table-responsive table-prescriptions" *ngIf="!costFilter1.length && !loader">
          Aucun résultat
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="AverageCostsFilter2" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
  <div class="modal-dialog prescription" role="document">
    <div class="modal-content modal-prescription">
      <div class="modal-prescription-header">
        <h4 class="modal-title">Coûts moyens entre 100 et 1000 euros</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
        <div class="is-loading loader-modal-content p-5" *ngIf="loader"></div>
        <div class="table-responsive table-prescriptions" *ngIf="costFilter2.length && !loader">
          <table class="table table-striped">
            <thead>
            <tr>
              <th class="href" (click)="filtersCostFilter2('Establishments.name')">
                <a class="text-muted">Établissement</a>
                <span class="fe fe-arrow-down" *ngIf="costFilter2Sort === 'Establishments.name' && costFilter2Order === 'asc'"></span>
                <span class="fe fe-arrow-up" *ngIf="costFilter2Sort === 'Establishments.name' && costFilter2Order === 'desc'"></span>
                <span class="fe fe-arrow-right" *ngIf="costFilter2Sort !== 'Establishments.name'"></span>
              </th>
              <th class="href" (click)="filtersCostFilter2('Commissions.visit_date')">
                <a class="text-muted">Date du rapport</a>
                <span class="fe fe-arrow-down" *ngIf="costFilter2Sort === 'Commissions.visit_date' && costFilter2Order === 'asc'"></span>
                <span class="fe fe-arrow-up" *ngIf="costFilter2Sort === 'Commissions.visit_date' && costFilter2Order === 'desc'"></span>
                <span class="fe fe-arrow-right" *ngIf="costFilter2Sort !== 'Commissions.visit_date'"></span>
              </th>
              <th>Nom de la prescription</th>
              <th>Coût réel</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr class="dropdown" *ngFor="let prescription of costFilter2">
              <td>{{ getEstablishmentNamePrescription(prescription) }}</td>
              <td>{{ prescription['commission'] && prescription['commission']['visit_date'] ? (prescription['commission']['visit_date'] | date : 'dd/MM/yyyy') : '' }}</td>
              <td>{{ prescription['name'] }}</td>
              <td>{{ prescription['financial_real'] + '€'}}</td>
              <td class="d-flex flex-row-reverse">
                <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i class="fe fe-more-vertical"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-right">
                  <a class="dropdown-item href" [routerLink]="[getUrlPrescription(prescription)]">
                    lien vers la prescription
                  </a>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
          <app-pagination (returnEvent)="dispatchPaginationCostsFilter2($event)"
                          [count_elements]="costFilter2Count"
                          [current_page]="costFilter2Page"
                          [elements_length]="costFilter2.length"
                          [limit]="costFilter2Limit"
                          [total_pages]="costFilter2Pages"
                          ngDefaultControl>
          </app-pagination>
        </div>
        <div class="table-responsive table-prescriptions" *ngIf="!costFilter2.length && !loader">
          Aucun résultat
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="AverageCostsFilter3" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
  <div class="modal-dialog prescription" role="document">
    <div class="modal-content modal-prescription">
      <div class="modal-prescription-header">
        <h4 class="modal-title">Coûts moyens au dessus de 1000 euros</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
        <div class="is-loading loader-modal-content p-5" *ngIf="loader"></div>
        <div class="table-responsive table-prescriptions" *ngIf="costFilter3.length && !loader">
          <table class="table table-striped">
            <thead>
            <tr>
              <th class="href" (click)="filtersCostFilter3('Establishments.name')">
                <a class="text-muted">Établissement</a>
                <span class="fe fe-arrow-down" *ngIf="costFilter3Sort === 'Establishments.name' && costFilter3Order === 'asc'"></span>
                <span class="fe fe-arrow-up" *ngIf="costFilter3Sort === 'Establishments.name' && costFilter3Order === 'desc'"></span>
                <span class="fe fe-arrow-right" *ngIf="costFilter3Sort !== 'Establishments.name'"></span>
              </th>
              <th class="href" (click)="filtersCostFilter3('Commissions.visit_date')">
                <a class="text-muted">Date du rapport</a>
                <span class="fe fe-arrow-down" *ngIf="costFilter3Sort === 'Commissions.visit_date' && costFilter3Order === 'asc'"></span>
                <span class="fe fe-arrow-up" *ngIf="costFilter3Sort === 'Commissions.visit_date' && costFilter3Order === 'desc'"></span>
                <span class="fe fe-arrow-right" *ngIf="costFilter3Sort !== 'Commissions.visit_date'"></span>
              </th>
              <th>Nom de la prescription</th>
              <th>Coût réel</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr class="dropdown" *ngFor="let prescription of costFilter3">
              <td>{{ getEstablishmentNamePrescription(prescription) }}</td>
              <td>{{ prescription['commission'] && prescription['commission']['visit_date'] ? (prescription['commission']['visit_date'] | date : 'dd/MM/yyyy') : '' }}</td>
              <td>{{ prescription['name'] }}</td>
              <td>{{ prescription['financial_real'] + '€'}}</td>
              <td class="d-flex flex-row-reverse">
                <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i class="fe fe-more-vertical"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-right">
                  <a class="dropdown-item href" [routerLink]="[getUrlPrescription(prescription)]">
                    lien vers la prescription
                  </a>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
          <app-pagination (returnEvent)="dispatchPaginationCostsFilter3($event)"
                          [count_elements]="costFilter3Count"
                          [current_page]="costFilter3Page"
                          [elements_length]="costFilter3.length"
                          [limit]="costFilter3Limit"
                          [total_pages]="costFilter3Pages"
                          ngDefaultControl>
          </app-pagination>
        </div>
        <div class="table-responsive table-prescriptions" *ngIf="!costFilter3.length && !loader">
          Aucun résultat
        </div>
      </div>
    </div>
  </div>
</div>

