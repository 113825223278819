<div class="main-content">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col">
        <div class="header mb-0">
          <div class="header-body no-border">
            <div class="row align-items-center">
              <div class="col">
                <h1 class="header-title">Risques</h1>

                <small class="form-text text-muted">Liste des risques</small>

                <app-breadcrumbs [currentPage]="pageName" [names]="previousPageName" [routes]="previousPageRoute"></app-breadcrumbs>
              </div>

              <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0" *ngIf="canCreate">
                <a class="btn btn-primary d-block d-md-inline-block" [routerLink]="[currentRoute + 'create']">
                  Ajouter un risque
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="is-loading loader-content" *ngIf="loader"></div>

        <div class="tab-content" *ngIf="!loader && risks.length">

          <div class="d-flex flex-row align-items-center col-12" *ngIf="lastUpdateDate">
            <div class="form-group w-100">
              <label for="dateTo">Date de dernière mise à jour</label>
              <input type="date" [(ngModel)]="lastUpdateDate" class="form-control" id="dateTo" placeholder="Date de dernière mise à jour" data-toggle="flatpickr">
            </div>
          </div>

          <div role="tablist" class="accordion_2 col-12" [attr.id]="'risk' + index" *ngFor="let item of risks; let index = index; let first = first">
            <div class="card">
              <div class="card-header" role="tab">
                <div class="row d-flex">
                  <div class="col-md-6 d-flex align-items-center">
                    <i data-toggle="collapse" [attr.href]="'#RiskList' + index" aria-expanded="true" class="fe chevron mr-2 href collapsed"></i>
                    <h2 class="mb-0 my-sm-3 mx-sm-auto mx-lg-0">Unité de travail : {{ item.unity.name }}</h2>
                  </div>
                  <div class="col-md-6">
                    <app-searchbar [(ngModel)]="search" (keyup)="searchData(item)" [searchbar_id]="'search_bar_' + item.unity.id" [searchbar_name]="'search_bar_' + item.unity.id" ngDefaultControl></app-searchbar>
                  </div>
                </div>
              </div>
              <div class="card-body collapse" [attr.id]="'RiskList' + index" role="tabpanel" [attr.data-parent]="'#risk' + index">
                <div class="table-responsive">
                  <table class="table table-striped">
                    <thead>
                    <tr>
                      <th class="href" (click)="filter(item, 'Risks.family')">
                        <a class="text-muted">Famille de risque</a>
                        <span class="fe fe-arrow-down" *ngIf="item.sort === 'Risks.family' && item.order === 'asc'"></span>
                        <span class="fe fe-arrow-up" *ngIf="item.sort === 'Risks.family' && item.order === 'desc'"></span>
                        <span class="fe fe-arrow-right" *ngIf="item.sort !== 'Risks.family'"></span>
                      </th>
                      <th class="href" (click)="filter(item, 'Risks.description')">
                        <a class="text-muted">Description du risque</a>
                        <span class="fe fe-arrow-down" *ngIf="item.sort === 'Risks.description' && item.order === 'asc'"></span>
                        <span class="fe fe-arrow-up" *ngIf="item.sort === 'Risks.description' && item.order === 'desc'"></span>
                        <span class="fe fe-arrow-right" *ngIf="item.sort !== 'Risks.description'"></span>
                      </th>
                      <th class="href" (click)="filter(item, 'Risks.frequency_id')">
                        <a class="text-muted">Fréquence</a>
                        <span class="fe fe-arrow-down" *ngIf="item.sort === 'Risks.frequency_id' && item.order === 'asc'"></span>
                        <span class="fe fe-arrow-up" *ngIf="item.sort === 'Risks.frequency_id' && item.order === 'desc'"></span>
                        <span class="fe fe-arrow-right" *ngIf="item.sort !== 'Risks.frequency_id'"></span>
                      </th>
                      <th class="href" (click)="filter(item, 'Risks.gravity_id')">
                        <a class="text-muted">Gravité</a>
                        <span class="fe fe-arrow-down" *ngIf="item.sort === 'Risks.gravity_id' && item.order === 'asc'"></span>
                        <span class="fe fe-arrow-up" *ngIf="item.sort === 'Risks.gravity_id' && item.order === 'desc'"></span>
                        <span class="fe fe-arrow-right" *ngIf="item.sort !== 'Risks.gravity_id'"></span>
                      </th>
                      <th class="href" (click)="filter(item, 'Risks.master_id')">
                        <a class="text-muted">Maitrise</a>
                        <span class="fe fe-arrow-down" *ngIf="item.sort === 'Risks.master_id' && item.order === 'asc'"></span>
                        <span class="fe fe-arrow-up" *ngIf="item.sort === 'Risks.master_id' && item.order === 'desc'"></span>
                        <span class="fe fe-arrow-right" *ngIf="item.sort !== 'Risks.master_id'"></span>
                      </th>
                      <th class="href" (click)="filter(item, 'Risks.critic')">
                        <a class="text-muted">Criticité</a>
                        <span class="fe fe-arrow-down" *ngIf="item.sort === 'Risks.critic' && item.order === 'asc'"></span>
                        <span class="fe fe-arrow-up" *ngIf="item.sort === 'Risks.critic' && item.order === 'desc'"></span>
                        <span class="fe fe-arrow-right" *ngIf="item.sort !== 'Risks.critic'"></span>
                      </th>
                      <th>Date de mise à jour</th>
                      <th>Moyens de protection et de prévention existants</th>
                      <th>Actions correctives à réaliser</th>
                      <th>Documents</th>
                      <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class="dropdown" *ngFor="let risk of item.risks">
                      <td>{{ risk.family }}</td>
                      <td>{{ risk.description }}</td>
                      <td>{{ JSON.parse(risk.frequency)['title'] }}</td>
                      <td>{{ JSON.parse(risk.gravity)['title'] }}</td>
                      <td>{{ JSON.parse(risk.master)['title'] }}</td>
                      <td><span class="label" [ngClass]="risk.label">{{ risk.critic }}</span></td>
                      <td>{{ risk['date_update'] | date : 'dd/MM/yyyy' }}</td>
                      <td>{{ risk.current_protection }}</td>
                      <td>{{ risk.todo_protection }}</td>
                      <td>
                        <a class="btn btn-sm btn-white" [routerLink]="[currentRoute + risk.id + '/files']">
                          {{ risk.count_documents + ' document(s)'}}
                        </a>
                      </td>
                      <td class="d-flex flex-row-reverse">
                        <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="fe fe-more-vertical"></i>
                        </a>

                        <div class="dropdown-menu dropdown-menu-right">
                          <a class="dropdown-item href" [routerLink]="[currentRoute + risk.id]">
                            {{ canUpdate ? 'Éditer le risque' : 'Consulter le risque' }}
                          </a>

                          <a class="dropdown-item href" *ngIf="canDelete" (click)="modalConfirmData.open(risk.id)">
                            Supprimer le risque
                          </a>

                          <a class="dropdown-item href" [routerLink]="[currentRoute + risk.id + '/logs']">
                            Historique des actions
                          </a>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>

                  <div class="d-flex align-items-center loader-content" *ngIf="!item.loader && !item.risks.length">
                    <p class="mb-0 mx-auto">Aucun résultat</p>
                  </div>

                  <div class="is-loading loader-content" *ngIf="item.loader"></div>

                  <app-pagination (returnEventAllRisks)="dispatchPagination($event)"
                                  [allRisksCurrentItem]="item"
                                  [allRisks]="true"
                                  [count_elements]="item.count"
                                  [current_page]="item.page"
                                  [elements_length]="item.risks.length"
                                  [limit]="item.limit"
                                  [total_pages]="item.pages"
                                  ngDefaultControl>
                  </app-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="loader-content" *ngIf="!loader && !risks.length">
          <p>Aucun résultat</p>
        </div>
      </div>
    </div>
  </div>
</div>

<app-confirm-modal [type]="'delete_data'" #confirmData>{{ bindModalConfirmData(confirmData) }}</app-confirm-modal>

<router-outlet></router-outlet>
