import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AllSanitaryAnalyzesModule } from './all-sanitary-analyzes/all-sanitary-analyzes.module';
import { UpdateSanitaryAnalyzeModule } from './update-sanitary-analyze/update-sanitary-analyze.module';
import { SanitaryAnalyzesRequestService } from '../../../services/sanitary-analyzes.request.service';
import { UploadSanitaryAnalyzeFileModule } from './upload-sanitary-analyze-file/upload-sanitary-analyze-file.module';

@NgModule({
  imports: [
    CommonModule,
    AllSanitaryAnalyzesModule,
    UpdateSanitaryAnalyzeModule,
    UploadSanitaryAnalyzeFileModule
  ],
  providers: [
      SanitaryAnalyzesRequestService
  ]
})
export class SanitaryAnalyzesModule {}
