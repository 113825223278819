import { Routes } from '@angular/router';

import { AllUnitiesComponent } from './risks-unities/all-unities/all-unities.component';
import { UpdateUnityComponent } from './risks-unities/update-unity/update-unity.component';

import { AllUsersComponent } from './risks-users/all-users/all-users.component';
import { UpdateUserComponent } from './risks-users/update-user/update-user.component';

import { AllRisksComponent } from './risks/all-risks/all-risks.component';
import { UpdateRiskComponent } from './risks/update-risk/update-risk.component';

import { AllCostsComponent } from './risks-costs/all-costs/all-costs.component';
import { UpdateCostComponent } from './risks-costs/update-cost/update-cost.component';

import { AllDamagesComponent } from './risks-damages/all-damages/all-damages.component';
import { UpdateDamageComponent } from './risks-damages/update-damage/update-damage.component';

import { AllRiskFilesComponent } from './risks-files/all-risk-files/all-risk-files.component';
import { UploadRiskFileComponent } from './risks-files/upload-risk-file/upload-risk-file.component';

import { AllActionsComponent } from './risks-actions/all-actions/all-actions.component';
import { AllFamiliesComponent } from './risks-family/all-families/all-families.component';
import { UpdateFamilyComponent } from './risks-family/update-family/update-family.component';

import { ShowUnityComponent } from './risks-unities/show-unity/show-unity.component';
import { ShowUserComponent } from './risks-users/show-user/show-user.component';

import { GlobalUnitiesComponent } from './risks-unities/global_unities/global-unities.component';
import { GlobalUsersComponent } from './risks-users/global_users/global-users.component';

import { ModalLogsComponent } from '../logs/modal-logs/modal-logs.component';

export const risksRoutes: Routes = [
  {
    path: 'dashboard-registry/:registry_id/risks-unities/global-show',
    component: GlobalUnitiesComponent
  },
  {
    path: 'dashboard-registry/:registry_id/risks-users/global-show',
    component: GlobalUsersComponent
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/risks-unities/show',
    component: ShowUnityComponent
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/risks-unities',
    component: AllUnitiesComponent,
    children: [
      {
        path: 'create',
        component: UpdateUnityComponent
      },
      {
        path: ':unity_id',
        component: UpdateUnityComponent
      },
      {
        path: ':unity_id/logs',
        component: ModalLogsComponent
      },
    ]
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/risks-users/show',
    component: ShowUserComponent
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/risks-users',
    component: AllUsersComponent,
    children: [
      {
        path: 'create',
        component: UpdateUserComponent
      },
      {
        path: ':user_id',
        component: UpdateUserComponent
      },
      {
        path: ':user_id/logs',
        component: ModalLogsComponent
      },
    ]
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/risks/:risk_id/files',
    component: AllRiskFilesComponent,
    children: [
      {
        path: 'create',
        component: UploadRiskFileComponent
      },
      {
        path: ':file_id',
        component: UploadRiskFileComponent
      }
    ]
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/risks',
    component: AllRisksComponent,
    children: [
      {
        path: 'create',
        component: UpdateRiskComponent
      },
      {
        path: ':risk_id',
        component: UpdateRiskComponent
      },
      {
        path: ':risk_id/logs',
        component: ModalLogsComponent
      }
    ]
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/correctives-actions',
    component: AllActionsComponent,
  },
  {
    path: 'dashboard-registry/:registry_id/risks-unities',
    component: AllUnitiesComponent,
    children: [
      {
        path: 'create',
        component: UpdateUnityComponent
      },
      {
        path: ':unity_id',
        component: UpdateUnityComponent
      },
      {
        path: ':unity_id/logs',
        component: ModalLogsComponent
      }
    ]
  },
  {
    path: 'dashboard-registry/:registry_id/risks-users',
    component: AllUsersComponent,
    children: [
      {
        path: 'create',
        component: UpdateUserComponent
      },
      {
        path: ':user_id',
        component: UpdateUserComponent
      },
      {
        path: ':user_id/logs',
        component: ModalLogsComponent
      }
    ]
  },
  {
    path: 'risk-costs',
    component: AllCostsComponent,
    children: [
      {
        path: ':cost_id',
        component: UpdateCostComponent
      }
    ]
  },
  {
    path: 'risk-damages',
    component: AllDamagesComponent,
    children: [
      {
        path: 'create',
        component: UpdateDamageComponent
      },
      {
        path: ':damage_id',
        component: UpdateDamageComponent
      }
    ]
  },
  {
    path: 'risk-families',
    component: AllFamiliesComponent,
    children: [
      {
        path: 'create',
        component: UpdateFamilyComponent
      },
      {
        path: ':family_id',
        component: UpdateFamilyComponent
      }
    ]
  },
];
