<app-modal-right (eventOnClose)="closeModal()" #modalPattern>{{ bindModal(modalPattern) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">Modèle d'attribution</h6>

        <h1 class="header-title">Points de contrôle</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <!-- CARD RECAP CHECKPOINT REFERENCE -->
    <div class="card bg-light border" *ngIf="!loaderContent">
      <div class="card-body">
        <p class="small text-muted mb-2">
          Si des points de contrôle sont dupliquées sur l'établissement de référence,
          le prestataire assigné sur le point de contrôle d'origine est appliqué sur tous les points de contrôle avec la même référence des autres établissement.<br>
          <br>
          Au cas par cas, vous pourrez modifier ce paramétrage.
        </p>
      </div>
    </div>

    <form [formGroup]="patternForm" *ngIf="!loaderContent">
      <div class="form-group">
        <label for="validationEnterprise">Établissement à utiliser</label>
        <select class="form-control" data-toggle="select" formControlName="establishment_id" id="validationEnterprise"
                [ngClass]="{ 'is-invalid': establishment_id.invalid && (establishment_id.dirty || establishment_id.touched) }">
          <option [value]="null">Sélectionnez un établissement</option>
          <ng-container *ngFor="let establishment of establishments">
            <option [value]="establishment.id">{{ establishment.name }}</option>
          </ng-container>
        </select>
      </div>

      <div class="form-group" *ngIf="!loaderContent">
        <label for="establismentSelected">Autre(s) établissement(s) cible(s)</label>
        <ng-select [items]="establishments"
                   [multiple]="true"
                   bindLabel="name"
                   bindValue="id"
                   groupBy="group"
                   notFoundText="Aucun résultat"
                   [selectableGroup]="true"
                   [selectableGroupAsModel]="false"
                   [closeOnSelect]="false"
                   placeholder="Sélectionnez un établissement"
                   [(ngModel)]="establishmentsSelected" [ngModelOptions]="{ standalone: true }">
          <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
            <input type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }"/> Sélectionnez tout
          </ng-template>
          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
            <input id="establismentSelected" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }" /> {{ item.name }}
          </ng-template>
        </ng-select>
      </div>
    </form>
  </div>

  <div modal-footer class="w-100">
    <button class="btn btn-primary w-100" [ngClass]="{ 'is-loading is-loading-sm': loader }" (click)="sendPattern()" [disabled]="loader || patternForm.invalid || !establishmentsSelected.length">
      Appliquer
    </button>
  </div>
</app-modal-right>
