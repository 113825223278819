import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UpdateSanitaryLegionellaFrameModule } from './update-sanitary-legionella-frame/update-sanitary-legionella-frame.module';
import { AllSanitaryLegionellaFramesModule } from './all-sanitary-legionella-frames/all-sanitary-legionella-frames.module';

import { SanitaryLegionellaFramesRequestService } from '../../../services/sanitary-legionella-frames.request.service';

@NgModule({
  imports: [
    CommonModule,
    AllSanitaryLegionellaFramesModule,
    UpdateSanitaryLegionellaFrameModule,
  ],
  providers: [
      SanitaryLegionellaFramesRequestService
  ]
})
export class SanitaryLegionellaFramesModule {}
