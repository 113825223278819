<app-modal-right (eventOnClose)="closeModal()" #establishment>{{ bindModal(establishment) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">Edition</h6>

        <h1 class="header-title">Attribution de masse des registres</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <form class="mb-4" [formGroup]="establishmentForm" [hidden]="loaderContent">

      <div class="tab-content">
        <div class="tab-pane fade show active" id="firstPart" role="tabpanel" aria-labelledby="firstPart">

          <div class="form-group" *ngIf="!loaderContent && registries">
            <label for="establismentSelected">Registres</label>
            <ng-select [items]="registries"
                       [multiple]="true"
                       bindLabel="name"
                       bindValue="id"
                       groupBy="group"
                       notFoundText="Aucun résultat"
                       [selectableGroup]="true"
                       [selectableGroupAsModel]="false"
                       [closeOnSelect]="false"
                       placeholder="Sélectionnez un/des registres"
                       [(ngModel)]="registriesSelected" [ngModelOptions]="{ standalone: true }">
              <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                <input type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }"/> Sélectionnez tout
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="registriesSelected" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }"/> {{ item.name }}
              </ng-template>
            </ng-select>
          </div>

          <div class="form-group" *ngIf="!loaderContent && establishments">
            <label for="establismentSelected">Etablissements</label>
            <ng-select [items]="establishments"
                       [multiple]="true"
                       bindLabel="name"
                       bindValue="id"
                       groupBy="group"
                       notFoundText="Aucun résultat"
                       [selectableGroup]="true"
                       [selectableGroupAsModel]="false"
                       [closeOnSelect]="false"
                       placeholder="Sélectionnez un/des établissements"
                       [(ngModel)]="establishmentsSelected" [ngModelOptions]="{ standalone: true }">
              <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                <input type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }"/> Sélectionnez tout
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="establismentSelected" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }"/> {{ item.name }}
              </ng-template>
            </ng-select>
          </div>

          <div class="form-group" *ngIf="!loaderContent && operation">
            <label for="establismentSelected">Opération à effectuer</label>
            <ng-select [items]="operation"
                       [multiple]="false"
                       bindLabel="name"
                       bindValue="id"
                       groupBy="group"
                       notFoundText="Aucun résultat"
                       [closeOnSelect]="true"
                       placeholder="Sélectionnez une opération"
                       [(ngModel)]="operationSelected" [ngModelOptions]="{ standalone: true }">
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                {{ item.name }}
              </ng-template>
            </ng-select>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div modal-footer class="w-100 d-flex justify-content-between">
    <button class="btn btn-primary w-100" (click)="updateEstablishment()" [ngClass]="{ 'is-loading is-loading-sm': loader }"
            [disabled]="loader || !registriesSelected.length || !establishmentsSelected.length || !operationSelected">
      Mettre à jour
    </button>
  </div>
</app-modal-right>
