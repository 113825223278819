import { Component, ElementRef, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CustomNotifierService } from "../../../services/custom.notifier.service";
import { NotifierService } from "angular-notifier";
import { StatisticsRequestService } from "../../../services/statistics.request.service";
import { Subject, Subscription } from "rxjs";
import { AuthService } from "../../../core/auth/auth.service";

import * as moment from "moment";
import { TrainingsRequestService } from "src/app/services/trainings.request.service";
import { EmployeesRequestService } from "src/app/services/employees.request.service";
import { EstablishmentsRequestService } from "src/app/services/establishments.request.service";
import { RegistriesRequestService } from "src/app/services/registries.request.service";

declare function UserCharts(): any;
declare var $: any;

@Component({
  selector: "app-dashboard-trainings",
  templateUrl: "./dashboard-trainings.component.html",
  styleUrls: ["./dashboard-trainings.component.scss"],
})
export class DashboardTrainingsComponent implements OnInit, OnDestroy {
  changeEnterprise: Subscription;
  errors = {};
  establishments = [];
  registries = [];
  trainings = [];
  expiredTrainings = [];
  employeesTrain = [];
  employeesMustTrain = [];
  selectedOriginTrainings = null;
  userChart = UserCharts();
  installations;
  checkpoints;
  modal;

  selectedEstablishments = [];
  selectedRegistry = null;

  loaderEmployeesByTrainings = false;
  loaderEmployeesByEstablishments = false;

  canCreate = false;
  canUpdate = false;
  canDelete = false;

  /// LISTE FORMATIONS
  countTraininigs = 0;
  pagesTraininigs = 1;

  searchTraininigs = "";
  sortTraininigs = "Trainings.id";
  orderTraininigs = "asc";
  limitTraininigs = 12;
  pageTraininigs = 1;

  /// LISTE FORMATIONS EXPIREES
  countExpiredTraininigs = 0;
  pagesExpiredTraininigs = 1;

  searchExpiredTraininigs = "";
  sortExpiredTraininigs = "Trainings.id";
  orderExpiredTraininigs = "asc";
  limitExpiredTraininigs = 12;
  pageExpiredTraininigs = 1;

  /// LISTE EMPLOYEES FORMEES
  countEmployeesTrain = 0;
  pagesEmployeesTrain = 1;

  searchEmployeesTrain = "";
  sortEmployeesTrain = "DateTrainingEmployees.id";
  orderEmployeesTrain = "asc";
  limitEmployeesTrain = 12;
  pageEmployeesTrain = 1;

  /// LISTE EMPLOYEES NON FORMEES
  countEmployeesMustTrain = 0;
  pagesEmployeesMustTrain = 1;

  searchEmployeesMustTrain = "";
  sortEmployeesMustTrain = "DateTrainingEmployees.id";
  orderEmployeesMustTrain = "asc";
  limitEmployeesMustTrain = 12;
  pageEmployeesMustTrain = 1;

  /// ESTABLISMENTS AND REGISTRIES
  search = "";
  order = "asc";
  limit = 12;
  page = 1;

  periods = [
    { value: "custom", name: "Période personnalisée" },
    { value: "days", name: "7 derniers jours" },
    { value: "month", name: "30 derniers jours" },
    { value: "months", name: "3 derniers mois" },
    { value: "year", name: "Année en cours" },
    { value: "lastyear", name: "Année dernière" },
    { value: "lastyears", name: "Il y a deux ans" },
  ];
  period = "custom";
  dateFrom;
  dateTo;
  groupBy = "month";

  total = 0;
  totalLate = 0;

  requestInProgress = new Subject();
  requestCount = 0;
  loader = false;

  nbTrainings = 0;
  nbTrainingsLate = 0;
  nbEmployeesTrain = 0;
  nbEmployeesMustTrain = 0;

  constructor(
    private router: Router,
    private authService: AuthService,
    private notifier: NotifierService,
    private statisticsService: StatisticsRequestService,
    private _rootNode: ElementRef,
    private trainingsService: TrainingsRequestService,
    private employeesService: EmployeesRequestService,
    private establishmentsService: EstablishmentsRequestService,
    private registriesService: RegistriesRequestService
  ) {}

  ngOnInit() {
    this.getStatisticsNumber();
    this.getRegistry();
    this.getEstablishment();
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loader = this.requestCount > 0;
    });
    this.changeEnterprise = this.authService.getEnterprise().subscribe(() => {
      this.initUserCharts();
    });
    if (this.authService.loaded) {
      this.initUserCharts();
    }
  }

  ngOnDestroy() {}

  initUserCharts() {
    this.loaderEmployeesByTrainings = true;
    this.loaderEmployeesByEstablishments = true;
    this.trainingsService
      .getNbEmployeesByTrainings(
        this.selectedEstablishments,
        this.selectedRegistry
      )
      .subscribe((response) => {
        if (response["success"]) {
          this.userChart.createNbEmployeesByTrainingsOrEstablishments(
            "#NbEmployeesByTrainings",
            response,
            this
          );
        } else {
          CustomNotifierService.getErrors(response["errors"], this.notifier);
        }
      });
    this.loaderEmployeesByTrainings = false;
    this.trainingsService
      .getNbEmployeesByEstablishments()
      .subscribe((response) => {
        if (response["success"]) {
          this.userChart.createNbEmployeesByTrainingsOrEstablishments(
            "#NbEmployeesByEstablishmentChart",
            response,
            this
          );
        } else {
          CustomNotifierService.getErrors(response["errors"], this.notifier);
        }
      });

    this.loaderEmployeesByEstablishments = false;
  }

  updateSelectedEstablishments() {
    this.selectedOriginTrainings = null;
    console.log(this.selectedEstablishments);
    this.getStatisticsNumber();
    this.initUserCharts();
  }
  updateSelectedRegistry() {
    this.selectedOriginTrainings = null;
    console.log(this.selectedRegistry);
    this.getStatisticsNumber();
    this.initUserCharts();
  }

  getEstablishment() {
    this.establishmentsService
      .getMyEstablishments(
        this.search,
        "Establishments.name",
        this.order,
        this.limit,
        this.page
      )
      .subscribe((response) => {
        if (response["success"]) {
          this.establishments = response["establishments"];
        } else if (response["errors"]) {
          this.errors = response["errors"];
        }
      });
  }

  getRegistry() {
    this.registriesService
      .getMyRegistries(
        this.search,
        "Registries.name",
        this.order,
        this.limit,
        this.page
      )
      .subscribe((response) => {
        if (response["success"]) {
          this.registries = response["registries"];
        } else if (response["errors"]) {
          this.errors = response["errors"];
        }
      });
  }

  open(content) {
    if ("list_trainings" === content) {
      this.getListTrainings(content);
    } else if ("list_expired_trainings" === content) {
      this.getListExpiredTrainings(content);
    } else if ("list_employees_train" === content) {
      this.getEmployeesTrain(content);
    } else if ("list_employees_must_train" === content) {
      this.getEmployeesMustTrain(content);
    }
  }

  getListTrainings(content) {
    let request;
    this.errors = {};
    this.loader = true;

    request = this.trainingsService.getAllTrainings(
      this.searchTraininigs,
      this.sortTraininigs,
      this.orderTraininigs,
      this.limitTraininigs,
      this.pageTraininigs,
      this.selectedEstablishments,
      this.selectedRegistry
    );

    request.subscribe((response) => {
      if (response["success"]) {
        this.canUpdate = response["canUpdate"];
        this.canCreate = response["canCreate"];
        this.canDelete = response["canDelete"];

        this.trainings = response["trainings"];

        this.pagesTraininigs = response["pages"];
        this.countTraininigs = response["count"];

        this.modal = $(this._rootNode.nativeElement).find("div#" + content);
        this.modal.modal("show");
      } else if (response["errors"]) {
        CustomNotifierService.getErrors(response["errors"], this.notifier);
      }
      this.loader = false;
    });
  }

  getListExpiredTrainings(content) {
    let request;
    this.errors = {};
    this.loader = true;

    request = this.trainingsService.getAllExpiredTrainings(
      this.searchExpiredTraininigs,
      this.sortExpiredTraininigs,
      this.orderExpiredTraininigs,
      this.limitExpiredTraininigs,
      this.pageExpiredTraininigs,
      this.selectedEstablishments,
      this.selectedRegistry
    );

    request.subscribe((response) => {
      if (response["success"]) {
        this.canUpdate = response["canUpdate"];
        this.canCreate = response["canCreate"];
        this.canDelete = response["canDelete"];

        this.expiredTrainings = response["trainings"];

        this.pagesExpiredTraininigs = response["pages"];
        this.countExpiredTraininigs = response["count"];

        this.modal = $(this._rootNode.nativeElement).find("div#" + content);
        this.modal.modal("show");
      } else if (response["errors"]) {
        CustomNotifierService.getErrors(response["errors"], this.notifier);
      }
      this.loader = false;
    });
  }

  getEmployeesTrain(content) {
    let request;
    this.errors = {};
    this.loader = true;

    request = this.employeesService.getAllEmployeesTrainOrNot(
      this.searchEmployeesTrain,
      this.sortEmployeesTrain,
      this.orderEmployeesTrain,
      this.limitEmployeesTrain,
      this.pageEmployeesTrain,
      1,
      this.selectedEstablishments,
      this.selectedRegistry
    );

    request.subscribe((response) => {
      if (response["success"]) {
        this.canUpdate = response["canUpdate"];
        this.canCreate = response["canCreate"];
        this.canDelete = response["canDelete"];

        this.employeesTrain = response["employees"];

        this.pagesEmployeesTrain = response["pages"];
        this.countEmployeesTrain = response["count"];

        this.modal = $(this._rootNode.nativeElement).find("div#" + content);
        this.modal.modal("show");
      } else if (response["errors"]) {
        CustomNotifierService.getErrors(response["errors"], this.notifier);
      }
      this.loader = false;
    });
  }

  getEmployeesMustTrain(content) {
    let request;
    this.errors = {};
    this.loader = true;

    request = this.employeesService.getAllEmployeesTrainOrNot(
      this.searchEmployeesMustTrain,
      this.sortEmployeesMustTrain,
      this.orderEmployeesMustTrain,
      this.limitEmployeesMustTrain,
      this.pageEmployeesMustTrain,
      0,
      this.selectedEstablishments,
      this.selectedRegistry
    );
    this.modal = $(this._rootNode.nativeElement).find("div#" + content);
    this.modal.modal("show");

    request.subscribe((response) => {
      if (response["success"]) {
        this.canUpdate = response["canUpdate"];
        this.canCreate = response["canCreate"];
        this.canDelete = response["canDelete"];

        this.employeesMustTrain = response["employees"];

        this.pagesEmployeesMustTrain = response["pages"];
        this.countEmployeesMustTrain = response["count"];

        this.modal = $(this._rootNode.nativeElement).find("div#" + content);
        this.modal.modal("show");
      } else if (response["errors"]) {
        CustomNotifierService.getErrors(response["errors"], this.notifier);
      }
      this.loader = false;
    });
  }

  public getStatisticsNumber() {
    let request;
    this.errors = {};

    request = this.statisticsService.getStatisticsTrainings(
      this.selectedEstablishments,
      this.selectedRegistry
    );

    request.subscribe((response) => {
      if (response["success"]) {
        this.nbTrainings = response["nbTrainings"];
        this.nbTrainingsLate = response["nbTrainingsLate"];
        this.nbEmployeesTrain = response["nbEmployeesTrain"];
        this.nbEmployeesMustTrain = response["nbEmployeesMustTrain"];
      } else {
        CustomNotifierService.getErrors(response["errors"], this.notifier);
      }
    });
  }

  dispatchPaginationTrainings(data) {
    if (data.action === "total_elements") {
      this.refreshPageTrainings(data.value);
    } else if (data.action === "change_page") {
      this.changePageTrainings(data.value);
    }
  }

  refreshPageTrainings(limit) {
    this.limitTraininigs = limit;
    this.pageTraininigs = 1;
    this.refreshDataTrainings();
  }

  changePageTrainings(page) {
    if (page >= 1 && page <= this.pagesTraininigs) {
      this.pageTraininigs = page;
      this.getListTrainings("list_trainings");
    }
  }

  refreshDataTrainings(
    sort = this.sortTraininigs,
    order = this.orderTraininigs,
    page = this.pageTraininigs
  ) {
    this.sortTraininigs = sort;
    this.orderTraininigs = order;
    this.pageTraininigs = page;
    this.getListTrainings("list_trainings");
  }

  filterTrainings(property) {
    if (this.sortTraininigs === property) {
      if (this.orderTraininigs === "asc") {
        this.orderTraininigs = "desc";
      } else {
        this.orderTraininigs = "asc";
      }
    } else {
      this.sortTraininigs = property;
      this.orderTraininigs = "asc";
    }

    this.getListTrainings("list_trainings");
  }

  dispatchPaginationExpiredTrainings(data) {
    if (data.action === "total_elements") {
      this.refreshPageExpiredTrainings(data.value);
    } else if (data.action === "change_page") {
      this.changePageExpiredTrainings(data.value);
    }
  }

  refreshPageExpiredTrainings(limit) {
    this.limitExpiredTraininigs = limit;
    this.pageExpiredTraininigs = 1;
    this.refreshDataExpiredTrainings();
  }

  changePageExpiredTrainings(page) {
    if (page >= 1 && page <= this.pagesExpiredTraininigs) {
      this.pageExpiredTraininigs = page;
      this.getListExpiredTrainings("list_expired_trainings");
    }
  }

  refreshDataExpiredTrainings(
    sort = this.sortExpiredTraininigs,
    order = this.orderExpiredTraininigs,
    page = this.pageExpiredTraininigs
  ) {
    this.sortExpiredTraininigs = sort;
    this.orderExpiredTraininigs = order;
    this.pageExpiredTraininigs = page;
    this.getListExpiredTrainings("list_expired_trainings");
  }

  filterExpiredTrainings(property) {
    if (this.sortExpiredTraininigs === property) {
      if (this.orderExpiredTraininigs === "asc") {
        this.orderExpiredTraininigs = "desc";
      } else {
        this.orderExpiredTraininigs = "asc";
      }
    } else {
      this.sortExpiredTraininigs = property;
      this.orderExpiredTraininigs = "asc";
    }

    this.getListExpiredTrainings("list_expired_trainings");
  }

  dispatchPaginationEmployeesTrain(data) {
    if (data.action === "total_elements") {
      this.refreshPageEmployeesTrain(data.value);
    } else if (data.action === "change_page") {
      this.changePageEmployeesTrain(data.value);
    }
  }

  refreshPageEmployeesTrain(limit) {
    this.limitEmployeesTrain = limit;
    this.pageEmployeesTrain = 1;
    this.refreshDataEmployeesTrain();
  }

  changePageEmployeesTrain(page) {
    if (page >= 1 && page <= this.pagesEmployeesTrain) {
      this.pageEmployeesTrain = page;
      this.getEmployeesTrain("list_employees_train");
    }
  }

  refreshDataEmployeesTrain(
    sort = this.sortEmployeesTrain,
    order = this.orderEmployeesTrain,
    page = this.pageEmployeesTrain
  ) {
    this.sortEmployeesTrain = sort;
    this.orderEmployeesTrain = order;
    this.pageEmployeesTrain = page;
    this.getEmployeesTrain("list_employees_train");
  }

  filterEmployeesTrain(property) {
    if (this.sortEmployeesTrain === property) {
      if (this.orderEmployeesTrain === "asc") {
        this.orderEmployeesTrain = "desc";
      } else {
        this.orderEmployeesTrain = "asc";
      }
    } else {
      this.sortEmployeesTrain = property;
      this.orderEmployeesTrain = "asc";
    }

    this.getEmployeesTrain("list_employees_train");
  }

  dispatchPaginationEmployeesMustTrain(data) {
    if (data.action === "total_elements") {
      this.refreshPageEmployeesTrain(data.value);
    } else if (data.action === "change_page") {
      this.changePageEmployeesTrain(data.value);
    }
  }

  refreshPageEmployeesMustTrain(limit) {
    this.limitEmployeesMustTrain = limit;
    this.pageEmployeesMustTrain = 1;
    this.refreshDataEmployeesMustTrain();
  }

  changePageEmployeesMustTrain(page) {
    if (page >= 1 && page <= this.pagesEmployeesMustTrain) {
      this.pageEmployeesMustTrain = page;
      this.getEmployeesMustTrain("list_employees_must_train");
    }
  }

  refreshDataEmployeesMustTrain(
    sort = this.sortEmployeesMustTrain,
    order = this.orderEmployeesMustTrain,
    page = this.pageEmployeesMustTrain
  ) {
    this.sortEmployeesMustTrain = sort;
    this.orderEmployeesMustTrain = order;
    this.pageEmployeesMustTrain = page;
    this.getEmployeesMustTrain("list_employees_must_train");
  }

  filterEmployeesMustTrain(property) {
    if (this.sortEmployeesMustTrain === property) {
      if (this.orderEmployeesMustTrain === "asc") {
        this.orderEmployeesMustTrain = "desc";
      } else {
        this.orderEmployeesMustTrain = "asc";
      }
    } else {
      this.sortEmployeesMustTrain = property;
      this.orderEmployeesMustTrain = "asc";
    }

    this.getEmployeesMustTrain("list_employees_must_train");
  }

  getUrl(content) {
    if ("list_trainings" === content || "list_expired_trainings" === content) {
      this.modal = $(this._rootNode.nativeElement).find("div#" + content);
      this.modal.modal("hide");
      return this.router.navigate(["/formations"]);
    } else if (
      "list_employees_train" === content ||
      "list_employees_must_train" === content
    ) {
      this.modal = $(this._rootNode.nativeElement).find("div#" + content);
      this.modal.modal("hide");
      return this.router.navigate(["/employees"]);
    }
  }
}
