import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs';

import { AuthService } from '../../../core/auth/auth.service';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { InterventionsRequestService } from '../../../services/interventions.request.service';

import { EstablishmentsRequestService } from '../../../services/establishments.request.service';
import { CheckpointsRequestService } from '../../../services/checkpoints.request.service';
import { StatisticsRequestService } from '../../../services/statistics.request.service';
import { VideosRequestService } from '../../../services/videos.request.service';

import Intervention from '../../../../shared/interfaces/intervention';
import BatiFile from '../../../../shared/interfaces/bati_file';

import { environment } from '../../../../environments/environment';

import { VideoModalComponent } from '../../../core/video-modal/video-modal.component';
import { PdfViewerComponent } from '../../../core/pdf-viewer/pdf-viewer.component';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-all-interventions',
  templateUrl: './all-interventions.component.html',
  styleUrls: ['./all-interventions.component.scss']
})
export class AllInterventionsComponent implements OnInit, AfterViewInit, OnDestroy {
  changeEnterprise: Subscription;

  canCreate = false;
  canUpdate = false;
  canDelete = false;
  canLog = false;

  requestInProgress = new Subject();
  requestCount = 0;

  currentRoute = '';

  interventions: Intervention[] = [];
  files: BatiFile[] = [];

  pageName: string;
  previousPageName: string[] = [];
  previousPageRoute: string[] = [];

  establishments = [];
  selectedEstablishment = [];
  installations = [];
  selectedInstallation = [];
  checkpoints = [];
  selectedCheckpoints = [];
  selectedCheckpoint = [];

  count = 0;
  pages = 1;

  view = '';

  search = '';
  sort = '';
  order = 'desc';
  limit = 12;
  page = 1;

  timeout: any;
  loader = false;

  modalConfirmData = null;
  modalConfirmFile = null;
  modalVideo: VideoModalComponent = null;
  modalPdf: PdfViewerComponent = null;

  constructor(
    private http: HttpClient,
    private notifier: NotifierService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private videosService: VideosRequestService,
    private statisticsService: StatisticsRequestService,
    private checkpointService: CheckpointsRequestService,
    private interventionsService: InterventionsRequestService,
    private establishmentService: EstablishmentsRequestService,
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loader = this.requestCount > 0;
    });

    this.statisticsService.getInstallations().subscribe(response => {
      if (response['success']) {
        this.installations = response['installations'];
        this.checkpoints = response['checkpoints'];
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
    });

    this.changeEnterprise = this.authService.getEnterprise().subscribe(() => {
      this.getInterventions();
      this.getEstablishments();
    });

    this.currentRoute = '/' + this.activatedRoute.snapshot.url.join('/') + '/';

    if (this.activatedRoute.snapshot.url[2] && this.activatedRoute.snapshot.url[2].path === 'files') {
      this.view = 'intervention_files';
      this.sort = 'InterventionVoucherFiles.name';
      this.pageName = 'Liste des documents';
    } else {
      this.view = 'interventions';
      this.sort = 'InterventionVouchers.intervention_number';
      this.pageName = 'Bons d\'intervention';
    }

    if (this.authService.loaded) {
      this.getInterventions();
      this.getEstablishments();
    }

    this.createBreadcrumbs();
  }

  ngAfterViewInit() {
    this.modalConfirmData.eventOnClose.subscribe(event => {
      if (event) {
        this.requestInProgress.next(true);
        let request;

        request = this.interventionsService.deleteIntervention(
          event
        );

        request.subscribe(response => {
          if (response['success']) {
            this.refreshData();
            CustomNotifierService.getSuccessDelete(this.notifier);
          } else if (response['errors']) {
            CustomNotifierService.getErrors(response['errors'], this.notifier);
          }
          this.requestInProgress.next(false);
        });
      }
    });
    this.modalConfirmFile.eventOnClose.subscribe(event => {
      if (event) {
        this.requestInProgress.next(true);
        let request;

        request = this.interventionsService.deleteFile(
          this.activatedRoute.snapshot.params.intervention_id,
          event
        );

        request.subscribe(response => {
          if (response['success']) {
            this.refreshData();
            CustomNotifierService.getSuccessDelete(this.notifier);
          } else if (response['errors']) {
            CustomNotifierService.getErrors(response['errors'], this.notifier);
          }
          this.requestInProgress.next(false);
        });
      }
    });
  }

  ngOnDestroy() {
    this.changeEnterprise.unsubscribe();
  }

  searchData() {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.page = 1;
      this.refreshData();
    }, 500);
  }

  refreshData(sort = this.sort, order = this.order, page = this.page) {
    this.sort = sort;
    this.order = order;
    this.page = page;
    this.getInterventions();
  }

  getEstablishments() {
    let request;
    this.establishments = [];
    this.requestInProgress.next(true);

    request = this.establishmentService.getMyEstablishments(
        '', '', '', '', ''
    );

    request.subscribe(response => {
      if (response['success']) {
        this.establishments = response['establishments'];
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  getCheckpoints() {
    if (!this.selectedInstallation || !this.checkpoints) {
      return [];
    }
    this.selectedCheckpoints = [];
    for (let i = 0; i < this.checkpoints.length; i++) {
      if (this.checkpoints[i]['installation_id'] === this.selectedInstallation) {
        this.selectedCheckpoints.push(this.checkpoints[i]);
      }
    }
  }

  updateSelectedInstallation() {
    this.selectedCheckpoint = [];
    this.getCheckpoints();
  }

  updateSelectedCheckpoint() {
    this.getInterventions();
  }

  getInterventions() {
    let request;
    this.interventions = [];
    this.requestInProgress.next(true);

    if (this.view === 'intervention_files') {
      request = this.interventionsService.getFiles(
        this.search, this.sort, this.order, this.limit, this.page,
        this.activatedRoute.snapshot.params.intervention_id
      );
    } else {
      request = this.interventionsService.getAllInterventions(
        this.search, this.sort, this.order, this.limit, this.page, this.selectedEstablishment, this.selectedCheckpoint
      );
    }

    request.subscribe(response => {
      if (response['success']) {
        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];
        this.canDelete = response['canDelete'];
        this.canLog = response['canLog'];

        if (response['interventions']) {
          this.interventions = response['interventions'];
        } else {
          this.files = response['files'];
        }

        this.count = response['count'];
        this.pages = response['pages'];
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  downloadPdf(id, intervention_number) {
    const elem = document.getElementById(id);
    const fileName = 'bon_intervention_' + intervention_number + '.pdf';

    elem.classList.add('is-loading');
    elem.classList.add('is-loading-sm');
    elem.classList.add('disabled');

    let request;
    request = this.interventionsService.downloadPdf(id);
    request.subscribe((response: BufferSource) => {
      try {
        const decoder = new TextDecoder('utf-8');
        const domString = decoder.decode(response);
        const json = JSON.parse(domString);
        CustomNotifierService.getErrors(json['errors'], this.notifier);
      } catch (e) {
        const blob = new Blob([response]);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      }
      elem.classList.remove('is-loading');
      elem.classList.remove('is-loading-sm');
      elem.classList.remove('disabled');
    });
  }

  downloadFile(file_id, file_name) {
    let request;

    request = this.interventionsService.downloadFile(
      this.activatedRoute.snapshot.params.intervention_id,
      file_id
    );

    request.subscribe((response: BufferSource) => {
      try {
        const decoder = new TextDecoder('utf-8');
        const domString = decoder.decode(response);
        const json = JSON.parse(domString);

        CustomNotifierService.getErrors(json['errors'], this.notifier);
      } catch (e) {
        const blob = new Blob([response]);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = file_name;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      }
    });
  }

  filter(property) {
    if (this.sort === property) {
      if (this.order === 'asc') {
        this.order = 'desc';
      } else {
        this.order = 'asc';
      }
    } else {
      this.sort = property;
      this.order = 'asc';
    }

    this.getInterventions();
  }

  dispatchPagination(data) {
    if (data.action === 'total_elements') {
      this.refreshPage(data.value);
    } else if (data.action === 'change_page') {
      this.changePage(data.value);
    }
  }

  refreshPage(limit) {
    this.limit = limit;
    this.page = 1;
    this.refreshData();
  }

  createBreadcrumbs() {
    this.previousPageName = [];
    this.previousPageRoute = [];

    this.previousPageName.push('Tableau de bord');
    this.previousPageRoute.push('/');

    if (this.view === 'intervention_files') {
      this.previousPageName.push('Bons d\'intervention');
      this.previousPageRoute.push('/interventions');
    }
  }

  changePage(page) {
    if (page >= 1 && page <= this.pages) {
      this.page = page;
      this.getInterventions();
    }
  }

  bindModalConfirmData(modal) {
    this.modalConfirmData = modal;
  }
  bindModalConfirmFile(modal) {
    this.modalConfirmFile = modal;
  }

  /**
   * Download one file
   *
   * @param file_id The file
   */
  showVideo(file_id) {
    this.videosService.getFile(file_id).subscribe(response => {
      if (response['success']) {
        const url = environment.API_PATH + 'download-video/' + response['file']['token'];
        this.modalVideo.open(url);
      }
    });
  }
  /**
   * Show Pdf
   *
   * @param file_url The file
   */
  showPdf(file_url) {
    const url = environment.API_PATH + 'file/InterventionFiles/' + file_url;
    this.http.get(url + '/isExist').subscribe(response => {
      if (response['success']) {
        this.modalPdf.open(url);
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
    });
  }
  getImage(extension) {
    if (extension === 'mp4') {
      return '../assets/img/files/mp4.png';
    } else if (extension === 'pdf') {
      return '../assets/img/files/pdf.png';
    } else if (extension === 'xlsx' || extension === 'csv') {
      return '../assets/img/files/xls.png';
    } else if (extension === 'doc' || extension === 'docx') {
      return '../assets/img/files/doc.png';
    } else if (extension === 'jpeg' || extension === 'jpg') {
      return '../assets/img/files/jpg.png';
    } else if (extension === 'png') {
      return '../assets/img/files/png.png';
    } else {
      return '../assets/img/files/file_icon.png';
    }
  }
  bindModalVideo(modal) {
    this.modalVideo = modal;
  }
  bindModalPdf(modal) {
    this.modalPdf = modal;
  }
}
