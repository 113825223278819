<app-modal-right (eventOnClose)="closeModal()" #offer>{{ bindModal(offer) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">{{ isNew ? 'Création' : canUpdate ? 'Mise à jour' : 'Consultation' }}</h6>

        <h1 class="header-title">Offre</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <form class="mb-4" [formGroup]="offerForm" [hidden]="loaderContent">
      <ul class="nav nav-tabs" role="tablist">
        <li class="nav-item col-6">
          <a class="nav-link href" [ngClass]="{ 'active': activeTab === 'firstPart'}" (click)="changeTabs('firstPart')">Informations</a>
        </li>
        <li class="nav-item col-6">
          <a class="nav-link href" [ngClass]="{ 'active': activeTab === 'secondPart'}" (click)="changeTabs('secondPart')">Tarifs</a>
        </li>
      </ul>

      <div class="tab-content">
        <div class="tab-pane fade" id="firstPart" role="tabpanel" aria-labelledby="firstPart" [ngClass]="{ 'show active': activeTab === 'firstPart'}">
          <div class="form-group">
            <label for="validationName" required>Nom de l'offre</label>
            <input type="text" formControlName="name" id="validationName" class="form-control" placeholder="Nom de l'offre" maxlength="255"
                   [ngClass]="{ 'is-invalid': name.invalid && (name.dirty || name.touched) }">
            <div *ngIf="name.invalid && (name.dirty || name.touched)" class="invalid-feedback">
              {{ getErrorMessage("name") }}
            </div>
          </div>

          <div class="form-group">
            <label for="validationType" required>Type d'offre</label>
            <select class="form-control" data-toggle="select" formControlName="type" id="validationType" placeholder="Type d'offre" [ngClass]="{ 'is-invalid': type.invalid && (type.dirty || type.touched) }">
              <option value="" [selected]="!type.value">Sélectionnez un type d'offre</option>
              <option value="Privée">Privée</option>
              <option value="Public">Public</option>
              <option value="Spécifique">Spécifique</option>
            </select>
            <div *ngIf="type.invalid && (type.dirty || type.touched)" class="invalid-feedback">
              {{ getErrorMessage("type") }}
            </div>
          </div>

          <div class="form-group" *ngIf="!loaderContent && (isNew || (!isNew && offerForm.value.id))">
            <label for="listRegistry">Registres prévu dans l'offre</label>
            <ng-select [items]="registries"
                       [multiple]="true"
                       bindLabel="name"
                       bindValue="id"
                       groupBy="group"
                       notFoundText="Aucun résultat"
                       [selectableGroup]="true"
                       [selectableGroupAsModel]="false"
                       [closeOnSelect]="false"
                       placeholder="Sélectionnez un/ou plusieurs registre(s)"
                       [(ngModel)]="selectedRegistry" [ngModelOptions]="{ standalone: true }" [disabled]="!isNew && !canUpdate">
              <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                <input type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }"/> Sélectionnez tout
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="listRegistry" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }" /> {{ item.name }}
              </ng-template>
            </ng-select>
          </div>

          <div class="form-group">
            <label for="validationNumberCustomer" required>Registre au choix du client</label>
            <input type="number" formControlName="registry_limit" id="validationNumberCustomer" class="form-control" placeholder="Nombre de registre" maxlength="255"
                   [ngClass]="{ 'is-invalid': registry_limit.invalid && (registry_limit.dirty || registry_limit.touched) }">
            <div *ngIf="registry_limit.invalid && (registry_limit.dirty || registry_limit.touched)" class="invalid-feedback">
              {{ getErrorMessage("registry_limit") }}
            </div>
          </div>
        </div>

        <div class="tab-pane fade" id="secondPart" role="tabpanel" aria-labelledby="secondPart" [ngClass]="{ 'show active': activeTab === 'secondPart'}">
          <div class="form-group">
            <div class="d-flex justify-content-between align-items-center mb-05 list-head" *ngIf="offerForm.controls['prices'].value.length">
              <span class="d-inline-block col-5 p-0">Établissement</span>

              <span class="d-inline-block col-5 p-0">Tarif (€)</span>

              <span style="width: 15px;"></span>
            </div>

            <div formArrayName="prices">
              <div *ngFor="let priceValue of offerForm['controls'].prices['controls']; let i = index;">
                <div [formGroupName]="i" class="d-flex justify-content-between align-items-center mb-05">
                  <input type="number" placeholder="Nombre" formControlName="nb_establishment" class="form-control d-inline-block col-5" [ngClass]="{ 'is-invalid': priceValue['controls'].nb_establishment.invalid && (priceValue['controls'].nb_establishment.dirty || priceValue['controls'].nb_establishment.touched) }">

                  <input type="number" placeholder="Tarif" formControlName="price" class="form-control d-inline-block col-5" [ngClass]="{ 'is-invalid': priceValue['controls'].price.invalid && (priceValue['controls'].price.dirty || priceValue['controls'].price.touched) }">

                  <a class="href" (click)="deleteOfferPrice(i)">
                    <i class="fe fe-x-circle"></i>
                  </a>
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-center align-items-center mt-4" *ngIf="!offerForm.controls.prices.value.length">
              Aucun tarif enregistré avec l'offre sélectionnée
            </div>
          </div>

          <div class="form-group">
            <button class="btn btn-primary w-100" (click)="addOfferPrice()">
              Ajouter un tarif
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div modal-footer class="w-100 d-flex justify-content-between">
    <button class="btn btn-primary w-30" [disabled]="activeTab === 'firstPart'" (click)="prevTabs()">
      Précédent
    </button>

    <button class="btn btn-primary w-30" *ngIf="activeTab === 'firstPart'" (click)="nextTabs()">
      Suivant
    </button>

    <button class="btn btn-primary w-30" *ngIf="activeTab === 'secondPart'" (click)="isNew ? createOffer() : updateOffer()"
            [ngClass]="{ 'is-loading is-loading-sm': loader }" [disabled]="loader || offerForm.invalid || (!isNew && !canUpdate)">
      {{ isNew ? 'Créer' : 'Mettre à jour' }}
    </button>
  </div>
</app-modal-right>
