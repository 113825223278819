import { Component, OnDestroy, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Subject, Subscription } from 'rxjs';

import Registry from '../../../../../shared/interfaces/registry';
import Establishment from '../../../../../shared/interfaces/establishment';

import { InstallationsRequestService } from '../../../../services/installations.request.service';
import { AuthService } from '../../../../core/auth/auth.service';
import { EstablishmentsRequestService } from '../../../../services/establishments.request.service';
import { CustomNotifierService } from '../../../../services/custom.notifier.service';
import { RegistriesRequestService } from '../../../../services/registries.request.service';

import { environment } from '../../../../../environments/environment';
import { RisksRequestService } from '../../../../services/risks.request.service';

@Component({
  selector: 'app-show-user',
  templateUrl: './show-user.component.html',
  styleUrls: ['./show-user.component.scss']
})
export class ShowUserComponent implements OnInit, OnDestroy {
  changeEnterprise: Subscription;
  environment = environment;

  canUpdate = false;
  canCreate = false;

  requestInProgress = new Subject();
  requestCount = 0;

  loader = false;
  errors = {};

  pageName: string;
  previousPageName: string[] = [];
  previousPageRoute: string[] = [];

  registry: Registry;
  establishment: Establishment;
  users: any[] = [];

  constructor(
      private router: Router,
      private authService: AuthService,
      private notifier: NotifierService,
      private activatedRoute: ActivatedRoute,
      private risksService: RisksRequestService,
      private registriesService: RegistriesRequestService,
      private installationsService: InstallationsRequestService,
      private establishmentsService: EstablishmentsRequestService,
  ) {
  }

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loader = this.requestCount > 0;
    });

    this.changeEnterprise = this.authService.getEnterprise().subscribe(() => {
      this.getRegistry();
      this.getEstablishment();
      this.getUsers();
      this.createBreadcrumbs();
    });

    if (this.authService.loaded) {
      this.getRegistry();
      this.getEstablishment();
      this.getUsers();
      this.createBreadcrumbs();
    }
  }

  ngOnDestroy() {
    this.changeEnterprise.unsubscribe();

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  getRegistry() {
    this.requestInProgress.next(true);
    this.registriesService.getRegistry(this.activatedRoute.snapshot.params.registry_id).subscribe(response => {
      if (response['success']) {
        this.registry = response['registry'];
        this.createBreadcrumbs();
      } else if (response['errors']) {
        this.errors = response['errors'];
      }
      this.requestInProgress.next(false);
    });
  }

  getEstablishment() {
    this.requestInProgress.next(true);
    this.establishmentsService.getMyEstablishment(this.activatedRoute.snapshot.params.establishment_id).subscribe(response => {
      if (response['success']) {
        this.establishment = response['establishment'];
        this.createBreadcrumbs();
      } else if (response['errors']) {
        this.errors = response['errors'];
      }
      this.requestInProgress.next(false);
    });
  }

  getUsers() {
    this.requestInProgress.next(true);

    const registry_id = this.activatedRoute.snapshot.params.registry_id;
    const establishment_id = this.activatedRoute.snapshot.params.establishment_id;
    this.risksService.getMyRegistryEstablishmentUsersShow(registry_id, establishment_id).subscribe(response => {
      if (response['success']) {
        this.users = response['users'];
      }
      this.requestInProgress.next(false);
    });
  }

  updateShow() {
    this.requestInProgress.next(true);

    const registry_id = this.activatedRoute.snapshot.params.registry_id;
    const establishment_id = this.activatedRoute.snapshot.params.establishment_id;

    this.risksService.updateMyRegistryEstablishmentUsersShow(
        registry_id, establishment_id, this.users).subscribe(response => {
      if (response['success']) {
        CustomNotifierService.getSuccessUpdate(this.notifier);
        this.getUsers();
      } else if (response['errors']) {
        CustomNotifierService.getErrors(this.errors, this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  checkUnity(keyUser) {
    const show_user = this.users[keyUser].show_user;
    this.users[keyUser].show_user = !show_user;
  }

  createBreadcrumbs() {
    this.pageName = 'Edition de masse';

    this.previousPageName = [];
    this.previousPageRoute = [];

    this.previousPageName.push('Tableau de bord');
    this.previousPageRoute.push('/');

    this.previousPageName.push(this.registry ? this.registry.name : 'Tableau de bord registre');
    this.previousPageRoute.push('/dashboard-registry/' + this.activatedRoute.snapshot.params.registry_id);

    this.previousPageName.push(this.establishment ? this.establishment.name : 'Tableau de bord établissement');
    this.previousPageRoute.push('/dashboard-registry/' + this.activatedRoute.snapshot.params.registry_id +
        '/establishment/' + this.activatedRoute.snapshot.params.establishment_id);

    this.previousPageName.push('Personnes concernées par les risques');
    this.previousPageRoute.push('/dashboard-registry/' + this.activatedRoute.snapshot.params.registry_id +
        '/establishment/' + this.activatedRoute.snapshot.params.establishment_id + '/risks-users');
  }
}
