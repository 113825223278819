import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './core/auth/auth.guard';

import { LayoutComponent } from './core/layout/layout.component';

import { accessInternalRoutes } from './pages/access-internal/access-internal.routing';
import { accessProviderRoutes } from './pages/access-provider/access-provider.routing';
import { authRoutes } from './pages/auth/auth.routing';
import { accountRoutes } from './pages/account/account.routing';
import { agenciesRoutes } from './pages/agencies/agencies.routing';
import { asbestosRoutes } from './pages/asbestos/asbestos.routing';
import { checkpointsRoutes } from './pages/checkpoints/checkpoints.routing';
import { cotationsRoutes } from './pages/cotations/cotations.routing';
import { customersRoutes } from './pages/customers/customers.routing';
import { documentsRoutes } from './pages/documents/documents.routing';
import { enterprisesRoutes } from './pages/enterprises/enterprises.routing';
import { establishmentsRoutes } from './pages/establishments/establishments.routing';
import { expertisesRoutes } from './pages/expertises/expertises.routing';
import { firePermitsRoutes } from './pages/fire-permits/fire-permits.routing';
import { trainingsRoutes } from './pages/trainings/trainings.routing';
import { installationsRoutes } from './pages/installations/installations.routing';
import { logsRoutes } from './pages/logs/logs.routing';
import { notFoundRoutes } from './pages/not-found/not-found.routing';
import { offersRoutes } from './pages/offers/offers.routing';
import { registriesRoutes } from './pages/registries/registries.routing';
import { reportsRoutes } from './pages/reports/reports.routing';
import { reportWorksheetsRoutes } from './pages/report-worksheets/report-worksheets.routing';
import { risksRoutes } from './pages/risks/risks.routing';
import { rolesRoutes } from './pages/roles/roles.routing';
import { usersRoutes } from './pages/users/users.routing';
import { worksheetsRoutes } from './pages/worksheets/worksheets.routing';
import { commissionsRoutes } from './pages/commissions/commissions.routing';
import { prescriptionsRoutes } from './pages/prescriptions/prescriptions.routing';
import { observationsRoutes } from './pages/observations/observations.routing';
import { interventionsRoutes } from './pages/interventions/interventions.routing';
import { employeesRoutes } from './pages/employees/employees.routing';
import { planningRoutes } from './pages/planning/planning.routing';
import { issuesRoutes } from './pages/issues/issues.routing';
import { officialsRoutes } from './pages/officials/officials.routing';
import { officialPrescriptionsRoutes } from './pages/official-prescriptions/official-prescriptions.routing';
import { notificationsRoutes } from './pages/notifications/notifications.routing';
import { preventionsRoutes } from './pages/preventions/preventions.routing';
import { preventionRisksRoutes } from './pages/prevention-risks/prevention-risks.routing';
import { securityProtocolsRoutes } from './pages/security-protocols/security-protocols.routing';
import { dashboardRoutes } from './pages/dashboard/dashboard.routing';
import { sanitaryContributorsRoutes } from './pages/sanitary-contributors/sanitary-contributors.routing';
import { sanitaryInstallationsRoutes } from './pages/sanitary-installations/sanitary-installations.routing';
import { sanitaryWorksheetsRoutes } from './pages/sanitary-worksheets/sanitary-worksheets.routing';
import { sanitaryOperationsRoutes } from './pages/sanitary-operations/sanitary-operations.routing';
import { sanitaryPoolsRoutes } from './pages/sanitary-pools/sanitary-pools.routing';
import { sanitarySurveillancesRoutes } from './pages/sanitary-surveillances/sanitary-surveillances.routing';
import { sanitaryFramesRoutes } from './pages/sanitary-frames/sanitary-frames.routing';
import { sanitaryCheckpointsRoutes } from './pages/sanitary-checkpoints/sanitary-checkpoints.routing';
import { singleSignOnRoutes } from './pages/single-sign-on/single-sign-on.routing';
import { adminExportsRoutes } from './pages/admin-exports/admin-exports.routing';
import { contentsRoutes } from './pages/contents/contents.routing';
import { faqRoutes } from './pages/faq/faq.routing';
import { rvratRoutes } from './pages/rvrat/rvrat.routing';
import { rvratPrescriptionsRoutes } from './pages/rvrat-prescriptions/rvrat-prescriptions.routing';
import { allTrainingRoutes } from './pages/all-training/all-training.routing';
import { employeesTrainingRoutes } from './pages/employees-training/get-employees-training.routing';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'customer/fr/',
        redirectTo: '',
        pathMatch: 'full',
      },
      {
        path: 'manager/fr/',
        redirectTo: '',
        pathMatch: 'full',
      },
      // {
      //   path: '',
      //   loadChildren: './pages/dashboard/dashboard.module#DashboardModule'
      // },
      ...dashboardRoutes,
      ...allTrainingRoutes,
      ...employeesTrainingRoutes,
      ...accessInternalRoutes,
      ...accessProviderRoutes,
      ...accountRoutes,
      ...agenciesRoutes,
      ...asbestosRoutes,
      ...checkpointsRoutes,
      ...cotationsRoutes,
      ...customersRoutes,
      ...documentsRoutes,
      ...enterprisesRoutes,
      ...establishmentsRoutes,
      ...expertisesRoutes,
      ...firePermitsRoutes,
      ...trainingsRoutes,
      ...installationsRoutes,
      ...logsRoutes,
      ...offersRoutes,
      ...reportsRoutes,
      ...registriesRoutes,
      ...reportWorksheetsRoutes,
      ...risksRoutes,
      ...rolesRoutes,
      ...usersRoutes,
      ...worksheetsRoutes,
      ...commissionsRoutes,
      ...prescriptionsRoutes,
      ...observationsRoutes,
      ...interventionsRoutes,
      ...issuesRoutes,
      ...employeesRoutes,
      ...planningRoutes,
      ...officialsRoutes,
      ...officialPrescriptionsRoutes,
      ...preventionsRoutes,
      ...preventionRisksRoutes,
      ...securityProtocolsRoutes,
      ...sanitaryContributorsRoutes,
      ...sanitaryInstallationsRoutes,
      ...sanitaryWorksheetsRoutes,
      ...sanitaryOperationsRoutes,
      ...sanitaryPoolsRoutes,
      ...sanitarySurveillancesRoutes,
      ...sanitaryFramesRoutes,
      ...sanitaryCheckpointsRoutes,
      ...notificationsRoutes,
      ...singleSignOnRoutes,
      ...adminExportsRoutes,
      ...contentsRoutes,
      ...faqRoutes,
      ...rvratRoutes,
      ...rvratPrescriptionsRoutes,
    ]
  },
  {
    path: 'auth',
    canActivate: [AuthGuard],
    children: [...authRoutes],
  },
  ...notFoundRoutes,
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
