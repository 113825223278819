<div class="main-content">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col">
        <div class="header mb-0">
          <div class="header-body no-border">
            <div class="row align-items-center">
              <div class="col">
                <h1 class="header-title">Fiches Travaux</h1>

                <small class="form-text text-muted">Liste des fiches travaux</small>

                <app-breadcrumbs [currentPage]="pageName" [names]="previousPageName" [routes]="previousPageRoute"></app-breadcrumbs>
              </div>

              <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0" *ngIf="view === 'customerListWorksheets' && canCreate">
                <a class="btn btn-primary d-block d-md-inline-block" [routerLink]="[createRoute]">
                  Ajouter une fiche travaux
                </a>
              </div>

              <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0" *ngIf="view === 'adminFileTypes' && canCreate">
                <a class="btn btn-primary d-block d-md-inline-block" [routerLink]="[createRoute]">
                  Ajouter un type de document
                </a>
              </div>

              <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0" *ngIf="view === 'customerFiles' && canCreate">
                <a class="btn btn-primary d-block d-md-inline-block" [routerLink]="[createRoute]">
                  Ajouter un fichier
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col">
            <app-searchbar [(ngModel)]="search" (keyup)="searchData()" ngDefaultControl></app-searchbar>
          </div>
        </div>

        <div class="is-loading loader-content" *ngIf="loader"></div>

        <div class="tab-content" *ngIf="!loader && (worksheets.length || requiredWorksheets.length || files.length)">
          <div class="tab-pane fade active show" id="tabPaneOne" role="tabpanel">
            <div class="row listAlias">
              <!-- WORKSHEETS -->
              <div class="col-12 d-flex flex-column" *ngIf="view === 'customerListWorksheets'">
                <div class="card flex-fill">
                  <div class="card-body">
                    <div class="table-responsive">
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th class="href" (click)="filter('Worksheets.operation_name')">
                              <a class="text-muted">Intitulé de l'opération</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'Worksheets.operation_name' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'Worksheets.operation_name' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'Worksheets.operation_name'"></span>
                            </th>
                            <th class="href" (click)="filter('Worksheets.uploaded_on_date')">
                              <a class="text-muted">DACAM ou PC déposé(e) le</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'Worksheets.uploaded_on_date' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'Worksheets.uploaded_on_date' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'Worksheets.uploaded_on_date'"></span>
                            </th>
                            <th class="href" (click)="filter('Worksheets.accepted_on_date')">
                              <a class="text-muted">DACAM ou PC accepté(e) le</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'Worksheets.accepted_on_date' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'Worksheets.accepted_on_date' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'Worksheets.accepted_on_date'"></span>
                            </th>
                            <th class="href" (click)="filter('Worksheets.project_manager')">
                              <a class="text-muted">Maître d'Oeuvre</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'Worksheets.project_manager' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'Worksheets.project_manager' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'Worksheets.project_manager'"></span>
                            </th>
                            <th class="href" (click)="filter('Worksheets.technical_controller')">
                              <a class="text-muted">Contrôlleur technique</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'Worksheets.technical_controller' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'Worksheets.technical_controller' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'Worksheets.technical_controller'"></span>
                            </th>
                            <th class="href" (click)="filter('Worksheets.project_manager_referent')">
                              <a class="text-muted">Référent maîtrise d'ouvrage</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'Worksheets.project_manager_referent' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'Worksheets.project_manager_referent' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'Worksheets.project_manager_referent'"></span>
                            </th>
                            <th class="href" (click)="filter('Worksheets.receipt_work_date')">
                              <a class="text-muted">Date de réception travaux</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'Worksheets.receipt_work_date' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'Worksheets.receipt_work_date' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'Worksheets.receipt_work_date'"></span>
                            </th>
                            <th>Date de réception Commission Sécurité</th>
                            <th>Document(s)</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="dropdown" *ngFor="let worksheet of worksheets">
                            <td>
                              <a class="href" [routerLink]="[viewRoute + '/worksheet/' + worksheet.id]">
                                {{ worksheet.operation_name }}
                              </a>
                            </td>
                            <td>{{ worksheet.uploaded_on_date | date : 'dd/MM/yyyy' }}</td>
                            <td>{{ worksheet.accepted_on_date | date : 'dd/MM/yyyy' }}</td>
                            <td>{{ worksheet.project_manager }}</td>
                            <td>{{ worksheet.technical_controller }}</td>
                            <td>{{ worksheet.project_manager_referent }}</td>
                            <td>{{ worksheet.receipt_work_date | date : 'dd/MM/yyyy' }}</td>
                            <td>{{ worksheet.commission?.visit_date | date : 'dd/MM/yyyy' }}</td>
                            <td>
                              <div class="btn-group">
                                <a class="btn btn-sm btn-white" [routerLink]="[viewRoute + '/worksheet/' + worksheet.id]">
                                  {{ worksheet.count_documents + ' document(s)'}}
                                </a>
                              </div>
                            </td>
                            <td class="d-flex flex-row-reverse">
                              <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fe fe-more-vertical"></i>
                              </a>

                              <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item href" [routerLink]="[viewRoute + '/worksheet/' + worksheet.id]">
                                  Consulter le contenu
                                </a>

                                <a class="dropdown-item href" [routerLink]="[currentRoute + worksheet.id]">
                                  {{ canUpdate ? 'Éditer la fiche travaux' : 'Consulter la fiche travaux' }}
                                </a>


                                <a class="dropdown-item href" (click)="worksheet.commission?.id && worksheet.commission?.visit_date ? modalConfirmCommission.open(worksheet.id) : modalConfirmData.open(worksheet.id)" *ngIf="canDelete">
                                  Supprimer la fiche travaux
                                </a>

                                <a class="dropdown-item href" [routerLink]="[currentRoute + worksheet.id + '/logs']">
                                  Historique des actions
                                </a>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <app-pagination (returnEvent)="dispatchPagination($event)"
                                    [count_elements]="count"
                                    [current_page]="page"
                                    [elements_length]="worksheets.length"
                                    [limit]="limit"
                                    [total_pages]="pages"
                                    ngDefaultControl>
                    </app-pagination>
                  </div>
                </div>
              </div>

              <!-- WORKSHEETS FILES REQUIRED -->
              <div class="col-12" *ngIf="view === 'customerFileTypes'">
                <div class="card bg-light border" *ngIf="requiredWorksheets.length && selectedRequired.length">
                  <div class="card-body">
                    <p class="mb-2">Exigences relatives à la fiche travaux</p>

                    <p class="small text-muted mb-2">
                      Des documents obligatoires n'ont pas été envoyés sur la plateforme :
                    </p>

                    <ul class="small text-muted pl-4 mb-0">
                      <li>
                        Les rapports de vérification réglementaire après travaux
                      </li>
                    </ul>
                    <ul class="small text-muted pl-4 mb-0">
                      <li *ngFor="let requiredWorksheet of selectedRequired">
                        {{ requiredWorksheet.name }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <!-- RVRAT -->
              <div class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4 d-flex flex-column" *ngIf="view === 'customerFileTypes'">
                <div class="card flex-fill">
                  <div class="card-body">

                    <span class="button__badge" [ngClass]="{ 'color-badge' : rvrats.length }">
                      {{ rvrats.length > 99 ? '99+' :rvrats.length }}
                    </span>

                    <div class="row align-items-center">
                      <div class="col-auto align-self-start">
                        <i class="fe fe-list"></i>
                      </div>

                      <div class="col overflow-hidden">
                        <h4 class="card-title name text-truncate mb-0">
                          <a [routerLink]="['rvrat']">
                            Les rapports de vérification réglementaire après travaux
                          </a>
                        </h4>

                        <p class="card-text small text-truncate mb-0" *ngIf="rvrats.length">
                          Rapport(s) envoyé(s) avec succès
                        </p>
                      </div>

                      <div class="col-auto align-self-start">
                        <div class="dropdown">
                          <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fe fe-more-vertical"></i>
                          </a>

                          <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item href" [routerLink]="['rvrat/create']">
                              Ajouter une rapport de vérification réglementaire après travaux
                            </a>

                            <a class="dropdown-item href" [routerLink]="['rvrat']">
                              Consulter les rapports de vérification réglementaire après travaux
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4 d-flex flex-column" *ngFor="let requiredWorksheet of requiredWorksheets">
                <div class="card flex-fill">
                  <div class="card-body" *ngIf="view === 'customerFileTypes'">
                    <span class="button__badge" [ngClass]="{ 'color-badge' : requiredWorksheet.worksheet_files.length }">
                      {{ requiredWorksheet.worksheet_files.length > 99 ? '99+' : requiredWorksheet.worksheet_files.length }}
                    </span>

                    <div class="row align-items-center">
                      <div class="col-auto align-self-start">
                        <i class="fe fe-list"></i>
                      </div>

                      <div class="col overflow-hidden">
                        <h4 class="card-title name text-truncate mb-0">
                          <a [routerLink]="[requiredWorksheet.worksheet_files.length === 0 ? currentRoute + '/file-type/' + requiredWorksheet.id + '/files/create' : currentRoute + '/file-type/' + requiredWorksheet.id + '/files']">
                            {{ requiredWorksheet.name }}
                          </a>
                        </h4>

                        <p class="card-text small text-truncate mb-0" *ngIf="requiredWorksheet.worksheet_files.length">
                          Document(s) envoyé(s) avec succès
                        </p>
                      </div>

                      <div class="col-auto align-self-start">
                        <div class="dropdown">
                          <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fe fe-more-vertical"></i>
                          </a>

                          <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item href" [routerLink]="[currentRoute + '/file-type/' + requiredWorksheet.id + '/files/create']">
                              Ajouter des fichiers
                            </a>

                            <a class="dropdown-item href" [routerLink]="[currentRoute + '/file-type/' + requiredWorksheet.id + '/files']">
                              Consulter les fichiers
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card-body" *ngIf="view === 'adminFileTypes'">
                    <div class="row align-items-center">
                      <div class="col-auto align-self-start">
                        <i class="fe fe-list"></i>
                      </div>

                      <div class="col overflow-hidden">
                        <h4 class="card-title name text-truncate mb-0">
                          <a [routerLink]="[currentRoute + requiredWorksheet.id]">
                            {{ requiredWorksheet.name }}
                          </a>
                        </h4>

                        <p class="card-text text-truncate mb-0">
                          Type de fichier
                        </p>

                        <p class="card-text small text-truncate mb-0">
                          {{ requiredWorksheet.is_required ? 'Requis' : 'Facultatif' }}
                        </p>
                      </div>

                      <div class="col-auto align-self-start">
                        <div class="dropdown">
                          <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fe fe-more-vertical"></i>
                          </a>

                          <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item href" [routerLink]="[currentRoute  + requiredWorksheet.id]">
                              {{ canUpdate ? 'Éditer le type de document' : 'Consulter le type de document' }}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- WORKSHEETS ALL FILES -->
              <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 d-flex flex-column" *ngFor="let file of files">
                <div class="card flex-fill">

                  <div class="href image-file-download" *ngIf="!['mp4', 'pdf', 'png', 'jpeg', 'jpg'].includes(file.extension)" (click)="downloadFile(file.id, file.name + '.' + file.extension)">
                    <img [src]="getImage(file.extension)" alt="Télécharger le fichier"/>
                  </div>
                  <div class="href image-file-download" *ngIf="file.extension === 'mp4'" (click)="showVideo(file.id)">
                    <img [src]="getImage(file.extension)" alt="Télécharger le fichier"/>
                  </div>
                  <div class="href image-file-download" *ngIf="['pdf', 'png', 'jpeg', 'jpg'].includes(file.extension)" (click)="showPdf(file.file_url)">
                    <img [src]="getImage(file.extension)" alt="Visionner le PDF"/>
                  </div>

                  <div class="card-body">
                    <div class="row align-items-center">
                      <div class="col overflow-hidden">
                        <h4 class="card-title name text-wrap mb-0">
                          <a [routerLink]="" *ngIf="!['mp4', 'pdf', 'png', 'jpeg', 'jpg'].includes(file.extension)" (click)="downloadFile(file.id, file.name + '.' + file.extension)">
                            {{ file.name + '.' + file.extension }}
                          </a>
                          <a [routerLink]="" *ngIf="file.extension === 'mp4'" (click)="showVideo(file.id)">
                            {{ file.name + '.' + file.extension }}
                          </a>
                          <a [routerLink]="" *ngIf="['pdf', 'png', 'jpeg', 'jpg'].includes(file.extension)" (click)="showPdf(file.file_url)">
                            {{ file.name + '.' + file.extension }}
                          </a>
                        </h4>
                      </div>

                      <div class="col-auto align-self-start">
                        <div class="dropdown">
                          <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fe fe-more-vertical"></i>
                          </a>

                          <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item href" [routerLink]="[currentRoute + file.id]">
                              {{ canUpdate ? 'Éditer le fichier' : 'Consulter le fichier' }}
                            </a>

                            <a class="dropdown-item href" *ngIf="file.extension === 'mp4'" (click)="showVideo(file.id)">
                              Regarder la vidéo
                            </a>

                            <a class="dropdown-item href" *ngIf="file.extension === 'pdf'" (click)="showPdf(file.file_url)">
                              Visionner le PDF
                            </a>

                            <a class="dropdown-item href" *ngIf="['png', 'jpeg', 'jpg'].includes(file.extension)" (click)="showPdf(file.file_url)">
                              Visionner l'image
                            </a>

                            <a class="dropdown-item href" (click)="downloadFile(file.id, file.name + '.' + file.extension)">
                              Télécharger le fichier
                            </a>

                            <a class="dropdown-item href" (click)="modalConfirmFile.open(file.id)" *ngIf="canDelete">
                              Supprimer le fichier
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- PAGINATION -->
          <div class="row pagination-content" *ngIf="requiredWorksheets.length || files.length">
            <div class="col-12 d-flex flex-column">
              <div class="card">
                <app-pagination (returnEvent)="dispatchPagination($event)"
                                [classes]="'card-body'"
                                [count_elements]="count"
                                [current_page]="page"
                                [elements_length]="files.length"
                                [limit]="limit"
                                [total_pages]="pages"
                                ngDefaultControl>
                </app-pagination>
              </div>
            </div>
          </div>
        </div>

        <div class="loader-content" *ngIf="!loader && (!worksheets.length && !requiredWorksheets.length && !files.length)">
          <p>Aucun résultat</p>
        </div>
      </div>
    </div>
  </div>
</div>

<app-confirm-modal [type]="'delete_data'" #confirmData>{{ bindModalConfirmData(confirmData) }}</app-confirm-modal>
<app-confirm-modal [type]="'delete_with_commission'" #confirmCommission>{{ bindModalConfirmCommission(confirmCommission) }}</app-confirm-modal>
<app-confirm-modal [type]="'delete_file'" #confirmFile>{{ bindModalConfirmFile(confirmFile) }}</app-confirm-modal>
<app-video-modal #video>{{ bindModalVideo(video) }}</app-video-modal>
<app-pdf-viewer #pdf>{{ bindModalPdf(pdf) }}</app-pdf-viewer>

<router-outlet></router-outlet>
