import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { CheckpointsRequestService } from '../../../services/checkpoints.request.service';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../core/auth/auth.service';
import { Subject, Subscription } from 'rxjs';
import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { NotifierService } from 'angular-notifier';
import { UsersRequestService } from '../../../services/users.request.service';
import Establishment from '../../../../shared/interfaces/establishment';
import User from '../../../../shared/interfaces/user';
import { frequencies } from '../../../../shared/frequencies';

@Component({
  selector: 'app-access-internal-checkpoints',
  templateUrl: './access-internal-checkpoints.component.html',
  styleUrls: ['./access-internal-checkpoints.component.scss']
})
export class AccessInternalCheckpointsComponent implements OnInit, OnDestroy {
  changeEnterprise: Subscription;
  environment = environment;

  canUpdate = false;
  canCreate = false;

  requestInProgress = new Subject();
  requestCount = 0;

  loader = false;
  errors = {};

  pageName: string;
  previousPageName: string[] = [];
  previousPageRoute: string[] = [];

  user: User;
  user_id: number;
  establishments: Establishment[] = [];
  openedEstablishment = -1;
  openedRegistry = -1;
  openedInstallation = -1;

  timeout: any;
  view = '';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private notifier: NotifierService,
    private checkpointsService: CheckpointsRequestService,
    private usersService: UsersRequestService
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loader = this.requestCount > 0;
    });

    this.changeEnterprise = this.authService.getEnterprise().subscribe(() => {
      this.user_id = parseInt(this.activatedRoute.snapshot.params.user_id, 10);
      this.getCheckpoints();
      this.getUser();
      this.createBreadcrumbs();
    });

    this.user_id = parseInt(this.activatedRoute.snapshot.params.user_id, 10);
    if (this.authService.loaded) {
      this.getCheckpoints();
      this.getUser();
      this.createBreadcrumbs();
    }
  }

  ngOnDestroy() {
    this.changeEnterprise.unsubscribe();

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  getCheckpoints() {
    this.requestInProgress.next(true);

    this.checkpointsService.getCheckpointsForInternal(this.user_id).subscribe(response => {
      if (response['success']) {
        const arrayToDelete = [];
        response['establishments'].forEach((establishment, key) => {
          establishment.establishment_registries.forEach(registry => {
            registry.establishment_checkpoints.forEach(checkpoint => {
              if (establishment.registries) {
                const foundRegistry = establishment.registries.find(element => {
                  return element.id === registry.registry.id;
                });
                if (foundRegistry) {
                  const foundInstallation = foundRegistry.installations.find(element => {
                    return element.id === checkpoint.establishment_installation.id;
                  });
                  if (foundInstallation) {
                    delete checkpoint.establishment_installation;
                    checkpoint.checked = this.getIsCheckpointChecked(checkpoint.id, response['checkpoints']);
                    foundInstallation.checkpoints.push(checkpoint);
                    if (checkpoint.checked) {
                      foundInstallation.checked = true;
                      foundRegistry.checked = true;
                      establishment.checked = true;
                    }
                  } else {
                    checkpoint.establishment_installation.collapsed = true;
                    checkpoint.checked = this.getIsCheckpointChecked(checkpoint.id, response['checkpoints']);
                    if (checkpoint.checked) {
                      checkpoint.establishment_installation.checked = true;
                      foundRegistry.checked = true;
                      establishment.checked = true;
                    }
                    foundRegistry.installations.push(checkpoint.establishment_installation);
                    delete checkpoint.establishment_installation;
                    foundRegistry.installations[foundRegistry.installations.length - 1].checkpoints = [];
                    foundRegistry.installations[foundRegistry.installations.length - 1].checkpoints.push(checkpoint);
                  }
                } else {
                  checkpoint.establishment_installation.collapsed = true;
                  checkpoint.checked = this.getIsCheckpointChecked(checkpoint.id, response['checkpoints']);
                  if (checkpoint.checked) {
                    checkpoint.establishment_installation.checked = true;
                    registry.registry.checked = true;
                    establishment.checked = true;
                  }
                  checkpoint.establishment_installation.checkpoints = [];
                  checkpoint.establishment_installation.checkpoints.push(checkpoint);
                  registry.registry.collapsed = true;
                  registry.registry.installations = [];
                  registry.registry.installations.push(checkpoint.establishment_installation);
                  delete checkpoint.establishment_installation;
                  establishment.registries.push(registry.registry);
                }
              } else {
                checkpoint.establishment_installation.collapsed = true;
                registry.registry.collapsed = true;
                checkpoint.checked = this.getIsCheckpointChecked(checkpoint.id, response['checkpoints']);
                if (checkpoint.checked) {
                  checkpoint.establishment_installation.checked = true;
                  registry.registry.checked = true;
                  establishment.checked = true;
                }
                checkpoint.establishment_installation.checkpoints = [];
                checkpoint.establishment_installation.checkpoints.push(checkpoint);
                registry.registry.installations = [];
                registry.registry.installations.push(checkpoint.establishment_installation);
                delete checkpoint.establishment_installation;
                establishment.registries = [];
                establishment.registries.push(registry.registry);
              }
            });
          });
          establishment.collapsed = true;
          delete establishment.establishment_registries;
          if (!establishment.registries) {
            arrayToDelete.unshift(key);
          }
        });
        arrayToDelete.forEach(key => {
          response['establishments'].splice(key, 1);
        });
        this.establishments = response['establishments'];
        this.sortEstablishments();
      }
      this.requestInProgress.next(false);
    });
  }
  getUser() {
    this.requestInProgress.next(true);

    this.usersService.getMyUser(this.user_id).subscribe(response => {
      if (response['success']) {
        this.user = response['user'];
      }
      this.requestInProgress.next(false);
    });
  }
  getIsCheckpointChecked(checkpoint_id, checkpoints) {
    return !!checkpoints.find(checkpoint => {
      return checkpoint.id === checkpoint_id;
    });
  }

  updateAccess() {
    this.requestInProgress.next(true);
    this.checkpointsService.updateCheckpointsForInternal(this.user_id, this.establishments).subscribe(response => {
      if (response['success']) {
        CustomNotifierService.getSuccessUpdate(this.notifier);
        this.router.navigate(['users/mine']).then(() => {});
      } else if (response['errors']) {
        CustomNotifierService.getErrors(this.errors, this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  sortEstablishments() {
    this.establishments.sort(this.sort);
    this.establishments.forEach(establishment => {
      establishment.registries.sort(this.sort);
      establishment.registries.forEach(registry => {
        registry.installations.sort(this.sort);
        registry.installations.forEach(installation => {
          installation.checkpoints.sort(this.sort);
        });
      });
    });
  }

  calcCheckedEstablishment(key) {
    let total = 0;
    if (this.establishments.length) {
      this.establishments[key].registries.forEach(registry => {
        registry.installations.forEach(installation => {
          installation.checkpoints.forEach(checkpoint => {
            if (checkpoint.checked) {
              total++;
            }
          });
        });
      });
    }
    return total;
  }
  calcAllEstablishment(key) {
    let total = 0;
    if (this.establishments.length) {
      this.establishments[key].registries.forEach(registry => {
        registry.installations.forEach(installation => {
          total += installation.checkpoints.length;
        });
      });
    }
    return total;
  }
  toggleEstablishment(key) {
    if (this.openedEstablishment >= 0) {
      if (this.openedEstablishment === key) {
        if (this.openedRegistry >= 0) {
          if (this.openedInstallation >= 0) {
            this.establishments[key].registries[this.openedRegistry].installations[this.openedInstallation].collapsed = true;
            this.openedInstallation = -1;
          }
          this.establishments[key].registries[this.openedRegistry].collapsed = true;
          this.openedRegistry = -1;
        }
        this.establishments[key].collapsed = true;
        this.openedEstablishment = -1;
      } else {
        if (this.openedRegistry >= 0) {
          if (this.openedInstallation >= 0) {
            this.establishments[key].registries[this.openedRegistry].installations[this.openedInstallation].collapsed = true;
            this.openedInstallation = -1;
          }
          this.establishments[key].registries[this.openedRegistry].collapsed = true;
          this.openedRegistry = -1;
        }
        this.establishments[this.openedEstablishment].collapsed = true;
        this.establishments[key].collapsed = false;
        this.openedEstablishment = key;
      }
    } else {
      this.establishments[key].collapsed = false;
      this.openedEstablishment = key;
    }
  }
  checkEstablishment(keyE) {
    if (this.calcCheckedEstablishment(keyE) === this.calcAllEstablishment(keyE)) {
      this.establishments[keyE].registries.forEach(registry => {
        registry.installations.forEach(installation => {
          installation.checkpoints.forEach(checkpoint => {
            checkpoint.checked = false;
          });
        });
      });
    } else {
      this.establishments[keyE].registries.forEach(registry => {
        registry.installations.forEach(installation => {
          installation.checkpoints.forEach(checkpoint => {
            checkpoint.checked = true;
          });
        });
      });
    }
  }

  calcCheckedRegistry(keyE, keyR) {
    let total = 0;
    if (this.establishments) {
      this.establishments[keyE].registries[keyR].installations.forEach(installation => {
        installation.checkpoints.forEach(checkpoint => {
          if (checkpoint.checked) {
            total++;
          }
        });
      });
    }
    return total;
  }
  calcAllRegistry(keyE, keyR) {
    let total = 0;
    if (this.establishments) {
      this.establishments[keyE].registries[keyR].installations.forEach(installation => {
        total += installation.checkpoints.length;
      });
    }
    return total;
  }
  toggleRegistry(key) {
    if (this.openedRegistry >= 0) {
      if (this.openedRegistry === key) {
        if (this.openedInstallation >= 0) {
          this.establishments[this.openedEstablishment]
            .registries[this.openedRegistry]
            .installations[this.openedInstallation].collapsed = true;
        }
        this.establishments[this.openedEstablishment].registries[key].collapsed = true;
        this.openedRegistry = -1;
      } else {
        if (this.openedRegistry >= 0) {
          this.establishments[this.openedEstablishment].registries[this.openedRegistry].collapsed = true;
        }
        this.establishments[this.openedEstablishment].registries[key].collapsed = false;
        this.openedRegistry = key;
      }
    } else {
      this.establishments[this.openedEstablishment].registries[key].collapsed = false;
      this.openedRegistry = key;
    }
  }
  checkRegistry(keyE, keyR) {
    if (this.calcCheckedRegistry(keyE, keyR) === this.calcAllRegistry(keyE, keyR)) {
      this.establishments[keyE].registries[keyR].installations.forEach(installation => {
        installation.checkpoints.forEach(checkpoint => {
          checkpoint.checked = false;
        });
      });
    } else {
      this.establishments[keyE].registries[keyR].installations.forEach(installation => {
        installation.checkpoints.forEach(checkpoint => {
          checkpoint.checked = true;
        });
      });
    }
  }

  calcCheckedInstallation(keyE, keyR, keyI) {
    let total = 0;
    if (this.establishments) {
      this.establishments[keyE].registries[keyR].installations[keyI].checkpoints.forEach(checkpoint => {
        if (checkpoint.checked) {
          total++;
        }
      });
    }
    return total;
  }
  calcAllInstallation(keyE, keyR, keyI) {
    let total = 0;
    if (this.establishments) {
      total = this.establishments[keyE].registries[keyR].installations[keyI].checkpoints.length;
    }
    return total;
  }
  toggleInstallation(key) {
    if (this.openedInstallation >= 0) {
      if (this.openedInstallation === key) {
        this.establishments[this.openedEstablishment].registries[this.openedRegistry].installations[key].collapsed = true;
        this.openedInstallation = -1;
      } else {
        if (this.openedInstallation >= 0) {
          this.establishments[this.openedEstablishment]
            .registries[this.openedRegistry]
            .installations[this.openedInstallation].collapsed = true;
        }
        this.establishments[this.openedEstablishment].registries[this.openedRegistry].installations[key].collapsed = false;
        this.openedInstallation = key;
      }
    } else {
      this.establishments[this.openedEstablishment].registries[this.openedRegistry].installations[key].collapsed = false;
      this.openedInstallation = key;
    }
  }
  checkInstallation(keyE, keyR, keyI) {
    if (this.calcCheckedInstallation(keyE, keyR, keyI) === this.calcAllInstallation(keyE, keyR, keyI)) {
      this.establishments[keyE].registries[keyR].installations[keyI].checkpoints.forEach(checkpoint => {
        checkpoint.checked = false;
      });
    } else {
      this.establishments[keyE].registries[keyR].installations[keyI].checkpoints.forEach(checkpoint => {
        checkpoint.checked = true;
      });
    }
  }

  checkCheckpoint(keyE, keyR, keyI, keyC) {
    const checked = this.establishments[keyE].registries[keyR].installations[keyI].checkpoints[keyC].checked;
    this.establishments[keyE].registries[keyR].installations[keyI].checkpoints[keyC].checked = !checked;
  }
  getFrequencyText(frequency, number) {
    if (frequencies[frequency] && frequencies[frequency][number]) {
      return frequencies[frequency][number];
    } else {
      if (frequency === 'year') {
        if (number > 1) {
          return number + ' ans';
        } else {
          return number + ' an';
        }
      } else if (frequency === 'month') {
        return number + ' mois';
      } else if (frequency === 'week') {
        if (number > 1) {
          return number + ' semaines';
        } else {
          return number + ' semaine';
        }
      } else if (frequency === 'day') {
        if (number > 1) {
          return number + ' jours';
        } else {
          return number + ' jour';
        }
      }
    }
  }
  createBreadcrumbs() {
    this.pageName = 'Points de contrôle';

    this.previousPageName = [];
    this.previousPageRoute = [];

    this.previousPageName.push('Tableau de bord');
    this.previousPageRoute.push('/');

    this.previousPageName.push('Utilisateurs');
    this.previousPageRoute.push('/users/mine');
  }

  sort = (a, b) => {
    if (a.checked && !b.checked) {
      return -1;
    }
    if (!a.checked && b.checked) {
      return 1;
    } else {
      if (a.installations) {
        if (a.name > b.name) {
          return -1;
        }
        if (a.name < b.name) {
          return 1;
        }
      } else if (a.checkpoints) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
      } else {
        const name_a = (a.initial_name ? a.initial_name + ' - ' : '') +
          this.getFrequencyText(a.frequency, a.frequency_duration) +
          (a.name && ' - ' + a.name);
        const name_b = (b.initial_name ? b.initial_name + ' - ' : '') +
          this.getFrequencyText(b.frequency, b.frequency_duration) +
          (b.name && ' - ' + b.name);
        if (name_a < name_b) {
          return -1;
        }
        if (name_a > name_b) {
          return 1;
        }
      }
    }
    return 0;
  }
}
