import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs';

import { AuthService } from '../../../core/auth/auth.service';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { EnterprisesRequestService } from '../../../services/enterprises.request.service';

import Contracts from '../../../../shared/interfaces/contracts';
import { EstablishmentsRequestService } from '../../../services/establishments.request.service';
import { RegistriesRequestService } from '../../../services/registries.request.service';
import Establishment from '../../../../shared/interfaces/establishment';
import Registry from '../../../../shared/interfaces/registry';
import Enterprise from '../../../../shared/interfaces/enterprise';

@Component({
  selector: 'app-all-provider-contracts',
  templateUrl: './all-provider-contracts.component.html',
  styleUrls: ['./all-provider-contracts.component.scss']
})
export class AllProviderContractsComponent implements OnInit, AfterViewInit, OnDestroy {

  requestInProgress = new Subject();
  requestCount = 0;

  changeEnterprise: Subscription;

  canCreate = false;
  canUpdate = false;

  contracts: Contracts[] = [];
  establishment: Establishment;
  registry: Registry;
  provider: Enterprise;

  pageName: string;
  previousPageName: string[] = [];
  previousPageRoute: string[] = [];

  currentRoute = '';

  count = 0;
  pages = 1;

  search = '';
  sort = 'ProviderContracts.end_date';
  order = 'desc';
  limit = 12;
  page = 1;

  errors: {};

  timeout: any;
  loader = false;

  modalConfirmContract = null;

  constructor(
    private authService: AuthService,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private registriesServices: RegistriesRequestService,
    private enterprisesService: EnterprisesRequestService,
    private establishmentsService: EstablishmentsRequestService,
  ) {}

  ngOnInit() {

    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loader = this.requestCount > 0;
    });

    this.changeEnterprise = this.authService.getEnterprise().subscribe(() => {
      this.getProviderContracts();
    });

    this.currentRoute = '/' + this.activatedRoute.snapshot.url.join('/') + '/';
    this.pageName = 'Documents';

    if (this.authService.loaded) {
      this.getProviderContracts();
    }

    if (this.activatedRoute.snapshot.params.registry_id && this.activatedRoute.snapshot.params.establishment_id &&
      this.activatedRoute.snapshot.params.enterprise_id) {
      this.getEstablishment();
      this.getRegistry();
      this.getProvider();
    }
    this.createBreadcrumbs();
  }

  ngAfterViewInit() {
    this.modalConfirmContract.eventOnClose.subscribe(event => {
      if (event) {
        let request;

        request = this.enterprisesService.deleteProviderContract(
          this.activatedRoute.snapshot.params.enterprise_id,
          event
        );

        request.subscribe(response => {
          if (response['success']) {
            this.refreshData();
            CustomNotifierService.getSuccessDelete(this.notifier);
          } else if (response['errors']) {
            CustomNotifierService.getErrors(response['errors'], this.notifier);
          }
        });
      }
    });
  }

  ngOnDestroy() {}

  searchData() {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.page = 1;
      this.refreshData();
    }, 500);
  }

  refreshData(sort = this.sort, order = this.order, page = this.page) {
    this.sort = sort;
    this.order = order;
    this.page = page;
    this.getProviderContracts();
  }

  getEstablishment() {
    this.requestInProgress.next(true);
    this.establishmentsService.getMyEstablishment(this.activatedRoute.snapshot.params.establishment_id).subscribe(response => {
      if (response['success']) {
        this.establishment = response['establishment'];
        this.createBreadcrumbs();
      } else if (response['errors']) {
        this.errors = response['errors'];
      }
      this.requestInProgress.next(false);
    });
  }

  getRegistry() {
    this.requestInProgress.next(true);
    this.registriesServices.getRegistry(this.activatedRoute.snapshot.params.registry_id,
      this.activatedRoute.snapshot.params.establishment_id).subscribe(response => {
      if (response['success']) {
        this.registry = response['registry'];
        this.createBreadcrumbs();
      } else if (response['errors']) {
        this.errors = response['errors'];
      }
      this.requestInProgress.next(false);
    });
  }
  getProvider() {
    this.requestInProgress.next(true);
    this.enterprisesService.getProvider(this.activatedRoute.snapshot.params.enterprise_id).subscribe(response => {
      if (response['success']) {
        this.provider = response['enterprise'];
        this.createBreadcrumbs();
      } else if (response['errors']) {
        this.errors = response['errors'];
      }
      this.requestInProgress.next(false);
    });
  }

  getProviderContracts() {
    this.requestInProgress.next(true);
    let request;

    request = this.enterprisesService.getProviderContracts(
      this.search, this.sort, this.order, this.limit, this.page,
      this.activatedRoute.snapshot.params.enterprise_id,
    );

    request.subscribe(response => {
      if (response['success']) {
        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];

        this.contracts = response['contracts'];

        this.count = response['count'];
        this.pages = response['pages'];
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  downloadFile(contract_id, file_name) {
    let request;

    request = this.enterprisesService.downloadProviderContractFile(
      this.activatedRoute.snapshot.params.enterprise_id,
      contract_id
    );

    request.subscribe((response: BufferSource) => {
      try {
        const decoder = new TextDecoder('utf-8');
        const domString = decoder.decode(response);
        const json = JSON.parse(domString);

        CustomNotifierService.getErrors(json['errors'], this.notifier);
      } catch (e) {
        const blob = new Blob([response]);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = file_name;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      }
    });
  }

  filter(property) {
    if (this.sort === property) {
      if (this.order === 'asc') {
        this.order = 'desc';
      } else {
        this.order = 'asc';
      }
    } else {
      this.sort = property;
      this.order = 'asc';
    }

    this.getProviderContracts();
  }

  createBreadcrumbs() {
    if (this.activatedRoute.snapshot.params.registry_id && this.activatedRoute.snapshot.params.establishment_id &&
      this.activatedRoute.snapshot.params.enterprise_id) {
      this.previousPageName = [];
      this.previousPageRoute = [];

      this.previousPageName.push('Tableau de bord');
      this.previousPageRoute.push('/');

      this.previousPageName.push(this.registry ? this.registry.name : 'Tableau de bord registre');
      this.previousPageRoute.push('/dashboard-registry/' + this.activatedRoute.snapshot.params.registry_id);

      this.previousPageName.push(this.establishment ? this.establishment.name : 'Tableau de bord établissement');
      this.previousPageRoute.push('/dashboard-registry/' + this.activatedRoute.snapshot.params.registry_id +
        '/establishment/' + this.activatedRoute.snapshot.params.establishment_id);

      this.previousPageName.push(this.provider ? this.provider.name : 'Tableau de bord prestataire');
      this.previousPageRoute.push('/dashboard-registry/' + this.activatedRoute.snapshot.params.registry_id +
        '/establishment/' + this.activatedRoute.snapshot.params.establishment_id + '/providers');
    } else {
      this.previousPageName = [];
      this.previousPageRoute = [];

      this.previousPageName.push('Tableau de bord');
      this.previousPageRoute.push('/');

      this.previousPageName.push('Prestataires');
      this.previousPageRoute.push('/providers');
    }
  }

  dispatchPagination(data) {
    if (data.action === 'total_elements') {
      this.refreshPage(data.value);
    } else if (data.action === 'change_page') {
      this.changePage(data.value);
    }
  }

  refreshPage(limit) {
    this.limit = limit;
    this.page = 1;
    this.refreshData();
  }

  changePage(page) {
    if (page >= 1 && page <= this.pages) {
      this.page = page;
      this.getProviderContracts();
    }
  }

  bindModalConfirmContract(modal) {
    this.modalConfirmContract = modal;
  }
}
