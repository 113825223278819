import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { HttpClient } from '@angular/common/http';
import { RequestService } from './request.service';

@Injectable()
export class SanitaryOperationsRequestService extends RequestService {
  constructor(
    protected http: HttpClient,
    protected notifier: NotifierService
  ) {
    super(http, notifier);
  }

  /// GET ALL
  public getAllSanitaryOperation(search, sort, order, limit, page, registry_id, establishment_id) {
    return this.get( 'registry/' + registry_id + '/establishment/' + establishment_id + '/sanitary-operations?' +
      'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  /// GET ONE
  public getSanitaryOperation(registry_id, establishment_id, sanitaryOperationId) {
    return this.get('registry/' + registry_id + '/establishment/' + establishment_id +
        '/sanitary-operation/' + sanitaryOperationId);
  }
  /// CREATE
  public createSanitaryOperation(registry_id, establishment_id, sanitaryOperation) {
    return this.post('registry/' + registry_id + '/establishment/' + establishment_id +
        '/sanitary-operation', { sanitaryOperation });
  }
  /// UPDATE
  public updateSanitaryOperation(registry_id, establishment_id, sanitaryOperationId, sanitaryOperation) {
    return this.put('registry/' + registry_id + '/establishment/' + establishment_id +
        '/sanitary-operation/' + sanitaryOperationId,
      { sanitaryOperation });
  }
  /// DELETE
  public deleteSanitaryOperation(registry_id, establishment_id, sanitaryOperationId) {
    return this.delete('registry/' + registry_id + '/establishment/' + establishment_id +
        '/sanitary-operation/' + sanitaryOperationId);
  }
}
