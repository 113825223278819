import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { ToolbarNotificationComponent } from './toolbar-notification.component';
import { NotificationService } from '../../services/notification.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule
  ],
  providers: [NotificationService],
  declarations: [ToolbarNotificationComponent],
  exports: [ToolbarNotificationComponent]
})

export class ToolbarNotificationModule {}
