import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';

import { AllWorksheetsComponent } from '../all-worksheets/all-worksheets.component';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { WorksheetsRequestService } from '../../../services/worksheets.request.service';

declare function Flatpickr(): any;

@Component({
  selector: 'app-update-worksheet',
  templateUrl: './update-worksheet.component.html',
  styleUrls: ['./update-worksheet.component.scss']
})
export class UpdateWorksheetComponent implements OnInit, AfterViewInit, OnDestroy {
  canCreate = false;
  canUpdate = false;

  worksheetForm: FormGroup;
  id = new FormControl('');
  establishment_registry_id = new FormControl('');
  operation_name = new FormControl('', Validators.required);
  receipt_work_date = new FormControl(null);
  uploaded_on_date = new FormControl(null);
  accepted_on_date = new FormControl(null);
  declaration_commitment_date = new FormControl(null);
  nature_of_work = new FormControl(null);
  project_manager = new FormControl(null);
  technical_controller = new FormControl(null);
  project_manager_referent = new FormControl(null);
  enterprises = new FormControl(null);
  comment_worksheet = new FormControl(null);

  errors = {};

  isNew = true;
  loader = false;
  loaderContent = false;

  modalWorksheet = null;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private allWorksheets: AllWorksheetsComponent,
    private worksheetsService: WorksheetsRequestService
  ) {}

  ngOnInit() {
    this.worksheetForm = this.formBuilder.group({
      id: this.id,
      establishment_registry_id: this.establishment_registry_id,
      operation_name: this.operation_name,
      receipt_work_date: this.receipt_work_date,
      uploaded_on_date: this.uploaded_on_date,
      accepted_on_date: this.accepted_on_date,
      declaration_commitment_date: this.declaration_commitment_date,
      nature_of_work: this.nature_of_work,
      project_manager: this.project_manager,
      technical_controller: this.technical_controller,
      project_manager_referent: this.project_manager_referent,
      enterprises: this.enterprises,
      comment_worksheet: this.comment_worksheet
    });

    if (this.activatedRoute.snapshot.params.worksheet_id) {
      this.isNew = false;
      this.getWorksheet();
    } else {
      Flatpickr();
    }
  }

  ngAfterViewInit() {
    this.modalWorksheet.open();
  }

  ngOnDestroy() {
    const flatPicker = document.getElementsByClassName('flatpickr-calendar') as HTMLCollection;
    for (let i = flatPicker.length; i >= 0; i--) {
      if (flatPicker[i]) {
        flatPicker[i].remove();
      }
    }

    this.modalWorksheet.close();
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  getWorksheet() {
    let request;
    this.loaderContent = true;

    request = this.worksheetsService.getWorksheet(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.activatedRoute.snapshot.params.worksheet_id
    );

    request.subscribe(response => {
      if (response['success']) {
        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];

        this.worksheetForm.patchValue(response['worksheet']);

        if (!this.canUpdate) {
          this.worksheetForm.disable();
        }
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      Flatpickr();
      this.loaderContent = false;
    });
  }

  createWorksheet() {
    let request;
    this.errors = {};
    this.loader = true;

    request = this.worksheetsService.createWorksheet(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.worksheetForm.value
    );

    request.subscribe(response => {
      if (response['success']) {
        this.modalWorksheet.close();
        this.router.navigate([
          'dashboard-registry/' + this.activatedRoute.snapshot.parent.params.registry_id +
          '/establishment/' + this.activatedRoute.snapshot.parent.params.establishment_id +
          '/worksheet/' + response['worksheet']['id']
        ]).then(() => {});
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loader = false;
    });
  }

  updateWorksheet() {
    let request;
    this.errors = {};
    this.loader = true;

    request = this.worksheetsService.updateWorksheet(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.activatedRoute.snapshot.params.worksheet_id,
      this.worksheetForm.value
    );

    request.subscribe(response => {
      if (response['success']) {
        this.modalWorksheet.close();
        CustomNotifierService.getSuccessUpdate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loader = false;
    });
  }

  bindModal(modal) {
    this.modalWorksheet = modal;
  }

  closeModal() {
    this.allWorksheets.refreshData();
    this.router.navigate([this.activatedRoute.snapshot.parent.url.join('/')]).then(() => {});
  }
}
