<div class="main-content">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col">
        <div class="header mb-0">
          <div class="header-body no-border">
            <div class="row align-items-center">
              <div class="col">
                <h1 class="header-title">{{ view === 'providers' ? 'Prestataires' : 'Entreprises' }}</h1>

                <small class="form-text text-muted">{{ view === 'providers' ? 'Liste de mes prestataires' : 'Liste des entreprises de la plateforme' }}</small>

                <app-breadcrumbs [currentPage]="pageName" [names]="previousPageName" [routes]="previousPageRoute"></app-breadcrumbs>
              </div>

              <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0" *ngIf="canCreate">
                <a class="btn btn-primary d-block d-md-inline-block" [routerLink]="'transfer'" *ngIf="view === 'providers'">
                  {{ 'Transfert de droits' }}
                </a>
              </div>

              <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0" *ngIf="canCreate">
                <a class="btn btn-primary d-block d-md-inline-block" [routerLink]="[createRoute]">
                  {{ view === 'providers' ? 'Ajouter un prestataire' : 'Ajouter une entreprise' }}
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col">
            <app-searchbar [(ngModel)]="search" (keyup)="searchData()" ngDefaultControl></app-searchbar>
          </div>

          <div class="col-auto">
            <div class="nav btn-group" aria-label="Liste d'actions pour l'affichage des entreprises" role="tablist">
              <button class="btn btn-lg btn-white tooltipHtml" data-placement="bottom" role="tab" aria-label="Affichage mosaïque" title="Mosaïque" [ngClass]="{ 'active': activeTabsList === 'tabPaneOne'}" (click)="changeActiveTabsList('tabPaneOne')">
                <span class="fe fe-grid"></span>
              </button>

              <button class="btn btn-lg btn-white tooltipHtml" data-placement="bottom" role="tab" aria-label="Affichage sous forme de liste" title="Liste" [ngClass]="{ 'active': activeTabsList === 'tabPaneTwo'}" (click)="changeActiveTabsList('tabPaneTwo')">
                <span class="fe fe-list"></span>
              </button>

              <button class="btn btn-lg btn-white tooltipHtml border-right-radius" role="tab" aria-label="Affichage des filtres" data-toggle="dropdown" data-placement="bottom" title="Filtre">
                <span class="fe fe-filter"></span>
              </button>
              <span class="dropdown-menu dropdown-menu-right">
                <a class="dropdown-item href" (click)="refreshData('Enterprises.name', 'asc', 1)" aria-label="Tri des établissements : Nom - Ordre croissant">
                  Nom A-Z
                </a>

                <a class="dropdown-item href" (click)="refreshData('Enterprises.name', 'desc', 1)" aria-label="Tri des établissements : Nom - Ordre décroissant">
                  Nom Z-A
                </a>

                <a class="dropdown-item href" (click)="refreshData('Enterprises.zipcode', 'asc', 1)" aria-label="Tri des établissements : Code postal - Ordre croissant">
                  Code postal A-Z
                </a>

                <a class="dropdown-item href" (click)="refreshData('Enterprises.zipcode', 'desc', 1)" aria-label="Tri des établissements : Code postal - Ordre décroissant">
                  Code postal Z-A
                </a>

                <a class="dropdown-item href" (click)="refreshData('Enterprises.city', 'asc', 1)" aria-label="Tri des établissements : Ville - Ordre croissant">
                  Ville A-Z
                </a>

                <a class="dropdown-item href" (click)="refreshData('Enterprises.city', 'desc', 1)" aria-label="Tri des établissements : Ville - Ordre décroissant">
                  Ville Z-A
                </a>
              </span>
            </div>
          </div>
        </div>

        <div class="is-loading loader-content" *ngIf="loader"></div>
        <!-- MODE CARD -->
        <div class="tab-content" [hidden]="loader || !enterprises.length">
          <div class="tab-pane fade" id="tabPaneOne" role="tabpanel" [ngClass]="{ 'show active': activeTabsList === 'tabPaneOne'}">
            <div class="row listAlias">
              <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 d-flex flex-column" *ngFor="let enterprise of enterprises" [attr.aria-label]="'Entreprise : ' + enterprise.name">
                <div class="card flex-fill">
                  <span class="card-img-top card-img-center" [title]="'Photo de l\'entreprise : ' + enterprise.name" [attr.aria-label]="'Photo de l\'entreprise : ' + enterprise.name" [style.backgroundImage]="'url(' + environment.SERVER_HOST + 'img/enterprises/' + enterprise.picture + ')'" *ngIf="enterprise.picture != '' && enterprise.picture != null"></span>

                  <span class="card-img-top card-img-center" [title]="'Photo de l\'entreprise : ' + enterprise.name" [attr.aria-label]="'Photo de l\'entreprise : ' + enterprise.name" [style.background-image]="'url(../assets/img/establishments/building.png)'" *ngIf="enterprise.picture == '' || enterprise.picture == null"></span>

                  <div class="card-body">
                    <div class="row align-items-center">
                      <div class="circle tooltipHtml" data-placement="bottom"
                           [ngClass]="[getAccess(enterprise), enterprise.my_enterprises[0].access_to_enterprise ? 'cursor' : '']" *ngIf="view === 'providers'">
                      </div>

                      <div class="col overflow-hidden">
                        <h4 class="card-title name text-truncate mb-2">
                          <a [routerLink]="[updateRoute + enterprise.id]" *ngIf="view !== 'providers'" [attr.aria-label]="'Nom de l\'entreprise : ' + enterprise.name">
                            {{ enterprise.name }}
                          </a>

                          <a [routerLink]="[updateRoute + enterprise.id + '/users']" *ngIf="view === 'providers'" [attr.aria-label]="'Nom de l\'entreprise : ' + enterprise.name">
                            {{ enterprise.name }}
                          </a>
                        </h4>

                        <p class="card-text small text-muted text-truncate mb-2">
                          <span *ngIf="enterprise.address != null && enterprise.zipcode != null && enterprise.city != null" [attr.aria-label]="'Adresse de l\'entreprise : ' + enterprise.address + ' ' + enterprise.zipcode + ' ' + enterprise.city">
                            {{ enterprise.address }} <br>
                            {{ enterprise.zipcode + ' ' + enterprise.city }}
                          </span>

                          <span *ngIf="enterprise.address == null || enterprise.zipcode == null || enterprise.city == null">
                            Aucune adresse renseignée par l'entreprise
                          </span>
                        </p>

                        <p class="card-text small text-muted text-truncate mb-0" *ngIf="enterprise.my_enterprises">
                          {{ enterprise.my_enterprises[0].last_date?.end_date ? 'Échéance : ' + (enterprise.my_enterprises[0].last_date?.end_date | date: 'dd/MM/yyyy') : '' }}
                        </p>
                      </div>

                      <div class="col-auto">
                        <div class="dropdown">
                          <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fe fe-more-vertical"></i>
                          </a>

                          <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item href" [routerLink]="[updateRoute + enterprise.id + '/users']">
                              Liste des utilisateurs
                            </a>

                            <a class="dropdown-item href" [routerLink]="[updateRoute + enterprise.id + '/establishments/active']" *ngIf="view === 'enterprises'">
                              Liste des établissements
                            </a>

                            <a class="dropdown-item href" [routerLink]="[updateRoute + enterprise.id + '/establishments/archive']" *ngIf="view === 'enterprises'">
                              Liste des établissements archivés
                            </a>

                            <a class="dropdown-item href" [routerLink]="[updateRoute + enterprise.id + '/formations']" *ngIf="view === 'enterprises'">
                              Liste des formations
                            </a>

                            <a class="dropdown-item href" [routerLink]="[updateRoute + enterprise.id + '/subscription']" *ngIf="view === 'enterprises'">
                              Éditer l'abonnement
                            </a>

                            <a class="dropdown-item href" [routerLink]="[updateRoute + enterprise.id + '/contracts']" *ngIf="view === 'providers'">
                              Consulter les documents
                            </a>

                            <a class="dropdown-item href" (click)="modalConfirmProvider.open(enterprise.id)" *ngIf="view === 'providers' && canUpdate">
                              Retirer ce prestataire
                            </a>

                            <a class="dropdown-item href" [routerLink]="[updateRoute + enterprise.id]">
                              {{ canUpdate ? 'Éditer l\'entreprise' : 'Consulter l\'entreprise' }}
                            </a>

                            <a class="dropdown-item href" [routerLink]="[updateRoute + enterprise.id + '/logs']" *ngIf="canLog">
                              Historique des actions
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- MODE TABLEAU -->
          <div class="tab-pane fade" id="tabPaneTwo" role="tabpanel" [ngClass]="{ 'show active': activeTabsList === 'tabPaneTwo'}">
            <div class="row list">
              <div class="col-12">
                <div class="card">
                  <div class="card-body">
                    <div class="table-responsive">
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th class="href" (click)="filter('Enterprises.name')">
                              <a class="text-muted">Société</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'Enterprises.name' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'Enterprises.name' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'Enterprises.name'"></span>
                            </th>
                            <th class="href" (click)="filter('Enterprises.siret')">
                              <a class="text-muted">Siret</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'Enterprises.siret' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'Enterprises.siret' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'Enterprises.siret'"></span>
                            </th>
                            <th *ngIf="view !== 'providers'">Adresse</th>
                            <th class="href" (click)="filter('Providers.qualification')" *ngIf="view === 'providers'">
                              <a class="text-muted">Qualification</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'Providers.qualification' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'Providers.qualification' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'Providers.qualification'"></span>
                            </th>
                            <th class="href" (click)="filter('Enterprises.name')" *ngIf="view === 'providers'">
                              <a class="text-muted">Date échéance</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'Enterprises.name' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'Enterprises.name' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'Enterprises.name'"></span>
                            </th>
                            <th class="href" (click)="filter('UserEnterprises.access_to_enterprise')" *ngIf="view === 'providers'">
                              <a class="text-muted">Donner un accès à BatiRegistre</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'UserEnterprises.access_to_enterprise' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'UserEnterprises.access_to_enterprise' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'UserEnterprises.access_to_enterprise'"></span>
                            </th>
                            <th class="href" *ngIf="view === 'providers'">
                              <a class="text-muted">Documents</a>
                            </th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="dropdown" *ngFor="let enterprise of enterprises">
                            <td>
                              <a class="href" [routerLink]="[view !== 'providers' ? updateRoute + enterprise.id : updateRoute + enterprise.id + '/users']">
                                {{ enterprise.name }}
                              </a>
                            </td>
                            <td [attr.aria-label]="enterprise.siret ? 'Siret de l\'entreprise : ' + enterprise.siret : 'Aucun siret de défini pour l\'entreprise'">{{ enterprise.siret }}</td>
                            <td *ngIf="view !== 'providers'">
                              <span *ngIf="enterprise.address != null && enterprise.zipcode != null && enterprise.city != null" [attr.aria-label]="'Adresse de l\'entreprise : ' + enterprise.address + ' ' + enterprise.zipcode + ' ' + enterprise.city">
                                {{ enterprise.address }} <br>
                                {{ enterprise.zipcode + ' ' + enterprise.city }}
                              </span>

                              <span *ngIf="enterprise.address == null || enterprise.zipcode == null || enterprise.city == null">
                                Aucune adresse renseignée par l'entreprise
                              </span>
                            </td>
                            <td *ngIf="view === 'providers'">{{ enterprise.my_enterprises[0].qualification }}</td>
                            <td *ngIf="view === 'providers'" [ngClass]="{ 'is-finished': enterprise.my_enterprises[0].last_date?.end_date ? currentDate.isAfter(enterprise.my_enterprises[0].last_date?.end_date) : false }">
                              {{ enterprise.my_enterprises[0].last_date?.end_date ? (enterprise.my_enterprises[0].last_date?.end_date | date: 'dd/MM/yyyy') : '-' }}
                            </td>
                            <td *ngIf="view === 'providers'">
                              <div class="circleList tooltipHtml" data-placement="bottom"
                                   [ngClass]="[getAccess(enterprise), enterprise.my_enterprises[0].access_to_enterprise ? 'cursor' : '']">
                              </div>
                            </td>
                            <td *ngIf="view === 'providers'">
                              <div class="btn-group">
                                <a class="btn btn-sm btn-white" [routerLink]="[updateRoute + enterprise.id + '/contracts']">
                                  Consulter les documents
                                </a>
                              </div>
                            </td>
                            <td class="d-flex flex-row-reverse">
                              <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fe fe-more-vertical"></i>
                              </a>

                              <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item href" [routerLink]="[updateRoute + enterprise.id + '/users']">
                                  Liste des utilisateurs
                                </a>

                                <a class="dropdown-item href" [routerLink]="[updateRoute + enterprise.id + '/establishments/active']" *ngIf="view === 'enterprises'">
                                  Liste des établissements
                                </a>

                                <a class="dropdown-item href" [routerLink]="[updateRoute + enterprise.id + '/establishments/archive']" *ngIf="view === 'enterprises'">
                                  Liste des établissements archivés
                                </a>

                                <a class="dropdown-item href" [routerLink]="[updateRoute + enterprise.id + '/formations']" *ngIf="view === 'enterprises'">
                                  Liste des formations
                                </a>

                                <a class="dropdown-item href" [routerLink]="[updateRoute + enterprise.id + '/subscription']" *ngIf="view === 'enterprises'">
                                  Éditer l'abonnement
                                </a>

                                <a class="dropdown-item href" [routerLink]="[updateRoute + enterprise.id + '/contracts']" *ngIf="view === 'providers'">
                                  Consulter les documents
                                </a>

                                <a class="dropdown-item href" (click)="modalConfirmProvider.open(enterprise.id)" *ngIf="view === 'providers' && canUpdate">
                                  Retirer ce prestataire
                                </a>

                                <a class="dropdown-item href" [routerLink]="[updateRoute + enterprise.id]">
                                  {{ canUpdate ? 'Éditer l\'entreprise' : 'Consulter l\'entreprise' }}
                                </a>

                                <a class="dropdown-item href" [routerLink]="[updateRoute + enterprise.id + '/logs']" *ngIf="canLog">
                                  Historique des actions
                                </a>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row pagination-content" aria-label="Pagination">
            <div class="col-12 d-flex flex-column">
              <div class="card">
                <app-pagination (returnEvent)="dispatchPagination($event)"
                                [classes]="'card-body'"
                                [count_elements]="count"
                                [current_page]="page"
                                [elements_length]="enterprises.length"
                                [limit]="limit"
                                [total_pages]="pages"
                                ngDefaultControl>
                </app-pagination>
              </div>
            </div>
          </div>
        </div>

        <div class="loader-content" *ngIf="!loader && !enterprises.length">
          <p>Aucun résultat</p>
        </div>
      </div>
    </div>
  </div>
</div>

<app-confirm-modal [type]="'delete_provider'" #confirmProvider>{{ bindModalConfirmProvider(confirmProvider) }}</app-confirm-modal>

<router-outlet></router-outlet>
