import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { HttpClient } from '@angular/common/http';
import { RequestService } from './request.service';

@Injectable()
export class OfficialsRequestService extends RequestService {
  constructor(
    protected http: HttpClient,
    protected notifier: NotifierService
  ) {
    super(http, notifier);
  }

  /// GET
  public getAllOfficials(search, sort, order, limit, page, registry_id, establishment_id, is_child = false) {
    return this.get( 'dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/officials?' +
      'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getOfficial(registry_id, establishment_id, official_id) {
    return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/officials/' + official_id);
  }
  public getFiles(search, sort, order, limit, page, registry_id, establishment_id, official_id) {
    return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/official/' + official_id +
      '/files?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getFile(registry_id, establishment_id, official_id, file_id) {
    return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/official/' + official_id +
      '/files/' + file_id);
  }

  /// CREATE
  public createOfficial(registry_id, establishment_id, official) {
    return this.post('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/officials', { official });
  }
  public createFile(registry_id, establishment_id, official_id, file) {
    return this.post('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/official/' + official_id + '/files',
      { file: file });
  }

  /// UPDATE
  public updateOfficial(registry_id, establishment_id, official_id, official) {
    return this.put('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/officials/' + official_id,
      { official });
  }
  public updateOfficialDate(registry_id, establishment_id, official) {
    return this.put('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/officials', { official });
  }
  public updateFile(registry_id, establishment_id, official_id, file_id, file) {
    return this.put('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/official/' + official_id +
      '/files/' + file_id, { file: file });
  }

  /// DELETE
  public deleteOfficial(registry_id, establishment_id, official_id) {
    return this.delete('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/officials/' + official_id);
  }
  public deleteFile(registry_id, establishment_id, official_id, file_id) {
    return this.delete('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/official/' + official_id +
      '/files/' + file_id);
  }

  /// DOWNLOAD
  public downloadFile(registry_id, establishment_id, official_id, file_id) {
    return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/official/' + official_id +
      '/files/' + file_id + '/download', { responseType: 'arraybuffer' });
  }
}
