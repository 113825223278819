import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { HttpClient } from '@angular/common/http';
import { RequestService } from './request.service';

@Injectable()
export class SanitaryFramesRequestService extends RequestService {
  constructor(
    protected http: HttpClient,
    protected notifier: NotifierService
  ) {
    super(http, notifier);
  }

  /// GET ALL
  public getAllSanitaryFrames(search, sort, order, limit, page, registry_id, establishment_id) {
    return this.get( 'registry/' + registry_id + '/establishment/' + establishment_id + '/sanitary-frames?' +
      'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  /// GET ONE
  public getSanitaryFrame(registry_id, establishment_id, sanitaryFrameId) {
    return this.get('registry/' + registry_id + '/establishment/' + establishment_id + '/sanitary-frame/' + sanitaryFrameId);
  }
  /// CREATE
  public createSanitaryFrame(registry_id, establishment_id, sanitaryFrame) {
    return this.post('registry/' + registry_id + '/establishment/' + establishment_id + '/sanitary-frame', { sanitaryFrame });
  }
  /// UPDATE
  public updateSanitaryFrame(registry_id, establishment_id, sanitaryFrameId, sanitaryFrame) {
    return this.put('registry/' + registry_id + '/establishment/' + establishment_id + '/sanitary-frame/' + sanitaryFrameId,
      { sanitaryFrame });
  }
  /// DELETE
  public deleteSanitaryFrame(registry_id, establishment_id, sanitaryFrameId) {
    return this.delete('registry/' + registry_id + '/establishment/' + establishment_id +
        '/sanitary-frame/' + sanitaryFrameId);
  }
}
