import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SidenavComponent } from './sidenav.component';
import { ToolbarNotificationModule } from '../toolbar-notification/toolbar-notification.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ToolbarNotificationModule
  ],
  declarations: [SidenavComponent],
  exports: [SidenavComponent]
})

export class SidenavModule {}
