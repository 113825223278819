<div class="main-content">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col">
        <div class="header mb-0">
          <div class="header-body no-border">
            <div class="row align-items-center">
              <div class="col">
                <h1 class="header-title">
                  Notifications</h1>
                <small class="form-text text-muted">
                  Liste des notifications
                </small>
                <app-breadcrumbs [currentPage]="pageName" [names]="previousPageName" [routes]="previousPageRoute"></app-breadcrumbs>
              </div>
              <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0">
                <div class="btn btn-primary d-block d-md-inline-block" (click)="markAsReadAll()">
                  Tout marquer comme lu
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col">
            <app-searchbar [(ngModel)]="search" (keyup)="searchData()" ngDefaultControl></app-searchbar>
          </div>
        </div>

        <div class="is-loading loader-content" *ngIf="loader"></div>

        <div class="tab-content" *ngIf="!loader && notificationService.allNotification.length">
          <div class="tab-pane fade active show" id="tabPaneOne" role="tabpanel">
            <div class="row listAlias">
              <div class="col-12 d-flex flex-column">
                <div class="card flex-fill">
                  <div class="card-body">
                    <div class="table-responsive">
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th>
                              <a class="text-muted">Message</a>
                            </th>
                            <th>
                              <a class="text-muted">Lien vers la notification</a>
                            </th>
                            <th>
                              <a class="text-muted">Date de récéption</a>
                            </th>
                            <th>
                              <a class="text-muted">Lu</a>
                            </th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let notification of notificationService.allNotification">
                            <td>
                              <div [ngClass]="{ 'strong': !notification['if_read']}">
                                  {{ notification['title'] }}
                              </div>
                              <div [ngClass]="{ 'strong': !notification['if_read']}" [innerHTML]="notification['body']"></div>
                            </td>
                            <td width="200" *ngIf="notification['url'] && notification['url'].includes('/')">
                              <div class="btn-group">
                                <a class="btn btn-sm btn-white" target="_blank" [href]="notification['url']" *ngIf="!notification['if_read']" (click)="markAsRead(notification['id'])">
                                  Voir la notification
                                </a>
                                <a class="btn btn-sm btn-white" target="_blank" [href]="notification['url']" *ngIf="notification['if_read']">
                                  Voir la notification
                                </a>
                              </div>
                            </td>
                            <td width="200" *ngIf="notification['url'] && !notification['url'].includes('/')">
                              <div class="btn-group" *ngIf="notification['url']">
                                <a class="btn btn-sm btn-white" id="{{ notification.id }}" (click)="downloadInterventionPdf(notification['url'], notification.id)">
                                  Téléchargement du B.I.
                                </a>
                              </div>
                            </td>
                            <td width="200" *ngIf="!notification['url']"></td>
                            <td>
                              <div>
                                {{ notification['created'] | date : 'dd/MM/yyyy' }}
                              </div>
                            </td>
                            <td width="20">
                              <span class="label" [ngClass]="{'label-danger': notification['if_read'] === false, 'label-success': notification['if_read'] === true }">
                                {{ notification['if_read'] ? 'Oui' : 'Non' }}
                              </span>
                            </td>
                            <td width="20">
                              <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fe fe-more-vertical"></i>
                              </a>

                              <div class="dropdown-menu dropdown-menu-right">
                                <div class="dropdown-item href" (click)="markAsRead(notification['id'])" *ngIf="!notification['if_read']">
                                  Marquer comme lu
                                </div>
                                <div class="dropdown-item href" (click)="deleteNotification(notification['id'])">
                                  Supprimer la notification
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <app-pagination (returnEvent)="dispatchPagination($event)"
                                    [count_elements]="count"
                                    [current_page]="page"
                                    [elements_length]="notificationService.allNotification.length"
                                    [limit]="limit"
                                    [total_pages]="pages"
                                    ngDefaultControl>
                    </app-pagination>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>

        <div class="loader-content" *ngIf="!loader && !notificationService.allNotification.length">
          <p>Aucun résultat</p>
        </div>
      </div>
    </div>
  </div>
</div>


<router-outlet></router-outlet>
