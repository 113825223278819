import { Routes } from '@angular/router';

import { AllFaqComponent } from './all-faq/all-faq.component';
import { UpdateFaqComponent } from './update-faq/update-faq.component';

export const faqRoutes: Routes = [
  // PUBLIC FAQ
  {
    path: 'faq/public',
    component: AllFaqComponent
  },
  // ADMIN FAQ
  {
    path: 'faq',
    component: AllFaqComponent,
    children: [
      {
        path: 'create',
        component: UpdateFaqComponent
      },
      {
        path: ':faq_id',
        component: UpdateFaqComponent
      }
    ]
  }
];
