import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Subject } from 'rxjs';

import { CustomNotifierService } from '../../../../services/custom.notifier.service';
import { CotationsRequestService } from '../../../../services/cotations.request.service';
import { AllRisksComponent } from '../all-risks/all-risks.component';
import { RisksRequestService } from '../../../../services/risks.request.service';

import Unity from '../../../../../shared/interfaces/unity';
import Cotation from '../../../../../shared/interfaces/cotation';
import { EstablishmentsRequestService } from '../../../../services/establishments.request.service';

import * as _ from 'lodash';

declare function Flatpickr(): any;

@Component({
  selector: 'app-update-risk',
  templateUrl: './update-risk.component.html',
  styleUrls: ['./update-risk.component.scss']
})
export class UpdateRiskComponent implements OnInit, AfterViewInit, OnDestroy {
  canUpdate = false;
  errors = {};

  requestInProgress = new Subject();
  requestCount = 0;

  loader = false;
  loaderContent = false;

  isNew = true;
  archive = false;

  establishments = [];
  unities = [];

  previousRoute = '';

  modalRisk = null;

  riskForm: FormGroup;
  id = new FormControl('');
  establishment_id = new FormControl('');
  risk_unity_work_id = new FormControl('', Validators.required);
  family = new FormControl('', Validators.required);
  description = new FormControl('', Validators.required);
  damages = new FormControl([]);
  frequency_id = new FormControl('', Validators.required);
  frequency = new FormControl('');
  gravity_id = new FormControl('', Validators.required);
  gravity = new FormControl('');
  master_id = new FormControl('', Validators.required);
  master = new FormControl('');
  has_protection = new FormControl(false);
  current_protection = new FormControl('');
  todo_protection = new FormControl('');
  deadline = new FormControl('');
  cost = new FormControl(0);
  cost_detail = new FormControl(null);
  user_affected = new FormControl('');
  user_receive_email = new FormControl(false);
  user_email = new FormControl('', Validators.email);
  state = new FormControl('', Validators.required);
  comments = new FormControl('');
  date_update = new FormControl('');

  risk_unity_works: Unity[] = [];
  frequencies: Cotation[];
  gravities: Cotation[];
  masters: Cotation[];

  users: any[] = [];
  usersSelected: any[] = [];
  establishmentsSelected: any[] = [];
  costs: any[] = [];
  risk_damages: any[] = [];
  risk_families: any[] = [];

  show_inactive_unities = false;
  show_inactive_users = false;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private allUnities: AllRisksComponent,
    private risksService: RisksRequestService,
    private cotationsService: CotationsRequestService,
    private establishmentRequestService: EstablishmentsRequestService,
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loaderContent = this.requestCount > 0;
      if (!this.loaderContent) {
        setTimeout(() => {
          Flatpickr();
          }, 1);
      }
    });

    const is_inactive_unities = JSON.parse(localStorage.getItem('show_inactive_unities'));
    if (is_inactive_unities !== null) {
      this.show_inactive_unities = is_inactive_unities;
    } else {
      localStorage.setItem('show_inactive_unities', JSON.stringify(this.show_inactive_unities));
    }

    const is_inactive_users = JSON.parse(localStorage.getItem('show_inactive_users'));
    if (is_inactive_users !== null) {
      this.show_inactive_users = is_inactive_users;
    } else {
      localStorage.setItem('show_inactive_users', JSON.stringify(this.show_inactive_users));
    }

    this.previousRoute = this.activatedRoute.snapshot.parent.url.join('/');

    this.riskForm = this.formBuilder.group({
      id: this.id,
      family: this.family,
      description: this.description,
      damages: this.damages,
      establishment_id: this.establishment_id,
      risk_unity_work_id: this.risk_unity_work_id,
      frequency_id: this.frequency_id,
      frequency: this.frequency,
      gravity_id: this.gravity_id,
      gravity: this.gravity,
      master_id: this.master_id,
      master: this.master,
      has_protection: this.has_protection,
      current_protection: this.current_protection,
      todo_protection: this.todo_protection,
      deadline: this.deadline,
      cost: this.cost,
      cost_detail: this.cost_detail,
      user_affected: this.user_affected,
      user_receive_email: this.user_receive_email,
      user_email: this.user_email,
      state: this.state,
      comments: this.comments,
      date_update: this.date_update,
    });

    this.getUnityWorks();
    this.getCotations();
    this.getUsers();
    this.getCosts();
    this.getDamages();
    this.getEstablishments();
    this.getFamilies();

    if (this.activatedRoute.snapshot.params.risk_id) {
      this.isNew = false;
      this.getRisk();
    } else {
      this.riskForm.patchValue({ establishment_id: this.activatedRoute.snapshot.parent.params.establishment_id });
    }
  }

  ngAfterViewInit() {
    this.modalRisk.open();
  }

  ngOnDestroy() {
    const flatPicker = document.getElementsByClassName('flatpickr-calendar') as HTMLCollection;
    for (let i = flatPicker.length; i >= 0; i--) {
      if (flatPicker[i]) {
        flatPicker[i].remove();
      }
    }

    this.modalRisk.close();
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  getRisk() {
    this.errors = {};
    this.requestInProgress.next(true);

    const establishment_id = this.activatedRoute.snapshot.parent.params.establishment_id;
    const risk_id = this.activatedRoute.snapshot.params.risk_id;
    this.risksService.getRisk(establishment_id, risk_id).subscribe(response => {
      if (response['success']) {
        this.canUpdate = response['canUpdate'];
        if (!this.canUpdate) {
          this.riskForm.disable();
        }
        this.riskForm.patchValue(response['risk']);
        response['risk']['risk_user_risks'].forEach(user => {
          this.usersSelected.push(user.risk_user.id);
        });
        this.patchCotations();
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.riskForm, this.notifier);
      }
      this.requestInProgress.next(false);
    });

  }
  getUnityWorks() {
    let request;
    this.requestInProgress.next(true);
    if (this.show_inactive_unities) {
      request = this.risksService.getAllMyRegistryEstablishmentUnities(
          '', 'RiskUnityWorks.name', 'asc', 0, 1,
          this.activatedRoute.snapshot.parent.params.registry_id, this.activatedRoute.snapshot.parent.params.establishment_id);
    } else {
      request = this.risksService.getMyRegistryEstablishmentUnities(
          '', 'RiskUnityWorks.name', 'asc', 0, 1,
          this.activatedRoute.snapshot.parent.params.registry_id, this.activatedRoute.snapshot.parent.params.establishment_id);
    }
   request.subscribe(response => {
      if (response['success']) {
        this.risk_unity_works = response['unities'];
      }
      this.requestInProgress.next(false);
    });
  }
  getCotations() {
    this.requestInProgress.next(true);
    this.cotationsService.getCotations().subscribe(response => {
      if (response['success']) {
        this.frequencies = response['frequencies'];
        this.gravities = response['gravities'];
        this.masters = response['masters'];
        this.patchCotations();
      }
      this.requestInProgress.next(false);
    });
  }
  getUsers() {
    let request;
    this.requestInProgress.next(true);
    if (this.show_inactive_users) {
      request = this.risksService.getAllMyRegistryEstablishmentUsers(
          '', 'RiskUsers.name', 'asc', 0, 1,
          this.activatedRoute.snapshot.parent.params.registry_id, this.activatedRoute.snapshot.parent.params.establishment_id);
    } else {
      request = this.risksService.getMyRegistryEstablishmentUsers(
          '', 'RiskUsers.name', 'asc', 0, 1,
          this.activatedRoute.snapshot.parent.params.registry_id, this.activatedRoute.snapshot.parent.params.establishment_id);
    }
    request.subscribe(response => {
      if (response['success']) {
        this.users = response['users'];
      }
      this.requestInProgress.next(false);
    });
  }
  getCosts() {
    this.requestInProgress.next(true);
    this.risksService.getCosts('', '', 'asc', 0, 1).subscribe(response => {
      if (response['success']) {
        this.costs = response['risk_costs'];
      }
      this.requestInProgress.next(false);
    });
  }
  getDamages() {
    this.requestInProgress.next(true);
    this.risksService.getDamagesLabel().subscribe(response => {
      if (response['success']) {
        this.risk_damages = response['risk_damages'];
      }
      this.requestInProgress.next(false);
    });
  }
  getFamilies() {
    this.requestInProgress.next(true);
    this.risksService.getFamiliesLabel().subscribe(response => {
      if (response['success']) {
        this.risk_families = response['risk_families'];
      }
      this.requestInProgress.next(false);
    });
  }
  patchCotations() {
    if (this.frequencies) {
      if (this.riskForm.value.frequency_id) {
        let update = {};
        let found;

        // Reset frequency_id
        found = this.frequencies.find(value => {
          return value.id === parseInt(this.riskForm.value.frequency_id, 10);
        });
        if (found === undefined) {
          update = { ...update, frequency_id: '' };
        }

        // Reset gravity_id
        found = this.gravities.find(value => {
          return value.id === parseInt(this.riskForm.value.gravity_id, 10);
        });
        if (found === undefined) {
          update = { ...update, gravity_id: '' };
        }

        // Reset master_id
        found = this.masters.find(value => {
          return value.id === parseInt(this.riskForm.value.master_id, 10);
        });
        if (found === undefined) {
          update = { ...update, master_id: '' };
        }

        this.riskForm.patchValue(update);
      }
    }
  }
  getEstablishments() {
    this.requestInProgress.next(true);
    this.establishmentRequestService.getAllEstablishmentsRegistry(this.activatedRoute.snapshot.parent.params.registry_id)
        .subscribe(response => {
          if (response['success']) {
            const establishments = response['establishments'];
            const found = establishments.findIndex(establishment => {
              return establishment.id === parseInt(this.activatedRoute.snapshot.parent.params.establishment_id, 10);
            });
            if (found !== -1) {
              establishments.splice(found, 1);
            }
            this.getSortData(establishments);
          } else {
            CustomNotifierService.getErrors(response['errors'], this.notifier);
          }
          this.requestInProgress.next(false);
        });
  }

  getSortData(establishments) {
    this.establishments = _.sortBy(establishments, 'name');
  }

  createRisk() {
    this.errors = {};
    this.loader = true;

    const establishment_id = this.activatedRoute.snapshot.parent.params.establishment_id;
    const risk = {
      ...this.riskForm.value,
      risk_user_risks: this.usersSelected,
      establishments: this.establishmentsSelected,
    };

    this.risksService.createRisk(establishment_id, risk).subscribe(response => {
      if (response['success']) {
        this.modalRisk.close();
        this.allUnities.refreshData();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.riskForm, this.notifier);
      }
      this.loader = false;
    });
  }

  updateRisk() {
    this.errors = {};
    this.loader = true;

    const establishment_id = this.activatedRoute.snapshot.parent.params.establishment_id;
    const risk = { ...this.riskForm.value, risk_user_risks: this.usersSelected };

    this.risksService.updateRisk(establishment_id, this.id.value, risk).subscribe(response => {
      if (response['success']) {
        this.modalRisk.close();
        this.allUnities.refreshData();
        CustomNotifierService.getSuccessUpdate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.riskForm, this.notifier);
      }
      this.loader = false;
    });
  }

  bindModal(modal) {
    this.modalRisk = modal;
  }

  closeModal() {
    this.allUnities.refreshData();
    this.router.navigate([this.previousRoute]).then(() => {
    });
  }
}
