import { Routes } from '@angular/router';

import { AllRvratPrescriptionsComponent } from './all-rvrat-prescriptions/all-rvrat-prescriptions.component';
import { UpdateRvratPrescriptionComponent } from './update-rvrat-prescription/update-rvrat-prescription.component';
import { UpdateRvratPrescriptionStateComponent } from './update-rvrat-prescription-state/update-rvrat-prescription-state.component';
import { UploadRvratPrescriptionFileComponent } from './upload-rvrat-prescription-file/upload-rvrat-prescription-file.component';

import { UpdateInterventionComponent } from '../interventions/update-intervention/update-intervention.component';

export const rvratPrescriptionsRoutes: Routes = [
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/worksheet/:worksheet_id/rvrat/:rvrat_id/rvrat-prescriptions',
    component: AllRvratPrescriptionsComponent,
    children: [
      {
        path: 'create',
        component: UpdateRvratPrescriptionComponent
      },
      {
        path: ':rvrat_prescription_id',
        component: UpdateRvratPrescriptionComponent
      },
      {
        path: ':rvrat_prescription_id/state',
        component: UpdateRvratPrescriptionStateComponent
      },
      {
        path: ':rvrat_prescription_id/intervention/create',
        component: UpdateInterventionComponent
      },
    ]
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/rvrat/:rvrat_id/rvrat-prescriptions',
    component: AllRvratPrescriptionsComponent,
    children: [
      {
        path: 'create',
        component: UpdateRvratPrescriptionComponent
      },
      {
        path: ':rvrat_prescription_id',
        component: UpdateRvratPrescriptionComponent
      },
      {
        path: ':rvrat_prescription_id/state',
        component: UpdateRvratPrescriptionStateComponent
      },
      {
        path: ':rvrat_prescription_id/intervention/create',
        component: UpdateInterventionComponent
      },
    ]
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/worksheet/:worksheet_id/rvrat/:rvrat_id/' +
      'rvrat-prescriptions/:rvrat_prescription_id/files',
    component: AllRvratPrescriptionsComponent,
    children: [
      {
        path: 'create',
        component: UploadRvratPrescriptionFileComponent
      },
      {
        path: ':file_id',
        component: UploadRvratPrescriptionFileComponent
      }
    ]
  },
];
