import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs';

import { AuthService } from '../../../core/auth/auth.service';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { AsbestosRequestService } from '../../../services/asbestos.request.service';
import { RegistriesRequestService } from '../../../services/registries.request.service';
import { EstablishmentsRequestService } from '../../../services/establishments.request.service';

import Registry from '../../../../shared/interfaces/registry';
import Establishment from '../../../../shared/interfaces/establishment';

@Component({
  selector: 'app-asbestos-technical-file',
  templateUrl: './asbestos-technical-file.component.html',
  styleUrls: ['./asbestos-technical-file.component.scss']
})
export class AsbestosTechnicalFileComponent implements OnInit, AfterViewInit, OnDestroy {
  changeEnterprise: Subscription;

  canCreate = false;
  canUpdate = false;

  requestInProgress = new Subject();
  requestCount = 0;

  pageName: string;
  previousPageName: string[] = [];
  previousPageRoute: string[] = [];

  registry: Registry;
  establishment: Establishment;

  asbestosDetections: any = [];
  loaderAsbestosDetections = false;
  pageAsbestosDetections = 1;
  pagesAsbestosDetections = 1;
  countAsbestosDetections = 0;
  limitAsbestosDetections = 12;
  sortByAsbestosDetections = 'AsbestosDetections.referency';
  sortDirectionAsbestosDetections = 'asc';
  searchAsbestosDetections = '';

  asbestosDetectionLocalizations: any = [];
  loaderAsbestosDetectionLocalizations = false;
  pageAsbestosDetectionLocalizations = 1;
  pagesAsbestosDetectionLocalizations = 1;
  countAsbestosDetectionlocalizations = 0;
  limitAsbestosDetectionlocalizations = 12;
  sortByAsbestosDetectionLocalizations = 'AsbestosDetectionLocalizations.localization';
  sortDirectionAsbestosDetectionLocalizations = 'asc';
  searchAsbestosDetectionLocalizations = '';

  asbestosWorks: any = [];
  loaderAsbestosWorks = false;
  pageAsbestosWorks = 1;
  pagesAsbestosWorks = 1;
  countAsbestosWorks = 0;
  limitAsbestosWorks = 12;
  sortByAsbestosWorks = 'AsbestosWorks.date';
  sortDirectionAsbestosWorks = 'desc';
  searchAsbestosWorks = '';

  errors = {};

  timeout: any;
  loaderContent = false;

  constructor(
    private authService: AuthService,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private asbestosService: AsbestosRequestService,
    private registriesService: RegistriesRequestService,
    private establishmentsService: EstablishmentsRequestService,
  ) {}

  ngOnInit() {
    this.changeEnterprise = this.authService.getEnterprise().subscribe(() => {
      this.getRegistry();
      this.getEstablishment();
      this.getAsbestosDetections(true);
      this.getAsbestosDetectionLocalizations(true);
      this.getAsbestosWorks(true);
      this.createBreadcrumbs();
    });

    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loaderContent = this.requestCount > 0;
    });

    this.pageName = 'Dossier technique amiante';

    if (this.authService.loaded) {
      this.getRegistry();
      this.getEstablishment();
      this.getAsbestosDetections(true);
      this.getAsbestosDetectionLocalizations(true);
      this.getAsbestosWorks(true);
      this.createBreadcrumbs();
    }
  }

  ngAfterViewInit() {}

  ngOnDestroy() {}

  getRegistry() {
    this.requestInProgress.next(true);
    this.registriesService.getRegistry(this.activatedRoute.snapshot.params.registry_id).subscribe(response => {
      if (response['success']) {
        this.registry = response['registry'];
        this.createBreadcrumbs();
      } else if (response['errors']) {
        this.errors = response['errors'];
      }
      this.requestInProgress.next(false);
    });
  }

  getEstablishment() {
    this.requestInProgress.next(true);
    this.establishmentsService.getMyEstablishment(this.activatedRoute.snapshot.params.establishment_id).subscribe(response => {
      if (response['success']) {
        this.establishment = response['establishment'];
        this.createBreadcrumbs();
      } else if (response['errors']) {
        this.errors = response['errors'];
      }
      this.requestInProgress.next(false);
    });
  }

  getAsbestosDetections(bool = false) {
    let request;
    this.asbestosDetections = [];
    if (bool) {
      this.requestInProgress.next(true);
    } else {
      this.loaderAsbestosDetections = true;
    }

    request = this.asbestosService.getAllAsbestosDetection(
      this.searchAsbestosDetections,
      this.sortByAsbestosDetections,
      this.sortDirectionAsbestosDetections,
      this.limitAsbestosDetections,
      this.pageAsbestosDetections,
      this.activatedRoute.snapshot.params.registry_id,
      this.activatedRoute.snapshot.params.establishment_id
    );

    request.subscribe(response => {
      if (response['success']) {
        this.asbestosDetections = response['asbestosDetection'];
        this.pagesAsbestosDetections = response['pages'];
        this.countAsbestosDetections = response['count'];
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      if (bool) {
        this.requestInProgress.next(false);
      } else {
        this.loaderAsbestosDetections = false;
      }
    });
  }
  filterAsbestosDetections(property) {
    if (this.sortByAsbestosDetections === property) {
      if (this.sortDirectionAsbestosDetections === 'asc') {
        this.sortDirectionAsbestosDetections = 'desc';
      } else {
        this.sortDirectionAsbestosDetections = 'asc';
      }
    } else {
      this.sortByAsbestosDetections = property;
      this.sortDirectionAsbestosDetections = 'asc';
    }

    this.getAsbestosDetections();
  }
  asbestosBuildingsIdentified(value) {
    if (value) {
      return value.join('\n');
    }
  }
  searchAsbestosDetectionsData() {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.pageAsbestosDetections = 1;
      this.getAsbestosDetections();
    }, 500);
  }
  dispatchPaginationAsbestosDetections(data) {
    if (data.action === 'total_elements') {
      this.refreshAsbestosDetectionsPage(data.value);
    } else if (data.action === 'change_page') {
      this.changePageAsbestosDetections(data.value);
    }
  }
  changePageAsbestosDetections(page) {
    if (page >= 1 && page <= this.pagesAsbestosDetections) {
      this.pageAsbestosDetections = page;
      this.getAsbestosDetections();
    }
  }
  refreshAsbestosDetectionsPage(limit) {
    this.limitAsbestosDetections = limit;
    this.pageAsbestosDetections = 1;
    this.getAsbestosDetections();
  }

  getAsbestosDetectionLocalizations(bool = false) {
    let request;
    this.asbestosDetectionLocalizations = [];
    if (bool) {
      this.requestInProgress.next(true);
    } else {
      this.loaderAsbestosDetectionLocalizations = true;
    }

    request = this.asbestosService.getAsbestosLocalizations(
      this.searchAsbestosDetectionLocalizations,
      this.sortByAsbestosDetectionLocalizations,
      this.sortDirectionAsbestosDetectionLocalizations,
      this.limitAsbestosDetectionlocalizations,
      this.pageAsbestosDetectionLocalizations,
      this.activatedRoute.snapshot.params.registry_id,
      this.activatedRoute.snapshot.params.establishment_id
    );

    request.subscribe(response => {
      if (response['success']) {
        this.asbestosDetectionLocalizations = response['asbestosLocalizations'];
        this.pagesAsbestosDetections = response['pages'];
        this.countAsbestosDetectionlocalizations = response['count'];
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      if (bool) {
        this.requestInProgress.next(false);
      } else {
        this.loaderAsbestosDetectionLocalizations = false;
      }
    });
  }
  filterAsbestosDetectionLocalizations(property) {
    if (this.sortByAsbestosDetectionLocalizations === property) {
      if (this.sortDirectionAsbestosDetectionLocalizations === 'asc') {
        this.sortDirectionAsbestosDetectionLocalizations = 'desc';
      } else {
        this.sortDirectionAsbestosDetectionLocalizations = 'asc';
      }
    } else {
      this.sortByAsbestosDetectionLocalizations = property;
      this.sortDirectionAsbestosDetectionLocalizations = 'asc';
    }

    this.getAsbestosDetectionLocalizations();
  }
  searchAsbestosDetectionLocalizationsData() {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.pageAsbestosDetectionLocalizations = 1;
      this.getAsbestosDetectionLocalizations();
    }, 500);
  }
  dispatchPaginationAsbestosDetectionLocalizations(data) {
    if (data.action === 'total_elements') {
      this.refreshAsbestosLocalizationsPage(data.value);
    } else if (data.action === 'change_page') {
      this.changePageAsbestosDetectionLocalizations(data.value);
    }
  }
  changePageAsbestosDetectionLocalizations(page) {
    if (page >= 1 && page <= this.pagesAsbestosDetectionLocalizations) {
      this.pageAsbestosDetectionLocalizations = page;
      this.getAsbestosDetectionLocalizations();
    }
  }
  refreshAsbestosLocalizationsPage(limit) {
    this.limitAsbestosDetectionlocalizations = limit;
    this.pageAsbestosDetectionLocalizations = 1;
    this.getAsbestosDetectionLocalizations();
  }

  getAsbestosWorks(bool = false) {
    let request;
    this.asbestosWorks = [];
    if (bool) {
      this.requestInProgress.next(true);
    } else {
      this.loaderAsbestosWorks = true;
    }

    request = this.asbestosService.getAllAsbestosWork(
      this.searchAsbestosWorks,
      this.sortByAsbestosWorks,
      this.sortDirectionAsbestosWorks,
      this.limitAsbestosWorks,
      this.pageAsbestosWorks,
      this.activatedRoute.snapshot.params.registry_id,
      this.activatedRoute.snapshot.params.establishment_id
    );

    request.subscribe(response => {
      if (response['success']) {
        this.asbestosWorks = response['asbestosWork'];
        this.pagesAsbestosWorks = response['pages'];
        this.countAsbestosWorks = response['count'];
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      if (bool) {
        this.requestInProgress.next(false);
      } else {
        this.loaderAsbestosWorks = false;
      }
    });
  }
  filterAsbestosWorks(property) {
    if (this.sortByAsbestosWorks === property) {
      if (this.sortDirectionAsbestosWorks === 'asc') {
        this.sortDirectionAsbestosWorks = 'desc';
      } else {
        this.sortDirectionAsbestosWorks = 'asc';
      }
    } else {
      this.sortByAsbestosWorks = property;
      this.sortDirectionAsbestosWorks = 'asc';
    }

    this.getAsbestosWorks();
  }
  searchAsbestosWorksData() {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.pageAsbestosWorks = 1;
      this.getAsbestosWorks();
    }, 500);
  }
  dispatchPaginationAsbestosWorks(data) {
    if (data.action === 'total_elements') {
      this.refreshAsbestosWorksPage(data.value);
    } else if (data.action === 'change_page') {
      this.changePageAsbestosWorks(data.value);
    }
  }
  changePageAsbestosWorks(page) {
    if (page >= 1 && page <= this.pagesAsbestosWorks) {
      this.pageAsbestosWorks = page;
      this.getAsbestosWorks();
    }
  }
  refreshAsbestosWorksPage(limit) {
    this.limitAsbestosWorks = limit;
    this.pageAsbestosWorks = 1;
    this.getAsbestosWorks();
  }

  createBreadcrumbs() {
    this.previousPageName = [];
    this.previousPageRoute = [];

    this.previousPageName.push('Tableau de bord');
    this.previousPageRoute.push('/');

    this.previousPageName.push(this.registry ? this.registry.name : 'Tableau de bord registre');
    this.previousPageRoute.push('/dashboard-registry/' + this.activatedRoute.snapshot.params.registry_id);

    this.previousPageName.push(this.establishment ? this.establishment.name : 'Tableau de bord établissement');
    this.previousPageRoute.push('/dashboard-registry/' + this.activatedRoute.snapshot.params.registry_id +
      '/establishment/' + this.activatedRoute.snapshot.params.establishment_id);
  }
}
