<div class="calendar" [ngClass]="{ 'registry': registry }">
  <!-- MONTH VIEW -->
  <div class="h-100">
    <div class="calendar-navs container-fluid">
      <div class="month-nav row">

        <!-- CALENDAR NAVIGATION -->
        <div class="col-md-6 d-flex">
          <button class="btn btn-lg btn-white tooltipHtml" data-placement="bottom" title="{{ currentDate.format('dddd D MMMM') | titlecase }}" [disabled]="loaderPlanning" (click)="goBackToday()">Aujourd'hui</button>
          <button class="button-prev tooltipHtml" data-placement="bottom" title="Mois précédent" [disabled]="loaderPlanning" (click)="prevMonth()"><i class="fe fe-chevron-left"></i></button>
          <button class="button-next tooltipHtml" data-placement="bottom" title="Mois suivant" [disabled]="loaderPlanning" (click)="nextMonth()"><i class="fe fe-chevron-right"></i></button>
          <div class="current-date d-flex">
            <div class="dropdown mr-1">
              <button class="btn btn-lg btn-white" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [disabled]="loaderPlanning">
                {{ currentDate.format('MMMM') | titlecase }} <i class="fe fe-chevron-down"></i>
              </button>

              <div class="dropdown-menu">
                <a class="dropdown-item href" *ngFor="let month of months" (click)="setMonth(month)">
                  {{ month }}
                </a>
              </div>
            </div>
            <div class="dropdown ml-1">
              <button class="btn btn-lg btn-white" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [disabled]="loaderPlanning">
                {{ currentDate.format('YYYY') }} <i class="fe fe-chevron-down"></i>
              </button>

              <div class="dropdown-menu">
                <a class="dropdown-item href" *ngFor="let year of years" (click)="setYear(year)">
                  {{ year }}
                </a>
              </div>
            </div>
            <div class="is-loading ml-1" *ngIf="loaderPlanning"></div>
          </div>
        </div>

        <!-- LEGEND -->
        <div class="col-md-6 d-flex p-3 legend">
          <p class="ml-2 ml-md-5">Légende :</p>
          <i class="fe fe-info ml-2 tooltipHtml legend-green" title="Rapport de contrôle effectué"></i>
          <i class="fe fe-info ml-2 tooltipHtml legend-red" title="Rapport de contrôle à faire, date limite atteinte"></i>
          <i class="fe fe-info ml-2 tooltipHtml legend-grey" title="Rapport de contrôle à faire, date limite non atteinte"></i>
          <i class="fe fe-info ml-2 tooltipHtml legend-blue" title="Rapport de contrôle à faire, date décalée en amont"></i>
          <i class="fe fe-info ml-2 tooltipHtml legend-yellow" title="Rapport de contrôle à faire, date décalée en aval"></i>
          <i class="fe fe-info ml-2 tooltipHtml legend-commissions" title="Estimation de la prochaine commission"></i>
        </div>

      </div>

      <div class="month-nav row">

        <div class="form-group col-md-6 mb-2" *ngIf="viewPlanning && establishments">
          <ng-select [items]="establishments"
                     [multiple]="true"
                     bindValue="id"
                     bindLabel="name"
                     notFoundText="Aucun résultat"
                     [selectableGroup]="true"
                     [closeOnSelect]="false"
                     placeholder="Sélectionnez un établissement"
                     [(ngModel)]="establishmentsSelected" (ngModelChange)="refreshEvents()" [ngModelOptions]="{ standalone: true }">
            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
              <input type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }"/> Sélectionnez tout
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index" class="overflow-hidden">
              <div class="text-truncate">
                <input id="establishment" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }" /> {{ item.name }}
              </div>
            </ng-template>
          </ng-select>
        </div>

        <div class="form-group col-md-6 mb-2" *ngIf="viewPlanning && registries">
          <ng-select [items]="registries"
                     [multiple]="true"
                     bindValue="id"
                     bindLabel="name"
                     notFoundText="Aucun résultat"
                     [selectableGroup]="true"
                     [closeOnSelect]="false"
                     placeholder="Sélectionnez un registre"
                     [(ngModel)]="registriesSelected" (ngModelChange)="refreshEvents()" [ngModelOptions]="{ standalone: true }">
            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
              <input type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }"/> Sélectionnez tout
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index" class="overflow-hidden">
              <div class="text-truncate">
                <input id="registry" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }" /> {{ item.name }}
              </div>
            </ng-template>
          </ng-select>
        </div>

      </div>
    </div>

    <div class="month-grid">
      <div class="day-names">
        <div class="day-name" [ngClass]="{ weekend: name.day === 0 || name.day === 6 }" *ngFor="let name of dayNames">
          {{ name.name }}
        </div>
      </div>

      <div class="weeks">
        <div class="week" *ngFor="let week of weeks; let firstWeek = first;">
          <div class="day" *ngFor="let day of week; let firstDay = first">
            <!-- PREV OR NEXT MONTH IF GRID CAN -->
            <div class="week-date disabled border-right border-bottom"
                 *ngIf="!isSelectedMonth(day.mDate)" (click)="!loaderPlanning && changeMonth(day)"
                 [ngClass]="{ weekend: day.weekend, 'border-top': firstWeek, 'border-left': firstDay, 'no-pointer': loaderPlanning }">
              <div class="date-text">
                {{ day.mDate.date() }}
              </div>

              <div class="dot"
                   [ngClass]="{
                   'to-do-late': event.to_do_late,
                   'to-do-not-late': event.to_do_not_late,
                   'to-do-before': event.to_do_before,
                   'to-do-after': event.to_do_after,
                   'done': event.done,
                   'next-commission': event.next_commission,
                   'start-date': event.start_date,
                   'end-date': event.end_date,
                   'margin': !first}" *ngFor="let event of day.event; let first = first;">
                <span>{{ event.count + ' événement(s)' }}</span>
              </div>
            </div>
            <!-- ACTUAL MONTH -->
            <div class="week-date enabled border-right border-bottom test" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                 *ngIf="isSelectedMonth(day.mDate)"
                 [ngClass]="{ today: day.today, selected: day.selected, weekend: day.weekend, 'border-top': firstWeek, 'border-left': firstDay, 'no-pointer': loaderPlanning }">
              <div class="date-text">
                {{ day.mDate.date() }}
              </div>

              <div class="dot"
                   [ngClass]="{
                   'today': day.today,
                   'to-do-late': event.to_do_late,
                   'to-do-not-late': event.to_do_not_late,
                   'to-do-before': event.to_do_before,
                   'to-do-after': event.to_do_after,
                   'done': event.done,
                   'next-commission': event.next_commission,
                   'start-date': event.start_date,
                   'end-date': event.end_date,
                   'margin': !first }" *ngFor="let event of day.event; let first = first;" (click)="!loaderPlanning && selectDate(day, event)">
                <span>{{ event.count + ' événement(s)' }}</span>
              </div>
            </div>
            <!-- DROPDOWN EVENTS DAY -->
            <div class="card-body dropdown-menu shadow">
              <div class="row">
                <div class="col">
                  <h3 class="header-title event">{{ dateOne }}</h3>

                  <small class="form-text text-muted">
                    {{ dateTwo }}
                  </small>
                </div>

                <i class="fe fe-x"></i>
              </div>
              <div class="is-loading report" *ngIf="loaderGetReport"></div>
              <div *ngIf="!loaderGetReport">
                <div class="search-results"
                     infiniteScroll
                     [infiniteScrollDistance]="1"
                     [infiniteScrollThrottle]="50"
                     (scrolled)="onScroll(page)"
                     [scrollWindow]="false" *ngIf="dayEvents.length">
                  <div class="card mt-4 day-list" *ngFor="let dayEvent of dayEvents; let index = index; let last = last;"
                       [ngClass]="{
                       'mb-0': last,
                       'to-do-late': dayEvent.to_do_late,
                       'to-do-not-late': dayEvent.to_do_not_late,
                       'to-do-before': dayEvent.to_do_before,
                       'to-do-after': dayEvent.to_do_after,
                       'done': dayEvent.done,
                       'next-commission': dayEvent.next_commission }" [routerLink]="getRouteEvent(dayEvent)" [hidden]="loaderPlanning">
                    <div class="card-body">
                      <div class="d-flex align-content-center justify-content-between">
                        <h4 class="card-title mb-0 name align-self-center" *ngIf="dayEvent.commission_date">
                          {{ establishment ? '' : dayEvent.establishment.name + ' - ' }}

                          Date estimée de la prochaine visite de commission de sécurité
                        </h4>

                        <h4 class="card-title mb-0 name align-self-center" *ngIf="dayEvent.establishment_checkpoint">
                          {{ establishment ? '' : dayEvent.establishment_checkpoint.establishment_registry.establishment.name + ' - ' }}

                          {{ dayEvent.establishment_checkpoint.full_name }}
                        </h4>

                        <h4 class="card-title mb-0 name align-self-center" *ngIf="dayEvent.establishment_registry">
                          {{ establishment ? '' : dayEvent.establishment_registry.establishment.name + ' - ' }}

                          {{ dayEvent.full_name }}
                        </h4>

                        <h4 class="card-title mb-0 name align-self-center" *ngIf="dayEvent.asbestos_detection">
                          {{ establishment ? '' : dayEvent.asbestos_detection.establishment_registry.establishment.name + ' - ' }}

                          {{ dayEvent.localization + ' - ' + dayEvent.recommended_measures }}
                        </h4>

                        <h4 class="card-title mb-0 name align-self-center" *ngIf="dayEvent.training" [routerLink]="'trainings/' + dayEvent.id + '/dates-training'">
                          {{ dayEvent.name ? 'Formation : ' + dayEvent.name : '' }} <br>
                          {{ dayEvent.establishment ? 'Établissement : ' + dayEvent.establishment.name : '' }}
                        </h4>

                        <h4 class="card-title mb-0 name align-self-center" *ngIf="dayEvent.risk">
                          {{ establishment ? '' : dayEvent.establishment.name + ' - ' }}

                          {{ dayEvent.description }}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div class="is-loading scroll" *ngIf="loaderScroll"></div>
                </div>

                <div class="loader-content" *ngIf="!dayEvents.length">
                  <p>Aucun événement</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<router-outlet *ngIf="viewPlanning"></router-outlet>
