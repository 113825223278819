import { AfterViewInit, Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { MapsAPILoader } from '@agm/core';

import { AllEstablishmentsComponent } from '../all-establishments/all-establishments.component';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { RegistriesRequestService } from '../../../services/registries.request.service';
import { EstablishmentsRequestService } from '../../../services/establishments.request.service';
import Establishment from '../../../../shared/interfaces/establishment';
import { Subject } from 'rxjs';
import Enterprise from '../../../../shared/interfaces/enterprise';
import Registry from '../../../../shared/interfaces/registry';
import Offer from '../../../../shared/interfaces/offer';

@Component({
  selector: 'app-update-group',
  templateUrl: './update-group.component.html',
  styleUrls: ['./update-group.component.scss']
})
export class UpdateGroupComponent implements OnInit, AfterViewInit, OnDestroy {
  canUpdate = false;

  groupForm: FormGroup;
  id = new FormControl('');
  enterprise_id = new FormControl('');
  name = new FormControl('', [Validators.required]);
  person_in_charge = new FormControl('', [Validators.required]);
  description = new FormControl('');
  group_list = new FormControl('');
  is_group = new FormControl(true);
  level = new FormControl('');

  errors = {};

  view = '';
  previousRoute = '';
  children = false;

  offer: Offer;
  quotas: Registry[];
  registries: Registry[];
  registry_offers: Registry[];
  registry_no_offers: Registry[];
  count_no_offers = 0;
  count_registries = 0;

  group_id = '';

  isNew = true;
  loader = false;
  loaderContent = false;
  alertNeeded = false;

  modalGroup = null;

  activeTab = 'firstPart';

  results = [];

  establishments: Establishment[] = [];
  requestInProgress = new Subject();
  requestCount = 0;
  selectedEstablishments = [];

  constructor(
    private ngZone: NgZone,
    private router: Router,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private mapsAPILoader: MapsAPILoader,
    private activatedRoute: ActivatedRoute,
    private registriesService: RegistriesRequestService,
    private allEstablishments: AllEstablishmentsComponent,
    private establishmentsService: EstablishmentsRequestService
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loaderContent = this.requestCount > 0;
    });

    this.groupForm = this.formBuilder.group({
      id: this.id,
      enterprise_id: this.enterprise_id,
      name: this.name,
      description: this.description,
      person_in_charge: this.person_in_charge,
      group_list: this.group_list,
      is_group: this.is_group,
      level: this.level
    });

    if (this.activatedRoute.snapshot.params.group_id) {
      this.isNew = false;
      this.group_id = this.activatedRoute.snapshot.params.group_id;
      this.getEstablishment();
      this.getAvailableRegistries(this.activatedRoute.snapshot.params.group_id, '');
    } else {
      let enterprise: Enterprise;
      if (JSON.parse(localStorage.getItem('connectedAs'))) {
        enterprise = JSON.parse(localStorage.getItem('connectedAsEnterprise'));
      } else {
        enterprise = JSON.parse(localStorage.getItem('enterprise'));
      }
      this.groupForm.patchValue({
        enterprise_id: enterprise.id
      });
      this.getAvailableRegistries(0, '');
    }
    this.previousRoute = this.activatedRoute.snapshot.parent.url.join('/');
    this.getEstablishments();
  }

  ngAfterViewInit() {
    this.modalGroup.open();
  }

  ngOnDestroy() {
    this.modalGroup.close();
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  getAvailableRegistries(establishment_id: number, parent: string) {
    this.loaderContent = true;

    let request;
    if (this.activatedRoute.snapshot.parent.params.enterprise_id) {
      request = this.registriesService.getEnterpriseQuotaEstablishment(
        establishment_id, parent, this.activatedRoute.snapshot.parent.params.enterprise_id
      );
    } else {
      request = this.registriesService.getQuotaEstablishment(establishment_id, parent);
    }

    request.subscribe(response => {
      if (response['success']) {

        this.offer = response['offer'];
        this.quotas = response['quotas'];

        this.registry_offers = response['registry_offers'];
        this.registry_offers.forEach(registry => {
          const found = this.quotas.find(element => {
            return element.registry_id === registry.id;
          });
          if (found) {
            registry.checked = found['active'];
            registry.initial_state = found['active'];
            registry.is_pool = found['is_pool'];
          } else {
            registry.checked = false;
            registry.initial_state = false;
            registry.is_pool = false;
          }
          registry.can_activate = true;
          if (registry.checked) {
            this.count_registries++;
          }
        });

        this.registry_no_offers = response['registry_no_offers'];
        this.registry_no_offers.forEach(registry => {
          const found = this.quotas.find(element => {
            return element.registry_id === registry.id;
          });
          if (found) {
            registry.checked = found['active'];
            registry.initial_state = found['active'];
            registry.can_activate = found['active'];
            registry.is_pool = found['is_pool'];
          } else {
            registry.checked = false;
            registry.initial_state = false;
            registry.can_activate = true;
            registry.is_pool = false;
          }
          if (registry.checked) {
            this.count_registries++;
          }
        });

        this.updateAvailability();
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loaderContent = false;
    });
  }
  getEstablishments() {
    this.requestInProgress.next(true);
    let request;

    request = this.establishmentsService.getMyEstablishments('', 'Establishments.name', 'ASC', '', '', false);

    request.subscribe(response => {
      if (response['success']) {
        this.establishments = response['establishments'];
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }
  getEstablishment() {
    this.errors = {};
    this.requestInProgress.next(true);

    const group_id = this.activatedRoute.snapshot.params.group_id;
    let request;
    if (this.activatedRoute.snapshot.parent.params.enterprise_id) {
      const enterprise_id = this.activatedRoute.snapshot.parent.params.enterprise_id;
      request = this.establishmentsService.getEstablishment(enterprise_id, group_id);
    } else {
      request = this.establishmentsService.getMyEstablishment(group_id);
    }

    request.subscribe(response => {
      if (response['success']) {
        this.canUpdate = response['canUpdate'];

        this.groupForm.patchValue(response['establishment']);
        response['establishment']['establishment_groups'].forEach(group => {
          if (!group.establishment.is_group) {
            this.selectedEstablishments = [...this.selectedEstablishments, group.establishment_id];
          }
        });
        if (!this.canUpdate) {
          this.groupForm.disable();
        }
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.groupForm, this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  createGroup() {
    this.errors = {};
    this.loader = true;

    const list = [];
    this.selectedEstablishments.forEach( selectedEstablishment => {
      list.push({id: selectedEstablishment});
    });
    this.group_list.setValue(list);

    let group = {};
    if (this.activatedRoute.parent.snapshot.params.group_id) {
      this.level.setValue(2);
      group = {
        ...this.groupForm.value,
        groupParentId: this.activatedRoute.parent.snapshot.params.group_id
      };
    } else {
      this.level.setValue(1);
      group = {
        ...this.groupForm.value,
      };
    }

    let request;
    if (this.activatedRoute.snapshot.parent.params.enterprise_id) {
      const enterprise_id = this.activatedRoute.snapshot.parent.params.enterprise_id;
      request = this.establishmentsService.createEstablishment(enterprise_id, group);
    } else {
      request = this.establishmentsService.createMyEstablishment(group);
    }

    request.subscribe(response => {
      if (response['success']) {
        this.groupForm.patchValue({ id: response['establishment']['id'] });
        this.modalGroup.close();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.groupForm, this.notifier);
      }
      this.loader = false;
    });
  }
  updateGroup() {
    this.errors = {};
    this.loader = true;

    const list = [];
    this.selectedEstablishments.forEach( selectedEstablishment => {
      list.push({id: selectedEstablishment});
    });
    this.group_list.setValue(list);

    let group = {};
    if (this.activatedRoute.parent.snapshot.params.group_id) {
      group = {
        ...this.groupForm.value,
        groupParentId: this.activatedRoute.parent.snapshot.params.group_id
      };
    } else {
      group = {
        ...this.groupForm.value,
      };
    }

    let request;
    if (this.activatedRoute.snapshot.parent.params.enterprise_id) {
      const enterprise_id = this.activatedRoute.snapshot.parent.params.enterprise_id;
      request = this.establishmentsService.updateEstablishment(enterprise_id, this.id.value, group);
    } else {
      request = this.establishmentsService.updateMyEstablishment(this.id.value, group);
    }

    request.subscribe(response => {
      if (response['success']) {
        this.modalGroup.close();
        CustomNotifierService.getSuccessUpdate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.groupForm, this.notifier);
      }
      this.loader = false;
    });
  }

  checkRegistry(registry: Registry) {
    if (!registry.checked) {
      this.count_registries++;
    } else {
      this.count_registries--;
    }
    registry.checked = !registry.checked;
    this.updateAvailability();
    this.updateAlertMessage();
  }
  checkPool(registry: Registry) {
    registry.is_pool = !registry.is_pool;
  }
  updateAvailability() {
    if (this.offer && this.offer.registry_limit > 0 && !this.children) {
      this.count_no_offers = 0;
      this.registry_no_offers.forEach(registry => {
        if (registry.checked) {
          this.count_no_offers++;
        }
      });
      if (this.count_no_offers < this.offer.registry_limit) {
        this.registry_no_offers.forEach(registry => {
          registry['disabled'] = false;
        });
      } else {
        this.registry_no_offers.forEach(registry => {
          registry['disabled'] = !registry.checked;
        });
      }
    }
  }
  updateAlertMessage() {
    this.alertNeeded = false;
    this.registry_no_offers.forEach(registry => {
      if (registry.initial_state && !registry.checked) {
        this.alertNeeded = true;
      }
    });
  }

  changeTabs(activeTab) {
    this.activeTab = activeTab;
  }
  prevTabs() {
    if (this.activeTab === 'secondPart') {
      this.activeTab = 'firstPart';
    } else if (this.activeTab === 'thirdPart') {
      this.activeTab = 'secondPart';
    } else {
      this.activeTab = 'thirdPart';
    }
  }
  nextTabs() {
    if (this.activeTab === 'secondPart') {
      this.activeTab = 'thirdPart';
    } else if (this.activeTab === 'thirdPart') {
      this.activeTab = 'fourthPart';
    } else {
      this.activeTab = 'secondPart';
    }
  }

  bindModal(modal) {
    this.modalGroup = modal;
  }
  closeModal() {
    this.allEstablishments.refreshData();
    this.router.navigate([this.previousRoute]).then(() => {});
  }
}
