import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AllSecurityProtocolsModule } from './all-security-protocols/all-security-protocols.module';
import { UpdateSecurityProtocolModule } from './update-security-protocol/update-security-protocol.module';
import { UploadSecurityProtocolFileModule } from './upload-security-protocol-file/upload-security-protocol-file.module';

import { SecurityProtocolsRequestService } from '../../services/security.protocols.request.service';

@NgModule({
  imports: [
    CommonModule,
    AllSecurityProtocolsModule,
    UpdateSecurityProtocolModule,
    UploadSecurityProtocolFileModule,
  ],
  providers: [
    SecurityProtocolsRequestService
  ]
})
export class SecurityProtocolsModule {}
