import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { HttpClient } from '@angular/common/http';
import { NotifierService } from 'angular-notifier';

@Injectable()
export class DocumentsRequestService extends RequestService {
  constructor(
    protected http: HttpClient,
    protected notifier: NotifierService
  ) {
    super(http, notifier);
  }

  /// GET
  public getFolders(search, sort, order, limit, page, parent_id = null) {
    if (parent_id) {
      return this.get('folders/' + parent_id +
        '?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
    } else {
      return this.get('folders?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
    }
  }

  public getRegistryFolders(search, sort, order, limit, page, registry_id) {
    return this.get('folders/registry/' + registry_id +
      '?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }

  public getPublicFolders(search, sort, order, limit, page) {
    return this.get('folders/public?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }

  public getChildrenFolders(search, sort, order, limit, page, parent_id) {
    return this.get('folders/' + parent_id +
      '?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }

  public getFiles(search, sort, order, limit, page, file_type_id) {
    return this.get('files/' + file_type_id +
      '?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }

  public getFolder(id) {
    return this.get('folder/' + id);
  }

  public getDocumentsRegistries() {
    return this.get('registries/module/document');
  }

  public getFolderType() {
    return this.get('folder-types');
  }

  public getFileType(file_type_id) {
    return this.get('file-type/' + file_type_id);
  }

  public getFile(file_id) {
    return this.get('file/' + file_id);
  }

  /// CREATE
  public createFolder(folder) {
    return this.post('folder', { folder });
  }

  public createFileType(fileType) {
    return this.post('file-type', { fileType });
  }

  public createFile(file, establishments = []) {
    return this.post('file', { file, establishments });
  }

  /// UPDATE
  public updateFolder(folder_id, folder) {
    return this.put('folder/' + folder_id, { folder });
  }

  public updateFileType(file_type_id, fileType) {
    return this.put('file-type/' + file_type_id , { fileType });
  }

  public updateFile(file_id, file, establishments = []) {
    return this.put('file/' + file_id, { file, establishments });
  }

  /// DELETE
  public deleteFile(file_id) {
    return this.delete('file/' + file_id);
  }

  /// DOWNLOAD
  public downloadFile(file_id) {
    return this.get('file/' + file_id + '/download', { responseType: 'arraybuffer' });
  }

  /**
   * Download a spreadsheet template
   *
   * @param employees Defines the view in which the function is called, whether it is for employees (true) or training employees (false).
   */
  public downloadTemplate(employees: boolean) {
    return this.get('file/spreadsheet/template/download?employees=' + employees, { responseType: 'arraybuffer' });
  }

  /** ====== DASHBOARD FOLDERS ====== **/
  public getDashboardFolders(search, sort, order, limit, page, registry_id, establishment_id) {
    return this.get(  'dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/folders' +
      '?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getDashboardChildrenFolders(search, sort, order, limit, page, registry_id, establishment_id, parent_id) {
    return this.get(  'dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/folders/' + parent_id +
      '?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  /** ====== DASHBOARD FILES ====== **/
  public getDashboardFiles(search, sort, order, limit, page, registry_id, establishment_id, file_type_id) {
    return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/file_type/' + file_type_id +
      '/files?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getDashboardFile(registry_id, establishment_id, file_id) {
    return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/file/' + file_id);
  }
  public createDashboardFile(registry_id, establishment_id, file, establishments = []) {
    return this.post('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/file', { file, establishments });
  }
  public updateDashboardFile(registry_id, establishment_id, file_id, file, establishments = []) {
    return this.put('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/file/' + file_id,
      { file, establishments });
  }
  public downloadDashboardFile(registry_id, establishment_id, file_id) {
    return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/file/' + file_id + '/download',
      { responseType: 'arraybuffer' });
  }
  public deleteDashboardFile(registry_id, establishment_id, file_id) {
    return this.delete('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/file/' + file_id);
  }

  getProtocolFolders(registry_id, establishment_id) {
    return this.get(  'dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/protocol-children-folders');
  }
}
