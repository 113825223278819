import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

import { UpdateEstablishmentDescriptionComponent } from './update-establishment-description.component';
import { ModalRightModule } from '../../../core/modal-right/modal-right.module';
import { UtilsModule } from '../../../core/utils/utils.module';
import { EditorModule } from '@tinymce/tinymce-angular';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    ModalRightModule,
    UtilsModule,
    EditorModule
  ],
  declarations: [UpdateEstablishmentDescriptionComponent]
})

export class UpdateEstablishmentDescriptionModule {}
