import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AllSanitaryInstallationsModule } from './all-sanitary-installations/all-sanitary-installations.module';
import { UpdateSanitaryInstallationModule } from './update-sanitary-installation/update-sanitary-installation.module';
import { UploadSanitaryInstallationFileModule } from './upload-sanitary-installation-file/upload-sanitary-installation-file.module';

import { SanitaryInstallationsRequestService } from '../../services/sanitary-installations.request.service';

@NgModule({
  imports: [
    CommonModule,
    AllSanitaryInstallationsModule,
    UpdateSanitaryInstallationModule,
    UploadSanitaryInstallationFileModule
  ],
  providers: [
    SanitaryInstallationsRequestService
  ]
})
export class SanitaryInstallationsModule {}
