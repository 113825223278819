import { Injectable } from "@angular/core";
import { RequestService } from "./request.service";
import { HttpClient } from "@angular/common/http";
import { NotifierService } from "angular-notifier";

@Injectable()
export class StatisticsRequestService extends RequestService {
  constructor(protected http: HttpClient, protected notifier: NotifierService) {
    super(http, notifier);
  }

  /// GET
  public getEstablishmentsNumber(searchByEnterpriseId, getByType) {
    return this.get(
      "stats/establishments?" +
        "searchByEnterpriseId=" +
        searchByEnterpriseId +
        "&getByType=" +
        getByType
    );
  }
  public getUsersNumber(groupBy, dateFrom, dateTo) {
    return this.get(
      "stats/users?" +
        "groupBy=" +
        groupBy +
        "&dateFrom=" +
        dateFrom +
        "&dateTo=" +
        dateTo
    );
  }
  public getRegistriesByDomain(searchByEnterpriseId) {
    return this.get(
      "stats/registers-by-domain?" +
        "searchByEnterpriseId=" +
        searchByEnterpriseId
    );
  }
  public getRegistriesByDomainAndEnterprise() {
    return this.get("stats/registers-by-domain-and-enterprise");
  }
  public getEnterpriseRegistriesByDomainAndEstablishment() {
    return this.get("stats/enterprise-registers-by-domain-and-establishment");
  }
  public getRegistries(
    establishments = [],
    checkpoint_registries = null,
    commission_registries = null
  ) {
    return this.get(
      "stats/registries?" +
        "establishments=" +
        establishments.join(",") +
        "&checkpoint_registries=" +
        checkpoint_registries +
        "&commission_registries=" +
        commission_registries
    );
  }
  public getTrainingsRegistries(establishments = []) {
    return this.get(
      "stats/registries-trainings?" +
        "establishments=" +
        establishments.join(",")
    );
  }
  public getTrainings(establishments = [], registry_id = "") {
    if (!establishments) {
      establishments = [];
    }
    if (registry_id === null) {
      registry_id = "";
    }
    return this.get(
      "stats/trainings?establishments=" +
        establishments +
        "&registry_id=" +
        registry_id
    );
  }
  public getInstallations(
    selectedEstablishments = [],
    selectedRegistry = null
  ) {
    if (!selectedEstablishments) {
      selectedEstablishments = [];
    }
    if (!selectedRegistry) {
      selectedRegistry = "";
    }
    return this.get(
      "stats/installations?selectedEstablishments=" +
        selectedEstablishments +
        "&selectedRegistry=" +
        selectedRegistry
    );
  }
  public getInstallationsCustom(selectedEstablishments = []) {
    if (!selectedEstablishments) {
      selectedEstablishments = [];
    }
    return this.get(
      "stats/installations-custom?selectedEstablishments=" +
        selectedEstablishments
    );
  }

  // OBSERVATIONS
  public getObservationsStats(groupBy, dateFrom, dateTo) {
    if (!dateFrom) {
      dateFrom = "";
    }
    if (!dateTo) {
      dateTo = "";
    }
    return this.get(
      "stats/enterprise-observations?" +
        "groupBy=" +
        groupBy +
        "&dateFrom=" +
        dateFrom +
        "&dateTo=" +
        dateTo
    );
  }
  public obsStat(
    installationIds,
    dateFrom,
    dateTo,
    checkpointId,
    selectedEstablishments,
    sort,
    order,
    limit,
    page,
    registryId = null,
    filter = ""
  ) {
    if (!registryId) {
      registryId = "";
    }
    if (!installationIds) {
      installationIds = "";
    }
    if (!checkpointId) {
      checkpointId = "";
    }
    if (!selectedEstablishments) {
      selectedEstablishments = "";
    }
    if (!dateFrom) {
      dateFrom = "";
    }
    if (!dateTo) {
      dateTo = "";
    }
    return this.get(
      "stats/obs-block?" +
        "installationIds=" +
        installationIds +
        "&dateFrom=" +
        dateFrom +
        "&dateTo=" +
        dateTo +
        "&checkpointId=" +
        checkpointId +
        "&selectedEstablishments=" +
        selectedEstablishments +
        "&sort=" +
        sort +
        "&order=" +
        order +
        "&limit=" +
        limit +
        "&page=" +
        page +
        "&registryId=" +
        registryId +
        "&filter=" +
        filter
    );
  }
  public getEmergenciesObservations(
    installationIds,
    dateFrom,
    dateTo,
    checkpointId,
    selectedEstablishments,
    sort,
    order,
    limit,
    page,
    registryId = null,
    filter = ""
  ) {
    if (!registryId) {
      registryId = "";
    }
    if (!installationIds) {
      installationIds = "";
    }
    if (!checkpointId) {
      checkpointId = "";
    }
    if (!selectedEstablishments) {
      selectedEstablishments = "";
    }
    if (!dateFrom) {
      dateFrom = "";
    }
    if (!dateTo) {
      dateTo = "";
    }
    return this.get(
      "stats/emergencies-obs?" +
        "installationIds=" +
        installationIds +
        "&dateFrom=" +
        dateFrom +
        "&dateTo=" +
        dateTo +
        "&checkpointId=" +
        checkpointId +
        "&selectedEstablishments=" +
        selectedEstablishments +
        "&sort=" +
        sort +
        "&order=" +
        order +
        "&limit=" +
        limit +
        "&page=" +
        page +
        "&registryId=" +
        registryId +
        "&filter=" +
        filter
    );
  }
  public getDelayObservations(
    installationIds,
    dateFrom,
    dateTo,
    checkpointId,
    selectedEstablishments,
    sort,
    order,
    limit,
    page,
    registryId = null,
    filter = ""
  ) {
    if (!registryId) {
      registryId = "";
    }
    if (!installationIds) {
      installationIds = "";
    }
    if (!checkpointId) {
      checkpointId = "";
    }
    if (!selectedEstablishments) {
      selectedEstablishments = "";
    }
    if (!dateFrom) {
      dateFrom = "";
    }
    if (!dateTo) {
      dateTo = "";
    }
    return this.get(
      "stats/delay-obs?" +
        "installationIds=" +
        installationIds +
        "&dateFrom=" +
        dateFrom +
        "&dateTo=" +
        dateTo +
        "&checkpointId=" +
        checkpointId +
        "&selectedEstablishments=" +
        selectedEstablishments +
        "&sort=" +
        sort +
        "&order=" +
        order +
        "&limit=" +
        limit +
        "&page=" +
        page +
        "&registryId=" +
        registryId +
        "&filter=" +
        filter
    );
  }
  public getDelayObservationsCheckpoints(
    installationIds,
    dateFrom,
    dateTo,
    checkpointId,
    selectedEstablishments,
    sort,
    order,
    limit,
    page,
    registryId = null,
    filter = ""
  ) {
    if (!registryId) {
      registryId = "";
    }
    if (!installationIds) {
      installationIds = "";
    }
    if (!checkpointId) {
      checkpointId = "";
    }
    if (!selectedEstablishments) {
      selectedEstablishments = "";
    }
    if (!dateFrom) {
      dateFrom = "";
    }
    if (!dateTo) {
      dateTo = "";
    }
    return this.get(
      "stats/delay-obs-checkpoints?" +
        "installationIds=" +
        installationIds +
        "&dateFrom=" +
        dateFrom +
        "&dateTo=" +
        dateTo +
        "&checkpointId=" +
        checkpointId +
        "&selectedEstablishments=" +
        selectedEstablishments +
        "&sort=" +
        sort +
        "&order=" +
        order +
        "&limit=" +
        limit +
        "&page=" +
        page +
        "&registryId=" +
        registryId +
        "&filter=" +
        filter
    );
  }
  public obsStatByInstallation(
    selectedInstallations,
    dateFrom,
    dateTo,
    selectedEstablishments,
    registryId,
    sort,
    order,
    limit,
    page,
    installationIds = "",
    filter = ""
  ) {
    if (!registryId) {
      registryId = "";
    }
    if (!selectedEstablishments) {
      selectedEstablishments = "";
    }
    if (!selectedInstallations) {
      selectedInstallations = "";
    }
    if (!dateFrom) {
      dateFrom = "";
    }
    if (!dateTo) {
      dateTo = "";
    }
    return this.get(
      "stats/observations-installation?" +
        "sort=" +
        sort +
        "&order=" +
        order +
        "&limit=" +
        limit +
        "&page=" +
        page +
        "&dateFrom=" +
        dateFrom +
        "&dateTo=" +
        dateTo +
        "&selectedEstablishments=" +
        selectedEstablishments +
        "&selectedInstallations=" +
        selectedInstallations +
        "&registryId=" +
        registryId +
        "&installationIds=" +
        installationIds +
        "&filter=" +
        filter
    );
  }
  public obsStatDay(
    installationIds,
    dateFrom,
    dateTo,
    checkpointId,
    selectedEstablishments,
    sort,
    order,
    limit,
    page,
    registryId = null,
    filter = ""
  ) {
    if (!registryId) {
      registryId = "";
    }
    if (!selectedEstablishments) {
      selectedEstablishments = "";
    }
    if (!installationIds) {
      installationIds = "";
    }
    if (!checkpointId) {
      checkpointId = "";
    }
    if (!dateFrom) {
      dateFrom = "";
    }
    if (!dateTo) {
      dateTo = "";
    }
    return this.get(
      "stats/observations-delay-day?" +
        "sort=" +
        sort +
        "&order=" +
        order +
        "&limit=" +
        limit +
        "&page=" +
        page +
        "&dateFrom=" +
        dateFrom +
        "&dateTo=" +
        dateTo +
        "&registryId=" +
        registryId +
        "&checkpointId=" +
        checkpointId +
        "&installationIds=" +
        installationIds +
        "&selectedEstablishments=" +
        selectedEstablishments +
        "&filter=" +
        filter
    );
  }
  public obsStatCosts(
    installationIds,
    dateFrom,
    dateTo,
    checkpointId,
    selectedEstablishments,
    sort,
    order,
    limit,
    page,
    registryId = null,
    filter = ""
  ) {
    if (!registryId) {
      registryId = "";
    }
    if (!selectedEstablishments) {
      selectedEstablishments = "";
    }
    if (!installationIds) {
      installationIds = "";
    }
    if (!checkpointId) {
      checkpointId = "";
    }
    if (!dateFrom) {
      dateFrom = "";
    }
    if (!dateTo) {
      dateTo = "";
    }
    return this.get(
      "stats/observations-costs?" +
        "sort=" +
        sort +
        "&order=" +
        order +
        "&limit=" +
        limit +
        "&page=" +
        page +
        "&dateFrom=" +
        dateFrom +
        "&dateTo=" +
        dateTo +
        "&registryId=" +
        registryId +
        "&checkpointId=" +
        checkpointId +
        "&installationIds=" +
        installationIds +
        "&selectedEstablishments=" +
        selectedEstablishments +
        "&filter=" +
        filter
    );
  }

  // PRESCRIPTIONS
  public getPrescriptionsStats(groupBy, dateFrom, dateTo) {
    if (!dateFrom) {
      dateFrom = "";
    }
    if (!dateTo) {
      dateTo = "";
    }
    return this.get(
      "stats/enterprise-prescriptions?" +
        "groupBy=" +
        groupBy +
        "&dateFrom=" +
        dateFrom +
        "&dateTo=" +
        dateTo
    );
  }
  public prescriptionsStat(
    dateFrom,
    dateTo,
    selectedEstablishments,
    selectedRegistry,
    sort,
    order,
    limit,
    page,
    filter = ""
  ) {
    if (!selectedEstablishments) {
      selectedEstablishments = "";
    }
    if (!selectedRegistry) {
      selectedRegistry = "";
    }
    if (!dateFrom) {
      dateFrom = "";
    }
    if (!dateTo) {
      dateTo = "";
    }
    return this.get(
      "stats/prescriptions-block?" +
        "&dateFrom=" +
        dateFrom +
        "&dateTo=" +
        dateTo +
        "&selectedEstablishments=" +
        selectedEstablishments +
        "&selectedRegistry=" +
        selectedRegistry +
        "&sort=" +
        sort +
        "&order=" +
        order +
        "&limit=" +
        limit +
        "&page=" +
        page +
        "&filter=" +
        filter
    );
  }
  public getEmergenciesPrescriptions(
    dateFrom,
    dateTo,
    selectedEstablishments,
    selectedRegistry,
    sort,
    order,
    limit,
    page,
    filter = ""
  ) {
    if (!selectedEstablishments) {
      selectedEstablishments = "";
    }
    if (!selectedRegistry) {
      selectedRegistry = "";
    }
    if (!dateFrom) {
      dateFrom = "";
    }
    if (!dateTo) {
      dateTo = "";
    }
    return this.get(
      "stats/emergencies-prescriptions?" +
        "&dateFrom=" +
        dateFrom +
        "&dateTo=" +
        dateTo +
        "&selectedEstablishments=" +
        selectedEstablishments +
        "&selectedRegistry=" +
        selectedRegistry +
        "&sort=" +
        sort +
        "&order=" +
        order +
        "&limit=" +
        limit +
        "&page=" +
        page +
        "&filter=" +
        filter
    );
  }
  public getDelayPrescriptions(
    dateFrom,
    dateTo,
    selectedEstablishments,
    selectedRegistry,
    sort,
    order,
    limit,
    page,
    filter = ""
  ) {
    if (!selectedEstablishments) {
      selectedEstablishments = "";
    }
    if (!selectedRegistry) {
      selectedRegistry = "";
    }
    if (!dateFrom) {
      dateFrom = "";
    }
    if (!dateTo) {
      dateTo = "";
    }
    return this.get(
      "stats/delay-prescriptions?" +
        "&dateFrom=" +
        dateFrom +
        "&dateTo=" +
        dateTo +
        "&selectedEstablishments=" +
        selectedEstablishments +
        "&selectedRegistry=" +
        selectedRegistry +
        "&sort=" +
        sort +
        "&order=" +
        order +
        "&limit=" +
        limit +
        "&page=" +
        page +
        "&filter=" +
        filter
    );
  }
  public prescStatDay(
    dateFrom,
    dateTo,
    selectedEstablishments,
    selectedRegistry,
    sort,
    order,
    limit,
    page,
    filter = ""
  ) {
    if (!selectedEstablishments) {
      selectedEstablishments = "";
    }
    if (!selectedRegistry) {
      selectedRegistry = "";
    }
    if (!dateFrom) {
      dateFrom = "";
    }
    if (!dateTo) {
      dateTo = "";
    }
    return this.get(
      "stats/prescriptions-delay-day?" +
        "dateFrom=" +
        dateFrom +
        "&dateTo=" +
        dateTo +
        "&selectedEstablishments=" +
        selectedEstablishments +
        "&selectedRegistry=" +
        selectedRegistry +
        "&sort=" +
        sort +
        "&order=" +
        order +
        "&limit=" +
        limit +
        "&page=" +
        page +
        "&filter=" +
        filter
    );
  }
  public prescStatCosts(
    dateFrom,
    dateTo,
    selectedEstablishments,
    selectedRegistry,
    sort,
    order,
    limit,
    page,
    filter = ""
  ) {
    if (!selectedEstablishments) {
      selectedEstablishments = "";
    }
    if (!selectedRegistry) {
      selectedRegistry = "";
    }
    if (!dateFrom) {
      dateFrom = "";
    }
    if (!dateTo) {
      dateTo = "";
    }
    return this.get(
      "stats/prescriptions-costs?" +
        "dateFrom=" +
        dateFrom +
        "&dateTo=" +
        dateTo +
        "&selectedEstablishments=" +
        selectedEstablishments +
        "&selectedRegistry=" +
        selectedRegistry +
        "&sort=" +
        sort +
        "&order=" +
        order +
        "&limit=" +
        limit +
        "&page=" +
        page +
        "&filter=" +
        filter
    );
  }

  // TRAININGS
  public getTrainingsStats(groupBy, dateFrom, dateTo) {
    if (!dateFrom) {
      dateFrom = "";
    }
    if (!dateTo) {
      dateTo = "";
    }
    return this.get(
      "stats/enterprise-trainings?" +
        "groupBy=" +
        groupBy +
        "&dateFrom=" +
        dateFrom +
        "&dateTo=" +
        dateTo
    );
  }
  public trainingsPeopleStats(
    dateFrom,
    dateTo,
    selectedEstablishments,
    registry_id,
    selectedOriginTrainings
  ) {
    if (!registry_id) {
      registry_id = "";
    }
    if (!selectedEstablishments) {
      selectedEstablishments = "";
    }
    if (!selectedOriginTrainings) {
      selectedOriginTrainings = "";
    }
    if (!dateFrom) {
      dateFrom = "";
    }
    if (!dateTo) {
      dateTo = "";
    }
    return this.get(
      "stats/trainings-people?" +
        "&dateFrom=" +
        dateFrom +
        "&dateTo=" +
        dateTo +
        "&selectedEstablishments=" +
        selectedEstablishments +
        "&selectedOriginTrainings=" +
        selectedOriginTrainings +
        "&registryId=" +
        registry_id
    );
  }
  public trainingsEstablishmentStats(
    dateFrom,
    dateTo,
    selectedEstablishments,
    registry_id,
    selectedOriginTrainings
  ) {
    if (!registry_id) {
      registry_id = "";
    }
    if (!selectedEstablishments) {
      selectedEstablishments = "";
    }
    if (!selectedOriginTrainings) {
      selectedOriginTrainings = "";
    }
    if (!dateFrom) {
      dateFrom = "";
    }
    if (!dateTo) {
      dateTo = "";
    }
    return this.get(
      "stats/trainings-establishment?" +
        "&dateFrom=" +
        dateFrom +
        "&dateTo=" +
        dateTo +
        "&selectedEstablishments=" +
        selectedEstablishments +
        "&selectedOriginTrainings=" +
        selectedOriginTrainings +
        "&registryId=" +
        registry_id
    );
  }
  public trainingsLateStats(
    dateFrom,
    dateTo,
    selectedEstablishments,
    registry_id,
    selectedOriginTrainings
  ) {
    if (!registry_id) {
      registry_id = "";
    }
    if (!selectedEstablishments) {
      selectedEstablishments = "";
    }
    if (!selectedOriginTrainings) {
      selectedOriginTrainings = "";
    }
    if (!dateFrom) {
      dateFrom = "";
    }
    if (!dateTo) {
      dateTo = "";
    }
    return this.get(
      "stats/trainings-late?" +
        "&dateFrom=" +
        dateFrom +
        "&dateTo=" +
        dateTo +
        "&selectedEstablishments=" +
        selectedEstablishments +
        "&selectedOriginTrainings=" +
        selectedOriginTrainings +
        "&registryId=" +
        registry_id
    );
  }

  // ASBESTOS
  public getAsbestosStats(groupBy, dateFrom, dateTo) {
    if (!dateFrom) {
      dateFrom = "";
    }
    if (!dateTo) {
      dateTo = "";
    }
    return this.get(
      "stats/enterprise-asbestos?" +
        "groupBy=" +
        groupBy +
        "&dateFrom=" +
        dateFrom +
        "&dateTo=" +
        dateTo
    );
  }
  public asbestosStat(dateFrom, dateTo, selectedEstablishments) {
    if (!selectedEstablishments) {
      selectedEstablishments = "";
    }
    if (!dateFrom) {
      dateFrom = "";
    }
    if (!dateTo) {
      dateTo = "";
    }
    return this.get(
      "stats/asbestos-block?" +
        "dateFrom=" +
        dateFrom +
        "&dateTo=" +
        dateTo +
        "&selectedEstablishments=" +
        selectedEstablishments
    );
  }
  public asbestosMeasureStat(dateFrom, dateTo, selectedEstablishments) {
    if (!selectedEstablishments) {
      selectedEstablishments = "";
    }
    if (!dateFrom) {
      dateFrom = "";
    }
    if (!dateTo) {
      dateTo = "";
    }
    return this.get(
      "stats/asbestos-measure-block?" +
        "dateFrom=" +
        dateFrom +
        "&dateTo=" +
        dateTo +
        "&selectedEstablishments=" +
        selectedEstablishments
    );
  }
  public asbestosWorksTypeStat(dateFrom, dateTo, selectedEstablishments) {
    if (!selectedEstablishments) {
      selectedEstablishments = "";
    }
    if (!dateFrom) {
      dateFrom = "";
    }
    if (!dateTo) {
      dateTo = "";
    }
    return this.get(
      "stats/asbestos-works-type-block?" +
        "dateFrom=" +
        dateFrom +
        "&dateTo=" +
        dateTo +
        "&selectedEstablishments=" +
        selectedEstablishments
    );
  }
  public asbestosEstablishmentsStat(dateFrom, dateTo, selectedEstablishments) {
    if (!selectedEstablishments) {
      selectedEstablishments = "";
    }
    if (!dateFrom) {
      dateFrom = "";
    }
    if (!dateTo) {
      dateTo = "";
    }
    return this.get(
      "stats/asbestos-establishments-block?" +
        "dateFrom=" +
        dateFrom +
        "&dateTo=" +
        dateTo +
        "&selectedEstablishments=" +
        selectedEstablishments
    );
  }

  // RISKS
  public getRiskUnityWorks(selectedEstablishments = null) {
    if (!selectedEstablishments) {
      selectedEstablishments = "";
    }
    return this.get(
      "stats/riskUnities?selectedEstablishments=" + selectedEstablishments
    );
  }
  public riskFamiliesStat(
    selectedEstablishments,
    selectedRiskUnity,
    limit,
    page,
    familyIds = ""
  ) {
    if (!selectedEstablishments) {
      selectedEstablishments = "";
    }
    if (!selectedRiskUnity) {
      selectedRiskUnity = "";
    }
    return this.get(
      "stats/risk-families?" +
        "selectedEstablishments=" +
        selectedEstablishments +
        "&selectedRiskUnity=" +
        selectedRiskUnity +
        "&familyIds=" +
        familyIds +
        "&limit=" +
        limit +
        "&page=" +
        page
    );
  }
  public riskByType(
    selectedEstablishments,
    selectedRiskUnity,
    limit,
    page,
    type,
    cotation_id = "",
    filter = ""
  ) {
    if (!selectedEstablishments) {
      selectedEstablishments = "";
    }
    if (!selectedRiskUnity) {
      selectedRiskUnity = "";
    }
    return this.get(
      "stats/risk-type?" +
        "selectedEstablishments=" +
        selectedEstablishments +
        "&selectedRiskUnity=" +
        selectedRiskUnity +
        "&limit=" +
        limit +
        "&page=" +
        page +
        "&type=" +
        type +
        "&cotation_id=" +
        cotation_id +
        "&filter=" +
        filter
    );
  }
  public risksByState(
    selectedEstablishments,
    selectedRiskUnity,
    limit,
    page,
    filter = ""
  ) {
    if (!selectedEstablishments) {
      selectedEstablishments = "";
    }
    if (!selectedRiskUnity) {
      selectedRiskUnity = "";
    }
    return this.get(
      "stats/risk-state?" +
        "selectedEstablishments=" +
        selectedEstablishments +
        "&selectedRiskUnity=" +
        selectedRiskUnity +
        "&limit=" +
        limit +
        "&page=" +
        page +
        "&filter=" +
        filter
    );
  }

  public checkpointsByProviders(
    dateFrom,
    dateTo,
    selectedEstablishments,
    selectedRegistry,
    limit,
    page,
    $provider_id = ""
  ) {
    if (!selectedEstablishments) {
      selectedEstablishments = "";
    }
    if (!selectedRegistry) {
      selectedRegistry = "";
    }
    if (!dateFrom) {
      dateFrom = "";
    }
    if (!dateTo) {
      dateTo = "";
    }
    return this.get(
      "stats/checkpoints-providers?" +
        "selectedEstablishments=" +
        selectedEstablishments +
        "&selectedRegistry=" +
        selectedRegistry +
        "&limit=" +
        limit +
        "&page=" +
        page +
        "&dateFrom=" +
        dateFrom +
        "&dateTo=" +
        dateTo +
        "&provider_id=" +
        $provider_id
    );
  }
  public reportsByProviders(
    dateFrom,
    dateTo,
    selectedEstablishments,
    selectedRegistry,
    sort,
    order,
    limit,
    page,
    $provider_id = ""
  ) {
    if (!selectedEstablishments) {
      selectedEstablishments = "";
    }
    if (!selectedRegistry) {
      selectedRegistry = "";
    }
    if (!dateFrom) {
      dateFrom = "";
    }
    if (!dateTo) {
      dateTo = "";
    }
    return this.get(
      "stats/reports-providers?" +
        "selectedEstablishments=" +
        selectedEstablishments +
        "&selectedRegistry=" +
        selectedRegistry +
        "&sort=" +
        sort +
        "&order=" +
        order +
        "&limit=" +
        limit +
        "&page=" +
        page +
        "&dateFrom=" +
        dateFrom +
        "&dateTo=" +
        dateTo +
        "&provider_id=" +
        $provider_id
    );
  }
  public observationsByProviders(
    dateFrom,
    dateTo,
    selectedEstablishments,
    selectedRegistry,
    sort,
    order,
    limit,
    page,
    $provider_id = ""
  ) {
    if (!selectedEstablishments) {
      selectedEstablishments = "";
    }
    if (!selectedRegistry) {
      selectedRegistry = "";
    }
    if (!dateFrom) {
      dateFrom = "";
    }
    if (!dateTo) {
      dateTo = "";
    }
    return this.get(
      "stats/observations-providers?" +
        "selectedEstablishments=" +
        selectedEstablishments +
        "&selectedRegistry=" +
        selectedRegistry +
        "&sort=" +
        sort +
        "&order=" +
        order +
        "&limit=" +
        limit +
        "&page=" +
        page +
        "&dateFrom=" +
        dateFrom +
        "&dateTo=" +
        dateTo +
        "&provider_id=" +
        $provider_id
    );
  }
  public interventionsByProviders(
    dateFrom,
    dateTo,
    selectedEstablishments,
    selectedRegistry,
    limit,
    page
  ) {
    if (!selectedEstablishments) {
      selectedEstablishments = "";
    }
    if (!selectedRegistry) {
      selectedRegistry = "";
    }
    if (!dateFrom) {
      dateFrom = "";
    }
    if (!dateTo) {
      dateTo = "";
    }
    return this.get(
      "stats/interventions-providers?" +
        "selectedEstablishments=" +
        selectedEstablishments +
        "&selectedRegistry=" +
        selectedRegistry +
        "&limit=" +
        limit +
        "&page=" +
        page +
        "&dateFrom=" +
        dateFrom +
        "&dateTo=" +
        dateTo
    );
  }
  public delayInterventionsByProviders(
    dateFrom,
    dateTo,
    selectedEstablishments,
    selectedRegistry,
    limit,
    page
  ) {
    if (!selectedEstablishments) {
      selectedEstablishments = "";
    }
    if (!selectedRegistry) {
      selectedRegistry = "";
    }
    if (!dateFrom) {
      dateFrom = "";
    }
    if (!dateTo) {
      dateTo = "";
    }
    return this.get(
      "stats/delay-interventions-providers?" +
        "selectedEstablishments=" +
        selectedEstablishments +
        "&selectedRegistry=" +
        selectedRegistry +
        "&limit=" +
        limit +
        "&page=" +
        page +
        "&dateFrom=" +
        dateFrom +
        "&dateTo=" +
        dateTo
    );
  }
  public costsInterventionsByProviders(
    dateFrom,
    dateTo,
    selectedEstablishments,
    selectedRegistry,
    limit,
    page
  ) {
    if (!selectedEstablishments) {
      selectedEstablishments = "";
    }
    if (!selectedRegistry) {
      selectedRegistry = "";
    }
    if (!dateFrom) {
      dateFrom = "";
    }
    if (!dateTo) {
      dateTo = "";
    }
    return this.get(
      "stats/costs-interventions-providers?" +
        "selectedEstablishments=" +
        selectedEstablishments +
        "&selectedRegistry=" +
        selectedRegistry +
        "&limit=" +
        limit +
        "&page=" +
        page +
        "&dateFrom=" +
        dateFrom +
        "&dateTo=" +
        dateTo
    );
  }

  public getStatisticsTrainings(establishments, registry) {
    return this.get(
      "statics_trainings?establishments=" +
        establishments +
        "&registry=" +
        registry
    );
  }
}
