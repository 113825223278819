import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AllRegistriesModule } from './all-registries/all-registries.module';
import { DashboardRegistryModule } from './dashboard-registry/dashboard-registry.module';
import { UpdateRegistryModule } from './update-registry/update-registry.module';
import { UpdateRegistryManagerModule } from './update-registry-manager/update-registry-manager.module';

@NgModule({
  imports: [
    CommonModule,
    AllRegistriesModule,
    DashboardRegistryModule,
    UpdateRegistryModule,
    UpdateRegistryManagerModule
  ]
})
export class RegistriesModule {}
