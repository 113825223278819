import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';

import { AllFaqComponent } from '../all-faq/all-faq.component';
import { CustomNotifierService } from '../../../services/custom.notifier.service';

import { Subject } from 'rxjs';
import { FaqRequestService } from '../../../services/faq.request.service';

@Component({
  selector: 'app-update-faq',
  templateUrl: './update-faq.component.html',
  styleUrls: ['./update-faq.component.scss']
})
export class UpdateFaqComponent implements OnInit, AfterViewInit, OnDestroy {
  canUpdate = false;

  requestInProgress = new Subject();
  requestCount = 0;

  faqForm: FormGroup;
  id = new FormControl('');
  question = new FormControl('', Validators.required);
  answer = new FormControl('', Validators.required);

  errors = {};

  isNew = true;
  loader = false;
  loaderContent = false;

  modalFaq = null;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private allFaq: AllFaqComponent,
    private faqServices: FaqRequestService,
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loaderContent = this.requestCount > 0;
    });

    this.faqForm = this.formBuilder.group({
      id: this.id,
      question: this.question,
      answer: this.answer,
    });

    if (this.activatedRoute.snapshot.params.faq_id) {
      this.isNew = false;
      this.getFaq();
    }
  }

  ngAfterViewInit() {
    this.modalFaq.open();
  }

  ngOnDestroy() {
    this.modalFaq.close();
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  getFaq() {
    this.errors = {};
    this.requestInProgress.next(true);

    this.faqServices.getFaq(this.activatedRoute.snapshot.params.faq_id).subscribe(response => {
      if (response['success']) {
        this.canUpdate = response['canUpdate'];
        this.faqForm.patchValue(response['faq']);

      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.faqForm, this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  createFaq() {
    this.errors = {};
    this.loader = true;

    this.faqServices.createFaq(this.faqForm.value).subscribe(response => {
      if (response['success']) {
        this.modalFaq.close();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.faqForm, this.notifier);
      }
      this.loader = false;
    });
  }

  updateFaq() {
    this.errors = {};
    this.loader = true;

    this.faqServices.updateFaq(this.id.value, this.faqForm.value).subscribe(response => {
      if (response['success']) {
        this.modalFaq.close();
        CustomNotifierService.getSuccessUpdate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.faqForm, this.notifier);
      }
      this.loader = false;
    });
  }

  bindModal(modal) {
    this.modalFaq = modal;
  }

  closeModal() {
    this.allFaq.getFaqs();
    this.router.navigate(['faq']).then(() => {});
  }
}
