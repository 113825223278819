import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';

import { CustomNotifierService } from '../../../services/custom.notifier.service';

import { AllEstablishmentsComponent } from '../all-establishments/all-establishments.component';
import { RegistriesRequestService } from '../../../services/registries.request.service';
import Registry from '../../../../shared/interfaces/registry';
import Offer from '../../../../shared/interfaces/offer';

@Component({
  selector: 'app-add-registries',
  templateUrl: './add-registries.component.html',
  styleUrls: ['./add-registries.component.scss']
})
export class AddRegistriesComponent implements OnInit, AfterViewInit, OnDestroy {
  establishmentRegistriesForm: FormGroup;
  id = new FormControl('', Validators.required);

  offer: Offer;
  quotas: any;
  registries: Registry[];

  errors = {};

  previousRoute = '';

  loader = false;
  loaderContent = false;

  modalQuota = null;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private allEstablishments: AllEstablishmentsComponent,
    private registriesService: RegistriesRequestService
  ) {}

  ngOnInit() {
    this.establishmentRegistriesForm = this.formBuilder.group({
      id: this.id
    });

    this.previousRoute = this.activatedRoute.snapshot.parent.url.join('/');

    this.getAvailableRegistries();
  }

  ngAfterViewInit() {
    this.modalQuota.open();
  }

  ngOnDestroy() {
    this.modalQuota.close();
  }

  getAvailableRegistries() {
    this.loaderContent = true;

    this.registriesService.getQuotaEstablishment(this.activatedRoute.snapshot.params.establishment_id, '').subscribe(response => {
      if (response['success']) {
        this.offer = response['offer'];
        this.quotas = response['quota'];
        this.registries = response['registries'];
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loaderContent = false;
    });
  }

  addEstablishmentRegistries() {
    this.loader = true;

    const establishment_id = this.activatedRoute.snapshot.params.establishment_id;
    const registry_id = this.establishmentRegistriesForm.value.id;

    let request;
    if (this.activatedRoute.snapshot.parent.params.enterprise_id) {
      request = this.registriesService.addEstablishmentRegistry(establishment_id, registry_id);
    } else {
      request = this.registriesService.addEstablishmentMyRegistry(establishment_id, registry_id);
    }

    request.subscribe(response => {
      if (response['success']) {
        this.getAvailableRegistries();
        CustomNotifierService.getSuccessUpdate(this.notifier);
      }
      this.loader = false;
    });
  }

  deleteRegistry(registry_id) {
    this.loader = true;

    const establishment_id = this.activatedRoute.snapshot.params.establishment_id;

    let request;
    if (this.activatedRoute.snapshot.parent.params.enterprise_id) {
      request = this.registriesService.deleteEstablishmentRegistry(establishment_id, registry_id);
    } else {
      request = this.registriesService.deleteEstablishmentMyRegistry(establishment_id, registry_id);
    }

    request.subscribe(response => {
      if (response['success']) {
        this.getAvailableRegistries();
        CustomNotifierService.getSuccessUpdate(this.notifier);
      } else {}
      this.loader = false;
    });
  }

  bindModal(modal) {
    this.modalQuota = modal;
  }

  closeModal() {
    this.allEstablishments.refreshData();
    this.router.navigate([this.previousRoute]).then(() => {});
  }
}
