import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { EmployeesRequestService } from "src/app/services/employees.request.service";
import { EstablishmentsRequestService } from "src/app/services/establishments.request.service";
import { RegistriesRequestService } from "src/app/services/registries.request.service";
import { UpdateTrainingSessionModule } from "../all-training/update-training-session/update-training-session.module";
import { AjoutSessionFormationModule } from "./ajout-session-formation/ajout-session-formation.module";
import { FormationsEmployeeModule } from "./formations-employee/formations-employee.module";
import { GetEmployeesTrainingModule } from "./get-employees-training/get-employees-training.module";
import { UpdateEmployeeModule } from "./update-employee/update-employee.module";

@NgModule({
  imports: [
    CommonModule,
    GetEmployeesTrainingModule,
    UpdateEmployeeModule,
    UpdateTrainingSessionModule,
    FormationsEmployeeModule,
    AjoutSessionFormationModule,
  ],
  providers: [
    EstablishmentsRequestService,
    RegistriesRequestService,
    EmployeesRequestService,
  ],
})
export class EmployeesTrainingModule {}
