import {Component, OnDestroy, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Subscription } from 'rxjs';

import { AuthService } from '../../../core/auth/auth.service';
import { StatisticsRequestService } from '../../../services/statistics.request.service';
import { CustomNotifierService } from '../../../services/custom.notifier.service';

declare function AdminCharts(): any;

@Component({
  selector: 'app-dashboard-admin',
  templateUrl: './dashboard-admin.component.html',
  styleUrls: ['./dashboard-admin.component.scss']
})
export class DashboardAdminComponent implements OnInit, OnDestroy {
  changeEnterprise: Subscription;
  totalUsers = 0;
  establishments = [];
  selectedEstablishments = null;
  totalEstablishments = 0;
  totalEnterprises = 0;
  adminChart = AdminCharts();
  installations;
  checkpoints;
  periods = [
    { value: 'custom', name: 'Période personnalisée' },
    { value: 'days', name: '7 derniers jours' },
    { value: 'month', name: '30 derniers jours' },
    { value: 'months', name: '3 derniers mois' },
    { value: 'year', name: 'Année en cours' },
    { value: 'lastyear', name: 'Année dernière' },
    { value: 'lastyears', name: 'Il y a deux ans' }
  ];
  period = 'custom';
  dateFrom;
  dateTo;
  groupBy = 'month';

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private notifier: NotifierService,
    private statisticsService: StatisticsRequestService
  ) {}

  ngOnInit() {

    this.changeEnterprise = this.authService.getEnterprise().subscribe(() => {
      this.initAdminCharts();
    });


    if (this.authService.loaded) {
        this.initAdminCharts();
    }

    // Remove background opacity bug if modal closed too fast
    const modalBackdrop = document.getElementsByClassName('modal-backdrop fade show') as HTMLCollection;
    for (let i = 0; i < modalBackdrop.length; i++) {
      modalBackdrop[i].remove();
    }
    const body = document.getElementsByTagName('body');
    for (let i = 0; i < body.length; i++) {
      body[i].className = body[i].className.replace('modal-open', '');
    }
  }

  ngOnDestroy() {
    this.changeEnterprise.unsubscribe();

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  initAdminCharts() {
    this.statisticsService.getUsersNumber('month', '', '')
      .subscribe(response => {
        if (response['success']) {
          this.adminChart.createUserChart('#headerChart', response, 'month');
        } else if (response['errors']) {
          CustomNotifierService.getErrors(response['errors'], this.notifier);
        }
      });
    this.statisticsService.getUsersNumber('', '', '')
      .subscribe(response => {
        if (response['success']) {
          this.totalUsers = response['nbUsers'];
        } else if (response['errors']) {
          CustomNotifierService.getErrors(response['errors'], this.notifier);
        }
      });

    this.statisticsService.getEstablishmentsNumber('', '')
      .subscribe(response => {
        if (response['success']) {
          this.totalEstablishments = response['nbEstablishments'];
        } else if (response['errors']) {
          CustomNotifierService.getErrors(response['errors'], this.notifier);
        }
      });
    this.statisticsService.getEstablishmentsNumber('', 'type')
      .subscribe(response => {
        if (response['success']) {
          this.adminChart.createEstablishmentChart('#establishmentsTypeChart', response);
        } else if (response['errors']) {
          CustomNotifierService.getErrors(response['errors'], this.notifier);
        }
      });

    this.statisticsService.getRegistriesByDomainAndEnterprise()
      .subscribe(response => {
        if (response['success']) {
          this.totalEnterprises = response['totalEnterprisesWithRegistry'];
          this.adminChart.createRegistryByDomainAndEnterpriseChart('#registryByEnterpriseChart', response);
        } else if (response['errors']) {
          CustomNotifierService.getErrors(response['errors'], this.notifier);
        }
      });
    this.statisticsService.getRegistriesByDomain('')
      .subscribe(response => {
        if (response['success']) {
          this.adminChart.createRegistryChart('#registryChart', response);
        } else if (response['errors']) {
          CustomNotifierService.getErrors(response['errors'], this.notifier);
        }
      });
  }

  updateUserChart(groupBy) {
    this.statisticsService.getUsersNumber(groupBy, '', '')
      .subscribe(response => {
        if (response['success']) {
          this.adminChart.createUserChart('#headerChart', response, groupBy);
        } else if (response['errors']) {
          CustomNotifierService.getErrors(response['errors'], this.notifier);
        }
      });
  }

}
