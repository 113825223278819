import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { HttpClient } from '@angular/common/http';
import { RequestService } from './request.service';

@Injectable()
export class PrescriptionsRequestService extends RequestService {
  constructor(
    protected http: HttpClient,
    protected notifier: NotifierService
  ) {
    super(http, notifier);
  }

  /// GET
  /* PRESCRIPTIONS */
  public getAllPrescriptions(search, sort, order, limit, page, registry_id, establishment_id, commission_id) {
    return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/commission/' + commission_id +
      '/prescriptions?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getAllUncorrectedPrescriptions(commission_id) {
    return this.get('commission/' + commission_id + '/prescriptions/uncorrected');
  }
  public getPrescription(registry_id, establishment_id, commission_id, prescription_id) {
    return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/commission/' + commission_id +
      '/prescriptions/' + prescription_id);
  }

  public getPrescriptionState(registry_id, establishment_id, commission_id, prescription_id) {
    return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/commission/' + commission_id +
      '/prescriptions/' + prescription_id + '/state');
  }
  public getFiles(search, sort, order, limit, page, registry_id, establishment_id, commission_id, prescription_id) {
    return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/commission/' + commission_id +
      '/prescription/' + prescription_id + '/files?' +
      'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getFile(registry_id, establishment_id, commission_id, prescription_id, file_id) {
    return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/commission/' + commission_id +
      '/prescription/' + prescription_id + '/files/' + file_id);
  }

  /* PRESCRIPTIONS DASHBOARD */
  public getPrescriptionDashboard(search, sort, order, limit, page, registry_id, establishment_id, emergency, year, month) {
    if (establishment_id) {
      return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/prescriptions?' +
        'emergency=' + emergency + '&year=' + year + '&month=' + month +
        '&search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
    } else {
      return this.get('dashboard-registry/' + registry_id + '/prescriptions?' +
        'emergency=' + emergency + '&year=' + year + '&month=' + month +
        '&search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
    }
  }
  public getMyPrescriptionDashboard(search, sort, order, limit, page, registry_id, establishment_id, emergency, year, month) {
    if (establishment_id) {
      return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/prescriptions/mine?' +
        'emergency=' + emergency + '&year=' + year + '&month=' + month +
        '&search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
    } else {
      return this.get('dashboard-registry/' + registry_id + '/prescriptions/mine?' +
        'emergency=' + emergency + '&year=' + year + '&month=' + month +
        '&search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
    }
  }

  /* ESTABLISHMENT PRESCRIPTIONS DASHBOARD */
  public getEstablishmentPrescription(registry_id, establishment_id, prescription_id) {
    return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id +
      '/prescriptions/' + prescription_id);
  }
  public getEstablishmentPrescriptionState(registry_id, establishment_id, prescription_id) {
    return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id +
      '/prescriptions/' + prescription_id + '/state');
  }

  /* REGISTRY PRESCRIPTIONS DASHBOARD */
  public getAllUncorrectedPrescriptionsRegistry(registry_id, commission_id) {
    return this.get('dashboard-registry/' + registry_id + '/commissions/' + commission_id + '/prescriptions/uncorrected');
  }
  public getRegistryPrescription(registry_id, prescription_id) {
    return this.get('dashboard-registry/' + registry_id + '/prescriptions/' + prescription_id);
  }
  public getRegistryPrescriptionState(registry_id, prescription_id) {
    return this.get( 'dashboard-registry/' + registry_id + '/prescriptions/' + prescription_id + '/state');
  }

  /// CREATE
  /* PRESCRIPTIONS */
  public createPrescription(registry_id, establishment_id, commission_id, prescription) {
    return this.post('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/commission/' + commission_id +
      '/prescriptions', { prescription });
  }
  public createFile(registry_id, establishment_id, commission_id, prescription_id, file) {
    return this.post('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/commission/' + commission_id +
      '/prescription/' + prescription_id + '/files', { file: file });
  }

  /* ESTABLISHMENT PRESCRIPTIONS DASHBOARD */
  createEstablishmentPrescriptionFile(registry_id, establishment_id, prescription_id, file) {
    return this.post('dashboard-registry/' + registry_id + '/establishment/' + establishment_id +
      '/prescriptions/' + prescription_id + '/files', { file: file });
  }
  /* REGISTRY PRESCRIPTIONS DASHBOARD */
  createRegistryPrescriptionFile(registry_id, prescription_id, file) {
    return this.post('dashboard-registry/' + registry_id + '/prescriptions/' + prescription_id + '/files', { file: file });
  }

  /// UPDATE
  /* PRESCRIPTIONS */
  public updatePrescription(registry_id, establishment_id, commission_id, prescription_id, prescription) {
    return this.put('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/commission/' + commission_id +
      '/prescriptions/' + prescription_id, { prescription });
  }
  public updatePrescriptionState(registry_id, establishment_id, commission_id, prescription_id, prescription) {
    return this.put('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/commission/' + commission_id +
      '/prescriptions/' + prescription_id + '/state', { prescription });
  }
  public updateFile(registry_id, establishment_id, commission_id, prescription_id, file_id, file) {
    return this.put('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/commission/' + commission_id +
      '/prescription/' + prescription_id + '/files/' + file_id, { file: file });
  }

  /* PRESCRIPTIONS DASHBOARD */
  /* ESTABLISHMENT PRESCRIPTIONS DASHBOARD */
  public updateEstablishmentPrescription(registry_id, establishment_id, prescription_id, prescription) {
    return this.put( 'dashboard-registry/' + registry_id + '/establishment/' + establishment_id +
      '/prescriptions/' + prescription_id, { prescription });
  }
  public updateEstablishmentPrescriptionState(registry_id, establishment_id, prescription_id, prescription) {
    return this.put('dashboard-registry/' + registry_id + '/establishment/' + establishment_id +
      '/prescriptions/' + prescription_id + '/state', { prescription });
  }

  /* REGISTRY PRESCRIPTIONS DASHBOARD */
  public updateRegistryPrescription(registry_id, prescription_id, prescription) {
    return this.put('dashboard-registry/' + registry_id + '/prescriptions/' + prescription_id, { prescription });
  }
  public updateRegistryPrescriptionState(registry_id, prescription_id, prescription) {
    return this.put('dashboard-registry/' + registry_id + '/prescriptions/' + prescription_id + '/state', { prescription });
  }

  /// DELETE
  public deletePrescription(registry_id, establishment_id, commission_id, prescription_id) {
    return this.delete('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/commission/' + commission_id +
      '/prescriptions/' + prescription_id);
  }
  public deleteFile(registry_id, establishment_id, commission_id, prescription_id, file_id) {
    return this.delete('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/commission/' + commission_id +
      '/prescription/' + prescription_id + '/files/' + file_id);
  }

  /// DOWNLOAD
  public downloadFile(registry_id, establishment_id, commission_id, prescription_id, file_id) {
    return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/commission/' + commission_id +
      '/prescription/' + prescription_id + '/files/' + file_id + '/download', { responseType: 'arraybuffer' });
  }
}
