import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AllAsbestosDetectionModule } from './all-asbestos-detection/all-asbestos-detection.module';
import { UpdateAsbestosDetectionModule } from './update-asbestos-detection/update-asbestos-detection.module';

import { AllAsbestosWorkModule } from './all-asbestos-work/all-asbestos-work.module';
import { UpdateAsbestosWorkModule } from './update-asbestos-work/update-asbestos-work.module';

import { UploadAsbestosFileModule } from './upload-asbestos-file/upload-asbestos-file.module';

import { AsbestosTechnicalFileModule } from './asbestos-technical-file/asbestos-technical-file.module';

import { AsbestosRequestService } from '../../services/asbestos.request.service';

@NgModule({
  imports: [
    CommonModule,
    AllAsbestosDetectionModule,
    UpdateAsbestosDetectionModule,
    AllAsbestosWorkModule,
    UpdateAsbestosWorkModule,
    UploadAsbestosFileModule,
    AsbestosTechnicalFileModule
  ],
  providers: [AsbestosRequestService]
})
export class AsbestosModule {}
