import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { HttpClient } from '@angular/common/http';
import { RequestService } from './request.service';

@Injectable()
export class RvratPrescriptionsRequestService extends RequestService {
  constructor(
    protected http: HttpClient,
    protected notifier: NotifierService
  ) {
    super(http, notifier);
  }

  /// GET
  /* PRESCRIPTIONS */
  public getAllRvratPrescriptions(search, sort, order, limit, page, registry_id, establishment_id, rvrat_id) {
    return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/rvrat/' + rvrat_id +
      '/rvrat-prescriptions?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getAllUncorrectedRvratPrescriptions(rvrat_id) {
    return this.get('rvrat/' + rvrat_id + '/rvrat-prescriptions/uncorrected');
  }
  public getRvratPrescription(registry_id, establishment_id, rvrat_id, rvrat_prescription_id) {
    return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/rvrat/' + rvrat_id +
      '/rvrat-prescriptions/' + rvrat_prescription_id);
  }

  public getRvratPrescriptionState(registry_id, establishment_id, rvrat_id, rvrat_prescription_id) {
    return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/rvrat/' + rvrat_id +
      '/rvrat-prescriptions/' + rvrat_prescription_id + '/state');
  }
  public getFiles(search, sort, order, limit, page, registry_id, establishment_id, rvrat_id, rvrat_prescription_id) {
    return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/rvrat/' + rvrat_id +
      '/rvrat-prescription/' + rvrat_prescription_id + '/files?' +
      'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getFile(registry_id, establishment_id, rvrat_id, rvrat_prescription_id, file_id) {
    return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/rvrat/' + rvrat_id +
      '/rvrat-prescription/' + rvrat_prescription_id + '/files/' + file_id);
  }

  /* PRESCRIPTIONS DASHBOARD */
  public getRvratPrescriptionDashboard(search, sort, order, limit, page, registry_id, establishment_id, emergency, year, month) {
    if (establishment_id) {
      return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/rvrat-prescriptions?' +
        'emergency=' + emergency + '&year=' + year + '&month=' + month +
        '&search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
    } else {
      return this.get('dashboard-registry/' + registry_id + '/rvrat-prescriptions?' +
        'emergency=' + emergency + '&year=' + year + '&month=' + month +
        '&search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
    }
  }
  public getMyRvratPrescriptionDashboard(search, sort, order, limit, page, registry_id, establishment_id, emergency, year, month) {
    if (establishment_id) {
      return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/rvrat-prescriptions/mine?' +
        'emergency=' + emergency + '&year=' + year + '&month=' + month +
        '&search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
    } else {
      return this.get('dashboard-registry/' + registry_id + '/rvrat-prescriptions/mine?' +
        'emergency=' + emergency + '&year=' + year + '&month=' + month +
        '&search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
    }
  }

  /* ESTABLISHMENT PRESCRIPTIONS DASHBOARD */
  public getEstablishmentRvratPrescription(registry_id, establishment_id, rvrat_prescription_id) {
    return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id +
      '/rvrat-prescriptions/' + rvrat_prescription_id);
  }
  public getEstablishmentRvratPrescriptionState(registry_id, establishment_id, rvrat_prescription_id) {
    return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id +
      '/rvrat-prescriptions/' + rvrat_prescription_id + '/state');
  }

  /* REGISTRY PRESCRIPTIONS DASHBOARD */
  public getAllUncorrectedRvratPrescriptionsRegistry(registry_id, rvrat_id) {
    return this.get('dashboard-registry/' + registry_id + '/rvrats/' + rvrat_id + '/rvrat-prescriptions/uncorrected');
  }
  public getRegistryRvratPrescription(registry_id, rvrat_prescription_id) {
    return this.get('dashboard-registry/' + registry_id + '/rvrat-prescriptions/' + rvrat_prescription_id);
  }
  public getRegistryRvratPrescriptionState(registry_id, rvrat_prescription_id) {
    return this.get( 'dashboard-registry/' + registry_id + '/rvrat-prescriptions/' + rvrat_prescription_id + '/state');
  }

  /// CREATE
  /* PRESCRIPTIONS */
  public createRvratPrescription(registry_id, establishment_id, rvrat_id, rvrat_prescription) {
    return this.post('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/rvrat/' + rvrat_id +
      '/rvrat-prescriptions', { rvrat_prescription });
  }
  public createFile(registry_id, establishment_id, rvrat_id, rvrat_prescription_id, file) {
    return this.post('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/rvrat/' + rvrat_id +
      '/rvrat-prescription/' + rvrat_prescription_id + '/files', { file: file });
  }

  /* ESTABLISHMENT PRESCRIPTIONS DASHBOARD */
  createEstablishmentRvratPrescriptionFile(registry_id, establishment_id, rvrat_prescription_id, file) {
    return this.post('dashboard-registry/' + registry_id + '/establishment/' + establishment_id +
      '/rvrat-prescriptions/' + rvrat_prescription_id + '/files', { file: file });
  }
  /* REGISTRY PRESCRIPTIONS DASHBOARD */
  createRegistryRvratPrescriptionFile(registry_id, rvrat_prescription_id, file) {
    return this.post('dashboard-registry/' + registry_id + '/rvrat-prescriptions/' + rvrat_prescription_id + '/files', { file: file });
  }

  /// UPDATE
  /* PRESCRIPTIONS */
  public updateRvratPrescription(registry_id, establishment_id, rvrat_id, rvrat_prescription_id, rvrat_prescription) {
    return this.put('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/rvrat/' + rvrat_id +
      '/rvrat-prescriptions/' + rvrat_prescription_id, { rvrat_prescription });
  }
  public updateRvratPrescriptionState(registry_id, establishment_id, rvrat_id, rvrat_prescription_id, rvrat_prescription) {
    return this.put('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/rvrat/' + rvrat_id +
      '/rvrat-prescriptions/' + rvrat_prescription_id + '/state', { rvrat_prescription });
  }
  public updateFile(registry_id, establishment_id, rvrat_id, rvrat_prescription_id, file_id, file) {
    return this.put('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/rvrat/' + rvrat_id +
      '/rvrat-prescription/' + rvrat_prescription_id + '/files/' + file_id, { file: file });
  }

  /* PRESCRIPTIONS DASHBOARD */
  /* ESTABLISHMENT PRESCRIPTIONS DASHBOARD */
  public updateEstablishmentRvratPrescription(registry_id, establishment_id, rvrat_prescription_id, rvrat_prescription) {
    return this.put( 'dashboard-registry/' + registry_id + '/establishment/' + establishment_id +
      '/rvrat-prescriptions/' + rvrat_prescription_id, { rvrat_prescription });
  }
  public updateEstablishmentRvratPrescriptionState(registry_id, establishment_id, rvrat_prescription_id, rvrat_prescription) {
    return this.put('dashboard-registry/' + registry_id + '/establishment/' + establishment_id +
      '/rvrat-prescriptions/' + rvrat_prescription_id + '/state', { rvrat_prescription });
  }

  /* REGISTRY PRESCRIPTIONS DASHBOARD */
  public updateRegistryRvratPrescription(registry_id, rvrat_prescription_id, rvrat_prescription) {
    return this.put('dashboard-registry/' + registry_id + '/rvrat-prescriptions/' + rvrat_prescription_id, { rvrat_prescription });
  }
  public updateRegistryRvratPrescriptionState(registry_id, rvrat_prescription_id, rvrat_prescription) {
    return this.put('dashboard-registry/' + registry_id + '/rvrat-prescriptions/' + rvrat_prescription_id + '/state',
      { rvrat_prescription });
  }

  /// DELETE
  public deleteRvratPrescription(registry_id, establishment_id, rvrat_id, rvrat_prescription_id) {
    return this.delete('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/rvrat/' + rvrat_id +
      '/rvrat-prescriptions/' + rvrat_prescription_id);
  }
  public deleteFile(registry_id, establishment_id, rvrat_id, rvrat_prescription_id, file_id) {
    return this.delete('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/rvrat/' + rvrat_id +
      '/rvrat-prescription/' + rvrat_prescription_id + '/files/' + file_id);
  }

  /// DOWNLOAD
  public downloadFile(registry_id, establishment_id, rvrat_id, rvrat_prescription_id, file_id) {
    return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/rvrat/' + rvrat_id +
      '/rvrat-prescription/' + rvrat_prescription_id + '/files/' + file_id + '/download', { responseType: 'arraybuffer' });
  }
}
