<div id="confirm-modal" class="modal modal-confirm fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header align-items-center">

        <div class="row">
          <div class="col">
            <h1 class="header-title">{{ view[type].title }}</h1>
          </div>
        </div>

        <button type="button" class="close" (click)="close()">
          <span aria-hidden="true">&times;</span>
          <span class="sr-only">Close</span>
        </button>
      </div>

      <div class="modal-body" [innerHTML]="view[type].content"></div>

      <div class="modal-footer">
        <button class="btn btn-primary col w-100" (click)="close()">
          Annuler
        </button>
        <button class="btn btn-primary col w-100" (click)="closeInternal()">
          Confirmer
        </button>
      </div>
    </div>
  </div>
</div>
