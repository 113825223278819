import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';

import { AllRvratPrescriptionsComponent } from '../all-rvrat-prescriptions/all-rvrat-prescriptions.component';
import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { RvratPrescriptionsRequestService } from '../../../services/rvrat-prescriptions.request.service';

@Component({
  selector: 'app-upload-rvrat-prescription-file',
  templateUrl: './upload-rvrat-prescription-file.component.html',
  styleUrls: ['./upload-rvrat-prescription-file.component.scss']
})
export class UploadRvratPrescriptionFileComponent implements OnInit, AfterViewInit, OnDestroy {
  canCreate = false;
  canUpdate = false;

  rvratPrescriptionFileForm: FormGroup;
  id = new FormControl('');
  rvrat_prescription_id = new FormControl('');
  name = new FormControl('', Validators.required);
  extension = new FormControl('');
  file_base64 = new FormControl('');

  fileName: string;

  errors = {};

  isNew = true;
  loader = false;
  loaderContent = false;

  modalPrescriptionFile = null;
  modalConfirmFile = null;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private allPrescriptions: AllRvratPrescriptionsComponent,
    private rvratPrescriptionsService: RvratPrescriptionsRequestService
  ) {}

  ngOnInit() {
    this.rvratPrescriptionFileForm = this.formBuilder.group({
      id: this.id,
      rvrat_prescription_id: this.rvrat_prescription_id,
      name: this.name,
      extension: this.extension,
      file_base64: this.file_base64
    });

    if (this.activatedRoute.snapshot.params.file_id) {
      this.isNew = false;
      this.getFile(this.activatedRoute.snapshot.params.file_id);
    } else {
      this.file_base64.setValidators([Validators.required]);
      this.file_base64.updateValueAndValidity();
      this.rvrat_prescription_id.setValue(this.activatedRoute.snapshot.parent.params.rvrat_prescription_id);
    }
  }

  ngAfterViewInit() {
    this.modalPrescriptionFile.open();

    this.modalConfirmFile.eventOnClose.subscribe(event => {
      if (event) {
        this.loaderContent = true;
        let request;

        request = this.rvratPrescriptionsService.deleteFile(
          this.activatedRoute.snapshot.parent.params.registry_id,
          this.activatedRoute.snapshot.parent.params.establishment_id,
          this.activatedRoute.snapshot.parent.params.rvrat_id,
          this.activatedRoute.snapshot.parent.params.rvrat_prescription_id,
          event
        );

        request.subscribe(response => {
          if (response['success']) {
            this.modalPrescriptionFile.close();
            CustomNotifierService.getSuccessDelete(this.notifier);
          } else if (response['errors']) {
            CustomNotifierService.getErrors(response['errors'], this.notifier);
          }
          this.loaderContent = false;
        });
      }
    });
  }

  ngOnDestroy() {
    this.modalPrescriptionFile.close();
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  getFile(file_id) {
    let request;
    this.loaderContent = true;

    request = this.rvratPrescriptionsService.getFile(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.activatedRoute.snapshot.parent.params.rvrat_id,
      this.activatedRoute.snapshot.parent.params.rvrat_prescription_id,
      file_id
    );

    request.subscribe(response => {
      if (response['success']) {
        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];

        this.rvratPrescriptionFileForm.patchValue(response['prescriptionFile']);

        if (!this.canUpdate) {
          this.rvratPrescriptionFileForm.disable();
        }
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loaderContent = false;
    });
  }

  chooseFile() {
    const element: HTMLElement = document.getElementById('inputFile') as HTMLElement;
    element.click();
  }

  onFileChange(event) {
    this.file_base64.setValue('');

    const reader = new FileReader();

    if (event.target && event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.file_base64.setValue(reader.result);
        this.fileName = file.name;

        const split = this.fileName.split('.');
        if (split.length) {
          this.extension.setValue(split[split.length - 1]);
        }
      };
    } else {
      this.file_base64.setValue(event.file);
      this.fileName = event.name;

      const split = this.fileName.split('.');
      if (split.length) {
        this.extension.setValue(split[split.length - 1]);
      }
    }
    const element: HTMLInputElement = document.getElementById('inputFile') as HTMLInputElement;
    element.value = '';
  }

  createFile() {
    let request;
    this.errors = {};
    this.loader = true;

    request = this.rvratPrescriptionsService.createFile(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.activatedRoute.snapshot.parent.params.rvrat_id,
      this.activatedRoute.snapshot.parent.params.rvrat_prescription_id,
      this.rvratPrescriptionFileForm.value
    );

    request.subscribe(response => {
      if (response['success']) {
        this.modalPrescriptionFile.close();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loader = false;
    });
  }

  updateFile() {
    let request;
    this.errors = {};
    this.loader = true;

    request = this.rvratPrescriptionsService.updateFile(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.activatedRoute.snapshot.parent.params.rvrat_id,
      this.activatedRoute.snapshot.parent.params.rvrat_prescription_id,
      this.id.value,
      this.rvratPrescriptionFileForm.value
    );

    request.subscribe(response => {
      if (response['success']) {
        this.modalPrescriptionFile.close();
        CustomNotifierService.getSuccessUpdate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loader = false;
    });
  }

  downloadFile(file_id, file_name) {
    let request;

    request = this.rvratPrescriptionsService.downloadFile(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.activatedRoute.snapshot.parent.params.rvrat_id,
      this.activatedRoute.snapshot.parent.params.rvrat_prescription_id,
      file_id
    );

    request.subscribe((response: BufferSource) => {
      try {
        const decoder = new TextDecoder('utf-8');
        const domString = decoder.decode(response);
        const json = JSON.parse(domString);

        CustomNotifierService.getErrors(json['errors'], this.notifier);
      } catch (e) {
        const blob = new Blob([response]);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = file_name;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      }
    });
  }

  removeFile() {
    this.file_base64.setValue(null);

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  bindModal(modal) {
    this.modalPrescriptionFile = modal;
  }

  bindModalConfirmFile(modal) {
    this.modalConfirmFile = modal;
  }

  closeModal() {
    this.allPrescriptions.refreshData();
    this.router.navigate([this.activatedRoute.snapshot.parent.url.join('/')]).then(() => {});
  }
}
