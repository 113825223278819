import { Routes } from '@angular/router';

import { AllEnterprisesComponent } from './all-enterprises/all-enterprises.component';
import { UpdateEnterpriseComponent } from './update-enterprise/update-enterprise.component';

import { UpdateSubscriptionComponent } from './update-subscription/update-subscription.component';

import { AllProviderContractsComponent } from './all-provider-contracts/all-provider-contracts.component';
import { UpdateProviderContractComponent } from './update-provider-contract/update-provider-contract.component';
import { AddProviderSireneComponent } from './add-provider-sirene/add-provider-sirene.component';
import { RightsTransferComponent } from './rights-transfer/rights-transfer.component';
import { ModalLogsComponent } from '../logs/modal-logs/modal-logs.component';
import { AllProviderRegistryComponent } from './all-provider-registry/all-provider-registry.component';

export const enterprisesRoutes: Routes = [
  {
    path: 'enterprises',
    component: AllEnterprisesComponent,
    children: [
      {
        path: 'create',
        component: UpdateEnterpriseComponent
      },
      {
        path: ':enterprise_id',
        component: UpdateEnterpriseComponent
      },
      {
        path: ':enterprise_id/subscription',
        component: UpdateSubscriptionComponent
      },
      {
        path: ':enterprise_id/logs',
        component: ModalLogsComponent,
      }
    ]
  },
  {
    path: 'providers',
    component: AllEnterprisesComponent,
    children: [
      {
        path: 'create',
        component: AddProviderSireneComponent
      },
      {
        path: 'transfer',
        component: RightsTransferComponent
      },
      {
        path: ':enterprise_id',
        component: UpdateEnterpriseComponent
      },
      {
        path: ':enterprise_id/logs',
        component: ModalLogsComponent,
      }
    ]
  },
  {
    path: 'providers/:enterprise_id/contracts',
    component: AllProviderContractsComponent,
    children: [
      {
        path: 'create',
        component: UpdateProviderContractComponent
      },
      {
        path: ':contract_id',
        component: UpdateProviderContractComponent
      }
    ]
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/providers',
    component: AllProviderRegistryComponent,
    children: [
      {
        path: ':enterprise_id',
        component: UpdateEnterpriseComponent
      },
      {
        path: ':enterprise_id/logs',
        component: ModalLogsComponent,
      }
    ]
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/providers/:enterprise_id/contracts',
    component: AllProviderContractsComponent,
    children: [
      {
        path: 'create',
        component: UpdateProviderContractComponent
      },
      {
        path: ':contract_id',
        component: UpdateProviderContractComponent
      }
    ]
  },
];
