import { Injectable } from "@angular/core";
import { RequestService } from "./request.service";
import { HttpClient } from "@angular/common/http";
import { NotifierService } from "angular-notifier";

@Injectable()
export class NotificationsRequestService extends RequestService {
  constructor(protected http: HttpClient, protected notifier: NotifierService) {
    super(http, notifier);
  }

  // NOTIFICATION CENTER
  // Get all notification center
  public getNotifications(search, sort, order, limit, page, type) {
    return this.get(
      "notification-center/mine?" +
        "search=" +
        search +
        "&sort=" +
        sort +
        "&order=" +
        order +
        "&limit=" +
        limit +
        "&page=" +
        page +
        "&type=" +
        type
    );
  }
  public getNotification(notification_id) {
    return this.get("notification-center/mine/" + notification_id);
  }
  public createNotification(notification) {
    return this.post("notification-center/mine", { notification });
  }
  public createAlerts(notifications) {
    return this.post("notification-center/mine/alerts", { notifications });
  }
  public updateNotification(notification_id, notification) {
    return this.put("notification-center/mine/" + notification_id, {
      notification,
    });
  }
  public deleteNotification(notification_id) {
    return this.delete("notification-center/mine/" + notification_id);
  }

  // NOTIFICATION CENTER ADMIN
  // Get all notification center
  public getAdminNotifications(search, sort, order, limit, page) {
    return this.get(
      "notification-center?search=" +
        search +
        "&sort=" +
        sort +
        "&order=" +
        order +
        "&limit=" +
        limit +
        "&page=" +
        page
    );
  }
  public getAdminNotification(notification_id) {
    return this.get("notification-center/" + notification_id);
  }
  public createAdminNotification(notification) {
    return this.post("notification-center", { notification });
  }
  public updateAdminNotification(notification_id, notification) {
    return this.put("notification-center/" + notification_id, { notification });
  }
  public deleteAdminNotification(notification_id) {
    return this.delete("notification-center/" + notification_id);
  }

  // USER NOTIFICATION
  // Get all User Notifications
  public getMyNotifications(search, sort, order, limit, page) {
    return this.get(
      "notifications?search=" +
        search +
        "&sort=" +
        sort +
        "&order=" +
        order +
        "&limit=" +
        limit +
        "&page=" +
        page
    );
  }
  // Get all User Not Read Notifications
  public getMyNotReadNotifications(search, sort, order, limit, page) {
    return this.get(
      "notifications/not-read?search=" +
        search +
        "&sort=" +
        sort +
        "&order=" +
        order +
        "&limit=" +
        limit +
        "&page=" +
        page
    );
  }
  // Mark One Notification as read
  public checkAsRead(notification_id) {
    return this.put("notification/" + notification_id, {});
  }
  // Mark All Notification as read
  public markAsReadAll() {
    return this.put("notification/", {});
  }
  // Delete my notification
  public deleteMyNotification(notification_id) {
    return this.delete("notification/" + notification_id);
  }

  // TOKEN NOTIFICATION
  // User Token for notification
  public sendNotificationToken(token) {
    return this.post("notification/notification-token", { token });
  }

  public getEnterpriseUsers(search, sort, order, limit, page, enterprise_id) {
    return this.get("notification-users/" + enterprise_id);
  }

  public getRoles(enterprise_id) {
    return this.get("notification-roles/" + enterprise_id);
  }

  sendPeriodicAlerts(user_id = null, enterprise_id = null) {
    return this.get(
      "notification/periodic-alerts/?user_id=" +
        user_id +
        "&enterprise_id=" +
        enterprise_id
    );
  }

  public sendEmailAlerts() {
    return this.get("notification/notifications_formations");
  }
}
