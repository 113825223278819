import { Directive, EventEmitter, HostListener, Output, OnInit } from '@angular/core';

@Directive({
  selector: '[appDragDrop]'
})
export class DragDropDirective implements OnInit {
  @Output() dropHandler: EventEmitter<any> = new EventEmitter<any>();

  name: string;
  public dragging: boolean;
  public loaded: boolean;

  @HostListener('dragover') onDragOver() {
    return false;
  }
  @HostListener('dragenter') handleDragEnter() {
    document.getElementsByClassName('dropzone')[0].classList.add('dz-drag-hover');
    this.dragging = true;
  }
  @HostListener('dragleave') handleDragLeave() {
    document.getElementsByClassName('dropzone')[0].classList.remove('dz-drag-hover');
    this.dragging = false;
  }
  @HostListener('drop', ['$event']) handleDrop(e) {
    document.getElementsByClassName('dropzone')[0].classList.remove('dz-drag-hover');
    e.preventDefault();
    this.dragging = false;
    this.handleInputChange(e);
  }

  ngOnInit() {}

  handleInputChange(e) {
    const self = this;
    const files = [].slice.call(e.dataTransfer.files);

    if (files.length > 0) {
      files.forEach(function (f) {
        const reader = new FileReader();
        reader.onload = function (event: any) {
          self.dropHandler.emit({ file: event.target.result, name: f.name });
        };
        reader.readAsDataURL(f);
      });
    }
  }
}
