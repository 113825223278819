<nav class="navbar navbar-vertical fixed-left navbar-expand-md navbar-light overflow-y" id="sidebar">
  <div class="container-fluid">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#sidebarCollapse" aria-controls="sidebarCollapse" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <a class="navbar-brand" [routerLink]="[isAdminChoosen ? '/administration' : getUrl()]">
      <img src="../../../assets/img/logo_batiregistre.png" class="navbar-brand-img mx-auto d-none d-md-block" alt="Logo BatiRegistre">
      <img src="../../../assets/img/picto_batiregistre.png" class="navbar-brand-img mx-auto d-md-none" alt="Logo BatiRegistre">
    </a>

    <div class="navbar-user d-md-none">

      <ng-container *ngIf="authService.isSideNavNotification && !authService.isToolBarNotification">
        <app-toolbar-notification></app-toolbar-notification>
      </ng-container>

      <div class="dropdown">
        <a id="sidebarIcon" class="dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <div class="avatar avatar-sm">
            <img class="avatar-img rounded-circle" alt="Photo utilisateur" [src]="environment.SERVER_HOST + 'img/users/' + user.picture" *ngIf="user.picture != '' && user.picture != null" />

            <img class="avatar-img rounded-circle" alt="Image général utilisateur" src="../assets/img/users/user.png" *ngIf="user.picture == '' || user.picture == null" />
          </div>
        </a>

        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="sidebarIcon">
          <a [routerLink]="['/account']" class="href dropdown-item">Mon compte</a>
          <hr class="dropdown-divider">
          <a (click)="authService.logout()" *ngIf="!connectedAs" class="href dropdown-item">Déconnexion</a>
          <a (click)="authService.disconnectAs()" *ngIf="connectedAs" class="href dropdown-item">Revenir à mon compte</a>
        </div>
      </div>
    </div>

    <div class="collapse navbar-collapse" id="sidebarCollapse">
      <div class="menu-content" *ngIf="showMenu">
        <hr class="navbar-divider my-3 d-block w-100">

        <div class="admin-title">
          <h2 class="d-flex justify-content-between align-items-center">
            {{ isAdminChoosen ? 'Administration' : (enterprise && enterprise.name ? enterprise.name : 'BatiRegistre') }}

            <span class="href" *ngIf="!isAdminChoosen && enterprises && enterprises.length > 1">
              <i class="fe fe-arrow-right-circle tooltipHtml" data-placement="bottom" title="Changement d'entreprise" (click)="chooseEnterprise()"></i>
            </span>
          </h2>
        </div>

        <hr class="navbar-divider my-3 d-block w-100" *ngIf="isProvider && !isAdminChoosen">

        <ul class="navbar-nav overflow-hidden" *ngIf="isProvider && !isAdminChoosen">
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/customers']" role="button" (click)="setActiveNav($event)">
              <i class="fe fe-activity"></i>Mes Clients
            </a>
          </li>
        </ul>

        <hr class="navbar-divider my-3 d-block w-100" *ngIf="!isOnlyProvider">

        <ul class="navbar-nav overflow-hidden" [hidden]="isAdminChoosen || isOnlyProvider">
          <ng-container *ngFor="let menu of menus">
            <hr class="navbar-divider my-3 d-block ml-4 mr-4" *ngIf="menu.url === '/documents/public' || menu.url === '/faq/public'">

            <li class="nav-item" *ngIf="(menu.submenu && menu.submenu.length) && (((monoOffer && !menu.isMonoOffer) || !monoOffer) || ((discoverOffer && !menu.isDiscoverOffer) || !discoverOffer))">

              <a href="#{{ menu.submenu_ref }}" class="nav-link" data-toggle="collapse" role="button">
                <i class="fe {{ menu.icon }}"></i> {{ menu.name }}
              </a>

              <div class="collapse" id="{{ menu.submenu_ref }}" *ngIf="menu.submenu.length">
                <ul class="nav nav-sm flex-column" *ngFor="let submenu of menu.submenu">
                  <li class="nav-item" *ngIf="setActiveMenu(submenu) && (((monoOffer && !submenu.isMonoOffer) || !monoOffer)) && !discoverOffer">
                    <a class="nav-link" [routerLink]="[submenu.url]" role="button" (click)="setActiveNav($event)">
                      <i class="fe {{ submenu.icon }}"></i> {{ submenu.name }}
                      <i *ngIf="submenu.name === 'Levées'" data-html="true" class="fe fe-info ml-2 tooltipHtml icon-tooltip" title="Liste des alertes de levée des : <br> • observations <br> • inspections <br> • commissions <br> • expertises <br> • rus"></i>
                      <i *ngIf="submenu.name === 'Non intervention'" data-html="true" class="fe fe-info ml-2 tooltipHtml icon-tooltip" title="Configurer une alerte avertissant si un contrôle n’a pas été ajouté alors que son échéance est passée"></i>
                    </a>
                  </li>

                  <li class="nav-item" *ngIf="setActiveMenu(submenu) && discoverOffer">
                    <a class="nav-link" [routerLink]="[submenu.url]" role="button" (click)="setActiveNav($event)" *ngIf="!submenu.isDiscoverOffer">
                      <i class="fe {{ submenu.icon }}"></i> {{ submenu.name }}
                    </a>
                    <a class="nav-link discover-color tooltipHtml" title="Veuillez souscrire à une offre pour débloquer le contenu" role="button" (click)="setActiveNav($event)" *ngIf="submenu.isDiscoverOffer">
                      <i class="fe {{ submenu.icon }}"></i> {{ submenu.name }}
                    </a>
                  </li>
                </ul>
              </div>
            </li>

            <li class="nav-item" *ngIf="(!menu.submenu || !menu.submenu.length) && (((monoOffer && !menu.isMonoOffer) || !monoOffer) || ((discoverOffer && menu.isDiscoverOffer) || !discoverOffer))">
              <ng-container *ngIf="(((monoOffer && !menu.isMonoOffer) || !monoOffer)) && !discoverOffer">
                <a class="nav-link" [routerLink]="[menu.url]" role="button" (click)="setActiveNav($event); hideCollapse()">
                  <i class="fe {{ menu.icon }}"></i> {{ menu.name }}
                </a>
              </ng-container>

              <ng-container *ngIf="discoverOffer">
                <a class="nav-link" [routerLink]="[menu.url]" role="button" (click)="setActiveNav($event); hideCollapse()" *ngIf="!menu.isDiscoverOffer">
                  <i class="fe {{ menu.icon }}"></i> {{ menu.name }}
                </a>
                <a class="nav-link discover-color tooltipHtml" title="Veuillez souscrire à une offre pour débloquer le contenu" role="button" (click)="setActiveNav($event); hideCollapse()" *ngIf="menu.isDiscoverOffer">
                  <i class="fe {{ menu.icon }}"></i> {{ menu.name }}
                </a>
              </ng-container>
            </li>

            <hr class="navbar-divider my-3 d-block ml-4 mr-4" *ngIf="menu.url === '/documents/public'">

          </ng-container>

        </ul>

        <ul class="navbar-nav overflow-hidden" [hidden]="!isAdminChoosen">
          <ng-container *ngFor="let menu of menuAdmin">
            <hr class="navbar-divider my-3 d-block ml-4 mr-4" *ngIf="menu.url === '/documents' || menu.url === '/issues' || menu.url === '/faq'">

            <li class="nav-item" *ngIf="menu.submenu && menu.submenu.length">
              <a href="#admin{{menu.submenu_ref}}" class="nav-link" data-toggle="collapse" role="button">
                <i class="fe {{ menu.icon }}"></i> {{ menu.name }}
              </a>

              <div class="collapse" id="admin{{menu.submenu_ref}}" *ngIf="menu.submenu.length">
                <ul class="nav nav-sm flex-column" *ngFor="let submenu of menu.submenu">
                  <li class="nav-item">
                    <a class="nav-link" [routerLink]="[submenu.url]" role="button" (click)="setActiveNav($event)">
                      <i class="fe {{ submenu.icon }}"></i> {{ submenu.name }}
                    </a>
                  </li>
                </ul>
              </div>
            </li>

            <li class="nav-item" *ngIf="!menu.submenu || !menu.submenu.length">
              <ng-container *ngIf="menu.url !== '/single-sign-on'">
                <a class="nav-link" [routerLink]="[menu.url]" role="button" (click)="setActiveNav($event); hideCollapse()">
                  <i class="fe {{ menu.icon }}"></i> {{ menu.name }}
                </a>
              </ng-container>
              <ng-container *ngIf="menu.url === '/single-sign-on' && isLeviatanUser">
                <a class="nav-link" [routerLink]="[menu.url]" role="button" (click)="setActiveNav($event); hideCollapse()">
                  <i class="fe {{ menu.icon }}"></i> {{ menu.name }}
                </a>
              </ng-container>
            </li>

            <hr class="navbar-divider my-3 d-block ml-4 mr-4" *ngIf="menu.url === '/issues' && isLeviatanUser">
          </ng-container>
        </ul>

        <hr class="navbar-divider my-3 d-block w-100">

        <div class="mt-auto">
          <div class="discover-day" *ngIf="discoverOffer" [innerHTML]="getDelayOffer()"></div>
        </div>

        <a class="btn btn-block btn-primary mt-4 mb-4 text-white text-truncate" *ngIf="isAdmin" (click)="switchAdministration()">
          <i class="fe fe-sliders mr-2"></i>
          <span>{{ isAdminChoosen ? (enterprise && enterprise.name ? enterprise.name : 'BatiRegistre') : 'Administration' }}</span>
        </a>
      </div>

      <div class="menu-content" *ngIf="!showMenu">
        <hr class="navbar-divider my-3 d-block w-100">

        <div class="admin-title">
          <h2 class="d-flex justify-content-between align-items-center">
            {{ isAdminChoosen ? 'Administration' : (enterprise && enterprise.name ? enterprise.name : 'BatiRegistre') }}

            <span class="href" *ngIf="!isAdminChoosen && enterprises && enterprises.length > 1">
              <i class="fe fe-x-circle" (click)="chooseEnterprise()"></i>
            </span>
          </h2>
        </div>

        <hr class="navbar-divider my-3 d-block w-100">

        <ul class="navbar-nav overflow-hidden">
          <ng-container *ngFor="let ent of enterprises; let first = first">
            <li class="nav-item">
              <a class="nav-link href" (click)="changeEnterprise(ent.id)" role="button">
                <i class="fe fe-home"></i> {{ ent.name }}
              </a>
            </li>
            <hr class="navbar-divider my-3 d-block ml-4 mr-4">
          </ng-container>
        </ul>
      </div>
    </div>
  </div>
</nav>
