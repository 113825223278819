import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';

import { AllSingleSignOnComponent } from '../all-single-sign-on/all-single-sign-on.component';
import { CustomNotifierService } from '../../../services/custom.notifier.service';

import { SsoConfigsRequestService } from '../../../services/sso-configs.request.service';
import { environment } from '../../../../environments/environment';
import { Subject } from 'rxjs';
import Enterprise from '../../../../shared/interfaces/enterprise';
import { EnterprisesRequestService } from '../../../services/enterprises.request.service';
import User from '../../../../shared/interfaces/user';
import { UsersRequestService } from '../../../services/users.request.service';

@Component({
  selector: 'app-update-single-sign-on',
  templateUrl: './update-single-sign-on.component.html',
  styleUrls: ['./update-single-sign-on.component.scss']
})
export class UpdateSingleSignOnComponent implements OnInit, AfterViewInit, OnDestroy {
  canUpdate = false;
  environment = environment;
  requestInProgress = new Subject();
  requestCount = 0;

  ssoForm: FormGroup;
  id = new FormControl('');
  name = new FormControl('', Validators.required);
  idp_name = new FormControl('', Validators.required);
  client_id = new FormControl('', Validators.required);
  redirect_uri = new FormControl('', Validators.required);
  authorize_uri = new FormControl('', Validators.required);
  client_secret = new FormControl('', Validators.required);
  token_uri = new FormControl('', Validators.required);
  user_uri = new FormControl('', Validators.required);
  logout_uri = new FormControl('');
  token_user = new FormControl(null, Validators.required);
  token_key = new FormControl('', Validators.required);
  picture = new FormControl(null);
  created = new FormControl('');
  updated = new FormControl('');

  errors = {};

  isNew = true;
  loader = false;
  loaderContent = false;

  tokenLists = [
    {'name' : 'AccessToken'},
    {'name' : 'IdToken'}
  ];

  modalSingleSignOn = null;
  selectedEnterprise = null;
  selectedUsers = [];
  enterprises: Enterprise[] = [];
  users: User[] = [];

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private usersService: UsersRequestService,
    private allSingleSignOn: AllSingleSignOnComponent,
    private ssoConfigService: SsoConfigsRequestService,
    private enterprisesService: EnterprisesRequestService,
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loaderContent = this.requestCount > 0;
    });

    this.ssoForm = this.formBuilder.group({
      id: this.id,
      name: this.name,
      idp_name: this.idp_name,
      client_id: this.client_id,
      redirect_uri: this.redirect_uri,
      authorize_uri: this.authorize_uri,
      client_secret: this.client_secret,
      token_uri: this.token_uri,
      user_uri: this.user_uri,
      logout_uri: this.logout_uri,
      token_user: this.token_user,
      token_key: this.token_key,
      picture: this.picture,
      created: this.created,
      updated: this.updated
    });

    if (this.activatedRoute.snapshot.params.sso_id) {
      this.isNew = false;
      this.getSingleSignOn(this.activatedRoute.snapshot.params.sso_id);
    }

    this.getEnterprises();
  }

  ngAfterViewInit() {
    this.modalSingleSignOn.open();
  }

  ngOnDestroy() {
    this.modalSingleSignOn.close();
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  getEnterprises() {
    this.requestInProgress.next(true);
    let request;

    request = this.enterprisesService.getEnterprises('', '', '', '', '');
    request.subscribe(response => {
      if (response['success']) {
        this.enterprises = response['enterprises'];
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  getUsers(enterprise_id) {
    this.requestInProgress.next(true);
    let request;

    this.selectedUsers = [];

    request = this.usersService.getEnterpriseUsers('', '', '', '', '', enterprise_id);
    request.subscribe(response => {
      if (response['success']) {
        this.users = response['users'];
      }
      this.requestInProgress.next(false);
    });
  }

  getSingleSignOn(sso_id) {
    this.errors = {};
    this.requestInProgress.next(true);

    this.ssoConfigService.getSsoConfig(sso_id).subscribe(response => {
      if (response['success']) {

        this.getUsers(response['sso_config']['enterprise_id']);
        this.ssoForm.patchValue(response['sso_config']);
        this.selectedEnterprise = response['sso_config']['enterprise_id'];

        response['sso_config']['users'].forEach(user => {
          this.selectedUsers.push(user.id);
        });

        this.canUpdate = response['canUpdate'];
        if (!this.canUpdate) {
          this.ssoForm.disable();
        }
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.ssoForm, this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  createSsoConfig() {
    this.errors = {};
    this.loader = true;

    const tempUsers = [];
    this.selectedUsers.forEach(user => {
      tempUsers.push({ id : user });
    });

    const sso = {
      ...this.ssoForm.value,
      users: tempUsers,
      enterprise_id: this.selectedEnterprise,
    };

    this.ssoConfigService.createSsoConfig(sso).subscribe(response => {
      if (response['success']) {
        this.modalSingleSignOn.close();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.ssoForm, this.notifier);
      }
      this.loader = false;
    });
  }

  updateSsoConfig() {
    this.errors = {};
    this.loader = true;

    const tempUsers = [];
    this.selectedUsers.forEach(user => {
      tempUsers.push({ id : user });
    });

    const sso = {
      ...this.ssoForm.value,
      users: tempUsers,
      enterprise_id: this.selectedEnterprise,
    };

    this.ssoConfigService.updateSsoConfig(this.id.value, sso).subscribe(response => {
      if (response['success']) {
        this.modalSingleSignOn.close();
        CustomNotifierService.getSuccessUpdate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.ssoForm, this.notifier);
      }
      this.loader = false;
    });
  }

  chooseFile() {
    const element: HTMLElement = document.getElementById('inputFile') as HTMLElement;
    element.click();
  }
  onFileChange(event) {
    this.picture.setValue('');

    const reader = new FileReader();

    if (event.target && event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.picture.setValue(reader.result);
      };
    } else {
      this.picture.setValue(event.file);
    }
    const element: HTMLInputElement = document.getElementById('inputFile') as HTMLInputElement;
    element.value = '';
  }
  checkPicture() {
    if (this.picture.value.indexOf(';base64,') !== -1) {
      return this.picture.value;
    } else {
      return environment.SERVER_HOST + 'img/sso_configs/' + this.picture.value;
    }
  }
  removeFile() {
    this.picture.setValue(null);

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  binModal(modal) {
    this.modalSingleSignOn = modal;
  }

  closeModal() {
    this.allSingleSignOn.getSingleSignOn();
    this.router.navigate(['single-sign-on']).then(() => {});
  }
}
