import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AllOffersModule } from './all-offers/all-offers.module';
import { UpdateOfferModule } from './update-offer/update-offer.module';

import { SubscriptionsRequestService } from '../../services/subscriptions.request.service';

@NgModule({
  imports: [
    CommonModule,
    AllOffersModule,
    UpdateOfferModule
  ],
  providers: [
    SubscriptionsRequestService
  ]
})
export class OffersModule {}
