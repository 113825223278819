<app-modal-right (eventOnClose)="closeModal()" #tooltip>{{ binModal(tooltip) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">
          Consulter un tooltip
        </h6>

        <h1 class="header-title">{{ checkpoint && checkpoint.full_name }}</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <div *ngIf="!loaderContent">
      <div class="row">

        <div class="col-12" *ngFor="let reference of references">
          <div class="card bg-light border">
            <div class="card-body">
              <div class="is-loading loader-content" *ngIf="loader"></div>

              <div class="bandeau" *ngIf="!loader">
                <div>
                  <span [innerHTML]="reference"></span>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

</app-modal-right>
