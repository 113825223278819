import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';

import { AllEmployeesComponent } from '../all-employees/all-employees.component';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { TrainingsRequestService } from '../../../services/trainings.request.service';

@Component({
  selector: 'app-update-employee',
  templateUrl: './update-employee.component.html',
  styleUrls: ['./update-employee.component.scss']
})
export class UpdateEmployeeComponent implements OnInit, AfterViewInit, OnDestroy {
  canCreate = false;
  canUpdate = false;

  employeeForm: FormGroup;
  id = new FormControl('');
  establishment_id = new FormControl('');
  registry_id = new FormControl('');
  firstname = new FormControl('', Validators.required);
  lastname = new FormControl('', Validators.required);
  email = new FormControl('', [Validators.required, Validators.email]);

  errors = {};

  isNew = true;
  loader = false;
  loaderContent = false;

  modalEmployee = null;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private allEmployees: AllEmployeesComponent,
    private trainingsService: TrainingsRequestService
  ) {
  }

  ngOnInit() {
    this.employeeForm = this.formBuilder.group({
      id: this.id,
      establishment_id: this.activatedRoute.snapshot.parent.params.establishment_id,
      registry_id: this.activatedRoute.snapshot.parent.params.registry_id,
      firstname: this.firstname,
      lastname: this.lastname,
      email: this.email
    });

    if (this.activatedRoute.snapshot.params.employee_id) {
      this.isNew = false;
      this.getEmployee();
    }
  }

  ngAfterViewInit(): void {
    this.modalEmployee.open();
  }

  ngOnDestroy(): void {
    this.modalEmployee.close();
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  getEmployee() {
    this.loaderContent = true;
    this.trainingsService.getEmployee(this.activatedRoute.snapshot.params.employee_id).subscribe(response => {
      if (response['success']) {
        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];
        this.employeeForm.patchValue(response['employee']);
        if (!this.canUpdate) {
          this.employeeForm.disable();
        }
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loaderContent = false;
    });
  }

  createEmployee() {
    this.errors = {};
    this.loader = true;

    this.trainingsService.createEmployee(this.employeeForm.value).subscribe(response => {
      if (response['success']) {
        this.modalEmployee.close();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loader = false;
    });
  }

  updateEmployee() {
    let employee;
    this.errors = {};
    this.loader = true;
    employee = this.employeeForm.value;

    this.trainingsService.updateEmployee(employee).subscribe(response => {
      if (response['success']) {
        this.modalEmployee.close();
        CustomNotifierService.getSuccessUpdate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loader = false;
    });
  }

  bindModal(modal) {
    this.modalEmployee = modal;
  }

  closeModal() {
    this.router.navigate([this.activatedRoute.snapshot.parent.url.join('/')]).then(() => {
      this.allEmployees.refreshData();
    });
  }
}
