<div class="layout-container">
  <div class="layout">
    <div class="sidenav-container">
      <app-sidenav></app-sidenav>
    </div>
    <div class="toolbar-container">
      <app-toolbar></app-toolbar>
      <div class="content-container">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>

