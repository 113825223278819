import { Routes } from '@angular/router';

import { AllOffersComponent } from './all-offers/all-offers.component';
import { UpdateOfferComponent } from './update-offer/update-offer.component';

export const offersRoutes: Routes = [
  {
    path: 'offers',
    component: AllOffersComponent,
    children: [
      {
        path: 'create',
        component: UpdateOfferComponent
      },
      {
        path: ':offer_id',
        component: UpdateOfferComponent
      }
    ]
  }
];
