<app-modal-right (eventOnClose)="closeModal()" #establishmentDescriptive>{{ bindModal(establishmentDescriptive) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">{{ canUpdate ? 'Mise à jour' : 'Consultation' }}</h6>

        <h1 class="header-title">Établissement</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <form class="mb-4" [formGroup]="establishmentForm" [hidden]="loaderContent">

          <div class="form-group">
            <label>Description de l'établissement</label>
            <editor initialValue=""
                    formControlName="description"
                    [init]="{ height: 500, menubar: false, plugins: ['link preview table code lists'],
                                toolbar:'undo redo | formatselect fontselect fontsizeselect | ' +
                                'bold italic underline strikethrough backcolor forecolor | preview | ' +
                                'alignleft aligncenter alignright alignjustify | ' +
                                'bullist numlist outdent indent link table'
                              }">
            </editor>
          </div>

    </form>
  </div>

  <div modal-footer class="w-100 d-flex justify-content-between">
    <button class="btn btn-primary w-30" (click)="closeModal()">
      Annuler
    </button>
    <button class="btn btn-primary w-30"
            (click)="updateEstablishment()" [ngClass]="{ 'is-loading is-loading-sm': loader }"
            [disabled]="loader || establishmentForm.invalid || !canUpdate">
      Mettre à jour
    </button>
  </div>
</app-modal-right>
