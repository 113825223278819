import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AllSanitaryOperationsModule } from './all-sanitary-operations/all-sanitary-operations.module';

import { UpdateSanitaryOperationModule } from './update-sanitary-operation/update-sanitary-operation.module';
import { UpdateSanitaryOperationFrameModule } from './update-sanitary-operation-frame/update-sanitary-operation-frame.module';
import { AllSanitaryOperationFramesModule } from './all-sanitary-operation-frames/all-sanitary-operation-frames.module';

import { SanitaryOperationsRequestService } from '../../services/sanitary-operations.request.service';
import { SanitaryOperationFramesRequestService } from '../../services/sanitary-operation-frames.request.service';

@NgModule({
  imports: [
    CommonModule,
    AllSanitaryOperationsModule,
    UpdateSanitaryOperationModule,
    AllSanitaryOperationFramesModule,
    UpdateSanitaryOperationFrameModule,
  ],
  providers: [
    SanitaryOperationsRequestService,
    SanitaryOperationFramesRequestService
  ]
})
export class SanitaryOperationsModule {}
