import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { UploadPreventionFileComponent } from './upload-prevention-file.component';
import { ModalRightModule } from '../../../core/modal-right/modal-right.module';
import { ConfirmModalModule } from '../../../core/confirm-modal/confirm-modal.module';
import { UtilsModule } from '../../../core/utils/utils.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    ModalRightModule,
    ConfirmModalModule,
    UtilsModule
  ],
  declarations: [
    UploadPreventionFileComponent
  ]
})

export class UploadPreventionFileModule {}
