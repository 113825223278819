<div class="main-content">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col">
        <div class="header mb-0">
          <div class="header-body no-border">
            <div class="row align-items-center">
              <div class="col">
                <h1 class="header-title">Unités de travail</h1>

                <small class="form-text text-muted">Edition de masse des affichages des unités de travail</small>

                <app-breadcrumbs [currentPage]="pageName" [names]="previousPageName" [routes]="previousPageRoute"></app-breadcrumbs>
              </div>

              <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0">
                <a class="btn btn-primary d-block d-md-inline-block" [routerLink]="" (click)="updateShow()">
                  Valider
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="is-loading loader-content" *ngIf="loader"></div>

        <div class="tab-content" [hidden]="loader || !unities.length">
          <div class="tab-pane fade show active" id="tabPaneOne" role="tabpanel">
            <div class="row listAlias">

              <!-- NEWS -->
              <div class="col-12">
                <div class="card bg-light border">
                  <div class="card-body">
                    Cette fonctionnalité vous permet de décocher les unités de travail que vous souhaitez retirer du tableau de bord car non présentes dans votre établissement
                  </div>
                </div>
              </div>

              <div class="col-12 d-flex flex-column">
                <div *ngFor="let unity of unities; let keyUnity = index">
                  <div class="card flex-fill">
                    <div class="card-body">
                      <div class="row align-items-center">
                        <div class="col overflow-hidden">
                          <h4 class="card-title mb-2 name text-truncate">
                            <a class="fix-font">
                              {{ unity['risk_unity_work']['name'] }}
                            </a>
                          </h4>
                        </div>

                        <div class="col-auto align-self-start d-flex">

                          <a class="href fix-checkbox" (click)="checkUnity(keyUnity)">
                            <i class="fe fe-check-square fe-black" *ngIf="unity.show_unity_work"></i>
                            <i class="fe fe-square fe-black" *ngIf="!unity.show_unity_work"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="loader-content" *ngIf="!loader && !unities.length">
          <p>Aucun résultat</p>
        </div>
      </div>
    </div>
  </div>
</div>
