<app-modal-right (eventOnClose)="closeModal()" #date_training
  >{{ binModal(date_training) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">
          {{ isNew ? "Création" : canUpdate ? "Mise à jour" : "Consultation" }}
        </h6>
        <h1 class="header-title">Date de formation</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <form [formGroup]="dateTrainingForm" *ngIf="!loaderContent">
      <div class="row">
        <div class="col-12">
          <!-- DATE -->
          <div class="form-group">
            <label for="validationDateFormationFormDate" required
              >Date de formation</label
            >
            <input
              type="date"
              id="validationDateFormationFormDate"
              formControlName="date"
              class="form-control"
              data-toggle="flatpickr"
              [placeholder]="today | date: 'dd/MM/yyyy'"
              [ngClass]="{
                'is-invalid': date.invalid && (date.dirty || date.touched)
              }"
            />
            <div
              *ngIf="date.invalid && (date.dirty || date.touched)"
              class="invalid-feedback"
            >
              {{ getErrorMessage("date") }}
            </div>
          </div>

          <!-- DURATION -->
          <div class="form-group">
            <label for="validationDateFormationFormDuration">Durée</label>
            <input
              type="text"
              id="validationDateFormationFormDuration"
              formControlName="duration"
              class="form-control"
              [ngClass]="{
                'is-invalid':
                  duration.invalid && (duration.dirty || duration.touched)
              }"
              data-toggle="flatpickr_time"
            />
            <div
              *ngIf="duration.invalid && (duration.dirty || duration.touched)"
              class="invalid-feedback"
            >
              {{ getErrorMessage("duration") }}
            </div>
          </div>

          <!-- ESTIMATED COST -->
          <div class="form-group">
            <label for="validationDateFormationFormEstimatedCost"
              >Coût estimé (€)</label
            >
            <input
              type="number"
              id="validationDateFormationFormEstimatedCost"
              formControlName="estimated_cost"
              class="form-control"
              min="0"
              [placeholder]="
                estimated_cost === null ? 'Pas de coût estimé renseigné' : ''
              "
              [ngClass]="{
                'is-invalid':
                  estimated_cost.invalid &&
                  (estimated_cost.dirty || estimated_cost.touched)
              }"
            />
            <div
              *ngIf="
                estimated_cost.invalid &&
                (estimated_cost.dirty || estimated_cost.touched)
              "
              class="invalid-feedback"
            >
              {{ getErrorMessage("estimated_cost") }}
            </div>
          </div>

          <!-- REAL COST -->
          <div class="form-group">
            <label for="validationDateFormationFormRealCost"
              >Coût réel (€)</label
            >
            <input
              type="number"
              id="validationDateFormationFormRealCost"
              formControlName="real_cost"
              class="form-control"
              min="0"
              [placeholder]="
                real_cost === null ? 'Pas de coût réel renseigné' : ''
              "
              [ngClass]="{
                'is-invalid':
                  real_cost.invalid && (real_cost.dirty || real_cost.touched)
              }"
            />
            <div
              *ngIf="
                real_cost.invalid && (real_cost.dirty || real_cost.touched)
              "
              class="invalid-feedback"
            >
              {{ getErrorMessage("real_cost") }}
            </div>
          </div>

          <!-- INSTRUCTOR -->
          <div class="form-group">
            <label for="validationDateFormationFormInstructor" required
              >Formateur</label
            >
            <input
              type="text"
              class="form-control"
              id="validationDateFormationFormInstructor"
              formControlName="instructor"
              placeholder="nom@mail.com"
              [ngClass]="{
                'is-invalid':
                  instructor.invalid && (instructor.dirty || instructor.touched)
              }"
            />
            <div
              *ngIf="
                instructor.invalid && (instructor.dirty || instructor.touched)
              "
              class="invalid-feedback"
            >
              {{ getErrorMessage("instructor") }}
            </div>
          </div>

          <!-- PLACE -->
          <div class="form-group">
            <label for="validationDateFormationFormPlace" required>Lieu</label>
            <input
              type="text"
              class="form-control"
              id="validationDateFormationFormPlace"
              formControlName="place"
              placeholder="Paris"
              [ngClass]="{
                'is-invalid': place.invalid && (place.dirty || place.touched)
              }"
            />
            <div
              *ngIf="place.invalid && (place.dirty || place.touched)"
              class="invalid-feedback"
            >
              {{ getErrorMessage("place") }}
            </div>
          </div>

          <!-- COMMENTS -->
          <div class="form-group">
            <label for="validationDateFormationFormComments"
              >Commentaires</label
            >
            <textarea
              id="validationDateFormationFormComments"
              formControlName="comments"
              class="form-control"
              placeholder="Apporter ses EPIs, ..."
              [ngClass]="{
                'is-invalid':
                  comments.invalid && (comments.dirty || comments.touched)
              }"
            ></textarea>
            <div
              *ngIf="comments.invalid && (comments.dirty || comments.touched)"
              class="invalid-feedback"
            >
              {{ getErrorMessage("comments") }}
            </div>
          </div>

          <!-- USERS -->
          <div class="form-group">
            <label for="listUsers">Personnels présents à cette formation</label>
            <ng-select
              [items]="employees"
              [multiple]="true"
              bindLabel="fullname"
              bindValue="id"
              groupBy="group"
              notFoundText="Aucun résultat"
              placeholder="Sélectionnez un ou plusieurs utilisateur(s)"
              [selectableGroup]="true"
              [selectableGroupAsModel]="false"
              [closeOnSelect]="false"
              [(ngModel)]="selectedEmployees"
              [ngModelOptions]="{ standalone: true }"
              [disabled]="!isNew && !canUpdate"
            >
              <ng-template
                ng-optgroup-tmp
                let-item="item"
                let-item$="item$"
                let-index="index"
              >
                <input
                  type="checkbox"
                  [ngModel]="item$.selected"
                  [ngModelOptions]="{ standalone: true }"
                />&nbsp; Tout sélectionner
              </ng-template>
              <ng-template
                ng-option-tmp
                let-item="item"
                let-item$="item$"
                let-index="index"
              >
                <input
                  type="checkbox"
                  id="listUsers"
                  [ngModel]="item$.selected"
                  [ngModelOptions]="{ standalone: true }"
                />
                {{ item.fullname }}
              </ng-template>
            </ng-select>
          </div>

          <!-- FILES -->
          <div class="form-group" *ngIf="isNew || canUpdate">
            <label for="inputFile">Documents</label>

            <div
              class="dropzone dropzone-multiple dz-clickable"
              appDragDrop
              (dropHandler)="onFileChange($event)"
            >
              <div class="dz-default dz-message" (click)="chooseFile()">
                <span>Déposer vos fichiers ici pour télécharger</span>
              </div>

              <input
                id="inputFile"
                (change)="onFileChange($event)"
                type="file"
                [hidden]="true"
                multiple
              />
            </div>
          </div>

          <!-- FILES LIST -->
          <div class="card" *ngFor="let file of files; let index = index">
            <div class="card-body">
              <div class="d-flex align-content-center justify-content-between">
                <h4 class="card-title mb-0 name align-self-center">
                  {{ file.name + "." + file.extension }}
                </h4>

                <a
                  class="href tooltipHtml"
                  data-toggle="dropdown"
                  data-placement="bottom"
                  title="Supprimer"
                  (click)="removeFile(index)"
                >
                  <i class="fe fe-trash"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div modal-footer class="w-100" *ngIf="isNew || canUpdate">
    <button
      class="btn btn-primary w-100"
      (click)="isNew ? createDateTraining() : updateDateTraining()"
      [ngClass]="{ 'is-loading is-loading-sm': loader }"
      [disabled]="loader || dateTrainingForm.invalid"
    >
      {{ isNew ? "Créer" : "Mettre à jour" }}
    </button>
  </div>
</app-modal-right>
