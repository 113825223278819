<app-modal-right (eventOnClose)="closeModal()" #operationFrame>
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">{{ isNew ? 'Création' : canUpdate ? 'Mise à jour' : 'Consultation' }}</h6>

        <h1 class="header-title">Fiche de suivi</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>
    <form [formGroup]="sanitaryOperationFrameForm" [hidden]="loaderContent">

      <!-- TITLE -->
      <div class="form-group">
        <label for="validationTitle" required>Titre</label>
        <input type="text" formControlName="title" class="form-control" id="validationTitle" placeholder="Titre" [ngClass]="{ 'is-invalid': title.invalid && (title.dirty || title.touched) }">
      </div>

      <!-- DATE -->
      <div class="form-group">
        <label for="validationDate" required>Année (préciser la date de réalisation)</label>
        <input type="text" formControlName="date" class="form-control" id="validationDate" placeholder="Date de réalisation" data-toggle="flatpickr" [ngClass]="{ 'is-invalid': date.invalid && (date.dirty || date.touched) }">
      </div>

      <!-- CONTRIBUTOR IF WEEKLY FRAME -->
      <div class="form-group" [hidden]="periodicity !== 'weekly'">
        <label required>Intervenant</label>
        <ng-select [items]="contributors"
                   [multiple]="false"
                   bindLabel="full_name"
                   bindValue="id"
                   groupBy="external"
                   notFoundText="Aucun résultat"
                   [selectableGroup]="false"
                   [selectableGroupAsModel]="false"
                   [closeOnSelect]="true"
                   placeholder="Séléctionnez un intervenant"
                   formControlName="contributor_id">
          <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
            {{ item.external ? 'Externe' : 'Interne' }}
          </ng-template>
          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
            {{ item.full_name }}
          </ng-template>
        </ng-select>
      </div>

      <!-- SHOW DESCRIPTION[0] FOR CONFIGURE USAGE POINT WHEN FRAME IS WEEKLY-->
      <div formArrayName="descriptions" class="mb-4" *ngIf="periodicity === 'weekly'">
        <div [formGroupName]="indexFirstDescription" *ngFor="let description of sanitaryOperationFrameForm['controls'].descriptions['controls'] as FormGroup; let indexFirstDescription = index;">
          <div class="card" *ngIf="indexFirstDescription === 0">

            <div class="card-header">
              <div class="d-flex align-items-center">
                <i data-toggle="collapse" [attr.href]="'#firstDescriptions' + indexFirstDescription" aria-expanded="true" class="fe chevron mr-2 href"></i>
                <p class="mb-0 my-sm-3 mx-sm-auto mx-lg-0">{{ description['controls'].title.value }}</p>
              </div>
            </div>

            <!-- FOR EACH IN VALUES -->
            <div formArrayName="values" class="card-body collapse show" [id]="'firstDescriptions' + indexFirstDescription" [attr.id]="'firstDescriptions' + indexFirstDescription" role="tabpanel" [attr.data-parent]="'#firstDescriptions' + indexFirstDescription">

              <div [formGroupName]="indexValue" class="form-group" *ngFor="let value of description['controls'].values['controls'] as FormGroup; let indexValue = index;">
                  <label required>{{ value['controls'].title.value }}</label>

                  <div formArrayName="comments" *ngIf="indexValue < 2 || my_frame">
                    <div [formGroupName]="indexComment" *ngFor="let comment of value['controls'].comments['controls'] as FormGroup; let indexComment = index; let firstCommentWeekly = first" class="row mb-2">

                      <div [ngClass]="{'col-12' : firstCommentWeekly, 'col-10' : indexFirstDescription === 0 && !firstCommentWeekly }">
                        <input type="text" [placeholder]="my_frame ? 'Identification' : indexValue === 0 ? 'Identification du point d\'usage' : 'Identification du ballon'" formControlName="title" class="form-control d-inline-block" (keyup)="configureUsagePoint(indexFirstDescription, indexValue, indexComment)" [ngClass]="{'is-invalid': comment['controls'].title.invalid && (comment['controls'].title.dirty || comment['controls'].title.touched) }">
                        <div *ngIf="comment['controls'].title.invalid && (comment['controls'].title.dirty || comment['controls'].title.touched)" class="invalid-feedback">
                          {{ getErrorMessage("title") }}
                        </div>
                      </div>
                      <a class="href tooltipHtml col-2 d-flex align-items-center justify-content-center" data-placement="bottom" title="Supprimer" (click)="deleteComment(indexFirstDescription, indexValue, indexComment)" *ngIf="!firstCommentWeekly">
                        <i class="fe fe-trash"></i>
                      </a>
                    </div>

                    <button class="btn btn-primary col-6 offset-3 mt-2" (click)="addNewComment(indexFirstDescription, indexValue)">
                      Ajouter une identification
                    </button>
                  </div>

              </div>

            </div>

          </div>
        </div>
      </div>

      <!-- SELECT FOR ADD VALUE IN FRAME -->
      <div class="form-group" *ngIf="listItems.length">
        <label required>Selectionner une valeur afin de remplir la fiche de suivi</label>
        <ng-select [items]="listItems"
                   [multiple]="false"
                   bindLabel="title"
                   bindValue="title"
                   [selectableGroup]="false"
                   [selectableGroupAsModel]="false"
                   [closeOnSelect]="true"
                   placeholder="Selectionner une valeur afin de remplir la fiche de suivi"
                   [(ngModel)]="selectedDescription" (ngModelChange)="changeDescription()" [ngModelOptions]="{ standalone: true }">
          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
            {{ item.title }}
          </ng-template>
        </ng-select>
      </div>

      <!-- FORM ARRAY -->
      <div formArrayName="descriptions" class="mb-4" *ngIf="selectedDescription">
        <!-- FOR EACH IN DESCRIPTION -->
        <div [formGroupName]="indexDescription" *ngFor="let description of sanitaryOperationFrameForm['controls'].descriptions['controls'] as FormGroup; let indexDescription = index; let firstDescription = first; let last = last">
          <div class="card" *ngIf="description['controls'].title.value === selectedDescription">

            <div class="card-header">
              <div class="d-flex align-items-center">
                <i data-toggle="collapse" [attr.href]="'#descriptions' + indexDescription" aria-expanded="true" class="fe chevron mr-2 href"></i>
                <p class="mb-0 my-sm-3 mx-sm-auto mx-lg-0">{{ description['controls'].title.value }}</p>
              </div>
            </div>

            <!-- FOR EACH IN VALUES -->
            <div formArrayName="values" class="card-body collapse show" [id]="'descriptions' + indexDescription" [attr.id]="'descriptions' + indexDescription" role="tabpanel" [attr.data-parent]="'#descriptions' + indexDescription" *ngIf="description['controls'].title.value === selectedDescription">

              <div [formGroupName]="indexValue" class="form-group" *ngFor="let value of description['controls'].values['controls'] as FormGroup; let indexValue = index; let firstValue = first">

                <!-- FRAME FORM : MONTHLY / QUARTERLY / ANNUAL -->
                <ng-container *ngIf="periodicity === 'monthly' || periodicity === 'quarterly' || periodicity === 'annual'">

                  <ng-container *ngIf="firstValue">
                    <label>{{ value['controls'].title.value }}</label>

                    <input type="text" formControlName="comment" class="form-control" placeholder="Date de réalisation" data-toggle="flatpickr" [ngClass]="{ 'is-invalid': value['controls'].comment.invalid && (value['controls'].comment.dirty || value['controls'].comment.touched) }">
                    <div *ngIf="value['controls'].comment.invalid && (value['controls'].comment.dirty || value['controls'].comment.touched)" class="invalid-feedback">
                      {{ getErrorMessage("comment") }}
                    </div>
                  </ng-container>

                  <ng-container *ngIf="indexValue === 1">
                    <div class="form-group">
                      <label>{{ value['controls'].title.value }}</label>

                      <ng-select [items]="contributors"
                                 [multiple]="false"
                                 bindLabel="full_name"
                                 bindValue="id"
                                 groupBy="external"
                                 notFoundText="Aucun résultat"
                                 [selectableGroup]="false"
                                 [selectableGroupAsModel]="false"
                                 [closeOnSelect]="true"
                                 placeholder="Séléctionnez un intervenant"
                                 formControlName="comment">
                        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                          {{ item.external ? 'Externe' : 'Interne' }}
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                          {{ item.full_name }}
                        </ng-template>
                      </ng-select>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="!firstValue && indexValue > 1">
                    <label>{{ value['controls'].title.value }}</label>

                    <input type="text" placeholder="{{ value['controls'].title.value }}" formControlName="comment" class="form-control d-inline-block col-12" [ngClass]="{'is-invalid': value['controls'].comment.invalid && (value['controls'].comment.dirty || value['controls'].comment.touched) }">
                    <div *ngIf="value['controls'].comment.invalid && (value['controls'].comment.dirty || value['controls'].comment.touched)" class="invalid-feedback">
                      {{ getErrorMessage("comment") }}
                    </div>
                  </ng-container>
                </ng-container>

                <!-- FRAME FORM : BIANNUAL -->
                <ng-container *ngIf="periodicity === 'biannual'">

                  <div formArrayName="comments" class="row">
                    <label class="col-12"><strong>{{ value['controls'].title.value }}</strong></label>

                    <div [formGroupName]="indexItem" class="col-6" *ngFor="let item of value['controls'].comments['controls'] as FormGroup; let indexItem = index; let firstCommentBiannual = first">
                      <ng-container *ngIf="indexValue === 0">
                        <div class="form-group">
                          <label>{{ 'Semestre ' + (indexItem + 1) }}</label>
                          <input type="text" formControlName="comment" class="form-control" placeholder="Date de réalisation" data-toggle="flatpickr" [ngClass]="{ 'is-invalid': item['controls'].comment.invalid && (item['controls'].comment.dirty || item['controls'].comment.touched) }">
                          <div *ngIf="item['controls'].comment.invalid && (item['controls'].comment.dirty || item['controls'].comment.touched)" class="invalid-feedback">
                            {{ getErrorMessage("comment") }}
                          </div>
                        </div>
                      </ng-container>

                      <ng-container *ngIf="indexValue === 1">
                        <div class="form-group">
                          <label>{{ 'Semestre ' + (indexItem + 1) }}</label>

                          <ng-select [items]="contributors"
                                     [multiple]="false"
                                     bindLabel="full_name"
                                     bindValue="id"
                                     groupBy="external"
                                     notFoundText="Aucun résultat"
                                     [selectableGroup]="false"
                                     [selectableGroupAsModel]="false"
                                     [closeOnSelect]="true"
                                     placeholder="Séléctionnez un intervenant"
                                     formControlName="comment">
                            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                              {{ item.external ? 'Externe' : 'Interne' }}
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                              {{ item.full_name }}
                            </ng-template>
                          </ng-select>
                        </div>
                      </ng-container>

                      <ng-container *ngIf="indexValue === 2">
                        <div class="form-group">
                          <label>{{ 'Semestre ' + (indexItem + 1) }}</label>

                          <input type="text" placeholder="{{ value['controls'].title.value }}" formControlName="comment" class="form-control d-inline-block" [ngClass]="{'is-invalid': item['controls'].comment.invalid && (item['controls'].comment.dirty || item['controls'].comment.touched) }">
                          <div *ngIf="item['controls'].comment.invalid && (item['controls'].comment.dirty || item['controls'].comment.touched)" class="invalid-feedback">
                            {{ getErrorMessage("comment") }}
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </ng-container>

                <!-- FRAME FORM : WEEKLY -->
                <ng-container *ngIf="periodicity === 'weekly'">
                  <label *ngIf="firstDescription" required>{{ value['controls'].title.value }}</label>
                  <label *ngIf="!firstDescription">{{ value['controls'].title.value }}</label>

                  <div formArrayName="comments" *ngIf="indexValue < 2 || my_frame">
                    <div [formGroupName]="indexComment" *ngFor="let comment of value['controls'].comments['controls'] as FormGroup; let indexComment = index; let firstCommentWeekly = first" class="row mb-2">

                      <div [ngClass]="{'col-12' : (indexDescription === 0 && firstCommentWeekly), 'col-10' : indexDescription === 0 && !firstCommentWeekly, 'col-6': indexDescription !== 0}">
                        <input type="text" [placeholder]="my_frame ? 'Identification' : indexValue === 0 ? 'Identification du point d\'usage' : 'Identification du ballon'" formControlName="title" class="form-control d-inline-block" (keyup)="configureUsagePoint(indexDescription, indexValue, indexComment)" [ngClass]="{'is-invalid': comment['controls'].title.invalid && (comment['controls'].title.dirty || comment['controls'].title.touched) }">
                        <div *ngIf="comment['controls'].title.invalid && (comment['controls'].title.dirty || comment['controls'].title.touched)" class="invalid-feedback">
                          {{ getErrorMessage("title") }}
                        </div>
                      </div>

                      <div class="col-6" *ngIf="!firstDescription">
                        <input type="text" placeholder="{{ value['controls'].title.value }}" formControlName="comment" class="form-control d-inline-block" [ngClass]="{'is-invalid': comment['controls'].comment.invalid && (comment['controls'].comment.dirty || comment['controls'].comment.touched) }">
                        <div *ngIf="comment['controls'].comment.invalid && (comment['controls'].comment.dirty || comment['controls'].comment.touched)" class="invalid-feedback">
                          {{ getErrorMessage("comment") }}
                        </div>
                      </div>

                      <a class="href tooltipHtml col-2 d-flex align-items-center justify-content-center" data-placement="bottom" title="Supprimer" (click)="deleteComment(indexDescription, indexValue, indexComment)" *ngIf="!firstCommentWeekly && firstDescription">
                        <i class="fe fe-trash"></i>
                      </a>
                    </div>

                    <button class="btn btn-primary col-6 offset-3 mt-2" (click)="addNewComment(indexDescription, indexValue)" *ngIf="firstDescription">
                      Ajouter une identification
                    </button>
                  </div>

                  <ng-container *ngIf="indexValue > 1 && !firstDescription && !my_frame">
                    <input type="text" placeholder="{{ value['controls'].title.value }}" formControlName="comment" class="form-control d-inline-block col-12" [ngClass]="{'is-invalid': value['controls'].comment.invalid && (value['controls'].comment.dirty || value['controls'].comment.touched) }">
                    <div *ngIf="value['controls'].comment.invalid && (value['controls'].comment.dirty || value['controls'].comment.touched)" class="invalid-feedback">
                      {{ getErrorMessage("comment") }}
                    </div>
                  </ng-container>

                </ng-container>

              </div>

            </div>

          </div>
        </div>
      </div>

      <!-- ADD DETECTED ANOMALIES AND INTERVENTIONS IF WEEKLY/MONTHLY/QUARTERLY FRAME -->
      <ng-container *ngIf="periodicity === 'weekly' || periodicity === 'monthly' || periodicity === 'quarterly'">
        <div class="form-group">
          <label for="validationDetectionAnomaly">Anomalie(s) détectée(s)</label>
          <textarea formControlName="detected_anomalies" class="form-control" id="validationDetectionAnomaly" placeholder="Anomalie(s) detectée(s)" rows="5"
                    [ngClass]="{ 'is-invalid': detected_anomalies.invalid && (detected_anomalies.dirty || detected_anomalies.touched) }"></textarea>
          <div *ngIf="detected_anomalies.invalid && (detected_anomalies.dirty || detected_anomalies.touched)" class="invalid-feedback">
            {{ getErrorMessage("detected_anomalies") }}
          </div>
        </div>

        <div class="form-group">
          <label for="validationIntervention">Intervention(s)</label>
          <textarea formControlName="interventions" class="form-control" id="validationIntervention" placeholder="Intervention(s)" rows="5"
                    [ngClass]="{ 'is-invalid': interventions.invalid && (interventions.dirty || interventions.touched) }"></textarea>
          <div *ngIf="interventions.invalid && (interventions.dirty || interventions.touched)" class="invalid-feedback">
            {{ getErrorMessage("interventions") }}
          </div>
        </div>
      </ng-container>

    </form>
  </div>

  <div modal-footer class="w-100" *ngIf="isNew || canUpdate">
    <button class="btn btn-primary w-100" (click)="isNew ? createSanitaryOperationFrame() : updateSanitaryOperationFrame()" [ngClass]="{ 'is-loading is-loading-sm': loader }" [disabled]="loader || sanitaryOperationFrameForm.invalid">
      {{ isNew ? 'Créer' : 'Mettre à jour' }}
    </button>
  </div>
</app-modal-right>
