import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { NotifierService } from 'angular-notifier';
import { StatisticsRequestService } from '../../../services/statistics.request.service';
import { Subject, Subscription } from 'rxjs';
import { AuthService } from '../../../core/auth/auth.service';

declare var $: any;

declare function UserCharts(): any;
declare function Flatpickr(): any;

@Component({
  selector: 'app-dashboard-unique-document',
  templateUrl: './dashboard-unique-document.component.html',
  styleUrls: ['./dashboard-unique-document.component.scss']
})
export class DashboardUniqueDocumentComponent implements OnInit, OnDestroy {
  changeEnterprise: Subscription;
  establishments = [];
  selectedEstablishments = null;
  riskUnities = [];
  selectedRiskUnities = null;
  userChart = UserCharts();
  installations;
  checkpoints;
  periods = [
    { value: 'custom', name: 'Période personnalisée' },
    { value: 'days', name: '7 derniers jours' },
    { value: 'month', name: '30 derniers jours' },
    { value: 'months', name: '3 derniers mois' },
    { value: 'year', name: 'Année en cours' },
    { value: 'lastyear', name: 'Année dernière' },
    { value: 'lastyears', name: 'Il y a deux ans' }
  ];
  period = 'custom';
  dateFrom;
  dateTo;
  groupBy = 'month';

  JSON = JSON;

  modal;

  familyName = '';
  families = [];
  cotationName = '';
  cotation = [];

  allRisks = [];
  riskFamilies = [];
  riskFamiliesLimit = 12;
  riskFamiliesCount = 0;
  riskFamiliesPage = 1;
  riskFamiliesPages = 1;

  allRisksGravity = [];
  risksGravity = [];
  risksGravityLimit = 12;
  risksGravityCount = 0;
  risksGravityPage = 1;
  risksGravityPages = 1;

  allRisksFrequencies = [];
  risksFrequencies = [];
  risksFrequenciesLimit = 12;
  risksFrequenciesCount = 0;
  risksFrequenciesPage = 1;
  risksFrequenciesPages = 1;

  allRisksMastery = [];
  risksMastery = [];
  risksMasteryLimit = 12;
  risksMasteryCount = 0;
  risksMasteryPage = 1;
  risksMasteryPages = 1;

  criticName = '';
  criticFilter = '';
  allRisksCritics = [];
  risksCriticFilter = [];
  risksCriticFilterLimit = 12;
  risksCriticFilterCount = 0;
  risksCriticFilterPage = 1;
  risksCriticFilterPages = 1;

  actionName = '';
  actionFilter = '';
  allActions = [];
  actions = [];
  actionsLimit = 12;
  actionsCount = 0;
  actionsPage = 1;
  actionsPages = 1;

  loaderRisksByFamily = false;
  loaderRisksByGravity = false;
  loaderRisksByFrequencies = false;
  loaderRisksByMastery = false;
  loaderRisksByCritic = false;
  loaderActionsByState = false;

  requestInProgress = new Subject();
  requestCount = 0;
  loader = false;

  constructor(
    private _rootNode: ElementRef,
    private authService: AuthService,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private statisticsService: StatisticsRequestService
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loader = this.requestCount > 0;
    });
    this.changeEnterprise = this.authService.getEnterprise().subscribe(() => {
      this.initUserCharts();
      Flatpickr();
    });

    if (this.authService.loaded) {
      this.initUserCharts();
      setTimeout(function () {
        Flatpickr();
      }, 100);
    }

    // Remove background opacity bug if modal closed too fast
    const modalBackdrop = document.getElementsByClassName('modal-backdrop fade show') as HTMLCollection;
    for (let i = 0; i < modalBackdrop.length; i++) {
      modalBackdrop[i].remove();
    }
    const body = document.getElementsByTagName('body');
    for (let i = 0; i < body.length; i++) {
      body[i].className = body[i].className.replace('modal-open', '');
    }
  }

  ngOnDestroy() {
    this.changeEnterprise.unsubscribe();

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }

    if (this.modal) {
      this.modal.modal('hide');
      this.selectedEstablishments = [];
    }
  }

  initUserCharts() {
    this.statisticsService.getEstablishmentsNumber('true', '').subscribe(response => {
      if (response['success']) {
        this.establishments = response['establishments'];
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
    });
    this.statisticsService.getRiskUnityWorks().subscribe(response => {
      if (response['success']) {
        this.riskUnities = response['riskUnities'];
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
    });
    this.getRiskFamilies();
    this.getRiskGravities();
    this.getRisksFrequencies();
    this.getRisksMastery();
    this.getRisksCritics();
    this.getRisksByState();
  }

  updateSelectedEstablishments() {
    this.getRiskFamilies();
    this.getRiskGravities();
    this.getRisksFrequencies();
    this.getRisksMastery();
    this.getRisksCritics();
    this.getRisksByState();
  }

  updateSelectedRiskUnities() {
    this.getRiskFamilies();
    this.getRiskGravities();
    this.getRisksFrequencies();
    this.getRisksMastery();
    this.getRisksCritics();
    this.getRisksByState();
  }

  getRiskFamilies() {
    this.loaderRisksByFamily = true;
    this.statisticsService.riskFamiliesStat(
      this.selectedEstablishments, this.selectedRiskUnities, '', ''
    ).subscribe(response => {
      if (response['success']) {
        this.allRisks = response['allRisks'];
        this.userChart.createRisksByFamilyChart('#risksByFamily', this.allRisks, this);
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loaderRisksByFamily = false;
    });
  }
  getAllRisksByFamily(content, familyIds = null) {
    this.requestInProgress.next(true);
    this.statisticsService.riskFamiliesStat(
      this.selectedEstablishments, this.selectedRiskUnities, this.riskFamiliesLimit, this.riskFamiliesPage, familyIds
    ).subscribe(response => {
        if (response['success']) {
          this.familyName = response['allRisks']['family']['name'];
          this.families = response['allRisks']['family']['family_id'];
          this.riskFamilies = response['allRisks']['risks'];
          this.riskFamiliesCount = response['allRisks']['count'];
          this.riskFamiliesPages = response['allRisks']['pages'];
          this.riskFamilies.forEach( element => {
            if (element.description && element.description.length > 50) {
              element.description = element.description.substr(0, 50) + '...';
            }
            if (element.current_protection && element.current_protection.length > 50) {
              element.current_protection = element.current_protection.substr(0, 50) + '...';
            }
            if (element.todo_protection && element.todo_protection.length > 50) {
              element.todo_protection = element.todo_protection.substr(0, 50) + '...';
            }
          });
          this.modal = $(this._rootNode.nativeElement).find('div#' + content);
          this.modal.modal('show');
        } else if (response['errors']) {
          CustomNotifierService.getErrors(response['errors'], this.notifier);
        }
        this.requestInProgress.next(false);
      });
  }
  dispatchPaginationAllRisksByFamily(data) {
    if (data.action === 'total_elements') {
      this.refreshPageAllRisksByFamily(data.value);
    } else if (data.action === 'change_page') {
      this.changePageAllRisksByFamily(data.value);
    }
  }
  refreshPageAllRisksByFamily(limit) {
    this.riskFamiliesLimit = limit;
    this.riskFamiliesPage = 1;
    this.getAllRisksByFamily('risksFamily', this.families);
  }
  changePageAllRisksByFamily(page) {
    if (page >= 1 && page <= this.riskFamiliesPages) {
      this.riskFamiliesPage = page;
      this.getAllRisksByFamily('risksFamily', this.families);
    }
  }

  getRiskGravities() {
    this.loaderRisksByGravity = true;
    this.statisticsService.riskByType(
      this.selectedEstablishments, this.selectedRiskUnities, '', '', 'gravity'
    ).subscribe(response => {
      if (response['success']) {
        this.allRisksGravity = response['risks'];
        this.userChart.createRisksByGravityChart('#risksByGravity', this.allRisksGravity, this);
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loaderRisksByGravity = false;
    });
  }
  getAllRisksByGravities(content, cotationId = null) {
    this.requestInProgress.next(true);
    this.statisticsService.riskByType(
      this.selectedEstablishments, this.selectedRiskUnities, this.risksGravityLimit, this.risksGravityPage, 'gravity', cotationId
    ).subscribe(response => {
      if (response['success']) {
        this.cotationName = response['risks']['cotation']['name'];
        this.cotation = response['risks']['cotation']['cotation_id'];
        this.risksGravity = response['risks']['risks'];
        this.risksGravityCount = response['risks']['count'];
        this.risksGravityPages = response['risks']['pages'];
        this.risksGravity.forEach( element => {
          if (element.description && element.description.length > 50) {
            element.description = element.description.substr(0, 50) + '...';
          }
          if (element.current_protection && element.current_protection.length > 50) {
            element.current_protection = element.current_protection.substr(0, 50) + '...';
          }
          if (element.todo_protection && element.todo_protection.length > 50) {
            element.todo_protection = element.todo_protection.substr(0, 50) + '...';
          }
        });
        this.modal = $(this._rootNode.nativeElement).find('div#' + content);
        this.modal.modal('show');
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }
  dispatchPaginationAllRisksByGravities(data) {
    if (data.action === 'total_elements') {
      this.refreshPageAllRisksByGravities(data.value);
    } else if (data.action === 'change_page') {
      this.changePageAllRisksByGravities(data.value);
    }
  }
  refreshPageAllRisksByGravities(limit) {
    this.risksGravityLimit = limit;
    this.risksGravityPage = 1;
    this.getAllRisksByGravities('risksGravity', this.cotation);
  }
  changePageAllRisksByGravities(page) {
    if (page >= 1 && page <= this.risksGravityPages) {
      this.risksGravityPage = page;
      this.getAllRisksByGravities('risksGravity', this.cotation);
    }
  }

  getRisksFrequencies() {
    this.loaderRisksByFrequencies = true;
    this.statisticsService.riskByType(
      this.selectedEstablishments, this.selectedRiskUnities, '', '', 'frequency'
    ).subscribe(response => {
      if (response['success']) {
        this.allRisksFrequencies = response['risks'];
        this.userChart.createRisksByFrequenciesChart('#risksByFrequencies', this.allRisksFrequencies, this);
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loaderRisksByFrequencies = false;
    });
  }
  getAllRisksByFrequencies(content, cotationId = null) {
    this.requestInProgress.next(true);
    this.statisticsService.riskByType(
      this.selectedEstablishments, this.selectedRiskUnities, this.risksFrequenciesLimit, this.risksFrequenciesPage, 'frequency', cotationId
    ).subscribe(response => {
      if (response['success']) {
        this.cotationName = response['risks']['cotation']['name'];
        this.cotation = response['risks']['cotation']['cotation_id'];
        this.risksFrequencies = response['risks']['risks'];
        this.risksFrequenciesCount = response['risks']['count'];
        this.risksFrequenciesPages = response['risks']['pages'];
        this.risksFrequencies.forEach( element => {
          if (element.description && element.description.length > 50) {
            element.description = element.description.substr(0, 50) + '...';
          }
          if (element.current_protection && element.current_protection.length > 50) {
            element.current_protection = element.current_protection.substr(0, 50) + '...';
          }
          if (element.todo_protection && element.todo_protection.length > 50) {
            element.todo_protection = element.todo_protection.substr(0, 50) + '...';
          }
        });
        this.modal = $(this._rootNode.nativeElement).find('div#' + content);
        this.modal.modal('show');
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }
  dispatchPaginationAllRisksByFrequencies(data) {
    if (data.action === 'total_elements') {
      this.refreshPageAllRisksByFrequencies(data.value);
    } else if (data.action === 'change_page') {
      this.changePageAllRisksByFrequencies(data.value);
    }
  }
  refreshPageAllRisksByFrequencies(limit) {
    this.risksFrequenciesLimit = limit;
    this.risksFrequenciesPage = 1;
    this.getAllRisksByFrequencies('risksFrequencies', this.cotation);
  }
  changePageAllRisksByFrequencies(page) {
    if (page >= 1 && page <= this.risksFrequenciesPages) {
      this.risksFrequenciesPage = page;
      this.getAllRisksByFrequencies('risksFrequencies', this.cotation);
    }
  }

  getRisksMastery() {
    this.loaderRisksByMastery = true;
    this.statisticsService.riskByType(
      this.selectedEstablishments, this.selectedRiskUnities, '', '', 'master'
    ).subscribe(response => {
      if (response['success']) {
        this.allRisksMastery = response['risks'];
        this.userChart.createRisksByMasteryChart('#risksByMastery', this.allRisksMastery, this);
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loaderRisksByMastery = false;
    });
  }
  getAllRisksByMastery(content, cotationId = null) {
    this.requestInProgress.next(true);
    this.statisticsService.riskByType(
      this.selectedEstablishments, this.selectedRiskUnities, this.risksMasteryLimit, this.risksMasteryPage, 'master', cotationId
    ).subscribe(response => {
      if (response['success']) {
        this.cotationName = response['risks']['cotation']['name'];
        this.cotation = response['risks']['cotation']['cotation_id'];
        this.risksMastery = response['risks']['risks'];
        this.risksMasteryCount = response['risks']['count'];
        this.risksMasteryPages = response['risks']['pages'];
        this.risksMastery.forEach( element => {
          if (element.description && element.description.length > 50) {
            element.description = element.description.substr(0, 50) + '...';
          }
          if (element.current_protection && element.current_protection.length > 50) {
            element.current_protection = element.current_protection.substr(0, 50) + '...';
          }
          if (element.todo_protection && element.todo_protection.length > 50) {
            element.todo_protection = element.todo_protection.substr(0, 50) + '...';
          }
        });
        this.modal = $(this._rootNode.nativeElement).find('div#' + content);
        this.modal.modal('show');
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }
  dispatchPaginationAllRisksByMastery(data) {
    if (data.action === 'total_elements') {
      this.refreshPageAllRisksByMastery(data.value);
    } else if (data.action === 'change_page') {
      this.changePageAllRisksByMastery(data.value);
    }
  }
  refreshPageAllRisksByMastery(limit) {
    this.risksMasteryLimit = limit;
    this.risksMasteryPage = 1;
    this.getAllRisksByMastery('risksMastery', this.families);
  }
  changePageAllRisksByMastery(page) {
    if (page >= 1 && page <= this.risksMasteryPages) {
      this.risksMasteryPage = page;
      this.getAllRisksByMastery('risksMastery', this.families);
    }
  }

  getRisksCritics() {
    this.loaderRisksByCritic = true;
    this.statisticsService.riskByType(
      this.selectedEstablishments, this.selectedRiskUnities, '', '', 'critic'
    ).subscribe(response => {
      if (response['success']) {
        this.allRisksCritics = response['risks'];
        this.userChart.createRisksByCriticChart('#risksByCritic', this.allRisksCritics, this);
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loaderRisksByCritic = false;
    });
  }
  getAllRisksByCritics(content, filter) {
    this.requestInProgress.next(true);
    this.statisticsService.riskByType(
      this.selectedEstablishments, this.selectedRiskUnities, this.risksCriticFilterLimit, this.risksCriticFilterPage, 'critic', '', filter
    ).subscribe(response => {
      if (response['success']) {
        this.criticName = response['risks']['name'];
        this.criticFilter = response['risks']['filter'];
        this.risksCriticFilter = response['risks']['risks'];
        this.risksCriticFilterCount = response['risks']['count'];
        this.risksCriticFilterPages = response['risks']['pages'];
        this.risksCriticFilter.forEach( element => {
          if (element.description && element.description.length > 50) {
            element.description = element.description.substr(0, 50) + '...';
          }
          if (element.current_protection && element.current_protection.length > 50) {
            element.current_protection = element.current_protection.substr(0, 50) + '...';
          }
          if (element.todo_protection && element.todo_protection.length > 50) {
            element.todo_protection = element.todo_protection.substr(0, 50) + '...';
          }
        });
        this.modal = $(this._rootNode.nativeElement).find('div#' + content);
        this.modal.modal('show');
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }
  dispatchPaginationAllRisksByCritics(data) {
    if (data.action === 'total_elements') {
      this.refreshPageAllRisksByCritics(data.value);
    } else if (data.action === 'change_page') {
      this.changePageAllRisksByCritics(data.value);
    }
  }
  refreshPageAllRisksByCritics(limit) {
    this.risksCriticFilterLimit = limit;
    this.risksCriticFilterPage = 1;
    this.getAllRisksByCritics('risksCritics', this.criticFilter);
  }
  changePageAllRisksByCritics(page) {
    if (page >= 1 && page <= this.risksCriticFilterPages) {
      this.risksCriticFilterPage = page;
      this.getAllRisksByCritics('risksCritics', this.criticFilter);
    }
  }

  getRisksByState() {
    this.loaderActionsByState = true;
    this.statisticsService.risksByState(
      this.selectedEstablishments, this.selectedRiskUnities, '', ''
    ).subscribe(response => {
      if (response['success']) {
        this.allActions = response['risks'];
        this.userChart.createActionsByStateChart('#actionsByState', this.allActions, this);
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loaderActionsByState = false;
    });
  }
  getAllRisksByState(content, filter) {
    this.requestInProgress.next(true);
    this.statisticsService.risksByState(
      this.selectedEstablishments, this.selectedRiskUnities, this.actionsLimit, this.actionsPage, filter,
    ).subscribe(response => {
      if (response['success']) {
        this.actionName = response['risks']['name'];
        this.actionFilter = response['risks']['filter'];
        this.actions = response['risks']['risks'];
        this.actionsCount = response['risks']['count'];
        this.actionsPages = response['risks']['pages'];
        this.actions.forEach( element => {
          if (element.description && element.description.length > 50) {
            element.description = element.description.substr(0, 50) + '...';
          }
          if (element.current_protection && element.current_protection.length > 50) {
            element.current_protection = element.current_protection.substr(0, 50) + '...';
          }
          if (element.todo_protection && element.todo_protection.length > 50) {
            element.todo_protection = element.todo_protection.substr(0, 50) + '...';
          }
        });
        this.modal = $(this._rootNode.nativeElement).find('div#' + content);
        this.modal.modal('show');
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }
  dispatchPaginationAllRisksByState(data) {
    if (data.action === 'total_elements') {
      this.refreshPageAllRisksByState(data.value);
    } else if (data.action === 'change_page') {
      this.changePageAllRisksByState(data.value);
    }
  }
  refreshPageAllRisksByState(limit) {
    this.actionsLimit = limit;
    this.actionsPage = 1;
    this.getAllRisksByState('risksState', this.actionFilter);
  }
  changePageAllRisksByState(page) {
    if (page >= 1 && page <= this.actionsPages) {
      this.actionsPage = page;
      this.getAllRisksByState('risksState', this.actionFilter);
    }
  }

  resetFilters() {
    this.selectedEstablishments = [];
    this.selectedRiskUnities = [];
    this.getRiskFamilies();
    this.getRiskGravities();
    this.getRisksFrequencies();
    this.getRisksMastery();
    this.getRisksCritics();
    this.getRisksByState();
  }
}
