import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AllContentsModule } from './all-contents/all-contents.module';
import { UpdateContentModule } from './udpate-content/update-content.module';
import { ContentsRequestService } from '../../services/contents.request.service';

@NgModule({
  imports: [
    CommonModule,
    AllContentsModule,
    UpdateContentModule
  ],
  providers: [
    ContentsRequestService
  ]
})
export class ContentsModule {}
