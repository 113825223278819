<app-modal-right (eventOnClose)="closeModal()" #trainingCustom>{{ binModal(trainingCustom) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">{{ isNew ? 'Création' : 'Mise à jour' }}</h6>
        <h1 class="header-title">Formation personnalisée</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <form [formGroup]="trainingForm" *ngIf="!loaderContent">
      <div class="row">
        <div class="col-12">
          <!-- CARD RECAP ORIGIN TRAINING -->
          <ng-container *ngIf="originTraining">
            <div class="card bg-light border" *ngFor="let item of originTraining.frequencies">
              <div class="card-body">
                <p class="small text-muted mb-2">
                  Fréquence type : {{
                  item.frequency_type === 'recommended' ? 'Recommandé' :
                    item.frequency_type === 'imposed' ? 'Imposé' : ''
                  }}
                </p>
                <p class="small text-muted mb-2">
                  Fréquence initiale : {{ item.frequency_duration}}
                  {{ item.frequency === 'day' ? 'Jour(s)' : item.frequency === 'week' ? 'Semaine(s)' : item.frequency === 'month' ? 'Mois' : 'Année(s)' }}
                </p>
              </div>
            </div>
          </ng-container>

          <!-- NAME -->
          <div class="form-group">
            <label for="validationTrainingName" required>Nom</label>
            <input type="text" id="validationTrainingName" formControlName="name" class="form-control"
                   placeholder="Nom permettant d'identifier de façon unique la formation" maxlength="255"
                   [ngClass]="{ 'is-invalid': name.invalid && (name.dirty || name.touched) }">
            <div *ngIf="name.invalid && (name.dirty || name.touched)" class="invalid-feedback">
              {{ getErrorMessage("name") }}
            </div>
          </div>

          <!-- DESCRIPTION -->
          <div class="form-group">
            <label for="validationTrainingDescription">Description</label>
            <textarea id="validationTrainingDescription" formControlName="description" class="form-control"
                      placeholder="Description"
                      [ngClass]="{ 'is-invalid': description.invalid && (description.dirty || description.touched) }"></textarea>
            <div *ngIf="description.invalid && (description.dirty || description.touched)" class="invalid-feedback">
              {{ getErrorMessage("description") }}
            </div>
          </div>

          <!-- FREQUENCY DURATION -->
          <div class="form-group">
            <label for="validationDuration" required>Durée de fréquence</label>
            <input type="number" id="validationDuration" formControlName="frequency_duration" class="form-control"
                   placeholder="Durée de fréquence" min="1" step="1"
                   (change)="changeFrequency()"
                   [ngClass]="{ 'is-invalid': frequency_duration.invalid && (frequency_duration.dirty || frequency_duration.touched) }">
            <div *ngIf="frequency_duration.invalid && (frequency_duration.dirty || frequency_duration.touched)"
                 class="invalid-feedback">
              {{ getErrorMessage("frequency_duration") }}
            </div>
          </div>

          <!-- FREQUENCY -->
          <div class="form-group">
            <label for="validationFrequency" required>Fréquence</label>
            <select class="form-control" data-toggle="select" formControlName="frequency" id="validationFrequency"
                    (change)="changeFrequency()"
                    [ngClass]="{ 'is-invalid': frequency.invalid && (frequency.dirty || frequency.touched) }">
              <option value="" [selected]="!frequency.value">Sélectionnez une fréquence</option>
              <option value="day">Jour(s)</option>
              <option value="week">Semaine(s)</option>
              <option value="month">Mois</option>
              <option value="year">Année(s)</option>
            </select>
            <div *ngIf="frequency.invalid && (frequency.dirty || frequency.touched)" class="invalid-feedback">
              {{ getErrorMessage("frequency") }}
            </div>
          </div>

          <!-- NOTIFICATION -->
          <div class="form-group d-flex justify-content-start align-items-center">
            <div class="col pl-0">
              Notification dans le calendrier
            </div>
            <div class="custom-checkbox-toggle">
              <input type="checkbox" class="custom-control-input" id="validationTrainingsFrequencyNotif"
                     formControlName="frequency_notification"
                     [ngClass]="{ 'backgroundColor': frequency_notification.value }">
              <label class="custom-control-label" [ngClass]="{ 'backgroundColor': frequency_notification.value }"
                     for="validationTrainingsFrequencyNotif"></label>
            </div>
          </div>

          <!-- ALERT -->
          <div class="form-group d-flex justify-content-start align-items-center">
            <div class="col pl-0">
              Rappel par email
            </div>
            <div class="custom-checkbox-toggle">
              <input type="checkbox" class="custom-control-input" id="validationTrainingsFrequencyAlert"
                     formControlName="frequency_alert" [ngClass]="{ 'backgroundColor': frequency_alert.value }">
              <label class="custom-control-label" [ngClass]="{ 'backgroundColor': frequency_alert.value }"
                     for="validationTrainingsFrequencyAlert"></label>
            </div>
          </div>

          <!-- PRIORITY -->
          <div class="form-group d-flex justify-content-start align-items-center">
            <div class="col pl-0">
              Cette formation est-elle prioritaire ?
            </div>
            <div class="custom-checkbox-toggle">
              <input type="checkbox" class="custom-control-input" id="validationFormationPriority"
                     formControlName="priority" [ngClass]="{ 'backgroundColor': priority.value }">
              <label class="custom-control-label" [ngClass]="{ 'backgroundColor': priority.value }"
                     for="validationFormationPriority"></label>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div modal-footer class="w-100" *ngIf="isNew || canUpdate">
    <button class="btn btn-primary w-100" (click)="isNew ? createCustomTraining() : updateCustomTraining()"
            [ngClass]="{ 'is-loading is-loading-sm': loader }" [disabled]="loader || trainingForm.invalid">
      {{ isNew ? 'Créer' : 'Mettre à jour' }}
    </button>
  </div>
</app-modal-right>
