import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { HttpClient } from '@angular/common/http';
import { NotifierService } from 'angular-notifier';

@Injectable()
export class ReportsRequestService extends RequestService {

  constructor(
    protected http: HttpClient,
    protected notifier: NotifierService
  ) {
    super(http, notifier);
  }

  /**
   * REPORTS
   **/
  public getAllReports(search, sort, order, limit, page, checkpoint_id) {
    return this.get('checkpoints/' + checkpoint_id + '/reports?' +
      'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page
    );
  }
  public getReport(checkpoint_id, report_id) {
    return this.get('checkpoints/' + checkpoint_id + '/reports/' + report_id
    );
  }

  public createReport(checkpoint_id, report) {
    return this.post('checkpoints/' + checkpoint_id + '/reports', { report });
  }
  public updateReport(checkpoint_id, report_id, report) {
    return this.put('checkpoints/' + checkpoint_id + '/reports/' + report_id, { report });
  }
  public deleteReport(checkpoint_id, report_id) {
    return this.delete('checkpoints/' + checkpoint_id + '/reports/' + report_id);
  }


  /**
   * REPORT FILES
   **/
  public getFiles(search, sort, order, limit, page, report_id) {
    return this.get('reports/' + report_id + '/files?' +
      'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getFile(report_id, file_id) {
    return this.get('reports/' + report_id + '/files/' + file_id);
  }
  public downloadFile(report_id, file_id) {
    return this.get('reports/' + report_id + '/files/' + file_id + '/download', { responseType: 'arraybuffer' });
  }

  public createFile(report_id, file) {
    return this.post('reports/' + report_id + '/files', { file: file });
  }
  public updateFile(report_id, file_id, file) {
    return this.put('reports/' + report_id + '/files/' + file_id, { file: file });
  }
  public deleteFile(report_id, file_id) {
    return this.delete('reports/' + report_id + '/files/' + file_id);
  }


  /**
   * CUSTOMER REPORTS
   **/
  public getCustomerReports(search, sort, order, limit, page, checkpoint_id) {
    return this.get('customers/checkpoints/' + checkpoint_id + '/reports?' +
      'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page
    );
  }
  public getCustomerReport(checkpoint_id, report_id) {
    return this.get('customers/checkpoints/' + checkpoint_id + '/reports/' + report_id);
  }
  public createCustomerReport(checkpoint_id, report) {
    return this.post('customers/checkpoints/' + checkpoint_id + '/reports', { report });
  }
  public updateCustomerReport(checkpoint_id, report_id, report) {
    return this.put('customers/checkpoints/' + checkpoint_id + '/reports/' + report_id, { report });
  }


  /**
   * CUSTOMER REPORT FILES
   **/
  public getCustomerFiles(search, sort, order, limit, page, report_id) {
    return this.get('customers/reports/' + report_id + '/files?' +
      'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getCustomerFile(report_id, file_id) {
    return this.get('customers/reports/' + report_id + '/files/' + file_id);
  }
  public downloadCustomerFile(report_id, file_id) {
    return this.get('customers/reports/' + report_id + '/files/' + file_id + '/download',
      { responseType: 'arraybuffer' });
  }

  public createCustomerFile(report_id, file) {
    return this.post('customers/reports/' + report_id + '/files', { file });
  }
  public updateCustomerFile(report_id, file_id, file) {
    return this.put('customers/reports/' + report_id + '/files/' + file_id,
      { file: file });
  }
  public deleteCustomerFile(report_id, file_id) {
    return this.delete('customers/reports/' + report_id + '/files/' + file_id);
  }
}
