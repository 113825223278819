import { Routes } from '@angular/router';

import { UpdateSanitaryAnalyzeComponent } from './update-sanitary-analyze/update-sanitary-analyze.component';

export const sanitaryAnalyzeRoutes: Routes = [
  {
    path: 'create',
    component: UpdateSanitaryAnalyzeComponent
  },
  {
    path: ':sanitary_analyze_id',
    component: UpdateSanitaryAnalyzeComponent
  },
];
