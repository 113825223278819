import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Subject } from 'rxjs';
import { NgbTimeAdapter } from '@ng-bootstrap/ng-bootstrap';

import { AllSanitaryAnalyzesComponent } from '../all-sanitary-analyzes/all-sanitary-analyzes.component';
import { SanitaryCheckpointsRequestService } from '../../../../services/sanitary-checkpoints.request.service';
import { SanitaryAnalyzesRequestService } from '../../../../services/sanitary-analyzes.request.service';
import { SanitaryInstallationsRequestService } from '../../../../services/sanitary-installations.request.service';
import { EstablishmentsRequestService } from '../../../../services/establishments.request.service';

import { NgbTimeStringAdapter } from '../../../../core/utils/ngb-time-string-adapter';
import { CustomNotifierService } from '../../../../services/custom.notifier.service';

import Establishment from '../../../../../shared/interfaces/establishment';
import BatiFile from '../../../../../shared/interfaces/bati_file';
import { ConfirmModalComponent } from '../../../../core/confirm-modal/confirm-modal.component';

declare function Dropzones(): any;

@Component({
  selector: 'app-update-sanitary-analyze',
  templateUrl: './update-sanitary-analyze.component.html',
  styleUrls: ['./update-sanitary-analyze.component.scss'],
  providers: [{ provide: NgbTimeAdapter, useClass: NgbTimeStringAdapter }]
})
export class UpdateSanitaryAnalyzeComponent implements OnInit, AfterViewInit, OnDestroy {
  counter = Array;
  canCreate = false;
  canUpdate = false;

  activeTab = 1;

  requestInProgress = new Subject();
  requestCount = 0;

  sanitaryAnalyzeForm: FormGroup;
  name = new FormControl(null, Validators.required);
  sanitary_installation_id = new FormControl(null, Validators.required);
  descriptions = new FormControl('');
  periodicity = new FormControl(null, Validators.required);

  files: BatiFile[] = [];
  filesRequired: BatiFile[] = [];
  establishment: Establishment;
  sanitaryInstallations = [];
  enterprises: any = [];
  user: string;

  errors = {};

  periods = [
    { value: 'weekly', name: 'Hebdomadaire' },
    { value: 'monthly', name: 'Mensuelle' },
    { value: 'quarterly', name: 'Trimestrielle' },
    { value: 'biannual', name: 'Semestrielle' },
    { value: 'annual', name: 'Annuelle' },
  ];

  isSanitary = false;
  isNew = true;
  loader = false;
  loaderContent = false;

  modalSanitaryAnalyze = null;
  modalUpdateFrame: ConfirmModalComponent = null;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private establishmentsService: EstablishmentsRequestService,
    private allSanitaryAnalyze: AllSanitaryAnalyzesComponent,
    private sanitaryAnalyzeServices: SanitaryAnalyzesRequestService,
    private sanitaryCheckpointsServices: SanitaryCheckpointsRequestService,
    private sanitaryInstallationsServices: SanitaryInstallationsRequestService,
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loaderContent = this.requestCount > 0;
    });

    this.sanitaryAnalyzeForm = this.formBuilder.group({
      name: this.name,
      sanitary_installation_id: this.sanitary_installation_id,
      periodicity: this.periodicity,
      descriptions: this.formBuilder.array([]),
    });

    if (this.activatedRoute.snapshot.params.sanitary_analyze_id) {
      this.isNew = false;
      this.getSanitaryAnalyze();
    } else {
      const control = <FormArray>this.sanitaryAnalyzeForm.controls['descriptions'];
      const tempPointList = this.formBuilder.array([]);
      tempPointList.push(this.formBuilder.group({
        lineId: this.formBuilder.control(this.getRandomIdentifier()),
        value: this.formBuilder.control(''),
      }));
      control.push(this.formBuilder.group({
        lineId: this.formBuilder.control(this.getRandomIdentifier()),
        title: this.formBuilder.control(''),
        more_information: this.formBuilder.control(''),
        pointLists: tempPointList,
        recommendation: this.formBuilder.control(''),
      }));
    }
    this.getEstablishment();
    this.getSanitaryInstallations();
  }

  ngAfterViewInit() {
    Dropzones();
    this.modalSanitaryAnalyze.open();

    this.modalUpdateFrame.eventOnClose.subscribe(event => {
      if (event) {
        this.updateSanitaryAnalyze();
      }
    });
  }

  ngOnDestroy() {
    const flatPicker = document.getElementsByClassName('flatpickr-calendar') as HTMLCollection;
    for (let i = flatPicker.length; i >= 0; i--) {
      if (flatPicker[i]) {
        flatPicker[i].remove();
      }
    }

    const dropZones = document.getElementsByClassName('dz-hidden-input') as HTMLCollection;
    for (let i = 0; i < dropZones.length; i++) {
      dropZones[i].remove();
    }

    this.clearTooltip();

    this.modalSanitaryAnalyze.close();
  }
  clearTooltip() {
    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }
  changeTabs(activeTab) {
    this.activeTab = activeTab;
  }

  getEstablishment() {
    this.errors = {};
    this.requestInProgress.next(true);

    const establishment_id = this.activatedRoute.snapshot.parent.params.establishment_id;
    let request;
    request = this.establishmentsService.getMyEstablishment(establishment_id);

    request.subscribe(response => {
      if (response['success']) {
        this.establishment = response['establishment'];
        if (this.establishment.establishment_type.name === 'ERP' &&
            this.establishment.establishment_subtype_establishments[0]['establishment_subtype']['name'] === 'U') {
            this.isSanitary = true;
        } else if (this.establishment.establishment_type.name === 'IGH' &&
                   this.establishment.establishment_subtype_establishments[0]['establishment_subtype']['name'] === 'GHU') {
            this.isSanitary = true;
        } else if (this.establishment.establishment_type.name === 'BHC' &&
                   this.establishment.establishment_category.name === 'Quatrième famille') {
            this.isSanitary = true;
        }
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }
  getSanitaryInstallations() {
    let request;
    this.requestInProgress.next(true);
    this.loader = true;

    request = this.sanitaryInstallationsServices.getAllSanitaryInstallations(
      '', '', '', 0, 1,
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
    );

    request.subscribe(response => {
      if (response['success']) {
        this.sanitaryInstallations = response['sanitaryInstallations'];
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loader = false;
      this.requestInProgress.next(false);
    });
  }

  getSanitaryAnalyze() {
    this.requestInProgress.next(true);
    this.sanitaryAnalyzeServices.getSanitaryAnalyze(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.activatedRoute.snapshot.params.sanitary_analyze_id
    ).subscribe(response => {
      if (response['success']) {
        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];

        response['sanitaryAnalyze']['descriptions'].forEach((description, indexDescription) => {
          this.addDescriptions(description, indexDescription);
        });
        this.sanitaryAnalyzeForm.patchValue(response['sanitaryAnalyze']);
        this.sanitaryAnalyzeForm[`controls`].periodicity.disable();

        if (!this.canUpdate) {
          this.sanitaryAnalyzeForm.disable();
        }
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  addDescriptions(description, indexDescription) {
    const control = <FormArray>this.sanitaryAnalyzeForm.controls['descriptions'];

    control.push(this.formBuilder.group({
      lineId: this.formBuilder.control(this.isNew ? this.getRandomIdentifier() : ''),
      title: this.formBuilder.control(''),
      more_information: this.formBuilder.control(''),
      pointLists: this.formBuilder.array([]),
      recommendation: this.formBuilder.control(''),
    }));
    if (description.pointLists.length) {
      description.pointLists.forEach(() => {
        this.addPointList(indexDescription);
      });
    } else {
      const updatePointList = <FormArray>this.sanitaryAnalyzeForm.controls['descriptions']['controls'][indexDescription]
        .controls['pointLists'];
      updatePointList.push(this.formBuilder.group({
        lineId: this.formBuilder.control(this.getRandomIdentifier()),
        value: this.formBuilder.control(''),
      }));
    }
  }
  deleteDescription(indexDescription) {
    const control = <FormArray>this.sanitaryAnalyzeForm.controls['descriptions'];
    control.removeAt(indexDescription);
    this.clearTooltip();
  }
  addNewDescription() {
    const control = <FormArray>this.sanitaryAnalyzeForm.controls['descriptions'];
    const tempPointList = this.formBuilder.array([]);
    tempPointList.push(this.formBuilder.group({
      lineId: this.formBuilder.control(this.getRandomIdentifier()),
      value: this.formBuilder.control(''),
    }));
    control.push(this.formBuilder.group({
      lineId: this.formBuilder.control(this.getRandomIdentifier()),
      title: this.formBuilder.control('', Validators.required),
      more_information: this.formBuilder.control(''),
      pointLists: tempPointList,
      recommendation: this.formBuilder.control(''),
    }));
  }
  addPointList(indexDescription) {
    this.clearTooltip();
    const control = <FormArray>this.sanitaryAnalyzeForm.controls['descriptions']['controls'][indexDescription].controls['pointLists'];
    control.push(this.formBuilder.group({
      lineId: this.formBuilder.control(this.isNew ? this.getRandomIdentifier() : ''),
      value: this.formBuilder.control(''),
    }));
  }
  addNewPointList(indexDescription) {
    this.clearTooltip();
    const control = <FormArray>this.sanitaryAnalyzeForm.controls['descriptions']['controls'][indexDescription].controls['pointLists'];
    control.push(this.formBuilder.group({
      lineId: this.formBuilder.control(this.getRandomIdentifier()),
      value: this.formBuilder.control(''),
    }));
  }
  deletePointList(indexDescription, indexPointList) {
    const control = <FormArray>this.sanitaryAnalyzeForm.controls['descriptions']['controls'][indexDescription].controls['pointLists'];
    control.removeAt(indexPointList);
    this.clearTooltip();
  }
  private getRandomIdentifier() {
    const date = new Date();
    const number = date.getTime();
    return parseInt(Math.floor((Math.random() * 500) + 1).toString(10) + number.toString(10), 10);
  }

  createSanitaryAnalyze() {
    let request;
    this.errors = {};
    this.loader = true;

    const data = {
      ...this.sanitaryAnalyzeForm.value,
    };

    request = this.sanitaryAnalyzeServices.createSanitaryAnalyze(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      data
    );

    request.subscribe(response => {
      if (response['success']) {
        if (this.files.length || this.filesRequired.length) {
          this.createDocuments(response['sanitaryAnalyze']['id']);
        } else {
          this.loader = false;
          this.modalSanitaryAnalyze.close();
          CustomNotifierService.getSuccessCreate(this.notifier);
          this.router.navigate([
            'dashboard-registry/' + this.activatedRoute.snapshot.parent.params.registry_id +
            '/establishment/' + this.activatedRoute.snapshot.parent.params.establishment_id +
            '/sanitary-surveillances/analyzes/' + response['sanitaryAnalyze']['id'] + '/frames/create']).then();
        }
      } else if (response['errors']) {
        this.loader = false;
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(response['errors'], this.sanitaryAnalyzeForm, this.notifier);
      } else {
        this.loader = false;
      }
    });
  }
  updateSanitaryAnalyze() {
    let request;

    this.errors = {};
    this.loader = true;

    const data = {
      ...this.sanitaryAnalyzeForm.value,
    };

    request = this.sanitaryAnalyzeServices.updateSanitaryAnalyze(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.activatedRoute.snapshot.params.sanitary_analyze_id,
      data
    );

    request.subscribe(response => {
      if (response['success']) {
        if (this.files.length || this.filesRequired.length) {
          this.createDocuments(response['sanitaryAnalyze']['id']);
        } else {
          this.loader = false;
          this.modalSanitaryAnalyze.close();
          CustomNotifierService.getSuccessUpdate(this.notifier);
        }
      } else if (response['errors']) {
        this.loader = false;
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      } else {
        this.loader = false;
      }
    });
  }
  createDocuments(sanitary_analyze_id) {
    let request;
    this.errors = {};

    request = this.sanitaryAnalyzeServices.createFile(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      sanitary_analyze_id,
      [...this.files, ...this.filesRequired]
    );

    request.subscribe(response => {
      if (response['success']) {
        this.modalSanitaryAnalyze.close();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loader = false;
    });
  }

  chooseFile() {
    const element: HTMLElement = document.getElementById('inputFile') as HTMLElement;
    element.click();
  }
  onFileChange(event) {
    if (event.target && event.target.files && event.target.files.length) {
      for (let i = 0; i < event.target.files.length; i++) {
        const reader = new FileReader();
        const file = event.target.files[i];
        reader.readAsDataURL(file);

        reader.onload = () => {
          let extension = '';
          let name = '';

          const split = file.name.split('.');
          if (split.length) {
            name = split[0];
            extension = split[split.length - 1];
          }

          this.files.push({ file_base64: reader.result, name: name, extension: extension });
        };
      }
    } else {
      let extension = '';
      let name = '';

      const split = event.name.split('.');
      if (split.length) {
        name = split[0];
        extension = split[split.length - 1];
      }

      this.files.push({ file_base64: event.file, name: name, extension: extension });
    }
    const element: HTMLInputElement = document.getElementById('inputFile') as HTMLInputElement;
    element.value = '';
  }
  removeFile(index) {
    this.files.splice(index, 1);

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  bindModal(modal) {
    this.modalSanitaryAnalyze = modal;
  }
  closeModal() {
    this.router.navigate([this.activatedRoute.snapshot.parent.url.join('/')]).then(() => {
      this.allSanitaryAnalyze.refreshData();
    });
  }
  bindModalUpdateFrame(modal) {
    this.modalUpdateFrame = modal;
  }
}
