import { Routes } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { SsoLoginComponent } from './sso-login/sso-login.component';
import { NewPasswordComponent } from './new-password/new-password.component';
import { LostPasswordComponent } from './lost-password/lost-password.component';
import { DiscoverComponent } from './discover/discover.component';

export const authRoutes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'discover',
    component: DiscoverComponent
  },
  {
    path: 'login/:redirect_uri',
    component: SsoLoginComponent
  },
  {
    path: 'new-password/:token',
    component: NewPasswordComponent
  },
  {
    path: 'lost-password',
    component: LostPasswordComponent
  },
  {
    path: 'forgot-password/:token',
    component: LostPasswordComponent
  }
];
