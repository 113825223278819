<app-modal-right (eventOnClose)="closeModal()" #subscription>{{ bindModal(subscription) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">{{ isNew ? 'Création' : canUpdate ? 'Mise à jour' : 'Consultation' }}</h6>

        <h1 class="header-title">Abonnement</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <form [formGroup]="subscriptionForm" [hidden]="loaderContent">
      <div class="row">
        <div class="col-12">
          <!-- OFFERS-->
          <div class="form-group">
            <label for="validationOffers" required>Offres</label>
            <select class="form-control" id="validationOffers" formControlName="offer_id" (change)="resetForm()">
              <option value="" [selected]="!offer_id.value">Sélectionnez une offre</option>
              <ng-container *ngFor="let offer of offers">
                <option [value]="offer.id">{{ offer.name + ' - ' + offer.type }}</option>
              </ng-container>
            </select>
          </div>

          <!-- QUOTA OFFER -->
          <div class="form-group" *ngIf="selectedOffer && selectedOffer?.prices.length !== 0">
            <label for="validationPrices">Quota/Prix</label>
            <select class="form-control" id="validationPrices" (change)="selectedPrice($event)">
              <option [value]="">Sélectionnez un quota</option>
              <ng-container *ngFor="let price of selectedOffer?.prices">
                <option [value]="price.id">{{ 'Quota : ' + price.nb_establishment + ' - Prix : ' + price.price + '€' }}</option>
              </ng-container>
            </select>
          </div>

          <!-- FREQUENCY -->
          <div class="form-group">
            <label for="validationFrequencies" required>Fréquence de facturation</label>
            <select class="form-control" id="validationFrequencies" formControlName="billing_frequency_id">
              <option value="" [selected]="!billing_frequency_id.value">Sélectionnez une fréquence de facturation</option>
              <ng-container *ngFor="let frequency of frequencies">
                <option [value]="frequency.id">
                  {{ frequency.frequency_type === 'Annuelle' ?
                  frequency.frequency_type + ' - ' + frequency.frequency_number + ' an' + ' : ' + frequency.factor + '%'
                  :
                  frequency.frequency_type + ' - ' + frequency.frequency_number + ' mois' + ' : ' + frequency.factor + '%'
                  }}</option>
              </ng-container>
            </select>
          </div>

          <!-- NUMBER ESTABLISHMENT -->
          <div class="form-group">
            <label for="validationNbEstablishment">Nombre d'établissement</label>
            <input type="number" formControlName="nb_establishment" class="form-control" id="validationNbEstablishment" placeholder="Nombre d'établissement" min="0">
          </div>

          <!-- PRICE ESTABLISHMENT -->
          <div class="form-group">
            <label for="validationPrice">Prix</label>
            <input type="number" formControlName="price" class="form-control" id="validationPrice" placeholder="Prix">
          </div>

          <!-- NUMBER ESTABLISHMENT RUS -->
          <div class="form-group">
            <label for="validationNbEstablishmentRUS">Nombre d'établissement RUS</label>
            <input type="number" formControlName="nb_establishment_rus" class="form-control" id="validationNbEstablishmentRUS" placeholder="Nombre d'établissement RUS" min="0">
          </div>

          <!-- IS MONO USER -->
          <div class="form-group d-flex justify-content-start align-items-center">
            <div class="col pl-0">
              Mono utilisateur ?
            </div>
            <div class="custom-checkbox-toggle">
              <input type="checkbox" class="custom-control-input" id="validationMono" formControlName="is_mono" [ngClass]="{ 'backgroundColor': is_mono.value }" (change)="validationMono(is_mono.value)">
              <label class="custom-control-label" [ngClass]="{ 'backgroundColor': is_mono.value }" for="validationMono"></label>
            </div>
          </div>

          <!-- NUMBER OF USER -->
          <div class="form-group">
            <label for="validationNbUser">Nombre d'utlisateur</label>
            <input type="number" formControlName="nb_user" class="form-control" id="validationNbUser" placeholder="Nombre d'utilisateur ( 0 : illimité )" min="0">
          </div>

          <div class="form-group d-flex justify-content-start align-items-center">
            <div class="col pl-0">
              Offre découverte ?
            </div>
            <div class="custom-checkbox-toggle">
              <input type="checkbox" class="custom-control-input" id="validationIsDiscover" formControlName="is_discover" [ngClass]="{ 'backgroundColor': is_mono.value }" (change)="validationDiscover(is_discover.value)">
              <label class="custom-control-label" [ngClass]="{ 'backgroundColor': is_discover.value }" for="validationIsDiscover"></label>
            </div>
          </div>

          <!-- END DISCOVER DATE -->
          <div class="form-group" *ngIf="is_discover.value">
            <label for="validationDiscover" required>Date de fin pour l'offre découverte</label>
            <input type="date" formControlName="discover_date" class="form-control" id="validationDiscover" placeholder="Date de fin pour l'offre découverte" data-toggle="flatpickr"
                   [ngClass]="{ 'is-invalid': discover_date.invalid && (discover_date.dirty || discover_date.touched) }">
            <div *ngIf="discover_date.invalid && (discover_date.dirty || discover_date.touched)" class="invalid-feedback">
              {{ getErrorMessage("discover_date") }}
            </div>
          </div>

          <!-- BEGIN DATE -->
          <div class="form-group">
            <label for="validationStartDate" required>Date de début</label>
            <input type="date" formControlName="start_date" class="form-control" id="validationStartDate" placeholder="Date de début" data-toggle="flatpickr"
                   [ngClass]="{ 'is-invalid': start_date.invalid && (start_date.dirty || start_date.touched) }">
            <div *ngIf="start_date.invalid && (start_date.dirty || start_date.touched)" class="invalid-feedback">
              {{ getErrorMessage("start_date") }}
            </div>
          </div>

          <!-- END DATE -->
          <div class="form-group">
            <label for="validationExpirationDate" required>Date de fin</label>
            <input type="date" formControlName="expiration_date" class="form-control" id="validationExpirationDate" placeholder="Date de fin" data-toggle="flatpickr"
                   [ngClass]="{ 'is-invalid': expiration_date.invalid && (expiration_date.dirty || expiration_date.touched) }">
            <div *ngIf="expiration_date.invalid && (expiration_date.dirty || expiration_date.touched)" class="invalid-feedback">
              {{ getErrorMessage("expiration_date") }}
            </div>
          </div>

          <!-- RENEW AUTO -->
          <div class="form-group d-flex justify-content-start align-items-center">
            <div class="col pl-0">
              Renouvellement automatique ?
            </div>
            <div class="custom-checkbox-toggle">
              <input type="checkbox" class="custom-control-input" id="validationRenewal" formControlName="renewal" [ngClass]="{ 'backgroundColor': renewal.value }">
              <label class="custom-control-label" [ngClass]="{ 'backgroundColor': renewal.value }" for="validationRenewal"></label>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div modal-footer class="w-100" *ngIf="isNew || canUpdate">
    <button class="btn btn-primary w-100" (click)="isNew ? createSubscription() : updateSubscription()" [ngClass]="{ 'is-loading is-loading-sm': loader }" [disabled]="loader || subscriptionForm.invalid">
      {{ isNew ? 'Créer' : 'Mettre à jour' }}
    </button>
  </div>
</app-modal-right>
