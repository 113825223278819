import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Subject } from 'rxjs';
import { NgbTimeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { NgbTimeStringAdapter } from '../../../core/utils/ngb-time-string-adapter';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { UsersRequestService } from '../../../services/users.request.service';
import { AllSanitaryWorksheetsComponent } from '../all-sanitary-worksheets/all-sanitary-worksheets.component';
import { SanitaryWorksheetsRequestService } from '../../../services/sanitary-worksheets.request.service';

declare function Flatpickr(): any;
declare function Dropzones(): any;

@Component({
  selector: 'app-update-sanitary-worksheet',
  templateUrl: './update-sanitary-worksheet.component.html',
  styleUrls: ['./update-sanitary-worksheet.component.scss'],
  providers: [{ provide: NgbTimeAdapter, useClass: NgbTimeStringAdapter }]
})
export class UpdateSanitaryWorksheetComponent implements OnInit, AfterViewInit, OnDestroy {
  counter = Array;
  canCreate = false;
  canUpdate = false;

  activeTab = 1;

  fileName: string;

  requestInProgress = new Subject();
  requestCount = 0;

  sanitaryWorksheetForm: FormGroup;
  date = new FormControl('', Validators.required);
  provider = new FormControl('', Validators.required);
  description = new FormControl('', Validators.required);
  is_file = new FormControl(false);

  enterprises: any = [];
  user: string;

  errors = {};

  isNew = true;
  loader = false;
  loaderContent = false;

  modalSanitaryWorksheet = null;

  constructor(
      private router: Router,
      private formBuilder: FormBuilder,
      private notifier: NotifierService,
      private activatedRoute: ActivatedRoute,
      private allSanitaryWorksheets: AllSanitaryWorksheetsComponent,
      private usersService: UsersRequestService,
      private sanitaryWorksheetServices: SanitaryWorksheetsRequestService,
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loaderContent = this.requestCount > 0;
    });

    this.sanitaryWorksheetForm = this.formBuilder.group({
      date: this.date,
      provider: this.provider,
      description: this.description,
      is_file: this.is_file,
    });

    if (this.activatedRoute.snapshot.params.sanitary_worksheet_id) {
      this.isNew = false;
      this.getSanitaryWorksheet();
    } else {
      setTimeout(() => {
        Flatpickr();
      }, 1);
    }
  }

  ngAfterViewInit() {
    Dropzones();
    this.modalSanitaryWorksheet.open();
  }

  ngOnDestroy() {
    const flatPicker = document.getElementsByClassName('flatpickr-calendar') as HTMLCollection;
    for (let i = flatPicker.length; i >= 0; i--) {
      if (flatPicker[i]) {
        flatPicker[i].remove();
      }
    }

    const dropZones = document.getElementsByClassName('dz-hidden-input') as HTMLCollection;
    for (let i = 0; i < dropZones.length; i++) {
      dropZones[i].remove();
    }

    this.clearTooltip();

    this.modalSanitaryWorksheet.close();
  }
  clearTooltip() {
    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }
  changeTabs(activeTab) {
    this.activeTab = activeTab;
  }

  getSanitaryWorksheet() {
    this.requestInProgress.next(true);
    this.sanitaryWorksheetServices.getSanitaryWorksheet(
        this.activatedRoute.snapshot.parent.params.registry_id,
        this.activatedRoute.snapshot.parent.params.establishment_id,
        this.activatedRoute.snapshot.params.sanitary_worksheet_id
    ).subscribe(response => {
      if (response['success']) {
        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];

        this.sanitaryWorksheetForm.patchValue(response['sanitaryWorksheet']);

        if (!this.canUpdate) {
          this.sanitaryWorksheetForm.disable();
        }
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      Flatpickr();
      this.requestInProgress.next(false);
    });
  }
  createSanitaryWorksheet() {
    let request;
    this.errors = {};
    this.loader = true;

    request = this.sanitaryWorksheetServices.createSanitaryWorksheet(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.sanitaryWorksheetForm.value
    );

    request.subscribe(response => {
      if (response['success']) {
        this.isNew = false;
        this.loader = false;
        this.modalSanitaryWorksheet.close();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response['errors']) {
        this.loader = false;
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(response['errors'], this.sanitaryWorksheetForm, this.notifier);
      } else {
        this.loader = false;
      }
    });
  }
  updateSanitaryWorksheet() {
    let request;

    this.errors = {};
    this.loader = true;

    const data = {
      ...this.sanitaryWorksheetForm.value,
    };

    request = this.sanitaryWorksheetServices.updateSanitaryWorksheet(
        this.activatedRoute.snapshot.parent.params.registry_id,
        this.activatedRoute.snapshot.parent.params.establishment_id,
        this.activatedRoute.snapshot.params.sanitary_worksheet_id,
        data
    );

    request.subscribe(response => {
      if (response['success']) {
        this.loader = false;
        this.modalSanitaryWorksheet.close();
        CustomNotifierService.getSuccessUpdate(this.notifier);
      } else if (response['errors']) {
        this.loader = false;
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      } else {
        this.loader = false;
      }
    });
  }
  downloadFile(file_name) {
    let request;

    request = this.sanitaryWorksheetServices.downloadFile(
        this.activatedRoute.snapshot.parent.params.registry_id,
        this.activatedRoute.snapshot.parent.params.establishment_id,
        this.activatedRoute.snapshot.parent.params.sanitary_worksheet_id
    );

    request.subscribe((response: BufferSource) => {
      try {
        const decoder = new TextDecoder('utf-8');
        const domString = decoder.decode(response);
        const json = JSON.parse(domString);

        CustomNotifierService.getErrors(json['errors'], this.notifier);
      } catch (e) {
        const blob = new Blob([response]);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = file_name;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      }
    });
  }
  bindModal(modal) {
    this.modalSanitaryWorksheet = modal;
  }
  closeModal() {
    this.router.navigate([this.activatedRoute.snapshot.parent.url.join('/')]).then(() => {
      this.allSanitaryWorksheets.refreshData();
      this.allSanitaryWorksheets.refreshDataFiles();
    });
  }
}
