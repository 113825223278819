import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Subject } from 'rxjs';
import { NgbTimeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { NgbTimeStringAdapter } from '../../../core/utils/ngb-time-string-adapter';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { UsersRequestService } from '../../../services/users.request.service';
import { PreventionsRequestService } from '../../../services/preventions.request.service';

import { AllPreventionRisksComponent } from '../all-prevention-risks/all-prevention-risks.component';

@Component({
  selector: 'app-update-prevention-risk',
  templateUrl: './update-prevention-risk.component.html',
  styleUrls: ['./update-prevention-risk.component.scss'],
  providers: [{ provide: NgbTimeAdapter, useClass: NgbTimeStringAdapter }]
})
export class UpdatePreventionRiskComponent implements OnInit, AfterViewInit, OnDestroy {
  canCreate = false;
  canUpdate = false;

  view = '';

  requestInProgress = new Subject();
  requestCount = 0;

  riskForm: FormGroup;
  name = new FormControl('', Validators.required);

  errors = {};

  isNew = true;
  loader = false;
  loaderContent = false;

  modalRisk = null;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private allRisks: AllPreventionRisksComponent,
    private usersService: UsersRequestService,
    private preventionsService: PreventionsRequestService,
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loaderContent = this.requestCount > 0;
    });

    if (this.activatedRoute.snapshot.parent.params.establishment_id) {
      this.view = 'registry';
    } else {
      this.view = 'admin';
    }

    this.riskForm = this.formBuilder.group({
      name: this.name,
    });

    if (this.activatedRoute.snapshot.params.risk_id) {
      this.isNew = false;
      this.getRisk();
    }
  }

  ngAfterViewInit() {
    this.modalRisk.open();
  }

  ngOnDestroy() {
    this.modalRisk.close();
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  getRisk() {
    this.requestInProgress.next(true);
    let request;
    if (this.view === 'admin') {
      request = this.preventionsService.getPreventionRisk(this.activatedRoute.snapshot.params.risk_id);
    } else {
      request = this.preventionsService.getMyPreventionRisk(
        this.activatedRoute.snapshot.parent.params.registry_id,
        this.activatedRoute.snapshot.parent.params.establishment_id,
        this.activatedRoute.snapshot.params.risk_id
      );
    }
    request.subscribe(response => {
      if (response['success']) {
        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];

        this.riskForm.patchValue(response['risk']);

        if (!this.canUpdate) {
          this.riskForm.disable();
        }
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }
  createRisk() {
    let request;
    this.errors = {};
    this.loader = true;

    const data = {
      ...this.riskForm.value,
    };

    if (this.view === 'admin') {
      request = this.preventionsService.createPreventionRisk(data);
    } else {
      request = this.preventionsService.createMyPreventionRisk(
        this.activatedRoute.snapshot.parent.params.registry_id,
        this.activatedRoute.snapshot.parent.params.establishment_id,
        data
      );
    }

    request.subscribe(response => {
      if (response['success']) {
        this.isNew = false;
        this.loader = false;
        this.modalRisk.close();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response['errors']) {
        this.loader = false;
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(response['errors'], this.riskForm, this.notifier);
      } else {
        this.loader = false;
      }
    });
  }
  updateRisk() {
    let request;

    this.errors = {};
    this.loader = true;

    const data = {
      ...this.riskForm.value,
    };

    if (this.view === 'admin') {
      request = this.preventionsService.updatePreventionRisk(this.activatedRoute.snapshot.params.risk_id, data);
    } else {
      request = this.preventionsService.updateMyPreventionRisk(
        this.activatedRoute.snapshot.parent.params.registry_id,
        this.activatedRoute.snapshot.parent.params.establishment_id,
        this.activatedRoute.snapshot.params.risk_id,
        data
      );
    }

    request.subscribe(response => {
      if (response['success']) {
        this.loader = false;
        this.modalRisk.close();
        CustomNotifierService.getSuccessUpdate(this.notifier);
      } else if (response['errors']) {
        this.loader = false;
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(response['errors'], this.riskForm, this.notifier);
      } else {
        this.loader = false;
      }
    });
  }

  bindModal(modal) {
    this.modalRisk = modal;
  }
  closeModal() {
    this.router.navigate([this.activatedRoute.snapshot.parent.url.join('/')]).then(() => {
      this.allRisks.refreshData();
    });
  }
}
