import { AfterViewInit, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { Subject, Subscription } from 'rxjs';

import { AuthService } from '../../../core/auth/auth.service';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { NotificationsRequestService } from '../../../services/notifications.request.service';
import { UsersRequestService } from '../../../services/users.request.service';
import { EnterprisesRequestService } from '../../../services/enterprises.request.service';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-all-messages',
  templateUrl: './all-messages.component.html',
  styleUrls: ['./all-messages.component.scss']
})
export class AllMessagesComponent implements OnInit, OnDestroy, AfterViewInit {
  changeEnterprise: Subscription;

  requestInProgress = new Subject();
  requestCount = 0;

  canCreate = false;
  canUpdate = false;
  canDelete = false;

  notifications = [];
  grouped: any;
  objectKeys = Object.keys;
  personal_message = [];

  pageName: string;
  previousPageName: string[] = [];
  previousPageRoute: string[] = [];

  count = 0;
  pages = 1;

  search = '';
  sort = '';
  order = 'ASC';
  limit = 12;
  page = 1;

  view = '';
  currentRoute = '';
  timeout: any;

  loader = false;

  errors;

  modalConfirmData = null;

  constructor(
    private sanitizer: DomSanitizer,
    private authService: AuthService,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private usersServices: UsersRequestService,
    private enterpriseService: EnterprisesRequestService,
    private notificationService: NotificationsRequestService,
  ) {
  }

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loader = this.requestCount > 0;
    });

    this.pageName = 'Notification';
    if (this.activatedRoute.snapshot.url[1]) {
      this.view = 'mine';
      this.previousPageName.push('Tableau de bord');
      this.previousPageRoute.push('/');
    } else {
      this.view = 'admin';
      this.previousPageName.push('Tableau de bord');
      this.previousPageRoute.push('/administration');
    }

    this.changeEnterprise = this.authService.getEnterprise().subscribe(() => {
      if (this.view === 'mine') {
        this.getNotifications();
      } else {
        this.getAdminNotifications();
      }
    });

    if (this.authService.loaded) {
      if (this.view === 'mine') {
        this.getNotifications();
      } else {
        this.getAdminNotifications();
      }
    }
  }

  ngAfterViewInit() {
    this.modalConfirmData.eventOnClose.subscribe(event => {
      if (event) {
        let request;
        this.requestInProgress.next(true);
        if (this.view === 'mine') {
          request = this.notificationService.deleteNotification(event);
        }
        if (this.view === 'admin') {
          request = this.notificationService.deleteAdminNotification(event);
        }
        request.subscribe(response => {
          if (response['success']) {
            this.refreshData();
            CustomNotifierService.getSuccessDelete(this.notifier);
          } else {
            CustomNotifierService.getErrors(response['errors'], this.notifier);
          }
          this.requestInProgress.next(false);
        });
      }
    });
  }

  searchData() {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.page = 1;
      if (this.view === 'mine') {
        this.refreshData();
      } else {
        this.refreshAdminData();
      }
    }, 500);
  }

  refreshData(sort = this.sort, order = this.order, page = this.page) {
    this.notifications = [];
    this.sort = sort;
    this.order = order;
    this.page = page;
    this.getNotifications();
  }

  refreshAdminData(sort = this.sort, order = this.order, page = this.page) {
    this.notifications = [];
    this.sort = sort;
    this.order = order;
    this.page = page;
    this.getAdminNotifications();
  }

  ngOnDestroy() {
    this.changeEnterprise.unsubscribe();

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  getNotifications() {
    this.personal_message = [];
    this.requestInProgress.next(true);
    let request;
    request = this.notificationService.getNotifications(this.search, this.sort, this.order, this.limit, this.page, 'personal_message');
    request.subscribe(response => {
      if (response['success']) {
        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];
        this.canDelete = response['canDelete'];
        this.notifications = response['notifications'];
        this.notifications.forEach(element => {
          if (element.tiles === 'Message personnalisé') {
            this.personal_message.push(element);
          }
        });
        this.count = response['count'];
        this.pages = response['pages'];
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }
  getAdminNotifications() {
    this.personal_message = [];
    this.requestInProgress.next(true);

    let request;
    request = this.notificationService.getAdminNotifications(this.search, this.sort, this.order, this.limit, this.page);
    request.subscribe(response => {
      if (response['success']) {
        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];
        this.canDelete = response['canDelete'];
        this.notifications = response['notifications'];
        this.notifications.forEach(element => {
          if (element.tiles === 'Message général') {
            this.personal_message.push(element);
          }
        });
        this.count = response['count'];
        this.pages = response['pages'];
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  sanitizerHtml(htmlString) {
    return this.sanitizer.bypassSecurityTrustHtml(htmlString);
  }

  dispatchPagination(data) {
    if (data.action === 'total_elements') {
      this.refreshPage(data.value);
    } else if (data.action === 'change_page') {
      this.changePage(data.value);
    }
  }

  refreshPage(limit) {
    this.limit = limit;
    this.page = 1;
    if (this.view === 'mine') {
      this.refreshData();
    } else {
      this.refreshAdminData();
    }
  }

  changePage(page) {
    if (page >= 1 && page <= this.pages) {
      this.page = page;
      if (this.view === 'mine') {
        this.getNotifications();
      } else {
        this.getAdminNotifications();
      }
    }
  }

  bindModalConfirmData(modal) {
    this.modalConfirmData = modal;
  }
}
