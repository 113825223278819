import { Component, Injector, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';

import { AuthService } from '../../../core/auth/auth.service';
import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { NotificationsRequestService } from '../../../services/notifications.request.service';
import { DomSanitizer } from '@angular/platform-browser';
import { NotificationService } from '../../../services/notification.service';
import { ToolbarNotificationComponent } from '../../../core/toolbar-notification/toolbar-notification.component';
import { InterventionsRequestService } from '../../../services/interventions.request.service';

@Component({
  selector: 'app-user-notifications',
  templateUrl: './user-notifications.component.html',
  styleUrls: ['./user-notifications.component.scss']
})
export class UserNotificationsComponent implements OnInit {
  changeEnterprise: Subscription;

  canCreate = false;
  canUpdate = false;
  canDelete = false;
  canLog = false;

  requestInProgress = new Subject();
  requestCount = 0;

  currentRoute = '';

  pageName: string;
  previousPageName: string[] = [];
  previousPageRoute: string[] = [];

  notifications = [];

  count = 0;
  pages = 1;

  search = '';
  sort = 'Notifications.created';
  order = 'desc';
  limit = 10;
  page = 1;

  timeout: any;
  loader = false;

  connectedAs = false;
  user: any;
  enterprise: any;

  modalConfirmData = null;
  modalConfirmFile = null;

  constructor(
    private router: Router,
    private injector: Injector,
    private sanitizer: DomSanitizer,
    private authService: AuthService,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    public notificationService: NotificationService,
    private toolbarNotification: ToolbarNotificationComponent,
    private interventionsService: InterventionsRequestService,
    private notificationRequestService: NotificationsRequestService,
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loader = this.requestCount > 0;
    });

    this.connectedAs = JSON.parse(localStorage.getItem('connectedAs'));
    if (this.connectedAs) {
      this.enterprise = JSON.parse(localStorage.getItem('connectedAsEnterprise'));
      this.user = JSON.parse(localStorage.getItem('connectedAsUser'));
    } else {
      this.enterprise = JSON.parse(localStorage.getItem('enterprise'));
      this.user = JSON.parse(localStorage.getItem('user'));
    }
    const observableEnterprise = this.authService.getEnterprise();
    observableEnterprise.subscribe(value => {
      this.enterprise = value;
    });

    const observableUser = this.authService.getUser();
    observableUser.subscribe(value => {
      this.user = value;
      this.connectedAs = JSON.parse(localStorage.getItem('connectedAs'));
      this.notifications = [];
      this.getMyNotifications();
    });

    this.getMyNotifications();

    this.currentRoute = '/' + this.activatedRoute.snapshot.url.join('/') + '/';
    this.pageName = 'Notifications';
    this.previousPageName.push('Tableau de bord');
    this.previousPageRoute.push('/administration');
  }

  searchData() {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.page = 1;
      this.refreshData();
    }, 500);
  }

  refreshData(sort = this.sort, order = this.order, page = this.page) {
    this.sort = sort;
    this.order = order;
    this.page = page;
    this.getMyNotifications();
  }

  getMyNotifications() {
    let request;
    this.notifications = [];
    this.requestInProgress.next(true);

    request = this.notificationRequestService.getMyNotifications(
      this.search, this.sort, this.order, this.limit, this.page);

    request.subscribe(response => {
      if (response['success']) {
        this.notificationService.allNotification = response['notifications'];
        this.notificationService.allNotification.forEach(element => {
          element.body = this.sanitizer.bypassSecurityTrustHtml(element.body);
        });
        this.count = response['count'];
        this.pages = response['pages'];
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  markAsRead(notification_id) {
    this.notificationRequestService.checkAsRead(notification_id).subscribe( response => {
      if (response['success']) {
        this.refreshData();
        this.toolbarNotification.getNotifications();
      }
    });
  }

  markAsReadAll() {
    this.requestInProgress.next(true);

    this.notificationRequestService.markAsReadAll().subscribe(response => {
      if (response['success']) {
        this.refreshData();
        this.toolbarNotification.getNotifications();
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  deleteNotification(notification_id) {
    this.requestInProgress.next(true);
    this.notificationRequestService.deleteMyNotification(notification_id).subscribe( response => {
      if (response['success']) {
        CustomNotifierService.getSuccessDelete(this.notifier);
        this.refreshData();
        this.toolbarNotification.getNotifications();
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  dispatchPagination(data) {
    if (data.action === 'total_elements') {
      this.refreshPage(data.value);
    } else if (data.action === 'change_page') {
      this.changePage(data.value);
    }
  }

  refreshPage(limit) {
    this.limit = limit;
    this.page = 1;
    this.refreshData();
  }

  changePage(page) {
    if (page >= 1 && page <= this.pages) {
      this.page = page;
      this.getMyNotifications();
    }
  }

  bindModalConfirmData(modal) {
    this.modalConfirmData = modal;
  }
  bindModalConfirmFile(modal) {
    this.modalConfirmFile = modal;
  }

  downloadInterventionPdf(id, notification_id) {
    const elem = document.getElementById(notification_id);
    const fileName = 'bon_intervention.pdf';

    elem.classList.add('is-loading');
    elem.classList.add('is-loading-sm');
    elem.classList.add('disabled');

    let request;
    request = this.interventionsService.downloadPdf(id);
    request.subscribe((response: BufferSource) => {
      try {
        const decoder = new TextDecoder('utf-8');
        const domString = decoder.decode(response);
        const json = JSON.parse(domString);
        CustomNotifierService.getErrors(json['errors'], this.notifier);
      } catch (e) {
        const blob = new Blob([response]);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      }
      this.markAsRead(notification_id);
      elem.classList.remove('is-loading');
      elem.classList.remove('is-loading-sm');
      elem.classList.remove('disabled');
    });
  }
}
