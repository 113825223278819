import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AllInterventionsModule } from './all-interventions/all-interventions.module';
import { InterventionsRequestService } from '../../services/interventions.request.service';
import { UpdateInterventionModule } from './update-intervention/update-intervention.module';
import { DetailsInterventionModule } from './details-intervention/details-intervention.module';
import { UploadInterventionFileModule } from './upload-intervention-file/upload-intervention-file.module';
import { StatisticsRequestService } from '../../services/statistics.request.service';

@NgModule({
  imports: [
    CommonModule,
    AllInterventionsModule,
    UpdateInterventionModule,
    DetailsInterventionModule,
    UploadInterventionFileModule
  ],
  providers: [
      InterventionsRequestService,
      StatisticsRequestService,
  ]
})
export class InterventionsModule {}
