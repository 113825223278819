<div class="main-content">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col">
        <div class="header mb-0">
          <div class="header-body no-border">
            <div class="row align-items-center">
              <div class="col">
                <h1 class="header-title">
                  Installations
                </h1>

                <small class="form-text text-muted">
                  <span>Liste des installations</span>
                </small>

                <app-breadcrumbs [currentPage]="pageName" [names]="previousPageName" [routes]="previousPageRoute"></app-breadcrumbs>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col">
            <app-searchbar [(ngModel)]="search" (keyup)="searchData()" ngDefaultControl></app-searchbar>
          </div>
        </div>

        <div class="is-loading loader-content" *ngIf="loader"></div>

        <div class="tab-content" *ngIf="!loader && installations.length">
          <div class="tab-pane fade active show" id="tabPaneOne" role="tabpanel">
            <div class="row listAlias">
              <div class="col-12 d-flex flex-column">
                <div class="card flex-fill">
                  <div class="card-body">
                    <div class="table-responsive">
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th class="href" (click)="filter('Installations.name')">
                              <a class="text-muted">Nom</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'Installations.name' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'Installations.name' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'Installations.name'"></span>
                            </th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="dropdown" *ngFor="let installation of installations">
                            <td>
                              <a class="href" [routerLink]="[currentRoute + installation.id + '/checkpoints']">
                                {{ (installation.installation ? installation.installation.name : '') }}
                                {{ installation.installation && installation.description ? ' - ' : '' }}
                                {{ installation.description }}
                              </a>
                            </td>
                            <td class="d-flex flex-row-reverse">
                              <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fe fe-more-vertical"></i>
                              </a>

                              <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item href" [routerLink]="[currentRoute + installation.id + '/checkpoints']">
                                  Consulter les points de contrôle
                                </a>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <app-pagination (returnEvent)="dispatchPagination($event)"
                                    [count_elements]="count"
                                    [current_page]="page"
                                    [elements_length]="installations.length"
                                    [limit]="limit"
                                    [total_pages]="pages"
                                    ngDefaultControl>
                    </app-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="loader-content" *ngIf="!loader && !installations.length">
          <p>Aucun résultat</p>
        </div>


        <!-- FILTER -->
        <div *ngIf="!loader && registry && checkIfFilter()">
          <div class="card flex-fill">
            <div class="card-body">
              <div class="row mb-05">
                <div class="col">
                  <h3 class="header-title">Filtres</h3>

                  <small class="form-text text-muted">
                    <span>Séléctionnez les différents filtres souhaités pour un résultat plus pertinent</span>
                  </small>
                </div>
              </div>

              <div class="row">
                <div class="form-group" [ngClass]="setFilterWidth()" *ngIf="registry.checkpoints_module || registry.checkpoints_custom_module || registry.checkpoints_direct_module">
                  <label>Points de contrôle</label>
                  <ng-select [items]="filterByCheckpoints"
                             [multiple]="false"
                             bindValue="id"
                             bindLabel="full_name"
                             notFoundText="Aucun résultat"
                             [selectableGroup]="true"
                             [closeOnSelect]="true"
                             placeholder="Sélectionnez un point de contrôle"
                             (change)="filterBy('filter_checkpoints', $event)">
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index"
                                 class="overflow-hidden">
                      <div class="text-truncate">
                        {{ item.full_name }}
                      </div>
                    </ng-template>
                  </ng-select>
                </div>

                <div class="form-group" [ngClass]="setFilterWidth()" *ngIf="registry.commissions_module || registry.expertises_module || registry.agencies_module">
                  <label>Degré d'urgence</label>
                  <ng-select [items]="filterByEmergencies"
                             [multiple]="false"
                             bindValue="id"
                             bindLabel="name"
                             notFoundText="Aucun résultat"
                             [selectableGroup]="true"
                             [closeOnSelect]="true"
                             placeholder="Sélectionnez un degré d'urgence"
                             (change)="filterBy('filter_emergencies', $event)">
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                      {{ item.name }}
                    </ng-template>
                  </ng-select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- OBSERVATIONS + PRESCRIPTIONS + CHECKPOINTS + TRAININGS -->
        <div *ngIf="registry && (registry.checkpoints_module || registry.checkpoints_custom_module || registry.checkpoints_direct_module ||
            registry.commissions_module || registry.expertises_module || registry.agencies_module || registry.checkpoints_module ||
            registry.checkpoints_custom_module || registry.checkpoints_direct_module || registry.formations_module)">
          <ul class="nav nav-tabs nav-overflow">
            <li class="nav-item href"
                *ngIf="registry.checkpoints_module || registry.checkpoints_custom_module || registry.checkpoints_direct_module">
              <a class="nav-link" [ngClass]="{ 'active': activeTab === 'observations'}"
                 (click)="changeTabs('observations')" aria-label="Récapitulatif des observations">Observations</a>
            </li>

            <li class="nav-item href"
                *ngIf="registry.checkpoints_module || registry.checkpoints_custom_module || registry.checkpoints_direct_module">
              <a class="nav-link" [ngClass]="{ 'active': activeTab === 'checkpoints'}"
                 (click)="changeTabs('checkpoints')" aria-label="Récapitulatif des points de contrôle">Points de
                contrôle</a>
            </li>
          </ul>

          <div class="tab-content pt-4" id="myTabContent">
            <!-- OBSERVATIONS -->
            <div class="tab-pane fade" role="tabpanel" aria-labelledby="observations myTabContent"
                 [ngClass]="{ 'show active': activeTab === 'observations'}">
              <div class="card flex-fill">
                <div class="card-body">
                  <div class="row mb-3">
                    <div class="col-md-6">
                      <h3 class="header-title">Observations</h3>
                      <small class="form-text text-muted">
                        <span>Liste d'observations à lever</span>
                      </small>
                    </div>

                    <div class="col-md-6">
                      <app-searchbar [(ngModel)]="searchObservation" (keyup)="searchObservations()" [searchbar_id]="'observations_search_bar'" [searchbar_name]="'observations_search_bar'" ngDefaultControl></app-searchbar>
                    </div>
                  </div>

                  <div class="table-responsive">
                    <table class="table table-striped">
                      <thead>
                      <tr>
                        <th class="href" (click)="filterObservations('Observations.emergencies')">
                          <a class="text-muted">Degré d'urgence</a>
                          <span class="fe fe-arrow-down"
                                *ngIf="sortByObservations === 'Observations.emergencies' && sortDirectionObservations === 'asc'"></span>
                          <span class="fe fe-arrow-up"
                                *ngIf="sortByObservations === 'Observations.emergencies' && sortDirectionObservations === 'desc'"></span>
                          <span class="fe fe-arrow-right"
                                *ngIf="sortByObservations !== 'Observations.emergencies'"></span>
                        </th>
                        <th class="href" (click)="filterObservations('Reports.visit_date')">
                          <a class="text-muted">Date de visite</a>
                          <span class="fe fe-arrow-down"
                                *ngIf="sortByObservations === 'Reports.visit_date' && sortDirectionObservations === 'asc'"></span>
                          <span class="fe fe-arrow-up"
                                *ngIf="sortByObservations === 'Reports.visit_date' && sortDirectionObservations === 'desc'"></span>
                          <span class="fe fe-arrow-right"
                                *ngIf="sortByObservations !== 'Reports.visit_date'"></span>
                        </th>
                        <th class="href" (click)="filterObservations('Checkpoints.name')">
                          <a class="text-muted">Installation</a>
                          <span class="fe fe-arrow-down"
                                *ngIf="sortByObservations === 'Checkpoints.name' && sortDirectionObservations === 'asc'"></span>
                          <span class="fe fe-arrow-up"
                                *ngIf="sortByObservations === 'Checkpoints.name' && sortDirectionObservations === 'desc'"></span>
                          <span class="fe fe-arrow-right" *ngIf="sortByObservations !== 'Checkpoints.name'"></span>
                        </th>
                        <th class="href" (click)="filterObservations('Observations.name')">
                          <a class="text-muted">Observation(s)</a>
                          <span class="fe fe-arrow-down"
                                *ngIf="sortByObservations === 'Observations.name' && sortDirectionObservations === 'asc'"></span>
                          <span class="fe fe-arrow-up"
                                *ngIf="sortByObservations === 'Observations.name' && sortDirectionObservations === 'desc'"></span>
                          <span class="fe fe-arrow-right" *ngIf="sortByObservations !== 'Observations.name'"></span>
                        </th>
                        <th></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngIf="loaderObservations">
                        <td colspan="5">
                          <div class="is-loading loader-content"></div>
                        </td>
                      </tr>
                      <tr *ngIf="!loaderObservations && !observations.length">
                        <td colspan="5">
                          <div class="loader-content">
                            Aucun résultat
                          </div>
                        </td>
                      </tr>
                      <tr class="dropdown" *ngFor="let observation of observations">
                        <td>
                              <span class="label label-rounded" [ngClass]="getEmergencies(observation) && getEmergencies(observation).color">
                                {{ getEmergencies(observation) && getEmergencies(observation).value }}
                              </span>
                        </td>
                        <td>{{ observation.visit_date | date : 'dd/MM/yyyy' }}</td>
                        <td>
                          {{ observation.report.establishment_checkpoint.full_name }}
                        </td>
                        <td>{{ observation.name }}</td>
                        <td class="d-flex flex-row-reverse">
                          <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fe fe-more-vertical"></i>
                          </a>

                          <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item href" [routerLink]="[currentRoute + 'observations/' + observation.id + '/state']" *ngIf="observation['canStateObservation']">
                              Lever l'observation
                            </a>

                            <a class="dropdown-item href"
                               [routerLink]="[currentRoute + 'observations/' + observation.id]">
                              {{ observation['canUpdateObservation'] ? 'Éditer l\'observation' : 'Consulter l\'observation' }}
                            </a>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <app-pagination (returnEvent)="dispatchPaginationObservations($event)"
                                  [count_elements]="countObservations"
                                  [current_page]="pageObservations"
                                  [elements_length]="observations.length"
                                  [limit]="limitObservations"
                                  [total_pages]="pagesObservations"
                                  ngDefaultControl>
                  </app-pagination>
                </div>
              </div>
            </div>

            <!-- CHECKPOINTS -->
            <div class="tab-pane fade" role="tabpanel" aria-labelledby="checkpoints myTabContent"
                 [ngClass]="{ 'show active': activeTab === 'checkpoints'}">
              <div class="card flex-fill">
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <h3 class="header-title">Points de contrôle</h3>

                      <small class="form-text text-muted">
                        <span>Liste des points de contrôle</span>
                      </small>
                    </div>

                    <div class="col-md-6">
                      <app-searchbar [(ngModel)]="searchCheckpoint" (keyup)="searchCheckpoints()" [searchbar_id]="'checkpoints_search_bar'" [searchbar_name]="'checkpoints_search_bar'" ngDefaultControl></app-searchbar>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-auto ml-auto">
                      <ul class="nav nav-tabs nav-tabs-sm">
                        <li class="nav-item href" (click)="switchTabCheckpoints(true)">
                          <a class="nav-link" [ngClass]="{ 'active': nextCheckpoint }">
                            Des 12 prochains mois
                          </a>
                        </li>

                        <li class="nav-item href" (click)="switchTabCheckpoints(false)">
                          <a class="nav-link" [ngClass]="{ 'active': !nextCheckpoint }">
                            En retard
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="table-responsive">
                    <table class="table table-striped">
                      <thead>
                      <tr>
                        <th *ngIf="!establishment" class="href" (click)="filterCheckpoints('Establishments.name')">
                          <a class="text-muted">Établissement</a>
                          <span class="fe fe-arrow-down"
                                *ngIf="sortByCheckpoints === 'Establishments.name' && sortDirectionCheckpoints === 'asc'"></span>
                          <span class="fe fe-arrow-up"
                                *ngIf="sortByCheckpoints === 'Establishments.name' && sortDirectionCheckpoints === 'desc'"></span>
                          <span class="fe fe-arrow-right"
                                *ngIf="sortByCheckpoints !== 'Establishments.name'"></span>
                        </th>
                        <th class="href" (click)="filterCheckpoints('EstablishmentCheckpoints.next_report_date')">
                          <a class="text-muted">Date</a>
                          <span class="fe fe-arrow-down"
                                *ngIf="sortByCheckpoints === 'EstablishmentCheckpoints.next_report_date' && sortDirectionCheckpoints === 'asc'"></span>
                          <span class="fe fe-arrow-up"
                                *ngIf="sortByCheckpoints === 'EstablishmentCheckpoints.next_report_date' && sortDirectionCheckpoints === 'desc'"></span>
                          <span class="fe fe-arrow-right"
                                *ngIf="sortByCheckpoints !== 'EstablishmentCheckpoints.next_report_date'"></span>
                        </th>
                        <th class="href" (click)="filterCheckpoints('EstablishmentCheckpoints.name')">
                          <a class="text-muted">Installation</a>
                          <span class="fe fe-arrow-down"
                                *ngIf="sortByCheckpoints === 'EstablishmentCheckpoints.name' && sortDirectionCheckpoints === 'asc'"></span>
                          <span class="fe fe-arrow-up"
                                *ngIf="sortByCheckpoints === 'EstablishmentCheckpoints.name' && sortDirectionCheckpoints === 'desc'"></span>
                          <span class="fe fe-arrow-right"
                                *ngIf="sortByCheckpoints !== 'EstablishmentCheckpoints.name'"></span>
                        </th>
                        <th></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngIf="loaderCheckpoints">
                        <td colspan="4">
                          <div class="is-loading loader-content"></div>
                        </td>
                      </tr>
                      <tr *ngIf="!loaderCheckpoints && !checkpoints.length">
                        <td colspan="4">
                          <div class="loader-content">
                            Aucun résultat
                          </div>
                        </td>
                      </tr>
                      <tr class="dropdown" *ngFor="let checkpoint of checkpoints">
                        <td *ngIf="!establishment">{{ checkpoint.establishment_registry.establishment.name }}</td>
                        <td>{{ checkpoint.next_report_date ? (checkpoint.next_report_date | date : 'dd/MM/yyyy') : 'Jamais réalisé' }}</td>
                        <td>
                          {{ checkpoint.full_name }}
                        </td>
                        <td class="d-flex flex-row-reverse">
                          <div class="btn-group" *ngIf="canCreateCheckpoints">
                            <a class="btn btn-sm btn-white" [routerLink]="[currentRoute + 'checkpoints/' + checkpoint.id + '/reports/create']">
                              Ajouter un rapport
                            </a>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <app-pagination (returnEvent)="dispatchPaginationCheckpoints($event)"
                                  [count_elements]="countCheckpoints"
                                  [current_page]="pageCheckpoints"
                                  [elements_length]="checkpoints.length"
                                  [limit]="limitCheckpoints"
                                  [total_pages]="pagesCheckpoints"
                                  ngDefaultControl>
                  </app-pagination>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<router-outlet></router-outlet>
