import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Subject } from 'rxjs';
import { NgbTimeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { NgbTimeStringAdapter } from '../../../core/utils/ngb-time-string-adapter';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { UsersRequestService } from '../../../services/users.request.service';
import { AllSanitaryPoolsComponent } from '../all-sanitary-pools/all-sanitary-pools.component';
import { SanitaryPoolsRequestService } from '../../../services/sanitary-pools.request.service';

import BatiFile from '../../../../shared/interfaces/bati_file';

declare function Flatpickr(): any;
declare function Dropzones(): any;

@Component({
  selector: 'app-update-sanitary-pool',
  templateUrl: './update-sanitary-pool.component.html',
  styleUrls: ['./update-sanitary-pool.component.scss'],
  providers: [{ provide: NgbTimeAdapter, useClass: NgbTimeStringAdapter }]
})
export class UpdateSanitaryPoolComponent implements OnInit, AfterViewInit, OnDestroy {
  counter = Array;
  canCreate = false;
  canUpdate = false;

  activeTab = 1;

  requestInProgress = new Subject();
  requestCount = 0;

  sanitaryPoolForm: FormGroup;
  description = new FormControl('', Validators.required);

  files: BatiFile[] = [];
  filesRequired: BatiFile[] = [];
  enterprises: any = [];
  user: string;

  errors = {};

  isNew = true;
  newDocument = false;
  loader = false;
  loaderContent = false;

  modalSanitaryPool = null;

  constructor(
      private router: Router,
      private formBuilder: FormBuilder,
      private notifier: NotifierService,
      private activatedRoute: ActivatedRoute,
      private usersService: UsersRequestService,
      private allSanitaryOperations: AllSanitaryPoolsComponent,
      private sanitaryPoolsServices: SanitaryPoolsRequestService,
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loaderContent = this.requestCount > 0;
    });

    this.sanitaryPoolForm = this.formBuilder.group({
      description: this.description,
    });

    if (this.activatedRoute.snapshot.params.sanitary_pool_id) {
      this.isNew = false;
      this.getSanitaryPool();
    } else {
      setTimeout(() => {
        Flatpickr();
      }, 1);
    }
  }

  ngAfterViewInit() {
    Dropzones();
    this.modalSanitaryPool.open();
  }

  ngOnDestroy() {
    const flatPicker = document.getElementsByClassName('flatpickr-calendar') as HTMLCollection;
    for (let i = flatPicker.length; i >= 0; i--) {
      if (flatPicker[i]) {
        flatPicker[i].remove();
      }
    }

    const dropZones = document.getElementsByClassName('dz-hidden-input') as HTMLCollection;
    for (let i = 0; i < dropZones.length; i++) {
      dropZones[i].remove();
    }

    this.clearTooltip();

    this.modalSanitaryPool.close();
  }
  clearTooltip() {
    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }
  changeTabs(activeTab) {
    this.activeTab = activeTab;
  }

  getSanitaryPool() {
    this.requestInProgress.next(true);
    this.sanitaryPoolsServices.getSanitaryPool(
        this.activatedRoute.snapshot.parent.params.registry_id,
        this.activatedRoute.snapshot.parent.params.establishment_id,
        this.activatedRoute.snapshot.params.sanitary_pool_id
    ).subscribe(response => {
      if (response['success']) {
        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];

        this.sanitaryPoolForm.patchValue(response['sanitaryPool']);

        if (!this.canUpdate) {
          this.sanitaryPoolForm.disable();
        }
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      Flatpickr();
      this.requestInProgress.next(false);
    });
  }
  createSanitaryPool() {
    let request;
    this.errors = {};
    this.loader = true;

    const data = {
      ...this.sanitaryPoolForm.value,
    };

    request = this.sanitaryPoolsServices.createSanitaryPool(
        this.activatedRoute.snapshot.parent.params.registry_id,
        this.activatedRoute.snapshot.parent.params.establishment_id,
        data
    );

    request.subscribe(response => {
      if (response['success']) {
        this.isNew = false;
        if (this.files.length || this.filesRequired.length) {
          this.newDocument = true;
          this.createDocuments(response['sanitaryPool']['id']);
        } else {
          this.loader = false;
          this.modalSanitaryPool.close();
          CustomNotifierService.getSuccessCreate(this.notifier);
          this.router.navigate([
            'dashboard-registry/' + this.activatedRoute.snapshot.parent.params.registry_id +
            '/establishment/' + this.activatedRoute.snapshot.parent.params.establishment_id +
            '/sanitary-pools/' + response['sanitaryPool']['id'] + '/records']).then();
        }
      } else if (response['errors']) {
        this.loader = false;
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(response['errors'], this.sanitaryPoolForm, this.notifier);
      } else {
        this.loader = false;
      }
    });
  }
  updateSanitaryPool() {
    let request;

    this.errors = {};
    this.loader = true;

    const data = {
      ...this.sanitaryPoolForm.value,
    };

    request = this.sanitaryPoolsServices.updateSanitaryPool(
        this.activatedRoute.snapshot.parent.params.registry_id,
        this.activatedRoute.snapshot.parent.params.establishment_id,
        this.activatedRoute.snapshot.params.sanitary_pool_id,
        data
    );

    request.subscribe(response => {
      if (response['success']) {
        if (this.files.length || this.filesRequired.length) {
          this.createDocuments(this.activatedRoute.snapshot.params.sanitary_pool_id);
        } else {
          this.loader = false;
          this.modalSanitaryPool.close();
          CustomNotifierService.getSuccessUpdate(this.notifier);
        }
      } else if (response['errors']) {
        this.loader = false;
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      } else {
        this.loader = false;
      }
    });
  }
  createDocuments(sanitary_pool_id) {
    let request;
    this.errors = {};

    request = this.sanitaryPoolsServices.createFile(
        this.activatedRoute.snapshot.parent.params.registry_id,
        this.activatedRoute.snapshot.parent.params.establishment_id,
        sanitary_pool_id,
        [...this.files, ...this.filesRequired]
    );

    request.subscribe(response => {
      if (response['success']) {
        this.modalSanitaryPool.close();
        CustomNotifierService.getSuccessCreate(this.notifier);
        if (this.newDocument) {
          this.router.navigate([
            'dashboard-registry/' + this.activatedRoute.snapshot.parent.params.registry_id +
            '/establishment/' + this.activatedRoute.snapshot.parent.params.establishment_id +
            '/sanitary-pools/' + sanitary_pool_id + '/records']).then();
        }
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loader = false;
    });
  }

  chooseFile() {
    const element: HTMLElement = document.getElementById('inputFile') as HTMLElement;
    element.click();
  }
  onFileChange(event) {
    if (event.target && event.target.files && event.target.files.length) {
      for (let i = 0; i < event.target.files.length; i++) {
        const reader = new FileReader();
        const file = event.target.files[i];
        reader.readAsDataURL(file);

        reader.onload = () => {
          let extension = '';
          let name = '';

          const split = file.name.split('.');
          if (split.length) {
            name = split[0];
            extension = split[split.length - 1];
          }

          this.files.push({ file_base64: reader.result, name: name, extension: extension });
        };
      }
    } else {
      let extension = '';
      let name = '';

      const split = event.name.split('.');
      if (split.length) {
        name = split[0];
        extension = split[split.length - 1];
      }

      this.files.push({ file_base64: event.file, name: name, extension: extension });
    }
    const element: HTMLInputElement = document.getElementById('inputFile') as HTMLInputElement;
    element.value = '';
  }
  removeFile(index) {
    this.files.splice(index, 1);

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  bindModal(modal) {
    this.modalSanitaryPool = modal;
  }
  closeModal() {
    this.router.navigate([this.activatedRoute.snapshot.parent.url.join('/')]).then(() => {
      this.allSanitaryOperations.refreshData();
    });
  }
}
