import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AllExpertisesModule } from './all-expertises/all-expertises.module';
import { UpdateExpertiseModule } from './update-expertise/update-expertise.module';
import { UploadExpertiseFileModule } from './upload-expertise-file/upload-expertise-file.module';

import { CommissionsRequestService } from '../../services/commissions.request.service';

@NgModule({
  imports: [
    CommonModule,
    AllExpertisesModule,
    UpdateExpertiseModule,
    UploadExpertiseFileModule
  ],
  providers: [
    CommissionsRequestService
  ]
})
export class ExpertisesModule {}
