import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { HttpClient } from '@angular/common/http';
import { RequestService } from './request.service';

@Injectable()
export class RvratsRequestService extends RequestService {
  constructor(
    protected http: HttpClient,
    protected notifier: NotifierService
  ) {
    super(http, notifier);
  }

  /// GET
  public getAllRvrats(search, sort, order, limit, page, registry_id, establishment_id, worksheet_id) {
    return this.get( 'dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/worksheet/' + worksheet_id
      + '/rvrats?' + 'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getRvrat(registry_id, establishment_id, rvrat_id) {
    return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/rvrat/' + rvrat_id);
  }
  public getFiles(search, sort, order, limit, page, registry_id, establishment_id, rvrat_id) {
    return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/rvrat/' + rvrat_id +
      '/files?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getFile(registry_id, establishment_id, rvrat_id, file_id) {
    return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/rvrat/' + rvrat_id +
      '/files/' + file_id);
  }
  public getWorksheet(registry_id, establishment_id, rvrat_id) {
    if (rvrat_id) {
      return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/rvrat/' + rvrat_id +
        '/worksheets');
    } else {
      return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/rvrat/worksheets');
    }
  }

  /// CREATE
  public createRvrat(registry_id, establishment_id, worksheet_id, rvrat) {
    return this.post('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/worksheet/' + worksheet_id
      + '/rvrat', { rvrat });
  }
  public createFile(registry_id, establishment_id, rvrat_id, file) {
    return this.post('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/rvrat/' + rvrat_id + '/files',
      { file: file });
  }

  /// UPDATE
  public updateRvrat(registry_id, establishment_id, rvrat_id, rvrat) {
    return this.put('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/rvrat/' + rvrat_id,
      { rvrat });
  }
  public updateFile(registry_id, establishment_id, rvrat_id, file_id, file) {
    return this.put('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/rvrat/' + rvrat_id +
      '/files/' + file_id, { file: file });
  }

  /// DELETE
  public deleteRvrat(registry_id, establishment_id, rvrat_id) {
    return this.delete('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/rvrat/' + rvrat_id);
  }
  public deleteFile(registry_id, establishment_id, rvrat_id, file_id) {
    return this.delete('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/rvrat/' + rvrat_id +
      '/files/' + file_id);
  }

  /// DOWNLOAD
  public downloadFile(registry_id, establishment_id, rvrat_id, file_id) {
    return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/rvrat/' + rvrat_id +
      '/files/' + file_id + '/download', { responseType: 'arraybuffer' });
  }
}
