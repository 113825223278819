import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Subject } from 'rxjs';
import { NgbTimeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { NgbTimeStringAdapter } from '../../../core/utils/ngb-time-string-adapter';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { UsersRequestService } from '../../../services/users.request.service';
import { SanitaryContributorsRequestService } from '../../../services/sanitary-contributors.request.service';

import { AllSanitaryContributorsComponent } from '../all-sanitary-contributors/all-sanitary-contributors.component';

import BatiFile from '../../../../shared/interfaces/bati_file';

declare function Flatpickr(): any;
declare function Dropzones(): any;

@Component({
  selector: 'app-update-sanitary-contributor',
  templateUrl: './update-sanitary-contributor.component.html',
  styleUrls: ['./update-sanitary-contributor.component.scss'],
  providers: [{ provide: NgbTimeAdapter, useClass: NgbTimeStringAdapter }]
})
export class UpdateSanitaryContributorComponent implements OnInit, AfterViewInit, OnDestroy {
  canCreate = false;
  canUpdate = false;

  requestInProgress = new Subject();
  requestCount = 0;

  sanitaryContributorForm: FormGroup;
  lastname = new FormControl('', Validators.required);
  firstname = new FormControl('', Validators.required);
  qualification = new FormControl('', Validators.required);
  phone = new FormControl('', Validators.required);
  email = new FormControl('', Validators.required);
  enterprise = new FormControl('');
  due_date = new FormControl('');

  enterprises: any = [];
  user: string;

  view = '';

  files: BatiFile[] = [];

  errors = {};

  isNew = true;
  loader = false;
  loaderContent = false;

  modalSanitaryContributor = null;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private usersService: UsersRequestService,
    private allSanitaryContributors: AllSanitaryContributorsComponent,
    private sanitaryContributorsService: SanitaryContributorsRequestService,
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loaderContent = this.requestCount > 0;
    });

    if (this.activatedRoute.snapshot.url[0].path === 'create_external') {
      this.view = 'external';
      if (!this.loaderContent) {
        setTimeout(() => {
          Flatpickr();
        }, 1);
      }
      this.enterprise.setValidators([Validators.required]);
      this.due_date.setValidators([Validators.required]);
      this.sanitaryContributorForm = this.formBuilder.group({
        lastname: this.lastname,
        firstname: this.firstname,
        qualification: this.qualification,
        phone: this.phone,
        email: this.email,
        enterprise: this.enterprise,
        due_date: this.due_date,
        external: true,
      });
    } else {
      this.sanitaryContributorForm = this.formBuilder.group({
        lastname: this.lastname,
        firstname: this.firstname,
        qualification: this.qualification,
        phone: this.phone,
        email: this.email,
        external: false,
      });
    }

    if (this.activatedRoute.snapshot.params.sanitary_contributor_id) {
      this.isNew = false;
      if (this.activatedRoute.snapshot.url[0].path === 'external') {
        this.view = 'external';
        this.enterprise.setValidators([Validators.required]);
        this.due_date.setValidators([Validators.required]);
        this.sanitaryContributorForm = this.formBuilder.group({
          lastname: this.lastname,
          firstname: this.firstname,
          qualification: this.qualification,
          phone: this.phone,
          email: this.email,
          enterprise: this.enterprise,
          due_date: this.due_date,
          external: true,
        });
      }
      this.getSanitaryContributor();
    }
  }

  ngAfterViewInit() {
    Dropzones();
    this.modalSanitaryContributor.open();
  }

  ngOnDestroy() {
    const flatPicker = document.getElementsByClassName('flatpickr-calendar') as HTMLCollection;
    for (let i = flatPicker.length; i >= 0; i--) {
      if (flatPicker[i]) {
        flatPicker[i].remove();
      }
    }

    const dropZones = document.getElementsByClassName('dz-hidden-input') as HTMLCollection;
    for (let i = 0; i < dropZones.length; i++) {
      dropZones[i].remove();
    }

    this.modalSanitaryContributor.close();
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  getSanitaryContributor() {
    this.requestInProgress.next(true);
    this.sanitaryContributorsService.getSanitaryContributor(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.activatedRoute.snapshot.params.sanitary_contributor_id
    ).subscribe(response => {
      if (response['success']) {
        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];
        this.sanitaryContributorForm.patchValue(response['sanitaryContributor']);
        if (!this.canUpdate) {
          this.sanitaryContributorForm.disable();
        }
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      Flatpickr();
      this.requestInProgress.next(false);
    });
  }

  createSanitaryContributor() {
    let request;
    this.errors = {};
    this.loader = true;

    request = this.sanitaryContributorsService.createSanitaryContributor(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.sanitaryContributorForm.value
    );

    request.subscribe(response => {
      if (response['success']) {
        this.isNew = false;
        if (this.files.length) {
          this.createDocuments(response['sanitaryContributor']['id']);
        } else {
          this.loader = false;
          this.modalSanitaryContributor.close();
          CustomNotifierService.getSuccessCreate(this.notifier);
        }
      } else if (response['errors']) {
        this.loader = false;
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(response['errors'], this.sanitaryContributorForm, this.notifier);
      } else {
        this.loader = false;
      }
    });
  }

  updateSanitaryContributor() {
    let request;

    this.errors = {};
    this.loader = true;

    request = this.sanitaryContributorsService.updateSanitaryContributor(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.activatedRoute.snapshot.params.sanitary_contributor_id,
      this.sanitaryContributorForm.value
    );

    request.subscribe(response => {
      if (response['success']) {
        if (this.files.length) {
          this.createDocuments(this.activatedRoute.snapshot.params.sanitary_contributor_id);
        } else {
          this.loader = false;
          this.modalSanitaryContributor.close();
          CustomNotifierService.getSuccessUpdate(this.notifier);
        }
      } else if (response['errors']) {
        this.loader = false;
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(response['errors'], this.sanitaryContributorForm, this.notifier);
      } else {
        this.loader = false;
      }
    });
  }

  createDocuments(sanitary_contributor_id) {
    let request;
    this.errors = {};

    request = this.sanitaryContributorsService.createFile(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      sanitary_contributor_id,
      this.files
    );

    request.subscribe(response => {
      if (response['success']) {
        this.modalSanitaryContributor.close();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loader = false;
    });
  }

  chooseFile() {
    const element: HTMLElement = document.getElementById('inputFile') as HTMLElement;
    element.click();
  }

  onFileChange(event) {
    if (event.target && event.target.files && event.target.files.length) {
      for (let i = 0; i < event.target.files.length; i++) {
        const reader = new FileReader();
        const file = event.target.files[i];
        reader.readAsDataURL(file);

        reader.onload = () => {
          let extension = '';
          let name = '';

          const split = file.name.split('.');
          if (split.length) {
            name = split[0];
            extension = split[split.length - 1];
          }

          this.files.push({ file_base64: reader.result, name: name, extension: extension });
        };
      }
    } else {
      let extension = '';
      let name = '';

      const split = event.name.split('.');
      if (split.length) {
        name = split[0];
        extension = split[split.length - 1];
      }

      this.files.push({ file_base64: event.file, name: name, extension: extension });
    }
    const element: HTMLInputElement = document.getElementById('inputFile') as HTMLInputElement;
    element.value = '';
  }

  removeFile(index) {
    this.files.splice(index, 1);

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  bindModal(modal) {
    this.modalSanitaryContributor = modal;
  }

  closeModal() {
    this.router.navigate([this.activatedRoute.snapshot.parent.url.join('/')]).then(() => {
      this.allSanitaryContributors.refreshDataInternal();
      this.allSanitaryContributors.refreshDataExternal();
    });
  }
}
