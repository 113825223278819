import { AfterViewInit, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';

import { AllRvratPrescriptionsComponent } from '../all-rvrat-prescriptions/all-rvrat-prescriptions.component';
import { DashboardRegistryComponent } from '../../registries/dashboard-registry/dashboard-registry.component';

import { RvratPrescriptionsRequestService } from '../../../services/rvrat-prescriptions.request.service';
import { CustomNotifierService } from '../../../services/custom.notifier.service';

import BatiFile from '../../../../shared/interfaces/bati_file';

declare function Flatpickr(): any;

@Component({
  selector: 'app-update-rvrat-prescription-state',
  templateUrl: './update-rvrat-prescription-state.component.html',
  styleUrls: ['./update-rvrat-prescription-state.component.scss']
})
export class UpdateRvratPrescriptionStateComponent implements OnInit, AfterViewInit, OnDestroy {
  canCreate = false;
  canUpdate = false;

  allRvratPrescriptions: any;
  dashboardRegistry: any;

  files: BatiFile[] = [];

  rvratPrescriptionStateForm: FormGroup;
  id = new FormControl('');
  rvrat_id = new FormControl('');
  name = new FormControl({ value: null, disabled: true });
  state = new FormControl(false);
  person_changing_state = new FormControl(null);
  date_changing_state = new FormControl(null);
  financial_real = new FormControl(0);
  comment_changing_state = new FormControl(null);

  errors = {};

  isNew = true;
  loader = false;
  loaderContent = false;

  prescriptions: any = [];
  selectedPrescriptions: any = [];

  modalPrescriptionState = null;

  constructor(
    private router: Router,
    private injector: Injector,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private rvratPrescriptionsService: RvratPrescriptionsRequestService
  ) {
    if (this.activatedRoute.snapshot.parent.params.rvrat_id) {
      this.allRvratPrescriptions = <AllRvratPrescriptionsComponent>this.injector.get(AllRvratPrescriptionsComponent);
    } else {
      this.dashboardRegistry = <DashboardRegistryComponent>this.injector.get(DashboardRegistryComponent);
    }
  }

  ngOnInit() {
    this.rvratPrescriptionStateForm = this.formBuilder.group({
      id: this.id,
      rvrat_id: this.rvrat_id,
      name: this.name,
      state: this.state,
      person_changing_state: this.person_changing_state,
      date_changing_state: this.date_changing_state,
      financial_real: this.financial_real,
      comment_changing_state: this.comment_changing_state
    });

    this.getPrescriptionState();
    Flatpickr();
  }

  ngAfterViewInit() {
    this.modalPrescriptionState.open();
  }

  ngOnDestroy() {
    const flatPicker = document.getElementsByClassName('flatpickr-calendar') as HTMLCollection;
    for (let i = flatPicker.length; i >= 0; i--) {
      if (flatPicker[i]) {
        flatPicker[i].remove();
      }
    }

    this.modalPrescriptionState.close();
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  getPrescriptionState() {
    let request;
    this.loaderContent = true;

    if (this.activatedRoute.snapshot.parent.params.establishment_id && this.activatedRoute.snapshot.parent.params.rvrat_id) {
      request = this.rvratPrescriptionsService.getRvratPrescriptionState(
        this.activatedRoute.snapshot.parent.params.registry_id,
        this.activatedRoute.snapshot.parent.params.establishment_id,
        this.activatedRoute.snapshot.parent.params.rvrat_id,
        this.activatedRoute.snapshot.params.rvrat_prescription_id
      );
    } else if (this.activatedRoute.snapshot.parent.params.establishment_id && !this.activatedRoute.snapshot.parent.params.rvrat_id) {
      request = this.rvratPrescriptionsService.getEstablishmentRvratPrescriptionState(
        this.activatedRoute.snapshot.parent.params.registry_id,
        this.activatedRoute.snapshot.parent.params.establishment_id,
        this.activatedRoute.snapshot.params.rvrat_prescription_id
      );
    } else {
      request = this.rvratPrescriptionsService.getRegistryRvratPrescriptionState(
        this.activatedRoute.snapshot.parent.params.registry_id,
        this.activatedRoute.snapshot.params.rvrat_prescription_id
      );
    }

    request.subscribe(response => {
      if (response['success']) {
        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];

        this.rvratPrescriptionStateForm.patchValue({ ...response['rvrat_prescription'], state: true });
        response['rvrat_prescriptions'].forEach(prescription => {
          prescription.group = true;
          this.prescriptions.push(prescription);
        });

        if (!this.canUpdate) {
          this.rvratPrescriptionStateForm.disable();
        }
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      Flatpickr();
      this.loaderContent = false;
    });
  }

  updatePrescriptionState() {
    let request;
    let prescription;

    this.errors = {};
    this.loader = true;

    prescription = {
      ...this.rvratPrescriptionStateForm.value,
      id: this.id.value,
      name: this.name.value,
      rvrat_id: this.rvrat_id.value,
      rvrat_prescriptions: this.selectedPrescriptions,
      files: this.files
    };

    if (this.activatedRoute.snapshot.parent.params.establishment_id && this.activatedRoute.snapshot.parent.params.rvrat_id) {
      request = this.rvratPrescriptionsService.updateRvratPrescriptionState(
        this.activatedRoute.snapshot.parent.params.registry_id,
        this.activatedRoute.snapshot.parent.params.establishment_id,
        this.activatedRoute.snapshot.parent.params.rvrat_id,
        this.activatedRoute.snapshot.params.rvrat_prescription_id,
        prescription
      );
    } else if (this.activatedRoute.snapshot.parent.params.establishment_id && !this.activatedRoute.snapshot.parent.params.rvrat_id) {
      request = this.rvratPrescriptionsService.updateEstablishmentRvratPrescriptionState(
        this.activatedRoute.snapshot.parent.params.registry_id,
        this.activatedRoute.snapshot.parent.params.establishment_id,
        this.activatedRoute.snapshot.params.rvrat_prescription_id,
        prescription
      );
    } else {
      request = this.rvratPrescriptionsService.updateRegistryRvratPrescriptionState(
        this.activatedRoute.snapshot.parent.params.registry_id,
        this.activatedRoute.snapshot.params.rvrat_prescription_id,
        prescription
      );
    }

    request.subscribe(response => {
      if (response['success']) {
        this.loader = false;
        this.modalPrescriptionState.close();
        CustomNotifierService.getSuccessUpdate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loader = false;
    });
  }

  changeState() {
    if (this.state.value) {
      setTimeout(() => {
        Flatpickr();
      }, 1);
    }
  }

  chooseFile() {
    const element: HTMLElement = document.getElementById('inputFile') as HTMLElement;
    element.click();
  }

  onFileChange(event) {
    if (event.target && event.target.files && event.target.files.length) {
      for (let i = 0; i < event.target.files.length; i++) {
        const reader = new FileReader();
        const file = event.target.files[i];
        reader.readAsDataURL(file);

        reader.onload = () => {
          let extension = '';
          let name = '';

          const split = file.name.split('.');
          if (split.length) {
            name = split[0];
            extension = split[split.length - 1];
          }

          this.files.push({ file_base64: reader.result, name: name, extension: extension });
        };
      }
    } else {
      let extension = '';
      let name = '';

      const split = event.name.split('.');
      if (split.length) {
        name = split[0];
        extension = split[split.length - 1];
      }

      this.files.push({ file_base64: event.file, name: name, extension: extension });
    }
    const element: HTMLInputElement = document.getElementById('inputFile') as HTMLInputElement;
    element.value = '';
  }

  removeFile(index) {
    this.files.splice(index, 1);

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  bindModal(modal) {
    this.modalPrescriptionState = modal;
  }

  closeModal() {
    if (this.activatedRoute.snapshot.parent.params.rvrat_id) {
      this.allRvratPrescriptions.refreshData();
    } else {
      this.dashboardRegistry.getRvratPrescriptions();
    }
    this.router.navigate([this.activatedRoute.snapshot.parent.url.join('/')]).then(() => {});
  }
}
