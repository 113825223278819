<app-modal-right (eventOnClose)="closeModal()" #role>{{ binModal(role) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6
          class="header-pretitle">{{ isNew ? 'Créer un rôle' : canUpdate ? 'Mettre à jour un rôle' : 'Consulter un rôle' }}</h6>

        <h1 class="header-title">Rôle</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <form [formGroup]="roleForm" [hidden]="loaderContent">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="validationName" required>Nom</label>
            <input type="text" formControlName="name" id="validationName" class="form-control" placeholder="Nom*" maxlength="255"
                   [ngClass]="{ 'is-invalid': name.invalid && (name.dirty || name.touched) }">
            <div *ngIf="name.invalid && (name.dirty || name.touched)" class="invalid-feedback">
              {{ getErrorMessage("name") }}
            </div>
          </div>

          <div class="form-group">
            <label for="validationDescription">Description</label>
            <input type="text" formControlName="description" id="validationDescription" class="form-control" placeholder="Description" maxlength="255"
                   [ngClass]="{ 'is-invalid': description.invalid && (description.dirty || description.touched) }">
            <div *ngIf="description.invalid && (description.dirty || description.touched)" class="invalid-feedback">
              {{ getErrorMessage("description") }}
            </div>
          </div>

          <div class="form-group">
            <label for="validationOrder" required>Ordre d'affichage</label>
            <input type="number" formControlName="order_number" id="validationOrder" class="form-control" placeholder="Ordre d'affichage" [ngClass]="{ 'is-invalid': order_number.invalid && (order_number.dirty || order_number.touched) }" min="0">
            <div *ngIf="order_number.invalid && (order_number.dirty || order_number.touched)" class="invalid-feedback">
              {{ getErrorMessage("order_number") }}
            </div>
          </div>

          <div class="form-group d-flex justify-content-start align-items-center">
            <div class="col pl-0">
              Ce rôle peut recevoir des alertes e-mail ?
            </div>
            <div class="custom-checkbox-toggle">
              <input type="checkbox" class="custom-control-input" id="canReceiveAlert" formControlName="can_receive_alert"
                     [ngClass]="{ 'backgroundColor': can_receive_alert.value }">
              <label class="custom-control-label" [ngClass]="{ 'backgroundColor': can_receive_alert.value }" for="canReceiveAlert"></label>
            </div>
          </div>

          <div class="form-group d-flex justify-content-start align-items-center">
            <div class="col pl-0">
              Ce rôle est-il visible sur la plateforme ?
            </div>
            <div class="custom-checkbox-toggle">
              <input type="checkbox" class="custom-control-input" id="isPublicVisible" formControlName="is_public_visible"
                     [ngClass]="{ 'backgroundColor': is_public_visible.value }">
              <label class="custom-control-label" [ngClass]="{ 'backgroundColor': is_public_visible.value }" for="isPublicVisible"></label>
            </div>
          </div>

          <div class="form-group" *ngIf="!loaderContent && (isNew || (!isNew && roleForm.value.id))">
            <label for="listRights" required>Droits liés au rôle</label>
            <ng-select [items]="rights"
                       [multiple]="true"
                       bindLabel="name"
                       bindValue="id"
                       groupBy="category"
                       notFoundText="Aucun résultat"
                       [selectableGroup]="true"
                       [selectableGroupAsModel]="false"
                       [closeOnSelect]="false"
                       placeholder="Sélectionnez un/ou plusieurs droit(s)"
                       [(ngModel)]="selectedRights" [ngModelOptions]="{ standalone: true }" [disabled]="!isNew && !canUpdate">
              <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                <input type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }"/> {{ item.category }}
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <div class="tooltipHtml" data-placement="bottom" [title]="item.description">
                  <input id="listRights" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }"/>
                  <!-- A utiliser pour afficher l'id des groupes -->
                  <!-- {{ item.id + ' - ' + item.name }} -->
                  {{ item.name }}
                </div>
              </ng-template>
            </ng-select>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div modal-footer class="w-100" *ngIf="isNew || canUpdate">
    <button class="btn btn-primary w-100" (click)="isNew ? createRole() : updateRole()" [ngClass]="{ 'is-loading is-loading-sm': loader }" [disabled]="loader || roleForm.invalid">
      {{ isNew ? 'Créer' : 'Mettre à jour'}}
    </button>
  </div>
</app-modal-right>
