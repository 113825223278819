import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { EmployeesRequestService } from "src/app/services/employees.request.service";
import { TrainingsRequestService } from "src/app/services/trainings.request.service";
import { AffectationEmployeesTrainingModule } from "./affectation-employees-training/affectation-employees-training.module";
import { CreateUpdateTrainingModule } from "./create-update-training/create-update-training.module";
import { EmployeesMustTrainModule } from "./employees-must-train/employees-must-train.module";
import { EmployeesTrainingModule } from "./employees-training/employees-training.module";
import { ExportDownloadModule } from "./export-download/export-download.module";
import { GetAllTrainingsModule } from "./get-all-training/get-all-training.module";
import { TrainingSessionsModule } from "./training-sessions/training-sessions.module";

@NgModule({
  imports: [
    CommonModule,
    GetAllTrainingsModule,
    CreateUpdateTrainingModule,
    EmployeesMustTrainModule,
    EmployeesTrainingModule,
    TrainingSessionsModule,
    ExportDownloadModule,
    AffectationEmployeesTrainingModule,
  ],
  providers: [TrainingsRequestService, EmployeesRequestService],
})
export class AllTrainingsModule {}
