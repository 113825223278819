<div class="main-content">

  <div class="header bg-dark pb-5">
    <div class="container-fluid">

      <div class="header-body">
        <div class="row align-items-end">
          <div class="col">

            <h6 class="header-pretitle text-secondary">
              TABLEAU DE BORD
            </h6>

            <h1 class="header-title text-white">
              Statistiques Document Unique
            </h1>

          </div>
        </div>
      </div>

    </div>
  </div>

  <div class="container-fluid mt-n6">
    <div class="row">
      <div class="col-12">

        <div class="card">
          <!-- INPUTS -->
          <div class="card-header">
            <div class="row align-items-center">
              <!-- ESTABLISHMENTS -->
              <div class="col-6" *ngIf="establishments">
                <div class="form-group">
                  <label>Etablissements</label>
                  <ng-select [items]="establishments"
                             [multiple]="true"
                             bindLabel="name"
                             notFoundText="Aucun résultat"
                             [selectableGroup]="true"
                             [closeOnSelect]="false"
                             bindValue="id"
                             placeholder="Sélectionnez un/des établissement(s)"
                             [(ngModel)]="selectedEstablishments" (ngModelChange)="updateSelectedEstablishments()" [ngModelOptions]="{ standalone: true }">
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                      <input type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }" /> {{ item.name }}
                    </ng-template>
                  </ng-select>

                </div>
              </div>
              <!-- RISK UNITY WORKS -->
              <div class="col-6" *ngIf="riskUnities">
                <div class="form-group">
                  <label>Unités de travail</label>
                  <ng-select [items]="riskUnities"
                             [multiple]="true"
                             bindLabel="name"
                             notFoundText="Aucun résultat"
                             [selectableGroup]="true"
                             [closeOnSelect]="false"
                             bindValue="id"
                             placeholder="Sélectionnez une/des unités de travail(s)"
                             [(ngModel)]="selectedRiskUnities" (ngModelChange)="updateSelectedRiskUnities()" [ngModelOptions]="{ standalone: true }">
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                      <input type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }" /> {{ item.name }}
                    </ng-template>
                  </ng-select>

                </div>
              </div>

              <!-- CLEAR FILTERS -->
              <div class="col-12 d-flex align-items-center justify-content-center">
                <div class="btn-group">
                  <a class="btn btn-block btn-primary mb-4 text-white text-truncate" (click)="resetFilters()">
                    Réinitialiser les filtres
                  </a>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-xl-6">
        <div class="card">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">
                <h4 class="card-header-title">
                  Risques par famille
                </h4>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="chart">
              <div class="is-loading" *ngIf="loaderRisksByFamily"></div>
              <canvas [hidden]="loaderRisksByFamily" id="risksByFamily" class="chart-canvas"></canvas>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-xl-6">
        <div class="card">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">
                <h4 class="card-header-title">
                  Risques par gravité
                </h4>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="chart">
              <div class="is-loading" *ngIf="loaderRisksByGravity"></div>
              <canvas [hidden]="loaderRisksByGravity" id="risksByGravity" class="chart-canvas"></canvas>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-xl-6">
        <div class="card">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">
                <h4 class="card-header-title">
                  Risques par fréquence
                </h4>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="chart">
              <div class="is-loading" *ngIf="loaderRisksByFrequencies"></div>
              <canvas [hidden]="loaderRisksByFrequencies" id="risksByFrequencies" class="chart-canvas"></canvas>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-xl-6">
        <div class="card">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">
                <h4 class="card-header-title">
                  Risques par maitrise
                </h4>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="chart">
              <div class="is-loading" *ngIf="loaderRisksByMastery"></div>
              <canvas [hidden]="loaderRisksByMastery" id="risksByMastery" class="chart-canvas"></canvas>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-xl-6">
        <div class="card">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">
                <h4 class="card-header-title">
                  Risques par criticité
                </h4>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="chart">
              <div class="is-loading" *ngIf="loaderRisksByCritic"></div>
              <canvas [hidden]="loaderRisksByCritic" id="risksByCritic" class="chart-canvas"></canvas>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-xl-6">
        <div class="card">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">
                <h4 class="card-header-title">
                  Nombre d’actions selon leur état de réalisation
                </h4>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="chart">
              <div class="is-loading" *ngIf="loaderActionsByState"></div>
              <canvas [hidden]="loaderActionsByState" id="actionsByState" class="chart-canvas"></canvas>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>

</div>

<div class="modal fade" id="risksFamily" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
  <div class="modal-dialog risk" role="document">
    <div class="modal-content modal-risk">
      <div class="modal-risk-header">
        <h4 class="modal-title" *ngIf="riskFamilies.length">Risques de la famille : {{ familyName }}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
        <div class="is-loading loader-modal-content p-5" *ngIf="loader"></div>
        <div class="table-responsive table-risks" *ngIf="riskFamilies.length && !loader">
          <table class="table table-striped">
            <thead>
            <tr>
              <th>Description du risque</th>
              <th>Fréquence</th>
              <th>Gravité</th>
              <th>Maitrise</th>
              <th>Criticité</th>
              <th>Date de mise à jour</th>
              <th>Moyens de protection et de prévention existants</th>
              <th>Actions correctives à réaliser</th>
            </tr>
            </thead>
            <tbody>
            <tr class="dropdown" *ngFor="let risk of riskFamilies">
              <td>{{ risk.description }}</td>
              <td>{{ JSON.parse(risk.frequency)['title'] }}</td>
              <td>{{ JSON.parse(risk.gravity)['title'] }}</td>
              <td>{{ JSON.parse(risk.master)['title'] }}</td>
              <td><span class="label" [ngClass]="risk.label">{{ risk.critic }}</span></td>
              <td>{{ risk['date_update'] | date : 'dd/MM/yyyy' }}</td>
              <td>{{ risk.current_protection }}</td>
              <td>{{ risk.todo_protection }}</td>
            </tr>
            </tbody>
          </table>
          <app-pagination (returnEvent)="dispatchPaginationAllRisksByFamily($event)"
                          [count_elements]="riskFamiliesCount"
                          [current_page]="riskFamiliesPage"
                          [elements_length]="riskFamilies.length"
                          [limit]="riskFamiliesLimit"
                          [total_pages]="riskFamiliesPages"
                          ngDefaultControl>
          </app-pagination>
        </div>
        <div class="table-responsive table-risks" *ngIf="!riskFamilies.length && !loader">
          Aucun résultat
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="risksGravity" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
  <div class="modal-dialog risk" role="document">
    <div class="modal-content modal-risk">
      <div class="modal-risk-header">
        <h4 class="modal-title" *ngIf="risksGravity.length">Gravité : {{ cotationName }}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
        <div class="is-loading loader-modal-content p-5" *ngIf="loader"></div>
        <div class="table-responsive table-risks" *ngIf="risksGravity.length && !loader">
          <table class="table table-striped">
            <thead>
            <tr>
              <th>Description du risque</th>
              <th>Fréquence</th>
              <th>Gravité</th>
              <th>Maitrise</th>
              <th>Criticité</th>
              <th>Date de mise à jour</th>
              <th>Moyens de protection et de prévention existants</th>
              <th>Actions correctives à réaliser</th>
            </tr>
            </thead>
            <tbody>
            <tr class="dropdown" *ngFor="let risk of risksGravity">
              <td>{{ risk.description }}</td>
              <td>{{ JSON.parse(risk.frequency)['title'] }}</td>
              <td>{{ JSON.parse(risk.gravity)['title'] }}</td>
              <td>{{ JSON.parse(risk.master)['title'] }}</td>
              <td><span class="label" [ngClass]="risk.label">{{ risk.critic }}</span></td>
              <td>{{ risk['date_update'] | date : 'dd/MM/yyyy' }}</td>
              <td>{{ risk.current_protection }}</td>
              <td>{{ risk.todo_protection }}</td>
            </tr>
            </tbody>
          </table>
          <app-pagination (returnEvent)="dispatchPaginationAllRisksByGravities($event)"
                          [count_elements]="risksGravityCount"
                          [current_page]="risksGravityPage"
                          [elements_length]="risksGravity.length"
                          [limit]="risksGravityLimit"
                          [total_pages]="risksGravityPages"
                          ngDefaultControl>
          </app-pagination>
        </div>
        <div class="table-responsive table-risks" *ngIf="!risksGravity.length && !loader">
          Aucun résultat
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="risksFrequencies" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
  <div class="modal-dialog risk" role="document">
    <div class="modal-content modal-risk">
      <div class="modal-risk-header">
        <h4 class="modal-title" *ngIf="risksFrequencies.length">Fréquence : {{ cotationName }}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
        <div class="is-loading loader-modal-content p-5" *ngIf="loader"></div>
        <div class="table-responsive table-risks" *ngIf="risksFrequencies.length && !loader">
          <table class="table table-striped">
            <thead>
            <tr>
              <th>Description du risque</th>
              <th>Fréquence</th>
              <th>Gravité</th>
              <th>Maitrise</th>
              <th>Criticité</th>
              <th>Date de mise à jour</th>
              <th>Moyens de protection et de prévention existants</th>
              <th>Actions correctives à réaliser</th>
            </tr>
            </thead>
            <tbody>
            <tr class="dropdown" *ngFor="let risk of risksFrequencies">
              <td>{{ risk.description }}</td>
              <td>{{ JSON.parse(risk.frequency)['title'] }}</td>
              <td>{{ JSON.parse(risk.gravity)['title'] }}</td>
              <td>{{ JSON.parse(risk.master)['title'] }}</td>
              <td><span class="label" [ngClass]="risk.label">{{ risk.critic }}</span></td>
              <td>{{ risk['date_update'] | date : 'dd/MM/yyyy' }}</td>
              <td>{{ risk.current_protection }}</td>
              <td>{{ risk.todo_protection }}</td>
            </tr>
            </tbody>
          </table>
          <app-pagination (returnEvent)="dispatchPaginationAllRisksByFrequencies($event)"
                          [count_elements]="risksFrequenciesCount"
                          [current_page]="risksFrequenciesPage"
                          [elements_length]="risksFrequencies.length"
                          [limit]="risksFrequenciesLimit"
                          [total_pages]="risksFrequenciesPages"
                          ngDefaultControl>
          </app-pagination>
        </div>
        <div class="table-responsive table-risks" *ngIf="!risksFrequencies.length && !loader">
          Aucun résultat
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="risksMastery" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
  <div class="modal-dialog risk" role="document">
    <div class="modal-content modal-risk">
      <div class="modal-risk-header">
        <h4 class="modal-title" *ngIf="risksMastery.length">Maitrise : {{ cotationName }}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
        <div class="is-loading loader-modal-content p-5" *ngIf="loader"></div>
        <div class="table-responsive table-risks" *ngIf="risksMastery.length && !loader">
          <table class="table table-striped">
            <thead>
            <tr>
              <th>Description du risque</th>
              <th>Fréquence</th>
              <th>Gravité</th>
              <th>Maitrise</th>
              <th>Criticité</th>
              <th>Date de mise à jour</th>
              <th>Moyens de protection et de prévention existants</th>
              <th>Actions correctives à réaliser</th>
            </tr>
            </thead>
            <tbody>
            <tr class="dropdown" *ngFor="let risk of risksMastery">
              <td>{{ risk.description }}</td>
              <td>{{ JSON.parse(risk.frequency)['title'] }}</td>
              <td>{{ JSON.parse(risk.gravity)['title'] }}</td>
              <td>{{ JSON.parse(risk.master)['title'] }}</td>
              <td><span class="label" [ngClass]="risk.label">{{ risk.critic }}</span></td>
              <td>{{ risk['date_update'] | date : 'dd/MM/yyyy' }}</td>
              <td>{{ risk.current_protection }}</td>
              <td>{{ risk.todo_protection }}</td>
            </tr>
            </tbody>
          </table>
          <app-pagination (returnEvent)="dispatchPaginationAllRisksByMastery($event)"
                          [count_elements]="risksMasteryCount"
                          [current_page]="risksMasteryPage"
                          [elements_length]="risksMastery.length"
                          [limit]="risksMasteryLimit"
                          [total_pages]="risksMasteryPages"
                          ngDefaultControl>
          </app-pagination>
        </div>
        <div class="table-responsive table-risks" *ngIf="!risksMastery.length && !loader">
          Aucun résultat
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="risksCritics" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
  <div class="modal-dialog risk" role="document">
    <div class="modal-content modal-risk">
      <div class="modal-risk-header">
        <h4 class="modal-title" *ngIf="risksCriticFilter.length">Criticité : {{ criticName }}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
        <div class="is-loading loader-modal-content p-5" *ngIf="loader"></div>
        <div class="table-responsive table-risks" *ngIf="risksCriticFilter.length && !loader">
          <table class="table table-striped">
            <thead>
            <tr>
              <th>Description du risque</th>
              <th>Fréquence</th>
              <th>Gravité</th>
              <th>Maitrise</th>
              <th>Criticité</th>
              <th>Date de mise à jour</th>
              <th>Moyens de protection et de prévention existants</th>
              <th>Actions correctives à réaliser</th>
            </tr>
            </thead>
            <tbody>
            <tr class="dropdown" *ngFor="let risk of risksCriticFilter">
              <td>{{ risk.description }}</td>
              <td>{{ JSON.parse(risk.frequency)['title'] }}</td>
              <td>{{ JSON.parse(risk.gravity)['title'] }}</td>
              <td>{{ JSON.parse(risk.master)['title'] }}</td>
              <td><span class="label" [ngClass]="risk.label">{{ risk.critic }}</span></td>
              <td>{{ risk['date_update'] | date : 'dd/MM/yyyy' }}</td>
              <td>{{ risk.current_protection }}</td>
              <td>{{ risk.todo_protection }}</td>
            </tr>
            </tbody>
          </table>
          <app-pagination (returnEvent)="dispatchPaginationAllRisksByCritics($event)"
                          [count_elements]="risksCriticFilterCount"
                          [current_page]="risksCriticFilterPage"
                          [elements_length]="risksCriticFilter.length"
                          [limit]="risksCriticFilterLimit"
                          [total_pages]="risksCriticFilterPages"
                          ngDefaultControl>
          </app-pagination>
        </div>
        <div class="table-responsive table-risks" *ngIf="!risksCriticFilter.length && !loader">
          Aucun résultat
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="risksState" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
  <div class="modal-dialog risk" role="document">
    <div class="modal-content modal-risk">
      <div class="modal-risk-header">
        <h4 class="modal-title" *ngIf="actions.length">Status : {{ actionName }}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
        <div class="is-loading loader-modal-content p-5" *ngIf="loader"></div>
        <div class="table-responsive table-risks" *ngIf="actions.length && !loader">
          <table class="table table-striped">
            <thead>
            <tr>
              <th>Description du risque</th>
              <th>Fréquence</th>
              <th>Gravité</th>
              <th>Maitrise</th>
              <th>Criticité</th>
              <th>Date de mise à jour</th>
              <th>Moyens de protection et de prévention existants</th>
              <th>Actions correctives à réaliser</th>
            </tr>
            </thead>
            <tbody>
            <tr class="dropdown" *ngFor="let risk of actions">
              <td>{{ risk.description }}</td>
              <td>{{ JSON.parse(risk.frequency)['title'] }}</td>
              <td>{{ JSON.parse(risk.gravity)['title'] }}</td>
              <td>{{ JSON.parse(risk.master)['title'] }}</td>
              <td><span class="label" [ngClass]="risk.label">{{ risk.critic }}</span></td>
              <td>{{ risk['date_update'] | date : 'dd/MM/yyyy' }}</td>
              <td>{{ risk.current_protection }}</td>
              <td>{{ risk.todo_protection }}</td>
            </tr>
            </tbody>
          </table>
          <app-pagination (returnEvent)="dispatchPaginationAllRisksByState($event)"
                          [count_elements]="actionsCount"
                          [current_page]="actionsPage"
                          [elements_length]="actions.length"
                          [limit]="actionsLimit"
                          [total_pages]="actionsPages"
                          ngDefaultControl>
          </app-pagination>
        </div>
        <div class="table-responsive table-risks" *ngIf="!actions.length && !loader">
          Aucun résultat
        </div>
      </div>
    </div>
  </div>
</div>
