import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AfterViewInit, Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { HttpClient } from '@angular/common/http';
import { Subject, Subscription } from 'rxjs';
import { MapsAPILoader } from '@agm/core';

import { environment } from '../../../environments/environment';

import { AuthService } from '../../core/auth/auth.service';

import { CustomNotifierService } from '../../services/custom.notifier.service';
import { EnterprisesRequestService } from '../../services/enterprises.request.service';

declare function Dropzones(): any;
declare var $: any;

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit, AfterViewInit, OnDestroy {
  environment = environment;
  changeEnterprise: Subscription;
  canUpdate = false;

  requestInProgress = new Subject();
  requestCount = 0;

  user: any;

  userForm: FormGroup;
  id = new FormControl('');
  lastname = new FormControl('', Validators.required);
  firstname = new FormControl('', Validators.required);
  phone = new FormControl('');
  email = new FormControl({ value: '', disabled: true }, [Validators.required, Validators.email]);
  picture = new FormControl('');

  passwordForm: FormGroup;
  old_password = new FormControl('', [Validators.required]);
  password = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(?:(?=.*[a-z])(?:(?=.*[A-Z])(?=.*[\d\W])|(?=.*\W)(?=.*\d))|(?=.*\W)(?=.*[A-Z])(?=.*\d)).{8,}$/)
  ]);
  confirm_password = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(?:(?=.*[a-z])(?:(?=.*[A-Z])(?=.*[\d\W])|(?=.*\W)(?=.*\d))|(?=.*\W)(?=.*[A-Z])(?=.*\d)).{8,}$/)
  ]);

  enterpriseForm: FormGroup;
  enterprise_id = new FormControl('');
  name = new FormControl('', [Validators.required]);
  qualification = new FormControl(null, [Validators.required]);
  access_to_enterprise = new FormControl(true);
  address = new FormControl('', []);
  zipcode = new FormControl('', []);
  city = new FormControl('', []);
  latitude = new FormControl(null);
  longitude = new FormControl(null);
  siret = new FormControl('', [Validators.required]);
  enterprise_picture = new FormControl('');

  errors = {};

  loader = false;
  isUpdatingUser = false;
  isUpdatingPassword = false;
  isUpdatingPicture = false;
  isUpdatingEnterprise = false;

  showOldPassword = false;
  showPassword = false;
  showNewPassword = false;

  activeTab = 'account';

  results = [];
  searchTerm: string;
  autocompleteService;
  sessionToken: google.maps.places.AutocompleteSessionToken = null;

  constructor(
    private ngZone: NgZone,
    private elm: ElementRef,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private notifier: NotifierService,
    private mapsAPILoader: MapsAPILoader,
    private enterprisesService: EnterprisesRequestService
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loader = this.requestCount > 0;
    });

    this.userForm = this.formBuilder.group({
      id: this.id,
      lastname: this.lastname,
      firstname: this.firstname,
      phone: this.phone,
      email: this.email,
      picture: this.picture
    });

    this.passwordForm = this.formBuilder.group({
      old_password: this.old_password,
      password: this.password,
      confirm_password: this.confirm_password
    });

    this.enterpriseForm = this.formBuilder.group({
      id: this.enterprise_id,
      name: this.name,
      qualification: this.qualification,
      access_to_enterprise: this.access_to_enterprise,
      address: this.address,
      zipcode: this.zipcode,
      city: this.city,
      latitude: this.latitude,
      longitude: this.longitude,
      siret: this.siret,
      picture: this.enterprise_picture
    });

    this.changeEnterprise = this.authService.getEnterprise().subscribe(() => {
      this.getEnterprise();
    });

    const observableUser = this.authService.getUser();
    observableUser.subscribe(value => {
      this.userForm.patchValue(value);
    });

    if (this.authService.loaded) {
      this.getEnterprise();
    }

    this.getMe();
  }

  ngAfterViewInit() {
    Dropzones();
  }

  ngOnDestroy() {
    const dropZones = document.getElementsByClassName('dz-hidden-input') as HTMLCollection;
    for (let i = 0; i < dropZones.length; i++) {
      dropZones[i].remove();
    }
  }

  toggle(type) {
    if (type === 'old_password') {
      this.showOldPassword = !this.showOldPassword;
    } else if (type === 'password') {
      this.showPassword = !this.showPassword;
    } else if (type === 'new_password') {
      this.showNewPassword = !this.showNewPassword;
    }
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  getMe() {
    this.requestInProgress.next(true);

    this.http.get(environment.API_PATH + 'user/me')
      .subscribe(response => {
        if (response['success']) {
          this.user = response['user'];
          this.userForm.patchValue(this.user);
          this.saveUserToLocalstorage();
        } else {
          this.errors = response['errors'];
          CustomNotifierService.getErrorsForm(this.errors, this.userForm, this.notifier);
        }
        this.requestInProgress.next(false);
      });
  }

  updateUser() {
    this.errors = {};
    this.isUpdatingUser = true;

    const user = { ...this.userForm.value, email: this.email.value };

    this.http.put(environment.API_PATH + 'user/me/' + this.id.value, { user: user })
      .subscribe(response => {
        if (response['success']) {
          user['picture'] = this.user.picture;
          this.saveUserToLocalstorage();
          CustomNotifierService.getSuccessUpdate(this.notifier);
        } else {
          this.errors = response['errors'];
          CustomNotifierService.getErrorsForm(this.errors, this.userForm, this.notifier);
        }
        this.isUpdatingUser = false;
      });
  }

  chooseFile() {
    const element: HTMLElement = document.getElementById('inputFile') as HTMLElement;
    element.click();
  }

  onFileChange(event) {
    this.picture.setValue('');

    const reader = new FileReader();

    if (event.target && event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.picture.setValue(reader.result);
      };
    } else {
      this.picture.setValue(event.file);
    }
    const element: HTMLInputElement = document.getElementById('inputFile') as HTMLInputElement;
    element.value = '';
  }

  checkPicture() {
    if (this.picture.value.indexOf(';base64,') !== -1) {
      return this.picture.value;
    } else {
      return environment.SERVER_HOST + 'img/users/' + this.picture.value;
    }
  }

  removeFile() {
    this.picture.setValue(null);

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  updatePicture() {
    this.isUpdatingPicture = true;

    this.http.put(environment.API_PATH + 'user/me/' + this.id.value + '/picture', { user: this.userForm.value })
      .subscribe(response => {
        if (response['success']) {
          this.user.picture = response['picture'];
          this.saveUserToLocalstorage();
          this.authService.changeUser(this.user);
          CustomNotifierService.getSuccessUpdate(this.notifier);
        } else {
          CustomNotifierService.getErrors(response['errors'], this.notifier);
        }
        this.isUpdatingPicture = false;
      });
  }

  updatePassword() {
    this.errors = {};
    this.isUpdatingPassword = true;

    const user = { ...this.userForm.value, email: this.email.value, ...this.passwordForm.value };

    this.http.put(environment.API_PATH + 'user/me/' + this.id.value, { user: user })
      .subscribe(response => {
        if (response['success']) {
          CustomNotifierService.getSuccessUpdate(this.notifier);
        } else {
          this.errors = response['errors'];
          CustomNotifierService.getErrorsForm(this.errors, this.passwordForm, this.notifier);
        }
        this.isUpdatingPassword = false;
      });
  }

  saveUserToLocalstorage() {
    const connectedAs = JSON.parse(localStorage.getItem('connectedAs'));
    if (connectedAs) {
      localStorage.setItem('connectedAsUser', JSON.stringify(this.user));
    } else {
      localStorage.setItem('user', JSON.stringify(this.user));
    }
  }

  getEnterprise() {
    this.requestInProgress.next(true);

    this.enterprisesService.getMine().subscribe(response => {
      if (response['success']) {
        this.canUpdate = response['canUpdate'];
        this.enterpriseForm.patchValue(response['enterprise']);
        if (!this.canUpdate) {
          this.enterpriseForm.disable();
        }

        this.mapsAPILoader.load().then(() => {
          this.sessionToken = new google.maps.places.AutocompleteSessionToken();
          this.autocompleteService = new google.maps.places.AutocompleteService();
        });
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.enterpriseForm, this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }
  chooseFileEnterprise() {
    const element: HTMLElement = document.getElementById('inputFileEnterprise') as HTMLElement;
    element.click();
  }
  onFileChangeEnterprise(event) {
    this.enterprise_picture.setValue('');

    const reader = new FileReader();

    if (event.target && event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.enterprise_picture.setValue(reader.result);
      };
    } else {
      this.enterprise_picture.setValue(event.file);
    }
    const element: HTMLInputElement = document.getElementById('inputFileEnterprise') as HTMLInputElement;
    element.value = '';
  }
  checkPictureEnterprise() {
    if (this.enterprise_picture.value.indexOf(';base64,') !== -1) {
      return this.enterprise_picture.value;
    } else {
      return environment.SERVER_HOST + 'img/enterprises/' + this.enterprise_picture.value;
    }
  }
  removeFileEnterprise() {
    this.enterprise_picture.setValue(null);

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }
  updateEnterprise() {
    this.isUpdatingEnterprise = true;

    this.enterprisesService.updateMine(this.enterpriseForm.value).subscribe(response => {
      if (response['success']) {
        CustomNotifierService.getSuccessUpdate(this.notifier);
        this.authService.enterprise.next(response['enterprise']);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.enterpriseForm, this.notifier);
      }
      this.isUpdatingEnterprise = false;
    });
  }

  onSearch(term: string) {
    this.searchTerm = term;
    if (this.searchTerm === '') { return; }
    this.autocompleteService.getPlacePredictions({
      input: term,
      sessionToken: this.sessionToken,
      componentRestrictions: { country: 'fr' }
    }, data => {
      if (data) {
        this.results = data;
      } else {
        this.results = [];
      }
    });
  }
  selectPlace(data) {
    const request = {
      placeId: data.place_id,
      sessionToken: this.sessionToken
    };
    const service = new google.maps.places.PlacesService($('#service-helper').get(0));
    service.getDetails(request, (place, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        let route = '';
        let streetNumber = '';

        for (let i = 0; i < place.address_components.length; i++) {
          const component = place.address_components[i];
          const addressType = component.types[0];

          switch (addressType) {
            case 'street_number':
              streetNumber = component.long_name;
              break;
            case 'route':
              route = component.short_name;
              break;
            case 'locality':
              this.city.setValue(component.long_name);
              break;
            case 'postal_code':
              this.zipcode.setValue(component.long_name);
              break;
          }
        }

        if (route) {
          this.address.setValue(streetNumber && route ? streetNumber + ' ' + route : route);
        }

        this.latitude.setValue(place.geometry.location.lat());
        this.longitude.setValue(place.geometry.location.lng());

      }
    });
    this.results = [];
  }

  changeTabs(tabValue: string) {
    this.activeTab = tabValue;
  }
}
