import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UtilsModule } from '../../../core/utils/utils.module';
import { NgSelectModule } from '@ng-select/ng-select';

import { DashboardObservationsComponent } from './dashboard-observations.component';
import { PaginationModule } from '../../../core/pagination/pagination.module';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        NgSelectModule,
        UtilsModule,
        PaginationModule,
    ],
  declarations: [
    DashboardObservationsComponent
  ]
})

export class DashboardObservationsModule {}
