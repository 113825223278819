<div class="main-content">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col">
        <div class="header mb-0">
          <div class="header-body no-border">
            <div class="row align-items-center">
              <div class="col">
                <h1 class="header-title">
                 Documents
                </h1>

                <small class="form-text text-muted">
                  Liste des documents du prestataire
                </small>

                <app-breadcrumbs [currentPage]="pageName" [names]="previousPageName" [routes]="previousPageRoute"></app-breadcrumbs>
              </div>

              <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0" *ngIf="canCreate">
                <a class="btn btn-primary d-block d-md-inline-block" [routerLink]="[currentRoute + 'create']">
                  Ajouter un document
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col">
            <app-searchbar [(ngModel)]="search" (keyup)="searchData()" ngDefaultControl></app-searchbar>
          </div>
        </div>

        <div class="is-loading loader-content" *ngIf="loader"></div>

        <div class="tab-content" *ngIf="!loader && contracts.length">
          <div class="tab-pane fade active show" id="tabPaneOne" role="tabpanel">
            <div class="row listAlias">
              <div class="col-12 d-flex flex-column">
                <div class="card flex-fill">
                  <div class="card-body">
                    <div class="table-responsive">
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th class="href" (click)="filter('ProviderContracts.name')">
                              <a class="text-muted">Nom du document</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'ProviderContracts.name' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'ProviderContracts.name' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'ProviderContracts.name'"></span>
                            </th>
                            <th class="href" (click)="filter('ProviderContracts.start_date')">
                              <a class="text-muted">Date de début</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'ProviderContracts.start_date' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'ProviderContracts.start_date' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'ProviderContracts.start_date'"></span>
                            </th>
                            <th class="href" (click)="filter('ProviderContracts.end_date')">
                              <a class="text-muted">Date de fin</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'ProviderContracts.end_date' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'ProviderContracts.end_date' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'ProviderContracts.end_date'"></span>
                            </th>
                            <th>Document</th>
                            <th>Rappel</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="dropdown" *ngFor="let contract of contracts">
                            <td>
                              <a class="href" [routerLink]="[currentRoute + contract.id]">
                                {{ contract.name }}
                              </a>
                            </td>
                            <td>{{ contract.start_date | date: 'dd/MM/yyyy' }}</td>
                            <td>{{ contract.end_date | date: 'dd/MM/yyyy' }}</td>
                            <td>
                              <div class="btn-group" *ngIf="contract.extension">
                                <a class="btn btn-sm btn-white" id="{{ contract.id }}" (click)="downloadFile(contract.id, contract.name + '.' + contract.extension)">
                                  Téléchargement
                                </a>
                              </div>
                            </td>
                            <td>{{ contract.months }} mois</td>
                            <td class="d-flex flex-row-reverse">
                              <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fe fe-more-vertical"></i>
                              </a>

                              <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item href" (click)="downloadFile(contract.id, contract.name + '.' + contract.extension)" *ngIf="contract.extension">
                                  Télécharger le document
                                </a>

                                <a class="dropdown-item href" [routerLink]="[currentRoute + contract.id]">
                                  {{ canUpdate ? 'Éditer le document' : 'Consulter le document' }}
                                </a>

                                <a class="dropdown-item href" (click)="modalConfirmContract.open(contract.id)">
                                  Supprimer le document
                                </a>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <app-pagination (returnEvent)="dispatchPagination($event)"
                                    [count_elements]="count"
                                    [current_page]="page"
                                    [elements_length]="contracts.length"
                                    [limit]="limit"
                                    [total_pages]="pages"
                                    ngDefaultControl>
                    </app-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="loader-content" *ngIf="!loader && !contracts.length">
          <p>Aucun résultat</p>
        </div>
      </div>
    </div>
  </div>
</div>

<app-confirm-modal [type]="'delete_contract'" #confirmContract>{{ bindModalConfirmContract(confirmContract) }}</app-confirm-modal>

<router-outlet></router-outlet>
