<div class="main-content">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col">
        <div class="header mb-0">
          <div class="header-body no-border">
            <div class="row align-items-center">
              <div class="col">
                <h1 class="header-title">
                  {{ viewTrainings ? 'Documents de la date de formation' : viewDateTrainingsEmployees ? 'Documents du personnel' : 'Documents' }}
                </h1>
                <!-- SUBTITLE -->
                <small class="form-text text-muted">
                  {{ viewTrainings ? 'Liste des documents communs à tout le personnel de la date de formation' : viewDateTrainingsEmployees ? 'Liste des documents spécifiques à chaque personnel de la date de formation' : 'Liste des documents'}}
                </small>
                <app-breadcrumbs [currentPage]="pageName" [names]="previousPageName"
                                 [routes]="previousPageRoute"></app-breadcrumbs>
              </div>

              <!-- ADD FILE TRAININGS -->
              <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0" *ngIf="canCreate && viewTrainings">
                <a class="btn btn-primary d-block d-md-inline-block" [routerLink]="[updateRoute + 'create']">
                  Ajouter un fichier
                </a>
              </div>
            </div>
          </div>
        </div>

        <!-- SEARCH BAR -->
        <div class="row mb-4">
          <div class="col">
            <app-searchbar [(ngModel)]="search" (keyup)="searchData()" [placeholder_text]="viewDateTrainingsEmployees ? 'Rechercher par nom, prénom, désignation ou email' : 'Rechercher par nom de fichier'" ngDefaultControl></app-searchbar>
          </div>
        </div>

        <div class="is-loading loader-content" *ngIf="loader"></div>
        <!-- TABLE -->
        <div class="tab-content" *ngIf="!loader && (files_date_training.length || employees_files_date_training.length)">

          <div class="tab-content" role="tabpanel">
            <div class="card flex-fill">
              <div class="card-body">
                <div class="table-responsive">
                  <!-- VIEW TRAININGS -->
                  <ng-container *ngIf="viewTrainings">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th class="href" (click)="filter('name')">
                            <a class="text-muted">Nom</a>
                            <span class="fe fe-arrow-down" *ngIf="sort === 'name' && order === 'asc'"></span>
                            <span class="fe fe-arrow-up" *ngIf="sort === 'name' && order === 'desc'"></span>
                            <span class="fe fe-arrow-right" *ngIf="sort !== 'name'"></span>
                          </th>
                          <th class="href" (click)="filter('created')">
                            <a class="text-muted">Date d'ajout</a>
                            <span class="fe fe-arrow-down" *ngIf="sort === 'created' && order === 'asc'"></span>
                            <span class="fe fe-arrow-up" *ngIf="sort === 'created' && order === 'desc'"></span>
                            <span class="fe fe-arrow-right" *ngIf="sort !== 'created'"></span>
                          </th>
                          <!-- Submenu button -->
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let file of files_date_training">
                          <td>
                            <a class="href" *ngIf="!['mp4', 'pdf', 'png', 'jpeg', 'jpg'].includes(file.extension)" (click)="downloadFile(file.id, file.name + '.' + file.extension)">
                              {{ file.name + '.' + file.extension }}
                            </a>
                            <a class="href" [routerLink]="" *ngIf="['pdf', 'png', 'jpeg', 'jpg'].includes(file.extension)" (click)="showPdf(file.file_url)">
                              {{ file.name + '.' + file.extension }}
                            </a>
                          </td>
                          <td>{{ file.created | date:'dd/MM/yyyy' }}</td>
                          <td class="d-flex flex-row-reverse">
                            <div class="dropdown">
                              <a class="dropdown-ellipses dropdown-toggle href" role="button" [attr.aria-label]="'Sous menus pour le fichier ' + file.name" data-toggle="dropdown"
                                 aria-haspopup="true" aria-expanded="false">
                                <i class="fe fe-more-vertical"></i>
                              </a>

                              <!-- ACTIONS SUBMENUS -->
                              <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item href" [routerLink]="[file.id]">
                                  {{ canUpdate ? 'Éditer le fichier' : 'Consulter le fichier' }}
                                </a>
                                <a class="dropdown-item href" [routerLink]="" *ngIf="['pdf'].includes(file.extension)" (click)="showPdf(file.file_url)">
                                  Visionner le PDF
                                </a>
                                <a class="dropdown-item href" [routerLink]="" *ngIf="['png', 'jpeg', 'jpg'].includes(file.extension)" (click)="showPdf(file.file_url)">
                                  Visionner l'image
                                </a>
                                <a class="dropdown-item href" (click)="downloadFile(file.id, file.name + '.' + file.extension)">
                                  Télécharger
                                </a>
                                <a class="dropdown-item href" (click)="modalConfirmFile.open(file.id)" *ngIf="canDelete">
                                  Supprimer
                                </a>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </ng-container>
                  <!-- VIEW TRAININGS EMPLOYEES -->
                  <ng-container *ngIf="viewDateTrainingsEmployees">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th class="href" (click)="filter('EmployeeTrainings.firstname')">
                            <a class="text-muted">Prénom</a>
                            <span class="fe fe-arrow-down" *ngIf="sort === 'EmployeeTrainings.firstname' && order === 'asc'"></span>
                            <span class="fe fe-arrow-up" *ngIf="sort === 'EmployeeTrainings.firstname' && order === 'desc'"></span>
                            <span class="fe fe-arrow-right" *ngIf="sort !== 'EmployeeTrainings.firstname'"></span>
                          </th>
                          <th class="href" (click)="filter('EmployeeTrainings.lastname')">
                            <a class="text-muted">Nom</a>
                            <span class="fe fe-arrow-down" *ngIf="sort === 'EmployeeTrainings.lastname' && order === 'asc'"></span>
                            <span class="fe fe-arrow-up" *ngIf="sort === 'EmployeeTrainings.lastname' && order === 'desc'"></span>
                            <span class="fe fe-arrow-right" *ngIf="sort !== 'EmployeeTrainings.lastname'"></span>
                          </th>
                          <th>Document(s)</th>
                          <th class="href" (click)="filter('DateTrainingEmployees.is_present')">
                            <a class="text-muted">Présence</a>
                            <span class="fe fe-arrow-down"
                                  *ngIf="sort === 'DateTrainingEmployees.is_present' && order === 'asc'"></span>
                            <span class="fe fe-arrow-up"
                                  *ngIf="sort === 'DateTrainingEmployees.is_present' && order === 'desc'"></span>
                            <span class="fe fe-arrow-right" *ngIf="sort !== 'DateTrainingEmployees.is_present'"></span>
                          </th>
                          <!-- SUBMENU -->
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="dropdown" *ngFor="let employee of employees_files_date_training; let i = index">
                          <td class="text-uppercase">{{ employee.employee_training.firstname }}</td>
                          <td class="text-uppercase">{{ employee.employee_training.lastname }}</td>
                          <td>
                            <ul>
                              <li *ngFor="let file of employee.training_employee_files">
                                <a class="href" [routerLink]="[employee.id + '/' + file.id]">
                                  {{ file.name + '.' + file.extension }}
                                </a>
                              </li>
                            </ul>
                          </td>
                          <td>
                            <div class="custom-checkbox-toggle" [attr.aria-label]="employee.is_present ? 'L\'employé est marqué comme présent' : 'L\'employé est marqué comme non présent'">
                              <input type="checkbox" class="custom-control-input"
                                     [id]="'userIsPresent' + employee.employee_training.id"
                                     [name]="'userIsPresent' + employee.employee_training.id"
                                     [attr.aria-label]="employee.is_present ? 'L\'employé n\'est pas présent' : 'L\'employé est présent'"
                                     [ngClass]="{ 'backgroundColor': employee.is_present }" [checked]="employee.is_present"
                                     (click)="updateEmployeePresent(i, employee.id, employee.is_present, 'userIsPresent' + employee.employee_training.id)">
                              <label class="custom-control-label" [ngClass]="{ 'backgroundColor': employee.is_present }"
                                     [htmlFor]="'userIsPresent' + employee.employee_training.id"></label>
                            </div>
                          </td>
                          <td class="d-flex flex-row-reverse">
                            <a class="dropdown-ellipses dropdown-toggle href" role="button"
                               [attr.aria-label]="'Sous menus pour l\'employé(e) ' + employee.employee_training.firstname + employee.employee_training.lastname"
                               data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <i class="fe fe-more-vertical"></i>
                            </a>

                            <div class="dropdown-menu dropdown-menu-right">
                              <a class="dropdown-item href" [routerLink]="['create/' + employee.id]">
                                Ajouter un document
                              </a>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </ng-container>
                </div>
                <app-pagination (returnEvent)="dispatchPagination($event)"
                                [count_elements]="count"
                                [current_page]="page"
                                [elements_length]="employees_files_date_training.length"
                                [limit]="limit"
                                [total_pages]="pages"
                                ngDefaultControl>
                </app-pagination>
              </div>
            </div>
          </div>
        </div>

        <div class="loader-content" *ngIf="!loader && !employees_files_date_training.length && !files_date_training.length">
          <p>Aucun résultat</p>
        </div>
      </div>
    </div>
  </div>
</div>

<app-confirm-modal [type]="'delete_file'" #confirmFile>{{ bindModalConfirmFile(confirmFile) }}</app-confirm-modal>
<app-pdf-viewer #pdf>{{ bindModalPdf(pdf) }}</app-pdf-viewer>

<router-outlet></router-outlet>
