import { AfterViewInit, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Subject } from 'rxjs';

import { AllEmployeesComponent } from '../all-employees/all-employees.component';
import { AllTrainingsFilesComponent } from '../all-trainings-files/all-trainings-files.component';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { DocumentsRequestService } from '../../../services/documents.request.service';
import { TrainingsRequestService } from '../../../services/trainings.request.service';
import { EstablishmentsRequestService } from '../../../services/establishments.request.service';
import { RegistriesRequestService } from '../../../services/registries.request.service';

import EmployeeTraining from '../../../../shared/interfaces/employee_training';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})

export class UploadFileComponent implements OnInit, AfterViewInit, OnDestroy {
  canCreate = false;
  canUpdate = false;
  viewFiles = false;
  viewSheets = false;

  allEmployees: any;
  allTrainingFiles: any;

  employees: EmployeeTraining[];
  establishments_dropdown: any = [];
  registries_dropdown: any = [];
  establishmentsSelected: any = [];
  registriesSelected: any = [];

  requestInProgress = new Subject();
  requestCount = 0;

  fileForm: FormGroup;
  id = new FormControl('');
  name = new FormControl('', Validators.required);
  extension = new FormControl('');
  establishment_id = new FormControl('');
  registry_id = new FormControl('');
  date_training_id = new FormControl('');
  dfu_id = new FormControl('');
  file_base64 = new FormControl('');
  sheetOverwriteData = new FormControl(false);
  registries = new FormControl([]);
  establishments = new FormControl([]);

  fileName: string;

  errors = {};

  isNew = true;
  loader = false;
  loaderContent = false;

  modalFile = null;
  modalConfirm = null;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private injector: Injector,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private trainingsService: TrainingsRequestService,
    private establishmentsService: EstablishmentsRequestService,
    private registriesService: RegistriesRequestService,
    private documentsService: DocumentsRequestService
  ) {
    if (this.activatedRoute.snapshot.url[0].path === 'sheet') {
      this.viewSheets = true;
      this.allEmployees = <AllEmployeesComponent>this.injector.get(AllEmployeesComponent);
    } else {
      this.viewFiles = true;
      this.allTrainingFiles = <AllTrainingsFilesComponent>this.injector.get(AllTrainingsFilesComponent);
    }
  }

  ngOnInit() {
    const snapshot = this.activatedRoute.snapshot;
    const parent = snapshot.parent.params;

    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loaderContent = this.requestCount > 0;
    });

    this.fileForm = this.formBuilder.group({
      id: this.id,
      name: this.name,
      extension: this.extension,
      establishment_id: this.establishment_id,
      registry_id: this.registry_id,
      date_training_id: this.date_training_id,
      dfu_id: this.dfu_id,
      file_base64: this.file_base64,
      sheetOverwriteData: this.sheetOverwriteData,
      registries: this.registries,
      establishments: this.establishments
    });

    this.fileForm.patchValue({ establishment_id: parent.establishment_id ? +parent.establishment_id : '' });
    this.fileForm.patchValue({ registry_id: parent.registry_id ? +parent.registry_id : '' });
    this.fileForm.patchValue({ date_training_id: parent.date_training_id ? +parent.date_training_id : '' });
    this.fileForm.patchValue({ dfu_id: snapshot.params.dte_id ? +snapshot.params.dte_id : '' });

    if (this.viewSheets) {
      this.getEmployees();
      this.getRegistries();
      this.getEstablishments();
      this.name.setValidators([]); // Remove validators for the name
      this.name.updateValueAndValidity();
      this.establishmentsSelected.push(+parent.establishment_id);
      this.registriesSelected.push(+parent.registry_id);
    }

    if (snapshot.params.file_id) {
      this.isNew = false;
      const dte_id = snapshot.params.dte_id === undefined ? 'null' : snapshot.params.dte_id;
      this.getTrainingFile(snapshot.params.file_id, parent.establishment_id, parent.date_training_id, dte_id);
    } else { // If is new
      this.file_base64.setValidators([Validators.required]);
      this.file_base64.updateValueAndValidity();
    }
  }

  ngAfterViewInit() {
    this.modalFile.open();
    const parent = this.activatedRoute.snapshot.parent.params;

    this.modalConfirm.eventOnClose.subscribe(event => {
      if (event) {
        let request;
        if (this.modalConfirm.type === 'delete_employee') {
          request = this.trainingsService.deleteEmployee(+event, +parent.establishment_id, +parent.registry_id);
        } else { // Delete a file
          request = this.trainingsService.deleteFile(+event, +parent.date_training_id, this.activatedRoute.snapshot.params.dte_id);
        }
        request.subscribe(response => {
          if (response['success']) {
            this.modalFile.close();
            CustomNotifierService.getSuccessDelete(this.notifier);
          } else if (response['errors']) {
            CustomNotifierService.getErrors(response['errors'], this.notifier);
          }
        });
      }
    });
  }

  ngOnDestroy() {
    this.modalFile.close();
  }

  /**
   * Retrieves a file data for a user from a training date or retrieves a specific file from a training date (common to all employees)
   *
   * @param file_id The file to search for
   * @param establishment_id The establishment linked
   * @param date_training_id The date training linked
   * @param dte_id The date training employee linked (only available to get employee file)
   */
  getTrainingFile(file_id: any, establishment_id: any, date_training_id: any, dte_id: any) {
    this.requestInProgress.next(true);
    this.trainingsService.getFile(file_id, establishment_id, date_training_id, dte_id).subscribe(response => {
      if (response['success']) {
        this.canUpdate = response['canUpdate'];
        this.fileForm.patchValue(response['file']);
        if (!this.canUpdate) {
          this.fileForm.disable();
        }
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  chooseFile() {
    const element: HTMLElement = this.viewSheets ?
      document.getElementById('inputSheet') as HTMLElement :
      document.getElementById('inputFile') as HTMLElement;
    element.click();
  }

  onFileChange(event) {
    this.file_base64.setValue('');
    const reader = new FileReader();

    if (event.target && event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.file_base64.setValue(reader.result);
        this.fileName = file.name;

        const split = this.fileName.split('.');
        if (split.length) {
          this.extension.setValue(split[split.length - 1]);
        }
      };
    } else { // Drag and drop
      this.file_base64.setValue(event.file);
      this.fileName = event.name;

      const split = this.fileName.split('.');
      if (split.length) {
        this.extension.setValue(split[split.length - 1]);
      }
    }
    const element: HTMLInputElement = this.viewSheets ?
      document.getElementById('inputSheet') as HTMLInputElement :
      document.getElementById('inputFile') as HTMLInputElement;
    element.value = '';
  }

  createFile() {
    const parent = this.activatedRoute.snapshot.parent.params;
    this.errors = {};
    this.loader = true;
    const dte_id = this.activatedRoute.snapshot.params.dte_id ? +this.activatedRoute.snapshot.params.dte_id : '';

    this.fileForm.patchValue({ registries: this.registriesSelected });
    this.fileForm.patchValue({ establishments: this.establishmentsSelected });

    const request = this.viewSheets ?
      this.trainingsService.createSheetEmployees(+parent.registry_id, +parent.establishment_id, this.sheetOverwriteData.value,
        this.fileForm.value) :
      this.trainingsService.createFile(+parent.date_training_id, dte_id, this.fileForm.value);
    request.subscribe(response => {
      if (response['success']) {
        this.modalFile.close();
        this.closeModal();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loader = false;
    });
  }

  updateFile() {
    this.errors = {};
    this.loader = true;
    const parent = this.activatedRoute.snapshot.parent.params;
    const dte_id = this.activatedRoute.snapshot.params.dte_id ? +this.activatedRoute.snapshot.params.dte_id : '';

    this.trainingsService.updateFile(this.id.value, parent.date_training_id, dte_id, this.fileForm.value).subscribe(response => {
      if (response['success']) {
        this.modalFile.close();
        CustomNotifierService.getSuccessUpdate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loader = false;
    });
  }

  downloadFile(file_id: any, file_name: any) {
    const parent = this.activatedRoute.snapshot.parent.params;
    const dte_id = this.activatedRoute.snapshot.params.dte_id ? +this.activatedRoute.snapshot.params.dte_id : '';

    this.trainingsService.downloadFile(+file_id, +parent.date_training_id, dte_id).subscribe((response: BufferSource) => {
      try {
        const decoder = new TextDecoder('utf-8');
        const domString = decoder.decode(response);
        const json = JSON.parse(domString);
        CustomNotifierService.getErrors(json['errors'], this.notifier);
      } catch (e) {
        const blob = new Blob([response]);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = file_name;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      }
    });
  }

  /**
   * Download a spreadsheet template (csv type) to indicate the fields needed for creation.
   *
   * @param file_name File name to download
   */
  downloadTemplate(file_name: string = 'template.csv') {
    this.requestInProgress.next(false);
    this.documentsService.downloadTemplate(false).subscribe((response: BufferSource) => {
      try {
        const decoder = new TextDecoder('utf-8');
        const domString = decoder.decode(response);
        const json = JSON.parse(domString);
        CustomNotifierService.getErrors(json['errors'], this.notifier);
      } catch (e) {
        const blob = new Blob([response]);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = file_name;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      }
      this.requestInProgress.next(false);
    });
  }

  removeFile() {
    this.file_base64.setValue(null);
    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  getEmployees() {
    this.requestInProgress.next(true);
    const parent = this.activatedRoute.snapshot.parent.params;
    this.trainingsService.getEmployees('', '', '', '', '', +parent.establishment_id, +parent.registry_id).subscribe(response => {
      if (response['success']) {
        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];
        this.employees = response['results']['employees'];
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  getRegistries() {
    this.requestInProgress.next(true);
    const parent = this.activatedRoute.snapshot.parent.params;
    this.registriesService.getRegistriesEstablishment('', '', '', '', '', parent.establishment_id).subscribe(response => {
      if (response['success']) {
        this.registries_dropdown = [];
        response['registries'].forEach(item => {
          item.group = true;
          this.registries_dropdown.push(item);
        });
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  getEstablishments() {
    this.requestInProgress.next(true);
    this.establishmentsService.getMyRegistry('', '', '', '', '', this.registry_id.value).subscribe(response => {
      if (response['success']) {
        this.establishments_dropdown = [];
        response['establishments'].forEach(item => {
          item.group = true;
          this.establishments_dropdown.push(item);
        });
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  bindModal(modal) {
    this.modalFile = modal;
  }

  bindModalConfirm(modal) {
    this.modalConfirm = modal;
  }

  closeModal() {
    this.viewSheets ? this.allEmployees.refreshData() : this.allTrainingFiles.refreshData();
    this.router.navigate([this.activatedRoute.snapshot.parent.url.join('/')]).then(() => {
    });
  }
}
