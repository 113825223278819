import { AfterViewInit, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Subject } from 'rxjs';

import { AllReportsComponent } from '../all-reports/all-reports.component';
import { DashboardRegistryComponent } from '../../registries/dashboard-registry/dashboard-registry.component';
import { CalendarComponent } from '../../../core/calendar/calendar.component';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { ReportsRequestService } from '../../../services/reports.request.service';
import { UsersRequestService } from '../../../services/users.request.service';
import { EnterprisesRequestService } from '../../../services/enterprises.request.service';
import { CheckpointsRequestService } from '../../../services/checkpoints.request.service';
import { EstablishmentsRequestService } from '../../../services/establishments.request.service';

import BatiFile from '../../../../shared/interfaces/bati_file';
import Checkpoint from '../../../../shared/interfaces/checkpoint';

declare function Flatpickr(): any;
declare function Dropzones(): any;

@Component({
  selector: 'app-update-report',
  templateUrl: './update-report.component.html',
  styleUrls: ['./update-report.component.scss']
})
export class UpdateReportComponent implements OnInit, AfterViewInit, OnDestroy {
  canCreate = false;
  canUpdate = false;

  allReports: any;
  dashboardRegistry: any;
  calendar: any;

  reportForm: FormGroup;
  id = new FormControl('');
  user_id = new FormControl('');
  enterprise_id = new FormControl('');
  establishment_checkpoint_id = new FormControl('');
  visit_date = new FormControl('', Validators.required);
  report_date = new FormControl(null);
  observations = new FormControl(null);
  corrector_user_id = new FormControl('');
  corrector_enterprise_id = new FormControl('');
  deadline = new FormControl(null);
  emergencies = new FormControl(null);
  financial_estimation = new FormControl(0, Validators.min(0));
  comment_report = new FormControl(null);
  type_visit = new FormControl(null);
  scoring = new FormControl(null);
  worksheet = new FormControl([]);

  errors = {};

  isNew = true;
  loader = false;
  loaderContent = false;

  requestInProgress = new Subject();
  requestCount = 0;

  modalReport = null;
  enterprises: any = [];
  user: string;
  correctors: any = [];
  corrector: string;
  files: BatiFile[] = [];
  checkpoint: Checkpoint = null;

  oldObservations: string = null;
  countOldObservations = 0;

  establishments: any = [];
  establishmentsSelected: any = [];

  monoOffer = false;
  discoverOffer = false;
  connectedAs = false;

  constructor(
    private router: Router,
    private injector: Injector,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private reportsService: ReportsRequestService,
    private enterprisesService: EnterprisesRequestService,
    private checkpointsService: CheckpointsRequestService,
    private establishmentsService: EstablishmentsRequestService,
    private usersService: UsersRequestService
  ) {
    if (this.activatedRoute.snapshot.parent.params.checkpoint_id) {
      this.allReports = <AllReportsComponent>this.injector.get(AllReportsComponent);
    } else {
      if (this.activatedRoute.snapshot.parent.params.registry_id) {
        this.dashboardRegistry = <DashboardRegistryComponent>this.injector.get(DashboardRegistryComponent);
      } else {
        this.calendar = <CalendarComponent>this.injector.get(CalendarComponent);
      }
    }
  }

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loaderContent = this.requestCount > 0;

      if (!this.loaderContent && this.correctors && this.correctors.length) {
        const arrayToSplice = [];
        this.correctors.forEach((enterprise, index) => {
          const found = this.correctors.find((search_e, search_i) => {
            if (index !== search_i) {
              return search_e.id === enterprise.id;
            }
            return false;
          });
          if (found) {
            arrayToSplice.unshift(found);
          }
        });
        if (arrayToSplice.length) {
          arrayToSplice.forEach(index => {
            this.correctors.splice(index, 1);
          });
        }
      }
    });

    this.reportForm = this.formBuilder.group({
      id: this.id,
      user_id: this.user_id,
      enterprise_id: this.enterprise_id,
      establishment_checkpoint_id: this.establishment_checkpoint_id,
      visit_date: this.visit_date,
      report_date: this.report_date,
      observations: this.observations,
      corrector_user_id: this.corrector_user_id,
      corrector_enterprise_id: this.corrector_enterprise_id,
      deadline: this.deadline,
      emergencies: this.emergencies,
      financial_estimation: this.financial_estimation,
      comment_report: this.comment_report,
      type_visit: this.type_visit,
      scoring: this.scoring,
      worksheet: this.worksheet
    });

    if (this.activatedRoute.snapshot.params.report_id) {
      this.isNew = false;
      this.getReport();
      this.getCheckpoint();
    } else {
      Flatpickr();
      this.getCheckpoint();
      this.getEstablishments();
    }

    this.connectedAs = JSON.parse(localStorage.getItem('connectedAs'));
    if (this.connectedAs) {
      this.monoOffer = JSON.parse(localStorage.getItem('connectedAsIsMonoOffer'));
      this.discoverOffer = JSON.parse(localStorage.getItem('connectedAsIsDiscoverOffer'));
    } else {
      this.monoOffer = JSON.parse(localStorage.getItem('isMonoOffer'));
      this.discoverOffer = JSON.parse(localStorage.getItem('isDiscoverOffer'));
    }
    if (!this.monoOffer && !this.discoverOffer) {
      this.getMyUsers();
    }
    this.getEnterprises();
  }

  ngAfterViewInit() {
    Dropzones();
    this.modalReport.open();
  }

  ngOnDestroy() {
    const flatPicker = document.getElementsByClassName('flatpickr-calendar') as HTMLCollection;
    for (let i = flatPicker.length; i >= 0; i--) {
      if (flatPicker[i]) {
        flatPicker[i].remove();
      }
    }

    const dropZones = document.getElementsByClassName('dz-hidden-input') as HTMLCollection;
    for (let i = 0; i < dropZones.length; i++) {
      dropZones[i].remove();
    }

    this.modalReport.close();
  }

  getEstablishments() {
    this.requestInProgress.next(true);

    this.establishmentsService.getMyEstablishments('', '', '', '', '').subscribe(response => {
      if (response['success']) {
        response['establishments'].forEach(establishment => {
          if (establishment.id !== parseInt(this.activatedRoute.snapshot.parent.params.establishment_id, 10)) {
            establishment.group = true;
            this.establishments.push(establishment);
          }
        });
      }

      this.requestInProgress.next(false);
    });
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  getReport() {
    let request;
    this.requestInProgress.next(true);

    if (this.activatedRoute.snapshot.parent.params.checkpoint_id) {
      request = this.reportsService.getReport(
        this.activatedRoute.snapshot.parent.params.checkpoint_id,
        this.activatedRoute.snapshot.params.report_id
      );
    } else {
      request = this.reportsService.getReport(
        this.activatedRoute.snapshot.params.checkpoint_id,
        this.activatedRoute.snapshot.params.report_id
      );
    }

    request.subscribe(response => {
      if (response['success']) {
        this.canUpdate = response['canUpdate'];

        this.reportForm.patchValue(response['report']);
        this.observations.setValue('');

        const temp = [];
        this.countOldObservations = response['report']['observations'].length;
        response['report']['observations'].forEach(element => {
          temp.push(element.name);
        });
        this.oldObservations = temp.join('\n');

        if (this.reportForm.value.enterprise_id && this.reportForm.value.user_id) {
          this.user = this.reportForm.value.enterprise_id + '-' + this.reportForm.value.user_id;
        }

        if (!this.canUpdate) {
          this.reportForm.disable();
        }
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      Flatpickr();
      this.requestInProgress.next(false);
    });
  }

  getCheckpoint() {
    let request;
    this.errors = {};
    this.requestInProgress.next(true);

    if (this.activatedRoute.snapshot.parent.params.checkpoint_id) {
      request = this.checkpointsService.getMyEstablishmentCheckpoint(this.activatedRoute.snapshot.parent.params.checkpoint_id);
    } else if (this.activatedRoute.snapshot.params.checkpoint_id) {
      request = this.checkpointsService.getMyEstablishmentCheckpoint(this.activatedRoute.snapshot.params.checkpoint_id);
    }

    if (request) {
      request.subscribe(response => {
        if (response['success']) {
          if (this.isNew) {
            if (response['checkpoint'].enterprise_id && response['checkpoint'].user_id) {
              this.user = response['checkpoint'].enterprise_id + '-' + response['checkpoint'].user_id;
              this.enterprise_id.setValue(response['checkpoint'].enterprise_id);
              this.user_id.setValue(response['checkpoint'].user_id);
            }
          }
          this.checkpoint = response['checkpoint'];
        }
        this.requestInProgress.next(false);
      });
    }
  }

  changeUser() {
    if (this.user) {
      const split = this.user.split('-');
      this.enterprise_id.setValue(split[0]);
      this.user_id.setValue(split[1]);
    } else {
      this.enterprise_id.setValue(null);
      this.user_id.setValue(null);
    }
  }

  changeCorrector() {
    if (this.corrector) {
      const split = this.corrector.split('-');
      this.corrector_enterprise_id.setValue(split[0]);
      this.corrector_user_id.setValue(split[1]);
    } else {
      this.corrector_enterprise_id.setValue(null);
      this.corrector_user_id.setValue(null);
    }
  }

  getEnterprises() {
    this.requestInProgress.next(true);

    this.enterprisesService.getProviders('', '', '', '', '').subscribe(response => {
      if (response['success']) {
        response['enterprises'].forEach(enterprise => {
          this.getUsersIntervenant(enterprise);
        });
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  getUsersIntervenant(enterprise) {
    this.requestInProgress.next(true);

    this.usersService.getProviderUsers('', '', '', '', '', enterprise.id).subscribe(response => {
      if (response['success']) {
        response['users'].forEach(user => {
          this.enterprises.push({
            id: enterprise.id + '-' + user.id,
            name: enterprise.name + ' - ' + user.firstname + ' ' + user.lastname
          });
          this.correctors.push({
            id: enterprise.id + '-' + user.id,
            name: enterprise.name + ' - ' + user.firstname + ' ' + user.lastname
          });
        });
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  getMyUsers() {
    this.requestInProgress.next(true);

    this.usersService.getMyUsers('', '', '', '', '').subscribe(response => {
      if (response['success']) {
        response['users'].forEach(user => {
          this.correctors.push({
            id: response['enterprise'].id + '-' + user.id,
            name: response['enterprise'].name + ' - ' + user.firstname + ' ' + user.lastname
          });
        });
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  createReport() {
    let request;
    this.errors = {};
    this.loader = true;

    if (this.observations.value) {
      const temp = [];
      const observations: string = this.observations.value;
      const splitValue = observations.split('\n');

      splitValue.forEach(value => {
        const reg = new RegExp(/^\s*•\s+/gm);
        value = value.replace(reg, '');
        if (value) {
          temp.push(value);
        }
      });
      this.observations.setValue(temp);
    }

    const report = { ...this.reportForm.value, establishments: this.establishmentsSelected };

    if (this.activatedRoute.snapshot.parent.params.establishment_id && this.activatedRoute.snapshot.parent.params.installation_id) {
      request = this.reportsService.createReport(
        this.activatedRoute.snapshot.parent.params.checkpoint_id,
        report
      );
    } else {
      request = this.reportsService.createReport(
        this.activatedRoute.snapshot.params.checkpoint_id,
        report
      );
    }

    request.subscribe(response => {
      if (response['success']) {
        this.isNew = false;
        if (this.files.length) {
          this.createDocuments(response['report']['id']);
          if (response['ids']) {
            response['ids'].forEach(id => {
              this.createDocuments(id);
            });
          }
        } else {
          this.loader = false;
          this.modalReport.close();
          CustomNotifierService.getSuccessCreate(this.notifier);
        }
      } else if (response['errors']) {
        this.errors = response['errors'];
        this.observations.setValue(null);
        CustomNotifierService.getErrorsForm(response['errors'], this.reportForm, this.notifier);
      }
      this.loader = false;

      if (this.observations.value) {
        this.observations.setValue(this.observations.value.join('\n'));
        this.observations.setValue(this.observations.value.replace(/(^|\r\n|\n)([^• ]|$)/g, '$1• $2'));
      }
    });
  }

  updateReport() {
    let request;

    this.errors = {};
    this.loader = true;

    if (this.observations.value) {
      const temp = [];
      const observations: string = this.observations.value;
      const splitValue = observations.split('\n');

      splitValue.forEach(value => {
        if (value) {
          const reg = new RegExp(/^\s*•\s+/gm);
          temp.push(value.replace(reg, ''));
        }
      });

      this.observations.setValue(temp);
    }

    request = this.reportsService.updateReport(
      this.activatedRoute.snapshot.params.checkpoint_id ? this.activatedRoute.snapshot.params.checkpoint_id :
          this.activatedRoute.snapshot.parent.params.checkpoint_id,
      this.activatedRoute.snapshot.params.report_id,
      this.reportForm.value
    );

    request.subscribe(response => {
      if (response['success']) {
        if (this.files.length) {
          this.createDocuments(this.activatedRoute.snapshot.params.report_id);
        } else {
          this.loader = false;
          this.modalReport.close();
          CustomNotifierService.getSuccessUpdate(this.notifier);
        }
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loader = false;

      if (this.observations.value) {
        this.observations.setValue(this.observations.value.join('\n'));
        this.observations.setValue(this.observations.value.replace(/(^|\r\n|\n)([^• ]|$)/g, '$1• $2'));
      }
    });
  }

  addTextareaBullet(event) {
    if (event.srcElement.value) {
      event.srcElement.value = event.srcElement.value.replace(/(^|\r\n|\n)([^• ]|$)/g, '$1• $2');
    }
  }

  pasteTextareaBullet(event) {
    setTimeout(() => {
      event.srcElement.value = event.srcElement.value.replace(/^\s+|\s+$/g, '');
      event.srcElement.value = event.srcElement.value.replace(/[\r\n]+/g, '\n');
      event.srcElement.value = event.srcElement.value.replace(/(^|\r\n|\n)([^• ]|$)/g, '$1• $2');
    }, 150);
  }

  createDocuments(report_id) {
    this.errors = {};
    this.loader = true;

    this.reportsService.createFile(report_id, this.files).subscribe(response => {
      if (response['success']) {
        this.modalReport.close();
        CustomNotifierService.getSuccessUpdate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loader = false;
    });
  }

  chooseFile() {
    const element: HTMLElement = document.getElementById('inputFile') as HTMLElement;
    element.click();
  }

  onFileChange(event) {
    if (event.target && event.target.files && event.target.files.length) {
      for (let i = 0; i < event.target.files.length; i++) {
        const reader = new FileReader();
        const file = event.target.files[i];
        reader.readAsDataURL(file);

        reader.onload = () => {
          let extension = '';
          let name = '';

          const names: string = file.name;
          const split = names.split('.');
          if (split.length) {
            name = split[0];
            extension = split[split.length - 1];
          }

          this.files.push({ file_base64: reader.result, name: name, extension: extension});
        };
      }
    } else {
      let extension = '';
      let name = '';

      const split = event.name.split('.');
      if (split.length) {
        name = split[0];
        extension = split[split.length - 1];
      }

      this.files.push({ file_base64: event.file, name: name, extension: extension });
    }
    const element: HTMLInputElement = document.getElementById('inputFile') as HTMLInputElement;
    element.value = '';
  }

  removeFile(index) {
    this.files.splice(index, 1);

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  bindModal(modal) {
    this.modalReport = modal;
  }

  closeModal() {
    if (this.activatedRoute.snapshot.parent.params.checkpoint_id) {
      this.allReports.refreshData();
    } else {
      if (this.activatedRoute.snapshot.parent.params.registry_id) {
        this.dashboardRegistry.getCheckpoints();
      } else {
        this.calendar.refreshEvents();
      }
    }
    this.router.navigate([this.activatedRoute.snapshot.parent.url.join('/')]).then(() => {});
  }
}
