import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ModalRightComponent } from './modal-right.component';
import { ConfirmModalModule } from '../confirm-modal/confirm-modal.module';

@NgModule({
  imports: [
    CommonModule,
    ConfirmModalModule
  ],
  declarations: [ModalRightComponent],
  exports: [ModalRightComponent]
})

export class ModalRightModule {}
