import { Component, HostListener, OnInit } from '@angular/core';

import { AuthGuard } from './core/auth/auth.guard';
import { AuthService } from './core/auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'BatiRegistre';

  constructor(
    public authGuard: AuthGuard,
    public authService: AuthService,
  ) {}

  ngOnInit() {
    this.calcMargin();
  }

  /**
   * Calc left margin for component
   */
  @HostListener('window:resize', ['$event'])
  calcMargin() {
    setTimeout(() => {
      const width = window.innerWidth;
      if (width > 768) {
        this.authService.isToolBarNotification = true;
        this.authService.isSideNavNotification = false;
      } else {
        this.authService.isToolBarNotification = false;
        this.authService.isSideNavNotification = true;
      }
    }, 15);
  }
}
