import { Routes } from '@angular/router';

import { DashboardSurveillanceComponent } from './dashboard-surveillance/dashboard-surveillance.component';
import { AllSanitaryTemperaturesComponent } from './sanitary-temperatures/all-sanitary-temperatures/all-sanitary-temperatures.component';
import { AllSanitaryLegionellasComponent } from './sanitary-legionellas/all-sanitary-legionellas/all-sanitary-legionellas.component';
import { AllSanitaryAnalyzesComponent } from './sanitary-analyzes/all-sanitary-analyzes/all-sanitary-analyzes.component';
import { AllSanitaryLegionellaFramesComponent } from './sanitary-legionella-frames/all-sanitary-legionella-frames/all-sanitary-legionella-frames.component';
import { AllSanitaryTemperatureFramesComponent } from './sanitary-temperature-frames/all-sanitary-temperature-frames/all-sanitary-temperature-frames.component';
import { UploadSanitaryLegionellaFileComponent } from './sanitary-legionellas/upload-sanitary-legionella-file/upload-sanitary-legionella-file.component';
import { UploadSanitaryTemperatureFileComponent } from './sanitary-temperatures/upload-sanitary-temperature-file/upload-sanitary-temperature-file.component';
import { UploadSanitaryAnalyzeFileComponent } from './sanitary-analyzes/upload-sanitary-analyze-file/upload-sanitary-analyze-file.component';

import { sanitaryLegionellaRoutes } from './sanitary-legionellas/sanitary-legionellas.routing';
import { sanitaryTemperatureRoutes } from './sanitary-temperatures/sanitary-temperatures.routing';
import { sanitaryLegionellaFramesRoutes } from './sanitary-legionella-frames/sanitary-legionella-frames.routing';
import { sanitaryTemperatureFramesRoutes } from './sanitary-temperature-frames/sanitary-temperature-frames.routing';
import { sanitaryAnalyzeRoutes } from './sanitary-analyzes/sanitary-analyzes.routing';
import { sanitaryAnalyzeFramesRoutes } from './sanitary-analyze-frames/sanitary-analyze-frames.routing';
import { AllSanitaryAnalyzeFramesComponent } from './sanitary-analyze-frames/all-sanitary-analyze-frames/all-sanitary-analyze-frames.component';


export const sanitarySurveillancesRoutes: Routes = [
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/sanitary-surveillances',
    component: DashboardSurveillanceComponent,
    children: []
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/sanitary-surveillances/temperatures',
    component: AllSanitaryTemperaturesComponent,
    children: [
      ...sanitaryTemperatureRoutes,
    ]
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/sanitary-surveillances/' +
      'temperatures/:sanitary_temperature_id/files',
    component: AllSanitaryTemperaturesComponent,
    children: [
      {
        path: 'create',
        component: UploadSanitaryTemperatureFileComponent
      },
      {
        path: ':file_id',
        component: UploadSanitaryTemperatureFileComponent
      }
    ]
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/sanitary-surveillances/legionellas',
    component: AllSanitaryLegionellasComponent,
    children: [
      ...sanitaryLegionellaRoutes
    ]
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/sanitary-surveillances/' +
      'legionellas/:sanitary_legionella_id/files',
    component: AllSanitaryLegionellasComponent,
    children: [
      {
        path: 'create',
        component: UploadSanitaryLegionellaFileComponent
      },
      {
        path: ':file_id',
        component: UploadSanitaryLegionellaFileComponent
      }
    ]
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/sanitary-surveillances/analyzes',
    component: AllSanitaryAnalyzesComponent,
    children: [
      ...sanitaryAnalyzeRoutes,
    ]
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/sanitary-surveillances/' +
      'analyzes/:sanitary_analyze_id/files',
    component: AllSanitaryAnalyzesComponent,
    children: [
      {
        path: 'create',
        component: UploadSanitaryAnalyzeFileComponent
      },
      {
        path: ':file_id',
        component: UploadSanitaryAnalyzeFileComponent
      }
    ]
  },
  // FRAME
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/sanitary-surveillances/' +
      'legionellas/:sanitary_legionella_id/frames',
    component: AllSanitaryLegionellaFramesComponent,
    children: [
      ...sanitaryLegionellaFramesRoutes
    ]
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/sanitary-surveillances/' +
      'temperatures/:sanitary_temperature_id/frames',
    component: AllSanitaryTemperatureFramesComponent,
    children: [
      ...sanitaryTemperatureFramesRoutes
    ]
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/sanitary-surveillances/' +
      'analyzes/:sanitary_analyze_id/frames',
    component: AllSanitaryAnalyzeFramesComponent,
    children: [
      ...sanitaryAnalyzeFramesRoutes
    ]
  },
];
