import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DashboardHomeComponent } from './dashboard-home.component';
import { BreadcrumbsModule } from '../../../core/breadcrumbs/breadcrumbs.module';
import { CalendarModule } from '../../../core/calendar/calendar.module';
import { ExportDownloadModule } from '../../export-download/export-download.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { SearchbarModule } from '../../../core/searchbar/searchbar.module';
import { PaginationModule } from '../../../core/pagination/pagination.module';
import { DashboardObservationsComponent } from '../dashboard-observations/dashboard-observations.component';
import { DashboardCommissionsComponent } from '../dashboard-commissions/dashboard-commissions.component';
import { DashboardObservationsModule } from '../dashboard-observations/dashboard-observations.module';
import { DashboardCommissionsModule } from '../dashboard-commissions/dashboard-commissions.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    BreadcrumbsModule,
    ExportDownloadModule,
    CalendarModule,
    NgSelectModule,
    SearchbarModule,
    PaginationModule,
    DashboardObservationsModule,
    DashboardCommissionsModule,
  ],
  declarations: [DashboardHomeComponent],
  entryComponents: [],
})

export class DashboardHomeModule {}
