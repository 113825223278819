import { Injectable } from "@angular/core";
import { RequestService } from "./request.service";
import { HttpClient } from "@angular/common/http";
import { NotifierService } from "angular-notifier";

@Injectable()
export class ExportDownloadRequestService extends RequestService {
  constructor(protected http: HttpClient, protected notifier: NotifierService) {
    super(http, notifier);
  }

  public getPdfExport(url, params, options) {
    let paramsStr = "";
    if (params) {
      for (let key in params) {
        paramsStr += key + "=" + params[key] + "&";
      }
    }
    if (options) {
      for (let key in options) {
        paramsStr += key + "=" + options[key] + "&";
      }
    }
    return this.get("download-export/" + url + "?" + paramsStr, {
      responseType: "arraybuffer",
    });
  }

  /// ADMIN EXPORT USERS
  public adminExport(url) {
    return this.get("download-export/" + url, { responseType: "arraybuffer" });
  }

  /// GET
  public getEstablishmentsNumber(searchByEnterpriseId, getByType) {
    return this.get(
      "stats/establishments?" +
        "searchByEnterpriseId=" +
        searchByEnterpriseId +
        "&getByType=" +
        getByType
    );
  }
  public getUsersNumber(groupBy, dateFrom, dateTo) {
    return this.get(
      "stats/users?" +
        "groupBy=" +
        groupBy +
        "&dateFrom=" +
        dateFrom +
        "&dateTo" +
        dateTo
    );
  }
  public getRegistriesByDomain(searchByEnterpriseId) {
    return this.get(
      "stats/registers-by-domain?" +
        "searchByEnterpriseId=" +
        searchByEnterpriseId
    );
  }
  public getRegistriesByDomainAndEnterprise() {
    return this.get("stats/registers-by-domain-and-enterprise");
  }
  public getRegistriesByDomainAndEstablishment() {
    return this.get("stats/registers-by-domain-and-establishment");
  }

  public getEnterpriseRegistriesByDomainAndEstablishment() {
    return this.get("stats/enterprise-registers-by-domain-and-establishment");
  }
  public getObservationsStats(groupBy, dateFrom, dateTo) {
    return this.get(
      "stats/enterprise-observations?" +
        "groupBy=" +
        groupBy +
        "&dateFrom=" +
        dateFrom +
        "&dateTo" +
        dateTo
    );
  }
}
