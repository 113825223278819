import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { HttpClient } from '@angular/common/http';
import { NotifierService } from 'angular-notifier';
import { group } from '@angular/animations';

@Injectable()
export class EstablishmentsRequestService extends RequestService {
  constructor(protected http: HttpClient, protected notifier: NotifierService) {
    super(http, notifier);
  }

  /// GET
  public getEnterpriseEstablishments(search, sort, order, limit, page, enterprise_id) {
    return this.get('enterprise/' + enterprise_id + '/establishments?' +
      'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getEnterpriseEstablishmentsArchive(search, sort, order, limit, page, enterprise_id) {
    return this.get('enterprise/' + enterprise_id + '/establishments/archive?' +
      'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getEstablishment(enterprise_id, establishment_id) {
    return this.get(
      'enterprise/' + enterprise_id + '/establishment/' + establishment_id
    );
  }

  public getMyRegistry(search, sort, order, limit, page, registry_id, viewDashboard = false) {
    return this.get('establishments/mine/registry/' + registry_id + '?' +
      'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page + '&viewDashboard=' + viewDashboard);
  }
  public getMyRegistryByGroupId(search, sort, order, limit, page, registry_id, group_id) {
    return this.get('establishments/mine/registry/' + registry_id + '/group/' + group_id +
      '?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getMyEstablishments(search, sort, order, limit, page, viewDashboard = false) {
    return this.get('establishments/mine?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit
      + '&page=' + page + '&viewDashboard=' + viewDashboard);
  }
  public getMyEstablishmentsByGroupId(search, sort, order, limit, page, group_id) {
    return this.get('establishments/mine/group/' + group_id + '?search=' + search + '&sort=' + sort + '&order=' + order +
      '&limit=' + limit + '&page=' + page);
  }
  public getMyEstablishmentsArchive(search, sort, order, limit, page, viewDashboard = false) {
    return this.get('establishments/mine/archive?' +
      'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page + '&viewDashboard=' + viewDashboard);
  }
  public getMyEstablishment(establishment_id) {
    return this.get('establishment/mine/' + establishment_id);
  }
  public geMyEstablishmentsChildren(search, sort, order, limit, page, parent_id) {
    return this.get('establishment/mine/' + parent_id + '/children?' +
      'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }

  public getCustomerEstablishments(search, sort, order, limit, page, enterprise_id) {
    return this.get('customers/' + enterprise_id + '/establishments?' +
      'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }

  public getAllEstablishmentsRegistry(registry_id) {
    return this.get('dashboard-registry/' + registry_id + '/establishments');
  }

  public getFiles(search, sort, order, limit, page, establishment_id) {
    return this.get('establishment/' + establishment_id + '/files?' +
      'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getFile(establishment_id, file_id) {
    return this.get('establishment/' + establishment_id + '/file/' + file_id);
  }

  public getMassImportTemplate(type, subtypes, cat, subCat, registries, work) {
    return this.get('establishments/template' +
      '?type=' + type + '&subtypes=' + subtypes + '&cat=' + cat + '&subcat=' + subCat + '&regis=' + registries + '&work=' + work,
      { responseType: 'arraybuffer' });
  }

  /// CREATE
  public createEstablishment(enterprise_id, establishment) {
    return this.post('enterprise/' + enterprise_id + '/establishment', {
      establishment,
    });
  }
  public createMyEstablishment(establishment) {
    return this.post('establishment/mine', { establishment });
  }

  public createFile(establishment_id, file) {
    return this.post('establishment/' + establishment_id + '/file', {
      file: file,
    });
  }
  public setMassImportTemplate(file) {
    return this.post('establishments/template', { file });
  }

  /// UPDATE
  public updateEstablishment(enterprise_id, establishment_id, establishment) {
    return this.put(
      'enterprise/' + enterprise_id + '/establishment/' + establishment_id,
      { establishment }
    );
  }
  public updateMyEstablishment(establishment_id, establishment) {
    return this.put('establishment/mine/' + establishment_id, {
      establishment,
    });
  }
  public changeActive(establishment_id) {
    return this.put('establishment/' + establishment_id + '/change_active', {});
  }
  public changeMyActive(establishment_id) {
    return this.put(
      'establishment/mine/' + establishment_id + '/change_active',
      {}
    );
  }
  public updateMyRegistries(registries, establishments, operation) {
    return this.put('establishments/mine/registries', {
      registries,
      establishments,
      operation,
    });
  }

  public updateFile(establishment_id, file_id, file) {
    return this.put('establishment/' + establishment_id + '/file/' + file_id, {
      file: file,
    });
  }

  /// DELETE
  public deleteFile(establishment_id, file_id) {
    return this.delete(
      'establishment/' + establishment_id + '/file/' + file_id
    );
  }
  public deleteMine(establishment_id) {
    return this.delete('establishment/mine/' + establishment_id);
  }

  /// DOWNLOAD
  public downloadFile(establishment_id, file_id) {
    return this.get(
      'establishment/' + establishment_id + '/file/' + file_id + '/download',
      { responseType: 'arraybuffer' }
    );
  }

  // REMOVE FROM GROUP
  removeFromGroup(group_id, establishment_id) {
    return this.delete('establishments/mine/' + establishment_id + '/group/' + group_id);
  }

  deleteGroup(group_id) {
    return this.delete('establishments/mine/group/' + group_id );
  }
  deleteGroupByParentId(group_id, parent_id) {
    return this.delete('establishments/mine/group/' + group_id + '/parent/' + parent_id);
  }

  /// GET ESTABLISHMENTS BY EMPLOYEE
  public getEstablishmentsByEmployee(employee_id) {
    return this.get('establishments/' + employee_id);
  }
}
