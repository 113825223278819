import { Routes } from '@angular/router';

import { AllUsersComponent } from './all-users/all-users.component';
import { UpdateUserComponent } from './update-user/update-user.component';

import { ModalLogsComponent } from '../logs/modal-logs/modal-logs.component';
import { AllProviderRegistryUsersComponent } from './all-provider-registry-users/all-provider-registry-users.component';

export const usersRoutes: Routes = [
  {
    path: 'users/mine',
    component: AllUsersComponent,
    children: [
      {
        path: 'create',
        component: UpdateUserComponent
      },
      {
        path: ':user_id',
        component: UpdateUserComponent
      },
      {
        path: ':user_id/logs',
        component: ModalLogsComponent,
      }
    ]
  },
  {
    path: 'users',
    component: AllUsersComponent,
    children: [
      {
        path: 'create',
        component: UpdateUserComponent
      },
      {
        path: ':user_id',
        component: UpdateUserComponent
      },
      {
        path: ':user_id/logs',
        component: ModalLogsComponent,
      }
    ]
  },
  {
    path: 'enterprises/:enterprise_id/users',
    component: AllUsersComponent,
    children: [
      {
        path: 'create',
        component: UpdateUserComponent
      },
      {
        path: ':user_id',
        component: UpdateUserComponent
      },
      {
        path: ':user_id/logs',
        component: ModalLogsComponent,
      }
    ]
  },
  {
    path: 'providers/:enterprise_id/users',
    component: AllUsersComponent,
    children: [
      {
        path: 'create',
        component: UpdateUserComponent
      },
      {
        path: ':user_id',
        component: UpdateUserComponent
      },
      {
        path: ':user_id/logs',
        component: ModalLogsComponent,
      }
    ]
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/providers/:enterprise_id/users',
    component: AllProviderRegistryUsersComponent,
    children: [
      {
        path: ':user_id',
        component: UpdateUserComponent
      },
      {
        path: ':user_id/logs',
        component: ModalLogsComponent,
      }
    ]
  }
];
