import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { UserNotificationsComponent } from './user-notifications.component';

import { BreadcrumbsModule } from '../../../core/breadcrumbs/breadcrumbs.module';
import { ConfirmModalModule } from '../../../core/confirm-modal/confirm-modal.module';
import { SearchbarModule } from '../../../core/searchbar/searchbar.module';
import { PaginationModule } from '../../../core/pagination/pagination.module';
import { ToolbarNotificationComponent } from '../../../core/toolbar-notification/toolbar-notification.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        BreadcrumbsModule,
        ConfirmModalModule,
        SearchbarModule,
        PaginationModule
    ],
    providers: [ToolbarNotificationComponent],
    declarations: [UserNotificationsComponent]
})

export class UserNotificationsModule {}
