import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';

import { environment } from '../../../../../environments/environment';

import { AuthService } from '../../../../core/auth/auth.service';

import { RisksRequestService } from '../../../../services/risks.request.service';
import { CustomNotifierService } from '../../../../services/custom.notifier.service';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-all-damages',
  templateUrl: './all-damages.component.html',
  styleUrls: ['./all-damages.component.scss']
})
export class AllDamagesComponent implements OnInit, AfterViewInit, OnDestroy {
  changeEnterprise: Subscription;
  environment = environment;

  requestInProgress = new Subject();
  requestCount = 0;

  isAdmin = false;

  canUpdate = false;
  canCreate = false;
  canDelete = false;

  activeTabsList = '';

  loader = false;
  errors = {};

  pageName: string;
  previousPageName: string[] = [];
  previousPageRoute: string[] = [];
  currentRoute = '';

  damages: any[] = [];
  count = 0;
  pages = 1;

  search = '';
  sort = 'RiskDamages.name';
  order = 'asc';
  limit = 12;
  page = 1;

  timeout: any;
  view = '';

  modalConfirmData = null;

  constructor(
    private router: Router,
    private authService: AuthService,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private risksService: RisksRequestService
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loader = this.requestCount > 0;
    });

    this.changeEnterprise = this.authService.getEnterprise().subscribe(() => {
      this.getDamages();
      this.createBreadcrumbs();
    });

    this.currentRoute = '/' + this.activatedRoute.snapshot.url.join('/') + '/';

    if (this.authService.loaded) {
      this.getDamages();
      this.createBreadcrumbs();
    }
    this.pageName = 'Dommages associés';
  }

  ngAfterViewInit() {
    this.modalConfirmData.eventOnClose.subscribe(event => {
      if (event) {
        this.requestInProgress.next(true);
        this.risksService.deleteDamage(event).subscribe(response => {
          if (response['success']) {
            this.refreshData();
            CustomNotifierService.getSuccessDelete(this.notifier);
          } else if (response['errors']) {
            CustomNotifierService.getErrors(response['errors'], this.notifier);
          }
          this.requestInProgress.next(false);
        });
      }
    });
  }

  ngOnDestroy() {
    this.changeEnterprise.unsubscribe();

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  searchData() {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.page = 1;
      this.refreshData();
    }, 500);
  }

  refreshData(sort = this.sort, order = this.order, page = this.page) {
    this.sort = sort;
    this.order = order;
    this.page = page;
    this.getDamages();
  }

  getDamages() {
    this.requestInProgress.next(true);

    this.risksService.getDamages(this.search, this.sort, this.order, this.limit, this.page).subscribe(response => {
      if (response['success']) {
        this.canUpdate = response['canUpdate'];
        this.canCreate = response['canCreate'];
        this.canDelete = response['canDelete'];
        this.canCreate = true;

        this.damages = response['risk_damages'];

        this.count = response['count'];
        this.pages = response['pages'];
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  filter(property) {
    if (this.sort === property) {
      if (this.order === 'asc') {
        this.order = 'desc';
      } else {
        this.order = 'asc';
      }
    } else {
      this.sort = property;
      this.order = 'asc';
    }

    this.refreshData();
  }

  dispatchPagination(data) {
    if (data.action === 'total_elements') {
      this.refreshPage(data.value);
    } else if (data.action === 'change_page') {
      this.changePage(data.value);
    }
  }

  refreshPage(limit) {
    this.limit = limit;
    this.page = 1;
    this.refreshData();
  }

  changePage(page) {
    if (page >= 1 && page <= this.pages) {
      this.page = page;
      this.refreshData();
    }
  }

  createBreadcrumbs() {
    this.previousPageName = [];
    this.previousPageRoute = [];

    this.previousPageName.push('Tableau de bord');
    this.previousPageRoute.push('/administration');
  }

  bindModalConfirmData(modal) {
    this.modalConfirmData = modal;
  }
}
