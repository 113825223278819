<app-modal-right (eventOnClose)="closeModal()" #session
  >{{ bindModal(session) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">
          {{ isNew ? "Création" : canUpdate ? "Mise à jour" : "Consultation" }}
        </h6>

        <h1 class="header-title">Membre du personnel</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <form [formGroup]="sessionForm" [hidden]="loaderContent">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="validationEstablishment" required>Établissement</label>
            <select
              class="form-control"
              data-toggle="select"
              formControlName="establishment_id"
              id="validationEstablishment"
              (change)="getTrainings()"
            >
              <option [ngValue]="null" [disabled]="true" class="text-muted">
                Sélectionner un établissement
              </option>
              <ng-container *ngFor="let estab of establishments">
                <option [value]="estab.id">
                  {{ estab.name }}
                </option>
              </ng-container>
            </select>
          </div>

          <div class="form-group">
            <label for="validationFormation" required>Formation</label>
            <select
              class="form-control"
              data-toggle="select"
              formControlName="training_id"
              id="validationFormation"
              (change)="getDateTrainings()"
              [attr.disabled]="disableSelect ? '' : null"
            >
              <option [ngValue]="null" [disabled]="true" class="text-muted">
                {{
                  disableSelect
                    ? "Veuillez d'abord Sélectionner un établissement"
                    : "Sélectionner une formation"
                }}
              </option>
              <ng-container *ngFor="let training of trainings">
                <option [value]="training.id">
                  {{ training.name }}
                </option>
              </ng-container>
            </select>
          </div>

          <div class="form-group">
            <label for="validationSessionFormation" required
              >Session formation</label
            >
            <select
              class="form-control"
              data-toggle="select"
              formControlName="date_training_id"
              id="validationSessionFormation"
              [attr.disabled]="disableSelectDate ? '' : null"
            >
              <option [ngValue]="null" [disabled]="true" class="text-muted">
                {{
                  disableSelectDate
                    ? "Veuillez d'abord Sélectionner une formation"
                    : dateTrainings.length
                    ? "Sélectionner une session de formation"
                    : "Il n'y a pas de session"
                }}
              </option>
              <ng-container *ngFor="let dateTraining of dateTrainings">
                <option [value]="dateTraining.id">
                  {{ dateTraining.date | date: "dd/MM/yyy" }}
                </option>
              </ng-container>
            </select>
          </div>

          <div class="is-loading loader-content" *ngIf="loader"></div>

          <div class="tab-content" *ngIf="!loader && employees.length">
            <div
              class="tab-pane fade active show"
              id="tabPaneOne"
              role="tabpanel"
            >
              <div class="row listAlias">
                <div class="col-12 d-flex flex-column">
                  <div class="card flex-fill">
                    <div class="card-body">
                      <div class="table-responsive">
                        <table class="table table-striped">
                          <thead>
                            <tr>
                              <th>
                                <input
                                  type="checkbox"
                                  (change)="selectedAllItems($event)"
                                  [checked]="checkedAllEmployees"
                                />
                              </th>
                              <th
                                class="href"
                                (click)="filter('EmployeeTrainings.firstname')"
                              >
                                <a class="text-muted">Prénom</a>
                                <span
                                  class="fe fe-arrow-down"
                                  *ngIf="
                                    sort === 'EmployeeTrainings.firstname' &&
                                    order === 'asc'
                                  "
                                ></span>
                                <span
                                  class="fe fe-arrow-up"
                                  *ngIf="
                                    sort === 'EmployeeTrainings.firstname' &&
                                    order === 'desc'
                                  "
                                ></span>
                                <span
                                  class="fe fe-arrow-right"
                                  *ngIf="sort !== 'EmployeeTrainings.firstname'"
                                ></span>
                              </th>
                              <th
                                class="href"
                                (click)="filter('EmployeeTrainings.lastname')"
                              >
                                <a class="text-muted">Nom</a>
                                <span
                                  class="fe fe-arrow-down"
                                  *ngIf="
                                    sort === 'EmployeeTrainings.lastname' &&
                                    order === 'asc'
                                  "
                                ></span>
                                <span
                                  class="fe fe-arrow-up"
                                  *ngIf="
                                    sort === 'EmployeeTrainings.lastname' &&
                                    order === 'desc'
                                  "
                                ></span>
                                <span
                                  class="fe fe-arrow-right"
                                  *ngIf="sort !== 'EmployeeTrainings.lastname'"
                                ></span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              class="dropdown"
                              *ngFor="let employee of employees"
                            >
                              <td>
                                <input
                                  type="checkbox"
                                  [name]="employee.name"
                                  [value]="employee.id"
                                  (change)="selectedItems($event, employee.id)"
                                  [checked]="checkedAll"
                                />
                              </td>
                              <td>
                                <a
                                  class="href"
                                  [routerLink]="[currentRoute + employee.id]"
                                >
                                  {{ employee.firstname }}
                                </a>
                              </td>
                              <td>
                                <a
                                  class="href"
                                  [routerLink]="[currentRoute + employee.id]"
                                >
                                  {{ employee.lastname }}
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <app-pagination
                        (returnEvent)="dispatchPagination($event)"
                        [count_elements]="count"
                        [current_page]="page"
                        [elements_length]="employees.length"
                        [limit]="limit"
                        [total_pages]="pages"
                        ngDefaultControl
                      >
                      </app-pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="loader-content"
            *ngIf="!loader && !employees.length && establishment_id.value"
          >
            <p>Aucun personnel dans cet établissement</p>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div modal-footer class="w-100" *ngIf="isNew || canUpdate">
    <button
      class="btn btn-primary w-100"
      (click)="isNew ? createSession() : updateSession()"
      [ngClass]="{ 'is-loading is-loading-sm': loader }"
      [disabled]="loader || sessionForm.invalid"
    >
      {{ isNew ? "Créer" : "Mettre à jour" }}
    </button>
  </div>
</app-modal-right>
