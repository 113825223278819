import { Routes } from '@angular/router';
import { UpdateSanitaryLegionellaFrameComponent } from './update-sanitary-legionella-frame/update-sanitary-legionella-frame.component';

export const sanitaryLegionellaFramesRoutes: Routes = [
  {
    path: 'create',
    component: UpdateSanitaryLegionellaFrameComponent
  },
  {
    path: ':sanitary_legionella_frame_id',
    component: UpdateSanitaryLegionellaFrameComponent
  },
];
