import { Routes } from '@angular/router';

import { ModalLogsComponent } from '../logs/modal-logs/modal-logs.component';
import { AllSanitaryFramesComponent } from './all-sanitary-frames/all-sanitary-frames.component';
import { UpdateSanitaryFrameComponent } from './update-sanitary-frame/update-sanitary-frame.component';

export const sanitaryFramesRoutes: Routes = [
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/sanitary-frames',
    component: AllSanitaryFramesComponent,
    children: [
      {
        path: 'create',
        component: UpdateSanitaryFrameComponent
      },
      {
        path: 'weekly',
        component: UpdateSanitaryFrameComponent
      },
      {
        path: 'monthly',
        component: UpdateSanitaryFrameComponent
      },
      {
        path: 'quarterly',
        component: UpdateSanitaryFrameComponent
      },
      {
        path: 'biannual',
        component: UpdateSanitaryFrameComponent
      },
      {
        path: 'annual',
        component: UpdateSanitaryFrameComponent
      },
      {
        path: 'weekly/:sanitary_frame_id',
        component: UpdateSanitaryFrameComponent
      },
      {
        path: 'monthly/:sanitary_frame_id',
        component: UpdateSanitaryFrameComponent
      },
      {
        path: 'quarterly/:sanitary_frame_id',
        component: UpdateSanitaryFrameComponent
      },
      {
        path: 'biannual/:sanitary_frame_id',
        component: UpdateSanitaryFrameComponent
      },
      {
        path: 'annual/:sanitary_frame_id',
        component: UpdateSanitaryFrameComponent
      },
      {
        path: ':sanitary_frame_id/logs',
        component: ModalLogsComponent
      }
    ]
  },
];
