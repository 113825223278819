<app-modal-right (eventOnClose)="closeModal()" #enterprise>{{ bindModal(enterprise) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">Création</h6>

        <h1 class="header-title">Prestataire</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <div [hidden]="loaderContent">
      <ul class="nav nav-tabs mb-3 pt-0" id="myTab" role="tablist">
        <li class="nav-item col-6">
          <a class="nav-link pt-0 href" [ngClass]="{ 'active': activeTab === 'siret'}" (click)="changeTabs('siret')">Par siret</a>
        </li>
        <li class="nav-item col-6">
          <a class="nav-link pt-0 href" [ngClass]="{ 'active': activeTab === 'email'}" (click)="changeTabs('email')">Par email</a>
        </li>
      </ul>

      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade" id="siret" role="tabpanel" aria-labelledby="tab-siret" [ngClass]="{ 'show active': activeTab === 'siret'}">
          <form [formGroup]="enterpriseSiretSearchForm">
            <div class="form-group">
              <label for="checkSiret" required>Recherche par Siret</label>
              <input type="text" class="form-control" formControlName="siret" id="checkSiret" placeholder="Siret" [ngClass]="{ 'is-invalid': siret.invalid && (siret.dirty || siret.touched) }">
              <div *ngIf="siret.invalid && (siret.dirty || siret.touched)" class="invalid-feedback">
                {{ getErrorMessage("siret") }}
              </div>
            </div>

            <button class="btn btn-primary w-100" (click)="checkSiret()" [ngClass]="{ 'is-loading is-loading-sm': loaderSearch }" [disabled]="loaderSearch || enterpriseSiretSearchForm.invalid">
              Rechercher
            </button>
          </form>

          <form class="mb-4 mt-4" [formGroup]="enterpriseSiretForm" [hidden]="existSiret === null || existSiret">
            <div class="row">
              <!-- NAME -->
              <div class="col-12">
                <div class="form-group">
                  <label for="validationName" required>Nom de l'entreprise</label>
                  <input type="text" formControlName="name" class="form-control" id="validationName" placeholder="Nom de l'entreprise" [ngClass]="{ 'is-invalid': nameSiret.invalid && (nameSiret.dirty || nameSiret.touched) }">
                  <div *ngIf="nameSiret.invalid && (nameSiret.dirty || nameSiret.touched)" class="invalid-feedback">
                    {{ getErrorMessage("name") }}
                  </div>
                </div>
              </div>

              <!-- SIRET -->
              <div class="col-12">
                <div class="form-group">
                  <label for="validationSiret">Siret</label>
                  <input type="text" formControlName="siret" class="form-control" id="validationSiret" placeholder="Siret" [ngClass]="{ 'is-invalid': siretSiret.invalid && (siretSiret.dirty || siretSiret.touched) }">
                  <div *ngIf="siretSiret.invalid && (siretSiret.dirty || siretSiret.touched)" class="invalid-feedback">
                    {{ getErrorMessage("siret") }}
                  </div>
                </div>
              </div>

              <!-- QUALIFICATION -->
              <div class="col-12">
                <div class="form-group">
                  <label for="qualificationSiret" required>Qualification</label>
                  <select class="form-control" data-toggle="select" formControlName="qualification" id="qualificationSiret" [ngClass]="{ 'is-invalid': qualificationSiret.invalid && (qualificationSiret.dirty || qualificationSiret.touched) }">
                    <option [value]="null">Sélectionnez une qualification</option>
                    <option value="TECHNICIEN">Technicien compétent</option>
                    <option value="ORGANISME">Organisme agréé</option>
                  </select>
                  <div *ngIf="qualificationSiret.invalid && (qualificationSiret.dirty || qualificationSiret.touched)" class="invalid-feedback">
                    {{ getErrorMessage("qualification") }}
                  </div>
                </div>
              </div>

              <!-- ACCESS TO ENTERPRISE -->
              <div class="col-12">
                <div class="form-group d-flex justify-content-start align-items-center">
                  <div class="col pl-0">
                    Donner accès à BatiRegistre
                  </div>
                  <div class="custom-checkbox-toggle">
                    <input type="checkbox" class="custom-control-input" id="validationAccess" formControlName="access_to_enterprise" [ngClass]="{ 'backgroundColor': access_to_enterpriseSiret.value }">
                    <label class="custom-control-label" [ngClass]="{ 'backgroundColor': access_to_enterpriseSiret.value }" for="validationAccess"></label>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <div class="card mt-4" *ngIf="existSiret">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col">
                  <h4 class="card-title mb-0 name">
                    {{ enterpriseSiret['name'] }}
                  </h4>
                </div>
              </div>

              <!-- QUALIFICATION -->
              <div class="form-group">
                <label for="validationProviderType" required>Qualification</label>
                <select required class="form-control" data-toggle="select" [(ngModel)]="qualification" id="validationProviderType">
                  <option value="">Sélectionnez une qualification</option>
                  <option value="TECHNICIEN">Technicien compétent</option>
                  <option value="ORGANISME">Organisme agréé</option>
                </select>
              </div>

              <!-- ACCESS TO ENTERPRISE -->
              <div class="form-group d-flex justify-content-start align-items-center">
                <div class="col pl-0">
                  Donner accès à BatiRegistre
                </div>
                <div class="custom-checkbox-toggle">
                  <input type="checkbox" class="custom-control-input" id="validationAccessSiret" [(ngModel)]="access_to_enterprise" [ngClass]="{ 'backgroundColor': access_to_enterprise }">
                  <label class="custom-control-label" [ngClass]="{ 'backgroundColor': access_to_enterprise }" for="validationAccessSiret"></label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="tab-pane fade" id="email" role="tabpanel" aria-labelledby="tab-email" [ngClass]="{ 'show active': activeTab === 'email'}">
          <!-- CARD RECAP CHECKPOINT REFERENCE -->
          <div class="card bg-light border">
            <div class="card-body">
              <p class="small text-muted mb-2">
                Dans le cas où il n'est pas nécessaire de donner des accès, ou pour les clients n'ayant pas besoin de renseigner tous les utilisateurs d'une société de prestation,
                nous recommanderons de renseigner un « utilisateur prestataire générique » avec une addresse email de type contact@entreprise.fr
              </p>
            </div>
          </div>

          <form class="mb-4" [formGroup]="enterpriseSearchByEmailForm">
            <div class="form-group">
              <label for="checkSiret">Recherche par Email</label>
              <input type="text" class="form-control" formControlName="email" id="checkEmail" placeholder="Email" [ngClass]="{ 'is-invalid': email.invalid && (email.dirty || email.touched) }">
              <div *ngIf="email.invalid && (email.dirty || email.touched)" class="invalid-feedback">
                {{ getErrorMessage("email") }}
              </div>
            </div>

            <button class="btn btn-primary w-100" (click)="checkEmail()" [ngClass]="{ 'is-loading is-loading-sm': loaderSearch }" [disabled]="loaderSearch || enterpriseSearchByEmailForm.invalid">
              Rechercher
            </button>
          </form>

          <form class="mb-4" [formGroup]="userEmailForm" [hidden]="existEmail1 === null">
            <div class="row">

              <!-- EMAIL -->
              <div class="col-12">
                <div class="form-group">
                  <label for="validationEmailDisabled" required>Email</label>
                  <input type="text" formControlName="email" class="form-control" id="validationEmailDisabled" placeholder="Email" [ngClass]="{ 'is-invalid': emailUser.invalid && (emailUser.dirty || emailUser.touched) }">
                  <div *ngIf="emailUser.invalid && (emailUser.dirty || emailUser.touched)" class="invalid-feedback">
                    {{ getErrorMessage("email") }}
                  </div>
                </div>
              </div>

              <!-- FIRSTNAME -->
              <div class="col-12">
                <div class="form-group">
                  <label for="validationFirstnameEmail" required>Prénom</label>
                  <input type="text" formControlName="firstname" class="form-control" id="validationFirstnameEmail" placeholder="Prénom" [ngClass]="{ 'is-invalid': firstname.invalid && (firstname.dirty || firstname.touched) }">
                  <div *ngIf="firstname.invalid && (firstname.dirty || firstname.touched)" class="invalid-feedback">
                    {{ getErrorMessage("firstname") }}
                  </div>
                </div>
              </div>

              <!-- LASTNAME -->
              <div class="col-12">
                <div class="form-group">
                  <label for="validationLastnameEmail" required>Nom de famille</label>
                  <input type="text" formControlName="lastname" class="form-control" id="validationLastnameEmail" placeholder="Nom de famille" [ngClass]="{ 'is-invalid': lastname.invalid && (lastname.dirty || lastname.touched) }">
                  <div *ngIf="lastname.invalid && (lastname.dirty || lastname.touched)" class="invalid-feedback">
                    {{ getErrorMessage("lastname") }}
                  </div>
                </div>
              </div>

              <!-- SIRET -->
              <div class="col-12">
                <div class="form-group">
                  <label for="validationSiretEmail">Recherche par Siret</label>
                  <input type="text" formControlName="siret" class="form-control" id="validationSiretEmail" placeholder="Siret" [ngClass]="{ 'is-invalid': siretSearchMail.invalid && (siretSearchMail.dirty || siretSearchMail.touched) }">
                  <div *ngIf="siretSearchMail.invalid && (siretSearchMail.dirty || siretSearchMail.touched)" class="invalid-feedback">
                    {{ getErrorMessage("siret") }}
                  </div>
                </div>
              </div>

              <div class="col-12">
                <button class="btn btn-primary w-100" (click)="checkSiretByEmail()" [ngClass]="{ 'is-loading is-loading-sm': loaderSearch }" [disabled]="loaderSearch || siretSearchMail.invalid">
                  Rechercher
                </button>
              </div>
            </div>
          </form>

          <form class="mb-4" [formGroup]="enterpriseEmailForm" [hidden]="existEmail2 !== false">
            <div class="row">

              <!-- ENTERPRISE NAME -->
              <div class="col-12">
                <div class="form-group">
                  <label for="validationNameEmail" required>Nom de l'entreprise</label>
                  <input type="text" formControlName="name" class="form-control" id="validationNameEmail" placeholder="Nom de l'entreprise" [ngClass]="{ 'is-invalid': nameEmail.invalid && (nameEmail.dirty || nameEmail.touched) }">
                  <div *ngIf="nameEmail.invalid && (nameEmail.dirty || nameEmail.touched)" class="invalid-feedback">
                    {{ getErrorMessage("name") }}
                  </div>
                </div>
              </div>

              <!-- SIRET -->
              <div class="col-12">
                <div class="form-group">
                  <label for="validationSiretEmail2" required>Siret</label>
                  <input type="text" formControlName="siret" class="form-control" id="validationSiretEmail2" placeholder="Siret" [ngClass]="{ 'is-invalid': siretEmail.invalid && (siretEmail.dirty || siretEmail.touched) }">
                  <div *ngIf="siretEmail.invalid && (siretEmail.dirty || siretEmail.touched)" class="invalid-feedback">
                    {{ getErrorMessage("siret") }}
                  </div>
                </div>
              </div>

              <!-- QUALIFICATION -->
              <div class="col-12">
                <div class="form-group">
                  <label for="qualificationEmail" required>Qualification</label>
                  <select class="form-control" data-toggle="select" formControlName="qualification" id="qualificationEmail" [ngClass]="{ 'is-invalid': qualificationEmail.invalid && (qualificationEmail.dirty || qualificationEmail.touched) }">
                    <option [value]="null">Sélectionnez une qualification</option>
                    <option value="TECHNICIEN">Technicien compétent</option>
                    <option value="ORGANISME">Organisme agréé</option>
                  </select>
                  <div *ngIf="qualificationEmail.invalid && (qualificationEmail.dirty || qualificationEmail.touched)" class="invalid-feedback">
                    {{ getErrorMessage("qualification") }}
                  </div>
                </div>
              </div>

              <!-- ACCESS TO ENTERPRISE -->
              <div class="col-12" *ngIf="!monoOffer && !discoverOffer">
                <div class="form-group d-flex justify-content-start align-items-center">
                  <div class="col pl-0">
                    Donner accès à BatiRegistre
                  </div>
                  <div class="custom-checkbox-toggle">
                    <input type="checkbox" class="custom-control-input" id="validationAccessEmail" formControlName="access_to_enterprise" [ngClass]="{ 'backgroundColor': access_to_enterpriseEmail.value }">
                    <label class="custom-control-label" [ngClass]="{ 'backgroundColor': access_to_enterpriseEmail.value }" for="validationAccessEmail"></label>
                  </div>
                </div>
              </div>

            </div>
          </form>

          <div class="card" *ngIf="existEmail2">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col">
                  <h4 class="card-title mb-0 name">
                    {{ enterpriseEmail['name'] }}
                  </h4>
                </div>
              </div>

              <!-- QUALIFICATION -->
              <div class="form-group">
                <label for="validationProviderType2" required>Qualification</label>
                <select required class="form-control" data-toggle="select" [(ngModel)]="qualification" id="validationProviderType2">
                  <option value="">Sélectionnez une qualification</option>
                  <option value="TECHNICIEN">Technicien compétent</option>
                  <option value="ORGANISME">Organisme agréé</option>
                </select>
              </div>

              <!-- ACCESS TO ENTERPRISE -->
              <div class="form-group d-flex justify-content-start align-items-center">
                <div class="col pl-0">
                  Donner accès à BatiRegistre
                </div>
                <div class="custom-checkbox-toggle">
                  <input type="checkbox" class="custom-control-input" id="validationAccessEmail2" [(ngModel)]="access_to_enterprise" [ngClass]="{ 'backgroundColor': access_to_enterprise }">
                  <label class="custom-control-label" [ngClass]="{ 'backgroundColor': access_to_enterprise }" for="validationAccessEmail2"></label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div modal-footer class="w-100">
    <button
      class="btn btn-primary w-100"
      (click)="activeTab === 'siret' ? addBySiret() : addByEmail()"
      [ngClass]="{ 'is-loading is-loading-sm': loaderSearch }"
      [disabled]="loaderSearch || (activeTab === 'siret' && existSiret && !qualification) || (activeTab === 'email' && existEmail2 && !qualification)"
      *ngIf="(activeTab === 'siret' && existSiret) || (activeTab === 'email' && existEmail2)">
      Ajouter ce prestataire
    </button>
    <button
      class="btn btn-primary w-100"
      (click)="activeTab === 'siret' ? createBySiret() : createByEmail()"
      [ngClass]="{ 'is-loading is-loading-sm': loader }"
      [disabled]="loader ||
      loaderSearch ||
      (activeTab === 'siret' && enterpriseSiretForm.invalid) ||
      (activeTab === 'email' && ((enterpriseEmailForm.invalid && !existEmail2) || (userEmailForm.invalid && !existEmail1)))"
      *ngIf="(activeTab === 'siret' && !existSiret) || (activeTab === 'email' && !existEmail2)">
      Créer ce prestataire
    </button>
  </div>
</app-modal-right>
