import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgSelectModule } from "@ng-select/ng-select";
import { ModalRightModule } from "src/app/core/modal-right/modal-right.module";
import { CreateUpdateTrainingComponent } from "./create-update-training.component";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    ModalRightModule,
  ],
  declarations: [CreateUpdateTrainingComponent],
})
export class CreateUpdateTrainingModule {}
