import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { HttpClient } from '@angular/common/http';
import { RequestService } from './request.service';

@Injectable()
export class SecurityProtocolsRequestService extends RequestService {
  constructor(
    protected http: HttpClient,
    protected notifier: NotifierService
  ) {
    super(http, notifier);
  }

  public getAllSecurityProtocols(search, sort, order, limit, page, registry_id, establishment_id) {
    return this.get( 'registry/' + registry_id + '/establishment/' + establishment_id + '/security-protocols?' +
      'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getSecurityProtocolsDashboard(search, sort, order, limit, page, registry_id, establishment_id, next) {
    return this.get( 'registry/' + registry_id + '/security-protocols?' +
      'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page +
      '&next=' + (next ? 'y' : '') + '&establishmentId=' + (establishment_id ? establishment_id : ''));
  }
  public getSecurityProtocol(registry_id, establishment_id, securityProtocolId) {
    return this.get('registry/' + registry_id + '/establishment/' + establishment_id + '/security-protocols/' + securityProtocolId);
  }
  public duplicateSecurityProtocol(registry_id, establishment_id, securityProtocolId) {
    // tslint:disable-next-line:max-line-length
    return this.get('registry/' + registry_id + '/establishment/' + establishment_id + '/security-protocols/' + securityProtocolId + '/duplicate');
  }
  public createSecurityProtocol(registry_id, establishment_id, securityProtocol) {
    return this.post('registry/' + registry_id + '/establishment/' + establishment_id + '/security-protocols', { securityProtocol });
  }
  public updateSecurityProtocol(registry_id, establishment_id, securityProtocolId, securityProtocol) {
    return this.put('registry/' + registry_id + '/establishment/' + establishment_id + '/security-protocols/' + securityProtocolId,
      { securityProtocol });
  }
  public deleteSecurityProtocol(registry_id, establishment_id, securityProtocolId) {
    return this.delete('registry/' + registry_id + '/establishment/' + establishment_id + '/security-protocols/' + securityProtocolId);
  }
  public downloadSecurityProtocol(registry_id, establishment_id, securityProtocolId) {
    return this.get('registry/' + registry_id + '/establishment/' + establishment_id + '/security-protocols/' + securityProtocolId +
      '/download', { responseType: 'arraybuffer' });
  }

  public getFiles(search, sort, order, limit, page, registry_id, establishment_id, securityProtocolId) {
    return this.get('registry/' + registry_id + '/establishment/' + establishment_id + '/security-protocols/' + securityProtocolId +
      '/files?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getFile(registry_id, establishment_id, securityProtocolId, file_id) {
    return this.get('registry/' + registry_id + '/establishment/' + establishment_id + '/security-protocols/' + securityProtocolId +
      '/files/' + file_id);
  }
  public createFile(registry_id, establishment_id, securityProtocolId, files) {
    return this.post('registry/' + registry_id + '/establishment/' + establishment_id + '/security-protocols/' + securityProtocolId +
      '/files', { files: files });
  }
  public updateFile(registry_id, establishment_id, securityProtocolId, file_id, file) {
    return this.put('registry/' + registry_id + '/establishment/' + establishment_id + '/security-protocols/' + securityProtocolId +
      '/files/' + file_id, { file: file });
  }
  public deleteFile(registry_id, establishment_id, securityProtocolId, file_id) {
    return this.delete('registry/' + registry_id + '/establishment/' + establishment_id + '/security-protocols/' + securityProtocolId +
      '/files/' + file_id);
  }
  public downloadFile(registry_id, establishment_id, securityProtocolId, file_id) {
    return this.get('registry/' + registry_id + '/establishment/' + establishment_id + '/security-protocols/' + securityProtocolId +
      '/files/' + file_id + '/download', { responseType: 'arraybuffer' });
  }
}
