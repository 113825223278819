<app-modal-right (eventOnClose)="closeModal()" #folder>{{ bindModal(folder) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">{{ isNew ? 'Création' : canUpdate ? 'Mise à jour' : 'Consultation' }}</h6>

        <h1 class="header-title">Dossier</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <form [formGroup]="folderForm" [hidden]="loaderContent">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="validationName" required>Nom</label>
            <input type="text" formControlName="name" id="validationName" class="form-control" placeholder="Nom du dossier" [ngClass]="{ 'is-invalid': name.invalid && (name.dirty || name.touched) }">
            <div *ngIf="name.invalid && (name.dirty || name.touched)" class="invalid-feedback">
              {{ getErrorMessage("name") }}
            </div>
          </div>

          <div class="form-group" *ngIf="generic_folders_type_id.value || isNew">
            <label for="validationFolderType" required>Type de dossier</label>
            <select class="form-control" data-toggle="select" formControlName="generic_folders_type_id" id="validationFolderType" [ngClass]="{ 'is-invalid': generic_folders_type_id.invalid && (generic_folders_type_id.dirty || generic_folders_type_id.touched) }">
              <option value="" [selected]="!generic_folders_type_id.value">Sélectionnez un type de dossier</option>
              <ng-container *ngFor="let folderType of folderTypes">
                <option [value]="folderType.id">{{ folderType.name }}</option>
              </ng-container>
            </select>
            <div *ngIf="generic_folders_type_id.invalid && (generic_folders_type_id.dirty || generic_folders_type_id.touched)" class="invalid-feedback">
              {{ getErrorMessage("generic_folders_type_id") }}
            </div>
          </div>

          <div class="form-group" *ngIf="selectedFolderType && (selectedFolderType?.registry_is_required || selectedFolderType?.worksheet_is_required)">
            <label for="validationRegistry" required>Registre</label>
            <select class="form-control" data-toggle="select" formControlName="registry_id" id="validationRegistry" [ngClass]="{ 'is-invalid': registry_id.invalid && (registry_id.dirty || registry_id.touched) }">
              <option value="" [selected]="!registry_id.value">Sélectionnez un registre</option>
              <ng-container *ngFor="let registry of registries">
                <option [value]="registry.id">{{ registry.name }}</option>
              </ng-container>
            </select>
            <div *ngIf="registry_id.invalid && (registry_id.dirty || registry_id.touched)" class="invalid-feedback">
              {{ getErrorMessage("registry_id") }}
            </div>
          </div>

          <div class="form-group">
            <label for="validationOrder" required>Ordre du téléchargement/affichage</label>
            <input type="number" formControlName="order_number" id="validationOrder" class="form-control" placeholder="Ordre du téléchargement/affichage" [ngClass]="{ 'is-invalid': order_number.invalid && (order_number.dirty || order_number.touched) }" min="0">
            <div *ngIf="order_number.invalid && (order_number.dirty || order_number.touched)" class="invalid-feedback">
              {{ getErrorMessage("order_number") }}
            </div>
          </div>

          <div class="form-group d-flex justify-content-start align-items-center">
            <div class="col pl-0">
              Ce dossier est-il important ?
            </div>
            <div class="custom-checkbox-toggle">
              <input type="checkbox" class="custom-control-input" id="validationIsImportant" formControlName="is_important" [ngClass]="{ 'backgroundColor': is_important.value }">
              <label class="custom-control-label" [ngClass]="{ 'backgroundColor': is_important.value }" for="validationIsImportant"></label>
            </div>
          </div>

          <div class="form-group d-flex justify-content-start align-items-center">
            <div class="col pl-0">
              Ce dossier peut-il contenir directement des fichiers ?
            </div>
            <div class="custom-checkbox-toggle">
              <input type="checkbox" class="custom-control-input" id="validationCanContainFile" formControlName="can_contain_file" [ngClass]="{ 'backgroundColor': can_contain_file.value }">
              <label class="custom-control-label" [ngClass]="{ 'backgroundColor': can_contain_file.value }" for="validationCanContainFile"></label>
            </div>
          </div>

          <div class="form-group d-flex justify-content-start align-items-center">
            <div class="col pl-0">
              Annexer tous les fichiers lors du téléchargement ?
            </div>
            <div class="custom-checkbox-toggle">
              <input type="checkbox" class="custom-control-input" id="validationConcatenable" formControlName="is_concatenable" [ngClass]="{ 'backgroundColor': is_concatenable.value }">
              <label class="custom-control-label" [ngClass]="{ 'backgroundColor': is_concatenable.value }" for="validationConcatenable"></label>
            </div>
          </div>

          <!-- BORDER COLOR -->
          <div class="form-group">
            <label for="validationBorderColor">Couleur</label>
            <input type="color" formControlName="background_color" id="validationBorderColor" class="form-control" [ngClass]="{ 'is-invalid': background_color.invalid && (background_color.dirty || background_color.touched) }">
            <div *ngIf="background_color.invalid && (background_color.dirty || background_color.touched)" class="invalid-feedback">
              {{ getErrorMessage("border_color") }}
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div modal-footer class="w-100" *ngIf="isNew || canUpdate">
    <button class="btn btn-primary w-100" (click)="isNew ? createFolder() : updateFolder()" [ngClass]="{ 'is-loading is-loading-sm': loader }" [disabled]="loader || folderForm.invalid">
      {{ isNew ? 'Créer' : 'Mettre à jour'}}
    </button>
  </div>
</app-modal-right>
