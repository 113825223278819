import { Routes } from '@angular/router';

import { AllCheckpointsComponent } from './all-checkpoints/all-checkpoints.component';
import { UpdateCheckpointComponent } from './update-checkpoint/update-checkpoint.component';
import { UpdateMyCheckpointComponent } from './update-my-checkpoint/update-my-checkpoint.component';
import { UpdateCheckpointCustomComponent } from './update-checkpoint-custom/update-checkpoint-custom.component';

import { ModalLogsComponent } from '../logs/modal-logs/modal-logs.component';
import { TooltipCheckpointComponent } from './tooltip-checkpoint/tooltip-checkpoint.component';

export const checkpointsRoutes: Routes = [
  {
    path: 'registries/:registry_id/checkpoints',
    component: AllCheckpointsComponent,
    children: [
      {
        path: 'create',
        component: UpdateCheckpointComponent
      },
      {
        path: ':checkpoint_id',
        component: UpdateCheckpointComponent
      }
    ]
  },
  {
    path: 'installations/:installation_id/checkpoints',
    component: AllCheckpointsComponent,
    children: [
      {
        path: 'create',
        component: UpdateCheckpointComponent
      },
      {
        path: ':checkpoint_id',
        component: UpdateCheckpointComponent
      }
    ]
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/installations/:installation_id/checkpoints',
    component: AllCheckpointsComponent,
    children: [
      {
        path: 'create',
        component: UpdateCheckpointCustomComponent
      },
      {
        path: ':checkpoint_id',
        component: UpdateMyCheckpointComponent
      },
      {
        path: ':checkpoint_id/logs',
        component: ModalLogsComponent
      },
      {
        path: ':checkpoint_id/tooltip',
        component: TooltipCheckpointComponent
      }
    ]
  }
];
