export const frequencies = {
  day: {
    1: 'Journalier',
    7: 'Hebdomadaire'
  },
  week: {
    1: 'Hebdomadaire',
    2: 'Bi-mensuelle',
    4: 'Mensuelle'
  },
  month: {
    1: 'Mensuelle',
    3: 'Trimestrielle',
    6: 'Semestrielle',
    12: 'Annuelle',
  },
  year: {
    1: 'Annuelle',
    2: 'Biennale',
    3: 'Triennale',
    5: 'Quinquennale',
    10: 'Decennale',
    20: 'Vicennale',
    30: 'Tricennale',
    40: 'Quadragennale',
    50: 'Cinquantennale',
    100: 'Centennale',
    1000: 'Millénale',
    10000: 'Décamillénale'
  },
};
