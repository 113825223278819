import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AllRolesModule } from './all-roles/all-roles.module';
import { UpdateRoleModule } from './update-role/update-role.module';
import { RolesRequestService } from '../../services/roles.request.service';

@NgModule({
  imports: [
    CommonModule,
    AllRolesModule,
    UpdateRoleModule
  ],
  providers: [
    RolesRequestService
  ]
})

export class RolesModule {}
