import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AllOfficialPrescriptionsModule } from './all-official-prescriptions/all-official-prescriptions.module';
import { UpdateOfficialPrescriptionModule } from './update-official-prescription/update-official-prescription.module';
import { UpdateOfficialPrescriptionStateModule } from './update-official-prescription-state/update-official-prescription-state.module';
import { UploadOfficialPrescriptionFileModule } from './upload-official-prescription-file/upload-official-prescription-file.module';

import { OfficialPrescriptionsRequestService } from '../../services/official.prescriptions.request.service';

@NgModule({
  imports: [
    CommonModule,
    AllOfficialPrescriptionsModule,
    UpdateOfficialPrescriptionModule,
    UploadOfficialPrescriptionFileModule,
    UpdateOfficialPrescriptionStateModule
  ],
  providers: [OfficialPrescriptionsRequestService]
})
export class OfficialPrescriptionsModule {}
