import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs';

import { AuthService } from '../../../core/auth/auth.service';
import { environment } from '../../../../environments/environment';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { InstallationsRequestService } from '../../../services/installations.request.service';
import { RegistriesRequestService } from '../../../services/registries.request.service';
import { EstablishmentsRequestService } from '../../../services/establishments.request.service';

import Installation from '../../../../shared/interfaces/installation';
import Establishment from '../../../../shared/interfaces/establishment';
import Registry from '../../../../shared/interfaces/registry';

@Component({
  selector: 'app-all-installations',
  templateUrl: './all-installations.component.html',
  styleUrls: ['./all-installations.component.scss']
})
export class AllInstallationsComponent implements OnInit, OnDestroy, AfterViewInit {
  changeEnterprise: Subscription;
  environment = environment;
  view = '';

  canUpdate = false;
  canCreate = false;
  canDelete = false;

  requestInProgress = new Subject();
  requestCount = 0;

  pageName: string;
  previousPageName: string[] = [];
  previousPageRoute: string[] = [];
  currentRoute = '';

  registry: Registry;
  establishment: Establishment;
  installations: Installation[] = [];
  count = 0;
  pages = 1;

  search = '';
  sort = 'Installations.name';
  order = 'asc';
  limit = 12;
  page = 1;

  errors = {};
  timeout: any;
  loader = false;
  modalConfirm = null;
  modalConfirmData = null;

  show_inactive = false;

  constructor(
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private installationsService: InstallationsRequestService,
    private registriesService: RegistriesRequestService,
    private establishmentsService: EstablishmentsRequestService
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loader = this.requestCount > 0;
    });

    const is_inactive = JSON.parse(localStorage.getItem('show_inactive_installations'));
    if (is_inactive !== null) {
      this.show_inactive = is_inactive;
    } else {
      localStorage.setItem('show_inactive_installations', JSON.stringify(this.show_inactive));
    }

    this.changeEnterprise = this.authService.getEnterprise().subscribe(() => {
      this.getInstallations();
    });

    this.activatedRoute.params.subscribe(() => {
      this.getInstallations();
    });

    this.currentRoute = '/' + this.activatedRoute.snapshot.url.join('/') + '/';

    this.pageName = 'Installations';

    if (this.activatedRoute.snapshot.params.registry_id && this.activatedRoute.snapshot.params.establishment_id) {
      this.view = 'dashboard';

      this.getRegistry();
      this.getEstablishment();
    } else {
      this.view = 'admin';
    }

    this.createBreadcrumbs();
  }

  ngOnDestroy() {
    this.changeEnterprise.unsubscribe();

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  ngAfterViewInit() {
    this.modalConfirmData.eventOnClose.subscribe(event => {
      if (event) {
        this.requestInProgress.next(true);
        let request;

        request = this.installationsService.deleteInstallationCustom(
          this.activatedRoute.snapshot.params.establishment_id,
          event
        );

        request.subscribe(response => {
          if (response['success']) {
            this.refreshData();
            CustomNotifierService.getSuccessDelete(this.notifier);
          } else if (response['errors']) {
            CustomNotifierService.getErrors(response['errors'], this.notifier);
          }
          this.requestInProgress.next(false);
        });
      }
    });
  }

  searchData() {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.page = 1;
      this.refreshData();
    }, 500);
  }

  refreshData(sort = this.sort, order = this.order, page = this.page) {
    this.sort = sort;
    this.order = order;
    this.page = page;
    this.getInstallations();
  }

  getRegistry() {
    this.requestInProgress.next(true);
    this.registriesService.getRegistry(this.activatedRoute.snapshot.params.registry_id).subscribe(response => {
      if (response['success']) {
        this.registry = response['registry'];
        this.createBreadcrumbs();
      } else if (response['errors']) {
        this.errors = response['errors'];
      }
      this.requestInProgress.next(false);
    });
  }

  getEstablishment() {
    this.requestInProgress.next(true);
    this.establishmentsService.getMyEstablishment(this.activatedRoute.snapshot.params.establishment_id).subscribe(response => {
      if (response['success']) {
        this.establishment = response['establishment'];
        this.createBreadcrumbs();
      } else if (response['errors']) {
        this.errors = response['errors'];
      }
      this.requestInProgress.next(false);
    });
  }

  getInstallations() {
    let request;
    this.requestInProgress.next(true);

    if (this.activatedRoute.snapshot.params.registry_id && this.activatedRoute.snapshot.params.establishment_id) {
      if (this.show_inactive) {
        request = this.installationsService.getAllMyRegistryEstablishmentInstallations(
          this.search, this.sort, this.order, this.limit, this.page,
          this.activatedRoute.snapshot.params.registry_id, this.activatedRoute.snapshot.params.establishment_id);
      } else {
        request = this.installationsService.getMyRegistryEstablishmentInstallations(
          this.search, this.sort, this.order, this.limit, this.page,
          this.activatedRoute.snapshot.params.registry_id, this.activatedRoute.snapshot.params.establishment_id);
      }
    } else {
      request = this.installationsService.getInstallations(this.search, this.sort, this.order, this.limit, this.page);
    }

    request.subscribe(response => {
      if (response['success']) {
        this.canUpdate = response['canUpdate'];
        this.canCreate = response['canCreate'];
        this.canDelete = response['canDelete'];

        this.installations = response['installations'];

        this.count = response['count'];
        this.pages = response['pages'];
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  showHiddenInstallations() {
    this.show_inactive = !this.show_inactive;
    localStorage.setItem('show_inactive_installations', JSON.stringify(this.show_inactive));
    this.getInstallations();
  }

  fullInactive(installation) {
    if (installation.establishment_checkpoints && installation.establishment_checkpoints.length) {
      let inactive = true;
      installation.establishment_checkpoints.forEach(checkpoint => {
        if (checkpoint.show_checkpoint) {
          inactive = false;
        }
      });
      return inactive;
    }
    return false;
  }

  filter(property) {
    if (this.sort === property) {
      if (this.order === 'asc') {
        this.order = 'desc';
      } else {
        this.order = 'asc';
      }
    } else {
      this.sort = property;
      this.order = 'asc';
    }

    this.refreshData();
  }

  dispatchPagination(data) {
    if (data.action === 'total_elements') {
      this.refreshPage(data.value);
    } else if (data.action === 'change_page') {
      this.changePage(data.value);
    }
  }

  refreshPage(limit) {
    this.limit = limit;
    this.page = 1;
    this.refreshData();
  }

  changePage(page) {
    if (page >= 1 && page <= this.pages) {
      this.page = page;
      this.refreshData();
    }
  }


  bindModalConfirmData(modal) {
    this.modalConfirmData = modal;
  }

  bindModal(modal) {
    this.modalConfirm = modal;
  }

  createBreadcrumbs() {
    this.previousPageName = [];
    this.previousPageRoute = [];

    if (this.activatedRoute.snapshot.params.registry_id && this.activatedRoute.snapshot.params.establishment_id) {
      this.previousPageName.push('Tableau de bord');
      this.previousPageRoute.push('/');

      this.previousPageName.push(this.registry ? this.registry.name : 'Tableau de bord registre');
      this.previousPageRoute.push('/dashboard-registry/' + this.activatedRoute.snapshot.params.registry_id);

      this.previousPageName.push(this.establishment ? this.establishment.name : 'Tableau de bord établissement');
      this.previousPageRoute.push('/dashboard-registry/' + this.activatedRoute.snapshot.params.registry_id +
        '/establishment/' + this.activatedRoute.snapshot.params.establishment_id);
    } else {
      this.previousPageName.push('Tableau de bord');
      this.previousPageRoute.push('/administration');
    }
  }
}
