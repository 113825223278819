import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AllCustomersModule } from './all-customers/all-customers.module';
import { AllCustomerEstablishmentsModule } from './all-customer-establishments/all-customer-establishments.module';
import { AllCustomerRegistriesModule } from './all-customer-registries/all-customer-registries.module';
import { AllCustomerInstallationsModule } from './all-customer-installations/all-customer-installations.module';
import { AllCustomerCheckpointsModule } from './all-customer-checkpoints/all-customer-checkpoints.module';

import { AllCustomerReportsModule } from './all-customer-reports/all-customer-reports.module';
import { UpdateCustomerReportModule } from './update-customer-report/update-customer-report.module';
import { UploadCustomerReportFileModule } from './upload-customer-report-file/upload-customer-report-file.module';

import { AllCustomerObservationsModule } from './all-customer-observations/all-customer-observations.module';
import { UpdateCustomerObservationModule } from './update-customer-observation/update-customer-observation.module';
import { UpdateCustomerObservationStateModule } from './update-customer-observation-state/update-customer-observation-state.module';
import { UploadCustomerObservationFileModule } from './upload-customer-observation-file/upload-customer-observation-file.module';
import { ObservationsRequestService } from '../../services/observations.request.service';

@NgModule({
  imports: [
    CommonModule,
    AllCustomersModule,
    AllCustomerEstablishmentsModule,
    AllCustomerRegistriesModule,
    AllCustomerInstallationsModule,
    AllCustomerCheckpointsModule,

    AllCustomerReportsModule,
    UpdateCustomerReportModule,
    UploadCustomerReportFileModule,

    AllCustomerObservationsModule,
    UpdateCustomerObservationModule,
    UpdateCustomerObservationStateModule,
    UploadCustomerObservationFileModule
  ],
  providers: [ObservationsRequestService]
})
export class CustomersModule {}
