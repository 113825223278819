<div id="modal-right" class="modal modal-right fade fixed-right" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div id="modal-click" class="modal-dialog modal-dialog-vertical" [style.maxWidth]="width">
    <div class="modal-content">
      <div class="modal-header">
        <ng-content select="[modal-header]"></ng-content>

        <button type="button" class="close" (click)="checkEdit()">
          <span aria-hidden="true">&times;</span>
          <span class="sr-only">Close</span>
        </button>
      </div>

      <div class="modal-body">
        <ng-content select="[modal-content]"></ng-content>
      </div>

      <div class="modal-footer">
        <ng-content select="[modal-footer]"></ng-content>
      </div>
    </div>
  </div>
</div>

<app-confirm-modal [type]="'verification'" #confirm></app-confirm-modal>
