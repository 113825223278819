<app-modal-right (eventOnClose)="closeModal()" #reportModal>{{ bindModal(reportModal) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">{{ isNew ? 'Création' : canUpdate ? 'Mise à jour' : 'Consultation' }}</h6>

        <h1 class="header-title">Rapport</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <form [formGroup]="workForm" [hidden]="loaderContent">
      <div class="row">
        <div class="col-12">
          <!-- YEAR -->
          <div class="form-group">
            <label for="validationYear" required>Année</label>
            <input type="number" formControlName="year" minlength="4" maxlength="4" min="1900" max="2100" class="form-control" id="validationYear" placeholder="Année"
                   [ngClass]="{ 'is-invalid': year.invalid && (year.dirty || year.touched) }">
            <div *ngIf="year.invalid && (year.dirty || year.touched)" class="invalid-feedback">
              {{ getErrorMessage("year") }}
            </div>
          </div>

          <!-- WORKS -->
          <div class="form-group">
            <label for="validationTravaux" required>Travaux</label>
            <input formControlName="work" class="form-control" id="validationTravaux" placeholder="Travaux" maxlength="255" [ngClass]="{ 'is-invalid': work.invalid && (work.dirty || work.touched) }">
            <div *ngIf="work.invalid && (work.dirty || work.touched)" class="invalid-feedback">
              {{ getErrorMessage("work") }}
            </div>
          </div>

          <!-- STATE -->
          <div class="form-group">
            <label for="validationState">Etat</label>
            <select class="form-control" data-toggle="select" formControlName="state" id="validationState" [ngClass]="{ 'is-invalid': state.invalid && (state.dirty || state.touched) }">
              <option value="0">Non réalisé</option>
              <option value="1">Réalisé</option>
              <option value="2">Annulé</option>
            </select>
            <div *ngIf="state.invalid && (state.dirty || state.touched)" class="invalid-feedback">
              {{ getErrorMessage("state") }}
            </div>
          </div>

          <!-- COMMENTS -->
          <div class="form-group">
            <label for="validationCommentReport">Commentaire</label>
            <textarea formControlName="comment" class="form-control" id="validationCommentReport" placeholder="Commentaire" rows="5"></textarea>
            <div *ngIf="comment.invalid && (comment.dirty || comment.touched)" class="invalid-feedback">
              {{ getErrorMessage("comment") }}
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div modal-footer class="w-100" *ngIf="isNew || canUpdate">
    <button class="btn btn-primary w-100" (click)="isNew ? createWork() : updateWork()" [ngClass]="{ 'is-loading is-loading-sm': loader }" [disabled]="loader || workForm.invalid">
      {{ isNew ? 'Créer' : 'Mettre à jour' }}
    </button>
  </div>
</app-modal-right>
