<app-modal-right (eventOnClose)="closeModal()" #rightsTransferModal>{{ binModal(rightsTransferModal) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">Transfert de droits</h6>
        <h1 class="header-title">Transfert de droits</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <!-- INFORMATIONS CONTAINER -->
    <ng-container *ngIf="!loaderContent">
      <div class="card bg-light border">
        <div class="card-body">
          <p class="small text-muted mb-2">
            L'utilisateur source transférera ses droits à l'utilisateur cible.
          </p>
        </div>
      </div>
    </ng-container>

    <form [formGroup]="rightsTransferForm" *ngIf="!loaderContent">
      <div class="row">
        <div class="col-12">
          <div class="form-group" *ngIf="!loaderContent">
            <label>Utilisateur source</label>
            <ng-select [items]="enterprises"
                       [multiple]="false"
                       bindLabel="name"
                       notFoundText="Aucun résultat"
                       [selectableGroup]="true"
                       [closeOnSelect]="true"
                       bindValue="id"
                       placeholder="Sélectionnez un utilisateur source"
                       [(ngModel)]="user_source" (ngModelChange)="changeUser(true)" [ngModelOptions]="{ standalone: true }"
                       [disabled]="!canUpdate">
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">{{ item.name }}</ng-template>
            </ng-select>
          </div>

          <div class="form-group" *ngIf="!loaderContent">
            <label>Utilisateur cible</label>
            <ng-select [items]="enterprises"
                       [multiple]="false"
                       bindLabel="name"
                       notFoundText="Aucun résultat"
                       [selectableGroup]="true"
                       [closeOnSelect]="true"
                       bindValue="id"
                       placeholder="Sélectionnez un utilisateur cible"
                       [(ngModel)]="user_target" (ngModelChange)="changeUser(false)" [ngModelOptions]="{ standalone: true }"
                       [disabled]="!canUpdate">
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">{{ item.name }}</ng-template>
            </ng-select>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div modal-footer class="w-100" *ngIf="canUpdate">
    <button class="btn btn-primary w-100" (click)="transfer()" [ngClass]="{ 'is-loading is-loading-sm': loader || loaderContent }"
            [disabled]="(loader || loaderContent) || rightsTransferForm.invalid">
      Transférer les droits
    </button>
  </div>
</app-modal-right>
