<app-modal-right (eventOnClose)="closeModal()" #asbestosDetection>{{ bindModal(asbestosDetection) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">{{ isNew ? 'Création' : canUpdate ? 'Mise à jour' : 'Consultation' }}</h6>

        <h1 class="header-title">Rapport</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <form [formGroup]="asbestosDetectionForm" [hidden]="loaderContent">
      <div class="row">
        <div class="col-12">

          <!-- TYPE -->
          <div class="form-group">
            <label for="validationType">Type</label>
            <select class="form-control" data-toggle="select" formControlName="type" id="validationType" [ngClass]="{ 'is-invalid': type.invalid && (type.dirty || type.touched) }">
              <option [value]="null">Sélectionnez un type de repérage</option>
              <option value="Repérage amiante avant travaux (RAAT)">Repérage amiante avant travaux (RAAT)</option>
              <option value="Repérage amiante avant vente (RAAV)">Repérage amiante avant vente (RAAV)</option>
              <option value="Repérage matériaux liste A">Repérage matériaux liste A</option>
              <option value="Repérage matériaux liste B">Repérage matériaux liste B</option>
              <option value="Repérage matériaux liste A et B">Repérage matériaux liste A et B</option>
              <option value="Mise à jour d'un repérage">Mise à jour d'un repérage</option>
            </select>
            <div *ngIf="type.invalid && (type.dirty || type.touched)" class="invalid-feedback">
              {{ getErrorMessage("type") }}
            </div>
          </div>

          <!-- REFERENCY -->
          <div class="form-group">
            <label for="validationReferency" required>Référence rapport de repérage</label>
            <input type="text" formControlName="referency" class="form-control" id="validationReferency" placeholder="Référence rapport de repérage" [ngClass]="{ 'is-invalid': referency.invalid && (referency.dirty || referency.touched) }">
            <div *ngIf="referency.invalid && (referency.dirty || referency.touched)" class="invalid-feedback">
              {{ getErrorMessage("referency") }}
            </div>
          </div>

          <!-- DATE -->
          <div class="form-group">
            <label for="validationDate" required>Date</label>
            <input type="date" formControlName="date" class="form-control" id="validationDate" placeholder="Date" data-toggle="flatpickr" [ngClass]="{ 'is-invalid': date.invalid && (date.dirty || date.touched) }">
            <div *ngIf="date.invalid && (date.dirty || date.touched)" class="invalid-feedback">
              {{ getErrorMessage("date") }}
            </div>
          </div>

          <!-- ORGANIZATION -->
          <div class="form-group">
            <label for="validationOrganization" required>Organisme</label>
            <input type="text" formControlName="organization" class="form-control" id="validationOrganization" placeholder="Organisme" [ngClass]="{ 'is-invalid': organization.invalid && (organization.dirty || organization.touched) }">
            <div *ngIf="organization.invalid && (organization.dirty || organization.touched)" class="invalid-feedback">
              {{ getErrorMessage("organization") }}
            </div>
          </div>

          <!-- OBJECT -->
          <div class="form-group">
            <label for="validationObject" required>Objet</label>
            <input type="text" formControlName="object" class="form-control" id="validationObject" placeholder="Objet" [ngClass]="{ 'is-invalid': object.invalid && (object.dirty || object.touched) }">
            <div *ngIf="object.invalid && (object.dirty || object.touched)" class="invalid-feedback">
              {{ getErrorMessage("object") }}
            </div>
          </div>

          <!-- LIST OF LOCALIZATIONS -->
          <div class="form-group">
            <label for="validationBuildingsIdentified" required>Liste des parties des immeubles repérées</label>
            <textarea formControlName="buildings_identified" class="form-control" id="validationBuildingsIdentified" placeholder="Liste des parties des immeubles repérées" rows="5"
                      (keydown.enter)="addTextareaBullet($event)" (paste)="pasteTextareaBullet($event)" (change)="addTextareaBullet($event)"
                      [ngClass]="{ 'is-invalid': buildings_identified.invalid && (buildings_identified.dirty || buildings_identified.touched) }"></textarea>
            <div *ngIf="buildings_identified.invalid && (buildings_identified.dirty || buildings_identified.touched)" class="invalid-feedback">
              {{ getErrorMessage("buildings_identified") }}
            </div>
            <span class="badge badge-soft-info d-inline-flex"><span class="fe fe-info mr-1"></span>Une ligne par partie d'immeubles repérées</span>
          </div>

          <!-- ADD FILE-->
          <div class="form-group" *ngIf="isNew || canUpdate">
            <label for="inputFile">Documents</label>

            <div class="dropzone dropzone-multiple dz-clickable" appDragDrop (dropHandler)="onFileChange($event)">
              <div class="dz-default dz-message" (click)="chooseFile()">
                <span>Déposer vos fichiers ici pour télécharger</span>
              </div>
              <input id="inputFile" (change)="onFileChange($event)" type="file" [hidden]="true" multiple>
            </div>
          </div>

          <!-- LIST OF FILES -->
          <div class="card" *ngFor="let file of files; let index = index">
            <div class="card-body">
              <div class="d-flex align-content-center justify-content-between">
                <h4 class="card-title mb-0 name align-self-center">
                  {{ file.name + '.' + file.extension }}
                </h4>

                <a class="href tooltipHtml" data-placement="bottom" title="Supprimer" (click)="removeFile(index)" *ngIf="isNew || canUpdate">
                  <i class="fe fe-trash"></i>
                </a>
              </div>
            </div>
          </div>

          <!-- HAS ASBESTOS LOCALIZATIONS -->
          <div class="form-group d-flex justify-content-start align-items-center">
            <div class="col pl-0">
              Présence d'amiante ?
            </div>
            <div class="custom-checkbox-toggle">
              <input type="checkbox" class="custom-control-input" id="validationHasAsbestos" formControlName="has_asbestos" (change)="changeHasAsbestos()" [ngClass]="{ 'backgroundColor': has_asbestos.value }">
              <label class="custom-control-label" [ngClass]="{ 'backgroundColor': has_asbestos.value }" for="validationHasAsbestos"></label>
            </div>
          </div>

          <div *ngIf="has_asbestos.value">
            <h2>Liste des matériaux et produits contenant de l’amiante</h2>

            <div class="form-group">
              <div formArrayName="asbestos_detection_localizations">
                <div *ngFor="let asbestosDetection of asbestosDetectionForm['controls'].asbestos_detection_localizations['controls']; let i = index;" class="mb-3">
                  <div [formGroupName]="i" class="mb-05">

                    <!-- LOCALIZATION -->
                    <div class="form-group">
                      <label for="validationLocalization" required>Localisation</label>
                      <select class="form-control" data-toggle="select" formControlName="localization" id="validationLocalization" [ngClass]="{ 'is-invalid': asbestosDetection['controls'].localization.invalid && (asbestosDetection['controls'].localization.dirty || asbestosDetection['controls'].localization.touched) }">
                        <option value="" [selected]="!asbestosDetection['controls'].localization.value">Sélectionnez une localisation</option>
                        <ng-container *ngFor="let local of select_localisation">
                          <option [value]="local">{{ local }}</option>
                        </ng-container>
                      </select>
                      <div *ngIf="asbestosDetection['controls'].localization.invalid && (asbestosDetection['controls'].localization.dirty || asbestosDetection['controls'].localization.touched)" class="invalid-feedback">
                        {{ getErrorMessage("localization") }}
                      </div>
                      <span class="badge badge-soft-info d-inline-flex"><span class="fe fe-info mr-1"></span>La localisation est récupérée depuis les parties d'immeubles repérées</span>
                    </div>

                    <!-- ACCURACY LOCALIZATION -->
                    <div class="form-group">
                      <label for="validationAccuracyLocalization" required>Précision localisation</label>
                      <input type="text" formControlName="accuracy_localization" class="form-control" id="validationAccuracyLocalization" placeholder="Précision localisation" [ngClass]="{ 'is-invalid': asbestosDetection['controls'].accuracy_localization.invalid && (asbestosDetection['controls'].accuracy_localization.dirty || asbestosDetection['controls'].accuracy_localization.touched) }">
                      <div *ngIf="asbestosDetection['controls'].accuracy_localization.invalid && (asbestosDetection['controls'].accuracy_localization.dirty || asbestosDetection['controls'].accuracy_localization.touched)" class="invalid-feedback">
                        {{ getErrorMessage("accuracy_localization") }}
                      </div>
                    </div>

                    <!-- IDENTIFIER -->
                    <div class="form-group">
                      <label for="validationIdentifier" required>Identifiant</label>
                      <input type="text" formControlName="identifier" class="form-control" id="validationIdentifier" placeholder="Identifiant" [ngClass]="{ 'is-invalid': asbestosDetection['controls'].identifier.invalid && (asbestosDetection['controls'].identifier.dirty || asbestosDetection['controls'].identifier.touched) }">
                      <div *ngIf="asbestosDetection['controls'].identifier.invalid && (asbestosDetection['controls'].identifier.dirty || asbestosDetection['controls'].identifier.touched)" class="invalid-feedback">
                        {{ getErrorMessage("identifier") }}
                      </div>
                    </div>

                    <!-- DESCRIPTION -->
                    <div class="form-group">
                      <label for="validationDescription" required>Description</label>
                      <input type="text" formControlName="description" class="form-control" id="validationDescription" placeholder="Description" [ngClass]="{ 'is-invalid': asbestosDetection['controls'].description.invalid && (asbestosDetection['controls'].description.dirty || asbestosDetection['controls'].description.touched) }">
                      <div *ngIf="asbestosDetection['controls'].description.invalid && (asbestosDetection['controls'].description.dirty || asbestosDetection['controls'].description.touched)" class="invalid-feedback">
                        {{ getErrorMessage("description") }}
                      </div>
                    </div>

                    <!-- CONSERVATION STATE -->
                    <div class="form-group">
                      <label for="validationConservationState" required>État de conservation</label>
                      <input type="text" formControlName="conservation_state" class="form-control" id="validationConservationState" placeholder="État de conservation" [ngClass]="{ 'is-invalid': asbestosDetection['controls'].conservation_state.invalid && (asbestosDetection['controls'].conservation_state.dirty || asbestosDetection['controls'].conservation_state.touched) }">
                      <div *ngIf="asbestosDetection['controls'].conservation_state.invalid && (asbestosDetection['controls'].conservation_state.dirty || asbestosDetection['controls'].conservation_state.touched)" class="invalid-feedback">
                        {{ getErrorMessage("conservation_state") }}
                      </div>
                    </div>

                    <!-- RECOMMENDED MEASURES -->
                    <div class="form-group">
                      <label for="validationRecommendedMeasures" required>Mesure préconisée</label>
                      <select class="form-control" data-toggle="select" formControlName="recommended_measures" id="validationRecommendedMeasures" (change)="changeRecommendedMeasures()" [ngClass]="{ 'is-invalid': asbestosDetection['controls'].recommended_measures.invalid && (asbestosDetection['controls'].recommended_measures.dirty || asbestosDetection['controls'].recommended_measures.touched) }">
                        <option [value]="null">Sélectionnez une mesure préconisée</option>
                        <option value="Évaluation périodique">Évaluation périodique</option>
                        <option value="Mesure d'empoussièrement">Mesure d'empoussièrement</option>
                        <option value="Travaux de retrait ou de confinement">Travaux de retrait ou de confinement</option>
                        <option value="Actions correctives de 1er niveau">Actions correctives de 1er niveau</option>
                        <option value="Actions correctives de 2ième niveau">Actions correctives de 2ième niveau</option>
                        <option value="Non précisé">Non précisé</option>
                      </select>
                      <div *ngIf="asbestosDetection['controls'].recommended_measures.invalid && (asbestosDetection['controls'].recommended_measures.dirty || asbestosDetection['controls'].recommended_measures.touched)" class="invalid-feedback">
                        {{ getErrorMessage("recommended_measures") }}
                      </div>
                    </div>

                    <!-- PERIODICITY -->
                    <div class="form-group">
                      <label for="validationPeriodicity" required>Périodicité</label>
                      <select class="form-control" data-toggle="select" formControlName="periodicity" id="validationPeriodicity"
                              [ngClass]="{ 'is-invalid': asbestosDetection['controls'].periodicity.invalid && (asbestosDetection['controls'].periodicity.dirty || asbestosDetection['controls'].periodicity.touched) }">
                        <option [value]="null">Sélectionnez une périodicitié</option>
                        <option value="3 mois">3 mois</option>
                        <option value="1 année(s)">1 année(s)</option>
                        <option value="2 année(s)">2 année(s)</option>
                        <option value="3 année(s)">3 année(s)</option>
                        <option value="4 année(s)">4 année(s)</option>
                        <option value="5 année(s)">5 année(s)</option>
                        <option value="10 année(s)">10 année(s)</option>
                      </select>
                      <div *ngIf="asbestosDetection['controls'].periodicity.invalid && (asbestosDetection['controls'].periodicity.dirty || asbestosDetection['controls'].periodicity.touched)" class="invalid-feedback">
                        {{ getErrorMessage("periodicity") }}
                      </div>
                    </div>

                    <!-- RESULT -->
                    <div class="form-group">
                      <label for="validationResult">Résultat</label>
                      <textarea formControlName="result" class="form-control" id="validationResult" placeholder="Résultat" rows="5"></textarea>
                    </div>

                    <!-- ADD FILE-->
                    <div class="form-group" *ngIf="isNew || canUpdate">
                      <label for="inputFile">Documents</label>

                      <div class="dropzone dropzone-multiple dz-clickable" appDragDrop (dropHandler)="onLocalizationFileChange(i, $event)">
                        <div class="dz-default dz-message" (click)="chooseLocalizationFile(i)">
                          <span>Déposer vos fichiers ici pour télécharger</span>
                        </div>
                        <input [id]="'inputFileLocalization_' + i" (change)="onLocalizationFileChange(i, $event)" type="file" [hidden]="true" multiple>
                      </div>
                    </div>

                    <!-- LIST OF FILES -->
                    <div class="card" *ngFor="let file of localizationFiles[i]; let index = index">
                      <div class="card-body">
                        <div class="d-flex align-content-center justify-content-between">
                          <h4 class="card-title mb-0 name align-self-center">
                            {{ file.name + '.' + file.extension }}
                          </h4>

                          <a class="href tooltipHtml" data-placement="bottom" title="Supprimer" (click)="removeLocalizationFile(i, index)" *ngIf="isNew || canUpdate">
                            <i class="fe fe-trash"></i>
                          </a>
                        </div>
                      </div>
                    </div>

                  </div>

                  <button class="btn btn-primary w-50 d-flex justify-content-center align-items-center m-auto" (click)="deleteLocalization(i)" *ngIf="isNew || canUpdate">
                    Supprimer la localisation
                  </button>
                </div>
              </div>
            </div>

            <button class="btn btn-primary w-100 mb-4" (click)="addLocalization()" *ngIf="isNew || canUpdate">
              Ajouter une localisation
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div modal-footer class="w-100" *ngIf="isNew || canUpdate">
    <button class="btn btn-primary w-100" (click)="isNew ? createAsbestosDetection() : updateAsbestosDetection()" [ngClass]="{ 'is-loading is-loading-sm': loader }" [disabled]="loader || asbestosDetectionForm.invalid">
      {{ isNew ? 'Créer' : 'Mettre à jour' }}
    </button>
  </div>
</app-modal-right>
