import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AllSanitaryContributorsModule } from './all-sanitary-contributors/all-sanitary-contributors.module';

import { UpdateSanitaryContributorModule } from './update-sanitary-contributor/update-sanitary-contributor.module';
import { UploadSanitaryContributorFileModule } from './upload-sanitary-contributor-file/upload-sanitary-contributor-file.module';

import { SanitaryContributorsRequestService } from '../../services/sanitary-contributors.request.service';

@NgModule({
  imports: [
    CommonModule,
    AllSanitaryContributorsModule,
    UpdateSanitaryContributorModule,
    UploadSanitaryContributorFileModule,
  ],
  providers: [
    SanitaryContributorsRequestService
  ]
})
export class SanitaryContributorsModule {}
