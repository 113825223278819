import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AllSanitaryLegionellasModule } from './all-sanitary-legionellas/all-sanitary-legionellas.module';
import { SanitaryLegionellasRequestService } from '../../../services/sanitary-legionellas.request.service';
import { UpdateSanitaryLegionellaModule } from './update-sanitary-legionella/update-sanitary-legionella.module';
import { UploadSanitaryLegionellaFileModule } from './upload-sanitary-legionella-file/upload-sanitary-legionella-file.module';


@NgModule({
  imports: [
    CommonModule,
    AllSanitaryLegionellasModule,
    UpdateSanitaryLegionellaModule,
    UploadSanitaryLegionellaFileModule
  ],
  providers: [
      SanitaryLegionellasRequestService
  ]
})
export class SanitaryLegionellasModule {}
