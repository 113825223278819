<app-modal-right (eventOnClose)="closeModal()" #pool>{{ bindModal(pool) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">{{ isNew ? 'Création' : canUpdate ? 'Mise à jour' : 'Consultation' }}</h6>

        <h1 class="header-title">Piscine</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <form [formGroup]="sanitaryPoolForm" [hidden]="loaderContent">
      <!-- DESCRIPTION -->
      <div class="form-group">
        <label for="validationName" required>Description</label>
        <input type="text" formControlName="description" class="form-control" id="validationName" placeholder="Description" [ngClass]="{ 'is-invalid': description.invalid && (description.dirty || description.touched) }">
      </div>

      <!-- DOCUMENTS -->
      <h2 class="header-title mb-3">Documents</h2>
      <div class="form-group" *ngIf="isNew || canUpdate">
        <div class="dropzone dropzone-multiple dz-clickable" appDragDrop (dropHandler)="onFileChange($event)">
          <div class="dz-default dz-message" (click)="chooseFile()">
            <span>Déposer vos fichiers ici pour télécharger</span>
          </div>
          <input id="inputFile" (change)="onFileChange($event)" type="file" [hidden]="true" multiple>
        </div>
      </div>
      <div class="card" *ngFor="let file of files; let index = index">
        <div class="card-body">
          <div class="d-flex align-content-center justify-content-between">
            <h4 class="card-title mb-0 name align-self-center">
              {{ file.name + '.' + file.extension }}
            </h4>

            <a class="href tooltipHtml" data-placement="bottom" title="Supprimer" (click)="removeFile(index)" *ngIf="isNew || canUpdate">
              <i class="fe fe-trash"></i>
            </a>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div modal-footer class="w-100" *ngIf="isNew || canUpdate">
    <button class="btn btn-primary w-100" (click)="isNew ? createSanitaryPool() : updateSanitaryPool()" [ngClass]="{ 'is-loading is-loading-sm': loader }" [disabled]="loader || sanitaryPoolForm.invalid">
      {{ isNew ? 'Créer' : 'Mettre à jour' }}
    </button>
  </div>
</app-modal-right>
