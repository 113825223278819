import { Routes } from '@angular/router';

import { AllPrescriptionsComponent } from './all-prescriptions/all-prescriptions.component';
import { UpdatePrescriptionComponent } from './update-prescription/update-prescription.component';
import { UpdatePrescriptionStateComponent } from './update-prescription-state/update-prescription-state.component';
import { UploadPrescriptionFileComponent } from './upload-prescription-file/upload-prescription-file.component';

import { UpdateInterventionComponent } from '../interventions/update-intervention/update-intervention.component';

export const prescriptionsRoutes: Routes = [
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/commission/:commission_id/prescriptions',
    component: AllPrescriptionsComponent,
    children: [
      {
        path: 'create',
        component: UpdatePrescriptionComponent
      },
      {
        path: ':prescription_id',
        component: UpdatePrescriptionComponent
      },
      {
        path: ':prescription_id/state',
        component: UpdatePrescriptionStateComponent
      },
      {
        path: ':prescription_id/intervention/create',
        component: UpdateInterventionComponent
      },
    ]
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/commission/:commission_id/prescriptions/:prescription_id/files',
    component: AllPrescriptionsComponent,
    children: [
      {
        path: 'create',
        component: UploadPrescriptionFileComponent
      },
      {
        path: ':file_id',
        component: UploadPrescriptionFileComponent
      }
    ]
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/expertises/:commission_id/prescriptions',
    component: AllPrescriptionsComponent,
    children: [
      {
        path: 'create',
        component: UpdatePrescriptionComponent
      },
      {
        path: ':prescription_id',
        component: UpdatePrescriptionComponent
      },
      {
        path: ':prescription_id/state',
        component: UpdatePrescriptionStateComponent
      },
      {
        path: ':prescription_id/intervention/create',
        component: UpdateInterventionComponent
      },
    ]
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/expertises/:commission_id/prescriptions/:prescription_id/files',
    component: AllPrescriptionsComponent,
    children: [
      {
        path: 'create',
        component: UploadPrescriptionFileComponent
      },
      {
        path: ':file_id',
        component: UploadPrescriptionFileComponent
      }
    ]
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/agencies/:commission_id/prescriptions',
    component: AllPrescriptionsComponent,
    children: [
      {
        path: 'create',
        component: UpdatePrescriptionComponent
      },
      {
        path: ':prescription_id',
        component: UpdatePrescriptionComponent
      },
      {
        path: ':prescription_id/state',
        component: UpdatePrescriptionStateComponent
      },
      {
        path: ':prescription_id/intervention/create',
        component: UpdateInterventionComponent
      },
    ]
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/agencies/:commission_id/prescriptions/:prescription_id/files',
    component: AllPrescriptionsComponent,
    children: [
      {
        path: 'create',
        component: UploadPrescriptionFileComponent
      },
      {
        path: ':file_id',
        component: UploadPrescriptionFileComponent
      }
    ]
  },
];
