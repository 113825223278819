import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { NotifierService } from 'angular-notifier';

import { environment } from '../../../../../environments/environment';

import { AuthService } from '../../../../core/auth/auth.service';

import { RisksRequestService } from '../../../../services/risks.request.service';
import { RegistriesRequestService } from '../../../../services/registries.request.service';
import { EstablishmentsRequestService } from '../../../../services/establishments.request.service';
import { CustomNotifierService } from '../../../../services/custom.notifier.service';

import Risk from '../../../../../shared/interfaces/risk';
import Unity from '../../../../../shared/interfaces/unity';
import Registry from '../../../../../shared/interfaces/registry';
import Establishment from '../../../../../shared/interfaces/establishment';

interface LRisk {
  unity: Unity;
  search?: string;
  sort?: string;
  order?: string;
  limit?: number;
  page?: number;
  pages?: number;
  count?: number;
  risks?: Risk[];
  loader?: boolean;
}

declare function Flatpickr(): any;

@Component({
  selector: 'app-all-risks',
  templateUrl: './all-risks.component.html',
  styleUrls: ['./all-risks.component.scss']
})
export class AllRisksComponent implements OnInit, AfterViewInit, OnDestroy {
  changeEnterprise: Subscription;
  environment = environment;
  JSON = JSON;

  requestInProgress = new Subject();
  requestCount = 0;

  loader = false;

  isAdmin = false;

  canUpdate = false;
  canCreate = false;
  canDelete = false;
  canLog = false;

  activeTabsList = '';
  lastUpdateDate = '';

  errors = {};

  pageName: string;
  previousPageName: string[] = [];
  previousPageRoute: string[] = [];
  currentRoute = '';

  risks: LRisk[] = [];
  registry: Registry;
  establishment: Establishment;

  count = 0;
  pages = 1;

  search = '';
  order = 'asc';
  limit = 12;
  page = 1;

  timeout: any;
  view = '';

  modalConfirmData = null;

  show_inactive_unities = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private risksService: RisksRequestService,
    private registriesService: RegistriesRequestService,
    private establishmentsService: EstablishmentsRequestService,
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loader = this.requestCount > 0;
    });

    const is_inactive_unities = JSON.parse(localStorage.getItem('show_inactive_unities'));
    if (is_inactive_unities !== null) {
      this.show_inactive_unities = is_inactive_unities;
    } else {
      localStorage.setItem('show_inactive_unities', JSON.stringify(this.show_inactive_unities));
    }

    this.changeEnterprise = this.authService.getEnterprise().subscribe(() => {
      this.getAllRisks();
    });

    this.pageName = 'Risques';
    this.currentRoute = '/' + this.activatedRoute.snapshot.url.join('/') + '/';

    if (this.activatedRoute.snapshot.params.registry_id) {
      this.getRegistry();
    }
    if (this.activatedRoute.snapshot.params.establishment_id) {
      this.getEstablishment();
    }
    if (this.authService.loaded) {
      this.getAllRisks();
    }
    this.createBreadcrumbs();
  }

  ngAfterViewInit() {
    this.modalConfirmData.eventOnClose.subscribe(event => {
      if (event) {
        this.requestInProgress.next(true);
        this.risksService.deleteRisk(this.activatedRoute.snapshot.params.establishment_id, event).subscribe(response => {
          if (response['success']) {
            this.refreshData();
            CustomNotifierService.getSuccessDelete(this.notifier);
          } else if (response['errors']) {
            CustomNotifierService.getErrors(response['errors'], this.notifier);
          }
          this.requestInProgress.next(false);
        });
      }
    });
  }

  ngOnDestroy() {
    this.changeEnterprise.unsubscribe();

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  searchData(item) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.page = 1;
      this.getRisks(item);
    }, 500);
  }

  refreshData(item: LRisk = null) {
    if (item) {
      this.getRisks(item);
    } else {
      this.getAllRisks();
    }
  }

  getRegistry() {
    this.requestInProgress.next(true);
    this.registriesService.getRegistry(this.activatedRoute.snapshot.params.registry_id).subscribe(response => {
      if (response['success']) {
        this.registry = response['registry'];
        this.createBreadcrumbs();
      } else if (response['errors']) {
        this.errors = response['errors'];
      }
      this.requestInProgress.next(false);
    });
  }

  getEstablishment() {
    this.requestInProgress.next(true);
    this.establishmentsService.getMyEstablishment(this.activatedRoute.snapshot.params.establishment_id).subscribe(response => {
      if (response['success']) {
        this.establishment = response['establishment'];
        this.createBreadcrumbs();
      } else if (response['errors']) {
        this.errors = response['errors'];
      }
      this.requestInProgress.next(false);
    });
  }

  getAllRisks() {
    let request_unities;
    this.requestInProgress.next(true);
    if (this.show_inactive_unities) {
      request_unities = this.risksService.getAllMyRegistryEstablishmentUnities(
          '', 'RiskUnityWorks.name', 'asc', 0, 1,
          this.activatedRoute.snapshot.params.registry_id, this.activatedRoute.snapshot.params.establishment_id);
    } else {
      request_unities = this.risksService.getMyRegistryEstablishmentUnities(
          '', 'RiskUnityWorks.name', 'asc', 0, 1,
          this.activatedRoute.snapshot.params.registry_id, this.activatedRoute.snapshot.params.establishment_id);
    }

    request_unities.subscribe(responseUnities => {
      if (responseUnities['success']) {
        this.lastUpdateDate = responseUnities['date'];
        responseUnities['unities'].forEach((unity: Unity) => {
          this.requestInProgress.next(true);
          let request;
          let found = this.risks.find(risk => {
            return risk.unity.id === unity.id;
          });
          if (found) {
            request = this.risksService.getRisksWork(found.search, found.sort, found.order, found.limit, found.page,
              this.activatedRoute.snapshot.params.establishment_id, unity.id);
          } else {
            request = this.risksService.getRisksWork('', 'Risks.id', 'asc', 12, 1,
              this.activatedRoute.snapshot.params.establishment_id, unity.id);
          }
          request.subscribe(responseRisks => {
            if (responseRisks['success']) {
              this.canCreate = responseRisks['canCreate'];
              this.canUpdate = responseRisks['canUpdate'];
              this.canDelete = responseRisks['canDelete'];
              this.canLog = responseRisks['canLog'];

              found = this.risks.find(risk => {
                return risk.unity.id === unity.id;
              });
              if (found) {
                found.risks = responseRisks['risks'];
                found.risks.forEach( element => {
                  if (element.description && element.description.length > 50) {
                    element.description = element.description.substr(0, 50) + '...';
                  }
                  if (element.current_protection && element.current_protection.length > 50) {
                    element.current_protection = element.current_protection.substr(0, 50) + '...';
                  }
                  if (element.todo_protection && element.todo_protection.length > 50) {
                    element.todo_protection = element.todo_protection.substr(0, 50) + '...';
                  }
                });
              } else {
                this.risks.push({
                  unity: unity,
                  count: responseRisks['count'],
                  pages: responseRisks['pages'],
                  page: 1,
                  limit: 12,
                  order: 'asc',
                  sort: 'Risks.family',
                  search: '',
                  risks: responseRisks['risks'],
                  loader: false
                });
                this.risks.forEach( element => {
                  element.risks.forEach( risk => {
                    if (risk.description && risk.description.length > 50) {
                      risk.description = risk.description.substr(0, 50) + '...';
                    }
                    if (risk.current_protection && risk.current_protection.length > 50) {
                      risk.current_protection = risk.current_protection.substr(0, 50) + '...';
                    }
                    if (risk.todo_protection && risk.todo_protection.length > 50) {
                      risk.todo_protection = risk.todo_protection.substr(0, 50) + '...';
                    }
                  });
                });
                this.risks.sort(this.sort);
              }
            } else {
              CustomNotifierService.getErrors(responseRisks['errors'], this.notifier);
            }
            this.requestInProgress.next(false);
            if (!this.activatedRoute.children.length) {
              setTimeout(() => {
                Flatpickr();
              }, 250);
            }
          });
        });
      } else {
        CustomNotifierService.getErrors(responseUnities['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  getRisks(risk: LRisk) {
    risk.loader = true;
    risk.search = this.search;
    this.risksService.getRisksWork(risk.search, risk.sort, risk.order, risk.limit, risk.page,
      this.activatedRoute.snapshot.params.establishment_id, risk.unity.id).subscribe(response => {
      if (response['success']) {
        risk.risks = response['risks'];
        risk.risks.forEach(element => {
          if (element.description && element.description.length > 50) {
            element.description = element.description.substr(0, 50) + '...';
          }
          if (element.current_protection && element.current_protection.length > 50) {
            element.current_protection = element.current_protection.substr(0, 50) + '...';
          }
          if (element.todo_protection && element.todo_protection.length > 50) {
            element.todo_protection = element.todo_protection.substr(0, 50) + '...';
          }
        });
        risk.pages = response['pages'];
      }
      risk.loader = false;
    });
  }

  filter(item: LRisk, property: string) {
    if (item.sort === property) {
      if (item.order === 'asc') {
        item.order = 'desc';
      } else {
        item.order = 'asc';
      }
    } else {
      item.sort = property;
      item.order = 'asc';
    }

    this.refreshData(item);
  }

  dispatchPagination(data) {
    if (data.action === 'total_elements') {
      this.refreshPage(data.item, data.value);
    } else if (data.action === 'change_page') {
      this.changePage(data.item, data.value);
    }
  }

  refreshPage(item: LRisk, limit: number) {
    item.limit = limit;
    item.page = 1;
    this.refreshData(item);
  }

  changePage(item: LRisk, page: number) {
    if (page >= 1 && page <= item.pages) {
      item.page = page;
      this.refreshData(item);
    }
  }

  createBreadcrumbs() {
    this.previousPageName = [];
    this.previousPageRoute = [];

    this.previousPageName.push('Tableau de bord');
    this.previousPageRoute.push('/');

    if (this.activatedRoute.snapshot.params.registry_id) {
      this.previousPageName.push(this.registry ? this.registry.name : 'Tableau de bord registre');
      this.previousPageRoute.push('/dashboard-registry/' + this.activatedRoute.snapshot.params.registry_id);
    }

    if (this.activatedRoute.snapshot.params.establishment_id) {
      this.previousPageName.push(this.establishment ? this.establishment.name : 'Tableau de bord établissement');
      this.previousPageRoute.push('/dashboard-registry/' + this.activatedRoute.snapshot.params.registry_id +
        '/establishment/' + this.activatedRoute.snapshot.params.establishment_id);
    }
  }

  sort = (a, b) => {
    if (a.unity.name < b.unity.name) {
      return -1;
    } else if (a.unity.name > b.unity.name) {
      return 1;
    } else {
      return 0;
    }
  }

  bindModalConfirmData(modal) {
    this.modalConfirmData = modal;
  }
}
