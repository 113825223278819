import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Subject } from 'rxjs';
import { NgbTimeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { NgbTimeStringAdapter } from '../../../../core/utils/ngb-time-string-adapter';

import { CustomNotifierService } from '../../../../services/custom.notifier.service';

import { ModalRightComponent } from '../../../../core/modal-right/modal-right.component';

import { UsersRequestService } from '../../../../services/users.request.service';
import { DocumentsRequestService } from '../../../../services/documents.request.service';
import { SanitaryAnalyzesRequestService } from '../../../../services/sanitary-analyzes.request.service';
import { SanitaryAnalyzeFramesRequestService } from '../../../../services/sanitary-analyze-frames.request.service';
import { AllSanitaryAnalyzeFramesComponent } from '../all-sanitary-analyze-frames/all-sanitary-analyze-frames.component';

import SanitaryAnalyze from '../../../../../shared/interfaces/sanitary_analyze';

import * as _ from 'lodash';

declare function Flatpickr(): any;
declare function Dropzones(): any;

@Component({
  selector: 'app-update-sanitary-analyze-frame',
  templateUrl: './update-sanitary-analyze-frame.component.html',
  styleUrls: ['./update-sanitary-analyze-frame.component.scss'],
  providers: [{ provide: NgbTimeAdapter, useClass: NgbTimeStringAdapter }]
})
export class UpdateSanitaryAnalyzeFrameComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('analyzeFrame') public modalSanitaryAnalyzeFrame: ModalRightComponent;

  counter = Array;
  canCreate = false;
  canUpdate = false;

  timeout: any;

  requestInProgress = new Subject();
  requestCount = 0;

  sanitaryAnalyze: SanitaryAnalyze;

  sanitaryAnalyzeFrameForm: FormGroup;
  title = new FormControl('', Validators.required);
  date = new FormControl('', Validators.required);
  descriptions = new FormControl('');
  type = new FormControl('');
  detected_anomalies = new FormControl('');
  interventions = new FormControl('');

  selectedDescription = null;
  errors = {};

  weeks = [
    'Semaine 1', 'Semaine 2', 'Semaine 3', 'Semaine 4', 'Semaine 5', 'Semaine 6', 'Semaine 7',
    'Semaine 8', 'Semaine 9', 'Semaine 10', 'Semaine 11', 'Semaine 12', 'Semaine 13', 'Semaine 14', 'Semaine 15', 'Semaine 16',
    'Semaine 17', 'Semaine 18', 'Semaine 19', 'Semaine 20', 'Semaine 21', 'Semaine 22', 'Semaine 23', 'Semaine 24', 'Semaine 25',
    'Semaine 26', 'Semaine 27', 'Semaine 28', 'Semaine 29', 'Semaine 30', 'Semaine 31', 'Semaine 32', 'Semaine 33', 'Semaine 34',
    'Semaine 35', 'Semaine 36', 'Semaine 37', 'Semaine 38', 'Semaine 39', 'Semaine 40', 'Semaine 41', 'Semaine 42', 'Semaine 43',
    'Semaine 44', 'Semaine 45', 'Semaine 46', 'Semaine 47', 'Semaine 48', 'Semaine 49', 'Semaine 50', 'Semaine 51', 'Semaine 52'
  ];
  months = [
    'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
  ];
  quarters = [
    '1er trimestre', '2ème trimestre', '3ème trimestre', '4ème trimestre'
  ];
  biannuals = [
    '1er semestre', '2ème semestre'
  ];

  titles = [
    'Date', 'Résultat', 'Commentaire'
  ];

  listItems = [];

  periodicity = '';

  isNew = true;
  loader = false;
  loaderContent = false;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private usersService: UsersRequestService,
    private documentsService: DocumentsRequestService,
    private sanitaryAnalyzesServices: SanitaryAnalyzesRequestService,
    private allSanitaryAnalyzeFrames: AllSanitaryAnalyzeFramesComponent,
    private sanitaryAnalyzeFramesServices: SanitaryAnalyzeFramesRequestService,
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loaderContent = this.requestCount > 0;
    });

    this.sanitaryAnalyzeFrameForm = this.formBuilder.group({
      date: this.date,
      title: this.title,
      type: this.type,
      descriptions: this.formBuilder.array([]),
      detected_anomalies: this.detected_anomalies,
      interventions: this.interventions,
    });

    if (this.activatedRoute.snapshot.params.sanitary_analyze_frame_id) {
      this.isNew = false;
      this.getSanitaryAnalyzeFrame();
    } else {
      this.getSanitaryAnalyze();
      setTimeout(() => {
        Flatpickr();
      }, 1);
    }
  }

  ngAfterViewInit() {
    Dropzones();
    this.modalSanitaryAnalyzeFrame.open();
  }

  ngOnDestroy() {
    const flatPicker = document.getElementsByClassName('flatpickr-calendar') as HTMLCollection;
    for (let i = flatPicker.length; i >= 0; i--) {
      if (flatPicker[i]) {
        flatPicker[i].remove();
      }
    }

    const dropZones = document.getElementsByClassName('dz-hidden-input') as HTMLCollection;
    for (let i = 0; i < dropZones.length; i++) {
      dropZones[i].remove();
    }

    this.clearTooltip();

    this.modalSanitaryAnalyzeFrame.close();
  }
  clearTooltip() {
    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  getSanitaryAnalyze() {
    this.requestInProgress.next(true);
    this.sanitaryAnalyzesServices.getSanitaryAnalyze(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.activatedRoute.snapshot.parent.params.sanitary_analyze_id,
    ).subscribe(response => {
      if (response['success']) {
        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];

        this.sanitaryAnalyze = response['sanitaryAnalyze'];
        this.periodicity = this.sanitaryAnalyze['periodicity'];
        this.type.setValue(this.periodicity);

        if (this.periodicity === 'Hebdomadaire') {
          const control = <FormArray>this.sanitaryAnalyzeFrameForm.controls['descriptions'];
          this.weeks.forEach((week) => {
            this.listItems.push({title: week});
            const array = this.formBuilder.array([]);
            this.sanitaryAnalyze['descriptions'].forEach((description) => {
              const arrayHeader = this.formBuilder.array([]);
              const arrayBody = this.formBuilder.array([]);
              this.titles.forEach(title => {
                arrayBody.push(this.formBuilder.group({
                  title: title,
                  comment: this.formBuilder.control(null),
                }));
              });
              description.pointLists.forEach(pointList => {
                arrayHeader.push(this.formBuilder.group({
                  lineId: pointList.lineId,
                  title: pointList.value,
                  comments: _.cloneDeep(arrayBody),
                }));
              });
              array.push(this.formBuilder.group({
                lineId: description.lineId,
                title: description.title,
                pointLists: _.cloneDeep(arrayHeader),
              }));
            });
            control.push(this.formBuilder.group({
              title: week,
              values: array,
            }));
          });
        } else if (this.periodicity === 'Mensuelle') {
          const control = <FormArray>this.sanitaryAnalyzeFrameForm.controls['descriptions'];
          this.months.forEach(month => {
            this.listItems.push({title: month});
            const array = this.formBuilder.array([]);
            this.sanitaryAnalyze['descriptions'].forEach(description => {
              const arrayHeader = this.formBuilder.array([]);
              const arrayBody = this.formBuilder.array([]);
              this.titles.forEach(title => {
                arrayBody.push(this.formBuilder.group({
                  title: title,
                  comment: this.formBuilder.control(null),
                }));
              });
              description.pointLists.forEach(pointList => {
                arrayHeader.push(this.formBuilder.group({
                  lineId: pointList.lineId,
                  title: pointList.value,
                  comments: _.cloneDeep(arrayBody),
                }));
              });
              array.push(this.formBuilder.group({
                lineId: description.lineId,
                title: description.title,
                pointLists: _.cloneDeep(arrayHeader),
              }));
            });
            control.push(this.formBuilder.group({
              title: month,
              values: array,
            }));
          });
        } else if (this.periodicity === 'Trimestrielle') {
          const control = <FormArray>this.sanitaryAnalyzeFrameForm.controls['descriptions'];
          this.quarters.forEach(quarter => {
            this.listItems.push({title: quarter});
            const array = this.formBuilder.array([]);
            this.sanitaryAnalyze['descriptions'].forEach(description => {
              const arrayHeader = this.formBuilder.array([]);
              const arrayBody = this.formBuilder.array([]);
              this.titles.forEach(title => {
                arrayBody.push(this.formBuilder.group({
                  title: title,
                  comment: this.formBuilder.control(null),
                }));
              });
              description.pointLists.forEach(pointList => {
                arrayHeader.push(this.formBuilder.group({
                  lineId: pointList.lineId,
                  title: pointList.value,
                  comments: _.cloneDeep(arrayBody),
                }));
              });
              array.push(this.formBuilder.group({
                lineId: description.lineId,
                title: description.title,
                pointLists: _.cloneDeep(arrayHeader),
              }));
            });
            control.push(this.formBuilder.group({
              title: quarter,
              values: array,
            }));
          });
        } else if (this.periodicity === 'Semestrielle') {
          const control = <FormArray>this.sanitaryAnalyzeFrameForm.controls['descriptions'];
          this.sanitaryAnalyze['descriptions'].forEach(description => {
            this.listItems.push({title: description.title});
            const array = this.formBuilder.array([]);
            const arrayHeader = this.formBuilder.array([]);
            this.titles.forEach(title => {
              const secondArray = this.formBuilder.array([]);
              this.biannuals.forEach(biannual => {
                secondArray.push(this.formBuilder.group({
                  title: biannual,
                  comment: this.formBuilder.control(null),
                }));
              });
              array.push(this.formBuilder.group({
                title: title,
                comments: secondArray,
              }));
            });
            description.pointLists.forEach(pointList => {
              arrayHeader.push(this.formBuilder.group({
                lineId: pointList.lineId,
                title: pointList.value,
                semesters: _.cloneDeep(array),
              }));
            });
            control.push(this.formBuilder.group({
              lineId: description.lineId,
              title: description.title,
              pointLists: arrayHeader,
            }));
          });
        } else if (this.periodicity === 'Annuelle') {
          const control = <FormArray>this.sanitaryAnalyzeFrameForm.controls['descriptions'];
          this.sanitaryAnalyze['descriptions'].forEach(description => {
            const arrayHeader = this.formBuilder.array([]);
            const arrayBody = this.formBuilder.array([]);
            this.listItems.push({title: description.title});
            this.titles.forEach(title => {
              arrayBody.push(this.formBuilder.group({
                title: title,
                comment: this.formBuilder.control(null),
              }));
            });
            description.pointLists.forEach(pointList => {
              arrayHeader.push(this.formBuilder.group({
                lineId: pointList.lineId,
                title: pointList.value,
                comments: _.cloneDeep(arrayBody),
              }));
            });
            control.push(this.formBuilder.group({
              lineId: description.lineId,
              title: description.title,
              pointLists: _.cloneDeep(arrayHeader),
            }));
          });
        }
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      setTimeout(() => {
        Flatpickr();
      }, 1);
      this.requestInProgress.next(false);
    });
  }

  // WWEEkLY / MONTHLY / QUARTERLY FORM
  addDescriptions(description, indexDescription) {
    const control = <FormArray>this.sanitaryAnalyzeFrameForm.controls['descriptions'];
    this.listItems.push({title: description.title});
    if (this.periodicity === 'Hebdomadaire' || this.periodicity === 'Mensuelle' || this.periodicity === 'Trimestrielle') {
      control.push(this.formBuilder.group({
        title: this.formBuilder.control(''),
        values: this.formBuilder.array([]),
      }));
      description.values.forEach((value, indexValue) => {
        this.addValues(indexDescription, value, indexValue);
      });
    } else if (this.periodicity === 'Semestrielle') {
      control.push(this.formBuilder.group({
        lineId:  this.formBuilder.control(''),
        title: this.formBuilder.control(''),
        pointLists: this.formBuilder.array([]),
      }));
      description.pointLists.forEach((pointList, indexPointList) => {
        this.addSemesterPointLists(indexDescription, pointList, indexPointList);
      });
    } else if (this.periodicity === 'Annuelle') {
      control.push(this.formBuilder.group({
        lineId:  this.formBuilder.control(''),
        title: this.formBuilder.control(''),
        pointLists: this.formBuilder.array([]),
      }));
      description.pointLists.forEach((pointList, indexPointList) => {
        this.addAnnualPointLists(indexDescription, pointList, indexPointList);
      });
    }
  }
  addValues(indexDescription, value, indexValue) {
    const control = <FormArray>this.sanitaryAnalyzeFrameForm.controls['descriptions']['controls'][indexDescription].controls['values'];
    control.push(this.formBuilder.group({
      lineId:  this.formBuilder.control(''),
      title: this.formBuilder.control(''),
      pointLists: this.formBuilder.array([]),
    }));
    value.pointLists.forEach((pointList, indexPointList) => {
      this.addPointLists(indexDescription, indexValue, pointList, indexPointList);
    });
  }
  addPointLists(indexDescription, indexValue, pointList, indexPointList) {
    const control = <FormArray>this.sanitaryAnalyzeFrameForm.controls['descriptions']['controls'][indexDescription]
      .controls['values']['controls'][indexValue].controls['pointLists'];

    control.push(this.formBuilder.group({
      lineId:  this.formBuilder.control(''),
      title: this.formBuilder.control(null),
      comments: this.formBuilder.array([]),
    }));
    pointList.comments.forEach(() => {
      this.addComments(indexDescription, indexValue, indexPointList);
    });
    if (!pointList.comments.length) {
      const updateValue = <FormArray>this.sanitaryAnalyzeFrameForm.controls['descriptions']['controls'][indexDescription]
        .controls['values']['controls'][indexValue].controls['pointLists']['controls'][indexPointList].controls['comments'];
      this.titles.forEach(title => {
        updateValue.push(this.formBuilder.group({
          title: title,
          comment: this.formBuilder.control(null),
        }));
      });
    }
  }
  addComments(indexDescription, indexValue, indexPointList) {
    const control = <FormArray>this.sanitaryAnalyzeFrameForm.controls['descriptions']['controls'][indexDescription]
      .controls['values']['controls'][indexValue].controls['pointLists']['controls'][indexPointList].controls['comments'];

    control.push(this.formBuilder.group({
      title: this.formBuilder.control(null),
      comment: this.formBuilder.control(null)
    }));
  }
  // SEMESTER FORM
  addSemesterPointLists(indexDescription, pointList, indexPointList) {
    const control = <FormArray>this.sanitaryAnalyzeFrameForm.controls['descriptions']['controls'][indexDescription].controls['pointLists'];
    control.push(this.formBuilder.group({
      lineId:  this.formBuilder.control(''),
      title: this.formBuilder.control(''),
      semesters: this.formBuilder.array([]),
    }));
    pointList.semesters.forEach((semester, indexSemester) => {
      this.addSemesters(indexDescription, indexPointList, semester, indexSemester);
    });
    if (!pointList.semesters.length) {
      const updateValue = <FormArray>this.sanitaryAnalyzeFrameForm.controls['descriptions']['controls'][indexDescription]
        .controls['pointLists']['controls'][indexPointList].controls['semesters'];
      this.titles.forEach(title => {
        const secondArray = this.formBuilder.array([]);
        this.biannuals.forEach(biannual => {
          secondArray.push(this.formBuilder.group({
            title: biannual,
            comment: this.formBuilder.control(null),
          }));
        });
        updateValue.push(this.formBuilder.group({
          title: title,
          comments: secondArray,
        }));
      });
    }
  }
  addSemesters(indexDescription, indexPointList, semester, indexSemester) {
    const control = <FormArray>this.sanitaryAnalyzeFrameForm.controls['descriptions']['controls'][indexDescription]
      .controls['pointLists']['controls'][indexPointList].controls['semesters'];

    control.push(this.formBuilder.group({
      title: this.formBuilder.control(null),
      comments: this.formBuilder.array([]),
    }));
    semester.comments.forEach(() => {
      this.addSemesterComments(indexDescription, indexPointList, indexSemester);
    });
  }
  addSemesterComments(indexDescription, indexPointList, indexSemester) {
    const control = <FormArray>this.sanitaryAnalyzeFrameForm.controls['descriptions']['controls'][indexDescription]
      .controls['pointLists']['controls'][indexPointList].controls['semesters']['controls'][indexSemester].controls['comments'];

    control.push(this.formBuilder.group({
      title: this.formBuilder.control(null),
      comment: this.formBuilder.control(null)
    }));
  }
  // ANNUAL FORM
  addAnnualPointLists(indexDescription, pointList, indexPointList) {
    const control = <FormArray>this.sanitaryAnalyzeFrameForm.controls['descriptions']['controls'][indexDescription].controls['pointLists'];

    control.push(this.formBuilder.group({
      lineId:  this.formBuilder.control(''),
      title: this.formBuilder.control(null),
      comments: this.formBuilder.array([]),
    }));
    pointList.comments.forEach(() => {
      this.addAnnualComments(indexDescription, indexPointList);
    });
    if (!pointList.comments.length) {
      const updateValue = <FormArray>this.sanitaryAnalyzeFrameForm.controls['descriptions']['controls'][indexDescription]
        .controls['pointLists']['controls'][indexPointList].controls['comments'];

      this.titles.forEach(title => {
        updateValue.push(this.formBuilder.group({
          title: title,
          comment: this.formBuilder.control(null),
        }));
      });
    }
  }
  addAnnualComments(indexDescription, indexPointList) {
    const control = <FormArray>this.sanitaryAnalyzeFrameForm.controls['descriptions']['controls'][indexDescription]
      .controls['pointLists']['controls'][indexPointList].controls['comments'];

    control.push(this.formBuilder.group({
      title: this.formBuilder.control(null),
      comment: this.formBuilder.control(null)
    }));
  }

  getSanitaryAnalyzeFrame() {
    this.requestInProgress.next(true);
    this.sanitaryAnalyzeFramesServices.getSanitaryAnalyzeFrame(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.activatedRoute.snapshot.parent.params.sanitary_analyze_id,
      this.activatedRoute.snapshot.params.sanitary_analyze_frame_id,
    ).subscribe(response => {
      if (response['success']) {
        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];
        this.periodicity = response['sanitaryAnalyzeFrame']['type'];

        response['sanitaryAnalyzeFrame']['descriptions'].forEach((description, indexDescription) => {
          this.addDescriptions(description, indexDescription);
        });
        this.sanitaryAnalyzeFrameForm.patchValue(response['sanitaryAnalyzeFrame']);
        if (!this.canUpdate) {
          this.sanitaryAnalyzeFrameForm.disable();
        }
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      setTimeout(() => {
        Flatpickr();
      }, 1);
      this.requestInProgress.next(false);
    });
  }
  createSanitaryAnalyzeFrame() {
    let request;
    this.errors = {};
    this.loader = true;

    const data = {
      ...this.sanitaryAnalyzeFrameForm.value,
    };

    request = this.sanitaryAnalyzeFramesServices.createSanitaryAnalyzeFrame(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.activatedRoute.snapshot.parent.params.sanitary_analyze_id,
      data
    );

    request.subscribe(response => {
      if (response['success']) {
        this.isNew = false;
        this.loader = false;
        this.modalSanitaryAnalyzeFrame.close();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response['errors']) {
        this.loader = false;
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(response['errors'], this.sanitaryAnalyzeFrameForm, this.notifier);
      } else {
        this.loader = false;
      }
    });
  }
  updateSanitaryAnalyzeFrame() {
    let request;

    this.errors = {};
    this.loader = true;

    const data = {
      ...this.sanitaryAnalyzeFrameForm.value,
    };

    request = this.sanitaryAnalyzeFramesServices.updateSanitaryAnalyzeFrame(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.activatedRoute.snapshot.parent.params.sanitary_analyze_id,
      this.activatedRoute.snapshot.params.sanitary_analyze_frame_id,
      data
    );

    request.subscribe(response => {
      if (response['success']) {
        this.loader = false;
        this.modalSanitaryAnalyzeFrame.close();
        CustomNotifierService.getSuccessUpdate(this.notifier);
      } else if (response['errors']) {
        this.loader = false;
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      } else {
        this.loader = false;
      }
    });
  }

  closeModal() {
    this.router.navigate([this.activatedRoute.snapshot.parent.url.join('/')]).then(() => {
      this.allSanitaryAnalyzeFrames.refreshData();
    });
  }

  addFlatpickr() {
    setTimeout(() => {
      Flatpickr();
    }, 1);
  }
}
