import { Routes } from '@angular/router';

import { AllReportsComponent } from './all-reports/all-reports.component';
import { UpdateReportComponent } from './update-report/update-report.component';
import { UploadReportFileComponent } from './upload-report-file/upload-report-file.component';

import { ModalLogsComponent } from '../logs/modal-logs/modal-logs.component';

export const reportsRoutes: Routes = [
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/' +
      'installations/:installation_id/checkpoints/:checkpoint_id/reports',
    component: AllReportsComponent,
    children: [
      {
        path: 'create',
        component: UpdateReportComponent
      },
      {
        path: ':report_id',
        component: UpdateReportComponent
      },
      {
        path: ':report_id/logs',
        component: ModalLogsComponent
      }
    ]
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/' +
      'installations/:installation_id/checkpoints/:checkpoint_id/reports/:report_id/files',
    component: AllReportsComponent,
    children: [
      {
        path: 'create',
        component: UploadReportFileComponent
      },
      {
        path: ':file_id',
        component: UploadReportFileComponent
      }
    ]
  }
];
