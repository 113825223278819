import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Subject, Subscription } from 'rxjs';

import { environment } from '../../../../environments/environment';

import { AuthService } from '../../../core/auth/auth.service';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { InstallationsRequestService } from '../../../services/installations.request.service';
import { RegistriesRequestService } from '../../../services/registries.request.service';
import { EstablishmentsRequestService } from '../../../services/establishments.request.service';

import Registry from '../../../../shared/interfaces/registry';
import Establishment from '../../../../shared/interfaces/establishment';
import Installation from '../../../../shared/interfaces/installation';

@Component({
  selector: 'app-show-installation',
  templateUrl: './show-installation.component.html',
  styleUrls: ['./show-installation.component.scss']
})
export class ShowInstallationComponent implements OnInit, OnDestroy {
  changeEnterprise: Subscription;
  environment = environment;

  canUpdate = false;
  canCreate = false;

  requestInProgress = new Subject();
  requestCount = 0;

  loader = false;
  errors = {};

  pageName: string;
  previousPageName: string[] = [];
  previousPageRoute: string[] = [];

  registry: Registry;
  establishment: Establishment;
  installations: Installation[] = [];
  openedInstallation = -1;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private notifier: NotifierService,
    private installationsService: InstallationsRequestService,
    private registriesService: RegistriesRequestService,
    private establishmentsService: EstablishmentsRequestService
  ) {
  }

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loader = this.requestCount > 0;
    });

    this.changeEnterprise = this.authService.getEnterprise().subscribe(() => {
      this.getRegistry();
      this.getEstablishment();
      this.getInstallations();
      this.createBreadcrumbs();
    });

    if (this.authService.loaded) {
      this.getRegistry();
      this.getEstablishment();
      this.getInstallations();
      this.createBreadcrumbs();
    }
  }

  ngOnDestroy() {
    this.changeEnterprise.unsubscribe();

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  getRegistry() {
    this.requestInProgress.next(true);
    this.registriesService.getRegistry(this.activatedRoute.snapshot.params.registry_id).subscribe(response => {
      if (response['success']) {
        this.registry = response['registry'];
        this.createBreadcrumbs();
      } else if (response['errors']) {
        this.errors = response['errors'];
      }
      this.requestInProgress.next(false);
    });
  }

  getEstablishment() {
    this.requestInProgress.next(true);
    this.establishmentsService.getMyEstablishment(this.activatedRoute.snapshot.params.establishment_id).subscribe(response => {
      if (response['success']) {
        this.establishment = response['establishment'];
        this.createBreadcrumbs();
      } else if (response['errors']) {
        this.errors = response['errors'];
      }
      this.requestInProgress.next(false);
    });
  }

  getInstallations() {
    this.requestInProgress.next(true);

    const registry_id = this.activatedRoute.snapshot.params.registry_id;
    const establishment_id = this.activatedRoute.snapshot.params.establishment_id;
    this.installationsService.getMyRegistryEstablishmentInstallationsShow(registry_id, establishment_id).subscribe(response => {
      if (response['success']) {
        response['installations'].forEach(installation => {
          installation.collapsed = true;
        });
        this.installations = response['installations'];
      }
      this.requestInProgress.next(false);
    });
  }

  updateShow() {
    this.requestInProgress.next(true);

    const registry_id = this.activatedRoute.snapshot.params.registry_id;
    const establishment_id = this.activatedRoute.snapshot.params.establishment_id;

    this.installationsService.updateMyRegistryEstablishmentInstallationsShow(
      registry_id, establishment_id, this.installations).subscribe(response => {
      if (response['success']) {
        CustomNotifierService.getSuccessUpdate(this.notifier);
        this.getInstallations();
      } else if (response['errors']) {
        CustomNotifierService.getErrors(this.errors, this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  calcCheckedInstallation(keyInstallation) {
    let total = 0;
    if (this.installations) {
      this.installations[keyInstallation].establishment_checkpoints.forEach(checkpoint => {
        if (checkpoint.show_checkpoint) {
          total++;
        }
      });
    }
    return total;
  }

  calcAllInstallation(keyInstallation) {
    let total = 0;
    if (this.installations) {
      this.installations[keyInstallation].establishment_checkpoints.forEach(() => {
        total++;
      });
    }
    return total;
  }

  toggleInstallation(key) {
    if (this.openedInstallation >= 0) {
      if (this.openedInstallation === key) {
        this.installations[key].collapsed = true;
        this.openedInstallation = -1;
      } else {
        if (this.openedInstallation >= 0) {
          this.installations[this.openedInstallation].collapsed = true;
        }
        this.installations[key].collapsed = false;
        this.openedInstallation = key;
      }
    } else {
      this.installations[key].collapsed = false;
      this.openedInstallation = key;
    }
  }

  checkInstallation(keyInstallation) {
    if (this.calcCheckedInstallation(keyInstallation) === this.calcAllInstallation(keyInstallation)) {
      this.installations[keyInstallation].establishment_checkpoints.forEach(checkpoint => {
        checkpoint.show_checkpoint = false;
      });
    } else {
      this.installations[keyInstallation].establishment_checkpoints.forEach(checkpoint => {
        checkpoint.show_checkpoint = true;
      });
    }
  }

  checkCheckpoint(keyInstallation, keyCheckpoint) {
    const show_checkpoint = this.installations[keyInstallation].establishment_checkpoints[keyCheckpoint].show_checkpoint;
    this.installations[keyInstallation].establishment_checkpoints[keyCheckpoint].show_checkpoint = !show_checkpoint;
  }

  createBreadcrumbs() {
    this.pageName = 'Edition de masse';

    this.previousPageName = [];
    this.previousPageRoute = [];

    this.previousPageName.push('Tableau de bord');
    this.previousPageRoute.push('/');

    this.previousPageName.push(this.registry ? this.registry.name : 'Tableau de bord registre');
    this.previousPageRoute.push('/dashboard-registry/' + this.activatedRoute.snapshot.params.registry_id);

    this.previousPageName.push(this.establishment ? this.establishment.name : 'Tableau de bord établissement');
    this.previousPageRoute.push('/dashboard-registry/' + this.activatedRoute.snapshot.params.registry_id +
      '/establishment/' + this.activatedRoute.snapshot.params.establishment_id);

    this.previousPageName.push('Installations');
    this.previousPageRoute.push('/dashboard-registry/' + this.activatedRoute.snapshot.params.registry_id +
      '/establishment/' + this.activatedRoute.snapshot.params.establishment_id + '/installations');
  }
}
