import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { HttpClient } from '@angular/common/http';
import { RequestService } from './request.service';

@Injectable()
export class SanitaryWorksheetsRequestService extends RequestService {
  constructor(
    protected http: HttpClient,
    protected notifier: NotifierService
  ) {
    super(http, notifier);
  }

  /// GET ALL
  public getAllSanitaryWorksheet(search, sort, order, limit, page, registry_id, establishment_id) {
    return this.get( 'registry/' + registry_id + '/establishment/' + establishment_id + '/sanitary-worksheets?' +
      'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page + '&is_file=' + 0);
  }
  /// GET ALL
  public getAllSanitaryWorksheetFiles(search, sort, order, limit, page, registry_id, establishment_id) {
    return this.get( 'registry/' + registry_id + '/establishment/' + establishment_id + '/sanitary-worksheets?' +
        'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page + '&is_file=' + 1);
  }
  /// GET ONE
  public getSanitaryWorksheet(registry_id, establishment_id, sanitaryWorksheetId) {
    return this.get('registry/' + registry_id + '/establishment/' + establishment_id +
        '/sanitary-worksheet/' + sanitaryWorksheetId);
  }
  /// CREATE
  public createSanitaryWorksheet(registry_id, establishment_id, sanitaryWorksheet) {
    return this.post('registry/' + registry_id + '/establishment/' + establishment_id +
        '/sanitary-worksheet', { sanitaryWorksheet });
  }
  /// UPDATE
  public updateSanitaryWorksheet(registry_id, establishment_id, sanitaryWorksheetId, sanitaryWorksheet) {
    return this.put('registry/' + registry_id + '/establishment/' + establishment_id +
        '/sanitary-worksheet/' + sanitaryWorksheetId,
      { sanitaryWorksheet });
  }
  /// DELETE
  public deleteSanitaryWorksheet(registry_id, establishment_id, sanitaryWorksheetId) {
    return this.delete('registry/' + registry_id + '/establishment/' + establishment_id +
        '/sanitary-worksheet/' + sanitaryWorksheetId);
  }
  public downloadFile(registry_id, establishment_id, sanitaryWorksheetId) {
    return this.get('registry/' + registry_id + '/establishment/' + establishment_id +
        '/sanitary-worksheet/files/download/' + sanitaryWorksheetId, { responseType: 'arraybuffer' });
  }
}
