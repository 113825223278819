import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AllProviderRegistryUsersComponent } from './all-provider-registry-users.component';
import { BreadcrumbsModule } from '../../../core/breadcrumbs/breadcrumbs.module';
import { SearchbarModule } from '../../../core/searchbar/searchbar.module';
import { PaginationModule } from '../../../core/pagination/pagination.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    BreadcrumbsModule,
    SearchbarModule,
    PaginationModule
  ],
  declarations: [AllProviderRegistryUsersComponent]
})

export class AllProviderRegistryUsersModule {}
