import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AllSanitaryPoolsModule } from './all-sanitary-pools/all-sanitary-pools.module';

import { UpdateSanitaryPoolModule } from './update-sanitary-pool/update-sanitary-pool.module';
import { UploadSanitaryPoolFileModule } from './upload-sanitary-pool-file/upload-sanitary-pool-file.module';
import { UpdateSanitaryPoolRecordModule } from './update-sanitary-pool-record/update-sanitary-pool-record.module';

import { SanitaryPoolsRequestService } from '../../services/sanitary-pools.request.service';

@NgModule({
  imports: [
    CommonModule,
    AllSanitaryPoolsModule,
    UpdateSanitaryPoolModule,
    UploadSanitaryPoolFileModule,
    UpdateSanitaryPoolRecordModule
  ],
  providers: [
    SanitaryPoolsRequestService
  ]
})
export class SanitaryPoolsModule {}
