<div class="main-content">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col">
        <div class="header mb-0">
          <div class="header-body no-border">
            <div class="row align-items-center">
              <div class="col">
                <h1 class="header-title">Utilisateurs</h1>

                <small class="form-text text-muted" *ngIf="view === 'enterprise'">Liste des utilisateurs de l'entreprise {{ enterprise && enterprise.name }}</small>
                <small class="form-text text-muted" *ngIf="view === 'provider'">Liste des utilisateurs du prestataire {{ enterprise && enterprise.name }}</small>
                <small class="form-text text-muted" *ngIf="view === 'mine'">Liste des utilisateurs de mon entreprise</small>
                <small class="form-text text-muted" *ngIf="view === 'all'">Liste des utilisateurs de la plateforme</small>

                <app-breadcrumbs [currentPage]="pageName" [names]="previousPageName" [routes]="previousPageRoute"></app-breadcrumbs>
              </div>

              <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0" *ngIf="canCreate">
                <a class="btn btn-primary d-block d-md-inline-block" [routerLink]="[createRoute]">
                  Ajouter un utilisateur
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col">
            <app-searchbar [(ngModel)]="search" (keyup)="searchData()" ngDefaultControl></app-searchbar>
          </div>

          <div class="col-auto">
            <div class="nav btn-group" role="tablist" id="filters-and-display-buttons">
              <button class="btn btn-lg btn-white tooltipHtml" role="tab" aria-label="Affichage mosaïque" aria-labelledby="filters-and-display-buttons" data-placement="bottom" title="Mosaïque" [ngClass]="{ 'active': activeTabsList === 'tabPaneOne'}" (click)="changeActiveTabsList('tabPaneOne')">
                <span class="fe fe-grid"></span>
              </button>

              <button class="btn btn-lg btn-white tooltipHtml" role="tab" aria-label="Affichage liste" aria-labelledby="filters-and-display-buttons" data-placement="bottom" title="Liste" [ngClass]="{ 'active': activeTabsList === 'tabPaneTwo'}" (click)="changeActiveTabsList('tabPaneTwo')">
                <span class="fe fe-list"></span>
              </button>

              <button class="btn btn-lg btn-white tooltipHtml border-right-radius" role="tab" aria-label="Filtres" aria-labelledby="filters-and-display-buttons" data-placement="bottom" title="Filtre" data-toggle="dropdown">
                <span class="fe fe-filter"></span>
              </button>
              <div class="dropdown-menu dropdown-menu-right">
                <a class="dropdown-item href" (click)="refreshData('Users.lastname', 'asc', 1)" aria-label="Tri des utilisateurs : Nom - Ordre croissant">
                  Nom A-Z
                </a>

                <a class="dropdown-item href" (click)="refreshData('Users.lastname', 'desc', 1)" aria-label="Tri des utilisateurs : Nom - Ordre décroissant">
                  Nom Z-A
                </a>

                <a class="dropdown-item href" (click)="refreshData('Users.firstname', 'asc', 1)" aria-label="Tri des utilisateurs : Prénom - Ordre croissant">
                  Prénom A-Z
                </a>

                <a class="dropdown-item href" (click)="refreshData('Users.firstname', 'desc', 1)" aria-label="Tri des utilisateurs : Prénom - Ordre décroissant">
                  Prénom Z-A
                </a>

                <a class="dropdown-item href" (click)="refreshData('Users.email', 'asc', 1)" aria-label="Tri des utilisateurs : Email - Ordre croissant">
                  Email A-Z
                </a>

                <a class="dropdown-item href" (click)="refreshData('Users.email', 'desc', 1)" aria-label="Tri des utilisateurs : Email - Ordre d&croissant">
                  Email Z-A
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="is-loading loader-content" *ngIf="loader"></div>

        <div *ngIf="!loader && view === 'provider' && !users.length" class="alert alert-primary" role="alert">
          Attention, si vous n'ajoutez pas d'utilisateur à ce prestataire, vous ne pourrez pas assigner de rapport/installation/prescription à ce prestataire
        </div>

        <div *ngIf="!loader && view === 'mine' && users.length && subscription > 0" class="alert" [ngClass]="{ 'alert-primary': total > subscription, 'alert-success': total < subscription, 'alert-warning': total === subscription }" role="alert">
          Nombre d'ulisateurs utilisé par rapport à votre abonnement : {{ total + '/' + subscription }}
        </div>

        <div class="tab-content" [hidden]="loader || !users.length">
          <!-- MODE CARD -->
          <div class="tab-pane fade" id="tabPaneOne" role="tabpanel" [ngClass]="{ 'show active': activeTabsList === 'tabPaneOne'}">
            <div class="row listAlias">
              <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 d-flex flex-column" *ngFor="let user of users" [attr.aria-label]="'Utilisateur : ' + user.firstname + ' ' + user.lastname">
                <div class="card flex-fill">
                  <span class="card-img-top card-img-center" [title]="'Photo de profil : ' + user.firstname + ' ' + user.lastname" [style.backgroundImage]="'url(' + environment.SERVER_HOST + 'img/users/' + user.picture + ')'" *ngIf="user.picture != '' && user.picture != null"></span>

                  <span class="card-img-top card-img-center" [title]="'Photo de profil : ' + user.firstname + ' ' + user.lastname" [style.background-image]="'url(../assets/img/users/user.png)'" *ngIf="user.picture == '' || user.picture == null"></span>

                  <div class="card-body">
                    <div class="row align-items-center">
                      <div class="circle tooltipHtml" data-placement="bottom" [ngClass]="[getAccess(user), user ? 'cursor' : '']" *ngIf="view === 'mine' || view === 'provider'"></div>

                      <div class="col overflow-hidden">
                        <h4 class="card-title name text-truncate" [ngClass]="{ 'mb-2' : !user.phone}">
                          <a [routerLink]="[updateRoute + user.id]" *ngIf="view !== 'provider'" [attr.aria-label]="'Nom : ' + user.firstname + ' ' + user.lastname">
                            {{ user.firstname + ' ' + user.lastname }}
                          </a>

                          <a [routerLink]="[canUpdate ? updateRoute + user.id + '/access' : updateRoute + user.id]" *ngIf="view === 'provider'" [attr.aria-label]="'Nom : ' + user.firstname + ' ' + user.lastname">
                            {{ user.firstname + ' ' + user.lastname }}
                          </a>
                        </h4>

                        <p class="card-text small text-muted text-truncate" [attr.aria-label]="'Téléphone : ' + user.phone" [ngClass]="view === 'mine' || view === 'enterprise' ? 'mb-2' : 'mb-0'" *ngIf="user.phone">
                          {{ user.phone }}
                        </p>

                        <p class="card-text small text-muted text-truncate" [attr.aria-label]="'Adresse email : ' + user.email" [ngClass]="view === 'mine' || view === 'enterprise' ? 'mb-2' : 'mb-0'">
                          {{ user.email }}
                        </p>

                        <p class="card-text small text-muted text-truncate mb-0" aria-label="Role de l'utilisateur" *ngIf="view === 'mine' || view === 'enterprise'">
                          {{ user.last_user_enterprise.role.name }}
                        </p>
                      </div>

                      <div class="col-auto">
                        <div class="dropdown">
                          <a class="dropdown-ellipses dropdown-toggle href" role="button" [attr.aria-label]="'Actions pour l\'utilisateur ' +  user.firstname + ' ' + user.lastname" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fe fe-more-vertical"></i>
                          </a>

                          <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item href" [routerLink]="[updateRoute + user.id]">
                              {{ canUpdate ? 'Éditer l\'utilisateur' : 'Consulter l\'utilisateur' }}
                            </a>

                            <a class="dropdown-item href" *ngIf="view === 'provider' && canUpdate" [routerLink]="[updateRoute + user.id + '/access']">
                              Attribuer des points de contrôle
                            </a>

                            <a class="dropdown-item href" *ngIf="view === 'mine' && canUpdate && canAffect" [routerLink]="[updateRoute + user.id + '/access']">
                              Attribuer des points de contrôle
                            </a>

                            <a class="dropdown-item href" *ngIf="view === 'mine' && canUpdate && canAffect" [routerLink]="[updateRoute + user.id + '/access/registries']">
                              Attribuer des établissements / registres
                            </a>

                            <a class="dropdown-item href" *ngIf="isAdmin" (click)="connectedAs(user.id)">
                              Se connecter en tant que
                            </a>

                            <a class="dropdown-item href" *ngIf="view === 'enterprise'" (click)="deleteEnterprise(user.id)">
                              Retirer cet utilisateur
                            </a>

                            <a class="dropdown-item href" *ngIf="view === 'provider' && canDelete" (click)="deleteProvider(user.id)">
                              Retirer ce prestataire
                            </a>

                            <a class="dropdown-item href" [routerLink]="[updateRoute + user.id + '/logs']">
                              Historique des actions
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- MODE TABLEAU -->
          <div class="tab-pane fade" id="tabPaneTwo" role="tabpanel" [ngClass]="{ 'show active': activeTabsList === 'tabPaneTwo'}">
            <div class="row list">
              <div class="col-12">
                <div class="card">
                  <div class="card-body">
                    <div class="table-responsive">
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th class="href" (click)="filter('Users.firstname')">
                              <a class="text-muted">Prénom</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'Users.firstname' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'Users.firstname' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'Users.firstname'"></span>
                            </th>
                            <th class="href" (click)="filter('Users.lastname')">
                              <a class="text-muted">Nom</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'Users.lastname' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'Users.lastname' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'Users.lastname'"></span>
                            </th>
                            <th class="href" (click)="filter('Users.phone')">
                              <a class="text-muted">Téléphone</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'Users.phone' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'Users.phone' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'Users.phone'"></span>
                            </th>
                            <th class="href" (click)="filter('Users.email')">
                              <a class="text-muted">Email</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'Users.email' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'Users.email' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'Users.email'"></span>
                            </th>
                            <th *ngIf="view === 'mine'">Groupe</th>
                            <th class="href" (click)="filter('UserEnterprises.access_to_enterprise')" *ngIf="view === 'mine' || view === 'provider'">
                              <a class="text-muted">Donner un accès à BatiRegistre</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'UserEnterprises.access_to_enterprise' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'UserEnterprises.access_to_enterprise' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'UserEnterprises.access_to_enterprise'"></span>
                            </th>
                            <th *ngIf="view === 'all'">Entreprise(s)</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="dropdown" *ngFor="let user of users">
                            <td>
                              <a class="href" [routerLink]="[updateRoute + user.id]" *ngIf="view !== 'provider'">
                                {{ user.firstname }}
                              </a>

                              <a class="href" [routerLink]="[canUpdate ? updateRoute + user.id + '/access' : updateRoute + user.id]" *ngIf="view === 'provider'">
                                {{ user.firstname }}
                              </a>
                            </td>
                            <td>
                              <a class="href" [routerLink]="[updateRoute + user.id]" *ngIf="view !== 'provider'">
                                {{ user.lastname }}
                              </a>

                              <a class="href" [routerLink]="[canUpdate ? updateRoute + user.id + '/access' : updateRoute + user.id]" *ngIf="view === 'provider'">
                                {{ user.lastname }}
                              </a>
                            </td>
                            <td aria-label="Téléphone de l'utilisateur">{{ user.phone }}</td>
                            <td aria-label="Adresse email de l'utilisateur">{{ user.email }}</td>
                            <td *ngIf="view === 'mine'">{{ user.last_user_enterprise.role.name }}</td>
                            <td *ngIf="view === 'mine' || view === 'provider'">
                              <div class="circleList tooltipHtml" data-placement="bottom"
                                   [ngClass]="[getAccess(user), user ? 'cursor' : '']" *ngIf="view === 'mine' || view === 'provider'">
                              </div>
                            </td>
                            <td *ngIf="view === 'all'">
                              <select class="form-control" data-toggle="select" *ngIf="user.user_enterprises.length">
                                <option disabled selected>Listes des entreprises de l'utilisateur</option>
                                <option *ngFor="let userEnterprise of user.user_enterprises;" disabled>{{ userEnterprise.enterprise.name + ' - ' + userEnterprise.role.name }}</option>
                              </select>
                            </td>
                            <td class="d-flex flex-row-reverse">
                              <a class="dropdown-ellipses dropdown-toggle href" role="button" [attr.aria-label]="'Actions pour l\'utilisateur ' + user.firstname + ' ' + user.lastname" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fe fe-more-vertical"></i>
                              </a>

                              <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item href" [routerLink]="[updateRoute + user.id]">
                                  {{ canUpdate ? 'Éditer l\'utilisateur' : 'Consulter l\'utilisateur' }}
                                </a>

                                <a class="dropdown-item href" *ngIf="view === 'provider' && canUpdate" [routerLink]="[updateRoute + user.id + '/access']">
                                  Attribuer des points de contrôle
                                </a>

                                <a class="dropdown-item href" *ngIf="view === 'mine' && canUpdate" [routerLink]="[updateRoute + user.id + '/access']">
                                  Attribuer des points de contrôle
                                </a>

                                <a class="dropdown-item href" *ngIf="view === 'mine' && canUpdate" [routerLink]="[updateRoute + user.id + '/access/registries']">
                                  Attribuer des établissements / registres
                                </a>

                                <a class="dropdown-item href" *ngIf="isAdmin" (click)="connectedAs(user.id)">
                                  Se connecter en tant que
                                </a>

                                <a class="dropdown-item href" *ngIf="view === 'enterprise'" (click)="deleteEnterprise(user.id)">
                                  Retirer cet utilisateur
                                </a>

                                <a class="dropdown-item href" *ngIf="view === 'provider' && canDelete" (click)="deleteProvider(user.id)">
                                  Retirer ce prestataire
                                </a>

                                <a class="dropdown-item href" [routerLink]="[updateRoute + user.id + '/logs']">
                                  Historique des actions
                                </a>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row pagination-content" aria-label="Pagination">
            <div class="col-12 d-flex flex-column">
              <div class="card">
                <app-pagination (returnEvent)="dispatchPagination($event)"
                                [classes]="'card-body'"
                                [count_elements]="count"
                                [current_page]="page"
                                [elements_length]="users.length"
                                [limit]="limit"
                                [total_pages]="pages"
                                ngDefaultControl>
                </app-pagination>
              </div>
            </div>
          </div>
        </div>

        <div class="loader-content" *ngIf="!loader && !users.length">
          <p>Aucun résultat</p>
        </div>
      </div>
    </div>
  </div>
</div>

<router-outlet></router-outlet>
