import { Component, Output, ElementRef, EventEmitter, AfterViewInit, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

declare var $: any;

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent implements AfterViewInit {
  @Input() type;
  @Output() eventOnClose: EventEmitter<any> = new EventEmitter();

  modalElPdf = null;
  url: SafeResourceUrl = '';
  loader = false;

  constructor (
    private http: HttpClient,
    private sanitize: DomSanitizer,
    private _rootNode: ElementRef,
  ) {}

  open(url) {
    this.url = this.sanitize.bypassSecurityTrustResourceUrl(url);
    this.modalElPdf.modal('show');
  }

  close() {
    this.url = '';
    this.eventOnClose.next(null);
    this.modalElPdf.modal('hide');
  }

  closeInternal() {
    this.url = '';
    this.eventOnClose.next(null);
    this.modalElPdf.modal('hide');
  }

  ngAfterViewInit() {
    this.modalElPdf = $(this._rootNode.nativeElement).find('div.modal-pdf');

    const self = this;
    $('#pdf-modal').on('hidden.bs.modal', function () {
      self.url = '';
      self.eventOnClose.next(null);
      self.modalElPdf.modal('hide');
    });
  }
}
