import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Subject } from 'rxjs';

import { EnterprisesRequestService } from '../../../services/enterprises.request.service';
import { CheckpointsRequestService } from '../../../services/checkpoints.request.service';
import { UsersRequestService } from '../../../services/users.request.service';
import { CustomNotifierService } from '../../../services/custom.notifier.service';

@Component({
  selector: 'app-rights-transfer',
  templateUrl: './rights-transfer.component.html',
  styleUrls: ['./rights-transfer.component.scss']
})
export class RightsTransferComponent implements OnInit, AfterViewInit, OnDestroy {
  canUpdate = false;
  loader = false;
  loaderContent = false;

  rightsTransferModal = null;
  previousRoute = '';

  enterprises: any = [];
  user_source: string;
  user_target: string;

  requestInProgress = new Subject();
  requestCount = 0;

  rightsTransferForm: FormGroup;
  enterprise_id_source = new FormControl('', Validators.required);
  user_id_source = new FormControl('', Validators.required);
  enterprise_id_target = new FormControl('', Validators.required);
  user_id_target = new FormControl('', Validators.required);

  errors = {};

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private checkpointsService: CheckpointsRequestService,
    private enterprisesService: EnterprisesRequestService,
    private usersService: UsersRequestService
  ) {
  }

  ngOnInit() {
    this.previousRoute = this.activatedRoute.snapshot.parent.url.join('/');
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loaderContent = this.requestCount > 0;
    });
    this.rightsTransferForm = this.formBuilder.group({
      enterprise_id_source: this.enterprise_id_source,
      user_id_source: this.user_id_source,
      enterprise_id_target: this.enterprise_id_target,
      user_id_target: this.user_id_target
    });

    this.getEnterprises();
  }
  ngAfterViewInit(): void {
    this.rightsTransferModal.open();
  }
  ngOnDestroy(): void {
    this.rightsTransferModal.close();
  }

  getEnterprises() {
    this.requestInProgress.next(true);
    this.enterprisesService.getProviders('', '', '', '', '').subscribe(response => {
      if (response['success']) {
        this.canUpdate = response['canUpdate'];
        response['enterprises'].forEach(enterprise => {
          this.getUsers(enterprise);
        });
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }
  getUsers(enterprise) {
    this.requestInProgress.next(true);
    this.usersService.getProviderUsers('', '', '', '', '', enterprise.id).subscribe(response => {
      if (response['success']) {
        response['users'].forEach(user => {
          this.enterprises.push({
            id: enterprise.id + '-' + user.id,
            name: enterprise.name + ' - ' + user.firstname + ' ' + user.lastname
          });
        });
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  changeUser(source: boolean) {
    if (source) {
      if (this.user_source) {
        const split = this.user_source.split('-');
        this.enterprise_id_source.setValue(split[0]);
        this.user_id_source.setValue(split[1]);
      } else {
        this.enterprise_id_source.setValue(null);
        this.user_id_source.setValue(null);
      }
    } else {
      if (this.user_target) {
        const split = this.user_target.split('-');
        this.enterprise_id_target.setValue(split[0]);
        this.user_id_target.setValue(split[1]);
      } else {
        this.enterprise_id_target.setValue(null);
        this.user_id_target.setValue(null);
      }
    }
  }

  transfer() {
    this.requestInProgress.next(true);
    this.errors = {};
    const providers = { ...this.rightsTransferForm.value };
    this.checkpointsService.updateProviderAccess(providers).subscribe(response => {
      if (response['success']) {
        this.rightsTransferModal.close();
        CustomNotifierService.getSuccessUpdate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.rightsTransferForm, this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }
  binModal(modal) {
    this.rightsTransferModal = modal;
  }
  closeModal() {
    this.router.navigate([this.activatedRoute.snapshot.parent.url.join('/')]).then(() => {
    });
  }
}
