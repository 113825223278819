import { Routes } from '@angular/router';

import { AccessProviderCheckpointsComponent } from './access-provider-checkpoints/access-provider-checkpoints.component';
import { AccessProviderPatternComponent } from './access-provider-pattern/access-provider-pattern.component';

export const accessProviderRoutes: Routes = [
  {
    path: 'providers/:enterprise_id/users/:user_id/access',
    component: AccessProviderCheckpointsComponent,
    children: [
      {
        path: 'pattern',
        component: AccessProviderPatternComponent
      }
    ]
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/providers/:enterprise_id/users/:user_id/access',
    component: AccessProviderCheckpointsComponent,
      children: [
      {
        path: 'pattern',
        component: AccessProviderPatternComponent
      }
    ]
  }
];
