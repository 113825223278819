import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

import { UpdateFaqComponent } from './update-faq.component';
import { ModalRightModule } from '../../../core/modal-right/modal-right.module';
import { EditorModule } from '@tinymce/tinymce-angular';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    ModalRightModule,
    EditorModule
  ],
  declarations: [UpdateFaqComponent]
})

export class UpdateFaqModule {}
