import { Routes } from '@angular/router';

import { ModalLogsComponent } from '../logs/modal-logs/modal-logs.component';
import { AllSanitaryPoolsComponent } from './all-sanitary-pools/all-sanitary-pools.component';
import { UpdateSanitaryPoolComponent } from './update-sanitary-pool/update-sanitary-pool.component';
import { UploadSanitaryPoolFileComponent } from './upload-sanitary-pool-file/upload-sanitary-pool-file.component';
import { UpdateSanitaryPoolRecordComponent } from './update-sanitary-pool-record/update-sanitary-pool-record.component';

export const sanitaryPoolsRoutes: Routes = [
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/sanitary-pools',
    component: AllSanitaryPoolsComponent,
    children: [
      {
        path: 'create',
        component: UpdateSanitaryPoolComponent
      },
      {
        path: ':sanitary_pool_id',
        component: UpdateSanitaryPoolComponent,
      },
      {
        path: ':sanitary_pool_id/logs',
        component: ModalLogsComponent
      },
      {
        path: ':sanitary_pool_id/records',
        component: UpdateSanitaryPoolRecordComponent
      }
    ]
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/sanitary-pools/:sanitary_pool_id/files',
    component: AllSanitaryPoolsComponent,
    children: [
      {
        path: 'create',
        component: UploadSanitaryPoolFileComponent
      },
      {
        path: ':file_id',
        component: UploadSanitaryPoolFileComponent
      }
    ]
  },
];
