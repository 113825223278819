import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AllDocumentsModule } from './all-documents/all-documents.module';
import { UpdateDocumentModule } from './update-document/update-document.module';
import { UpdateFileTypeModule } from './update-file-type/update-file-type.module';
import { UploadFileModule } from './upload-file/upload-file.module';

import { DocumentsRequestService } from '../../services/documents.request.service';
import { VideosRequestService } from '../../services/videos.request.service';
import { PdfRequestService } from '../../services/pdf.request.service';

@NgModule({
  imports: [
    CommonModule,
    AllDocumentsModule,
    UpdateDocumentModule,
    UpdateFileTypeModule,
    UploadFileModule
  ],
  providers: [DocumentsRequestService, VideosRequestService, PdfRequestService],
})
export class DocumentsModule {}
