import { Routes } from '@angular/router';

import { AllCommissionsComponent } from './all-commissions/all-commissions.component';
import { UpdateCommissionComponent } from './update-commission/update-commission.component';
import { UploadCommissionFileComponent } from './upload-commission-file/upload-commission-file.component';
import { ModalLogsComponent } from '../logs/modal-logs/modal-logs.component';
import { UpdateContentComponent } from '../contents/udpate-content/update-content.component';

export const commissionsRoutes: Routes = [
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/commissions',
    component: AllCommissionsComponent,
    children: [
      {
        path: 'create',
        component: UpdateCommissionComponent
      },
      {
        path: ':commission_id',
        component: UpdateCommissionComponent
      },
      {
        path: ':commission_id/logs',
        component: ModalLogsComponent
      },
      {
        path: 'contents/:content_id',
        component: UpdateContentComponent
      }
    ]
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/commission/:commission_id/files',
    component: AllCommissionsComponent,
    children: [
      {
        path: 'create',
        component: UploadCommissionFileComponent
      },
      {
        path: ':file_id',
        component: UploadCommissionFileComponent
      }
    ]
  },
];
