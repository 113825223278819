<div class="main-content">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col">
        <div class="header mb-0">
          <div class="header-body no-border">
            <div class="row align-items-center">
              <div class="col">
                <h1 class="header-title">Authentifications unique</h1>

                <small class="form-text text-muted">Liste des authentifications unique</small>

                <app-breadcrumbs [currentPage]="pageName" [names]="previousPageName" [routes]="previousPageRoute"></app-breadcrumbs>
              </div>

              <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0" *ngIf="canCreate">
                <a class="btn btn-primary d-block d-md-inline-block" [routerLink]="['create']">Ajouter une authentification unique</a>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col">
            <app-searchbar [(ngModel)]="search" (keyup)="searchData()" ngDefaultControl></app-searchbar>
          </div>

          <div class="col-auto">
            <button class="btn btn-lg btn-white tooltipHtml" aria-label="Afficher les filtres disponibles"  data-toggle="dropdown" data-placement="bottom" title="Filtre">
              <span class="fe fe-filter"></span>
            </button>

            <span class="dropdown-menu dropdown-menu-right">
              <a class="dropdown-item href" (click)="refreshData('SsoConfigs.name', 'asc', 1)" aria-label="Tri des rôles : Nom - Ordre croissant">
                Nom A-Z
              </a>

              <a class="dropdown-item href" (click)="refreshData('SsoConfigs.name', 'desc', 1)" aria-label="Tri des rôles : Nom - Ordre décroissant">
                Nom Z-A
              </a>
            </span>
          </div>
        </div>

        <div class="is-loading loader-content" *ngIf="loader"></div>

        <div class="tab-content" *ngIf="!loader && ssoConfigs.length">
          <div class="tab-pane fade active show" id="tabPaneOne" role="tabpanel">
            <div class="row listAlias">
              <div class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4 d-flex flex-column" *ngFor="let sso of ssoConfigs">
                <div class="card flex-fill">
                  <span class="card-img-top card-img-center" [title]="'Photo de l\'authentification unique de : ' + sso.name" [style.backgroundImage]="'url(' + environment.SERVER_HOST + 'img/sso_configs/' + sso['picture'] + ')'" *ngIf="sso['picture'] != '' && sso['picture'] != null"></span>

                  <span class="card-img-top card-img-center" [title]="'Photo de l\'authentification unique de : ' + sso.name" [style.background-image]="'url(../assets/img/establishments/building.png)'" *ngIf="sso['picture'] == '' || sso['picture'] == null"></span>

                  <div class="card-body">
                    <div class="row align-items-center">
                      <div class="col overflow-hidden">
                        <h4 class="card-title name text-truncate mb-0">
                          <a [routerLink]="[sso.id]">
                            {{ sso.name }}
                          </a>
                        </h4>
                      </div>

                      <div class="col-auto align-self-start">
                        <div class="dropdown">
                          <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fe fe-more-vertical"></i>
                          </a>

                          <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item href" [routerLink]="[sso.id]">
                              {{ canUpdate ? 'Éditer l\'authentification unique' : 'Consulter l\'authentification unique' }}
                            </a>

                            <a class="dropdown-item href" (click)="modalConfirmData.open(sso.id)" *ngIf="canDelete">
                              Supprimer l'authentification unique
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div class="row pagination-content" aria-label="Pagination">
            <div class="col-12 d-flex flex-column">
              <div class="card">
                <app-pagination (returnEvent)="dispatchPagination($event)"
                                [classes]="'card-body'"
                                [count_elements]="count"
                                [current_page]="page"
                                [elements_length]="ssoConfigs.length"
                                [limit]="limit"
                                [total_pages]="pages"
                                ngDefaultControl>
                </app-pagination>
              </div>
            </div>
          </div>
        </div>

        <div class="loader-content" *ngIf="!loader && !ssoConfigs.length">
          <p>Aucun résultat</p>
        </div>
      </div>
    </div>
  </div>
</div>

<app-confirm-modal [type]="'delete_data'" #confirmData>{{ bindModalConfirmData(confirmData) }}</app-confirm-modal>

<router-outlet></router-outlet>
