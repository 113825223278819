import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Subject } from 'rxjs';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { UsersRequestService } from '../../../services/users.request.service';
import { EnterprisesRequestService } from '../../../services/enterprises.request.service';

import { AllRvratComponent } from '../all-rvrat/all-rvrat.component';
import { RvratsRequestService } from '../../../services/rvrats.request.service';
import { EstablishmentsRequestService } from '../../../services/establishments.request.service';

import BatiFile from '../../../../shared/interfaces/bati_file';
import Establishment from '../../../../shared/interfaces/establishment';

declare function Flatpickr(): any;
declare function Dropzones(): any;

@Component({
  selector: 'app-update-rvrat',
  templateUrl: './update-rvrat.component.html',
  styleUrls: ['./update-rvrat.component.scss']
})
export class UpdateRvratComponent implements OnInit, AfterViewInit, OnDestroy {
  canCreate = false;
  canUpdate = false;

  requestInProgress = new Subject();
  requestCount = 0;

  rvratForm: FormGroup;
  id = new FormControl('');
  establishment_registry_id = new FormControl('');
  date = new FormControl('', Validators.required);
  prescriptions_name = new FormControl(null);
  enterprise_id = new FormControl(null);
  user_id = new FormControl(null);
  establishment_id = new FormControl(null);
  updated = new FormControl('');
  comment_rvrat = new FormControl(null);

  files: BatiFile[] = [];
  enterprises: any = [];
  user: string;

  errors = {};

  isNew = true;
  loader = false;
  loaderContent = false;

  modalRvrat = null;

  oldPrescriptions: string = null;
  countOldPrescriptions = 0;

  establishment: Establishment;
  establishments: Establishment[];
  establishment_selected: number;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private allRvrats: AllRvratComponent,
    private usersService: UsersRequestService,
    private rvratsService: RvratsRequestService,
    private establishmentsService: EstablishmentsRequestService,
    private enterprisesService: EnterprisesRequestService
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loaderContent = this.requestCount > 0;
    });

    this.rvratForm = this.formBuilder.group({
      id: this.id,
      establishment_registry_id: this.establishment_registry_id,
      date: this.date,
      prescriptions_name: this.prescriptions_name,
      enterprise_id: this.enterprise_id,
      user_id: this.user_id,
      establishment_id: this.establishment_id,
      updated: this.updated,
      comment_rvrat: this.comment_rvrat,
    });

    if (this.activatedRoute.snapshot.params.rvrat_id) {
      this.isNew = false;
      this.getRvrat();
    } else {
      Flatpickr();
    }

    this.getEstablishment();
  }

  ngAfterViewInit() {
    Dropzones();
    this.modalRvrat.open();
  }

  ngOnDestroy() {
    const flatPicker = document.getElementsByClassName('flatpickr-calendar') as HTMLCollection;
    for (let i = flatPicker.length; i >= 0; i--) {
      if (flatPicker[i]) {
        flatPicker[i].remove();
      }
    }

    const dropZones = document.getElementsByClassName('dz-hidden-input') as HTMLCollection;
    for (let i = 0; i < dropZones.length; i++) {
      dropZones[i].remove();
    }

    this.modalRvrat.close();
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  getRvrat() {
    let request;
    this.requestInProgress.next(true);

    request = this.rvratsService.getRvrat(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.activatedRoute.snapshot.params.rvrat_id
    );

    request.subscribe(response => {
      if (response['success']) {
        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];

        this.rvratForm.patchValue(response['rvrat']);

        this.prescriptions_name.setValue('');
        const temp = [];
        this.countOldPrescriptions = response['rvrat']['rvrat_prescriptions'].length;
        response['rvrat']['rvrat_prescriptions'].forEach(element => {
          temp.push(element.name);
        });
        this.oldPrescriptions = temp.join('\n');

        if (!this.canUpdate) {
          this.rvratForm.disable();
        }

      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      Flatpickr();
      this.requestInProgress.next(false);
    });
  }

  getEstablishment() {
    this.requestInProgress.next(true);
    this.establishmentsService.getMyEstablishment(this.activatedRoute.snapshot.parent.params.establishment_id).subscribe(response => {
      if (response['success']) {
        this.establishment = response['establishment'];
        if (!this.establishment.parent_id && this.establishment.is_rus) {
          this.getEstablishments();
        } else {
          this.rvratForm.patchValue({ establishment_id: this.establishment.id });
        }
      } else if (response['errors']) {
        this.errors = response['errors'];
      }
      this.requestInProgress.next(false);
    });
  }

  getEstablishments() {
    this.requestInProgress.next(true);
    this.establishmentsService.geMyEstablishmentsChildren(
      '', '', '', '', '',
      this.activatedRoute.snapshot.parent.params.establishment_id
    ).subscribe(response => {
      if (response['success']) {
        this.establishments = response['establishments'];
      } else if (response['errors']) {
        this.errors = response['errors'];
      }
      this.requestInProgress.next(false);
    });
  }

  changeEstablishment() {
    this.establishment_id.setValue(this.establishment_selected);
  }

  createRvrat() {
    let request;
    this.errors = {};
    this.loader = true;

    if (this.prescriptions_name.value) {
      const temp = [];
      const prescriptions_name: string = this.prescriptions_name.value;
      const splitValue = prescriptions_name.split('\n');

      splitValue.forEach(value => {
        const reg = new RegExp(/^\s*•\s+/gm);
        value = value.replace(reg, '');
        if (value) {
          temp.push(value);
        }
      });
      this.prescriptions_name.setValue(temp);
    }

    request = this.rvratsService.createRvrat(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.activatedRoute.snapshot.parent.params.worksheet_id,
      this.rvratForm.value
    );

    request.subscribe(response => {
      if (response['success']) {
        this.isNew = false;
        if (this.files.length) {
          this.createDocuments(response['rvrat']['id']);
        } else {
          this.loader = false;
          this.modalRvrat.close();
          CustomNotifierService.getSuccessCreate(this.notifier);
        }
      } else if (response['errors']) {
        this.loader = false;
        this.errors = response['errors'];
        this.prescriptions_name.setValue(null);
        CustomNotifierService.getErrorsForm(response['errors'], this.rvratForm, this.notifier);
      }

      if (this.prescriptions_name.value) {
        this.prescriptions_name.setValue(this.prescriptions_name.value.join('\n'));
        this.prescriptions_name.setValue(this.prescriptions_name.value.replace(/(^|\r\n|\n)([^• ]|$)/g, '$1• $2'));
      }
    });
  }

  updateRvrat() {
    let request;
    let rvrat;

    this.errors = {};
    this.loader = true;

    if (this.prescriptions_name.value) {
      const temp = [];
      const prescriptions_name: string = this.prescriptions_name.value;
      const splitValue = prescriptions_name.split('\n');

      splitValue.forEach(value => {
        if (value) {
          const reg = new RegExp(/^\s*•\s+/gm);
          temp.push(value.replace(reg, ''));
        }
      });

      this.prescriptions_name.setValue(temp);
    }

    rvrat = {
      ...this.rvratForm.value,
      id: this.id.value,
      establishment_registry_id: this.establishment_registry_id.value
    };

    request = this.rvratsService.updateRvrat(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.activatedRoute.snapshot.params.rvrat_id,
      rvrat
    );

    request.subscribe(response => {
      if (response['success']) {
        if (this.files.length) {
          this.createDocuments(this.activatedRoute.snapshot.params.rvrat_id);
        } else {
          this.loader = false;
          this.modalRvrat.close();
          CustomNotifierService.getSuccessUpdate(this.notifier);
        }
      } else if (response['errors']) {
        this.loader = false;
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }

      if (this.prescriptions_name.value) {
        this.prescriptions_name.setValue(this.prescriptions_name.value.join('\n'));
        this.prescriptions_name.setValue(this.prescriptions_name.value.replace(/(^|\r\n|\n)([^• ]|$)/g, '$1• $2'));
      }
    });
  }

  addTextareaBullet(event) {
    if (event.srcElement.value) {
      event.srcElement.value = event.srcElement.value.replace(/(^|\r\n|\n)([^• ]|$)/g, '$1• $2');
    }
  }

  pasteTextareaBullet(event) {
    setTimeout(() => {
      event.srcElement.value = event.srcElement.value.replace(/^\s+|\s+$/g, '');
      event.srcElement.value = event.srcElement.value.replace(/[\r\n]+/g, '\n');
      event.srcElement.value = event.srcElement.value.replace(/(^|\r\n|\n)([^• ]|$)/g, '$1• $2');
    }, 150);
  }

  createDocuments(rvrat_id) {
    let request;
    this.errors = {};

    request = this.rvratsService.createFile(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      rvrat_id,
      this.files
    );

    request.subscribe(response => {
      if (response['success']) {
        this.modalRvrat.close();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loader = false;
    });
  }

  chooseFile() {
    const element: HTMLElement = document.getElementById('inputFile') as HTMLElement;
    element.click();
  }

  onFileChange(event) {
    if (event.target && event.target.files && event.target.files.length) {
      for (let i = 0; i < event.target.files.length; i++) {
        const reader = new FileReader();
        const file = event.target.files[i];
        reader.readAsDataURL(file);

        reader.onload = () => {
          let extension = '';
          let name = '';

          const split = file.name.split('.');
          if (split.length) {
            name = split[0];
            extension = split[split.length - 1];
          }

          this.files.push({ file_base64: reader.result, name: name, extension: extension });
        };
      }
    } else {
      let extension = '';
      let name = '';

      const split = event.name.split('.');
      if (split.length) {
        name = split[0];
        extension = split[split.length - 1];
      }

      this.files.push({ file_base64: event.file, name: name, extension: extension });
    }
    const element: HTMLInputElement = document.getElementById('inputFile') as HTMLInputElement;
    element.value = '';
  }

  removeFile(index) {
    this.files.splice(index, 1);

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  bindModal(modal) {
    this.modalRvrat = modal;
  }

  closeModal() {
    this.router.navigate([this.activatedRoute.snapshot.parent.url.join('/')]).then(() => {
      this.allRvrats.refreshData();
    });
  }
}
