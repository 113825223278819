<div class="main-content">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col">
        <div class="header mb-0">
          <div class="header-body no-border">
            <div class="row align-items-center">
              <div class="col">
                <h1 class="header-title">
                  Documents
                </h1>

                <small class="form-text text-muted">
                  Liste des documents
                </small>

                <app-breadcrumbs [currentPage]="pageName" [names]="previousPageName" [routes]="previousPageRoute"></app-breadcrumbs>
              </div>

              <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0" *ngIf="canCreate">
                <a class="btn btn-primary d-block d-md-inline-block" [routerLink]="[currentRoute + 'create']">
                  Ajouter un document
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col">
            <app-searchbar [(ngModel)]="search" (keyup)="searchData()" ngDefaultControl></app-searchbar>
          </div>

          <div class="col-auto" *ngIf="view === 'commission_files'">
            <button class="btn btn-lg btn-white tooltipHtml" aria-label="Afficher les filtres disponibles"  data-toggle="dropdown" data-placement="bottom" title="Filtre">
              <span class="fe fe-filter"></span>
            </button>

            <span class="dropdown-menu dropdown-menu-right">
              <a class="dropdown-item href" (click)="refreshData('Commissions.name', 'asc', 1)" aria-label="Tri des documents : Nom - Ordre croissant">
                Nom A-Z
              </a>

              <a class="dropdown-item href" (click)="refreshData('Commissions.name', 'desc', 1)" aria-label="Tri des documents : Nom - Ordre décroissant">
                Nom Z-A
              </a>
            </span>
          </div>
        </div>

        <div class="is-loading loader-content" *ngIf="loader"></div>

        <div class="tab-content" *ngIf="!loader && files.length">
          <div class="tab-pane fade active show" id="tabPaneOne" role="tabpanel">
            <div class="row listAlias">
              <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 d-flex flex-column" *ngFor="let file of files">
                <div class="card flex-fill">

                  <div class="href image-file-download" *ngIf="!['mp4', 'pdf', 'png', 'jpeg', 'jpg'].includes(file.extension)" (click)="downloadFile(file.id, file.name + '.' + file.extension)">
                    <img [src]="getImage(file.extension)" alt="Télécharger le fichier"/>
                  </div>
                  <div class="href image-file-download" *ngIf="file.extension === 'mp4'" (click)="showVideo(file.id)">
                    <img [src]="getImage(file.extension)" alt="Télécharger le fichier"/>
                  </div>
                  <div class="href image-file-download" *ngIf="['pdf', 'png', 'jpeg', 'jpg'].includes(file.extension)" (click)="showPdf(file.file_url)">
                    <img [src]="getImage(file.extension)" alt="Visionner le PDF"/>
                  </div>

                  <div class="card-body">
                    <div class="row align-items-center">
                      <div class="col overflow-hidden">
                        <h4 class="card-title name text-wrap mb-0">
                          <a [routerLink]="" *ngIf="!['mp4', 'pdf', 'png', 'jpeg', 'jpg'].includes(file.extension)" (click)="downloadFile(file.id, file.name + '.' + file.extension)">
                            {{ file.name + '.' + file.extension }}
                          </a>
                          <a [routerLink]="" *ngIf="file.extension === 'mp4'" (click)="showVideo(file.id)">
                            {{ file.name + '.' + file.extension }}
                          </a>
                          <a [routerLink]="" *ngIf="['pdf', 'png', 'jpeg', 'jpg'].includes(file.extension)" (click)="showPdf(file.file_url)">
                            {{ file.name + '.' + file.extension }}
                          </a>
                        </h4>
                      </div>

                      <div class="col-auto align-self-start">
                        <div class="dropdown">
                          <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fe fe-more-vertical"></i>
                          </a>

                          <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item href" [routerLink]="[currentRoute + file.id]">
                              {{ canUpdate ? 'Éditer le fichier' : 'Consulter le fichier' }}
                            </a>

                            <a class="dropdown-item href" *ngIf="file.extension === 'mp4'" (click)="showVideo(file.id)">
                              Regarder la vidéo
                            </a>

                            <a class="dropdown-item href" *ngIf="file.extension === 'pdf'" (click)="showPdf(file.file_url)">
                              Visionner le PDF
                            </a>

                            <a class="dropdown-item href" *ngIf="['png', 'jpeg', 'jpg'].includes(file.extension)" (click)="showPdf(file.file_url)">
                              Visionner l'image
                            </a>

                            <a class="dropdown-item href" (click)="downloadFile(file.id, file.name + '.' + file.extension)">
                              Télécharger le fichier
                            </a>

                            <a class="dropdown-item href" (click)="modalConfirmFile.open(file.id)" *ngIf="canDelete">
                              Supprimer le fichier
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row pagination-content">
            <div class="col-12 d-flex flex-column">
              <div class="card">
                <app-pagination (returnEvent)="dispatchPagination($event)"
                                [classes]="'card-body'"
                                [count_elements]="count"
                                [current_page]="page"
                                [elements_length]="files.length"
                                [limit]="limit"
                                [total_pages]="pages"
                                ngDefaultControl>
                </app-pagination>
              </div>
            </div>
          </div>
        </div>

        <div class="loader-content" *ngIf="!loader && !files.length">
          <p>Aucun résultat</p>
        </div>

      </div>
    </div>
  </div>
</div>

<app-confirm-modal [type]="'delete_file'" #confirmFile>{{ bindModalConfirmFile(confirmFile) }}</app-confirm-modal>
<app-video-modal #video>{{ bindModalVideo(video) }}</app-video-modal>
<app-pdf-viewer #pdf>{{ bindModalPdf(pdf) }}</app-pdf-viewer>

<router-outlet></router-outlet>
