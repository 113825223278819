import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { HttpClient } from '@angular/common/http';
import { NotifierService } from 'angular-notifier';

@Injectable()
export class RolesRequestService extends RequestService {
  constructor(
    protected http: HttpClient,
    protected notifier: NotifierService
  ) {
    super(http, notifier);
  }

  /// GET
  public getRoles(search, sort, order, limit, page) {
    return this.get('roles?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }

  public getRole(role_id) {
    return this.get('role/' + role_id);
  }
  public getRights() {
    return this.get('rights');
  }

  /// CREATE
  public createRole(role) {
    return this.post('role', { role });
  }

  /// UPDATE
  public updateRole(role_id, role) {
    return this.put('role/' + role_id, { role });
  }

  /// DELETE
  public deleteRole(role_id) {
    return this.delete('role/' + role_id);
  }
}
