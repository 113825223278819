export const environment = {
  production: false,
  SERVER_HOST: 'https://dev.app.api.batiregistre.dev-leviatan.fr/',
  API_PATH: 'https://dev.app.api.batiregistre.dev-leviatan.fr/api/v1/',
  ERP_HOST: 'https://dev.app.batiregistre.dev-leviatan.fr',

  tenant_id: '8402efe3-99b6-4ab0-9260-64e823b16c9c',

  firebase: {
    apiKey: 'AIzaSyBBOKINaQzjpuAC2amNsCzp3o08M05QRPA',
    authDomain: 'erp-batiregistre.firebaseapp.com',
    databaseURL: 'https://erp-batiregistre.firebaseio.com',
    projectId: 'erp-batiregistre',
    storageBucket: 'erp-batiregistre.appspot.com',
    messagingSenderId: '988772858240',
    appId: '1:988772858240:web:3ac8837001def8e92ea965',
    measurementId: 'G-RR0E62GY9X',
    vapidKey: 'BHiGcIPf-nJHWXt2FTdrIuxsulvUr3qjfrGXWdBNHnb07vGG_mlB5jAVinzF8H3fV8EwAFjp4Dr9yh9J1mhE49Q',
  }
};

export const maps = {
  API_KEY: 'AIzaSyCU4_Hv9nxc4SdDnV2VejTYrPVa6JEbxtQ'
};
