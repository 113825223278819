import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AllUsersModule } from './all-users/all-users.module';
import { UpdateUserModule } from './update-user/update-user.module';
import { AllProviderRegistryUsersModule } from './all-provider-registry-users/all-provider-registry-users.module';

@NgModule({
  imports: [
    CommonModule,
    AllUsersModule,
    UpdateUserModule,
    AllProviderRegistryUsersModule
  ]
})
export class UsersModule {}
