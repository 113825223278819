import { Component, OnDestroy, OnInit } from '@angular/core';

import { NotifierService } from 'angular-notifier';
import { CustomNotifierService } from '../../../services/custom.notifier.service';

import { ActivatedRoute } from '@angular/router';
import { RegistriesRequestService } from '../../../services/registries.request.service';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../core/auth/auth.service';
import Registry from '../../../../shared/interfaces/registry';
import Establishment from '../../../../shared/interfaces/establishment';

@Component({
  selector: 'app-all-customer-registries',
  templateUrl: './all-customer-registries.component.html',
  styleUrls: ['./all-customer-registries.component.scss']
})
export class AllCustomerRegistriesComponent implements OnInit, OnDestroy {
  changeEnterprise: Subscription;
  canCreate = false;
  canUpdate = false;

  establishment: Establishment;
  registries: Registry[] = [];

  pageName: string;
  previousPageName: string[] = [];
  previousPageRoute: string[] = [];

  count = 0;
  pages = 1;

  search = '';
  sort = 'Registries.name';
  order = 'asc';
  limit = 12;
  page = 1;

  timeout: any;
  view = '';
  loader = false;

  constructor(
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private registriesService: RegistriesRequestService
  ) {}

  ngOnInit() {
    this.changeEnterprise = this.authService.getEnterprise().subscribe(() => {
      this.getRegistries();
    });

    this.previousPageName = [];
    this.previousPageRoute = [];

    this.pageName = 'Registres';
    this.previousPageName.push('Tableau de bord');
    this.previousPageRoute.push('/');

    this.previousPageName.push('Mes clients');
    this.previousPageRoute.push('/customers');

    this.previousPageName.push('Etablissements');
    this.previousPageRoute.push(
      '/customers/' + this.activatedRoute.snapshot.params.enterprise_id + '/establishments'
    );

    if (this.authService.loaded) {
      this.getRegistries();
    }
  }

  ngOnDestroy() {
    this.changeEnterprise.unsubscribe();

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  searchData() {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.page = 1;
      this.refreshData();
    }, 500);
  }

  refreshData(sort = this.sort, order = this.order, page = this.page) {
    this.sort = sort;
    this.order = order;
    this.page = page;
    this.getRegistries();
  }

  getRegistries() {
    this.loader = true;
    const enterprise_id = this.activatedRoute.snapshot.params.enterprise_id;
    const establishment_id = this.activatedRoute.snapshot.params.establishment_id;
    this.registriesService.getCustomerRegistries(
      this.search, this.sort, this.order, this.limit, this.page, enterprise_id, establishment_id
    ).subscribe(response => {
      if (response['success']) {
        this.registries = response['registries'];

        this.count = response['count'];
        this.pages = response['pages'];
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loader = false;
    });
  }

  dispatchPagination(data) {
    if (data.action === 'total_elements') {
      this.refreshPage(data.value);
    } else if (data.action === 'change_page') {
      this.changePage(data.value);
    }
  }

  refreshPage(limit) {
    this.limit = limit;
    this.page = 1;
    this.refreshData();
  }

  changePage(page) {
    if (page >= 1 && page <= this.pages) {
      this.page = page;
      this.getRegistries();
    }
  }
}
