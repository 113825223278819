<div class="breadcrumbs">
  <div class="crumbs d-flex flex-wrap">
    <div class="crumb d-flex" *ngFor="let name of names; let i = index">
      <a class="link" [routerLink]="getRoute(routes[i])" [attr.aria-label]="'Fil d\'ariane : Redirection vers ' + name">{{ name }}</a>
      <i class="chevron align-self-center fe fe-chevron-right"></i>
    </div>

    <div class="crumb">
      <div class="link" [attr.aria-label]="'Fil d\'ariane : Vous êtes actuellement sur la page ' + currentPage">{{ currentPage }}</div>
    </div>
  </div>
</div>
