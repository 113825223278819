import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { HttpClient } from '@angular/common/http';
import { NotifierService } from 'angular-notifier';

@Injectable()
export class RegistriesRequestService extends RequestService {
  constructor(
    protected http: HttpClient,
    protected notifier: NotifierService
  ) {
    super(http, notifier);
  }

  /// GET
  public getRegistries(search, sort, order, limit, page) {
    return this.get('registries?' +
      'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getCheckpointRegistries() {
    return this.get('registries/module/checkpoint');
  }
  public getMyRegistries(search, sort, order, limit, page) {
    return this.get('registries/mine' +
      '?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getRegistriesEstablishment(search, sort, order, limit, page, establishment_id) {
    return this.get('registries/establishment/' + establishment_id + '?' +
      'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getRegistry(registry_id, establishment_id = '') {
    return this.get('registry/' + registry_id + '?establishment=' + establishment_id);
  }
  public getCustomerRegistries(search, sort, order, limit, page, enterprise_id, establishment_id) {
    return this.get('customers/' + enterprise_id + '/establishments/' + establishment_id + '/registries?' +
      'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getQuotaEstablishment(establishment_id: number, parent: string) {
    return this.get('subscription/quota/' + establishment_id + '?parent=' + parent);
  }
  public getEnterpriseQuotaEstablishment(establishment_id: number, parent: string, enterprise_id: number) {
    return this.get('subscription/quota/' + establishment_id + '/' + enterprise_id + '?parent=' + parent);
  }
  public getManager(registry_id, establishment_id) {
    return this.get('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/registry-manager');
  }

  public getAccess(registry_id, establishment_id) {
    if (establishment_id) {
      return this.get('auth/registry/' + registry_id + '/establishment/' + establishment_id);
    } else {
      return this.get('auth/registry/' + registry_id);
    }
  }

  /// CREATE
  public createRegistry(registry) {
    return this.post('registry', { registry });
  }

  public createManager(registry_id, establishment_id, manager) {
    return this.post('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/registry-manager', { manager });
  }

  /// UPDATE
  public updateRegistry(registry_id, registry) {
    return this.put('registry/' + registry_id, { registry });
  }

  public updateManager(registry_id, establishment_id, manager_id, manager) {
    return this.put('dashboard-registry/' + registry_id + '/establishment/' + establishment_id + '/registry-manager/' + manager_id,
      { manager });
  }

  /// ADD
  public addEstablishmentRegistry(establishment_id, registry_id) {
    return this.post('establishment/' + establishment_id + '/registry', { registry_id });
  }

  public addEstablishmentMyRegistry(establishment_id, registry_id) {
    return this.post('establishment/mine/' + establishment_id + '/registry', { registry_id });
  }

  /// DELETE
  public deleteEstablishmentRegistry(establishment_id, registry_id) {
    return this.put('establishment/' + establishment_id + '/registry', { registry_id });
  }

  public deleteEstablishmentMyRegistry(establishment_id, registry_id) {
    return this.put('establishment/mine/' + establishment_id + '/registry', { registry_id });
  }
}
