import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NotifierService } from "angular-notifier";
import { CustomNotifierService } from "src/app/services/custom.notifier.service";
import { TrainingsRequestService } from "src/app/services/trainings.request.service";

import Employees from "src/shared/interfaces/employees";

@Component({
  selector: "app-employees-training",
  templateUrl: "./employees-training.component.html",
  styleUrls: ["./employees-training.component.scss"],
})
export class EmployeesTrainingComponent implements OnInit {
  pageName: string;
  previousPageName: string[] = [];
  previousPageRoute: string[] = [];
  currentRoute = "";

  canCreate = false;
  canUpdate = false;
  canDelete = false;

  loaderContent = false;

  timeout: any;

  count = 0;
  pages = 1;

  search = "";
  sort = "";
  order = "asc";
  limit = 12;
  page = 1;

  loader = false;

  employeesTraining: Employees[] = [];

  modalConfirmData = null;

  constructor(
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private trainingsService: TrainingsRequestService
  ) {}

  ngOnInit() {
    this.pageName = "Employées formées";
    this.currentRoute = "/" + this.activatedRoute.snapshot.url.join("/") + "/";
    this.createBreadcrumbs();
    this.getEmployeesTrain();
  }

  getEmployeesTrain() {
    let request;
    this.loader = true;
    const is_present = 1;

    request = this.trainingsService.getEmployeesTrained(
      this.search,
      this.sort,
      this.order,
      this.limit,
      this.page,
      this.activatedRoute.snapshot.params.training_id,
      is_present
    );

    request.subscribe((response) => {
      if (response["success"]) {
        this.canCreate = response["canCreate"];
        this.canUpdate = response["canUpdate"];
        this.canDelete = response["canDelete"];

        this.employeesTraining = response["employees_training"];

        this.pages = response["pages"];
        this.count = response["count"];
      } else {
        CustomNotifierService.getErrors(response["errors"], this.notifier);
      }
      this.loader = false;
    });
  }

  searchData() {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.page = 1;
      this.refreshData();
    }, 500);
  }

  refreshData(sort = this.sort, order = this.order, page = this.page) {
    this.sort = sort;
    this.order = order;
    this.page = page;
    this.getEmployeesTrain();
  }

  dispatchPagination(data) {
    if (data.action === "total_elements") {
      this.refreshPage(data.value);
    } else if (data.action === "change_page") {
      this.changePage(data.value);
    }
  }

  refreshPage(limit) {
    this.limit = limit;
    this.page = 1;
    this.refreshData();
  }

  filter(property) {
    if (this.sort === property) {
      if (this.order === "asc") {
        this.order = "desc";
      } else {
        this.order = "asc";
      }
    } else {
      this.sort = property;
      this.order = "asc";
    }

    this.getEmployeesTrain();
  }

  changePage(page) {
    if (page >= 1 && page <= this.pages) {
      this.page = page;
      this.getEmployeesTrain();
    }
  }

  bindModalConfirmData(modal) {
    this.modalConfirmData = modal;
  }

  createBreadcrumbs() {
    this.previousPageName = [];
    this.previousPageRoute = [];

    this.previousPageName.push("Formations");
    this.previousPageRoute.push("/formations");
  }
}
