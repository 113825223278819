import {
  AfterViewInit,
  Component,
  Injector,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NotifierService } from "angular-notifier";
import { Subject } from "rxjs";

import { CustomNotifierService } from "../../services/custom.notifier.service";

import { ExportDownloadRequestService } from "../../services/export-download.request.service";
import { RegistriesRequestService } from "../../services/registries.request.service";
import { EstablishmentsRequestService } from "../../services/establishments.request.service";
import * as moment from "moment";
import Establishment from "../../../shared/interfaces/establishment";

import { InstallationsRequestService } from "../../services/installations.request.service";
import Installation from "../../../shared/interfaces/installation";
import { CheckpointsRequestService } from "../../services/checkpoints.request.service";

declare function Flatpickr(): any;

@Component({
  selector: "app-export-download",
  templateUrl: "./export-download.component.html",
  styleUrls: ["./export-download.component.scss"],
})
export class ExportDownloadComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  canCreate = false;
  canUpdate = false;
  firstLoad = true;

  errors = {};

  isNew = true;
  loader = false;
  loaderContent = true;

  requestInProgress = new Subject();
  requestCount = 0;
  modalDownload = null;
  previousRoute = "";

  establishment: Establishment;
  installations: Installation[] = [];
  registryInstallations: Installation[] = [];
  establishmentInstallations: Installation[] = [];

  selectedInstallations = [];
  exportBeginDate = "";
  exportEndDate = "";

  show_inactive = false;
  connectedAs = false;
  monoOffer = false;
  discoverOffer = false;

  downloadForm: FormGroup;
  name = new FormControl({ value: "", disabled: false });
  withFormations = new FormControl({ value: true, disabled: false });
  withAnnexesFormations = new FormControl({ value: true, disabled: false });
  withCommissions = new FormControl({ value: true, disabled: false });
  withAnnexesCommissions = new FormControl({ value: true, disabled: false });
  withWorksheets = new FormControl({ value: true, disabled: false });
  withAnnexesWorksheets = new FormControl({ value: true, disabled: false });
  withEmployees = new FormControl({ value: true, disabled: false });
  withInstallations = new FormControl({ value: true, disabled: false });
  withAnnexesInstallations = new FormControl({ value: true, disabled: false });
  withAnnexes = new FormControl({ value: true, disabled: false });
  withDocuments = new FormControl({ value: false, disabled: false });

  exportType = new FormControl({ value: "XLSX", disabled: false });

  selectedExport;
  exports;
  filteredExports;
  establishmentId;
  registryId;
  registry;

  constructor(
    private router: Router,
    private injector: Injector,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private registriesService: RegistriesRequestService,
    private checkpointsService: CheckpointsRequestService,
    private installationsService: InstallationsRequestService,
    private exportDownloadService: ExportDownloadRequestService,
    private establishmentsService: EstablishmentsRequestService
  ) {}

  ngOnInit() {
    this.previousRoute = this.activatedRoute.snapshot.parent.url.join("/");

    this.establishmentId =
      this.activatedRoute.snapshot.parent.params.establishment_id;
    this.registryId = this.activatedRoute.snapshot.parent.params.registry_id;

    const is_inactive = JSON.parse(
      localStorage.getItem("show_inactive_installations")
    );
    if (is_inactive !== null) {
      this.show_inactive = is_inactive;
    } else {
      localStorage.setItem(
        "show_inactive_installations",
        JSON.stringify(this.show_inactive)
      );
    }

    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loaderContent = this.requestCount > 0;
    });
    if (this.establishmentId) {
      this.getEstablishment();
    } else {
      this.getRegistry();
    }

    this.downloadForm = this.formBuilder.group({
      name: this.name,
      withFormations: this.withFormations,
      withAnnexesFormations: this.withAnnexesFormations,
      withCommissions: this.withCommissions,
      withAnnexesCommissions: this.withAnnexesCommissions,
      withWorksheets: this.withWorksheets,
      withAnnexesWorksheets: this.withAnnexesWorksheets,
      withInstallations: this.withInstallations,
      withAnnexesInstallations: this.withAnnexesInstallations,
      withEmployees: this.withEmployees,
      withAnnexes: this.withAnnexes,
      withDocuments: this.withDocuments,
      exportType: this.exportType,
    });

    this.connectedAs = JSON.parse(localStorage.getItem("connectedAs"));
    if (this.connectedAs) {
      this.monoOffer = JSON.parse(
        localStorage.getItem("connectedAsIsMonoOffer")
      );
      this.discoverOffer = JSON.parse(
        localStorage.getItem("connectedAsIsDiscoverOffer")
      );
    } else {
      this.monoOffer = JSON.parse(localStorage.getItem("isMonoOffer"));
      this.discoverOffer = JSON.parse(localStorage.getItem("isDiscoverOffer"));
    }
  }

  ngAfterViewInit() {
    this.modalDownload.open();
  }

  ngOnDestroy() {
    this.modalDownload.close();
  }

  getEstablishment() {
    this.requestInProgress.next(true);
    this.establishmentsService
      .getMyEstablishment(this.establishmentId)
      .subscribe((response) => {
        if (response["success"]) {
          this.establishment = response["establishment"];
          this.getRegistry();
        }
        this.requestInProgress.next(false);
      });
  }

  getInstallation() {
    let request;
    this.requestInProgress.next(true);

    if (this.establishmentId) {
      if (this.show_inactive) {
        request =
          this.installationsService.getAllMyRegistryEstablishmentInstallations(
            "",
            "",
            "",
            "",
            "",
            this.registryId,
            this.establishmentId
          );
      } else {
        request =
          this.installationsService.getMyRegistryEstablishmentInstallations(
            "",
            "",
            "",
            "",
            "",
            this.registryId,
            this.establishmentId
          );
      }
    } else {
      request = this.installationsService.getRegistryInstallations(
        "",
        "",
        "",
        "",
        "",
        this.registryId
      );
    }

    request.subscribe((response) => {
      if (response["success"]) {
        if (this.establishmentId) {
          this.installations = response["installations"];
        } else {
          this.registryInstallations = response["installations"];
        }
      } else if (response["errors"]) {
        CustomNotifierService.getErrors(response["errors"], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  getEstablishmentInstallation() {
    let request;
    this.requestInProgress.next(true);

    if (this.establishmentId) {
      request =
        this.installationsService.getAllMyRegistryEstablishmentInstallations(
          "",
          "",
          "",
          "",
          "",
          this.activatedRoute.snapshot.parent.params.registry_id,
          this.establishmentId
        );
    } else {
      request = this.installationsService.getMyRegistryEnterprise(
        "",
        "",
        "",
        "",
        "",
        this.activatedRoute.snapshot.parent.params.registry_id
      );
    }

    request.subscribe((response) => {
      if (response["success"]) {
        if (this.establishmentId) {
          this.installations = response["installations"];
        } else {
          this.establishmentInstallations = response["installations"];
        }
      } else if (response["errors"]) {
        CustomNotifierService.getErrors(response["errors"], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  getRegistry() {
    this.requestInProgress.next(true);
    this.registriesService
      .getRegistry(this.activatedRoute.snapshot.parent.params.registry_id)
      .subscribe((response) => {
        if (response["success"]) {
          this.registry = response["registry"];
          if (this.registry && this.registry.exports_module.length > 0) {
            this.exports = this.registry.exports_module;
            if (this.monoOffer && !this.discoverOffer) {
              this.filteredExports = this.exports
                .filter((d) => {
                  if (
                    this.establishmentId &&
                    d["on_dashboard_establishment"] &&
                    d["mono_offer"]
                  ) {
                    if (
                      d.options.indexOf("rus") === -1 ||
                      (d.options.indexOf("rus") !== -1 &&
                        this.establishment.is_rus &&
                        d["mono_offer"])
                    ) {
                      return d;
                    }
                  } else if (
                    !this.establishmentId &&
                    d["on_dashboard_registry"] &&
                    d["mono_offer"]
                  ) {
                    return d;
                  }
                  return false;
                })
                .sort((a, b) => a.order > b.order);
            } else if (this.discoverOffer) {
              this.filteredExports = [];
            } else {
              this.filteredExports = this.exports
                .filter((d) => {
                  if (this.establishmentId && d["on_dashboard_establishment"]) {
                    if (
                      d.options.indexOf("rus") === -1 ||
                      (d.options.indexOf("rus") !== -1 &&
                        this.establishment.is_rus)
                    ) {
                      return d;
                    }
                  } else if (
                    !this.establishmentId &&
                    d["on_dashboard_registry"]
                  ) {
                    return d;
                  }
                  return false;
                })
                .sort((a, b) => a.order > b.order);
            }
            this.firstLoad = false;
          }
        } else if (response["errors"]) {
          CustomNotifierService.getErrors(response["errors"], this.notifier);
        }
        this.requestInProgress.next(false);
      });
  }

  getErrorMessage(param) {
    return this.errors[param] && this.errors[param]["message"];
  }

  downloadReport() {
    if (!this.selectedExport) {
      return;
    }
    this.loader = true;
    this.disableForm();
    const params = {
      establishmentId: this.establishmentId,
      registryId: this.registryId,
      exportType: this.exportType.value,
    };
    const options = {
      withFormations: this.withFormations.value,
      withAnnexesFormations: this.withAnnexesFormations.value,
      withInstallations: this.withInstallations.value,
      withAnnexesInstallations: this.withAnnexesInstallations.value,
      installationsList: this.selectedInstallations,
      withCommissions: this.withCommissions.value,
      withAnnexesCommissions: this.withAnnexesCommissions.value,
      withWorksheets: this.withWorksheets.value,
      withAnnexesWorksheets: this.withAnnexesWorksheets.value,
      withEmployees: this.withEmployees.value,
      withAnnexes: this.withAnnexes.value,
      withDocuments: this.withDocuments.value,
      exportBeginDate: this.exportBeginDate,
      exportEndDate: this.exportEndDate,
    };

    this.exportDownloadService
      .getPdfExport(this.selectedExport.code, params, options)
      .subscribe((response: BufferSource) => {
        try {
          const decoder = new TextDecoder("utf-8");
          const domString = decoder.decode(response);
          const json = JSON.parse(domString);
          CustomNotifierService.getErrors(json["errors"], this.notifier);
        } catch (e) {
          const blob = new Blob([response]);
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          document.body.appendChild(a);
          a.setAttribute("style", "display: none");
          a.href = url;
          const extension =
            this.selectedExport["type"] === "PDF"
              ? "pdf"
              : this.exportType.value.toLowerCase();
          a.download =
            this.selectedExport["name"] +
            " " +
            moment().format("DD-MM-YYYY") +
            "." +
            extension;
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
        }
        this.loader = false;
        this.enableForm();
      });
  }

  disableForm() {
    this.name.disable();
    this.withFormations.disable();
    this.withAnnexesFormations.disable();
    this.withCommissions.disable();
    this.withAnnexesCommissions.disable();
    this.withWorksheets.disable();
    this.withAnnexesWorksheets.disable();
    this.withEmployees.disable();
    this.withInstallations.disable();
    this.withAnnexesInstallations.disable();
    this.withAnnexes.disable();
    this.exportType.disable();
  }

  enableForm() {
    this.name.enable();
    this.withFormations.enable();
    this.withAnnexesFormations.enable();
    this.withCommissions.enable();
    this.withAnnexesCommissions.enable();
    this.withWorksheets.enable();
    this.withAnnexesWorksheets.enable();
    this.withEmployees.enable();
    this.withInstallations.enable();
    this.withAnnexesInstallations.enable();
    this.withAnnexes.enable();
    this.exportType.enable();
  }

  bindModal(modal) {
    this.modalDownload = modal;
  }

  closeModal() {
    this.router
      .navigate([this.activatedRoute.snapshot.parent.url.join("/")])
      .then(() => {});
  }

  selectExport($export) {
    this.generateFlatpickr();
    this.selectedInstallations = [];
    $export.options.forEach((value) => {
      if (value === "with_installations" || value === "installations_list") {
        if (this.registry.id === 5) {
          this.getEstablishmentInstallation();
        } else {
          this.getInstallation();
        }
      }
    });
  }

  generateFlatpickr() {
    Flatpickr();
  }
}
