import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Subject } from 'rxjs';
import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { IssuesRequestService } from '../../../services/issues.request.service';
import IssueCategory from '../../../../shared/interfaces/issue_category';
import { AllIssuesComponent } from '../all-issues/all-issues.component';
import IssueCommentFile from '../../../../shared/interfaces/issue_comment_file';

@Component({
  selector: 'app-create-issue',
  templateUrl: './create-issue.component.html',
  styleUrls: ['./create-issue.component.scss']
})
export class CreateIssueComponent implements OnInit, AfterViewInit, OnDestroy {
  requestInProgress = new Subject();
  requestCount = 0;
  view = '';
  loader = false;
  modal = null;

  currentRoute = '';

  categories: IssueCategory[];
  errors = {};
  selectedCategory: any;
  files: IssueCommentFile[] = [];

  issueForm: FormGroup;
  id = new FormControl('');
  issue_category_id = new FormControl('', Validators.required);
  emergency = new FormControl('', Validators.required);
  content = new FormControl('', Validators.required);
  comment = new FormControl('', Validators.required);

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private issuesService: IssuesRequestService,
    private allIssues: AllIssuesComponent
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loader = this.requestCount > 0;
    });

    this.currentRoute = '/' + this.activatedRoute.snapshot.url.join('/') + '/';

    this.issueForm = this.formBuilder.group({
      id: this.id,
      issue_category_id: this.issue_category_id,
      emergency: this.emergency,
      content: this.content,
      comment: this.comment,
    });

    this.getCategories();
  }

  ngAfterViewInit() {
    this.modal.open();
  }

  ngOnDestroy() {
    this.modal.close();
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  getCategories() {
    let request;
    this.requestInProgress.next(true);

    request = this.issuesService.getCategories();

    request.subscribe(response => {
      if (response['success']) {
        this.categories = response['categories'];
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  createIssue() {
    this.errors = {};
    this.loader = true;

    let request;
    request = this.issuesService.createMyIssue(this.issueForm.value);
    request.subscribe(response => {
      if (response['success']) {
        if (this.files.length) {
          this.createDocuments(response['comment']);
        } else {
          // this.socketService.sendMessage('issue' + this.activatedRoute.parent.snapshot.params.issueId);
          this.issueForm.patchValue({ id: response['issue']['id'] });
          this.modal.close();
          this.router.navigate([this.activatedRoute.snapshot.parent.url.join('/') + '/' + response['issue']['id']]).then(() => {});
          CustomNotifierService.getSuccessCreate(this.notifier);
        }
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.issueForm, this.notifier);
      }
      this.loader = false;
    });
  }

  setCategory() {
    this.issueForm.patchValue({ issue_category_id: this.selectedCategory });
  }

  createDocuments(comment) {
    let request;
    this.errors = {};

    request = this.issuesService.createMyIssueCommentFile(
      comment.issue_id,
      comment.id,
      this.files
    );

    request.subscribe(response => {
      if (response['success']) {
        // this.socketService.sendMessage('issue' + this.activatedRoute.parent.snapshot.params.issueId);
        this.modal.close();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loader = false;
    });
  }

  chooseFile() {
    const element: HTMLElement = document.getElementById('inputFile') as HTMLElement;
    element.click();
  }

  onFileChange(event) {
    if (event.target && event.target.files && event.target.files.length) {
      for (let i = 0; i < event.target.files.length; i++) {
        const reader = new FileReader();
        const file = event.target.files[i];
        reader.readAsDataURL(file);

        reader.onload = () => {
          let extension = '';
          let name = '';

          const split = file.name.split('.');
          if (split.length) {
            name = split[0];
            extension = split[split.length - 1];
          }

          this.files.push({ file_base64: reader.result, name: name, extension: extension });
        };
      }
    } else {
      let extension = '';
      let name = '';

      const split = event.name.split('.');
      if (split.length) {
        name = split[0];
        extension = split[split.length - 1];
      }

      this.files.push({ file_base64: event.file, name: name, extension: extension });
    }
    const element: HTMLInputElement = document.getElementById('inputFile') as HTMLInputElement;
    element.value = '';
  }

  removeFile(index) {
    this.files.splice(index, 1);

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  bindModal(modal) {
    this.modal = modal;
  }

  closeModal() {
    this.allIssues.getIssues();
    this.router.navigate([this.activatedRoute.snapshot.parent.url.join('/')]).then(() => {});
  }

}
