import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Subject } from 'rxjs';
import { NgbTimeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { NgbTimeStringAdapter } from '../../../core/utils/ngb-time-string-adapter';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { UsersRequestService } from '../../../services/users.request.service';
import { SanitaryPoolsRequestService } from '../../../services/sanitary-pools.request.service';
import SanitaryPool from '../../../../shared/interfaces/sanitary_pool';
import { AllSanitaryPoolsComponent } from '../all-sanitary-pools/all-sanitary-pools.component';

import * as moment from 'moment';

declare function Flatpickr(): any;
declare function Dropzones(): any;

@Component({
  selector: 'app-update-sanitary-pool-record',
  templateUrl: './update-sanitary-pool-record.component.html',
  styleUrls: ['./update-sanitary-pool-record.component.scss'],
  providers: [{ provide: NgbTimeAdapter, useClass: NgbTimeStringAdapter }]
})
export class UpdateSanitaryPoolRecordComponent implements OnInit, AfterViewInit, OnDestroy {
  counter = Array;
  canCreate = false;
  canUpdate = false;

  activeTab = 1;

  sanitaryPool: SanitaryPool;
  requestInProgress = new Subject();
  requestCount = 0;

  sanitaryPoolRecordForm: FormGroup;
  record_date = new FormControl('', Validators.required);
  attendance = new FormControl('', Validators.required);
  water_meter = new FormControl('', Validators.required);
  observations = new FormControl('', Validators.required);

  selected_date = null;

  enterprises: any = [];
  user: string;

  errors = {};

  isNew = true;
  loader = false;
  loaderContent = false;
  valueChange = false;

  modalSanitaryPoolRecord = null;

  constructor(
      private router: Router,
      private formBuilder: FormBuilder,
      private notifier: NotifierService,
      private activatedRoute: ActivatedRoute,
      private changeDetector: ChangeDetectorRef,
      private usersService: UsersRequestService,
      private allSanitaryPools: AllSanitaryPoolsComponent,
      private sanitaryPoolsServices: SanitaryPoolsRequestService,
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loaderContent = this.requestCount > 0;
    });

    this.sanitaryPoolRecordForm = this.formBuilder.group({
      record_date: this.record_date,
      attendance: this.attendance,
      transparency: this.formBuilder.group({
        statement_1: new FormControl('', Validators.required),
        statement_2: new FormControl(),
      }),
      water_ph: this.formBuilder.group({
        statement_1: new FormControl('', Validators.required),
        statement_2: new FormControl(),
      }),
      water_disinfectant: this.formBuilder.group({
        statement_1: new FormControl('', Validators.required),
        statement_2: new FormControl(),
      }),
      temperature: this.formBuilder.group({
        statement_1: new FormControl('', Validators.required),
        statement_2: new FormControl(),
      }),
      water_meter: this.water_meter,
      observations: this.observations,
      stabilizer: this.formBuilder.group({
        name: new FormControl('', Validators.required),
        concentration: new FormControl('', Validators.required),
      }),
    });
    this.sanitaryPoolRecordForm.valueChanges.subscribe(() => {
      this.valueChange = true;
    });
    this.getSanitaryPool();
  }

  ngAfterViewInit() {
    Dropzones();
    this.modalSanitaryPoolRecord.open();
  }

  ngOnDestroy() {
    const flatPicker = document.getElementsByClassName('flatpickr-calendar') as HTMLCollection;
    for (let i = flatPicker.length; i >= 0; i--) {
      if (flatPicker[i]) {
        flatPicker[i].remove();
      }
    }

    const dropZones = document.getElementsByClassName('dz-hidden-input') as HTMLCollection;
    for (let i = 0; i < dropZones.length; i++) {
      dropZones[i].remove();
    }

    this.clearTooltip();

    this.modalSanitaryPoolRecord.close();
  }
  clearTooltip() {
    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }
  changeTabs(activeTab) {
    this.activeTab = activeTab;
  }

  getSanitaryPool() {
    this.requestInProgress.next(true);
    this.sanitaryPoolsServices.getSanitaryPool(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.activatedRoute.snapshot.params.sanitary_pool_id
    ).subscribe(response => {
      if (response['success']) {
        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];
        this.sanitaryPool = response['sanitaryPool'];
        if (response['sanitaryPool']['sanitary_pool_records'].length) {
          this.isNew = false;
          this.selected_date = response['sanitaryPool']['sanitary_pool_records'][0]['record_date'];
          this.sanitaryPoolRecordForm.patchValue((response['sanitaryPool']['sanitary_pool_records'][0]));
          this.valueChange = false;
          setTimeout(() => {
            Flatpickr();
          }, 1);
        } else {
          this.record_date.setValue(moment().format('YYYY-MM-DD'));
          this.selected_date = moment().format('YYYY-MM-DD');
          setTimeout(() => {
            Flatpickr();
          }, 1);
        }
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  getSanitaryPoolRecord(selected_date) {
    this.requestInProgress.next(true);
    this.sanitaryPoolsServices.getSanitaryPoolRecord(
        this.activatedRoute.snapshot.parent.params.registry_id,
        this.activatedRoute.snapshot.parent.params.establishment_id,
        this.activatedRoute.snapshot.params.sanitary_pool_id,
        selected_date
    ).subscribe(response => {
      if (response['success']) {
        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];

        if (response['sanitaryPoolRecord'].length) {
          this.isNew = false;
          this.sanitaryPoolRecordForm.patchValue((response['sanitaryPoolRecord'][0]));
          this.valueChange = false;
        } else {
          this.isNew = true;
          this.sanitaryPoolRecordForm.reset();
          this.record_date.setValue(selected_date);
        }
        this.changeDetector.detectChanges();

        if (!this.canUpdate) {
          this.sanitaryPoolRecordForm.disable();
        }
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      setTimeout(() => {
        Flatpickr();
      }, 1);
      this.requestInProgress.next(false);
    });
  }

  updateSanitaryPoolRecord(selected_date = null) {
    if (this.sanitaryPoolRecordForm.valid && this.valueChange) {
      let request;
      this.errors = {};
      this.loader = true;

      const data = {
        ...this.sanitaryPoolRecordForm.value,
      };

      if (this.isNew) {
        request = this.sanitaryPoolsServices.createSanitaryPoolRecord(
          this.activatedRoute.snapshot.parent.params.registry_id,
          this.activatedRoute.snapshot.parent.params.establishment_id,
          this.activatedRoute.snapshot.params.sanitary_pool_id,
          data
        );
      } else {
        request = this.sanitaryPoolsServices.updateSanitaryPoolRecord(
          this.activatedRoute.snapshot.parent.params.registry_id,
          this.activatedRoute.snapshot.parent.params.establishment_id,
          this.activatedRoute.snapshot.params.sanitary_pool_id,
          this.record_date.value,
          data
        );
      }

      request.subscribe(response => {
        if (response['success']) {
          this.loader = false;
          CustomNotifierService.getSuccessUpdate(this.notifier);
          if (selected_date) {
            this.getSanitaryPoolRecord(selected_date);
          } else {
            this.modalSanitaryPoolRecord.close();
          }
        } else if (response['errors']) {
          this.loader = false;
          this.errors = response['errors'];
          CustomNotifierService.getErrors(response['errors'], this.notifier);
        } else {
          this.loader = false;
        }
      });
    } else {
      this.getSanitaryPoolRecord(selected_date);
    }
  }

  bindModal(modal) {
    this.modalSanitaryPoolRecord = modal;
  }
  closeModal() {
    this.router.navigate([this.activatedRoute.snapshot.parent.url.join('/')]).then(() => {
      this.allSanitaryPools.refreshData();
    });
  }
}
