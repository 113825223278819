import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs';

import { AuthService } from '../../../core/auth/auth.service';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { RvratPrescriptionsRequestService } from '../../../services/rvrat-prescriptions.request.service';
import { RegistriesRequestService } from '../../../services/registries.request.service';
import { EstablishmentsRequestService } from '../../../services/establishments.request.service';

import RvratPrescription from '../../../../shared/interfaces/rvrat-prescription';
import bati_file from '../../../../shared/interfaces/bati_file';
import Registry from '../../../../shared/interfaces/registry';
import Establishment from '../../../../shared/interfaces/establishment';
import Intervention from '../../../../shared/interfaces/intervention';
import * as moment from 'moment';


@Component({
  selector: 'app-all-rvrat-prescriptions',
  templateUrl: './all-rvrat-prescriptions.component.html',
  styleUrls: ['./all-rvrat-prescriptions.component.scss']
})
export class AllRvratPrescriptionsComponent implements OnInit, AfterViewInit, OnDestroy {
  changeEnterprise: Subscription;

  canCreate = false;
  canUpdate = false;
  canDelete = false;

  requestInProgress = new Subject();
  requestCount = 0;

  pageName: string;
  previousPageName: string[] = [];
  previousPageRoute: string[] = [];

  currentRoute = '';
  rvratRoute = '';
  view = '';
  is_child = false;

  registry: Registry;
  establishment: Establishment;
  rvrat_prescriptions: RvratPrescription[] = [];
  files: bati_file[] = [];

  count = 0;
  pages = 1;

  search = '';
  sort = '';
  order = 'asc';
  limit = 12;
  page = 1;

  errors = {};

  timeout: any;
  loader = false;

  modalConfirmData = null;
  modalConfirmFile = null;

  constructor(
    private authService: AuthService,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private rvratPrescriptionsService: RvratPrescriptionsRequestService,
    private registriesService: RegistriesRequestService,
    private establishmentsService: EstablishmentsRequestService,
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loader = this.requestCount > 0;
    });

    this.changeEnterprise = this.authService.getEnterprise().subscribe(() => {
      this.getRegistry();
      this.getEstablishment();
      this.getRvratPrescriptions();
    });

    this.currentRoute = '/' + this.activatedRoute.snapshot.url.join('/') + '/';

    if (this.activatedRoute.snapshot.url[10] && this.activatedRoute.snapshot.url[10].path === 'files') {
      this.view = 'rvrat_prescription_files';
      this.sort = 'RvratPrescriptionFiles.name';
      this.pageName = 'Liste des documents';
    } else {
      this.view = 'rvrat_prescriptions';
      this.sort = 'RvratPrescriptions.emergencies';
      this.order = 'desc';
      this.pageName = 'Liste des prescriptions';
    }

    if (this.authService.loaded) {
      this.getRegistry();
      this.getEstablishment();
      this.getRvratPrescriptions();
    }
    this.createBreadcrumbs();
  }

  ngAfterViewInit() {
    this.modalConfirmData.eventOnClose.subscribe(event => {
      if (event) {
        this.requestInProgress.next(true);
        let request;

        request = this.rvratPrescriptionsService.deleteRvratPrescription(
          this.activatedRoute.snapshot.params.registry_id,
          this.activatedRoute.snapshot.params.establishment_id,
          this.activatedRoute.snapshot.params.rvrat_id,
          event
        );

        request.subscribe(response => {
          if (response['success']) {
            this.refreshData();
            CustomNotifierService.getSuccessDelete(this.notifier);
          } else if (response['errors']) {
            CustomNotifierService.getErrors(response['errors'], this.notifier);
          }
          this.requestInProgress.next(false);
        });
      }
    });
    this.modalConfirmFile.eventOnClose.subscribe(event => {
      if (event) {
        this.requestInProgress.next(true);
        let request;

        request = this.rvratPrescriptionsService.deleteFile(
          this.activatedRoute.snapshot.params.registry_id,
          this.activatedRoute.snapshot.params.establishment_id,
          this.activatedRoute.snapshot.params.rvrat_id,
          this.activatedRoute.snapshot.params.rvrat_prescription_id,
          event
        );

        request.subscribe(response => {
          if (response['success']) {
            this.refreshData();
            CustomNotifierService.getSuccessDelete(this.notifier);
          } else if (response['errors']) {
            CustomNotifierService.getErrors(response['errors'], this.notifier);
          }
          this.requestInProgress.next(false);
        });
      }
    });
  }

  ngOnDestroy() {
    this.changeEnterprise.unsubscribe();
  }

  searchData() {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.page = 1;
      this.refreshData();
    }, 500);
  }

  refreshData(sort = this.sort, order = this.order, page = this.page) {
    this.sort = sort;
    this.order = order;
    this.page = page;
    this.getRvratPrescriptions();
  }

  getRegistry() {
    this.requestInProgress.next(true);
    this.registriesService.getRegistry(this.activatedRoute.snapshot.params.registry_id).subscribe(response => {
      if (response['success']) {
        this.registry = response['registry'];
        this.createBreadcrumbs();
      } else if (response['errors']) {
        this.errors = response['errors'];
      }
      this.requestInProgress.next(false);
    });
  }

  getEstablishment() {
    this.requestInProgress.next(true);
    this.establishmentsService.getMyEstablishment(this.activatedRoute.snapshot.params.establishment_id).subscribe(response => {
      if (response['success']) {
        this.establishment = response['establishment'];
        if (this.establishment.parent_id) {
          this.is_child = true;
        }
        this.createBreadcrumbs();
      } else if (response['errors']) {
        this.errors = response['errors'];
      }
      this.requestInProgress.next(false);
    });
  }

  getRvratPrescriptions() {
    let request;
    this.requestInProgress.next(true);

    if (this.view === 'rvrat_prescription_files') {
      request = this.rvratPrescriptionsService.getFiles(
        this.search, this.sort, this.order, this.limit, this.page,
        this.activatedRoute.snapshot.params.registry_id,
        this.activatedRoute.snapshot.params.establishment_id,
        this.activatedRoute.snapshot.params.rvrat_id,
        this.activatedRoute.snapshot.params.rvrat_prescription_id
      );
    } else {
      request = this.rvratPrescriptionsService.getAllRvratPrescriptions(
        this.search, this.sort, this.order, this.limit, this.page,
        this.activatedRoute.snapshot.params.registry_id,
        this.activatedRoute.snapshot.params.establishment_id,
        this.activatedRoute.snapshot.params.rvrat_id
      );
    }

    request.subscribe(response => {
      if (response['success']) {
        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];
        this.canDelete = response['canDelete'];

        if (response['rvrat_prescriptions']) {
          this.rvrat_prescriptions = response['rvrat_prescriptions'];
          if (response['interventions']) {
            this.rvrat_prescriptions.forEach(prescription => {
              response['interventions'].forEach((intervention: Intervention) => {
                intervention.actions.forEach(action => {
                  if (prescription.id === action.id) {
                    prescription.has_intervention = true;
                    if (prescription.deadline_intervention && prescription.deadline_intervention > intervention.deadline) {
                      prescription.deadline_intervention = intervention.deadline;
                    } else if (!prescription.deadline_intervention) {
                      prescription.deadline_intervention = intervention.deadline;
                    }
                  }
                });
              });
              if (prescription.deadline_intervention) {
                prescription.deadline_danger = moment(prescription.deadline_intervention).isBefore(moment());
              }
            });
          }
        } else {
          this.files = response['files'];
        }

        this.count = response['count'];
        this.pages = response['pages'];
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  getEmergencies(prescription) {
    if (prescription.emergencies === 0) {
      return {color: 'visit-low', value: 'Bas'};
    } else if (prescription.emergencies === 1) {
      return {color: 'visit-normal', value: 'Normal'};
    } else if (prescription.emergencies === 2) {
      return {color: 'visit-high', value: 'Haut'};
    } else if (prescription.emergencies === 3) {
      return {color: 'visit-priority', value: 'Prioritaire'};
    }
  }

  downloadFile(file_id, file_name) {
    let request;

    request = this.rvratPrescriptionsService.downloadFile(
      this.activatedRoute.snapshot.params.registry_id,
      this.activatedRoute.snapshot.params.establishment_id,
      this.activatedRoute.snapshot.params.rvrat_id,
      this.activatedRoute.snapshot.params.rvrat_prescription_id,
      file_id
    );

    request.subscribe((response: BufferSource) => {
      try {
        const decoder = new TextDecoder('utf-8');
        const domString = decoder.decode(response);
        const json = JSON.parse(domString);

        CustomNotifierService.getErrors(json['errors'], this.notifier);
      } catch (e) {
        const blob = new Blob([response]);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = file_name;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      }
    });
  }

  filter(property) {
    if (this.sort === property) {
      if (this.order === 'asc') {
        this.order = 'desc';
      } else {
        this.order = 'asc';
      }
    } else {
      this.sort = property;
      this.order = 'asc';
    }

    this.getRvratPrescriptions();
  }

  createBreadcrumbs() {
    this.previousPageName = [];
    this.previousPageRoute = [];
    this.rvratRoute = '/dashboard-registry/' + this.activatedRoute.snapshot.params.registry_id +
      '/establishment/' + this.activatedRoute.snapshot.params.establishment_id;

    if (this.activatedRoute.snapshot.params.registry_id && this.activatedRoute.snapshot.params.establishment_id) {
      this.previousPageName.push('Tableau de bord');
      this.previousPageRoute.push('/');

      this.previousPageName.push(this.registry ? this.registry.name : 'Tableau de bord registre');
      this.previousPageRoute.push('/dashboard-registry/' + this.activatedRoute.snapshot.params.registry_id);

      this.previousPageName.push(this.establishment ? this.establishment.name : 'Tableau de bord établissement');
      this.previousPageRoute.push('/dashboard-registry/' + this.activatedRoute.snapshot.params.registry_id +
        '/establishment/' + this.activatedRoute.snapshot.params.establishment_id);

      this.previousPageName.push('Fiche travaux');
      this.previousPageRoute.push('/dashboard-registry/' + this.activatedRoute.snapshot.params.registry_id +
        '/establishment/' + this.activatedRoute.snapshot.params.establishment_id + '/worksheets');

      this.previousPageName.push('Type de fichier');
      this.previousPageRoute.push('/dashboard-registry/' + this.activatedRoute.snapshot.params.registry_id +
        '/establishment/' + this.activatedRoute.snapshot.params.establishment_id + '/worksheet/' +
        this.activatedRoute.snapshot.params.worksheet_id);

      this.previousPageName.push('Rapport de vérification réglementaire après travaux');
      this.previousPageRoute.push('/dashboard-registry/' + this.activatedRoute.snapshot.params.registry_id +
        '/establishment/' + this.activatedRoute.snapshot.params.establishment_id + '/worksheet/' +
      this.activatedRoute.snapshot.params.worksheet_id + '/rvrat');

      if (this.view === 'rvrat_prescription_files') {
        this.previousPageName.push('Prescriptions');
        this.previousPageRoute.push('/dashboard-registry/' + this.activatedRoute.snapshot.params.registry_id +
          '/establishment/' + this.activatedRoute.snapshot.params.establishment_id + '/worksheet/' +
          this.activatedRoute.snapshot.params.worksheet_id + /rvrat/ +
          this.activatedRoute.snapshot.params.rvrat_id + '/rvrat-prescriptions');
      }
    } else {
      this.previousPageName.push('Tableau de bord');
      this.previousPageRoute.push('/administration');
    }
  }

  dispatchPagination(data) {
    if (data.action === 'total_elements') {
      this.refreshPage(data.value);
    } else if (data.action === 'change_page') {
      this.changePage(data.value);
    }
  }

  refreshPage(limit) {
    this.limit = limit;
    this.page = 1;
    this.refreshData();
  }

  changePage(page) {
    if (page >= 1 && page <= this.pages) {
      this.page = page;
      this.getRvratPrescriptions();
    }
  }

  bindModalConfirmData(modal) {
    this.modalConfirmData = modal;
  }
  bindModalConfirmFile(modal) {
    this.modalConfirmFile = modal;
  }
}
