<div class="main-content">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col">
        <div class="header mb-0">
          <div class="header-body no-border">
            <div class="row align-items-center">
              <div class="col">
                <h1 class="header-title">Formations</h1>

                <small class="form-text text-muted">
                  Liste des formations
                </small>

                <app-breadcrumbs
                  [currentPage]="pageName"
                  [names]="previousPageName"
                  [routes]="previousPageRoute"
                >
                </app-breadcrumbs>
              </div>

              <div
                class="col-12 col-md-auto mt-2 mt-md-0 mb-0"
                *ngIf="canCreate"
              >
                <a
                  class="btn btn-primary d-block d-md-inline-block"
                  [routerLink]="[currentRoute + 'import_massif']"
                >
                  Affectation des personnels à une session formation
                </a>
              </div>

              <div
                class="col-12 col-md-auto mt-2 mt-md-0 mb-0"
                *ngIf="canCreate"
              >
                <a
                  class="btn btn-primary d-block d-md-inline-block"
                  [routerLink]="[currentRoute + 'create']"
                >
                  Ajouter une formation
                </a>
              </div>

              <div
                class="col-12 col-md-auto mt-2 mt-md-0 mb-0"
                *ngIf="canCreate"
              >
                <a
                  class="btn btn-primary d-block d-md-inline-block"
                  [routerLink]="[currentRoute + 'download']"
                >
                  Télechargement
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col">
            <app-searchbar
              [(ngModel)]="search"
              (keyup)="searchData()"
              ngDefaultControl
            ></app-searchbar>
          </div>
        </div>

        <div class="is-loading loader-content" *ngIf="loader"></div>

        <div class="tab-content" *ngIf="!loader && trainings.length">
          <div
            class="tab-pane fade active show"
            id="tabPaneOne"
            role="tabpanel"
          >
            <div class="row listAlias">
              <div class="col-12 d-flex flex-column">
                <div class="card flex-fill">
                  <div class="card-body">
                    <div class="table-responsive">
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th class="href" (click)="filter('Trainings.name')">
                              <a class="text-muted">Nom</a>
                              <span
                                class="fe fe-arrow-down"
                                *ngIf="
                                  sort === 'Trainings.name' && order === 'asc'
                                "
                              ></span>
                              <span
                                class="fe fe-arrow-up"
                                *ngIf="
                                  sort === 'Trainings.name' && order === 'desc'
                                "
                              ></span>
                              <span
                                class="fe fe-arrow-right"
                                *ngIf="sort !== 'Trainings.name'"
                              ></span>
                            </th>

                            <th
                              class="href"
                              (click)="filter('Trainings.frequency')"
                            >
                              <a class="text-muted">Périodicité</a>
                              <span
                                class="fe fe-arrow-down"
                                *ngIf="
                                  sort === 'Trainings.frequency' &&
                                  order === 'asc'
                                "
                              ></span>
                              <span
                                class="fe fe-arrow-up"
                                *ngIf="
                                  sort === 'Trainings.frequency' &&
                                  order === 'desc'
                                "
                              ></span>
                              <span
                                class="fe fe-arrow-right"
                                *ngIf="sort !== 'Trainings.frequency'"
                              ></span>
                            </th>

                            <th
                              class="href"
                              (click)="filter('Trainings.establishment_id')"
                            >
                              <a class="text-muted">Établissement</a>
                              <span
                                class="fe fe-arrow-down"
                                *ngIf="
                                  sort === 'Trainings.establishment_id' &&
                                  order === 'asc'
                                "
                              ></span>
                              <span
                                class="fe fe-arrow-up"
                                *ngIf="
                                  sort === 'Trainings.establishment_id' &&
                                  order === 'desc'
                                "
                              ></span>
                              <span
                                class="fe fe-arrow-right"
                                *ngIf="sort !== 'Trainings.establishment_id'"
                              ></span>
                            </th>

                            <th
                              class="href"
                              (click)="filter('Trainings.registry_id')"
                            >
                              <a class="text-muted">Registre</a>
                              <span
                                class="fe fe-arrow-down"
                                *ngIf="
                                  sort === 'Trainings.registry_id' &&
                                  order === 'asc'
                                "
                              ></span>
                              <span
                                class="fe fe-arrow-up"
                                *ngIf="
                                  sort === 'Trainings.registry_id' &&
                                  order === 'desc'
                                "
                              ></span>
                              <span
                                class="fe fe-arrow-right"
                                *ngIf="sort !== 'Trainings.registry_id'"
                              ></span>
                            </th>

                            <th
                              class="href"
                              (click)="filter('Trainings.priority')"
                            >
                              <a class="text-muted">Status</a>
                              <span
                                class="fe fe-arrow-down"
                                *ngIf="
                                  sort === 'Trainings.priority' &&
                                  order === 'asc'
                                "
                              ></span>
                              <span
                                class="fe fe-arrow-up"
                                *ngIf="
                                  sort === 'Trainings.priority' &&
                                  order === 'desc'
                                "
                              ></span>
                              <span
                                class="fe fe-arrow-right"
                                *ngIf="sort !== 'Trainings.priority'"
                              ></span>
                            </th>

                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            class="dropdown"
                            *ngFor="let training of trainings"
                          >
                            <td>
                              <a
                                class="href"
                                [routerLink]="[currentRoute + training.id]"
                              >
                                {{ training.name }}
                              </a>
                            </td>
                            <td>
                              <a
                                class="href"
                                [routerLink]="[currentRoute + training.id]"
                              >
                                {{
                                  training.frequency &&
                                  training.frequency_duration
                                    ? training.frequency_duration +
                                      " " +
                                      training.frequency
                                    : "Aucune"
                                }}
                              </a>
                            </td>
                            <td>
                              <a
                                class="href"
                                [routerLink]="[currentRoute + training.id]"
                                >{{
                                  training.establishment
                                    ? training.establishment.name
                                    : ""
                                }}
                              </a>
                            </td>
                            <td>
                              <a
                                class="href"
                                [routerLink]="[currentRoute + training.id]"
                                >{{ training.registry.name }}
                              </a>
                            </td>
                            <td>
                              <a
                                class="href"
                                [routerLink]="[currentRoute + training.id]"
                              >
                                <span
                                  class="label label-rounded"
                                  [ngClass]="
                                    training.priority
                                      ? 'label-important'
                                      : 'label-not-important'
                                  "
                                  [attr.aria-label]="
                                    training.priority
                                      ? 'La formation est marquée comme prioritaire'
                                      : 'La formation est marquée comme non prioritaire'
                                  "
                                >
                                  {{
                                    training.priority
                                      ? "Obligatoire"
                                      : "Recommandée"
                                  }}
                                </span>
                              </a>
                            </td>
                            <td class="d-flex flex-row-reverse">
                              <a
                                class="dropdown-ellipses dropdown-toggle href"
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <i class="fe fe-more-vertical"></i>
                              </a>

                              <div class="dropdown-menu dropdown-menu-right">
                                <a
                                  class="dropdown-item href"
                                  [routerLink]="[
                                    currentRoute + training.id + '/sessions'
                                  ]"
                                >
                                  Consulter les sessions
                                </a>

                                <a
                                  class="dropdown-item href"
                                  [routerLink]="[
                                    currentRoute +
                                      training.id +
                                      '/employees_train'
                                  ]"
                                >
                                  Consulter les personnes formées
                                </a>

                                <a
                                  class="dropdown-item href"
                                  [routerLink]="[
                                    currentRoute +
                                      training.id +
                                      '/employees_must_train'
                                  ]"
                                >
                                  Consulter les personnes non formées et devant
                                  l’être
                                </a>

                                <a
                                  class="dropdown-item href"
                                  [routerLink]="[currentRoute + training.id]"
                                >
                                  {{
                                    canUpdate
                                      ? "Éditer la formation"
                                      : "Consulter la formation"
                                  }}
                                </a>

                                <a
                                  class="dropdown-item href"
                                  (click)="modalConfirmData.open(training.id)"
                                  *ngIf="canDelete"
                                >
                                  Supprimer la formation
                                </a>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <app-pagination
                      (returnEvent)="dispatchPagination($event)"
                      [count_elements]="count"
                      [current_page]="page"
                      [elements_length]="trainings.length"
                      [limit]="limit"
                      [total_pages]="pages"
                      ngDefaultControl
                    >
                    </app-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="loader-content" *ngIf="!loader && !trainings.length">
          <p>Aucun résultat</p>
        </div>
      </div>
    </div>
  </div>
</div>

<app-confirm-modal [type]="'delete_data'" #confirmData>{{
  bindModalConfirmData(confirmData)
}}</app-confirm-modal>

<router-outlet></router-outlet>
