<app-modal-right (eventOnClose)="closeModal()" #reportModal>{{ bindModal(reportModal) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">{{ isNew ? 'Création' : canUpdate ? 'Mise à jour' : 'Consultation' }}</h6>

        <h1 class="header-title">Rapport</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <form [formGroup]="reportForm" [hidden]="loaderContent">
      <div class="row">
        <div class="col-12">
          <!-- YEAR -->
          <div class="form-group">
            <label for="validationYear" required>Année</label>
            <input type="number" formControlName="year" minlength="4" maxlength="4" min="1900" max="2100" class="form-control" id="validationYear" placeholder="Année"
                   [ngClass]="{ 'is-invalid': year.invalid && (year.dirty || year.touched) }">
            <div *ngIf="year.invalid && (year.dirty || year.touched)" class="invalid-feedback">
              {{ getErrorMessage("year") }}
            </div>
          </div>

          <!-- WORKS -->
          <div class="form-group" *ngIf="isNew">
            <label for="validationTravaux">Travaux</label>
            <textarea formControlName="works" class="form-control" id="validationTravaux" placeholder="Travaux" rows="5" (keydown.enter)="addTextareaBullet($event)" (paste)="pasteTextareaBullet($event)" (change)="addTextareaBullet($event)"
                      [ngClass]="{ 'is-invalid': works.invalid && (works.dirty || works.touched) }"></textarea>
            <div *ngIf="works.invalid && (works.dirty || works.touched)" class="invalid-feedback">
              {{ getErrorMessage("works") }}
            </div>
            <span class="badge badge-soft-info d-inline-flex"><span class="fe fe-info mr-1"></span>Une observation par ligne</span>
          </div>

          <!-- ESTIMATE WORK -->
          <div class="form-group">
            <label for="validationFinancialEstimation">Estimation travaux € HT</label>
            <input type="number" formControlName="financial_estimation" class="form-control" id="validationFinancialEstimation" placeholder="0" [ngClass]="{ 'is-invalid': financial_estimation.invalid && (financial_estimation.dirty || financial_estimation.touched) }" min="0">
            <div *ngIf="financial_estimation.invalid && (financial_estimation.dirty || financial_estimation.touched)" class="invalid-feedback">
              {{ getErrorMessage("financial_estimation") }}
            </div>
          </div>

          <!-- FINANCIAL ESTIMATE -->
          <div class="form-group">
            <label for="validationFinancialEstimationIntellectual">Estimation prestations intellectuelles éventuelles € HT</label>
            <input type="number" formControlName="financial_estimation_intellectual" class="form-control" id="validationFinancialEstimationIntellectual" placeholder="0" [ngClass]="{ 'is-invalid': financial_estimation_intellectual.invalid && (financial_estimation_intellectual.dirty || financial_estimation_intellectual.touched) }" min="0">
            <div *ngIf="financial_estimation_intellectual.invalid && (financial_estimation_intellectual.dirty || financial_estimation_intellectual.touched)" class="invalid-feedback">
              {{ getErrorMessage("financial_estimation_intellectual") }}
            </div>
          </div>

          <!-- FINANCIAL REAL -->
          <div class="form-group">
            <label for="validationFinancialReal">Coût TOTAL réel € HT</label>
            <input type="number" formControlName="financial_real" class="form-control" id="validationFinancialReal" placeholder="0" [ngClass]="{ 'is-invalid': financial_real.invalid && (financial_real.dirty || financial_real.touched) }" min="0">
            <div *ngIf="financial_real.invalid && (financial_real.dirty || financial_real.touched)" class="invalid-feedback">
              {{ getErrorMessage("financial_real") }}
            </div>
          </div>

          <!-- COMMENTS -->
          <div class="form-group">
            <label for="validationCommentReport">Commentaires éventuels</label>
            <textarea formControlName="comment" class="form-control" id="validationCommentReport" placeholder="Commentaires éventuels" rows="5"></textarea>
            <div *ngIf="comment.invalid && (comment.dirty || comment.touched)" class="invalid-feedback">
              {{ getErrorMessage("comment") }}
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div modal-footer class="w-100" *ngIf="isNew || canUpdate">
    <button class="btn btn-primary w-100" (click)="isNew ? createReport() : updateReport()" [ngClass]="{ 'is-loading is-loading-sm': loader }" [disabled]="loader || reportForm.invalid">
      {{ isNew ? 'Créer' : 'Mettre à jour' }}
    </button>
  </div>
</app-modal-right>
