import { Routes } from '@angular/router';

import { AllTrainingsComponent } from './all-trainings/all-trainings.component';
import { UpdateTrainingComponent } from './update-training/update-training.component';
import { UpdateTrainingCustomComponent } from './update-training-custom/update-training-custom.component';
import { UpdateDateTrainingComponent } from './update-date-training/update-date-training.component';

import { AllTrainingsFilesComponent } from './all-trainings-files/all-trainings-files.component';
import { UploadFileComponent } from './upload-file/upload-file.component';

import { ShowTrainingComponent } from './show-training/show-training.component';

import { AllEmployeesComponent } from './all-employees/all-employees.component';
import { UpdateEmployeeComponent } from './update-employee/update-employee.component';

import { ModalLogsComponent } from '../logs/modal-logs/modal-logs.component';

export const trainingsRoutes: Routes = [
  { // Admin - generic trainings -
    path: 'trainings',
    component: AllTrainingsComponent,
    children: [
      {
        path: 'create',
        component: UpdateTrainingComponent
      },
      {
        path: ':training_id',
        component: UpdateTrainingComponent
      },
      {
        path: ':training_id/logs',
        component: ModalLogsComponent
      }
    ]
  },
  { // User - Show or hide trainings -
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/trainings/show',
    component: ShowTrainingComponent,
  },
  { // User - Employees -
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/employees-trainings',
    component: AllEmployeesComponent,
    children: [
      {
        path: 'create',
        component: UpdateEmployeeComponent
      },
      { // User - Add employees with a spreadsheet -
        path: 'sheet',
        component: UploadFileComponent
      },
      {
        path: ':employee_id',
        component: UpdateEmployeeComponent
      }
    ]
  },
  { // User - All trainings -
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/trainings',
    component: AllTrainingsComponent,
    children: [
      {
        path: 'create',
        component: UpdateTrainingCustomComponent
      },
      {
        path: ':training_id',
        component: UpdateTrainingCustomComponent
      },
      {
        path: ':training_id/logs',
        component: ModalLogsComponent
      }
    ]
  },
  { // User - Dates trainings -
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/trainings/:training_id/dates-training',
    component: AllTrainingsComponent,
    children: [
      {
        path: 'create',
        component: UpdateDateTrainingComponent
      },
      {
        path: ':date_training_id',
        component: UpdateDateTrainingComponent
      },
      {
        path: ':date_training_id/logs',
        component: ModalLogsComponent
      }
    ]
  },
  { // User - Files of a date training -
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/trainings/:training_id/dates-training/:date_training_id/files',
    component: AllTrainingsFilesComponent,
    children: [
      {
        path: 'create',
        component: UploadFileComponent
      },
      {
        path: ':file_id',
        component: UploadFileComponent
      }
    ]
  },
  { // User - Employees and their files for a date training -
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/trainings/:training_id/dates-training/:date_training_id' +
      '/employees',
    component: AllTrainingsFilesComponent,
    children: [
      {
        path: 'create/:dte_id',
        component: UploadFileComponent
      },
      {
        path: ':dte_id/:file_id',
        component: UploadFileComponent
      }
    ]
  }
];
