import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Subject } from 'rxjs';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { UsersRequestService } from '../../../services/users.request.service';

import { AllOfficialsComponent } from '../all-officials/all-officials.component';
import { OfficialsRequestService } from '../../../services/officials.request.service';

import BatiFile from '../../../../shared/interfaces/bati_file';
import { EnterprisesRequestService } from '../../../services/enterprises.request.service';
import { EstablishmentsRequestService } from '../../../services/establishments.request.service';
import Establishment from '../../../../shared/interfaces/establishment';

declare function Flatpickr(): any;
declare function Dropzones(): any;

@Component({
  selector: 'app-update-official',
  templateUrl: './update-official.component.html',
  styleUrls: ['./update-official.component.scss']
})
export class UpdateOfficialComponent implements OnInit, AfterViewInit, OnDestroy {
  canCreate = false;
  canUpdate = false;

  requestInProgress = new Subject();
  requestCount = 0;

  officialForm: FormGroup;
  id = new FormControl('');
  establishment_registry_id = new FormControl('');
  visit_date = new FormControl('', Validators.required);
  report_date = new FormControl(null);
  prescriptions = new FormControl(null);
  enterprise_id = new FormControl(null);
  user_id = new FormControl(null);
  establishment_id = new FormControl(null);
  deadline = new FormControl(null);
  emergencies = new FormControl(null);
  financial_estimation = new FormControl(0, Validators.min(0));
  comment = new FormControl(null);
  updated = new FormControl('');

  files: BatiFile[] = [];
  enterprises: any = [];
  user: string;

  errors = {};

  isNew = true;
  loader = false;
  loaderContent = false;

  modalOfficial = null;

  oldPrescriptions: string = null;
  countOldPrescriptions = 0;

  establishment: Establishment;
  establishments: Establishment[];
  establishment_selected: number;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private allOfficials: AllOfficialsComponent,
    private usersService: UsersRequestService,
    private officialsService: OfficialsRequestService,
    private establishmentsService: EstablishmentsRequestService,
    private enterprisesService: EnterprisesRequestService
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loaderContent = this.requestCount > 0;
    });

    this.officialForm = this.formBuilder.group({
      id: this.id,
      establishment_registry_id: this.establishment_registry_id,
      visit_date: this.visit_date,
      report_date: this.report_date,
      prescriptions: this.prescriptions,
      enterprise_id: this.enterprise_id,
      user_id: this.user_id,
      establishment_id: this.establishment_id,
      deadline: this.deadline,
      emergencies: this.emergencies,
      financial_estimation: this.financial_estimation,
      comment: this.comment,
      updated: this.updated,
    });

    if (this.activatedRoute.snapshot.params.official_id) {
      this.isNew = false;
      this.getOfficial();
    } else {
      Flatpickr();
    }

    this.getEstablishment();
  }

  ngAfterViewInit() {
    Dropzones();
    this.modalOfficial.open();
  }

  ngOnDestroy() {
    const flatPicker = document.getElementsByClassName('flatpickr-calendar') as HTMLCollection;
    for (let i = flatPicker.length; i >= 0; i--) {
      if (flatPicker[i]) {
        flatPicker[i].remove();
      }
    }

    const dropZones = document.getElementsByClassName('dz-hidden-input') as HTMLCollection;
    for (let i = 0; i < dropZones.length; i++) {
      dropZones[i].remove();
    }

    this.modalOfficial.close();
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  getOfficial() {
    let request;
    this.requestInProgress.next(true);

    request = this.officialsService.getOfficial(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.activatedRoute.snapshot.params.official_id
    );

    request.subscribe(response => {
      if (response['success']) {
        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];

        this.officialForm.patchValue(response['official']);

        this.prescriptions.setValue('');
        const temp = [];
        this.countOldPrescriptions = response['official']['official_prescriptions'].length;
        response['official']['official_prescriptions'].forEach(element => {
          temp.push(element.name);
        });
        this.oldPrescriptions = temp.join('\n');

        if (!this.canUpdate) {
          this.officialForm.disable();
        }

        if (this.officialForm.value.enterprise_id && this.officialForm.value.user_id) {
          this.user = this.officialForm.value.enterprise_id + '-' + this.officialForm.value.user_id;
        }
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      Flatpickr();
      this.requestInProgress.next(false);
    });
  }

  getEstablishment() {
    this.requestInProgress.next(true);
    this.establishmentsService.getMyEstablishment(this.activatedRoute.snapshot.parent.params.establishment_id).subscribe(response => {
      if (response['success']) {
        this.establishment = response['establishment'];
        if (!this.establishment.parent_id) {
          this.getEstablishments();
          this.getMyUsers();
          this.getEnterprises();
        }
      } else if (response['errors']) {
        this.errors = response['errors'];
      }
      this.requestInProgress.next(false);
    });
  }

  getEstablishments() {
    this.requestInProgress.next(true);
    this.establishmentsService.geMyEstablishmentsChildren(
      '', '', '', '', '',
      this.activatedRoute.snapshot.parent.params.establishment_id
    ).subscribe(response => {
      if (response['success']) {
        this.establishments = response['establishments'];
      } else if (response['errors']) {
        this.errors = response['errors'];
      }
      this.requestInProgress.next(false);
    });
  }

  changeEstablishment() {
    this.establishment_id.setValue(this.establishment_selected);
  }

  getEnterprises() {
    this.requestInProgress.next(true);

    this.enterprisesService.getProviders('', '', '', '', '').subscribe(response => {
      if (response['success']) {
        response['enterprises'].forEach(enterprise => {
          this.getUsers(enterprise);
        });
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  getUsers(enterprise) {
    this.requestInProgress.next(true);

    this.usersService.getProviderUsers('', '', '', '', '', enterprise.id).subscribe(response => {
      if (response['success']) {
        response['users'].forEach(user => {
          this.enterprises.push({
            id: enterprise.id + '-' + user.id,
            name: enterprise.name + ' - ' + user.firstname + ' ' + user.lastname
          });
        });
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  getMyUsers() {
    this.requestInProgress.next(true);

    this.usersService.getMyUsers('', '', '', '', '').subscribe(response => {
      if (response['success']) {
        response['users'].forEach(user => {
          this.enterprises.push({
            id: response['enterprise'].id + '-' + user.id,
            name: response['enterprise'].name + ' - ' + user.firstname + ' ' + user.lastname
          });
        });
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  changeUser() {
    if (this.user) {
      const split = this.user.split('-');
      this.enterprise_id.setValue(split[0]);
      this.user_id.setValue(split[1]);
    } else {
      this.enterprise_id.setValue(null);
      this.user_id.setValue(null);
    }
  }

  createOfficial() {
    let request;
    this.errors = {};
    this.loader = true;

    if (this.prescriptions.value) {
      const temp = [];
      const prescriptions: string = this.prescriptions.value;
      const splitValue = prescriptions.split('\n');

      splitValue.forEach(value => {
        const reg = new RegExp(/^\s*•\s+/gm);
        value = value.replace(reg, '');
        if (value) {
          temp.push(value);
        }
      });
      this.prescriptions.setValue(temp);
    }

    request = this.officialsService.createOfficial(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.officialForm.value
    );

    request.subscribe(response => {
      if (response['success']) {
        this.isNew = false;
        if (this.files.length) {
          this.createDocuments(response['official']['id']);
        } else {
          this.loader = false;
          this.modalOfficial.close();
          CustomNotifierService.getSuccessCreate(this.notifier);
        }
      } else if (response['errors']) {
        this.loader = false;
        this.errors = response['errors'];
        this.prescriptions.setValue(null);
        CustomNotifierService.getErrorsForm(response['errors'], this.officialForm, this.notifier);
      } else {
        this.loader = false;
      }

      if (this.prescriptions.value) {
        this.prescriptions.setValue(this.prescriptions.value.join('\n'));
        this.prescriptions.setValue(this.prescriptions.value.replace(/(^|\r\n|\n)([^• ]|$)/g, '$1• $2'));
      }
    });
  }

  updateOfficial() {
    let request;
    let official;

    this.errors = {};
    this.loader = true;

    if (this.prescriptions.value) {
      const temp = [];
      const prescriptions: string = this.prescriptions.value;
      const splitValue = prescriptions.split('\n');

      splitValue.forEach(value => {
        if (value) {
          const reg = new RegExp(/^\s*•\s+/gm);
          temp.push(value.replace(reg, ''));
        }
      });

      this.prescriptions.setValue(temp);
    }

    official = {
      ...this.officialForm.value,
      id: this.id.value,
      establishment_registry_id: this.establishment_registry_id.value
    };

    request = this.officialsService.updateOfficial(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.activatedRoute.snapshot.params.official_id,
      official
    );

    request.subscribe(response => {
      if (response['success']) {
        if (this.files.length) {
          this.createDocuments(this.activatedRoute.snapshot.params.official_id);
        } else {
          this.loader = false;
          this.modalOfficial.close();
          CustomNotifierService.getSuccessUpdate(this.notifier);
        }
      } else if (response['errors']) {
        this.loader = false;
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }

      if (this.prescriptions.value) {
        this.prescriptions.setValue(this.prescriptions.value.join('\n'));
        this.prescriptions.setValue(this.prescriptions.value.replace(/(^|\r\n|\n)([^• ]|$)/g, '$1• $2'));
      }
    });
  }

  addTextareaBullet(event) {
    if (event.srcElement.value) {
      event.srcElement.value = event.srcElement.value.replace(/(^|\r\n|\n)([^• ]|$)/g, '$1• $2');
    }
  }

  pasteTextareaBullet(event) {
    setTimeout(() => {
      event.srcElement.value = event.srcElement.value.replace(/^\s+|\s+$/g, '');
      event.srcElement.value = event.srcElement.value.replace(/[\r\n]+/g, '\n');
      event.srcElement.value = event.srcElement.value.replace(/(^|\r\n|\n)([^• ]|$)/g, '$1• $2');
    }, 150);
  }

  createDocuments(official_id) {
    let request;
    this.errors = {};

    request = this.officialsService.createFile(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      official_id,
      this.files
    );

    request.subscribe(response => {
      if (response['success']) {
        this.modalOfficial.close();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loader = false;
    });
  }

  chooseFile() {
    const element: HTMLElement = document.getElementById('inputFile') as HTMLElement;
    element.click();
  }

  onFileChange(event) {
    if (event.target && event.target.files && event.target.files.length) {
      for (let i = 0; i < event.target.files.length; i++) {
        const reader = new FileReader();
        const file = event.target.files[i];
        reader.readAsDataURL(file);

        reader.onload = () => {
          let extension = '';
          let name = '';

          const split = file.name.split('.');
          if (split.length) {
            name = split[0];
            extension = split[split.length - 1];
          }

          this.files.push({ file_base64: reader.result, name: name, extension: extension });
        };
      }
    } else {
      let extension = '';
      let name = '';

      const split = event.name.split('.');
      if (split.length) {
        name = split[0];
        extension = split[split.length - 1];
      }

      this.files.push({ file_base64: event.file, name: name, extension: extension });
    }
    const element: HTMLInputElement = document.getElementById('inputFile') as HTMLInputElement;
    element.value = '';
  }

  removeFile(index) {
    this.files.splice(index, 1);

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  bindModal(modal) {
    this.modalOfficial = modal;
  }

  closeModal() {
    this.router.navigate([this.activatedRoute.snapshot.parent.url.join('/')]).then(() => {
      this.allOfficials.refreshData();
    });
  }
}
