import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RisksRequestService } from '../../services/risks.request.service';

import { AllUnitiesModule } from './risks-unities/all-unities/all-unities.module';
import { UpdateUnityModule } from './risks-unities/update-unity/update-unity.module';

import { AllUsersModule } from './risks-users/all-users/all-users.module';
import { UpdateUserModule } from './risks-users/update-user/update-user.module';

import { AllRisksModule } from './risks/all-risks/all-risks.module';
import { UpdateRiskModule } from './risks/update-risk/update-risk.module';

import { AllCostsModule } from './risks-costs/all-costs/all-costs.module';
import { UpdateCostModule } from './risks-costs/update-cost/update-cost.module';

import { AllDamagesModule } from './risks-damages/all-damages/all-damages.module';
import { UpdateDamageModule } from './risks-damages/update-damage/update-damage.module';

import { AllActionsModule } from './risks-actions/all-actions/all-actions.module';

import {AllRiskFilesModule} from './risks-files/all-risk-files/all-risk-files.module';
import {UploadRiskFileModule} from './risks-files/upload-risk-file/upload-risk-file.module';

import { AllFamiliesModule } from './risks-family/all-families/all-families.module';
import { UpdateFamilyModule } from './risks-family/update-family/update-family.module';

import { ShowUnityModule } from './risks-unities/show-unity/show-unity.module';
import { ShowUserModule } from './risks-users/show-user/show-user.module';
import { GlobalUnitiesModule } from './risks-unities/global_unities/global-unities.module';
import { GlobalUsersModule } from './risks-users/global_users/global-users.module';

@NgModule({
  imports: [
    CommonModule,

    AllUnitiesModule,
    UpdateUnityModule,

    AllUsersModule,
    UpdateUserModule,

    AllRisksModule,
    UpdateRiskModule,

    AllCostsModule,
    UpdateCostModule,

    AllDamagesModule,
    UpdateDamageModule,

    AllRiskFilesModule,
    UploadRiskFileModule,

    AllActionsModule,

    AllFamiliesModule,
    UpdateFamilyModule,

    ShowUnityModule,
    ShowUserModule,

    GlobalUnitiesModule,
    GlobalUsersModule,
  ],
  providers: [
    RisksRequestService
  ]
})
export class RisksModule {}
