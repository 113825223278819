<app-modal-right (eventOnClose)="closeModal()" #employee
  >{{ bindModal(employee) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">
          {{ isNew ? "Création" : canUpdate ? "Mise à jour" : "Consultation" }}
        </h6>

        <h1 class="header-title">Membre du personnel</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <form [formGroup]="employeeForm" [hidden]="loaderContent">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="validationFirstname" required>Prénom</label>
            <input
              type="text"
              formControlName="firstname"
              class="form-control"
              id="validationFirstname"
              placeholder="Prénom"
              [ngClass]="{
                'is-invalid':
                  firstname.invalid && (firstname.dirty || firstname.touched)
              }"
            />
            <div
              *ngIf="
                firstname.invalid && (firstname.dirty || firstname.touched)
              "
              class="invalid-feedback"
            >
              {{ getErrorMessage("firstname") }}
            </div>
          </div>

          <div class="form-group">
            <label for="validationLastname" required>Nom</label>
            <input
              type="text"
              formControlName="lastname"
              class="form-control"
              id="validationLastname"
              placeholder="Nom"
              [ngClass]="{
                'is-invalid':
                  lastname.invalid && (lastname.dirty || lastname.touched)
              }"
            />
            <div
              *ngIf="lastname.invalid && (lastname.dirty || lastname.touched)"
              class="invalid-feedback"
            >
              {{ getErrorMessage("lastname") }}
            </div>
          </div>

          <div class="form-group">
            <label for="validationYearEntryService" required>Email</label>
            <input
              type="email"
              formControlName="email"
              class="form-control"
              id="validationYearEntryService"
              placeholder="Email"
              [ngClass]="{
                'is-invalid': email.invalid && (email.dirty || email.touched)
              }"
            />
            <div
              *ngIf="email.invalid && (email.dirty || email.touched)"
              class="invalid-feedback"
            >
              {{ getErrorMessage("email") }}
            </div>
          </div>

          <div class="form-group" *ngIf="isNew">
            <label for="validationRegistrie" required>Régistrie</label>
            <select
              class="form-control"
              data-toggle="select"
              formControlName="registry_id"
              id="validationRegistrie"
              (change)="getEstablishment()"
            >
              <option [ngValue]="null" [disabled]="true" class="text-muted">
                Sélectionner un registre
              </option>
              <ng-container *ngFor="let registrie of registries">
                <option [value]="registrie.id">{{ registrie.name }}</option>
              </ng-container>
            </select>
          </div>

          <div class="form-group" *ngIf="isNew">
            <label for="validationEstablishment" required>Établissement</label>
            <select
              class="form-control"
              data-toggle="select"
              formControlName="establishment_id"
              id="validationEstablishment"
              [attr.disabled]="disableSelect ? '' : null"
            >
              <option [ngValue]="null" [disabled]="true" class="text-muted">
                {{
                  disableSelect
                    ? "Veuillez d'abord selectionner un régistre"
                    : "Sélectionner un établissement"
                }}
              </option>
              <ng-container *ngFor="let estab of establishment">
                <option [value]="estab.id">{{ estab.name }}</option>
              </ng-container>
            </select>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div modal-footer class="w-100" *ngIf="isNew || canUpdate">
    <button
      class="btn btn-primary w-100"
      (click)="isNew ? createEmployee() : updateEmployee()"
      [ngClass]="{ 'is-loading is-loading-sm': loader }"
      [disabled]="loader || employeeForm.invalid"
    >
      {{ isNew ? "Créer" : "Mettre à jour" }}
    </button>
  </div>
</app-modal-right>
