import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { HttpClient } from '@angular/common/http';
import { NotifierService } from 'angular-notifier';

@Injectable()
export class CalendarRequestService extends RequestService {
  constructor(
    protected http: HttpClient,
    protected notifier: NotifierService
  ) {
    super(http, notifier);
  }

  public getAllCheckpoint(period, day, registry_id = '', establishment_id = '', establishments = [], registries = []) {
    return this.get('calendar/checkpoints/' + day + '?period=' + period +
      '&registry_id=' + registry_id + '&establishment_id=' + establishment_id +
      '&establishments=' + establishments.join(',') +
      '&registries=' + registries.join(','));
  }

  public getAllObservations(period, day, registry_id = '', establishment_id = '', establishments = [], registries = [],
                            reportDate = '', limit = null, page = null) {
    return this.get('calendar/reports/' + day + '?period=' + period +
      '&registry_id=' + registry_id + '&establishment_id=' + establishment_id +
      '&establishments=' + establishments.join(',') +
      '&registries=' + registries.join(',') +
      '&reportDate=' + reportDate +
      '&limit=' + limit +
      '&page=' + page);
  }

  public getAllCommissions(period, day, registry_id = '', establishment_id = '', establishments = [], registries = []) {
    return this.get('calendar/commissions/' + day + '?period=' + period +
      '&registry_id=' + registry_id + '&establishment_id=' + establishment_id +
      '&establishments=' + establishments.join(',') +
      '&registries=' + registries.join(','));
  }

  public getAllAsbestos(period, day, registry_id = '', establishment_id = '', establishments = [], registries = []) {
    return this.get('calendar/asbestos/' + day + '?period=' + period +
      '&registry_id=' + registry_id + '&establishment_id=' + establishment_id +
      '&establishments=' + establishments.join(',') +
      '&registries=' + registries.join(','));
  }

  public getAllTrainings(period, day, registry_id = '', establishment_id = '', establishments = [], registries = []) {
    return this.get('calendar/trainings/' + day + '?period=' + period +
      '&registry_id=' + registry_id + '&establishment_id=' + establishment_id +
      '&establishments=' + establishments.join(',') +
      '&registries=' + registries.join(','));
  }

  // todo
  public getAllRisks(period, day, registry_id = '', establishment_id = '', establishments = [], registries = []) {
    return this.get('calendar/risks/' + day + '?period=' + period +
      '&registry_id=' + registry_id + '&establishment_id=' + establishment_id +
      '&establishments=' + establishments.join(',') +
      '&registries=' + registries.join(','));
  }
}
