import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AllCheckpointsModule } from './all-checkpoints/all-checkpoints.module';
import { UpdateCheckpointModule } from './update-checkpoint/update-checkpoint.module';
import { UpdateMyCheckpointModule } from './update-my-checkpoint/update-my-checkpoint.module';
import { UpdateCheckpointCustomModule } from './update-checkpoint-custom/update-checkpoint-custom.module';
import { CheckpointsRequestService } from '../../services/checkpoints.request.service';
import { InstallationsRequestService } from '../../services/installations.request.service';
import { RegistriesRequestService } from '../../services/registries.request.service';
import { TooltipCheckpointModule } from './tooltip-checkpoint/tooltip-checkpoint.module';

@NgModule({
  imports: [
    CommonModule,
    AllCheckpointsModule,
    UpdateCheckpointModule,
    UpdateMyCheckpointModule,
    UpdateCheckpointCustomModule,
    TooltipCheckpointModule
  ],
  providers: [
    CheckpointsRequestService,
    InstallationsRequestService,
    RegistriesRequestService
  ]
})
export class CheckpointsModule {}
