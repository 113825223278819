import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Subject } from 'rxjs';
import { NgbTimeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { NgbTimeStringAdapter } from '../../../core/utils/ngb-time-string-adapter';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { UsersRequestService } from '../../../services/users.request.service';
import { PreventionsRequestService } from '../../../services/preventions.request.service';

import { AllPreventionsComponent } from '../all-preventions/all-preventions.component';

import BatiFile from '../../../../shared/interfaces/bati_file';

declare function Flatpickr(): any;
declare function Dropzones(): any;

@Component({
  selector: 'app-update-prevention-simplified',
  templateUrl: './update-prevention-simplified.component.html',
  styleUrls: ['./update-prevention-simplified.component.scss'],
  providers: [{ provide: NgbTimeAdapter, useClass: NgbTimeStringAdapter }]
})
export class UpdatePreventionSimplifiedComponent implements OnInit, AfterViewInit, OnDestroy {
  counter = Array;
  canCreate = false;
  canUpdate = false;

  activeTab = 1;

  requestInProgress = new Subject();
  requestCount = 0;

  preventionForm: FormGroup;
  referency = new FormControl('');
  eu_social_reason = new FormControl('', Validators.required);
  eu_address = new FormControl('');
  eu_phone = new FormControl('');
  eu_legal_manager_name = new FormControl('');
  eu_legal_manager_function = new FormControl('');
  eu_legal_manager_phone = new FormControl('');
  eu_security_manager_name = new FormControl('');
  eu_security_manager_function = new FormControl('');
  eu_security_manager_phone = new FormControl('');
  eu_informations = new FormControl('');
  operation = new FormControl('', Validators.required);
  place = new FormControl('');
  begin_date = new FormControl('', Validators.required);
  end_date = new FormControl('', Validators.required);
  duration_estimation = new FormControl('', Validators.required);
  time_slot = new FormControl('');
  works = new FormControl('');
  descriptions = new FormControl('');
  visit_date = new FormControl('', Validators.required);

  files: BatiFile[] = [];
  enterprises: any = [];
  user: string;
  risks = [];

  errors = {};

  isNew = true;
  loader = false;
  loaderContent = false;

  modalPrevention = null;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private allPreventions: AllPreventionsComponent,
    private usersService: UsersRequestService,
    private preventionsService: PreventionsRequestService,
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loaderContent = this.requestCount > 0;
    });

    this.preventionForm = this.formBuilder.group({
      referency: this.referency,
      eu_social_reason: this.eu_social_reason,
      eu_address: this.eu_address,
      eu_phone: this.eu_phone,
      eu_legal_manager_name: this.eu_legal_manager_name,
      eu_legal_manager_function: this.eu_legal_manager_function,
      eu_legal_manager_phone: this.eu_legal_manager_phone,
      eu_security_manager_name: this.eu_security_manager_name,
      eu_security_manager_function: this.eu_security_manager_function,
      eu_security_manager_phone: this.eu_security_manager_phone,
      eu_informations: this.eu_informations,
      operation: this.operation,
      place: this.place,
      begin_date: this.begin_date,
      end_date: this.end_date,
      duration_estimation: this.duration_estimation,
      time_slot: this.time_slot,
      works: this.works,
      descriptions: this.descriptions,
      prevention_providers: this.formBuilder.array([]) as FormArray,
      participants: this.formBuilder.array([]) as FormArray,
      visit_date: this.visit_date,
      actions: this.formBuilder.group({
        bool1: new FormControl(false),
        item1: new FormControl(''),
        bool2: new FormControl(false),
        item2: new FormControl(''),
        bool3: new FormControl(false),
        item3: new FormControl(''),
        bool4: new FormControl(false),
        item4: new FormControl(''),
        bool5: new FormControl(false),
        item5: new FormControl(''),
        bool6: new FormControl(false),
        item6: new FormControl(''),
        bool7: new FormControl(false),
        item7: new FormControl(''),
        bool8: new FormControl(false),
        item8: new FormControl(''),
      }),
      prevention_materials: this.formBuilder.array([]) as FormArray,
      emergency_numbers: this.formBuilder.array([]) as FormArray,
      emergency_materials: this.formBuilder.array([]) as FormArray,
      documents: this.formBuilder.group({
        doc1: new FormControl(false),
        doc2: new FormControl(false),
        doc3: new FormControl(false),
        doc4: new FormControl(false),
        doc5: new FormControl(false),
        doc6: new FormControl(false),
        doc7: new FormControl(false),
        obs7: new FormControl(''),
        doc8: new FormControl(false),
        doc9: new FormControl(false),
        doc10: new FormControl(false),
        doc11: new FormControl(false),
        doc12: new FormControl(false),
        obs12: new FormControl(''),
      }),
      health_service: this.formBuilder.group({
        medic_name: new FormControl(''),
        medic_phone: new FormControl(''),
        infirmary: new FormControl(''),
        cse_members: this.formBuilder.array([]) as FormArray,
        required_follow: new FormControl(''),
        jobs: this.formBuilder.array([]) as FormArray,
        attached: this.formBuilder.array([]) as FormArray,
        requirements_required: new FormControl(''),
        requirements: this.formBuilder.array([]) as FormArray,
      }),
      risks: this.formBuilder.array([]) as FormArray,
    });

    this.getRisks();

    this.addPreventionProvider();
    if (this.activatedRoute.snapshot.params.prevention_id) {
      this.isNew = false;
      this.getPrevention();
    } else {
      Flatpickr();
    }
  }

  ngAfterViewInit() {
    Dropzones();
    this.modalPrevention.open();
  }

  ngOnDestroy() {
    const flatPicker = document.getElementsByClassName('flatpickr-calendar') as HTMLCollection;
    for (let i = flatPicker.length; i >= 0; i--) {
      if (flatPicker[i]) {
        flatPicker[i].remove();
      }
    }

    const dropZones = document.getElementsByClassName('dz-hidden-input') as HTMLCollection;
    for (let i = 0; i < dropZones.length; i++) {
      dropZones[i].remove();
    }

    this.modalPrevention.close();
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }
  changeTabs(activeTab) {
    this.activeTab = activeTab;
  }

  addPreventionProvider() {
    const control = <FormArray>this.preventionForm.controls['prevention_providers'];
    control.push(this.formBuilder.group({
      social_reason: ['', Validators.required],
      address: [''],
      phone: [''],
      legal_manager_name: [''],
      legal_manager_function: [''],
      legal_manager_phone: [''],
      informations: [''],
      prevention_providers: this.formBuilder.array([]) as FormArray,
    }));
  }

  addParticipant() {
    const control = <FormArray>this.preventionForm.controls['participants'];
    control.push(this.formBuilder.group({
      name: ['', Validators.required],
      users: this.formBuilder.array([]) as FormArray,
    }));
  }
  addUser(index) {
    const control = <FormArray>this.preventionForm.controls['participants']['controls'][index]['controls']['users'];
    control.push(this.formBuilder.group({
      name: ['', Validators.required],
      role: ['', Validators.required],
    }));
  }

  addJob() {
    const control = <FormArray>this.preventionForm.controls['health_service']['controls']['jobs'];
    control.push(this.formBuilder.group({
      name: ['', Validators.required],
    }));
  }
  deleteJob(index) {
    const control = <FormArray>this.preventionForm.controls['health_service']['controls']['jobs'];
    control.removeAt(index);
  }

  addRisk() {
    const control = <FormArray>this.preventionForm.controls['risks'];
    control.push(this.formBuilder.group({
      operation: ['', Validators.required],
      nature: ['', Validators.required],
      identified_risks: ['', Validators.required],
      begin_date: ['', Validators.required],
      end_date: ['', Validators.required],
      enterprises: ['', Validators.required],
      measures: ['', Validators.required],
    }));
    setTimeout(() => {
      Flatpickr();
    }, 1);
  }
  deleteRisk(index) {
    const control = <FormArray>this.preventionForm.controls['risks'];
    control.removeAt(index);
  }

  getLabel(field) {
    const placeholder = {
      bool1: 'Délimiter le secteur d’intervention des entreprises extérieures',
      bool2: 'Matérialiser les zones dangereuses de ce secteur',
      bool3: 'Indiquer les voies de circulation pouvant être empruntées par le personnel, véhicules et engins des EE',
      bool4: 'Définir les voies d’accès du personnel des EE aux locaux et installations mis à disposition',
      bool5: 'Repérer les risques d’interférence',
      bool6: 'Identifier les conditions de réalisation de l’opération',
      bool7: 'Communiquer les consignes de sécurité (permis feu, consignation, règles de circulation, etc.)',
      bool8: 'Prévoir l’organisation des secours',
    };
    return placeholder[field] ? placeholder[field] : '';
  }

  getRisks() {
    this.requestInProgress.next(true);
    this.preventionsService.getAllMyPreventionRisks(
      '', 'PreventionRisks.name', 'ASC', '', '',
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      'y'
    ).subscribe(response => {
      if (response['success']) {
        this.risks = response['risks'];
      }
      this.requestInProgress.next(false);
    });
  }
  getPrevention() {
    this.requestInProgress.next(true);
    this.preventionsService.getPrevention(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.activatedRoute.snapshot.params.prevention_id
    ).subscribe(response => {
      if (response['success']) {
        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];

        response['prevention']['participants'].forEach((element, index) => {
          this.addParticipant();
          element['users'].forEach(() => {
            this.addUser(index);
          });
        });

        response['prevention']['risks'].forEach(() => {
          this.addRisk();
        });

        response['prevention']['health_service']['jobs'].forEach(() => {
          this.addJob();
        });

        this.preventionForm.patchValue(response['prevention']);

        if (!this.canUpdate) {
          this.preventionForm.disable();
        }
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      Flatpickr();
      this.requestInProgress.next(false);
    });
  }
  createPrevention() {
    let request;
    this.errors = {};
    this.loader = true;

    const data = {
      ...this.preventionForm.value,
    };

    request = this.preventionsService.createPrevention(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      data, 'limited'
    );

    request.subscribe(response => {
      if (response['success']) {
        this.isNew = false;
        if (this.files.length) {
          this.createDocuments(response['prevention']['id']);
        } else {
          this.loader = false;
          this.modalPrevention.close();
          CustomNotifierService.getSuccessCreate(this.notifier);
        }
      } else if (response['errors']) {
        this.loader = false;
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(response['errors'], this.preventionForm, this.notifier);
      } else {
        this.loader = false;
      }
    });
  }
  updatePrevention() {
    let request;

    this.errors = {};
    this.loader = true;

    const data = {
      ...this.preventionForm.value,
    };

    request = this.preventionsService.updatePrevention(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.activatedRoute.snapshot.params.prevention_id,
      data
    );

    request.subscribe(response => {
      if (response['success']) {
        if (this.files.length) {
          this.createDocuments(this.activatedRoute.snapshot.params.prevention_id);
        } else {
          this.loader = false;
          this.modalPrevention.close();
          CustomNotifierService.getSuccessUpdate(this.notifier);
        }
      } else if (response['errors']) {
        this.loader = false;
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      } else {
        this.loader = false;
      }
    });
  }
  createDocuments(prevention_id) {
    let request;
    this.errors = {};

    request = this.preventionsService.createFile(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      prevention_id,
      this.files
    );

    request.subscribe(response => {
      if (response['success']) {
        this.modalPrevention.close();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loader = false;
    });
  }

  chooseFile() {
    const element: HTMLElement = document.getElementById('inputFile') as HTMLElement;
    element.click();
  }
  onFileChange(event) {
    if (event.target && event.target.files && event.target.files.length) {
      for (let i = 0; i < event.target.files.length; i++) {
        const reader = new FileReader();
        const file = event.target.files[i];
        reader.readAsDataURL(file);

        reader.onload = () => {
          let extension = '';
          let name = '';

          const split = file.name.split('.');
          if (split.length) {
            name = split[0];
            extension = split[split.length - 1];
          }

          this.files.push({ file_base64: reader.result, name: name, extension: extension });
        };
      }
    } else {
      let extension = '';
      let name = '';

      const split = event.name.split('.');
      if (split.length) {
        name = split[0];
        extension = split[split.length - 1];
      }

      this.files.push({ file_base64: event.file, name: name, extension: extension });
    }
    const element: HTMLInputElement = document.getElementById('inputFile') as HTMLInputElement;
    element.value = '';
  }
  removeFile(index) {
    this.files.splice(index, 1);

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  bindModal(modal) {
    this.modalPrevention = modal;
  }
  closeModal() {
    this.router.navigate([this.activatedRoute.snapshot.parent.url.join('/')]).then(() => {
      this.allPreventions.refreshData();
    });
  }
}
