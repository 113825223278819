import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { HttpClient } from '@angular/common/http';
import { NotifierService } from 'angular-notifier';

@Injectable()
export class IssuesRequestService extends RequestService {
  constructor(
    protected http: HttpClient,
    protected notifier: NotifierService
  ) {
    super(http, notifier);
  }

  /**
   * ====== ADMIN ======
   * **/
  public getIssues(search, sort, order, limit, page) {
    return this.get( 'issues?' + 'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getIssue(issueId) {
    return this.get( 'issues/' + issueId);
  }
  public downloadIssueCommentFile(issueId, commentId, fileId) {
    return this.get( 'issues/' + issueId + '/comment/' + commentId + '/file/' + fileId, { responseType: 'arraybuffer' });
  }
  public updateIssue(issueId, issue) {
    return this.put( 'issues/' + issueId, { issue });
  }
  public createIssueComment(issueId, comment) {
    return this.post('issues/' + issueId + '/comment', { comment });
  }
  public createIssueCommentFile(issueId, commentId, files) {
    return this.post('issues/' + issueId + '/comment/' + commentId + '/file', { files });
  }

  /**
   * ====== USERS ======
   * **/
  public getMyIssues(search, sort, order, limit, page) {
    return this.get( 'issues/mines?' + 'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getMyIssue(issueId) {
    return this.get( 'issues/mines/' + issueId);
  }
  public downloadMyIssueCommentFile(issueId, commentId, fileId) {
    return this.get( 'issues/mines/' + issueId + '/comment/' + commentId + '/file/' + fileId, { responseType: 'arraybuffer' });
  }
  public updateMyIssue(issueId, issue) {
    return this.put( 'issues/mines/' + issueId, { issue });
  }
  public createMyIssue(issue) {
    return this.post( 'issues/mines', { issue });
  }
  public createMyIssueComment(issueId, comment) {
    return this.post('issues/mines/' + issueId + '/comment', { comment });
  }
  public createMyIssueCommentFile(issueId, commentId, files) {
    return this.post('issues/mines/' + issueId + '/comment/' + commentId + '/file', { files });
  }

  /**
   * ====== GENERAL ======
   * **/
  public getCategories() {
    return this.get( 'issues/categories');
  }
  public getStates() {
    return this.get( 'issues/states');
  }

}
