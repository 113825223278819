import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { RegistriesRequestService } from '../../../services/registries.request.service';

@Component({
  selector: 'app-update-registry-manager',
  templateUrl: './update-registry-manager.component.html',
  styleUrls: ['./update-registry-manager.component.scss']
})
export class UpdateRegistryManagerComponent implements OnInit, AfterViewInit, OnDestroy {
  canUpdate = false;

  managerForm: FormGroup;
  id = new FormControl('');
  establishment_registry_id = new FormControl('');
  name = new FormControl('', Validators.required);
  role = new FormControl('', Validators.required);
  services = new FormControl('');
  address = new FormControl('', Validators.required);
  phone = new FormControl('');
  email = new FormControl('', Validators.required);
  access = new FormControl('', Validators.required);
  place = new FormControl('', Validators.required);
  hour = new FormControl('');
  others = new FormControl('');

  errors = {};

  isNew = false;
  loader = false;
  loaderContent = false;

  modalManager = null;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private registriesService: RegistriesRequestService
  ) {}

  ngOnInit() {
    this.managerForm = this.formBuilder.group({
      id: this.id,
      establishment_registry_id: this.establishment_registry_id,
      name: this.name,
      role: this.role,
      services: this.services,
      address: this.address,
      phone: this.phone,
      email: this.email,
      access: this.access,
      place: this.place,
      hour: this.hour,
      others: this.others,
    });

    this.getManager();
  }

  ngAfterViewInit() {
    this.modalManager.open();
  }

  ngOnDestroy() {
    this.modalManager.close();
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  getManager() {
    this.errors = {};
    this.loaderContent = true;

    this.registriesService.getManager(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id
    ).subscribe(response => {
      if (response['success']) {
        this.canUpdate = response['canUpdate'];

        if (response['manager']) {
          this.managerForm.patchValue(response['manager']);
        } else {
          this.isNew = true;
        }

        if (!this.canUpdate) {
          this.managerForm.disable();
        }
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.managerForm, this.notifier);
      }
      this.loaderContent = false;
    });
  }

  createManager() {
    this.errors = {};
    this.loader = true;

    this.registriesService.createManager(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.managerForm.value
    ).subscribe(response => {
      if (response['success']) {
        this.modalManager.close();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.managerForm, this.notifier);
      }
      this.loader = false;
    });
  }

  updateManager() {
    this.errors = {};
    this.loader = true;

    this.registriesService.updateManager(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.id.value,
      this.managerForm.value
    ).subscribe(response => {
      if (response['success']) {
        this.modalManager.close();
        CustomNotifierService.getSuccessUpdate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.managerForm, this.notifier);
      }
      this.loader = false;
    });
  }

  bindModal(modal) {
    this.modalManager = modal;
  }

  closeModal() {
    this.router.navigate([this.activatedRoute.snapshot.parent.url.join('/')]).then(() => {});
  }
}
