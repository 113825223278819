import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NotifierService } from "angular-notifier";
import { CustomNotifierService } from "src/app/services/custom.notifier.service";
import { EstablishmentsRequestService } from "src/app/services/establishments.request.service";
import { RegistriesRequestService } from "src/app/services/registries.request.service";
import { TrainingsRequestService } from "src/app/services/trainings.request.service";
import Establishment from "src/shared/interfaces/establishment";
import Registry from "src/shared/interfaces/registry";
import { GetAllTrainingsComponent } from "../get-all-training/get-all-training.component";

@Component({
  selector: "app-create-update-training",
  templateUrl: "./create-update-training.component.html",
  styleUrls: ["./create-update-training.component.scss"],
})
export class CreateUpdateTrainingComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  isNew = true;

  canCreate = false;
  canUpdate = false;

  loaderContent = false;
  loader = false;
  errors = {};

  disableEstab = true;

  establishments: Establishment;
  registries: Registry;

  search = "";
  sort = "Establishments.id";
  order = "asc";
  limit = 12;
  page = 1;

  trainingForm: FormGroup;
  id = new FormControl(null);
  name = new FormControl("", Validators.required);
  frequency_duration = new FormControl(null);
  frequency = new FormControl("");
  registry_id = new FormControl(null, Validators.required);
  establishment_id = new FormControl(null);
  priority = new FormControl(null);
  hasEstablishment = new FormControl(false);
  formation_dispensee = new FormControl("none");

  modalEmployee = null;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private notifier: NotifierService,
    private formBuilder: FormBuilder,
    private establishmentsService: EstablishmentsRequestService,
    private registriesService: RegistriesRequestService,
    private allTrainings: GetAllTrainingsComponent,
    private trainingsService: TrainingsRequestService
  ) {}

  ngOnInit() {
    this.trainingForm = this.formBuilder.group({
      name: this.name,
      frequency_duration: this.frequency_duration,
      frequency: this.frequency,
      registry_id: this.registry_id,
      establishment_id: this.establishment_id,
      priority: this.priority,
      hasEstablishment: this.hasEstablishment,
      formation_dispensee: this.formation_dispensee,
    });
    this.getRegistry();

    if (this.activatedRoute.snapshot.params.training_id) {
      this.isNew = false;
      this.getTraining();
    }
  }

  ngAfterViewInit() {
    this.modalEmployee.open();
  }

  ngOnDestroy() {
    this.modalEmployee.close();
  }

  getTraining() {
    let request;
    this.loaderContent = true;

    request = this.trainingsService.getTraining(
      this.activatedRoute.snapshot.params.training_id
    );

    request.subscribe((response) => {
      if (response["success"]) {
        this.canCreate = response["canCreate"];
        this.canUpdate = response["canUpdate"];

        this.trainingForm.patchValue(response["training"]);

        if (response["training"].establishment_id) {
          this.getEstablishment();
          this.trainingForm.patchValue({
            hasEstablishment: true,
            establishment_id: response["training"].establishment_id,
          });
        }

        if (!this.canUpdate) {
          this.trainingForm.disable();
        }
      } else if (response["errors"]) {
        CustomNotifierService.getErrors(response["errors"], this.notifier);
      }
      this.loaderContent = false;
    });
  }

  getEstablishment() {
    if (this.registry_id.value) {
      this.establishment_id.patchValue(null);
      this.establishmentsService
        .getAllEstablishmentsRegistry(this.registry_id.value)
        .subscribe((response) => {
          if (response["success"]) {
            this.establishments = response["establishments"];
            this.disableEstab = false;
          } else if (response["errors"]) {
            this.errors = response["errors"];
          }
        });
    }
  }

  getRegistry() {
    this.registriesService
      .getMyRegistries(
        this.search,
        this.sort,
        this.order,
        this.limit,
        this.page
      )
      .subscribe((response) => {
        if (response["success"]) {
          this.registries = response["registries"];
        } else if (response["errors"]) {
          this.errors = response["errors"];
        }
      });
  }

  createTraining() {
    this.errors = {};
    this.loader = true;
    let request;

    request = this.trainingsService.createTrainingFromMainMenu(
      this.trainingForm.value
    );

    request.subscribe((response) => {
      if (response["success"]) {
        this.modalEmployee.close();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response["errors"]) {
        this.errors = response["errors"];
        CustomNotifierService.getErrors(response["errors"], this.notifier);
      }
      this.loader = false;
    });
  }

  updateTraining() {
    this.errors = {};
    this.loader = true;
    let request;

    request = this.trainingsService.updateTrainingFromMainMenu(
      this.activatedRoute.snapshot.params.training_id,
      this.trainingForm.value
    );

    request.subscribe((response) => {
      if (response["success"]) {
        this.modalEmployee.close();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response["errors"]) {
        this.errors = response["errors"];
        CustomNotifierService.getErrors(response["errors"], this.notifier);
      }
      this.loader = false;
    });
  }

  bindModal(modal) {
    this.modalEmployee = modal;
  }

  getErrorMessage(param) {
    return this.errors[param] && this.errors[param]["message"];
  }

  closeModal() {
    this.router
      .navigate([this.activatedRoute.snapshot.parent.url.join("/")])
      .then(() => {
        this.allTrainings.refreshData();
      });
  }
}
