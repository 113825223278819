import EstablishmentType from './interfaces/establishment_type';
import EstablishmentSubtype from './interfaces/establishment_subtype';
import EstablishmentCategory from './interfaces/establishment_category';

export const ERP_subtypes: EstablishmentSubtype[] = [
  {
    id: 1,
    name: 'J',
    hasSubSubTypes: false,
    subSubTypes: null,
    group: true
  },
  {
    id: 2,
    name: 'L',
    hasSubSubTypes: false,
    subSubTypes: null,
    group: true
  },
  {
    id: 3,
    name: 'M',
    hasSubSubTypes: false,
    subSubTypes: null,
    group: true
  },
  {
    id: 4,
    name: 'N',
    hasSubSubTypes: false,
    subSubTypes: null,
    group: true
  },
  {
    id: 5,
    name: 'O',
    hasSubSubTypes: false,
    subSubTypes: null,
    group: true
  },
  {
    id: 6,
    name: 'P',
    hasSubSubTypes: false,
    subSubTypes: null,
    group: true
  },
  {
    id: 7,
    name: 'R',
    hasSubSubTypes: false,
    subSubTypes: null,
    group: true
  },
  {
    id: 8,
    name: 'S',
    hasSubSubTypes: false,
    subSubTypes: null,
    group: true
  },
  {
    id: 9,
    name: 'T',
    hasSubSubTypes: false,
    subSubTypes: null,
    group: true
  },
  {
    id: 10,
    name: 'U',
    hasSubSubTypes: false,
    subSubTypes: null,
    group: true
  },
  {
    id: 11,
    name: 'V',
    hasSubSubTypes: false,
    subSubTypes: null,
    group: true
  },
  {
    id: 12,
    name: 'W',
    hasSubSubTypes: false,
    subSubTypes: null,
    group: true
  },
  {
    id: 13,
    name: 'X',
    hasSubSubTypes: false,
    subSubTypes: null,
    group: true
  },
  {
    id: 14,
    name: 'Y',
    hasSubSubTypes: false,
    subSubTypes: null,
    group: true
  },
  {
    id: 15,
    name: 'PA',
    hasSubSubTypes: false,
    subSubTypes: null,
    group: true
  },
  {
    id: 16,
    name: 'CTS',
    hasSubSubTypes: false,
    subSubTypes: null,
    group: true
  },
  {
    id: 17,
    name: 'SG',
    hasSubSubTypes: false,
    subSubTypes: null,
    group: true
  },
  {
    id: 18,
    name: 'OA',
    hasSubSubTypes: false,
    subSubTypes: null,
    group: true
  },
  {
    id: 19,
    name: 'REF',
    hasSubSubTypes: false,
    subSubTypes: null,
    group: true
  },
  {
    id: 20,
    name: 'PS',
    hasSubSubTypes: false,
    subSubTypes: null,
    group: true
  },
  {
    id: 21,
    name: 'GA',
    hasSubSubTypes: false,
    subSubTypes: null,
    group: true
  },
  {
    id: 22,
    name: 'EF',
    hasSubSubTypes: false,
    subSubTypes: null,
    group: true
  }
];

export const ERP_first_subcategories = [
  { id: 1, name: '1ère catégorie' },
  { id: 2, name: '2ème catégorie' },
  { id: 3, name: '3ème catégorie' },
  { id: 4, name: '4ème catégorie' }
];

export const ERP_second_subcategories = [
  { id: 5, name: '5ème catégorie' }
];

export const ERP_categories: EstablishmentCategory[] = [
  {
    id: 1,
    name: '1er groupe',
    hasSubCategories: true,
    subCategories: ERP_first_subcategories
  },
  {
    id: 2,
    name: '2ème groupe',
    hasSubCategories: true,
    subCategories: ERP_second_subcategories
  }
];

export const BHC_categories: EstablishmentCategory[] = [
  {
    id: 3,
    name: 'Première famille',
    hasSubCategories: false,
    subCategories: null
  },
  {
    id: 4,
    name: 'Deuxième famille',
    hasSubCategories: false,
    subCategories: null
  },
  {
    id: 5,
    name: 'Troisième famille A',
    hasSubCategories: false,
    subCategories: null
  },
  {
    id: 6,
    name: 'Troisième famille B',
    hasSubCategories: false,
    subCategories: null
  },
  {
    id: 7,
    name: 'Quatrième famille',
    hasSubCategories: false,
    subCategories: null
  }
];

export const IGH_subtypes: EstablishmentSubtype[] = [
  {
    id: 23,
    name: 'GHA',
    hasSubSubTypes: false,
    subSubTypes: null,
    group: true
  },
  {
    id: 24,
    name: 'GHO',
    hasSubSubTypes: false,
    subSubTypes: null,
    group: true
  },
  {
    id: 25,
    name: 'GHR',
    hasSubSubTypes: false,
    subSubTypes: null,
    group: true
  },
  {
    id: 26,
    name: 'GHS',
    hasSubSubTypes: false,
    subSubTypes: null,
    group: true
  },
  {
    id: 27,
    name: 'GHTC',
    hasSubSubTypes: false,
    subSubTypes: null,
    group: true
  },
  {
    id: 28,
    name: 'GHU',
    hasSubSubTypes: false,
    subSubTypes: null,
    group: true
  },
  {
    id: 29,
    name: 'GHW 1',
    hasSubSubTypes: false,
    subSubTypes: null,
    group: true
  },
  {
    id: 30,
    name: 'GHW 2',
    hasSubSubTypes: false,
    subSubTypes: null,
    group: true
  },
  {
    id: 31,
    name: 'GHZ',
    hasSubSubTypes: false,
    subSubTypes: null,
    group: true
  },
  {
    id: 32,
    name: 'ITGH',
    hasSubSubTypes: false,
    subSubTypes: null,
    group: true
  }
];

export const establishment_types: EstablishmentType[] = [
  {
    id: 1,
    name: 'ERP',
    hasSubtypes: true,
    requiredSubtypes: true,
    subtypes: ERP_subtypes,
    hasCategories: true,
    requiredCategories: false,
    requiredSubCategories: true,
    categories: ERP_categories
  },
  {
    id: 2,
    name: 'ERT',
    hasSubtypes: false,
    requiredSubtypes: false,
    subtypes: null,
    hasCategories: false,
    requiredCategories: false,
    requiredSubCategories: false,
    categories: null
  },
  {
    id: 3,
    name: 'BHC',
    hasSubtypes: false,
    requiredSubtypes: false,
    subtypes: null,
    hasCategories: true,
    requiredCategories: true,
    requiredSubCategories: false,
    categories: BHC_categories
  },
  {
    id: 4,
    name: 'IGH',
    hasSubtypes: true,
    requiredSubtypes: true,
    subtypes: IGH_subtypes,
    hasCategories: false,
    requiredCategories: false,
    requiredSubCategories: false,
    categories: null
  },
  {
    id: 5,
    name: 'IMH',
    hasSubtypes: false,
    requiredSubtypes: false,
    subtypes: null,
    hasCategories: false,
    requiredCategories: false,
    requiredSubCategories: false,
    categories: null
  }
];
