import { Routes } from '@angular/router';

import { AllRolesComponent } from './all-roles/all-roles.component';
import { UpdateRoleComponent } from './update-role/update-role.component';

export const rolesRoutes: Routes = [
  {
    path: 'roles',
    component: AllRolesComponent,
    children: [
      {
        path: 'create',
        component: UpdateRoleComponent
      },
      {
        path: ':role_id',
        component: UpdateRoleComponent
      }
    ]
  }
];
