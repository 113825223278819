import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';

import { environment } from '../../../../environments/environment';

import { AuthService } from '../../../core/auth/auth.service';
import { UsersRequestService } from '../../../services/users.request.service';

import { NotifierService } from 'angular-notifier';

import Registry from '../../../../shared/interfaces/registry';
import Establishment from '../../../../shared/interfaces/establishment';
import User from '../../../../shared/interfaces/user';

import { EstablishmentsRequestService } from '../../../services/establishments.request.service';
import { RegistriesRequestService } from '../../../services/registries.request.service';
import { EnterprisesRequestService } from '../../../services/enterprises.request.service';
import Enterprise from '../../../../shared/interfaces/enterprise';

declare const Select2: any;

@Component({
  selector: 'app-all-provider-registry-users',
  templateUrl: './all-provider-registry-users.component.html',
  styleUrls: ['./all-provider-registry-users.component.scss']
})
export class AllProviderRegistryUsersComponent implements OnInit, OnDestroy {
  changeEnterprise: Subscription;
  environment = environment;

  requestInProgress = new Subject();
  requestCount = 0;

  isAdmin = false;

  canUpdate = false;
  canCreate = false;
  canDelete = false;

  activeTabsList = '';

  loader = false;
  errors = {};

  pageName: string;
  previousPageName: string[] = [];
  previousPageRoute: string[] = [];
  updateRoute = '';
  createRoute = '';

  enterprise: any;
  users: User[] = [];
  registry: Registry;
  establishment: Establishment;
  provider: Enterprise;
  count = 0;
  pages = 1;

  enterprise_id: number = null;
  registry_id: number = null;
  establishment_id: number = null;
  search = '';
  sort = 'Users.firstname';
  order = 'asc';
  limit = 12;
  page = 1;

  timeout: any;
  view = '';

  constructor(
    private router: Router,
    private authService: AuthService,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private usersService: UsersRequestService,
    private registriesServices: RegistriesRequestService,
    private enterprisesService: EnterprisesRequestService,
    private establishmentsService: EstablishmentsRequestService,
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loader = this.requestCount > 0;
    });

    let connectedAs;
    this.changeEnterprise = this.authService.getEnterprise().subscribe(() => {
      this.getUsers();
      connectedAs = JSON.parse(localStorage.getItem('connectedAs'));
      if (connectedAs) {
        this.isAdmin = JSON.parse(localStorage.getItem('connectedAsIsAdmin'));
      } else {
        this.isAdmin = JSON.parse(localStorage.getItem('isAdmin'));
      }
    });

    connectedAs = JSON.parse(localStorage.getItem('connectedAs'));
    if (connectedAs) {
      this.isAdmin = JSON.parse(localStorage.getItem('connectedAsIsAdmin'));
    } else {
      this.isAdmin = JSON.parse(localStorage.getItem('isAdmin'));
    }

    this.activeTabsList = localStorage.getItem('userTabsList');
    if (!this.activeTabsList) {
      localStorage.setItem('userTabsList', JSON.stringify('tabPaneOne'));
      this.activeTabsList = 'tabPaneOne';
    } else {
      this.activeTabsList = JSON.parse(this.activeTabsList);
    }

    this.updateRoute = '/' + this.activatedRoute.snapshot.url.join('/') + '/';
    this.createRoute = '/' + this.activatedRoute.snapshot.url.join('/') + '/create';

    this.pageName = 'Utilisateurs';

    this.enterprise_id = this.activatedRoute.snapshot.params.enterprise_id;
    this.registry_id = this.activatedRoute.snapshot.params.registry_id;
    this.establishment_id = this.activatedRoute.snapshot.params.establishment_id;

    this.getEstablishment();
    this.getRegistry();

    if (this.authService.loaded) {
      this.getUsers();
    }
  }

  ngOnDestroy() {
    this.changeEnterprise.unsubscribe();

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  searchData() {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.page = 1;
      this.refreshData();
    }, 500);
  }

  refreshData(sort = this.sort, order = this.order, page = this.page) {
    this.sort = sort;
    this.order = order;
    this.page = page;
    this.getUsers();
  }

  getEstablishment() {
    this.requestInProgress.next(true);
    this.establishmentsService.getMyEstablishment(this.activatedRoute.snapshot.params.establishment_id).subscribe(response => {
      if (response['success']) {
        this.establishment = response['establishment'];
        this.createBreadcrumbs();
      } else if (response['errors']) {
        this.errors = response['errors'];
      }
      this.requestInProgress.next(false);
    });
  }

  getRegistry() {
    this.requestInProgress.next(true);
    this.registriesServices.getRegistry(this.activatedRoute.snapshot.params.registry_id,
      this.activatedRoute.snapshot.params.establishment_id).subscribe(response => {
      if (response['success']) {
        this.registry = response['registry'];
        this.createBreadcrumbs();
      } else if (response['errors']) {
        this.errors = response['errors'];
      }
      this.requestInProgress.next(false);
    });
  }

  getUsers() {
    this.requestInProgress.next(true);

    let request;
    request = this.usersService.getRegistryProviderUsers(this.search, this.sort, this.order, this.limit, this.page,
      this.enterprise_id, this.registry_id, this.establishment_id);

    request.subscribe(response => {
      if (response['success']) {
        setTimeout(() => {
          Select2();
        }, 1);

        this.canUpdate = response['canUpdate'];
        this.canCreate = response['canCreate'];
        this.canDelete = response['canDelete'];

        this.users = response['users'];
        if (response['enterprise']) {
          this.enterprise = response['enterprise'];
        }

        this.count = response['count'];
        this.pages = response['pages'];
      }
      this.requestInProgress.next(false);
    });
  }

  dispatchPagination(data) {
    if (data.action === 'total_elements') {
      this.refreshPage(data.value);
    } else if (data.action === 'change_page') {
      this.changePage(data.value);
    }
  }

  refreshPage(limit) {
    this.limit = limit;
    this.page = 1;
    this.refreshData();
  }

  filter(property) {
    if (this.sort === property) {
      if (this.order === 'asc') {
        this.order = 'desc';
      } else {
        this.order = 'asc';
      }
    } else {
      this.sort = property;
      this.order = 'asc';
    }

    this.refreshData();
  }

  changePage(page) {
    if (page >= 1 && page <= this.pages) {
      this.page = page;
      this.refreshData();
    }
  }

  changeActiveTabsList(tabValue: string) {
    localStorage.setItem('userTabsList', JSON.stringify(tabValue));
    this.activeTabsList = tabValue;
  }

  connectedAs(user_id) {
    let enterprise_id = '';
    if (this.activatedRoute.snapshot.params.enterprise_id) {
      enterprise_id = this.activatedRoute.snapshot.params.enterprise_id;
    } else if (this.view === 'mine') {
      const connectedAs = JSON.parse(localStorage.getItem('connectedAs'));
      if (connectedAs) {
        const enterprise = JSON.parse(localStorage.getItem('connectedAsEnterprise'));
        enterprise_id = enterprise.id;
      } else {
        const enterprise = JSON.parse(localStorage.getItem('enterprise'));
        enterprise_id = enterprise.id;
      }
    }
    this.usersService.connectedAs(user_id, enterprise_id).subscribe(response => {
      if (response['success']) {
        localStorage.setItem('connectedAs', 'true');
        localStorage.setItem('connectedAsRefreshToken', response['refreshToken']);
        localStorage.setItem('connectedAsAccessToken', response['accessToken']);

        localStorage.setItem('connectedAsEnterprises', JSON.stringify(response['enterprises']));
        localStorage.setItem('connectedAsIsOnlyProvider', JSON.stringify(response['is_only_provider']));
        localStorage.setItem('connectedAsEnterprise', JSON.stringify(response['enterprise']));
        localStorage.setItem('connectedAsIsAdmin', JSON.stringify(response['is_admin']));
        localStorage.setItem('connectedAsUser', JSON.stringify(response['user']));
        localStorage.setItem('connectedAsRegistries', JSON.stringify(response['registries']));

        this.authService.changeEnterprises(response['enterprises']);
        this.authService.changeEnterprise(response['enterprise']);
        this.authService.changeProvider(response['is_provider']);
        this.authService.changeOnlyProvider(response['is_only_provider']);
        this.authService.changeAdmin(response['is_admin']);
        this.authService.changeUser(response['user']);
        this.authService.changeRegistries(response['registries']);
      }
    });
  }

  getAccess(user) {
    if (this.enterprise.provider && !this.enterprise.provider.access_to_enterprise) {
      return 'scoring-red';
    } else if (user.last_user_enterprise && !user.last_user_enterprise.access_to_enterprise) {
      return 'scoring-red';
    } else if (!user.hasCheckpoints && this.view === 'provider') {
      return 'scoring-red';
    }
    return 'scoring-green';
  }

  createBreadcrumbs() {
    this.previousPageName = [];
    this.previousPageRoute = [];

    this.previousPageName.push('Tableau de bord');
    this.previousPageRoute.push('/');

    this.previousPageName.push(this.registry ? this.registry.name : 'Tableau de bord registre');
    this.previousPageRoute.push('/dashboard-registry/' + this.activatedRoute.snapshot.params.registry_id);

    this.previousPageName.push(this.establishment ? this.establishment.name : 'Tableau de bord établissement');
    this.previousPageRoute.push('/dashboard-registry/' + this.activatedRoute.snapshot.params.registry_id +
      '/establishment/' + this.activatedRoute.snapshot.params.establishment_id);

    this.previousPageName.push('Prestataires');
    this.previousPageRoute.push('/dashboard-registry/' + this.activatedRoute.snapshot.params.registry_id +
      '/establishment/' + this.activatedRoute.snapshot.params.establishment_id + '/providers');
  }
}
