import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

import { UpdateSanitaryLegionellaComponent } from './update-sanitary-legionella.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalRightModule } from '../../../../core/modal-right/modal-right.module';
import { UtilsModule } from '../../../../core/utils/utils.module';
import { ConfirmModalModule } from '../../../../core/confirm-modal/confirm-modal.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    ModalRightModule,
    UtilsModule,
    NgbModule,
    ConfirmModalModule
  ],
  declarations: [UpdateSanitaryLegionellaComponent]
})

export class UpdateSanitaryLegionellaModule {}
