import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { HttpClient } from '@angular/common/http';
import { NotifierService } from 'angular-notifier';

@Injectable()
export class ArchivesRequestService extends RequestService {
  constructor(
    protected http: HttpClient,
    protected notifier: NotifierService
  ) {
    super(http, notifier);
  }

  /// ADD
  public addArchive(enterprise_id) {
    return this.put('archive/' + enterprise_id, {});
  }

  /// DOWNLOAD
  public downloadArchive(enterprise_id) {
    return this.get('archive/' + enterprise_id);
  }
}
