import { Routes } from '@angular/router';

import { AllExpertisesComponent } from './all-expertises/all-expertises.component';
import { UpdateExpertiseComponent } from './update-expertise/update-expertise.component';
import { UploadExpertiseFileComponent } from './upload-expertise-file/upload-expertise-file.component';

export const expertisesRoutes: Routes = [
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/expertises',
    component: AllExpertisesComponent,
    children: [
      {
        path: 'create',
        component: UpdateExpertiseComponent
      },
      {
        path: ':commission_id',
        component: UpdateExpertiseComponent
      }
    ]
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/expertises/:commission_id/files',
    component: AllExpertisesComponent,
    children: [
      {
        path: 'create',
        component: UploadExpertiseFileComponent
      },
      {
        path: ':file_id',
        component: UploadExpertiseFileComponent
      }
    ]
  }
];
