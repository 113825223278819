<div id="pdf-modal" class="modal modal-pdf fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header align-items-center">

        <button type="button" class="close" (click)="close()">
          <span aria-hidden="true">&times;</span>
          <span class="sr-only">Close</span>
        </button>
      </div>
      <div class="modal-body" *ngIf="url">
        <iframe [src]="url" height="100%" width="100%" allowfullscreen></iframe>
      </div>
    </div>
  </div>
</div>
