import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Subject } from 'rxjs';
import { NgbTimeAdapter } from '@ng-bootstrap/ng-bootstrap';

import { AllSanitaryLegionellaFramesComponent } from '../all-sanitary-legionella-frames/all-sanitary-legionella-frames.component';
import { SanitaryLegionellaFramesRequestService } from '../../../../services/sanitary-legionella-frames.request.service';

import { NgbTimeStringAdapter } from '../../../../core/utils/ngb-time-string-adapter';
import { CustomNotifierService } from '../../../../services/custom.notifier.service';

import { SanitaryLegionellasRequestService } from '../../../../services/sanitary-legionellas.request.service';
import SanitaryLegionella from '../../../../../shared/interfaces/sanitary_legionella';

import * as _ from 'lodash';

declare function Flatpickr(): any;
declare function Dropzones(): any;

@Component({
  selector: 'app-update-sanitary-legionella-frame',
  templateUrl: './update-sanitary-legionella-frame.component.html',
  styleUrls: ['./update-sanitary-legionella-frame.component.scss'],
  providers: [{ provide: NgbTimeAdapter, useClass: NgbTimeStringAdapter }]
})
export class UpdateSanitaryLegionellaFrameComponent implements OnInit, AfterViewInit, OnDestroy {
  counter = Array;
  canCreate = false;
  canUpdate = false;

  activeTab = 1;

  requestInProgress = new Subject();
  requestCount = 0;

  sanitaryLegionella: SanitaryLegionella;

  sanitaryLegionellaFrameForm: FormGroup;
  date = new FormControl('', Validators.required);
  title = new FormControl('', Validators.required);
  descriptions = new FormControl('');

  listHeader = [
    'Date', 'Température °C', 'Mode de prélèvement (1er jet ou après écoulement)',
    'Résultat Legionella pneumophia (UFC/l)', 'Actions à réaliser si résultat > 1000 UFC/L', 'Echéance'
  ];
  listItems = [];
  selectedDescription = null;

  enterprises: any = [];
  user: string;

  errors = {};

  isNew = true;
  loader = false;
  loaderContent = false;

  modalSanitaryLegionellaFrame = null;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private allSanitaryLegionellas: AllSanitaryLegionellaFramesComponent,
    private sanitaryLegionellasServices: SanitaryLegionellasRequestService,
    private sanitaryLegionellaFramesServices: SanitaryLegionellaFramesRequestService,
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loaderContent = this.requestCount > 0;
    });

    this.sanitaryLegionellaFrameForm = this.formBuilder.group({
      date: this.date,
      title: this.title,
      descriptions: this.formBuilder.array([]),
    });

    if (this.activatedRoute.snapshot.params.sanitary_legionella_frame_id) {
      this.isNew = false;
      this.getSanitaryLegionellaFrame();
    } else {
      this.getSanitaryLegionella();
      setTimeout(() => {
        Flatpickr();
      }, 1);
    }
  }

  ngAfterViewInit() {
    Dropzones();
    this.modalSanitaryLegionellaFrame.open();
  }

  ngOnDestroy() {
    const flatPicker = document.getElementsByClassName('flatpickr-calendar') as HTMLCollection;
    for (let i = flatPicker.length; i >= 0; i--) {
      if (flatPicker[i]) {
        flatPicker[i].remove();
      }
    }

    const dropZones = document.getElementsByClassName('dz-hidden-input') as HTMLCollection;
    for (let i = 0; i < dropZones.length; i++) {
      dropZones[i].remove();
    }

    this.clearTooltip();

    this.modalSanitaryLegionellaFrame.close();
  }
  clearTooltip() {
    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }
  changeTabs(activeTab) {
    this.activeTab = activeTab;
  }

  getSanitaryLegionella() {
    this.requestInProgress.next(true);
    this.sanitaryLegionellasServices.getSanitaryLegionella(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.activatedRoute.snapshot.parent.params.sanitary_legionella_id
    ).subscribe(response => {
      if (response['success']) {
        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];
        this.sanitaryLegionella = response['sanitaryLegionella'];

        const control = <FormArray>this.sanitaryLegionellaFrameForm.controls['descriptions'];
        this.sanitaryLegionella['descriptions'].forEach(description => {
          this.listItems.push({title: description.title});
          const arrayHeader = this.formBuilder.array([]);
          const arrayBody = this.formBuilder.array([]);
          this.listHeader.forEach(list => {
            arrayBody.push(this.formBuilder.group({
              title: list,
              comment: this.formBuilder.control(null),
            }));
          });
          description.pointLists.forEach(pointList => {
            arrayHeader.push(this.formBuilder.group({
              lineId: pointList.lineId,
              title: pointList.value,
              comments: _.cloneDeep(arrayBody),
            }));
          });
          control.push(this.formBuilder.group({
            lineId: description.lineId,
            title: description.title,
            values: arrayHeader,
          }));
        });
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      setTimeout(() => {
        Flatpickr();
      }, 1);
      this.requestInProgress.next(false);
    });
  }

  getSanitaryLegionellaFrame() {
    this.requestInProgress.next(true);
    this.sanitaryLegionellaFramesServices.getSanitaryLegionellaFrame(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.activatedRoute.snapshot.parent.params.sanitary_legionella_id,
      this.activatedRoute.snapshot.params.sanitary_legionella_frame_id
    ).subscribe(response => {
      if (response['success']) {
        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];

        response['sanitaryLegionellaFrame']['descriptions'].forEach((description, indexDescription) => {
          this.listItems.push({title: description.title});
          this.addDescriptions(description, indexDescription);
        });
        this.sanitaryLegionellaFrameForm.patchValue(response['sanitaryLegionellaFrame']);

        if (!this.canUpdate) {
          this.sanitaryLegionellaFrameForm.disable();
        }
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      setTimeout(() => {
        Flatpickr();
      }, 1);
      this.requestInProgress.next(false);
    });
  }

  addDescriptions(description, indexDescription) {
    const control = <FormArray>this.sanitaryLegionellaFrameForm.controls['descriptions'];
    control.push(this.formBuilder.group({
      lineId:  this.formBuilder.control(''),
      title: this.formBuilder.control('', Validators.required),
      values: this.formBuilder.array([]),
    }));
    description.values.forEach((value, indexValue) => {
      this.addValue(indexDescription, value, indexValue);
    });
  }
  addValue(indexDescription, value, indexValue) {
    const control = <FormArray>this.sanitaryLegionellaFrameForm.controls['descriptions']['controls'][indexDescription].controls['values'];
    control.push(this.formBuilder.group({
      lineId:  this.formBuilder.control(''),
      title: this.formBuilder.control(''),
      comments: this.formBuilder.array([]),
    }));
    value.comments.forEach(() => {
      this.addComment(indexDescription, indexValue);
    });
    if (!value.comments.length) {
      const updateValue = <FormArray>this.sanitaryLegionellaFrameForm.controls['descriptions']['controls'][indexDescription]
        .controls['values']['controls'][indexValue].controls['comments'];
      this.listHeader.forEach(list => {
        updateValue.push(this.formBuilder.group({
          title: list,
          comment: this.formBuilder.control(null),
        }));
      });
    }
  }
  addComment(indexDescription, indexValue) {
    const control = <FormArray>this.sanitaryLegionellaFrameForm.controls['descriptions']['controls'][indexDescription]
      .controls['values']['controls'][indexValue].controls['comments'];
    control.push(this.formBuilder.group({
      title: this.formBuilder.control(null),
      comment: this.formBuilder.control(null)
    }));
  }

  createSanitaryLegionellaFrame() {
    let request;
    this.errors = {};
    this.loader = true;

    const data = {
      ...this.sanitaryLegionellaFrameForm.value,
    };

    request = this.sanitaryLegionellaFramesServices.createSanitaryLegionellaFrame(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.activatedRoute.snapshot.parent.params.sanitary_legionella_id,
      data
    );

    request.subscribe(response => {
      if (response['success']) {
        this.loader = false;
        this.modalSanitaryLegionellaFrame.close();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response['errors']) {
        this.loader = false;
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(response['errors'], this.sanitaryLegionellaFrameForm, this.notifier);
      } else {
        this.loader = false;
      }
    });
  }

  updateSanitaryLegionellaFrame() {
    let request;

    this.errors = {};
    this.loader = true;

    const data = {
      ...this.sanitaryLegionellaFrameForm.value,
    };

    request = this.sanitaryLegionellaFramesServices.updateSanitaryLegionellaFrame(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.activatedRoute.snapshot.parent.params.sanitary_legionella_id,
      this.activatedRoute.snapshot.params.sanitary_legionella_frame_id,
      data
    );

    request.subscribe(response => {
      if (response['success']) {
        this.loader = false;
        this.modalSanitaryLegionellaFrame.close();
        CustomNotifierService.getSuccessUpdate(this.notifier);
      } else if (response['errors']) {
        this.loader = false;
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      } else {
        this.loader = false;
      }
    });
  }

  bindModal(modal) {
    this.modalSanitaryLegionellaFrame = modal;
  }
  closeModal() {
    this.router.navigate([this.activatedRoute.snapshot.parent.url.join('/')]).then(() => {
      this.allSanitaryLegionellas.refreshData();
    });
  }

  changeDescription() {
    setTimeout(() => {
      Flatpickr();
    }, 1);
  }
}
