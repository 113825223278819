<app-modal-right (eventOnClose)="closeModal()" #session
  >{{ bindModal(session) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">
          {{ isNew ? "Création" : canUpdate ? "Mise à jour" : "Consultation" }}
        </h6>

        <h1 class="header-title">Membre du personnel</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <form [formGroup]="sessionForm" [hidden]="loaderContent">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="validationEstablishment" required>Établissement</label>
            <select
              class="form-control"
              data-toggle="select"
              formControlName="establishment_id"
              id="validationEstablishment"
              (change)="getTrainings()"
            >
              <option [ngValue]="null" [disabled]="true" class="text-muted">
                Sélectionner un établissement
              </option>
              <ng-container *ngFor="let estab of establishments">
                <option [value]="estab.establishment.id">
                  {{ estab.establishment.name }}
                </option>
              </ng-container>
            </select>
          </div>

          <div class="form-group">
            <label for="validationFormation" required>Formation</label>
            <select
              class="form-control"
              data-toggle="select"
              formControlName="training_id"
              id="validationFormation"
              (change)="getDateTrainings()"
              [attr.disabled]="disableSelect ? '' : null"
            >
              <option [ngValue]="null" [disabled]="true" class="text-muted">
                {{
                  disableSelect
                    ? "Veuillez d'abord Sélectionner un établissement"
                    : "Sélectionner une formation"
                }}
              </option>
              <ng-container *ngFor="let training of trainings">
                <option [value]="training.id">
                  {{ training.name }}
                </option>
              </ng-container>
            </select>
          </div>

          <div class="form-group">
            <label for="validationSessionFormation" required
              >Session formation</label
            >
            <select
              class="form-control"
              data-toggle="select"
              formControlName="date_training_id"
              id="validationSessionFormation"
              [attr.disabled]="disableSelectDate ? '' : null"
            >
              <option [ngValue]="null" [disabled]="true" class="text-muted">
                {{
                  disableSelectDate
                    ? "Veuillez d'abord Sélectionner une formation"
                    : dateTrainings.length
                    ? "Sélectionner une session de formation"
                    : "Il n'y a pas de session"
                }}
              </option>
              <ng-container *ngFor="let dateTraining of dateTrainings">
                <option [value]="dateTraining.id">
                  {{ dateTraining.date | date: "dd/MM/yyy" }}
                </option>
              </ng-container>
            </select>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div modal-footer class="w-100" *ngIf="isNew || canUpdate">
    <button
      class="btn btn-primary w-100"
      (click)="isNew ? createSession() : updateSession()"
      [ngClass]="{ 'is-loading is-loading-sm': loader }"
      [disabled]="loader || sessionForm.invalid"
    >
      {{ isNew ? "Créer" : "Mettre à jour" }}
    </button>
  </div>
</app-modal-right>
