import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AllSanitaryTemperaturesModule } from './all-sanitary-temperatures/all-sanitary-temperatures.module';
import { UpdateSanitaryTemperatureModule } from './update-sanitary-temperature/update-sanitary-temperature.module';
import { SanitaryTemperaturesRequestService } from '../../../services/sanitary-temperatures.request.service';
import { UploadSanitaryTemperatureFileModule } from './upload-sanitary-temperature-file/upload-sanitary-temperature-file.module';

@NgModule({
  imports: [
    CommonModule,
    AllSanitaryTemperaturesModule,
    UpdateSanitaryTemperatureModule,
    UploadSanitaryTemperatureFileModule
  ],
  providers: [
      SanitaryTemperaturesRequestService
  ]
})
export class SanitaryTemperaturesModule {}
