import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';

import { InterventionsRequestService } from '../../../services/interventions.request.service';
import { AllInterventionsComponent } from '../all-interventions/all-interventions.component';
import { CustomNotifierService } from '../../../services/custom.notifier.service';

@Component({
  selector: 'app-details-intervention',
  templateUrl: './details-intervention.component.html',
  styleUrls: ['./details-intervention.component.scss']
})
export class DetailsInterventionComponent implements OnInit, AfterViewInit, OnDestroy {
  details: any = [];

  loaderContent = false;

  modalDetailsIntervention = null;

  constructor(
    private router: Router,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private allInterventions: AllInterventionsComponent,
    private interventionsService: InterventionsRequestService
  ) {}

  ngOnInit() {
    this.getInterventionVoucher();
  }

  ngAfterViewInit() {
    this.modalDetailsIntervention.open();
  }

  ngOnDestroy() {
    this.modalDetailsIntervention.close();
  }

  getInterventionVoucher() {
    let request;
    this.loaderContent = true;

    request = this.interventionsService.getInterventionDetail(
      this.activatedRoute.snapshot.params.intervention_id
    );

    request.subscribe(response => {
      if (response['success']) {
        this.details = response['details'];
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loaderContent = false;
    });
  }

  bindModal(modal) {
    this.modalDetailsIntervention = modal;
  }

  closeModal() {
    this.allInterventions.refreshData();
    this.router.navigate([this.activatedRoute.snapshot.parent.url.join('/')]).then(() => {});
  }
}
