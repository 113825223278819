import { Component, OnInit } from '@angular/core';

import { AuthService } from '../auth/auth.service';

import { environment } from '../../../environments/environment';

import { Subject } from 'rxjs';
import {  Router } from '@angular/router';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  user: any;
  enterprise: any;
  hideSidebar: boolean;
  environment = environment;

  requestInProgress = new Subject();
  requestCount = 0;
  search = '';
  sort = 'Notifications.created';
  order = 'desc';
  limit = 10;
  page = 1;

  connectedAs = false;
  loader = false;

  constructor(
    public router: Router,
    public authService: AuthService,
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loader = this.requestCount > 0;
    });

    this.hideSidebar = false;

    this.connectedAs = JSON.parse(localStorage.getItem('connectedAs'));
    if (this.connectedAs) {
      this.enterprise = JSON.parse(localStorage.getItem('connectedAsEnterprise'));
      this.user = JSON.parse(localStorage.getItem('connectedAsUser'));
    } else {
      this.enterprise = JSON.parse(localStorage.getItem('enterprise'));
      this.user = JSON.parse(localStorage.getItem('user'));
    }

    const observableEnterprise = this.authService.getEnterprise();
    observableEnterprise.subscribe(value => {
      this.enterprise = value;
    });

    const observableUser = this.authService.getUser();
    observableUser.subscribe(value => {
      this.user = value;
      this.connectedAs = JSON.parse(localStorage.getItem('connectedAs'));
    });
  }

  collapseSidebar() {
    const sidenav = document.getElementById('sidebar') as HTMLElement;
    const toolbar = document.getElementsByClassName('toolbar-container') as HTMLCollection;

    if (this.hideSidebar) {
      sidenav.className = sidenav.className.replace('sidebar-hide', '');
      toolbar[0].className = toolbar[0].className.replace('toolbar-margin-left', '');
      this.hideSidebar = false;
    } else {
      sidenav.className += ' sidebar-hide';
      toolbar[0].className += ' toolbar-margin-left';
      this.hideSidebar = true;
    }
  }
}
