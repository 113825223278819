<div class="main-content">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col">
        <div class="header mb-0">
          <div class="header-body no-border">
            <div class="row align-items-center">
              <div class="col">
                <h1>{{ nom }}&nbsp;&nbsp;{{ prenom }}</h1>
                <h3 class="form-text text-muted" style="padding-bottom: 10px">
                  {{ email }}
                </h3>
                <app-breadcrumbs
                  [currentPage]="pageName"
                  [names]="previousPageName"
                  [routes]="previousPageRoute"
                ></app-breadcrumbs>

                <h2 class="header-title" style="padding-top: 30px">
                  Formations suivies
                </h2>

                <small class="form-text text-muted">
                  Liste des sessions des formations suivies
                </small>
              </div>

              <div
                class="col-12 col-md-auto mt-2 mt-md-0 mb-0"
                *ngIf="canCreate"
              >
                <a
                  class="btn btn-primary d-block d-md-inline-block"
                  [routerLink]="[currentRoute + 'create']"
                >
                  Ajouter une session de formation
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col">
            <app-searchbar
              [(ngModel)]="search"
              (keyup)="searchData()"
              ngDefaultControl
            ></app-searchbar>
          </div>
        </div>

        <div class="is-loading loader-content" *ngIf="loader"></div>

        <div class="tab-content" *ngIf="!loader && trainings.length">
          <div
            class="tab-pane fade active show"
            id="tabPaneOne"
            role="tabpanel"
          >
            <div class="row listAlias">
              <div class="col-12 d-flex flex-column">
                <div class="card flex-fill">
                  <div class="card-body">
                    <div class="table-responsive">
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th></th>
                            <th class="href" (click)="filter('Trainings.name')">
                              <a class="text-muted">Nom</a>
                              <span
                                class="fe fe-arrow-down"
                                *ngIf="
                                  sort === 'Trainings.name' && order === 'asc'
                                "
                              ></span>
                              <span
                                class="fe fe-arrow-up"
                                *ngIf="
                                  sort === 'Trainings.name' && order === 'desc'
                                "
                              ></span>
                              <span
                                class="fe fe-arrow-right"
                                *ngIf="sort !== 'Trainings.name'"
                              ></span>
                            </th>
                            <th
                              class="href"
                              (click)="filter('DateTrainings.duration')"
                            >
                              <a class="text-muted">Durée</a>
                              <span
                                class="fe fe-arrow-down"
                                *ngIf="
                                  sort === 'DateTrainings.duration' &&
                                  order === 'asc'
                                "
                              ></span>
                              <span
                                class="fe fe-arrow-up"
                                *ngIf="
                                  sort === 'DateTrainings.duration' &&
                                  order === 'desc'
                                "
                              ></span>
                              <span
                                class="fe fe-arrow-right"
                                *ngIf="sort !== 'DateTrainings.duration'"
                              ></span>
                            </th>
                            <th
                              class="href"
                              (click)="filter('DateTrainings.date')"
                            >
                              <a class="text-muted">Écheance</a>
                              <span
                                class="fe fe-arrow-down"
                                *ngIf="
                                  sort === 'DateTrainings.date' &&
                                  order === 'asc'
                                "
                              ></span>
                              <span
                                class="fe fe-arrow-up"
                                *ngIf="
                                  sort === 'DateTrainings.date' &&
                                  order === 'desc'
                                "
                              ></span>
                              <span
                                class="fe fe-arrow-right"
                                *ngIf="sort !== 'DateTrainings.date'"
                              ></span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            class="dropdown"
                            *ngFor="let training of trainings"
                          >
                            <td
                              [ngClass]="
                                training.expired ? 'fe fe-alert-triangle' : ''
                              "
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Formation éxpirée"
                            ></td>
                            <td>
                              <a>
                                {{ training.date_training.training.name }}
                              </a>
                            </td>
                            <td>
                              <a *ngIf="training.date_training.duration">
                                {{
                                  training.date_training.duration
                                    | date: "h:mm":"+0000"
                                }}
                              </a>

                              <a *ngIf="!training.date_training.duration">
                                Aucune
                              </a>
                            </td>
                            <td>
                              <a>
                                {{
                                  training.date_training.date
                                    | date: "dd/MM/yyyy"
                                }}
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <app-pagination
                      (returnEvent)="dispatchPagination($event)"
                      [count_elements]="count"
                      [current_page]="page"
                      [elements_length]="trainings.length"
                      [limit]="limit"
                      [total_pages]="pages"
                      ngDefaultControl
                    >
                    </app-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="loader-content" *ngIf="!loader && !trainings.length">
          <p>Aucun résultat</p>
        </div>
      </div>
    </div>
  </div>
</div>

<router-outlet></router-outlet>
