<div class="main-content">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col">
        <div class="header mb-0">
          <div class="header-body no-border">
            <div class="row align-items-center">
              <div class="col">
                <h1 class="header-title">
                  Membres du personnel
                </h1>

                <small class="form-text text-muted">
                  Liste des membres du personnel
                </small>

                <app-breadcrumbs [currentPage]="pageName" [names]="previousPageName" [routes]="previousPageRoute"></app-breadcrumbs>
              </div>

              <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0" *ngIf="canCreate">
                <a class="btn btn-primary d-block d-md-inline-block" [routerLink]="[currentRoute + 'sheet']">
                  Import de masse du personnel
                </a>
              </div>

              <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0" *ngIf="canCreate">
                <a class="btn btn-primary d-block d-md-inline-block" [routerLink]="[currentRoute + 'create']">
                  Ajouter un membre du personnel
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col">
            <app-searchbar [(ngModel)]="search" (keyup)="searchData()" ngDefaultControl></app-searchbar>
          </div>
        </div>

        <div class="is-loading loader-content" *ngIf="loader"></div>

        <div class="tab-content" *ngIf="!loader && employees.length">
          <div class="tab-pane fade active show" id="tabPaneOne" role="tabpanel">
            <div class="row listAlias">
              <div class="col-12 d-flex flex-column">
                <div class="card flex-fill">
                  <div class="card-body">
                    <div class="table-responsive">
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th class="href" (click)="filter('Employees.firstname')">
                              <a class="text-muted">Prénom</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'Employees.firstname' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'Employees.firstname' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'Employees.firstname'"></span>
                            </th>
                            <th class="href" (click)="filter('Employees.lastname')">
                              <a class="text-muted">Nom</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'Employees.lastname' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'Employees.lastname' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'Employees.lastname'"></span>
                            </th>
                            <th class="href" (click)="filter('Employees.year_entry_service')">
                              <a class="text-muted">Année d'entrée dans le service</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'Employees.year_entry_service' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'Employees.year_entry_service' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'Employees.year_entry_service'"></span>
                            </th>
                            <th class="href" (click)="filter('Employees.role')">
                              <a class="text-muted">Fonction</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'Employees.role' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'Employees.role' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'Employees.role'"></span>
                            </th>
                            <th class="href" (click)="filter('Employees.qualification')">
                              <a class="text-muted">Qualification / Formation</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'Employees.qualification' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'Employees.qualification' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'Employees.qualification'"></span>
                            </th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="dropdown" *ngFor="let employee of employees">
                            <td>
                              <a class="href" [routerLink]="[currentRoute + employee.id]">
                                {{ employee.firstname }}
                              </a>
                            </td>
                            <td>
                              <a class="href" [routerLink]="[currentRoute + employee.id]">
                                {{ employee.lastname }}
                              </a>
                            </td>
                            <td>{{ employee.year_entry_service }}</td>
                            <td>{{ employee.role }}</td>
                            <td>{{ employee.qualification }}</td>
                            <td class="d-flex flex-row-reverse">
                              <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fe fe-more-vertical"></i>
                              </a>

                              <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item href" [routerLink]="[currentRoute + employee.id]">
                                  {{ canUpdate ? 'Éditer le membre du personnel' : 'Consulter le membre du personnel' }}
                                </a>

                                <a class="dropdown-item href" (click)="modalConfirmData.open(employee.id)" *ngIf="canDelete">
                                  Supprimer le membre du personnel
                                </a>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <app-pagination (returnEvent)="dispatchPagination($event)"
                                    [count_elements]="count"
                                    [current_page]="page"
                                    [elements_length]="employees.length"
                                    [limit]="limit"
                                    [total_pages]="pages"
                                    ngDefaultControl>
                    </app-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="loader-content" *ngIf="!loader && !employees.length">
          <p>Aucun résultat</p>
        </div>
      </div>
    </div>
  </div>
</div>

<app-confirm-modal [type]="'delete_data'" #confirmData>{{ bindModalConfirmData(confirmData) }}</app-confirm-modal>

<router-outlet></router-outlet>
