<app-modal-right (eventOnClose)="closeModal()" #worksheet>{{ bindModal(worksheet) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">{{ isNew ? 'Création' : canUpdate ? 'Mise à jour' : 'Consultation' }}</h6>

        <h1 class="header-title">Fiche travaux</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <form [formGroup]="worksheetForm" [hidden]="loaderContent">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="validationOperatioName" required>Intitulé de l'opération</label>
            <input type="text" formControlName="operation_name" id="validationOperatioName" class="form-control" placeholder="Intitulé de l'opération"
                   [ngClass]="{ 'is-invalid': operation_name.invalid && (operation_name.dirty || operation_name.touched) }">
            <div *ngIf="operation_name.invalid && (operation_name.dirty || operation_name.touched)" class="invalid-feedback">
              {{ getErrorMessage("operation_name") }}
            </div>
          </div>

          <div class="form-group">
            <label for="validationReceiptWorkDate">Date de reception des travaux</label>
            <input type="date" formControlName="receipt_work_date" class="form-control" id="validationReceiptWorkDate" placeholder="Date de reception des travaux" data-toggle="flatpickr"
                   [ngClass]="{ 'is-invalid': receipt_work_date.invalid && (receipt_work_date.dirty || receipt_work_date.touched) }">
            <div *ngIf="receipt_work_date.invalid && (receipt_work_date.dirty || receipt_work_date.touched)" class="invalid-feedback">
              {{ getErrorMessage("receipt_work_date") }}
            </div>
          </div>

          <div class="form-group">
            <label for="validationUploadedOnDate">DACAM<span class="fe fe-info href tooltipHtml" data-placement="bottom" title="Demande d’autorisation de construire, aménager ou modifier un ERP."></span> ou PC déposé(e) le</label>
            <input type="date" formControlName="uploaded_on_date" class="form-control" id="validationUploadedOnDate" placeholder="DACAM* ou PC déposé(e) le" data-toggle="flatpickr"
                   [ngClass]="{ 'is-invalid': uploaded_on_date.invalid && (uploaded_on_date.dirty || uploaded_on_date.touched) }">
            <div *ngIf="uploaded_on_date.invalid && (uploaded_on_date.dirty || uploaded_on_date.touched)" class="invalid-feedback">
              {{ getErrorMessage("uploaded_on_date") }}
            </div>
          </div>

          <div class="form-group">
            <label for="validationAcceptedOnDate">DACAM<span class="fe fe-info href tooltipHtml" data-placement="bottom" title="Demande d’autorisation de construire, aménager ou modifier un ERP."></span> ou PC accepté(e) le</label>
            <input type="date" formControlName="accepted_on_date" class="form-control" id="validationAcceptedOnDate" placeholder="DACAM* ou PC accepté(e) le" data-toggle="flatpickr"
                   [ngClass]="{ 'is-invalid': accepted_on_date.invalid && (accepted_on_date.dirty || accepted_on_date.touched) }">
            <div *ngIf="accepted_on_date.invalid && (accepted_on_date.dirty || accepted_on_date.touched)" class="invalid-feedback">
              {{ getErrorMessage("accepted_on_date") }}
            </div>
          </div>

          <div class="form-group">
            <label for="validationDeclarationCommitmentDate">OU Déclaration d'engagement de l'exploitant relative aux travaux de rénovation ou d'aménagement (annexe note GN 10) en date du</label>
            <input type="date" formControlName="declaration_commitment_date" class="form-control" id="validationDeclarationCommitmentDate" placeholder="OU Déclaration d'engagement de l'exploitant relative aux travaux de rénovation ou d'aménagement (annexe note GN 10) en date du" data-toggle="flatpickr"
                   [ngClass]="{ 'is-invalid': declaration_commitment_date.invalid && (declaration_commitment_date.dirty || declaration_commitment_date.touched) }">
            <div *ngIf="declaration_commitment_date.invalid && (declaration_commitment_date.dirty || declaration_commitment_date.touched)" class="invalid-feedback">
              {{ getErrorMessage("declaration_commitment_date") }}
            </div>
          </div>

          <div class="form-group">
            <label for="validationNatureWork">Nature des travaux</label>
            <textarea formControlName="nature_of_work" class="form-control" id="validationNatureWork" placeholder="Nature des travaux" rows="5"
                      [ngClass]="{ 'is-invalid': nature_of_work.invalid && (nature_of_work.dirty || nature_of_work.touched) }"></textarea>
            <div *ngIf="nature_of_work.invalid && (nature_of_work.dirty || nature_of_work.touched)" class="invalid-feedback">
              {{ getErrorMessage("nature_of_work") }}
            </div>
          </div>

          <div class="form-group">
            <label for="validationProjectManager">Maître d'Oeuvre</label>
            <input type="text" formControlName="project_manager" id="validationProjectManager" class="form-control" placeholder="Maître d'Oeuvre" maxlength="255"
                   [ngClass]="{ 'is-invalid': project_manager.invalid && (project_manager.dirty || project_manager.touched) }">
            <div *ngIf="project_manager.invalid && (project_manager.dirty || project_manager.touched)" class="invalid-feedback">
              {{ getErrorMessage("project_manager") }}
            </div>
          </div>

          <div class="form-group">
            <label for="validationTechnicalController">Contrôleur technique</label>
            <input type="text" formControlName="technical_controller" id="validationTechnicalController" class="form-control" placeholder="Contrôleur technique" maxlength="255"
                   [ngClass]="{ 'is-invalid': technical_controller.invalid && (technical_controller.dirty || technical_controller.touched) }">
            <div *ngIf="technical_controller.invalid && (technical_controller.dirty || technical_controller.touched)" class="invalid-feedback">
              {{ getErrorMessage("technical_controller") }}
            </div>
          </div>

          <div class="form-group">
            <label for="validationProjectManagerReferent">Référent maîtrise d'ouvrage</label>
            <input type="text" formControlName="project_manager_referent" id="validationProjectManagerReferent" class="form-control" placeholder="Référent maîtrise d'ouvrage" maxlength="255"
                   [ngClass]="{ 'is-invalid': project_manager_referent.invalid && (project_manager_referent.dirty || project_manager_referent.touched) }">
            <div *ngIf="project_manager_referent.invalid && (project_manager_referent.dirty || project_manager_referent.touched)" class="invalid-feedback">
              {{ getErrorMessage("project_manager_referent") }}
            </div>
          </div>

          <div class="form-group">
            <label for="validationEnterprises">Entreprises</label>
            <input type="text" formControlName="enterprises" id="validationEnterprises" class="form-control" placeholder="Entreprises" maxlength="255"
                   [ngClass]="{ 'is-invalid': enterprises.invalid && (enterprises.dirty || enterprises.touched) }">
            <div *ngIf="enterprises.invalid && (enterprises.dirty || enterprises.touched)" class="invalid-feedback">
              {{ getErrorMessage("enterprises") }}
            </div>
          </div>

          <div class="form-group">
            <label for="validationCommentWorksheet">Commentaires utiles éventuels</label>
            <textarea formControlName="comment_worksheet" class="form-control" id="validationCommentWorksheet" placeholder="Commentaires éventuels" rows="5"
                      [ngClass]="{ 'is-invalid': comment_worksheet.invalid && (comment_worksheet.dirty || comment_worksheet.touched) }"></textarea>
            <div *ngIf="comment_worksheet.invalid && (comment_worksheet.dirty || comment_worksheet.touched)" class="invalid-feedback">
              {{ getErrorMessage("comment_worksheet") }}
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div modal-footer class="w-100" *ngIf="isNew || canUpdate">
    <button class="btn btn-primary w-100" (click)="isNew ? createWorksheet() : updateWorksheet()" [ngClass]="{ 'is-loading is-loading-sm': loader }" [disabled]="loader || worksheetForm.invalid">
      {{ isNew ? 'Créer' : 'Mettre à jour'}}
    </button>
  </div>
</app-modal-right>
