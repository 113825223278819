import {Compiler, Component, HostBinding, OnInit} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { NotifierService } from 'angular-notifier';

import { AuthService } from '../../../core/auth/auth.service';
import { CustomNotifierService } from '../../../services/custom.notifier.service';

import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [
    trigger('routeTransition', [
      state('void', style({ width: '100%', height: '100%', display: 'block', position: 'relative' }) ),
      state('*', style({ width: '100%', height: '100%', display: 'block', position: 'relative' }) ),
      transition(':enter', [
        style({
          opacity: '0',
          overflow: 'hidden'
        }),
        animate('.5s linear', style({
          opacity: '1',
          overflow: 'hidden'
        }))
      ]),
      transition(':leave', [
        style({
        })
      ])
    ])
  ]
})
export class LoginComponent implements OnInit {
  showPassword = false;
  isSigningIn = false;

  loginForm: FormGroup;
  email = new FormControl('', [Validators.required, Validators.email]);
  password = new FormControl('', [Validators.required]);
  errors = {};

  @HostBinding('@routeTransition') get routeTransition() {
    return;
  }

  constructor(
    private router: Router,
    private http: HttpClient,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private compiler: Compiler
  ) {}

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: this.email,
      password: this.password
    });
  }

  toggle() {
    this.showPassword = !this.showPassword;
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  signIn() {
    this.errors = {};
    this.isSigningIn = true;

    this.http.post(environment.API_PATH + 'auth/login', { user: this.loginForm.value })
      .subscribe(response => {
        if (response['success']) {
          if (response['sso_url']) {
            const queryParams: any = {};
            queryParams.redirect = true;
            this.router.navigate([response['sso_url']], { queryParams });
          } else {
            this.compiler.clearCache();
            localStorage.setItem('accessToken', response['accessToken']);
            localStorage.setItem('refreshToken', response['refreshToken']);

            localStorage.setItem('enterprises', JSON.stringify(response['enterprises']));
            localStorage.setItem('isProvider', JSON.stringify(response['is_provider']));
            localStorage.setItem('isOnlyProvider', JSON.stringify(response['is_only_provider']));
            localStorage.setItem('enterprise', JSON.stringify(response['enterprise']));
            localStorage.setItem('isAdmin', JSON.stringify(response['is_admin']));
            localStorage.setItem('user', JSON.stringify(response['user']));
            localStorage.setItem('registries', JSON.stringify(response['registries']));
            localStorage.setItem('isMonoOffer', JSON.stringify(response['is_mono_offer']));
            localStorage.setItem('isDiscoverOffer', JSON.stringify(response['is_discover_offer']));

            this.authService.changeProvider(response['is_provider']);
            this.authService.changeOnlyProvider(response['is_only_provider']);
            this.authService.changeAdmin(response['is_admin']);
            this.authService.changeRegistries(response['registries']);
            this.authService.changeMonoOffer(response['is_mono_offer']);
            this.authService.changeDiscoverOffer(response['is_discover_offer']);

            this.authService.login();
          }
        } else if (response['errors'] && !response['errors']['general']) {
          this.errors = response['errors'];
          CustomNotifierService.getErrorsForm(this.errors, this.loginForm, this.notifier);
        }
        this.isSigningIn = false;
      });
  }

  clearError() {
    this.email.updateValueAndValidity();
    this.password.updateValueAndValidity();
  }
}
