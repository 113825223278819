import { Inject, Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs';
import { FirebaseApp } from '@angular/fire';
import * as firebase from 'firebase/app';
import 'firebase/messaging';

@Injectable()

export class MessagingService {
  currentMessage = new BehaviorSubject(null);

  constructor(
    private angularFireMessaging: AngularFireMessaging,
    @Inject(FirebaseApp) private _firebaseApp: firebase.app.App
  ) {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('./firebase-messaging-sw.js').then(function (reg) {
        return reg.scope;
      }).catch(function (err) {});
    }

    const self = this;
    navigator.serviceWorker.addEventListener('message', function (event) {
      self.currentMessage.next(event.data);
    });
  }
}
