import { Component, Output, ElementRef, EventEmitter, AfterViewInit, Input } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements AfterViewInit {
  @Input() type;
  @Output() eventOnClose: EventEmitter<any> = new EventEmitter();

  modalElConfirm = null;
  id: number;

  view = {
    delete_contract: {
      title: 'Suppression',
      content: 'Vous êtes sur le point de <span class="strong">supprimer définitivement</span> un document d\'un prestataire.' +
        'Voulez-vous continuer ?'
    },
    delete_data: {
      title: 'Suppression',
      content: 'Vous êtes sur le point de <span class="strong">supprimer définitivement</span> une donnée. Voulez-vous continuer ?'
    },
    delete_employee: {
      title: 'Suppression',
      content: 'Vous êtes sur le point de <span class="strong">supprimer définitivement</span> un employé, ' +
          '<span class="strong">toutes les dates de formations et les fichiers lui étant liés seront par conséquent aussi effacés' +
          '<span class="strong">. Voulez-vous continuer ?'
    },
    delete_establishment: {
      title: 'Suppression',
      content: 'Vous êtes sur le point de <span class="strong">supprimer définitivement</span> un établissement. ' +
        '<span class="strong">Toutes les données lui étant liées seront par conséquent effacées<span class="strong">.' +
        'Voulez-vous continuer ?'
    },
    delete_file: {
      title: 'Suppression',
      content: 'Vous êtes sur le point de <span class="strong">supprimer définitivement</span> un fichier. Voulez-vous continuer ?'
    },
    delete_provider: {
      title: 'Suppression',
      content: 'Vous êtes sur le point de <span class="strong">retirer définitivement</span> un prestataire. Voulez-vous continuer ?'
    },
    delete_with_commission: {
      title: 'Suppression',
      content: 'Vous êtes sur le point de <span class="strong">supprimer définitivement</span> une donnée qui est liée à une commission ' +
        'de sécurité. <span class="strong">Cela n\'impactera pas la commission</span>. Voulez-vous continuer ?'
    },
    delete_with_localization: {
      title: 'Suppression',
      content: 'Vous êtes sur le point de <span class="strong">supprimer définitivement</span> une donnée qui est liée à une ' +
        'localisation amiante. <span class="strong">Cela n\'impactera pas la localisation</span>. Voulez-vous continuer ?'
    },
    delete_with_report: {
      title: 'Suppression',
      content: 'Vous êtes sur le point de supprimer définitivement un point de contrôle, <span class="strong">tous les ' +
        'rapports, observations et fichiers lui étant liés seront par conséquent aussi effacés</span>. Voulez-vous continuer ?'
    },
    activation: {
      title: 'Activation',
      content: 'Vous êtes sur le point <span class="strong">d\'activer</span> un établissement. Voulez-vous continuer ?'
    },
    deactivation: {
      title: 'Désactivation',
      content: 'Vous êtes sur le point de <span class="strong">désactiver</span> un établissement. Voulez-vous continuer ?' +
        'Attention, cette action est irréversible.'
    },
    confirmation: {
      title: 'Confirmation',
      content: 'Vous êtes sur le point de <span class="strong">valider</span> des données. Voulez-vous continuer ?'
    },
    verification: {
      title: 'Attention',
      content: 'Les modifications que vous avez apportées <span class="strong">ne seront pas enregistrées</span> ' +
          'si vous quittez la page sans sauvegarder. Voulez-vous continuer ?'
    },
    update_frame: {
      title: 'Attention',
      content: 'Les modifications que vous avez apportées <span class="strong">peuvent supprimer des données existante </span> ' +
        'dans les fiches de suivi. Voulez-vous continuer ?'
    }
  };

  constructor(
    private _rootNode: ElementRef
  ) {}

  open(id) {
    this.id = id;
    this.modalElConfirm.modal('show');
  }

  close() {
    this.eventOnClose.next(null);
    this.modalElConfirm.modal('hide');
  }

  closeInternal() {
    this.eventOnClose.next(this.id);
    this.modalElConfirm.modal('hide');
  }

  ngAfterViewInit() {
    this.modalElConfirm = $(this._rootNode.nativeElement).find('div.modal-confirm');

    const self = this;
    $('#confirm-modal').on('hidden.bs.modal', function () {
      self.eventOnClose.next(null);
    });
  }
}
