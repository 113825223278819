<app-modal-right (eventOnClose)="closeModal()" #commission>{{ bindModal(commission) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">{{ isNew ? 'Création' : canUpdate ? 'Mise à jour' : 'Consultation' }}</h6>

        <h1 class="header-title">{{ viewName }}</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <form [formGroup]="itemForm" [hidden]="loaderContent">

      <div class="row">
        <div class="col-12">

          <div class="form-group">
            <label for="validationTitle" required>Titre</label>
            <input type="text" formControlName="title" class="form-control" id="validationTitle" placeholder="Titre" [ngClass]="{ 'is-invalid': title.invalid && (title.dirty || title.touched) }">
            <div *ngIf="title.invalid && (title.dirty || title.touched)" class="invalid-feedback">
              {{ getErrorMessage("title") }}
            </div>
          </div>

          <div class="form-group">
            <label for="validationDescription">Description</label>
            <input type="text" formControlName="description" class="form-control" id="validationDescription" placeholder="Description" [ngClass]="{ 'is-invalid': description.invalid && (description.dirty || description.touched) }">
            <div *ngIf="description.invalid && (description.dirty || description.touched)" class="invalid-feedback">
              {{ getErrorMessage("description") }}
            </div>
          </div>

          <div class="form-group">
            <label for="validationCoefficient" required>Coefficient</label>
            <input type="number" formControlName="coefficient" class="form-control" id="validationCoefficient" placeholder="Coefficient" [ngClass]="{ 'is-invalid': coefficient.invalid && (coefficient.dirty || coefficient.touched) }">
            <div *ngIf="coefficient.invalid && (coefficient.dirty || coefficient.touched)" class="invalid-feedback">
              {{ getErrorMessage("coefficient") }}
            </div>
          </div>

        </div>
      </div>

    </form>
  </div>

  <div modal-footer class="w-100" *ngIf="isNew || canUpdate">
    <button class="btn btn-primary w-100" (click)="isNew ? createCotation() : updateCotation()" [ngClass]="{ 'is-loading is-loading-sm': loader }" [disabled]="loader || itemForm.invalid">
      {{ isNew ? 'Créer'  : 'Mettre à jour' }}
    </button>
  </div>
</app-modal-right>
