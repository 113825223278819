import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs';

import { AuthService } from '../../../core/auth/auth.service';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { RegistriesRequestService } from '../../../services/registries.request.service';
import { EstablishmentsRequestService } from '../../../services/establishments.request.service';
import { TrainingsRequestService } from '../../../services/trainings.request.service';

import EmployeeTraining from '../../../../shared/interfaces/employee_training';
import Registry from '../../../../shared/interfaces/registry';
import Establishment from '../../../../shared/interfaces/establishment';

@Component({
  selector: 'app-all-employees',
  templateUrl: './all-employees.component.html',
  styleUrls: ['./all-employees.component.scss']
})
export class AllEmployeesComponent implements OnInit, AfterViewInit, OnDestroy {

  changeEnterprise: Subscription;

  canCreate = false;
  canUpdate = false;
  canDelete = false;

  requestInProgress = new Subject();
  requestCount = 0;

  registry: Registry;
  establishment: Establishment;
  employees: EmployeeTraining[] = [];

  pageName: string;
  previousPageName: string[] = [];
  previousPageRoute: string[] = [];

  currentRoute = '';
  trainingsRoute = '';
  view = '';

  count = 0;
  pages = 1;

  search = '';
  sort = 'EmployeeTrainings.firstname';
  order = 'asc';
  limit = 12;
  page = 1;

  errors = {};

  timeout: any;
  loader = false;
  modalConfirmEmployee = null;

  constructor(
    private authService: AuthService,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private trainingsService: TrainingsRequestService,
    private registriesService: RegistriesRequestService,
    private establishmentsService: EstablishmentsRequestService,
  ) {
  }

  ngOnInit() {
    const snapshot = this.activatedRoute.snapshot;
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loader = this.requestCount > 0;
    });

    this.changeEnterprise = this.authService.getEnterprise().subscribe(() => {
      this.getRegistry();
      this.getEstablishment();
      this.getEmployees();
      this.createBreadcrumbs();
    });

    this.pageName = 'Membres du personnel - Formations';
    this.currentRoute = '/' + snapshot.url.join('/') + '/';
    this.trainingsRoute = '/dashboard-registry/' + snapshot.params.registry_id + '/establishment/' + snapshot.params.establishment_id +
      '/trainings';

    this.getRegistry();
    this.getEstablishment();
    this.getEmployees();
    this.createBreadcrumbs();
  }

  ngAfterViewInit(): void {
    this.changeEnterprise.unsubscribe();
    this.modalConfirmEmployee.eventOnClose.subscribe(event => {
      if (event) { // Event = the employee (id) to delete
        const params = this.activatedRoute.snapshot.params;
        this.trainingsService.deleteEmployee(+event, +params.establishment_id, +params.registry_id).subscribe(response => {
          if (response['success']) {
            CustomNotifierService.getSuccessDelete(this.notifier);
            this.refreshData();
          } else if (response['errors']) {
            CustomNotifierService.getErrors(response['errors'], this.notifier);
          }
        });
      }
    });
  }

  ngOnDestroy(): void {
  }

  searchData() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.page = 1;
      this.refreshData();
    }, 500);
  }

  refreshData(sort = this.sort, order = this.order, page = this.page) {
    this.sort = sort;
    this.order = order;
    this.page = page;
    this.getEmployees();
  }

  getRegistry() {
    this.requestInProgress.next(true);
    this.registriesService.getRegistry(this.activatedRoute.snapshot.params.registry_id).subscribe(response => {
      if (response['success']) {
        this.registry = response['registry'];
        this.createBreadcrumbs();
      } else if (response['errors']) {
        this.errors = response['errors'];
      }
      this.requestInProgress.next(false);
    });
  }

  getEstablishment() {
    this.requestInProgress.next(true);
    this.establishmentsService.getMyEstablishment(this.activatedRoute.snapshot.params.establishment_id).subscribe(response => {
      if (response['success']) {
        this.establishment = response['establishment'];
        this.createBreadcrumbs();
      } else if (response['errors']) {
        this.errors = response['errors'];
      }
      this.requestInProgress.next(false);
    });
  }

  getEmployees() {
    let request;
    this.requestInProgress.next(true);

    request = this.trainingsService.getEmployees(this.search, this.sort, this.order, this.limit, this.page,
      this.activatedRoute.snapshot.params.establishment_id, this.activatedRoute.snapshot.params.registry_id);

    request.subscribe(response => {
      if (response['success']) {
        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];
        this.canDelete = response['canDelete'];

        this.employees = response['results']['employees'];

        this.count = response['count'];
        this.pages = response['pages'];
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  filter(property) {
    if (this.sort === property) {
      if (this.order === 'asc') {
        this.order = 'desc';
      } else {
        this.order = 'asc';
      }
    } else {
      this.sort = property;
      this.order = 'asc';
    }

    this.getEmployees();
  }

  dispatchPagination(data) {
    if (data.action === 'total_elements') {
      this.refreshPage(data.value);
    } else if (data.action === 'change_page') {
      this.changePage(data.value);
    }
  }

  refreshPage(limit) {
    this.limit = limit;
    this.page = 1;
    this.refreshData();
  }

  changePage(page) {
    if (page >= 1 && page <= this.pages) {
      this.page = page;
      this.getEmployees();
    }
  }

  bindModalConfirmEmployee(modal) {
    this.modalConfirmEmployee = modal;
  }

  createBreadcrumbs() {
    const params = this.activatedRoute.snapshot.params;
    this.previousPageName = [];
    this.previousPageRoute = [];

    this.previousPageName.push('Tableau de bord');
    this.previousPageRoute.push('/');

    this.previousPageName.push(this.registry ? this.registry.name : 'Tableau de bord registre');
    this.previousPageRoute.push('/dashboard-registry/' + params.registry_id);

    this.previousPageName.push(this.establishment ? this.establishment.name : 'Tableau de bord établissement');
    this.previousPageRoute.push('/dashboard-registry/' + params.registry_id + '/establishment/' + params.establishment_id);
  }
}
