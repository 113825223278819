<app-modal-right (eventOnClose)="closeModal()" #checkpointModal>{{ binModal(checkpointModal) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">Création</h6>

        <h1 class="header-title">Points de contrôle</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <form [formGroup]="checkpointForm" *ngIf="!loaderContent">
      <div class="row">
        <div class="col-12">
          <!-- INSTALLATION -->
<!--          <div class="form-group">-->
<!--            <label for="validationInstallation" required>Installation</label>-->
<!--            <select class="form-control" data-toggle="select" formControlName="installation_id" id="validationInstallation"-->
<!--                    [ngClass]="{ 'is-invalid': installation_id.invalid && (installation_id.dirty || installation_id.touched) }">-->
<!--              <option value="" [selected]="!installation_id">Sélectionnez une installation</option>-->
<!--              <ng-container *ngFor="let installation of installations">-->
<!--                <option [value]="installation.id">{{ installation.name }}</option>-->
<!--              </ng-container>-->
<!--            </select>-->
<!--            <div *ngIf="installation_id.invalid && (installation_id.dirty || installation_id.touched)" class="invalid-feedback">-->
<!--              {{ getErrorMessage("installation_id") }}-->
<!--            </div>-->
<!--          </div>-->

          <!-- NAME -->
          <div class="form-group">
            <label for="validationCheckpointName" required>Nom permettant d'identifier de façon unique le point de contrôle</label>
            <input type="text" id="validationCheckpointName" formControlName="name" class="form-control" placeholder="Nom permettant d'identifier de façon unique le point de contrôle"
                   [ngClass]="{ 'is-invalid': name.invalid && (name.dirty || name.touched) }">
            <div *ngIf="name.invalid && (name.dirty || name.touched)" class="invalid-feedback">
              {{ getErrorMessage("name") }}
            </div>
          </div>

          <!-- DESCRIPTION -->
          <div class="form-group">
            <label for="validationCheckpointDescription">Description</label>
            <textarea id="validationCheckpointDescription" formControlName="description" class="form-control" placeholder="Description"
                      [ngClass]="{ 'is-invalid': description.invalid && (description.dirty || description.touched) }"></textarea>
            <div *ngIf="description.invalid && (description.dirty || description.touched)" class="invalid-feedback">
              {{ getErrorMessage("description") }}
            </div>
          </div>

          <!-- FREQUENCY DURATION -->
          <div class="form-group">
            <label for="validationDuration" required>Durée de fréquence</label>
            <input type="number" id="validationDuration" formControlName="frequency_duration" class="form-control" placeholder="Durée de fréquence" min="1" step="1"
                   (change)="changeFrequency()"
                   [ngClass]="{ 'is-invalid': frequency_duration.invalid && (frequency_duration.dirty || frequency_duration.touched) }">
            <div *ngIf="frequency_duration.invalid && (frequency_duration.dirty || frequency_duration.touched)" class="invalid-feedback">
              {{ getErrorMessage("frequency_duration") }}
            </div>
          </div>

          <!-- FREQUENCY -->
          <div class="form-group">
            <label for="validationFrequency" required>Fréquence</label>
            <select class="form-control" data-toggle="select" formControlName="frequency" id="validationFrequency"
                    (change)="changeFrequency()"
                    [ngClass]="{ 'is-invalid': frequency.invalid && (frequency.dirty || frequency.touched) }">
              <option value="" [selected]="!frequency.value">Sélectionnez une fréquence</option>
              <option value="day">Jour(s)</option>
              <option value="week">Semaine(s)</option>
              <option value="month">Mois</option>
              <option value="year">Année(s)</option>
            </select>
            <div *ngIf="frequency.invalid && (frequency.dirty || frequency.touched)" class="invalid-feedback">
              {{ getErrorMessage("frequency") }}
            </div>
          </div>

          <!-- USER -->
          <div class="form-group" *ngIf="!loaderContent">
            <label>Intervenant -  Créer des rapports</label>
            <ng-select [items]="enterprises"
                       [multiple]="false"
                       bindLabel="name"
                       notFoundText="Aucun résultat"
                       [selectableGroup]="true"
                       [closeOnSelect]="true"
                       bindValue="id"
                       placeholder="Sélectionnez un intervenant"
                       [(ngModel)]="userReport" (ngModelChange)="changeUserReport()" [ngModelOptions]="{ standalone: true }">
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">{{ item.name }}</ng-template>
            </ng-select>
          </div>
          <!-- USER -->
          <div class="form-group" *ngIf="!loaderContent">
            <label>Intervenant - Levées des observations</label>
            <ng-select [items]="enterprises"
                       [multiple]="false"
                       bindLabel="name"
                       notFoundText="Aucun résultat"
                       [selectableGroup]="true"
                       [closeOnSelect]="true"
                       bindValue="id"
                       placeholder="Sélectionnez un intervenant"
                       [(ngModel)]="userObservation" (ngModelChange)="changeUserObservation()" [ngModelOptions]="{ standalone: true }">
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">{{ item.name }}</ng-template>
            </ng-select>
          </div>

          <!-- NOTIFICATION -->
          <div class="form-group d-flex justify-content-start align-items-center">
            <div class="col pl-0">
              Notification dans le calendrier
            </div>
            <div class="custom-checkbox-toggle">
              <input type="checkbox" class="custom-control-input" id="validationCheckpointsFrequencyNotif" formControlName="frequency_notification" [ngClass]="{ 'backgroundColor': frequency_notification.value }">
              <label class="custom-control-label" [ngClass]="{ 'backgroundColor': frequency_notification.value }" for="validationCheckpointsFrequencyNotif"></label>
            </div>
          </div>

          <!-- ALERT -->
          <div class="form-group d-flex justify-content-start align-items-center">
            <div class="col pl-0">
              Rappel par email
            </div>
            <div class="custom-checkbox-toggle">
              <input type="checkbox" class="custom-control-input" id="validationCheckpointsFrequencyAlert" formControlName="frequency_alert" [ngClass]="{ 'backgroundColor': frequency_alert.value }">
              <label class="custom-control-label" [ngClass]="{ 'backgroundColor': frequency_alert.value }" for="validationCheckpointsFrequencyAlert"></label>
            </div>
          </div>

        </div>
      </div>

    </form>
  </div>

  <div modal-footer class="w-100">
    <button class="btn btn-primary w-100" (click)="createCustomCheckpoint()" [ngClass]="{ 'is-loading is-loading-sm': loader }" [disabled]="loader || checkpointForm.invalid">
      Créer
    </button>
  </div>
</app-modal-right>
