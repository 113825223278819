<app-modal-right (eventOnClose)="closeModal()" #file>{{ bindModal(file) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">{{ isNew ? 'Création' : canUpdate ? 'Mise à jour' : 'Consultation' }}</h6>

        <h1 class="header-title">Fichier</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <form [formGroup]="fileForm" [hidden]="loaderContent">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="validationName" required>Nom</label>
            <input type="text" formControlName="name" id="validationName" class="form-control" placeholder="Nom du fichier"
                   [ngClass]="{ 'is-invalid': name.invalid && (name.dirty || name.touched) }">
            <div *ngIf="name.invalid && (name.dirty || name.touched)" class="invalid-feedback">
              {{ getErrorMessage("name") }}
            </div>
          </div>

          <div class="form-group" *ngIf="!loaderContent && establishments && viewCustomer">
            <label for="establismentSelected">Autre(s) établissement(s) cible(s)</label>
            <ng-select [items]="establishments"
                       [multiple]="true"
                       bindLabel="name"
                       bindValue="id"
                       groupBy="group"
                       notFoundText="Aucun résultat"
                       [selectableGroup]="true"
                       [selectableGroupAsModel]="false"
                       [closeOnSelect]="false"
                       placeholder="Sélectionnez un établissement"
                       [(ngModel)]="establishmentsSelected" [ngModelOptions]="{ standalone: true }" [disabled]="!isNew && !canUpdate">
              <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                <input type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }"/> Sélectionnez tout
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="establismentSelected" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }"/> {{ item.name }}
              </ng-template>
            </ng-select>
          </div>

          <div class="form-group" *ngIf="!viewCustomer">
            <label for="validationOrder" required>Ordre du téléchargement/affichage</label>
            <input type="number" formControlName="order_number" id="validationOrder" class="form-control" placeholder="Ordre du téléchargement/affichage" min="0"
                   [ngClass]="{ 'is-invalid': order_number.invalid && (order_number.dirty || order_number.touched) }">
            <div *ngIf="order_number.invalid && (order_number.dirty || order_number.touched)" class="invalid-feedback">
              {{ getErrorMessage("order_number") }}
            </div>
          </div>

          <div class="form-group d-flex justify-content-start align-items-center" *ngIf="!viewCustomer">
            <div class="col pl-0">
              Le ficher est-il un modèle ?
            </div>

            <div class="custom-checkbox-toggle">
              <input type="checkbox" class="custom-control-input" id="validationIsModel" formControlName="is_model" [ngClass]="{ 'backgroundColor': is_model.value }">
              <label class="custom-control-label" [ngClass]="{ 'backgroundColor': is_model.value }" for="validationIsModel"></label>
            </div>
          </div>

          <div class="card" *ngIf="!isNew">
            <div class="card-body">
              <div class="d-flex align-content-center justify-content-between">
                <h4 class="card-title mb-0 name align-self-center">
                  <a class="href tooltipHtml" data-placement="bottom" title="Téléchargement du fichier" (click)="downloadFile(id.value, name.value + '.' + extension.value)">
                    {{ name.value + '.' + extension.value }}
                  </a>
                </h4>

                <a class="href tooltipHtml" data-placement="bottom" title="Supprimer" (click)="modalConfirmFile.open(id.value)" *ngIf="canUpdate">
                  <i class="fe fe-trash"></i>
                </a>
              </div>
            </div>
          </div>

          <div *ngIf="isNew || canUpdate && viewCustomer">
            <div class="form-group">
              <label for="inputFile" *ngIf="!isNew">Documents</label>
              <label for="inputFile" *ngIf="isNew" required>Documents</label>

              <div class="dropzone dropzone-multiple dz-clickable" appDragDrop (dropHandler)="onFileChange($event)">
                <div class="dz-default dz-message" (click)="chooseFile()">
                  <span>Déposer votre fichier ici pour télécharger</span>
                </div>
                <input id="inputFile" (change)="onFileChange($event)" type="file" [hidden]="true">
              </div>
            </div>

            <div class="form-group card" *ngIf="file_base64.value">
              <div class="card-body">
                <div class="d-flex align-content-center justify-content-between">
                  <h4 class="card-title mb-0 name align-self-center">
                    {{ fileName }}
                  </h4>

                  <a class="href tooltipHtml" data-placement="bottom" title="Supprimer" (click)="removeFile()" *ngIf="isNew || canUpdate">
                    <i class="fe fe-trash"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div modal-footer class="w-100" *ngIf="isNew || canUpdate">
    <button class="btn btn-primary w-100" (click)="isNew ? createFile() : updateFile()" [ngClass]="{ 'is-loading is-loading-sm': loader }" [disabled]="loader || fileForm.invalid">
      {{ isNew ? 'Créer' : 'Mettre à jour'}}
    </button>
  </div>
</app-modal-right>

<app-confirm-modal [type]="'delete_file'" #confirmFile>{{ bindModalConfirmFile(confirmFile) }}</app-confirm-modal>
