import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AllSanitaryCheckpointsModule } from './all-sanitary-checkpoints/all-sanitary-checkpoints.module';
import { UpdateSanitaryCheckpointModule } from './update-sanitary-checkpoint/update-sanitary-checkpoint.module';
import { SanitaryCheckpointsRequestService } from '../../services/sanitary-checkpoints.request.service';
import { RegistriesRequestService } from '../../services/registries.request.service';

@NgModule({
  imports: [
    CommonModule,
    AllSanitaryCheckpointsModule,
    UpdateSanitaryCheckpointModule,
  ],
  providers: [
    SanitaryCheckpointsRequestService,
    RegistriesRequestService
  ]
})
export class SanitaryCheckpointsModule {}
