import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';

import { AllRegistriesComponent } from '../all-registries/all-registries.component';
import { CustomNotifierService } from '../../../services/custom.notifier.service';

import { RegistriesRequestService } from '../../../services/registries.request.service';

@Component({
  selector: 'app-update-registry',
  templateUrl: './update-registry.component.html',
  styleUrls: ['./update-registry.component.scss']
})
export class UpdateRegistryComponent implements OnInit, AfterViewInit, OnDestroy {
  canCreate = false;
  canUpdate = false;

  registryForm: FormGroup;
  id = new FormControl('');
  name = new FormControl('', Validators.required);
  background_color = new FormControl('');
  documents_module = new FormControl(false);
  checkpoints_module = new FormControl(false);
  checkpoints_custom_module = new FormControl(false);
  checkpoints_name = new FormControl(null);
  commissions_module = new FormControl(false);
  commissions_name = new FormControl(null);
  expertises_module = new FormControl(false);
  expertises_name = new FormControl(null);
  agencies_module = new FormControl(false);
  agencies_name = new FormControl(null);
  formations_module = new FormControl(false);
  worksheets_module = new FormControl(false);
  rus_module = new FormControl(false);
  report_worksheets_module = new FormControl(false);
  has_manager_module = new FormControl(false);
  asbestos_module = new FormControl(false);
  cotations_module = new FormControl(false);
  employees_module = new FormControl(false);
  fire_permits_module = new FormControl(false);
  sanitary_record_module = new FormControl(false);

  errors = {};

  isNew = true;
  loader = false;
  loaderContent = false;

  modalRegistry = null;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private allRegistries: AllRegistriesComponent,
    private registriesService: RegistriesRequestService
  ) {}

  ngOnInit() {
    this.registryForm = this.formBuilder.group({
      id: this.id,
      name: this.name,
      background_color: this.background_color,
      documents_module: this.documents_module,
      checkpoints_module: this.checkpoints_module,
      checkpoints_custom_module: this.checkpoints_custom_module,
      checkpoints_name: this.checkpoints_name,
      commissions_module: this.commissions_module,
      commissions_name: this.commissions_name,
      expertises_module: this.expertises_module,
      expertises_name: this.expertises_name,
      agencies_module: this.agencies_module,
      agencies_name: this.agencies_name,
      formations_module: this.formations_module,
      worksheets_module: this.worksheets_module,
      rus_module: this.rus_module,
      report_worksheets_module: this.report_worksheets_module,
      has_manager_module: this.has_manager_module,
      asbestos_module: this.asbestos_module,
      cotations_module: this.cotations_module,
      employees_module: this.employees_module,
      fire_permits_module: this.fire_permits_module,
      sanitary_record_module: this.sanitary_record_module
    });

    if (this.activatedRoute.snapshot.params.registry_id) {
      this.isNew = false;
      this.getRegistry();
    }
  }

  ngAfterViewInit() {
    this.modalRegistry.open();
  }

  ngOnDestroy() {
    this.modalRegistry.close();
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  getRegistry() {
    this.errors = {};
    this.loaderContent = true;

    this.registriesService.getRegistry(this.activatedRoute.snapshot.params.registry_id).subscribe(response => {
      if (response['success']) {
        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];

        this.registryForm.patchValue(response['registry']);

        if (this.commissions_module.value) {
          this.commissions_name.setValidators(Validators.required);
          this.commissions_name.updateValueAndValidity();
        }

        if (this.checkpoints_module.value) {
          this.checkpoints_name.setValidators(Validators.required);
          this.checkpoints_name.updateValueAndValidity();
        }

        if (this.expertises_module.value) {
          this.expertises_name.setValidators(Validators.required);
          this.expertises_name.updateValueAndValidity();
        }

        if (!this.canUpdate) {
          this.registryForm.disable();
        }
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.registryForm, this.notifier);
      }
      this.loaderContent = false;
    });
  }

  changeCommissionRequired() {
    if (this.commissions_module.value) {
      this.commissions_name.setValidators(Validators.required);
      this.commissions_name.updateValueAndValidity();
    } else {
      this.commissions_name.setValue(null);
      this.commissions_name.clearValidators();
      this.commissions_name.updateValueAndValidity();
    }
  }

  changeExpertiseRequired() {
    if (this.expertises_module.value) {
      this.expertises_name.setValidators(Validators.required);
      this.expertises_name.updateValueAndValidity();
    } else {
      this.expertises_name.setValue(null);
      this.expertises_name.clearValidators();
      this.expertises_name.updateValueAndValidity();
    }
  }

  changeAgenciesRequired() {
    if (this.agencies_module.value) {
      this.agencies_name.setValidators(Validators.required);
      this.agencies_name.updateValueAndValidity();
    } else {
      this.agencies_name.setValue(null);
      this.agencies_name.clearValidators();
      this.agencies_name.updateValueAndValidity();
    }
  }

  changeCheckpointRequired() {
    if (this.checkpoints_module.value) {
      this.checkpoints_name.setValidators(Validators.required);
      this.checkpoints_name.updateValueAndValidity();
    } else {
      this.checkpoints_name.setValue(null);
      this.checkpoints_name.clearValidators();
      this.checkpoints_name.updateValueAndValidity();
    }
  }

  createRegistry() {
    this.errors = {};
    this.loader = true;

    this.registriesService.createRegistry(this.registryForm.value).subscribe(response => {
      if (response['success']) {
        this.modalRegistry.close();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.registryForm, this.notifier);
      }
      this.loader = false;
    });
  }

  updateRegistry() {
    this.errors = {};
    this.loader = true;

    this.registriesService.updateRegistry(this.id.value, this.registryForm.value).subscribe(response => {
      if (response['success']) {
        this.modalRegistry.close();
        CustomNotifierService.getSuccessUpdate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.registryForm, this.notifier);
      }
      this.loader = false;
    });
  }

  bindModal(modal) {
    this.modalRegistry = modal;
  }

  closeModal() {
    this.allRegistries.getRegistries();
    this.router.navigate(['registries']).then(() => {});
  }
}
