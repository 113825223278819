<app-modal-right (eventOnClose)="closeModal()" #sanitaryContributor>{{ bindModal(sanitaryContributor) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">{{ isNew ? 'Création' : canUpdate ? 'Mise à jour' : 'Consultation' }}</h6>

        <h1 class="header-title">{{ view === 'external' ? 'Intervenant externe' : 'Intervenant' }}</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <form [formGroup]="sanitaryContributorForm" [hidden]="loaderContent">
      <div class="row">
        <div class="col-12">

          <!-- LAST NAME -->
          <div class="form-group">
            <label for="validationLastName" required>Nom</label>
            <input type="text" formControlName="lastname" class="form-control" id="validationLastName" placeholder="Nom" [ngClass]="{ 'is-invalid': lastname.invalid && (lastname.dirty || lastname.touched) }">
            <div *ngIf="lastname.invalid && (lastname.dirty || lastname.touched)" class="invalid-feedback">
              {{ getErrorMessage("lastname") }}
            </div>
          </div>

          <!-- FIRST NAME -->
          <div class="form-group">
            <label for="validationFirstName" required>Prénom</label>
            <input type="text" formControlName="firstname" class="form-control" id="validationFirstName" placeholder="Prénom" [ngClass]="{ 'is-invalid': firstname.invalid && (firstname.dirty || firstname.touched) }">
            <div *ngIf="firstname.invalid && (firstname.dirty || firstname.touched)" class="invalid-feedback">
              {{ getErrorMessage("firstname") }}
            </div>
          </div>

          <!-- QUALIFICATION -->
          <div class="form-group">
            <label for="validationQualification" required>Qualification</label>
            <input type="text" formControlName="qualification" class="form-control" id="validationQualification" placeholder="Qualification" [ngClass]="{ 'is-invalid': qualification.invalid && (qualification.dirty || qualification.touched) }">
            <div *ngIf="qualification.invalid && (qualification.dirty || qualification.touched)" class="invalid-feedback">
              {{ getErrorMessage("qualification") }}
            </div>
          </div>

          <!-- PHONE -->
          <div class="form-group">
            <label for="validationPhone" required>Téléphone</label>
            <input type="tel" formControlName="phone" class="form-control" id="validationPhone" placeholder="Phone" [ngClass]="{ 'is-invalid': phone.invalid && (phone.dirty || phone.touched) }">
            <div *ngIf="phone.invalid && (phone.dirty || phone.touched)" class="invalid-feedback">
              {{ getErrorMessage("qualification") }}
            </div>
          </div>

          <!-- EMAIL -->
          <div class="form-group">
            <label for="validationEmail" required>Email</label>
            <input type="text" formControlName="email" class="form-control" id="validationEmail" placeholder="Email" [ngClass]="{ 'is-invalid': email.invalid && (email.dirty || email.touched) }">
            <div *ngIf="email.invalid && (email.dirty || email.touched)" class="invalid-feedback">
              {{ getErrorMessage("email") }}
            </div>
          </div>

          <!-- ENTERPRISE -->
          <div class="form-group" *ngIf="view === 'external'">
            <label for="validationEnterprise" required>Entreprise</label>
            <input type="text" formControlName="enterprise" class="form-control" id="validationEnterprise" placeholder="Entreprise" [ngClass]="{ 'is-invalid': enterprise.invalid && (enterprise.dirty || enterprise.touched) }">
            <div *ngIf="enterprise.invalid && (enterprise.dirty || enterprise.touched)" class="invalid-feedback">
              {{ getErrorMessage("enterprise") }}
            </div>
          </div>

          <!-- DUE DATE -->
          <div class="form-group" *ngIf="view === 'external'">
            <label for="validationDueDate" required>Date d'échéance</label>
            <input type="text" formControlName="due_date" class="form-control" id="validationDueDate" placeholder="Date d'échéance" data-toggle="flatpickr" [ngClass]="{ 'is-invalid': due_date.invalid && (due_date.dirty || due_date.touched) }">
            <div *ngIf="due_date.invalid && (due_date.dirty || due_date.touched)" class="invalid-feedback d-block">
              {{ getErrorMessage("due_date") }}
            </div>
          </div>

          <!-- DOCUMENTS -->
          <div class="form-group" *ngIf="(isNew || canUpdate) && view === 'external'">
            <label for="inputFile">Contrat</label>

            <div class="dropzone dropzone-multiple dz-clickable" appDragDrop (dropHandler)="onFileChange($event)">
              <div class="dz-default dz-message" (click)="chooseFile()">
                <span>Déposer vos fichiers ici pour télécharger</span>
              </div>
              <input id="inputFile" (change)="onFileChange($event)" type="file" [hidden]="true" multiple>
            </div>
          </div>
          <div class="card" *ngFor="let file of files; let index = index">
            <div class="card-body">
              <div class="d-flex align-content-center justify-content-between">
                <h4 class="card-title mb-0 name align-self-center">
                  {{ file.name + '.' + file.extension }}
                </h4>

                <a class="href tooltipHtml" data-placement="bottom" title="Supprimer" (click)="removeFile(index)" *ngIf="isNew || canUpdate">
                  <i class="fe fe-trash"></i>
                </a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </form>
  </div>

  <div modal-footer class="w-100" *ngIf="isNew || canUpdate">
    <button class="btn btn-primary w-100" (click)="isNew ? createSanitaryContributor() : updateSanitaryContributor()" [ngClass]="{ 'is-loading is-loading-sm': loader }" [disabled]="loader || sanitaryContributorForm.invalid">
      {{ isNew ? 'Créer' : 'Mettre à jour' }}
    </button>
  </div>
</app-modal-right>
