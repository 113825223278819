import { AfterViewInit, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Subject } from 'rxjs';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { AsbestosRequestService } from '../../../services/asbestos.request.service';

import { AllAsbestosDetectionComponent } from '../all-asbestos-detection/all-asbestos-detection.component';
import { DashboardRegistryComponent } from '../../registries/dashboard-registry/dashboard-registry.component';

import BatiFile from '../../../../shared/interfaces/bati_file';

declare function Flatpickr(): any;

@Component({
  selector: 'app-update-asbestos-detection',
  templateUrl: './update-asbestos-detection.component.html',
  styleUrls: ['./update-asbestos-detection.component.scss']
})
export class UpdateAsbestosDetectionComponent implements OnInit, AfterViewInit, OnDestroy {
  canCreate = false;
  canUpdate = false;

  requestInProgress = new Subject();
  requestCount = 0;

  allAsbestosDetection: any;
  dashboardRegistry: any;

  asbestosDetectionForm: FormGroup;
  id = new FormControl('');
  establishment_registry_id = new FormControl('');
  type = new FormControl(null);
  referency = new FormControl(null, Validators.required);
  date = new FormControl(null, Validators.required);
  organization = new FormControl(null, Validators.required);
  object = new FormControl(null, Validators.required);
  buildings_identified = new FormControl('', Validators.required);
  has_asbestos = new FormControl(false, Validators.required);

  select_localisation = [];
  files: BatiFile[] = [];
  localizationFiles: BatiFile[][] = [];

  errors = {};

  isNew = true;
  loader = false;
  loaderContent = false;

  modalAsbestosDetection = null;

  constructor(
    private router: Router,
    private injector: Injector,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private asbestosService: AsbestosRequestService,
  ) {
    if (this.activatedRoute.snapshot.url[0].path === 'asbestos-detection-dashboard') {
      this.dashboardRegistry = <DashboardRegistryComponent>this.injector.get(DashboardRegistryComponent);
    } else {
      this.allAsbestosDetection = <AllAsbestosDetectionComponent>this.injector.get(AllAsbestosDetectionComponent);
    }
  }

  ngOnInit() {
    this.asbestosDetectionForm = this.formBuilder.group({
      id: this.id,
      establishment_registry_id: this.establishment_registry_id,
      type: this.type,
      referency: this.referency,
      date: this.date,
      organization: this.organization,
      object: this.object,
      buildings_identified: this.buildings_identified,
      has_asbestos: this.has_asbestos,

      asbestos_detection_localizations: this.formBuilder.array([]),
    });

    if (this.activatedRoute.snapshot.params.asbestos_detection_id) {
      this.isNew = false;
      this.getAsbestosDetection();
    } else {
      Flatpickr();
    }
  }

  ngAfterViewInit() {
    this.modalAsbestosDetection.open();
  }

  ngOnDestroy() {
    const flatPicker = document.getElementsByClassName('flatpickr-calendar') as HTMLCollection;
    for (let i = flatPicker.length; i >= 0; i--) {
      if (flatPicker[i]) {
        flatPicker[i].remove();
      }
    }

    this.modalAsbestosDetection.close();
  }

  initLocalizations() {
    return this.formBuilder.group({
      id: [null],
      localization: [null, Validators.required],
      accuracy_localization: [null, Validators.required],
      identifier: [null, Validators.required],
      description: [null, Validators.required],
      conservation_state: [null, Validators.required],
      recommended_measures: [null, Validators.required],
      periodicity: [null, Validators.required],
      result: [null]
    });
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  getAsbestosDetection() {
    let request;
    this.loaderContent = true;

    if (this.activatedRoute.snapshot.url[0].path === 'asbestos-detection-dashboard' &&
        !this.activatedRoute.snapshot.parent.params.establishment_id) {
      request = this.asbestosService.getRegistryAsbestosDetection(
        this.activatedRoute.snapshot.parent.params.registry_id,
        this.activatedRoute.snapshot.params.asbestos_detection_id
      );
    } else {
      request = this.asbestosService.getAsbestosDetection(
        this.activatedRoute.snapshot.parent.params.registry_id,
        this.activatedRoute.snapshot.parent.params.establishment_id,
        this.activatedRoute.snapshot.params.asbestos_detection_id
      );
    }

    request.subscribe(response => {
      if (response['success']) {
        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];

        response['asbestosDetection']['asbestos_detection_localizations'].forEach(() => {
          this.addLocalization();
        });

        this.asbestosDetectionForm.patchValue(response['asbestosDetection']);

        if (this.buildings_identified.value) {
          const temp = [];
          this.select_localisation = this.buildings_identified.value;
          this.buildings_identified.value.forEach(value => {
            temp.push(value.replace(/(^|\r\n|\n)([^• ]|$)/g, '$1• $2'));
          });
          this.buildings_identified.setValue(temp.join('\n'));
        }

        if (!this.canUpdate) {
          this.asbestosDetectionForm.disable();
        }
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      Flatpickr();
      this.loaderContent = false;
    });
  }

  createAsbestosDetection() {
    let request;
    this.errors = {};
    // this.loader = true;

    if (this.buildings_identified.value) {
      const temp = [];
      const buildings_identified: string = this.buildings_identified.value;
      const splitValue = buildings_identified.split('\n');

      splitValue.forEach(value => {
        const reg = new RegExp(/^\s*•\s+/gm);
        value = value.replace(reg, '');
        if (value) {
          temp.push(value);
        }
      });

      this.buildings_identified.setValue(temp);
    }

    request = this.asbestosService.createAsbestosDetection(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.parent.params.establishment_id,
      this.asbestosDetectionForm.value
    );

    request.subscribe(response => {
      if (response['success']) {
        this.isNew = false;
        if (response['asbestosDetectionLocalizations']) {
          response['asbestosDetectionLocalizations'].forEach((localization, index) => {
            this.createLocalizationDocuments(index, localization['id'], response['asbestosDetection']['id']);
          });
        }
        if (this.files.length) {
          this.createDocuments(response['asbestosDetection']['id']);
        } else {
          this.loader = false;
          this.modalAsbestosDetection.close();
          CustomNotifierService.getSuccessCreate(this.notifier);
        }
      } else if (response['errors']) {
        this.loader = false;
        this.errors = response['errors'];
        this.buildings_identified.setValue(null);
        CustomNotifierService.getErrorsForm(response['errors'], this.asbestosDetectionForm, this.notifier);
      }

      if (this.buildings_identified.value) {
        this.buildings_identified.setValue(this.buildings_identified.value.join('\n'));
        this.buildings_identified.setValue(this.buildings_identified.value.replace(/(^|\r\n|\n)([^• ]|$)/g, '$1• $2'));
      }
    });
  }

  createDocuments(asbestos_detection_id) {
    let request;
    this.errors = {};

    request = this.asbestosService.createAsbestosDetectionFile(
      this.activatedRoute.snapshot.parent.params.registry_id,
      asbestos_detection_id,
      this.files
    );

    request.subscribe(response => {
      if (response['success']) {
        this.modalAsbestosDetection.close();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loader = false;
    });
  }
  createLocalizationDocuments(index, localization_id, asbestos_detection_id) {
    let request;
    this.errors = {};

    request = this.asbestosService.createAsbestosDetectionLocalizationFile(
      this.activatedRoute.snapshot.parent.params.registry_id,
      asbestos_detection_id,
      localization_id,
      this.localizationFiles[index]
    );

    request.subscribe(response => {
      if (response['success']) {
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loader = false;
    });
  }

  updateAsbestosDetection() {
    let request;
    let asbestosDetection;

    this.errors = {};
    this.loader = true;

    if (this.buildings_identified.value) {
      const temp = [];
      const buildings_identified: string = this.buildings_identified.value;
      const splitValue = buildings_identified.split('\n');

      splitValue.forEach(value => {
        const reg = new RegExp(/^\s*•\s+/gm);
        value = value.replace(reg, '');
        if (value) {
          temp.push(value);
        }
      });
      this.buildings_identified.setValue(temp);
    }

    asbestosDetection = {
      ...this.asbestosDetectionForm.value,
      id: this.id.value,
      establishment_registry_id: this.establishment_registry_id.value
    };

    request = this.asbestosService.updateRegistryAsbestosDetection(
      this.activatedRoute.snapshot.parent.params.registry_id,
      this.activatedRoute.snapshot.params.asbestos_detection_id,
      asbestosDetection
    );

    request.subscribe(response => {
      if (response['success']) {
        if (response['asbestosDetectionLocalizations']) {
          response['asbestosDetectionLocalizations'].forEach((localization, index) => {
            this.createLocalizationDocuments(index, localization['id'], response['asbestosDetection']['id']);
          });
        }
        if (this.files.length) {
          this.createDocuments(this.activatedRoute.snapshot.params.asbestos_detection_id);
        } else {
          this.loader = false;
          this.modalAsbestosDetection.close();
          CustomNotifierService.getSuccessUpdate(this.notifier);
        }
      } else if (response['errors']) {
        this.loader = false;
        this.errors = response['errors'];
        this.buildings_identified.setValue(null);
        CustomNotifierService.getErrorsForm(response['errors'], this.asbestosDetectionForm, this.notifier);
      }

      if (this.buildings_identified.value) {
        this.buildings_identified.setValue(this.buildings_identified.value.join('\n'));
        this.buildings_identified.setValue(this.buildings_identified.value.replace(/(^|\r\n|\n)([^• ]|$)/g, '$1• $2'));
      }
    });
  }

  addTextareaBullet(event) {
    if (event.srcElement.value) {
      event.srcElement.value = event.srcElement.value.replace(/(^|\r\n|\n)([^• ]|$)/g, '$1• $2');

      // ADD NEW LOCALIZATION
      const temp = [];
      const splitValue = event.srcElement.value.split('\n');
      splitValue.forEach(value => {
        const reg = new RegExp(/^\s*•\s+/gm);
        value = value.replace(reg, '');
        if (value) {
          temp.push(value);
        }
      });
      this.select_localisation = temp;

      // REMOVE LOCALIZATION
      const id = [];
      this.select_localisation.forEach((value, index) => {
        value = value.replace(/(^|\r\n|\n)([^• ]|$)/g, '$1• $2');
        if (!splitValue.includes(value)) {
          id.unshift(index);
        }
      });
      id.forEach(value => {
        this.select_localisation.splice(value, 1);
      });
    } else {
      this.select_localisation = [];
    }
  }

  pasteTextareaBullet(event) {
    setTimeout(() => {
      event.srcElement.value = event.srcElement.value.replace(/^\s+|\s+$/g, '');
      event.srcElement.value = event.srcElement.value.replace(/[\r\n]+/g, '\n');
      event.srcElement.value = event.srcElement.value.replace(/(^|\r\n|\n)([^• ]|$)/g, '$1• $2');
    }, 150);
  }

  changeHasAsbestos() {
    const control = <FormArray>this.asbestosDetectionForm.controls['asbestos_detection_localizations'];
    if (!control.controls.length) {
      this.addLocalization();
    }
    control.controls.forEach(element => {
      if (this.has_asbestos.value) {
        element['controls'].localization.setValidators(Validators.required);
        element['controls'].accuracy_localization.setValidators(Validators.required);
        element['controls'].identifier.setValidators(Validators.required);
        element['controls'].description.setValidators(Validators.required);
        element['controls'].conservation_state.setValidators(Validators.required);
        element['controls'].recommended_measures.setValidators(Validators.required);
        element['controls'].periodicity.setValidators(Validators.required);
      } else {
        element['controls'].localization.clearValidators();
        element['controls'].accuracy_localization.clearValidators();
        element['controls'].identifier.clearValidators();
        element['controls'].description.clearValidators();
        element['controls'].conservation_state.clearValidators();
        element['controls'].recommended_measures.clearValidators();
        element['controls'].periodicity.clearValidators();
      }

      element['controls'].localization.updateValueAndValidity();
      element['controls'].accuracy_localization.updateValueAndValidity();
      element['controls'].identifier.updateValueAndValidity();
      element['controls'].description.updateValueAndValidity();
      element['controls'].conservation_state.updateValueAndValidity();
      element['controls'].recommended_measures.updateValueAndValidity();
      element['controls'].periodicity.updateValueAndValidity();
    });
  }

  changeRecommendedMeasures() {
    const control = <FormArray>this.asbestosDetectionForm.controls['asbestos_detection_localizations'];
    control.controls.forEach(element => {
      if (element['value'].recommended_measures === 'Évaluation périodique' ||
          element['value'].recommended_measures === 'Mesure d\'empoussièrement') {
        element['controls'].periodicity.enable();
        element['controls'].periodicity.setValidators(Validators.required);
        element['controls'].periodicity.updateValueAndValidity();
      } else {
        element['controls'].periodicity.disable();
        element['controls'].periodicity.setValue(null);
        element['controls'].periodicity.clearValidators();
        element['controls'].periodicity.updateValueAndValidity();
      }
    });
  }

  addLocalization() {
    const control = <FormArray>this.asbestosDetectionForm.controls['asbestos_detection_localizations'];
    control.push(this.initLocalizations());
    this.changeRecommendedMeasures();
  }

  deleteLocalization(index) {
    const control = <FormArray>this.asbestosDetectionForm.controls['asbestos_detection_localizations'];
    control.removeAt(index);
    this.localizationFiles.splice(index, 1);
    if (!control.controls.length) {
      this.has_asbestos.setValue(false);
    }
  }

  chooseFile() {
    const element: HTMLElement = document.getElementById('inputFile') as HTMLElement;
    element.click();
  }

  onFileChange(event) {
    if (event.target && event.target.files && event.target.files.length) {
      for (let i = 0; i < event.target.files.length; i++) {
        const reader = new FileReader();
        const file = event.target.files[i];
        reader.readAsDataURL(file);

        reader.onload = () => {
          let extension = '';
          let name = '';

          const split = file.name.split('.');
          if (split.length) {
            name = split[0];
            extension = split[split.length - 1];
          }

          this.files.push({ file_base64: reader.result, name: name, extension: extension });
        };
      }
    } else {
      let extension = '';
      let name = '';

      const split = event.name.split('.');
      if (split.length) {
        name = split[0];
        extension = split[split.length - 1];
      }

      this.files.push({ file_base64: event.file, name: name, extension: extension });
    }
    const element: HTMLInputElement = document.getElementById('inputFile') as HTMLInputElement;
    element.value = '';
  }

  removeFile(index) {
    this.files.splice(index, 1);

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  chooseLocalizationFile(index) {
    const element: HTMLElement = document.getElementById('inputFileLocalization_' + index) as HTMLElement;
    element.click();
  }

  onLocalizationFileChange(index, event) {
    if (event.target && event.target.files && event.target.files.length) {
      for (let i = 0; i < event.target.files.length; i++) {
        const reader = new FileReader();
        const file = event.target.files[i];
        reader.readAsDataURL(file);

        reader.onload = () => {
          let extension = '';
          let name = '';

          const split = file.name.split('.');
          if (split.length) {
            name = split[0];
            extension = split[split.length - 1];
          }

          if (!this.localizationFiles[index]) {
            this.localizationFiles[index] = [];
          }
          this.localizationFiles[index].push({ file_base64: reader.result, name: name, extension: extension });
        };
      }
    } else {
      let extension = '';
      let name = '';

      const split = event.name.split('.');
      if (split.length) {
        name = split[0];
        extension = split[split.length - 1];
      }

      if (!this.localizationFiles[index]) {
        this.localizationFiles[index] = [];
      }
      this.localizationFiles[index].push({ file_base64: event.file, name: name, extension: extension });
    }
    const element: HTMLInputElement = document.getElementById('inputFileLocalization_' + index) as HTMLInputElement;
    element.value = '';
  }

  removeLocalizationFile(index1, index2) {
    this.localizationFiles[index1].splice(index2, 1);

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  bindModal(modal) {
    this.modalAsbestosDetection = modal;
  }

  closeModal() {
    if (this.activatedRoute.snapshot.url[0].path === 'asbestos-detection-dashboard') {
      this.dashboardRegistry.getAsbestos();
    } else {
      this.allAsbestosDetection.refreshData();
    }
    this.router.navigate([this.activatedRoute.snapshot.parent.url.join('/')]).then(() => {});
  }
}
