import { Component, OnDestroy, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { frequencies } from '../../../../shared/frequencies';
import { AuthService } from '../../../core/auth/auth.service';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { CheckpointsRequestService } from '../../../services/checkpoints.request.service';
import { RegistriesRequestService } from '../../../services/registries.request.service';
import { EstablishmentsRequestService } from '../../../services/establishments.request.service';
import { InstallationsRequestService } from '../../../services/installations.request.service';

import Checkpoint from '../../../../shared/interfaces/checkpoint';
import Establishment from '../../../../shared/interfaces/establishment';
import Registry from '../../../../shared/interfaces/registry';
import Installation from '../../../../shared/interfaces/installation';

@Component({
  selector: 'app-all-customer-checkpoints',
  templateUrl: './all-customer-checkpoints.component.html',
  styleUrls: ['./all-customer-checkpoints.component.scss']
})
export class AllCustomerCheckpointsComponent implements OnInit, OnDestroy {
  changeEnterprise: Subscription;
  environment = environment;

  canUpdate = false;
  canCreate = false;

  requestInProgress = new Subject();
  requestCount = 0;

  pageName: string;
  previousPageName: string[] = [];
  previousPageRoute: string[] = [];
  currentRoute = '';

  establishment: Establishment;
  registry: Registry;
  installation: Installation;
  checkpoints: Checkpoint[] = [];
  count = 0;
  pages = 1;

  search = '';
  sort = 'Checkpoints.name';
  order = 'asc';
  limit = 12;
  page = 1;

  view = '';
  errors = {};
  timeout: any;
  loader = false;

  constructor(
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private registriesService: RegistriesRequestService,
    private establishmentsService: EstablishmentsRequestService,
    private installationsService: InstallationsRequestService,
    private checkpointsService: CheckpointsRequestService
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loader = this.requestCount > 0;
    });

    this.changeEnterprise = this.authService.getEnterprise().subscribe(() => {
      this.createBreadcrumbs();
      this.getCheckpoints();
    });

    if (this.authService.loaded) {
      this.createBreadcrumbs();
      this.getCheckpoints();
    }
  }

  ngOnDestroy() {
    this.changeEnterprise.unsubscribe();

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  searchData() {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.page = 1;
      this.refreshData();
    }, 500);
  }

  refreshData(sort = this.sort, order = this.order, page = this.page) {
    this.sort = sort;
    this.order = order;
    this.page = page;
    this.getCheckpoints();
  }

  getCheckpoints() {
    this.requestInProgress.next(true);

    const enterprise_id = this.activatedRoute.snapshot.params.enterprise_id;
    const establishment_id = this.activatedRoute.snapshot.params.establishment_id;
    const registry_id = this.activatedRoute.snapshot.params.registry_id;
    const installation_id = this.activatedRoute.snapshot.params.installation_id;

    this.checkpointsService.getCustomerCheckpoints(
      this.search, this.sort, this.order, this.limit, this.page,
      establishment_id, registry_id, installation_id
    ).subscribe(response => {
      if (response['success']) {
        this.checkpoints = response['checkpoints'];

        this.count = response['count'];
        this.pages = response['pages'];
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  getFrequencyText(frequency, number) {
    if (frequencies[frequency] && frequencies[frequency][number]) {
      return frequencies[frequency][number];
    } else {
      if (frequency === 'year') {
        if (number > 1) {
          return number + ' ans';
        } else {
          return number + ' an';
        }
      } else if (frequency === 'month') {
        return number + ' mois';
      } else if (frequency === 'week') {
        if (number > 1) {
          return number + ' semaines';
        } else {
          return number + ' semaine';
        }
      } else if (frequency === 'day') {
        if (number > 1) {
          return number + ' jours';
        } else {
          return number + ' jour';
        }
      }
    }
  }

  getFrequencyRequired(required_frequencies) {
    let required = false;
    required_frequencies.forEach(frequency => {
      if (frequency.frequency_type === 'required_by_law') {
        required = true;
      }
    });
    return required;
  }

  filter(property) {
    if (this.sort === property) {
      if (this.order === 'asc') {
        this.order = 'desc';
      } else {
        this.order = 'asc';
      }
    } else {
      this.sort = property;
      this.order = 'asc';
    }

    this.refreshData();
  }

  dispatchPagination(data) {
    if (data.action === 'total_elements') {
      this.refreshPage(data.value);
    } else if (data.action === 'change_page') {
      this.changePage(data.value);
    }
  }

  refreshPage(limit) {
    this.limit = limit;
    this.page = 1;
    this.refreshData();
  }

  changePage(page) {
    if (page >= 1 && page <= this.pages) {
      this.page = page;
      this.refreshData();
    }
  }

  createBreadcrumbs() {
    this.currentRoute = '/' + this.activatedRoute.snapshot.url.join('/');

    this.pageName = 'Points de contrôle';
    this.previousPageName.push('Tableau de bord');
    this.previousPageRoute.push('/');

    this.previousPageName.push('Mes clients');
    this.previousPageRoute.push('/customers');

    this.previousPageName.push('Etablissements');
    this.previousPageRoute.push(
      '/customers/' + this.activatedRoute.snapshot.params.enterprise_id + '/establishments'
    );

    this.previousPageName.push('Registres');
    this.previousPageRoute.push(
      '/customers/' + this.activatedRoute.snapshot.params.enterprise_id +
      '/establishments/' + this.activatedRoute.snapshot.params.establishment_id +
      '/registries'
    );

    this.previousPageName.push('Installations');
    this.previousPageRoute.push(
      '/customers/' + this.activatedRoute.snapshot.params.enterprise_id +
      '/establishments/' + this.activatedRoute.snapshot.params.establishment_id +
      '/registries/' + this.activatedRoute.snapshot.params.registry_id +
      '/installations'
    );
  }
}
