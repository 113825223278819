
<div class="dropdown mr-4">
  <a class="text-muted" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <span id="notificationIcon" class="icon" [ngClass]="{ 'active' : notificationService.notReadNotification.length }">
            <i class="fe fe-bell"></i>
          </span>
  </a>
  <div class="dropdown-menu dropdown-menu-right dropdown-menu-card">
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col">
          <h5 class="card-header-title">
            Notifications
          </h5>
        </div>
        <div class="col-auto">
          <a [routerLink]="['/my-notifications']" class="small">
            Voir tout
          </a>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="is-loading" heigth="100" *ngIf="loader"></div>
      <div class="list-group list-group-flush my--3" *ngIf="!loader && notificationService.notReadNotification.length">
        <div class="list-group-item px-0" *ngFor="let notification of notificationService.notReadNotification">
          <div class="row">
            <div class="col-auto">
              <span class="icon">
                 <i class="fe fe-mail"></i>
              </span>
            </div>
            <div class="col ml--2">
              <div class="small text-muted">{{ notification['title'] }}</div>
              <div class="small text-muted" [innerHTML]="sanitizerHtml(notification['body'])"></div>
            </div>
          </div>
          <div class="d-flex justify-content-between mt-2">
            <a class="href text-muted small" (click)="markAsRead(notification['id'])">
              Marquer comme lu
            </a>
            <a class="text-muted small href" target="_blank" *ngIf="notification['url'] && notification['url'].includes('/')" [href]="notification['url']" (click)="markAsRead(notification['id'])">
              Voir la notification
            </a>
            <a class="text-muted small href" *ngIf="notification['url'] && !notification['url'].includes('/')" (click)="markAsRead(notification['id']); downloadInterventionPdf(notification['url'])">
              Télécharger le B.I.
            </a>
          </div>
        </div>
      </div>
      <div class="list-group list-group-flush my--3" *ngIf="!loader && !notificationService.notReadNotification.length">
        <a class="list-group-item px-0">
          <div class="row">
            <div class="col ml--2">
              <div class="small text-muted">
                Aucune notification en attente
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>
