import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { HttpClient } from '@angular/common/http';
import { RequestService } from './request.service';

@Injectable()
export class SanitaryOperationFramesRequestService extends RequestService {
  constructor(
    protected http: HttpClient,
    protected notifier: NotifierService
  ) {
    super(http, notifier);
  }

  /// GET ALL
  public getAllSanitaryOperationFrames(search, sort, order, limit, page, registry_id, establishment_id, sanitaryOperationId) {
    return this.get( 'registry/' + registry_id + '/establishment/' + establishment_id + '/sanitary-operations/' +
      sanitaryOperationId + '/operation-frames?' + 'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit +
      '&page=' + page);
  }
  /// GET ONE
  public getSanitaryOperationFrame(registry_id, establishment_id, sanitaryOperationId, sanitaryOperationFrameId) {
    return this.get('registry/' + registry_id + '/establishment/' + establishment_id + '/sanitary-operations/' + sanitaryOperationId +
      '/operation-frame/' + sanitaryOperationFrameId);
  }
  /// CREATE
  public createSanitaryOperationFrame(registry_id, establishment_id, sanitaryOperationId, sanitaryOperationFrame) {
    return this.post('registry/' + registry_id + '/establishment/' + establishment_id + '/sanitary-operations/' + sanitaryOperationId
      + '/operation-frame/', { sanitaryOperationFrame });
  }
  /// UPDATE
  public updateSanitaryOperationFrame(registry_id, establishment_id, sanitaryOperationId, sanitaryOperationFrameId,
                                       sanitaryOperationFrame) {
    return this.put('registry/' + registry_id + '/establishment/' + establishment_id + '/sanitary-operations/' + sanitaryOperationId +
      '/operation-frame/' + sanitaryOperationFrameId, { sanitaryOperationFrame });
  }
  /// DELETE
  public deleteSanitaryOperationFrame(registry_id, establishment_id, sanitaryOperationId, sanitaryOperationFrameId) {
    return this.delete('registry/' + registry_id + '/establishment/' + establishment_id + '/sanitary-operations/' + sanitaryOperationId +
        '/operation-frame/' + sanitaryOperationFrameId);
  }
}
