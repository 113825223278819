import { Routes } from '@angular/router';

import { AllObservationsComponent } from './all-observations/all-observations.component';
import { UpdateObservationComponent } from './update-observation/update-observation.component';
import { UpdateObservationStateComponent } from './update-observation-state/update-observation-state.component';
import { UploadObservationFileComponent } from './upload-observation-file/upload-observation-file.component';
import { UpdateInterventionComponent } from '../interventions/update-intervention/update-intervention.component';

export const observationsRoutes: Routes = [
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/installations/:installation_id/checkpoints/:checkpoint_id/' +
      'reports/:report_id/observations',
    component: AllObservationsComponent,
    children: [
      {
        path: 'create',
        component: UpdateObservationComponent
      },
      {
        path: ':observation_id',
        component: UpdateObservationComponent
      },
      {
        path: ':observation_id/state',
        component: UpdateObservationStateComponent
      },
      {
        path: ':observation_id/intervention/create',
        component: UpdateInterventionComponent
      },
    ]
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/installations/:installation_id/checkpoints/:checkpoint_id/' +
      'reports/:report_id/observations/:observation_id/files',
    component: AllObservationsComponent,
    children: [
      {
        path: 'create',
        component: UploadObservationFileComponent
      },
      {
        path: ':file_id',
        component: UploadObservationFileComponent
      }
    ]
  }
];
