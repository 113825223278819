import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NotifierService } from "angular-notifier";
import { Subject } from "rxjs";
import { CustomNotifierService } from "src/app/services/custom.notifier.service";
import { TrainingsRequestService } from "src/app/services/trainings.request.service";
import Training from "src/shared/interfaces/training";

@Component({
  selector: "app-get-all-training",
  templateUrl: "./get-all-training.component.html",
  styleUrls: ["./get-all-training.component.scss"],
})
export class GetAllTrainingsComponent implements OnInit {
  pageName: string;
  previousPageName: string[] = [];
  previousPageRoute: string[] = [];
  currentRoute = "";

  canCreate = false;
  canUpdate = false;
  canDelete = false;

  count = 0;
  pages = 1;

  search = "";
  sort = "";
  order = "asc";
  limit = 12;
  page = 1;

  errors = {};

  timeout: any;
  loader = false;

  trainings: Training[] = [];

  modalConfirmData = null;

  requestInProgress = new Subject();

  constructor(
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private trainingsService: TrainingsRequestService
  ) {}

  ngOnInit() {
    this.pageName = "";
    this.currentRoute = "/" + this.activatedRoute.snapshot.url.join("/") + "/";
    this.createBreadcrumbs();
    this.getAllTrainings();
  }

  ngAfterViewInit() {
    this.modalConfirmData.eventOnClose.subscribe((event) => {
      if (event) {
        this.requestInProgress.next(true);
        let request;

        request = this.trainingsService.deleteTrainingFromMainMenu(event);

        request.subscribe((response) => {
          if (response["success"]) {
            this.refreshData();
            CustomNotifierService.getSuccessDelete(this.notifier);
          } else if (response["errors"]) {
            CustomNotifierService.getErrors(response["errors"], this.notifier);
          }
          this.requestInProgress.next(false);
        });
      }
    });
  }

  searchData() {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.page = 1;
      this.refreshData();
    }, 500);
  }

  refreshData(sort = this.sort, order = this.order, page = this.page) {
    this.sort = sort;
    this.order = order;
    this.page = page;
    this.getAllTrainings();
  }

  getAllTrainings() {
    let request;
    this.loader = true;
    const snapshot = this.activatedRoute.snapshot;

    request = this.trainingsService.getAllTrainings(
      this.search,
      this.sort,
      this.order,
      this.limit,
      this.page,
      [],
      null
    );

    request.subscribe((response) => {
      if (response["success"]) {
        this.canUpdate = response["canUpdate"];
        this.canCreate = response["canCreate"];
        this.canDelete = response["canDelete"];

        this.trainings = response["trainings"];

        this.pages = response["pages"];
        this.count = response["count"];
      } else if (response["errors"]) {
        CustomNotifierService.getErrors(response["errors"], this.notifier);
      }
      this.loader = false;
    });
  }

  dispatchPagination(data) {
    if (data.action === "total_elements") {
      this.refreshPage(data.value);
    } else if (data.action === "change_page") {
      this.changePage(data.value);
    }
  }

  refreshPage(limit) {
    this.limit = limit;
    this.page = 1;
    this.refreshData();
  }

  changePage(page) {
    if (page >= 1 && page <= this.pages) {
      this.page = page;
      this.getAllTrainings();
    }
  }

  filter(property) {
    if (this.sort === property) {
      if (this.order === "asc") {
        this.order = "desc";
      } else {
        this.order = "asc";
      }
    } else {
      this.sort = property;
      this.order = "asc";
    }

    this.getAllTrainings();
  }

  createBreadcrumbs() {
    this.previousPageName = [];
    this.previousPageRoute = [];

    this.previousPageName.push("Formations");
    this.previousPageRoute.push("/formations");
  }

  bindModalConfirmData(modal) {
    this.modalConfirmData = modal;
  }
}
