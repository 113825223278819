<div class="main-content">

  <div class="header bg-dark pb-5">
    <div class="container-fluid">

      <div class="header-body">
        <div class="row align-items-end">
          <div class="col">

            <h6 class="header-pretitle text-secondary">
              TABLEAU DE BORD
            </h6>

            <h1 class="header-title text-white">
              Statistiques Dossier Technique Amiante
            </h1>

          </div>
          <div class="col-auto">

            <ul class="nav nav-tabs header-tabs">
              <li class="nav-item" data-toggle="chart" data-target="#headerChart">
                <a href="#" class="nav-link text-center active" data-toggle="tab" (click)="updateAsbestosChart('month')">
                  <h6 class="header-pretitle text-secondary">
                    GROUPER PAR
                  </h6>
                  <h3 class="text-white mb-0">
                    MOIS
                  </h3>
                </a>
              </li>
              <li class="nav-item" data-toggle="chart" data-target="#headerChart">
                <a href="#" class="nav-link text-center" data-toggle="tab" (click)="updateAsbestosChart('day')">
                  <h6 class="header-pretitle text-secondary">
                    GROUPER PAR
                  </h6>
                  <h3 class="text-white mb-0">
                    JOUR
                  </h3>
                </a>
              </li>
            </ul>

          </div>
        </div>
      </div>

      <div class="header-footer">

        <div class="chart">
          <canvas id="headerChart" class="chart-canvas"></canvas>
        </div>

      </div>

    </div>
  </div>

  <div class="container-fluid mt-n6">
    <div class="row">
      <div class="col-12">

        <div class="card">
          <!-- INPUTS -->
          <div class="card-header">
            <div class="row align-items-center">
              <!-- ESTABLISHMENTS -->
              <div class="col-6" *ngIf="establishments">
                <div class="form-group">
                  <label>Etablissements</label>
                  <ng-select [items]="establishments"
                             [multiple]="true"
                             bindLabel="name"
                             notFoundText="Aucun résultat"
                             [selectableGroup]="true"
                             [closeOnSelect]="false"
                             bindValue="id"
                             placeholder="Sélectionnez un/des établissement(s)"
                             [(ngModel)]="selectedEstablishments" (ngModelChange)="updateSelectedEstablishments()" [ngModelOptions]="{ standalone: true }">
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                      <input type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }" /> {{ item.name }}
                    </ng-template>
                  </ng-select>

                </div>
              </div>
              <!-- PRESET TIME -->
              <div class="col-6" *ngIf="establishments">
                <div class="form-group">
                  <label>Période</label>
                  <ng-select [items]="periods"
                             [multiple]="false"
                             bindLabel="name"
                             notFoundText="Aucun résultat"
                             [selectableGroup]="true"
                             [closeOnSelect]="true"
                             bindValue="value"
                             placeholder="Sélectionnez une période"
                             [(ngModel)]="period" (ngModelChange)="updatePeriod($event)" [ngModelOptions]="{ standalone: true }">
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">{{ item.name }}</ng-template>
                  </ng-select>

                </div>
              </div>

              <!-- CLEAR FILTERS -->
              <div class="col-12 d-flex align-items-center justify-content-center">
                <div class="btn-group">
                  <a class="btn btn-block btn-primary mb-4 text-white text-truncate" (click)="resetFilters()">
                    Réinitialiser les filtres
                  </a>
                </div>
              </div>

              <!-- ASBESTOS -->
              <div class="card-body">
                <div class="is-loading loader-height" *ngIf="loaderAsbestosDetection"></div>
                <div [hidden]="loaderAsbestosDetection" class="row">
                  <!-- CARDS -->
                  <div class="col-6">
                    <!-- TOTAL -->
                    <div class="card cursor">
                      <div class="card-body">

                        <div class="row align-items-center">
                          <div class="col">
                            <h6 class="card-title text-uppercase text-muted mb-2">
                              Nombre de repérages effectués
                            </h6>
                            <span class="h2 mb-0">{{ hasAsbestos }}</span>
                          </div>
                          <div class="col-auto">
                            <span class="h2 fe fe-bar-chart text-muted mb-0"></span>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <!-- TOTAL OK -->
                    <div class="card cursor">
                      <div class="card-body">

                        <div class="row align-items-center">
                          <div class="col">
                            <h6 class="card-title text-uppercase text-muted mb-2">
                              Nombre de matériaux et produits amiantés
                            </h6>
                            <span class="h2 mb-0">{{ nbLocalizations }}</span>
                          </div>
                          <div class="col-auto">
                            <span class="h2 fe fe-bar-chart text-muted mb-0"></span>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  <!-- GRAPH CARDS -->
                  <div class="col-12 d-flex align-items-center">
                    <div class="chart w-100">
                      <canvas id="asbestosDetectionChart" class="chart-canvas"></canvas>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-xl-6">
        <div class="card">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">
                <h4 class="card-header-title">
                  Répartition des mesures préconisées pour le patrimoine
                </h4>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="chart">
              <div class="is-loading" *ngIf="loaderAsbestosMeasure"></div>
              <canvas [hidden]="loaderAsbestosMeasure" id="asbestosMeasureChart" class="chart-canvas"></canvas>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-xl-6">
        <div class="card">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">
                <h4 class="card-header-title">
                  Répartition des travaux effectués par type
                </h4>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="chart">
              <div class="is-loading" *ngIf="loaderAsbestosWorksType"></div>
              <canvas [hidden]="loaderAsbestosWorksType" id="asbestosWorksTypeChart" class="chart-canvas"></canvas>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">
                <h4 class="card-header-title">
                  Nombre de matériaux et produits amiantés par établissements
                </h4>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="chart">
              <div class="is-loading" *ngIf="loaderAsbestosWorksType"></div>
              <canvas [hidden]="loaderAsbestosWorksType" id="asbestosEstablishmentsChart" class="chart-canvas"></canvas>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>

</div>
