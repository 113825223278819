<div class="preloader" *ngIf="authGuard.loader">
  <img src="../assets/img/picto_batiregistre.png" class="navbar-brand-img mx-auto" alt="Logo BatiRegistre">
</div>

<div class="overlay" *ngIf="authService.loader">
  <div class="is-loading loader-content"></div>
</div>

<router-outlet></router-outlet>
<notifier-container></notifier-container>
