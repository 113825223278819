import { Routes } from '@angular/router';

import { AllRvratComponent } from './all-rvrat/all-rvrat.component';
import { UpdateRvratComponent } from './update-rvrat/update-rvrat.component';
import { UploadRvratFileComponent } from './upload-rvrat-file/upload-rvrat-file.component';
import { ModalLogsComponent } from '../logs/modal-logs/modal-logs.component';
import { UpdateContentComponent } from '../contents/udpate-content/update-content.component';

export const rvratRoutes: Routes = [
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/worksheet/:worksheet_id/rvrat',
    component: AllRvratComponent,
    children: [
      {
        path: 'create',
        component: UpdateRvratComponent
      },
      {
        path: ':rvrat_id',
        component: UpdateRvratComponent
      },
      {
        path: ':rvrat_id/logs',
        component: ModalLogsComponent
      },
      {
        path: 'contents/:content_id',
        component: UpdateContentComponent
      }
    ]
  },
  {
    path: 'dashboard-registry/:registry_id/establishment/:establishment_id/worksheet/:worksheet_id/rvrat/:rvrat_id/files',
    component: AllRvratComponent,
    children: [
      {
        path: 'create',
        component: UploadRvratFileComponent
      },
      {
        path: ':file_id',
        component: UploadRvratFileComponent
      }
    ]
  },
];
