import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

import { ExportDownloadComponent } from './export-download.component';
import { ModalRightModule } from '../../core/modal-right/modal-right.module';
import { UtilsModule } from '../../core/utils/utils.module';
import { ExportDownloadRequestService } from '../../services/export-download.request.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    ModalRightModule,
    UtilsModule
  ],
  providers: [
    ExportDownloadRequestService
  ],
  declarations: [ExportDownloadComponent]
})

export class ExportDownloadModule {}
