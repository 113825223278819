import { HttpClient } from "@angular/common/http";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { NotifierService } from "angular-notifier";
import { Subject, Subscription } from "rxjs";
import { AuthService } from "src/app/core/auth/auth.service";
import { CustomNotifierService } from "src/app/services/custom.notifier.service";
import { EnterprisesRequestService } from "src/app/services/enterprises.request.service";
import { NotificationsRequestService } from "src/app/services/notifications.request.service";
import { UsersRequestService } from "src/app/services/users.request.service";
import { environment } from "../../../../environments/environment";

@Component({
  selector: "app-formations",
  templateUrl: "./formations.component.html",
  styleUrls: ["./formations.component.scss"],
})
export class FormationsComponent implements OnInit, OnDestroy {
  environment = environment;
  changeEnterprise: Subscription;

  requestInProgress = new Subject();
  requestCount = 0;

  currentRoute = "";
  pageName: string;
  previousPageName: string[] = [];
  previousPageRoute: string[] = [];

  view = "";

  loader = false;
  errors = {};

  users = [];
  enterprise: any;

  canCreate = false;
  canUpdate = false;
  canDelete = false;

  notifications = [];
  type = "";
  periodicAlerts = [];
  updateNotification = [];

  periodUsers = [
    { name: "Mensuelle", checked: false },
    { name: "Trimestrielle", checked: false },
    { name: "Semestrielle", checked: false },
    { name: "Annuelle", checked: false },
  ];

  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private authService: AuthService,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private usersServices: UsersRequestService,
    private enterpriseService: EnterprisesRequestService,
    private notificationService: NotificationsRequestService
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loader = this.requestCount > 0;
    });

    this.pageName = "Notification formations";
    this.view = "mine";
    this.previousPageName.push("Tableau de bord");
    this.previousPageRoute.push("/");

    this.type = this.activatedRoute.snapshot.url[2].path;
    const connectedAs = localStorage.getItem("connectedAs");

    if (connectedAs) {
      this.enterprise = JSON.parse(
        localStorage.getItem("connectedAsEnterprise")
      );
    } else {
      this.enterprise = JSON.parse(localStorage.getItem("enterprise"));
    }

    this.getUsers();

    this.changeEnterprise = this.authService.getEnterprise().subscribe(() => {
      this.getNotifications();
    });

    if (this.authService.loaded) {
      this.getNotifications();
    }

    this.periodUsers.forEach((period) => {
      const element = {
        tiles: "Recyclage Formations",
        users: [],
        period: period.name,
        enterprise_id: this.enterprise.id,
      };
      this.updateNotification.push(element);
    });
  }

  ngOnDestroy() {
    this.changeEnterprise.unsubscribe();

    const tooltips = document.getElementsByClassName(
      "tooltip"
    ) as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  update() {
    let request;
    this.errors = {};
    this.loader = true;
    this.updateValueForUpdate();

    request = this.notificationService.createAlerts(this.updateNotification);

    request.subscribe((response) => {
      if (response["success"]) {
        this.getNotifications();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response["errors"]) {
        this.errors = response["errors"];
        CustomNotifierService.getErrors(this.errors, this.notifier);
      }
      this.loader = false;
    });
  }

  sendEmailAlerts() {
    this.loader = true;
    this.notificationService.sendEmailAlerts().subscribe((response) => {
      if (response["success"]) {
        CustomNotifierService.getSuccessEmail(this.notifier);
      } else {
        CustomNotifierService.getErrors(response["errors"], this.notifier);
      }
      this.loader = false;
    });
  }

  updateValueForUpdate() {
    this.updateNotification.forEach((notification) => {
      notification.users = [];
    });
    this.users.forEach((user) => {
      if (user.monthly) {
        const found = this.updateNotification.find((element) => {
          return element.period === "Mensuelle";
        });
        if (found !== undefined) {
          found.users.push({
            id: user.id,
            name: user.name,
            category: user.category,
          });
        }
      }
      if (user.quarterly) {
        const found = this.updateNotification.find((element) => {
          return element.period === "Trimestrielle";
        });
        if (found !== undefined) {
          found.users.push({
            id: user.id,
            name: user.name,
            category: user.category,
          });
        }
      }
      if (user.semester) {
        const found = this.updateNotification.find((element) => {
          return element.period === "Semestrielle";
        });
        if (found !== undefined) {
          found.users.push({
            id: user.id,
            name: user.name,
            category: user.category,
          });
        }
      }
      if (user.annual) {
        const found = this.updateNotification.find((element) => {
          return element.period === "Annuelle";
        });
        if (found !== undefined) {
          found.users.push({
            id: user.id,
            name: user.name,
            category: user.category,
          });
        }
      }
    });
  }

  getAllChecked(item, period) {
    if (period.name === "Mensuelle") {
      let count = 0;
      item.forEach((value) => {
        if (value.monthly) {
          count += 1;
        }
      });
      return count === item.length;
    }
    if (period.name === "Trimestrielle") {
      let count = 0;
      item.forEach((value) => {
        if (value.quarterly) {
          count += 1;
        }
      });
      return count === item.length;
    }
    if (period.name === "Semestrielle") {
      let count = 0;
      item.forEach((value) => {
        if (value.semester) {
          count += 1;
        }
      });
      return count === item.length;
    }
    if (period.name === "Annuelle") {
      let count = 0;
      item.forEach((value) => {
        if (value.annual) {
          count += 1;
        }
      });
      return count === item.length;
    }
  }

  getNotifications() {
    this.periodicAlerts = [];
    this.requestInProgress.next(true);
    let request;
    request = this.notificationService.getNotifications(
      "",
      "",
      "",
      "",
      "",
      this.type
    );
    request.subscribe((response) => {
      if (response["success"]) {
        this.canCreate = response["canCreate"];
        this.canUpdate = response["canUpdate"];
        this.canDelete = response["canDelete"];
        this.notifications = response["notifications"];
        this.updateUserLists();
      } else if (response["errors"]) {
        CustomNotifierService.getErrors(response["errors"], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  getUsers() {
    let request;
    this.requestInProgress.next(true);
    request = this.notificationService.getEnterpriseUsers(
      "",
      "",
      "",
      "",
      "",
      this.enterprise.id
    );
    request.subscribe((response) => {
      if (response["success"]) {
        response["users"].forEach((element) => {
          const value = {
            id: element.id,
            name: element.full_name,
            is_super_user: element.last_user_enterprise.role.is_super_user,
            category: element.last_user_enterprise.role.name,
            can_receive_alert:
              element.last_user_enterprise.role.can_receive_alert,
            monthly: false,
            quarterly: false,
            semester: false,
            annual: false,
          };
          this.users.push(value);
        });
      }
      this.requestInProgress.next(false);
      this.updateUserLists();
    });
  }

  checkPeriod(item, period, checked) {
    item.forEach((value) => {
      if (period.name === "Mensuelle") {
        value.monthly = checked;
      }
      if (period.name === "Trimestrielle") {
        value.quarterly = checked;
      }
      if (period.name === "Semestrielle") {
        value.semester = checked;
      }
      if (period.name === "Annuelle") {
        value.annual = checked;
      }
    });
    period.checked = checked;
  }

  check(item, period) {
    item[period] = !item[period];
    if (period === "monthly") {
      item.quarterly = false;
      item.semester = false;
      item.anual = false;
    }
    if (period === "quarterly") {
      item.monthly = false;
      item.semester = false;
      item.anual = false;
    }
    if (period === "semester") {
      item.monthly = false;
      item.quarterly = false;
      item.anual = false;
    }
    if (period === "annual") {
      item.monthly = false;
      item.quarterly = false;
      item.semester = false;
    }
  }

  updateUserLists() {
    if (this.notifications.length && this.users.length) {
      this.notifications.forEach((notification) => {
        if (notification.users) {
          notification.users.forEach((user) => {
            const found = this.users.find((element) => {
              return element.id === user.id;
            });
            if (found !== undefined) {
              if (notification.period === "Mensuelle") {
                found.monthly = true;
              }
              if (notification.period === "Trimestrielle") {
                found.quarterly = true;
              }
              if (notification.period === "Semestrielle") {
                found.semester = true;
              }
              if (notification.period === "Annuelle") {
                found.annual = true;
              }
            }
          });
        }
      });
    }
  }
}
