<app-modal-right (eventOnClose)="closeModal()" #establishment>{{ bindModal(establishment) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">Import</h6>

        <h1 class="header-title">Import de masse des établissements</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <form class="mb-4" [formGroup]="establishmentForm" [hidden]="loaderContent">

      <div class="tab-content">
        <div class="tab-pane fade show active" id="firstPart" role="tabpanel" aria-labelledby="firstPart">

          <div class="card bg-light border">
            <div class="card-body">
              <p class="small text-muted mb-2">
                Pour importer vos établissements en masse, vous devez suivre les étapes suivantes :
              </p>
              <ol class="small text-muted mb-2">
                <li>Séléctionnez une configuration d'établissement avec le formulaire ci-dessous</li>
                <li>Exportez un fichier XLSX à remplir (logiciels supportés : Microsoft Excel, LibreOffice, OpenOffice)</li>
                <li>Importez le fichier ici (pas besoin de reconfigurer le formulaire, la configuration est stocké dans le fichier)</li>
              </ol>
            </div>
          </div>

          <!-- REGISTRIES -->
          <div class="form-group" *ngIf="registries && registries.length">
            <label for="listRegistry">Registres prévu dans l'offre</label>
            <ng-select [items]="registries"
                       [multiple]="true"
                       bindLabel="name"
                       bindValue="id"
                       groupBy="group"
                       notFoundText="Aucun résultat"
                       [selectableGroup]="true"
                       [selectableGroupAsModel]="false"
                       [closeOnSelect]="false"
                       placeholder="Sélectionnez un/ou plusieurs registre(s)"
                       (ngModelChange)="validate($event)"
                       [(ngModel)]="selectedRegistries" [ngModelOptions]="{ standalone: true }">
              <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                <input type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }"/> Sélectionnez tout
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="listRegistry" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }" /> {{ item.name }}
              </ng-template>
            </ng-select>
          </div>

          <!-- TYPE -->
          <div class="form-group">
            <label class="w-100" required>Type d'établissements</label>
            <div class="form-check form-check-inline" *ngFor="let typeEstablishment of typesEstablishments">
              <input *ngIf="typeEstablishment.id !== 5" class="form-check-input" type="radio" name="inlineRadioOptions" id="{{ typeEstablishment.name }}" value="{{ typeEstablishment.id }}" (change)="changeTypeEstablishment(typeEstablishment)" [checked]="establishment_type_id.value === typeEstablishment.id">
              <label *ngIf="typeEstablishment.id !== 5" class="form-check-label" for="{{ typeEstablishment.name }}">{{ typeEstablishment.name }}</label>
            </div>
          </div>

          <!-- ERP CATEGORY -->
          <div class="form-group" *ngIf="establishment_type_id.value === 1">
            <label for="ERPCategories" required>Catégorie ERP</label>
            <select class="form-control" id="ERPCategories" data-toggle="select" formControlName="establishment_subcategory_id"
                    (ngModelChange)="validate($event)"
                    [ngClass]="{ 'is-invalid': establishment_subcategory_id.invalid && (establishment_subcategory_id.dirty || establishment_subcategory_id.touched) }">
              <option [value]="null">Sélectionnez une catégorie</option>
              <optgroup label="1er groupe">
                <option [value]="1">1ère catégorie</option>
                <option [value]="2">2ème catégorie</option>
                <option [value]="3">3ème catégorie</option>
                <option [value]="4">4ème catégorie</option>
              </optgroup>
              <optgroup label="2ème groupe">
                <option [value]="5">5ème catégorie</option>
              </optgroup>
            </select>
          </div>

          <!-- BHC CATEGORY -->
          <div class="form-group" *ngIf="establishment_type_id.value === 3">
            <label for="BHCCategories" required>Catégorie BHC</label>
            <select class="form-control" id="BHCCategories" data-toggle="select" formControlName="establishment_category_id"
                    (ngModelChange)="validate($event)"
                    [ngClass]="{ 'is-invalid': establishment_category_id.invalid && (establishment_category_id.dirty || establishment_category_id.touched) }">
              <option [value]="null">Sélectionnez une catégorie</option>
              <ng-container *ngFor="let categoriesBHC of categoriesBHC">
                <option [value]="categoriesBHC.id">{{ categoriesBHC.name }}</option>
              </ng-container>
            </select>
          </div>

          <!-- ERP SUBTYPES -->
          <div class="form-group" *ngIf="establishment_type_id.value === 1">
            <label for="typeERP" required>Type d'ERP</label>
            <ng-select [items]="subTypesERP"
                       [multiple]="true"
                       bindLabel="name"
                       bindValue="id"
                       groupBy="group"
                       notFoundText="Aucun résultat"
                       [selectableGroup]="true"
                       [selectableGroupAsModel]="false"
                       [closeOnSelect]="false"
                       placeholder="Sélectionnez un type d'ERP"
                       [(ngModel)]="selectedERPSubTypes" (ngModelChange)="setSubType()" [ngModelOptions]="{ standalone: true }">
              <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                <input type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }"/> Sélectionnez tout
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="typeERP" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }" /> {{ item.name }}
              </ng-template>
            </ng-select>
          </div>

          <!-- IGH SUBTYPES -->
          <div class="form-group" *ngIf="establishment_type_id.value === 4">
            <label for="typeIGH" required>Type d'IGH</label>
            <ng-select [items]="subTypesIGH"
                       [multiple]="true"
                       bindLabel="name"
                       bindValue="id"
                       groupBy="group"
                       notFoundText="Aucun résultat"
                       [selectableGroup]="true"
                       [selectableGroupAsModel]="false"
                       [closeOnSelect]="false"
                       placeholder="Sélectionnez un type d'IGH"
                       [(ngModel)]="selectedIGHSubTypes" (ngModelChange)="setSubType()" [ngModelOptions]="{ standalone: true }">
              <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                <input type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }"/> Sélectionnez tout
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="typeIGH" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }" /> {{ item.name }}
              </ng-template>
            </ng-select>
          </div>

          <div class="form-group d-flex justify-content-start align-items-center">
            <div class="col pl-0">
              L’{{  children ? 'exploitant' : 'établissement'  }} est-il soumis au code du travail ?
            </div>
            <div class="custom-checkbox-toggle">
              <input type="checkbox" class="custom-control-input" id="isWorkCode" formControlName="is_work_code" [ngClass]="{ 'backgroundColor': is_work_code.value }">
              <label class="custom-control-label" [ngClass]="{ 'backgroundColor': is_work_code.value }" for="isWorkCode"></label>
            </div>
          </div>

          <!-- FILE UPLOAD -->
          <div class="form-group">
            <label for="inputFile">Import</label>

            <div class="dropzone dz-clickable" appDragDrop (dropHandler)="onFileChange($event)">
              <div class="dz-default dz-message" (click)="chooseFile()">
                <span>Déposer votre fichier ici pour télécharger</span>
              </div>
              <input id="inputFile" (change)="onFileChange($event)" type="file" accept=".xlsx" [hidden]="true">
            </div>
          </div>

          <div class="form-group card" *ngIf="file_base64.value">
            <div class="card-body">
              <div class="d-flex align-content-center justify-content-between">
                <h4 class="card-title mb-0 name align-self-center">
                  {{ fileName }}
                </h4>
              </div>
            </div>
          </div>

        </div>
      </div>
    </form>
  </div>

  <div modal-footer class="w-100">
    <button class="btn btn-primary w-100 mb-3" (click)="getTemplate()" [ngClass]="{ 'is-loading is-loading-sm': loader }" [disabled]="loader || is_invalid">
      Exporter le fichier
    </button>
    <button class="btn btn-primary w-100" (click)="setTemplate()" [ngClass]="{ 'is-loading is-loading-sm': loader }" [disabled]="loader || !file_base64.value">
      Importer mes établissements
    </button>
  </div>
</app-modal-right>
