<div class="main-content">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col">
        <div class="header mb-0">
          <div class="header-body no-border">
            <div class="row align-items-center">
              <div class="col">
                <h1 class="header-title">
                  Centre de notification
                </h1>

                <small class="form-text text-muted">
                  Liste des alertes avertissant si un contrôle n’a pas été ajouté alors que son échéance est passée
                </small>

                <app-breadcrumbs [currentPage]="pageName" [names]="previousPageName" [routes]="previousPageRoute"></app-breadcrumbs>
              </div>

              <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0">
                <a class="btn btn-primary d-block d-md-inline-block text-white" (click)="update()">
                  Mettre à jour
                </a>
              </div>

            </div>
          </div>
        </div>

        <div class="is-loading loader-content" *ngIf="loader"></div>

        <div class="tab-content" *ngIf="!loader && users.length && roles.length">
          <div class="tab-pane fade active show" id="tabPaneOne" role="tabpanel">
            <div class="row listAlias">
              <div class="col-12 d-flex flex-column">
                <div class="card flex-fill">
                  <div class="card-body">

                    <div class="table-responsive mb-4">
                      <table class="table table-striped mb-0">
                        <thead>
                        <tr>
                          <th>Rôles</th>
                          <th>Registres</th>
                          <th>Nombre de jour(s)<i class="fe fe-info ml-2 tooltipHtml" title="Nombre de jours avant de recevoir une notification si un contrôle n’a pas été ajouté alors que son échéance est passée"></i></th>
                          <th class="text-center">Envoyer un Email</th>
                          <th class="text-center">Notifier si un rapport n’a pas été ajouté</th>
                          <th class="text-center">Notifier le prestataire</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <th>Tous les rôles</th>
                          <td width="30%">
                            <div class="form-group">
                              <ng-select [items]="registriesRoles"
                                         [multiple]="true"
                                         bindLabel="name"
                                         [compareWith]="compareRegistries"
                                         groupBy="title"
                                         notFoundText="Aucun résultat"
                                         [selectableGroup]="true"
                                         [selectableGroupAsModel]="false"
                                         [closeOnSelect]="false"
                                         placeholder="Sélectionnez un/des registre(s)"
                                         [(ngModel)]="selectedAllRegistriesRole" [ngModelOptions]="{ standalone: true }" (change)="updateAllRoles()">
                                <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                  <input type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }"/> {{ item.title }}
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                  <div class="tooltipHtml" data-placement="bottom">
                                    <input type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }"/>
                                    {{ item.name }}
                                  </div>
                                </ng-template>
                              </ng-select>
                            </div>
                          </td>
                          <td class="td-center">
                            <input type="number" class="form-control" placeholder="Nbre de jour(s)" [(ngModel)]="all_role_nb_days" (change)="updateAllRoleDay()">
                          </td>
                          <td class="td-center">
                            <div class="d-flex justify-content-center">
                              <i class="fe fe-check-square fe-white" *ngIf="getAllCheckedEmail(roles); else notCheckedEmailRole" (click)="checkAllEmail(roles, false)"></i>
                              <ng-template #notCheckedEmailRole>
                                <i class="fe fe-square fe-white" (click)="checkAllEmail(roles, true)"></i>
                              </ng-template>
                            </div>
                          </td>
                          <td class="td-center">
                            <div class="d-flex justify-content-center">
                              <i class="fe fe-check-square fe-white" *ngIf="getAllCheckedReport(roles); else notCheckedReportRole" (click)="checkAllReport(roles, false)"></i>
                              <ng-template #notCheckedReportRole>
                                <i class="fe fe-square fe-white" (click)="checkAllReport(roles, true)"></i>
                              </ng-template>
                            </div>
                          </td>
                          <td class="td-center">
                            <div class="d-flex justify-content-center">
                              <i class="fe fe-check-square fe-white" *ngIf="getAllCheckedProvider(roles); else notCheckedProviderRole" (click)="checkAllProvider(roles, false)"></i>
                              <ng-template #notCheckedProviderRole>
                                <i class="fe fe-square fe-white" (click)="checkAllProvider(roles, true)"></i>
                              </ng-template>
                            </div>
                          </td>
                        </tr>
                        <tr *ngFor="let role of roles;let indexRole = index">
                          <th>
                            {{ role.name }}
                          </th>
                          <td  width="30%">
                            <div class="form-group">
                              <ng-select [items]="registriesRoles"
                                         [multiple]="true"
                                         bindLabel="name"
                                         [compareWith]="compareRegistries"
                                         groupBy="title"
                                         notFoundText="Aucun résultat"
                                         [selectableGroup]="true"
                                         [selectableGroupAsModel]="false"
                                         [closeOnSelect]="false"
                                         placeholder="Sélectionnez un/des registre(s)"
                                         [(ngModel)]="selectedRegistriesRole[indexRole]" [ngModelOptions]="{ standalone: true }" (change)="updateRole(indexRole)">
                                <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                  <input type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }"/> {{ item.title }}
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                  <div class="tooltipHtml" data-placement="bottom">
                                    <input type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }"/>
                                    {{ item.name }}
                                  </div>
                                </ng-template>
                              </ng-select>
                            </div>
                          </td>
                          <td class="td-center">
                            <input type="number" class="form-control" placeholder="Nbre de jour(s)" [(ngModel)]="role.nb_days">
                          </td>
                          <td class="td-center">
                            <div class="d-flex justify-content-center">
                              <i class="fe fe-white" (click)="checkEmail(role)"
                                 [ngClass]="{'fe-check-square': role.send_email, 'fe-square': !role.send_email}">
                              </i>
                            </div>
                          </td>
                          <td class="td-center">
                            <div class="d-flex justify-content-center">
                              <i class="fe fe-white" (click)="checkReport(role)"
                                 [ngClass]="{'fe-check-square': role.notify_report, 'fe-square': !role.notify_report}">
                              </i>
                            </div>
                          </td>
                          <td class="td-center">
                            <div class="d-flex justify-content-center">
                              <i class="fe fe-white" (click)="checkProvider(role)"
                                 [ngClass]="{'fe-check-square': role.notify_provider, 'fe-square': !role.notify_provider}">
                              </i>
                            </div>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>

                    <div class="table-responsive mb-4">
                      <table class="table table-striped mb-0">
                        <thead>
                        <tr>
                          <th>Utilisateurs</th>
                          <th>Registres
                            <span *ngIf="errors && errors['registries']" class="errors">
                                {{ getErrorMessage("registries") }}
                              </span>
                          </th>
                          <th>Nombre de jour(s)<i class="fe fe-info ml-2 tooltipHtml" title="Nombre de jours avant de recevoir une notification si un contrôle n’a pas été ajouté alors que son échéance est passée"></i></th>
                          <th class="text-center">Envoyer un Email</th>
                          <th class="text-center">Notifier si un rapport n’a pas été ajouté</th>
                          <th class="text-center">Notifier le prestataire</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <th>Tous les utilisateurs</th>
                          <td width="30%">
                            <div class="form-group">
                              <ng-select [items]="registriesUsers"
                                         [multiple]="true"
                                         bindLabel="name"
                                         [compareWith]="compareRegistries"
                                         groupBy="title"
                                         notFoundText="Aucun résultat"
                                         [selectableGroup]="true"
                                         [selectableGroupAsModel]="false"
                                         [closeOnSelect]="false"
                                         placeholder="Sélectionnez un/des registre(s)"
                                         [(ngModel)]="selectedAllRegistriesUser" [ngModelOptions]="{ standalone: true }" (change)="updateAllUser()">
                                <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                  <input type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }"/> {{ item.title }}
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                  <div class="tooltipHtml" data-placement="bottom">
                                    <input type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }"/>
                                    {{ item.name }}
                                  </div>
                                </ng-template>
                              </ng-select>
                            </div>
                          </td>
                          <td class="td-center">
                            <input type="number" class="form-control" placeholder="Nbre de jour(s)" [(ngModel)]="all_user_nb_days" (change)="updateAllUserDay()">
                          </td>
                          <td class="td-center">
                            <div class="d-flex justify-content-center">
                              <i class="fe fe-check-square fe-white" *ngIf="getAllCheckedEmail(users); else notCheckedEmailUser" (click)="checkAllEmail(users, false)"></i>
                              <ng-template #notCheckedEmailUser>
                                <i class="fe fe-square fe-white" (click)="checkAllEmail(users, true)"></i>
                              </ng-template>
                            </div>
                          </td>
                          <td class="td-center">
                            <div class="d-flex justify-content-center">
                              <i class="fe fe-check-square fe-white" *ngIf="getAllCheckedReport(users); else notCheckedReportUser" (click)="checkAllReport(users, false)"></i>
                              <ng-template #notCheckedReportUser>
                                <i class="fe fe-square fe-white" (click)="checkAllReport(users, true)"></i>
                              </ng-template>
                            </div>
                          </td>
                          <td class="td-center">
                            <div class="d-flex justify-content-center">
                              <i class="fe fe-check-square fe-white" *ngIf="getAllCheckedProvider(users); else notCheckedProviderUser" (click)="checkAllProvider(users, false)"></i>
                              <ng-template #notCheckedProviderUser>
                                <i class="fe fe-square fe-white" (click)="checkAllProvider(users, true)"></i>
                              </ng-template>
                            </div>
                          </td>
                        </tr>
                        <tr *ngFor="let user of users; let indexUser = index">
                          <th>
                            {{ user.name + ' - ' + user.category }}
                          </th>
                          <td  width="30%">
                            <div class="form-group">
                              <ng-select [items]="registriesUsers"
                                         [multiple]="true"
                                         bindLabel="name"
                                         [compareWith]="compareRegistries"
                                         groupBy="title"
                                         notFoundText="Aucun résultat"
                                         [selectableGroup]="true"
                                         [selectableGroupAsModel]="false"
                                         [closeOnSelect]="false"
                                         placeholder="Sélectionnez un/des registre(s)"
                                         [(ngModel)]="selectedRegistriesUser[indexUser]" [ngModelOptions]="{ standalone: true }" (change)="updateUser(indexUser)">
                                <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                  <input type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }"/> {{ item.title }}
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                  <div class="tooltipHtml" data-placement="bottom">
                                    <input type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }"/>
                                    {{ item.name }}
                                  </div>
                                </ng-template>
                              </ng-select>
                            </div>
                          </td>
                          <td class="td-center">
                            <input type="number" class="form-control" placeholder="Nbre de jour(s)" [(ngModel)]="user.nb_days">
                          </td>
                          <td class="td-center">
                            <div class="d-flex justify-content-center">
                              <i class="fe fe-white" (click)="checkEmail(user)"
                                 [ngClass]="{'fe-check-square': user.send_email, 'fe-square': !user.send_email}">
                              </i>
                            </div>
                          </td>
                          <td class="td-center">
                            <div class="d-flex justify-content-center">
                              <i class="fe fe-white" (click)="checkReport(user)"
                                 [ngClass]="{'fe-check-square': user.notify_report, 'fe-square': !user.notify_report}">
                              </i>
                            </div>
                          </td>
                          <td class="td-center">
                            <div class="d-flex justify-content-center">
                              <i class="fe fe-white" (click)="checkProvider(user)"
                                 [ngClass]="{'fe-check-square': user.notify_provider, 'fe-square': !user.notify_provider}">
                              </i>
                            </div>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<router-outlet></router-outlet>
