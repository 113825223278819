import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NotifierService } from "angular-notifier";
import { CustomNotifierService } from "src/app/services/custom.notifier.service";
import { EmployeesRequestService } from "src/app/services/employees.request.service";
import { EstablishmentsRequestService } from "src/app/services/establishments.request.service";
import { TrainingsRequestService } from "src/app/services/trainings.request.service";
import DateTraining from "src/shared/interfaces/date_training";
import Employees from "src/shared/interfaces/employees";
import Establishment from "src/shared/interfaces/establishment";
import Training from "src/shared/interfaces/training";
import { GetAllTrainingsComponent } from "../get-all-training/get-all-training.component";

@Component({
  selector: "app-affectation-employees-training",
  templateUrl: "./affectation-employees-training.component.html",
  styleUrls: ["./affectation-employees-training.component.scss"],
})
export class AffectationEmployeesTrainingComponent
  implements OnInit, OnDestroy
{
  modalTraining = null;

  canCreate = false;
  canUpdate = false;

  checkedAll = false;
  checkedAllEmployees = false;

  search = "";
  sortDate = "date";
  order = "desc";
  sort = "EmployeeTrainings.firstname";
  limit = 12;
  page = 1;

  count = 0;
  pages = 1;

  errors = {};

  isNew = true;

  timeout: any;

  loaderContent = false;
  loader = false;
  disableSelect = true;
  disableSelectDate = true;

  establishments: Establishment[] = [];
  trainings: Training[] = [];
  dateTrainings: DateTraining[] = [];
  employees: Employees[] = [];

  selectedEmployees = [];

  sessionForm: FormGroup;
  id = new FormControl(null);
  establishment_id = new FormControl(null, Validators.required);
  training_id = new FormControl(null, Validators.required);
  date_training_id = new FormControl(null, Validators.required);
  employees_id = new FormControl(null, Validators.required);

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private allTrainings: GetAllTrainingsComponent,
    private establishmentsService: EstablishmentsRequestService,
    private trainingsService: TrainingsRequestService,
    private employeeTrainingsService: EmployeesRequestService
  ) {}

  ngOnInit() {
    this.sessionForm = this.formBuilder.group({
      id: this.id,
      establishment_id: this.establishment_id,
      training_id: this.training_id,
      date_training_id: this.date_training_id,
      employees_id: this.employees_id,
    });

    this.getEstablishments();
  }

  ngAfterViewInit() {
    this.modalTraining.open();
  }

  ngOnDestroy() {
    this.modalTraining.close();
  }

  getEstablishments() {
    let request;
    this.errors = {};
    this.loader = true;

    request = this.establishmentsService.getMyEstablishments(
      this.search,
      "Establishments.name",
      this.order,
      this.limit,
      this.page
    );

    request.subscribe((response) => {
      if (response["success"]) {
        this.establishments = response["establishments"];
      } else if (response["errors"]) {
        CustomNotifierService.getErrors(response["errors"], this.notifier);
      }
      this.loader = false;
    });
  }

  getTrainings() {
    let request;
    this.errors = {};
    this.loader = true;
    if (this.establishment_id.value) {
      this.training_id.patchValue(null);
      this.trainings = [];
      request = this.trainingsService.getAllTrainingsWithDateTrainings(
        this.establishment_id.value
      );

      request.subscribe((response) => {
        if (response["success"]) {
          this.trainings = response["trainings"];
          this.disableSelect = false;
        } else if (response["errors"]) {
          CustomNotifierService.getErrors(response["errors"], this.notifier);
        }
        this.getEmployees();
        this.loader = false;
      });
    }
  }

  getDateTrainings() {
    let request;
    this.errors = {};
    this.loader = true;
    if (this.training_id.value) {
      this.date_training_id.patchValue(null);
      this.dateTrainings = [];
      request = this.trainingsService.getDateTrainings(
        this.search,
        this.sortDate,
        this.order,
        this.limit,
        this.page,
        this.training_id.value
      );

      request.subscribe((response) => {
        if (response["success"]) {
          this.dateTrainings = response["date_trainings"].filter((item) => {
            var varDate = new Date(item.date);
            var today = new Date();
            return varDate >= today;
          });
          this.disableSelectDate = false;
        } else if (response["errors"]) {
          CustomNotifierService.getErrors(response["errors"], this.notifier);
        }
        this.loader = false;
      });
    }
  }

  getEmployees() {
    let request;
    this.errors = {};
    this.loader = true;

    if (this.establishment_id.value) {
      request =
        this.employeeTrainingsService.getEmployeesTrainingByEstablishments(
          this.search,
          this.sort,
          this.order,
          this.limit,
          this.page,
          this.establishment_id.value
        );
      request.subscribe((response) => {
        if (response["success"]) {
          this.employees = response["employees_training"];
        } else if (response["errors"]) {
          CustomNotifierService.getErrors(response["errors"], this.notifier);
        }
        this.loader = false;
      });
    }
  }

  selectedItems(e, id) {
    if (e.target.checked) {
      this.selectedEmployees.push(id);
    } else {
      this.selectedEmployees = this.selectedEmployees.filter((m) => m != id);
    }
    this.sessionForm.patchValue({
      employees_id: this.selectedEmployees,
    });
  }

  selectedAllItems(e) {
    if (e.target.checked) {
      this.employees.map((item) => {
        this.selectedEmployees.push(item.id);
        this.checkedAll = true;
      });
    } else {
      this.selectedEmployees = [];
      this.checkedAll = false;
    }
    this.sessionForm.patchValue({
      employees_id: this.selectedEmployees,
    });
  }

  searchData() {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.page = 1;
      this.refreshData();
    }, 500);
  }

  filter(property) {
    if (this.sort === property) {
      if (this.order === "asc") {
        this.order = "desc";
      } else {
        this.order = "asc";
      }
    } else {
      this.sort = property;
      this.order = "asc";
    }

    this.getEmployees();
  }

  createSession() {
    let request;
    this.errors = {};
    this.loader = true;

    request = this.trainingsService.createDateTrainingEmployees(
      this.sessionForm.value
    );

    request.subscribe((response) => {
      if (response["success"]) {
        this.modalTraining.close();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response["errors"]) {
        this.errors = response["errors"];
        CustomNotifierService.getErrors(response["errors"], this.notifier);
      }
      this.loader = false;
    });
  }

  updateSession() {}

  dispatchPagination(data) {
    if (data.action === "total_elements") {
      this.refreshPage(data.value);
    } else if (data.action === "change_page") {
      this.changePage(data.value);
    }
  }

  refreshData(sort = this.sort, order = this.order, page = this.page) {
    this.sort = sort;
    this.order = order;
    this.page = page;
    this.getEmployees();
  }

  refreshPage(limit) {
    this.limit = limit;
    this.page = 1;
    this.refreshData();
  }

  changePage(page) {
    if (page >= 1 && page <= this.pages) {
      this.page = page;
      this.getEmployees();
    }
  }

  bindModal(modal) {
    this.modalTraining = modal;
  }

  closeModal() {
    this.router
      .navigate([this.activatedRoute.snapshot.parent.url.join("/")])
      .then(() => {
        this.allTrainings.refreshData();
      });
  }
}
