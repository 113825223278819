<div class="main-content">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col">
        <div class="header mb-0">
          <div class="header-body no-border">
            <div class="row align-items-center">
              <div class="col">
                <h1 class="header-title">
                  Travaux
                </h1>

                <small class="form-text text-muted">
                  Liste des travaux
                </small>

                <app-breadcrumbs [currentPage]="pageName" [names]="previousPageName" [routes]="previousPageRoute"></app-breadcrumbs>
              </div>

              <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0" *ngIf="canCreate">
                <a class="btn btn-primary d-block d-md-inline-block" [routerLink]="[currentRoute + 'create']">
                  Ajouter des travaux
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col">
            <app-searchbar [(ngModel)]="search" (keyup)="searchData()" ngDefaultControl></app-searchbar>
          </div>

          <div class="col-auto">
            <button class="btn btn-lg btn-white tooltipHtml" aria-label="Afficher les filtres disponibles"  data-toggle="dropdown" data-placement="bottom" title="Filtre">
              <span class="fe fe-filter"></span>
            </button>

            <span class="dropdown-menu dropdown-menu-right">
              <a class="dropdown-item href" (click)="refreshData('ReportWorksheets.year', 'asc', 1)">
                Année
              </a>
            </span>
          </div>
        </div>

        <div class="is-loading loader-content" *ngIf="loader"></div>

        <div class="tab-content" *ngIf="!loader && (works.length)">
          <div class="tab-pane fade active show" id="tabPaneOne" role="tabpanel">
            <div class="row listAlias">
              <div class="col-12 d-flex flex-column">
                <div class="card flex-fill">
                  <div class="card-body">
                    <div class="table-responsive">
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th class="href" (click)="filter('Works.year')">
                              <a class="text-muted">Année</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'Works.year' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'Works.year' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'Works.year'"></span>
                            </th>
                            <th class="href" (click)="filter('Works.work')">
                              <a class="text-muted">Travaux</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'Works.work' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'Works.work' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'Works.work'"></span>
                            </th>
                            <th class="href" (click)="filter('Works.state')">
                              <a class="text-muted">État</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'Works.state' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'Works.state' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'Works.state'"></span>
                            </th>
                            <th class="href" (click)="filter('Works.comment')">
                              <a class="text-muted">Commentaires</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'Works.comment' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'Works.comment' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'Works.comment'"></span>
                            </th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="dropdown" *ngFor="let work of works">
                            <td>
                              <a class="href" [routerLink]="[currentRoute + work.id]">
                                {{ work.year }}
                              </a>
                            </td>
                            <td>{{ work.work }}</td>
                            <td><span class="label" [ngClass]="{'label-danger': !work.state, 'label-success': work.state }">{{ work.state == 0 ? 'Non réalisé' : work.state == 1 ? 'Réalisé' : 'Annulé' }}</span></td>
                            <td>{{ work.comment }}</td>
                            <td class="d-flex flex-row-reverse">
                              <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fe fe-more-vertical"></i>
                              </a>

                              <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item href" [routerLink]="[currentRoute + work.id]">
                                  {{ canUpdate ? 'Éditer la fiche travaux' : 'Consulter la fiche travaux' }}
                                </a>

                                <a class="dropdown-item href" (click)="modalConfirmData.open(work.id)" *ngIf="canDelete">
                                  Supprimer la fiche travaux
                                </a>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <app-pagination (returnEvent)="dispatchPagination($event)"
                                    [count_elements]="count"
                                    [current_page]="page"
                                    [elements_length]="works.length"
                                    [limit]="limit"
                                    [total_pages]="pages"
                                    ngDefaultControl>
                    </app-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="loader-content" *ngIf="!loader && (!works.length)">
          <p>Aucun résultat</p>
        </div>
      </div>
    </div>
  </div>
</div>

<app-confirm-modal [type]="'delete_data'" #confirmData>{{ bindModalConfirmData(confirmData) }}</app-confirm-modal>

<router-outlet></router-outlet>
