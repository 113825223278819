import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AllTrainingsFilesComponent } from './all-trainings-files.component';
import { BreadcrumbsModule } from '../../../core/breadcrumbs/breadcrumbs.module';
import { ConfirmModalModule } from '../../../core/confirm-modal/confirm-modal.module';
import { ModalRightModule } from '../../../core/modal-right/modal-right.module';
import { SearchbarModule } from '../../../core/searchbar/searchbar.module';
import { PaginationModule } from '../../../core/pagination/pagination.module';
import { PdfViewerModule } from '../../../core/pdf-viewer/pdf-viewer.module';

@NgModule({
  declarations: [AllTrainingsFilesComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    BreadcrumbsModule,
    ConfirmModalModule,
    ModalRightModule,
    SearchbarModule,
    PaginationModule,
    PdfViewerModule
  ]
})
export class AllTrainingsFilesModule { }
