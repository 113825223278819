import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { HttpClient } from '@angular/common/http';
import { RequestService } from './request.service';

@Injectable()
export class SanitaryInstallationsRequestService extends RequestService {
  constructor(
    protected http: HttpClient,
    protected notifier: NotifierService
  ) {
    super(http, notifier);
  }

  /// GET ALL
  public getAllSanitaryInstallations(search, sort, order, limit, page, registry_id, establishment_id) {
    return this.get( 'registry/' + registry_id + '/establishment/' + establishment_id + '/sanitary-installations?' +
      'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  /// GET ONE
  public getSanitaryInstallation(registry_id, establishment_id, sanitaryInstallationId) {
    return this.get('registry/' + registry_id + '/establishment/' + establishment_id + '/sanitary-installation/' + sanitaryInstallationId);
  }
  /// CREATE
  public createSanitaryInstallation(registry_id, establishment_id, sanitaryInstallation) {
    return this.post('registry/' + registry_id + '/establishment/' + establishment_id + '/sanitary-installation', { sanitaryInstallation });
  }
  /// UPDATE
  public updateSanitaryInstallation(registry_id, establishment_id, sanitaryInstallationId, sanitaryInstallation) {
    return this.put('registry/' + registry_id + '/establishment/' + establishment_id + '/sanitary-installation/' + sanitaryInstallationId,
      { sanitaryInstallation });
  }
  /// DELETE
  public deleteSanitaryInstallation(registry_id, establishment_id, sanitaryInstallationId) {
    return this.delete('registry/' + registry_id + '/establishment/' + establishment_id +
        '/sanitary-installation/' + sanitaryInstallationId);
  }
  /// FILE
  public getFiles(search, sort, order, limit, page, registry_id, establishment_id, sanitaryInstallationId) {
    return this.get('registry/' + registry_id + '/establishment/' + establishment_id + '/sanitary-installation/' + sanitaryInstallationId +
        '/files?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getFile(registry_id, establishment_id, sanitaryInstallationId, file_id) {
    return this.get('registry/' + registry_id + '/establishment/' + establishment_id + '/sanitary-installation/' + sanitaryInstallationId +
        '/files/' + file_id);
  }
  public createFile(registry_id, establishment_id, sanitaryInstallationId, files) {
    return this.post('registry/' + registry_id + '/establishment/' + establishment_id +
        '/sanitary-installation/' + sanitaryInstallationId + '/files',
        { files: files });
  }
  public updateFile(registry_id, establishment_id, sanitaryInstallationId, file_id, file) {
    return this.put('registry/' + registry_id + '/establishment/' + establishment_id + '/sanitary-installation/' + sanitaryInstallationId +
        '/files/' + file_id, { file: file });
  }
  public deleteFile(registry_id, establishment_id, sanitaryInstallationId, file_id) {
    return this.delete('registry/' + registry_id + '/establishment/' + establishment_id +
        '/sanitary-installation/' + sanitaryInstallationId + '/files/' + file_id);
  }
  public downloadFile(registry_id, establishment_id, sanitaryInstallationId, file_id) {
    return this.get('registry/' + registry_id + '/establishment/' + establishment_id + '/sanitary-installation/' + sanitaryInstallationId +
        '/files/' + file_id + '/download', { responseType: 'arraybuffer' });
  }
}
