<app-modal-right (eventOnClose)="closeModal()" #intervention>{{ bindModal(intervention) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">{{ isNew ? 'Création' : canUpdate ? 'Mise à jour' : 'Consultation' }}</h6>

        <h1 class="header-title">Bon d'intervention</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <form class="mb-4" [formGroup]="interventionForm" [hidden]="loaderContent">
      <ul class="nav nav-tabs" role="tablist">
        <li class="nav-item col-6">
          <a class="nav-link href" [ngClass]="{ 'active': activeTab === 'firstPart'}" (click)="changeTabs('firstPart')">Informations</a>
        </li>
        <li class="nav-item col-6">
          <a class="nav-link href" [ngClass]="{ 'active': activeTab === 'secondPart'}" (click)="changeTabs('secondPart')">Ajouts de documents</a>
        </li>
      </ul>

      <div class="tab-content">
        <div class="tab-pane fade" id="firstPart" role="tabpanel" aria-labelledby="firstPart" [ngClass]="{ 'show active': activeTab === 'firstPart'}">
          <div class="form-group" *ngIf="!loaderContent">
            <label for="users" required>Nom du destinataire du bon d'intervention</label>
            <ng-select [items]="enterprises"
                       [multiple]="false"
                       bindLabel="name"
                       notFoundText="Aucun résultat"
                       [selectableGroup]="true"
                       [closeOnSelect]="true"
                       bindValue="id"
                       placeholder="Sélectionnez un intervenant"
                       [(ngModel)]="user" (ngModelChange)="changeUser()" [ngModelOptions]="{ standalone: true }" [disabled]="!isNew && !canUpdate">
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">{{ item.name }}</ng-template>
            </ng-select>
          </div>

          <div class="form-group">
            <label for="validationDeadline" required>Échéance</label>
            <input type="date" formControlName="deadline" class="form-control" id="validationDeadline" placeholder="Échéance" data-toggle="flatpickr"
                   [ngClass]="{ 'is-invalid': deadline.invalid && (deadline.dirty || deadline.touched) }">
            <div *ngIf="deadline.invalid && (deadline.dirty || deadline.touched)" class="invalid-feedback">
              {{ getErrorMessage("deadline") }}
            </div>
          </div>

          <div class="form-group">
            <label for="validationComments">Commentaires éventuels</label>
            <textarea formControlName="comments" class="form-control" id="validationComments" placeholder="Commentaires éventuels" rows="5"></textarea>
            <div *ngIf="comments.invalid && (comments.dirty || comments.touched)" class="invalid-feedback">
              {{ getErrorMessage("comments") }}
            </div>
          </div>

          <div class="form-group" *ngIf="!loaderContent">
            <label for="observations" required>Observations</label>
            <ng-select [items]="items"
                       [multiple]="true"
                       bindLabel="name"
                       groupBy="group"
                       notFoundText="Aucun résultat"
                       [selectableGroup]="true"
                       [selectableGroupAsModel]="false"
                       [closeOnSelect]="false"
                       placeholder="Sélectionnez une observation"
                       [(ngModel)]="selectedItems" (ngModelChange)="changeItems()" [ngModelOptions]="{ standalone: true }" [disabled]="!isNew && !canUpdate">
              <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                <input type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }"/> Sélectionnez tout
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="observations" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }" /> {{ item.name }}
              </ng-template>
            </ng-select>
          </div>

          <div class="form-group d-flex justify-content-start align-items-center">
            <div class="col pl-0">
              Voulez-vous annexer le rapport avec le bon d'intervention ?
            </div>
            <div class="custom-checkbox-toggle">
              <input type="checkbox" class="custom-control-input" id="mergeReport" formControlName="merge_report" [ngClass]="{ 'backgroundColor': merge_report.value }">
              <label class="custom-control-label" [ngClass]="{ 'backgroundColor': merge_report.value }" for="mergeReport"></label>
            </div>
          </div>

          <div class="form-group d-flex justify-content-start align-items-center">
            <div class="col pl-0">
              Envoyer le bon d'intervention par e-mail
            </div>
            <div class="custom-checkbox-toggle">
              <input type="checkbox" class="custom-control-input" id="sendEmail" formControlName="send_email" [ngClass]="{ 'backgroundColor': send_email.value }" (change)="changeEmailValidator()">
              <label class="custom-control-label" [ngClass]="{ 'backgroundColor': send_email.value }" for="sendEmail"></label>
            </div>
          </div>

          <div class="form-group" *ngIf="send_email.value">
            <label for="validationEmail" required>Email</label>
            <input type="text" formControlName="email" class="form-control" id="validationEmail" placeholder="Email"
                   [ngClass]="{ 'is-invalid': email.invalid && (email.dirty || email.touched) }">
            <div *ngIf="email.invalid && (email.dirty || email.touched)" class="invalid-feedback">
              {{ getErrorMessage("email") }}
            </div>
          </div>
        </div>

        <div class="tab-content">
          <div class="tab-pane fade" id="secondPart" role="tabpanel" aria-labelledby="secondPart" [ngClass]="{ 'show active': activeTab === 'secondPart'}">
            <div class="form-group" *ngIf="isNew || canUpdate">
              <label for="inputFile">Documents</label>

              <div class="dropzone dropzone-multiple dz-clickable" appDragDrop (dropHandler)="onFileChange($event)">
                <div class="dz-default dz-message" (click)="chooseFile()">
                  <span>Déposer vos fichiers ici pour télécharger</span>
                </div>
                <input id="inputFile" (change)="onFileChange($event)" type="file" [hidden]="true" multiple>
              </div>
            </div>

            <div class="card" *ngFor="let file of files; let index = index">
              <div class="card-body">
                <div class="d-flex align-content-center justify-content-between">
                  <h4 class="card-title mb-0 name align-self-center">
                    {{ file.name + '.' + file.extension }}
                  </h4>

                  <a class="href tooltipHtml" data-placement="bottom" title="Supprimer" (click)="removeFile(index)" *ngIf="isNew || canUpdate">
                    <i class="fe fe-trash"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div modal-footer class="w-100 d-flex justify-content-between">
    <button class="btn btn-primary w-30" [disabled]="activeTab === 'firstPart'" (click)="prevTabs()">
      Précédent
    </button>

    <button class="btn btn-primary w-30" *ngIf="activeTab === 'firstPart'" (click)="nextTabs()">
      Suivant
    </button>

    <button class="btn btn-primary w-30" *ngIf="activeTab === 'secondPart'" (click)="isNew ? createIntervention() : updateIntervention()"
            [ngClass]="{ 'is-loading is-loading-sm': loader }" [disabled]="loader || interventionForm.invalid || (!isNew && !canUpdate)">
      {{ isNew ? 'Créer' : 'Mettre à jour' }}
    </button>
  </div>
</app-modal-right>
