import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { Subject, Subscription } from 'rxjs';

import { AuthService } from '../../../core/auth/auth.service';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { FaqRequestService } from '../../../services/faq.request.service';

import Faq from '../../../../shared/interfaces/faq';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-all-faq',
  templateUrl: './all-faq.component.html',
  styleUrls: ['./all-faq.component.scss']
})
export class AllFaqComponent implements OnInit, AfterViewInit, OnDestroy {
  changeEnterprise: Subscription;

  requestInProgress = new Subject();
  requestCount = 0;

  canCreate = false;
  canUpdate = false;
  canDelete = false;

  faqs: Faq[] = [];

  pageName: string;
  previousPageName: string[] = [];
  previousPageRoute: string[] = [];

  count = 0;
  pages = 1;

  search = '';
  sort = 'Faqs.created';
  order = 'asc';
  limit = 12;
  page = 1;

  timeout: any;

  loader = false;
  viewPublic = false;
  viewAdmin = false;

  modalConfirmData = null;

  constructor(
    public sanitizer: DomSanitizer,
    private notifier: NotifierService,
    private authService: AuthService,
    private faqService: FaqRequestService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loader = this.requestCount > 0;
    });

    this.changeEnterprise = this.authService.getEnterprise().subscribe(() => {
      this.getFaqs();
    });

    this.pageName = 'Faq';
    this.previousPageName.push('Tableau de bord');
    this.previousPageRoute.push('/administration');

    if (this.authService.loaded) {
      this.getFaqs();
    }

    this.defineView();
  }

  ngAfterViewInit() {
    this.modalConfirmData.eventOnClose.subscribe(event => {
      if (event) {
        this.requestInProgress.next(true);
        this.faqService.deleteFaq(event).subscribe(response => {
          if (response['success']) {
            this.refreshData();
            CustomNotifierService.getSuccessDelete(this.notifier);
          } else if (response['errors']) {
            CustomNotifierService.getErrors(response['errors'], this.notifier);
          }
          this.requestInProgress.next(false);
        });
      }
    });
  }

  ngOnDestroy() {
    this.changeEnterprise.unsubscribe();

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  defineView() {
    const snapshot = this.activatedRoute.snapshot;

    if (snapshot.url[1] && snapshot.url[1].path === 'public') {
      this.viewPublic = true;
    } else {
      this.viewAdmin = true;
    }
  }

  searchData() {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.page = 1;
      this.refreshData();
    }, 500);
  }

  refreshData(sort = this.sort, order = this.order, page = this.page) {
    this.sort = sort;
    this.order = order;
    this.page = page;
    this.getFaqs();
  }

  getFaqs() {
    this.requestInProgress.next(true);

    if (this.viewPublic) {
      this.limit = 0;
      this.page = 1;
    }

    this.faqService.getAllFaq(this.search, this.sort, this.order, this.limit, this.page).subscribe(response => {
      if (response['success']) {
        this.canCreate = response['canCreate'];
        this.canUpdate = response['canUpdate'];
        this.canDelete = response['canDelete'];

        this.faqs = response['faqs'];
        this.faqs.forEach(faq => {
          faq.answer = <string>this.sanitizer.bypassSecurityTrustHtml(faq.answer);
        });

        this.count = response['count'];
        this.pages = response['pages'];
      } else if (response['errors']) {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  dispatchPagination(data) {
    if (data.action === 'total_elements') {
      this.refreshPage(data.value);
    } else if (data.action === 'change_page') {
      this.changePage(data.value);
    }
  }

  refreshPage(limit) {
    this.limit = limit;
    this.page = 1;
    this.refreshData();
  }

  changePage(page) {
    if (page >= 1 && page <= this.pages) {
      this.page = page;
      this.getFaqs();
    }
  }

  bindModalConfirmData(modal) {
    this.modalConfirmData = modal;
  }
}
