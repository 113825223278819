import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UpdateSanitaryTemperatureFrameModule } from './update-sanitary-temperature-frame/update-sanitary-temperature-frame.module';
import { AllSanitaryTemperatureFramesModule } from './all-sanitary-temperature-frames/all-sanitary-temperature-frames.module';

import { SanitaryTemperatureFramesRequestService } from '../../../services/sanitary-temperature-frames.request.service';

@NgModule({
  imports: [
    CommonModule,
    AllSanitaryTemperatureFramesModule,
    UpdateSanitaryTemperatureFrameModule,
  ],
  providers: [
      SanitaryTemperatureFramesRequestService
  ]
})
export class SanitaryTemperatureFramesModule {}
