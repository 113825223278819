<div class="main-content">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col">
        <div class="header mb-0">
          <div class="header-body no-border">
            <div class="row align-items-center">
              <div class="col">
                <h1 class="header-title">
                  Fiches de suivi
                </h1>

                <small class="form-text text-muted">
                  Liste des fiches de suivi
                </small>

                <app-breadcrumbs [currentPage]="pageName" [names]="previousPageName" [routes]="previousPageRoute"></app-breadcrumbs>
              </div>

            </div>
          </div>
        </div>

        <div class="is-loading loader-content" *ngIf="loader"></div>

        <div class="tab-content" *ngIf="!loader">
          <div class="card">
            <div class="card-body">

              <!-- WEEKLY -->
              <div class="row">
                <div class="col">
                  <h2 class="col">Fiche de suivi maintenance Hebdomadaire</h2>
                </div>

                <div class="col-auto">
                  <a class="btn btn-primary d-block d-md-inline-block text-white tooltipHtml" title="Ajouter une fiche de suivi" [routerLink]="['weekly']">
                    <span class="fe fe-plus"></span>
                  </a>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                  <tr>
                    <th>Label</th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let item of weekly">
                    <td>{{ item.label }}</td>
                    <td class="d-flex flex-row-reverse">
                      <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fe fe-more-vertical"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item href" [routerLink]="['weekly/' + item.id]" *ngIf="!item.enterprise_id || !canUpdate">
                          Consulter la fiche de suivi
                        </a>
                        <a class="dropdown-item href" [routerLink]="['weekly/' + item.id]" *ngIf="item.enterprise_id && canUpdate">
                          Editer la fiche de suivi
                        </a>
                        <a class="dropdown-item href" (click)="modalConfirmData.open(item.id)" *ngIf="item.enterprise_id && canUpdate">
                          Supprimer la fiche de suivi
                        </a>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>

              <!-- MONTHLY -->
              <div class="row">
                <div class="col">
                  <h2 class="col">Fiche de suivi maintenance Mensuelle</h2>
                </div>

                <div class="col-auto">
                  <a class="btn btn-primary d-block d-md-inline-block text-white tooltipHtml" title="Ajouter une fiche de suivi" [routerLink]="['monthly']">
                    <span class="fe fe-plus"></span>
                  </a>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                  <tr>
                    <th>Label</th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let item of monthly">
                    <td>{{ item.label }}</td>
                    <td class="d-flex flex-row-reverse">
                      <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fe fe-more-vertical"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item href" [routerLink]="['monthly/' + item.id]" *ngIf="!item.enterprise_id || !canUpdate">
                          Consulter la fiche de suivi
                        </a>
                        <a class="dropdown-item href" [routerLink]="['monthly/' + item.id]" *ngIf="item.enterprise_id && canUpdate">
                          Editer la fiche de suivi
                        </a>
                        <a class="dropdown-item href" (click)="modalConfirmData.open(item.id)" *ngIf="item.enterprise_id && canUpdate">
                          Supprimer la fiche de suivi
                        </a>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>

              <!-- QUARTERLY -->
              <div class="row">
                <div class="col">
                  <h2 class="col">Fiche de suivi maintenance Trimestrielle</h2>
                </div>

                <div class="col-auto">
                  <a class="btn btn-primary d-block d-md-inline-block text-white tooltipHtml" title="Ajouter une fiche de suivi" [routerLink]="['quarterly']">
                    <span class="fe fe-plus"></span>
                  </a>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                  <tr>
                    <th>Label</th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let item of quarterly">
                    <td>{{ item.label }}</td>
                    <td class="d-flex flex-row-reverse">
                      <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fe fe-more-vertical"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item href" [routerLink]="['quarterly/' + item.id]" *ngIf="!item.enterprise_id || !canUpdate">
                          Consulter la fiche de suivi
                        </a>
                        <a class="dropdown-item href" [routerLink]="['quarterly/' + item.id]" *ngIf="item.enterprise_id && canUpdate">
                          Editer la fiche de suivi
                        </a>
                        <a class="dropdown-item href" (click)="modalConfirmData.open(item.id)" *ngIf="item.enterprise_id && canUpdate">
                          Supprimer la fiche de suivi
                        </a>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>

              <!-- BIANNUAL -->
              <div class="row">
                <div class="col">
                  <h2 class="col">Fiche de suivi maintenance Semestrielle</h2>
                </div>

                <div class="col-auto">
                  <a class="btn btn-primary d-block d-md-inline-block text-white tooltipHtml" title="Ajouter une fiche de suivi" [routerLink]="['biannual']">
                    <span class="fe fe-plus"></span>
                  </a>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                  <tr>
                    <th>Label</th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let item of biannual">
                    <td>{{ item.label }}</td>
                    <td class="d-flex flex-row-reverse">
                      <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fe fe-more-vertical"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item href" [routerLink]="['biannual/' + item.id]" *ngIf="!item.enterprise_id || !canUpdate">
                          Consulter la fiche de suivi
                        </a>
                        <a class="dropdown-item href" [routerLink]="['biannual/' + item.id]" *ngIf="item.enterprise_id && canUpdate">
                          Editer la fiche de suivi
                        </a>
                        <a class="dropdown-item href" (click)="modalConfirmData.open(item.id)" *ngIf="item.enterprise_id && canUpdate">
                          Supprimer la fiche de suivi
                        </a>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>

              <!-- ANNUAL -->
              <div class="row">
                <div class="col">
                  <h2 class="col">Fiche de suivi maintenance Annuelle</h2>
                </div>

                <div class="col-auto">
                  <a class="btn btn-primary d-block d-md-inline-block text-white tooltipHtml" title="Ajouter une fiche de suivi" [routerLink]="['annual']">
                    <span class="fe fe-plus"></span>
                  </a>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                  <tr>
                    <th>Label</th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let item of annual">
                    <td>{{ item.label }}</td>
                    <td class="d-flex flex-row-reverse">
                      <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fe fe-more-vertical"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item href" [routerLink]="['annual/' + item.id]" *ngIf="!item.enterprise_id || !canUpdate">
                          Consulter la fiche de suivi
                        </a>
                        <a class="dropdown-item href" [routerLink]="['annual/' + item.id]" *ngIf="item.enterprise_id && canUpdate">
                          Editer la fiche de suivi
                        </a>
                        <a class="dropdown-item href" (click)="modalConfirmData.open(item.id)" *ngIf="item.enterprise_id && canUpdate">
                          Supprimer la fiche de suivi
                        </a>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>

            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<app-confirm-modal [type]="'delete_data'" #confirmData>{{ bindModalConfirmData(confirmData) }}</app-confirm-modal>

<router-outlet></router-outlet>
