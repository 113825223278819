import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { PlanningComponent } from './planning.component';

import { UtilsModule } from '../../core/utils/utils.module';
import { CalendarModule } from '../../core/calendar/calendar.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    UtilsModule,
    CalendarModule
  ],
  declarations: [PlanningComponent]
})

export class PlanningModule {}
