import { AfterViewInit, Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { MapsAPILoader } from '@agm/core';

import { environment } from '../../../../environments/environment';
import { establishment_types, ERP_subtypes, IGH_subtypes, BHC_categories } from '../../../../shared/establishments';

import { AllEstablishmentsComponent } from '../all-establishments/all-establishments.component';

import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { RegistriesRequestService } from '../../../services/registries.request.service';
import { EstablishmentsRequestService } from '../../../services/establishments.request.service';

import Enterprise from '../../../../shared/interfaces/enterprise';
import Offer from '../../../../shared/interfaces/offer';
import Registry from '../../../../shared/interfaces/registry';
import EstablishmentType from '../../../../shared/interfaces/establishment_type';

declare function Flatpickr(): any;
declare var $: any;

@Component({
  selector: 'app-update-establishment',
  templateUrl: './update-establishment.component.html',
  styleUrls: ['./update-establishment.component.scss']
})
export class UpdateEstablishmentComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('search', { static: true }) public searchElementRef: ElementRef;

  environment = environment;

  typesEstablishments = establishment_types;
  subTypesERP = ERP_subtypes;
  subTypesIGH = IGH_subtypes;
  categoriesBHC = BHC_categories;

  canUpdate = false;
  canRus = false;

  establishmentForm: FormGroup;
  id = new FormControl('');
  parent_id = new FormControl(null);

  enterprise_id = new FormControl('');
  name = new FormControl('', [Validators.required]);
  description = new FormControl('');
  address = new FormControl(null);
  zipcode = new FormControl(null);
  city = new FormControl(null);
  latitude = new FormControl(null);
  longitude = new FormControl(null);
  phone = new FormControl(null);
  person_in_charge = new FormControl('', [Validators.required]);
  person_in_charge_phone = new FormControl('', [Validators.required]);
  referency = new FormControl(null);

  open_date = new FormControl(null);
  issuance_building_date = new FormControl(null);
  scoring = new FormControl(null);
  effective_staff = new FormControl(null);
  effective_public = new FormControl(null);
  area = new FormControl(null);
  syndicate = new FormControl(null);
  picture = new FormControl(null);

  establishment_category = new FormControl(null);
  establishment_category_id = new FormControl(null);
  establishment_subcategory = new FormControl(null);
  establishment_subcategory_id = new FormControl(null);
  establishment_subtype_establishments = new FormControl([]);
  establishment_type = new FormControl(null);
  establishment_type_id = new FormControl('', [Validators.required]);
  is_work_code = new FormControl('');
  is_rus = new FormControl('');
  is_group = new FormControl(false);

  selectedERPSubTypes = [];
  selectedERPSubTypesFull = [];
  selectedIGHSubTypes = [];
  selectedIGHSubTypesFull = [];

  errors = {};

  view = '';
  previousRoute = '';
  children = false;

  offer: Offer;
  quotas: Registry[];
  registries: Registry[];
  registry_offers: Registry[];
  registry_no_offers: Registry[];
  count_no_offers = 0;
  count_registries = 0;

  isNew = true;
  loader = false;
  loaderContent = false;
  alertNeeded = false;

  modalEstablishment = null;

  activeTab = 'firstPart';

  results = [];
  searchTerm: string;
  autocompleteService;
  sessionToken: google.maps.places.AutocompleteSessionToken = null;

  constructor(
    private ngZone: NgZone,
    private router: Router,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private mapsAPILoader: MapsAPILoader,
    private activatedRoute: ActivatedRoute,
    private registriesService: RegistriesRequestService,
    private allEstablishments: AllEstablishmentsComponent,
    private establishmentsService: EstablishmentsRequestService
  ) {}

  ngOnInit() {
    this.establishmentForm = this.formBuilder.group({
      id: this.id,
      parent_id: this.parent_id,

      enterprise_id: this.enterprise_id,
      name: this.name,
      description: this.description,
      address: this.address,
      zipcode: this.zipcode,
      city: this.city,
      latitude: this.latitude,
      longitude: this.longitude,
      phone: this.phone,
      person_in_charge: this.person_in_charge,
      person_in_charge_phone: this.person_in_charge_phone,
      referency: this.referency,

      open_date: this.open_date,
      issuance_building_date: this.issuance_building_date,
      scoring: this.scoring,
      effective_staff: this.effective_staff,
      effective_public: this.effective_public,
      area: this.area,
      syndicate: this.syndicate,
      picture: this.picture,

      establishment_category: this.establishment_category,
      establishment_category_id: this.establishment_category_id,
      establishment_subcategory: this.establishment_subcategory,
      establishment_subcategory_id: this.establishment_subcategory_id,
      establishment_subtype_establishments: this.establishment_subtype_establishments,
      establishment_type: this.establishment_type,
      establishment_type_id: this.establishment_type_id,
      is_work_code: this.is_work_code,
      is_rus: this.is_rus,
      is_group: this.is_group,

      use_full_numbers: this.formBuilder.array([]) as FormArray,
    });

    if (this.activatedRoute.snapshot.parent.params.active && this.activatedRoute.snapshot.parent.params.active === 'archive') {
      this.view = 'archive';
    } else if (this.activatedRoute.snapshot.parent.params.enterprise_id) {
      this.view = 'enterprise';
    }

    if (this.activatedRoute.snapshot.params.establishment_id) {
      this.isNew = false;
      this.getEstablishment();

      if (this.activatedRoute.snapshot.parent.url.length === 5 && this.activatedRoute.snapshot.parent.url[4].path === 'children') {
        this.children = true;
        this.getParent();
      }
    } else {
      this.getAvailableRegistries(0, '');

      if (this.activatedRoute.snapshot.parent.url.length === 5 && this.activatedRoute.snapshot.parent.url[4].path === 'children') {
        this.children = true;
        this.getParent();
      }
      Flatpickr();

      if (this.activatedRoute.snapshot.parent.params.enterprise_id) {
        this.establishmentForm.patchValue({ enterprise_id: this.activatedRoute.snapshot.parent.params.enterprise_id });
      }
      if (this.activatedRoute.snapshot.parent.params.parent_id) {
        let enterprise: Enterprise;
        if (JSON.parse(localStorage.getItem('connectedAs'))) {
          enterprise = JSON.parse(localStorage.getItem('connectedAsEnterprise'));
        } else {
          enterprise = JSON.parse(localStorage.getItem('enterprise'));
        }
        this.establishmentForm.patchValue({
          parent_id: this.activatedRoute.snapshot.parent.params.parent_id,
          enterprise_id: enterprise.id
        });
      }
    }

    this.previousRoute = this.activatedRoute.snapshot.parent.url.join('/');

    this.subTypesIGH.forEach(value => {
      value['group'] = true;
    });

    this.subTypesERP.forEach(value => {
      value['group'] = true;
    });
  }

  ngAfterViewInit() {
    this.modalEstablishment.open();

    this.mapsAPILoader.load().then(() => {
      this.sessionToken = new google.maps.places.AutocompleteSessionToken();
      this.autocompleteService = new google.maps.places.AutocompleteService();
    });
  }

  ngOnDestroy() {
    const flatPicker = document.getElementsByClassName('flatpickr-calendar') as HTMLCollection;
    for (let i = flatPicker.length; i >= 0; i--) {
      if (flatPicker[i]) {
        flatPicker[i].remove();
      }
    }
    this.modalEstablishment.close();
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  getEstablishment() {
    this.errors = {};
    this.loaderContent = true;

    const establishment_id = this.activatedRoute.snapshot.params.establishment_id;
    let request;
    if (this.activatedRoute.snapshot.parent.params.enterprise_id) {
      const enterprise_id = this.activatedRoute.snapshot.parent.params.enterprise_id;
      request = this.establishmentsService.getEstablishment(enterprise_id, establishment_id);
    } else {
      request = this.establishmentsService.getMyEstablishment(establishment_id);
    }

    request.subscribe(response => {
      if (response['success']) {
        if (this.activatedRoute.snapshot.parent.params.active !== 'archive') {
          this.canUpdate = response['canUpdate'];
        }

        if (response['establishment']['use_full_numbers']) {
          for (const key in response['establishment']['use_full_numbers']) {
            if (response['establishment']['use_full_numbers'].hasOwnProperty(key)) {
              this.addNumber();
            }
          }
        } else {
          response['establishment']['use_full_numbers'] = [];
        }

        this.establishmentForm.patchValue(response['establishment']);
        response['establishment'].establishment_subtype_establishments.forEach(subType => {
          if (response['establishment'].establishment_type.name === 'ERP') {
            this.selectedERPSubTypes.push(subType.establishment_subtype.id);
            this.selectedERPSubTypesFull.push(subType);
          } else if (response['establishment'].establishment_type.name === 'IGH') {
            this.selectedIGHSubTypes.push(subType.establishment_subtype.id);
            this.selectedIGHSubTypesFull.push(subType);
          }
        }, this);

        if (this.view === 'archive') {
          this.canUpdate = false;
        }
        if (!this.canUpdate) {
          this.establishmentForm.disable();
        } else {
          if (this.children) {
            this.getAvailableRegistries(this.activatedRoute.snapshot.params.establishment_id, 'y');
          } else {
            this.getAvailableRegistries(this.activatedRoute.snapshot.params.establishment_id, '');
          }
        }
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.establishmentForm, this.notifier);
      }
      Flatpickr();
      this.loaderContent = false;
    });
  }
  getParent() {
    this.errors = {};
    this.loaderContent = true;

    const establishment_id = this.activatedRoute.snapshot.parent.params.parent_id;
    this.establishmentsService.getMyEstablishment(establishment_id).subscribe(response => {
      if (response['success']) {
        if (this.isNew) {
          this.establishmentForm.patchValue({
            address: response['establishment']['address'],
            zipcode: response['establishment']['zipcode'],
            city: response['establishment']['city'],
            establishment_category: response['establishment']['establishment_category'],
            establishment_category_id: response['establishment']['establishment_category_id'],
            establishment_subcategory: response['establishment']['establishment_subcategory'],
            establishment_subcategory_id: response['establishment']['establishment_subcategory_id'],
            establishment_type: response['establishment']['establishment_type'],
            establishment_type_id: response['establishment']['establishment_type_id'],
          });
        }
        this.address.disable();
        this.zipcode.disable();
        this.city.disable();
        this.establishment_category.disable();
        this.establishment_category_id.disable();
        this.establishment_subcategory.disable();
        this.establishment_subcategory_id.disable();
        this.establishment_type.disable();
        this.establishment_type_id.disable();
        this.establishment_subtype_establishments.disable();
        for (const key in response['establishment'].establishment_subtype_establishments) {
          if (response['establishment'].establishment_subtype_establishments.hasOwnProperty(key)) {
            if (response['establishment'].establishment_type.name === 'ERP') {
              this.selectedERPSubTypes.push(
                response['establishment'].establishment_subtype_establishments[key].establishment_subtype.id
              );
              this.selectedERPSubTypesFull.push(
                response['establishment'].establishment_subtype_establishments[key]
              );
            } else if (response['establishment'].establishment_type.name === 'IGH') {
              this.selectedIGHSubTypes.push(
                response['establishment'].establishment_subtype_establishments[key].establishment_subtype.id
              );
              this.selectedIGHSubTypesFull.push(
                response['establishment'].establishment_subtype_establishments[key]
              );
            }
          }
        }
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.establishmentForm, this.notifier);
      }
      this.loaderContent = false;
    });
  }
  getAvailableRegistries(establishment_id: number, parent: string) {
    this.loaderContent = true;

    let request;
    if (this.activatedRoute.snapshot.parent.params.enterprise_id) {
      request = this.registriesService.getEnterpriseQuotaEstablishment(
        establishment_id, parent, this.activatedRoute.snapshot.parent.params.enterprise_id
      );
    } else {
      request = this.registriesService.getQuotaEstablishment(establishment_id, parent);
    }

    request.subscribe(response => {
      if (response['success']) {
        this.canRus = response['canRus'];

        this.offer = response['offer'];
        this.quotas = response['quotas'];

        this.registry_offers = response['registry_offers'];
        this.registry_offers.forEach(registry => {
          const found = this.quotas.find(element => {
            return element.registry_id === registry.id;
          });
          if (found) {
            registry.checked = found['active'];
            registry.initial_state = found['active'];
            registry.is_pool = found['is_pool'];
          } else {
            registry.checked = false;
            registry.initial_state = false;
            registry.is_pool = false;
          }
          registry.can_activate = true;
          if (registry.checked) {
            this.count_registries++;
          }
        });

        this.registry_no_offers = response['registry_no_offers'];
        this.registry_no_offers.forEach(registry => {
          const found = this.quotas.find(element => {
            return element.registry_id === registry.id;
          });
          if (found) {
            registry.checked = found['active'];
            registry.initial_state = found['active'];
            registry.can_activate = found['active'];
            registry.is_pool = found['is_pool'];
          } else {
            registry.checked = false;
            registry.initial_state = false;
            registry.can_activate = true;
            registry.is_pool = false;
          }
          if (registry.checked) {
            this.count_registries++;
          }
        });

        this.updateAvailability();
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loaderContent = false;
    });
  }

  onSearch(term: string) {
    this.searchTerm = term;
    if (this.searchTerm === '') { return; }
    this.autocompleteService.getPlacePredictions({
      input: term,
      sessionToken: this.sessionToken,
      componentRestrictions: { country: ['fr', 'gp', 'mq', 'gf', 're', 'pm', 'yt', 'nc', 'pf', 'mf', 'tf'] }
    }, data => {
      if (data) {
        this.results = data;
      } else {
        this.results = [];
      }
    });
  }
  clearSearch() {
    this.address.reset();
    this.results = [];
  }
  selectPlace(data) {
    const request = {
      placeId: data.place_id,
      sessionToken: this.sessionToken
    };
    const service = new google.maps.places.PlacesService($('#service-helper').get(0));
    service.getDetails(request, (place, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        let route = '';
        let streetNumber = '';

        for (let i = 0; i < place.address_components.length; i++) {
          const component = place.address_components[i];
          const addressType = component.types[0];

          switch (addressType) {
            case 'street_number':
              streetNumber = component.long_name;
              break;
            case 'route':
              route = component.short_name;
              break;
            case 'locality':
              this.city.setValue(component.long_name);
              break;
            case 'postal_code':
              this.zipcode.setValue(component.long_name);
              break;
          }
        }

        if (route) {
          this.address.setValue(streetNumber && route ? streetNumber + ' ' + route : route);
        }

        this.latitude.setValue(place.geometry.location.lat());
        this.longitude.setValue(place.geometry.location.lng());

      }
    });
    this.results = [];
  }

  createEstablishment() {
    this.errors = {};
    this.loader = true;

    if (this.establishment_type_id.value === 1) {
      if (this.establishment_subcategory_id.value &&
        (this.establishment_subcategory_id.value === 5 || this.establishment_subcategory_id.value === '5')) {
        this.establishment_category_id.setValue(2);
      } else if (this.establishment_subcategory_id.value) {
        this.establishment_category_id.setValue(1);
      }
    }

    let establishment = {
      ...this.establishmentForm.value,
      is_work_code: this.is_work_code.value,
      is_rus: this.is_rus.value,
      registry_offers: this.registry_offers,
      registry_no_offers: this.registry_no_offers
    };
    if (this.children) {
      const subtypes = [];
      if (this.establishment_type.value.name === 'ERP') {
        this.selectedERPSubTypes.forEach( selectedSubtype => {
          subtypes.push({ establishment_subtype_id: selectedSubtype });
        });
      } else if (this.establishment_type.value.name === 'IGH') {
        this.selectedIGHSubTypes.forEach( selectedSubtype => {
          subtypes.push({ establishment_subtype_id: selectedSubtype });
        });
      }
      this.establishment_subtype_establishments.setValue(subtypes);
      establishment = {
        ...establishment,
        address: this.address.value,
        zipcode: this.zipcode.value,
        city: this.city.value,
        establishment_category: this.establishment_category.value,
        establishment_category_id: this.establishment_category_id.value,
        establishment_subcategory: this.establishment_subcategory.value,
        establishment_subcategory_id: this.establishment_subcategory_id.value,
        establishment_subtype_establishments: this.establishment_subtype_establishments.value,
        establishment_type: this.establishment_type.value,
        establishment_type_id: this.establishment_type_id.value
      };
    }

    if (this.activatedRoute.snapshot.parent.params.group_id) {
      establishment = {
        ...establishment,
        group_id: this.activatedRoute.snapshot.parent.params.group_id
      };
    }
    let request;
    if (this.activatedRoute.snapshot.parent.params.enterprise_id) {
      const enterprise_id = this.activatedRoute.snapshot.parent.params.enterprise_id;
      request = this.establishmentsService.createEstablishment(enterprise_id, establishment);
    } else {
      request = this.establishmentsService.createMyEstablishment(establishment);
    }

    request.subscribe(response => {
      if (response['success']) {
        this.establishmentForm.patchValue({ id: response['establishment']['id'] });
        this.modalEstablishment.close();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.establishmentForm, this.notifier);
      }
      this.loader = false;
    });
  }
  updateEstablishment() {
    this.errors = {};
    this.loader = true;

    if (this.establishment_type_id.value === 1) {
      if (this.establishment_subcategory_id.value &&
        (this.establishment_subcategory_id.value === 5 || this.establishment_subcategory_id.value === '5')) {
        this.establishment_category_id.setValue(2);
      } else if (this.establishment_subcategory_id.value) {
        this.establishment_category_id.setValue(1);
      }
    } else if (this.establishment_type_id.value === 2) {
      this.establishment_category.setValue('');
      this.establishment_category_id.setValue('');
      this.establishment_subcategory.setValue('');
      this.establishment_subcategory_id.setValue('');
      this.establishment_subtype_establishments.setValue([]);
    } else if (this.establishment_type_id.value === 3) {
      this.establishment_subcategory.setValue('');
      this.establishment_subcategory_id.setValue('');
      this.establishment_subtype_establishments.setValue([]);
    } else if (this.establishment_type_id.value === 4) {
      this.establishment_category.setValue('');
      this.establishment_category_id.setValue('');
      this.establishment_subcategory.setValue('');
      this.establishment_subcategory_id.setValue('');
    } else if (this.establishment_type_id.value === 5) {
      this.establishment_category.setValue('');
      this.establishment_category_id.setValue('');
      this.establishment_subcategory.setValue('');
      this.establishment_subcategory_id.setValue('');
      this.establishment_subtype_establishments.setValue([]);
    }

    const establishment = {
      ...this.establishmentForm.value,
      registry_offers: this.registry_offers,
      registry_no_offers: this.registry_no_offers
    };

    let request;
    if (this.activatedRoute.snapshot.parent.params.enterprise_id) {
      const enterprise_id = this.activatedRoute.snapshot.parent.params.enterprise_id;
      request = this.establishmentsService.updateEstablishment(enterprise_id, this.id.value, establishment);
    } else {
      request = this.establishmentsService.updateMyEstablishment(this.id.value, establishment);
    }

    request.subscribe(response => {
      if (response['success']) {
        this.modalEstablishment.close();
        CustomNotifierService.getSuccessUpdate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.establishmentForm, this.notifier);
      }
      this.loader = false;
    });
  }

  checkRegistry(registry: Registry) {
    if (!registry.checked) {
      this.count_registries++;
    } else {
      this.count_registries--;
    }
    registry.checked = !registry.checked;
    this.updateAvailability();
    this.updateAlertMessage();
  }
  checkPool(registry: Registry) {
    registry.is_pool = !registry.is_pool;
  }
  updateAvailability() {
    if (this.offer && this.offer.registry_limit > 0 && !this.children) {
      this.count_no_offers = 0;
      this.registry_no_offers.forEach(registry => {
        if (registry.checked) {
          this.count_no_offers++;
        }
      });
      if (this.count_no_offers < this.offer.registry_limit) {
        this.registry_no_offers.forEach(registry => {
          registry['disabled'] = false;
        });
      } else {
        this.registry_no_offers.forEach(registry => {
          registry['disabled'] = !registry.checked;
        });
      }
    }
  }
  updateAlertMessage() {
    this.alertNeeded = false;
    this.registry_no_offers.forEach(registry => {
      if (registry.initial_state && !registry.checked) {
        this.alertNeeded = true;
      }
    });
  }

  chooseFile() {
    const element: HTMLElement = document.getElementById('inputFile') as HTMLElement;
    element.click();
  }
  onFileChange(event) {
    this.picture.setValue('');

    const reader = new FileReader();

    if (event.target && event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.picture.setValue(reader.result);
      };
    } else {
      this.picture.setValue(event.file);
    }
    const element: HTMLInputElement = document.getElementById('inputFile') as HTMLInputElement;
    element.value = '';
  }
  checkPicture() {
    if (this.picture.value.indexOf(';base64,') !== -1) {
      return this.picture.value;
    } else {
      return environment.SERVER_HOST + 'img/establishments/' + this.picture.value;
    }
  }
  removeFile() {
    this.picture.setValue(null);

    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].remove();
    }
  }

  setSubType() {
    const subtypes = [];
    let selected = null;
    let selectedFull = null;

    if (this.establishment_type_id.value === 1) {
      selected = this.selectedERPSubTypes;
      selectedFull = this.selectedERPSubTypesFull;
    } else if (this.establishment_type_id.value === 4) {
      selected = this.selectedIGHSubTypes;
      selectedFull = this.selectedIGHSubTypesFull;
    }
    if (selected && selectedFull) {
      selected.forEach(selectedSubtype => {
        const find = selectedFull.find(element => {
          return element.establishment_subtype_id === selectedSubtype;
        });
        if (find) {
          subtypes.push(find);
        } else {
          subtypes.push({ establishment_subtype_id: selectedSubtype });
        }
      });
      this.establishment_subtype_establishments.setValue(subtypes);
    }
  }
  changeTypeEstablishment(type: EstablishmentType) {
    if (type.requiredCategories) {
      this.establishment_category_id.setValidators([Validators.required]);
    } else {
      this.establishment_category_id.clearValidators();
    }
    if (type.requiredSubCategories) {
      this.establishment_subcategory_id.setValidators([Validators.required]);
    } else {
      this.establishment_subcategory_id.clearValidators();
    }
    if (type.requiredSubtypes) {
      this.establishment_subtype_establishments.setValidators([Validators.required]);
    } else {
      this.establishment_subtype_establishments.clearValidators();
    }

    if (type.id === 3) {
      this.is_work_code.setValue(false);
      this.is_work_code.disable();
    } else {
      if (type.id === 1 || type.id === 2) {
        this.is_work_code.setValue(true);
      } else if (type.id === 4) {
        this.is_work_code.setValue(false);
      }
      this.is_work_code.enable();
    }

    this.establishment_category_id.updateValueAndValidity();
    this.establishment_subcategory_id.updateValueAndValidity();
    this.establishment_subtype_establishments.updateValueAndValidity();
    this.establishment_type_id.setValue(type.id);
    this.setSubType();
  }

  changeTabs(activeTab) {
    this.activeTab = activeTab;
  }
  prevTabs() {
    if (this.activeTab === 'secondPart') {
      this.activeTab = 'firstPart';
    } else if (this.activeTab === 'thirdPart') {
      this.activeTab = 'secondPart';
    } else {
      this.activeTab = 'thirdPart';
    }
  }
  nextTabs() {
    if (this.activeTab === 'secondPart') {
      this.activeTab = 'thirdPart';
    } else if (this.activeTab === 'thirdPart') {
      this.activeTab = 'fourthPart';
    } else {
      this.activeTab = 'secondPart';
    }
  }

  bindModal(modal) {
    this.modalEstablishment = modal;
  }
  closeModal() {
    this.allEstablishments.refreshData();
    this.router.navigate([this.previousRoute]).then(() => {});
  }

  initPrices() {
    return this.formBuilder.group({
      name: ['', Validators.required],
      number: ['', Validators.required]
    });
  }
  addNumber() {
    const control = <FormArray>this.establishmentForm.controls['use_full_numbers'];
    control.push(this.initPrices());
  }
  deleteNumber(index) {
    const control = <FormArray>this.establishmentForm.controls['use_full_numbers'];
    control.removeAt(index);
  }
}
