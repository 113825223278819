import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { HttpClient } from '@angular/common/http';
import { RequestService } from './request.service';

@Injectable()
export class SanitaryContributorsRequestService extends RequestService {
  constructor(
    protected http: HttpClient,
    protected notifier: NotifierService
  ) {
    super(http, notifier);
  }

  /// GET ALL
  public getAllInternalSanitaryContributors(search, sort, order, limit, page, registry_id, establishment_id) {
    return this.get( 'registry/' + registry_id + '/establishment/' + establishment_id + '/sanitary-contributors?' +
      'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page + '&external=' + 0);
  }
  public getAllExternalSanitaryContributors(search, sort, order, limit, page, registry_id, establishment_id) {
    return this.get( 'registry/' + registry_id + '/establishment/' + establishment_id + '/sanitary-contributors?' +
        'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page + '&external=' + 1);
  }
  public getAllSanitaryContributors(search, sort, order, limit, page, registry_id, establishment_id) {
    return this.get( 'registry/' + registry_id + '/establishment/' + establishment_id + '/sanitary-contributors?' +
      'search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  /// GET ONE
  public getSanitaryContributor(registry_id, establishment_id, sanitaryContributorId) {
    return this.get('registry/' + registry_id + '/establishment/' + establishment_id + '/sanitary-contributor/' + sanitaryContributorId);
  }
  /// CREATE
  public createSanitaryContributor(registry_id, establishment_id, sanitaryContributor) {
    return this.post('registry/' + registry_id + '/establishment/' + establishment_id + '/sanitary-contributor', { sanitaryContributor });
  }
  /// UPDATE
  public updateSanitaryContributor(registry_id, establishment_id, sanitaryContributorId, sanitaryContributor) {
    return this.put('registry/' + registry_id + '/establishment/' + establishment_id + '/sanitary-contributor/' + sanitaryContributorId,
      { sanitaryContributor });
  }
  /// DELETE
  public deleteSanitaryContributor(registry_id, establishment_id, sanitaryContributorId) {
    return this.delete('registry/' + registry_id + '/establishment/' + establishment_id + '/sanitary-contributor/' + sanitaryContributorId);
  }
  /// FILE
  public getFiles(search, sort, order, limit, page, registry_id, establishment_id, sanitaryContributorId) {
    return this.get('registry/' + registry_id + '/establishment/' + establishment_id + '/sanitary-contributor/' + sanitaryContributorId +
        '/files?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page);
  }
  public getFile(registry_id, establishment_id, sanitaryContributorId, file_id) {
    return this.get('registry/' + registry_id + '/establishment/' + establishment_id + '/sanitary-contributor/' + sanitaryContributorId +
        '/files/' + file_id);
  }
  public createFile(registry_id, establishment_id, sanitaryContributorId, files) {
    return this.post('registry/' + registry_id + '/establishment/' + establishment_id +
        '/sanitary-contributor/' + sanitaryContributorId + '/files',
        { files: files });
  }
  public updateFile(registry_id, establishment_id, sanitaryContributorId, file_id, file) {
    return this.put('registry/' + registry_id + '/establishment/' + establishment_id + '/sanitary-contributor/' + sanitaryContributorId +
        '/files/' + file_id, { file: file });
  }
  public deleteFile(registry_id, establishment_id, sanitaryContributorId, file_id) {
    return this.delete('registry/' + registry_id + '/establishment/' + establishment_id + '/sanitary-contributor/' + sanitaryContributorId +
        '/files/' + file_id);
  }
  public downloadFile(registry_id, establishment_id, sanitaryContributorId, file_id) {
    return this.get('registry/' + registry_id + '/establishment/' + establishment_id + '/sanitary-contributor/' + sanitaryContributorId +
        '/files/' + file_id + '/download', { responseType: 'arraybuffer' });
  }
}
