import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AllCustomerInstallationsComponent } from './all-customer-installations.component';
import { BreadcrumbsModule } from '../../../core/breadcrumbs/breadcrumbs.module';
import { SearchbarModule } from '../../../core/searchbar/searchbar.module';
import { PaginationModule } from '../../../core/pagination/pagination.module';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    BreadcrumbsModule,
    SearchbarModule,
    PaginationModule,
    NgSelectModule
  ],
  declarations: [AllCustomerInstallationsComponent]
})

export class AllCustomerInstallationsModule {}
