import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { ModalRightModule } from "../../../core/modal-right/modal-right.module";
import { UpdateTrainingComponent } from "./update-training.component";
import { NgSelectModule } from "@ng-select/ng-select";

@NgModule({
  declarations: [UpdateTrainingComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    ModalRightModule,
    NgSelectModule,
  ],
})
export class UpdateTrainingModule {}
