<div class="main-content">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col">
        <div class="header mb-0">
          <div class="header-body no-border">
            <div class="row align-items-center">
              <div class="col">
                <h1 class="header-title">
                  Échelles de cotation
                </h1>

                <small class="form-text text-muted">
                  Echelles de cotation
                </small>

                <app-breadcrumbs [currentPage]="pageName" [names]="previousPageName" [routes]="previousPageRoute"></app-breadcrumbs>
              </div>
            </div>
          </div>
        </div>

        <div class="is-loading loader-content" *ngIf="loader"></div>

        <div class="tab-content" *ngIf="!loader">
          <div class="card">
            <div class="card-body">
              <!-- FREQUENCIES -->
              <div class="row">
                <div class="col">
                  <h2 class="col">Cotation de fréquence</h2>
                </div>

                <div class="col-auto">
                  <a class="btn btn-primary d-block d-md-inline-block text-white tooltipHtml" title="Ajouter une fréquence" [routerLink]="['frequencies']">
                    <span class="fe fe-plus"></span>
                  </a>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th>Label</th>
                      <th>Description</th>
                      <th>Coefficient</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of frequencies">
                      <td>{{ item.title }}</td>
                      <td>{{ item.description }}</td>
                      <td [attr.aria-label]="'Coefficient : ' + item.coefficient">{{ item.coefficient }}</td>
                      <td class="d-flex flex-row-reverse">
                        <a class="btn btn-primary d-block d-md-inline-block text-white tooltipHtml ml-3 mb-3" title="Éditer la fréquence" [routerLink]="['frequencies/' + item.id]"
                           *ngIf="view === 'user' && item.enterprise_id !== null || view === 'admin'">
                          <span class="fe fe-edit-2"></span>
                        </a>
                        <a class="btn btn-primary d-block d-md-inline-block text-white tooltipHtml ml-3 mb-3" title="Supprimer la fréquence" (click)="modalConfirmData.open(item.id)"
                           *ngIf="view === 'user' && item.enterprise_id !== null || view === 'admin' && frequencies.length > 4">
                          <span class="fe fe-trash"></span>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <!-- GRAVITIES -->
              <div class="row">
                <div class="col">
                  <h2 class="col">Cotation de gravité</h2>
                </div>

                <div class="col-auto">
                  <a class="btn btn-primary d-block d-md-inline-block text-white tooltipHtml" title="Ajouter une gravité" [routerLink]="['gravities']">
                    <span class="fe fe-plus"></span>
                  </a>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th>Label</th>
                      <th>Description</th>
                      <th>Coefficient</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of gravities">
                      <td>{{ item.title }}</td>
                      <td>{{ item.description }}</td>
                      <td [attr.aria-label]="'Coefficient : ' + item.coefficient">{{ item.coefficient }}</td>
                      <td class="d-flex flex-row-reverse">
                        <a class="btn btn-primary d-block d-md-inline-block text-white tooltipHtml ml-3 mb-3" title="Éditer la gravité" [routerLink]="['gravities/' + item.id]"
                           *ngIf="view === 'user' && item.enterprise_id !== null || view === 'admin'">
                          <span class="fe fe-edit-2"></span>
                        </a>
                        <a class="btn btn-primary d-block d-md-inline-block text-white tooltipHtml ml-3 mb-3" title="Supprimer la fréquence" (click)="modalConfirmData.open(item.id)"
                           *ngIf="view === 'user' && item.enterprise_id !== null || view === 'admin' && gravities.length > 4">
                          <span class="fe fe-trash"></span>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <!-- MASTERS -->
              <div class="row">
                <div class="col">
                  <h2 class="col">Cotation de maitrise</h2>
                </div>

                <div class="col-auto">
                  <a class="btn btn-primary d-block d-md-inline-block text-white tooltipHtml" title="Ajouter une maitrise" [routerLink]="['masters']">
                    <span class="fe fe-plus"></span>
                  </a>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th>Label</th>
                      <th>Description</th>
                      <th>Coefficient</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of masters">
                      <td>{{ item.title }}</td>
                      <td>{{ item.description }}</td>
                      <td [attr.aria-label]="'Coefficient : ' + item.coefficient">{{ item.coefficient }}</td>
                      <td class="d-flex flex-row-reverse">
                        <a class="btn btn-primary d-block d-md-inline-block text-white tooltipHtml ml-3 mb-3" title="Éditer la maitrise" [routerLink]="['masters/' + item.id]"
                        *ngIf="view === 'user' && item.enterprise_id !== null || view === 'admin'">
                          <span class="fe fe-edit-2"></span>
                        </a>
                        <a class="btn btn-primary d-block d-md-inline-block text-white tooltipHtml ml-3 mb-3" title="Supprimer la fréquence" (click)="modalConfirmData.open(item.id)"
                           *ngIf="view === 'user' && item.enterprise_id !== null || view === 'admin' && masters.length > 4">
                          <span class="fe fe-trash"></span>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <!-- BRUT -->
              <div class="row">
                <div class="col">
                  <h2 class="col">Matrice de cotation du risque brut</h2>
                </div>

                <div class="col-auto">
                  <a class="btn btn-primary d-block d-md-inline-block text-white tooltipHtml" title="Éditer les paliers" [routerLink]="['limits']">
                    <span class="fe fe-edit-2"></span>
                  </a>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table table-nonfluid table-striped">
                  <thead>
                    <tr>
                      <th>Fréquence</th>
                      <th colspan="4"></th>
                    </tr>
                    <tr *ngFor="let frequency of frequencies">
                      <td>{{ frequency.coefficient }}</td>
                      <td *ngFor="let gravity of gravities" [style.background-color]="getBrutColor(gravity.coefficient * frequency.coefficient)">{{ gravity.coefficient * frequency.coefficient }}</td>
                    </tr>
                    <tr>
                      <td>-</td>
                      <td *ngFor="let gravity of gravities">{{ gravity.coefficient }}</td>
                    </tr>
                    <tr>
                      <th></th>
                      <th colspan="4">Gravité</th>
                    </tr>
                  </thead>
                </table>
              </div>

              <!-- MATRICE -->
              <div class="row">
                <div class="col">
                  <h2 class="col">Matrice de cotation du risque après mise en place des moyens de prévention</h2>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table table-nonfluid table-striped">
                  <thead>
                    <tr>
                      <th>Fréquence * Gravité</th>
                      <th colspan="4"></th>
                    </tr>
                    <tr *ngFor="let item of matrice">
                      <td>{{ item }}</td>
                      <td *ngFor="let master of masters" [style.background-color]="getNetColor(item * master.coefficient)">{{ master.coefficient * item }}</td>
                    </tr>
                    <tr>
                      <td>-</td>
                      <td *ngFor="let master of masters">{{ master.coefficient }}</td>
                    </tr>
                    <tr>
                      <th></th>
                      <th colspan="4">Maitrise</th>
                    </tr>
                  </thead>
                </table>
              </div>

              <div class="row">
                <div class="col-12">
                  <ul>
                    <li>Vert - Risque très faible à faible : risque pouvant être traité à long terme</li>
                    <li>Jaune - Risque modéré : risque à traiter à moyen terme</li>
                    <li>Rouge - Risque élevé à très élevé : risque à traiter et à surveiller en priorité</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-confirm-modal [type]="'delete_data'" #confirmData>{{ bindModalConfirmData(confirmData) }}</app-confirm-modal>

<router-outlet></router-outlet>
