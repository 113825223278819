import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AllCommissionsModule } from './all-commissions/all-commissions.module';
import { UpdateCommissionModule } from './update-commission/update-commission.module';
import { UploadCommissionFileModule } from './upload-commission-file/upload-commission-file.module';

import { CommissionsRequestService } from '../../services/commissions.request.service';

@NgModule({
  imports: [
    CommonModule,
    AllCommissionsModule,
    UpdateCommissionModule,
    UploadCommissionFileModule
  ],
  providers: [
    CommissionsRequestService
  ]
})
export class CommissionsModule {}
