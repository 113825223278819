import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';

import { AllEnterprisesComponent } from '../all-enterprises/all-enterprises.component';
import { CustomNotifierService } from '../../../services/custom.notifier.service';

import { EnterprisesRequestService } from '../../../services/enterprises.request.service';
import { UsersRequestService } from '../../../services/users.request.service';
import { AuthService } from '../../../core/auth/auth.service';

@Component({
  selector: 'app-add-provider',
  templateUrl: './add-provider.component.html',
  styleUrls: ['./add-provider.component.scss']
})
export class AddProviderComponent implements OnInit, AfterViewInit, OnDestroy {
  canCreate = false;
  canUpdate = false;

  activeTab = 'siret';

  existSiret = null;
  enterpriseSiret = {};
  enterpriseSiretSearchForm: FormGroup;
  siret = new FormControl('', [Validators.required]);
  qualification = '';
  access_to_enterprise = false;
  enterpriseSiretForm: FormGroup;
  nameSiret = new FormControl('', [Validators.required]);
  siretSiret = new FormControl({ value: '', disabled: true });
  qualificationSiret = new FormControl(null, [Validators.required]);
  access_to_enterpriseSiret = new FormControl(false, [Validators.required]);

  existEmail1 = null;
  existEmail2 = null;
  enterpriseEmail = {};
  enterpriseSearchByEmailForm: FormGroup;
  email = new FormControl('', [Validators.required]);
  userEmailForm: FormGroup;
  id = new FormControl('');
  emailUser = new FormControl({ value: '', disabled: true }, [Validators.required]);
  firstname = new FormControl('', [Validators.required]);
  lastname = new FormControl('', [Validators.required]);
  siretSearchMail = new FormControl('', [Validators.required]);
  enterpriseEmailForm: FormGroup;
  nameEmail = new FormControl('', [Validators.required]);
  siretEmail = new FormControl({ value: '', disabled: true }, [Validators.required]);
  qualificationEmail = new FormControl(null, [Validators.required]);
  access_to_enterpriseEmail = new FormControl(false, [Validators.required]);

  enterprise_id: number;

  errors = {};

  loader = false;
  loaderSearch = false;
  loaderContent = false;

  modalEnterprise = null;

  monoOffer = false;
  discoverOffer = false;
  connectedAs = false;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private allEnterprises: AllEnterprisesComponent,
    private enterprisesService: EnterprisesRequestService,
    private usersService: UsersRequestService
  ) {}

  ngOnInit() {
    this.enterpriseSiretSearchForm = this.formBuilder.group({
      siret: this.siret,
    });
    this.enterpriseSiretForm = this.formBuilder.group({
      name: this.nameSiret,
      siret: this.siretSiret,
      qualification: this.qualificationSiret,
      access_to_enterprise: this.access_to_enterpriseSiret,
    });

    this.enterpriseSearchByEmailForm = this.formBuilder.group({
      email: this.email,
    });
    this.userEmailForm = this.formBuilder.group({
      id: this.id,
      email: this.emailUser,
      firstname: this.firstname,
      lastname: this.lastname,
      siret: this.siretSearchMail,
    });
    this.enterpriseEmailForm = this.formBuilder.group({
      name: this.nameEmail,
      siret: this.siretEmail,
      qualification: this.qualificationEmail,
      access_to_enterprise: this.access_to_enterpriseEmail,
    });

    this.connectedAs = JSON.parse(localStorage.getItem('connectedAs'));
    if (this.connectedAs) {
      this.monoOffer = JSON.parse(localStorage.getItem('connectedAsIsMonoOffer'));
      this.discoverOffer = JSON.parse(localStorage.getItem('connectedAsIsDiscoverOffer'));
    } else {
      this.monoOffer = JSON.parse(localStorage.getItem('isMonoOffer'));
      this.discoverOffer = JSON.parse(localStorage.getItem('isDiscoverOffer'));
    }
  }

  ngAfterViewInit() {
    this.modalEnterprise.open();
  }

  ngOnDestroy() {
    this.modalEnterprise.close();
  }

  getErrorMessage(param) {
    return (this.errors[param] && this.errors[param]['message']);
  }

  changeTabs(activeTab) {
    this.activeTab = activeTab;
  }

  checkSiret() {
    this.loaderSearch = true;
    this.existSiret = null;
    this.errors = {};

    this.enterprisesService.checkSiret(this.enterpriseSiretSearchForm.value.siret.trim()).subscribe(response => {
        if (response['success']) {
          this.existSiret = response['exist'];
          if (this.existSiret) {
            this.enterpriseSiret = response['enterprise'];
          } else {
            this.enterpriseSiretForm.patchValue({siret: this.enterpriseSiretSearchForm.value.siret});
          }
        } else if (response['errors']) {
          this.errors = response['errors'];
          CustomNotifierService.getErrorsForm(this.errors, this.enterpriseSiretSearchForm, this.notifier);
        }
        this.loaderSearch = false;
      });
  }

  createBySiret() {
    this.loaderSearch = true;
    const enterprise = {
      name: this.enterpriseSiretForm.value.name,
      siret: this.siretSiret.value,
      qualification: this.qualificationSiret.value,
      access_to_enterprise: this.access_to_enterpriseSiret.value
    };

    this.enterprisesService.createProvider(enterprise).subscribe(response => {
      if (response['success']) {
        this.enterprise_id = response['enterprise']['id'];
        this.modalEnterprise.close();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.enterpriseSiretForm, this.notifier);
        this.loaderSearch = false;
      }
      this.loaderSearch = false;
    });
  }

  addBySiret() {
    this.loaderSearch = true;

    const enterprise = {
      qualification: this.qualification,
      access_to_enterprise: this.access_to_enterprise,
    };
    this.enterprisesService.addProvider(this.enterpriseSiret['id'], enterprise).subscribe(response => {
      if (response['success']) {
        this.enterprise_id = this.enterpriseSiret['id'];
        this.modalEnterprise.close();
        CustomNotifierService.getSuccessCreate(this.notifier);
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.enterpriseSiretSearchForm, this.notifier);
      }
      this.loaderSearch = false;
    });
  }

  checkEmail() {
    this.loaderSearch = true;
    this.existEmail1 = null;
    this.existEmail2 = null;
    this.enterpriseEmail = {};
    this.errors = {};

    this.enterprisesService.checkEmail(this.enterpriseSearchByEmailForm.value.email.trim()).subscribe(response => {
      if (response['success']) {
        this.existEmail1 = response['exist'];
        this.userEmailForm.reset();
        if (this.existEmail1) {
          this.userEmailForm.patchValue(response['user']);
          this.userEmailForm.controls['firstname'].disable();
          this.userEmailForm.controls['lastname'].disable();
        } else {
          this.userEmailForm.patchValue({email: this.enterpriseSearchByEmailForm.value.email});
          this.userEmailForm.enable();
        }
        this.userEmailForm.controls['email'].disable();
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.enterpriseSearchByEmailForm, this.notifier);
      }
      this.loaderSearch = false;
    });
  }

  checkSiretByEmail() {
    this.loaderSearch = true;
    this.existEmail2 = null;
    this.errors = {};

    this.enterprisesService.checkSiret(this.userEmailForm.value.siret.trim()).subscribe(response => {
      if (response['success']) {
        this.existEmail2 = response['exist'];
        if (this.existEmail2) {
          this.enterpriseEmail = response['enterprise'];
        } else {
          this.enterpriseEmailForm.patchValue({siret: this.userEmailForm.value.siret});
        }
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.userEmailForm, this.notifier);
      }
      this.loaderSearch = false;
    });
  }

  createByEmail() {
    this.loaderSearch = true;
    const enterprise = {
      name: this.enterpriseEmailForm.value.name,
      siret: this.siretEmail.value,
      qualification: this.enterpriseEmailForm.value.qualification
    };

    this.enterprisesService.createProvider(enterprise).subscribe(response => {
      if (response['success']) {
        this.enterprise_id = response['enterprise']['id'];
        this.enterpriseEmail = response['enterprise'];
        this.existEmail2 = true;
        if (this.existEmail1) {
          this.addUser(response['enterprise']['id']);
        } else {
          this.createUser(response['enterprise']['id']);
        }
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.userEmailForm, this.notifier);
        this.loaderSearch = false;
      }
      this.loaderSearch = false;
    });
  }

  addByEmail() {
    this.loaderSearch = true;

    this.enterprisesService.addProvider(this.enterpriseEmail['id'], this.qualification).subscribe(response => {
      if (response['success']) {
        this.enterprise_id = this.enterpriseEmail['id'];
        if (this.existEmail1) {
          this.addUser(this.enterpriseEmail['id']);
        } else {
          this.createUser(this.enterpriseEmail['id']);
        }
        this.loaderSearch = false;
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.enterpriseSearchByEmailForm, this.notifier);
        this.loaderSearch = false;
      }
    });
  }

  createUser(enterprise_id) {
    this.loaderSearch = true;
    const user = {
      firstname: this.userEmailForm.value.firstname,
      lastname: this.userEmailForm.value.lastname,
      email: this.emailUser.value,
      enterprise_id: enterprise_id
    };

    this.usersService.createProvider(enterprise_id, user).subscribe(response => {
      if (response['success']) {
        this.modalEnterprise.close();
        CustomNotifierService.getSuccessCreate(this.notifier);
        this.loaderSearch = false;
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.userEmailForm, this.notifier);
        this.loaderSearch = false;
      }
      this.loaderSearch = false;
    });
  }

  addUser(enterprise_id) {
    this.usersService.addProvider(enterprise_id, this.userEmailForm.value.id).subscribe(response => {
      if (response['success']) {
        this.modalEnterprise.close();
        CustomNotifierService.getSuccessCreate(this.notifier);
        this.loaderSearch = false;
      } else if (response['errors']) {
        this.errors = response['errors'];
        CustomNotifierService.getErrorsForm(this.errors, this.userEmailForm, this.notifier);
        this.loaderSearch = false;
      }
      this.loaderSearch = false;
    });
  }

  bindModal(modal) {
    this.modalEnterprise = modal;
  }

  closeModal() {
    this.allEnterprises.getEnterprises();
    if (this.activatedRoute.snapshot.parent.url[0]) {
      if (this.enterprise_id) {
        this.router.navigate([this.activatedRoute.snapshot.parent.url[0].path + '/' + this.enterprise_id + '/contracts/create'])
          .then(() => {});
      } else {
        this.router.navigate([this.activatedRoute.snapshot.parent.url[0].path ])
          .then(() => {});
      }
    } else {
      const url = this.authService.checkUrlStats(JSON.parse(localStorage.getItem('registries')));
      this.router.navigate([url]).then(() => {});
    }
  }
}
