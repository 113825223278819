<div class="main-content">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col">
        <div class="header mb-0">
          <div class="header-body no-border">
            <div class="row align-items-center">
              <div class="col">
                <h1 class="header-title">
                  Points de contrôle
                </h1>

                <small class="form-text text-muted">
                  <span>Liste des points de contrôle</span>
                </small>

                <app-breadcrumbs [currentPage]="pageName" [names]="previousPageName" [routes]="previousPageRoute"></app-breadcrumbs>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col">
            <app-searchbar [(ngModel)]="search" (keyup)="searchData()" ngDefaultControl></app-searchbar>
          </div>
        </div>

        <div class="is-loading loader-content" *ngIf="loader"></div>

        <div class="tab-content" *ngIf="!loader && checkpoints.length">
          <div class="tab-pane fade active show" id="tabPaneOne" role="tabpanel">
            <div class="row listAlias">
              <div class="col-12 d-flex flex-column">
                <div class="card flex-fill">
                  <div class="card-body">
                    <div class="table-responsive">
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th class="href" (click)="filter('Checkpoints.name')">
                              <a class="text-muted">Nom</a>
                              <span class="fe fe-arrow-down" *ngIf="sort === 'Checkpoints.name' && order === 'asc'"></span>
                              <span class="fe fe-arrow-up" *ngIf="sort === 'Checkpoints.name' && order === 'desc'"></span>
                              <span class="fe fe-arrow-right" *ngIf="sort !== 'Checkpoints.name'"></span>
                            </th>
                            <th>Description</th>
                            <th>Rapports</th>
                            <th>Type</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="dropdown" *ngFor="let checkpoint of checkpoints">
                            <td>
                              <a class="href" [routerLink]="[currentRoute + '/' + checkpoint.id + '/reports']">
                                {{ checkpoint.full_name }}
                              </a>
                            </td>
                            <td>{{ checkpoint.description }}</td>
                            <td>
                              <div class="btn-group">
                                <a class="btn btn-sm btn-white" [routerLink]="[currentRoute + '/' + checkpoint.id + '/reports']">
                                  {{ checkpoint.count_reports + ' rapport(s)'}}
                                </a>
                              </div>
                            </td>
                            <td><span class="label" [ngClass]="{'label-danger': getFrequencyRequired(checkpoint.frequencies), 'label-success': !getFrequencyRequired(checkpoint.frequencies) }">{{ getFrequencyRequired(checkpoint.frequencies) ? 'Imposé' : 'Recommandé' }}</span></td>
                            <td class="d-flex flex-row-reverse">
                              <a class="dropdown-ellipses dropdown-toggle href" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fe fe-more-vertical"></i>
                              </a>
                              <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item href" [routerLink]="[currentRoute + '/' + checkpoint.id + '/reports/create']" *ngIf="checkpoint['canCreateReport']">
                                  Ajouter un rapport de contrôle
                                </a>
                                <a class="dropdown-item href" [routerLink]="[currentRoute + '/' + checkpoint.id + '/reports']">
                                  Consulter les rapports
                                </a>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <app-pagination (returnEvent)="dispatchPagination($event)"
                                    [count_elements]="count"
                                    [current_page]="page"
                                    [elements_length]="checkpoints.length"
                                    [limit]="limit"
                                    [total_pages]="pages"
                                    ngDefaultControl>
                    </app-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="loader-content" *ngIf="!loader && !checkpoints.length">
          <p>Aucun résultat</p>
        </div>
      </div>
    </div>
  </div>
</div>

<router-outlet></router-outlet>
