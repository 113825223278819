<app-modal-right (eventOnClose)="closeModal()" #temperature>{{ bindModal(temperature) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">{{ isNew ? 'Création' : canUpdate ? 'Mise à jour' : 'Consultation' }}</h6>

        <h1 class="header-title">Autres analyses</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <form [formGroup]="sanitaryAnalyzeForm" [hidden]="loaderContent">
      <!-- ID / NAME -->
      <div class="form-group">
        <label for="validationTitle" required>Identification</label>
        <input type="text" formControlName="name" class="form-control" id="validationTitle" placeholder="Identification" [ngClass]="{ 'is-invalid': name.invalid && (name.dirty || name.touched) }">
      </div>
      <!-- INSTALLATION -->
      <div class="form-group">
        <label required>Installation</label>
        <ng-select [items]="sanitaryInstallations"
                   [multiple]="false"
                   bindLabel="description"
                   notFoundText="Aucun résultat"
                   [selectableGroup]="true"
                   [closeOnSelect]="true"
                   bindValue="id"
                   placeholder="Sélectionnez une installation"
                   formControlName="sanitary_installation_id">
          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">{{ item.description }}</ng-template>
        </ng-select>
      </div>

      <!-- PERIODICITY -->
      <div class="form-group">
        <label required>Période</label>
        <ng-select [items]="periods"
                   [multiple]="false"
                   bindLabel="name"
                   notFoundText="Aucun résultat"
                   [selectableGroup]="true"
                   [closeOnSelect]="true"
                   bindValue="name"
                   placeholder="Sélectionnez une période"
                   formControlName="periodicity">
          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">{{ item.name }}</ng-template>
        </ng-select>
      </div>

      <div class="form-group" formArrayName="descriptions">
        <div [formGroupName]="indexDescription" [attr.id]="'description' + indexDescription" *ngFor="let description of sanitaryAnalyzeForm['controls'].descriptions['controls'] as FormGroup; let indexDescription = index;">
          <div class="card">

            <div class="card-header">
              <div class="d-flex align-items-center">
                <i data-toggle="collapse" [attr.href]="'#indexDescription' + indexDescription" aria-expanded="true" class="fe chevron mr-2 href collapsed"></i>
                <input type="text" id="validationTitleDescription" formControlName="title"  class="form-control" placeholder="Titre"
                       [ngClass]="{ 'is-invalid': description['controls'].title.invalid && (description['controls'].title.dirty || description['controls'].title.touched) }">
                <div *ngIf="description['controls'].title.invalid && (description['controls'].title.dirty || description['controls'].title.touched)" class="invalid-feedback">
                  {{ getErrorMessage("title") }}
                </div>
                <a class="href tooltipHtml col-2 d-flex align-items-center justify-content-center" data-placement="bottom" title="Supprimer" (click)="deleteDescription(indexDescription)">
                  <i class="fe fe-trash"></i>
                </a>
              </div>
            </div>

            <div class="card-body collapse" [id]="'indexDescription' + indexDescription" [attr.id]="'indexDescription' + indexDescription" role="tabpanel" [attr.data-parent]="'#indexDescription' + indexDescription">

              <div class="form-group">
                <label for="validationPointList">Liste des points et leur localisation (étage, n°de chambre, lavabo, douche….)</label>
                <div class="form-group" formArrayName="pointLists">
                  <div [formGroupName]="indexPointList" *ngFor="let pointList of description['controls'].pointLists['controls'] as FormGroup; let indexPointList = index;">
                    <div class="d-flex align-items-center">
                      <input type="text" id="validationPointList" formControlName="value"  class="form-control mb-2" placeholder="Point de contrôle"
                             [ngClass]="{ 'is-invalid': pointList['controls'].value.invalid && (pointList['controls'].value.dirty || pointList['controls'].value.touched) }">
                      <div *ngIf="pointList['controls'].value.invalid && (pointList['controls'].value.dirty || pointList['controls'].value.touched)" class="invalid-feedback">
                        {{ getErrorMessage("title") }}
                      </div>
                      <a class="href tooltipHtml col-2 d-flex align-items-center justify-content-center" data-placement="bottom" title="Supprimer" (click)="deletePointList(indexDescription, indexPointList)">
                        <i class="fe fe-trash"></i>
                      </a>
                    </div>
                  </div>
                  <button class="btn btn-primary col-6 offset-3 mt-2" (click)="addNewPointList(indexDescription)">
                    Ajouter un point et sa localisation
                  </button>
                </div>
              </div>

              <!-- RECOMMENDATION -->
              <div class="form-group">
                <label for="validationRecommendation">Préconisation</label>
                <textarea formControlName="recommendation" class="form-control" id="validationRecommendation" placeholder="Préconisation " rows="5"
                          [ngClass]="{ 'is-invalid': description['controls'].recommendation.invalid && (description['controls'].recommendation.dirty || description['controls'].recommendation.touched) }"></textarea>
              </div>

            </div>

          </div>
        </div>
        <button class="btn btn-primary col-6 offset-3 mt-2" (click)="addNewDescription()">
          Ajouter une identification
        </button>
      </div>

      <!-- DOCUMENT -->
      <h2 class="header-title mb-3">Documents</h2>
      <div class="form-group" *ngIf="isNew || canUpdate">
        <div class="dropzone dropzone-multiple dz-clickable" appDragDrop (dropHandler)="onFileChange($event)">
          <div class="dz-default dz-message" (click)="chooseFile()">
            <span>Déposer vos fichiers ici pour télécharger</span>
          </div>
          <input id="inputFile" (change)="onFileChange($event)" type="file" [hidden]="true" multiple>
        </div>
      </div>
      <div class="card" *ngFor="let file of files; let index = index">
        <div class="card-body">
          <div class="d-flex align-content-center justify-content-between">
            <h4 class="card-title mb-0 name align-self-center">
              {{ file.name + '.' + file.extension }}
            </h4>

            <a class="href tooltipHtml" data-placement="bottom" title="Supprimer" (click)="removeFile(index)" *ngIf="isNew || canUpdate">
              <i class="fe fe-trash"></i>
            </a>
          </div>
        </div>
      </div>

    </form>
  </div>

  <div modal-footer class="w-100" *ngIf="isNew || canUpdate">
    <button class="btn btn-primary w-100" (click)="isNew ? createSanitaryAnalyze() : modalUpdateFrame.open(sanitaryAnalyzeForm)" [ngClass]="{ 'is-loading is-loading-sm': loader }" [disabled]="loader || sanitaryAnalyzeForm.invalid">
      {{ isNew ? 'Créer' : 'Mettre à jour' }}
    </button>
  </div>
</app-modal-right>

<app-confirm-modal [type]="'update_frame'" #updateFrame>{{ bindModalUpdateFrame(updateFrame) }}</app-confirm-modal>
