import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { AuthService } from '../../../core/auth/auth.service';
import { HttpClient } from '@angular/common/http';

import { VideosRequestService } from '../../../services/videos.request.service';
import { CustomNotifierService } from '../../../services/custom.notifier.service';
import { ObservationsRequestService } from '../../../services/observations.request.service';

import Observation from '../../../../shared/interfaces/observation';
import BatiFile from '../../../../shared/interfaces/bati_file';

import { VideoModalComponent } from '../../../core/video-modal/video-modal.component';
import { PdfViewerComponent } from '../../../core/pdf-viewer/pdf-viewer.component';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-all-customer-observations',
  templateUrl: './all-customer-observations.component.html',
  styleUrls: ['./all-customer-observations.component.scss']
})
export class AllCustomerObservationsComponent implements OnInit, AfterViewInit, OnDestroy {
  changeEnterprise: Subscription;
  canCreate = false;
  canUpdate = false;
  canObservation = false;

  pageName: string;
  previousPageName: string[] = [];
  previousPageRoute: string[] = [];

  currentRoute = '';
  view = '';

  observations: Observation[] = [];
  files: BatiFile[] = [];

  count = 0;
  pages = 1;

  search = '';
  sort = '';
  order = 'asc';
  limit = 12;
  page = 1;

  timeout: any;
  loader = false;

  modalConfirmFile = null;
  modalVideo: VideoModalComponent = null;
  modalPdf: PdfViewerComponent = null;

  constructor(
    private http: HttpClient,
    private notifier: NotifierService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private videosService: VideosRequestService,
    private observationsService: ObservationsRequestService
  ) {}

  ngOnInit() {
    this.changeEnterprise = this.authService.getEnterprise().subscribe(() => {
      this.getObservations();
      this.createBreadcrumbs();
    });

    this.currentRoute = '/' + this.activatedRoute.snapshot.url.join('/') + '/';

    if (this.activatedRoute.snapshot.params.observation_id) {
      this.view = 'observation_files';
      this.sort = 'ObservationFiles.name';
      this.pageName = 'Documents';
    } else {
      this.view = 'observations';
      this.sort = 'Observations.emergencies';
      this.order = 'desc';
      this.pageName = 'Observations';
    }
    if (this.authService.loaded) {
      this.getObservations();
      this.createBreadcrumbs();
    }
  }

  ngAfterViewInit() {
    this.modalConfirmFile.eventOnClose.subscribe(file_id => {
      if (file_id) {
        let request;

        request = this.observationsService.deleteCustomerFile(
          this.activatedRoute.snapshot.params.enterprise_id,
          this.activatedRoute.snapshot.params.establishment_id,
          this.activatedRoute.snapshot.params.registry_id,
          this.activatedRoute.snapshot.params.installation_id,
          this.activatedRoute.snapshot.params.checkpoint_id,
          this.activatedRoute.snapshot.params.report_id,
          this.activatedRoute.snapshot.params.observation_id,
          file_id
        );

        request.subscribe(response => {
          if (response['success']) {
            this.refreshData();
            CustomNotifierService.getSuccessDelete(this.notifier);
          } else if (response['errors']) {
            CustomNotifierService.getErrors(response['errors'], this.notifier);
          }
        });
      }
    });
  }

  ngOnDestroy() {
    this.changeEnterprise.unsubscribe();
  }

  searchData() {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.page = 1;
      this.refreshData();
    }, 500);
  }

  refreshData(sort = this.sort, order = this.order, page = this.page) {
    this.sort = sort;
    this.order = order;
    this.page = page;
    this.getObservations();
  }

  getObservations() {
    this.loader = true;
    let request;

    if (this.view === 'observation_files') {
      request = this.observationsService.getCustomerFiles(
        this.search, this.sort, this.order, this.limit, this.page,
        this.activatedRoute.snapshot.params.enterprise_id,
        this.activatedRoute.snapshot.params.establishment_id,
        this.activatedRoute.snapshot.params.registry_id,
        this.activatedRoute.snapshot.params.installation_id,
        this.activatedRoute.snapshot.params.checkpoint_id,
        this.activatedRoute.snapshot.params.report_id,
        this.activatedRoute.snapshot.params.observation_id
      );
    } else {
      request = this.observationsService.getCustomerAllObservations(
        this.search, this.sort, this.order, this.limit, this.page,
        this.activatedRoute.snapshot.params.enterprise_id,
        this.activatedRoute.snapshot.params.establishment_id,
        this.activatedRoute.snapshot.params.registry_id,
        this.activatedRoute.snapshot.params.installation_id,
        this.activatedRoute.snapshot.params.checkpoint_id,
        this.activatedRoute.snapshot.params.report_id
      );
    }

    request.subscribe(response => {
      if (response['success']) {
        this.canUpdate = response['canCreateObservation'];
        this.canCreate = response['canCreateObservation'];
        this.canObservation = response['canStateObservation'];

        if (response['observations']) {
          this.observations = response['observations'];
        } else {
          this.files = response['files'];
        }

        this.count = response['count'];
        this.pages = response['pages'];
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
      this.loader = false;
    });
  }

  getEmergencies(observation) {
    if (observation.emergencies === 0) {
      return {color: 'visit-low', value: 'Bas'};
    } else if (observation.emergencies === 1) {
      return {color: 'visit-normal', value: 'Normal'};
    } else if (observation.emergencies === 2) {
      return {color: 'visit-high', value: 'Haut'};
    } else if (observation.emergencies === 3) {
      return {color: 'visit-priority', value: 'Prioritaire'};
    }
  }

  downloadFile(file_id, file_name) {
    let request;

    request = this.observationsService.downloadCustomerFile(
      this.activatedRoute.snapshot.params.enterprise_id,
      this.activatedRoute.snapshot.params.establishment_id,
      this.activatedRoute.snapshot.params.registry_id,
      this.activatedRoute.snapshot.params.installation_id,
      this.activatedRoute.snapshot.params.checkpoint_id,
      this.activatedRoute.snapshot.params.report_id,
      this.activatedRoute.snapshot.params.observation_id,
      file_id
    );

    request.subscribe((response: BufferSource) => {
      try {
        const decoder = new TextDecoder('utf-8');
        const domString = decoder.decode(response);
        const json = JSON.parse(domString);

        CustomNotifierService.getErrors(json['errors'], this.notifier);
      } catch (e) {
        const blob = new Blob([response]);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = file_name;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      }
    });
  }

  filter(property) {
    if (this.sort === property) {
      if (this.order === 'asc') {
        this.order = 'desc';
      } else {
        this.order = 'asc';
      }
    } else {
      this.sort = property;
      this.order = 'asc';
    }

    this.getObservations();
  }

  createBreadcrumbs() {
    this.previousPageName = [];
    this.previousPageRoute = [];

    this.previousPageName.push('Tableau de bord');
    this.previousPageRoute.push('/');

    this.previousPageName.push('Mes clients');
    this.previousPageRoute.push('/customers');

    this.previousPageName.push('Etablissements');
    this.previousPageRoute.push(
      '/customers/' + this.activatedRoute.snapshot.params.enterprise_id + '/establishments'
    );

    this.previousPageName.push('Registres');
    this.previousPageRoute.push(
      '/customers/' + this.activatedRoute.snapshot.params.enterprise_id +
      '/establishments/' + this.activatedRoute.snapshot.params.establishment_id +
      '/registries'
    );

    this.previousPageName.push('Installations');
    this.previousPageRoute.push(
      '/customers/' + this.activatedRoute.snapshot.params.enterprise_id +
      '/establishments/' + this.activatedRoute.snapshot.params.establishment_id +
      '/registries/' + this.activatedRoute.snapshot.params.registry_id +
      '/installations'
    );

    this.previousPageName.push('Points de contrôle');
    this.previousPageRoute.push(
      '/customers/' + this.activatedRoute.snapshot.params.enterprise_id +
      '/establishments/' + this.activatedRoute.snapshot.params.establishment_id +
      '/registries/' + this.activatedRoute.snapshot.params.registry_id +
      '/installations/' + this.activatedRoute.snapshot.params.installation_id +
      '/checkpoints'
    );

    this.previousPageName.push('Rapports de contrôle');
    this.previousPageRoute.push(
      '/customers/' + this.activatedRoute.snapshot.params.enterprise_id +
      '/establishments/' + this.activatedRoute.snapshot.params.establishment_id +
      '/registries/' + this.activatedRoute.snapshot.params.registry_id +
      '/installations/' + this.activatedRoute.snapshot.params.installation_id +
      '/checkpoints/' + this.activatedRoute.snapshot.params.checkpoint_id +
      '/reports'
    );

    if (this.view === 'observation_files') {

      this.previousPageName.push('Observations');
      this.previousPageRoute.push(
        '/customers/' + this.activatedRoute.snapshot.params.enterprise_id +
        '/establishments/' + this.activatedRoute.snapshot.params.establishment_id +
        '/registries/' + this.activatedRoute.snapshot.params.registry_id +
        '/installations/' + this.activatedRoute.snapshot.params.installation_id +
        '/checkpoints/' + this.activatedRoute.snapshot.params.checkpoint_id +
        '/reports/' + this.activatedRoute.snapshot.params.report_id +
        '/observations'
      );
    }
  }

  dispatchPagination(data) {
    if (data.action === 'total_elements') {
      this.refreshPage(data.value);
    } else if (data.action === 'change_page') {
      this.changePage(data.value);
    }
  }

  refreshPage(limit) {
    this.limit = limit;
    this.page = 1;
    this.refreshData();
  }

  changePage(page) {
    if (page >= 1 && page <= this.pages) {
      this.page = page;
      this.getObservations();
    }
  }

  bindModalConfirmFile(modal) {
    this.modalConfirmFile = modal;
  }

  /**
   * Download one file
   *
   * @param file_id The file
   */
  showVideo(file_id) {
    this.videosService.getFile(file_id).subscribe(response => {
      if (response['success']) {
        const url = environment.API_PATH + 'download-video/' + response['file']['token'];
        this.modalVideo.open(url);
      }
    });
  }
  /**
   * Show Pdf
   *
   * @param file_url The file
   */
  showPdf(file_url) {
    const url = environment.API_PATH + 'file/ObservationFiles/' + file_url;
    this.http.get(url + '/isExist').subscribe(response => {
      if (response['success']) {
        this.modalPdf.open(url);
      } else {
        CustomNotifierService.getErrors(response['errors'], this.notifier);
      }
    });
  }
  getImage(extension) {
    if (extension === 'mp4') {
      return '../assets/img/files/mp4.png';
    } else if (extension === 'pdf') {
      return '../assets/img/files/pdf.png';
    } else if (extension === 'xlsx' || extension === 'csv') {
      return '../assets/img/files/xls.png';
    } else if (extension === 'doc' || extension === 'docx') {
      return '../assets/img/files/doc.png';
    } else if (extension === 'jpeg' || extension === 'jpg') {
      return '../assets/img/files/jpg.png';
    } else if (extension === 'png') {
      return '../assets/img/files/png.png';
    } else {
      return '../assets/img/files/file_icon.png';
    }
  }
  bindModalVideo(modal) {
    this.modalVideo = modal;
  }
  bindModalPdf(modal) {
    this.modalPdf = modal;
  }
}
