<app-modal-right (eventOnClose)="closeModal()" #installation>{{ binModal(installation) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle">{{ isNew ? 'Création' : canUpdate ? 'Mise à jour' : 'Consultation' }}</h6>

        <h1 class="header-title">Installation</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <form [formGroup]="installationForm" *ngIf="!loaderContent">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="validationInstallationName" required>Nom</label>
            <input type="text" id="validationInstallationName" formControlName="name" class="form-control" placeholder="Nom"
              [ngClass]="{ 'is-invalid': name.invalid && (name.dirty || name.touched) }">
            <div *ngIf="name.invalid && (name.dirty || name.touched)" class="invalid-feedback">
              {{ getErrorMessage("name") }}
            </div>
          </div>

          <div class="form-group" *ngIf="installations">
            <label for="validationInstallationDescription" required>Description</label>
            <input type="text" id="validationInstallationDescription" formControlName="description" class="form-control" placeholder="Description">
            <div *ngIf="description.invalid && (description.dirty || description.touched)" class="invalid-feedback">
              {{ getErrorMessage("description") }}
            </div>
          </div>

          <!-- INSTALLATION -->
          <div class="form-group" *ngIf="installations">
            <label for="validationInstallation" required>Installation</label>
            <select class="form-control" data-toggle="select" formControlName="installation_id" id="validationInstallation"
                    [ngClass]="{ 'is-invalid': installation_id.invalid && (installation_id.dirty || installation_id.touched) }">
              <option value="" [selected]="!installation_id.value">Sélectionnez une installation</option>
              <ng-container *ngFor="let installation of installations">
                <option [value]="installation.id">{{ installation.name }}</option>
              </ng-container>
            </select>
            <div *ngIf="installation_id.invalid && (installation_id.dirty || installation_id.touched)" class="invalid-feedback">
              {{ getErrorMessage("installation_id") }}
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div modal-footer class="w-100" *ngIf="isNew || canUpdate">
    <button class="btn btn-primary w-100" (click)="isNew ? createInstallation() : updateInstallation()"
            [ngClass]="{ 'is-loading is-loading-sm': loader }" [disabled]="loader || installationForm.invalid">
      {{ isNew ? 'Créer' : 'Mettre à jour' }}
    </button>
  </div>
</app-modal-right>
