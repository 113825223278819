import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AllExportsModule } from './all-exports/all-exports.module';

@NgModule({
  imports: [
    CommonModule,
    AllExportsModule,
  ],
  providers: []
})

export class AdminExportsModule {}
