<app-modal-right (eventOnClose)="closeModal()" #content>{{ binModal(content) }}
  <div modal-header>
    <div class="row align-items-center">
      <div class="col">
        <h6 class="header-pretitle" *ngIf="view === 'admin'">
          {{ isNew ? 'Créer un tooltip' : canUpdate ? 'Mettre à jour un tooltip' : 'Consulter un tooltip' }}
        </h6>
        <h6 class="header-pretitle" *ngIf="view === 'commissions'">
          Consulter un tooltip
        </h6>

        <h1 class="header-title">Tooltip</h1>
      </div>
    </div>
  </div>

  <div modal-content class="h-100">
    <div class="is-loading loader-modal-content" *ngIf="loaderContent"></div>

    <form [formGroup]="contentForm" [hidden]="loaderContent" *ngIf="view === 'admin'">
      <div class="row">

        <div class="col-12">
          <div class="form-group">
            <label for="validationTitle" required>Title</label>
            <input type="text" formControlName="title" id="validationTitle" class="form-control" placeholder="Titre" maxlength="255"
                   [ngClass]="{ 'is-invalid': title.invalid && (title.dirty || title.touched) }">
            <div *ngIf="title.invalid && (title.dirty || title.touched)" class="invalid-feedback">
              {{ getErrorMessage("title") }}
            </div>
          </div>

          <div class="form-group">
            <label for="validationShortDescription">Infobulle</label>
            <input type="text" formControlName="short_description" id="validationShortDescription" class="form-control" placeholder="Infobulle" maxlength="255"
                   [ngClass]="{ 'is-invalid': short_description.invalid && (short_description.dirty || short_description.touched) }">
            <div *ngIf="short_description.invalid && (short_description.dirty || short_description.touched)" class="invalid-feedback">
              {{ getErrorMessage("short_description") }}
            </div>
          </div>

          <div class="form-group">
            <label>Description</label>
            <editor initialValue=""
                    formControlName="description"
                    [init]="{ height: 500, menubar: false, plugins: ['link preview table code lists'],
                                toolbar:'undo redo | formatselect fontselect fontsizeselect | ' +
                                'bold italic underline strikethrough backcolor forecolor | preview | ' +
                                'alignleft aligncenter alignright alignjustify | ' +
                                'bullist numlist outdent indent link table'
                              }">
            </editor>
            <div *ngIf="description.invalid && (description.dirty || description.touched)" class="invalid-feedback">
              {{ getErrorMessage("description") }}
            </div>
          </div>
        </div>

      </div>
    </form>

    <form [formGroup]="contentForm" [hidden]="loaderContent" *ngIf="view === 'commissions' && showDescription">
      <div class="row">

        <div class="col-12">
          <div class="card bg-light border">
            <div class="card-body">
              <div class="is-loading loader-content" *ngIf="loader"></div>

              <div class="bandeau" *ngIf="!loader">
                <div>
                  <span [innerHTML]="showDescription"></span>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </form>
  </div>

  <div modal-footer class="w-100" *ngIf="(isNew || canUpdate) && view === 'admin'">
    <button class="btn btn-primary w-100" (click)="isNew ? createContent() : updateContent()" [ngClass]="{ 'is-loading is-loading-sm': loader }" [disabled]="loader || contentForm.invalid">
      {{ isNew ? 'Créer' : 'Mettre à jour'}}
    </button>
  </div>
</app-modal-right>
