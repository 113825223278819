import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  loader = false;
  loaderChange = new Subject<boolean>();

  connectedAs = false;

  constructor(
    private authService: AuthService,
    private router: Router
  ) {
    this.loaderChange.subscribe((value) => {
      this.loader = value;
    });
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    this.loaderChange.next(true);

    if (state.url.match('/auth/')) {
      this.loaderChange.next(false);
      return true;
    } else if (state.url === '/') {
      if (localStorage.getItem('accessToken')) {
        setTimeout(() => {
          this.loaderChange.next(false);
        }, 1000);
        this.authService.isDashboardRedirect = true;
        return true;
      } else {
        this.router.navigate(['/auth/login']).then(() => {});
        setTimeout(() => {
          this.loaderChange.next(false);
        }, 1000);
        return false;
      }
    } else if (state.url === '/dashboard-commissions' || state.url === '/dashboard-asbestos' ||
      state.url === '/dashboard-unique-document') {
      this.connectedAs = JSON.parse(localStorage.getItem('connectedAs'));
      if (this.connectedAs) {
        if (localStorage.getItem('connectedAsIsMonoOffer')) {
          this.router.navigate(['/dashboard-observations']).then(() => {});
          setTimeout(() => {
            this.loaderChange.next(false);
          }, 1000);
          return false;
        }
      } else {
        if (localStorage.getItem('connectedAsIsMonoOffer')) {
          this.router.navigate(['/dashboard-observations']).then(() => {});
          setTimeout(() => {
            this.loaderChange.next(false);
          }, 1000);
          return false;
        }
      }
    } else {
      if (localStorage.getItem('accessToken')) {
        setTimeout(() => {
          this.loaderChange.next(false);
        }, 1000);
        return true;
      } else {
        this.router.navigate(['/auth/login']).then(() => {});
        setTimeout(() => {
          this.loaderChange.next(false);
        }, 1000);
        return false;
      }
    }
  }
}
