<div class="main-content">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col">
        <div class="header mb-0">
          <div class="header-body no-border">
            <div class="row align-items-center">
              <div class="col">
                <h1 class="header-title">
                  {{ view === 'dates_training' ? 'Dates de formation' : 'Formations' }}
                </h1>
                <small class="form-text text-muted">
                  <span>{{ training_name ? training_name.toLowerCase() : 'Liste des formations' }}</span>
                </small>
                <app-breadcrumbs [names]="previousPageName" [routes]="previousPageRoute"
                  [currentPage]="view === 'dates_training' ? 'Dates de formation' : 'Formations'">
                </app-breadcrumbs>
              </div>

              <ng-container *ngIf="canCreate">
                <!-- DROPDOWN BUTTON FOR USER (NOT DATE TRAINING VIEW) -->
                <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0 dropdown" *ngIf="view === 'user'">
                  <a class="btn btn-primary d-block d-md-inline-block dropdown-toggle" [routerLink]="" role="button" [attr.aria-label]="'Sous menu pour les actions relatives aux formations'"
                     id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Actions
                  </a>
                  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
                    <a class="href dropdown-item" (click)="showHideTrainingsHidden()" [attr.aria-label]="show_inactive ? 'Cacher les formations masquées' : 'Afficher les formations masquées'">
                      {{ show_inactive ? 'Cacher les formations masquées' : 'Afficher les formations masquées'}}
                    </a>
                    <a class="dropdown-item" [routerLink]="['show']">Édition de masse</a>
                    <a class="dropdown-item" [routerLink]="[employeesRoute]">Liste du personnel</a>
                    <a class="dropdown-item" [routerLink]="[createRoute]">Ajouter une formation</a>
                  </div>
                </div>

                <!-- DISPLAY THIS BUTTON WHEN VIEW IS ADMIN -->
                <div class="col-12 col-md-auto mt-2 mt-md-0 mb-0" *ngIf="view === 'admin'">
                  <a class="btn btn-primary d-block d-md-inline-block" [routerLink]="[createRoute]">
                    Ajouter une formation
                  </a>
                </div>

                <!-- DISPLAY THIS BUTTON WHEN VIEW IS DATE TRAININGS -->
                <a class="btn btn-primary d-block d-md-inline-block" [routerLink]="[createRoute]" *ngIf="view === 'dates_training'">
                  Ajouter une date de formation
                </a>
              </ng-container>
            </div>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col">
            <app-searchbar [(ngModel)]="search" (keyup)="searchData()" ngDefaultControl></app-searchbar>
          </div>
        </div>

        <div class="is-loading loader-content" *ngIf="loader"></div>
        <!-- VIEW FOR ADMIN AND / OR ENTERPRISE (TRAININGS) -->
        <div class="tab-content" *ngIf="!loader && trainings.length && (view === 'admin' || view === 'user')">

          <div class="col-12" *ngIf="show_inactive">
            <div class="card bg-light border">
              <div class="card-body">
                <span>Attention, à votre demande, les formations masquées sont temporairement visibles en gris.</span>
              </div>
            </div>
          </div>

          <div class="card flex-fill">
            <div class="card-body">
              <!-- TABLE CONTENT -->
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                  <tr>
                    <th class="href" (click)="filter('Trainings.name')">
                      <a class="text-muted">Libellé</a>
                      <span class="fe fe-arrow-down" *ngIf="sort === 'Trainings.name' && order === 'asc'"></span>
                      <span class="fe fe-arrow-up" *ngIf="sort === 'Trainings.name' && order === 'desc'"></span>
                      <span class="fe fe-arrow-right" *ngIf="sort !== 'Trainings.name'"></span>
                    </th>
                    <th class="href" (click)="filter('Registries.name')" *ngIf="view === 'admin'">
                      <a class="text-muted">Registre</a>
                      <span class="fe fe-arrow-down" *ngIf="sort === 'Registries.name' && order === 'asc'"></span>
                      <span class="fe fe-arrow-up" *ngIf="sort === 'Registries.name' && order === 'desc'"></span>
                      <span class="fe fe-arrow-right" *ngIf="sort !== 'Registries.name'"></span>
                    </th>
                    <th class="href" (click)="filter('Trainings.priority')">
                      <a class="text-muted">Importance</a>
                      <span class="fe fe-arrow-down" *ngIf="sort === 'Trainings.priority' && order === 'asc'"></span>
                      <span class="fe fe-arrow-up" *ngIf="sort === 'Trainings.priority' && order === 'desc'"></span>
                      <span class="fe fe-arrow-right" *ngIf="sort !== 'Trainings.priority'"></span>
                    </th>
                    <!-- Actions button -->
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  <!-- VIEW FOR ADMIN -->
                  <ng-container *ngIf="view === 'admin'">
                    <tr class="dropdown" *ngFor="let training of trainings">
                      <td>
                        <a class="href" [routerLink]="[training.id]" [attr.aria-label]="'Nom de la formation : ' + training.name">
                          {{ training.name }}
                        </a>
                      </td>
                      <td>
                        <a class="href" [attr.aria-label]="'La formation est associée au registre ' + training.registry.name">
                          {{ training.registry.name }}
                        </a>
                      </td>
                      <td>
                          <span class="label label-rounded"
                                [ngClass]="training.priority ? 'label-important' : 'label-not-important'"
                                [attr.aria-label]="training.priority ? 'La formation est marquée comme prioritaire' : 'La formation est marquée comme non prioritaire'">
                            {{ training.priority ? 'Prioritaire' : 'Non prioritaire' }}
                          </span>
                      </td>

                      <td class="d-flex flex-row-reverse">
                        <a class="dropdown-ellipses dropdown-toggle href" role="button" [attr.aria-label]="'Sous menu de la formation ' + training.name" data-toggle="dropdown"
                           aria-haspopup="true" aria-expanded="false">
                          <i class="fe fe-more-vertical"></i>
                        </a>

                        <div class="dropdown-menu dropdown-menu-right">
                          <a class="dropdown-item href" [routerLink]="[updateRoute + training.id]">
                            {{ canUpdate ? 'Éditer la formation' : 'Consulter la formation' }}
                          </a>

                          <a class="dropdown-item href" (click)="modalConfirmData.open(training.id)" *ngIf="canDelete">
                            Supprimer la formation
                          </a>

                          <a class="dropdown-item href" [routerLink]="[updateRoute + training.id + '/logs']" *ngIf="canLog">
                            Historique des actions
                          </a>
                        </div>
                      </td>
                    </tr>
                  </ng-container>

                  <!-- VIEW FOR USER -->
                  <ng-container *ngIf="view === 'user'">
                    <ng-container *ngFor="let training of trainings">
                      <tr class="dropdown">
                        <td>
                          <a class="href" *ngIf="view === 'user'"
                             [ngClass]="{ 'is-inactive': !training.display }"
                             [routerLink]="[training.id + '/dates-training']"
                             [attr.aria-label]="'Nom de la formation : ' + training.name" >
                            {{ training.name }}
                          </a>
                        </td>
                        <td>
                          <span class="label label-rounded"
                                [ngClass]="training.priority ? 'label-important' : 'label-not-important'"
                                [attr.aria-label]="training.priority ? 'La formation est marquée comme prioritaire' : 'La formation est marquée comme non prioritaire'">
                            {{ training.priority ? 'Prioritaire' : 'Non prioritaire' }}
                          </span>
                        </td>

                        <td class="d-flex flex-row-reverse">
                          <a class="dropdown-ellipses dropdown-toggle href" role="button" [attr.aria-label]="'Sous menu de la formation ' + training.name" data-toggle="dropdown"
                             aria-haspopup="true" aria-expanded="false">
                            <i class="fe fe-more-vertical"></i>
                          </a>

                          <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item href" [routerLink]="[training.id + '/dates-training']"
                               *ngIf="view === 'user'">
                              Consulter les dates de la formation
                            </a>

                            <a class="dropdown-item href" [routerLink]="[updateRoute + training.id]">
                              {{ canUpdate ? 'Éditer la formation' : 'Consulter la formation' }}
                            </a>

                            <a class="dropdown-item href" (click)="modalConfirmData.open(training.id)" *ngIf="canDelete && (!training.generic && !training.custom)">
                              Supprimer la formation
                            </a>

                            <a class="dropdown-item href" [routerLink]="[updateRoute + training.id + '/logs']" *ngIf="canLog">
                              Historique des actions
                            </a>
                          </div>
                        </td>
                      </tr>
                    </ng-container>
                  </ng-container>
                  </tbody>
                </table>
              </div>
              <app-pagination (returnEvent)="dispatchPagination($event)"
                              [count_elements]="count"
                              [current_page]="page"
                              [elements_length]="trainings.length"
                              [limit]="limit"
                              [total_pages]="pages"
                              ngDefaultControl>
              </app-pagination>
            </div>
          </div>
        </div>

        <!-- VIEW FOR DATE TRAININGS -->
        <div class="tab-content" *ngIf="!loader && date_trainings.length && (view === 'dates_training')">
          <div class="card flex-fill">
            <div class="card-body">
              <!-- TABLE CONTENT -->
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                  <tr>
                    <th class="href" (click)="filter('date')">
                      <a class="text-muted">Date</a>
                      <span class="fe fe-arrow-down" *ngIf="sort === 'date' && order === 'asc'"></span>
                      <span class="fe fe-arrow-up" *ngIf="sort === 'date' && order === 'desc'"></span>
                      <span class="fe fe-arrow-right" *ngIf="sort !== 'date'"></span>
                    </th>
                    <th><a class="text-muted" aria-label="Participants de la date de formation">Participant(s)</a></th>
                    <th class="href" (click)="filter('duration')">
                      <a class="text-muted">Durée</a>
                      <span class="fe fe-arrow-down" *ngIf="sort === 'duration' && order === 'asc'"></span>
                      <span class="fe fe-arrow-up" *ngIf="sort === 'duration' && order === 'desc'"></span>
                      <span class="fe fe-arrow-right" *ngIf="sort !== 'duration'"></span>
                    </th>
                    <th class="href" (click)="filter('estimated_cost')">
                      <a class="text-muted">Coût estimé</a>
                      <span class="fe fe-arrow-down" *ngIf="sort === 'estimated_cost' && order === 'asc'"></span>
                      <span class="fe fe-arrow-up" *ngIf="sort === 'estimated_cost' && order === 'desc'"></span>
                      <span class="fe fe-arrow-right" *ngIf="sort !== 'estimated_cost'"></span>
                    </th>
                    <th class="href" (click)="filter('real_cost')">
                      <a class="text-muted">Coût réel</a>
                      <span class="fe fe-arrow-down" *ngIf="sort === 'real_cost' && order === 'asc'"></span>
                      <span class="fe fe-arrow-up" *ngIf="sort === 'real_cost' && order === 'desc'"></span>
                      <span class="fe fe-arrow-right" *ngIf="sort !== 'real_cost'"></span>
                    </th>
                    <th><a class="text-muted" aria-label="Documents liés à la date de formation">Document(s)</a></th>
                    <!-- Actions button -->
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr class="dropdown" *ngFor="let date_training of date_trainings">
                    <td>
                      <a class="href" [routerLink]="[updateRoute + date_training.id]">
                        {{ date_training.date | date:'dd/MM/yyyy' }}
                      </a>
                    </td>
                    <td>
                      <a class="btn btn-sm btn-white" [routerLink]="[date_training.id + '/employees']">
                        {{ date_training.date_training_employees.length + (date_training.date_training_employees.length === 1 ? ' participant' : ' participants') }}
                      </a>
                    </td>
                    <td>{{ date_training.duration | date:'HH:mm' : 'UTC' }}</td>
                    <td>{{ date_training.estimated_cost === null ? 'N/A' : date_training.estimated_cost + ' €' }}</td>
                    <td [ngClass]="date_training.estimated_cost > date_training.real_cost ? 'label-success' : 'label-danger'">
                      {{ date_training.real_cost === null ? 'N/A' : date_training.real_cost + ' €' }}
                    </td>
                    <td>
                      <a class="btn btn-sm btn-white" [routerLink]="[date_training.id + '/files']">
                        {{ date_training.date_training_files.length + (date_training.date_training_files.length === 1 ? ' document' : ' documents') }}
                      </a>
                    </td>
                    <td class="d-flex flex-row-reverse">
                      <a class="dropdown-ellipses dropdown-toggle href" role="button" [attr.aria-label]="'Sous menu de la date de formation du ' +  (date_training.date | date:'dd/MM/yyyy')" data-toggle="dropdown"
                         aria-haspopup="true" aria-expanded="false">
                        <i class="fe fe-more-vertical"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item href" [routerLink]="[date_training.id + '/employees']">
                          Consulter le personnel et leurs documents
                        </a>

                        <a class="dropdown-item href" [routerLink]="[updateRoute + date_training.id]">
                          {{ canUpdate ? 'Éditer la date de formation' : 'Consulter la date de formation' }}
                        </a>

                          <a class="dropdown-item href" (click)="modalConfirmData.open(date_training.id)" *ngIf="canDelete && (!training.generic && !training.custom)">
                            Supprimer la date de formation
                          </a>

                          <a class="dropdown-item href" [routerLink]="[updateRoute + date_training.id + '/logs']" *ngIf="canLog">
                            Historique des actions
                          </a>
                        </div>
                      </td>
                    </tr>

                  </tbody>
                </table>
              </div>
              <app-pagination (returnEvent)="dispatchPagination($event)"
                              [count_elements]="count"
                              [current_page]="page"
                              [elements_length]="date_trainings.length"
                              [limit]="limit"
                              [total_pages]="pages"
                              ngDefaultControl>
              </app-pagination>
            </div>
          </div>
        </div>

        <div class="loader-content" *ngIf="!loader && (!trainings.length && !date_trainings.length)">
          <p>Aucun résultat</p>
        </div>
      </div>
    </div>
  </div>
</div>

<app-confirm-modal [type]="'delete_data'" #confirmData>{{ bindModalConfirmData(confirmData) }}</app-confirm-modal>

<router-outlet></router-outlet>
