import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NotifierService } from "angular-notifier";
import { Subject, Subscription } from "rxjs";
import { AuthService } from "src/app/core/auth/auth.service";
import { CustomNotifierService } from "src/app/services/custom.notifier.service";
import { EmployeesRequestService } from "src/app/services/employees.request.service";
import { TrainingsRequestService } from "src/app/services/trainings.request.service";
import Employees from "src/shared/interfaces/employees";

@Component({
  selector: "app-get-employees-training",
  templateUrl: "./get-employees-training.component.html",
  styleUrls: ["./get-employees-training.component.scss"],
})
export class EmployeesTrainingComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  changeEnterprise: Subscription;

  canCreate = false;
  canUpdate = false;
  canDelete = false;

  requestInProgress = new Subject();
  requestCount = 0;

  employees: Employees[] = [];

  pageName: string;
  previousPageName: string[] = [];
  previousPageRoute: string[] = [];

  currentRoute = "";
  view = "";

  count = 0;
  pages = 1;

  search = "";
  sort = "EmployeeTrainings.id";
  order = "asc";
  limit = 12;
  page = 1;

  errors = {};

  timeout: any;
  loader = false;

  modalConfirmData = null;

  constructor(
    private authService: AuthService,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private employeesService: EmployeesRequestService,
    private trainingService: TrainingsRequestService
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loader = this.requestCount > 0;
    });

    this.changeEnterprise = this.authService.getEnterprise().subscribe(() => {
      this.getEmployees();
      this.createBreadcrumbs();
    });

    this.pageName = "";
    this.currentRoute = "/" + this.activatedRoute.snapshot.url.join("/") + "/";

    if (this.authService.loaded) {
      this.getEmployees();
      this.createBreadcrumbs();
    }
  }

  ngAfterViewInit() {
    this.modalConfirmData.eventOnClose.subscribe((event) => {
      if (event) {
        this.requestInProgress.next(true);
        let request;
        request = this.employeesService.deleteEmployeeFromMainMenu(event);
        request.subscribe((response) => {
          if (response["success"]) {
            this.refreshData();
            CustomNotifierService.getSuccessDelete(this.notifier);
          } else if (response["errors"]) {
            CustomNotifierService.getErrors(response["errors"], this.notifier);
          }
          this.requestInProgress.next(false);
        });
      }
    });
  }

  ngOnDestroy() {
    const flatPicker = document.getElementsByClassName(
      "flatpickr-calendar"
    ) as HTMLCollection;
    for (let i = flatPicker.length; i >= 0; i--) {
      if (flatPicker[i]) {
        flatPicker[i].remove();
      }
    }

    this.changeEnterprise.unsubscribe();
  }

  searchData() {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.page = 1;
      this.refreshData();
    }, 500);
  }

  refreshData(sort = this.sort, order = this.order, page = this.page) {
    this.sort = sort;
    this.order = order;
    this.page = page;
    this.getEmployees();
  }

  getEmployees() {
    let request;

    this.requestInProgress.next(true);

    request = this.employeesService.getEmployeesTraining(
      this.search,
      this.sort,
      this.order,
      this.limit,
      this.page
    );

    request.subscribe((response) => {
      if (response["success"]) {
        this.canCreate = response["canCreate"];
        this.canUpdate = response["canUpdate"];
        this.canDelete = response["canDelete"];

        this.employees = response["employees_training"];

        this.count = response["count"];
        this.pages = response["pages"];
      } else {
        CustomNotifierService.getErrors(response["errors"], this.notifier);
      }
      this.requestInProgress.next(false);
    });
  }

  filter(property) {
    if (this.sort === property) {
      if (this.order === "asc") {
        this.order = "desc";
      } else {
        this.order = "asc";
      }
    } else {
      this.sort = property;
      this.order = "asc";
    }

    this.getEmployees();
  }

  createBreadcrumbs() {
    this.previousPageName = [];
    this.previousPageRoute = [];

    this.previousPageName.push("Employées");
    this.previousPageRoute.push("/employees");
  }

  dispatchPagination(data) {
    if (data.action === "total_elements") {
      this.refreshPage(data.value);
    } else if (data.action === "change_page") {
      this.changePage(data.value);
    }
  }

  refreshPage(limit) {
    this.limit = limit;
    this.page = 1;
    this.refreshData();
  }

  changePage(page) {
    if (page >= 1 && page <= this.pages) {
      this.page = page;
      this.getEmployees();
    }
  }

  bindModalConfirmData(modal) {
    this.modalConfirmData = modal;
  }
}
