import { Routes } from '@angular/router';
import { UpdateSanitaryAnalyzeFrameComponent } from './update-sanitary-analyze-frame/update-sanitary-analyze-frame.component';

export const sanitaryAnalyzeFramesRoutes: Routes = [
  {
    path: 'create',
    component: UpdateSanitaryAnalyzeFrameComponent
  },
  {
    path: ':sanitary_analyze_frame_id',
    component: UpdateSanitaryAnalyzeFrameComponent
  },
];
