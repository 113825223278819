import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {
  @Input() currentPage: string;
  @Input() names: string[] = [];
  @Input() routes: string[] = [];

  constructor(
    private authService: AuthService,
  ) {}

  ngOnInit() {}

  getRoute(string: string) {
    if (string === '/') {
      return this.authService.checkUrlStats(JSON.parse(localStorage.getItem('registries')));
    } else {
      return string;
    }
  }
}
